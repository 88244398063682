export interface SendItemArgs {
  company: string;
  address: string;
  content: string;
  trip_id: number;
  traveler: string;
  from: string;
  to: string;
  name: string;
  boarding_arrival: string;
  type: string;
  seat: string;
  preference?: string;
  applicator: string;
  shinkansen_urgent: boolean;
}

export class SendItem {
  readonly company: string;

  readonly address: string;

  readonly content: string;

  readonly trip_id: number;

  readonly traveler: string;

  readonly from: string;

  readonly to: string;

  readonly name: string;

  readonly boardingArrival: string;

  readonly type: string;

  readonly seat: string;

  readonly preference?: string;

  readonly applicator: string;

  readonly shinkansenUrgent: boolean;

  constructor(args: SendItemArgs) {
    this.company = args.company;
    this.address = args.address;
    this.content = args.content;
    this.trip_id = args.trip_id;
    this.traveler = args.traveler;
    this.from = args.from;
    this.to = args.to;
    this.name = args.name;
    this.boardingArrival = args.boarding_arrival;
    this.type = args.type;
    this.seat = args.seat;
    this.preference = args.preference;
    this.applicator = args.applicator;
    this.shinkansenUrgent = args.shinkansen_urgent;
  }
}
