import type { Approver } from '@this/domain/trip_report/approver';

export interface InAdvanceApproverCandidateArgs {
  id: number;
  in_advance_approval_id: number;
  approver_candidate_id: number;
  approver_candidate: Approver;
}

export class InAdvanceApproverCandidate {
  id: number;

  inAdvanceApprovalId: number;

  approverCandidateId: number;

  approverCandidate: Approver;

  constructor(args: InAdvanceApproverCandidateArgs) {
    this.id = args.id;
    this.inAdvanceApprovalId = args.in_advance_approval_id;
    this.approverCandidateId = args.approver_candidate_id;
    this.approverCandidate = args.approver_candidate;
  }
}
