import React, { useContext } from 'react';
import { DepartmentSelectContext } from './context';
import type { DepartmentSelectListProps } from './department_select_list';
import DepartmentSelectList from './department_select_list';

type Props = Omit<DepartmentSelectListProps, 'departments'>;

export type DepartmentSelectProps = Props;

const DepartmentSelect: React.FC<Props> = props => {
  const departments = useContext(DepartmentSelectContext);

  if (departments.length < 1) return <></>;

  return <DepartmentSelectList departments={departments} {...props} />;
};

export default DepartmentSelect;
