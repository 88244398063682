import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import Tooltip from '@this/shared/tooltip/tooltip';
import type Transit from '../../../../domain/transit/transit';

import TranslateIgnoreText from '../../../shared/text/translate_ignore_text';

type Props = {
  transit: Transit | null | undefined;
  elementRaw: any;
  domesticAirPriceIndex: number | null | undefined;
  // shinkansenTooLate: boolean;
};

class ReserveConfirmTransitTr extends React.Component<Props> {
  render() {
    try {
      const { transit, elementRaw, domesticAirPriceIndex } = this.props;
      const shinkansenTooLate = false; // 渡されないprops

      return (
        <>
          {transit ? (
            transit.shinkansen && shinkansenTooLate ? (
              <ShinkansenTooLate>
                新幹線のチケットは郵送する必要がありますので出発の2日前以降のご予約は承っておりません。
              </ShinkansenTooLate>
            ) : (
              <>
                <TranslateIgnoreText
                  text={
                    transit.legsummaries_fromto_jrair ? transit.legsummaries_fromto_jrair : transit.legs_fromto
                  }
                />
                <TextDetail>
                  {elementRaw.air === true ? (
                    <>
                      <TranslateIgnoreText text={transit.selected_text} />
                      {elementRaw.prices && domesticAirPriceIndex && (
                        <TicketSeatTypeArea>{`券種: ${
                          elementRaw.prices[domesticAirPriceIndex].ticket_category === 'changeable'
                            ? '変更可'
                            : '変更不可'
                        } 席種: ${elementRaw.prices[domesticAirPriceIndex].seat_type}`}</TicketSeatTypeArea>
                      )}
                    </>
                  ) : (
                    <TranslateIgnoreText text={transit.selected_text} />
                  )}
                </TextDetail>
                <TextDetail>
                  {transit.railway_distance && transit.shinkansen && !transit.package && (
                    <TextKm>
                      営業キロ
                      <Tooltip width={300}>
                        <TextKmDetail>
                          ※営業キロとは、鉄道における営業線の長さを示すキロ程のことであり、運賃計算などの基礎となる数字です。
                        </TextKmDetail>
                        <TextKmDetail>※営業キロは新幹線だけでなく在来線も含みます。</TextKmDetail>
                      </Tooltip>
                      ：<span data-wovn-ignore>{transit.railway_distance}</span>km
                    </TextKm>
                  )}
                </TextDetail>
                {!transit.package && (
                  <>
                    {transit.priceDetailArray().map((d: any, i: number) => (
                      <PriceDetail key={i}>
                        <p>{d.title}</p>
                        <p>{d.price}</p>
                      </PriceDetail>
                    ))}
                  </>
                )}
              </>
            )
          ) : (
            '-'
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ShinkansenTooLate = styled.span`
  color: ${props => props.theme.redColor};
`;

const TicketSeatTypeArea = styled.div`
  margin-bottom: 10px;
`;

const PriceDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
`;

const TextDetail = styled.div`
  font-size: 0.9em;
  padding-left: 0.9em;
  font-weight: normal;
`;

const TextKm = styled.div`
  display: flex;
`;

const TextKmDetail = styled.p`
  padding-left: 1em;
  text-indent: -1em;
`;

export default ReserveConfirmTransitTr;
