import type { SmartHrImportDataChangeResponseArgs } from '@this/domain/smart_hr_import_data_change';
import SmartHrImportDataChange, {
  convertSmartHrImportDataChangeResponseToArgs
} from '@this/domain/smart_hr_import_data_change';

type Status = 'unstarted' | 'skipped' | 'succeeded' | 'failed';

const StatusMessage: { [key in Status]: string } = {
  unstarted: '読み込み中',
  skipped: 'スキップ',
  succeeded: '成功',
  failed: '失敗'
};

export type SmartHrImportDataCrewResponseArgs = {
  id: number;
  status: Status;
  import_type: string;
  user_id: number;
  error_message: string[];
  smart_hr_email: string;
  smart_hr_crew_id: string;
  change_attr: SmartHrImportDataChangeResponseArgs;
};

export type SmartHrImportDataCrewArgs = {
  id: number;
  status: Status;
  importType: string;
  userId: number;
  errorMessage: string[];
  smartHrEmail: string;
  smartHrCrewId: string;
  changeAttr: SmartHrImportDataChange | null;
};

export const convertSmartHrImportDataCrewResponseToArgs = (response: SmartHrImportDataCrewResponseArgs) => {
  const args: SmartHrImportDataCrewArgs = {
    id: response.id,
    status: response.status,
    importType: response.import_type,
    userId: response.user_id,
    errorMessage: response.error_message,
    smartHrEmail: response.smart_hr_email,
    smartHrCrewId: response.smart_hr_crew_id,
    changeAttr: response.change_attr
      ? new SmartHrImportDataChange(convertSmartHrImportDataChangeResponseToArgs(response.change_attr))
      : null
  };
  return args;
};

export default class SmartHrImportDataCrew {
  public readonly id: number;

  public readonly status: Status;

  public readonly importType: string;

  public readonly userId: number;

  public readonly errorMessage: string[];

  public readonly smartHrEmail: string;

  public readonly smartHrCrewId: string;

  public readonly changeAttr: SmartHrImportDataChange | null;

  constructor(args: SmartHrImportDataCrewArgs) {
    this.id = args.id;
    this.status = args.status;
    this.importType = args.importType;
    this.userId = args.userId;
    this.errorMessage = args.errorMessage;
    this.smartHrEmail = args.smartHrEmail;
    this.smartHrCrewId = args.smartHrCrewId;
    this.changeAttr = args.changeAttr;
  }

  isSuccess() {
    return this.status === 'succeeded';
  }

  isFail() {
    return this.status === 'failed';
  }

  statusToText() {
    return StatusMessage[this.status];
  }

  errorMessageText() {
    if (this.errorMessage && this.errorMessage.length > 0) {
      return ` : ${this.smartHrEmail || this.smartHrCrewId || ''}`;
    }
    return '';
  }

  importTypeToText() {
    let importTypeText;
    switch (this.importType) {
      case 'insert':
        importTypeText = '新規';
        break;
      case 'update':
        importTypeText = '更新';
        break;
      default:
        importTypeText = '';
    }
    return importTypeText;
  }
}
