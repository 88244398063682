Approver = require('./approver')

class ApproverList
  constructor: (raw) ->
    @list = _.map raw, (o) -> new Approver(o)
    @list ||= []

  joinApproverByApproveStage: (approve_stage) ->
    filterList = _.filter @list, 'approve_stage': approve_stage
    if filterList
      _.map filterList, (a) -> a.name
    else
      ['-']

module.exports = ApproverList
