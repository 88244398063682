import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Button } from '@this/shared/ui/inputs/button';
import { Fetcher } from '@this/src/util';

interface PriorityTableProps {}

interface DomesticAirPriorityResponse {
  data: DomesticAirPriority[];
}

interface DomesticAirPriority {
  id: number;
  service_id: number;
  index: number;
  class_name: string;
}

const PriorityTable: React.FC<PriorityTableProps> = () => {
  const [priority, setPriority] = useState<DomesticAirPriority[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPriority = async () => {
      setLoading(true);
      try {
        const response = await Fetcher.get<DomesticAirPriorityResponse>('/god/domestic_air_priority');
        setPriority(response.data);
      } catch (error) {
        setError('データの取得に失敗しました');
      } finally {
        setLoading(false);
      }
    };

    fetchPriority();
  }, []);

  const handleSave = async (id: number, updatedItem: DomesticAirPriority) => {
    setLoading(true);
    try {
      await Fetcher.put(`/god/domestic_air_priority/${id}`, updatedItem);
      setPriority(prevPriority => prevPriority.map(item => (item.id === id ? updatedItem : item)));
    } catch (error) {
      setError('データの更新に失敗しました');
    }
    setLoading(false);
  };

  const handleInputChange = (id: number, field: keyof DomesticAirPriority, value: string | number) => {
    setPriority(prevPriority => prevPriority.map(item => (item.id === id ? { ...item, [field]: value } : item)));
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container style={{ fontSize: '10px' }}>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>service ID</th>
            <th>優先順位</th>
            <th>クラス名</th>
            <th>アクション</th>
          </tr>
        </thead>
        <tbody>
          {priority.map(p => (
            <tr key={p.id}>
              <td>{p.id}</td>
              <td>{p.service_id}</td>
              <td>
                <input
                  type="number"
                  value={p.index}
                  onChange={e => handleInputChange(p.id, 'index', Number(e.target.value))}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={p.class_name}
                  onChange={e => handleInputChange(p.id, 'class_name', e.target.value)}
                />
              </td>
              <td>
                <Button onClick={() => handleSave(p.id, p)}>保存</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export default observer(PriorityTable);
