import _ from 'lodash';

import type { FileValidatorResult } from './types';

export default class FileValidator {
  static validate(file?: File): Promise<FileValidatorResult> {
    return new Promise<FileValidatorResult>(resolve => {
      const result: FileValidatorResult = { rowCount: 0 };

      if (!file) {
        result.errors = ['CSV以外のファイル形式はアップロードできません。'];
        resolve(result);
      } else {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = () => {
          const rowCount = (reader.result as string).split('\n').filter(l => l.trim().length).length;
          // ヘッダーを無視する
          result.rowCount = rowCount - 1;
          if (result.rowCount === 0) {
            result.errors = ['ファイル上にアップロード可能なデータを確認できませんでした。'];
          } else {
            result.file = file;
          }
          resolve(result);
        };
      }
    });
  }
}
