import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  shown: boolean;
  handleClose: () => void;
}

const TripChatInformation: React.FC<Props> = ({ shown, handleClose, children }) => {
  if (!shown) {
    return null;
  }

  return (
    <Information>
      <p>{children}</p>
      <CloseButton type="button" onClick={() => handleClose()}>
        ×
      </CloseButton>
    </Information>
  );
};

export default TripChatInformation;

const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #d8f2ff;
  border: 1px solid #a9cee0;
  border-radius: 3px;
  color: #35a4da;
  margin-bottom: 10px;
  padding: 10px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  padding: 0;
  outline: none;
  color: #35a4da;
  transition: opacity 0.3s;

  &:hover {
    background-color: transparent;
    color: #35a4da;
    opacity: 0.8;
  }
`;
