import _ from 'lodash';
import { observable, action } from 'mobx';
import type { ProjectShareArgs } from './project_share';
import ProjectShare from './project_share';

class ProjectShareList {
  @observable
  public list: ProjectShare[];

  constructor(raw: ProjectShareArgs[]) {
    this.list = raw.map(o => new ProjectShare(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  last() {
    return _.last(this.list);
  }

  @action
  push(o: ProjectShare) {
    this.list.push(o);
  }

  @action
  remove(o: ProjectShare) {
    const i = this.list.findIndex(item => item.id === o.id);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  @action
  update(newOrg: ProjectShare) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }
}

export default ProjectShareList;
