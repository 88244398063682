import React from 'react';
import { observer } from 'mobx-react';
import Divider from '@material-ui/core/Divider';

import { styled } from '@this/constants/themes';
import FormattedKeyValue from '@this/shared/formatted_key_value/formatted_key_value';
import type { FreeeExternalProps } from './freee/freee_external';
import FreeeExternal from './freee/freee_external';
import type { InfomartExternalProps } from './infomart/infomart_external';
import InfomartExternal from './infomart/infomart_external';

type Props = FreeeExternalProps & InfomartExternalProps;

const External = ({ freeeConnected, infomartConnected, invoice, onRefreshInvoices }: Props) => {
  return (
    <div>
      <p>請求書を外部サービスと連携することができます。</p>
      <Info>
        <FormattedKeyValue
          data={[
            { key: '対象期間', value: invoice.from && invoice.from.format('YYYY年MM月分') },
            { key: '請求日', value: invoice.chargedAt.format('YYYY/MM/DD') },
            { key: '合計金額', value: utils.formatPriceWithMark(invoice.totalPriceWithTax) },
            { key: 'お支払い期限', value: invoice.payAt.format('YYYY/MM/DD') }
          ]}
        />
      </Info>
      <div>
        <H2>freee連携</H2>
        <FreeeExternal freeeConnected={freeeConnected} invoice={invoice} />
      </div>
      {infomartConnected && (
        <>
          <Divider />
          <div>
            <InfomartExternal
              infomartConnected={infomartConnected}
              invoice={invoice}
              onRefreshInvoices={onRefreshInvoices}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Info = styled.div`
  margin-top: 10px;
  font-size: 12px;
  background: #ddd;
  padding: 5px;
  width: fit-content;
`;

const H2 = styled.h2`
  margin-top: 20px;
`;

export default observer(External);
