import { observable, computed, action } from 'mobx';

export interface InvoiceItemArgs {
  id?: number;
  invoice_id?: number;
  title?: string;
  unit_price?: number;
  amount?: number;
  include_tax?: boolean;
  tax?: number;
  tax_type_id?: number;
  create_manually?: boolean;
}

class InvoiceItem {
  @observable
  consumingTaxRatio = 0.1;

  @observable
  id: number | null;

  @observable
  invoiceId: number | null;

  @observable
  title: string;

  @observable
  unitPrice: number;

  @observable
  amount: number;

  @observable
  includeTax: boolean;

  @observable
  tax: number;

  @observable
  taxTypeId: number | null;

  @observable
  createManually: boolean;

  constructor(args: InvoiceItemArgs) {
    this.id = args.id || null;
    this.invoiceId = args.invoice_id || null;
    this.title = args.title || '';
    this.unitPrice = args.unit_price || 0;
    this.amount = args.amount || 0;
    this.includeTax = args.include_tax || false;
    this.tax = args.tax || 0;
    this.taxTypeId = args.tax_type_id || null;
    this.createManually = args.create_manually || false;
  }

  @computed
  get calcTax(): number {
    if (this.includeTax) {
      return 0;
    }
    // 本来は画面上で税率を設定できるようにすべき
    return Math.floor(this.unitPrice * this.amount * this.consumingTaxRatio);
  }

  @action
  setUnitPrice(unitPrice: number) {
    this.unitPrice = unitPrice;
    this.tax = this.calcTax;
  }

  @action
  setAmount(amount: number) {
    this.amount = amount;
    this.tax = this.calcTax;
  }

  @action
  setIncludeTax(includeTax: boolean) {
    this.includeTax = includeTax;
    this.tax = this.calcTax;
  }

  @computed
  get priceWithoutTax(): number {
    return this.unitPrice * this.amount;
  }

  @computed
  get priceWithTax(): number {
    return this.priceWithoutTax + this.tax;
  }

  submitParams(): object {
    return {
      id: this.id,
      invoice_id: this.invoiceId,
      title: this.title,
      unit_price: this.unitPrice,
      amount: this.amount,
      include_tax: this.includeTax,
      tax: this.tax,
      tax_type_id: this.taxTypeId,
      create_manually: this.createManually
    };
  }
}

export default InvoiceItem;
