import type { SuppliedItemArgs } from '@this/domain/supplied_item/supplied_item';
import SuppliedItem from '@this/domain/supplied_item/supplied_item';
import BaseAPI from '../base_api';

interface SuppliersResponse {
  supplied_items: SuppliedItemArgs[];
}

export default class SuppliedItemAPI extends BaseAPI {
  public list(): Promise<SuppliedItem[]> {
    return this.get<SuppliersResponse>(`/supplied_items.json`).then(res =>
      res.data.supplied_items.map((raw: SuppliedItemArgs) => new SuppliedItem(raw))
    );
  }
}
