import React from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@this/constants/themes';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import type { Moment } from 'moment';
import moment from 'moment';
import DateRangePicker from '../todo_list/date_range_picker';

export interface UrlQuery {
  trip_id: number | undefined;
  email: string;
  created_at_from: Moment | undefined;
  created_at_to: Moment | undefined;
}

export type UrlQueryAction =
  | { type: 'SET_EMAIL'; payload: string }
  | { type: 'SET_TRIP_ID'; payload: string }
  | { type: 'SET_CREATED_AT_FROM'; payload: Moment | undefined }
  | { type: 'SET_CREATED_AT_TO'; payload: Moment | undefined };

interface Props {
  urlQuery: UrlQuery;
  dispatch: Dispatch<ReducerAction<Reducer<{ urlQuery: UrlQuery }, UrlQueryAction>>>;
  renderOrganizationSelector: () => JSX.Element;
}

export const urlQueryReducer = (state: UrlQuery, action: UrlQueryAction): UrlQuery => {
  switch (action.type) {
    case 'SET_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_TRIP_ID': {
      const trip_id = action.payload ? parseInt(action.payload, 10) : undefined;
      return { ...state, trip_id };
    }
    case 'SET_CREATED_AT_FROM':
      return { ...state, created_at_from: action.payload };
    case 'SET_CREATED_AT_TO':
      return { ...state, created_at_to: action.payload };
    default:
      return state;
  }
};

const getStringParam = (key: string): string => {
  return utils.getParam(key) || '';
};

const getNumberParam = (key: string): number | undefined => {
  const str = utils.getParam(key);
  return str ? parseInt(str, 10) : undefined;
};

const getMomentParam = (key: string): Moment | undefined => {
  const str = utils.getParam(key);
  return str ? moment(str) : moment();
};

export const initialUrlQuery: UrlQuery = {
  trip_id: getNumberParam('trip_id'),
  email: getStringParam('email'),
  created_at_from: getMomentParam('created_at_from'),
  created_at_to: getMomentParam('created_at_to')
};

const SearchTrackingsSearchArea: React.FC<Props> = ({ urlQuery, dispatch, renderOrganizationSelector }) => {
  const { trip_id, email, created_at_from, created_at_to } = urlQuery;

  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>メールアドレス</SearchLabel>
        <Input
          value={email || ''}
          onChange={e => dispatch({ type: 'SET_EMAIL', payload: e.target.value })}
          style={{ width: '160px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>Trip ID</SearchLabel>
        <Input
          value={trip_id || ''}
          onChange={e => dispatch({ type: 'SET_TRIP_ID', payload: e.target.value })}
          style={{ width: '80px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>期間</SearchLabel>
        <DateRangePicker
          from={created_at_from}
          to={created_at_to}
          onFromChange={d => dispatch({ type: 'SET_CREATED_AT_FROM', payload: d })}
          onToChange={d => dispatch({ type: 'SET_CREATED_AT_TO', payload: d })}
        />
      </SearchBlock>
    </SearchArea>
  );
};

const SearchArea = styled(Box)`
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default SearchTrackingsSearchArea;
