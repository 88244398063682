/**
 * 文字列操作に関わるUtilです。
 */

import escapeHtml from 'escape-html';
import type { EscapeHtmlOptions } from './escape_html_whitelist';
import { escapeHtml as escapeHtmlWhitelist } from './escape_html_whitelist';

/**
 * 桁の数字を0埋めして2桁になるようにします
 */
export const doubleDigits = (num: number) => num.toString().padStart(2, '0');

/**
 * スネークケースからキャメルケースに変換します
 */
export const snakeToCamel = (str: string) => str.replace(/_./g, s => s.charAt(1).toUpperCase());

/**
 * キャメルケースからスネークケースに変換します
 */
export const camelToSnake = (str: string) => str.replace(/([A-Z])/g, s => `_${s.charAt(0).toLowerCase()}`);

/**
 * 日本の電話番号に変換します
 */
export const toDomesticTel = (tel: string) => {
  if (tel) {
    return tel.replace(/^\+81|^81-/, '0');
  }
  return tel;
};

/**
 * 妥当なメールアドレスかどうか検証します
 */
export const validateEmail = (email: string) => {
  const r =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return r.test(email);
};

/**
 * 指定の数字で省略表示します
 */
export const roundString = (str: string | null | undefined, length: number) => {
  if (!str) {
    return '';
  }
  let res = str.substr(0, length);
  if (str.length > length) {
    res += '...';
  }
  return res;
};

/**
 * html文字列から危険な文字列を除去します
 */
export const escapeHTML = (htmlText: string) => {
  return escapeHtml(htmlText).replace(/javascript:/g, '');
};

/**
 * html文字列から危険な文字列を除去します(除外するタグを指定できます)
 */
export const escapeHTMLWhitelist = (htmlText: string, options?: Partial<EscapeHtmlOptions>) => {
  return escapeHtmlWhitelist(htmlText, options).replace(/javascript:/g, '');
};

/**
 * 全角から半角に変換(英数字のみ。カタカナは非対応)
 */
export const zenToHan = (str: string) => {
  return str.replace(/[！-～]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
};
