import { observable } from 'mobx';

export type ShinkansenLimitTypeJson = {
  id?: number;
  business_days?: number;
  hour?: number;
  created_at?: string;
  updated_at?: string;
  organization_id?: number;
};

class ShinkansenLimitType {
  @observable
  readonly id?: number | null;

  @observable
  businessDays: number;

  @observable
  hour: number;

  constructor(args: ShinkansenLimitTypeJson) {
    this.id = args.id;
    this.businessDays = args.business_days || 1;
    this.hour = typeof args.hour === 'number' ? args.hour : 15;
  }

  description() {
    return `${this.businessDays}営${this.hour}時`;
  }

  submitParams() {
    return {
      id: this.id,
      business_days: this.businessDays,
      hour: this.hour
    };
  }
}

export default ShinkansenLimitType;
