import React from 'react';
import _ from 'lodash';

import type FlightList from '@this/domain/flight/flight_list';
import type FlightSliceCandidate from '@this/domain/flight/flight_slice_candidate';
import type SelectRepository from '@this/domain/select_repository';
import type SelectStore from '@this/src/domain/select_store';
import SelectFlightBox from '../../flight_box/flight_box';

interface SelectFlightListProps {
  flights: FlightList;
  type: number;
  repository: SelectRepository;
  store: SelectStore;
  onChange: () => void;
}

class SelectFlightList extends React.Component<SelectFlightListProps> {
  handleSelect = (f: FlightSliceCandidate) => {
    this.props.onChange();
    this.props.flights.selectWithAnimation(f.list[0].id);
  };

  handleReload() {
    this.props.repository.reloadFlights();
  }

  render() {
    try {
      return (
        <div className="select-transport-list">
          {this.props.flights.flightCandidates(this.props.type, true).map(f => (
            <SelectFlightBox
              key={f.list[0].id}
              flight={f}
              inList
              selected={
                this.props.flights.currentSliceId(this.props.type) === f.list[0].itineraries[this.props.type].id
              }
              handleSelect={this.handleSelect}
              flightIndex={this.props.type}
              tab={this.props.type}
              isDomestic={this.props.store.result.isDomesticAirResult()}
            />
          ))}
          <div className="center" style={{ marginTop: '20px' }}>
            ご希望の便が表示されませんか？
            <br />
            <button type="button" style={{ marginTop: '20px' }} onClick={() => this.handleReload()}>
              現在の条件で再検索
            </button>
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectFlightList;
