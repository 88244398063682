import React, { useState } from 'react';
import styled from 'styled-components';
import { AccountStatus } from '@this/domain/user/organization_member_json';
import type { MembersFilterFormData } from '@this/components/organization/members/types';
import { SubmitButton } from '@this/shared/atoms/button';
import type DepartmentList from '@this/domain/department/department_list';
import type OrganizationRole from '@this/domain/organization_role/organization_role';
import type { GradeArgs } from '@this/domain/grade';
import { Select } from '@this/shared/ui/inputs/select';
import { getColor } from '@this/shared/ui/theme';

type MembersFilterSortType = MembersFilterFormData['sortTypeText'];

const sortOptions: Record<MembersFilterSortType, string> = {
  CREATED_AT_ASC: '登録順',
  EMPLOYEE_NUMBER_ASC: '社員番号昇順',
  EMPLOYEE_NUMBER_DESC: '社員番号降順'
};

interface Props {
  formData: MembersFilterFormData;
  onChange: (formData: MembersFilterFormData) => void;
  onSearch: (formData: MembersFilterFormData) => void;
  departments: DepartmentList;
  organizationRoles: OrganizationRole[];
  grades: GradeArgs[];
  showSp?: boolean;
}

const MembersSearchFilter: React.FC<Props> = ({
  formData,
  onChange,
  onSearch,
  departments,
  organizationRoles,
  grades,
  showSp
}) => {
  const [form, setForm] = useState(formData);

  const handleClickSearch = () => {
    const newFormData = { ...form, accountStatus: form.pendingAccountStatus };
    onSearch(newFormData);
  };

  const onChangeSearchConditions = (value: string, formAttr: keyof MembersFilterFormData) => {
    const newFormData = { ...form, [formAttr]: value };
    // TODO: pendingAccountStatusを廃止して、accountStatusのみにできないか検討する
    newFormData.accountStatus = newFormData.pendingAccountStatus;
    setForm(newFormData);
    onChange(newFormData);
  };

  return (
    <OrganizationMembersMemberFilter className="e2e-member-filter">
      <OrganizationMembersSearchConditions>
        <OrganizationMembersSearchConditionsRow>
          <OrganizationMembersMemberFilterRadioDiv>
            <OrganizationMembersMemberFilterRadioInput
              id={`radio-${AccountStatus.ENABLED}`}
              type="radio"
              name="accountStatus"
              value={AccountStatus.ENABLED}
              checked={form.pendingAccountStatus === AccountStatus.ENABLED}
              onChange={e => onChangeSearchConditions(e.target.value, 'pendingAccountStatus')}
            />
            <label htmlFor={`radio-${AccountStatus.ENABLED}`}>有効</label>
            <OrganizationMembersMemberFilterRadioInput
              id={`radio-${AccountStatus.DISABLED}`}
              type="radio"
              name="accountStatus"
              value={AccountStatus.DISABLED}
              checked={form.pendingAccountStatus === AccountStatus.DISABLED}
              onChange={e => onChangeSearchConditions(e.target.value, 'pendingAccountStatus')}
            />
            <label htmlFor={`radio-${AccountStatus.DISABLED}`}>無効</label>
            <OrganizationMembersMemberFilterRadioInput
              id={`radio-${AccountStatus.INVITED}`}
              type="radio"
              name="accountStatus"
              value={AccountStatus.INVITED}
              checked={form.pendingAccountStatus === AccountStatus.INVITED}
              onChange={e => onChangeSearchConditions(e.target.value, 'pendingAccountStatus')}
            />
            <label htmlFor={`radio-${AccountStatus.INVITED}`}>招待済み</label>
            <OrganizationMembersMemberFilterRadioInput
              id={`radio-${AccountStatus.UNINVITE}`}
              type="radio"
              name="accountStatus"
              value={AccountStatus.UNINVITE}
              checked={form.pendingAccountStatus === AccountStatus.UNINVITE}
              onChange={e => onChangeSearchConditions(e.target.value, 'pendingAccountStatus')}
            />
            <label htmlFor={`radio-${AccountStatus.UNINVITE}`}>招待前</label>
            <OrganizationMembersMemberFilterSortDiv>
              <OrganizationMembersMemberFilterSortLabel>ソート順</OrganizationMembersMemberFilterSortLabel>
              <Select
                value={form.sortTypeText}
                onChange={e => onChangeSearchConditions(e.target.value, 'sortTypeText')}
              >
                {Object.entries(sortOptions).map(([k, v]) => (
                  <option key={k} value={k}>
                    {v}
                  </option>
                ))}
              </Select>
            </OrganizationMembersMemberFilterSortDiv>
          </OrganizationMembersMemberFilterRadioDiv>
        </OrganizationMembersSearchConditionsRow>
        <OrganizationMembersSearchConditionsRow>
          <OrganizationMembersMemberFilterLabel>社員検索</OrganizationMembersMemberFilterLabel>
          <OrganizationMembersMemberFilterField
            value={form.text}
            placeholder="氏名"
            onChange={e => onChangeSearchConditions(e.target.value, 'text')}
          />
          <OrganizationMembersDepartmentFilterLabel>部署</OrganizationMembersDepartmentFilterLabel>
          <OrganizationMembersDepartmentFilterField
            placeholder="部署名"
            list="organization_members_department_filter_list"
            name="organization_members_department_filter_field"
            id="organization_members_department_filter_field"
            onChange={e => onChangeSearchConditions(e.target.value, 'departmentText')}
          />
          <datalist id="organization_members_department_filter_list">
            {departments.list
              .filter((d: any) => !d.disabled)
              .map((d: any) => (
                <option key={d.id}>{d.name}</option>
              ))}
          </datalist>
          <OrganizationMembersRoleFilterLabel>役割</OrganizationMembersRoleFilterLabel>
          <OrganizationMembersRoleSelect onChange={e => onChangeSearchConditions(e.target.value, 'roleId')}>
            <option key="oganization_members_role_not_selected" value="">
              --
            </option>
            {organizationRoles.map(role => (
              <option key={role.id} value={role.id}>
                {role.name}
              </option>
            ))}
          </OrganizationMembersRoleSelect>
          <OrganizationMembersGradeFilterLabel>役職等級</OrganizationMembersGradeFilterLabel>
          <OrganizationMembersGradeSelect onChange={e => onChangeSearchConditions(e.target.value, 'gradeId')}>
            <option key="oganization_members_grade_not_selected" value="">
              --
            </option>
            {grades.map(grade => (
              <option key={grade.id} value={grade.id}>
                {grade.name}
              </option>
            ))}
          </OrganizationMembersGradeSelect>
          {showSp && (
            <>
              <OrganizationMembersSpFilterLabel>SP</OrganizationMembersSpFilterLabel>
              <OrganizationMembersSpSelect onChange={e => onChangeSearchConditions(e.target.value, 'sp')}>
                <option value="">--</option>
                <option value="true">◯</option>
                <option value="false">✖︎</option>
              </OrganizationMembersSpSelect>
            </>
          )}
          <OrganizationMembersSearchButtons>
            <TheSubmitButton type="submit" value="検索" onClick={handleClickSearch} />
          </OrganizationMembersSearchButtons>
        </OrganizationMembersSearchConditionsRow>
      </OrganizationMembersSearchConditions>
    </OrganizationMembersMemberFilter>
  );
};

const OrganizationMembersMemberFilter = styled.div`
  // display: flex;
  // align-items: center;
  margin-bottom: 30px;
`;

const OrganizationMembersSearchConditions = styled.div`
  margin-bottom: 10px;
`;

const OrganizationMembersMemberFilterSortDiv = styled.div`
  display: flex;
  float: left;
  align-items: center;
`;

const OrganizationMembersSearchConditionsRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrganizationMembersSearchButtons = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const OrganizationMembersMemberFilterSortLabel = styled.label`
  font-weight: normal;
  // padding-left: 20px;
  // position: relative;
  // float: left;
  margin-right: 10px;
`;

const OrganizationMembersMemberFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersDepartmentFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersRoleFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersGradeFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersSpFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersMemberFilterRadioDiv = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 50%;
`;

const OrganizationMembersMemberFilterField = styled.input.attrs({ type: 'text' })`
  flex: 5 1 0;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const OrganizationMembersDepartmentFilterField = styled.input.attrs({ type: 'text' })`
  flex: 1 1 0;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const OrganizationMembersRoleSelect = styled.select`
  flex: 1 1 0;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const OrganizationMembersGradeSelect = styled.select`
  flex: 1 1 0;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const OrganizationMembersSpSelect = styled.select`
  flex: 1 1 0;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const TheSubmitButton = styled(SubmitButton)`
  &&& {
    width: 100px;
    margin-right: 30px;
  }
`;

const OrganizationMembersMemberFilterRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

export default MembersSearchFilter;
