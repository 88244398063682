import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase02 } from '@this/shared/atoms/button';
import SearchableSelect from '@this/shared/searchable_select';
import type { InternationalAirline } from './types';

interface Props {
  internationalAirlines: InternationalAirline[];
  exclusionAirlines: string[];
  onChange: any;
}

const ExclusionAirlinesArea: React.FC<Props> = ({ internationalAirlines, exclusionAirlines, onChange }) => {
  const [selectedAirlines, setAirlines] = useState(exclusionAirlines);
  const selectableAirlines = internationalAirlines.map(airline => ({
    value: airline.code,
    displayName: airline.name
  }));
  const addAirline = () => {
    selectedAirlines.push('');
    setAirlines([...selectedAirlines]);
  };
  const changeAirline = (i: number, value: string) => {
    selectedAirlines.splice(i, 1, value);
    setAirlines([...selectedAirlines]);
    onChange(selectedAirlines);
  };
  const removeAirline = (i: number) => {
    selectedAirlines.splice(i, 1);
    setAirlines([...selectedAirlines]);
    onChange(selectedAirlines);
  };

  return (
    <table className="organization-setting-table">
      <tbody>
        {selectedAirlines.map((airline, i) => (
          <tr key={i}>
            <td>{i === 0 && <label>航空会社</label>}</td>
            <td className="select-cell">
              <AirlineSelectArea>
                <SearchableSelect
                  list={selectableAirlines}
                  currentValue={airline}
                  onChange={(v: string) => changeAirline(i, v)}
                />
                <RemoveButton onClick={() => removeAirline(i)}>+</RemoveButton>
              </AirlineSelectArea>
            </td>
          </tr>
        ))}
        <tr>
          <td>
            <Button onClick={addAirline}>航空会社を追加</Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase02};
  padding: 0.75em 1em;
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  color: #7d7d7d;
  border-radius: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  transform: rotate(45deg);
  transition: opacity 0.3s;
  font-size: 16px;
  outline: none;

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
    color: #7d7d7d;
  }
`;

const AirlineSelectArea = styled.div`
  display: flex;
  align-items: center;

  ${RemoveButton} {
    margin-left: 10px;
  }
`;

export default ExclusionAirlinesArea;
