import React from 'react';
import type {
  TableContainerProps as MuiTableContainerProps,
  TableProps as MuiTableProps,
  TableCellProps as MuiTableCellProps,
  TableHeadProps as MuiTableHeadProps,
  TableBodyProps as MuiTableBodyProps,
  TableRowProps as MuiTableRowProps
} from '@material-ui/core';
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
  makeStyles
} from '@material-ui/core';
import type { PaginationProps } from '@this/shared/ui/navigations/pagination';
import { Pagination } from '@this/shared/ui/navigations/pagination';
import styled from 'styled-components';
import type { AITTheme } from '@this/shared/ui/theme';

export type TableContainerProps = Omit<MuiTableContainerProps, 'component'> & { height?: string };
const useTableContainerStyle = makeStyles<AITTheme, { height?: string }>(theme => {
  return {
    root: ({ height }) => ({
      position: 'relative',
      height: height ?? '100%',
      borderBottom: `1px solid ${theme.tokens.colors.border.divider}`
    })
  };
});
export const TableContainer = ({ className, height, ...props }: TableContainerProps) => {
  const styles = useTableContainerStyle({ height });
  return <MuiTableContainer className={`${styles.root} ${className}`} {...props} />;
};

export type TableProps = Omit<MuiTableProps, 'component'>;
export const Table = (props: TableProps) => <MuiTable {...props} />;

export type TableHeadProps = Omit<MuiTableHeadProps, 'component'>;
export const TableHead = (props: TableHeadProps) => <MuiTableHead {...props} />;

export type TableBodyProps = Omit<MuiTableBodyProps, 'component'>;
export const TableBody = (props: TableBodyProps) => <MuiTableBody {...props} />;

export type TableRowProps = Omit<MuiTableRowProps, 'component'> & { disabled?: boolean };
const useTableRowStyle = makeStyles<AITTheme, { disabled: boolean }>(({ tokens: { colors } }) => {
  return {
    root: ({ disabled }) => {
      return {
        backgroundColor: disabled ? colors.background.disabled : '',
        '&.Mui-selected': {
          backgroundColor: colors.background.selected
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.background.hover
        }
      };
    }
  };
});
export const TableRow = ({ className, disabled = false, ...props }: TableRowProps) => {
  const styles = useTableRowStyle({ disabled });
  return <MuiTableRow className={`${className} ${styles.root}`} {...props} />;
};

export type TableCellProps = MuiTableCellProps & { width?: string; th?: boolean; nowrap?: boolean };
const useTableCellStyle = makeStyles<AITTheme>(({ tokens: { colors, typographies }, utils }) => {
  return {
    root: {
      padding: `${utils.getSpacing(2)}px ${utils.getSpacing(3)}px`,
      '&.MuiTableCell-head': {
        fontWeight: 'bold',
        fontSize: typographies.fontSizes.caption,
        lineHeight: typographies.lineHeight.caption,
        backgroundColor: colors.common.white,
        borderBottom: `1px solid ${colors.border.divider}`
      },
      '&.MuiTableCell-sizeSmall': {
        padding: `${utils.getSpacing(1)}px ${utils.getSpacing(2)}px`
      }
    }
  };
});
const Th = styled.th`
  font-weight: bold;
`;
export const TableCell = ({
  className,
  width,
  th = false,
  nowrap = false,
  style: domStyle = {},
  ...props
}: TableCellProps) => {
  const styles = useTableCellStyle();
  const style = { ...domStyle, width };
  if (nowrap) {
    style.whiteSpace = 'nowrap';
  }
  return (
    <MuiTableCell
      className={`${styles.root} ${className}`}
      style={style}
      component={th ? Th : undefined}
      {...props}
    />
  );
};

export const TableSortLabel = MuiTableSortLabel;

const useTablePaginationStyle = makeStyles<AITTheme>(theme => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.utils.getSpacing(2)
    }
  };
});
export type TablePaginationProps = { className?: string } & PaginationProps;
export const TablePagination = ({ className, ...props }: TablePaginationProps) => {
  const styles = useTablePaginationStyle();
  return (
    <div className={`${className} ${styles.root}`}>
      <Pagination {...props} />
    </div>
  );
};
