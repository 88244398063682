import React from 'react';

import { Table, Td, TdCenter, Th, Tr } from '@this/components/arrangement/search_flights/search_flight.style';
import type Flight from '@this/domain/flight/flight';

interface Props {
  flights: Flight[];
}
interface State {}

class FlightListBlock extends React.Component<Props, State> {
  private static flightList(flight: Flight, index: number) {
    return (
      <Tr key={index}>
        <TdCenter>{index + 1}</TdCenter>
        <TdCenter>{utils.formatPriceWithMark(flight.price)} 円</TdCenter>
        <Td>{flight.description}</Td>
        <Td>{flight.outword.segments[0].carrier_name}</Td>
        <Td>{flight.outword.duration}</Td>
        <Td>{flight.minirule}</Td>
        <Td>{flight.outword.deadline}</Td>
        <Td>{flight.outword.segments[0].cabin}</Td>
      </Tr>
    );
  }

  render() {
    return (
      <Table>
        <thead>
          <Tr>
            <Th width="3%">No</Th>
            <Th width="10%">金額</Th>
            <Th>詳細</Th>
            <Th width="10%">航空会社</Th>
            <Th width="10%">所用時間</Th>
            <Th width="12%">ルール</Th>
            <Th width="10%">deadline</Th>
            <Th width="5%">cabin</Th>
          </Tr>
        </thead>
        <tbody>
          {this.props.flights.map((flight: Flight, index: number) => FlightListBlock.flightList(flight, index))}
        </tbody>
      </Table>
    );
  }
}

export default FlightListBlock;
