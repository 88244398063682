import React from 'react';

export const ThumbsUpFillIcon: React.FC = () => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.833496 12.29H2.16683C2.5335 12.29 2.8335 11.99 2.8335 11.6234V5.62337C2.8335 5.25671 2.5335 4.95671 2.16683 4.95671H0.833496V12.29ZM14.0535 7.54337C14.1268 7.37671 14.1668 7.19671 14.1668 7.01004V6.29004C14.1668 5.55671 13.5668 4.95671 12.8335 4.95671H9.16683L9.78016 1.85671C9.8135 1.71004 9.7935 1.55004 9.72683 1.41671C9.5735 1.11671 9.38016 0.843372 9.14016 0.603372L8.8335 0.290039L4.56016 4.56337C4.30683 4.81671 4.16683 5.15671 4.16683 5.51004V10.7367C4.16683 11.59 4.86683 12.29 5.72683 12.29H11.1335C11.6002 12.29 12.0402 12.0434 12.2802 11.6434L14.0535 7.54337Z"
        fill="#B0995E"
      />
    </svg>
  );
};
