import React from 'react';
import { Slide } from 'react-toastify';

// Globalに展開されたToastContainerを使用しないとトーストが表示されないため。
/* eslint-disable react/jsx-no-undef */
const Root = (Component: React.ComponentType<any>, props: any) => {
  return (
    <>
      <Component {...props} />
      <ToastContainer transition={Slide} />
    </>
  );
};
export default Root;
/* eslint-enable react/jsx-no-undef */
