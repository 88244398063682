import type React from 'react';
import type { Bar } from 'react-chartjs-2';
import type { ApprovalsLeadtimeType } from '@this/components/organization/dashboard/share/index';
import type { ColorTheme } from './graph';
import { BORDER_STYLES, COLOR_THEMES } from './graph';

const LEADTIME_RANGE_LABELS: Record<ApprovalsLeadtimeType, string> = {
  unknown: '不明',
  short: '1日以内',
  normal: '1日〜3日',
  long: '3日〜2週間',
  too_long: '2週間より長い'
};

export interface BarLeadtime {
  key: ApprovalsLeadtimeType;
  count: number;
  price?: number;
}

export const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const value = tooltipItem.raw as number;
          return tooltipItem.datasetIndex === 0
            ? `${value.toLocaleString()}件`
            : utils.formatPrice(Math.round(value));
        }
      }
    }
  }
};

export const getBarDataLeadtimes = (leadtimes: BarLeadtime[], theme: ColorTheme = 'default') => {
  const labels = leadtimes.map(({ key }) => LEADTIME_RANGE_LABELS[key]);
  const counts = leadtimes.map(({ count }) => count);
  const prices = leadtimes.map(({ price }) => price ?? 0);
  const usePrices = prices.some(value => value > 0);
  const colorTheme = COLOR_THEMES[theme];
  const borderStyle = BORDER_STYLES[theme];

  const options: React.ComponentProps<typeof Bar>['options'] = {
    scales: {
      yAxesCounts: {
        stacked: false,
        position: 'left'
      },
      xAxesCounts: {
        grid: { display: false }
      }
    }
  };

  const datasets: React.ComponentProps<typeof Bar>['data']['datasets'] = [
    {
      type: 'bar',
      yAxisID: 'yAxesCounts',
      label: '旅程数',
      data: counts,
      backgroundColor: [
        colorTheme.blue.backgroundColor,
        colorTheme.green.backgroundColor,
        colorTheme.yellow.backgroundColor,
        colorTheme.red.backgroundColor
      ],
      ...borderStyle
    }
  ];

  if (usePrices) {
    Object.assign(options.scales ?? {}, {
      yAxesPrices: {
        stacked: false,
        position: 'right'
      }
    });

    datasets.push({
      type: 'bar',
      yAxisID: 'yAxesPrices',
      label: '相場',
      data: prices,
      ...colorTheme.green,
      ...borderStyle
    });
  }

  return { data: { labels, datasets }, options };
};
