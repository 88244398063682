import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Popover, FormControlLabel, Checkbox, FormGroup, Box, FormLabel } from '@material-ui/core';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobType, OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/domain/order_item/order_item_status';
import { OrderItemStatus, OrderItemStatusAvailable } from '@this/domain/order_item/order_item_status';

interface Props {
  selectedOrderItemJobTypes: OrderItemJobTypeKey[];
  onChangeSelectedOrderItemJobTypes: (jobTypes: OrderItemJobTypeKey[]) => void;
  selectedOrderItemStatuses: OrderItemStatusKey[];
  onChangeSelectedOrderItemStatuses: (statuses: OrderItemStatusKey[]) => void;
}

const OrderItemStatusSelector = observer(
  ({
    selectedOrderItemJobTypes,
    onChangeSelectedOrderItemJobTypes,
    selectedOrderItemStatuses,
    onChangeSelectedOrderItemStatuses
  }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const handleClickOrderItemFilterButton = (e: React.MouseEvent) => {
      setAnchorEl(e.currentTarget);
    };

    const handleCloseOrderItemFilter = () => {
      setAnchorEl(null);
    };

    const handleSelectAllOrderItemJobTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
      const jobTypes = e.target.checked ? OrderItemJobTypeNumKeys : [];
      onChangeSelectedOrderItemJobTypes(jobTypes);
    };

    const handleSelectAllOrderItemStatuses = (e: React.ChangeEvent<HTMLInputElement>) => {
      const statuses = e.target.checked ? OrderItemStatusAvailable : [];
      onChangeSelectedOrderItemStatuses(statuses as OrderItemStatusKey[]);
    };

    const handleSelectOrderItemJobType = (
      e: React.ChangeEvent<HTMLInputElement>,
      jobType: OrderItemJobTypeKey
    ) => {
      const jobTypes = e.target.checked
        ? selectedOrderItemJobTypes.concat([jobType])
        : _.without(selectedOrderItemJobTypes, jobType);
      onChangeSelectedOrderItemJobTypes(jobTypes);
    };

    const handleSelectOrderItemStatus = (e: React.ChangeEvent<HTMLInputElement>, status: number) => {
      const statuses = e.target.checked
        ? selectedOrderItemStatuses.concat([status as OrderItemStatusKey])
        : _.without(selectedOrderItemStatuses, status);
      onChangeSelectedOrderItemStatuses(statuses as OrderItemStatusKey[]);
    };

    return (
      <>
        <Button
          size="small"
          variant="outlined"
          onClick={handleClickOrderItemFilterButton}
          style={{ backgroundColor: 'white' }}
        >
          絞り込み
        </Button>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseOrderItemFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box display="flex">
            <FormGroup style={{ padding: '5px' }}>
              <FormLabel>ジョブタイプ</FormLabel>
              <FormControlLabel
                label="すべて選択"
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedOrderItemJobTypes.length === OrderItemJobTypeNumKeys.length}
                    indeterminate={
                      selectedOrderItemStatuses.length !== 0 &&
                      selectedOrderItemStatuses.length !== OrderItemStatusAvailable.length
                    }
                    onChange={handleSelectAllOrderItemJobTypes}
                  />
                }
              />
              {OrderItemJobTypeNumKeys.map(key => (
                <FormControlLabel
                  key={key}
                  label={OrderItemJobType[key]}
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedOrderItemJobTypes.includes(key)}
                      onChange={e => {
                        handleSelectOrderItemJobType(e, key);
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
            <FormGroup style={{ padding: '5px' }}>
              <FormLabel>ステータス</FormLabel>
              <FormControlLabel
                label="すべて選択"
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedOrderItemStatuses.length === OrderItemStatusAvailable.length}
                    indeterminate={
                      selectedOrderItemStatuses.length !== 0 &&
                      selectedOrderItemStatuses.length !== OrderItemStatusAvailable.length
                    }
                    onChange={handleSelectAllOrderItemStatuses}
                  />
                }
              />
              {OrderItemStatusAvailable.map(key => (
                <FormControlLabel
                  key={key}
                  label={OrderItemStatus[key]}
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedOrderItemStatuses.includes(key)}
                      onChange={e => {
                        handleSelectOrderItemStatus(e, key);
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </Popover>
      </>
    );
  }
);

export default OrderItemStatusSelector;
