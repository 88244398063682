import type { Themes } from '@this/constants/themes';
import { styled } from '@this/constants/themes';
import type { ThemeProps } from 'styled-components';

interface Props extends ThemeProps<Themes> {
  width?: string;
}

export const SearchArea = styled.div`
  margin: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: ${(props: Props) => props.theme.grayColorLight2};
  border: solid 1px #878787;
`;

export const ResultArea = styled.div`
  margin: 10px;
`;

export const SearchFlightsInputArea = styled.div`
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Required = styled.span`
  color: #ff0000;
  font-size: 10px;
`;

export const SearchButton = styled.div`
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const Result = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const Errors = styled.div`
  color: ${(props: Props) => props.theme.redColor};
`;

export const Table = styled.table`
  margin: 10px;
  table-layout: auto;
  border: solid 1px #878787;
  border-collapse: collapse;
  cellpadding: 10px;
`;

export const Th = styled.th`
  background: ${(props: Props) => props.theme.grayColorLight2};
  font-size: 14px;
  color: #767676;
  text-align: center;
  border: solid 1px #878787;
  ${(props: Props) => props.width && `width: ${props.width}`}
`;

export const Tr = styled.tr`
  &:hover {
    background: ${(props: Props) => props.theme.grayColorLight2};
  }
`;

export const Td = styled.td`
  font-size: 12px;
  color: #000000;
  border: solid 1px #878787;
  padding: 10px;
`;

export const TdCenter = styled(Td)`
  text-align: center !important;
`;

export const Error = styled.div`
  color: ${(props: Props) => props.theme.redColor};
`;
