import React, { useCallback, useContext, useEffect, useState } from 'react';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Tooltip from '@this/shared/tooltip/tooltip';
import jsonPromiseContext from '@this/src/components/constants/json_promise_context';
import { styled } from '@this/constants/themes';
import type { DashboardProps } from '../../share';
import type { UserCost } from '../../share/cost_saving_list';
import CostSavingList from '../../share/cost_saving_list';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// 出張費節約ランキングセクションV2：元々使用されていたダッシュボードを速度調整したもの
// Usecase::Organization::DashboardTrips::CostRankingPatterns::CachePatternUsecase を使用
const USECASE = { section: 'cost_saving_ranking', pattern: 'cache' };

interface State {
  loading: boolean;
  userCosts: UserCost[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  user_costs: State['userCosts'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    userCosts: result.user_costs,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardCostRankingSection: React.FC<DashboardProps> = props => {
  const jsonPromise = useContext(jsonPromiseContext);

  const [state, setState] = useState<State>({
    loading: true,
    userCosts: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, userCosts, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    fetchSummary({ setState, jsonPromise, ...props });
  }, [type, from, to, refreshAt, props.refreshAt]);

  return (
    <>
      <div className="organization-trips__bordered-area__header">
        <Wrap className="graph_title">
          出張費節約ランキング
          <Tooltip>
            <p>
              手配依頼日で指定された期間内の旅程を対象に、旅費規程よりも安く出張している累計の上位１０名を表示。
            </p>
            <p>
              ※現状はホテルのみを対象としています。
              <br />
              ※航空券など、機能を今後拡張予定です。
            </p>
          </Tooltip>
        </Wrap>
      </div>
      {loading ? <SimpleLoading /> : <CostSavingList userCosts={userCosts} />}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

export const Wrap = styled.h3`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export default DashboardCostRankingSection;
