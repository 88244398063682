export interface Args {
  id: number;
  organization_id: number;
  name: string;
  address: never;
  postal_code: string;
  addressee: string;
  google_map_address?: string | null;
}

export class OrganizationBase {
  id: number;

  organizationId: number;

  name: string;

  address: never;

  postalCode: string;

  addressee: string;

  googleMapAddress?: string | null;

  constructor(args: Args) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.name = args.name;
    this.address = args.address;
    this.postalCode = args.postal_code;
    this.addressee = args.addressee;
    this.googleMapAddress = args.google_map_address;
  }

  description() {
    if (!this.address) return `${this.name}：住所が登録されていません`;
    return `${this.name}：${this.postalCode || ''} ${this.address} ${this.addressee || ''}`;
  }
}

export default OrganizationBase;
