import React from 'react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { BudgetStore } from '@this/src/domain/organization/budget_store';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import YearMonthSelector from '../../shared/year_month_selector/year_month_selector';
import { BudgetItemsForm } from './budget_items_form';

type Props = {
  store: BudgetStore;
};

export const BudgetsForm: React.FC<Props> = ({ store }) => {
  const { budgets, loading } = store;
  const validPeriods = store.validPeriods();

  return (
    <>
      <TitleRow>
        <h3>予算の設定</h3>
        <Button onClick={() => store.addBudget()}>予算期間追加</Button>
      </TitleRow>
      {loading ? (
        <div>
          <SimpleLoading />
        </div>
      ) : (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>期間</TableCell>
                <TableCell>全体予算</TableCell>
                <TableCell>消化予算</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {budgets.length < 1 && (
                <TableRow>
                  <TableCell colSpan={4}>予算期間は設定されていません</TableCell>
                </TableRow>
              )}
              {budgets.map((budget, i) =>
                budget.editing ? (
                  <React.Fragment key={`budget-${i}`}>
                    <TableEditingRow>
                      <TableYearMonthCell>
                        <YearMonthSelector date={budget.fromTime} onChange={value => budget.setFromTime(value)} />
                        <Rotate>～</Rotate>
                        <YearMonthSelector
                          date={budget.toTime}
                          onChange={value => budget.setField('toTime', value)}
                          futureYear={3}
                        />
                        {validPeriods[i] && <Error>{validPeriods[i]}</Error>}
                      </TableYearMonthCell>
                      <TableCell>
                        <input
                          type="number"
                          defaultValue={budget.totalBudget}
                          onChange={e => budget.setField('totalBudget', parseInt(e.target.value, 10) || 0)}
                        />
                        {budget.validTotalBudget() ? (
                          <Error>{budget.validTotalBudget()}</Error>
                        ) : (
                          `（${budget.getTotalBudget()}）`
                        )}
                      </TableCell>
                      <TableCell>{budget.getUsedBudgets()}</TableCell>
                      <TableCell>
                        <Actions>
                          <Button size="small" onClick={() => budget.setField('editing', false)}>
                            登録
                          </Button>
                        </Actions>
                      </TableCell>
                    </TableEditingRow>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <ItemWrap>
                          <BudgetItemsForm budget={budget} index={i} />
                        </ItemWrap>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ) : (
                  <TableRow key={`budget-${i}`}>
                    <TableCell>
                      {budget.getPeriod()}
                      {validPeriods[i] && <Error>{validPeriods[i]}</Error>}
                    </TableCell>
                    <TableCell>
                      {budget.getTotalBudget()}
                      {budget.validTotalBudget() && <Error>{budget.validTotalBudget()}</Error>}
                    </TableCell>
                    <TableCell>{budget.getUsedBudgets()}</TableCell>
                    <TableCell>
                      <Actions>
                        <Button size="small" onClick={() => budget.setField('editing', true)}>
                          編集
                        </Button>
                        <IconButton color="primary" size="small" onClick={() => store.removeBudget(i)}>
                          <CancelOutlined />
                        </IconButton>
                      </Actions>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  & > h3 {
    margin: 0;
  }
`;

const TableEditingRow = styled(TableRow)`
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;

const TableYearMonthCell = styled(TableCell)`
  .flex {
    gap: 4px 8px;

    select {
      margin-bottom: 0;
    }
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
  font-size: 0.9em;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Rotate = styled.div`
  text-align: center;
  rotate: 90deg;
`;

const ItemWrap = styled.div`
  background-color: #fff;
  border: solid 2px ${props => props.theme.grayBorderColor};
  padding: 8px;
  margin-left: -8px;
  margin-right: -8px;
`;
