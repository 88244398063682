import React from 'react';
import { styled } from '@this/constants/themes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import UserSignIn from '@this/components/user_signin';
import CoreApp, { CoreAppPaths } from '@this/components/core_app/core_app';
import StaticPages, { StaticPagePaths } from '@this/components/static_pages/static_pages';
import God from '@this/components/god/god';
import MarketLogClass from '@this/components/market_log/market_log';
import ReserveConfirmMarketLogClass from '@this/components/market_log/reserve_confirm_market_log';
import NotFound from '@this/components/not_found/not_found';
import type User from '@this/domain/user/user';

interface Props {
  serviceId: number;
  user?: User;
  env?: string;
}

interface State {}

class AITravel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    try {
      return (
        <ThemeWrapper>
          <Router>
            <Switch>
              {/* TODO: UserSignInもCoreAppに入れてrouter遷移したいけどヘッダーが違う */}
              <Route
                exact
                path="/users/sign_in"
                render={props => <UserSignIn {...this.props} {...props} {...this.state} />}
              />
              {/* market_logはヘッダーが異なるため、CoreAppとは分けている */}
              <Route
                path="/trips/:id/market_log"
                render={props => <MarketLogClass {...this.props} {...props} {...this.state} />}
              />
              <Route
                path="/market_logs/:id"
                render={props => <ReserveConfirmMarketLogClass {...this.props} {...props} {...this.state} />}
              />
              <Route
                path={CoreAppPaths}
                render={props => <CoreApp {...this.props} {...props} {...this.state} />}
              />
              <Route
                path={StaticPagePaths}
                render={props => <StaticPages {...this.props} {...props} {...this.state} />}
              />
              <Route path="/god" render={props => <God {...this.props} {...props} {...this.state} />} />
              <Route render={props => <NotFound {...this.props} {...props} {...this.state} />} />
            </Switch>
          </Router>
        </ThemeWrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ThemeWrapper = styled.div`
  background: ${props => props.theme.grayBgColor};
  display: flex;
  flex-direction: column;
  flex-grow: 9999;
`;

export default AITravel;
