export interface TransportElementAttachFileArgs {
  id: number;
  tripId: number;
  uploadFileName: string;
  contentType: string;
  filePath: string;
}

export default class TransportElementAttachFile {
  id: number;

  tripId: number;

  uploadFileName: string;

  contentType: string;

  filePath: string;

  constructor(args: TransportElementAttachFileArgs) {
    this.id = args.id;
    this.tripId = args.tripId;
    this.uploadFileName = args.uploadFileName;
    this.contentType = args.contentType;
    this.filePath = args.filePath;
  }

  updateParams() {
    return {
      trip_id: this.tripId,
      upload_file_name: this.uploadFileName,
      content_type: this.contentType,
      file_path: this.filePath
    };
  }
}
