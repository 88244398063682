import React from 'react';
import { styled } from '@this/constants/themes';

import type { BulkActionAttr } from '@this/components/organization/types';

interface Props {
  formats: BulkActionAttr[];
  expenses?: boolean;
}
export default class FileFormat extends React.Component<Props> {
  render() {
    const { formats, expenses } = this.props;
    return (
      <table className=".organization__csv-format-table">
        <thead>
          <tr>
            <TheTh expenses={expenses} className="row-name">
              項目
            </TheTh>
            <TheTh expenses={expenses} className="summary">
              項目内容
            </TheTh>
            <TheTh expenses={expenses} className="example">
              サンプル
            </TheTh>
          </tr>
        </thead>
        <tbody>
          {formats.map((f, i) => (
            <tr key={i}>
              <td className="row-name">
                {f.name}
                {f.required && <Red>(必須)</Red>}
              </td>
              <td className="summary">{f.summary}</td>
              <td className="example">
                <Pre>{f.example}</Pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const TheTh = styled.th<{ expenses: boolean | undefined }>`
  ${props =>
    props.expenses &&
    `
    background: #f1ede5;
    border: 0;
    color: #323232;
    font-size: 14px;
    line-height: 1;
    padding: 7px 7px 8px;
  `}
`;

const Red = styled.span`
  color: ${props => props.theme.redColor};
`;

const Pre = styled.p`
  white-space: pre-wrap;
`;
