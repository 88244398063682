import React from 'react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import ContentBody, { ContentBodyIn } from '@this/components/shared/atoms/content_body';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Trip from '@this/domain/trip/trip';
import InAdvanceApplicationCardTemplate from './in_advance_application_card/in_advance_application_card.template';
import InAdvanceApplicationFilter from './in_advance_application_filter/in_advance_application_filter';
import InAdvanceHeader from './in_advance_header';
import TripAdvanceApplicationFilter from './in_advance_application_filter/trip_advance_application_filter';

interface Props extends RouteComponentProps {
  availableOptions: string[];
}

interface State {
  applyingTrips: any[];
  loading: boolean;
  error: string | null;
  tripReportAvailable: boolean;
}

interface FetchTripsResult {
  user: any;
  trips: any[];
  show_fee: boolean;
}

class InAdvanceApplications extends React.Component<Props, State> {
  filter: React.RefObject<InAdvanceApplicationFilter>;

  constructor(props: Props) {
    super(props);

    this.filter = React.createRef();

    this.state = {
      applyingTrips: [],
      loading: false,
      error: null,
      tripReportAvailable: props.availableOptions.includes('trip_report')
    };
  }

  componentDidMount() {
    const query = this.filter.current!.searchQuery();
    this.fetchTrips(query);
  }

  static fetchEndTime(trip: any) {
    return utils.dig(trip, 'endTime') || trip.order.endDate() || trip.return_time;
  }

  handleSearch = (query: any) => {
    this.fetchTrips(query);
  };

  private async fetchTrips(query = {}) {
    this.setState({
      loading: true,
      error: null
    });
    try {
      const res = await utils.jsonPromise<FetchTripsResult>('/application/in_advance.json', query);
      this.setState({
        loading: false,
        applyingTrips: _.map(
          res.trips,
          t => new Trip(_.merge(t, { showFee: res.show_fee, currentUser: res.user }))
        )
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: '通信に失敗しました。しばらくしてから再度お試しください。'
      });
      utils.sendErrorObject(e);
    }
  }

  render() {
    try {
      const { applyingTrips, loading, error, tripReportAvailable } = this.state;
      return (
        <ApplicationsWrap>
          <Applications tripReportAvailable={tripReportAvailable}>
            <ApplicationsIn>
              {tripReportAvailable && <InAdvanceHeader selected="trip" tripReportAvailable />}
              <Body>
                <ContentBodyIn>
                  {tripReportAvailable ? (
                    <>
                      <PageTitle>出張前の申請</PageTitle>
                      <TripAdvanceApplicationFilter onSearch={this.handleSearch} filterRef={this.filter} />
                    </>
                  ) : (
                    <>
                      <Title>承認</Title>
                      <InAdvanceApplicationFilter onSearch={this.handleSearch} ref={this.filter} />
                    </>
                  )}
                  {loading ? (
                    <SimpleLoading />
                  ) : error ? (
                    <Error>{error}</Error>
                  ) : (
                    <Section>
                      {!applyingTrips || applyingTrips.length === 0 ? (
                        <div>対象の承認依頼はありません</div>
                      ) : (
                        <>
                          {applyingTrips.map(trip => (
                            <InAdvanceApplicationCardTemplate
                              key={trip.id}
                              trip={trip}
                              approver={trip.currentApprovedApprover(trip.currentApprover(trip.currentUser.id))}
                              currentStatus={trip.approvalStatus(trip.in_advance_approval.length - 1)}
                              endTime={InAdvanceApplications.fetchEndTime(trip)}
                            />
                          ))}
                        </>
                      )}
                    </Section>
                  )}
                </ContentBodyIn>
              </Body>
            </ApplicationsIn>
          </Applications>
        </ApplicationsWrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ApplicationsWrap = styled.div`
  min-height: 100vh;
`;

const Applications = styled.div<{ tripReportAvailable: boolean }>`
  padding: 20px 0;
  display: block;

  ${props =>
    props.tripReportAvailable &&
    `
    padding-top: 0;
  `}
`;

const ApplicationsIn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Section = styled.div``;

const Title = styled.div`
  border-bottom: 2px solid ${props => props.theme.grayBorderColor};
  padding: 5px 0;
  margin-bottom: 20px;
  font-weight: bold;
`;

const PageTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export default InAdvanceApplications;
