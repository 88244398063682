import React from 'react';

import { styled } from '@this/constants/themes';
import { Popover } from '@material-ui/core';
import { Link } from '@this/shared/ui/navigations/link';

type Props = {
  children: React.ReactNode;
  tripIds?: number[];
};

export const ArrangementReportsPopup = ({ children, tripIds = [] }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  if (tripIds.length === 0) {
    return <p>{children}</p>;
  }

  return (
    <>
      <a onClick={e => setAnchorEl(e.target as HTMLElement)}>{children}</a>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: -8,
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverContent>
          {tripIds.map(id => (
            <li key={id}>
              <Link href={`/arrangement/virtual_counter#${id}`} target="_blank">
                {id}
              </Link>
            </li>
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
};

const PopoverContent = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 4px;
  width: 80px;
  max-height: 200px;
`;
