import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import OrderItemSupplierItemSelectList from './order_item_supplier_item_select_list';

interface Props {
  suppliedItems: SuppliedItem[];
  suppliedItemId: number | null;
  onSelect: (i: number) => void;
}
interface State {
  showList: boolean;
  suppliedItem?: SuppliedItem | null;
}

const OrderItemSupplierItemSelect: React.FC<Props> = ({ suppliedItems, suppliedItemId, onSelect }) => {
  const suppliedItem = _.find(suppliedItems, s => s.id === suppliedItemId);
  const [state, setState] = useState<State>({
    showList: false,
    suppliedItem
  });

  const handleSelect = (selected: number) => {
    const newState = { ...state };
    newState.showList = false;
    setState(newState);
    onSelect(selected);
  };

  const handleToggleShowList = () => {
    const newState = { ...state };
    newState.showList = !state.showList;
    setState(newState);
  };

  const description = (s: SuppliedItem): string => {
    return `(${s.supplierCode})${s.supplierName} (${s.code})${s.name}`;
  };

  return (
    <SelectSearch>
      <SuppleidItemText onClick={() => handleToggleShowList()}>
        {suppliedItem ? description(suppliedItem) : ''}
      </SuppleidItemText>
      {state.showList && (
        <OrderItemSupplierItemSelectList
          self={suppliedItem}
          onSelect={id => handleSelect(id)}
          suppliedItems={suppliedItems}
        />
      )}
    </SelectSearch>
  );
};

const SelectSearch = styled.div`
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 10px;
  -webkit-box-flex: 50;
  flex-grow: 50;
  min-width: 120px;
`;

const SuppleidItemText = styled.p`
  min-height: 20px;
  background: ${props => props.theme.fieldBgColor};
  line-height: 20px;
  padding: 0 10px;
  cursor: pointer;
  border: 2px solid #eee;
`;

export default OrderItemSupplierItemSelect;
