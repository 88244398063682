import React from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import { ButtonBase } from '@this/shared/atoms/button';

import SubInfo from '@this/shared/sub_info/sub_info';

import DateSelector from '@this/shared/date_selector/date_selector';
import moment from 'moment-timezone';
import ExpenseForm from '../expense_form/expense_form';

interface Props {
  user: any;
  trip: any;
  report: any;
  expenseTypes: any;
  projects: any;
  onConfirm: () => void;
  withSubmitButton?: boolean | null;
}

interface State {
  withSubmitButton: boolean;
  errors: [] | null;
}

class TripReportForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      withSubmitButton: typeof props.withSubmitButton === 'boolean' ? props.withSubmitButton : true,
      errors: null
    };

    this.handleDepartureDateChange = this.handleDepartureDateChange.bind(this);
    this.handleReturnDateChange = this.handleReturnDateChange.bind(this);
  }

  handleChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    this.props.report[name](e.target.value);
  }

  handleTextAreaChange(name: string, e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    this.props.report[name](e.target.value);
  }

  handleDepartureDateChange(date: any) {
    this.props.report.setDepartureDate(date);
  }

  handleReturnDateChange(date: any) {
    this.props.report.setReturnDate(date);
  }

  handleAddExpense(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.report.addExpense();
  }

  handleConfirm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.props.report.errors() && this.props.report.errors().length > 0) {
      this.setState({ errors: this.props.report.errors() });
    } else {
      this.setState({ errors: null });
      this.props.onConfirm();
    }
  }

  render() {
    try {
      const { user, report, expenseTypes, projects } = this.props;
      const { errors, withSubmitButton } = this.state;
      return (
        <Form onSubmit={e => this.handleConfirm(e)}>
          <Section>
            <Title>旅程情報</Title>
            <p>
              部署：<span data-wovn-ignore>{user.department.name}</span>
              <br />
              氏名：<span data-wovn-ignore>{user.name}</span>
            </p>
            <Label>出発地</Label>
            <Input type="text" value={report.origin} onChange={e => this.handleChange('setOrigin', e)} />
            <Label>目的地</Label>
            <Input type="text" value={report.destination} onChange={e => this.handleChange('setDestination', e)} />
            <Label>期間</Label>
            <Flex>
              <DateSelector
                date={report.departureDate ? moment(report.departureDate) : moment()}
                onDateChange={this.handleDepartureDateChange}
              />
              <div style={{ margin: '6px 10px' }}>〜</div>
              <DateSelector
                date={report.returnDate ? moment(report.returnDate) : moment()}
                onDateChange={this.handleReturnDateChange}
              />
            </Flex>
          </Section>
          <Section>
            <Title>経費一覧</Title>
            {report.expenseList.list().map((expense: any, i: number) => (
              <ExpenseForm
                key={i}
                index={i}
                report={report}
                expense={expense}
                types={expenseTypes}
                projects={projects}
              />
            ))}
            <AddExpense onClick={e => this.handleAddExpense(e)}>+経費を追加</AddExpense>
          </Section>
          <Section>
            <Title>経費集計</Title>
            <SubInfo data={report.typeSummary()} />
          </Section>
          <Section>
            <Title>出張先（任意）</Title>
            <TextArea
              value={report.finalDestination}
              onChange={e => this.handleTextAreaChange('setFinalDestination', e)}
            />
          </Section>
          <Section>
            <Title>出張の目的</Title>
            <TextArea value={report.purpose} onChange={e => this.handleTextAreaChange('setPurpose', e)} />
          </Section>
          <Section>
            <Title>成果報告・備考</Title>
            <TextArea value={report.result} onChange={e => this.handleTextAreaChange('setResult', e)} />
          </Section>
          {errors && errors.length > 0 && errors.map((e: string, i: number) => <Error key={i}>{e}</Error>)}
          {withSubmitButton && <Submit type="submit" value="確認" />}
        </Form>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Form = styled.form``;

export const Section = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h3`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: normal;
  margin-top: 20px;
`;

export const Input = styled.input`
  &&&:focus {
    box-shadow: none;
    border-color: ${props => props.theme.linkColor};
  }
`;

const Flex = styled.div`
  display: flex;
`;

const AddExpense = styled(A)`
  display: block;
`;

export const TextArea = styled.textarea`
  &&&:focus {
    box-shadow: none;
    border-color: ${props => props.theme.linkColor};
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

export const Submit = styled.input`
  &&& {
    ${ButtonBase}
    margin-top: 10px;
  }
`;

export default TripReportForm;
