import React from 'react';
import { BodyWrap, Wrap } from '../shared/wrappers';
import { TripReportFormHeader } from '../trip_report_form/trip_report_form_header';
import { TripReportFormFooter } from '../trip_report_form/trip_report_form_footer';
import { TripReportFormBody } from './trip_report_form_body';

export const TripReportForm: React.FC = () => (
  <Wrap>
    <BodyWrap>
      <TripReportFormHeader />
      <TripReportFormBody />
      <TripReportFormFooter />
    </BodyWrap>
  </Wrap>
);
