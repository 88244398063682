/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import type Hotel from '@this/domain/hotel/hotel';
import type HotelList from '@this/domain/hotel/hotel_list';
import { styled } from '@this/constants/themes';
import HotelMap from '@this/shared/hotel_map/hotel_map';
import TranslateIgnoreText from '@this/shared/text/translate_ignore_text';
import {
  Amenities,
  AmenityTitle,
  DetailBoxSmall,
  DetailBoxTitle,
  Error,
  Li,
  PriceSoldout,
  RoomTypeBox,
  Ul
} from '@this/components/hotel/hotel_pc.template';
import Text from '@this/shared/text/text';
import A from '@this/shared/atoms/a';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import MarginType from '@this/domain/organization/margin_type2';
import _ from 'lodash';
import type { HotelResponse } from '@this/components/reserve_trip/select/hotel_box/hotel_candidate_box';
import type HotelStayPlan from '@this/domain/hotel/hotel_stay_plan';
import type { HotelJson } from '@this/domain/select_repository';
import type { HotelBedType } from '@this/domain/hotel/hotel';
import type User from '@this/domain/user/user';

interface Props {
  destLocation?: { lat: number; lng: number } | null;
  me?: User | null;
  user: User;
  hotel: Hotel | null;
  hotels: HotelList;
  showModal: boolean;
  searchQueryId: number | null;
  serviceId: number | undefined;
  handleChangeHotelStayPlan: (
    hotel: HotelJson,
    selectedPlan: HotelStayPlan,
    showFee: boolean,
    marginType: MarginType | undefined
  ) => void;
  submitButtonLabel: string | null | undefined;
  onClose: () => void;
}

const HotelDetailModalSpTemplate = observer(
  ({
    destLocation,
    me,
    user,
    hotel,
    hotels,
    showModal,
    searchQueryId,
    serviceId,
    handleChangeHotelStayPlan,
    submitButtonLabel,
    onClose
  }: Props) => {
    const [showMorePropertyAmenities, setShowMorePropertyAmenities] = useState<boolean>(false);
    const [showMoreRoomAmenities, setShowMoreRoomAmenities] = useState<boolean>(false);
    const [selectedStayPlan, setSelectedStayPlan] = useState<HotelStayPlan | null>(null);

    useEffect(() => {
      if (hotel) {
        const stayPlan = hotel.stay_plans.find(s => s.id === hotel.selected_stay_plan_id) || null;
        setSelectedStayPlan(stayPlan);
      }
    }, [hotel]);

    const handleImageHover = (i: number) => () => {
      if (hotel) {
        return hotel.selectImage(i);
      }
      return undefined;
    };

    const togglePropertyAmenities = () => {
      setShowMorePropertyAmenities(!showMorePropertyAmenities);
    };

    const getPropertyAmenities = () => {
      const amenities = hotel && hotel.propertyAmenities();
      if (showMorePropertyAmenities) {
        return amenities || [];
      }
      return (amenities && amenities.slice(0, 10)) || [];
    };

    const toggleRoomAmenities = () => {
      setShowMoreRoomAmenities(!showMoreRoomAmenities);
    };
    const getRoomAmenities = () => {
      const amenities = hotel && hotel.roomAmenities();
      if (showMoreRoomAmenities) {
        return amenities || [];
      }

      return (amenities && amenities.slice(0, 10)) || [];
    };

    const handleClickStayPlan = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (hotel) {
        hotel.selected_stay_plan_id = e.target.value;
        const stayPlan = hotel.stay_plans.find(s => s.id === hotel.selected_stay_plan_id) || null;
        setSelectedStayPlan(stayPlan);
      }
      return undefined;
    };

    const handleChangeStayPlan = () => {
      if (hotel) {
        onClose();
        hotel.setHotelLoading(true);
        if (selectedStayPlan) {
          try {
            utils
              .jsonPromise<HotelResponse>(
                `/hotels/${hotel.id}/change_stay_plan`,
                { stay_plan_id: selectedStayPlan.id },
                'put'
              )
              .then(result => {
                let marginType: MarginType | undefined;
                if (result.margin_type) {
                  marginType = new MarginType(result.margin_type);
                }

                handleChangeHotelStayPlan(result.hotel, selectedStayPlan, result.show_fee, marginType);
              });
          } catch (e) {
            utils.sendErrorObject(e);
          } finally {
            hotel.setHotelLoading(false);
          }
        }
      }
      return undefined;
    };

    return (
      <Modal open={showModal} onClose={onClose} size="large">
        <ModalHeader>プラン選択</ModalHeader>
        <ModalBody>
          {hotel == null ? (
            <LoadingSpan>
              <div data-wovn-ignore className="cssloader">
                Loading...
              </div>
            </LoadingSpan>
          ) : (
            <>
              <TopArea key={0}>
                <div>
                  <Title data-wovn-ignore>{hotel.name}</Title>
                  <Address data-wovn-ignore>{hotel.address}</Address>
                </div>
                {hotel.sold_out && <Soldout>※たった今他の方が予約されたため、空室がなくなりました。</Soldout>}
              </TopArea>
              <BodyArea key={1}>
                <BodyAreaIn>
                  <Row>
                    <MainImageArea>
                      <MainImage style={{ backgroundImage: `url(${hotel.currentImage()})` }} />
                      <MainThumbs>
                        {hotel.images?.slice(0, 26).map((image, i) => (
                          <MainThumb
                            key={i}
                            selected={hotel.currentImage() === image}
                            style={{ backgroundImage: `url(${image})` }}
                            onMouseOver={handleImageHover(i)}
                          />
                        ))}
                      </MainThumbs>
                    </MainImageArea>
                  </Row>
                  <Row>
                    <MapArea>
                      {destLocation && (
                        <HotelMap
                          hotel={hotel}
                          destLocation={destLocation}
                          serviceId={serviceId}
                          searchQueryId={searchQueryId}
                        />
                      )}
                      <InfoArea>
                        <table className="key-value-table">
                          <tbody>
                            <tr>
                              <td className="key-value-table__label">目的地から</td>
                              <td className="key-value-table__separator">：</td>
                              <td data-wovn-ignore className="key-value-table__value">
                                {hotel.walkMinuteText()}
                              </td>
                            </tr>
                            <tr>
                              <td className="key-value-table__label">最寄り駅から</td>
                              <td className="key-value-table__separator">：</td>
                              <td data-wovn-ignore className="key-value-table__value">
                                {hotel.stationDistanceText()}
                              </td>
                            </tr>
                            <tr>
                              <td className="key-value-table__label">チェックイン</td>
                              <td className="key-value-table__separator">：</td>
                              <td data-wovn-ignore className="key-value-table__value">
                                {hotel.checkinText()}
                              </td>
                            </tr>
                            <tr>
                              <td className="key-value-table__label">チェックアウト</td>
                              <td className="key-value-table__separator">：</td>
                              <td data-wovn-ignore className="key-value-table__value">
                                {hotel.checkoutText()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <PriceArea>
                          {hotel.sold_out ? (
                            <PriceSoldout>空室なし</PriceSoldout>
                          ) : (
                            <>
                              <Price key={0}>
                                <span>ホテル料金合計：</span>
                                <span data-wovn-ignore>{utils.digits(hotel.totalPrice())}</span>
                                <span>円</span>
                              </Price>
                              <TranslateIgnoreText text={hotel.priceDetailText()} />
                              {hotel.showCancelStatus() && <Error>{`※キャンセル：${hotel.refund}`}</Error>}
                            </>
                          )}
                        </PriceArea>
                      </InfoArea>
                    </MapArea>
                  </Row>
                  {selectedStayPlan !== null && (
                    <Row>
                      <SelectedStayPlanBox>
                        <RowTitle>選択中のプラン</RowTitle>
                        <SelectedStayPlanContentArea>
                          <StayPlanName>{selectedStayPlan.name || selectedStayPlan.room_type || '-'}</StayPlanName>
                          <StayPlanAmenities>
                            <StayPlanSmokeType>{selectedStayPlan.smoke ? '喫煙' : '禁煙'}</StayPlanSmokeType>
                            {selectedStayPlan.bed_types && selectedStayPlan.bed_types.length > 0 ? (
                              <RoomBedTypes>
                                <ul>
                                  {selectedStayPlan.bed_types.map((b, i) => (
                                    <li key={i}>{b.description}</li>
                                  ))}
                                </ul>
                              </RoomBedTypes>
                            ) : (
                              <></>
                            )}
                          </StayPlanAmenities>
                          <StayPlanPriceBox>
                            <span>{utils.digits(selectedStayPlan.total_fee || 0)}円</span>
                            <StayPlanPriceDetail>
                              {' '}
                              / {hotel.stay_days}泊{hotel.roomnum}室
                            </StayPlanPriceDetail>
                          </StayPlanPriceBox>
                        </SelectedStayPlanContentArea>
                      </SelectedStayPlanBox>
                    </Row>
                  )}
                  {hotel.stay_plans.length > 1 && me?.organization?.enable_multiple_hotel_plans ? (
                    <>
                      <Row>
                        <StayPlanListBox>
                          <RadioGroup
                            layout="vertical"
                            onChange={handleClickStayPlan}
                            value={selectedStayPlan && selectedStayPlan.id}
                          >
                            {hotel.stay_plans.map((stay_plan: any, i: number) => (
                              <StayPlanList key={stay_plan.id}>
                                <Radio key={stay_plan.id} value={stay_plan.id}>
                                  <StayPlanContentArea>
                                    <StayPlanName>{stay_plan.name || '-'}</StayPlanName>
                                    <StayPlanAmenities>
                                      <StayPlanSmokeType>{stay_plan.smoke ? '喫煙' : '禁煙'}</StayPlanSmokeType>
                                      <RoomTypeBox>{stay_plan.room_type}</RoomTypeBox>
                                      <RoomBedTypes>
                                        <ul>
                                          {stay_plan.bed_types.forEach((b: HotelBedType) => (
                                            <li>{b.description}</li>
                                          ))}
                                        </ul>
                                      </RoomBedTypes>
                                    </StayPlanAmenities>
                                    <StayPlanPriceBox>
                                      <span>{utils.digits(stay_plan.total_fee || 0)}円</span>
                                      <StayPlanPriceDetail>
                                        {' '}
                                        / {hotel.stay_days}泊{hotel.roomnum}室
                                      </StayPlanPriceDetail>
                                    </StayPlanPriceBox>
                                  </StayPlanContentArea>
                                </Radio>
                              </StayPlanList>
                            ))}
                          </RadioGroup>
                        </StayPlanListBox>
                      </Row>
                      <Row>
                        <MoreBox>
                          <MoreBoxSelectButton onClick={() => handleChangeStayPlan()}>
                            {submitButtonLabel || 'プランを選択する'}
                          </MoreBoxSelectButton>
                        </MoreBox>
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}

                  <Row>
                    <Amenities>
                      <AmenityTitle>施設の設備</AmenityTitle>
                      <Ul>
                        {getPropertyAmenities().map((a, i) => (
                          <Li key={i}>{a}</Li>
                        ))}
                      </Ul>
                      {getPropertyAmenities().length >= 10 && (
                        <ToggleLink onClick={togglePropertyAmenities}>
                          {showMorePropertyAmenities ? '閉じる' : 'もっと見る'}
                        </ToggleLink>
                      )}
                    </Amenities>
                  </Row>
                  <Row>
                    <Amenities>
                      <AmenityTitle>部屋の設備</AmenityTitle>
                      <Ul>
                        {getRoomAmenities().map((a, i) => (
                          <Li key={i}>{a}</Li>
                        ))}
                      </Ul>
                      {getRoomAmenities().length >= 10 && (
                        <ToggleLink onClick={toggleRoomAmenities}>
                          {showMoreRoomAmenities ? '閉じる' : 'もっと見る'}
                        </ToggleLink>
                      )}
                    </Amenities>
                  </Row>
                  <Row>
                    <DetailBox>
                      <DetailBoxTitle>キャンセルポリシー</DetailBoxTitle>
                      <DetailBoxSmall>
                        <Text text={hotel.cancelPolicy()} />
                      </DetailBoxSmall>
                    </DetailBox>
                    {hotel.specialCheckinInstructions() && (
                      <DetailBox>
                        <DetailBoxTitle>備考</DetailBoxTitle>
                        <DetailBoxSmall
                          data-wovn-ignore
                          dangerouslySetInnerHTML={{ __html: hotel.specialCheckinInstructions() || '' }}
                        />
                      </DetailBox>
                    )}
                    <DetailBox>
                      <DetailBoxSmall dangerouslySetInnerHTML={{ __html: hotel.checkinInstructions() }} />
                    </DetailBox>
                    {hotel.parkingInformation && (
                      <DetailBox>
                        <DetailBoxTitle>駐車場に関する情報</DetailBoxTitle>
                        <DetailBoxSmall>
                          <TranslateIgnoreText text={hotel.parkingInformation} />
                        </DetailBoxSmall>
                      </DetailBox>
                    )}
                  </Row>
                </BodyAreaIn>
              </BodyArea>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
);

const TopArea = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  padding: 12px 15px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const Address = styled.div`
  font-size: 12px;
`;

const Soldout = styled.div`
  margin-left: auto;
  color: ${props => props.theme.redColor};
`;

const BodyArea = styled.div`
  width: 100%;
  padding: 20px;
`;

const BodyAreaIn = styled.div``;

const mainThumbWidth = 36;
const mainImageWidth = mainThumbWidth * 13;
const imageBorderWidth = 3;
const mapWidth = 452;

const MainImageArea = styled.div`
  flex-grow: 1;
  max-width: ${`${mainImageWidth + imageBorderWidth * 2}px`};
  height: 367px;
  border: ${`${imageBorderWidth}px`} solid ${props => props.theme.hotelImageBorderColor};
  border-radius: ${imageBorderWidth};
`;

const MainImage = styled.div`
  max-width: ${`${mainImageWidth}px`};
  width: 100%;
  height: 288px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const MainThumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${`${mainImageWidth}px`};
  background: ${props => props.theme.grayColorLight};
`;

const MainThumb = styled.div<{ selected: boolean }>`
  width: ${`${mainThumbWidth}px`};
  height: ${`${mainThumbWidth}px`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${props =>
    !props.selected &&
    `
    background-blend-mode: color;
    background-color: rgba(255, 255, 255, 0.7);
  `}
`;

const MapArea = styled.div`
  max-width: ${`${mapWidth}px`};
  width: 100%;
  margin-left: auto;
`;

const InfoArea = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${props => props.theme.grayColorLight};
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: left;
`;

const PriceArea = styled.div`
  background: #fff;
  padding: 5px;
  margin: 10px auto 10px auto;
  width: 100%;
`;

const Price = styled.div`
  font-size: 14px;
  color: ${props => props.theme.moneyColor};
`;

const ToggleLink = styled(A)`
  font-size: 10px;
`;

export const DetailBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const StayPlanListBox = styled.div`
  width: 100%;
`;

export const StayPlanList = styled.div`
  border: 1px solid #eee;
  width: 100%;
  padding: 0 0 0 10px;
  margin-bottom: 5px;
  display: inline-flex;

  & > label {
    width: 100%;
  }

  & .MuiTypography-root {
    width: 100%;
  }
`;

export const SelectedStayPlanBox = styled.div`
  width: 100%;
`;

export const SelectedStayPlanContentArea = styled.div`
  border: 3px solid #eee;
  border-color: #af985e;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const StayPlanContentArea = styled.div`
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const MoreBox = styled.div`
  display: block;
  margin: auto;
`;

export const MoreBoxSelectButton = styled.div`
  color: white;
  width: 200px;
  height: 40px;
  text-align: center;
  border: 1px solid ${props => props.theme.linkColor};
  border-radius: 5px;
  padding: 2px;
  font-weight: bold;
  line-height: 28px;
  font-size: 14px;
  background: ${props => props.theme.linkColor};
`;

const RoomBedTypes = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

const RowTitle = styled.div`
  margin: 10px 0 5px 0;
  display: flex;
  font-weight: bold;
`;

const StayPlanAmenities = styled.div`
  display: inline-flex;
  font-size: 10px;
  font-weight: normal;
  text-indent: -5px;
`;

const StayPlanSmokeType = styled.div`
  padding: 0 5px 0 5px;
`;

const StayPlanName = styled.div`
  font-weight: bold;
`;

export const StayPlanPriceBox = styled.div`
  text-align: right;
  width: 100%;
  color: #af985e;
  font-weight: bold;
`;

const StayPlanPriceDetail = styled.span`
  font-weight: normal;
  height: 100%;
`;

const LoadingSpan = styled.span`
  width: 20%;
  top: -0.5em;
`;

export default HotelDetailModalSpTemplate;
