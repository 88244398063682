import _ from 'lodash';
import OtherServiceShareholderInfo from './other_service_shareholder_info';

interface OtherServiceShareholderInfoArgs {
  shareholder_infos: OtherServiceShareholderInfo[];
}

class OtherServiceShareholderInfoList {
  public list: OtherServiceShareholderInfo[];

  constructor(args: OtherServiceShareholderInfoArgs) {
    this.list =
      args.shareholder_infos.map(t => {
        return new OtherServiceShareholderInfo({
          id: t.id,
          from: t.from,
          to: t.to,
          purchase_price_ana: t.purchase_price_ana,
          purchase_price_jal: t.purchase_price_jal,
          purchase_price_sfj: t.purchase_price_sfj
        });
      }) || [];
  }

  find(id: number | null | void) {
    this.list.find((m: OtherServiceShareholderInfo) => {
      return m.id === id;
    });
  }

  last() {
    _.last(this.list);
  }

  push(m: OtherServiceShareholderInfo) {
    this.list.push(m);
  }

  unshift(m: OtherServiceShareholderInfo) {
    this.list.unshift(m);
  }

  remove(m: OtherServiceShareholderInfo) {
    const i = this.list.indexOf(m);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  update(newOtherServiceShareholderInfo: OtherServiceShareholderInfo) {
    const m: OtherServiceShareholderInfo | void = this.find(newOtherServiceShareholderInfo.id);
    if (m !== undefined) {
      _.assign(m, newOtherServiceShareholderInfo);
    }
  }
}

export default OtherServiceShareholderInfoList;
