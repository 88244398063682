import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import HowToItemForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/how_to_item_for_ait.template';
import type {
  HeaderWithCodeProps,
  SubCode
} from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_area_for_ait';
import type { HowToUse } from '@this/components/reserve_trip/dashboard/dashboard';

type Props = {
  header: HeaderWithCodeProps;
  travelerApproverGuides: HowToUse[];
  travelerApproverManuals: HowToUse[];
  adminGuides: HowToUse[];
  adminManuals: HowToUse[];
};

interface AccordionItemProps {
  subTitle: { subCode?: SubCode; text: string };
  travelerApproverGuides: HowToUse[];
  travelerApproverManuals: HowToUse[];
  adminGuides: HowToUse[];
  adminManuals: HowToUse[];
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  subTitle,
  travelerApproverGuides,
  travelerApproverManuals,
  adminGuides,
  adminManuals
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContents>
      <SubTitle onClick={toggleAccordion}>
        {subTitle.subCode === 'T_AND_A' ? <BaggageIcon /> : <AdminIcon />}
        {subTitle.text}
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </SubTitle>
      {isOpen && (
        <AccordionContentsItems>
          {subTitle.subCode === 'T_AND_A' ? (
            <HowToItemForAIT guides={travelerApproverGuides} manuals={travelerApproverManuals} />
          ) : (
            <HowToItemForAIT guides={adminGuides} manuals={adminManuals} />
          )}
        </AccordionContentsItems>
      )}
    </AccordionContents>
  );
};

const HowToBlockForAIT: React.FC<Props> = ({
  header,
  travelerApproverGuides,
  travelerApproverManuals,
  adminGuides,
  adminManuals
}) => {
  return (
    <Wrapper>
      <Header>
        <MainTitle>{header.mainTitle}</MainTitle>
        <>
          {header.subTitles.map((subTitle, index) => (
            <AccordionItem
              key={index}
              subTitle={subTitle}
              travelerApproverGuides={travelerApproverGuides}
              travelerApproverManuals={travelerApproverManuals}
              adminGuides={adminGuides}
              adminManuals={adminManuals}
            />
          ))}
        </>
      </Header>
    </Wrapper>
  );
};

const ChevronDownIcon: React.FC = () => (
  <img src="/images/help/chevron_down_icon.svg" alt="chevron down" style={{ marginLeft: 'auto' }} />
);

const ChevronUpIcon: React.FC = () => (
  <img src="/images/help/chevron_up_icon.svg" alt="chevron up" style={{ marginLeft: 'auto' }} />
);
const BaggageIcon: React.FC = () => (
  <img src="/images/help/baggage_icon.svg" alt="baggage" style={{ marginRight: '8px' }} />
);

const AdminIcon: React.FC = () => (
  <img src="/images/help/admin_icon.svg" alt="admin" style={{ marginRight: '8px' }} />
);

const Wrapper = styled.div`
  flex-basis: 320px;
  flex-grow: 999;
`;

const Header = styled.div`
  margin-bottom: 40px;
`;

const MainTitle = styled.div`
  color: #3a3a3a;
  font-size: 23px;
  font-weight: 700;
  line-height: 28.75px;
`;

const SubTitle = styled.div`
  cursor: pointer;
  padding: 24px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  background: #292c35;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AccordionContents = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
`;

const AccordionContentsItems = styled.div`
  width: 100%;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
`;

export default HowToBlockForAIT;
