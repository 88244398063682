import React from 'react';
import { styled } from '@this/constants/themes';
import {
  OrganizationTitle,
  OrganizationBody,
  CsvDownloadContainer,
  DownloadButton
} from '@this/components/organization/organization.style';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import TripList from '@this/domain/trip/trip_list';
import SCButton from '@this/components/organization/button';
import type { DateRangePickerValue } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import OrganizationTripReportsTable from './table/table';

const TripReportList = require('../../../domain/trip_report_list');

interface Props {
  serviceId: number;
}

interface State {
  reports: any[];
  projects: any[];
  expenseTypes: any;
  downloading: boolean;
  loading: boolean;
  error: string | null;
  csvEncoding: string;
  fromDate: Moment;
  toDate: Moment;
}

const DATE_FORMAT = 'YYYY-MM-DD';

interface TripReportsResult {
  reports: any[];
  trips: any[];
  show_fee: boolean;
  users: any[];
  projects: any[];
  expense_types: any;
}

class TripReports extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reports: [],
      projects: [],
      expenseTypes: null,
      downloading: false,
      loading: true,
      error: null,
      csvEncoding: `${navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8'}`,
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month')
    };

    this.fetchReports = this.fetchReports.bind(this);
    this.handleApproveReport = this.handleApproveReport.bind(this);
    this.handleRejectReport = this.handleRejectReport.bind(this);
    this.handleEditReport = this.handleEditReport.bind(this);
    this.handleCSVDownload = this.handleCSVDownload.bind(this);
  }

  componentDidMount() {
    this.fetchReports();
  }

  fetchReports() {
    this.setState({ loading: true });
    const params = {
      from: this.state.fromDate.format(DATE_FORMAT),
      to: this.state.toDate.format(DATE_FORMAT)
    };
    utils
      .jsonPromise<TripReportsResult>('/organization/trip_reports.json', params)
      .then(
        result => {
          const trips = new TripList(result.trips, { showFee: result.show_fee, users: result.users });
          this.setState({
            reports: TripReportList.withTripList(result.reports, trips).items,
            projects: result.projects,
            expenseTypes: result.expense_types,
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleApproveReport(report: any, onComplete: () => void) {
    utils
      .jsonPromise(`/organization/trip_reports/${report.id}/approve`, report.toApproveParams(), 'PUT')
      .then(
        _result => {
          onComplete();
          this.fetchReports();
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleRejectReport(report: any, onComplete: () => void) {
    utils
      .jsonPromise(`/organization/trip_reports/${report.id}/reject`, { reject_reason: report.rejectReason }, 'PUT')
      .then(
        _result => {
          onComplete();
          this.fetchReports();
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleEditReport(report: any, onComplete: () => void) {
    utils
      .jsonPromise(`/organization/trip_reports/${report.id}`, report.toCreateParams(), 'PUT')
      .then(
        () => {
          onComplete();
          this.fetchReports();
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleCSVEncodingChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    this.setState({ csvEncoding: e.target.value });
  }

  handleCSVDownload(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ downloading: true });
    new Promise(resolve => {
      location.href = `/organization/trip_reports.csv?${utils.toParams({ encoding: this.state.csvEncoding })}`;
      resolve();
    }).then(() => {
      setTimeout(() => {
        this.setState({ downloading: false });
      }, 5000);
    });
  }

  handleDateRangeChange = (value: DateRangePickerValue | null) => {
    if (!value) {
      return;
    }

    this.setState({
      fromDate: moment(value[0]),
      toDate: moment(value[1])
    });
  };

  handleSearchClick = () => {
    this.fetchReports();
  };

  render() {
    try {
      const { serviceId } = this.props;
      const { reports, projects, expenseTypes, downloading, loading, error, fromDate, toDate } = this.state;
      return (
        <>
          <OrganizationTitle>出張報告一覧</OrganizationTitle>
          <OrganizationBody>
            <DateArea>
              <Label>出張完了日</Label>
              <DateRangePicker
                value={[fromDate.toDate(), toDate.toDate()]}
                onChange={this.handleDateRangeChange}
                disabledClear
              />
              <ReloadButtonArea>
                <SCButton
                  serviceId={serviceId}
                  tag="input"
                  type="submit"
                  value="検索"
                  width="80px"
                  onClick={this.handleSearchClick}
                />
              </ReloadButtonArea>
            </DateArea>
            <CsvDownloadContainer>
              <select onChange={this.handleCSVEncodingChange}>
                <option value="utf8">Macなど（文字コード：UTF8）</option>
                <option value="sjis">Windows（文字コード：SJIS）</option>
              </select>
              <DownloadButton disabled={downloading} onClick={this.handleCSVDownload}>
                CSVをダウンロード
              </DownloadButton>
            </CsvDownloadContainer>
            {error ? (
              <Error>{error}</Error>
            ) : (
              <OrganizationTripReportsTable
                reports={reports}
                projects={projects}
                expenseTypes={expenseTypes}
                loading={loading}
                onApprove={this.handleApproveReport}
                onReject={this.handleRejectReport}
                onEdit={this.handleEditReport}
              />
            )}
          </OrganizationBody>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Label = styled.p`
  line-height: 25px;
  margin-right: 5px;
  white-space: nowrap;
`;

const DateArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: #f7f7f7;
  padding: 10px;
  font-weight: bold;
`;

const ReloadButtonArea = styled.div`
  margin-top: 2px;
  margin-left: 10px;
`;

const Error = styled.div`
  padding: 0 10px;
  color: ${props => props.theme.redColor};
`;

export default TripReports;
