import React from 'react';

import type KitElement from '@this/domain/kit_element';

interface Props {
  element: KitElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

type TypeKind = 'PCR検査キット' | 'PCR検査キット(一括購入)';
type ProductName = '新型コロナ抗原検査キット';

const KindOptions: TypeKind[] = ['PCR検査キット', 'PCR検査キット(一括購入)'];
const NameOptions: ProductName[] = ['新型コロナ抗原検査キット'];

const KitElementForm = ({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('kind')}`}>
        種別
      </label>
      <select value={element.kind} onChange={e => element.setKind(e.target.value)}>
        {KindOptions.map(v => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('name')}`}>
        商品名
      </label>
      <select value={element.name} onChange={e => element.setName(e.target.value)}>
        {NameOptions.map(v => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    </div>

    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('kitSize')}`}>
        数量
      </label>
      <input type="number" value={element.kitSize} onChange={e => element.setKitSize(+e.target.value)} />
    </div>

    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('stockNumber')}`}>
        在庫管理番号
        <span className="red">（必須）</span>
      </label>
      <input type="text" value={element.stockNumber} onChange={e => element.setStockNumber(e.target.value)} />
    </div>

    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('lotNumber')}`}>
        ロット管理番号
      </label>
      <input type="text" value={element.lotNumber} onChange={e => element.setLotNumber(e.target.value)} />
    </div>

    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remark')}`}>
        備考
      </label>
      <textarea value={element.remark} onChange={e => element.setRemark(e.target.value)} />
    </div>
  </>
);
export default KitElementForm;
