import { observable } from 'mobx';

export type FreeeAccountItemResponseArgs = {
  id: number;
  name: string;
};

export type FreeeAccountItemArgs = {
  id: number;
  name: string;
};

export const convertFreeeAccountItemRespnseToArgs = (response: FreeeAccountItemResponseArgs) => {
  const args: FreeeAccountItemArgs = {
    id: response.id,
    name: response.name
  };
  return args;
};

export default class FreeeAccountItem {
  @observable
  id: number;

  @observable
  name: string;

  constructor(args: FreeeAccountItemArgs) {
    this.id = args.id;
    this.name = args.name;
  }
}
