import React from 'react';
import { styled } from '@this/constants/themes';

import type ArrangementRequestOtherItemInfo from '@this/domain/arrangement_request_info/arrangement_request_other_item_info';

export type Props = {
  otherItemInfo: ArrangementRequestOtherItemInfo;
};

const ArrangementRequestOtherItemInfoForm: React.SFC<Props> = ({ otherItemInfo }) => (
  <div className="reserve-confirm__section">
    <div className="reserve-confirm__subtitle">その他の商品</div>
    <div className="reserve-confirm__child-content">
      {otherItemInfo.otherItems.map((otherItem, i) => (
        <div key={i}>
          {i !== 0 && <OtherItemRemove onClick={() => otherItemInfo.removeOtherItem(i)}>×</OtherItemRemove>}
          <p className="reserve-confirm__peoplenum">ご依頼内容{i !== 0 && i + 1}</p>
          <div className="reserve-confirm__traveler">
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">商品</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <select
                  value={otherItem.itemType}
                  onChange={e => otherItemInfo.setItemType(e.target.value as never, i)}
                >
                  <option value="">商品を選択してください</option>
                  {otherItemInfo.itemTypeOptions.map(([label, value]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                {otherItem.itemType === 'other' && (
                  <input
                    className="reserve-confirm__input"
                    type="text"
                    value={otherItem.itemTypeOther}
                    onChange={e => otherItemInfo.setItemTypeOther(e.target.value as never, i)}
                  />
                )}
                {otherItemInfo.errorMessage('itemType', i) && (
                  <Annotation className="red">{otherItemInfo.errorMessage('itemType', i)}</Annotation>
                )}
                {otherItemInfo.errorMessage('itemTypeOther', i) && (
                  <Annotation className="red">{otherItemInfo.errorMessage('itemTypeOther', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">備考</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <textarea
                  className="reserve-confirm__input"
                  value={otherItem.remarks}
                  onChange={e => otherItemInfo.setRemarks(e.target.value as never, i)}
                />
              </ColumnDiv>
            </div>
          </div>
        </div>
      ))}
      <AddOtherItemToggle onClick={() => otherItemInfo.addOtherItem()}>
        + その他の商品を追加する
      </AddOtherItemToggle>
    </div>
  </div>
);

const ColumnDiv = styled.div`
  flex-flow: column;
`;

const Annotation = styled.div`
  font-size: 0.85em;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const OtherItemRemove = styled.div`
  float: right;
  cursor: pointer;
  margin-right: 60px;
`;

const AddOtherItemToggle = styled.div`
  color: #af985e;
  text-decoration: none;
  transition: color 0.1s linear;
  cursor: pointer;
`;

export default ArrangementRequestOtherItemInfoForm;
