import moment from 'moment';
import type { Moment } from '../lib/moment';

type SearchTrackingStatus = 'standby' | 'search' | 'confirm' | 'billed';

interface Setting {
  key: string;
  value: string | number | null;
}

export interface SearchTrackingArgs {
  id: number;
  organization_id: number;
  user_id: number;
  trip_id: number;
  search_query_id: number;
  status: SearchTrackingStatus;
  settings?: Setting[];
  created_at: string;
  updated_at?: string;
  last_log_created_at?: string;
  bugsnag_link?: string;
  organization?: {
    id: number;
    name: string;
  };
  user?: {
    id: number;
    name: string;
    email: string;
  };
}

class SearchTracking {
  id: number;

  organizationId: number | null;

  organizationName: string | null;

  userId: number | null;

  userName: string | null;

  userEmail: string | null;

  tripId: number | null;

  searchQueryId: number | null;

  status: SearchTrackingStatus;

  settings: Setting[];

  createdAt: Moment;

  updatedAt: Moment | null;

  lastLogCreatedAt: Moment | null;

  bugsnagLink: string | null;

  constructor(args: SearchTrackingArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.organizationName = args.organization?.name || null;
    this.userId = args.user_id;
    this.userName = args.user?.name || null;
    this.userEmail = args.user?.email || null;
    this.tripId = args.trip_id;
    this.searchQueryId = args.search_query_id;
    this.status = args.status;
    this.settings = args.settings || [];
    this.createdAt = moment(args.created_at);
    this.updatedAt = args.updated_at ? moment(args.updated_at) : null;
    this.lastLogCreatedAt = args.last_log_created_at ? moment(args.last_log_created_at) : null;
    this.bugsnagLink = args.bugsnag_link || null;
  }
}

export default SearchTracking;
