export interface CutoffDateJson {
  id?: number;
  date: string;
  date_type: string;
  end_of_month: boolean;
  destroy?: string;
}

export class CutoffDate {
  id?: number;

  date: string;

  dateType: string;

  endOfMonth: boolean;

  destroy?: string;

  constructor(args: CutoffDateJson) {
    this.id = args.id;
    this.date = args.date;
    this.dateType = args.date_type;
    this.endOfMonth = args.end_of_month;
    this.destroy = args.destroy;
  }

  setDate(value: string, dateType: string) {
    if (value === 'endOfMonth') {
      this.endOfMonth = true;
      this.dateType = dateType;
    } else {
      this.endOfMonth = false;
      this.date = value;
      this.dateType = dateType;
    }
  }

  value() {
    return this.endOfMonth ? 'endOfMonth' : this.date;
  }

  setDestroy() {
    this.destroy = '1';
  }

  submitParams() {
    return {
      id: this.id,
      date: this.date,
      date_type: this.dateType,
      end_of_month: this.endOfMonth,
      _destroy: this.destroy
    };
  }
}
