import React from 'react';
import { styled } from '@this/constants/themes';
import { css } from 'styled-components';
import { Input } from '@this/shared/ui/inputs/input';
import { Wrapper, Header } from '../report_items/search_form';

interface Props {
  onClose: () => void;
  onSubmit: (reason: string) => Promise<void>;
}

interface State {
  reason: string;
  submitting: boolean;
}

export default class Reject extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reason: '',
      submitting: false
    };
  }

  render() {
    return (
      <Wrapper>
        <Header>差し戻し理由を入力してください</Header>
        <Body>
          <TheInput
            value={this.state.reason}
            onChange={e => {
              this.setState({ reason: e.target.value });
            }}
            inputProps={{ style: { width: '240px' } }}
          />
        </Body>
        <ActionWrapper>
          <CancelButton onClick={this.props.onClose}>キャンセル</CancelButton>
          <BaseButton
            disabled={!this.state.reason || this.state.submitting}
            onClick={async () => {
              this.setState({ submitting: true });
              await this.props.onSubmit(this.state.reason);
              this.setState({ submitting: false });
            }}
          >
            差し戻し
          </BaseButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const baseColor = '#927230';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const cancelBtnColor = 'transparent';

const Body = styled.div`
  width: 100%;
  margin: 8px 0 36px;
  text-align: center;
`;

const TheInput = styled(Input)`
  padding: 5px;
  background: #faf9f8;

  .MuiOutlinedInput-notchedOutline {
    border-color: #f1ede5;
  }
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const BaseButtonCss = css`
  width: unset !important;
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  padding: 0.875rem 2.25rem;
  border-radius: 6px;
  font-size: 1rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
    color: ${primaryTxtColor};
  }
`;

export const BaseButton = styled.button.attrs({ type: 'button' })`
  ${BaseButtonCss}
`;

export const CancelButtonCss = css`
  ${BaseButtonCss}
  background: ${cancelBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  padding: 0.815rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${cancelBtnColor};
    color: ${baseColor};
  }
`;

export const CancelButton = styled.button.attrs({ type: 'button' })`
  ${CancelButtonCss}
`;
