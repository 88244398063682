import React from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import type { FlightInfo } from './type';

type Props = {
  className?: string;
  trip: Trip;
  flightInfo: FlightInfo;
  totalPrice: number;
};

export const ReceiptDetail = ({ className, trip, flightInfo, totalPrice }: Props) => {
  const order = trip.order;
  const { flightCustomers } = flightInfo;

  return (
    <Root className={className}>
      <Title>ご利用明細</Title>
      <Summary>
        <p>
          {trip.dateRange()} {trip.stayDays()}
          <br />
          手配者: {trip.user.name} 様 <br />
          宿泊者: {trip.guest_last_name} {trip.guest_first_name}
        </p>
        {flightCustomers.length > 0 && (
          <FlightCustomerList>
            {flightCustomers.map(customer => (
              <li key={customer.id}>
                {customer.last_name} {customer.first_name}({customer.birthday})
              </li>
            ))}
          </FlightCustomerList>
        )}
      </Summary>

      <Detail>
        {order.orderItems && order.orderItems.length > 0 ? (
          <OrderItemsInfo orderItems={order.orderItems} />
        ) : (
          <TripInfo trip={trip} flightInfo={flightInfo} />
        )}

        <Item>
          <p>■合計：¥{utils.digits(totalPrice)}</p>
        </Item>
      </Detail>
    </Root>
  );
};

const OrderItemsInfo = ({ orderItems }: { orderItems: Props['trip']['order']['orderItems'] }) => (
  <>
    {orderItems.map(item => (
      <Item key={item.id}>
        <p>
          ■{item.categoryStr()}：¥{utils.digits(item.totalPriceWithAll())}
        </p>
        {item.elements.map(elm => (
          <div key={elm.id}>
            {(elm as any).name && <p>{(elm as any).name}</p>}
            <p>{elm.summary()}</p>
            {elm.detail && <p>{elm.detail()}</p>}
          </div>
        ))}
      </Item>
    ))}
  </>
);

const TripInfo = ({ trip, flightInfo }: Pick<Props, 'trip' | 'flightInfo'>) => {
  const order = trip.order;
  const { outwordChangeable, homewordChangeable, outwordName, homewordName } = flightInfo;
  const hotelName = React.useMemo(() => trip.currentOrder.hotel_name || trip.hotelName(), [trip]);
  const items = React.useMemo(() => {
    const result: string[][] = [];

    if (order.flight) {
      const item = [];
      item.push(`■航空券：¥${utils.digits(trip.flightPrice())}`);
      order.flight.split('\n').forEach(line => item.push(line));
      result.push(item);
    }

    if (hotelName) {
      const item = [];
      item.push(`■ホテル：¥${utils.digits(trip.hotelPrice())}`);
      item.push(hotelName);
      result.push(item);
    }

    if (outwordName) {
      const item = [];
      item.push(`■往路：¥${utils.digits(trip.outwordPrice())}`);
      outwordName.split('\n').forEach(line => item.push(line));
      if (!outwordChangeable) {
        item.push('特割');
      }
      result.push(item);
    }

    if (homewordName) {
      const item = [];
      item.push(`■復路：¥${utils.digits(trip.homewordPrice())}`);
      homewordName.split('\n').forEach(line => item.push(line));
      if (!homewordChangeable) {
        item.push('特割');
      }
      result.push(item);
    }

    // - old data compatibility
    if (order.shinkansen_fee) {
      result.push([`■発券手数料(送料込)：¥${utils.digits(order.shinkansen_fee)}`]);
    } else if (order.domestic_air_fee || order.foreign_air_fee) {
      result.push([
        `■発行手数料：¥${utils.digits(
          order.domestic_air_fee ? order.domestic_air_fee ?? 0 : order.foreign_air_fee ?? 0
        )}`
      ]);
    }

    return result;
  }, [trip, order, hotelName, outwordName, homewordName, outwordChangeable, homewordChangeable]);

  return (
    <>
      {items.map((item, i) => (
        <Item key={i.toString()}>
          {item.map((line, j) => (
            <p key={j.toString()}>{line}</p>
          ))}
        </Item>
      ))}
    </>
  );
};

const Root = styled.div``;

const Title = styled.h2`
  margin-bottom: 8px;
`;

const Summary = styled.div``;

const FlightCustomerList = styled.ul`
  margin-left: 24px;
  list-style-type: disc;
`;

const Detail = styled.div`
  margin-top: 24px;
`;

const Item = styled.div`
  & + & {
    margin-top: 16px;
  }
`;
