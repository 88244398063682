import React from 'react';

const HeaderPc = () => {
  return (
    <header>
      <div className="header_container flex">
        <div className="logos left">
          <a href="/">
            <div className="logo-img2" />
          </a>
          <img
            className="good_design_award"
            src="/images/biz_lp/good_design_award.png"
            alt="good design award"
            height="30"
          />
        </div>
        <span className="phone_icon">
          <img src="/images/biz_lp/phone.png" alt="phone icon" width="16" height="16" />
        </span>
        <span className="tel">
          <div className="tel_number">
            <span>03-6843-3297</span>
          </div>
          <div className="note">10:00 ~ 18:00（土日祝日除く）</div>
        </span>
        <div className="inquiries flex">
          <a className="button inquiry flex" href="https://aitravel.cloud/download/">
            資料請求
          </a>
        </div>
      </div>
    </header>
  );
};

export default HeaderPc;
