import _ from 'lodash';

import type { FlightMarketLogCandidateJson } from '@this/domain/market_log/flight_market_log_candidate';
import FlightMarketLogCandidate from '@this/domain/market_log/flight_market_log_candidate';

export interface FlightMarketLogJson {
  candidates?: FlightMarketLogCandidateJson[];
  user_name: string;
  approver_names: string;
  traveler_names: string;
  transport_descriptions: string[];
  price: number;
  market_price: number;
  max_price: number;
  min_price: number;
  average_price: number;
  median_price: number;
  cheap_line_price: number;
  expensive_line_price: number;
  deviation_value: number;
  price_ranking: number;
  recommend_ranking: number;
}

class FlightMarketLog {
  userName: string;

  approverNames: string;

  travelerNames: string;

  transportDescriptions: string[];

  price: number;

  marketPrice: number | null;

  maxPrice: number;

  minPrice: number;

  averagePrice: number;

  medianPrice: number;

  deviationValue: number | null;

  cheapLinePrice: number | null;

  expensiveLinePrice: number | null;

  candidates: FlightMarketLogCandidate[];

  priceRanking: number | null;

  recommendRanking: number | null;

  constructor(args: FlightMarketLogJson) {
    this.userName = args.user_name;
    this.approverNames = args.approver_names;
    this.travelerNames = args.traveler_names;
    this.transportDescriptions = args.transport_descriptions;
    this.price = args.price;
    this.marketPrice = args.market_price;
    this.maxPrice = args.max_price;
    this.minPrice = args.min_price;
    this.averagePrice = args.average_price;
    this.medianPrice = args.median_price;
    this.deviationValue = args.deviation_value;
    this.cheapLinePrice = args.cheap_line_price;
    this.expensiveLinePrice = args.expensive_line_price;
    this.candidates = args.candidates ? args.candidates.map(c => new FlightMarketLogCandidate(c)) : [];
    this.priceRanking = args.price_ranking;
    this.recommendRanking = args.recommend_ranking;
  }

  candidateMaxTransportLength(): number {
    const lengths = this.candidates.map(c => c.transportDescriptions.length);
    return Math.max(...lengths);
  }

  priceCheck(): string {
    if (this.marketPrice === null || this.cheapLinePrice === null || this.expensiveLinePrice === null) {
      return '';
    }
    if (this.marketPrice < this.cheapLinePrice) {
      return 'cheap';
    }
    if (this.marketPrice > this.expensiveLinePrice) {
      return 'expensive';
    }

    return 'standard';
  }
}

export default FlightMarketLog;
