export interface Summary {
  priceWithTax: number;
  perUnit: string;
  aitSameItemPrice: number;
  aitSameItemDiff: number;
  aitCheapestItemPrice: number;
  aitCheapestItemDiff: number;
  aitRecommendedItemPrice: number;
  aitRecommendedItemDiff: number;
  aitAveragePrice: number;
  aitAverageDiff: number;
  pastPriceWithTax: number;
  pastPerUnit: string;
  aitPastSameItemPrice: number;
  aitPastSameItemDiff: number;
  aitPastCheapestItemPrice: number;
  aitPastCheapestItemDiff: number;
  aitPastRecommendedItemPrice: number;
  aitPastRecommendedItemDiff: number;
  aitPastAveragePrice: number;
  aitPastAverageDiff: number;
}

export interface SimulateBaseSummaryArgs {
  count: number;
  people_num: number;
  stay_num: number;
  price_with_tax: number;
  price_with_tax_per_count: number;
  price_with_tax_per_people: number;
  price_with_tax_per_stay: number;
  ait_same_item_price: number;
  ait_same_item_diff: number;
  ait_same_item_price_per_count: number;
  ait_same_item_diff_per_count: number;
  ait_same_item_price_per_people: number;
  ait_same_item_diff_per_people: number;
  ait_same_item_price_per_stay: number;
  ait_same_item_diff_per_stay: number;
  ait_cheapest_item_price: number;
  ait_cheapest_item_diff: number;
  ait_cheapest_item_price_per_count: number;
  ait_cheapest_item_diff_per_count: number;
  ait_cheapest_item_price_per_people: number;
  ait_cheapest_item_diff_per_people: number;
  ait_cheapest_item_price_per_stay: number;
  ait_cheapest_item_diff_per_stay: number;
  ait_recommended_item_price: number;
  ait_recommended_item_diff: number;
  ait_recommended_item_price_per_count: number;
  ait_recommended_item_diff_per_count: number;
  ait_recommended_item_price_per_people: number;
  ait_recommended_item_diff_per_people: number;
  ait_recommended_item_price_per_stay: number;
  ait_recommended_item_diff_per_stay: number;
  ait_average_price: number;
  ait_average_diff: number;
  ait_average_price_per_count: number;
  ait_average_diff_per_count: number;
  ait_average_price_per_people: number;
  ait_average_diff_per_people: number;
  ait_average_price_per_stay: number;
  ait_average_diff_per_stay: number;
  past_count: number;
  past_people_num: number;
  past_stay_num: number;
  past_price_with_tax: number;
  past_price_with_tax_per_count: number;
  past_price_with_tax_per_people: number;
  past_price_with_tax_per_stay: number;
  ait_past_same_item_price: number;
  ait_past_same_item_diff: number;
  ait_past_same_item_price_per_count: number;
  ait_past_same_item_diff_per_count: number;
  ait_past_same_item_price_per_people: number;
  ait_past_same_item_diff_per_people: number;
  ait_past_same_item_price_per_stay: number;
  ait_past_same_item_diff_per_stay: number;
  ait_past_cheapest_item_price: number;
  ait_past_cheapest_item_diff: number;
  ait_past_cheapest_item_price_per_count: number;
  ait_past_cheapest_item_diff_per_count: number;
  ait_past_cheapest_item_price_per_people: number;
  ait_past_cheapest_item_diff_per_people: number;
  ait_past_cheapest_item_price_per_stay: number;
  ait_past_cheapest_item_diff_per_stay: number;
  ait_past_recommended_item_price: number;
  ait_past_recommended_item_diff: number;
  ait_past_recommended_item_price_per_count: number;
  ait_past_recommended_item_diff_per_count: number;
  ait_past_recommended_item_price_per_people: number;
  ait_past_recommended_item_diff_per_people: number;
  ait_past_recommended_item_price_per_stay: number;
  ait_past_recommended_item_diff_per_stay: number;
  ait_past_average_price: number;
  ait_past_average_diff: number;
  ait_past_average_price_per_count: number;
  ait_past_average_diff_per_count: number;
  ait_past_average_price_per_people: number;
  ait_past_average_diff_per_people: number;
  ait_past_average_price_per_stay: number;
  ait_past_average_diff_per_stay: number;
}

type SummaryField = 'total' | 'perCount' | 'perPeople' | 'perStay';
type PriceField = 'aitSameItemPrice' | 'aitCheapestItemPrice' | 'aitRecommendedItemPrice' | 'aitAveragePrice';
type DiffField = 'aitSameItemDiff' | 'aitCheapestItemDiff' | 'aitRecommendedItemDiff' | 'aitAverageDiff';

const PRICE_TO_DIFF: Record<PriceField, DiffField> = {
  aitSameItemPrice: 'aitSameItemDiff',
  aitCheapestItemPrice: 'aitCheapestItemDiff',
  aitRecommendedItemPrice: 'aitRecommendedItemDiff',
  aitAveragePrice: 'aitAverageDiff'
};

export class SimulateBaseSummary {
  count: number;

  peopleNum: number;

  stayNum: number;

  pastCount: number;

  pastPeopleNum: number;

  pastStayNum: number;

  total: Summary;

  perCount: Summary;

  perPeople: Summary;

  perStay: Summary;

  constructor(args: SimulateBaseSummaryArgs) {
    this.count = args.count;
    this.peopleNum = args.people_num;
    this.stayNum = args.stay_num;
    this.pastCount = args.past_count;
    this.pastPeopleNum = args.past_people_num;
    this.pastStayNum = args.past_stay_num;
    this.total = {
      priceWithTax: args.price_with_tax,
      perUnit: '─',
      aitSameItemPrice: args.ait_same_item_price,
      aitSameItemDiff: args.ait_same_item_diff,
      aitCheapestItemPrice: args.ait_cheapest_item_price,
      aitCheapestItemDiff: args.ait_cheapest_item_diff,
      aitRecommendedItemPrice: args.ait_recommended_item_price,
      aitRecommendedItemDiff: args.ait_recommended_item_diff,
      aitAveragePrice: args.ait_average_price,
      aitAverageDiff: args.ait_average_diff,
      pastPriceWithTax: args.past_price_with_tax,
      pastPerUnit: '─',
      aitPastSameItemPrice: args.ait_past_same_item_price,
      aitPastSameItemDiff: args.ait_past_same_item_diff,
      aitPastCheapestItemPrice: args.ait_past_cheapest_item_price,
      aitPastCheapestItemDiff: args.ait_past_cheapest_item_diff,
      aitPastRecommendedItemPrice: args.ait_past_recommended_item_price,
      aitPastRecommendedItemDiff: args.ait_past_recommended_item_diff,
      aitPastAveragePrice: args.ait_past_average_price,
      aitPastAverageDiff: args.ait_past_average_diff
    };
    this.perCount = {
      priceWithTax: args.price_with_tax_per_count,
      perUnit: `/${this.count.toLocaleString()}件`,
      aitSameItemPrice: args.ait_same_item_price_per_count,
      aitSameItemDiff: args.ait_same_item_diff_per_count,
      aitCheapestItemPrice: args.ait_cheapest_item_price_per_count,
      aitCheapestItemDiff: args.ait_cheapest_item_diff_per_count,
      aitRecommendedItemPrice: args.ait_recommended_item_price_per_count,
      aitRecommendedItemDiff: args.ait_recommended_item_diff_per_count,
      aitAveragePrice: args.ait_average_price_per_count,
      aitAverageDiff: args.ait_average_diff_per_count,
      pastPriceWithTax: args.past_price_with_tax_per_count,
      pastPerUnit: `/${this.pastCount.toLocaleString()}件`,
      aitPastSameItemPrice: args.ait_past_same_item_price_per_count,
      aitPastSameItemDiff: args.ait_past_same_item_diff_per_count,
      aitPastCheapestItemPrice: args.ait_past_cheapest_item_price_per_count,
      aitPastCheapestItemDiff: args.ait_past_cheapest_item_diff_per_count,
      aitPastRecommendedItemPrice: args.ait_past_recommended_item_price_per_count,
      aitPastRecommendedItemDiff: args.ait_past_recommended_item_diff_per_count,
      aitPastAveragePrice: args.ait_past_average_price_per_count,
      aitPastAverageDiff: args.ait_past_average_diff_per_count
    };
    this.perPeople = {
      priceWithTax: args.price_with_tax_per_people,
      perUnit: `/${this.peopleNum.toLocaleString()}人`,
      aitSameItemPrice: args.ait_same_item_price_per_people,
      aitSameItemDiff: args.ait_same_item_diff_per_people,
      aitCheapestItemPrice: args.ait_cheapest_item_price_per_people,
      aitCheapestItemDiff: args.ait_cheapest_item_diff_per_people,
      aitRecommendedItemPrice: args.ait_recommended_item_price_per_people,
      aitRecommendedItemDiff: args.ait_recommended_item_diff_per_people,
      aitAveragePrice: args.ait_average_price_per_people,
      aitAverageDiff: args.ait_average_diff_per_people,
      pastPriceWithTax: args.past_price_with_tax_per_people,
      pastPerUnit: `/${this.pastPeopleNum.toLocaleString()}人`,
      aitPastSameItemPrice: args.ait_past_same_item_price_per_people,
      aitPastSameItemDiff: args.ait_past_same_item_diff_per_people,
      aitPastCheapestItemPrice: args.ait_past_cheapest_item_price_per_people,
      aitPastCheapestItemDiff: args.ait_past_cheapest_item_diff_per_people,
      aitPastRecommendedItemPrice: args.ait_past_recommended_item_price_per_people,
      aitPastRecommendedItemDiff: args.ait_past_recommended_item_diff_per_people,
      aitPastAveragePrice: args.ait_past_average_price_per_people,
      aitPastAverageDiff: args.ait_past_average_diff_per_people
    };
    this.perStay = {
      priceWithTax: args.price_with_tax_per_stay,
      perUnit: `/${this.stayNum.toLocaleString()}泊`,
      aitSameItemPrice: args.ait_same_item_price_per_stay,
      aitSameItemDiff: args.ait_same_item_diff_per_stay,
      aitCheapestItemPrice: args.ait_cheapest_item_price_per_stay,
      aitCheapestItemDiff: args.ait_cheapest_item_diff_per_stay,
      aitRecommendedItemPrice: args.ait_recommended_item_price_per_stay,
      aitRecommendedItemDiff: args.ait_recommended_item_diff_per_stay,
      aitAveragePrice: args.ait_average_price_per_stay,
      aitAverageDiff: args.ait_average_diff_per_stay,
      pastPriceWithTax: args.past_price_with_tax_per_stay,
      pastPerUnit: `/${this.pastStayNum.toLocaleString()}泊`,
      aitPastSameItemPrice: args.ait_past_same_item_price_per_stay,
      aitPastSameItemDiff: args.ait_past_same_item_diff_per_stay,
      aitPastCheapestItemPrice: args.ait_past_cheapest_item_price_per_stay,
      aitPastCheapestItemDiff: args.ait_past_cheapest_item_diff_per_stay,
      aitPastRecommendedItemPrice: args.ait_past_recommended_item_price_per_stay,
      aitPastRecommendedItemDiff: args.ait_past_recommended_item_diff_per_stay,
      aitPastAveragePrice: args.ait_past_average_price_per_stay,
      aitPastAverageDiff: args.ait_past_average_diff_per_stay
    };
  }

  priceWithDiff(field: SummaryField, price: PriceField) {
    const diff = PRICE_TO_DIFF[price];
    const summary = this[field];

    return {
      price: summary[price],
      diff: summary[diff]
    };
  }
}
