import React from 'react';
import type God from '@this/domain/god/god';

interface Context {
  currentGod: God | null;
}

const GodContext = React.createContext<Context>({
  currentGod: null
});
export default GodContext;
