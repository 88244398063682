export interface ShinkansenSeatArgs {
  item_id?: number;
  trip_id?: number;
  shinkansen_seat: string;
}

export class ShinkansenSeat {
  itemId?: number;

  tripId?: number;

  shinkansenSeat: string;

  constructor(args: ShinkansenSeatArgs) {
    this.itemId = args.item_id;
    this.tripId = args.trip_id;
    this.shinkansenSeat = args.shinkansen_seat;
  }
}
