import type User from '@this/domain/user/user';
import React, { useEffect, useState } from 'react';
import HamburgerButton from '@this/shared/header/hamburger_button';
import { styled } from '@this/constants/themes';
import NavContantSm from '@this/shared/header/nav_content_sm';
import { useLocation } from 'react-router';

interface Props {
  user?: User | null;
  appliedCount: number;
}

const HeaderContentSm: React.FC<Props> = ({ user, appliedCount }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();

  const close = () => {
    document.body.removeAttribute('style');
    setActive(false);
  };

  const open = () => {
    document.body.style.overflow = 'hidden';
    setActive(true);
  };

  useEffect(close, [location]);

  return (
    <>
      <Container>
        <HamburgerButton active={active} onClick={() => (active ? close() : open())} />
      </Container>
      {user && <StyledNav user={user} active={active} appliedCount={appliedCount} />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
`;

const StyledNav = styled(NavContantSm)<{ active: boolean }>`
  left: ${({ active }) => (active ? 0 : '100%')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s, left 0.3s;
`;

export default HeaderContentSm;
