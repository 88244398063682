/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { css } from 'styled-components';
import { styled } from '@this/constants/themes';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ABase } from '@this/components/shared/atoms/a';
import { Input as SharedInput } from '@this/components/shared/ui/inputs/input';
import { OrganizationHeader, OrganizationTitle } from '@this/components/organization/organization.style';
import { ButtonBase02 } from '@this/shared/atoms/button';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Select } from '@this/shared/ui/inputs/select';
import Input from '@this/shared/atoms/input';
import DistanceSetting from '@this/domain/distance_setting';
import type { shinkansenSeatType, TicketType } from '@this/domain/grade';
import Grade from '@this/domain/grade';
import SearchWeightSettings from '@this/domain/search_weight_settings';
import type { WorkflowRelatedSetting, WorkflowSetting } from '@this/domain/setting';
import Setting from '@this/domain/setting';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Region from '@this/domain/region';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import Organization from '@this/domain/organization/organization2';
import AccountTypeMapping from '@this/domain/account_type_mapping';
import ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import Department from '@this/domain/department/department';
import Tooltip from '@this/shared/tooltip/tooltip';
import PasswordPolicySettingArea from '@this/components/organization/setting/password_policy_setting_area';
import Context from '@this/components/core_app/core_app_context';
import SmartHrAssociateColumn from '@this/domain/smart_hr_associate_column';
import SmartHrAssociateColumnType from '@this/domain/smart_hr_associate_column_type';
import { Fetcher } from '@this/src/util';
import Modal from '@this/shared/modal/modal';
import FlightQuerySettingArea from './flight_query_setting_area';
import SsoEnable from './sso_enable';
import type {
  FlightQuerySetting,
  InternationalAirline,
  InternationalAirport,
  SettingResponse,
  ResionsMunicipalitiesResponse,
  FreeePartner,
  FreeeSection,
  FreeeApprovalFlowRoute,
  FreeeForm
} from './types';
import Notification from '../../../notification';
import ExclusionAirlinesArea from './exclusion_airlines_area';
import AccountTypeMappingSection from './account_type_mapping_section';
import { AutocompleteSelect } from '../../shared/ui/inputs/autocomplete';

interface Props {
  serviceId: number;
  availableOptions: string[];
}

interface State {
  loading: boolean;
  seatClassTypes: Array<{ value: string; displayName: string }>;
  shinkansenSeatTypes: Array<{ value: shinkansenSeatType; displayName: string }>;
  setting: Setting | null;
  grades: Grade[];
  regions: Region[];
  searchWeightSettings: SearchWeightSettings | null;
  flightQuerySettings: FlightQuerySetting[];
  distanceSetting: DistanceSetting;
  internationalAirlines: InternationalAirline[];
  internationalAirports: InternationalAirport[];
  freeeAuthUri: string | null;
  smartHrAuthUri: string | null;
  organization: Organization | null;
  dailyAllowanceAvailability: boolean;
  restrictionDistanceAndTimeAvailability: boolean;
  selectedFreeePartner: FreeePartner | null;
  selectedFreeeSection: FreeeSection | null;
  freeeApprovalFlowRoutes: FreeeApprovalFlowRoute[];
  freeeForms: FreeeForm[];
  freeeLoading: boolean;
  ssoIssuer: string;
  ssoServiceUrl: string;
  ssoLogoutServiceUrl: string;
  exclusionAirlines: string[];
  accountTypeMappings: AccountTypeMapping[];
  expensesAccountTypes: ExpensesAccountType[];
  departments: Department[];
  passwordPolicy?: SettingResponse['password_policy'];
  params: any;
  expenseByMunicipalityFlg: boolean;
  expensesAccountTypeFlg: boolean;
  expenseTool: string;
  contractBiztraPro: boolean;
  tripReportAvailable: boolean;
  smartHrAssociateColumnTypes: SmartHrAssociateColumnType[];
  smartHrAssociateColumns: SmartHrAssociateColumn[];
  nonApproverDepartments: Department[];
  associateColumnEmail: string;
  associateCustomName: string;
  error: boolean;
  errorMessage: string;
  showModal: boolean;
}

@observer
class SettingEdit extends React.Component<Props, State> {
  static contextType = Context;

  context!: React.ContextType<typeof Context>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      setting: null,
      grades: [],
      regions: [],
      searchWeightSettings: null,
      flightQuerySettings: [],
      distanceSetting: DistanceSetting.initialize(),
      freeeAuthUri: '',
      smartHrAuthUri: '',
      organization: null,
      dailyAllowanceAvailability: false,
      restrictionDistanceAndTimeAvailability: false,
      seatClassTypes: [
        { value: 'M', displayName: 'エコノミー' },
        { value: 'W', displayName: 'プレミアムエコノミー' },
        { value: 'C', displayName: 'ビジネス' },
        { value: 'F', displayName: 'ファースト' }
      ],
      shinkansenSeatTypes: [
        { value: 'free', displayName: '自由席' },
        { value: 'reserve', displayName: '指定席' },
        { value: 'green', displayName: 'グリーン席' }
      ],
      internationalAirlines: [],
      internationalAirports: [],
      selectedFreeePartner: null,
      selectedFreeeSection: null,
      freeeApprovalFlowRoutes: [],
      freeeForms: [],
      freeeLoading: false,
      ssoIssuer: '',
      ssoServiceUrl: '',
      ssoLogoutServiceUrl: '',
      exclusionAirlines: [],
      accountTypeMappings: [],
      expensesAccountTypes: [],
      departments: [],
      params: {},
      expenseByMunicipalityFlg: false,
      expensesAccountTypeFlg: false,
      expenseTool: 'none',
      contractBiztraPro: false,
      tripReportAvailable: props.availableOptions.includes('trip_report'),
      smartHrAssociateColumnTypes: [],
      smartHrAssociateColumns: [],
      nonApproverDepartments: [],
      associateColumnEmail: '',
      associateCustomName: '',
      error: false,
      errorMessage: '',
      showModal: false
    };
  }

  async componentDidMount() {
    let urlParamStr = window.location.search;
    let params = {};
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1);
      urlParamStr = decodeURI(urlParamStr);
      urlParamStr.split('&').forEach(param => {
        const temp = param.split('=');
        params = {
          ...params,
          [temp[0]]: temp[1]
        };
      });
    }
    this.setState({
      loading: true,
      params
    });
    await Promise.all([this.fetchSetting(), this.fetchAirlines(), this.fetchAirports()]);
    this.setState({ loading: false });
  }

  async fetchSetting() {
    try {
      const result = await utils.jsonPromise<SettingResponse>('/organization/setting.json');
      if (result.warnings.length) Notification.error(result.warnings);
      this.setState({
        setting: new Setting(result.setting),
        grades: result.grades.map(g => new Grade(g)),
        regions: result.regions.map(r => new Region(r)),
        searchWeightSettings: new SearchWeightSettings(result.search_weight_settings),
        flightQuerySettings: result.flight_query_settings,
        distanceSetting: new DistanceSetting(result.distance_setting),
        freeeAuthUri: result.freee_auth_uri,
        smartHrAuthUri: result.smart_hr_auth_uri,
        organization: new Organization(result.organization),
        dailyAllowanceAvailability: result.daily_allowance_availability,
        restrictionDistanceAndTimeAvailability: result.restriction_distance_and_time_availability,
        ssoIssuer: result.sso_issuer,
        ssoServiceUrl: result.sso_service_url,
        ssoLogoutServiceUrl: result.sso_logout_service_url,
        exclusionAirlines: result.exclusion_airlines,
        accountTypeMappings: result.account_type_mappings.map(m => new AccountTypeMapping(m)),
        expensesAccountTypes: result.expenses_account_types.map(t => new ExpensesAccountType(t)),
        departments: result.departments.map(d => new Department(d)),
        selectedFreeePartner: result.freee_partner || null,
        selectedFreeeSection: result.freee_section || null,
        freeeApprovalFlowRoutes: result.freee_approval_flow_routes || [],
        freeeForms: result.freee_forms || [],
        passwordPolicy: result.password_policy,
        expenseByMunicipalityFlg: result.expense_by_municipality_flg,
        expensesAccountTypeFlg: result.expenses_account_type_flg,
        expenseTool: result.expense_tool,
        contractBiztraPro: result.contract_biztra_pro,
        smartHrAssociateColumnTypes: result.smart_hr_associate_column_types.map(
          s => new SmartHrAssociateColumnType(s)
        ),
        associateColumnEmail: this.filteredSmartHrAssociateColumn(
          result.smart_hr_associate_columns.map((s: any) => new SmartHrAssociateColumn(s)),
          'email'
        ).associateColumn,
        associateCustomName: this.filteredSmartHrAssociateColumn(
          result.smart_hr_associate_columns.map((s: any) => new SmartHrAssociateColumn(s)),
          'email'
        ).customName,
        smartHrAssociateColumns: result.smart_hr_associate_columns.map(s => new SmartHrAssociateColumn(s)),
        nonApproverDepartments: result.non_approver_departments.map(d => new Department(d))
      });
    } catch (e) {
      Notification.error(
        e.status === 400 ? e.responseJSON.errors : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
      );
      utils.sendErrorObject(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchAirports() {
    const res = await utils.jsonPromise<{ airlines: InternationalAirline[] }>('/international_airlines.json');
    this.setState({ internationalAirlines: res.airlines });
  }

  async fetchAirlines() {
    const res = await utils.jsonPromise<{ airports: InternationalAirport[] }>('/international_airports.json');
    this.setState({ internationalAirports: res.airports });
  }

  async fetchMunicipalities(prefectutreId: number) {
    const res = await utils.jsonPromise<ResionsMunicipalitiesResponse>(
      `/organization/prefectures/${prefectutreId}/municipalities`
    );
    const regions = this.state.regions;
    res.regions_municipality.forEach(resr => {
      if (resr !== null) {
        regions.map(r => {
          if (r.id === resr.id) {
            r.setMunicipalities(resr);
          }
          return r;
        });
      }
    });
    this.setState({ regions });
    app.render();
  }

  handleAddGrade() {
    const biggestGrade = _.last(this.state.grades);
    const newPrice = biggestGrade ? biggestGrade.hotelPriceLimit : 8500;
    const newForeignPrice = biggestGrade ? biggestGrade.foreignHotelPriceLimit : 8500;
    const newWeight = biggestGrade ? biggestGrade.weight + 1 : 1;
    const newGrade = new Grade({
      name: `等級+${newWeight}`,
      hotel_price_limit: newPrice,
      foreign_hotel_price_limit: newForeignPrice,
      weight: newWeight,
      default_seat_classes: [],
      shinkansen_seat_types: ['free', 'reserve', 'green'],
      default_ticket_type: 'changeable',
      domestic_daily_allowance_with_stay: 0,
      domestic_daily_allowance_without_stay: 0,
      foreign_daily_allowance_with_stay: 0,
      lodging_check: false,
      domestic_air_seat_upgrade: false,
      allow_breakfast_domestic_hotel: true,
      allow_breakfast_foreign_hotel: true
    });
    this.state.grades.push(newGrade);
    app.render();
  }

  filteredSmartHrAssociateColumn(value: SmartHrAssociateColumn[], target: string) {
    const filteredColumns = value.filter(s => s.targetColumn === target);
    if (filteredColumns.length > 0) {
      return filteredColumns[0];
    }
    return SmartHrAssociateColumn.initialize();
  }

  hasErrorSmartHrCustomName = (value: string) => {
    const isValid = this.state.associateColumnEmail === 'crew_custom_email' ? value && value.trim() !== '' : true;
    this.setState({
      error: !isValid,
      errorMessage: isValid ? '' : 'emailのカスタム項目名を設定してください'
    });
    return isValid;
  };

  submit() {
    if (!this.state.setting || !this.state.organization) {
      return;
    }

    if (!this.hasErrorSmartHrCustomName(this.state.associateCustomName)) {
      Notification.error('SmartHRの連携するメールアドレス：emailのカスタム項目名を設定してください');
      return;
    }

    this.setState({ loading: true });
    const params = _.merge(this.state.setting.submitParams(), {
      grades: this.state.grades.map(g => g.submitParams()),
      regions: this.state.regions.map(r => r.submitParams()),
      search_weight_settings: this.state.searchWeightSettings?.submitParams(),
      flight_query_settings: this.state.flightQuerySettings,
      distance_setting: this.state.distanceSetting?.submitParams(),
      selected_freee_company_id: this.state.organization ? this.state.organization.selectedFreeeCompanyId : null,
      freee_partner_id: this.state.organization.freeePartnerId,
      freee_monthly_price_section_id: this.state.organization.freeeMonthlyPriceSectionId,
      freee_project_destination: this.state.organization.freeeProjectDestination,
      is_create_freee_tag: this.state.organization.isCreateFreeeTag,
      freee_department: this.state.organization.freeeDepartment,
      freee_workflow: this.state.organization.freeeWorkflow,
      freee_partner_setting: this.state.organization.freeePartnerSetting,
      freee_workflow_approval_flow_route_id_domestic: this.selectedFreeeApprovalFlowRouteDomesticOption()?.value,
      freee_workflow_form_id_domestic: this.selectedFreeeFormDomesticOption()?.value,
      freee_workflow_approval_flow_route_id_foreign: this.selectedFreeeApprovalFlowRouteForeignOption()?.value,
      freee_workflow_form_id_foreign: this.selectedFreeeFormForeignOption()?.value,
      sso_enabled: this.state.organization.ssoEnabled,
      sso_entity_id: this.state.organization.ssoEntityId,
      sso_url: this.state.organization.ssoUrl,
      sso_logout_url: this.state.organization.ssoLogoutUrl,
      sso_idp_cert: this.state.organization.ssoIdpCert,
      exclusion_airlines: this.state.exclusionAirlines,
      account_type_mappings: this.state.accountTypeMappings.map(m => m.submitParams()),
      smart_hr_associate_columns: this.state.smartHrAssociateColumns.map(m => m.submitParams()),
      password_policy: this.state.passwordPolicy,
      expense_tool: this.state.expenseTool
    });
    utils
      .jsonPromise('/organization/setting', params, 'PUT')
      .then(
        () => {
          Notification.success('保存が完了しました');
          this.setState({ loading: false });
          this.fetchSetting();
        },
        error => {
          Notification.error(
            error.status === 400
              ? error.responseJSON.errors
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          );
          this.setState({
            loading: false
          });
        }
      )
      .catch(e => utils.sendErrorObject(e));
  }

  hasSeatClass = (grade: Grade, seatClass: string) => {
    return !!grade.defaultSeatClasses.find(s => s === seatClass);
  };

  hasShinkansenType = (grade: Grade, seatType: shinkansenSeatType) => {
    return !!grade.shinkansenSeatTypes.find(s => s === seatType);
  };

  handleTicketTypeChange = (index: number, ticketType: TicketType) => {
    const grade = this.state.grades[index];
    grade.defaultTicketType = ticketType;
    app.render();
  };

  handleSeatClassChange = (index: number, value: string) => {
    const grade = this.state.grades[index];
    const hasIndex = grade.defaultSeatClasses.findIndex(s => s === value);
    if (hasIndex < 0) {
      grade.defaultSeatClasses.push(value);
    } else {
      grade.defaultSeatClasses.splice(hasIndex, 1);
    }

    app.render();
  };

  handleShinkansenSeatChange = (index: number, value: shinkansenSeatType) => {
    const grade = this.state.grades[index];
    const hasIndex = grade.shinkansenSeatTypes.findIndex(s => s === value);
    if (hasIndex < 0) {
      grade.shinkansenSeatTypes.push(value);
    } else {
      grade.shinkansenSeatTypes.splice(hasIndex, 1);
    }

    app.render();
  };

  handleFlightQuerySettings = (settings: FlightQuerySetting[]) => {
    this.setState({ flightQuerySettings: settings });
  };

  handleExclusionAirlinesSettings = (exclusionAirlines: string[]) => {
    this.setState({ exclusionAirlines });
  };

  disconnectFreee() {
    utils.jsonPromise('/organization/freee/token', {}, 'DELETE').then(
      () => {
        this.fetchSetting();
      },
      error => {
        Notification.error(
          error.status === 400
            ? error.responseJSON.errors
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
      }
    );
  }

  disconnectSmartHr() {
    utils.jsonPromise('/organization/smart_hr/token', {}, 'DELETE').then(() => {
      this.fetchSetting();
    });
  }

  selectedFreeeApprovalFlowRouteDomesticOption(): FilterableSelectorOption | null {
    const organization = this.state.organization;
    if (!organization) {
      return null;
    }
    if (!organization.freeeWorkflowApprovalFlowRouteIdDomestic) {
      return null;
    }
    const route = this.state.freeeApprovalFlowRoutes.find(
      r => r.id === organization.freeeWorkflowApprovalFlowRouteIdDomestic
    );
    if (!route) {
      return null;
    }
    return {
      label: route.name,
      value: route.id.toString(),
      matcher: route.name
    };
  }

  selectedFreeeFormDomesticOption(): FilterableSelectorOption | null {
    const organization = this.state.organization;
    if (!organization) {
      return null;
    }
    if (!organization.freeeWorkflowFormIdDomestic) {
      return null;
    }
    const form = this.state.freeeForms.find(f => f.id === organization.freeeWorkflowFormIdDomestic);
    if (!form) {
      return null;
    }
    return {
      label: form.name,
      value: form.id.toString(),
      matcher: form.name
    };
  }

  selectedFreeeApprovalFlowRouteForeignOption(): FilterableSelectorOption | null {
    const organization = this.state.organization;
    if (!organization) {
      return null;
    }
    if (!organization.freeeWorkflowApprovalFlowRouteIdForeign) {
      return null;
    }
    const route = this.state.freeeApprovalFlowRoutes.find(
      r => r.id === organization.freeeWorkflowApprovalFlowRouteIdForeign
    );
    if (!route) {
      return null;
    }
    return {
      label: route.name,
      value: route.id.toString(),
      matcher: route.name
    };
  }

  selectedFreeeFormForeignOption(): FilterableSelectorOption | null {
    const organization = this.state.organization;
    if (!organization) {
      return null;
    }
    if (!organization.freeeWorkflowFormIdForeign) {
      return null;
    }
    const form = this.state.freeeForms.find(f => f.id === organization.freeeWorkflowFormIdForeign);
    if (!form) {
      return null;
    }
    return {
      label: form.name,
      value: form.id.toString(),
      matcher: form.name
    };
  }

  handleSsoIdpCertFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const organization = this.state.organization;
    if (!organization) {
      return;
    }
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      organization.setSsoIdpCertFile(file);
    }
  }

  handleRakurakuDataSetting() {
    if (this.state.setting === null) {
      return;
    }
    const newSetting = this.state.setting;
    newSetting.rakurakuSetting.exicOrderDataStopProvide = !newSetting.rakurakuSetting.exicOrderDataStopProvide;
    this.setState({ setting: newSetting });
  }

  async handleChangeFreeeCompany(value: string) {
    const organization = this.state.organization;
    if (!organization) {
      return;
    }

    if (!value || value === '') {
      organization.selectedFreeeCompanyId = undefined;
      organization.freeePartnerId = undefined;
      organization.freeeMonthlyPriceSectionId = undefined;
      return;
    }

    organization.selectedFreeeCompanyId = parseInt(value, 10);
    this.handleChangeFreeeCompanyPartner(organization);
    this.handleChangeFreeeCompanySection(organization);
  }

  async handleChangeFreeeCompanyPartner(organization: Organization) {
    const partnerName = this.state.selectedFreeePartner?.name;
    if (!partnerName) {
      organization.freeePartnerId = undefined;
      this.setState({ selectedFreeePartner: null });
      return;
    }

    const partners = await this.fetchFreeePartners(partnerName);
    const partner = partners.find(p => p.name === partnerName);
    if (partner) {
      organization.freeePartnerId = partner.id;
      this.setState({ selectedFreeePartner: partner });
    } else {
      organization.freeePartnerId = undefined;
      this.setState({ selectedFreeePartner: null });
    }
  }

  async handleChangeFreeeCompanySection(organization: Organization) {
    const sectionName = this.state.selectedFreeeSection?.name;
    if (!sectionName) {
      organization.freeeMonthlyPriceSectionId = undefined;
      this.setState({ selectedFreeeSection: null });
      return;
    }

    const sections = await this.fetchFreeeSections(sectionName);
    const section = sections.find(s => s.name === sectionName);
    if (section) {
      organization.freeeMonthlyPriceSectionId = section.id;
      this.setState({ selectedFreeeSection: section });
    } else {
      organization.freeeMonthlyPriceSectionId = undefined;
      this.setState({ selectedFreeeSection: null });
    }
  }

  handleFreeeWorkflowToggle() {
    const organization = this.state.organization;
    if (!organization) {
      return;
    }
    organization.toggleFreeeWorkflow();
  }

  handleExpenseTool = (tool: string) => {
    this.setState({ expenseTool: tool });
  };

  async handleMunicipalityToggle(region: Region, resionId: number, prefId: number) {
    if (region.getMunicipalities(prefId).length === 0) {
      await this.fetchMunicipalities(prefId);
    }
    this.state.regions.forEach(r => {
      if (r.id === resionId) {
        r.toggleMunicipalitiesDisabled(prefId);
        app.render();
      }
    });
  }

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  async fetchFreeePartners(keyword: string | undefined) {
    const params = {
      keyword,
      selected_freee_company_id: this.state.organization?.selectedFreeeCompanyId
    };

    return Fetcher.get<FreeePartner[]>('/organization/freee/partners.json', params);
  }

  async fetchFreeeSections(keyword: string | undefined) {
    const params = {
      keyword,
      selected_freee_company_id: this.state.organization?.selectedFreeeCompanyId
    };

    return Fetcher.get<FreeeSection[]>('/organization/freee/sections.json', params);
  }

  render() {
    const { serviceId } = this.props;
    const {
      loading,
      setting,
      grades,
      regions,
      searchWeightSettings,
      flightQuerySettings,
      distanceSetting,
      seatClassTypes,
      shinkansenSeatTypes,
      internationalAirlines,
      internationalAirports,
      freeeAuthUri,
      smartHrAuthUri,
      organization,
      dailyAllowanceAvailability,
      restrictionDistanceAndTimeAvailability,
      selectedFreeePartner,
      selectedFreeeSection,
      freeeApprovalFlowRoutes,
      freeeForms,
      freeeLoading,
      ssoIssuer,
      ssoServiceUrl,
      ssoLogoutServiceUrl,
      exclusionAirlines,
      accountTypeMappings,
      expensesAccountTypes,
      departments,
      expenseByMunicipalityFlg,
      expensesAccountTypeFlg,
      expenseTool,
      contractBiztraPro,
      smartHrAssociateColumnTypes,
      smartHrAssociateColumns,
      error,
      errorMessage,
      tripReportAvailable
    } = this.state;
    // 環境により文字化けしたので、frontで変換することにした
    const smartHrErrorMessages: Record<string, string> = {
      state_mismatch: 'stateが不一致の為、連携に失敗しました',
      invalid_request: '認可情報が無効です',
      invalid_grant: '認可情報が無効、もしくは有効期限切れです',
      unauthorized_client: 'クライアントは連携を実行する権限がありません',
      access_denied: 'リソースオーナーもしくは認可サーバがリクエストを拒否しました',
      temporarily_unavailable:
        '認可サーバが一時的に高負荷な状態にあるか、もしくはメンテナンス中であるため、利用できません',
      invalid_client:
        'クライアントが不明、クライアント認証が含まれていない、もしくは無効なクライアント認証方法のため、連携は失敗しました',
      invalid_redirect_uri: '含まれるリダイレクトURIが正しくありません',
      unsupported_grant_type: '認可サーバは指定されたグラントタイプをサポートしていません',
      unsupported_response_type: '認可サーバは指定されたレスポンスタイプをサポートしていません',
      server_error: '予期せぬ事態が発生したため、認可サーバはリクエストを処理できませんでした'
    };
    // const params = useParams();
    return (
      <>
        <OrganizationTitle>システム設定</OrganizationTitle>
        <OrganizationHeader>
          <Button style={{ marginLeft: 'auto', padding: '3px 20px' }} onClick={() => this.submit()}>
            保存
          </Button>
        </OrganizationHeader>
        <Body>
          {loading ? (
            <SimpleLoading />
          ) : (
            setting &&
            organization && (
              <>
                <Section>
                  <SectionName>サービス</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      共通
                    </SubSectionTitle>
                    <Line>
                      <LineLabel>JR購入区間</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.purchaseSection}
                          onChange={e => setting.setPurchaseSection(parseInt(e.target.value, 10))}
                        >
                          <option value={0}>新幹線・特急区間のみ</option>
                          <option value={1}>新幹線・特急区間＋ローカル線を含む</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>プライベート利用</LineLabel>
                      <LineSelectWrapper>
                        <FlexLabel>
                          <input
                            id="allow-private-use"
                            type="checkbox"
                            checked={setting.allowPrivateUse}
                            onChange={() => {
                              setting.allowPrivateUse = !setting.allowPrivateUse;
                            }}
                          />
                          <div>許可する</div>
                        </FlexLabel>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>転送アドレス</LineLabel>
                      <LineSelectWrapper>
                        <FlexLabel>
                          <input
                            id="send-user-forwarded-email"
                            type="checkbox"
                            checked={setting.sendUserForwardedEmail}
                            onChange={() => {
                              setting.setSendUserForwardedEmail(!setting.sendUserForwardedEmail);
                            }}
                          />
                          <div>許可する</div>
                        </FlexLabel>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>マイレージ番号表示</LineLabel>
                      <LineSelectWrapper>
                        <FlexLabel>
                          <input
                            id="disable-show-milage-number"
                            type="checkbox"
                            checked={!setting.disableShowMilageNumber}
                            onChange={() => {
                              setting.disableShowMilageNumber = !setting.disableShowMilageNumber;
                            }}
                          />
                          <div>許可する</div>
                        </FlexLabel>
                      </LineSelectWrapper>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      出張申請
                    </SubSectionTitle>
                    <Line>
                      <LineLabel>出張先</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.destinationBusinessTrip}
                          onChange={e => setting.setDestinationBusinessTrip(e.target.value as WorkflowSetting)}
                        >
                          <option value="mandatory">必須にする</option>
                          <option value="optional">必須にしない</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>出張の目的</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.purposeBusinessTrip}
                          onChange={e => setting.setPurposeBusinessTrip(e.target.value as WorkflowSetting)}
                        >
                          <option value="mandatory">必須にする</option>
                          <option value="optional">必須にしない</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>社内管理番号</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.internalNumber}
                          onChange={e => setting.setInternalNumber(e.target.value as WorkflowRelatedSetting)}
                        >
                          <option value="mandatory">必須にする</option>
                          <option value="optional">必須にしない</option>
                          <option value="hidden">非表示</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>プロジェクト名</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.projectName}
                          onChange={e => setting.setProjectName(e.target.value as WorkflowRelatedSetting)}
                        >
                          <option value="mandatory">必須にする</option>
                          <option value="optional">必須にしない</option>
                          <option value="hidden">非表示</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    <Line>
                      <LineLabel>費用負担先部署</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.chargingDepartment}
                          onChange={e => setting.setChargingDepartment(e.target.value as WorkflowRelatedSetting)}
                        >
                          <option value="mandatory">必須にする</option>
                          <option value="optional">必須にしない</option>
                          <option value="hidden">非表示</option>
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                    {expensesAccountTypeFlg && (
                      <Line>
                        <LineLabel>勘定科目</LineLabel>
                        <LineSelectWrapper>
                          <Select
                            value={setting.expensesAccountType}
                            onChange={e =>
                              setting.setExpensesAccountType(e.target.value as WorkflowRelatedSetting)
                            }
                          >
                            <option value="mandatory">必須にする</option>
                            <option value="optional">必須にしない</option>
                            <option value="hidden">非表示</option>
                          </Select>
                        </LineSelectWrapper>
                      </Line>
                    )}
                  </SubSection>
                  {organization.useNonOrderItem && (
                    <SubSection>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        {organization.userDashboardLabel2 || '申請から始める'}
                      </SubSectionTitle>
                      <Line>
                        <LineLabel>0円申請を禁止する</LineLabel>
                        <LineSelectWrapper>
                          <FlexLabel>
                            <input
                              id="prevent-zero-amount-application"
                              type="checkbox"
                              checked={setting.preventZeroAmountApplication}
                              onChange={() => {
                                setting?.setPreventZeroAmountApplication(!setting.preventZeroAmountApplication);
                              }}
                            />
                            <div>有効にする</div>
                          </FlexLabel>
                        </LineSelectWrapper>
                      </Line>
                      {organization.useSmartSearch && (
                        <Line>
                          <LineLabel>デフォルトでSmart Searchを有効にする</LineLabel>
                          <LineSelectWrapper>
                            <FlexLabel>
                              <input
                                id="default-smart-search-enabled"
                                type="checkbox"
                                checked={setting.defaultSmartSearchEnabled}
                                onChange={() => {
                                  setting?.setDefaultSmartSearchEnabled(!setting.defaultSmartSearchEnabled);
                                }}
                              />
                              <div>有効にする</div>
                            </FlexLabel>
                          </LineSelectWrapper>
                        </Line>
                      )}
                    </SubSection>
                  )}
                  {tripReportAvailable && (
                    <SubSection>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        出張報告
                      </SubSectionTitle>
                      <Line>
                        <LineLabel>活動内容</LineLabel>
                        <LineSelectWrapper>
                          <Select
                            value={setting.tripReportActivityContent}
                            onChange={e =>
                              setting.setTripReportActivityContent(e.target.value as WorkflowRelatedSetting)
                            }
                          >
                            <option value="mandatory">必須にする</option>
                            <option value="optional">必須にしない</option>
                            <option value="hidden">非表示</option>
                          </Select>
                        </LineSelectWrapper>
                      </Line>
                      <Line>
                        <LineLabel>成果内容</LineLabel>
                        <LineSelectWrapper>
                          <Select
                            value={setting.tripReportResultsContent}
                            onChange={e =>
                              setting.setTripReportResultsContent(e.target.value as WorkflowRelatedSetting)
                            }
                          >
                            <option value="mandatory">必須にする</option>
                            <option value="optional">必須にしない</option>
                            <option value="hidden">非表示</option>
                          </Select>
                        </LineSelectWrapper>
                      </Line>
                      <Line>
                        <LineLabel>その他</LineLabel>
                        <LineSelectWrapper>
                          <Select
                            value={setting.tripReportOtherText}
                            onChange={e =>
                              setting.setTripReportOtherText(e.target.value as WorkflowRelatedSetting)
                            }
                          >
                            <option value="mandatory">必須にする</option>
                            <option value="optional">必須にしない</option>
                            <option value="hidden">非表示</option>
                          </Select>
                        </LineSelectWrapper>
                      </Line>
                    </SubSection>
                  )}
                </Section>
                <Section>
                  <SectionName>旅費規程</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      共通
                    </SubSectionTitle>
                    <Line>
                      {dailyAllowanceAvailability && (
                        <>
                          <LineLabel>日当集計単位</LineLabel>
                          <FlexLabel>
                            <input
                              type="radio"
                              id="aggregate_unit_only_stay"
                              checked={setting.aggregateUnit === 'only_stay'}
                              onChange={() => {
                                setting.setAggregateUnit('only_stay');
                              }}
                            />
                            <label htmlFor="aggregate_unit_only_stay">宿泊のみ</label>
                            <input
                              type="radio"
                              id="aggregate_unit_stay_and_day_trip"
                              checked={setting.aggregateUnit === 'stay_and_day_trip'}
                              onChange={() => {
                                setting.setAggregateUnit('stay_and_day_trip');
                              }}
                            />
                            <label htmlFor="aggregate_unit_stay_and_day_trip">宿泊＋日帰り</label>
                            <Tooltip place="right" width={660}>
                              <p>宿泊日当・・・宿泊を伴う勤務地以外での業務が発生した場合に支払う手当。</p>
                              <CommentAggregateUnit>多くは宿泊日のみに支払うケースが多い。</CommentAggregateUnit>
                              <CommentAggregateUnit>
                                宿泊日当が付与されている場合、該当の宿泊日と翌日は日帰日当は付与されない。
                              </CommentAggregateUnit>
                              <p>日帰り日当・・・宿泊を伴わない勤務地以外での業務が発生した場合に支払う手当。</p>
                              <CommentAggregateUnit>多くは距離による制限がある場合が多い。</CommentAggregateUnit>
                              <CommentAggregateUnit>
                                宿泊日当が付与されている場合、該当の宿泊日と翌日は日帰日当は付与されない。
                              </CommentAggregateUnit>
                              <br />
                              <CommentAggregateUnit>
                                なお、宿泊日当の対象が「出張期間」である場合（1泊2日の出張の際に、宿泊日当が
                              </CommentAggregateUnit>
                              <CommentAggregateUnit>
                                2日分払われる場合）、この場合は宿泊手当が充当され日帰り日当金額は充当されない
                              </CommentAggregateUnit>
                              <p />
                            </Tooltip>
                          </FlexLabel>
                        </>
                      )}
                    </Line>
                    <Line>
                      {dailyAllowanceAvailability && (
                        <>
                          <LineLabel>日当集計基準</LineLabel>
                          <FlexLabel>
                            <input
                              type="radio"
                              id="aggregate_base_business_trip_date"
                              checked={setting.aggregateBase === 'business_trip_date'}
                              onChange={() => {
                                setting.setAggregateBase('business_trip_date');
                              }}
                            />
                            <label htmlFor="aggregate_base_business_trip_date">出張日</label>
                            <input
                              type="radio"
                              id="aggregate_base_business_trip_period"
                              checked={setting.aggregateBase === 'business_trip_period'}
                              onChange={() => {
                                setting.setAggregateBase('business_trip_period');
                              }}
                            />
                            <label htmlFor="aggregate_base_business_trip_period">出張期間</label>
                            <Tooltip place="right" width={620}>
                              <p>検索期間の帰着日・・・TripID「旅程番号」の日付を基準に該当案件を出力。</p>
                              <CommentAggregateBase>
                                TripIDの終わりの日付が含まれていれば該当案件として出力される。
                              </CommentAggregateBase>
                              <CommentAggregateBase>
                                例）検索期間が「2/1~2/28」の場合、旅程が1/29~2/1のものは含まれる。
                              </CommentAggregateBase>
                              <CommentAggregateBase2>一方で旅程が2/27~3/1のものは含まれない</CommentAggregateBase2>
                            </Tooltip>
                          </FlexLabel>
                        </>
                      )}
                    </Line>
                    {restrictionDistanceAndTimeAvailability && (
                      <>
                        <Line>
                          <LineLabel>規程適用距離</LineLabel>
                          <FlexLabel>
                            <input
                              type="radio"
                              id="stay_distance_direct"
                              checked={distanceSetting.calcMode === 'direct'}
                              onChange={e =>
                                this.setState({ distanceSetting: distanceSetting.update('calcMode', 'direct') })
                              }
                            />
                            <label htmlFor="stay_distance_direct">直線距離</label>
                            <input
                              type="radio"
                              id="stay_distance_aitravel"
                              checked={distanceSetting.calcMode === 'aitravel'}
                              onChange={e =>
                                this.setState({
                                  distanceSetting: distanceSetting.update('calcMode', 'aitravel')
                                })
                              }
                            />
                            <label htmlFor="stay_distance_aitravel">AI Travel定義距離</label>
                            <Tooltip place="right" width={460}>
                              <p>直線距離: 出発地または拠点～目的地の緯度経度を元にした直線距離（片道）</p>
                              <p>
                                AI Travel定義距離:
                                出発地または拠点～目的地の最寄り駅/空港～目的地間の移動距離（片道）
                              </p>
                            </Tooltip>
                          </FlexLabel>
                        </Line>
                        <Line>
                          <LineLabel>移動距離・時間計算の起点</LineLabel>
                          <FlexLabel>
                            <input
                              type="radio"
                              id="starting_point_organization_base"
                              checked={distanceSetting.startingPoint === 'organization_base'}
                              onChange={e =>
                                this.setState({
                                  distanceSetting: distanceSetting.update('startingPoint', 'organization_base')
                                })
                              }
                            />
                            <label htmlFor="starting_point_organization_base">拠点から</label>
                            <input
                              type="radio"
                              id="starting_point_origin"
                              checked={distanceSetting.startingPoint === 'origin'}
                              onChange={e =>
                                this.setState({
                                  distanceSetting: distanceSetting.update('startingPoint', 'origin')
                                })
                              }
                            />
                            <label htmlFor="starting_point_origin">出発地から</label>
                          </FlexLabel>
                        </Line>
                        {dailyAllowanceAvailability && (
                          <Line>
                            <LineLabel>宿泊日当反映有効移動距離</LineLabel>
                            <LineBody>
                              <LineColumn>
                                <FlexLabel>
                                  <input
                                    type="checkbox"
                                    checked={distanceSetting.allowanceDistanceLimit !== null}
                                    onChange={e =>
                                      this.setState({
                                        distanceSetting: distanceSetting.update(
                                          'allowanceDistanceLimit',
                                          e.target.checked ? 0 : null
                                        )
                                      })
                                    }
                                  />
                                  <div>設定する</div>
                                </FlexLabel>
                              </LineColumn>
                              {distanceSetting.allowanceDistanceLimit !== null && (
                                <DistanceLineColumn>
                                  <input
                                    type="number"
                                    placeholder="例) 100"
                                    value={
                                      distanceSetting.allowanceDistanceLimit
                                        ? distanceSetting.allowanceDistanceLimit.toString()
                                        : ''
                                    }
                                    onChange={e =>
                                      this.setState({
                                        distanceSetting: distanceSetting.update(
                                          'allowanceDistanceLimit',
                                          parseInt(e.target.value, 10) || 0
                                        )
                                      })
                                    }
                                  />
                                  <div>km</div>
                                </DistanceLineColumn>
                              )}
                            </LineBody>
                          </Line>
                        )}
                        <Line>
                          <LineLabel>宿泊アラート移動距離</LineLabel>
                          <LineBody>
                            <LineColumn>
                              <FlexLabel>
                                <input
                                  type="checkbox"
                                  checked={distanceSetting.distanceLimit !== null}
                                  onChange={e =>
                                    this.setState({
                                      distanceSetting: distanceSetting.update(
                                        'distanceLimit',
                                        e.target.checked ? 0 : null
                                      )
                                    })
                                  }
                                />
                                <div>設定する</div>
                              </FlexLabel>
                            </LineColumn>
                            {distanceSetting.distanceLimit !== null && (
                              <DistanceLineColumn>
                                <input
                                  type="number"
                                  placeholder="例) 100"
                                  value={
                                    distanceSetting.distanceLimit ? distanceSetting.distanceLimit.toString() : ''
                                  }
                                  onChange={e =>
                                    this.setState({
                                      distanceSetting: distanceSetting.update(
                                        'distanceLimit',
                                        parseInt(e.target.value, 10) || 0
                                      )
                                    })
                                  }
                                />
                                <div>km</div>
                              </DistanceLineColumn>
                            )}
                          </LineBody>
                        </Line>
                        <Line>
                          <LineLabel>宿泊アラート移動時間</LineLabel>
                          <LineBody>
                            <LineColumn>
                              <FlexLabel>
                                <input
                                  type="checkbox"
                                  checked={distanceSetting.timeLimit !== null}
                                  onChange={e =>
                                    this.setState({
                                      distanceSetting: distanceSetting.update(
                                        'timeLimit',
                                        e.target.checked ? 0 : null
                                      )
                                    })
                                  }
                                />
                                <div>設定する</div>
                              </FlexLabel>
                            </LineColumn>
                            {distanceSetting.timeLimit !== null && (
                              <DistanceLineColumn>
                                <input
                                  type="number"
                                  placeholder="例) 120"
                                  value={distanceSetting.timeLimit ? distanceSetting.timeLimit.toString() : ''}
                                  onChange={e =>
                                    this.setState({
                                      distanceSetting: distanceSetting.update(
                                        'timeLimit',
                                        parseInt(e.target.value, 10) || 0
                                      )
                                    })
                                  }
                                />
                                <div>分</div>
                              </DistanceLineColumn>
                            )}
                          </LineBody>
                        </Line>
                      </>
                    )}
                    <Line>
                      <LineLabel>役職等級</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeInput
                              type="text"
                              className="grade-box"
                              value={g.name}
                              onChange={e => g.setName(e.target.value)}
                            />
                          </LineColumn>
                        ))}
                        <LineColumn>
                          <AddGradeButton onClick={() => this.handleAddGrade()}>等級を追加</AddGradeButton>
                        </LineColumn>
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        国内ホテル
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    <Line>
                      <LineLabel>
                        宿泊費(税込)
                        <DetailButton onClick={() => regions.map(r => r.toggleRegionDisabled())}>
                          {regions[0].hidden ? '地方別に設定 ▼' : '閉じる ▲'}
                        </DetailButton>
                      </LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <input
                              type="number"
                              value={g.hotelPriceLimit || 0}
                              onChange={e =>
                                g.setHotelPriceLimit(parseInt(utils.sanitizePrice(e.target.value) || '0', 10))
                              }
                            />
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                    {regions.map(r => (
                      <div key={`region-${r.id}`}>
                        {!r.hidden && (
                          <>
                            <Line>
                              <AreaLabel>
                                {r.name}
                                {r.id !== 1 && (
                                  <DetailButton onClick={() => r.togglePrefectureDisabled()}>
                                    {r.prefectures[0].hidden ? '都道府県別に設定 ▼' : '閉じる ▲'}
                                  </DetailButton>
                                )}
                                {r.id === 1 && expenseByMunicipalityFlg && (
                                  <DetailButton onClick={() => this.handleMunicipalityToggle(r, r.id, 1)}>
                                    {r.isMunicipalitiesHidden(1) ? '市区町村別に設定 ▼' : '閉じる ▲'}
                                  </DetailButton>
                                )}
                              </AreaLabel>
                              <LineBody>
                                {r.grades.map((g, i) => (
                                  <LineColumn key={`region-${r.id}-grade-${i}`}>
                                    <input
                                      type="number"
                                      value={g.hotelPriceLimit}
                                      onChange={e =>
                                        r.setHotelPriceLimit(
                                          parseInt(utils.sanitizePrice(e.target.value) || '', 10),
                                          g.id,
                                          0
                                        )
                                      }
                                    />
                                  </LineColumn>
                                ))}
                                <LineColumn />
                              </LineBody>
                            </Line>
                            {r.id !== 1 ? (
                              <>
                                {r.prefectures.map(p => (
                                  <div key={`prefecture-${p.id}`}>
                                    {!p.hidden && (
                                      <Line>
                                        <PrefLabel>
                                          {p.name}
                                          {expenseByMunicipalityFlg && (
                                            <AreaLabel>
                                              <DetailButton
                                                onClick={() => this.handleMunicipalityToggle(r, r.id, p.id)}
                                              >
                                                {r.isMunicipalitiesHidden(p.id)
                                                  ? '市区町村別に設定 ▼'
                                                  : '閉じる ▲'}
                                              </DetailButton>
                                            </AreaLabel>
                                          )}
                                        </PrefLabel>
                                        <LineBody>
                                          {p.grades.map((g, i) => (
                                            <LineColumn key={`prefecture-${p.id}-grade-${i}`}>
                                              <input
                                                type="number"
                                                value={g.hotelPriceLimit}
                                                onChange={e =>
                                                  r.setHotelPriceLimit(
                                                    parseInt(utils.sanitizePrice(e.target.value) || '', 10),
                                                    g.id,
                                                    p.id
                                                  )
                                                }
                                              />
                                            </LineColumn>
                                          ))}
                                          <LineColumn />
                                        </LineBody>
                                      </Line>
                                    )}
                                    {r.getMunicipalities(p.id).map(m => (
                                      <div key={`municipality-${m.municipalityCode}`}>
                                        {!m.hidden && (
                                          <Line>
                                            <PrefLabel>{m.name}</PrefLabel>
                                            <LineBody>
                                              {m.grades.map((g, i) => (
                                                <LineColumn key={`municipality-$m.municipalityCod}-grade-${i}`}>
                                                  <input
                                                    type="number"
                                                    value={g.hotelPriceLimit}
                                                    onChange={e =>
                                                      r.setHotelPriceLimitMunicipality(
                                                        parseInt(utils.sanitizePrice(e.target.value) || '', 10),
                                                        g.id,
                                                        p.id,
                                                        m.municipalityCode
                                                      )
                                                    }
                                                  />
                                                </LineColumn>
                                              ))}
                                              <LineColumn />
                                            </LineBody>
                                          </Line>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <>
                                {r.getMunicipalities(1).map(m => (
                                  <div key={`municipality-${m.municipalityCode}`}>
                                    {!m.hidden && (
                                      <Line>
                                        <PrefLabel>{m.name}</PrefLabel>
                                        <LineBody>
                                          {m.grades.map((g, i) => (
                                            <LineColumn key={`municipality-$m.municipalityCod}-grade-${i}`}>
                                              <input
                                                type="number"
                                                value={g.hotelPriceLimit}
                                                onChange={e =>
                                                  r.setHotelPriceLimitMunicipality(
                                                    parseInt(utils.sanitizePrice(e.target.value) || '', 10),
                                                    g.id,
                                                    1,
                                                    m.municipalityCode
                                                  )
                                                }
                                              />
                                            </LineColumn>
                                          ))}
                                          <LineColumn />
                                        </LineBody>
                                      </Line>
                                    )}
                                  </div>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        海外ホテル
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    <Line>
                      <LineLabel>宿泊費(税込)</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <input
                              type="number"
                              value={g.foreignHotelPriceLimit || 0}
                              onChange={e =>
                                g.setForeignHotelPriceLimit(
                                  parseInt(utils.sanitizePrice(e.target.value) || '0', 10)
                                )
                              }
                            />
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        宿泊
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    {restrictionDistanceAndTimeAvailability && (
                      <Line>
                        <LineLabel>宿泊チェッカー</LineLabel>
                        <LineBody>
                          {grades.map((g, i) => (
                            <LineColumn key={i}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={g.lodgingCheck}
                                  onChange={() => g.setLodgingCheck(!g.lodgingCheck)}
                                />
                                有効
                              </label>
                            </LineColumn>
                          ))}
                          <LineColumn />
                        </LineBody>
                      </Line>
                    )}
                    <Line>
                      <LineLabel>朝食指定の制御</LineLabel>
                      <LineBody />
                    </Line>
                    <Line>
                      <LineSubLabel>国内ホテル</LineSubLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <label>
                              <input
                                type="checkbox"
                                checked={g.allowBreakfastDomesticHotel}
                                onChange={() => g.setAllowBreakfastDomesticHotel(!g.allowBreakfastDomesticHotel)}
                              />
                              朝食許可
                            </label>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                    <Line>
                      <LineSubLabel>海外ホテル</LineSubLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <label>
                              <input
                                type="checkbox"
                                checked={g.allowBreakfastForeignHotel}
                                onChange={() => g.setAllowBreakfastForeignHotel(!g.allowBreakfastForeignHotel)}
                              />
                              朝食許可
                            </label>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        国内航空券
                        <Tooltip place="right">
                          <p>
                            変更不可を選択した場合でも、変更可の券種の方が安い場合は変更可の券種が優先的に表示されます
                          </p>
                        </Tooltip>
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    <Line>
                      <LineLabel>デフォルトの券種</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <FlexLabel>
                              <input
                                type="radio"
                                checked={g.defaultTicketType === 'changeable'}
                                onChange={() => this.handleTicketTypeChange(i, 'changeable')}
                              />
                              <div>変更可</div>
                            </FlexLabel>
                            <FlexLabel>
                              <input
                                type="radio"
                                checked={g.defaultTicketType === 'unchangeable'}
                                onChange={() => this.handleTicketTypeChange(i, 'unchangeable')}
                              />
                              <div>変更不可</div>
                            </FlexLabel>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                    <Line>
                      <LineLabel>アップグレード席</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <label>
                              <input
                                type="checkbox"
                                checked={g.domesticAirSeatUpgrade}
                                onChange={() => g.setDomesticAirSeatUpgradeCheck(!g.domesticAirSeatUpgrade)}
                              />
                              表示
                            </label>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        海外航空券
                        <Tooltip place="right">
                          <p>海外航空券の絞り込みの際にチェックした条件で絞り込みが行われます。</p>
                        </Tooltip>
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    <Line>
                      <LineLabel>シートクラス</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            {seatClassTypes.map((seat, j) => (
                              <FlexLabel key={j}>
                                <input
                                  type="checkbox"
                                  checked={this.hasSeatClass(g, seat.value)}
                                  onChange={() => this.handleSeatClassChange(i, seat.value)}
                                />
                                <div>{seat.displayName}</div>
                              </FlexLabel>
                            ))}
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionLine>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        新幹線
                      </SubSectionTitle>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            <GradeHeader>{g.name}</GradeHeader>
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </SubSectionLine>
                    <Line>
                      <LineLabel>座席</LineLabel>
                      <LineBody>
                        {grades.map((g, i) => (
                          <LineColumn key={i}>
                            {shinkansenSeatTypes.map((seat, j) => (
                              <FlexLabel key={j}>
                                <input
                                  type="checkbox"
                                  checked={this.hasShinkansenType(g, seat.value)}
                                  onChange={() => this.handleShinkansenSeatChange(i, seat.value)}
                                />
                                <div>{seat.displayName}</div>
                              </FlexLabel>
                            ))}
                          </LineColumn>
                        ))}
                        <LineColumn />
                      </LineBody>
                    </Line>
                  </SubSection>
                  {dailyAllowanceAvailability && (
                    <SubSection>
                      <SubSectionLine>
                        <SubSectionTitle>
                          <SubSectionTitleCircle />
                          1単位あたりの日当金額
                        </SubSectionTitle>
                        <LineBody>
                          {grades.map((g, i) => (
                            <LineColumn key={i}>
                              <GradeHeader>{g.name}</GradeHeader>
                            </LineColumn>
                          ))}
                          <LineColumn />
                        </LineBody>
                      </SubSectionLine>
                      <Line>
                        <LineLabel>国内日帰り日当(税込)</LineLabel>
                        <LineBody>
                          {grades.map((g, i) => (
                            <LineColumn key={i}>
                              <input
                                type="number"
                                value={g.domesticDailyAllowanceWithoutStay || 0}
                                onChange={e =>
                                  g.setDomesticDailyAllowanceWithoutStay(
                                    parseInt(utils.sanitizePrice(e.target.value) || '0', 10)
                                  )
                                }
                              />
                            </LineColumn>
                          ))}
                          <LineColumn />
                        </LineBody>
                      </Line>
                      <Line>
                        <LineLabel>国内宿泊日当(税込)</LineLabel>
                        <LineBody>
                          {grades.map((g, i) => (
                            <LineColumn key={i}>
                              <input
                                type="number"
                                value={g.domesticDailyAllowanceWithStay || 0}
                                onChange={e =>
                                  g.setDomesticDailyAllowanceWithStay(
                                    parseInt(utils.sanitizePrice(e.target.value) || '0', 10)
                                  )
                                }
                              />
                            </LineColumn>
                          ))}
                          <LineColumn />
                        </LineBody>
                      </Line>
                      <Line>
                        <LineLabel>海外宿泊日当(税込)</LineLabel>
                        <LineBody>
                          {grades.map((g, i) => (
                            <LineColumn key={i}>
                              <input
                                type="number"
                                value={g.foreignDailyAllowanceWithStay || 0}
                                onChange={e =>
                                  g.setForeignDailyAllowanceWithStay(
                                    parseInt(utils.sanitizePrice(e.target.value) || '0', 10)
                                  )
                                }
                              />
                            </LineColumn>
                          ))}
                          <LineColumn />
                        </LineBody>
                      </Line>
                    </SubSection>
                  )}
                </Section>
                <Section>
                  <SectionName>企業設定</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      共通
                    </SubSectionTitle>
                    <Line>
                      <LineLabel>期末月</LineLabel>
                      <LineSelectWrapper>
                        <Select
                          value={setting.financialLastMonth}
                          onChange={e => setting.setFinancialLastMonth(parseInt(e.target.value, 10))}
                        >
                          <option value="">選択してください</option>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
                            <option key={i} value={i}>
                              {i}月
                            </option>
                          ))}
                        </Select>
                      </LineSelectWrapper>
                    </Line>
                  </SubSection>
                </Section>
                <Section>
                  <SectionName>検索優先条件</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      海外航空券
                    </SubSectionTitle>
                    <FlightQuerySettingArea
                      flightQuerySettings={flightQuerySettings}
                      internationalAirports={internationalAirports}
                      internationalAirlines={internationalAirlines}
                      onChange={this.handleFlightQuerySettings}
                    />
                    <Line>
                      <LineLabelWithCircle>
                        金額による検索重みづけ
                        <Tooltip place="right">
                          <p>検索時の海外航空券の最低金額からの乖離を重みとして検索結果に反映します。</p>
                          <p>0〜100の数値を登録できます。それ以外の数値は無効値として扱います。</p>
                        </Tooltip>
                      </LineLabelWithCircle>
                      <WeightInput
                        type="text"
                        defaultValue={searchWeightSettings?.foreignFlightPriceWeight}
                        onChange={e =>
                          searchWeightSettings?.setforeignFlightPriceWeight(parseInt(e.target.value, 10) || 0)
                        }
                      />
                    </Line>
                    <Line>
                      <LineLabelWithCircle>
                        到着時刻による検索重みづけ
                        <Tooltip place="right">
                          <p>基準時刻からの乖離を重みとして検索結果に反映します。</p>
                          <p>0〜100の数値を登録できます。それ以外の数値は無効値として扱います。</p>
                        </Tooltip>
                      </LineLabelWithCircle>
                      <WeightInput
                        type="text"
                        defaultValue={searchWeightSettings?.foreignFlightBaseTimeWeight}
                        onChange={e =>
                          searchWeightSettings?.setForeignFlightBaseTimeWeight(parseInt(e.target.value, 10) || 0)
                        }
                      />
                    </Line>
                    <Line>
                      <LineLabelWithCircle>
                        所要時間による検索重みづけ
                        <Tooltip place="right">
                          <p>基準所要時間との乖離を重みとして検索結果に反映します。</p>
                          <p>0〜100の数値を登録できます。それ以外の数値は無効値として扱います。</p>
                        </Tooltip>
                      </LineLabelWithCircle>
                      <WeightInput
                        type="text"
                        defaultValue={searchWeightSettings?.foreignFlightSpentTimeWeight}
                        onChange={e =>
                          searchWeightSettings?.setForeignFlightSpentTimeWeight(parseInt(e.target.value, 10) || 0)
                        }
                      />
                    </Line>
                    <Line>
                      <LineLabelWithCircle>
                        キャリアによる検索重みづけ
                        <Tooltip place="right">
                          <p>検索優先キャリアに登録された重みを検索結果に反映します。</p>
                          <p>0〜100の数値を登録できます。それ以外の数値は無効値として扱います。</p>
                        </Tooltip>
                      </LineLabelWithCircle>
                      <WeightInput
                        type="text"
                        defaultValue={searchWeightSettings?.foreignFlightCarrierWeight}
                        onChange={e =>
                          searchWeightSettings?.setforeignFlightCarrierWeight(parseInt(e.target.value, 10) || 0)
                        }
                      />
                    </Line>
                  </SubSection>
                </Section>
                <Section>
                  <SectionName>検索除外条件</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      海外航空券
                    </SubSectionTitle>
                    <ExclusionAirlinesArea
                      internationalAirlines={internationalAirlines}
                      exclusionAirlines={exclusionAirlines}
                      onChange={this.handleExclusionAirlinesSettings}
                    />
                  </SubSection>
                </Section>
                <Section>
                  <SectionName>会計・勘定科目・部門</SectionName>
                  <AccountTypeMappingSection
                    mappings={accountTypeMappings}
                    accountTypes={expensesAccountTypes}
                    departments={departments}
                  />
                </Section>
                <Section>
                  <SectionName>セキュリティ</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      ログインセッション
                    </SubSectionTitle>
                    <Line>
                      <LineLabelWithCircle>
                        保持期間(分)
                        <Tooltip place="right">
                          <p>
                            設定した時間以上ユーザからサイトにアクセスがなかった場合、ログアウトします。値を設定しない場合、ログイン状態が1週間持続します。
                          </p>
                        </Tooltip>
                      </LineLabelWithCircle>
                      <LineBody>
                        <input
                          type="number"
                          placeholder="例) 60"
                          value={setting.timeoutMinutes || ''}
                          onChange={e => {
                            const val = parseInt(e.target.value, 10);
                            setting.timeoutMinutes = Number.isNaN(val) ? undefined : val;
                          }}
                        />
                      </LineBody>
                    </Line>
                  </SubSection>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      パスワード
                    </SubSectionTitle>
                    <PasswordPolicySettingArea
                      passwordPolicy={this.state.passwordPolicy}
                      onChange={passwordPolicy => this.setState({ passwordPolicy })}
                    />
                  </SubSection>
                </Section>
                <Section>
                  <SectionName>外部連携</SectionName>
                  <SubSection>
                    <SubSectionTitle>
                      <SubSectionTitleCircle />
                      シングルサインオン
                    </SubSectionTitle>
                    <Line>
                      <LineLabelNoPadding>有効化</LineLabelNoPadding>
                      <LineBody>
                        <SsoEnable organization={organization} />
                      </LineBody>
                    </Line>
                    {organization.ssoEnabled && (
                      <>
                        <Line>
                          <LineLabel>メタデータURL</LineLabel>
                          <LineBody>
                            <UrlInput
                              type="text"
                              value={organization.ssoEntityId || ''}
                              onChange={e => {
                                organization.ssoEntityId = e.target.value;
                              }}
                            />
                          </LineBody>
                        </Line>
                        <Line>
                          <LineLabel>ログインURL</LineLabel>
                          <LineBody>
                            <UrlInput
                              type="text"
                              value={organization.ssoUrl || ''}
                              onChange={e => {
                                organization.ssoUrl = e.target.value;
                              }}
                            />
                          </LineBody>
                        </Line>
                        <Line>
                          <LineLabel>ログアウトURL</LineLabel>
                          <LineBody>
                            <UrlInput
                              type="text"
                              value={organization.ssoLogoutUrl || ''}
                              onChange={e => {
                                organization.ssoLogoutUrl = e.target.value;
                              }}
                            />
                          </LineBody>
                        </Line>
                        <Line>
                          <LineLabelNoPadding>証明書ファイル</LineLabelNoPadding>
                          <LineBody>
                            {organization.ssoIdpCertSet ? (
                              <SsoIdpCertStatus>登録済み</SsoIdpCertStatus>
                            ) : (
                              <SsoIdpCertStatus>未登録</SsoIdpCertStatus>
                            )}
                            <FileInput type="file" onChange={e => this.handleSsoIdpCertFileChange(e)} />
                          </LineBody>
                        </Line>
                        <Line>
                          <LineLabelNoPadding>設定情報</LineLabelNoPadding>
                          <LineBody>
                            <div>
                              <p>IDプロバイダ側に以下の情報を設定してください。</p>
                              <SsoInfo>
                                <Flex>
                                  <SsoInfoLabel>イシュア</SsoInfoLabel>
                                  <div>{ssoIssuer}</div>
                                </Flex>
                                <Flex>
                                  <SsoInfoLabel>ログインレシーバ</SsoInfoLabel>
                                  <div>{ssoServiceUrl}</div>
                                </Flex>
                                <Flex>
                                  <SsoInfoLabel>ログアウトURL</SsoInfoLabel>
                                  <div>{ssoLogoutServiceUrl}</div>
                                </Flex>
                              </SsoInfo>
                            </div>
                          </LineBody>
                        </Line>
                      </>
                    )}
                  </SubSection>
                  {serviceId === 1 && (
                    <SubSection>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        freee
                      </SubSectionTitle>
                      {this.state.params.freee_auth_error && (
                        <Line>
                          <LineLabelError>{this.state.params.freee_auth_error}</LineLabelError>
                          <LineLabelErrorDescription>
                            {this.state.params.freee_auth_error_description}
                          </LineLabelErrorDescription>
                        </Line>
                      )}
                      <Line>
                        <LineLabel>アカウント連携</LineLabel>
                        <LineBody>
                          {freeeAuthUri ? (
                            <AButton href={freeeAuthUri} target="_blank" rel="noopener noreferrer">
                              freeeと接続する
                            </AButton>
                          ) : (
                            <Flex>
                              連携中
                              <RightButton
                                onClick={() => this.disconnectFreee()}
                                disabled={
                                  this.state.organization?.freeeWorkflow &&
                                  this.state.nonApproverDepartments.length > 0
                                }
                              >
                                連携解除
                              </RightButton>
                              {this.state.organization?.freeeWorkflow &&
                                this.state.nonApproverDepartments.length > 0 && (
                                  <WarningIcon color="primary" onClick={() => this.handleShowModal()} />
                                )}
                            </Flex>
                          )}
                        </LineBody>
                      </Line>
                      {!freeeAuthUri && (
                        <>
                          <Line>
                            <LineLabel>事業所</LineLabel>
                            <LineSelectWrapper>
                              <Select
                                value={organization.selectedFreeeCompanyId || ''}
                                onChange={e => this.handleChangeFreeeCompany(e.target.value)}
                              >
                                <option value="">選択してください</option>
                                {organization.freeeCompanies &&
                                  organization.freeeCompanies.map(c => (
                                    <option value={c.id} key={c.id}>
                                      {c.displayName}
                                    </option>
                                  ))}
                              </Select>
                            </LineSelectWrapper>
                          </Line>
                          <Line>
                            <LineLabel>取引先</LineLabel>
                            <FlexDirection>
                              <LineBody>
                                <FlexLabel>
                                  <input
                                    type="radio"
                                    checked={organization.freeePartnerSetting === 'partner'}
                                    onChange={() => organization.setFreeePartnerSetting('partner')}
                                  />
                                  <div>取引先マスタから選択</div>
                                </FlexLabel>
                                <FlexLabel>
                                  <input
                                    type="radio"
                                    checked={organization.freeePartnerSetting === 'user_name'}
                                    onChange={() => organization.setFreeePartnerSetting('user_name')}
                                  />
                                  <div>出張者名を自動登録</div>
                                </FlexLabel>
                              </LineBody>
                              {organization.freeePartnerSetting === 'partner' && (
                                <LineSelectWrapper>
                                  <AutocompleteSelect
                                    async="FILTERING"
                                    placeholder="取引先を検索"
                                    getOptions={(keyword: string | undefined) => this.fetchFreeePartners(keyword)}
                                    getOptionLabel={p => p.name}
                                    value={selectedFreeePartner}
                                    onChange={(__, selected) => {
                                      organization.freeePartnerId = selected ? selected.id : undefined;
                                      this.setState({ selectedFreeePartner: selected });
                                    }}
                                    loading={freeeLoading}
                                  />
                                </LineSelectWrapper>
                              )}
                            </FlexDirection>
                          </Line>
                          <Line>
                            <LineLabel>月額費用負担部門</LineLabel>
                            <LineSelectWrapper>
                              <AutocompleteSelect
                                async="FILTERING"
                                placeholder="部門を検索"
                                getOptions={(keyword: string | undefined) => this.fetchFreeeSections(keyword)}
                                getOptionLabel={p => p.name}
                                value={selectedFreeeSection}
                                onChange={(__, selected) => {
                                  organization.freeeMonthlyPriceSectionId = selected ? selected.id : undefined;
                                  this.setState({ selectedFreeeSection: selected });
                                }}
                                loading={freeeLoading}
                              />
                            </LineSelectWrapper>
                          </Line>
                          <Line>
                            <LineLabel>プロジェクトの項目</LineLabel>
                            <LineSelectWrapper>
                              <Select
                                value={organization.freeeProjectDestination || ''}
                                onChange={e => {
                                  organization.freeeProjectDestination = e.target.value;
                                }}
                              >
                                <option value="">選択してください</option>
                                <option value="memotag">メモタグ</option>
                                <option value="segment1">セグメント1</option>
                                <option value="segment2">セグメント2</option>
                                <option value="segment3">セグメント3</option>
                              </Select>
                            </LineSelectWrapper>
                          </Line>
                          <Line>
                            <LineLabelNoPadding>タグ</LineLabelNoPadding>
                            <LineBody>
                              <FlexLabel>
                                <input
                                  type="checkbox"
                                  checked={organization.isCreateFreeeTag}
                                  onChange={() => organization.toggleIsCreateFreeeTag()}
                                />
                                <div>マッチするタグがなければ自動で作成</div>
                              </FlexLabel>
                            </LineBody>
                          </Line>
                          <Line>
                            <LineLabel>部門の項目</LineLabel>
                            <LineSelectWrapper>
                              <Select
                                value={organization.freeeDepartment || ''}
                                onChange={e => {
                                  organization.freeeDepartment = e.target.value;
                                }}
                              >
                                <option value="department">部署</option>
                                <option value="charging_department">費用負担部署</option>
                              </Select>
                            </LineSelectWrapper>
                          </Line>
                          <Line>
                            <LineLabel>ワークフロー連携</LineLabel>
                            <LineBody>
                              <input
                                type="checkbox"
                                checked={organization.freeeWorkflow}
                                onChange={() => this.handleFreeeWorkflowToggle()}
                                disabled={
                                  this.state.organization?.freeeWorkflow &&
                                  this.state.nonApproverDepartments.length > 0
                                }
                              />
                              <div>連携する</div>
                              {this.state.organization?.freeeWorkflow &&
                                this.state.nonApproverDepartments.length > 0 && (
                                  <WarningIcon color="primary" onClick={() => this.handleShowModal()} />
                                )}
                            </LineBody>
                          </Line>
                          {organization.freeeWorkflow && (
                            <>
                              <Line>
                                <LineLabel>ワークフロー用申請経路（国内）</LineLabel>
                                <LineSelectWrapper>
                                  <FilterableSelector
                                    placeholder="申請経路を検索"
                                    options={freeeApprovalFlowRoutes.map(s => {
                                      return { label: s.name, value: s.id.toString(), matcher: s.name };
                                    })}
                                    selected={this.selectedFreeeApprovalFlowRouteDomesticOption()}
                                    onSelect={selected => {
                                      organization.freeeWorkflowApprovalFlowRouteIdDomestic = selected
                                        ? parseInt(selected.value, 10)
                                        : null;
                                    }}
                                  />
                                </LineSelectWrapper>
                              </Line>
                              <Line>
                                <LineLabel>ワークフロー用フォーム（国内）</LineLabel>
                                <LineSelectWrapper>
                                  <FilterableSelector
                                    placeholder="フォームを検索"
                                    options={freeeForms.map(s => {
                                      return { label: s.name, value: s.id.toString(), matcher: s.name };
                                    })}
                                    selected={this.selectedFreeeFormDomesticOption()}
                                    onSelect={selected => {
                                      organization.freeeWorkflowFormIdDomestic = selected
                                        ? parseInt(selected.value, 10)
                                        : null;
                                    }}
                                  />
                                </LineSelectWrapper>
                              </Line>
                              <Line>
                                <LineLabel>ワークフロー用申請経路（海外）</LineLabel>
                                <LineSelectWrapper>
                                  <FilterableSelector
                                    placeholder="申請経路を検索"
                                    options={freeeApprovalFlowRoutes.map(s => {
                                      return { label: s.name, value: s.id.toString(), matcher: s.name };
                                    })}
                                    selected={this.selectedFreeeApprovalFlowRouteForeignOption()}
                                    onSelect={selected => {
                                      organization.freeeWorkflowApprovalFlowRouteIdForeign = selected
                                        ? parseInt(selected.value, 10)
                                        : null;
                                    }}
                                  />
                                </LineSelectWrapper>
                              </Line>
                              <Line>
                                <LineLabel>ワークフロー用フォーム（海外）</LineLabel>
                                <LineSelectWrapper>
                                  <FilterableSelector
                                    placeholder="フォームを検索"
                                    options={freeeForms.map(s => {
                                      return { label: s.name, value: s.id.toString(), matcher: s.name };
                                    })}
                                    selected={this.selectedFreeeFormForeignOption()}
                                    onSelect={selected => {
                                      organization.freeeWorkflowFormIdForeign = selected
                                        ? parseInt(selected.value, 10)
                                        : null;
                                    }}
                                  />
                                </LineSelectWrapper>
                              </Line>
                            </>
                          )}
                        </>
                      )}
                    </SubSection>
                  )}
                  {serviceId === 1 && (
                    <SubSection>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        SmartHR
                      </SubSectionTitle>
                      {this.state.params.smart_hr_auth_error && (
                        <Line>
                          <LineLabelError>
                            {smartHrErrorMessages[this.state.params.smart_hr_auth_error] ||
                              this.state.params.smart_hr_auth_error}
                          </LineLabelError>
                          <LineLabelErrorDescription>
                            {this.state.params.smart_hr_auth_error_description}
                          </LineLabelErrorDescription>
                        </Line>
                      )}
                      <Line>
                        <LineLabel>アカウント連携</LineLabel>
                        <LineBody>
                          {smartHrAuthUri ? (
                            <AButton href={smartHrAuthUri} target="_blank" rel="noopener noreferrer">
                              SmartHRと接続する
                            </AButton>
                          ) : (
                            <Flex>
                              連携中
                              <RightAButton onClick={() => this.disconnectSmartHr()}>連携解除</RightAButton>
                            </Flex>
                          )}
                        </LineBody>
                      </Line>
                      {!smartHrAuthUri &&
                        smartHrAssociateColumns.map((s, i) => (
                          <Line key={i}>
                            <LineLabel>{s.targetText}</LineLabel>
                            <>
                              <LineSelectWrapper>
                                <Select
                                  value={s.associateColumn || ''}
                                  onChange={e => {
                                    this.setState({ associateColumnEmail: e.target.value });
                                    s.setAssociateColumn(e.target.value);
                                  }}
                                >
                                  {smartHrAssociateColumnTypes
                                    .filter(t => t.target === s.target)
                                    .map(c => (
                                      <option value={c.selectValue} key={c.id}>
                                        {c.displayName}
                                      </option>
                                    ))}
                                </Select>
                              </LineSelectWrapper>
                              {s.associateColumn === 'crew_custom_email' && (
                                <>
                                  <SharedInput
                                    type="text"
                                    placeholder="emailのカスタム項目名"
                                    value={s.customName}
                                    style={{ marginLeft: '20px' }}
                                    error={error}
                                    onChange={e => {
                                      s.setCustomName(e.target.value);
                                      this.setState({ associateCustomName: e.target.value });
                                      this.hasErrorSmartHrCustomName(e.target.value);
                                    }}
                                  />
                                  {error && <ErrorText>{errorMessage}</ErrorText>}
                                </>
                              )}
                            </>
                          </Line>
                        ))}
                    </SubSection>
                  )}
                  {serviceId === 1 && (
                    <ExpenseSection>
                      <SubSectionTitle>
                        <SubSectionTitleCircle />
                        経費精算
                      </SubSectionTitle>
                      <Line>
                        <FlexLabel>
                          <input
                            type="radio"
                            checked={expenseTool === 'none'}
                            onChange={() => this.handleExpenseTool('none')}
                          />
                          <div>連携なし</div>
                        </FlexLabel>
                        <FlexLabel>
                          <input
                            type="radio"
                            checked={expenseTool === 'rakuraku'}
                            onChange={() => this.handleExpenseTool('rakuraku')}
                          />
                          <div>楽楽精算</div>
                        </FlexLabel>
                        <FlexLabel>
                          <input
                            type="radio"
                            checked={expenseTool === 'biztra'}
                            disabled={!contractBiztraPro}
                            onChange={() => this.handleExpenseTool('biztra')}
                          />
                          <div>ビズトラ</div>
                          {!contractBiztraPro && (
                            <Tooltip place="right">
                              <p>
                                ビズトラ連携する場合は別途お申し込み、もしくはビズトラプロプランをご登録ください。詳しくは
                                <a href="https://biztra.cloud/" target="_blank" rel="noreferrer">
                                  こちら
                                </a>
                              </p>
                            </Tooltip>
                          )}
                        </FlexLabel>
                      </Line>
                      {expenseTool === 'rakuraku' && (
                        <Line>
                          <LineLabelWithCircle>
                            連携停止項目
                            <Tooltip place="right">
                              <p>
                                楽楽経費精算とのデータ連携をお申し込みしているお客様で、EXICの手数料データの連携を停止したいユーザはEXICのチェックボックスを選択して保存してください。
                              </p>
                            </Tooltip>
                          </LineLabelWithCircle>
                          <LineBody>
                            <input
                              type="checkbox"
                              checked={setting.rakurakuSetting.exicOrderDataStopProvide}
                              onChange={() => this.handleRakurakuDataSetting()}
                            />
                            <div>EXIC</div>
                          </LineBody>
                        </Line>
                      )}
                    </ExpenseSection>
                  )}
                </Section>
              </>
            )
          )}
        </Body>
        <Modal
          hideModal={() => this.setState({ showModal: false })}
          show={this.state.showModal}
          title="承認者未設定の部署一覧"
        >
          <div>
            <p>以下の部署に承認者が設定されていません。Freee連携を解除する前に承認者を設定してください。</p>
            <DepartmentList>
              {this.state.nonApproverDepartments.map(dep => (
                <DepartmentListItem key={dep.id}>
                  ({dep.code}) {dep.name}
                </DepartmentListItem>
              ))}
            </DepartmentList>
          </div>
        </Modal>
      </>
    );
  }
}

const Body = styled.div`
  height: calc(100vh - 50px - 46px - 40px - 122px);
  overflow-y: scroll;
`;

const Section = styled.section`
  border-bottom: 1px solid #eee;
  margin-top: 16px;
  padding-bottom: 20px;
`;

const SectionName = styled.h3`
  border-left: 5px solid ${props => props.theme.linkColor};
  padding: 4px 0 4px 10px;
`;

const SubSection = styled.section`
  position: relative;
  margin-top: 16px;
`;

const ExpenseSection = styled.section`
  position: relative;
  margin-top: 16px;
  margin-bottom: 100px;
`;

const SubSectionTitle = styled.h4`
  position: relative;
  width: 194px;
  margin-left: 16px;
  margin-bottom: 0;
  height: 20px;
  padding-top: 3px;
  padding-left: 28px;
  display: flex; /* tooltip用 */

  ::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 9px;
    z-index: 1;
    width: 1px;
    height: 11px;
    border: solid 1px transparent;
    border-left-color: #fff;
  }
  ::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    z-index: 1;
    width: 6px;
    height: 4px;
    border: solid 4px transparent;
    border-left: solid 6px #fff;
  }
`;

const SubSectionTitleCircle = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 20px;
  width: 20px;
  background: ${props => props.theme.selectArrowColor};
  border-radius: 50%;
`;

const SubSectionLine = styled.div`
  display: flex;
`;

const Line = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
`;

const LineLabel = styled.div`
  padding-top: 7px;
  width: 190px;
  font-weight: bold;
  font-size: 11px;

  ::before {
    content: '・';
  }
`;

const LineSubLabel = styled.div`
  padding-top: 7px;
  margin-left: 30px;
  width: 140px;
  font-weight: bold;
  font-size: 11px;

  ::before {
    content: '・';
  }
`;

const LineLabelWithCircle = styled.div`
  padding-top: 7px;
  width: 200px;
  font-weight: bold;
  font-size: 11px;
  display: flex; /* tooltip用 */
  align-item ::before {
    content: '・';
  }
`;

const WeightInput = styled.input`
  &&& {
    width: 40px;
    margin-bottom: 0px;
  }
`;

const LineLabelNoPadding = styled(LineLabel)`
  padding-top: 0;
`;

const LineSelectWrapper = styled.div`
  width: 216px;
`;

const LineBody = styled.div`
  display: flex;
`;

const LineColumn = styled.div`
  width: 88px;
  margin-right: 20px;
`;

const LineLabelError = styled.div`
  padding-top: 7px;
  width: 150px;
  font-weight: bold;
  font-size: 11px;
  color: ${props => props.theme.redColor};
`;

const LineLabelErrorDescription = styled.div`
  padding-top: 7px;
  width: 150px;
  font-weight: bold;
  font-size: 11px;
  color: ${props => props.theme.textColor};
`;

const DistanceLineColumn = styled.div`
  width: 160px;
  display: inline-flex;
  align-items: baseline;
  gap: 6px;
`;

const GradeBoxStyle = css`
  border: 1px solid #333;
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  line-height: 1;
`;

const GradeInput = styled.input`
  &&& {
    ${GradeBoxStyle}

    &:hover {
      border-color: #ddd;
    }
  }
`;

const AddGradeButton = styled.div`
  ${GradeBoxStyle}
  border-color: ${props => props.theme.linkColor};
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const GradeHeader = styled.div`
  ${GradeBoxStyle}
  border-color: #ddd;
  background-color: #f9f7f3;
  color: #666;
  padding: 2px;
  font-size: 11px;
  font-weight: normal;
`;

const DetailButton = styled.div`
  ${ABase}
  font-size: 10px;
  cursor: pointer;
  font-weight: normal;
`;

const AreaLabel = styled.div`
  width: 150px;
  font-size: 11px;
`;

const PrefLabel = styled.div`
  width: 150px;
  padding-left: 20px;
  font-size: 11px;
`;

const FlexLabel = styled.label`
  display: flex;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const FlexDirection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RightButton = styled(Button)`
  padding: 2px 0 0 0;
  margin-left: 10px;
`;

const UrlInput = styled(Input)`
  &&& {
    width: 400px;
  }
`;

const SsoIdpCertStatus = styled.span`
  width: 80px;
`;

const FileInput = styled.input`
  &&& {
    padding-bottom: 0;
  }
`;

const SsoInfo = styled.div`
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #eee;
  font-size: 11px;
  color: #666;
`;

const SsoInfoLabel = styled.div`
  width: 100px;
`;

const ErrorText = styled.span`
  margin-left: 10px;
  margin-top: 5px;
  color: ${props => props.theme.redColor};
`;

const AButton = styled.a`
  ${ButtonBase02}
  padding: 5px 10px;
`;

const RightAButton = styled(AButton)`
  margin-left: 10px;
`;

const CommentAggregateBase = styled.p`
  margin-left: 148px;
`;

const CommentAggregateBase2 = styled.p`
  margin-left: 172px;
`;

const CommentAggregateUnit = styled.p`
  margin-left: 94px;
`;

const DepartmentList = styled.ul`
  margin: 10px;
  padding: 10px;
`;

const DepartmentListItem = styled.li`
  list-style: circle;
`;

const WarningIcon = styled(ErrorOutlineIcon)`
  cursor: pointer;
`;

export default SettingEdit;
