import React, { useState } from 'react';
import { observer } from 'mobx-react';
import A from '@this/components/shared/atoms/a';
import {
  MappingFormWrapper,
  MappingFormLabel,
  MappingFormDetailArea,
  MappingFormDetailIndentedArea,
  MappingFormDetailItemArea,
  MappingFormDetailSelectArea,
  MappingFormDetailLinkArea
} from '@this/components/trips_management/trips/request_approval_modal/mapping_form.style';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import type Project from '@this/domain/project/project';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import type ArrangementRequestWfInfo from '@this/src/domain/arrangement_request_info/arrangement_request_wf_info';

interface Props {
  wf: ArrangementRequestWfInfo;
}

const ProjectMappingForm: React.FC<Props> = ({ wf }) => {
  const [showDetail, setShowDetail] = useState(wf.isForProjectDetail());

  const handleSelectProjectDetail = (
    changingMapping: OrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    const values = option?.value.split('-'); // e.g. project-1
    if (values && values?.length === 2) {
      if (values[0] === 'project') {
        changingMapping.setProjectId(Number(values[1]));
      } else if (values[0] === 'share') {
        changingMapping.setProjectShareId(Number(values[1]));
      }
    }
  };

  const handleSelect = (selected: FilterableSelectorOption | null): void => {
    const values = selected?.value.split('-'); // e.g. project-1
    if (values && values?.length === 2 && values[0] === 'project') {
      wf.projectId = values[1];
    } else {
      wf.projectId = '';
    }
    wf.orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => handleSelectProjectDetail(mapping, selected));
    });
  };

  const createOption = (project: Project) => ({
    label: project.codeAndName(),
    value: `project-${project.id.toString()}`,
    matcher: project.codeAndName()
  });

  const handleShowProjectDetailClick = (value: boolean) => {
    if (!value) wf.resetMappingsProjectId();
    setShowDetail(value);
    wf.showProjectDetail = value;
  };

  const projectOption = () => {
    return wf.projects.list.map(p => ({
      label: p.codeAndName(),
      value: `project-${p.id.toString()}`,
      matcher: p.codeAndName()
    }));
  };

  const projectDetailOption = (): any[] => {
    const projectsOption: any[] = wf.projects.list.map(p => {
      return {
        label: p.codeAndName(),
        value: `project-${p.id.toString()}`,
        matcher: p.codeAndName()
      };
    });
    const sharesOption: any[] = wf.projectShares.list.map(s => {
      return {
        labelLines: s.codeAndNameLines(),
        value: `share-${s.id.toString()}`,
        matcher: s.codeAndNameOneLine()
      };
    });
    return projectsOption.concat(sharesOption);
  };

  return (
    <MappingFormWrapper>
      {showDetail ? (
        <MappingFormDetailArea>
          {wf.orderItems.map((item, item_index) => (
            <div key={`item${item.id}`}>
              <MappingFormLabel>
                {item_index + 1}. {item?.categoryStr()}
              </MappingFormLabel>
              <MappingFormDetailIndentedArea>
                {item.travelerInformations.map((traveler: any) => (
                  <MappingFormDetailItemArea key={traveler.id}>
                    <MappingFormLabel>{traveler.name}</MappingFormLabel>
                    <MappingFormDetailSelectArea>
                      <FilterableSelector
                        placeholder="プロジェクトを検索"
                        options={projectDetailOption()}
                        selected={
                          item.orderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id)
                            ? {
                                label:
                                  item.orderItemMappings
                                    .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                    ?.projectDescription(wf.projects, wf.projectShares) || '',
                                value: '',
                                matcher: ''
                              }
                            : null
                        }
                        onSelect={option => {
                          const mapping = item.orderItemMappings.findByTravelerInformationAndOrderItem(
                            traveler.id,
                            item.id
                          );
                          if (mapping) {
                            handleSelectProjectDetail(mapping, option);
                          }
                        }}
                      />
                    </MappingFormDetailSelectArea>
                  </MappingFormDetailItemArea>
                ))}
              </MappingFormDetailIndentedArea>
            </div>
          ))}
        </MappingFormDetailArea>
      ) : (
        <div>
          <FilterableSelector
            placeholder="プロジェクトを検索"
            options={projectOption()}
            selected={wf.project ? createOption(wf.project) : null}
            onSelect={handleSelect}
          />
        </div>
      )}
      {wf.projectShareAvailability &&
        (showDetail ? (
          <MappingFormDetailLinkArea>
            <A onClick={() => handleShowProjectDetailClick(false)}>通常設定へ</A>
          </MappingFormDetailLinkArea>
        ) : (
          <MappingFormDetailLinkArea>
            <A onClick={() => handleShowProjectDetailClick(true)}>詳細設定へ</A>
          </MappingFormDetailLinkArea>
        ))}
    </MappingFormWrapper>
  );
};

export default observer(ProjectMappingForm);
