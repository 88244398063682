import { observable, action } from 'mobx';

export interface ArrangementRequestSettingJson {
  id: number;
  organization_id: number;
  enable: boolean;
  fee: number | null;
}

export default class ArrangementRequestSetting {
  @observable
  id: number;

  @observable
  organizationId: number;

  @observable
  enable: boolean;

  @observable
  fee: number;

  constructor(arg: ArrangementRequestSettingJson) {
    this.id = arg.id;
    this.organizationId = arg.organization_id;
    this.enable = !!arg.enable;
    this.fee = arg.fee || 0;
  }

  @action
  toggleEnable() {
    this.enable = !this.enable;
  }

  submitParams() {
    return {
      id: this.id,
      enable: this.enable,
      fee: this.fee || 0
    };
  }
}
