import React from 'react';
import _ from 'lodash';
import { Block } from './search_block/search_block.template';

import SearchBlock from './search_block/search_block';
import { AddButton } from './search_multiple_locations.template';
import SearchTravelersBlock from './search_travelers_block/search_travelers_block';
import SearchPeoplenumBlock from './search_peoplenum_block/search_peoplenum_block.template';

import type { RentalCarTripTemplateProps } from './types';

const SearchRentalCarTripTemplate: React.SFC<RentalCarTripTemplateProps> = ({
  query,
  user,
  handleChange,
  handleRentalCarInfoAdd,
  handleRemoveItem
}) => (
  <div>
    {query.items.map((item, i) => (
      <SearchBlock key={i} index={i} item={item} icon={i + 1} onRemoveItem={handleRemoveItem} />
    ))}

    <Block className="search__block">
      <div className="search__block__body">
        <AddButton onClick={e => handleRentalCarInfoAdd(e)}>+ 依頼フォームを追加する</AddButton>
      </div>
    </Block>
    {user ? (
      <SearchTravelersBlock query={query} user={user} handleChange={handleChange} />
    ) : (
      <SearchPeoplenumBlock query={query} handleChange={handleChange} />
    )}
  </div>
);

export default SearchRentalCarTripTemplate;
