import { styled } from '@this/constants/themes';
import React from 'react';
import { css } from 'styled-components';
import { headerHeight } from '@this/components/expenses/header/header';

interface Props {
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const HamburgerButton: React.FC<Props> = ({ active, onClick }) => {
  return (
    <ButtonContainer active={active} onClick={onClick} style={{ height: `${headerHeight}` }}>
      <span />
      <span />
      <span />
    </ButtonContainer>
  );
};

const btnColor = '#EFEDEA';

const ButtonContainer = styled.button<{ active: boolean }>`
  position: relative;
  width: 61px;
  background-color: transparent;
  outline: none;

  &:hover,
  &:focus {
    background-color: transparent !important;
  }

  span {
    position: absolute;
    width: 20%;
    height: 1px;
    left: 24px;
    background-color: ${btnColor};
    border-radius: 4px;
    transition: opacity 0.3s, transform 0.3s;

    &:nth-of-type(1) {
      top: 24px;

      ${({ active }) =>
        active &&
        css`
          transform: translateY(4px) rotate(-45deg);
        `}
    }
    &:nth-of-type(2) {
      top: 28px;

      ${({ active }) =>
        active &&
        css`
          opacity: 0;
        `}
    }
    &:nth-of-type(3) {
      bottom: 23px;

      ${({ active }) =>
        active &&
        css`
          transform: translateY(-4px) rotate(45deg);
        `}
    }
  }
`;

export default HamburgerButton;
