export class Approver {
  id: number;

  approverId: number;

  name: string;

  email: string;

  constructor(args: ApproverCandidateArgs) {
    this.id = args.id;
    this.approverId = args.approver_candidate_id;
    this.name = args.approver.name;
    this.email = args.approver.email;
  }
}

export interface ApproverCandidateArgs {
  id: number;

  approver_candidate_id: number;

  approver: {
    name: string;

    email: string;
  };
}
