import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { styled } from '@this/constants/themes';
import { Fetcher, HTTPError } from '@this/src/util';

interface Props {
  onClose: () => void;
}

interface Response {
  service_supplier_setting: { rakuten_travel_login_id: string };
}

const ServiceSupplierSettingModal = observer(({ onClose }: Props) => {
  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const fetchServiceSupplierSetting = () => {
    Fetcher.get<Response>(`/arrangement/service_supplier_settings`).then(res => {
      setLoginId(res.service_supplier_setting.rakuten_travel_login_id);
    });
  };

  useEffect(() => {
    fetchServiceSupplierSetting();
  }, []);

  const saveSetting = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess(null);
    setSubmitError(null);
    const params = {
      rakuten_travel_login_id: loginId,
      rakuten_travel_password_digest: password,
      rakuten_travel_password_digest_confirmation: passwordConfirmation
    };
    Fetcher.post<{
      rakuten_travel_login_id: string;
      rakuten_travel_password_digest: string;
      rakuten_travel_password_digest_confirmation: string;
    }>(`/arrangement/service_supplier_settings`, params)
      .then(() => {
        setSuccess('ID、パスワードを設定しました');
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
          setSubmitError(e.response.data.errors);
        } else {
          setSubmitError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        }
      });
  };

  return (
    <Modal open onClose={onClose} size="large">
      <ModalHeader>楽天API管理画面ログイン情報設定</ModalHeader>
      <ModalBody>
        <form id="saveSettingForm" onSubmit={saveSetting} autoComplete="on" noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="password">ログインID</InputLabel>
            <Input
              id="rakuten_travel_login_id"
              autoComplete="on"
              type="text"
              name="rakuten_travel_login_id"
              value={loginId}
              onChange={e => setLoginId(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="password">パスワード</InputLabel>
            <Input
              id="rakuten_travel_password_digest"
              autoComplete="on"
              type="password"
              name="rakuten_travel_password_digest"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="passwordConfirmation">パスワード （確認）</InputLabel>
            <Input
              id="rakuten_travel_password_digest_confirmation"
              autoComplete="on"
              type="password"
              name="rakuten_travel_password_digest_confirmation"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
          </FormControl>
          {submitError && <Error>{submitError}</Error>}
          {success && <Success>{success}</Success>}
          <SubmitButton type="submit">保存</SubmitButton>
        </form>
      </ModalBody>
    </Modal>
  );
});

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Success = styled.div`
  color: ${props => props.theme.successColor};
`;

export default ServiceSupplierSettingModal;
