import _ from 'lodash';
import type MarginType from '@this/domain/organization/margin_type2';
import localStorage from '@this/lib/local_storage';
import type ListWrapperInterface from '../list_wrapper_interface';
import Hotel from './hotel';
import type { HotelJson } from '../select_repository';

interface Args {
  showFee?: boolean;
  includeTax?: boolean;
  package?: boolean;
  current?: string;
  marginType?: MarginType;
  isShowMap?: boolean;
}

interface LatLng {
  lat: number;
  lng: number;
}

interface HotelFilter {
  isShowFilter: boolean;
  maxPrice: number;
  minPrice: number;
  maxDistance: number;
  minDistance: number;
  initialFilter: Omit<HotelFilter, 'isShowFilter' | 'initialFilter'>;
}

class HotelList implements ListWrapperInterface {
  includeTax: boolean;

  package: boolean;

  list: Hotel[];

  filteredList: Hotel[];

  sortKey: 'orderedIndex' | 'average_price_with_tax' | 'distance' | 'station_distance' | 'map';

  beforeSortKey: 'orderedIndex' | 'average_price_with_tax' | 'distance' | 'station_distance' | 'map';

  currentId: string | null | undefined;

  showMap: boolean;

  showBox: boolean;

  showFee: boolean;

  private marginType: MarginType | undefined;

  newId: string | null;

  segment: undefined; // only for typing

  filter: HotelFilter;

  constructor(rawHotels?: HotelJson[], args: Args = {}) {
    this.includeTax = !!args.includeTax;
    this.package = !!args.package;
    this.showFee = args.showFee || false;
    this.marginType = args.marginType;
    this.list =
      _.map(
        rawHotels,
        h =>
          new Hotel(
            _.merge(h, {
              includeTax: this.includeTax,
              package: this.package,
              showFee: this.showFee,
              marginType: this.marginType
            }),
            h.score_index
          )
      ) || [];
    this.filteredList = this.list;
    this.sortKey = 'orderedIndex';
    this.beforeSortKey = 'orderedIndex';
    this.filter = this.setFilter();

    const c: Hotel | null | undefined = this.currentHotelConstructor(args);
    if (c) {
      this.currentId = c.id;
    } else {
      const h = this.package ? _.minBy(this.list, hotel => hotel.price) : _.first(this.list);
      this.currentId = h && h.id;
    }

    if (args.isShowMap === true) {
      this.showMap = true;
    } else {
      this.showMap = false;
    }

    // for CSSTransition
    this.showBox = true;
    this.newId = null;

    this.select(localStorage.getItem('hotelId') ?? '');
  }

  // 選択されているホテルを構築する。
  currentHotelConstructor(args: Args = {}): Hotel | null | undefined {
    let current;
    if (args.current) {
      current = this.list.find(h => !!h.name && h.name.replace(/\n/g, '') + h.price === (args && args.current));
    }
    if (!current) {
      current = this.list.find(current => !!current.optimal);
    }
    return current;
  }

  setIncludeTax(value: boolean) {
    this.includeTax = value;
    this.list.forEach(h => {
      h.setIncludeTax(value);
    });
    app.render();
  }

  find(id: string | null | undefined): Hotel | undefined {
    if (id) return _.find(this.list, h => h.id === id);
    return undefined;
  }

  findByIndex(index: number) {
    return _.find(this.list, h => h.orderedIndex === index);
  }

  current(): Hotel {
    return this.find(this.currentId) || new Hotel();
  }

  first(): Hotel {
    return _.first(this.list) || new Hotel();
  }

  currentIndex() {
    const orderedIndex = this.current().orderedIndex;
    return orderedIndex !== undefined ? orderedIndex + 1 : undefined;
  }

  isFirst(): boolean {
    return this.current().orderedIndex === 0;
  }

  select(id: string) {
    this.list.forEach(h => {
      h.hovered = false;
    });

    if (this.find(id)) {
      this.currentId = id;

      app.render();
    }
  }

  selectWithAnimation(id: string) {
    this.newId = id;
    app.render(); // スマホのために一度renderしてoutlineを表示しておく

    setTimeout(() => {
      this.showBox = false;
      app.render();
    }, 100); // スマホでCSSTransitionのonExitを発火させるため
  }

  handleTransitionExited() {
    this.showBox = true;
    if (this.newId) this.select(this.newId);
    this.newId = null;
  }

  selectByIndex(index: number) {
    const h = this.findByIndex(index);
    if (h) this.currentId = h.id;
    app.render();
  }

  fetchDistances(location: LatLng) {
    this.list.forEach(h => {
      if (h.latitude && h.longitude) {
        h.walkminute = utils.walkMinutesText(
          utils.calcDistance(location.lat, location.lng, h.latitude, h.longitude)
        );
      }
    });
  }

  sortBy(key: string) {
    const availableHotels = _.filter(this.list, h => !(h.sold_out && h.too_late));
    const unavailableHotels = _.filter(this.list, h => !!(h.sold_out && h.too_late));
    this.list = _.concat(_.sortBy(availableHotels, [key, 'orderedIndex']), unavailableHotels);
    this.search();
  }

  setSortBy(key: 'orderedIndex' | 'average_price_with_tax' | 'distance' | 'station_distance') {
    this.sortKey = key;
    const availableHotels = _.filter(this.list, h => !(h.sold_out && h.too_late));
    const unavailableHotels = _.filter(this.list, h => !!(h.sold_out && h.too_late));
    this.list = _.concat(_.sortBy(availableHotels, [key, 'orderedIndex']), unavailableHotels);
  }

  handleSortKeyChange =
    (key: 'orderedIndex' | 'average_price_with_tax' | 'distance' | 'station_distance') => () => {
      if (key === 'average_price_with_tax' && !this.includeTax) {
        this.sortBy('average_price');
      } else {
        this.sortBy(key);
      }
      this.sortKey = key;
      localStorage.setItem('hotelSortKey', key);
      this.showMap = false;
      app.render();
    };

  handleToggleMap = () => {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.beforeSortKey = this.sortKey;
      this.sortKey = 'map';
    } else {
      this.sortKey = this.beforeSortKey;
    }
    app.render();
  };

  length() {
    return this.list.length;
  }

  get filterLength() {
    return this.filteredList.length;
  }

  get allLength() {
    return this.list.length;
  }

  get hasNoDirectAir() {
    return false;
  }

  isCurrentHotelTooLate(): boolean {
    return !!this.current().too_late;
  }

  canReserveJustBefore(): boolean {
    return this.current().type === 'eps_rapid' || this.current().type === 'e';
  }

  updateHotelList(hotel: Hotel) {
    const targetHotel = this.find(hotel.id);
    if (targetHotel) {
      this.list = _.map(this.list, h => {
        if (h.id !== hotel.id) return h;

        if (!hotel.orderedIndex) hotel.orderedIndex = h.orderedIndex;
        return hotel;
      });
    }
    app.render();
  }

  search() {
    this.filteredList = _.filter(this.list, h => {
      if (!h.price || !h.distance) return false;
      if (this.filter.minPrice > h.price || this.filter.maxPrice < h.price) return false;
      if (this.filter.minDistance > h.distance || this.filter.maxDistance < h.distance) return false;
      return true;
    });
    app.render();
  }

  setFilter(): HotelFilter {
    const initialFilter = {
      maxPrice: _.maxBy(this.list, h => h.price)?.price || 0,
      minPrice: _.minBy(this.list, h => h.price)?.price || 0,
      maxDistance: _.maxBy(this.list, h => h.distance)?.distance || 0,
      minDistance: _.minBy(this.list, h => h.distance)?.distance || 0
    };

    return {
      isShowFilter: false,
      ...initialFilter,
      initialFilter
    };
  }

  toggleShowFilter() {
    this.filter = { ...this.filter, isShowFilter: !this.filter.isShowFilter };
    app.render();
  }

  clearFilter() {
    this.filter = { ...this.filter, ...this.filter.initialFilter };
    this.search();
  }

  // Sliderの初期値とラベルを設定
  // 両端を切り上げ切り捨てして、初期値が両端の場合は初期値を表示する（各ステップの値がpriceStepの倍数になるように設定）
  priceSliderMarks(priceStep: number) {
    const minPrice = Math.floor(this.filter.initialFilter.minPrice / priceStep) * priceStep;
    const maxPrice = Math.ceil(this.filter.initialFilter.maxPrice / priceStep) * priceStep;
    return [
      { value: minPrice, label: utils.formatPrice(this.filter.initialFilter.minPrice) },
      { value: maxPrice, label: utils.formatPrice(this.filter.initialFilter.maxPrice) }
    ];
  }

  minPriceValue(currentMinPrice: number, marks: { value: number; label: string }[]) {
    return currentMinPrice === this.filter.initialFilter.minPrice ? marks[0].value : currentMinPrice;
  }

  maxPriceValue(currentMaxPrice: number, marks: { value: number; label: string }[]) {
    return currentMaxPrice === this.filter.initialFilter.maxPrice ? marks[1].value : currentMaxPrice;
  }

  // Sliderの値が変更されたときの処理
  // 両端の丸めた値の場合は元の最大値・最小値に戻す
  handlePriceChange = (newValue: number | number[], marks: { value: number; label: string }[]) => {
    const values = newValue as number[];
    const min = values[0] === marks[0].value ? this.filter.initialFilter.minPrice : values[0];
    const max = values[1] === marks[1].value ? this.filter.initialFilter.maxPrice : values[1];
    this.filter = { ...this.filter, minPrice: min, maxPrice: max };
    this.search();
  };

  // Sliderのラベルをフォーマット
  // 両端の丸めた値の場合は元の最大値・最小値を表示する
  priceValueLabelFormat = (value: number, marks: { value: number; label: string }[]) => {
    const price =
      value === marks[1].value
        ? this.filter.initialFilter.maxPrice
        : value === marks[0].value
        ? this.filter.initialFilter.minPrice
        : value;
    return utils.formatPrice(price);
  };

  // Sliderの初期値を設定
  // 両端を切り上げ切り捨てして、初期値が両端の場合は初期値を表示する（各ステップの値がdistanceStepの倍数になるように設定）
  distanceSliderMarks(distanceStep: number) {
    const minDistance = Math.floor(this.filter.initialFilter.minDistance / distanceStep) * distanceStep;
    const maxDistance = Math.ceil(this.filter.initialFilter.maxDistance / distanceStep) * distanceStep;
    return [
      { value: minDistance, label: `${(minDistance / 1000).toFixed(1)}km` },
      { value: maxDistance, label: `${(maxDistance / 1000).toFixed(1)}km` }
    ];
  }

  minDistanceValue(currentMinDistance: number, marks: { value: number; label: string }[]) {
    return currentMinDistance === this.filter.initialFilter.minDistance ? marks[0].value : currentMinDistance;
  }

  maxDistanceValue(currentMaxDistance: number, marks: { value: number; label: string }[]) {
    return currentMaxDistance === this.filter.initialFilter.maxDistance ? marks[1].value : currentMaxDistance;
  }

  // Sliderの値が変更されたときの処理
  // 両端の丸めた値の場合は元の最大値・最小値に戻す
  handleDistanceChange = (newValue: number | number[], marks: { value: number; label: string }[]) => {
    const values = newValue as number[];
    const min = values[0] === marks[0].value ? this.filter.initialFilter.minDistance : values[0];
    const max = values[1] === marks[1].value ? this.filter.initialFilter.maxDistance : values[1];
    this.filter = { ...this.filter, minDistance: min, maxDistance: max };
    this.search();
  };

  // Sliderのラベルをフォーマット
  // 両端の丸めた値の場合は元の最大値・最小値を表示する
  distanceValueLabelFormat = (value: number, marks: { value: number; label: string }[]) => {
    const distance =
      value === marks[1].value
        ? this.filter.initialFilter.maxDistance
        : value === marks[0].value
        ? this.filter.initialFilter.minDistance
        : value;
    return `${(distance / 1000).toFixed(1)}km`;
  };

  get priceRange(): string {
    return `${utils.formatPrice(this.filter.minPrice)} ~ ${utils.formatPrice(this.filter.maxPrice)}`;
  }

  get distanceRange(): string | null {
    const maxDistance = (this.filter.maxDistance / 1000).toFixed(1);
    const minDistance = (this.filter.minDistance / 1000).toFixed(1);

    return `${minDistance}km ~ ${maxDistance}km`;
  }
}

export default HotelList;
