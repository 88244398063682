import React from 'react';
import type Trip from '@this/domain/trip/trip';

interface Props {
  trip: Trip;
}

const TripInfoWindow: React.FC<Props> = ({ trip }) => {
  const travelersInfoLabel = () => {
    const travelers = trip.travelerInformations.list;
    let label = utils.dig(travelers[0], 'displayName');
    if (travelers.length > 1) {
      label = `${label} + ${travelers.length - 1}`;
    }

    return label;
  };

  return (
    <ul>
      <li>旅程番号: {trip.id}</li>
      <li>
        旅程: {trip.startTime.format('YYYY/MM/DD')} ~ {trip.endTime.format('YYYY/MM/DD')}
      </li>
      <li>出張者: {travelersInfoLabel()}</li>
    </ul>
  );
};

export default TripInfoWindow;
