import type { ReactElement, RefObject } from 'react';
import ReactDOM from 'react-dom';

import type Trip from '@this/domain/trip/trip';

export default class TripsMapHelper {
  static createMap(ref: RefObject<HTMLDivElement>): google.maps.Map | null {
    if (google && ref.current) {
      return new google.maps.Map(ref.current, {
        zoom: 14,
        center: new google.maps.LatLng(35.681247, 139.766709),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false
      });
    }
    return null;
  }

  static createMarkers(map: google.maps.Map | null, trips: Trip[]): { trip: Trip; marker: google.maps.Marker }[] {
    if (!map) {
      return [];
    }

    return trips.reduce((acc: { trip: Trip; marker: google.maps.Marker }[], trip) => {
      (trip.currentOrder?.order_items || []).forEach((item: any) => {
        let marker: google.maps.Marker | null = null;

        if (item.hotels.length) {
          item.hotels.forEach((h: any) => {
            marker = TripsMapHelper.createHotelMarker(h, map);
          });
        } else if (item.transports.length) {
          item.transports.forEach((t: any) => {
            // 到着地のみマーカーを表示したいため
            const firstItem = trip.currentOrder.order_items[0];
            const firstTransport = firstItem.transports.length ? firstItem.transports[0] : null;
            if (!firstTransport || firstTransport.from.name !== t.to.name) {
              marker = TripsMapHelper.createTransportMarker(t, map);
            }
          });
        }

        if (marker) {
          acc.push({ trip, marker });
        }
      });

      return acc;
    }, []);
  }

  static fitBounds(map: google.maps.Map, markers: google.maps.Marker[]) {
    if (markers.length === 0) {
      return;
    }

    const [maxLatLng, minLatLng] = markers.reduce(
      (acc, marker) => {
        const position = marker.getPosition();
        if (position) {
          const [maxLatLng, minLatLng] = acc;
          const lat = position.lat();
          const lng = position.lng();
          if (maxLatLng.lat < lat) {
            maxLatLng.lat = lat;
          }

          if (maxLatLng.lng < lng) {
            maxLatLng.lng = lng;
          }

          if (minLatLng.lat === 0 || minLatLng.lat > lat) {
            minLatLng.lat = lat;
          }

          if (minLatLng.lng === 0 || minLatLng.lng > lng) {
            minLatLng.lng = lng;
          }
        }

        return acc;
      },
      [
        { lat: 0, lng: 0 },
        { lat: 0, lng: 0 }
      ]
    );

    // minとmaxの差が少ない場合、zoomしすぎてしまうため調整
    if (maxLatLng.lat - minLatLng.lat < 0.1) {
      maxLatLng.lat += 0.05;
      minLatLng.lat -= 0.05;
    }
    if (maxLatLng.lng - minLatLng.lng < 0.1) {
      maxLatLng.lng += 0.05;
      minLatLng.lng -= 0.05;
    }

    map.fitBounds(new google.maps.LatLngBounds(minLatLng, maxLatLng));
  }

  static createInfoWindow<P>(trip: Trip, windowComponent: ReactElement) {
    const id = `infoWindow-${trip.id}`;
    const infoWindow = new google.maps.InfoWindow({
      content: `<div id="${id}" />`
    });
    infoWindow.addListener('domready', () => {
      ReactDOM.render(windowComponent, document.getElementById(id));
    });

    return infoWindow;
  }

  private static createHotelMarker(hotel: any, map: google.maps.Map) {
    const lat = parseFloat(hotel.latitude);
    const lng = parseFloat(hotel.longitude);

    if (!(lat && lng)) {
      return null;
    }

    return new google.maps.Marker({
      position: { lat, lng },
      icon: {
        fillColor: '#FF0000',
        fillOpacity: 1,
        path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        scale: 2,
        strokeColor: '#FF0000',
        strokeWeight: 1.0
      },
      map
    });
  }

  private static createTransportMarker(transport: any, map: google.maps.Map) {
    if (transport.arrival_lat_lng) {
      const lat = parseFloat(transport.arrival_lat_lng.latitude);
      const lng = parseFloat(transport.arrival_lat_lng.longitude);

      if (!(lat && lng)) {
        return null;
      }

      return new google.maps.Marker({
        position: { lat, lng },
        map
      });
    }

    return null;
  }
}
