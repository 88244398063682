import { Fetcher } from '@this/src/util';

interface Props {
  defaultOrigin?: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

interface Params {
  default_origin?: string;
}

interface Response {
  success: boolean;
  errors?: string[];
}

const updateUserSettingApi = ({ defaultOrigin, onSuccess, onError }: Props) => {
  const params: Params = {};
  if (defaultOrigin !== undefined) {
    params.default_origin = defaultOrigin;
  }

  Fetcher.post<Response>('/chatbot/user_settings', params)
    .then(res => {
      if (res.success) {
        onSuccess();
      } else {
        onError(res.errors?.join('\n') ?? '通信に失敗しました。');
      }
    })
    .catch(() => {
      onError('通信に失敗しました。');
    });
};

export default updateUserSettingApi;
