import type { ThemeProps } from 'styled-components';
import { css } from 'styled-components';
import type { Themes } from '@this/constants/themes';
import { styled } from '@this/constants/themes';

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export enum ButtonType {
  PRIMARY = 'primary',
  DANGER = 'danger',
  POSITIVE = 'positive',
  SUB = 'sub'
}

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export interface ButtonColorProps extends ThemeProps<Themes> {
  buttonType?: ButtonType;
}

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const ButtonShape = css`
  display: block;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const ButtonColor = css`
  &,
  &:hover,
  &:focus {
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  ${({ buttonType = ButtonType.PRIMARY, theme }: ButtonColorProps) => {
    switch (buttonType) {
      case ButtonType.PRIMARY:
        return css`
          &,
          &:focus {
            background-color: ${theme.accentColor};
          }
          &:hover {
            background-color: ${theme.accentColorHover};
            opacity: ${theme.linkOpacityHover};
          }
          &.loading {
            opacity: 0.5;
            &:hover {
              background-color: ${theme.accentColor};
              cursor: default;
              opacity: 0.5;
            }
          }
          &:disabled:hover {
            background-color: ${theme.accentColor};
          }
        `;
      case ButtonType.POSITIVE:
        return css`
          &,
          &:focus {
            background-color: ${theme.successColor};
          }
          &:hover {
            background-color: ${theme.successColor};
            opacity: 0.8;
          }
          &:disabled:hover {
            background-color: ${theme.successColor};
          }
        `;
      case ButtonType.DANGER:
        return css`
          &,
          &:focus {
            background-color: ${theme.redColor};
          }
          &:hover {
            background-color: ${theme.redColor};
            opacity: 0.8;
          }
          &:disabled:hover {
            background-color: ${theme.redColor};
          }
        `;
      case ButtonType.SUB:
        return css`
          &,
          &:focus {
            background-color: ${theme.linkColor};
          }
          &:hover {
            background-color: ${theme.linkColor};
            opacity: 0.8;
          }
          &:disabled:hover {
            background-color: ${theme.linkColor};
          }
        `;
      default:
        return '';
    }
  }}
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const ButtonBase = css`
  ${ButtonShape}
  ${ButtonColor}
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const ButtonBase02 = css`
  ${ButtonShape}

  &,
  &:hover,
  &:focus {
    color: white;
  }
  &,
  &:focus {
    background-color: ${props => props.theme.linkColor};
  }
  &:hover {
    background-color: ${props => props.theme.linkColorHover};
    opacity: ${props => props.theme.linkOpacityHover};
  }
  &.loading {
    opacity: 0.5;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: ${props => props.theme.linkColor};
    }
  }
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
const Button = styled.a`
  ${ButtonBase}
  padding: 6px;
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const MidButton = css`
  ${ButtonBase}
  padding: 10px;
  height: 40px;
  border-radius: ${props => props.theme.buttonRadius};
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const SubmitButton = styled.input.attrs({
  type: 'submit'
})`
  &&& {
    ${ButtonBase}
  }
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
const BorderButtonShape = css`
  display: block;
  text-align: center;
  font-weight: bold;
  background-color: transparent;
  border-radius: 3px;
  border: solid 1px;
  cursor: pointer;
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const BorderButtonBase = css`
  ${BorderButtonShape}

  &,
  &:hover,
  &:focus {
    background-color: transparent;
  }
  &,
  &:focus {
    color: ${props => props.theme.accentColor};
  }
  &:hover {
    color: ${props => props.theme.accentColorHover};
    opacity: ${props => props.theme.linkOpacityHover};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      color: ${props => props.theme.accentColor};
      opacity: 1;
    }
  }
`;

export const BorderButtonBase02 = css`
  ${BorderButtonShape}

  &,
  &:focus {
    color: ${props => props.theme.linkColor};
  }
  &:hover {
    color: ${props => props.theme.linkColorHover};
    opacity: ${props => props.theme.linkOpacityHover};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      color: ${props => props.theme.linkColor};
      opacity: 1;
    }
  }
`;

// BorderButtonBaseと同じだが、border-radiusのみ通常のButtonShapeの値を使っている。
// ボタンの形が変わるので、呼び出し元でborder-radiusを上書きするよりもこちらに定義する方が良いと考えた。
/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const BorderButtonBase03 = css`
  ${BorderButtonShape};

  border-radius: 3px;
  &,
  &:focus {
    color: ${props => props.theme.accentColor};
  }
  &:hover {
    color: ${props => props.theme.accentColorHover};
    opacity: ${props => props.theme.linkOpacityHover};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      color: ${props => props.theme.accentColor};
      opacity: 1;
    }
  }
`;

/**
 * @deprecated
 * should use button in js/src/components/shared/ui/inputs/button
 */
export const RoundButton = css`
  margin: auto 10px;
  padding: 5px 20px;
  background: white;
  border: 2px solid ${props => props.theme.linkColor};
  border-radius: 30px;
  color: ${props => props.theme.linkColor};
  text-align: center;
  cursor: pointer;
`;

export default Button;
