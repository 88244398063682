class BulkTicketUnavailableTerm
  constructor: (args = {}) ->
    _.assign @, snakeToCamelObject(args)

  description: ->
    "#{moment(@from).format('YYYY/MM/DD')} 〜 #{moment(@to).format('YYYY/MM/DD')}"

  setFrom: (from) ->
    @from = from
    app.render()

  setTo: (to) ->
    @to = to
    app.render()

  submitParams: ->
    from: @from
    to: @to

module.exports = BulkTicketUnavailableTerm
