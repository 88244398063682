import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';

import { DownloadType } from './types';

interface Props {
  downloadFormat: (encoding: DownloadType) => void;
  onSelect?: (type: DownloadType) => void;
  dataEnabled: boolean;
}
interface State {
  form: {
    encoding: DownloadType;
  };
}

const SELECT_OPTIONS = [
  { value: DownloadType.UTF8, displayName: 'テンプレート（文字コード：UTF8）' },
  { value: DownloadType.SJIS, displayName: 'テンプレート（文字コード：SJIS）' }
];

export const DATA_SELECT_OPTIONS = [
  { value: DownloadType.DATA_UTF8, displayName: '登録データ（文字コード：UTF8）' },
  { value: DownloadType.DATA_SJIS, displayName: '登録データ（文字コード：SJIS）' }
];

export const DEFAULT_ENCODING = navigator.platform.indexOf('Win') !== -1 ? DownloadType.SJIS : DownloadType.UTF8;

export default class DownloadFormat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { form: { encoding: DEFAULT_ENCODING } };
  }

  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const val = value as DownloadType;
    this.setState({ form: { encoding: val } });
    if (this.props.onSelect) this.props.onSelect(val);
  };

  handleClick() {
    this.props.downloadFormat(this.state.form.encoding);
  }

  render() {
    const { dataEnabled } = this.props;
    const {
      form: { encoding }
    } = this.state;

    const options = dataEnabled ? [...SELECT_OPTIONS, ...DATA_SELECT_OPTIONS] : SELECT_OPTIONS;

    return (
      <div className="organization__csv-download">
        <select
          className="organization__csv-download__select"
          value={encoding}
          onChange={this.handleChange}
          style={{ marginBottom: '10px', padding: '5px 2px' }}
        >
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.displayName}
            </option>
          ))}
        </select>
        <Button onClick={() => this.handleClick()}>ダウンロード</Button>
      </div>
    );
  }
}

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase}
  display: inline-block;
  padding: 0.75em 1em;
`;
