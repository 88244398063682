/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import AddIcon from '@material-ui/icons/Add';
import { ButtonBase, ButtonType } from '@this/shared/atoms/button';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import FlashMessage from '@this/shared/flash_message/flash_message';
import {
  OrganizationBiztraTh,
  OrganizationBiztraTdEditButton,
  OrganizationBiztraAddButton,
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationBiztraAddressTd,
  BulkActionLink
} from '@this/components/expenses/organization/organization.style';

import Department from '@this/domain/department/department';
import DepartmentList from '@this/domain/department/department_list';
import User from '@this/domain/user/user';
import SelectApprovers from '@this/components/organization/departments/select_approvers/select_approvers';
import type ExpensesApproverCandidate from '@this/domain/department/expenses_approver_candidate';
import { getColor } from '@this/shared/ui/theme';
import MainTitle from '../../shared/organization/main_title/main_title';
import DepartmentSharesBlock from '../../organization/departments/department_shares_block.template';
import { ModalInputArea, ModalInput, ModalInputLabel } from './members';
import { ActionWrapper, BaseButtonCss, CancelButtonCss } from '../approvals/reject';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '../ui/feedbacks/modal';

interface Props {}

interface Approver {
  userId: number | null;
}

interface State {
  departments: any;
  members: any[];
  projectShareAvailability: boolean;
  expensesMultistageApproval: boolean;
  editingDepartment: any;
  deletingDepartment: any;
  approveStages: { approvers: Approver[] }[];
  title: string;
  loading: boolean;
  saving: boolean;
  error: string | null;
  saveError: string | null;
  mode: string;
  showEditModal: boolean;
}

interface DepartmentsResponse {
  departments: any[];
  members: any[];
  project_share_availability: boolean;
  expenses_multistage_approval: boolean;
}

interface CreateResponse {
  id: number;
}

class Departments extends React.Component<Props, State> {
  private departmentsPath: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      departments: new DepartmentList([], []),
      members: [],
      projectShareAvailability: false,
      expensesMultistageApproval: false,
      editingDepartment: null,
      deletingDepartment: null,
      approveStages: [{ approvers: [{ userId: null }] }],
      title: '',
      loading: false,
      saving: false,
      error: null,
      saveError: null,
      mode: 'department',
      showEditModal: false
    };

    this.departmentsPath = '/biztra/organization/departments';
    this.handleChangeExpensesApprover = this.handleChangeExpensesApprover.bind(this);
    this.handleChangeDepartmentsRadio = this.handleChangeDepartmentsRadio.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchDepartments();
  }

  fetchDepartments() {
    utils
      .jsonPromise<DepartmentsResponse>(`${this.departmentsPath}.json`)
      .then(
        result => {
          const members = _.map(result.members, u => new User(u));
          this.setState({
            departments: new DepartmentList(result.departments, members),
            members,
            projectShareAvailability: result.project_share_availability,
            expensesMultistageApproval: result.expenses_multistage_approval,
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleChangeDepartmentsRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      mode: e.target.id
    });
  }

  handleAddDepartmentClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const department = new Department({}, this.state.members);
    this.state.departments.push(department);
    this.setEditingDepartment(department, '追加');
  }

  handleEditClick(department: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setEditingDepartment(_.cloneDeep(department), '編集');

    const approverCandidates: ExpensesApproverCandidate[] = department.expenses_approver_candidates;
    const uniqueApproveStages = this.uniqueApproveStages(approverCandidates);
    this.setState({
      approveStages:
        uniqueApproveStages.length > 0
          ? uniqueApproveStages.map(stage => ({
              approvers: approverCandidates
                .filter(c => stage === c.approve_stage)
                .map(c => ({
                  userId: c.user_id
                }))
            }))
          : [{ approvers: [{ userId: null }] }]
    });
  }

  setEditingDepartment(department: any, title: string) {
    this.setState({
      editingDepartment: department,
      title,
      approveStages: [{ approvers: [{ userId: null }] }],
      showEditModal: true
    });
  }

  handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveError: null
    });

    if (this.state.editingDepartment.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  }

  submitCreate() {
    utils
      .jsonPromise<CreateResponse>(
        this.departmentsPath,
        {
          ...this.state.editingDepartment.updateParams(),
          expenses_approver_candidates_attributes: {
            ...this.state.approveStages.flatMap((s, i) =>
              s.approvers.filter(a => a.userId).map(a => ({ approve_stage: i + 1, user_id: a.userId }))
            )
          }
        },
        'POST'
      )
      .then(
        result => {
          _.assign(this.state.editingDepartment, { id: result.id });
          this.setState({
            editingDepartment: null,
            saving: false,
            showEditModal: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: true });
        this.fetchDepartments();
      });
  }

  submitUpdate() {
    utils
      .jsonPromise(
        `${this.departmentsPath}/${this.state.editingDepartment.id}`,
        {
          ...this.state.editingDepartment.updateParams(),
          expenses_approver_candidates_attributes: {
            ...this.state.approveStages.flatMap((s, i) =>
              s.approvers.filter(a => a.userId).map(a => ({ approve_stage: i + 1, user_id: a.userId }))
            )
          }
        },
        'PUT'
      )
      .then(
        _result => {
          this.state.departments.update(this.state.editingDepartment);
          this.setState({
            editingDepartment: null,
            saving: false,
            showEditModal: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: true });
        this.fetchDepartments();
      });
  }

  handleCancelClick() {
    // remove only new department
    if (!this.state.editingDepartment.id) {
      this.state.departments.remove(this.state.editingDepartment);
    }
    this.setState({
      editingDepartment: null
    });
  }

  handleFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingDepartment[name](e.target.value);
  }

  handleAddExpensesApprovalAuthority(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.state.editingDepartment.addExpensesApprovalAuthority();
  }

  handleRemoveExpensesApprovalAuthority(authority: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.state.editingDepartment.removeExpensesApprovalAuthority(authority);
  }

  handleChangeExpensesApprover(authority: any, member: any) {
    this.state.editingDepartment.setExpensesApprover(authority, member.id);
  }

  handleChangeExpensesApproverForMultistage = (stageIndex: number, approverIndex: number, memberId: number) => {
    this.setState({
      approveStages: this.state.approveStages.map((s, i) => {
        return i === stageIndex
          ? {
              approvers: s.approvers.map((a, _approverIndex) => {
                return _approverIndex === approverIndex ? { userId: memberId } : a;
              })
            }
          : { approvers: s.approvers };
      })
    });
  };

  handleDeleteAlertClose() {
    this.setState({ deletingDepartment: null });
  }

  handleChangeDepartmentDisabledRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingDepartment.setDisabled(e.target.value === 'disabled');
  }

  handleDeleteConfirm() {
    utils
      .jsonPromise(`/organization/departments/${this.state.deletingDepartment.id}`, {}, 'DELETE')
      .then(
        _result => {
          this.state.departments.remove(this.state.deletingDepartment);
          this.setState({
            deletingDepartment: null,
            saving: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  grayIfNeeded = (disabled: boolean) => {
    return {
      background: disabled ? '#f2f2f3' : '#ffffff'
    };
  };

  uniqueApproveStages = (candidates?: ExpensesApproverCandidate[]): number[] => {
    return _.uniq(candidates?.map(c => c.approve_stage)) || [];
  };

  inputApprovalCandidates = () => {
    const { departments, editingDepartment, expensesMultistageApproval, approveStages } = this.state;
    return (
      <>
        {expensesMultistageApproval ? (
          <>
            {approveStages.map((stageApprovers, stageIndex) => (
              <ApproverGroup key={stageIndex}>
                <Label>{`第${stageIndex + 1}承認者`}</Label>
                <ApproverWrap>
                  <ApproverSection>
                    {stageApprovers.approvers.map((s, approverIndex) => (
                      <Flex key={approverIndex}>
                        <SelectApprovers
                          rawMembers={utils.dig(departments, 'members')}
                          members={editingDepartment.membersNotApprover(s)}
                          selectedId={s.userId!}
                          i={approverIndex}
                          s={stageIndex}
                          onSelectExpensesForMultistage={this.handleChangeExpensesApproverForMultistage}
                        />
                        <ApproverDeleteButtonWrap>
                          <ApproverButton
                            buttonType={ButtonType.DANGER}
                            type="button"
                            onClick={_ => {
                              const removedApproverStages = approveStages?.map((s, i) => {
                                return i === stageIndex
                                  ? {
                                      approvers: s.approvers.filter(
                                        (_, _approverIndex) => _approverIndex !== approverIndex
                                      )
                                    }
                                  : { approvers: s.approvers };
                              });
                              const filteredApproversStages = removedApproverStages.filter(
                                r => r.approvers.length > 0
                              );
                              this.setState({
                                approveStages:
                                  filteredApproversStages.length > 0
                                    ? filteredApproversStages
                                    : [{ approvers: [] }]
                              });
                            }}
                          >
                            - 承認者を削除
                          </ApproverButton>
                        </ApproverDeleteButtonWrap>
                      </Flex>
                    ))}
                  </ApproverSection>
                </ApproverWrap>
                <ApproverButtonWrap>
                  <ApproverButton
                    buttonType={ButtonType.SUB}
                    type="button"
                    onClick={_ => {
                      this.setState({
                        approveStages: approveStages?.map((s, i) => {
                          return i === stageIndex
                            ? { approvers: [...s.approvers, { userId: null }] }
                            : { approvers: s.approvers };
                        })
                      });
                    }}
                  >
                    + 承認者を追加
                  </ApproverButton>
                </ApproverButtonWrap>
                {approveStages.length === stageIndex + 1 && approveStages[stageIndex].approvers.length > 0 && (
                  <ApproverButtonWrap>
                    <ApproverButton
                      buttonType={ButtonType.SUB}
                      type="button"
                      onClick={_ => {
                        this.setState({
                          approveStages: [...approveStages, { approvers: [{ userId: null }] }]
                        });
                      }}
                    >
                      + ステップ追加
                    </ApproverButton>
                  </ApproverButtonWrap>
                )}
              </ApproverGroup>
            ))}
          </>
        ) : (
          <ApproverInputArea>
            <Label htmlFor="approver">
              承認者
              <small>*管理者は承認者に設定できません</small>
            </Label>
            {editingDepartment.expensesApprovalAuthoritiesToShow().map((auth: any, i: number) => (
              <Flex key={i}>
                <SelectApprovers
                  rawMembers={utils.dig(departments, 'members')}
                  members={editingDepartment.membersNotAdmin()}
                  selectedId={auth.expenses_approver_id}
                  i={i + 1}
                  s={0}
                  onSelectExpenses={this.handleChangeExpensesApprover}
                  auth={auth}
                />
                <ApproverDeleteButtonWrap>
                  <ApproverButton
                    buttonType={ButtonType.DANGER}
                    type="button"
                    onClick={e => this.handleRemoveExpensesApprovalAuthority(auth, e)}
                  >
                    - 承認者を削除
                  </ApproverButton>
                </ApproverDeleteButtonWrap>
              </Flex>
            ))}
            <ApproverButtonWrap>
              <ApproverButton
                buttonType={ButtonType.SUB}
                type="button"
                onClick={e => this.handleAddExpensesApprovalAuthority(e)}
              >
                + 承認者を追加
              </ApproverButton>
            </ApproverButtonWrap>
          </ApproverInputArea>
        )}
      </>
    );
  };

  render() {
    try {
      const {
        departments,
        projectShareAvailability,
        editingDepartment,
        deletingDepartment,
        expensesMultistageApproval,
        members,
        title,
        loading,
        saving,
        error,
        saveError,
        showEditModal
      } = this.state;

      return (
        <>
          <FlashMessage message={saveError} type="error" />
          <MainTitle
            value="部署マスタ"
            buttons={
              <TheAddButton onClick={e => this.handleAddDepartmentClick(e)}>
                <AddIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'text-top' }} />
                部署を追加
              </TheAddButton>
            }
          />
          <ContentBody>
            {projectShareAvailability && (
              <OrganizationDepartmentsRadioDiv>
                <OrganizationDepartmentsRadioInput
                  type="radio"
                  name="mode"
                  id="department"
                  onChange={this.handleChangeDepartmentsRadio}
                  checked={this.state.mode === 'department'}
                />
                <label htmlFor="department">部署</label>

                <OrganizationDepartmentsRadioInput
                  type="radio"
                  name="mode"
                  id="share"
                  onChange={this.handleChangeDepartmentsRadio}
                  checked={this.state.mode === 'share'}
                />
                <label htmlFor="share">按分</label>
              </OrganizationDepartmentsRadioDiv>
            )}

            {this.state.mode === 'department' ? (
              <>
                <BulkActionArea>
                  <BulkActionLink to="/biztra/organization/departments/csv/bulk_upsert">
                    部署一括追加・更新
                  </BulkActionLink>
                  {expensesMultistageApproval && (
                    <BulkActionLink to="/biztra/organization/departments/csv/bulk_update_approvers">
                      承認者一括追加・更新
                    </BulkActionLink>
                  )}
                </BulkActionArea>
                <TheTable>
                  <tbody>
                    <tr>
                      <OrganizationBiztraTh width="8%">部署コード</OrganizationBiztraTh>
                      <OrganizationBiztraTh width="18%">部署名</OrganizationBiztraTh>
                      <OrganizationBiztraTh width="14%">承認者</OrganizationBiztraTh>
                      <OrganizationBiztraTh width="10%">郵便番号</OrganizationBiztraTh>
                      <OrganizationBiztraTh>住所</OrganizationBiztraTh>
                      <OrganizationBiztraTh width="14%">宛名</OrganizationBiztraTh>
                      <OrganizationBiztraTh width="90px">編集</OrganizationBiztraTh>
                    </tr>
                    {loading ? (
                      <tr>
                        <td colSpan={11}>
                          <SimpleLoading />
                        </td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan={11}>
                          <Error>{error}</Error>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {utils.dig(departments, 'list').map((d: any, i: number) => (
                          <tr style={this.grayIfNeeded(d.disabled)} key={i}>
                            <OrganizationBiztraTd>{d.code}</OrganizationBiztraTd>
                            <OrganizationBiztraTd>{d.name}</OrganizationBiztraTd>
                            <OrganizationBiztraTd>
                              {expensesMultistageApproval ? (
                                <Grid>
                                  {d.expenses_approver_candidates?.length > 0 &&
                                    this.uniqueApproveStages(d.expenses_approver_candidates).map(stage => (
                                      <div key={stage}>
                                        <p>{`第${stage}承認者`}</p>
                                        {d.expenses_approver_candidates
                                          .filter((c: ExpensesApproverCandidate) => c.approve_stage === stage)
                                          .map((c: ExpensesApproverCandidate, candidateIndex: number) => (
                                            <div key={`${i}_${stage}_${candidateIndex}`}>
                                              {members.find(m => m.id === c.user_id)?.name}
                                            </div>
                                          ))}
                                      </div>
                                    ))}
                                </Grid>
                              ) : (
                                <>
                                  {d.expensesApprovers().map((approver: any, j: number) => (
                                    <ApproverList key={j}>{approver.name}</ApproverList>
                                  ))}
                                </>
                              )}
                            </OrganizationBiztraTd>
                            <OrganizationBiztraAddressTd>{d.postalCode}</OrganizationBiztraAddressTd>
                            <OrganizationBiztraAddressTd>{d.address}</OrganizationBiztraAddressTd>
                            <OrganizationBiztraTd>{d.addressee}</OrganizationBiztraTd>
                            <OrganizationBiztraTd>
                              {!editingDepartment && (
                                <OrganizationBiztraTdEditButton onClick={e => this.handleEditClick(d, e)}>
                                  編集
                                </OrganizationBiztraTdEditButton>
                              )}
                            </OrganizationBiztraTd>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </TheTable>
                {!editingDepartment ? null : (
                  <BiztraModal size="large" onClose={() => this.handleCancelClick()} open={showEditModal}>
                    <BiztraModalHeader>部署情報を{title}する</BiztraModalHeader>
                    <BiztraModalBody>
                      <form onSubmit={e => this.handleFormSubmit(e)}>
                        <EditWrap>
                          <EditSection className="leftSection">
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="code">部署コード</DepartmentsModalInputLabel>
                              <ModalInput
                                id="code"
                                type="text"
                                value={editingDepartment.code}
                                onChange={e => this.handleFieldChange('setCode', e)}
                                autoFocus={showEditModal}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="name">部署名</DepartmentsModalInputLabel>
                              <ModalInput
                                id="name"
                                type="text"
                                value={editingDepartment.name}
                                onChange={e => this.handleFieldChange('setName', e)}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="postal_code">
                                郵便番号
                              </DepartmentsModalInputLabel>
                              <ModalInput
                                id="postal_code"
                                type="text"
                                value={editingDepartment.postalCode}
                                onChange={e => this.handleFieldChange('setPostalCode', e)}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="address">住所</DepartmentsModalInputLabel>
                              <ModalInput
                                id="address"
                                type="text"
                                value={editingDepartment.address}
                                onChange={e => this.handleFieldChange('setAddress', e)}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="addressee">宛名</DepartmentsModalInputLabel>
                              <ModalInput
                                id="addressee"
                                type="text"
                                value={editingDepartment.addressee}
                                onChange={e => this.handleFieldChange('setAddressee', e)}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel htmlFor="monthly_budget">
                                月次予算
                              </DepartmentsModalInputLabel>
                              <ModalInput
                                id="monthly_budget"
                                type="number"
                                value={editingDepartment.monthly_budget}
                                onChange={e => this.handleFieldChange('setMonthlyBudget', e)}
                              />
                            </ModalInputArea>
                            <ModalInputArea>
                              <DepartmentsModalInputLabel>状態</DepartmentsModalInputLabel>
                              <Flex>
                                <DepartmentEditRadioValue>
                                  <input
                                    type="radio"
                                    value="enabled"
                                    onChange={e => this.handleChangeDepartmentDisabledRadio(e)}
                                    checked={!editingDepartment.disabled}
                                  />
                                  <span>有効</span>
                                </DepartmentEditRadioValue>
                                <DepartmentEditRadioValue>
                                  <input
                                    type="radio"
                                    value="disabled"
                                    onChange={e => this.handleChangeDepartmentDisabledRadio(e)}
                                    checked={editingDepartment.disabled}
                                  />
                                  <span>無効</span>
                                </DepartmentEditRadioValue>
                              </Flex>
                            </ModalInputArea>
                          </EditSection>
                          <EditSection className="rightSection">{this.inputApprovalCandidates()}</EditSection>
                        </EditWrap>
                        {saving && (
                          <Saving>
                            <SimpleLoading />
                          </Saving>
                        )}
                        <ActionWrapper>
                          <CancelButton type="reset" value="キャンセル" onClick={() => this.handleCancelClick()} />
                          <SubmitButton type="submit" value="保存" />
                        </ActionWrapper>
                      </form>
                    </BiztraModalBody>
                  </BiztraModal>
                )}
              </>
            ) : (
              <DepartmentSharesBlock />
            )}
          </ContentBody>
          {deletingDepartment && (
            <Modal show hideModal={() => this.handleDeleteAlertClose()}>
              <Confirm
                onConfirmed={() => this.handleDeleteConfirm()}
                onAborted={() => this.handleDeleteAlertClose()}
                message={`${deletingDepartment.name}(${deletingDepartment.code})を削除してよろしいですか？\n※この操作は取り消せません`}
              />
            </Modal>
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ApproverList = styled.p``;

const EditWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px calc(20 / 1248 * 100%);
`;

const EditSection = styled.div`
  padding: 5px;
  &.leftSection {
    width: 40%;
  }
  &.rightSection {
    width: 60%;
    max-width: 624px;
  }
`;

const DepartmentsModalInputLabel = styled(ModalInputLabel)`
  min-width: 75px;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: normal;
`;

const ApproverInputArea = styled.div`
  position: relative;
  margin-bottom: 10px;
`;

const ApproverButtonWrap = styled.div`
  float: left;
  width: 90px;
  margin: 0 10px 0 auto;
  font-size: 10px;
`;

const ApproverButton = styled.button`
  &&& {
    ${ButtonBase};
    width: 90px;
    margin: 0;
    padding: 0.75em 1em;
  }
`;

const ApproverGroup = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 16px;
`;

const ApproverDeleteButtonWrap = styled(ApproverButtonWrap)`
  margin: 18px 0 0 6px;
  flex-basis: 20%;
`;

const Saving = styled.div``;

export const TheAddButton = styled(OrganizationBiztraAddButton)`
  width: auto;
  margin: 0;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const ContentBody = styled.div`
  flex-grow: 9999;
`;

const OrganizationDepartmentsRadioDiv = styled.div`
  height: 40px;
  padding-top: 10px;
`;

const OrganizationDepartmentsRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

const DepartmentEditRadioValue = styled.label`
  width: 80px;
  > span {
    padding-left: 4px;
  }
`;

const TheTable = styled(OrganizationBiztraTable)`
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
`;

const Grid = styled.div`
  display: grid;
  gap: 8px;
`;

const ApproverWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ApproverSection = styled.div`
  width: 100%;
  flex-basis: auto;
`;

const CancelButton = styled.input`
  &&& {
    ${CancelButtonCss}
  }
`;

const SubmitButton = styled.input`
  &&& {
    ${BaseButtonCss}
  }
`;

const BulkActionArea = styled.div`
  ${BulkActionLink} {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export default Departments;
