import type { ChangeEvent } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';
import type { Moment } from 'moment';
import { Input } from '@this/shared/ui/inputs/input';
import DatePickerForSearch from '../search_form/date_picker_for_search';

type Status = '' | 'draft' | 'applied' | 'approved' | 'rejected';

interface Props {
  onSetSearchParams: (
    paidAtFrom: string,
    paidAtTo: string,
    selectedPayee: string,
    priceFrom: string,
    priceTo: string,
    selectedStatus: Status,
    tripReportId: string
  ) => void;
  onCancel: () => void;
  payees: string[];
  tripReports: [number, string][];
  paidAtFrom: string;
  paidAtTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  selectedStatus: Status;
  tripReportId: string;
  tripReportAvailable: boolean;
}

interface State {
  paidAtFrom: string;
  paidAtTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  selectedStatus: Status;
  tripReportId: string;
}

export default class SearchForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paidAtFrom: props.paidAtFrom,
      paidAtTo: props.paidAtTo,
      selectedPayee: props.selectedPayee,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      selectedStatus: props.selectedStatus,
      tripReportId: props.tripReportId
    };
  }

  getFormat = (): string => 'YYYY-MM-DD';

  statuses = [
    { value: 'draft', label: '未申請' },
    { value: 'applied', label: '承認待ち' },
    { value: 'approved', label: '承認済み' },
    { value: 'rejected', label: '差し戻し' }
  ];

  handleChangePaidAtFrom = (value: Moment) => {
    this.setState({ paidAtFrom: (value && value.format(this.getFormat())) || '' });
  };

  handleChangePaidAtTo = (value: Moment) => {
    this.setState({ paidAtTo: (value && value.format(this.getFormat())) || '' });
  };

  handleChangeSelectedPayee = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedPayee: e.target.value });
  };

  handleChangePriceFrom = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceFrom: e.target.value });
  };

  handleChangePriceTo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceTo: e.target.value });
  };

  handleChangeSelectedStatus = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedStatus: e.target.value as Status });
  };

  handleChangeSelectedTripReportId = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ tripReportId: e.target.value });
  };

  setSearchParams = () => {
    this.props.onSetSearchParams(
      this.state.paidAtFrom,
      this.state.paidAtTo,
      this.state.selectedPayee,
      this.state.priceFrom,
      this.state.priceTo,
      this.state.selectedStatus,
      this.state.tripReportId
    );
  };

  render() {
    return (
      <Wrapper>
        <Header>検索条件を指定</Header>
        <SearchFormSection>
          <Title>期間</Title>
          <Row3Column>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.paidAtFrom} onChange={this.handleChangePaidAtFrom} />
            </DatePickerForSearchWrapper>
            <FromTo>～</FromTo>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.paidAtTo} onChange={this.handleChangePaidAtTo} />
            </DatePickerForSearchWrapper>
          </Row3Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>取引先</Title>
          <Row1Column>
            <Select value={this.state.selectedPayee} onChange={this.handleChangeSelectedPayee}>
              <option value="" />
              {this.props.payees.map(payee => (
                <option value={payee} key={payee}>
                  {payee}
                </option>
              ))}
            </Select>
          </Row1Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>金額</Title>
          <Row3Column>
            <SearchFormInput
              value={this.state.priceFrom}
              onChange={this.handleChangePriceFrom}
              size="small"
              type="number"
              inputProps={{
                placeholder: '100000',
                style: { fontSize: 14 }
              }}
            />
            <FromTo>～</FromTo>
            <SearchFormInput
              value={this.state.priceTo}
              onChange={this.handleChangePriceTo}
              size="small"
              type="number"
              inputProps={{
                placeholder: '999999999',
                style: { fontSize: 14 }
              }}
            />
          </Row3Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>ステータス</Title>
          <Row1Column>
            <SelectWrapper>
              <Select value={this.state.selectedStatus} onChange={this.handleChangeSelectedStatus}>
                <option value="">選択してください</option>
                {this.statuses.map(s => (
                  <option value={s.value} key={s.value}>
                    {s.label}
                  </option>
                ))}
              </Select>
            </SelectWrapper>
          </Row1Column>
        </SearchFormSection>
        {this.props.tripReportAvailable && this.props.tripReports.length > 0 && (
          <SearchFormSection>
            <Title>出張報告</Title>
            <Row1Column>
              <SelectWrapper>
                <Select value={this.state.tripReportId} onChange={this.handleChangeSelectedTripReportId}>
                  <option value="">選択してください</option>
                  {this.props.tripReports.map(([id, name]) => (
                    <option value={id.toString()} key={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            </Row1Column>
          </SearchFormSection>
        )}
        <ActionWrapper>
          <OutlineSmallButton onClick={this.props.onCancel}>キャンセル</OutlineSmallButton>
          <SecondarySmallButton onClick={this.setSearchParams}>検索</SecondarySmallButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const blackTxtColor = '#323232';

const SecondaryBtnColor = '#927230';

const SecondaryTxtColor = '#ffffff';

const outlineBtnColor = '#FFFFFF';

const outlineTxtColor = '#927230';

export const Header = styled.p`
  border-radius: 5px;
  color: ${blackTxtColor};
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0 30px;
  text-align: center;
  overflow-wrap: break-word;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 10px;
  }
`;

export const SearchFormSection = styled.div`
  display: flex;
  padding: 10px 0;
  @media screen and (max-width: 767px) {
    display: block;
    padding: 12px 0 0;
  }
`;

export const Row1Column = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const Row3Column = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
  width: 100px;
`;

export const DatePickerForSearchWrapper = styled.div`
  flex-grow: 1;
`;

export const SearchFormInput = styled(Input)`
  flex-grow: 1;
  padding: 10px;
  background: #faf9f8;

  .MuiOutlinedInput-notchedOutline {
    border-color: #f1ede5;
  }
`;

export const FromTo = styled.div`
  padding: 0 10px;
  line-height: 45px;
`;

export const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 24px;
  @media screen and (max-width: 767px) {
    margin-bottom: unset;
  }
`;

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${outlineTxtColor};
  border: 1px solid ${outlineTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${outlineTxtColor};
  }
`;

const SecondaryButton = styled.button.attrs({ type: 'button' })`
  background: ${SecondaryBtnColor};
  color: ${SecondaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${SecondaryBtnColor};
  }
`;

export const OutlineSmallButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
`;

export const SecondarySmallButton = styled(SecondaryButton)`
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
`;

export const Wrapper = styled.div`
  padding: 12px;
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 17px;
    right: 16px;
    width: 8px;
    height: 8px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Select = styled.select`
  appearance: none;
  background: #faf9f8;
  border-radius: 4px;
  border: 1px solid #f1ede5;
  font-size: 14px;
  padding: 13px 10px;
  margin-bottom: 0;
  width: 100%;
`;
