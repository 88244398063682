import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Divider } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import type { CsvImportActionArgs } from '@this/domain/csv_import_action/csv_import_action';
import CsvImportAction from '@this/domain/csv_import_action/csv_import_action';

interface IndexResponse {
  actions: CsvImportActionArgs[];
}

const ApproveItemListCsvImportActions = observer(() => {
  const { approveItemId } = useParams<{ approveItemId: string }>();
  const [actions, setActions] = useState<CsvImportAction[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);

  const indexPath = `/organization/approve_items/${approveItemId}/approve_item_lists/approve_item_list_csv_import_actions`;

  const fetchActions = async () => {
    setFetching(true);
    setFetchError(null);
    try {
      const res = await utils.jsonPromise<IndexResponse>(`${indexPath}.json`);
      const as = res.actions.map(a => new CsvImportAction(a));
      setActions(as);
    } catch {
      setFetchError('エラーが発生しました');
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchActions();
  }, []);

  return (
    <Wrapper>
      <Box style={{ marginBottom: '10px' }}>
        <Link to={`/organization/approve_items/${approveItemId}/approve_item_lists/bulk_upsert`}>
          一括登録・更新に戻る
        </Link>
      </Box>
      <HistoryTitleWrapper>
        <h3>アップロード履歴</h3>
        <p style={{ color: '#aaa' }}>合計：{actions.length}件</p>
      </HistoryTitleWrapper>
      <Divider />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ファイル名</TableCell>
            <TableCell>アップロード日時</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>詳細</TableCell>
          </TableRow>
        </TableHead>
        {fetching ? (
          <Loading />
        ) : (
          <TableBody>
            {actions.map(a => (
              <TableRow key={a.id} hover>
                <TableCell>{a.filename}</TableCell>
                <TableCell>{a.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>
                <TableCell>{a.statusMessage}</TableCell>
                <TableCell>
                  <Link to={`${indexPath}/${a.id}`}>詳細</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {fetchError && <Box color="red">{fetchError}</Box>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const HistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ApproveItemListCsvImportActions;
