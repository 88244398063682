import type { ParticipantArgs } from './trip_args';

type EditableFields = 'name' | 'peopleNum';

export class Participant {
  id?: number;

  nonOrderItemId: number;

  name: string;

  peopleNum: number;

  constructor(args: ParticipantArgs) {
    this.id = args.id;
    this.nonOrderItemId = args.non_order_item_id;
    this.name = args.name;
    this.peopleNum = args.people_num || 1;
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      non_order_item_id: this.nonOrderItemId,
      name: this.name,
      people_num: this.peopleNum
    };
  }
}
