import { observable } from 'mobx';
import moment from 'moment';
import type { Moment } from 'moment';
import type { InvoiceCustomFormatFieldArgs } from './invoice_custom_format_field';
import InvoiceCustomFormatField from './invoice_custom_format_field';

export interface InvoiceCustomFormatArgs {
  id?: number;
  name: string;
  disabled: boolean;
  invoice_custom_format_fields?: InvoiceCustomFormatFieldArgs[];
  updated_at?: string;
}

class InvoiceCustomFormat {
  @observable
  id?: number;

  @observable
  name: string;

  @observable
  disabled: boolean;

  @observable
  invoiceCustomFormatFields: InvoiceCustomFormatField[];

  updatedAt?: Moment;

  constructor(args: InvoiceCustomFormatArgs) {
    this.id = args.id;
    this.name = args.name || '';
    this.disabled = args.disabled || false;
    this.invoiceCustomFormatFields = (args.invoice_custom_format_fields || []).map(
      field => new InvoiceCustomFormatField(field)
    );
    this.updatedAt = args.updated_at ? moment(args.updated_at) : undefined;
  }

  submitParams() {
    return {
      name: this.name,
      disabled: this.disabled,
      invoice_custom_format_fields_attributes: this.invoiceCustomFormatFields.map(field => field.submitParams())
    };
  }

  validate(): string[] {
    const errors: string[] = [];
    if (this.name === '') {
      errors.push('名前を入力してください');
    }
    return errors;
  }
}

export default InvoiceCustomFormat;
