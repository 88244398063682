import React from 'react';

const FooterPc = () => {
  return (
    <footer className="flex">
      <a href="https://www.aitravel.company" target="_blank" rel="noreferrer">
        会社概要
      </a>
      <a href="https://aitravel.cloud/tos">利用約款</a>
      <a href="https://aitravel.cloud/policy/">個人情報保護方針</a>
      <a href="help">よくある質問</a>
      <a href="/inquiries/new">お問い合わせ</a>
      <div className="copyright">© TRANSFER DATA Inc.</div>
    </footer>
  );
};

export default FooterPc;
