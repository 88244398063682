/**
 * ブラウザ操作や判定を行うためのUtilです
 */

/**
 * iOSかどうか
 * TODO: iPad x safariだとfalseになります
 */
export const iOS = () => {
  const ua = navigator.userAgent;
  if (/iPhone/.test(ua) || /iPad/.test(ua) || /iPod/.test(ua)) {
    return true;
  }
  return false;
};

/**
 * bodyの末尾に指定のURLのスクリプトを追加します
 */
export const appendScript = (url: string) => {
  const e = document.createElement('script');
  e.charset = 'utf8';
  e.src = url;
  return document.body.appendChild(e);
};

/**
 * 上部までスクロール
 */
export const scrollToTop = () => {
  if (window) {
    window.scrollTo(0, 0);
  }
};

/**
 * 選択されている文字列をクリップボードにコピーします。
 */
export const copyToClipboard = (showAlert = true) => {
  document.execCommand('copy');
  if (showAlert) {
    alert('クリップボードにコピーしました');
  }
};

export const selectRange = (el: HTMLElement) => {
  let sel;
  if (document.createRange && window.getSelection) {
    const range = document.createRange();
    sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (error) {
        range.selectNode(el);
        sel.addRange(range);
      }
    }
  }
  return sel;
};

export const copyElementToClipBoard = (el: HTMLElement, showAlert = true) => {
  if (document.createRange && window.getSelection) {
    selectRange(el);
    copyToClipboard(showAlert);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (document.body.createTextRange) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
    copyToClipboard(showAlert);
  }
};
