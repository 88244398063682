export const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  10: 10,
  1000: 1000,
  1100: 1100,
  1200: 1200,
  1300: 1300,
  1400: 1400,
  1500: 1500,
  1600: 1600,
  1700: 1700,
  1800: 1800
};

export type ZIndices = typeof zIndices;
