import React from 'react';
import { styled } from '@this/constants/themes';
import { OemService } from '@this/domain/oem_service';
import { ByMedia } from '@this/shared/ui/other/by_media';
import { getColor } from '@this/shared/ui/theme';
import { Button } from '@this/shared/ui/inputs/button';
import { Link } from '@this/shared/ui/navigations/link';

interface Props {
  signedIn: boolean;
  serviceId?: number;
  ignoreMenu?: boolean;
}

interface State {
  service: OemService;
}

class Footer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      service: new OemService({ id: props.serviceId })
    };
  }

  render() {
    const { signedIn, ignoreMenu } = this.props;
    const { service } = this.state;
    let items = service.naviItems();
    if (!signedIn) {
      items = items.filter(item => !item.onlySignedIn);
    }
    return (
      <ByMedia>
        {matches =>
          matches.sm ? (
            <SPFooter>
              {ignoreMenu === undefined || ignoreMenu === false ? (
                <SPNaviContainer>
                  <NaviList>
                    {items.map(item => (
                      <SPNaviItem key={item.link}>
                        <NaviLink
                          href={item.link}
                          target={item.newTab ? '_blank' : ''}
                          rel={item.newTab ? 'noopener noreferrer' : ''}
                        >
                          {item.title}
                        </NaviLink>
                      </SPNaviItem>
                    ))}
                  </NaviList>
                  <NaviList>
                    {!signedIn && (
                      <SPNaviItem>
                        <SPButton href="/users/sign_in">ログイン</SPButton>
                      </SPNaviItem>
                    )}
                    {signedIn && (
                      <SPNaviItem>
                        <SPButton id="userEdit" href="/users/edit">
                          アカウント設定
                        </SPButton>
                      </SPNaviItem>
                    )}
                    {signedIn && (
                      <SPNaviItem>
                        <SPButton id="logout" href="/users/sign_out" isExternal>
                          ログアウト
                        </SPButton>
                      </SPNaviItem>
                    )}
                  </NaviList>
                </SPNaviContainer>
              ) : (
                <SPNaviContainer />
              )}
              <SPCopyrightContainer>
                <SPLogo />
                <Copyright />
              </SPCopyrightContainer>
            </SPFooter>
          ) : (
            <PCFooter>
              {ignoreMenu === undefined || ignoreMenu === false ? (
                <NaviContainer>
                  <NaviList>
                    {items.map(item => (
                      <PCNaviItem key={item.title}>
                        <NaviLink
                          href={item.link}
                          target={item.newTab ? '_blank' : ''}
                          rel={item.newTab ? 'noopener noreferrer' : ''}
                        >
                          {item.title}
                        </NaviLink>
                      </PCNaviItem>
                    ))}
                  </NaviList>
                  <NaviList>
                    {!signedIn && (
                      <PCNaviItem>
                        <Link href="/users/sign_in">ログイン</Link>
                      </PCNaviItem>
                    )}
                  </NaviList>
                </NaviContainer>
              ) : (
                <NaviContainer />
              )}
              <CopyrightContainer>
                <Logo />
                <Copyright />
              </CopyrightContainer>
            </PCFooter>
          )
        }
      </ByMedia>
    );
  }
}

const TheFooter = styled.footer`
  background-color: ${getColor('background', 'tertiary')};
`;

const PCFooter = styled(TheFooter)`
  display: flex;
  justify-content: space-between;
  padding: 40px 60px 30px;
`;

const SPFooter = styled(TheFooter)`
  background: ${getColor('background', 'tertiary')} url('${props => props.theme.footerBgPath}') center/cover
    no-repeat;
  padding: 0;
`;

const NaviContainer = styled.div``;

const SPNaviContainer = styled(NaviContainer)`
  display: flex;
  justify-content: space-between;
  padding: 30px;
`;

const NaviList = styled.ul`
  font-size: 14px;
  margin-bottom: 20px;
`;

const PCNaviItem = styled.li`
  display: inline-block;
  margin-left: 25px;
  &:first-child {
    margin-left: 0;
  }
  font-size: 12px;
`;

const SPNaviItem = styled.li`
  margin-top: 20px;
`;

const SPButton = styled(Button)`
  width: 140px;
`;

const NaviLink = styled.a`
  color: ${getColor('common', 'white')};
  &:hover {
    color: ${getColor('common', 'white')};
    opacity: 0.8;
  }
`;

const CopyrightContainer = styled.div``;

const SPCopyrightContainer = styled(CopyrightContainer)`
  text-align: center;
  padding: 30px;
  background-color: ${getColor('background', 'overlayDark')};
`;

const Logo = styled.div`
  background-image: url(${props => props.theme.footerLogoPath});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 32px;
`;

const SPLogo = styled(Logo)`
  margin: 0 auto;
`;

const Copyright = styled.span`
  color: white;
  font-size: 10px;
  &::before {
    content: '${props => props.theme.copyright}';
  }
`;

export default Footer;
