import React from 'react';
import type { Moment } from 'moment';
import TextArea from 'react-textarea-autosize';
import type SupportFeeElement from '@this/domain/support_fee_element';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

interface Props {
  element: SupportFeeElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const SupportFeeElementForm = ({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('content')}`}>
        内容
        <span className="red">（必須）</span>
      </label>
      <TextArea
        type="text"
        value={element.content}
        onChange={e => element.handleContentChange(e.target.value)}
        minRows={1}
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('reservedAt')}`}>
        予約日
      </label>
      <DatetimePicker
        value={element.reservedAt}
        onChange={(time: Moment) => element.handleReservedAtChange(time)}
        showTime={false}
        disabledDays={0}
        showPast
      />
    </div>
  </>
);

export default SupportFeeElementForm;
