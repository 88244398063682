import type {
  OrganizationSystemFeeSettingPaymentMethodType,
  OrganizationSystemFeeSettingPaymentGatewayType,
  OrganizationSystemFeeSettingArgs
} from './organization_system_fee_setting';
import { OrganizationSystemFeeSetting } from './organization_system_fee_setting';
import type { PaymentGatewayUserArgs } from './payment_gateway_user';
import { PaymentGatewayUser } from './payment_gateway_user';

export type TripPaymentMethodType = OrganizationSystemFeeSettingPaymentMethodType;
export type TripPaymentGatewayType = OrganizationSystemFeeSettingPaymentGatewayType;

export type PaymentSettingArgs = {
  method_type: TripPaymentMethodType;
  gateway_type: TripPaymentGatewayType;
  gateway_members: PaymentGatewayUserArgs[];
  token_generate_script_url: string;
  system_fee: OrganizationSystemFeeSettingArgs;
};

export class PaymentSetting {
  methodType: TripPaymentMethodType;

  gatewayType: TripPaymentGatewayType;

  tokenGenerateScriptUrl: string;

  gatewayMembers: PaymentGatewayUser[];

  systemFee: OrganizationSystemFeeSetting;

  constructor(args: PaymentSettingArgs) {
    this.methodType = args.method_type;
    this.gatewayType = args.gateway_type;
    this.tokenGenerateScriptUrl = args.token_generate_script_url;
    this.gatewayMembers = args.gateway_members.map(m => new PaymentGatewayUser(m));
    this.systemFee = new OrganizationSystemFeeSetting(args.system_fee);
  }
}
