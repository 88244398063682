import React from 'react';
import { styled } from '@this/constants/themes';
import type User from '@this/domain/user/user';
import Link from '@this/components/shared/atoms/link';
import A from '@this/components/shared/atoms/a';

interface Props {
  user: User;
  className?: string;
}

const Profile = ({ user, className }: Props) => {
  return (
    <Wrapper className={className}>
      {user.departmentName() && <Small data-wovn-ignore>{user.departmentName()} </Small>}
      <UserName data-wovn-ignore>{user.name}</UserName>
      <Small data-wovn-ignore>{user.email}</Small>
      <LinkArea>
        <Setting to="/users/edit">アカウント設定</Setting>
        <VerticalLine />
        <SignOut href="/users/sign_out">ログアウト</SignOut>
      </LinkArea>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
`;

const Small = styled.div`
  font-size: 10px;
  font-weight: bold;
`;

const UserName = styled.div`
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: bold;
`;

const LinkArea = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-top: 15px;
`;

const VerticalLine = styled.div`
  width: 2px;
  height: 8px;
  background: ${props => props.theme.grayBgColor};
  margin: auto 5px;
`;

const Setting = styled(Link)`
  margin-left: auto;
`;

const SignOut = styled(A)`
  margin-right: auto;
`;

export default Profile;
