import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import { Fetcher } from '@this/src/util';
import type Invoice from '@this/src/domain/invoice/invoice2';
import type InvoiceCustomFormat from '@this/src/domain/invoice_custom_format/invoice_custom_format';
import { Button } from '@this/shared/ui/inputs/button';
import InvoiceCustomCsv from '@this/src/domain/invoice_custom_csv/invoice_custom_csv';
import type { InvoiceCustomCsvArgs } from '@this/src/domain/invoice_custom_csv/invoice_custom_csv';
import { InvoiceSplittedTableRow } from './invoice_splitted_table_row';

interface Props {
  invoice: Invoice;
  invoiceCustomFormats: InvoiceCustomFormat[];
  encoding: string;
  setShowExternalInvoice: Dispatch<SetStateAction<Invoice | null>>;
}

interface Response {
  csvs: InvoiceCustomCsvArgs[];
}

export const InvoiceTableRow: React.FC<Props> = observer(
  ({ invoice, invoiceCustomFormats, encoding, setShowExternalInvoice }) => {
    const invoiceCustomCsvButton = (csv: InvoiceCustomCsv) => {
      const format = invoiceCustomFormats.find(f => f.id === csv.invoiceCustomFormatId);
      if (!format) return null;
      return (
        <Button
          size="small"
          key={csv.id}
          href={
            csv.onGoing
              ? ''
              : `/organization/invoices/${invoice.id}/invoice_custom_csvs/${csv.id}.csv?encoding=${encoding}`
          }
          disabled={csv.onGoing}
          isExternal
        >
          {csv.onGoing ? '作成中' : format.name}
        </Button>
      );
    };

    const createCustomCsv = async () => {
      const res = await Fetcher.post<Response>(
        `/organization/invoices/${invoice.id}/invoice_custom_csvs.json`,
        {}
      );
      const csvs = res.csvs.map(raw => new InvoiceCustomCsv(raw));
      invoice.invoiceCustomCsvs = csvs;
    };

    return (
      <>
        <Tr key={invoice.id} noBorder={invoice.invoice_splitteds.length > 0}>
          <Td>{`${invoice.targetYear}年${invoice.targetMonth}月分`} </Td>
          <Td>{invoice.chargedAt.format('YYYY/MM/DD')}</Td>
          {invoice.showQualifiedInvoice ? (
            <Td>{utils.formatPriceWithMark(invoice.totalPriceWithTaxFromTaxes)}</Td>
          ) : (
            <Td>{utils.formatPriceWithMark(invoice.totalPriceWithTax)}</Td>
          )}
          <Td>{invoice.payAt.format('YYYY/MM/DD')}</Td>
          <Td style={{ display: 'flex' }}>
            <Button
              size="small"
              href={`/organization/invoices/${invoice.id}/invoice_detail_items.csv?encoding=${encoding}`}
              isExternal
            >
              請求明細
            </Button>
            <Button
              size="small"
              href={`/organization/invoices/${invoice.id}/invoice_trip_materials.csv?encoding=${encoding}`}
              isExternal
              style={{ marginLeft: '10px' }}
            >
              集計用データ
            </Button>
            <Button
              size="small"
              href={
                invoice.showQualifiedInvoice
                  ? `/organization/invoices/${invoice.id}/invoice_journals_qualified.csv?encoding=${encoding}`
                  : `/organization/invoices/${invoice.id}/invoice_journals.csv?encoding=${encoding}`
              }
              isExternal
              style={{ marginLeft: '10px' }}
            >
              会計用データ
            </Button>
            {invoice.showTransferJournalCsv && (
              <Button
                size="small"
                href={`/organization/invoices/${invoice.id}/invoice_transfer_journals.csv?encoding=${encoding}`}
                isExternal
                style={{ marginLeft: '10px' }}
              >
                振替用データ
              </Button>
            )}
          </Td>
          {invoiceCustomFormats.length > 0 && (
            <Td>
              {invoice.showQualifiedInvoice ? (
                invoice.invoiceCustomCsvs.length > 0 ? (
                  <div style={{ display: 'flex', gap: '5px' }}>
                    {invoice.invoiceCustomCsvs.map(csv => invoiceCustomCsvButton(csv))}
                    <Button
                      size="small"
                      onClick={() => {
                        createCustomCsv();
                      }}
                      disabled={invoice.invoiceCustomCsvs.some(csv => csv.onGoing)}
                    >
                      CSVを再作成
                    </Button>
                  </div>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      createCustomCsv();
                    }}
                    disabled={invoice.invoiceCustomCsvs.some(csv => csv.onGoing)}
                  >
                    CSVを作成
                  </Button>
                )
              ) : (
                '未対応'
              )}
            </Td>
          )}
          <Td>
            {invoice.showQualifiedInvoice ? (
              <Button
                size="small"
                href={`/organization/invoices/${invoice.id}/invoice_pdf_qualified.pdf`}
                isExternal
              >
                適格請求書
              </Button>
            ) : (
              <Button size="small" href={`/organization/invoices/${invoice.id}.pdf`} isExternal>
                請求書
              </Button>
            )}
          </Td>
          <Td>
            <Button size="small" className="button" onClick={() => setShowExternalInvoice(invoice)}>
              外部連携
            </Button>
          </Td>
        </Tr>
        {invoice.invoice_splitteds.map((splitted, i) => (
          <InvoiceSplittedTableRow
            key={splitted.id}
            invoice={invoice}
            invoiceSplitItem={splitted}
            encoding={encoding}
            last={i === invoice.invoice_splitteds.length - 1}
          />
        ))}
      </>
    );
  }
);

const Tr = styled.tr<{ noBorder?: boolean }>`
  ${props =>
    props.noBorder
      ? `
    & > td {
      border-bottom: none;
    }
  `
      : ''}
`;

const Td = styled.td`
  padding: 4px;
`;
