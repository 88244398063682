import type { ReactElement } from 'react';
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { styled } from '@this/constants/themes';
import ItemList from '@this/domain/approve_item/item_list';
import type ItemListJson from '@this/domain/approve_item/item_list_json';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

interface ApproveItemListResponse {
  approve_item_lists: ItemListJson[];
}

interface Props {
  self: ItemList | null;
  approveItemId: number;
  chargingDepartmentIds?: number[];
  onSelect: (itemList: ItemList | null) => void;
  tripedit?: boolean;
  workflowFormWidth: string;
}

interface State {
  filteredItemLists: ItemList[];
  isLoading: boolean;
}

interface TruncatableTextProps {
  text: string;
  truncateMaxWidth: string;
  onClick: () => void;
}

class SelectApproveItemListList extends React.Component<Props, State> {
  input: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      filteredItemLists: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchApproveItemList('');
    const el = document.getElementById('select-approveitemlist-input');
    if (el) el.focus();
  }

  componentWillUnmount() {
    if (this.fetchApproveItemListsSubscription) {
      this.fetchApproveItemListsSubscription.unsubscribe();
    }
  }

  handleSearchUsers = (_e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyword = this.input.current!.value;
    this.fetchApproveItemListsSubject.next(keyword);
  };

  fetchApproveItemList = async (keyword: string) => {
    const { approveItemId, chargingDepartmentIds } = this.props;
    this.setState({ isLoading: true });
    const params = { id: approveItemId, keyword, 'department_ids[]': chargingDepartmentIds, tripedit: false };
    if (this.props.tripedit === true) {
      params.tripedit = true;
    }
    try {
      const itemLists = await utils.jsonPromise<ApproveItemListResponse>(
        '/organization/approve_items/item_lists/search.json',
        params
      );
      this.setState({
        isLoading: false,
        filteredItemLists: itemLists.approve_item_lists.map(o => new ItemList(o))
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
      utils.sendErrorObject(e);
    }
  };

  private fetchApproveItemListsSubject = new Subject<string>();

  private fetchApproveItemListsSubscription = this.fetchApproveItemListsSubject
    .pipe(debounceTime(300))
    .subscribe(this.fetchApproveItemList);

  handleSelect(itemList: ItemList | null) {
    this.props.onSelect(itemList);
  }

  useTruncated = (
    text?: string,
    truncateMaxWidth?: number
  ): [(instance: HTMLLIElement | null) => void, boolean | undefined] => {
    const [truncated, setTruncated] = useState(false);
    const ref = useCallback(
      node => {
        if (!node) return;
        if (truncateMaxWidth) {
          setTruncated(node.scrollWidth > truncateMaxWidth);
        } else {
          setTruncated(node.scrollWidth > node.offsetWidth);
        }
      },
      [text, truncateMaxWidth]
    );
    return [ref, truncated];
  };

  TrancatableText = ({ text, truncateMaxWidth, onClick }: TruncatableTextProps): ReactElement | null => {
    const maxWidth = truncateMaxWidth ? Number(truncateMaxWidth.replace(/[^0-9]/g, '')) : 420;
    const [ref, isTruncated] = this.useTruncated(text, maxWidth);
    const title = isTruncated && { title: text };

    return (
      <ListItem data-wovn-ignore ref={ref} onClick={onClick} {...title}>
        {text}
      </ListItem>
    );
  };

  render() {
    try {
      const { filteredItemLists, isLoading } = this.state;
      return (
        <SelectArea>
          <Input
            type="search"
            id="select-approveitemlist-input"
            ref={this.input}
            placeholder="検索"
            onKeyUp={this.handleSearchUsers}
          />
          <ul>
            {isLoading ? (
              <li>
                <SimpleLoading />
              </li>
            ) : (
              <ListUl workflowFormWidth={this.props.workflowFormWidth}>
                <ListItem onClick={() => this.handleSelect(null)}>選択を外す</ListItem>
                {filteredItemLists.map((u, i) => (
                  <this.TrancatableText
                    text={`${u.code}：${u.name}`}
                    truncateMaxWidth={this.props.workflowFormWidth}
                    onClick={() => this.handleSelect(u)}
                    key={i}
                  />
                ))}
              </ListUl>
            )}
          </ul>
        </SelectArea>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Input = styled.input`
  &&& {
    width: 100%;
    margin-bottom: 0;
    border-top: none;
  }
`;

const SelectArea = styled.div`
  margin-top: 5px;
`;

const ListUl = styled.ul<{ workflowFormWidth?: string }>`
  overflow-y: scroll;
  max-height: 150px;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const ListItem = styled.li`
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  width: 100%;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 2px solid ${props => props.theme.grayBorderColor};
  border-top: none;
  z-index: 100;
`;

export default SelectApproveItemListList;
