import type { SearchTrackingLogStatus } from './search_tracking_log';

interface StatusCount {
  status: SearchTrackingLogStatus;
  count: number;
}

interface RepositoryCount {
  repository: string;
  count: number;
}

export interface SearchTrackingSummaryArgs {
  status: Partial<Record<SearchTrackingLogStatus, number>>;
  repository: Record<string, number>;
}

class SearchTrackingSummary {
  status: StatusCount[];

  repository: RepositoryCount[];

  constructor(args: SearchTrackingSummaryArgs) {
    this.status = Object.entries(args.status).map(([status, count]) => ({
      status: status as SearchTrackingLogStatus,
      count: count || 0
    }));
    this.repository = Object.entries(args.repository).map(([repository, count]) => ({
      repository,
      count: count || 0
    }));
  }

  static default(): SearchTrackingSummary {
    return new SearchTrackingSummary({
      status: {},
      repository: {}
    });
  }
}

export default SearchTrackingSummary;
