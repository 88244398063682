import _ from 'lodash';
import type MarginType from '@this/domain/organization/margin_type2';
import FlightSlice from './flight_slice';

import type { FlightJson } from '../select_repository';
import type { Carrier } from './flight_list';

export type Args = FlightJson & {
  showFee?: boolean;
  marginType?: MarginType;
};

class Flight {
  private readonly raw: Args;

  // from FlightJson
  public readonly id: number;

  readonly price: number;

  // private readonly price_text: string;

  readonly description: string;

  // private readonly score: number;

  private readonly fee: number;

  readonly peoplenum: number;

  // private readonly search_query_id: number;

  // private readonly search_query_item_id: number;

  public readonly ticketing_enable: boolean;

  public readonly minirule?: string;

  // override
  public readonly itineraries: FlightSlice[];

  readonly outword: FlightSlice;

  readonly homeword: FlightSlice | null;

  // additional arg
  readonly showFee: boolean;

  readonly marginType: MarginType | undefined;

  readonly orderedIndex: number | undefined;

  isSP: boolean;

  hovered: boolean;

  cancelable: boolean | undefined;

  changeable: boolean | undefined;

  readonly seatReservationEnable: boolean;

  constructor(args: Args, index?: number) {
    this.id = args.id;
    this.price = args.price;
    // this.price_text = args.price_text;
    this.description = args.description;
    // this.score = args.score;
    this.fee = args.fee;
    this.peoplenum = args.peoplenum;
    // this.search_query_id = args.search_query_id;
    // this.search_query_item_id = args.search_query_item_id;
    this.ticketing_enable = args.ticketing_enable;
    this.minirule = args.minirule;

    this.raw = args;
    this.itineraries = _.map(args.itineraries, it => new FlightSlice(it));
    this.outword = args.outword && new FlightSlice(args.outword);
    this.homeword = args.homeword && new FlightSlice(args.homeword);
    this.fee = args.fee || 0;

    this.orderedIndex = index;
    this.price = args.price || 0;

    this.peoplenum = args.peoplenum || 1;
    this.showFee = args.showFee === undefined ? true : args.showFee;
    this.marginType = args.marginType;
    this.isSP = false;
    this.hovered = false;

    this.seatReservationEnable = args.seat_reservation_enable;
  }

  totalPrice() {
    if (this.showFee) {
      return this.price + this.marginAmount();
    }
    return this.price;
  }

  pricePerPerson() {
    return this.price / this.peoplenum;
  }

  marginAmount() {
    if (this.marginType) {
      return this.marginType.calcMarginAmount(this.price, this.peoplenum, 2);
    }
    return this.fee * this.peoplenum;
  }

  feeText() {
    if (this.marginType) {
      return this.marginType.describe(this.price, this.peoplenum, 2);
    }
    return `${utils.formatPrice(this.fee)} × ${this.peoplenum}名`;
  }

  priceDetailText() {
    let text = `└ チケット代金: ${utils.formatPrice(this.price)}(${this.peoplenum}名分)`;
    if (this.showFee && this.marginAmount() > 0) {
      text += `\n└ 発券手数料: ${this.feeText()}`;
    }
    return text;
  }

  priceDetailArray() {
    const arr = [];
    arr.push({ title: 'チケット代金:', price: `${utils.formatPrice(this.price)}(${this.peoplenum}名分)` });
    if (this.showFee && this.marginAmount() > 0) {
      arr.push({ title: '発券手数料:', price: this.feeText() });
    }
    return arr;
  }

  priceText() {
    return `航空券：${utils.formatPrice(this.totalPrice())}\n${this.priceDetailText()}`;
  }

  startDate() {
    const first = _.first(this.itineraries);
    return first && first.startDate();
  }

  endDate() {
    const last = _.last(this.itineraries);
    return last && last.endDate();
  }

  startDateTime() {
    const first = _.first(this.itineraries);
    return first && first.startDateTime();
  }

  handleTouched() {
    this.isSP = true;
  }

  setHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.hovered = value;
      app.render();
    }
  };

  sliceFromSubtype(subtype: 'outword' | 'homeword' | number) {
    if (subtype === 'outword') {
      return this.outword;
    }
    if (subtype === 'homeword') {
      return this.homeword;
    }
    return this.itineraries[subtype];
  }

  toRaw() {
    return this.raw;
  }

  checkCancelable(): boolean {
    if (this.minirule) {
      this.cancelable = this.minirule.indexOf('取消：不可') < 0;
      return this.cancelable;
    }
    return false;
  }

  checkChangeable(): boolean {
    if (this.minirule) {
      this.changeable = this.minirule.indexOf('変更：不可') < 0;
      return this.changeable;
    }
    return false;
  }

  checkItineraries(selectItineraries: FlightSlice[], subtype: number): boolean {
    const results = _.map(this.itineraries, (itinerary, i) => {
      if (subtype > i) {
        if (selectItineraries[i].id === itinerary.id) return true;

        return false;
      }
      if (subtype === i) {
        if (selectItineraries[i].id === itinerary.id) return true;

        return false;
      }

      return undefined;
    });

    if (_.find(results, result => result === false) === false) {
      return false;
    }

    return true;
  }

  getCarriers(): Carrier[] {
    const allCarriers = _.flatMap(this.itineraries.map(i => i.carriers));
    return _.uniqWith(allCarriers, _.isEqual);
  }

  uniqString(): string {
    const uniqStrings = this.itineraries.map(i => i.uniqString());
    return _.join(uniqStrings, '-');
  }
}

export default Flight;
