import type { CSSProperties } from 'react';
import React, { useState } from 'react';
import CsvDropzone from '@this/components/expenses/organization/csv_bulk_action/csv_dropzone';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { ButtonBase } from '@this/shared/atoms/button';
import { styled } from '@this/constants/themes';

interface Props {
  loading: boolean;
  historyPath: string;
  validateResult?: FileValidatorResult;
  uploadResult?: any;
  dropZoneStyle?: CSSProperties;
  select: (file?: File) => void;
  upload: (file: File, isSendInvitation: boolean) => void;
}
const CsvBulkUpsertMemberForm: React.FC<Props> = ({
  loading,
  historyPath,
  validateResult,
  uploadResult,
  dropZoneStyle,
  select,
  upload
}) => {
  const [isSendInvitation, setInvitation] = useState(false);
  const canSubmit = validateResult && validateResult.file && !uploadResult && !loading;
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!canSubmit) {
      return;
    }

    upload(validateResult!.file!, isSendInvitation);
  };

  return (
    <form>
      <CsvDropzone
        loading={loading}
        validateResult={validateResult}
        uploadResult={uploadResult}
        dropZoneStyle={dropZoneStyle}
        select={select}
        historyPath={historyPath}
      />
      <CheckboxContainer>
        <label>
          <input type="checkbox" checked={isSendInvitation} onChange={() => setInvitation(!isSendInvitation)} />
          アップロード後招待メールを送信
        </label>
      </CheckboxContainer>
      <Actions>
        <Button onClick={handleSubmit} disabled={!canSubmit}>
          アップロード
        </Button>
      </Actions>
    </form>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  *:not(:first-child) {
    margin-left: 10px;
  }
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase};
  display: inline-block;
  margin: 0 10px;
  padding: 0.75em 1em;
`;

export default CsvBulkUpsertMemberForm;
