import React from 'react';
import { observer } from 'mobx-react';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import TripStatus, { TripStatusColor } from '@this/src/domain/trip/trip_status';

interface Props {
  status: TripStatusKey;
}

const TripStatusIndicator = observer(({ status }: Props) => {
  return (
    <>
      <span
        style={{
          width: '10px',
          height: '10px',
          display: 'inline-block',
          borderRadius: '5px',
          marginRight: '2px',
          backgroundColor: TripStatusColor[status]
        }}
      />
      <span>{TripStatus[status]}</span>
    </>
  );
});

export default TripStatusIndicator;
