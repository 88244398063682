import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import type { OrderItemCategoryOptions } from '../order_item';
import Project from '../project/project';
import { NonOrderItemForReport, NonOrderItemSort } from './non_order_item';
import { AllowanceItemForReport, AllowanceItemSort } from './allowance_item';
import { OrderItemPriceDetailForReport } from './order_item_price_detail';
import type { InAdvanceApprovalArgs, NonOrderItemForReportArgs, TripForReportArgs } from './trip_args';

export class TripForReport {
  id: number;

  status: number;

  startTime: Moment;

  endTime: Moment;

  from: string;

  to: string;

  finalDestination: string;

  purpose: string;

  internalNumber: string | null;

  approvalChannelId: number | null;

  travelers: string[] = [];

  prices: number[] = [];

  advances: number[] = [];

  projects: Project[] = [];

  orderItemPriceDetails: OrderItemPriceDetailForReport[] = [];

  nonOrderItems: NonOrderItemForReport[] = [];

  allowanceItems: AllowanceItemForReport[] = [];

  constructor(args: TripForReportArgs, options?: OrderItemCategoryOptions) {
    this.id = args.id;
    this.status = args.status;
    this.startTime = moment(args.start_time);
    this.endTime = moment(args.end_time);
    this.from =
      args.current_order?.order_items[0]?.transports[0]?.from.name ||
      args.search_query?.search_query_items[0]?.origin ||
      this.firstNonOrderLocation(args.non_order_items)?.from.name ||
      '';
    this.to =
      args.current_order?.order_items[0]?.transports[0]?.to.name ||
      args.search_query?.search_query_items[0]?.destination ||
      this.firstNonOrderLocation(args.non_order_items)?.to.name ||
      '';
    this.finalDestination = args.final_destination || '';
    this.purpose = args.purpose || '';
    this.internalNumber = args.internal_number || null;
    this.approvalChannelId = this.firstApprovalChannelId(args.in_advance_approval);
    this.prices =
      args.current_order?.order_items.filter(item => item.price).map(item => item.price.price || 0) || [];
    this.advances = args.non_order_items?.filter(item => item.price).map(item => item.price.price || 0) || [];
    this.projects = args.projects.map(projectArgs => new Project(projectArgs));
    this.travelers = args.traveler_informations?.map(traveler => traveler.name) || [];

    const defaultProjects = args.projects
      .filter(project => !project.disabled)
      .map(project => ({ projectId: project.id, project }));

    this.orderItemPriceDetails =
      args.current_order?.order_items
        .filter(item => item.price?.order_item_price_details)
        .map(item =>
          item.price.order_item_price_details.map(
            detail =>
              new OrderItemPriceDetailForReport(
                {
                  ...detail,
                  projects: (detail.projects || []).length > 0 ? detail.projects : defaultProjects,
                  order_item_price: {
                    ...item.price,
                    order_item: {
                      ...item,
                      order: {
                        ...args.current_order,
                        trip: args
                      }
                    }
                  }
                },
                options
              )
          )
        )
        .flat() || [];

    this.nonOrderItems =
      args.non_order_items
        ?.map(
          item =>
            new NonOrderItemForReport(
              {
                ...item,
                project_id: item.project_id || defaultProjects[0]?.projectId,
                project: item.project || defaultProjects[0]?.project || null
              },
              {
                defaultTime: this.startTime || this.endTime,
                options
              }
            )
        )
        ?.sort(NonOrderItemSort) || [];

    this.allowanceItems =
      args.allowance_items
        ?.map(
          item =>
            new AllowanceItemForReport(
              {
                ...item
              },
              {
                defaultTime: this.startTime || this.endTime
              }
            )
        )
        ?.sort(AllowanceItemSort) || [];
  }

  firstNonOrderLocation(args?: NonOrderItemForReportArgs[]) {
    return args
      ?.filter(
        item =>
          item.transports &&
          item.transports.length > 0 &&
          ['domestic_air', 'foreign_air', 'shinkansen'].includes(item.transports?.[0]?.transport_type)
      )
      ?.sort((a, b) => (a.id < b.id ? -1 : 1))?.[0]?.transports?.[0];
  }

  firstApprovalChannelId(args?: InAdvanceApprovalArgs[]): number | null {
    if (!args) return null;

    const checkedApproval = [...args].sort((a, b) => (a.id < b.id ? 1 : -1))?.[0];
    return checkedApproval?.status === 1 ? checkedApproval.approval_channel_id : null;
  }

  getPeriod() {
    if (this.startTime && this.endTime) {
      return this.startTime.month === this.endTime.month
        ? `${this.startTime.format('Y年M月D日')}∼${this.endTime.format('D日')}`
        : `${this.startTime.format('Y年M月D日')}∼${this.endTime.format('M月D日')}`;
    }

    return `${(this.startTime || this.endTime)?.format('Y年M月D日') || '─'}`;
  }

  getProjects() {
    return this.orderItemPriceDetails
      .flatMap(priceDetail => priceDetail.projects.flatMap(project => project.project || []))
      .concat(this.nonOrderItems.flatMap(item => item.project || []))
      .reduce(
        (projects, project) =>
          projects.findIndex(p => p.id === project.id) !== -1 ? projects : projects.concat(project),
        [] as Project[]
      );
  }

  alreadyReported() {
    return (
      this.orderItemPriceDetails.some(priceDetail => priceDetail.tripReportId) ||
      this.nonOrderItems.some(nonOrderItem => nonOrderItem.tripReportId)
    );
  }

  // システム手配金額
  totalPrice() {
    return this.prices.reduce((prev, current) => prev + current, 0);
  }

  // 立替経費
  totalAdvance() {
    return this.advances.reduce((prev, current) => prev + current, 0);
  }

  // 合計金額
  totalAmount() {
    return this.totalPrice() + this.totalAdvance();
  }

  // 旅程から立替経費を削除
  removeNonOrderItem(nonOrderItem: NonOrderItemForReport) {
    nonOrderItem.id = -1;
    const index = this.nonOrderItems.findIndex(item => item.id === -1);
    if (index !== -1) this.nonOrderItems.splice(index, 1);
    app.render();
  }

  // 旅程から日当を削除
  removeAllowanceItem(allowanceItem: AllowanceItemForReport) {
    allowanceItem.id = -1;
    const index = this.allowanceItems.findIndex(item => item.id === -1);
    if (index !== -1) this.allowanceItems.splice(index, 1);
    app.render();
  }
}
