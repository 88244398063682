import React from 'react';
import { styled } from '@this/constants/themes';
import FlightSeatMapFacility from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map_facility';
import FlightSeatMapRowSide from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map_row_side';
import type SeatMapRow from '@this/domain/flight/seat_map_row';
import type SeatMapFacility from '@this/domain/flight/seat_map_facility';

interface Props {
  columnCount: number;
  seatMapRow: SeatMapRow | null;
  onClickFacility: (f: SeatMapFacility) => void;
}

const FlightSeatMapRow: React.FC<Props> = ({ columnCount, seatMapRow, onClickFacility }) => {
  const row = () => {
    if (seatMapRow) {
      return (
        <Container columnCount={columnCount + 2}>
          <LeftSide>
            <FlightSeatMapRowSide
              side="left"
              rowNumber={seatMapRow.rowNumber}
              isWindowExists={seatMapRow.isWindowExistsOnLeftSide}
              wing={seatMapRow.wingType}
            />
          </LeftSide>
          <SeatsWrapper columnCount={seatMapRow.columnCount()}>
            {seatMapRow.facilities.map((f, i) => (
              <FlightSeatMapFacility key={i} seatMapFacility={f} onClick={onClickFacility} />
            ))}
          </SeatsWrapper>
          <RightSide>
            <FlightSeatMapRowSide
              side="right"
              rowNumber={seatMapRow.rowNumber}
              isWindowExists={seatMapRow.isWindowExistsOnRightSide}
              wing={seatMapRow.wingType}
            />
          </RightSide>
        </Container>
      );
    }
    return (
      <Container columnCount={columnCount + 2}>
        <LeftSide>
          <FlightSeatMapRowSide side="left" />
        </LeftSide>
        {[...Array(columnCount)].map((_, i) => (
          <NoFacility key={i} />
        ))}
        <RightSide>
          <FlightSeatMapRowSide side="right" />
        </RightSide>
      </Container>
    );
  };

  return row();
};

const Container = styled.div<{ columnCount: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columnCount}, 1fr);
`;

const LeftSide = styled.div`
  grid-column: 1 / 2;
`;

const SeatsWrapper = styled.div<{ columnCount: number }>`
  grid-column: 2 / -2;
  display: grid;
  grid-template-columns: repeat(${props => props.columnCount}, 1fr);
`;

const RightSide = styled.div`
  grid-column: -1 / -2;
`;

const NoFacility = styled.div`
  aspect-ratio: 1;
  width: 100%;
  color: gray;
  font-weight: bold;
  display: grid;
  place-items: center;
`;

export default FlightSeatMapRow;
