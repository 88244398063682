import type {
  FreeePostDealHistoryCount,
  FreeePostDealHistoryResponseArgs,
  FreeePostDealHistoryUpdateStatusResponseArgs
} from './freee_post_deal_history';
import FreeePostDealHistory, { convertFreeePostDealHistoryResponseToArgs } from './freee_post_deal_history';

export type FreeePostDealHistoriesCount = Record<number, FreeePostDealHistoryCount>;

class FreeePostDealHistoryList {
  list: FreeePostDealHistory[] = [];

  submitting = false;

  lastAction: FreeePostDealHistory | null = null;

  fromResponse(response: FreeePostDealHistoryResponseArgs[]): FreeePostDealHistoryList {
    const list = response.map(history => {
      return new FreeePostDealHistory(convertFreeePostDealHistoryResponseToArgs(history));
    });
    this.list = list;
    this.setAction();
    return this;
  }

  setAction() {
    this.submitting =
      this.list.find(history => history.status === 'started' || history.status === 'unstarted') !== undefined;

    if (this.list.length === 0) {
      this.lastAction = null;
      return;
    }

    this.lastAction = this.list[this.list.length - 1];

    if (this.lastAction?.status !== 'unstarted' && this.lastAction?.status !== 'started') {
      this.submitting = false;
    }
  }

  updateCount(counts: FreeePostDealHistoriesCount): FreeePostDealHistoryList {
    const list = this.list.map(history => {
      const count = counts[history.historyId];
      return count ? history.updateCount(count) : history;
    });
    this.list = list;
    return this;
  }

  updateStatus(response: FreeePostDealHistoryUpdateStatusResponseArgs): FreeePostDealHistoryList {
    const list = this.list.map(history => {
      if (history.historyId === response.history_id && history.actionType === response.action_type) {
        return history.updateStatus(response);
      }
      return history;
    });
    this.list = list;
    this.setAction();
    return this;
  }
}

export default FreeePostDealHistoryList;
