import { colors, radiuses, typographies, zIndices, breakpoints } from './primitive-tokens';

export const semanticColors = {
  brand: {
    primary: colors.brand['500'],
    hover: colors.brand['300'],
    visited: colors.brand['700'],
    background: colors.brand['50']
  },
  success: {
    primary: colors.green['500'],
    hover: colors.green['300'],
    visited: colors.green['700'],
    background: colors.green['50']
  },
  warning: {
    primary: colors.yellow['500'],
    hover: colors.yellow['300'],
    visited: colors.yellow['700'],
    background: colors.yellow['50']
  },
  danger: {
    primary: colors.red['500'],
    hover: colors.red['300'],
    visited: colors.red['700'],
    background: colors.red['50']
  },
  info: {
    primary: colors.blue['500'],
    hover: colors.blue['300'],
    visited: colors.blue['700'],
    background: colors.blue['50']
  },
  text: {
    primary: colors.gray['600'],
    description: colors.gray['400'],
    placeholder: colors.gray['300'],
    disabled: colors.gray['200']
  },
  background: {
    primary: colors.gray['20'],
    secondary: colors.brand['50'],
    tertiary: colors.gray['900'],
    dark: colors.gray['700'],
    disabled: colors.gray['50'],
    selected: colors.brand['50'],
    hover: colors.gray['20'],
    overlayDark: colors.blackAlpha,
    overlayLight: colors.whiteAlpha
  },
  border: { field: colors.gray['100'], divider: colors.gray['50'] },
  common: {
    black: colors.black,
    white: colors.white
  }
};

export const semanticTokens = {
  colors: semanticColors,
  typographies: {
    fontFamily: typographies.fontFamily,
    fontSizes: {
      heading1: typographies.fontSizes['23'],
      heading2: typographies.fontSizes['20'],
      heading3: typographies.fontSizes['17'],
      body: typographies.fontSizes['13'],
      caption: typographies.fontSizes['11']
    },
    lineHeight: {
      heading: typographies.lineHeights['1.2'],
      body: typographies.lineHeights['1.5'],
      caption: typographies.lineHeights['1.5']
    }
  },
  spacing: {
    base: 4
  },
  radiuses: {
    r4: radiuses['4'],
    r8: radiuses['8'],
    r12: radiuses['12']
  },
  zIndices: {
    hide: zIndices.hide,
    auto: zIndices.auto,
    base: zIndices.base,
    dropdown: zIndices['1000'],
    sticky: zIndices['1100'],
    overlay: zIndices['1200'],
    modal: zIndices['1300'],
    popover: zIndices['1500'],
    toast: zIndices['1700'],
    tooltip: zIndices['1800']
  },
  breakpoints: {
    sm: breakpoints['768'],
    md: breakpoints['992']
  }
};

export type SemanticTokens = typeof semanticTokens;
