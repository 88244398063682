export interface ArrangementRequestSettingJson {
  id: number;
  organization_id: number;
  enable: boolean;
  fee: number | null;
}

/**
 * @deprecated
 * should use button in js/src/domain/arrangement_request_setting2
 */
export default class ArrangementRequestSetting {
  readonly id: number;

  readonly organizationId: number;

  enable: boolean;

  fee: number;

  constructor(arg: ArrangementRequestSettingJson) {
    this.id = arg.id;
    this.organizationId = arg.organization_id;
    this.enable = arg.enable;
    this.fee = arg.fee || 0;
  }

  setFee(value: number) {
    this.fee = value;
    app.render();
  }

  toggleEnable() {
    this.enable = !this.enable;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      enable: this.enable,
      fee: this.fee
    };
  }
}
