/* eslint-disable max-classes-per-file */
import axios from 'axios';
import _ from 'lodash';
import OrganizationAPI from './admin/organization_api';
import SuppliedItemAPI from './admin/supplied_item_api';
import SupplierAPI from './admin/supplier_api';
import PaymentMethodAPI from './admin/payment_method_api';
import SupplierOptionsAPI from './arrangement/supplier_options_api';

// CSRFトークンのセット
axios.interceptors.request.use(
  config => {
    if (config.method) {
      if (['post', 'put', 'patch', 'delete'].includes(config.method) && config.headers) {
        const metas = document.getElementsByTagName('meta');
        const csrfMeta = _.find(metas, meta => meta.name === 'csrf-token');
        if (csrfMeta) {
          config.headers['X-CSRF-Token'] = csrfMeta.content;
        }
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

class AdminWrapper {
  public readonly organizations: OrganizationAPI;

  public readonly suppliers: SupplierAPI;

  public readonly suppliedItems: SuppliedItemAPI;

  public readonly paymentMethods: PaymentMethodAPI;

  constructor(baseUrl: string) {
    this.organizations = new OrganizationAPI(baseUrl);
    this.suppliers = new SupplierAPI(baseUrl);
    this.suppliedItems = new SuppliedItemAPI(baseUrl);
    this.paymentMethods = new PaymentMethodAPI(baseUrl);
  }
}

class ArrangementWrapper {
  public readonly suppliers: SupplierAPI;

  public readonly suppliedItems: SuppliedItemAPI;

  public readonly supplierOptions: SupplierOptionsAPI;

  constructor(baseUrl: string) {
    this.suppliers = new SupplierAPI(baseUrl);
    this.suppliedItems = new SuppliedItemAPI(baseUrl);
    this.supplierOptions = new SupplierOptionsAPI(baseUrl);
  }
}

export const AdminAPI = new AdminWrapper('/admin');
export const ArrangementAPI = new ArrangementWrapper('/arrangement');
