import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';

import type User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';

import { reportError } from '@this/lib/bugsnag';
import SelectTravelersBlockList from './select_travelers_block_list/select_travelers_block_list';

interface Props {
  user: User;
  traveler: Traveler | undefined;
  index: number;
  onSelect: (i: number, traveler: Traveler, type: string) => void;
  excludeUserIds?: number[];
}

interface State {
  showList: boolean;
}

class SelectTravelersBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { showList: false };
  }

  handleSelect = (selected: User) => {
    const { user, index, onSelect } = this.props;
    const newTraveler = new Traveler(selected);
    const newType = selected.id === user.id ? 'self' : 'member';
    this.setState({ showList: false }, () => {
      onSelect(index, newTraveler, newType);
    });
  };

  handleToggleShowList = () => {
    this.setState({ showList: !this.state.showList });
  };

  render() {
    try {
      const { traveler, user, excludeUserIds } = this.props;
      const { showList } = this.state;
      return (
        <div data-wovn-ignore>
          <TravelerTextArea>
            <TravelerNameText onClick={this.handleToggleShowList}>
              {traveler ? traveler.name : ''}
            </TravelerNameText>
            <TravelerEmailText onClick={this.handleToggleShowList}>
              {traveler ? traveler.email : ''}
            </TravelerEmailText>
          </TravelerTextArea>
          {showList && (
            <SelectTravelersBlockList self={user} onSelect={this.handleSelect} excludeUserIds={excludeUserIds} />
          )}
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const TravelerTextArea = styled.div`
  min-width: 50px;
  flex-wrap: nowrap;
  display: flex;
`;

const TravelerNameText = styled.div`
  height: 30px;
  min-width: 0;
  background: ${props => props.theme.fieldBgColor};
  line-height: 30px;
  padding: 0 0;
  cursor: pointer;
  position: relative;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
`;

const TravelerEmailText = styled.div`
  height: 30px;
  min-width: 0;
  background: ${props => props.theme.fieldBgColor};
  line-height: 30px;
  padding: 0 0;
  cursor: pointer;
  position: relative;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 7px;
  vertical-align: 2px;
  font-size: 12px;
  color: #aaa;
  flex-shrink: 99;
`;

export default SelectTravelersBlock;
