import { observable } from 'mobx';

export type HotelLimitTypeJson = {
  id?: number;
  business_days: number;
  hour: number;
};

class HotelLimitType {
  readonly id?: number;

  @observable
  businessDays: number;

  @observable
  hour: number;

  static newForService(serviceId: number) {
    let initialArgs: HotelLimitTypeJson;
    if (utils.isTabikobo(serviceId)) {
      initialArgs = { business_days: 1, hour: 18 };
    } else {
      initialArgs = { business_days: 1, hour: 15 };
    }
    return new HotelLimitType(initialArgs);
  }

  constructor(args: HotelLimitTypeJson) {
    this.id = args.id;
    this.businessDays = args.business_days;
    this.hour = args.hour;
  }

  description() {
    return `${this.businessDays}営${this.hour}時`;
  }

  submitParams() {
    return {
      id: this.id,
      business_days: this.businessDays,
      hour: this.hour
    };
  }
}

export default HotelLimitType;
