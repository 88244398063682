import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export interface MasterStepSequence {
  id: number;
  name: string;
}

interface Props {
  selectedMasterStepSequenceIds: number[];
  onSelect: (ids: number[]) => void;
}

interface Response {
  master_step_sequences: MasterStepSequence[];
}

const ArrangementMasterStepSequenceSelector = observer(({ selectedMasterStepSequenceIds, onSelect }: Props) => {
  const [masterStepSequences, setMasterStepSequences] = useState<MasterStepSequence[]>([]);
  const [selectedMasterStepSequences, setSelectedMasterStepSequences] = useState<MasterStepSequence[]>([]);
  const [query, setQuery] = useState<string>('');

  const fetchMasterStepSequences = useCallback(() => {
    const params = {
      q: query,
      ids: selectedMasterStepSequences.map(mss => mss.id).join(',')
    };
    utils.jsonPromise<Response>('/arrangement/master_step_sequences/autocomplete.json', params).then(res => {
      if (
        res.master_step_sequences.map(mss => mss.id).join(',') !== masterStepSequences.map(mss => mss.id).join(',')
      ) {
        setMasterStepSequences(res.master_step_sequences);
      }
    });
  }, [query, selectedMasterStepSequences]);

  useEffect(() => {
    fetchMasterStepSequences();
  }, [fetchMasterStepSequences]);

  useEffect(() => {
    setSelectedMasterStepSequences(
      masterStepSequences.filter(masterStepSequence =>
        selectedMasterStepSequenceIds.includes(masterStepSequence.id)
      )
    );
  }, [masterStepSequences, selectedMasterStepSequenceIds]);

  return (
    <Autocomplete
      multiple
      options={masterStepSequences}
      getOptionLabel={mss => mss.name}
      filterOptions={(options, state) => {
        const q: string = state.inputValue;
        return _.filter(options, mss => mss.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
      }}
      onChange={(_, selected) => {
        onSelect(selected.map(mss => mss.id));
        setQuery('');
      }}
      onInputChange={(_e, value, reason) => {
        if (reason === 'input') {
          setQuery(value);
        }
      }}
      inputValue={query}
      value={selectedMasterStepSequences}
      size="small"
      renderInput={params => (
        <TextField {...params} variant="outlined" style={{}} InputLabelProps={{ shrink: true }} size="small" />
      )}
      style={{ minWidth: '240px' }}
    />
  );
});

export default ArrangementMasterStepSequenceSelector;
