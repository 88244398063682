import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { TripReportForm } from './trip_report_form/trip_report_form';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

export const TripReportEdit: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const { id } = useParams<{ id: string }>();
  const tripReports = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);

  const fetchTripReports = useCallback(() => {
    tripReports.getShow(id);
    tripReports.getTrips(id);
  }, [id]);

  useEffect(() => {
    fetchTripReports();
  }, [fetchTripReports]);

  return (
    <TripReportContext.Provider value={tripReports}>
      <TripReportForm />
    </TripReportContext.Provider>
  );
};
