import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { calculateAge } from '@this/domain/age_util';
import { FormField, FormTitle, InputGroup, TextInput } from './order_item_traveler.style';
import type { Traveler } from './type';

export type TravelerDomesticAirFormData = {
  airSeat: Traveler['airSeat'];
  flightBirthday: Traveler['flightBirthday'];
  flightFirstNameRoman: Traveler['flightFirstNameRoman'];
  flightGender: Traveler['flightGender'];
  flightLastNameRoman: Traveler['flightLastNameRoman'];
  flightTel: Traveler['flightTel'];
  mileageNumber: Traveler['mileageNumber'];
};

type Props = {
  formData: TravelerDomesticAirFormData;
  transportDate: string | null;
  hiddenPersonalForm?: boolean;
  onChange: (formData: TravelerDomesticAirFormData) => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
};

type State = TravelerDomesticAirFormData;

const OrderItemTravelerDomesticAirForm: React.FC<Props> = ({
  formData,
  transportDate,
  hiddenPersonalForm,
  onChange,
  classNameForModifiedField
}) => {
  const [state, setState] = useState<State>({ ...formData });
  const onChangeInput = (val: any, attr: keyof State) => {
    const newState = { ...state };
    newState[attr] = val;
    setState(newState);

    onChange(newState);
  };

  return (
    <Container>
      <FormTitle>航空券情報</FormTitle>
      {!hiddenPersonalForm && (
        <>
          <FormField>
            <label
              className={`
                ${classNameForModifiedField('flightLastNameRoman')}
                ${classNameForModifiedField('flightFirstNameRoman')}
              `}
            >
              氏名(ローマ字)
            </label>
            <InputGroup>
              <TextInput
                type="text"
                placeholder="姓(ローマ字)"
                value={state.flightLastNameRoman}
                onChange={e => onChangeInput(e.target.value, 'flightLastNameRoman')}
              />
              <TextInput
                type="text"
                placeholder="名(ローマ字)"
                value={state.flightFirstNameRoman}
                onChange={e => onChangeInput(e.target.value, 'flightFirstNameRoman')}
              />
            </InputGroup>
          </FormField>
          <FormField>
            <label className={classNameForModifiedField('flightBirthday')}>誕生日</label>
            <TextInput
              placeholder="例) 2020-04-01"
              value={state.flightBirthday ?? ''}
              onChange={e => onChangeInput(e.target.value, 'flightBirthday')}
            />
            {state.flightBirthday && transportDate && (
              <FlightAge>
                <label>搭乗時年齢</label>
                <span>{`${calculateAge(state.flightBirthday, transportDate).toString()}歳`}</span>
              </FlightAge>
            )}
          </FormField>
          <FormField>
            <label className={classNameForModifiedField('flightGender')}>性別</label>
            <InputGroup>
              <label>
                <input
                  type="radio"
                  value="male"
                  name="foreign-air-gender"
                  checked={state.flightGender === 'male'}
                  onChange={e => onChangeInput(e.target.value, 'flightGender')}
                />
                男性
              </label>
              <label>
                <input
                  type="radio"
                  value="female"
                  name="foreign-air-gender"
                  checked={state.flightGender === 'female'}
                  onChange={e => onChangeInput(e.target.value, 'flightGender')}
                />
                女性
              </label>
            </InputGroup>
          </FormField>
        </>
      )}

      <FormField>
        <label className={classNameForModifiedField('flightTel')}>緊急連絡先番号</label>
        <TextInput
          placeholder="例) 09012345678"
          value={state.flightTel ?? ''}
          onChange={e => onChangeInput(e.target.value, 'flightTel')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('airSeat')}>座席の希望</label>
        <TextInput
          placeholder="例) 前方通路側"
          value={state.airSeat ?? ''}
          onChange={e => onChangeInput(e.target.value, 'airSeat')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('mileageNumber')}>マイル番号</label>
        <TextInput
          placeholder="例) 0123456789"
          value={state.mileageNumber ?? ''}
          onChange={e => onChangeInput(e.target.value, 'mileageNumber')}
        />
      </FormField>
    </Container>
  );
};

const Container = styled.div``;

const FlightAge = styled.div`
  margin-bottom: 0.75em;
`;

export default OrderItemTravelerDomesticAirForm;
