import { styled } from '@this/constants/themes';

const ContentBody = styled.div`
  display: flex;
  flex-grow: 9999;
  flex-direction: column;
  width: 100%;
  min-height: 0%;
  margin-left: auto;
  margin-right: auto;
  background: white;
`;

export const ContentBodyIn = styled.div`
  display: flex;
  flex-grow: 9999;
  flex-direction: column;
  padding: 20px;
`;

export default ContentBody;
