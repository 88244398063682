import React, { useState } from 'react';
import _ from 'lodash';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type UserJson from '@this/domain/user/user_json';
import useApi from '@this/shared/hooks/use_api';
import User from '@this/domain/user/user';

interface Props {
  onSelect: (member: User) => void;
}

interface MembersListResponse {
  members: UserJson[];
}

const MembersList: React.FC<Props> = ({ onSelect }: Props) => {
  const [keyword, setKeyword] = useState('');
  const params = { keyword, travelable: true };
  const { data, isLoading } = useApi<MembersListResponse>('/members.json', params);

  const members: User[] = data
    ? data.members.map(raw => {
        return new User(raw);
      })
    : [];

  return (
    <div className="members-list">
      <input
        type="search"
        defaultValue=""
        placeholder="社員名またはemailで検索"
        onChange={e => setKeyword(e.target.value)}
      />
      <ul>
        {isLoading ? (
          <li className="members-list__item">
            <SimpleLoading />
          </li>
        ) : (
          <>
            {members.map((member, i) => (
              <li key={i} className="members-list__item" onClick={() => onSelect(member)}>
                <div className="members-list__item__info">
                  {member.departmentName() && (
                    <p className="members-list__item__name">{member.departmentName()}</p>
                  )}
                  <p className="members-list__item__name">{member.name}</p>
                  <p className="members-list__item__email">({member.email})</p>
                  {member.employee_number !== null && (
                    <p className="members-list__item-part">[{member.employee_number}]</p>
                  )}
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default MembersList;
