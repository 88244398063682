import React, { useState } from 'react';
import { observer } from 'mobx-react';
import SelectTab from './share/SelectTab';
import JalBooking from './jal_booking/jal_booking';

const Tab = {
  JAL_BOOKING: 'JAL自動予約'
} as const;
export type TabType = keyof typeof Tab;

const AutoReservationInventory = observer(() => {
  const [currentTab, setCurrentTab] = useState<TabType>('JAL_BOOKING');

  return (
    <>
      <SelectTab tabs={Tab} currentTab={currentTab} onChange={setCurrentTab} />
      {currentTab === 'JAL_BOOKING' && <JalBooking />}
    </>
  );
});

export default AutoReservationInventory;
