import React from 'react';
import TextArea from 'react-textarea-autosize';
import type RoomElement from '../../../../../../domain/room_element';
import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: RoomElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const RoomElementForm = ({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('name')}`}>
        施設名
        <span className="red">（必須）</span>
      </label>
      <input type="text" value={element.name} onChange={e => element.handleChangeName(e.target.value)} />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('address')}`}>
        住所
      </label>
      <input type="text" value={element.address} onChange={e => element.handleChangeAddress(e.target.value)} />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('startedAT')}`}>
        利用開始日時
      </label>
      <DatetimePicker
        value={element.startedAt}
        onChange={time => element.handleStartedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('endedAt')}`}>
        利用終了日時
      </label>
      <DatetimePicker
        value={element.endedAt}
        onChange={time => element.handleEndedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remark')}`}>
        備考
      </label>
      <TextArea
        type="text"
        value={element.remark}
        onChange={e => element.handleChangeRemark(e.target.value)}
        minRows={2}
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`virtual-counte-price-change-form__label ${classNameForModifiedField('reservationNumber')}`}
      >
        予約番号
      </label>
      <input
        type="text"
        value={element.reservationNumber}
        onChange={e => element.handleReservationNumberChange(e.target.value)}
      />
    </div>
  </>
);
export default RoomElementForm;
