import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import type { SendListArgs } from '@this/domain/send_list/send_list';
import { SendList } from '@this/domain/send_list/send_list';
import type { ShinkansenSeatArgs } from '@this/domain/send_list/shinkansen_seat';
import { ShinkansenSeat } from '@this/domain/send_list/shinkansen_seat';
import type { SendListItem } from '@this/domain/send_list/send_list_item';
import { Loading } from '@this/shared/ui/feedbacks/loading';

type Props = RouteComponentProps;

interface SendListResponse {
  list: SendListArgs;
  messages: Message[];
  honorific_options: { [key: string]: string };
  shinkansen_seats: ShinkansenSeatArgs[];
}

interface MessageContent {
  body: string;
  type: string;
}

interface Message {
  trip_id: number;
  messages: MessageContent[];
}
interface State {
  sendList?: SendList;
  loading: boolean;
  submitting: boolean;
  messages?: Message[];
  honorificOptions?: { [key: string]: string };
  shinkansenSeats?: ShinkansenSeat[];
}
class New extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false
    };
  }

  componentDidMount() {
    this.fetchNewSendList();
  }

  async fetchNewSendList(): Promise<void> {
    this.setState({ loading: true });
    return utils.jsonPromise<SendListResponse>('/arrangement/send_list2/new').then(result =>
      this.setState({
        loading: false,
        sendList: new SendList(result.list),
        messages: result.messages,
        honorificOptions: result.honorific_options,
        shinkansenSeats: result.shinkansen_seats.map(s => new ShinkansenSeat(s))
      })
    );
  }

  private messageForItem(item: SendListItem): MessageContent {
    const message = this.state.messages?.find(m => m.trip_id === item.tripId);
    return utils.dig(message, 'messages', 0);
  }

  private handleAllCheck() {
    this.state.sendList?.allCheck();
    app.render();
  }

  private handleAllUncheck() {
    this.state.sendList?.allUncheck();
    app.render();
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({ submitting: true });
    const params = this.state.sendList?.submitParams();
    utils
      .jsonPromise('/arrangement/send_list2', params, 'POST')
      .then(() => {
        this.setState({ submitting: false });
        location.href = '/arrangement/send_list2';
      })
      .catch(e => {
        this.setState({ submitting: false });
        alert(e.responseJSON.message);
      });
  }

  private handleChecked(item: SendListItem) {
    item.toggleChecked();
    app.render();
  }

  private handleChangeName(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.preventDefault();
    item.name = e.target.value;
    app.render();
  }

  private handleChangeHonorific(item: SendListItem, e: React.ChangeEvent<HTMLSelectElement>) {
    item.honorific = e.target.value;
    app.render();
  }

  private handleChangePic(item: SendListItem, e: React.ChangeEvent<HTMLInputElement>) {
    item.pic = e.target.value;
    app.render();
  }

  private handleChangePostcode(item: SendListItem, e: React.ChangeEvent<HTMLInputElement>) {
    item.postcode = e.target.value;
    app.render();
  }

  private handleChangeAddress(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.address = e.target.value;
    app.render();
  }

  private handleChangeSubAddress(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.subAddress = e.target.value;
    app.render();
  }

  private handleChangeAltShinkansenSeat(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.altShinkansenSeat = e.target.value;
    app.render();
  }

  private getshinkansenSeats(item: SendListItem): string {
    const shinkansen = this.state.shinkansenSeats?.find(m => m.tripId === item.tripId);
    if (shinkansen !== undefined) {
      return shinkansen.shinkansenSeat;
    }
    return '';
  }

  render() {
    try {
      const { loading, sendList, honorificOptions, submitting } = this.state;
      return (
        <div className="send-list-new">
          {loading ? (
            <Loading />
          ) : (
            <div>
              <h2>送付リスト作成</h2>
              <div className="send-list-new__check-buttons">
                <button type="button" onClick={() => this.handleAllCheck()}>
                  すべてチェックする
                </button>
                <button type="button" onClick={() => this.handleAllUncheck()}>
                  すべてチェックを外す
                </button>
              </div>
              <form onSubmit={e => this.handleSubmit(e)}>
                <table className="send-list-new__table">
                  <tbody>
                    <tr>
                      <th className="send-list-new-check" />
                      <th className="send-list-new-id">旅程番号</th>
                      <th className="send-list-new-name">クライアント名</th>
                      <th className="send-list-new-honorific">ラベル</th>
                      <th className="send-list-new-pic">担当者名</th>
                      <th className="send-list-new-postcode">郵便番号</th>
                      <th className="send-list-new-address">住所</th>
                      <th className="send-list-new-sub-address">住所(フロア名)</th>
                      <th className="send-list-new-shinkansen-seat">座席希望</th>
                      <th className="send-list-message">メッセージ</th>
                    </tr>
                    {sendList &&
                      sendList?.items.map((item: SendListItem, i: number) => (
                        <tr key={i}>
                          <td className="send-list-new-check">
                            <input
                              type="checkbox"
                              checked={item.checked}
                              onChange={() => this.handleChecked(item)}
                            />
                          </td>
                          <td className="send-list-new-id">
                            <p>{item.tripId}</p>
                          </td>
                          <td className="send-list-new-name">
                            <textarea value={item.name} onChange={e => this.handleChangeName(item, e)} cols={40} />
                          </td>
                          <td className="send-list-new-honorific">
                            <select value={item.honorific} onChange={e => this.handleChangeHonorific(item, e)}>
                              {honorificOptions &&
                                Object.keys(honorificOptions).map((v: string) => (
                                  <option key={v} value={v}>
                                    {honorificOptions[v]}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td className="send-list-new-pic">
                            <div className="flex">
                              <input type="text" value={item.pic} onChange={e => this.handleChangePic(item, e)} />
                              <span>様</span>
                            </div>
                          </td>
                          <td className="send-list-new-postcode">
                            <input
                              type="text"
                              value={item.postcode}
                              onChange={e => this.handleChangePostcode(item, e)}
                            />
                          </td>
                          <td className="send-list-new-address">
                            <textarea
                              value={item.address}
                              onChange={e => this.handleChangeAddress(item, e)}
                              cols={40}
                            />
                          </td>
                          <td className="send-list-new-sub-address">
                            <textarea
                              value={item.subAddress}
                              onChange={e => this.handleChangeSubAddress(item, e)}
                              cols={40}
                            />
                          </td>
                          <td className="send-list-new-shinkansen-seat">
                            <textarea
                              value={item.altShinkansenSeat ? item.altShinkansenSeat : ''}
                              onChange={e => this.handleChangeAltShinkansenSeat(item, e)}
                              cols={40}
                            />
                            <div className="send-list-new__shinkansen-seat">{this.getshinkansenSeats(item)}</div>
                          </td>
                          <td className="send-list-message">
                            {this.messageForItem(item) && (
                              <div className="send-list-new__message">
                                <div className="send-list-new__message-container">
                                  {this.messageForItem(item)
                                    ?.body.split('\n')
                                    .map((line: string, i: number) => (
                                      <p key={i}>{line}</p>
                                    ))}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <input className="send-list-new__submit" type="submit" value="登録" disabled={submitting} />
              </form>
            </div>
          )}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default New;
