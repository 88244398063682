import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { BoxShortdistance } from '../shared/box_shortdistance_parts';
import type { SelectTransportBoxProps, SelectTransportBoxState } from './transport_box';
import type SelectStore from '../../../../domain/select_store';
import SelectTransportBoxShortdistanceExpressTemplate from './transport_box_shortdistance_express.template';
import SelectTransportBoxShortdistancePlaneTemplate from './transport_box_shortdistance_plane.template';

type Props = SelectTransportBoxProps &
  SelectTransportBoxState & {
    handleDomesticAirPriceChange: (index: number) => void;
    handleChange: () => void;
    store: SelectStore;
    direction?: string;
  };

const SelectTransportBoxShortdistanceTemplate: React.SFC<Props> = ({
  key,
  transport,
  inList,
  selected,
  domesticAirPriceIndex,
  loading,
  handleDomesticAirPriceChange,
  handleChange,
  store,
  direction,
  isRequestForm
}) => (
  <BoxShortdistance selected={selected} key={key}>
    {transport ? (
      transport.segments.map((s, i) => {
        s.resetUseSelectTransportBox();
        return s.leg_summaries && s.leg_summaries.length > 0 ? (
          <SelectTransportBoxShortdistanceExpressTemplate
            key={i.toString()}
            transport={transport}
            inList={inList}
            selected={selected}
            domesticAirPriceIndex={domesticAirPriceIndex}
            loading={loading}
            handleDomesticAirPriceChange={handleDomesticAirPriceChange}
            handleChange={handleChange}
            store={store}
            direction={direction}
            isRequestForm={isRequestForm}
            segment={s}
            i={i}
          />
        ) : (
          <SelectTransportBoxShortdistancePlaneTemplate
            key={i.toString()}
            transport={transport}
            inList={inList}
            selected={selected}
            domesticAirPriceIndex={domesticAirPriceIndex}
            loading={loading}
            handleDomesticAirPriceChange={handleDomesticAirPriceChange}
            handleChange={handleChange}
            store={store}
            direction={direction}
            isRequestForm={isRequestForm}
            segment={s}
            segmentIdx={i}
          />
        );
      })
    ) : (
      <Loading />
    )}
  </BoxShortdistance>
);

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const LegTime = styled.div`
  min-width: 50px;
  color: #3675ff;
  font-size: 13px;
  height: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 768px) {
    min-width: 40px;
  }
`;

export const LegTimeSpace = styled.div`
  min-width: 50px;
  color: #3675ff;
  font-size: 13px;
  height: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  @media screen and (max-width: 768px) {
    min-width: 0;
  }
`;

export const BoxTitle = styled.div`
  background: #aaa;
  color: white;
  min-width: 25px;
  line-height: 16px;
  margin-right: 10px;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
`;

export const Summary = styled.div`
  padding: 0;
  margin: 0;
  display: block;
  padding: 2px 5px 2px 10px;
`;

export const Segment = styled.div<Pick<Props, 'inList'>>`
  margin: 0;
  display: flex;
  align-items: center;
  &:first-child {
    padding-top: ${props => (props.inList ? '5px' : '0')};
  }
  &:last-child {
    padding-bottom: ${props => (props.inList ? '5px' : '0')};
  }
`;

export default SelectTransportBoxShortdistanceTemplate;
