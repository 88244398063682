import Markdown from '@this/shared/markdown/markdown';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import moment from '@this/lib/moment';
import SelectApproveItemList from '@this/shared/select_approveitemlist/select_approveitemlist';
import ApproveItemDropzone from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/approve_items/approve_item_dropzone';
import React from 'react';
import { styled } from '@this/constants/themes';
import { useTripReportStore } from '@this/domain/trip_report/trip_report_store';
import Text from '@this/shared/text/text';
import type ApproveItem from '@this/src/domain/approve_item/approve_item';

const WORKFLOW_FORM_WIDTH = '420px';

export const ApproveItemForm: React.FC = () => {
  const store = useTripReportStore();

  return (
    <>
      {store.approveItems.list.map((d: ApproveItem, i: number) => (
        <div key={`approval_items_${d.id ?? i}`}>
          {d.dataType === 'label' ? (
            <ApproveItemLabelOnly style={{ wordWrap: 'break-word' }}>
              <Markdown markdownText={d.userDisplayName} />
            </ApproveItemLabelOnly>
          ) : (
            <Section
              label={d.userDisplayName}
              required={d.requiredType === 'required' || store.isApprovalRequired(d.requiredType)}
            >
              {d.dataType === 'freetext' && (
                <WorkflowInputArea data-wovn-ignore workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                  <InputTextArea
                    id={`${d.id}`}
                    value={store.getApproveItemValue(d.id)}
                    onChange={e => store.setApproveItemValue(d.id, e.target.value)}
                    placeholder={d.getReservePlaceholder(store.isApprovalRequired(d.requiredType))}
                    rows={1}
                  />
                </WorkflowInputArea>
              )}
              {d.dataType === 'multilinefreetext' && (
                <WorkflowInputArea data-wovn-ignore workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                  <InputTextArea
                    id={`${d.id}`}
                    value={store.getApproveItemValue(d.id)}
                    onChange={e => store.setApproveItemValue(d.id, e.target.value)}
                    placeholder={d.getReservePlaceholder(store.isApprovalRequired(d.requiredType))}
                    rows={5}
                  />
                </WorkflowInputArea>
              )}
              {d.dataType === 'calendar' && (
                <ApproveItemDatePicker data-wovn-ignore>
                  <DatetimePicker
                    placeholder="選択してください"
                    onChange={store.handleApproveCalendarSelect(d.id)}
                    value={
                      store.getApproveItemValue(d.id) !== null && store.getApproveItemValue(d.id) !== undefined
                        ? moment(store.getApproveItemValue(d.id))
                        : undefined
                    }
                    disabledDays={0}
                    showTime={false}
                    showPast
                    deletable
                    border
                  />
                </ApproveItemDatePicker>
              )}
              {d.dataType === 'list' && (
                <SelectApproveItemList
                  onSelect={store.handleApproveListChange(d.id)}
                  approveItemId={d.id}
                  chargingDepartmentIds={store.getChargingDepartmentIDs()}
                  defaultItemListName={
                    store.getApproveItemValueCode(d.id) && store.getApproveItemValue(d.id)
                      ? `${store.getApproveItemValueCode(d.id)}：${store.getApproveItemValue(d.id)}`
                      : '選択してください'
                  }
                  workflowFormWidth={WORKFLOW_FORM_WIDTH}
                />
              )}
              {d.dataType === 'file' && (
                <ApproveItemDropzone
                  preview={store.getApproveItemFile(d.id) ? store.getApproveItemFile(d.id)?.name : undefined}
                  onSelect={f => store.handleApproveFileChange(d.id, f)}
                  onRemove={() => store.handleApproveFileRemove(d.id)}
                />
              )}
            </Section>
          )}
        </div>
      ))}
    </>
  );
};

const WorkflowInputArea = styled.div<{ workflowFormWidth?: string }>`
  line-height: 34px;
  display: flex;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const ApproveItemLabelOnly = styled.div`
  margin-bottom: 5px;
`;

const ApproveItemDatePicker = styled.div<{ workflowFormWidth?: string }>`
  margin-left: 0;
  display: block;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const InputTextArea = styled.textarea`
  margin-right: 0px;
`;

const Label = styled.label`
  width: 150px;
  line-height: 34px;

  .required {
    margin-left: 4px;
    font-weight: bold;
  }
`;

const SectionWrap = styled.div``;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const InputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

type SectionProps = {
  label: string;
  invalid?: string | false;
  required?: boolean;
};

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 600px;
  align-items: center;
`;

const Errors = styled.div`
  margin-bottom: 20px;
`;

const Section: React.FC<SectionProps> = ({ label, invalid, required, children }) => (
  <SectionWrap>
    <SectionIn>
      <InputArea>
        <Label>
          {label}
          {required && <span className="required">*</span>}
        </Label>
        <InputAreaRight>{children}</InputAreaRight>
        {invalid && (
          <Errors>
            <Error>
              <Text text={invalid} />
            </Error>
          </Errors>
        )}
      </InputArea>
    </SectionIn>
  </SectionWrap>
);
