import { Fetcher } from '@this/src/util';
import React, { lazy, Suspense } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';
import type { AdminRoleResponse } from '@this/domain/admin_role/admin_role';
import AdminRole from '@this/domain/admin_role/admin_role';
import { BulkTicketsPage } from '@this/components/admin/bulk_tickets/bulk_tickets.page';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import RepositoryDisplayAdjustment from '@this/components/admin/repository_display_adjustment/repository_display_adjustment';
import Manuals from '@this/components/admin/manuals/manuals';
import NotFound from '@this/components/admin/not_found/not_found';
import Knowledge_images from '@this/components/admin/KnowledgeImages/knowledge_images';
import OrganizationAnnouncements from '@this/components/admin/organization_announcements/organization_announcements';
import IndividualTargetSuppliedItems from '@this/components/admin/supplied_item_mappings/individual_target_supplied_items';
import IndividualRentalcarSuppliedItems from '@this/components/admin/supplied_item_mappings/individual_rentalcar_supplied_items';
import IndividualRailwaySuppliedItems from '@this/components/admin/supplied_item_mappings/individual_railway_supplied_items';
import IndividualDomesticAirSuppliedItems from '@this/components/admin/supplied_item_mappings/individual_domestic_air_supplied_items';
import { reportError } from '@this/lib/bugsnag';
import AdminRoles from './admin_roles';
import AdminHeader from './admin_header/admin_header';
import Footer from '../shared/footer/footer';

const Dashboard = lazy(() => import('./dashboard/dashboard'));
const Admins = lazy(() => import('./admins'));
const AdminUserEdit = lazy(() => import('./admin_user_edit/admin_user_edit'));
const Invoices = lazy(() => import('./invoices/invoices'));
const InvoicesEdit = lazy(() => import('./invoices/invoices_edit'));
const Accounting = lazy(() => import('./accounting/accounting'));
const Organizations = lazy(() => import('./organizations/organizations'));
const OrganizationDetail = lazy(() => import('./organization_detail/organization_detail'));
const ArrangeSteps = lazy(() => import('./arrange_steps/arrange_steps'));
const ArrangeCategories = lazy(() => import('./arrange_categories/arrange_categories'));
const MasterStepSequences = lazy(() => import('./master_step_sequences/master_step_sequences'));
const MasterSteps = lazy(() => import('./master_steps/master_steps'));
const MasterStepMappings = lazy(() => import('./master_step_mappings/master_step_mappings'));
const MasterStepMappingsV2 = lazy(() => import('./master_step_mappings/master_step_mappings_v2'));
const MasterStepSettings = lazy(() => import('./master_step_settings/master_step_settings'));
const MasterStepSequenceHistories = lazy(
  () => import('./master_step_sequence_histories/master_step_sequence_histories')
);
const MasterStepSequenceHistory = lazy(
  () => import('./master_step_sequence_histories/master_step_sequence_history')
);
const Holidays = lazy(() => import('./holidays/holidays'));
const Suppliers = lazy(() => import('./suppliers/suppliers'));
const SupplierDetail = lazy(() => import('./supplier_detail/supplier_detail'));
const SuppliedItemMappings = lazy(() => import('./supplied_item_mappings/supplied_item_mappings'));
const SupplierHistories = lazy(() => import('./supplier_histories/supplier_histories'));
const SupplierHistory = lazy(() => import('./supplier_histories/supplier_history'));
const SuppliedItems = lazy(() => import('./supplied_items/supplied_items'));
const AuditLogs = lazy(() => import('./audit_logs/audit_logs'));
const SupplyCategoryDetail = lazy(() => import('./supply_category_detail/supply_category_detail'));
const PaymentMethods = lazy(() => import('./payment_methods/payment_methods'));
const PaymentMethodHistories = lazy(() => import('./payment_method_histories/payment_method_histories'));
const PaymentMethodHistory = lazy(() => import('./payment_method_histories/payment_method_history'));
const SavingAccounts = lazy(() => import('./saving_accounts/saving_accounts'));
const OrganizationDashboards = lazy(() => import('./organization_dashboards/organization_dashboards'));
const OrganizationDashboardDetail = lazy(() => import('./organization_dashboards/organization_dashboard_detail'));
const Revenues = lazy(() => import('./revenues/revenues'));
const Informations = lazy(() => import('./informations/informations'));
const ShareholderTicketFees = lazy(() => import('./shareholder_ticket_fees/shareholder_ticket_fees'));
const Arrangers = lazy(() => import('./arrangers/arrangers'));

// TS化が完了したら順次requireをimportに切り替えていく
const ArrangementReports = require('./arrangement_reports/arrangement_reports');

type Props = RouteComponentProps;

interface State {
  roleLoading: boolean;
  readablePaths: string[];
  writablePaths: string[];
}

class Admin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      roleLoading: true,
      readablePaths: [],
      writablePaths: []
    };
  }

  componentDidMount() {
    this.fetchResources();
  }

  fetchResources() {
    Fetcher.get<AdminRoleResponse>('/admin/role.json').then(data => {
      const role = new AdminRole(data.role);
      const readablePaths = role.resources.map(resource => {
        if (!resource.readPermission) {
          return '';
        }
        return resource.resourcePath;
      });
      const writablePaths = role.resources.map(resource => {
        if (!resource.writePermission) {
          return '';
        }
        return resource.resourcePath;
      });
      this.setState({
        readablePaths,
        writablePaths,
        roleLoading: false
      });
    });
  }

  isReadable() {
    if (location.pathname === '/admin/edit') {
      return true;
    }

    const path =
      location.pathname.indexOf('admin/organizations') > 0
        ? '/admin/organizations'
        : location.pathname.match(/admin\/invoices\/\d+\/edit/)
        ? '/admin/invoices'
        : location.pathname.indexOf('admin/accounting') > 0
        ? '/admin/accounting/trial_balance'
        : location.pathname.match(/arrange_categories\/\d+\/arrange_steps/)
        ? '/admin/arrange_categories'
        : location.pathname.match(/arrange_categories\/\d+\/arrange_steps/) ||
          location.pathname.match(/admin\/suppliers\/\d+/) ||
          location.pathname.match(/admin\/supply_categories\/\d+/) ||
          location.pathname.indexOf('admin/master_step_sequences') > 0 ||
          location.pathname.indexOf('admin/master_step_mappings') > 0 ||
          location.pathname.indexOf('admin/master_step_settings') > 0 ||
          location.pathname.indexOf('admin/master_step_sequence_histories') > 0 ||
          location.pathname.indexOf('admin/supplier_histories') > 0 ||
          location.pathname.indexOf('admin/supplied_items') > 0
        ? '/admin/suppliers'
        : location.pathname.indexOf('admin/payment_methods') > 0 ||
          location.pathname.indexOf('admin/payment_method_histories') > 0
        ? '/admin/payment_methods'
        : location.pathname.indexOf('admin/supplied_item_mappings') > 0 ||
          location.pathname.indexOf('admin/individual_target_supplied_items') > 0 ||
          location.pathname.indexOf('admin/individual_railway_supplied_items') > 0 ||
          location.pathname.indexOf('admin/individual_domestic_air_supplied_items') > 0 ||
          location.pathname.indexOf('admin/individual_rentalcar_supplied_items') > 0
        ? '/admin/supplied_item_mappings'
        : location.pathname.indexOf('admin/organization_dashboards') > 0
        ? '/admin/organization_dashboards'
        : location.pathname;

    return this.state.readablePaths.indexOf(path) >= 0;
  }

  isWritable() {
    return this.state.writablePaths.indexOf(location.pathname) >= 0;
  }

  render() {
    const {
      match: { path, url }
    } = this.props;

    try {
      return (
        <TheAdmin>
          <AdminHeader />
          <Wrapper>
            <NaviUl>
              <li>
                <NaviItem to={url}>ダッシュボード</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/organizations`}>顧客マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/admins`}>管理者マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/admin_roles`}>管理者役割設定</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/arrangers`}>手配者マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/master_step_sequences`}>手配ステップ設定</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/invoices`}>請求書</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/accounting/trial_balance`}>会計</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/revenues`}>売上票</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/arrangement_reports`}>手配関連集計</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/bulk_tickets`}>回数券</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/manuals`}>マニュアル</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/knowledge_images`}>ファイルアップロード</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/informations`}>お知らせ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/organization_announcements`}>管理者向けのお知らせ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/shareholder_ticket_fees`}>株主優待券</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/repository_display_adjustment`}>在庫表示調整</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/holidays`}>休日設定</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/suppliers`}>仕入先マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/payment_methods`}>決済方法マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/saving_accounts`}>入金口座マスタ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/supplied_item_mappings`}>仕入商品マッピング</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/audit_logs`}>特権アカウント作業ログ</NaviItem>
              </li>
              <li>
                <NaviItem to={`${url}/organization_dashboards`}>顧客分析</NaviItem>
              </li>
            </NaviUl>
            <Content>
              {this.state.roleLoading ? (
                <Loading />
              ) : this.isReadable() ? (
                <Suspense fallback={<></>}>
                  <Switch>
                    <Route
                      exact
                      path={path}
                      render={props => <Dashboard {...{ writable: this.isWritable() }} {...props} />}
                    />
                    <Route exact path={`${path}/organizations`} component={Organizations} />
                    <Route path={`${path}/organizations/:id`} component={OrganizationDetail} />
                    <Route exact path={`${path}/invoices`} component={Invoices} />
                    <Route path={`${path}/invoices/:id/edit`} component={InvoicesEdit} />
                    <Route path={`${path}/accounting`} component={Accounting} />
                    <Route exact path={`${path}/edit`} component={AdminUserEdit} />
                    <Route exact path={`${path}/admins`} component={Admins} />
                    <Route exact path={`${path}/admin_roles`} component={AdminRoles} />
                    <Route exact path={`${path}/arrangers`} component={Arrangers} />
                    <Route exact path={`${path}/arrange_categories`} component={ArrangeCategories} />
                    <Route exact path={`${path}/master_step_sequences`} component={MasterStepSequences} />
                    <Route path={`${path}/master_step_sequences/:id`} component={MasterSteps} />
                    <Route exact path={`${path}/master_step_mappings`} component={MasterStepMappingsV2} />
                    <Route exact path={`${path}/master_step_mappings/v1`} component={MasterStepMappings} />
                    <Route exact path={`${path}/master_step_settings`} component={MasterStepSettings} />
                    <Route
                      exact
                      path={`${path}/master_step_sequence_histories`}
                      component={MasterStepSequenceHistories}
                    />
                    <Route
                      path={`${path}/master_step_sequence_histories/:id`}
                      component={MasterStepSequenceHistory}
                    />
                    <Route path={`${path}/arrange_categories/:id/arrange_steps`} component={ArrangeSteps} />
                    <Route path={`${path}/revenues`} component={Revenues} />
                    <Route exact path={`${path}/bulk_tickets`} component={BulkTicketsPage} />
                    <Route exact path={`${path}/manuals`} component={Manuals} />
                    <Route exact path={`${path}/knowledge_images`} component={Knowledge_images} />
                    <Route exact path={`${path}/informations`} component={Informations} />
                    <Route exact path={`${path}/arrangement_reports`} component={ArrangementReports} />
                    <Route exact path={`${path}/shareholder_ticket_fees`} component={ShareholderTicketFees} />
                    <Route exact path={`${path}/holidays`} component={Holidays} />
                    <Route exact path={`${path}/suppliers`} component={Suppliers} />
                    <Route path={`${path}/suppliers/:id`} component={SupplierDetail} />
                    <Route path={`${path}/supply_categories/:id`} component={SupplyCategoryDetail} />
                    <Route exact path={`${path}/supplied_item_mappings`} component={SuppliedItemMappings} />
                    <Route
                      exact
                      path={`${path}/individual_target_supplied_items`}
                      component={IndividualTargetSuppliedItems}
                    />
                    <Route
                      exact
                      path={`${path}/individual_domestic_air_supplied_items`}
                      component={IndividualDomesticAirSuppliedItems}
                    />
                    <Route
                      exact
                      path={`${path}/individual_rentalcar_supplied_items`}
                      component={IndividualRentalcarSuppliedItems}
                    />
                    <Route
                      exact
                      path={`${path}/individual_railway_supplied_items`}
                      component={IndividualRailwaySuppliedItems}
                    />
                    <Route exact path={`${path}/supplier_histories`} component={SupplierHistories} />
                    <Route path={`${path}/supplier_histories/:id`} component={SupplierHistory} />
                    <Route path={`${path}/supplied_items`} component={SuppliedItems} />
                    <Route
                      path={`${path}/repository_display_adjustment`}
                      component={RepositoryDisplayAdjustment}
                    />
                    <Route path={`${path}/audit_logs`} component={AuditLogs} />
                    <Route path={`${path}/payment_methods`} component={PaymentMethods} />
                    <Route path={`${path}/saving_accounts`} component={SavingAccounts} />
                    <Route exact path={`${path}/payment_method_histories`} component={PaymentMethodHistories} />
                    <Route path={`${path}/payment_method_histories/:id`} component={PaymentMethodHistory} />
                    <Route exact path={`${path}/organization_dashboards`} component={OrganizationDashboards} />
                    <Route path={`${path}/organization_dashboards/:id`} component={OrganizationDashboardDetail} />
                    <Route
                      exact
                      path={`${path}/organization_announcements`}
                      component={OrganizationAnnouncements}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              ) : (
                <AccessDenied>
                  このページに対するアクセス権限がありません。管理者の方にご確認ください。
                </AccessDenied>
              )}
            </Content>
          </Wrapper>
          <Footer signedIn={false} />
        </TheAdmin>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  flex-grow: 9999;
  max-width: 100%;
  min-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: ${props => props.theme.grayBgColor};
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const TheAdmin = styled.div`
  font-size: 13px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
`;

const NaviUl = styled.ul`
  flex-grow: 0;
  display: flex;
  width: 100%;
  background: white;
  padding: 0;
  margin-bottom: 20px;
`;

const NaviItem = styled(Link)`
  padding: 8px 10px;
  display: block;

  &&& {
    color: ${props => props.theme.textColor};
  }
  &:hover {
    background: ${props => props.theme.grayBorderColor};
  }
`;

const Content = styled.div`
  flex-grow: 9999;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`;

const AccessDenied = styled.div`
  padding: 8px 10px;
`;

export default Admin;
