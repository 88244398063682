import { Fetcher } from '@this/src/util';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import type { AdminBulkTicktesResponse } from '@this/components/admin/bulk_tickets/types';
import { Grid } from '@material-ui/core';
import { BulkTicketOrganizations } from '@this/components/admin/bulk_tickets/bulk_ticket_organizations';
import { styled } from '@this/constants/themes';
import type { RouteSeed } from '@this/components/admin/bulk_tickets/bult_ticket_routes';
import { BulkTicketRoutes } from '@this/components/admin/bulk_tickets/bult_ticket_routes';
import type { TermSeed } from '@this/components/admin/bulk_tickets/bulk_ticket_terms';
import { BulkTicketTerms } from '@this/components/admin/bulk_tickets/bulk_ticket_terms';

import Notification from '../../../notification';

const request = <T extends any>(
  url: string,
  {
    params,
    method = 'GET'
  }: {
    params?: { [k in string]: any };
    method?: 'POST' | 'PUT' | 'GET';
  }
): Promise<T> => {
  return (async () => {
    try {
      const res = await Fetcher.request<T>({ url, data: params, method });
      return res;
    } catch (e) {
      Notification.error(e.response.data.errors);
      throw e;
    }
  })();
};

type Props = RouteComponentProps;

export const BulkTicketsPage = (_: Props) => {
  const [routes, setRoutes] = React.useState<AdminBulkTicktesResponse['routes']>([]);
  const [organizations, setOrganizations] = React.useState<AdminBulkTicktesResponse['organizations']>([]);
  const [terms, setTerms] = React.useState<AdminBulkTicktesResponse['terms']>([]);
  const [loading, setLoading] = React.useState(true);

  const fetch = React.useCallback(
    (cb?: () => void) =>
      request<AdminBulkTicktesResponse>('/admin/bulk_tickets', {}).then(res => {
        setRoutes(res.routes);
        setOrganizations(res.organizations);
        setTerms(res.terms);
        if (cb) {
          cb();
        }
      }),
    []
  );

  const handleCreateTerm = React.useCallback(async (params: TermSeed) => {
    await request('/admin/bulk_ticket_unavailable_terms', { params, method: 'POST' });
    Notification.success('利用不可期間を作成しました');
    fetch();
  }, []);

  const handleUpdateTerm = React.useCallback(async (id: number, params: TermSeed) => {
    await request(`/admin/bulk_ticket_unavailable_terms/${id}`, { params, method: 'PUT' });
    Notification.success('利用不可期間を更新しました');
    fetch();
  }, []);

  const handleCreateRoute = React.useCallback(async (params: RouteSeed) => {
    await request('/admin/bulk_ticket_routes', { params, method: 'POST' });
    Notification.success('区間を作成しました');
    fetch();
  }, []);

  const handleUpdateRoute = React.useCallback(async (id: number, params: RouteSeed) => {
    await request(`/admin/bulk_ticket_routes/${id}`, { params, method: 'PUT' });
    Notification.success('区間を更新しました');
    fetch();
  }, []);

  React.useEffect(() => {
    fetch(() => setLoading(false));
  }, []);

  return (
    <Container container spacing={3}>
      {loading ? (
        'loading...'
      ) : (
        <>
          <Grid item xs={6}>
            <BulkTicketOrganizations organizations={organizations} />
          </Grid>
          <Grid item xs={3}>
            <BulkTicketRoutes routes={routes} onCreate={handleCreateRoute} onUpdate={handleUpdateRoute} />
          </Grid>
          <Grid item xs={3}>
            <BulkTicketTerms terms={terms} onCreate={handleCreateTerm} onUpdate={handleUpdateTerm} />
          </Grid>
        </>
      )}
    </Container>
  );
};

const Container = styled(Grid)`
  padding: 16px;
`;
