import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import type { IViewModel } from 'mobx-utils';
import { createViewModel } from 'mobx-utils';
import type { RouteComponentProps } from 'react-router-dom';
import type { GodArgs, PermissionType } from '@this/domain/god/god';
import { styled } from '@this/constants/themes';
import { Button as UiButton } from '@this/components/shared/ui/inputs/button';
import { Loading } from '@this/shared/ui/feedbacks/loading/loading';
import GodList from '@this/domain/god/god_list';
import { ConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import God, { initializeGod } from '@this/domain/god/god';
import { Fetcher, HTTPError } from '@this/src/util';
import { Title } from '../god';

const StickyTable = require('react-sticky-table').StickyTable;
const Row = require('react-sticky-table').Row;
const Cell = require('react-sticky-table').Cell;

type Props = RouteComponentProps;

interface GodsResponse {
  gods: GodArgs[];
  success: boolean;
  message?: string;
}

const Gods: React.FC<Props> = () => {
  const [gods, setGods] = useState<GodList | null>(null);
  const [editingGod, setEditingGod] = useState<(God & IViewModel<God>) | null>(null);
  const [deletingGod, setDeletingGod] = useState<(God & IViewModel<God>) | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const fetchGods = useCallback(async () => {
    setLoading(true);
    setError(null);

    await Fetcher.get<GodsResponse>('/god/gods.json')
      .then(response => {
        setGods(new GodList(response.gods));
      })
      .catch(() => setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。'))
      .finally(() => setLoading(false));
  }, []);

  const handleEditClick = useCallback((god: God) => {
    setEditingGod(createViewModel(god));
  }, []);

  const handleEditCancel = useCallback(
    (god: God) => {
      if (gods && !god.id) {
        gods.remove(god);
      }
      setEditingGod(null);
    },
    [gods]
  );

  const handleDeleteClick = useCallback(async (god: God | null) => {
    if (god) {
      setDeletingGod(createViewModel(god));
    } else {
      setDeletingGod(null);
    }
  }, []);

  const handleAddClick = useCallback(() => {
    const newGod = new God(initializeGod);
    if (gods != null) gods.push(newGod);
    setEditingGod(createViewModel(newGod));
  }, [gods]);

  const updateGods = useCallback(async () => {
    if (editingGod) {
      setLoading(true);
      setError(null);
      setSuccess(null);

      await Fetcher.put<GodsResponse>(`/god/gods/${editingGod.id}.json`, editingGod.submitParams())
        .then(response => {
          setEditingGod(null);
          setGods(new GodList(response.gods));
          setSuccess(response.message || null);
        })
        .catch(e => {
          if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.message) {
            setError(e.response.data.message.join('\n'));
          } else {
            setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
          }
        })
        .finally(() => setLoading(false));
    }
  }, [editingGod]);

  const createGods = useCallback(async () => {
    if (editingGod == null) return;
    setLoading(true);
    setError(null);
    setSuccess(null);

    await Fetcher.post<GodsResponse>(`/god`, { god: editingGod.submitParams() })
      .then(response => {
        setEditingGod(null);
        setGods(new GodList(response.gods));
        setSuccess(response.message || null);
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
          setError(e.response.data.errors.join('\n'));
        } else {
          setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        }
      })
      .finally(() => setLoading(false));
  }, [editingGod]);

  const handleSubmit = useCallback(() => {
    if (editingGod == null) return;

    if (editingGod.id) {
      updateGods();
    } else {
      createGods();
    }
  }, [editingGod, updateGods, createGods]);

  const onConfirm = useCallback(
    async (disabled: boolean) => {
      if (!deletingGod) return;
      const url = disabled ? `/god/gods/${deletingGod.id}` : `/god/gods/${deletingGod.id}/enable`;
      const method = disabled ? 'DELETE' : 'POST';
      await Fetcher.request({ url, method });
      setDeletingGod(null);
      fetchGods();
    },
    [deletingGod, fetchGods]
  );

  useEffect(() => {
    fetchGods();
  }, []);

  return (
    <>
      <div>
        <Title>アカウント</Title>
        <Content>
          {loading ? (
            <Loading />
          ) : (
            <>
              {success && <Success>{success}</Success>}
              {error && <Error>{error}</Error>}
              <GodTable>
                <Row>
                  <GodTh>名前・姓</GodTh>
                  <GodTh>名前・名</GodTh>
                  <GodTh>Eメール</GodTh>
                  <GodTh>ユーザ権限</GodTh>
                  <GodTh>編集</GodTh>
                  <GodTh>無効化/有効化</GodTh>
                </Row>
                {gods &&
                  gods.list.map((god, i) => (
                    <Row key={`${god.id}-${i}`} style={god.deletedAt ? { background: '#bfbfbf' } : {}}>
                      {editingGod && editingGod.id === god.id
                        ? [
                            <GodTd key="1">
                              <Input
                                type="text"
                                name="lastName"
                                value={editingGod.lastName}
                                onChange={e => {
                                  editingGod.lastName = e.target.value;
                                }}
                              />
                            </GodTd>,
                            <GodTd key="2">
                              <Input
                                type="text"
                                name="firstName"
                                value={editingGod.firstName}
                                onChange={e => {
                                  editingGod.firstName = e.target.value;
                                }}
                              />
                            </GodTd>,
                            <GodTd key="3">
                              <Input
                                type="text"
                                name="email"
                                value={editingGod.email}
                                onChange={e => {
                                  editingGod.email = e.target.value;
                                }}
                              />
                            </GodTd>,
                            <GodTd key="4">
                              {/* PermissionTypeのセレクトボックス */}
                              <select
                                name="permissionType"
                                value={editingGod.permission}
                                onChange={e => {
                                  editingGod.permission = e.target.value as PermissionType;
                                }}
                              >
                                <option value="administrator">administrator</option>
                                <option value="analyst">analyst</option>
                              </select>
                            </GodTd>,
                            <GodTd key="5">
                              <UiButton size="small" onClick={handleSubmit}>
                                送信
                              </UiButton>
                              <UiButton
                                color="sub"
                                size="small"
                                type="button"
                                onClick={() => handleEditCancel(god)}
                              >
                                閉じる
                              </UiButton>
                            </GodTd>
                          ]
                        : [
                            <GodTd key="1">{god.lastName}</GodTd>,
                            <GodTd key="2">{god.firstName}</GodTd>,
                            <GodTd key="3">{god.email}</GodTd>,
                            <GodTd key="4">{god.permission}</GodTd>,
                            <GodTd key="5">
                              <UiButton color="sub" size="small" onClick={() => handleEditClick(god)}>
                                編集
                              </UiButton>
                            </GodTd>
                          ]}
                      <GodTd key="6">
                        {god.deletedAt === null ? (
                          <UiButton color="danger" size="small" onClick={() => handleDeleteClick(god)}>
                            無効
                          </UiButton>
                        ) : (
                          <UiButton size="small" onClick={() => handleDeleteClick(god)}>
                            有効
                          </UiButton>
                        )}
                      </GodTd>
                    </Row>
                  ))}
              </GodTable>
            </>
          )}
          {!editingGod && (
            <AddButton size="small" onClick={handleAddClick}>
              アカウント追加
            </AddButton>
          )}
        </Content>
      </div>
      <ConfirmModal
        open={!!deletingGod}
        description={
          deletingGod?.deletedAt === null
            ? [
                'このアカウントは無効となり、閲覧・編集ができなくなります。本当に無効にしますか？',
                '※ 他のデータと関連性ある場合、削除できません。'
              ]
            : ['このアカウントは有効となり、閲覧・編集ができます。本当に有効にしますか？']
        }
        onCancel={() => handleDeleteClick(null)}
        onSubmit={() => onConfirm(deletingGod?.deletedAt === null)}
        submitButtonLabel={deletingGod?.deletedAt === null ? '無効' : '有効'}
        color={deletingGod?.deletedAt === null ? 'danger' : 'primary'}
      />
    </>
  );
};

export default observer(Gods);

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const GodTable = styled(StickyTable)`
  margin: 0;
  table-layout: fixed;
`;

const GodTh = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
  ${props => props.width && `width: ${props.width}px;`}
`;

const GodTd = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;

  .MuiButton-root {
    margin: 0 4px;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Success = styled.div`
  color: ${props => props.theme.successColor};
`;

const AddButton = styled(UiButton)`
  margin-top: 20px;
  width: 200px;
`;

const Input = styled.input`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;
