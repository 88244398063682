import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type PaymentMethod from '@this/domain/payment_method/payment_method';
import type PaymentMethodList from '@this/domain/payment_method/payment_method_list';

interface Props {
  paymentMethods: PaymentMethodList;
  paymentMethodId: number | null;
  suppliedItem: SuppliedItem | null;
  onSelect: (i: number) => void;
}
interface State {
  showList: boolean;
  paymentMethod?: PaymentMethod | null;
}

const OrderItemPaymentMethodSelect: React.FC<Props> = ({
  paymentMethods,
  paymentMethodId,
  suppliedItem,
  onSelect
}) => {
  const paymentMethod = paymentMethods.find(paymentMethodId || undefined);
  const defaultPaymentMethod = paymentMethods.default(suppliedItem?.paymentMethodId || 0);
  const [state, setState] = useState<State>({
    showList: false,
    paymentMethod
  });

  const handleSelect = (selected: number) => {
    const newState = { ...state };
    newState.showList = false;
    setState(newState);
    onSelect(selected);
  };

  return (
    <>
      <Select
        value={paymentMethod?.id || ''}
        onChange={e => {
          handleSelect(parseInt(e.target.value, 10));
        }}
      >
        <option value="">{defaultPaymentMethod ? 'デフォルト値を設定' : '未設定'}</option>
        {paymentMethods.list.map(paymentMethod => (
          <option key={paymentMethod.id} value={paymentMethod.id?.toString() || ''}>
            {paymentMethod.codeWithName()}
          </option>
        ))}
      </Select>
      {defaultPaymentMethod && <Default>デフォルト：{defaultPaymentMethod}</Default>}
    </>
  );
};

const Select = styled.select`
  max-width: 120px;
  margin-bottom: 0;
`;

const Default = styled.div`
  font-size: 0.9em;
  margin-top: 4px;
  color: ${props => props.theme.grayTextColor};
`;

export default OrderItemPaymentMethodSelect;
