import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import A from '@this/components/shared/atoms/a';
import { MidButton } from '@this/components/shared/atoms/button';
import Tooltip from '@this/shared/tooltip/tooltip';
import { Date } from '../outline_item/outline_item.template';

import type SelectStore from '../../../../domain/select_store';
import type SearchQuery from '../../../../domain/search_query';
import type ReservingTrip from '../../../../domain/trip/reserving_trip';
import type User from '../../../../domain/user/user';

import SearchIconTemplate from '../search_icon/search_icon.template';
import Search from '../../../shared/search/search';

interface SelectSearchAreaProps {
  // Select.props
  query: SearchQuery;
  user: User | null;
  availableOptions: string[];
  selectErrors: string[];
  submitting: boolean;
  store: SelectStore;

  // Select.methods
  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleReSearch: (query: SearchQuery) => void;

  trip: ReservingTrip;
}
interface SelectSearchAreaState {
  showSearchBox: boolean;
}

class SelectSearchArea extends React.Component<SelectSearchAreaProps, SelectSearchAreaState> {
  constructor(props: SelectSearchAreaProps) {
    super(props);
    this.state = { showSearchBox: false };
  }

  toggleShowSearchBox = () => {
    this.setState({ showSearchBox: !this.state.showSearchBox });
  };

  render() {
    try {
      return (
        <SearchArea className="select-search-area">
          <Outline className="flex">
            <Left>
              <HeadArea>
                <Title>検索条件</Title>
                <SearchLink onClick={this.toggleShowSearchBox}>
                  <SearchIcon>
                    <SearchIconTemplate />
                  </SearchIcon>
                  <span>検索条件を変更する</span>
                </SearchLink>
              </HeadArea>
              {this.state.showSearchBox ? (
                <Search
                  query={this.props.query}
                  user={this.props.user}
                  availableOptions={this.props.availableOptions}
                  handleSearch={(query: SearchQuery) => {
                    this.props.handleReSearch(query);
                    this.toggleShowSearchBox();
                  }}
                />
              ) : (
                <table className="key-value-table">
                  <tbody>
                    {Object.entries(this.props.query.getSummaryData()).map((v, k) => (
                      <tr key={k}>
                        <td className="key-value-table__label">{v[0]}</td>
                        <td className="key-value-table__separator">：</td>
                        {typeof v[1] === 'string' ? (
                          <td data-wovn-ignore className="key-value-table__value">
                            {v[1]}
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Left>
            <Right>
              <ReserveButton
                className={`select-pc__reserve-button ${this.props.submitting ? 'loading' : ''}`}
                onClick={this.props.handleNextButtonClick}
              >
                予約確認画面へ
              </ReserveButton>
              {this.props.selectErrors.length > 0 && (
                <div className="select__errors-bottom">
                  {this.props.selectErrors.map((error, i) => (
                    <div className="error" key={i}>
                      {error}
                    </div>
                  ))}
                </div>
              )}
              <Date className="select-outline__item-block__header__date">
                合計：
                <Price>
                  <span data-wovn-ignore>
                    {this.props.store.result.type === 'separate'
                      ? utils.digits(this.props.trip.totalPrice())
                      : utils.digits(this.props.store.result.getPackageTrip().totalPrice())}
                  </span>
                  <Yen>円</Yen>
                </Price>
                <Tooltip type="info">
                  <div className="select__tooltip-format">
                    {this.props.trip
                      .tooltipText()
                      .split('\n')
                      .map((l: string, i: number) => (
                        <p key={i}>{l}</p>
                      ))}
                  </div>
                </Tooltip>
              </Date>
            </Right>
          </Outline>
        </SearchArea>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const SearchArea = styled.div`
  padding: 10px;
`;

const Outline = styled.div`
  ${media.sp`
    flex-direction: column;
  `}
`;

const Left = styled.div`
  flex-grow: 99999;
  margin-right: 20px;
  ${media.sp`
    margin-right: 0;
  `}
`;

const Right = styled.div`
  margin-left: auto;
  ${media.sp`
    margin-top: 5px;
    margin-left: 0;
  `}
`;

const Price = styled.span`
  color: ${props => props.theme.redColor};
`;

const HeadArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  margin-bottom: 0;
`;

const SearchLink = styled(A)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  height: fit-content;
  border: ${props => (props.theme.themeClass === 'tabikobo' ? '2px solid' : 'none')};
  border-radius: ${props => (props.theme.themeClass === 'tabikobo' ? '5px' : '0px')};
  padding: ${props => (props.theme.themeClass === 'tabikobo' ? '3px' : '0px')};
`;

const SearchIcon = styled.div`
  width: 12px;
  height: 12px;
`;

const ReserveButton = styled.div`
  ${MidButton}
  position: relative;
  width: 214px;
  margin: 0 auto 10px;
  ${media.sp`
    display: none;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50px;
    border: 5px solid transparent;
    border-left: 5px solid #fff;
  }
`;

const Yen = styled.span`
  font-size: 9px;
  margin-left: 3px;
`;

export default SelectSearchArea;
