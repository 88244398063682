import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';

import MuiPaper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { styled } from '@this/src/components/constants/themes';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import type SearchTrackingExternalLog from '@this/src/domain/search_tracking_external_log';
import type SearchTrackingLog from '@this/src/domain/search_tracking_log';
import type {
  SearchTrackingElementArgs,
  SearchTrackingElementCollection,
  SearchTrackingElementType
} from '@this/src/domain/search_tracking_element';
import SearchTrackingElement from '@this/src/domain/search_tracking_element';

import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import SearchTrackingLogDetailInformation from './search_tracking_log_detail_information';
import SearchTrackingLogElements from './search_tracking_log_elements';

interface Props {
  searchTrackingLog: SearchTrackingLog;
  searchTrackingExternalLogs: SearchTrackingExternalLog[];
}

interface State {
  tab: number;
  loading: boolean;
  elementReady: boolean;
  searchTrackingElements: SearchTrackingElementCollection;
}

interface Request {
  searchTrackingLogId: number;
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}

interface Response {
  outward?: SearchTrackingElementArgs[];
  homeward?: SearchTrackingElementArgs[];
  hotel?: SearchTrackingElementArgs[];
  flights?: SearchTrackingElementArgs[];
}

type Action =
  | { type: 'TAB_CHANGE'; payload: number }
  | { type: 'FETCH_START' }
  | { type: 'FETCH'; payload: Response }
  | { type: 'FETCH_ERROR'; payload: string[] };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TAB_CHANGE':
      return {
        ...state,
        tab: action.payload
      };
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        searchTrackingElements: []
      };
    case 'FETCH':
      return {
        ...state,
        loading: false,
        elementReady: true,
        searchTrackingElements: Object.entries(action.payload).map(([key, value]) => ({
          type: key as SearchTrackingElementType,
          list: value.map((raw: SearchTrackingElementArgs) => new SearchTrackingElement(raw))
        }))
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

const fetchSearchTrackingElements = ({ searchTrackingLogId, dispatch }: Request) => {
  dispatch({ type: 'FETCH_START' });
  Fetcher.get<Response>(`/arrangement/search_tracking_logs/${searchTrackingLogId}/elements`)
    .then(response => {
      dispatch({ type: 'FETCH', payload: response });
    })
    .catch(() => {
      dispatch({ type: 'FETCH_ERROR', payload: [] });
    });
};

const generateQueryString = (query: any, rakutenId: string) => {
  const params = [];
  params.push(`rakuten_id=${encodeURIComponent(rakutenId)}`);
  const allowedKeys = ['smoke', 'breakfast', 'roomnum', 'peoplenum', 'checkin', 'checkout'];
  allowedKeys.forEach(key => {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key];
      if (value !== '') {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });
  return params.join('&');
};

const initialState: State = {
  tab: 0,
  loading: false,
  elementReady: false,
  searchTrackingElements: []
};

interface HotelMaster {
  id: number;
  rakuten_id: string;
  name: string;
}
interface HotelMasterListResponse {
  data: HotelMaster[];
}

const SearchTrackingLogDetail: React.FC<Props> = ({ searchTrackingLog: log }) => {
  const initialTab = useMemo(() => (!log.query && log.result ? 1 : 0), [log]);
  const [state, dispatch] = useReducer(reducer, { ...initialState, tab: initialTab });
  const { tab, loading, elementReady, searchTrackingElements } = state;
  const [loadingHotelMasterList, setLoadingHotelMasterList] = useState(false);
  const [hotelMasterList, setHotelMasterList] = useState<HotelMaster[]>([]);

  const handleTabChange = useCallback(
    (_: unknown, newValue: number) => {
      dispatch({ type: 'TAB_CHANGE', payload: newValue });
    },
    [dispatch]
  );

  const handleFetch = useCallback(() => {
    if (!elementReady) fetchSearchTrackingElements({ searchTrackingLogId: log.id, dispatch });
  }, [log, elementReady, dispatch]);

  const handleFetchHotelMasterListByCoordinates = useCallback(() => {
    const parsedData = JSON.parse(log.query);
    setLoadingHotelMasterList(true);
    Fetcher.get<HotelMasterListResponse>(
      `/arrangement/rakuten_static_investigate/search_by_coordinates?latitude=${parsedData.latitude}&longitude=${parsedData.longitude}`
    )
      .then(response => {
        setHotelMasterList(response.data);
        setLoadingHotelMasterList(false);
      })
      .catch(() => {
        setLoadingHotelMasterList(false);
      });
    setLoadingHotelMasterList(false);
  }, [log, setLoadingHotelMasterList]);

  useEffect(() => {
    // 2: 検索結果のタブを開いたら検索結果を取得する
    if (tab === 2) handleFetch();
    if (log.repository?.includes('Rakuten::RakutenStaticFileHotelRepository') && tab === 3) {
      handleFetchHotelMasterListByCoordinates();
    }
  }, [tab, handleFetch]);

  return (
    <Wrapper>
      <Left>
        <Paper>
          <SearchTrackingLogDetailInformation searchTrackingLog={log} />
        </Paper>
      </Left>
      <Right>
        <Paper>
          <Tabs indicatorColor="primary" value={tab} onChange={handleTabChange}>
            <Tab label="入力" {...allyProps(0)} disabled={!log.query} />
            <Tab label="出力" {...allyProps(1)} disabled={!log.result} />
            <Tab label="検索結果" {...allyProps(2)} disabled={!log.esIds} />
            {log.action !== 'hotels#index' && (
              <>
                <Tab label="Amadeus Request Log" {...allyProps(3)} disabled={!log.amadeusRequestLog} />
                <Tab label="Amadeus Response Log" {...allyProps(3)} disabled={!log.amadeusResponseLog} />
              </>
            )}
            {log.action === 'hotels#index' && (
              <Tab
                label="楽天Staticホテル調査"
                {...allyProps(3)}
                disabled={!log.repository?.includes('Rakuten::RakutenStaticFileHotelRepository')}
              />
            )}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Actions>
              <Link href={`/arrangement/search_tracking_logs/${log.id}/json?query=1`} isExternal target="_blank">
                JSONファイル
              </Link>
            </Actions>
            {log.query}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Actions>
              <Link href={`/arrangement/search_tracking_logs/${log.id}/json?result=1`} isExternal target="_blank">
                JSONファイル
              </Link>
            </Actions>
            {log.result}
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {loading ? (
              <SimpleLoading />
            ) : (
              <SearchTrackingLogElements searchTrackingLogId={log.id} elements={searchTrackingElements} />
            )}
          </TabPanel>
          {log.action !== 'hotels#index' && (
            <>
              <TabPanel value={tab} index={3}>
                {log.amadeusLogId ? (
                  <Actions>
                    <Link
                      href={`/arrangement/amadeus_logs/${log.amadeusLogId}/download_request_log.xml`}
                      isExternal
                      target="_blank"
                    >
                      XMLファイル
                    </Link>
                  </Actions>
                ) : (
                  ''
                )}
                <div>{log.amadeusRequestLog ?? ''}</div>
              </TabPanel>
              <TabPanel value={tab} index={4}>
                {log.amadeusLogId ? (
                  <Actions>
                    <Link
                      href={`/arrangement/amadeus_logs/${log.amadeusLogId}/download_response_log.xml`}
                      isExternal
                      target="_blank"
                    >
                      XMLファイル
                    </Link>
                  </Actions>
                ) : (
                  ''
                )}
                <div>{log.amadeusResponseLog ?? ''}</div>
              </TabPanel>
            </>
          )}
          {log.action === 'hotels#index' && (
            <TabPanel value={tab} index={3}>
              {loadingHotelMasterList ? (
                <SimpleLoading />
              ) : (
                <>
                  {hotelMasterList &&
                    hotelMasterList.map((item, i) => {
                      return (
                        <div key={i}>
                          <div>
                            <Link
                              href={`/arrangement/rakuten_static_investigate?${generateQueryString(
                                JSON.parse(log.query),
                                item.rakuten_id
                              )}`}
                              isExternal
                              target="_blank"
                            >
                              {item.name}
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </TabPanel>
          )}
        </Paper>
      </Right>
    </Wrapper>
  );
};

const allyProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <Panel
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <PanelBody>{children}</PanelBody>}
  </Panel>
);

const scrollbar = `
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  height: calc(100vh - 190px);
`;

const Left = styled.div`
  flex: 1;
  min-width: 360px;
  height: 100%;
`;

const Paper = styled(MuiPaper)`
  width: 100%;
  height: 100%;
  padding: 16px;
  max-height: 100%;
  overflow: auto;
  ${scrollbar}
`;

const Right = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 376px);
  height: 100%;
`;

const Panel = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  overflow: auto;
  margin-top: 16px;
  ${scrollbar}
`;

const PanelBody = styled.div`
  white-space: pre-wrap;
  position: relative;
`;

const Actions = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  & > * {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export default SearchTrackingLogDetail;
