import type { ChangeEvent } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';
import { Wrapper, Header, SearchFormSection, Title, SelectWrapper, Select } from '../report_items/search_form';
import { ActionWrapper, BaseButton, CancelButton } from './reject';

interface Props {
  onClose: () => void;
  selectedReportIds: number[];
}

type encoding = 'utf8' | 'sjis';

interface State {
  selectedEncoding: '' | encoding;
}

export default class CsvDownload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedEncoding: ''
    };
  }

  private handleChangeEncoding = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedEncoding: e.target.value as '' | encoding });
  };

  private onDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const ids = this.props.selectedReportIds.join('&ids[]=');
    location.href = `/biztra/approvals/items.csv?ids[]=${ids}&encoding=${this.state.selectedEncoding}`;
    this.props.onClose();
  };

  render() {
    return (
      <Wrapper>
        <Header>CSVデータを出力</Header>
        <Body>
          <SearchFormSection>
            <Title>出力形式</Title>
            <SelectWrapper>
              <Select value={this.state.selectedEncoding} onChange={this.handleChangeEncoding}>
                <option value="" />
                <option value="utf8">UTF-8</option>
                <option value="sjis">Shift_JIS</option>
              </Select>
            </SelectWrapper>
          </SearchFormSection>
        </Body>
        <ActionWrapper>
          <CancelButton onClick={this.props.onClose}>キャンセル</CancelButton>
          <BaseButton disabled={!this.state.selectedEncoding} onClick={this.onDownload}>
            出力する
          </BaseButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const Body = styled.div`
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
