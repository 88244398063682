import React from 'react';
import _ from 'lodash';

interface Props {
  text: string | null | undefined;
  lessClass: string;
  readmoreTextClass: string;
}
const ReadMore = ({ text, lessClass, readmoreTextClass }: Props) => {
  const [isOriginOverflow, setIsOriginOverflow] = React.useState(false);
  const [isOverflow, setIsOverflow] = React.useState(false);
  const [textLessClass, setTextLessClass] = React.useState(lessClass);
  const paragraph = React.useRef<HTMLSpanElement>(null);

  const handleReadMore = () => {
    setTextLessClass('');
    setIsOverflow(!isOverflow);
  };

  const handleReadLess = () => {
    setTextLessClass(lessClass);
    setIsOverflow(!isOverflow);
  };

  React.useEffect(() => {
    const el = paragraph.current;
    if (el) {
      setIsOverflow(el.offsetHeight < el.scrollHeight);
      setIsOriginOverflow(el.offsetHeight < el.scrollHeight);
      if (el.offsetHeight === el.scrollHeight) {
        setTextLessClass('');
      }
    }
  }, []);

  return (
    <div>
      <span ref={paragraph} className={textLessClass}>
        {text?.split('\n').map(t => (
          <>
            {t}
            <br />
          </>
        ))}
      </span>
      {isOverflow ? (
        <a className={readmoreTextClass} onClick={handleReadMore}>
          全文を表示する
        </a>
      ) : isOriginOverflow ? (
        <a className={readmoreTextClass} onClick={handleReadLess}>
          閉じる
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReadMore;
