import _ from 'lodash';
import { observable, action } from 'mobx';
import type { ApproveItemArgs } from './approve_item';
import ApproveItem from './approve_item';

class ApproveItemList {
  @observable
  public list: ApproveItem[];

  constructor(raw: ApproveItemArgs[]) {
    this.list = raw.map(o => new ApproveItem(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  last() {
    return _.last(this.list);
  }

  replaceList(lists: ApproveItem[]) {
    this.list = lists;
  }

  @action
  push(o: ApproveItem) {
    this.list.push(o);
  }

  @action
  remove(o: ApproveItem) {
    const i = this.list.findIndex(item => item.id === o.id);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  @action
  update(newOrg: ApproveItem) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }
}

export default ApproveItemList;
