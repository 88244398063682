import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import StatusLabel from '@this/components/expenses/report_items/status_label';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import ApprovalSteps from '@this/components/expenses/approvals/approval_steps';
import type { ApprovalStage } from '@this/domain/expenses/approval_stage';
import { Checkbox } from '@this/components/expenses/ui/inputs/checkbox';
import type User from '@this/domain/user/user';
import type { Report } from '@this/domain/expenses/report';
import ReportItemCards, {
  ExpenseType,
  ItemCardDate,
  ItemCardList,
  Price,
  StatusAndId
} from '@this/components/expenses/report_items/report_item_cards';
import { ByMedia } from '@this/shared/ui/other/by_media';
import { PriceWrapper, ReportCardSpWrapper, SubTitle } from '@this/components/expenses/reports/report_card';

interface Props {
  reports: Report[];
  user: User;
  showCheckbox: boolean;
  inRejected: boolean;
}

interface State {
  showApprovalStepModal: boolean;
  selectedApprovalStages: ApprovalStage[];
}

class ApprovalsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { showApprovalStepModal: false, selectedApprovalStages: [] };
  }

  handleShowReportItemCards = (report: Report) => {
    report.toggleShowDetail();
  };

  private reportDetail(report: Report) {
    return (
      <tbody key={report.id}>
        <ReportTr onClick={() => this.handleShowReportItemCards(report)}>
          {this.props.showCheckbox && (
            <CheckAreaTd>
              {report.hasNextStepAction() && (
                <Checkbox
                  checked={report.selected}
                  onClick={e => e.stopPropagation()}
                  onChange={_e => report.toggleSelected()}
                />
              )}
            </CheckAreaTd>
          )}
          <StatusLabelTd>
            <StatusLabel report={report} />
          </StatusLabelTd>
          <IdTd onClick={() => window.open(`/biztra/approvals/${report.id}`, '_blank')}>No.{report.id}</IdTd>
          <TitleTd onClick={() => window.open(`/biztra/approvals/${report.id}`, '_blank')}>{report.title}</TitleTd>
          <ApplierTd colSpan={2}>{report.user?.name}</ApplierTd>
          <PriceTd>{utils.formatPriceWithMark(report.totalPrice())}</PriceTd>
        </ReportTr>
        {report.showDetail && (
          <ReportItemCards
            items={report.items}
            handleAddItem={() => {}}
            inApprovals
            inRejected={this.props.inRejected}
          />
        )}
      </tbody>
    );
  }

  private reportDetailSp(report: Report) {
    return (
      <ReportCardSpWrapper key={report.id} onClick={() => this.handleShowReportItemCards(report)}>
        <StatusAndId>
          {this.props.showCheckbox && report.hasNextStepAction() && (
            <Checkbox
              checked={report.selected}
              onClick={e => e.stopPropagation()}
              onChange={_e => report.toggleSelected()}
              className="inApprovalsSp"
            />
          )}
          <StatusLabel report={report} />
          <Id onClick={() => window.open(`/biztra/approvals/${report.id}`, '_blank')}>No.{report.id}</Id>
        </StatusAndId>
        <ExpenseType onClick={() => window.open(`/biztra/approvals/${report.id}`, '_blank')}>
          {report.title}
        </ExpenseType>
        <SubTitle>{report.user?.name}</SubTitle>
        <PriceWrapper>
          <Price>{utils.formatPriceWithMark(report.totalPrice())}</Price>
        </PriceWrapper>
        {report.showDetail && (
          <ReportItemCards
            items={report.items}
            handleAddItem={() => {}}
            inApprovals
            inRejected={this.props.inRejected}
          />
        )}
      </ReportCardSpWrapper>
    );
  }

  render() {
    const { reports, showCheckbox } = this.props;
    const { showApprovalStepModal, selectedApprovalStages } = this.state;
    const goi = _.groupBy(reports, report => report.appliedAt!.format('YYYY年MM月DD日'));
    const dates = Object.keys(goi).sort().reverse();

    const hasNextStepActionGroup = (date: string) => {
      return goi[date].some(r => r.hasNextStepAction());
    };

    return (
      <>
        {dates.map((date: string) => (
          <ByMedia key={date}>
            {matches =>
              matches.sm ? (
                <ItemCardList>
                  <ItemCardDate>{date}</ItemCardDate>
                  {goi[date].map(r => this.reportDetailSp(r))}
                </ItemCardList>
              ) : (
                <ReportCardListTable>
                  <colgroup>
                    {showCheckbox && hasNextStepActionGroup(date) && <ReportCardListColCheckArea />}
                    <ReportCardListColStatus />
                    <ReportCardListColNo />
                    <col />
                    <ReportCardListColApplier />
                    <ReportCardListColBrank />
                    <ReportCardListColPrice />
                  </colgroup>
                  <ReportCardListThead>
                    <tr>
                      <ItemCardDateTh colSpan={showCheckbox ? 7 : 6}>{date}</ItemCardDateTh>
                    </tr>
                  </ReportCardListThead>
                  {goi[date].map(r => this.reportDetail(r))}
                </ReportCardListTable>
              )
            }
          </ByMedia>
        ))}
        <Modal
          size="medium"
          onClose={() => {
            this.setState({ showApprovalStepModal: false });
          }}
          open={showApprovalStepModal}
        >
          <ApprovalSteps
            onClose={() => {
              this.setState({ showApprovalStepModal: false });
            }}
            approvalStages={selectedApprovalStages}
          />
        </Modal>
      </>
    );
  }
}

const cardListBaseColor = '#f1ede5';

const blackTxtColor = '#323232';

const baseColor = '#927230';

const grayColor = '#767676';

const ReportCardListTable = styled.table`
  margin: 0 !important;
  border: solid 1px ${cardListBaseColor};
`;

const ReportCardListColCheckArea = styled.col`
  width: 3%;
  min-width: 27px;
`;

const ReportCardListColStatus = styled.col`
  width: 8%;
  min-width: 96px;
`;

const ReportCardListColNo = styled.col`
  width: 10%;
  min-width: 100px;
`;

const ReportCardListColApplier = styled.col`
  width: 30%;
`;

const ReportCardListColBrank = styled.col`
  width: 2.65%;
`;

const ReportCardListColPrice = styled.col`
  width: 13%;
  min-width: 125px;
`;

const ReportCardListThead = styled.thead`
  background-color: ${cardListBaseColor};
`;

const ItemCardDateTh = styled.th`
  color: ${blackTxtColor};
  font-size: 16px;
  padding: 6px;
  letter-spacing: normal;
  font-weight: normal;
  border-bottom: none;
`;

const ReportTr = styled.tr`
  cursor: pointer;
  & td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px ${cardListBaseColor};
  }
`;

const CheckAreaTd = styled.td`
  padding-left: calc(16 / 1136 * 100%);
  align-items: center;
`;

const StatusLabelTd = styled.td`
  padding-left: calc(16 / 1136 * 100%);
`;

const IdTd = styled.td`
  color: ${grayColor};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(30 / 1136 * 100%);
  cursor: pointer;
`;

const TitleTd = styled.td`
  color: ${blackTxtColor};
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(30 / 1136 * 100%);
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ApplierTd = styled.td`
  color: ${grayColor};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(34.5 / 1136 * 100%);
`;

const PriceTd = styled.td`
  color: ${baseColor};
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  padding-left: calc(10 / 1136 * 100%);
  padding-right: calc(20 / 1136 * 100%);
`;

const Id = styled.p`
  color: ${grayColor};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

export default ApprovalsTable;
