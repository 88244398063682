import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import { ButtonBase } from '@this/components/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import DepartmentSelector from '../../shared/department_selector';

interface Props {
  onSearch: (params: SearchParams) => void;
  onCsvDownload: (params: SearchParams) => void;
  loading: boolean;
}

const DestinationCategories = [
  { label: 'all', name: 'すべて' },
  { label: 'domestic', name: '国内' },
  { label: 'foreign', name: '海外' }
];

type DestinationCategoryType = 'all' | 'domestic' | 'foreign';

type DateType = 'confirmed_at' | 'end_at';

type Encoding = 'sjis' | 'utf8';

export interface SearchParams {
  department_ids: string;
  destination_category: string;
  date_type: DateType;
  start_date: string;
  end_date: string;
  bill_amount_from: number | string;
  bill_amount_to: number | string;
  encoding: Encoding;
}

const CostAnalysisSearchArea: React.FC<Props> = ({ onSearch, onCsvDownload, loading }) => {
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<number[]>([]);
  const [destinationCategory, setDestinationCategory] = useState<DestinationCategoryType>('all');
  const [dateType, setDateType] = useState<DateType>('confirmed_at');
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('month'));
  const [billAmountFrom, setBillAmountFrom] = useState<number | string>('');
  const [billAmountTo, setBillAmountTo] = useState<number | string>('');
  const [encoding, setEncoding] = useState<Encoding>(navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8');

  const getParams = (): SearchParams => {
    return {
      department_ids: selectedDepartmentIds.join(','),
      destination_category: destinationCategory,
      date_type: dateType,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      bill_amount_from: billAmountFrom || '',
      bill_amount_to: billAmountTo || '',
      encoding
    };
  };

  const handleSearch = () => {
    const params = getParams();
    onSearch(params);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [selectedDepartmentIds]);

  const handleSearchClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleSearch();
  };

  const handleCsvDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const params = getParams();
    onCsvDownload(params);
  };

  return (
    <>
      <SearchArea>
        <Section>
          <Title>データ条件</Title>
          <div className="flex">
            <SearchBlock>
              <SearchLabel>出張種別</SearchLabel>
              <Select
                value={destinationCategory}
                onChange={e => setDestinationCategory(e.target.value as DestinationCategoryType)}
                style={{ marginBottom: 0, marginRight: '5px' }}
              >
                {DestinationCategories.map(cat => (
                  <option key={cat.label} value={cat.label}>
                    {cat.name}
                  </option>
                ))}
              </Select>
            </SearchBlock>
            <SearchBlock>
              <SearchLabel>部署</SearchLabel>
              <DepartmentSelector
                selectedDepartmentIds={selectedDepartmentIds}
                onSelect={ids => setSelectedDepartmentIds(ids)}
                filter
              />
            </SearchBlock>
          </div>
          <RadioButtonGroup>
            <label className="radio">
              <input
                type="radio"
                name="date_type"
                value="confirmed_at"
                checked={dateType === 'confirmed_at'}
                onChange={() => setDateType('confirmed_at')}
              />
              出張確定日
            </label>
            <label className="radio">
              <input
                type="radio"
                name="date_type"
                value="end_at"
                checked={dateType === 'end_at'}
                onChange={() => setDateType('end_at')}
              />
              出張完了日
            </label>
          </RadioButtonGroup>
        </Section>
        <div className="flex">
          <Section>
            <Title>対象期間</Title>
            <TermGroup>
              <DatetimePicker
                value={startDate}
                onChange={d => setStartDate(d)}
                showTime={false}
                disabledDays={0}
                showPast
              />
              <span>〜</span>
              <DatetimePicker
                value={endDate}
                onChange={d => setEndDate(d)}
                showTime={false}
                disabledDays={0}
                showPast
              />
            </TermGroup>
          </Section>
          <Section style={{ alignItems: 'center', marginBottom: '0.75em', marginLeft: '0.75em' }}>
            <Title>合計金額</Title>
            <TermGroup>
              <input
                type="number"
                value={billAmountFrom}
                onChange={e => setBillAmountFrom(parseInt(e.target.value, 10) || '')}
                style={{ height: '24px', width: '120px', marginBottom: 0 }}
              />
              円<span style={{ lineHeight: '24px' }}>〜</span>
              <input
                type="number"
                value={billAmountTo}
                onChange={e => setBillAmountTo(parseInt(e.target.value, 10) || '')}
                style={{ height: '24px', width: '120px', marginBottom: 0 }}
              />
              円
            </TermGroup>
          </Section>
        </div>
        <Section>
          <SearchButton value="検索" onClick={e => handleSearchClick(e)} disabled={loading} />
        </Section>
        <p className="cost-analysis__search__info">
          ※請求確定していないものについては、データが最新でない可能性がございます。
        </p>
      </SearchArea>
      <div className="flex">
        <select value={encoding} onChange={e => setEncoding(e.target.value as Encoding)}>
          <option value="utf8">Macなど（文字コード：UTF8）</option>
          <option value="sjis">Windows（文字コード：SJIS）</option>
        </select>
        <div className="cost-analysis__csv">
          <DownloadButton type="button" onClick={e => handleCsvDownload(e)}>
            CSVをダウンロード
          </DownloadButton>
        </div>
      </div>
    </>
  );
};

const SearchArea = styled.div`
  padding: 10px;
  background-color: #eee;
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 5px;
`;

export const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const Title = styled.div`
  font-weight: bold;
`;

const TermGroup = styled.div`
  display: flex;
`;

const RadioButtonGroup = styled.div`
  display: flex;
  align-items: center;

  label.radio:not(:first-child) {
    margin-left: 10px;
  }
`;

const SearchButton = styled.input.attrs({
  type: 'submit'
})`
  &&& {
    ${ButtonBase}
    height: 25px;
    width: 80px;
    margin-left: 5px;
    padding: 0 10px;
  }
`;

const DownloadButton = styled.button`
  ${ButtonBase}
  padding: 5px 10px;
`;

export default CostAnalysisSearchArea;
