import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  value: string | null | undefined;
  buttons?: React.ReactNode;
  flexWrap?: boolean;
}

const MainTitle = (props: Props) => {
  return (
    <div>
      <TitleWrap flexWrap={props.flexWrap}>
        <Title flexWrap={props.flexWrap}>{props.value}</Title>
        <Buttons>{props.buttons}</Buttons>
      </TitleWrap>
    </div>
  );
};
export default MainTitle;

const titleColor = `#927230`;

const TitleWrap = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 25px;
  @media screen and (max-width: 814px) {
    flex-wrap: ${props => props.flexWrap && 'wrap'};
  }
`;

const Title = styled.h1<{ flexWrap?: boolean }>`
  font-size: 1.5em;
  font-weight: bold;
  color: ${titleColor};
  @media screen and (max-width: 814px) {
    margin-bottom: ${props => props.flexWrap && '10px'};
  }
`;

const Buttons = styled.div`
  padding: 0 20px 0 0;
`;
