import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Fetcher } from '@this/src/util';
import useDebounce from '@this/lib/hooks/useDebounce';
import { Button } from '@this/shared/ui/inputs/button';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { Modal, ModalBody, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import type InvoiceCustomFormatField from '@this/src/domain/invoice_custom_format/invoice_custom_format_field';
import type InvoiceStandardTable from '@this/src/domain/invoice_custom_format/invoice_standard_table';
import type InvoiceStandardField from '@this/src/domain/invoice_custom_format/invoice_standard_field';

const OPERATORS = {
  '+ 加算': '+',
  '- 減算': '-',
  '* 乗算': '*',
  '/ 除算': '/',
  '( 開き括弧': '(',
  ') 閉じ括弧': ')',
  '& 連結': '&',
  '= 次と等しい': '=',
  '<> 次と等しくない': '<>',
  '< 未満': '<',
  '<= 以下': '<=',
  '> 超える': '>',
  '>= 以上': '>=',
  '&& かつ': '&&',
  '|| または': '||'
} as const;

const FUNCTIONS = {
  IF: 'IF(logical_test, value_if_true, value_if_false)',
  AND: 'AND(logical1, logical2)',
  OR: 'OR(logical1, logical2)'
} as const;

interface Props {
  invoiceCustomFormatField: InvoiceCustomFormatField;
  invoiceStandardTables: InvoiceStandardTable[];
}

const InvoiceCustomFormatFieldFormulaForm = observer(
  ({ invoiceCustomFormatField, invoiceStandardTables }: Props) => {
    const [selectedOperator, setSelectedOperator] = useState<string>(Object.values(OPERATORS)[0]);
    const [selectedFunction, setSelectedFunction] = useState<string>(Object.values(FUNCTIONS)[0]);
    const [selectedTable, setSelectedTable] = useState<InvoiceStandardTable | null>(
      invoiceStandardTables[0] || null
    );
    const [selectedField, setSelectedField] = useState<InvoiceStandardField | null>(
      invoiceStandardTables[0]?.invoiceStandardFields[0] || null
    );
    const [showFieldForm, setShowFieldForm] = useState(false);
    const [fetchingFormulaPreview, setFetchingFormulaPreview] = useState(false);
    const [formulaPreview, setFormulaPreview] = useState('');

    const debouncedFormula = useDebounce(invoiceCustomFormatField.formula, 500);

    useEffect(() => {
      fetchFomuraPreveiw(debouncedFormula);
    }, [debouncedFormula]);

    const fetchFomuraPreveiw = async (formula: string) => {
      setFetchingFormulaPreview(true);
      invoiceCustomFormatField.previewFailed = false;
      try {
        const response = await Fetcher.get<{ result: string }>(
          '/organization/invoice_custom_formats/formula_preview.json',
          { formula }
        );
        setFormulaPreview(response.result);
        invoiceCustomFormatField.previewFailed = false;
      } catch (e) {
        setFormulaPreview('構文エラー');
        invoiceCustomFormatField.previewFailed = true;
      } finally {
        setFetchingFormulaPreview(false);
      }
    };

    const handleInsertOperator = () => {
      if (selectedOperator) {
        invoiceCustomFormatField.formula += `${selectedOperator} `;
      }
    };

    const handleInsertFunction = () => {
      if (selectedFunction) {
        invoiceCustomFormatField.formula += `${selectedFunction} `;
      }
    };

    const handleInsertField = () => {
      if (selectedTable && selectedField) {
        invoiceCustomFormatField.formula += `${selectedTable.tableName}.${selectedField.name} `;
      }
    };

    const fieldForm = () => {
      return (
        <div>
          <p style={{ marginBottom: '20px' }}>項目を選択して、[挿入] をクリックします。</p>
          <div style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <div>
                <label id="invoice_standard_table">引用元カテゴリー</label>
                <select
                  id="invoice_standard_table"
                  value={selectedTable?.id || ''}
                  onChange={e => {
                    setSelectedTable(
                      invoiceStandardTables.find(t => t.id === parseInt(e.target.value, 10)) || null
                    );
                    setSelectedField(null);
                  }}
                >
                  {invoiceStandardTables.map(table => (
                    <option key={table.id} value={table.id}>
                      {table.displayName}
                    </option>
                  ))}
                </select>
              </div>
              {selectedTable && selectedTable.name === 'departments' && (
                <div>
                  <label id="department_target">部署種別</label>
                  <select
                    id="department_target"
                    value={selectedTable.departmentTarget}
                    onChange={e => {
                      selectedTable.departmentTarget = e.target.value as 'user_department' | 'charging_department';
                    }}
                  >
                    <option value="user_department">所属部署</option>
                    <option value="charging_department">費用負担先部署</option>
                  </select>
                </div>
              )}
              <div>
                <label id="invoice_standard_field">項目カテゴリー</label>
                <select
                  id="invoice_standard_field"
                  value={selectedField?.id || ''}
                  onChange={e => {
                    setSelectedField(
                      selectedTable?.invoiceStandardFields.find(f => f.id === parseInt(e.target.value, 10)) || null
                    );
                  }}
                >
                  <option value="">選択してください</option>
                  {selectedTable?.invoiceStandardFields.map(field => (
                    <option key={field.id} value={field.id}>
                      {field.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <div style={{ marginBottom: '20px' }}>
                <p style={{ marginBottom: '5px' }}>選択した項目：</p>
                <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  {selectedTable?.tableName}.{selectedField?.name}
                </p>
                <p>{`引用元：${selectedTable?.displayName}`}</p>
                <p>{`項目：${selectedField?.displayName}`}</p>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Button
                onClick={() => {
                  setShowFieldForm(false);
                }}
              >
                閉じる
              </Button>
              <Button
                onClick={() => {
                  setShowFieldForm(false);
                  handleInsertField();
                }}
              >
                挿入
              </Button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div>
        <p style={{ fontSize: '0.9em', marginBottom: '20px' }}>
          数式を入力し、必要に応じて項目の挿入、演算子、および関数を使用してください。
          <br /> 例：departments.name &amp; &quot;, &quot; &amp; invoice_journal_adjusteds.traveler &amp;
          &quot;様&quot;
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '50px',
            marginBottom: '20px'
          }}
        >
          <Button
            onClick={() => {
              setShowFieldForm(true);
            }}
          >
            項目の挿入
          </Button>
          <div>
            <label>演算子カテゴリー</label>
            <select
              value={selectedOperator}
              onChange={e => {
                setSelectedOperator(e.target.value);
              }}
              style={{ marginBottom: '0.5em' }}
            >
              {Object.entries(OPERATORS).map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <Button
              onClick={() => {
                handleInsertOperator();
              }}
            >
              選択した演算子の挿入
            </Button>
          </div>
          <div>
            <label>関数カテゴリー</label>
            <select
              value={selectedFunction}
              onChange={e => {
                setSelectedFunction(e.target.value);
              }}
              style={{ marginBottom: '0.5em' }}
            >
              {Object.entries(FUNCTIONS).map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <Button
              onClick={() => {
                handleInsertFunction();
              }}
            >
              選択した関数の挿入
            </Button>
          </div>
        </div>
        <div>
          <textarea
            id="formula"
            value={invoiceCustomFormatField.formula}
            onChange={e => {
              invoiceCustomFormatField.formula = e.target.value;
            }}
          />
          <div>
            {fetchingFormulaPreview ? <Loading size="small" /> : <p style={{ color: 'gray' }}>{formulaPreview}</p>}
          </div>
        </div>
        <Modal open={showFieldForm} onClose={() => setShowFieldForm(false)}>
          <ModalHeader>項目の挿入</ModalHeader>
          <ModalBody>{fieldForm()}</ModalBody>
        </Modal>
      </div>
    );
  }
);

export default InvoiceCustomFormatFieldFormulaForm;
