import React from 'react';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { SettingJson } from '@this/domain/expenses/organization_setting';
import {
  OrganizationSetting,
  CutoffDateType,
  ApplicationDateType,
  PaymentDateType
} from '@this/domain/expenses/organization_setting';
import TextArea from 'react-textarea-autosize';
import MainTitle from '@this/shared/organization/main_title/main_title';
import {
  OrganizationBiztraEditButton,
  OrganizationButtonSection
} from '@this/components/expenses/organization/organization.style';
import { ContentBodyDetail } from './organization';
import RemittanceSourceAccountForm from './remittance_source_account_form';

interface State {
  setting?: OrganizationSetting;
  loading: boolean;
  submitting: boolean;
  error?: string;
  submitError?: string[];
}

type Props = any;

export default class Setting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false
    };
  }

  componentDidMount() {
    this.fetchSetting();
  }

  async fetchSetting() {
    this.setState({ loading: true });

    try {
      const response = await utils.jsonPromise<SettingJson>('/biztra/organization/setting.json');
      this.setState({
        setting: new OrganizationSetting(response),
        loading: false
      });
    } catch (error) {
      this.setState({
        error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
        loading: false
      });
      utils.sendErrorObject(error);
    }
  }

  async submitSetting() {
    const setting = this.state.setting;
    if (!setting) {
      return;
    }

    this.setState({
      loading: true,
      submitting: true,
      submitError: undefined
    });

    try {
      const response = await utils.jsonPromise<SettingJson>(
        '/biztra/organization/setting',
        setting.submitParams(),
        'PUT'
      );
      this.setState({
        setting: new OrganizationSetting(response),
        loading: false,
        submitting: false
      });
    } catch (error) {
      if (error.status === 400) {
        this.setState({
          submitError: error.responseJSON.errors,
          loading: false,
          submitting: false
        });
      } else {
        this.setState({
          submitError: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'],
          loading: false,
          submitting: false
        });
      }
    }
  }

  handleAddCutoffDate = (dateType: string, index: number) => {
    const setting = this.state.setting;
    if (!setting) {
      return;
    }
    setting.addNewCutoffDate(dateType, index);
  };

  handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.submitSetting();
  };

  handleRemoveCutoffDate = (index: number) => {
    const setting = this.state.setting;
    if (!setting) {
      return;
    }
    setting.removeCutoffDate(index);
    this.submitSetting();
  };

  handleEditingAnnouncement = (value: string) => {
    const setting = this.state.setting;
    if (!setting) {
      return;
    }
    setting.updateAnnouncement(value);
  };

  handleMultistageApproval = () => {
    const setting = this.state.setting;
    if (!setting) {
      return;
    }
    setting.updateMultistageApproval();
  };

  render() {
    const { loading, error, setting, submitting, submitError } = this.state;
    return (
      <>
        <MainTitle value="詳細設定" />
        <ContentBodyDetail>
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <p>{error}</p>
          ) : setting ? (
            <div className="expenses-content-organization-main__body">
              <SettingTitle>締日</SettingTitle>
              <BodyWrap>
                {setting.cutoffDatesToShow(CutoffDateType).length > 0 ? (
                  setting.cutoffDatesToShow(CutoffDateType).map((cutoffDate, i) => (
                    <div className="expenses-organization-setting__cutoff-date" key={i}>
                      <select
                        value={cutoffDate.value()}
                        onChange={e => setting.setCutoffDate(i, e.target.value, CutoffDateType)}
                      >
                        {Array(31)
                          .fill(0)
                          .map((_v, i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}日
                            </option>
                          ))}
                        <option value="endOfMonth">月末</option>
                      </select>
                      {setting.cutoffDatesToShow(CutoffDateType).length === 2 && (
                        <a onClick={() => this.handleRemoveCutoffDate(i)}>×</a>
                      )}
                    </div>
                  ))
                ) : (
                  <div />
                )}
                {setting.cutoffDatesToShow(CutoffDateType).length < 2 ? (
                  <div>
                    <a
                      onClick={() =>
                        this.handleAddCutoffDate(CutoffDateType, setting.cutoffDatesToShow(CutoffDateType).length)
                      }
                    >
                      締日の追加
                    </a>
                  </div>
                ) : (
                  <div />
                )}
              </BodyWrap>
              <Hr />

              <SettingTitle>申請期日</SettingTitle>
              <BodyWrap>
                {setting.cutoffDatesToShow(ApplicationDateType).length > 0 ? (
                  setting.cutoffDatesToShow(ApplicationDateType).map((date, i) => (
                    <div className="expenses-organization-setting__cutoff-date" key={i}>
                      <select
                        value={date.value()}
                        onChange={e =>
                          setting.setCutoffDate(
                            i + setting.cutoffDatesToShow(CutoffDateType).length,
                            e.target.value,
                            ApplicationDateType
                          )
                        }
                      >
                        {Array(31)
                          .fill(0)
                          .map((_v, i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}日
                            </option>
                          ))}
                        <option value="endOfMonth">月末</option>
                      </select>
                      {setting.cutoffDatesToShow(ApplicationDateType).length > 1 && (
                        <a onClick={() => this.handleRemoveCutoffDate(i)}>×</a>
                      )}
                    </div>
                  ))
                ) : (
                  <div>
                    <a
                      onClick={() =>
                        this.handleAddCutoffDate(
                          ApplicationDateType,
                          setting.cutoffDatesToShow(CutoffDateType).length
                        )
                      }
                    >
                      申請期日の追加
                    </a>
                  </div>
                )}
              </BodyWrap>
              <Hr />

              <SettingTitle>支払期日</SettingTitle>
              <BodyWrap>
                {setting.cutoffDatesToShow(PaymentDateType).length > 0 ? (
                  setting.cutoffDatesToShow(PaymentDateType).map((date, i) => (
                    <div className="expenses-organization-setting__cutoff-date" key={i}>
                      <select
                        value={date.value()}
                        onChange={e =>
                          setting.setCutoffDate(
                            i +
                              setting.cutoffDatesToShow(CutoffDateType).length +
                              setting.cutoffDatesToShow(ApplicationDateType).length,
                            e.target.value,
                            PaymentDateType
                          )
                        }
                      >
                        {Array(31)
                          .fill(0)
                          .map((_v, i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}日
                            </option>
                          ))}
                        <option value="endOfMonth">月末</option>
                      </select>
                    </div>
                  ))
                ) : (
                  <div>
                    <a
                      onClick={() =>
                        this.handleAddCutoffDate(
                          PaymentDateType,
                          setting.cutoffDatesToShow(CutoffDateType).length +
                            setting.cutoffDatesToShow(ApplicationDateType).length
                        )
                      }
                    >
                      支払期日の追加
                    </a>
                  </div>
                )}
              </BodyWrap>
              <Hr />

              <SettingTitle>振込データ用</SettingTitle>
              <BodyWrap>
                {setting.zenginAvailable ? (
                  <RemittanceSourceAccountForm account={setting.account!} />
                ) : (
                  <>
                    <div>
                      ビズトラProにアップグレードすると、振込用データ（全銀フォーマット）をダウンロードすることができます。
                    </div>
                    <a href="/biztra/organization/plans" target="_blank">
                      プラン設定ページを開く
                    </a>
                  </>
                )}
              </BodyWrap>
              <Hr />

              <SettingTitle>お知らせ表示</SettingTitle>
              <BodyWrap>
                <TextArea
                  placeholder="お知らせを入力"
                  value={setting.announcement}
                  minRows={10}
                  maxRows={25}
                  onChange={e => {
                    this.handleEditingAnnouncement(e.target.value);
                  }}
                />
              </BodyWrap>

              {setting.showApprovalSetting && (
                <>
                  <SettingTitle>承認設定</SettingTitle>
                  <BodyWrap>
                    <CheckboxContainer>
                      <label>
                        <input
                          type="checkbox"
                          checked={setting.multistageApproval}
                          onClick={() => this.handleMultistageApproval()}
                        />
                        部署による多段承認を有効にする
                        <WarningDescription>
                          一度有効にすると経理による承認に戻すことはできません。
                        </WarningDescription>
                      </label>
                    </CheckboxContainer>
                  </BodyWrap>
                </>
              )}

              <OrganizationButtonSection>
                <OrganizationBiztraEditButton onClick={e => this.handleSubmit(e)} disabled={submitting}>
                  保存
                </OrganizationBiztraEditButton>
              </OrganizationButtonSection>

              {submitError && (
                <ErrorArea>
                  {submitError.map((error, i) => (
                    <p className="error" key={i}>
                      {error}
                    </p>
                  ))}
                </ErrorArea>
              )}
            </div>
          ) : (
            <p>通信エラーが発生しました。時間をおいて再度お試しください。</p>
          )}
        </ContentBodyDetail>
      </>
    );
  }
}

const SettingTitle = styled.p`
  font-size: 1.125em;
  font-weight: bold;
  margin: 20px 0 10px;
  padding-bottom: 5px;
  &:first-child {
    margin-top: 0;
  }
`;

const ErrorArea = styled.div`
  margin: 15px 0;
`;

const Hr = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-top: 10px;
`;

const BodyWrap = styled.div`
  padding-left: 6px;
`;

const CheckboxContainer = styled.div`
  margin-bottom: 20px;
`;

const WarningDescription = styled.p`
  color: red;
  font-size: 14px;
  font-weight: normal;
`;
