import _ from 'lodash';
import { observable } from 'mobx';
import type { RakurakuSettingArgs } from './rakuraku_setting';
import { RakurakuSetting } from './rakuraku_setting';

export type AutoRemoveFreeePostDeals = 'hidden' | 'unassociated' | 'associated';

export interface SettingArgs {
  id: number;
  purchase_section: number;
  is_internal_number_required: boolean;
  is_project_name_required: boolean;
  is_charging_department_required: boolean;
  is_purpose_business_trip_required: boolean;
  is_destination_business_trip_required: boolean;
  is_expenses_account_type_required: boolean;
  is_trip_report_activity_content_required: boolean;
  is_trip_report_results_content_required: boolean;
  is_trip_report_other_text_required: boolean;
  show_internal_number: boolean;
  show_project_name: boolean;
  show_charging_department: boolean;
  show_expenses_account_type: boolean;
  show_trip_report_activity_content: boolean;
  show_trip_report_results_content: boolean;
  show_trip_report_other_text: boolean;
  financial_last_month: number;
  count_allowance_without_stay: boolean;
  allow_private_use: boolean;
  timeout_minutes: number;
  rakuraku_setting: RakurakuSettingArgs;
  send_user_forwarded_email: boolean;
  disable_show_milage_number: boolean;
  aggregate_unit: string;
  aggregate_base: string;
  expense_tool: string;
  auto_remove_freee_post_deals: AutoRemoveFreeePostDeals;
  default_smart_search_enabled: boolean;
  prevent_zero_amount_application: boolean;
}

export type WorkflowRelatedSetting = 'mandatory' | 'optional' | 'hidden';

export type ExpenseTool = 'none' | 'rakuraku' | 'biztra';

// 仕様上、{ required: true, show: false } の組み合わせは無い
const WorkflowRelatedSettingMap = {
  mandatory: { required: true, show: true },
  optional: { required: false, show: true },
  hidden: { required: false, show: false }
};

// WorkflowRelatedSettingとは異なり必須か任意かのみの選択肢
export type WorkflowSetting = 'mandatory' | 'optional';

const WorkflowSettingMap = {
  mandatory: true,
  optional: false
};

class Setting {
  readonly id: number;

  public purchaseSection: number;

  public financialLastMonth: number;

  public internalNumber: WorkflowRelatedSetting;

  public projectName: WorkflowRelatedSetting;

  public chargingDepartment: WorkflowRelatedSetting;

  public countAllowanceWithoutStay: boolean;

  public purposeBusinessTrip: WorkflowSetting;

  public destinationBusinessTrip: WorkflowSetting;

  public expensesAccountType: WorkflowRelatedSetting;

  public tripReportActivityContent: WorkflowRelatedSetting;

  public tripReportResultsContent: WorkflowRelatedSetting;

  public tripReportOtherText: WorkflowRelatedSetting;

  public sendUserForwardedEmail: boolean;

  private isInternalNumberRequired: boolean;

  private isProjectNameRequired: boolean;

  private isChargingDepartmentRequired: boolean;

  private isPurposeBusinessTripRequired: boolean;

  private isDestinationBusinessTripRequired: boolean;

  private isExpensesAccountTypeRequired: boolean;

  private isTripReportActivityContentRequired: boolean;

  private isTripReportResultsContentRequired: boolean;

  private isTripReportOtherTextRequired: boolean;

  private showInternalNumber: boolean;

  private showProjectName: boolean;

  private showChargingDepartment: boolean;

  private showExpensesAccountType: boolean;

  private showTripReportActivityContent: boolean;

  private showTripReportResultsContent: boolean;

  private showTripReportOtherText: boolean;

  public aggregateUnit: string;

  public aggregateBase: string;

  public expenseTool: ExpenseTool;

  public autoRemoveFreeePostDeals: AutoRemoveFreeePostDeals;

  public defaultSmartSearchEnabled: boolean;

  public preventZeroAmountApplication: boolean;

  @observable
  allowPrivateUse: boolean;

  @observable
  timeoutMinutes?: number;

  @observable
  rakurakuSetting: RakurakuSetting;

  @observable
  disableShowMilageNumber: boolean;

  constructor(args: SettingArgs) {
    this.id = args.id;
    this.purchaseSection = args.purchase_section;
    this.isInternalNumberRequired = args.is_internal_number_required;
    this.isProjectNameRequired = args.is_project_name_required;
    this.isChargingDepartmentRequired = args.is_charging_department_required;
    this.isPurposeBusinessTripRequired = args.is_purpose_business_trip_required;
    this.isDestinationBusinessTripRequired = args.is_destination_business_trip_required;
    this.isExpensesAccountTypeRequired = args.is_expenses_account_type_required;
    this.isTripReportActivityContentRequired = args.is_trip_report_activity_content_required;
    this.isTripReportResultsContentRequired = args.is_trip_report_results_content_required;
    this.isTripReportOtherTextRequired = args.is_trip_report_other_text_required;
    this.showInternalNumber = args.show_internal_number;
    this.showProjectName = args.show_project_name;
    this.showChargingDepartment = args.show_charging_department;
    this.showExpensesAccountType = args.show_expenses_account_type;
    this.showTripReportActivityContent = args.show_trip_report_activity_content;
    this.showTripReportResultsContent = args.show_trip_report_results_content;
    this.showTripReportOtherText = args.show_trip_report_other_text;
    this.financialLastMonth = args.financial_last_month;
    this.internalNumber = Setting.getWorkflowRelatedSettingValue(
      args.is_internal_number_required,
      args.show_internal_number
    );
    this.projectName = Setting.getWorkflowRelatedSettingValue(
      args.is_project_name_required,
      args.show_project_name
    );
    this.chargingDepartment = Setting.getWorkflowRelatedSettingValue(
      args.is_charging_department_required,
      args.show_charging_department
    );
    this.countAllowanceWithoutStay = args.count_allowance_without_stay || false;
    this.purposeBusinessTrip = Setting.getWorkflowSettingValue(args.is_purpose_business_trip_required);
    this.destinationBusinessTrip = Setting.getWorkflowSettingValue(args.is_destination_business_trip_required);
    this.expensesAccountType = Setting.getWorkflowRelatedSettingValue(
      args.is_expenses_account_type_required,
      args.show_expenses_account_type
    );
    this.tripReportActivityContent = Setting.getWorkflowRelatedSettingValue(
      args.is_trip_report_activity_content_required,
      args.show_trip_report_activity_content
    );
    this.tripReportResultsContent = Setting.getWorkflowRelatedSettingValue(
      args.is_trip_report_results_content_required,
      args.show_trip_report_results_content
    );
    this.tripReportOtherText = Setting.getWorkflowRelatedSettingValue(
      args.is_trip_report_other_text_required,
      args.show_trip_report_other_text
    );
    this.allowPrivateUse = args.allow_private_use;
    this.timeoutMinutes = args.timeout_minutes;

    this.rakurakuSetting = new RakurakuSetting(args.rakuraku_setting);
    this.sendUserForwardedEmail = args.send_user_forwarded_email;
    this.disableShowMilageNumber = args.disable_show_milage_number;
    this.aggregateUnit = args.aggregate_unit;
    this.aggregateBase = args.aggregate_base;
    this.expenseTool = args.expense_tool as ExpenseTool;
    this.autoRemoveFreeePostDeals = args.auto_remove_freee_post_deals;
    this.defaultSmartSearchEnabled = args.default_smart_search_enabled;
    this.preventZeroAmountApplication = args.prevent_zero_amount_application;
  }

  static getWorkflowRelatedSettingValue(required: boolean, show: boolean) {
    return _.findKey(WorkflowRelatedSettingMap, { required, show }) as WorkflowRelatedSetting;
  }

  static getWorkflowSettingValue(required: boolean) {
    if (required) {
      return 'mandatory' as WorkflowSetting;
    }
    return 'optional' as WorkflowSetting;
  }

  setInternalNumber(value: WorkflowRelatedSetting) {
    this.internalNumber = value;
    ({ required: this.isInternalNumberRequired, show: this.showInternalNumber } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setProjectName(value: WorkflowRelatedSetting) {
    this.projectName = value;
    ({ required: this.isProjectNameRequired, show: this.showProjectName } = WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setChargingDepartment(value: WorkflowRelatedSetting) {
    this.chargingDepartment = value;
    ({ required: this.isChargingDepartmentRequired, show: this.showChargingDepartment } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setPurposeBusinessTrip(value: WorkflowSetting) {
    this.purposeBusinessTrip = value;
    this.isPurposeBusinessTripRequired = WorkflowSettingMap[value];
    app.render();
  }

  setDestinationBusinessTrip(value: WorkflowSetting) {
    this.destinationBusinessTrip = value;
    this.isDestinationBusinessTripRequired = WorkflowSettingMap[value];
    app.render();
  }

  setExpensesAccountType(value: WorkflowRelatedSetting) {
    this.expensesAccountType = value;
    ({ required: this.isExpensesAccountTypeRequired, show: this.showExpensesAccountType } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setTripReportActivityContent(value: WorkflowRelatedSetting) {
    this.tripReportActivityContent = value;
    ({ required: this.isTripReportActivityContentRequired, show: this.showTripReportActivityContent } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setTripReportResultsContent(value: WorkflowRelatedSetting) {
    this.tripReportResultsContent = value;
    ({ required: this.isTripReportResultsContentRequired, show: this.showTripReportResultsContent } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setTripReportOtherText(value: WorkflowRelatedSetting) {
    this.tripReportOtherText = value;
    ({ required: this.isTripReportOtherTextRequired, show: this.showTripReportOtherText } =
      WorkflowRelatedSettingMap[value]);
    app.render();
  }

  setPurchaseSection(value: number) {
    this.purchaseSection = value;
    app.render();
  }

  setFinancialLastMonth(value: number) {
    this.financialLastMonth = value;
    app.render();
  }

  setCountAllowanceWithoutStay() {
    this.countAllowanceWithoutStay = !this.countAllowanceWithoutStay;
    app.render();
  }

  setRakurakuSetting(value: boolean) {
    this.rakurakuSetting.exicOrderDataStopProvide = value;
  }

  setSendUserForwardedEmail(value: boolean) {
    this.sendUserForwardedEmail = value;
    app.render();
  }

  setAggregateUnit(value: string) {
    this.aggregateUnit = value;
    app.render();
  }

  setAggregateBase(value: string) {
    this.aggregateBase = value;
    app.render();
  }

  setAutoRemoveFreeePostDeals(value: AutoRemoveFreeePostDeals) {
    this.autoRemoveFreeePostDeals = value;
    app.render();
  }

  setDefaultSmartSearchEnabled(value: boolean) {
    this.defaultSmartSearchEnabled = value;
    app.render();
  }

  setPreventZeroAmountApplication(value: boolean) {
    this.preventZeroAmountApplication = value;
    app.render();
  }

  submitParams() {
    return {
      purchase_section: this.purchaseSection,
      is_internal_number_required: this.isInternalNumberRequired,
      is_project_name_required: this.isProjectNameRequired,
      is_charging_department_required: this.isChargingDepartmentRequired,
      is_purpose_business_trip_required: this.isPurposeBusinessTripRequired,
      is_destination_business_trip_required: this.isDestinationBusinessTripRequired,
      is_expenses_account_type_required: this.isExpensesAccountTypeRequired,
      is_trip_report_activity_content_required: this.isTripReportActivityContentRequired,
      is_trip_report_results_content_required: this.isTripReportResultsContentRequired,
      is_trip_report_other_text_required: this.isTripReportOtherTextRequired,
      show_internal_number: this.showInternalNumber,
      show_project_name: this.showProjectName,
      show_charging_department: this.showChargingDepartment,
      show_expenses_account_type: this.showExpensesAccountType,
      show_trip_report_activity_content: this.showTripReportActivityContent,
      show_trip_report_results_content: this.showTripReportResultsContent,
      show_trip_report_other_text: this.showTripReportOtherText,
      financial_last_month: this.financialLastMonth,
      count_allowance_without_stay: this.countAllowanceWithoutStay,
      allow_private_use: this.allowPrivateUse,
      timeout_minutes: this.timeoutMinutes,
      rakuraku_setting: this.rakurakuSetting.submitParams(),
      send_user_forwarded_email: this.sendUserForwardedEmail,
      disable_show_milage_number: this.disableShowMilageNumber,
      aggregate_unit: this.aggregateUnit,
      aggregate_base: this.aggregateBase,
      default_smart_search_enabled: this.defaultSmartSearchEnabled,
      prevent_zero_amount_application: this.preventZeroAmountApplication
    };
  }

  organizationSubmitParams() {
    return {
      id: this.id,
      auto_remove_freee_post_deals: this.autoRemoveFreeePostDeals
    };
  }
}

export default Setting;
