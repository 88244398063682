import { useEffect, useState } from 'react';

export const useSwitchText = (key: 'hotel', loading: boolean): string => {
  const loadingTextsWithKey = {
    hotel: ['最新の部屋情報を取得しています', '最新の価格情報を取得しています']
  };
  const loadingTexts = loadingTextsWithKey[key];
  const [textIndex, setTextIndex] = useState(0);
  useEffect(() => {
    if (!loading) return undefined;
    const timerId = setInterval(() => {
      setTextIndex(prevIndex => prevIndex + 1);
    }, 1700);
    return () => {
      clearInterval(timerId);
    };
  }, [loading]);
  return loadingTexts[textIndex % loadingTexts.length];
};
