import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Popover, FormControlLabel, Checkbox, FormGroup, Box, FormLabel } from '@material-ui/core';
import type { HotelElementProviderKey } from '@this/src/domain/hotel_element_provider';
import { HotelElementProvider, HotelElementProviderAvailable } from '@this/src/domain/hotel_element_provider';

interface Props {
  selectedHotelProviders: HotelElementProviderKey[];
  onChangeSelectedHotelProviders: (statuses: HotelElementProviderKey[]) => void;
  disabled?: boolean;
}

const HotelProviderSelector = observer(
  ({ selectedHotelProviders, onChangeSelectedHotelProviders, disabled }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const handleClickHotelProviderFilterButton = (e: React.MouseEvent) => {
      setAnchorEl(e.currentTarget);
    };

    const handleCloseHotelProviderFilter = () => {
      setAnchorEl(null);
    };

    const handleSelectAllHotelProviders = (e: React.ChangeEvent<HTMLInputElement>) => {
      const statuses = e.target.checked ? HotelElementProviderAvailable : [];
      onChangeSelectedHotelProviders(statuses as HotelElementProviderKey[]);
    };

    const handleSelectHotelProvider = (e: React.ChangeEvent<HTMLInputElement>, status: string) => {
      const statuses = e.target.checked
        ? selectedHotelProviders.concat([status as HotelElementProviderKey])
        : _.without(selectedHotelProviders, status);
      onChangeSelectedHotelProviders(statuses as HotelElementProviderKey[]);
    };

    return (
      <>
        <Button
          size="small"
          variant="outlined"
          onClick={handleClickHotelProviderFilterButton}
          style={{ backgroundColor: 'white' }}
          disabled={disabled}
        >
          絞り込み
        </Button>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseHotelProviderFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box display="flex">
            <FormGroup style={{ padding: '5px' }}>
              <FormLabel>プロバイダ</FormLabel>
              <FormControlLabel
                label="すべて選択"
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedHotelProviders.length === HotelElementProviderAvailable.length}
                    indeterminate={
                      selectedHotelProviders.length !== 0 &&
                      selectedHotelProviders.length !== HotelElementProviderAvailable.length
                    }
                    onChange={handleSelectAllHotelProviders}
                  />
                }
              />
              {HotelElementProviderAvailable.map(key => (
                <FormControlLabel
                  key={key}
                  label={HotelElementProvider[key]}
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedHotelProviders.includes(key)}
                      onChange={e => {
                        handleSelectHotelProvider(e, key);
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </Popover>
      </>
    );
  }
);

export default HotelProviderSelector;
