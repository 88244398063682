import React from 'react';
import type {
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioGroupProps as MuiRadioGroupProps
} from '@material-ui/core';
import {
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio as MuiRadio,
  makeStyles
} from '@material-ui/core';
import type { AITTheme } from '@this/shared/ui/theme';

const useRadioGroupStyle = makeStyles<AITTheme, { layout: RadioGroupProps['layout'] }>(({ utils }) => {
  return {
    root: ({ layout }) =>
      layout === 'horizontal'
        ? {
            flexDirection: 'row',
            '& .MuiFormControlLabel-root:not(:first-child)': { marginLeft: utils.getSpacing(2) }
          }
        : { '& .MuiFormControlLabel-root:not(:first-child)': { marginTop: utils.getSpacing(2) } }
  };
});

export type RadioGroupProps = { layout?: 'vertical' | 'horizontal' } & MuiRadioGroupProps;
export const RadioGroup = ({ layout = 'vertical', className, ...props }: RadioGroupProps) => {
  const styles = useRadioGroupStyle({ layout });
  return <MuiRadioGroup className={`${className} ${styles.root}`} {...props} />;
};

const useRadioStyle = makeStyles<AITTheme>(({ utils }) => {
  return {
    radioRoot: { padding: 0, marginRight: utils.getSpacing(1) },
    labelRoot: { marginLeft: 0, marginRight: 0 }
  };
});
export type RadioProps = { children: React.ReactNode } & Omit<MuiFormControlLabelProps, 'control' | 'label'>;
export const Radio = ({ className, children, ...props }: RadioProps) => {
  const styles = useRadioStyle();

  return (
    <MuiFormControlLabel
      className={`${className} ${styles.labelRoot}`}
      control={<MuiRadio className={styles.radioRoot} color="primary" />}
      label={children}
      {...props}
    />
  );
};
