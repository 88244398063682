import _ from 'lodash';

class ArrangementRequestHotelInfo {
  hotelFirstName: string;

  hotelLastName: string;

  hotelFirstNameKana: string;

  hotelLastNameKana: string;

  hotelTel: string;

  bedTypeId: number | undefined;

  bedTypeDescription: string | undefined;

  readonly bedTypes: { id: number; description: string }[] | null | undefined;

  constructor() {
    this.hotelFirstName = '';
    this.hotelLastName = '';
    this.hotelFirstNameKana = '';
    this.hotelLastNameKana = '';
    this.hotelTel = '';
  }

  setHotelFirstName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.hotelFirstName = e.target.value;
    app.render();
  };

  setHotelLastName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.hotelLastName = e.target.value;
    app.render();
  };

  setHotelFirstNameKana = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.hotelFirstNameKana = e.target.value;
    app.render();
  };

  setHotelLastNameKana = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.hotelLastNameKana = e.target.value;
    app.render();
  };

  setHotelTel = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    this.hotelTel = e.target.value;
    app.render();
  };

  setBedType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const bedTypeId = parseInt(e.target.value, 10);
    this.bedTypeId = bedTypeId;
    this.bedTypeDescription = _.find(this.bedTypes, bedType => bedType.id === bedTypeId)!.description;
    app.render();
  };

  validate(): string[] {
    const errors: string[] = [];
    if (this.hotelLastName.length === 0) errors.push('代表者氏名（姓）を入力してください');
    if (this.hotelFirstName.length === 0) errors.push('代表者氏名（名）を入力してください');
    if (this.hotelLastNameKana.length === 0) errors.push('代表者氏名(カナ)（姓）を入力してください');
    if (this.hotelFirstNameKana.length === 0) errors.push('代表者氏名(カナ)（名）を入力してください');
    if (this.hotelTel.length === 0) errors.push('代表者電話番号を入力してください');
    return errors;
  }

  submitParams() {
    return {
      last_name: this.hotelLastName,
      first_name: this.hotelFirstName,
      last_name_kana: this.hotelLastNameKana,
      first_name_kana: this.hotelFirstNameKana,
      tel: this.hotelTel
    };
  }
}

export default ArrangementRequestHotelInfo;
