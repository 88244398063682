interface UserForwardedEmailsJson {
  id: number;
  email: string;
}

export class UserForwardedEmails {
  readonly id?: number;

  email: string;

  constructor(args?: UserForwardedEmailsJson) {
    if (args) {
      this.id = args.id;
      this.email = args.email;
    } else {
      this.email = '';
    }
  }

  setEmail(value: string) {
    this.email = value;
    app.render();
  }

  params() {
    return {
      id: this.id,
      email: this.email
    };
  }
}
