import React from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/src/components/shared/atoms/media';
import type { TripReport } from '@this/src/domain/trip_report/trip_report';
import type Setting from '@this/src/domain/setting';
import { Typography } from '@material-ui/core';
import Markdown from '@this/shared/markdown/markdown';
import type { TripReportStore } from '@this/domain/trip_report/trip_report_store';
import moment from 'moment';
import type TripApproveItemJson from '@this/src/domain/trip_approve_item/trip_approve_item_json';
import type ApproveItem from '@this/src/domain/approve_item/approve_item';

type Props = {
  tripReport: TripReport;
  store: TripReportStore;
  setting: Setting | null;
  isPreview: boolean;
};

const TripReportOverview: React.FC<Props> = ({ tripReport, setting, store, isPreview = false }) =>
  tripReport.activityContent ||
  tripReport.resultsContent ||
  tripReport.otherText ||
  store.approveItems.list.length > 0 ||
  store.approveItemJson ? (
    <Wrapper>
      {setting?.tripReportActivityContent !== 'hidden' && tripReport.activityContent && (
        <Row>
          <Label>活動内容</Label>
          <Text>{tripReport.activityContent}</Text>
        </Row>
      )}
      {setting?.tripReportResultsContent !== 'hidden' && tripReport.resultsContent && (
        <Row>
          <Label>成果内容</Label>
          <Text>{tripReport.resultsContent}</Text>
        </Row>
      )}
      {setting?.tripReportOtherText !== 'hidden' && tripReport.otherText && (
        <Row>
          <Label>その他</Label>
          <Text>{tripReport.otherText}</Text>
        </Row>
      )}
      {/* 申請者/承認者が申請の閲覧を行う場合はこちら */}
      {!isPreview &&
        store.approveItemJson &&
        store.parseApproveItemJson(store.approveItemJson).map((item: TripApproveItemJson) => {
          if (item.dataType === 'label') {
            return <></>;
          }
          return (
            <Row key={item.id}>
              <Label>{item.userDisplayName}</Label>
              <Text>
                {item.dataType !== 'calendar' && (
                  <>
                    {item.dataType !== 'file' ? (
                      <>
                        {item.valueCode ? (
                          <span data-wovn-ignore>
                            {item.valueCode}：{item.value}
                          </span>
                        ) : (
                          <span data-wovn-ignore>{item.value}</span>
                        )}
                      </>
                    ) : (
                      <>
                        {item.filePath && (
                          <>
                            <a
                              href={`/trip_reports/${tripReport.id}/approve_item_files/${item.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FileLinkIcon src="/images/clip_highlighted.png" />
                            </a>
                            <span data-wovn-ignore>{item.value}</span>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {item.dataType === 'calendar' && (
                  <span data-wovn-ignore>{item.value ? moment(item.value).format('MM月DD日') : ''}</span>
                )}
              </Text>
            </Row>
          );
        })}
      {/* 申請者が入力の確認画面を表示している場合はこちら */}
      {isPreview &&
        store.approveItems.list.map((d: ApproveItem) => (
          <div className="flex" key={d.id}>
            {d.dataType === 'label' ? (
              <ApproveItemLabelOnly style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                <Markdown markdownText={d.userDisplayName} />
              </ApproveItemLabelOnly>
            ) : (
              <Label data-wovn-ignore>
                {store.getApproveItemValueCode(d.id) && <>{store.getApproveItemValueCode(d.id)}：</>}
                {store.getApproveItemValue(d.id)}
              </Label>
            )}
          </div>
        ))}
    </Wrapper>
  ) : (
    <></>
  );

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  font-size: 0.9em;

  ${media.sp`
    gap: 16px;
  `}
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;

  ${media.sp`
    flex-flow: column;
    gap: 0;
  `}
`;

const Label = styled.label`
  width: 100px;
`;

const Text = styled(Typography)`
  flex: 1;
  white-space: pre-wrap;

  ${media.sp`
    font-size: 0.95em;
  `}
`;

const ApproveItemLabelOnly = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 34px;
`;

const FileLinkIcon = styled.img`
  width: 20px;
`;

export default TripReportOverview;
