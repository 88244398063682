import React, { useEffect } from 'react';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import TripReportReportApproval from './trip_report_report_approval';
import TripReportOverview from './trip_report_form/body/trip_report_confirm/trip_report_overview';
import TripCard from './trip_report_form/body/trip_report_confirm/trip_card';
import NonOrderItemsCard from './trip_report_form/body/trip_report_confirm/non_order_items_card';

type Props = {
  editable?: boolean;
};

const TripReportReport: React.FC<Props> = ({ editable }) => {
  const location = useLocation();
  const isNew = location.pathname.indexOf('new') > 0;
  const isEdit = location.pathname.indexOf('edit') > 0;
  const store = useTripReportStore();
  const { serviceId, currentTripReport, trips, setting } = store;

  useEffect(() => {
    store.getMenus();
  }, [store]);

  return (
    <Box display="flex" gridGap={24} style={{ flexFlow: 'column' }}>
      <TripReportOverview
        tripReport={currentTripReport}
        setting={setting}
        store={store}
        isPreview={isNew || isEdit}
      />
      {trips.map(trip => (
        <TripCard
          key={trip.id}
          serviceId={serviceId}
          trip={trip}
          {...store.tripPrices(trip.id)}
          nonOrderItems={store.nonOrderItems.filter(item => item.tripId === trip.id)}
          allowanceItems={store.allowanceItems.filter(item => item.tripId === trip.id)}
          categoryOptions={store.categoryOptions}
        />
      ))}
      <NonOrderItemsCard
        serviceId={serviceId}
        tripReport={currentTripReport}
        nonOrderItems={store.optionalNonOrderItems()}
        allowanceItems={store.optionalAllowanceItems()}
        projects={store.optionalNonOrderItemProjects()}
        {...store.optionalTotalPrice()}
      />
      <TripReportReportApproval editable={editable} />
    </Box>
  );
};

export default TripReportReport;
