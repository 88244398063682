import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useReducer } from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';

import { styled } from '@this/src/components/constants/themes';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import SearchTrackingLog from '@this/src/domain/search_tracking_log';
import type { SearchTrackingLogArgs } from '@this/src/domain/search_tracking_log';
import SearchTrackingExternalLog from '@this/src/domain/search_tracking_external_log';
import type { SearchTrackingExternalLogArgs } from '@this/src/domain/search_tracking_external_log';
import SearchTrackingLogDetail from './search_tracking_log_detail';

interface Props {
  searchTrackingLogId: number | null;
  onClose: () => void;
}

interface State {
  loading: boolean;
  searchTrackingLog: SearchTrackingLog | null;
  searchTrackingExternalLogs: SearchTrackingExternalLog[];
  errors: string[];
}

interface Response {
  search_tracking_log: SearchTrackingLogArgs;
  search_tracking_external_logs: SearchTrackingExternalLogArgs[];
}

type Action =
  | { type: 'FETCH_START' }
  | { type: 'FETCH'; payload: Response }
  | { type: 'FETCH_ERROR'; payload: string[] };

interface Request {
  searchTrackingLogId: number;
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        loading: true,
        searchTrackingLog: null,
        searchTrackingExternalLogs: [],
        errors: []
      };
    case 'FETCH':
      return {
        ...state,
        loading: false,
        searchTrackingLog: new SearchTrackingLog(action.payload.search_tracking_log),
        searchTrackingExternalLogs: action.payload.search_tracking_external_logs.map(
          raw => new SearchTrackingExternalLog(raw)
        )
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    default:
      return state;
  }
};

const fetchSearchTrackingLog = ({ searchTrackingLogId, dispatch }: Request) => {
  dispatch({ type: 'FETCH_START' });
  Fetcher.get<Response>(`/arrangement/search_tracking_logs/${searchTrackingLogId}.json`)
    .then(result => {
      dispatch({ type: 'FETCH', payload: result });
    })
    .catch(() => {
      dispatch({ type: 'FETCH_ERROR', payload: ['ログ詳細の取得に失敗しました'] });
    });
};

const initialState: State = {
  loading: true,
  searchTrackingLog: null,
  searchTrackingExternalLogs: [],
  errors: []
};

const SearchTrackingLogDetailDialog: React.FC<Props> = ({ searchTrackingLogId, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, searchTrackingLog, searchTrackingExternalLogs, errors } = state;

  const fetch = useCallback(() => {
    if (searchTrackingLogId) fetchSearchTrackingLog({ searchTrackingLogId, dispatch });
  }, [searchTrackingLogId, dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Modal open={Boolean(searchTrackingLogId)} size="large" onClose={onClose}>
      <ModalHeader>ログ詳細</ModalHeader>
      <ModalBody>
        {loading ? (
          <SimpleLoading />
        ) : errors.length > 0 ? (
          <Errors>
            {errors.map((error, index) => (
              <Error key={index}>{error}</Error>
            ))}
          </Errors>
        ) : (
          searchTrackingLog && (
            <SearchTrackingLogDetail
              searchTrackingLog={searchTrackingLog}
              searchTrackingExternalLogs={searchTrackingExternalLogs}
            />
          )
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
};

const Errors = styled.div`
  color: ${props => props.theme.redColor};
`;

const Error = styled.div`
  margin-bottom: 10px;
`;

export default SearchTrackingLogDetailDialog;
