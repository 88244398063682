import React, { useEffect, useMemo } from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/src/components/constants/themes';
import type OrderItemStepTodo from '@this/src/domain/arrangement/order_item_step_todo';
import { getColor } from '@this/shared/ui/theme';

interface Props {
  todo: OrderItemStepTodo;
}

const OrderItemStepDurationCounter: React.FC<Props> = ({ todo }) => {
  const { standardDurationMinutes, startedAt } = todo;

  return standardDurationMinutes && startedAt ? (
    <div>
      <div>
        <span>標準時間: </span>
        <span>{standardDurationMinutes}分00秒</span>
      </div>
      <div>
        <span>経過時間: </span>
        <TimeElapsed minutes={standardDurationMinutes} startedAt={startedAt} />
      </div>
    </div>
  ) : (
    <></>
  );
};

const TimeElapsed: React.FC<{ minutes: number; startedAt: Moment }> = ({ minutes, startedAt }) => {
  const [time, setTime] = React.useState(0);
  const formattedTime = useMemo(() => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}分${seconds < 10 ? `0${seconds}` : seconds}秒`;
  }, [time]);
  const color = useMemo(() => {
    const diff = minutes * 60 - time;
    return diff > 60 ? 'normal' : diff > 0 ? 'warning' : 'danger';
  }, [time, minutes]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(now.diff(startedAt));
      const seconds = Math.floor(duration.asSeconds());
      setTime(seconds);
    }, 1000);

    return () => clearInterval(interval);
  }, [startedAt]);

  return <ColorSpan className={color}>{formattedTime}</ColorSpan>;
};

const ColorSpan = styled.span`
  &.normal {
    color: ${getColor('success', 'primary')};
  }
  &.warning {
    color: ${getColor('warning', 'primary')};
  }
  &.danger {
    color: ${getColor('danger', 'primary')};
  }
`;

export default OrderItemStepDurationCounter;
