import { observable } from 'mobx';
import type ProjectList from './project/project_list';
import type ProjectShareList from './project/project_share_list';
import type DepartmentList from './department/department_list';
import type ChargingDepartmentShareList from './department/charging_department_share_list';
import Department from './department/department';
import Project from './project/project';
import type { ProjectArgs } from './project/project';
import ChargingDepartmentShare from './department/charging_department_share';
import type { ChargingDepartmentShareArgs } from './department/charging_department_share';
import ProjectShare from './project/project_share';
import type { ProjectShareArgs } from './project/project_share';
import type ExpensesAccountType from './expenses/expenses_account_type';

export type OrderItemMappingArgs = {
  id?: number;
  traveler_information_id?: number;
  traveler_id?: number;
  order_item_id?: number;
  order_item_type?: string;
  order_item_index?: number;
  project_id?: number;
  project_share_id?: number;
  charging_department_id?: number;
  charging_department_share_id?: number;
  expenses_account_type_id?: number;
  selected_option_label?: string;
  traveler_information?: any;
  project?: ProjectArgs;
  charging_department?: any;
  project_share?: ProjectShareArgs;
  charging_department_share?: ChargingDepartmentShareArgs;
};

export type OrderItemMappingMode = 'item' | 'traveler' | 'both' | 'default';

export const ORDER_ITEM_MAPPING_DEFAULT_ARGS: OrderItemMappingArgs = {
  traveler_id: -1,
  order_item_type: 'all',
  order_item_index: -1
};

export default class OrderItemMapping {
  @observable
  id: number | null;

  @observable
  travelerInformationId: number | null;

  @observable
  travelerId: number | null;

  @observable
  orderItemId: number | null;

  @observable
  orderItemType: string | null;

  @observable
  orderItemIndex: number | null;

  @observable
  projectId: number | null;

  @observable
  projectShareId: number | null;

  @observable
  chargingDepartmentId: number | null;

  @observable
  chargingDepartmentShareId: number | null;

  @observable
  expensesAccountTypeId: number | null;

  @observable
  selectedOptionLabel: string | null;

  @observable
  travelerInformation?: any | null;

  @observable
  chargingDepartment?: Department | null;

  @observable
  project?: Project | null;

  @observable
  chargingDepartmentShare?: ChargingDepartmentShare | null;

  @observable
  projectShare?: ProjectShare | null;

  constructor(args: OrderItemMappingArgs) {
    this.id = args.id || null;
    this.travelerInformationId = args.traveler_information_id || null;
    this.travelerId = args.traveler_id !== undefined ? args.traveler_id : null;
    this.orderItemId = args.order_item_id || null;
    this.orderItemType = args.order_item_type || null;
    this.orderItemIndex = args.order_item_index !== undefined ? args.order_item_index : null;
    this.projectId = args.project_id || null;
    this.projectShareId = args.project_share_id || null;
    this.chargingDepartmentId = args.charging_department_id || null;
    this.chargingDepartmentShareId = args.charging_department_share_id || null;
    this.expensesAccountTypeId = args.expenses_account_type_id || null;
    this.selectedOptionLabel = args.selected_option_label || null;
    if (args.traveler_information) this.travelerInformation = args.traveler_information;
    if (args.project) this.project = new Project(args.project);
    if (args.charging_department) this.chargingDepartment = new Department(args.charging_department);
    if (args.project_share) this.projectShare = new ProjectShare(args.project_share);
    if (args.charging_department_share)
      this.chargingDepartmentShare = new ChargingDepartmentShare(args.charging_department_share);
  }

  orderItemMappingType(): OrderItemMappingMode | '' {
    let type: OrderItemMappingMode | '' = '';
    if (this.travelerId !== null && this.orderItemIndex !== null) {
      if (this.travelerId >= 0 && this.orderItemIndex >= 0) {
        type = 'both';
      } else if (this.travelerId < 0 && this.orderItemIndex >= 0) {
        type = 'item';
      } else if (this.travelerId >= 0 && this.orderItemIndex < 0) {
        type = 'traveler';
      } else {
        type = 'default';
      }
    }
    return type;
  }

  clone(): OrderItemMapping {
    const args: OrderItemMappingArgs = {
      id: this.id || undefined,
      traveler_information_id: this.travelerInformationId || undefined,
      traveler_id: this.travelerId || undefined,
      order_item_id: this.orderItemId || undefined,
      order_item_type: this.orderItemType || undefined,
      order_item_index: this.orderItemIndex || undefined,
      project_id: this.projectId || undefined,
      project_share_id: this.projectShareId || undefined,
      charging_department_id: this.chargingDepartmentId || undefined,
      charging_department_share_id: this.chargingDepartmentShareId || undefined,
      expenses_account_type_id: this.expensesAccountTypeId || undefined,
      selected_option_label: this.selectedOptionLabel || undefined
    };
    return new OrderItemMapping(args);
  }

  setProjectId = (value: number | null) => {
    this.projectId = value;
    this.projectShareId = null;
    app.render();
  };

  setProjectShareId = (value: number | null) => {
    this.projectShareId = value;
    this.projectId = null;
    app.render();
  };

  setChargingDepartmentId = (value: number | null) => {
    this.chargingDepartmentId = value;
    this.chargingDepartmentShareId = null;
    app.render();
  };

  setChargingDepartmentShareId = (value: number) => {
    this.chargingDepartmentShareId = value;
    this.chargingDepartmentId = null;
    app.render();
  };

  setExpensesAccountTypeId = (value: number | null) => {
    this.expensesAccountTypeId = value;
    app.render();
  };

  setSelectedOptionLabel = (value: string) => {
    this.selectedOptionLabel = value;
  };

  isProjectExist = () => {
    return !!this.projectId || !!this.projectShareId;
  };

  isChargingDepartmentExist = () => {
    return !!this.chargingDepartmentId || !!this.chargingDepartmentShareId;
  };

  isExpensesAccountTypeExist = () => {
    return !!this.expensesAccountTypeId;
  };

  projectDescription = (projects: ProjectList, projectShares: ProjectShareList) => {
    const project = this.projectId ? projects.find(this.projectId.toString()) : null;
    const projectShare = this.projectShareId ? projectShares.find(this.projectShareId) : null;

    return project?.codeAndName() || projectShare?.codeAndNameOneLine();
  };

  chargingDepartmentDescription = (departments: DepartmentList, departmentShares: ChargingDepartmentShareList) => {
    const department = this.chargingDepartmentId ? departments.find(this.chargingDepartmentId.toString()) : null;
    const departmentShare = this.chargingDepartmentShareId
      ? departmentShares.find(this.chargingDepartmentShareId)
      : null;

    return department?.descriptionWithCode() || departmentShare?.codeAndNameOneLine();
  };

  projectPercentages = () => {
    // プロジェクトの設定がある場合
    if (this.project) return [{ name: this.project.name, percentage: 100 }];
    // プロジェクト按分の設定がある場合
    const percentages = this.projectShare?.percentages();
    if (percentages && percentages.length > 0) {
      return percentages.map(item => ({
        name: item.project.name,
        percentage: item.percentage
      }));
    }
    // プロジェクトの設定がない場合
    return [{ name: '未設定', percentage: 100 }];
  };

  chargingDepartmentPercentages = () => {
    // 費用負担部署の設定がある場合
    if (this.chargingDepartment) return [{ name: this.chargingDepartment.name, percentage: 100 }];
    // 費用負担部署按分の設定がある場合
    const percentages = this.chargingDepartmentShare?.percentages();
    if (percentages && percentages.length > 0) {
      return percentages.map(item => ({
        name: item.department.name,
        percentage: item.percentage
      }));
    }
    // 費用負担部署の設定がない場合
    return [{ name: '未設定', percentage: 100 }];
  };

  expensesAccountTypeDescription = (expensesAccontTypes: ExpensesAccountType[]) => {
    const expensesAccountType = this.expensesAccountTypeId
      ? expensesAccontTypes.find(account => account.id === this.expensesAccountTypeId)
      : null;

    return expensesAccountType?.name;
  };

  submitParams = (): any => {
    return {
      id: this.id,
      traveler_information_id: this.travelerInformationId,
      traveler_id: this.travelerId,
      order_item_id: this.orderItemId,
      order_item_type: this.orderItemType,
      order_item_index: this.orderItemIndex,
      project_id: this.projectId || null,
      project_share_id: this.projectShareId || null,
      charging_department_id: this.chargingDepartmentId || null,
      expenses_account_type_id: this.expensesAccountTypeId || null,
      charging_department_share_id: this.chargingDepartmentShareId || null
    };
  };

  submitParamsWithoutDepartment = (): any => {
    return {
      id: this.id,
      traveler_information_id: this.travelerInformationId,
      traveler_id: this.travelerId,
      order_item_id: this.orderItemId,
      order_item_type: this.orderItemType,
      order_item_index: this.orderItemIndex,
      project_id: this.projectId || null,
      project_share_id: this.projectShareId || null,
      expenses_account_type_id: this.expensesAccountTypeId || null
    };
  };

  submitParamsWithoutProject = (): any => {
    return {
      id: this.id,
      traveler_information_id: this.travelerInformationId,
      traveler_id: this.travelerId,
      order_item_id: this.orderItemId,
      order_item_type: this.orderItemType,
      order_item_index: this.orderItemIndex,
      charging_department_id: this.chargingDepartmentId || null,
      charging_department_share_id: this.chargingDepartmentShareId || null
    };
  };

  static defaultMapping = (): OrderItemMapping => {
    const mapping = new OrderItemMapping(ORDER_ITEM_MAPPING_DEFAULT_ARGS);
    return mapping;
  };
}
