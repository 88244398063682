import React from 'react';
import _ from 'lodash';

import type OtherServiceShareholderInfoList from '../../../../domain/other_service_shareholder_info_list';
import type OtherServiceShareholderInfo from '../../../../domain/other_service_shareholder_info';

interface Props {
  key: number;
  supplierType: number;
  otherServiceShareholderInfos: OtherServiceShareholderInfoList;
  otherServiceShareholderInfo: OtherServiceShareholderInfo;
  editingOtherServiceShareholderInfo: OtherServiceShareholderInfo | null;
  handleCancelClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleEditClick: (
    otherServiceShareholderInfo: OtherServiceShareholderInfo,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  handleDeleteClick: (
    otherServiceShareholderInfo: OtherServiceShareholderInfo,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  handleFieldChange: (
    name: keyof OtherServiceShareholderInfoObj,
    e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>
  ) => void;
}

interface OtherServiceShareholderInfoObj {
  setFrom: string;
  setTo: string;
  setPurchasePriceAna: string;
  setPurchasePriceJal: string;
  setPurchasePriceSfj: string;
}

class OtherServiceShareholderInfoBlock extends React.Component<Props> {
  render() {
    const template = this.props.otherServiceShareholderInfo;
    const editingOtherServiceShareholderInfo = this.props.editingOtherServiceShareholderInfo;
    return (
      <tbody>
        {editingOtherServiceShareholderInfo && template.id === editingOtherServiceShareholderInfo.id ? (
          <tr>
            <td />
            <td>
              <input
                type="date"
                value={editingOtherServiceShareholderInfo.from}
                required
                onChange={e => this.props.handleFieldChange('setFrom', e)}
              />
            </td>
            <td>
              <input
                type="date"
                value={editingOtherServiceShareholderInfo.to}
                onChange={e => this.props.handleFieldChange('setTo', e)}
              />
            </td>
            <td>
              <input
                type="text"
                value={editingOtherServiceShareholderInfo.purchase_price_ana?.toString()}
                onChange={e => this.props.handleFieldChange('setPurchasePriceAna', e)}
              />
            </td>
            <td>
              <input
                type="text"
                value={editingOtherServiceShareholderInfo.purchase_price_jal?.toString()}
                onChange={e => this.props.handleFieldChange('setPurchasePriceJal', e)}
              />
            </td>
            {this.props.supplierType === 1 && (
              <td>
                <input
                  type="text"
                  value={editingOtherServiceShareholderInfo.purchase_price_sfj?.toString()}
                  onChange={e => this.props.handleFieldChange('setPurchasePriceSfj', e)}
                />
              </td>
            )}
            <td className="organization__td">
              <input type="submit" value="保存" />
            </td>
            <td className="organization__td">
              <input type="submit" value="キャンセル" onClick={e => this.props.handleCancelClick(e)} />
            </td>
          </tr>
        ) : editingOtherServiceShareholderInfo && editingOtherServiceShareholderInfo.id ? (
          <tr>
            <td>{template.id}</td>
            <td>{template.from}</td>
            <td>{template.to}</td>
            <td>{template.purchase_price_ana}</td>
            <td>{template.purchase_price_jal}</td>
            {this.props.supplierType === 1 && <td>{template.purchase_price_sfj}</td>}
            <td className="organization__td" />
            <td className="organization__td" />
          </tr>
        ) : (
          <tr>
            <td>{template.id}</td>
            <td>{template.from}</td>
            <td>{template.to}</td>
            <td>{template.purchase_price_ana}</td>
            <td>{template.purchase_price_jal}</td>
            {this.props.supplierType === 1 && <td>{template.purchase_price_sfj}</td>}
            <td className="organization__td">
              <input type="submit" value="編集" onClick={e => this.props.handleEditClick(template, e)} />
            </td>
            <td className="organization__td">
              <input type="submit" value="削除" onClick={e => this.props.handleDeleteClick(template, e)} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}

export default OtherServiceShareholderInfoBlock;
