import _ from 'lodash';
import type { OtherItem } from '../other_item_info';
import OtherItemInfo from '../other_item_info';

type ErrorMap = { [key in keyof Partial<OtherItem>]: string };

class ArrangementRequestOtherItemInfo extends OtherItemInfo {
  errors: ErrorMap[] = [];

  errorMessage(key: keyof OtherItem, i: number) {
    return this.errors[i]?.[key];
  }

  requiredError = (name: string, value: string | undefined, message = 'を入力してください') => {
    return !value || value.length === 0 ? `${name}${message}` : undefined;
  };

  validate() {
    this.errors = this.otherItems.map(otherItem => {
      const errors: ErrorMap = {};

      if (_.isEmpty(otherItem.itemType)) {
        errors.itemType = this.requiredError('商品', otherItem.itemType, 'を選択してください');
      }
      if (otherItem.itemType === 'other' && _.isEmpty(otherItem.itemTypeOther)) {
        errors.itemType = this.requiredError('商品', otherItem.itemTypeOther);
      }

      return errors;
    });

    const errors: string[] = [];
    this.errors.forEach((errs, i) => {
      const prefix = `その他の商品${i > 0 ? i + 1 : ''}：`;
      Object.values(errs).forEach(e => errors.push(`${prefix}${e}`));
    });

    app.render();
    return errors;
  }

  submitParams() {
    return {
      other_item_requests: this.otherItems.map(otherItem => ({
        item_type: otherItem.itemType,
        item_type_other: otherItem.itemTypeOther,
        remarks: otherItem.remarks
      }))
    };
  }
}

export default ArrangementRequestOtherItemInfo;
