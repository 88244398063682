import React, { useCallback, useState } from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { styled } from '@this/src/components/constants/themes';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import type { SearchTrackingElementCollection } from '@this/src/domain/search_tracking_element';
import { SEARCH_TRACKING_ELEMENT_LABELS } from '@this/src/domain/search_tracking_element';

interface Props {
  searchTrackingLogId: number;
  elements: SearchTrackingElementCollection;
}

const SearchTrackingLogElements: React.FC<Props> = ({ searchTrackingLogId: logId, elements }) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = useCallback(
    (_: unknown, newValue: number) => {
      setTab(newValue);
    },
    [setTab]
  );

  return (
    <>
      <Tabs indicatorColor="secondary" value={tab} onChange={handleTabChange}>
        {elements.map((element, i) => (
          <Tab key={i} label={SEARCH_TRACKING_ELEMENT_LABELS[element.type]} {...allyProps(i)} />
        ))}
      </Tabs>
      {elements.map((element, i) => (
        <TabPanel key={i} value={tab} index={i}>
          {element.list.map((item, i) => (
            <div key={i}>
              <div>{item.title}</div>
              <div>
                <Link
                  href={`/arrangement/search_tracking_logs/${logId}/json?es_id=${item.id}`}
                  isExternal
                  target="_blank"
                >
                  {item.id}
                </Link>
              </div>
            </div>
          ))}
        </TabPanel>
      ))}
    </>
  );
};

const allyProps = (index: number) => ({
  id: `element-tab-${index}`,
  'aria-controls': `element-tabpanel-${index}`
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <Panel
    role="tabpanel"
    hidden={value !== index}
    id={`element-tabpanel-${index}`}
    aria-labelledby={`element-tab-${index}`}
  >
    {value === index && <PanelBody>{children}</PanelBody>}
  </Panel>
);

const Panel = styled.div`
  padding: 16px;
`;

const PanelBody = styled.div`
  white-space: pre-wrap;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default SearchTrackingLogElements;
