export interface TripResponse {
  trip: any;
  messages: Message[];
  hotel: any;
  order: any;
  firstTrip?: boolean;
  receiptIssuable: boolean;
  cancel_message: string;
  pusherKey: string;
  pusherChannelPrefix: string;
  serviceId: number;
  enabledChat: boolean;
}

export interface MessagesResponse {
  messages: Message[];
  trip: any;
  order: any;
  receiptIssuable: boolean;
}

export interface Message {
  id: number;
  trip_id: number;
  partner_id?: any;
  body: string;
  created_at: string;
  updated_at: string;
  img_path?: string | null;
  img_type?: any;
  user_read?: boolean;
  show_accept_button?: boolean;
  accepted: boolean;
  arranger_id?: number | null;
  arranger_name?: string | null;
  attach_files?: AttachFile[] | null;
  type: MessageType;
  partner_read?: boolean;
  user_id?: number;
  user_name?: string;
  current?: number;
  previous?: number;
  confirmed_at?: string;
  order_id?: string;
  mark_finished_arranger_name?: string;
  mark_finished_at?: string;
}

export enum MessageType {
  USER = 'user',
  PARTNER = 'partner',
  STATUS_LOG = 'status_log',
  PARTNER_LOG = 'partner_log',
  ORDER_ITEM_BILLED_AT_LOG = 'order_item_billed_at_log'
}

export interface AttachFile {
  file_path: string;
  content_type: string;
  file_name: string;
}
