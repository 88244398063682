import React, { createContext, useContext, useState } from 'react';

interface Props {
  children?: React.ReactNode;
}

const TicketingInstructionUpdatingContext = createContext({
  updating: false,
  setUpdating: (_updating: boolean) => {}
});

const TicketingInstructionUpdatingContextProvider: React.FC<Props> = ({ children }) => {
  const [updating, setUpdating] = useState(false);

  return (
    <TicketingInstructionUpdatingContext.Provider value={{ updating, setUpdating }}>
      {children}
    </TicketingInstructionUpdatingContext.Provider>
  );
};

const withTicketingInstructionUpdatingContext = (Component: React.FC<Props>) => {
  return function () {
    return (
      <TicketingInstructionUpdatingContextProvider>
        <Component />
      </TicketingInstructionUpdatingContextProvider>
    );
  };
};

const useTicketingInstructionUpdatingContext = () => {
  return useContext(TicketingInstructionUpdatingContext);
};

export {
  TicketingInstructionUpdatingContextProvider,
  withTicketingInstructionUpdatingContext,
  useTicketingInstructionUpdatingContext
};
