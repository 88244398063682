import React from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@this/constants/themes';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import { Button } from '@this/shared/ui/inputs/button';

export interface UrlQuery {
  flight_type: string;
  pnr_id: string;
}

export type UrlQueryAction =
  | { type: 'SET_FLIGHT_TYPE'; payload: string }
  | { type: 'SET_PNR_ID'; payload: string };

interface Props {
  urlQuery: UrlQuery;
  dispatch: Dispatch<ReducerAction<Reducer<{ urlQuery: UrlQuery }, UrlQueryAction>>>;
  handleSearch: () => void;
}

export const urlQueryReducer = (state: UrlQuery, action: UrlQueryAction): UrlQuery => {
  switch (action.type) {
    case 'SET_FLIGHT_TYPE':
      return { ...state, flight_type: action.payload };
    case 'SET_PNR_ID':
      return { ...state, pnr_id: action.payload };
    default:
      return state;
  }
};

const getStringParam = (key: string, defaultValue = ''): string => {
  const value = utils.getParam(key);
  return value || defaultValue;
};

export const initialUrlQuery: UrlQuery = {
  pnr_id: getStringParam('pnr_id'),
  flight_type: getStringParam('flight_type', 'domestic_air')
};

const SearchPnrFlightsSearchArea: React.FC<Props> = ({ urlQuery, dispatch, handleSearch }) => {
  const { pnr_id, flight_type } = urlQuery;
  return (
    <SearchArea>
      <SearchBlock>
        <RadioGroup
          layout="horizontal"
          value={flight_type}
          onChange={e => dispatch({ type: 'SET_FLIGHT_TYPE', payload: e.target.value })}
        >
          <Radio key="domestic_air" name="domestic_air" value="domestic_air">
            国内航空券
          </Radio>
          <Radio key="foreign_air" name="foreign_air" value="foreign_air">
            海外航空券
          </Radio>
        </RadioGroup>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>PNR</SearchLabel>
        <Input
          value={pnr_id || ''}
          onChange={e => dispatch({ type: 'SET_PNR_ID', payload: e.target.value })}
          style={{ width: '100px', marginRight: '20px' }}
        />
        <Button onClick={handleSearch} disabled={!pnr_id}>
          検索
        </Button>
      </SearchBlock>
    </SearchArea>
  );
};

const SearchArea = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default SearchPnrFlightsSearchArea;
