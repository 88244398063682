import React from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import A from '@this/components/shared/atoms/a';
import MarketLogHelper from '@this/domain/market_log/market_log_helper';

import type ElementBase from '../../../../../domain/element_base';
import type TransportElement from '../../../../../domain/transport_element';
import type HotelElement from '../../../../../domain/hotel_element';
import type { KeyValue } from '../../../../shared/formatted_key_value/formatted_key_value';
import FormattedKeyValue from '../../../../shared/formatted_key_value/formatted_key_value';

interface Props {
  theme: {
    serviceName: string;
  };
  isFollowingElement: boolean;
  isOrderItem: boolean;
  element: ElementBase;
  price: number;
  tripId: number;
  hotelPriceLimit: number;
  serviceId: number;
}

class Element extends React.Component<Props> {
  getSymbol() {
    if (this.props.element.type === 'transport') {
      const el = this.props.element as TransportElement;
      if (el.isRailwayTicket()) {
        return 'train';
      }
      if (el.isRailway()) {
        return 'shinkansen';
      }
      if (el.isAir()) {
        return 'air';
      }
      if (el.isRentalCar()) {
        return 'rental_car';
      }
      if (el.isBus()) {
        return 'bus';
      }
      return '';
    }
    return this.props.element.type;
  }

  elementInfo(): KeyValue[] {
    let elementList: KeyValue[] = [];
    if (this.props.element.type === 'transport') {
      const el = this.props.element as TransportElement;
      if (el.isAir()) {
        elementList = [
          { key: '便名', value: el.name },
          { key: '経路', value: `${el.from.description()}発 → ${el.to.description()}着` },
          { key: '種別', value: el.airTicketTypeStr() }
        ];
        if (el.isDomesticAir()) {
          elementList.push({ key: '確認番号', value: el.airConfirmationNumber });
        }
      } else if (el.isRailway()) {
        elementList = [
          { key: '便名', value: el.name },
          { key: '経路', value: `${el.from.description()}発 → ${el.to.description()}着` }
        ];
      } else if (el.isRentalCar()) {
        elementList = [
          { key: '車種', value: el.name },
          { key: '経路', value: `${el.from.description()}発 → ${el.to.description()}着` }
        ];
      } else if (el.isBus()) {
        elementList = [
          { key: '便名', value: el.name },
          { key: '経路', value: `${el.from.description()}発 → ${el.to.description()}着` }
        ];
      } else if (el.isTaxi()) {
        elementList = [{ key: '経路', value: `${el.from.description()}発 → ${el.to.description()}着` }];
      }
    }
    if (this.props.element.type === 'hotel') {
      const el = this.props.element as HotelElement;
      elementList = [
        { key: '施設名', value: el.name },
        { key: 'タイプ', value: el.bedType },
        { key: 'ルーム', value: el.roomType },
        { key: '宿泊数', value: `${el.stayDays()}泊` },
        { key: '部屋数', value: `${el.roomNum}室` }
      ];
    }

    if (elementList.length > 0) {
      if (this.props.price !== 0) {
        const non_order_item_text = !this.props.isOrderItem ? '（個人手配）' : '';
        elementList.push({
          key: '料金（合計）',
          value: utils.formatPrice(this.props.price) + non_order_item_text
        });
      }
      if (this.props.element.type === 'hotel' && this.props.hotelPriceLimit) {
        elementList.push({ key: '規程料金', value: utils.formatPrice(this.props.hotelPriceLimit) });
      }
    }
    return elementList;
  }

  marketLogPath() {
    const { element, tripId } = this.props;
    return MarketLogHelper.elementMarketLogPath(element, tripId);
  }

  marketLogClass() {
    if (this.showMarketLogLink()) {
      const el = this.props.element as HotelElement;
      return el.marketlogAvailable ? '' : 'disabled';
    }
    return '';
  }

  showMarketLogLink(): boolean {
    const { element, theme } = this.props;
    return MarketLogHelper.isElementMarketLogLinkAvailable(element, theme.serviceName);
  }

  render() {
    try {
      const { isFollowingElement, isOrderItem, element } = this.props;
      return (
        <div className="organization-trips-detail__detail-panel__item-block">
          <div className="organization-trips-detail__detail-panel__item-block__header">
            <div
              className={`organization-trips-detail__detail-panel__item-block__header__icon icon--${this.getSymbol()}`}
            />
            <div className="organization-trips-detail__detail-panel__item-block__header__date">
              {element.startDate() ? element.startDate().format('YYYY/MM/DD') : ''}
            </div>
          </div>
          <BlockBody>
            <div className="organization-trips-detail__detail-panel__item-block__body__content">
              <FormattedKeyValue data={this.elementInfo()} />
              {this.showMarketLogLink() && isOrderItem && !isFollowingElement && (
                <A
                  href={this.marketLogPath()}
                  className={`organization-trips-detail__detail-panel__item-block__market-log-button ${this.marketLogClass()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  マーケットログを確認
                  <img
                    className={`organization-trips-detail__detail-panel__item-block__market-log-link ${this.marketLogClass()}`}
                    src="/images/external_link_icon.png"
                    alt="link"
                  />
                </A>
              )}
            </div>
          </BlockBody>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const BlockBody = styled.div`
  margin-left: 8px;
  padding: 10px 0 10px 20px;
  border-left: solid 4px ${props => props.theme.linkColor};
`;

export default withTheme(Element);
