import _ from 'lodash';
import { observable, action } from 'mobx';
import type { ChargingDepartmentShareArgs } from './charging_department_share';
import ChargingDepartmentShare from './charging_department_share';

class ChargingDepartmentShareList {
  @observable
  public list: ChargingDepartmentShare[];

  constructor(raw: ChargingDepartmentShareArgs[]) {
    this.list = raw.map(o => new ChargingDepartmentShare(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  last() {
    return _.last(this.list);
  }

  @action
  push(o: ChargingDepartmentShare) {
    this.list.push(o);
  }

  @action
  remove(o: ChargingDepartmentShare) {
    const i = this.list.findIndex(item => item.id === o.id);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  @action
  update(newOrg: ChargingDepartmentShare) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }
}

export default ChargingDepartmentShareList;
