import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { MidButton } from '@this/components/shared/atoms/button';
import Link from '@this/components/shared/atoms/link';
import ContentBody from '@this/components/shared/atoms/content_body';

import Tooltip from '@this/shared/tooltip/tooltip';
import AlertArea from '@this/components/reserve_trip/select/alert_area/alert_area';
import type SearchQuery from '../../../domain/search_query';
import type SearchQueryItem from '../../../domain/search_query_item';
import type SelectRepository from '../../../domain/select_repository';
import type { SelectType, Airline } from '../../../domain/select_store';
import type SelectStore from '../../../domain/select_store';
import type User from '../../../domain/user/user';

import SelectDefaultArea from './default_area/default_area';
import SelectListArea from './list_area/list_area';
import SelectSearchArea from './search_area/search_area';
import SelectOutlineWrapperTemplate from './outline_wrapper/outline_wrapper.template';
import SelectOutlineWrapperTop from './outline_wrapper/outline_wrapper.top';

import ArrangementRequestForm from './arrangement_request_form/arrangement_request_form';
import Modal from '../../shared/modal/modal';

export interface SelectPcTemplateProps {
  // Select.props
  query: SearchQuery;
  store: SelectStore;
  repository: SelectRepository;
  selectErrors: string[];
  submitting: boolean;
  showArrangementRequestForm: boolean;
  serviceId: number;
  user: User | null;
  availableOptions: string[];
  showSearchBox: boolean;

  // Select.methods
  handleSelectType: (type: SelectType) => () => void;
  handleAirline: (airline: Airline) => () => void;
  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;

  handleSelectItem: () => void;
  handleReSearch: (query: SearchQuery) => void;

  handleAddItem: (item_type: 'transport' | 'hotel', e: React.MouseEvent<HTMLElement>) => void;
  handleAdditionalSearch: (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => void;
  additionalSearchErrors?: { [k in string]: string };
  handleRemoveItem: () => void;

  handleToggleArrangementRequestForm: () => void;
  toggleShowSearchBox: () => void;
  handleOpenShowSearchBox: () => void;
}

const SelectPcTemplate: React.SFC<SelectPcTemplateProps> = ({
  query,
  store,
  repository,
  selectErrors,
  submitting,
  showArrangementRequestForm,
  serviceId,
  user,
  availableOptions,
  showSearchBox,

  handleSelectType,
  handleAirline,
  handleNextButtonClick,

  handleSelectItem,
  handleReSearch,

  handleAddItem,
  handleAdditionalSearch,
  additionalSearchErrors,
  handleRemoveItem,

  handleToggleArrangementRequestForm,
  toggleShowSearchBox,
  handleOpenShowSearchBox
}) => (
  <SelectPc className="select-pc">
    <Wrap>
      <Body>
        <BodyIn>
          <SelectSearchArea
            query={query}
            user={user}
            trip={store.result.getReservingTrip()}
            selectErrors={selectErrors}
            submitting={submitting}
            store={store}
            handleNextButtonClick={handleNextButtonClick}
            handleReSearch={handleReSearch}
            availableOptions={availableOptions}
          />
          <AlertArea result={store.result} />
          <Content>
            <Left>
              <SelectOutlineWrapperTop
                store={store}
                serviceId={serviceId}
                handleSelectType={handleSelectType}
                handleAirline={handleAirline}
                width={100}
              />
              <SelectOutlineWrapperTemplate
                query={query}
                store={store}
                selectErrors={selectErrors}
                packageErrors={store.packageErrors[store.result.airline]}
                handleSelectItem={handleSelectItem}
                handleAddItem={handleAddItem}
                handleRemoveItem={handleRemoveItem}
                handleAdditionalSearch={handleAdditionalSearch}
                additionalSearchErrors={additionalSearchErrors}
                handleNextButtonClick={handleNextButtonClick}
                handleOpenShowSearchBox={handleOpenShowSearchBox}
                submitting={submitting}
              />
              {utils.dig(store, 'user', 'organization', 'arrangement_request_enable?') ? (
                <RequestWrapper>
                  <ReserveButton
                    className={`select-pc__reserve-button ${submitting ? 'loading' : ''}`}
                    onClick={handleNextButtonClick}
                  >
                    予約確認画面へ
                  </ReserveButton>
                  <RequestText>
                    <p>
                      お探しの結果が見つからない場合は
                      <AText onClick={handleToggleArrangementRequestForm}>リクエストフォーム</AText>
                      からご依頼ください
                    </p>
                    <Tooltip>
                      <p>
                        ご希望の検索結果が出てこない場合は、こちらのフォームからご依頼ください
                        <br />
                        <span data-wovn-ignore>{`（手数料: ${utils.dig(
                          store,
                          'user',
                          'organization',
                          'arrangement_request_setting',
                          'fee'
                        )}）`}</span>
                        円
                      </p>
                    </Tooltip>
                  </RequestText>
                </RequestWrapper>
              ) : null}
            </Left>
            <Right>
              <SelectDefaultArea
                isOrganization
                show={store.currentTab === 'none'}
                serviceId={serviceId}
                sp={false}
              />
              {store.result.items.map((item, i) => (
                <SelectListArea
                  key={item.index}
                  icon={String(i + 1)}
                  repository={repository}
                  store={store}
                  listWrapper={item.elementList}
                  type={item.elementType()}
                  subtype={item.flightIndex}
                  queryItem={query.items[i]}
                  resultItem={item}
                  query={query}
                  serviceId={serviceId}
                  show={store.result.type === 'separate' && store.currentTab === item.index}
                  showSearchBox={showSearchBox}
                  toggleShowSearchBox={toggleShowSearchBox}
                />
              ))}
              {store.result.packageItems[store.result.airline].map((item, i) => (
                <SelectListArea
                  key={item.index}
                  icon={String(i + 1)}
                  repository={repository}
                  store={store}
                  listWrapper={item.elementList}
                  type={item.elementType()}
                  subtype={item.flightIndex}
                  queryItem={query.items[i]}
                  resultItem={item}
                  query={query}
                  serviceId={serviceId}
                  show={store.result.type === 'airPackage' && store.currentTab === item.index}
                  direction={i === 0 ? 'out' : i === 2 ? 'home' : undefined}
                  showSearchBox={showSearchBox}
                  toggleShowSearchBox={toggleShowSearchBox}
                />
              ))}
              {store.result.jrPackageItems.map((item, i) => (
                <SelectListArea
                  key={item.index}
                  icon={String(i + 1)}
                  repository={repository}
                  store={store}
                  listWrapper={item.elementList}
                  type={item.elementType()}
                  subtype={item.flightIndex}
                  queryItem={query.items[i]}
                  resultItem={item}
                  query={query}
                  serviceId={serviceId}
                  show={store.result.type === 'jrPackage' && store.currentTab === item.index}
                  showSearchBox={showSearchBox}
                  toggleShowSearchBox={toggleShowSearchBox}
                />
              ))}
            </Right>
          </Content>
        </BodyIn>
      </Body>
      {showArrangementRequestForm && (
        <Modal show hideModal={handleToggleArrangementRequestForm} title="リクエストフォーム">
          <ArrangementRequestForm
            serviceId={serviceId}
            query={query}
            store={store}
            selectErrors={selectErrors}
            handleSelectItem={handleSelectItem}
            handleAdditionalSearch={handleAdditionalSearch}
            additionalSearchErrors={additionalSearchErrors}
            handleNextButtonClick={handleNextButtonClick}
            handleOpenShowSearchBox={handleOpenShowSearchBox}
            reserveRequestMailNotificationType={
              store.user?.organization?.reserve_request_mail_notification_type ?? 'togglable'
            }
            shortdistance={false}
          />
        </Modal>
      )}
    </Wrap>
  </SelectPc>
);

const SelectPc = styled.div`
  display: flex;
`;

const Wrap = styled.div`
  display: flex;
  flex-grow: 99999;
  flex-direction: column;
  width: 100%;
  min-height: 0%;
`;

const Body = styled(ContentBody)`
  display: flex;
  flex-direction: column;
  width: 1150px;
  padding: 0 20px;
`;

const BodyIn = styled.div`
  display: flex;
  flex-grow: 99999;
  flex-direction: column;
  min-height: 0%;
  background: #fff;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 99999;
  min-height: 0%;
  padding-top: 20px;
`;

const Left = styled.div`
  flex-basis: 360px;
  flex-grow: 10;
`;

const RequestText = styled.div`
  display: flex;
  padding: 10px;

  .tooltip__content {
    right: -60px;
  }
`;

const AText = styled.a`
  border-bottom: 1px solid;
  ${Link}
`;

const Right = styled.div`
  display: flex;
  flex-basis: 360px;
  flex-grow: 10;
  flex-direction: column;
`;

const RequestWrapper = styled.div``;

const ReserveButton = styled.div`
  ${MidButton}
  position: relative;
  width: 214px;
  margin: 0 0 10px 10px;
  ${media.sp`
    display: none;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 50px;
    border: 5px solid transparent;
    border-left: 5px solid #fff;
  }
`;

export default SelectPcTemplate;
