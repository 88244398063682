export interface PrefectureArgs {
  id: number;
  name: string;
}

class Prefecture {
  id: number;

  name: string;

  constructor(args: PrefectureArgs) {
    this.id = args.id;
    this.name = args.name;
  }
}

export default Prefecture;
