import type { Themes } from '@this/constants/themes';
import { styled } from '@this/constants/themes';
import type { ThemeProps } from 'styled-components';
import { ButtonBase } from '@this/shared/atoms/button';
import { Link } from 'react-router-dom';

/**
 * 組織管理画面共通で使用する定義する StyledComponent
 */

export const OrganizationHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const OrganizationHeaderTitle = styled.h2`
  margin: 0;
`;

export const OrganizationBody = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
`;

export const OrganizationTable = styled.table`
  margin: 8px 0 0 0;
  table-layout: auto;
`;

interface OrganizationThProps extends ThemeProps<Themes> {
  width?: string;
}

export const OrganizationTh = styled.th`
  background: ${(props: OrganizationThProps) => props.theme.grayColorLight};
  font-size: 10px;
  font-weight: normal;
  border-bottom: none;
  border-right: solid 1px white;
  padding: 4px;
  ${(props: OrganizationThProps) => props.width && `width: ${props.width}`}
`;

export const OrganizationTd = styled.td`
  font-size: 12px;
  font-weight: bold;
  padding: 8px 4px;
`;

export const OrganizationTdButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${ButtonBase};
  width: 80px;
  margin: 2px auto;
  padding: 5px 0;
`;

export const AddButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${ButtonBase};
  width: 200px;
  padding: 0.75em 1em;
`;

const biztraPrimaryColor = '#1d7c2d';

const biztraPrimaryTxtColor = '#FFFFFF';

const trBiztraBgColor = 'rgba(241, 237, 229, 0.3)';

const thBiztraBgColor = '#f1ede5';

const thBiztraTxtColor = '#767676';

const tdBiztraTxtColor = '#323232';

const successBiztraColor = '#1D7C2D';

const dangerBiztraColor = '#C72F62';

const grayBiztraColor = '#323232';

export const OrganizationBiztraTable = styled(OrganizationTable)<{ showBorder?: boolean }>`
  margin: 0;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 4px;
  border: ${props => (props.showBorder ? '1px' : '0px')} solid ${thBiztraBgColor};
`;

export const OrganizationBiztraTr = styled.tr`
  &.even {
    background: ${trBiztraBgColor};
  }
`;

export const OrganizationBiztraTd = styled(OrganizationTd)<{ showBorder?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${tdBiztraTxtColor};
  border-bottom: ${props => (props.showBorder ? '1px' : '0px')} solid ${thBiztraBgColor};

  &.green {
    color: ${successBiztraColor};
  }
  &.red {
    color: ${dangerBiztraColor};
  }
  &.gray {
    color: ${grayBiztraColor};
  }
`;

export const OrganizationBiztraAddressTd = styled(OrganizationBiztraTd)`
  font-size: 12px;
  line-height: 14px;
`;

export const OrganizationBiztraTdWithPointer = styled(OrganizationBiztraTd)`
  cursor: pointer;
`;

export const OrganizationBiztraTdEditButton = styled(OrganizationTdButton)`
  border-radius: 6px;
  padding: 7px 0;
`;

export const OrganizationBiztraTdDeleteButton = styled(OrganizationBiztraTdEditButton)`
  background: ${props => props.theme.redColor} !important;
`;

export const OrganizationBiztraTh = styled.th`
  background: ${thBiztraBgColor};
  color: ${thBiztraTxtColor};
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  border-bottom: none;
  padding: 11px 7px;
  ${(props: OrganizationThProps) => props.width && `width: ${props.width}`}
`;

export const OrganizationBiztraButtonTh = styled(OrganizationBiztraTh)`
  width: 100px;
`;

export const OrganizationBiztraAddButton = styled(AddButton)`
  border-radius: 6px;
  margin: 0 0 1em 0.2rem;

  &&& {
    background: ${biztraPrimaryColor};
    &&:focus,
    &&:hover {
      background: ${biztraPrimaryColor};
    }
  }
`;

export const OrganizationLabel = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin: 8px 0;
  overflow-wrap: break-word;
`;

export const OrganizationFormSection = styled.div`
  margin: calc(12 / 1214 * 100%);
`;

export const OrganizationBiztraEditButton = styled.button.attrs(() => ({ type: 'button' }))`
  border-radius: 6px;
  margin-right: 10px;
  background: ${biztraPrimaryColor};
  color: ${biztraPrimaryTxtColor};
  font-size: 1rem;
  padding: 0.625rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${biztraPrimaryColor};
  }
`;

export const OrganizationButtonSection = styled.div`
  display: block;
`;

export const OrganizationAccountTypeButtonSection = styled(OrganizationButtonSection)`
  display: flex;
  -webkit-box-pack: end;
  box-pack: end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  gap: 0px 5px;
`;

export const OrganizationEditModalWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px 80px;
  padding: 20px;
`;

export const OrganizationEditModalErr = styled.p`
  color: red;
`;

export const OrganizationEditModalSelectWrap = styled.div`
  > select {
    width: 100%;
    max-width: 400px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-bottom: 0.75em
    padding: 0 2px;
    &&&:focus {
      box-shadow: none;
      border: 1px solid #ae985e;
    }
  }
`;

export const BulkActionLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
`;
