import { observable } from 'mobx';
import type { KnowledgeResponseArgs } from './knowledge';
import Knowledge, { convertKnowledgeResponseToArgs } from './knowledge';

export type KnowledgeSubCategoryResponseArgs = {
  id: number;
  sf_name: string;
  knowledges?: KnowledgeResponseArgs[];
  knowledges_count: number;
};

export type KnowledgeSubCategoryArgs = {
  id: number;
  sfName: string;
  knowledges?: Knowledge[];
  knowledgesCount: number;
};

export const convertKnowledgeSubCategoryResponseToArgs = (
  response: KnowledgeSubCategoryResponseArgs
): KnowledgeSubCategoryArgs => {
  const args: KnowledgeSubCategoryArgs = {
    id: response.id,
    sfName: response.sf_name,
    knowledges: response.knowledges
      ? response.knowledges.map(raw => new Knowledge(convertKnowledgeResponseToArgs(raw)))
      : undefined,
    knowledgesCount: response.knowledges_count
  };
  return args;
};

export default class KnowledgeSubCategory {
  @observable id: number;

  @observable sfName: string;

  @observable knowledges?: Knowledge[];

  @observable knowledgesCount: number;

  constructor(args: KnowledgeSubCategoryArgs) {
    this.id = args.id;
    this.sfName = args.sfName;
    this.knowledges = args.knowledges;
    this.knowledgesCount = args.knowledgesCount;
  }
}
