import React from 'react';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import type Trip from '@this/domain/trip/trip';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import { MessageBox, MessageCenter, MessageLeft } from './partner_message';
import VirtualCounterMessageBody from './body';

interface Props {
  message: Message;
  selectedTrip: Trip;
  fetchMessages: (trip: Trip) => void;
  handleMessageEdit: (messageId: number, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const UserMessage: React.FC<Props> = ({ message, selectedTrip, fetchMessages, handleMessageEdit }) => {
  return (
    <UserMessageWrap>
      <MessageLeft>
        <UserIcon data-testid="userMessageIcon" />
        <MessageTime>
          {moment(message.created_at).format('MM/DD')}
          <br />
          {moment(message.created_at).format('HH:mm')}
        </MessageTime>
      </MessageLeft>
      <MessageCenter>
        {message.user_name && <USerName>{message.user_name}</USerName>}
        <UserMessageBox>
          <VirtualCounterMessageBody
            message={message}
            selectedTrip={selectedTrip}
            fetchMessages={fetchMessages}
            handleMessageEdit={handleMessageEdit}
          />
        </UserMessageBox>
        {message.mark_finished_arranger_name && (
          <div>
            {`${message.mark_finished_arranger_name}がこのメッセージを対応不要にしました。（${moment(
              message.mark_finished_at
            ).format('MM/DD HH:mm')}）`}
          </div>
        )}
      </MessageCenter>
      <MessageRight />
    </UserMessageWrap>
  );
};

const UserMessageWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-right: auto;
`;

const UserMessageBox = styled(MessageBox)`
  background: ${props => props.theme.grayColorLight};
`;

const USerName = styled.div`
  margin-bottom: 5px;
`;

const UserIcon = styled.div`
  margin-bottom: 7px;
  padding: 30%;
  background: url('/images/user_icon.png') center center / 55px auto no-repeat;
  width: 60px;
  height: 55px;
`;

const MessageTime = styled.div`
  line-height: 1.1;
`;

const MessageRight = styled.div`
  flex-shrink: 0;
  width: 60px;
  text-align: center;
  margin-left: 10px;
`;

export default UserMessage;
