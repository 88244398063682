import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';

export class InAdvanceMessageTemplate {
  id = 0;

  message = '';

  createdAt: Moment;

  updatedAt: Moment;

  constructor(args?: InAdvanceMessageTemplateArgs) {
    if (args) {
      this.id = args.id;
      this.message = args.message;
      this.createdAt = moment(args.created_at);
      this.updatedAt = moment(args.updated_at);
    } else {
      this.createdAt = moment();
      this.updatedAt = moment();
    }
  }

  setMessage(message: string) {
    this.message = message;
    app.render();
  }

  submitParams() {
    return { message: this.message };
  }
}

export interface InAdvanceMessageTemplateArgs {
  id: number;

  message: string;

  created_at: string;

  updated_at: string;
}
