/**
 * tackingのためのUtilです
 */

export const trackEvent = (name: string, properties = {}) => {
  if (typeof mixpanel === 'undefined') {
    return;
  }

  mixpanel.track(`[web]${name}`, properties);
};

export const trackView = (view: string) => trackEvent(`[view] ${view}`);

export const trackClick = (name: string) => trackEvent(`${name} clicked`);
