/* eslint-disable max-lines */
import React from 'react';
import { observer } from 'mobx-react';
import { css } from 'styled-components';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import Organization from '@this/domain/organization/organization2';
import { isWithinSixMonth, isOverDate } from '@this/components/shared/checker/date_checker';
import { isIncludeHyphen } from '@this/components/shared/checker/hyphen_checker';
import SelectNationalities from '@this/shared/nationality/select_nationalities';
import _ from 'lodash';
import Context from '@this/components/core_app/core_app_context';
import { Box } from '@material-ui/core';
import { Button } from '@this/shared/ui/inputs/button';
import { getColor } from '@this/shared/ui/theme';
import { Select } from '@this/shared/ui/inputs/select';
import moment from 'moment/moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { EditRegistrationForm } from './edit_registration_form';

interface Props {}

interface State {
  user: User | null;
  organization: Organization | null;
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
  editNotice: string | null;
  submitting: boolean;
  editErrors: string[];
  showEditRegistration: boolean;
  registrationSubmitting: boolean;
  editRegistrationErrors: string[];
  freeeAuthUri: string;
  freeeWorkflow: boolean;
  restrictionDistanceAndTimeAvailability: boolean;
  birthdayYear: string;
  birthdayMonth: string;
  birthdayDay: string;
  exicPasswordType: string;
}

const DEFAULT_BIRTHDAYYEAR = '2000' as const;

@observer
class UserEdit extends React.Component<Props, State> {
  static contextType = Context;

  context!: React.ContextType<typeof Context>;

  constructor(props: Props) {
    super(props);
    this.state = {
      user: null,
      organization: null,
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      editNotice: null,
      submitting: false,
      editErrors: [],
      showEditRegistration: false,
      registrationSubmitting: false,
      editRegistrationErrors: [],
      freeeAuthUri: '',
      freeeWorkflow: false,
      restrictionDistanceAndTimeAvailability: false,
      birthdayYear: DEFAULT_BIRTHDAYYEAR,
      birthdayMonth: '',
      birthdayDay: '',
      exicPasswordType: 'password'
    };
    this.handleNationalityIdChange = this.handleNationalityIdChange.bind(this);
  }

  componentDidMount() {
    utils
      .jsonPromise<UserJson>('/users.json')
      .then(
        response => {
          const user = new User(response);
          const organization = new Organization(user.organization);

          // birthday未設定の時は空文字
          const year = user.birthday === '' ? DEFAULT_BIRTHDAYYEAR : user.birthday.match(/^\d{4}/)![0];
          const month = user.birthday === '' ? '' : `${parseInt(user.birthday.match(/-(\d{2})-/)![1], 10)}`;
          const day = user.birthday === '' ? '' : `${parseInt(user.birthday.match(/(\d{2})$/)![1], 10)}`;

          this.setState({
            user,
            organization,
            freeeAuthUri: response.freee_auth_uri,
            freeeWorkflow: response.freee_workflow,
            restrictionDistanceAndTimeAvailability: response.restriction_distance_and_time_availability,
            birthdayYear: year,
            birthdayMonth: month,
            birthdayDay: day
          });
        },
        () => {
          this.setState({
            user: null
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  showEditRegistrationForm = () => {
    this.setState({ showEditRegistration: true });
  };

  hideEditRegistrationForm = () => {
    this.setState({
      showEditRegistration: false,
      editRegistrationErrors: []
    });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.isValidFormContent()) {
      return;
    }
    this.setState(
      {
        submitting: true,
        editErrors: [],
        editNotice: null
      },
      this.updateAccount
    );
  };

  /* formの内容が正しければtrueを返す */
  isValidFormContent() {
    const invalidErrors = [];
    if (this.state.user && isIncludeHyphen(this.state.user.tel)) {
      invalidErrors.push('電話番号はハイフンを含めず入力してください');
    }
    if (!this.isValidBirthday(this.state.birthdayYear, this.state.birthdayMonth, this.state.birthdayDay)) {
      invalidErrors.push('生年月日は正しい形式で入力してください');
    }
    if (this.state.organization?.showEx && this.state.user?.exicId && !/^\d{10}$/.test(this.state.user.exicId)) {
      invalidErrors.push('EXIC会員IDは半角数字10桁で入力してください');
    }
    if (invalidErrors.length > 0) {
      this.setState({
        editErrors: invalidErrors,
        editNotice: null
      });
      return false;
    }
    return true;
  }

  isValidBirthday = (year: string, month: string, day: string) => {
    if (year && month && day) {
      const mo = moment().set({ year: Number(year), month: Number(month) - 1, date: Number(day) });
      return mo.isValid();
    }
    return false;
  };

  private handleYearChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ birthdayYear: e.target.value });

    const year = e.target.value;
    const month = this.state.birthdayMonth;
    const day = this.state.birthdayDay;
    if (this.isValidBirthday(year, month, day)) {
      const mo = moment().set({ year: Number(year), month: Number(month) - 1, date: Number(day) });
      this.state.user?.setBirthdayMoment(mo);
    }
  }

  private handleMonthChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ birthdayMonth: e.target.value });

    const year = this.state.birthdayYear;
    const month = e.target.value;
    const day = this.state.birthdayDay;
    if (this.isValidBirthday(year, month, day)) {
      const mo = moment().set({ year: Number(year), month: Number(month) - 1, date: Number(day) });
      this.state.user?.setBirthdayMoment(mo);
    }
  }

  private handleDateChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ birthdayDay: e.target.value });

    const year = this.state.birthdayYear;
    const month = this.state.birthdayMonth;
    const day = e.target.value;
    if (this.isValidBirthday(year, month, day)) {
      const mo = moment().set({ year: Number(year), month: Number(month) - 1, date: Number(day) });
      this.state.user?.setBirthdayMoment(mo);
    }
  }

  fetchFreeeAuthUri() {
    utils.jsonPromise<UserJson>('/organization/freee/auth_uri.json').then(response => {
      this.setState({
        freeeAuthUri: response.freee_auth_uri
      });
    });
  }

  disconnectFreee() {
    utils.jsonPromise('/organization/freee/member_tokens', {}, 'DELETE').then(() => {
      this.fetchFreeeAuthUri();
    });
  }

  async updateAccount() {
    const params: { [key: string]: any } = {
      'user[user_id]': this.state.user!.id,
      'user[last_name]': this.state.user!.lastName,
      'user[first_name]': this.state.user!.firstName,
      'user[last_name_roman]': this.state.user!.lastNameRoman,
      'user[first_name_roman]': this.state.user!.firstNameRoman,
      'user[last_name_kana]': this.state.user!.lastNameKana,
      'user[first_name_kana]': this.state.user!.firstNameKana,
      'user[birthday]': this.state.user!.birthday,
      'user[nationality_id]': this.state.user!.nationalityId,
      'user[gender]': this.state.user!.gender,
      'user[tel]': this.state.user!.tel,
      'user[admin_notification]': this.state.user!.adminNotification,
      'user[exic_id]': this.state.user!.exicId,
      'user[exic_password]': this.state.user!.exicPassword,
      'user[passport_number]': this.state.user!.passportNumber,
      'user[passport_expire]': this.state.user!.passportExpire,
      'organization[organization_name]': this.state.organization!.name,
      'organization[url]': this.state.organization!.url,
      'organization[address]': this.state.organization!.address,
      'organization[organization_tel]': this.state.organization!.tel
    };
    this.state.user!.mileageNumbers.forEach((mileageNumber, i) => {
      if (mileageNumber.number) {
        params[`user[mileage_numbers][${i}]`] = mileageNumber;
      }
    });
    this.state.user!.userForwardedEmails.forEach((userForwardedEmail, i) => {
      if (userForwardedEmail.email) {
        params[`user[user_forwarded_emails][${i}]`] = userForwardedEmail.params();
      }
    });
    try {
      await utils.jsonPromise('/users/update_account.json', params, 'PUT');
      this.setState({
        editErrors: [],
        editNotice: '変更を保存しました',
        submitting: false
      });
    } catch (e) {
      if (e.status === 400) {
        this.setState({
          editErrors: e.responseJSON.errors,
          submitting: false
        });
      } else {
        this.setState({
          editErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'],
          submitting: false
        });
        utils.sendErrorObject(e);
      }
    }
  }

  handleChangeCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentPassword: e.target.value });
  };

  handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  handleChangePasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordConfirmation: e.target.value });
  };

  handleUpdateRegistrationSubmit = () => {
    this.setState(
      {
        registrationSubmitting: true,
        editRegistrationErrors: [],
        editNotice: null
      },
      this.updateRegistration
    );
  };

  handleNationalityIdChange(id: number) {
    this.state.user!.setNationalityId(id);
  }

  handleExicPasswordType = () => {
    if (this.state.exicPasswordType === 'password') {
      this.setState({ exicPasswordType: 'text' });
      return;
    }
    this.setState({ exicPasswordType: 'password' });
  };

  async updateRegistration() {
    const params = {
      'user[user_id]': this.state.user!.id,
      'user[current_password]': this.state.currentPassword,
      'user[password]': this.state.password,
      'user[password_confirmation]': this.state.passwordConfirmation
    };
    try {
      await utils.jsonPromise('/users', params, 'PUT');
      this.setState({
        editNotice: 'パスワードを変更しました',
        editErrors: [],
        registrationSubmitting: false,
        showEditRegistration: false
      });
    } catch (e) {
      if (e.status === 400) {
        this.setState({
          editRegistrationErrors: e.responseJSON.errors,
          registrationSubmitting: false
        });
      } else {
        this.setState({
          editRegistrationErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'],
          registrationSubmitting: false
        });
        utils.sendErrorObject(e);
      }
    }
  }

  render() {
    try {
      const {
        user,
        organization,
        editNotice,
        editErrors,
        submitting,
        showEditRegistration,
        currentPassword,
        password,
        passwordConfirmation,
        editRegistrationErrors,
        registrationSubmitting,
        freeeAuthUri,
        freeeWorkflow,
        restrictionDistanceAndTimeAvailability,
        birthdayYear,
        birthdayMonth,
        birthdayDay,
        exicPasswordType
      } = this.state;
      const carries = ['ANA', 'JAL', 'SFJ', 'ADO', 'SNA', 'AXM', 'その他'];
      const dateOfMonth = user?.birthdayMoment?.daysInMonth() || 31;
      return (
        <Wrap>
          <div className="content-body">
            {user && (
              <form onSubmit={this.handleSubmit}>
                <Section>
                  <LeftLabel>氏名</LeftLabel>
                  <SubLabel htmlFor="lastName">氏</SubLabel>
                  <InputSplit
                    type="text"
                    id="lastName"
                    placeholder="例） 山田"
                    value={user.lastName}
                    onChange={e => user.setLastName(e.target.value)}
                  />
                  <SubLabel htmlFor="firstName">名</SubLabel>
                  <InputSplit
                    type="text"
                    id="firstName"
                    placeholder="例） 一郎"
                    value={user.firstName}
                    onChange={e => user.setFirstName(e.target.value)}
                  />
                </Section>
                <Section>
                  <LeftLabel>氏名ローマ字</LeftLabel>
                  <SubLabel htmlFor="lastNameRoman">氏</SubLabel>
                  <InputSplit
                    type="text"
                    id="lastNameRoman"
                    placeholder="例） Yamada"
                    value={user.lastNameRoman}
                    onChange={e => user.setLastNameRoman(e.target.value)}
                  />
                  <SubLabel htmlFor="firstNameRoman">名</SubLabel>
                  <InputSplit
                    type="text"
                    id="firstNameRoman"
                    placeholder="例） Ichiro"
                    value={user.firstNameRoman}
                    onChange={e => user.setFirstNameRoman(e.target.value)}
                  />
                </Section>
                <Section>
                  <LeftLabel>氏名カナ</LeftLabel>
                  <SubLabel htmlFor="lastNameKana">氏</SubLabel>
                  <InputSplit
                    type="text"
                    id="lastNameKana"
                    placeholder="例） ヤマダ"
                    value={user.lastNameKana}
                    onChange={e => user.setLastNameKana(e.target.value)}
                  />
                  <SubLabel htmlFor="firstNameKana">名</SubLabel>
                  <InputSplit
                    type="text"
                    id="firstNameKana"
                    placeholder="例） イチロウ"
                    value={user.firstNameKana}
                    onChange={e => user.setFirstNameKana(e.target.value)}
                  />
                </Section>
                <Field>
                  <LeftLabel>生年月日</LeftLabel>
                  <Select
                    value={birthdayYear}
                    onChange={e => {
                      this.handleYearChange(e);
                    }}
                    style={{ marginRight: '5px' }}
                  >
                    <option value="">-</option>
                    {_.range(1900, moment().year() + 2).map(y => (
                      <option value={y} key={y}>
                        {y}年
                      </option>
                    ))}
                  </Select>
                  <Select
                    value={birthdayMonth}
                    onChange={e => {
                      this.handleMonthChange(e);
                    }}
                    style={{ marginRight: '5px' }}
                  >
                    <option value="">-</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (
                      <option value={m} key={m}>
                        {m}月
                      </option>
                    ))}
                  </Select>
                  <Select
                    value={birthdayDay}
                    onChange={e => {
                      this.handleDateChange(e);
                    }}
                  >
                    <option value="">-</option>
                    {[...Array(dateOfMonth)]
                      .map((u, i) => i + 1)
                      .map(d => (
                        <option value={d} key={d}>
                          {d}日
                        </option>
                      ))}
                  </Select>
                  {this.isValidBirthday(birthdayYear, birthdayMonth, birthdayDay) || (
                    <div className="error">日付を設定してください</div>
                  )}
                </Field>
                <Section>
                  <LeftLabel>国籍</LeftLabel>
                  <SelectNationalities
                    nationalities={user.nationalities}
                    selectedId={user.nationalityId}
                    onSelect={this.handleNationalityIdChange}
                  />
                </Section>
                <Section>
                  <LeftLabel>性別</LeftLabel>
                  <label>
                    <input
                      type="radio"
                      value="male"
                      checked={user.gender === 'male'}
                      onChange={() => user.setGender('male')}
                    />
                    <span>男</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="female"
                      checked={user.gender === 'female'}
                      onChange={() => user.setGender('female')}
                    />
                    <span>女</span>
                  </label>
                </Section>
                {restrictionDistanceAndTimeAvailability && (
                  <Section>
                    <LeftLabel>拠点</LeftLabel>
                    {user.organizationBase?.name}
                  </Section>
                )}
                <Field>
                  <LeftLabel htmlFor="tel">電話番号</LeftLabel>
                  <Input
                    type="text"
                    id="tel"
                    placeholder="例） 09011112222"
                    value={user.tel}
                    onChange={e => user.setTel(e.target.value)}
                  />
                  {isIncludeHyphen(user.tel) && <div className="error">ハイフンを含めず入力してください</div>}
                </Field>
                {!user.organization?.setting?.disable_show_milage_number && (
                  <Section>
                    <LeftLabel>マイレージ</LeftLabel>
                    <MileageItems>
                      {user.mileageNumbers.map((mileageNumber, i) => (
                        <MileageItem key={i}>
                          <select
                            value={mileageNumber.carrier}
                            onChange={e => user.setMilageCarrier(i, e.target.value)}
                          >
                            {carries.map((c, i) => (
                              <option key={i} value={c}>
                                {c}
                              </option>
                            ))}
                          </select>
                          <input
                            type="text"
                            id="mileage"
                            className="user-signin__input"
                            value={mileageNumber.number}
                            onChange={e => user.setMilageNumber(i, e.target.value)}
                            autoComplete="new-password"
                          />
                          <A
                            className="user-edit__section__mileage-items__remove"
                            onClick={() => user.removeMileageNumber(i)}
                          >
                            <img src="/images/close_search.png" />
                          </A>
                        </MileageItem>
                      ))}
                      <div className="user-edit__section__mileage-items__add">
                        <A onClick={() => user.addMileageNumber()}>+マイレージを追加</A>
                      </div>
                    </MileageItems>
                  </Section>
                )}
                {(user.express_outsourcing_availability || organization?.showEx) && (
                  <>
                    <Section>
                      <LeftLabel htmlFor="exicId">EXIC会員ID</LeftLabel>
                      <Input
                        type="text"
                        id="exicId"
                        placeholder="半角数字10桁"
                        value={user.exicId}
                        onChange={e => user.setExicId(e.target.value)}
                      />
                    </Section>
                    <Section>
                      <LeftLabel htmlFor="exicPassword">EXICパスワード</LeftLabel>
                      <Input
                        type={exicPasswordType}
                        id="exicPassword"
                        placeholder="半角数字記号4-8桁"
                        value={user.exicPassword}
                        onChange={e => user.setExicPassword(e.target.value)}
                        autoComplete="new-password"
                      />
                      <span onClick={this.handleExicPasswordType}>
                        {exicPasswordType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </span>
                    </Section>
                  </>
                )}
                <Section>
                  <LeftLabel htmlFor="passportNumber">パスポート番号</LeftLabel>
                  <Input
                    type="text"
                    id="passportNumber"
                    placeholder="例）AA1234567"
                    value={user.passportNumber}
                    onChange={e => user.setPassportNumber(e.target.value)}
                  />
                </Section>
                <Field>
                  <LeftLabel htmlFor="passportExpire">有効期限</LeftLabel>
                  <Input
                    type="text"
                    id="passportExpire"
                    placeholder="例）2025-01-01"
                    value={user.passportExpire || ''}
                    onChange={e => user.setPassportExpire(e.target.value)}
                  />
                  {isWithinSixMonth(user.passportExpire) && (
                    <div className="error">
                      有効期限が近づいてきています。
                      <br />
                      パスポートをご確認ください。
                    </div>
                  )}
                  {isOverDate(user.passportExpire) && (
                    <div className="error">
                      有効期限が過ぎています。
                      <br />
                      パスポートをご確認ください。
                    </div>
                  )}
                </Field>
                {organization?.setting?.sendUserForwardedEmail && (
                  <Field>
                    <LeftLabel htmlFor="user_forwarded_email">転送アドレス</LeftLabel>
                    {_.map(user.userForwardedEmails, (e, i) => (
                      <Input
                        key={`user_forwarded_email_${i}`}
                        type="text"
                        id="user_forwarded_email"
                        value={e.email}
                        placeholder="user@example.jp"
                        onChange={e => user.setUserForwardedEmails(e.target.value, i)}
                      />
                    ))}
                  </Field>
                )}
                {freeeWorkflow && (
                  <Field>
                    <LeftLabel htmlFor="freeeConnect">freee連携</LeftLabel>
                    {freeeAuthUri ? (
                      <Button isExternal href={freeeAuthUri} size="small">
                        freeeと接続する
                      </Button>
                    ) : (
                      <>
                        <Flex>連携中</Flex>
                        <Button onClick={() => this.disconnectFreee()} size="small">
                          連携解除
                        </Button>
                      </>
                    )}
                  </Field>
                )}
                {user.useSlackApp && (
                  <Field>
                    <LeftLabel htmlFor="SlackConnect">Slack連携</LeftLabel>
                    {!user.aiTravelSlackToken ? (
                      <Button isExternal href="/slackapp/ai_travel/start_slack_auth" size="small">
                        Slackと接続する
                      </Button>
                    ) : (
                      <Flex>
                        <div style={{ marginRight: '10px' }}>連携</div>
                        <Button isExternal href="/slackapp/ai_travel/remove_slack_auth" size="small">
                          連携解除
                        </Button>
                      </Flex>
                    )}
                  </Field>
                )}
                <A className="user-edit__registration-link" onClick={this.showEditRegistrationForm}>
                  パスワードの変更
                </A>
                <EditRegistrationForm
                  submitting={registrationSubmitting}
                  open={showEditRegistration}
                  onSubmit={this.handleUpdateRegistrationSubmit}
                  onAbort={this.hideEditRegistrationForm}
                  currentPassword={currentPassword}
                  onChangeCurrentPassword={this.handleChangeCurrentPassword}
                  password={password}
                  onChangePassword={this.handleChangePassword}
                  passwordConfirmation={passwordConfirmation}
                  onChangePasswordConfirmation={this.handleChangePasswordConfirmation}
                  errors={editRegistrationErrors}
                />

                {editNotice && (
                  <div className="user-edit__notice">
                    <div className="notice">{editNotice}</div>
                  </div>
                )}

                <div className="user-edit__errors">
                  {editErrors.map((e, i) => (
                    <div className="error" key={i}>
                      {e.split('\n').map((l, j) => (
                        <p key={j}>{l}</p>
                      ))}
                    </div>
                  ))}
                </div>

                {submitting ? (
                  <img className="user-edit__loading" src="/images/loading.gif" width={50} height={50} />
                ) : (
                  <Box ml={2} mr={2} mb={2}>
                    <Button type="submit" size="large" fullWidth>
                      保存
                    </Button>
                  </Box>
                )}
              </form>
            )}
          </div>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  flex-grow: 9999;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.linkColor};
  padding: 20px;
  & select {
    margin: 0 5px 0 0;
  }
`;

export const Field = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.linkColor};
  flex-wrap: wrap;
  padding: 20px;
  & select {
    margin: 0 5px 0 0;
  }
`;

export const LeftLabel = styled.label`
  min-width: 120px;
  color: ${props => props.theme.grayTextColor};
  font-weight: bold;
`;

export const SubLabel = styled.label`
  min-width: 20px;
`;

const MixinInput = css`
  &::placeholder {
    color: ${getColor('text', 'placeholder')};
  }

  border: 0;
  padding: 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 15px;
  height: 20px;
  background: white;
`;

export const Input = styled.input`
  &&& {
    ${MixinInput};
    width: calc(100% - 120px);
  }
`;

const InputSplit = styled.input`
  &&& {
    ${MixinInput};
    width: calc(45% - (120px / 2));
    margin-right: 10px;
  }
`;

const MileageItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const MileageItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  & select {
    margin-bottom: 0.75em;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export default UserEdit;
