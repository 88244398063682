import React, { useCallback, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Moment } from 'moment';
import _ from 'lodash';
import { Fetcher } from '@this/src/util';
import Notification from '@this/src/notification';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { JalBookingInventoryItemResponseArgs } from '@this/src/domain/arrangement/jal_booking_inventory_item';
import type JalBookingInventoryItem from '@this/src/domain/arrangement/jal_booking_inventory_item';
import { convertJalBookingInventoryItemResponseToArgs } from '@this/src/domain/arrangement/jal_booking_inventory_item';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusDone, OrderItemStatusOngoing } from '@this/src/domain/order_item/order_item_status';
import moment from 'moment';
import ItemsSearchArea from './items_search_area';
import useOrganizationSelector from '../../../use_organization_selector';
import ListResultTable from './items_result_table';

interface Response {
  results: JalBookingInventoryItemResponseArgs[];
  total_count: number;
  total_page: number;
}

export type InventoryStatus = 'done' | 'not_yet' | 'all';
export type SortKey = 'received_at' | 'started_at';
export type SortDirection = 'asc' | 'desc';

const Items = observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [jalBookingInventoryItems, setJalBookingInventoryItems] = useState<JalBookingInventoryItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(parseInt(utils.getParam('page') || '1', 10));

  const [inventoryStatus, setInventoryStatus] = useState<InventoryStatus>(
    (utils.getParam('inventory_status') as InventoryStatus) || 'not_yet'
  );
  const [jobTypes, setJobTypes] = useState<OrderItemJobTypeKey[]>(
    (utils.getParam('job_types')?.split(',').map(Number) as OrderItemJobTypeKey[]) || OrderItemJobTypeNumKeys
  );
  const [statuses, setStatuses] = useState<OrderItemStatusKey[]>(
    (utils.getParam('statuses')?.split(',').map(Number) as OrderItemStatusKey[]) ||
      OrderItemStatusOngoing.concat(OrderItemStatusDone)
  );
  const [tripId, setTripId] = useState<number | undefined>(utils.getParamInt('trip_id') ?? undefined);
  const [startDateFrom, setStartDateFrom] = useState<Moment | undefined>(
    utils.getParamMoment('start_date_from') ?? undefined
  );
  const [startDateTo, setStartDateTo] = useState<Moment | undefined>(
    utils.getParamMoment('start_date_to') ?? undefined
  );
  const [reservationDateFrom, setReservationDateFrom] = useState<Moment | undefined>(
    utils.getParamMoment('reservation_date_from') ?? moment().subtract(1, 'day')
  );
  const [reservationDateTo, setReservationDateTo] = useState<Moment | undefined>(
    utils.getParamMoment('reservation_date_to') ?? moment().subtract(1, 'day')
  );
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [sortKey, setSortKey] = useState<SortKey>((utils.getParam('sort_key') as SortKey) || 'received_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    (utils.getParam('sort_direction') as SortDirection) || 'asc'
  );
  const [isPaginate, setIsPaginate] = useState(true);
  const fetchingRef = useRef(false);

  const fetchTodos = useCallback(() => {
    if (fetchingRef.current) {
      fetchingRef.current = false;
      return;
    }
    setIsLoading(true);
    setJalBookingInventoryItems([]);
    setTotalCount(0);
    setTotalPage(1);

    const params = {
      page,
      inventory_status: inventoryStatus,
      job_types: jobTypes.join(','),
      statuses: statuses.join(','),
      trip_id: tripId ?? '',
      start_date_from: startDateFrom?.format('YYYY-MM-DD') ?? '',
      start_date_to: startDateTo?.format('YYYY-MM-DD') ?? '',
      reservation_date_from: reservationDateFrom?.format('YYYY-MM-DD') ?? '',
      reservation_date_to: reservationDateTo?.format('YYYY-MM-DD') ?? '',
      organization_ids: organizationIds.join(','),
      sort_key: sortKey,
      sort_direction: sortDirection,
      pagination: isPaginate ? 'true' : 'false'
    };

    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=AUTO_RESERVATION_INVENTORY&jal_booking_tab=ITEMS&${query}`);

    Fetcher.get<Response>('/arrangement/auto_reservation_inventory/jal_booking/items.json', params)
      .then(res => {
        setJalBookingInventoryItems(res.results.map(args => convertJalBookingInventoryItemResponseToArgs(args)));
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .catch(e => {
        Notification.error('データの取得に失敗しました');
        utils.sendErrorObject(e);
      })
      .finally(() => setIsLoading(false));
  }, [
    page,
    inventoryStatus,
    jobTypes,
    statuses,
    tripId,
    startDateFrom,
    startDateTo,
    reservationDateFrom,
    reservationDateTo,
    organizationIds,
    sortKey,
    sortDirection,
    isPaginate,
    history
  ]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  const handleCsvDownload = useCallback(() => {
    if (fetchingRef.current) {
      return;
    }
    fetchingRef.current = true;

    const params = {
      inventory_status: inventoryStatus,
      job_types: jobTypes.join(','),
      statuses: statuses.join(','),
      trip_id: tripId,
      start_date_from: startDateFrom?.format('YYYY-MM-DD'),
      start_date_to: startDateTo?.format('YYYY-MM-DD'),
      reservation_date_from: reservationDateFrom?.format('YYYY-MM-DD'),
      reservation_date_to: reservationDateTo?.format('YYYY-MM-DD'),
      organization_ids: organizationIds.join(','),
      sort_key: sortKey,
      sort_direction: sortDirection,
      pagination: 'false'
    };

    const query = Object.entries(params)
      .reduce<string[]>((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
        }
        return acc;
      }, [])
      .join('&');
    window.location.href = `/arrangement/auto_reservation_inventory/jal_booking/items.csv?${query}`;
    setTimeout(() => {
      fetchingRef.current = false;
    }, 1000);
  }, [
    inventoryStatus,
    jobTypes,
    statuses,
    tripId,
    startDateFrom,
    startDateTo,
    reservationDateFrom,
    reservationDateTo,
    organizationIds,
    sortKey,
    sortDirection
  ]);

  return (
    <>
      <ItemsSearchArea
        jobTypes={jobTypes}
        statuses={statuses}
        inventoryStatus={inventoryStatus}
        tripId={tripId}
        startDateFrom={startDateFrom}
        startDateTo={startDateTo}
        reservationDateFrom={reservationDateFrom}
        reservationDateTo={reservationDateTo}
        sortKey={sortKey}
        sortDirection={sortDirection}
        isPaginate={isPaginate}
        setJobTypes={setJobTypes}
        setStatuses={setStatuses}
        setInventoryStatus={setInventoryStatus}
        setTripId={setTripId}
        setStartDateFrom={setStartDateFrom}
        setStartDateTo={setStartDateTo}
        setReservationDateFrom={setReservationDateFrom}
        setReservationDateTo={setReservationDateTo}
        setSortKey={setSortKey}
        setSortDirection={setSortDirection}
        setIsPaginate={setIsPaginate}
        renderOrganizationSelector={renderOrganizationSelector}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <ListResultTable
          jalBookingInventoryItems={jalBookingInventoryItems}
          totalCount={totalCount}
          totalPage={totalPage}
          page={page}
          fetchTodos={fetchTodos}
          setPage={setPage}
          onCsvDownload={handleCsvDownload}
        />
      )}
    </>
  );
});

export default Items;
