import { observable } from 'mobx';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import type { OrderItemJobTypeKey } from '../order_item/order_item_job_type';
import type { OrderItemStatusKey } from '../order_item/order_item_status';
import type { TripStatusKey } from '../trip/trip_status';
import type { OrderItemCategoryKey } from '../order_item/order_item_category';
import type { OrderItemStepTodoArgs, OrderItemStepTodoResponseArgs } from './order_item_step_todo';
import OrderItemStepTodo, { convertOrderItemStepTodoResponseToArgs } from './order_item_step_todo';

export type OrderItemTodoType = 'message' | 'order_item';

export type OrderItemTodoResponseArgs = {
  todo_type: string;
  trip_id: number;
  order_item_id?: number;
  trace_id?: number;
  trip_status: number;
  job_type: number;
  status: number;
  arranger_name?: string;
  category?: OrderItemCategoryKey;
  organization_name?: string;
  waiting?: boolean;
  start_date?: string;
  end_date?: string;
  received_at?: string;
  last_user_message?: string;
  last_user_message_id?: number;
  last_log_message?: string;
  last_log_message_arranger?: string;
  snooze_to?: string;
  snooze_message?: string;
  is_urgent: boolean;
  sp: boolean;
  use_qr?: boolean;
  ticketing_type?: number[];
  premium_support?: boolean;
  changed_status?: boolean;
  current_step?: OrderItemStepTodoResponseArgs;
};

export type OrderItemTodoArgs = {
  todoType: OrderItemTodoType;
  tripId: number;
  orderItemId?: number;
  traceId?: number;
  tripStatus: TripStatusKey;
  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  arrangerName?: string;
  category?: OrderItemCategoryKey;
  organizationName?: string;
  waiting?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  receivedAt?: Moment;
  lastUserMessage?: string;
  lastUserMessageId?: number;
  lastLogMessage?: string;
  lastLogMessageArranger?: string;
  snoozeTo?: Moment;
  snoozeMessage?: string;
  isUrgent: boolean;
  sp: boolean;
  useQr: boolean;
  ticketingType: number[];
  premiumSupport: boolean;
  changedStatus?: boolean;
  currentStep?: OrderItemStepTodoArgs;
};

export const convertOrderItemTodoResponseToArgs = (response: OrderItemTodoResponseArgs) => {
  const args: OrderItemTodoArgs = {
    todoType: response.todo_type as OrderItemTodoType,
    tripId: response.trip_id,
    orderItemId: response.order_item_id,
    traceId: response.trace_id,
    tripStatus: response.trip_status as TripStatusKey,
    jobType: response.job_type as OrderItemJobTypeKey,
    status: response.status as OrderItemStatusKey,
    arrangerName: response.arranger_name,
    category: response.category,
    organizationName: response.organization_name,
    waiting: response.waiting,
    startDate: response.start_date ? moment(response.start_date) : undefined,
    endDate: response.end_date ? moment(response.end_date) : undefined,
    receivedAt: response.received_at ? moment(response.received_at) : undefined,
    lastUserMessage: response.last_user_message,
    lastUserMessageId: response.last_user_message_id,
    lastLogMessage: response.last_log_message,
    lastLogMessageArranger: response.last_log_message_arranger,
    snoozeTo: response.snooze_to ? moment(response.snooze_to) : undefined,
    snoozeMessage: response.snooze_message,
    isUrgent: response.is_urgent,
    sp: response.sp,
    useQr: response.use_qr || false,
    ticketingType: response.ticketing_type || [],
    premiumSupport: response.premium_support || false,
    changedStatus: response.changed_status,
    currentStep: response.current_step ? convertOrderItemStepTodoResponseToArgs(response.current_step) : undefined
  };
  return args;
};

export default class OrderItemTodo {
  @observable readonly todoType: OrderItemTodoType;

  @observable readonly tripId: number;

  @observable readonly orderItemId?: number;

  @observable readonly traceId?: number;

  @observable readonly tripStatus: TripStatusKey;

  @observable jobType: OrderItemJobTypeKey;

  @observable status: OrderItemStatusKey;

  @observable arrangerName?: string;

  @observable category?: OrderItemCategoryKey;

  @observable organizationName?: string;

  @observable waiting?: boolean;

  @observable startDate?: Moment;

  @observable endDate?: Moment;

  @observable receivedAt?: Moment;

  @observable lastUserMessage?: string;

  @observable lastUserMessageId?: number;

  @observable lastLogMessage?: string;

  @observable lastLogMessageArranger?: string;

  @observable snoozeTo?: Moment;

  @observable snoozeMessage?: string;

  @observable isUrgent: boolean;

  @observable sp: boolean;

  @observable useQr: boolean;

  @observable ticketingType: number[];

  @observable premiumSupport: boolean;

  @observable changedStatus?: boolean;

  @observable currentStep?: OrderItemStepTodo;

  constructor(args: OrderItemTodoArgs) {
    this.todoType = args.todoType;
    this.tripId = args.tripId;
    this.orderItemId = args.orderItemId;
    this.traceId = args.traceId;
    this.tripStatus = args.tripStatus;
    this.jobType = args.jobType;
    this.status = args.status;
    this.arrangerName = args.arrangerName;
    this.category = args.category;
    this.organizationName = args.organizationName;
    this.waiting = args.waiting;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.receivedAt = args.receivedAt;
    this.lastUserMessage = args.lastUserMessage;
    this.lastUserMessageId = args.lastUserMessageId;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
    this.snoozeTo = args.snoozeTo;
    this.snoozeMessage = args.snoozeMessage;
    this.isUrgent = args.isUrgent;
    this.sp = args.sp;
    this.useQr = args.useQr;
    this.ticketingType = args.ticketingType;
    this.premiumSupport = args.premiumSupport;
    this.changedStatus = args.changedStatus;
    if (args.currentStep) this.currentStep = new OrderItemStepTodo(args.currentStep);
  }
}
