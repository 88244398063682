import React, { useEffect, useMemo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import {
  Button,
  Paper,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { AllowanceItemForReport } from '@this/src/domain/trip_report/allowance_item';
import { BodyTitle } from '../../../shared/wrappers';
import {
  AllowanceItemsFormContext,
  AllowanceItemsFormStore,
  TripReportTripSelectedAllowanceItemsForm
} from './allowance_items_form';

type Props = {
  changePrice: boolean;
  allowanceItems: AllowanceItemForReport[];
  setChangePrice: Dispatch<SetStateAction<boolean>>;
  trip_id: any;
};

export const TripReportTripSelectedAllowanceItems: React.FC<Props> = ({
  changePrice,
  allowanceItems,
  setChangePrice,
  trip_id
}) => {
  const allowanceItemsFormStore = useMemo(() => new AllowanceItemsFormStore(), []);
  const store = useTripReportStore();
  const { setting } = store;

  useEffect(() => {
    store.defaultAllowanceItems = [];
    store.getDefaultAllowanceItems(trip_id);
  }, [trip_id]);

  return (
    <AllowanceItemSection>
      <BodyTitle>日当を登録</BodyTitle>
      <TableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell>日付</TableCell>
              <TableCell>金額</TableCell>
              <TableCell>種別</TableCell>
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            <AllowanceItemsFormContext.Provider value={allowanceItemsFormStore}>
              {allowanceItems.map((item, i) => (
                <NonOrderTableRow key={i} className={item.editing ? 'editing' : ''}>
                  {item.editing ? (
                    <TripReportTripSelectedAllowanceItemsForm
                      changePrice={changePrice}
                      item={item}
                      index={i}
                      expenseTool={setting?.expenseTool}
                      tripTypeOptions={store.tripTypeOptions}
                      calcPriceOrderOptions={store.calcPriceOrderOptions}
                      setChangePrice={setChangePrice}
                      removeAllowanceItem={() => store.removeAllowanceItem(item)}
                    />
                  ) : (
                    <>
                      <TableCell>{item.getPeriod()}</TableCell>
                      <TableCell>{item.getPrice()}</TableCell>
                      <TableCell>{item.getAllowanceTypeName()}</TableCell>
                      <TableCell>
                        <Actions>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => item.setEditing(true)}
                          >
                            編集
                          </Button>
                          <RemoveButton
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              store.removeAllowanceItem(item);
                              setChangePrice(!changePrice);
                            }}
                          >
                            削除
                          </RemoveButton>
                        </Actions>
                      </TableCell>
                    </>
                  )}
                </NonOrderTableRow>
              ))}
            </AllowanceItemsFormContext.Provider>
            <TableRow>
              <TableCell colSpan={5}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => store.addAllowanceItem()}
                >
                  日当を追加
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </TableContainer>
    </AllowanceItemSection>
  );
};

const AllowanceItemSection = styled.div``;

const StyledTable = styled(Table)`
  ${media.sp`
    display: block;

    .spDisable {
      display: none;
    }

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-flow: column;
    }

    .MuiTableRow-root {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      padding: 16px;
      gap: 8px 16px;
      border-bottom: solid 1px #eee;
    }

    .MuiTableCell-root {
      border: 0;
      padding: 0;
      width: 100%;

      &.spWrap {
        width: auto;
      }

      &:last-child {
        padding-top: 8px;
        border-top: solid 1px #eee;
      }

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }
    }
  `}
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.grayBgColorLight};

  .MuiTableCell {
    &-head {
      padding: 8px 16px;
      min-width: 120px;
    }
  }

  ${media.sp`
    display: none;
  `}
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const RemoveButton = styled(Button)`
  color: ${props => props.theme.redColor};
  font-weight: 500;
`;

const NonOrderTableRow = styled(TableRow)`
  &.editing {
    background-color: ${props => props.theme.grayBgColorLight};
  }
`;
