import { observable } from 'mobx';

export type RentalCarLimitTypeJson = {
  id?: number | null;
  business_days?: number;
  hour?: number;
  actual_days?: number;
  is_booking_too_late?: boolean;
  is_today_holiday?: boolean;
  previous_business_day: string;
};

class RentalCarLimitType {
  @observable
  readonly id?: number | null;

  @observable
  businessDays: number;

  @observable
  hour: number;

  @observable
  actualDays: number;

  @observable
  bookingTooLate: boolean;

  @observable
  todayHoliday: boolean;

  constructor(args: RentalCarLimitTypeJson) {
    this.id = args.id;
    this.businessDays = args.business_days || 2;
    this.hour = typeof args.hour === 'number' ? args.hour : 12;
    this.actualDays = args.actual_days !== undefined ? args.actual_days : 2;
    this.bookingTooLate = args.is_booking_too_late !== undefined ? args.is_booking_too_late : false;
    this.todayHoliday = args.is_today_holiday !== undefined ? args.is_today_holiday : false;
  }

  description() {
    return `${this.businessDays}営業日前の${this.hour}時`;
  }

  submitParams() {
    return {
      id: this.id,
      business_days: this.businessDays,
      hour: this.hour
    };
  }
}

export default RentalCarLimitType;
