import { styled } from '@this/constants/themes';

export const headerHeight = '50px';

const Icon = styled.span`
  display: inline-block;
  margin-right: 5px;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 at-icon;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`;

export const SearchIcon = styled(Icon)`
  ::before {
    content: '\\ea01';
  }
`;

export const ListIcon = styled(Icon)`
  ::before {
    content: '\\ea02';
  }
`;

export const CheckIcon = styled(Icon)`
  ::before {
    content: '\\ea03';
  }
`;

export const GraphIcon = styled(Icon)`
  ::before {
    content: '\\ea04';
  }
`;
