import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type FreeePostDealHistory from '@this/src/domain/freee/freee_post_deal_history';

interface Props {
  history: FreeePostDealHistory | null;
}

const FreeeDealsSubmitProgress: React.FC<Props> = ({ history }) => {
  const loadingHistory = history?.status === 'started' || history?.status === 'unstarted';

  return (
    <Flex>
      <div>
        <SimpleLoading size={30} />
      </div>
      <span>登録中...</span>
      {history && loadingHistory && (
        <>
          <MainFlexItem>
            <LinearProgress variant="determinate" value={history.progress} style={{ width: '100%' }} />
          </MainFlexItem>
          <p>{history.progressText}</p>
        </>
      )}
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 16px;
  width: 100%;
`;

const MainFlexItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default FreeeDealsSubmitProgress;
