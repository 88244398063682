import React from 'react';
import { styled } from '@this/constants/themes';
import { Flex } from '@this/shared/ui/layout/flex';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';

import Button from '@this/shared/atoms/button';
import { Select } from '@this/shared/ui/inputs/select';

export type ProjectFilterSortType = 'CREATED_AT_ASC' | 'CODE_ASC' | 'CODE_DESC';

const sortOptions: Record<ProjectFilterSortType, string> = {
  CREATED_AT_ASC: '登録順',
  CODE_ASC: 'コード昇順',
  CODE_DESC: 'コード降順'
};

type Props = {
  onSearch: (text: string, disabled: boolean, order: ProjectFilterSortType) => void;
};
export const ProjectFilter = ({ onSearch }: Props) => {
  const [filterText, setFilterText] = React.useState('');
  const [filterDisabled, setFilterDisabled] = React.useState(false);
  const [sortType, setSortType] = React.useState<ProjectFilterSortType>('CREATED_AT_ASC');

  return (
    <Filter className="e2e-project-filter">
      <Flex alignItems="center">
        <FilterLabel htmlFor="project-search-word">検索ワード</FilterLabel>
        <OrganizationProjectFilterField
          id="project-search-word"
          value={filterText}
          placeholder="検索"
          onChange={e => setFilterText(e.target.value)}
        />
      </Flex>
      <Flex alignItems="center">
        <FilterLabel>ステータス</FilterLabel>
        <RadioGroup
          layout="horizontal"
          value={filterDisabled}
          onChange={(_, value) => setFilterDisabled(value === 'true')}
        >
          <Radio name="status" value={false}>
            有効
          </Radio>
          <Radio name="status" value>
            無効
          </Radio>
        </RadioGroup>
      </Flex>
      <Flex alignItems="center">
        <FilterLabel>ソート順</FilterLabel>
        <Select value={sortType} onChange={e => setSortType(e.target.value as ProjectFilterSortType)}>
          {Object.entries(sortOptions).map(([k, v]) => (
            <option key={k} value={k}>
              {v}
            </option>
          ))}
        </Select>
      </Flex>
      <FilterButton onClick={() => onSearch(filterText, filterDisabled, sortType)}>検索</FilterButton>
    </Filter>
  );
};

const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
`;

const FilterLabel = styled.label`
  font-weight: normal;
  margin-right: 5px;
`;

const OrganizationProjectFilterField = styled.input.attrs({ type: 'text' })`
  flex-grow: 1;
  width: auto !important;
  margin-bottom: 0 !important;
`;

const FilterButton = styled(Button)`
  width: 100px;
  margin-left: 16px;
`;
