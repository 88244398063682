// ts化前の暫定的パーツのため、呼び出し元のts化の際に通常のscにする
import React from 'react';
import { styled, createTheme } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { AITThemeProvider } from '@this/shared/ui/theme';

interface Props {
  serviceId: number;
  type: string;
  value: string;
  to: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  className: string;
}

interface State {}

class SCText extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public static defaultProps = {
    type: '',
    to: '#',
    onClick: () => false,
    className: ''
  };

  render() {
    const theme = createTheme(this.props.serviceId);

    try {
      return (
        <AITThemeProvider theme={theme}>
          {this.props.type === 'link' ? (
            <A href={this.props.to} onClick={this.props.onClick} className={this.props.className}>
              {this.props.value}
            </A>
          ) : (
            <ColoredText className={this.props.className}>{this.props.value}</ColoredText>
          )}
        </AITThemeProvider>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ColoredText = styled.span`
  color: ${props => props.theme.linkColor};
`;

export default SCText;
