import React from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import type ApproveItem from '@this/domain/approve_item/approve_item';
import type { DataType, Display, RequiredType, Target } from '@this/domain/approve_item/approve_item';
import {
  dataTypeOptions,
  targetOptions,
  displayOptions,
  requiredTypeOptions,
  requiredTypeForReportOptions
} from '@this/domain/approve_item/approve_item';
import { Input } from '../../shared/ui/inputs/input';
import { Select } from '../../shared/ui/inputs/select';
import { Radio } from '../../shared/ui/inputs/radio';
import { Checkbox } from '../../shared/ui/inputs/checkbox';
import { Button } from '../../shared/ui/inputs/button';

type FieldProps = {
  children: React.ReactNode;
};

const Field = ({ children }: FieldProps) => (
  <Grid container spacing={2} style={{ marginBottom: '20px' }}>
    {children}
  </Grid>
);

type Props = {
  approveItem: ApproveItem;
  showExicSeisanTrips: boolean;
};
const ApproveItemForm = observer(({ approveItem, showExicSeisanTrips }: Props) => {
  const targetOptionsWithSimpleRequest = {
    ...targetOptions,
    ...(showExicSeisanTrips && { simple_request_trip: '簡易申請' })
  };

  return (
    <>
      <Field>
        <Grid item xs={2}>
          <label htmlFor="userDisplayName">設定表示名</label>
        </Grid>
        <Grid item xs={10}>
          <Input
            id="userDisplayName"
            type="text"
            defaultValue={approveItem.userDisplayName}
            onChange={e => {
              approveItem.userDisplayName = e.target.value;
            }}
          />
          <InputNote>出張申請画面に表示されるラベル名です。</InputNote>
        </Grid>
      </Field>
      <Field>
        <Grid item xs={2}>
          <label htmlFor="dataType">入力データのタイプ</label>
        </Grid>
        <Grid item xs={10}>
          <Select
            id="dataType"
            disabled={!!approveItem.id}
            value={approveItem.dataType}
            onChange={e => {
              approveItem.dataType = e.target.value as DataType;
            }}
          >
            <option value="">選択してください</option>
            {Object.entries(dataTypeOptions).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </Select>
          <InputNote>「リストから選択」を選択した場合、マスタ画面から項目の設定をしてください。</InputNote>
        </Grid>
      </Field>
      {approveItem.id && approveItem.dataType === 'list' && (
        <Field>
          <Grid item xs={2}>
            <label>リストデータ</label>
          </Grid>
          <Grid item xs={10}>
            <Button color="sub" href={`/organization/approve_items/${approveItem.id}/approve_item_lists`}>
              リストの内容を編集
            </Button>
          </Grid>
        </Field>
      )}
      <Field>
        <Grid item xs={2}>
          <label htmlFor="target">申請対象</label>
        </Grid>
        <Grid item xs={10}>
          <Select
            id="target"
            value={approveItem.target}
            onChange={e => {
              const val = e.target.value as Target;
              approveItem.target = val;
              if (val === 'report_after_business_trip') approveItem.requiredType = '';
            }}
          >
            <option value="">選択してください</option>
            {Object.entries(targetOptionsWithSimpleRequest).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </Select>
          <InputNote />
        </Grid>
      </Field>
      <Field>
        <Grid item xs={2}>
          <label htmlFor="display">申請画面での表示有無</label>
        </Grid>
        <Grid item xs={10}>
          {Object.entries(displayOptions).map(([k, v]) => (
            <Radio
              key={k}
              value={k}
              name="display"
              checked={approveItem.display === k}
              onChange={() => {
                approveItem.display = k as Display;
              }}
              style={{ marginRight: '20px' }}
            >
              {v}
            </Radio>
          ))}
          <InputNote />
        </Grid>
      </Field>
      {approveItem.dataType !== 'label' && (
        <Field>
          <Grid item xs={2}>
            <label htmlFor="requiredType">入力必須有無</label>
          </Grid>
          <Grid item xs={10}>
            <Select
              id="requiredType"
              value={approveItem.requiredType}
              onChange={e => {
                approveItem.requiredType = e.target.value as RequiredType;
              }}
            >
              <option value="">選択してください</option>
              {Object.entries(
                approveItem.target !== 'before_business_trip' ? requiredTypeForReportOptions : requiredTypeOptions
              ).map(([k, v]) => (
                <option key={k} value={k}>
                  {v}
                </option>
              ))}
            </Select>
            <InputNote />
          </Grid>
        </Field>
      )}
      {approveItem.isNeedBusinessTripType && (
        <Field>
          <Grid item xs={2}>
            <label htmlFor="businessTripType">適用する出張の種類</label>
          </Grid>
          <Grid item xs={10}>
            <Checkbox
              checked={approveItem.isBusinessTripTypeOversea}
              onChange={e => {
                approveItem.isBusinessTripTypeOversea = e.target.checked;
              }}
              style={{ marginRight: '20px' }}
            >
              海外出張
            </Checkbox>
            <Checkbox
              checked={approveItem.isBusinessTripTypeDomestic}
              onChange={e => {
                approveItem.isBusinessTripTypeDomestic = e.target.checked;
              }}
            >
              国内出張
            </Checkbox>
            <InputNote />
          </Grid>
        </Field>
      )}
      {approveItem.isNeedPlaceholder && (
        <Field>
          <Grid item xs={2}>
            <label htmlFor="placeholder">プレースホルダー</label>
          </Grid>
          <Grid item xs={10}>
            <Input
              id="placeholder"
              type="text"
              defaultValue={approveItem.placeholder}
              onChange={e => {
                approveItem.placeholder = e.target.value;
              }}
            />
            <InputNote>「テキスト自由入力」を選択した場合設定できます。30文字以内で入力してください。 </InputNote>
          </Grid>
        </Field>
      )}
    </>
  );
});

const InputNote = styled.p`
  color: #666;
  margin-top: 5px;
`;

export default ApproveItemForm;
