import { observable } from 'mobx';

export type DataProviderDomesticAirRouteJson = {
  id: number;
  csv: string;
};

class DataProviderDomesticAirRoute {
  readonly id: number;

  @observable
  csv: string;

  constructor(args: DataProviderDomesticAirRouteJson) {
    this.id = args.id;
    this.csv = args.csv;
  }

  submitParams() {
    return { csv: this.csv };
  }
}

export default DataProviderDomesticAirRoute;
