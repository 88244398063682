import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import type { UpdateAction } from '@this/src/domain/trip_report/trip_report_store';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { Button } from '@this/shared/ui/inputs/button';
import { FooterWrap, FooterBody, FooterActions, FooterActionGroup } from '../shared/wrappers';

export const TripReportFormFooter: React.FC = () => {
  const store = useTripReportStore();
  const step = store.step;

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      entry => {
        if (!entry[0].isIntersecting) {
          document.querySelector('#footerActions')?.classList.add('sticky');
        } else {
          document.querySelector('#footerActions')?.classList.remove('sticky');
        }
      },
      { threshold: 0.5 }
    );
    const selector = document.querySelector('#footerActions--toggle');
    if (selector) observer.observe(selector);
  }, []);

  return (
    <>
      <FooterWrap id="footerActions">
        <FooterBody>
          <FooterActions>
            {step === 'tripReport' ? (
              <TripReportActions />
            ) : step === 'tripSelect' ? (
              <TripReportSelectActions />
            ) : step === 'tripSelected' ? (
              <TripReportSelectedActions />
            ) : step === 'confirm' ? (
              <TripReportConfirmActions />
            ) : (
              <></>
            )}
          </FooterActions>
        </FooterBody>
      </FooterWrap>
      <span id="footerActions--toggle" />
    </>
  );
};

const TripReportActions: React.FC = () => {
  const history = useHistory();
  const store = useTripReportStore();
  const { loading, submitting, currentTripReport, validationMessages } = store;

  const draftInvalid = store.validate('draft').length > 0;
  const applyInvalid = store.validate('apply').length > 0;

  const handleClick = useCallback(() => {
    store.setStep('confirm');
  }, [store]);

  const fetchUpdate = useCallback(() => {
    store.update({
      action: 'draft',
      onSuccess: (id: number) => {
        const message = '出張報告の下書きを保存しました。';
        history.push('/trip_report', { tripReport: { id, message } });
        window.scroll({ top: 0 });
      }
    });
  }, []);

  return (
    <FooterActionGroup>
      {['initial', 'draft'].includes(currentTripReport.status) && (
        <Button color="sub" disabled={submitting || loading || draftInvalid} onClick={fetchUpdate}>
          下書き保存
        </Button>
      )}
      <Button disabled={submitting || loading || applyInvalid} onClick={handleClick}>
        報告内容を確認する
      </Button>
      <Errors>
        {validationMessages.map((message, i) => (
          <Error key={i}>{message}</Error>
        ))}
      </Errors>
    </FooterActionGroup>
  );
};

const TripReportSelectActions: React.FC = () => {
  const store = useTripReportStore();
  const { loading, selected, itemEditing } = store;

  const handleClick = useCallback(() => {
    store.setStep('tripSelected');
  }, [store]);

  return (
    <FooterActionGroup>
      <Button disabled={loading || (!selected && !itemEditing)} onClick={handleClick}>
        旅程を選択して次へ
      </Button>
    </FooterActionGroup>
  );
};

const TripReportSelectedActions: React.FC = () => {
  const store = useTripReportStore();
  const { loading, selected } = store;
  const editing =
    store.nonOrderItems.findIndex(item => item.editing) !== -1 ||
    store.allowanceItems.findIndex(item => item.editing) !== -1;
  const additing =
    Boolean(selected) &&
    (store.selectedTrip()?.nonOrderItems?.findIndex(item => item.editing) !== -1 ||
      store.selectedTrip()?.allowanceItems?.findIndex(item => item.editing) !== -1);

  const handleBack = useCallback(() => {
    store.setStep('tripReport');
  }, [store]);

  const handleSubmit = useCallback(() => {
    if (selected) store.insertSelectedTrip();
    store.setStep('tripReport');
  }, [store, selected]);

  return (
    <FooterActionGroup>
      {selected && (
        <Button color="sub" disabled={loading || editing} onClick={handleBack}>
          キャンセル
        </Button>
      )}
      <Button disabled={loading || editing || additing} onClick={handleSubmit}>
        この内容で登録する
      </Button>
    </FooterActionGroup>
  );
};

const TripReportConfirmActions: React.FC = () => {
  const history = useHistory();
  const store = useTripReportStore();
  const { loading, submitting, currentTripReport, validationMessages } = store;

  const draftInvalid = store.validate('draft').length > 0;
  const applyInvalid = store.validate('apply', true).length > 0;

  const fetchUpdate = useCallback(
    (action: UpdateAction) => () => {
      store.update({
        action,
        onSuccess: (id: number) => {
          const message =
            action === 'draft' ? '出張報告の下書きを保存しました。' : '出張報告の申請が完了しました。';
          history.push('/trip_report', { tripReport: { id, message } });
          window.scroll({ top: 0 });
        }
      });
    },
    []
  );

  return (
    <FooterActionGroup>
      {['initial', 'draft', 'send_back'].includes(currentTripReport.status) && (
        <Button color="sub" disabled={submitting || loading || draftInvalid} onClick={fetchUpdate('draft')}>
          下書き保存
        </Button>
      )}
      <Button disabled={submitting || loading || applyInvalid} onClick={fetchUpdate('apply')}>
        この内容で申請する
      </Button>
      <Errors>
        {validationMessages.map((message, i) => (
          <Error key={i}>{message}</Error>
        ))}
      </Errors>
    </FooterActionGroup>
  );
};

const Errors = styled.div``;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;
