import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import {
  Header,
  Icon,
  Date,
  Remove,
  Body
} from '@this/components/reserve_trip/select/outline_item/outline_item.template';
import { Name } from '@this/src/components/reserve_trip/select/transport_box/transport_box_foreign.template';
import {
  BoxButtonArea,
  BoxButton
} from '@this/components/reserve_trip/select/transport_box/right/right_in_outline.template';
import { BoxButtonText } from '@this/components/reserve_trip/select/shared/box_parts';

import type NonOrderItem from '@this/domain/non_order_item';
import type TransportElement from '@this/domain/transport_element';

interface Props {
  icon: number;
  item: NonOrderItem;
  element: TransportElement;
  editing: boolean;
  editable: boolean;
  deselectItemType?: (e: React.MouseEvent<HTMLElement>) => void;
  startEditingNonOrderItem?: (item: NonOrderItem) => void;
  toggleNonOrderItemNeed?: (item: NonOrderItem) => void;
  theme: { serviceName: string };
}

const TransportBlockTemplate: React.SFC<Props> = ({
  icon,
  item,
  element,
  editing,
  editable,
  deselectItemType,
  startEditingNonOrderItem,
  toggleNonOrderItemNeed,
  theme
}) => (
  <Block className="select-outline__item-block">
    <Header>
      <Icon>{icon}</Icon>
      <Date>{`交通機関：${element.from.dateStr()}`}</Date>
      {editable && item.isNeed && (
        <CheckLabel>
          <input type="checkbox" checked={false} disabled />
          システムで手配
        </CheckLabel>
      )}
      {editable && toggleNonOrderItemNeed && (
        <Remove onClick={() => toggleNonOrderItemNeed(item)}>{item.isNeed ? '削除する' : '元に戻す'}</Remove>
      )}
    </Header>
    <Body>
      {item.isNeed && (
        <Card>
          <CardLeft>
            <Name>{element.title()}</Name>
            <Text>
              {`発 ${element.from.dateTimeStr()} ${element.boardingStation}`}
              <br />
              {`着 ${element.to.dateTimeStr()} ${element.arrivalStation}`}
            </Text>
          </CardLeft>
          <CardRight>
            <>
              <CardPrice>{utils.formatPriceWithMark(item.price.totalPrice())}</CardPrice>
              {editable && startEditingNonOrderItem && deselectItemType && (
                <BlockButtonArea>
                  <BoxButton
                    selected={editing}
                    onClick={e => (editing ? deselectItemType(e) : startEditingNonOrderItem(item))}
                  >
                    <BoxButtonText className="select-transport-box__right-gray__button-text">
                      {editing ? (
                        <span>
                          編集を
                          <br />
                          キャンセル
                        </span>
                      ) : (
                        '編集 >'
                      )}
                    </BoxButtonText>
                  </BoxButton>
                </BlockButtonArea>
              )}
            </>
          </CardRight>
        </Card>
      )}
    </Body>
  </Block>
);

export const Block = styled.div`
  margin-bottom: 15px;
`;

export const CheckLabel = styled.label`
  color: ${props => props.theme.disabledColor};
  font-weight: inherit;
`;

export const Card = styled.div`
  display: flex;
  background: #fff;
  border: solid 2px ${props => props.theme.grayBorderColor};
`;

export const CardLeft = styled.div`
  padding: 10px;
`;

export const Text = styled.div`
  margin-top: 2px;
  font-size: 12px;
`;

export const CardRight = styled.div`
  display: flex;
  flex-grow: 0;
  margin-left: auto;
  min-width: 170px;
  background: ${props => props.theme.grayBgColorLight};
`;

export const CardPrice = styled.div`
  justify-content: center;
  margin: auto 0 auto 10px;
  color: ${props => props.theme.linkColor};
  font-size: 14px;
  font-weight: bold;
`;

export const BlockButtonArea = styled(BoxButtonArea)`
  color: ${props => props.theme.linkColor};
`;

export default withTheme(TransportBlockTemplate);
