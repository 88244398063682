import React, { useCallback, useMemo } from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { TableCell, TableRow } from '@material-ui/core';
import type Trip from '@this/src/domain/trip/trip';
import type { InAdvanceApprovalBatch } from '@this/src/domain/in_advance/in_advance_approval_batch';
import type { SearchStatus } from '@this/src/domain/in_advance/in_advance_application_store';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Button } from '../../shared/ui/inputs/button';
import LineClampBox from '../../shared/text/line_clamp_box';
import RuleErrors from './rule_errors';
import { Checkbox } from '../../shared/ui/inputs/checkbox';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import A from '../../shared/atoms/a';

interface Props {
  trip: Trip;
  batch: InAdvanceApprovalBatch;
  status: SearchStatus;
  getOrderCategories: (trip: Trip) => string[];
}

const InAdvanceApplicationRow: React.FC<Props> = ({ trip, batch, status, getOrderCategories }) => {
  const { categories, startDate, endDate, createdAt, totalPrice, approvers, rejectReason, approveReason } =
    useMemo(
      () => ({
        categories: getOrderCategories(trip),
        startDate: trip.startDate('YYYY/MM/DD'),
        endDate: trip.endDate('YYYY/MM/DD'),
        createdAt: [trip.createdAt('YYYY/MM/DD'), trip.createdAt('HH:mm')],
        totalPrice: trip.totalPrice() ? `${utils.digits(trip.totalPrice())}円` : '確認中',
        approvers:
          status === 'approved' && trip.in_advance_approval?.flatMap(approval => approval.approver?.name || []),
        rejectReason:
          status === 'rejected' &&
          trip.in_advance_approval?.find(approval => approval.status === 2)?.reject_reason,
        approveReason:
          status === 'approved' &&
          trip.in_advance_approval?.find(approval => approval.status === 1)?.approve_reason
      }),
      [trip.id]
    );

  const batchStatus = batch.tripStatus(trip.id);
  const handleCheck = useCallback(() => batch.setSelectedTrip(trip.id), [trip.id, batch]);

  return (
    <TableRow>
      {status === 'applied' && (
        <CheckboxCell>
          {batchStatus === 'checkbox' ? (
            <Checkbox checked={batch.selectedTrips.includes(trip.id)} onChange={handleCheck} />
          ) : batchStatus === 'loading' ? (
            <SimpleLoading size={20} />
          ) : batchStatus === 'success' ? (
            <DoneIcon color="primary" />
          ) : (
            <ErrorOutlineIcon color="error" />
          )}
        </CheckboxCell>
      )}
      <TableCell>
        <SpLabel>旅程番号</SpLabel>
        {status === 'applied' ? trip.id : <A href={`/application/in_advance/${trip.id}`}>{trip.id}</A>}
      </TableCell>
      <TableCell>
        <SpLabel>
          出張者
          <TableCellGrayRow>(申請者)</TableCellGrayRow>
        </SpLabel>
        {trip.travelerInformations.list.map(i => (
          <TableCellRow key={i.id}>{i.getTravelerNameFromPriority()}</TableCellRow>
        ))}
        <TableCellGrayRow>({trip.user.name})</TableCellGrayRow>
      </TableCell>
      <TableCell>
        <SpLabel>出張先</SpLabel>
        <LineClampBox>{trip.final_destination || '─'}</LineClampBox>
      </TableCell>
      <TableCell>
        <SpLabel>出張の目的</SpLabel>
        <LineClampBox>{trip.purpose || '─'}</LineClampBox>
      </TableCell>
      <TableCell>
        <SpLabel>旅程日程</SpLabel>
        {startDate && <span>{startDate}</span>}
        {startDate && endDate && <span>~</span>}
        {endDate && <span>{endDate}</span>}
      </TableCell>
      <TableCell>
        <SpLabel>申請金額</SpLabel>
        <strong>{totalPrice}</strong>
      </TableCell>
      <TableCell>
        <SpLabel>予約商品</SpLabel>
        {categories.map(category => (
          <TableCellRow key={category}>{category}</TableCellRow>
        ))}
      </TableCell>
      <TableCell>
        <SpLabel>申請日</SpLabel>
        {createdAt.map(time => (
          <span key={time}>{time}</span>
        ))}
      </TableCell>
      <TableCell>
        <SpLabel>旅費規程チェッカー</SpLabel>
        {trip.ruleErrors.length < 1 ? (
          <RuleErrorContent>
            <CostCheckMarkSuccess>
              <img src="/images/organization/trips/check.png" alt="ok" />
            </CostCheckMarkSuccess>
            <CostCheckTextSuccess>問題なし</CostCheckTextSuccess>
          </RuleErrorContent>
        ) : (
          <RuleErrors ruleErrors={trip.ruleErrors}>
            <RuleErrorContent>
              <CostCheckMarkDanger>
                <img src="/images/organization/trips/question.png" alt="question" />
              </CostCheckMarkDanger>
              <CostCheckTextDanger>
                <span>{trip.ruleErrors.length}件の規程違反</span>
              </CostCheckTextDanger>
            </RuleErrorContent>
          </RuleErrors>
        )}
      </TableCell>
      {status === 'applied' ? (
        <TableCell>
          <SpLabel>詳細</SpLabel>
          <Button href={`/application/in_advance/${trip.id}`} color="sub" size="small">
            詳細を確認する
          </Button>
        </TableCell>
      ) : status === 'approved' ? (
        <>
          <TableCell>
            <SpLabel>承認者</SpLabel>
            {approvers && approvers.length
              ? approvers.map(approver => <TableCellRow key={approver}>{approver}</TableCellRow>)
              : '─'}
          </TableCell>
          <TableCell>
            <SpLabel>承認理由</SpLabel>
            <LineClampBox>{approveReason || '─'}</LineClampBox>
          </TableCell>
        </>
      ) : (
        <TableCell>
          <SpLabel>却下理由</SpLabel>
          <LineClampBox>{rejectReason || '─'}</LineClampBox>
        </TableCell>
      )}
    </TableRow>
  );
};

const TableCellRow = styled.div``;

const TableCellGrayRow = styled.div`
  font-size: 0.9em;
  color: ${props => props.theme.grayTextColor};
`;

const CheckboxCell = styled(TableCell)`
  .vertical-centered-box {
    width: fit-content;
    margin: 0 1px;
  }

  ${media.sp`
    position: absolute;
    top: 12px;
    right: 12px;

    .MuiCheckbox-root {
      margin: 0;
      padding: 0;
    }
  `}
`;

const SpLabel = styled.label`
  display: none;

  ${media.sp`
    display: flex;
    gap: 8px;
  `}
`;

const RuleErrorContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const CostCheckMark = styled.div`
  display: inline-flex;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  min-width: 1.3em;
  align-items: center;
  justify-content: center;

  img {
    width: 1.1em;
    height: 1.1em;
  }
`;

const CostCheckMarkSuccess = styled(CostCheckMark)`
  background-color: #28a745;
`;

const CostCheckMarkDanger = styled(CostCheckMark)`
  background-color: ${props => props.theme.redColor};
`;

const CostCheckTextSuccess = styled.span`
  color: #28a745;
`;

const CostCheckTextDanger = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.redColor};

  .tooltip__content {
    width: 250px;
    top: auto;
    bottom: 110%;
  }
`;

export default InAdvanceApplicationRow;
