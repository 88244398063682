import { styled } from '@this/constants/themes';

export const TicketCountArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TicketCountTitle = styled.h2`
  margin-bottom: 0;
`;

export const TicketCountLabel = styled.div`
  margin-left: 10px;
`;
