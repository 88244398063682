import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type ReserveInfo from '@this/domain/reserve_info';
import { Text as AITText } from '@this/components/shared/ui/data_displays/typography';

import type User from '@this/domain/user/user';
import moment from '../../../../lib/moment';

import ReservingTrip from '../../../../domain/trip/reserving_trip';

import Text from '../../../shared/text/text';
import ReserveConfirmOutlineItemTemplate from '../outline_item/outline_item.template';
import ReserveConfirmOutlineItemShortdistanceTemplate from '../outline_item/outline_item_shortdistance.template';

import ReserveConfirmOutlineDetailTemplate from '../outline_detail/outline_detail.template';

type Props = {
  userInfo: User;
  reserveInfo: ReserveInfo;
  peoplenum: number;
  roomnum: number | undefined;
  // queries: { stay_days: number };
  // shinkansenTooLate: boolean;
};

const ReserveConfirmOutlineTemplate: React.SFC<Props> = ({
  userInfo,
  reserveInfo,
  peoplenum,
  roomnum
  // queries
  // shinkansenTooLate
}) => {
  const reservingTrip = reserveInfo.reservingTrip;
  const travelers = reserveInfo.travelers;

  return (
    <div className="reserve-confirm__outline" id="right-navi-content">
      <ReserveConfirmRightSubTitle>予約内容の概要</ReserveConfirmRightSubTitle>
      <div className="reserve-confirm-right-wrapper">
        <ReserveConfirmRightSection>
          <AITText color="danger">
            下記が最終的なご依頼内容となります。日時や金額等、間違いがないかご確認ください。
          </AITText>
        </ReserveConfirmRightSection>
        <ReserveConfirmRightSection>
          <div className="reserve-confirm-right-title">日程</div>
          <div className="reserve-confirm-right-content">
            <p data-wovn-ignore>
              {moment(reservingTrip.startDate()).format('M月D日')}〜
              {moment(reservingTrip.endDate()).format('M月D日')}
            </p>
          </div>
        </ReserveConfirmRightSection>
        {reservingTrip.items && reservingTrip.items.length > 0 && (
          <>
            <ReserveConfirmRightSection>
              <ReserveConfirmTotalPriceBox>
                当サイトで予約可能な合計金額
                <ReserveConfirmTotalPriceBoxPrice data-wovn-ignore>
                  {utils.digits(reservingTrip.totalPrice())}円
                </ReserveConfirmTotalPriceBoxPrice>
              </ReserveConfirmTotalPriceBox>
            </ReserveConfirmRightSection>
            {reservingTrip.hasLegSummaries() && (
              <ReserveConfirmRightSection>
                <div className="reserve-confirm-right-title">【この旅程にかかる金額詳細】</div>
                <div className="reserve-confirm-right-content-note">
                  ※AIトラベル以外の提示している金額はあくまで目安です。
                </div>
              </ReserveConfirmRightSection>
            )}
            <ReserveConfirmOutlineBox>
              <ReserveConfirmOutlineBoxTitle>当サイトで予約可能な商品の詳細</ReserveConfirmOutlineBoxTitle>
              {reservingTrip.items.map(
                (item, i) =>
                  item && (
                    <ReserveConfirmOutlineItemTemplate
                      isPackage={!!reservingTrip.isPackage()}
                      key={i}
                      domesticAirPriceIndex={item.domesticAirPriceIndex}
                      element={item.element}
                      elementType={item.element_type}
                      elementRaw={item.element_raw}
                      foreignExist={item.foreignExist}
                      // shinkansenTooLate={shinkansenTooLate}
                      flightDescription={item.flightDescription()}
                      minirule={item.minirule()}
                      deadline={item.getDeadlineHour()}
                      flightPrice={item.totalPrice() || 0}
                      hotelPrice={item.totalPrice() || 0}
                      peoplenum={peoplenum}
                      roomnum={roomnum}
                      travelers={travelers}
                      index={item.index}
                    />
                  )
              )}
              <ReserveConfirmOutlineBoxHr />
              <ReserveConfirmRightContentTotal>
                {reservingTrip.isPackage() ? (
                  <ReserveConfirmTotalPrice data-wovn-ignore>
                    <Text data-wovn-ignore text={reservingTrip.tooltipText()} />
                  </ReserveConfirmTotalPrice>
                ) : (
                  <ReserveConfirmTotalPrice data-wovn-ignore>
                    {utils.digits(reservingTrip.totalPrice())}円(税込)
                  </ReserveConfirmTotalPrice>
                )}
              </ReserveConfirmRightContentTotal>
            </ReserveConfirmOutlineBox>
            {reservingTrip.hasLegSummaries() && (
              <>
                <ReserveConfirmOutlineBox>
                  <ReserveConfirmOutlineBoxTitle>その他経費目安</ReserveConfirmOutlineBoxTitle>
                  {reservingTrip.items.map(
                    (item, i) =>
                      item && (
                        <ReserveConfirmOutlineItemShortdistanceTemplate
                          element={item.element}
                          elementType={item.element_type}
                        />
                      )
                  )}
                  <ReserveConfirmOutlineBoxHr />
                  <ReserveConfirmRightContentTotal>
                    <ReserveConfirmTotalPrice data-wovn-ignore>
                      {utils.digits(reservingTrip.totalPriceShortDistance())}円(税込)
                    </ReserveConfirmTotalPrice>
                  </ReserveConfirmRightContentTotal>
                </ReserveConfirmOutlineBox>
                <ReserveConfirmOutlineBoxAllTotal>
                  <ReserveConfirmOutlineBoxTitleAllTotal>
                    この旅程でかかる費用目安
                  </ReserveConfirmOutlineBoxTitleAllTotal>
                  <ReserveConfirmTotalPriceAllTotal data-wovn-ignore>
                    {utils.digits(reservingTrip.totalPrice() + reservingTrip.totalPriceShortDistance())}円(税込)
                  </ReserveConfirmTotalPriceAllTotal>
                </ReserveConfirmOutlineBoxAllTotal>
              </>
            )}
            {reservingTrip.isAirPackage() && (
              <ReserveConfirmRightSection>
                <ReserveConfirmRightTitle>■旅行企画・実施</ReserveConfirmRightTitle>
                <ReserveConfirmRightContent>
                  <Text
                    text={
                      reservingTrip.isAnaAirPackage()
                        ? ReservingTrip.anaPackageDesc()
                        : reservingTrip.isJalAirPackage()
                        ? ReservingTrip.jalPackageDesc()
                        : ''
                    }
                  />
                </ReserveConfirmRightContent>
                <ReserveConfirmRightTitle>■受託販売会社</ReserveConfirmRightTitle>
                <ReserveConfirmRightContent>
                  <Text text={ReservingTrip.consignmentSaleDesc(userInfo.service.consignment_sale_tel)} />
                </ReserveConfirmRightContent>
              </ReserveConfirmRightSection>
            )}
            <br />
            <ReserveConfirmRightSection>
              <div className="reserve-confirm-right-title">経路詳細</div>
              <ReserveConfirmOutlineDetailTemplate reservingTrip={reservingTrip} />
            </ReserveConfirmRightSection>
          </>
        )}
      </div>
    </div>
  );
};

const ReserveConfirmRightSubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ReserveConfirmRightSection = styled.div`
  margin-bottom: 15px;
`;

const ReserveConfirmRightTitle = styled.div`
  font-weight: bold;
`;

const ReserveConfirmRightContent = styled.div`
  margin-left: 10px;
`;

const ReserveConfirmRightContentTotal = styled.div`
  text-align: right;
  padding: 5px;
`;

const ReserveConfirmTotalPrice = styled.div`
  font-size: 13px;
  font-weight: bold;
`;

const ReserveConfirmTotalPriceBox = styled.div`
  background-color: #feffd2;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReserveConfirmTotalPriceBoxPrice = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #dc2626;
`;

const ReserveConfirmOutlineBox = styled.div`
  border: 1px solid #d1d5db;
  margin-top: -1px;
`;

const ReserveConfirmOutlineBoxTitle = styled.div`
  padding: 10px;
  font-weight: bold;
  background-color: #f9fafb;
`;

const ReserveConfirmOutlineBoxHr = styled.hr`
  margin: 4px 0;
`;

const ReserveConfirmOutlineBoxAllTotal = styled.div`
  border: 1px solid #d1d5db;
  margin-top: -1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReserveConfirmOutlineBoxTitleAllTotal = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 13px;
  background-color: #e5e7eb;
  width: 55%;
`;

const ReserveConfirmTotalPriceAllTotal = styled.div`
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
`;

export default ReserveConfirmOutlineTemplate;
