import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type Flight from '../../../../domain/flight/flight';

import Text from '../../../shared/text/text';

type Props = {
  flight: Flight | null | undefined;
  // shinkansenTooLate: boolean;
};

class ReserveConfirmFlightTr extends React.Component<Props> {
  render() {
    try {
      const { flight } = this.props;

      return (
        <>
          {flight ? (
            <>
              <Text text={flight.description} />
              {flight.priceDetailArray().map((d: any, i: number) => (
                <PriceDetail key={i}>
                  <p>{d.title}</p>
                  <p>{d.price}</p>
                </PriceDetail>
              ))}
            </>
          ) : (
            '-'
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const PriceDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
`;

export default ReserveConfirmFlightTr;
