import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import { styled } from '@this/constants/themes';

import type User from '@this/domain/user/user';
import { ByMedia } from '@this/shared/ui/other/by_media';
import type { AvailableRepository } from '@this/domain/available_repository';
import PropTypes from 'prop-types';
import SearchResult from '@this/src/domain/search_result';
import type { SelectResponse } from '@this/src/domain/select_repository';
import { Fetcher, HTTPError, objectToFormData, trackClick, trackView } from '@this/src/util';
import SearchQuery from '../../../domain/search_query';
import type SearchQueryItem from '../../../domain/search_query_item';
import SelectRepository from '../../../domain/select_repository';
import type { SelectType, Airline } from '../../../domain/select_store';
import SelectStore from '../../../domain/select_store';

import SelectPcTemplate from './select_pc.template';
import SelectSpTemplate from './select_sp.template';
import SelectPcShortdistanceTemplate from './select_pc_shortdistance.template';
import SelectSpShortdistanceTemplate from './select_sp_shortdistance.template';

interface SelectProps extends RouteComponentProps {
  user: User;
  availableOptions: string[];
  serviceId: number;
  query: SearchQuery;
  availableRepos: AvailableRepository[];
  theme: { themeClass: string };
}

interface SelectState {
  store: SelectStore;
  repository: SelectRepository;
  additionalSearchErrors?: { [k in string]: string };
  selectErrors: string[];
  submitting: boolean;
  showArrangementRequestForm: boolean;
  showSearchBox: boolean;
  shortdistance: boolean;
}

interface ShortdistanceJson {
  shortdistance: boolean;
}
class Select extends React.Component<SelectProps, SelectState> {
  constructor(props: SelectProps) {
    super(props);
    const store = new SelectStore({ query: props.query }, this.props.user, this.props.availableRepos);

    this.state = {
      store,
      repository: new SelectRepository({ store }),
      selectErrors: [],
      submitting: false,
      showArrangementRequestForm: false,
      showSearchBox: false,
      shortdistance: false
    };
  }

  getChildContext() {
    return {
      query:
        this.props.query ||
        new SearchQuery(Object.assign(utils.getParams(), { availableRepos: this.props.availableRepos }))
    };
  }

  async componentDidMount() {
    trackView('/select');
    this.props.query.applyParams(utils.getParams());
    this.state.store.result = new SearchResult({ query: this.props.query });

    // FIXME: any
    ($('.lead') as any).textillate({
      loop: true,
      in: {
        effect: 'fadeIn',
        delayScale: 1.5,
        delay: 50,
        sync: false,
        shuffle: false
      },
      out: {
        effect: 'fadeOut',
        delayScale: 1.5,
        delay: 50,
        sync: false,
        shuffle: false
      }
    });
    await this.fetchShortdistance();
    this.state.repository.startSearch(this.props.theme.themeClass);
  }

  async componentDidUpdate() {
    const params = utils.getParams();
    if (params.rebuild === 'true') {
      this.props.query.applyParams(params);
      this.state.store.result = new SearchResult({ query: this.props.query });
      history.replaceState(null, '', location.pathname + location.search.replace(/&?rebuild=true/, ''));
      this.state.repository.startSearch(this.props.theme.themeClass);
    }
  }

  handleAddItem = (item_type: 'transport' | 'hotel', e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.query.addItem(item_type);
  };

  handleRemoveLastItem = () => {
    this.props.query.removeItem(this.props.query.items.length - 1);
  };

  handleAdditionalSearch = (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      this.props.query.validationPromise();
      this.props.history.push(`/select${this.props.query.getQueryString()}`);
      const resultItem = this.state.store.result.addItem(queryItem);
      resultItem.setLoading(true);
      this.state.repository.startPartialSearch(queryItem);
    } catch (e) {
      this.setState({
        additionalSearchErrors: e
      });
    }
  };

  handleSelectType = (type: SelectType) => () => {
    this.state.store.result.setType(type);
    this.updateUrl();
  };

  handleAirline = (airline: Airline) => () => {
    this.state.store.result.setType('airPackage');
    this.state.store.result.setAirline(airline);
    this.updateUrl();
  };

  handleSelectItem = () => {
    this.setState({ selectErrors: [] });
    this.updateUrl();
  };

  private updateUrl() {
    let href = '/select';
    href += this.props.query.getQueryString();
    if (this.state.store.result) {
      href += this.state.store.result.getQueryString();
    }
    this.props.history.push(href);
    const params = utils.getParams();
    _.map(params.items, (item, i) => {
      if (item.item_type === 'hotel') {
        _.merge(item, {
          prefecture: this.state.repository.getPrefecture(Number(i))
        });
      }
      return item;
    });
  }

  handleNextButtonClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.state.submitting) {
      return;
    }
    trackClick('/select next page button');
    const errors = this.state.store.result.errors(this.props.query);
    if (errors) {
      this.setState({ selectErrors: errors });
      return;
    }

    this.setState({
      submitting: true,
      selectErrors: []
    });

    if (this.state.store.needReplaceSegments) {
      const segmentUpdated = await this.updateTransitSegments();
      if (!segmentUpdated) return;
    }

    // APIがFormDataを期待しているため
    Fetcher.post('/reserve_confirm.json', objectToFormData(this.state.store.result.confirmParams())).then(
      () => {
        this.setState(
          {
            submitting: false
          },
          () => {
            this.props.history.push(
              `/reserve_confirm?search_query_id=${this.props.query.items[0]?.searchQueryId}`
            );
          }
        );
      },
      error => {
        let errors = [];
        if (error instanceof HTTPError && error.response?.data.errors) {
          errors = error.response.data.errors;
        } else {
          errors.push('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
        }
        this.setState({
          submitting: false,
          selectErrors: errors
        });
      }
    );
  };

  updateTransitSegments = async () => {
    return Fetcher.put('/update_transit_segments.json', this.state.store.result.updateTransitSegmentsParams())
      .then(
        () => true,
        error => {
          let errors = [];
          if (error instanceof HTTPError && error.response?.data.errors) {
            errors = error.response.data.errors.map((error: { message: string }) => error.message);
          } else {
            errors.push('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
          }
          this.setState({
            submitting: false,
            selectErrors: errors
          });
          return false;
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  };

  handleReSearch = (query: SearchQuery) => {
    query.clearFlightFilter();
    Fetcher.post('/search_histories', query.searchHistoryParams());
    if (query.searchType() === 'rentalCar') {
      Fetcher.post<SelectResponse>(`/select${query.getQueryString()}`, {}).then(res => {
        this.props.history.push(`/reserve_confirm?search_query_id=${res.id}`);
      });
    } else {
      this.props.history.push(`/select${query.getQueryString()}`);

      const store = new SelectStore({ query }, this.props.user, this.props.availableRepos);
      this.setState({ store, repository: new SelectRepository({ store }) }, () =>
        this.state.repository.startSearch(this.props.theme.themeClass)
      );
    }
  };

  handleToggleArrangementRequestForm = () => {
    this.setState({ showArrangementRequestForm: !this.state.showArrangementRequestForm });
  };

  toggleShowSearchBox = () => {
    this.setState({ showSearchBox: !this.state.showSearchBox });
  };

  handleOpenShowSearchBox = () => {
    this.setState({ showSearchBox: true });
  };

  async fetchShortdistance() {
    try {
      const response = await Fetcher.get<ShortdistanceJson>('/shortdistance.json');
      this.setState({
        shortdistance: response.shortdistance
      });
    } catch (error) {
      const errors = [];
      errors.push('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      this.setState({
        selectErrors: errors
      });
    }
  }

  render() {
    return (
      <div className="select">
        <ByMedia>
          {matches =>
            matches.md || matches.sm ? (
              <div className="select__sp">
                {this.state.shortdistance ? (
                  <SelectSpShortdistanceTemplate
                    {...this.state}
                    {...this.props}
                    handleSelectType={this.handleSelectType}
                    handleAirline={this.handleAirline}
                    handleNextButtonClick={this.handleNextButtonClick}
                    handleSelectItem={this.handleSelectItem}
                    handleAddItem={this.handleAddItem}
                    handleAdditionalSearch={this.handleAdditionalSearch}
                    handleReSearch={this.handleReSearch}
                    handleToggleArrangementRequestForm={this.handleToggleArrangementRequestForm}
                    toggleShowSearchBox={this.toggleShowSearchBox}
                    handleOpenShowSearchBox={this.handleOpenShowSearchBox}
                  />
                ) : (
                  <SelectSpTemplate
                    {...this.state}
                    {...this.props}
                    handleSelectType={this.handleSelectType}
                    handleAirline={this.handleAirline}
                    handleNextButtonClick={this.handleNextButtonClick}
                    handleSelectItem={this.handleSelectItem}
                    handleAddItem={this.handleAddItem}
                    handleAdditionalSearch={this.handleAdditionalSearch}
                    handleReSearch={this.handleReSearch}
                    handleToggleArrangementRequestForm={this.handleToggleArrangementRequestForm}
                    toggleShowSearchBox={this.toggleShowSearchBox}
                    handleOpenShowSearchBox={this.handleOpenShowSearchBox}
                  />
                )}
              </div>
            ) : (
              <SelectPc className="select__pc">
                {this.state.shortdistance ? (
                  <SelectPcShortdistanceTemplate
                    {...this.state}
                    {...this.props}
                    handleSelectType={this.handleSelectType}
                    handleAirline={this.handleAirline}
                    handleNextButtonClick={this.handleNextButtonClick}
                    handleSelectItem={this.handleSelectItem}
                    handleAddItem={this.handleAddItem}
                    handleRemoveItem={this.handleRemoveLastItem}
                    handleAdditionalSearch={this.handleAdditionalSearch}
                    handleReSearch={this.handleReSearch}
                    handleToggleArrangementRequestForm={this.handleToggleArrangementRequestForm}
                    toggleShowSearchBox={this.toggleShowSearchBox}
                    handleOpenShowSearchBox={this.handleOpenShowSearchBox}
                  />
                ) : (
                  <SelectPcTemplate
                    {...this.state}
                    {...this.props}
                    handleSelectType={this.handleSelectType}
                    handleAirline={this.handleAirline}
                    handleNextButtonClick={this.handleNextButtonClick}
                    handleSelectItem={this.handleSelectItem}
                    handleAddItem={this.handleAddItem}
                    handleRemoveItem={this.handleRemoveLastItem}
                    handleAdditionalSearch={this.handleAdditionalSearch}
                    handleReSearch={this.handleReSearch}
                    handleToggleArrangementRequestForm={this.handleToggleArrangementRequestForm}
                    toggleShowSearchBox={this.toggleShowSearchBox}
                    handleOpenShowSearchBox={this.handleOpenShowSearchBox}
                  />
                )}
              </SelectPc>
            )
          }
        </ByMedia>
      </div>
    );
  }

  static childContextTypes = {
    query: PropTypes.object // eslint-disable-line react/forbid-prop-types
  };
}

const SelectPc = styled.div`
  display: flex;
`;

export default withTheme(Select);
