import React from 'react';
import { styled } from '@this/constants/themes';
import type { ApprovalStage } from '@this/domain/expenses/approval_stage';
import type { ApprovalStatus } from '@this/domain/expenses/approval';

interface Props {
  onClose: () => void;
  approvalStages: ApprovalStage[];
}

export default class ApprovalSteps extends React.Component<Props> {
  render() {
    const { onClose, approvalStages } = this.props;
    const keys = {
      applied: '未承認',
      approved: '承認済み',
      rejected: '差し戻し',
      completed: '完了'
    };
    const approvalLabel = (v?: ApprovalStatus) => (v ? keys[v] : keys.applied);
    return (
      <Wrapper>
        <Header>承認ステップ</Header>
        {Array.from(new Set(approvalStages.map(s => s.approveStage))).map(stage => (
          <div key={stage}>
            <Stage>第{stage}承認者</Stage>
            {approvalStages
              .filter(s => s.approveStage === stage)
              .map((s, i) => (
                <ApprovalCandidate key={`${stage}_${i}`}>
                  {s.userName}：
                  <StatusLabel className={s.approval?.status || 'applied'}>
                    {approvalLabel(s.approval?.status)}
                  </StatusLabel>
                </ApprovalCandidate>
              ))}
          </div>
        ))}
        <ActionWrapper>
          <CancelButton onClick={onClose}>閉じる</CancelButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 12px;
  background: white;
`;

const Header = styled.p`
  background-color: #e5e5e5;
  border-radius: 5px;
  color: #707070;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;
`;

const Stage = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 16px;
  margin-left: 120px;
  text-align: center;
  width: 100px;
`;

const ApprovalCandidate = styled.div`
  font-size: 14px;
  text-align: center;
`;

const StatusLabel = styled.span`
  &.applied,
  &.rejected {
    color: #bf0101;
  }
  &.approved,
  &.completed {
    color: #00ab30;
  }
`;

const ActionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 72px;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const BaseButton = styled.button.attrs({ type: 'button' })`
  border-radius: 5px;
  font-size: 14px;
  height: 40px;
  width: 100px;
`;

const CancelButton = styled(BaseButton)`
  background: #b1b1b1;
  &&:hover,
  &&:focus {
    background: #b1b1b1;
    filter: brightness(0.85);
  }
`;
