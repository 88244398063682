import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import type ItemList from '@this/src/domain/approve_item/item_list';
import { Input } from '@this/src/components/shared/ui/inputs/input';

type Props = {
  approveItemList: ItemList;
};

const ApproveItemListForm = observer(({ approveItemList }: Props) => {
  return (
    <>
      <Field>
        <Input
          type="text"
          value={approveItemList.code}
          placeholder="項目コード"
          onChange={e => {
            approveItemList.code = e.target.value;
          }}
        />
      </Field>
      <Field>
        <Input
          type="text"
          value={approveItemList.name}
          placeholder="項目名"
          onChange={e => {
            approveItemList.name = e.target.value;
          }}
        />
      </Field>
      <Field>
        <textarea
          placeholder="利用可能部署コード"
          value={approveItemList.depCodes}
          onChange={e => {
            approveItemList.depCodes = e.target.value;
          }}
        />
        <Note>
          「部署コード/部署コード…」の形で構成されるようにしてください。
          <br />
          部署コードは、この項目を選択できるようにしたい費用負担部署のコードを入力ください。指定しない場合、すべてのユーザがこの項目を選択できるようになります。
        </Note>
      </Field>
    </>
  );
});

const Field = styled.div`
  margin-bottom: 20px;
`;

const Note = styled.p`
  color: ${(props: any) => props.theme.linkColor};
  font-size: 0.9em;
`;

export default ApproveItemListForm;
