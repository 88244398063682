import React from 'react';

interface Props {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  id: string;
}

interface State {}

class RadioField extends React.Component<Props, State> {
  render() {
    const { label, value, checked, id, onChange } = this.props;
    const classBase = 'form-elements-radio-field';

    return (
      <div className={`${classBase}`}>
        <input
          className={`${classBase}__radio`}
          type="radio"
          value={value}
          checked={checked}
          onChange={e => onChange(e.target.value)}
          id={id}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
}

export default RadioField;
