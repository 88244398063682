import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingIcon from '@material-ui/icons/Settings';

import { styled } from '@this/constants/themes';

import type { ChatbotDisplayMode } from '../mode';
import { baseColor } from '../styles';

interface Props {
  mode: ChatbotDisplayMode;
  onChangeMode: (mode: ChatbotDisplayMode) => void;
  onClose: () => void;
  forTerms?: boolean;
}

export const ChatbotHeader: React.FC<Props> = ({ mode, onChangeMode, onClose, forTerms }) => {
  return (
    <ExpandedHeader>
      <Left>
        {mode !== 'chat' && (
          <WhiteIconButton size="small" onClick={() => onChangeMode('chat')}>
            <ChevronLeftIcon />
          </WhiteIconButton>
        )}
        <Title>
          <Logo />
          <TitleText>
            <ServiceName>AIトラベル</ServiceName>
            <ChatbotName>AIコンシェルジュ</ChatbotName>
          </TitleText>
        </Title>
      </Left>
      <Right>
        {!forTerms && (
          <>
            <WhiteIconButton size="small" onClick={() => onChangeMode(mode === 'history' ? 'chat' : 'history')}>
              <ScheduleIcon />
            </WhiteIconButton>
            <WhiteIconButton size="small" onClick={() => onChangeMode(mode === 'setting' ? 'chat' : 'setting')}>
              <SettingIcon />
            </WhiteIconButton>
          </>
        )}
        <WhiteIconButton size="small" onClick={onClose}>
          <CloseIcon />
        </WhiteIconButton>
      </Right>
    </ExpandedHeader>
  );
};

const ExpandedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 16px;
  width: 100%;
  height: 56px;
  background: ${baseColor};
  border-radius: 16px 16px 0 0;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Logo = styled.span`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('/images/logo_mark.png');
  background-repeat: no-repeat;
  background-size: contain;
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceName = styled(Typography)`
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  line-height: 21.72px;
`;

const ChatbotName = styled(Typography)`
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  line-height: 15.93px;
`;

const WhiteIconButton = styled(IconButton)`
  color: #fff;
`;
