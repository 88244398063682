import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import FlightMarketLog from '../market_log/flight_market_log';
import type { FlightMarketLogJson } from '../market_log/flight_market_log';

export interface FlightMarketLogSummaryArgs {
  trip_id: number;
  market_log: FlightMarketLogJson;
  tomorrow_market_log: FlightMarketLogJson;
  yesterday_market_log: FlightMarketLogJson;
  received_at?: string;
  start_time?: string;
  lead_time: number;
}

class FlightMarketLogSummary {
  public tripId: number;

  public marketLog: FlightMarketLog;

  public tomorrowMarketLog: FlightMarketLog;

  public yesterdayMarketLog: FlightMarketLog;

  public receivedAt: Moment | null;

  public startTime: Moment | null;

  public leadTime: number;

  constructor(args: FlightMarketLogSummaryArgs) {
    this.tripId = args.trip_id;
    this.marketLog = new FlightMarketLog(args.market_log);
    this.tomorrowMarketLog = new FlightMarketLog(args.tomorrow_market_log);
    this.yesterdayMarketLog = new FlightMarketLog(args.yesterday_market_log);
    this.receivedAt = args.received_at ? moment(args.received_at) : null;
    this.startTime = args.start_time ? moment(args.start_time) : null;
    this.leadTime = args.lead_time;
  }
}

export default FlightMarketLogSummary;
