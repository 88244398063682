import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import type { TripUpdateResponse } from '@this/domain/trip/trip';
import A from '@this/components/shared/atoms/a';

import { Fetcher } from '@this/src/util/fetcher';
import StatusLabelTemplate from './status_label.template';

interface Props {
  trip: any;
  endTime: Date | null;
  serviceId: number;
}

interface State {}

class TripCardHead extends React.Component<Props, State> {
  handleChangeInternalNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { trip } = this.props;
    trip.setInternalNumber(e.target.value);
  };

  handleSubmitInternalNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { trip } = this.props;
    const params = { internal_number: trip.internalNumber };
    try {
      const res = await Fetcher.put<TripUpdateResponse>(`trips/${trip.id}/internal_number`, params);
      if (res.success) {
        alert('社内管理番号を保存しました');
      } else {
        alert(res.errors);
      }
    } catch {
      alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    }
  };

  render() {
    const { trip, endTime, serviceId } = this.props;
    return (
      <>
        <HeadText>
          <HeadTitle>
            旅程番号：<span data-wovn-ignore>{`${trip.id} (${trip.dateRange()})`}</span>
          </HeadTitle>
          <HeadSmall title={trip.originTitle()}>
            出発地：<span data-wovn-ignore>{`${trip.originText()}`}</span>
          </HeadSmall>
          <HeadSmall title={trip.destinationTitle()}>
            目的地：<span data-wovn-ignore>{trip.destinationText()}</span>
          </HeadSmall>
          {utils.isTabikobo(serviceId) && (
            <HeadForm onSubmit={this.handleSubmitInternalNumber}>
              <span>社内管理番号：</span>
              <input type="text" value={trip.internalNumber || ''} onChange={this.handleChangeInternalNumber} />
              <button type="submit">保存</button>
            </HeadForm>
          )}
          <HeadSmall title={trip.createdAt('YYYY/MM/DD HH:mm')}>
            旅程作成日時：<span data-wovn-ignore>{trip.createdAt('YYYY/MM/DD HH:mm')}</span>
          </HeadSmall>
        </HeadText>
        <HeadStatusGroup>
          <HeadStatusBlock>
            {trip.totalPrice() ? (
              <TotalPrice>
                <span>合計</span>
                <span data-wovn-ignore>{utils.digits(trip.totalPrice())}</span>
                <span>円</span>
              </TotalPrice>
            ) : (
              <TotalPrice>合計：確認中</TotalPrice>
            )}
            <HeadStatus>{!trip.draft && <StatusLabelTemplate trip={trip} endTime={endTime} />}</HeadStatus>
            {trip.freee_approval_request_id && trip.status !== 12 && (
              <HeadStatus>
                <HeadButton
                  href={`https://secure.freee.co.jp/approval_requests/${trip.freee_approval_request_id}`}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  freeeワークフロー申請ページ
                </HeadButton>
              </HeadStatus>
            )}
          </HeadStatusBlock>
        </HeadStatusGroup>
      </>
    );
  }
}

const HeadText = styled.div`
  margin: auto 0;
`;

const HeadTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 2;

  ${media.sp`
    font-size: 12px;
  `}
`;

const HeadSmall = styled.p`
  color: ${props => props.theme.disabledColor};
  font-size: 11px;
  line-height: 1.6;

  ${media.sp`
    font-size: 10px;
  `}
`;

const HeadStatus = styled.div`
  margin: 0 0 5px auto;
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  width: 130px;
`;

const HeadForm = styled.form`
  display: flex;
  margin-top: 5px;
  @media screen and (max-width: 485px) {
    flex-wrap: wrap;
  }

  & span {
    line-height: 30px;
    width: 200px;
  }

  & input {
    margin: 0 5px;
  }

  & button {
    height: fit-content;
  }
`;

const HeadButtonBase = styled.form`
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  display: block;
  margin-left: 5px;
  line-height: 12px;
`;

const HeadButton = styled(A)`
  ${HeadButtonBase}
`;

const HeadStatusGroup = styled.form`
  margin-left: auto;
`;

const HeadStatusBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TotalPrice = styled.p`
  color: ${props => props.theme.textColor};
  font-weight: bold;
`;

export default TripCardHead;
