import React from 'react';

import { styled } from '@this/constants/themes';
import { ModalFooter, ModalBody, Modal, MODAL_SIZE } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import type SearchQueryItem from '@this/domain/search_query_item';
import type TransitList from '@this/domain/transit/transit_list';
import CheckboxMultiSelector from '../checkbox_multi_selector/checkbox_multi_selector';

interface Props {
  queryItem?: SearchQueryItem;
  listWrapper: TransitList;
  showUpgradeSeatBox: boolean;
  open: boolean;
  onSubmit: (
    filteredUpgradeSeat: boolean,
    carrierIds: string[],
    changeablePrices: string[],
    showConnectingAir: boolean
  ) => void;
  onAbort: () => void;
}
const FlightCondition: React.FC<Props> = ({ open, ...props }) => {
  return (
    <Modal open={open} onClose={props.onAbort} size={MODAL_SIZE.small}>
      <FlightConditionModalContent {...props} />
    </Modal>
  );
};

const FlightConditionModalContent = ({
  queryItem,
  listWrapper,
  showUpgradeSeatBox,
  onSubmit,
  onAbort
}: Omit<Props, 'open'>) => {
  if (queryItem) {
    queryItem.showConnectingAir = listWrapper.isShowConnectingAir();
  }
  const [filteredUpgradeSeat, setFilteredUpgradeSeat] = React.useState(
    queryItem ? queryItem.filteredUpgradeSeat : false
  );
  const [carrierIds, setCarrierIds] = React.useState(
    queryItem && queryItem.carrierIds.length > 0 ? queryItem.carrierIds : listWrapper.allAirLineName
  );
  const [changeablePrices, setChangeablePrices] = React.useState(
    queryItem && queryItem.changeablePrices.length > 0
      ? queryItem.changeablePrices
      : ['changeable', 'unchangeable']
  );
  const [showConnectingAir, setShowConnectingAir] = React.useState(
    queryItem ? queryItem.showConnectingAir : false
  );

  const convertItemHash = (strList: string[]) => {
    return strList.map(str => {
      return { label: str, value: str };
    });
  };
  return (
    <>
      <ModalBody>
        {showUpgradeSeatBox && (
          <SubWrapper>
            <ListRowTitle>座席クラス</ListRowTitle>
            <ListRowContent>
              <label>
                <input
                  type="checkbox"
                  checked={filteredUpgradeSeat}
                  onChange={e => setFilteredUpgradeSeat(e.target.checked)}
                />
                アップグレード座席を表示する
              </label>
            </ListRowContent>
          </SubWrapper>
        )}
        <SubWrapper>
          <ListRowTitle>航空会社</ListRowTitle>
          <ListRowContent>
            <CheckboxMultiSelector
              items={convertItemHash(listWrapper.allAirLineName)}
              selectedValues={carrierIds}
              selectableAll
              selectableLabel="航空会社"
              onChange={(values: string[]) => setCarrierIds(values)}
            />
          </ListRowContent>
        </SubWrapper>
        <SubWrapper>
          <ListRowTitle>チケット変更</ListRowTitle>
          <ListRowContent>
            <CheckboxMultiSelector
              items={[
                { label: '変更可', value: 'changeable' },
                { label: '変更不可', value: 'unchangeable' }
              ]}
              selectedValues={changeablePrices}
              selectableAll={false}
              onChange={(values: string[]) => setChangeablePrices(values)}
            />
          </ListRowContent>
        </SubWrapper>
        <SubWrapper>
          <ListRowTitle>直行便</ListRowTitle>
          <ListRowContent>
            <label>
              <input
                type="checkbox"
                checked={showConnectingAir}
                onChange={e => setShowConnectingAir(e.target.checked)}
              />
              直行便のみ表示する
            </label>
          </ListRowContent>
        </SubWrapper>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onAbort}>
          キャンセル
        </Button>
        <Button onClick={() => onSubmit(filteredUpgradeSeat, carrierIds, changeablePrices, showConnectingAir)}>
          絞り込み
        </Button>
      </ModalFooter>
    </>
  );
};

const SubWrapper = styled.div`
  flex-direction: row;
  margin-bottom: 20px;
`;

const ListRowTitle = styled.div`
  font-size: 12px;
  color: #3a3a3a;
  font-weight: bold;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  margin: 6px;
`;

const ListRowContent = styled.div`
  font-size: 12px;
  margin: 6px 6px 6px 20px;
`;

export default FlightCondition;
