import { computed, observable } from 'mobx';
import type { InvoiceStandardFieldArgs } from './invoice_standard_field';
import InvoiceStandardField from './invoice_standard_field';

const DISPLAY_NAMES = {
  invoice_journal_adjusteds: '会計用データ',
  departments: '部署マスタ',
  projects: 'プロジェクトマスタ'
} as const;

const FIELD_NAMES = {
  invoice_journal_adjusteds: {
    // invoice_id: '',
    price_without_tax: '税抜金額',
    tax: '消費税額',
    price_with_tax: '税込金額',
    include_tax: '内税外税',
    // tax_type_id: '',
    tax_type_name: '税区分',
    item_name: '品目',
    trip_id: '手配番号',
    // project_id: '',
    project_code: 'プロジェクトコード',
    project_name: 'プロジェクト名',
    project_share_name: 'プロジェクト按分名',
    internal_number: '社内管理番号',
    ordered_at: '予約日',
    received_at: '依頼日',
    start_at: '利用開始日',
    end_at: '利用終了日',
    trip_user: '予約者',
    reserver_employee_number: '予約者社員番号',
    traveler: '出張者',
    express_outsourcing_trip_no: '出張番号',
    employee_number: '社員番号',
    // department_id: '',
    department_code: '部署コード',
    department_name: '部署名',
    // charging_department_id: '',
    charging_department_code: '費用負担先部署コード',
    charging_department_name: '費用負担先部署名',
    charging_department_share_name: '費用負担先部署按分名',
    approve_items: 'カスタム申請項目',
    trip_place: '出張先',
    trip_purpose: '目的',
    trip_invoice_trip_type: '出張種別',
    invoice_trip_type_tax: '出張種別（税対応）',
    main_type: '大項目',
    sub_type: '中項目',
    summary: '内容',
    payment_type: '支払い',
    flight_tax: '空港税等',
    address: 'ホテル住所',
    country: '旅費規程エリア: 国',
    region: '旅費規程エリア: 地方',
    prefecture: '旅費規程エリア: 県',
    municipality: '旅費規程エリア: 市区町村',
    hotel_price_limit: '規程金額',
    private_use_desc: 'プライベート利用',
    debt_account_type_code: '借方勘定科目コード',
    debt_account_type_name: '借方勘定科目',
    credit_account_type_code: '貸方勘定科目コード',
    credit_account_type_name: '貸方勘定科目',
    invoice_registration_number: 'インボイス登録番号',
    grade_name: '役職',
    // created_at: '',
    // updated_at: '',
    // invoice_split_target_id: '',
    invoice_split_target_name: '分割請求先'
  },
  departments: {
    code: '部署コード',
    name: '部署名'
  },
  projects: {
    code: 'プロジェクトコード',
    name: 'プロジェクト名'
  }
} as const;

export interface InvoiceStandardTableArgs {
  id: number;
  name: string;
  invoice_standard_fields: InvoiceStandardFieldArgs[];
}

class InvoiceStandardTable {
  @observable
  id: number;

  @observable
  name: string;

  @observable
  departmentTarget: 'user_department' | 'charging_department' = 'user_department';

  invoiceStandardFields: InvoiceStandardField[];

  constructor(args: InvoiceStandardTableArgs) {
    this.id = args.id;
    this.name = args.name;
    const fieldNames: Record<string, string> = FIELD_NAMES[this.name as keyof typeof FIELD_NAMES];
    this.invoiceStandardFields = args.invoice_standard_fields
      .filter(f => f.name in fieldNames)
      .map(f => new InvoiceStandardField(Object.assign(f, { display_name: fieldNames[f.name] })));
  }

  @computed
  get displayName() {
    return DISPLAY_NAMES[this.name as keyof typeof DISPLAY_NAMES];
  }

  @computed
  get tableName() {
    if (this.isDepartmentTable) {
      return this.departmentTarget === 'user_department' ? 'departments' : 'charging_departments';
    }
    return this.name;
  }

  @computed
  get isDepartmentTable() {
    return this.name === 'departments';
  }
}

export default InvoiceStandardTable;
