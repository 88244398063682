import React from 'react';
import { TextareaAutosize, useFormControl } from '@material-ui/core';
import type { TextareaAutosizeProps } from '@material-ui/core/TextareaAutosize/TextareaAutosize';
import { useStyles } from './textarea.styles';

export const TEXTAREA_SIZE = {
  small: 'small',
  medium: 'medium'
} as const;
export type TextareaSize = typeof TEXTAREA_SIZE[keyof typeof TEXTAREA_SIZE];

export type TextareaProps = { size?: TextareaSize; error?: boolean; fullWidth?: boolean } & TextareaAutosizeProps;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      size = 'medium',
      error: rawError = false,
      fullWidth: rawFullWidth = false,
      disabled: rawDisabled = false,
      ...props
    },
    ref
  ) => {
    const muiFormControl = useFormControl();
    const disabled = muiFormControl?.disabled ? true : rawDisabled;
    const error = muiFormControl?.error ? true : rawError;
    const fullWidth = muiFormControl?.fullWidth ? true : rawFullWidth;

    const styles = useStyles({ size, error, fullWidth });
    // 利用側で「Mui」から始まるクラスが付与されているinputにスタイルを付与しているため、影響を受けないようにしておく
    // ライブラリが利用側に依存するのはよくないため、いずれ削除したい。
    const classNames = ['Mui-textarea-auto-size', className, styles.root];

    return <TextareaAutosize className={classNames.join(' ')} ref={ref} disabled={disabled} {...props} />;
  }
);
