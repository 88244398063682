interface FileArgs {
  id: number;
  file_name: string;
  created_at: string;
}

export interface ImportItemFileArgs {
  file: FileArgs;
  count: number;
}

export class ImportItemFile {
  id: number;

  fileName: string;

  createdAt: string;

  count: number;

  constructor(args: ImportItemFileArgs) {
    this.id = args.file.id;
    this.fileName = args.file.file_name;
    this.createdAt = args.file.created_at;
    this.count = args.count;
  }
}
