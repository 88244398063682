import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
import {
  BulkActionSection,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import Link from '@this/shared/atoms/link';
import CsvBulkDeleteMemberForm from '@this/components/organization/csv_bulk_action/csv_bulk_delete_member_form';
import FileFormat from '@this/components/organization/file_format';
import * as React from 'react';
import { styled } from '@this/constants/themes';
import DELETE_MEMBER_FORMATS from '@this/components/organization/csv_bulk_action/delete_member_file_formats';
import FileValidator from '@this/components/organization/csv_bulk_action/file_validator';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { DownloadType } from '@this/components/organization/csv_bulk_action/types';
import DownloadFormat from '@this/components/organization/csv_bulk_action/download_format';
import { Fetcher, HTTPError } from '@this/src/util';

interface Props {}

interface State {
  loading: boolean;

  validateResult?: FileValidatorResult;

  uploadResult?: { uploaded: boolean; errors?: string[] };

  downloadType: DownloadType;
}

class CsvBulkDeleteMember extends React.Component<Props, State> {
  historyPath = '/organization/bulk_action_statuses?actionType=delete_users';

  backPath = '/organization/members';

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      downloadType: navigator.platform.indexOf('Win') !== -1 ? DownloadType.SJIS : DownloadType.UTF8
    };
  }

  downloadFormat = () => {
    location.href = `/organization/member/csv/bulk_delete/template?encoding=${this.state.downloadType}`;
  };

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload(`/organization/member/csv/bulk_delete`, formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }

  render() {
    const { loading, validateResult, uploadResult } = this.state;
    return (
      <>
        <OrganizationTitle>社員一括削除</OrganizationTitle>
        <OrganizationBody>
          <Description>
            <p>
              CSVファイルをアップロードすることで、社員を一括削除することができます。
              <br />
              削除後は元に戻すことができないため、実行する際は必ず内容の確認をお願い致します。
            </p>
          </Description>
          <DownloadFormat
            downloadFormat={this.downloadFormat}
            onSelect={type => this.setState({ downloadType: type })}
            dataEnabled={false}
          />
          <BulkActionSections>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>アップロード</h3>
                <Link to={this.historyPath}>アップロード履歴</Link>
              </BulkActionSectionHeader>
              <SectionContent>
                <CsvBulkDeleteMemberForm
                  loading={loading}
                  historyPath={this.historyPath}
                  backPath={this.backPath}
                  validateResult={validateResult}
                  uploadResult={uploadResult}
                  dropZoneStyle={{ height: '532px' }}
                  select={this.checkFile}
                  upload={this.upload}
                />
              </SectionContent>
            </BulkActionSection>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>フォーマット</h3>
              </BulkActionSectionHeader>
              <SectionContent>
                <FileFormat formats={DELETE_MEMBER_FORMATS} />
              </SectionContent>
            </BulkActionSection>
          </BulkActionSections>
        </OrganizationBody>
      </>
    );
  }
}

const Description = styled.div`
  p {
    margin-bottom: 5px;
  }
`;

const SectionContent = styled.div`
  height: 440px;
`;

export default CsvBulkDeleteMember;
