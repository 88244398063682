import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import Pagination from '@this/shared/pagination/pagination';
import SearchForm from './search_form';
import type { ItemJson } from '../../../domain/expenses/item';
import { Item } from '../../../domain/expenses/item';
import type { CutoffDate, CutoffDateJson } from '../../../domain/expenses/cutoff_date';
import ExpensesMain from '../main/main';
import ReportItemCards from './report_item_cards';
import Message from '../message/onboard_message';
import Announcement from '../announcement/announcement_message';

interface ReportItemsResponse {
  items: ItemJson[];
  payees: string[];
  trip_reports: [number, string][];
  cutoff_dates: CutoffDateJson[];
  total_page: number;
  all_count: number;
  trip_report_available: boolean;
}

type Status = '' | 'draft' | 'applied' | 'approved' | 'rejected';

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  items: Item[];
  payees: string[];
  tripReports: [number, string][];
  cutoffDates?: CutoffDate[];
  error?: string;
  loading: boolean;
  // コピー関連
  isSelectingItemState: boolean;
  // 検索関連
  showSearchModal: boolean;
  paidAtFrom: string;
  paidAtTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  selectedStatus: Status;
  tripReportId: string;
  // ページネーション関連
  currentPage: number;
  totalPage: number;
  // オプション関連
  tripReportAvailable: boolean;
}

class ReportItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      payees: [],
      tripReports: [],
      loading: true,
      isSelectingItemState: false,
      showSearchModal: false,
      paidAtFrom: '',
      paidAtTo: '',
      selectedPayee: '',
      priceFrom: '',
      priceTo: '',
      selectedStatus: '',
      tripReportId: '',
      currentPage: 1,
      totalPage: 1,
      tripReportAvailable: false
    };
  }

  async componentDidMount() {
    await this.mountSearchParams();
    await this.handleSearchItems();
    mixpanel.track('[Biztra][view] /report_items');
  }

  private handleAddItem = () => {
    this.props.history.push('/biztra/report_items/new');
  };

  private handleEditItem = (item: Item) => {
    this.props.history.push(`/biztra/report_items/${item.id}/edit`);
  };

  private handleAddItemFromHistory = (item: Item) => {
    this.props.history.push(`/biztra/report_items/new?id=${item.id}`);
  };

  private mountSearchParams = async () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const status = searchParams.get('selectedStatus') || '';
    this.setState({
      paidAtFrom: searchParams.get('paidAtFrom') || '',
      paidAtTo: searchParams.get('paidAtTo') || '',
      selectedPayee: searchParams.get('selectedPayee') || '',
      priceFrom: searchParams.get('priceFrom') || '',
      priceTo: searchParams.get('priceTo') || '',
      selectedStatus: ['draft', 'applied', 'approved', 'rejected'].includes(status) ? (status as Status) : '',
      tripReportId: searchParams.get('tripReportId') || '',
      currentPage: parseInt(searchParams.get('currentPage') || '1', 10)
    });
  };

  private historyReplaceSearchParams = () => {
    const query = [
      'paidAtFrom',
      'paidAtTo',
      'selectedPayee',
      'priceFrom',
      'priceTo',
      'selectedStatus',
      'tripReportId',
      'currentPage'
    ];
    const params = Object.fromEntries(
      Object.entries(this.state).flatMap(([key, value]) =>
        query.includes(key) && value !== '' && value !== 1
          ? [[key, typeof value === 'number' ? value.toString() : value]]
          : []
      )
    );
    const searchParams = new URLSearchParams(params as { [k: string]: string });
    this.props.history.replace({ search: searchParams.toString() });
  };

  setSearchParams = async (
    paidAtFrom: string,
    paidAtTo: string,
    selectedPayee: string,
    priceFrom: string,
    priceTo: string,
    selectedStatus: Status,
    tripReportId: string,
    currentPage = 1
  ) => {
    await this.setState({
      paidAtFrom,
      paidAtTo,
      selectedPayee,
      priceFrom,
      priceTo,
      selectedStatus,
      tripReportId,
      currentPage,
      showSearchModal: false
    });
    await this.handleSearchItems();
  };

  private handleSearchItems = async () => {
    const { paidAtFrom, paidAtTo, selectedPayee, priceFrom, priceTo, selectedStatus, tripReportId, currentPage } =
      this.state;
    try {
      this.uncheckedItems();

      this.setState({ loading: true });
      const params = new URLSearchParams({
        paid_at_from: paidAtFrom,
        paid_at_to: paidAtTo,
        payee: selectedPayee,
        price_from: priceFrom,
        price_to: priceTo,
        status: selectedStatus,
        trip_report_id: tripReportId,
        page: currentPage.toString()
      });
      const response = await utils.jsonPromise<ReportItemsResponse>(`/biztra/report_items.json?${params}`);
      const items = response.items.map(item => new Item(item));
      this.setState({
        items,
        payees: response.payees,
        tripReports: response.trip_reports,
        loading: false,
        currentPage,
        totalPage: response.total_page,
        tripReportAvailable: response.trip_report_available
      });
      this.historyReplaceSearchParams();
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  };

  private uncheckedItems = () => {
    this.state.items.filter(i => i.selected).forEach(i => i.toggleSelected());
  };

  utils() {
    return (
      <BtnWrapper>
        {!this.state.isSelectingItemState && (
          <BtnArea className="expenses-content-main__button-area">
            <CreateNewButton onClick={this.handleAddItem}>新規登録</CreateNewButton>
            <OutlineButton
              onClick={e => {
                e.currentTarget.blur();
                this.setState({ isSelectingItemState: true });
              }}
            >
              履歴から登録
            </OutlineButton>
          </BtnArea>
        )}
      </BtnWrapper>
    );
  }

  filteredPaidAt() {
    return (
      <FilteredBox>
        <FilteredTitle>期間</FilteredTitle>
        <span>：</span>
        <span>{this.state.paidAtFrom}</span>
        <FromTo>～</FromTo>
        <span>{this.state.paidAtTo}</span>
      </FilteredBox>
    );
  }

  filteredPayee() {
    return (
      <FilteredBox>
        <FilteredTitle>取引先</FilteredTitle>
        <span>：</span>
        {this.state.selectedPayee && <span>{this.state.selectedPayee}</span>}
      </FilteredBox>
    );
  }

  filteredPrice() {
    return (
      <FilteredBox>
        <FilteredTitle>金額</FilteredTitle>
        <span>：</span>
        {this.state.priceFrom && <span>{this.state.priceFrom}円</span>}
        <FromTo>～</FromTo>
        {this.state.priceTo && <span>{this.state.priceTo}円</span>}
      </FilteredBox>
    );
  }

  filteredStatus() {
    const keys = {
      '': '',
      draft: '未申請',
      applied: '承認待ち',
      approved: '承認済み',
      rejected: '差し戻し'
    };
    const label = keys[this.state.selectedStatus];
    return (
      <FilteredBox>
        <FilteredTitle>ステータス</FilteredTitle>
        <span>：</span>
        {this.state.selectedStatus && <span>{label}</span>}
      </FilteredBox>
    );
  }

  filteredTripReportId() {
    const label = this.state.tripReports.find(([id]) => id.toString() === this.state.tripReportId)?.[1];
    return (
      <FilteredBox>
        <FilteredTitle>出張報告</FilteredTitle>
        <span>：</span>
        {this.state.tripReportId && <span>{label}</span>}
      </FilteredBox>
    );
  }

  render() {
    const {
      isSelectingItemState,
      items,
      paidAtFrom,
      paidAtTo,
      payees,
      tripReports,
      selectedPayee,
      priceFrom,
      priceTo,
      selectedStatus,
      tripReportId,
      loading,
      error,
      showSearchModal,
      currentPage,
      totalPage,
      tripReportAvailable
    } = this.state;

    return (
      <>
        <Announcement />
        <ExpensesMain
          title={isSelectingItemState ? '履歴から登録' : '登録経費一覧'}
          utils={this.utils()}
          noBackground={items.length === 0}
          noBackgroundSp
        >
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <Message />
              <FilteredWrapper className={items.length === 0 ? 'noItems' : ''}>
                <OpenSearchModalButton
                  onClick={e => {
                    e.currentTarget.blur();
                    this.setState({ showSearchModal: true });
                  }}
                >
                  検索条件を指定
                </OpenSearchModalButton>
                <FiltersWrapper>
                  {(!!paidAtFrom || !!paidAtTo) && this.filteredPaidAt()}
                  {!!selectedPayee && this.filteredPayee()}
                  {(!!priceFrom || !!priceTo) && this.filteredPrice()}
                  {!!selectedStatus && this.filteredStatus()}
                  {!!tripReportId && this.filteredTripReportId()}
                </FiltersWrapper>
              </FilteredWrapper>
              {isSelectingItemState ? (
                <ReportItemCards
                  items={items}
                  handleAddItem={this.handleAddItem}
                  withCopyButton={isSelectingItemState}
                  onClickCopyButton={this.handleAddItemFromHistory}
                  tripReportAvailable={tripReportAvailable}
                />
              ) : (
                <ReportItemCards
                  items={items}
                  handleAddItem={this.handleAddItem}
                  onClickItem={this.handleEditItem}
                  withCopyButton={isSelectingItemState}
                  onClickCopyButton={this.handleAddItemFromHistory}
                  tripReportAvailable={tripReportAvailable}
                />
              )}
            </>
          )}
          {!loading && totalPage > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              handleSearch={async (page: number) => {
                await this.setState({ currentPage: page });
                await this.handleSearchItems();
              }}
            />
          )}
        </ExpensesMain>
        <Modal
          size="medium"
          onClose={() => {
            this.setState({ showSearchModal: false });
          }}
          open={showSearchModal}
          PaperProps={{ style: { background: 'white' } }}
        >
          <SearchForm
            onSetSearchParams={this.setSearchParams}
            onCancel={() => this.setState({ showSearchModal: false })}
            payees={payees}
            tripReports={tripReports}
            paidAtFrom={paidAtFrom}
            paidAtTo={paidAtTo}
            selectedPayee={selectedPayee}
            priceFrom={priceFrom}
            priceTo={priceTo}
            selectedStatus={selectedStatus}
            tripReportId={tripReportId}
            tripReportAvailable={tripReportAvailable}
          />
        </Modal>
      </>
    );
  }
}

export default ReportItems;

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const BtnWrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
`;

const BtnArea = styled.div`
  @media screen and (max-width: 767px) {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const PrimaryButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const OutlineButton = styled.button.attrs({ type: 'button' })`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

const CreateNewButton = styled(PrimaryButton)`
  margin-right: 5px;
`;

const OpenSearchModalButton = styled(OutlineButton)`
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilteredWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  &.noItems {
    background: transparent;
    margin-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    &.noItems {
      margin-bottom: 24px;
    }
  }
`;

export const FilteredBox = styled.div`
  display: flex;
  flex-grow: 1;
`;

const FilteredTitle = styled.span`
  color: ${baseColor};
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-left: 12px;
`;

const FromTo = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;
