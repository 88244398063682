import React from 'react';
import _ from 'lodash';

import UPSERT_MEMBER_FORMATS from '@this/components/organization/csv_bulk_action/member_file_formats';
import type { BulkActionAttr } from '../types';
import { BulkActionAttrServiceType } from '../types';
import { OrganizationTable, OrganizationTd, OrganizationTh } from '../organization.style';

interface Props {
  status: any;
  expenses?: boolean;
}
interface State {
  changeAttrs: BulkActionAttr[];
}
export default class BulkUpsertActionUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      changeAttrs: UPSERT_MEMBER_FORMATS.reduce((array: BulkActionAttr[], f: BulkActionAttr) => {
        let isEnableServiceType = f.serviceType === BulkActionAttrServiceType.ALL;
        if (!isEnableServiceType) {
          isEnableServiceType = this.props.expenses
            ? f.serviceType === BulkActionAttrServiceType.BIZTRA
            : f.serviceType === BulkActionAttrServiceType.AI_TRAVEL;
        }

        if (isEnableServiceType && !['email', 'birthday', 'gender'].some(s => s === f.name)) {
          array.push(f);
        }
        return array;
      }, [])
    };
  }

  getStatusClass = (user: any): string => (user.isSuccess() ? 'green' : user.isFail() ? 'red' : 'gray');

  getChangeAttrText = (user: any, name: string): string => {
    const change = user.hasChange(name);
    if (change) {
      const beforeValTest = change.before == null ? '[未設定]' : this.convertAttrText(change.before, name);
      const afterValText = change.after == null ? '[削除]' : this.convertAttrText(change.after, name);
      return `${beforeValTest} → ${afterValText}`;
    }
    return '-';
  };

  convertAttrText = (attr: any, attrName: string): any => {
    switch (attrName) {
      case 'domestic_approval_required':
      case 'foreign_approval_required':
        return attr ? '必要' : '不要';
      case 'show_other_members':
        return attr ? '表示する' : '表示しない';
      default:
        return attr;
    }
  };

  render() {
    const { status } = this.props;
    const { changeAttrs } = this.state;
    return (
      <OrganizationTable>
        <thead>
          <tr>
            <OrganizationTh>アドレス</OrganizationTh>
            <OrganizationTh>ステータス</OrganizationTh>
            {changeAttrs.map((attr, i) => (
              <OrganizationTh key={i}>{attr.summary}</OrganizationTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {status.actionUsers.map((user: any, i: number) => (
            <tr key={i}>
              <OrganizationTd className="e2e-email">{user.email}</OrganizationTd>
              <OrganizationTd className={`e2e-status ${this.getStatusClass(user)}`}>
                {user.statusMessage()}
              </OrganizationTd>
              {changeAttrs.map((attr, i) => (
                <OrganizationTd key={i} className={`e2e-${_.replace(attr.name, /_/g, '-')}`}>
                  {this.getChangeAttrText(user, attr.name)}
                </OrganizationTd>
              ))}
            </tr>
          ))}
        </tbody>
      </OrganizationTable>
    );
  }
}
