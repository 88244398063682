import React from 'react';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import type { ImageFile } from 'react-dropzone';
import Dropzone from 'react-dropzone';

interface Props {
  selectedPath?: string;
  onSelect: (f: ImageFile) => void;
  onRemove: () => void;
}

const ReceiptDropzone = observer(({ selectedPath, onSelect, onRemove }: Props) => {
  return (
    <Wrapper>
      {selectedPath ? (
        <PreviewWrapper>
          <PreviewArea>
            <img src={selectedPath} />
          </PreviewArea>
          <div>
            <RemoveButton onClick={() => onRemove()}>×</RemoveButton>
          </div>
        </PreviewWrapper>
      ) : (
        <StyledDropzone onDrop={(files: ImageFile[]) => onSelect(files[0])} accept="image/*,application/pdf">
          <p>
            ここへ領収書ファイルをドラッグ&amp;ドロップ
            <br />
            もしくは
            <br />
            クリックして領収書ファイルを指定
          </p>
        </StyledDropzone>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledDropzone = styled(Dropzone)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  background: ${props => props.theme.accentColorLight};
  border: 1px dashed #ccc;
  margin: 10px 0 20px;
  cursor: pointer;
  padding: 20px;
`;

const PreviewWrapper = styled.div`
  display: flex;
`;

const PreviewArea = styled.div`
  border: 1px solid ${props => props.theme.grayBorderColor};
  width: fit-content;
`;

const RemoveButton = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};
  margin-left: 10px;
`;

export default ReceiptDropzone;
