import React from 'react';
import _ from 'lodash';

import type ArrangementRequestInfo from '@this/domain/arrangement_request_info/arrangement_request_info';

import ArrangementRequestHotelInfoForm from './arrangement_request_hotel_info_form';
import ArrangementRequestFlightInfoForm from './arrangement_request_flight_info_form';
import ArrangementRequestShinkansenInfoForm from './arrangement_request_shinkansen_info_form';
import ArrangementRequestRentalCarInfoForm from './arrangement_request_rental_car_info_form';
import ArrangementRequestOtherItemInfoForm from './arrangement_request_other_item_info_form';

import FilterableSelector from '../../../../shared/filterable_selector/filterable_selector';

export type Props = {
  requestInfo: ArrangementRequestInfo;
};

const ArrangementRequestInfoForm: React.SFC<Props> = ({ requestInfo }) => (
  <>
    {requestInfo.isAvailable('domestic_flight') && (
      <>
        <label>
          <input
            type="checkbox"
            checked={requestInfo.needDomesticFlight}
            onChange={requestInfo.toggleNeedDomesticFlight}
          />
          国内航空券の手配を依頼する
        </label>
        {requestInfo.needDomesticFlight && (
          <ArrangementRequestFlightInfoForm
            flightInfo={requestInfo.flightInfo}
            foreignFlight={false}
            packageFlight={false}
          />
        )}
      </>
    )}
    {requestInfo.isAvailable('foreign_flight') && (
      <>
        <label>
          <input
            type="checkbox"
            checked={requestInfo.needForeignFlight}
            onChange={requestInfo.toggleNeedForeignFlight}
          />
          海外航空券の手配を依頼する
        </label>
        {requestInfo.needForeignFlight && (
          <ArrangementRequestFlightInfoForm
            flightInfo={requestInfo.flightInfo}
            foreignFlight
            packageFlight={false}
          />
        )}
      </>
    )}
    {requestInfo.isAvailable('shinkansen') && (
      <>
        <label>
          <input
            type="checkbox"
            checked={requestInfo.needShinkansen}
            onChange={requestInfo.toggleNeedShinkansen}
          />
          新幹線の手配を依頼する
        </label>
        {requestInfo.needShinkansen && (
          <ArrangementRequestShinkansenInfoForm
            shinkansenInfo={requestInfo.shinkansenInfo}
            useBulkTicket={requestInfo.useBulkTicket}
            showEx={requestInfo.showEx}
          />
        )}
      </>
    )}
    {requestInfo.isAvailable('hotel') && (
      <>
        <label>
          <input type="checkbox" checked={requestInfo.needHotel} onChange={requestInfo.toggleNeedHotel} />
          ホテルの手配を依頼する
        </label>
        {requestInfo.needHotel && <ArrangementRequestHotelInfoForm hotelInfo={requestInfo.hotelInfo} />}
      </>
    )}
    {requestInfo.isAvailable('rentalcar') && (
      <>
        <label>
          <input type="checkbox" checked={requestInfo.needRentalcar} onChange={requestInfo.toggleNeedRentalcar} />
          レンタカーの手配を依頼する
        </label>
        {requestInfo.needRentalcar && (
          <ArrangementRequestRentalCarInfoForm rentalCarInfo={requestInfo.rentalCarInfo} />
        )}
      </>
    )}
    {requestInfo.isAvailable('other') && (
      <>
        <label>
          <input type="checkbox" checked={requestInfo.needOther} onChange={requestInfo.toggleNeedOther} />
          その他の商品の手配を依頼する
        </label>
        {requestInfo.needOther && (
          <ArrangementRequestOtherItemInfoForm otherItemInfo={requestInfo.otherItemInfo} />
        )}
      </>
    )}
    <div className="arrangement-request-form__field">
      <div className="reserve-confirm__traveler__input-area">
        <div className="reserve-confirm__label">社内管理番号</div>
        <div className="reserve-confirm__traveler__input-area-right">
          <input
            className="reserve-confirm__input"
            type="text"
            value={requestInfo.internalNumber}
            onChange={e => requestInfo.setInternalNumber(e.target.value)}
          />
        </div>
      </div>
      {requestInfo.projects && requestInfo.projects.list.length > 0 && (
        <div className="reserve-confirm__traveler__input-area">
          <div className="reserve-confirm__label">プロジェクト</div>
          <div className="reserve-confirm__traveler__input-area-right">
            <FilterableSelector
              placeholder="プロジェクトを検索"
              options={requestInfo.projects.list.map(p => {
                return { label: p.codeAndName(), value: p.id.toString(), matcher: p.codeAndName() };
              })}
              selected={requestInfo.getProjectOption()}
              onSelect={requestInfo.handleProjectSelect}
            />
          </div>
        </div>
      )}
    </div>
  </>
);

export default ArrangementRequestInfoForm;
