import React from 'react';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import HelpItem from './help_item.template';

type Props = {
  mainTitle: string;
  subTitle: string;
  items: { href: string; text: string }[];
  banners?: { href: string; src: string }[];
  isLoading?: boolean;
};

const HelpBlock: React.FC<Props> = ({ mainTitle, subTitle, items, banners, isLoading }) => (
  <Wrapper>
    <Header>
      <MainTitle>{mainTitle}</MainTitle>
      <SubTitle>{subTitle}</SubTitle>
    </Header>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <ul>
          {items.map((item, i) => (
            <TheHelpItem key={item.href} href={item.href} text={item.text} number={i + 1} />
          ))}
        </ul>
        {banners && (
          <Banners>
            {banners.map((b, i) => (
              <a key={i} href={b.href} rel="noopener noreferrer" target="_blank">
                <BannerImg src={b.src} />
              </a>
            ))}
          </Banners>
        )}
      </>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  flex-basis: 320px;
  flex-grow: 999;
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
`;

const MainTitle = styled.div`
  font-size: 22px;
  margin-right: 10px;
`;

const SubTitle = styled.div`
  font-size: 12px;
`;

const TheHelpItem = styled(HelpItem)`
  margin-bottom: 15px;
`;

const Banners = styled.div`
  text-align: center;
`;

const BannerImg = styled.img`
  @media screen and (min-width: 1030px) {
    width: 255px;
    margin-left: 15px;
  }

  @media screen and (max-width: 430px) {
    width: 90%;
  }
`;

export default HelpBlock;
