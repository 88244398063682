import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { Button } from '@this/shared/ui/inputs/button';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { SearchExpensesStatus } from '@this/src/domain/trip_report/trip_report_store';
import type { TripReport as TripReportClass } from '@this/src/domain/trip_report/trip_report';
import { Checkbox, Typography, lighten } from '@material-ui/core';
import Notification from '@this/src/notification';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import TripReportCard from './trip_report_card';
import TripHeader from '../trips/trip_header';
import { Wrap, BodyWrap, Body, BodyTitle, FooterWrap, FooterBody, FooterActions } from './shared/wrappers';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

interface Result {
  message: string;
  success: boolean;
  errors?: string[];
}

interface Counter {
  message: string;
  success: boolean;
  count: number;
  total: number;
}

const TripReportExpenses: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const location = useLocation<{ tripReport?: { id: number; message: string } }>();
  const history = useHistory();
  const checkboxesRef = useRef<number[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [counter, setCounter] = useState<Counter | null>(null);
  const [results, setResults] = useState<Result[]>([]);
  const [error, setError] = useState<string | null>(null);
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const tripReports = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);
  const selectedStatus = useMemo(() => searchParams.get('status') ?? 'uncreated', [searchParams]);

  const fetchTripReports = useCallback(() => {
    tripReports.getExpensesIndex(selectedStatus as SearchExpensesStatus | '' | undefined);
  }, []);

  const fetchChangeStatus = useCallback(
    (status: SearchExpensesStatus | '') => {
      tripReports.getExpensesIndex(status);
      searchParams.set('status', status);
      history.replace({ search: searchParams.toString() });
      checkboxesRef.current = [];
    },
    [searchParams, checkboxesRef]
  );

  const handleChange = useCallback(
    (tripReportId: number) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        checkboxesRef.current.push(tripReportId);
      } else {
        const index = checkboxesRef.current.findIndex(i => i === tripReportId);
        checkboxesRef.current.splice(index, 1);
      }
    },
    [checkboxesRef]
  );

  const handleExpensesReportItems = useCallback(async () => {
    setError(null);
    if (checkboxesRef.current.length < 1) {
      setError('登録する出張報告を選択してください');
      return;
    }

    setSubmitting(true);
    const results: Result[] = [];
    const total = checkboxesRef.current.length;
    setCounter({ message: '経費を連携しています', success: true, count: 0, total });

    let promise = Promise.resolve();
    checkboxesRef.current.forEach((tripReportId, count) => {
      const tripReport = tripReports.tripReports.find(tr => tr.id === tripReportId);
      if (!tripReport) {
        return;
      }

      promise = promise.then(() =>
        tripReports.createExpensesReport({
          tripReport,
          onSuccess: () => {
            const result = {
              message: `${tripReport.name}の経費を連携しました`,
              success: true
            };
            results.push(result);
            setResults(results);
            setCounter({ ...result, count: count + 1, total });
          },
          onError: errors => {
            const result = {
              message: `${tripReport.name}の経費を連携できませんでした`,
              success: false
            };
            results.push({ ...result, errors });
            setResults(results);
            setCounter({ ...result, count: count + 1, total });
          }
        })
      );
    });

    promise.then(() => {
      if (results.some(result => result.success)) {
        Notification.success('経費を連携しました');
      } else {
        Notification.error('経費を連携できませんでした');
      }
      fetchChangeStatus('created');
      setSubmitting(false);
      setCounter(null);
    });
  }, [checkboxesRef, setError, fetchChangeStatus]);

  const handleExpensesReport = useCallback((tripReport: TripReportClass, onError?: (errors: string[]) => void) => {
    tripReports.createExpensesReport({ tripReport, onError });
  }, []);

  useEffect(() => {
    fetchTripReports();

    if (location.state?.tripReport) {
      const state = location.state.tripReport;
      tripReports.setMessage(state.id, state.message);
    }
  }, []);

  return (
    <TripReportContext.Provider value={tripReports}>
      <Wrap>
        <BodyWrap>
          <div>
            <TripHeader selected="tripReport" tripReportAvailable />
            <Body>
              <BodyTitle>経費を一括連携する</BodyTitle>
              <Navigations>
                <SelectButtons>
                  {tripReports.selectExpensesStatusMenu().map(({ status, label }) => (
                    <SelectButton
                      key={status}
                      disabled={submitting}
                      className={selectedStatus === status ? 'selected' : ''}
                      onClick={() => !submitting && fetchChangeStatus(status)}
                    >
                      {label}
                    </SelectButton>
                  ))}
                </SelectButtons>
                <Actions>
                  <Button href="/trip_report" disabled={submitting}>
                    出張報告一覧に戻る
                  </Button>
                  <Button href="/biztra/report_items" isExternal disabled={submitting}>
                    ビズトラに移動
                  </Button>
                </Actions>
              </Navigations>
              {selectedStatus === 'created' && results.length > 0 && !submitting && (
                <ResultWrap>
                  {results.map(({ message, success, errors }, i) => (
                    <ResultRow key={`result-${i}`}>
                      <Typography color={success ? 'textPrimary' : 'error'}>{message}</Typography>
                      {errors && (
                        <ResultErrors>
                          {errors.map((error, j) => (
                            <Typography key={`resultError-${i}-${j}`} component="li" color="error">
                              {error}
                            </Typography>
                          ))}
                        </ResultErrors>
                      )}
                    </ResultRow>
                  ))}
                </ResultWrap>
              )}
              {tripReports.loading ? (
                <LoadingWrap>
                  <SimpleLoading />
                </LoadingWrap>
              ) : tripReports.errors.length > 0 ? (
                <Error>{tripReports.errors.join('\n')}</Error>
              ) : (
                <TripsWrap>
                  {tripReports.tripReports.length > 0 ? (
                    <ListWrap>
                      {tripReports.tripReports.map(tripReport => (
                        <TripReportCard
                          key={tripReport.id}
                          expenseTool={tripReports.setting?.expenseTool}
                          tripReport={tripReport}
                          message={tripReports.messages[tripReport.id]}
                          loading={submitting}
                          handleExpensesReport={handleExpensesReport}
                          linkProps={{ target: '_blank' }}
                        >
                          {selectedStatus !== 'created' && (
                            <StyledCheckbox
                              color="primary"
                              disabled={submitting || !tripReport.expensesConvertible()}
                              onChange={handleChange(tripReport.id)}
                            />
                          )}
                        </TripReportCard>
                      ))}
                    </ListWrap>
                  ) : (
                    <NoItem>進行中の出張報告はありません</NoItem>
                  )}
                  <ListWrap />
                </TripsWrap>
              )}
            </Body>
          </div>
        </BodyWrap>
        {selectedStatus !== 'created' && (
          <FooterWrap>
            <FooterBody>
              <FooterActions>
                <Button loading={submitting} onClick={handleExpensesReportItems}>
                  経費を一括連携する
                </Button>
                {counter && (
                  <Typography color={counter.success ? 'textPrimary' : 'error'}>
                    進捗({counter.count}/{counter.total})：{counter.message}
                  </Typography>
                )}
                {error && <Typography color="error">{error}</Typography>}
              </FooterActions>
            </FooterBody>
          </FooterWrap>
        )}
      </Wrap>
    </TripReportContext.Provider>
  );
};

const Navigations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.sp`
    flex-flow: column-reverse;
    gap: 24px;
  `}
`;

const ResultWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  margin-top: 16px;
  border: solid 3px ${props => props.theme.grayBorderColor};
  background-color: ${props => props.theme.grayBgColorLight};
`;

const ResultRow = styled.div``;

const ResultErrors = styled.ul`
  list-style: initial;
  padding-left: 28px;
  margin-top: 4px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 8px;
  margin-right: -8px;
`;

const SelectButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectButton = styled.div<{ disabled?: boolean }>`
  padding: 5px 10px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &.selected {
    color: #ffffff;
    background-color: #374151;
  }

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: ${lighten('#404040', 0.5)};
    background-color: ${lighten(props.theme.grayBgColor, 0.5)};

    &.selected {
      background-color: ${lighten('#374151', 0.5)};
    }
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const TripsWrap = styled.div`
  padding: 20px 0;
`;

const ListWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 25px;
`;

const NoItem = styled.p`
  margin: 0 auto;
  text-align: center;
`;

export default TripReportExpenses;
