import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';
import { CheckIcon, GraphIcon, headerHeight, ListIcon, SearchIcon } from '@this/shared/header/header.style';
import type User from '@this/domain/user/user';
import _ from 'lodash';
import Profile from '@this/shared/header/profile';
import { Badge } from '@material-ui/core';
import { getColor, getSpacing, getTypogragy, getZindex } from '@this/shared/ui/theme';

interface Props {
  user?: User | null;
  appliedCount: number;
}

const isTabSelected = (path: string, conditions: RegExp[]) => {
  let result = false;
  _.each(conditions, regexp => {
    if (regexp.test(path)) {
      result = true;
    }
  });
  return result;
};

const conditionsList = {
  search: [/^\/dashboard/, /select/, /reserve_confirm/, /draft_trips\/new/, /trips\/new/],
  trips: [/^\/trips/, /^\/trip_reports/],
  approval: [/application\/in_advance/],
  organization: [/organization/]
};

// ヘッダ右上に何文字まで表示するか
const wordBreakPoint = 11;

const getOrganizationName = (user: User) => {
  if (!user) {
    return '';
  }
  const organization = user.organization;
  if (!organization) {
    return '';
  }
  if (organization.name.length > wordBreakPoint) {
    return `${organization.name.slice(0, wordBreakPoint)}...`;
  }
  return organization.name;
};

const getUserName = (user: User) => {
  if (!user) {
    return '';
  }
  if (user.name.length > wordBreakPoint) {
    return `${user.name.slice(0, wordBreakPoint)}...`;
  }
  return user.name;
};

const HeaderContent: React.FC<Props> = ({ user, appliedCount }) => {
  const [showAccount, setShowAccount] = useState(false);
  const path = window.location.pathname;

  return (
    <Nav>
      {showAccount && user && <PopoverProfile user={user} />}
      {user && user.id && (
        <>
          <LinkButton to="/dashboard" selected={isTabSelected(path, conditionsList.search)}>
            <SearchIcon />
            検索・予約
          </LinkButton>
          <LinkButton to="/trips" selected={isTabSelected(path, conditionsList.trips)}>
            <ListIcon />
            旅程・履歴
          </LinkButton>
          {user.organizationId && (
            <>
              <StyledBudge badgeContent={appliedCount} color="error" max={9} invisible={!appliedCount}>
                <LinkButton to="/application/in_advance" selected={isTabSelected(path, conditionsList.approval)}>
                  <CheckIcon />
                  承認
                </LinkButton>
              </StyledBudge>
              {user.isOrganizationAdmin && (
                <LinkButton to="/organization" selected={isTabSelected(path, conditionsList.organization)}>
                  <GraphIcon />
                  管理・分析
                </LinkButton>
              )}
            </>
          )}
          <VerticalLine />
          <NameArea onClick={() => setShowAccount(!showAccount)}>
            <div>
              <span data-wovn-ignore>{getOrganizationName(user)}</span>
              <br />
              <span data-wovn-ignore>{getUserName(user)}</span>
            </div>
            <Triangle>▼</Triangle>
          </NameArea>
        </>
      )}
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  margin-left: auto;
  font-size: ${getTypogragy('fontSizes', 'body')}px;
  position: relative;
`;

const LinkButton = styled(Link)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 ${getSpacing(2)}px;
  height: ${headerHeight};
  min-width: 61px;
  & {
    color: ${({
      selected,
      theme: {
        tokens: { colors }
      }
    }) => (selected ? colors.brand.primary : colors.common.white)};
  }
  &:hover,
  &:focus {
    color: ${getColor('brand', 'hover')};
  }
`;

const VerticalLine = styled.div`
  width: 2px;
  height: 20px;
  background: ${getColor('common', 'white')};
  margin: auto 0;
`;

const NameArea = styled.div`
  display: flex;
  align-items: center;
  color: ${getColor('common', 'white')};
  font-size: ${getTypogragy('fontSizes', 'caption')}px;
  line-height: 15px;
  padding: ${getSpacing(2)}px ${getSpacing(2)}px ${getSpacing(2)}px ${getSpacing(4)}px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Triangle = styled.div`
  font-size: 7px;
  margin-left: ${getSpacing(2)}px;
`;

const PopoverProfile = styled(Profile)`
  position: absolute;
  right: 0;
  top: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 250px;
  border-radius: ${({
    theme: {
      tokens: { radiuses }
    }
  }) => `0 0 ${radiuses.r12}px ${radiuses.r12}px`};
  z-index: ${getZindex('popover')};
`;

const StyledBudge = styled(Badge)`
  .MuiBadge-colorError {
    background-color: ${getColor('danger', 'primary')};
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    top: 12px;
    right: 4px;
  }

  .MuiBadge-badge {
    height: 16px;
    min-width: 16px;
    border-radius: 8px;
    padding: 0 4px;
  }
`;

export default HeaderContent;
