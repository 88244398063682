import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import Trip from '@this/src/domain/trip/trip';

interface Props {
  trip: any;
  applicant: any;
  approvers: any;
  currentStatus: number | null;
}

interface State {
  trip: any;
  department: any;
  statusList: { name: string; current: boolean; approvers: string[] }[];
  currentStatus: number | null;
}

class InAdvanceApprovalStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      trip: new Trip(props.trip),
      department: props.applicant ? props.applicant.department : null,
      statusList: [],
      currentStatus: props.currentStatus
    };
  }

  componentDidMount() {
    this.createStatusList();
  }

  createStatusList() {
    let statuses: { name: string; current: boolean; approvers: string[] }[];
    statuses = [];

    if (this.state.trip.in_advance_approval && this.state.trip.in_advance_approval.length > 0) {
      statuses.unshift({ name: '承認済み', current: false, approvers: [] });
    }
    interface Authority {
      approve_stage: number;
      approver: {
        name: string;
      };
    }
    if (this.props.applicant && !this.state.trip.freee_approval_request_id) {
      const approvalRequired = this.props.trip.foreign_air
        ? this.props.applicant.foreign_approval_required
        : this.props.applicant.domestic_approval_required;
      if (approvalRequired) {
        let stages: { [key: string]: Authority[] } = {};
        if (this.state.department && this.state.trip.approval_route_version === 1) {
          stages = _.groupBy(this.state.department.approval_authorities, (aa: Authority) => aa.approve_stage);
        } else {
          _.forEach(this.state.trip.in_advance_approval, (ap: any) => {
            stages[ap.approve_stage] = ap.in_advance_approver_candidates.map((ca: any) => {
              return {
                approve_stage: ap.approve_stage,
                approver: {
                  name: ca.name
                }
              };
            });
          });
        }
        statuses = _.concat(
          _.keys(stages).map(s => {
            const authorities: Authority[] = stages[s];
            return { name: `${s}次承認`, current: false, approvers: authorities.map(a => a.approver.name) };
          }),
          statuses
        );
      }
    }
    this.setCurrentStatus(statuses);
  }

  setCurrentStatus(statusList: { name: string; current: boolean; approvers: string[] }[]) {
    let currentStatusNumber = 0;
    if (!this.state.trip.draft) {
      if (this.state.currentStatus === 0) {
        const num =
          this.state.trip.in_advance_approval[this.state.trip.in_advance_approval.length - 1].approve_stage;
        currentStatusNumber = num - 1;
      } else if (this.state.trip.status === 3) {
        currentStatusNumber = statusList.length - 1;
      }
      statusList.forEach((s, i) => {
        if (i <= currentStatusNumber) {
          s.current = true;
        }
      });
    }
    this.setState({ statusList });
  }

  render() {
    try {
      const { trip, statusList, currentStatus } = this.state;
      return (
        <Wrap>
          {!_.isEmpty(trip.isApproveCanceled()) ? (
            <Canceled>取消済み</Canceled>
          ) : currentStatus === 2 ? (
            <Canceled>却下済み</Canceled>
          ) : trip.status === 6 ? (
            <Canceled>キャンセル依頼中</Canceled>
          ) : trip.status === 5 ? (
            <Canceled>キャンセル済み</Canceled>
          ) : (
            <StatusItems>
              {statusList.map((s, i) => (
                <StatusItem key={`status_${i}`} current={s.current} first={i === 0}>
                  <StatusIcon>
                    <svg
                      viewBox="0 0 22 22"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <Circle current={s.current} strokeWidth="6" cx="11" cy="11" r="6" />
                      </g>
                    </svg>
                  </StatusIcon>
                  <StatusText>
                    <span>{s.name}</span>
                    {s.approvers.length > 0 && (
                      <>
                        <span>：</span>
                        <span data-wovn-ignore>{s.approvers.map((n, j) => `${j > 0 ? ', ' : ''}${n}`)}</span>
                      </>
                    )}
                  </StatusText>
                </StatusItem>
              ))}
            </StatusItems>
          )}
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  font-size: 11px;

  ${media.sp`
    font-size: 10px;
  `}
`;

const Canceled = styled.p`
  color: ${props => props.theme.redColor};
`;

const StatusItems = styled.div`
  margin-top: -12px;
  padding-left: 3px;
`;

const StatusItem = styled.div<{ current: boolean; first: boolean }>`
  position: relative;
  z-index: 0;
  height: 20px;
  margin-bottom: 6px;
  border-left: solid 3px ${props => props.theme.grayTextColor};

  ${props =>
    props.current &&
    `
    border-color: ${props.theme.linkColor};
  `}
  ${props =>
    props.first &&
    `
    border-color: transparent;
  `}
  ${media.sp`
    margin-bottom: 6px;
  `}
`;

const StatusIcon = styled.div`
  position: absolute;
  bottom: -6px;
  left: -7px;
  z-index: 10;
  width: 11px;
  height: 11px;
`;

const Circle = styled.circle<{ current: boolean }>`
  stroke: ${props => props.theme.grayTextColor};

  ${props =>
    props.current &&
    `
    stroke: ${props.theme.linkColor};
  `}
`;

const StatusText = styled.div`
  position: absolute;
  bottom: -10px;
  left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.sp`
    bottom: -8px;
  `}
`;

export default InAdvanceApprovalStatus;
