/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import A from '@this/components/shared/atoms/a';
import TranslateIgnoreText from '@this/components/shared/text/translate_ignore_text';
import LaunchIcon from '@material-ui/icons/Launch';
import HotelElement from '@this/domain/hotel_element';
import type Trip from '@this/domain/trip/trip';
import type OrderItem from '@this/src/domain/order_item';
import type NonOrderItem from '@this/domain/non_order_item';
import MarketLogHelper from '@this/domain/market_log/market_log_helper';
import { media } from '@this/shared/atoms/media';
import { getColor } from '@this/shared/ui/theme';

interface Props {
  trip: any;
  theme: { serviceName: string };
  transportVacancies: {
    transportId: number;
    priceDiff: number;
    occupied: boolean;
  }[];
}

class InAdvanceApplicationTripDetail extends React.Component<Props> {
  // リクエストフォームを利用した申請の場合は金額変動による表現は行わないようにする。
  orderItemsDescriptionWhenRequestForm() {
    const { trip } = this.props;
    return (
      <>
        {trip.order.orderItems.map((orderItem: any, i: number) => (
          <SectionDesc key={i}>
            <p>
              <span>{`■${orderItem.typeStr() || orderItem.categoryStr()}：`}</span>
              {trip.arrangement_request ? (
                <SectionDescText isOld={false}>
                  <span data-wovn-ignore>{utils.digits(orderItem.price.price)}</span>
                  <span>円</span>
                </SectionDescText>
              ) : (
                <>
                  <SectionDescText isOld={orderItem.soldOut || orderItem.isPriceChanged()}>
                    {utils.formatPrice(orderItem.firstPrice.price)}
                  </SectionDescText>
                  {orderItem.soldOut ? (
                    <SectionDescText isOld={false} className="red">
                      売り切れ
                    </SectionDescText>
                  ) : orderItem.isPriceChanged() ? (
                    <SectionDescText isOld={false} className="red">
                      {utils.formatPrice(orderItem.price.price)}
                    </SectionDescText>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </p>
            {orderItem.price.margin.amount !== 0 && (
              <p>{`■手数料：${utils.formatPrice(orderItem.price.margin.amount)}`}</p>
            )}
            {orderItem.elements.map((el: any, j: number) => (
              <OutlineBox key={j}>
                <SectionApplication>
                  <SectionLabel>
                    <p key={j} data-wovn-ignore>{`【${el.title()}】`}</p>
                  </SectionLabel>

                  <SectionApplicationInfo>
                    {el.name && <p>{el.name}</p>}
                    <TranslateIgnoreText text={el.summary()} />
                    {typeof el.detail === 'function' && <TranslateIgnoreText text={el.detail()} />}
                  </SectionApplicationInfo>
                </SectionApplication>
              </OutlineBox>
            ))}
          </SectionDesc>
        ))}
      </>
    );
  }

  orderItemsDescription() {
    const { trip, theme, transportVacancies } = this.props;
    return (
      <>
        {trip.order.orderItems.map((orderItem: any, i: number) => {
          const transportVacancy = transportVacancies.find(tv => tv.transportId === orderItem.transports[0]?.id);

          let ticketStateType: ItemPriceProps['ticketStateType'] = 'notApplicable';
          let changedPrice = orderItem.price.price;
          if (transportVacancy) {
            if (transportVacancy.occupied) {
              ticketStateType = 'noFlight';
            } else if (transportVacancy.priceDiff > 0) {
              ticketStateType = 'priceChanged';
              changedPrice += transportVacancy.priceDiff;
            } else {
              ticketStateType = 'priceNotChanged';
            }
          }

          return (
            <SectionDesc key={i}>
              <OrderItemDescription
                ticketStateType={ticketStateType}
                trip={trip}
                tripId={trip.id}
                orderItem={orderItem}
                changedPrice={changedPrice}
                theme={theme}
              />
            </SectionDesc>
          );
        })}
      </>
    );
  }

  nonOrderItemsDescription() {
    const { trip } = this.props;
    return (
      <>
        {trip.nonOrderItems.map((item: NonOrderItem, i: number) => (
          <SectionDesc key={i}>
            <OutlineBox>
              <SectionApplication>
                <SectionLabel>
                  <p data-wovn-ignore>{`【経路${i + 1}】`}</p>
                </SectionLabel>
                <SectionApplicationInfo>
                  <SectionDescText isOld={false}>
                    {item.elements.map((element: any, j: number) => (
                      <SectionFlex key={j}>
                        <p data-wovn-ignore>【{element.transportType !== 'other' ? element.title() : '徒歩'}】</p>
                        <TranslateIgnoreText text={element.summary()} />
                      </SectionFlex>
                    ))}
                    <p data-wovn-ignore>※表記は現地時刻です</p>
                  </SectionDescText>
                  <SectionBudgetHr />
                  <SectionPrice>
                    <span>{item.typeStr()}</span>
                    <span>{utils.digits(item.price.price)}円</span>
                  </SectionPrice>
                </SectionApplicationInfo>
              </SectionApplication>
            </OutlineBox>
          </SectionDesc>
        ))}
      </>
    );
  }

  render() {
    try {
      const { trip } = this.props;
      return (
        <section>
          {trip.arrangement_request ? (
            <>
              <SectionTitle>旅程の詳細</SectionTitle>
              {this.orderItemsDescriptionWhenRequestForm()}
            </>
          ) : (
            <>
              <SectionTitle>旅程の詳細</SectionTitle>
              {this.orderItemsDescription()}
              {(() => {
                const list = trip.rentalCars.list;
                if (!list || list.length === 0) {
                  return null;
                }
                return (
                  <SectionDesc>
                    <p>&lt;レンタカー&gt; ※料金は依頼完了後のチャットで通知します。</p>
                    {trip.rentalCars.list.map((r: any, i: number) => (
                      <>
                        <p>{`■ご依頼内容${i + 1}`}</p>
                        <p>{`
                    ${r.departure_place} ${trip.rentalCars.dateText(r.departure_date)}出発 ->
                    ${r.return_place + trip.rentalCars.dateText(r.return_date)}返却
                  `}</p>
                        <p>{`車種：${trip.rentalCars.typeText(r.car_type)}`}</p>
                        {r.car_type === 'other' && <p>{r.car_type_other}</p>}
                        <p>{`備考：${r.remarks}`}</p>
                      </>
                    ))}
                  </SectionDesc>
                );
              })()}
              {!_.isEmpty(utils.dig(trip, 'nonOrderItems')) && (
                <>
                  <SectionTitle>経費予定分</SectionTitle>
                  {this.nonOrderItemsDescription()}
                </>
              )}
            </>
          )}
        </section>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

type OrderItemDescriptionProps = ItemPriceProps &
  Pick<MarketLogContentProps, 'tripId' | 'theme'> & {
    trip: Trip;
  };

const OrderItemDescription = ({
  ticketStateType,
  trip,
  tripId,
  orderItem,
  theme,
  changedPrice
}: OrderItemDescriptionProps) => {
  return (
    <OutlineBox>
      <OrderItemDetailDescription
        ticketStateType={ticketStateType}
        trip={trip}
        tripId={tripId}
        orderItem={orderItem}
        theme={theme}
        changedPrice={changedPrice}
      />
    </OutlineBox>
  );
};

type OrderItemDetailDescriptionProps = ItemPriceProps &
  Pick<MarketLogContentProps, 'tripId' | 'theme'> & {
    trip: Trip;
  };
const OrderItemDetailDescription = ({
  ticketStateType,
  trip,
  tripId,
  orderItem,
  theme,
  changedPrice
}: OrderItemDetailDescriptionProps) => {
  const firstItem = orderItem.firstItem;
  return (
    <SectionApplicationBox>
      {orderItem.elements.map((el: any, j: number) => (
        <SectionApplication key={j}>
          <SectionLabel>
            <p key={j} data-wovn-ignore>{`【${el.title()}】`}</p>
          </SectionLabel>

          <SectionApplicationInfo>
            <TripDescriptionContent
              key={j}
              el={el}
              firstEl={firstItem?.elements[j]}
              isPackage={orderItem.isPackage()}
              flightPriceChangeDetail={orderItem.price.flightPriceChangeDetail}
            />
            {trip.travelerInformations.list
              .filter((tst: any) => tst.railway_type === true)
              .map((tst: any, i: number) => (
                <TranslateIgnoreText key={i} text={formatTravelerText(el, tst, i)} />
              ))}
            <SectionBudgetHr />
          </SectionApplicationInfo>

          <SectionMarket>
            <MarketLogContent key={j} tripId={tripId} el={el} theme={theme} />
          </SectionMarket>
        </SectionApplication>
      ))}

      <SectionApplication>
        <SectionLabel />
        <SectionApplicationInfo>
          <ItemPrice orderItem={orderItem} changedPrice={changedPrice} ticketStateType={ticketStateType} />
          <ItemMargin orderItem={orderItem} />
        </SectionApplicationInfo>
        <SectionMarket />
      </SectionApplication>
    </SectionApplicationBox>
  );
};

interface MarketLogContentProps {
  el: any;
  tripId: number;
  theme: Props['theme'];
}

const MarketLogContent = ({ el, theme, tripId }: MarketLogContentProps) => {
  const marketLogPath = () => MarketLogHelper.elementMarketLogPath(el, tripId);

  return (
    <Element>
      {MarketLogHelper.isElementMarketLogLinkAvailable(el, theme.serviceName) && el.marketlogAvailable && (
        <A href={marketLogPath()} target="_blank" rel="noopener noreferrer">
          マーケットログ
          <LaunchIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'middle', color: '#aa935b' }} />
        </A>
      )}
    </Element>
  );
};

const formatTravelerText = (el: any, tst: any, i: number) => {
  let str = '';
  if (el.type === 'transport') {
    if (tst.shinkansen_ticket_type) {
      str = `券種: ${tst.shinkansen_ticket_type_text}, `;
    }
    if (tst.shinkansen_seat_type) {
      str += `座席: ${tst.shinkansen_seat_type_text}, `;
    }
    if (tst.shinkansen_seat_type) {
      str += `座席希望: ${tst.shinkansen_seat}`;
    }
  }
  return `出張者:${i + 1}人目　　(${tst.last_name_roman} ${tst.first_name_roman})　${str}`;
};

interface TripDescriptionContentProps {
  el: any;
  firstEl?: any;
  isPackage: boolean;
  flightPriceChangeDetail: string;
}
const TripDescriptionContent = ({
  el,
  firstEl,
  isPackage,
  flightPriceChangeDetail
}: TripDescriptionContentProps) => {
  return (
    <Element>
      {el.name && <ElementHeader>{el.name}</ElementHeader>}
      <div data-wovn-ignore>
        {el
          .summary()
          .split('\n')
          .map((line: string, i: number) => {
            const prevLine = firstEl?.summary().split('\n')[i];
            if (_.isEmpty(prevLine) || prevLine === line) {
              return <p key={i}>{_.isEmpty(line) ? <br /> : line}</p>;
            }
            return (
              <>
                <p key={i} style={{ textDecoration: 'line-through', textDecorationColor: '#a72c09' }}>
                  {_.isEmpty(prevLine) ? <br /> : prevLine}
                </p>
                <p key={i} style={{ color: '#a72c09' }}>
                  {_.isEmpty(line) ? <br /> : line}
                </p>
              </>
            );
          })}
      </div>
      {flightPriceChangeDetail && <p>{flightPriceChangeDetail}</p>}
      {typeof el.detail === 'function' && (
        <div data-wovn-ignore>
          {el
            .detail()
            .split('\n')
            .map((line: string, i: number) => {
              const prevLine = firstEl?.detail().split('\n')[i];
              if (_.isEmpty(prevLine) || prevLine === line) {
                return <p key={i}>{_.isEmpty(line) ? <br /> : line}</p>;
              }
              return (
                <>
                  <p key={i} style={{ textDecoration: 'line-through', textDecorationColor: '#a72c09' }}>
                    {_.isEmpty(prevLine) ? <br /> : prevLine}
                  </p>
                  <p key={i} style={{ color: '#a72c09' }}>
                    {_.isEmpty(line) ? <br /> : line}
                  </p>
                </>
              );
            })}
        </div>
      )}
      {el instanceof HotelElement && !isPackage && el.detailPath && (
        <div>
          <span>宿泊詳細情報：</span>
          <span>
            <a href={el.detailPath} target="_blank" rel="noreferrer noopener">
              詳細はコチラ
            </a>
          </span>
        </div>
      )}
      {el.isForeignAir && el.tabikoboAviations().length > 0 && (
        <>
          <br />
          <ElementHeader>同便の過去のチケット金額</ElementHeader>
          <table>
            <tbody>
              <tr>
                <th>便名</th>
                <th>シートクラス</th>
                <th>搭乗日</th>
                <th>出発日</th>
                <th>帰着日</th>
                <th>受付日</th>
                <th>1人あたりチケット代金</th>
              </tr>
              {el.tabikoboAviations().map((a: any, i: number) => (
                <>
                  {a.flightNumber ? (
                    <tr key={i}>
                      <td>{a.flightNumber}</td>
                      <td>{a.className}</td>
                      <td>{a.boardingDate}</td>
                      <td>{a.departureDate}</td>
                      <td>{a.returnDate}</td>
                      <td>{a.receptionDate}</td>
                      <td>{utils.formatPrice(a.salesTotal / a.numberOfParticipants)}</td>
                    </tr>
                  ) : (
                    <tr />
                  )}
                </>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Element>
  );
};

interface ItemPriceProps {
  orderItem: OrderItem;
  changedPrice: number;
  ticketStateType: 'priceChanged' | 'priceNotChanged' | 'noFlight' | 'notApplicable';
}
const ItemPrice = ({ orderItem, changedPrice, ticketStateType }: ItemPriceProps) => {
  const soldOut = orderItem.soldOut || ticketStateType === 'noFlight';
  const isPriceChanged = orderItem.isPriceChanged() || ticketStateType === 'priceChanged';

  return (
    <SectionPrice>
      <span>{`${orderItem.typeStr() || orderItem.categoryStr()}`}</span>
      <span>
        <SectionDescText isOld={soldOut || isPriceChanged}>
          <span data-wovn-ignore>{utils.digits(orderItem.firstItem?.price?.price || 0)}</span>
          <span>円</span>
        </SectionDescText>
        {soldOut ? (
          <SectionDescText isOld={false} className="red">
            {ticketStateType === 'noFlight' ? '満席' : '売り切れ'}
          </SectionDescText>
        ) : isPriceChanged ? (
          <SectionDescText isOld={false} className="red">
            <span data-wovn-ignore>{utils.digits(changedPrice)}</span>
            <span>円</span>
          </SectionDescText>
        ) : (
          <></>
        )}
      </span>
    </SectionPrice>
  );
};

const ItemMargin = ({ orderItem }: { orderItem: OrderItem }) => {
  if (orderItem.price.margin.amount === 0) {
    return null;
  }

  return (
    <SectionPrice>
      <span>手数料</span>
      <span data-wovn-ignore>{utils.digits(orderItem.price.margin.amount)}円</span>
    </SectionPrice>
  );
};

const SectionTitle = styled.h3`
  margin-top: 40px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
`;

const SectionDesc = styled.div`
  margin-bottom: 10px;
`;

const SectionFlex = styled.div`
  display: flex;
  gap: 8px;

  & > *:first-child {
    min-width: 80px;
  }
`;

const SectionDescText = styled.span<{ isOld: boolean }>`
  &.red {
    color: ${props => props.theme.redColor};
  }

  ${props =>
    props.isOld &&
    `
    text-decoration: line-through;
  `}
`;

const Element = styled.div``;

const ElementHeader = styled.div`
  font-weight: bold;
`;

const OutlineBox = styled.div`
  border: 1px solid ${getColor('border', 'divider')};
  margin-top: -1px;
  padding: 20px;
  margin: 10px 0;

  ${media.sp`
     padding: 6px;
  `}
`;

const SectionApplication = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${media.sp`
    flex-direction: column;
  `}
`;

const SectionApplicationInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 68%;
  padding: 10px;

  ${media.sp`
    width: 100%;
  `}
`;

const SectionApplicationBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionLabel = styled.div`
  padding: 10px;
  flex-basis: 16%;
  font-weight: bold;

  ${media.sp`
    width: 100%;
  `}
`;

const SectionMarket = styled.div`
  padding-top: 10px;
  width: 16%;
  text-align: right;

  ${media.sp`
    width: 100%;
    text-align: left;
    padding-left: 10px;
  `}
`;

const SectionPrice = styled.div`
  padding: 5px;
  font-weight: bold;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;

  ${media.sp`
    width: 100%;
  `}
`;

const SectionBudgetHr = styled.hr`
  width: 100%;
  border: 1px solid ${props => props.theme.grayBorderColor};
  margin: 5px 0;
`;

export default withTheme(InAdvanceApplicationTripDetail);
