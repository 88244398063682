import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { Button } from '@this/shared/ui/inputs/button';
import AddIcon from '@material-ui/icons/Add';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { TripReport as TripReportClass, TripReportStatus } from '@this/src/domain/trip_report/trip_report';
import { Box } from '@material-ui/core';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import TripReportCard from './trip_report_card';
import TripHeader from '../trips/trip_header';
import { Wrap, BodyWrap, Body, BodyTitle } from './shared/wrappers';
import TripReportCancel from './trip_report_cancel';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

const TripReport: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const location = useLocation<{ tripReport?: { id: number; message: string } }>();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const tripReports = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);

  const [cancel, setCancel] = useState<number | null>(null);
  const [cancelErrors, setCancelErrors] = useState<string[]>([]);

  const fetchTripReports = useCallback(() => {
    tripReports.setStatusEq(searchParams.get('status') || '', () => tripReports.getIndex());
  }, []);

  const fetchChangeStatus = useCallback(
    (status: TripReportStatus | '') => {
      tripReports.setStatusEq(status, () => {
        tripReports.getIndex();
        searchParams.set('status', status);
        history.replace({ search: searchParams.toString() });
      });
    },
    [searchParams]
  );

  const handleCancel = useCallback((id: number, message: string) => {
    setCancelErrors([]);
    tripReports.cancel({
      tripReportId: id,
      message,
      onSuccess: () => setCancel(null),
      onError: errors => setCancelErrors(errors)
    });
  }, []);

  const handleExpensesReport = useCallback((tripReport: TripReportClass, onError?: (errors: string[]) => void) => {
    tripReports.createExpensesReport({ tripReport, onError });
  }, []);

  useEffect(() => {
    fetchTripReports();

    if (location.state?.tripReport) {
      const state = location.state.tripReport;
      tripReports.setMessage(state.id, state.message);
    }
  }, []);

  return (
    <TripReportContext.Provider value={tripReports}>
      <Wrap>
        <BodyWrap>
          <div>
            <TripHeader selected="tripReport" tripReportAvailable />
            <Body>
              <Box display="flex">
                <BodyTitle style={{ marginRight: 'auto' }}>出張後の報告一覧</BodyTitle>
                <Box>
                  <Button href="/trip_report/new" startIcon={<AddIcon />}>
                    新規に出張報告を作成する
                  </Button>
                </Box>
              </Box>
              <Navigations>
                <SelectButtons>
                  {tripReports.selectStatusMenu().map(({ status, label }) => (
                    <SelectButton
                      key={status}
                      className={tripReports.statusEq === status ? 'selected' : ''}
                      onClick={() => fetchChangeStatus(status)}
                    >
                      {label}
                    </SelectButton>
                  ))}
                </SelectButtons>
                <Actions>
                  {tripReports.setting?.expenseTool === 'biztra' && (
                    <Button href="/trip_report/expenses">経費を一括連携する</Button>
                  )}
                </Actions>
              </Navigations>
              {tripReports.loading ? (
                <LoadingWrap>
                  <SimpleLoading />
                </LoadingWrap>
              ) : tripReports.errors.length > 0 ? (
                <Error>{tripReports.errors.join('\n')}</Error>
              ) : (
                <TripsWrap>
                  {tripReports.tripReports.length > 0 ? (
                    <ListWrap>
                      {tripReports.tripReports.map(tripReport => (
                        <TripReportCard
                          key={tripReport.id}
                          expenseTool={tripReports.setting?.expenseTool}
                          tripReport={tripReport}
                          message={tripReports.messages[tripReport.id]}
                          setCancel={setCancel}
                          handleExpensesReport={handleExpensesReport}
                        />
                      ))}
                    </ListWrap>
                  ) : (
                    <NoItem>進行中の出張報告はありません</NoItem>
                  )}
                  <ListWrap />
                </TripsWrap>
              )}
              <TripReportCancel
                tripReportId={cancel}
                errors={cancelErrors}
                setErrors={setCancelErrors}
                onClose={() => setCancel(null)}
                onSubmit={handleCancel}
              />
            </Body>
          </div>
        </BodyWrap>
      </Wrap>
    </TripReportContext.Provider>
  );
};

const Navigations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.sp`
    flex-flow: column-reverse;
    gap: 24px;
  `}
`;

const SelectButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectButton = styled.div`
  padding: 5px 10px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &.selected {
    color: #ffffff;
    background-color: #374151;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const TripsWrap = styled.div`
  padding: 20px 0;
`;

const ListWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 25px;
`;

const NoItem = styled.p`
  margin: 0 auto;
  text-align: center;
`;

export default TripReport;
