import React, { useMemo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import {
  Button,
  Paper,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type Project from '@this/src/domain/project/project';
import type ExpensesType from '@this/src/domain/expenses/expenses_type';
import type TaxType from '@this/src/domain/tax_type';
import type { NonOrderItemForReport } from '@this/src/domain/trip_report/non_order_item';
import { BodyTitle } from '../../../shared/wrappers';
import {
  NonOrderItemsFormContext,
  NonOrderItemsFormStore,
  TripReportTripSelectedNonOrderItemsForm
} from './non_order_items_form';

type Props = {
  changePrice: boolean;
  projectOptions: Project[];
  expensesTypeOptions: ExpensesType[];
  nonOrderItems: NonOrderItemForReport[];
  taxTypeOptions: TaxType[];
  setChangePrice: Dispatch<SetStateAction<boolean>>;
};

export const TripReportTripSelectedNonOrderItems: React.FC<Props> = ({
  changePrice,
  projectOptions,
  expensesTypeOptions,
  taxTypeOptions,
  nonOrderItems,
  setChangePrice
}) => {
  const nonOrderItemsFormStore = useMemo(() => new NonOrderItemsFormStore(), []);
  const store = useTripReportStore();
  const { serviceId, setting } = store;
  const projectable = useMemo(() => projectOptions.length > 0, [projectOptions]);

  return (
    <NonOrderItemSection>
      <BodyTitle>{serviceId !== 2 ? '立替経費を登録' : '自己手配経費を登録'}</BodyTitle>
      <TableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              {setting?.expenseTool !== 'none' && <TableCell>勘定科目：経費科目</TableCell>}
              <TableCell>日付</TableCell>
              <TableCell>訪問先／支払い先</TableCell>
              <TableCell>合計金額</TableCell>
              {projectable && <TableCell>該当プロジェクト</TableCell>}
              <TableCell>備考</TableCell>
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            <NonOrderItemsFormContext.Provider value={nonOrderItemsFormStore}>
              {nonOrderItems.map((item, i) => (
                <NonOrderTableRow key={i} className={item.editing ? 'editing' : ''}>
                  {item.editing ? (
                    <TripReportTripSelectedNonOrderItemsForm
                      changePrice={changePrice}
                      item={item}
                      index={i}
                      expenseTool={setting?.expenseTool}
                      projectOptions={projectOptions}
                      expensesTypeOptions={expensesTypeOptions}
                      tripTypeOptions={store.tripTypeOptions}
                      taxTypeOptions={taxTypeOptions}
                      calcPriceOrderOptions={store.calcPriceOrderOptions}
                      setChangePrice={setChangePrice}
                      removeNonOrderItem={() => store.removeNonOrderItem(item)}
                    />
                  ) : (
                    <>
                      {setting?.expenseTool !== 'none' && (
                        <TableCell>
                          {item.expensesType
                            ? `${item.expensesType.expensesAccountType?.name}：${item.expensesType.name}`
                            : '─'}
                        </TableCell>
                      )}
                      <TableCell>{item.getPeriod()}</TableCell>
                      <TableCell>{item.payee || '─'}</TableCell>
                      <TableCell>{item.getPrice()}</TableCell>
                      <TableCell className={!item.projectId ? 'spDisable' : ''}>{item.getProjectName()}</TableCell>
                      <TableCell>{item.getMemoOrElement()}</TableCell>
                      <TableCell>
                        <Actions>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => item.setEditing(true)}
                          >
                            編集
                          </Button>
                          <RemoveButton
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              store.removeNonOrderItem(item);
                              setChangePrice(!changePrice);
                            }}
                          >
                            削除
                          </RemoveButton>
                        </Actions>
                      </TableCell>
                    </>
                  )}
                </NonOrderTableRow>
              ))}
            </NonOrderItemsFormContext.Provider>
            <TableRow>
              <TableCell colSpan={projectable ? 6 : 5}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => store.addNonOrderItem()}
                >
                  {serviceId !== 2 ? '立替経費を追加' : '自己手配経費を追加'}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </TableContainer>
    </NonOrderItemSection>
  );
};

const NonOrderItemSection = styled.div``;

const StyledTable = styled(Table)`
  ${media.sp`
    display: block;

    .spDisable {
      display: none;
    }

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-flow: column;
    }

    .MuiTableRow-root {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      padding: 16px;
      gap: 8px 16px;
      border-bottom: solid 1px #eee;
    }

    .MuiTableCell-root {
      border: 0;
      padding: 0;
      width: 100%;

      &.spWrap {
        width: auto;
      }

      &:last-child {
        padding-top: 8px;
        border-top: solid 1px #eee;
      }

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }
    }
  `}
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.grayBgColorLight};

  .MuiTableCell {
    &-head {
      padding: 8px 16px;
      min-width: 120px;
    }
  }

  ${media.sp`
    display: none;
  `}
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const RemoveButton = styled(Button)`
  color: ${props => props.theme.redColor};
  font-weight: 500;
`;

const NonOrderTableRow = styled(TableRow)`
  &.editing {
    background-color: ${props => props.theme.grayBgColorLight};
  }
`;
