import { Loading } from '@this/shared/ui/feedbacks/loading';
import React from 'react';
import { styled } from '@this/constants/themes';
import { ArrangementReportsFilter } from './arrangement_reports_filter';
import { ArrangementReportTable } from './arrangement_report_table';

type Props = {
  loading: boolean;
  initialFilterData: React.ComponentProps<typeof ArrangementReportsFilter>['initialFilterData'];
  reportItems: React.ComponentProps<typeof ArrangementReportTable>['reportItems'];
  reportTimes: React.ComponentProps<typeof ArrangementReportTable>['reportTimes'];
  onFilterSubmit: React.ComponentProps<typeof ArrangementReportsFilter>['onSubmit'];
};
export const ArrangementReportsTemplate = ({
  loading,
  initialFilterData,
  reportItems,
  reportTimes,
  onFilterSubmit
}: Props) => {
  return (
    <>
      <h2 className="admin__title">手配関連集計</h2>
      <div className="admin__content-in">
        {initialFilterData && (
          <StyledArrangementReportsFilter initialFilterData={initialFilterData} onSubmit={onFilterSubmit} />
        )}

        {loading ? (
          <Loading centerd />
        ) : (
          <ArrangementReportTable reportItems={reportItems} reportTimes={reportTimes} />
        )}
      </div>
    </>
  );
};

const StyledArrangementReportsFilter = styled(ArrangementReportsFilter)`
  margin-bottom: 16px;
`;
