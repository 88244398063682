import type { PlaceEntity } from '@this/domain/place/place.type';

export default class Place {
  components: PlaceEntity['address_components'];

  location: PlaceEntity['location'];

  info?: PlaceEntity['info'];

  prefectureName: string | null;

  municipalityName: string | null;

  isDomestic: boolean;

  constructor(entity: PlaceEntity) {
    this.components = entity.address_components;
    this.location = entity.location;
    this.info = entity.info;

    this.prefectureName = this.findPrefectureName();
    this.municipalityName = this.findMunicipalityName();
    this.isDomestic = this.hasDommesticComponent();
  }

  private findPrefectureName(): string | null {
    const component = this.components.find(a => a.types.includes('administrative_area_level_1'));
    return component ? component.long_name : null;
  }

  private findMunicipalityName(): string | null {
    const component = this.components.find(a => a.types.includes('locality'));
    return component ? component.long_name : null;
  }

  private hasDommesticComponent() {
    return this.components.some(component => component.types[0] === 'country' && component.short_name === 'JP');
  }
}
