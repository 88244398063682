import type { AITTheme } from '@this/shared/ui/theme';
import { createMuiTheme as createTheme } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';

// TODO: 本当はこれ定義したいけど、MUIを使用しているAIカレンダーにも影響がでてしまう。tsconfigを分ければよいが、整理のコストが高い。
// declare module '@material-ui/core/styles' {
//   interface Theme {
//     tokens: AITTheme['tokens'];
//     primitiveTokens: AITTheme['primitiveTokens'];
//   }
//   interface ThemeOptions {
//     tokens: AITTheme['tokens'];
//     primitiveTokens: AITTheme['primitiveTokens'];
//   }
// }

export type AIThemeWithMui = Theme & Omit<AITTheme, 'overrides'>;

export const createMuiTheme = (rawTheme: AITTheme): AIThemeWithMui => {
  const {
    tokens: { colors, typographies, breakpoints, zIndices },
    primitiveTokens: { breakpoints: primitiveBreakpoints },
    overrides = {},
    ...theme
  } = rawTheme;

  return {
    ...createTheme({
      palette: {
        primary: { dark: colors.brand.visited, main: colors.brand.primary, contrastText: colors.common.white },
        secondary: { main: colors.danger.primary, contrastText: colors.common.white },
        text: { primary: colors.text.primary, secondary: colors.text.description, disabled: colors.text.disabled },
        action: {
          selected: colors.background.selected,
          hover: colors.background.hover,
          disabledBackground: colors.background.disabled
        },
        background: { default: colors.background.primary, paper: colors.common.white },
        common: colors.common
      },
      typography: {
        fontFamily: typographies.fontFamily,
        fontSize: typographies.fontSizes.body,
        body1: { fontSize: typographies.fontSizes.body },
        caption: { fontSize: typographies.fontSizes.caption },
        button: { fontSize: typographies.fontSizes.body }
      },
      breakpoints: {
        values: {
          xs: primitiveBreakpoints['0'],
          sm: breakpoints.sm,
          md: breakpoints.md,
          lg: primitiveBreakpoints['1280'],
          xl: primitiveBreakpoints['1920']
        }
      },
      zIndex: {
        appBar: zIndices.sticky,
        drawer: zIndices.overlay,
        modal: zIndices.modal,
        snackbar: zIndices.toast,
        tooltip: zIndices.tooltip
      },
      props: {
        MuiButtonBase: {
          disableRipple: true
        }
      },
      overrides: {
        MuiButton: {
          root: {
            textTransform: 'none',
            fontWeight: 'bold',
            '&$disabled': {
              opacity: 0.5
            },
            paddingTop: 8,
            paddingBottom: 8,
            lineHeight: 1.5
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              // _typography.scssのaのスタイルに負けてしまうため付与。本来必要ない。
              color: colors.common.white
            },
            '&:hover,&:active': {
              boxShadow: 'none'
            }
          },
          sizeLarge: {
            fontSize: 20
          },
          outlined: {
            paddingTop: 7,
            paddingBottom: 7
          }
        },
        MuiDialog: {
          paperScrollPaper: {
            backgroundColor: colors.background.primary
          }
        },
        MuiDialogContent: {
          root: {
            paddingRight: 16,
            paddingLeft: 16,
            '& p': {
              margin: 0
            }
          }
        },
        MuiDialogActions: {
          root: {
            paddingBottom: 16,
            paddingLeft: 16,
            paddingRight: 16
          }
        },
        ...overrides
      }
    }),
    tokens: rawTheme.tokens,
    primitiveTokens: rawTheme.primitiveTokens,
    ...theme
  };
};
