export const OrderItemJobType = {
  reserve: '新規',
  change: '変更',
  cancel: 'キャンセル',

  // TODO: 統一したい
  0: '新規',
  1: '変更',
  2: 'キャンセル'
};

export type OrderItemJobTypeKey = keyof typeof OrderItemJobType;
export const OrderItemJobTypeKeys: OrderItemJobTypeKey[] = ['reserve', 'change', 'cancel'];
export const OrderItemJobTypeNumKeys: OrderItemJobTypeKey[] = [0, 1, 2];
