import React from 'react';

import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';

type Props = {
  className?: string;
  reservationJson: { fp: object | null; mynavi: object | null };
};

export const TicketReservation = ({ className, reservationJson }: Props) => {
  if (!(reservationJson.fp && reservationJson.mynavi)) {
    return null;
  }

  return (
    <Root className={className}>
      <h3>航空券予約</h3>

      {reservationJson.fp && <ReservationForm type="fp" params={reservationJson.fp} />}
      {reservationJson.mynavi && <ReservationForm type="mynavi" params={reservationJson.mynavi} />}
    </Root>
  );
};

const ReservationForm = ({ params, type }: { type: 'fp' | 'mynavi'; params: object }) => (
  <form
    action={
      type === 'mynavi'
        ? 'https://www.tabipon.jp/mynavibtm/MyPlanLink.aspx'
        : 'http://www.tabipon.jp/dpapi/MyPlanLink.aspx'
    }
    method="POST"
    target="_blank"
  >
    <input type="hidden" name="relationjson" value={JSON.stringify(params)} />
    <Button type="submit" size="small">
      航空券予約({type})
    </Button>
  </form>
);

const Root = styled.div``;
