import React from 'react';
import _ from 'lodash';

interface Props {
  text: string | null | undefined;
}
const TranslateIgnoreText = ({ text }: Props) => {
  return (
    <div data-wovn-ignore>
      {text &&
        typeof text === 'string' &&
        text.split('\n').map((line, i) => <p key={i}>{_.isEmpty(line) ? <br /> : line}</p>)}
    </div>
  );
};

export default TranslateIgnoreText;
