type Status = 'enqueued' | 'running' | 'success' | 'skip' | 'fail';

const StatusMessage: { [key in Status]: string } = {
  enqueued: '読み込み中',
  running: '実行中',
  success: '完了',
  skip: 'スキップ',
  fail: '失敗'
};

export interface CsvImportActionItemArgs {
  id: number;
  status: Status;
  error_message: string;
  uploaded_data: string;
  resource_id?: number;
}

export default class CsvImportActionItem {
  id: number;

  status: Status;

  errorMessage: string;

  uploadedData: string;

  resourceId?: number;

  constructor(args: CsvImportActionItemArgs) {
    this.id = args.id;
    this.status = args.status;
    this.errorMessage = args.error_message;
    this.uploadedData = args.uploaded_data;
    this.resourceId = args.resource_id;
  }

  get uploadedDataHash() {
    return JSON.parse(this.uploadedData);
  }

  get statusMessage() {
    return StatusMessage[this.status];
  }
}
