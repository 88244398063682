import React from 'react';

import { styled } from '@this/constants/themes';
import { AiHotelIcon } from '@this/src/components/shared/icons/ai_hotel_icon';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type SearchQueryItem from '@this/src/domain/search_query_item';
import { HotelScoresReasonFeedback } from './hotel_scores_reason_feedback';

interface Props {
  searchQueryItem?: SearchQueryItem;
  reasonStatus: 'none' | 'loading' | 'completed';
  scoreReason: string | null;
}

const HotelScoresReason: React.FC<Props> = ({ searchQueryItem, reasonStatus, scoreReason }) => {
  if (!searchQueryItem) {
    return null;
  }

  if (reasonStatus === 'none') {
    return null;
  }

  if (reasonStatus === 'completed' && !scoreReason) {
    return null;
  }

  return (
    <Reason className="HotelScoresReason">
      <Header>
        <AiHotelIcon />
        <Label>AIによる検索結果の総評</Label>
      </Header>
      {reasonStatus === 'loading' ? (
        <SimpleLoading />
      ) : (
        <>
          <Text>{scoreReason}</Text>
          <HotelScoresReasonFeedback searchQueryItem={searchQueryItem} />
        </>
      )}
    </Reason>
  );
};

const Reason = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(92.26deg, #47b5ff -6.66%, #b482f5 91.17%);
  border-image-slice: 1;
  background-color: ${props => props.theme.fieldBgColor};
`;

const Header = styled.div`
  display: flex;
  align-items: normal;
  gap: 4px;
`;

const Label = styled.div`
  font-size: 12.5px;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};
`;

const Text = styled.div`
  font-size: 12px;
  color: ${props => props.theme.blackTextColor};
`;

export default HotelScoresReason;
