class SupportFeeElement
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @type = 'support_fee'
    @content ||= ''
    @reservedAt = moment(args.reservedAt)

  handleContentChange: (value) ->
    @content = value
    app.render()

  handleReservedAtChange: (value) ->
    @reservedAt = value
    app.render()

  startDate: ->
    null

  endDate: ->
    null

  description: ->
    """
    <出張サポート費>
    ■内容
    #{@content}
    ■予約日
    #{@reservedAt.format('MM/DD')}
    """

  title: ->
    '出張サポート費'

  summary: ->
    '[出張サポート費]' + @content

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  validationErrors: (_) ->
    errors = []
    if @content == '' then errors.push('出張サポート費の内容を入力してください。')
    errors

  updateParams: ->
    type: @type
    content: @content
    reserved_at: @reservedAt.format('YYYY-MM-DD')

module.exports = SupportFeeElement
