import React, { useCallback, useMemo, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { media } from '@this/components/shared/atoms/media';
import { styled } from '@this/constants/themes';
import { useInAdvanceApplicationStore } from '@this/src/domain/in_advance/in_advance_application_store';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import { Button } from '../../shared/ui/inputs/button';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import BulkActionsAccept from './bulk_actions_accept';
import BulkActionsReject from './bulk_actions_reject';

const BulkActions: React.FC = () => {
  const [dialog, setDialog] = useState<'accept' | 'reject' | null>(null);
  const store = useInAdvanceApplicationStore();
  const { tripsLoading, batchLoading, batchActionLoading, batch } = store;
  const actionType = useMemo(
    () =>
      batch.batch === 'complete'
        ? 'complete'
        : batch.batch === 'processing'
        ? 'processing'
        : batch.selectedTrips.length > 0
        ? 'selected'
        : 'unselected',
    [batch.batch, batch.selectedTrips.length]
  );

  const handleClose = useCallback(() => setDialog(null), [setDialog]);

  return (
    <Wrap>
      {tripsLoading || batchLoading ? (
        <SimpleLoading />
      ) : (
        <>
          <Summary>申請が{batch.selectedTrips.length}件選択されています。</Summary>
          {actionType === 'unselected' ? (
            <Actions>
              <DisableButton color="sub" size="small" startIcon={<CheckIcon />}>
                一括承認する
              </DisableButton>
              <DisableButton color="sub" size="small" startIcon={<CheckIcon />}>
                一括却下する
              </DisableButton>
            </Actions>
          ) : actionType === 'selected' ? (
            <Actions>
              <Button
                color="primary"
                size="small"
                loading={batchActionLoading}
                startIcon={<CheckIcon />}
                onClick={() => setDialog('accept')}
              >
                一括承認する
              </Button>
              <Button
                color="primary"
                size="small"
                loading={batchActionLoading}
                startIcon={<CheckIcon />}
                onClick={() => setDialog('reject')}
              >
                一括却下する
              </Button>
            </Actions>
          ) : actionType === 'processing' ? (
            <Button loading size="small">
              {batch.action === 'accept' ? '一括承認' : '一括却下'}({batch.progress} / {batch.total})
            </Button>
          ) : (
            <Text>{batch.action === 'accept' ? '一括承認' : '一括却下'}が完了しました</Text>
          )}
        </>
      )}
      <Modal open={dialog !== null} onClose={handleClose}>
        {dialog === 'accept' ? (
          <BulkActionsAccept onClose={handleClose} />
        ) : (
          dialog === 'reject' && <BulkActionsReject onClose={handleClose} />
        )}
      </Modal>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  ${media.sp`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Summary = styled.h3`
  font-size: 15px;
  font-weight: bold;
  margin: 0;
`;

const DisableButton = styled(Button)`
  pointer-events: none;
`;

const Text = styled.div``;

export default BulkActions;
