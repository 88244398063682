export type PaymentGatewayUserArgs = {
  id: number;
  uid: string;
  name: string;
  masked_credit_card_number: string;
  use_system_fee: boolean;
};

export class PaymentGatewayUser {
  id: number;

  uid: string;

  name: string;

  maskedCreditCardNumber: string;

  useSystemFee: boolean;

  constructor(args: PaymentGatewayUserArgs) {
    this.id = args.id;
    this.uid = args.uid;
    this.name = args.name;
    this.maskedCreditCardNumber = args.masked_credit_card_number;
    this.useSystemFee = args.use_system_fee;
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name
    };
  }
}
