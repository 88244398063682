import type { BulkActionAttr } from '@this/components/organization/types';
import { BulkActionAttrServiceType } from '@this/components/organization/types';

const DELETE_MEMBER_FORMATS: BulkActionAttr[] = [
  {
    name: 'email',
    summary: 'メールアドレス',
    example: 'tanaka.taro@example.com',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  }
];

export default DELETE_MEMBER_FORMATS;
