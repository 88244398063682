import { escapeHTMLWhitelist } from '@this/src/util';

const buildHTML = (text: string) => {
  const escaped = escapeHTMLWhitelist(text, { allowedTags: ['br'], escapeMode: 'remove' });
  const result = escaped
    .replace(/\\n|\n/g, '<br />')
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" class="message__yahoo-link" target="_blank">$1</a>');
  return result.replace(/\*\*(.+)\*\*/, '<span class="bold">$1</span>');
};

export default buildHTML;
