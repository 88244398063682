import React, { useContext, useMemo } from 'react';
import type { DialogProps as MuiDialogProps } from '@material-ui/core';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CancelOutlined } from '@material-ui/icons';
import { Heading } from '@this/shared/ui/data_displays/typography';
import type { AITTheme } from '@this/shared/ui/theme';

export const ModalBody = MuiDialogContent;
export const ModalFooter = MuiDialogActions;

export type ModalCloseReason = 'backdropClick' | 'escapeKeyDown' | 'buttonClick';
export type ModalOnClose = (event: object, reason: ModalCloseReason) => void;
export const ModalContext = React.createContext<{ onClose?: ModalOnClose }>({});

export const MODAL_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
} as const;
export type ModalSize = typeof MODAL_SIZE[keyof typeof MODAL_SIZE];

export type ModalProps = Omit<MuiDialogProps, 'maxWidth' | 'fullScreen' | 'onClose'> & {
  size?: ModalSize;
  onClose?: ModalOnClose;
};

const modalSizeMap: Record<ModalSize, MuiDialogProps['maxWidth']> = {
  small: 'xs',
  medium: 'sm',
  large: 'lg',
  xlarge: 'xl'
};

// TODO spの対応
export const Modal = ({ size = 'medium', onClose, fullWidth, ...props }: ModalProps) => {
  const context = useMemo(
    () => ({
      onClose
    }),
    [onClose]
  );

  return (
    <ModalContext.Provider value={context}>
      <MuiDialog
        maxWidth={modalSizeMap[size]}
        fullWidth={fullWidth === undefined ? true : fullWidth}
        onClose={context.onClose}
        {...props}
      />
    </ModalContext.Provider>
  );
};

const useModalHeaderStyle = makeStyles<AITTheme>(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.tokens.colors.background.tertiary,
    textAlign: 'center',
    paddingTop: theme.utils.getSpacing(2),
    paddingBottom: theme.utils.getSpacing(2),
    paddingLeft: theme.utils.getSpacing(4),
    paddingRight: theme.utils.getSpacing(4),
    color: theme.tokens.colors.common.white
  },
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 4
  }
}));

export const ModalHeader = ({ className, children }: { children: React.ReactNode; className?: string }) => {
  const styles = useModalHeaderStyle();
  const { onClose: handleClose } = useContext(ModalContext);

  return (
    <MuiDialogTitle disableTypography className={`${className} ${styles.root}`}>
      <Heading level="3">{children}</Heading>
      {handleClose && (
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={e => handleClose(e, 'buttonClick')}
          color="primary"
          size="small"
        >
          <CancelOutlined />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};
