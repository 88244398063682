import { observable } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';

export type BulkTicketFragmentArgs = {
  id: number;
  bulk_ticket_id: number;
  number: string;
  price: number;
  last_used_trip_id: number;
  status: number;
  repaid_at: string;
};

export default class BulkTicketFragment {
  @observable
  id: number;

  @observable
  bulkTicketId: number;

  @observable
  number: string;

  @observable
  price: number;

  @observable
  lastUsedTripId: number;

  @observable
  status: number;

  @observable
  repaidAt: Moment;

  constructor(args: BulkTicketFragmentArgs) {
    this.id = args.id;
    this.bulkTicketId = args.bulk_ticket_id;
    this.number = args.number;
    this.price = args.price;
    this.lastUsedTripId = args.last_used_trip_id;
    this.status = args.status;
    this.repaidAt = args.repaid_at ? moment(args.repaid_at) : moment();
  }
}
