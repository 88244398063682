import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Select } from '@this/components/shared/ui/inputs/select';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';

type Props = {
  onDateChange: (date: Moment) => void;
  date: Moment | undefined;
};

const DateSelector: FC<Props> = ({ onDateChange, date }) => {
  const [year, setYear] = useState<number | null>();
  const [month, setMonth] = useState<number | null>();
  const [day, setDay] = useState<number | null>();

  const dateOfMonth = date?.daysInMonth() || 31;

  useEffect(() => {
    setYear(date ? date.year() : null);
    setMonth(date ? date.month() + 1 : null);
    setDay(date ? date.date() : null);
  }, [date]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>, type: string) => {
      if (_.isEmpty(e.target.value)) return;

      const inputYear = type === 'year' ? Number(e.target.value) : year;
      const inputMonth = type === 'month' ? Number(e.target.value) - 1 : month ? month - 1 : null;
      const inputDay = type === 'date' ? Number(e.target.value) : day;

      if (inputYear === null) return;
      if (inputMonth === null) return;
      if (inputDay === null) return;

      // eslint-disable-next-line no-param-reassign
      date = moment().set({ year: inputYear, month: inputMonth, date: inputDay });
      if (!date.isValid()) return;

      onDateChange(date);
    },
    [year, month, day]
  );

  return (
    <>
      <Select
        value={year}
        onChange={e => {
          setYear(e.target.value ? Number(e.target.value) : null);
          handleChange(e, 'year');
        }}
        style={{ marginRight: '5px' }}
      >
        <option value="">-</option>
        {_.range(1900, moment().year() + 2).map(y => (
          <option value={y} key={y}>
            {y}年
          </option>
        ))}
      </Select>
      <Select
        value={month}
        onChange={e => {
          setMonth(e.target.value ? Number(e.target.value) : null);
          handleChange(e, 'month');
        }}
        style={{ marginRight: '5px' }}
      >
        <option value="">-</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (
          <option value={m} key={m}>
            {m}月
          </option>
        ))}
      </Select>
      <Select
        value={day}
        onChange={e => {
          setDay(e.target.value ? Number(e.target.value) : null);
          handleChange(e, 'date');
        }}
      >
        <option value="">-</option>
        {[...Array(dateOfMonth)]
          .map((u, i) => i + 1)
          .map(d => (
            <option value={d} key={d}>
              {d}日
            </option>
          ))}
      </Select>
    </>
  );
};

export default DateSelector;
