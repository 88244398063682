import React from 'react';
import { observer } from 'mobx-react';

import { styled } from '@this/constants/themes';
import { SEARCH_TRACKING_LOG_STATUS_LABELS } from '@this/src/domain/search_tracking_log';
import type SearchTrackingSummary from '@this/src/domain/search_tracking_summary';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';

import { SearchTrackingDetailSection } from './share/SearchTrackingDetailSection';

interface Props {
  searchTrackingSummary: SearchTrackingSummary;
}

const SearchTrackingDetailSummary: React.FC<Props> = observer(({ searchTrackingSummary }) => {
  return (
    <SearchTrackingDetailSection title="集計の結果">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell width="240px">内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>ステータス</TableCell>
            <TableCell />
          </TableRow>
          {searchTrackingSummary.status.map(({ status, count }) => (
            <TableRow key={status}>
              <SecondaryTableCell>{SEARCH_TRACKING_LOG_STATUS_LABELS[status]}</SecondaryTableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>レポジトリ</TableCell>
            <TableCell />
          </TableRow>
          {searchTrackingSummary.repository.map(({ repository, count }) => (
            <TableRow key={repository}>
              <SecondaryTableCell>{repository}</SecondaryTableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SearchTrackingDetailSection>
  );
});

const SecondaryTableCell = styled(TableCell)`
  padding-left: 28px;
`;

export default SearchTrackingDetailSummary;
