moment = require('moment-timezone')

class ShareholderTicket
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    @id = args.id
    @status = args.status
    @companyType = args.companyType || 0
    @price = args.price || ''
    @purchasedAt = moment(args.purchasedAt).tz('Asia/Tokyo')
    @expireAt = moment(args.expireAt).tz('Asia/Tokyo')
    @inactivatedAt = moment(args.inactivatedAt).tz('Asia/Tokyo') if args.inactivatedAt
    @isExpired = @expireAt <= moment()
    @number = args.number || ''
    @password = args.password || ''
    @tripId = args.tripId
    @isDeleted = args.isDeleted
    @remarks = args.remarks

  companyStr: ->
    switch @companyType
      when 0 then 'ANA'
      when 1 then 'JAL'
      when 2 then 'SFJ'

  summary: ->
    "#{@companyStr()}: #{@number} (#{formatPrice(@price)})"

  description: ->
    """
    #{@companyStr()}: #{formatPrice(@price)}
    番号: #{@number}
    パス: #{@password}
    購入日: #{@purchasedAt.format('YYYY/MM/DD')}
    有効期限: #{@expireAt.format('YYYY/MM/DD')}
    """

  setCompanyType: (value) ->
    @companyType = parseInt(value)
    app.render()

  setPrice: (value) ->
    @price = value
    app.render()

  sanitizePrice: ->
    @price = this.convertToHalfWidth(@price) || ''
    app.render()

  convertToHalfWidth: (int) ->
    if int == ''
      null
    else
      zenToHan(int.toString())

  setPurchasedAt: (value) ->
    @purchasedAt = moment(value)
    app.render()

  setExpireAt: (value) ->
    @expireAt = moment(value)
    app.render()

  setNumber: (value) ->
    @number = value
    app.render()

  setPassword: (value) ->
    @password = value
    app.render()

  setIsDelete: (value) ->
    @isDeleted = value
    app.render()

  setRemarks: (value) ->
    @remarks = value
    app.render()

  updateParams: ->
    company_type: @companyType
    price: @price
    purchased_at: @purchasedAt.format('YYYY-MM-DD')
    expire_at: @expireAt.format('YYYY-MM-DD')
    number: @number
    password: @password
    is_deleted: @isDeleted
    remarks: @remarks

module.exports = ShareholderTicket
