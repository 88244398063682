import React, { useMemo, useState } from 'react';
import { styled } from '@this/constants/themes';
import { Divider } from '@material-ui/core';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import { BodyTitle, LoadingWrap } from '../../shared/wrappers';
import { TripReportTripSelectedOrderItems } from './trip_report_trip_selected/order_items';
import { TripReportTripSelectedNonOrderItems } from './trip_report_trip_selected/non_order_items';
import { TripReportTripSelectedAllowanceItems } from './trip_report_trip_selected/allowance_items';

export const TripReportTripSelected: React.FC = () => {
  const store = useTripReportStore();
  const {
    serviceId,
    loading,
    itemEditing,
    categoryOptions,
    projectOptions,
    expensesTypeOptions,
    taxTypeOptions,
    allowanceAvailable
  } = store;

  const [changePrice, setChangePrice] = useState(false);
  const tripReport = store.currentTripReport;
  const { trip, priceDetails, nonOrderItems, allowanceItems } = store.getFormTargets();

  const totalPrice = useMemo(() => priceDetails.reduce((prev, current) => prev + current.price, 0), [changePrice]);
  const totalAdvance = useMemo(
    () => nonOrderItems.reduce((prev, current) => prev + (current.price || 0), 0),
    [changePrice]
  );
  const totalAllowance = useMemo(
    () => allowanceItems.reduce((prev, current) => prev + (current.price || 0), 0),
    [changePrice]
  );
  const totalAmount = useMemo(() => totalPrice + totalAdvance + totalAllowance, [changePrice]);

  return (
    <>
      <BodyTitle>
        {tripReport.name}
        {!itemEditing && `（旅程番号：${trip?.id}）`}
      </BodyTitle>
      {loading ? (
        <LoadingWrap>
          <SimpleLoading />
        </LoadingWrap>
      ) : (
        <TripSelectedWrap>
          {!itemEditing && (
            <>
              <PriceSection>
                <PriceSectionBody>
                  <PriceRow>
                    <label>システム手配金額</label>
                    <span>{totalPrice > 0 ? `¥${totalPrice.toLocaleString()}` : '─'}</span>
                  </PriceRow>
                  <PriceRow>
                    <label>{serviceId !== 2 ? '立替経費金額' : '自己手配金額'}</label>
                    <span>{totalAdvance > 0 ? `¥${totalAdvance.toLocaleString()}` : '─'}</span>
                  </PriceRow>
                  {allowanceAvailable && (
                    <PriceRow>
                      <label>日当</label>
                      <span>{totalAllowance > 0 ? `¥${totalAllowance.toLocaleString()}` : '─'}</span>
                    </PriceRow>
                  )}
                  <StyledDivider />
                  <PriceRow>
                    <label>合計金額</label>
                    <span className="total">{totalAmount > 0 ? `¥${totalAmount.toLocaleString()}` : '─'}</span>
                  </PriceRow>
                </PriceSectionBody>
              </PriceSection>
              <TripReportTripSelectedOrderItems
                categoryOptions={categoryOptions}
                projectOptions={projectOptions}
                priceDetails={priceDetails}
              />
            </>
          )}
          <TripReportTripSelectedNonOrderItems
            changePrice={changePrice}
            projectOptions={projectOptions}
            expensesTypeOptions={expensesTypeOptions}
            taxTypeOptions={taxTypeOptions}
            nonOrderItems={nonOrderItems}
            setChangePrice={setChangePrice}
          />
          {allowanceAvailable && (
            <TripReportTripSelectedAllowanceItems
              changePrice={changePrice}
              allowanceItems={allowanceItems}
              setChangePrice={setChangePrice}
              trip_id={trip?.id}
            />
          )}
        </TripSelectedWrap>
      )}
    </>
  );
};

const TripSelectedWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const PriceSection = styled.div`
  background-color: #f8f6f1;
  padding: 16px;
`;

const PriceSectionBody = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  max-width: 400px;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.95em;
  }

  .total {
    font-size: 1.1em;
  }
`;

const StyledDivider = styled(Divider)``;
