import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import Transit from '@this/domain/transit/transit';
import Hotel from '@this/domain/hotel/hotel';
import Flight from '@this/domain/flight/flight';
import type ReservingTrip from '@this/domain/trip/reserving_trip';

import ElementTransportBoxShortdistance from '../transit_element/transport_box_shortdistance.template';
import ElementTransportBox from '../transit_element/transport_box.template';
import ElementHotelBox from '../transit_element/hotel_box.template';
import ElementFlightBox from '../transit_element/flight_box.template';

type Props = {
  reservingTrip: ReservingTrip;
};

const ReserveConfirmOutlineDetailTemplate: React.SFC<Props> = ({ reservingTrip }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <TransportBox>
          {reservingTrip.items.map(
            (item, idx) =>
              item && (
                <React.Fragment key={idx + 1}>
                  <Icon>{idx + 1}</Icon>
                  <TransportBoxContents>
                    {item.element_type === 'transport' && item.element instanceof Transit ? (
                      item.element.segments?.map((segment, i) =>
                        segment.leg_summaries
                          ? segment.leg_summaries?.map((legsummary, j) => (
                              <ElementTransportBoxShortdistance
                                segment={segment}
                                legsummary={legsummary}
                                i={i}
                                j={j}
                                key={`${i}_${j}`}
                              />
                            ))
                          : segment.legs?.map((leg, j) => (
                              <ElementTransportBox segment={segment} leg={leg} i={i} j={j} key={`${i}_${j}`} />
                            ))
                      )
                    ) : item.element_type === 'flight' && item.element instanceof Flight ? (
                      item.element.sliceFromSubtype('outword') ? (
                        <ElementFlightBox flightSlice={item.element.sliceFromSubtype('outword')} />
                      ) : null
                    ) : item.element_type === 'hotel' && item.element instanceof Hotel ? (
                      <ElementHotelBox hotel={item.element} />
                    ) : null}
                  </TransportBoxContents>
                </React.Fragment>
              )
          )}
          {reservingTrip.items.map(
            (item, i) =>
              item &&
              item.element_type === 'flight' &&
              item.element instanceof Flight && (
                <React.Fragment key={i + 1}>
                  <Icon>{reservingTrip.items.length + 1}</Icon>
                  <TransportBoxContents>
                    {item.element.sliceFromSubtype('homeword') && (
                      <ElementFlightBox flightSlice={item.element.sliceFromSubtype('homeword')} />
                    )}
                  </TransportBoxContents>
                </React.Fragment>
              )
          )}
          <Icon>&nbsp;</Icon>
        </TransportBox>
      )}
      <Button onClick={() => setShow(!show)}>
        {show ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="#B9A678"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M18 15l-6-6-6 6" />
            </svg>
            &nbsp;&nbsp;旅程の詳細を閉じる
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              stroke="#B9A678"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
            &nbsp;&nbsp;この旅程のルートを見る
          </>
        )}
      </Button>
    </>
  );
};

const TransportBox = styled.div`
  flex-shrink: 10;
  padding: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0;
  margin-top: 10px;
`;

const TransportBoxContents = styled.div`
  padding: 0 0 0 20px;
  margin-left: 8px;
  border-left: solid 4px ${props => props.theme.iconColor};
  display: flex;
  flex-flow: column;
  gap: 6px 0;
`;

export const Icon = styled.div`
  text-align: center;
  width: 20px;
  height: 20px;
  background: ${props => props.theme.iconColor};
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin: -6px 10px -6px 0;
  z-index: 2;
`;

const Button = styled.div`
  color: #b9a678;
  background: white;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  width: 220px;
  white-space: nowrap;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ReserveConfirmOutlineDetailTemplate;
