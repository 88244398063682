/* eslint-disable max-lines */
import React from 'react';
import { withTheme } from 'styled-components';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type Trip from '@this/domain/trip/trip';
import TravelerDomain from '@this/domain/traveler/traveler';
import type OrderItem from '@this/domain/order_item';
import OrderItemOriginalPrice from '@this/domain/order_item_original_price';
import OrderItemPriceDetail from '@this/domain/order_item_price_detail';
import type BulkTicket from '@this/domain/bulk_ticket2';
import type ShareholderTicketList from '@this/domain/shareholder_ticket_list';
import PriceField from '@this/shared/price_field/price_field';
import Text from '@this/shared/text/text';
import Button from '@this/shared/atoms/button';
import { Button as NewButton } from '@this/shared/ui/inputs/button/button';
import type TaxType from '@this/domain/tax_type';
import UserSearchRepositoty from '@this/domain/arrangement/user_search.repository';
import type TransportElement from '@this/domain/transport_element';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import type { TravelerCandidate } from '@this/domain/arrangement/user_search.repository';
import moment from 'moment/moment';
import type { ShareholderInfo } from '@this/domain/other_service_shareholder_info';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import OrderItemSupplierItemSelect from './order_item_supplier_item_select';
import type { Traveler, TravelerFormData } from './type';
import OrderItemTravelerForm from './order_item_traveler_form';

interface Props {
  index: number;
  serviceId: number;
  selectedTrip: Trip;
  orderItem: OrderItem;
  bulkTickets: BulkTicket[];
  shareholderTickets: ShareholderTicketList;
  taxTypes: TaxType[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList;
  shareholderInfos: ShareholderInfo[];
  individualTargetSuppliedItems: IndividualTargetSuppliedItem[];
  theme: { themeClass: string };
  classNameForModifiedField: (...path: (string | number)[]) => string;
  changed?: () => void;
}

interface State {
  candidates: TravelerCandidate[];
  candidatesLoading: boolean;
  shownTravelerPopupId: string | null;
  selectedBulkTickets: BulkTicket[];
  openPriceDetails: boolean;
  errors: string[];
}

class OrderItemPriceForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      candidates: [],
      candidatesLoading: false,
      shownTravelerPopupId: null,
      selectedBulkTickets: [],
      errors: [],
      openPriceDetails: props.orderItem.orderItemCategory !== 'foreign_air'
    };
  }

  fetchCandidates = async (query: string) => {
    this.setState({ candidatesLoading: true });
    const traverlerUserIds = this.props.orderItem.travelerInformations.map((t: any) => t.userId);
    const results = await UserSearchRepositoty.search(this.props.selectedTrip.user.organization.id, query);
    const candidates = results.filter(u => !traverlerUserIds.includes(u.id));

    this.setState({ candidates, candidatesLoading: false });
  };

  handlePeopleNumChange(value: number): void {
    // 紐づけを解除する回数券のid
    const ids = _.slice(this.props.orderItem.price.bulkTicketIds, value);

    for (let i = 0; i < ids.length; i += 1) {
      const id = ids[i];
      const t = _.find(this.props.bulkTickets, t => {
        return t.id === Number(id);
      });
      if (t) {
        t.cancelUse();
      }
    }

    this.props.orderItem.handlePeopleNumChange(value);
  }

  handleBulkTicketChange(index: number, value: number): void {
    const price = this.props.orderItem.price;
    const prevTicketId = price.bulkTicketIds[index];
    if (prevTicketId) {
      // すでに別のが紐付いていた
      const prevTicket = _.find(this.props.bulkTickets, t => t.id === Number(prevTicketId));
      if (prevTicket) {
        prevTicket.cancelUse();
      }
    }
    const nextTicket = _.find(this.props.bulkTickets, t => t.id === Number(value));
    if (nextTicket) {
      nextTicket.use();
      const selectedBulkTickets = this.state.selectedBulkTickets;
      selectedBulkTickets[index] = nextTicket;
      this.setState({ selectedBulkTickets });
    }
    price.setBulkTicket(index, value);
  }

  handleBulkTicketFragmentChange(index: number, value: number): void {
    const price = this.props.orderItem.price;
    price.setBulkTicketFragment(index, value);
  }

  handleChangeOrderItemPrice(value: number): void {
    const orderItemPrice = this.props.orderItem.price;
    orderItemPrice.setPrice(value);
    if (orderItemPrice.priceDetails && orderItemPrice.priceDetails.length > 0) {
      orderItemPrice.priceDetails[0].setPrice(value);
    }
  }

  handleClickTravelerAdd = () => {
    const newId = this.state.shownTravelerPopupId === 'new' ? null : 'new';
    this.setState({ shownTravelerPopupId: newId });
  };

  handleAddTraveler = (formData: TravelerFormData) => {
    try {
      // 永続化していない編集データの管理のために仮のidが必要
      // uuidのほうが安全だが、4桁の乱数程度でよいと判断
      const tempId = `temp-${Math.floor(Math.random() * (9999 + 1 - 1000)) + 1000}`;
      this.props.orderItem.addTraveler({ id: tempId, ...formData });
      this.handleClickTravelerAdd();
      app.render();
      this.props.changed?.();
    } catch (errors) {
      if (Array.isArray(errors)) this.setState({ errors });
    }
  };

  handleClickTravelerEdit = (id: string) => {
    const newId = this.state.shownTravelerPopupId === id ? null : id;
    this.setState({ shownTravelerPopupId: newId, errors: [] });
  };

  handleEditTraveler = (traveler: Traveler, formData: TravelerFormData) => {
    try {
      const updatationTraveler = new TravelerDomain({ ...traveler, ...formData, type: 'companion' });
      this.props.orderItem.updateTraveler(updatationTraveler);

      this.handleClickTravelerEdit(traveler.id.toString());
      app.render();
      this.props.changed?.();
    } catch (errors) {
      if (Array.isArray(errors)) this.setState({ errors });
    }
  };

  handleDeleteTraveler = (traveler: Traveler) => {
    this.props.orderItem.removeTraveler(traveler.id);
    app.render();
    this.props.changed?.();
  };

  handleAddPriceDetail(): void {
    const orderItemPrice = this.props.orderItem.price;
    const priceDetails = orderItemPrice.priceDetails;
    if (priceDetails) {
      priceDetails.push(
        new OrderItemPriceDetail({
          name: '',
          price: 0,
          orderItemPriceId: orderItemPrice.id,
          taxTypeId: orderItemPrice.taxTypeId || 0
        })
      );
    }
    app.render();
    this.props.changed?.();
  }

  handleDeletePriceDetail(index: number): void {
    if (confirm('内訳を削除しますか？')) {
      const priceDetails = this.props.orderItem.price.priceDetails;
      if (priceDetails) {
        priceDetails.splice(index, 1);
      }
      this.setTotalPrice();
      app.render();
      this.props.changed?.();
    }
  }

  setTotalPrice(): void {
    const orderItemPrice = this.props.orderItem.price;
    if (orderItemPrice.priceDetails) {
      const total = orderItemPrice.priceDetails.reduce((value, detail) => value + detail.price, 0);
      orderItemPrice.setPrice(total);
    }
  }

  setOriginalTotalPrice(): void {
    const orderItemPrice = this.props.orderItem.price;
    orderItemPrice.setOriginalTotalPrice();
  }

  getSuppliedItemId(
    individualTargetSuppliedItems: IndividualTargetSuppliedItem[],
    elements: any,
    targetType: string
  ): number | undefined {
    let airlineCompanyType = '';
    const elementName = elements[0].name;

    const jalRegex = /(jal|jac|jta|rac)/i;
    const anaRegex = /ana/i;
    const sfjRegex = /sfj/i;

    if (jalRegex.test(elementName)) {
      airlineCompanyType = 'JAL';
    } else if (anaRegex.test(elementName)) {
      airlineCompanyType = 'ANA';
    } else if (sfjRegex.test(elementName)) {
      airlineCompanyType = 'SFJ';
    }

    const matchedItem = individualTargetSuppliedItems.find(item => {
      return item.airline_company_type === airlineCompanyType && item.target_type === targetType;
    });

    if (matchedItem) {
      return matchedItem.supplied_item_id;
    }

    return undefined;
  }

  getShareholderInfoOriginalPrice(
    originalPrices: any,
    elements: any,
    shareholderInfos: ShareholderInfo[],
    targetType: string
  ): number {
    const targetSupplierType = targetType.includes('アリーズ') ? 'allies' : 'frontier';
    const purchasedAt = moment(originalPrices[0].purchasedAt);
    const elementName = elements[0].name;

    let priceKey = '';

    const jalRegex = /(jal|jac|jta|rac)/i;
    const anaRegex = /ana/i;
    const sfjRegex = /sfj/i;

    if (jalRegex.test(elementName)) {
      priceKey = 'purchase_price_jal';
    } else if (anaRegex.test(elementName)) {
      priceKey = 'purchase_price_ana';
    } else if (sfjRegex.test(elementName)) {
      priceKey = 'purchase_price_sfj';
    }

    const selectedPriceInfo = shareholderInfos.find(info => {
      const from = moment(info.from);
      const to = info.to !== null ? moment(info.to) : moment('9999-12-31');

      const fromDate = from.format('YYYY-MM-DD');
      const toDate = to.format('YYYY-MM-DD');
      const purchasedDate = purchasedAt.format('YYYY-MM-DD');

      return (
        purchasedDate >= fromDate &&
        purchasedDate <= toDate &&
        info.supplier_type === targetSupplierType &&
        priceKey
      );
    });

    if (selectedPriceInfo !== null && selectedPriceInfo !== undefined) {
      const selectedPrice = selectedPriceInfo[priceKey];
      if (selectedPrice !== null && selectedPrice !== undefined) {
        return selectedPrice;
      }
    }

    return 0;
  }

  handleAddOriginalPriceCommon(
    suppliedItems: SuppliedItem[],
    originalPrices: OrderItemOriginalPrice[] | null,
    elements: any,
    shareholderInfos: ShareholderInfo[],
    targetType: string
  ): void {
    const { individualTargetSuppliedItems } = this.props;
    const price = this.getShareholderInfoOriginalPrice(originalPrices, elements, shareholderInfos, targetType);
    const suppliedItemId = this.getSuppliedItemId(individualTargetSuppliedItems, elements, targetType);

    if (suppliedItems && originalPrices) {
      originalPrices.push(
        new OrderItemOriginalPrice({
          orderItemOriginalPriceType: this.props.orderItem.orderItemType,
          price,
          paymentMethodId: suppliedItems.find(item => item.id === suppliedItemId)?.paymentMethodId || undefined,
          suppliedItemId,
          purchasedAt: moment(originalPrices[0].purchasedAt).format('YYYY-MM-DD')
        })
      );
      this.setOriginalTotalPrice();

      app.render();
      this.props.changed?.();
    }
  }

  handleAddAlliesOriginalPrice(): void {
    const { suppliedItems, orderItem, shareholderInfos } = this.props;
    const originalPrices = this.props.orderItem.price.originalPrices;
    const { elements } = orderItem;

    this.handleAddOriginalPriceCommon.call(
      this,
      suppliedItems,
      originalPrices,
      elements,
      shareholderInfos,
      'アリーズ株主優待券'
    );
  }

  handleAddFrontierOriginalPrice(): void {
    const { suppliedItems, orderItem, shareholderInfos } = this.props;
    const originalPrices = this.props.orderItem.price.originalPrices;
    const { elements } = orderItem;

    this.handleAddOriginalPriceCommon.call(
      this,
      suppliedItems,
      originalPrices,
      elements,
      shareholderInfos,
      'フロンティア株主優待券'
    );
  }

  handleAddOriginalPrice(suppliedItems: SuppliedItem[], suppliedItemId: number): void {
    const originalPrices = this.props.orderItem.price.originalPrices;
    if (suppliedItems && originalPrices) {
      originalPrices.push(
        new OrderItemOriginalPrice({
          orderItemOriginalPriceType: this.props.orderItem.orderItemType,
          price: 0,
          paymentMethodId: suppliedItems.find(item => item.id === suppliedItemId)?.paymentMethodId || undefined
        })
      );
    }
    app.render();
    this.props.changed?.();
  }

  handleDeleteOriginalPrice(index: number): void {
    if (confirm('新卸値を削除しますか？')) {
      const originalPrices = this.props.orderItem.price.originalPrices;
      if (originalPrices) {
        originalPrices.splice(index, 1);
      }
      app.render();
      this.props.changed?.();
    }
  }

  findBulkTicketById(id: string): BulkTicket {
    const t = _.find(this.props.bulkTickets, t => {
      return `${t.id}` === id;
    });
    return t || this.props.bulkTickets[0];
  }

  getTransportType(orderItem: OrderItem): TransportElement.TransportType | null {
    if (orderItem.transports.length) {
      return orderItem.transports[0].transportType;
    }
    if (orderItem.elements.length && orderItem.elements[0].type === 'transport') {
      const tr = orderItem.elements[0] as TransportElement;
      return tr.transportType;
    }
    return null;
  }

  getTransportDate(orderItem: OrderItem): string | null {
    if (orderItem.transports.length) {
      return orderItem.transports[0].from.dateStr();
    }
    if (orderItem.elements.length && orderItem.elements[0].type === 'transport') {
      const tr = orderItem.elements[0] as TransportElement;
      return tr.from.dateStr();
    }
    return null;
  }

  isMatchTargetSuppliedItem(
    individualTargetSuppliedItems: IndividualTargetSuppliedItem[],
    originalPrices: OrderItemOriginalPrice[],
    targetType: string
  ): boolean {
    return individualTargetSuppliedItems.some(item =>
      originalPrices.some(
        originalPrice =>
          item.display_condition_supplied_item_id &&
          originalPrice.suppliedItemId &&
          item.display_condition_supplied_item_id === originalPrice.suppliedItemId &&
          item.target_type === targetType
      )
    );
  }

  render() {
    const {
      index,
      selectedTrip,
      orderItem,
      bulkTickets,
      shareholderTickets,
      taxTypes,
      suppliedItems,
      paymentMethods,
      theme,
      classNameForModifiedField,
      changed
    } = this.props;
    const { candidates, candidatesLoading, shownTravelerPopupId, selectedBulkTickets, openPriceDetails, errors } =
      this.state;
    try {
      const peopleNumArray: number[] = [];
      for (let i = 0; i < orderItem.peopleNum; i += 1) {
        peopleNumArray.push(i);
      }
      return (
        <Wrapper>
          <InputWrapperBase>
            <Label>利用者</Label>
            {orderItem.travelerInformations.map((t: any, i: number) => (
              <TravelerBlock key={i}>
                <span>
                  {t.lastNameKana ? `${t.lastNameKana} ${t.firstNameKana}` : t.email ? t.email : t.user.email}
                  {t.flightBirthday ? `(${t.flightBirthday})` : '(未設定)'}
                </span>
                <LinkButton onClick={() => this.handleClickTravelerEdit(t.id.toString())}>編集</LinkButton>

                <DeleteButton onClick={() => this.handleDeleteTraveler(t)}>×</DeleteButton>

                {shownTravelerPopupId === t.id.toString() && (
                  <StyledOrderItemTravelerForm
                    traveler={t}
                    candidates={candidates}
                    transportType={this.getTransportType(orderItem)}
                    transportDate={this.getTransportDate(orderItem)}
                    candidatesLoading={candidatesLoading}
                    errors={errors}
                    onSubmit={formData => this.handleEditTraveler(t, formData)}
                    onCancel={() => this.handleClickTravelerEdit(t.id.toString())}
                    onSearchCandidates={this.fetchCandidates}
                    classNameForModifiedField={(...path) =>
                      classNameForModifiedField('travelerInformations', i, ...path)
                    }
                  />
                )}
              </TravelerBlock>
            ))}
            <IndentedWrapper>
              <AddButton onClick={this.handleClickTravelerAdd}>利用者を追加</AddButton>
              {shownTravelerPopupId === 'new' && (
                <StyledOrderItemTravelerForm
                  candidates={candidates}
                  transportType={this.getTransportType(orderItem)}
                  transportDate={this.getTransportDate(orderItem)}
                  candidatesLoading={candidatesLoading}
                  errors={errors}
                  onSubmit={formData => this.handleAddTraveler(formData)}
                  onCancel={this.handleClickTravelerAdd}
                  onSearchCandidates={this.fetchCandidates}
                  classNameForModifiedField={() => ''}
                />
              )}
            </IndentedWrapper>
          </InputWrapperBase>
          {orderItem.isAvailableBulkTicket() ? null : (
            <>
              <InputWrapper>
                <Label>代金(ユーザーに見せる)</Label>
                <p>{orderItem.price.price.toLocaleString()}円</p>
              </InputWrapper>
              <InputWrapperBase>
                {orderItem.price.priceDetails && openPriceDetails && (
                  <>
                    {orderItem.price.priceDetails.map((priceDetail, index) => (
                      <div key={index}>
                        <IndentedWrapper>
                          <Label className={classNameForModifiedField('price', 'priceDetails', index, 'name')}>
                            {index + 1}.項目名
                          </Label>
                          <input
                            type="text"
                            value={priceDetail.name}
                            onChange={e => {
                              priceDetail.setName(e.target.value);
                            }}
                            onBlur={() => changed?.()}
                          />
                          {index !== 0 && (
                            <DeleteButton onClick={e => this.handleDeletePriceDetail(index)}>×</DeleteButton>
                          )}
                        </IndentedWrapper>
                        <Depth2IndentedWrapper>
                          <Label className={classNameForModifiedField('price', 'priceDetails', index, 'price')}>
                            金額
                          </Label>
                          <PriceField
                            initialValue={priceDetail.price}
                            onChange={value => {
                              priceDetail.setPrice(value);
                              this.setTotalPrice();
                              app.render();
                              this.props.changed?.();
                            }}
                          />
                          <p>円</p>
                        </Depth2IndentedWrapper>
                        <Depth2IndentedWrapper>
                          <Label
                            className={classNameForModifiedField('price', 'priceDetails', index, 'taxTypeId')}
                          >
                            税区分
                          </Label>
                          <select
                            value={priceDetail.taxTypeId}
                            onChange={e => {
                              priceDetail.setTaxTypeId(parseInt(e.target.value, 10));
                              changed?.();
                            }}
                          >
                            {taxTypes.map(taxType => (
                              <option key={taxType.id} value={taxType.id}>
                                {taxType.name}
                              </option>
                            ))}
                          </select>
                        </Depth2IndentedWrapper>
                      </div>
                    ))}
                  </>
                )}
                {openPriceDetails ? (
                  <IndentedWrapper>
                    <AddButton onClick={e => this.handleAddPriceDetail()}>内訳を追加</AddButton>
                    {orderItem.orderItemCategory === 'foreign_air' && (
                      <ActionButton color="sub" onClick={() => this.setState({ openPriceDetails: false })}>
                        内訳を閉じる
                      </ActionButton>
                    )}
                  </IndentedWrapper>
                ) : (
                  <IndentedWrapper>
                    <ActionButton onClick={() => this.setState({ openPriceDetails: true })}>
                      内訳を開く
                    </ActionButton>
                  </IndentedWrapper>
                )}
              </InputWrapperBase>
              {orderItem.price.originalPrices && (
                <InputWrapperBase>
                  <InputWrapper>
                    <Label>新卸値(ユーザーに見せない)</Label>
                    <p>{orderItem.price.originalPrice.toLocaleString()}円</p>
                  </InputWrapper>
                  <div>
                    {orderItem.price.originalPrices.map((originalPrice, index) => (
                      <div key={index}>
                        <IndentedWrapper>
                          <Label className={classNameForModifiedField('price', 'originalPrices', index, 'price')}>
                            {index + 1}.
                          </Label>
                          <PriceField
                            initialValue={originalPrice.price}
                            onChange={value => {
                              originalPrice.setPrice(value);
                              this.setOriginalTotalPrice();
                              changed?.();
                            }}
                          />
                          <p>円</p>
                          <DeleteButton onClick={e => this.handleDeleteOriginalPrice(index)}>×</DeleteButton>
                        </IndentedWrapper>
                        <Depth2IndentedWrapper>
                          <Label
                            className={classNameForModifiedField('price', 'originalPrices', index, 'taxTypeId')}
                          >
                            税区分(仕入管理用)
                          </Label>
                          <select
                            value={originalPrice.taxTypeId || '2'}
                            onChange={e => {
                              originalPrice.setTaxTypeId(
                                _.isEmpty(e.target.value) ? null : parseInt(e.target.value, 10)
                              );
                              changed?.();
                            }}
                          >
                            {taxTypes.map(taxType => (
                              <option key={taxType.id} value={taxType.id}>
                                {taxType.name}
                              </option>
                            ))}
                          </select>
                        </Depth2IndentedWrapper>
                        {suppliedItems && (
                          <Depth2IndentedWrapper>
                            <Label
                              className={classNameForModifiedField(
                                'price',
                                'originalPrices',
                                index,
                                'suppliedItemId'
                              )}
                            >
                              仕入商品
                            </Label>
                            <OrderItemSupplierItemSelect
                              suppliedItems={_.filter(suppliedItems, item => item.disabled === false)}
                              onSelect={(v: number) => {
                                originalPrice.setSuppliedItemId(v);
                                originalPrice.setPaymentMethod(
                                  suppliedItems.find(s => s.id === v)?.paymentMethodId ?? null
                                );
                                changed?.();
                              }}
                              suppliedItemId={originalPrice.suppliedItemId}
                            />
                          </Depth2IndentedWrapper>
                        )}
                        <Depth2IndentedWrapper>
                          <Label
                            className={classNameForModifiedField(
                              'price',
                              'originalPrices',
                              index,
                              'paymentMethodId'
                            )}
                          >
                            仕入先決済方法
                          </Label>
                          <div>
                            <Select
                              value={originalPrice.paymentMethodId?.toString() || ''}
                              onChange={e => {
                                originalPrice.setPaymentMethod(parseInt(e.target.value, 10) || null);
                                changed?.();
                              }}
                              disabled={(orderItem.price.originalPrices?.length || 0) < 1}
                            >
                              <option value="">
                                {this.props.paymentMethods.default(
                                  originalPrice.defaultPaymentMethod(this.props.suppliedItems) || 0
                                )
                                  ? 'デフォルト値を設定'
                                  : '未設定'}
                              </option>
                              {paymentMethods.list.map(paymentMethod => (
                                <option key={paymentMethod.id} value={paymentMethod.id?.toString() || ''}>
                                  {paymentMethod.codeWithName()}
                                </option>
                              ))}
                            </Select>
                            <Default>
                              デフォルト：
                              {this.props.paymentMethods.default(
                                originalPrice.defaultPaymentMethod(this.props.suppliedItems) || 0
                              )}
                            </Default>
                          </div>
                        </Depth2IndentedWrapper>
                        <Depth2IndentedWrapper>
                          <Label
                            className={classNameForModifiedField('price', 'originalPrices', index, 'purchasedAt')}
                          >
                            仕入日
                          </Label>
                          <DatetimePicker
                            value={originalPrice.purchasedAt}
                            onChange={d => {
                              originalPrice.setPurchasedAt(d);
                              changed?.();
                            }}
                            showTime={false}
                            disabledDays={0}
                            showPast
                            border
                          />
                        </Depth2IndentedWrapper>
                      </div>
                    ))}
                  </div>
                  <IndentedWrapperWithGap>
                    <AddButton onClick={e => this.handleAddOriginalPrice(this.props.suppliedItems, 0)}>
                      新卸値を追加
                    </AddButton>
                    {selectedTrip.serviceId === 1 && orderItem.price.originalPrices.length > 0 && (
                      <>
                        {this.isMatchTargetSuppliedItem(
                          this.props.individualTargetSuppliedItems,
                          orderItem.price.originalPrices,
                          'アリーズ株主優待券'
                        ) && (
                          <AddButtonWithMarginLeft onClick={e => this.handleAddAlliesOriginalPrice()}>
                            アリーズ株優を追加
                          </AddButtonWithMarginLeft>
                        )}
                        {this.isMatchTargetSuppliedItem(
                          this.props.individualTargetSuppliedItems,
                          orderItem.price.originalPrices,
                          'フロンティア株主優待券'
                        ) && (
                          <AddButtonWithMarginLeft onClick={e => this.handleAddFrontierOriginalPrice()}>
                            フロンティア株優を追加
                          </AddButtonWithMarginLeft>
                        )}
                      </>
                    )}
                  </IndentedWrapperWithGap>
                </InputWrapperBase>
              )}
              {orderItem.transports[0]?.ticketingType === 1 ? (
                <InputWrapper>
                  <Label className={classNameForModifiedField('transports', 0, 'alliesApplicationNumber')}>
                    アリーズ申込識別番号
                  </Label>
                  <input
                    type="text"
                    value={orderItem.transports[0]?.alliesApplicationNumber || ''}
                    onChange={e => orderItem.transports[0]?.handleAlliesApplicationNumber(e.target.value)}
                    onBlur={() => changed?.()}
                  />
                </InputWrapper>
              ) : orderItem.transports[0]?.ticketingType === 5 ? (
                <InputWrapper>
                  <Label className={classNameForModifiedField('transports', 0, 'frontierApplicationNumber')}>
                    フロンティア申込識別番号
                  </Label>
                  <input
                    type="text"
                    value={orderItem.transports[0]?.frontierApplicationNumber || ''}
                    onChange={e => orderItem.transports[0]?.handleFrontierApplicationNumber(e.target.value)}
                    onBlur={() => changed?.()}
                  />
                </InputWrapper>
              ) : orderItem.transports[0]?.ticketingType === 6 ? (
                <InputWrapper>
                  <Label className={classNameForModifiedField('transports', 0, 'skytoursApplicationNumber')}>
                    スカイツアーズ申込識別番号
                  </Label>
                  <input
                    type="text"
                    value={orderItem.transports[0]?.skytoursApplicationNumber || ''}
                    onChange={e => orderItem.transports[0]?.handleSkytoursApplicationNumber(e.target.value)}
                    onBlur={() => changed?.()}
                  />
                </InputWrapper>
              ) : null}
              {utils.isAiTravel(this.props.serviceId) && orderItem.foreignFlightTicketIssuer === 'tsd' && (
                <InputWrapper>
                  <Label className={classNameForModifiedField('tsdInvoiceNumber')}>
                    ティ・エス・ディ請求書番号
                  </Label>
                  <input
                    type="text"
                    value={orderItem.tsdInvoiceNumber || ''}
                    onChange={e => orderItem.handleTsdInvoiceNumber(e.target.value)}
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <Label className={classNameForModifiedField('price', 'margin', 'amount')}>手数料</Label>
                <PriceField
                  initialValue={orderItem.price.margin.amount}
                  onChange={value => {
                    orderItem.price.margin.setAmount(value);
                    changed?.();
                  }}
                />
                <p>円</p>
                <ReCalcButton
                  onClick={e => {
                    orderItem.price.calcMarginAmount(e);
                    changed?.();
                  }}
                >
                  再計算
                </ReCalcButton>
              </InputWrapper>
            </>
          )}
          <InputWrapper>
            <Label className={classNameForModifiedField('price', 'refund')}>返金予定額（仕訳用）</Label>
            <PriceField
              initialValue={orderItem.price.refund}
              onChange={value => {
                orderItem.price.setRefund(value);
                changed?.();
              }}
            />
            <p>円</p>
          </InputWrapper>
          <InputWrapper>
            <Label className={classNameForModifiedField('payment', 'paymentType')}>支払い方法</Label>
            <select
              value={orderItem.payment.paymentType}
              onChange={e => {
                orderItem.payment.handlePaymentTypeChange(e.target.value);
                changed?.();
              }}
            >
              {theme.themeClass === 'aitravel' && <option value={0}>クレジットカード</option>}
              <option value={1}>請求書</option>
              <option value={2}>現地払い</option>
              <option value={3}>回数券</option>
            </select>
          </InputWrapper>
          <InputWrapper>
            <Label
              className={classNameForModifiedField('price', 'selfLoss')}
              htmlFor={`trip_${selectedTrip.id}-item_${index}-selfLoss`}
            >
              自損による損失
            </Label>
            <input
              type="checkbox"
              id={`trip_${selectedTrip.id}-item_${index}-selfLoss`}
              checked={orderItem.price.selfLoss || false}
              onChange={e => {
                orderItem.price.setSelfLoss(e.target.checked);
                changed?.();
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Label
              className={classNameForModifiedField('price', 'debtWaiver')}
              htmlFor={`trip_${selectedTrip.id}-item_${index}-debtWaiver`}
            >
              請求権放棄による損金
            </Label>
            <input
              type="checkbox"
              id={`trip_${selectedTrip.id}-item_${index}-debtWaiver`}
              checked={orderItem.price.debtWaiver || false}
              onChange={e => {
                orderItem.price.setDebtWaiver(e.target.checked);
                changed?.();
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Label className={classNameForModifiedField('price', 'lossWorkflowUrl')}>ワークフローURL</Label>
            <input
              type="text"
              value={orderItem.price.lossWorkflowUrl || ''}
              onChange={e => orderItem.price.setLossWorkflowUrl(e.target.value)}
              onBlur={() => changed?.()}
            />
          </InputWrapper>
          {orderItem.isChangeableExist() && (
            <>
              {orderItem.price.shareholderTicketIds.map((shareholderTicketId, i) => (
                <div key={i}>
                  <InputWrapper>
                    <Label className={classNameForModifiedField('price', 'shareholderTicketIds', i)}>
                      株優 {i + 1}
                    </Label>
                    <select
                      value={shareholderTicketId || ''}
                      onChange={e => {
                        orderItem.price.handleShareholderTicketChange(i, e.target.value);
                        changed?.();
                      }}
                    >
                      <option value="">なし</option>
                      {shareholderTickets.list.map(t =>
                        !t.tripId || t.tripId === selectedTrip.id ? (
                          <option key={t.id} value={t.id}>
                            {t.summary()}
                          </option>
                        ) : null
                      )}
                    </select>
                    <DeleteButton
                      onClick={() => {
                        orderItem.price.handleShareholderTicketRemove(i);
                        changed?.();
                      }}
                    >
                      ×
                    </DeleteButton>
                  </InputWrapper>
                  {(() => {
                    const currentTicket = shareholderTickets.find(shareholderTicketId);
                    if (currentTicket) {
                      return (
                        <KabuyuDescription>
                          選択中の株優
                          <br />
                          <Text text={currentTicket.description()} />
                        </KabuyuDescription>
                      );
                    }
                    return null;
                  })()}
                </div>
              ))}
              <IndentedWrapper>
                <AddButton
                  onClick={() => {
                    orderItem.price.handleShareholderTicketAdd();
                    changed?.();
                  }}
                >
                  株優を追加
                </AddButton>
              </IndentedWrapper>
            </>
          )}
          {orderItem.isAvailableBulkTicket() ? (
            <>
              {peopleNumArray.map(i => (
                <div key={i}>
                  <InputWrapper>
                    <Label className={classNameForModifiedField('price', 'bulkTicketIds', i)}>回数券(冊)</Label>
                    <select
                      value={orderItem.price.bulkTicketIds[i]}
                      onChange={e => {
                        this.handleBulkTicketChange(i, parseInt(e.target.value, 10));
                        changed?.();
                      }}
                    >
                      <option value="">なし</option>
                      {bulkTickets.map(t =>
                        t.unused > 0 || t.id === parseInt(orderItem.price.bulkTicketIds[i], 10) ? (
                          <option key={t.id} value={t.id}>
                            {t.summary}
                          </option>
                        ) : null
                      )}
                    </select>
                  </InputWrapper>
                  <InputWrapper>
                    {(orderItem.price.bulkTicketIds[i] || selectedBulkTickets[i]) && (
                      <>
                        <FragmentLabel className={classNameForModifiedField('price', 'bulkTicketFragmentIds', i)}>
                          券番
                        </FragmentLabel>
                        <select
                          value={orderItem.price.bulkTicketFragmentIds[i]}
                          onChange={e => {
                            this.handleBulkTicketFragmentChange(i, parseInt(e.target.value, 10));
                            changed?.();
                          }}
                        >
                          <option value="">なし</option>
                          {selectedBulkTickets[i] ? (
                            <>
                              {/* 新規で設定するケース */}
                              {selectedBulkTickets[i].bulkTicketFragments.map(f =>
                                f.status === 0 ? (
                                  <option key={f.id} value={f.id}>
                                    {f.number}
                                  </option>
                                ) : null
                              )}
                            </>
                          ) : (
                            <>
                              {/* すでに紐付られているケース */}
                              {this.findBulkTicketById(orderItem.price.bulkTicketIds[i]).bulkTicketFragments.map(
                                f =>
                                  f.status === 0 ||
                                  f.id === parseInt(orderItem.price.bulkTicketFragmentIds[i], 10) ? (
                                    <option key={f.id} value={f.id}>
                                      {f.number}
                                    </option>
                                  ) : null
                              )}
                            </>
                          )}
                        </select>
                      </>
                    )}
                  </InputWrapper>
                </div>
              ))}
            </>
          ) : null}
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  background-color: ${props => props.theme.grayColorLight};
  padding: 5px;
`;

const InputWrapperBase = styled.div`
  margin-top: 5px;
`;

const InputWrapper = styled(InputWrapperBase)`
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  max-width: 200px;
`;

const ReCalcButton = styled(Button)`
  padding: 5px;
  margin-left: 5px;
  min-width: 60px;
`;

const AddButton = styled(Button)`
  padding: 5px;
  min-width: 60px;
`;

const AddButtonWithMarginLeft = styled(Button)`
  padding: 5px;
  margin-left: 5px;
`;

const ActionButton = styled(NewButton)`
  padding: 5px;
  margin-left: 5px;
  min-width: 60px;
`;

const DeleteButton = styled(Button)`
  padding: 0px;
  margin-left: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
`;

const Label = styled.label`
  flex-shrink: 0;
  margin-right: 5px;
`;

const IndentedWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  padding-left: 40px;
`;

const Depth2IndentedWrapper = styled.div`
  display: flex;
  padding-left: 64px;

  &:last-child {
    padding-bottom: 10px;
  }
`;

const IndentedWrapperWithGap = styled.div`
  position: relative;
  display: flex;
  padding-left: 40px;
  gap: 5px;
`;

const KabuyuDescription = styled.div`
  font-size: 10px;
  border: 1px solid ${props => props.theme.grayBorderColor};
  background: ${props => props.theme.grayColorLight};
  padding: 2px;
  margin-left: 10px;
`;

const LinkButton = styled.a``;

const TravelerBlock = styled(IndentedWrapper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;

  ${LinkButton} {
    margin-left: 4px;
  }

  ${DeleteButton} {
    margin-left: 4px;
  }
`;

const StyledOrderItemTravelerForm = styled(OrderItemTravelerForm)`
  position: absolute;
  bottom: -5px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #333;
  padding: 5px;
  transform: translateY(100%);
  z-index: 1;
`;

const FragmentLabel = styled.label`
  margin-left: 30px;
`;

const Default = styled.div`
  font-size: 0.9em;
  margin-top: 4px;
  color: ${props => props.theme.grayTextColor};
`;

export default withTheme(OrderItemPriceForm);
