import type { OrganizationBase } from '@this/components/organization/organization_base/type';
import React from 'react';

import { Input } from '@this/shared/ui/inputs/input';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { ModalFooter, ModalBody, Modal, ModalHeader } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';

interface Props {
  organizationBase?: OrganizationBase;
  open: boolean;
  onAbort: () => void;
  onSubmit: (organizationBase: OrganizationBase) => void;
}
const OrganizationBaseForm: React.FC<Props> = ({ open, ...props }) => {
  return (
    <Modal open={open} onClose={props.onAbort} className="e2e-modal-edit">
      <OrganizationBaseFormModalContent {...props} />
    </Modal>
  );
};

const OrganizationBaseFormModalContent = ({
  organizationBase = { id: 0, name: '', address: '', postal_code: '', addressee: '' },
  onSubmit,
  onAbort
}: Omit<Props, 'open'>) => {
  const title = organizationBase.id !== 0 ? '拠点を編集' : '拠点を作成';
  const [name, setName] = React.useState(organizationBase.name);
  const [address, setAddress] = React.useState(organizationBase.address);
  const [postalCode, setPostalCode] = React.useState(organizationBase.postal_code);
  const [addressee, setAddressee] = React.useState(organizationBase.addressee);

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <FormControl fullWidth required>
          <InputLabel htmlFor="name">拠点名</InputLabel>
          <Input
            id="name"
            name="organizationBaseName"
            value={name}
            autoFocus
            onChange={e => setName(e.target.value)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="postal-code">郵便番号</InputLabel>
          <Input
            id="postal-code"
            name="postalCode"
            value={postalCode}
            onChange={e => setPostalCode(e.target.value)}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth required>
          <InputLabel htmlFor="address">拠点住所</InputLabel>
          <Input id="address" name="address" value={address} onChange={e => setAddress(e.target.value)} />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="addressee">宛名</InputLabel>
          <Input id="addressee" name="addressee" value={addressee} onChange={e => setAddressee(e.target.value)} />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        <Button color="sub" onClick={onAbort}>
          キャンセル
        </Button>
        <Button
          onClick={() => onSubmit({ id: organizationBase.id, name, address, postal_code: postalCode, addressee })}
        >
          保存
        </Button>
      </ModalFooter>
    </>
  );
};

export default OrganizationBaseForm;
