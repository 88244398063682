import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import _ from 'lodash';
import { InAdvanceApprovalBatchItem } from './in_advance_approval_batch_item';
import type {
  ActionType as ItemActionType,
  InAdvanceApprovalBatchItemArgs
} from './in_advance_approval_batch_item';

export type BatchType = 'unexecuted' | 'processing' | 'complete';
export type ActionType = 'unexecuted' | ItemActionType;
export type TripStatus = 'checkbox' | 'loading' | 'success' | 'failure';

export class InAdvanceApprovalBatch {
  batch: BatchType = 'unexecuted';

  action: ActionType = 'unexecuted';

  batchItems: InAdvanceApprovalBatchItem[] = [];

  updatedAt: Moment | null = null;

  progress = 0;

  total = 0;

  runningTrips: number[] = [];

  successTrips: number[] = [];

  failureTrips: number[] = [];

  selectedTrips: number[] = [];

  constructor(args?: InAdvanceApprovalBatchArgs) {
    if (!args) return;

    this.batch = args.batch;
    this.action = args.action;
    this.batchItems = args.batch_items.map(raw => new InAdvanceApprovalBatchItem(raw));
    this.updatedAt = args.updated_at ? moment(args.updated_at) : null;
    this.progress = args.progress;
    this.total = args.total;

    this.runningTrips = this.batchItems.flatMap(i => (i.isRunning() ? i.tripId : []));
    this.successTrips = this.batchItems.flatMap(i => (i.isSuccess() ? i.tripId : []));
    this.failureTrips = this.batchItems.flatMap(i => (i.isFailure() ? i.tripId : []));
  }

  setSelectedTrips(trips: number[]) {
    this.selectedTrips = trips;
    app.render();
  }

  setSelectedTrip(trip: number) {
    if (this.selectedTrips.includes(trip)) {
      _.remove(this.selectedTrips, i => i === trip);
    } else {
      this.selectedTrips.push(trip);
    }
    app.render();
  }

  tripStatus(trip: number): TripStatus {
    if (this.runningTrips.includes(trip)) {
      return 'loading';
    }
    if (this.successTrips.includes(trip)) {
      return 'success';
    }
    if (this.failureTrips.includes(trip)) {
      return 'failure';
    }

    return 'checkbox';
  }
}

export interface InAdvanceApprovalBatchArgs {
  batch: BatchType;

  action: ActionType;

  batch_items: InAdvanceApprovalBatchItemArgs[];

  updated_at?: string;

  progress: number;

  total: number;
}
