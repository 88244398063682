import type { PaymentMethodArgs } from '@this/src/domain/payment_method/payment_method';
import type { SupplierArgs } from '@this/domain/supplier/supplier';
import type { SuppliedItemArgs } from '@this/src/domain/supplied_item/supplied_item';
import PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import Supplier from '@this/domain/supplier/supplier';
import SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import type { IndividualRailwaySuppliedItemArgs } from '@this/domain/individual_railway_supplied_item/individual_railway_supplied_item';
import IndividualRailwaySuppliedItem from '@this/domain/individual_railway_supplied_item/individual_railway_supplied_item';
import BaseAPI from '../base_api';

interface State {
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethodList | null;
  individualRailwaySuppliedItems: IndividualRailwaySuppliedItem[];
}

interface SupplierOptionsResponse {
  suppliers: SupplierArgs[];
  supplied_items: SuppliedItemArgs[];
  payment_methods: PaymentMethodArgs[];
  individual_railway_supplied_items: IndividualRailwaySuppliedItemArgs[];
}

export default class SupplierOptionsAPI extends BaseAPI {
  public list(): Promise<State> {
    return this.get<SupplierOptionsResponse>(`/suppliers/options.json`).then(res => {
      const suppliers = res.data.suppliers.map((raw: SupplierArgs) => new Supplier(raw));
      const suppliedItems = res.data.supplied_items.map((raw: SuppliedItemArgs) => new SuppliedItem(raw));
      const paymentMethods = new PaymentMethodList(res.data.payment_methods);
      const individualRailwaySuppliedItems = res.data.individual_railway_supplied_items.map(
        (raw: IndividualRailwaySuppliedItemArgs) => new IndividualRailwaySuppliedItem(raw)
      );

      return { suppliers, suppliedItems, paymentMethods, individualRailwaySuppliedItems };
    });
  }
}
