import { styled } from '@this/constants/themes';

export const InvoiceCustomFormatTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
`;

export const InvoiceCustomFormatDescription = styled.p`
  padding: 10px;
  margin: 0;
`;
