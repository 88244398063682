import type { BulkActionAttr } from './types';

export const UPSERT_FORMAT: BulkActionAttr[] = [
  {
    name: 'code',
    summary: '部署コード',
    example: '',
    required: true
  },
  {
    name: 'name',
    summary: '部署名',
    example: '',
    required: true
  },
  {
    name: 'postal_code',
    summary: '郵便番号',
    example: '',
    required: false
  },
  {
    name: 'address',
    summary: '住所',
    example: '',
    required: false
  },
  {
    name: 'addressee',
    summary: '宛名',
    example: '',
    required: false
  },
  {
    name: 'monthly_budget',
    summary: '月次予算',
    example: '',
    required: false
  },
  {
    name: 'disabled',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true
  }
];
