import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import _ from 'lodash';
import Link from '@this/components/shared/atoms/link';

import {
  OrganizationBody,
  OrganizationHeader,
  OrganizationHeaderTitle
} from '@this/components/expenses/organization/organization.style';
import {
  BulkActionSection,
  BulkActionSectionContent,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import type { BulkActionAttr } from '@this/components/organization/types';
import DownloadFormat from '@this/components/organization/csv_bulk_action/download_format';
import type { DownloadType, FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import CsvUploadForm from '@this/components/organization/csv_bulk_action/csv_upload_form';
import FileValidator from '@this/components/organization/csv_bulk_action/file_validator';
import { BackLink } from '@this/components/expenses/main/main';
import FileFormat from '../file_format';

type Props = RouteComponentProps<{ id?: string }>;
interface State {
  loading: boolean;
  title: string;
  description: string;
  formats: BulkActionAttr[];
  validateResult?: FileValidatorResult;
  uploadResult?: { uploaded: boolean; errors?: string[] };
  admin?: boolean;
  organizationName?: string;
}

export default abstract class CsvBulkAction extends React.Component<Props, State> {
  dataEnabled = false;

  abstract historyPath: string;

  abstract backPath: string;

  abstract downloadFormat: (encoding: DownloadType) => void;

  abstract upload: (file: File) => void;

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  render() {
    const { loading, title, description, formats, validateResult, uploadResult } = this.state;
    return (
      <>
        <Header expenses>
          <HeaderTitle expenses>{title}</HeaderTitle>
        </Header>
        <div>
          <BackLink to={this.backPath}>&lt; 一覧に戻る</BackLink>
        </div>
        <OrganizationBody>
          <p>
            {description.split('\n').map((text, i) => (
              <>
                {i !== 0 && <br />}
                {text}
              </>
            ))}
          </p>
          <DownloadFormat downloadFormat={this.downloadFormat} dataEnabled={this.dataEnabled} />
          <BulkActionSections>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>アップロード</h3>
                <Link to={this.historyPath}>アップロード履歴</Link>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <CsvUploadForm
                  loading={loading}
                  historyPath={this.historyPath}
                  backPath={this.backPath}
                  validateResult={validateResult}
                  uploadResult={uploadResult}
                  select={this.checkFile}
                  upload={this.upload}
                />
              </BulkActionSectionContent>
            </BulkActionSection>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>フォーマット</h3>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <FileFormat formats={formats} />
              </BulkActionSectionContent>
            </BulkActionSection>
          </BulkActionSections>
        </OrganizationBody>
      </>
    );
  }

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }
}

const Header = styled(OrganizationHeader)`
  padding: ${({ expenses }: { expenses?: boolean }) => (expenses ? '0' : '10px')};
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: ${({ expenses }: { expenses?: boolean }) => (expenses ? '#927230' : '#404040')};
  font-size: ${({ expenses }: { expenses?: boolean }) => (expenses ? '24px' : '1em')};
  margin: ${({ expenses }: { expenses?: boolean }) => (expenses ? '0 0 12px 0' : '0 0 0.75em')};
`;
