export interface SendListItemArgs {
  id?: number;
  name: string;
  honorific: string;
  pic: string;
  postcode: string;
  address: string;
  sub_address: string;
  alt_shinkansen_seat: string;
  transport_element_id?: number;
  kit_element_id?: number;
  posting_number: string; // 廃止予定
  trip_id: number;
  order_item_id: number;
  traveler_information_id: number;
}

export class SendListItem {
  id?: number;

  name: string;

  honorific: string;

  pic: string;

  postcode: string;

  address: string;

  subAddress: string;

  altShinkansenSeat: string;

  readonly tripId: number;

  readonly orderItemId: number;

  private readonly transportElementId?: number;

  private readonly kitElementId?: number;

  readonly postingNumber: string; // 廃止予定

  private readonly travelerInformationId: number;

  checked: boolean;

  constructor(args: SendListItemArgs) {
    this.id = args.id;
    this.name = args.name;
    this.honorific = args.honorific;
    this.pic = args.pic || '';
    this.postcode = args.postcode || '';
    this.address = args.address;
    this.subAddress = args.sub_address;
    this.altShinkansenSeat = args.alt_shinkansen_seat;
    this.transportElementId = args.transport_element_id;
    this.kitElementId = args.kit_element_id;
    this.postingNumber = args.posting_number;
    this.tripId = args.trip_id;
    this.orderItemId = args.order_item_id;
    this.travelerInformationId = args.traveler_information_id;
    this.checked = true;
  }

  toggleChecked() {
    this.checked = !this.checked;
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      honorific: this.honorific,
      pic: this.pic,
      postcode: this.postcode,
      address: this.address,
      sub_address: this.subAddress,
      alt_shinkansen_seat: this.altShinkansenSeat,
      transport_element_id: this.transportElementId,
      kit_element_id: this.kitElementId,
      trip_id: this.tripId,
      traveler_information_id: this.travelerInformationId
    };
  }
}
