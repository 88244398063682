import React from 'react';

import TextArea from 'react-textarea-autosize';
import type ShippingElement from '../../../../../../domain/shipping_element';
import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: ShippingElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const ShippingElementForm = ({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('category')}`}>
        種別
      </label>
      <select value={element.category} onChange={e => element.handleChangeCategory(e.target.value)}>
        {Object.keys(element.shippingCategoryOptions).map(v => (
          <option key={v} value={v}>
            {element.shippingCategoryOptions[v]}
          </option>
        ))}
      </select>
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('shippedAt')}`}>
        配送日時
      </label>
      <DatetimePicker
        value={element.shippedAt}
        onChange={time => element.handleShippedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('arrivedAt')}`}>
        到着日時
      </label>
      <DatetimePicker
        value={element.arrivedAt}
        onChange={time => element.handleArrivedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('number')}`}>
        特定記録番号
      </label>
      <input type="text" value={element.number} onChange={e => element.handleChangeNumber(e.target.value)} />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remark')}`}>
        備考
        <span className="red">（必須）</span>
      </label>
      <TextArea
        type="text"
        value={element.remark}
        onChange={e => element.handleChangeRemark(e.target.value)}
        minRows={2}
      />
    </div>
  </>
);
export default ShippingElementForm;
