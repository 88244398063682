/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { ButtonBase, ButtonType } from '@this/shared/atoms/button';

import { Fetcher } from '@this/src/util';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import FlashMessage from '@this/shared/flash_message/flash_message';
import {
  OrganizationBody,
  OrganizationTable,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTd,
  OrganizationTdButton,
  AddButton,
  BulkActionLink,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import Department from '@this/domain/department/department';
import DepartmentList from '@this/domain/department/department_list';
import User from '@this/domain/user/user';
import { DeleteMasterButton } from '@this/components/organization/delete-master-button';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import type { OptionPlanKey } from '@this/domain/plan/option_plan';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import { workflowStyleName } from '@this/domain/workflow_style';
import { getColor } from '@this/shared/ui/theme';
import SelectApprovers from './select_approvers/select_approvers';
import DepartmentSharesBlock from './department_shares_block.template';
import Notification from '../../../notification';
import type { DepartmentFilterSortType } from './department_filter';
import { DepartmentFilter } from './department_filter';
import { AutocompleteSelect } from '../../shared/ui/inputs/autocomplete';
import type { FreeeSection } from '../setting/types';

interface Props {}

interface State {
  departments: any;
  members: any[];
  editingDepartment: any;
  deletingDepartment: any;
  selectApproveStages: any;
  filterFormText: string;
  filterDisabled: boolean;
  loading: boolean;
  saving: boolean;
  error: string | null;
  saveError: string | null;
  mode: string;
  currentPage: number;
  totalPage: number;
  orderType: DepartmentFilterSortType | 'CREATED_AT_ASC';
  availableOptionPlans: OptionPlanKey[];
  organizationWorkflowStyle: WorkflowStyle;
  freeeConnected: boolean;
  freeeAutoSaveLoading: boolean;
}

interface DepartmentsResponse {
  departments: any[];
  members: any[];
  available_option_plans: OptionPlanKey[];
  organization_workflow_style: WorkflowStyle;
  total_page: number;
  freee_connected: boolean;
}

interface CreateResponse {
  id: number;
}

const workflowTypesWithName = {
  default: 'デフォルト',
  overseas: '海外出張'
};

type WorkflowTypes = typeof workflowTypesWithName;
export type WorkflowTypeKeys = keyof WorkflowTypes;

const workflowTypesWithLabel: WorkflowTypes = {
  default: 'デフォルト',
  overseas: '海外出張(指定のない場合は、デフォルトのワークフロー設定が適用されます)'
};

class Departments extends React.Component<Props, State> {
  private CodeFieldRef: any;

  private departmentsPath: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      departments: new DepartmentList([], []),
      members: [],
      editingDepartment: null,
      deletingDepartment: null,
      selectApproveStages: { default: [], overseas: [] },
      filterFormText: '',
      filterDisabled: false,
      loading: false,
      saving: false,
      error: null,
      saveError: null,
      mode: 'department',
      totalPage: 1,
      currentPage: 1,
      orderType: 'CREATED_AT_ASC',
      availableOptionPlans: [],
      organizationWorkflowStyle: 'department',
      freeeConnected: false,
      freeeAutoSaveLoading: false
    };

    this.CodeFieldRef = React.createRef<HTMLFormElement>();
    this.departmentsPath = '/organization/departments';
    this.handleApproverChange = this.handleApproverChange.bind(this);
    this.handleChangeDepartmentsRadio = this.handleChangeDepartmentsRadio.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchDepartments();
  }

  fetchDepartments(page = 1, text = '', disabled = false, orderType: DepartmentFilterSortType = 'CREATED_AT_ASC') {
    utils
      .jsonPromise<DepartmentsResponse>(`${this.departmentsPath}.json`, {
        page,
        text,
        disabled,
        order_type: orderType
      })
      .then(
        result => {
          const members = _.map(result.members, u => new User(u));
          this.setState({
            departments: new DepartmentList(result.departments, members),
            members,
            loading: false,
            currentPage: page,
            totalPage: result.total_page,
            filterFormText: text,
            filterDisabled: disabled,
            orderType,
            availableOptionPlans: result.available_option_plans,
            organizationWorkflowStyle: result.organization_workflow_style,
            freeeConnected: result.freee_connected
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  fetchFreeeSections(keyword: string | undefined) {
    return Fetcher.get<FreeeSection[]>('/organization/freee/sections.json', { keyword });
  }

  handleChangeDepartmentsRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      mode: e.target.id
    });
  }

  handleAddDepartmentClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const department = new Department({}, this.state.members);
    this.state.departments.push(department);
    this.setEditingDepartment(department);
  }

  handleEditClick(department: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setEditingDepartment(_.cloneDeep(department));
  }

  setEditingDepartment(department: any) {
    this.setState(
      {
        editingDepartment: department,
        selectApproveStages: _.cloneDeep(department.approveStages)
      },
      () => {
        this.CodeFieldRef.current.focus();
      }
    );
  }

  handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveError: null
    });

    if (this.state.editingDepartment.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  }

  submitCreate() {
    utils
      .jsonPromise<CreateResponse>(this.departmentsPath, this.state.editingDepartment.updateParams(), 'POST')
      .then(
        result => {
          _.assign(this.state.editingDepartment, { id: result.id });
          this.setState({
            editingDepartment: null,
            saving: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  submitUpdate() {
    utils
      .jsonPromise(
        `${this.departmentsPath}/${this.state.editingDepartment.id}`,
        this.state.editingDepartment.updateParams(),
        'PUT'
      )
      .then(
        () => {
          this.setState({
            editingDepartment: null,
            saving: false
          });
          this.fetchDepartments(
            this.state.currentPage,
            this.state.filterFormText,
            this.state.filterDisabled,
            this.state.orderType
          );
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleCancelClick() {
    // remove only new department
    if (!this.state.editingDepartment.id) {
      this.state.departments.remove(this.state.editingDepartment);
    }
    this.setState({
      editingDepartment: null
    });
  }

  handleFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingDepartment[name](e.target.value);
  }

  handleFieldChangeValue(name: string, value: any) {
    this.state.editingDepartment[name](value);
  }

  handleApproverChange(i: number, s: number, member: any, workflowType: WorkflowTypeKeys = 'default') {
    const approveStages = this.state.selectApproveStages;
    if (member === null) {
      switch (s) {
        case 1: // 1次承認者の処理
          if (approveStages[workflowType][s].length < 2) {
            // 承認者数が1以下なら-1 を代入
            approveStages[workflowType][s][0] = -1;
          } else {
            approveStages[workflowType][s].splice(i, 1);
          }
          break;
        default: // 1次承認以外の処理
          if (approveStages[workflowType][s].length < 2) {
            // 承認者数が1以下なら削除処理
            delete approveStages[workflowType][s];
          } else {
            approveStages[workflowType][s].splice(i, 1);
          }
          break;
      }
      this.state.editingDepartment.setApproveStages(approveStages);
    } else {
      approveStages[workflowType][s][i] = member.id;
      this.setApproverIds(approveStages, s, workflowType);
    }
    this.setState({ selectApproveStages: approveStages });
  }

  setApproverIds(approveStages: any, s: number, workflowType: WorkflowTypeKeys = 'default') {
    this.state.editingDepartment.setApproverIds(
      _.intersection(_.compact(approveStages[workflowType][s])),
      s,
      workflowType
    );
  }

  handleAddSelect(s: number, e: React.MouseEvent<HTMLElement>, workflowType: WorkflowTypeKeys = 'default') {
    e.preventDefault();
    const approveStages = this.state.selectApproveStages;
    approveStages[workflowType][s].push('');
    this.state.editingDepartment.setApproveStages(approveStages);
    this.setState({ selectApproveStages: approveStages });
  }

  handleRemoveSelect(s: number, e: React.MouseEvent<HTMLElement>, workflowType: WorkflowTypeKeys = 'default') {
    e.preventDefault();
    const approveStages = this.state.selectApproveStages;
    approveStages[workflowType][s].pop();
    if (approveStages[workflowType][s].length === 0) {
      delete approveStages[workflowType][s];
    }
    this.state.editingDepartment.setApproveStages(approveStages);
    this.setState({ selectApproveStages: approveStages });
  }

  handleAddApproveStage(s: number, e: React.MouseEvent<HTMLElement>, workflowType: WorkflowTypeKeys = 'default') {
    e.preventDefault();
    const approveStages = this.state.selectApproveStages;
    approveStages[workflowType][s + 1] = [];
    approveStages[workflowType][s + 1].push('');
    this.state.editingDepartment.setApproveStages(approveStages);
    this.setState({ selectApproveStages: approveStages });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingDepartment: null });
  }

  handleChangeDepartmentDisabledRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingDepartment.setDisabled(e.target.value === 'disabled');
  }

  handleChangeWorkflowStyle = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.state.editingDepartment.setWorkflowStyle(e.target.value);
  };

  handleDeleteConfirm() {
    utils
      .jsonPromise(`/organization/departments/${this.state.deletingDepartment.id}`, {}, 'DELETE')
      .then(
        _result => {
          this.state.departments.remove(this.state.deletingDepartment);
          this.setState({
            deletingDepartment: null,
            saving: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  grayIfNeeded = (disabled: boolean) => {
    return {
      background: disabled ? '#f2f2f3' : '#ffffff'
    };
  };

  reloadDepartments = () => {
    this.fetchDepartments();
  };

  deleteDepartment = (department: Department) => {
    this.setState({
      saving: true
    });

    utils
      .jsonPromise(`/organization/departments/${department.id}`, {}, 'DELETE')
      .then(() => {
        this.fetchDepartments(
          this.state.currentPage,
          this.state.filterFormText,
          this.state.filterDisabled,
          this.state.orderType
        );
      })
      .catch(e => {
        Notification.error(
          e.status === 400
            ? utils.dig(e, 'responseJSON', 'errors')
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({
          saving: false
        });
      });
  };

  isEditableApprovers = () => {
    const { organizationWorkflowStyle, availableOptionPlans, editingDepartment } = this.state;

    if (!editingDepartment) {
      return false;
    }

    if (availableOptionPlans.includes('custom_workflow') && editingDepartment.workflowStyle) {
      return editingDepartment.workflowStyle === 'department';
    }

    return organizationWorkflowStyle === 'department';
  };

  isDeletableApprover = (i: number, s: number, workflowType: WorkflowTypeKeys = 'default'): boolean => {
    const approveStages = this.state.selectApproveStages;
    // 2次承認者以降は全て削除可能
    if (s > 1) return true;

    // 1次承認で承認者が2名以上
    if (s === 1 && approveStages[workflowType][s].length > 1) return true;

    // 1次承認で承認者が1名のみで，かつ2次承認が無い
    if (
      s === 1 &&
      Object.keys(approveStages[workflowType]).length === 1 &&
      approveStages[workflowType][s].length === 1 &&
      approveStages[workflowType][s][0] !== ''
    ) {
      return true;
    }
    return false;
  };

  autoSaveFreeeSections() {
    this.setState({ freeeAutoSaveLoading: true });

    Fetcher.post('/organization/freee/sections', {})
      .then(() => {
        this.fetchDepartments();
        Notification.success('freee部門名の自動保存が完了しました');
      })
      .catch(() => {
        Notification.error('freee部門名の自動保存に失敗しました');
      })
      .finally(() => {
        this.setState({ freeeAutoSaveLoading: false });
      });
  }

  render() {
    try {
      const {
        departments,
        editingDepartment,
        selectApproveStages,
        deletingDepartment,
        filterFormText,
        filterDisabled,
        loading,
        saving,
        error,
        saveError,
        orderType,
        currentPage,
        totalPage,
        availableOptionPlans,
        organizationWorkflowStyle,
        freeeConnected,
        freeeAutoSaveLoading
      } = this.state;

      return (
        <>
          <FlashMessage message={saveError} type="error" />
          <OrganizationTitle>部署マスタ</OrganizationTitle>
          <OrganizationBody>
            {availableOptionPlans.includes('project_share') && (
              <OrganizationDepartmentsRadioDiv>
                <OrganizationDepartmentsRadioInput
                  type="radio"
                  name="mode"
                  id="department"
                  onChange={this.handleChangeDepartmentsRadio}
                  checked={this.state.mode === 'department'}
                />
                <label htmlFor="department">部署</label>

                <OrganizationDepartmentsRadioInput
                  type="radio"
                  name="mode"
                  id="share"
                  onChange={this.handleChangeDepartmentsRadio}
                  checked={this.state.mode === 'share'}
                />
                <label htmlFor="share">按分</label>
              </OrganizationDepartmentsRadioDiv>
            )}

            {this.state.mode === 'department' ? (
              <>
                <SpaceBetween>
                  <DepartmentFilter
                    onSearch={(text, disabled, orderType) => this.fetchDepartments(1, text, disabled, orderType)}
                  />
                  {freeeConnected && (
                    <AddButton
                      buttonType={ButtonType.SUB}
                      onClick={() => this.autoSaveFreeeSections()}
                      disabled={freeeAutoSaveLoading}
                    >
                      {freeeAutoSaveLoading ? 'freee部門マスタと紐付け中...' : 'freee部門マスタと紐付け'}
                    </AddButton>
                  )}
                </SpaceBetween>
                <Note>承認者を複数設定した場合は、誰か1人が承認した時点で手配依頼が送信されます。</Note>

                <MasterListContainer
                  currentPage={currentPage}
                  totalPage={totalPage}
                  onPaginate={page => this.fetchDepartments(page, filterFormText, filterDisabled, orderType)}
                  createButton={
                    <AddButton onClick={e => this.handleAddDepartmentClick(e)} disabled={!!editingDepartment}>
                      部署を追加
                    </AddButton>
                  }
                  headerContent={
                    <BulkActionArea>
                      <BulkActionLink to="/organization/departments/csv/bulk_upsert">
                        部署一括追加・更新
                      </BulkActionLink>
                      <BulkActionLink to="/organization/departments/csv/bulk_update_approvers">
                        承認者一括追加・更新
                      </BulkActionLink>
                    </BulkActionArea>
                  }
                  renderContent={() => (
                    <OrganizationTable>
                      <tbody>
                        <tr>
                          <OrganizationTh>部署コード</OrganizationTh>
                          <OrganizationTh>部署名</OrganizationTh>
                          <OrganizationTh>承認者</OrganizationTh>
                          <OrganizationTh>郵便番号</OrganizationTh>
                          <OrganizationTh>住所</OrganizationTh>
                          <OrganizationTh>宛名</OrganizationTh>
                          <OrganizationTh>月次予算</OrganizationTh>
                          {freeeConnected && <OrganizationTh>freee部門名</OrganizationTh>}
                          <OrganizationButtonTh>編集</OrganizationButtonTh>
                          <OrganizationButtonTh>削除</OrganizationButtonTh>
                        </tr>
                        {loading ? (
                          <tr>
                            <td colSpan={11}>
                              <SimpleLoading />
                            </td>
                          </tr>
                        ) : error ? (
                          <tr>
                            <td colSpan={11}>
                              <Error>{error}</Error>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {departments.list.map((d: any, i: number) => (
                              <tr style={this.grayIfNeeded(d.disabled)} key={i} data-e2e-id={d.id}>
                                <OrganizationTd className="e2e-code">{d.code}</OrganizationTd>
                                <OrganizationTd className="e2e-name">{d.name}</OrganizationTd>
                                <OrganizationTd className="e2e-approver-names">
                                  {(Object.keys(d.approveStages) as WorkflowTypeKeys[]).map(
                                    (workflowType: WorkflowTypeKeys, j: number) => (
                                      <ApproverListWrap key={j}>
                                        {d.approveStages[workflowType][1].filter((a: number) => a).length > 0 && (
                                          <p>{`[${workflowTypesWithName[workflowType]}]`}</p>
                                        )}
                                        {Object.keys(d.approveStages[workflowType]).map(
                                          (stepNum: string, k: number) =>
                                            !_.isEmpty(
                                              d.approverNames(d.approveStages[workflowType][stepNum])
                                            ) && (
                                              <>
                                                <p>{`${stepNum}次承認者`}</p>
                                                {d
                                                  .approverNames(d.approveStages[workflowType][stepNum])
                                                  .map((name: string, l: number) => (
                                                    <ApproverList key={`${j}_${k}_${l}`}>{name}</ApproverList>
                                                  ))}
                                              </>
                                            )
                                        )}
                                      </ApproverListWrap>
                                    )
                                  )}
                                </OrganizationTd>
                                <OrganizationTd className="e2e-postal-code">{d.postalCode}</OrganizationTd>
                                <OrganizationTd className="e2e-address">{d.address}</OrganizationTd>
                                <OrganizationTd className="e2e-addressee">{d.addressee}</OrganizationTd>
                                <OrganizationTd className="e2e-monthly-budget">
                                  {d.monthly_budget.toString() !== '' && `${d.monthly_budget.toString()}円`}
                                </OrganizationTd>
                                {freeeConnected && <OrganizationTd>{d.freee_section_name}</OrganizationTd>}
                                <OrganizationTd className="e2e-edit">
                                  {!editingDepartment && (
                                    <OrganizationTdButton onClick={e => this.handleEditClick(d, e)}>
                                      編集
                                    </OrganizationTdButton>
                                  )}
                                </OrganizationTd>
                                <OrganizationTd className="e2e-delete">
                                  <DeleteMasterButton
                                    className="e2e-confirm-delete"
                                    targetLabel={d.name}
                                    onConfirm={() => {
                                      this.deleteDepartment(d);
                                    }}
                                  />
                                </OrganizationTd>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </OrganizationTable>
                  )}
                />

                {editingDepartment && (
                  <form onSubmit={e => this.handleFormSubmit(e)}>
                    <Modal
                      show
                      hideModal={() => this.handleCancelClick()}
                      title={editingDepartment.id ? '部署情報を変更する' : '部署情報を追加する'}
                      className="e2e-modal-edit"
                    >
                      <EditWrap>
                        <EditSection>
                          <InputArea>
                            <Label htmlFor="code">部署コード</Label>
                            <Input
                              id="code"
                              type="text"
                              value={editingDepartment.code}
                              onChange={e => this.handleFieldChange('setCode', e)}
                              ref={this.CodeFieldRef}
                            />
                          </InputArea>
                          <InputArea>
                            <Label htmlFor="name">部署名</Label>
                            <Input
                              id="name"
                              type="text"
                              value={editingDepartment.name}
                              onChange={e => this.handleFieldChange('setName', e)}
                            />
                          </InputArea>
                          <InputArea>
                            <Label htmlFor="postal_code">郵便番号</Label>
                            <Input
                              id="postal_code"
                              type="text"
                              value={editingDepartment.postalCode}
                              onChange={e => this.handleFieldChange('setPostalCode', e)}
                            />
                          </InputArea>
                          <InputArea>
                            <Label htmlFor="address">住所</Label>
                            <Input
                              id="address"
                              type="text"
                              value={editingDepartment.address}
                              onChange={e => this.handleFieldChange('setAddress', e)}
                            />
                          </InputArea>
                          <InputArea>
                            <Label htmlFor="addressee">宛名</Label>
                            <Input
                              id="addressee"
                              type="text"
                              value={editingDepartment.addressee}
                              onChange={e => this.handleFieldChange('setAddressee', e)}
                            />
                          </InputArea>
                          <InputArea>
                            <Label htmlFor="monthly_budget">月次予算</Label>
                            <Input
                              id="monthly_budget"
                              type="number"
                              value={editingDepartment.monthly_budget}
                              onChange={e => this.handleFieldChange('setMonthlyBudget', e)}
                            />
                          </InputArea>

                          {freeeConnected && (
                            <InputArea>
                              <Label>freee部門名</Label>
                              <AutocompleteSelect
                                async="FILTERING"
                                placeholder="部門を検索"
                                getOptions={(keyword: string | undefined) => this.fetchFreeeSections(keyword)}
                                getOptionLabel={option => option.name}
                                value={{
                                  id: editingDepartment.freee_section_id,
                                  name: editingDepartment.freee_section_name
                                }}
                                onChange={(_, selected) => {
                                  this.handleFieldChangeValue('setFreeeSection', selected);
                                }}
                              />
                            </InputArea>
                          )}

                          <InputArea>
                            <Label>状態</Label>
                            <Flex>
                              <DepartmentEditRadioValue>
                                <input
                                  type="radio"
                                  value="enabled"
                                  onChange={e => this.handleChangeDepartmentDisabledRadio(e)}
                                  checked={!editingDepartment.disabled}
                                />
                                <span>有効</span>
                              </DepartmentEditRadioValue>
                              <DepartmentEditRadioValue>
                                <input
                                  type="radio"
                                  value="disabled"
                                  onChange={e => this.handleChangeDepartmentDisabledRadio(e)}
                                  checked={editingDepartment.disabled}
                                />
                                <span>無効</span>
                              </DepartmentEditRadioValue>
                            </Flex>
                          </InputArea>
                        </EditSection>
                        <EditSection>
                          {availableOptionPlans.includes('custom_workflow') && (
                            <>
                              <h3>ワークフローのルート設定</h3>
                              <select onChange={e => this.handleChangeWorkflowStyle(e)}>
                                <option value="" selected={editingDepartment.workflowStyle === null}>
                                  システムのデフォルト(現在の設定: {workflowStyleName[organizationWorkflowStyle]}
                                  ごと)
                                </option>
                                <option
                                  value="department"
                                  selected={editingDepartment.workflowStyle === 'department'}
                                >
                                  部署に設定されたワークフロールートを使用する
                                </option>
                                <option value="trip" selected={editingDepartment.workflowStyle === 'trip'}>
                                  旅程ごとにワークフロールートを指定する
                                </option>
                              </select>
                            </>
                          )}
                          {this.isEditableApprovers() && (
                            <>
                              <h3>ワークフローの設定</h3>
                              {(Object.keys(workflowTypesWithLabel) as WorkflowTypeKeys[]).map(workflowType => (
                                <ApproverInputArea key={workflowType} id={`${workflowType}-workflow`}>
                                  <Label>{workflowTypesWithLabel[workflowType]}</Label>
                                  {Object.keys(selectApproveStages[workflowType]).map(
                                    (stageId: string, s: number) => (
                                      <ApproverGroup key={s}>
                                        <ApproveStageLabel>{`${s + 1}次承認者`}</ApproveStageLabel>
                                        {selectApproveStages[workflowType][stageId].map(
                                          (approverId: number, i: number) => (
                                            <SelectApprovers
                                              key={`${s}_${i}`}
                                              rawMembers={utils.dig(departments, 'members')}
                                              members={editingDepartment.membersNotApprover(stageId)}
                                              selectedId={approverId}
                                              i={i}
                                              s={s + 1}
                                              workflowType={workflowType}
                                              onSelect={this.handleApproverChange}
                                              isDeletableApprover={this.isDeletableApprover}
                                            />
                                          )
                                        )}
                                        <ApproverButtonWrap>
                                          <ApproverButton
                                            buttonType={ButtonType.SUB}
                                            type="button"
                                            onClick={e => this.handleAddSelect(s + 1, e, workflowType)}
                                          >
                                            + 承認者を追加
                                          </ApproverButton>
                                        </ApproverButtonWrap>
                                        {(selectApproveStages[workflowType][stageId].length > 1 ||
                                          (s + 1 === Object.keys(selectApproveStages[workflowType]).length &&
                                            s > 0)) && (
                                          <ApproverButtonWrap>
                                            <ApproverButton
                                              buttonType={ButtonType.DANGER}
                                              type="button"
                                              onClick={e => this.handleRemoveSelect(s + 1, e, workflowType)}
                                            >
                                              - 承認者を削除
                                            </ApproverButton>
                                          </ApproverButtonWrap>
                                        )}
                                        {s + 1 === Object.keys(selectApproveStages[workflowType]).length && (
                                          <ApproverButtonWrap>
                                            <ApproverButton
                                              buttonType={ButtonType.SUB}
                                              type="button"
                                              onClick={e => this.handleAddApproveStage(s + 1, e, workflowType)}
                                            >
                                              + ステップ追加
                                            </ApproverButton>
                                          </ApproverButtonWrap>
                                        )}
                                      </ApproverGroup>
                                    )
                                  )}
                                </ApproverInputArea>
                              ))}
                            </>
                          )}
                        </EditSection>
                      </EditWrap>
                      {saving && (
                        <Saving>
                          <SimpleLoading />
                        </Saving>
                      )}
                      <ModalButtons>
                        <ModalButton type="submit" value="保存" />
                        <ModalButton type="reset" value="キャンセル" onClick={() => this.handleCancelClick()} />
                      </ModalButtons>
                    </Modal>
                  </form>
                )}
              </>
            ) : (
              <DepartmentSharesBlock />
            )}
          </OrganizationBody>
          {deletingDepartment && (
            <Modal show hideModal={() => this.handleDeleteAlertClose()}>
              <Confirm
                onConfirmed={() => this.handleDeleteConfirm()}
                onAborted={() => this.handleDeleteAlertClose()}
                message={`${deletingDepartment.name}(${deletingDepartment.code})を削除してよろしいですか？\n※この操作は取り消せません`}
              />
            </Modal>
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Note = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

const BulkActionArea = styled.div`
  ${BulkActionLink} {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ApproverList = styled.p``;

const EditWrap = styled.div`
  display: flex;
  gap: 8px;
`;

const EditSection = styled.div`
  flex: 1 1 auto;

  &:empty {
    display: none;
  }

  & + & {
    max-width: 50%;
  }
`;

const InputArea = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: normal;
`;

const ApproveStageLabel = styled(Label)`
  margin-top: 10px;
`;

const Input = styled.input`
  &&&:focus {
    box-shadow: none;
    border-color: ${props => props.theme.linkColor};
  }
`;

const ApproverInputArea = styled(InputArea)`
  position: relative;
  margin-bottom: 20px;
`;

const ApproverButtonWrap = styled.div`
  float: left;
  width: 90px;
  margin: 0 10px 0 auto;
  font-size: 10px;
`;

const ApproverButton = styled.button`
  &&& {
    ${ButtonBase};
    width: 90px;
    margin: 0;
    padding: 0.75em 1em;
  }
`;

const ApproverGroup = styled.div`
  width: 100%;
  overflow: auto;
`;

const Saving = styled.div``;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled.input`
  &&& {
    ${ButtonBase};
    width: 100px;
    margin: 0 0 0 20px;
    padding: 0.75em 1em;
  }
`;

const OrganizationDepartmentsRadioDiv = styled.div`
  height: 40px;
  padding-top: 10px;
`;

const OrganizationDepartmentsRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

const DepartmentEditRadioValue = styled.label`
  width: 80px;
  > span {
    padding-left: 4px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ApproverListWrap = styled.div`
  :not(:first-child) {
    margin-top: 10px;
  }
`;

export default Departments;
