export type SearchTrackingElementType = 'outward' | 'homeward' | 'hotel' | 'flights';

export const SEARCH_TRACKING_ELEMENT_LABELS: { [key in SearchTrackingElementType]: string } = {
  outward: '往路',
  homeward: '復路',
  hotel: 'ホテル',
  flights: '航空券'
};

export type SearchTrackingElementCollection = SearchTrackingElementList[];

export interface SearchTrackingElementList {
  type: SearchTrackingElementType;
  list: SearchTrackingElement[];
}

export interface SearchTrackingElementArgs {
  id: number;
  title: string;
}

class SearchTrackingElement {
  id: number;

  title: string;

  constructor(args: SearchTrackingElementArgs) {
    this.id = args.id;
    this.title = args.title;
  }
}

export default SearchTrackingElement;
