import React from 'react';

interface Props {
  label: string;
  checked: boolean;
  onChange: () => void;
  id?: string;
}

interface State {}

class CheckboxField extends React.Component<Props, State> {
  render() {
    const { label, checked, id, onChange } = this.props;
    const classBase = 'form-elements-checkbox-field';

    return (
      <label className={`${classBase}`} htmlFor={id}>
        <input
          className={`${classBase}__checkbox`}
          type="checkbox"
          checked={checked}
          onChange={_e => onChange()}
          id={id}
        />
        <span className={`${classBase}__label`}>{label}</span>
      </label>
    );
  }
}

export default CheckboxField;
