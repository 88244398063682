import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';

import type { TextareaProps } from './textarea';

type Props = Required<Pick<TextareaProps, 'size' | 'error' | 'fullWidth'>>;

export const useStyles = makeStyles<AITTheme, Props>(
  ({ palette, tokens: { colors, radiuses, typographies }, utils }) => ({
    root: ({ size, error, fullWidth }) => ({
      width: fullWidth ? '100%' : 300,
      backgroundColor: colors.common.white,
      padding: utils.getSpacing(size === 'small' ? 1 : 2),
      borderColor: error ? palette.error.main : colors.border.field,
      borderRadius: radiuses.r4,
      transition: 'border-color 0.2s',
      fontFamily: typographies.fontFamily,
      fontSize: size === 'small' ? typographies.fontSizes.caption : typographies.fontSizes.body,
      outline: 'none',

      '&::placeholder': {
        color: colors.text.placeholder
      },
      '&:disabled': {
        color: colors.text.disabled
      },
      '&:focus': {
        borderColor: error ? palette.error.main : colors.brand.primary
      },
      '&:hover:not(:focus)': {
        borderColor: error ? palette.error.main : colors.border.divider
      }
    })
  })
);
