import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';

interface Props {
  report: any;
}

const TripReportCardTemplate: React.SFC<Props> = ({ report }) => (
  <Link to={`/trip_reports/${report.id}`}>
    <Card>
      <Header>
        <Status>
          <p className={report.statusClass()}>{report.statusDescription()}</p>
        </Status>
      </Header>
      <Content>
        <Left>
          <Price>
            <span data-wovn-ignore>{utils.digits(report.totalPrice())}</span>円
          </Price>
          <Other>
            出張期間:{' '}
            <span data-wovn-ignore>{`${report.departureDate.format('YYYY-MM-DD')} 〜 ${report.returnDate.format(
              'YYYY-MM-DD'
            )}`}</span>
            <br />
            場所　　: <span data-wovn-ignore>{`${report.origin} <=> ${report.destination}`}</span>
            <br />
            報告　　: <span data-wovn-ignore>{report.result}</span>
          </Other>
        </Left>
        <Arrow>&gt;</Arrow>
      </Content>
    </Card>
  </Link>
);

const Card = styled.div`
  overflow: hidden;
  margin-bottom: 10px;
  padding: 20px;
  background: #fff;
  border: solid 1px ${props => props.theme.grayBorderColor};
  border-radius: 10px;
  color: ${props => props.theme.textColor};
  font-size: 14px;
  cursor: pointer;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

const Status = styled.div`
  margin-left: auto;
`;

const Content = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 100%;
  margin-right: 20px;
`;

const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-left: auto;
`;

const Other = styled.div`
  word-break: break-all;
`;

const Arrow = styled.div`
  margin: auto 0 auto auto;
  font-size: 20px;
  color: ${props => props.theme.accentColor};
`;

export default TripReportCardTemplate;
