import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

class UserSigninHeader extends React.Component {
  render() {
    return (
      <UserSignInHeader>
        <UserSignInInner>
          <HeaderLogo />
        </UserSignInInner>
      </UserSignInHeader>
    );
  }
}

const UserSignInHeader = styled.header`
  padding: 8px;
`;

const UserSignInInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
`;

const HeaderLogo = styled.h1`
  background-image: url('${props => props.theme.signInHeaderLogoPath}');
  background-repeat: no-repeat;
  background-size: contain;
  width: 159px;
  height: 30px;
`;

export default UserSigninHeader;
