import React from 'react';
import { styled } from '@this/constants/themes';
import type TripRuleError from '@this/domain/trip/trip_rule_error';

interface Props {
  tripRuleError: TripRuleError;
  showAlert: boolean;
}

const TripRuleErrorSection: React.FC<Props> = ({ tripRuleError, showAlert }) => {
  return (
    <Section>
      <p>■{tripRuleError.ruleErrorText()}</p>
      {showAlert && tripRuleError.detail.lines?.length && (
        <LineSection>
          {tripRuleError.detail.lines.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </LineSection>
      )}
    </Section>
  );
};

export const Section = styled.div`
  color: #3a3a3a;
  font-weight: 400;
  font-size: 11px;
`;

export const LineSection = styled.div`
  margin-bottom: 10px;
`;

export default TripRuleErrorSection;
