export const typographies = {
  fontFamily: 'Noto Sans JP,sans-serif',

  fontSizes: {
    23: 23,
    20: 20,
    17: 17,
    13: 13,
    11: 11
  },

  lineHeights: {
    1.2: 1.2,
    1.5: 1.5
  }
};

export type Typographies = typeof typographies;
