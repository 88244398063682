import React from 'react';
import _ from 'lodash';

interface Props {
  size?: number;
  style?: React.CSSProperties;
}

/**
 * @deprecated
 *
 * please use Loading
 * js/src/components/shared/ui/feedbacks/loading/loading.tsx
 */
const SimpleLoading = ({ size, style }: Props) => (
  <div className="vertical-centered-box" style={style}>
    <img className="simple-loading" src="/images/loading.gif" width={size || 50} height={size || 50} />
  </div>
);

export default SimpleLoading;
