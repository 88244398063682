import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { ByMedia } from '@this/shared/ui/other/by_media';

interface Props {
  title: string;
  breadcrumbs?: { [key: string]: string }[];
  utils?: React.ReactNode;
  noBackground?: boolean;
  noBackgroundSp?: boolean;
  children: React.ReactNode;
  backLinkPath?: string;
}

const Main = (props: Props) => (
  <div className="expenses-content-main">
    <ContentMainHeader className="expenses-content-main__header">
      <div>
        <ContentMainTitle>{props.title}</ContentMainTitle>
        {props.breadcrumbs ? (
          <ul className="expenses-content-main__breadcrumb">
            <li>
              <Link to="/biztra/report_items">home</Link>
            </li>
            {props.breadcrumbs.map((breadcrumb, i: number) => (
              <li key={i}>
                {breadcrumb.link ? <Link to={breadcrumb.link}>{breadcrumb.text}</Link> : breadcrumb.text}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      {props.utils}
    </ContentMainHeader>
    {props.backLinkPath && (
      <BackLinkWrapper>
        <BackLink to={props.backLinkPath}>&lt; 一覧に戻る</BackLink>
      </BackLinkWrapper>
    )}
    <ByMedia>
      {matches =>
        matches.sm ? (
          <ContentMainBody noBackground={props.noBackgroundSp} className="expenses-content-main__body">
            {props.children}
          </ContentMainBody>
        ) : (
          <ContentMainBody noBackground={props.noBackground} className="expenses-content-main__body">
            {props.children}
          </ContentMainBody>
        )
      }
    </ByMedia>
  </div>
);

const mainTitleColor = `#927230`;

const primaryBtnColor = '#1d7c2d';

const ContentMainHeader = styled.div`
  @media screen and (max-width: 767px) {
    margin: 24px 30px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
    padding: 0;
    height: unset;
  }
`;

const ContentMainTitle = styled.h1`
  color: ${mainTitleColor};
  font-size: 1.6em;
  font-weight: bold;
  margin: 0;
`;

const BackLinkWrapper = styled.div`
  max-width: 1330px;
  margin: 0 auto 24px;
  @media screen and (max-width: 767px) {
    margin-left: 20px;
    width: calc(100vw - 20px);
  }
`;

export const BackLink = styled(Link)`
  display: inline-block;
  color: ${primaryBtnColor};
  font-size: 14px;
`;

const ContentMainBody = styled.div<{ noBackground?: boolean }>`
  background: ${props => props.noBackground && 'transparent'};
  padding: ${props => (props.noBackground ? '0' : 'calc(32 / 1440 * 100%)')};
  border-radius: ${props => !props.noBackground && '4px'};
  box-shadow: ${props => !props.noBackground && '0 0 12px 0 rgba(0, 0, 0, 0.05)'};
  @media screen and (max-width: 767px) {
    width: ${props => !props.noBackground && 'calc(100vw - 30px)'};
    padding: ${props => (props.noBackground ? '0' : '20px')};
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

export default Main;
