import type { ChangeEvent } from 'react';
import React from 'react';
import type { FormControlLabelProps as MuiFormControlLabelProps } from '@material-ui/core';
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel, makeStyles } from '@material-ui/core';
import type { AITTheme } from '@this/shared/ui/theme';

const useCheckboxGroupStyle = makeStyles<AITTheme, { layout: CheckboxGroupProps['layout'] }>(({ utils }) => {
  return {
    root: ({ layout }) =>
      layout === 'horizontal'
        ? {
            display: 'flex',
            flexDirection: 'row',
            '& .MuiFormControlLabel-root:not(:first-child)': { marginLeft: utils.getSpacing(2) }
          }
        : {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiFormControlLabel-root:not(:first-child)': { marginTop: utils.getSpacing(2) }
          }
  };
});

export type CheckboxGroupProps = {
  className?: string;
  layout?: 'vertical' | 'horizontal';
  children: React.ReactNode;
};
export const CheckboxGroup = ({ layout = 'vertical', className, children }: CheckboxGroupProps) => {
  const styles = useCheckboxGroupStyle({ layout });
  return <div className={`${className} ${styles.root}`}>{children}</div>;
};

const useCheckboxStyle = makeStyles<AITTheme>(({ utils }) => {
  return {
    checkboxRoot: { padding: 0, marginRight: utils.getSpacing(1) },
    labelRoot: { marginLeft: 0, marginRight: 0 }
  };
});
export type CheckboxProps = {
  children?: React.ReactNode;
  indeterminate?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<MuiFormControlLabelProps, 'control' | 'label' | 'onChange'>;
export const Checkbox = ({
  className,
  children,
  checked,
  indeterminate,
  name,
  onChange,
  ...props
}: CheckboxProps) => {
  const styles = useCheckboxStyle();
  return (
    <MuiFormControlLabel
      className={`${className} ${styles.labelRoot}`}
      control={
        <MuiCheckbox
          className={styles.checkboxRoot}
          name={name}
          color="primary"
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          disableRipple
        />
      }
      label={children}
      {...props}
    />
  );
};
