import React from 'react';
import { styled } from '../../constants/themes';

interface Props {
  nationalities: { id: number; name: string }[];
  onSelect: (id: number) => void;
  selectedNationalities: { id: number; name: string }[];
}

interface State {
  filteredNationalities: { id: number; name: string }[];
}

class NationalityList extends React.Component<Props, State> {
  private searchTextInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      filteredNationalities: this.props.nationalities
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearchNationalities = this.handleSearchNationalities.bind(this);
    this.searchTextInput = React.createRef();
  }

  componentDidMount() {
    if (this.searchTextInput.current) {
      this.searchTextInput.current.focus();
    }
  }

  handleSelect(id: number) {
    this.props.onSelect(id);
  }

  handleSearchNationalities(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value;
    const filtered = this.props.nationalities.filter(nationality => nationality.name.indexOf(keyword) >= 0);
    this.setState({ filteredNationalities: filtered });
  }

  render() {
    try {
      return (
        <SelectNationalityWrapper>
          <NationalitySelectList>
            <NationalityListInput
              type="search"
              defaultValue=""
              placeholder="国名で検索"
              onChange={this.handleSearchNationalities}
              ref={this.searchTextInput}
            />
            <NationarityUl>
              {this.state.filteredNationalities.map((nationality, i) => (
                <Item key={`nationality_${i}`} onClick={() => this.handleSelect(nationality.id)}>
                  <ItemInfo>
                    <ItemName>{nationality.name}</ItemName>
                  </ItemInfo>
                </Item>
              ))}
            </NationarityUl>
          </NationalitySelectList>
        </SelectNationalityWrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const SelectNationalityWrapper = styled.div`
  font-size: 13px;
  margin: 0;
  min-width: 263px;
  background-color: #ffffff;
  position: absolute;
`;

const NationalitySelectList = styled.div`
  margin: 0;
  background-color: #ffffff;
  width: 263px;
`;

const NationarityUl = styled.ul`
  margin: 0;
  background-color: #ffffff;
  width: 263px;
  height: 300px;
  position: absolute;
  overflow: scroll;
  border: 1px solid #eee;
  z-index: 100;
`;

const NationalityListInput = styled.input`
  margin-bottom: 0 !important;
`;

const Item = styled.li`
  display: inline-block;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  flex-wrap: wrap;
  width: 263px;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const ItemInfo = styled.div`
  display: inline-block;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 12px;
`;

const ItemName = styled.p`
  margin-right: 10px;
  display: inline-block;
`;

export default NationalityList;
