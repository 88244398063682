import React from 'react';
import { observer } from 'mobx-react';

import type SearchTracking from '@this/src/domain/search_tracking';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';

import { SearchTrackingDetailSection } from './share/SearchTrackingDetailSection';

interface Props {
  searchTracking: SearchTracking;
}

const SearchTrackingDetailSettings: React.FC<Props> = observer(({ searchTracking }) => {
  return (
    <SearchTrackingDetailSection title="顧客設定">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell>内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchTracking.settings.map(({ key, value }) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SearchTrackingDetailSection>
  );
});

export default SearchTrackingDetailSettings;
