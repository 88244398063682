import React from 'react';
import _ from 'lodash';

import type MessageTemplateList from '../../../../domain/message_template_list';
import type MessageTemplate from '../../../../domain/message_template';

interface Props {
  key: number;
  messageTemplates: MessageTemplateList;
  messageTemplate: MessageTemplate;
  editingMessageTemplate: MessageTemplate;
  handleCancelClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleEditClick: (messageTemplate: MessageTemplate, e: React.MouseEvent<HTMLElement>) => void;
  handleDeleteClick: (messageTemplate: MessageTemplate, e: React.MouseEvent<HTMLElement>) => void;
  handleFieldChange: (
    name: keyof MessageTemplateObj,
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>
  ) => void;
  contentFormat: (content: string | undefined) => (JSX.Element | '')[] | '';
}

interface MessageTemplateObj {
  setTitle: string;
  setContent: string;
}

class MessageTemplateBlock extends React.Component<Props> {
  render() {
    const template = this.props.messageTemplate;
    const editingMessageTemplate = this.props.editingMessageTemplate;
    return (
      <tbody>
        {editingMessageTemplate && template.id === editingMessageTemplate.id ? (
          <tr>
            <td />
            <td>
              <input
                type="text"
                value={editingMessageTemplate.title}
                onChange={e => this.props.handleFieldChange('setTitle', e)}
              />
            </td>
            <td>
              <textarea
                className="message-templates__content"
                value={editingMessageTemplate.content}
                onChange={e => this.props.handleFieldChange('setContent', e)}
              />
            </td>
            <td className="organization__td">
              <input type="submit" value="保存" />
            </td>
            <td className="organization__td">
              <input type="submit" value="キャンセル" onClick={e => this.props.handleCancelClick(e)} />
            </td>
          </tr>
        ) : template.isDeleted || editingMessageTemplate.id ? (
          <tr>
            <td>{template.id}</td>
            <td>{template.title}</td>
            <td>{this.props.contentFormat(template.content)}</td>
            <td className="organization__td" />
            <td className="organization__td" />
          </tr>
        ) : (
          <tr>
            <td>{template.id}</td>
            <td>{template.title}</td>
            <td>{this.props.contentFormat(template.content)}</td>
            <td className="organization__td">
              <input type="submit" value="編集" onClick={e => this.props.handleEditClick(template, e)} />
            </td>
            <td className="organization__td">
              <input type="submit" value="削除" onClick={e => this.props.handleDeleteClick(template, e)} />
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}

export default MessageTemplateBlock;
