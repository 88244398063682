import { observable } from 'mobx';

export type TokenCountMethod = 'embedding' | 'length';

export interface ChatbotSettingArgs {
  id?: number;
  service_id: number;
  model: string;
  token_count_method: TokenCountMethod;
  using_16k_model: boolean;
  knowledge_threshold: number;
  knowledge_unknown_message: string;
}

export class ChatbotSetting {
  @observable id: number | null;

  @observable serviceId: number;

  @observable model: string;

  @observable tokenCountMethod: TokenCountMethod;

  @observable using16kModel: boolean;

  @observable knowledgeThreshold: number;

  @observable knowledgeUnknownMessage: string;

  constructor(args: ChatbotSettingArgs) {
    this.id = args.id || null;
    this.serviceId = args.service_id;
    this.model = args.model ?? '';
    this.tokenCountMethod = args.token_count_method;
    this.using16kModel = args.using_16k_model;
    this.knowledgeThreshold = args.knowledge_threshold;
    this.knowledgeUnknownMessage = args.knowledge_unknown_message;
  }

  submitParams(): ChatbotSettingArgs {
    return {
      id: this.id || undefined,
      service_id: this.serviceId,
      model: this.model,
      token_count_method: this.tokenCountMethod,
      using_16k_model: this.using16kModel,
      knowledge_threshold: this.knowledgeThreshold,
      knowledge_unknown_message: this.knowledgeUnknownMessage
    };
  }
}
