import { Box } from '@material-ui/core';
import { styled } from '@this/constants/themes';

export const SearchArea = styled(Box)`
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

export const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;
