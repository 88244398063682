import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import type { RouteComponentProps } from 'react-router-dom';

interface SearchQueryItem {
  destination: string;
  destination_address: string;
}

interface SearchResult {
  hotel_master_id: number;
  hotel_master_name: string;
  rakuten_price: number;
  expedia_price: number;
  agoda_price: number;
}

interface ApiResponse {
  searchQueryItem: SearchQueryItem;
  results: SearchResult[];
}

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  color: #333;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }
`;

type Props = RouteComponentProps<{ id: string }>;

const SearchQueryDetail: React.FC<Props> = ({ match: { params } }) => {
  const [data, setData] = useState<ApiResponse | null>(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get<ApiResponse>(`/god/search_query_items/${params.id}`);
        setData(response.data);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    }

    fetchData();
  }, [params.id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Title>検索クエリ詳細</Title>
      <p>目的地: {data.searchQueryItem.destination}</p>
      <p>住所: {data.searchQueryItem.destination_address}</p>

      <Title>ホテル一覧</Title>
      <Table>
        <thead>
          <tr>
            <th>ホテルID</th>
            <th>ホテル名</th>
            <th>楽天価格</th>
            <th>Expedia価格</th>
            <th>Agoda価格</th>
          </tr>
        </thead>
        <tbody>
          {data.results.map(result => (
            <tr key={result.hotel_master_id}>
              <td>{result.hotel_master_id}</td>
              <td>{result.hotel_master_name}</td>
              <td>{result.rakuten_price}</td>
              <td>{result.expedia_price}</td>
              <td>{result.agoda_price}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SearchQueryDetail;
