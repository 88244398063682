export interface SearchPnrFlightArgs {
  id: number;
  order_id: number;
  trip_id: number;
  trace_id: number;
  pnr_id: string;
}

class SearchPnrFlight {
  id: number;

  orderId: number | null;

  pnrId: string | null;

  tripId: number | null;

  traceId: number | null;

  constructor(args: SearchPnrFlightArgs) {
    this.id = args.id;
    this.traceId = args.trace_id;
    this.pnrId = args.pnr_id;
    this.orderId = args.order_id;
    this.tripId = args.trip_id;
  }
}

export default SearchPnrFlight;
