import React from 'react';
import styled from 'styled-components';
import { SubmitButton } from '@this/shared/atoms/button';
import { Link } from '@this/shared/ui/navigations/link';
import { Text } from '@this/shared/ui/data_displays/typography';

interface Props {
  onShowBulkImportSmartHr: () => void;
  importSmartHrMessage: string | null;
  isSmartHrError: boolean;
  disabled: boolean;
}

const MemberImportSmartHr: React.FC<Props> = ({
  onShowBulkImportSmartHr,
  importSmartHrMessage,
  isSmartHrError,
  disabled
}) => {
  return (
    <Wrap>
      <ImportSmartHrButton
        type="submit"
        value="SmartHR連携"
        onClick={() => onShowBulkImportSmartHr()}
        disabled={disabled}
      />
      {importSmartHrMessage && (
        <Text color={isSmartHrError ? 'danger' : 'description'}>{importSmartHrMessage}</Text>
      )}
      <Link style={{ fontWeight: 'bold' }} href="/organization/member/smart_hr/import_actions">
        実行履歴
      </Link>
    </Wrap>
  );
};

const ImportSmartHrButton = styled(SubmitButton)`
  &&& {
    width: 110px;
    margin-bottom: 2px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
`;

export default MemberImportSmartHr;
