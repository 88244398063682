import React from 'react';
import { reportError } from '@this/lib/bugsnag';

interface Props {}

interface State {}

class NotFound extends React.Component<Props, State> {
  render() {
    try {
      return <div>ページがありません</div>;
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}
export default NotFound;
