import React from 'react';
import Tooltip from '@this/shared/tooltip/tooltip';

const PurchaseLeadtimeTooltip: React.FC = () => {
  return (
    <Tooltip width={300}>
      <p>間際予約：直前1週間前(7日未満)</p>
      <p>通常予約：1〜4週間前(7日以上、30日未満)</p>
      <p>早期予約：1〜3ヶ月前(30日以上、90日未満)</p>
      <p>計画予約：3ヶ月以上前(90日以上)</p>
    </Tooltip>
  );
};
export default PurchaseLeadtimeTooltip;
