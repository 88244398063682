import React from 'react';
import styled from 'styled-components';

export type TextTruncateProps = {
  className?: string;
  children: React.ReactNode;
  lines?: number;
};

export const TextTruncate = React.forwardRef<HTMLDivElement, TextTruncateProps>(
  ({ className, children, lines = 1 }, ref) => {
    return (
      <Root className={className} ref={ref} $lines={lines}>
        {children}
      </Root>
    );
  }
);

const Root = styled.div<{ $lines: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ $lines }) => $lines};
`;
