import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { FormControl } from '@this/shared/ui/inputs/form_control';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Input } from '@this/components/shared/ui/inputs/input';
import { Fetcher, HTTPError } from '@this/src/util';

interface Props {
  open: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  id?: number | null;
  fileName?: string | null;
  modalType: string;
}

const ManualModal = observer(({ open, onClose, afterSubmit, id, fileName, modalType }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const type = modalType === 'add' ? '登録' : modalType === 'mod' ? '更新' : '削除';

  useEffect(() => {
    setName(fileName || '');
  }, [id, fileName]);

  const handleSubmit = useCallback(async () => {
    const uri = id ? `/admin/manuals/${id}` : '/admin/manuals';
    const method = id ? 'PUT' : 'POST';
    const formData = new FormData();
    formData.append('file', file || '');
    formData.append('name', name);
    setIsLoading(true);

    try {
      await Fetcher.upload(uri, formData, { method });
      onClose();
      afterSubmit();
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.errors) {
        setErrors(e.response?.data?.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }, [id, name, file, onClose, afterSubmit]);

  const handleDelete = useCallback(async () => {
    setErrors([]);
    if (id == null) {
      return;
    }
    setIsLoading(true);

    try {
      await Fetcher.delete(`/admin/manuals/${id}`);
      onClose();
      afterSubmit();
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.errors) {
        setErrors(e.response?.data?.errors);
      }
    } finally {
      setIsLoading(false);
    }
  }, [onClose, afterSubmit]);

  const onFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setErrors([]);
      const file = e.target.files ? e.target.files[0] : null;
      if (!file) return;
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      const acceptExtension = ['pdf'];
      if (fileExtension && !acceptExtension.includes(fileExtension)) {
        setErrors(['ファイルはpdfで登録してください。']);
        e.target.value = '';
        return;
      }
      setFile(file);
    },
    [file]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={modalType === 'delete' ? 'e2e-confirm-delete' : 'e2e-modal-edit'}
      size="medium"
    >
      <ModalHeader>マニュアルを{type}する</ModalHeader>
      <ModalBody style={{ margin: '10px' }}>
        {modalType === 'delete' ? (
          <Text>
            {name}を削除してよろしいですか？
            <br />
            ※この操作は取り消せません
          </Text>
        ) : (
          <>
            <Box margin="10px 0" className="e2e-name">
              <Input
                type="text"
                placeholder="ファイル名"
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Box>
            <Box className="e2e-file">
              <input type="file" accept="application/pdf" onChange={e => onFileInputChange(e)} />
            </Box>
          </>
        )}

        {errors.length > 0 && (
          <FormControl fullWidth margin="dense">
            {errors.map((error, i) => (
              <Box key={i} color="red">
                {error}
              </Box>
            ))}
          </FormControl>
        )}
      </ModalBody>
      <ModalFooter>
        {isLoading && <SimpleLoading />}
        {modalType === 'delete' ? (
          <Button onClick={handleDelete} disabled={isLoading}>
            はい
          </Button>
        ) : (
          <Button onClick={handleSubmit} disabled={isLoading}>
            {type}
          </Button>
        )}
        <Button onClick={onClose} disabled={isLoading}>
          {modalType === 'delete' ? 'いいえ' : '取り消し'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

const Text = styled.span`
  font-size: 14px;
  color: ${props => props.theme.textColor};
`;

export default ManualModal;
