import moment from '@this/src/lib/moment';
import type { Moment } from '@this/src/lib/moment';

export interface ChatbotUserSettingResponseArgs {
  id?: number;
  user_id: number;
  default_origin?: string;
  created_at: string | Moment;
  updated_at: string | Moment;
}

export interface ChatbotUserSettingArgs {
  id?: number;
  userId: number;
  defaultOrigin: string;
  createdAt: Moment;
  updatedAt: Moment;
}

export const convertChatbotUserSettingResponseToArgs = (response: ChatbotUserSettingResponseArgs) => {
  const args: ChatbotUserSettingArgs = {
    id: response.id,
    userId: response.user_id,
    defaultOrigin: response.default_origin || '',
    createdAt: moment(response.created_at),
    updatedAt: moment(response.updated_at)
  };
  return args;
};

export default class ChatbotUserSetting {
  id: number | null;

  userId: number;

  defaultOrigin: string;

  createdAt: Moment;

  updatedAt: Moment;

  error: string | null = null;

  constructor(args: ChatbotUserSettingArgs) {
    this.id = args.id || null;
    this.userId = args.userId;
    this.defaultOrigin = args.defaultOrigin;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
  }

  setDefaultOrigin(defaultOrigin: string) {
    this.defaultOrigin = defaultOrigin;
    app.render();
  }

  setError(error: string | null) {
    this.error = error;
    app.render();
  }
}

const initialState: ChatbotUserSettingArgs = {
  userId: 0,
  defaultOrigin: '',
  createdAt: moment(),
  updatedAt: moment()
};

export const emptyChatbotUserSetting = () => new ChatbotUserSetting(initialState);
