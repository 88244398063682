import React from 'react';
import { styled } from '@this/constants/themes';
import moment from 'moment';

import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import { MessageType } from '@this/components/trips_management/trips/trips_chat/types';
import TripChatMessageBody from './trip_chat_message_body';

interface Props {
  message: Message;
  onSubmitConfirmation: () => void;
}

const TripChatMessage: React.FC<Props> = ({ message, onSubmitConfirmation }) => {
  return (
    <Container>
      {message.type === MessageType.USER && message.partner_read && <ReadMark>[既読]</ReadMark>}
      {message.type === MessageType.PARTNER && (
        <MessageSenderContainer>
          <PartnerAvatarImage />
          <MessageSenderContent>コンシェルジュデスク</MessageSenderContent>
          <MessageSenderContent>
            <CreatedAt date={message.created_at} />
          </MessageSenderContent>
        </MessageSenderContainer>
      )}
      {message.type === MessageType.USER && (
        <MessageSenderContainer>
          <UserAvatarImage />
          <MessageSenderContent data-wovn-ignore>{message.user_name}</MessageSenderContent>
          <MessageSenderContent>
            <CreatedAt date={message.created_at} />
          </MessageSenderContent>
        </MessageSenderContainer>
      )}
      <MessageBodyContainer>
        <TripChatMessageBody message={message} onSubmitConfirmation={onSubmitConfirmation} />
      </MessageBodyContainer>
    </Container>
  );
};

const CreatedAt: React.FC<{ date: string }> = ({ date }) => {
  const momentDate = moment(date);

  return (
    <span data-wovn-ignore>
      （{momentDate.format('MM/DD')} {momentDate.format('HH:mm')}）
    </span>
  );
};

const Container = styled.div`
  position: relative;
`;

const MessageSenderContainer = styled.div`
  display: flex;
  color: #c9c9c9;
  font-size: 13px;
  letter-spacing: 1px;
`;

const MessageSenderContent = styled.div`
  margin-top: 10px;
`;

const MessageBodyContainer = styled.div`
  font-size: 12px;
`;

const AvatarImage = styled.div`
  margin-bottom: 6px;
  margin-right: 6px;
  width: 30px;
  height: 30px;
`;

const PartnerAvatarImage = styled(AvatarImage)`
  background: url('/images/partner_icon.png') center center / 30px auto no-repeat;
`;

const UserAvatarImage = styled(AvatarImage)`
  background: url('/images/user_icon.png') center center / 30px auto no-repeat;
`;

const ReadMark = styled.span`
  position: absolute;
  right: 0px;
  top: 12px;
  font-size: 10px;
`;

export default TripChatMessage;
