import React, { useState } from 'react';
import A from '@this/components/shared/atoms/a';

import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle,
  Flex,
  ShareDetailLinkArea,
  ShareDetailArea,
  ShareDetailFlexArea,
  ShareDetailIndentedArea,
  ShareDetailLabel,
  ShareDetailSelectArea
} from '@this/components/organization/trips/detail/trip_detail.style';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import type Department from '@this/domain/department/department';
import type Trip from '@this/domain/trip/trip';
import type ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import type OrderItem from '@this/domain/order_item';
import OrderItemMappingList from '@this/domain/order_item_mapping_list';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import type NonOrderItem from '@this/domain/non_order_item';
import NonOrderItemMappingList from '@this/domain/non_order_item_mapping_list';
import type NonOrderItemMapping from '@this/domain/non_order_item_mapping';

interface Props {
  departments: Department[];
  chargingDepartmentShares: ChargingDepartmentShareList;
  projectShareAvailability: boolean;
  orderItems: OrderItem[];
  nonOrderItems?: NonOrderItem[];
  selectedDepartmentId: ReturnType<Trip['departmentId']>;
  showDepartmentDetail: boolean;
  setShowDepartmentDetail: (status: boolean) => void;
  onSave: (
    departmentId: ReturnType<Trip['departmentId']>,
    orderItemMapping: OrderItemMappingList,
    editingNonOrderItemMappings: any
  ) => void;
  onSaveDetail: (orderItemMapping: OrderItemMappingList, nonOrderItemMappings: NonOrderItemMappingList) => void;
}

const TripDetailDepartmentForm: React.FC<Props> = ({
  departments,
  chargingDepartmentShares,
  projectShareAvailability,
  orderItems,
  nonOrderItems,
  selectedDepartmentId,
  showDepartmentDetail,
  setShowDepartmentDetail,
  onSave,
  onSaveDetail
}) => {
  const [selectedId, setSelectedId] = useState(selectedDepartmentId);
  const [departmentDetailChanged, setDepartmentDetailChanged] = useState(false);

  const prepareOrderItemMappingFromTrip = () => {
    const mappings: OrderItemMappingList = new OrderItemMappingList([]);
    orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        const newMapping = mapping.clone();
        if (newMapping.chargingDepartmentId) {
          const department = departments.find(department => department.id === newMapping.chargingDepartmentId);
          newMapping.setSelectedOptionLabel(department?.descriptionWithCode() || '');
        } else if (newMapping.chargingDepartmentShareId) {
          const chargingDepartmentShare = chargingDepartmentShares.find(newMapping.chargingDepartmentShareId);
          newMapping.setSelectedOptionLabel(chargingDepartmentShare?.codeAndNameOneLine() || '');
        }
        mappings.push(newMapping);
      });
    });

    return mappings;
  };

  const prepareNonOrderItemMappingFromTrip = () => {
    const mappings: NonOrderItemMappingList = new NonOrderItemMappingList([]);
    nonOrderItems?.forEach(item => {
      item.nonOrderItemMappings.forEach(mapping => {
        const newMapping = mapping.clone();
        if (newMapping.chargingDepartmentId) {
          const department = departments.find(department => department.id === newMapping.chargingDepartmentId);
          newMapping.setSelectedOptionLabel(department?.descriptionWithCode() || '');
        } else if (newMapping.chargingDepartmentShareId) {
          const chargingDepartmentShare = chargingDepartmentShares.find(newMapping.chargingDepartmentShareId);
          newMapping.setSelectedOptionLabel(chargingDepartmentShare?.codeAndNameOneLine() || '');
        }
        mappings.push(newMapping);
      });
    });

    return mappings;
  };

  const [orderItemMappings, setOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [editingOrderItemMappings, setEditingOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [nonOrderItemMappings, setNonOrderItemMappings] = useState(prepareNonOrderItemMappingFromTrip());
  const [editingNonOrderItemMappings, setEditingNonOrderItemMappings] = useState(
    prepareNonOrderItemMappingFromTrip()
  );

  const onSelectDepartmentDetail = (
    changingMapping: OrderItemMapping | NonOrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    const values = option?.value.split('-'); // e.g. department-1
    if (!values) {
      changingMapping.setChargingDepartmentId(null);
      changingMapping.setSelectedOptionLabel('');
    }
    if (values && values?.length === 2) {
      if (values[0] === 'department') {
        changingMapping.setChargingDepartmentId(Number(values[1]));
        changingMapping.setSelectedOptionLabel(option?.label || '');
      } else if (values[0] === 'share') {
        changingMapping.setChargingDepartmentShareId(Number(values[1]));
        if (option?.labelLines) {
          changingMapping.setSelectedOptionLabel(option.labelLines[0] || '');
        }
      }
    }
    setDepartmentDetailChanged(true);
  };

  const createOption = (department: Department) => ({
    label: `${department.code}: ${department.name}`,
    value: department.id.toString(),
    matcher: `${department.code}${department.name}`
  });
  const onSelect = (selected: FilterableSelectorOption | null): void => {
    const values = selected?.value.split('-'); // e.g. department-1
    if (values && values?.length === 2 && values[0] === 'department') {
      setSelectedId(parseInt(values[1], 10));
    } else {
      setSelectedId(null);
    }
    editingOrderItemMappings.list.forEach(mapping => {
      onSelectDepartmentDetail(mapping, selected);
    });
    editingNonOrderItemMappings.list.forEach(mapping => {
      onSelectDepartmentDetail(mapping, selected);
    });
  };

  const selectedDepartment = departments.find(d => d.id === selectedId);
  const selectOption = selectedDepartment ? createOption(selectedDepartment) : null;

  const onShowDepartmentDetailClick = (value: boolean) => {
    setShowDepartmentDetail(value);
  };

  const departmentDetailOption = (): any[] => {
    const departmentsOption: any[] = departments.map(d => {
      return {
        label: d.descriptionWithCode(),
        value: `department-${d.id.toString()}`,
        matcher: d.descriptionWithCode()
      };
    });
    const sharesOption: any[] = chargingDepartmentShares.list.map(s => {
      return {
        labelLines: s.codeAndNameLines(),
        value: `share-${s.id.toString()}`,
        matcher: s.codeAndNameOneLine()
      };
    });
    return departmentsOption.concat(sharesOption);
  };

  const onClickSaveDetail = (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    onSaveDetail(orderItemMappings, nonOrderItemMappings);
    setOrderItemMappings(editingOrderItemMappings.clone());
    setNonOrderItemMappings(editingNonOrderItemMappings.clone());
    setDepartmentDetailChanged(false);
  };

  const onCancelDetail = () => {
    setDepartmentDetailChanged(false);
    setEditingOrderItemMappings(orderItemMappings.clone());
    setEditingNonOrderItemMappings(nonOrderItemMappings.clone());
  };

  return (
    <MetaSection>
      <Flex>
        <MetaSectionTitle>費用負担部署</MetaSectionTitle>
        {projectShareAvailability &&
          (showDepartmentDetail ? (
            <ShareDetailLinkArea>
              <A onClick={() => onShowDepartmentDetailClick(false)}>通常設定へ</A>
            </ShareDetailLinkArea>
          ) : (
            <ShareDetailLinkArea>
              <A onClick={() => onShowDepartmentDetailClick(true)}>詳細設定へ</A>
            </ShareDetailLinkArea>
          ))}
      </Flex>
      {showDepartmentDetail ? (
        <>
          <ShareDetailArea>
            {orderItems.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.categoryStr()}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="部署を検索"
                          options={departmentDetailOption()}
                          selected={{
                            label:
                              editingOrderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                ?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectDepartmentDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
            {nonOrderItems?.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.elementTypeForOrganizationTrip === 'hotel' ? 'ホテル' : '経路'}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="部署を検索"
                          options={departmentDetailOption()}
                          selected={{
                            label:
                              editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                                traveler.id,
                                item.id
                              )?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectDepartmentDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
          </ShareDetailArea>
          {departmentDetailChanged && (
            <MetaSectionFormActions>
              <MetaSectionFormButton
                onClick={() => onClickSaveDetail(editingOrderItemMappings, editingNonOrderItemMappings)}
              >
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => onCancelDetail()}>キャンセル</MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      ) : (
        <>
          <FilterableSelector
            placeholder="部署を検索"
            options={departmentDetailOption()}
            selected={selectOption}
            onSelect={onSelect}
          />
          {selectedDepartmentId !== selectedId && (
            <MetaSectionFormActions>
              <MetaSectionFormButton
                onClick={() => onSave(selectedId, editingOrderItemMappings, editingNonOrderItemMappings)}
              >
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => setSelectedId(selectedDepartmentId)}>
                キャンセル
              </MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      )}
    </MetaSection>
  );
};

export default TripDetailDepartmentForm;
