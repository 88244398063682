const objectToQueryString = (obj: Record<string, any>): string => {
  const queryString = Object.entries(obj)
    .flatMap(([key, value]: [string, any]) => {
      const encodedKey = encodeURIComponent(key);

      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(value)
          .map(([nestedKey, nestedValue]: [string, any]) => {
            const nestedEncodedKey = `${encodedKey}[${encodeURIComponent(nestedKey)}]`;
            return `${nestedEncodedKey}=${encodeURIComponent(nestedValue)}`;
          })
          .join('&');
      }

      if (Array.isArray(value)) {
        return value.flatMap((item: any, index: number) =>
          Object.entries(item)
            .map(([nestedKey, nestedValue]: [string, any]) => {
              const nestedEncodedKey = `${encodedKey}[${index}][${encodeURIComponent(nestedKey)}]`;
              return `${nestedEncodedKey}=${encodeURIComponent(nestedValue)}`;
            })
            .join('&')
        );
      }

      return `${encodedKey}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return queryString;
};

export default objectToQueryString;
