import type { FunctionComponent } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  show: boolean;
  hideModal: () => void;
  type?: 'pc' | 'sp';
  className?: string;
  title?: string;
  withPadding?: boolean;
  maxWidth?: number;
}

/**
 * @deprecated
 * should use js/src/components/shared/ui/feedbacks/modal
 */
const Modal: FunctionComponent<Props> = ({
  show,
  hideModal,
  type,
  className,
  title,
  children,
  withPadding,
  maxWidth
}) => {
  try {
    const addPadding = withPadding !== false;
    const modalBox = type === 'pc' ? 'modal-pc__box' : 'modal__box';
    const modalBody = type === 'pc' ? 'modal-pc__body' : 'modal__body';
    const modalBoxClass = className ? `${modalBox} ${className}` : modalBox;
    const top = document.body.scrollTop + window.innerHeight / 2;
    return (
      <>
        {show && (
          <div className="modal__wrapper" onClick={() => hideModal()}>
            <div className="modal__wrapper-in">
              <div className={modalBoxClass} onClick={e => e.stopPropagation()} style={{ top, maxWidth }}>
                {title && (
                  <div className="modal__header">
                    <div className="modal__header-title">{title}</div>
                    <Close className="modal__close-button" onClick={() => hideModal()} />
                  </div>
                )}
                {addPadding ? (
                  <div className={modalBody}>{children}</div>
                ) : (
                  <div className="modal__body modal__body-without-padding">{children}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } catch (e) {
    utils.sendErrorObject(e);
    return null;
  }
};

const Close = styled.div`{
  position: absolute;
  right: 10px;
  top: 12px;
  width: 24px;
  height: 24px;
  margin-left: auto;
  background: url(${props => props.theme.closeIconPath}) 0 0 / 24px no-repeat;
  cursor: pointer;
`;

export default Modal;
