import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import type Trip from '@this/domain/trip/trip';
import { styled } from '@this/src/components/constants/themes';
import { getSpacing } from '@this/src/components/shared/ui/theme';

type TripUserInfo = {
  name: string;
  name_roman: string;
  birthday: string;
  age: number | null;
  gender: string;
  tel: string;
  email: string;
  domestic_approval_required: boolean;
  foreign_approval_required: boolean;
  department: { name: string };
  grade: { name: string };
};

interface Props {
  trip: Trip;
  planName: string;
}
export const PartnerDescription: React.FC<Props> = ({ trip, planName }) => {
  const hasForbiddenRepository = useCallback((repos, category, repository): boolean => {
    let flg = false;
    if (repos) {
      repos.forEach((r: any) => {
        if (r.category === category && r.repository === repository) {
          flg = true;
        }
      });
    }
    return flg;
  }, []);

  // 1つ目のorder_itemの１つ目のtraveler_informationにuserがあれば、それが出張代表者
  // なければログインユーザが出張代表者
  const travelerInfo = useMemo(() => {
    if (trip.currentOrder.order_items[0] === undefined) return null;
    if (trip.currentOrder.order_items[0].traveler_informations === undefined) return null;
    if (trip.currentOrder.order_items[0].traveler_informations[0] === undefined) return null;
    return trip.currentOrder.order_items[0].traveler_informations[0].user;
  }, [trip]);

  const userInfo = useMemo<TripUserInfo>(() => {
    const birthday = trip.user.birthday;

    return {
      name: trip.user.name,
      name_roman: `${trip.user.last_name_roman} ${trip.user.first_name_roman}`,
      birthday,
      age: moment().diff(moment(birthday, 'YYYY-MM-DD'), 'years'),
      gender: trip.user.gender,
      tel: trip.user.nationalized_tel,
      email: trip.user.email,
      domestic_approval_required: trip.user.domestic_approval_required ?? false,
      foreign_approval_required: trip.user.foreign_approval_required ?? false,
      department: {
        name: utils.dig(trip, 'user', 'department', 'name') ?? ''
      },
      grade: {
        name: utils.dig(trip, 'user', 'grade', 'name') ?? ''
      }
    };
  }, [trip]);

  return (
    <div>
      <Section>
        <SectionTitle>料金プラン</SectionTitle>
        <p>{planName}</p>
      </Section>

      <Section>
        <SectionTitle>予約者</SectionTitle>
        <ul>
          <li>氏名: {userInfo.name}</li>
          <li>ローマ字: {userInfo.name_roman}</li>
          <li>生年月日: {`${userInfo.birthday}(${userInfo.age}歳)`}</li>
          <li>性別: {userInfo.gender}</li>
          <li>Tel: {userInfo.tel}</li>
          <li>E-mail: {userInfo.email}</li>
          <li>部署: {userInfo.department.name}</li>
          <li>国内承認: {userInfo.domestic_approval_required ? 'あり' : 'なし'}</li>
          <li>海外承認: {userInfo.foreign_approval_required ? 'あり' : 'なし'}</li>
        </ul>
      </Section>

      <Section>
        <SectionTitle>旅費規程適用ユーザ(出張代表者)情報</SectionTitle>

        {travelerInfo ? (
          <ul>
            <li>氏名: {travelerInfo.name}</li>
            <li>ローマ字: {`${travelerInfo.last_name_roman} ${travelerInfo.first_name_roman}`}</li>
            <li>生年月日: {travelerInfo.birthday}</li>
            <li>性別: {travelerInfo.gender}</li>
            <li>Tel: {travelerInfo.nationalized_tel}</li>
            <li>E-mail: {travelerInfo.email}</li>
            <li>役職等級: {utils.dig(travelerInfo, 'grade', 'name') || ''}</li>
            <li>部署: {utils.dig(travelerInfo, 'department', 'name') || ''}</li>
            <li>国内承認: {travelerInfo.domestic_approval_required ? 'あり' : 'なし'}</li>
            <li>海外承認: {travelerInfo.foreign_approval_required ? 'あり' : 'なし'}</li>
          </ul>
        ) : (
          <ul>
            <li>氏名: {userInfo.name}</li>
            <li>ローマ字: {userInfo.name_roman}</li>
            <li>生年月日: {`${userInfo.birthday}(${userInfo.age}歳)`}</li>
            <li>性別: {userInfo.gender}</li>
            <li>Tel: {userInfo.tel}</li>
            <li>E-mail: {userInfo.email}</li>
            <li>部署: {userInfo.department.name}</li>
            <li>国内承認: {userInfo.domestic_approval_required ? 'あり' : 'なし'}</li>
            <li>海外承認: {userInfo.foreign_approval_required ? 'あり' : 'なし'}</li>
          </ul>
        )}
      </Section>

      <Section>
        <SectionTitle>旅費規程適用商品情報</SectionTitle>
        {trip && (
          <>
            {trip.currentOrder.order_items.map((item: any, i: number) => {
              return (
                item.hotels[0] && (
                  <React.Fragment key={`${i + 1}`}>
                    <p>□ 商品情報{`${i + 1} (${item.hotels[0].name})`}</p>
                    <br />
                    <ul>
                      {item.hotel_price_prefecture && (
                        <>
                          <li>規程エリア(地域) {item.hotel_price_prefecture.region_name}</li>
                          <li>規程エリア(県) {item.hotel_price_prefecture.name}</li>
                        </>
                      )}
                      {item.hotel_price_municipality && (
                        <li>規程エリア(市区町村) {item.hotel_price_municipality.name}</li>
                      )}
                      <li>宿泊規程金額 {item.hotel_price_limit}円</li>
                    </ul>
                    <br />
                  </React.Fragment>
                )
              );
            })}
            {trip?.forbiddenRepositories !== undefined &&
            hasForbiddenRepository(trip.forbiddenRepositories, 'rentalcar', 'smas') ? (
              <p>SMAS：利用しない</p>
            ) : (
              <p>SMAS：利用する</p>
            )}
          </>
        )}
      </Section>
    </div>
  );
};

const Section = styled.section`
  & + & {
    margin-top: ${getSpacing(4)}px;
  }
`;

const SectionTitle = styled.h3`
  margin-bottom: ${getSpacing(1)}px;
`;
