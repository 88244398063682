import React from 'react';

interface Props {
  status: any;
}
export default class BulkUpdateActionApprovers extends React.Component<Props> {
  getStatusClass = (bulkAction: any): string =>
    bulkAction.isSuccess() ? 'green' : bulkAction.isFail() ? 'red' : 'gray';

  render() {
    const { status } = this.props;
    return (
      <table className="organization__table">
        <thead>
          <tr>
            <th className="organization__th">部署コード</th>
            <th className="organization__th">処理結果</th>
          </tr>
        </thead>
        <tbody>
          {status.actionApprovers.map((approver: any, i: number) => (
            <tr key={i} className="organization__tr">
              <td className="organization__td e2e-code">{approver.code}</td>
              <td className={`organization__td e2e-status ${this.getStatusClass(approver)}`}>
                {approver.statusMessage()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
