import { styled } from '@this/constants/themes';

export const BoxShortdistance = styled.div<{ selected: boolean }>`
  display: flex;
  background: white;
  margin-bottom: 10px;
  font-size: 10px;

  ${props =>
    props.selected &&
    `
    border: 2px solid #eee;
    border-color: ${props.theme.linkColor}
  `};
`;

export const BoxButtonText = styled.p`
  margin: 0 auto;
`;

export const Yen = styled.span`
  font-size: 10px;
  margin-left: 3px;
`;
