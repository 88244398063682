import React from 'react';

import { styled } from '@this/constants/themes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@this/shared/ui/data_displays/table';
import { isPlainObject } from 'lodash';
import { ArrangementReportsPopup } from './arrangement_reports_popup';

type Props = {
  className?: string;
  reportItems: ReportItem[];
  reportTimes: ReportTime[];
};

type ReportItemDatum = {
  value: number | string;
  trip_ids?: number[];
};

type ReportItem = {
  cancel_charge: ReportItemDatum;
  flight: {
    domestic: {
      changeable: {
        ana: ReportItemDatum;
        jal: ReportItemDatum;
        other: ReportItemDatum;
        sfj: ReportItemDatum;
      };
      unchangeable: {
        ana: ReportItemDatum;
        jal: ReportItemDatum;
        other: ReportItemDatum;
        sfj: ReportItemDatum;
      };
    };
    foreign: ReportItemDatum;
  };
  hotel: {
    ana_tabisaku: ReportItemDatum;
    ean: ReportItemDatum;
    other: ReportItemDatum;
    rakuten: ReportItemDatum;
  };
  shinkansen: {
    bulk_ticket: ReportItemDatum;
    reserved: ReportItemDatum;
    other: ReportItemDatum;
  };
  other: ReportItemDatum;
  order_item_count: number;
  total_price: number;
  time: string;
};

type ReportTime = {
  range: string;
  str: string;
  value: string;
};

type RowData = {
  firstColName?: string;
  secondColName: string;
  data: ReportItemDatum[];
};

const createInitialRowDataRecord = (): Record<string, RowData> => ({
  totalPrice: { secondColName: '取扱高', data: [] },
  tripCount: { secondColName: 'Trip数', data: [] },
  orderCount: { secondColName: '手配アイテム数', data: [] },
  hotel__ean__foreign: { secondColName: 'EAN（海外）', data: [], firstColName: '宿泊' },
  hotel__ean__domestic: { secondColName: 'EAN（国内）', data: [] },
  hotel__rakuten: { secondColName: '楽天', data: [] },
  hotel__ana_tabisaku: { secondColName: 'ANA旅作', data: [] },
  hotel__other: { secondColName: 'その他', data: [] },
  hotelTotal: { secondColName: '宿泊合計', data: [] },
  flight__domestic__changeable__ana: { secondColName: 'ANA（変更可）', data: [], firstColName: '飛行機（国内）' },
  flight__domestic__unchangeable__ana: { secondColName: 'ANA（変更不可）', data: [] },
  flight__domestic__changeable__jal: { secondColName: 'JAL（変更可）', data: [] },
  flight__domestic__unchangeable__jal: { secondColName: 'JAL（変更不可）', data: [] },
  flight__domestic__changeable__sfj: { secondColName: 'SFJ（変更可）', data: [] },
  flight__domestic__unchangeable__sfj: { secondColName: 'SFJ（変更不可）', data: [] },
  flight__domestic__changeable__other: { secondColName: 'その他（変更可）', data: [] },
  flight__domestic__unchangeable__other: { secondColName: 'その他（変更不可）', data: [] },
  flightDomesticChangeableTotal: { secondColName: '飛行機（変更可）合計', data: [] },
  flightDomesticUnchangeableTotal: { secondColName: '飛行機（変更不可）合計', data: [] },
  flightDomesticTotal: { secondColName: '飛行機（国内）合計', data: [] },
  flight__foreign: { secondColName: '飛行機（海外）', data: [], firstColName: '飛行機（海外）' },
  shinkansen__reserved: { secondColName: '指定席', data: [], firstColName: '新幹線' },
  shinkansen__bulk_ticket: { secondColName: '回数券', data: [] },
  shinkansenTotal: { secondColName: '新幹線合計', data: [] },
  cancel_charge: { secondColName: 'キャンセル合計', data: [], firstColName: 'キャンセル' },
  other: { secondColName: 'その他合計', data: [], firstColName: 'その他' }
});

const getTripIds = (item: Record<string, any>): number[] => {
  const tripIds = Object.values(item).reduce((acc, value) => {
    if (value.trip_ids !== undefined) {
      acc.push(...value.trip_ids);
    } else if (isPlainObject(value)) {
      acc.push(...getTripIds(value));
    }
    return acc;
  }, []);

  // Setで重複を排除
  return Array.from(new Set(tripIds));
};

const calcTotalValue = (item: Record<string, any>): number => {
  return Object.values(item).reduce((acc, value) => {
    if (value.value !== undefined) {
      return acc + value.value;
    }
    if (isPlainObject(value)) {
      return acc + calcTotalValue(value);
    }
    return acc;
  }, 0);
};

export const ArrangementReportTable = ({ className, reportItems, reportTimes }: Props) => {
  const rowDataList = React.useMemo(
    () =>
      Object.values<RowData>(
        reportItems.reduce<Record<string, RowData>>((acc, item) => {
          Object.keys(acc).forEach(key => {
            const splitedKeys = key.split('__');
            const datum = utils.dig(item, ...splitedKeys);
            if (!datum) {
              return;
            }

            acc[key].data.push(datum);
          });
          acc.totalPrice.data.push({ value: utils.formatPriceWithMark(item.total_price) });
          const tripIds = getTripIds(item);
          acc.tripCount.data.push({ value: tripIds.length, trip_ids: tripIds });
          acc.orderCount.data.push({ value: item.order_item_count ?? 0, trip_ids: tripIds });
          // 宿泊合計
          acc.hotelTotal.data.push({ value: calcTotalValue(item.hotel), trip_ids: getTripIds(item.hotel) });
          // 国内航空券
          const domesticChangeableObj = utils.dig(item.flight, 'domestic', 'changeable');
          acc.flightDomesticChangeableTotal.data.push({
            value: calcTotalValue(domesticChangeableObj),
            trip_ids: getTripIds(domesticChangeableObj)
          });
          const domesticUnchangeableObj = utils.dig(item.flight, 'domestic', 'unchangeable');
          acc.flightDomesticUnchangeableTotal.data.push({
            value: calcTotalValue(domesticUnchangeableObj),
            trip_ids: getTripIds(domesticUnchangeableObj)
          });
          acc.flightDomesticTotal.data.push({
            value: calcTotalValue(item.flight.domestic),
            trip_ids: getTripIds(item.flight.domestic)
          });
          // 新幹線
          acc.shinkansenTotal.data.push({
            value: calcTotalValue(item.shinkansen),
            trip_ids: getTripIds(item.shinkansen)
          });
          return acc;
        }, createInitialRowDataRecord())
      ),
    [reportItems]
  );

  return (
    <TableContainer className={className} height="calc(100vh - 470px)">
      <Table size="small" stickyHeader style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <HeaderFirstCol>大分類</HeaderFirstCol>
            <HeaderSecondCol>中分類</HeaderSecondCol>
            {reportTimes.map(time => (
              <TableCell key={time.value} width="100px" align="center">
                {time.str}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowDataList.map(rowData => (
            <TableRow key={rowData.secondColName}>
              <FirstCol>{rowData.firstColName}</FirstCol>
              <SecondCol>{rowData.secondColName}</SecondCol>
              {rowData.data.map((datum, i) => (
                <TableCell key={i.toString()} align="center">
                  <ArrangementReportsPopup tripIds={datum.trip_ids}>{datum.value}</ArrangementReportsPopup>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const firstColWidth = '120px';
const FirstCol = styled(TableCell).attrs({ th: true })`
  position: sticky;
  width: ${firstColWidth};
  left: 0;
  z-index: 3;
  text-align: center;
  background: #fff;
`;
const SecondCol = styled(TableCell).attrs({ th: true })`
  position: sticky;
  width: 164px;
  left: ${firstColWidth};
  z-index: 3;
  text-align: center;
  background: #fff;
`;

const HeaderFirstCol = styled(FirstCol)`
  z-index: 4;
`;

const HeaderSecondCol = styled(SecondCol)`
  z-index: 4;
`;
