import React, { useEffect } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import TripInfoWindow from '@this/components/organization/trips/trip_info_window';
import TripsMapHelper from '@this/components/organization/trips/trips_map.helper';

interface Props {
  trips: Trip[];
}

const TripsMap: React.FC<Props> = ({ trips }) => {
  const infoWindowMap = new Map<number, google.maps.InfoWindow>();

  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const map = TripsMapHelper.createMap(ref);
    if (map) {
      const makerWithTripList = TripsMapHelper.createMarkers(map, trips);
      TripsMapHelper.fitBounds(
        map,
        makerWithTripList.map(mt => mt.marker)
      );

      makerWithTripList.forEach(mt => {
        mt.marker.addListener('click', () => {
          const infoWindow = infoWindowMap.has(mt.trip.id)
            ? infoWindowMap.get(mt.trip.id)!
            : TripsMapHelper.createInfoWindow(mt.trip, <TripInfoWindow trip={mt.trip} />);

          infoWindow.close();
          infoWindow.open(map, mt.marker);
          infoWindowMap.set(mt.trip.id, infoWindow);
        });
      });
    }
  });

  return <MapContainer ref={ref} />;
};

const MapContainer = styled.div`
  height: 100%;
`;

export default TripsMap;
