import React from 'react';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { Body } from '../shared/wrappers';
import { TripReportMainForm } from './body/trip_report_main_form';
import { TripReportTripSelect } from './body/trip_report_trip_select';
import { TripReportTripSelected } from './body/trip_report_trip_selected';
import { TripReportConfirm } from './body/trip_report_confirm';

export const TripReportFormBody: React.FC = () => {
  const store = useTripReportStore();
  const step = store.step;

  return (
    <Body>
      {step === 'tripReport' ? (
        <TripReportMainForm />
      ) : step === 'tripSelect' ? (
        <TripReportTripSelect />
      ) : step === 'tripSelected' ? (
        <TripReportTripSelected />
      ) : step === 'confirm' ? (
        <TripReportConfirm />
      ) : (
        <></>
      )}
    </Body>
  );
};
