import { styled } from '@this/constants/themes';
import { css } from 'styled-components';

export const ABase = css`
  &,
  &:focus {
    color: ${props => props.theme.linkColor};
  }

  &:hover {
    color: ${props => props.theme.linkColorHover};
    opacity: ${props => props.theme.linkOpacityHover};
  }
`;

/**
 * @deprecated
 * should use js/src/components/shared/ui/navigations/link
 */
const A = styled.a`
  ${ABase}
`;

export default A;
