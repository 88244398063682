import React, { useEffect, useState } from 'react';

import { styled } from '@this/constants/themes';
import type TransportElement from '@this/domain/transport_element';
import { ButtonBase } from '@this/shared/atoms/button';
import type { TravelerCandidate } from '@this/domain/arrangement/user_search.repository';
import type { TravelerInputsFormData } from './order_item_traveler_inputs_form';
import OrderItemTravelerInputsForm from './order_item_traveler_inputs_form';
import OrderItemTravelerSelect from './order_item_traveler_select';
import type { TravelerDomesticAirFormData } from './order_item_traveler_domestic_air_form';
import OrderItemTravelerDomesticAirForm from './order_item_traveler_domestic_air_form';
import type { TravelerForeignAirFormData } from './order_item_traveler_foreign_air_form';
import OrderItemTravelerForeignAirForm from './order_item_traveler_foreign_air_form';
import type { TravelerRailwayFormData } from './order_item_traveler_railway_form';
import OrderItemTravelerRailwayForm from './order_item_traveler_railway_form';
import type { Traveler, TravelerFormData } from './type';

type Props = {
  transportType: TransportElement['transportType'] | null;
  transportDate: string | null;
  traveler?: Traveler;
  className?: string;
  candidates: TravelerCandidate[];
  candidatesLoading: boolean;
  errors?: string[];
  onSearchCandidates: (query: string) => void;
  onSubmit: (formData: TravelerFormData) => void;
  onCancel: () => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
};

type State = {
  isManualInput: boolean;
  formData: TravelerFormData | null;
};

const USER_DEFAULT_FORMDATA = {
  userId: null,
  email: null,
  firstNameKana: '',
  firstNameRoman: '',
  flightFirstName: '',
  lastNameKana: '',
  lastNameRoman: '',
  middleNameRoman: '',
  flightLastName: '',
  flightMiddleName: '',
  flightFirstNameRoman: '',
  flightLastNameRoman: '',
  flightMiddleNameRoman: '',
  flightGender: null,
  birthday: '',
  flightBirthday: ''
};

const TRANSPORT_DEFAULT_FORMDATA = {
  airSeat: null,
  flightTel: null,
  mileageNumber: null,
  passportExpire: null,
  passportIssuedCountry: '日本',
  passportNumber: null,
  shinkansenSeat: '指定なし'
};

const OrderItemTravelerForm: React.FC<Props> = ({
  candidates,
  className,
  traveler,
  transportDate,
  transportType,
  candidatesLoading,
  errors,
  onSubmit,
  onCancel,
  onSearchCandidates,
  classNameForModifiedField
}) => {
  const [state, setState] = useState<State>({
    isManualInput: traveler ? !traveler.userId : false,
    formData: traveler ? { ...traveler } : null
  });
  const [inputErrorMessages, setInputErrorMessages] = useState<string[]>([]);
  const { isManualInput, formData } = state;
  const setManualInput = (bool: boolean) => {
    setState({ formData: null, isManualInput: bool });
  };
  const onChangeTravelerForm = (data: TravelerInputsFormData) => {
    const currentFormData = formData ?? TRANSPORT_DEFAULT_FORMDATA;

    const newFormData = {
      ...currentFormData,
      userId: null,
      email: null,
      firstNameKana: data.firstNameKana,
      firstNameRoman: data.firstNameRoman,
      flightFirstName: data.firstNameRoman,
      lastNameKana: data.lastNameKana,
      lastNameRoman: data.lastNameRoman,
      middleNameRoman: data.middleNameRoman,
      flightLastName: data.lastNameRoman,
      flightMiddleName: data.middleNameRoman,
      flightFirstNameRoman: data.firstNameRoman,
      flightLastNameRoman: data.lastNameRoman,
      flightMiddleNameRoman: data.middleNameRoman,
      flightGender: data.gender,
      birthday: data.birthday,
      flightBirthday: data.birthday
    };
    setState({ ...state, formData: newFormData });
  };

  const onSelectCandidate = (c: TravelerCandidate) => {
    const currentFormData = formData ?? TRANSPORT_DEFAULT_FORMDATA;
    const mileageNumber = c.mileage_numbers[0] || '';

    const newFormData = {
      ...currentFormData,
      userId: c.id,
      email: c.email,
      firstNameKana: c.first_name_kana,
      firstNameRoman: c.first_name_roman,
      flightFirstName: c.first_name_roman,
      lastNameKana: c.last_name_kana,
      lastNameRoman: c.last_name_roman,
      middleNameRoman: c.middle_name_roman,
      flightLastName: c.last_name_roman,
      flightMiddleName: c.middle_name_roman,
      flightFirstNameRoman: c.first_name_roman,
      flightLastNameRoman: c.last_name_roman,
      flightMiddleNameRoman: c.middle_name_roman,
      flightTel: c.tel,
      flightGender: c.gender,
      birthday: c.birthday,
      flightBirthday: c.birthday,
      passportNumber: c.passport_number,
      passportExpire: c.passport_expire,
      mileageNumber: typeof mileageNumber === 'string' ? mileageNumber : mileageNumber.number
    };
    setState({ ...state, formData: newFormData });
  };
  const onChangeRailwayForm = ({ shinkansenSeat }: TravelerRailwayFormData) => {
    if (!shinkansenSeat) {
      return;
    }

    const currentFormData = formData ?? { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA };
    const newFormData = { ...currentFormData, shinkansenSeat };
    setState({ ...state, formData: newFormData });
  };
  const onChangeForeignAirForm = (foreignAirFormData: TravelerForeignAirFormData) => {
    const currentFormData = formData ?? { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA };
    const newFormData = { ...currentFormData, ...foreignAirFormData };

    setState({ ...state, formData: newFormData });
  };
  const onChangeDomesticAirForm = (domesticAirFormData: TravelerDomesticAirFormData) => {
    const currentFormData = formData ?? { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA };
    const newFormData = { ...currentFormData, ...domesticAirFormData };

    setState({ ...state, formData: newFormData });
  };

  useEffect(() => {
    if (!isManualInput) {
      // 初期表示のため
      onSearchCandidates('');
    }
  }, [isManualInput]);

  const travelerFormInputsFormData: TravelerInputsFormData | null = formData
    ? {
        firstNameKana: formData.firstNameKana ?? '',
        lastNameKana: formData.lastNameKana ?? '',
        firstNameRoman: formData.firstNameRoman ?? '',
        lastNameRoman: formData.lastNameRoman ?? '',
        middleNameRoman: formData.middleNameRoman ?? '',
        gender: formData.flightGender ?? null,
        birthday: formData.birthday ?? ''
      }
    : null;

  const foreignAirFormData: TravelerForeignAirFormData = formData
    ? {
        airSeat: formData.airSeat,
        flightBirthday: formData.flightBirthday,
        flightFirstName: formData.flightFirstName,
        flightGender: formData.flightGender,
        flightLastName: formData.flightLastName,
        flightMiddleName: formData.flightMiddleName,
        flightTel: formData.flightTel,
        mileageNumber: formData.mileageNumber,
        passportExpire: formData.passportExpire,
        passportIssuedCountry: formData.passportIssuedCountry,
        passportNumber: formData.passportNumber
      }
    : { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA };

  const domesticAirFormData: TravelerDomesticAirFormData = formData
    ? {
        airSeat: formData.airSeat,
        flightBirthday: formData.flightBirthday,
        flightFirstNameRoman: formData.flightFirstNameRoman,
        flightGender: formData.flightGender,
        flightLastNameRoman: formData.flightLastNameRoman,
        flightTel: formData.flightTel,
        mileageNumber: formData.mileageNumber
      }
    : { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA };

  const canInputTransportInfo = (): boolean => {
    if (!formData) {
      return false;
    }

    // 手動入力の場合、全項目が入力されてから追加の項目を入力させたい
    if (isManualInput) {
      const manualInputAttrs: (keyof TravelerFormData)[] = [
        'firstNameKana',
        'lastNameKana',
        'firstNameRoman',
        'lastNameRoman',
        'birthday',
        'flightGender'
      ];
      return manualInputAttrs.every(key => !!formData[key]);
    }

    return true;
  };

  useEffect(() => {
    if (formData) {
      if (transportType === 'domestic_air' || transportType === 'foreign_air') {
        const birthDayValid = validateBirthDay(formData);
        const basicInfoValid = canInputTransportInfo();

        const errorMessages = [];
        if (!birthDayValid) {
          errorMessages.push('誕生日をYYYY-MM-DD形式で入力してください');
        }
        if (!basicInfoValid) {
          errorMessages.push('氏名(ローマ字)、氏名(カナ)を入力してください');
        }

        setInputErrorMessages(errorMessages);
      }
    }
  }, [formData]);

  useEffect(() => {
    if (state) {
      if (transportType === 'domestic_air' || transportType === 'foreign_air') {
        const flightBirthDayValid = validateFlightBirthDay(state);
        const flightNameValid = validateFlightName(state);

        const errorMessages = [];
        if (!flightBirthDayValid) {
          errorMessages.push('誕生日をYYYY-MM-DD形式で入力してください');
        }
        if (!flightNameValid) {
          errorMessages.push('氏名(ローマ字)を入力してください');
        }

        setInputErrorMessages(errorMessages);
      }
    }
  }, [state]);

  const birthdayPattern = /^\d{4}-\d{2}-\d{2}$/;
  const validateBirthDay = (formData: any): boolean => {
    return !!formData.birthday && birthdayPattern.test(formData.birthday);
  };
  const validateFlightBirthDay = (state: any): boolean => {
    if (!state.formData?.flightBirthday) {
      return false;
    }
    return !!state.formData.flightBirthday && birthdayPattern.test(state.formData.flightBirthday);
  };

  const validateFlightName = (state: any): boolean => {
    if (!state.formData?.flightLastName || !state.formData?.flightFirstName) {
      return false;
    }
    return state.formData.flightLastName.trim() !== '' && state.formData.flightFirstName.trim() !== '';
  };
  const canSubmit = (): boolean => {
    if (!formData) {
      return false;
    }
    return inputErrorMessages.length === 0;
  };

  return (
    <div className={className}>
      <TravelerBaseInfoContainer>
        {isManualInput ? (
          <>
            <StyledOrderItemTravelerInputsForm
              formData={travelerFormInputsFormData}
              transportDate={transportDate}
              onChange={onChangeTravelerForm}
              classNameForModifiedField={classNameForModifiedField}
              transportType={transportType}
            />
            <LinkButton onClick={() => setManualInput(false)}>検索入力</LinkButton>
          </>
        ) : (
          <>
            <StyledOrderItemTravelerSelect
              selectedUserName={
                formData
                  ? formData.lastNameKana
                    ? `${formData.lastNameKana} ${formData.firstNameKana}`
                    : formData.email
                  : null
              }
              candidates={candidates}
              loading={candidatesLoading}
              onSearch={onSearchCandidates}
              onChange={onSelectCandidate}
              className={`
                ${classNameForModifiedField('lastNameKana')}
                ${classNameForModifiedField('firstNameKana')}
              `}
            />
            <LinkButton onClick={() => setManualInput(true)}>手動入力</LinkButton>
          </>
        )}
      </TravelerBaseInfoContainer>

      {canInputTransportInfo() ? (
        transportType === 'domestic_air' ? (
          <OrderItemTravelerDomesticAirForm
            formData={domesticAirFormData}
            transportDate={transportDate}
            hiddenPersonalForm={isManualInput}
            onChange={onChangeDomesticAirForm}
            classNameForModifiedField={classNameForModifiedField}
          />
        ) : transportType === 'foreign_air' ? (
          <OrderItemTravelerForeignAirForm
            formData={foreignAirFormData}
            transportDate={transportDate}
            hiddenPersonalForm={isManualInput}
            onChange={onChangeForeignAirForm}
            classNameForModifiedField={classNameForModifiedField}
          />
        ) : transportType === 'shinkansen' || transportType === 'express' ? (
          <OrderItemTravelerRailwayForm
            formData={{ shinkansenSeat: formData?.shinkansenSeat ?? null }}
            onChange={onChangeRailwayForm}
            classNameForModifiedField={classNameForModifiedField}
          />
        ) : (
          ''
        )
      ) : (
        ''
      )}
      {errors && errors.length > 0 && (
        <OrderItemError>
          {errors.map((error, i) => (
            <Error key={i}>{error}</Error>
          ))}
        </OrderItemError>
      )}
      {formData &&
        inputErrorMessages &&
        inputErrorMessages.length > 0 &&
        inputErrorMessages.map((error, i) => <Error key={i}>{error}</Error>)}
      <Actions>
        <LinkButton onClick={onCancel}>キャンセル</LinkButton>
        <SubmitButton
          onClick={() => onSubmit(formData ?? { ...USER_DEFAULT_FORMDATA, ...TRANSPORT_DEFAULT_FORMDATA })}
          disabled={!canSubmit()}
        >
          更新
        </SubmitButton>
      </Actions>
    </div>
  );
};

const TravelerBaseInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledOrderItemTravelerInputsForm = styled(OrderItemTravelerInputsForm)`
  width: 230px;
`;
const StyledOrderItemTravelerSelect = styled(OrderItemTravelerSelect)`
  width: 230px;
`;

const OrderItemError = styled.div`
  padding: 10px 0;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const LinkButton = styled.a`
  cursor: pointer;
`;

const SubmitButton = styled.button.attrs({ type: 'button' })`
  ${ButtonBase};
  padding: 5px 10px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  > * + * {
    margin-left: 5px;
  }
`;

export default OrderItemTravelerForm;
