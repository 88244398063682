import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { lighten, Link } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { STATUS_NAMES as APPROVAL_STATUS } from '@this/src/domain/trip_report/approval';
import type { Stage } from '@this/src/domain/trip_report/approval';
import { media } from '../../shared/atoms/media';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import { BodyTitle, LoadingWrap } from './shared/wrappers';
import ProjectInformations from './approvals/project_informations';
import ApproveRoute from '../../shared/approve_route/approve_route_input';
import { Select } from '../../shared/ui/inputs/select';

type Props = {
  editable?: boolean;
};

const TripReportReportApproval: React.FC<Props> = ({ editable }) => {
  const { id } = useParams<{ id: string }>();
  const store = useTripReportStore();
  const { serviceId, currentTripReport, workflowStyle, workflowOptions } = store;
  const approvalGroups = workflowStyle === 'trip_report' ? [store.approvals] : store.approvalGroups();
  const totalAmount = store.totalAmount();
  const [loadingWorkflow, setLoadingWorkflow] = useState(false);

  const workflowEditable = useMemo(() => workflowStyle === 'trip_report' && editable, [workflowStyle, editable]);

  // 承認ルートの設定が読み込まれているか確認（手動設定で編集時は除く）
  const approvalExists = useMemo(
    () => approvalGroups.length > 0 || workflowEditable,
    [workflowEditable, approvalGroups]
  );

  // 承認ワークフローが形成されているか確認（手動設定の場合は、登録済みのものがあれば確認）
  const workflowExists = useMemo(
    () =>
      approvalExists &&
      (workflowStyle === 'trip_report'
        ? Boolean(approvalGroups[0]?.[0]?.id)
        : Boolean(approvalGroups[0]?.[0]?.groupName)),
    [workflowStyle, approvalExists, approvalGroups]
  );

  const handleApproveStagesChange = useCallback((stages: Stage[]) => store.setApproveStages(stages), [store]);

  const handleApproveStagesChangeByOption = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      store.setApproveStages(workflowOptions[parseInt(e.target.value, 10)].workflow);
      setLoadingWorkflow(true);
      setTimeout(() => setLoadingWorkflow(false), 500);
    },
    [store.setApproveStages, workflowOptions, setLoadingWorkflow]
  );

  useEffect(() => {
    // TODO: 部署による承認に対応
    setLoadingWorkflow(true);
    store.getApprovals(id, () => setLoadingWorkflow(false));
  }, [id]);

  return !workflowStyle ? (
    <LoadingWrap>
      <SimpleLoading />
    </LoadingWrap>
  ) : approvalExists ? (
    <Wrapper>
      {workflowStyle === 'project' ? (
        <Section>
          <BodyTitle>プロジェクト詳細</BodyTitle>
          <SectionBody>
            <ProjectInformations approvalGroups={approvalGroups} totalAmount={totalAmount} />
          </SectionBody>
        </Section>
      ) : (
        <SimpleSection>
          <BodyTitle>全ての出張の合計金額</BodyTitle>
          <SectionBody>
            <Total>¥ {totalAmount.toLocaleString()}</Total>
          </SectionBody>
        </SimpleSection>
      )}
      {workflowEditable && store.approvalRequired && (
        <Section>
          <BodyTitle>
            ワークフロー
            <br />
            承認ルート登録
          </BodyTitle>
          <SectionBody>
            {workflowOptions.length > 0 && (
              <WorkflowSelectWrap>
                <Select value="" onChange={handleApproveStagesChangeByOption}>
                  <option value="">- 既存の承認ルートを使用する -</option>
                  {workflowOptions.map((workflowOption, i) => (
                    <option key={`workflowOption-${i}`} value={i.toString()}>
                      {workflowOption.name}
                    </option>
                  ))}
                </Select>
              </WorkflowSelectWrap>
            )}
            {loadingWorkflow ? (
              <LoadingWrap>
                <SimpleLoading />
              </LoadingWrap>
            ) : (
              <ApproveRoute stages={store.approvalStages} handleApproveStagesChange={handleApproveStagesChange} />
            )}
          </SectionBody>
        </Section>
      )}
      {workflowExists && (
        <Section>
          <BodyTitle>承認フロー</BodyTitle>
          <StatusWrap>
            {approvalGroups.map((approvals, i) => (
              <StatusGroup key={`approvals-${i}`}>
                <StatusGroupLabel>{approvals[0].groupName}</StatusGroupLabel>
                <StatusItems>
                  {approvals.map((approval, j) => (
                    <StatusItem
                      key={`approval-${i}-${j}`}
                      approved={approval.status === 'approved'}
                      first={j === 0}
                      hasApprovalAt={j > 0 && Boolean(approvals[j - 1].approvedAt)}
                    >
                      <StatusIcon>
                        <svg
                          viewBox="0 0 22 22"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <Circle
                              approved={approval.status === 'approved'}
                              strokeWidth="6"
                              cx="11"
                              cy="11"
                              r="8"
                            />
                          </g>
                        </svg>
                      </StatusIcon>
                      <StatusText>
                        <StatusColor className={approval.currentStatus()}>
                          {APPROVAL_STATUS[approval.currentStatus()]}
                        </StatusColor>
                        <div>{approval.approvers.map(approver => approver.name).join('、')}</div>
                        {approval.approvedAt && (
                          <ApprovalWrapper>
                            <ApprovalAt>(対応日：{approval.approvedAt.format('YYYY/MM/DD HH:mm')})</ApprovalAt>
                            <div>{approval.message}</div>
                          </ApprovalWrapper>
                        )}
                      </StatusText>
                    </StatusItem>
                  ))}
                </StatusItems>
              </StatusGroup>
            ))}
          </StatusWrap>
        </Section>
      )}
    </Wrapper>
  ) : currentTripReport.status !== 'self_approved' ? (
    <div>
      <Warning>※ 承認者が設定されていません</Warning>
      {workflowStyle === 'project' && (
        <>
          <Warning>
            ※ 「システムで手配した商品」と「{serviceId !== 2 ? '社員立替経費' : '自己手配経費'}
            」に承認者が設定されているプロジェクトを指定してください
          </Warning>
          <Link color="primary" onClick={() => store.setStep('tripReport')}>
            {'< 前の画面に戻る'}
          </Link>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 40px;
`;

const Section = styled.section`
  display: flex;
  max-width: 632px; // 600 + 16 + 16 (Padding分プラス)

  & > h2 {
    width: 140px;
    margin-bottom: 0;
  }

  ${media.sp`
    flex-flow: column;
  `};
`;

const SimpleSection = styled.section`
  display: flex;
  align-items: baseline;
  max-width: 632px; // 600 + 16 + 16 (Padding分プラス)

  & > h2 {
    width: 160px;
    margin-bottom: 0;
  }

  & > div {
    text-align: right;
  }

  ${media.sp`
    flex-flow: column;
  `};
`;

const SectionBody = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 8px;
  padding: 0 16px;
  max-width: 600px;

  ${media.sp`
    padding: 16px 0;
    gap: 16px;
  `};
`;

const Total = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

const WorkflowSelectWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusWrap = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 1.6em;
  padding: 0;
  max-width: 600px;

  ${media.sp`
    padding: 16px 0;
    gap: 16px;
  `};
`;

const StatusGroup = styled.div`
  padding: 0 16px;
`;

const StatusGroupLabel = styled.label`
  font-size: 0.8em;
  margin-bottom: 4px;
  color: ${props => props.theme.grayTextColor};
`;

const StatusItems = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1.6em;

  ${media.sp`
    padding: 0;
    gap: 16px;
  `};
`;

const StatusItem = styled.div<{ approved: boolean; first: boolean; hasApprovalAt: boolean }>`
  position: relative;
  padding-left: 29px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 9px;
    z-index: 0;
    border-left: solid 3px ${props => props.theme.linkColor};

    height: ${props => (props.hasApprovalAt ? '3.6em' : '1.7em')};
    top: ${props => (props.hasApprovalAt ? '-3.2em' : '-1.6em')};
    ${props =>
      props.hasApprovalAt &&
      media.sp`
      height: 5.1em;
      top: -4.8em;
    `}

    ${props =>
      props.approved &&
      `
      border-color: ${lighten(props.theme.linkColor, 0.6)};
    `}
    ${props =>
      props.first &&
      `
      border-color: transparent;
    `}
  }

  ${media.sp`
    margin-bottom: 6px;
  `}
`;

const StatusIcon = styled.div`
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 10;
  width: 21px;
  height: 21px;
`;

const Circle = styled.circle<{ approved: boolean }>`
  stroke: ${props => props.theme.linkColor};

  ${props =>
    props.approved &&
    `
    stroke: ${lighten(props.theme.linkColor, 0.6)};
  `}
`;

const StatusText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
`;

const COLORS = {
  gray: '#6B7280',
  orange: '#FB923C',
  green: '#10B981'
};

const StatusColor = styled.div`
  color: ${COLORS.gray};
  &.applied {
    color: ${COLORS.orange};
  }
  &.approved {
    color: ${COLORS.green};
  }
`;

const ApprovalAt = styled.div`
  color: ${props => props.theme.grayTextColor};
`;
const ApprovalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Warning = styled.div`
  color: ${props => props.theme.redColor};
`;

export default TripReportReportApproval;
