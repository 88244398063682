export interface AdminArgs {
  id?: number;
  email?: string;
  admin_role_id: number;
  last_name?: string;
  first_name?: string;
  deleted_at?: string;
}

class Admin {
  id: number | null;

  email: string;

  adminRoleId: number;

  lastName: string;

  firstName: string;

  deleted_at: string;

  constructor(args: AdminArgs) {
    this.id = args.id || null;
    this.email = args.email || '';
    this.adminRoleId = args.admin_role_id;
    this.lastName = args.last_name || '';
    this.firstName = args.first_name || '';
    this.deleted_at = args.deleted_at || '';
  }

  getName() {
    if (this.lastName && this.firstName) {
      return `${this.lastName} ${this.firstName}`;
    }
    return this.lastName || this.firstName || this.email;
  }

  handleEmailChange(value: string) {
    this.email = value;
    app.render();
  }

  handleRoleChange(value: number) {
    this.adminRoleId = value;
    app.render();
  }

  handleLastNameChange(value: string) {
    this.lastName = value;
    app.render();
  }

  handleFirstNameChange(value: string) {
    this.firstName = value;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      email: this.email,
      admin_role_id: this.adminRoleId,
      last_name: this.lastName,
      first_name: this.firstName
    };
  }
}

export default Admin;
