import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { OrganizationTitle } from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { InAdvanceMessageTemplate } from '@this/src/domain/in_advance/in_advance_message_template';
import type { InAdvanceMessageTemplateArgs } from '@this/src/domain/in_advance/in_advance_message_template';
import Cards from './cards';
import EditFormModal from './edit_form_modal';
import DeleteFormModal from './delete_form_modal';

type Props = {
  theme: { themeClass: string };
};

type IndexResponse = {
  message_templates: InAdvanceMessageTemplateArgs[];
};

const InAdvanceMessageTemplates: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [messages, setMessages] = useState<InAdvanceMessageTemplate[]>([]);
  const [editingMessage, setEditingMessage] = useState<InAdvanceMessageTemplate | null>(null);
  const [deletingMessage, setDeletingMessage] = useState<InAdvanceMessageTemplate | null>(null);

  const fetchMessages = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await utils.jsonPromise<IndexResponse>('/organization/in_advance_message_templates.json');
      setMessages(response.message_templates.map(raw => new InAdvanceMessageTemplate(raw)));
    } catch (e) {
      if (e.status === 400) {
        setError(e.responseJSON.error);
      } else {
        setError('通信エラーが発生しました。時間をおいて再度お試しください。');
        utils.sendErrorObject(e);
      }
    }
    setLoading(false);
  };

  const deleteMessage = async () => {
    try {
      await utils.jsonPromise(`/organization/in_advance_message_templates/${deletingMessage!.id}`, {}, 'DELETE');
      setDeletingMessage(null);
      fetchMessages();
    } catch (e) {
      setError('通信エラーが発生しました。時間をおいて再度お試しください。');
      utils.sendErrorObject(e);
    }
  };

  const clickEdit = async (t: InAdvanceMessageTemplate | null) => {
    if (t !== null) {
      const editT = _.cloneDeep(t);
      setEditingMessage(editT);
    }
  };

  const clickAdd = async () => {
    const new_message = new InAdvanceMessageTemplate();
    setMessages([new_message, ...messages]);
    setEditingMessage(new_message);
  };

  const clickCancel = () => {
    if (editingMessage && !editingMessage.id && messages) {
      setMessages(messages.filter(t => t.id));
    }
    setEditingMessage(null);
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const modal = () => (
    <>
      {editingMessage && (
        <EditFormModal
          messageTemplate={editingMessage}
          onSubmit={() => {
            setEditingMessage(null);
            fetchMessages();
          }}
          hideModal={clickCancel}
        />
      )}
      {deletingMessage && (
        <DeleteFormModal
          messageTemplate={deletingMessage}
          onSubmit={() => deleteMessage()}
          hideModal={() => setDeletingMessage(null)}
        />
      )}
    </>
  );

  return (
    <>
      <OrganizationTitle>却下理由マスタ</OrganizationTitle>
      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Wrap>
          <Cards
            messageTemplates={messages}
            onClickEdit={t => clickEdit(t)}
            onClickDelete={t => {
              setDeletingMessage(t);
            }}
          />
        </Wrap>
      )}
      {!editingMessage && (
        <Wrap>
          <TheInput
            className="organization__add-button"
            type="submit"
            value="メッセージテンプレートを追加"
            onClick={clickAdd}
          />
        </Wrap>
      )}
      {modal()}
    </>
  );
};

const Wrap = styled.div`
  padding: 10px;
`;

const TheInput = styled.input`
  &&& {
    width: auto;
    margin-right: 10px;
  }
`;

export default InAdvanceMessageTemplates;
