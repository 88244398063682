import React, { useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { styled } from '@this/constants/themes';
import { Button as UiButton } from '@this/components/shared/ui/inputs/button';
import type { SimulateBaseFileArgs } from '@this/src/domain/simulate_base_file';
import { SimulateBaseFile } from '@this/src/domain/simulate_base_file';
import { IconButton, Typography } from '@material-ui/core';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { Fetcher, HTTPError } from '@this/src/util';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import { Title } from '../god';

const StickyTable = require('react-sticky-table').StickyTable;
const Row = require('react-sticky-table').Row;
const Cell = require('react-sticky-table').Cell;

type Props = RouteComponentProps;

interface SimulateBaseFilesResponse {
  simulate_base_files: SimulateBaseFileArgs[];
}

interface SimulateBaseFileCountResponse {
  simulate_base_file: SimulateBaseFileArgs;
  simulate_base_records: Record<never, never>;
}

const Simulation: React.FC<Props> = () => {
  const [simulateBaseFiles, setSimulateBaseFiles] = useState<SimulateBaseFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [clear, setClear] = useState<'loading' | boolean | null>(null);

  const fetchSimulateBaseFiles = useCallback(async () => {
    setLoading(true);
    setError(null);

    await Fetcher.get<SimulateBaseFilesResponse>('/god/simulate_base_files.json')
      .then(response => {
        setSimulateBaseFiles(response.simulate_base_files.map(raw => new SimulateBaseFile(raw)));
      })
      .catch(() => setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。'))
      .finally(() => setLoading(false));
  }, [setSimulateBaseFiles, setLoading]);

  const fetchSimulateBaseFileCount = useCallback(
    async (file: SimulateBaseFile) => {
      if (file.totalCount <= file.executed) return;

      await Fetcher.get<SimulateBaseFileCountResponse>(`/god/simulate_base_files/${file.id}/count.json`).then(
        ({ simulate_base_file }) => {
          setSimulateBaseFiles(files =>
            files.map(f => (f.id === simulate_base_file.id ? new SimulateBaseFile(simulate_base_file) : f))
          );
        }
      );
    },
    [setSimulateBaseFiles]
  );

  const fetchStop = useCallback(
    async (file: SimulateBaseFile) => {
      if (file.totalCount <= file.executed) return;

      await Fetcher.post<{ success: boolean }>(`/god/simulate_base_files/${file.id}/stop`, {}).then(() => {
        setSimulateBaseFiles(files =>
          files.map(f =>
            f.id === file.id
              ? new SimulateBaseFile({ ...f.submitParams(), failed: f.totalCount - f.completed })
              : f
          )
        );
      });
    },
    [setSimulateBaseFiles]
  );

  const handleDrop = useCallback(
    (files: File[]) => {
      setFile(files[0]);
    },
    [setFile]
  );

  const handleStop = useCallback((file: SimulateBaseFile) => () => fetchStop(file), [fetchStop]);

  const handleUpload = useCallback(
    async (file: File | null) => {
      if (file === null) return;

      setUploading(true);
      setError(null);

      const formData = new FormData();
      formData.append('file', file);

      try {
        await Fetcher.upload('/god/simulate_base_files', formData);
        await fetchSimulateBaseFiles();
      } catch (e) {
        setError(
          e instanceof HTTPError && e.response?.data?.error
            ? e.response.data.error
            : '通信エラーが発生しました。時間をおいて再度お試しください。'
        );
      }

      setFile(null);
      setUploading(false);
    },
    [setUploading, setFile, setError, fetchSimulateBaseFiles]
  );

  const handleClear = useCallback(async () => {
    setClear('loading');

    await Fetcher.post('/god/simulate_base_files/clear', {})
      .then(() => setClear(true))
      .catch(() => setClear(false));
  }, [setClear]);

  useEffect(() => {
    fetchSimulateBaseFiles();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      simulateBaseFiles.forEach(file => fetchSimulateBaseFileCount(file));
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [simulateBaseFiles]);

  return (
    <div>
      <Title>あとづけマーケットログ</Title>
      <Content>
        {loading ? (
          <SimpleLoading />
        ) : (
          <>
            <InputAreaRight>
              <UploadForm onDrop={handleDrop} multiple={false} disabled={uploading}>
                {file ? file.name : <p> クリックしてファイルをアップロード </p>}
              </UploadForm>
              <UiButton onClick={() => handleUpload(file)} loading={uploading}>
                アップロード
              </UiButton>
              <UiButton onClick={handleClear} loading={clear === 'loading'}>
                キャッシュクリア
              </UiButton>
              {clear === true ? (
                <Typography>キャッシュをクリアしました</Typography>
              ) : (
                clear === false && <Typography color="error">キャッシュクリアに失敗しました</Typography>
              )}
            </InputAreaRight>
            <div>
              {error && <Error>{error}</Error>}
              <SimulationTable>
                <Row>
                  <SimulationTh>ファイルID</SimulationTh>
                  <SimulationTh>ファイル名</SimulationTh>
                  <SimulationTh width={140}>ステータス</SimulationTh>
                  <SimulationTh>進捗率</SimulationTh>
                  <SimulationTh>最終更新</SimulationTh>
                  <SimulationTh width={180} />
                </Row>
                {simulateBaseFiles.map(file => (
                  <Row key={file.id}>
                    <SimulationTd>{file.id}</SimulationTd>
                    <SimulationTd>{file.fileName}</SimulationTd>
                    <SimulationTd>
                      <SimulationStatus>
                        {file.getStatus()}
                        {file.executed < file.totalCount && (
                          <>
                            <SimpleLoading size={16} style={{ display: 'inline-block', padding: 8 }} />
                            <IconButton size="small" onClick={handleStop(file)}>
                              <PauseCircleOutlineIcon />
                            </IconButton>
                          </>
                        )}
                      </SimulationStatus>
                    </SimulationTd>
                    <SimulationTd>
                      {file.executed} / {file.totalCount}
                    </SimulationTd>
                    <SimulationTd>{file.updatedAt.format('L LTS')}</SimulationTd>
                    <SimulationTd>
                      <UiButton href={`/god/simulation/${file.id}`} size="small">
                        進捗を確認
                      </UiButton>
                      <UiButton
                        href={`/god/simulate_base_files/${file.id}.csv`}
                        target="_blank"
                        size="small"
                        disabled={file.getStatus() !== '完了'}
                      >
                        CSV
                      </UiButton>
                    </SimulationTd>
                  </Row>
                ))}
              </SimulationTable>
            </div>
          </>
        )}
      </Content>
    </div>
  );
};

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const InputAreaRight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  min-width: 550px;
  gap: 16px;
`;

const UploadForm = styled(Dropzone)`
  width: 150px;
  background-color: #eee;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SimulationTable = styled(StickyTable)`
  margin: 0;
  table-layout: fixed;
  height: 80vh;
`;

const SimulationTh = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
  ${props => props.width && `width: ${props.width}px;`}
`;

const SimulationTd = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;

  .MuiButton-root {
    margin: 0 4px;
  }
`;

const SimulationStatus = styled.div`
  display: flex;
  align-items: center;
`;

export default Simulation;
