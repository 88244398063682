import React from 'react';
import { observer } from 'mobx-react';

import TextArea from 'react-textarea-autosize';
import type TravelAuthorizationElement from '@this/domain/travel_authorization_element';
import type { TravelAuthorizationServiceType } from '@this/domain/travel_authorization_element';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

interface Props {
  element: TravelAuthorizationElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const TravelAuthorizationElementForm = observer(({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`virtual-counte-price-change-form__label ${
          classNameForModifiedField ? classNameForModifiedField('service') : ''
        }`}
      >
        サービス
      </label>
      <select
        value={element.service}
        onChange={e => {
          element.service = e.target.value as TravelAuthorizationServiceType;
        }}
      >
        <option value="">選択なし</option>
        <option value="visa">ビザ</option>
        <option value="esta">ESTA</option>
      </select>
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`virtual-counte-price-change-form__label ${
          classNameForModifiedField ? classNameForModifiedField('content') : ''
        }`}
      >
        内容
        <span style={{ color: 'red' }}>（必須）</span>
      </label>
      <TextArea
        type="text"
        value={element.content}
        onChange={e => {
          element.content = e.target.value;
        }}
        minRows={2}
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('startedAt')}`}>
        利用開始日時
      </label>
      <DatetimePicker
        value={element.startedAt}
        onChange={time => element.setField('startedAt', time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('endedAt')}`}>
        利用終了日時
      </label>
      <DatetimePicker
        value={element.endedAt}
        onChange={time => element.setField('endedAt', time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
  </>
));

export default TravelAuthorizationElementForm;
