import React, { useCallback, useState } from 'react';

import type Trip from '@this/domain/trip/trip';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';
import { Fetcher } from '@this/src/util';

interface Props {
  selectedTrip: Trip | null;
  onClick: (message: string) => void;
}

interface Response {
  message: string | null;
}

export const ProposalReplyButton: React.FC<Props> = ({ selectedTrip, onClick }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    if (!selectedTrip) {
      return;
    }

    setLoading(true);

    const params = { trip_id: selectedTrip.id };
    Fetcher.post<Response>('/arrangement/message_templates/completion', params)
      .then(response => {
        if (response.message) {
          onClick(response.message);
          Notification.success('AIで回答を起案しました。');
        } else {
          Notification.error('AIでの回答が起案できませんでした。');
        }
      })
      .catch(() => {
        Notification.error('AIでの回答の起案に失敗しました。');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedTrip, onClick]);

  return (
    <Button size="small" style={{ marginLeft: 10 }} loading={loading} onClick={handleClick}>
      AIで回答を起案
    </Button>
  );
};
