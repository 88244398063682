import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo, useCallback, useState } from 'react';
import { css } from 'styled-components';
import { media } from '@this/components/shared/atoms/media';
import { Badge, Button, Fade, IconButton, Paper, Popper, Typography } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/TextsmsOutlined';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import Link from '@this/components/shared/atoms/link';
import { styled } from '@this/constants/themes';
import type { TripReport } from '@this/src/domain/trip_report/trip_report';
import type { ExpenseTool } from '@this/src/domain/setting';

type Props = {
  tripReport: TripReport;
  expenseTool?: ExpenseTool;
  message?: string;
  loading?: boolean;
  setCancel?: Dispatch<SetStateAction<number | null>>;
  handleExpensesReport: (tripReport: TripReport, onError?: (errors: string[]) => void) => void;
  linkProps?: Partial<{ target: string }>;
};

const TripReportCard: React.FC<Props> = ({
  tripReport,
  expenseTool,
  message,
  loading,
  setCancel,
  handleExpensesReport,
  linkProps = {},
  children
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [errorEl, setErrorEl] = useState<HTMLButtonElement | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const appliedAt = tripReport.appliedAt();
  const approvedAt = tripReport.approvedAt();
  const href = useMemo(
    () =>
      ['initial', 'draft', 'send_back'].includes(tripReport.status)
        ? `/trip_report/${tripReport.id}/edit`
        : `/trip_report/${tripReport.id}`,
    [tripReport.id, tripReport.status]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const handleExpensesReportClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setErrorEl(errorEl ? null : event.currentTarget);
      if (!errorEl) {
        handleExpensesReport(tripReport, errors => setErrors(errors));
      }
    },
    [errorEl, handleExpensesReport]
  );

  return (
    <Wrap className={message ? 'isMessage' : ''}>
      {message && (
        <MessageWrap>
          <Row>{message}</Row>
        </MessageWrap>
      )}
      <ActionWrap>
        {children}
        <MainWrap>
          <LeftWrap>
            <TitleRow>
              <Title href={href} {...linkProps}>
                {tripReport.name}
              </Title>
              <IconButton onClick={handleClick} disabled={!tripReport.isComment()} size="small">
                <Badge color="error" variant="dot" invisible={!tripReport.isComment()}>
                  {tripReport.isComment() ? <MessageIcon /> : <DisabledMessageIcon />}
                </Badge>
              </IconButton>
              <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="right" transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{ padding: 10 }}>
                      <Typography>{tripReport.newComment()}</Typography>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </TitleRow>
            <Row>
              <StatusChip {...tripReport.statusColor()}>{tripReport.statusName()}</StatusChip>
            </Row>
            <Row>
              <div>
                申請合計：
                <strong>{tripReport.totalAmount()}</strong>
              </div>
              <div>
                申請日：{appliedAt ? <time dateTime={appliedAt.format('L')}>{appliedAt.format('LL')}</time> : '─'}
              </div>
            </Row>
          </LeftWrap>
          <RightWrap>
            <Row>
              <div>
                承認日：
                {approvedAt ? <time dateTime={approvedAt.format('L')}>{approvedAt.format('LL')}</time> : '─'}
              </div>
            </Row>
            <Actions>
              {tripReport.status === 'applying' && setCancel && (
                <ButtonLink to="#" onClick={() => setCancel(tripReport.id)}>
                  申請を取り消す
                </ButtonLink>
              )}
              {expenseTool === 'biztra' &&
                tripReport.status === 'approved' &&
                (tripReport.expensesConverted ? (
                  <NavLink href={`/biztra/report_items?tripReportId=${tripReport.id}`} isExternal {...linkProps}>
                    連携済み経費
                  </NavLink>
                ) : (
                  <>
                    <StyledButton
                      color="primary"
                      disabled={
                        loading || (tripReport.nonOrderItems.length < 1 && tripReport.allowanceItems.length < 1)
                      }
                      onClick={handleExpensesReportClick}
                    >
                      経費を連携する
                    </StyledButton>
                    <Popper
                      open={Boolean(errorEl) && errors.length > 0}
                      anchorEl={errorEl}
                      placement="bottom"
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper style={{ padding: 10 }}>
                            <Error>{errors}</Error>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </>
                ))}
              <ButtonLink to={href} {...linkProps}>
                詳細を見る
              </ButtonLink>
            </Actions>
          </RightWrap>
        </MainWrap>
      </ActionWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  font-size: 14px;
  border-radius: 5px;
  color: ${props => props.theme.grayTextColor};
  box-shadow: 0 0 3px 1px ${props => props.theme.grayBorderColor};

  &.isMessage {
    box-shadow: 0 0 3px 1px ${props => props.theme.accentBorderColor};
  }
`;

const ActionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MainWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  padding: 16px;

  ${media.sp`
    flex-flow: column;
    gap: 8px;
    align-items: flex-start;
  `}
`;

const LeftWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  width: 100%;
`;

const RightWrap = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 8px;
  width: 100%;

  ${media.sp`
    align-items: flex-start;
  `}
`;

const MessageWrap = styled.div`
  padding: 16px;
  border-bottom: solid 1px ${props => props.theme.accentBorderColor};
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  ${media.sp`
    flex-flow: column;
    gap: 8px;
  `}
`;

const TitleRow = styled(Row as any)`
  gap: 8px;

  ${media.sp`
    flex-flow: row;
  `}
`;

const Title = styled(NavLink)`
  font-size: 14px;
  text-decoration: underline;
`;

const StatusChip = styled.div<{ textColor: string; backgroundColor: string }>`
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  color: ${props => props.textColor};
  background-color: ${props => props.backgroundColor};
`;

const DisabledMessageIcon = styled(MessageIcon)`
  fill: ${props => props.theme.grayBorderColor};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${media.sp`
    width: 100%;
    justify-content: center;
  `}
`;

const ButtonBase = css`
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  display: block;
  margin-left: 5px;
  line-height: 12px;
`;

const ButtonLink = styled(Link)`
  ${ButtonBase}
`;

const StyledButton = styled(Button)`
  ${ButtonBase}
`;

const Error = styled(Typography)`
  color: ${props => props.theme.redColor};
`;

export default TripReportCard;
