/**
 * HTTPリクエストを行うためのUtilです。
 */

// eslint-disable-next-line max-classes-per-file
import qs from 'qs';
import { AxiosError } from 'axios';
import { serialize } from 'object-to-formdata';
import { reportError } from '@this/lib/bugsnag';
import Notification from '../../notification';
import { createHttpClient } from './http_client_builder';

export const toParams = (obj: any) => qs.stringify(obj, { arrayFormat: 'brackets' });

export const getParams = () => {
  if (!(window.location && window.location.search)) {
    return {};
  }

  return qs.parse(window.location.search.substring(1)) as unknown as Record<string, any>;
};

export const getParam = (key: string) => {
  return getParams()[key];
};

export const objectToFormData = (obj: any) => serialize(obj, { indices: true });

const ipAddressErrorKey = 'unallowed_ip_address';

/**
 * HTTPリクエスト例外を表すカスタムエラー。
 */
export class HTTPError extends Error {
  constructor(
    message: string,
    public request: { url: string; data?: Record<string, any> },
    public response?: { data: Record<string, any>; status: number }
  ) {
    super(message);

    // エラートラッキンツール上でエラーの概要がわかるように。クエリパラメータは除去し、id部分はグルーピングしやすくするため丸める
    this.name = `${response?.status ?? 'ステータス不明'}(${request.url.split('?')[0].replace(/\d+/g, '{id}')})`;
  }
}

/**
 * HTTPリクエストをするためのクラス。
 *
 * @example
 * HTTPメソッドに対応する関数を呼ぶことでリクエストが可能です
 *
 * ```
 * try {
 *   const res = await await Fetcher.get('/get')<{res: any}>
 *   console.log(res) // Prints response data
 * } catch(e) {
 * 　if(e instanceof HttpError) {
 *     console.log(e.response?.status) // status code
 *     console.log(e.response?.data) // error response data
 *   }
 * }
 * ```
 *
 */
export class Fetcher {
  static client = createHttpClient();

  static async get<T>(url: string, data?: Record<string, any>) {
    const requestUrl = data ? `${url}?${toParams(data)}` : url;
    return this.request<T>({ url: requestUrl, method: 'GET' });
  }

  static async post<T>(url: string, data: Record<string, any>) {
    return this.request<T>({ url, method: 'POST', data });
  }

  static async put<T>(url: string, data: Record<string, any>) {
    return this.request<T>({ url, method: 'PUT', data });
  }

  static async delete<T>(url: string, data?: Record<string, any>) {
    const requestUrl = data ? `${url}?${toParams(data)}` : url;
    return this.request<T>({ url: requestUrl, method: 'DELETE' });
  }

  static async upload<T>(
    url: string,
    data: FormData,
    { method }: { method: 'POST' | 'PUT' | 'PATCH' } = { method: 'POST' }
  ) {
    return this.request<T>({ url, method, data, contentType: 'multipart/form-data' });
  }

  static async request<T>({
    url,
    method,
    data,
    contentType = 'application/json'
  }: {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    data?: Record<string, any>;
    contentType?: string;
  }) {
    try {
      // NOTE: 内部的にはaxiosを使用。Fetcherクラスで隠蔽することで将来的にaxios以外に変更が可能にしています。
      const res = await Fetcher.client(url, {
        method,
        data,
        headers: {
          'Content-Type': contentType
        }
      });

      return res.data as T;
    } catch (e) {
      const response =
        e instanceof AxiosError && e.response ? { data: e.response.data, status: e.response.status } : undefined;
      const message =
        response?.data?.errors?.[0] ??
        response?.data?.[ipAddressErrorKey] ??
        JSON.stringify(response?.data) ??
        e.message ??
        'HTTPリクエストでエラーが発生しました。';

      const error = new HTTPError(message, { url, data }, response);

      // IPアドレス制限
      if (error.response?.data?.[ipAddressErrorKey]) {
        Notification.error(error.response.data[ipAddressErrorKey]);

        // IPアドレス制限かかるとセッション切れるのでリロードさせて、強制的にログイン画面に戻す。
        window.location.reload();
      }

      reportError(error);
      throw error;
    }
  }
}
