interface CancelChargeOriginalMappingArgs {
  id: number;
  cancel_charge_element_id: number;
  cancel_charge_order_item_id: number;
  cancel_charge_trace_id: number;
  original_order_item_id: number;
  original_trace_id: number;
}

interface CancelChargeOriginalMappingMaps {
  id: number;
  cancelChargeElementId: number;
  cancelChargeOrderItemId: number;
  cancelChargeTraceId: number;
  originalOrderItemId: number;
  originalTraceId: number;
}

class CancelChargeOriginalMapping {
  id: number | null = null;

  cancelChargeElementId: number | null = null;

  cancelChargeOrderItemId: number | null = null;

  cancelChargeTraceId: number | null = null;

  originalOrderItemId: number | null = null;

  originalTraceId: number | null = null;

  constructor(args: Partial<CancelChargeOriginalMappingArgs> | Partial<CancelChargeOriginalMappingMaps>) {
    Object.assign(this, utils.snakeToCamelObject(args));
  }

  setOriginalTraceId(traceId: number | null) {
    this.originalTraceId = traceId;
    app.render();
  }

  updateParams() {
    return {
      id: this.id,
      cancel_charge_element_id: this.cancelChargeElementId,
      cancel_charge_order_item_id: this.cancelChargeOrderItemId,
      cancel_charge_trace_id: this.cancelChargeTraceId,
      original_order_item_id: this.originalOrderItemId,
      original_trace_id: this.originalTraceId
    };
  }
}

export default CancelChargeOriginalMapping;
