import type { OrganizationArgs } from '@this/domain/organization/organization2';
import Organization from '@this/domain/organization/organization2';
import BaseAPI from '../base_api';

interface OrganizationsResponse {
  organizations: OrganizationArgs[];
}

export default class OrganizationAPI extends BaseAPI {
  public list(): Promise<Organization[]> {
    return this.get<OrganizationsResponse>(`/organizations.json`).then(res =>
      res.data.organizations.map((raw: OrganizationArgs) => new Organization(raw))
    );
  }
}
