import type { Moment } from 'moment';
import moment from 'moment';

interface OrderItemTodoMessageLogArgs {
  id: number;
  traceId: number;
  arrangerId: number;
  arrangerName: string;
  createdAt: string;
  body: string;
}

class OrderItemTodoMessageLog {
  public id: number;

  public traceId: number;

  public arrangerId: number;

  public arrangerName: string;

  public createdAt: Moment;

  public body: string;

  constructor(args: OrderItemTodoMessageLogArgs) {
    this.id = args.id;
    this.traceId = args.traceId;
    this.arrangerId = args.arrangerId;
    this.arrangerName = args.arrangerName;
    this.createdAt = moment(args.createdAt);
    this.body = args.body;
  }
}

export default OrderItemTodoMessageLog;
