interface HotelReservationJson {
  id: number;
  status: 'enqueued' | 'running' | 'success' | 'fail';
  error?: string;
}

class HotelReservation {
  readonly id: number;

  status: 'enqueued' | 'running' | 'success' | 'fail';

  error: string;

  constructor(args: HotelReservationJson) {
    this.id = args.id;
    this.status = args.status;
    this.error = args.error || '';
  }

  statusDescription(): string {
    return {
      enqueued: '開始中',
      running: '実行中',
      success: '成功',
      fail: '失敗'
    }[this.status];
  }
}

export default HotelReservation;
