import moment from 'moment-timezone';

/* 引数に与えられた日程が今日から６ヶ月以内かを判定する */
export const isWithinSixMonth = (expire: string | null) => {
  if (expire === null) {
    return false;
  }
  const result = expire.match('[0-9]{4}(-|/)[0-9]{1,2}(-|/)[0-9]{1,2}');
  if (result) {
    const expireDate = new Date(expire);
    const sixMonthExpireDate = new Date(expire);
    sixMonthExpireDate.setMonth(expireDate.getMonth() - 6);
    /* 今日よりも期限が後日かつ期限まで６ヶ月以内の時 */
    if (expireDate >= new Date() && sixMonthExpireDate <= new Date()) {
      return true;
    }
  }
  return false;
};

/* 引数に与えられた日程が今日よりも以前の日かを判定する */
export const isOverDate = (expire: string | null) => {
  if (expire === null) {
    return false;
  }
  const result = expire.match('[0-9]{4}(-|/)[0-9]{1,2}(-|/)[0-9]{1,2}');
  if (result) {
    const expireDate = new Date(expire);
    if (expireDate < new Date()) {
      return true;
    }
  }
  return false;
};

/* 引数に与えられた日程が今日から数えた営業日よりも以前の日かを判定する */
// RentalCarLimitType#too_late?と同じ
export const isOverLimitDateHour = (
  actualDays: number,
  hour: number,
  departure: string,
  bookingTooLate: boolean,
  todayHoliday: boolean
) => {
  // eg. ['2022-01-12', '-', '-', index: 0, input: '2022-01-12T11:00', groups: undefined]
  const separatedDepartureArray = departure.match('[0-9]{4}(-|/)[0-9]{1,2}(-|/)[0-9]{1,2}');
  if (separatedDepartureArray) {
    const currentDateTime = moment().tz('Asia/Tokyo').clone();
    const departureDateTime = moment(separatedDepartureArray[0]).tz('Asia/Tokyo').clone();
    let inputLimitDateTime;

    if (bookingTooLate) {
      // 依頼締切時刻以降なら
      // 出発日23:59からactualDays日引く
      inputLimitDateTime = departureDateTime.endOf('day').subtract(actualDays, 'days');
    } else {
      // 依頼締切時刻より前なら
      // 出発日00:00からactualDays日引く
      inputLimitDateTime = departureDateTime.startOf('day').subtract(actualDays, 'days');
    }

    if (!todayHoliday) {
      // 顧客マスタ：土日祝日を営業日としてカウントするON：手動追加した休日ではない時
      // 顧客マスタ：土日祝日を営業日としてカウントするOFF：土曜、日曜、日本の祝日、手動追加した休日ではない時
      inputLimitDateTime = inputLimitDateTime.add(hour, 'hours');
    }

    // inputLimitDateTime < currentDateTime ならtrue：依頼期限超えている
    if (currentDateTime.isAfter(inputLimitDateTime)) {
      return true;
    }
  }
  return false;
};
