import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Box } from '../shared/box_parts';
import type { SelectTransportBoxProps, SelectTransportBoxState } from './transport_box';
import SelectTransportBoxRightInOutlineTemplate from './right/right_in_outline.template';
import SelectTransportBoxRightInListTemplate from './right/right_in_list.template';
import SelectTransportBoxRightInDomesticAirListTemplate from './right/right_in_domestic_air_list.template';
import type SelectStore from '../../../../domain/select_store';

type Props = SelectTransportBoxProps &
  SelectTransportBoxState & {
    handleDomesticAirPriceChange: (index: number) => void;
    handleChange: () => void;
    store: SelectStore;
    direction?: string;
  };

const SelectTransportBoxTemplate: React.SFC<Props> = ({
  key,
  transport,
  inList,
  selected,
  domesticAirPriceIndex,
  loading,
  handleDomesticAirPriceChange,
  handleChange,
  store,
  isRequestForm,
  disabled
}) => (
  <Box selected={selected} key={key}>
    {transport ? (
      <>
        <Left>
          {transport.segments.map((s, i) =>
            s.legs.map((l, j) => (
              <Segment key={`${i}_${j}`}>
                <div className="flex">
                  <Name data-wovn-ignore>
                    {l.airline_name && `${l.airline_name} `}
                    {l.name}
                  </Name>
                  <Image src={s.imagePath()} />
                </div>
                <BoxContent>
                  <BoxTitle>出発</BoxTitle>
                  <Space data-wovn-ignore>{l.from.date}</Space>
                  <Space data-wovn-ignore>{l.from.time}</Space>
                  <Space data-wovn-ignore>{l.from.name}</Space>
                </BoxContent>
                <BoxContent>
                  <BoxTitle>到着</BoxTitle>
                  <Space data-wovn-ignore>{l.to.date}</Space>
                  <Space data-wovn-ignore>{l.to.time}</Space>
                  <Space data-wovn-ignore>{l.to.name}</Space>
                </BoxContent>
                <div data-wovn-ignore className="select-transport-box__left__boarding-arrival">
                  {s.shouldShowBoardingStation() && j === 0 && [`乗車駅：${s.boardingName()}`, <br key={0} />]}
                  {s.shouldShowArrivalStation() && j === s.legs.length - 1 && `降車駅：${s.arrivalName()}`}
                </div>
              </Segment>
            ))
          )}
        </Left>

        {!inList ? (
          <SelectTransportBoxRightInOutlineTemplate
            price={((): number | undefined => {
              // ボタン押下後に呼ばれる。
              if (transport.package && store.result.packageProvider === 'wbf') {
                // パッケージ
                return transport.additionalPrice || 0;
              }
              // 個別
              if (transport.pricesOriginal && transport.pricesOriginal[domesticAirPriceIndex]) {
                // 国内航空券
                return transport.pricesOriginal[domesticAirPriceIndex].price;
              }
              // 新幹線
              return transport.price;
            })()}
            isAddtional={transport.package === true && store.result.packageProvider === 'wbf'}
            priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
            includeTax={transport.includeTax}
            selected={selected}
            onClick={handleChange}
            showsPricePerPersonText={store.result.type !== 'airPackage'}
            showsIncludeTax={store.result.type !== 'airPackage'}
            isRequestForm={isRequestForm || false}
            isRakutenPackage={transport.isRakutenPackage}
            // パッケージで交通機関を再選択中は他の操作をできないように
            disabled={store.result.type === 'airPackage' && store.isCurrentAirlinePackageLoading}
          />
        ) : (
          <>
            {transport.package ? (
              // パッケージ
              <div className="select-transport-box__right-wrapper">
                <SelectTransportBoxRightInListTemplate
                  loading={loading}
                  selected={selected}
                  hovered={transport.hovered}
                  price={store.result.packageProvider === 'wbf' ? transport.additionalPrice || 0 : undefined}
                  isAddtional={store.result.packageProvider === 'wbf'}
                  priceRange={store.result.type === 'airPackage' ? transport.price_range : undefined}
                  showsPricePerPersonText={store.result.type !== 'airPackage'}
                  onClick={handleChange}
                  onTouchStart={transport.handleTouched}
                  onMouseOver={transport.setHovered(true)}
                  onMouseLeave={transport.setHovered(false)}
                  isPackage={transport.package}
                  disabled={disabled}
                />
              </div>
            ) : !transport.airExists() ? (
              // 個別/新幹線
              <div className="select-transport-box__right-wrapper">
                <SelectTransportBoxRightInListTemplate
                  loading={loading}
                  selected={selected}
                  hovered={transport.hovered}
                  price={transport.price}
                  onClick={handleChange}
                  onTouchStart={transport.handleTouched}
                  onMouseOver={transport.setHovered(true)}
                  onMouseLeave={transport.setHovered(false)}
                />
              </div>
            ) : (
              // 個別/航空券 : 変更可、不可 x 席種のボタンを表示
              <SelectTransportBoxDomesticAirRightWrapper>
                {transport.prices &&
                  transport.prices.map((p, i) => (
                    <div key={i} onClick={() => handleDomesticAirPriceChange(i)}>
                      <SelectTransportBoxRightInDomesticAirListTemplate
                        key={i}
                        loading={loading}
                        selected={
                          selected &&
                          transport.pricesOriginal != null &&
                          p === transport.pricesOriginal[domesticAirPriceIndex]
                        }
                        price={p}
                      />
                    </div>
                  ))}
              </SelectTransportBoxDomesticAirRightWrapper>
            )}
          </>
        )}
      </>
    ) : (
      <SimpleLoading />
    )}
  </Box>
);

export const BoxContent = styled.div`
  display: flex;
`;

export const Left = styled.div`
  flex-shrink: 10;
  padding: 5px 5px 5px 10px;
`;

export const Segment = styled.div`
  padding: 5px 0;
`;

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const Image = styled.img`
  height: 20px;
  margin-left: 5px;
`;

export const BoxTitle = styled.div`
  background: #aaa;
  color: white;
  width: 25px;
  line-height: 13px;
  margin-right: 10px;
  height: 14px;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  text-align: center;
`;

export const Space = styled.div`
  margin-right: 10px;
`;

export const SpaceLeft = styled.div`
  margin-left: 20px;
`;

export const TitleSpace = styled.div`
  margin-left: 35px;
  margin-right: 10px;
`;

export const BoxFooter = styled.div<{ selected: boolean }>`
  background: ${props => props.theme.grayBorderColor};
  color: ${props => props.theme.grayTextColor};
  padding: 5px;

  ${props =>
    props.selected &&
    `
    background: ${props.theme.linkColor};
    color: white;
  `};
`;

const SelectTransportBoxDomesticAirRightWrapper = styled.div`
  display: block;
  margin-left: auto;
  min-width: 242px;
  background: #f8f6f1;
  padding: 12px;
`;

export default SelectTransportBoxTemplate;
