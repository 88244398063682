import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';

export const MetaSection = styled.section`
  padding: 10px;
`;

export const MetaSectionPanel = styled.div`
  flex-grow: 1;
  margin: 10px;
  background-color: #fff;

  ${MetaSection} + ${MetaSection} {
    border-top: 5px solid #f7f7f7;
  }
`;

export const MetaSectionTitle = styled.h4`
  color: #7d7d7d;
  margin-bottom: 5px;
  font-weight: normal;
`;

export const MetaSectionFormButton = styled.button.attrs({ type: 'button' })`
  ${ButtonBase};
  width: 80px;
  padding: 6px;
`;

export const MetaSectionFormActions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  ${MetaSectionFormButton} + ${MetaSectionFormButton} {
    margin-left: 5px;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const ShareDetailLinkArea = styled.div`
  font-weight: bold;
  margin-top: -2px;
  margin-left: 32px;
`;

export const ShareDetailArea = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const ShareDetailFlexArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const ShareDetailLabel = styled.p`
  font-weight: bold;
  margin-top: 4px;
  margin-left: 8px;
  width: 60px;
`;

export const ShareDetailIndentedArea = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  width; 200px;
`;

export const ShareDetailSelectArea = styled.div`
  width: 240px;
`;
