import React, { useState } from 'react';
import { observer } from 'mobx-react';
import SelectTab from '../share/SelectTab';
import Total from './total/total';
import Items from './items/items';

const JalTab = {
  TOTAL: '総数',
  ITEMS: '全件'
} as const;
export type JalTabType = keyof typeof JalTab;

const JalBooking = observer(() => {
  const [jalBookingTab, setJalBookingTab] = useState<JalTabType>(
    (utils.getParam('jal_booking_tab') as JalTabType) || 'TOTAL'
  );

  return (
    <>
      <SelectTab tabs={JalTab} currentTab={jalBookingTab} onChange={setJalBookingTab} />
      {jalBookingTab === 'TOTAL' ? <Total /> : <Items />}
    </>
  );
});

export default JalBooking;
