import React, { useCallback } from 'react';
import { styled } from '@this/constants/themes';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { media } from '@this/components/shared/atoms/media';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Text from '@this/components/shared/text/text';
import { Button } from '@this/shared/ui/inputs/button';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import {
  Paper,
  TableBody,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { ApproveItemForm } from '@this/components/trips_management/trip_report/trip_report_form/body/trip_report_main/approve_item_form';
import { BodyTitle, LoadingWrap, Border } from '../../shared/wrappers';

export const TripReportMainForm: React.FC = () => {
  const store = useTripReportStore();
  const { serviceId, loading, errors, trips, setting, allowanceAvailable } = store;
  const tripReport = store.currentTripReport;
  const nonOrderItems = store.optionalNonOrderItems();
  const allowanceItems = store.optionalAllowanceItems();
  const { totalAdvance, totalAllowance, totalAmount } = store.optionalTotalPrice();

  const handleClick = useCallback(() => {
    store.setStep('tripSelect');
  }, [store]);

  return (
    <>
      <BodyTitle>報告する内容を作成します</BodyTitle>
      <TripReportForm>
        {loading ? (
          <LoadingWrap>
            <SimpleLoading />
          </LoadingWrap>
        ) : errors.length > 0 ? (
          <LoadingWrap>
            <Error>{errors.join('\n')}</Error>
          </LoadingWrap>
        ) : (
          <>
            <Section label="申請タイトル" invalid={store.invalid('name') && store.validationErrors.name} required>
              <Input
                type="text"
                value={tripReport.name}
                onChange={e => tripReport.setField('name', e.target.value)}
                placeholder="入力してください"
              />
            </Section>
            <Section label="申請者">
              <Typography>{tripReport.userName}</Typography>
            </Section>
            {setting?.tripReportActivityContent !== 'hidden' && (
              <Section
                label="活動内容"
                invalid={store.invalid('activityContent') && store.validationErrors.activityContent}
                required={setting?.tripReportActivityContent === 'mandatory'}
              >
                <Textarea
                  value={tripReport.activityContent}
                  onChange={e => tripReport.setField('activityContent', e.target.value)}
                  placeholder="入力してください"
                  rows={3}
                />
              </Section>
            )}
            {setting?.tripReportResultsContent !== 'hidden' && (
              <Section
                label="成果内容"
                invalid={store.invalid('resultsContent') && store.validationErrors.resultsContent}
                required={setting?.tripReportResultsContent === 'mandatory'}
              >
                <Textarea
                  value={tripReport.resultsContent}
                  onChange={e => tripReport.setField('resultsContent', e.target.value)}
                  placeholder="入力してください"
                  rows={3}
                />
              </Section>
            )}
            {setting?.tripReportOtherText !== 'hidden' && (
              <Section
                label="その他"
                invalid={store.invalid('otherText') && store.validationErrors.otherText}
                required={setting?.tripReportOtherText === 'mandatory'}
              >
                <Textarea
                  value={tripReport.otherText}
                  onChange={e => tripReport.setField('otherText', e.target.value)}
                  placeholder="入力してください"
                  rows={3}
                />
              </Section>
            )}
            <ApproveItemForm />
          </>
        )}
      </TripReportForm>
      <Border />
      <BodyTitle>報告する旅程を入力してください</BodyTitle>
      {loading ? (
        <LoadingWrap>
          <SimpleLoading />
        </LoadingWrap>
      ) : (
        <TableContainer component={Paper}>
          <StyledTable>
            <StyledTableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>旅程番号</TableCell>
                <TableCell>出張期間</TableCell>
                <TableCell>出発地</TableCell>
                <TableCell>目的地</TableCell>
                <TableCell>出張先</TableCell>
                <TableCell>システム手配金額</TableCell>
                <TableCell>{serviceId !== 2 ? '立替経費' : '自己手配経費'}</TableCell>
                {allowanceAvailable && <TableCell>日当</TableCell>}
                <TableCell>合計金額</TableCell>
                <TableCell>操作</TableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {trips.map((trip, i) => {
                const { totalPrice, totalAdvance, totalAllowance, totalAmount } = store.tripPrices(trip.id);
                const useSub = Boolean(trip.purpose);

                return (
                  <React.Fragment key={i}>
                    <MainTableRow useSub={useSub}>
                      <TableCell rowSpan={useSub ? 2 : 1}>{i + 1}</TableCell>
                      <TableCell>
                        <div>旅程#{trip.id}</div>
                        {trip.approvalChannelId && (
                          <div>
                            申請番号
                            <NavLink href={`/application/approved/${trip.approvalChannelId}`} target="_blank">
                              #{trip.approvalChannelId}
                            </NavLink>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <label>出張期間</label>
                        <span>{trip.getPeriod()}</span>
                      </TableCell>
                      <TableCell>
                        <label>出発地</label>
                        <span>{trip.from}</span>
                      </TableCell>
                      <TableCell>
                        <label>目的地</label>
                        <span>{trip.to}</span>
                      </TableCell>
                      <TableCell>
                        <label>出張先</label>
                        <span>{trip.finalDestination}</span>
                      </TableCell>
                      <TableCell>
                        <label>システム手配金額</label>
                        <span>{totalPrice > 0 ? `${totalPrice.toLocaleString()}円` : '─'}</span>
                      </TableCell>
                      <TableCell>
                        <label>{serviceId !== 2 ? '立替金額' : '自己手配金額'}</label>
                        <span>{totalAdvance > 0 ? `${totalAdvance.toLocaleString()}円` : '入力なし'}</span>
                      </TableCell>
                      {allowanceAvailable && (
                        <TableCell>
                          <label>日当</label>
                          <span>{totalAllowance > 0 ? `${totalAllowance.toLocaleString()}円` : '入力なし'}</span>
                        </TableCell>
                      )}
                      <TableCell>
                        <label>合計金額</label>
                        <span>{totalAmount > 0 ? `${totalAmount.toLocaleString()}円` : '─'}</span>
                      </TableCell>
                      {trip.purpose && (
                        <SpTableCell>
                          <label>出張の目的</label>
                          <span>{trip.purpose}</span>
                        </SpTableCell>
                      )}
                      <TableCell className="operation" rowSpan={useSub ? 2 : 1}>
                        <Button size="small" onClick={() => store.setEditing(trip.id)}>
                          編集
                        </Button>
                        <RemoveButton color="primary" onClick={() => store.removeTrip(trip.id)}>
                          <RemoveIcon />
                        </RemoveButton>
                      </TableCell>
                    </MainTableRow>
                    {useSub && (
                      <SubTableRow>
                        <TableCell colSpan={10}>出張目的：{trip.purpose}</TableCell>
                      </SubTableRow>
                    )}
                  </React.Fragment>
                );
              })}
              {(nonOrderItems.length > 0 || allowanceItems.length > 0) && (
                <TableRow>
                  <TableCell>{trips.length + 1}</TableCell>
                  <TableCell colSpan={6}>
                    {serviceId !== 2 ? 'その他の立替経費' : 'その他の自己手配経費'}
                  </TableCell>
                  <TableCell>
                    <label>{serviceId !== 2 ? '立替金額' : '自己手配金額'}</label>
                    <span>{totalAdvance > 0 ? `${totalAdvance.toLocaleString()}円` : '入力なし'}</span>
                  </TableCell>
                  {allowanceAvailable && (
                    <TableCell>
                      <label>日当</label>
                      <span>{totalAllowance > 0 ? `${totalAllowance.toLocaleString()}円` : '入力なし'}</span>
                    </TableCell>
                  )}
                  <TableCell>
                    <label>合計金額</label>
                    <span>{totalAmount > 0 ? `${totalAmount.toLocaleString()}円` : '─'}</span>
                  </TableCell>
                  <TableCell className="operation">
                    <Button size="small" onClick={() => store.setItemEditing(true, { changeStep: true })}>
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>{trips.length + 1 + (nonOrderItems.length > 0 ? 1 : 0)}</TableCell>
                <TableCell colSpan={10}>
                  <Button startIcon={<AddIcon />} onClick={handleClick}>
                    報告する出張を選択する
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
    </>
  );
};

type SectionProps = {
  label: string;
  invalid?: string | false;
  required?: boolean;
};

const Section: React.FC<SectionProps> = ({ label, invalid, required, children }) => (
  <SectionWrap>
    <SectionIn>
      <InputArea>
        <Label>
          {label}
          {required && <span className="required">*</span>}
        </Label>
        <InputAreaRight>{children}</InputAreaRight>
        {invalid && (
          <Errors>
            <Error>
              <Text text={invalid} />
            </Error>
          </Errors>
        )}
      </InputArea>
    </SectionIn>
  </SectionWrap>
);

const TripReportForm = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
`;

const Errors = styled.div`
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SectionWrap = styled.div``;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const InputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Label = styled.label`
  width: 150px;
  line-height: 34px;

  .required {
    margin-left: 4px;
    font-weight: bold;
  }
`;

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 600px;
  align-items: center;
`;

const Input = styled.input`
  &&& {
    max-width: 600px;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const Textarea = styled.textarea`
  &&& {
    max-width: 600px;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const StyledTable = styled(Table)`
  .MuiTableCell-root label {
    display: none;
  }

  ${media.sp`
    display: block;

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-flow: column;
    }

    .MuiTableRow-root {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      padding: 16px;
      gap: 8px 16px;
      border-bottom: solid 1px #eee;
    }

    .MuiTableCell-root {
      display: flex;
      align-items: baseline;
      gap: 8px;
      border: 0;
      padding: 0;
      width: 100%;

      &:nth-child(-n+2) {
        width: auto;
      }

      &.operation {
        padding-top: 8px;
        border-top: solid 1px #eee;
      }

      label {
        display: block;
        width: 70px;
        min-width: 70px;
      }
    }
  `}
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.grayBgColorLight};

  .MuiTableCell {
    &-head {
      padding: 8px 16px;
    }
  }

  ${media.sp`
    display: none;
  `}
`;

const SpTableCell = styled(TableCell)`
  display: none;

  ${media.sp`
    display: flex;
  `}
`;

const MainTableRow = styled(TableRow)<{ useSub: boolean }>`
  ${props =>
    props.useSub &&
    `
    .MuiTableCell-root:not(:first-child):not(:last-child) {
      border-bottom: 0;
      padding-bottom: 4px;
    }
  `}
`;

const SubTableRow = styled(TableRow)`
  .MuiTableCell-root {
    padding-top: 4px;
  }
`;

const RemoveButton = styled(IconButton)`
  padding: 8px;
`;
