import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';

export const Body = styled.div``;

export const Text = styled.p`
  text-align: center;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;

  input:first-child {
    margin-right: 10px;
  }
`;

export const Button = styled.input`
  &&& {
    ${ButtonBase};
    width: auto;
    padding: 0.75em 1em;
  }
`;
