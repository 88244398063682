import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import A from '@this/components/shared/atoms/a';
import TripList from '@this/domain/trip/trip_list';
import ProjectList from '@this/domain/project/project_list';
import DepartmentList from '@this/domain/department/department_list';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { FilterParams } from '@this/components/organization/trips/filter/filter';

import type { UpdationTripParams, UpdationTripApproveItemParams } from '@this/components/organization/trips/types';
import type Trip from '@this/domain/trip/trip';
import type { ProjectShareArgs } from '@this/domain/project/project_share';
import ProjectShareList from '@this/domain/project/project_share_list';
import type { ChargingDepartmentShareArgs } from '@this/domain/department/charging_department_share';
import type Organization from '@this/domain/organization/organization2';
import ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import TripsMap from '@this/components/organization/trips/trips_map';
import { TripCsvDownloadButton } from '@this/components/organization/trips/trip_csv_download_button';
import { OrganizationTitle } from '@this/components/organization/organization.style';
import ExicSeisanTable from '@this/components/organization/trips/exic_seisan_table/exic_seisan_table';
import Pagination from '@this/shared/pagination/pagination';
import moment from 'moment-timezone';
import type { SearchType } from './filter/filter';
import Filter from './filter/filter';
import Table from './table/table';
import Notification from '../../../notification';

// 負荷軽減のために `in_advance_approval` は返していないので型として明示しておく
// ただしもともと型検査されていなかったのでTripの他のフィールドがAPIから返っている保証はない
export type TripSummary = Exclude<Trip, 'in_advance_approval'>;

interface TripsResponse<T extends Trip | TripSummary> {
  is_internal_number_required: boolean;
  is_project_name_required: boolean;
  show_fee: boolean;
  departments: object[];
  projects: object[];
  project_shares: ProjectShareArgs[];
  charging_department_shares: ChargingDepartmentShareArgs[];
  project_share_availability: boolean;
  restriction_distance_and_time_availability: boolean;
  trips: T[];
  total_page: number;
  total_count: number;
  expenses_account_type_available: boolean;
  expenses_account_types: ExpensesAccountTypeJson[];
}

interface TripResponse<T extends Trip | TripSummary> {
  is_internal_number_required: boolean;
  is_project_name_required: boolean;
  trips: T[];
}

interface Props {
  serviceId: number;
  availableOptions: string[];
  organization?: Organization;
}

interface State {
  loading: boolean;
  trips: TripList<TripSummary>;
  projects: ProjectList;
  projectShares: ProjectShareList;
  projectShareAvailability: boolean;
  departments: DepartmentList;
  expensesAccountTypes: ExpensesAccountType[];
  expensesAccountTypeAvailable: boolean;
  chargingDepartmentShares: ChargingDepartmentShareList;
  serviceId: number;
  tripReportAvailable: boolean;
  filterParams: FilterParams;
  isShownMap: boolean;
  currentPage: number;
  totalPage: number;
  totalCount: number;
  searchType: SearchType;
}

const DATE_FORMAT = 'YYYY-MM-DD';

class Trips extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const params = Trips.searchParams();
    this.state = {
      loading: true,
      isShownMap: false,
      trips: new TripList(),
      projects: new ProjectList([]),
      projectShares: new ProjectShareList([]),
      projectShareAvailability: false,
      departments: new DepartmentList([], undefined),
      expensesAccountTypes: [],
      expensesAccountTypeAvailable: false,
      chargingDepartmentShares: new ChargingDepartmentShareList([]),
      serviceId: this.props.serviceId,
      tripReportAvailable: this.props.availableOptions.includes('trip_report'),
      currentPage: 0,
      totalPage: 0,
      totalCount: 0,
      filterParams: {
        fromDate: params.fromDate,
        toDate: params.toDate,
        projectId: params.projectId,
        userName: params.userName,
        travelerName: params.travelerName,
        departmentId: params.departmentId,
        dateType: params.dateType,
        inAdvanced: params.inAdvanced,
        arranged: params.arranged,
        fixed: params.fixed,
        canceled: params.canceled,
        foreign: params.foreign,
        tripReport: params.tripReport,
        orderType: params.orderType,
        searchType: params.searchType,
        tripId: params.tripId
      },
      searchType: params.searchType
    };

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.fetchTrips();
  }

  static searchParams() {
    const params = utils.getParams();
    const fromStr = utils.dig(params, 'from');
    const from = fromStr ? moment(fromStr) : moment().subtract(1, 'month');
    const toStr = utils.dig(params, 'to');
    const to = toStr ? moment(toStr) : moment();

    const projectId = utils.dig(params, 'project');
    const userName = utils.dig(params, 'user');
    const travelerName = utils.dig(params, 'traveler');
    const departmentId = utils.dig(params, 'department');
    const dateType = utils.dig(params, 'dateType');
    const inAdvanced = utils.dig(params, 'in_advanced');
    const arranged = utils.dig(params, 'arranged');
    const fixed = utils.dig(params, 'fixed');
    const canceled = utils.dig(params, 'canceled');
    const foreign = utils.dig(params, 'foreign');
    const tripReport = utils.dig(params, 'tripReport');
    const orderType = utils.dig(params, 'orderType');
    const searchType = utils.dig(params, 'search_type');
    const tripId = utils.dig(params, 'trip_id');

    return {
      fromDate: from,
      toDate: to,
      projectId: projectId || '',
      userName: userName || '',
      travelerName: travelerName || '',
      departmentId: departmentId || '',
      dateType: dateType || 'travel',
      inAdvanced: Boolean(inAdvanced) || true,
      arranged: Boolean(arranged) || true,
      fixed: Boolean(fixed) || true,
      canceled: Boolean(canceled) || true,
      foreign: foreign || '',
      tripReport: tripReport || '',
      orderType: orderType || '',
      searchType: searchType || 'status',
      tripId: tripId || ''
    };
  }

  search(filterParams: FilterParams) {
    const q = {
      dateType: filterParams.dateType,
      from: filterParams.fromDate.format(DATE_FORMAT),
      to: filterParams.toDate.format(DATE_FORMAT),
      project: filterParams.projectId,
      user: filterParams.userName,
      traveler: filterParams.travelerName,
      department: filterParams.departmentId,
      inAdvanced: filterParams.inAdvanced,
      arranged: filterParams.arranged,
      fixed: filterParams.fixed,
      canceled: filterParams.canceled,
      foreign: filterParams.foreign,
      tripReport: filterParams.tripReport,
      orderType: filterParams.orderType,
      search_type: filterParams.searchType,
      trip_id: filterParams.tripId
    };

    const queries = _.join(
      _.map(q, (v, k) => `${k}=${v}`),
      '&'
    );
    window.history.pushState({}, '', `${location.pathname}?${queries}`);

    this.setState({ filterParams }, () => this.fetchTrips());
  }

  fetchTrips(page = 1) {
    this.setState({ loading: true });
    utils
      .jsonPromise<TripsResponse<TripSummary>>('/organization/trips.json', this.tripsQuery(page))
      .then(result => {
        // 分析目的のため設定に関わらず手数料は見せる
        const showFee = true;
        const isInternalNumberRequired = result.is_internal_number_required;
        const isProjectNameRequired = result.is_project_name_required;
        this.setState({
          loading: false,
          trips: new TripList<TripSummary>(result.trips, {
            showFee,
            isInternalNumberRequired,
            isProjectNameRequired
          }),
          projects: new ProjectList(result.projects),
          projectShares: new ProjectShareList(result.project_shares),
          projectShareAvailability: result.project_share_availability,
          departments: new DepartmentList(result.departments, undefined),
          chargingDepartmentShares: new ChargingDepartmentShareList(result.charging_department_shares),
          currentPage: page,
          totalPage: result.total_page,
          totalCount: result.total_count,
          expensesAccountTypes: result.expenses_account_types.map(account => new ExpensesAccountType(account)),
          expensesAccountTypeAvailable: result.expenses_account_type_available
        });
      });
  }

  tripsQuery(page = 1) {
    const { tripReportAvailable } = this.state;
    const {
      fromDate,
      toDate,
      projectId,
      userName,
      travelerName,
      departmentId,
      dateType,
      inAdvanced,
      arranged,
      fixed,
      canceled,
      foreign,
      tripReport,
      orderType,
      searchType,
      tripId
    } = this.state.filterParams;

    return {
      from: fromDate.format(DATE_FORMAT),
      to: toDate.format(DATE_FORMAT),
      project_id: projectId,
      user_name: userName,
      traveler_name: travelerName,
      department_id: departmentId,
      date_type: dateType,
      in_advanced: inAdvanced,
      arranged,
      fixed,
      canceled,
      foreign,
      trip_report: tripReportAvailable ? tripReport : '',
      order_type: orderType,
      search_type: searchType,
      trip_id: tripId,
      page
    };
  }

  fetchTrip = async (id: number) => {
    return utils.jsonPromise<TripResponse<Trip>>(`/organization/trips/${id}.json`).then(result => {
      if (result.trips.length < 1) return false;

      const showFee = true;
      const isInternalNumberRequired = result.is_internal_number_required;
      const isProjectNameRequired = result.is_project_name_required;
      this.state.trips.update(result.trips[0], {
        showFee,
        isInternalNumberRequired,
        isProjectNameRequired
      });

      return true;
    });
  };

  updateTrip = async (id: Trip['id'], params: UpdationTripParams) => {
    const trip = this.state.trips.list.find(t => t.id === id);
    if (!trip) {
      return;
    }

    try {
      await utils.jsonPromise(`/organization/trips/${id}`, params, 'PUT');

      trip.setProject(this.state.projects.list.find(p => p.id === params.project_id));
      trip.setDepartment(this.state.departments.list.find(d => d.id === params.department_id));
      trip.setInternalNumber(params.internal_number || '');
      trip.setFinalDestination(params.final_destination || '');
      trip.setPurpose(params.purpose || '');
    } catch (e) {
      Notification.error(e.responseJSON.errors);
      throw e;
    }
  };

  updateTripApproveItems = async (id: Trip['id'], params: UpdationTripApproveItemParams) => {
    const trip = this.state.trips.list.find(t => t.id === id);
    if (!trip) {
      return;
    }

    try {
      await utils.jsonPromise(`/organization/trips/${id}/approve_items`, params, 'PUT');
    } catch (e) {
      Notification.error(e.responseJSON.errors);
      throw e;
    }
  };

  toggleMap = () => {
    this.setState({ isShownMap: !this.state.isShownMap });
  };

  handleChangeSearchType = (searchType: SearchType) => {
    this.setState({ searchType });
  };

  render() {
    try {
      const {
        loading,
        trips,
        projects,
        projectShares,
        projectShareAvailability,
        departments,
        chargingDepartmentShares,
        serviceId,
        tripReportAvailable,
        filterParams,
        isShownMap,
        currentPage,
        totalPage,
        totalCount,
        expensesAccountTypes,
        expensesAccountTypeAvailable
      } = this.state;

      const { organization } = this.props;

      return (
        <>
          <OrganizationTitle>出張者情報・旅程一覧</OrganizationTitle>
          <div className="organization-trips">
            <Filter
              departments={departments}
              projects={projects}
              filterParams={filterParams}
              search={this.search}
              serviceId={serviceId}
              organization={organization}
              tripReportAvailable={tripReportAvailable}
              onChangeSearchType={this.handleChangeSearchType}
            />
            {this.state.searchType === 'exic_seisan' ? (
              <ExicSeisanTable serviceId={serviceId} />
            ) : (
              <>
                <SpaceBetween>
                  <TripsSummaryArea>
                    <TripsSummary>検索結果: {totalCount} 件</TripsSummary>
                  </TripsSummaryArea>
                  <CsvDownloadArea>
                    <TripCsvDownloadButton serviceId={serviceId} params={filterParams} />
                  </CsvDownloadArea>
                </SpaceBetween>
                <LinkButtonArea>
                  <A onClick={this.toggleMap}>{isShownMap ? '旅程マップを非表示 ▼' : '旅程マップを表示 ▶'}</A>
                </LinkButtonArea>
                {isShownMap && (
                  <MapArea>
                    <TripsMap trips={trips.list} />
                  </MapArea>
                )}
                <Table
                  serviceId={serviceId}
                  trips={trips.list}
                  projects={projects.list}
                  departments={departments.list}
                  expensesAccountTypes={expensesAccountTypes}
                  expensesAccountTypeAvailable={expensesAccountTypeAvailable}
                  tripReportAvailable={tripReportAvailable}
                  loading={loading}
                  fetchTrip={this.fetchTrip}
                  onUpdateTrip={this.updateTrip}
                  onUpdateTripApproveItems={this.updateTripApproveItems}
                  projectShares={projectShares}
                  projectShareAvailability={projectShareAvailability}
                  chargingDepartmentShares={chargingDepartmentShares}
                />
                {totalPage > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPage={totalPage}
                    handleSearch={page => this.fetchTrips(page)}
                  />
                )}
              </>
            )}
          </div>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const TripsSummaryArea = styled.div`
  display: flex;
`;

const TripsSummary = styled.p`
  font-weight: bold;
  margin-right: 10px;
`;

const LinkButtonArea = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
`;

const MapArea = styled.div`
  height: calc(100vh - 510px);
  padding: 0 10px 10px 10px;
`;

const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const CsvDownloadArea = styled.div``;

export default Trips;
