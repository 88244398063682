import React, { useState, useCallback, useEffect, createRef } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import useApi from '@this/shared/hooks/use_api';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import A from '@this/shared/atoms/a';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import type { DataProviderDomesticAirRouteJson } from '@this/domain/data_provider_domestic_air_route';
import DataProviderDomesticAirRoute from '@this/domain/data_provider_domestic_air_route';
import type { DataProviderDomesticAirUsageResponseArgs } from '@this/domain/data_provider_domestic_air_usage';
import DataProviderDomesticAirUsage, {
  convertDataproviderDomesticAirUsageRespnseToArgs
} from '@this/domain/data_provider_domestic_air_usage';
import type { Moment } from 'moment';
import moment from 'moment';
import { Fetcher, HTTPError } from '@this/src/util';
import { Title, Content } from '../god';

interface Record {
  id: number;
  startDay: number;
  rangeDay: number;
  spanHour: number;
  lastExecuted: Moment;
}

interface RecordResponse {
  id: number;
  start_day: number;
  range_day: number;
  span_hour: number;
  last_executed: Moment;
}

interface DomesticAirDataResponse {
  records: RecordResponse[];
  ttl: { [service_id: number]: number };
}

interface DomesticAirCache {
  id: number;
  from: string;
  to: string;
  flightName: string;
  price: number;
  ticketType: string;
  targetDate: string;
  beforeDate: number;
  createdAt: string;
}

interface DomesticAirCacheResponse {
  id: number;
  from: string;
  to: string;
  flight_name: string;
  price: number;
  target_date: string;
  ticket_type: string;
  before_date: number;
  created_at: string;
}
interface DomesticAirCachesResponse {
  caches: DomesticAirCacheResponse[];
}

interface DataProviderDomesticAirRouteResponse {
  route: DataProviderDomesticAirRouteJson;
}

const OldVersionTable = () => {
  const domesticAirDataResponse = useApi<DomesticAirDataResponse>('/god/domestic_air.json');
  const data = domesticAirDataResponse.data;
  const globalResTtl = data && data.ttl[0] ? data.ttl[0] : 0;
  const aiTravelResTtl = data && data.ttl[1] ? data.ttl[1] : 0;
  const isScheduleLoading = domesticAirDataResponse.isLoading;
  const [isCacheLoading, setIsCacheLoading] = useState<boolean>(false);
  const [editSchedule, setEditSchedule] = useState<Record | null>(null);
  const [cacheSearchDate, setCacheSearchDate] = useState(moment().startOf('month'));
  const [cacheSearchType, setCacheSearchType] = useState('ticketType');
  const [cacheSearchDayType, setCacheSearchDayType] = useState('search_day');

  const [usageCountFrom, setUsageCountFrom] = useState(moment().startOf('month'));
  const [usageCountTo, setUsageCountTo] = useState(moment().endOf('month'));

  const [isUsageCountLoading, setIsUsageCountLoading] = useState<boolean>(true);
  const [usageCount, setUsageCount] = useState<DataProviderDomesticAirUsage | null>(null);

  const [fromAirport, setFromAirport] = useState<string>('');
  const [toAirport, setToAirport] = useState<string>('');
  const [caches, setCaches] = useState<DomesticAirCache[]>([]);
  const [globalCacheTtl, setGlobalCacheTtl] = useState<number>(0);
  const [aiTravelCacheTtl, setAiTravelCacheTtl] = useState<number>(0);
  const [route, setRoute] = useState<DataProviderDomesticAirRoute | null>(null);
  const [routeError, setRouteError] = useState<string | null>(null);

  const ref = createRef<HTMLTableElement>();
  const copyCacheSearchResultToClipBoard = () => {
    utils.copyElementToClipBoard(ref.current, false);
  };

  const records: Record[] = data
    ? data.records.map(raw => {
        return {
          id: raw.id,
          startDay: raw.start_day,
          rangeDay: raw.range_day,
          spanHour: raw.span_hour,
          lastExecuted: raw.last_executed
        };
      })
    : [];

  const fetchDataProviderDomesticAirRoute = async () => {
    const response = await Fetcher.get<DataProviderDomesticAirRouteResponse>(
      '/god/data_provider_domestic_air_route.json'
    );
    setRoute(new DataProviderDomesticAirRoute(response.route));
  };

  const fetchDataProviderDomesticAirUsage = async () => {
    setIsUsageCountLoading(true);
    const usageCountParams = {
      from: usageCountFrom.format('YYYY-MM-DD'),
      to: usageCountTo.format('YYYY-MM-DD')
    };
    const usageCountResponse = await Fetcher.get<DataProviderDomesticAirUsageResponseArgs>(
      '/god/domestic_air_cache/usage.json',
      usageCountParams
    );
    setIsUsageCountLoading(false);
    setUsageCount(
      new DataProviderDomesticAirUsage(convertDataproviderDomesticAirUsageRespnseToArgs(usageCountResponse))
    );
  };

  useEffect(() => {
    fetchDataProviderDomesticAirRoute();
    fetchDataProviderDomesticAirUsage();
  }, []);

  const updateCacheTtl = useCallback(async (service_id, ttl) => {
    const params = {
      service_id,
      ttl
    };

    await Fetcher.put('/god/domestic_air_cache.json', params);
  }, []);

  const handleRangeDay = (value: string) => {
    if (editSchedule) {
      const newSchedule = { ...editSchedule };
      newSchedule.rangeDay = parseInt(value, 10) || 0;
      setEditSchedule(newSchedule);
    }
  };

  const handleStartDay = (value: string) => {
    if (editSchedule) {
      const newSchedule = { ...editSchedule };
      newSchedule.startDay = parseInt(value, 10) || 0;
      setEditSchedule(newSchedule);
    }
  };

  const handleSpanHour = (value: string) => {
    if (editSchedule) {
      const newSchedule = { ...editSchedule };
      newSchedule.spanHour = parseInt(value, 10) || 0;
      setEditSchedule(newSchedule);
    }
  };

  const submitEditSchedule = useCallback(async () => {
    await Fetcher.put('/god/domestic_air.json', editSchedule ?? {});
    location.reload();
  }, [editSchedule]);

  const searchCache = useCallback(async () => {
    setIsCacheLoading(true);
    const params = {
      from_airport: fromAirport,
      to_airport: toAirport,
      cache_search_date: cacheSearchDate.format('YYYY-MM-DD'),
      cache_search_type: cacheSearchType,
      cache_search_day_type: cacheSearchDayType
    };
    const data = await Fetcher.get<DomesticAirCachesResponse>('/god/domestic_air_cache.json', params);
    const domesticAirCaches: DomesticAirCache[] = data.caches.map(c => {
      return {
        id: c.id,
        from: c.from,
        to: c.to,
        flightName: c.flight_name,
        price: c.price,
        ticketType: c.ticket_type,
        targetDate: c.target_date,
        beforeDate: c.before_date,
        createdAt: c.created_at
      };
    });
    setCaches(domesticAirCaches);
    setIsCacheLoading(false);
  }, [fromAirport, toAirport, cacheSearchDate, cacheSearchType, cacheSearchDayType]);

  const deleteAllCache = useCallback(async () => {
    if (confirm('国内航空券のキャッシュデータをすべて削除しますか？')) {
      await Fetcher.delete('/god/domestic_air_cache.json', {});
    }
  }, []);

  const submitRoute = async () => {
    setRouteError(null);
    try {
      await Fetcher.put('/god/data_provider_domestic_air_route.json', route!.submitParams());
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
        setRouteError(e.response.data.errors.join('\n'));
      } else {
        utils.sendErrorObject(e);
      }
    }
  };

  return (
    <div>
      <Title>旧：国内航空券データ取得</Title>
      <Content>
        {isScheduleLoading ? (
          <SimpleLoading />
        ) : (
          <>
            <h3>データ提供型クロール制御</h3>
            <Table>
              <thead>
                <tr>
                  <Th>取得開始日(登録日 + n day)</Th>
                  <Th>連続取得日数(day)</Th>
                  <Th>取得停止期間(hour)</Th>
                  <Th>最終実行日時</Th>
                  <Th>編集</Th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, i) =>
                  editSchedule && editSchedule.id === record.id ? (
                    <tr key={i}>
                      <Td>
                        <input
                          type="text"
                          value={editSchedule.startDay}
                          onChange={e => handleStartDay(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          value={editSchedule.rangeDay}
                          onChange={e => handleRangeDay(e.target.value)}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          value={editSchedule.spanHour}
                          onChange={e => handleSpanHour(e.target.value)}
                        />
                      </Td>
                      <Td>{record.lastExecuted}</Td>
                      <Td>
                        <A onClick={() => submitEditSchedule()}>保存</A>
                        <A onClick={() => setEditSchedule(null)}>戻る</A>
                      </Td>
                    </tr>
                  ) : (
                    <tr key={i}>
                      <Td>{record.startDay}</Td>
                      <Td>{record.rangeDay}</Td>
                      <Td>{record.spanHour}</Td>
                      <Td>{record.lastExecuted}</Td>
                      <Td>
                        <A onClick={() => setEditSchedule(record)}>編集</A>
                      </Td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            {route && (
              <RouteArea>
                <h4>取得航路設定</h4>
                <Flex>
                  <Label>csv</Label>
                  <Textarea
                    rows={15}
                    value={route.csv}
                    onChange={e => {
                      route.csv = e.target.value;
                    }}
                  />
                </Flex>
                <button type="submit" onClick={() => submitRoute()}>
                  編集
                </button>
                {routeError && <p className="error">{routeError}</p>}
              </RouteArea>
            )}
            <h3>
              航空券マーケットデータ(
              <a onClick={() => copyCacheSearchResultToClipBoard()}>結果をクリップボードにコピー</a>)
            </h3>
            <CacheWrapper>
              <Flex>
                <Label>検索種別</Label>
                <SearchCacheType>
                  <input
                    id="cache_search_type_ticket_type"
                    type="radio"
                    value="ticketType"
                    checked={cacheSearchType === 'ticketType'}
                    onChange={e => setCacheSearchType(e.target.value)}
                  />
                  券種(変更可/不可)ごとの最安値
                </SearchCacheType>
                <Label>出発空港</Label>
                <Input type="text" onChange={e => setFromAirport(e.target.value)} />
                <Label>到着空港</Label>
                <Input type="text" onChange={e => setToAirport(e.target.value)} />
                <SearchCacheDayType>
                  <input
                    id="cache_search_search_day"
                    type="radio"
                    value="search_day"
                    checked={cacheSearchDayType === 'search_day'}
                    onChange={e => setCacheSearchDayType(e.target.value)}
                  />
                  検索日
                  <input
                    id="cache_search_start_day"
                    type="radio"
                    value="start_day"
                    checked={cacheSearchDayType === 'start_day'}
                    onChange={e => setCacheSearchDayType(e.target.value)}
                  />
                  出発日
                </SearchCacheDayType>
                <DatetimePicker
                  value={cacheSearchDate}
                  onChange={d => setCacheSearchDate(d)}
                  showTime={false}
                  disabledDays={0}
                  showPast
                  border
                />
                <TheButton onClick={() => searchCache()}>検索</TheButton>
              </Flex>
              {isCacheLoading ? (
                <SimpleLoading />
              ) : (
                <Table ref={ref}>
                  <thead>
                    <tr>
                      <CenterAlignTh>出発空港</CenterAlignTh>
                      <CenterAlignTh>到着空港</CenterAlignTh>
                      <CenterAlignTh>出発日</CenterAlignTh>
                      <CenterAlignTh>便名</CenterAlignTh>
                      <CenterAlignTh>券種</CenterAlignTh>
                      <CenterAlignTh>金額</CenterAlignTh>
                      <CenterAlignTh>検索日</CenterAlignTh>
                      <CenterAlignTh>出発日までのリードタイム日数</CenterAlignTh>
                    </tr>
                  </thead>
                  <tbody>
                    {caches.map((cache, i) => (
                      <tr key={i}>
                        <RightAlignTd>{cache.from}</RightAlignTd>
                        <RightAlignTd>{cache.to}</RightAlignTd>
                        <RightAlignTd>{cache.targetDate}</RightAlignTd>
                        <RightAlignTd>{cache.flightName}</RightAlignTd>
                        <RightAlignTd>{cache.ticketType}</RightAlignTd>
                        <RightAlignTd>{cache.price}</RightAlignTd>
                        <RightAlignTd>{cache.createdAt}</RightAlignTd>
                        <RightAlignTd>{cache.beforeDate}</RightAlignTd>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CacheWrapper>
            <h3>API提供型キャッシュ制御 (データの有効期限を秒単位で設定できます)</h3>
            <Flex>
              <Label>グローバル キャッシュ有効期限</Label>
              <Input
                type="text"
                value={globalCacheTtl || globalResTtl}
                onChange={e => setGlobalCacheTtl(parseInt(e.target.value, 10) || 0)}
              />
              <TheButton onClick={() => updateCacheTtl(0, globalCacheTtl)}>送信</TheButton>
            </Flex>
            <Flex>
              <Label>AITravel用 キャッシュ有効期限</Label>
              <Input
                type="text"
                value={aiTravelCacheTtl || aiTravelResTtl}
                onChange={e => setAiTravelCacheTtl(parseInt(e.target.value, 10) || 0)}
              />
              <TheButton onClick={() => updateCacheTtl(1, aiTravelCacheTtl)}>送信</TheButton>
            </Flex>
            <A onClick={() => deleteAllCache()}>国内航空券のキャッシュをすべて削除する</A>
            <h3>API提供型利用状況</h3>
            <Flex>
              <DatetimePicker
                value={usageCountFrom}
                onChange={d => setUsageCountFrom(d)}
                showTime={false}
                disabledDays={0}
                showPast
                border
              />
              <span>〜</span>
              <DatetimePicker
                value={usageCountTo}
                onChange={d => setUsageCountTo(d)}
                showTime={false}
                disabledDays={0}
                showPast
                border
              />
              <TheButton onClick={() => fetchDataProviderDomesticAirUsage()}>再集計</TheButton>
            </Flex>
            {isUsageCountLoading ? (
              <SimpleLoading />
            ) : (
              <Table>
                <thead>
                  <tr>
                    <Th>非キャッシュアクセス数</Th>
                    <Th>キャッシュアクセス数</Th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{usageCount?.unhitCount}</td>
                    <td>{usageCount?.hitCount}</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </>
        )}
      </Content>
    </div>
  );
};

const Table = styled.table`
  margin: 0 0 30px 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 4px;
`;

const Td = styled.td`
  padding: 4px;
`;

const CenterAlignTh = styled.th`
  text-align: center;
  padding: 4px;
`;

const RightAlignTd = styled.td`
  text-align: right;
  padding: 4px;
`;

const Flex = styled.div`
  display: flex;
`;

const CacheWrapper = styled.div`
  margin-top: 10px;
`;

const Label = styled.p`
  margin-right: 5px;
  white-space: nowrap;
`;

const Input = styled.input`
  &&& {
    margin-right: 15px;
    height: 24px;
    width: 150px;
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
    font-size: 1em;
  }
`;

const TheButton = styled(Button)`
  margin-left: 5px;
`;

const Textarea = styled.textarea`
  height: auto;
`;

const RouteArea = styled.div`
  margin-bottom: 20px;
`;

const SearchCacheType = styled.div`
  margin-right: 15px;
`;

const SearchCacheDayType = styled.div`
  margin-right: 15px;
`;

export default observer(OldVersionTable);
