import React from 'react';
import { css, styled } from '@this/constants/themes';
import {
  RightButton,
  RightBody,
  RightChangeable,
  RightPrice,
  Yen,
  RightPeopleNum
} from '@this/components/reserve_trip/select/list_area/list_area.template';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import SelectTransportBoxPriceRangeLabel from '../price_range_label';

type Props = {
  loading: boolean;
  selected: boolean;
  hovered: boolean;
  price?: number;
  isAddtional?: boolean;
  priceRange?: string;
  showsPricePerPersonText?: boolean;
  changeable?: boolean;
  onClick: () => void;
  onTouchStart: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  seatType?: string | null;
  isPackage?: true | null;
  seatCount?: string | null;
  isDomestic?: boolean;
  disabled?: boolean;
};

const SelectTransportBoxRightInListTemplate: React.FC<Props> = ({
  loading,
  selected,
  hovered,
  price,
  isAddtional,
  priceRange,
  showsPricePerPersonText,
  changeable,
  onClick,
  onTouchStart,
  onMouseOver,
  onMouseLeave,
  isPackage,
  seatCount,
  isDomestic,
  disabled
}) => (
  <TransportSelectButton
    selected={selected || hovered}
    onClick={onClick}
    onTouchStart={onTouchStart}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    isPackage={isPackage}
    isDomestic={isDomestic}
    disabled={disabled}
  >
    {loading ? (
      <RightBody>
        <Loading />
      </RightBody>
    ) : hovered && !selected ? (
      <div className="select-transport-box__right__body__select">選択する</div>
    ) : (
      <RightBox className="select-transport-box__right__body">
        {changeable != null && (
          <Changeable selected={selected}>
            <RightChangeable className="select-transport-box__right__body__changeable flex">
              変更
              <br />
              {changeable ? '可' : '不可'}
            </RightChangeable>
          </Changeable>
        )}
        <Details selected={selected}>
          <div>
            <RightPrice>
              {price !== undefined && (
                <>
                  {isAddtional && <>+ </>}
                  <span data-wovn-ignore>{utils.digits(price)}</span>
                  <Yen>円</Yen>
                </>
              )}
              {priceRange && <SelectTransportBoxPriceRangeLabel priceRange={priceRange} />}
            </RightPrice>
            <RightPeopleNum>{showsPricePerPersonText ? '/1名' : ''}</RightPeopleNum>
          </div>
          {seatCount != null && <div>{seatCount}</div>}
        </Details>
      </RightBox>
    )}
  </TransportSelectButton>
);

SelectTransportBoxRightInListTemplate.defaultProps = {
  showsPricePerPersonText: true
};

/**
 * この書き方でないとCannot create styled-component for component: undefinedになる
 * ref: https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
 */
const TransportSelectButton = styled(props => <RightButton {...props} />)<
  Pick<Props, 'isPackage' | 'disabled' | 'isDomestic'>
>`
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
        `
      : null}

  ${({ isPackage }) =>
    isPackage
      ? css`
          min-width: 145px;
          min-height: 45px;
          text-align: center;
        `
      : null}

  ${({ isDomestic }) =>
    isDomestic
      ? css`
          height: 3rem;
        `
      : null}
  padding: 0;
  width: 100%;
`;

const RightBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Changeable = styled.div<Pick<Props, 'selected'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 100%;
  background: ${props => (props.selected ? props.theme.accentColor : 'inherit')};
`;

const Details = styled.div<Pick<Props, 'selected'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${props => (props.selected ? props.theme.accentColor : 'inherit')};
`;

export default SelectTransportBoxRightInListTemplate;
