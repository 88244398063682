import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import type { OrderItemCategory, OrderItemCategoryOptions } from '../order_item';
import type { OrderItemPriceDetailForReportArgs } from './trip_args';
import { OrderItemPriceDetailProject } from './order_item_price_detail_project';

export class OrderItemPriceDetailForReport {
  id: number;

  tripId = 0;

  tripReportId?: number;

  name: string;

  shortName: string;

  price: number;

  orderItemCategory: OrderItemCategory = 'other';

  startDate: Moment | null = null;

  endDate: Moment | null = null;

  fromName: string | null = null;

  toName: string | null = null;

  projects: OrderItemPriceDetailProject[] = [];

  projectRemoves: number[] = [];

  constructor(args: OrderItemPriceDetailForReportArgs, options?: OrderItemCategoryOptions) {
    this.id = args.id;
    this.tripReportId = args.trip_report_id;
    this.name = args.name;
    this.shortName = args.name;
    this.price = args.price;

    if (args.projects) {
      this.projects = args.projects.map(projectArgs => new OrderItemPriceDetailProject(projectArgs));
    }

    if (args.order_item_price) {
      this.tripId = args.order_item_price.order_item.order.trip.id;
      this.orderItemCategory = args.order_item_price.order_item.order_item_category;
      this.startDate = moment(args.order_item_price.order_item.order.trip.start_time);
      this.endDate = moment(args.order_item_price.order_item.order.trip.end_time);

      if (this.orderItemCategory === 'hotel') {
        const hotel = args.order_item_price.order_item.hotels[0];
        if (hotel) {
          this.startDate = moment(hotel.checkin_date);
          this.endDate = moment(hotel.checkout_date);
          this.toName = hotel.name || null;
        }
      }

      if (
        ['domestic_air', 'foreign_air', 'shinkansen', 'express', 'railway_ticket', 'bus', 'ana_tabisaku'].includes(
          this.orderItemCategory
        )
      ) {
        const transport = args.order_item_price.order_item.transports[0];
        if (transport) {
          this.startDate = moment(transport.from.time);
          this.endDate = moment(transport.to.time);
          this.fromName = transport.from.name || null;
          this.toName = transport.to.name || null;
          if (this.toName) {
            this.shortName = this.fromName ? `${this.fromName} ∼ ${this.toName}` : this.toName;
          }
        }
      }

      if (options && _.isEmpty(this.name)) {
        this.name = options.find(([_, key]) => key === this.orderItemCategory)?.[0] || '';
      }
    }
  }

  getCategoryName(categoryOptions: OrderItemCategoryOptions, suffix?: string) {
    const categoryName = categoryOptions.find(([_, value]) => value === this.orderItemCategory)?.[0];
    return categoryName && `${categoryName}${suffix || ''}`;
  }

  getPeriod() {
    if (this.startDate && this.endDate) {
      return this.startDate.month === this.endDate.month
        ? `${this.startDate.format('M月D日')}∼${this.endDate.format('D日')}`
        : `${this.startDate.format('M月D日')}∼${this.endDate.format('M月D日')}`;
    }

    return `${(this.startDate || this.endDate)?.format('M月D日') || '─'}`;
  }

  addProject() {
    this.projects.push(new OrderItemPriceDetailProject({}));
    app.render();
  }

  removeProject(index: number) {
    const targetProject = this.projects[index];
    if (targetProject.id) this.projectRemoves.push(targetProject.id);

    this.projects.splice(index, 1);
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      projects: this.projects.filter(project => project.projectId).map(project => project.submitParams()),
      project_removes: this.submitProjectRemoves()
    };
  }

  submitProjectRemoves() {
    const projectEmpties = this.projects
      .filter(project => !project.projectId)
      .map(project => project.id)
      .filter((id): id is number => typeof id === 'number');

    return this.projectRemoves.concat(projectEmpties);
  }
}
