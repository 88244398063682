import type { ChatbotMessageResponseArgs } from '@this/src/domain/chatbot/chatbot_message';

export interface SessionMessagesResponse {
  session_id: number | null;
  chatbot_session_messages: ChatbotMessageResponseArgs[];
}

interface Props {
  sessionId: number;
  onSuccess?: (res: SessionMessagesResponse) => void;
}

const fetchSessionMessagesApi = ({ sessionId, onSuccess }: Props) => {
  const url = `/chatbot/sessions/${sessionId}`;
  utils.jsonPromise<SessionMessagesResponse>(url).then(res => onSuccess?.(res));
};

export default fetchSessionMessagesApi;
