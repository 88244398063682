import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';

import SearchTracking from '@this/src/domain/search_tracking';
import type { SearchTrackingArgs } from '@this/src/domain/search_tracking';

import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';

import useOrganizationSelector from '../todo_list/use_organization_selector';

import SearchTrackingsSearchArea, { initialUrlQuery, urlQueryReducer } from './search_trackings_search_area';
import type { UrlQuery, UrlQueryAction } from './search_trackings_search_area';
import SearchTrackingsTableRow from './search_trackings_table_row';

interface State {
  searchTrackings: SearchTracking[];
  urlQuery: UrlQuery;
  loading: boolean;
  totalCount: number;
  totalPage: number;
  page: number;
}

interface Response {
  search_trackings: SearchTrackingArgs[];
  total_count: number;
  total_page: number;
}

type Action =
  | UrlQueryAction
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_PAGE'; payload: number }
  | { type: 'SET_RESPONSE'; payload: { response: Response; page: number } };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_EMAIL':
    case 'SET_TRIP_ID':
    case 'SET_CREATED_AT_FROM':
    case 'SET_CREATED_AT_TO':
      return { ...state, urlQuery: urlQueryReducer(state.urlQuery, action) };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_RESPONSE':
      return {
        ...state,
        searchTrackings: action.payload.response.search_trackings.map(raw => new SearchTracking(raw)),
        loading: false,
        totalCount: action.payload.response.total_count,
        totalPage: action.payload.response.total_page,
        page: action.payload.page
      };
    default:
      return state;
  }
};

interface Request {
  page: number;
  organizationIds: number[];
  urlQuery: UrlQuery;
  history: RouteComponentProps['history'];
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}

const fetchSearchTrackings = ({ page, organizationIds, urlQuery, history, dispatch }: Request) => {
  dispatch({ type: 'SET_LOADING', payload: true });

  const { created_at_from, created_at_to, ...others } = urlQuery;
  const params = {
    page,
    ...others,
    created_at_from: created_at_from?.format('YYYY-MM-DD'),
    created_at_to: created_at_to?.format('YYYY-MM-DD'),
    organization_ids: organizationIds.join(',')
  };
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) delete params[key as keyof typeof params];
  });
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  history.push(`/arrangement/search_trackings?${query}`);

  Fetcher.get<Response>('/arrangement/search_trackings.json', params)
    .then(response => {
      dispatch({ type: 'SET_RESPONSE', payload: { response, page } });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
    });
};

const initialState: State = {
  searchTrackings: [],
  urlQuery: initialUrlQuery,
  loading: false,
  totalCount: 0,
  totalPage: 0,
  page: 1
};

const SearchTrackings: React.FC = observer(() => {
  const { current: history } = useRef(useHistory());
  const [state, dispatch] = useReducer(reducer, initialState);
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();

  const { searchTrackings, urlQuery, loading, totalPage, totalCount, page } = state;

  const fetch = useCallback(() => {
    fetchSearchTrackings({
      page,
      urlQuery,
      organizationIds,
      history,
      dispatch
    });
  }, [page, urlQuery, organizationIds, history, dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      <SearchTrackingsSearchArea
        urlQuery={state.urlQuery}
        dispatch={dispatch}
        renderOrganizationSelector={renderOrganizationSelector}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div>ログ: {totalCount}件</div>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>トラッキングID</TableCell>
                <TableCell>ユーザー名</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>企業名</TableCell>
                <TableCell nowrap>SearchQuery ID</TableCell>
                <TableCell nowrap>Trip ID</TableCell>
                <TableCell>追跡開始時刻</TableCell>
                <TableCell>追跡終了時刻</TableCell>
                <TableCell>詳細</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchTrackings.map(searchTracking => (
                <SearchTrackingsTableRow key={searchTracking.id} searchTracking={searchTracking} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_e, page) => dispatch({ type: 'SET_PAGE', payload: page })}
          />
        </>
      )}
    </>
  );
});

export default SearchTrackings;
