export type ItemType = 'foreign_railway' | 'foreign_hotel_bus' | 'foreign_wifi' | 'taxi' | 'visa_esta' | 'other';

export interface Args {
  itemType: [string, string][];
  otherItemAvailable: boolean;
}

export interface OtherItem {
  itemType: ItemType | '';

  itemTypeOther: string;

  remarks: string;
}

class OtherItemInfo<T = Record<never, never>> {
  otherItemAvailable: boolean;

  itemTypeOptions: [string, string][];

  otherItems: (OtherItem & T)[];

  constructor(args: Args & Partial<T>) {
    this.otherItemAvailable = args.otherItemAvailable;
    this.itemTypeOptions = args.itemType || [];
    this.otherItems = [
      {
        itemType: '',
        itemTypeOther: '',
        remarks: '',
        ...this.setDefaultOtherItem()
      }
    ];
  }

  /* eslint class-methods-use-this: 0 */
  setDefaultOtherItem(): T & Partial<OtherItem> {
    return {} as T;
  }

  setItemType(value: never, i: number) {
    this.otherItems[i].itemType = value;
    app.render();
  }

  setItemTypeOther(value: never, i: number) {
    this.otherItems[i].itemTypeOther = value;
    app.render();
  }

  setRemarks(value: never, i: number) {
    this.otherItems[i].remarks = value;
    app.render();
  }

  addOtherItem() {
    this.otherItems.push({
      itemType: '',
      itemTypeOther: '',
      remarks: '',
      ...this.setDefaultOtherItem()
    });
    app.render();
  }

  removeOtherItem(i: number) {
    this.otherItems = this.otherItems.filter((r, ri) => ri !== i);
    app.render();
  }
}

export default OtherItemInfo;
