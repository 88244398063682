import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { calculateAge } from '@this/domain/age_util';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { isWithinSixMonth, isOverDate } from '@this/src/components/shared/checker/date_checker';
import type { Traveler } from './type';
import { FormField, FormTitle, InputGroup, TextInput } from './order_item_traveler.style';

export type TravelerForeignAirFormData = {
  airSeat: Traveler['airSeat'];
  flightBirthday: Traveler['flightBirthday'];
  flightFirstName: Traveler['flightFirstName'];
  flightGender: Traveler['flightGender'];
  flightLastName: Traveler['flightLastName'];
  flightMiddleName: Traveler['flightMiddleName'];
  flightTel: Traveler['flightTel'];
  mileageNumber: Traveler['mileageNumber'];
  passportExpire: Traveler['passportExpire'];
  passportIssuedCountry: Traveler['passportIssuedCountry'];
  passportNumber: Traveler['passportNumber'];
};

type Props = {
  formData: TravelerForeignAirFormData;
  transportDate: string | null;
  hiddenPersonalForm?: boolean;
  onChange: (formData: TravelerForeignAirFormData) => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
};

type State = TravelerForeignAirFormData;

const OrderItemTravelerForeignAirForm: React.FC<Props> = ({
  formData,
  transportDate,
  hiddenPersonalForm = false,
  onChange,
  classNameForModifiedField
}) => {
  const [state, setState] = useState<State>({ ...formData });
  const onChangeInput = (val: any, attr: keyof State) => {
    const newState = { ...state };
    newState[attr] = val;
    setState(newState);

    onChange(newState);
  };

  return (
    <Container>
      <FormTitle>航空券情報</FormTitle>
      {!hiddenPersonalForm && (
        <>
          <FormField>
            <label
              className={`
                ${classNameForModifiedField('flightLastName')}
                ${classNameForModifiedField('flightFirstName')}
              `}
            >
              氏名(ローマ字)
            </label>
            <InputGroup>
              <TextInput
                type="text"
                placeholder="姓(ローマ字)"
                value={state.flightLastName}
                onChange={e => onChangeInput(e.target.value, 'flightLastName')}
              />
              <TextInput
                type="text"
                placeholder="ミドルネーム(ローマ字)"
                value={state.flightMiddleName}
                onChange={e => onChangeInput(e.target.value, 'flightMiddleName')}
              />
              <TextInput
                type="text"
                placeholder="名(ローマ字)"
                value={state.flightFirstName}
                onChange={e => onChangeInput(e.target.value, 'flightFirstName')}
              />
            </InputGroup>
          </FormField>
          <FormField>
            <label className={classNameForModifiedField('flightBirthday')}>誕生日</label>
            <TextInput
              placeholder="例) 2020-04-01"
              value={state.flightBirthday ?? ''}
              onChange={e => onChangeInput(e.target.value, 'flightBirthday')}
            />
            {state.flightBirthday && transportDate && (
              <FlightAge>
                <label>搭乗時年齢</label>
                <span>{`${calculateAge(state.flightBirthday, transportDate).toString()}歳`}</span>
              </FlightAge>
            )}
          </FormField>
          <FormField>
            <label className={classNameForModifiedField('flightGender')}>性別</label>
            <InputGroup>
              <label>
                <input
                  type="radio"
                  value="male"
                  name="foreign-air-gender"
                  checked={state.flightGender === 'male'}
                  onChange={e => onChangeInput(e.target.value, 'flightGender')}
                />
                男性
              </label>
              <label>
                <input
                  type="radio"
                  value="female"
                  name="foreign-air-gender"
                  checked={state.flightGender === 'female'}
                  onChange={e => onChangeInput(e.target.value, 'flightGender')}
                />
                女性
              </label>
            </InputGroup>
          </FormField>
        </>
      )}

      <FormField>
        <label className={classNameForModifiedField('flightTel')}>緊急連絡先番号</label>
        <TextInput
          placeholder="例) 09012345678"
          value={state.flightTel ?? ''}
          onChange={e => onChangeInput(e.target.value, 'flightTel')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('passportNumber')}>パスポート番号</label>
        <TextInput
          placeholder="例) AA1234567"
          value={state.passportNumber ?? ''}
          onChange={e => onChangeInput(e.target.value, 'passportNumber')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('passportExpire')}>パスポート有効期限</label>
        <TextInput
          placeholder="例) 2025-01-01"
          value={state.passportExpire ?? ''}
          onChange={e => onChangeInput(e.target.value, 'passportExpire')}
        />
        {isWithinSixMonth(state.passportExpire) && (
          <div>
            <Text color="danger">有効期限が近づいてきています。パスポート情報をご確認ください。</Text>
          </div>
        )}
        {isOverDate(state.passportExpire) && (
          <div>
            <Text color="danger">有効期限が切れています。パスポート情報をご確認ください。</Text>
          </div>
        )}
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('passportIssuedCountry')}>パスポート発行国</label>
        <TextInput
          placeholder="例) 日本"
          value={state.passportIssuedCountry ?? ''}
          onChange={e => onChangeInput(e.target.value, 'passportIssuedCountry')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('airSeat')}>座席の希望</label>
        <TextInput
          placeholder="例) 前方通路側"
          value={state.airSeat ?? ''}
          onChange={e => onChangeInput(e.target.value, 'airSeat')}
        />
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('mileageNumber')}>マイル番号</label>
        <TextInput
          placeholder="例) 0123456789"
          value={state.mileageNumber ?? ''}
          onChange={e => onChangeInput(e.target.value, 'mileageNumber')}
        />
      </FormField>
    </Container>
  );
};

const Container = styled.div``;

const FlightAge = styled.div`
  margin-bottom: 0.75em;
`;

export default OrderItemTravelerForeignAirForm;
