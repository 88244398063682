import _ from 'lodash';

export const TicketingTypeQuery = (serviceId: number) =>
  utils.isTabikobo(serviceId)
    ? [
        { value: 9, label: '全て' },
        { value: 1, label: 'アリーズ' },
        { value: 3, label: 'IWAツアー' },
        { value: 4, label: '旅工房' }
      ]
    : [
        { value: 9, label: '全て' },
        { value: 0, label: 'AIトラベル' },
        { value: 1, label: 'アリーズ' },
        { value: 2, label: 'エボラブルアジア' },
        { value: 5, label: 'フロンティア' },
        { value: 6, label: 'スカイツアーズ' }
      ];

export type TicketingTypeQueryKey = keyof typeof TicketingTypeQuery;

export const FindTicketingTypeByValue = (serviceId: number, value: number) => {
  if (value == null) return '';
  return TicketingTypeQuery(serviceId).find(obj => obj.value === value)?.label || '';
};
