import React from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Text } from '@this/shared/ui/data_displays/typography';

type Props = {
  className?: string;
  trip: Trip;
};

export const TripInfo = ({ className, trip }: Props) => {
  return (
    <Root className={className}>
      {trip.status === 5 && (
        <ErrorBox>
          <Text color="danger">この予約はキャンセルされました</Text>
        </ErrorBox>
      )}
      <p>社内管理番号: {trip.internalNumber || '-'}</p>
      <p>出張種別: {trip.invoice_trip_type === 'domestic' ? '国内出張' : '海外出張'}</p>
    </Root>
  );
};

const Root = styled.div``;

const ErrorBox = styled.div`
  margin-bottom: 16px;
`;
