import React, { useCallback, useEffect, useReducer } from 'react';

import { styled } from '@this/constants/themes';
import type ChatbotUserSetting from '@this/src/domain/chatbot/chatbot_user_setting';
import { Button } from '@this/src/components/shared/ui/inputs/button';

import { UserSettingFormOrigin } from './form/UserSettingFormOrigin';
import type { OriginFieldOptions } from './form/UserSettingFormOrigin';

interface Props extends OriginFieldOptions {
  userSetting: ChatbotUserSetting;
  updateDefaultOrigin: (origin?: string, onSuccess?: () => void) => void;
}

interface State {
  origin: string;
  address: string;
  success: boolean;
}

type Action =
  | { type: 'SET_ORIGIN'; payload: { origin: string; address?: string } }
  | { type: 'SET_SUCCESS'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ORIGIN':
      return {
        ...state,
        origin: action.payload.origin,
        address: action.payload.address ?? ''
      };
    case 'SET_SUCCESS':
      return { ...state, success: action.payload };
    default:
      return state;
  }
};

const initialState: State = {
  origin: '',
  address: '',
  success: false
};

export const UserSettingForm: React.FC<Props> = ({ userSetting, updateDefaultOrigin, ...options }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch({ type: 'SET_SUCCESS', payload: false });
      updateDefaultOrigin(state.origin, () => dispatch({ type: 'SET_SUCCESS', payload: true }));
    },
    [state, userSetting, updateDefaultOrigin, dispatch]
  );

  const handleChangeOrigin = useCallback(
    (origin: string, address?: string) => {
      dispatch({ type: 'SET_ORIGIN', payload: { origin, address } });
    },
    [dispatch]
  );

  useEffect(() => {
    handleChangeOrigin(userSetting.defaultOrigin);
  }, [userSetting.defaultOrigin, handleChangeOrigin]);

  return (
    <Form onSubmit={handleSubmit}>
      <Title>デフォルトの出発地を登録</Title>
      <Description>
        「東京への旅程を検索」のように出発地を指定していないリクエストを行った際に使用されるデフォルトの出発地を登録できます。
      </Description>
      <FormContent>
        <UserSettingFormOrigin
          origin={state.origin}
          address={state.address}
          setOrigin={handleChangeOrigin}
          {...options}
        />
      </FormContent>
      {state.success && <SucesssMessage>デフォルトの出発地を登録しました。</SucesssMessage>}
      {userSetting.error && <ErrorMessage>{userSetting.error}</ErrorMessage>}
      <Button type="submit">保存する</Button>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
`;

const Description = styled.p`
  font-size: 12.5px;
  font-weight: normal;
  margin-bottom: 8px;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
`;

const SucesssMessage = styled.p`
  font-size: 12.5px;
  font-weight: normal;
  margin-bottom: 4px;
  color: ${props => props.theme.successColor};
`;

const ErrorMessage = styled.p`
  font-size: 12.5px;
  font-weight: normal;
  margin-bottom: 4px;
  color: ${props => props.theme.redColor};
`;
