import { observable } from 'mobx';

export interface HotelBaseScoreArgs {
  id: number;
  provider: string;
  value: number;
}

export default class HotelBaseScore {
  readonly id: number;

  readonly provider: string;

  @observable
  value: number;

  constructor(args: HotelBaseScoreArgs) {
    this.id = args.id;
    this.provider = args.provider;
    this.value = args.value;
  }

  submitParams() {
    return { id: this.id, value: this.value };
  }
}
