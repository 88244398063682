User = require('../user/user')
ReservingTrip = require('../trip/reserving_trip').default
toDomesticTel = require('../../util/string').toDomesticTel

class Traveler extends User
  constructor: (args = {}) ->
    super(args)
    @user_id = args.user_id || ''
    @reservingTrip = args.reservingTrip || new ReservingTrip()
    @firstNameRoman = args.firstNameRoman || args.first_name_roman || ''
    @lastNameRoman = args.lastNameRoman || args.last_name_roman || ''
    @middleNameRoman = args.middleNameRoman || args.middle_name_roman || ''
    @firstNameKana = args.firstNameKana || args.first_name_kana || ''
    @lastNameKana = args.lastNameKana || args.last_name_kana || ''
    @flightFirstNameRoman = args.flightFirstNameRoman || @firstNameRoman || ''
    @flightLastNameRoman = args.flightLastNameRoman || @lastNameRoman || ''
    @flightMiddleNameRoman = args.flightMiddleNameRoman || @middleNameRoman || ''
    @flightFirstNameKana = args.flightFirstNameKana || @firstNameKana || ''
    @flightLastNameKana = args.flightLastNameKana || @lastNameKana || ''
    @flightGender = args.flightGender || args.gender || args.flight_gender || 'male'
    @flightBirthday = args.flightBirthday || args.birthday || args.flight_birthday || ''
    travelerInformations = args.traveler_informations || []
    searchQueryId = args.search_query_id || 0
    info = travelerInformations.find (information) -> information.search_query_id == searchQueryId
    @travelerInformationId = dig(info, 'id')
    @airSeat = args.airSeat || dig(info, 'air_seat') || ''
    @shinkansenTicketType = args.shinkansenTicketType || args.shinkansen_ticket_type || undefined
    @shinkansenSeatType = args.shinkansenSeatType || args.shinkansen_seat_type || undefined
    @shinkansenSeatDetail = args.shinkansenSeat || args.shinkansen_seat || ''
    @shinkansenSeat = @shinkansenSeatDetail.split(/\r\n|\r|\n/)[0] || ''
    @requestShinkansenSeat = ''
    @mileageNumber = args.mileageNumber || dig(info, 'mileage_number') || ''
    @mileageNumbers = args.mileageNumbers || dig(info, 'mileage_numbers') || []
    if @mileageNumbers && !Array.isArray(@mileageNumbers)
      @mileageNumbers = Object.values(@mileageNumbers)
    @exicNumber = args.exicNumber || args.exic_number || args.exicId || args.exic_id || ''
    @exicPassword = args.exicPassword || args.exic_password || ''
    @passportNumber = args.passportNumber || dig(info, 'passport_number') || ''
    @passportExpire = args.passportExpire || dig(info, 'passport_expire') || ''
    @passportIssuedCoutry =
      args.passportIssuedCoutry || dig(info, 'passport_issued_country') || '日本'
    @type = args.type || 'self'
    if args.type == 'member'
      flightTel = dig(info, 'flight_tel')
      @flightTel = flightTel || args.tel || ''
    else
      @flightTel = args.flightTel || args.flight_tel || args.tel || ''
    flightFirstName = dig(info, 'flight_first_name') || ''
    flightLastName = dig(info, 'flight_last_name') || ''
    flightMiddleName = dig(info, 'flight_middle_name') || ''
    if flightFirstName.match(/[A-za-z]+/) && flightLastName.match(/[A-za-z]+/)
      @flightFirstNameRoman ||= flightFirstName || ''
      @flightLastNameRoman ||= flightLastName || ''
      @flightMiddleNameRoman ||= flightMiddleName || ''
    else if flightFirstName.match(/^[ァ-ヶー　]*$/) && flightLastName.match(/^[ァ-ヶー　]*$/)
      @flightFirstNameKana ||= flightFirstName || ''
      @flightLastNameKana ||= flightLastName || ''

    @immigrationCardCreation = false
    @visaApplication = false
    @prefectureId = dig(info, 'prefecture_id') || 0
    @organization = args.organization || undefined
    @department = _.merge args.department, { id: args.department_id }
    @gradeId = if args.grade_id then parseInt(args.grade_id) else undefined
    @nationalityId = dig(info, 'nationality_id') || args.nationality_id || 121 # デフォルトは日本
    @displayName = args.display_name
    @domesticAirSeatUpgrade = args.domestic_air_seat_upgrade

  getTravelerName: ->
    @name || "#{@lastNameRoman} #{@firstNameRoman}"

  isTravelerShinkansenType: ->
    @shinkansenTicketType != undefined || @shinkansenSeatType != undefined

  getShinkansenTicketTypeText: ->
    switch @shinkansenTicketType
      when 'normal'
        '通常きっぷ'
      when 'kyuusyuu'
        'JR九州きっぷ'
      when 'bulk_ticket'
        '回数券'
      when 'ex'
        'EX-IC'

  getShinkansenSeatTypeText: ->
    switch @shinkansenSeatType
      when 'reserve'
        '指定席'
      when 'free', 'non_reserved'
        '自由席'
      when 'green'
        'グリーン席'

  getTravelerShinkansenType: ->
    list = []
    list.push "券種: #{@getShinkansenTicketTypeText()}" if @shinkansenTicketType
    list.push "座席: #{@getShinkansenSeatTypeText()}" if @shinkansenSeatType
    list.push "座席希望: #{@shinkansenSeat}" if @shinkansenSeat
    list.push "EX-IC番号: #{@exicNumber}" if @exicNumber && @shinkansenTicketType == 'ex'
    list.push "EX-ICパスワード: #{@exicPassword.replace(/./g, '*')}" if @exicPassword && @shinkansenTicketType == 'ex'
    "(#{@getTravelerName()}) #{list.join(', ')}"

  getTravelerAirlineType: ->
    list = []
    list.push "座席希望: #{@airSeat}" if @airSeat
    list.push "マイル番号: #{@getTravelerMileageNumber()}" if @mileageNumber || @mileageNumbers.length > 0
    "(#{@getTravelerName()}) #{list.join(', ')}"

  getTravelerMileageNumber: ->
    list = []
    list.push @mileageNumber if @mileageNumber
    @mileageNumbers?.forEach (mileageNumber) ->
      list.push "(#{mileageNumber.carrier}) #{mileageNumber.number}"
    list.join('、')

  getTravelerNameFromPriority: ->
    if @lastName && @firstName
      "#{@lastName} #{@firstName}"
    else if @lastNameKana && @firstNameKana
      "#{@lastNameKana} #{@firstNameKana}"
    else
      "#{@lastNameRoman} #{@firstNameRoman}"

  getTravelerParams: (reservingTrip = {}) ->
    user_id: @user_id || if @type == 'companion' then '' else @id
    type: @type
    first_name: @firstName
    last_name: @lastName
    first_name_kana: @firstNameKana
    last_name_kana: @lastNameKana
    first_name_roman: @firstNameRoman
    last_name_roman: @lastNameRoman
    birthday: @birthday
    tel: @tel
    air_seat: @airSeat
    shinkansen_ticket_type: @shinkansenTicketType
    shinkansen_seat_type: @shinkansenSeatType
    shinkansen_seat: if @requestShinkansenSeat then "#{@shinkansenSeat}:#{@requestShinkansenSeat}" else @shinkansenSeat
    mileage_number: @mileageNumber
    exic_number: @exicNumber
    exic_password: @exicPassword
    flight_first_name: if dig(reservingTrip, 'isForeignFlightCustomerInfoRequired')
      @flightFirstNameRoman
    else
      @flightFirstNameKana
    flight_last_name: if dig(reservingTrip, 'isForeignFlightCustomerInfoRequired')
      @flightLastNameRoman
    else
      @flightLastNameKana
    flight_middle_name: if dig(reservingTrip, 'isForeignFlightCustomerInfoRequired') then @flightMiddleNameRoman else ''
    flight_first_name_roman: @flightFirstNameRoman
    flight_last_name_roman: @flightLastNameRoman
    flight_middle_name_roman: @flightMiddleNameRoman
    flight_gender: @flightGender
    flight_birthday: @flightBirthday
    flight_tel: if @flightTel then toDomesticTel(@flightTel.replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '')) else ''
    passport_number: @passportNumber
    passport_expire: @passportExpire
    passport_issued_country: @passportIssuedCoutry
    immigration_card_creation: @immigrationCardCreation
    visa_application: @visaApplication
    prefecture_id: @prefectureId
    nationality_id: @nationalityId
    traveler_information_id: @travelerInformationId

  getQueryString: (key) ->
    res = ''
    _.entries(@getTravelerParams()).forEach(([k, v]) ->
      return if (_.isNil(v) || v == '')
      res += "#{key}[#{k}]=#{v}&"
    )
    res

  setReservingTrip: (value) ->
    @reservingTrip = value
    if @reservingTrip.isShinkansenInfoRequired() && @shinkansenSeatType == undefined
      @setInitialShinkansenTypes()

  setFirstNameRoman: (value) ->
    @firstNameRoman = value
    @flightFirstNameRoman = value
    app.render()

  setLastNameRoman: (value) ->
    @lastNameRoman = value
    @flightLastNameRoman = value
    app.render()

  setFirstNameKana: (value) ->
    @firstNameKana = value
    @flightFirstNameKana = value
    app.render()

  setLastNameKana: (value) ->
    @lastNameKana = value
    @flightLastNameKana = value
    app.render()

  setBirthday: (value) ->
    @birthday = value
    @flightBirthday = value
    app.render()

  setFlightFirstNameRoman: (value) ->
    @flightFirstNameRoman = value
    app.render()

  setFlightLastNameRoman: (value) ->
    @flightLastNameRoman = value
    app.render()

  setFlightMiddleNameRoman: (value) ->
    @flightMiddleNameRoman = value
    app.render()

  setFlightFirstNameKana: (value) ->
    @flightFirstNameKana = value
    app.render()

  setFlightLastNameKana: (value) ->
    @flightLastNameKana = value
    app.render()

  setFlightGender: (value) ->
    @flightGender = value
    app.render()

  setFlightBirthday: (value) ->
    @flightBirthday = value
    @birthday = value
    app.render()

  setFlightTel: (value) ->
    @flightTel = value
    app.render()

  setAirSeat: (value) ->
    @airSeat = value
    app.render()

  setShinkansenTicketType: (value) ->
    @shinkansenTicketType = value
    app.render()

  setInitialShinkansenTypes: () ->
    @shinkansenTicketType ||= 'normal'
    @shinkansenSeat ||= '指定なし'
    seatType = if @reservingTrip.isUnreservedSeatAvailable || !@organization then 'free' else undefined
    if @organization
      SeatTypes = ['free', 'reserve', 'green']
      gradeId = @gradeId # 一旦代入しないと何故かfilterの一致が取れませんでした。。
      Grade = _.first(@organization.grades?.filter (g) -> g.id == gradeId)
      targetSeatTypes = Grade?.shinkansen_seat_type?.split(',')
      targetSeatTypes = targetSeatTypes?.filter (seatType) -> SeatTypes.includes(seatType)

      if targetSeatTypes && targetSeatTypes.length
        if targetSeatTypes.includes('reserve')
          seatType = 'reserve'
        else
          if targetSeatTypes.includes('free') && @reservingTrip.isUnreservedSeatAvailable
            seatType = 'free'
          else
            seatType = 'green'

    @shinkansenSeatType = seatType

  setShinkansenSeatType: (value) ->
    @shinkansenSeatType = value
    app.render()

  setShinkansenSeatText: (value) ->
    @requestShinkansenSeat = value
    @shinkansenSeatText = "#{@shinkansenSeat}\n#{@requestShinkansenSeat}"
    app.render()

  setShinkansenSeat: (value) ->
    if value != 'その他'
      @requestShinkansenSeat = ''
    else
    @shinkansenSeat = value
    @shinkansenSeatText = value
    app.render()

  setMileageNumber: (value) ->
    @mileageNumber = value
    app.render()

  setExicNumber: (value) ->
    @exicNumber = value
    app.render()

  setExicPassword: (value) ->
    @exicPassword = value
    app.render()

  setPassportNumber: (value) ->
    @passportNumber = value
    app.render()

  setPassportExpire: (value) ->
    @passportExpire = value
    app.render()

  setPassportIssuedCountry: (value) ->
    @passportIssuedCoutry = value
    app.render()

  setImmigrationCardCreation: (value) ->
    @immigrationCardCreation = value == 'true'
    app.render()

  setVisaApplication: (value) ->
    @visaApplication = value == 'true'
    app.render()

  setPrefectureId: (value) ->
    @prefectureId = parseInt(value, 10)
    app.render()

  setNationalityId: (value) ->
    @nationalityId = value
    app.render()

  validate: (errors, isForeignFlightCustomerInfoRequired) ->
    switch @type
      when 'self'
        if !@id
          errors.memberSelect = '出張者を選択してください'
      when 'member'
        if !@id
          errors.memberSelect = '出張者を選択してください'
      when 'companion'
        if isForeignFlightCustomerInfoRequired

          if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen))
            errors.travelerFirstNameRomen = @requiredError('出張者の名前(ローマ字)', @firstNameRoman)
            errors.travelerLastNameRomen = @requiredError('出張者の名字(ローマ字)', @lastNameRoman)
          if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana))
            errors.travelerFirstNameKana = @requiredError('出張者の名前(カナ)', @firstNameKana)
            errors.travelerLastNameKana = @requiredError('出張者の名字(カナ)', @lastNameKana)
          if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen))
            errors.travelerFirstNameRomen = @alphabetError('出張者の名前(ローマ字)', @firstNameRoman)
            errors.travelerLastNameRomen = @alphabetError('出張者の名字(ローマ字)', @lastNameRoman)
          if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana))
            errors.travelerFirstNameKana = @katakanaError('出張者の名前(カナ)', @firstNameKana)
            errors.travelerLastNameKana = @katakanaError('出張者の名字(カナ)', @lastNameKana)
        else
          if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen))
            errors.travelerFirstNameRomen = @requiredError(
              '出張者の名前(ローマ字)',
              @firstNameRoman
            )
            errors.travelerLastNameRomen = @requiredError('出張者の名字(ローマ字)', @lastNameRoman)
          if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana))
            errors.travelerFirstNameKana = @requiredError('出張者の名前(カナ)', @firstNameKana)
            errors.travelerLastNameKana = @requiredError('出張者の名字(カナ)', @lastNameKana)
          if (_.isEmpty(errors.travelerFirstNameRomen) && _.isEmpty(errors.travelerLastNameRomen))
            errors.travelerFirstNameRomen = @alphabetError(
              '出張者の名前(ローマ字)',
              @firstNameRoman
            )
            errors.travelerLastNameRomen = @alphabetError(
              '出張者の名字(ローマ字)',
              @lastNameRoman
            )
          if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana))
            errors.travelerFirstNameKana = @katakanaError('出張者の名前(カナ)', @firstNameKana)
            errors.travelerLastNameKana = @katakanaError('出張者の名字(カナ)', @lastNameKana)
          if (_.isEmpty(errors.travelerFirstNameKana) && _.isEmpty(errors.travelerLastNameKana))
            errors.travelerFirstName =
              @katakanaError('搭乗者氏名', @flightLastNameKana) ||
              @katakanaError('搭乗者氏名', @flightFirstNameKana)

  flightValidate: (errors, isForeignFlightCustomerInfoRequired) ->
    if isForeignFlightCustomerInfoRequired
      errors.flightCustomerName =
        @alphabetError('搭乗者氏名', @flightLastNameRoman) ||
        @alphabetError('搭乗者氏名', @flightFirstNameRoman) ||
        @alphabetErrorAllowEmpty('搭乗者氏名', @flightMiddleNameRoman)
    else
      errors.flightCustomerName =
        @requiredError('搭乗者氏名', @flightLastNameKana) ||
        @requiredError('搭乗者氏名', @flightFirstNameKana)
      if _.isEmpty(errors.flightCustomerName)
        errors.flightCustomerName =
          @katakanaError('搭乗者氏名', @flightLastNameKana) ||
          @katakanaError('搭乗者氏名', @flightFirstNameKana)
    if @type == 'companion'
      errors.travelerBirthday = @dateError('出張者の誕生日', @flightBirthday)
    errors.flightCustomerBirthday = @dateError('搭乗者の誕生日', @flightBirthday)
    errors.flightCustomerTel = @requiredError('搭乗者の緊急連絡先', toDomesticTel(@flightTel))
    if isForeignFlightCustomerInfoRequired
      errors.passportNumber = @requiredError('パスポート番号', @passportNumber)
      errors.passportExpire = @requiredError('パスポート有効期限', @passportExpire)
      errors.passportIssuedCoutry = @requiredError('パスポート発行国', @passportIssuedCoutry)
    errors.flightCustomerGender = @requiredError('性別', @flightGender, 'を選択してください')

  requiredError: (name, value) ->
    if (!value || value.length == 0)
      name + 'を入力してください'
    else
      undefined

  katakanaError: (name, value) ->
    if !value.match(/^[ァ-ヶー　]*$/)
      name + 'はカタカナで入力してください'
    else
      undefined

  alphabetError: (name, value) ->
    error = @requiredError(name, value)
    if (error)
      error
    else if (!value.match(/^[a-zA-Z]+$/))
      name + 'はアルファベッドで入力してください'
    else
      undefined

  alphabetErrorAllowEmpty: (name, value) ->
    if (!value || value.length == 0)
      undefined
    else if (!value.match(/^[a-zA-Z]+$/))
      name + 'はアルファベッドで入力してください'
    else
      undefined

  dateError: (name, value) ->
    error = @requiredError(name, value)
    if (error)
      error
    else if !moment(value).isValid()
      name + 'を正しく入力してください（例：1986-01-01）'
    else
      undefined

module.exports = Traveler
