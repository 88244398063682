import React from 'react';
import { RightButtonPriceOnly, RightBody, RightPrice, Yen } from '../../list_area/list_area.template';

type Props = {
  price?: number;
};

const SelectTransportBoxRightInListPriceTemplate: React.FC<Props> = ({ price }) => (
  <RightButtonPriceOnly>
    <RightBody className="select-transport-box__right__body">
      <div>
        <RightPrice>
          {price !== undefined && (
            <>
              <span data-wovn-ignore>{utils.digits(price)}</span>
              <Yen>円</Yen>
            </>
          )}
        </RightPrice>
      </div>
    </RightBody>
  </RightButtonPriceOnly>
);

export default SelectTransportBoxRightInListPriceTemplate;
