import type { MouseEvent } from 'react';
import React from 'react';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';

interface Props {
  show: boolean;
  submitting: boolean;
  handleHide: () => void;
  handleSubmit: (e?: MouseEvent<HTMLAnchorElement>) => void;
}

const TicketingTripModal: React.FC<Props> = ({ show, submitting, handleHide, handleSubmit, children }) => {
  return (
    <Modal show={show} hideModal={handleHide} title="下記の旅程の発券依頼">
      {submitting ? (
        <p>処理中...</p>
      ) : (
        children && (
          <>
            {children}
            <Confirm message="上記を発券依頼します。" onConfirmed={handleSubmit} onAborted={handleHide} />
          </>
        )
      )}
    </Modal>
  );
};

export default TicketingTripModal;
