import React from 'react';
import { withTheme } from 'styled-components';

import type SearchQuery from '../../../../domain/search_query';
import type SearchQueryItem from '../../../../domain/search_query_item';
import type SelectStore from '../../../../domain/select_store';

import SelectOutlineTemplate from './outline/outline.template';

interface Props {
  // Select.props
  query: SearchQuery;
  store: SelectStore;
  selectErrors: string[];
  packageErrors?: string[] | null | undefined;
  submitting: boolean;

  handleSelectItem: () => void;

  handleAddItem?: (item_type: 'transport' | 'hotel', e: React.MouseEvent<HTMLElement>) => void;
  handleAdditionalSearch: (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => void;
  additionalSearchErrors?: { [k in string]: string };
  handleRemoveItem?: () => void;

  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;

  // ArrangementRequestForm
  isRequestForm?: boolean;

  theme: { themeClass: string };
  handleOpenShowSearchBox: () => void;
}

const SelectOutlineWrapperTemplate: React.SFC<Props> = ({
  query,
  store,
  selectErrors,
  packageErrors,

  handleSelectItem,

  handleAddItem,
  handleAdditionalSearch,
  additionalSearchErrors,
  handleRemoveItem,

  handleNextButtonClick,
  submitting,

  isRequestForm,

  theme,
  handleOpenShowSearchBox
}) => (
  <div className="select-outline-wrapper" style={{ marginTop: '10px' }}>
    {store.showPackage &&
    ((store.result.type === 'airPackage' &&
      store.result.packageItems[store.result.airline].length === 0 &&
      store.packageLoading[store.result.airline]) ||
      (store.result.type === 'jrPackage' && store.jrPackageLoading)) ? (
      <>
        <img className="select__loading" src="/images/loading.gif" width={50} height={50} />
        <div className="select__loading-text">パッケージを検索中です</div>
      </>
    ) : store.showPackage && store.result.type === 'airPackage' && packageErrors && packageErrors.length > 0 ? (
      <div className="select-pc__no-results">
        {packageErrors.map((error, i) => (
          <div className="error" key={i}>
            {error}
          </div>
        ))}
      </div>
    ) : store.showPackage &&
      store.result.type === 'jrPackage' &&
      store.jrPackageErrors &&
      store.jrPackageErrors.length > 0 ? (
      <div className="select-pc__no-results">
        {store.jrPackageErrors.map((error, i) => (
          <div className="error" key={i}>
            {error}
          </div>
        ))}
      </div>
    ) : store.showPackage &&
      ((store.result.type === 'airPackage' && store.result.packageItems[store.result.airline].length === 0) ||
        (store.result.type === 'jrPackage' && store.result.jrPackageItems.length === 0)) ? (
      <div className="select-pc__no-results">
        該当のパッケージはありません。
        <br />
        検索条件を変更して再度検索してください。
      </div>
    ) : (
      <SelectOutlineTemplate
        query={query}
        store={store}
        selectErrors={selectErrors}
        handleSelectItem={handleSelectItem}
        handleAddItem={handleAddItem}
        handleRemoveItem={handleRemoveItem}
        handleAdditionalSearch={handleAdditionalSearch}
        additionalSearchErrors={additionalSearchErrors}
        handleNextButtonClick={handleNextButtonClick}
        submitting={submitting}
        isRequestForm={isRequestForm || false}
        handleOpenShowSearchBox={handleOpenShowSearchBox}
      />
    )}
  </div>
);

export default withTheme(SelectOutlineWrapperTemplate);
