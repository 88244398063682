import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createTheme } from '@this/constants/themes';
import ErrorBoundary from '@this/shared/error_boundary/error_boundary';
import UserSignIn from '@this/components/user_signin';
import Arrangement from '@this/components/arrangement/arrangement';
import useApi from '@this/shared/hooks/use_api';
import { ConfirmModalContainer } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import { AITThemeProvider } from '@this/shared/ui/theme';
import ArrangerPasswordReset from '@this/components/arrangement/arranger_password/arranger_reset_password';
import ArrangerUserEdit from '@this/components/arrangement/arranger_user_edit/arranger_user_edit';

export const ArrangementRoot = () => {
  const { data, isLoading } = useApi<{ service_id: number; me: any }>('/arrangement/me');
  const serviceId = data?.service_id ?? 1;
  const theme = createTheme(serviceId);

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <AITThemeProvider theme={theme}>
        <ConfirmModalContainer>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/arrangers/sign_in"
                render={props => <UserSignIn serviceId={serviceId} {...props} />}
              />
              <Route exact path="/arrangers/password/edit" component={ArrangerPasswordReset} />
              <Route exact path="/arrangers/edit" component={ArrangerUserEdit} />
              <Route path="/arrangement" render={props => <Arrangement serviceId={serviceId} {...props} />} />
            </Switch>
          </BrowserRouter>
        </ConfirmModalContainer>
      </AITThemeProvider>
    </ErrorBoundary>
  );
};
