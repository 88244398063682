import _ from 'lodash';
import { observable } from 'mobx';

export type DataType = 'freetext' | 'multilinefreetext' | 'calendar' | 'list' | 'file' | 'label';
export type Display = 'display' | 'hidden';
export type Target = 'before_business_trip' | 'report_after_business_trip';
export type TargetSimpleRequest = 'simple_request_trip';
export type RequiredType = 'required' | 'optional' | 'required_with_workflow';
export type RequiredTypeForReport = 'required' | 'optional';
export type BusinessTripType = 'all' | 'domestic' | 'oversea';

export const dataTypeOptions: Record<DataType, string> = {
  freetext: 'テキスト自由入力(1行)',
  multilinefreetext: 'テキスト自由入力(複数行)',
  calendar: 'カレンダー',
  list: 'リスト',
  file: 'ファイル',
  label: 'ラベルのみ'
};
export const displayOptions: Record<Display, string> = {
  display: '表示する',
  hidden: '表示しない'
};
export const businessTripTypeOptions: Record<BusinessTripType, string> = {
  all: 'すべて',
  domestic: '国内出張のみ',
  oversea: '海外出張のみ'
};
export const targetOptions: Record<Target, string> = {
  before_business_trip: '出張前の申請',
  report_after_business_trip: '出張後の報告'
};
export const requiredTypeOptions: Record<RequiredType, string> = {
  required: '必須にする',
  required_with_workflow: 'ワークフロー有効時必須にする',
  optional: '必須にしない'
};
export const requiredTypeForReportOptions: Record<RequiredTypeForReport, string> = {
  required: '必須にする',
  optional: '必須にしない'
};

export type ApproveItemArgs = {
  id: number;
  user_display_name: string;
  data_type: DataType;
  target: Target;
  display: Display;
  required_type: RequiredType | null;
  placeholder: string;
  business_trip_type: BusinessTripType | null;
  itemList: string;
  default_item: number | null;
  default_item_code: string | null;
  default_item_name: string | null;
};

interface ApproveItemListResponse {
  approve_item_lists: string;
}

class ApproveItem {
  id: number;

  @observable
  userDisplayName: string;

  @observable
  dataType: DataType;

  @observable
  target: Target | TargetSimpleRequest;

  @observable
  display: Display;

  @observable
  requiredType: RequiredType | '';

  @observable
  placeholder: string;

  @observable
  isBusinessTripTypeDomestic: boolean | undefined;

  @observable
  isBusinessTripTypeOversea: boolean | undefined;

  @observable
  itemList: string;

  @observable
  defaultItem: number | null;

  @observable
  defaultItemCode: string | null;

  @observable
  defaultItemName: string | null;

  private approveItemListPath: string;

  constructor(args: ApproveItemArgs) {
    this.id = args.id;
    this.userDisplayName = args.user_display_name;
    this.dataType = args.data_type;
    this.target = args.target;
    this.display = args.display;
    this.requiredType = args.required_type ? args.required_type : '';
    this.placeholder = args.placeholder;
    this.businessTripType = args.business_trip_type;
    this.itemList = args.itemList;
    this.defaultItem = args.default_item;
    this.defaultItemCode = args.default_item_code;
    this.defaultItemName = args.default_item_name;
    this.approveItemListPath = `/organization/approve_items/${this.id}/item_lists`;
  }

  copy = (approveItem: ApproveItem) => {
    this.id = approveItem.id;
    this.userDisplayName = approveItem.userDisplayName;
    this.dataType = approveItem.dataType;
    this.target = approveItem.target;
    this.display = approveItem.display;
    this.requiredType = approveItem.requiredType;
    this.placeholder = approveItem.placeholder;
    this.businessTripType = approveItem.businessTripType;
    this.defaultItem = approveItem.defaultItem;
    this.defaultItemCode = approveItem.defaultItemCode;
    this.defaultItemName = approveItem.defaultItemName;
    this.approveItemListPath = `/organization/approve_items/${this.id}/item_lists`;
  };

  setTarget = (value: string) => {
    this.target = value as Target;
    if (value !== 'before_business_trip') this.requiredType = '';
    app.render();
  };

  setDefaultItem = (value: number | null) => {
    this.defaultItem = value;
    app.render();
  };

  setDefaultItemCode = (value: string | null) => {
    this.defaultItemCode = value;
  };

  setDefaultItemName = (value: string | null) => {
    this.defaultItemName = value;
  };

  getReservePlaceholder = (isRequiredWorkflowFlag: boolean) => {
    let requiredLavel = '';
    switch (this.requiredType) {
      case 'required':
        requiredLavel = '(必須)';
        break;
      case 'optional':
        requiredLavel = '(任意)';
        break;
      case 'required_with_workflow':
        if (isRequiredWorkflowFlag) {
          requiredLavel = '(必須)';
        } else {
          requiredLavel = '(任意)';
        }
        break;
      default:
        requiredLavel = '';
        break;
    }
    const placeholder = this.placeholder !== null ? this.placeholder : '';
    return requiredLavel + placeholder;
  };

  get isNeedPlaceholder(): boolean {
    return ['freetext', 'multilinefreetext'].includes(this.dataType);
  }

  get isNeedBusinessTripType(): boolean {
    return this.target === 'before_business_trip';
  }

  get dataTypeText() {
    return dataTypeOptions[this.dataType];
  }

  get targetText() {
    const targetOptionsWithSimpleRequest = {
      ...targetOptions,
      simple_request_trip: '簡易申請'
    };
    return targetOptionsWithSimpleRequest[this.target];
  }

  get displayText() {
    return displayOptions[this.display];
  }

  get requiedTypeText() {
    return requiredTypeOptions[this.requiredType as RequiredType];
  }

  get businessTripTypeText() {
    return businessTripTypeOptions[this.businessTripType as BusinessTripType];
  }

  get businessTripType(): BusinessTripType | null {
    if (this.isBusinessTripTypeDomestic && this.isBusinessTripTypeOversea) return 'all';
    if (this.isBusinessTripTypeDomestic && !this.isBusinessTripTypeOversea) return 'domestic';
    if (!this.isBusinessTripTypeDomestic && this.isBusinessTripTypeOversea) return 'oversea';
    return null;
  }

  set businessTripType(btt: BusinessTripType | null) {
    if (btt === 'all') {
      this.isBusinessTripTypeDomestic = true;
      this.isBusinessTripTypeOversea = true;
    } else if (btt === 'domestic') {
      this.isBusinessTripTypeDomestic = true;
      this.isBusinessTripTypeOversea = false;
    } else if (btt === 'oversea') {
      this.isBusinessTripTypeDomestic = false;
      this.isBusinessTripTypeOversea = true;
    } else {
      this.isBusinessTripTypeDomestic = false;
      this.isBusinessTripTypeOversea = false;
    }
  }

  async fetchApproveItemList() {
    await utils
      .jsonPromise<ApproveItemListResponse>(`${this.approveItemListPath}.json`)
      .then(
        result => {
          this.itemList = result.approve_item_lists;
        },
        _error => {
          throw _error;
        }
      )
      .catch(e => {
        throw e;
      });
  }

  submitParams = () => {
    const params = {
      user_display_name: this.userDisplayName,
      data_type: this.dataType,
      target: this.target,
      display: this.display,
      required_type: this.requiredType,
      business_trip_type: this.businessTripType,
      placeholder: '',
      item_list: '',
      default_item: null as number | null,
      default_item_code: null as string | null,
      default_item_name: null as string | null
    };
    if (this.dataType === 'freetext' || this.dataType === 'multilinefreetext') {
      params.placeholder = this.placeholder;
    }
    if (this.dataType === 'list') {
      params.item_list = this.itemList;
      params.default_item = this.defaultItem == null ? null : this.defaultItem;
      params.default_item_code = this.defaultItemCode == null ? null : this.defaultItemCode;
      params.default_item_name = this.defaultItemName == null ? null : this.defaultItemName;
    }
    if (this.target !== 'before_business_trip') {
      params.business_trip_type = 'all';
    }
    return params;
  };
}

export default ApproveItem;
