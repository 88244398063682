import React from 'react';
import JSONTree from 'react-json-inspector';

import { styled } from '@this/constants/themes';

type Props = {
  className?: string;
  json: any;
  label: string;
};

export const JsonViewer = ({ className, json, label }: Props) => {
  return (
    <Root className={className}>
      <p>{label}</p>
      <StyledJsonTree data={json} search={false} />
    </Root>
  );
};

const Root = styled.div``;

const StyledJsonTree = styled(JSONTree)`
  word-break: break-all;
`;
