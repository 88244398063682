import React from 'react';
import { styled } from '@this/constants/themes';
import Modal from '@this/shared/modal/modal';
import { Body, Text, Info, Buttons, Button } from './style';

import InfoTemplate from './info/info.template';

interface Props {
  selectedReport: any;
  requesting: boolean;
  errors: string[] | null;
  handleReportChange: (name: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleReject: () => void;
  handleCancelClick: () => void;
}

const RejectModalTemplate: React.SFC<Props> = ({
  selectedReport,
  requesting,
  errors,
  handleReportChange,
  handleReject,
  handleCancelClick
}) => (
  <Modal hideModal={() => handleCancelClick()} show title="承認">
    <Body>
      <Text>以下の出張報告を差し戻します。よろしいですか？</Text>
      <Info>
        <InfoTemplate selectedReport={selectedReport} />
      </Info>
      <Text>差し戻し理由</Text>
      <Info>
        <TextArea
          value={selectedReport.rejectReason || ''}
          onChange={e => handleReportChange('setRejectReason', e)}
          rows={4}
          cols={40}
        />
      </Info>
      {errors && errors.length > 0 && errors.map((e: string, i: number) => <ErrorText key={i}>{e}</ErrorText>)}
      <Buttons>
        <Button type="button" value="戻る" disabled={requesting} onClick={() => handleCancelClick()} />
        <Button type="button" value="差戻" disabled={requesting} onClick={() => handleReject()} />
      </Buttons>
    </Body>
  </Modal>
);

const TextArea = styled.textarea`
  width: 70%;
  margin: 5px 0 10px;
`;

const ErrorText = styled(Text)`
  color: ${props => props.theme.redColor};
`;

export default RejectModalTemplate;
