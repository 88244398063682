import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rgba } from 'polished';
import type { Moment } from 'moment';
import moment from 'moment';
import { TableCell, TableRow } from '@this/components/shared/ui/data_displays/table';
import { Link } from '@this/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Loading } from '@this/shared/ui/feedbacks/loading/loading';
import type {
  OrderItemSnoozeTodoCreationParams,
  OrderItemSnoozeTodoCancelParams
} from '@this/components/arrangement/todo_list/types';
import type OrderItem from '@this/domain/order_item';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import LogModal from '../log_modal';
import { orderItemTodoListCategories, orderItemRailwayTicketType } from '../order_item_todo_list_legacy';
import TripStatusIndicator from '../../shared/trip_status_indicator';

interface Props {
  orderItem: OrderItem;
  editing: boolean;
  onCreateSnooze: (params: OrderItemSnoozeTodoCreationParams) => Promise<void>;
  onCancelSnooze: (params: OrderItemSnoozeTodoCancelParams) => Promise<void>;
  onOpenForm: () => void;
  onCloseForm: () => void;
  showSp?: boolean;
  fetchOrderItems: () => void;
}

const getDefaultSnoozeTo = () => {
  const now = moment();
  return moment({
    years: now.get('year'),
    months: now.get('month'),
    days: now.get('date'),
    hours: 9,
    minutes: 0
  });
};

const OrderItemCard = observer(
  ({
    orderItem,
    editing,
    onCreateSnooze,
    onCancelSnooze,
    onOpenForm,
    onCloseForm,
    showSp,
    fetchOrderItems
  }: Props) => {
    const [snoozeTo, setSnoozeTo] = useState<Moment>(getDefaultSnoozeTo());
    const [memo, setMemo] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);
    const [canceling, setCanceling] = useState<boolean>(false);
    const [showLogModal, setShowLogModal] = useState<boolean>(false);

    const trip = orderItem.order.trip;
    const categoryStr =
      orderItem.orderItemCategory === 'shinkansen'
        ? orderItem.transports[0].railwayTicketType === orderItemRailwayTicketType.exic
          ? orderItemTodoListCategories.exic
          : orderItemTodoListCategories.shinkansen
        : orderItem.categoryStr();

    const isUrgent = (trip: any): boolean => {
      const urgentTripStatuses = [3, 0, 4]; // PAID, REQUESTED, FIXED
      const yesterday = moment(new Date()).subtract(1, 'day');
      const afterThreeDays = moment(new Date()).add(3, 'days');
      const startAt = moment(trip.startTime);

      return startAt.isAfter(yesterday, 'date') &&
        startAt.isBefore(afterThreeDays, 'date') &&
        trip.status &&
        trip.status >= 0 &&
        trip.status <= 7
        ? urgentTripStatuses.includes(trip.status)
        : false;
    };

    const saveSnooze = async () => {
      setSaving(true);
      const params: OrderItemSnoozeTodoCreationParams = {
        order_item_id: orderItem.id,
        snooze_to: snoozeTo.format('YYYY-MM-DD hh:mm'),
        memo
      };
      onCreateSnooze(params).finally(() => setSaving(false));
    };

    const cancelSnooze = async () => {
      setCanceling(true);
      const params: OrderItemSnoozeTodoCancelParams = {
        order_item_id: orderItem.id
      };
      onCancelSnooze(params).finally(() => setCanceling(false));
    };

    return (
      <>
        <TableRow
          key={orderItem.id}
          style={{
            border: '1px solid $gray-color-border',
            padding: '2px',
            marginBottom: '10px',
            background: isUrgent(trip) ? rgba('red', 0.3) : 'white'
          }}
        >
          <TableCell>
            <TripStatusIndicator status={trip.status as TripStatusKey} />
          </TableCell>
          <TableCell>
            <OrderItemStatusForm
              orderItemId={orderItem.id}
              jobType={orderItem.jobType}
              status={orderItem.status as OrderItemStatusKey}
              onJobTypeChange={value => orderItem.handleJobTypeChange(value)}
              onStatusChange={value => orderItem.handleStatusChange(value)}
              showLabel={false}
              fetchData={fetchOrderItems}
            />
          </TableCell>
          <TableCell>{_.last(orderItem.orderItemStatusLogs)?.arrangerName}</TableCell>
          <TableCell>{categoryStr}</TableCell>
          <TableCell>{orderItem.organizationName}</TableCell>
          {showSp && <TableCell>{orderItem.includeSp ? '◯' : '×'}</TableCell>}
          <TableCell>{orderItem.waiting ? '◯' : '×'}</TableCell>
          <TableCell nowrap>
            {orderItem.startDateStr()}〜{orderItem.endDateStr()}
          </TableCell>
          <TableCell nowrap>{trip.receivedAt && trip.receivedAt.format('YYYY-MM-DD')}</TableCell>
          <TableCell>{trip.latestUserMessage}</TableCell>
          <TableCell nowrap>
            <Link href={`/arrangement/virtual_counter?trip_id=${trip.id}`} target="_blank" rel="noopener noreffer">
              {trip.id}
            </Link>
          </TableCell>
          <TableCell nowrap>{orderItem.traceId}</TableCell>
          <TableCell>
            {orderItem.orderItemTodoMessageLog && (
              <>
                <span>{orderItem.orderItemTodoMessageLog.body}</span>
                <span>({orderItem.orderItemTodoMessageLog.arrangerName})</span>
              </>
            )}
            <p>
              (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
            </p>
          </TableCell>
          <TableCell>
            {(() => {
              const snooze = orderItem.currentSnooze();
              if (snooze) {
                return (
                  <>
                    <div>{snooze.snoozeTo.format('YYYY-MM-DD hh:mm')}</div>
                    <div>{snooze.memo}</div>
                    {canceling ? <Loading /> : <Button onClick={cancelSnooze}>TODOに戻す</Button>}
                  </>
                );
              }
              return saving ? (
                <Loading />
              ) : editing ? (
                <>
                  <DatetimePicker
                    value={snoozeTo}
                    onChange={d => setSnoozeTo(d)}
                    showTime
                    showPast={false}
                    disabledDays={0}
                  />
                  <input type="text" placeholder="メモ" value={memo} onChange={e => setMemo(e.target.value)} />
                  <Box display="flex">
                    <Button size="small" onClick={() => onCloseForm()}>
                      キャンセル
                    </Button>
                    <Button size="small" style={{ marginLeft: '10px' }} onClick={saveSnooze}>
                      保存
                    </Button>
                  </Box>
                </>
              ) : (
                <Button size="small" onClick={() => onOpenForm()}>
                  設定
                </Button>
              );
            })()}
          </TableCell>
        </TableRow>
        {showLogModal && orderItem.traceId && (
          <LogModal traceId={orderItem.traceId} onClose={() => setShowLogModal(false)} />
        )}
      </>
    );
  }
);

export default OrderItemCard;
