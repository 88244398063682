import _ from 'lodash';

export interface FlightMarketLogCandidateJson {
  transport_descriptions: string[];
  price: number;
}

class FlightMarketLogCandidate {
  transportDescriptions: string[];

  price: number;

  constructor(args: FlightMarketLogCandidateJson) {
    this.transportDescriptions = args.transport_descriptions;
    this.price = args.price;
  }

  description() {
    return this.transportDescriptions.map((d, i) => `経路${i + 1}\n${d}`).join('\n');
  }
}

export default FlightMarketLogCandidate;
