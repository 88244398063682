import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@this/constants/themes';
import type { Department } from './context';

interface Props {
  departmentId: number | null;
  onSelect: (departmentId: number | null) => void;
  departments: Department[];
}

interface State {
  departments: Department[];
  open: boolean;
}

export type DepartmentSelectListProps = Props;

const DepartmentSelectList: React.FC<Props> = ({ departmentId, onSelect, departments }) => {
  const [state, setState] = useState<State>({ departments, open: false });
  const { open } = state;
  const department = useMemo(() => departments.find(d => d.id === departmentId), [departments, departmentId]);
  const workflowFormWidth = '240px';

  const handleToggle = useCallback(() => {
    setState(state => ({ ...state, open: !state.open }));
  }, []);

  const handleSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const keyword = (event.target as HTMLInputElement).value;
      const filtered = departments.filter(
        department => department.code.indexOf(keyword) >= 0 || department.name.indexOf(keyword) >= 0
      );
      setState(state => ({ ...state, departments: filtered }));
    },
    [departments]
  );

  const handleSelect = useCallback(
    (departmentId: number | null) => () => {
      onSelect(departmentId);
      setState(state => ({ ...state, open: false }));
    },
    [onSelect]
  );

  return (
    <SelectDepartmentsWrapper workflowFormWidth={workflowFormWidth}>
      <SelectDepartmentsListItem data-testid="fs_open_box" onClick={handleToggle}>
        {department ? (
          <SelectDepartmentsItemSelected>
            <p>
              {department.code && `${department.code}:`}
              {department.name}
            </p>
          </SelectDepartmentsItemSelected>
        ) : (
          <SelectDepartmentsItemMessage>部署を選択してください</SelectDepartmentsItemMessage>
        )}
        <SelectDepartmentsTriangle>{open ? '▲' : '▼'}</SelectDepartmentsTriangle>
      </SelectDepartmentsListItem>
      {open && (
        <DepartmentsListItemLists>
          <DepartmentListsInput
            type="text"
            id="departments-list-input"
            defaultValue=""
            placeholder="検索"
            onKeyUp={handleSearch}
          />
          <DepartmentsListUl workflowFormWidth={workflowFormWidth}>
            <DepartmentsListItemEnabled onClick={handleSelect(null)}>選択を外す</DepartmentsListItemEnabled>
            {state.departments.map((department, i) => (
              <DepartmentsListItemEnabled key={i} onClick={handleSelect(department.id)}>
                <DepartmentsListItemInfo>
                  <p>
                    {department.code && `${department.code}:`}
                    {department.name}
                  </p>
                </DepartmentsListItemInfo>
              </DepartmentsListItemEnabled>
            ))}
          </DepartmentsListUl>
        </DepartmentsListItemLists>
      )}
    </SelectDepartmentsWrapper>
  );
};

const SelectDepartmentsWrapper = styled.div<{ workflowFormWidth?: string }>`
  width: ${props => props.workflowFormWidth || '100%'};
  font-size: 13px;
  margin-bottom: 5px;
  line-height: normal;
`;

const SelectDepartmentsListItem = styled.div`
  display: flex;
  padding: 5px 10px;
  border: 2px solid ${props => props.theme.grayBorderColor};
  cursor: pointer;
`;

const SelectDepartmentsItemSelected = styled.div`
  display: flex;
  flex: wrap;
`;

const SelectDepartmentsItemMessage = styled.p`
  color: $black-color;
`;

const SelectDepartmentsTriangle = styled.div`
  margin-left: auto;
`;

const DepartmentsListItemLists = styled.div`
  margin: 0;
  position: absolute;
  background: ${props => props.theme.fieldBgColor};
`;

const DepartmentListsInput = styled.input`
  &&& {
    width: 100%;
    margin-bottom: 0;
    border-top: none;
  }
`;

const DepartmentsListUl = styled.ul<{ workflowFormWidth?: string }>`
  overflow-y: scroll;
  max-height: 150px;
  width: ${props => props.workflowFormWidth || '420px'};
  border-bottom: 2px solid ${props => props.theme.grayBorderColor};
`;

const DepartmentsListItemEnabled = styled.li`
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  width: 100%;
  line-height: 30px;
  padding:  0 10px
  cursor: pointer;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 2px solid ${props => props.theme.grayBorderColor};
  border-top: none;
  z-index: 100;
`;

const DepartmentsListItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DepartmentSelectList;
