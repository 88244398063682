import React, { createContext, useContext, useEffect, useMemo } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { styled } from '@this/constants/themes';
import { Button, TableCell } from '@material-ui/core';
import type { ExpenseTool } from '@this/src/domain/setting';
import type {
  CalcPriceOrder,
  CalcPriceOrderOptions,
  AllowanceItemForReport,
  TripTypeOptions
} from '@this/src/domain/trip_report/allowance_item';
import DatetimePicker from '@this/src/components/shared/datetime_picker/datetime_picker';

type Props = {
  changePrice: boolean;
  item: AllowanceItemForReport;
  index: number;
  expenseTool?: ExpenseTool;
  tripTypeOptions: TripTypeOptions;
  calcPriceOrderOptions: CalcPriceOrderOptions;
  setChangePrice: Dispatch<SetStateAction<boolean>>;
  removeAllowanceItem: () => void;
};

export class AllowanceItemsFormStore {
  calcPriceOrders: Record<string, CalcPriceOrder> = {};

  useIcs: Record<string, boolean> = {};

  mount(serial: string) {
    if (Object.keys(this.calcPriceOrders).includes(serial)) return;

    this.calcPriceOrders[serial] = 'price';
    this.useIcs[serial] = true;
    app.render();
  }
}

export const AllowanceItemsFormContext = createContext<AllowanceItemsFormStore>(
  null as unknown as AllowanceItemsFormStore
);

export const useAllowanceItemsFormStore = () => useContext(AllowanceItemsFormContext);

export const TripReportTripSelectedAllowanceItemsForm: React.FC<Props> = ({
  changePrice,
  item,
  // index,
  expenseTool,
  setChangePrice,
  removeAllowanceItem
}) => {
  const store = useAllowanceItemsFormStore();
  const colSpan = useMemo(() => 5 + (expenseTool !== 'none' ? 1 : 0), [expenseTool]);

  useEffect(() => {
    store.mount(item.serial);
  }, [item.serial]);

  return (
    <TableCell colSpan={colSpan}>
      <AllowanceItemForm>
        <AllowanceItemRow>
          <Value>
            {item.availableTime() ? (
              <DatetimePicker
                value={item.time || undefined}
                onChange={value => item.setField('time', value)}
                showPast
                border
                placeholder="日付を選択"
              />
            ) : (
              item.getPeriod()
            )}
          </Value>
          {item.validationErrors.time && <Error>{item.validationErrors.time}</Error>}
        </AllowanceItemRow>
        <AllowanceItemRow>
          <Input
            type="number"
            value={item.price?.toString() || ''}
            onChange={e => item.setField('price', e.target.value !== '' ? parseInt(e.target.value, 10) : null)}
            placeholder="金額"
          />
          {item.validationErrors.price && <Error>{item.validationErrors.price}</Error>}
        </AllowanceItemRow>
        <AllowanceItemRow>
          <Select
            className="input"
            value={item.allowanceType || ''}
            onChange={e => item.setAllowanceType(e.target.value)}
          >
            <option value="">種別を選択を選択</option>
            <option value="half_day">半日</option>
            <option value="one_day">1日</option>
          </Select>
          {item.validationErrors.allowanceType && <Error>{item.validationErrors.allowanceType}</Error>}
        </AllowanceItemRow>
        <Actions>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => (item.adding ? removeAllowanceItem() : item.setEditing(false, { cancel: true }))}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              item.setEditing(false);
              setChangePrice(!changePrice);
            }}
          >
            保存
          </Button>
        </Actions>
      </AllowanceItemForm>
    </TableCell>
  );
};

const Error = styled.div`
  color: ${props => props.theme.redColor};
  margin-top: -0.75em;
  margin-bottom: 0.75em;
  padding: 0 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Value = styled.div`
  margin-right: 10px;
  margin-bottom: 0.75em;
  font-size: 13px;
`;

const Input = styled.input<{ width?: string }>`
  &&& {
    margin-right: 10px;
    font-size: 13px;
    ${props =>
      props.width &&
      `
      width: ${props.width};
      max-width: 100%;
    `}

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const Select = styled.select`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

const AllowanceItemForm = styled.form`
  max-width: 400px;
`;

const AllowanceItemRow = styled.div``;
