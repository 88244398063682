import { action, observable, computed } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';
import _ from 'lodash';

import type { BulkTicketRouteArgs } from './bulk_ticket_route2';
import BulkTicketRoute from './bulk_ticket_route2';
import type { BulkTicketUnavailableTermArgs } from './bulk_ticket_unavailable_term2';
import BulkTicketUnavailableTerm from './bulk_ticket_unavailable_term2';
import type { BulkTicketFragmentArgs } from './bulk_ticket_fragment';
import BulkTicketFragment from './bulk_ticket_fragment';

export type BulkTicketArgs = Partial<{
  term: BulkTicketUnavailableTermArgs;
  route: BulkTicketRouteArgs;
  bulk_ticket_fragments: BulkTicketFragmentArgs[];
}> & {
  id: number;
  unused: number;
  amount: number;
  price: number;
  route_id: number;
  unavailable_term_id: number;
  seat_type: 'reserved' | 'unreserved' | 'green';
  bulk_ticket_number: string;
  ticket_type: 'both' | 'basic' | 'express';
  purchased_at: string;
  fee: number;
  available_from: string;
  expire_at: string;
};

export const seatTypes = { reserved: '指定席', unreserved: '自由席', green: 'グリーン車' } as const;
export const ticketTypes = { both: '乗車券＋特急券', basic: '乗車券', express: '特急券' } as const;
export default class BulkTicket {
  @observable
  id: number;

  @observable
  amount: number;

  @observable
  price: number;

  @observable
  seatType: keyof typeof seatTypes;

  @observable
  ticketType: keyof typeof ticketTypes;

  @observable
  unused: number;

  @observable
  routeId: number;

  @observable
  unavailableTermId: number;

  @observable
  bulkTicketNumber: string;

  @observable
  route?: BulkTicketRoute;

  @observable
  term?: BulkTicketUnavailableTerm;

  @observable
  purchasedAt: Moment;

  @observable
  fee: number;

  @observable
  availableFrom: Moment;

  @observable
  expireAt: Moment;

  @observable
  bulkTicketFragments: BulkTicketFragment[];

  constructor(args: BulkTicketArgs) {
    this.id = args.id;
    this.unused = args.unused;
    this.amount = args.amount;
    this.price = args.price;
    this.routeId = args.route_id;
    this.unavailableTermId = args.unavailable_term_id;
    this.seatType = args.seat_type;
    this.ticketType = args.ticket_type;
    this.bulkTicketNumber = args.bulk_ticket_number;
    this.purchasedAt = moment(args.purchased_at);
    this.availableFrom = moment(args.available_from);
    this.expireAt = moment(args.expire_at);
    this.fee = args.fee;
    if (args.bulk_ticket_fragments) {
      this.bulkTicketFragments = args.bulk_ticket_fragments.map(
        (args: BulkTicketFragmentArgs) => new BulkTicketFragment(args)
      );
    } else {
      this.bulkTicketFragments = [];
    }
    if (args.route) {
      this.route = new BulkTicketRoute(args.route);
    }
    if (args.term) {
      this.term = new BulkTicketUnavailableTerm(args.term);
    }
  }

  // @unusedはサーバーには送らない、クライアント側で回数券の残数を"予測"するための変数
  // (利用のコンフリクトなどが起きうるため)
  // 利用の紐づけ・解除が手動なので、あくまでも目安
  @action
  use() {
    this.unused -= 1;
  }

  @action
  cancelUse() {
    this.unused += 1;
  }

  @computed
  get unuseCount() {
    return this.bulkTicketFragments
      ? _.filter(this.bulkTicketFragments, f => {
          return f.status === 0;
        }).length
      : 0;
  }

  @computed
  get pricePerTicket() {
    if (this.amount === 0) {
      return 0;
    }
    return this.price / this.amount;
  }

  routeDescription(routes?: BulkTicketRoute[]) {
    let route;
    if (routes) {
      route = routes.find(route => route.id === this.routeId);
      if (route) {
        return route.description;
      }
      return '';
    }
    return this.route ? this.route.description : '';
  }

  termDescription(terms: BulkTicketUnavailableTerm[]) {
    if (terms) {
      const term = terms.find(term => term.id === this.unavailableTermId);
      if (term) {
        return term.description;
      }
      return '';
    }
    return this.term ? this.term.description : '';
  }

  @computed
  get seatTypeDescription() {
    return seatTypes[this.seatType];
  }

  @computed
  get ticketTypeDescription() {
    return ticketTypes[this.ticketType];
  }

  @computed
  get summary() {
    return `${this.bulkTicketNumber || ''}:${this.routeDescription()}  残数:${
      _.filter(this.bulkTicketFragments, f => f.status === 0).length
    } 購入日:${this.purchasedAt.toString()}`;
  }

  submitParams() {
    return {
      bulk_ticket_number: this.bulkTicketNumber,
      route_id: this.routeId,
      seat_type: this.seatType,
      ticket_type: this.ticketType,
      amount: this.amount,
      price: this.price,
      fee: this.fee,
      purchased_at: this.purchasedAt.toString(),
      available_from: this.availableFrom.toString(),
      expire_at: this.expireAt.toString(),
      unavailable_term_id: this.unavailableTermId
    };
  }

  submitFragmentsParams() {
    if (!this.bulkTicketFragments) {
      return [];
    }
    const fragments = this.bulkTicketFragments.map(f => {
      return {
        id: f.id,
        bulk_ticket_id: this.id,
        number: f.number,
        price: f.price,
        status: f.status,
        repaid_at: f.repaidAt.toString()
      };
    });
    return { fragments };
  }
}
