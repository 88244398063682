import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { InvoiceCustomFormatTitle } from '@this/components/organization/invoice_custom_formats/invoice_custom_formats.style';
import InvoiceCustomFormatFieldFormulaForm from '@this/components/organization/invoice_custom_formats/invoice_custom_format_field_formula_form';
import type InvoiceCustomFormatField from '@this/src/domain/invoice_custom_format/invoice_custom_format_field';
import type { ColumnType } from '@this/src/domain/invoice_custom_format/invoice_custom_format_field';
import type InvoiceStandardTable from '@this/src/domain/invoice_custom_format/invoice_standard_table';

interface Props {
  invoiceCustomFormatField: InvoiceCustomFormatField;
  invoiceStandardTables: InvoiceStandardTable[];
  onConfirm: () => void;
  onCancel: () => void;
}

const InvoiceCustomFormatFieldForm = observer(
  ({ invoiceCustomFormatField, invoiceStandardTables, onConfirm, onCancel }: Props) => {
    const defaultTable = invoiceStandardTables.find(t => {
      const fieldIds = t.invoiceStandardFields.map(f => f.id);
      const id = invoiceCustomFormatField.invoiceStandardFieldId;
      return id && fieldIds.includes(id);
    });

    const [selectedTable, setSelectedTable] = useState<InvoiceStandardTable | null>(
      defaultTable || invoiceStandardTables[0] || null
    );
    const [errors, setErrors] = useState<string[]>([]);

    const handleConfirm = () => {
      setErrors([]);
      const errs = invoiceCustomFormatField.validate(selectedTable?.isDepartmentTable || false);
      setErrors(errs);
      if (errs.length > 0) return;
      onConfirm();
    };

    return (
      <form>
        <InvoiceCustomFormatTitle>カラム設定</InvoiceCustomFormatTitle>
        <p style={{ margin: '20px 0' }}>カラムを設定してください。</p>
        <div>
          <div className="flex">
            <FieldLabel htmlFor="name">カラム名</FieldLabel>
            <div style={{ flexGrow: 1 }}>
              <input
                id="name"
                type="text"
                value={invoiceCustomFormatField.name}
                onChange={e => {
                  invoiceCustomFormatField.name = e.target.value;
                }}
              />
            </div>
          </div>
          <div className="flex">
            <FieldLabel htmlFor="column_type">種別</FieldLabel>
            <select
              id="column_type"
              value={invoiceCustomFormatField.columnType}
              onChange={e => {
                invoiceCustomFormatField.columnType = e.target.value as ColumnType;
              }}
            >
              <option value="standard">標準カラム</option>
              <option value="fixed">固定値</option>
              <option value="formula">数式</option>
            </select>
          </div>
          {invoiceCustomFormatField.columnType === 'standard' ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              <div className="flex">
                <FieldLabel htmlFor="invoice_standard_table_id">テーブル</FieldLabel>
                <select
                  id="invoice_standard_table_id"
                  value={selectedTable?.id || ''}
                  onChange={e => {
                    setSelectedTable(
                      invoiceStandardTables.find(t => t.id === parseInt(e.target.value, 10)) || null
                    );
                    invoiceCustomFormatField.invoiceStandardFieldId = undefined;
                  }}
                >
                  {invoiceStandardTables.map(t => (
                    <option key={t.id} value={t.id}>
                      {t.displayName}
                    </option>
                  ))}
                </select>
              </div>
              {selectedTable?.name === 'departments' && (
                <div className="flex">
                  <label htmlFor="department_target" style={{ marginRight: '5px' }}>
                    部署種別
                  </label>
                  <select
                    id="department_target"
                    value={invoiceCustomFormatField.invoiceStandardFieldDepartmentTarget}
                    onChange={e => {
                      const val = e.target.value as 'user_department' | 'charging_department' | undefined;
                      invoiceCustomFormatField.invoiceStandardFieldDepartmentTarget = val;
                    }}
                  >
                    <option value={undefined}>選択してください</option>
                    <option value="user_department">所属部署</option>
                    <option value="charging_department">費用負担先部署</option>
                  </select>
                </div>
              )}
              <div className="flex">
                <label htmlFor="invoice_standard_field_id" style={{ marginRight: '5px' }}>
                  テーブルの項目
                </label>
                <select
                  id="invoice_standard_field_id"
                  value={invoiceCustomFormatField.invoiceStandardFieldId}
                  onChange={e => {
                    invoiceCustomFormatField.invoiceStandardFieldId = parseInt(e.target.value, 10);
                  }}
                >
                  <option value="">選択してください</option>
                  {selectedTable?.invoiceStandardFields.map(f => (
                    <option key={f.id} value={f.id}>
                      {f.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : invoiceCustomFormatField.columnType === 'fixed' ? (
            <div className="flex">
              <FieldLabel htmlFor="fixed_value">表示内容</FieldLabel>
              <div style={{ flexGrow: 1 }}>
                <textarea
                  id="fixed_value"
                  value={invoiceCustomFormatField.fixedValue}
                  onChange={e => {
                    invoiceCustomFormatField.fixedValue = e.target.value;
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="flex">
              <FieldLabel htmlFor="formula">数式エリア</FieldLabel>
              <div style={{ flexGrow: 1 }}>
                <InvoiceCustomFormatFieldFormulaForm
                  invoiceCustomFormatField={invoiceCustomFormatField}
                  invoiceStandardTables={invoiceStandardTables}
                />
              </div>
            </div>
          )}
        </div>
        <div style={{ margin: '20px 0' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button onClick={handleConfirm}>決定</Button>
            <Button onClick={onCancel}>もどる</Button>
          </div>
          <div style={{ marginTop: '10px' }}>
            {errors.map((error, i) => (
              <p key={i} className="error">
                {error}
              </p>
            ))}
          </div>
        </div>
      </form>
    );
  }
);

const FieldLabel = styled.label`
  width: 110px;
`;

export default InvoiceCustomFormatFieldForm;
