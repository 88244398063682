import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import useApi from '@this/shared/hooks/use_api';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { Moment } from 'moment';
import moment from 'moment';
import YearMonthSelector from '@this/shared/year_month_selector/year_month_selector';
import type { RouteChildrenProps } from 'react-router';
import { Title, Content } from '../god';

interface Record {
  serviceName: string;
  plans: number;
  plansPrice: number;
  optionPlans: number;
  optionPlansPrice: number;
  ticketCount: number;
}

interface RecordResponse {
  service_name: string;
  plans: number;
  plans_price: number;
  option_plans: number;
  option_plans_price: number;
  ticket_count: number;
}

interface DashboardResponse {
  records: RecordResponse[];
}

const getInitialDate = () => {
  const d = moment();
  const year: string | undefined = utils.getParam('year');
  if (year) {
    d.set('year', parseInt(year, 10));
  }
  const month: string | undefined = utils.getParam('month');
  if (month) {
    d.set('month', parseInt(month, 10) - 1);
  }
  return d;
};

const GodDashboard: React.FC<RouteChildrenProps> = (props: RouteChildrenProps) => {
  const [date, setDate] = useState(getInitialDate());
  const params = { year: date.year(), month: date.month() + 1 };
  const { data, isLoading } = useApi<DashboardResponse>('/god/dashboard.json', params);

  useEffect(() => {
    const year = date.year();
    const month = date.month() + 1;
    const query = _.join([`year=${year}`, `month=${month}`], '&');
    props.history.push(`/god?${query}`);
  }, [date, props.history]);

  const records: Record[] = data
    ? data.records.map(raw => {
        return {
          serviceName: raw.service_name,
          plans: raw.plans,
          plansPrice: raw.plans_price,
          optionPlans: raw.option_plans,
          optionPlansPrice: raw.option_plans_price,
          ticketCount: raw.ticket_count
        };
      })
    : [];

  const onYearMonthChange = useCallback(
    (d: Moment) => {
      setDate(d);
    },
    [date.year(), date.month()]
  );

  return (
    <div>
      <Title>ダッシュボード</Title>
      <Content>
        <YearMonthSelector date={date} onChange={date => onYearMonthChange(date)} />
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>サービス</Th>
                <Th>有効プラン数</Th>
                <Th>有効プラン金額</Th>
                <Th>有効オプションプラン数</Th>
                <Th>有効オプションプラン金額</Th>
                <Th>チケット上限合計</Th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, i) => (
                <tr key={i}>
                  <Td>{record.serviceName}</Td>
                  <TdRight>{record.plans}</TdRight>
                  <TdRight>{utils.formatPriceWithMark(record.plansPrice)}</TdRight>
                  <TdRight>{record.optionPlans}</TdRight>
                  <TdRight>{utils.formatPriceWithMark(record.optionPlansPrice)}</TdRight>
                  <TdRight>{record.ticketCount}</TdRight>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Content>
    </div>
  );
};

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 4px;
`;

const Td = styled.td`
  padding: 4px;
`;

const TdRight = styled(Td)`
  text-align: right;
`;

export default GodDashboard;
