import { observable } from 'mobx';

export interface RakurakuSettingArgs {
  setting_id?: number;
  exic_order_data_stop_provide?: boolean;
}

export class RakurakuSetting {
  public settingId?: number;

  @observable
  exicOrderDataStopProvide: boolean;

  constructor(args: RakurakuSettingArgs) {
    this.settingId = args?.setting_id;
    this.exicOrderDataStopProvide = args?.exic_order_data_stop_provide || false;
  }

  submitParams() {
    return {
      exic_order_data_stop_provide: this.exicOrderDataStopProvide
    };
  }
}

export default RakurakuSetting;
