import type { BulkActionAttr } from './types';

export const CREATE_FORMATS: BulkActionAttr[] = [
  {
    name: 'code',
    summary: 'プロジェクトコード',
    example: '',
    required: true
  },
  {
    name: 'name',
    summary: 'プロジェクト名',
    example: '',
    required: true
  },
  {
    name: 'disabled',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true
  }
];
