import _ from 'lodash';
import NonOrderItemPriceDetail from './non_order_item_price_detail';

interface Args {
  price: number;
  elementType?: string;
  details?: any[];
}

class NonOrderItemPrice {
  price: number;

  details: NonOrderItemPriceDetail[];

  constructor(args: Args) {
    this.price = args.price || 0;
    this.details = args.details
      ? _.map(args.details, detail => new NonOrderItemPriceDetail(detail))
      : args.elementType === 'rental_car'
      ? [new NonOrderItemPriceDetail({ purpose: 'rental_car_gas' })]
      : [];
  }

  setPrice(value: number) {
    this.price = value;
    app.render();
  }

  totalPrice() {
    return this.price + (this.details.length > 0 ? this.details[0].price : 0);
  }

  updateParams() {
    return {
      price: this.price,
      details: _.map(this.details, detail => detail.updateParams())
    };
  }
}

export default NonOrderItemPrice;
