import React, { useCallback, useState } from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import type { Moment } from '@this/src/lib/moment';
import { styled } from '@this/src/components/constants/themes';
import { Fetcher } from '@this/src/util';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import { createEndpoint } from './fetch';

interface Props {
  cacheType?: string;
  aggregateBy?: string;
  dateType: string;
  from: Moment;
  to: Moment;
  organizationId?: number;
  lastUpdatedAt?: Moment | null;
  onRefreshCache: () => void;
}

export const RefreshCacheButton: React.FC<Props> = ({
  cacheType,
  aggregateBy,
  dateType,
  from,
  to,
  organizationId,
  lastUpdatedAt,
  onRefreshCache
}) => {
  const [loading, setLoading] = useState(false);

  const handleRefreshCache = useCallback(() => {
    const params = {
      cache_type: cacheType,
      aggregate_by: aggregateBy,
      date_type: dateType,
      start_date: from.format('YYYY-MM-DD'),
      end_date: to.format('YYYY-MM-DD')
    };

    setLoading(true);
    Fetcher.post(createEndpoint('refresh_cache', organizationId), params)
      .then(() => onRefreshCache())
      .finally(() => setLoading(false));
  }, [cacheType, aggregateBy, dateType, from, to, organizationId, onRefreshCache, setLoading]);

  return (
    <Flex>
      {loading ? (
        <SimpleLoading size={18} style={{ flexGrow: 'inherit', padding: '12px' }} />
      ) : (
        <IconButton onClick={handleRefreshCache}>
          <CachedIcon color="primary" />
        </IconButton>
      )}
      {lastUpdatedAt === undefined && <span>Refresh</span>}
      {lastUpdatedAt && <span>{lastUpdatedAt.fromNow()}</span>}
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
