import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import EncodingSelector from '@this/shared/encoding_selector/encoding_selector';
import Modal from '@this/shared/modal/modal';
import { Button } from '@this/shared/ui/inputs/button';

import type { InvoiceArgs } from '@this/domain/invoice/invoice2';
import Invoice from '@this/domain/invoice/invoice2';
import jsonPromiseContext from '@this/constants/json_promise_context';
import type { OrganizationAnnouncementArg } from '@this/domain/organization_announcement/organization_announcement';
import OrganizationAnnouncement from '@this/domain/organization_announcement/organization_announcement';
import InvoiceCustomFormat from '@this/src/domain/invoice_custom_format/invoice_custom_format';
import type { InvoiceCustomFormatArgs } from '@this/domain/invoice_custom_format/invoice_custom_format';
import { Link } from '@this/shared/ui/navigations/link';
import styled, { css } from 'styled-components';
import { InvoiceTable } from './invoice_table/invoice_table';
import External from './external/external';

interface Response {
  invoices: InvoiceArgs[];
  freee_connected: boolean;
  infomart_connected: boolean;
  organization_announcements: OrganizationAnnouncementArg[];
  invoice_custom_formats: InvoiceCustomFormatArgs[];
}

interface FooterItemProps {
  backgroundImage: string;
}

const fetchInvoices = async ({
  setLoading,
  jsonPromise,
  setInvoices,
  setFreeeConnected,
  setInfomartConnected,
  setOrganizationAnnouncements,
  setInvoiceCustomFormats
}: {
  setLoading: (flag: boolean) => void;
  jsonPromise: typeof utils.jsonPromise;
  setInvoices: (invoices: Invoice[]) => void;
  setFreeeConnected: (flag: boolean) => void;
  setInfomartConnected: (flag: boolean) => void;
  setOrganizationAnnouncements: (organizationAnnouncements: OrganizationAnnouncement[]) => void;
  setInvoiceCustomFormats: (invoiceCustomFormats: InvoiceCustomFormat[]) => void;
}) => {
  setLoading(true);
  const response = await jsonPromise<Response>('/organization/invoices.json');
  setLoading(false);
  setInvoices(response.invoices.map(raw => new Invoice(raw)));
  setFreeeConnected(response.freee_connected);
  setInfomartConnected(response.infomart_connected);
  setOrganizationAnnouncements(response.organization_announcements.map(raw => new OrganizationAnnouncement(raw)));
  setInvoiceCustomFormats(response.invoice_custom_formats.map(raw => new InvoiceCustomFormat(raw)));
};

const isProduction = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname === 'aitravel.jp';
  } catch (e) {
    return false;
  }
};

const Invoices = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [encoding, setEncoding] = useState('');
  const [showExternalInvoice, setShowExternalInvoice] = useState<Invoice | null>(null);
  const [freeeConnected, setFreeeConnected] = useState(false);
  const [infomartConnected, setInfomartConnected] = useState(false);
  const [organizationAnnouncements, setOrganizationAnnouncements] = useState<OrganizationAnnouncement[]>([]);
  const [invoiceCustomFormats, setInvoiceCustomFormats] = useState<InvoiceCustomFormat[]>([]);
  const jsonPromise = useContext(jsonPromiseContext);
  useEffect(() => {
    fetchInvoices({
      setLoading,
      jsonPromise,
      setInvoices,
      setFreeeConnected,
      setInfomartConnected,
      setOrganizationAnnouncements,
      setInvoiceCustomFormats
    }).catch(e => {
      utils.sendErrorObject(e);
    });
  }, [jsonPromise]);

  const getBackgroundImageUrl = (index: number) => {
    const images = [
      '/images/announcement/announcement_bg_gold.png',
      '/images/announcement/announcement_bg_green.png',
      '/images/announcement/announcement_bg_gray.png'
    ];
    return images[index % images.length];
  };

  const refreshInvoices = () => {
    fetchInvoices({
      setLoading,
      jsonPromise,
      setInvoices,
      setFreeeConnected,
      setInfomartConnected,
      setOrganizationAnnouncements,
      setInvoiceCustomFormats
    }).catch(e => {
      utils.sendErrorObject(e);
    });
  };

  return (
    <div>
      <OrganizationTitle>請求書・明細</OrganizationTitle>
      <OrganizationBody>
        {loading ? (
          <SimpleLoading />
        ) : invoices.length === 0 ? (
          <div>現在表示可能な請求書はありません</div>
        ) : (
          <>
            <div style={{ display: 'flex', gap: '10px' }}>
              <EncodingSelector encoding={encoding} onChange={encoding => setEncoding(encoding)} />
              {!isProduction(location.href) && (
                <Button size="small" href="/organization/invoice_custom_formats">
                  カスタムCSV設定
                </Button>
              )}
            </div>
            <InvoiceTable
              invoices={invoices}
              invoiceCustomFormats={invoiceCustomFormats}
              encoding={encoding}
              setShowExternalInvoice={setShowExternalInvoice}
            />
          </>
        )}
      </OrganizationBody>
      <Footer>
        {organizationAnnouncements.map((data, index) => (
          <Link
            key={data.id}
            href={data.url || '/inquiries/new'}
            target="_blank"
            isExternal
            rel="noopener noreferrer"
          >
            <FooterItem backgroundImage={getBackgroundImageUrl(index)}>
              <TitlesContainer>
                <Title1>{data.title1}</Title1>
                <Title2>{data.title2}</Title2>
              </TitlesContainer>
            </FooterItem>
          </Link>
        ))}
      </Footer>
      <Modal
        show={!!showExternalInvoice}
        hideModal={() => setShowExternalInvoice(null)}
        title="請求書の外部サービス連携"
      >
        {showExternalInvoice && (
          <External
            freeeConnected={freeeConnected}
            infomartConnected={infomartConnected}
            invoice={showExternalInvoice}
            onRefreshInvoices={refreshInvoices}
          />
        )}
      </Modal>
    </div>
  );
};

const Footer = styled.footer`
  display: flex;
  justify-content: flex-start;
  margin: 100px 10px 0 10px;
  gap: 20px;
`;

const FooterItem = styled.div<FooterItemProps>`
  background-image: url(${props => props.backgroundImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 300px;
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  padding: 0 10px;
`;

const commonTitleStyles = css`
  color: white;
  font-weight: bold;
`;

const Title1 = styled.div`
  ${commonTitleStyles};
  font-size: 11px;
  margin-bottom: 3px;
`;

const Title2 = styled.div`
  ${commonTitleStyles};
  font-size: 13px;
`;

export default observer(Invoices);
