import React from 'react';

import { styled } from '@this/constants/themes';
import { toDomesticTel } from '@this/src/util';
import ContentBody from '@this/components/shared/atoms/content_body';
import A from '@this/components/shared/atoms/a';
import { RoundButton } from '@this/components/shared/atoms/button';
import LegacyText from '@this/components/shared/text/text';
import TranslateIgnoreText from '@this/components/shared/text/translate_ignore_text';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';

import HotelMap from '@this/components/shared/hotel_map/hotel_map';
import ReadMore from '@this/components/shared/text/readmore';
import { getColor } from '@this/shared/ui/theme';
import { Text } from '@this/shared/ui/data_displays/typography';
import type { HotelOrderItemState } from '../types';

type Props = HotelOrderItemState & {
  getPropertyAmenities: () => any[];
  getRoomAmenities: () => any[];
  getImages: () => string[];
  handleImageHover: (i: number) => () => void;
  togglePropertyAmenities: () => void;
  toggleRoomAmenities: () => void;

  searchQueryId: number | null;
  signedIn?: unknown; // FIXME: 渡されないprops
};

const HotelOrderItemPcTemplate: React.SFC<Props> = ({
  showPropertyAmenities,
  showRoomAmenities,
  loading,
  error,
  destLocation,
  hotels,
  hotel,
  orderItemPrice,
  serviceId,
  searchQueryId,

  getPropertyAmenities,
  getRoomAmenities,
  getImages,
  handleImageHover,
  togglePropertyAmenities,
  toggleRoomAmenities
}) => (
  <HotelPc>
    <BodyWrapper>
      <Body>
        <BodyIn>
          {loading || !hotel ? (
            <BodyArea>
              <SimpleLoading />
            </BodyArea>
          ) : error ? (
            <BodyArea>
              <Text color="danger">{error}</Text>
              <A onClick={window.close}>閉じる</A>
            </BodyArea>
          ) : (
            [
              <TopArea key={0}>
                <div>
                  <Title data-wovn-ignore>{hotel.name}</Title>
                  <Address data-wovn-ignore>{hotel.address}</Address>
                </div>
                {hotel.sold_out && <Soldout>※たった今他の方が予約されたため、空室がなくなりました。</Soldout>}
                <ButtonArea>
                  <Button onClick={window.close}>閉じる</Button>
                </ButtonArea>
              </TopArea>,
              <BodyArea key={1}>
                <BodyAreaIn>
                  <MainImageArea>
                    <MainImage style={{ backgroundImage: `url(${hotel.currentImage()})` }} />
                    <MainThumbs>
                      {getImages().map((image, i) => (
                        <MainThumb
                          key={i}
                          selected={hotel.currentImage() === image}
                          style={{ backgroundImage: `url(${image})` }}
                          onMouseOver={handleImageHover(i)}
                        />
                      ))}
                    </MainThumbs>
                  </MainImageArea>
                  <MapArea>
                    <HotelMap hotel={hotel} hotels={hotels} serviceId={serviceId} searchQueryId={searchQueryId} />
                    <InfoArea>
                      <table className="key-value-table">
                        <tbody>
                          <tr>
                            <td className="key-value-table__label">目的地から</td>
                            <td className="key-value-table__separator">：</td>
                            <td data-wovn-ignore className="key-value-table__value">
                              {hotel.walkMinuteText()}
                            </td>
                          </tr>
                          <tr>
                            <td className="key-value-table__label">最寄り駅から</td>
                            <td className="key-value-table__separator">：</td>
                            <td data-wovn-ignore className="key-value-table__value">
                              {hotel.stationDistanceText()}
                            </td>
                          </tr>
                          <tr>
                            <td className="key-value-table__label">チェックイン</td>
                            <td className="key-value-table__separator">：</td>
                            <td data-wovn-ignore className="key-value-table__value">
                              {hotel.checkinText()}
                            </td>
                          </tr>
                          <tr>
                            <td className="key-value-table__label">チェックアウト</td>
                            <td className="key-value-table__separator">：</td>
                            <td data-wovn-ignore className="key-value-table__value">
                              {hotel.checkoutText()}
                            </td>
                          </tr>
                          {hotel.note && (
                            <tr>
                              <td colSpan={3} style={{ maxWidth: '200px' }}>
                                {hotel.note}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <PriceArea>
                        {hotel.sold_out ? (
                          <PriceSoldout>空室なし</PriceSoldout>
                        ) : (
                          <>
                            <Text color="brand">
                              <span>ホテル料金合計：</span>
                              <span data-wovn-ignore>{utils.digits(orderItemPrice?.total_price || 0)}</span>
                              <span>円</span>
                            </Text>
                            <TranslateIgnoreText
                              text={hotel.priceDetailTextOrderUItem(
                                orderItemPrice?.total_price || 0,
                                orderItemPrice?.price || 0,
                                orderItemPrice?.inclusive_tax || 0
                              )}
                            />
                            {hotel.showCancelStatus() && (
                              <Text color="danger">{`※キャンセル：${hotel.refund}`}</Text>
                            )}
                          </>
                        )}
                      </PriceArea>
                    </InfoArea>
                  </MapArea>
                </BodyAreaIn>
                <BodyLower>
                  <OthersArea>
                    {hotel.tel && (
                      <DetailBox>
                        <DetailBoxTitle>電話番号</DetailBoxTitle>
                        <DetailBoxSmall>
                          <LegacyText text={toDomesticTel(hotel.tel)} />
                        </DetailBoxSmall>
                      </DetailBox>
                    )}
                    <DetailBox>
                      <DetailBoxTitle>宿泊プラン名</DetailBoxTitle>
                      <ul className="hotel-pc__ul">
                        <li className="hotel-pc__li">{hotel.plan_name || '-'}</li>
                      </ul>
                      <div
                        style={{ fontSize: '10px', fontWeight: 'normal', textIndent: '-5px', marginLeft: '20px' }}
                      >
                        <ReadMore
                          text="※ポイント等の特典付きの表示がある場合がございますが、付与対象になりませんのでご了承ください"
                          lessClass="hotel-pc__readmore"
                          readmoreTextClass="hotel-pc__readmore_text"
                        />
                      </div>
                    </DetailBox>
                    <DetailBox>
                      <DetailBoxTitle>宿泊プラン詳細</DetailBoxTitle>
                      <ul className="hotel-pc__ul">
                        <li className="hotel-pc__li">
                          <ReadMore
                            text={hotel.plan_detail || '-'}
                            lessClass="hotel-pc__readmore"
                            readmoreTextClass="hotel-pc__readmore_text"
                          />
                        </li>
                      </ul>
                    </DetailBox>
                    {hotel.room_type || hotel.roomBasicInfoExists() ? (
                      <DetailBox>
                        <DetailBoxTitle>部屋タイプ</DetailBoxTitle>
                        <ul className="hotel-pc__ul">
                          {hotel.room_type && <li className="hotel-pc__li">{hotel.room_type}</li>}
                          {hotel.roomBasicInfoExists() && (
                            <li className="hotel-pc__li">{hotel.rakutenRoomName()}</li>
                          )}
                        </ul>
                      </DetailBox>
                    ) : (
                      <DetailBox>
                        <DetailBoxTitle>部屋タイプ</DetailBoxTitle>
                        <ul className="hotel-pc__ul">
                          <li className="hotel-pc__li">-</li>
                        </ul>
                      </DetailBox>
                    )}
                    {hotel.bed_types && hotel.bed_types.length > 0 ? (
                      <DetailBox>
                        <DetailBoxTitle>ベッドの種類</DetailBoxTitle>
                        <ul className="hotel-pc__ul">
                          {hotel.bed_types.map((b, i) => (
                            <li className="hotel-pc__li" key={i}>
                              {b.description}
                            </li>
                          ))}
                        </ul>
                      </DetailBox>
                    ) : (
                      <DetailBox>
                        <DetailBoxTitle>ベッドの種類</DetailBoxTitle>
                        <ul className="hotel-pc__ul">
                          <li className="hotel-pc__li">-</li>
                        </ul>
                      </DetailBox>
                    )}
                    <DetailBox>
                      <DetailBoxTitle>キャンセルポリシー</DetailBoxTitle>
                      <DetailBoxSmall>
                        <LegacyText text={hotel.cancelPolicy()} />
                      </DetailBoxSmall>
                    </DetailBox>
                    <DetailBox>
                      <DetailBoxSmall dangerouslySetInnerHTML={{ __html: hotel.checkinInstructions() }} />
                    </DetailBox>
                    {hotel.specialCheckinInstructions() && (
                      <DetailBox>
                        <DetailBoxTitle>備考</DetailBoxTitle>
                        <DetailBoxSmall data-wovn-ignore>{hotel.specialCheckinInstructions()}</DetailBoxSmall>
                      </DetailBox>
                    )}
                    {hotel.parkingInformation && (
                      <DetailBox>
                        <DetailBoxTitle>駐車場に関する情報</DetailBoxTitle>
                        <DetailBoxSmall>
                          <TranslateIgnoreText text={hotel.parkingInformation} />
                        </DetailBoxSmall>
                      </DetailBox>
                    )}
                  </OthersArea>
                  <AmenityArea>
                    <Amenities>
                      <AmenityTitle>施設の設備</AmenityTitle>
                      <Ul>
                        {getPropertyAmenities().map((a, i) => (
                          <Li key={i}>{a}</Li>
                        ))}
                      </Ul>
                      {getPropertyAmenities().length >= 10 && (
                        <ToggleLink onClick={togglePropertyAmenities}>
                          {showPropertyAmenities ? '閉じる' : 'もっと見る'}
                        </ToggleLink>
                      )}
                    </Amenities>
                    <Amenities>
                      <AmenityTitle>部屋の設備</AmenityTitle>
                      <Ul>
                        {getRoomAmenities().map((a, i) => (
                          <Li key={i}>{a}</Li>
                        ))}
                      </Ul>
                      {getRoomAmenities().length >= 10 && (
                        <ToggleLink onClick={toggleRoomAmenities}>
                          {showRoomAmenities ? '閉じる' : 'もっと見る'}
                        </ToggleLink>
                      )}
                    </Amenities>
                  </AmenityArea>
                </BodyLower>
              </BodyArea>
            ]
          )}
        </BodyIn>
      </Body>
    </BodyWrapper>
  </HotelPc>
);

const HotelPc = styled.div``;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 9999;
  min-height: 0%;
  width: 100%;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
  padding: 20px;
  background: none;
`;

const BodyIn = styled.div`
  min-height: 100vh;
  background: white;
  font-weight: bold;
`;

const TopArea = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${getColor('border', 'divider')};
  padding: 12px 15px;
`;

const Title = styled.div`
  font-size: 18px;
`;

const Address = styled.div`
  font-size: 12px;
`;

const Soldout = styled.div`
  margin-left: auto;
  color: ${getColor('danger', 'primary')};
`;

const ButtonArea = styled.div`
  margin-left: auto;
`;

export const Button = styled.div`
  ${RoundButton}
`;

const BodyArea = styled.div`
  width: 100%;
  padding: 20px;
`;

const BodyAreaIn = styled.div`
  display: flex;
`;

const mainThumbWidth = 36;
const mainImageWidth = mainThumbWidth * 13;
const imageBorderWidth = 3;
const mapWidth = 452;

const MainImageArea = styled.div`
  flex-grow: 1;
  max-width: ${`${mainImageWidth + imageBorderWidth * 2}px`};
  height: 367px;
  border: ${`${imageBorderWidth}px`} solid ${getColor('common', 'black')};
`;

const MainImage = styled.div`
  max-width: ${`${mainImageWidth}px`};
  width: 100%;
  height: 288px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const MainThumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${`${mainImageWidth}px`};
`;

const MainThumb = styled.div<{ selected: boolean }>`
  width: ${`${mainThumbWidth}px`};
  height: ${`${mainThumbWidth}px`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${props =>
    !props.selected &&
    `
    background-blend-mode: color;
    background-color: rgba(255, 255, 255, 0.7);
  `}
`;

const MapArea = styled.div`
  max-width: ${`${mapWidth}px`};
  width: 100%;
  margin-left: auto;
`;

const InfoArea = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: ${getColor('background', 'primary')};
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: left;
`;

const PriceArea = styled.div`
  background: #fff;
  padding: 7px 10px;
  margin-left: auto;
`;

export const PriceSoldout = styled(Text).attrs({ color: 'description' })`
  margin: 25px;
`;

const BodyLower = styled.div`
  display: flex;
  margin-top: 20px;
  font-size: 12px;
`;

export const AmenityArea = styled.div`
  display: flex;
  max-width: ${`${mainImageWidth + imageBorderWidth * 2}px`};
  width: 100%;
  max-width: ${`${mapWidth}px`};
`;

export const Amenities = styled.div`
  max-width: 223px;
  width: 100%;
  margin-left: auto;

  &:first-child {
    margin-left: 0;
  }
`;

export const AmenityTitle = styled.h3`
  margin-bottom: 0;
`;

export const Ul = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
`;

export const Li = styled.li`
  font-size: 10px;
  font-weight: normal;
  text-indent: -5px;
`;

const ToggleLink = styled(A)`
  font-size: 10px;
`;

const OthersArea = styled.div`
  max-width: ${`${mapWidth}px`};
  width: 100%;
  margin-right: auto;
`;

export const DetailBox = styled.div`
  margin-bottom: 20px;
`;

export const DetailBoxTitle = styled.h3`
  margin-bottom: 0;
`;

export const TitleBox = styled.span`
  display: flex;
  white-space: nowrap;
  font-size: 16px;
`;

export const TitleSmall = styled.span`
  display: block;
  transform: scale(0.8);
  transform-origin: left bottom;
  font-size: 0.5em;
  font-weight: normal;
  @media (max-width: 430px) {
    white-space: normal;
    transform-origin: left top;
  }
`;

export const DetailBoxSmall = styled.div`
  font-size: 10px;
  font-weight: normal;

  b {
    font-size: 12px;
    margin-top: 20px;
    display: block;
  }
`;

export default HotelOrderItemPcTemplate;
