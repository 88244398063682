/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { ButtonBase, ButtonType } from '@this/components/shared/atoms/button';
import Text from '@this/components/shared/text/text';

import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import EkispertInput from '@this/shared/autocompletable_input/ekispert_input';
import { getColor } from '@this/shared/ui/theme';
import SelectTravelers from '../../../reserve_trip/reserve_confirm/select_travelers/select_travelers';

interface Props {
  editable: boolean;
  travelers: any;
  user: any;
  members: any;
  outdate: Moment;
  homedate: Moment;
  title?: string;
  price?: number | null;
  boardingStation?: string | null;
  arrivalStation?: string | null;
  tripType?: string | null;
  validationErrors: any;
  handleChangeTitle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePrice?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveTraveler?: (i?: number, e?: React.MouseEvent<HTMLElement>) => void;
  handleTravelerTypeChange?: (i: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTravelerSelect: (i: number, traveler: any) => void;
  handleTravelerInfoChange?: (i: number, method: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddTraveler?: (e?: React.MouseEvent<HTMLElement>) => void;
  handleChangeDate?: (kind: 'outdate' | 'homedate', date: Moment) => void;
  handleChangeRoute?: (name: string, value: string, address: string) => void;
  handleChangeTripType?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSimpleRequest?: boolean;
}

const TripsFormBasicinfoTemplate: React.SFC<Props> = ({
  editable,
  travelers,
  user,
  members,
  outdate,
  homedate,
  title,
  price,
  boardingStation,
  arrivalStation,
  tripType,
  validationErrors,
  handleChangeTitle,
  handleChangePrice,
  handleRemoveTraveler,
  handleTravelerTypeChange,
  handleTravelerSelect,
  handleTravelerInfoChange,
  handleAddTraveler,
  handleChangeDate,
  handleChangeRoute,
  handleChangeTripType,
  isSimpleRequest
}) => (
  <>
    <Section>
      <SectionIn>
        <InputArea>
          <Label>出張期間</Label>
          <Term>
            <OutDateWrap>
              {editable ? (
                <DateTextBox id="outdateTextbox">
                  <DateLabel>出発</DateLabel>
                  <DateInputWrap>
                    <DatetimePicker
                      value={outdate}
                      dateFormat="YYYY/MM/DD (ddd)"
                      onChange={(date: Moment) => handleChangeDate && handleChangeDate('outdate', date)}
                      showPast={user.organization.allow_apply_past_date}
                      width="100%"
                    />
                  </DateInputWrap>
                </DateTextBox>
              ) : (
                <DateTextBoxNonEditable id="outdateTextbox">
                  <DateLabel>出発</DateLabel>
                  <DateInputWrap>{moment(outdate).format('YYYY/MM/DD (ddd)')}</DateInputWrap>
                </DateTextBoxNonEditable>
              )}
            </OutDateWrap>
            <HomeDateWrap>
              {editable ? (
                <DateTextBox id="homedateTextbox">
                  <DateLabel>到着</DateLabel>
                  <DateInputWrap>
                    <DatetimePicker
                      value={homedate}
                      dateFormat="YYYY/MM/DD (ddd)"
                      onChange={(date: Moment) => handleChangeDate && handleChangeDate('homedate', date)}
                      showPast={user.organization.allow_apply_past_date}
                      width="100%"
                    />
                  </DateInputWrap>
                </DateTextBox>
              ) : (
                <DateTextBoxNonEditable id="homedateTextbox">
                  <DateLabel>到着</DateLabel>
                  <DateInputWrap>{moment(homedate).format('YYYY/MM/DD (ddd)')}</DateInputWrap>
                </DateTextBoxNonEditable>
              )}
            </HomeDateWrap>
          </Term>
        </InputArea>
      </SectionIn>
    </Section>
    <Section>
      <SectionIn>
        <InputArea>
          <Label>出張者</Label>
          <List>
            {travelers.list.map((traveler: any, i: number) => (
              <div key={i}>
                <ListHeader>
                  <ListNumber>{`${i + 1}人目`}</ListNumber>
                  {editable && i > 0 && (
                    <A onClick={e => handleRemoveTraveler && handleRemoveTraveler(i, e)}>削除</A>
                  )}
                </ListHeader>
                <ListContent>
                  {!editable && (
                    <ListInfoWrap>
                      {traveler.type === 'companion' ? (
                        <div className="members-list__item__info">
                          <p className="members-list__item-part">
                            {traveler.lastNameKana} {traveler.firstNameKana}
                          </p>
                          <p className="members-list__item-part">({traveler.birthday})</p>
                          <p className="members-list__item-part">
                            {traveler.flightGender === 'male' && <span>男性</span>}
                            {traveler.flightGender === 'female' && <span>女性</span>}
                          </p>
                        </div>
                      ) : (
                        <div className="members-list__item__info">
                          {traveler.departmentName() && (
                            <p className="members-list__item-part">{traveler.departmentName()}</p>
                          )}
                          <p className="members-list__item-part">{traveler.name}</p>
                          <p className="members-list__item-part">({traveler.email})</p>
                        </div>
                      )}
                    </ListInfoWrap>
                  )}
                  {editable && (
                    <TypeLabel>
                      <input
                        type="radio"
                        value="self"
                        onChange={e => handleTravelerTypeChange && handleTravelerTypeChange(i, e)}
                        checked={traveler.type === 'self'}
                      />
                      <span>自分の手配をする</span>
                    </TypeLabel>
                  )}
                  {editable && user && traveler.type === 'self' && (
                    <ListInfoWrap>
                      <ListInfo>
                        <ListInfoIn>
                          {user.departmentName() && <ListText>{user.departmentName()}</ListText>}
                          <ListText>{user.name}</ListText>
                          <ListText>{`(${user.email})`}</ListText>
                        </ListInfoIn>
                      </ListInfo>
                    </ListInfoWrap>
                  )}
                  {editable && user && user.show_other_members && members.length > 0 && (
                    <>
                      <TypeLabel>
                        <input
                          type="radio"
                          value="member"
                          onChange={e => handleTravelerTypeChange && handleTravelerTypeChange(i, e)}
                          checked={traveler.type === 'member'}
                        />
                        <span>他の人の手配をする</span>
                      </TypeLabel>
                      {traveler.type === 'member' && (
                        <ListInfoWrap>
                          <SelectTravelers
                            travelers={travelers.list}
                            currentIndex={i}
                            onSelect={handleTravelerSelect}
                          />
                          {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'memberSelect')) && (
                            <Errors>
                              <Error>
                                <Text text={validationErrors.travelers[i].memberSelect} />
                              </Error>
                            </Errors>
                          )}
                        </ListInfoWrap>
                      )}
                    </>
                  )}
                  {editable && (
                    <TypeLabel>
                      <input
                        type="radio"
                        value="companion"
                        onChange={e => handleTravelerTypeChange && handleTravelerTypeChange(i, e)}
                        checked={traveler.type === 'companion'}
                      />
                      <span>手動で入力</span>
                    </TypeLabel>
                  )}
                  {editable && traveler.type === 'companion' && (
                    <ListInfoWrap>
                      <InputArea>
                        <Label>氏名（ローマ字）</Label>
                        <InputAreaRight>
                          <NameInput
                            type="text"
                            placeholder="例) Yamada"
                            value={traveler.lastNameRoman}
                            onChange={e =>
                              handleTravelerInfoChange && handleTravelerInfoChange(i, 'setLastNameRoman', e)
                            }
                          />
                          <NameInput
                            type="text"
                            placeholder="例) Ichiro"
                            value={traveler.firstNameRoman}
                            onChange={e =>
                              handleTravelerInfoChange && handleTravelerInfoChange(i, 'setFirstNameRoman', e)
                            }
                          />
                        </InputAreaRight>
                        <Errors>
                          <Error>
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerLastNameRomen')) && (
                              <Text text={validationErrors.travelers[i].travelerLastNameRomen} />
                            )}
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerFirstNameRomen')) && (
                              <Text text={validationErrors.travelers[i].travelerFirstNameRomen} />
                            )}
                          </Error>
                        </Errors>
                      </InputArea>
                      <InputArea>
                        <Label>氏名（カナ）</Label>
                        <InputAreaRight>
                          <NameInput
                            type="text"
                            placeholder="例) ヤマダ"
                            value={traveler.lastNameKana}
                            onChange={e =>
                              handleTravelerInfoChange && handleTravelerInfoChange(i, 'setLastNameKana', e)
                            }
                          />
                          <NameInput
                            type="text"
                            placeholder="例) イチロウ"
                            value={traveler.firstNameKana}
                            onChange={e =>
                              handleTravelerInfoChange && handleTravelerInfoChange(i, 'setFirstNameKana', e)
                            }
                          />
                        </InputAreaRight>
                        <Errors>
                          <Error>
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerLastNameKana')) && (
                              <Text text={validationErrors.travelers[i].travelerLastNameKana} />
                            )}
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerFirstNameKana')) && (
                              <Text text={validationErrors.travelers[i].travelerFirstNameKana} />
                            )}
                          </Error>
                        </Errors>
                      </InputArea>
                      <InputArea>
                        <Label>誕生日</Label>
                        <InputAreaRight>
                          <NameInput
                            type="text"
                            placeholder="例) 1986-01-01"
                            value={traveler.birthday}
                            onChange={e =>
                              handleTravelerInfoChange && handleTravelerInfoChange(i, 'setBirthday', e)
                            }
                          />
                        </InputAreaRight>
                        <Errors>
                          <Error>
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerBirthday')) && (
                              <Text text={validationErrors.travelers[i].travelerBirthday} />
                            )}
                          </Error>
                        </Errors>
                      </InputArea>
                      <InputArea>
                        <Label>性別</Label>
                        <InputAreaRight>
                          <RadioLabel>
                            <RadioInput
                              type="radio"
                              value="male"
                              checked={traveler.flightGender === 'male'}
                              onChange={e =>
                                handleTravelerInfoChange && handleTravelerInfoChange(i, 'setFlightGender', e)
                              }
                            />
                            <span>男性</span>
                          </RadioLabel>
                          <RadioLabel>
                            <RadioInput
                              type="radio"
                              value="female"
                              checked={traveler.flightGender === 'female'}
                              onChange={e =>
                                handleTravelerInfoChange && handleTravelerInfoChange(i, 'setFlightGender', e)
                              }
                            />
                            <span>女性</span>
                          </RadioLabel>
                        </InputAreaRight>
                        <Errors>
                          <Error>
                            {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'travelerGender')) && (
                              <Text text={validationErrors.travelers[i].travelerGender} />
                            )}
                          </Error>
                        </Errors>
                      </InputArea>
                    </ListInfoWrap>
                  )}
                </ListContent>
              </div>
            ))}
            {editable && (
              <Button buttonType={ButtonType.SUB} onClick={e => handleAddTraveler && handleAddTraveler(e)}>
                +出張者を追加
              </Button>
            )}
          </List>
        </InputArea>
      </SectionIn>
    </Section>
    <Section>
      {!isSimpleRequest && (
        <SectionIn>
          <InputArea>
            <Label>旅程名</Label>
            <InputAreaRight>
              {editable ? (
                <Input type="text" value={title} onChange={e => handleChangeTitle && handleChangeTitle(e)} />
              ) : (
                <>{title}</>
              )}
            </InputAreaRight>
            {!_.isEmpty(validationErrors.title) && (
              <Errors>
                <Error>
                  <Text text={validationErrors.title} />
                </Error>
              </Errors>
            )}
          </InputArea>
        </SectionIn>
      )}
      {isSimpleRequest && (
        <>
          <SectionIn>
            <InputArea>
              <Label>乗車駅</Label>
              <InputAreaRight>
                <EkispertInput
                  example="駅名"
                  onChange={(id, value, address) =>
                    handleChangeRoute && handleChangeRoute('boardingStation', value, address)
                  }
                  value={boardingStation || ''}
                  id="boardingStation"
                  className=" "
                  trafficTypes={['train.shinkansen']}
                />
              </InputAreaRight>
            </InputArea>
            <InputArea>
              <Label>降車駅</Label>
              <InputAreaRight>
                <EkispertInput
                  example="駅名"
                  onChange={(id, value, address) =>
                    handleChangeRoute && handleChangeRoute('arrivalStation', value, address)
                  }
                  value={arrivalStation || ''}
                  id="arrivalStation"
                  className=" "
                  trafficTypes={['train.shinkansen']}
                />
              </InputAreaRight>
            </InputArea>
          </SectionIn>
          <SectionIn>
            <InputArea>
              <Label>旅行タイプ</Label>
              <InputAreaRight>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    value="one_way"
                    checked={tripType === 'one_way'}
                    onChange={handleChangeTripType}
                  />
                  <span>片道</span>
                </RadioLabel>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    value="round_trip"
                    checked={tripType === 'round_trip'}
                    onChange={handleChangeTripType}
                  />
                  <span>往復</span>
                </RadioLabel>
              </InputAreaRight>
            </InputArea>
          </SectionIn>
          <SectionIn>
            <InputArea>
              <Label>
                金額<Red>*</Red>
              </Label>
              <InputAreaRight>
                {editable ? (
                  <Input
                    type="number"
                    value={price != null ? price : ''}
                    onChange={e => handleChangePrice && handleChangePrice(e)}
                  />
                ) : (
                  <>{price}</>
                )}
              </InputAreaRight>
              {!_.isEmpty(utils.dig(validationErrors, 'price')) && (
                <Errors>
                  <Error>
                    <Text text={validationErrors.price} />
                  </Error>
                </Errors>
              )}
            </InputArea>
          </SectionIn>
        </>
      )}
    </Section>
  </>
);

const Section = styled.div`
  padding-bottom: 20px;
`;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const InputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Label = styled.label`
  width: 150px;
  line-height: 34px;
`;

const Red = styled.span`
  color: ${getColor('danger', 'primary')};
`;

const List = styled.div`
  flex-grow: 1;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ListNumber = styled.p``;

const ListContent = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
`;

const TypeLabel = styled.label`
  margin: 10px 0 5px;
`;

const ListInfoWrap = styled.div`
  justify-content: space-between;
  margin-left: 10px;
`;

const ListInfo = styled.div`
  display: flex;
  padding: 5px 20px;
  border: 2px solid ${props => props.theme.grayBorderColor};
  cursor: pointer;
`;

const ListInfoIn = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ListText = styled.p`
  line-height: 26px;
  margin-right: 10px;
`;

const Errors = styled.div`
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 300px;
`;

const NameInput = styled.input`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const RadioLabel = styled.label`
  margin: 10px 10px 5px 0;
  font-size: 13px;
`;

const RadioInput = styled.input``;

const Button = styled.button`
  ${ButtonBase};
  font-size: 13px;
`;

const Term = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DateWrap = styled.div`
  flex-grow: 1;
  background-color: white;
`;

const OutDateWrap = styled(DateWrap)`
  margin: 0;
`;

const HomeDateWrap = styled(DateWrap)`
  margin: 0 0 0 10px;
`;

const DateTextBox = styled.div`
  padding: 3px 5px;
  border: solid 2px #f7f7f7;
`;

const DateTextBoxNonEditable = styled.div`
  padding: 3px 5px;
`;

const DateLabel = styled.label`
  color: ${props => props.theme.linkColor};
  font-size: 12px;
  font-weight: 700;
`;

const DateInputWrap = styled.div`
  width: 100%;
`;

const Input = styled.input`
  &&& {
    max-width: 300px;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

export default TripsFormBasicinfoTemplate;
