import React, { useCallback, useState } from 'react';

import { styled } from '@this/constants/themes';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';

type Props = {
  className?: string;
  name: string;
  onSubmit: (params: { name: string }) => Promise<void>;
};

export const ReceiptCreateForm = ({ className, name: rawName, onSubmit }: Props) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>(rawName);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubmitting(true);
      onSubmit({ name }).finally(() => setSubmitting(false));
    },
    [name, onSubmit]
  );

  return (
    <Root className={className}>
      <Heading>領収書の発行</Heading>
      <p>
        AI Travelのご利用ありがとうございます。
        <br />
        領収書に記載する宛名をご確認ください。
        <br />
        領収書の発行後は宛名を変更できません。
      </p>

      <Form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <InputLabel>宛名</InputLabel>
          <Input type="text" value={name} onChange={e => setName(e.target.value)} />
        </FormControl>

        <Footer>
          <Button type="submit" loading={submitting}>
            発行
          </Button>
        </Footer>
      </Form>
    </Root>
  );
};

const Root = styled.div``;

const Heading = styled.h1`
  font-size: 22px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 18px;
`;

const Form = styled.form`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;
