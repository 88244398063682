import React, { useCallback } from 'react';
import type OrderItemStepTodo from '@this/src/domain/arrangement/order_item_step_todo';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';

interface Props {
  todo: OrderItemStepTodo | null;
  onClose: () => void;
  onAbort: (todo: OrderItemStepTodo) => void;
}

const OrderItemStepSequenceAbortConfirm: React.FC<Props> = ({ todo, onClose, onAbort }) => {
  const handleSubmit = useCallback(() => {
    if (todo) onAbort(todo);
    onClose();
  }, [todo, onAbort]);

  return (
    <Modal open={Boolean(todo)} size="medium" onClose={onClose}>
      <ModalHeader>シーケンスの破棄</ModalHeader>
      <ModalBody>
        <p>進行しているステップをすべて破棄してシーケンスを終了しますか？</p>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onClose}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          破棄
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderItemStepSequenceAbortConfirm;
