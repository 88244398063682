export default {
  getItem(key: string) {
    if (!localStorage) {
      return null;
    }
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },

  setItem(key: string, value: any) {
    if (!localStorage) {
      return;
    }
    try {
      localStorage.setItem(key, value);
    } catch (error) {}
  },

  removeItem(key: string) {
    if (!localStorage) {
      return;
    }
    try {
      localStorage.removeItem(key);
    } catch (error) {}
  }
};
