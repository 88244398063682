/* eslint-disable max-lines */
import React from 'react';
import { withTheme, css } from 'styled-components';
import { styled } from '@this/constants/themes';
import type { ImageFile } from 'react-dropzone';

import A from '@this/shared/atoms/a';
import LinkButton from '@this/shared/atoms/link_button';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { getColor } from '@this/components/shared/ui/theme';
import _ from 'lodash';

import PersonIcon from '@material-ui/icons/Person';
import Tooltip from '@this/shared/tooltip/tooltip';
import { TravelerFromMember } from '@this/shared/search/search_travelers_block/search_travelers_block';
import SelectNationalities from '@this/shared/nationality/select_nationalities';
import NotifiedUserSelectorTemplate from '@this/shared/user_selector/notified_user_selector.template';
import ApproveRoute from '@this/shared/approve_route/approve_route_input';
import type Prefecture from '@this/domain/prefecture';
import AutoCompletableInput from '@this/shared/autocompletable_input/autocompletable_input';
import Markdown from '@this/shared/markdown/markdown';
import SelectApproveItemList from '@this/components/shared/select_approveitemlist/select_approveitemlist';
import DateSelector from '@this/shared/date_selector/date_selector';
import TripRuleErrorSection from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_error_section';
import TripRuleSuccessSection from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_success_section';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { InfoOutlined } from '@material-ui/icons';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import { BorderButtonBase } from '@this/shared/atoms/button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Flex } from '@this/shared/ui/layout/flex';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import { toDomesticTel } from '@this/src/util';
import moment from '../../../../../lib/moment';

import type User from '../../../../../domain//user/user';
import type Department from '../../../../../domain/department/department';
import type OrganizationBase from '../../../../../domain/organization_base/organization_base';
import type Traveler from '../../../../../domain/traveler/traveler';
import type { OrderItemMappingMode } from '../../../../../domain/order_item_mapping';
import type OrderItemMapping from '../../../../../domain/order_item_mapping';

import DatetimePicker from '../../../../shared/datetime_picker/datetime_picker';

import ChargingDepartmentSection from './charging_department_section/charging_department_section';
import SelectDepartments from './select_departments/select_departments';
import ProjectNameSection from './project_name_section/project_name_section';
import SelectOrganizationBases from './select_organization_bases/select_organization_bases';
import WelfareSection from './welfare_section/welfare_section';
import PaymentSection from './payment_section/payment_section';
import ApproveItemDropzone from './approve_items/approve_item_dropzone';
import type { FilterableSelectorOption } from '../../../../shared/filterable_selector/filterable_selector';
import SelectTravelers from '../../../reserve_confirm/select_travelers/select_travelers';

import type { Props as ComponentProps, State as ComponentState } from './input_customer_information';
import ExpensesAccountTypeSection from './expenses_account_type_section/expenses_account_type_section';

import type ItemList from '../../../../../domain/approve_item/item_list';
import TripDuplicationSection from './trip_duplication_section/trip_duplication_section';

const WORKFLOW_FORM_WIDTH = '420px';

interface Stage {
  stage: number;
  approvers: (User | null)[];
}

export type Props = {
  handleTravelerTypeChange: (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTravelerSelect(i: number, traveler: Traveler): void;
  handleTravelerInfoChange: (
    i: number,
    method: string
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleTravelerBirthDayChange: (i: number, method: string) => (birthday: moment.Moment) => void;
  handleTravelerNationalityChange: (i: number, method: string) => (id: number) => void;
  handleDateChange: (method: string, i: number) => (date: moment.Moment) => void;
  handleReserveInfoChange: (
    method: string
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleHotelInfoChange: (
    method: string,
    i: number,
    j: number
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleRentalCarInfoChange: (method: string, i: number) => (value: string) => void;
  handleRentalCarInfoAdd: () => void;
  handleRentalCarInfoRemove: (i: number) => (e: React.MouseEvent<HTMLElement>) => void;
  handleNotifiedUserAdd: (e: React.MouseEvent<HTMLElement>) => void;
  handleNotifiedUserRemove: (e: React.MouseEvent<HTMLElement>, i: number) => void;
  handleNotifiedUserSelect: (i: number, traveler: Traveler, type: string) => void;
  handleAddressDepartmentSelect: (department: Department) => void;
  handleAddressOrganizationBaseSelect: (organizationBase: OrganizationBase) => void;
  validateInput(e: React.MouseEvent): void;
  handleChargingDepartmentSelect: (department: Department) => void;
  handleShowDepartmentDetailClick: () => void;
  handleCancelDepartmentDetailClick: () => void;
  handleDepartmentDetailModeChange: (mode: OrderItemMappingMode) => void;
  handleDepartmentDetailChange: (mapping: OrderItemMapping, option: FilterableSelectorOption | null) => void;
  handleProjectSelect: (option: FilterableSelectorOption | null) => void;
  handleShowProjectDetailClick: () => void;
  handleCancelProjectDetailClick: () => void;
  handleProjectDetailModeChange: (mode: OrderItemMappingMode) => void;
  handleProjectDetailChange: (mapping: OrderItemMapping, option: FilterableSelectorOption | null) => void;
  handleShowExpensesAccountTypeDetailClick: () => void;
  handleCancelExpensesAccountTypeDetailClick: () => void;
  handleExpensesAccountTypeDetailModeChange: (mode: OrderItemMappingMode) => void;
  handleExpensesAccountTypeDetailChange: (
    mapping: OrderItemMapping,
    option: FilterableSelectorOption | null
  ) => void;
  handleExpensesAccountTypeSelect: (option: FilterableSelectorOption | null) => void;
  theme: { themeClass: string };
  isWithinSixMonth: (expire: string | null) => boolean;
  isOverDate: (expire: string | null) => boolean;
  projectShareAvailability: boolean;
  prefecture: Prefecture[];
  handleApproveTextChange: (id: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleApproveCalendarSelect: (id: number) => (date: moment.Moment) => void;
  handleApproveListChange: (id: number) => (itemList: ItemList | null) => void;
  handleApproveStagesChange: (stages: Stage[]) => void;
  handleApproveFileChange: (id: number, file?: ImageFile) => void;
  handleApproveFileRemove: (id: number) => void;
  fromTripForm: boolean;
  handleSaveDraft?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleExicPasswordType: (i: number) => void;
  handleShowAlert: (showAlert: boolean) => void;
  isSimpleRequest?: boolean;
  errorsInputCustomer?: { [key: string]: string | undefined };
} & ComponentProps &
  ComponentState;

const InputCustomerInformationTemplate: React.SFC<Props> = ({
  isCustomerInfoRequired,
  handleTravelerTypeChange,
  handleTravelerInfoChange,
  reserveInfo,
  applicant,
  reservingTrip,
  // bedTypeId, どこから？
  departments,
  organizationBases,
  useBulkTicket,
  useKyuusyuuTicket,
  showEx,
  handleBackToSelectClick,
  handleAddressOrganizationBaseSelect,
  handleAddressDepartmentSelect,
  handleTravelerSelect,
  validationErrors,
  handleHotelInfoChange,
  handleReserveInfoChange,
  handleRentalCarInfoChange,
  handleRentalCarInfoAdd,
  handleRentalCarInfoRemove,
  handleTravelerBirthDayChange,
  handleTravelerNationalityChange,
  handleDateChange,
  handleNotifiedUserAdd,
  handleNotifiedUserRemove,
  handleNotifiedUserSelect,
  selectPageUrl,
  validateInput,
  handleChargingDepartmentSelect,
  handleShowDepartmentDetailClick,
  handleCancelDepartmentDetailClick,
  showDepartmentDetail,
  handleDepartmentDetailModeChange,
  handleDepartmentDetailChange,
  departmentDetailMode,
  handleProjectSelect,
  theme,
  isWithinSixMonth,
  isOverDate,
  handleShowProjectDetailClick,
  handleCancelProjectDetailClick,
  showProjectDetail,
  handleProjectDetailModeChange,
  handleProjectDetailChange,
  projectDetailMode,
  projectShareAvailability,
  tripRuleErrors,
  expensesAccountTypeDetailMode,
  showExpensesAccountTypeDetail,
  handleShowExpensesAccountTypeDetailClick,
  handleCancelExpensesAccountTypeDetailClick,
  handleExpensesAccountTypeDetailModeChange,
  handleExpensesAccountTypeDetailChange,
  handleExpensesAccountTypeSelect,
  handleApproveTextChange,
  handleApproveCalendarSelect,
  handleApproveListChange,
  handleApproveStagesChange,
  handleApproveFileChange,
  handleApproveFileRemove,
  handleExicPasswordType,
  isSubmitting,
  fromTripForm,
  tripDuplications,
  handleSaveDraft,
  tripDistanceAndTimeInfo,
  queries,
  exicPasswordTypes,
  showAlert,
  isSimpleRequest,
  handleShowAlert,
  errorsInputCustomer
}) => {
  const getItemList = () => {
    const { items, nonOrderItems } = reserveInfo.reservingTrip;
    return items && items.length > 0 ? items : nonOrderItems;
  };
  const itemList = getItemList();

  return (
    <>
      {!isSimpleRequest && <Title>予約情報を入力してください</Title>}
      {(_.isEmpty(queries) || queries.search_type !== 'multipleLocations') && tripRuleErrors.length > 0 && (
        <ReserveErrorAlert icon={<ErrorOutlineIcon />} variant="filled" severity="error">
          <StyledAlertTitle>※{tripRuleErrors.length}件の規程違反があります</StyledAlertTitle>
          <Flex>
            <div>
              {tripRuleErrors.map((t, i) => (
                <TripRuleErrorSection key={i} tripRuleError={t} showAlert={showAlert} />
              ))}
            </div>
            {tripRuleErrors.some((rule_error: TripRuleError) => !_.isEmpty(rule_error.detail.lines)) && (
              <DescriptionButton onClick={() => handleShowAlert(!showAlert)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="15"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="#666666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d={showAlert ? 'M18 15l-6-6-6 6' : 'M6 9l6 6 6-6'} />
                </svg>
              </DescriptionButton>
            )}
          </Flex>
        </ReserveErrorAlert>
      )}
      {(_.isEmpty(queries) || queries.search_type !== 'multipleLocations') &&
        tripRuleErrors.length === 0 &&
        tripDistanceAndTimeInfo.length > 0 && (
          <ReserveSuccessAlert icon={<InfoOutlined />} variant="filled" severity="info">
            <StyledAlertTitle>※旅費規程の範囲内です</StyledAlertTitle>
            <Flex>
              <div>
                {tripDistanceAndTimeInfo.map((info, i) => (
                  <TripRuleSuccessSection key={i} tripRuleDistanceTimeInfo={info} showAlert={showAlert} />
                ))}
              </div>
              {tripDistanceAndTimeInfo[0].detail.lines?.length && (
                <DescriptionButton onClick={() => handleShowAlert(!showAlert)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="15"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="#666666"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d={showAlert ? 'M18 15l-6-6-6 6' : 'M6 9l6 6 6-6'} />
                  </svg>
                </DescriptionButton>
              )}
            </Flex>
          </ReserveSuccessAlert>
        )}
      {tripDuplications.length ? (
        <>
          {tripDuplications.map((duplication, i) => (
            <TripDuplicationSection key={i} tripDuplication={duplication} />
          ))}
          <small>
            ※代理のお手配の場合、権限の都合で上記のリンクを開けない場合があります。その場合は出張者ご本人に詳細をご確認ください。
          </small>
        </>
      ) : null}
      <ChildWrapper>
        {!isSimpleRequest && isCustomerInfoRequired() && (
          <Section>
            <Subtitle>出張者</Subtitle>
            <ChildContent>
              {reserveInfo.travelers.list.map((traveler, i) => (
                <div key={i}>
                  <TravelerPeopleNum>{i + 1}人目</TravelerPeopleNum>
                  {i === 0 ? (
                    <ReserveConfirmTraveler className="input-customer-information__traveler">
                      <p>
                        {traveler.departmentName() && <>{traveler.departmentName()}&nbsp;</>}
                        {traveler.type === 'companion'
                          ? `${traveler.getTravelerName()}`
                          : `${traveler.name} (${traveler.email})`}
                        {traveler.employee_number && <>&nbsp;[{traveler.employee_number}]</>}
                      </p>
                    </ReserveConfirmTraveler>
                  ) : (
                    <ReserveConfirmTraveler className="input-customer-information__traveler">
                      {/* - user=reserveInfo.user */}
                      <TravelerType>
                        <input
                          type="radio"
                          value="self"
                          onChange={handleTravelerTypeChange(i)}
                          checked={traveler.type === 'self'}
                        />
                        <span>自分の手配をする</span>
                      </TravelerType>
                      {traveler.type === 'self' && (
                        <TravelerFromMember>
                          <SelectTravelersSelectedListItem>
                            <MembersListItemInfo>
                              {reserveInfo.user.departmentName() && (
                                <MembersListItemName>{reserveInfo.user.departmentName()}</MembersListItemName>
                              )}
                              <MembersListItemName>{reserveInfo.user.name}</MembersListItemName>
                              <p className="members-list__item__birthday">({reserveInfo.user.email})</p>
                              {reserveInfo.user.employee_number && (
                                <p className="members-list__item__birthday">
                                  [{reserveInfo.user.employee_number}]
                                </p>
                              )}
                            </MembersListItemInfo>
                          </SelectTravelersSelectedListItem>
                        </TravelerFromMember>
                      )}
                      {reserveInfo.user.show_other_members && reserveInfo.hasMembers && (
                        <>
                          <TravelerType>
                            <input
                              type="radio"
                              value="member"
                              onChange={handleTravelerTypeChange(i)}
                              checked={traveler.type === 'member'}
                            />
                            <span>他の人の手配をする</span>
                          </TravelerType>
                          {traveler.type === 'member' && (
                            <TravelerFromMember>
                              <SelectTravelers
                                travelers={reserveInfo.travelers.list}
                                currentIndex={i}
                                onSelect={handleTravelerSelect}
                              />
                            </TravelerFromMember>
                          )}
                        </>
                      )}
                      <TravelerType>
                        <input
                          className="companion-label"
                          type="radio"
                          value="companion"
                          onChange={handleTravelerTypeChange(i)}
                          checked={traveler.type === 'companion'}
                        />
                        <span>手動で入力</span>
                      </TravelerType>
                      {traveler.type === 'companion' && (
                        <TravelerFromMember>
                          <TravelerInputArea>
                            <Label>氏名（ローマ字）</Label>
                            <InputAreaRight>
                              <InputName
                                id="traveler-input-area_english-last-name"
                                className="last-name"
                                type="text"
                                placeholder="例) Yamada"
                                value={traveler.lastNameRoman}
                                onChange={handleTravelerInfoChange(i, 'setLastNameRoman')}
                              />
                              <InputName
                                id="traveler-input-area_english-first-name"
                                className="first-name"
                                type="text"
                                placeholder="例) Ichiro"
                                value={traveler.firstNameRoman}
                                onChange={handleTravelerInfoChange(i, 'setFirstNameRoman')}
                              />
                            </InputAreaRight>
                            {!(
                              _.isEmpty(validationErrors.travelerFirstNameRomen) ||
                              _.isEmpty(validationErrors.travelerLastNameRomen)
                            ) && (
                              <div>
                                <Text color="danger">{validationErrors.travelerFirstNameRomen}</Text>
                                <Text color="danger">{validationErrors.travelerLastNameRomen}</Text>
                              </div>
                            )}
                          </TravelerInputArea>
                          <TravelerInputArea>
                            <Label>氏名（カナ）</Label>
                            <InputAreaRight>
                              <InputName
                                id="traveler-input-area_japanese-last-name"
                                className="last-name"
                                type="text"
                                placeholder="例) ヤマダ"
                                value={traveler.lastNameKana}
                                onChange={handleTravelerInfoChange(i, 'setLastNameKana')}
                              />
                              <InputName
                                id="traveler-input-area_japanese-first-name"
                                className="first-name"
                                type="text"
                                placeholder="例) イチロウ"
                                value={traveler.firstNameKana}
                                onChange={handleTravelerInfoChange(i, 'setFirstNameKana')}
                              />
                            </InputAreaRight>
                            {!(
                              _.isEmpty(validationErrors.travelerFirstNameKana) ||
                              _.isEmpty(validationErrors.travelerLastNameKana)
                            ) && (
                              <div>
                                <Text color="danger">{validationErrors.travelerFirstNameKana}</Text>
                                <Text color="danger">{validationErrors.travelerLastNameKana}</Text>
                              </div>
                            )}
                          </TravelerInputArea>
                          {reservingTrip.isFlightCustomerInfoRequired() && (
                            <>
                              <TravelerInputArea>
                                <Label>誕生日</Label>
                                <InputAreaRight>
                                  <InputBirthDay
                                    id="traveler-input-area_birthday"
                                    type="text"
                                    placeholder="例) 1986-01-01"
                                    value={traveler.birthday}
                                    onChange={handleTravelerInfoChange(i, 'setBirthday')}
                                  />
                                </InputAreaRight>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors.travelerBirthday) && (
                                <Text color="danger">{validationErrors.travelerBirthday}</Text>
                              )}
                            </>
                          )}
                          {(reservingTrip.isFlightCustomerInfoRequired() ||
                            reservingTrip.isHotelInfoRequired()) && (
                            <TravelerInputArea>
                              <Label>性別</Label>
                              <InputAreaRight>
                                <RadioLabel>
                                  <input
                                    id="traveler-input-area_radio-male"
                                    className="reserve-confirm__radio-input"
                                    type="radio"
                                    value="male"
                                    checked={traveler.flightGender === 'male'}
                                    onChange={handleTravelerInfoChange(i, 'setFlightGender')}
                                  />
                                  <span>男性</span>
                                </RadioLabel>
                                <RadioLabel>
                                  <input
                                    className="reserve-confirm__radio-input"
                                    type="radio"
                                    value="female"
                                    checked={traveler.flightGender === 'female'}
                                    onChange={handleTravelerInfoChange(i, 'setFlightGender')}
                                  />
                                  <span>女性</span>
                                </RadioLabel>
                              </InputAreaRight>
                            </TravelerInputArea>
                          )}
                        </TravelerFromMember>
                      )}
                    </ReserveConfirmTraveler>
                  )}
                </div>
              ))}
              {!_.isEmpty(validationErrors.selfSelection) && (
                <Text color="danger">{validationErrors.selfSelection}</Text>
              )}
            </ChildContent>
          </Section>
        )}
        {reservingTrip.isFlightCustomerInfoRequired() && (
          <Section>
            <Subtitle>航空券</Subtitle>
            <ChildContent>
              {reserveInfo.travelers.list.map((traveler, i) => (
                <div key={i}>
                  <PeopleNum>{i + 1}人目</PeopleNum>
                  <ReserveConfirmTraveler>
                    <TravelerInputArea>
                      {reservingTrip.isForeignFlightCustomerInfoRequired() ? (
                        <div>
                          <FlightCustomerInfo>
                            <Label>
                              姓（LastName）<Red>*</Red>
                            </Label>
                            <InputName
                              type="text"
                              placeholder="例) Yamada"
                              value={traveler.flightLastNameRoman}
                              onChange={handleTravelerInfoChange(i, 'setFlightLastNameRoman')}
                            />
                          </FlightCustomerInfo>
                          <FlightCustomerInfo>
                            <Label>ミドルネーム（MiddleName）</Label>
                            <InputName
                              type="text"
                              placeholder="例) William"
                              value={traveler.flightMiddleNameRoman}
                              onChange={handleTravelerInfoChange(i, 'setFlightMiddleNameRoman')}
                            />
                          </FlightCustomerInfo>
                          <FlightCustomerInfo>
                            <Label>
                              名（FirstName）<Red>*</Red>
                            </Label>
                            <InputName
                              type="text"
                              placeholder="例) Ichiro"
                              value={traveler.flightFirstNameRoman}
                              onChange={handleTravelerInfoChange(i, 'setFlightFirstNameRoman')}
                            />
                          </FlightCustomerInfo>
                          <Text level="caption">
                            ※1文字でもパスポートの表記と異なるとご搭乗いただけません。
                            <br />
                            ※各航空会社の定めにより予約後の訂正はできないため、キャンセルと新たな予約が必要となります。その場合規定の手数料が発生いたします。手数料の詳細については
                            <A href="/charges" target="_blank">
                              旅行業務取扱い料金表
                            </A>
                            をご確認ください。
                          </Text>
                        </div>
                      ) : (
                        <>
                          <Label>氏名（カナ）</Label>
                          <InputAreaRight>
                            <InputName
                              type="text"
                              placeholder="例) ヤマダ"
                              value={traveler.flightLastNameKana}
                              onChange={handleTravelerInfoChange(i, 'setFlightLastNameKana')}
                            />
                            <InputName
                              type="text"
                              placeholder="例) イチロウ"
                              value={traveler.flightFirstNameKana}
                              onChange={handleTravelerInfoChange(i, 'setFlightFirstNameKana')}
                            />
                          </InputAreaRight>
                        </>
                      )}
                      {!_.isEmpty(validationErrors[`flightCustomerName_${i}`]) && (
                        <Text color="danger">{validationErrors[`flightCustomerName_${i}`]}</Text>
                      )}
                    </TravelerInputArea>
                    {!reservingTrip.isForeignFlightCustomerInfoRequired() && (
                      <TravelerInputArea>
                        <Label>氏名（アルファベット）</Label>
                        <InputAreaRight>
                          <InputName
                            type="text"
                            placeholder="例) Yamada"
                            value={traveler.flightLastNameRoman}
                            onChange={handleTravelerInfoChange(i, 'setFlightLastNameRoman')}
                          />
                          <InputName
                            type="text"
                            placeholder="例) Ichiro"
                            value={traveler.flightFirstNameRoman}
                            onChange={handleTravelerInfoChange(i, 'setFlightFirstNameRoman')}
                          />
                        </InputAreaRight>
                        {!_.isEmpty(validationErrors[`flightCustomerNameRoman_${i}`]) && (
                          <Text color="danger">{validationErrors[`flightCustomerNameRoman_${i}`]}</Text>
                        )}
                        <Text level="caption">
                          ※各航空会社の定めにより予約後の訂正はできないため、キャンセルと新たな予約が必要となります。その場合規定の手数料が発生いたします。手数料の詳細については
                          <A href="/charges" target="_blank">
                            旅行業務取扱い料金表
                          </A>
                          をご確認ください。
                        </Text>
                      </TravelerInputArea>
                    )}
                    <TravelerInputArea>
                      <Label>性別</Label>
                      <InputAreaRight>
                        <RadioLabel>
                          <input
                            className="reserve-confirm__radio-input"
                            type="radio"
                            value="male"
                            checked={traveler.flightGender === 'male'}
                            onChange={handleTravelerInfoChange(i, 'setFlightGender')}
                          />
                          <span>男性</span>
                        </RadioLabel>
                        <RadioLabel>
                          <input
                            className="reserve-confirm__radio-input"
                            type="radio"
                            value="female"
                            checked={traveler.flightGender === 'female'}
                            onChange={handleTravelerInfoChange(i, 'setFlightGender')}
                          />
                          <span>女性</span>
                        </RadioLabel>
                      </InputAreaRight>
                      {!_.isEmpty(validationErrors[`flightCustomerGender_${i}`]) && (
                        <Text color="danger">{validationErrors[`flightCustomerGender_${i}`]}</Text>
                      )}
                    </TravelerInputArea>
                    <TravelerInputArea>
                      <Label>誕生日</Label>
                      <InputAreaRight>
                        {traveler.type === 'companion' || traveler.id === reserveInfo.loginUserId ? (
                          <DateSelector
                            date={
                              moment(traveler.flightBirthday).isValid()
                                ? moment(traveler.flightBirthday)
                                : moment([2000])
                            }
                            onDateChange={handleTravelerBirthDayChange(i, 'setFlightBirthday')}
                          />
                        ) : (
                          '****-**-**(プライバシー保護のため非表示)'
                        )}
                      </InputAreaRight>
                      {!_.isEmpty(validationErrors[`flightCustomerBirthday_${i}`]) && (
                        <Text color="danger">{validationErrors[`flightCustomerBirthday_${i}`]}</Text>
                      )}
                    </TravelerInputArea>
                    {reservingTrip.isForeignFlightCustomerInfoRequired() && (
                      <TravelerInputArea>
                        <Label>国籍</Label>
                        <InputNationarityAreaRight>
                          <SelectNationalities
                            nationalities={reserveInfo.nationalities}
                            selectedId={traveler.nationalityId}
                            onSelect={handleTravelerNationalityChange(i, 'setNationalityId')}
                          />
                        </InputNationarityAreaRight>
                      </TravelerInputArea>
                    )}
                    {!_.isEmpty(validationErrors.travelerBirthday) && (
                      <Text color="danger">{validationErrors.travelerBirthday}</Text>
                    )}
                    <TravelerInputArea>
                      <Label>緊急連絡先電話番号</Label>
                      <InputAreaRight>
                        <Input
                          type="text"
                          placeholder="例) 090-1111-2222"
                          value={toDomesticTel(traveler.flightTel)}
                          onChange={handleTravelerInfoChange(i, 'setFlightTel')}
                        />
                      </InputAreaRight>
                      {!_.isEmpty(validationErrors[`flightCustomerTel_${i}`]) && (
                        <Text color="danger">{validationErrors[`flightCustomerTel_${i}`]}</Text>
                      )}
                    </TravelerInputArea>
                    {reservingTrip.isForeignFlightCustomerInfoRequired() && (
                      <>
                        <TravelerInputArea>
                          <Label>
                            パスポート番号<Red>*</Red>
                          </Label>
                          <InputAreaRight>
                            <Input
                              className="passport-number"
                              type="text"
                              placeholder="例) AA1234567"
                              value={traveler.passportNumber}
                              onChange={handleTravelerInfoChange(i, 'setPassportNumber')}
                            />
                          </InputAreaRight>
                          {!_.isEmpty(validationErrors[`passportNumber_${i}`]) && (
                            <Text color="danger">{validationErrors[`passportNumber_${i}`]}</Text>
                          )}
                        </TravelerInputArea>
                        <TravelerInputArea>
                          <Label>
                            パスポート有効期限<Red>*</Red>
                          </Label>
                          <InputAreaRight>
                            <Input
                              className="passport-expire"
                              type="text"
                              placeholder="例) 2025-01-01"
                              value={traveler.passportExpire}
                              onChange={handleTravelerInfoChange(i, 'setPassportExpire')}
                            />
                          </InputAreaRight>
                          {isWithinSixMonth(traveler.passportExpire) && (
                            <div>
                              <Text color="danger">有効期限が近づいてきています。パスポートをご確認ください</Text>
                              <Text color="danger">
                                内容が変更されている場合、アカウント設定から変更してください
                              </Text>
                            </div>
                          )}
                          {isOverDate(traveler.passportExpire) && (
                            <div>
                              <Text color="danger">有効期限が切れています。パスポートをご確認ください。</Text>
                              <Text color="danger">
                                内容が変更されている場合、アカウント設定から変更してください。
                              </Text>
                            </div>
                          )}
                          {!_.isEmpty(validationErrors[`passportExpire_${i}`]) && (
                            <Text color="danger">{validationErrors[`passportExpire_${i}`]}</Text>
                          )}
                        </TravelerInputArea>
                        <TravelerInputArea>
                          <Label>パスポート発行国</Label>
                          <InputAreaRight>
                            <Input
                              className="passport-issued-coutry"
                              type="text"
                              placeholder="例) 日本"
                              value={traveler.passportIssuedCoutry}
                              onChange={handleTravelerInfoChange(i, 'setPassportIssuedCountry')}
                            />
                          </InputAreaRight>
                          {!_.isEmpty(validationErrors[`passportIssuedCoutry_${i}`]) && (
                            <Text color="danger">{validationErrors[`passportIssuedCoutry_${i}`]}</Text>
                          )}
                        </TravelerInputArea>
                      </>
                    )}
                    <TravelerInputArea>
                      <Label>座席の希望</Label>
                      <InputAirTicketAreaRight>
                        <Input
                          className="air-seat"
                          type="text"
                          placeholder="(任意)「前方通路側」など"
                          value={traveler.airSeat}
                          onChange={handleTravelerInfoChange(i, 'setAirSeat')}
                        />
                        <CaptionText>
                          ※座席の希望にアップグレード席等の要望を記載いただいてもご対応はできません。
                          <br />
                          ※アップグレード席を希望の方は貴社管理者より表示設定を依頼してください。
                          <br />
                          詳しくは
                          <A href="https://aitravel.jp/knowledges/000001188" target="_blank">
                            コチラ
                          </A>
                        </CaptionText>
                      </InputAirTicketAreaRight>
                    </TravelerInputArea>
                    {!reserveInfo.user.organization?.setting?.disable_show_milage_number && (
                      <>
                        <TravelerInputArea>
                          <Label>マイル番号</Label>
                          <InputAreaRight>
                            <Input
                              className="mileage-number"
                              type="text"
                              placeholder="(任意)マイル番号を入力してください"
                              value={traveler.mileageNumber}
                              onChange={handleTravelerInfoChange(i, 'setMileageNumber')}
                            />
                          </InputAreaRight>
                        </TravelerInputArea>
                        {traveler.mileageNumbers && traveler.mileageNumbers.length > 0 && (
                          <TravelerInputArea>
                            <Label>登録済みマイル番号</Label>
                            <InputAreaRight>
                              <div>
                                {traveler.mileageNumbers.map((mileage, i) => (
                                  <div key={i}>
                                    {mileage.carrier}:{' '}
                                    {traveler.type === 'companion' || traveler.id === reserveInfo.user.id
                                      ? mileage.number
                                      : '**********(プライバシー保護のため非表示)'}
                                  </div>
                                ))}
                              </div>
                            </InputAreaRight>
                          </TravelerInputArea>
                        )}
                      </>
                    )}
                    {reserveInfo.user.organization.immigration_card_enabled && (
                      <TravelerInputArea>
                        <Label>出入国カード作成代行</Label>
                        <InputAreaRight>
                          <RadioLabel>
                            <input
                              className="reserve-confirm__radio-input"
                              type="radio"
                              value="true"
                              checked={traveler.immigrationCardCreation}
                              onChange={handleTravelerInfoChange(i, 'setImmigrationCardCreation')}
                            />
                            <span>はい</span>
                          </RadioLabel>
                          <RadioLabel>
                            <input
                              className="reserve-confirm__radio-input"
                              type="radio"
                              value="false"
                              checked={!traveler.immigrationCardCreation}
                              onChange={handleTravelerInfoChange(i, 'setImmigrationCardCreation')}
                            />
                            <span>いいえ</span>
                          </RadioLabel>
                        </InputAreaRight>
                      </TravelerInputArea>
                    )}
                    {reserveInfo.user.organization.visa_application_enabled && (
                      <>
                        <TravelerInputArea>
                          <Label>ビザ申請代行</Label>
                          <InputAreaRight>
                            <RadioLabel>
                              <input
                                className="reserve-confirm__radio-input"
                                type="radio"
                                value="true"
                                checked={traveler.visaApplication}
                                onChange={handleTravelerInfoChange(i, 'setVisaApplication')}
                              />
                              <span>はい</span>
                            </RadioLabel>
                            <RadioLabel>
                              <input
                                className="reserve-confirm__radio-input"
                                type="radio"
                                value="false"
                                checked={!traveler.visaApplication}
                                onChange={handleTravelerInfoChange(i, 'setVisaApplication')}
                              />
                              <span>いいえ</span>
                            </RadioLabel>
                          </InputAreaRight>
                        </TravelerInputArea>
                        <A href="https://aitravel.jp/knowledges/000001151" target="_blank">
                          ビザの必要な国
                        </A>
                      </>
                    )}
                  </ReserveConfirmTraveler>
                </div>
              ))}
              <Text color="danger" level="caption">
                {reservingTrip.isForeignFlightCustomerInfoRequired()
                  ? '※海外への渡航をご予定の場合、氏名等がパスポートの記載と異なると、当日ご利用いただけない場合がございます。'
                  : '※氏名、誕生日が間違っていると搭乗できない場合がありますのでご注意ください。'}
              </Text>
            </ChildContent>
          </Section>
        )}
        {reservingTrip.isShinkansenInfoRequired() && (
          <Section>
            <Subtitle>新幹線・特急</Subtitle>
            <div>
              {reserveInfo.travelers.list.length > 1 && (
                <RailwayTravelerInputHead>
                  <RadioGroup
                    layout="horizontal"
                    value={`${reserveInfo.isSeparateShinkansenType}`}
                    onChange={e => reserveInfo.setIsSeparateShinkansenType(e.target.value === 'true')}
                  >
                    <TheRadio value="false" style={{ marginRight: '10px' }}>
                      全員に同じチケットを適用
                    </TheRadio>
                    <TheRadio value="true">個別にチケット選ぶ</TheRadio>
                  </RadioGroup>
                </RailwayTravelerInputHead>
              )}
              <RailwayTravelerInputBox>
                {!reservingTrip.isPackage() && !reserveInfo.isSeparateShinkansenType && (
                  <>
                    <RailwayTravelerInputArea withBorder={false}>
                      <Label>チケットの券種</Label>
                      <InputAreaRight>
                        <div>
                          <RadioGroup
                            layout="horizontal"
                            value={reserveInfo.ticket}
                            onChange={e => {
                              handleReserveInfoChange('setTicket')(e);
                              reserveInfo.travelers.list.forEach((_traveler, i) => {
                                handleTravelerInfoChange(i, 'setShinkansenTicketType')(e);
                              });
                            }}
                          >
                            {showEx && <TheRadio value="ex">EX-IC</TheRadio>}
                            {useKyuusyuuTicket && <TheRadio value="kyuusyuu">JR九州きっぷ</TheRadio>}
                            <TheRadio value="normal">通常きっぷ</TheRadio>
                            {useBulkTicket && (
                              <FlexRadio value="bulk_ticket">
                                <span>回数券</span>
                                <Tooltip>
                                  「回数券」を選択した場合、日時・座席指定をしていない対象区間の特急・乗車券を送付します。
                                </Tooltip>
                              </FlexRadio>
                            )}
                          </RadioGroup>
                        </div>
                      </InputAreaRight>
                    </RailwayTravelerInputArea>
                    {reserveInfo.ticket !== 'bulk_ticket' && (
                      <RailwayTravelerInputArea withBorder={false}>
                        <Label>座席</Label>
                        <InputAreaRight>
                          <div>
                            <RadioGroup
                              layout="horizontal"
                              value={reserveInfo.seat}
                              onChange={e => {
                                handleReserveInfoChange('setSeat')(e);
                                reserveInfo.travelers.list.forEach((_traveler, i) => {
                                  handleTravelerInfoChange(i, 'setShinkansenSeatType')(e);
                                });
                              }}
                            >
                              {reserveInfo.isTargetSeatType('free') && <TheRadio value="free">自由席</TheRadio>}
                              {reserveInfo.isTargetSeatType('reserve') && (
                                <TheRadio value="reserve">指定席</TheRadio>
                              )}
                              {reserveInfo.isTargetSeatType('green') && (
                                <TheRadio value="green">グリーン席</TheRadio>
                              )}
                            </RadioGroup>
                          </div>
                        </InputAreaRight>
                      </RailwayTravelerInputArea>
                    )}
                  </>
                )}
                {(reserveInfo.seat === 'reserve' ||
                  reserveInfo.seat === 'green' ||
                  reserveInfo.ticket === 'ex' ||
                  reserveInfo.isSeparateShinkansenType) &&
                  reserveInfo.travelers.list.map((traveler, i) => (
                    <RailwayTravelerInputArea key={i} withBorder={i > 0}>
                      <RailwayTravelerInputName className="reserve-confirm__peoplenum">
                        <ThePersonIcon />
                        {traveler.lastNameRoman} {traveler.firstNameRoman}
                      </RailwayTravelerInputName>
                      <ReserveConfirmTraveler>
                        {reserveInfo.isSeparateShinkansenType && (
                          <>
                            <TravelerInputAreaItem>
                              <GrayLabel>チケットの券種</GrayLabel>
                              <div>
                                <RadioGroup
                                  layout="horizontal"
                                  value={traveler.shinkansenTicketType}
                                  onChange={handleTravelerInfoChange(i, 'setShinkansenTicketType')}
                                >
                                  {showEx && <TheRadio value="ex">EX-IC</TheRadio>}
                                  {useKyuusyuuTicket && <TheRadio value="kyuusyuu">JR九州きっぷ</TheRadio>}
                                  <TheRadio value="normal">通常きっぷ</TheRadio>
                                  {useBulkTicket && (
                                    <FlexRadio value="bulk_ticket">
                                      <span>回数券</span>
                                      <Tooltip>
                                        「回数券」を選択した場合、日時・座席指定をしていない対象区間の特急・乗車券を送付します。
                                      </Tooltip>
                                    </FlexRadio>
                                  )}
                                </RadioGroup>
                              </div>
                            </TravelerInputAreaItem>
                            {traveler.shinkansenTicketType !== 'bulk_ticket' && (
                              <TravelerInputAreaItem>
                                <GrayLabel>座席</GrayLabel>
                                <div>
                                  <RadioGroup
                                    layout="horizontal"
                                    value={traveler.shinkansenSeatType}
                                    onChange={handleTravelerInfoChange(i, 'setShinkansenSeatType')}
                                  >
                                    {reserveInfo.isTargetSeatType('free') && (
                                      <TheRadio value="free">自由席</TheRadio>
                                    )}
                                    {reserveInfo.isTargetSeatType('reserve') && (
                                      <TheRadio value="reserve">指定席</TheRadio>
                                    )}
                                    {reserveInfo.isTargetSeatType('green') && (
                                      <TheRadio value="green">グリーン席</TheRadio>
                                    )}
                                  </RadioGroup>
                                </div>
                              </TravelerInputAreaItem>
                            )}
                          </>
                        )}
                        {(traveler.shinkansenSeatType === 'reserve' ||
                          traveler.shinkansenSeatType === 'green' ||
                          reserveInfo.ticket === 'ex') && (
                          <TravelerInputAreaItem>
                            <GrayLabel>座席の希望</GrayLabel>
                            <div>
                              <RadioGroup
                                layout="horizontal"
                                value={traveler.shinkansenSeat}
                                onChange={handleTravelerInfoChange(i, 'setShinkansenSeat')}
                              >
                                <TheRadio value="指定なし">指定なし</TheRadio>
                                <TheRadio value="窓側">窓側</TheRadio>
                                <TheRadio value="通路側">通路側</TheRadio>
                                <TheRadio value="その他">その他</TheRadio>
                              </RadioGroup>
                              {traveler.shinkansenSeat === 'その他' && (
                                <Input
                                  className="shinkansen-seat"
                                  type="text"
                                  placeholder="(任意)"
                                  style={{ margin: '5px 0 0' }}
                                  value={traveler.requestShinkansenSeat}
                                  onChange={handleTravelerInfoChange(i, 'setShinkansenSeatText')}
                                />
                              )}
                            </div>
                          </TravelerInputAreaItem>
                        )}
                        {(reserveInfo.isSeparateShinkansenType
                          ? traveler.shinkansenTicketType
                          : reserveInfo.ticket) === 'ex' && (
                          <>
                            <TravelerInputAreaItem>
                              <GrayLabel>EX-IC番号</GrayLabel>
                              <div>
                                <Input
                                  type="text"
                                  placeholder="(任意)EX-IC番号"
                                  style={{ margin: '5px 0 0' }}
                                  value={traveler.exicNumber}
                                  onChange={handleTravelerInfoChange(i, 'setExicNumber')}
                                  autoComplete="new-password"
                                />
                              </div>
                            </TravelerInputAreaItem>
                            <TravelerInputAreaItem>
                              <GrayLabel>EX-ICパスワード</GrayLabel>
                              <div>
                                <Input
                                  type={exicPasswordTypes[i]}
                                  placeholder="(任意)EX-ICパスワード"
                                  style={{ margin: '5px 0 0' }}
                                  value={traveler.exicPassword}
                                  onChange={handleTravelerInfoChange(i, 'setExicPassword')}
                                  autoComplete="new-password"
                                />
                                <span
                                  onClick={async () => {
                                    await handleExicPasswordType(i);
                                  }}
                                >
                                  {exicPasswordTypes[i] === 'password' ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </span>
                              </div>
                            </TravelerInputAreaItem>
                          </>
                        )}
                      </ReserveConfirmTraveler>
                    </RailwayTravelerInputArea>
                  ))}
              </RailwayTravelerInputBox>
              {reserveInfo.ticket !== 'ex' && (
                <>
                  {reservingTrip.isPackage() && (
                    <>
                      <TravelerPeopleNum>
                        ※パッケージ商品の新幹線チケットは日時の変更ができません。通常のチケットとは取扱いが異なりますのでご注意ください
                      </TravelerPeopleNum>
                      <p>
                        <br />
                      </p>
                    </>
                  )}
                  <TravelerPeopleNum>切符送り先</TravelerPeopleNum>
                  <ReserveConfirmTraveler>
                    {reserveInfo.user.organization.use_qr ? (
                      utils.isTabikobo(reserveInfo.user.organization.service_id) ? (
                        <p>
                          【通常きっぷ】の新幹線チケットにつきましては、予約完了後、チャットにてQRコードを添付いたします。
                          <br />
                          添付したQRコードで駅の指定席券売機もしくは窓口にて発券をお願いいたします。
                          <br />
                          なお、発券方法等の詳細は、チャットにてご案内いたします。
                        </p>
                      ) : (
                        <p>
                          新幹線チケットにつきましては、弊社で予約後、チャット画面にてQRコードを添付いたします。
                          <br />
                          添付したQRコードをもとに駅の指定席券売機で発券をお願いいたします。
                          <br />
                          なお、発券方法等の詳細は、チャットにてご案内いたします。
                        </p>
                      )
                    ) : (
                      <>
                        {reserveInfo.user.organization.enabled_customize_address && (
                          <>
                            <TravelerType>
                              <input
                                id="shinkansen_text"
                                className="companion-label"
                                type="radio"
                                value="text"
                                onChange={handleReserveInfoChange('setShinkansenAddressType')}
                                checked={reserveInfo.shinkansenAddressType === 'text'}
                              />
                              <span>手動入力</span>
                            </TravelerType>
                            {reserveInfo.shinkansenAddressType === 'text' && (
                              <TravelerFromMember>
                                <span>郵便番号</span>
                                <Input
                                  id="postcode"
                                  type="text"
                                  name="postcode"
                                  value={reserveInfo.postcode}
                                  onChange={handleReserveInfoChange('setPostcode')}
                                />
                                <span>住所</span>
                                <input
                                  id="shinkansen_address"
                                  type="text"
                                  placeholder="チケットを送付するのに必要な情報まで記入ください（ビル名、部屋番号など）"
                                  value={reserveInfo.shinkansenAddress}
                                  onChange={handleReserveInfoChange('setShinkansenAddress')}
                                />
                                <span>宛名</span>
                                <input
                                  id="shinkansen_addressee"
                                  type="text"
                                  placeholder="個人宅への送付の場合は、送付先住所に加え、お受け取りの方のお名前をフルネームで記載をお願いします"
                                  value={reserveInfo.shinkansenAddressee}
                                  onChange={handleReserveInfoChange('setShinkansenAddressee')}
                                />
                              </TravelerFromMember>
                            )}
                          </>
                        )}
                        {departments && departments.length > 0 && (
                          <>
                            <TravelerType>
                              <input
                                type="radio"
                                id="shinkansen_select"
                                value="select"
                                onChange={handleReserveInfoChange('setShinkansenAddressType')}
                                checked={reserveInfo.shinkansenAddressType === 'select'}
                              />
                              <span>部署から選択</span>
                            </TravelerType>
                            {reserveInfo.shinkansenAddressType === 'select' && (
                              <TravelerFromMember>
                                <SelectDepartments
                                  departments={departments}
                                  onSelect={handleAddressDepartmentSelect}
                                  disabledAddress
                                  user={reserveInfo.user}
                                  isRequired
                                  workflowFormWidth={WORKFLOW_FORM_WIDTH}
                                />
                              </TravelerFromMember>
                            )}
                          </>
                        )}
                        {organizationBases && organizationBases.length > 0 && (
                          <>
                            <TravelerType>
                              <input
                                type="radio"
                                id="shinkansen_select"
                                value="organization_base_select"
                                onChange={handleReserveInfoChange('setShinkansenAddressType')}
                                checked={reserveInfo.shinkansenAddressType === 'organization_base_select'}
                              />
                              <span>拠点から選択</span>
                            </TravelerType>
                            {reserveInfo.shinkansenAddressType === 'organization_base_select' && (
                              <TravelerFromMember>
                                <SelectOrganizationBases
                                  organizationBases={organizationBases}
                                  onSelect={handleAddressOrganizationBaseSelect}
                                  isRequired
                                />
                              </TravelerFromMember>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </ReserveConfirmTraveler>
                  {validationErrors.postcode && <Text color="danger">{validationErrors.postcode}</Text>}
                  {validationErrors.shinkansenAddress && (
                    <Text color="danger">{validationErrors.shinkansenAddress}</Text>
                  )}
                </>
              )}
            </div>
          </Section>
        )}
        {reservingTrip.isHotelInfoRequired() && (
          <Section>
            <Subtitle>宿泊</Subtitle>
            {reserveInfo.hotels.map((hotel, i) => (
              <div key={`hotel_${String(i)}`}>
                <HotelName>{hotel.name}</HotelName>
                {validationErrors[`autoReserveRakutenHotel_${i}`] && (
                  <Text color="danger">{validationErrors[`autoReserveRakutenHotel_${i}`]}</Text>
                )}
                {hotel.rooms &&
                  hotel.rooms.map((room, j) => (
                    <div key={`room_${String(j)}`}>
                      <RoomNum>{j + 1}室目</RoomNum>
                      <ChildContent>
                        <TravelerInputArea>
                          <Label>代表者氏名（ローマ字）</Label>
                          <InputAreaRight>
                            <InputName
                              type="text"
                              placeholder="例) Yamada"
                              value={room.hotelLastName}
                              onChange={handleHotelInfoChange('setHotelLastName', i, j)}
                            />
                            <InputName
                              type="text"
                              placeholder="例) Ichiro"
                              value={room.hotelFirstName}
                              onChange={handleHotelInfoChange('setHotelFirstName', i, j)}
                            />
                          </InputAreaRight>
                          {validationErrors[`firstName_${j}`] && (
                            <Text color="danger">{validationErrors[`firstName_${j}`]}</Text>
                          )}
                          {validationErrors[`lastName_${j}`] && (
                            <Text color="danger">{validationErrors[`lastName_${j}`]}</Text>
                          )}
                        </TravelerInputArea>
                        <TravelerInputArea>
                          <Label>代表者氏名（カナ）</Label>
                          <InputAreaRight>
                            <InputName
                              type="text"
                              placeholder="例) ヤマダ"
                              value={room.hotelLastNameKana}
                              onChange={handleHotelInfoChange('setHotelLastNameKana', i, j)}
                            />
                            <InputName
                              type="text"
                              placeholder="例) イチロウ"
                              value={room.hotelFirstNameKana}
                              onChange={handleHotelInfoChange('setHotelFirstNameKana', i, j)}
                            />
                          </InputAreaRight>
                          {validationErrors[`firstNameKana_${j}`] && (
                            <Text color="danger">{validationErrors[`firstNameKana_${j}`]}</Text>
                          )}
                          {validationErrors[`lastNameKana_${j}`] && (
                            <Text color="danger">{validationErrors[`lastNameKana_${j}`]}</Text>
                          )}
                        </TravelerInputArea>
                        <TravelerInputArea>
                          <Label>代表者電話番号</Label>
                          <InputAreaRight>
                            <Input
                              type="text"
                              placeholder="例) 090-1111-2222"
                              value={toDomesticTel(room.hotelTel)}
                              onChange={handleHotelInfoChange('setHotelTel', i, j)}
                            />
                          </InputAreaRight>
                          {validationErrors[`tel_${j}`] && (
                            <Text color="danger">{validationErrors[`tel_${j}`]}</Text>
                          )}
                        </TravelerInputArea>
                        {room.bedTypes ? (
                          <TravelerInputArea>
                            <Label>ベッドの種類</Label>
                            <InputAreaRight>
                              <div>
                                {room.bedTypes.map((bedType, i) => (
                                  <p key={i}>{bedType.description}</p>
                                ))}
                              </div>
                            </InputAreaRight>
                          </TravelerInputArea>
                        ) : (
                          <TravelerInputArea>
                            <Label>ベッドの種類</Label>
                            <InputAreaRight>
                              <div>
                                <p>-</p>
                              </div>
                            </InputAreaRight>
                          </TravelerInputArea>
                        )}
                      </ChildContent>
                    </div>
                  ))}
              </div>
            ))}
          </Section>
        )}
        {!reservingTrip.isForeignFlightCustomerInfoRequired() && (
          <>
            {reserveInfo.rentalCarAvailable && fromTripForm === false && (
              <Section>
                <Subtitle>
                  <SubtitleLeft>レンタカー</SubtitleLeft>
                  <SubtitleRight>※オプション</SubtitleRight>
                </Subtitle>
                <ChildContent>
                  <RentalCarDescription>
                    レンタカーをご利用予定の場合、下の「レンタカーを予約する」のテキストをクリックしてください。
                  </RentalCarDescription>
                  <ReserveConfirmTraveler>
                    <RentalCarLabel>
                      <input
                        className="companion-label"
                        type="radio"
                        value="false"
                        onChange={handleReserveInfoChange('setRentalCarRequired')}
                        checked={reserveInfo.rentalCarRequired === 'false'}
                      />
                      <span>レンタカーを予約しない</span>
                    </RentalCarLabel>
                    <RentalCarLabel>
                      <input
                        id="book-rental-car"
                        type="radio"
                        value="true"
                        onChange={handleReserveInfoChange('setRentalCarRequired')}
                        checked={reserveInfo.rentalCarRequired === 'true'}
                      />
                      <span>レンタカーを予約する（※確定料金は依頼後のチャット画面でお伝えします）</span>
                    </RentalCarLabel>
                    {reserveInfo.rentalCarRequired === 'true' && (
                      <>
                        {reserveInfo.rentalCars.map((rentalCar, i) => (
                          <div key={i}>
                            <RentalCarOrderTitle>ご依頼内容{i !== 0 && i + 1}</RentalCarOrderTitle>
                            <RentalCarOrderContent>
                              {i !== 0 && (
                                <RentalCarRemove onClick={handleRentalCarInfoRemove(i)}>×</RentalCarRemove>
                              )}
                              <TravelerRentalCarInputAreaTitleText>
                                <TravelerRentalCarLabel>出発日時</TravelerRentalCarLabel>
                                <TravelerRentalCarInputAreaTextInput>
                                  <RentalCarDatePicker>
                                    <DatetimePicker
                                      value={moment(rentalCar.departureDate)}
                                      onChange={handleDateChange('setDepartureDate', i)}
                                      showTime
                                      showPast={false}
                                      disabledDays={reserveInfo.rentalCarLimitType.actualDays}
                                    />
                                  </RentalCarDatePicker>
                                </TravelerRentalCarInputAreaTextInput>
                              </TravelerRentalCarInputAreaTitleText>
                              {!_.isEmpty(validationErrors[`departureDate_${i}`]) && (
                                <Text color="danger">{validationErrors[`departureDate_${i}`]}</Text>
                              )}
                              <TravelerRentalCarInputAreaTitleText>
                                <TravelerRentalCarLabel>返却日時</TravelerRentalCarLabel>
                                <TravelerRentalCarInputAreaTextInput>
                                  <RentalCarDatePicker>
                                    <DatetimePicker
                                      value={moment(rentalCar.returnDate)}
                                      onChange={handleDateChange('setReturnDate', i)}
                                      showTime
                                      showPast={false}
                                      disabledDays={reserveInfo.rentalCarLimitType.actualDays}
                                    />
                                  </RentalCarDatePicker>
                                </TravelerRentalCarInputAreaTextInput>
                              </TravelerRentalCarInputAreaTitleText>
                              <TravelerInputArea>
                                <TravelerRentalCarLabel>出発場所</TravelerRentalCarLabel>
                                <TravelerRentalCarInputAreaTextInput>
                                  <StyledAutoCompletableInput
                                    id={`rental-car-departure-place_${i}`}
                                    placeholder="例) 東京駅"
                                    value={rentalCar.departurePlace}
                                    onChange={(_, value, address) =>
                                      handleRentalCarInfoChange(
                                        'setDeparturePlace',
                                        i
                                      )(address ? `${value}(${address})` : value)
                                    }
                                  />
                                </TravelerRentalCarInputAreaTextInput>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors[`departurePlace_${i}`]) && (
                                <Text color="danger">{validationErrors[`departurePlace_${i}`]}</Text>
                              )}
                              <TravelerInputArea>
                                <TravelerRentalCarLabel>返却場所</TravelerRentalCarLabel>
                                <TravelerRentalCarInputArea>
                                  <RentalCarRadio>
                                    <input
                                      className="companion-label"
                                      type="radio"
                                      value="current"
                                      onChange={e =>
                                        handleRentalCarInfoChange('setReturnPlace', i)(e.target.value)
                                      }
                                      checked={rentalCar.returnPlace === 'current'}
                                    />
                                    <RentalCarRadioP>出発場所へ戻す</RentalCarRadioP>
                                  </RentalCarRadio>
                                  <RentalCarRadio>
                                    <input
                                      type="radio"
                                      value="other"
                                      onChange={e =>
                                        handleRentalCarInfoChange('setReturnPlace', i)(e.target.value)
                                      }
                                      checked={rentalCar.returnPlace === 'other'}
                                    />
                                    <RentalCarRadioP>
                                      別の場所へ乗り捨てる(※乗り捨てを希望する場所を記載して下さい)
                                    </RentalCarRadioP>
                                  </RentalCarRadio>
                                  <RentalCarRadio>
                                    <StyledAutoCompletableInput
                                      id={`rental-car-return-place_${i}`}
                                      value={rentalCar.returnPlaceName}
                                      onChange={(_, value, address) =>
                                        handleRentalCarInfoChange(
                                          'setReturnPlaceName',
                                          i
                                        )(address ? `${value}(${address})` : value)
                                      }
                                      disabled={rentalCar.returnPlace !== 'other'}
                                    />
                                  </RentalCarRadio>
                                </TravelerRentalCarInputArea>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors[`returnPlace_${i}`]) && (
                                <Text color="danger">{validationErrors[`returnPlace_${i}`]}</Text>
                              )}
                              <TravelerInputArea>
                                <TravelerRentalCarLabel>
                                  車種・クラス ※選択いただいたタイプから適切な車両をお選びします。
                                </TravelerRentalCarLabel>
                                <TravelerRentalCarInputAreaCarType>
                                  {rentalCar.carTypeOptions.map((c, ci) => (
                                    <RentalCarRadio key={`car_type_${ci}`}>
                                      <input
                                        className="companion-label"
                                        type="radio"
                                        value={c[1]}
                                        onChange={e => handleRentalCarInfoChange('setCarType', i)(e.target.value)}
                                        checked={rentalCar.carType === c[1]}
                                      />
                                      <RentalCarRadioP>{c[0]}</RentalCarRadioP>
                                    </RentalCarRadio>
                                  ))}
                                  <RentalCarRadio>
                                    <InputName
                                      type="text"
                                      value={rentalCar.carTypeOther}
                                      onChange={e =>
                                        handleRentalCarInfoChange('setCarTypeOther', i)(e.target.value)
                                      }
                                      disabled={rentalCar.carType !== 'other'}
                                    />
                                  </RentalCarRadio>
                                </TravelerRentalCarInputAreaCarType>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors[`carType_${i}`]) && (
                                <>
                                  <Text color="danger">{validationErrors[`carType_${i}`]}</Text>

                                  <TravelerInputArea>
                                    <TravelerRentalCarLabel>その他</TravelerRentalCarLabel>
                                    <TravelerRentalCarInputAreaCarOther />
                                  </TravelerInputArea>
                                </>
                              )}
                              <TravelerInputArea>
                                <Label>台数</Label>
                                <InputAreaRight>
                                  <select
                                    value={rentalCar.carNumber}
                                    onChange={e => handleRentalCarInfoChange('setCarNumber', i)(e.target.value)}
                                  >
                                    {_.times(8, i => (
                                      <option value={i + 1} key={i}>{`${i + 1}台`}</option>
                                    ))}
                                  </select>
                                </InputAreaRight>
                              </TravelerInputArea>
                              <TravelerInputArea>
                                <Label>運転者氏名（カナ）</Label>
                                <InputAreaRight>
                                  <InputName
                                    type="text"
                                    placeholder="例) ヤマダ"
                                    value={rentalCar.lastNameKana}
                                    onChange={e =>
                                      handleRentalCarInfoChange('setRCLastNameKana', i)(e.target.value)
                                    }
                                  />
                                  <InputName
                                    type="text"
                                    placeholder="例) イチロウ"
                                    value={rentalCar.firstNameKana}
                                    onChange={e =>
                                      handleRentalCarInfoChange('setRCFirstNameKana', i)(e.target.value)
                                    }
                                  />
                                </InputAreaRight>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors[`rcLastNameKana_${i}`]) && (
                                <Text color="danger">{validationErrors[`rcLastNameKana_${i}`]}</Text>
                              )}
                              {!_.isEmpty(validationErrors[`rcFirstNameKana_${i}`]) && (
                                <Text color="danger">{validationErrors[`rcFirstNameKana_${i}`]}</Text>
                              )}
                              <TravelerInputArea>
                                <Label>運転者電話番号</Label>
                                <InputAreaRight>
                                  <Input
                                    type="text"
                                    placeholder="例) 090-1111-2222"
                                    value={toDomesticTel(rentalCar.driverTel)}
                                    onChange={e => handleRentalCarInfoChange('setDriverTel', i)(e.target.value)}
                                  />
                                </InputAreaRight>
                              </TravelerInputArea>
                              {!_.isEmpty(validationErrors[`rcTel_${i}`]) && (
                                <Text color="danger">{validationErrors[`rcTel_${i}`]}</Text>
                              )}
                              <TravelerInputArea>
                                <TravelerRentalCarLabel>備考</TravelerRentalCarLabel>
                                <TravelerRentalCarInputAreaCarRemarks>
                                  <textarea
                                    className="companion-label"
                                    value={rentalCar.remarks}
                                    onChange={e => handleRentalCarInfoChange('setRemarks', i)(e.target.value)}
                                  />
                                </TravelerRentalCarInputAreaCarRemarks>
                              </TravelerInputArea>
                            </RentalCarOrderContent>
                          </div>
                        ))}
                        <AddRentalCarToggle onClick={handleRentalCarInfoAdd}>
                          + 予約フォームを追加する
                        </AddRentalCarToggle>
                      </>
                    )}
                  </ReserveConfirmTraveler>
                </ChildContent>
              </Section>
            )}
          </>
        )}
        {reserveInfo.user.organization.reserve_request_mail_notification_type === 'selectable_user' ? (
          <>
            <Subtitle>予約依頼に関するメール通知対象者の選択</Subtitle>
            <ChildContent>
              <NotifiedUserSelectorTemplate
                applicant={applicant}
                users={reserveInfo.notifiedUsers}
                onAdd={handleNotifiedUserAdd}
                onSelect={handleNotifiedUserSelect}
                onRemove={handleNotifiedUserRemove}
              />
            </ChildContent>
            {!_.isEmpty(validationErrors.notifiedUsers) && (
              <Text color="danger">{validationErrors.notifiedUsers}</Text>
            )}
          </>
        ) : (
          <>
            <Subtitle>予約依頼に関するメール通知の有無</Subtitle>
            <ChildContent>
              <NotificationSubArea>
                <NotificationCards>
                  <Input
                    type="checkbox"
                    checked={reserveInfo.allTravelersNotification}
                    onChange={handleReserveInfoChange('toggleAllTravelersNotification')}
                  />
                  <span>手配内容を関係者に通知する</span>
                </NotificationCards>
                <SmallGraySpan>※申請者にはチェックにかかわらず通知されます</SmallGraySpan>
              </NotificationSubArea>
            </ChildContent>
          </>
        )}
        <Section>
          <Subtitle>申請内容</Subtitle>
          {!isSimpleRequest && reserveInfo.user.organization.freee_workflow && reserveInfo.isApprovalRequired() && (
            <FreeeSection>
              <p>freeeによるワークフロー連携が設定されています。</p>
              <p>申請の依頼は予約依頼完了後にfreee上にて行ってください。</p>
            </FreeeSection>
          )}
          <ChildContent>
            {!reserveInfo.user.organization.freee_workflow &&
              reserveInfo.isApprovalRequired() &&
              reserveInfo.workflowStyle === 'department' && (
                <>
                  {reserveInfo.getApplicant().department_approvers &&
                    reserveInfo.getApplicant().department_approvers!.list.map(
                      (a, i) =>
                        (i === 0 ||
                          a.approve_stage >
                            reserveInfo.getApplicant().department_approvers!.list[i - 1].approve_stage) && (
                          <TravelerInputArea key={`input_approver_${i}`}>
                            <Label>{a.approve_stage}次承認者</Label>
                            <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                              {reserveInfo
                                .getApplicant()
                                .department_approvers!.joinApproverByApproveStage(a.approve_stage)
                                .join(', ')}
                            </WorkflowInputArea>
                          </TravelerInputArea>
                        )
                    )}
                </>
              )}
            {reserveInfo.isApprovalRequired() || reserveInfo.isDestinationBusinessTripRequired() ? (
              <>
                <TravelerInputArea>
                  <Label>
                    出張先<Red>*</Red>
                  </Label>
                  <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                    <InputTextArea
                      id="finalDestination"
                      value={reserveInfo.finalDestination}
                      onChange={handleReserveInfoChange('setFinalDestination')}
                      placeholder="(必須)出張先"
                      rows={2}
                    />
                  </WorkflowInputArea>
                </TravelerInputArea>
                {!_.isEmpty(validationErrors.area) && <Text color="danger">{validationErrors.area}</Text>}
                {!_.isEmpty(utils.dig(errorsInputCustomer, 'area')) && (
                  <Text color="danger">{errorsInputCustomer?.area}</Text>
                )}
              </>
            ) : (
              <TravelerInputArea>
                <Label>出張先</Label>
                <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                  <InputTextArea
                    id="finalDestination"
                    value={reserveInfo.finalDestination}
                    onChange={handleReserveInfoChange('setFinalDestination')}
                    placeholder="(任意)出張先"
                    rows={2}
                  />
                </WorkflowInputArea>
              </TravelerInputArea>
            )}

            {reserveInfo.isApprovalRequired() || reserveInfo.isPurposeBusinessTripRequired() ? (
              <>
                <TravelerInputArea>
                  <Label>
                    出張の目的<Red>*</Red>
                  </Label>
                  <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                    <InputTextArea
                      id="purpose"
                      value={reserveInfo.purpose}
                      onChange={handleReserveInfoChange('setPurpose')}
                      placeholder="(必須)出張の目的や承認者への備考などを入力してください"
                      rows={3}
                    />
                  </WorkflowInputArea>
                </TravelerInputArea>
                {!_.isEmpty(validationErrors.purpose) && <Text color="danger">{validationErrors.purpose}</Text>}
                {!_.isEmpty(utils.dig(errorsInputCustomer, 'purpose')) && (
                  <Text color="danger">{errorsInputCustomer?.purpose}</Text>
                )}
              </>
            ) : (
              <TravelerInputArea>
                <Label>出張の目的</Label>
                <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                  <InputTextArea
                    id="purpose"
                    value={reserveInfo.purpose}
                    onChange={handleReserveInfoChange('setPurpose')}
                    placeholder="(任意)出張の目的や承認者への備考などを入力してください"
                    rows={3}
                  />
                </WorkflowInputArea>
              </TravelerInputArea>
            )}

            {!isSimpleRequest && reserveInfo.isInternalNumberShown() && (
              <>
                {reserveInfo.isInternalNumberRequired() ? (
                  <>
                    <TravelerInputArea>
                      <Label>
                        社内管理番号<Red>*</Red>
                      </Label>
                      <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                        <InputInternalNumber
                          type="text"
                          placeholder="(必須)社内稟議番号など"
                          value={reserveInfo.internalNumber}
                          onChange={handleReserveInfoChange('setInternalNumber')}
                        />
                      </WorkflowInputArea>
                    </TravelerInputArea>
                    {!_.isEmpty(validationErrors.internalNumber) && (
                      <Text color="danger">{validationErrors.internalNumber}</Text>
                    )}
                  </>
                ) : (
                  <TravelerInputArea>
                    <Label>社内管理番号</Label>
                    <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                      <InputInternalNumber
                        type="text"
                        placeholder="(任意)社内稟議番号など"
                        value={reserveInfo.internalNumber}
                        onChange={handleReserveInfoChange('setInternalNumber')}
                      />
                    </WorkflowInputArea>
                  </TravelerInputArea>
                )}
              </>
            )}
            {!isSimpleRequest && reserveInfo.isChargingDepartmentShown() && (
              <TravelerInputArea>
                <ChargingDepartmentSection
                  detailMode={departmentDetailMode}
                  showDetail={showDepartmentDetail}
                  onClickShowDetail={handleShowDepartmentDetailClick}
                  onClickHideDetail={handleCancelDepartmentDetailClick}
                  onClickChangeDetailMode={handleDepartmentDetailModeChange}
                  onSelectDetail={handleDepartmentDetailChange}
                  onSelect={handleChargingDepartmentSelect}
                  reserveInfo={reserveInfo}
                  itemList={itemList}
                  workflowFormWidth={WORKFLOW_FORM_WIDTH}
                  projectShareAvailability={projectShareAvailability}
                />
                {!_.isEmpty(validationErrors.chargingDepartment) && (
                  <Text color="danger">{validationErrors.chargingDepartment}</Text>
                )}
              </TravelerInputArea>
            )}
            {!isSimpleRequest && reserveInfo.isProjectNameShown() && (
              <TravelerInputArea>
                <ProjectNameSection
                  detailMode={projectDetailMode}
                  showDetail={showProjectDetail}
                  onClickShowDetail={handleShowProjectDetailClick}
                  onClickHideDetail={handleCancelProjectDetailClick}
                  onClickChangeDetailMode={handleProjectDetailModeChange}
                  onSelectDetail={handleProjectDetailChange}
                  onSelect={handleProjectSelect}
                  reserveInfo={reserveInfo}
                  itemList={itemList}
                  workflowFormWidth={WORKFLOW_FORM_WIDTH}
                  projectShareAvailability={projectShareAvailability}
                />
                {!_.isEmpty(validationErrors.projectId) && (
                  <Text color="danger">{validationErrors.projectId}</Text>
                )}
              </TravelerInputArea>
            )}

            {!isSimpleRequest &&
              reserveInfo.expensesAccountTypeAvailable &&
              reserveInfo.isExpensesAcountTypeShown() && (
                <TravelerInputArea>
                  <ExpensesAccountTypeSection
                    detailMode={expensesAccountTypeDetailMode}
                    showDetail={showExpensesAccountTypeDetail}
                    onClickShowDetail={handleShowExpensesAccountTypeDetailClick}
                    onClickHideDetail={handleCancelExpensesAccountTypeDetailClick}
                    onClickChangeDetailMode={handleExpensesAccountTypeDetailModeChange}
                    onSelectDetail={handleExpensesAccountTypeDetailChange}
                    onSelect={handleExpensesAccountTypeSelect}
                    reserveInfo={reserveInfo}
                    itemList={itemList}
                    workflowFormWidth={WORKFLOW_FORM_WIDTH}
                  />
                  {!_.isEmpty(validationErrors.expensesAccountType) && (
                    <Text color="danger">{validationErrors.expensesAccountType}</Text>
                  )}
                </TravelerInputArea>
              )}

            {/* ここは簡易申請でも表示させたい */}
            {reserveInfo.approveItems.list.map((d: any, i: number) => (
              <div key={`approval_items_${i}`}>
                {d.dataType === 'label' ? (
                  <ApproveItemLabelOnly style={{ wordWrap: 'break-word' }}>
                    <Markdown markdownText={d.userDisplayName} openLinksNewTab />
                  </ApproveItemLabelOnly>
                ) : (
                  <>
                    <TravelerInputArea key={d.id}>
                      <Label>
                        {d.userDisplayName}
                        {(d.requiredType === 'required' ||
                          reserveInfo.isApproveItemRequiredWithWorkflow(d.requiredType)) && <Red>*</Red>}
                      </Label>
                      {d.dataType === 'freetext' && (
                        <WorkflowInputArea data-wovn-ignore workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                          <InputTextArea
                            id={d.id}
                            value={reserveInfo.getApproveItemValue(d.id)}
                            onChange={handleApproveTextChange(d.id)}
                            placeholder={d.getReservePlaceholder(
                              reserveInfo.isApproveItemRequiredWithWorkflow(d.requiredType)
                            )}
                            rows={1}
                          />
                        </WorkflowInputArea>
                      )}
                      {d.dataType === 'multilinefreetext' && (
                        <WorkflowInputArea data-wovn-ignore workflowFormWidth={WORKFLOW_FORM_WIDTH}>
                          <InputTextArea
                            id={d.id}
                            value={reserveInfo.getApproveItemValue(d.id)}
                            onChange={handleApproveTextChange(d.id)}
                            placeholder={d.getReservePlaceholder(
                              reserveInfo.isApproveItemRequiredWithWorkflow(d.requiredType)
                            )}
                            rows={5}
                          />
                        </WorkflowInputArea>
                      )}
                      {d.dataType === 'calendar' && (
                        <ApproveItemDatePicker data-wovn-ignore>
                          <DatetimePicker
                            placeholder="選択してください"
                            onChange={handleApproveCalendarSelect(d.id)}
                            value={
                              reserveInfo.getApproveItemValue(d.id) !== null &&
                              reserveInfo.getApproveItemValue(d.id) !== undefined
                                ? moment(reserveInfo.getApproveItemValue(d.id))
                                : undefined
                            }
                            disabledDays={0}
                            showTime={false}
                            showPast
                            deletable
                            border
                          />
                        </ApproveItemDatePicker>
                      )}
                      {d.dataType === 'list' && (
                        <SelectApproveItemList
                          onSelect={handleApproveListChange(d.id)}
                          approveItemId={d.id}
                          chargingDepartmentIds={reserveInfo.getChargingDepartmentIDs()}
                          defaultItemListName={
                            reserveInfo.getApproveItemValueCode(d.id) && reserveInfo.getApproveItemValue(d.id)
                              ? `${reserveInfo.getApproveItemValueCode(d.id)}：${reserveInfo.getApproveItemValue(
                                  d.id
                                )}`
                              : '選択してください'
                          }
                          workflowFormWidth={WORKFLOW_FORM_WIDTH}
                        />
                      )}
                      {d.dataType === 'file' && (
                        <ApproveItemDropzone
                          preview={
                            reserveInfo.getApproveItemFile(d.id)
                              ? reserveInfo.getApproveItemFile(d.id)?.name
                              : undefined
                          }
                          onSelect={f => handleApproveFileChange(d.id, f)}
                          onRemove={() => handleApproveFileRemove(d.id)}
                        />
                      )}
                    </TravelerInputArea>
                    {!_.isEmpty(validationErrors[`appleveItem_${d.id}`]) && (
                      <Text key={`appleveItem_${d.id}`} color="danger">
                        {validationErrors[`appleveItem_${d.id}`]}
                      </Text>
                    )}
                    {!_.isEmpty(utils.dig(errorsInputCustomer, `appleveItem_${d.id}`)) && (
                      <Text color="danger">
                        {errorsInputCustomer && errorsInputCustomer[`appleveItem_${d.id}`]}
                      </Text>
                    )}
                  </>
                )}
              </div>
            ))}
          </ChildContent>
        </Section>
        {!reserveInfo.user.organization.freee_workflow &&
          reserveInfo.isApprovalRequired() &&
          reserveInfo.workflowStyle === 'trip' && (
            <Section>
              {/* 旅程ごとにワークフローを通したいニーズに対応、adminでこ顧客ごとに切り替えることができる */}
              <Subtitle>ワークフロー 承認ルート登録</Subtitle>
              <ChildContent>
                <ApproveRoute
                  handleApproveStagesChange={handleApproveStagesChange}
                  stages={reserveInfo.approveStages}
                />
                {!_.isEmpty(utils.dig(errorsInputCustomer, 'approveStages')) && (
                  <Text color="danger">{errorsInputCustomer?.approveStages}</Text>
                )}
              </ChildContent>
            </Section>
          )}
        {!isSimpleRequest && reserveInfo.isPrivateUseShown() && (
          <Section>
            <Subtitle>福利厚生</Subtitle>
            <ChildContent>
              <WelfareSection reserveInfo={reserveInfo} />
            </ChildContent>
          </Section>
        )}
        {theme.themeClass === 'aitravel' && reserveInfo.reservingTrip.items.length > 0 && (
          <Section>
            <Subtitle>支払い方法</Subtitle>
            <ChildContent>
              <PaymentSection
                validationErrors={validationErrors}
                reserveInfo={reserveInfo}
                handleReserveInfoChange={handleReserveInfoChange}
              />
            </ChildContent>
          </Section>
        )}
        <ChildWrapper>
          <Section>
            {!isSimpleRequest &&
              reserveInfo.user.organization.freee_workflow &&
              !isSubmitting &&
              reserveInfo.isApprovalRequired() && (
                <div>
                  <p>
                    freeeのワークフロー機能が有効になっております。
                    <a href="users/edit" target="_blank">
                      こちらのページ
                    </a>
                    から、ワークフロー連携の設定を行ってください。
                  </p>
                </div>
              )}
          </Section>
        </ChildWrapper>
      </ChildWrapper>
      <div>
        {_.entries(validationErrors).map(([k, v]) => (
          <Text key={k} color="danger">
            {v?.split('\n').map(line => (
              <React.Fragment key={line}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Text>
        ))}
      </div>
      <ButtonArea>
        {!isSimpleRequest && <ConfirmBack onClick={() => handleBackToSelectClick()}>{'< 戻る'}</ConfirmBack>}
        <ConfirmButtons>
          {handleSaveDraft && <DraftButton onClick={handleSaveDraft}>下書き保存</DraftButton>}
          {!isSimpleRequest &&
            (!reserveInfo.user.organization.freee_workflow ||
              !reserveInfo.isApprovalRequired() ||
              isSubmitting) && (
              <NextButton to="step2" onClick={validateInput}>
                次へ
              </NextButton>
            )}
        </ConfirmButtons>
      </ButtonArea>
    </>
  );
};

const Title = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const ChildWrapper = styled.div`
  font-size: 13px;
  margin-bottom: 20px;
`;

const ChildContent = styled.div`
  font-size: 13px;
  margin-left: 10px;
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  margin: 20px 0 10px;
  font-weight: bold;
`;

const SubtitleLeft = styled.div`
  float: left;
  padding-right: 5px;
`;

const SubtitleRight = styled.div`
  float: left;
  font-size: 13px;
  font-weight: normal;
`;

const Label = styled.div`
  font-size: 13px;
  width: 200px;
  line-height: 34px;
  word-wrap: break-word;
  padding-right: 5px;
`;

const GrayLabel = styled.div`
  color: #666;
  font-size: 13px;
  line-height: 34px;
  font-weight: bold;
  word-wrap: break-word;
`;

const Input = styled.input`
  max-width: 300px;
  margin-right: 10px;
`;

const InputInternalNumber = styled.input`
  margin-right: 0px;
`;
const InputTextArea = styled.textarea`
  margin-right: 0px;
`;

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 420px;
`;

const InputNationarityAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 420px;
  margin-bottom: 10px;
`;

const InputAirTicketAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  flex-flow: column;
  width: 420px;
`;

const InputBirthDay = styled.input`
  width: 120px;
  margin-right: 10px;
`;

const InputNamedisabled = css`
  background-color: #aaa !important;
`;

const InputName = styled.input`
  width: 120px !important;
  margin-right: 10px;
  ${props => props.disabled && InputNamedisabled}
`;

const SmallGraySpan = styled.div`
  font-size: 11px;
  color: $gray-color-text;
`;

const ReserveConfirmTraveler = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
`;

const PeopleNum = styled.p`
  font-size: 13px;
  margin-top: 10px;
`;

const TravelerInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const RailwayTravelerInputHead = styled.div`
  padding: 0 12px 12px;
`;

const RailwayTravelerInputBox = styled.div`
  margin-bottom: 24px;
  padding: 12px 0;
  background-color: #fafafa;
`;

const RailwayTravelerInputArea = styled.div<{ withBorder: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding: 0 20px;

  ${props =>
    props.withBorder &&
    `
    border-top: solid 1px #eee;
  `}
`;

const TravelerInputAreaItem = styled.div`
  margin: 3px 0 10px;
`;

const RailwayTravelerInputName = styled.div`
  padding-right: 10px;
  font-weight: bold;
`;

const ThePersonIcon = styled(PersonIcon)`
  color: #666;
  vertical-align: middle;
  margin-right: 5px;
`;

const TravelerPeopleNum = styled.p`
  font-size: 13px;
`;

const TravelerType = styled.label`
  margin: 10px 0 5px;
  font-size: 13px;
`;

const TravelerRentalCarLabel = styled.div`
  line-height: 34px;
  display: flex;
  float: left;
`;

const RentalCarDescription = styled.p`
  clear: both;
  padding: 10px 0;
`;

const RentalCarLabel = styled.label`
  margin: 10px 0 5px;
  font-size: 13px;
`;

const RentalCarRadio = styled.label`
  margin-left: 20px;
`;

const RentalCarRadioP = styled.p`
  display: inline-block;

  @media (max-width: 430px) {
    margin-top: -7px;
    display: block;
    float: left;
    width: calc(100% - 24px);
  }
`;

const RentalCarOrderTitle = styled.p`
  margin: 10px 0 5px;
`;

const RentalCarOrderContent = styled.div`
  margin-left: 0;
  padding-left: 20px;
  position: relative;
  border-left: 3px solid #7d7d7d;
`;

const RentalCarDatePicker = styled.div`
  margin-left: 0;
  display: block;
`;

const ApproveItemDatePicker = styled.div<{ workflowFormWidth?: string }>`
  margin-left: 0;
  display: block;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const RentalCarRemove = styled.div`
  float: right;
  cursor: pointer;
  margin-right: 60px;
`;

const AddRentalCarToggle = styled.div`
  color: #af985e;
  text-decoration: none;
  transition: color 0.1s linear;
  cursor: pointer;
`;

const TravelerRentalCarInputArea = styled.div`
  line-height: 34px;
`;

const TravelerRentalCarInputAreaTitleText = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const TravelerRentalCarInputAreaTextInput = styled.div`
  line-height: 34px;
  margin-left: 20px;
  margin-top: 5px;

  @media (max-width: 430px) {
    margin-left: 0;
  }
`;

const TravelerRentalCarInputAreaCarType = styled.div`
  line-height: 34px;
  margin-left: 52px;

  @media (max-width: 430px) {
    margin-left: 0;
  }
`;

const TravelerRentalCarInputAreaCarOther = styled.div`
  line-height: 34px;
  margin-left: 13px;
`;

const TravelerRentalCarInputAreaCarRemarks = styled.div`
  line-height: 34px;
  margin-top: 10px;
  margin-left: 47px;
`;

const RadioLabel = styled.label`
  display: flex;
  margin-right: 10px;
`;

const TheRadio = styled(Radio)`
  margin-right: 10px;

  .Mui-checked + .MuiFormControlLabel-label {
    font-weight: bold;
  }
`;

const FlexRadio = styled(TheRadio)`
  .MuiFormControlLabel-label {
    display: flex;
  }
`;

const Red = styled.span`
  color: ${getColor('danger', 'primary')};
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConfirmBack = styled(A)`
  margin: auto 0;
`;

const NextButton = styled(LinkButton)`
  margin-left: 15px;
  padding: 0.75em 1em;
  line-height: 1;
`;

const NotificationSubArea = styled.div`
  margin-top: 15px;
`;

const NotificationCards = styled.label`
  margin-bottom: 15px;
`;

const HotelName = styled.p`
  @media {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
`;

const RoomNum = styled.p`
  @media (max-width: 430px) {
    font-size: 13px;
    margin-top: 10px;
  }
`;

const SelectTravelersSelectedListItem = styled.div`
  display: flex;
  padding: 5px 20px;
  border: 2px solid $gray-color;
  cursor: pointer;
`;

const MembersListItemInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MembersListItemName = styled.p`
  margin-right: 10px;
`;

const FlightCustomerInfo = styled.div`
  display: flex;
`;

const CaptionText = styled.p`
  line-height: 1.5;
  color: #3a3a3a;
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.007em;
`;

export const ReserveErrorAlert = styled(Alert)`
  background-color: #f6eae6;
  color: ${props => props.theme.redColor};
  margin-bottom: 20px;
  .MuiAlert-message {
    width: 100%;
  }
`;

export const ReserveSuccessAlert = styled(Alert)`
  background-color: #edf6fa;
  color: #48a4c8;
  margin-bottom: 20px;
  .MuiAlert-message {
    width: 100%;
  }
`;

export const DescriptionButton = styled.div`
  cursor: pointer;
  width: 50px;
  margin-left: auto;
  display: flex;
  justify-content: center;
`;

const StyledAutoCompletableInput = styled(AutoCompletableInput)`
  // TextFieldの詳細度に負けてしまうため "&&&" で重ねがけ
  &&& {
    input {
      border: 1px solid #eee;
      padding: 0.5em;
      height: auto;
      width: 300px;
    }
  }
`;

const FreeeSection = styled.div`
  margin-bottom: 10px;
`;

const WorkflowInputArea = styled.div<{ workflowFormWidth?: string }>`
  line-height: 34px;
  display: flex;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const ApproveItemLabelOnly = styled.div`
  margin-bottom: 5px;
`;

const ConfirmButtons = styled.div`
  display: flex;
`;

const DraftButton = styled.button`
  ${BorderButtonBase}
`;

export const StyledAlertTitle = styled(AlertTitle)`
  font-weight: 700;
  margin-top: 1px;
`;

export default withTheme(InputCustomerInformationTemplate);
