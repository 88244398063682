import { observable } from 'mobx';
import moment from 'moment';

import type { Moment } from '@this/src/lib/moment';

import type { ChatbotMessageFormStatus, ChatbotMessageRequired } from './chatbot_message';

type ItemType = 'transport' | 'hotel';
type TimeType = 'arrival' | 'departure';

export type TextToQueryResultItemResponseArgs = {
  item_type?: ItemType;
  origin?: string;
  destination?: string;
  date?: string;
  hour?: string;
  minute?: string;
  timetype?: TimeType;
  checkout_date?: string;
  peoplenum?: number;
};

export type TextToQueryFormAttributesResponseArgs = {
  id: number;
  form_status: ChatbotMessageFormStatus;
  requireds: ChatbotMessageRequired[];
  search_query: TextToQueryResultItemResponseArgs[];
  message: string;
};

export type TextToQueryResultItemArgs = {
  itemType?: ItemType;
  origin?: string;
  destination?: string;
  date?: Moment;
  hour?: number;
  minute?: number;
  timetype?: TimeType;
  checkoutDate?: Moment;
  peoplenum?: number;
};

export const convertTextToQueryResultItemResponseToArgs = (response: TextToQueryResultItemResponseArgs) => {
  const args: TextToQueryResultItemArgs = {
    itemType: response.item_type,
    origin: response.origin,
    destination: response.destination,
    date: response.date ? moment(response.date) : undefined,
    hour: response.hour ? parseInt(response.hour, 10) : undefined,
    minute: response.minute ? parseInt(response.minute, 10) : undefined,
    timetype: response.timetype,
    checkoutDate: response.checkout_date ? moment(response.checkout_date) : undefined,
    peoplenum: response.peoplenum
  };
  return args;
};

export default class TextToQueryResultItem {
  @observable itemType?: ItemType;

  @observable origin?: string;

  @observable destination?: string;

  @observable date?: Moment;

  @observable hour?: number;

  @observable minute?: number;

  @observable timetype?: TimeType;

  @observable checkoutDate?: Moment;

  @observable peoplenum?: number;

  constructor(args: TextToQueryResultItemArgs) {
    this.itemType = args.itemType;
    this.origin = args.origin;
    this.destination = args.destination;
    this.date = args.date;
    this.hour = args.hour;
    this.minute = args.minute;
    this.timetype = args.timetype;
    this.checkoutDate = args.checkoutDate;
    this.peoplenum = args.peoplenum;
  }

  toSelectQuery(index: number) {
    return {
      item_type: this.itemType,
      origin: this.origin,
      destination: this.destination,
      outdate: this.date?.format('YYYY-MM-DD'),
      // hour: this.hour?.toString(),
      // minute: this.minute?.toString(),
      outtype: this.timetype,
      homedate: (this.checkoutDate || this.date)?.format('YYYY-MM-DD'),
      peoplenum: this.peoplenum,
      roomnum: this.peoplenum,
      index
    };
  }
}
