import React from 'react';

interface Props {
  banList: string;
  update: (banList: string) => Promise<any>;
}

interface State {
  form: {
    banList: string;
    touched: boolean;
  };
}

export default class HotelBanListForm extends React.Component<Props, State> {
  state = { form: { banList: this.props.banList, touched: false } };

  handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.target as HTMLTextAreaElement;
    this.setState({ form: { banList: value, touched: true } });
  };

  handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!window.confirm('本当によろしいですか？')) {
      return;
    }

    this.props.update(this.state.form.banList);
    const form = this.state.form;
    form.touched = false;
    this.setState({ form });
  };

  render() {
    const {
      form: { banList, touched }
    } = this.state;

    return (
      <form>
        <textarea rows={10} onChange={this.handleChange} value={banList} />
        <button type="button" onClick={this.handleSubmit} disabled={!touched}>
          更新
        </button>
      </form>
    );
  }
}
