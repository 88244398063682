export interface SearchWeightSettingsArgs {
  id: number;
  foreign_flight_price_weight: number;
  foreign_flight_base_time_weight: number;
  foreign_flight_spent_time_weight: number;
  foreign_flight_carrier_weight: number;
}

class SearchWeightSettings {
  public id: number;

  public foreignFlightPriceWeight: number;

  public foreignFlightBaseTimeWeight: number;

  public foreignFlightSpentTimeWeight: number;

  public foreignFlightCarrierWeight: number;

  constructor(args: SearchWeightSettingsArgs) {
    this.id = args.id;
    this.foreignFlightPriceWeight = args.foreign_flight_price_weight;
    this.foreignFlightBaseTimeWeight = args.foreign_flight_base_time_weight;
    this.foreignFlightSpentTimeWeight = args.foreign_flight_spent_time_weight;
    this.foreignFlightCarrierWeight = args.foreign_flight_carrier_weight;
  }

  submitParams() {
    return {
      id: this.id,
      foreign_flight_price_weight: this.foreignFlightPriceWeight,
      foreign_flight_base_time_weight: this.foreignFlightBaseTimeWeight,
      foreign_flight_spent_time_weight: this.foreignFlightSpentTimeWeight,
      foreign_flight_carrier_weight: this.foreignFlightCarrierWeight
    };
  }

  setforeignFlightPriceWeight(value: number) {
    this.foreignFlightPriceWeight = value;
    app.render();
  }

  setForeignFlightBaseTimeWeight(value: number) {
    this.foreignFlightBaseTimeWeight = value;
    app.render();
  }

  setForeignFlightSpentTimeWeight(value: number) {
    this.foreignFlightSpentTimeWeight = value;
    app.render();
  }

  setforeignFlightCarrierWeight(value: number) {
    this.foreignFlightCarrierWeight = value;
    app.render();
  }
}

export default SearchWeightSettings;
