import React, { useCallback, useMemo, useState } from 'react';
import type { InputProps } from '@this/shared/ui/inputs/input';
import type { RangePickerProps } from 'rc-picker';
import { RangePicker } from 'rc-picker';
import jaJP from 'rc-picker/lib/locale/ja_JP';
import momentConfig from 'rc-picker/lib/generate/moment';
import type { Moment } from 'moment';
import moment from 'moment';
import { disabledDate, usePopupStyle } from '@this/shared/ui/inputs/datepicker/datepicker_base/datepicker.utils';
import { DatepickerPanelWrapper } from '@this/shared/ui/inputs/datepicker/datepicker_base/datepicker_base';
import { IconButton, useFormControl } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useStyle } from './date_range_picker.styles';

export type DateRangePickerValue = [Date | null, Date | null];
export type DateRangePickerPreset = { label: string; value: DateRangePickerValue };
export type DateRangePickerProps = {
  className?: string;
  min?: Date;
  max?: Date;
  presets?: DateRangePickerPreset[];
  placeholder?: [string, string];
  value?: DateRangePickerValue;
  defaultValue?: DateRangePickerValue;
  dateFormat?: string;
  disabled?: boolean;
  disabledClear?: boolean;
  fullWidth?: boolean;
  allowEmpty?: [true, false] | [false, true];
  onChange?: (value: DateRangePickerValue | null) => void;
} & Pick<InputProps, 'size' | 'error'>;

type DateType = Moment;
type RCRangePickerPreset = NonNullable<RangePickerProps<DateType>['presets']>[number];

const DATE_FORMAT = 'YYYY/MM/DD';
const convertValue = ([from, to]: DateRangePickerValue): RCRangePickerPreset['value'] => {
  return [from ? moment(from) : from, to ? moment(to) : to];
};

export const DateRangePicker = ({
  className,
  min,
  max,
  value: rawValue,
  defaultValue: rawDefaultValue,
  presets: rawPresets,
  dateFormat = DATE_FORMAT,
  size,
  error: rawError,
  disabled: rawDisabled,
  disabledClear,
  allowEmpty,
  placeholder = ['年/月/日', '年/月/日'],
  onChange
}: DateRangePickerProps) => {
  const muiFormControl = useFormControl();
  const disabled = muiFormControl?.disabled ?? rawDisabled;
  const error = muiFormControl?.error ?? rawError;

  const styles = useStyle({ size, error, disabled, disabledClear });
  const popupStyle = usePopupStyle();

  const valueProps = useMemo(() => {
    const value = rawValue ? convertValue(rawValue) : null;
    if (value) {
      return { value };
    }

    const defaultValue = rawDefaultValue ? convertValue(rawDefaultValue) : null;
    if (defaultValue) {
      return { defaultValue };
    }

    return null;
  }, [rawDefaultValue, rawValue]);
  const presets = useMemo(
    () =>
      (rawPresets ?? []).map<RCRangePickerPreset>(({ label, value: presetVal }) => ({
        label,
        value: convertValue(presetVal)
      })),
    [rawPresets]
  );

  const [currentValue, setCurrentValue] = useState<RangeValue<DateType> | null>(
    valueProps?.value ?? valueProps?.defaultValue ?? null
  );

  const currentPreset = useMemo(() => {
    if (!currentValue) {
      return undefined;
    }

    return presets.find(({ value: [from, to] }) => {
      const [currentFrom, currentTo] = currentValue;

      return (
        (from === currentFrom || from?.isSame(currentFrom, 'd')) &&
        (to === currentTo || to?.isSame(currentTo, 'd'))
      );
    });
  }, [presets, currentValue]);

  const handleOnChange = useCallback(
    (rangeValue: RangeValue<DateType>) => {
      setCurrentValue(rangeValue);
      const [from, to] = rangeValue ?? [null, null];
      onChange?.(from === null && to === null ? null : [from?.toDate() ?? null, to?.toDate() ?? null]);
    },
    [onChange]
  );

  return (
    <div>
      <RangePicker<DateType>
        className={`${styles.input} ${className}`}
        picker="date"
        locale={jaJP}
        generateConfig={momentConfig}
        presets={presets}
        placeholder={placeholder}
        disabled={disabled}
        popupStyle={popupStyle}
        format={dateFormat}
        allowClear={!disabledClear}
        allowEmpty={allowEmpty}
        disabledDate={date => disabledDate(date, { min, max })}
        suffixIcon={currentPreset?.label}
        clearIcon={<ClearButton />}
        panelRender={originPanel => <DatepickerPanelWrapper className={styles.pickerPanel} panel={originPanel} />}
        onChange={handleOnChange}
        inputReadOnly
        {...valueProps}
      />
    </div>
  );
};

const ClearButton = React.memo(() => (
  <IconButton size="small">
    <Close fontSize="small" />
  </IconButton>
));
