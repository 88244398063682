import React from 'react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import type Project from '@this/domain/project/project';
import type { ApproveStages, WorkflowType } from '@this/domain/project/project';
import User from '@this/domain/user/user';
import { DeleteMasterButton } from '@this/components/organization/delete-master-button';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import type { ProjectFilterSortType } from '@this/components/organization/projects/project_filter';
import { ProjectFilter } from '@this/components/organization/projects/project_filter';
import { ProjectFormModal } from '@this/components/organization/projects/project_form_modal';
import ProjectList from '@this/domain/project/project_list';
import { getColor } from '@this/shared/ui/theme';
import ProjectSharesBlock from './project_shares_block.template';
import {
  OrganizationBody,
  OrganizationTable,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTd,
  OrganizationTdButton,
  AddButton,
  BulkActionLink,
  OrganizationTitle
} from '../organization.style';
import Notification from '../../../notification';

type Props = RouteComponentProps;

interface State {
  projects: any;
  projectShareAvailability: boolean;
  editingProject: any;
  saving: boolean;
  loading: boolean;
  error: string | null;
  mode: string;
  currentPage: number;
  totalPage: number;
  orderType: ProjectFilterSortType | 'CREATED_AT_ASC';
  selectApproveStages: ApproveStages;
  filterFormText: string;
  filterDisabled: boolean;
  showForm: boolean;
  showApproval: boolean;
  useProject: boolean;
}

interface ProjectsResponse {
  projects: any;
  members: any;
  projectShares: any;
  project_share_availability: boolean;
  total_page: number;
  show_approval: boolean;
}

interface ProjectResponse {
  project: any;
}

interface CreateResponse {
  id: number;
}

interface UpdateResponse {}

interface DeleteResponse {}

class Projects extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      projects: new ProjectList([]),
      projectShareAvailability: false,
      editingProject: null,
      saving: false,
      loading: false,
      error: null,
      mode: 'project',
      currentPage: 1,
      totalPage: 0,
      orderType: 'CREATED_AT_ASC',
      selectApproveStages: { default: { 1: [''] } },
      filterFormText: '',
      filterDisabled: false,
      showForm: false,
      showApproval: false,
      useProject: false
    };

    this.handleApproverChange = this.handleApproverChange.bind(this);
    this.handleApproverReset = this.handleApproverReset.bind(this);
    this.handleChangeProjectsRadio = this.handleChangeProjectsRadio.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchProjects();
  }

  fetchProjects = (page = 1, text = '', disabled = false, orderType: ProjectFilterSortType = 'CREATED_AT_ASC') => {
    utils
      .jsonPromise<ProjectsResponse>('/organization/projects.json', {
        page,
        text,
        disabled,
        order_type: orderType
      })
      .then(
        result => {
          const members = _.map(result.members, u => new User(u));
          this.setState({
            projects: new ProjectList(result.projects, members),
            projectShareAvailability: result.project_share_availability,
            loading: false,
            currentPage: page,
            totalPage: result.total_page,
            orderType,
            filterFormText: text,
            filterDisabled: disabled,
            showApproval: result.show_approval
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  };

  handleChangeProjectsRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      mode: e.target.id
    });
  }

  handleApproverChange(i: number, s: number, member: User, workflowType: WorkflowType = 'default') {
    const approveStages = this.state.selectApproveStages;
    approveStages[workflowType][s][i] = member.id;
    this.setApproverIds(approveStages, s, workflowType);
    this.setState({ selectApproveStages: approveStages });
  }

  handleApproverReset(i: number, s: number, workflowType: WorkflowType = 'default') {
    const approveStages = this.state.selectApproveStages;
    approveStages[workflowType][s][i] = '';
    this.setApproverIds(approveStages, s, workflowType);
    this.setState({ selectApproveStages: approveStages });
  }

  setApproverIds(approveStages: any, s: number, workflowType: WorkflowType = 'default') {
    this.state.editingProject?.setApproverIds(
      _.intersection(_.compact(approveStages[workflowType][s])),
      s,
      workflowType
    );
  }

  handleAddClick = () =>
    this.setState({
      showForm: true,
      useProject: false,
      selectApproveStages: { default: { 1: [''] } }
    });

  handleEditClick = (project: any, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ useProject: false });
    utils
      .jsonPromise<ProjectResponse>(`/organization/projects/${project.id}`)
      .then(result => {
        this.setState({
          editingProject: project,
          showForm: true,
          selectApproveStages: _.cloneDeep(project.approveStages),
          useProject: result.project.use_project
        });
      })
      .catch(error => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
          loading: false
        });
        utils.sendErrorObject(error);
      });
  };

  handleFormSubmit = async (newProject: Project) => {
    this.setState({
      saving: true
    });

    try {
      if (newProject.id !== 0) {
        await this.submitUpdate(newProject);
      } else {
        await this.submitCreate(newProject);
      }
      this.fetchProjects();

      this.setState({
        editingProject: null,
        saving: false,
        showForm: false
      });
    } catch (e) {
      Notification.error(
        e.status === 400
          ? utils.dig(e, 'responseJSON', 'errors')
          : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
      );
      utils.sendErrorObject(e);
    }
  };

  submitCreate = async (newProject: Project) => {
    await utils.jsonPromise<CreateResponse>('/organization/projects', newProject.updateParams(), 'POST');
  };

  submitUpdate = async (project: Project) => {
    await utils.jsonPromise<UpdateResponse>(`/organization/projects/${project.id}`, project.updateParams(), 'PUT');
  };

  handleCancelClick = () => {
    this.setState({
      editingProject: null,
      showForm: false
    });
  };

  deleteProject(project: Project) {
    this.setState({
      saving: true
    });

    utils
      .jsonPromise<DeleteResponse>(`/organization/projects/${project.id}`, {}, 'DELETE')
      .then(() => {
        this.fetchProjects();
      })
      .catch(e => {
        Notification.error(
          e.status === 400
            ? utils.dig(e, 'responseJSON', 'errors')
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({
          saving: false
        });
      });
  }

  grayIfNeeded = (disabled: boolean) => {
    return {
      background: disabled ? '#f2f2f3' : '#ffffff'
    };
  };

  headerContents = (showApproval: boolean) => {
    if (showApproval) {
      return (
        <>
          <BulkActionLink to="/organization/projects/csv/bulk_create">一括登録・更新</BulkActionLink>
          <BulkActionLink to="/organization/projects/csv/bulk_update_approvers">
            承認者一括追加・更新
          </BulkActionLink>
        </>
      );
    }
    return <BulkActionLink to="/organization/projects/csv/bulk_create">一括登録・更新</BulkActionLink>;
  };

  render() {
    try {
      const {
        projects,
        projectShareAvailability,
        editingProject,
        saving,
        loading,
        error,
        currentPage,
        totalPage,
        orderType,
        selectApproveStages,
        filterFormText,
        filterDisabled,
        showForm,
        showApproval,
        useProject
      } = this.state;
      return (
        <>
          <OrganizationTitle>プロジェクトマスタ</OrganizationTitle>
          <OrganizationBody>
            {projectShareAvailability && (
              <OrganizationProjectsRadioDiv>
                <OrganizationProjectsRadioInput
                  type="radio"
                  name="mode"
                  id="project"
                  onChange={this.handleChangeProjectsRadio}
                  checked={this.state.mode === 'project'}
                />
                <label htmlFor="project">プロジェクト</label>

                <OrganizationProjectsRadioInput
                  type="radio"
                  name="mode"
                  id="share"
                  onChange={this.handleChangeProjectsRadio}
                  checked={this.state.mode === 'share'}
                />
                <label htmlFor="share">按分</label>
              </OrganizationProjectsRadioDiv>
            )}

            {this.state.mode === 'project' ? (
              <>
                <ProjectFormModal
                  open={showForm}
                  project={editingProject}
                  members={projects.members}
                  approveStages={selectApproveStages}
                  loading={saving}
                  onClose={this.handleCancelClick}
                  onSubmit={this.handleFormSubmit}
                  showApproval={showApproval}
                  useProject={useProject}
                  handleApproverChange={this.handleApproverChange}
                  handleApproverReset={this.handleApproverReset}
                />

                <ProjectFilter
                  onSearch={(text, disabled, orderType) => this.fetchProjects(1, text, disabled, orderType)}
                />
                <MasterListContainer
                  currentPage={currentPage}
                  totalPage={totalPage}
                  onPaginate={page => this.fetchProjects(page, filterFormText, filterDisabled, orderType)}
                  createButton={
                    <AddButton onClick={this.handleAddClick} disabled={!!editingProject}>
                      プロジェクトを追加
                    </AddButton>
                  }
                  headerContent={<BulkActionArea>{this.headerContents(showApproval)}</BulkActionArea>}
                  renderContent={() => {
                    return (
                      <OrganizationTable>
                        <tbody>
                          <tr>
                            <OrganizationTh>プロジェクトコード</OrganizationTh>
                            <OrganizationTh>プロジェクト名</OrganizationTh>
                            {showApproval && <OrganizationTh>承認者</OrganizationTh>}
                            <OrganizationButtonTh>編集</OrganizationButtonTh>
                            <OrganizationButtonTh>削除</OrganizationButtonTh>
                          </tr>
                          {loading ? (
                            <tr>
                              <td colSpan={11}>
                                <SimpleLoading />
                              </td>
                            </tr>
                          ) : error ? (
                            <tr>
                              <td colSpan={11}>
                                <Error>{error}</Error>
                              </td>
                            </tr>
                          ) : (
                            <>
                              {projects.list.map((p: any, i: number) => (
                                <tr style={this.grayIfNeeded(p.disabled)} key={i} data-e2e-id={p.id}>
                                  <OrganizationTd className="e2e-code">{p.code}</OrganizationTd>
                                  <OrganizationTd className="e2e-name">{p.name}</OrganizationTd>
                                  {showApproval && (
                                    <OrganizationTd className="e2e-approver">
                                      {p.approvers.length > 0 && p.approvers[0].email}
                                    </OrganizationTd>
                                  )}
                                  <OrganizationTd>
                                    {!editingProject && (
                                      <OrganizationTdButton onClick={e => this.handleEditClick(p, e)}>
                                        編集
                                      </OrganizationTdButton>
                                    )}
                                  </OrganizationTd>
                                  <OrganizationTd>
                                    <DeleteMasterButton
                                      className="e2e-confirm-delete"
                                      targetLabel={p.name}
                                      onConfirm={() => {
                                        this.deleteProject(p);
                                      }}
                                    />
                                  </OrganizationTd>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </OrganizationTable>
                    );
                  }}
                />
              </>
            ) : (
              <ProjectSharesBlock />
            )}
          </OrganizationBody>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const OrganizationProjectsRadioDiv = styled.div`
  height: 40px;
  padding-top: 10px;
`;

const OrganizationProjectsRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

const BulkActionArea = styled.div`
  ${BulkActionLink} {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
`;

export default Projects;
