import React, { useState } from 'react';
import _ from 'lodash';
import type ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import type FreeeAccountItem from '@this/domain/organization/freee_account_item';
import Modal from '@this/shared/modal/modal';
import Form from './form';

type Props = {
  type: ExpensesAccountType;
  isFreeeConnected: boolean;
  freeeAccountItems: FreeeAccountItem[];
  onSubmit: () => void;
  hideModal: () => void;
};

const NewFormModal: React.FC<Props> = ({ type, isFreeeConnected, freeeAccountItems, onSubmit, hideModal }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  const updateType = async () => {
    setSubmitting(true);
    setSubmitErrors([]);
    try {
      await utils.jsonPromise('/organization/expenses_account_types', type.submitParams(), 'POST');
      setSubmitting(false);
      onSubmit();
    } catch (e) {
      if (e.status === 400) {
        const error = e.responseJSON.error;
        setSubmitErrors(typeof error === 'string' ? [error] : error);
        setSubmitting(false);
      } else {
        setSubmitErrors(['通信エラーが発生しました。時間をおいて再度お試しください。']);
        setSubmitting(false);
        utils.sendErrorObject(e);
      }
    }
  };

  return (
    <Modal show hideModal={hideModal} title="勘定科目を作成する" className="e2e-modal-edit">
      <Form
        type={type}
        isFreeeConnected={isFreeeConnected}
        freeeAccountItems={freeeAccountItems}
        submitting={submitting}
        onSubmit={updateType}
        onClickCancel={hideModal}
        errors={submitErrors}
      />
    </Modal>
  );
};

export default NewFormModal;
