import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

import { styled } from '@this/constants/themes';
import { Button } from '@this/src/components/shared/ui/inputs/button';

interface Props {
  open: boolean;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
}

const defaultTitle = 'フィードバック';
const defaultDescription = '検索結果に対するフィードバックに関するご要望などがあればお知らせください。';

export const FeedbackModal: React.FC<Props> = ({
  open,
  title = defaultTitle,
  description = defaultDescription,
  children,
  onClose,
  onSubmit
}) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ variant: 'outlined' }}>
      <FeedbackContent dividers>
        <FeedbackTitle variant="h5">{title}</FeedbackTitle>
        <FeedbackText>{description}</FeedbackText>
        {children}
      </FeedbackContent>
      <FeedbackActions>
        <Button onClick={onClose} color="sub">
          キャンセル
        </Button>
        <Button onClick={onSubmit} color="primary">
          送信
        </Button>
      </FeedbackActions>
    </Dialog>
  );
};

const FeedbackTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const FeedbackText = styled(Typography)`
  font-size: 16px;
  text-align: center;
  max-width: 370px;
`;

const FeedbackContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FeedbackActions = styled(DialogActions)`
  padding: 16px;
`;
