import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';

export const BulkActionSections = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

export const BulkActionSection = styled.section`
  flex-basis: 49%;
`;

export const BulkActionSectionHeader = styled.div`
  position: relative;
  border-bottom: 1px solid #ddd;

  ${Link} {
    position: absolute;
    top: -3px;
    right: 0;
  }
`;

export const BulkActionSectionContent = styled.div`
  max-height: calc(100vh - 300px);
  overflow-x: scroll;
`;
