import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { SelectType, Airline } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';

interface Props {
  store: SelectStore;
  width: number;
  handleSelectType: (type: SelectType) => () => void;
  handleAirline: (airline: Airline) => () => void;
  serviceId: number;
}

const SelectOutlineWrapperTop: React.SFC<Props> = ({
  store,
  handleSelectType,
  handleAirline,
  width,
  serviceId
}) => (
  <Top width={width}>
    {store.showPackage && store.result.isPackageSupportSearchType() && (
      <>
        <Tabs>
          <Tab selected={store.result.type === 'separate'} onClick={handleSelectType('separate')}>
            個別プラン
            <br />
            <Yen data-wovn-ignore>{utils.digits(store.result.getReservingTrip().separateFinalPrice())}</Yen>
            <Yen>円〜</Yen>
          </Tab>
          <Tab
            selected={
              store.result.type !== 'separate' &&
              store.result.airline === 'ANA' &&
              store.result.type === 'airPackage'
            }
            onClick={handleAirline('ANA')}
          >
            {store.searchProgressAll(serviceId) === false && store.cheapestPricePackage(serviceId) === 'ANA' && (
              <Cheapestprice>最安値プランあり</Cheapestprice>
            )}
            {store.searchProgressAna() ? (
              <>
                ANAパッケージ
                <br />
                <Yen data-wovn-ignore>
                  <div data-wovn-ignore className="cssloader">
                    Loading...
                  </div>
                </Yen>
              </>
            ) : store.result.getAirPackageTrip('ANA').packageFinalPrice() === 0 ? (
              <>
                ANAパッケージ
                <br />
                <Yen>なし</Yen>
              </>
            ) : (
              <>
                ANAパッケージ
                <br />
                <Yen data-wovn-ignore>
                  {utils.digits(store.result.getAirPackageTripCheapest('ANA').packageFinalPrice())}
                </Yen>
                <Yen>円〜</Yen>
              </>
            )}
          </Tab>
          <Tab
            selected={
              store.result.type !== 'separate' &&
              store.result.airline === 'JAL' &&
              store.result.type === 'airPackage'
            }
            onClick={handleAirline('JAL')}
          >
            {store.searchProgressAll(serviceId) === false && store.cheapestPricePackage(serviceId) === 'JAL' && (
              <Cheapestprice>最安値プランあり</Cheapestprice>
            )}
            {store.searchProgressJal() ? (
              <>
                JALパッケージ
                <br />
                <Yen data-wovn-ignore>
                  <div data-wovn-ignore className="cssloader">
                    Loading...
                  </div>
                </Yen>
              </>
            ) : store.result.getAirPackageTrip('JAL').packageFinalPrice() === 0 ? (
              <>
                JALパッケージ
                <br />
                <Yen>なし</Yen>
              </>
            ) : (
              <>
                JALパッケージ
                <br />
                <Yen data-wovn-ignore>
                  {utils.digits(store.result.getAirPackageTripCheapest('JAL').packageFinalPrice())}
                </Yen>
                <Yen>円〜</Yen>
              </>
            )}
          </Tab>
          {utils.isMynavi(serviceId) === false && (
            <Tab
              selected={store.result.type !== 'separate' && store.result.type === 'jrPackage'}
              onClick={handleSelectType('jrPackage')}
            >
              {store.searchProgressAll(serviceId) === false && store.cheapestPricePackage(serviceId) === 'JR' && (
                <Cheapestprice>最安値プランあり</Cheapestprice>
              )}
              {store.searchProgressJr() ? (
                <>
                  JRパッケージ
                  <br />
                  <Yen data-wovn-ignore>
                    <div data-wovn-ignore className="cssloader">
                      Loading...
                    </div>
                  </Yen>
                </>
              ) : store.result.getJrPackageTrip().packageFinalPrice() === 0 ? (
                <>
                  JRパッケージ
                  <br />
                  <Yen>なし</Yen>
                </>
              ) : (
                <>
                  JRパッケージ
                  <br />
                  <Yen data-wovn-ignore>
                    {utils.digits(store.result.getJrPackageTripCheapest().packageFinalPrice())}
                  </Yen>
                  <Yen>円〜</Yen>
                </>
              )}
            </Tab>
          )}
        </Tabs>
        {store.isEndFlashMsg() === false && (
          <LoadingTab>
            {store.searchProgressAll(serviceId) ? (
              <>
                最適なパッケージを検索してます{' '}
                <LoadingSpan>
                  <div data-wovn-ignore className="cssloader">
                    Loading...
                  </div>
                </LoadingSpan>
              </>
            ) : (
              <>{store.searchPackageResultText(serviceId)}</>
            )}
          </LoadingTab>
        )}
      </>
    )}
  </Top>
);

const Top = styled.div<{ width: number }>`
  ${props => `width: ${props.width}%`}
  padding: 0px 10px 0px 10px;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div<{ selected: boolean }>`
  border: 1px solid #e5e7eb;
  text-align: center;
  font-size: 11px;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 16px 8px;
  line-height: 110%;
  font-weight: normal;
  cursor: pointer;
  color: ${props => props.theme.textColor};
  margin-top: -1px;
  margin-left: -1px;
  width: calc(25% + 1px);
  position: relative;

  ${props =>
    props.selected &&
    `
    border-color: #E5E7EB;
    background-color: #8F7B4A;
    cursor: default;
    color: white;
  `};
`;

const Yen = styled.span`
  font-size: 13px;
  font-weight: bold;
  line-height: 125%;
`;

const LoadingTab = styled.div`
  display: flex
  justify-content: center;
  align-items: center;
  background-color: #F8F6F1;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold;
  line-height: 110%;
  color: ${props => props.theme.textColor};
  padding: 20px 20px;
  width: 100%;
  margin-top: 5px;
`;

const LoadingSpan = styled.span`
  width: 20%;
  top: -0.5em;
`;

const Cheapestprice = styled.div`
  border: 1px solid #ff0000;
  background-color: #ffffff;
  color: #ff0000;
  font-size: 8px;
  padding: 3px;
  border-radius: 10px;
  top: -10px;
  left: 15%;
  position: absolute;
  z-index: 10;
`;

export default SelectOutlineWrapperTop;
