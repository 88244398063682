import React from 'react';

interface Props {
  items: Item[];
  headerLabel: string;
  selectedValues: string[];
  defaultSeatClasses?: string[];
  selectableAll: boolean;
  onChange: (v: string[]) => void;
  onSelectAll: () => void;
}

interface State {
  selectedValues: string[];
  isShow: boolean;
}

interface Item {
  label: string;
  value: string;
}
export default class PopupListMultiSelector extends React.Component<Props, State> {
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = { selectedValues: this.props.selectedValues, isShow: false };
    this.ref = React.createRef();
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.selectedValues.join() !== prevState.selectedValues.join()) {
      return { ...prevState, selectedValues: nextProps.selectedValues };
    }

    return null;
  }

  getCurrentLabel(): string {
    const selectedCount = this.state.selectedValues.length;
    if (selectedCount === 0) {
      return this.props.headerLabel;
    }

    if (this.selectedAll) {
      return this.selectAllLabel;
    }

    const item = this.props.items.find(i => i.value === this.state.selectedValues[0]);
    if (item) {
      return selectedCount === 1 ? item.label : `${item.label} + ${selectedCount - 1}`;
    }
    return this.props.headerLabel;
  }

  toggleShowSelector() {
    this.setState({ isShow: !this.state.isShow }, () =>
      this.state.isShow ? this.addListener() : this.removeListener()
    );
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    let selectedValues = this.state.selectedValues;
    const i = selectedValues.findIndex(v => v === value);

    if (i >= 0) {
      selectedValues.splice(i, 1);
    } else if (this.selectedAll) {
      selectedValues = [value];
    } else {
      selectedValues.push(value);
    }

    this.setState({ selectedValues });
    this.props.onChange(selectedValues);
  }

  handleCheckAll() {
    if (this.selectedAll) {
      this.setState({ selectedValues: [] });
    } else {
      this.setState({ selectedValues: ['all'] });
    }
    this.props.onSelectAll();
  }

  get selectAllLabel() {
    return `すべての${this.props.headerLabel}`;
  }

  get selectedAll(): boolean {
    return this.state.selectedValues[0] === 'all';
  }

  onClick = (e: MouseEvent) => {
    const currentNode = this.ref.current;

    if (currentNode && currentNode.contains(e.target as Node)) {
      return;
    }

    this.setState({ isShow: false });
  };

  private removeListener() {
    document.removeEventListener('click', this.onClick);
  }

  private addListener() {
    document.addEventListener('click', this.onClick);
  }

  render() {
    const classBase = 'popup-list-selector';
    const buttonHiddenclassBase = `popup-list-selector${this.selectedAll ? '' : '__select'}`;
    const { isShow, selectedValues } = this.state;
    return (
      <div className={classBase} ref={this.ref}>
        {isShow ? (
          <>
            <div className={`${classBase}__button-show`} onClick={() => this.toggleShowSelector()}>
              {this.getCurrentLabel()}
            </div>
            <div className={`${classBase}__popup`}>
              <div className={`${classBase}__popup__header`}>
                {this.props.headerLabel}
                <div
                  className={`${classBase}__popup__header__close-button`}
                  onClick={() => this.toggleShowSelector()}
                >
                  ☓
                </div>
              </div>
              <div className={`${classBase}__popup__body`}>
                <ul>
                  {!this.props.selectableAll || (
                    <li>
                      <label
                        className={`${classBase}__popup__body__label ${classBase}__popup__body__label--select-all`}
                      >
                        <input
                          type="checkbox"
                          value="all"
                          onChange={() => this.handleCheckAll()}
                          checked={this.selectedAll}
                        />
                        {this.selectAllLabel}
                      </label>
                    </li>
                  )}
                  {this.props.items.map(item => (
                    <li key={item.value}>
                      <label className={`${classBase}__popup__body__label`}>
                        <input
                          type="checkbox"
                          value={item.value}
                          onChange={e => this.handleChange(e)}
                          checked={selectedValues.some(v => v === item.value)}
                          disabled={
                            this.props?.defaultSeatClasses && this.props.defaultSeatClasses.length > 0
                              ? this.props.defaultSeatClasses.every(v => v !== item.value)
                              : false
                          }
                        />
                        {item.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className={`${buttonHiddenclassBase}__button-hidden`} onClick={() => this.toggleShowSelector()}>
            {this.getCurrentLabel()}
          </div>
        )}
      </div>
    );
  }
}
