import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import ContentBody from '@this/components/shared/atoms/content_body';
import Link from '@this/components/shared/atoms/link';
import Tooltip from '@this/shared/tooltip/tooltip';

import AlertArea from '@this/components/reserve_trip/select/alert_area/alert_area';
import { getColor } from '@this/shared/ui/theme';
import type SearchQuery from '../../../domain/search_query';
import type SearchQueryItem from '../../../domain/search_query_item';
import type SelectRepository from '../../../domain/select_repository';
import type { SelectType, Airline } from '../../../domain/select_store';
import type SelectStore from '../../../domain/select_store';
import type User from '../../../domain/user/user';

import SelectDefaultArea from './default_area/default_area';
import SelectListAreaShortdistance from './list_area/list_area_shortdistance';
import SelectSearchArea from './search_area/search_area';
import SelectOutlineWrapperShortdistance from './outline_wrapper/outline_wrapper_shortdistance.template';
import SelectOutlineWrapperShortdistanceTop from './outline_wrapper/outline_wrapper_shortdistance.top';

import ArrangementRequestForm from './arrangement_request_form/arrangement_request_form';

interface Props {
  // Select.props
  query: SearchQuery;
  user: User | null;
  availableOptions: string[];
  store: SelectStore;
  repository: SelectRepository;
  selectErrors: string[];
  submitting: boolean;
  showArrangementRequestForm: boolean;
  serviceId: number;
  showSearchBox: boolean;

  // Select.methods
  handleSelectType: (type: SelectType) => () => void;
  handleAirline: (airline: Airline) => () => void;
  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleReSearch: (query: SearchQuery) => void;

  handleSelectItem: () => void;

  handleAddItem: (item_type: 'transport' | 'hotel', e: React.MouseEvent<HTMLElement>) => void;
  handleAdditionalSearch: (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => void;
  additionalSearchErrors?: { [k in string]: string };

  handleToggleArrangementRequestForm: () => void;
  toggleShowSearchBox: () => void;
  handleOpenShowSearchBox: () => void;
}

const SelectSpShortdistanceTemplate: React.SFC<Props> = ({
  query,
  user,
  availableOptions,
  store,
  repository,
  selectErrors,
  submitting,
  showArrangementRequestForm,
  serviceId,
  showSearchBox,

  handleSelectType,
  handleAirline,
  handleNextButtonClick,

  handleSelectItem,
  handleReSearch,

  handleAddItem,
  handleAdditionalSearch,
  additionalSearchErrors,

  handleToggleArrangementRequestForm,
  toggleShowSearchBox,
  handleOpenShowSearchBox
}) => (
  <div className="select-sp">
    {showArrangementRequestForm
      ? [
          <BackHeader key={0}>
            <BackHeaderA onClick={handleToggleArrangementRequestForm}>{'< 戻る'}</BackHeaderA>
          </BackHeader>,
          <Body key={1}>
            <ArrangementRequestForm
              serviceId={serviceId}
              query={query}
              store={store}
              selectErrors={selectErrors}
              handleSelectItem={handleSelectItem}
              handleAdditionalSearch={handleAdditionalSearch}
              additionalSearchErrors={additionalSearchErrors}
              handleNextButtonClick={handleNextButtonClick}
              handleOpenShowSearchBox={handleOpenShowSearchBox}
              reserveRequestMailNotificationType={
                store.user?.organization?.reserve_request_mail_notification_type ?? 'togglable'
              }
              shortdistance
            />
          </Body>
        ]
      : [
          <>
            {store.currentTab === 'none' && (
              <Body key={1}>
                <SelectSearchArea
                  query={query}
                  user={user}
                  availableOptions={availableOptions}
                  trip={store.result.getReservingTrip()}
                  selectErrors={selectErrors}
                  submitting={submitting}
                  store={store}
                  handleNextButtonClick={handleNextButtonClick}
                  handleReSearch={handleReSearch}
                />
                <AlertArea result={store.result} />
                <div style={{ paddingTop: '20px' }}>
                  <SelectOutlineWrapperShortdistanceTop
                    store={store}
                    handleSelectType={handleSelectType}
                    handleAirline={handleAirline}
                    width={100}
                    serviceId={serviceId}
                  />
                </div>
                <SelectOutlineWrapperShortdistance
                  query={query}
                  store={store}
                  selectErrors={selectErrors}
                  packageErrors={store.packageErrors[store.result.airline]}
                  handleSelectItem={handleSelectItem}
                  handleAddItem={handleAddItem}
                  handleAdditionalSearch={handleAdditionalSearch}
                  additionalSearchErrors={additionalSearchErrors}
                  handleNextButtonClick={handleNextButtonClick}
                  submitting={submitting}
                  handleOpenShowSearchBox={handleOpenShowSearchBox}
                />
                {utils.dig(store, 'user', 'organization', 'arrangement_request_enable?') ? (
                  <RequestText>
                    <p>
                      お探しの結果が見つからない場合は
                      <AText onClick={handleToggleArrangementRequestForm}>リクエストフォーム</AText>
                      からご依頼ください
                    </p>
                    <Tooltip>
                      <p>
                        ご希望の検索結果が出てこない場合は、こちらのフォームからご依頼ください
                        <br />
                        <span data-wovn-ignore>{`（手数料: ${utils.dig(
                          store,
                          'user',
                          'organization',
                          'arrangement_request_setting',
                          'fee'
                        )}）`}</span>
                        円
                      </p>
                    </Tooltip>
                  </RequestText>
                ) : null}
                <SelectDefaultArea isOrganization show={store.currentTab === 'none'} serviceId={serviceId} sp />
              </Body>
            )}
            {/* SelectListAreaShortdistance は hidden 状態でレンダリングされるようにしておく */}
            <SearchShortDistanceArea visibility={store.currentTab !== 'none'}>
              <BackHeader key={0}>
                <BackHeaderA onClick={() => store.handleClickTab('none')}>{'< 戻る'}</BackHeaderA>
              </BackHeader>
              <Body key={1}>
                {store.result.items.map((item, i) => (
                  <SelectListAreaShortdistance
                    key={item.index}
                    icon={String(i + 1)}
                    repository={repository}
                    store={store}
                    hotelPriceLimit={item.hotelPriceLimit}
                    listWrapper={item.elementList}
                    type={item.elementType()}
                    subtype={item.flightIndex}
                    show={store.result.type === 'separate' && store.currentTab === item.index}
                    queryItem={query.items[i]}
                    resultItem={item}
                    query={query}
                    serviceId={serviceId}
                    showSearchBox={showSearchBox}
                    toggleShowSearchBox={toggleShowSearchBox}
                  />
                ))}
                {store.result.packageItems[store.result.airline].map((item, i) => (
                  <SelectListAreaShortdistance
                    key={item.index}
                    hotelPriceLimit={item.hotelPriceLimit}
                    icon={String(i + 1)}
                    repository={repository}
                    store={store}
                    listWrapper={item.elementList}
                    type={item.elementType()}
                    subtype={item.flightIndex}
                    queryItem={query.items[i]}
                    resultItem={item}
                    query={query}
                    serviceId={serviceId}
                    show={store.result.type === 'airPackage' && store.currentTab === item.index}
                    direction={i === 0 ? 'out' : i === 2 ? 'home' : undefined}
                    showSearchBox={showSearchBox}
                    toggleShowSearchBox={toggleShowSearchBox}
                  />
                ))}
              </Body>
            </SearchShortDistanceArea>
          </>
        ]}
  </div>
);

const BackHeader = styled.div`
  background: ${props => props.theme.backHeaderBgColor};
  padding: 10px;
`;

const BackHeaderA = styled.a`
  &,
  &:hover,
  &:focus {
    color: ${getColor('brand', 'primary')};
  }
`;

const Body = styled(ContentBody)`
  padding: 0;
`;

const RequestText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -90px;
  padding-left: 22px;

  .tooltip__content {
    right: -60px;
  }
`;

const AText = styled.a`
  border-bottom: 1px solid;
  ${Link}
`;

const SearchShortDistanceArea = styled.div<{ visibility: boolean }>`
  visibility: ${props => (props.visibility ? 'visible' : 'hidden')};
  height: ${props => (props.visibility ? 'auto' : 0)};
`;

export default SelectSpShortdistanceTemplate;
