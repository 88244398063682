import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { setupMixpanel } from '@this/lib/mixpanel_utils';
import { setupBugsnag } from '@this/lib/bugsnag';
import SimpleLoading from '../shared/simple_loading/simple_loading';
import ExpensesHeader from './header/header';
import ExpensesFooter from './footer/footer';
import ExpensesUsersSignup from './users/sign_up';
import Confirmation from './users/confirmation';
import ExpensesUsersInvitationEdit from './users/invitation_edit';
import SignIn from './users/sign_in';
import ResetPassword from './users/reset_password';
import ExpensesReports from './reports/reports';
import ExpensesReportDetail from './reports/detail';
import ExpensesReportForm from './reports/form';
import ExpensesReportItems from './report_items/report_items';
import ExpensesReportItemNew from './report_items/new';
import ExpensesReportItemEdit from './report_items/edit';
import ExpensesOrganization from './organization/organization';
import ExpensesApprovals from './approvals/approvals';
import ExpensesApprovalDetail from './approvals/detail';
import ExpensesApprovalItemEdit from './approval_items/edit';
import OrganizationInvitation from './organization_invitation/organizatoin_invitation';
import ZenginData from './approvals/zengin_data';
import User from '../../domain/user/user';
import type UserJson from '../../domain/user/user_json';

interface Props extends RouteComponentProps {
  env: string;
}

interface State {
  loadingUser: boolean;
  user: User | null | undefined;
}

class Expenses extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: undefined,
      loadingUser: true
    };
  }

  componentDidMount() {
    const distinctId = utils.getParam('distinct_id');
    if (!_.isEmpty(distinctId)) {
      mixpanel.identify(distinctId);
      const newUrl = location.href.replace(/\?distinct_id=.*/, '');
      window.history.pushState(null, '', newUrl);
    }
    this.fetchUser();
  }

  private async fetchUser() {
    try {
      this.setState({ loadingUser: true });
      const response = await utils.jsonPromise<UserJson>('/users');
      const user = new User(response);

      this.setState({ user, loadingUser: false });
      setupMixpanel(user);
      setupBugsnag(user);
    } catch (e) {
      this.setState({ user: null, loadingUser: false });
      mixpanel.identify(mixpanel.get_distinct_id());
      mixpanel.people.set_once({ name: 'anonymous' });
      if (e.status !== 404) {
        utils.sendErrorObject(e);
      }
    }
  }

  handleSignedIn(user: User) {
    this.setState({ user });
    setupMixpanel(user);
  }

  render() {
    try {
      const props = this.props;
      const path = props.match.path;
      const { loadingUser, user } = this.state;
      return (
        <Wrapper>
          <ExpensesHeader user={user} />
          <Main>
            {loadingUser ? (
              <SimpleLoading />
            ) : (
              <Switch>
                <Route
                  exact
                  path={`${path}/users/sign_up`}
                  render={routeProps => <ExpensesUsersSignup user={user} {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/users/confirmation`}
                  render={routeProps => <Confirmation user={user} {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/users/invitation/accept`}
                  render={routeProps => {
                    return <ExpensesUsersInvitationEdit {..._.merge(routeProps, props)} />;
                  }}
                />
                <Route
                  exact
                  path={`${path}/users/sign_in`}
                  render={routeProps => (
                    <SignIn
                      user={user}
                      onSignedIn={user => this.handleSignedIn(user)}
                      {..._.merge(routeProps, props)}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${path}/users/password/edit`}
                  render={routeProps => {
                    const params = new URLSearchParams(this.props.location.search);
                    const token = params.get('reset_password_token');
                    return <ResetPassword token={token!} {..._.merge(routeProps, props)} />;
                  }}
                />
                <Route
                  exact
                  path={`${path}/reports`}
                  render={routeProps => <ExpensesReports {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/reports/new`}
                  render={routeProps => (
                    <ExpensesReportForm {..._.merge(routeProps, props, { action: 'new' } as const)} />
                  )}
                />
                <Route
                  exact
                  path={`${path}/reports/:id`}
                  render={routeProps => <ExpensesReportDetail {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/reports/:id/edit`}
                  render={routeProps => (
                    <ExpensesReportForm {..._.merge(routeProps, props, { action: 'edit' } as const)} />
                  )}
                />
                <Route
                  exact
                  path={`${path}/report_items`}
                  render={routeProps => <ExpensesReportItems {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/report_items/new`}
                  render={routeProps => <ExpensesReportItemNew {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/report_items/:id/edit`}
                  render={routeProps => <ExpensesReportItemEdit {..._.merge(routeProps, props)} />}
                />
                <Route
                  path={`${path}/organization`}
                  render={routeProps => <ExpensesOrganization {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/approvals`}
                  render={routeProps => <ExpensesApprovals {..._.merge(_.merge(routeProps, props), { user })} />}
                />
                <Route
                  exact
                  path={`${path}/approvals/:id`}
                  render={routeProps => (
                    <ExpensesApprovalDetail {..._.merge(_.merge(routeProps, props), { user })} />
                  )}
                />
                <Route
                  exact
                  path={`${path}/approvals/:approval_id/approval_items/:id/edit`}
                  render={routeProps => <ExpensesApprovalItemEdit {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/approvals/zengin_data/new`}
                  render={routeProps => <ZenginData {..._.merge(routeProps, props)} />}
                />
                <Route
                  exact
                  path={`${path}/organization_invitations/:id`}
                  render={routeProps => <OrganizationInvitation {..._.merge(routeProps, props)} />}
                />
              </Switch>
            )}
          </Main>
          <ExpensesFooter user={user} />
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const mainBgColor = `#faf9f8`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex-grow: 9999;
  width: 100%;
  background: ${mainBgColor};
  display: flex;
  flex-direction: column;
`;

export default Expenses;
