import type { ChangeEvent } from 'react';
import React, { useState, useCallback } from 'react';
import { styled } from '@this/constants/themes';
import TextareaAutosize from 'react-textarea-autosize';
import { ButtonBase } from '@this/shared/atoms/button';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';

interface Props {
  handleSubmit: (messageBody: string, files: File[], e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface KeyWithFile {
  [key: number]: File | null;
}

const TripChatForm: React.FC<Props> = ({ handleSubmit }) => {
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<KeyWithFile>({});
  const [error, setError] = useState<string | null>(null);

  const onFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, fileKey: number) => {
      setError(null);
      const maxFileSize = 5242880; // 5MB
      const acceptExtension = ['jpeg', 'png', 'pdf', 'jpg'];
      const file = e.target.files ? e.target.files[0] : null;
      if (!file) return;
      const fileSize = file.size;
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileSize > maxFileSize) {
        setError('1つあたりのファイルサイズは5MB以下にしてください。');
        e.target.value = '';
        return;
      }
      if (fileExtension && !acceptExtension.includes(fileExtension)) {
        setError('ファイルのタイプはpng、jpeg、pdfで登録してください。');
        e.target.value = '';
        return;
      }

      if (Object.keys(files).includes(String(fileKey))) {
        setFiles(prev => ({ ...prev, [fileKey]: file }));
      }
    },
    [files]
  );

  const onClickAddFile = useCallback(() => {
    const fileLength = Object.keys(files).length;

    if (fileLength >= 5) {
      setError('一度に添付できるのは5ファイルまでです。');
      return;
    }

    let key = 0;
    if (fileLength > 0) {
      key = Math.max(...Object.keys(files).map(k => Number(k))) + 1;
    } else {
      key = Math.floor(Math.random() * 1000);
    }
    setFiles(prev => ({ ...prev, [key]: null }));
  }, [files]);

  const onClickRemoveFile = useCallback(
    (fileKey: number) => {
      if (Object.keys(files).length <= 4) setError(null);
      setFiles(prevFiles => {
        const newFiles = { ...prevFiles };
        if (Object.keys(newFiles).includes(String(fileKey))) delete newFiles[fileKey];
        return newFiles;
      });
    },
    [files]
  );

  return (
    <Form>
      <Wrap>
        <InputWrap>
          <Textarea
            name="message"
            value={value}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)}
            placeholder="手配(内容で気になることをコンシェルジュに聞くことができます"
          />
          <LinkButton onClick={onClickAddFile}>
            <AttachFileIcon fontSize="small" />
            ファイルを添付する
          </LinkButton>
          {Object.keys(files).map(key => (
            <FileInputWrap key={key}>
              <FileInput
                type="file"
                name="files[]"
                accept="image/jpeg,image/png,application/pdf"
                onChange={e => onFileInputChange(e, Number(key))}
              />
              <RemoveIcon fontSize="small" onClick={() => onClickRemoveFile(Number(key))} />
            </FileInputWrap>
          ))}
        </InputWrap>
        <SubmitButton
          type="submit"
          onClick={e => {
            const postFiles = Object.values(files).filter(f => f);
            handleSubmit(value, postFiles, e);
            setValue('');
            setFiles({});
          }}
          disabled={!value}
        >
          送信
        </SubmitButton>
      </Wrap>
      {error && <Error>{error}</Error>}
    </Form>
  );
};

const Form = styled.form`
  margin-bottom: 20px;
`;

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InputWrap = styled.div`
  width: 100%;
`;

const Textarea = styled(TextareaAutosize)`
  margin-bottom: 0 !important;
`;

const SubmitButton = styled.button`
  ${ButtonBase};
  width: 60px;
  margin-left: 10px;
  padding: 5px;
  height: 32px;
`;

const LinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.grayTextColor};
`;

const FileInput = styled.input`
  display: block;
  width: 35% !important;
  padding-bottom: 0 !important;
  @media (max-width: 1024px) {
    width: 100% !important;
  }
`;

const FileInputWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const RemoveIcon = styled(CancelIcon)`
  cursor: pointer;
  margin-left: 5px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

export default TripChatForm;
