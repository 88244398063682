export type SmartHrImportDataChangeAttrResponseArgs = {
  before: string;
  after: string;
  changed: boolean;
};

export type SmartHrImportDataChangeAttrArgs = {
  before: string;
  after: string;
  changed: boolean;
};

export const convertSmartHrImportDataChangeAttrResponseToArgs = (
  response: SmartHrImportDataChangeAttrResponseArgs
) => {
  const args: SmartHrImportDataChangeAttrArgs = {
    before: response.before,
    after: response.after,
    changed: response.changed
  };
  return args;
};

export default class SmartHrImportDataChangeAttr {
  public readonly before: string;

  public readonly after: string;

  public readonly changed: boolean;

  constructor(args: SmartHrImportDataChangeAttrArgs) {
    this.before = args.before;
    this.after = args.after;
    this.changed = args.changed;
  }
}
