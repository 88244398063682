interface TeikiJson {
  id: number;
  from: string;
  to: string;
  ekispert_sort: EkispertSort;
  data?: string;
}

export type EkispertSort = 'price' | 'time' | 'transfer';

export class Teiki {
  readonly id?: number;

  from: string;

  to: string;

  ekispertSort: EkispertSort;

  readonly data?: string;

  destroy?: string;

  constructor(args?: TeikiJson) {
    if (args) {
      this.id = args.id;
      this.from = args.from;
      this.to = args.to;
      this.ekispertSort = args.ekispert_sort;
      if (args.data) this.data = args.data;
    } else {
      this.from = '';
      this.to = '';
      this.ekispertSort = 'price';
    }
  }

  setFrom(value: string) {
    this.from = value;
    app.render();
  }

  setTo(value: string) {
    this.to = value;
    app.render();
  }

  setEkispertSort(value: EkispertSort) {
    this.ekispertSort = value;
    app.render();
  }

  remove() {
    this.destroy = '1';
    app.render();
  }

  isRemoved(): boolean {
    return this.destroy === '1';
  }

  params() {
    return {
      id: this.id,
      from: this.from,
      to: this.to,
      ekispert_sort: this.ekispertSort,
      _destroy: this.destroy
    };
  }
}
