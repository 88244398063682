import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Fetcher } from '@this/src/util';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { Link } from '@this/components/shared/ui/navigations/link';
import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
import { InvoiceCustomFormatTitle } from '@this/components/organization/invoice_custom_formats/invoice_custom_formats.style';
import InvoiceCustomFormatForm from '@this/components/organization/invoice_custom_formats/invoice_custom_format_form';
import InvoiceCustomFormat from '@this/domain/invoice_custom_format/invoice_custom_format';
import InvoiceStandardTable from '@this/src/domain/invoice_custom_format/invoice_standard_table';
import type { InvoiceStandardTableArgs } from '@this/src/domain/invoice_custom_format/invoice_standard_table';

interface Response {
  invoice_standard_tables: InvoiceStandardTableArgs[];
}
const InvoiceCustomFormatNew = observer(() => {
  const [loading, setLoading] = useState(false);
  const [invoiceCustomFormat] = useState(new InvoiceCustomFormat({ name: '', disabled: false }));
  const [invoiceStandardTables, setInvoiceStandardTables] = useState<InvoiceStandardTable[]>([]);

  const fetch = async () => {
    setLoading(true);
    const response = await Fetcher.get<Response>('/organization/invoice_custom_formats/new.json');
    setInvoiceStandardTables(response.invoice_standard_tables.map(t => new InvoiceStandardTable(t)));
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleCreate = () => {
    if (invoiceCustomFormat === null) return;
    setLoading(true);
    Fetcher.post(`/organization/invoice_custom_formats.json`, invoiceCustomFormat.submitParams())
      .then(() => {
        window.location.href = '/organization/invoice_custom_formats';
      })
      .catch(e => {
        if (e.status === 400) {
          alert(e.responseJSON.errors);
        } else {
          alert('エラーが発生しました。');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <OrganizationTitle>請求書・明細</OrganizationTitle>
      <OrganizationBody>
        <Link href="/organization/invoice_custom_formats">&lt; 戻る</Link>
        <InvoiceCustomFormatTitle>カスタムCSV新規作成</InvoiceCustomFormatTitle>
        {loading ? (
          <Loading />
        ) : (
          <InvoiceCustomFormatForm
            invoiceCustomFormat={invoiceCustomFormat}
            invoiceStandardTables={invoiceStandardTables}
            onSubmit={handleCreate}
          />
        )}
      </OrganizationBody>
    </>
  );
});

export default InvoiceCustomFormatNew;
