import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import {
  OrganizationBody,
  OrganizationTitle,
  OrganizationNavBar
} from '@this/components/organization/organization.style';
import DownloadFormat from '@this/components/organization/csv_bulk_action/download_format';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { DownloadType, Encoding } from '@this/components/organization/csv_bulk_action/types';
import { styled } from '@this/constants/themes';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  BulkActionSection,
  BulkActionSectionContent,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import FileFormat from '@this/components/organization/file_format';
import FileValidator from '@this/components/organization/csv_bulk_action/file_validator';
import CsvBulkUpsertForm from '@this/components/organization/approve_items/approve_item_lists/csv_bulk_upsert/csv_bulk_upsert_form';
import type { BulkActionAttr } from '@this/components/organization/types';
import { BulkActionAttrServiceType } from '@this/components/organization/types';
import { Fetcher, HTTPError } from '@this/src/util';

const UPSERT_FORMATS: BulkActionAttr[] = [
  {
    name: 'code',
    summary: '項目コード',
    example: '001',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'name',
    summary: '項目名',
    example: '項目その１',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'dep_codes',
    summary: '利用可能部署コード',
    example: '部署コード1/部署コード2/部署コード3/...',
    required: false,
    serviceType: BulkActionAttrServiceType.ALL
  }
];

type Props = RouteComponentProps<{ approveItemId: string }>;

interface State {
  loading: boolean;
  validateResult?: FileValidatorResult;
  uploadResult?: { uploaded: boolean; errors?: string[] };
  message: string | null;
}

class CsvBulkUpsert extends React.Component<Props, State> {
  historyPath = `/organization/approve_items/${this.props.match.params.approveItemId}/approve_item_lists/approve_item_list_csv_import_actions`;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      message: null
    };
  }

  downloadFormat = (type: DownloadType) => {
    const downloadFormatPath = `/organization/approve_items/${this.props.match.params.approveItemId}/approve_item_lists/bulk_upsert/new`;
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `${downloadFormatPath}?encoding=${encoding}&data=true`;
    } else {
      location.href = `${downloadFormatPath}?encoding=${type}`;
    }
  };

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload(
        `/organization/approve_items/${this.props.match.params.approveItemId}/approve_item_lists/bulk_upsert`,
        formData
      );
      this.setState({ uploadResult: { uploaded: true }, message: 'アップロードしました' });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };

  handleCloseSnackbar = () => {
    this.setState({ message: null });
  };

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }

  render() {
    const { loading, validateResult, uploadResult, message } = this.state;
    const backPath = `/organization/approve_items/${this.props.match.params.approveItemId}/approve_item_lists`;
    return (
      <>
        <Snackbar
          open={!!message}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MuiAlert onClose={this.handleCloseSnackbar} severity="success">
            {message && message.split('\n').map((line, i) => <div key={i}>{line}</div>)}
          </MuiAlert>
        </Snackbar>
        <OrganizationNavBar>
          <Link href={backPath} style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeft />
            リスト申請項目一覧に戻る
          </Link>
        </OrganizationNavBar>
        <OrganizationTitle>リスト申請項目一括登録・更新</OrganizationTitle>
        <OrganizationBody>
          <Description>
            <p>
              CSVファイルをアップロードすることで、リスト申請項目を一括登録・更新することができます。
              <br />
              適用費用負担部署を指定しない申請項目は、すべての部署で利用可能な項目として扱います。
              <br />
              既存リスト申請項目を編集したい場合はプルダウンメニューより「登録データ」を選択の上、ダウンロードして編集後、アップロードしてください。
            </p>
          </Description>
          <DownloadFormat downloadFormat={this.downloadFormat} dataEnabled />
          <BulkActionSections>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-between' }}>
                  <h3>アップロード</h3>
                  <Link href={this.historyPath}>アップロード履歴</Link>
                </div>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <CsvBulkUpsertForm
                  loading={loading}
                  historyPath={this.historyPath}
                  backPath={backPath}
                  validateResult={validateResult}
                  uploadResult={uploadResult}
                  dropZoneStyle={{ height: `calc(100vh - 532px)` }}
                  select={this.checkFile}
                  upload={this.upload}
                />
              </BulkActionSectionContent>
            </BulkActionSection>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>フォーマット</h3>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <FileFormat formats={UPSERT_FORMATS} />
              </BulkActionSectionContent>
            </BulkActionSection>
          </BulkActionSections>
        </OrganizationBody>
      </>
    );
  }
}

const Description = styled.div`
  p {
    margin-bottom: 5px;
  }
`;

export default CsvBulkUpsert;
