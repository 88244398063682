import React from 'react';
import type { ChangeEvent } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel, makeStyles } from '@material-ui/core';
import type { FormControlLabelProps as MuiFormControlLabelProps } from '@material-ui/core';

const useCheckboxGroupStyle = makeStyles(theme => {
  return {
    root: ({ layout }: { layout: CheckboxGroupProps['layout'] }) =>
      layout === 'horizontal'
        ? {
            display: 'flex',
            flexDirection: 'row',
            '& .MuiFormControlLabel-root:not(:first-child)': { marginLeft: theme.spacing(1) }
          }
        : {
            display: 'flex',
            flexDirection: 'column',
            '& .MuiFormControlLabel-root:not(:first-child)': { marginTop: theme.spacing(1) }
          }
  };
});

export type CheckboxGroupProps = {
  className?: string;
  layout?: 'vertical' | 'horizontal';
  children: React.ReactNode;
};
export const CheckboxGroup = ({ layout = 'vertical', className, children }: CheckboxGroupProps) => {
  const styles = useCheckboxGroupStyle({ layout });
  return <div className={`${className} ${styles.root}`}>{children}</div>;
};

const baseColor = '#927230';

const pinkColor = '#C72F62';

const bgColor = '#EFEDEA';

const useCheckboxStyle = makeStyles(theme => {
  return {
    checkboxRoot: {
      '& + *': {
        padding: 0,
        marginRight: theme.spacing(0.5),
        marginTop: '2px',
        fontSize: '0.875rem'
      },
      '&.MuiCheckbox-root': {
        color: 'transparent'
      },
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&.Mui-checked:hover': {
        backgroundColor: 'transparent'
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          fill: pinkColor,
          '& path': {
            d: 'path("M17.9003 6.49351C18.1892 6.6824 18.2703 7.06974 18.0814 7.35864L11.5647 17.3253C11.4646 17.4785 11.3023 17.5801 11.1208 17.6032C10.9392 17.6264 10.7566 17.5689 10.6212 17.4457L6.40454 13.6124C6.14912 13.3802 6.1303 12.9849 6.36249 12.7295C6.59468 12.4741 6.98997 12.4553 7.24538 12.6875L10.9197 16.0277L17.0352 6.67458C17.2241 6.38568 17.6114 6.30461 17.9003 6.49351Z")'
          }
        }
      },
      '& input + *': {
        width: '20px',
        height: '20px',
        backgroundColor: bgColor,
        border: '1px solid',
        borderColor: baseColor
      }
    }
  };
});
export type CheckboxProps = {
  className?: string;
  children?: React.ReactNode;
  indeterminate?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<MuiFormControlLabelProps, 'control' | 'label' | 'onChange'>;
export const Checkbox = ({
  className,
  children,
  checked,
  indeterminate,
  name,
  onChange,
  ...props
}: CheckboxProps) => {
  const styles = useCheckboxStyle();
  return (
    <MuiFormControlLabel
      className={`${className}`}
      control={
        <MuiCheckbox
          className={styles.checkboxRoot}
          name={name}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          disableRipple
        />
      }
      label={children}
      {...props}
    />
  );
};
