import type { AirRouteDetail, FlightSegmentJson } from '../select_repository';

class FlightSegment {
  public readonly from: {
    code: string;
    date: string;
    datetime: string;
    name: string;
    time: string;
    time_zone: string;
  };

  public readonly to: {
    code: string;
    date: string;
    datetime: string;
    name: string;
    time: string;
    time_zone: string;
  };

  public readonly cabin: string;

  public readonly name: string;

  public readonly carrier_name: string;

  public readonly marketing_carrier: string;

  public readonly flight_number: string;

  public fare_basis_code?: string;

  public air_route_detail?: AirRouteDetail;

  readonly api_related_data: any;

  constructor(args: FlightSegmentJson) {
    this.from = args.from;
    this.to = args.to;
    this.cabin = args.cabin;
    this.name = args.name;
    this.carrier_name = args.carrier_name;
    this.marketing_carrier = args.marketing_carrier;
    this.fare_basis_code = args.fare_basis_code;
    this.air_route_detail = args.air_route_detail;
    this.flight_number = args.flight_number;
    this.api_related_data = args.api_related_data;
  }

  get travelportTravelers(): { name: string; key: string; seatCode: string | undefined }[] {
    const travelers = this.api_related_data?.seat_map_data_for_post_booking_workflow?.travelers;
    if (travelers) {
      return travelers.map((t: any) => ({
        name: `${t.booking_traveler_name['@last']} ${t.booking_traveler_name['@first']}`,
        key: t.key,
        seatCode: this.travelportSeatCodeForTraveler(t.key)
      }));
    }
    return [];
  }

  private travelportSeatCodeForTraveler(key: string): string | undefined {
    if (this.api_related_data.reserved_seats) {
      const seat = this.api_related_data.reserved_seats.find(
        (s: { traveler_key: string; seat_code: string }) => s.traveler_key === key
      );
      return seat?.seat_code;
    }
    return undefined;
  }

  travelportSeatCodeForTravelerInformationId(travelerInformationId: number): string | undefined {
    if (this.api_related_data.reserved_seats) {
      const seat = this.api_related_data.reserved_seats.find(
        (s: { traveler_information_id: number; seat_code: string }) =>
          s.traveler_information_id === travelerInformationId
      );
      return seat?.seat_code;
    }
    return undefined;
  }
}

export default FlightSegment;
