import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import type Supplier from '@this/src/domain/supplier/supplier';
import type SuppliedItem from '@this/src/domain/supplied_item/supplied_item';
import { Box } from '@material-ui/core';
import type PaymentMethodList from '@this/domain/payment_method/payment_method_list';
import { styled } from '@this/constants/themes';

interface Props {
  suppliers: Supplier[];
  suppliedItems: SuppliedItem[];
  suppliedItemId: number | null;
  paymentMethods: PaymentMethodList | null;
  disabled?: boolean;
  onChange: (suppliedItemId: number | null) => void;
  paymentMethodProps?: {
    value: number | null;
    onChange: (value: number | null) => void;
  };
}

const SuppliedItemSelector = observer(
  ({
    suppliers,
    suppliedItems,
    suppliedItemId,
    paymentMethods,
    disabled,
    onChange,
    paymentMethodProps
  }: Props) => {
    const [supplierId, setSupplierId] = useState<number | null>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<number | null>(null);

    const handlePaymentMethodChange = useCallback(
      (paymentMethodId: number | null) => {
        setPaymentMethodId(paymentMethodId);
        if (paymentMethodProps) {
          paymentMethodProps.onChange(paymentMethodId);
        }
      },
      [paymentMethodProps]
    );

    const defaultPaymentMethod = () => {
      if (suppliedItems) {
        const targetItem = suppliedItems.find(s => s.id === suppliedItemId || 0);
        if (targetItem) {
          return paymentMethods?.default(targetItem.paymentMethodId || 0);
        }
        return '';
      }
      return '';
    };

    useEffect(() => {
      const suppliedItem = suppliedItems.find(item => item.id === suppliedItemId);
      if (suppliedItem) {
        setSupplierId(suppliedItem.supplierId);
        setPaymentMethodId(paymentMethodProps?.value ?? suppliedItem?.paymentMethodId);
      }
    }, [suppliedItems, suppliedItemId]);

    return (
      <>
        <Box>
          <Select
            value={supplierId || ''}
            disabled={disabled}
            onChange={e => {
              if (e.target.value === '') {
                setSupplierId(null);
                onChange(null);
              } else {
                const newSupplierId = parseInt(e.target.value, 10);
                setSupplierId(newSupplierId);
                const suppliedItem = suppliedItems.find(item => item.supplierId === newSupplierId);
                if (suppliedItem) {
                  onChange(suppliedItem.id);
                }
              }
            }}
          >
            <option value="">未選択</option>
            {suppliers.map(s => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Select
            value={suppliedItemId || ''}
            disabled={disabled}
            onChange={e => {
              if (e.target.value === '') {
                onChange(null);
              } else {
                onChange(parseInt(e.target.value, 10));
              }
            }}
          >
            <option value="">未選択</option>
            {suppliedItems
              .filter(si => si.supplierId === supplierId || supplierId === undefined)
              .map(si => (
                <option key={si.id} value={si.id}>
                  {si.name}
                </option>
              ))}
          </Select>
        </Box>
        <Box>
          <Select
            value={paymentMethodId?.toString() || ''}
            disabled={disabled}
            onChange={e => handlePaymentMethodChange(parseInt(e.target.value, 10) || null)}
          >
            <option value="">{defaultPaymentMethod() ? 'デフォルト値を設定' : '未設定'}</option>
            {paymentMethods?.list.map(paymentMethod => (
              <option key={paymentMethod.id} value={paymentMethod.id?.toString() || ''}>
                {paymentMethod.codeWithName()}
              </option>
            ))}
          </Select>
          {defaultPaymentMethod() && <Default>デフォルト：{defaultPaymentMethod()}</Default>}
        </Box>
      </>
    );
  }
);

const Select = styled.select`
  margin-bottom: 0;
`;

const Default = styled.div`
  font-size: 0.9em;
  margin-top: 4px;
  color: ${props => props.theme.grayTextColor};
`;

export default SuppliedItemSelector;
