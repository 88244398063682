import type { SeatMapFacilityJson } from '@this/domain/flight/seat_map_facility';
import SeatMapFacility from '@this/domain/flight/seat_map_facility';

export interface SeatMapRowJson {
  row_number: string;
  row_index: number;
  facilities: SeatMapFacilityJson[];
  wing_type?: 'start' | 'middle' | 'end';
}

class SeatMapRow {
  readonly rowNumber: number;

  readonly rowIndex: number;

  readonly facilities: SeatMapFacility[];

  readonly wingType?: 'start' | 'middle' | 'end';

  constructor(args: SeatMapRowJson) {
    this.rowNumber = Number(args.row_number);
    this.rowIndex = args.row_index;
    this.facilities = args.facilities.map(f => new SeatMapFacility(f));
    this.wingType = args.wing_type;
  }

  columnCount() {
    return this.facilities.length;
  }

  get isWindowExistsOnLeftSide(): boolean {
    return this.leftSideFacility.isWindowExists;
  }

  get isWindowExistsOnRightSide(): boolean {
    return this.rightSideFacility.isWindowExists;
  }

  get isOnWing(): boolean {
    return this.leftSideFacility.isOnWing;
  }

  get leftSideFacility(): SeatMapFacility {
    return this.facilities[0];
  }

  get rightSideFacility(): SeatMapFacility {
    return this.facilities[this.facilities.length - 1];
  }
}

export default SeatMapRow;
