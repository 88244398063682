import { observable } from 'mobx';

export type DueMonth = 'N' | 'N+1' | 'N+2';

const DUE_MONTH_LABELS: Record<DueMonth, string> = {
  N: '当月（N）',
  'N+1': '翌月（N+1）',
  'N+2': '翌々月（N+2）'
};

export const dueMonthOptions = Object.entries(DUE_MONTH_LABELS).map(([value, label]) => ({ value, label }));

export type ClosingDateArgs = {
  id?: number;
  closing_day: number;
  due_month: DueMonth;
  due_date: number;
};

export default class ClosingDate {
  @observable
  id: number | null;

  @observable
  closingDay: number;

  @observable
  dueMonth: DueMonth;

  @observable
  dueDate: number;

  @observable
  destroy = false;

  constructor(args: ClosingDateArgs) {
    this.id = args.id ?? null;
    this.closingDay = args.closing_day;
    this.dueMonth = args.due_month;
    this.dueDate = args.due_date;
  }

  cloneArgs(): ClosingDateArgs {
    return {
      id: this.id ?? undefined,
      closing_day: this.closingDay,
      due_month: this.dueMonth,
      due_date: this.dueDate
    };
  }

  dueMonthLabel() {
    return DUE_MONTH_LABELS[this.dueMonth];
  }

  setDestroy() {
    this.destroy = true;
    app.render();
  }

  setClosingDay(closingDay: number | string) {
    this.closingDay = typeof closingDay === 'string' ? parseInt(closingDay, 10) : closingDay;
    app.render();
  }

  setDueMonth(dueMonth: DueMonth) {
    this.dueMonth = dueMonth;
    app.render();
  }

  setDueDate(dueDate: number | string) {
    this.dueDate = typeof dueDate === 'string' ? parseInt(dueDate, 10) : dueDate;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      closing_day: this.closingDay,
      due_month: this.dueMonth,
      due_date: this.dueDate,
      _destroy: this.destroy
    };
  }

  static initialize() {
    return new ClosingDate({
      closing_day: 31,
      due_month: 'N+1',
      due_date: 31
    });
  }
}
