export const OrderItemStatus = {
  9: '発券の指示',
  3: '予約',
  8: 'FINAL',
  10: '発注',
  11: '発券',
  12: '発送',
  13: '右ナビ承認',
  7: '完了',
  4: '[廃止予定]予約確定',
  6: '[廃止予定]キャンセル依頼中',
  5: '[廃止予定]キャンセル'
} as const;

export type OrderItemStatusKey = keyof typeof OrderItemStatus;

export const OrderItemStatusAvailable: readonly OrderItemStatusKey[] = [9, 3, 8, 10, 11, 12, 13, 7, 4, 6, 5];

// 廃止予定を順次消していきたいが、旅工房で問題が起きる可能性があるため、一旦別に定義しておく
export const OrderItemStatusAvailable2: readonly OrderItemStatusKey[] = [9, 3, 8, 10, 11, 12, 13, 7];

export const OrderItemStatusOngoing: readonly OrderItemStatusKey[] = [9, 3, 8, 10, 11, 12, 13, 4, 6];

export const OrderItemStatusDone: readonly OrderItemStatusKey[] = [7];
