import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { RouteComponentProps } from 'react-router-dom';
import Button from '@this/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { styled } from '@this/constants/themes';
import { Input } from '@this/shared/ui/inputs/input';
import type { SuppliedItemArgs } from '@this/domain/supplied_item/supplied_item';
import SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type { PaymentMethodArgs } from '@this/domain/payment_method/payment_method';
import PaymentMethodList from '@this/domain/payment_method/payment_method_list';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import { Fetcher, HTTPError } from '@this/src/util';
import { SendList } from '../../../domain/send_list/send_list';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import ShippingCostForm from './shipping_cost_form';
import ShippingCostTable from './shipping_cost_table/shipping_cost_table';

type Props = RouteComponentProps;

interface SendListResponse {
  lists: SendList[];
}

interface State {
  sendLists: SendList[];
  loading: boolean;
  from: Moment;
  to: Moment;
  editingList: SendList | null;
  suppliedItems: SuppliedItem[];
  suppliedItemLoading: boolean;
  paymentMethods: PaymentMethodList | null;
  paymentMethodLoading: boolean;
  tabType: 'create' | 'search';
  searchType: 'term' | 'id';
  searchSendListId: number | string;
}

class SendList2 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const tabType: 'create' | 'search' = utils.getParam('tabType') || 'create';
    const from: string | undefined = utils.getParam('from');
    const to: string | undefined = utils.getParam('to');
    this.state = {
      sendLists: [],
      loading: false,
      from: from ? moment(from) : moment().add(-7, 'days'),
      to: to ? moment(to) : moment(),
      editingList: null,
      suppliedItems: [],
      suppliedItemLoading: false,
      paymentMethods: null,
      paymentMethodLoading: false,
      tabType,
      searchType: 'term',
      searchSendListId: ''
    };
  }

  componentDidMount() {
    this.fetchSendList2();
    this.fetchSuppliedItems();
    this.fetchPaymentMethods();
  }

  async fetchSendList2(): Promise<void> {
    this.setState({ loading: true });
    const searchType = this.state.searchType;
    const searchSendListId = this.state.searchSendListId;
    const from = this.state.from.format('YYYY-MM-DD');
    const to = this.state.to.format('YYYY-MM-DD');
    const tabType = this.state.tabType;
    const args = { searchType, searchSendListId, from, to, tabType };
    const queries = _.map(args, (v, k) => `${k}=${v}`);
    const query = _.join(queries, '&');
    this.props.history.push(`/arrangement/send_list2?${query}`);

    return Fetcher.get<SendListResponse>('/arrangement/send_list2.json', args).then(result =>
      this.setState({
        sendLists: result.lists.map(raw => new SendList(raw)),
        loading: false
      })
    );
  }

  async fetchSuppliedItems(): Promise<void> {
    this.setState({ suppliedItemLoading: true });

    return utils
      .jsonPromise<SuppliedItemArgs[]>('/arrangement/supplied_items.json?for_delivery_charge=true')
      .then(result =>
        this.setState({
          suppliedItems: result.map(raw => new SuppliedItem(raw)),
          suppliedItemLoading: false
        })
      );
  }

  async fetchPaymentMethods(): Promise<void> {
    this.setState({ paymentMethodLoading: true });

    return Fetcher.get<PaymentMethodArgs[]>('/arrangement/payment_methods.json').then(result =>
      this.setState({
        paymentMethods: new PaymentMethodList(result),
        paymentMethodLoading: false
      })
    );
  }

  async createShippingCosts(list: SendList): Promise<void> {
    return Fetcher.post(`/arrangement/send_list2/${list.id}/shipping_costs.json`, {}).then(() =>
      this.fetchSendList2()
    );
  }

  async destroyShippingCosts(list: SendList): Promise<void> {
    return Fetcher.delete(`/arrangement/send_list2/${list.id}/shipping_costs.json`, {})
      .then(() => this.fetchSendList2())
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.message) {
          // eslint-disable-next-line no-alert
          alert(e.response.data.message);
        } else {
          // eslint-disable-next-line no-alert
          alert('不明なエラー');
        }
      });
  }

  static handleCreate() {
    location.href = '/arrangement/send_list2/new';
  }

  handleChangeTab(tabType: 'create' | 'search') {
    this.setState({ tabType });
  }

  handleChangeSearchType(searchType: 'term' | 'id') {
    this.setState({ searchType });
  }

  handleFromChange(date: Moment) {
    let to = this.state.to;
    if (date.isAfter(to)) {
      to = date;
    }
    this.setState({ from: date, to });
  }

  handleToChange(date: Moment) {
    this.setState({ to: date });
  }

  handleCreateShippingCosts(list: SendList) {
    this.createShippingCosts(list);
  }

  handleDestroyShippingCosts(list: SendList) {
    this.destroyShippingCosts(list);
  }

  handleShowShippingCostModal(list: SendList) {
    this.setState({ editingList: list });
  }

  handleHideShippingCostModal() {
    this.setState({ editingList: null });
  }

  handleSubmitShippingCosts() {
    this.setState({ editingList: null }, () => this.fetchSendList2());
  }

  render() {
    try {
      const {
        suppliedItems,
        suppliedItemLoading,
        paymentMethods,
        paymentMethodLoading,
        loading,
        from,
        to,
        editingList,
        tabType,
        searchType,
        searchSendListId
      } = this.state;
      return (
        <Wrapper>
          <div style={{ display: 'flex', fontSize: '14px', marginBottom: '20px' }}>
            <Tab onClick={() => this.handleChangeTab('create')}>作成</Tab>
            <Tab onClick={() => this.handleChangeTab('search')}>検索</Tab>
          </div>
          {tabType === 'create' ? (
            <>
              {loading ? (
                <SimpleLoading />
              ) : (
                <div>
                  <SearchArea>
                    <input
                      type="radio"
                      value="term"
                      checked={searchType === 'term'}
                      onChange={() => this.handleChangeSearchType('term')}
                    />
                    <span>期間</span>
                    <input
                      type="radio"
                      value="id"
                      checked={searchType === 'id'}
                      onChange={() => this.handleChangeSearchType('id')}
                    />
                    <span>送付状ID</span>
                  </SearchArea>
                  <SearchArea>
                    {searchType === 'term' ? (
                      <SearchForm>
                        <SearchLabel>作成日</SearchLabel>
                        <DatetimePicker
                          value={from}
                          onChange={d => this.handleFromChange(d)}
                          showTime={false}
                          disabledDays={0}
                          showPast
                          border
                        />
                        <span>〜</span>
                        <DatetimePicker
                          value={to}
                          onChange={d => this.handleToChange(d)}
                          showTime={false}
                          disabledDays={0}
                          showPast
                          border
                        />
                        <TheButton onClick={() => this.fetchSendList2()}>検索</TheButton>
                      </SearchForm>
                    ) : (
                      <SearchForm>
                        <SearchLabel>送付状ID</SearchLabel>
                        <Input
                          type="text"
                          value={searchSendListId}
                          onChange={e => this.setState({ searchSendListId: parseInt(e.target.value, 10) || 0 })}
                        />
                        <TheButton onClick={() => this.fetchSendList2()}>検索</TheButton>
                      </SearchForm>
                    )}
                  </SearchArea>
                  <button type="button" onClick={SendList2.handleCreate}>
                    新規作成
                  </button>

                  <Table style={{ width: 'auto' }} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>送付状ID</TableCell>
                        <TableCell>作成日</TableCell>
                        <TableCell colSpan={4}>最終更新日</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.sendLists.map(list => (
                        <TableRow key={list.id} hover>
                          <TableCell>{list.id}</TableCell>
                          <TableCell>{moment(list.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                          <TableCell>{moment(list.updatedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                          <TableCell>
                            {list.shippingCosts.length > 0 ? (
                              <a onClick={() => this.handleShowShippingCostModal(list)}>送料登録</a>
                            ) : (
                              <a href={`/arrangement/send_list2/${list.id}/edit`}>編集</a>
                            )}
                          </TableCell>
                          <TableCell>
                            <a
                              href={`/arrangement/send_list2/${list.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              印刷画面
                            </a>
                          </TableCell>
                          <TableCell>
                            {list.shippingCosts.length > 0 ? (
                              <a onClick={() => this.handleDestroyShippingCosts(list)}>取消</a>
                            ) : (
                              <a onClick={() => this.handleCreateShippingCosts(list)}>確定</a>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              {editingList && (
                <Modal open onClose={() => this.handleHideShippingCostModal()} size="xlarge">
                  <ModalHeader>送料請求に関する情報</ModalHeader>
                  <ModalBody style={{ padding: 0 }}>
                    <ShippingCostForm
                      suppliedItems={suppliedItems}
                      suppliedItemLoading={suppliedItemLoading}
                      paymentMethods={paymentMethods}
                      paymentMethodLoading={paymentMethodLoading}
                      list={editingList}
                      onSubmit={() => this.handleSubmitShippingCosts()}
                    />
                  </ModalBody>
                </Modal>
              )}
            </>
          ) : (
            <ShippingCostTable suppliedItems={suppliedItems} />
          )}
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const SearchArea = styled.div`
  margin-bottom: 20px;
`;

const SearchForm = styled.div`
  display: flex;
`;

const SearchLabel = styled.span`
  margin-right: 10px;
`;

const TheButton = styled(Button)`
  margin-left: 10px;
`;

const Tab = styled.div`
  margin-right: 10px;
  cursor: pointer;
  border-bottom: #ccc solid 2px;
`;

export default SendList2;
