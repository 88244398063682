class RoomElement
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @type = 'room'
    @startedAt = moment(args.startedAt)
    @endedAt = if args.endedAt then moment(args.endedAt) else moment(@startedAt).add(1, 'hour')
    @name ||= ''
    @address ||= ''
    @remark ||= ''
    @reservationNumber = args.reservationNumber || ''

  handleStartedAtChange: (moment) ->
    @startedAt = moment
    app.render()

  handleEndedAtChange: (moment) ->
    @endedAt = moment
    app.render()

  handleChangeName: (value) ->
    @name = value
    app.render()

  handleChangeAddress: (value) ->
    @address = value
    app.render()

  handleChangeRemark: (value) ->
    @remark = value
    app.render()

  handleReservationNumberChange: (value) ->
    @reservationNumber = value
    app.render()

  startDate: ->
    @startedAt

  endDate: ->
    @endedAt

  description: ->
    """
    <会議室>
    ■施設名：#{@name}
    ■住所：#{@address}
    ■ご利用日時：#{@startedAt.format('MM/DD HH:mm')}〜#{@endedAt.format('MM/DD HH:mm')}
    ■備考
    #{@remark}
    """

  title: ->
    '会議室'

  summary: ->
    '[会議室]' + @name

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  validationErrors: (orderItemStatus) ->
    errors = []
    needStatusGuard = orderItemStatus == 7 || orderItemStatus == 13
    if @name == '' then errors.push(@roomValidationError)
    if @endedAt < @startedAt then errors.push(@timeValidationError)
    if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
    errors

  roomValidationError:
    '会議室の施設名を入力してください。'

  timeValidationError:
    '利用開始以降を指定してください。'

  reservationNumberValidationError:
    '予約番号を入力してください。'

  updateParams: ->
    type: @type
    started_at: @startedAt.format('YYYY-MM-DD HH:mm:ss')
    ended_at: @endedAt.format('YYYY-MM-DD HH:mm:ss')
    name: @name
    address: @address
    remark: @remark
    reservation_number: @reservationNumber

module.exports = RoomElement
