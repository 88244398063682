import type { MouseEvent } from 'react';
import React from 'react';

import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';
import { BorderButtonBase02 } from '@this/shared/atoms/button';

interface Props {
  billed: boolean;
  handleReceiptClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const TripChatHeader: React.FC<Props> = ({ billed, handleReceiptClick }) => (
  <Header>
    <Link to="/trips">&lt; 旅程管理一覧</Link>
    <ButtonGroup>
      {billed && (
        <Button className="receipt-button" onClick={handleReceiptClick}>
          領収書
        </Button>
      )}
    </ButtonGroup>
  </Header>
);

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #eee;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Button = styled.button`
  ${BorderButtonBase02}
  padding: 5px 6px;
  font-size: 12px;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
`;

const ButtonGroup = styled.div`
  display: flex;

  ${Button} + ${Button} {
    margin-left: 10px;
  }
`;

export default TripChatHeader;
