import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

interface Props {
  trip: any;
  endTime: Date | null;
}

const StatusLabelTemplate = ({ trip, endTime }: Props) =>
  !_.isEmpty(trip.isApproveCanceled()) ? (
    <Inactive>取消済み</Inactive>
  ) : trip.currentApprovalStatus() === 2 ? (
    <Denied>却下済み</Denied>
  ) : trip.status === 4 || trip.status === 7 ? (
    endTime && endTime.valueOf() < Date.now() ? (
      <TripFinished>出張完了</TripFinished>
    ) : (
      <ArrangementFinished>手配完了</ArrangementFinished>
    )
  ) : !(!trip.currentOrder && trip.nonOrderItems) && trip.isAllOrderItemStatusComplete() ? (
    <Arranging>手配完了</Arranging>
  ) : trip.status === 0 ? (
    <Arranging>旅程リクエスト中</Arranging>
  ) : trip.status === 6 ? (
    <Inactive>キャンセル依頼中</Inactive>
  ) : trip.status === 5 ? (
    <Inactive>キャンセル済み</Inactive>
  ) : trip.status === 8 ? (
    <Arranging>ワークフロー申請待ち</Arranging>
  ) : trip.status === 9 ? (
    <Arranging>ワークフロー承認待ち</Arranging>
  ) : trip.status === 10 ? (
    <Arranging>ワークフロー再申請待ち</Arranging>
  ) : trip.status === 11 ? (
    <Inactive>ワークフロー却下</Inactive>
  ) : trip.status === 12 ? (
    <Inactive>ワークフロー削除済み</Inactive>
  ) : trip.currentApprovalStatus() === 0 ? (
    <Inactive>承認待ち</Inactive>
  ) : trip.status === 3 ? (
    !trip.currentOrder && trip.nonOrderItems ? (
      endTime && endTime.valueOf() < Date.now() ? (
        <TripFinished>出張完了</TripFinished>
      ) : trip.in_advance_approval && trip.in_advance_approval.length > 0 ? (
        trip.currentApprovalStatus() === 1 ? (
          <Inactive>承認済み</Inactive>
        ) : (
          <ArrangementFinished>旅程作成済</ArrangementFinished>
        )
      ) : (
        <ArrangementFinished>旅程作成済</ArrangementFinished>
      )
    ) : (
      <Arranging>手配中</Arranging>
    )
  ) : (
    <Arranging>手配中</Arranging>
  );

const Denied = styled.p`
  color: ${props => props.theme.redColor};
`;

const Inactive = styled.p`
  color: #868686;
`;

const Arranging = styled.p`
  color: #0076bf;
`;

const ArrangementFinished = styled.p`
  color: #0d8b2f;
`;

const TripFinished = styled.p`
  color: #b58f2d;
`;

export default StatusLabelTemplate;
