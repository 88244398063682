/**
 * Object操作のためのUtilです
 */

import { isEmpty } from 'lodash';
import { snakeToCamel, camelToSnake } from '../string';

type AnyObject = { [k in string]: any };

/**
 * 受け取ったObjectのkeyをキャメルケースに変換します。
 */
export const snakeToCamelObject = (object: AnyObject) => {
  const res: AnyObject = {};
  Object.entries(object).forEach(([k, v]) => {
    res[snakeToCamel(k)] = v == null ? null : typeof v === 'object' ? snakeToCamelObject(v) : v;
  });

  return res;
};

/**
 * 受け取ったObjectのkeyをスネークケースに変換します。
 */
export const camelToSnakeObject = (object: AnyObject) => {
  const res: AnyObject = {};
  Object.entries(object).forEach(([k, v]) => {
    res[camelToSnake(k)] = v == null ? null : typeof v === 'object' ? camelToSnakeObject(v) : v;
  });

  return res;
};

/**
 * 指定のオブジェクトからパスで値を取得します。
 */
export const dig = (obj: AnyObject, ...keys: string[]) => {
  let res: any = obj;

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (res == null) {
      return res;
    }

    if (typeof obj[key] === 'function') {
      res = res[key]();
    } else {
      res = res[key];
    }
  }
  return res;
};

/**
 * 指定のオブジェクトから空を除去します。
 * TODO: 数字まで除去されてしまうことに注意
 */
export const compactObject = (o: AnyObject) => {
  const result: AnyObject = {};
  Object.entries(o).forEach(([k, v]) => {
    if (!isEmpty(v)) {
      result[k] = v;
    }
  });

  return result;
};
