import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import HotelElement from '@this/domain/hotel_element';
import type NonOrderItem from '@this/domain/non_order_item';
import type OrderItem from '@this/domain/order_item';
import TransportElement from '@this/domain/transport_element';

interface Props {
  trip: any;
  isOrderItem: boolean;
}

const OrderItemIcons: React.FC<Props> = props => {
  const symbols = getSymbolsByTrip(props);

  return (
    <>
      {!props.isOrderItem && symbols.length > 0 && <SmallLabel className="text--muted">自己手配</SmallLabel>}
      {symbols.map((symbol, i) => {
        return (
          <li key={i}>
            {symbol === 'hotel' && <OrderItemIconHotel />}
            {symbol === 'air' && <OrderItemIconAir />}
            {symbol === 'rental_car' && <OrderItemIconRentalCar />}
            {symbol === 'shinkansen' && <OrderItemIconShinkansen />}
            {symbol === 'bus' && <OrderItemIconBus />}
            {symbol === 'train' && <OrderItemIconTrain />}
          </li>
        );
      })}
    </>
  );
};

const getSymbolsByTrip = ({ trip, isOrderItem }: Props) => {
  const symbols: string[] = [];

  if (isOrderItem) {
    if (trip.currentOrder) {
      trip.order.orderItems.forEach((item: OrderItem) => {
        switch (item.orderItemCategory) {
          case 'hotel':
            symbols.push('hotel');
            break;
          case 'domestic_air':
          case 'foreign_air':
            symbols.push('air');
            break;
          case 'shinkansen':
          case 'express':
            symbols.push('shinkansen');
            break;
          case 'railway_ticket':
            symbols.push('train');
            break;
          case 'rental_car':
            symbols.push('rental_car');
            break;
          case 'bus':
            symbols.push('bus');
            break;
          case 'other':
            symbols.push('option');
            break;
          case 'wbf_package':
            symbols.push('hotel');
            symbols.push('air');
            break;
          default:
            break;
        }
      });
    }
  } else if (trip.nonOrderItems.length > 0) {
    trip.nonOrderItems.forEach((non_order_item: NonOrderItem) => {
      non_order_item.elements.forEach((element: any) => {
        if (element instanceof TransportElement) {
          switch (element.transportType) {
            case 'domestic_air':
            case 'foreign_air':
              symbols.push('air');
              break;
            case 'shinkansen':
              symbols.push('shinkansen');
              break;
            case 'railway_ticket':
              symbols.push('train');
              break;
            case 'rental_car':
              symbols.push('rental_car');
              break;
            case 'bus':
              symbols.push('bus');
              break;
            default:
              break;
          }
        } else if (element instanceof HotelElement) {
          symbols.push('hotel');
        }
      });
    });
  }
  return _.uniq(symbols);
};

const SmallLabel = styled.small`
  margin-bottom: 2px;
`;

const OrderItemIcon = styled.i`
  display: block;
  width: 20px;
  height: 20px;
  margin: 0;
  background-size: 20px 20px;
`;

const OrderItemIconHotel = styled(OrderItemIcon)`
  background-image: url('/images/trip_form_icon_hotel.png');
`;

const OrderItemIconAir = styled(OrderItemIcon)`
  background-image: url('/images/trip_form_icon_plane.png');
`;

const OrderItemIconShinkansen = styled(OrderItemIcon)`
  background-image: url('/images/trip_form_icon_train.png');
`;

const OrderItemIconRentalCar = styled(OrderItemIcon)`
  background-image: url('/images/trip_form_icon_car.png');
`;

const OrderItemIconBus = styled(OrderItemIcon)`
  background-image: url('/images/bus.png');
`;

const OrderItemIconTrain = styled(OrderItemIcon)`
  background-image: url('/images/trip_form_icon_train.png');
`;

export default OrderItemIcons;
