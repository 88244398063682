import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type RemittanceDestinationAccount from '@this/domain/expenses/remittance_destination_account';

type Props = {
  account: RemittanceDestinationAccount;
};

const RemittanceDestinationAccountForm: React.FC<Props> = ({ account }) => {
  return (
    <dl>
      <Title>
        <label htmlFor="bank-code">被仕向金融機関コード</label>
      </Title>
      <dd>
        <input
          id="bank-code"
          type="text"
          placeholder="半角数字"
          value={account.bankCode}
          onChange={e => {
            account.bankCode = e.target.value;
          }}
        />
      </dd>
      <Title>
        <label htmlFor="bank-name">被仕向金融機関名</label>
      </Title>
      <dd>
        <input
          id="bank-name"
          type="text"
          placeholder="半角カナ英数字"
          value={account.bankName}
          onChange={e => {
            account.bankName = e.target.value;
          }}
        />
      </dd>
      <Title>
        <label htmlFor="branch-code">被仕向支店コード</label>
      </Title>
      <dd>
        <input
          id="branch-code"
          type="text"
          placeholder="半角数字"
          value={account.branchCode}
          onChange={e => {
            account.branchCode = e.target.value;
          }}
        />
      </dd>
      <Title>
        <label htmlFor="branch-name">被仕向支店名</label>
      </Title>
      <dd>
        <input
          id="branch-name"
          type="text"
          placeholder="半角カナ英数字"
          value={account.branchName}
          onChange={e => {
            account.branchName = e.target.value;
          }}
        />
      </dd>
      <Title>
        <label htmlFor="account-type">預金種目</label>
      </Title>
      <dd>
        <select
          id="account-type"
          value={account.accountType}
          onChange={e => {
            account.accountType = e.target.value as 'savings' | 'checking';
          }}
        >
          <option value="savings">普通</option>
          <option value="checking">当座</option>
          <option value="deposit">貯蓄</option>
        </select>
      </dd>
      <Title>
        <label htmlFor="account-number">口座番号</label>
      </Title>
      <dd>
        <input
          id="account-number"
          type="text"
          placeholder="半角数字"
          value={account.accountNumber}
          onChange={e => {
            account.accountNumber = e.target.value;
          }}
        />
      </dd>
      <Title>
        <label htmlFor="account-holder">受取人名</label>
      </Title>
      <dd>
        <input
          id="account-holder"
          type="text"
          placeholder="半角カナ英数字"
          value={account.accountHolder}
          onChange={e => {
            account.accountHolder = e.target.value;
          }}
        />
      </dd>
    </dl>
  );
};

const Title = styled.dt`
  & > label {
    font-size: 13px;
    font-weight: normal;
  }
`;

export default observer(RemittanceDestinationAccountForm);
