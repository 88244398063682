import { observable } from 'mobx';
import type { PaymentMethodArgs } from '@this/domain/payment_method/payment_method';
import PaymentMethod from '@this/domain/payment_method/payment_method';
import type PaymentMethodList from '@this/domain/payment_method/payment_method_list';
import type { SupplierArgs } from '../supplier/supplier';
import Supplier from '../supplier/supplier';

export type SuppliedItemArgs = {
  id: number;
  name: string;
  code: string;
  supplier_id: number;
  payment_method_id?: number;
  supplier_name?: string;
  supplier_code?: string;
  disabled: boolean;
  account_record_type: string;
  supplier?: SupplierArgs;
  plane_object?: boolean;
  payment_method?: PaymentMethodArgs;
};

export const AccountRecordTypeMap: { [key: string]: string } = {
  return_at: '帰着日',
  ticketing_at: '仕入日',
  started_at: '出発日'
} as const;

export default class SuppliedItem {
  @observable
  id: number;

  @observable
  name: string;

  @observable
  code: string;

  @observable
  supplierId: number;

  @observable
  paymentMethodId: number | null;

  @observable
  supplierName?: string;

  @observable
  supplierCode?: string;

  @observable
  disabled: boolean;

  @observable
  accountRecordType: string;

  supplier?: Supplier;

  @observable
  paymentMethod?: PaymentMethod;

  constructor(args: SuppliedItemArgs) {
    this.id = args.id;
    this.name = args.name;
    this.code = args.code;
    this.supplierId = args.supplier_id;
    this.paymentMethodId = args.payment_method_id || null;
    this.supplierName = args.supplier_name;
    this.supplierCode = args.supplier_code;
    this.disabled = args.disabled;
    this.accountRecordType = args.account_record_type;
    if (args.supplier) {
      this.supplier = new Supplier(args.supplier);
      this.supplierName = this.supplier.name;
      this.supplierCode = this.supplier.code;
    }
    if (args.payment_method) {
      this.paymentMethod = new PaymentMethod(args.payment_method);
    }
  }

  clone(): SuppliedItem {
    const args: SuppliedItemArgs = {
      id: this.id,
      supplier_id: this.supplierId,
      payment_method_id: this.paymentMethodId || undefined,
      name: this.name,
      code: this.code,
      disabled: this.disabled,
      account_record_type: this.accountRecordType
    };
    return new SuppliedItem(args);
  }

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setCode = (value: string) => {
    this.code = value;
    app.render();
  };

  setPaymentMethodId = (value: number | null, paymentMethods: PaymentMethodList | null) => {
    this.paymentMethodId = value;
    this.paymentMethod = paymentMethods?.list.find(paymentMethod => paymentMethod.id === value);
    app.render();
  };

  setDisabled = (value: boolean) => {
    this.disabled = value;
    app.render();
  };

  setAccountRecordType = (value: string) => {
    this.accountRecordType = value;
    app.render();
  };

  submitParams(args = {}) {
    const params = {
      supplier_id: this.supplierId,
      payment_method_id: this.paymentMethodId,
      name: this.name,
      code: this.code,
      disabled: this.disabled,
      account_record_type: this.accountRecordType
    };
    return Object.assign(params, args);
  }
}
