ExpenseList = require('./expense/expense_list')
Expense = require('./expense/expense')
Trip = require('./trip/trip')

module.exports = class TripReport
  @fromTrip: (trip) ->
    q = dig(trip, 'search_query')
    args =
      origin: dig(q, 'origin')
      destination: dig(q, 'destination')
      expenseList: ExpenseList.fromTrip(trip)
      tripId: trip.id
    departureTime = dig(q, 'departure_time')
    args['departureDate'] = moment(departureTime) if departureTime
    returnTime = dig(q, 'return_time')
    args['returnDate'] = moment(returnTime) if returnTime
    new @(args)

  constructor: (args = {}) ->
    @trip = args.trip

    args = snakeToCamelObject _.omit(args, 'trip')
    @id = args.id
    @tripId = args.tripId
    @origin = args.origin
    @destination = args.destination
    @departureDate =
      if args.departureDate
        moment(args.departureDate)
      else
        moment()
    @returnDate =
      if args.returnDate
        moment(args.returnDate)
      else
        moment()
    @expenseList =
      if args.expenseList
        args.expenseList
      else if args.expenses
        new ExpenseList(_.map(args.expenses, (raw) -> new Expense(snakeToCamelObject(raw))))
      else
        new ExpenseList()
    @result = args.result || ''
    @status = args.status
    @finalDestination = args.finalDestination || ''
    @purpose = args.purpose || ''
    @selected = true
    @rejectReason = args.rejectReason
    @payDateRaw = args.payDate
    @payDate =
      if @payDateRaw
        moment(@payDateRaw)
      else
        moment()

  setOrigin: (value) ->
    @origin = value
    app.render()

  setDestination: (value) ->
    @destination = value
    app.render()

  setDepartureDate: (value) ->
    @departureDate = value
    app.render()

  setReturnDate: (value) ->
    @returnDate = value
    app.render()

  setPayDate: (value) ->
    @payDateRaw = value
    @payDate = moment(value)
    app.render()

  setResult: (value) ->
    @result = value
    app.render()

  setFinalDestination: (value) ->
    @finalDestination = value
    app.render()

  setPurpose: (value) ->
    @purpose = value
    app.render()

  setRejectReason: (value) ->
    @rejectReason = value
    app.render()

  toggleSelected: ->
    @selected = !@selected
    app.render()

  addExpense: ->
    @expenseList.addExpense()

  removeExpense: (expense) ->
    @expenseList.removeExpense(expense)

  typeSummary: ->
    @expenseList.typeSummary()

  selectedExpenses: ->
    @expenseList.selectedItems()

  errors: ->
    errors = []
    unless @origin && @origin.length > 0
      errors.push('出発地を入力してください')
    unless @destination && @destination.length > 0
      errors.push('目的地を入力してください')
    unless @purpose && @purpose.length > 0
      errors.push('出張の目的を入力してください')
    errors = _.concat(errors, @expenseList.errors())
    errors

  errorsForReject: ->
    errors = []
    unless @rejectReason && @rejectReason.length > 0
      errors.push('差し戻し理由を入力してください')
    errors

  isValidForReject: ->
    _.isEmpty @errorsForReject()

  totalPrice: ->
    @expenseList.totalPrice()

  tripTotalPrice: ->
    @trip.totalPrice()

  totalPriceWithTrip: ->
    @totalPrice() + @tripTotalPrice()

  totalPaidPrice: ->
    @expenseList.totalPaidPrice()

  totalUnpaidPrice: ->
    @expenseList.totalUnpaidPrice()

  statusBeforeApplication: ->
    @status == 'before_application'

  statusApplying: ->
    @status == 'applying'

  statusApproved: ->
    @status == 'approved'

  statusDescription: ->
    desc = { before_application: '申請前', applying: '申請中', approved: '承認済み' }
    desc[@status]

  statusClass: ->
    classes = { before_application: 'gray', applying: 'yellow', approved: 'green' }
    classes[@status]

  representativeName: ->
    dig(@trip, 'user', 'name')

  payDateDescription: ->
    if @payDateRaw
      @payDate.format('YYYY/MM/DD')
    else
      ''

  adminAvailableFor: (action) ->
    availableActions =
      before_application: []
      applying: ['edit', 'approve', 'reject']
      approved: []
    _.includes availableActions[@status], action

  toCreateParams: ->
    trip_id: @tripId
    origin: @origin
    destination: @destination
    departure_date: @departureDate.format('YYYY-MM-DD')
    return_date: @returnDate.format('YYYY-MM-DD')
    expenses_attributes: @expenseList.toCreateParams()
    result: @result
    final_destination: @finalDestination
    purpose: @purpose

  toApproveParams: ->
    pay_date: @payDate.format('YYYY-MM-DD')

  toCreateExpenseApplicationParams: ->
    id: @id
    expenses: @expenseList.toCreateExpenseApplicationParams()
