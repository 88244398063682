import { observable, computed } from 'mobx';
import _ from 'lodash';
import type BulkTicket from '../bulk_ticket2';

export type MarginBaseType = 'percentage' | 'fixed';

export type CategoryType =
  | 'domestic'
  | 'domestic_hotel'
  | 'domestic_package'
  | 'foreign'
  | 'foreign_hotel'
  | 'domestic_rental_car'
  | 'domestic_flight'
  | 'domestic_shinkansen';

export type MarginTypeArgs = Partial<{
  base_type: MarginBaseType;
  base_amount: number;
  category: CategoryType;
  id: number;
}>;

export default class MarginType {
  static defaultBaseAmount = 1100 as const;

  static baseTypes: { [key in MarginBaseType]: string } = {
    fixed: '固定',
    percentage: '歩合'
  } as const;

  static categoryTexts: { [key in CategoryType]: string } = {
    domestic: '国内交通機関手数料',
    domestic_hotel: '国内ホテル手数料',
    domestic_package: '国内パッケージ手数料',
    foreign: '海外交通機関手数料',
    foreign_hotel: '海外ホテル手数料',
    domestic_rental_car: 'レンタカー手数料',
    domestic_flight: '国内航空券手数料',
    domestic_shinkansen: '国内新幹線・特急手数料'
  } as const;

  static calcDefaultMarginAmount(peoplenum: number, sectionNum = 1) {
    return MarginType.defaultBaseAmount * peoplenum * sectionNum;
  }

  @observable
  id?: number;

  @observable
  baseType: MarginBaseType = 'fixed';

  @observable
  baseAmount: number = MarginType.defaultBaseAmount;

  // domestic/foreignはどちらも交通機関のみに適用される
  @observable
  category: CategoryType = 'domestic';

  constructor(args: MarginTypeArgs = {}) {
    if (args.id != null) this.id = args.id;
    if (args.base_amount != null) this.baseAmount = args.base_amount;
    if (args.base_type != null) this.baseType = args.base_type;
    if (args.category != null) this.category = args.category;
  }

  @computed
  get baseTypeText() {
    return MarginType.baseTypes[this.baseType];
  }

  @computed
  get baseAmountSuffix() {
    switch (this.baseType) {
      case 'fixed':
        return '円';
      case 'percentage':
        return '％';
      default:
        return 'fixed';
    }
  }

  @computed
  get baseAmountText() {
    return `${this.baseAmount}${this.baseAmountSuffix}`;
  }

  submitParams() {
    return {
      id: this.id,
      base_type: this.baseType,
      base_amount: this.baseAmount || 0,
      category: this.category
    };
  }

  calcMarginAmount(price: number, peopleNum: number, sectionNum = 1) {
    const target = this.baseType === 'fixed' ? peopleNum : price;
    const base = this.baseType === 'fixed' ? (this.baseAmount || 0) * sectionNum : (this.baseAmount || 0) / 100.0;
    return _.ceil(target * base);
  }

  calcBulkTicketFee(ticket: BulkTicket) {
    if (this.baseType === 'fixed') {
      return this.baseAmount;
    }
    return (ticket.price * (this.baseAmount || 0)) / 100;
  }

  describe(price: number, peopleNum: number, sectionNum = 1) {
    switch (this.baseType) {
      case 'fixed':
        return `${utils.formatPrice((this.baseAmount || 0) * sectionNum)} × ${peopleNum}名`;
      case 'percentage':
        return `${utils.formatPrice(price)} × ${this.baseAmount}％`;
      default:
        return `${utils.formatPrice((this.baseAmount || 0) * sectionNum)} × ${peopleNum}名`;
    }
  }

  @computed
  get categoryText() {
    return MarginType.categoryTexts[this.category];
  }

  @computed
  get isDomestic() {
    return this.category === 'domestic';
  }

  @computed
  get isForeign() {
    return this.category === 'foreign';
  }

  @computed
  get isDomesticFlight() {
    return this.category === 'domestic_flight';
  }

  @computed
  get isDomesticShinkansen() {
    return this.category === 'domestic_shinkansen';
  }

  @computed
  get isDomesticHotel() {
    return this.category === 'domestic_hotel';
  }

  @computed
  get isForeignHotel() {
    return this.category === 'foreign_hotel';
  }
}
