import type { Moment } from 'moment';
import moment from 'moment';

interface OrderItemSnoozedTodoArgs {
  id: number;
  orderItemId: number;
  arrangerId: number;
  snoozeTo: string;
  memo: string;
  canceled: boolean;
}

class OrderItemSnoozedTodo {
  public id: number;

  public orderItemId: number;

  public arrangerId: number;

  public snoozeTo: Moment;

  public memo: string;

  public canceled: boolean;

  constructor(args: OrderItemSnoozedTodoArgs) {
    this.id = args.id;
    this.orderItemId = args.orderItemId;
    this.arrangerId = args.arrangerId;
    this.snoozeTo = moment(args.snoozeTo);
    this.memo = args.memo;
    this.canceled = args.canceled;
  }

  isSnoozing() {
    return !this.canceled && this.snoozeTo.isAfter(moment());
  }
}

export default OrderItemSnoozedTodo;
