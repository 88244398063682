import React from 'react';
import Grid from '@material-ui/core/Grid';
import type { OrderItemStepTodoFieldStore } from '@this/src/domain/arrangement/order_item_step_todo_field_store';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { styled } from '@this/src/components/constants/themes';
import { makeStyles } from '@material-ui/core/styles';
import OrderItemStepWorkDialogForm from './order_item_step_work_dialog_form';
import OrderItemStepWorkDialogFields from './order_item_step_work_dialog_fields';

interface Props {
  serviceId: number;
  orderItemStepTodoFieldStore: OrderItemStepTodoFieldStore;
  errors: string[];
}

const OrderItemStepWorkDialogContent: React.FC<Props> = ({ serviceId, orderItemStepTodoFieldStore, errors }) => {
  const { todo, orderItem } = orderItemStepTodoFieldStore;
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} className={classes.scrollableGrid}>
        <H3>ステップ情報</H3>
        <Ul>
          <Li>
            <label>ステップ数</label>
            <Text>{todo.stepNum}</Text>
          </Li>
          <Li>
            <label>ステップ名</label>
            <Text>{todo.step?.stepName}</Text>
          </Li>
          <Li>
            <label>ステップコード</label>
            <Text>{todo.step?.stepCode}</Text>
          </Li>
          <Li>
            <label>ドキュメントURL</label>
            <Text>
              {todo.step?.stepDocumentUrl && (
                <Link isExternal href={todo.step.stepDocumentUrl} target="_blank">
                  {todo.step.stepDocumentUrl}
                </Link>
              )}
            </Text>
          </Li>
          <Li>
            <label>ステップ説明</label>
            <Text>{todo.step?.stepDescription}</Text>
          </Li>
          <Li>
            <label>完了後アクション</label>
            <Text>{todo.getActionNames()}</Text>
          </Li>
        </Ul>
        <H3>商品情報</H3>
        <Ul>
          <Li>
            <label>Trace ID</label>
            <Text>{orderItem.traceId}</Text>
          </Li>
          <OrderItemStepWorkDialogFields orderItemStepTodoFieldStore={orderItemStepTodoFieldStore} />
          <Li>
            <label>商品説明</label>
            <Text>{orderItem.description}</Text>
          </Li>
          <Li>
            <label>利用者</label>
            <Ul>
              {orderItem.travelerInformations?.map((ti: any) => (
                <Li key={ti.id}>
                  <Text>
                    {ti.lastNameKana} {ti.firstNameKana} ({ti.lastNameRoman} {ti.firstNameRoman})
                  </Text>
                </Li>
              ))}
            </Ul>
          </Li>
        </Ul>
        <H3>旅程情報</H3>
        <Ul>
          <Li>
            <label>Trip ID</label>
            <Text>
              <Link href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`} target="_blank">
                {todo.tripId}
              </Link>
            </Text>
          </Li>
          <Li>
            <label>予約者</label>
            <Text>{orderItem.order?.trip?.user?.name}</Text>
          </Li>
          <Li>
            <label>企業の情報</label>
            <Text>{orderItem.order?.trip?.user?.organization?.name}</Text>
          </Li>
        </Ul>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.scrollableGrid}>
        <OrderItemStepWorkDialogForm
          serviceId={serviceId}
          orderItemStepTodoFieldStore={orderItemStepTodoFieldStore}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

const H3 = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};
  margin-bottom: 8px;
`;

const Ul = styled.ul`
  margin-bottom: 40px;
`;

const Li = styled.li`
  margin-bottom: 16px;
`;

const Text = styled.p`
  white-space: pre-wrap;
  padding-left: 16px;
  word-break: break-all;
`;

const useStyles = makeStyles(() => ({
  scrollableGrid: {
    height: '90vh',
    overflowY: 'auto'
  }
}));

export default OrderItemStepWorkDialogContent;
