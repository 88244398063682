require('core-js/stable');
require('regenerator-runtime/runtime');
require('tslib');
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');

require('./react');
require('./components');
require('./application/index');

// devtoolのconsoleからデプロイされているversionを確認できるように
window.AIT_VERSION = process.env.VERSION;
