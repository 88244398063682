import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

interface Props {
  orderItem: any;
}

const orderItemStatus = (orderItem: any) => {
  switch (orderItem.jobTypeValue) {
    case 'reserve':
      switch (orderItem.status) {
        case 3:
        case 9:
          return '手配準備中';
        case 8:
          return '発券依頼が必要';
        case 10:
        case 11:
        case 12:
          return '手配中';
        case 7:
        case 13:
          return '手配完了';
        default:
          return '';
      }
    case 'change':
      switch (orderItem.status) {
        case 3:
        case 9:
          return '変更手配準備中';
        case 8:
          return '発券依頼が必要';
        case 10:
        case 11:
        case 12:
          return '変更手配中';
        case 7:
        case 13:
          return '変更手配完了';
        default:
          return '';
      }
    case 'cancel':
      switch (orderItem.status) {
        case 3:
        case 9:
          return ' キャンセル準備中';
        case 8:
          return ' 発券依頼が必要';
        case 10:
        case 11:
        case 12:
          return 'キャンセル中';
        case 7:
        case 13:
          return 'キャンセル完了';
        default:
          return '';
      }
    default:
      return '';
  }
};

const OrderItemStatusLabelTemplate: React.SFC<Props> = ({ orderItem }) => (
  <Status>{orderItemStatus(orderItem)}</Status>
);

const Status = styled.p`
  color: #0076bf;
`;

export default OrderItemStatusLabelTemplate;
