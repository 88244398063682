import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

export type SimulateBaseFileStatus = 'stoped' | 'running' | 'completed';

const STATUSES: Record<SimulateBaseFileStatus, string> = {
  stoped: '停止中',
  running: '検索中',
  completed: '完了'
};

export interface SimulateBaseFileArgs {
  id: number;
  file_name: string;
  total_count: number;
  completed: number;
  failed: number;
  stoped: number;
  created_at: string;
  updated_at: string;
}

export class SimulateBaseFile {
  id: number;

  status: SimulateBaseFileStatus;

  fileName: string;

  totalCount: number;

  completed: number;

  failed: number;

  stoped: number;

  executed: number;

  createdAt: Moment;

  updatedAt: Moment;

  constructor(args: SimulateBaseFileArgs) {
    this.id = args.id;
    this.fileName = args.file_name;
    this.totalCount = args.total_count;
    this.completed = args.completed;
    this.failed = args.failed;
    this.stoped = args.stoped;
    this.executed = this.completed + this.failed + this.stoped;
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);

    this.status = this.stoped > 0 ? 'stoped' : this.executed < this.totalCount ? 'running' : 'completed';
  }

  getStatus() {
    return STATUSES[this.status];
  }

  submitParams(): SimulateBaseFileArgs {
    return {
      id: this.id,
      file_name: this.fileName,
      total_count: this.totalCount,
      completed: this.completed,
      failed: this.failed,
      stoped: this.stoped,
      created_at: this.createdAt.format('L LTS'),
      updated_at: this.updatedAt.format('L LTS')
    };
  }
}
