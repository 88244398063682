import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MuiCarousel from 'react-material-ui-carousel';

export type CarouselProps = {
  className?: string;
  children: React.ReactNode;
};

export const Carousel = ({ className, children }: CarouselProps) => {
  return (
    <MuiCarousel
      className={className}
      animation="slide"
      autoPlay={false}
      indicators={false}
      swipe
      navButtonsAlwaysVisible
      NextIcon={<NavigateNextIcon />}
      PrevIcon={<NavigateBeforeIcon />}
    >
      {children}
    </MuiCarousel>
  );
};
