import type { BulkActionAttr } from '@this/components/organization/types';

export const UPSERT_FORMAT: BulkActionAttr[] = [
  {
    name: 'code',
    summary: '勘定科目コード',
    example: '',
    required: false
  },
  {
    name: 'name',
    summary: '勘定科目名',
    example: '',
    required: true
  },
  {
    name: 'is_deleted',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true
  }
];

export const UPSERT_FORMAT_PRO: BulkActionAttr[] = [
  {
    name: 'code',
    summary: '勘定科目コード',
    example: '',
    required: false
  },
  {
    name: 'name',
    summary: '勘定科目名',
    example: '',
    required: true
  },
  {
    name: 'is_deleted',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true
  },
  {
    name: 'receipt_file_required',
    summary: '領収書アップロード',
    example: '必須: 1, 任意: 0',
    required: true
  }
];
