import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { toDomesticTel } from '@this/src/util';
import ContentBody from '@this/components/shared/atoms/content_body';
import A from '@this/components/shared/atoms/a';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import HotelMap from '@this/components/shared/hotel_map/hotel_map';
import SubInfo from '@this/shared/sub_info/sub_info';
import ReadMore from '@this/components/shared/text/readmore';

import { Carousel } from '@this/shared/ui/data_displays/carousel';
import {
  BodyWrapper,
  Error,
  Button,
  PriceSoldout,
  AmenityArea,
  Amenities,
  AmenityTitle,
  Ul,
  Li,
  DetailBox,
  DetailBoxTitle,
  DetailBoxSmall
} from './hotel_pc.template';

import type { HotelState } from './types';
import Text from '../shared/text/text';

type Props = HotelState & {
  getPropertyAmenities: () => any[];
  getRoomAmenities: () => any[];
  getImages: () => string[];
  togglePropertyAmenities: () => void;
  toggleRoomAmenities: () => void;

  searchQueryId: number | null;
  signedIn?: unknown; // FIXME: 渡されないprops
};

const HotelSpTemplate: React.SFC<Props> = ({
  showPropertyAmenities,
  showRoomAmenities,
  loading,
  error,
  destLocation,
  hotels,
  hotel,
  serviceId,
  searchQueryId,

  getPropertyAmenities,
  getRoomAmenities,
  getImages,
  togglePropertyAmenities,
  toggleRoomAmenities,

  signedIn
}) => (
  <HotelSp>
    <BodyWrapper>
      <Body>
        <BodyIn>
          {loading || !hotel ? (
            <Loading>
              <SimpleLoading />
            </Loading>
          ) : error ? (
            <Loading>
              <Error>{error}</Error>
              <A onClick={window.close}>閉じる</A>
            </Loading>
          ) : (
            <>
              <TopArea key={0}>
                <div>
                  <Title>{hotel.name}</Title>
                  <Address>{hotel.address}</Address>
                </div>
                {hotel.sold_out && <Soldout>※たった今他の方が予約されたため、空室がなくなりました。</Soldout>}
                <Button onClick={window.close}>閉じる</Button>
              </TopArea>
              <BodyArea key={1}>
                <MainImageArea>
                  <Carousel>
                    {getImages().map((image, i) => (
                      <div key={i} className="slide-wrapper">
                        <div className="slide">
                          <div className="image-wrap">
                            <MainImage style={{ backgroundImage: `url(${image})` }} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </MainImageArea>
                <InfoArea>
                  <SubInfo
                    data={{
                      [`${'目的地から'}`]: hotel.walkMinuteText(),
                      [`${'最寄り駅から'}`]: hotel.stationDistanceText(),
                      [`${'チェックイン'}`]: hotel.checkinText(),
                      [`${'チェックアウト'}`]: hotel.checkoutText()
                    }}
                    note={hotel.note}
                    customFontSize="11px"
                    isBold
                  />
                  <PriceArea>
                    {hotel.sold_out ? (
                      <PriceSoldout>空室なし</PriceSoldout>
                    ) : (
                      <>
                        <Price key={0}>{`ホテル料金合計：${hotel.priceText()}`}</Price>
                        {hotel.price_detail_text && <Text text={hotel.price_detail_text} />}
                        {hotel.showCancelStatus() && <Error>{`※キャンセル：${hotel.refund}`}</Error>}
                      </>
                    )}
                  </PriceArea>
                </InfoArea>
                <HotelMap
                  hotel={hotel}
                  hotels={hotels}
                  destLocation={destLocation}
                  serviceId={serviceId}
                  searchQueryId={searchQueryId}
                />

                <SpAmenityArea>
                  <Amenities>
                    <AmenityTitle>施設の設備</AmenityTitle>
                    <Ul>
                      {getPropertyAmenities().map((a, i) => (
                        <Li key={i}>{a}</Li>
                      ))}
                    </Ul>
                    {getPropertyAmenities().length >= 10 && (
                      <ToggleLink onClick={togglePropertyAmenities}>
                        {showPropertyAmenities ? '閉じる' : 'もっと見る'}
                      </ToggleLink>
                    )}
                  </Amenities>
                  <Amenities>
                    <AmenityTitle>部屋の設備</AmenityTitle>
                    <Ul>
                      {getRoomAmenities().map((a, i) => (
                        <Li key={i}>{a}</Li>
                      ))}
                    </Ul>
                    {getRoomAmenities().length >= 10 && (
                      <ToggleLink onClick={toggleRoomAmenities}>
                        {showRoomAmenities ? '閉じる' : 'もっと見る'}
                      </ToggleLink>
                    )}
                  </Amenities>
                </SpAmenityArea>
                <OthersArea>
                  {hotel.tel && (
                    <DetailBox>
                      <DetailBoxTitle>電話番号</DetailBoxTitle>
                      <DetailBoxSmall>
                        <Text text={toDomesticTel(hotel.tel)} />
                      </DetailBoxSmall>
                    </DetailBox>
                  )}
                  <DetailBox>
                    <DetailBoxTitle>宿泊プラン名</DetailBoxTitle>
                    <Ul>
                      <Li>{hotel.plan_name || '-'}</Li>
                    </Ul>
                    <div
                      style={{ fontSize: '10px', fontWeight: 'normal', textIndent: '-5px', marginLeft: '20px' }}
                    >
                      <ReadMore
                        text="※ポイント等の特典付きの表示がある場合がございますが、付与対象になりませんのでご了承ください"
                        lessClass="hotel-sp__readmore"
                        readmoreTextClass="hotel-sp__readmore_text"
                      />
                    </div>
                  </DetailBox>
                  <DetailBox>
                    <DetailBoxTitle>宿泊プラン詳細</DetailBoxTitle>
                    <Ul>
                      <Li>
                        <ReadMore
                          text={hotel.plan_detail || '-'}
                          lessClass="hotel-sp__readmore"
                          readmoreTextClass="hotel-sp__readmore_text"
                        />
                      </Li>
                    </Ul>
                  </DetailBox>
                  {hotel.room_type || hotel.roomBasicInfoExists() ? (
                    <DetailBox>
                      <DetailBoxTitle>部屋タイプ</DetailBoxTitle>
                      <Ul>
                        {hotel.room_type && <Li>{hotel.room_type}</Li>}
                        {hotel.roomBasicInfoExists() && <Li>{hotel.rakutenRoomName()}</Li>}
                      </Ul>
                    </DetailBox>
                  ) : (
                    <DetailBox>
                      <DetailBoxTitle>部屋タイプ</DetailBoxTitle>
                      <Ul>
                        <Li>-</Li>
                      </Ul>
                    </DetailBox>
                  )}
                  {hotel.bed_types && hotel.bed_types.length > 0 ? (
                    <DetailBox>
                      <DetailBoxTitle>ベッドの種類</DetailBoxTitle>
                      <Ul>
                        {hotel.bed_types.map((b, i) => (
                          <Li key={i}>{b.description}</Li>
                        ))}
                      </Ul>
                    </DetailBox>
                  ) : (
                    <DetailBox>
                      <DetailBoxTitle>ベッドの種類</DetailBoxTitle>
                      <Ul>
                        <Li>-</Li>
                      </Ul>
                    </DetailBox>
                  )}
                  <DetailBox>
                    <DetailBoxTitle>キャンセルポリシー</DetailBoxTitle>
                    <DetailBoxSmall>
                      <Text text={hotel.cancelPolicy()} />
                    </DetailBoxSmall>
                  </DetailBox>
                  <DetailBox>
                    <DetailBoxTitle>駐車場</DetailBoxTitle>
                    <DetailBoxSmall>
                      <Text text={hotel.parkingInformation} />
                    </DetailBoxSmall>
                  </DetailBox>
                  <DetailBox>
                    <DetailBoxSmall dangerouslySetInnerHTML={{ __html: hotel.checkinInstructions() }} />
                  </DetailBox>
                  {hotel.specialCheckinInstructions() && (
                    <DetailBox>
                      <DetailBoxTitle>備考</DetailBoxTitle>
                      <DetailBoxSmall>{hotel.specialCheckinInstructions()}</DetailBoxSmall>
                    </DetailBox>
                  )}
                  {hotel.parkingInformation && (
                    <DetailBox>
                      <DetailBoxTitle>駐車場に関する情報</DetailBoxTitle>
                      <DetailBoxSmall>
                        <Text text={hotel.parkingInformation} />
                      </DetailBoxSmall>
                    </DetailBox>
                  )}
                </OthersArea>
              </BodyArea>
            </>
          )}
        </BodyIn>
      </Body>
    </BodyWrapper>
  </HotelSp>
);

const HotelSp = styled.div``;

const Body = styled(ContentBody)`
  max-width: 100%;
  padding: 10px;
`;

const BodyIn = styled.div`
  min-height, 100vh;
  background: white;
  font-weight: bold;
`;

const Loading = styled.div`
  padding: 20px 0 0;
  text-align: center;
`;

const TopArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  padding: 12px 15px;

  @media screen and (max-width: 425px) {
    padding: 12px 0;
  }
`;

const Title = styled.div`
  font-size: 18px;
`;

const Address = styled.div`
  font-size: 12px;
`;

const Soldout = styled.div`
  margin-left: auto;
  color: ${props => props.theme.redColor};
`;

const BodyArea = styled.div`
  padding: 20px;
  width: 100%;

  @media screen and (max-width: 425px) {
    padding: 15px 0;
  }
`;

// & は
const MainImageArea = styled.div`
  width: 100%;
`;

const MainImage = styled.div`
  width: 100%;
  height: 288px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 0;
  background: ${props => props.theme.grayColorLight};
  font-size: 12px;

  @media screen and (max-width: 425px) {
    font-size: 11px;
  }
`;

const PriceArea = styled.div`
  background: white;
  padding: 5px;
`;

const Price = styled.div`
  font-size: 14px;
  color: ${props => props.theme.moneyColor};

  @media screen and (max-width: 425px) {
    font-size: 13px;
  }
`;

const SpAmenityArea = styled(AmenityArea)`
  margin: 15px 0;
`;

const ToggleLink = styled(A)`
  font-size: 10px;
`;

const OthersArea = styled.div`
  width: 100%;
`;

export default HotelSpTemplate;
