import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export interface Organization {
  id: number;
  name: string;
}

interface Props {
  selectedOrganizationIds: number[];
  onSelect: (ids: number[]) => void;
}

interface Response {
  organizations: Organization[];
}

const ArrangementOrganizationSelector = observer(({ selectedOrganizationIds, onSelect }: Props) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState<Organization[]>([]);
  const [query, setQuery] = useState<string>('');

  const fetchOrganizations = useCallback(() => {
    const params = {
      q: query,
      ids: selectedOrganizations.map(o => o.id).join(',')
    };
    utils.jsonPromise<Response>('/arrangement/organizations/autocomplete.json', params).then(res => {
      if (res.organizations.map(o => o.id).join(',') !== organizations.map(o => o.id).join(',')) {
        setOrganizations(res.organizations);
      }
    });
  }, [query, selectedOrganizations]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  useEffect(() => {
    setSelectedOrganizations(organizations.filter(org => selectedOrganizationIds.includes(org.id)));
  }, [organizations, selectedOrganizationIds]);

  return (
    <Autocomplete
      multiple
      options={organizations}
      getOptionLabel={o => o.name}
      filterOptions={(options, state) => {
        const q: string = state.inputValue;
        return _.filter(options, o => o.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
      }}
      onChange={(_, selected) => {
        onSelect(selected.map(o => o.id));
        setQuery('');
      }}
      onInputChange={(_e, value, reason) => {
        if (reason === 'input') {
          setQuery(value);
        }
      }}
      inputValue={query}
      value={selectedOrganizations}
      size="small"
      renderInput={params => (
        <TextField {...params} variant="outlined" style={{}} InputLabelProps={{ shrink: true }} size="small" />
      )}
      style={{ minWidth: '240px' }}
    />
  );
});

export default ArrangementOrganizationSelector;
