import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';

import moment from 'moment';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { SearchQueryHistoriesArgs } from '@this/domain/search_query_histories';
import SearchQueryHistories from '@this/domain/search_query_histories';
import type { SearchType } from '@this/domain/search_query';
import { SearchTypes } from '@this/domain/search_query';
import type User from '@this/domain/user/user';
import { getColor } from '@this/shared/ui/theme';
import { Button, BUTTON_SIZE, BUTTON_COLOR } from '@this/shared/ui/inputs/button';

interface SearchQuertHistoriesResponse {
  queries: SearchQueryHistoriesArgs[];
  user: User;
}

interface SearchHistoriesProps {
  handleResearch: (history: SearchQueryHistories) => void;
  onClickShowSearch: () => void;
}

interface SearchHistoriesState {
  searchHistories: SearchQueryHistories[];
  isLoading: boolean;
}

class SearchHistories extends React.Component<SearchHistoriesProps, SearchHistoriesState> {
  constructor(props: SearchHistoriesProps) {
    super(props);

    this.state = {
      searchHistories: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchSearchQueryHistories();
  }

  arrowPath = (type: SearchType) => {
    switch (type) {
      case SearchTypes.ROUND_TRIP:
        return 'arrow_round.png';
      case SearchTypes.ONE_WAY:
        return 'arrow_oneway.png';
      case SearchTypes.MULTI_LOCATION:
        return 'arrow_multi.png';
      default:
        return 'arrow_oneway.png';
    }
  };

  fetchSearchQueryHistories = () => {
    this.setState({ isLoading: true });
    Fetcher.get<SearchQuertHistoriesResponse>('/search_query_histories.json').then(res => {
      const searchHistories = res.queries
        .filter(iem => iem.items.length > 0)
        .map((iem: SearchQueryHistoriesArgs) => new SearchQueryHistories(iem, res.user));
      this.setState({ searchHistories, isLoading: false });
      app.render();
    });
  };

  handleDel = (h: SearchQueryHistories) => {
    this.setState({ isLoading: true });
    Fetcher.delete(`/search_query_histories/${h.id}.json`, {}).then(() => {
      this.fetchSearchQueryHistories();
    });
  };

  render() {
    try {
      const { isLoading, searchHistories } = this.state;
      const { handleResearch } = this.props;
      return (
        <HistoryList>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TablistArea>
                <Button
                  color={BUTTON_COLOR.SUB}
                  onClick={this.props.onClickShowSearch}
                  style={{ marginBottom: '10px' }}
                >
                  検索
                </Button>
              </TablistArea>
              {searchHistories.map((history, i) => (
                <HistoryLine key={i}>
                  <HistoryBox>
                    <HistoryBoxLineUpper>
                      <>
                        {history.searchType !== SearchTypes.HOTEL &&
                          history.items[0].origin !== history.items[0].destination && (
                            <>
                              {history.items[0].origin}
                              {this.arrowPath(history.searchType) && (
                                <HistoryBoxImg
                                  src={`/images/search_query_histories/${this.arrowPath(history.searchType)}`}
                                />
                              )}
                            </>
                          )}
                        {history.items[0].destination}
                      </>
                    </HistoryBoxLineUpper>
                    <HistoryBoxLineLower>
                      {moment(history.items[0].outdate).format('YYYY/MM/DD(dd)')} -{' '}
                      {moment(history.items[history.items.length - 1].outdate).format('YYYY/MM/DD(dd)')}
                    </HistoryBoxLineLower>
                    {history.showDetail && (
                      <HistoryBoxLineDetail>
                        {history.roomnum}部屋{' '}
                        <HistoryBoxIconImg src="/images/search_query_histories/icon_smoking.png" />
                        煙草{history.smokeStr()}{' '}
                        <HistoryBoxIconImg src="/images/search_query_histories/icon_food.png" />
                        朝食{history.breakfastStr()}
                        <ul>
                          {history.travelers?.list.map(t => (
                            <li key={t.travelerInformationId}>{t.name}</li>
                          ))}
                        </ul>
                        <HistoryBoxIconLeftImg src="/images/search_query_histories/icon_clock.png" />
                        <SerahcTime>検索日時：{history.createdAt.format('YYYY/MM/DD HH:mm')}</SerahcTime>
                      </HistoryBoxLineDetail>
                    )}
                  </HistoryBox>
                  <HistoryButton>
                    <Button
                      color={BUTTON_COLOR.SUB}
                      size={BUTTON_SIZE.medium}
                      endIcon={history.showDetail ? <ArrowTop /> : <ArrowDown />}
                      onClick={() => history.showOrHideDetail()}
                    >
                      詳細
                    </Button>
                    <Button
                      color={BUTTON_COLOR.PRIMARY}
                      size={BUTTON_SIZE.medium}
                      onClick={() => handleResearch(history)}
                    >
                      再検索
                    </Button>
                    <DeleteButton onClick={() => this.handleDel(history)}>
                      <img src="/images/search_query_histories/icon_delete.png" />
                    </DeleteButton>
                  </HistoryButton>
                </HistoryLine>
              ))}
            </>
          )}
        </HistoryList>
      );
    } catch (e) {
      return null;
    }
  }
}

const HistoryList = styled.div`
  max-height: calc(9 * 51px);
  overflow-y: auto;
  padding-right: 4px;
  position: relative;
`;

const HistoryLine = styled.div`
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${getColor('border', 'divider')};
`;

const HistoryBox = styled.div`
  padding: 1px;
`;

const HistoryButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const HistoryBoxImg = styled.img`
  margin: auto 10px;
  vertical-align: middle;
`;

const HistoryBoxIconImg = styled.img`
  margin: auto 2px auto 5px;
  vertical-align: middle;
`;

const HistoryBoxIconLeftImg = styled.img`
  margin: auto 2px auto 0;
  vertical-align: middle;
`;

const HistoryBoxLineUpper = styled.div`
  padding: 1px;
  vertical-align: middle;
  font-size: 13px;
  font-weight: bold;
`;

const HistoryBoxLineLower = styled.div`
  padding: 1px;
  font-size: 11px;
`;

const HistoryBoxLineDetail = styled.div`
  padding: 3px 1px;
  font-size: 13px;
  vertical-align: middle;
`;

const SerahcTime = styled.span`
  font-size: 11px;
`;

const DeleteButton = styled.a`
  font-size: 14px;
  padding: 8px 6px;
  margin: auto 2px;
`;

const ArrowDown = styled.span`
  &&& {
    padding-right: 15px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 9px;
      height: 9px;
      margin: auto 4px;
      border-top: 2px solid ${props => props.theme.linkColor};
      border-right: 2px solid ${props => props.theme.linkColor};
      transform: translateY(-2px) rotate(135deg);
      box-sizing: border-box;
    }
  }
`;

const ArrowTop = styled.span`
  &&& {
    padding-right: 15px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 9px;
      height: 9px;
      margin: auto 4px;
      border-top: 2px solid ${props => props.theme.linkColor};
      border-right: 2px solid ${props => props.theme.linkColor};
      transform: translateY(2px) rotate(315deg);
      box-sizing: border-box;
    }
  }
`;

const TablistArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

export default SearchHistories;
