import React, { useCallback, useMemo } from 'react';
import type { PickerProps } from 'rc-picker';
import Picker from 'rc-picker';
import jaJP from 'rc-picker/lib/locale/ja_JP';
import momentConfig from 'rc-picker/lib/generate/moment';
import type { InputProps } from '@this/shared/ui/inputs/input';
import { Input } from '@this/shared/ui/inputs/input';
import { IconButton, useFormControl } from '@material-ui/core';
import type { Moment } from 'moment';
import type { PickerPanelDateProps } from 'rc-picker/lib/PickerPanel';
import moment from 'moment';
import { Close } from '@material-ui/icons';

import { disabledDate, usePopupStyle } from '@this/shared/ui/inputs/datepicker/datepicker_base/datepicker.utils';
import { DatepickerPanelWrapper } from '@this/shared/ui/inputs/datepicker/datepicker_base/datepicker_base';
import { useStyles } from './datepicker.styles';

type DateType = Moment;

export const DATEPICKER_VARIANT = {
  outlined: 'outlined',
  ghost: 'ghost'
} as const;
export type DatepickerVariant = keyof typeof DATEPICKER_VARIANT;

/**
 * 内部的にはmomentを使用するが、in/outはjsの標準クラスであるDateにする。
 * 日付ライブラリを変更する際のするときの影響範囲を少なくするため
 */
export type DatepickerProps = {
  type?: 'date' | 'datetime-local';
  min?: Date;
  max?: Date;
  value?: Date;
  defaultValue?: Date;
  dateFormat?: string;
  variant?: DatepickerVariant;
  fullWidth?: boolean;
  disabledClear?: boolean;
  onChange?: (value: Date | null) => void;
} & Pick<InputProps, 'size' | 'error'> &
  Pick<PickerProps<DateType>, 'className' | 'style' | 'placeholder' | 'disabled'>;

const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';
const DEFAULT_TIME_FORMAT = 'HH:mm';

export const Datepicker = ({
  className,
  type = 'date',
  min,
  max,
  value: rawValue,
  defaultValue: rawDefaultValue,
  disabled: rawDisabled,
  onChange,
  size,
  error,
  dateFormat,
  variant = 'outlined',
  fullWidth,
  disabledClear,
  placeholder: rawPlaceholder,
  ...props
}: DatepickerProps) => {
  const muiFormControl = useFormControl();
  const styles = useStyles({ size, fullWidth: fullWidth ?? muiFormControl?.fullWidth, variant });
  const disabled = muiFormControl?.disabled ? muiFormControl.disabled : rawDisabled;

  const handleOnChange = useCallback(
    (date: DateType | null) => {
      onChange?.(date?.toDate() ?? null);
    },
    [onChange]
  );

  const format = useMemo(
    () =>
      dateFormat ??
      (type === 'datetime-local' ? `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}` : DEFAULT_DATE_FORMAT),
    [type, dateFormat]
  );
  const placeholder = useMemo(() => rawPlaceholder ?? dateFormat ?? '年/月/日', [rawPlaceholder, dateFormat]);
  const showTime = useMemo<PickerPanelDateProps<DateType>['showTime']>(
    () => (type === 'datetime-local' ? { showSecond: false, format: DEFAULT_TIME_FORMAT } : false),
    [type]
  );
  const value = useMemo<DateType | undefined>(() => (rawValue ? moment(rawValue) : undefined), [rawValue]);
  const defaultValue = useMemo<DateType | undefined>(
    () => (rawDefaultValue ? moment(rawDefaultValue) : undefined),
    [rawDefaultValue]
  );
  const popupStyle = usePopupStyle();

  return (
    <Picker<Moment>
      className={`${className} ${styles.root}`}
      showToday
      allowClear={!disabledClear}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      locale={jaJP}
      generateConfig={momentConfig}
      inputRender={inputProps => <InputAsDatepicker {...inputProps} size={size} error={error} />}
      clearIcon={<ClearButton />}
      panelRender={originPanel => <DatepickerPanelWrapper panel={originPanel} />}
      showTime={showTime}
      format={format}
      popupStyle={popupStyle}
      disabledDate={date => disabledDate(date, { min, max, type })}
      disabled={disabled}
      onChange={handleOnChange}
      {...props}
    />
  );
};

const InputAsDatepicker = React.memo(
  (props: Pick<DatepickerProps, 'size' | 'error'> & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>) => {
    return <Input {...props} readOnly fullWidth />;
  }
);

const ClearButton = React.memo(() => (
  <IconButton size="small">
    <Close fontSize="small" />
  </IconButton>
));
