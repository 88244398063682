class ShippingElement
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @type = 'shipping'
    @shippingCategoryOptions = camelToSnakeObject(@shippingCategoryOptions)
    @shippedAt = moment(args.shippedAt)
    @arrivedAt = if args.arrivedAt then moment(args.arrivedAt) else moment(@shippedAt).add(1, 'day')
    @category ||= _.keys(@shippingCategoryOptions)[0]
    @number ||= ''
    @remark ||= ''

  handleShippedAtChange: (moment) ->
    @shippedAt = moment
    app.render()

  handleArrivedAtChange: (moment) ->
    @arrivedAt = moment
    app.render()

  handleChangeCategory: (value) ->
    @category = value
    app.render()

  handleChangeNumber: (value) ->
    @number = value
    app.render()

  handleChangeRemark: (value) ->
    @remark = value
    app.render()

  startDate: ->
    @shippedAt

  endDate: ->
    @arrivedAt

  description: ->
    """
    <配送料>
    ■種別：#{@categoryName()}
    ■発送日時：#{@shippedAt.format('MM/DD HH:mm')}
    ■到着日時：#{@arrivedAt.format('MM/DD HH:mm')}
    ■特定記録番号：#{@number}
    ■備考
    #{@remark}
    """

  title: ->
    '配送料'

  summary: ->
    '[配送料]' + @categoryName()

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  categoryName: ->
    @shippingCategoryOptions[@category] || ''

  validationErrors: (_) ->
    errors = []
    if @remark == '' then errors.push(@shippingValidationError)
    if @arrivedAt < @shippedAt then errors.push(@timeValidationError)
    errors

  shippingValidationError:
    '配送料の備考を入力してください。'

  timeValidationError:
    '配送日時以降を指定してください。'

  updateParams: ->
    type: @type
    category: @category
    shipped_at: @shippedAt.format('YYYY-MM-DD HH:mm:ss')
    arrived_at: @arrivedAt.format('YYYY-MM-DD HH:mm:ss')
    number: @number
    remark: @remark

module.exports = ShippingElement
