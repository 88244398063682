import React from 'react';
import { Redirect } from 'react-router-dom';
import Link from '@this/shared/atoms/link';
import { TextField } from '../../shared/form_elements/form_elements';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import { isIncludeHyphen } from '../../shared/checker/hyphen_checker';

import type User from '../../../domain/user/user';

interface Props {
  user: User | null | undefined;
}

interface State {
  company: string;
  email: string;
  password: string;
  lastName: string;
  firstName: string;
  tel: string;
  submitting: boolean;
  succeed: boolean;
  isPrivacyChecked: boolean;
  errors: {
    company?: string;
    email?: string;
    password?: string;
    last_name?: string;
    first_name?: string;
    tel?: string;
  };
  submitError: string | null;
}

class ExpensesUsersSignup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      company: '',
      email: '',
      password: '',
      lastName: '',
      firstName: '',
      tel: '',
      submitting: false,
      succeed: false,
      isPrivacyChecked: false,
      errors: {},
      submitError: null
    };
  }

  componentDidMount() {
    mixpanel.track('[Biztra][view] /users/sign_up');
  }

  handleCompanyChange(company: string) {
    this.setState({ company });
  }

  handleEmailChange(email: string) {
    this.setState({ email });
  }

  handlePasswordChange(password: string) {
    this.setState({ password });
  }

  handleLastNameChange(lastName: string) {
    this.setState({ lastName });
  }

  handleFirstNameChange(firstName: string) {
    this.setState({ firstName });
  }

  handleTelChange(tel: string) {
    if (isIncludeHyphen(tel)) {
      this.setState({ errors: { ...this.state.errors, tel: 'ハイフンを含めず入力してください' } });
    } else {
      this.setState({ errors: { ...this.state.errors, tel: '' } });
    }
    this.setState({ tel });
  }

  /* formの内容が正しければtrueを返す */
  isValidFormContent() {
    const invalidErrors: State['errors'] = {};
    if (isIncludeHyphen(this.state.tel)) {
      invalidErrors.tel = 'ハイフンを含めず入力してください';
    }
    if (Object.keys(invalidErrors).length > 0) {
      this.setState({
        errors: { ...this.state.errors, ...invalidErrors }
      });
      return false;
    }
    return true;
  }

  changePrivacyCheck() {
    this.setState({ isPrivacyChecked: !this.state.isPrivacyChecked });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.isValidFormContent()) {
      return;
    }
    try {
      this.setState({ submitting: true, errors: {}, submitError: null });
      const args = {
        company: this.state.company,
        email: this.state.email,
        password: this.state.password,
        last_name: this.state.lastName,
        first_name: this.state.firstName,
        tel: this.state.tel
      };
      await utils.jsonPromise('/biztra/users.json', args, 'POST');
      this.setState({ submitting: false, succeed: true });
      mixpanel.track('[Biztra] sign up mail sent');
    } catch (e) {
      if (e.status === 400) {
        this.setState({ submitting: false, errors: e.responseJSON.errors });
        mixpanel.track('[Biztra] sign up error', { errors: e.responseJSON.errors });
      } else {
        this.setState({
          submitting: false,
          submitError: '通信エラーが発生しました。時間をおいて再度お試しください。'
        });
        utils.sendErrorObject(e);
      }
    }
  }

  render() {
    try {
      const { user } = this.props;
      const {
        company,
        email,
        password,
        lastName,
        firstName,
        tel,
        submitting,
        succeed,
        isPrivacyChecked,
        errors,
        submitError
      } = this.state;
      const classBase = 'expenses-users-signup';
      return (
        <div className={classBase}>
          <div className={`${classBase}__content`}>
            {user ? (
              <Redirect to="/biztra/report_items" />
            ) : (
              <form onSubmit={e => this.handleSubmit(e)}>
                <div className={`${classBase}__tagline`}>今すぐ使ってみる</div>
                <TextField
                  label="会社名（必須）"
                  value={company}
                  example="例：株式会社トランスファーデータ"
                  error={errors.company}
                  onChange={value => this.handleCompanyChange(value)}
                />
                <div className="flex">
                  <TextField
                    label="姓（必須）"
                    value={lastName}
                    example="例：山田"
                    error={errors.last_name}
                    className={`${classBase}__flex-field`}
                    onChange={value => this.handleLastNameChange(value)}
                  />
                  <TextField
                    label="名（必須）"
                    value={firstName}
                    example="例：一郎"
                    error={errors.first_name}
                    className={`${classBase}__flex-field`}
                    onChange={value => this.handleFirstNameChange(value)}
                  />
                </div>
                <TextField
                  label="メールアドレス（必須）"
                  value={email}
                  example="例：biztra@aitravel.jp"
                  error={errors.email}
                  onChange={value => this.handleEmailChange(value)}
                />
                <TextField
                  label="パスワード（必須）"
                  value={password}
                  example=""
                  type="password"
                  error={errors.password}
                  onChange={value => this.handlePasswordChange(value)}
                />
                <TextField
                  label="電話番号（必須）"
                  value={tel}
                  example="例：0312345678"
                  error={errors.tel}
                  onChange={value => this.handleTelChange(value)}
                />
                <p className={`${classBase}__terms-link`}>
                  <input type="checkbox" onClick={() => this.changePrivacyCheck()} />
                  <a href="http://biztra.cloud/etc.html" target="_blank" rel="noreferrer noopener">
                    利用規約・個人情報の取扱いについて
                  </a>
                  に同意の上、ご登録ください
                </p>
                {submitting ? (
                  <SimpleLoading />
                ) : succeed ? (
                  <div className="gray">
                    仮登録完了のメールを送信しました。
                    <br />
                    メールの内容に従って本登録を行ってください。
                  </div>
                ) : (
                  <input type="submit" value="登録" disabled={!isPrivacyChecked} />
                )}
                {submitError && <div className="error">{submitError}</div>}
              </form>
            )}
          </div>
          <div className={`${classBase}__footer`}>
            <Link to="/biztra/users/sign_in">すでにアカウントをお持ちの方はこちら</Link>
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default ExpensesUsersSignup;
