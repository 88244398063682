import moment from 'moment';
import type { Moment } from 'moment';

export type SearchTrackingLogStatus = 'running' | 'success' | 'failed';

export const SEARCH_TRACKING_LOG_STATUS_LABELS: Record<SearchTrackingLogStatus, string> = {
  running: '稼働中',
  success: '成功',
  failed: '失敗'
};

export const SEARCH_TRACKING_PATH_LABELS: Record<string, string> = {
  search_histories__create: '検索履歴作成', // 1
  shortdistance__index: '近距離検索', // 2
  select__index: '検索結果', // 3
  select__create: '検索結果', // 4
  places__show: 'LatLng取得', // 5
  hotels__index: 'ホテル検索', // 6
  rakuten_packages__index: '楽天パッケージ検索', // 7
  jr_packages__index: 'JRパッケージ検索', // 8
  transit__v1: '経路検索（レガシー）', // 9
  flights__index: '海外航空券検索', // 10
  transit__v2: '経路検索（新版）', // 11
  transit__shortdistance: '近距離乗換検索', // 12
  reserve_confirm__create: '予約確認作成', // 13
  reserve_confirm__index: '予約確認', // 14
  reserve_confirm__show: '予約確認詳細', // 15
  payments__token: 'カードトークン作成', // 16
  payments__card_seq: 'カードシーケンス作成', // 17
  payments__billing: '手配予約' // 18
};

export interface SearchTrackingLogArgs {
  id: number;
  search_tracking_id: number;
  trip_id?: number;
  search_query_id?: number;
  search_query_item_id?: number;
  status: SearchTrackingLogStatus;
  path: string;
  repository?: string;
  origin?: string;
  destination?: string;
  origin_latitude?: number;
  origin_longitude?: number;
  destination_latitude?: number;
  destination_longitude?: number;
  created_at: string;
  bugsnag_link?: string;
  amadeus_log_id?: number;
  amadeus_request_log?: string;
  amadeus_response_log?: string;
  search_query?: {
    id: number;
    search_type: string;
  };
  query?: any;
  result?: any;
  es_ids?: any;
}

class SearchTrackingLog {
  id: number;

  searchTrackingId: number;

  tripId: number | null;

  searchQueryId: number | null;

  searchQueryItemId: number | null;

  status: SearchTrackingLogStatus;

  searchType: string | null;

  path: string;

  action: string;

  repository: string | null;

  origin: string | null;

  destination: string | null;

  originLatitude: number | null;

  originLongitude: number | null;

  destinationLatitude: number | null;

  destinationLongitude: number | null;

  createdAt: Moment;

  bugsnagLink: string | null;

  amadeusLogId: number | null;

  amadeusRequestLog: string | null;

  amadeusResponseLog: string | null;

  query: any | null;

  result: any | null;

  esIds: any | null;

  constructor(args: SearchTrackingLogArgs) {
    this.id = args.id;
    this.searchTrackingId = args.search_tracking_id;
    this.tripId = args.trip_id || null;
    this.searchQueryId = args.search_query_id || null;
    this.searchQueryItemId = args.search_query_item_id || null;
    this.status = args.status;
    this.searchType = args.search_query?.search_type || null;
    this.path = args.path;
    this.action = args.path.split('__').join('#');
    this.repository = args.repository || null;
    this.origin = args.origin || null;
    this.destination = args.destination || null;
    this.originLatitude = args.origin_latitude || null;
    this.originLongitude = args.origin_longitude || null;
    this.destinationLatitude = args.destination_latitude || null;
    this.destinationLongitude = args.destination_longitude || null;
    this.createdAt = moment(args.created_at);
    this.bugsnagLink = args.bugsnag_link || null;
    this.amadeusLogId = args.amadeus_log_id || null;
    this.amadeusRequestLog = args.amadeus_request_log || null;
    this.amadeusResponseLog = args.amadeus_response_log || null;
    this.query = args.query || null;
    this.result = args.result || null;
    this.esIds = args.es_ids || null;
  }
}

export default SearchTrackingLog;
