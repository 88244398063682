import _ from 'lodash';
import { observable, action } from 'mobx';
import type { GodArgs } from './god';
import God from './god';

class GodList {
  @observable
  public list: God[];

  constructor(raw: GodArgs[]) {
    this.list = raw.map(god => new God(god));
  }

  find(id?: number) {
    if (!id) return undefined;
    return _.find(this.list, god => god.id === id);
  }

  @action
  push(god: God) {
    this.list.push(god);
  }

  @action
  remove(god: God) {
    const i = this.list.indexOf(god);
    if (i > -1) this.list.splice(i, 1);
  }

  @action
  update(newGod: God) {
    const god = this.find(newGod.id);
    if (god) _.assign(god, newGod);
  }
}

export default GodList;
