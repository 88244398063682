import * as React from 'react';
import { Link } from 'react-router-dom';

import Profile from '@this/shared/header/profile';
import { Badge } from '@material-ui/core';
import type User from '../../../domain/user/user';
import { styled } from '../../constants/themes';
import { CheckIcon, GraphIcon, headerHeight, ListIcon, SearchIcon } from './header.style';

interface Props {
  user: User;
  appliedCount: number;
  className?: string;
}

const NavContantSm: React.FC<Props> = ({ user, className, appliedCount }) => {
  return (
    <Nav className={className}>
      <Profile user={user} />
      <NavItems>
        <NavItem>
          <NavLink to="/dashboard">
            <SearchIcon />
            検索・予約
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/trips">
            <ListIcon />
            旅程・履歴
          </NavLink>
        </NavItem>
        {user.organizationId && (
          <>
            <NavItem>
              <StyledBudge badgeContent={appliedCount} color="error" max={9} invisible={!appliedCount}>
                <NavLink to="/application/in_advance">
                  <CheckIcon />
                  承認
                </NavLink>
              </StyledBudge>
            </NavItem>
            {user.isOrganizationAdmin && (
              <NavItem>
                <NavLink to="/organization">
                  <GraphIcon />
                  管理・分析
                </NavLink>
              </NavItem>
            )}
          </>
        )}
      </NavItems>
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: ${headerHeight};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2b2b2b;
  padding: 40px;
  z-index: 1000;
`;

const NavItems = styled.ul`
  margin-top: 20px;
`;

const NavItem = styled.li`
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
  border-bottom: 1px solid #606060;
`;

const NavLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  color: #fff;

  ${SearchIcon}, ${ListIcon}, ${CheckIcon}, ${GraphIcon} {
    font-size: 30px;
    margin-right: 20px;
    vertical-align: middle;
  }
`;

const StyledBudge = styled(Badge)`
  .MuiBadge-colorError {
    background-color: ${props => props.theme.redColor};
  }
`;

export default NavContantSm;
