import React, { useState } from 'react';
import ArrangementArrangerSelector from '../shared/arrangement_arranger_selector';

const getDefaultArrangerIds = (): number[] => {
  const arrangerIds = utils.getParam('arranger_ids');
  if (!arrangerIds) {
    return [];
  }
  return arrangerIds.split(',').map((id: string) => parseInt(id, 10));
};

const useArrangerSelector = (): [number[], () => JSX.Element] => {
  const [arrangerIds, setArrangerIds] = useState<number[]>(getDefaultArrangerIds());

  const renderArrangerSelector = () => (
    <ArrangementArrangerSelector selectedArrangerIds={arrangerIds} onSelect={ids => setArrangerIds(ids)} />
  );

  return [arrangerIds, renderArrangerSelector];
};

export default useArrangerSelector;
