import React from 'react';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import type { ImageFile } from 'react-dropzone';
import Dropzone from 'react-dropzone';

interface Props {
  preview?: string;
  onSelect: (f: ImageFile) => void;
  onRemove: () => void;
}

const ApproveItemDropzone = observer(({ preview, onSelect, onRemove }: Props) => {
  return (
    <Wrapper>
      {preview ? (
        <PreviewWrapper>
          <PreviewArea>{preview}</PreviewArea>
          <div>
            <RemoveButton onClick={() => onRemove()}>×</RemoveButton>
          </div>
        </PreviewWrapper>
      ) : (
        <StyledDropzone onDrop={(files: ImageFile[]) => onSelect(files[0])}>
          <p>
            ここへファイルをドラッグ&amp;ドロップ
            <br />
            もしくは
            <br />
            クリックしてファイルを指定
          </p>
        </StyledDropzone>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  margin-bottom: 0;
`;

const StyledDropzone = styled(Dropzone)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  background: ${props => props.theme.accentColorLight};
  border: 1px dashed #ccc;
  margin: 2px 0;
  cursor: pointer;
  padding: 20px;
`;

const PreviewWrapper = styled.div`
  display: flex;
`;

const PreviewArea = styled.div`
  margin: auto 0;
`;

const RemoveButton = styled.a`
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};
  margin-left: 10px;
`;

export default ApproveItemDropzone;
