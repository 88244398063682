import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '@this/shared/error_boundary/error_boundary';
import Expenses from '@this/components/expenses/expenses';
import { createTheme } from '@this/constants/themes';
import { AITThemeProvider } from '@this/shared/ui/theme';

interface Props {
  env: string;
}

export const BiztraRoot: React.FC<Props> = props => {
  const theme = createTheme();

  return (
    <ErrorBoundary>
      <AITThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/biztra" render={routeProps => <Expenses {...routeProps} {...props} />} />
          </Switch>
        </BrowserRouter>
      </AITThemeProvider>
    </ErrorBoundary>
  );
};
