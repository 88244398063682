import { styled } from '@this/constants/themes';

export const MappingFormWrapper = styled.section`
  display: flex;
  margin-top: 5px;
`;

export const MappingFormLabel = styled.p`
  font-weight: bold;
`;

export const MappingFormDetailArea = styled.div``;

export const MappingFormDetailIndentedArea = styled.div`
  margin-left: 16px;
  margin-top: 8px;
`;

export const MappingFormDetailItemArea = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

export const MappingFormDetailSelectArea = styled.div`
  width: 240px;
`;

export const MappingFormDetailLinkArea = styled.div`
  font-weight: bold;
  margin-left: 32px;
`;
