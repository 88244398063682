export interface YamatoShippingCostArgs {
  id: number;
  shop_code: string;
  customer_code: string;
  category_code: string | null;
  reception_date: string;
  registration_no: string;
  item_category1: string;
  item_category2: string;
  size: string;
  quantity: number;
  shop_prefecture: string;
  shop_city: string;
  fare_total: number;
  fare: number;
  disbursement: number;
  preminum: number;
  consumption_tax: number;
  reduced_tax_eligibility: string | null;
  csv_import_action_item_id: number;
}

export default class YamatoShippingCost {
  id: number;

  shopCode: string;

  customerCode: string;

  categoryCode: string | null;

  receptionDate: string;

  registrationNo: string;

  itemCategory1: string;

  itemCategory2: string;

  size: string;

  quantity: number;

  shopPrefecture: string;

  shopCity: string;

  fareTotal: number;

  fare: number;

  disbursement: number;

  preminum: number;

  consumptionTax: number;

  reducedTaxEligibility: string | null;

  csvImportActionItemId: number;

  constructor(args: YamatoShippingCostArgs) {
    this.id = args.id;
    this.shopCode = args.shop_code;
    this.customerCode = args.customer_code;
    this.categoryCode = args.category_code;
    this.receptionDate = args.reception_date;
    this.registrationNo = args.registration_no;
    this.itemCategory1 = args.item_category1;
    this.itemCategory2 = args.item_category2;
    this.size = args.size;
    this.quantity = args.quantity;
    this.shopPrefecture = args.shop_prefecture;
    this.shopCity = args.shop_city;
    this.fareTotal = args.fare_total;
    this.fare = args.fare;
    this.disbursement = args.disbursement;
    this.preminum = args.preminum;
    this.consumptionTax = args.consumption_tax;
    this.reducedTaxEligibility = args.reduced_tax_eligibility;
    this.csvImportActionItemId = args.csv_import_action_item_id;
  }

  get trackingNumber() {
    return this.registrationNo.replace(/-/g, '');
  }
}
