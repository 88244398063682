import React from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { MarketLogArgs } from '@this/domain/market_log/market_log';
import MarketLog from '@this/domain/market_log/market_log';
import type { DomesticAirMarketLogArgs } from '@this/domain/market_log/domestic_air_market_log';
import DomesticAirMarketLog from '@this/domain/market_log/domestic_air_market_log';
import type { FlightMarketLogJson } from '@this/domain/market_log/flight_market_log';
import FlightMarketLog from '@this/domain/market_log/flight_market_log';
import type { TabikoboAviationJson } from '@this/domain/market_log/tabikobo_aviation';
import TabikoboAviation from '@this/domain/market_log/tabikobo_aviation';

import MarketLogTemplate from '@this/components/market_log/market_log.template';
import DomesticAirMarketLogTemplate from '@this/components/market_log/domestic_air_market_log.template';
import FlightMarketLogTemplate from '@this/components/market_log/flight_market_log.template';

interface MarketLogResponse {
  market_log: MarketLogArgs | DomesticAirMarketLogArgs | FlightMarketLogJson;
  yesterday_market_log: FlightMarketLogJson;
  tomorrow_market_log: FlightMarketLogJson;

  white_carrier_ids: string[];
  white_carrier_names: string[];
  white_carrier_market_logs: FlightMarketLogJson[][];

  tabikobo_aviations: TabikoboAviationJson[];

  available: boolean;
}

interface Props {
  theme: {
    serviceName: string;
    marketLogLogoPath: string;
  };
  match: {
    params: {
      id: string;
    };
  };
  serviceId: number;
}

interface State {
  id: string;
  element_type: 'hotel' | 'domestic_air' | 'transport';
  loading: boolean;

  marketLog: MarketLog | DomesticAirMarketLog | FlightMarketLog | null;
  yesterdayMarketLog: FlightMarketLog | null;
  tomorrowMarketLog: FlightMarketLog | null;

  whiteCarrierIds: string[];
  whiteCarrierNames: string[];
  whiteCarrierMarketLogs: FlightMarketLog[][] | null;

  tabikoboAviations: TabikoboAviation[] | null;

  available: boolean;
}

class ReserveConfirmMarketLogClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      element_type: utils.getParam('element_type') || '',
      loading: true,

      marketLog: null,
      yesterdayMarketLog: null,
      tomorrowMarketLog: null,

      whiteCarrierIds: [],
      whiteCarrierNames: [],
      whiteCarrierMarketLogs: null,

      tabikoboAviations: null,

      available: false
    };
  }

  componentDidMount() {
    this.fetchMarketLog();
  }

  async fetchMarketLog() {
    const { id, element_type } = this.state;
    this.setState({ loading: true });
    try {
      const result = await utils.jsonPromise<MarketLogResponse>(`/market_logs/${id}.json`, { element_type });
      if (element_type === 'transport') {
        const todayLog = new FlightMarketLog(result.market_log as FlightMarketLogJson);
        const yesterdayLog = new FlightMarketLog(result.yesterday_market_log as FlightMarketLogJson);
        const tomorrowLog = new FlightMarketLog(result.tomorrow_market_log as FlightMarketLogJson);
        const whiteCarrierIds = result.white_carrier_ids;
        const whiteCarrierNames = result.white_carrier_names;
        const whiteCarrierLogs = result.white_carrier_market_logs.map(logs =>
          logs.map(log => new FlightMarketLog(log))
        );

        const tabikoboAviations = result.tabikobo_aviations.map(aviation => new TabikoboAviation(aviation));

        this.setState({
          marketLog: todayLog,
          yesterdayMarketLog: yesterdayLog,
          tomorrowMarketLog: tomorrowLog,

          whiteCarrierIds,
          whiteCarrierNames,
          whiteCarrierMarketLogs: whiteCarrierLogs,

          tabikoboAviations,

          available: result.available
        });
      } else {
        const log =
          element_type === 'hotel'
            ? new MarketLog(result.market_log as MarketLogArgs)
            : new DomesticAirMarketLog(result.market_log as DomesticAirMarketLogArgs);
        this.setState({ marketLog: log, available: result.available });
      }
    } catch (e) {
      utils.sendErrorObject(e);
    }
    this.setState({ loading: false });
  }

  render() {
    try {
      const { serviceName, marketLogLogoPath } = this.props.theme;
      const { serviceId } = this.props;
      const {
        element_type,
        loading,

        marketLog,
        yesterdayMarketLog,
        tomorrowMarketLog,

        whiteCarrierIds,
        whiteCarrierNames,
        whiteCarrierMarketLogs,

        tabikoboAviations,

        available
      } = this.state;
      return (
        <div className="market-log-wrapper">
          <MarketLogContent>
            <MarketLogHeader>
              <MarketLogHeaderLogo>
                <img src={marketLogLogoPath} alt={serviceName} />
              </MarketLogHeaderLogo>
            </MarketLogHeader>
            {loading ? (
              <SimpleLoading />
            ) : marketLog ? (
              <>
                {element_type === 'hotel' ? (
                  <MarketLogTemplate marketLog={marketLog as MarketLog} available={available} />
                ) : element_type === 'domestic_air' ? (
                  <DomesticAirMarketLogTemplate
                    marketLog={marketLog as DomesticAirMarketLog}
                    available={available}
                  />
                ) : (
                  <FlightMarketLogTemplate
                    marketLog={marketLog as FlightMarketLog}
                    yesterdayMarketLog={yesterdayMarketLog as FlightMarketLog}
                    tomorrowMarketLog={tomorrowMarketLog as FlightMarketLog}
                    whiteCarrierIds={whiteCarrierIds}
                    whiteCarrierNames={whiteCarrierNames}
                    whiteCarrierMarketLogs={whiteCarrierMarketLogs as FlightMarketLog[][]}
                    tabikoboAviations={tabikoboAviations as TabikoboAviation[]}
                    serviceId={serviceId}
                    available={available}
                  />
                )}
              </>
            ) : (
              <p>マーケットログが見つかりませんでした</p>
            )}
          </MarketLogContent>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const MarketLogContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 30px;
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
`;

const MarketLogHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MarketLogHeaderLogo = styled.div`
  width: 144px;
`;

export default withTheme(ReserveConfirmMarketLogClass);
