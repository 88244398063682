import _ from 'lodash';
import type { LegJson, LegSummaryJson, SegmentJson } from '../select_repository';

class Segment {
  public legs: LegJson[];

  public leg_summaries: LegSummaryJson[];

  // private old_price: number;

  // private price: number;

  public changeable_price: number;

  public unchangeable_price: number;

  public type: 'air' | 'shin' | 'express' | 'evo_air';

  private boarding: null;

  private arrival: null;

  public distance: null;

  public routeTime: null;

  public changeable_seat_type: string | null;

  public unchangeable_seat_type: string | null;

  public changeable_available_seat_count: string | null;

  public unchangeable_available_seat_count: string | null;

  private showSelectTransportBox: boolean;

  constructor(args: SegmentJson) {
    this.legs = args.legs;
    this.leg_summaries = args.leg_summaries;
    // this.old_price = args.old_price;
    // this.price = args.price;
    this.changeable_price = args.changeable_price;
    this.unchangeable_price = args.unchangeable_price;
    this.type = args.type;
    this.boarding = args.boarding;
    this.arrival = args.arrival;
    this.distance = args.distance;
    this.routeTime = args.route_time;
    this.changeable_seat_type = args.changeable_seat_type;
    this.unchangeable_seat_type = args.unchangeable_seat_type;
    this.changeable_available_seat_count = args.changeable_available_seat_count;
    this.unchangeable_available_seat_count = args.unchangeable_available_seat_count;
    this.showSelectTransportBox = false;
  }

  firstLeg() {
    return _.first(this.legs);
  }

  lastLeg() {
    return _.last(this.legs);
  }

  getLeg(index: number) {
    return this.legs[index];
  }

  firstLegName(): string {
    return utils.dig(this.firstLeg(), 'name');
  }

  lastLegName(): string {
    return utils.dig(this.lastLeg(), 'name');
  }

  firstLegFromName(): string {
    return utils.dig(this.firstLeg(), 'from', 'name');
  }

  firstLegFromTime(): string {
    return utils.dig(this.firstLeg(), 'from', 'time');
  }

  lastLegToName(): string {
    return utils.dig(this.lastLeg(), 'to', 'name');
  }

  lastLegToTime(): string {
    return utils.dig(this.lastLeg(), 'to', 'time');
  }

  getLegName(index: number): string {
    return utils.dig(this.getLeg(index), 'name');
  }

  boardingName(): never {
    return utils.dig(this.boarding, 'name') as never;
  }

  arrivalName(): never {
    return utils.dig(this.arrival, 'name') as never;
  }

  boardingTime(): string {
    return utils.dig(this.boarding, 'time');
  }

  arrivalTime(): string {
    return utils.dig(this.arrival, 'time');
  }

  shouldShowBoardingStation(): boolean {
    return this.boardingName() && this.boardingName() !== this.firstLegFromName();
  }

  shouldShowArrivalStation(): boolean {
    return this.arrivalName() && this.arrivalName() !== this.lastLegToName();
  }

  imagePath(): string {
    const legname = this.firstLegName().replace('ＪＲ', 'JR');
    return utils.transitIconPath(legname);
  }

  imagePathByIndex(index: number): string {
    const legname = this.getLegName(index).replace('ＪＲ', 'JR');
    return utils.transitIconPath(legname);
  }

  static imagePathByName(name: string | null): string {
    if (name) {
      const legname = name.replace('ＪＲ', 'JR');
      return utils.transitIconPath(legname);
    }
    return '';
  }

  isAir(): boolean {
    return this.type === 'air' || this.type === 'evo_air';
  }

  get hasConnectingAir(): boolean {
    const airLegSummary = this.leg_summaries?.find(ls => ls.type === 'air');
    const hasMultiAirLegs = !!airLegSummary && airLegSummary.indexes.length > 1;
    const hasConnectingAirLegSummary = !!airLegSummary?.name && airLegSummary.name.split('/').length > 1;
    const hasConnectingAirLeg = this.legs.some(
      leg => leg.name.includes('乗継便') || leg.name.split('/').length > 1
    );
    return this.isAir() && (hasMultiAirLegs || hasConnectingAirLegSummary || hasConnectingAirLeg);
  }

  changeableSeatTypeText(): string | null {
    return Segment.seatTypeText(this.changeable_seat_type);
  }

  unchangeableSeatTypeText(): string | null {
    return Segment.seatTypeText(this.unchangeable_seat_type);
  }

  isUseSelectTransportBox() {
    return this.showSelectTransportBox;
  }

  useSelectTransportBox() {
    this.showSelectTransportBox = true;
  }

  resetUseSelectTransportBox() {
    this.showSelectTransportBox = false;
  }

  static seatTypeText(seatType: string | null): string | null {
    if (seatType !== null) {
      switch (seatType) {
        case 'n':
          return '※エコノミー';
        case 'p':
          return '※プレミアム';
        case 'j':
          return '※ビジネス';
        case 'f':
          return '※ファースト';
        default:
          return `※${seatType}`;
      }
    }
    return '';
  }

  changeableSeatCountText(): string | null {
    return Segment.seatCountText(this.changeable_available_seat_count);
  }

  unchangeableSeatCountText(): string | null {
    return Segment.seatCountText(this.unchangeable_available_seat_count);
  }

  static seatCountText(seatCount: string | null): string | null {
    if (seatCount !== null) {
      if (seatCount === '○' || Number(seatCount) >= 10) {
        return '残り10席以上';
      }
      return `残り${seatCount}席`;
    }
    return '';
  }
}

export default Segment;
