import React from 'react';
import styled from 'styled-components';
import { getTypogragy } from '@this/shared/ui/theme';

export const HEADING_LEVEL = {
  '1': '1',
  '2': '2',
  '3': '3'
} as const;
export type HeadingLevel = typeof HEADING_LEVEL[keyof typeof HEADING_LEVEL];

export type HeadingProps = {
  className?: string;
  children: React.ReactNode;
  level: HeadingLevel;
};

export const Heading = React.forwardRef<any, HeadingProps>(({ className, children, level }, ref) => {
  return (
    <Root className={className} ref={ref} $level={level} as={`h${level}` as 'h1' | 'h2' | 'h3'}>
      {children}
    </Root>
  );
});

const Root = styled.h1<{ $level: HeadingLevel }>`
  font-size: ${props =>
    getTypogragy('fontSizes', `heading${props.$level}` as 'heading1' | 'heading2' | 'heading3')}px;
  line-height: ${getTypogragy('lineHeight', 'heading')};
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;
