import { observable } from 'mobx';

export interface InvoiceStandardFieldArgs {
  id: number;
  name: string;
  display_name?: string;
}

class InvoiceStandardField {
  @observable
  id: number;

  @observable
  name: string;

  @observable
  displayName?: string;

  constructor(args: InvoiceStandardFieldArgs) {
    this.id = args.id;
    this.name = args.name;
    this.displayName = args.display_name;
  }
}

export default InvoiceStandardField;
