import type React from 'react';
import { useMemo } from 'react';
import { useTheme } from '@material-ui/core';
import type { Moment } from 'moment';

export const usePopupStyle = () => {
  const theme = useTheme();
  return useMemo<React.CSSProperties>(
    () => ({ position: 'absolute', zIndex: theme.zIndex.modal }),
    [theme.zIndex.modal]
  );
};

export const disabledDate = (
  date: Moment,
  { min, max, type = 'date' }: { min?: Date; max?: Date; type?: 'date' | 'datetime-local' }
) => {
  const granularity = type === 'date' ? 'd' : 'm';

  if (max && min) {
    return !date.isBetween(min, max, granularity, '[]');
  }

  if (max) {
    return !date.isSameOrBefore(max, granularity);
  }

  if (min) {
    return !date.isSameOrAfter(min, granularity);
  }

  return false;
};
