/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import { withTheme } from 'styled-components';
import { styled } from '@this/constants/themes';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Trip from '@this/domain/trip/trip';
import type OrderItem from '@this/domain/order_item';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import type { OrderItemStatusKey } from '@this/domain/order_item/order_item_status';
import { OrderItemStatusAvailable } from '@this/domain/order_item/order_item_status';
import type {
  OrderItemSnoozeTodoCreationParams,
  OrderItemSnoozeTodoCancelParams
} from '@this/components/arrangement/todo_list/types';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type MessageTodo from '@this/src/domain/arrangement/message_todo';
import { Fetcher } from '@this/src/util';
import OrderItemCard from './order_item_card/order_item_card';
import OrderItemStatusSelector from './order_item_status_selector';
import MessageTodoRow from './message_todo_row';

interface Props {
  theme: { serviceName: string };
}

interface Response {
  trips: object[];
  user_messages: MessageTodo[];
  next_business_day: string;
}

// default sort AITRAVEL-3073
interface TripWithTag {
  trip: Trip;
  priority: number;
  date: DateForDefaultSort;
}

enum SelectedSortKey {
  DefaultSortOrder,
  StartAt,
  OrderAt
}

enum StartAtSortKey {
  ASCENDING,
  DESCENDING
}

enum OrderAtSortKey {
  ASCENDING,
  DESCENDING
}

enum DateForDefaultSort {
  TODAY,
  TOMORROW_OR_DAY_AFTER_TOMORROW,
  AFTER_MORE_THAN_THREE_DAYS,
  BEFORE_TODAY
}

interface OrderItemTodoListCategories {
  [key: string]: string;
}

// categories for AITRAVEL-3074 & 3075
export const orderItemTodoListCategories: OrderItemTodoListCategories = {
  message: 'メッセージ',
  hotel: 'ホテル',
  domestic_hotel: '国内ホテル',
  foreign_hotel: '海外ホテル',
  domestic_air: '国内航空券',
  foreign_air: '海外航空券',
  shinkansen: '新幹線',
  exic: 'EX-IC',
  express: '特急',
  rental_car: 'レンタカー',
  air_package: '航空券パッケージ',
  railway_package: '新幹線パッケージ',
  shipping: '配送料',
  wifi: 'WiFi',
  room: '会議室',
  bus: 'バス',
  arrangement_request: 'リクエストフォーム',
  cancel: 'キャンセル'
};

export const orderItemRailwayTicketType = {
  normal: 0,
  kyuusyuu: 1,
  bulk_ticket: 2,
  exic: 3
};

type WaitingType = 'all' | 'true' | 'false';

type SpType = 'all' | 'true' | 'false';

const getDefaultJobTypes = (): OrderItemJobTypeKey[] => {
  const searchParams = new URLSearchParams(window.location.search);
  return (searchParams
    .get('order_item_job_types')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || OrderItemJobTypeNumKeys) as OrderItemJobTypeKey[];
};

const OrderItemTodoListLegacy = observer(({ theme }: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params = utils.getParams();
  const [selectedSortKey, setSelectedSortKey] = useState<SelectedSortKey>(
    params.selected_sort_key ? parseInt(params.selected_sort_key, 10) : SelectedSortKey.DefaultSortOrder
  );
  const [startAtSortKey, setStartAtSortKey] = useState<StartAtSortKey>(
    params.start_at_sort_key ? parseInt(params.start_at_sort_key, 10) : StartAtSortKey.ASCENDING
  );
  const [orderAtSortKey, setOrderAtSortKey] = useState<OrderAtSortKey>(
    params.order_at_sort_key ? parseInt(params.order_at_sort_key, 10) : OrderAtSortKey.ASCENDING
  );
  const [currentOrderItemsCategory, setCurrentOrderItemsCategory] = useState<string[]>(
    params.order_items_category ? decodeURIComponent(params.order_items_category).split(',') : ['']
  );
  const [waiting, setWaiting] = useState<WaitingType>('all');
  const [currentOrderItemsSnoozed, setCurrentOrderItemsSnoozed] = useState<boolean>(false);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [filteredOrderItems, setFilteredOrderItems] = useState<OrderItem[]>([]);
  const [messageTodos, setMessageTodos] = useState<MessageTodo[]>([]);
  const [editingOrderItemIds, setEditingOrderItemIds] = useState<Set<number>>(new Set<number>());
  const [selectedOrderItemJobTypes, setSelectedOrderItemJobTypes] = useState<OrderItemJobTypeKey[]>(
    getDefaultJobTypes()
  );
  const [selectedOrderItemStatuses, setSelectedOrderItemStatuses] = useState<OrderItemStatusKey[]>(
    params.order_item_statuses
      ? params.order_item_statuses.map((s: string) => parseInt(s, 10))
      : OrderItemStatusAvailable.map(key => key)
  );
  const [organizationNames, setOrganizationNames] = useState<string[]>([]);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState<string>(
    params.organization_name ? params.organization_name : 'all'
  );
  const [selectedSp, setSelectedSp] = useState<SpType>(params.sp ? params.sp : 'all');

  const sortTripsByOrderAt = (a: Trip, b: Trip) => {
    const key = orderAtSortKey;
    if (!a.receivedAt || !b.receivedAt) {
      return 1;
    }

    if (key === OrderAtSortKey.DESCENDING) {
      return b.receivedAt.unix() - a.receivedAt.unix();
    }

    return a.receivedAt.unix() - b.receivedAt.unix();
  };

  const sortTripsByStartAt = (a: Trip, b: Trip) => {
    const key = startAtSortKey;
    if (!a.startTime || !b.startTime) {
      return 1;
    }

    if (key === StartAtSortKey.DESCENDING) {
      return b.startTime.unix() - a.startTime.unix();
    }

    return a.startTime.unix() - b.startTime.unix();
  };

  const sortFunc = () => {
    switch (selectedSortKey) {
      case SelectedSortKey.OrderAt:
        return sortTripsByOrderAt;
      case SelectedSortKey.StartAt:
        return sortTripsByStartAt;
      default:
        return sortTripsByOrderAt;
    }
  };

  const getPriorityTagForDefaultSort = (trip: Trip): number => {
    // priority of default sort. 0 is highest. 255 is unknown.
    // priority 1: REQUESTED, 0: PAID, 2: FIXED, 3: CANCELED or CANCELING, 4: DONE
    const priorityTags = [1, 255, 255, 0, 2, 3, 3, 4, 255, 255, 255];

    // must specify null check
    return trip.status !== undefined && trip.status !== null && trip.status >= 0 && trip.status <= 7
      ? priorityTags[trip.status]
      : 255;
  };

  const getDateForDefaultSort = (trip: Trip): DateForDefaultSort => {
    const today = moment(new Date());
    const tomorrow = moment(new Date()).add(1, 'day');
    const dayAfterTomorrow = moment(new Date()).add(2, 'days');
    const startAt = moment(trip.startTime);

    if (startAt.isSame(today, 'date')) {
      return DateForDefaultSort.TODAY;
    }
    if (startAt.isSame(tomorrow, 'date') || startAt.isSame(dayAfterTomorrow, 'date')) {
      return DateForDefaultSort.TOMORROW_OR_DAY_AFTER_TOMORROW;
    }
    if (startAt.isAfter(dayAfterTomorrow, 'date')) {
      return DateForDefaultSort.AFTER_MORE_THAN_THREE_DAYS;
    }

    return DateForDefaultSort.BEFORE_TODAY;
  };

  // 日付の昇順にソート
  const defaultSortByStartAt = () => (a: TripWithTag, b: TripWithTag) => {
    if (!a.trip.startTime || !b.trip.startTime) {
      return 1;
    }

    // 出発日の昇順で並べ替え
    const startDateOfA = moment(a.trip.startTime);
    const startDateOfB = moment(b.trip.startTime);
    const res = startDateOfA.isBefore(startDateOfB, 'date')
      ? -1
      : startDateOfA.isAfter(startDateOfB, 'date')
      ? 1
      : 0;
    return res;
  };

  // ステータスでソート
  const defaultSortByStatus = () => (a: TripWithTag, b: TripWithTag) => {
    return a.priority - b.priority;
  };

  // todo list の item order ごとの標準ソート
  const defaultSortFunction = (trips: Trip[]): Trip[] => {
    // tagging
    const tripsWithTag = trips.map((t, id) => {
      const trip = new Trip(t); // ここの new は不要かもしれない？
      const priority = getPriorityTagForDefaultSort(trip);
      const date = getDateForDefaultSort(trip);
      return { trip, priority, date } as TripWithTag;
    });
    tripsWithTag.sort(defaultSortByStartAt());

    // trip.startTimeがキャンセルでなく{当日(A) 明日・翌営業日(B)}とそれ以外(C)に分割
    const groupA = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.TODAY && tripWithTag.priority < 3) {
        return true;
      }
      return false;
    });

    const groupB = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.TOMORROW_OR_DAY_AFTER_TOMORROW && tripWithTag.priority < 3) {
        return true;
      }
      return false;
    });

    const groupC0 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.AFTER_MORE_THAN_THREE_DAYS && tripWithTag.priority === 0) {
        return true;
      }
      return false;
    });
    const groupC1 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.AFTER_MORE_THAN_THREE_DAYS && tripWithTag.priority === 1) {
        return true;
      }
      return false;
    });
    const groupC2 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.AFTER_MORE_THAN_THREE_DAYS && tripWithTag.priority === 2) {
        return true;
      }
      return false;
    });

    const groupD0 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.BEFORE_TODAY && tripWithTag.priority === 0) {
        return true;
      }
      return false;
    });
    const groupD1 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.BEFORE_TODAY && tripWithTag.priority === 1) {
        return true;
      }
      return false;
    });
    const groupD2 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.date === DateForDefaultSort.BEFORE_TODAY && tripWithTag.priority === 2) {
        return true;
      }
      return false;
    });
    const groupD3 = tripsWithTag.filter((tripWithTag, index, array) => {
      if (tripWithTag.priority === 3) {
        return true;
      }
      return false;
    });

    // ソートして連結
    groupA.sort(defaultSortByStatus());
    groupB.sort(defaultSortByStatus()).sort(defaultSortByStartAt());
    const sortedTripsWithTag = groupA.concat(
      groupB,
      groupC0,
      groupD0,
      groupC1,
      groupD1,
      groupC2,
      groupD2,
      groupD3
    );

    // ソート結果からtripのみ抽出してリスト化
    const sortedTrips = sortedTripsWithTag.map(tripWithTag => {
      return tripWithTag.trip;
    });
    return sortedTrips;
  };

  const fetchOrderItems = () => {
    setIsLoading(true);

    Fetcher.get<Response>('/arrangement/order_item_todos/legacy_index.json')
      .then(result => {
        const trips =
          selectedSortKey === SelectedSortKey.DefaultSortOrder
            ? defaultSortFunction(result.trips.map(raw => new Trip(raw)))
            : result.trips.map(raw => new Trip(raw)).sort(sortFunc());
        setTrips(trips);
        setMessageTodos(result.user_messages);
        const onames = _.compact(_.uniq(trips.map(t => t.user.organization.name)));
        setOrganizationNames(onames);
      })
      .finally(() => setIsLoading(false))
      .catch(() => {});
  };

  useEffect(() => {
    fetchOrderItems();
    setSelectedSortKey(SelectedSortKey.DefaultSortOrder);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', 'ORDER_ITEM_LEGACY');
    history.push(`/arrangement/todo_list?${searchParams.toString()}`);
  }, []);

  useEffect(() => {
    const orderItems: OrderItem[] = [];
    const sortedTrips =
      selectedSortKey === SelectedSortKey.DefaultSortOrder ? defaultSortFunction(trips) : trips.sort(sortFunc());
    sortedTrips.forEach(t => {
      t.order.orderItems.forEach(orderItem => {
        if (orderItem.statusStr === '完了' || orderItem.statusStr === 'キャンセル' || orderItem.deletedAt) {
          return;
        }
        orderItems.push(orderItem);
      });
    });
    setOrderItems(orderItems);
  }, [trips, selectedSortKey, startAtSortKey, orderAtSortKey]);

  useEffect(() => {
    const ois: OrderItem[] = orderItems.filter(orderItem => {
      if ((waiting === 'true' && !orderItem.waiting) || (waiting === 'false' && orderItem.waiting)) {
        return false;
      }
      let matched = true;
      matched = matched && selectedOrderItemStatuses.includes(orderItem.status as OrderItemStatusKey);
      if (orderItem.jobType !== null && orderItem.jobType !== undefined) {
        matched = matched && selectedOrderItemJobTypes.includes(orderItem.jobType);
      }
      if (currentOrderItemsCategory[0] !== '') {
        const categories = currentOrderItemsCategory;
        let categoryMatched = true;
        for (let i = 0; i < categories.length; i += 1) {
          // EX-IC のフィルタリングは orderItemCategory が shinkansen で railwayTicketType が exic なら真
          if (categories[i] === 'exic') {
            categoryMatched =
              orderItem.orderItemCategory === 'shinkansen' &&
              orderItem.transports[0].railwayTicketType === orderItemRailwayTicketType.exic;
          }
          // 新幹線のフィルタリングは orderItemCategory が shinkansen で railwayTicketType が exic 以外なら真
          else if (categories[i] === 'shinkansen') {
            categoryMatched =
              orderItem.orderItemCategory === 'shinkansen' &&
              orderItem.transports[0].railwayTicketType !== orderItemRailwayTicketType.exic;
          }
          // それ以外は orderItemCategory のみ比較
          else {
            categoryMatched = orderItem.orderItemCategory === categories[i];
          }
          if (categoryMatched) {
            break;
          }
        }
        matched = matched && categoryMatched;
      }
      matched = matched && orderItem.isSnoozing() === currentOrderItemsSnoozed;
      if (selectedOrganizationName !== 'all') {
        matched = matched && orderItem.organizationName === selectedOrganizationName;
      }
      if (selectedSp !== 'all') {
        const selected = selectedSp === 'true';
        matched = matched && orderItem.includeSp === selected;
      }
      return matched;
    });
    setFilteredOrderItems(ois);
  }, [
    orderItems,
    currentOrderItemsCategory,
    currentOrderItemsSnoozed,
    waiting,
    selectedOrderItemJobTypes,
    selectedOrderItemStatuses,
    selectedOrganizationName,
    selectedSp
  ]);

  const handleChangeSelectedOrderItemJobTypes = (jobTypes: OrderItemJobTypeKey[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('order_item_job_types', jobTypes.join(','));
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);
    setSelectedOrderItemJobTypes(jobTypes);
  };

  const handleChangeSelectedOrderItemStatuses = (statuses: number[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    OrderItemStatusAvailable.forEach((key, idx) => {
      searchParams.delete(`order_item_statuses[${idx}]`);
    });
    statuses.forEach((status, idx) => {
      searchParams.set(`order_item_statuses[${idx}]`, `${status}`);
    });
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);
    setSelectedOrderItemStatuses(statuses as OrderItemStatusKey[]);
  };

  const handleOrderItemsCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = event.currentTarget.selectedOptions;
    const options = [];
    for (let i = 0; i < selectedOptions.length; i += 1) {
      options.push(selectedOptions[i].value);
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('order_items_category', encodeURIComponent(options.join(',')));
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);

    setCurrentOrderItemsCategory(options);
  };

  const handleSortStartAt = (sortKey: StartAtSortKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('selected_sort_key', `${SelectedSortKey.StartAt}`);
    searchParams.set('start_at_sort_key', `${sortKey}`);
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);
    setSelectedSortKey(SelectedSortKey.StartAt);
    setStartAtSortKey(sortKey);
  };

  const handleSortOrderAt = (sortKey: OrderAtSortKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('selected_sort_key', `${SelectedSortKey.OrderAt}`);
    searchParams.set('order_at_sort_key', `${sortKey}`);
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);

    setSelectedSortKey(SelectedSortKey.OrderAt);
    setOrderAtSortKey(sortKey);
  };

  const handleOrderItemsSnoozedChange = (event: { target: { value: string } }) => {
    const snoozed: boolean = event.target.value === 'true';
    setCurrentOrderItemsSnoozed(snoozed);
  };

  const handleOrganizationNameChange = (selectedName: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('organization_name', selectedName);
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);
    setSelectedOrganizationName(selectedName);
  };

  const handleSpChange = (sp: SpType) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('sp', sp);
    window.history.pushState({}, '', `/arrangement/todo_list?${searchParams.toString()}`);
    setSelectedSp(sp);
  };

  const addEditingOrderItemId = (id: number) => {
    const ids = _.clone(editingOrderItemIds);
    ids.add(id);
    setEditingOrderItemIds(ids);
  };

  const removeEditingOrderItemId = (id: number) => {
    const ids = _.clone(editingOrderItemIds);
    ids.delete(id);
    setEditingOrderItemIds(ids);
  };

  const createOrderItemSnooze = async (params: OrderItemSnoozeTodoCreationParams): Promise<void> => {
    await Fetcher.post('/arrangement/order_item_snoozed_todos', params);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchOrderItems();
    removeEditingOrderItemId(params.order_item_id);
  };

  const cancelOrderItemSnooze = async (params: OrderItemSnoozeTodoCancelParams): Promise<void> => {
    await Fetcher.put(`/arrangement/order_item_snoozed_todos/cancel`, params);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchOrderItems();
    removeEditingOrderItemId(params.order_item_id);
  };

  const organizationSelector = () => {
    const options = ['all'].concat(organizationNames);
    return (
      <Autocomplete
        options={options}
        value={selectedOrganizationName}
        getOptionLabel={name => (name === 'all' ? '選択なし' : name)}
        filterOptions={(names, state) => {
          const q = state.inputValue;
          return _.filter(names, name => name.indexOf(q) > -1);
        }}
        onChange={(_, selected) => {
          if (selected) handleOrganizationNameChange(selected);
        }}
        renderInput={inputParams => (
          <TextField
            {...inputParams}
            variant="outlined"
            style={{}}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        )}
      />
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div>表示件数: {filteredOrderItems.length}件</div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell nowrap>Tripステータス</TableCell>
            <TableCell nowrap>
              <div>ステータス</div>
              <OrderItemStatusSelector
                selectedOrderItemJobTypes={selectedOrderItemJobTypes}
                onChangeSelectedOrderItemJobTypes={handleChangeSelectedOrderItemJobTypes}
                selectedOrderItemStatuses={selectedOrderItemStatuses}
                onChangeSelectedOrderItemStatuses={handleChangeSelectedOrderItemStatuses}
              />
            </TableCell>
            <TableCell>ステータス最終更新者</TableCell>
            <TableCell nowrap>
              <div>カテゴリ</div>
              <OrderItemsFilterSelect
                onChange={event => handleOrderItemsCategoryChange(event)}
                defaultValue={currentOrderItemsCategory}
                multiple
                size={3}
              >
                <option key="" value="">
                  選択なし
                </option>
                {Object.entries(orderItemTodoListCategories).map(([key, value], _) => {
                  return (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  );
                })}
              </OrderItemsFilterSelect>
            </TableCell>
            <TableCell nowrap width="300px">
              <div>法人名</div>
              {organizationSelector()}
            </TableCell>
            {theme.serviceName === 'AI Travel' && (
              <TableCell nowrap>
                <div>SP</div>
                <OrderItemsFilterSelect
                  onChange={e => handleSpChange(e.target.value as SpType)}
                  defaultValue={selectedSp}
                >
                  <option value="all">全て</option>
                  <option value="true">◯</option>
                  <option value="false">×</option>
                </OrderItemsFilterSelect>
              </TableCell>
            )}
            <TableCell nowrap>
              <div>問い合わせ対応</div>
              <OrderItemsFilterSelect
                onChange={e => setWaiting(e.target.value as WaitingType)}
                defaultValue={waiting}
              >
                <option value="all">全て</option>
                <option value="true">◯</option>
                <option value="false">×</option>
              </OrderItemsFilterSelect>
            </TableCell>
            <TableCell nowrap>
              日程
              <a onClick={() => handleSortStartAt(StartAtSortKey.ASCENDING)}>▲</a>
              <a onClick={() => handleSortStartAt(StartAtSortKey.DESCENDING)}>▼</a>
            </TableCell>
            <TableCell>
              依頼日
              <a onClick={() => handleSortOrderAt(OrderAtSortKey.ASCENDING)}>▲</a>
              <a onClick={() => handleSortOrderAt(OrderAtSortKey.DESCENDING)}>▼</a>
            </TableCell>
            <TableCell>チャットメッセージ</TableCell>
            <TableCell nowrap>Trip ID</TableCell>
            <TableCell nowrap>Trace ID</TableCell>
            <TableCell>最新のログメッセージ</TableCell>
            <TableCell nowrap>
              <div>スヌーズ</div>
              <OrderItemsFilterSelect
                onChange={event => handleOrderItemsSnoozedChange(event)}
                defaultValue={currentOrderItemsSnoozed.toString()}
              >
                <option key="false" value="false">
                  TODO
                </option>
                <option key="true" value="true">
                  スヌーズ中
                </option>
              </OrderItemsFilterSelect>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(currentOrderItemsCategory.includes('message') || currentOrderItemsCategory.includes('')) &&
            ['all', 'false'].includes(waiting) &&
            messageTodos.map(messageTodo => (
              <MessageTodoRow key={messageTodo.id} messageTodo={messageTodo} onUpdate={() => fetchOrderItems()} />
            ))}
          {filteredOrderItems.map(orderItem => (
            <OrderItemCard
              key={orderItem.id}
              orderItem={orderItem}
              editing={editingOrderItemIds.has(orderItem.id)}
              onCreateSnooze={createOrderItemSnooze}
              onCancelSnooze={cancelOrderItemSnooze}
              onOpenForm={() => addEditingOrderItemId(orderItem.id)}
              onCloseForm={() => removeEditingOrderItemId(orderItem.id)}
              showSp={theme.serviceName === 'AI Travel'}
              fetchOrderItems={fetchOrderItems}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
});

const OrderItemsFilterSelect = styled.select`
  margin-bottom: 0;
`;

export default withTheme(OrderItemTodoListLegacy);
