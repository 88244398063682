import * as url from 'url';

/**
 * Escapes HTML tags and attributes.
 * @param allowedTags 許可されたHTMLタグのリスト
 * @param allowedAttrs 許可されたHTML属性のリスト
 * @param allowedProtocols 許可されたプロトコルのリスト
 * @param allowNullProtocol プロトコルなしのURLを許可するかどうか
 * @param escapeMode エスケープモード escape: エスケープ, remove: 削除, escapeAll: 全てエスケープ
 */
interface EscapeHtmlOptions {
  allowedTags: string[];
  allowedAttrs: { [key: string]: string[] };
  allowedProtocols: { [key: string]: string[] };
  allowNullProtocol: boolean;
  escapeMode: 'escape' | 'remove' | 'escapeAll';
}

const defaultOptions: EscapeHtmlOptions = {
  allowedTags: [
    'br',
    'hr',
    'div',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'pre',
    'code',
    'ul',
    'ol',
    'nl',
    'li',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'b',
    'strong',
    'i',
    'em',
    'strike',
    'a'
  ],
  allowedAttrs: {
    a: ['href', 'target']
  },
  allowedProtocols: {
    '*': ['http', 'https', 'ftp', 'mailto']
  },
  allowNullProtocol: true,
  escapeMode: 'escape'
};

const htmlTagRegExp = /<\s*(\/?)\s*([a-z][a-z0-9]*)\b([^>]*)>/gi;
const htmlAttrsRegExp = /([a-z]+)(?:=(?:"(.+?)"|'(.+?)'))?\s*/gi;

function escape(str: string): string {
  const escapeMap: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;'
  };
  return str.replace(/[&<>"']/g, char => escapeMap[char]);
}

function escapeHtml(text: string, options?: Partial<EscapeHtmlOptions>): string {
  const mergedOptions = { ...defaultOptions, ...options };

  return text.replace(htmlTagRegExp, (match, isClosing, tagName, tagAttrs) => {
    const lowercaseTagName = tagName.toLowerCase();

    if (mergedOptions.escapeMode === 'escapeAll') {
      return escape(match);
    }

    if (mergedOptions.allowedTags.indexOf(lowercaseTagName) < 0) {
      switch (mergedOptions.escapeMode) {
        case 'remove':
          return '';
        case 'escape':
        default:
          return escape(match);
      }
    } else {
      return escapeTag(isClosing === '/', lowercaseTagName, tagAttrs, mergedOptions);
    }
  });
}

function escapeTag(isClosing: boolean, tagName: string, tagAttrs: string, options: EscapeHtmlOptions): string {
  let newTagAttrs = '';

  if (!isClosing) {
    while (true) {
      const match = htmlAttrsRegExp.exec(tagAttrs);
      if (!match) {
        break;
      }

      const attrName = match[1];
      let attrValue = match[2] || match[3];

      if (isAllowed(tagName, attrName, options.allowedAttrs)) {
        if (attrValue) {
          attrValue = trimQuotes(attrValue);

          if (attrName === 'href') {
            attrValue = escapeHref(tagName, attrValue, options);
          } else {
            attrValue = escape(attrValue);
          }
        }

        newTagAttrs += ` ${attrName}`;
        newTagAttrs += attrValue ? `="${attrValue}"` : '';
      }
    }
  }

  return `<${isClosing ? '/' : ''}${tagName}${newTagAttrs}>`;
}

function isAllowed(tagName: string, value: string, whitelist: { [key: string]: string[] }): boolean {
  if (whitelist[tagName] && whitelist[tagName].indexOf(value) >= 0) {
    return true;
  }

  if (whitelist['*'] && whitelist['*'].indexOf(value) >= 0) {
    return true;
  }

  return false;
}

function trimQuotes(text: string): string {
  if ((text.startsWith('"') && text.endsWith('"')) || (text.startsWith("'") && text.endsWith("'"))) {
    return text.substring(1, text.length - 1);
  }

  return text;
}

function escapeHref(tagName: string, href: string, options: EscapeHtmlOptions): string {
  const parsed = url.parse(href);

  let protocol = parsed.protocol;
  const parsedHref = parsed.href || '';

  if (protocol === null) {
    if (!options.allowNullProtocol) {
      return '';
    }
  } else {
    protocol = protocol.substr(0, protocol.length - 1);

    if (!isAllowed(tagName, protocol, options.allowedProtocols)) {
      return '';
    }
  }

  return parsedHref;
}

export type { EscapeHtmlOptions };
export { escapeHtml, defaultOptions };

// 使用例
/*
import { escapeHtml, EscapeHtmlOptions } from './html-escape';

// デフォルトオプションでのエスケープ
const input1 = '<p>This is <strong>bold</strong> and <em>italic</em>.</p>';
console.log(escapeHtml(input1));
// 出力: <p>This is <strong>bold</strong> and <em>italic</em>.</p>

// カスタムオプションでのエスケープ
const customOptions: Partial<EscapeHtmlOptions> = {
    allowedTags: ['p', 'strong'],
    escapeMode: 'remove'
};
const input2 = '<p>This is <strong>bold</strong> and <em>italic</em>.</p>';
console.log(escapeHtml(input2, customOptions));
// 出力: <p>This is <strong>bold</strong> and italic.</p>

// すべてのタグをエスケープ
const input3 = '<p>This is <strong>bold</strong> and <em>italic</em>.</p>';
console.log(escapeHtml(input3, { escapeMode: 'escapeAll' }));
// 出力: &lt;p&gt;This is &lt;strong&gt;bold&lt;/strong&gt; and &lt;em&gt;italic&lt;/em&gt;.&lt;/p&gt;
*/
