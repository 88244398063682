import type Department from '@this/domain/department/department_json';
import type OrganizationBase from '@this/domain/organization_base/organization_base_json';

export default interface OrganizationMemberJson {
  admin_notification: number;
  birthday: string;
  created_at: string;
  organization_base: OrganizationBase;
  organization_base_id: number;
  department: Department;
  department_id: number;
  disabled: boolean;
  domestic_approval_required: boolean;
  email: StringConstructor;
  employee_number: string | null;
  employment_status: string | null;
  encrypted_exic_password: string | null;
  encrypted_exic_password_iv: string | null;
  exic_id: string | null;
  first_name: string;
  first_name_kana: string;
  first_name_roman: string;
  flight_tel: string;
  foreign_approval_required: boolean;
  gender: string;
  gmo_id: string;
  grade_id: string | null;
  id: number;
  invitation_accepted_at: string | null;
  invitation_created_at: string | null;
  invitation_limit: string | null;
  invitation_sent_at: string | null;
  invitation_token: string | null;
  invitations_count: number;
  invited_by_id: string | null;
  invited_by_type: string | null;
  last_name: string;
  last_name_kana: string;
  last_name_roman: string;
  mileage_numbers:
    | {
        carrier: string;
        number: string;
      }[]
    | [];
  browsing_departments:
    | {
        department_id: number;
      }[]
    | [];
  name: string;
  name_kana: string;
  organization_id: number;
  organization_role_id: number;
  show_hotel_of_over_limit: boolean;
  show_foreign_hotel_of_over_limit: boolean;
  show_other_members: boolean;
  tel: string;
  updated_at: string;
  account_status: AccountStatus;
}

export enum AccountStatus {
  UNINVITE = 'uninvite',
  INVITED = 'invited',
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}
