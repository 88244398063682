import React from 'react';
import _ from 'lodash';

import type { MarginTypeArgs } from '@this/domain/organization/margin_type2';
import MarginType from '@this/domain/organization/margin_type2';
import HotelList from '@this/domain/hotel/hotel_list';
import HotelModel from '@this/domain/hotel/hotel';
import type { HotelJson } from '@this/domain/select_repository';
import type { HotelOrderItemState, OrderItemPrice } from '../types';

import HotelOrderItemPcTemplate from './hotel_order_item_pc.template';
import HotelOrderItemSpTemplate from './hotel_order_item_sp.template';

interface HotelOrderItemResponse {
  hotel: HotelJson;
  order_item_price: OrderItemPrice;
  lng: number;
  lat: number;
  show_fee: boolean;
  margin_type: MarginTypeArgs;
}

interface HotelProps {
  serviceId?: number | null;
}

class HotelOrderItem extends React.Component<HotelProps, HotelOrderItemState> {
  constructor(props: HotelProps) {
    super(props);

    this.state = {
      showPropertyAmenities: false,
      showRoomAmenities: false,
      loading: true,
      error: null,
      serviceId: props.serviceId || 1,
      searchQueryId: null
    };
  }

  componentDidMount() {
    return utils.jsonPromise<HotelOrderItemResponse>(location.pathname).then(
      result => {
        const hotels = new HotelList();
        let marginType: MarginType | undefined;
        if (result.margin_type) {
          marginType = new MarginType(result.margin_type);
        }
        const hotel = new HotelModel(_.merge(result.hotel, { showFee: result.show_fee, marginType }));
        this.setState({ destLocation: { lat: result.lat, lng: result.lng } });
        if (this.state.destLocation) hotel.fetchDistance(this.state.destLocation);
        const orderItemPrice = result.order_item_price;
        hotels.list = [hotel];
        return this.setState({
          hotels,
          hotel,
          orderItemPrice,
          loading: false,
          error: null
        });
      },
      e => {
        const error =
          e.status === 404
            ? 'ページが見つかりません。URLを確認してください。'
            : utils.dig(e, 'responseJSON', 'error')
            ? utils.dig(e, 'responseJSON', 'error')
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        return this.setState({
          loading: false,
          error
        });
      }
    );
  }

  getPropertyAmenities = () => {
    const amenities = this.state.hotel && this.state.hotel.propertyAmenities();
    if (this.state.showPropertyAmenities) {
      return amenities || [];
    }

    return (amenities && amenities.slice(0, 10)) || [];
  };

  getRoomAmenities = () => {
    const amenities = this.state.hotel && this.state.hotel.roomAmenities();
    if (this.state.showRoomAmenities) {
      return amenities || [];
    }

    return (amenities && amenities.slice(0, 10)) || [];
  };

  getImages = () => {
    const images = (this.state.hotel && this.state.hotel.images) || [];

    return images.slice(0, 26);
  };

  togglePropertyAmenities = () => {
    this.setState({ showPropertyAmenities: !this.state.showPropertyAmenities });
  };

  toggleRoomAmenities = () => {
    this.setState({ showRoomAmenities: !this.state.showRoomAmenities });
  };

  handleImageHover = (i: number) => () => {
    if (this.state.hotel) {
      return this.state.hotel.selectImage(i);
    }
    return undefined;
  };

  render() {
    try {
      return (
        <div className="hotel">
          <div className="hotel__pc">
            <HotelOrderItemPcTemplate
              {...this.state}
              getPropertyAmenities={this.getPropertyAmenities}
              getRoomAmenities={this.getRoomAmenities}
              getImages={this.getImages}
              togglePropertyAmenities={this.togglePropertyAmenities}
              toggleRoomAmenities={this.toggleRoomAmenities}
              handleImageHover={this.handleImageHover}
            />
          </div>
          <div className="hotel__sp">
            <HotelOrderItemSpTemplate
              {...this.state}
              getPropertyAmenities={this.getPropertyAmenities}
              getRoomAmenities={this.getRoomAmenities}
              getImages={this.getImages}
              togglePropertyAmenities={this.togglePropertyAmenities}
              toggleRoomAmenities={this.toggleRoomAmenities}
            />
          </div>
        </div>
      );
    } catch (e) {
      return null;
    }
  }
}

export default HotelOrderItem;
