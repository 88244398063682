/* eslint-disable max-lines */
import React, { useCallback } from 'react';

import { lighten } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';

import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';

import { media } from '../../shared/atoms/media';

import OrderItemIcons from './order_item_icons';
import StatusLabelTemplate from './status_label.template';

interface Props {
  trip: any;
}

const TripRow: React.FC<Props> = ({ trip }) => {
  const endTime = useCallback(() => {
    const endTime = trip.endTime || trip.order.endDate() || trip.return_time;
    return endTime;
  }, [trip]);

  // 複数回呼び出すので変数に格納するが、頻繁に更新はされないためメモ化はしない
  const dateRange = trip.dateRange();
  const originText = trip.originText();
  const destinationText = trip.destinationText();
  const totalPrice = trip.totalPrice();

  return (
    <SimpleCardWrap>
      <SimpleCard key={trip.id}>
        <CardFlex>
          <CardFlexLeft>
            <ContentFlex>
              <ContentFlexLeft>
                <ContentRow>
                  <ContentTitle>
                    <span data-wovn-ignore>{dateRange}</span>
                  </ContentTitle>
                  <ContentIcons>
                    <OrderItemIcons trip={trip} isOrderItem />
                  </ContentIcons>
                </ContentRow>
                <ContentRow>
                  <ContentId>
                    旅程番号：<span data-wovn-ignore>{trip.id}</span>
                  </ContentId>
                </ContentRow>
                <ContentRow>
                  <ContentText>
                    出発地：
                    {originText ? <span data-wovn-ignore>{originText}</span> : <EmptyText>なし</EmptyText>}
                  </ContentText>
                </ContentRow>
                <ContentRow>
                  <ContentText>
                    目的地：
                    {destinationText ? (
                      <span data-wovn-ignore>{destinationText}</span>
                    ) : (
                      <EmptyText>なし</EmptyText>
                    )}
                  </ContentText>
                </ContentRow>
              </ContentFlexLeft>
              <ContentFlexRight>
                {trip.freee_approval_request_id && trip.status !== 12 && (
                  <ContentFlexRow>
                    <Link
                      href={`https://secure.freee.co.jp/approval_requests/${trip.freee_approval_request_id}`}
                      target="_blank"
                    >
                      freeeワークフロー申請ページ
                    </Link>
                  </ContentFlexRow>
                )}
                <ContentFlexRow>
                  {totalPrice ? (
                    <TotalPrice>
                      <span>合計：</span>
                      <span data-wovn-ignore>{utils.digits(totalPrice)}</span>
                      <span>円</span>
                    </TotalPrice>
                  ) : (
                    <TotalPrice>合計：確認中</TotalPrice>
                  )}
                  {!trip.draft && (
                    <ContentStatus>
                      <StatusLabelTemplate trip={trip} endTime={endTime()} />
                    </ContentStatus>
                  )}
                </ContentFlexRow>
              </ContentFlexRight>
            </ContentFlex>
          </CardFlexLeft>
          <CardFlexRight href={`/trips/${trip.id}`}>
            <ArrowForwardIcon />
          </CardFlexRight>
        </CardFlex>
      </SimpleCard>
    </SimpleCardWrap>
  );
};

const SimpleCardWrap = styled.div`
  margin-bottom: 25px;
  border-radius: 5px;
`;

const SimpleCard = styled.div`
  background: #fff;
  border-radius: 5px;
`;

const CardFlex = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 5px;
`;

const CardFlexLeft = styled.div`
  flex: 1;
  padding: 16px;
`;

const CardFlexRight = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${props => props.theme.grayBgColorLight};
  transition: background-color 0.3s;

  & svg {
    color: ${props => props.theme.grayTextColor};
    border: solid 2px ${props => lighten(props.theme.grayTextColor, 0.3)};
    border-radius: 50%;
    padding: 2px;
    transition: color 0.3s, border-color 0.3s;
  }

  &:hover {
    background-color: ${props => lighten(props.theme.grayBgColorLight, 0.1)};

    & svg {
      color: ${props => lighten(props.theme.grayTextColor, 0.3)};
      border-color: ${props => lighten(props.theme.grayTextColor, 0.6)};
    }
  }
`;

const ContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${media.sp`
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;
  `}
`;

const ContentFlexLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`;

const ContentFlexRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: -8px;

  ${media.sp`
    align-items: flex-start;
  `}
`;

const ContentFlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ContentTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
`;

const ContentIcons = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;

  & li {
    list-style: none;
  }
`;

const ContentId = styled.p`
  font-size: 12px;
  color: ${props => props.theme.grayTextColor};
`;

const ContentText = styled.p`
  font-size: 14px;
`;

const EmptyText = styled.span`
  color: ${props => props.theme.grayTextColor};
`;

const TotalPrice = styled.p`
  font-weight: bold;
`;

const ContentStatus = styled.div`
  margin: 0 0 5px auto;
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  width: 130px;
`;

export default TripRow;
