import type { SmartHrImportDataChangeAttrResponseArgs } from '@this/domain/smart_hr_import_data_change_attr';
import SmartHrImportDataChangeAttr, {
  convertSmartHrImportDataChangeAttrResponseToArgs
} from '@this/domain/smart_hr_import_data_change_attr';

export type SmartHrImportDataChangeResponseArgs = {
  email: SmartHrImportDataChangeAttrResponseArgs;
  last_name: SmartHrImportDataChangeAttrResponseArgs;
  first_name: SmartHrImportDataChangeAttrResponseArgs;
  last_name_kana: SmartHrImportDataChangeAttrResponseArgs;
  first_name_kana: SmartHrImportDataChangeAttrResponseArgs;
  tel: SmartHrImportDataChangeAttrResponseArgs;
  gender: SmartHrImportDataChangeAttrResponseArgs;
  birthday: SmartHrImportDataChangeAttrResponseArgs;
  nationality_id: SmartHrImportDataChangeAttrResponseArgs;
};

export type SmartHrImportDataChangeArgs = {
  email: SmartHrImportDataChangeAttr | null;
  lastName: SmartHrImportDataChangeAttr | null;
  firstName: SmartHrImportDataChangeAttr | null;
  lastNameKana: SmartHrImportDataChangeAttr | null;
  firstNameKana: SmartHrImportDataChangeAttr | null;
  tel: SmartHrImportDataChangeAttr | null;
  gender: SmartHrImportDataChangeAttr | null;
  birthday: SmartHrImportDataChangeAttr | null;
  nationalityId: SmartHrImportDataChangeAttr | null;
};

export const convertSmartHrImportDataChangeResponseToArgs = (response: SmartHrImportDataChangeResponseArgs) => {
  const args: SmartHrImportDataChangeArgs = {
    email: response.email
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.email))
      : null,
    lastName: response.last_name
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.last_name))
      : null,
    firstName: response.first_name
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.first_name))
      : null,
    lastNameKana: response.last_name_kana
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.last_name_kana))
      : null,
    firstNameKana: response.first_name_kana
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.first_name_kana))
      : null,
    tel: response.tel
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.tel))
      : null,
    gender: response.gender
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.gender))
      : null,
    birthday: response.birthday
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.birthday))
      : null,
    nationalityId: response.nationality_id
      ? new SmartHrImportDataChangeAttr(convertSmartHrImportDataChangeAttrResponseToArgs(response.nationality_id))
      : null
  };
  return args;
};

export default class SmartHrImportDataChange {
  public readonly email: SmartHrImportDataChangeAttr | null;

  public readonly lastName: SmartHrImportDataChangeAttr | null;

  public readonly firstName: SmartHrImportDataChangeAttr | null;

  public readonly lastNameKana: SmartHrImportDataChangeAttr | null;

  public readonly firstNameKana: SmartHrImportDataChangeAttr | null;

  public readonly tel: SmartHrImportDataChangeAttr | null;

  public readonly gender: SmartHrImportDataChangeAttr | null;

  public readonly birthday: SmartHrImportDataChangeAttr | null;

  public readonly nationalityId: SmartHrImportDataChangeAttr | null;

  constructor(args: SmartHrImportDataChangeArgs) {
    this.email = args.email;
    this.lastName = args.lastName;
    this.firstName = args.firstName;
    this.lastNameKana = args.lastNameKana;
    this.firstNameKana = args.firstNameKana;
    this.tel = args.tel;
    this.gender = args.gender;
    this.birthday = args.birthday;
    this.nationalityId = args.nationalityId;
  }
}
