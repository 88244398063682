import React from 'react';
import _ from 'lodash';

interface Props {
  label?: string;
  value: string;
  example: string;
  error?: string;
  className?: string;
  onChange: (value: string) => void;
}

interface State {
  editing: boolean;
}

class TextArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false
    };
  }

  static getDerivedStateFromProps(nextProps: Props, _prevState: State): State | null {
    if (nextProps.value.length === 0) return null;
    return { editing: true };
  }

  handleFocus() {
    this.setState({ editing: true });
  }

  handleBlur() {
    if (this.props.value.length === 0) {
      this.setState({ editing: false });
    }
  }

  render() {
    try {
      const { label, value, example, error, className, onChange } = this.props;
      const { editing } = this.state;
      const classBase = 'form-elements-text-area';
      const errorClass = error ? ' error' : '';
      const customClass = className ? ` ${className}` : '';
      return (
        <div className={`${classBase}${errorClass}${customClass}`}>
          {label && <div className={`${classBase}__label`}>{editing && label}</div>}
          <textarea
            value={value}
            onChange={e => onChange(e.target.value)}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
            placeholder={editing ? example : label}
          />
          <div className={`${classBase}__bottom`}>{error}</div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default TextArea;
