import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { Moment } from 'moment';
import moment from 'moment';

import { ButtonBase } from '@this/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

interface Props {
  onSearch: (query: any) => void;
}

interface State {
  fromDate?: Moment;
  toDate?: Moment;
  travelerName: string;
  showApplied: boolean;
  showApproved: boolean;
  showRejected: boolean;
  orderBy: string;
}

class InAdvanceApplicationFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const filterParams = InAdvanceApplicationFilter.getQueries();
    this.state = filterParams;
  }

  handletravelerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      travelerName: e.target.value
    });
  };

  handleOrderByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      orderBy: e.target.value
    });
  };

  handleFromDateChange = (date: Moment | null) => {
    this.setState({ fromDate: date ?? undefined });
  };

  handleToDateChange = (date: Moment | null) => {
    this.setState({ toDate: date ?? undefined });
  };

  handleToggleApplied() {
    this.setState({ showApplied: !this.state.showApplied });
  }

  handleToggleApproved() {
    this.setState({ showApproved: !this.state.showApproved });
  }

  handleToggleRejected() {
    this.setState({ showRejected: !this.state.showRejected });
  }

  handleStatusChange(status: 'applied' | 'approved' | 'rejected') {
    this.setState({
      showApplied: status === 'applied',
      showApproved: status === 'approved',
      showRejected: status === 'rejected'
    });
  }

  handleSearch = () => {
    const query = this.searchQuery();

    const q = _.join(
      _.map(query, (v, k) => `${k}=${v}`),
      '&'
    );
    const url = `${location.pathname}?${q}`;
    window.history.pushState(null, '', url);

    this.props.onSearch(query);
  };

  static getQueries() {
    const params = utils.getParams();
    /**
     * from_dateとend_dateが指定されてない場合はデフォルト値として、開始日に今日の日付をいれる。
     * 明示的にundefinedが指定されてる場合、開始日終了日を明示的に未指定で検索したとして、undefinedをセットする
     */
    const from = utils.dig(params, 'from_date');
    const fromDate = from ? (from === 'undefined' ? undefined : moment(from)) : moment();
    const to = utils.dig(params, 'to_date');
    const toDate = to && to !== 'undefined' ? moment(to) : undefined;
    const showApplied = _.isNil(utils.dig(params, 'show_applied'))
      ? true
      : utils.dig(params, 'show_applied') === 'true';
    const showApproved = utils.dig(params, 'show_approved') === 'true';
    const showRejected = utils.dig(params, 'show_rejected') === 'true';

    return {
      fromDate,
      toDate,
      travelerName: utils.dig(params, 'traveler_name') || '',
      showApplied,
      showApproved,
      showRejected,
      orderBy: utils.dig(params, 'order_by') || 'start'
    };
  }

  searchQuery() {
    const { fromDate, toDate, travelerName, showApplied, showApproved, showRejected, orderBy } = this.state;
    return {
      from_date: fromDate?.format('YYYY-MM-DD'),
      to_date: toDate?.format('YYYY-MM-DD'),
      traveler_name: travelerName,
      show_applied: showApplied,
      show_approved: showApproved,
      show_rejected: showRejected,
      order_by: orderBy
    };
  }

  render() {
    try {
      const { fromDate, toDate, travelerName, showApplied, showApproved, showRejected, orderBy } = this.state;
      return (
        <Search>
          <SearchField>
            <SearchTitle>出張者</SearchTitle>
            <SearchInput>
              <input
                type="text"
                placeholder="出張者"
                value={travelerName}
                onChange={this.handletravelerNameChange}
              />
            </SearchInput>
          </SearchField>
          <SearchField>
            <SearchTitle>ステータス</SearchTitle>
            <SearchInput>
              <label>
                <input
                  type="checkbox"
                  value="applied"
                  checked={showApplied}
                  onChange={() => this.handleToggleApplied()}
                />
                未承認
              </label>
              <label>
                <input
                  type="checkbox"
                  value="approved"
                  checked={showApproved}
                  onChange={() => this.handleToggleApproved()}
                />
                承認済
              </label>
              <label>
                <input
                  type="checkbox"
                  value="rejected"
                  checked={showRejected}
                  onChange={() => this.handleToggleRejected()}
                />
                却下済
              </label>
            </SearchInput>
          </SearchField>
          <SearchField>
            <SearchTitle>出張日</SearchTitle>
            <SearchInput>
              <DatetimePicker
                value={fromDate}
                onChange={this.handleFromDateChange}
                placeholder="開始日"
                border
                showToday
                showPast
                deletable
              />
              <span>〜</span>
              <DatetimePicker
                value={toDate}
                onChange={this.handleToDateChange}
                placeholder="終了日"
                border
                showToday
                showPast
                deletable
              />
            </SearchInput>
          </SearchField>
          <SearchField>
            <SearchTitle>並び順</SearchTitle>
            <SearchInput>
              <select value={orderBy} onChange={this.handleOrderByChange}>
                <option key="start" value="start">
                  出発日順
                </option>
                <option key="create" value="create">
                  依頼日順
                </option>
              </select>
            </SearchInput>
            <SearchButton onClick={this.handleSearch}>検索</SearchButton>
          </SearchField>
        </Search>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Search = styled.div`
  padding: 10px;
  border: 1px solid ${props => props.theme.grayBorderColor};
  border-radius: 3px;
  background-color: ${props => props.theme.grayBgColor};
  font-size: 13px;
  margin-bottom: 20px;
`;

const SearchField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SearchTitle = styled.p`
  width: 100px;
`;

const SearchInput = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  input,
  select {
    margin-bottom: 0;
  }

  label {
    font-weight: normal;
    cursor: pointer;
    margin-right: 10px;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  & .single-calendar-input {
    width: 120px !important;
  }
`;

const SearchButton = styled.a`
  ${ButtonBase};
  padding: 5px 10px;
  margin-left: auto;
`;

export default InAdvanceApplicationFilter;
