class Arranger
  constructor: (args = {}) ->
    _.assign @, args

  setLastName: (value) ->
    @last_name = value
    app.render()

  setFirstName: (value) ->
    @first_name = value
    app.render()

  setEmail: (value) ->
    @email = value
    app.render()

  submitParams: ->
    last_name: @last_name
    first_name: @first_name
    email: @email

  @empty: ->
    new Arranger last_name: '', first_name: '', email: ''

module.exports = Arranger
