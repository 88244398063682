import moment from 'moment';
import type {
  OrderItemStepFieldFieldType,
  OrderItemStepFieldTargetField,
  OrderItemStepFieldTargetModel
} from '../master_step/order_item_step_field';
import type OrderItem from '../order_item';
import type TransportElement from '../transport_element';
import type TransportElementReservation from '../transport_element_reservation';
import type TicketOprReservation from '../ticket_opr_reservation';

export interface OrderItemStepFieldArgs {
  id: number;
  order_item_step_id: number;
  field_type: OrderItemStepFieldFieldType;
  target_model: OrderItemStepFieldTargetModel;
  target_field: OrderItemStepFieldTargetField;
  required: boolean;
}

interface FieldBase {
  key: string;
  is: boolean;
  required: boolean;
  label: string;
}

interface SelectBase extends FieldBase {
  options: { label: string; value: string }[];
}

interface TransportTextField extends FieldBase {
  type: 'transportText';
  value: (element: TransportElement) => string;
  handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface TransportSelectField extends SelectBase {
  type: 'transportSelect';
  value: (element: TransportElement) => string;
  handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface TransportReservationViewField extends FieldBase {
  type: 'transportReservationView';
  value: (reservation: TransportElementReservation) => string;
}

interface TicketOprReservationViewField extends FieldBase {
  type: 'ticketOprReservationView';
  value: (reservation: TicketOprReservation) => string;
}

export type OrderItemStepTodoField =
  | TransportTextField
  | TransportSelectField
  | TransportReservationViewField
  | TicketOprReservationViewField;

type OrderItemStepTodoSelectField = TransportSelectField;

class OrderItemStepTodoFields {
  serviceId: number;

  // 金額の入力エリアを設ける
  isOrderItemPriceForm: boolean;

  // 発券方法
  isTicketingType: boolean;

  requiredTicketingType: boolean;

  // 予約番号
  isAirReservationNumber: boolean;

  requiredAirReservationNumber: boolean;

  // 確認番号
  isAirConfirmationNumber: boolean;

  requiredAirConfirmationNumber: boolean;

  // アリーズ申込識別番号
  isAlliesApplicationNumber: boolean;

  requiredAlliesApplicationNumber: boolean;

  // フロンティア申込識別番号
  isFrontierApplicationNumber: boolean;

  requiredFrontierApplicationNumber: boolean;

  // スカイツアーズ申込識別番号
  isSkytoursApplicationNumber: boolean;

  requiredSkytoursApplicationNumber: boolean;

  // スマチケ管理ID
  isSmartTicketRsvId: boolean;

  requiredSmartTicketRsvId: boolean;

  // TicketOpr管理ID
  isTicketOprRsvId: boolean;

  requiredTicketOprRsvId: boolean;

  constructor(args: OrderItemStepFieldArgs[], serviceId?: number) {
    this.serviceId = serviceId ?? 1;
    this.isOrderItemPriceForm = args.some(raw => raw.target_field === 'order_item_price_form');
    this.isTicketingType = args.some(raw => raw.target_field === 'ticketing_type');
    this.requiredTicketingType = args.some(raw => raw.target_field === 'ticketing_type' && raw.required);
    this.isAirReservationNumber = args.some(raw => raw.target_field === 'air_reservation_number');
    this.requiredAirReservationNumber = args.some(
      raw => raw.target_field === 'air_reservation_number' && raw.required
    );
    this.isAirConfirmationNumber = args.some(raw => raw.target_field === 'air_confirmation_number');
    this.requiredAirConfirmationNumber = args.some(
      raw => raw.target_field === 'air_confirmation_number' && raw.required
    );
    this.isAlliesApplicationNumber = args.some(raw => raw.target_field === 'allies_application_number');
    this.requiredAlliesApplicationNumber = args.some(
      raw => raw.target_field === 'allies_application_number' && raw.required
    );
    this.isFrontierApplicationNumber = args.some(raw => raw.target_field === 'frontier_application_number');
    this.requiredFrontierApplicationNumber = args.some(
      raw => raw.target_field === 'frontier_application_number' && raw.required
    );
    this.isSkytoursApplicationNumber = args.some(raw => raw.target_field === 'skytours_application_number');
    this.requiredSkytoursApplicationNumber = args.some(
      raw => raw.target_field === 'skytours_application_number' && raw.required
    );
    this.isSmartTicketRsvId = args.some(raw => raw.target_field === 'smart_ticket_rsv_id');
    this.requiredSmartTicketRsvId = false;
    this.isTicketOprRsvId = args.some(raw => raw.target_field === 'ticket_opr_rsv_id');
    this.requiredTicketOprRsvId = false;
  }

  fields(): OrderItemStepTodoField[] {
    return [
      {
        key: 'ticketingType',
        is: this.isTicketingType,
        required: this.requiredTicketingType,
        label: '発券方法',
        type: 'transportSelect' as const,
        options: this.ticketingTypeOptions(),
        value: (element: TransportElement) => element.ticketingType?.toString() || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLSelectElement>) => {
          element.handleTicketingTypeChange(e.target.value);
        }
      },
      {
        key: 'airReservationNumber',
        is: this.isAirReservationNumber,
        required: this.requiredAirReservationNumber,
        label: '予約番号',
        type: 'transportText' as const,
        value: (element: TransportElement) => element.airReservationNumber || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
          element.handleAirReservationNumberChange(e.target.value);
        }
      },
      {
        key: 'airConfirmationNumber',
        is: this.isAirConfirmationNumber,
        required: this.requiredAirConfirmationNumber,
        label: '確認番号',
        type: 'transportText' as const,
        value: (element: TransportElement) => element.airConfirmationNumber || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
          element.handleAirConfirmationNumberChange(e.target.value);
        }
      },
      {
        key: 'alliesApplicationNumber',
        is: this.isAlliesApplicationNumber,
        required: this.requiredAlliesApplicationNumber,
        label: 'アリーズ申込識別番号',
        type: 'transportText' as const,
        value: (element: TransportElement) => element.alliesApplicationNumber || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
          element.handleAlliesApplicationNumber(e.target.value);
        }
      },
      {
        key: 'frontierApplicationNumber',
        is: this.isFrontierApplicationNumber,
        required: this.requiredFrontierApplicationNumber,
        label: 'フロンティア申込識別番号',
        type: 'transportText' as const,
        value: (element: TransportElement) => element.frontierApplicationNumber || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
          element.handleFrontierApplicationNumber(e.target.value);
        }
      },
      {
        key: 'skytoursApplicationNumber',
        is: this.isSkytoursApplicationNumber,
        required: this.requiredSkytoursApplicationNumber,
        label: 'スカイツアーズ申込識別番号',
        type: 'transportText' as const,
        value: (element: TransportElement) => element.skytoursApplicationNumber || '',
        handleChange: (element: TransportElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
          element.handleSkytoursApplicationNumber(e.target.value);
        }
      },
      {
        key: 'smartTicketRsvId',
        is: this.isSmartTicketRsvId,
        required: this.requiredSmartTicketRsvId,
        label: 'スマチケ管理ID',
        type: 'transportReservationView' as const,
        value: (r: TransportElementReservation) =>
          [
            r.smartTicketRsvId && `管理ID: ${r.smartTicketRsvId}`,
            r.smartTicketReservationNumber && `予約番号: ${r.smartTicketReservationNumber}`,
            r.smartTicketAdultPrice &&
              `購入価格: ${parseInt(r.smartTicketAdultPrice.toString(), 10).toLocaleString()}円`,
            r.smartTicketTicketName && `券種: ${r.smartTicketTicketName} (${r.smartTicketTicketCode})`,
            r.smartTicketExpiredDate && `有効期限: ${moment(r.smartTicketExpiredDate).format('YYYY/MM/DD')}`
          ]
            .filter(Boolean)
            .join('\n')
      },
      {
        key: 'ticketOprRsvId',
        is: this.isTicketOprRsvId,
        required: this.requiredTicketOprRsvId,
        label: 'TicketOpr管理ID',
        type: 'ticketOprReservationView' as const,
        value: (r: TicketOprReservation) =>
          [
            r.ticketOprRsvId && `管理ID: ${r.ticketOprRsvId}`,
            r.ticketOprReservationNumber && `予約番号: ${r.ticketOprReservationNumber}`,
            r.ticketOprAdultPrice &&
              `購入価格: ${parseInt(r.ticketOprAdultPrice.toString(), 10).toLocaleString()}円`,
            r.ticketOprTicketName && `券種: ${r.ticketOprTicketName} (${r.ticketOprTicketCode})`,
            r.ticketOprExpiredDate && `有効期限: ${moment(r.ticketOprExpiredDate).format('YYYY/MM/DD')}`
          ]
            .filter(Boolean)
            .join('\n')
      }
    ].filter(field => field.is);
  }

  ticketingTypeOptions(): SelectBase['options'] {
    if (utils.isTabikobo(this.serviceId)) {
      return [
        { value: '1', label: 'アリーズ' },
        { value: '3', label: 'IWAツアー' },
        { value: '4', label: '旅工房' }
      ];
    }

    return [
      { value: '0', label: 'AIトラベル' },
      { value: '1', label: 'アリーズ' },
      { value: '2', label: 'エボラブルアジア' },
      { value: '5', label: 'フロンティア' },
      { value: '6', label: 'スカイツアーズ' }
    ];
  }

  updateParams(orderItem: OrderItem) {
    return {
      order_items: [orderItem.updateParams()],
      input_fields: this.inputFieldsParams(orderItem),
      input_price_field: this.isOrderItemPriceForm ? 'true' : 'false'
    };
  }

  enumLabel(field: OrderItemStepTodoSelectField, targetObject: unknown) {
    const value = field.value(targetObject as any);
    const option = field.options.find(option => option.value === value);

    return option ? option.label : '';
  }

  inputFieldsParams(orderItem: OrderItem) {
    const transportElement = orderItem.elements[0] as TransportElement;

    return this.fields().map(field => {
      if (field.type === 'transportSelect' || field.type === 'transportText') {
        return {
          label: field.label,
          value: field.value(transportElement),
          required: field.required ? 'true' : 'false'
        };
      }

      return { label: '入力フィールド', value: '', required: 'false' };
    });
  }

  viewFieldsParams(orderItem: OrderItem) {
    const transportElement = orderItem.elements[0] as TransportElement;
    const transportElementReservation = transportElement.transportElementReservations[0];
    const ticketOprReservation = transportElement.ticketOprReservations[0];

    return this.fields().map(field => {
      if (field.type === 'transportSelect' || field.type === 'transportText') {
        return {
          label: field.label,
          value: field.value(transportElement)
        };
      }

      if (field.type === 'transportReservationView') {
        return {
          label: field.label,
          value: field.value(transportElementReservation)
        };
      }

      if (field.type === 'ticketOprReservationView') {
        return {
          label: field.label,
          value: field.value(ticketOprReservation)
        };
      }

      return { label: '表示フィールド', value: '' };
    });
  }
}

export default OrderItemStepTodoFields;
