import { observable } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';

export interface CreditBalanceHistoryResponse {
  id: number;
  date: string;
  description: string;
  expire_at: string | null;
  billed_amount?: number;
  paid_amount?: number;
  balance: number;
}

interface CreditBalanceHistoryArgs {
  id: number;
  date: Moment;
  description: string;
  expireAt?: Moment;
  billedAmount?: number;
  paidAmount?: number;
  balance: number;
}

export const convertCreditBalanceHistoryResponseToArgs = (response: CreditBalanceHistoryResponse) => {
  const args: CreditBalanceHistoryArgs = {
    id: response.id,
    date: moment(response.date),
    description: response.description,
    expireAt: response.expire_at ? moment(response.expire_at) : undefined,
    billedAmount: response.billed_amount,
    paidAmount: response.paid_amount,
    balance: response.balance
  };
  return args;
};

export default class CreditBalanceHistory {
  @observable
  id: number;

  @observable
  date: Moment;

  @observable
  description: string;

  @observable
  expireAt?: Moment;

  @observable
  billedAmount?: number;

  @observable
  paidAmount?: number;

  @observable
  balance: number;

  constructor(args: CreditBalanceHistoryArgs) {
    this.id = args.id;
    this.date = args.date;
    this.description = args.description;
    this.expireAt = args.expireAt;
    this.billedAmount = args.billedAmount;
    this.paidAmount = args.paidAmount;
    this.balance = args.balance;
  }
}
