import type { Moment } from 'moment';
import moment from 'moment';

interface SnoozedTodoArgs {
  id: number;
  trip_id: number;
  arranger_id: number;
  snooze_to: string;
  memo: string;
  canceled: boolean;
}

class SnoozedTodo {
  public id: number;

  public tripId: number;

  public arrangerId: number;

  public snoozeTo: Moment;

  public memo: string;

  public canceled: boolean;

  constructor(args: SnoozedTodoArgs) {
    this.id = args.id;
    this.tripId = args.trip_id;
    this.arrangerId = args.arranger_id;
    this.snoozeTo = moment(args.snooze_to);
    this.memo = args.memo;
    this.canceled = args.canceled;
  }

  isSnoozing() {
    return !this.canceled && this.snoozeTo.isAfter(moment());
  }
}

export default SnoozedTodo;
