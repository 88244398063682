import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type { PaymentGatewayType } from '@this/domain/organization/organization2';
import Text from '../../../../../shared/text/text';

import type { Props as ComponentProps, State as ComponentState } from './payment_section';

type Props = {
  handleAutoNextFocus: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleDeleteCard: (
    payment_gateway_type: PaymentGatewayType | undefined,
    card_seq: string
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
  handlePaymentTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePaymentTransaction: (event: React.ChangeEvent<HTMLInputElement>) => void;

  // openPaymentConfirm;
} & ComponentProps &
  ComponentState;

const PaymentSectionTemplate: React.SFC<Props> = ({
  // ComponentProps
  validationErrors,
  reserveInfo,
  handleReserveInfoChange,
  handlePaymentTransaction,

  // ComponentState
  deleteCardStatus,
  cardCompanyImageNum,
  paymentMethodType,

  // Component methods
  handleAutoNextFocus,
  handleDeleteCard,
  handlePaymentTypeChange
  // openPaymentConfirm,
}) => (
  <div>
    <PaymentFlash>
      {deleteCardStatus === 'success' ? (
        <div className="flash succeed">クレジットカードのご登録情報を削除しました。</div>
      ) : deleteCardStatus === 'fail' ? (
        <div className="flash faild">
          通信環境が不安定です。
          <br />
          時間をおいてもう一度お試しください。
        </div>
      ) : null}
    </PaymentFlash>
    <form /* onSubmit={openPaymentConfirm} */>
      <PaymentFormSubArea>
        {deleteCardStatus === 'deleting' ? (
          <PaymentDeletingFlash>処理中...</PaymentDeletingFlash>
        ) : (
          <>
            {(reserveInfo.paymentMethodOptions === 'selectable' ||
              reserveInfo.paymentMethodOptions === 'invoice') && (
              <PaymentCards>
                <input
                  type="radio"
                  value="billing"
                  checked={paymentMethodType === 'billing'}
                  onChange={handlePaymentTypeChange}
                />
                <span>{reserveInfo.user.organization.name}に請求する</span>
              </PaymentCards>
            )}
            {(reserveInfo.paymentMethodOptions === 'selectable' ||
              reserveInfo.paymentMethodOptions === 'card') && (
              <>
                <PaymentCards id="card">
                  <input
                    type="radio"
                    value="card"
                    checked={paymentMethodType === 'card'}
                    onChange={handlePaymentTypeChange}
                  />
                  <span>クレジットカードで決済する</span>
                </PaymentCards>
                {paymentMethodType === 'card' && (
                  <div className="payment__card-area">
                    {reserveInfo.paymentGatewayMembers &&
                      reserveInfo.paymentGatewayMembers.length > 0 &&
                      reserveInfo.paymentGatewayMembers.map((member, i) => (
                        <PaymentCards key={i}>
                          <input
                            type="radio"
                            value={member.uid}
                            checked={reserveInfo.paymentTransaction?.uid === member.uid}
                            onChange={handlePaymentTransaction}
                          />
                          <span>{member.name}</span>
                          {reserveInfo.paymentGatewayType === 'gmo' && (
                            <PaymentDelCard
                              href="#"
                              onClick={handleDeleteCard(reserveInfo.paymentGatewayType, member.uid)}
                            >
                              このカードを削除
                            </PaymentDelCard>
                          )}
                        </PaymentCards>
                      ))}
                    {reserveInfo.paymentGatewayType === 'gmo' && (
                      <PaymentCardsNew id="payment-card-new-label">
                        <input
                          type="radio"
                          value="new"
                          checked={reserveInfo.paymentTransaction?.uid === 'new'}
                          onChange={handlePaymentTransaction}
                        />
                        <span>新しいカードで決済</span>
                      </PaymentCardsNew>
                    )}
                    {reserveInfo.paymentGatewayType === 'gmo' && reserveInfo.paymentTransaction?.uid === 'new' && (
                      <>
                        <PaymentNewCardArea>
                          <PaymentSection>
                            <PaymentLeftLabel htmlFor="card_number">
                              カード番号
                              {cardCompanyImageNum !== '' && (
                                <PaymentLeftLabelImgWrap>
                                  <PaymentLeftLabelImg src={`/images/logo_card${cardCompanyImageNum}.gif`} />
                                </PaymentLeftLabelImgWrap>
                              )}
                            </PaymentLeftLabel>
                            <PaymentSectionRight>
                              <PaymentInputCardNumber
                                type="number"
                                pattern="\\d*"
                                name="card[number_1]"
                                id="card_number_1"
                                placeholder="0000"
                                value={reserveInfo.cardNum01}
                                maxLength={4}
                                onChange={handleReserveInfoChange('setCardNum01')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_number_2"
                              />
                              <span>-</span>
                              <PaymentInputCardNumber
                                type="number"
                                pattern="\\d*"
                                name="card[number_2]"
                                id="card_number_2"
                                placeholder="0000"
                                value={reserveInfo.cardNum02}
                                maxLength={4}
                                onChange={handleReserveInfoChange('setCardNum02')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_number_3"
                              />
                              <span>-</span>
                              <PaymentInputCardNumber
                                type="number"
                                pattern="\\d*"
                                name="card[number_3]"
                                id="card_number_3"
                                placeholder="0000"
                                value={reserveInfo.cardNum03}
                                maxLength={4}
                                onChange={handleReserveInfoChange('setCardNum03')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_number_4"
                              />
                              <span>-</span>
                              <PaymentInputCardNumber
                                type="number"
                                pattern="\\d*"
                                name="card[number_4]"
                                id="card_number_4"
                                placeholder="0000"
                                value={reserveInfo.cardNum04}
                                maxLength={4}
                                onChange={handleReserveInfoChange('setCardNum04')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_expire_month"
                              />
                            </PaymentSectionRight>
                          </PaymentSection>
                          <PaymentSection>
                            <PaymentLeftLabel htmlFor="card_expire_month">有効期限</PaymentLeftLabel>
                            <PaymentSectionRight>
                              <PaymentExpire
                                type="number"
                                pattern="\\d*"
                                name="card[expire_month]"
                                id="card_expire_month"
                                placeholder="MM"
                                value={reserveInfo.cardExpireMonth}
                                maxLength={2}
                                onChange={handleReserveInfoChange('setCardExpireMonth')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_expire_year"
                              />
                              <PaymentSlash> / </PaymentSlash>
                              <PaymentExpire
                                type="number"
                                pattern="\\d*"
                                name="card[expire_year]"
                                id="card_expire_year"
                                placeholder="YY"
                                value={reserveInfo.cardExpireYear}
                                maxLength={2}
                                onChange={handleReserveInfoChange('setCardExpireYear')}
                                onKeyUp={handleAutoNextFocus}
                                data-nextelementid="card_name"
                              />
                            </PaymentSectionRight>
                          </PaymentSection>
                          <PaymentSection>
                            <PaymentLeftLabel htmlFor="card_name">カード名義</PaymentLeftLabel>
                            <PaymentSectionRight>
                              <PaymentInput
                                type="text"
                                name="card[name]"
                                id="card_name"
                                placeholder="ICHIRO YAMADA"
                                value={reserveInfo.cardName}
                                onChange={handleReserveInfoChange('setCardName')}
                              />
                            </PaymentSectionRight>
                          </PaymentSection>
                          <PaymentSection>
                            <PaymentLeftLabel htmlFor="card_code">セキュリティコード</PaymentLeftLabel>
                            <PaymentSectionRight>
                              <PaymentInput
                                type="text"
                                name="card[code]"
                                id="card_code"
                                placeholder="000"
                                value={reserveInfo.cardCode}
                                onChange={handleReserveInfoChange('setCardCode')}
                              />
                            </PaymentSectionRight>
                          </PaymentSection>
                        </PaymentNewCardArea>
                        {!_.isEmpty(validationErrors.card) && (
                          <ReserveConfirmErrors>
                            <Error>
                              <Text text={validationErrors.card} />
                            </Error>
                          </ReserveConfirmErrors>
                        )}
                      </>
                    )}
                    <p>※ 予約時に決済となります。</p>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PaymentFormSubArea>
    </form>
  </div>
);

const labelWidth = '130px';

const PaymentFlash = styled.div`
  margin: 0 0 10px;
`;

const PaymentFormSubArea = styled.div`
  margin-top: 15px;
`;

const PaymentDeletingFlash = styled.div`
  margin: 10px 0;
`;

const PaymentCards = styled.label`
  overflow: hidden;
  margin-bottom: 15px;
`;

const PaymentCardsNew = styled.label`
  margin-top: 15px;
`;

const PaymentDelCard = styled.a`
  display: inline-block;
  float: right;
`;

const PaymentNewCardArea = styled.div`
  margin-left: 20px;
`;

const PaymentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  line-height: 27px;
`;

const PaymentSectionRight = styled.div`
  display: flex;
  width: 300px;
`;

const PaymentLeftLabel = styled.label`
  font-weight: normal;
  width: ${labelWidth};
`;

const PaymentLeftLabelImgWrap = styled.div`
  display: inline-block;
  width: 36px;
  height: 23px;
  margin: 0 0 0 8px;
  vertical-align: middle;
`;

const PaymentLeftLabelImg = styled.img`
  display: block;
  max-height: 100%;
`;

const PaymentInput = styled.input`
  width: calc(100% - ${labelWidth});
`;

const PaymentInputCardNumber = styled.input`
  width: 4em;
`;

const PaymentSlash = styled.div`
  width: 20px;
  text-align: center;
`;

const PaymentExpire = styled.input`
  width: 3em;
`;

const ReserveConfirmErrors = styled.div`
  margin-bottom: $base-margin;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

export default PaymentSectionTemplate;
