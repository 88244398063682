import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import NewVersionTable from './new_version_table';
import OldVersionTable from './old_version_table';
import PriorityTable from './priority_table';

const DomesticAirData = () => {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const initialVersion = query.get('version') || 'new';
  const initialMode = query.get('mode') || 'setting';
  const initialStatusFilter = query.get('status') || '';
  const initialSourceFilter = query.get('source') || '';
  const initialFromAirportFilter = query.get('from_airport') || '';
  const initialToAirportFilter = query.get('to_airport') || '';
  const initialTargetDateFilter =
    query.get('target_date') ||
    (() => {
      const today = new Date();
      const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      return nextWeek.toISOString().split('T')[0];
    })();

  const [version, setVersion] = useState(initialVersion);
  const [mode, setMode] = useState(initialMode);
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);
  const [sourceFilter, setSourceFilter] = useState(initialSourceFilter);
  const [fromAirportFilter, setFromAirportFilter] = useState(initialFromAirportFilter);
  const [toAirportFilter, setToAirportFilter] = useState(initialToAirportFilter);
  const [targetDateFilter, setTargetDateFilter] = useState(initialTargetDateFilter);

  const updateQueryParams = () => {
    const params = new URLSearchParams();
    params.append('version', version);
    params.append('mode', mode);
    if (statusFilter) params.append('status', statusFilter);
    if (sourceFilter) params.append('source', sourceFilter);
    if (fromAirportFilter) params.append('from_airport', fromAirportFilter);
    if (toAirportFilter) params.append('to_airport', toAirportFilter);
    if (targetDateFilter) params.append('target_date', targetDateFilter);
    history.push(`?${params.toString()}`);
  };

  const handleVersionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVersion(e.target.value);
  }, []);

  const handleModeChange = useCallback((newMode: string) => {
    setMode(newMode);
  }, []);

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
  };

  const handleSourceFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSourceFilter(e.target.value);
  };

  const handleFromAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFromAirportFilter(e.target.value);
  };

  const handleToAirportChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setToAirportFilter(e.target.value);
  };

  const handleTargetDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetDateFilter(e.target.value);
  };

  useEffect(() => {
    updateQueryParams();
  }, [version, mode, statusFilter, sourceFilter, fromAirportFilter, toAirportFilter, targetDateFilter]);

  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <label>
          <input
            type="radio"
            name="version"
            value="new"
            checked={version === 'new'}
            onChange={handleVersionChange}
          />
          データ取得(新)
        </label>
        <label>
          <input
            type="radio"
            name="version"
            value="old"
            checked={version === 'old'}
            onChange={handleVersionChange}
          />
          データ取得(旧)
        </label>
        <label>
          <input
            type="radio"
            name="version"
            value="priority"
            checked={version === 'priority'}
            onChange={handleVersionChange}
          />
          レポジトリ順
        </label>
      </div>
      {version === 'new' && (
        <NewVersionTable
          mode={mode}
          onModeChange={handleModeChange}
          statusFilter={statusFilter}
          onStatusFilterChange={handleStatusFilterChange}
          sourceFilter={sourceFilter}
          onSourceFilterChange={handleSourceFilterChange}
          fromAirportFilter={fromAirportFilter}
          onFromAirportChange={handleFromAirportChange}
          toAirportFilter={toAirportFilter}
          onToAirportChange={handleToAirportChange}
          targetDateFilter={targetDateFilter}
          onTargetDateChange={handleTargetDateChange}
        />
      )}
      {version === 'old' && <OldVersionTable />}
      {version === 'priority' && <PriorityTable />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export default DomesticAirData;
