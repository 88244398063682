import type { ReactElement } from 'react';
import React, { useState, useCallback } from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';

import type ItemList from '@this/domain/approve_item/item_list';
import SelectApproveItemListList from './select_approveitemlist_list/select_approveitemlist_list';

interface Props {
  approveItemId: number;
  chargingDepartmentIds?: number[];
  defaultItemListName: string | undefined;
  onSelect: (itemList: ItemList | null) => void;
  tripedit?: boolean;
  workflowFormWidth: string;
}

interface State {
  itemList: ItemList | null;
  showList: boolean;
}

interface TruncatableTextProps {
  text: string;
  truncateMaxWidth: string;
}

interface HTMLElementEvent<T extends EventTarget> extends Event {
  target: T;
}

class SelectApproveItemList extends React.Component<Props, State> {
  private selectAreaRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      showList: false,
      itemList: null
    };

    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    // ここをコメントアウトしたら以下のチケットが修正できた。元々何のためのリセット処理？
    // https://aitravel.atlassian.net/browse/AITRAVEL-3053
    // if (prevProps.defaultItemListName !== this.props.defaultItemListName) {
    //   this.resetItemList();
    // }
  }

  componentDidMount() {
    document.addEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  handleClickEvent(e: HTMLElementEvent<HTMLElement>) {
    // ref内にクリックされたeventのDOMが含まれているかを確認する
    if (this.selectAreaRef && this.selectAreaRef.current && !this.selectAreaRef.current.contains(e.target)) {
      this.setState({ showList: false });
    }
  }

  handleSelect = (itemList: ItemList | null) => {
    const { onSelect } = this.props;
    this.setState({ showList: false, itemList }, () => {
      onSelect(itemList);
    });
  };

  handleToggleShowList = () => {
    this.setState({ showList: !this.state.showList });
  };

  resetItemList = () => {
    this.setState({ itemList: null });
  };

  getDefaultItemListName = (): string => {
    let defaultItemString = '';
    if (this.props.defaultItemListName !== null && this.props.defaultItemListName !== undefined) {
      defaultItemString = this.props.defaultItemListName;
    }
    return defaultItemString;
  };

  useTruncated = (
    text?: string | undefined | null,
    truncateMaxWidth?: number
  ): [(node: HTMLDivElement) => void, boolean | undefined] => {
    const [truncated, setTruncated] = useState(false);
    const ref = useCallback(
      node => {
        if (!node) return;
        if (truncateMaxWidth) {
          setTruncated(node.scrollWidth > truncateMaxWidth);
        } else {
          setTruncated(node.scrollWidth > node.offsetWidth);
        }
      },
      [text, truncateMaxWidth]
    );
    return [ref, truncated];
  };

  TrancatableText = ({ text, truncateMaxWidth }: TruncatableTextProps): ReactElement | null => {
    const maxWidth = truncateMaxWidth ? Number(truncateMaxWidth.replace(/[^0-9]/g, '')) : 420;
    const [ref, isTruncated] = this.useTruncated(text, maxWidth);
    const title = isTruncated && { title: text };

    return (
      <ApproveItemListNameText ref={ref} {...title}>
        {text}
      </ApproveItemListNameText>
    );
  };

  render() {
    try {
      const { approveItemId, chargingDepartmentIds, workflowFormWidth } = this.props;
      const { showList, itemList } = this.state;
      return (
        <Wrapper ref={this.selectAreaRef}>
          <ApproveItemListTextArea workflowFormWidth={workflowFormWidth} onClick={this.handleToggleShowList}>
            {itemList ? (
              <this.TrancatableText
                text={`${itemList.code}：${itemList.name}`}
                truncateMaxWidth={workflowFormWidth}
              />
            ) : (
              <this.TrancatableText text={this.getDefaultItemListName()} truncateMaxWidth={workflowFormWidth} />
            )}
            <ApproveItemListTriangleText>
              <Triangle>{showList ? '▲' : '▼'}</Triangle>
            </ApproveItemListTriangleText>
          </ApproveItemListTextArea>
          {showList && (
            <SelectApproveItemListList
              self={itemList}
              onSelect={this.handleSelect}
              approveItemId={approveItemId}
              chargingDepartmentIds={chargingDepartmentIds}
              tripedit={this.props.tripedit}
              workflowFormWidth={workflowFormWidth}
            />
          )}
        </Wrapper>
      );
    } catch (e) {
      return null;
    }
  }
}

const Wrapper = styled.div`
  margin: 0px;
`;

const ApproveItemListTextArea = styled.div<{ workflowFormWidth?: string }>`
  min-width: 50px;
  width: ${props => props.workflowFormWidth || '420px'}
  flex-wrap: nowrap;
  display: flex;
  border: 2px solid ${props => props.theme.grayBorderColor};
  padding: 0px 10px;
  cursor: pointer;
  line-height: normal;
`;

const ApproveItemListNameText = styled.div`
  height: 30px;
  width: 450px;
  background: ${props => props.theme.fieldBgColor};
  line-height: 30px;
  padding: 0 0;
  cursor: pointer;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ApproveItemListTriangleText = styled.div`
  height: 30px;
  width: 15px;
  background: ${props => props.theme.fieldBgColor};
  line-height: 30px;
  padding: 0 0;
  cursor: pointer;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Triangle = styled.span`
  margin-left: auto;
`;

export default SelectApproveItemList;
