import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  selectedReport: any;
}

const InfoTemplate: React.SFC<Props> = ({ selectedReport }) => (
  <Dl>
    <Dt>旅程番号</Dt>
    <Dd>{selectedReport.tripId}</Dd>
    <Dt>出張報告番号</Dt>
    <Dd>{selectedReport.id}</Dd>
    <Dt>出張者（代表者）</Dt>
    <Dd>{selectedReport.representativeName()}</Dd>
    <Dt>出張期間</Dt>
    <Dd>{`${selectedReport.departureDate.format('YYYY/MM/DD')} ~ ${selectedReport.returnDate.format(
      'YYYY/MM/DD'
    )}`}</Dd>
    <Dt>出張費総額</Dt>
    <Dd>{`${selectedReport.totalPrice().toLocaleString()}円`}</Dd>
    <Dt>社員立替費</Dt>
    <Dd>{`${selectedReport.totalUnpaidPrice().toLocaleString()}円`}</Dd>
  </Dl>
);

const Dl = styled.dl``;

const Dt = styled.dt`
  float: left;
  clear: left;
  margin: 0 0.5em 0 0;
  width: 120px;
`;

const Dd = styled.dd`
  float: left;
  margin-left: 1em;
`;

export default InfoTemplate;
