import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rgba } from 'polished';
import { styled } from '@this/src/components/constants/themes';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type HotelOrderItemRakutenTodo from '@this/src/domain/arrangement/hotel_order_item_rakuten_todo';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import _ from 'lodash';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import LogModal from '../log_modal';

interface Props {
  todo: HotelOrderItemRakutenTodo;
  checked: boolean;
  showDescription: boolean;
  onInventory: () => void;
  onToggle: (checked: boolean) => void;
}

const HotelOrderItemRakutenTodoTableRow = observer(
  ({ todo, checked, showDescription, onInventory, onToggle }: Props) => {
    const [showLogModal, setShowLogModal] = useState<boolean>(false);

    const handleInventory = async () => {
      await Fetcher.put(`/arrangement/hotel_order_item_todos/${todo.orderItemId}/inventory.json`, {});
      onInventory();
    };

    const handleCancelInventory = async () => {
      await Fetcher.put(`/arrangement/hotel_order_item_todos/${todo.orderItemId}/cancel_inventory.json`, {});
      onInventory();
    };

    return (
      <>
        <TableRow style={{ background: todo.isDiff || !todo.orderItemId ? rgba('red', 0.3) : 'auto' }}>
          {/* 一括チェック */}
          <TableCell rowSpan={2}>
            <Checkbox
              checked={checked}
              disabled={!todo.orderItemId || Boolean(todo.inventoriedAt)}
              onChange={e => onToggle(e.target.checked)}
            />
          </TableCell>
          {/* 行説明 */}
          <TableCell>
            <Label>OrderItem</Label>
          </TableCell>
          {/* 予約番号 */}
          <TableCell>{todo.fromOrderItem?.reservationNumber || '─'}</TableCell>
          {/* 宿泊人数 */}
          <TableCell>{todo.fromOrderItem?.peopleNum ? `${todo.fromOrderItem?.peopleNum}人` : '─'}</TableCell>
          {/* 施設名 */}
          <TableCell>{todo.fromOrderItem?.hotelName || '─'}</TableCell>
          {/* 施設住所 */}
          <TableCell>{todo.fromOrderItem?.hotelAddress || '─'}</TableCell>
          {/* 部屋タイプ */}
          <TableCell>{todo.fromOrderItem?.roomType || '─'}</TableCell>
          {/* プラン名 */}
          <TableCell>{todo.fromOrderItem?.planName || '─'}</TableCell>
          {/* 朝食有無 */}
          <TableCell>
            {todo.fromOrderItem?.breakfast === null ? '─' : todo.fromOrderItem.breakfast ? 'あり' : 'なし'}
          </TableCell>
          {/* チェックイン日 */}
          <TableCell>{todo.fromOrderItem?.checkinDate || '─'}</TableCell>
          {/* チェックアウト日 */}
          <TableCell>{todo.fromOrderItem?.checkoutDate || '─'}</TableCell>
          {/* 宿泊代表者名 */}
          <TableCell>{todo.fromOrderItem?.travelerName || '─'}</TableCell>
          {/* 料金 */}
          <TableCell rowSpan={2}>
            <Block>代金合計: {todo.price?.toLocaleString()}円</Block>
            <Block>卸値: {todo.originalPrice?.toLocaleString()}円</Block>
            <Block>楽天側料金: {todo.hotelPrice?.toLocaleString()}円</Block>
          </TableCell>
          {/* 卸値の仕入商品 */}
          <TableCell rowSpan={2}>{todo.suppliedItemName || '─'}</TableCell>
          {/* ステータス */}
          <TableCell rowSpan={2}>
            {todo.orderItemId && (
              <OrderItemStatusForm
                orderItemId={todo.orderItemId}
                jobType={todo.jobType}
                status={todo.status}
                onJobTypeChange={value => {
                  todo.jobType = value;
                }}
                onStatusChange={value => {
                  todo.status = value;
                }}
                showLabel={false}
                fetchData={handleInventory}
              />
            )}
          </TableCell>
          {/* 棚卸ステータス */}
          <TableCell rowSpan={2}>{todo.isInventoried ? '済' : '未'}</TableCell>
          {/* 棚卸実施者 */}
          <TableCell rowSpan={2}>{todo.inventoriedBy}</TableCell>
          {/* 棚卸日時 */}
          <TableCell rowSpan={2}>{todo.inventoriedAt}</TableCell>
          {/* Trip ID */}
          <TableCell rowSpan={2} nowrap={!showDescription}>
            <Link
              href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`}
              target="_blank"
              rel="noopener noreffer"
            >
              {todo.tripId}
            </Link>
          </TableCell>
          {/* Trace ID */}
          <TableCell rowSpan={2} nowrap={!showDescription}>
            {todo.traceId}
          </TableCell>
          {/* 最新のログメッセージ */}
          <TableCell rowSpan={2}>
            {todo.lastLogMessage && <span>{todo.lastLogMessage}</span>}
            {todo.lastLogMessageArranger && <span>({todo.lastLogMessageArranger})</span>}
            {todo.orderItemId && (
              <p>
                (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
              </p>
            )}
          </TableCell>
          {/* アクション */}
          <TableCell rowSpan={2}>
            {todo.orderItemId &&
              (todo.inventoriedAt ? (
                <Button size="small" onClick={() => handleCancelInventory()}>
                  取消
                </Button>
              ) : (
                <Button size="small" onClick={() => handleInventory()}>
                  棚卸完了
                </Button>
              ))}
          </TableCell>
          {showLogModal && todo.traceId && (
            <LogModal traceId={todo.traceId} onClose={() => setShowLogModal(false)} />
          )}
        </TableRow>
        <TableRow style={{ background: Object.keys(todo.diff).length > 0 ? rgba('red', 0.3) : 'auto' }}>
          {/* 行説明 */}
          <TableCell>
            <Label>楽天トラベル</Label>
          </TableCell>
          {/* 予約番号 */}
          <TableCell>{todo.fromRakutenReservation?.reservationNumber || '─'}</TableCell>
          {/* 宿泊人数 */}
          <TableCell>
            {todo.fromRakutenReservation?.peopleNum ? `${todo.fromRakutenReservation?.peopleNum}人` : '─'}
          </TableCell>
          {/* 施設名 */}
          <TableCell>{todo.fromRakutenReservation?.hotelName || '─'}</TableCell>
          {/* 施設住所 */}
          <TableCell>{todo.fromRakutenReservation?.hotelAddress || '─'}</TableCell>
          {/* 部屋タイプ */}
          <TableCell>{todo.fromRakutenReservation?.roomType || '─'}</TableCell>
          {/* プラン名 */}
          <TableCell>{todo.fromRakutenReservation?.planName || '─'}</TableCell>
          {/* 朝食有無 */}
          <TableCell>
            {todo.fromRakutenReservation?.breakfast === null
              ? '─'
              : todo.fromRakutenReservation.breakfast
              ? 'あり'
              : 'なし'}
          </TableCell>
          {/* チェックイン日 */}
          <TableCell>{todo.fromRakutenReservation?.checkinDate || '─'}</TableCell>
          {/* チェックアウト日 */}
          <TableCell>{todo.fromRakutenReservation?.checkoutDate || '─'}</TableCell>
          {/* 宿泊代表者名 */}
          <TableCell>{todo.fromRakutenReservation?.travelerName || '─'}</TableCell>
          {/* 料金 */}
          {/* 卸値の仕入商品 */}
          {/* ステータス */}
          {/* 棚卸ステータス */}
          {/* 棚卸実施者 */}
          {/* 棚卸日時 */}
          {/* Trip ID */}
          {/* Trace ID */}
          {/* 最新のログメッセージ */}
          {/* アクション */}
        </TableRow>
      </>
    );
  }
);

const Block = styled.div``;

const Label = styled.span`
  font-size: 0.8em;
  font-weight: bold;
`;

export default HotelOrderItemRakutenTodoTableRow;
