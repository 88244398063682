import React, { useCallback, useState } from 'react';
import type { PaperProps } from '@material-ui/core';
import { Popover, useMediaQuery } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { sizes } from '@this/components/shared/atoms/media';

const DEFAULT_ROW = 3;

interface Props {
  row?: number;
  width?: string | number;
  spSize?: number;
  PaperProps?: Partial<PaperProps>;
}

interface State {
  anchorEl: HTMLElement | null;
  holding: boolean;
}

const LineClampBox: React.FC<Props> = ({ row, width, spSize, PaperProps: paperProps, children }) => {
  const [state, setState] = useState<State>({ anchorEl: null, holding: false });
  const { anchorEl, holding } = state;
  const sp = useMediaQuery(`(max-width: ${spSize ?? sizes.sp}px)`);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!sp) setState(state => ({ ...state, anchorEl: event.currentTarget }));
    },
    [sp]
  );

  const handleClick = useCallback(() => {
    setState(({ holding, ...state }) => ({ ...state, holding: !holding }));
  }, []);

  const handleMouseLeave = useCallback(() => {
    setState(({ anchorEl, holding, ...state }) => ({ ...state, anchorEl: holding ? anchorEl : null, holding }));
  }, []);

  const handlePopoverClose = useCallback(() => {
    setState(state => ({ ...state, anchorEl: null, holding: false }));
  }, []);

  return (
    <>
      <LineClamp
        row={row}
        spSize={spSize}
        onClick={handleClick}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </LineClamp>
      <Popover
        style={{ pointerEvents: holding ? 'auto' : 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          variant: 'outlined',
          ...paperProps,
          style: {
            width: width ?? 300,
            fontSize: 12,
            padding: 8,
            whiteSpace: 'pre-wrap',
            backgroundColor: '#eee',
            ...paperProps?.style
          }
        }}
      >
        {children}
      </Popover>
    </>
  );
};

const LineClamp = styled.div<Pick<Props, 'row' | 'spSize'>>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.row ?? DEFAULT_ROW};
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${props => `
    @media (max-width: ${props.spSize ?? sizes.sp}px) {
      display: block;
    }
  `}
`;

export default LineClampBox;
