import React, { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import type { SendList } from '@this/domain/send_list/send_list';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Link } from '@this/shared/ui/navigations/link';
import { Loading } from '@this/shared/ui/feedbacks/loading/loading';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import {
  OrderItemSupplierItemSelect,
  OrderItemPaymentMethodSelect
} from '@this/components/arrangement/shared/price_change_form';
import { Box, Button, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import type { YamatoShippingCostArgs } from '@this/domain/yamato_shipping_cost';
import YamatoShippingCost from '@this/domain/yamato_shipping_cost';
import type ShippingCost from '@this/domain/shipping_cost';
import type PaymentMethodList from '@this/domain/payment_method/payment_method_list';
import { Fetcher } from '@this/src/util';

interface YamatoShippingCostsResponse {
  costs: YamatoShippingCostArgs[];
}

interface EcohaisResponse {
  ecohais: { cost_id: number; price: number; original_price: number }[];
}

type Props = {
  suppliedItems: SuppliedItem[];
  suppliedItemLoading: boolean;
  paymentMethods: PaymentMethodList | null;
  paymentMethodLoading: boolean;
  list: SendList;
  onSubmit: () => void;
};

const ShippingCostForm: React.FC<Props> = ({
  suppliedItems,
  suppliedItemLoading,
  paymentMethods,
  paymentMethodLoading,
  list,
  onSubmit
}: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [fetchingPrices, setfetchingPrices] = useState(false);
  const [deleteTargetShippingCost, setDeleteTargetShippingCost] = useState<ShippingCost | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const handleSubmit = async () => {
    setSubmitting(true);
    const params = list.shippingCostsSubmitParams();
    await Fetcher.put(`/arrangement/send_list2/${list.id}/`, params);
    setSubmitting(false);
    onSubmit();
  };

  const handleDelete = async () => {
    if (deleteTargetShippingCost) {
      setDeleteError(null);
      try {
        await Fetcher.delete(`/arrangement/shipping_costs/${deleteTargetShippingCost.id}.json`);
        const idx = list.shippingCosts.findIndex(cost => cost.id === deleteTargetShippingCost.id);
        list.shippingCosts.splice(idx, 1);
        setDeleteTargetShippingCost(null);
      } catch {
        setDeleteError('エラーが発生しました。');
      }
    }
  };

  const handleClickDeleteButton = (shippingCost: ShippingCost) => {
    setDeleteTargetShippingCost(shippingCost);
  };

  const assignOriginalPriceFromCosts = (costs: YamatoShippingCost[]) => {
    costs.forEach(cost => {
      const shippingCost = list.shippingCosts.find(c => c.trackingNumber === cost.trackingNumber);
      if (shippingCost && !shippingCost.originalPrice) {
        shippingCost.originalPrice = cost.fareTotal;
      }
    });
  };

  const assignPricesFromEcohais = (res: EcohaisResponse) => {
    const ecohais = res.ecohais;
    ecohais.forEach(ecohai => {
      const shippingCost = list.shippingCosts.find(cost => cost.id === ecohai.cost_id);
      if (shippingCost && !shippingCost.price && !shippingCost.originalPrice) {
        shippingCost.price = ecohai.price;
        shippingCost.originalPrice = ecohai.original_price;
      }
    });
  };

  const fetchYamatoShippingCosts = async () => {
    const trackingNumbers = list.shippingCosts.map(cost => cost.trackingNumber);
    const params = { 'shipping_cost_tracking_numbers[]': trackingNumbers };
    const res = await Fetcher.get<YamatoShippingCostsResponse>('/arrangement/yamato_shipping_costs.json', params);
    const costs = res.costs.map(cost => new YamatoShippingCost(cost));
    assignOriginalPriceFromCosts(costs);
  };

  const fetchEcohais = async () => {
    const shippingCostIds = list.shippingCosts.map(cost => cost.id);
    const params = { shipping_cost_ids: shippingCostIds };
    const res = await Fetcher.get<EcohaisResponse>('/arrangement/ecohais.json', params);
    assignPricesFromEcohais(res);
  };

  const fetchPrices = async () => {
    setfetchingPrices(true);
    await fetchYamatoShippingCosts();
    await fetchEcohais();
    setfetchingPrices(false);
  };

  return (
    <>
      <form>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>送料ID</TableCell>
              <TableCell>
                送付先住所
                <br />
                宛名
              </TableCell>
              <TableCell>送料(税込)</TableCell>
              <TableCell>発送日</TableCell>
              <TableCell>到着予定日</TableCell>
              <TableCell>到着日</TableCell>
              <TableCell>請求対象日</TableCell>
              <TableCell>追跡番号</TableCell>
              <TableCell nowrap>TripID</TableCell>
              <TableCell>卸値</TableCell>
              <TableCell>種別</TableCell>
              <TableCell>仕入商品</TableCell>
              <TableCell>決済方法</TableCell>
              <TableCell>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <p>削除</p>
                  <Tooltip
                    title={
                      <p>
                        【送付先住所・宛名】【追跡番号】が空欄かつ、【送料】【卸値】が空欄または0円のものが削除できます。
                      </p>
                    }
                  >
                    <Info fontSize="small" style={{ color: '#999' }} />
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.shippingCosts.map(cost => (
              <TableRow key={cost.id}>
                <TableCell>{cost.id}</TableCell>
                <TableCell>
                  {cost.address}
                  <br />
                  {cost.addressee}
                </TableCell>
                <TableCell>
                  <FlexBox>
                    <input
                      type="text"
                      value={cost.price || 0}
                      onChange={e => {
                        cost.price = Number(e.target.value);
                      }}
                      style={{ width: '50px' }}
                    />
                    <span>円</span>
                  </FlexBox>
                </TableCell>
                <TableCell>
                  <DatetimePicker
                    value={cost.shippedAt || moment()}
                    onChange={d => {
                      cost.shippedAt = d;
                    }}
                    showTime={false}
                    showPast
                    disabledDays={0}
                    width="100"
                  />
                </TableCell>
                <TableCell>
                  <DatetimePicker
                    value={cost.expectedArrivedAt || moment().add(1, 'days')}
                    onChange={d => {
                      cost.expectedArrivedAt = d;
                    }}
                    showTime={false}
                    showPast
                    disabledDays={0}
                    width="100"
                  />
                </TableCell>
                <TableCell>
                  <DatetimePicker
                    value={cost.arrivedAt || undefined}
                    onChange={d => {
                      cost.arrivedAt = d;
                    }}
                    showTime={false}
                    showPast
                    disabledDays={0}
                    width="100"
                  />
                </TableCell>
                <TableCell>
                  <DatetimePicker
                    value={cost.billingTarget || undefined}
                    onChange={d => {
                      cost.billingTarget = d;
                    }}
                    showTime={false}
                    showPast
                    disabledDays={0}
                    width="100"
                  />
                </TableCell>
                <TableCell>
                  <input
                    type="text"
                    value={cost.trackingNumber || ''}
                    onChange={e => {
                      cost.trackingNumber = e.target.value;
                    }}
                    style={{ width: '100px' }}
                  />
                </TableCell>
                <TableCell nowrap>
                  {cost.sendListItems.map(item => (
                    <p key={`p_${item.id}`}>
                      <Link key={item.id} isExternal href={`/arrangement/virtual_counter?trip_id=${item.tripId}`}>
                        {item.tripId}
                      </Link>
                    </p>
                  ))}
                </TableCell>
                <TableCell>
                  <FlexBox>
                    <input
                      type="text"
                      value={cost.originalPrice || 0}
                      onChange={e => {
                        cost.originalPrice = Number(e.target.value);
                      }}
                      style={{ width: '50px' }}
                    />
                    <span>円</span>
                  </FlexBox>
                </TableCell>
                <TableCell>
                  <select
                    value={cost.shippingType}
                    onChange={e => {
                      cost.shippingType = Number(e.target.value);
                    }}
                  >
                    <option value={1}>通常</option>
                    <option value={2}>バイク便</option>
                  </select>
                </TableCell>
                <TableCell>
                  {suppliedItemLoading ? (
                    <Loading />
                  ) : (
                    <OrderItemSupplierItemSelect
                      suppliedItems={_.filter(suppliedItems, item => item.disabled === false)}
                      onSelect={(v: number) => {
                        cost.suppliedItemId = v;
                      }}
                      suppliedItemId={cost.suppliedItemId}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {paymentMethodLoading || !paymentMethods ? (
                    <Loading />
                  ) : (
                    <OrderItemPaymentMethodSelect
                      paymentMethods={paymentMethods}
                      onSelect={(v: number) => {
                        cost.paymentMethodId = v > 0 ? v : null;
                      }}
                      paymentMethodId={cost.paymentMethodId}
                      suppliedItem={_.find(suppliedItems, s => s.id === cost.suppliedItemId) || null}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    disabled={!cost.isDeletable}
                    onClick={() => handleClickDeleteButton(cost)}
                  >
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ButtonWrapper>
          <button type="button" onClick={() => handleSubmit()} disabled={submitting}>
            保存
          </button>
          <Box className="flex" style={{ marginRight: '20px', alignItems: 'center' }}>
            <Button
              variant="outlined"
              type="button"
              style={{ marginRight: '3px' }}
              disabled={fetchingPrices}
              onClick={() => fetchPrices()}
            >
              {fetchingPrices ? <Loading size="small" /> : '卸値を取得'}
            </Button>
            <Tooltip
              title={
                <p>
                  事前にアップロードしたヤマト配送料金のCSVファイルから、追跡番号を元に卸値を反映します。
                  <br />
                  また、エコ配の配送料マスタをから宛先の住所をもとに販売値・卸値を反映します。
                  <br />
                  既に販売値・卸値が設定してある場合は反映されません。
                  <br />
                  保存はされませんので、反映後に保存ボタンを押してください。
                </p>
              }
            >
              <Info fontSize="small" style={{ color: '#999' }} />
            </Tooltip>
          </Box>
        </ButtonWrapper>
      </form>
      <Modal open={!!deleteTargetShippingCost} onClose={() => setDeleteTargetShippingCost(null)}>
        <ModalHeader>配送料の削除</ModalHeader>
        <ModalBody>
          <p>配送料を削除します。よろしいですか？</p>
          {deleteError && <p className="error">{deleteError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button variant="contained" onClick={() => setDeleteTargetShippingCost(null)}>
            キャンセル
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleDelete()}>
            削除
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 20px;
`;

export default observer(ShippingCostForm);
