import type { Dispatch } from 'react';
import React, { useCallback, useEffect, useReducer } from 'react';
import type OrderItemStepTodo from '@this/src/domain/arrangement/order_item_step_todo';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/src/components/shared/ui/feedbacks/modal';
import { styled } from '@this/src/components/constants/themes';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type { OrderItemStepTodoFieldStoreResponseArgs } from '@this/src/domain/arrangement/order_item_step_todo_field_store';
import {
  OrderItemStepTodoFieldStore,
  convertOrderItemStepTodoFieldStoreResponseArgs
} from '@this/src/domain/arrangement/order_item_step_todo_field_store';
import type { HTTPError } from '@this/src/util';
import { Fetcher } from '@this/src/util';
import OrderItemStepWorkDialogContent from './order_item_step_work_dialog_content';
import OrderItemStepDurationCounter from './order_item_step_duration_counter';

interface Props {
  serviceId: number;
  todo: OrderItemStepTodo | null;
  onClose: (isFetch?: boolean) => void;
}

type CriticalError = { type: 'error' | 'message'; message: string } | { type: 'trip_id'; tripId: number };

interface State {
  loading: boolean;
  updating: boolean;
  orderItemStepTodoFieldStore: OrderItemStepTodoFieldStore | null;
  errors: string[];
  criticalErrors: CriticalError[];
}

type Action =
  | { type: 'INIT' }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_UPDATING'; payload: boolean }
  | { type: 'SET_ERRORS'; payload?: string[] }
  | { type: 'FETCH_ORDER_ITEM'; payload: OrderItemStepTodoFieldStoreResponseArgs }
  | { type: 'FETCH_ERRORS'; payload: HTTPError };

interface Request {
  todo: OrderItemStepTodo | null;
  dispatch: Dispatch<Action>;
}

interface PauseRequest extends Request {
  fetch: (isFetch?: boolean) => void;
}

interface CompleteRequest extends Omit<Request, 'todo'> {
  orderItemStepTodoFieldStore: OrderItemStepTodoFieldStore;
  fetch: (isFetch?: boolean) => void;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT':
      return initialState;
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_UPDATING':
      return { ...state, updating: action.payload };
    case 'SET_ERRORS':
      return { ...state, errors: action.payload ?? [], criticalErrors: [] };
    case 'FETCH_ORDER_ITEM':
      return {
        ...state,
        loading: false,
        orderItemStepTodoFieldStore: new OrderItemStepTodoFieldStore(
          convertOrderItemStepTodoFieldStoreResponseArgs(action.payload)
        )
      };
    case 'FETCH_ERRORS': {
      const message = action.payload.response?.data.message;
      if (!message) {
        const errors = action.payload.response?.data.errors ?? [];
        return { ...state, errors: typeof errors === 'string' ? [errors] : errors };
      }

      const criticalErrors: CriticalError[] = [];
      (Array.isArray(message) ? message : [message]).forEach(msg => {
        criticalErrors.push({ type: 'error', message: msg });
      });
      const description = action.payload.response?.data.description;

      (Array.isArray(description) ? description : []).forEach(msg => {
        criticalErrors.push({ type: 'message', message: msg });
      });
      if (action.payload.response?.data.trip_id) {
        criticalErrors.push({ type: 'trip_id', tripId: action.payload.response?.data.trip_id });
      }
      return { ...state, criticalErrors };
    }
    default:
      return state;
  }
};

const fetchOrderItem = async ({ todo, dispatch }: Request) => {
  dispatch({ type: 'INIT' });
  if (!todo) return;

  Fetcher.get<OrderItemStepTodoFieldStoreResponseArgs>(`/arrangement/order_item/${todo.traceId}`)
    .then(response => {
      dispatch({ type: 'FETCH_ORDER_ITEM', payload: response });
    })
    .catch(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
    });
};

const fetchPause = ({ todo, dispatch, fetch }: PauseRequest) => {
  dispatch({ type: 'SET_UPDATING', payload: true });
  Fetcher.post('/arrangement/order_item_step_todos/todo_action.json', {
    order_item_step_id: todo?.id,
    action_type: 'pause'
  })
    .then(() => {
      fetch(true);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_ERRORS', payload: error });
      fetch();
    })
    .finally(() => {
      dispatch({ type: 'SET_UPDATING', payload: false });
    });
};

const fetchComplete = ({ orderItemStepTodoFieldStore, dispatch, fetch }: CompleteRequest) => {
  dispatch({ type: 'SET_UPDATING', payload: true });
  Fetcher.post('/arrangement/order_item_step_todos.json', orderItemStepTodoFieldStore.updateParams())
    .then(() => {
      fetch(true);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_ERRORS', payload: error });
    })
    .finally(() => {
      dispatch({ type: 'SET_UPDATING', payload: false });
    });
};

const initialState: State = {
  loading: true,
  updating: false,
  orderItemStepTodoFieldStore: null,
  errors: [],
  criticalErrors: []
};

const OrderItemStepWorkDialog: React.FC<Props> = ({ serviceId, todo, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, updating, orderItemStepTodoFieldStore, errors, criticalErrors } = state;
  const stateTodo = orderItemStepTodoFieldStore?.todo;

  const handleClose = useCallback(() => {
    if (stateTodo?.stepStatus === 'started') {
      fetchPause({ todo: stateTodo, dispatch, fetch: onClose });
    } else {
      onClose();
    }
  }, [stateTodo, dispatch, onClose]);

  const handleSecondaryClose = useCallback(() => {
    dispatch({ type: 'SET_ERRORS' });
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch({ type: 'SET_ERRORS' });
    if (orderItemStepTodoFieldStore) {
      fetchComplete({ orderItemStepTodoFieldStore, dispatch, fetch: onClose });
    } else {
      dispatch({ type: 'SET_ERRORS', payload: ['TODOが選択されていません'] });
    }
  }, [orderItemStepTodoFieldStore, dispatch, onClose]);

  useEffect(() => {
    fetchOrderItem({ todo, dispatch });
  }, [todo, dispatch]);

  return (
    <>
      <Modal open={Boolean(todo)} size="large" onClose={handleClose}>
        <ModalHeader>作業を行ってください</ModalHeader>
        <ModalBody>
          {loading ? (
            <SimpleLoading />
          ) : !orderItemStepTodoFieldStore ? (
            <Errors>
              <p>商品情報が取得できませんでした</p>
            </Errors>
          ) : (
            <div>
              <OrderItemStepWorkDialogContent
                serviceId={serviceId}
                orderItemStepTodoFieldStore={orderItemStepTodoFieldStore}
                errors={errors}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {stateTodo && <OrderItemStepDurationCounter todo={stateTodo} />}
          <Button color="sub" disabled={loading} loading={updating} onClick={handleClose}>
            一時停止
          </Button>
          <Button color="primary" disabled={loading} loading={updating} onClick={handleSubmit}>
            完了
          </Button>
        </ModalFooter>
      </Modal>
      <Modal open={criticalErrors.length > 0} onClose={handleSecondaryClose}>
        <ModalHeader>エラーが発生しました</ModalHeader>
        <ModalBody>
          {criticalErrors.map((error, index) =>
            error.type === 'error' ? (
              <Error key={index}>{error.message}</Error>
            ) : error.type === 'message' ? (
              <Message key={index}>{error.message}</Message>
            ) : error.type === 'trip_id' ? (
              <Message key={index}>
                旅程番号：
                <Link href={`/arrangement/virtual_counter?trip_id=${error.tripId}`} target="_blank">
                  {error.tripId}
                </Link>
              </Message>
            ) : null
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSecondaryClose}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const Errors = styled.div`
  color: ${props => props.theme.redColor};
`;

const Error = styled.p`
  color: ${props => props.theme.redColor};
`;

const Message = styled.p``;

export default OrderItemStepWorkDialog;
