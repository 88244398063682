import type { TabikoboReservationJson } from '@this/domain/market_log/tabikobo_reservation';
import TabikoboReservation from '@this/domain/market_log/tabikobo_reservation';

export interface TabikoboAviationJson {
  flight_number: string | null;
  class_name: string | null;
  boarding_date: string | null;

  tabikobo_reservation: TabikoboReservationJson | null;
}

class TabikoboAviation {
  flightNumber: string | null;

  className: string | null;

  boardingDate: string | null;

  tabikoboReservation: TabikoboReservation | null;

  constructor(args: TabikoboAviationJson) {
    this.flightNumber = args.flight_number;
    this.className = args.class_name;
    this.boardingDate = args.boarding_date;

    this.tabikoboReservation = null;
    if (args.tabikobo_reservation) {
      this.tabikoboReservation = new TabikoboReservation(args.tabikobo_reservation);
    } else {
      this.tabikoboReservation = null;
    }
  }
}

export default TabikoboAviation;
