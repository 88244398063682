import moment from '@this/lib/moment';
import type { Approver } from '@this/domain/trip_report/approver';
import type { Moment } from '@this/lib/moment';
import type { InAdvanceApproverCandidateArgs } from '@this/domain/in_advance_approver_candidate';
import { InAdvanceApproverCandidate } from '@this/domain/in_advance_approver_candidate';

export interface InAdvanceApprovalArgs {
  id: number;
  trip_id: number;
  applicant_id: number;
  approver_id: number;
  status: number;
  reject_reason: string;
  cancel_reason: string;
  approve_reason: string;
  approved_at: string;
  approve_stage: number;
  approval_channel_id: number;
  rejected_at: string;
  approver?: Approver;
  in_advance_approver_candidates: InAdvanceApproverCandidateArgs[];
}

export class InAdvanceApproval {
  id: number;

  tripId: number;

  applicantId: number;

  approverId: number;

  status: number;

  rejectReason: string;

  cancelReason: string;

  approveReason: string;

  approvedAt: Moment | null;

  rejectedAt: Moment | null;

  approveStage: number;

  approvalChannelId: number;

  approver: Approver | null;

  inAdvanceApproverCandidates: InAdvanceApproverCandidate[] = [];

  constructor(args: InAdvanceApprovalArgs) {
    this.id = args.id;
    this.tripId = args.trip_id;
    this.applicantId = args.applicant_id;
    this.approverId = args.approver_id;
    this.status = args.status;
    this.rejectReason = args.reject_reason;
    this.cancelReason = args.cancel_reason;
    this.approveReason = args.approve_reason;
    this.approvedAt = args.approved_at ? moment(args.approved_at) : null;
    this.rejectedAt = args.rejected_at ? moment(args.rejected_at) : null;
    this.approveStage = args.approve_stage;
    this.approvalChannelId = args.approval_channel_id;
    this.approver = args.approver ? args.approver : null;
    this.inAdvanceApproverCandidates = args.in_advance_approver_candidates.map(
      candidate => new InAdvanceApproverCandidate(candidate)
    );
  }

  statusName() {
    switch (this.status) {
      case 0:
        return '承認待ち';
      case 1:
        return '承認済み';
      case 2:
        return '却下';
      case 3:
        return 'キャンセル';
      case 4:
        return '申請中';
      default:
        return '';
    }
  }
}
