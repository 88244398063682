import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import type { HotelElementProviderKey } from '@this/src/domain/hotel_element_provider';
import { SearchArea, SearchBlock, SearchLabel } from '../../search_area';
import OrderItemStatusSelector from '../../order_item_status_selector';
import HotelProviderSelector from '../../hotel_provider_selector';
import DateRangePicker from '../../date_range_picker';
import type { AutoApprovedStatus, InventoryStatus, SortDirection, SortKey } from '../hotel_order_item_todo_list';

interface Props {
  isRakutenTab: boolean;
  jobTypes: OrderItemJobTypeKey[];
  statuses: OrderItemStatusKey[];
  autoApprovedStatus: AutoApprovedStatus;
  inventoryStatus: InventoryStatus;
  providers: HotelElementProviderKey[];
  tripId?: number;
  startDateFrom?: Moment;
  startDateTo?: Moment;
  receiveDateFrom?: Moment;
  receiveDateTo?: Moment;
  showDescription: boolean;
  logExists: string;
  sortKey: SortKey;
  sortDirection: SortDirection;
  isPaginate: boolean;
  isExternal: boolean;
  isDiff: boolean;
  isNotDiff: boolean;
  setJobTypes: (jobTypes: OrderItemJobTypeKey[]) => void;
  setStatuses: (statuses: OrderItemStatusKey[]) => void;
  setAutoApprovedStatus: (autoApprovedStatus: AutoApprovedStatus) => void;
  setInventoryStatus: (inventoryStatus: InventoryStatus) => void;
  setProviders: (providers: HotelElementProviderKey[]) => void;
  setTripId: (tripId?: number) => void;
  setStartDateFrom: (startDateFrom?: Moment) => void;
  setStartDateTo: (startDateTo?: Moment) => void;
  setReceiveDateFrom: (receiveDateFrom?: Moment) => void;
  setReceiveDateTo: (receiveDateTo?: Moment) => void;
  setShowDescription: (showDescription: boolean) => void;
  setLogExists: (logExists: string) => void;
  setSortKey: (sortKey: SortKey) => void;
  setSortDirection: (sortDirection: SortDirection) => void;
  setIsPaginate: (isPaginate: boolean) => void;
  setIsExternal: (isExternal: boolean) => void;
  setIsDiff: (isDiff: boolean) => void;
  setIsNotDiff: (isNotDiff: boolean) => void;
  renderOrganizationSelector: () => JSX.Element;
}

const HotelOrderItemTodoSearchArea: React.FC<Props> = ({
  isRakutenTab,
  jobTypes,
  statuses,
  autoApprovedStatus,
  inventoryStatus,
  providers,
  tripId,
  startDateFrom,
  startDateTo,
  receiveDateFrom,
  receiveDateTo,
  showDescription,
  logExists,
  sortKey,
  sortDirection,
  isPaginate,
  isExternal,
  isDiff,
  isNotDiff,
  setJobTypes,
  setStatuses,
  setAutoApprovedStatus,
  setInventoryStatus,
  setProviders,
  setTripId,
  setStartDateFrom,
  setStartDateTo,
  setReceiveDateFrom,
  setReceiveDateTo,
  setShowDescription,
  setLogExists,
  setSortKey,
  setSortDirection,
  setIsPaginate,
  setIsExternal,
  setIsDiff,
  setIsNotDiff,
  renderOrganizationSelector
}) => {
  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>商品ステータス</SearchLabel>
        <OrderItemStatusSelector
          selectedOrderItemJobTypes={jobTypes}
          onChangeSelectedOrderItemJobTypes={setJobTypes}
          selectedOrderItemStatuses={statuses}
          onChangeSelectedOrderItemStatuses={setStatuses}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>自動承認</SearchLabel>
        <Select
          value={autoApprovedStatus}
          onChange={e => setAutoApprovedStatus(e.target.value as AutoApprovedStatus)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="auto">自動のみ</option>
          <option value="manual">手動のみ</option>
          <option value="all">全て</option>
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>棚卸ステータス</SearchLabel>
        <Select
          value={inventoryStatus}
          onChange={e => setInventoryStatus(e.target.value as InventoryStatus)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="not_yet">未</option>
          <option value="done">済</option>
          <option value="all">全て</option>
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>プロバイダ</SearchLabel>
        <HotelProviderSelector
          selectedHotelProviders={providers}
          onChangeSelectedHotelProviders={setProviders}
          disabled={isRakutenTab}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>Trip ID</SearchLabel>
        <Input
          value={tripId || ''}
          onChange={e => {
            if (_.isEmpty(e.target.value)) {
              setTripId(undefined);
            } else {
              setTripId(parseInt(e.target.value, 10));
            }
          }}
          style={{ width: '80px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>出発日</SearchLabel>
        <DateRangePicker
          from={startDateFrom}
          to={startDateTo}
          onFromChange={d => setStartDateFrom(d)}
          onToChange={d => setStartDateTo(d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>依頼日</SearchLabel>
        <DateRangePicker
          from={receiveDateFrom}
          to={receiveDateTo}
          onFromChange={d => setReceiveDateFrom(d)}
          onToChange={d => setReceiveDateTo(d)}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      <SearchBlock>
        <Checkbox checked={showDescription} onChange={e => setShowDescription(e.target.checked)}>
          <b style={{ fontSize: '12px' }}>詳細表示</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>ログメッセージ</SearchLabel>
        <Select
          value={logExists}
          onChange={e => setLogExists(e.target.value)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="all">全て</option>
          <option value="exists">あり</option>
          <option value="not_exists">なし</option>
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>並び順</SearchLabel>
        <Select
          value={sortKey}
          onChange={e => setSortKey(e.target.value as SortKey)}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          <option value="received_at">依頼日</option>
          <option value="started_at">出発日</option>
        </Select>
        <Radio
          checked={sortDirection === 'asc'}
          onChange={() => setSortDirection('asc')}
          style={{ marginRight: '5px' }}
        >
          昇順▲
        </Radio>
        <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
          降順▼
        </Radio>
      </SearchBlock>
      {isRakutenTab && (
        <>
          <SearchBlock>
            <Checkbox checked={isPaginate} onChange={e => setIsPaginate(e.target.checked)}>
              <b style={{ fontSize: '12px' }}>ページネーションを利用</b>
            </Checkbox>
          </SearchBlock>
          <SearchBlock>
            <Checkbox checked={isExternal} onChange={e => setIsExternal(e.target.checked)}>
              <b style={{ fontSize: '12px' }}>未登録の予約を表示</b>
            </Checkbox>
          </SearchBlock>
          <SearchBlock>
            <Checkbox checked={isDiff} disabled={isExternal} onChange={e => setIsDiff(e.target.checked)}>
              <b style={{ fontSize: '12px' }}>差異あり</b>
            </Checkbox>
          </SearchBlock>
          <SearchBlock>
            <Checkbox checked={isNotDiff} disabled={isExternal} onChange={e => setIsNotDiff(e.target.checked)}>
              <b style={{ fontSize: '12px' }}>差異なし</b>
            </Checkbox>
          </SearchBlock>
        </>
      )}
    </SearchArea>
  );
};

export default HotelOrderItemTodoSearchArea;
