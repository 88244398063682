import React from 'react';
import bugsnagReact from '@bugsnag/plugin-react';

// eslint-disable-next-line import/no-mutable-exports
let ErrorBoundary = (props: any) => {
  return <div>{props.children}</div>;
};
if (typeof bugsnagClient !== 'undefined') {
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
}

export default ErrorBoundary;
