import type { CSSProperties } from 'react';
import React from 'react';
import Dropzone from 'react-dropzone';

import Link from '@this/shared/atoms/link';
import { styled } from '@this/constants/themes';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { FileUploadOutlinedIcon } from '@this/components/expenses/icons';

interface Props {
  loading: boolean;
  historyPath: string;
  validateResult?: FileValidatorResult;
  uploadResult?: any;
  dropZoneStyle?: CSSProperties;
  select: (file?: File) => void;
}

const CsvDropzone: React.FC<Props> = ({
  loading,
  historyPath,
  validateResult,
  uploadResult,
  dropZoneStyle,
  select
}) => {
  const inputProps = { id: 'droppedFile' };
  let errors = [];
  if (validateResult && validateResult.errors) {
    errors = validateResult.errors;
  }
  if (uploadResult && uploadResult.errors) {
    errors = uploadResult.errors;
  }

  return (
    <StyledDropzone
      accept=".csv"
      onDrop={(files: File[]) => select(files[0])}
      inputProps={inputProps}
      multiple={false}
      disabled={loading || uploadResult}
      style={dropZoneStyle}
    >
      {(() => {
        if (loading) {
          return <img src="/images/loading.gif" width={50} height={50} />;
        }
        if (errors.length) {
          return (
            <ul>
              {errors.map((e: string, i: number) => (
                <li key={i}>
                  <ErrorText>{e}</ErrorText>
                </li>
              ))}
            </ul>
          );
        }
        if (validateResult) {
          if (uploadResult) {
            return (
              <div>
                <SuccessText>{`${validateResult.rowCount}件をアップロードしました`}</SuccessText>
                <p>
                  アップロード結果は
                  <Link to={historyPath}>アップロード履歴</Link>
                  から確認できます。
                </p>
              </div>
            );
          }
          return <p>{`${validateResult.file!.name}(${validateResult.rowCount}件)`}</p>;
        }
        return (
          <>
            <FileUploadOutlinedIconWrapper>
              <FileUploadOutlinedIcon color="#927230" width="80px" height="80px" />
            </FileUploadOutlinedIconWrapper>
            <p>
              ここへファイルをドラッグ&amp;ドロップ
              <br />
              もしくは
              <br />
              クリックしてファイルを指定
            </p>
          </>
        );
      })()}
    </StyledDropzone>
  );
};

const StyledDropzone = styled(Dropzone)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 439px);
  background: rgba(146, 114, 48, 0.05);
  margin: 10px 0;
  cursor: pointer;
  min-height: 170px;
`;

const SuccessText = styled.p`
  color: ${props => props.theme.successColor};
`;

const ErrorText = styled.p`
  color: ${props => props.theme.redColor};
`;

const FileUploadOutlinedIconWrapper = styled.div`
  opacity: 0.5;
`;

export default CsvDropzone;
