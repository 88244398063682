import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { styled } from '@this/constants/themes';

import type Invoice from '@this/domain/invoice/invoice2';
import type InvoiceCustomFormat from '@this/src/domain/invoice_custom_format/invoice_custom_format';
import { InvoiceTableRow } from './invoice_table_row';

interface Props {
  invoices: Invoice[];
  invoiceCustomFormats: InvoiceCustomFormat[];
  encoding: string;
  setShowExternalInvoice: Dispatch<SetStateAction<Invoice | null>>;
}

export const InvoiceTable: React.FC<Props> = ({
  invoices,
  invoiceCustomFormats,
  encoding,
  setShowExternalInvoice
}) => {
  return (
    <Table>
      <tbody>
        <tr>
          <Th>対象期間</Th>
          <Th>請求日</Th>
          <Th>合計金額</Th>
          <Th>お支払い期限</Th>
          <Th>CSV</Th>
          {invoiceCustomFormats.length > 0 && <Th>カスタムCSV</Th>}
          <Th>PDF</Th>
          <Th>外部連携</Th>
        </tr>
        {invoices.map(invoice => (
          <InvoiceTableRow
            key={invoice.id}
            invoice={invoice}
            invoiceCustomFormats={invoiceCustomFormats}
            encoding={encoding}
            setShowExternalInvoice={setShowExternalInvoice}
          />
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: auto !important;
  table-layout: auto !important;
`;

const Th = styled.th`
  padding: 4px;
`;
