import React from 'react';

interface Props {
  label: string;
  value: string;
  options: { [value: string]: string };
  example: string;
  error?: string;
  className?: string;
  onChange: (value: string) => void;
}

interface State {
  editing: boolean;
}

class SelectField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false
    };
  }

  handleFocus() {
    this.setState({ editing: true });
  }

  handleBlur() {
    if (this.props.value.length === 0) {
      this.setState({ editing: false });
    }
  }

  render() {
    try {
      const { label, value, options, example, error, className, onChange } = this.props;
      const { editing } = this.state;
      const classBase = 'form-elements-select-field';
      const errorClass = error ? ' error' : '';
      const customClass = className ? ` ${className}` : '';
      return (
        <div className={`${classBase}${errorClass}${customClass}`}>
          <div className={`${classBase}__label`}>{editing && label}</div>
          <div className={`${classBase}__select-wrapper`}>
            <select
              className={`${classBase}__select`}
              value={value}
              onChange={e => onChange(e.target.value)}
              onFocus={() => this.handleFocus()}
              onBlur={() => this.handleBlur()}
            >
              <option value="">{example}</option>
              {Object.keys(options).map((value: string) => (
                <option key={value} value={value}>
                  {options[value]}
                </option>
              ))}
            </select>
          </div>
          <div className={`${classBase}__bottom`}>{error}</div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectField;
