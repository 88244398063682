import React, { useCallback, useState } from 'react';
import type Trip from '@this/domain/trip/trip';
import type { Message as MessageType } from '@this/components/trips_management/trips/trips_chat/types';
import PartnerMessage from './partner_message';
import Logs from './logs';
import UserMessage from './user_message';

interface Props {
  message: MessageType;
  selectedTrip: Trip;
  fetchMessages: (trip: Trip) => void;
  handleMessageEdit: (messageId: number, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const Message: React.FC<Props> = props => {
  const { message } = props;
  const [showMessage, setShowMessage] = useState(false);

  const toggleShowMessage = useCallback(() => {
    setShowMessage(prev => !prev);
  }, []);

  if (message.type === 'partner') {
    return <PartnerMessage {...props} />;
  }
  if (message.type === 'user') {
    return <UserMessage {...props} />;
  }
  if (['partner_log', 'status_log', 'order_item_status_log', 'order_item_billed_at_log'].includes(message.type)) {
    return <Logs {...props} showMessage={showMessage} toggleShowMessage={toggleShowMessage} />;
  }

  return null;
};
