import * as React from 'react';
import { OrganizationTdButton } from '@this/components/organization/organization.style';
import { useConfirmModal } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import { ButtonType } from '@this/shared/atoms/button';

type Props = {
  className?: string;
  targetLabel: string;
  onConfirm: () => void;
};

export const DeleteMasterButton = ({ className, targetLabel, onConfirm }: Props) => {
  const { open } = useConfirmModal();
  const handleClick = async () => {
    const { result } = await open({
      description: [
        `「${targetLabel}」を本当に削除してよろしいですか？`,
        '※ この操作は取り消せません。他のデータと関連性ある場合、削除できません。'
      ],
      submitButtonLabel: '削除',
      color: 'danger',
      className
    });
    if (result) {
      onConfirm();
    }
  };

  return (
    <OrganizationTdButton buttonType={ButtonType.DANGER} onClick={handleClick}>
      削除
    </OrganizationTdButton>
  );
};
