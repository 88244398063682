export interface TabikoboReservationJson {
  reception_date: string | null;
  number_of_participants: string | null;
  payment_total: string | null;
}

class TabikoboReservation {
  receptionDate: string | null;

  numberOfParticipants: number | null;

  paymentTotal: number | null;

  constructor(args: TabikoboReservationJson) {
    this.receptionDate = args.reception_date;

    if (args.number_of_participants) {
      this.numberOfParticipants = parseInt(args.number_of_participants, 10);
    } else {
      this.numberOfParticipants = null;
    }

    if (args.payment_total) {
      this.paymentTotal = parseInt(args.payment_total, 10);
    } else {
      this.paymentTotal = null;
    }
  }

  // 1 人あたりのチケット料金。
  paymentPerPerson(): number | null {
    if (this.paymentTotal && this.numberOfParticipants) {
      return this.paymentTotal / this.numberOfParticipants;
    }
    return null;
  }
}

export default TabikoboReservation;
