import React from 'react';
import _ from 'lodash';

import { darken } from 'polished';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import { Block } from './search_block/search_block.template';

import SearchBlock from './search_block/search_block';
import SearchTravelersBlock from './search_travelers_block/search_travelers_block';
import SearchPeoplenumBlock from './search_peoplenum_block/search_peoplenum_block.template';
import SearchHotelOptionBlock from './search_hotel_option_block/search_hotel_option_block.template';

import type { SearchTemplateProps } from './types';

const SearchMultipleLocationsTemplate: React.SFC<SearchTemplateProps> = ({
  query,
  user,
  handleChange,
  handleRemoveItem,
  addItemEnable,
  handleAddItem
}) => {
  return (
    <div>
      {query.items.map((item, i) => (
        <SearchBlock key={i} index={i} item={item} icon={i + 1} onRemoveItem={handleRemoveItem} />
      ))}

      <Block className="search__block">
        <div className="search__block__body">
          {!query.disabledTransitSearch && (
            <AddButton
              type="submit"
              className="search__add-block"
              disabled={!addItemEnable}
              onClick={handleAddItem('transport')}
            >
              +交通機関を追加
            </AddButton>
          )}
          {!query.disabledHotelSearch && (
            <AddButton
              type="submit"
              className="search__add-block"
              disabled={!addItemEnable}
              onClick={handleAddItem('hotel')}
            >
              +ホテルを追加
            </AddButton>
          )}
        </div>
      </Block>
      {user ? (
        <SearchTravelersBlock query={query} user={user} handleChange={handleChange} />
      ) : (
        <SearchPeoplenumBlock query={query} handleChange={handleChange} />
      )}
      {!query.disabledHotelSearch && <SearchHotelOptionBlock query={query} handleChange={handleChange} />}
    </div>
  );
};

export const AddButton = styled.button`
  margin-right: 10px;
  background: none;
  padding: 0;
  color: ${props => props.theme.linkColor};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${props => darken(0.1, props.theme.linkColor)};
    background: none;
  }
`;

export default withTheme(SearchMultipleLocationsTemplate);
