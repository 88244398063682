import React from 'react';

import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import Notification from '@this/src/notification';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import type TransportElementAttachFile from '@this/src/domain/transport_element_attach_file';

type Props = {
  className?: string;
  attachFile?: TransportElementAttachFile | null;
  onChange: (file: File) => void;
};

export const TransportElementAttachFileForm = ({ className, attachFile, onChange }: Props) => {
  const [showForm, setShowForm] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      const errorMessage = 'ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。';
      e.target.value = '';
      Notification.error(errorMessage);
      return;
    }
    onChange(file);
  };

  return (
    <Root className={className}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        {attachFile && (
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}>
            <PdfIcon style={{ marginRight: '5px' }} />
            {attachFile.uploadFileName}
          </div>
        )}
        <AddAttachmentButton
          size="small"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            setShowForm(true);
          }}
          data-testid="attachment-field-add"
        >
          {attachFile ? '変更' : '添付ファイル（QR等）'}
        </AddAttachmentButton>
      </div>
      {showForm && (
        <input
          type="file"
          name="files[]"
          onChange={e => handleChange(e)}
          accept="application/pdf"
          data-testid="attachment-field-input"
        />
      )}
    </Root>
  );
};

const Root = styled.div``;

const AddAttachmentButton = styled(Link)`
  margin-bottom: 8px;
`;
