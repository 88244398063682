import React from 'react';

import Text from '../text/text';

export type KeyValue = {
  key: string;
  value?: string | null | undefined;
};

interface Props {
  data: KeyValue[];
  valueAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
}

const FormattedKeyValue = ({ data, valueAlign }: Props) => {
  try {
    return (
      <table className="key-value-table">
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td className="key-value-table__label">
                <Text text={d.key} />
              </td>
              <td className="key-value-table__separator">：</td>
              <td className="key-value-table__value" style={{ textAlign: valueAlign || 'left' }}>
                <Text text={d.value} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } catch (e) {
    utils.sendErrorObject(e);
    return null;
  }
};

export default FormattedKeyValue;
