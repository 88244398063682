import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  totalCount: number;
  totalPrice: string;
}

class ReportSummary extends React.Component<Props> {
  render() {
    const { totalCount, totalPrice } = this.props;

    return (
      <Wrapper>
        <Box>
          <Item>
            <Sub>合計件数</Sub>
            <Main>{totalCount}</Main>
          </Item>
          <Item>
            <Sub>合計金額</Sub>
            <Main>{totalPrice}</Main>
          </Item>
        </Box>
      </Wrapper>
    );
  }
}

const blackTxtColor = '#323232';

const baseColor = '#927230';

const Wrapper = styled.div`
  display: inline-block;
`;

const Box = styled.div`
  display: flex;
  padding: 10px;
  border: 2px solid ${baseColor};
  border-radius: 4px;
  background-color: white;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 3.5px 20px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 40px;
  }
`;

const Sub = styled.p`
  margin-right: 8px;
  color: ${blackTxtColor};
  font-size: 14px;
  font-weight: bold;
`;

const Main = styled.p`
  color: ${baseColor};
  font-size: 1.25rem;
  font-weight: bold;
`;

export default ReportSummary;
