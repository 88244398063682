import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const FileUploadOutlinedIcon: React.SFC<Props> = ({ color, width, height }) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.81825 1.18191C7.64251 1.00618 7.35759 1.00618 7.18185 1.18191L4.18185 4.18191C4.00611 4.35765 4.00611 4.64257 4.18185 4.81831C4.35759 4.99404 4.64251 4.99404 4.81825 4.81831L7.05005 2.58651V9.49999C7.05005 9.74852 7.25152 9.94999 7.50005 9.94999C7.74858 9.94999 7.95005 9.74852 7.95005 9.49999V2.58651L10.1819 4.81831C10.3576 4.99404 10.6425 4.99404 10.8182 4.81831C10.994 4.64257 10.994 4.35765 10.8182 4.18191L7.81825 1.18191ZM2.5 10C2.77614 10 3 10.2239 3 10.5V12C3 12.5539 3.44565 13 3.99635 13H11.0012C11.5529 13 12 12.5528 12 12V10.5C12 10.2239 12.2239 10 12.5 10C12.7761 10 13 10.2239 13 10.5V12C13 13.1041 12.1062 14 11.0012 14H3.99635C2.89019 14 2 13.103 2 12V10.5C2 10.2239 2.22386 10 2.5 10Z"
      fill={color}
    />
  </svg>
);

export const GearOutlinedIcon: React.SFC<Props> = ({ color, width, height }) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.071.65a.85.85 0 0 0-.829.662l-.238 1.05c-.379.11-.74.262-1.08.448l-.91-.574a.85.85 0 0 0-1.055.118l-.606.606a.85.85 0 0 0-.118 1.054l.575.912c-.187.339-.338.7-.448 1.079l-1.05.238a.85.85 0 0 0-.662.829v.857a.85.85 0 0 0 .662.83l1.05.237c.11.38.261.74.448 1.08l-.574.91a.85.85 0 0 0 .118 1.055l.606.606a.85.85 0 0 0 1.054.118l.911-.574c.339.186.7.337 1.079.447l.238 1.05a.85.85 0 0 0 .83.662h.856a.85.85 0 0 0 .83-.662l.237-1.05c.38-.11.74-.26 1.08-.447l.911.574a.85.85 0 0 0 1.054-.118l.607-.606a.85.85 0 0 0 .117-1.054l-.574-.91c.187-.34.338-.702.448-1.08l1.05-.239a.85.85 0 0 0 .662-.829v-.857a.85.85 0 0 0-.662-.829l-1.05-.238c-.11-.38-.26-.74-.447-1.08l.574-.91a.85.85 0 0 0-.118-1.055l-.606-.606a.85.85 0 0 0-1.055-.118l-.91.574a5.322 5.322 0 0 0-1.08-.448l-.239-1.05A.85.85 0 0 0 7.928.65h-.857zm-2.15 3.163a4.476 4.476 0 0 1 1.795-.744L7.07 1.5h.857l.356 1.568c.659.116 1.269.375 1.795.744l1.36-.857.607.606-.858 1.36c.37.527.628 1.137.744 1.796l1.568.355v.857l-1.568.356a4.475 4.475 0 0 1-.744 1.795l.857 1.36-.606.606-1.36-.857a4.476 4.476 0 0 1-1.795.743L7.928 13.5h-.857l-.355-1.568a4.475 4.475 0 0 1-1.795-.744l-1.36.858-.606-.606.857-1.36a4.475 4.475 0 0 1-.744-1.795L1.5 7.929v-.857l1.568-.356a4.476 4.476 0 0 1 .744-1.794l-.858-1.36.606-.607 1.36.858zM9.025 7.5a1.525 1.525 0 1 1-3.05 0 1.525 1.525 0 0 1 3.05 0zm.9 0a2.425 2.425 0 1 1-4.85 0 2.425 2.425 0 0 1 4.85 0z"
      fill={color}
    />
  </svg>
);

export const MagicWandOutlinedIcon: React.SFC<Props> = ({ color, width, height }) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9.5a.4.4 0 0 0-.8 0v.6h-.6a.4.4 0 0 0 0 .8h.6v.6a.4.4 0 0 0 .8 0v-.6h.6a.4.4 0 0 0 0-.8h-.6V.5zm-2.046 2.646a.5.5 0 0 1 0 .707l-1 1a.5.5 0 1 1-.707-.707l1-1a.5.5 0 0 1 .707 0zm-2 2a.5.5 0 0 1 0 .707l-7 7a.5.5 0 0 1-.708-.707l7-7a.5.5 0 0 1 .708 0zM13.5 5.1c.22 0 .4.179.4.4v.6h.6a.4.4 0 0 1 0 .8h-.6v.6a.4.4 0 0 1-.8 0v-.6h-.6a.4.4 0 0 1 0-.8h.6v-.6c0-.221.18-.4.4-.4zM8.9.5a.4.4 0 0 0-.8 0v.6h-.6a.4.4 0 1 0 0 .8h.6v.6a.4.4 0 1 0 .8 0v-.6h.6a.4.4 0 0 0 0-.8h-.6V.5z"
      fill={color}
    />
  </svg>
);

export const LockClosedOutlinedIcon: React.SFC<Props> = ({ color, width, height }) => (
  <svg
    width={width || 15}
    height={height || 15}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.636c0-.875.242-1.53.643-1.962.396-.427 1.003-.695 1.858-.695.856 0 1.462.268 1.857.693.4.431.642 1.085.642 1.961V6H5V4.636zM4 6V4.636c0-1.054.293-1.978.91-2.643C5.533 1.323 6.427.98 7.501.98c1.075 0 1.969.344 2.59 1.013.617.664.909 1.587.909 2.641V6h1a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1zM3 7h9v6H3V7z"
      fill={color}
    />
  </svg>
);

export const CheckOutlinedIcon: React.SFC<Props> = ({ color, width, height }) => (
  <svg width={width || 15} height={width || 15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.467 3.727c.289.189.37.576.181.865l-4.25 6.5a.625.625 0 0 1-.943.12l-2.75-2.5a.625.625 0 0 1 .84-.924l2.208 2.007 3.849-5.887a.625.625 0 0 1 .865-.181z"
      fill={color}
    />
  </svg>
);
