import React, { useCallback, useState } from 'react';

import type { MessageTemplate } from '@this/components/arrangement/virtual_counter/types';
import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { InputLabel } from '@this/shared/ui/inputs/form_control';
import { AutocompleteSelect } from '@this/shared/ui/inputs/autocomplete';
import { Flex } from '@this/shared/ui/layout/flex';
import { Link } from '@this/shared/ui/navigations/link';

import { ProposalReplyButton } from './proposal_reply_button';

type Props = {
  className?: string;
  messageTemplates: MessageTemplate[];
  selectedTrip: Trip | null;
  onSubmit: (message: string) => void;
  serviceId: number;
};

export const MessageTemplateSelector = ({
  className,
  messageTemplates,
  selectedTrip,
  onSubmit,
  serviceId
}: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | null>(null);
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      if (!selectedTemplate) {
        return;
      }
      onSubmit(selectedTemplate.content);
    },
    [selectedTemplate, onSubmit]
  );

  return (
    <Root className={className}>
      <Flex alignItems="center" gap="4px">
        <InputLabel>テンプレート</InputLabel>
        <Box>
          <AutocompleteSelect
            size="small"
            placeholder="テンプレートを選択"
            options={messageTemplates}
            getOptionLabel={template => template.title}
            onChange={(e, template) => setSelectedTemplate(template)}
            disableClearable
            fullWidth
          />
        </Box>
      </Flex>

      <Link onClick={handleSubmit} disabled={!selectedTemplate}>
        適用
      </Link>

      {utils.isAiTravel(serviceId) && messageTemplates.length > 0 && (
        <ProposalReplyButton selectedTrip={selectedTrip} onClick={onSubmit} />
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Box = styled.div`
  width: 300px;
`;
