import React, { useState } from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  type?: string;
  place?: string;
  width?: number;
  children: React.ReactNode;
}

const Tooltip = ({ type, place, width, children }: Props) => {
  const [show, setShow] = useState(false);
  return (
    <Wrap
      className="tooltip"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
      onTouchEnd={() => setShow(!show)}
    >
      {type === 'info' ? (
        <Icon help={false} className="tooltip__icon-info">
          i
        </Icon>
      ) : (
        <Icon help className="tooltip__icon">
          ?
        </Icon>
      )}
      {show && (
        <Content place={place === 'left' ? 'right' : 'left'} width={width || 200} className="tooltip__content">
          {children}
        </Content>
      )}
    </Wrap>
  );
};

export const Wrap = styled.div`
  display: flex;
  position: relative;
  margin-left: 2px;
`;

export const Icon = styled.div<{ help: boolean }>`
  background: ${props => props.theme.disabledColor};
  color: white;
  font-size: 11px;
  line-height: 13px;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  text-align: center;
  margin: auto;
  cursor: ${props => (props.help ? 'help' : 'default')};
`;

export const Content = styled.div<{ place: string; width: number }>`
  position: absolute;
  top: 110%;
  ${props => props.place}: 0%;
  min-width: ${props => props.width}px;
  background: ${props => props.theme.grayBorderColor};
  border: 1px solid ${props => props.theme.disabledColor};
  border-radius: 3px;
  padding: 5px;
  z-index: 10;
  color: ${props => props.theme.textColor};
  text-align: left;
  font-weight: normal;
`;

export default Tooltip;
