import React from 'react';
import { withTheme } from 'styled-components';
import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
// import TripAnalysis from '@this/components/organization/analysis/trip_analysis/trip_analysis';
import CostAnalysis from './cost_analysis/cost_analysis';

interface Props {
  theme: any;
}

interface State {
  // serviceId: number;
}

class Analysis extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // serviceId: this.fetchServiceId()
    };
  }

  fetchServiceId() {
    switch (utils.dig(this.props.theme, 'themeClass')) {
      case 'tabikobo':
        return 2;
      case 'mynavi':
        return 3;
      default:
        return 1;
    }
  }

  render() {
    // const { serviceId } = this.state;
    return (
      <>
        <OrganizationTitle>簡易分析</OrganizationTitle>
        <OrganizationBody>
          <CostAnalysis />
          {/* <TripAnalysis serviceId={serviceId} /> */}
        </OrganizationBody>
      </>
    );
  }
}

export default withTheme(Analysis);
