import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import MuiButton from '@material-ui/core/Button';
import MuiFab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import CoreAppContext from '@this/components/core_app/core_app_context';
import { styled } from '@this/constants/themes';

import type { ChatbotMessageFormStatus } from '@this/src/domain/chatbot/chatbot_message';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

import { CHAT_MESSAGES_ID } from './idList';
import { baseColor } from './styles';
import ChatIcon from './ChatIcon';
import ExpandLessIcon from './ExpandLessIcon';
import fetchSessionMessagesApi from './api/fetch_session_messages';
import handleSubmitMessageApi from './api/handle_submit_message_api';
import initiateSessionApi from './api/initiate_session_api';
import queryCompletionApi from './api/query_completion_api';
import searchKnowledgeApi from './api/search_knowledge_api';
import textToQueryApi from './api/text_to_query_api';
import updateUserSettingApi from './api/update_user_setting_api';
import { ChatHistories } from './content/ChatHistories';
import ChatMessages from './content/ChatMessages';
import type { ChatMessagesOptions } from './content/ChatMessages';
import { UserSettingForm } from './content/UserSettingForm';
import ChatbotTerms from './content/chatbot_terms';
import { ChatbotFooter } from './footer/chatbot_footer';
import { ChatbotHistoryFooter } from './footer/chatbot_history_footer';
import { ChatbotSettingFooter } from './footer/chatbot_setting_footer';
import { ChatbotHeader } from './header/chatbot_header';
import type { ChatbotDisplayMode } from './mode';
import { chatbotReducer, initialChatbotState } from './reducer';

type Props = Omit<ChatMessagesOptions, 'onSubmit' | 'userSetting' | 'updateDefaultOrigin'>;

const Chatbot: React.FC<Props> = observer(options => {
  const { user } = useContext(CoreAppContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(chatbotReducer, initialChatbotState);
  const {
    isOpen,
    loading,
    mode,
    message,
    isSubmitting,
    isMakingQuery,
    isKnowledgeSearching,
    isAutoFocus,
    isLatest,
    isRestart,
    messages,
    userSetting,
    sessionId,
    loadedSessionId,
    latestSessionId
  } = state;
  const useChatbot = useMemo(() => user?.useChatbot ?? false, [user]);

  useEffect(() => {
    if (!isOpen || mode === 'history') return;

    const el = document.getElementById(CHAT_MESSAGES_ID);
    if (el) el.scrollTop = el.scrollHeight;
  }, [isOpen, mode, loadedSessionId, isSubmitting, isMakingQuery, isKnowledgeSearching]);

  const handleToggleOpen = useCallback(() => {
    dispatch({ type: 'TOGGLE_OPEN' });
  }, [dispatch]);

  const handleChangeMode = useCallback(
    (mode: ChatbotDisplayMode) => {
      dispatch({ type: 'CHANGE_MODE', payload: mode });
    },
    [dispatch]
  );

  const handleChangeMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_MESSAGE', message: event.target.value });
    },
    [dispatch]
  );

  const initiateSession = useCallback(() => {
    dispatch({ type: 'LOADING' });
    initiateSessionApi({
      onSuccess: payload => dispatch({ type: 'NEW_SESSION', payload })
    });
  }, [dispatch]);

  const fetchSessionMessages = useCallback(
    (sessionId: number) => {
      dispatch({ type: 'LOADING' });
      fetchSessionMessagesApi({
        sessionId,
        onSuccess: payload => dispatch({ type: 'RELOAD_MESSAGES', payload })
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (sessionId && sessionId !== loadedSessionId) {
      fetchSessionMessages(sessionId);
    } else if (latestSessionId === undefined) {
      initiateSession();
    }
  }, [sessionId, loadedSessionId, latestSessionId, fetchSessionMessages, initiateSession]);

  const updateDefaultOrigin = useCallback(
    (defaultOrigin?: string, onSuccess?: () => void) => {
      userSetting.setError(null);
      updateUserSettingApi({
        defaultOrigin: defaultOrigin ?? '',
        onSuccess: () => {
          userSetting.setDefaultOrigin(defaultOrigin ?? '');
          onSuccess?.();
        },
        onError: message => {
          userSetting.setError(message);
        }
      });
    },
    [userSetting]
  );

  const textToQuery = useCallback(() => {
    textToQueryApi({
      sessionId,
      message,
      onStarted: () => dispatch({ type: 'SET_IS_MAKING_QUERY', isMakingQuery: true }),
      onComplete: ({ url, message }) => {
        dispatch({ type: 'PUSH_BOT_MESSAGE', text: message });
        history.push(url);
      },
      onIncomplete: ({ message, formAttributes }) => {
        dispatch({ type: 'PUSH_BOT_MESSAGE', text: message, formAttributes });
      },
      onError: message => dispatch({ type: 'PUSH_BOT_ERROR_MESSAGE', text: message }),
      onFinally: () => {
        dispatch({ type: 'SET_IS_MAKING_QUERY', isMakingQuery: false });
        dispatch({ type: 'RESET_MESSAGE' });
      }
    });
  }, [history, dispatch, message, sessionId]);

  const queryCompletion = useCallback(
    (message: ChatbotMessage, status: ChatbotMessageFormStatus) => {
      queryCompletionApi({
        message,
        status,
        onStarted: () => dispatch({ type: 'SET_IS_MAKING_QUERY', isMakingQuery: true }),
        onComplete: ({ url, status, chatbotMessages }) => {
          message.setStatus(status);
          dispatch({ type: 'PUSH_MESSAGES', messages: chatbotMessages });
          if (url) history.push(url);
        },
        onFinally: () => {
          dispatch({ type: 'SET_IS_MAKING_QUERY', isMakingQuery: false });
          dispatch({ type: 'RESET_MESSAGE' });
        }
      });
    },
    [history, dispatch]
  );

  const searchKnowledge = useCallback(() => {
    searchKnowledgeApi({
      sessionId,
      message,
      onStarted: () => dispatch({ type: 'SET_IS_KNOWLEDGE_SEARCHING', isKnowledgeSearching: true }),
      onSuccess: message => dispatch({ type: 'PUSH_BOT_MESSAGE', text: message }),
      onError: message => dispatch({ type: 'PUSH_BOT_ERROR_MESSAGE', text: message }),
      onFinally: () => {
        dispatch({ type: 'SET_IS_KNOWLEDGE_SEARCHING', isKnowledgeSearching: false });
        dispatch({ type: 'RESET_MESSAGE' });
      }
    });
  }, [dispatch, message, sessionId]);

  const handleSubmitMessage = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      handleSubmitMessageApi({
        sessionId,
        message,
        isRestart,
        onStarted: () => {
          dispatch({ type: 'PUSH_USER_MESSAGE', text: message, restart: isRestart });
          dispatch({ type: 'SET_IS_SUBMITTING', isSubmitting: true });
        },
        onAction: action => {
          if (action === 'search') {
            textToQuery();
          } else if (action === 'knowledge') {
            searchKnowledge();
          }
        },
        onSuccess: message => dispatch({ type: 'PUSH_BOT_MESSAGE', text: message }),
        onError: message => dispatch({ type: 'PUSH_BOT_ERROR_MESSAGE', text: message }),
        onFinally: () => {
          dispatch({ type: 'SET_IS_SUBMITTING', isSubmitting: false });
          dispatch({ type: 'RESET_MESSAGE' });
        }
      });
    },
    [dispatch, sessionId, message, isRestart, textToQuery, searchKnowledge]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!event.shiftKey && event.key === 'Enter' && !event.nativeEvent.isComposing) {
        event.preventDefault();
        handleSubmitMessage();
      }
    },
    [handleSubmitMessage]
  );

  const handleClickChangeSession = useCallback(
    (sessionId: number) => {
      dispatch({ type: 'CHANGE_SESSION', sessionId });
    },
    [dispatch]
  );

  const handleClickChangeLatestSession = useCallback(() => {
    if (isLatest) {
      dispatch({ type: 'CHANGE_LATEST_SESSION' });
    } else {
      dispatch({ type: 'CHANGE_NEW_SESSION' });
    }
  }, [dispatch, isLatest]);

  const handleAcceptTerms = () => {
    user?.setAcceptedChatbotTerms(true);
  };

  return useChatbot ? (
    <Wrapper>
      <Fab onClick={handleToggleOpen}>{isOpen ? <ExpandLessIcon /> : <ChatIcon />}</Fab>
      <Collapse className={isOpen ? 'expanded' : 'collapsed'}>
        {isOpen ? (
          <Expanded>
            {user?.acceptedChatbotTerms ? (
              <>
                <ChatbotHeader mode={mode} onChangeMode={handleChangeMode} onClose={handleToggleOpen} />
                <ExpandedBody id={CHAT_MESSAGES_ID}>
                  <ExpandedContent>
                    {loading ? (
                      <SimpleLoading />
                    ) : mode === 'chat' ? (
                      <ChatMessages
                        messages={messages}
                        isRestart={sessionId !== latestSessionId}
                        isSubmitting={isSubmitting}
                        isKnowledgeSearching={isKnowledgeSearching}
                        isMakingQuery={isMakingQuery}
                        onSubmit={queryCompletion}
                        userSetting={userSetting}
                        updateDefaultOrigin={updateDefaultOrigin}
                        {...options}
                      />
                    ) : mode === 'history' ? (
                      <ChatHistories onClick={handleClickChangeSession} />
                    ) : (
                      <UserSettingForm
                        userSetting={userSetting}
                        updateDefaultOrigin={updateDefaultOrigin}
                        {...options}
                      />
                    )}
                  </ExpandedContent>
                </ExpandedBody>
                {mode === 'chat' ? (
                  <ChatbotFooter
                    message={message}
                    isAutoFocus={isAutoFocus}
                    disabled={loading || isSubmitting || isMakingQuery || isKnowledgeSearching}
                    onChange={handleChangeMessage}
                    onSubmit={handleSubmitMessage}
                    onKeyDown={handleKeyDown}
                  />
                ) : mode === 'history' ? (
                  <ChatbotHistoryFooter onClick={handleClickChangeLatestSession} />
                ) : (
                  <ChatbotSettingFooter onChange={handleChangeMode} />
                )}
              </>
            ) : (
              <>
                <ChatbotHeader forTerms mode={mode} onChangeMode={handleChangeMode} onClose={handleToggleOpen} />
                <ExpandedBody id={CHAT_MESSAGES_ID}>
                  <ExpandedContent>
                    <ChatbotTerms onAcceptTerms={handleAcceptTerms} />
                  </ExpandedContent>
                </ExpandedBody>
              </>
            )}
          </Expanded>
        ) : (
          <Collapsed onClick={handleToggleOpen}>
            <Typography style={{ fontSize: 15, marginRight: -8 }}>
              AIコンシェルジュが
              <br />
              ご用件を伺います。
            </Typography>
          </Collapsed>
        )}
      </Collapse>
    </Wrapper>
  ) : null;
});

const Wrapper = styled.div`
  width: 323px;
  z-index: 9;
  position: fixed;
  bottom: 16px;
  right: 24px;
  background: white;
  display: flex;
  flex-direction: column;
`;

const Fab = styled(MuiFab)`
  position: absolute;
  bottom: 6px;
  right: 0;
  width: 56px;
  height: 56px;
  background: ${baseColor};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  z-index: 10;

  &:hover,
  &:focus {
    background: ${lighten(baseColor, 0.1)};
  }
`;

const Collapse = styled.div`
  position: absolute;
  transition: all 0.3s ease-in-out;

  &.expanded {
    width: 100%;
    height: 500px;
    max-height: calc(100vh - 72px - 32px);
    right: 0;
    bottom: calc(56px + 16px + 6px);
    border: solid 0px #c4c4c4;
    background: white;
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.25));
    border-radius: 16px;
  }

  &.collapsed {
    width: 203px;
    height: 68px;
    right: 0;
    bottom: calc(56px + 16px + 6px);
    border: solid 1px #c4c4c4;
    border-radius: 16px 16px 0px 16px;
    background: #f7f5ef;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
`;

const Collapsed = styled(MuiButton)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const Expanded = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ExpandedBody = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #888 #f1f1f1;

  & > * {
    margin-right: -5px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ExpandedContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;

export default Chatbot;
