import { observable } from 'mobx';

export interface FreeeCompanyArgs {
  id: number;
  display_name: string;
}

export default class FreeeCompany {
  @observable
  public id: number;

  @observable
  public displayName: string;

  constructor(args: FreeeCompanyArgs) {
    this.id = args.id;
    this.displayName = args.display_name;
  }
}
