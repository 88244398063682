import * as React from 'react';
import { styled } from '@this/constants/themes';
import Pagination from '@this/shared/pagination/pagination';

type Props = {
  createButton: React.ReactNode;
  currentPage?: number;
  totalPage?: number;
  onPaginate?: React.ComponentProps<typeof Pagination>['handleSearch'];
  headerContent?: React.ReactNode;
  renderContent: () => React.ReactNode;
};

export const MasterListContainer = ({
  createButton,
  currentPage = 1,
  totalPage = 1,
  onPaginate,
  headerContent,
  renderContent
}: Props) => {
  return (
    <div>
      <ContainerHeader>
        {createButton}
        {headerContent}
      </ContainerHeader>
      <ContainerBody>{renderContent()}</ContainerBody>
      {totalPage > 1 && onPaginate && (
        <Pagination currentPage={currentPage} totalPage={totalPage} handleSearch={onPaginate} />
      )}
      {createButton}
    </div>
  );
};

const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerBody = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;
