import { observable } from 'mobx';

export type AccountType = 'ordinary' | 'checking';

export const ACCOUNT_TYPES: Record<AccountType, string> = {
  ordinary: '普通預金',
  checking: '当座預金'
};

export type SavingAccountArgs = {
  id: number;
  bank_name: string;
  branch_name: string;
  branch_code: string;
  account_type: AccountType;
  number: number;
  holder_name: string;
};

export default class SavingAccount {
  @observable id: number | null;

  @observable bankName: string;

  @observable branchName: string;

  @observable branchCode: string;

  @observable accountType: AccountType;

  @observable number: string;

  @observable holderName: string;

  constructor(args: Partial<SavingAccountArgs> = {}) {
    this.id = args.id || null;
    this.bankName = args.bank_name || '';
    this.branchName = args.branch_name || '';
    this.branchCode = args.branch_code || '';
    this.accountType = args.account_type || 'ordinary';
    this.number = args.number ? args.number.toString() : '';
    this.holderName = args.holder_name || '';
  }

  get accountTypeStr() {
    return ACCOUNT_TYPES[this.accountType];
  }

  clone(): SavingAccount {
    const args: Partial<SavingAccountArgs> = {
      id: this.id || undefined,
      bank_name: this.bankName,
      branch_name: this.branchName,
      branch_code: this.branchCode,
      account_type: this.accountType,
      number: this.number !== '' ? parseInt(this.number, 10) : undefined,
      holder_name: this.holderName
    };
    return new SavingAccount(args);
  }

  setBankName(value: string) {
    this.bankName = value;
    app.render();
  }

  setBranchName(value: string) {
    this.branchName = value;
    app.render();
  }

  setBranchCode(value: string) {
    this.branchCode = value;
    app.render();
  }

  setAccountType(value: AccountType) {
    this.accountType = value;
    app.render();
  }

  setNumber(value: string) {
    this.number = value;
    app.render();
  }

  setHolderName(value: string) {
    this.holderName = value;
    app.render();
  }

  submitParams() {
    return {
      bank_name: this.bankName,
      branch_name: this.branchName,
      branch_code: this.branchCode,
      account_type: this.accountType,
      number: this.number !== '' ? parseInt(this.number, 10) : undefined,
      holder_name: this.holderName
    };
  }
}
