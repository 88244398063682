export interface ApprovalJson {
  id: number;
  status: ApprovalStatus;
}

export type ApprovalStatus = 'applied' | 'approved' | 'rejected' | 'completed';

export class Approval {
  id: number;

  status: ApprovalStatus;

  constructor(args: ApprovalJson) {
    this.id = args.id;
    this.status = args.status;
  }
}
