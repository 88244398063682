import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import type { SimulateBaseFileArgs } from '@this/src/domain/simulate_base_file';
import { SimulateBaseFile } from '@this/src/domain/simulate_base_file';
import type { SimulateBaseRecordArgs, SimulateBaseRecordStatus } from '@this/src/domain/simulate_base_record';
import { SimulateBaseRecord } from '@this/src/domain/simulate_base_record';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Link from '@this/components/shared/atoms/link';
import { Button as UiButton } from '@this/components/shared/ui/inputs/button';
import { Checkbox } from '@this/components/shared/ui/inputs/checkbox';
import Pagination from '@this/components/shared/pagination/pagination';
import { Fetcher } from '@this/src/util';
import { Title } from '../god';
import SimulationSummary from './simulation_summary';
import ResultRow from './simulation_records/result_row';

const StickyTable = require('react-sticky-table').StickyTable;
const Row = require('react-sticky-table').Row;
const Cell = require('react-sticky-table').Cell;

type Props = RouteComponentProps<{ id: string }>;

interface SimulateBaseFileResponse {
  simulate_base_file: SimulateBaseFileArgs;
  simulate_base_records: SimulateBaseRecordArgs[];
  total_pages: number;
}

interface SimulateBaseFileCountResponse {
  simulate_base_file: SimulateBaseFileArgs;
  simulate_base_records: Record<string, { id: number; status: SimulateBaseRecordStatus; message?: string }>;
}

interface SimulateBaseRecordsResponse {
  simulate_base_records: Record<number, SimulateBaseRecordArgs>;
}

const SimulationRecords: React.FC<Props> = ({ match: { params } }) => {
  const history = useHistory();
  const location = useLocation();
  const [simulateBaseFile, setSimulateBaseFile] = useState<SimulateBaseFile | null>(null);
  const [simulateBaseRecords, setSimulateBaseRecords] = useState<SimulateBaseRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [withSummary, setWithSummary] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentPage = useMemo(() => search.get('page') || '1', [search]);
  const downloadParams = useMemo(
    () => `${search.toString()}&with_summary=${withSummary ? 'true' : ''}`,
    [search, withSummary]
  );

  const fetchSimulateBaseFile = useCallback(
    async (search: URLSearchParams) => {
      setLoading(true);
      setError(null);

      await Fetcher.get<SimulateBaseFileResponse>(
        `/god/simulate_base_files/${params.id}.json?${search.toString()}`
      )
        .then(({ simulate_base_file, simulate_base_records, total_pages }) => {
          setSimulateBaseFile(new SimulateBaseFile(simulate_base_file));
          setSimulateBaseRecords(simulate_base_records.map(raw => new SimulateBaseRecord(raw)));
          setTotalPage(total_pages);
        })
        .catch(() => setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。'))
        .finally(() => setLoading(false));
    },
    [params.id, setSimulateBaseFile, setSimulateBaseRecords]
  );

  const fetchSimulateBaseFileCount = useCallback(
    async (file: SimulateBaseFile) => {
      if (file.totalCount <= file.executed) return;

      await Fetcher.get<SimulateBaseFileCountResponse>(
        `/god/simulate_base_files/${file.id}/count?with_records=true`
      ).then(({ simulate_base_file, simulate_base_records }) => {
        setSimulateBaseFile(new SimulateBaseFile(simulate_base_file));
        setSimulateBaseRecords(records => {
          const updated = records.filter(record => {
            const data = simulate_base_records[record.id.toString()];
            return data && data.status !== record.status;
          });
          if (updated.length > 0) fetchSimulateBaseRecords(updated);

          return records;
        });
      });
    },
    [setSimulateBaseFile, setSimulateBaseRecords]
  );

  const fetchSimulateBaseRecords = useCallback(
    async (records: SimulateBaseRecord[]) => {
      const params = { id: records.map(r => r.id) };

      await Fetcher.post<SimulateBaseRecordsResponse>(`/god/simulate_base_files/records`, params).then(
        ({ simulate_base_records }) => {
          setSimulateBaseRecords(records =>
            records.map(r =>
              simulate_base_records[r.id] ? new SimulateBaseRecord(simulate_base_records[r.id]) : r
            )
          );
        }
      );
    },
    [setSimulateBaseRecords]
  );

  const fetchRestart = useCallback(
    async (search: URLSearchParams, force = false) => {
      if (!simulateBaseFile || (simulateBaseFile.status !== 'stoped' && !force)) return;

      await Fetcher.post<{ success: boolean }>(`/god/simulate_base_files/${simulateBaseFile.id}/restart`, {}).then(
        () => {
          fetchSimulateBaseFile(search);
        }
      );
    },
    [simulateBaseFile, fetchSimulateBaseFile]
  );

  const fetchStop = useCallback(
    async (search: URLSearchParams, restart: boolean) => {
      if (!simulateBaseFile || simulateBaseFile.status !== 'running') return;

      await Fetcher.post<{ success: boolean }>(`/god/simulate_base_files/${simulateBaseFile.id}/stop`, {}).then(
        () => {
          if (restart) {
            fetchRestart(search, true);
          } else {
            fetchSimulateBaseFile(search);
          }
        }
      );
    },
    [simulateBaseFile, fetchSimulateBaseFile, fetchRestart]
  );

  const handlePageChange = useCallback(
    async (page: number) => {
      const params = new URLSearchParams(location.search);
      params.set('page', page.toString());
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location]
  );

  const handleRecordTypeChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const params = new URLSearchParams(location.search);
      if (e.target.value !== '') {
        params.set('record_type', e.target.value);
      } else {
        params.delete('record_type');
      }
      params.delete('page');
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location]
  );

  const handleStatusChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const params = new URLSearchParams(location.search);
      if (e.target.value !== '') {
        params.set('status', e.target.value);
      } else {
        params.delete('status');
      }
      params.delete('page');
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location]
  );

  const handleSameItemCheck = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const params = new URLSearchParams(location.search);
      if (e.target.checked) {
        params.set('same_item', 'true');
      } else {
        params.delete('same_item');
      }
      params.delete('page');
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location]
  );

  const handlePastSameItemCheck = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const params = new URLSearchParams(location.search);
      if (e.target.checked) {
        params.set('past_same_item', 'true');
      } else {
        params.delete('past_same_item');
      }
      params.delete('page');
      history.replace({ pathname: location.pathname, search: params.toString() });
    },
    [history, location]
  );

  const handleProgressPageChange = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const executed = simulateBaseFile?.executed ?? 0;
      await handlePageChange(Math.ceil(executed / 100) || 1);
    },
    [simulateBaseFile?.executed, handlePageChange]
  );

  const handleRestart = useCallback(() => fetchRestart(search), [fetchRestart, search]);

  const handleStop = useCallback((restart: boolean) => () => fetchStop(search, restart), [fetchStop, search]);

  useEffect(() => {
    fetchSimulateBaseFile(search);
    setPage(parseInt(currentPage, 10));
  }, [search]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (simulateBaseFile) fetchSimulateBaseFileCount(simulateBaseFile);
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [simulateBaseFile]);

  return (
    <div>
      <Title>
        あとづけマーケットログ
        {simulateBaseFile && ` - ${simulateBaseFile.fileName}`}
      </Title>
      <Content>
        <InputAreaRight>
          <InputAreaCell>
            <label>種別</label>
            <select value={search.get('record_type') || ''} onChange={handleRecordTypeChange}>
              <option value="">全て</option>
              <option value="hotel">ホテル</option>
              <option value="shinkansen">新幹線・特急券</option>
              <option value="domestic_air">航空券</option>
            </select>
          </InputAreaCell>
          <InputAreaCell>
            <label>ステータス</label>
            <select value={search.get('status') || ''} onChange={handleStatusChange}>
              <option value="">全て</option>
              <option value="not_executed">検索待ち・検索中</option>
              <option value="executed">検索済み</option>
              <option value="completed">成功</option>
              <option value="failed">失敗</option>
              <option value="stoped">停止中</option>
            </select>
          </InputAreaCell>
          <InputAreaCell>
            <Checkbox checked={search.get('same_item') === 'true'} onChange={handleSameItemCheck}>
              同名のホテル・便名（現在）
            </Checkbox>
          </InputAreaCell>
          <InputAreaCell>
            <Checkbox checked={search.get('past_same_item') === 'true'} onChange={handlePastSameItemCheck}>
              同名のホテル・便名（予約時）
            </Checkbox>
          </InputAreaCell>
        </InputAreaRight>
        {simulateBaseFile && (
          <InputAreaRight>
            <InputAreaCell>
              <label>状態</label>
              {simulateBaseFile.getStatus()}
              {simulateBaseFile.status === 'running' && (
                <>
                  <SimpleLoading size={16} style={{ display: 'inline-block' }} />
                  <UiButton onClick={handleStop(false)}>停止する</UiButton>
                  <UiButton onClick={handleStop(true)}>ジョブをリロード</UiButton>
                </>
              )}
              {simulateBaseFile.status === 'stoped' && <UiButton onClick={handleRestart}>再開する</UiButton>}
            </InputAreaCell>
            <InputAreaCell>
              <label>進捗率</label>
              <Link to="" onClick={handleProgressPageChange}>
                {simulateBaseFile.executed}
              </Link>
              {' / '}
              {simulateBaseFile.totalCount}
              {simulateBaseFile.status !== 'stoped'
                ? ` (失敗: ${simulateBaseFile.failed})`
                : ` (失敗: ${simulateBaseFile.failed}, 停止: ${simulateBaseFile.stoped})`}
            </InputAreaCell>
            <InputAreaCell>
              <label>最終更新</label>
              {simulateBaseFile.updatedAt.format('L LTS')}
              {` (${simulateBaseFile.updatedAt.fromNow()})`}
            </InputAreaCell>
            <InputAreaCell>
              <UiButton
                href={`/god/simulate_base_files/${simulateBaseFile.id}.csv?${downloadParams}`}
                target="_blank"
                disabled={simulateBaseFile.getStatus() === '検索中'}
              >
                ダウンロード
              </UiButton>
            </InputAreaCell>
            <InputAreaCell>
              <Checkbox checked={withSummary} onChange={() => setWithSummary(v => !v)}>
                集計行(DL)
              </Checkbox>
            </InputAreaCell>
          </InputAreaRight>
        )}
        <SimulationSummary simulateBaseFile={simulateBaseFile} searchParams={search} />
        {loading ? (
          <SimpleLoading />
        ) : (
          <div>
            {error && <Error>{error}</Error>}
            <SimulationTable>
              <Row>
                <SimulationTh>行</SimulationTh>
                <SimulationTh>状態</SimulationTh>
                <SimulationTh>ホテル詳細</SimulationTh>
                <SimulationTh>検索結果（現在）</SimulationTh>
                <SimulationTh>検索結果（予約時）</SimulationTh>
                <SimulationTh width={136}>メッセージ</SimulationTh>
              </Row>
              {simulateBaseRecords.map(record => (
                <Row key={record.id}>
                  <SimulationTd>{record.row}</SimulationTd>
                  <SimulationTd>{record.getStatus()}</SimulationTd>
                  <SimulationDetailTd>
                    <LongText>{record.itemName}</LongText>
                    <div>
                      {record.peopleNum}人、{record.roomNum}部屋
                      {record.stayNum && `、${record.stayNum}泊`}
                    </div>
                    <div>
                      <label>税込金額</label>
                      {record.priceWithTax.toLocaleString()}円
                      {record.priceWithTaxPerStay && `, ${record.priceWithTaxPerStay.toLocaleString()}円/泊`}
                    </div>
                    <div>
                      <label>出張者</label>
                      {record.traveler || '─'}
                      {record.arranger && (
                        <>
                          <label>手配者</label>
                          {record.arranger}
                        </>
                      )}
                    </div>
                  </SimulationDetailTd>
                  <SimulationResultTd>
                    <div>
                      {record.checkin?.format('L')} ~ {record.checkout?.format('L')}
                      （予約日：{record.updatedAt.format('L')}
                      {record.leadTime && `, ${record.leadTime}日前`}）
                    </div>
                    {record.aitSameItemHotelId && (
                      <ResultRow record={record} field="aitSameItemPrice" label="同名" />
                    )}
                    {record.aitCheapestItemHotelId && (
                      <ResultRow record={record} field="aitCheapestItemPrice" label="最安値" />
                    )}
                    {record.aitRecommendedItemHotelId && (
                      <ResultRow record={record} field="aitRecommendedItemPrice" label="おすすめ" />
                    )}
                    {record.aitAveragePrice && (
                      <ResultRow record={record} field="aitAveragePrice" label="平均値" />
                    )}
                  </SimulationResultTd>
                  <SimulationResultTd>
                    <div>
                      {record.startAt.format('L')} ~ {record.endAt.format('L')}
                      （予約日：{record.reservedAt.format('L')}
                      {record.leadTime && `, ${record.leadTime}日前`}）
                    </div>
                    {record.aitPastSameItemHotelId && (
                      <ResultRow record={record} field="aitPastSameItemPrice" label="同名" />
                    )}
                    {record.aitPastCheapestItemHotelId && (
                      <ResultRow record={record} field="aitPastCheapestItemPrice" label="最安値" />
                    )}
                    {record.aitPastRecommendedItemHotelId && (
                      <ResultRow record={record} field="aitPastRecommendedItemPrice" label="おすすめ" />
                    )}
                    {record.aitPastAveragePrice && (
                      <ResultRow record={record} field="aitPastAveragePrice" label="平均値" />
                    )}
                  </SimulationResultTd>
                  <SimulationDetailTd>{record.message}</SimulationDetailTd>
                </Row>
              ))}
            </SimulationTable>
          </div>
        )}
      </Content>
      <PaginationSection>
        <Pagination
          currentPage={parseInt(currentPage, 10)}
          totalPage={totalPage}
          handleSearch={handlePageChange}
          siblingCount={3}
        />
        <PaginationInput
          value={page || ''}
          onChange={e => setPage(parseInt(e.target.value, 10))}
          onKeyPress={e => e.key === 'Enter' && handlePageChange(page)}
        />
      </PaginationSection>
    </div>
  );
};

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const InputAreaRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;
  min-width: 550px;
`;

const InputAreaCell = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;

  & label {
    font-weight: bold;
  }

  & select {
    margin-bottom: 0;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SimulationTable = styled(StickyTable)`
  margin: 0;
  table-layout: fixed;
  height: 80vh;
`;

const SimulationTh = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
  ${props => props.width && `width: ${props.width}px;`}
`;

const SimulationTd = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
`;

const SimulationDetailTd = styled(Cell)`
  padding: 4px 6px;
  font-size: 0.8em;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    column-gap: 8px;
  }
`;

const SimulationResultTd = styled(Cell)`
  padding: 4px 6px;
  font-size: 0.8em;

  & > div {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    & > label {
      display: inline;
      margin-right: 8px;
    }
  }
`;

const LongText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const PaginationInput = styled.input`
  &&& {
    margin: 0;
    width: 40px;
  }
`;

export default SimulationRecords;
