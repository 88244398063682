import _ from 'lodash';

import type TravelerList from '@this/domain/traveler/traveler_list';
import type User from '@this/domain/user/user';

interface Args {
  travelers: TravelerList;
  user: User;
  foreignExist: boolean;
}

class ArrangementRequestFlightInfo {
  readonly travelers: TravelerList;

  readonly user: User;

  readonly foreignExist: boolean;

  constructor(args: Args) {
    this.travelers = args.travelers;
    this.user = args.user;
    this.foreignExist = args.foreignExist;
  }

  validate(foreign?: boolean): string[] {
    const errors: { [key: string]: string | undefined } = {};
    this.travelers.list.map(t => t.flightValidate(errors, foreign === undefined ? this.foreignExist : foreign));
    return _.compact(Object.keys(errors).map(k => errors[k]));
  }
}

export default ArrangementRequestFlightInfo;
