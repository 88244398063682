import { observable } from 'mobx';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import type { HotelElementProviderKey } from '@this/src/domain/hotel_element_provider';
import type { OrderItemJobTypeKey } from '../order_item/order_item_job_type';
import type { OrderItemStatusKey } from '../order_item/order_item_status';
import type { TripStatusKey } from '../trip/trip_status';
import type { OrderItemCategoryKey } from '../order_item/order_item_category';

export type HotelOrderItemTodoType = 'message' | 'order_item';

type Diff = {
  action: '-' | '+';
  position: number;
  element: string;
};

export type HotelOrderItemTodoResponseArgs = {
  todo_type: string;
  trip_id: number;
  order_item_id?: number;
  trace_id?: number;
  trip_status: number;
  job_type: number;
  status: number;
  arranger_name?: string;
  category?: OrderItemCategoryKey;
  organization_name?: string;
  waiting?: boolean;
  start_date?: string;
  end_date?: string;
  received_at?: string;
  last_user_message?: string;
  last_user_message_id?: number;
  last_log_message?: string;
  last_log_message_arranger?: string;
  snooze_to?: string;
  snooze_message?: string;
  is_urgent: boolean;
  sp: boolean;
  use_qr?: boolean;
  premium_support?: boolean;
  inventoried_at?: string;
  inventoried_by?: string;
  provider?: HotelElementProviderKey;
  auto_booked?: boolean;
  auto_approved?: boolean;
  auto_approved_message?: string;
  description?: string;
  trace_description?: string;
  diff_description?: Diff[][];
};

export type HotelOrderItemTodoArgs = {
  todoType: HotelOrderItemTodoType;
  tripId: number;
  orderItemId?: number;
  traceId?: number;
  tripStatus: TripStatusKey;
  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  arrangerName?: string;
  category?: OrderItemCategoryKey;
  organizationName?: string;
  waiting?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  receivedAt?: Moment;
  lastUserMessage?: string;
  lastUserMessageId?: number;
  lastLogMessage?: string;
  lastLogMessageArranger?: string;
  snoozeTo?: Moment;
  snoozeMessage?: string;
  isUrgent: boolean;
  sp: boolean;
  useQr: boolean;
  premiumSupport: boolean;
  inventoriedAt?: Moment;
  inventoriedBy?: string;
  provider?: HotelElementProviderKey;
  autoBooked: boolean;
  autoApproved: boolean;
  autoApprovedMessage?: string;
  description?: string;
  traceDescription?: string;
  diffDescription: Diff[][];
};

const convertHotelOrderItemTodoResponseToArgs = (response: HotelOrderItemTodoResponseArgs) => {
  const args: HotelOrderItemTodoArgs = {
    todoType: response.todo_type as HotelOrderItemTodoType,
    tripId: response.trip_id,
    orderItemId: response.order_item_id,
    traceId: response.trace_id,
    tripStatus: response.trip_status as TripStatusKey,
    jobType: response.job_type as OrderItemJobTypeKey,
    status: response.status as OrderItemStatusKey,
    arrangerName: response.arranger_name,
    category: response.category,
    organizationName: response.organization_name,
    waiting: response.waiting,
    startDate: response.start_date ? moment(response.start_date) : undefined,
    endDate: response.end_date ? moment(response.end_date) : undefined,
    receivedAt: response.received_at ? moment(response.received_at) : undefined,
    lastUserMessage: response.last_user_message,
    lastUserMessageId: response.last_user_message_id,
    lastLogMessage: response.last_log_message,
    lastLogMessageArranger: response.last_log_message_arranger,
    snoozeTo: response.snooze_to ? moment(response.snooze_to) : undefined,
    snoozeMessage: response.snooze_message,
    isUrgent: response.is_urgent,
    sp: response.sp,
    useQr: response.use_qr || false,
    premiumSupport: response.premium_support || false,
    inventoriedAt: response.inventoried_at ? moment(response.inventoried_at) : undefined,
    inventoriedBy: response.inventoried_by,
    provider: response.provider,
    autoBooked: response.auto_booked || false,
    autoApproved: response.auto_approved || false,
    autoApprovedMessage: response.auto_approved_message,
    description: response.description,
    traceDescription: response.trace_description,
    diffDescription: response.diff_description || []
  };
  return args;
};

export default class HotelOrderItemTodo {
  @observable readonly todoType: HotelOrderItemTodoType;

  @observable readonly tripId: number;

  @observable readonly orderItemId?: number;

  @observable readonly traceId?: number;

  @observable readonly tripStatus: TripStatusKey;

  @observable jobType: OrderItemJobTypeKey;

  @observable status: OrderItemStatusKey;

  @observable arrangerName?: string;

  @observable category?: OrderItemCategoryKey;

  @observable organizationName?: string;

  @observable waiting?: boolean;

  @observable startDate?: Moment;

  @observable endDate?: Moment;

  @observable receivedAt?: Moment;

  @observable lastUserMessage?: string;

  @observable lastUserMessageId?: number;

  @observable lastLogMessage?: string;

  @observable lastLogMessageArranger?: string;

  @observable snoozeTo?: Moment;

  @observable snoozeMessage?: string;

  @observable isUrgent: boolean;

  @observable sp: boolean;

  @observable useQr: boolean;

  @observable premiumSupport: boolean;

  @observable inventoriedAt?: Moment;

  @observable inventoriedBy?: string;

  @observable provider?: HotelElementProviderKey;

  @observable autoBooked: boolean;

  @observable autoApproved: boolean;

  @observable autoApprovedMessage?: string;

  @observable description?: string;

  @observable traceDescription?: string;

  @observable diffDescription: Diff[][];

  constructor(res: HotelOrderItemTodoResponseArgs) {
    const args = convertHotelOrderItemTodoResponseToArgs(res);
    this.todoType = args.todoType;
    this.tripId = args.tripId;
    this.orderItemId = args.orderItemId;
    this.traceId = args.traceId;
    this.tripStatus = args.tripStatus;
    this.jobType = args.jobType;
    this.status = args.status;
    this.arrangerName = args.arrangerName;
    this.category = args.category;
    this.organizationName = args.organizationName;
    this.waiting = args.waiting;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.receivedAt = args.receivedAt;
    this.lastUserMessage = args.lastUserMessage;
    this.lastUserMessageId = args.lastUserMessageId;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
    this.snoozeTo = args.snoozeTo;
    this.snoozeMessage = args.snoozeMessage;
    this.isUrgent = args.isUrgent;
    this.sp = args.sp;
    this.useQr = args.useQr;
    this.premiumSupport = args.premiumSupport;
    this.inventoriedAt = args.inventoriedAt;
    this.inventoriedBy = args.inventoriedBy;
    this.provider = args.provider;
    this.autoBooked = args.autoBooked;
    this.autoApproved = args.autoApproved;
    this.autoApprovedMessage = args.autoApprovedMessage;
    this.description = args.description;
    this.traceDescription = args.traceDescription;
    this.diffDescription = args.diffDescription;
  }
}
