import React, { useState } from 'react';

import type User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';
import MembersList from './members_list/members_list';

interface Props {
  travelers: Traveler[];
  currentIndex: number;
  onSelect: (i: number, traveler: Traveler) => void;
}

const SelectTravelers: React.FC<Props> = ({ travelers, currentIndex, onSelect }: Props) => {
  const [showMemberList, setShowMemberList] = useState(false);
  const traveler = travelers[currentIndex];
  const toggleMemberList = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowMemberList(!showMemberList);
  };

  const handleMemberSelect = (member: User) => {
    setShowMemberList(false);
    onSelect(currentIndex, new Traveler(member));
  };

  return (
    <div className="select-travelers">
      <div className="select-travelers__wrapper">
        <div className="select-travelers__selected-list__item" onClick={e => toggleMemberList(e)}>
          {traveler && traveler.id ? (
            <div className="members-list__item__info">
              {traveler.departmentName() && <p className="members-list__item-part">{traveler.departmentName()}</p>}
              <p className="members-list__item-part">{traveler.name}</p>
              <p className="members-list__item-part">({traveler.email})</p>
              {traveler.employee_number !== null && (
                <p className="members-list__item-part">[{traveler.employee_number}]</p>
              )}
            </div>
          ) : (
            <p className="select-travelers__selected-list__message">選択してください</p>
          )}
          <div className="select-travelers__selected-list__right">{showMemberList ? '▲' : '▼'}</div>
        </div>
        {showMemberList && <MembersList onSelect={handleMemberSelect} />}
      </div>
    </div>
  );
};

export default SelectTravelers;
