import moment from 'moment';
import OrderItemCategoryStrMap from '@this/domain/order_item/order_item_category_str_map';

export interface ImportItemArgs {
  id: number;
  price: number;
  project_id: number | null;
  project_code: string;
  project_name: string;
  department_id: number | null;
  department_code: string;
  department_name: string;
  import_item_category_id: string;
  import_item_category_name: string;
  date: string;
  start_date: string | null;
  end_date: string | null;
  employee_id: number | null;
  employee_code: string;
  employee_name: string;
}

export class ImportItem {
  id: number;

  price: number;

  projectId: number | null;

  projectCode: string;

  projectName: string;

  departmentId: number | null;

  departmentCode: string;

  departmentName: string;

  importItemCategoryId: string;

  importItemCategoryName: string;

  date: string;

  startDate: string;

  endDate: string;

  employeeId: number | null;

  employeeCode: string;

  employeeName: string;

  constructor(args: ImportItemArgs) {
    this.id = args.id;
    this.price = args.price;
    this.projectId = args.project_id;
    this.projectCode = args.project_code;
    this.projectName = args.project_name;
    this.departmentId = args.department_id;
    this.departmentCode = args.department_code;
    this.departmentName = args.department_name;
    this.importItemCategoryId = args.import_item_category_id;
    this.importItemCategoryName = args.import_item_category_name;
    this.date = moment(args.date).format('YYYY-MM-DD');
    this.startDate = '';
    if (args.start_date) {
      this.startDate = moment(args.start_date).format('YYYY-MM-DD');
    }
    this.endDate = '';
    if (args.end_date) {
      this.endDate = moment(args.end_date).format('YYYY-MM-DD');
    }
    this.employeeId = args.employee_id;
    this.employeeCode = args.employee_code;
    this.employeeName = args.employee_name;
  }

  isOther() {
    if (this.importItemCategoryId === 'other') {
      return true;
    }
    return false;
  }

  categoryStr() {
    if (this.importItemCategoryId in OrderItemCategoryStrMap) {
      return OrderItemCategoryStrMap[this.importItemCategoryId];
    }
    return 'その他';
  }
}
