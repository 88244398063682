import React from 'react';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import type Item from '@this/domain/expenses/item';

interface Props {
  item: Item | null;
}

const ReceiptIconLink = observer(({ item }: Props) => {
  return item && item.receiptFilePath ? (
    <ClipIcon
      href={`/biztra/images/${item.receiptFilePath}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => e.stopPropagation()}
    />
  ) : null;
});

const ClipIcon = styled.a`
  display: block;
  background: url('/images/clip.png') no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;

  &:hover {
    background-image: url('/images/clip_highlighted.png');
  }
`;

export default ReceiptIconLink;
