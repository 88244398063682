import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

export type ActionType = 'accept' | 'reject';
export type StatusType = 'queue' | 'running' | 'success' | 'failure';

export class InAdvanceApprovalBatchItem {
  id: number;

  tripId: number;

  action: ActionType;

  status: StatusType;

  rejectMesasge: string | null = null;

  errorMessage: string | null = null;

  updatedAt: Moment;

  constructor(args: InAdvanceApprovalBatchItemArgs) {
    this.id = args.id;
    this.tripId = args.trip_id;
    this.action = args.action;
    this.status = args.status;
    this.rejectMesasge = args.reject_mesasge || null;
    this.errorMessage = args.error_message || null;
    this.updatedAt = moment(args.updated_at);
  }

  isRunning() {
    return this.status === 'queue' || this.status === 'running';
  }

  isSuccess() {
    return this.status === 'success';
  }

  isFailure() {
    return this.status === 'failure';
  }
}

export interface InAdvanceApprovalBatchItemArgs {
  id: number;

  trip_id: number;

  action: ActionType;

  status: StatusType;

  reject_mesasge?: string;

  error_message?: string;

  updated_at: string;
}
