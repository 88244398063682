import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { styled } from '@this/constants/themes';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { reportError } from '@this/lib/bugsnag';

interface OrganizationsListResponse {
  organizations: string[];
}

interface Props {
  onSelect: (organization: string) => void;
}

interface State {
  organizations: string[];
  isLoading: boolean;
}

class SelectTravelersBlockList extends React.Component<Props, State> {
  input: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      organizations: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchOrganizations('');
  }

  componentWillUnmount() {
    if (this.fetchOrganizationsSubscription) {
      this.fetchOrganizationsSubscription.unsubscribe();
    }
  }

  handleSearchOrganizations = (_e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyword = this.input.current!.value;
    this.fetchOrganizationsSubject.next(keyword);
  };

  fetchOrganizations = async (keyword: string) => {
    this.setState({ isLoading: true });
    try {
      const res = await Fetcher.get<OrganizationsListResponse>('/arrangement/organizations/search.json', {
        keyword
      });
      this.setState({
        isLoading: false,
        organizations: res.organizations
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  };

  private fetchOrganizationsSubject = new Subject<string>();

  private fetchOrganizationsSubscription = this.fetchOrganizationsSubject
    .pipe(debounceTime(300))
    .subscribe(this.fetchOrganizations);

  handleSelect(organization: string) {
    this.props.onSelect(organization);
  }

  render() {
    try {
      const { organizations: filteredOrganizations, isLoading } = this.state;
      return (
        <>
          <Input type="search" ref={this.input} placeholder="検索" onKeyUp={this.handleSearchOrganizations} />
          <ul>
            {isLoading ? (
              <li>
                <SimpleLoading />
              </li>
            ) : (
              <>
                <ListItem key={-1} onClick={() => this.handleSelect('')}>
                  選択を外す
                </ListItem>
                {filteredOrganizations.map((o, i) => (
                  <ListItem key={i} onClick={() => this.handleSelect(o)}>
                    {o}
                  </ListItem>
                ))}
              </>
            )}
          </ul>
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Input = styled.input`
  &&& {
    margin-bottom: 0;
  }
`;

const ListItem = styled.li`
  background: ${props => props.theme.fieldBgColor};
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #ccc;
  white-space: nowrap;
`;

export default SelectTravelersBlockList;
