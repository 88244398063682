interface FreeeCompanyArgs {
  id: number;
  display_name: string;
}

class FreeeCompany {
  public id: number;

  public displayName: string;

  constructor(args: FreeeCompanyArgs) {
    this.id = args.id;
    this.displayName = args.display_name;
  }
}

export default FreeeCompany;
