import ReactDOM from 'react-dom';
import AITravelRoot from '@this/components/root';
import { UserConfirm } from '@this/components/user_confirm';
import Admin from '@this/components/admin/admin';
import MarketLogClass from '@this/components/market_log/market_log';
import Simulation from '@this/components/lp/simulation/simulation';
import Thanks from '@this/components/lp/simulation/thanks/thanks';
import type React from 'react';
import { AdminRoot } from '@this/components/admin/admin_root';
import { BiztraRoot } from '@this/components/expenses/biztra_root';
import { ArrangementRoot } from '@this/components/arrangement/arrangement_root';
import Root from './root';

window.app = { render: () => {} };
const ROOT_SELECTOR = '#content';
const HEADER_PREFIX = 'T.';
const COMPONENTS: Record<string, React.ComponentType<any>> = {
  Root: AITravelRoot,
  MarketLog: MarketLogClass,
  UserConfirm,
  Simulation,
  Thanks,
  Admin,
  AdminRoot,
  ArrangementRoot,
  BiztraRoot
};

const parseProps = (el: Element | null) => {
  const str = el?.getAttribute('data-react-props');
  try {
    return JSON.parse(str ?? '');
  } catch (e) {
    return {};
  }
};

const getComponentClass = (el: Element | null) => {
  const str = el?.getAttribute('data-react-class');
  if (str) {
    const componentName = str.replace(HEADER_PREFIX, '');
    return COMPONENTS[componentName];
  }
  return null;
};

const onLoad = () => {
  const root = document.querySelector(ROOT_SELECTOR);
  const renderedRoot = document.querySelector(`${ROOT_SELECTOR} > *`);

  const props = parseProps(renderedRoot);
  const component = getComponentClass(renderedRoot);
  if (component) {
    // eslint-disable-next-line react/no-render-return-value
    window.app.render = () => ReactDOM.render(Root(component, props), root);
    app.render();
  }
};

window.addEventListener('DOMContentLoaded', onLoad);
window.addEventListener('page:load', onLoad);
