import React from 'react';
import { styled } from '@this/constants/themes';
import type User from '../../../domain/user/user';

interface Props {
  user: User | null | undefined;
}

const ExpensesFooter = ({ user }: Props) => (
  <Footer>
    <FooterContainer>
      <Content>
        <NaviList>
          <NaviItem>
            <NaviLink href="https://transferdata.co.jp/about" target="_blank">
              会社概要
            </NaviLink>
          </NaviItem>
          <NaviItem>
            <NaviLink href="https://biztra.cloud/etc.html" target="_blank">
              利用規約
            </NaviLink>
          </NaviItem>
          <NaviItem>
            <NaviLink href="https://biztra.cloud/data/biztra_manual.pdf" target="_blank">
              マニュアル
            </NaviLink>
          </NaviItem>
          <NaviItem>
            <NaviLink href="https://aitravel.cloud/sct" target="_blank">
              特定商取引に関する法律に基づく表記
            </NaviLink>
          </NaviItem>
          <NaviItem>
            <NaviLink href="https://transferdata.co.jp/privacy-policy#a" target="_blank">
              個人情報保護方針 / 個人情報の取り扱いについて
            </NaviLink>
          </NaviItem>
          {user ? (
            <NaviItem>
              <NaviLink href="/biztra/users/sign_out">ログアウト</NaviLink>
            </NaviItem>
          ) : null}
        </NaviList>
      </Content>
      <Logo>
        <p style={{ fontSize: '0.8em' }}>Transfer Data Inc.</p>
      </Logo>
    </FooterContainer>
  </Footer>
);

const footerBgColor = `#faf9f8`;

const Footer = styled.footer`
  background: ${footerBgColor};
  min-height: 50px;
  text-align: center;
`;

const FooterContainer = styled.div`
  max-width: 1000px;
  min-height: 50px;
  margin: auto;
`;

const Content = styled.div`
  float: left;
  width: calc(100% - 120px - 15px);
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const NaviList = styled.ul`
  padding: 15px;
  text-align: left;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
`;

const NaviItem = styled.li`
  display: inline-block;
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    &:first-child {
      margin-left: 20px;
    }
  }
`;

const NaviLink = styled.a`
  font-size: 0.8em;
  color: #b9b2a5;
`;

const Logo = styled.div`
  float: left;
  box-sizing: content-box;
  width: 120px;
  padding: 20px 10px 15px 0;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    margin-bottom: 25px;
  }
`;

export default ExpensesFooter;
