import React from 'react';
import _ from 'lodash';

interface Props {
  message: string | string[] | null;
  handleFlashMessageClose?: (e: React.MouseEvent<HTMLDivElement>) => void;
  type: 'error';
}

const FlashMessage = ({ type, message, handleFlashMessageClose = _.noop }: Props) => {
  const lines = () => {
    if (!message) return [];
    const ms = _.isArray(message) ? message : [message];
    return _.flatten(_.map(ms, m => m.split('\n')));
  };
  try {
    return (
      <div className="flash-message">
        {message && message.length > 0 && (
          <div className={`flash-message__${type}`}>
            <div>
              {lines().map((l, i) => (
                <p key={i}>{l}</p>
              ))}
            </div>
            <div className="flash-message__close" onClick={handleFlashMessageClose}>
              ×
            </div>
          </div>
        )}
      </div>
    );
  } catch (e) {
    return utils.sendErrorObject(e);
  }
};

export default FlashMessage;
