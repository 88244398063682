import _ from 'lodash';
import moment from '../lib/moment';

export interface Args {
  carType: [string, string][];
  rentalCarAvailable: boolean;
}

export interface RentalCar {
  departureDate: string | undefined;

  returnDate: string | undefined;

  departurePlace: string;

  returnPlace: 'current' | 'other';

  returnPlaceName: string;

  carType: 'compact' | 'other';

  carTypeOptions: [string, string][];

  carTypeOther: string;
}

class RentalCarInfo<T = Record<never, never>> {
  rentalCarAvailable: boolean;

  rentalCarRequired: 'true' | 'false';

  carTypeOptions: [string, string][];

  rentalCars: (RentalCar & T)[];

  constructor(args: Args & Partial<T>) {
    this.rentalCarAvailable = args.rentalCarAvailable;
    this.rentalCarRequired = 'false'; // args.rentalCarRequired || 'false';
    this.carTypeOptions = args.carType || '';
    this.rentalCars = [
      {
        departureDate: moment().add(1, 'days').format('YYYY-MM-DD'),
        returnDate: moment().add(2, 'days').format('YYYY-MM-DD'),
        departurePlace: '',
        returnPlace: 'current',
        returnPlaceName: '',
        carType: 'compact',
        carTypeOptions: args.carType || '',
        carTypeOther: '',
        ...this.setDefaultRentalCar()
      }
    ];
  }

  /* eslint class-methods-use-this: 0 */
  setDefaultRentalCar(): T & Partial<RentalCar> {
    return {} as T;
  }

  isRentalCarRequired() {
    return this.rentalCarRequired === 'true' ? true : undefined;
  }

  rentalCarsParam() {
    return _.map(this.rentalCars, rentalCar => ({
      departure_date: rentalCar.departureDate,
      departure_place: rentalCar.departurePlace,
      return_date: rentalCar.returnDate,
      return_place: RentalCarInfo.returnPlaceParam(rentalCar),
      car_type: rentalCar.carType,
      car_type_other: RentalCarInfo.carTypeOtherParam(rentalCar)
    }));
  }

  departureDateParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.departureDate);
  }

  departurePlaceParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.departurePlace);
  }

  returnDateParam() {
    return _.map(this.rentalCars, rentalCar => rentalCar.returnDate);
  }

  static returnPlaceParam(rentalCar: {
    departureDate: string | undefined;
    returnDate: string | undefined;
    departurePlace: string;
    returnPlace: 'current' | 'other';
    returnPlaceName: string;
    carType: 'compact' | 'other';
    carTypeOptions: [string, string][];
    carTypeOther: string;
  }) {
    if (rentalCar.returnPlace === 'current') {
      return rentalCar.departurePlace;
    }
    return rentalCar.returnPlaceName;
  }

  static carTypeOtherParam(rentalCar: {
    departureDate: string | undefined;
    returnDate: string | undefined;
    departurePlace: string;
    returnPlace: 'current' | 'other';
    returnPlaceName: string;
    carType: 'compact' | 'other';
    carTypeOptions: [string, string][];
    carTypeOther: string;
  }) {
    if (rentalCar.carType === 'other') {
      return rentalCar.carTypeOther;
    }
    return '';
  }

  private static requiredError(name: string, value: string | undefined, message = 'を入力してください') {
    // if (message == null) { message = 'を入力してください'; }
    if (!value || value.length === 0) {
      return name + message;
    }

    return undefined;
  }

  validationErrors() {
    const errors: { [key: string]: string | undefined } = {};
    if (this.isRentalCarRequired()) {
      if (_.isEmpty(this.rentalCars[0].departurePlace)) {
        errors.departurePlace = RentalCarInfo.requiredError('出発場所', this.rentalCars[0].departurePlace);
      }
      if (this.rentalCars[0].returnPlace !== 'current' && _.isEmpty(this.rentalCars[0].returnPlaceName)) {
        errors.returnPlace = RentalCarInfo.requiredError('返却場所', this.rentalCars[0].returnPlaceName);
      }
      if (this.rentalCars[0].carType === 'other' && _.isEmpty(this.rentalCars[0].carTypeOther)) {
        errors.carType = RentalCarInfo.requiredError('車種・クラス', this.rentalCars[0].carTypeOther);
      }
    }

    return utils.compactObject(errors);
  }

  submitParams() {
    const params: { [key: string]: any } = {
      rental_car_required: this.isRentalCarRequired()
    };
    if (this.rentalCarRequired === 'true') {
      params.rental_cars = this.rentalCarsParam();
    }
    return params;
  }

  setRentalCarRequired(value: never) {
    this.rentalCarRequired = value;
    app.render();
  }

  setDeparturePlace(value: never, i: number) {
    this.rentalCars[i].departurePlace = value;
    app.render();
  }

  setDepartureDate(value: never, i: number) {
    this.rentalCars[i].departureDate = value;
    app.render();
  }

  setReturnPlace(value: never, i: number) {
    this.rentalCars[i].returnPlace = value;
    app.render();
  }

  setReturnDate(value: never, i: number) {
    this.rentalCars[i].returnDate = value;
    app.render();
  }

  setReturnPlaceName(value: never, i: number) {
    this.rentalCars[i].returnPlaceName = value;
    app.render();
  }

  setCarType(value: never, i: number) {
    this.rentalCars[i].carType = value;
    app.render();
  }

  setCarTypeOther(value: never, i: number) {
    this.rentalCars[i].carTypeOther = value;
    app.render();
  }

  addRentalCar() {
    this.rentalCars.push({
      departureDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      returnDate: moment().add(2, 'days').format('YYYY-MM-DD'),
      departurePlace: '',
      returnPlace: 'current',
      returnPlaceName: '',
      carType: 'compact',
      carTypeOptions: this.carTypeOptions,
      carTypeOther: '',
      ...this.setDefaultRentalCar()
    });
    app.render();
  }

  removeRentalCar(i: number) {
    this.rentalCars = this.rentalCars.filter((r, ri) => ri !== i);
    app.render();
  }
}

export default RentalCarInfo;
