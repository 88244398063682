import { observable } from 'mobx';

export type PaymentMethodTypeArgs = {
  id?: number;
  code?: string;
  name?: string;
};

export const initializePaymentMethodType: PaymentMethodTypeArgs = {
  id: undefined,
  code: '',
  name: ''
};

type EditableFields = 'id' | 'code' | 'name';

class PaymentMethodType {
  @observable
  id?: number;

  @observable
  code: string;

  @observable
  name: string;

  constructor(args: PaymentMethodTypeArgs) {
    this.id = args.id;
    this.code = args.code || '';
    this.name = args.name || '';
  }

  codeWithName() {
    return `${this.name}${this.code && ` - ${this.code}`}`;
  }

  setField<T extends EditableFields>(field: T, value: this[T]) {
    this[field] = value;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      code: this.code,
      name: this.name
    };
  }
}

export default PaymentMethodType;
