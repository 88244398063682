import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from '@this/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import { TableCell, TableRow } from '@this/components/shared/ui/data_displays/table';
import type MessageTodo from '@this/src/domain/arrangement/message_todo';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import moment from '@this/src/lib/moment';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { Fetcher } from '@this/src/util';
import TripStatusIndicator from '../shared/trip_status_indicator';

interface Props {
  messageTodo: MessageTodo;
  onUpdate: () => void;
}

const MessageTodoRow = observer(({ messageTodo, onUpdate }: Props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const markMessageFinish = (messageId: number) => {
    setIsSaving(true);
    Fetcher.put(`/arrangement/messages/${messageId}/finish`, {})
      .then(() => onUpdate())
      .finally(() => setIsSaving(false));
  };

  return (
    <TableRow>
      <TableCell>
        <TripStatusIndicator status={messageTodo.trip_status as TripStatusKey} />
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell>メッセージ</TableCell>
      <TableCell />
      <TableCell>×</TableCell>
      <TableCell />
      <TableCell>{moment(messageTodo.created_at).format('YYYY-MM-DD HH:mm')}</TableCell>
      <TableCell>{messageTodo.message}</TableCell>
      <TableCell nowrap>
        <Link
          href={`/arrangement/virtual_counter?trip_id=${messageTodo.trip_id}`}
          target="_blank"
          rel="noopener noreffer"
        >
          {messageTodo.trip_id}
        </Link>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell>
        {isSaving ? (
          <Loading />
        ) : (
          <Button size="small" onClick={() => markMessageFinish(messageTodo.id)}>
            対応不要
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
});

export default MessageTodoRow;
