import React from 'react';

const ChatIcon: React.FC = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 9.0001C19.6 13.6393 15.302 17.4001 10 17.4001C8.21006 17.4001 6.53451 16.9714 5.10015 16.2251L0.400024 17.4001L2.00599 13.6528C0.991386 12.3209 0.400024 10.7209 0.400024 9.0001C0.400024 4.36091 4.69809 0.600098 10 0.600098C15.302 0.600098 19.6 4.36091 19.6 9.0001ZM6.40002 7.8001H4.00002V10.2001H6.40002V7.8001ZM16 7.8001H13.6V10.2001H16V7.8001ZM8.80002 7.8001H11.2V10.2001H8.80002V7.8001Z"
        fill="white"
      />
    </svg>
  );
};

export default ChatIcon;
