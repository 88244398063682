// TODO: Template Literal Typeつかってこうしたい: `#${string}` | `rgb(${string},${string},${string})`
// typescirpt4.1〜にするまで
export type ColorCode = string;
export type AlphaColorCode = string;

type ColorHues = {
  50: ColorCode;
  100: ColorCode;
  200: ColorCode;
  300: ColorCode;
  400: ColorCode;
  500: ColorCode;
  600: ColorCode;
  700: ColorCode;
  800: ColorCode;
  900: ColorCode;
};
type GrayColorHues = ColorHues & { 5: ColorCode; 20: ColorCode };
type PaletteColorName = 'brand' | 'darkBlue' | 'red' | 'blue' | 'yellow' | 'green';

export type PaletteColors = Record<PaletteColorName, ColorHues> & { gray: GrayColorHues };

const baseColors: Record<'black' | 'white', ColorCode> = {
  black: '#000',
  white: '#fff'
};

const alphaColors: Record<'blackAlpha' | 'whiteAlpha', AlphaColorCode> = {
  blackAlpha: 'rgba(27,27,27,0.5)',
  whiteAlpha: 'rgba(255,255,255,0.5)'
};

const colorPalette: PaletteColors = {
  brand: {
    50: '#f7f5ef',
    100: '#e6dfcd',
    200: '#dad0b5',
    300: '#c9ba93',
    400: '#bfad7e',
    500: '#af985e',
    600: '#9f8a56',
    700: '#7c6c43',
    800: '#605434',
    900: '#4a4027'
  },

  darkBlue: {
    50: '#eff0f2',
    100: '#ced0d7',
    200: '#b6bac4',
    300: '#959aa9',
    400: '#818698',
    500: '#61687e',
    600: '#585f73',
    700: '#454a59',
    800: '#353945',
    900: '#292c35'
  },

  gray: {
    5: '#fcfcfc',
    20: '#fafafa',
    50: '#ececec',
    100: '#c4c4c4',
    200: '#a7a7a7',
    300: '#7f7f7f',
    400: '#666666',
    500: '#404040',
    600: '#3a3a3a',
    700: '#2d2d2d',
    800: '#232323',
    900: '#1b1b1b'
  },

  red: {
    50: '#f6eae6',
    100: '#e4beb3',
    200: '#d79e8e',
    300: '#c4725a',
    400: '#b9563a',
    500: '#a72c09',
    600: '#982808',
    700: '#771f06',
    800: '#5c1805',
    900: '#461204'
  },

  blue: {
    50: '#edf6fa',
    100: '#c6e3ee',
    200: '#abd5e6',
    300: '#84c2da',
    400: '#6db6d3',
    500: '#48a4c8',
    600: '#4295b6',
    700: '#33748e',
    800: '#285a6e',
    900: '#1e4554'
  },

  yellow: {
    50: '#fbf6e7',
    100: '#f2e3b4',
    200: '#ebd690',
    300: '#e2c35d',
    400: '#ddb83d',
    500: '#d4a60d',
    600: '#c1970c',
    700: '#977609',
    800: '#755b07',
    900: '#594605'
  },

  green: {
    50: '#edf4f0',
    100: '#c7decf',
    200: '#acceb8',
    300: '#86b797',
    400: '#6ea983',
    500: '#4a9464',
    600: '#43875b',
    700: '#356947',
    800: '#295137',
    900: '#1f3e2a'
  }
};

export const colors = {
  ...baseColors,
  ...alphaColors,
  ...colorPalette
};

export type Colors = typeof colors;
