import React from 'react';
import ReactDOM from 'react-dom';
import { styled, createTheme } from '@this/constants/themes';
import type Hotel from '@this/domain/hotel/hotel';
import type HotelList from '@this/domain/hotel/hotel_list';
import { AITThemeProvider } from '@this/shared/ui/theme';

interface HotelMarkerProps {
  price: number;
  selected: boolean;
  showDetail: boolean;
  serviceId: number;
}

const HotelmarkerDiv = styled.div<{ selected: boolean; showDetail: boolean }>`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 3px 5px;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: ${props =>
    props.selected ? props.theme.accentColor : props.showDetail ? props.theme.accentColorHover : 'white'};
  color: ${props => (props.selected || props.showDetail ? 'white' : props.theme.accentColor)};
`;

const Yen = styled.span`
  font-size: 8px;
  margin-left: 1px;
`;
const HotelMarkerComponent = ({ price, selected, showDetail, serviceId }: HotelMarkerProps) => {
  const theme = createTheme(serviceId);
  return (
    <AITThemeProvider theme={theme}>
      <HotelmarkerDiv selected={selected} showDetail={showDetail}>
        {utils.digits(price)}
        <Yen>円</Yen>
      </HotelmarkerDiv>
    </AITThemeProvider>
  );
};

class HotelMarker extends google.maps.OverlayView {
  content: HTMLDivElement | null;

  hotel: Hotel;

  hotels: HotelList;

  location: google.maps.LatLng | null;

  public selected: boolean;

  public showDetail: boolean;

  serviceId: number;

  render: (() => void) | null;

  handleSelect: (hotel: Hotel) => void;

  constructor(hotel: Hotel, hotels: HotelList, serviceId: number, handleSelect: (hotel: Hotel) => void) {
    super();
    this.content = null;
    this.hotel = hotel;
    this.hotels = hotels;
    if (hotel.latitude && hotel.longitude) {
      this.location = new google.maps.LatLng(hotel.latitude, hotel.longitude);
    } else {
      this.location = null;
    }
    this.selected = false;
    this.showDetail = false;
    this.serviceId = serviceId;
    this.render = null;
    this.handleSelect = handleSelect;
  }

  draw() {
    let price: number | null;
    if (this.hotel.package_type) {
      price = this.hotel.package_base_price || null;
    } else {
      price = this.hotel.getAveragePrice() || null;
    }
    if (!price) {
      return;
    }
    if (!this.content) {
      const content = document.createElement('div');
      content.style.position = 'absolute';
      if (this.selected) {
        content.style.zIndex = '2';
      } else {
        content.style.zIndex = '0';
      }

      const panes = this.getPanes();
      panes.overlayLayer.appendChild(content);
      panes.overlayMouseTarget.appendChild(content);

      google.maps.event.addDomListener(content, 'click', () => {
        this.handleSelect(this.hotel);
      });

      this.content = content;
    }
    this.render = () => {
      if (price) {
        ReactDOM.render(
          React.createElement(HotelMarkerComponent, {
            price,
            selected: this.selected,
            showDetail: this.showDetail,
            serviceId: this.serviceId
          }),
          this.content
        );
      }
    };
    this.render();

    const projection = this.getProjection();
    if (!this.location) {
      return;
    }
    const point = projection.fromLatLngToDivPixel(this.location);
    this.content.style.left = `${point.x - this.content.offsetWidth / 2}px`;
    this.content.style.top = `${point.y - this.content.offsetHeight / 2}px`;
  }

  setMap(map: google.maps.Map | null) {
    super.setMap(map);

    if (map === null) {
      // マーカーが削除される場合、DOMからマーカー要素を削除する
      const marker = this.content;
      if (marker && marker.parentNode) {
        marker.parentNode.removeChild(marker);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onRemove() {}
}

export default HotelMarker;
