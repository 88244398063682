import React, { useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { media } from '@this/components/shared/atoms/media';
import { styled } from '@this/constants/themes';
import type Trip from '@this/src/domain/trip/trip';
import { useInAdvanceApplicationStore } from '@this/src/domain/in_advance/in_advance_application_store';
import InAdvanceApplicationRow from './in_advance_application_row';
import { Checkbox } from '../../shared/ui/inputs/checkbox';

const InAdvanceApplicationTable: React.FC = () => {
  const store = useInAdvanceApplicationStore();
  const { trips, batch, allSelected } = store;
  const status = store.searchStatus();
  const colSpan = status === 'applied' ? 11 : 10;

  const handleCheckAll = useCallback(() => store.setBatchSelectedAll(), [store]);

  const handleOrderCategories = useCallback((trip: Trip) => store.orderItemCategories(trip), [store]);

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <TableHeadRow>
            {status === 'applied' && (
              <TableCell>
                <Checkbox checked={allSelected} onChange={handleCheckAll} />
              </TableCell>
            )}
            <TableCell style={{ width: 80 }}>旅程番号</TableCell>
            <TableCell style={{ width: 100 }}>
              <TableCellRow>出張者</TableCellRow>
              <TableCellGrayRow>(申請者)</TableCellGrayRow>
            </TableCell>
            <TableCell style={{ minWidth: 120 }}>出張先</TableCell>
            <TableCell style={{ minWidth: 120 }}>出張の目的</TableCell>
            <TableCell style={{ width: 100 }}>旅程日程</TableCell>
            <TableCell style={{ width: 80 }}>申請金額</TableCell>
            <TableCell style={{ width: 80 }}>予約商品</TableCell>
            <TableCell style={{ width: 90 }}>申請日</TableCell>
            <TableCell style={{ width: 100 }}>
              <TableCellRow>旅費規程</TableCellRow>
              <TableCellRow>チェッカー</TableCellRow>
            </TableCell>
            <TableCell style={{ width: 140 }}>
              {status === 'applied'
                ? '詳細'
                : status === 'approved'
                ? '承認者'
                : status === 'rejected' && '却下理由'}
            </TableCell>
            {status === 'approved' && <TableCell style={{ width: 120 }}>承認理由</TableCell>}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {trips.map(trip => (
            <InAdvanceApplicationRow
              key={trip.id}
              trip={trip}
              batch={batch}
              status={status}
              getOrderCategories={handleOrderCategories}
            />
          ))}
          {trips.length < 1 && (
            <TableRow>
              <TableCell colSpan={colSpan}>対象の承認依頼はありません</TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    padding: 8px;

    &:first-child {
      padding-left: 16px;
    }
    &:last-child {
      padding-right: 16px;
    }

    span {
      display: inline-block;
      padding-right: 4px;

      &:last-child {
        padding-right: 0;
      }
    }

    .MuiCheckbox-root .MuiIconButton-label {
      display: flex;
      align-items: center;
    }
  }

  ${media.sp`
    display: block;

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .MuiTableRow-root {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 12px;
      padding: 16px;
      border: solid 1px ${props => props.theme.grayBorderColor};
      border-radius: 4px;
    }

    .MuiTableCell-root {
      padding: 0;
      border: 0;

      &:first-child {
        padding: 0;
      }
    }
  `}
`;

const TableHeadRow = styled(TableRow)`
  background-color: ${props => props.theme.grayBgColor};

  .MuiTableCell-head {
    line-height: 1.5;
  }
`;

const TableCellRow = styled.div``;

const TableCellGrayRow = styled.div`
  font-size: 0.9em;
  color: ${props => props.theme.grayTextColor};
`;

export default InAdvanceApplicationTable;
