import React from 'react';

import { styled } from '@this/constants/themes';

interface Props {
  title: string;
}

export const SearchTrackingDetailSection: React.FC<Props> = ({ title, children }) => {
  return (
    <Section>
      <Title>{title}</Title>
      {children}
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
`;
