import React from 'react';
import _ from 'lodash';

import type SearchResultItem from '../../../../domain/search_result_item';

import SelectOutlineItemShortdistanceTemplate from './outline_item_shortdistance.template';

import type { Tab } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';

export interface SelectOutlineItemShortdistanceProps {
  icon: number;
  item: SearchResultItem;
  handleClickTab: (tab: Tab) => void;
  handleSelectItem: () => void;
  selected: boolean;
  hotelPriceLimit: number | null;
  store: SelectStore;
  direction?: string;

  // ArrangementRequestForm
  isRequestForm: boolean;
  handleOpenShowSearchBox: () => void;

  isTripsForm?: boolean;
}

class SelectOutlineItemShortdistance extends React.Component<SelectOutlineItemShortdistanceProps> {
  handleCssTransitionExited = () => {
    if (this.props.item.elementList) this.props.item.elementList.handleTransitionExited();
    this.props.handleSelectItem();
  };

  setItemLoading = (loading: boolean) => {
    if (this.props.item) {
      this.props.item.setLoadingWithSelectedLoading(loading);
    }
  };

  render() {
    const isNeedTexts: string[] = this.props.isTripsForm ? ['削除する', '元に戻す'] : ['予約しない', '予約する'];
    try {
      return (
        <SelectOutlineItemShortdistanceTemplate
          {...this.props}
          {...this.state}
          isNeedTexts={isNeedTexts}
          handleCssTransitionExited={this.handleCssTransitionExited}
          setItemLoading={this.setItemLoading}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectOutlineItemShortdistance;
