export interface ParticipantCompanyJson {
  id?: number;
  name: string;
  people_num: number;
  own: boolean;
}

class ParticipantCompany {
  id?: number;

  name: string;

  peopleNum: number;

  own: boolean;

  destroy?: string;

  constructor(args: ParticipantCompanyJson) {
    this.id = args.id;
    this.name = args.name;
    this.peopleNum = args.people_num;
    this.own = args.own;
  }

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setPeopleNum = (value: string) => {
    this.peopleNum = parseInt(value, 10);
    app.render();
  };

  setDestroy = () => {
    this.destroy = '1';
    app.render();
  };

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      people_num: this.peopleNum,
      own: this.own,
      destroy: this.destroy
    };
  }
}

export default ParticipantCompany;
