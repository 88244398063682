import React from 'react';
import { styled, css } from '@this/constants/themes';
import { media } from '@this/src/components/shared/atoms/media';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import type { TripForReport } from '@this/src/domain/trip_report/trip';
import type { OrderItemCategoryOptions } from '@this/src/domain/order_item';
import { Divider } from '@material-ui/core';
import type { NonOrderItemForReport } from '@this/src/domain/trip_report/non_order_item';
import type { AllowanceItemForReport } from '@this/domain/trip_report/allowance_item';
import { useTripReportStore } from '@this/domain/trip_report/trip_report_store';
import { getColor, getRadius, getSpacing, getTypogragy } from '@this/shared/ui/theme';
import { Heading, Text as AITText } from '@this/shared/ui/data_displays/typography';

type Props = {
  serviceId: number;
  trip: TripForReport;
  totalPrice: number;
  totalAdvance: number;
  totalAmount: number;
  nonOrderItems: NonOrderItemForReport[];
  allowanceItems: AllowanceItemForReport[];
  totalAllowance: number;
  categoryOptions: OrderItemCategoryOptions;
};

const TripCard: React.FC<Props> = ({
  serviceId,
  trip,
  totalPrice,
  totalAdvance,
  totalAmount,
  nonOrderItems,
  allowanceItems,
  totalAllowance,
  categoryOptions
}) => {
  const store = useTripReportStore();
  const allowanceAvailable = store.allowanceAvailable;
  return (
    <Wrapper>
      <Heading level="3">旅程#{trip.id}</Heading>
      <Body>
        <LeftWrap>
          <WrapRow>
            <WrapLabel>報告番号</WrapLabel>
            <Text>{trip.id}</Text>
          </WrapRow>
          {trip.approvalChannelId && (
            <WrapRow>
              <WrapLabel>申請番号</WrapLabel>
              <Text>
                <NavLink href={`/application/approved/${trip.approvalChannelId}`} target="_blank">
                  {trip.approvalChannelId}
                </NavLink>
              </Text>
            </WrapRow>
          )}
          <WrapRow>
            <WrapLabel>出張者(代表者)</WrapLabel>
            <Text>{trip.travelers[0] || '─'}</Text>
          </WrapRow>
          <WrapRow>
            <WrapLabel>出張日程</WrapLabel>
            <Text>{trip.getPeriod()}</Text>
          </WrapRow>
          <WrapRow>
            <WrapLabel>出発地・目的地</WrapLabel>
            <Text>
              {trip.from} ∼ {trip.to}
            </Text>
          </WrapRow>
          {trip.finalDestination && (
            <WrapRow>
              <WrapLabel>出張先</WrapLabel>
              <Text>{trip.finalDestination}</Text>
            </WrapRow>
          )}
          {trip.purpose && (
            <WrapRow>
              <WrapLabel>出張の目的</WrapLabel>
              <Text>{trip.purpose}</Text>
            </WrapRow>
          )}
          <Divider />
          <BetweenRow>
            <GrayLabel>社内管理番号</GrayLabel>
            <Text>{trip.internalNumber || '─'}</Text>
          </BetweenRow>
          {trip.getProjects().map(project => (
            <BetweenRow key={`project-${project.id}`}>
              <GrayLabel>プロジェクトコード</GrayLabel>
              <Text>{project.code || project.name}</Text>
            </BetweenRow>
          ))}
        </LeftWrap>
        <RightWrap>
          <TotalSection>
            <label>合計金額</label>
            <Text bold>{totalAmount > 0 ? `¥${totalAmount.toLocaleString()}` : '─'}</Text>
          </TotalSection>
          <label>【申請内容】</label>
          {trip.orderItemPriceDetails.length > 0 && (
            <PriceSection>
              <PriceLabel>システム手配金額</PriceLabel>
              {trip.orderItemPriceDetails.map((priceDetail, i) => (
                <PriceRow key={`orderItemPriceDetail-${i}`}>
                  <Text>
                    {priceDetail.getCategoryName(categoryOptions, '　')}
                    {priceDetail.shortName}
                  </Text>
                  <Text>{priceDetail.price.toLocaleString()}円</Text>
                </PriceRow>
              ))}
              <Divider />
              <BetweenRow>
                <label>合計</label>
                <Text bold>{totalPrice.toLocaleString()}円</Text>
              </BetweenRow>
            </PriceSection>
          )}
          {nonOrderItems.length > 0 && (
            <PriceSection>
              <PriceLabel>{serviceId !== 2 ? '社員立替金額' : '自己手配経費'}</PriceLabel>
              {nonOrderItems.map((item, i) => (
                <PriceRow key={`nonOrderItem-${i}`}>
                  <Text>{item.expensesType?.name || item.payee}</Text>
                  <Text>{item.getPrice()}</Text>
                </PriceRow>
              ))}
              <Divider />
              <BetweenRow>
                <label>合計</label>
                <Text bold>{totalAdvance.toLocaleString()}円</Text>
              </BetweenRow>
            </PriceSection>
          )}
          {allowanceAvailable && allowanceItems.length > 0 && (
            <PriceSection>
              <PriceLabel>日当</PriceLabel>
              {allowanceItems.map((item, i) => (
                <PriceRow key={`allowanceItem-${i}`}>
                  <Text>
                    {item.getTime()}
                    <TextSpan>{item.getAllowanceTypeName()}</TextSpan>
                  </Text>
                  <Text>{item.getPrice()}</Text>
                </PriceRow>
              ))}
              <Divider />
              <BetweenRow>
                <label>合計</label>
                <Text bold>{totalAllowance.toLocaleString()}円</Text>
              </BetweenRow>
            </PriceSection>
          )}
        </RightWrap>
      </Body>
    </Wrapper>
  );
};

const verticalLines = css`
  display: flex;
  flex-flow: column;
  gap: ${getSpacing(2)}px;
`;

const horizontalLines = css`
  display: flex;
  flex-flow: row;
  gap: ${getSpacing(2)}px;
`;

export const Wrapper = styled.div`
  ${verticalLines};
  font-size: ${getTypogragy('fontSizes', 'body')}px;
  padding: ${getSpacing(5)}px;
  max-width: 800px;
  background-color: ${getColor('background', 'primary')};

  ${media.sp`
    margin-left: ${getSpacing(-5)}px;
    margin-right: ${getSpacing(-5)}px;
  `}
`;

export const Body = styled.div`
  ${horizontalLines};

  ${media.sp`
    ${verticalLines};
  `};
`;

export const LeftWrap = styled.div`
  ${verticalLines};
  padding: ${getSpacing(4)}px;
  width: 100%;
  max-width: 320px;

  ${media.sp`
    padding: ${getSpacing(4)}px 0;
    max-width: initial;
  `}
`;

export const RightWrap = styled.div`
  ${verticalLines};
  flex: 1;
  padding: ${getSpacing(4)}px;
  background-color: ${getColor('common', 'white')};

  ${media.sp`
    margin-left: ${getSpacing(-2)}px;
    margin-right:${getSpacing(-2)}px;
  `}
`;

export const WrapRow = styled.div`
  ${horizontalLines};
  align-items: baseline;
`;

export const BetweenRow = styled.div`
  ${horizontalLines};
  justify-content: space-between;
`;

export const WrapLabel = styled.label`
  width: 100px;
  min-width: 100px;
`;

export const GrayLabel = styled.label`
  color: ${getColor('text', 'description')};
`;

export const Text = styled(AITText)`
  max-width: 80%;
`;

export const TextSpan = styled.span`
  background-color: 'pink';
  padding-left: ${getSpacing(5)}px;
`;

export const TotalSection = styled.div`
  ${horizontalLines};
  align-items: baseline;
  justify-content: space-between;
  padding: ${getSpacing(4)}px;
  margin-bottom: ${getSpacing(2)}px;
  border-radius: ${getRadius(60)}px;
  background-color: ${getColor('background', 'primary')};
`;

export const PriceSection = styled.div`
  ${verticalLines};
  box-sizing: border-box;
  gap: ${getSpacing(3)}px;
  padding: ${getSpacing(3)}px ${getSpacing(4)}px;
  border: solid 1px ${getColor('border', 'divider')};
`;

export const PriceLabel = styled.label`
  margin-bottom: ${getSpacing(1)}px;
`;

export const PriceRow = styled(BetweenRow)`
  padding: 0 ${getSpacing(2)}px;
`;

export default TripCard;
