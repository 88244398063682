import React from 'react';

import type { AdminBulkTicktesResponse } from '@this/components/admin/bulk_tickets/types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import { BulkTicketsSection } from '@this/components/admin/bulk_tickets/bulk_tickets_section';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { useBoolean } from '@this/shared/hooks/use_boolean';

type Term = AdminBulkTicktesResponse['terms'][number];
export type TermSeed = Pick<Term, 'from' | 'to'>;

type Props = {
  terms: AdminBulkTicktesResponse['terms'];
  onCreate: (term: TermSeed) => Promise<void>;
  onUpdate: (id: number, term: TermSeed) => Promise<void>;
};

export const BulkTicketTerms = ({ terms, onCreate, onUpdate }: Props) => {
  const [value, { on, off }] = useBoolean();
  const [editting, setEditting] = React.useState<Term | undefined>(undefined);

  const close = React.useCallback(() => {
    setEditting(undefined);
    off();
  }, [off, setEditting]);

  const handleSubmit = React.useCallback(
    async (term: TermSeed) => {
      if (editting) {
        await onUpdate(editting.id, term);
      } else {
        await onCreate(term);
      }

      close();
    },
    [editting, onCreate, onUpdate]
  );

  return (
    <>
      <BulkTicketsSection
        title="利用不可期間"
        renderHeaderAction={
          <Button size="small" onClick={on}>
            作成
          </Button>
        }
        renderTable={
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell width="24px">{/** 編集ボタン用 */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {terms.map(term => (
                <TableRow key={term.id}>
                  <TableCell>{term.from}</TableCell>
                  <TableCell>{term.to}</TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setEditting(term);
                        on();
                      }}
                    >
                      編集
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      />

      <BulkTicketTermForm open={value} term={editting} onSubmit={handleSubmit} onCancel={close} />
    </>
  );
};

type FormProps = {
  open: boolean;
  term?: Term;
  onSubmit: (term: TermSeed) => Promise<void>;
  onCancel: () => void;
};
const BulkTicketTermForm = ({ open, term, onSubmit, onCancel }: FormProps) => {
  const [from, setFrom] = React.useState(term?.from ?? '');
  const [to, setTo] = React.useState(term?.to ?? '');
  const [submitting, setSubmitting] = React.useState(false);
  const formid = 'BulkTicketTermForm';

  React.useEffect(() => {
    setFrom(term?.from ?? '');
    setTo(term?.to ?? '');
  }, [term]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      await onSubmit({ from, to });
      setFrom('');
      setTo('');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} size="small" onClose={onCancel}>
      <ModalHeader>利用不可期間の{term ? '編集' : '作成'}</ModalHeader>
      <ModalBody>
        <form id={formid} onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel>From</InputLabel>
            <Input type="date" value={from} onChange={e => setFrom(e.target.value)} />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel>To</InputLabel>
            <Input type="date" value={to} onChange={e => setTo(e.target.value)} />
          </FormControl>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onCancel}>
          キャンセル
        </Button>
        <Button type="submit" form={formid} disabled={submitting}>
          保存
        </Button>
      </ModalFooter>
    </Modal>
  );
};
