import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import Context from '@this/components/core_app/core_app_context';
import InquiryThanks from './inquiry_thanks';

interface Props {
  user: any;
}

interface State {
  name: string;
  email: string;
  company: string;
  position: string;
  subject: string;
  description: string;
  tel: string;
  user: any;
  finish: boolean;
  loading: boolean;
  errors: string[];
  serviceContactEmail: string;
}

interface InquiryResponse {
  service: {
    name: string;
    contactEmail: string;
  };
}

class InquiryForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      company: '',
      position: '',
      subject: '',
      description: '',
      tel: '',
      user: null,
      finish: false,
      loading: false,
      errors: [],
      serviceContactEmail: ''
    };
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    // setState only first time
    if (!this.state.user) {
      this.setState({
        name: utils.dig(props.user, 'name') || '',
        email: utils.dig(props.user, 'email') || '',
        tel: utils.dig(props.user, 'tel') || '',
        company: utils.dig(props.user, 'organization', 'name') || '',
        user: props.user
      });
    }
  }

  handleChange(
    key: 'name' | 'email' | 'company' | 'position' | 'subject' | 'description' | 'tel',
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) {
    e.preventDefault();
    this.setState({ [key]: e.currentTarget.value } as any);
  }

  getPlaceholder() {
    const { subject } = this.state;
    switch (subject) {
      case '希望の宿泊施設・交通機関が表示されない':
        return `迅速なご案内、ご回答を差し上げるために、可能な範囲で構いませんので、以下情報の記載をお願いいたします。
・検索条件（出発地、目的地、日付）
・検索結果が表示された画面のURLリンク`;
      case '使用方法が分からない':
        return `迅速なご案内、ご回答を差し上げるために、可能な範囲で構いませんので、以下情報の記載をお願いいたします。
・具体的に行いたい操作方法`;
      case 'チャットのメッセージに対する返信':
        return `迅速なご案内、ご回答を差し上げるために、可能な範囲で構いませんので、以下情報の記載をお願いいたします。
・該当の旅程番号`;
      case 'キャンセル変更希望':
        return `迅速なご案内、ご回答を差し上げるために、可能な範囲で構いませんので、以下情報の記載をお願いいたします。
・該当の旅程番号`;
      case 'その他':
        return `迅速なご案内、ご回答を差し上げるために、可能な範囲で構いませんので、詳細の記載をお願いいたします。`;
      default:
        return '';
    }
  }

  async handleFormSubmit(e: React.FormEvent<HTMLFormElement>, useWebToCase: boolean) {
    if (!useWebToCase) {
      e.preventDefault();
    }
    this.setState({
      loading: true,
      errors: []
    });

    const url = '/inquiries';
    const params = {
      company: this.state.company,
      name: this.state.name,
      email: this.state.email,
      position: this.state.position,
      subject: this.state.subject,
      description: this.state.description,
      tel: this.state.tel
    };

    try {
      const result = await utils.jsonPromise<InquiryResponse>(url, params, 'POST');
      this.setState({
        loading: false,
        company: '',
        name: '',
        email: '',
        position: '',
        description: '',
        tel: '',
        finish: true,
        serviceContactEmail: result.service.contactEmail
      });
    } catch (error) {
      if (error.status === 400) {
        this.setState({
          loading: false,
          errors: utils.dig(error, 'responseJSON', 'errors')
        });
      } else {
        this.setState({
          loading: false,
          errors: ['通信エラーが発生しました。しばらく時間をおいて再度お試しください。']
        });
      }
    }
  }

  render() {
    try {
      const {
        company,
        position,
        name,
        email,
        tel,
        subject,
        description,
        loading,
        finish,
        errors,
        serviceContactEmail
      } = this.state;
      const urlBase = `${location.protocol}//${location.hostname}${
        _.isEmpty(location.port) ? '' : `:${location.port}`
      }`;
      const webToCaseLink = 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';

      // SFで余計なケースが作さされないよう、AI Travelの本番のみ有効化する
      const useWebToCase = location.hostname === 'aitravel.jp';

      const action = location.hostname === 'aitravel.jp' ? webToCaseLink : '';
      return (
        <Context.Consumer>
          {context => (
            <Form
              className="inquiry-form"
              action={action}
              method="POST"
              onSubmit={e => this.handleFormSubmit(e, useWebToCase)}
            >
              <Title>ご意見・ご要望</Title>
              {!finish ? (
                <>
                  <Text>
                    <p>お客様にとって最高のサービスであるために、日々機能追加と改善に取り組んでいます。</p>
                    <p>ぜひサービスへのご意見をお聞かせください。</p>
                    <p>旅程関連の問合せは該当旅程のチャット、その他は問合せフォームをご利用ください。</p>
                    <p>該当旅程のチャットは以下手順にて確認が可能です。</p>
                    <a href="https://aitravel.jp/knowledges/000001204" target="_blank" rel="noreferrer noopener">
                      https://aitravel.jp/knowledges/000001204
                    </a>
                  </Text>
                  <div>
                    <input type="hidden" name="orgid" value="00D7F000002CrgE" />
                    <input type="hidden" name="retURL" value={`${urlBase}/inquiries/thanks`} />
                    <Field>
                      <Label htmlFor="company">会社名</Label>
                      <InputArea>
                        <Input
                          type="text"
                          name="company"
                          value={company}
                          onChange={event => this.handleChange('company', event)}
                          placeholder="例) 株式会社◯◯商事"
                        />
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="position">部署・役職</Label>
                      <InputArea>
                        <Input
                          type="text"
                          name="00N7F00000SAUMi"
                          value={position}
                          onChange={event => this.handleChange('position', event)}
                          placeholder="例) 総務部 部長"
                        />
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="name">氏名</Label>
                      <InputArea>
                        <Input
                          data-wovn-ignore
                          type="text"
                          name="name"
                          value={name}
                          onChange={event => this.handleChange('name', event)}
                          placeholder="鈴木一郎"
                        />
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="email">メールアドレス</Label>
                      <InputArea>
                        <Input
                          data-wovn-ignore
                          type="text"
                          name="email"
                          value={email}
                          onChange={event => this.handleChange('email', event)}
                          placeholder="例) ichiro.suzuki@example.com"
                        />
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="tel">電話番号</Label>
                      <InputArea>
                        <Input
                          data-wovn-ignore
                          type="text"
                          name="phone"
                          value={tel}
                          onChange={event => this.handleChange('tel', event)}
                          placeholder="例）090-1234-5678"
                        />
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="subject">問い合わせ種別</Label>
                      <InputArea>
                        <Select
                          name="subject"
                          value={subject}
                          onChange={event => this.handleChange('subject', event)}
                          required
                        >
                          <option value="">選択してください</option>
                          <option value="希望の宿泊施設・交通機関が表示されない">
                            希望の宿泊施設・交通機関が表示されない
                          </option>
                          <option value="使用方法が分からない">使用方法が分からない</option>
                          <option value="チャットのメッセージに対する返信">
                            チャットのメッセージに対する返信
                          </option>
                          <option value="キャンセル変更希望">キャンセル変更希望</option>
                          <option value="その他">その他</option>
                        </Select>
                      </InputArea>
                    </Field>
                    <Field>
                      <Label htmlFor="description">内容</Label>
                      <InputArea>
                        <textarea
                          name="description"
                          value={description}
                          onChange={event => this.handleChange('description', event)}
                          placeholder={this.getPlaceholder()}
                          rows={10}
                        />
                      </InputArea>
                    </Field>
                    <div>
                      <Submit type="submit" value="送信" disabled={loading} />
                    </div>
                    {errors && (
                      <Errors>
                        <ul>
                          {errors.map((e, i) => (
                            <li key={i}>{e}</li>
                          ))}
                        </ul>
                      </Errors>
                    )}
                  </div>
                </>
              ) : (
                <InquiryThanks contactEmail={serviceContactEmail} />
              )}
            </Form>
          )}
        </Context.Consumer>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Form = styled.form`
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
`;

const Text = styled.div`
  margin-bottom: 20px;
`;

const Field = styled.div`
  background: white;
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  padding: 10px;
`;

const Label = styled.label`
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  font-size: 12px;
`;

const InputArea = styled.div`
  input,
  textarea {
    border: 0;
    box-shadow: none;
    margin-bottom: 0;
    padding: 2px 0 3px;
  }
`;

const Input = styled.input`
  &&& {
    &:focus {
      box-shadow: none;
    }
  }
`;

const Select = styled.select`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%);
  box-sizing: border-box;
  font-size: 1em;
  padding: 0.5em;
  margin-bottom: 0;
  transition: border-color;
  width: 100%;
  height: auto;
`;

const Submit = styled.input`
  &&& {
    display: block;
    background: white;
    border: 2px solid ${props => props.theme.linkColor};
    color: ${props => props.theme.linkColor};
    font-size: 20px;
    padding: 18px;
    border-radius: 30px;
    margin: 20px auto 0;
    width: 200px;

    &:hover,
    &:focus {
      background-color: ${props => props.theme.linkColor};
    }
  }
`;

const Errors = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.redColor};
`;

export default InquiryForm;
