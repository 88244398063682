import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/components/shared/modal/modal';

interface Props {
  cancelReason: string;
  cancelSubmitting: boolean;
  showCancelApproveModal: boolean;
  handleSubmitCancel: (e: React.MouseEvent<HTMLElement>) => void;
  handleCancelCancel: () => void;
  handleCancelReasonChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  cancelError: string | null;
}

const TripModalTemplate: React.SFC<Props> = ({
  cancelReason,
  cancelSubmitting,
  showCancelApproveModal,
  handleSubmitCancel,
  handleCancelCancel,
  handleCancelReasonChange,
  cancelError
}) => (
  <Modal
    hideModal={handleCancelCancel}
    show={showCancelApproveModal}
    title="承認依頼の取消"
    className="trip-modal"
  >
    <Message>この手配依頼の申請を取消します。</Message>
    <Hint>取消理由を記入してください（必須）</Hint>
    <ReasonInput name="cancel_reason" value={cancelReason} onChange={e => handleCancelReasonChange(e)} />
    <Remark>
      承認を取消後、承認者にメールが通知されます。
      <br />
      また取消した案件は旅程一覧の過去の旅程から確認できます。
    </Remark>
    {cancelSubmitting ? (
      <SimpleLoading />
    ) : (
      <>
        {cancelError && <Error>{cancelError}</Error>}
        <Buttons>
          <Button disabled={!cancelReason.length} onClick={handleSubmitCancel}>
            はい
          </Button>
          <BackButton onClick={handleCancelCancel}>戻る</BackButton>
        </Buttons>
      </>
    )}
  </Modal>
);

const Message = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Hint = styled.p`
  margin-left: 30px;
  margin-bottom: 10px;
`;

const ReasonInput = styled.textarea`
  width: 250px;
  height: 80px;
  margin-left: 30px;
  padding: 10px;
`;

const Remark = styled.p`
  margin: 10px 20px 20px 25px;
  font-size: 12px;

  &:before {
    margin-left: -1em;
    content: '※';
  }
`;

const Error = styled.p`
  color: ${props => props.theme.redColor};
  margin: 20px;
`;

const Buttons = styled.div`
  text-align: center;
`;

const Button = styled.button`
  ${ButtonBase}
  display: inline-block;
`;

const BackButton = styled(Button)`
  margin-left: 20px;
`;

export default TripModalTemplate;
