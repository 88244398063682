import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type { ProjectArgs } from '@this/src/domain/project/project';
import Project from '@this/src/domain/project/project';
import type { TaxTypeArgs } from '@this/src/domain/tax_type';
import TaxType from '@this/src/domain/tax_type';
import { Fetcher, HTTPError } from '@this/src/util';
import type { ItemJson } from '../../../domain/expenses/item';
import { Item } from '../../../domain/expenses/item';
import ExpensesMain from '../main/main';
import type { ExpensesTypeJson } from '../../../domain/expenses/expenses_type';
import { ExpensesType } from '../../../domain/expenses/expenses_type';
import Form from './form';
import Detail from './detail';

interface ItemResponse {
  item: ItemJson;
  expenses_types: ExpensesTypeJson[];
  projects: ProjectArgs[];
  tax_types: TaxTypeArgs[];
  category_options: { [key: string]: string };
  travel_expense_type_options: { [key: string]: string };
  trip_type_options: { [key: string]: string };
  receipt_available: boolean;
  is_admin: boolean;
  use_project: boolean;
}

interface ItemPutResponse {
  item?: ItemJson;
  error?: string[];
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  item?: Item;
  expensesTypes?: ExpensesType[];
  projects?: Project[];
  taxTypes?: TaxType[];
  categoryOptions?: { [key: string]: string };
  tripTypeOptions?: { [key: string]: string };
  error?: string;
  putErrors?: string | string[];
  loading: boolean;
  submitting: boolean;
  receiptAvailable: boolean;
  isAdmin: boolean;
  useProject: boolean;
}

class Edit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      receiptAvailable: false,
      isAdmin: false,
      useProject: false
    };
  }

  componentDidMount() {
    this.fetchItem();
  }

  handleSubmit() {
    this.submitItem();
  }

  handleDelete() {
    this.deleteItem();
  }

  private async fetchItem() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ItemResponse>(`/biztra/report_items/${this.itemId()}/edit`);
      const types = response.expenses_types.map(type => {
        return new ExpensesType(type);
      });
      this.setState({
        item: new Item(response.item),
        expensesTypes: types,
        projects: response.projects.map(raw => new Project(raw)),
        taxTypes: response.tax_types.map(raw => new TaxType(raw)),
        categoryOptions: response.category_options,
        tripTypeOptions: response.trip_type_options,
        receiptAvailable: response.receipt_available,
        isAdmin: response.is_admin,
        useProject: response.use_project,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async submitItem() {
    const item = this.state.item;
    if (!item) {
      return;
    }
    this.setState({ submitting: true });
    try {
      await Fetcher.upload<ItemPutResponse>(`/biztra/report_items/${this.itemId()}`, item.formData, {
        method: 'PUT'
      });
      this.setState({ submitting: false }, () => {
        this.props.history.push('/biztra/report_items');
      });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
        this.setState({
          putErrors: e.response.data.error,
          submitting: false
        });
      } else {
        this.setState({
          putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
          submitting: false
        });
      }
    }
  }

  private async deleteItem() {
    this.setState({ submitting: true });
    try {
      await Fetcher.delete<ItemPutResponse>(`/biztra/report_items/${this.itemId()}`);
      this.setState({ submitting: false }, () => {
        this.props.history.push('/biztra/report_items');
      });
    } catch (e) {
      this.setState({
        putErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
        submitting: false
      });
    }
  }

  private itemFrom() {
    const {
      item,
      expensesTypes,
      projects,
      taxTypes,
      categoryOptions,
      tripTypeOptions,
      submitting,
      putErrors,
      receiptAvailable,
      isAdmin,
      useProject
    } = this.state;
    return item!.editable() ? (
      <Form
        item={item!}
        expensesTypes={expensesTypes!}
        projects={projects!}
        taxTypes={taxTypes!}
        categoryOptions={categoryOptions!}
        tripTypeOptions={tripTypeOptions!}
        onSubmit={() => this.handleSubmit()}
        submitting={submitting}
        errors={putErrors}
        edit
        onDelete={() => this.handleDelete()}
        backPath="/biztra/report_items"
        receiptAvailable={receiptAvailable}
        isAdmin={isAdmin}
        useProject={useProject}
      />
    ) : (
      <Detail item={item!} receiptAvailable={receiptAvailable} isAdmin={isAdmin} />
    );
  }

  private itemId = () => this.props.match.params.id;

  render() {
    const { item, loading, error } = this.state;

    return (
      <ExpensesMain title={item?.editable() ? '経費を編集' : '経費詳細'} backLinkPath="/biztra/report_items">
        {loading ? <SimpleLoading /> : error ? <p>{error}</p> : this.itemFrom()}
      </ExpensesMain>
    );
  }
}

export default Edit;
