import React from 'react';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { Input } from '@this/shared/ui/inputs/input';
import { ExpenseType, StatusAndId, Price } from '@this/components/expenses/report_items/report_item_cards';
import type { Report } from '../../../domain/expenses/report';
import StatusLabel from '../report_items/status_label';
import { ActionWrapper, BaseButton, CancelButton } from '../approvals/reject';

interface Props {
  report?: Report;
  onClickReport?: (report: Report) => void;
  handleCreateReport?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  submitReport?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  cancelReport?: () => void;
  submitting?: boolean;
  newCard?: boolean;
  confirm?: boolean;
  detail?: boolean;
  error?: boolean;
  isMobile?: boolean;
}

class ReportCard extends React.Component<Props> {
  private card() {
    const report = this.props.report!;
    const isRejected = report.isRejected();
    const { confirm, detail, error, submitReport, cancelReport, isMobile } = this.props;
    const className = `${confirm ? 'confirm' : detail ? 'detail' : ''}`;
    const clickableClass = this.props.onClickReport ? 'clickable' : '';
    const rejectClass = isRejected ? 'rejected' : '';
    if (confirm) {
      return (
        <CardWrapper
          className={className}
          onClick={() => {
            this.handleClickCard(report);
          }}
        >
          <div className="detail_card">
            <div>
              <div className={`expenses-report-card__title ${report.items.length === 0 ? 'no-item' : ''}`}>
                <TitleWrapper>
                  <Title className={className}>申請タイトル</Title>
                  <InputTitleWrapper>
                    <InputTitle type="text" onChange={e => report.setTitle(e.target.value)} value={report.title} />
                    {error && report.validationErrors().title && (
                      <div className="expenses-report-card__error">{report.validationErrors().title}</div>
                    )}
                  </InputTitleWrapper>
                </TitleWrapper>
              </div>
              <Description>{report.subTitle()}</Description>
              {report.items.length > 0 && (
                <Description className={className}>明細 {report.items.length}件</Description>
              )}
              <Description>申請日：{moment().format('YYYY/MM/DD')}</Description>
            </div>
            <ActionWrapper>
              <CancelButton onClick={cancelReport} disabled={this.props.submitting}>
                キャンセル
              </CancelButton>
              <BaseButton onClick={submitReport} disabled={this.props.submitting}>
                申請
              </BaseButton>
            </ActionWrapper>
          </div>
        </CardWrapper>
      );
    }
    if (detail) {
      return (
        <CardWrapper
          className={className}
          onClick={() => {
            this.handleClickCard(report);
          }}
        >
          <div className="detail_card">
            <div>
              <StatusLabelWrapper>
                <StatusLabel report={report} />
              </StatusLabelWrapper>
              {isRejected && <RejectReason>差戻理由：{report.rejectReason}</RejectReason>}
              <Id detail>{report.num()}</Id>
              <div className={`expenses-report-card__title ${report.items.length === 0 ? 'no-item' : ''}`}>
                <Title>{report.title}</Title>
              </div>
              <SubTitle>{report.subTitle()}</SubTitle>
              <AppliedAt>申請日：{report.appliedAtDesc()}</AppliedAt>
            </div>
            <div>
              <DetailPrice className={className}>{utils.formatPriceWithMark(report.totalPrice())}</DetailPrice>
            </div>
          </div>
          {report.items.length > 0 && <ItemsCount className={className}>明細 {report.items.length}件</ItemsCount>}
        </CardWrapper>
      );
    }
    if (isMobile) {
      return (
        <ReportCardSpWrapper
          className={clickableClass}
          onClick={() => {
            this.handleClickCard(report);
          }}
        >
          <StatusAndId>
            <StatusLabel report={report} />
            <Id isMobile>{report.num()}</Id>
          </StatusAndId>
          {!detail && isRejected && <RejectReason isMobile>差し戻し理由：{report.rejectReason}</RejectReason>}
          <ExpenseType>{report.title}</ExpenseType>
          <SubTitleAndDescription>
            <SubTitle>{report.subTitle()}</SubTitle>
            <SubTitle>明細{report.items.length}件</SubTitle>
          </SubTitleAndDescription>
          <PriceWrapper>
            <Price>{utils.formatPriceWithMark(report.totalPrice())}</Price>
          </PriceWrapper>
        </ReportCardSpWrapper>
      );
    }
    if (!detail && isRejected) {
      return (
        <ReportCardTbody
          className={clickableClass}
          onClick={() => {
            this.handleClickCard(report);
          }}
        >
          <ReportCardTr className={rejectClass}>
            <StatusLabelTd>
              <StatusLabel report={report} />
            </StatusLabelTd>
            <IdTd>{report.num()}</IdTd>
            <TitleTd>{report.title}</TitleTd>
            <SubTitleTd>{report.subTitle()}</SubTitleTd>
            <DescriptionTd>明細{report.items.length}件</DescriptionTd>
            <PriceTd>{utils.formatPriceWithMark(report.totalPrice())}</PriceTd>
          </ReportCardTr>
          <ReportCardTrReject>
            <RejectReasonTd colSpan={6}>差し戻し理由：{report.rejectReason}</RejectReasonTd>
          </ReportCardTrReject>
        </ReportCardTbody>
      );
    }
    return (
      <ReportCardTbody
        className={clickableClass}
        onClick={() => {
          this.handleClickCard(report);
        }}
      >
        <ReportCardTr>
          <StatusLabelTd>
            <StatusLabel report={report} />
          </StatusLabelTd>
          <IdTd>{report.num()}</IdTd>
          <TitleTd>{report.title}</TitleTd>
          <SubTitleTd>{report.subTitle()}</SubTitleTd>
          <DescriptionTd>明細{report.items.length}件</DescriptionTd>
          <PriceTd>{utils.formatPriceWithMark(report.totalPrice())}</PriceTd>
        </ReportCardTr>
      </ReportCardTbody>
    );
  }

  private newCard() {
    return (
      <NewCardWrapper className="expenses-report-card new">
        <ImgWrapper>
          <img src="/images/expenses/img_display.png" width="157" />
        </ImgWrapper>
        <CardIn>
          <div>
            <p>申請書を作成しましょう</p>
          </div>
          <BtnWrapper>
            <PrimaryButton onClick={e => this.props.handleCreateReport!(e)} disabled={this.props.submitting}>
              申請書を追加
            </PrimaryButton>
          </BtnWrapper>
        </CardIn>
      </NewCardWrapper>
    );
  }

  private handleClickCard(clickedReport: Report) {
    if (this.props.confirm || this.props.detail) return;
    this.props.onClickReport!(clickedReport);
  }

  render() {
    const { newCard } = this.props;
    return newCard ? this.newCard() : this.card();
  }
}

export default ReportCard;

const cardListBaseColor = '#f1ede5';

const blackTxtColor = '#323232';

const baseColor = '#927230';

const grayColor = '#767676';

const pinkColor = '#c72f62';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const PrimaryButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

export const NewCardWrapper = styled.div`
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: unset;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 32px 0;
    width: calc(100vw - 30px * 2);
  }
`;

export const ImgWrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const CardWrapper = styled.div`
  color: #000;
  font-size: 0.8em;
  margin-bottom: 20px;
  cursor: pointer;

  &.confirm {
    cursor: default;
  }

  &.detail {
    margin-bottom: 0;
    cursor: default;

    .detail_card {
      max-width: 520px;
      padding: 20px;
      cursor: default;
      border: 2px solid ${baseColor};
    }
  }
`;

const Id = styled.p<{ isMobile?: boolean; detail?: boolean }>`
  color: ${props => (props.detail ? blackTxtColor : grayColor)};
  font-size: ${props => (props.isMobile ? '1rem' : '12px')};
`;

const Title = styled.div`
  color: ${blackTxtColor};
  font-size: 20px;
  font-weight: bold;
  margin: 4px 0;
  word-wrap: break-word;

  &.confirm {
    margin-right: 30px;
    font-size: 16px;
  }
`;

const Description = styled.p`
  margin-top: 30px;
  color: ${blackTxtColor};
  font-size: 16px;
`;

const ItemsCount = styled.p`
  margin: 24px 0 -20px;
  color: ${blackTxtColor};
  font-size: 12px;
`;

const StatusLabelWrapper = styled.div`
  margin-bottom: 10px;
`;

const RejectReason = styled.p<{ isMobile?: boolean }>`
  margin-bottom: ${props => !props.isMobile && '10px'};
  margin-top: ${props => props.isMobile && '8px'};
  color: ${pinkColor};
  font-size: ${props => (props.isMobile ? '0.75rem' : '16px')};
  word-wrap: break-word;
`;

const DetailPrice = styled.p`
  color: ${baseColor};
  font-size: 1.75rem;
  font-weight: bold;
  text-align: right;
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const InputTitleWrapper = styled.div`
  width: 75%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const InputTitle = styled(Input)`
  padding: 5px;
  background: #faf9f8;
  .MuiOutlinedInput-notchedOutline {
    border-color: #f1ede5;
  }
  width: 100%;
`;

const ReportCardTbody = styled.tbody`
  &.clickable {
    cursor: pointer;
  }
`;

const ReportCardTr = styled.tr`
  &.clickable {
    cursor: pointer;
  }
  & td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px ${cardListBaseColor};
  }
  &.rejected td {
    border-bottom-width: 0;
  }
`;

const ReportCardTrReject = styled.tr`
  &.clickable {
    cursor: pointer;
  }
  & td {
    padding-top: 4px;
    padding-bottom: 16px;
    border-bottom: solid 1px ${cardListBaseColor};
  }
`;

const StatusLabelTd = styled.td`
  padding-left: calc(16 / 1136 * 100%);
  @media screen and (max-width: 767px) {
    padding-left: 10px;
  }
`;

const IdTd = styled.td`
  color: ${grayColor};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(38 / 1136 * 100%);
  @media screen and (max-width: 767px) {
    padding-left: calc(10 / 706 * 100%);
  }
`;

const TitleTd = styled.td`
  color: ${blackTxtColor};
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(30 / 1136 * 100%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding-left: calc(10 / 706 * 100%);
  }
`;

const SubTitleTd = styled.td`
  color: ${blackTxtColor};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(34.5 / 1136 * 100%);
  @media screen and (max-width: 767px) {
    padding-left: calc(10 / 706 * 100%);
  }
`;

const DescriptionTd = styled.td`
  color: ${blackTxtColor};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(34.5 / 1136 * 100%);
  @media screen and (max-width: 767px) {
    padding-left: calc(10 / 706 * 100%);
  }
`;

const PriceTd = styled.td`
  color: ${baseColor};
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  padding-left: calc(10 / 1136 * 100%);
  padding-right: calc(20 / 1136 * 100%);
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const RejectReasonTd = styled.td`
  color: ${pinkColor};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(16 / 1136 * 100%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding-left: calc(10 / 706 * 100%);
  }
`;

export const CardIn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  @media screen and (max-width: 767px) {
    margin-left: unset;
    justify-content: center;

    p {
      margin-bottom: 20px;
    }
  }
`;

export const BtnWrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin: auto;
  }
`;

export const ReportCardSpWrapper = styled.div`
  display: block;
  padding: 8px;
  &:not(:last-child) {
    border-bottom: solid 1px ${cardListBaseColor};
  }
`;

const SubTitleAndDescription = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SubTitle = styled.p`
  color: ${blackTxtColor};
  font-size: 1rem;
  text-align: left;
  margin-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AppliedAt = styled.p`
  color: ${grayColor};
  font-size: 0.75rem;
  text-align: left;
  margin-top: 4px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;
