import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle
} from '@this/components/organization/trips/detail/trip_detail.style';

interface Props {
  purpose?: string;

  onSave: (purpose: string) => void;
}

const PurposeForm: React.FC<Props> = ({ purpose, onSave }) => {
  const [editPurpose, setEditPurpose] = useState(purpose);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditPurpose(e.target.value || '');
  };

  return (
    <MetaSection>
      <MetaSectionTitle>出張の目的</MetaSectionTitle>

      <input
        type="text"
        placeholder="(任意)出張の目的や承認者への備考などを入力してください"
        value={editPurpose}
        onChange={onChange}
      />

      {purpose !== editPurpose && (
        <MetaSectionFormActions>
          <MetaSectionFormButton onClick={() => onSave(editPurpose || '')}>保存</MetaSectionFormButton>
          <MetaSectionFormButton onClick={() => setEditPurpose(purpose)}>キャンセル</MetaSectionFormButton>
        </MetaSectionFormActions>
      )}
    </MetaSection>
  );
};

export default PurposeForm;
