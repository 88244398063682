import { observable } from 'mobx';
import type { KnowledgeSubCategoryArgs, KnowledgeSubCategoryResponseArgs } from './knowledge_sub_category';
import type KnowledgeSubCategory from './knowledge_sub_category';
import { convertKnowledgeSubCategoryResponseToArgs } from './knowledge_sub_category';

export type KnowledgeCategoryResponseArgs = {
  id: number;
  sf_name: string;
  children?: KnowledgeSubCategoryResponseArgs[];
};

export type KnowledgeCategoryArgs = {
  id: number;
  sfName: string;
  children?: KnowledgeSubCategoryArgs[];
};

export const convertKnowledgeCategoryResponseToArgs = (
  response: KnowledgeCategoryResponseArgs
): KnowledgeCategoryArgs => {
  const args: KnowledgeCategoryArgs = {
    id: response.id,
    sfName: response.sf_name,
    children: response.children ? response.children.map(convertKnowledgeSubCategoryResponseToArgs) : []
  };
  return args;
};

export default class KnowledgeCategory {
  @observable id: number;

  @observable sfName: string;

  @observable children: KnowledgeSubCategory[];

  constructor(args: KnowledgeCategoryArgs) {
    this.id = args.id;
    this.sfName = args.sfName;
    this.children = args.children || [];
  }
}
