import React from 'react';
import { Link } from '@this/shared/ui/navigations/link';
import type { HowToUse } from '@this/components/reserve_trip/dashboard/dashboard';
import styled from 'styled-components';

type Props = {
  guides: HowToUse[];
  manuals: HowToUse[];
};

const HowToItemForAIT: React.FC<Props> = ({ guides, manuals }) => (
  <ItemWrapper>
    <ItemBlock>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          padding: '12px 24px',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <MovieIcon />
        ご利用はじめてガイド
      </div>
      <ul
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px'
        }}
      >
        {guides.map(g => (
          <Link
            key={g.salesforce_id}
            href={`/knowledges/${g.salesforce_id}`}
            target="_blank"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#3A3A3A',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '19.50px',
              wordWrap: 'break-word'
            }}
          >
            <ChevronRightIcon />
            {g.title}
          </Link>
        ))}
      </ul>
    </ItemBlock>

    <ItemBlock>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          padding: '12px 24px',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <ManualIcon />
        ご利用マニュアル
      </div>
      <ul
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px'
        }}
      >
        {manuals.map(k => (
          <Link
            key={k.salesforce_id}
            href={`/knowledges/${k.salesforce_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#3A3A3A',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '19.50px',
              wordWrap: 'break-word'
            }}
          >
            <ChevronRightIcon />
            {k.title}
          </Link>
        ))}
      </ul>
    </ItemBlock>
  </ItemWrapper>
);

const MovieIcon: React.FC = () => <img src="/images/help/movie_icon.svg" alt="movie" />;

const ManualIcon: React.FC = () => <img src="/images/help/manual_icon.svg" alt="manual" />;

const ChevronRightIcon: React.FC = () => (
  <img src="/images/help/chevron_right_icon.svg" alt="chevron right" style={{ marginRight: '4px' }} />
);

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const ItemBlock = styled.div`
  width: 50%;
  &:nth-child(1) {
    border-right: 1px #ececec solid;
  }

  @media screen and (max-width: 430px) {
    width: 100%;
    &:nth-child(1) {
      border-right: none;
    }
  }
`;

export default HowToItemForAIT;
