import React from 'react';
import { styled } from '@this/constants/themes';

import type { BulkActionAttr } from './types';

interface Props {
  formats: BulkActionAttr[];
}
export default class FileFormat extends React.Component<Props> {
  render() {
    const { formats } = this.props;
    return (
      <table className=".organization__csv-format-table">
        <thead>
          <tr>
            <th className="row-name">項目</th>
            <th className="summary">項目内容</th>
            <th className="example">サンプル</th>
          </tr>
        </thead>
        <tbody>
          {formats.map((f, i) => (
            <tr key={i}>
              <td className="row-name">
                {f.name}
                {f.required && <Red>(必須)</Red>}
              </td>
              <td className="summary">{f.summary}</td>
              <td className="example">
                <Pre>{f.example}</Pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

const Red = styled.span`
  color: ${props => props.theme.redColor};
`;

const Pre = styled.p`
  white-space: pre-wrap;
`;
