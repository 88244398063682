// order_item.coffeeで、snakeToCamelObject(args)した値が渡される為、CamelCaseにしている
export interface ChangeFeeOriginalMappingArgs {
  id: number | null;
  changeFeeElementId: number | null;
  changeFeeOrderItemId: number | null;
  changeFeeTraceId: number | null;
  originalOrderItemId: number | null;
  originalTraceId: number | null;
}

class ChangeFeeOriginalMapping {
  id: number | null = null;

  changeFeeElementId: number | null = null;

  changeFeeOrderItemId: number | null = null;

  changeFeeTraceId: number | null = null;

  originalOrderItemId: number | null = null;

  originalTraceId: number | null = null;

  constructor(args: ChangeFeeOriginalMappingArgs) {
    this.id = args.id;
    this.changeFeeElementId = args.changeFeeElementId;
    this.changeFeeOrderItemId = args.changeFeeOrderItemId;
    this.changeFeeTraceId = args.changeFeeTraceId;
    this.originalOrderItemId = args.originalOrderItemId;
    this.originalTraceId = args.originalTraceId;
  }

  setOriginalTraceId(traceId: number | null) {
    this.originalTraceId = traceId;
    app.render();
  }

  updateParams() {
    return {
      id: this.id,
      change_fee_element_id: this.changeFeeElementId,
      change_fee_order_item_id: this.changeFeeOrderItemId,
      change_fee_trace_id: this.changeFeeTraceId,
      original_order_item_id: this.originalOrderItemId,
      original_trace_id: this.originalTraceId
    };
  }
}

export default ChangeFeeOriginalMapping;
