import DownloadFormat from '@this/components/organization/csv_bulk_action/download_format';
import {
  BulkActionSection,
  BulkActionSectionContent,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import Link from '@this/shared/atoms/link';
import FileFormat from '@this/components//expenses/organization/file_format';
import * as React from 'react';
import CsvUploadForm from '@this/components/expenses/organization/csv_bulk_action/csv_upload_form';
import {
  OrganizationBody,
  OrganizationHeader,
  OrganizationHeaderTitle
} from '@this/components/expenses/organization/organization.style';
import type { BulkActionAttr } from '@this/components/organization/types';
import FileValidator from '@this/components/organization/csv_bulk_action/file_validator';
import { styled } from '@this/constants/themes';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { DownloadType, Encoding } from '@this/components/organization/csv_bulk_action/types';
import { BackLink } from '@this/components/expenses/main/main';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import { Fetcher, HTTPError } from '@this/src/util';
import { UPSERT_FORMAT, UPSERT_FORMAT_PRO } from '../expenses_account_type_file_formats';

interface CsvBulkExpensesAccountTypesResponse {
  receipt_available: boolean;
}

interface Props {}
interface State {
  loading: boolean;
  title: string;
  description: string;
  formats: BulkActionAttr[];
  validateResult?: FileValidatorResult;
  uploadResult?: { uploaded: boolean; errors?: string[] };
  error: string | null;
}

class CsvBulkUpsertExpensesAccountTypes extends React.Component<Props, State> {
  dataEnabled = true;

  pathPrefix = '/biztra/';

  historyPath = `${this.pathPrefix}organization/bulk_action_statuses?actionType=upsert_account_types`;

  backPath = `${this.pathPrefix}organization/expenses_account_types`;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      formats: UPSERT_FORMAT,
      title: '一括CSV登録・更新',
      description: [
        'CSVファイルをアップロードすることで、勘定科目を一括登録・更新することができます。',
        '既存の情報を編集したい場合はプルダウンメニューより「登録データ」を選択の上、ダウンロードして編集してください。',
        '※ 存在している勘定科目コードがCSVに含まれている場合、「勘定科目名」は上書きされます。'
      ].join('\n')
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchResponse();
  }

  fetchResponse() {
    Fetcher.get<CsvBulkExpensesAccountTypesResponse>(
      `${this.pathPrefix}organization/expenses_account_types/csv/bulk_upsert.json`
    ).then(
      result => {
        this.setState({
          loading: false,
          formats: result.receipt_available ? UPSERT_FORMAT_PRO : UPSERT_FORMAT
        });
      },
      () => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
          loading: false
        });
      }
    );
  }

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);

    try {
      await Fetcher.upload(`${this.pathPrefix}organization/expenses_account_types/csv/bulk_upsert`, formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };

  downloadFormat = (type: DownloadType) => {
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `${this.pathPrefix}/organization/expenses_account_types/csv/bulk_upsert/template_with_expenses_account_types?encoding=${encoding}`;
    } else {
      location.href = `${this.pathPrefix}/organization/expenses_account_types/csv/bulk_upsert/template?encoding=${type}`;
    }
  };

  render() {
    const { loading, formats, title, description, validateResult, uploadResult, error } = this.state;
    return (
      <>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
        </Header>
        <div>
          <BackLink to={this.backPath}>&lt; 一覧に戻る</BackLink>
        </div>
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <OrganizationBody>
            <p>
              {description.split('\n').map((text, i) => (
                <span key={i}>
                  {i !== 0 && <br />}
                  {text}
                </span>
              ))}
            </p>
            <DownloadFormat downloadFormat={this.downloadFormat} dataEnabled={this.dataEnabled} />
            <BulkActionSections>
              <BulkActionSection>
                <TheBulkActionSectionHeader>
                  <h3>アップロード</h3>
                  <Link to={this.historyPath}>アップロード履歴</Link>
                </TheBulkActionSectionHeader>
                <BulkActionSectionContent>
                  <CsvUploadForm
                    loading={loading}
                    historyPath={this.historyPath}
                    validateResult={validateResult}
                    uploadResult={uploadResult}
                    select={this.checkFile}
                    upload={this.upload}
                  />
                </BulkActionSectionContent>
              </BulkActionSection>
              <BulkActionSection>
                <TheBulkActionSectionHeader>
                  <h3>フォーマット</h3>
                </TheBulkActionSectionHeader>
                <BulkActionSectionContent>
                  <FileFormat formats={formats} expenses />
                </BulkActionSectionContent>
              </BulkActionSection>
            </BulkActionSections>
          </OrganizationBody>
        )}
      </>
    );
  }

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }
}

const Header = styled(OrganizationHeader)`
  padding: 0;
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: #927230;
  font-size: 24px;
  margin: 0 0 20px 0;
`;

const TheBulkActionSectionHeader = styled(BulkActionSectionHeader)`
  border: 0;
`;

export default CsvBulkUpsertExpensesAccountTypes;
