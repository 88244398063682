module.exports = class Expense
  @fromHotel: (hotel, date, hotelLimit) ->
    new @
      date: date
      type: 'ホテル代'
      price: hotel.price
      hotelLimit: hotelLimit
      abstract: hotel.name

  @fromTransit: (transit, date) ->
    new @
      date: date
      type:
        if transit.air
          '飛行機代'
        else if  transit.shinkansen
          '新幹線代'
      price: transit.totalPrice()
      abstract: transit.selected_text

  constructor: (args = {}) ->
    @id = args.id
    @date = moment(args.date) || moment()
    @type = args.type || ''
    @price = @_toInt(args.price)
    @abstract = args.abstract || ''
    @projectId = args.projectId
    @memo = args.memo
    @internalNumber = args.internalNumber || ''
    @paid = args.paid
    @selected = true

  setDate: (value) ->
    @date = value
    app.render()

  setType: (value) ->
    @type = value
    app.render()

  setPrice: (value) ->
    @price = parseInt value
    app.render()

  setProjectId: (value) ->
    @projectId = parseInt value
    app.render()

  setMemo: (value) ->
    @memo = value
    app.render()

  setAbstract: (value) ->
    @abstract = value
    app.render()

  setInternalNumber: (value) ->
    @internalNumber = value
    app.render()

  toggleSelected: ->
    @selected = !@selected
    app.render()

  markAsDestroy: ->
    @_destroy = 1

  destroyed: ->
    @_destroy == 1

  errors: ->
    errors = []
    unless @date.isValid()
      errors.push('経費の日付を正しく入力してください')
    unless @type && @type.length > 0
      errors.push('経費の種別を選択してください')
    errors

  toCreateParams: ->
    params =
      date: @date.format('YYYY-MM-DD')
      type: @type
      price: @price
      project_id: @projectId
      memo: @memo
      abstract: @abstract
      internal_number: @internalNumber
    params['id'] = @id if @id
    params['_destroy'] = @_destroy if @destroyed()
    params

  _toInt: (value, defaultValue = 0) ->
    parseInt(value) || defaultValue
