// 価格帯のラベル。
// priceRange: 0 => '○○○○○'
// priceRange: 1 => '○○○○￥'
// priceRange: 2 => '○○○￥￥'
// priceRange: 3 => '○○￥￥￥'
// priceRange: 4 => '○￥￥￥￥'
// priceRange: 5 => '￥￥￥￥￥'
import React from 'react';
import { styled } from '@this/constants/themes';
import { Yen } from '../shared/box_parts';

type Props = {
  priceRange: string;
};
const getPriceRange = (priceRange: string) => {
  switch (priceRange) {
    case '0':
      return (
        <>
          0<Yen>円</Yen>
        </>
      );
    case '1':
      return (
        <>
          〜2,000<Yen>円</Yen>
        </>
      );
    case '2':
      return (
        <>
          2,000〜4,000<Yen>円</Yen>
        </>
      );
    case '3':
      return (
        <>
          4,000〜6,000<Yen>円</Yen>
        </>
      );
    case '4':
      return (
        <>
          6,000〜8,000<Yen>円</Yen>
        </>
      );
    case '5':
      return (
        <>
          8,000<Yen>円</Yen>〜
        </>
      );
    default:
      return null;
  }
};
const SelectTransportBoxPriceRangeLabel: React.FC<Props> = ({ priceRange }) => (
  <RangeCircleButtons>
    料金の目安
    <br />＋ {getPriceRange(priceRange)}
  </RangeCircleButtons>
);

export const RangeCircleButtons = styled.div`
  margin-left: 4px;
  padding-bottom: 2px;
`;

export const RangeCircleOn = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f9cf68;
  text-align: center;
  line-height: 12px;
  margin-right: 3px;
`;

export const RangeCircleOnText = styled.div`
  color: white;
  font-size: 8px;
`;

export const RangeCircleOff = styled.div`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ccc;
  text-align: center;
  line-height: 12px;
  margin-right: 3px;
`;

export const RangeCircleOffText = styled.div`
  color: #ccc;
  font-size: 8px;
`;

export default SelectTransportBoxPriceRangeLabel;
