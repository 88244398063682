import type { ChangeEvent } from 'react';
import React from 'react';
import type { Moment } from 'moment';
import DatePickerForSearch from '../search_form/date_picker_for_search';
import {
  Wrapper,
  Header,
  SearchFormSection,
  Row1Column,
  Row3Column,
  Title,
  DatePickerForSearchWrapper,
  SearchFormInput,
  FromTo,
  Select,
  ActionWrapper,
  OutlineSmallButton,
  SecondarySmallButton
} from '../report_items/search_form';

interface Props {
  onSetSearchParams: (
    appliedAtFrom: string,
    appliedAtTo: string,
    priceFrom: string,
    priceTo: string,
    selectedApplierUserId?: number
  ) => void;
  onCancel: () => void;
  appliers: { userId: number; name: string }[];
  periodFrom: string;
  periodTo: string;
  selectedApplierUserId?: number;
  priceFrom: string;
  priceTo: string;
}

interface State {
  periodFrom: string;
  periodTo: string;
  selectedApplierUserId?: number;
  priceFrom: string;
  priceTo: string;
}

export default class SearchForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      periodFrom: props.periodFrom,
      periodTo: props.periodTo,
      selectedApplierUserId: props.selectedApplierUserId,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo
    };
  }

  getFormat = (): string => 'YYYY-MM-DD';

  handleChangePeriodFrom = (value: Moment) => {
    this.setState({ periodFrom: (value && value.format(this.getFormat())) || '' });
  };

  handleChangePeriodTo = (value: Moment) => {
    this.setState({ periodTo: (value && value.format(this.getFormat())) || '' });
  };

  handleChangeSelectedApplier = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedApplierUserId: Number(e.target.value) });
  };

  handleChangePriceFrom = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceFrom: e.target.value });
  };

  handleChangePriceTo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceTo: e.target.value });
  };

  setSearchParams = () => {
    this.props.onSetSearchParams(
      this.state.periodFrom,
      this.state.periodTo,
      this.state.priceFrom,
      this.state.priceTo,
      this.state.selectedApplierUserId
    );
  };

  render() {
    return (
      <Wrapper>
        <Header>検索条件を指定</Header>
        <SearchFormSection>
          <Title>期間</Title>
          <Row3Column>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.periodFrom} onChange={this.handleChangePeriodFrom} />
            </DatePickerForSearchWrapper>
            <FromTo>～</FromTo>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.periodTo} onChange={this.handleChangePeriodTo} />
            </DatePickerForSearchWrapper>
          </Row3Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>申請者</Title>
          <Row1Column>
            <Select value={this.state.selectedApplierUserId} onChange={this.handleChangeSelectedApplier}>
              <option value="" />
              {this.props.appliers.map(applier => (
                <option value={applier.userId} key={applier.userId}>
                  {applier.name}
                </option>
              ))}
            </Select>
          </Row1Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>金額</Title>
          <Row3Column>
            <SearchFormInput
              value={this.state.priceFrom}
              onChange={this.handleChangePriceFrom}
              size="small"
              type="number"
              inputProps={{
                placeholder: '100000',
                style: { fontSize: 14 }
              }}
            />
            <FromTo>～</FromTo>
            <SearchFormInput
              value={this.state.priceTo}
              onChange={this.handleChangePriceTo}
              size="small"
              type="number"
              inputProps={{
                placeholder: '999999999',
                style: { fontSize: 14 }
              }}
            />
          </Row3Column>
        </SearchFormSection>
        <ActionWrapper>
          <OutlineSmallButton onClick={this.props.onCancel}>キャンセル</OutlineSmallButton>
          <SecondarySmallButton onClick={this.setSearchParams}>検索</SecondarySmallButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}
