import { observable } from 'mobx';

export type ChargingDepartmentShareMappingArgs = {
  id: number;
  department_id: number;
  department?: any;
  charging_department_share_id: number;
  weight: number;
};

export default class ChargingDepartmentShareMapping {
  @observable
  id: number;

  @observable
  departmentId: number;

  @observable
  chargingDepartmentShareId: number;

  @observable
  weight: number;

  @observable
  department: any;

  constructor(args: ChargingDepartmentShareMappingArgs) {
    this.id = args.id;
    this.departmentId = args.department_id;
    this.department = args.department;
    this.chargingDepartmentShareId = args.charging_department_share_id;
    this.weight = args.weight;
  }

  setDepartmentId = (value: number) => {
    this.departmentId = value;
    app.render();
  };

  setWeight = (value: number) => {
    this.weight = value;
    app.render();
  };

  submitParams() {
    return {
      id: this.id,
      department_id: this.departmentId,
      charging_department_share_id: this.chargingDepartmentShareId,
      weight: this.weight
    };
  }
}
