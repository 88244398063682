/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { Item } from '@this/domain/expenses/item';
import ReceiptIconLink from '@this/components/expenses/receipt_icon_link';
import { Checkbox } from '@this/components/expenses/ui/inputs/checkbox';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import { BtnWrapper, CardIn, ImgWrapper, NewCardWrapper } from '@this/components/expenses/reports/report_card';
import { ByMedia } from '@this/shared/ui/other/by_media';
import StatusLabel from './status_label';

interface Props {
  items: Item[];
  handleAddItem: () => void;
  onClickItem?: (item: Item) => void;
  withCopyButton?: boolean;
  withCheckbox?: boolean;
  onClickCopyButton?: (item: Item) => void;
  inApprovals?: boolean;
  inRejected?: boolean;
  tripReportAvailable?: boolean;
  inDetail?: boolean;
}

class ReportItemCards extends React.Component<Props> {
  handleClickItem = (item: Item) => {
    if (this.props.inApprovals)
      window.open(`/biztra/approvals/${item.reportId}/approval_items/${item.id}/edit`, '_blank');
    if (this.props.onClickItem) this.props.onClickItem!(item);
  };

  handleClickCopyButton = (item: Item) => {
    if (this.props.onClickCopyButton) this.props.onClickCopyButton!(item);
  };

  handleStopPropagation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  private newItemCard() {
    return (
      <NewCardWrapper className="expenses-report-card new">
        <ImgWrapper>
          <img src="/images/expenses/img_display.png" width="157" />
        </ImgWrapper>
        <CardIn>
          <div>
            <p>経費を登録しましょう</p>
          </div>
          <BtnWrapper>
            <NewItemButton onClick={() => this.props.handleAddItem()}>経費を登録</NewItemButton>
          </BtnWrapper>
        </CardIn>
      </NewCardWrapper>
    );
  }

  private itemCardsSp(items: Item[]) {
    return items.map(item => this.itemCardSp(item));
  }

  private itemCardSp(item: Item) {
    const { withCheckbox, withCopyButton, tripReportAvailable, inApprovals } = this.props;
    if (inApprovals) {
      return (
        <ItemCardSpWrapper key={item.id} onClick={() => this.handleClickItem(item)} className="inApprovals">
          <StatusAndId>
            <Id>No.{item.id}</Id>
          </StatusAndId>
          <ExpenseType>{item.expensesType.nameText()}</ExpenseType>
          {tripReportAvailable && item.tripReport && (
            <TripReportWrapper>
              <NavLink
                size="small"
                href={`/trip_report/${item.tripReport.id}`}
                isExternal
                onClick={this.handleStopPropagation}
              >
                出張報告：{item.tripReport.name}
              </NavLink>
            </TripReportWrapper>
          )}
          <Payee>{item.payee}</Payee>
          <ReceiptIconAndPrice>
            <ReceiptIconLink item={item} />
            <Price>{utils.formatPriceWithMark(item.price)}</Price>
          </ReceiptIconAndPrice>
        </ItemCardSpWrapper>
      );
    }

    return (
      <ItemCardSpWrapper key={item.id} onClick={() => this.handleClickItem(item)}>
        <StatusAndId>
          {withCheckbox && this.props.onClickItem && (
            <CheckboxWrapper>
              <Checkbox
                checked={item.selected}
                onChange={() => this.props.onClickItem!(item)}
                onClick={() => this.handleClickItem(item)}
              />
            </CheckboxWrapper>
          )}
          <StatusLabel report={item.report} />
          <Id>No.{item.id}</Id>
        </StatusAndId>
        <ExpenseType>{item.expensesType.nameText()}</ExpenseType>
        {tripReportAvailable && item.tripReport && (
          <TripReportWrapper>
            <NavLink href={`/trip_report/${item.tripReport.id}`} isExternal onClick={this.handleStopPropagation}>
              {item.tripReport.name}
            </NavLink>
          </TripReportWrapper>
        )}
        <Payee>{item.payee}</Payee>
        <ReceiptIconAndPrice>
          <ReceiptIconLink item={item} />
          <Price>{utils.formatPriceWithMark(item.price)}</Price>
          {withCopyButton && (
            <SmallBaseButton onClick={() => this.handleClickCopyButton(item)}>複製</SmallBaseButton>
          )}
        </ReceiptIconAndPrice>
      </ItemCardSpWrapper>
    );
  }

  private itemCards(items: Item[]) {
    return items.map(item => this.itemCard(item));
  }

  private itemCard(item: Item) {
    const { withCheckbox, withCopyButton, onClickItem, inApprovals, inRejected, tripReportAvailable } = this.props;
    const clickableClass = onClickItem && !withCopyButton ? 'clickable' : '';

    if (inApprovals) {
      return (
        <tbody key={item.id}>
          <ItemCardTrInApprovals onClick={() => this.handleClickItem(item)} className="clickable">
            {inRejected || <CheckAreaTd />}
            <StatusLabelTd />
            <IdTd>No.{item.id}</IdTd>
            <ExpenseTypeTd>{item.expensesType.nameText()}</ExpenseTypeTd>
            <PayeeTd>
              {item.payee}
              {tripReportAvailable && item.tripReport && (
                <TripReportLabel>
                  <NavLink
                    size="small"
                    href={`/trip_report/${item.tripReport.id}`}
                    isExternal
                    onClick={this.handleStopPropagation}
                  >
                    関連する出張報告：{item.tripReport.name}
                  </NavLink>
                </TripReportLabel>
              )}
            </PayeeTd>
            <ReceiptIconLinkTd>
              <ReceiptIconLink item={item} />
            </ReceiptIconLinkTd>
            <PriceTd>{utils.formatPriceWithMark(item.price)}</PriceTd>
          </ItemCardTrInApprovals>
        </tbody>
      );
    }

    return (
      <ItemCardTr key={item.id} onClick={() => this.handleClickItem(item)} className={`${clickableClass}`}>
        {withCheckbox && this.props.onClickItem && (
          <CheckAreaTd>
            <Checkbox
              checked={item.selected}
              onChange={() => this.props.onClickItem!(item)}
              onClick={() => this.handleClickItem(item)}
            />
          </CheckAreaTd>
        )}
        <StatusLabelTd>
          <StatusLabel report={item.report} />
        </StatusLabelTd>
        <IdTd>No.{item.id}</IdTd>
        <ExpenseTypeTd>{item.expensesType.nameText()}</ExpenseTypeTd>
        <PayeeTd>
          {item.payee}
          {tripReportAvailable && item.tripReport && (
            <TripReportLabel>
              <NavLink
                size="small"
                href={`/trip_report/${item.tripReport.id}`}
                isExternal
                onClick={this.handleStopPropagation}
              >
                関連する出張報告：{item.tripReport.name}
              </NavLink>
            </TripReportLabel>
          )}
        </PayeeTd>
        <ReceiptIconLinkTd>
          <ReceiptIconLink item={item} />
        </ReceiptIconLinkTd>
        <PriceTd>{utils.formatPriceWithMark(item.price)}</PriceTd>
        {withCopyButton && (
          <CopyButtonTd>
            <SmallBaseButton onClick={() => this.handleClickCopyButton(item)}>複製</SmallBaseButton>
          </CopyButtonTd>
        )}
      </ItemCardTr>
    );
  }

  render() {
    const items = this.props.items;
    const { withCopyButton, withCheckbox, inApprovals, inRejected, inDetail } = this.props;
    if (items && items.length > 0) {
      const goi = _.groupBy(items, item => item.paidAt.format('YYYY年MM月DD日'));
      const dates = Object.keys(goi).sort().reverse();
      if (inApprovals) {
        return (
          <>
            {dates.map((date: string) => (
              <ByMedia key={date}>
                {matches =>
                  matches.sm ? (
                    <ItemCardList className="inApprovals">
                      <ItemCardDate className="inApprovals">{date}</ItemCardDate>
                      {this.itemCardsSp(goi[date])}
                    </ItemCardList>
                  ) : (
                    <tr>
                      <ItemCardListTableWrapTd colSpan={inRejected ? 6 : 7}>
                        <ItemCardListTable className="inApprovals">
                          <colgroup>
                            {inRejected || <ItemCardListColCheckArea />}
                            <ItemCardListColStatus />
                            <ItemCardListColId />
                            <col />
                            <ItemCardListColExpenseType />
                            <ItemCardListColReceiptIcon />
                            <ItemCardListColPrice />
                          </colgroup>
                          <thead>
                            <tr>
                              <ItemCardDateTh colSpan={inRejected ? 1 : 2}>
                                <br />
                              </ItemCardDateTh>
                              <ItemCardDateTh colSpan={5} className="inApprovals">
                                {date}
                              </ItemCardDateTh>
                            </tr>
                          </thead>
                          {this.itemCards(goi[date])}
                        </ItemCardListTable>
                      </ItemCardListTableWrapTd>
                    </tr>
                  )
                }
              </ByMedia>
            ))}
          </>
        );
      }

      return (
        <ItemCardListTableWrap withCheckbox={withCheckbox} inDetail={inDetail}>
          {dates.map((date: string) => (
            <ByMedia key={date}>
              {matches =>
                matches.sm ? (
                  <ItemCardList>
                    <ItemCardDate>{date}</ItemCardDate>
                    {this.itemCardsSp(goi[date])}
                  </ItemCardList>
                ) : (
                  <ItemCardListTable>
                    <colgroup>
                      {withCheckbox && <ItemCardListColCheckArea />}
                      <ItemCardListColStatus />
                      <ItemCardListColId />
                      <ItemCardListColExpenseType />
                      <col />
                      <ItemCardListColReceiptIcon />
                      <ItemCardListColPrice />
                      {withCopyButton && <ItemCardListColCopyButton />}
                    </colgroup>
                    <ItemCardListThead>
                      <tr>
                        <ItemCardDateTh colSpan={withCopyButton || withCheckbox ? 7 : 6}>{date}</ItemCardDateTh>
                      </tr>
                    </ItemCardListThead>
                    <tbody>{this.itemCards(goi[date])}</tbody>
                  </ItemCardListTable>
                )
              }
            </ByMedia>
          ))}
        </ItemCardListTableWrap>
      );
    }
    return withCopyButton || withCheckbox ? (
      <p>経費がありません</p>
    ) : inApprovals ? (
      <tr>
        <td />
        <td colSpan={6}>経費がありません</td>
      </tr>
    ) : (
      this.newItemCard()
    );
  }
}

const cardListBaseColor = '#f1ede5';

const blackTxtColor = '#323232';

const baseColor = '#927230';

const grayColor = '#767676';

const whiteColor = '#FFFFFF';

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const PrimaryButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const NewItemButton = styled(PrimaryButton)`
  padding: 0.875rem 3rem;
`;

const BaseButton = styled.button.attrs({ type: 'button' })`
  border-radius: 6px;
  font-size: 16px;
  color: ${baseColor};
  background-color: ${whiteColor};
  border: solid 1px ${baseColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  &:hover,
  &:focus {
    color: ${baseColor} !important;
    background-color: ${whiteColor} !important;
  }
`;

const SmallBaseButton = styled(BaseButton)`
  font-size: 14px;
`;

const ItemCardListTableWrap = styled.div<{ withCheckbox?: boolean; inDetail?: boolean }>`
  padding: 30px 0;
  @media screen and (max-width: 767px) {
    padding: ${props => (props.withCheckbox ? '24px 0' : props.inDetail ? '24px 0' : '24px 20px')};
    width: 100%;
  }
`;

const ItemCardListTableWrapTd = styled.td`
  border-bottom-width: 0;
`;

const ItemCardListTable = styled.table`
  margin: 0 !important;
  border: solid 1px ${cardListBaseColor};
  &.inApprovals {
    border: none;
  }
`;

const ItemCardListColCheckArea = styled.col`
  width: 3%;
  min-width: 27px;
`;

const ItemCardListColStatus = styled.col`
  width: 8%;
  min-width: 96px;
`;

const ItemCardListColId = styled.col`
  width: 10%;
  min-width: 100px;
`;

const ItemCardListColExpenseType = styled.col`
  width: 30%;
`;

const ItemCardListColReceiptIcon = styled.col`
  width: 2.65%;
  min-width: 20px;
`;

const ItemCardListColPrice = styled.col`
  width: 13%;
  min-width: 125px;
`;

const ItemCardListColCopyButton = styled.col`
  width: 8%;
  min-width: 75px;
`;

const ItemCardListThead = styled.thead`
  background-color: ${cardListBaseColor};
`;

const ItemCardDateTh = styled.th`
  color: ${blackTxtColor};
  font-size: 16px;
  padding: 6px;
  letter-spacing: normal;
  font-weight: normal;
  border-bottom: none;

  &.inApprovals {
    color: ${baseColor};
    padding: calc(6 / 609 * 100%) calc(6 / 609 * 100%) calc(6 / 609 * 100%) calc(16 / 609 * 100%);
  }
`;

const ItemCardTr = styled.tr`
  &.clickable {
    cursor: pointer;
  }
  & td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px ${cardListBaseColor};
  }
  &&:last-child td {
    border-bottom-width: 0;
  }
`;

const ItemCardTrInApprovals = styled(ItemCardTr)`
  & td {
    border-bottom-width: 0;
  }
`;

const CheckAreaTd = styled.td`
  padding-left: calc(16 / 1136 * 100%);
  align-items: center;
`;

const StatusLabelTd = styled.td`
  padding-left: calc(16 / 1136 * 100%);
`;

const IdTd = styled.td`
  color: ${grayColor};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(30 / 1136 * 100%);
`;

const ExpenseTypeTd = styled.td`
  color: ${blackTxtColor};
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(30 / 1136 * 100%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const PayeeTd = styled.td`
  color: ${grayColor};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: calc(34.5 / 1136 * 100%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ReceiptIconLinkTd = styled.td`
  padding-left: calc(10 / 1136 * 100%);
`;

const PriceTd = styled.td`
  color: ${baseColor};
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  padding-left: calc(10 / 1136 * 100%);
  padding-right: calc(20 / 1136 * 100%);
`;

const CopyButtonTd = styled.td`
  padding-right: calc(30 / 1136 * 100%);
`;

export const ItemCardList = styled.div`
  border: solid 1px ${cardListBaseColor};
  background-color: ${whiteColor};
  &.inApprovals {
    border: none;
  }
`;

export const ItemCardDate = styled.p`
  background-color: ${cardListBaseColor};
  color: ${blackTxtColor};
  font-size: 1rem;
  padding: 6px;
  letter-spacing: normal;
  font-weight: normal;
  border-bottom: none;
  &.inApprovals {
    background-color: transparent;
    color: ${baseColor};
  }
`;

const ItemCardSpWrapper = styled.div`
  display: block;
  padding: 14px 10px;
  &:not(:last-child) {
    border-bottom: solid 1px ${cardListBaseColor};
  }
  &.inApprovals {
    border: none;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-left: calc(2 / 706 * 100%);
  .MuiFormControlLabel-root {
    margin-left: unset;
    margin-right: unset;
  }
`;

export const StatusAndId = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
  & .inApprovalsSp.MuiFormControlLabel-root {
    margin-right: -9px;
    margin-left: -9px;
  }
`;

const Id = styled.p`
  color: ${grayColor};
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

export const ExpenseType = styled.p`
  color: ${blackTxtColor};
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Payee = styled.p`
  color: ${grayColor};
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ReceiptIconAndPrice = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
`;

export const Price = styled.span`
  color: ${baseColor};
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
`;

const TripReportLabel = styled.div``;

const TripReportWrapper = styled.div`
  margin-left: calc(18 / 362.5 * 100%);
  margin-right: calc(8 / 362.5 * 100%);

  a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default ReportItemCards;
