import React from 'react';
import { styled } from '@this/constants/themes';
import type { ModalProps } from '@this/shared/ui/feedbacks/modal';
import { Modal, ModalHeader, ModalBody } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { Input } from '@this/shared/ui/inputs/input';

type Props = {
  submitting?: boolean;
  onSubmit: () => void;
  onAbort: () => void;
  currentPassword: string;
  onChangeCurrentPassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  onChangePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  passwordConfirmation: string;
  onChangePasswordConfirmation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string[];
} & Pick<ModalProps, 'open'>;

export const EditRegistrationForm = ({
  open,
  submitting,
  onSubmit,
  onAbort,
  currentPassword,
  onChangeCurrentPassword,
  password,
  onChangePassword,
  passwordConfirmation,
  onChangePasswordConfirmation,
  errors
}: Props) => (
  <Modal open={open} onClose={onAbort} size="xlarge">
    <ModalHeader>
      <Title>パスワードの変更</Title>
    </ModalHeader>
    <ModalBody>
      <Section>
        <Label className="user-edit__pass-confirm" htmlFor="currentPassword">
          <span>現在のパスワード</span>
          <span className="red">（必須）</span>
        </Label>
        <Input
          type="password"
          id="currentPassword"
          placeholder="英(大小)数記号を含む8文字以上"
          value={currentPassword}
          onChange={onChangeCurrentPassword}
          fullWidth
        />
      </Section>
      <Section>
        <Label className="user-edit__pass-confirm" htmlFor="password">
          新しいパスワード
        </Label>
        <Input
          type="password"
          id="password"
          placeholder="英(大小)数記号を含む8文字以上"
          value={password}
          onChange={onChangePassword}
          fullWidth
        />
      </Section>
      <Section>
        <Label className="user-edit__pass-confirm" htmlFor="passwordConfirmation">
          新しいパスワード　（確認）
        </Label>
        <Input
          type="password"
          id="passwordConfirmation"
          placeholder="英(大小)数記号を含む8文字以上"
          value={passwordConfirmation}
          onChange={onChangePasswordConfirmation}
          fullWidth
        />
      </Section>
      <div className="user-edit__modal-errors">
        {errors.map((error, i) => (
          <div className="error" key={i}>
            {error.split('\n').map((l, j) => (
              <p key={j}>{l}</p>
            ))}
          </div>
        ))}
      </div>
    </ModalBody>
    <ButtonWrap>
      <div className="user-edit__modal-submit">
        <Button onClick={onSubmit} disabled={submitting} fullWidth>
          保存
        </Button>
      </div>
      <Button color="sub" onClick={onAbort} className="user-edit__modal-cancel" fullWidth>
        キャンセル
      </Button>
    </ButtonWrap>
  </Modal>
);

const Section = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.linkColor};
  padding: 20px;
  align-items: center;
  & select {
    margin: 0 5px 0 0;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const Label = styled.label`
  min-width: 220px;
  color: ${props => props.theme.grayTextColor};
  font-weight: bold;
  margin: 8px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

const ButtonWrap = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 48px;
  height: 48px;
  text-align: center;
  margin: 0;
`;
