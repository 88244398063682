import React from 'react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type OrganizationJson from '../../../domain/organization/organization_json';
import Organization from '../../../domain/organization/organization';

interface InvitationResponse {
  organization: OrganizationJson;
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  organization?: Organization;
  error?: string;
  loading: boolean;
  succeeded: boolean;
}

class OrganizationInvitation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      succeeded: false
    };
  }

  componentDidMount() {
    this.fetchInvitation();
  }

  handleClickAcceptInvitation = () => {
    this.acceptInvitation();
  };

  private async fetchInvitation() {
    try {
      this.setState({ loading: true });
      const response = await utils.jsonPromise<InvitationResponse>(
        `/biztra/organization_invitations/${this.props.match.params.id}.json`
      );
      this.setState({
        organization: new Organization(response.organization),
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async acceptInvitation() {
    this.setState({ loading: true });
    try {
      await utils.jsonPromise(
        `/biztra/organization_invitations/${this.props.match.params.id}/approve.json`,
        {},
        'PUT'
      );
      this.setState(
        {
          loading: false,
          succeeded: true
        },
        () => {
          setTimeout(() => {
            location.href = '/biztra/reports';
          }, 1000);
        }
      );
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  render() {
    try {
      const { loading, error, organization, succeeded } = this.state;
      const classBase = 'expenses-organization-invitation';
      return (
        <div className={`${classBase}`}>
          <div className={`${classBase}__content`}>
            {loading ? (
              <SimpleLoading />
            ) : error ? (
              <p>{error}</p>
            ) : succeeded ? (
              <>
                <p>所属組織を変更しました。</p>
                <p>トップページへジャンプします...</p>
              </>
            ) : (
              <>
                <p>{`${organization!.name}から招待されました。`}</p>
                <p>{`この招待を承認すると所属組織が${organization!.name}に変更されます。`}</p>
                <button type="button" onClick={this.handleClickAcceptInvitation}>
                  承認する
                </button>
              </>
            )}
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default OrganizationInvitation;
