export interface OrganizationBillReceiverArgs {
  id?: number;
  name?: string;
  email?: string;
  organization_id: number;
}

/**
 * @deprecated
 * should use button in js/src/domain/organization_bill_receiver2
 */
class OrganizationBillReceiver {
  public id: number | null;

  public name: string;

  public email: string;

  public organizationId: number;

  constructor(args: OrganizationBillReceiverArgs) {
    this.id = args.id || null;
    this.name = args.name || '';
    this.email = args.email || '';
    this.organizationId = args.organization_id;
  }

  setName(value: string) {
    this.name = value;
    app.render();
  }

  setEmail(value: string) {
    this.email = value;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      email: this.email
    };
  }
}

export default OrganizationBillReceiver;
