import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type { InAdvanceMessageTemplate } from '@this/src/domain/in_advance/in_advance_message_template';

type Props = {
  messageTemplates: InAdvanceMessageTemplate[];
  onClickEdit: (type: InAdvanceMessageTemplate) => void;
  onClickDelete: (type: InAdvanceMessageTemplate) => void;
};

const Cards: React.FC<Props> = ({ messageTemplates, onClickEdit, onClickDelete }) => {
  const messageTemplateCard = (messageTemplate: InAdvanceMessageTemplate) => (
    <Tr key={messageTemplate.id} data-e2e-id={messageTemplate.id}>
      <PreWrap className="organizationTdClass e2e-message">{messageTemplate.message}</PreWrap>
      <td className="organizationTdClass e2e-created-at">
        {messageTemplate.createdAt.format('YYYY/MM/DD HH:mm')}
      </td>
      <td className="organizationTdClass e2e-updated-at">
        {messageTemplate.updatedAt.format('YYYY/MM/DD HH:mm')}
      </td>
      <td className="organizationTdClass">
        <input
          className="organization__button"
          type="submit"
          value="編集"
          onClick={() => onClickEdit(messageTemplate)}
        />
      </td>
      <td className="organizationTdClass">
        <input
          className="organization__delete-button"
          type="submit"
          value="削除"
          onClick={() => onClickDelete(messageTemplate)}
        />
      </td>
    </Tr>
  );

  return (
    <table className="organization__table">
      <thead>
        <tr>
          <th className="organization__th"> 選択時メッセージ </th>
          <th className="organization__th"> 作成日時 </th>
          <th className="organization__th"> 更新日時 </th>
          <th className="organization__button-th"> 編集 </th>
          <th className="organization__button-th"> 削除 </th>
        </tr>
      </thead>
      <tbody>{messageTemplates.map(messageTemplate => messageTemplateCard(messageTemplate))}</tbody>
    </table>
  );
};

const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

const PreWrap = styled.td`
  white-space: pre-wrap;
`;

export default Cards;
