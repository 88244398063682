import moment from 'moment-timezone';
import { Budget } from './budget';
import type { BudgetArgs } from './budget';

export type BudgetType = 'project';

export class BudgetStore {
  budgets: Budget[] = [];

  removeBudgets: Budget[] = [];

  loading: boolean;

  errors: string[] = [];

  constructor({ loading }: { loading?: boolean } = {}) {
    this.loading = loading ?? false;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    app.render();
  }

  addBudget() {
    const prevBudget = this.budgets[0];
    const fromTime = prevBudget ? prevBudget.toTime.clone().add(1, 'month') : moment();
    const toTime = prevBudget
      ? fromTime.clone().add(prevBudget.toTime.diff(prevBudget.fromTime, 'months'), 'months')
      : moment();
    const budget = new Budget({
      from_time: fromTime.toString(),
      to_time: toTime.toString(),
      total_budget: 0,
      editing: true
    });

    this.budgets.splice(0, 0, budget);
    app.render();
  }

  removeBudget(index: number) {
    const budget = this.budgets[index];
    if (!budget) return;

    if (budget.id) {
      budget.setField('removed', true);
      this.removeBudgets.push(budget);
    }

    this.budgets.splice(index, 1);
    app.render();
  }

  validPeriods() {
    const budgetPeriods = this.budgets.map(budget => {
      const months = [];
      const time = budget.fromTime.clone();
      while (time <= budget.toTime) {
        months.push(time.format('YYMM'));
        time.add(1, 'month');
      }

      return months;
    });

    const periods = budgetPeriods.flat();
    const dupPeriods = periods.flatMap(month =>
      periods.indexOf(month) !== periods.lastIndexOf(month) ? [month] : []
    );

    return Object.fromEntries(
      budgetPeriods.map((months, i) => [
        i,
        months.length < 1
          ? '終了月は開始月以降を指定してください'
          : months.some(month => dupPeriods.includes(month))
          ? '期間が重複しています'
          : null
      ])
    );
  }

  fetchBudgets(id: number | undefined, budgetType: BudgetType) {
    if (!id) {
      this.budgets = [];
      app.render();
      return;
    }

    this.setLoading(true);
    utils
      .jsonPromise<BudgetArgs[]>(`/organization/budgets/${id}?budget_type=${budgetType}`)
      .then(result => {
        this.budgets = result.map(raw => new Budget(raw));
      })
      .catch(() => {
        this.errors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.loading = false;
        app.render();
      });
  }

  submitParams() {
    return [...this.budgets, ...this.removeBudgets].map(budget => budget.submitParams());
  }
}
