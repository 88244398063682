import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody
} from '@this/shared/ui/data_displays/table';

interface Props {}

interface State {
  elementTickets: { month: number; current: number; limit: number | null }[];
  loading: boolean;
  error: string | null;
}

type ElementTicketsResponse = { month: number; current: number; limit: number | null }[];

class ElementTickets extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      elementTickets: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    this.fetchElementTickets();
  }

  async fetchElementTickets() {
    this.setState({ loading: true, error: null });
    try {
      const res = await utils.jsonPromise<ElementTicketsResponse>('/organization/element_tickets.json');
      this.setState({
        elementTickets: res,
        loading: false
      });
    } catch (e) {
      utils.sendErrorObject(e);
      this.setState({
        loading: false,
        error: '通信環境が不安定です。時間をおいてもう一度お試しください。'
      });
    }
  }

  isLimitExist(): boolean {
    const limits = this.state.elementTickets.map(t => t.limit);
    return limits.some(limit => !_.isNil(limit));
  }

  currentLimit() {
    const currentTicket = this.currentTicket();
    return _.isNil(currentTicket) ? null : currentTicket.limit;
  }

  currentTicket() {
    const m = moment().month() + 1;
    return this.state.elementTickets.find(t => t.month === m);
  }

  render() {
    try {
      const { elementTickets, loading, error } = this.state;
      const m = moment().month() + 1;
      const currentLimit = this.currentLimit();
      return (
        <>
          <OrganizationTitle>利用状況</OrganizationTitle>
          <OrganizationBody>
            {loading ? (
              <SimpleLoading />
            ) : error ? (
              <p className="error">{error}</p>
            ) : (
              <>
                {!_.isNil(currentLimit) && <LimitText>現在の利用上限：{currentLimit}チケット</LimitText>}
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="80px">{/** 月 */}</TableCell>
                        <TableCell align="right" width="200px">
                          利用チケット数
                        </TableCell>
                        <TableCell align="right" width="200px">
                          利用上限
                        </TableCell>
                        <TableCell>{/** margin */}</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {elementTickets.map((t, i) => (
                        <TableRow key={i} selected={t.month === m}>
                          <TableCell th>{`${t.month}月${t.month === m ? '(当月)' : ''}`}</TableCell>
                          <TableCell align="right">{t.current}</TableCell>
                          <TableCell align="right">{_.isNil(t.limit) ? '' : t.limit}</TableCell>
                          <TableCell>{/** margin */}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </OrganizationBody>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const LimitText = styled.p`
  font-weight: bold;
  font-size: 1.2em;
`;

export default ElementTickets;
