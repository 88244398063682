import React, { useCallback, useMemo, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import AdminContext from '@this/components/admin/context';

const TABS_FOR_AIT = [
  { path: '/admin/supplied_item_mappings', label: '検索商品' },
  { path: '/admin/individual_target_supplied_items', label: '個別（国内航空券・株主優待券）' },
  { path: '/admin/individual_domestic_air_supplied_items', label: '個別（国内航空券）' },
  { path: '/admin/individual_rentalcar_supplied_items', label: '個別（レンタカー）' },
  { path: '/admin/individual_railway_supplied_items', label: '個別（鉄道）' }
];
const TABS = TABS_FOR_AIT.filter(t => t.path !== '/admin/individual_rentalcar_supplied_items');

const SuppliedItemMappingTabs: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const serviceId = useContext(AdminContext);
  const tabs = utils.isAiTravel(serviceId) ? TABS_FOR_AIT : TABS;
  const tab = useMemo(() => tabs.find(t => t.path === location.pathname), [location.pathname, tabs]);

  const handleChange = useCallback(
    (path: string) => () => {
      history.push(path);
    },
    [history]
  );

  return (
    <FilterInputArea>
      {tabs.map(({ path, label }) => (
        <RadioLabel key={path}>
          <Input type="radio" value={path} checked={path === tab?.path} onChange={handleChange(path)} />
          <span>{label}</span>
        </RadioLabel>
      ))}
    </FilterInputArea>
  );
};

const FilterInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const RadioLabel = styled.label`
  display: flex;
  margin-right: 10px;
`;

const Input = styled.input`
  display: flex;
  margin-right: 10px;
`;

export default SuppliedItemMappingTabs;
