import React from 'react';
import { observer } from 'mobx-react';

import { Link } from '@this/shared/ui/navigations/link';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { SEARCH_TRACKING_PATH_LABELS } from '@this/src/domain/search_tracking_log';
import type SearchTrackingLog from '@this/src/domain/search_tracking_log';
import { SEARCH_TYPE_LABELS } from '@this/src/domain/search_query';
import type { SearchType } from '@this/src/domain/search_query';

import { SearchTrackingDetailSection } from '../share/SearchTrackingDetailSection';

interface Props {
  searchTrackingLog: SearchTrackingLog;
}

const SearchTrackingLogDetailInformation: React.FC<Props> = observer(({ searchTrackingLog: log }) => {
  return (
    <SearchTrackingDetailSection title="ログの詳細">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell>内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>アクション名</TableCell>
            <TableCell>
              {SEARCH_TRACKING_PATH_LABELS[log.path]}({log.action})
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>実行開始時刻</TableCell>
            <TableCell>{log.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ステータス</TableCell>
            <TableCell>{log.status}</TableCell>
          </TableRow>
          {log.searchType && (
            <TableRow>
              <TableCell>検索種別</TableCell>
              <TableCell>{SEARCH_TYPE_LABELS[log.searchType as SearchType]}</TableCell>
            </TableRow>
          )}
          {log.repository && (
            <TableRow>
              <TableCell>リポジトリ</TableCell>
              <TableCell>{log.repository}</TableCell>
            </TableRow>
          )}
          {log.origin && (
            <TableRow>
              <TableCell>出発地</TableCell>
              <TableCell>{log.origin}</TableCell>
            </TableRow>
          )}
          {log.originLatitude && (
            <TableRow>
              <TableCell>出発地緯度</TableCell>
              <TableCell>{log.originLatitude}</TableCell>
            </TableRow>
          )}
          {log.originLongitude && (
            <TableRow>
              <TableCell>出発地経度</TableCell>
              <TableCell>{log.originLongitude}</TableCell>
            </TableRow>
          )}
          {log.destination && (
            <TableRow>
              <TableCell>目的地</TableCell>
              <TableCell>{log.destination}</TableCell>
            </TableRow>
          )}
          {log.destinationLatitude && (
            <TableRow>
              <TableCell>目的地緯度</TableCell>
              <TableCell>{log.destinationLatitude}</TableCell>
            </TableRow>
          )}
          {log.destinationLongitude && (
            <TableRow>
              <TableCell>目的地経度</TableCell>
              <TableCell>{log.destinationLongitude}</TableCell>
            </TableRow>
          )}
          {log.tripId && (
            <TableRow>
              <TableCell>Trip ID</TableCell>
              <TableCell>{log.tripId}</TableCell>
            </TableRow>
          )}
          {log.searchQueryId && (
            <TableRow>
              <TableCell>SearchQuery ID</TableCell>
              <TableCell>{log.searchQueryId}</TableCell>
            </TableRow>
          )}
          {log.searchQueryItemId && (
            <TableRow>
              <TableCell>SearchQueryItem ID</TableCell>
              <TableCell>{log.searchQueryItemId}</TableCell>
            </TableRow>
          )}
          {log.bugsnagLink && (
            <TableRow>
              <TableCell>Bugsnag</TableCell>
              <TableCell>
                <Link href={log.bugsnagLink} target="_blank" rel="noopener noreferrer">
                  Bugsnagを見る
                </Link>
              </TableCell>
            </TableRow>
          )}
          {log.amadeusLogId && (
            <TableRow>
              <TableCell>AmadeusLogID</TableCell>
              <TableCell>{log.amadeusLogId}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </SearchTrackingDetailSection>
  );
});

export default SearchTrackingLogDetailInformation;
