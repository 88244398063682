import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import BilledAtEditor from '@this/domain/billed_at_editor/billed_at_editor';
import type PaymentMethod from '@this/src/domain/payment_method/payment_method';
import type OrderItem from '../../../../../domain/order_item';
import type Journal from '../../../../../domain/journal';

interface Props {
  item: OrderItem;
  suppliedItems: SuppliedItem[];
  paymentMethods: PaymentMethod[];
  traceItems: Record<number, string>;
}

interface AccountingInfoProps {
  journal: Journal;
}

const classBase = 'order-item-cell';

const getShortenName = (name: string): string => {
  const limit = 6;
  let result = name.slice(0, limit);
  if (name.length > limit) {
    result += '...';
  }
  return result;
};

const AccountingInfo = ({ journal }: AccountingInfoProps) => (
  <tr>
    <td className={`${classBase}__accounting-left`}>
      {journal.debtor}:{getShortenName(journal.debtorSub)} {utils.formatPrice(journal.debtorAmount)}
    </td>
    <td className={`${classBase}__accounting-slash`}>/</td>
    <td className={`${classBase}__accounting-right`}>
      {journal.creditor}:{getShortenName(journal.creditorSub)} {utils.formatPrice(journal.creditorAmount)}
    </td>
  </tr>
);

const OrderItemCell = ({ item, suppliedItems, paymentMethods, traceItems }: Props) => (
  <div className={classBase}>
    <div>order_item_id: {item.id}</div>
    {item.hotels.map(el => {
      const checkin = el.checkinDate.format('MM/DD');
      const checkout = el.checkoutDate.format('MM/DD');
      let itineraryId;
      if (el.provider === 'ean') {
        itineraryId = el.eanItineraryId;
      } else if (el.provider === 'eps_rapid') {
        itineraryId = el.rapidItineraryId;
      } else {
        itineraryId = '';
      }
      return (
        <div key={el.id}>
          <span>{`<${el.title()}:${el.providerString()}>`}</span>
          <span>
            {el.provider === 'ean' || el.provider === 'eps_rapid' ? (
              <a
                href={`https://mybookings.ean.com/#/503221/${itineraryId}/expedia@aitravel.jp?lang=ja_JP`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${el.name} ${checkin}〜${checkout}`}
              </a>
            ) : (
              `${el.name} ${checkin}〜${checkout}`
            )}
          </span>
          {el.hotelReservationNumber && <span>{`予約番号:${el.hotelReservationNumber}`}</span>}
        </div>
      );
    })}
    {item.transports.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>{`${el.from.description()} → ${el.to.description()} ${el.name}`}</span>
      </div>
    ))}
    {item.transports.map(
      el =>
        el.ticketingTypeStr() && (
          <div key={el.id}>
            <span>発券方法: {el.ticketingTypeStr()}</span>
          </div>
        )
    )}
    {item.transports.map(el => (
      <>
        <div>
          <span>transport_element_id: {el.id}</span>
        </div>
        {el.reservationNumber && (
          <div>
            <span>　予約番号: {el.reservationNumber}</span>
          </div>
        )}
        {el.ticketNumber && (
          <div>
            <span>　航空券番号: {el.ticketNumber}</span>
          </div>
        )}
        {el.alliesApplicationNumber && (
          <div>
            <span>　アリーズ申込番号: {el.alliesApplicationNumber}</span>
          </div>
        )}
        {el.frontierApplicationNumber && (
          <div>
            <span>　フロンティア申込番号: {el.frontierApplicationNumber}</span>
          </div>
        )}
        {el.skytoursApplicationNumber && (
          <div>
            <span>　スカイツアーズ申込番号: {el.skytoursApplicationNumber}</span>
          </div>
        )}
        {el.airReservationNumber && (
          <div>
            <span>　航空券予約番号: {el.airReservationNumber}</span>
          </div>
        )}
        {el.airConfirmationNumber && (
          <div>
            <span>　航空券確認番号: {el.airConfirmationNumber}</span>
          </div>
        )}
      </>
    ))}
    {item.cancelCharges.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>{el.content}</span>
        <br />
        {el.mappings.length > 0 && (
          <>
            <div>キャンセル商品</div>
            {el.mappings.map((mapping, i) => (
              <DetailColumn key={`mapping-${i}`}>
                trace_id-{mapping.originalTraceId}: {traceItems[mapping.originalTraceId || 0]}
              </DetailColumn>
            ))}
          </>
        )}
      </div>
    ))}
    {item.changeFees.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>{el.content}</span>
        <br />
        {el.mappings.length > 0 && (
          <>
            <div>変更商品</div>
            {el.mappings.map((mapping, i) => (
              <DetailColumn key={`mapping-${i}`}>
                trace_id-{mapping.originalTraceId}: {traceItems[mapping.originalTraceId || 0]}
              </DetailColumn>
            ))}
          </>
        )}
      </div>
    ))}
    {item.shippings.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>{`${el.category} ${el.shippedAt.format('YYYY/MM/DD')} ${el.number}`}</span>
      </div>
    ))}
    {item.wifis.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>
          {`${el.provider} ${el.startedAt.format('YYYY/MM/DD')}〜${el.endedAt.format('YYYY/MM/DD')} ${el.area}`}
        </span>
        <br />
        <span>{`確認番号: ${el.reservationNumber}`}</span>
      </div>
    ))}
    {item.rooms.map(el => (
      <div key={el.id}>
        <span>{`<${el.title()}>`}</span>
        <span>{`${el.startedAt.format('YYYY/MM/DD hh:mm')}〜${el.endedAt.format('YYYY/MM/DD hh:mm')} ${
          el.name
        }`}</span>
        <br />
        <span>{`確認番号: ${el.reservationNumber}`}</span>
      </div>
    ))}
    {item.elements
      .filter(e => ['travel_authorization', 'insurance', 'kit'].includes(e.type))
      .map(el => (
        <div key={el.id}>
          <span>{`<${el.title()}>`}</span>
        </div>
      ))}
    {item.deletedAt ? (
      <p>削除済み</p>
    ) : (
      <>
        <table className={`${classBase}__accounting-table`}>
          <tbody>
            {item.journals.map((j, index) => (
              <AccountingInfo key={index} journal={j} />
            ))}
          </tbody>
        </table>
        <div>支払い方法: {item.payment.paymentTypeString()}</div>
        <div>表示代金: {utils.formatPrice(item.price.price)}</div>
        {item.price.priceDetails &&
          item.price.priceDetails.map((pd, i) => (
            <div key={pd.id || ''}>
              <div>商品詳細{i + 1}</div>
              <DetailColumn>detail_trace_id: {pd.detailTraceId}</DetailColumn>
              <DetailColumn>detail_id: {pd.id}</DetailColumn>
              <DetailColumn>
                {pd.name}: {utils.formatPrice(pd.price)}
              </DetailColumn>
            </div>
          ))}
        <div>
          新卸値合計: {utils.formatPrice(item.price.originalPrices?.reduce((sum, op) => sum + op.price, 0) || 0)}
        </div>
        {item.price.originalPrices &&
          item.price.originalPrices.map((op, i) => (
            <div key={op.id || ''}>
              <div>
                新卸値{i + 1}: {utils.formatPrice(op.price)}
              </div>
              <DetailColumn>original_price_trace_id: {op.originalPriceTraceId}</DetailColumn>
              <DetailColumn>original_price_id: {op.id}</DetailColumn>
              <DetailColumn>
                仕入商品:{' '}
                {(() => {
                  const suppliedItem = _.find(suppliedItems, si => si.id === op.suppliedItemId);
                  if (suppliedItem) {
                    return `${suppliedItem.supplierName}/${suppliedItem.name}`;
                  }
                  return '';
                })()}
              </DetailColumn>
              <DetailColumn>
                支払い方法:{' '}
                {(() => {
                  const paymentMethod = _.find(paymentMethods, pm => pm.id === op.paymentMethodId);
                  if (paymentMethod) {
                    return `${paymentMethod.paymentMethodType?.name}/${paymentMethod.name}`;
                  }
                  return '';
                })()}
              </DetailColumn>
              <DetailColumn>仕入日: {op.purchasedAt?.format('YYYY/MM/DD')}</DetailColumn>
              <DetailColumn>仕入計上基準日: {op.accountRecordedAt?.format('YYYY/MM/DD')}</DetailColumn>
              <DetailColumn>仕入締日: {op.closingDay?.format('YYYY/MM/DD')}</DetailColumn>
              <DetailColumn>支払予定日: {op.dueDate?.format('YYYY/MM/DD')}</DetailColumn>
            </div>
          ))}
        {item.transports[0]?.ticketingType === 1 && (
          <div>アリーズ申込識別番号: {item.transports[0]?.alliesApplicationNumber}</div>
        )}
        {item.price.shareholderTickets &&
          item.price.shareholderTickets.map((st, i) => (
            <div key={st.id || ''}>
              前払旅行券{i + 1}: {utils.formatPrice(st.price)}
            </div>
          ))}
        <div>手数料: {utils.formatPrice(item.price.margin.amount)}</div>
        <div>返金予定額: {utils.formatPrice(item.price.refund)}</div>
      </>
    )}
    <BilledAtEditor billedAt={item.billedAt} orderItemId={item.id} />
  </div>
);

const DetailColumn = styled.div`
  padding-left: 10px;
`;

export default OrderItemCell;
