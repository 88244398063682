import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { darken } from '@material-ui/core/styles/colorManipulator';
import type TransportElement from '@this/src/domain/transport_element';
import type { OrderItemStepTodoFieldStore } from '@this/src/domain/arrangement/order_item_step_todo_field_store';
import { styled } from '@this/src/components/constants/themes';
import { OrderItemPriceForm } from '@this/components/arrangement/shared/price_change_form';

interface Props {
  serviceId: number;
  orderItemStepTodoFieldStore: OrderItemStepTodoFieldStore;
  errors: string[];
}

const OrderItemStepWorkDialogForm: React.FC<Props> = ({ serviceId, orderItemStepTodoFieldStore, errors }) => {
  const [modifiedFields, setModifiedFields] = useState<string[]>([]);

  const { fields, isPriceForm, transportElement } = useMemo(
    () => ({
      fields: orderItemStepTodoFieldStore.inputFields(),
      isPriceForm: orderItemStepTodoFieldStore.isPriceForm(),
      transportElement: orderItemStepTodoFieldStore.orderItem.elements[0] as TransportElement | undefined
    }),
    [orderItemStepTodoFieldStore]
  );

  const defaultValues = useMemo(
    () =>
      Object.fromEntries(
        fields.map(field => [
          field.key,
          field.type === 'transportText' || field.type === 'transportSelect'
            ? transportElement && field.value(transportElement)
            : undefined
        ])
      ),
    []
  );

  const classNameForModifiedField = useCallback(
    (...path: (string | number)[]): string => {
      const orderItemDiff = orderItemStepTodoFieldStore.orderItemDiff;
      const modified = orderItemDiff ? _.has(orderItemDiff, path) : true;
      return modified ? 'virtual-counte-price-change-form__modified_field' : '';
    },
    [orderItemStepTodoFieldStore.orderItemDiff]
  );

  const toggleModifiedField = useCallback(
    (key: string, value: any) => {
      const defaultValue = defaultValues[key] || '';
      if (value !== defaultValue) {
        setModifiedFields(state => (state.includes(key) ? state : [...state, key]));
      } else {
        setModifiedFields(state => (state.includes(key) ? state.filter(field => field !== key) : state));
      }
    },
    [setModifiedFields, defaultValues]
  );

  return (
    <Wrap>
      {errors.length > 0 && (
        <Errors>
          {errors.map((error, i) => (
            <p key={i}>{error}</p>
          ))}
        </Errors>
      )}
      {fields.length < 1 && !isPriceForm && <Empty>このステップでは入力フィールドは設定されていません</Empty>}
      <InputFieldArea>
        {fields.map(field => (
          <InputField key={field.label}>
            <label
              className={
                modifiedFields.includes(field.key) ? 'virtual-counte-price-change-form__modified_field' : ''
              }
            >
              {field.label} {field.required && <RequiredMark>*</RequiredMark>}
            </label>
            {field.type === 'transportText' ? (
              transportElement ? (
                <input
                  type="text"
                  defaultValue={defaultValues[field.key] || ''}
                  onBlur={e => {
                    toggleModifiedField(field.key, e.target.value);
                    field.handleChange(transportElement)(e);
                  }}
                />
              ) : (
                <UnknownField>{field.label}がありません</UnknownField>
              )
            ) : field.type === 'transportSelect' ? (
              transportElement ? (
                <Select
                  defaultValue={defaultValues[field.key] || ''}
                  onChange={e => {
                    toggleModifiedField(field.key, e.target.value);
                    field.handleChange(transportElement)(e);
                  }}
                >
                  {field.options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              ) : (
                <UnknownField>{field.label}がありません</UnknownField>
              )
            ) : (
              <></>
            )}
          </InputField>
        ))}
      </InputFieldArea>
      {isPriceForm && (
        <PriceFormArea>
          <OrderItemPriceForm
            {...orderItemStepTodoFieldStore.getProps()}
            serviceId={serviceId}
            classNameForModifiedField={classNameForModifiedField}
            changed={() => orderItemStepTodoFieldStore.changed()}
          />
        </PriceFormArea>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputField = styled.div`
  display: flex;
  gap: 8px;

  & label {
    min-width: fit-content;
  }
`;

const Select = styled.select`
  margin: 0;
`;

const UnknownField = styled.div`
  color: ${props => props.theme.grayTextColor};
`;

const PriceFormArea = styled.div`
  border: 1px solid ${props => darken(props.theme.fieldBorderColor, 0.1)};
  border-radius: 3px;
  padding: 16px;

  & select {
    margin: 0;
  }
`;

const Empty = styled.div`
  font-size: 1.2em;
  color: ${props => props.theme.grayTextColor};
`;

const RequiredMark = styled.span`
  color: ${props => props.theme.redColor};
`;

const Errors = styled.div`
  color: ${props => props.theme.redColor};
`;

export default OrderItemStepWorkDialogForm;
