import { observable } from 'mobx';

export type ItemListArgs = {
  id: number;
  code: string;
  name: string;
  dep_codes?: string;
  deleted_at?: string;
};

class ItemList {
  @observable
  id: number;

  @observable
  code: string;

  @observable
  name: string;

  @observable
  depCodes: string;

  readonly deletedAt?: string;

  constructor(args: ItemListArgs) {
    this.id = args.id;
    this.code = args.code;
    this.name = args.name;
    this.depCodes = args.dep_codes || '';
    this.deletedAt = args.deleted_at;
  }

  get submitParams() {
    return {
      code: this.code,
      name: this.name,
      dep_codes: this.depCodes
    };
  }
}

export default ItemList;
