import React from 'react';
import type { PaginationProps as MuiPaginationProps } from '@material-ui/lab';
import { Pagination as MuiPagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import type { AITTheme } from '@this/shared/ui/theme';

export type PaginationProps = Omit<
  MuiPaginationProps,
  | 'color'
  | 'variant'
  | 'shape'
  | 'size'
  | 'renderItem'
  | 'hideNextButton'
  | 'hidePrevButton'
  | 'showFirstButton'
  | 'showLastButton'
  | 'siblingCount'
>;
const usePaginationStyle = makeStyles<AITTheme>(({ tokens: { colors } }) => {
  return {
    root: {
      '& .MuiPaginationItem-page.Mui-selected': {
        backgroundColor: colors.background.selected
      }
    }
  };
});
export const Pagination = ({ className, ...props }: PaginationProps) => {
  const styles = usePaginationStyle();
  return <MuiPagination className={`${className} ${styles.root}`} color="standard" shape="rounded" {...props} />;
};
