import type { RefObject } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';

interface ListItem {
  value: string;
  displayName: string;
  subText?: string;
  selected?: boolean;
}

interface State {
  filterdList: ListItem[];
  searchText: string;
  searching: boolean;
}

interface Props {
  list: ListItem[];
  currentValue: string;
  className?: string;
  onChange: (value: string) => void;
}

export default class SearchableSelect extends React.Component<Props, State> {
  private readonly inputRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { filterdList: this.props.list, searchText: '', searching: false };
  }

  get currentDisplayName() {
    const value = this.props.list.find(l => l.value === this.props.currentValue);
    return value ? value.displayName : '選択してください';
  }

  search = (e: React.SyntheticEvent) => {
    const searchText = (e.target as any).value;
    const filterdList = this.props.list.filter(l => l.displayName.indexOf(searchText) >= 0);

    this.setState({ searchText, filterdList });
  };

  showSearchArea = () => {
    this.setState({ searching: true });
    document.addEventListener('click', this.handleClickOutside);
  };

  hideSearchArea = () => {
    this.setState({ searching: false, filterdList: this.props.list });
    document.removeEventListener('click', this.handleClickOutside);
  };

  selectItem = (value: string) => {
    this.props.onChange(value);
    this.hideSearchArea();
  };

  onKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const filterdList = this.state.filterdList;
    const index = filterdList.findIndex(l => !!l.selected);

    switch (event.keyCode) {
      // ↑
      case 38:
        if (index === 0) {
          return;
        }
        if (filterdList[index]) {
          filterdList[index].selected = false;
        }
        filterdList[index - 1].selected = true;
        this.setState({ filterdList });
        break;
      // ↓
      case 40:
        if (index === filterdList.length - 1) {
          return;
        }
        if (filterdList[index]) {
          filterdList[index].selected = false;
        }

        filterdList[index + 1].selected = true;
        this.setState({ filterdList });
        break;
      // enter
      case 13:
        if (index < 0) {
          return;
        }
        this.selectItem(this.state.filterdList[index].value);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <Container className={this.props.className} ref={this.inputRef}>
        <SelectedValue onClick={this.showSearchArea}>{this.currentDisplayName}</SelectedValue>
        {this.state.searching && (
          <SelectArea>
            <SelectAreaInput
              type="text"
              placeholder="検索"
              value={this.state.searchText}
              onChange={this.search}
              onKeyDown={this.onKeydown}
              autoFocus
            />
            <SelectAreaItems>
              {this.state.filterdList.map((l, i) => (
                <SelectAreaItem key={i} selected={l.selected} onClick={() => this.selectItem(l.value)}>
                  <span>{l.displayName}</span>
                  {l.subText && <small className="subtext">{l.subText}</small>}
                </SelectAreaItem>
              ))}
              {this.state.filterdList.length === 0 && <SelectAreaItem>候補がみつかりません</SelectAreaItem>}
            </SelectAreaItems>
          </SelectArea>
        )}
      </Container>
    );
  }

  private handleClickOutside = (event: MouseEvent) => {
    if (this.inputRef.current && !this.inputRef.current.contains(event.target as any)) {
      this.hideSearchArea();
    }
  };
}

const Container = styled.div`
  position: relative;
`;

const SelectedValue = styled.div`
  padding: 4px 16px 4px 2px;
  border-bottom: 1px solid #333;

  &::after {
    position: absolute;
    content: '▼';
    color: rgb(170, 147, 91);
    font-size: 10px;
    top: 6px;
    right: 0px;
  }
`;

const SelectArea = styled.div`
  position: absolute;
  top: 28px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
`;

const SelectAreaInput = styled.input`
  border-radius: 0 !important;
  margin-bottom: 0 !important;
`;

const SelectAreaItems = styled.ul`
  border: 2px solid #eee;
  overflow: auto;
  max-height: 140px;
  margin-bottom: 8px;
`;

const SelectAreaItem = styled.li<{ selected?: boolean }>`
  padding: 5px 12px;
  cursor: pointer;

  ${({ selected }) => selected && `background-color: #eee;`}

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;
