import type { ChatbotUserSettingResponseArgs } from '@this/src/domain/chatbot/chatbot_user_setting';
import { Fetcher } from '@this/src/util';

interface Props {
  onSuccess?: (res: InitiateSessionResponse) => void;
}

export interface InitiateSessionResponse {
  session_id: number;
  message: string;
  chatbot_user_setting: ChatbotUserSettingResponseArgs;
}

const initiateSessionApi = ({ onSuccess }: Props) => {
  Fetcher.post<InitiateSessionResponse>('/chatbot/sessions', {}).then(res => {
    onSuccess?.(res);
  });
};

export default initiateSessionApi;
