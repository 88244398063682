import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import {
  OrganizationBody,
  OrganizationHeader,
  OrganizationHeaderTitle,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import DownloadFormat, {
  DEFAULT_ENCODING,
  DATA_SELECT_OPTIONS
} from '@this/components/organization/csv_bulk_action/download_format';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { DownloadType, Encoding } from '@this/components/organization/csv_bulk_action/types';
import { styled } from '@this/constants/themes';
import {
  BulkActionSection,
  BulkActionSectionContent,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import Link from '@this/shared/atoms/link';
import FileFormat from '@this/components/organization/file_format';
import FileValidator from '@this/components/organization/csv_bulk_action/file_validator';
import UPSERT_MEMBER_FORMATS from '@this/components/organization/csv_bulk_action/member_file_formats';
import CsvBulkUpsertMemberForm from '@this/components/organization/csv_bulk_action/csv_bulk_upsert_member_form';
import type { BulkActionAttr } from '@this/components/organization/types';
import { BulkActionAttrServiceType } from '@this/components/organization/types';
import DownloadAccountStatusFilter from '@this/components/organization/csv_bulk_action/download_account_status_filter';
import type OrganizationData from '@this/domain/organization/organization2';
import type { OrganizationRoleResponse } from '@this/domain/organization_role/organization_role';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import type { AccountStatus } from '@this/domain/user/organization_member_json';
import { Fetcher, HTTPError } from '@this/src/util';
import { Title } from '@this/components/admin/admin';

interface Props extends RouteComponentProps<{ id?: string }> {
  expenses?: boolean;
  organization?: OrganizationData;
}
interface State {
  loading: boolean;
  organizationRoles: OrganizationRole[];
  validateResult?: FileValidatorResult;
  uploadResult?: { uploaded: boolean; errors?: string[] };
  downloadType: DownloadType;
  downloadAccountStatus: AccountStatus | null;
  admin?: boolean;
  organizationName?: string;
}

class CsvBulkUpsertMember extends React.Component<Props, State> {
  historyPath = '/organization/bulk_action_statuses?actionType=upsert_members';

  backPath = '/organization/members';

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      organizationRoles: [],
      downloadType: DEFAULT_ENCODING,
      downloadAccountStatus: null
    };

    if (this.props.expenses) {
      const pathPrefix = '/biztra';
      this.historyPath = `${pathPrefix}${this.historyPath}`;
      this.backPath = `${pathPrefix}${this.backPath}`;
    }
  }

  componentDidMount(): void {
    Fetcher.get<OrganizationRoleResponse>('/organization/roles.json').then(result => {
      const roles = result ? result.roles.map(args => new OrganizationRole(args)) : [];
      this.setState({
        organizationRoles: roles
      });
    });
  }

  downloadFormat = (type: DownloadType) => {
    const pathPrefix = this.props.expenses ? '/biztra' : '';
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      let path = `${pathPrefix}/organization/member/csv/bulk_upsert/template_with_members?encoding=${encoding}`;
      const accountStatus = this.state.downloadAccountStatus;
      if (accountStatus) path += `&account_status=${accountStatus}`;
      location.href = path;
    } else {
      location.href = `${pathPrefix}/organization/member/csv/bulk_upsert/template?encoding=${type}`;
    }
  };

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  upload = async (file: File, isSendInvitation: boolean) => {
    this.setState({ loading: true });

    const pathPrefix = this.props.expenses ? '/biztra' : '';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('is_send_invitation', isSendInvitation.toString());
    try {
      await Fetcher.upload(`${pathPrefix}/organization/member/csv/bulk_upsert`, formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }

  // 役割名は変わりうる値なので、バックエンドから取得した値でアップデートする。
  private setRoleToFormats(formatTemplates: BulkActionAttr[]) {
    return formatTemplates.map(t => {
      if (t.name === 'role') {
        t.example = this.state.organizationRoles.map(r => r.name).join(', ');
        return t;
      }
      return t;
    });
  }

  // 役職名は変わりうる値なので、バックエンドから取得した値でアップデートする。
  private setGradeToFormats(formatTemplates: BulkActionAttr[]) {
    return formatTemplates.map(t => {
      if (t.name === 'grade') {
        if (this.props.organization?.grades !== undefined) {
          t.example = this.props.organization?.grades?.map(g => g.name).join(', ');
          return t;
        }
      }
      return t;
    });
  }

  private get fileFormat(): BulkActionAttr[] {
    const umf = this.setRoleToFormats(UPSERT_MEMBER_FORMATS);
    const upsertMemberFormats = this.setGradeToFormats(umf);
    return upsertMemberFormats.filter(f => {
      if (f.serviceType == null || f.serviceType === BulkActionAttrServiceType.ALL) {
        return true;
      }

      return this.state.admin
        ? f.serviceType === BulkActionAttrServiceType.AI_TRAVEL ||
            f.serviceType === BulkActionAttrServiceType.ADMIN
        : this.props.expenses
        ? f.serviceType === BulkActionAttrServiceType.BIZTRA
        : f.serviceType === BulkActionAttrServiceType.AI_TRAVEL;
    });
  }

  render() {
    const { loading, validateResult, uploadResult, downloadType, downloadAccountStatus, admin, organizationName } =
      this.state;
    const { expenses } = this.props;
    return (
      <>
        {expenses ? (
          <Header>
            <HeaderTitle>一括CSV登録・更新</HeaderTitle>
          </Header>
        ) : admin ? (
          <Title>
            <Link to="/admin/organizations">顧客一覧</Link>
            <span> &gt; {organizationName}</span>
            <span> &gt; </span>
            <Link to={`/admin/organizations/${this.props.match.params.id}/members`}>社員マスタ</Link>
            <span> &gt; 社員一括登録・更新</span>
          </Title>
        ) : (
          <OrganizationTitle>社員一括登録・編集</OrganizationTitle>
        )}
        <OrganizationBody>
          <Description>
            <p>
              CSVファイルをアップロードすることで、社員を一括登録・更新することができます。
              <br />
              既存ユーザー情報を編集したい場合はプルダウンメニューより「登録データ」を選択の上、ダウンロードして編集後、アップロードしてください。
            </p>
            <ul>
              <li>
                ※ ログイン方法をシングルサインオンに変更したい場合、
                あらかじめ設定画面でシングルサインオンを有効化するにチェックをいれてください
              </li>
              <li>
                ※「登録データ」CSVをダウンロードするとユーザーステータス情報（account_status）が記載ありますが変更できません。（変更しても反映されません）
              </li>
            </ul>
          </Description>
          <DownloadFormat
            downloadFormat={this.downloadFormat}
            onSelect={type => this.setState({ downloadType: type })}
            dataEnabled
          />
          {DATA_SELECT_OPTIONS.map(o => o.value).includes(downloadType) && (
            <DownloadAccountStatusFilter
              currentStatus={downloadAccountStatus}
              onChange={newStatus => this.setState({ downloadAccountStatus: newStatus })}
            />
          )}
          <BulkActionSections>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>アップロード</h3>
                <Link to={this.historyPath}>アップロード履歴</Link>
              </BulkActionSectionHeader>
              <SectionContent expenses={expenses}>
                <CsvBulkUpsertMemberForm
                  loading={loading}
                  historyPath={this.historyPath}
                  backPath={this.backPath}
                  validateResult={validateResult}
                  uploadResult={uploadResult}
                  dropZoneStyle={{ height: `calc(100vh - ${expenses ? '458px' : '532px'})` }}
                  select={this.checkFile}
                  upload={this.upload}
                />
              </SectionContent>
            </BulkActionSection>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>フォーマット</h3>
              </BulkActionSectionHeader>
              <SectionContent expenses={expenses}>
                <FileFormat formats={this.fileFormat} />
              </SectionContent>
            </BulkActionSection>
          </BulkActionSections>
        </OrganizationBody>
      </>
    );
  }
}

const Description = styled.div`
  p {
    margin-bottom: 5px;
  }
`;

const SectionContent = styled(BulkActionSectionContent)`
  height: calc(100vh - ${({ expenses }: { expenses?: boolean }) => (expenses ? '347px' : '440px')});
`;

const Header = styled(OrganizationHeader)`
  padding: 0;
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: #927230;
  font-size: 24px;
  margin: 0 0 12px 0;
`;
export default CsvBulkUpsertMember;
