import { observable } from 'mobx';
import type { OrderItemJobTypeKey } from '../order_item/order_item_job_type';
import type { OrderItemStatusKey } from '../order_item/order_item_status';

export type HotelOrderItemRakutenTodoMapperResponseArgs = {
  reservation_number: string;
  people_num: number;
  hotel_name: string;
  hotel_address: string;
  room_type: string;
  plan_name: string;
  breakfast: boolean;
  checkin_date: string;
  checkout_date: string;
  traveler_name: string;
};

export type HotelOrderItemRakutenTodoResponseArgs = {
  from_order_item: HotelOrderItemRakutenTodoMapperResponseArgs;
  from_rakuten_reservation: HotelOrderItemRakutenTodoMapperResponseArgs;
  diff: { [key: string]: boolean };
  price: number;
  original_price: number;
  hotel_price: number;
  supplied_item_name: string;
  job_type: number;
  status: number;
  is_inventoried: boolean;
  inventoried_by: string;
  inventoried_at: string;
  trip_id: number;
  order_item_id: number;
  trace_id: number;
  last_log_message: string;
  last_log_message_arranger: string;
};

type HotelOrderItemRakutenTodoMapperArgs = {
  reservationNumber: string;
  peopleNum: number;
  hotelName: string;
  hotelAddress: string;
  roomType: string;
  planName: string;
  breakfast: boolean;
  checkinDate: string;
  checkoutDate: string;
  travelerName: string;
};

type HotelOrderItemRakutenTodoArgs = {
  fromOrderItem: HotelOrderItemRakutenTodoMapperArgs;
  fromRakutenReservation: HotelOrderItemRakutenTodoMapperArgs;
  diff: { [key: string]: boolean };
  price: number;
  originalPrice: number;
  hotelPrice: number;
  suppliedItemName: string;
  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  isInventoried: boolean;
  inventoriedBy: string;
  inventoriedAt: string;
  tripId: number;
  orderItemId: number;
  traceId: number;
  lastLogMessage: string;
  lastLogMessageArranger: string;
};

const convertHotelOrderItemRakutenTodoMapperResponseToArgs = (
  response: HotelOrderItemRakutenTodoMapperResponseArgs
) => {
  const args: HotelOrderItemRakutenTodoMapperArgs = {
    reservationNumber: response.reservation_number,
    peopleNum: response.people_num,
    hotelName: response.hotel_name,
    hotelAddress: response.hotel_address,
    roomType: response.room_type,
    planName: response.plan_name,
    breakfast: response.breakfast,
    checkinDate: response.checkin_date,
    checkoutDate: response.checkout_date,
    travelerName: response.traveler_name
  };
  return args;
};

const convertHotelOrderItemRakutenTodoResponseToArgs = (response: HotelOrderItemRakutenTodoResponseArgs) => {
  const args: HotelOrderItemRakutenTodoArgs = {
    fromOrderItem: convertHotelOrderItemRakutenTodoMapperResponseToArgs(response.from_order_item),
    fromRakutenReservation: convertHotelOrderItemRakutenTodoMapperResponseToArgs(
      response.from_rakuten_reservation
    ),
    diff: response.diff,
    price: response.price,
    originalPrice: response.original_price,
    hotelPrice: response.hotel_price,
    suppliedItemName: response.supplied_item_name,
    jobType: response.job_type as OrderItemJobTypeKey,
    status: response.status as OrderItemStatusKey,
    isInventoried: response.is_inventoried,
    inventoriedBy: response.inventoried_by,
    inventoriedAt: response.inventoried_at,
    tripId: response.trip_id,
    orderItemId: response.order_item_id,
    traceId: response.trace_id,
    lastLogMessage: response.last_log_message,
    lastLogMessageArranger: response.last_log_message_arranger
  };
  return args;
};

export default class HotelOrderItemRakutenTodo {
  @observable readonly fromOrderItem: HotelOrderItemRakutenTodoMapperArgs;

  @observable readonly fromRakutenReservation: HotelOrderItemRakutenTodoMapperArgs;

  @observable readonly diff: { [key: string]: boolean };

  @observable readonly price: number;

  @observable readonly originalPrice: number;

  @observable readonly hotelPrice: number;

  @observable readonly suppliedItemName: string;

  @observable jobType: OrderItemJobTypeKey;

  @observable status: OrderItemStatusKey;

  @observable readonly isInventoried: boolean;

  @observable readonly inventoriedBy: string;

  @observable readonly inventoriedAt: string;

  @observable readonly tripId: number;

  @observable readonly orderItemId: number;

  @observable readonly traceId: number;

  @observable readonly lastLogMessage: string;

  @observable readonly lastLogMessageArranger: string;

  @observable readonly isDiff: boolean;

  constructor(args: HotelOrderItemRakutenTodoArgs) {
    this.fromOrderItem = args.fromOrderItem;
    this.fromRakutenReservation = args.fromRakutenReservation;
    this.diff = args.diff;
    this.price = args.price;
    this.originalPrice = args.originalPrice;
    this.hotelPrice = args.hotelPrice;
    this.suppliedItemName = args.suppliedItemName;
    this.jobType = args.jobType;
    this.status = args.status;
    this.isInventoried = args.isInventoried;
    this.inventoriedBy = args.inventoriedBy;
    this.inventoriedAt = args.inventoriedAt;
    this.tripId = args.tripId;
    this.orderItemId = args.orderItemId;
    this.traceId = args.traceId;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
    this.isDiff = Object.values(this.diff).some(v => v);
  }

  static fromResponse(response: HotelOrderItemRakutenTodoResponseArgs) {
    const args = convertHotelOrderItemRakutenTodoResponseToArgs(response);
    return new HotelOrderItemRakutenTodo(args);
  }
}
