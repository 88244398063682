import React from 'react';
import type { Report } from '@this/domain/expenses/report';
import { styled } from '@this/constants/themes';

interface Props {
  report?: Report;
}

export default class StatusLabel extends React.Component<Props> {
  render() {
    const { report } = this.props;
    const keys = {
      draft: '未申請',
      applied: '承認待ち',
      appliedForApprover: '担当者承認待ち',
      appliedForAccountant: '経理承認待ち',
      approved: '承認済み',
      rejected: '差し戻し',
      completed: '完了'
    };
    const text = report ? keys[report.status] : '未申請';

    return (
      <StatusLabelWrapper className={report ? report.status : 'draft'}>
        <Label className={report ? report.status : 'draft'}>{text}</Label>
      </StatusLabelWrapper>
    );
  }
}

const fontSize = `12px`;

const borderRadius = `4px`;

const draftBgColor = `rgba(118, 118, 118, 0.2)`;

const draftTxtColor = `#767676`;

const appliedBgColor = `rgba(123, 97, 255, 0.2)`;

const appliedTxtColor = `#7b61ff`;

const approvedBgColor = `rgba(29, 124, 45, 0.2)`;

const approvedTxtColor = `#1d7c2d`;

const rejectedBgColor = `rgba(199, 47, 98, 0.2)`;

const rejectedTxtColor = `#c72f62`;

const completedBgColor = `rgba(235, 191, 64, 0.2)`;

const completedTxtColor = `#c59632`;

const StatusLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 12px;
  &.appliedForApprover {
    padding: 4px 6px;
  }
  border-radius: ${borderRadius};
  width: 72px;
  &.appliedForApprover,
  &.appliedForAccountant {
    width: 96px;
  }

  &.draft {
    background: ${draftBgColor};
  }
  &.applied,
  &.appliedForApprover,
  &.appliedForAccountant {
    background: ${appliedBgColor};
  }
  &.approved {
    background: ${approvedBgColor};
  }
  &.rejected {
    background: ${rejectedBgColor};
  }
  &.completed {
    background: ${completedBgColor};
  }
`;

const Label = styled.span`
  font-size: ${fontSize};
  text-align: center;
  letter-spacing: normal;

  &.draft {
    color: ${draftTxtColor};
  }
  &.applied,
  &.appliedForApprover,
  &.appliedForAccountant {
    color: ${appliedTxtColor};
  }
  &.approved {
    color: ${approvedTxtColor};
  }
  &.rejected {
    color: ${rejectedTxtColor};
  }
  &.completed {
    color: ${completedTxtColor};
  }
`;
