export type CreditCardPaymentHistoryCsvReportFileArgs = {
  file_name: string;
  encoding: string;
  file_download_path: string;
};
export class CreditCardPaymentHistoryCsvReportFile {
  fileName: string;

  fileDownloadPath: string;

  encoding: string;

  constructor(args: CreditCardPaymentHistoryCsvReportFileArgs) {
    this.fileName = args.file_name;
    this.encoding = args.encoding;
    this.fileDownloadPath = args.file_download_path;
  }
}
