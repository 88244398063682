moment = require('moment-timezone')

class TransportElementTerminal
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @timeZone = args.timeZone ||= 'Asia/Tokyo'
    @time =
      if typeof moment(args.time).tz(@timeZone) != 'undefined'
        moment(args.time).tz(@timeZone)
      else
        moment().tz(@timeZone)
    @timeInTimeZoneStr = args.timeInTimeZoneStr
    @code = args.code

  handleChangeName: (value) ->
    @name = value
    app.render()

  handleTimeChange: (moment) ->
    @time = moment
    app.render()

  dateStr: ->
    "#{@time.tz(@timeZone).format('YYYY-MM-DD')}"

  dateTimeStr: ->
    "#{@time.tz(@timeZone).format('MM/DD HH:mm')}"

  timeStr: ->
    "#{@time.tz(@timeZone).format('HH:mm')}"

  description: ->
    time = @time.clone()
    "#{@name || ''} #{time.tz(@timeZone).format('YYYY/MM/DD HH:mm')}"

  foreignAirDescription: ->
    time = @time.clone()
    "#{time.tz(@timeZone).format('YYYY/MM/DD HH:mm')} #{@name}"

  changeTimeZone: (timezone) ->
    @timeZone = timezone
    # 時間を変えずタイムゾーンだけを変える
    @time.tz(timezone, true)
    app.render()

  updateParams: ->
    time = @time.clone()
    name: @name
    time: time.tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    time_zone: @timeZone
    code: @code

module.exports = TransportElementTerminal
