import type { Themes } from '@this/constants/themes';
import { styled } from '@this/constants/themes';
import type { ThemeProps } from 'styled-components';
import { ButtonBase } from '@this/shared/atoms/button';
import { Link } from 'react-router-dom';

/**
 * 組織管理画面共通で使用する定義する StyledComponent
 */

export const OrganizationNavBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #eee;
  padding-bottom: 10px;
`;

export const OrganizationTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0;
  background-color: #eee;
`;

export const OrganizationSubTitle = styled.p`
  padding-bottom: 20px;
  margin: 0;
  background-color: #eee;
`;

export const OrganizationHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

export const OrganizationHeaderTitle = styled.h2`
  margin: 0;
`;

export const OrganizationBody = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
`;

export const OrganizationTable = styled.table`
  margin: 8px 0 0 0;
  table-layout: auto;
`;

interface OrganizationThProps extends ThemeProps<Themes> {
  width?: string;
}

export const OrganizationTh = styled.th`
  background: ${(props: OrganizationThProps) => props.theme.grayColorLight};
  font-size: 10px;
  font-weight: normal;
  border-bottom: none;
  border-right: solid 1px white;
  padding: 4px;
  ${(props: OrganizationThProps) => props.width && `width: ${props.width}`}
`;

export const OrganizationButtonTh = styled(OrganizationTh)`
  width: 100px;
`;

export const OrganizationTd = styled.td`
  font-size: 12px;
  font-weight: bold;
  padding: 8px 4px;
`;

export const OrganizationTruncateTd = styled.td`
  font-size: 12px;
  font-weight: bold;
  padding: 8px 4px;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const OrganizationTdButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${ButtonBase};
  width: 80px;
  margin: 2px auto;
  padding: 5px 0;
`;

export const OrganizationDisableTd = styled(OrganizationTd)`
  display: flex;
  margin-bottom: -1px;
`;

export const DisableTdButton = styled(OrganizationTdButton)`
  margin-left: 16px;
`;

export const AddButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${ButtonBase};
  width: 200px;
  padding: 0.75em 1em;
`;

export const OrganizationBodyHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const OrganizationBodyFooterActions = styled.div`
  margin-top: 20px;
`;

export const DownloadButton = styled.button.attrs(() => ({ type: 'button' }))`
  ${ButtonBase};
  padding: 5px 10px;
`;

export const CsvDownloadContainer = styled(OrganizationBodyHeaderActions)`
  justify-content: flex-start;
  margin-bottom: 20px;

  select {
    margin: 0;
  }

  ${DownloadButton} {
    margin-left: 10px;
  }
`;

export const BulkActionLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
`;

export const BulkDeleteActionLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
  color: rgb(191, 0, 0);
`;

export const OrganizationBiztraTable = styled(OrganizationTable)`
  margin: 0;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 4px;
`;

export const OrganizationBiztraTd = styled(OrganizationTd)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: center !important;
`;

export const OrganizationBiztraAddressTd = styled(OrganizationBiztraTd)`
  font-size: 12px;
  line-height: 14px;
`;

export const OrganizationBiztraTdWithPointer = styled(OrganizationBiztraTd)`
  cursor: pointer;
`;

export const OrganizationBiztraTdEditButton = styled(OrganizationTdButton)`
  border-radius: 6px;
  padding: 7px 0;
`;

export const OrganizationBiztraTdDeleteButton = styled(OrganizationBiztraTdEditButton)`
  background: ${props => props.theme.redColor} !important;
`;

export const OrganizationBiztraTh = styled.th`
  background: ${(props: OrganizationThProps) => props.theme.grayColorLight2};
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #767676;
  border-right: solid 1px white;
  text-align: center;
  ${(props: OrganizationThProps) => props.width && `width: ${props.width}`}
`;

export const OrganizationBiztraButtonTh = styled(OrganizationBiztraTh)`
  width: 100px;
`;

export const OrganizationBiztraAddButton = styled(AddButton)`
  border-radius: 6px;
  margin: 0 0 1em 0.2rem;
`;

export const OrganizationBiztraAccountTypeButton = styled(AddButton)`
  border-radius: 6px;
  background-color: #919191 !important;
  width: 160px;
  padding: 0.4rem 0 !important;
`;

export const OrganizationLabel = styled.div`
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin: 8px 0;
`;

export const OrganizationFormSection = styled.div`
  margin: 8px 40px;
`;

export const OrganizationBiztraEditButton = styled.button.attrs(() => ({ type: 'button' }))`
  background-color: #ae985e;
  border-radius: 6px;
  margin-right: 16px;
  margin-right: 24px;
  width: 100px;
`;

export const OrganizationButtonSection = styled.div`
  display: flex;
  -webkit-box-pack: end;
  box-pack: end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
  gap: 0px 20px;
`;

export const OrganizationAccountTypeButtonSection = styled(OrganizationButtonSection)`
  gap: 0px 5px;
`;

export const OrganizationEditModalWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px 80px;
  padding: 20px;
`;

export const OrganizationEditModalBody = styled.div`
  padding: 20px;
`;

export const OrganizationEditModalErr = styled.p`
  color: red;
`;

export const OrganizationEditModalSection = styled.div`
  width: 48%;
  max-width: 400px;
  padding: 5px;
`;

export const OrganizationEditModalLabel = styled.label`
  font-size: 13px;
  font-weight: normal;
`;

export const OrganizationEditModalSelectWrap = styled.div`
  > select {
    width: 400px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #eee;
    margin-bottom: 0.75em
    padding: 0 2px;
    &&&:focus {
      box-shadow: none;
      border: 1px solid #ae985e;
    }
  }
`;

export const OrganizationAddressArea = styled.div`
  color: ${props => props.theme.grayTextColor};
  font-weight: bold;
  font-size: 11px;
`;
