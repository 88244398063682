import React, { useEffect, useState } from 'react';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { styled } from '@this/constants/themes';
import type { TravelerCandidate } from '@this/domain/arrangement/user_search.repository';

type Props = {
  className?: string;
  selectedUserName: string | null;
  loading: boolean;
  candidates: TravelerCandidate[];
  onSearch: (query: string) => void;
  onChange: (candidate: TravelerCandidate) => void;
};

const OrderItemTravelerSelect: React.FC<Props> = ({
  className,
  selectedUserName,
  loading = false,
  candidates,
  onSearch,
  onChange
}) => {
  const inputValueSubject = new Subject<string>();
  const inputValue$ = inputValueSubject.asObservable();
  const subscription = inputValue$.pipe(debounceTime(300)).subscribe(str => onSearch(str));
  const [canSelect, setCanSelect] = useState(false);
  const selectCandidate = (candidate: TravelerCandidate) => {
    onChange(candidate);
    setCanSelect(false);
  };

  useEffect(() => () => subscription.unsubscribe(), []);

  return (
    <Container className={className}>
      <TravelerReadOnlyInput
        value={selectedUserName ?? ''}
        placeholder="利用者を選択してください"
        onClick={() => setCanSelect(!canSelect)}
      />
      {canSelect && (
        <Popup>
          <SearchInput type="text" placeholder="検索" onChange={e => inputValueSubject.next(e.target.value)} />
          <Candidates>
            {loading ? (
              <li>
                <SimpleLoading />
              </li>
            ) : (
              candidates.map((u, i) => (
                <CandidatesItem key={i} onClick={() => selectCandidate(u)}>
                  <CandidateName>
                    {u.last_name_kana || u.last_name} {u.first_name_kana || u.first_name}
                  </CandidateName>
                  <CandidateEmail>{u.email}</CandidateEmail>
                </CandidatesItem>
              ))
            )}
          </Candidates>
        </Popup>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Popup = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  border: 1px solid #eee;
  background-color: #fff;
  z-index: 2;
`;

const TravelerReadOnlyInput = styled.input.attrs({ type: 'text', readOnly: true })`
  cursor: pointer;
`;

const SearchInput = styled.input`
  border: 1px solid #eee !important;
`;

const Candidates = styled.ul`
  overflow-y: scroll;
  height: 100px;
  padding: 5px 10px;

  li + li {
    margin-top: 5px;
  }
`;

const CandidatesItem = styled.li`
  cursor: pointer;
`;

const CandidateName = styled.div``;

const CandidateEmail = styled.div`
  font-size: 10px;
  color: #bbb;
`;

export default OrderItemTravelerSelect;
