import React from 'react';
import { styled } from '@this/constants/themes';
import type User from '@this/domain/user/user';

interface Props {
  user: User;
}

const OrganizationError: React.FC<Props> = ({ user }) => {
  return (
    <Section>
      {user && user.department ? (
        <ErrorMessage>
          「{user.department.name}
          」に事前申請の承認者が設定されていません。管理者に連絡して承認者の設定を依頼してください。
        </ErrorMessage>
      ) : (
        <ErrorMessage>
          所属部署が設定されていません。管理者に連絡して所属部署の設定を依頼してください。
        </ErrorMessage>
      )}

      <Label>■ {user.organization.name}の管理者</Label>
      {user.organization.admins &&
        user.organization.admins.map((row, i) => (
          <Row key={i}>
            {row.name}:{row.email}
          </Row>
        ))}
    </Section>
  );
};

const Section = styled.div`
  color: ${props => props.theme.redColor};
  font-size: 15px;
  padding: 10px;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.redColor};
`;

const Label = styled.div`
  margin-top: 20px;
`;

const Row = styled.div`
  padding: 2px 10px;
`;

export default OrganizationError;
