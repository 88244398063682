import _ from 'lodash';
import DomesticAirMarketLogCandidate from './domestic_air_market_log_candidate';

interface AirInfo {
  name: string;
  from: string;
  to: string;
  changeable: string;
}

interface SummaryInfo {
  average_price: number;
  max_price: number;
  min_price: number;
  median_price: number;
}

interface Summary {
  changeable: SummaryInfo;
  unchangeable: SummaryInfo;
}

export interface DomesticAirMarketLogArgs {
  candidates?: DomesticAirMarketLogCandidate[];
  user_name: string;
  approver_names: string;
  traveler_names: string;
  info: AirInfo[];
  summary: Summary;
  price_desc: string;
  price_ordered: number;
}

class DomesticAirMarketLog {
  public candidates: DomesticAirMarketLogCandidate[];

  public userName: string;

  public approverNames: string;

  public travelerNames: string;

  public info: AirInfo[];

  public priceDesc: string;

  public summary: Summary;

  public priceOrdered: number;

  constructor(args: DomesticAirMarketLogArgs) {
    this.candidates = args.candidates ? args.candidates.map(c => new DomesticAirMarketLogCandidate(c)) : [];
    this.userName = args.user_name;
    this.approverNames = args.approver_names;
    this.travelerNames = args.traveler_names;
    this.info = args.info;
    this.priceDesc = args.price_desc || '';
    this.summary = args.summary;
    this.priceOrdered = args.price_ordered;
  }
}

export default DomesticAirMarketLog;
