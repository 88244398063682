type SeatMapFacilityType = 'seat' | 'aisle';
export type SeatMapFacilityAvailability = 'available' | 'occupied' | 'no_seat' | 'blocked';

export interface SeatMapFacilityJson {
  type: SeatMapFacilityType;
  seat_code: string;
  availability: SeatMapFacilityAvailability | null;
  row_number: number;
  row_index: number;
  column_index: number;
  characteristics: {
    value: string;
    padis_code: string;
  }[];
  window_exists: boolean;
  on_wing: boolean;
}

class SeatMapFacility {
  readonly type: SeatMapFacilityType;

  readonly seatCode: string;

  readonly availability: SeatMapFacilityAvailability | null;

  readonly rowNumber: number;

  readonly rowIndex: number;

  readonly columnIndex: number;

  readonly characteristics: {
    value: string;
    padisCode: string;
  }[];

  readonly isWindowExists: boolean;

  readonly isOnWing: boolean;

  selected: boolean;

  constructor(args: SeatMapFacilityJson) {
    this.type = args.type;
    this.seatCode = args.seat_code;
    this.availability = args.availability;
    this.rowNumber = args.row_number;
    this.rowIndex = args.row_index;
    this.columnIndex = args.column_index;
    this.characteristics = args.characteristics.map(c => ({
      value: c.value,
      padisCode: c.padis_code
    }));
    this.isWindowExists = args.window_exists;
    this.isOnWing = args.on_wing;
    this.selected = false;
  }
}

export default SeatMapFacility;
