import React from 'react';
import { styled } from '@this/constants/themes';

import type MarketLog from '@this/domain/market_log/market_log';
import { Text } from '@this/shared/ui/data_displays/typography';
import MarketLogSample from './market_log_sample';

type Props = {
  marketLog: MarketLog;
  available: boolean;
};

const MarketLogTemplate: React.FC<Props> = ({ marketLog, available }) => (
  <div className="merket-log__body">
    <BasicInfo>
      <Title>基本情報</Title>
      <BasicInfoContents>
        <BasicInfoItemWrapper>
          <BasicInfoItem>
            <BasicInfoLabel>予約者</BasicInfoLabel>
            <BasicInfoText>{marketLog.userName}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>承認者</BasicInfoLabel>
            <BasicInfoText>{marketLog.approverNames}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>出張者</BasicInfoLabel>
            <BasicInfoText>{marketLog.travelerNames}</BasicInfoText>
          </BasicInfoItem>
        </BasicInfoItemWrapper>
        <BasicInfoItemWrapper>
          <BasicInfoItem>
            <BasicInfoLabel>宿泊日</BasicInfoLabel>
            <BasicInfoText>{marketLog.term}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>施設名</BasicInfoLabel>
            <BasicInfoText>{marketLog.name}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>料金</BasicInfoLabel>
            <BasicInfoText>{marketLog.priceDesc}</BasicInfoText>
          </BasicInfoItem>
        </BasicInfoItemWrapper>
      </BasicInfoContents>
    </BasicInfo>
    {available ? (
      <>
        <Summary>
          <Title>サマリー</Title>
          <SummaryContents>
            <SummaryItem>
              <SummaryLabel>旅費規程</SummaryLabel>
              <SummaryText>{marketLog.hotelPriceLimitDesc()}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>当日の平均</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.averagePrice)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>申請時の価格</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.priceOrdered)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>旅費規程との差額</SummaryLabel>
              <SummaryText color={marketLog.isPriceLimitDiffPlus() ? 'danger' : 'success'}>
                {marketLog.priceLimitDiffDesc()}
              </SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>申請時からの価格上昇</SummaryLabel>
              <SummaryText color={marketLog.isPriceIncreasesPlus() ? 'danger' : 'success'}>
                {marketLog.priceIncreasesDesc()}
              </SummaryText>
            </SummaryItem>
          </SummaryContents>
        </Summary>
        <Candidates>
          <Title>その他の候補</Title>
          {marketLog.candidates.length > 0 ? (
            <CandidatesTable>
              <tbody>
                <tr>
                  <CandidatesNameTh>施設名</CandidatesNameTh>
                  <CandidatesTh>価格</CandidatesTh>
                  <CandidatesTh>目的地からの距離</CandidatesTh>
                </tr>
                {marketLog.candidates.map((c, i) => (
                  <tr key={String(i)}>
                    <td>{c.name}</td>
                    <td>{utils.formatPrice(c.price)}</td>
                    <td>{c.distanceDesc()}</td>
                  </tr>
                ))}
              </tbody>
            </CandidatesTable>
          ) : (
            <p>その他の候補が見つかりませんでした</p>
          )}
        </Candidates>
      </>
    ) : (
      <MarketLogSample />
    )}
  </div>
);

const BasicInfo = styled.div`
  margin-top: 10px;
  font-weight: bold;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const BasicInfoContents = styled.div`
  display: flex;
`;

const BasicInfoItemWrapper = styled.div`
  flex-grow: 1;
`;

const BasicInfoItem = styled.div`
  display: flex;
  font-size: 0.75em;
`;

const BasicInfoLabel = styled.p`
  flex-basis: 100px;
  background-color: #f7f7f7;
  text-align: center;
  padding: 5px 20px;
`;

const BasicInfoText = styled.p`
  padding: 5px;
`;

const Summary = styled.div`
  margin-top: 10px;
`;

const SummaryContents = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SummaryItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  text-align: center;
  background-color: #f7f7f7;
  padding: 10px 30px;
  margin: 0 2px;
`;

const SummaryLabel = styled.p`
  font-size: 0.75em;
`;

const SummaryText = styled(Text).attrs({ bold: true })`
  font-size: 1.3em;
`;

const Candidates = styled.div`
  margin-top: 10px;
`;

const CandidatesTable = styled.table`
  border-collapse: separate;
  border-spacing: 4px 0;
  table-layout: fixed;
`;

const CandidatesTh = styled.th`
  text-align: center;
  font-size: 0.75em;
  background-color: #f7f7f7;
`;

const CandidatesNameTh = styled.th`
  text-align: center;
  font-size: 0.75em;
  background-color: #f7f7f7;
  width: 60%;
`;

export default MarketLogTemplate;
