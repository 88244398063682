import _ from 'lodash';
import moment from 'moment';
import type { TicketOprResponseArgs } from '@this/domain/ticket_opr_response';
import type Trip from '../../../domain/trip/trip';
import type Traveler from '../../../domain/traveler/traveler';
import type TransportElementReservation from '../../../domain/transport_element_reservation';
import type TicketOprReservation from '../../../domain/ticket_opr_reservation';
import { formatToJPY } from '../../../lib/currency';

interface Info {
  status: string;
  flight: string;
  info?: string;
  ticketOprReservation?: TicketOprReservation;
  retryMessage?: string;
  errorMessage?: string;
}

interface SimpleTraveler {
  name: string;
  transports: Array<Info>;
}

interface TicketInfo {
  travelers: Array<SimpleTraveler>;
}

const convertSmartTicketTransports = (name: string, ter: TransportElementReservation): Info => {
  let flight = `${name || ''}`;
  let status;

  const {
    status: terStatus,
    retryMessage,
    errorMessage,
    smartTicketRsvId,
    smartTicketAirCompanyCode,
    smartTicketReservationNumber,
    smartTicketAmount,
    smartTicketExpiredDate,
    smartTicketTicketName,
    smartTicketAdultPrice,
    smartTicketTicketCode,
    failureType
  } = ter;

  const amountValue = smartTicketAmount
    ? formatToJPY(smartTicketAmount) || `大人価格=${formatToJPY(smartTicketAdultPrice) || ''}`
    : null;
  const ticketValue = smartTicketTicketName
    ? `${smartTicketTicketName || ''} ${smartTicketTicketCode ? `(${smartTicketTicketCode})` : ''}`
    : null;
  flight = `${name} ${smartTicketAirCompanyCode ? `(${smartTicketAirCompanyCode}）運行` : ''}`;
  const infoArray = [
    {
      title: 'スマチケ管理ID',
      value: smartTicketRsvId
    },
    {
      title: '予約番号',
      value: smartTicketReservationNumber
    },
    {
      title: '購入価格',
      value: amountValue
    },
    {
      title: '券種',
      value: ticketValue
    },
    {
      title: '支払い期限',
      value: smartTicketExpiredDate ? moment(smartTicketExpiredDate).format('YYYY/MM/DD') : ''
    }
  ];
  const description = infoArray
    .filter(({ value }) => value)
    .map(({ title, value }) => `${title}：${value || ''}`)
    .join('、');

  switch (terStatus) {
    case 'unsupport':
      status = '対象外';
      break;
    case 'waiting':
    case 'reserved': // <-- it's reserved but when getting the reervation info hasn't been gotten yet, the actual reservation is not fixed.
      status = '処理中...';
      break;
    case 'occupied':
    case 'failure':
      status = `失敗しました ${!failureType ? '' : `(${failureType})`}`;
      break;
    case 'reservation_info_gotten':
      status = '予約済み';
      break;
    default:
      status = '不明';
  }

  return {
    status,
    flight,
    info: description,
    retryMessage,
    errorMessage
  };
};

const convertTicketOprTransports = (
  name: string,
  ter: TicketOprReservation,
  ticketOprResponse: TicketOprResponseArgs[]
): Info => {
  let flight = `${name || ''}`;
  let status;

  const {
    status: terStatus,
    retryMessage,
    errorMessage,
    ticketOprRsvId,
    ticketOprAirCompanyCode,
    ticketOprReservationNumber,
    ticketOprAmount,
    ticketOprExpiredDate,
    ticketOprTicketName,
    ticketOprTicketCode,
    failureType
  } = ter;

  const validResponse = Array.isArray(ticketOprResponse) ? ticketOprResponse : [];
  const reservation = validResponse.find(item => item.ticket_opr_rsv_id === ticketOprRsvId);
  const reservationPrice = reservation
    ? formatToJPY(reservation.price)
    : ticketOprAmount
    ? formatToJPY(ticketOprAmount)
    : '';
  const ticketValue = ticketOprTicketName
    ? `${ticketOprTicketName || ''} ${ticketOprTicketCode ? `(${ticketOprTicketCode})` : ''}`
    : null;
  flight = `${name} ${ticketOprAirCompanyCode ? `(${ticketOprAirCompanyCode}）運行` : ''}`;
  const infoArray = [
    {
      title: 'TicketOpr予約ID',
      value: ticketOprRsvId
    },
    {
      title: '予約番号',
      value: ticketOprReservationNumber
    },
    {
      title: '発券期限',
      value: ticketOprExpiredDate ? moment(ticketOprExpiredDate).format('YYYY/MM/DD') : ''
    },
    {
      title: '券種',
      value: ticketValue
    },
    // TODO: localでの確認時、ticketOprAdultPriceが保存されなかったため一旦非表示（本番データ確認してもらった後修正予定）
    // {
    //   title: '予約記録',
    //   value: `空席予約依頼時金額=${ticketOprAdultPrice ? formatToJPY(ticketOprAdultPrice) : ''}`
    // },
    {
      title: '予約記録',
      value: `空席予約成功時金額=${reservationPrice}`
    }
  ];
  const description = infoArray
    .filter(({ value }) => value)
    .map(({ title, value }) => `${title}：${value || ''}`)
    .join('<br>');

  switch (terStatus) {
    case 'waiting':
    case 'reserved': // <-- it's reserved but when getting the reervation info hasn't been gotten yet, the actual reservation is not fixed.
      status = '処理中...';
      break;
    case 'occupied':
    case 'failure':
      status = `失敗しました ${!failureType ? '' : `(${failureType})`}`;
      break;
    case 'reservation_info_gotten':
      status = '予約済み';
      break;
    case 'ticketing_requested':
      status = '発券リクエスト済み';
      break;
    case 'ticket_issued':
      status = '発券済み';
      break;
    default:
      status = '不明';
  }

  return {
    status,
    flight,
    info: description,
    ticketOprReservation: ter,
    retryMessage,
    errorMessage
  };
};

export const getSmartTicketInfo = (trip: Trip, ticketOprResponse: TicketOprResponseArgs[]): TicketInfo => {
  // NOTE:
  // reservationレコードを使用してtraveler情報を取得するものと、そうでないものとを分離。
  // ここでの判定は厳密なものでない。
  // jobの中でreservationレコードを作成しているため、job実行が予約直後に呼びたせされなかった場合、
  // reservations.length == 0 となるが、その後にjob呼び出しによってレコードが作られる。
  // その為、自動予約実行対象のものも、一時的に自動予約サポート外です、と表示される可能性あり。
  let source = 0; // 0: trip, 1: smartTicket, 2: ticketOpr
  if (
    trip.order.orderItems.some(oi =>
      oi.transports.some(t => t.transportElementReservations && t.transportElementReservations.length > 0)
    )
  ) {
    source = 1;
  } else if (
    trip.order.orderItems.some(oi =>
      oi.transports.some(t => t.ticketOprReservations && t.ticketOprReservations.length > 0)
    )
  ) {
    source = 2;
  }

  let travelers: Array<SimpleTraveler> = [];
  const travelerList: Array<Traveler> = trip?.travelerInformations?.list || [];
  const initialTransports: Array<Info> = [];
  switch (source) {
    case 0:
      // use travelers from trip
      travelers = travelerList.map(({ firstNameKana, lastNameKana }) => ({
        name: `${lastNameKana || ''}${firstNameKana || ''}`,
        transports: trip.order.orderItems.reduce((transports, oi) => {
          if (!oi.transports || oi.transports.length === 0) {
            return transports;
          }
          const tps = oi.transports.map(t => ({
            status: '自動予約サポート外です',
            flight: `${t.name || ''}`
          }));
          return [...transports, ...tps];
        }, initialTransports)
      }));
      break;
    case 1:
      // use travelers from smartTicket
      _.each(trip.order.orderItems, oi => {
        if (!oi.transports || oi.transports.length === 0) {
          return;
        }

        _.each(oi.transports, t => {
          const { name: transportName } = t;
          const pushedTravelers = t.transportElementReservations.map(ter => {
            const { flightFirstName, flightLastName } = ter.travelerInformation;
            const transports = [convertSmartTicketTransports(transportName, ter)];

            return {
              name: `${flightLastName || ''}${flightFirstName || ''}`,
              transports
            };
          });
          travelers = [...travelers, ...pushedTravelers];
        });
      });
      break;
    case 2:
      // use travelers from ticketOpr
      _.each(trip.order.orderItems, oi => {
        if (!oi.transports || oi.transports.length === 0) {
          return;
        }

        _.each(oi.transports, t => {
          const { name: transportName } = t;
          const pushedTravelers = t.ticketOprReservations.map(ter => {
            const { flightFirstName, flightLastName } = ter.travelerInformation;
            const transports = [convertTicketOprTransports(transportName, ter, ticketOprResponse)];

            return {
              name: `${flightLastName || ''}${flightFirstName || ''}`,
              transports
            };
          });
          travelers = [...travelers, ...pushedTravelers];
        });
      });
      break;
    default:
      break;
  }
  return { travelers };
};
