import React from 'react';
import type { Moment } from 'moment';
import { SearchArea, SearchBlock, SearchLabel } from '@this/src/components/arrangement/todo_list/search_area';
import DateRangePicker from '@this/src/components/arrangement/todo_list/date_range_picker';

interface Props {
  reservationDateFrom?: Moment;
  reservationDateTo?: Moment;
  setReservationDateFrom: (reservationDateFrom?: Moment) => void;
  setReservationDateTo: (reservationDateTo?: Moment) => void;
}

const TotalSearchArea: React.FC<Props> = ({
  reservationDateFrom,
  reservationDateTo,
  setReservationDateFrom,
  setReservationDateTo
}) => {
  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>予約日</SearchLabel>
        <DateRangePicker
          from={reservationDateFrom}
          to={reservationDateTo}
          onFromChange={d => setReservationDateFrom(d)}
          onToChange={d => setReservationDateTo(d)}
        />
      </SearchBlock>
    </SearchArea>
  );
};

export default TotalSearchArea;
