import { Fetcher, camelToSnake, objectToFormData } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { reportError } from '@this/lib/bugsnag';
import Modal from '../../shared/modal/modal';

interface VirtualCounterResponse {
  signed_in: {
    signed_in: boolean;
    arranger: {
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      created_at: string;
      updated_at: string;
      invitation_token: string;
      invitation_created_at: string;
      invitation_sent_at: string;
      invitation_accepted_at: string;
      invitation_limit: string;
      invited_by_id: never;
      invited_by_type: never;
      invitations_count: number;
      service_id: string;
    };
  };
}
interface PasswordInput {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

interface UserInput extends PasswordInput {
  lastName: string;
  firstName: string;
}

interface State extends UserInput {
  editNotice: string | null;
  submitting: boolean;
  editErrors: (string | string[])[];
  arrangerId?: number;
  showEditRegistration: boolean;
  registrationSubmitting: boolean;
  editRegistrationErrors: (string | string[])[];
}

type UserInputHandler = (name: keyof UserInput) => (e: React.ChangeEvent<HTMLInputElement>) => void;

const expandErrors = ({ errors }: { errors: (string | string[])[] }) =>
  _.map(errors, error => {
    if (typeof error === 'string') {
      return error.split('\n').map(line => <p key={line}>{line}</p>);
    }
    return error[0];
  }).map((elm, i) => (
    <div className="error" key={i}>
      {elm}
    </div>
  ));

const PasswordInputOld = ({
  name,
  labelChild,
  state,
  handler
}: {
  name: keyof PasswordInput;
  labelChild: React.ReactNode;
  state: PasswordInput;
  handler: UserInputHandler;
}) => (
  <div className="user-edit__section">
    <label htmlFor={name} className="user-edit__left-label user-edit__pass-confirm">
      {labelChild}
    </label>
    <input
      type="password"
      className="user-edit__input"
      id={name}
      value={state[name]}
      placeholder="英(大小)数記号を含む8文字以上"
      onChange={handler(name)}
    />
  </div>
);

const PasswordInputNew = ({
  name,
  labelChild,
  state,
  handler
}: {
  name: keyof PasswordInput;
  labelChild: React.ReactNode;
  state: PasswordInput;
  handler: UserInputHandler;
}) => (
  <div className="user-edit__section">
    <label htmlFor={name} className="user-edit__left-label user-edit__pass-confirm">
      {labelChild}
    </label>
    <input
      type="password"
      className="user-edit__input"
      id={name}
      value={state[name]}
      placeholder="英(大小)数記号を含む8文字以上"
      onChange={handler(name)}
    />
  </div>
);

const PasswordInputConfirm = ({
  name,
  labelChild,
  state,
  handler
}: {
  name: keyof PasswordInput;
  labelChild: React.ReactNode;
  state: PasswordInput;
  handler: UserInputHandler;
}) => (
  <div className="user-edit__section">
    <label htmlFor={name} className="user-edit__left-label user-edit__pass-confirm">
      {labelChild}
    </label>
    <input
      type="password"
      className="user-edit__input"
      id={name}
      value={state[name]}
      placeholder="英(大小)数記号を含む8文字以上"
      onChange={handler(name)}
    />
  </div>
);

type Props = any;

export default class ArrangerUserEdit extends React.Component<Props, State> {
  state: State = {
    editNotice: null,
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
    submitting: false,
    editErrors: [],
    showEditRegistration: false,
    registrationSubmitting: false,
    editRegistrationErrors: [],
    lastName: '',
    firstName: ''
  };

  componentDidMount() {
    this.initPromise();
  }

  initPromise = async () => {
    const result = await Fetcher.get<VirtualCounterResponse>('/arrangement/virtual_counter.json');
    this.setState({
      arrangerId: result.signed_in.arranger.id,
      lastName: result.signed_in.arranger.last_name,
      firstName: result.signed_in.arranger.first_name
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      submitting: true,
      editErrors: [],
      editNotice: null
    });

    const params = this.stateToParamsWithKeys(['arrangerId', 'lastName', 'firstName']);
    try {
      await Fetcher.put('/arrangers/update_account', objectToFormData(params));
      this.setState({
        editErrors: [],
        editNotice: '変更を保存しました',
        submitting: false
      });
    } catch (error) {
      this.setState({ submitting: false });
      if (error.response.status === 400) {
        this.setState({ editErrors: error.response.data.errors });
      } else {
        this.setState({ editErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'] });
      }
    }
  };

  handleUpdateRegistrationSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      registrationSubmitting: true,
      editRegistrationErrors: [],
      editNotice: null
    });

    const params = this.stateToParamsWithKeys([
      'arrangerId',
      'currentPassword',
      'password',
      'passwordConfirmation'
    ]);
    try {
      await Fetcher.put('/arrangers', objectToFormData(params));
      this.setState({
        editNotice: 'パスワードを変更しました',
        editErrors: [],
        registrationSubmitting: false,
        showEditRegistration: false
      });
    } catch (error) {
      this.setState({ registrationSubmitting: false });
      if (error.response.status === 400) {
        this.setState({ editRegistrationErrors: error.response.data.errors });
      } else {
        this.setState({
          editRegistrationErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。']
        });
      }
    }
  };

  stateToParamsWithKeys = (keys: ('arrangerId' | keyof UserInput)[]) => {
    const params: { [key: string]: string | number } = {};
    keys.forEach(key => {
      params[`arranger[${camelToSnake(key)}]`] = (this.state as any)[key];
    });
    return params;
  };

  showEditRegistrationForm = () => this.setState({ showEditRegistration: true });

  hideEditRegistrationForm = () =>
    this.setState({
      showEditRegistration: false,
      editRegistrationErrors: []
    });

  change: UserInputHandler = name => e => {
    const rt = { [name]: e.target.value } as { [key in typeof name]: string };
    this.setState(rt);
  };

  render() {
    try {
      return (
        <div className="user-edit">
          <div className="content-body">
            <form onSubmit={this.handleSubmit}>
              <div className="user-edit__section">
                <label className="user-edit__left-label">氏名</label>
                <label className="user-edit__sub-label" htmlFor="lastName">
                  氏
                </label>
                <input
                  id="lastName"
                  type="text"
                  placeholder="例） 山田"
                  value={this.state.lastName}
                  className="user-edit__input-split"
                  onChange={this.change('lastName')}
                />
                <label className="user-edit__sub-label" htmlFor="firstName">
                  名
                </label>
                <input
                  id="firstName"
                  type="text"
                  placeholder="例） 一郎"
                  value={this.state.firstName}
                  className="user-edit__input-split"
                  onChange={this.change('firstName')}
                />
              </div>
              <Password data-testid="change-password-link" onClick={this.showEditRegistrationForm}>
                パスワードの変更
              </Password>
              {this.state.editNotice && (
                <div className="user-edit__notice">
                  <div className="notice">{this.state.editNotice}</div>
                </div>
              )}
              <div className="user-edit__errors">{expandErrors({ errors: this.state.editErrors })}</div>
              {this.state.submitting ? (
                <img className="user-edit__loading" src="user-edit__loading" width={50} height={50} />
              ) : (
                <div className="user-edit__button">
                  <input type="submit" value="保存" />
                </div>
              )}
            </form>
            {this.state.showEditRegistration && (
              <Modal
                hideModal={this.hideEditRegistrationForm}
                show={this.state.showEditRegistration}
                title="パスワードの変更"
              >
                <form onSubmit={this.handleUpdateRegistrationSubmit}>
                  <PasswordInputOld
                    name="currentPassword"
                    labelChild={[
                      <span key={0}>現在のパスワード</span>,
                      <span className="red" key={1}>
                        （必須）
                      </span>
                    ]}
                    handler={this.change}
                    state={this.state}
                  />
                  <PasswordInputNew
                    name="password"
                    labelChild="新しいパスワード"
                    handler={this.change}
                    state={this.state}
                  />
                  <PasswordInputConfirm
                    name="passwordConfirmation"
                    labelChild="新しいパスワード　（確認）"
                    handler={this.change}
                    state={this.state}
                  />
                  <div className="user-edit__modal-errors">
                    {expandErrors({ errors: this.state.editRegistrationErrors })}
                  </div>
                  <div className="user-edit__modal-submit">
                    <input type="submit" value="保存" disabled={this.state.registrationSubmitting} />
                  </div>
                  <a className="user-edit__modal-cancel" onClick={this.hideEditRegistrationForm}>
                    キャンセル
                  </a>
                </form>
              </Modal>
            )}
          </div>
        </div>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Password = styled.button.attrs({
  type: 'button'
})`
  padding: 5px;
  border: 1px solid ${props => props.theme.linkColor};
  color: ${props => props.theme.linkColor};
  text-align: center;
  width: 110px;
  border-radius: 5px;
  font-size: 10px;
  margin-bottom: 10px;
`;
