import type { Theme } from '@material-ui/core';
import MuiPagination from '@material-ui/lab/Pagination';
import type { PaginationProps } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import type { FunctionComponent } from 'react';
import React from 'react';
import { withTheme } from 'styled-components';

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    '& .Mui-selected': {
      'background-color': props => props.theme.linkColor
    },
    display: 'flex',
    justifyContent: 'center'
  }
});
interface Props extends Omit<PaginationProps, 'count' | 'color' | 'onChange' | 'page'> {
  currentPage: number;
  totalPage: number;
  handleSearch: (page: number) => void;
  theme: { linkColor: string };
}
interface StyleProps {
  theme: { linkColor: string };
}

const Pagination: FunctionComponent<Props> = ({ currentPage, totalPage, handleSearch, theme, ...props }) => {
  const classes = useStyles({ theme });
  const setPageNumber = (page: number) => {
    handleSearch(page);
  };

  return (
    <div className={classes.root}>
      <MuiPagination
        count={totalPage} // 総ページ数
        color="primary" // ページネーションの色
        onChange={(e, page) => setPageNumber(page)} // 変更されたときに走る関数。第2引数にページ番号が入る
        page={currentPage} // 現在のページ番号
        {...props}
      />
    </div>
  );
};

export default withTheme(Pagination);
