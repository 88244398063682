import type { FormEvent } from 'react';
import React, { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Flex } from '@this/shared/ui/layout/flex';
import { Button } from '@this/shared/ui/inputs/button';
import { Link } from '@this/shared/ui/navigations/link';
import { Modal, ModalBody, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { useBoolean } from '@this/shared/hooks/use_boolean';
import type { Message as MessageType } from '@this/components/trips_management/trips/trips_chat/types';
import { Checkbox } from '@this/shared/ui/inputs/checkbox';
import { MessageTemplateSelector } from './message_template_selector';
import type { EdittingAttachment } from './editting_attachments_field';
import { EdittingAttachmentsField } from './editting_attachments_field';
import { AttachmentsField } from './attachments_field';

export type VirtualCounterMessageFormData = {
  message: string;
  replyRequired: boolean;
  files: File[];
  deleteFiles: NonNullable<MessageType['attach_files']>;
};

type Props = {
  className?: string;
  edittingMessage: MessageType | null;
  selectedTrip: Trip | null;
  onSubmit: (formData: VirtualCounterMessageFormData) => Promise<boolean>;
  onClickEditCancel: () => void;
  serviceId: number;
} & Pick<React.ComponentProps<typeof MessageTemplateSelector>, 'messageTemplates'>;

export const VirtualCounterMessageForm = ({
  className,
  edittingMessage,
  selectedTrip,
  onSubmit,
  onClickEditCancel,
  messageTemplates,
  serviceId
}: Props) => {
  const [isOpen, { on, off }] = useBoolean(false);
  const [message, setMessage] = useState<string>(edittingMessage?.body ?? '');
  const [edittingAttachments, setEditingAttachments] = useState<EdittingAttachment[]>(
    edittingMessage?.attach_files ?? []
  );
  const [files, setFiles] = useState<File[]>([]);
  const [replyRequired, setReplyRequired] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  React.useEffect(() => {
    setMessage(edittingMessage?.body ?? '');
    setEditingAttachments(edittingMessage?.attach_files ?? []);
  }, [edittingMessage]);

  const handleMessageSelect = useCallback(message => {
    setMessage(message);
  }, []);

  const handleAttachmentChange = useCallback((attachments: File[]) => {
    setFiles(attachments);
  }, []);

  const handleOpenModal = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      on();
    },
    [on]
  );

  const handleToggleEdittingAttachments = useCallback(
    (attachment: EdittingAttachment) => {
      attachment.isDelete = !attachment.isDelete;
      setEditingAttachments([...edittingAttachments]);
    },
    [edittingAttachments]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (sending) {
        return;
      }

      setSending(true);
      try {
        const res = await onSubmit({
          message,
          replyRequired,
          files,
          deleteFiles: edittingAttachments.filter(f => f.isDelete)
        });
        if (res) {
          setMessage('');
          setReplyRequired(false);
          setFiles([]);
          setEditingAttachments([]);
        }
      } finally {
        setSending(false);
      }
    },
    [sending, message, replyRequired, files, onSubmit, edittingAttachments]
  );

  return (
    <Root className={className}>
      <form onSubmit={handleSubmit}>
        <Flex gap="8px">
          <FormFieds>
            <div>
              <Textarea
                name="message"
                value={message}
                placeholder="メッセージを入力してください"
                onChange={e => setMessage(e.target.value)}
              />
              <Flex justifyContent="flex-end">
                <Link size="small" onClick={handleOpenModal}>
                  大きく表示
                </Link>
              </Flex>
            </div>
            <AttachmentsFieldWrapper>
              {edittingMessage && edittingAttachments.length > 0 && (
                <EdittingAttachmentsField
                  tripId={edittingMessage.trip_id}
                  attachments={edittingAttachments}
                  onClickToggleDelete={handleToggleEdittingAttachments}
                />
              )}
              <AttachmentsField onChange={handleAttachmentChange} />
            </AttachmentsFieldWrapper>
          </FormFieds>

          <Flex flexDirection="column" gap="4px">
            <Button type="submit" loading={sending} disabled={!message}>
              送信
            </Button>
            <Checkbox checked={replyRequired} onChange={e => setReplyRequired(e.target.checked)}>
              <b style={{ fontSize: '12px' }}>要返信</b>
            </Checkbox>
            {edittingMessage && (
              <Button type="button" color="sub" onClick={onClickEditCancel}>
                キャンセル
              </Button>
            )}
          </Flex>
        </Flex>
      </form>

      <MessageTemplateSelector
        messageTemplates={messageTemplates}
        selectedTrip={selectedTrip}
        onSubmit={handleMessageSelect}
        serviceId={serviceId}
      />

      <Modal open={isOpen} onClose={off}>
        <ModalHeader>メッセージの編集</ModalHeader>
        <ModalBody>
          <TextareaAutosize minRows={10} value={message} onChange={e => setMessage(e.target.value)} />
        </ModalBody>
      </Modal>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FormFieds = styled.div`
  flex: 1;
`;

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  max-height: 35vh;
  min-height: 35px;
  resize: vertical;
  margin-bottom: 0 !important; // global cssの上書き
`;

const AttachmentsFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: -20px;
`;
