import React, { useReducer, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import Box from '@material-ui/core/Box';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { Text } from '@this/src/components/shared/ui/data_displays/typography/text';
import { Fetcher } from '@this/src/util';
import type { MailSendLogArgs } from '@this/src/domain/mail_send_log';
import MailSendLog from '@this/src/domain/mail_send_log';
import MailSendLogsTableRow from './mail_send_logs_table_row';
import MailSendLogsSearchArea, { urlQueryReducer, initialUrlQuery } from './mail_send_logs_search_area';
import type { UrlQuery, UrlQueryAction } from './mail_send_logs_search_area';
import useOrganizationSelector from '../todo_list/use_organization_selector';

interface State {
  mailSendLogs: MailSendLog[];
  urlQuery: UrlQuery;
  loading: boolean;
}

interface MailSendLogsResponse {
  mail_send_logs: MailSendLogArgs[];
}

type Action =
  | UrlQueryAction
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_RESPONSE'; payload: { response: MailSendLogsResponse } }
  | { type: 'SET_ERROR'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TRIP_ID':
    case 'SET_SUBJECT':
    case 'SET_MAIL_TO':
    case 'SET_CC':
      return { ...state, urlQuery: urlQueryReducer(state.urlQuery, action) };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_RESPONSE':
      return {
        ...state,
        mailSendLogs: action.payload.response.mail_send_logs.map(raw => new MailSendLog(raw)),
        loading: false,
        isError: false
      };
    case 'SET_ERROR':
      return {
        ...state,
        mailSendLogs: [],
        loading: false,
        isError: true
      };
    default:
      return state;
  }
};

interface State {
  mailSendLogs: MailSendLog[];
  urlQuery: UrlQuery;
  loading: boolean;
  isError: boolean;
}

const initialState: State = {
  mailSendLogs: [],
  urlQuery: initialUrlQuery,
  loading: false,
  isError: false
};

const MailSendLogs: React.FC = observer(() => {
  const { current: history } = useRef(useHistory());
  const [state, dispatch] = useReducer(reducer, initialState);
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();

  const { mailSendLogs, urlQuery, loading, isError } = state;

  const fetchMailSendLogs = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });

    const params = {
      ...urlQuery,
      organization_ids: organizationIds.join(',')
    };
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined) delete params[key as keyof typeof params];
    });
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    history.push(`/arrangement/mail_send_logs?${query}`);

    await Fetcher.get<MailSendLogsResponse>('/arrangement/mail_send_logs.json', params)
      .then(response => {
        dispatch({ type: 'SET_RESPONSE', payload: { response } });
      })
      .catch(() => {
        dispatch({ type: 'SET_ERROR', payload: true });
      });
  }, [history, urlQuery, organizationIds]);

  return (
    <>
      <MailSendLogsSearchArea
        urlQuery={urlQuery}
        dispatch={dispatch}
        renderOrganizationSelector={renderOrganizationSelector}
        fetchMailSendLogs={fetchMailSendLogs}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {!isError ? (
            <>
              <Box m={2}>
                <Text>検索条件を入力して検索ボタンを押してください。</Text>
              </Box>
              <Box m={2}>
                <Text level="body" bold>
                  ログ: {mailSendLogs.length}件 （100件まで表示）
                </Text>
              </Box>
            </>
          ) : (
            <Box m={2}>
              <Text color="danger" level="body" bold>
                エラーが発生しました。
              </Text>
            </Box>
          )}
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>旅程番号ID</TableCell>
                <TableCell>組織</TableCell>
                <TableCell>メール件名</TableCell>
                <TableCell>メール本文</TableCell>
                <TableCell>宛先To</TableCell>
                <TableCell>宛先CC</TableCell>
                <TableCell>送信日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mailSendLogs.map(mailSendLog => (
                <MailSendLogsTableRow key={mailSendLog.id} mailSendLog={mailSendLog} />
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
});

export default MailSendLogs;
