import { observable } from 'mobx';

export interface AutoReservationSettingArgs {
  id: number;
  service_name: string;
  active: boolean;
}

export class AutoReservationSetting {
  @observable id: number;

  @observable serviceName: string;

  @observable active: boolean;

  constructor(args: AutoReservationSettingArgs) {
    this.id = args.id;
    this.serviceName = args.service_name;
    this.active = args.active;
  }

  submitParams(): AutoReservationSettingArgs {
    return {
      id: this.id,
      service_name: this.serviceName,
      active: this.active
    };
  }
}
