export interface TripRuleDistanceTimeInfoArgs {
  code: Code;
  detail: Detail;
}

export type Code = 'ALLOWED_DISTANCE' | 'ALLOWED_TIME';

export type Detail = {
  actual: number;
  limit: number;
  lines?: string[];
};

class TripRuleDistanceTimeInfo {
  code: Code;

  detail: Detail;

  constructor(args: TripRuleDistanceTimeInfoArgs) {
    this.code = args.code || '';
    this.detail = args.detail || { actual: 0, limit: 0 };
  }

  ruleDistanceTimeInfoText = () => {
    switch (this.code) {
      case 'ALLOWED_TIME':
        return `宿泊可能の移動時間(${this.detail.actual}時間, 下限:${this.detail.limit}時間)`;
      case 'ALLOWED_DISTANCE':
        return `宿泊可能の移動距離(${this.detail.actual}km, 下限:${this.detail.limit}km)`;
      default:
        return '';
    }
  };
}

export default TripRuleDistanceTimeInfo;
