import type { Moment } from 'moment';
import moment from 'moment';
import type { CsvImportActionItemArgs } from './csv_import_action_item';
import CsvImportActionItem from './csv_import_action_item';

type Status = 'enqueued' | 'running' | 'success' | 'fail';

const StatusMessage: { [key in Status]: string } = {
  enqueued: '読み込み中',
  running: '実行中',
  success: '完了',
  fail: '失敗'
};

export interface CsvImportActionArgs {
  id: number;
  status: Status;
  filename: string;
  type: string;
  created_at: string;
  csv_import_action_items?: CsvImportActionItemArgs[];
}

export default class CsvImportAction {
  id: number;

  status: Status;

  filename: string;

  type: string;

  createdAt: Moment;

  csvImportActionItems: CsvImportActionItem[];

  constructor(args: CsvImportActionArgs) {
    this.id = args.id;
    this.status = args.status;
    this.filename = args.filename;
    this.type = args.type;
    this.createdAt = moment(args.created_at);
    this.csvImportActionItems = args.csv_import_action_items
      ? args.csv_import_action_items.map(item => new CsvImportActionItem(item))
      : [];
  }

  get statusMessage() {
    return StatusMessage[this.status];
  }
}
