import React from 'react';
import { styled } from '@this/constants/themes';
import type Hotel from '@this/src/domain/hotel/hotel';
import type HotelList from '@this/src/domain/hotel/hotel_list';
import HotelCandidateBox from '@this/components/reserve_trip/select/hotel_box/hotel_candidate_box';

interface Props {
  hotel: Hotel;
  hotels: HotelList;
  hotelPriceLimit: number | null;
  searchQueryId: number | null;
  selected: boolean;
  visible: boolean;
  onClose: () => void;
  handleHotelSelect: (hotel: Hotel) => void;
}

const HotelDetailOnMap = ({
  hotel,
  hotels,
  hotelPriceLimit,
  searchQueryId,
  selected,
  visible,
  onClose,
  handleHotelSelect
}: Props) => {
  return (
    <Wrapper visible={visible}>
      <HotelCandidateBox
        hotel={hotel}
        hotels={hotels}
        selected={selected}
        hotelPriceLimit={hotelPriceLimit}
        searchQueryId={searchQueryId}
        showCloseButton
        onClose={onClose}
        handleSelect={handleHotelSelect}
      />
    </Wrapper>
  );
};

export default HotelDetailOnMap;

const Wrapper = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  margin: 0;
`;
