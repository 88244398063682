import * as React from 'react';
import { marked } from 'marked';
import { sanitize } from 'dompurify';
import { styled } from '@this/constants/themes';

interface Props {
  markdownText: string;
  openLinksNewTab?: boolean;
}
const Markdown: React.FC<Props> = ({ markdownText, openLinksNewTab = false }) => {
  const renderer = new marked.Renderer();
  renderer.link = (href, title, text) => {
    const target = openLinksNewTab ? 'target="_blank"' : '';
    const rel = openLinksNewTab ? 'rel="noopener noreferrer"' : '';
    return `<a ${target} ${rel} href="${href}" title="${title}">${text}</a>`;
  };

  const htmlText = sanitize(marked.parse(markdownText, { renderer, breaks: true, gfm: true }), {
    ADD_ATTR: ['target', 'rel']
  });
  return <Section dangerouslySetInnerHTML={{ __html: htmlText }} />;
};

const Section = styled.section`
  a {
    color: ${props => props.theme.linkColor};
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  strong {
    font-weight: bold;
  }

  pre {
    word-break: normal;

    code {
      background: transparent;
      border: 0;
      font-size: 100%;
      margin: 0;
      padding: 0;
      white-space: pre;
      word-break: normal;
    }
  }

  code {
    background-color: #f7f7f7;
    border-radius: 3px;
    font-size: 85%;
    margin: 0;
    padding: 2px 4px;
    border: 1px solid #eee;
  }

  ol,
  ul {
    padding-left: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    word-wrap: break-word;

    > p {
      margin-top: 16px;
    }

    + li {
      margin-top: 2px;
    }
  }

  blockquote {
    margin: 0 0 10px 0;
    border-left: 2px solid #eee;
    background-color: #f7f7f7;
    color: #aaa;
    padding: 5px 10px;

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 20px;
  }

  h1,
  h2 {
    border-bottom: 1px solid #eee;
    padding-bottom: 3px;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 12px;
  }

  h4 {
    font-size: 10px;
  }

  h5 {
    font-size: 8px;
  }
`;

export default Markdown;
