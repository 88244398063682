import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { Link as baseLink } from 'react-router-dom';
import { css } from 'styled-components';
import type Arranger from '@this/src/domain/arranger/arranger';
import { getColor } from '@this/shared/ui/theme';

const headerHeight = '50px';

interface Props {
  arranger: Arranger | null;
  serviceId: number;
}

interface State {}

class ArrangementHeader extends React.Component<Props, State> {
  render() {
    const { arranger, serviceId } = this.props;

    try {
      return (
        <TheHeader className="noprint">
          <HeaderIn>
            <h1>
              <Logo to={arranger ? '/arrangement/todo_list' : '/'} />
            </h1>
            <LinkArea>
              {arranger ? (
                <>
                  <LinkButton to="/arrangement/todo_list">TODO</LinkButton>
                  <LinkButton to="/arrangement/virtual_counter">手配</LinkButton>
                  <LinkButton to="/arrangement/send_list">送付リスト</LinkButton>
                  <LinkButton to="/arrangement/send_list2">送付リスト2</LinkButton>
                  <LinkButton to="/arrangement/yamato_csv_import_actions">配送料登録</LinkButton>
                  <LinkButton to="/arrangement/shareholder_tickets">株優</LinkButton>
                  <LinkButton to="/arrangement/order_histories">履歴</LinkButton>
                  <LinkButton to="/arrangement/message_templates">テンプレート</LinkButton>
                  <LinkButton to="/arrangement/hotel_reservations">ホテル</LinkButton>
                  <LinkButton to="/arrangement/search_pnr_flights">航空券</LinkButton>
                  <LinkButton to="/arrangement/search_flights">海外GDS検索</LinkButton>
                  <LinkButton to="/arrangement/search_trackings">旅程検索ログ</LinkButton>
                  {utils.isAiTravel(serviceId) && (
                    <LinkButton to="/arrangement/mail_send_logs">メール送信ログ</LinkButton>
                  )}
                  <SignOutButton id="logout" href="/arrangers/sign_out">
                    ログアウト
                  </SignOutButton>
                </>
              ) : (
                <SignInButton href="/arrangers/sign_in" selected={false}>
                  ログイン
                </SignInButton>
              )}
            </LinkArea>
          </HeaderIn>
        </TheHeader>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const TheHeader = styled.header`
  width: 100%;
  height: ${headerHeight};
`;

const HeaderIn = styled.div`
  display: flex;
  align-items: center;
  background: ${getColor('background', 'tertiary')};
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  height: ${headerHeight};
`;

const Logo = styled(Link)`
  display: block;
  width: 120px;
  height: 21px;
  margin-left: 10px;
  background-image: url('${props => props.theme.logoPath}');
  background-repeat: no-repeat;
  background-size: contain;
`;

const LinkArea = styled.div`
  display: flex;
  margin-left: auto;
`;

const HeaderAStyle = css`
  display: block;
  margin-right: 15px;
  color: ${getColor('brand', 'primary')};
  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.linkFocusColorLight};
  }
`;

const LinkButton = styled(baseLink)`
  ${HeaderAStyle}
`;

const SignOutButton = styled.a`
  ${HeaderAStyle}
`;

const SignInButton = styled.a<{ selected: boolean }>`
  ${HeaderAStyle};
  color: ${props => (props.selected ? props.theme.tokens.colors.brand.primary : 'white')};
`;

export default ArrangementHeader;
