import React from 'react';

import { styled } from '@this/constants/themes';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import type User from '@this/domain/user/user';

interface Props {
  serviceId?: number | null;
  user?: User;
}

interface State {
  user: User | null;
  serviceId: number;
}

class NotFound extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: props.user || null,
      serviceId: props.serviceId || 1
    };
  }

  render() {
    try {
      const { user, serviceId } = this.state;
      return (
        <Wrapper>
          <Header user={user} ignoreMenu={false} />
          <Content>
            <Title>404 Page Not Found</Title>
            <Section>
              <p>ご指定のページが見つかりません。</p>
            </Section>
            <Section>
              <p>ご利用いただきありがとうございます。</p>
              <p>申し訳ございませんが、ご指定のページが</p>
              <p>存在しない可能性があります。</p>
            </Section>
            <Section>
              <a href="/">トップページに戻る</a>
            </Section>
          </Content>
          <Footer signedIn={!!user} serviceId={serviceId} ignoreMenu={false} />
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  min-height: calc(100vh - 50px - 74px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin: 100px 0 20px;
  font-size: 1.5em;
`;

const Section = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default NotFound;
