import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Link } from '@this/shared/ui/navigations/link';

import moment from 'moment';

import Pagination from '@this/src/components/shared/pagination/pagination';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';

const StickyTable = require('react-sticky-table').StickyTable;
const Row = require('react-sticky-table').Row;
const Cell = require('react-sticky-table').Cell;

const PaginationWrapper = styled.div`
  padding: 40px;
`;

export const Title = styled.h2`
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const SearchQueryItemTable = styled(StickyTable)`
  margin: 0;
  table-layout: fixed;
  height: 80vh;
`;

const SearchQueryItemTh = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
  ${props => props.width && `width: ${props.width}px;`}
`;

const SearchQueryItemTd = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;

  .MuiButton-root {
    margin: 0 4px;
  }
`;

type SearchQueryItemList = {
  id: string;
  search_query_id: string;
  destination: string;
  destination_address: string;
  item_type: string;
  pair: string;
  departure_time: string;
  departure_time_type: number;
  return_time: string;
  return_time_type: number;
  hotel_price: number;
  seat: number;
  smoke: boolean;
  breakfast: null;
  roomnum: number;
  peoplenum: number;
  created_at: string;
  updated_at: string;
  prefecture_id: number;
  destination_lng: number;
  destination_lat: number;
  municipality_code: number;
}[];
const useHotelOperations = (currentPage: number, setTotalPage: any, setSearchQueryItems: any, setLoading: any) => {
  const fetchSearchQueryItems = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get<{
        search_query_item_list: SearchQueryItemList;
        page: number;
        total_pages: number;
      }>('/god/search_query_items', { params: { page: currentPage } });
      setSearchQueryItems(response.data.search_query_item_list);
      setTotalPage(response.data.total_pages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  }, [currentPage, setLoading, setSearchQueryItems, setTotalPage]);

  return { fetchSearchQueryItems };
};

const SearchQueryItems = () => {
  const [searchQueryItems, setSearchQueryItems] = useState<SearchQueryItemList>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1); // 総ページ数
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchSearchQueryItems } = useHotelOperations(currentPage, setTotalPage, setSearchQueryItems, setLoading);

  useEffect(() => {
    fetchSearchQueryItems();
  }, [currentPage, fetchSearchQueryItems]);

  return (
    <div>
      <Title>レベニューマネジメント（検索クエリアイテム一覧）</Title>
      {loading ? (
        <Loading />
      ) : (
        <Content>
          <PaginationWrapper>
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              handleSearch={page => setCurrentPage(page)}
            />
          </PaginationWrapper>
          <SearchQueryItemTable>
            <Row>
              <SearchQueryItemTh>SearchQueryItemID</SearchQueryItemTh>
              <SearchQueryItemTh>SearchQueryID</SearchQueryItemTh>
              <SearchQueryItemTh>目的地</SearchQueryItemTh>
              <SearchQueryItemTh>住所</SearchQueryItemTh>
              <SearchQueryItemTh>緯度経度</SearchQueryItemTh>
              <SearchQueryItemTh>作成日</SearchQueryItemTh>
              <SearchQueryItemTh>詳細</SearchQueryItemTh>
            </Row>
            {searchQueryItems.map((searchQueryItem, index) => (
              <Row key={index}>
                <SearchQueryItemTd>{searchQueryItem.id}</SearchQueryItemTd>
                <SearchQueryItemTd>{searchQueryItem.search_query_id}</SearchQueryItemTd>
                <SearchQueryItemTd>{searchQueryItem.destination}</SearchQueryItemTd>
                <SearchQueryItemTd>{searchQueryItem.destination_address}</SearchQueryItemTd>
                <SearchQueryItemTd>
                  {searchQueryItem.destination_lat},{searchQueryItem.destination_lng}
                </SearchQueryItemTd>
                <SearchQueryItemTd>
                  {moment(searchQueryItem.created_at).format('YYYY-MM-DD HH:mm:ss')}
                </SearchQueryItemTd>
                <SearchQueryItemTd>
                  <Link href={`search_query_items_manage/${searchQueryItem.id}`}>詳細</Link>
                </SearchQueryItemTd>
              </Row>
            ))}
          </SearchQueryItemTable>
          <PaginationWrapper>
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              handleSearch={page => setCurrentPage(page)}
            />
          </PaginationWrapper>
        </Content>
      )}
    </div>
  );
};

export default SearchQueryItems;
