import React, { useMemo } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Link } from '@this/shared/ui/navigations/link';
import { Flex } from '@this/shared/ui/layout/flex';
import Tooltip from '@this/shared/tooltip/tooltip';
import { getSpacing } from '@this/shared/ui/theme';

type Props = {
  className?: string;
  trip: Trip;
};

export const ReservationUrls = ({ className, trip }: Props) => {
  const packageBookingUrl = useMemo(() => trip.order.packageBookingUrl(), [trip]);
  const hotelReservations = useMemo(() => trip.order.hotelReservations() ?? [], [trip]);

  return (
    <Root className={className}>
      <h3>ホテル/パッケージ予約</h3>

      {packageBookingUrl ? (
        <>
          <Flex>
            <Link href={packageBookingUrl} target="_blank" isExternal>
              パッケージを予約
            </Link>
            <Tooltip place="right">
              <p>リンク先の在庫がない場合は、代替パッケージの検索リンクから商品を探してください</p>
            </Tooltip>
          </Flex>
          {hotelReservations.length > 0 && (
            <Link href={hotelReservations[0].reserveUrl} target="_blank" isExternal>
              代替パッケージの検索
            </Link>
          )}
        </>
      ) : (
        hotelReservations && (
          <Flex flexDirection="column" gap="4px">
            {hotelReservations.map((r, i) => (
              <div key={i.toString()}>
                <p>{i + 1}つ目のホテル</p>
                <ReserveUrlList>
                  <ReserveUrlListItem>
                    <Link href={r.reserveUrl} target="_blank" isExternal>
                      ホテルを予約(全体)
                    </Link>
                    <Link href={r.singleReserveUrl} target="_blank" isExternal>
                      ホテルを予約(1名ずつ)
                    </Link>
                    <Link href={r.alternativePlanUrl} target="_blank" isExternal>
                      代替プランを探す
                    </Link>
                  </ReserveUrlListItem>
                </ReserveUrlList>
              </div>
            ))}
          </Flex>
        )
      )}
    </Root>
  );
};
const Root = styled.div``;

const ReserveUrlList = styled.ul`
  margin-left: ${getSpacing(2)}px;
`;

const ReserveUrlListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
