import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import Department from '../../../../../../domain/department/department';
import type User from '../../../../../../domain/user/user';
import type Traveler from '../../../../../../domain/traveler/traveler';

import DepartmentsList from './departments_list/departments_list';

type Props = {
  departments: Department[];
  user: User | Traveler;
  onSelect: (department: Department) => void;
  disabledAddress: boolean;
  allowBlank?: true;
  isRequired: boolean;
  workflowFormWidth: string;
  defaultDepartment?: Department | null;
};

type State = {
  showDepartmentsList: boolean;

  selected?: Department;
};

interface HTMLElementEvent<T extends EventTarget> extends Event {
  target: T;
}

class SelectDepartments extends React.Component<Props, State> {
  private selectAreaRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = { showDepartmentsList: false };
  }

  componentDidMount() {
    if (this.props.departments.length > 0) {
      const selected =
        this.props.defaultDepartment ||
        this.defaultDepartment(this.props.user) ||
        (this.props.allowBlank ? undefined : this.props.departments[0]);
      if (selected) {
        this.setState({ selected }, () => this.onSelect(selected));
      }
    }

    document.addEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  handleClickEvent(e: HTMLElementEvent<HTMLElement>) {
    // ref内にクリックされたeventのDOMが含まれているかを確認する
    if (this.selectAreaRef && this.selectAreaRef.current && !this.selectAreaRef.current.contains(e.target)) {
      this.setState({ showDepartmentsList: false });
    }
  }

  handleDepartmentSelect = (department: Department) => {
    this.setState({
      showDepartmentsList: false,
      selected: department
    });
    return this.onSelect(department);
  };

  toggleDepartmentsList = (e: React.MouseEvent) => {
    e.preventDefault();
    return this.setState({ showDepartmentsList: !this.state.showDepartmentsList });
  };

  private onSelect(department: Department) {
    return this.props.onSelect(department);
  }

  private defaultDepartment(user: User | Traveler) {
    const department = new Department(user.department);
    return _.find(this.props.departments, d => d.id === department.id);
  }

  render() {
    const { isRequired } = this.props;
    try {
      return (
        <SelectDepartmentsWrapper workflowFormWidth={this.props.workflowFormWidth} ref={this.selectAreaRef}>
          <SelectDepartmentsListItem data-testid="fs_open_box" onClick={this.toggleDepartmentsList}>
            {this.state.selected ? (
              <SelectDepartmentsItemSelected>
                <p>
                  {this.props.disabledAddress
                    ? this.state.selected.description()
                    : this.state.selected.descriptionWithCode()}
                </p>
              </SelectDepartmentsItemSelected>
            ) : (
              <SelectDepartmentsItemMessage>
                {isRequired ? '(必須)' : '(任意)'}選択してください
              </SelectDepartmentsItemMessage>
            )}
            <SelectDepartmentsTriangle>{this.state.showDepartmentsList ? '▲' : '▼'}</SelectDepartmentsTriangle>
          </SelectDepartmentsListItem>
          {this.state.showDepartmentsList && (
            <DepartmentsList
              departments={this.props.departments}
              onSelect={this.handleDepartmentSelect}
              disabledAddress={this.props.disabledAddress}
              workflowFormWidth={this.props.workflowFormWidth}
            />
          )}
        </SelectDepartmentsWrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const SelectDepartmentsWrapper = styled.div<{ workflowFormWidth?: string }>`
  width: ${props => props.workflowFormWidth || '100%'};
  font-size: 13px;
  margin-bottom: 5px;
  line-height: normal;
`;

const SelectDepartmentsListItem = styled.div`
  display: flex;
  padding: 5px 10px;
  border: 2px solid ${props => props.theme.grayBorderColor};
  cursor: pointer;
`;

const SelectDepartmentsItemSelected = styled.div`
  display: flex;
  flex: wrap;
`;

const SelectDepartmentsItemMessage = styled.p`
  color: $black-color;
`;

const SelectDepartmentsTriangle = styled.div`
  margin-left: auto;
`;

export default SelectDepartments;
