import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type SuppliedItem from '@this/domain/supplied_item/supplied_item';

interface Props {
  suppliedItems: SuppliedItem[];
  self?: SuppliedItem | null;
  onSelect: (id: number) => void;
}

interface State {
  filteredSuppliedItems: SuppliedItem[];
}

const OrderItemSupplierItemSelectList: React.FC<Props> = ({ suppliedItems, self, onSelect }) => {
  const input: React.RefObject<HTMLInputElement> = React.createRef();
  const [state, setState] = useState<State>({
    filteredSuppliedItems: suppliedItems
  });

  const handleSearchSupplierItems = (_e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyword = input.current!.value;
    const newState = { ...state };
    newState.filteredSuppliedItems = _.filter(suppliedItems, (s: SuppliedItem) => s.name.indexOf(keyword) !== -1);
    setState(newState);
  };

  const handleSelect = (id: number) => {
    onSelect(id);
  };

  const description = (s: SuppliedItem): string => {
    return `(${s.supplierCode})${s.supplierName} (${s.code})${s.name}`;
  };

  return (
    <>
      <Input type="search" ref={input} placeholder="検索" onKeyUp={handleSearchSupplierItems} />
      <ul>
        <ListItem onClick={() => handleSelect(-1)}>選択を外す</ListItem>
        {state.filteredSuppliedItems.map((s, i) => (
          <ListItem key={i} onClick={() => handleSelect(s.id)}>
            {description(s)}
          </ListItem>
        ))}
      </ul>
    </>
  );
};

const Input = styled.input`
  &&& {
    margin-bottom: 0;
  }
`;

const ListItem = styled.li`
  background: ${props => props.theme.fieldBgColor};
  line-height: 20px;
  padding: 5px 10px;
  cursor: pointer;
  overflow-wrap: break-word;
`;

export default OrderItemSupplierItemSelectList;
