import type { ChatbotHistoryResponseArgs } from '@this/src/domain/chatbot/chatbot_history';

export interface HistoriesResponse {
  next_id: number | null;
  has_more: boolean;
  chatbot_sessions: ChatbotHistoryResponseArgs[];
}

interface Props {
  nextId: number | null;
  onSuccess?: (res: HistoriesResponse) => void;
}

const fetchHistoriesApi = ({ nextId, onSuccess }: Props) => {
  utils.jsonPromise<HistoriesResponse>('/chatbot/histories', { next_id: nextId }).then(res => onSuccess?.(res));
};

export default fetchHistoriesApi;
