export interface DistanceSettingArgs {
  calc_mode: string;
  distance_limit: number | null;
  time_limit: number | null;
  allowance_distance_limit: number | null;
  starting_point: string;
}

class DistanceSetting {
  public calcMode: string;

  public distanceLimit: number | null;

  public timeLimit: number | null;

  public allowanceDistanceLimit: number | null;

  public startingPoint: string;

  constructor(args: DistanceSettingArgs) {
    this.calcMode = args.calc_mode;
    this.distanceLimit = args.distance_limit;
    this.timeLimit = args.time_limit;
    this.allowanceDistanceLimit = args.allowance_distance_limit;
    this.startingPoint = args.starting_point;
  }

  update<T extends keyof this>(name: T, value: this[T]) {
    this[name] = value;
    return this;
  }

  submitParams() {
    return {
      calc_mode: this.calcMode,
      distance_limit: this.distanceLimit || null,
      time_limit: this.timeLimit || null,
      allowance_distance_limit: this.allowanceDistanceLimit || null,
      starting_point: this.startingPoint
    };
  }

  static initialize() {
    return new DistanceSetting({
      calc_mode: 'direct',
      distance_limit: null,
      time_limit: null,
      allowance_distance_limit: null,
      starting_point: 'organization_base'
    });
  }
}

export default DistanceSetting;
