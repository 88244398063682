import { styled } from '@this/constants/themes';

export const FormTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FormField = styled.div`
  & + & {
    margin-top: 5px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px !important;
  }
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
  margin-right: 0 !important;
`;
