/* eslint-disable max-classes-per-file */
// https://gist.github.com/kui/3a2831fe5081d3870501

// const queue = new AsyncQueue(3);
// const workers = asyncWorkers(queue, 3);
// queue.enqueue(next => {
//   // Callback
//   next();
// });

export type PlaneCallback = () => void;
export type NextCallback = PlaneCallback;
export type TaskCallback = (next: NextCallback) => void;
export type QueuingCallback = (task: TaskCallback) => void;
export type ErrorHandler = (e: any) => boolean;

class Latch {
  subscribers: PlaneCallback[] = [];

  notify() {
    const s = this.subscribers.shift();
    if (s) s();
  }

  add(s: () => void) {
    this.subscribers.push(s);
  }
}

export class AsyncQueue {
  max: number;

  q: TaskCallback[] = [];

  underMax = new Latch();

  notEmpty = new Latch();

  constructor(max?: number) {
    this.max = max || 0;
  }

  enqueue(e: TaskCallback, callback?: PlaneCallback) {
    const f = () => {
      this.q.push(e);
      if (callback) callback();
      this.notEmpty.notify();
    };

    if (this.max <= 0 || this.getSize() < this.max) {
      f();
    } else {
      this.underMax.add(f);
    }

    return this;
  }

  dequeue(callback?: QueuingCallback) {
    const f = () => {
      const e = this.q.shift();
      if (callback && e) callback(e);
      if (this.max <= 0 || this.getSize() < this.max) {
        this.underMax.notify();
      }
    };

    if (this.getSize() > 0) {
      f();
    } else {
      this.notEmpty.add(f);
    }

    return this;
  }

  getSize() {
    return this.q.length;
  }
}

export class AsyncWorker {
  queue: AsyncQueue;

  errorHandler: ErrorHandler;

  isContinue = true;

  constructor(queue: AsyncQueue, errorHandler?: ErrorHandler) {
    this.queue = queue;
    this.errorHandler = errorHandler || (() => true);
    this.next();
  }

  next() {
    this.queue.dequeue(task => {
      if (!this.isContinue) return;

      try {
        task(() => this.next());
      } catch (e) {
        if (this.errorHandler(e)) {
          this.next();
        }
      }
    });
  }
}

export const asyncWorkers = (queue: AsyncQueue) => [...Array(queue.max)].map(() => new AsyncWorker(queue));
