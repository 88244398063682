import moment from '@this/lib/moment';

export type InfomartPostInvoiceLogStatus = 'unstarted' | 'succeeded' | 'failed';

export interface InfomartPostInvoiceLogArgs {
  id: number;
  invoice_id: number;
  status: InfomartPostInvoiceLogStatus;
  invoice_mng_num: string;
  error_message: string;
  created_at: string;
  updated_at: string;
}

class InfomartPostInvoiceLog {
  id: number;

  invoiceId: number;

  status: InfomartPostInvoiceLogStatus;

  invoiceMngNum: string;

  errorMessage: string;

  createdAt: string;

  constructor(args: InfomartPostInvoiceLogArgs) {
    this.id = args.id;
    this.invoiceId = args.invoice_id;
    this.status = args.status;
    this.invoiceMngNum = args.invoice_mng_num || '';
    this.errorMessage = args.error_message || '';
    this.createdAt = moment(args.created_at).format('YYYY/MM/DD HH:mm:ss');
  }

  get formattedErrorMessage(): string {
    try {
      const errors = JSON.parse(this.errorMessage);
      if (Array.isArray(errors)) {
        return errors.map(err => `・${err.error_detail}`).join('\n');
      }
    } catch (e) {}
    return this.errorMessage;
  }

  get statusLabel(): string {
    switch (this.status) {
      case 'succeeded':
        return '成功';
      case 'failed':
        return '失敗';
      default:
        return '';
    }
  }
}

export default InfomartPostInvoiceLog;
