import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';

interface Props {
  tripReportAvailable: boolean;
  dailyAllowanceAvailable: boolean;
  useExicExpence: boolean;
  approveItemAvailable: boolean;
  enableBatchApproval: boolean;
  marketLogAvailable: boolean;
  creditCardPaymentSettingAvailable: boolean;
}

const Sidenav = observer(
  ({
    tripReportAvailable,
    dailyAllowanceAvailable,
    useExicExpence,
    approveItemAvailable,
    enableBatchApproval,
    marketLogAvailable,
    creditCardPaymentSettingAvailable
  }: Props) => {
    return (
      <ul>
        <li>
          <ListTitle>
            <NavLink to="/organization">
              <IconImg src="/images/organization/sidebar/dashboard_icon.svg" alt="dashboard" />
              ダッシュボード
            </NavLink>
          </ListTitle>
        </li>
        <li>
          <ListTitle>
            <ChevronIcon />
            一覧
          </ListTitle>
          <SubList>
            <SubListItem>
              <NavLink to="/organization/trips">出張者情報・旅程一覧</NavLink>
            </SubListItem>
            {marketLogAvailable && (
              <SubListItem>
                <NavLink to="/organization/market_logs">マーケットログサマリ</NavLink>
              </SubListItem>
            )}
            {/*
              このページの出張報告は旧版のどの組織でも利用可能な出張報告βが利用されているとき
              のみ有効であるべきで、出張報告オプションが存在するときは表示不要。
            */}
            {!tripReportAvailable && (
              <SubListItem>
                <NavLink to="/organization/trip_reports">出張報告一覧</NavLink>
              </SubListItem>
            )}
            {dailyAllowanceAvailable && (
              <SubListItem>
                <NavLink to="/organization/allowances">日当一覧</NavLink>
              </SubListItem>
            )}
            {useExicExpence && (
              <SubListItem>
                <NavLink to="/organization/exic_reports">EXIC利用一覧</NavLink>
              </SubListItem>
            )}
          </SubList>
        </li>
        <li>
          <ListTitle>
            <ChevronIcon />
            マスタ
          </ListTitle>
          <SubList>
            <SubListItem>
              <NavLink to="/organization/members">社員マスタ</NavLink>
            </SubListItem>
            <SubListItem>
              <NavLink to="/organization/departments">部署マスタ</NavLink>
            </SubListItem>
            {approveItemAvailable && (
              <SubListItem>
                <NavLink to="/organization/approve_items">申請項目マスタ</NavLink>
              </SubListItem>
            )}
            {enableBatchApproval && (
              <SubListItem>
                <NavLink to="/organization/in_advance_message_templates">却下理由マスタ</NavLink>
              </SubListItem>
            )}
            <SubListItem>
              <NavLink to="/organization/projects">プロジェクトマスタ</NavLink>
            </SubListItem>
            <SubListItem>
              <NavLink to="/organization/expenses_account_types">勘定科目マスタ</NavLink>
            </SubListItem>
            {tripReportAvailable && (
              <SubListItem>
                <NavLink to="/organization/expenses_types">経費科目マスタ</NavLink>
              </SubListItem>
            )}
            <SubListItem>
              <NavLink to="/organization/organization_bases">拠点マスタ</NavLink>
            </SubListItem>
          </SubList>
        </li>
        <li>
          <ListTitle>
            <NavLink to="/organization/import_items">
              <IconImg src="/images/organization/sidebar/import_icon.svg" alt="data import" />
              旅費データインポート
            </NavLink>
          </ListTitle>
        </li>
        <li>
          <ListTitle>
            <NavLink to="/organization/invoices">
              <IconImg src="/images/organization/sidebar/invoice_icon.svg" alt="invoice" />
              請求書・明細
            </NavLink>
          </ListTitle>
        </li>
        {creditCardPaymentSettingAvailable && (
          <li>
            <ListTitle>
              <NavLink to="/organization/credit_card">
                <IconImg src="/images/organization/sidebar/invoice_icon.svg" alt="invoice" />
                クレジットカード明細
              </NavLink>
            </ListTitle>
          </li>
        )}
        <li>
          <ListTitle>
            <NavLink to="/organization/element_tickets">
              <IconImg src="/images/organization/sidebar/usage_icon.svg" alt="usage" />
              利用状況
            </NavLink>
          </ListTitle>
        </li>
        <li>
          <ListTitle>
            <ChevronIcon />
            各種設定
          </ListTitle>
          <SubList>
            <SubListItem>
              <NavLink to="/organization/setting">システム設定</NavLink>
            </SubListItem>
            {creditCardPaymentSettingAvailable && (
              <SubListItem>
                <NavLink to="/organization/payment">支払い設定</NavLink>
              </SubListItem>
            )}
            <SubListItem>
              <NavLink to="/organization/roles">役割・権限設定</NavLink>
            </SubListItem>
          </SubList>
        </li>
        <li>
          <ListTitle>
            <NavLink to="/organization/informations">
              <IconImg src="/images/organization/sidebar/information_icon.svg" alt="information" />
              お知らせ登録
            </NavLink>
          </ListTitle>
        </li>
        <li>
          <ListTitle>
            <NavLink to="/organization/metrics">
              <IconImg src="/images/organization/sidebar/analysis_icon.svg" alt="analysis" />
              簡易分析
            </NavLink>
          </ListTitle>
        </li>
      </ul>
    );
  }
);

const ChevronIcon: React.FC = () => (
  <img
    src="/images/organization/sidebar/chevron_down_icon.svg"
    alt="chevron down"
    style={{ marginRight: '4px' }}
  />
);

const IconImg = styled.img`
  padding: 2px;
  margin-right: 4px;
`;

const ListTitle = styled.p`
  display: flex;
  align-items: center;
  color: white;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 13px;
`;

const SubList = styled.ul`
  padding-left: 24px;
`;

const SubListItem = styled.li`
  height: 28px;
  line-height: 28px;
  font-size: 11px;
`;

const NavLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  color: white;

  &:hover {
    color: gray;
  }

  &:active,
  &:focus {
    color: white;
  }
`;

export default Sidenav;
