import type { AITTokens, AITPrimitiveTokens, AITTokens as Tokens } from './tokens';
import {
  semanticTokens,
  primitiveTokens as basePrimitiveTokens,
  getSpacingFromToken,
  getRadiusFromToken
} from './tokens';

const createThemeUtils = (tokens: Tokens) => {
  return {
    getSpacing: (level: number) => getSpacingFromToken(tokens, level),
    getRadius: (boxSize: number) => getRadiusFromToken(tokens, boxSize)
  };
};

export type AITThemeUtils = ReturnType<typeof createThemeUtils>;
export type AITBaseTheme = {
  tokens: AITTokens;
  primitiveTokens: AITPrimitiveTokens;
  utils: AITThemeUtils;
  // 旅工房対応でButtonとLinkだけthemeからoverride可にする
  overrides?: Record<'Button' | 'Link', any>;
};
export type AITTheme = AITBaseTheme & Record<string, any>;

export const createAITTheme = ({
  tokens: overrideTokens,
  primitiveTokens: overridePrimitiveTokens,
  ...args
}: Partial<Omit<AITTheme, 'utils'>> = {}): AITTheme => {
  const tokens: AITTokens = { ...semanticTokens, ...overrideTokens };
  const primitiveTokens: AITPrimitiveTokens = { ...basePrimitiveTokens, ...overridePrimitiveTokens };
  const utils: AITThemeUtils = createThemeUtils(tokens);

  return { tokens, primitiveTokens, utils, ...args };
};
