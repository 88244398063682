import React, { useMemo } from 'react';
import type { DialogProps as MuiDialogProps } from '@material-ui/core';
import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography
} from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

export const BiztraModalBody = MuiDialogContent;
export const BiztraModalFooter = MuiDialogActions;

export type ModalCloseReason = 'backdropClick' | 'escapeKeyDown' | 'buttonClick';
export type ModalOnClose = (event: object, reason: ModalCloseReason) => void;
export const ModalContext = React.createContext<{ onClose?: ModalOnClose }>({});

export const MODAL_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
} as const;
export type ModalSize = typeof MODAL_SIZE[keyof typeof MODAL_SIZE];

export type ModalProps = Omit<MuiDialogProps, 'maxWidth' | 'fullScreen' | 'onClose'> & {
  size?: ModalSize;
  onClose?: ModalOnClose;
};

const modalSizeMap: Record<ModalSize, MuiDialogProps['maxWidth']> = {
  small: 'xs',
  medium: 'sm',
  large: 'lg',
  xlarge: 'xl'
};

export const BiztraModal = ({ size = 'medium', onClose, fullWidth, ...props }: ModalProps) => {
  const context = useMemo(
    () => ({
      onClose
    }),
    [onClose]
  );

  return (
    <ModalContext.Provider value={context}>
      <MuiDialog
        maxWidth={modalSizeMap[size]}
        fullWidth={fullWidth === undefined ? true : fullWidth}
        onClose={context.onClose}
        {...props}
      />
    </ModalContext.Provider>
  );
};
const txtColor = '#323232';

const bgColor = '#f1ede5';

const useModalHeaderStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    backgroundColor: `${bgColor}`,
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: `${txtColor}`
  }
}));

export const BiztraModalHeader = ({ className, children }: { children: React.ReactNode; className?: string }) => {
  const styles = useModalHeaderStyle();

  return (
    <MuiDialogTitle disableTypography className={`${className} ${styles.root}`}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
};
