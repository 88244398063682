import { observable } from 'mobx';

export const TAX_TYPE_ID = {
  TAXABLE_8_ID: 1,
  TAXABLE_10_ID: 2,
  HIKAZEI_ID: 3,
  FUKAZEI_ID: 4,
  MENZEI_ID: 5,
  NA_ID: 6,
  TAXED_REVENUE_10_ID: 7,
  HIKAZEI_TRANSIT_80_ID: 8,
  HIKAZEI_TRANSIT_50_ID: 9,
  TAXABLE_8_TRANSIT_80_ID: 10,
  TAXABLE_8_TRANSIT_50_ID: 11,
  TAXABLE_10_TRANSIT_80_ID: 12,
  TAXABLE_10_TRANSIT_50_ID: 13
} as const;

export type TaxTypeArgs = {
  id: number;
  name: string;
  tax_ratio: number;
};

class TaxType {
  @observable
  id: number;

  @observable
  name: string;

  @observable
  taxRatio: number;

  constructor(args: TaxTypeArgs) {
    this.id = args.id;
    this.name = args.name;
    this.taxRatio = args.tax_ratio;
  }
}

export default TaxType;
