import React from 'react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/components/shared/ui/inputs/button';
import type User from '@this/domain/user/user';
import SelectApprovers from './select_approvers';

interface Stage {
  stage: number;
  approvers: (User | null)[];
}

interface Props {
  stages?: Stage[];
  handleApproveStagesChange: (stages: Stage[]) => void;
}

interface State {
  stages: Stage[];
}

// 旅程申請時及び、リクエストフォーム承認時にワークフローの承認ルートを作るケースに一旦特化したコンポーネント
// 部署ごとのワークフロー設定にも汎用化して使えるようにしたい。
class ApproveRoute extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stages: props.stages?.length ? props.stages : [{ stage: 1, approvers: [null] }]
    };
  }

  handleApproverChange = (stage: number, index: number, user: User) => {
    const approveStages = this.state.stages;
    const newApproveStages = approveStages.map((approveStage: Stage) => {
      if (approveStage.stage === stage) {
        const newApprovers = approveStage.approvers;
        newApprovers[index] = user;
        return { stage, approvers: newApprovers };
      }
      return approveStage;
    });
    this.props.handleApproveStagesChange(newApproveStages);
    this.setState({ stages: newApproveStages });
  };

  handleAddSelect(s: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const approveStages = this.state.stages;
    const newApproveStages = approveStages.map((approveStage: Stage) => {
      if (approveStage.stage === s) {
        const newApprovers = approveStage.approvers;
        newApprovers.push(null);
        return { stage: s, approvers: newApprovers };
      }
      return approveStage;
    });
    this.setState({ stages: newApproveStages });
  }

  handleRemoveSelect(s: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const approveStages = this.state.stages;
    const newApproveStages = approveStages.map((approveStage: Stage) => {
      if (approveStage.stage === s) {
        const newApprovers = approveStage.approvers;
        newApprovers.pop();
        return { stage: s, approvers: newApprovers };
      }
      return approveStage;
    });
    this.setState({ stages: newApproveStages });
  }

  handleAddApproveStage(s: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const approveStages = this.state.stages;
    const newApproveStages = approveStages;
    newApproveStages.push({ stage: approveStages[approveStages.length - 1].stage + 1, approvers: [null] });
    this.setState({ stages: newApproveStages });
  }

  handleRemoveApproveStage(s: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const approveStages = this.state.stages;
    const newApproveStages = approveStages;
    newApproveStages.pop();
    this.props.handleApproveStagesChange(newApproveStages);
    this.setState({ stages: newApproveStages });
  }

  render() {
    const { stages } = this.state;
    return (
      <>
        {stages.map((stage: Stage, s: number) => (
          <ApproverGroup key={s}>
            <ApproveStageLabel>{`${s + 1}次承認者`}</ApproveStageLabel>
            {stage.approvers.length === 0 ? (
              <div key={`${s}`} style={{ marginBottom: '5px' }}>
                <SelectApprovers key={`${s}`} stage={s + 1} index={0} onSelect={this.handleApproverChange} />
              </div>
            ) : (
              stage.approvers.map((member: User | null, i: number) => (
                <div key={`${s}_${i}`} style={{ marginBottom: '5px' }}>
                  <SelectApprovers
                    key={`${s}_${i}`}
                    stage={s + 1}
                    index={i}
                    defaultMember={member}
                    onSelect={this.handleApproverChange}
                  />
                </div>
              ))
            )}
            <ApproverButtonWrap>
              <Button size="small" onClick={e => this.handleAddSelect(s + 1, e)}>
                + 承認者を追加
              </Button>
              {stage.approvers.length > 1 && (
                <Button size="small" color="danger" onClick={e => this.handleRemoveSelect(s + 1, e)}>
                  - 承認者を削除
                </Button>
              )}
              {s + 1 === stages.length && (
                <Button size="small" color="danger" onClick={e => this.handleAddApproveStage(s + 1, e)}>
                  + ステップ追加
                </Button>
              )}
              {stages.length > 1 && s + 1 === stages.length && (
                <Button size="small" color="danger" onClick={e => this.handleRemoveApproveStage(s + 1, e)}>
                  + ステップ削除
                </Button>
              )}
            </ApproverButtonWrap>
          </ApproverGroup>
        ))}
      </>
    );
  }
}

const ApproverGroup = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 5px;
`;

const ApproveStageLabel = styled.label`
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 5px;
`;

const ApproverButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  font-size: 10px;
  margin-bottom: 5px;
`;

export default ApproveRoute;
