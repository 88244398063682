import React from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import { observer } from 'mobx-react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { styled } from '@this/constants/themes';
import { Select } from '@this/shared/ui/inputs/select';
import type { SearchTrackingLogStatus } from '@this/src/domain/search_tracking_log';
import type SearchTrackingOptions from '@this/src/domain/search_tracking_options';

export interface SearchQuery {
  status: SearchTrackingLogStatus | null;
  path: string[];
  searchType: string | null;
  searchQuery: number | null;
}

export type SearchQueryAction =
  | { type: 'SET_STATUS'; payload: SearchTrackingLogStatus }
  | { type: 'SET_PATH'; payload: string[] }
  | { type: 'SET_SEARCH_TYPE'; payload: string }
  | { type: 'SET_SEARCH_QUERY'; payload: number };

interface Props {
  searchQuery: SearchQuery;
  searchTrackingOptions: SearchTrackingOptions;
  dispatch: Dispatch<ReducerAction<Reducer<SearchQuery, SearchQueryAction>>>;
}

export const searchQueryReducer = (state: SearchQuery, action: SearchQueryAction): SearchQuery => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_PATH':
      return { ...state, path: action.payload };
    case 'SET_SEARCH_TYPE':
      return { ...state, searchType: action.payload };
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };
    default:
      return state;
  }
};

export const initialSearchQuery: SearchQuery = {
  status: null,
  path: [],
  searchType: null,
  searchQuery: null
};

const SearchTrackingDetailSearchArea: React.FC<Props> = observer(
  ({ searchQuery, searchTrackingOptions, dispatch }) => {
    const { status, path, searchType } = searchQuery;
    const { status: statusOptions, path: pathOptions, searchType: searchTypeOptions } = searchTrackingOptions;

    return (
      <SearchArea>
        <SearchBlock>
          <SearchLabel>ステータス</SearchLabel>
          <Select
            value={status ?? ''}
            onChange={e => dispatch({ type: 'SET_STATUS', payload: e.target.value as SearchTrackingLogStatus })}
          >
            <option value="">すべて</option>
            {statusOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>検索種別</SearchLabel>
          <Select
            value={searchType ?? ''}
            onChange={e => dispatch({ type: 'SET_SEARCH_TYPE', payload: e.target.value })}
          >
            <option value="">すべて</option>
            {searchTypeOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>パス</SearchLabel>
          <Autocomplete
            multiple
            options={pathOptions}
            filterOptions={(options, state) => {
              const q: string = state.inputValue;
              return options.filter(o => o.value.indexOf(q) > -1 || o.label.indexOf(q) > -1);
            }}
            getOptionLabel={option => option.label}
            value={pathOptions.filter(({ value }) => path?.includes(value) ?? false)}
            onChange={(_, value) => dispatch({ type: 'SET_PATH', payload: value.map(({ value }) => value) })}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                style={{}}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            )}
            style={{ minWidth: '240px' }}
          />
        </SearchBlock>
      </SearchArea>
    );
  }
);

const SearchArea = styled(Box)`
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default SearchTrackingDetailSearchArea;
