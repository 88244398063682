import { observable } from 'mobx';

type ActionType = 'search' | 'knowledge' | 'none' | 'error';

export type ChatbotActionResponseArgs = {
  action: ActionType;
  message?: string;
};

export type ChatbotActionArgs = {
  action: ActionType;
  message?: string;
};

export const convertChatbotActionResponseToArgs = (response: ChatbotActionResponseArgs) => {
  const args: ChatbotActionArgs = {
    action: response.action,
    message: response.message
  };
  return args;
};

export default class ChatbotAction {
  @observable action: ActionType;

  @observable message?: string;

  constructor(args: ChatbotActionArgs) {
    this.action = args.action;
    this.message = args.message;
  }
}
