import _ from 'lodash';

interface Leg {
  name: string;
  from: string;
  to: string;
}

interface Args {
  legs: Leg[];
  price: number;
  changeable_price?: number | null;
  unchangeable_price?: number | null;
  type: string;
}

class DomesticAirMarketLogCandidate {
  public legs: Leg[];

  public price: number;

  public changeablePrice?: number | null;

  public unchangeablePrice?: number | null;

  public type: string;

  constructor(args?: Args) {
    this.legs = (args && args.legs) || [];
    this.price = (args && args.price) || 0;
    this.changeablePrice = args && args.changeable_price;
    this.unchangeablePrice = args && args.unchangeable_price;
    this.type = (args && args.type) || '';
  }
}

export default DomesticAirMarketLogCandidate;
