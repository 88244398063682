import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Help from '@this/shared/footer/help/help';

import Charges from '@this/shared/footer/travel_terms/charges/charges';
import Knowledge, { KnowledgePaths } from '@this/components/knowledge/knowledge';
import TravelTerms from '../shared/footer/travel_terms/travel_terms';
import Inquiries from '../shared/footer/inquiries/inquiries';

interface Props {
  serviceId: number;
}

interface State {
  serviceId: number;
}

export const StaticPagePaths = KnowledgePaths.concat([
  '/travel_terms',
  '/charges',
  '/help',
  '/inquiries/new',
  '/inquiries/thanks'
]);

class StaticPages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serviceId: props.serviceId
    };
  }

  render() {
    try {
      return (
        <Switch>
          <Route exact path="/travel_terms" component={TravelTerms} />
          <Route exact path="/charges" component={Charges} />
          <Route path="/help" render={_props => <Help {...this.state} />} />
          <Route path="/inquiries" render={_props => <Inquiries {...this.state} />} />
          {utils.isAiTravel(this.state.serviceId) && <Route path={KnowledgePaths} component={Knowledge} />}
        </Switch>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default StaticPages;
