import React from 'react';
import { Datepicker } from '@this/shared/ui/inputs/datepicker';
import { Button } from '@this/shared/ui/inputs/button';
import { styled } from '@this/constants/themes';
import moment from '../../../../lib/moment';

const jsonPromise = utils.jsonPromise;

interface Props {
  orderId: number;
  initialConfirmedAt: string;
}
interface State {
  orderId: number;
  confirmedAt: moment.Moment;
  submitting: boolean;
  error: boolean;
  errorMessage: string;
  message: string;
}
class UpdateConfirmedAtForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orderId: props.orderId,
      confirmedAt: moment(props.initialConfirmedAt),
      submitting: false,
      error: false,
      errorMessage: '',
      message: ''
    };
  }

  handleConfirmedAtChange(confirmedAt: moment.Moment) {
    this.setState({ confirmedAt });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({ submitting: true, error: false });

    const result = await this.updateOrder();
    if (!result) {
      this.setState({
        submitting: false,
        error: true,
        errorMessage: '保存に失敗しました'
      });
    } else {
      this.setState({
        submitting: false,
        message: '保存しました'
      });
    }
  }

  async updateOrder(): Promise<boolean> {
    try {
      const url = `/arrangement/orders/${this.state.orderId}`;
      const params = {
        confirmed_at: this.state.confirmedAt.format('YYYY-MM-DD')
      };
      await jsonPromise(url, params, 'put');
      return true;
    } catch (_) {
      return false;
    }
  }

  render() {
    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <label>請求確定日</label>
        <Datepicker
          value={this.state.confirmedAt.toDate()}
          onChange={date => this.handleConfirmedAtChange(moment(date))}
          disabled={this.state.submitting}
          disabledClear
        />
        <Button type="submit" size="small">
          保存
        </Button>
        {(() => {
          if (this.state.submitting) {
            return <div>保存中...</div>;
          }
          if (this.state.error) {
            return <div className="error">{this.state.errorMessage}</div>;
          }
          if (this.state.message) {
            return <div>{this.state.message}</div>;
          }
          return <div />;
        })()}
      </Form>
    );
  }
}

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`;

export default UpdateConfirmedAtForm;
