import { makeStyles } from '@material-ui/core';
import type { AITTheme } from '@this/shared/ui/theme';
import type { DateRangePickerProps } from './date_range_picker';

type Props = Pick<DateRangePickerProps, 'size' | 'error' | 'disabled' | 'disabledClear'>;
export const useStyle = makeStyles<AITTheme, Props>(({ tokens: { colors, radiuses, typographies }, utils }) => ({
  input: ({ size, error, disabled, disabledClear }) => ({
    display: 'inline-flex',
    minWidth: disabledClear ? 'auto' : 200,
    alignItems: 'center',
    border: '1px solid',
    borderColor: error ? colors.danger.primary : colors.border.divider,
    borderRadius: radiuses.r4,
    transition: 'border-color 0.2s',
    fontSize: size === 'small' ? typographies.fontSizes.caption : typographies.fontSizes.body,
    backgroundColor: colors.common.white,
    '&:focus-within': {
      borderColor: colors.brand.primary
    },
    '&.rc-picker-range .rc-picker-input input': {
      border: 'none',
      padding: size === 'small' ? utils.getSpacing(1) : utils.getSpacing(2),
      width: 68,
      borderRadius: radiuses.r4,
      '&:focus-visible': {
        outline: 'none'
      },
      '&:disabled': {
        backgroundColor: colors.common.white,
        color: colors.text.disabled
      },
      // TODO: AI TravelのGlobal CSSに上書きされてしまう問題の暫定対応
      margin: 0,
      boxShadow: 'none',
      boxSizing: 'content-box',
      '&:focus': {
        boxShadow: 'none'
      }
      // ここまで
    },
    '& .rc-picker-range-separator': {
      color: disabled ? colors.text.disabled : 'inherit'
    },
    '& .rc-picker-suffix': {
      marginLeft: utils.getSpacing(-1),
      paddingRight: utils.getSpacing(1),
      fontSize: typographies.fontSizes.caption,
      fontWeight: 'bold',
      color: disabled ? colors.text.disabled : colors.brand.primary
    }
  }),
  pickerPanel: () => ({
    width: 'fit-content',
    '& .rc-picker-presets': {
      borderRight: `1px solid ${colors.border.divider}`,
      '& ul': {
        listStyle: 'none',
        padding: 0,
        margin: `${utils.getSpacing(2)}px 0`
      },
      '& li': {
        padding: `${utils.getSpacing(2)}px ${utils.getSpacing(4)}px`,
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: colors.background.hover
        }
      }
    },
    '& .rc-picker-panels': {
      display: 'flex'
    },
    '& table.rc-picker-content': {
      '& td': {
        '&.rc-picker-cell-in-view:not(.rc-picker-cell-disabled)': {
          '&.rc-picker-cell-range-start, &.rc-picker-cell-range-end': {
            backgroundColor: colors.background.selected,
            color: colors.text.primary,
            borderRadius: radiuses.r4
          },
          '&.rc-picker-cell-range-hover': {
            backgroundColor: colors.background.hover
          },
          '&.rc-picker-cell-in-range': {
            backgroundColor: colors.background.selected
          }
        }
      }
    }
  })
}));
