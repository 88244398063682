import * as React from 'react';
import { Link } from 'react-router-dom';
import { headerHeight, HeaderLink } from '@this/components/expenses/header/header';
import type User from '../../../domain/user/user';
import { styled } from '../../constants/themes';

interface Props {
  user: User;
  className?: string;
}

const NavContentSm: React.FC<Props> = ({ user, className }) => {
  return (
    <Nav className={className}>
      <HeaderLink>
        <Link to="/biztra/report_items">
          <img src="/images/expenses/icon_item.png" alt="登録アイコン" />
          <span>登録</span>
        </Link>
      </HeaderLink>
      <HeaderLink>
        <Link to="/biztra/reports">
          <img src="/images/expenses/icon_report.png" alt="申請アイコン" />
          <span>申請</span>
        </Link>
      </HeaderLink>
      {(user?.isOrganizationAdmin || user?.is_expenses_approver) && (
        <HeaderLink>
          <Link to="/biztra/approvals">
            <img src="/images/expenses/icon_approval.png" alt="承認アイコン" />
            <span>承認</span>
          </Link>
        </HeaderLink>
      )}
      {user?.isOrganizationAdmin && (
        <HeaderLink>
          <Link to="/biztra/organization">
            <img src="/images/expenses/icon_setting.png" alt="管理アイコン" />
            <span>管理</span>
          </Link>
        </HeaderLink>
      )}
    </Nav>
  );
};

const Nav = styled.nav`
  position: fixed;
  top: ${headerHeight};
  left: 0;
  right: 0;
  background-color: #393e46;
  z-index: 1000;
`;

export default NavContentSm;
