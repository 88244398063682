import moment from '../lib/moment';
import type DashboardInformationInterface from './dashboard_information_interface';

export interface OrganizationInformationRaw {
  id: number;
  organization_id: number;
  content: string;
  created_at: string;
  updated_at: string;
  display_order: number;
}

class OrganizationInformation implements DashboardInformationInterface {
  readonly id: number;

  readonly organizationId: number;

  readonly content: string;

  readonly updatedAt: string;

  readonly createdAt: string;

  readonly description: string;

  readonly displayOrder: number;

  readonly kind: 'notification';

  readonly type: 'organization';

  constructor(raw: OrganizationInformationRaw) {
    this.id = raw.id;
    this.organizationId = raw.organization_id;
    this.content = raw.content;
    this.description = raw.content;
    this.displayOrder = raw.display_order;
    this.kind = 'notification';
    this.type = 'organization';
    this.updatedAt = moment(raw.updated_at).format('YYYY-MM-DD HH:mm:ss');
    this.createdAt = moment(raw.created_at).format('YYYY-MM-DD HH:mm:ss');
  }
}

export default OrganizationInformation;
