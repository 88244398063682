import type { ColorTheme } from '@this/components/organization/dashboard/share/graph';
import { BORDER_STYLES, COLOR_THEMES } from '@this/components/organization/dashboard/share/graph';
import type { PurchaseLeadtimeType } from '@this/components/organization/dashboard/share/index';
import type { Bar } from 'react-chartjs-2';

export interface BarPurchaseLeadtime {
  label: string;
  leadtimes: Record<Exclude<PurchaseLeadtimeType, 'all'> | 'total', number>;
}

export const getBarHeight = (leadtimes: BarPurchaseLeadtime[]) => {
  return 120 + 25 * leadtimes.length;
};

export const getBarData = (leadtimes: BarPurchaseLeadtime[], theme: ColorTheme) => {
  const labels = leadtimes.map(({ label }) => label);
  const values = leadtimes.map(({ leadtimes }) => leadtimes);
  const colorTheme = COLOR_THEMES[theme];
  const borderStyle = BORDER_STYLES[theme];

  const datasets = [
    {
      label: '間際予約',
      data: values.map(value => value.short_notice),
      ...colorTheme.red,
      ...borderStyle
    },
    {
      label: '通常予約',
      data: values.map(value => value.normal_booking),
      ...colorTheme.yellow,
      ...borderStyle
    },
    {
      label: '早期予約',
      data: values.map(value => value.early_booking),
      ...colorTheme.green,
      ...borderStyle
    },
    {
      label: '計画予約',
      data: values.map(value => value.planned_booking),
      ...colorTheme.blue,
      ...borderStyle
    }
  ];

  return { labels, datasets };
};

export const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    xAxes: {
      stacked: true,
      ticks: {
        precision: 0
      }
    },
    yAxes: {
      grid: { display: false },
      stacked: true
    }
  },
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const value = tooltipItem.raw as number;
          return `${tooltipItem.dataset.label}:${value.toLocaleString()}件`;
        }
      }
    }
  }
};
