import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import {
  Body,
  BodyTitle,
  BodyWrap,
  Border,
  HeaderBody,
  HeaderDivider,
  HeaderWrap,
  LoadingWrap,
  PageTitle,
  Wrap
} from './shared/wrappers';
import TripReportReport from './trip_report_report';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import TripReportApprove from './trip_report_form/trip_report_approve';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

const TripReportApproval: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const { id } = useParams<{ id: string }>();
  const store = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);
  const tripReport = store.currentTripReport;
  const { loading, errors } = store;

  const fetchTripReports = useCallback(() => {
    store.getShowForApproval(id);
    store.getTrips(id);
  }, [id]);

  useEffect(() => {
    fetchTripReports();
  }, [fetchTripReports]);

  return (
    <TripReportContext.Provider value={store}>
      <Wrap>
        <HeaderWrap>
          <HeaderBody>
            <PageTitle>申請内容</PageTitle>
            <HeaderDivider />
          </HeaderBody>
        </HeaderWrap>
        <BodyWrap>
          <Body>
            {loading ? (
              <LoadingWrap>
                <SimpleLoading />
              </LoadingWrap>
            ) : errors.length > 0 ? (
              <LoadingWrap>
                <Error>{errors.join('\n')}</Error>
              </LoadingWrap>
            ) : (
              <>
                <BodyTitle>申請タイトル：{tripReport.name}</BodyTitle>
                <Border />
                <TripReportReport />
                <Border />
                <TripReportApprove />
              </>
            )}
          </Body>
        </BodyWrap>
      </Wrap>
    </TripReportContext.Provider>
  );
};

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

export default TripReportApproval;
