import React from 'react';
import { styled } from '@this/constants/themes';
import type { PriceField, SimulateBaseRecord } from '@this/src/domain/simulate_base_record';

interface Props {
  record: SimulateBaseRecord;
  field: PriceField;
  label: string;
}

const ResultRowStyle = styled.div`
  & > label,
  & > span {
    margin-right: 4px;
  }

  .plus {
    color: ${props => props.theme.redColor};
  }
  .minus {
    color: ${props => props.theme.successColor};
  }
`;

const ResultRow: React.FC<Props> = ({ record, field, label }) => {
  const { price, diff, pricePerStay, diffPerStay } = record.priceWithDiff(field);
  const hotel = record.priceWithHotel(field);

  return (
    <ResultRowStyle>
      <label>{label}</label>
      <span>{price?.toLocaleString() || '-'}円</span>
      <span>
        (
        <span className={diff >= 0 ? 'plus' : 'minus'}>
          {diff >= 0 && '+'}
          {diff?.toLocaleString()}
        </span>
        )
      </span>
      {pricePerStay !== null && <span>{pricePerStay.toLocaleString()}円/泊</span>}
      {diffPerStay !== null && (
        <span>
          (
          <span className={diffPerStay >= 0 ? 'plus' : 'minus'}>
            {diffPerStay >= 0 && '+'}
            {diffPerStay.toLocaleString()}
          </span>
          )
        </span>
      )}
      {hotel && <span>{hotel}</span>}
    </ResultRowStyle>
  );
};

export default ResultRow;
