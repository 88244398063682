import styled, { withTheme } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import type DashbordInformationInterface from '@this/domain/dashboard_information_interface';
import type { InformationType } from '@this/domain/dashboard_information_interface';
import InformationAreaItem from './information_area_item';

type FilterType = 'all' | InformationType;

type Props = {
  infos: DashbordInformationInterface[];
  theme: { serviceName: string };
};

const InformationArea: React.FC<Props> = ({ infos, theme }) => {
  const [selectedInformationType, setSelectedInformationType] = useState<FilterType>('all');
  const [filteredInfos, setFilteredInfos] = useState<DashbordInformationInterface[]>(infos);

  const handleChangeSelectedInformationType = (e: React.MouseEvent, newType: FilterType) => {
    setSelectedInformationType(newType);
  };

  useEffect(() => {
    const filtered =
      selectedInformationType === 'all' ? infos : infos.filter(info => info.type === selectedInformationType);
    setFilteredInfos(filtered);
  }, [infos, selectedInformationType]);

  return (
    <Wrapper>
      <TagLines>
        <StyledToggleButtonGroup
          size="small"
          value={selectedInformationType}
          exclusive
          onChange={handleChangeSelectedInformationType}
        >
          <ToggleButton value="all" aria-label="all" style={{ padding: '5px 25px' }}>
            <p>すべて</p>
          </ToggleButton>
          <ToggleButton value="admin" aria-label="admin" style={{ padding: '5px 25px' }}>
            <p>{theme.serviceName === 'AI Travel' ? 'AI Travel' : 'システム'}からのお知らせ</p>
          </ToggleButton>
          <ToggleButton value="organization" aria-label="organization" style={{ padding: '5px 25px' }}>
            <p>社内通知</p>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </TagLines>
      <NewsBody data-wovn-ignore>
        <NewsLines>
          {filteredInfos.map((info, i) => (
            <InformationAreaItem key={i} info={info} />
          ))}
        </NewsLines>
      </NewsBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 430px;
  margin: 0 0 0 auto;
  font-weight: bold;
  background: ${props => props.theme.contentBgColor};

  @media screen and (max-width: 1030px) {
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 430px) {
    padding: 0 20px 20px;
    color: ${props => props.theme.textColor};
  }
`;

const TagLines = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 10px 20px;
`;

const NewsBody = styled.div`
  height: 500px;
  border-radius: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
`;

const NewsLines = styled.div`
  padding: 0 20px 0;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  background-color: #eee;

  & .MuiToggleButtonGroup-grouped {
    margin: 5px;
    border: 0;
    border-radius: 4px;
    text-transform: none;
    &.Mui-selected {
      background-color: white;
    }
  }
`;

export default withTheme(InformationArea);
