import type { PaymentMethodArgs } from '@this/src/domain/payment_method/payment_method';
import PaymentMethodList from '@this/src/domain/payment_method/payment_method_list';
import BaseAPI from '../base_api';

interface PaymentMethodsResponse {
  payment_methods: PaymentMethodArgs[];
}

export default class PaymentMethodAPI extends BaseAPI {
  public list(): Promise<PaymentMethodList> {
    return this.get<PaymentMethodsResponse>(`/payment_methods.json`).then(
      res => new PaymentMethodList(res.data.payment_methods)
    );
  }
}
