import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Detail from '@this/components/expenses/report_items/detail';
import type { ItemJson } from '../../../domain/expenses/item';
import { Item } from '../../../domain/expenses/item';
import ExpensesMain from '../main/main';
import type { ExpensesTypeJson } from '../../../domain/expenses/expenses_type';

interface ItemResponse {
  item: ItemJson;
  expenses_types: ExpensesTypeJson[];
  category_options: { [key: string]: string };
  travel_expense_type_options: { [key: string]: string };
  trip_type_options: { [key: string]: string };
  receipt_available: boolean;
  is_admin: boolean;
}

type Props = RouteComponentProps<{ approval_id?: string; id?: string }>;

interface State {
  item?: Item;
  loading: boolean;
  error?: string;
  receiptAvailable: boolean;
  isAdmin: boolean;
}

class Edit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      receiptAvailable: false,
      isAdmin: false
    };
  }

  componentDidMount() {
    this.fetchItem();
  }

  private async fetchItem() {
    try {
      this.setState({ loading: true });
      const response = await utils.jsonPromise<ItemResponse>(
        `/biztra/approvals/${this.approvalId()}/approval_items/${this.itemId()}/edit`
      );
      this.setState({
        item: new Item(response.item),
        receiptAvailable: response.receipt_available,
        isAdmin: response.is_admin,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
      utils.sendErrorObject(e);
    }
  }

  private itemFrom() {
    const { item, receiptAvailable, isAdmin } = this.state;
    return <Detail item={item!} receiptAvailable={receiptAvailable} isAdmin={isAdmin} />;
  }

  private approvalId = () => this.props.match.params.approval_id;

  private itemId = () => this.props.match.params.id;

  render() {
    const { loading, error } = this.state;

    return (
      <ExpensesMain title="経費詳細">
        {loading ? <SimpleLoading /> : error ? <p>{error}</p> : this.itemFrom()}
      </ExpensesMain>
    );
  }
}

export default Edit;
