import React from 'react';

import UPSERT_MEMBER_FORMATS from '@this/components/organization/csv_bulk_action/member_file_formats';
import type { BulkActionAttr } from '@this/components/organization/types';
import { BulkActionAttrServiceType } from '@this/components/organization/types';
import {
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationBiztraTh,
  OrganizationBiztraTr
} from '@this/components/expenses/organization/organization.style';

interface Props {
  status: any;
  expenses?: boolean;
}
interface State {
  changeAttrs: BulkActionAttr[];
}
export default class BulkUpsertActionUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      changeAttrs: UPSERT_MEMBER_FORMATS.reduce((array: BulkActionAttr[], f: BulkActionAttr) => {
        let isEnableServiceType = f.serviceType === BulkActionAttrServiceType.ALL;
        if (!isEnableServiceType) {
          isEnableServiceType = this.props.expenses
            ? f.serviceType === BulkActionAttrServiceType.BIZTRA
            : f.serviceType === BulkActionAttrServiceType.AI_TRAVEL;
        }

        if (isEnableServiceType && !['email', 'birthday', 'gender'].some(s => s === f.name)) {
          array.push(f);
        }
        return array;
      }, [])
    };
  }

  getStatusClass = (user: any): string => (user.isSuccess() ? 'green' : user.isFail() ? 'red' : 'gray');

  getChangeAttrText = (user: any, name: string): string => {
    const change = user.hasChange(name);
    if (change) {
      const beforeValTest = change.before == null ? '[未設定]' : this.convertAttrText(change.before, name);
      const afterValText = change.after == null ? '[削除]' : this.convertAttrText(change.after, name);
      return `${beforeValTest} → ${afterValText}`;
    }
    return '-';
  };

  convertAttrText = (attr: any, attrName: string): any => {
    switch (attrName) {
      case 'domestic_approval_required':
      case 'foreign_approval_required':
        return attr ? '必要' : '不要';
      case 'show_other_members':
        return attr ? '表示する' : '表示しない';
      default:
        return attr;
    }
  };

  render() {
    const { status } = this.props;
    const { changeAttrs } = this.state;
    return (
      <OrganizationBiztraTable>
        <thead>
          <tr>
            <OrganizationBiztraTh>アドレス</OrganizationBiztraTh>
            <OrganizationBiztraTh>ステータス</OrganizationBiztraTh>
            {changeAttrs.map((attr, i) => (
              <OrganizationBiztraTh key={i}>{attr.summary}</OrganizationBiztraTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {status.actionUsers.map((user: any, i: number) => (
            <OrganizationBiztraTr key={i} className={i % 2 === 1 ? 'even' : 'odd'}>
              <OrganizationBiztraTd>{user.email}</OrganizationBiztraTd>
              <OrganizationBiztraTd className={this.getStatusClass(user)}>
                {user.statusMessage()}
              </OrganizationBiztraTd>
              {changeAttrs.map((attr, i) => (
                <OrganizationBiztraTd key={i}>{this.getChangeAttrText(user, attr.name)}</OrganizationBiztraTd>
              ))}
            </OrganizationBiztraTr>
          ))}
        </tbody>
      </OrganizationBiztraTable>
    );
  }
}
