import React from 'react';
import { media } from '@this/components/shared/atoms/media';
import { styled } from '@this/constants/themes';
import { useInAdvanceApplicationStore } from '@this/src/domain/in_advance/in_advance_application_store';
import BulkActions from './bulk_actions';
import ResultSummary from './result_summary';

const InAdvanceApplicationSummary: React.FC = () => {
  const store = useInAdvanceApplicationStore();
  const status = store.searchStatus();

  return (
    <>
      <SummaryWrap>
        <LeftSection>{status === 'applied' && <BulkActions />}</LeftSection>
        <RightSection>
          {store.autoStockCheckExecutedAt && (
            <AutoStockCheckDate>{`在庫確認：${store.autoStockCheckExecutedAt.format(
              'YYYY年MM月DD日 HH時mm分'
            )}更新`}</AutoStockCheckDate>
          )}
        </RightSection>
      </SummaryWrap>
      {status !== 'applied' && <ResultSummary />}
    </>
  );
};

const SummaryWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.sp`
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 20px;
  `}
`;

const AutoStockCheckDate = styled.div`
  color: ${props => props.theme.grayTextColor};
  margin-bottom: 12px;
`;

const LeftSection = styled.div``;
const RightSection = styled.div``;

export default InAdvanceApplicationSummary;
