import React from 'react';
import {
  OrganizationBiztraTable,
  OrganizationBiztraTd,
  OrganizationBiztraTh,
  OrganizationBiztraTr
} from '@this/components/expenses/organization/organization.style';

interface Props {
  status: any;
}

export default class BulkUpsertActionExpensesTypes extends React.Component<Props> {
  getStatusClass = (bulkAction: any): string =>
    bulkAction.isSuccess() ? 'green' : bulkAction.isFail() ? 'red' : 'gray';

  render() {
    const { status } = this.props;
    return (
      <OrganizationBiztraTable>
        <thead>
          <tr>
            <OrganizationBiztraTh>経費科目名</OrganizationBiztraTh>
            <OrganizationBiztraTh>処理結果</OrganizationBiztraTh>
          </tr>
        </thead>
        <tbody>
          {status.actionExpensesTypes.map((expenses_type: any, i: number) => (
            <OrganizationBiztraTr key={i} className={i % 2 === 1 ? 'even' : 'odd'}>
              <OrganizationBiztraTd>{expenses_type.code}</OrganizationBiztraTd>
              <OrganizationBiztraTd className={`${this.getStatusClass(expenses_type)}`}>
                {expenses_type.statusMessage()}
              </OrganizationBiztraTd>
            </OrganizationBiztraTr>
          ))}
        </tbody>
      </OrganizationBiztraTable>
    );
  }
}
