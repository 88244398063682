import React from 'react';
import _ from 'lodash';
import { TextField } from '../../shared/form_elements/form_elements';

interface Props {
  navigateToBack: () => void;
}
interface State {
  form: { email: string };
  result?: { type: UserPasswordResetFormResultType; message: string };
}
enum UserPasswordResetFormResultType {
  SUCCESS = 'success',
  FAIL = 'fail'
}
class PasswordResetForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { form: { email: '' } };
  }

  handleChange(value: string) {
    this.setState({ form: { email: value } });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({ result: undefined });
    try {
      const params = {
        'user[reset_email]': this.state.form.email
      };
      await utils.jsonPromise<{ redirect: string }>('/biztra/users/password', params, 'POST');
      this.setState({
        result: {
          type: UserPasswordResetFormResultType.SUCCESS,
          message: 'リセット用のリンクをメールで送信しました'
        },
        form: { email: '' }
      });
    } catch (e) {
      if (e.status === 400) {
        this.setState({
          result: {
            type: UserPasswordResetFormResultType.FAIL,
            message: e.responseJSON.errors
          }
        });
      } else {
        this.setState({
          result: {
            type: UserPasswordResetFormResultType.FAIL,
            message: '通信環境が不安定です。時間をおいてもう一度お試しください。'
          }
        });
      }
    }
  }

  get messageClassName(): string {
    if (!this.state.result) {
      return '';
    }

    return this.state.result.type === UserPasswordResetFormResultType.SUCCESS ? 'success' : 'error';
  }

  render() {
    return (
      <div className="form-container">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <TextField
            label="メールアドレス（必須）"
            value={this.state.form.email}
            example="例：biztra@aitravel.jp"
            onChange={value => this.handleChange(value)}
          />
          {!this.state.result || (
            <div className="expenses-users-signup__message">
              <p className={this.messageClassName}>{this.state.result.message}</p>
            </div>
          )}
          <button className="submit-button" type="submit" disabled={!this.state.form.email}>
            パスワード再設定
          </button>
        </form>
        <div className="expenses-users-signup__utils">
          <a onClick={this.props.navigateToBack}>戻る</a>
        </div>
      </div>
    );
  }
}

export default PasswordResetForm;
