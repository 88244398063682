import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import jsonPromiseContext from '@this/src/components/constants/json_promise_context';
import type { DashboardProps } from '../../share';
import type { BarPrices } from '../../share/graph';
import { chartOptions, getBarDataPrices, getBarHeight, sortBarPrices } from '../../share/graph';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// 社員別セクションV2：元々使用されていたダッシュボードを速度調整したもの
// Usecase::Organization::DashboardTrips::TravelerPatterns::CachePatternUsecase を使用
const USECASE = { section: 'cost_by_traveler', pattern: 'cache' };

interface State {
  loading: boolean;
  prices: BarPrices[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  prices: State['prices'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    prices: result.prices,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardTravelerSection: React.FC<DashboardProps> = props => {
  const jsonPromise = useContext(jsonPromiseContext);

  const [state, setState] = useState<State>({
    loading: true,
    prices: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, prices, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  const data = useMemo(() => getBarDataPrices(sortBarPrices(prices, 'desc'), 'default'), [prices]);
  const height = useMemo(() => getBarHeight(prices), [prices]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    fetchSummary({ setState, jsonPromise, ...props });
  }, [type, from, to, refreshAt, props.refreshAt]);

  return (
    <>
      <h3 className="graph_title">社員別</h3>
      {loading ? (
        <SimpleLoading />
      ) : (
        <Bar data={data} width={550} height={height} options={{ indexAxis: 'y', ...chartOptions }} />
      )}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

export default DashboardTravelerSection;
