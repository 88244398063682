import _ from 'lodash';
import { observable, action } from 'mobx';
import TripApproveItem from './trip_approve_item';
import type TripApproveItemJson from './trip_approve_item_json';

class TripApproveItemList {
  @observable
  public list: TripApproveItem[];

  constructor(raw: TripApproveItemJson[]) {
    this.list = raw.map(o => new TripApproveItem(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  last() {
    return _.last(this.list);
  }

  convertObjects() {
    const retObj: Map<number, string> = new Map<number, string>();
    this.list.forEach(item => {
      retObj.set(item.id, item.value);
    });
    return retObj;
  }

  @action
  push(o: TripApproveItem) {
    this.list.push(o);
  }

  @action
  remove(o: TripApproveItem) {
    const i = this.list.findIndex(item => item.id === o.id);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  @action
  update(newOrg: TripApproveItem) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }

  clone(): TripApproveItemList {
    const cloned = new TripApproveItemList(JSON.parse(JSON.stringify(this.list)));
    return cloned;
  }
}

export default TripApproveItemList;
