import React, { Suspense, useCallback, useState } from 'react';

import { styled } from '@this/constants/themes';
import { useHistory } from 'react-router-dom';
import { Radio, RadioGroup } from '../../shared/ui/inputs/radio';

const ChatbotKnowledgeLogs = React.lazy(() => import('./chatbot_knowledge_logs'));
const HandleChatUserMessageTryOut = React.lazy(() => import('./handle_chat_user_message_try_out'));

type Tab = 'chatbot_knowledge_logs' | 'handle_chat_user_message';

const ENDPOINT = '/god/chatbot';
const BASE_PARAM = 'TAB';

const initialState = (): Tab => {
  return utils.getParam(BASE_PARAM) || 'chatbot_knowledge_logs';
};

const ChatbotTryOuts = () => {
  const history = useHistory();
  const [tab, setTab] = useState<Tab>(initialState);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTab(e.target.value as Tab);
      history.push(`${ENDPOINT}?${BASE_PARAM}=${e.target.value}`);
    },
    [history, setTab]
  );

  return (
    <Wrapper>
      <TitleArea>
        <Title>AIコンシェルジュ管理ツール</Title>
        <RadioGroup layout="horizontal" value={tab} onChange={handleChange}>
          <Radio name="tab" value="chatbot_knowledge_logs">
            ナレッジ検索ログ
          </Radio>
          <Radio name="tab" value="handle_chat_user_message">
            knowledge・search・none のクラスタリング
          </Radio>
        </RadioGroup>
      </TitleArea>
      <Suspense fallback={null}>
        {tab === 'chatbot_knowledge_logs' ? (
          <ChatbotKnowledgeLogs />
        ) : (
          tab === 'handle_chat_user_message' && <HandleChatUserMessageTryOut />
        )}
      </Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`;

export default ChatbotTryOuts;
