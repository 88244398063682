import React from 'react';
import { styled } from '@this/constants/themes';

import type FlightMarketLog from '@this/domain/market_log/flight_market_log';
import type FlightMarketLogCandidate from '@this/domain/market_log/flight_market_log_candidate';
import type TabikoboAviation from '@this/domain/market_log/tabikobo_aviation';
import PriceDistributionChart from '@this/components/market_log/price_distribution_chart';
import MarketLogSample from './market_log_sample';

type Props = {
  marketLog: FlightMarketLog;
  yesterdayMarketLog: FlightMarketLog;
  tomorrowMarketLog: FlightMarketLog;

  whiteCarrierIds: string[];
  whiteCarrierNames: string[];
  whiteCarrierMarketLogs: FlightMarketLog[][];

  tabikoboAviations: TabikoboAviation[];

  serviceId: number;
  available: boolean;
};

const inCandidateList = (target: FlightMarketLog, candidates: FlightMarketLogCandidate[]) => {
  return candidates.some(c => c.transportDescriptions.toString() === target.transportDescriptions.toString());
};

const FlightMarketLogTemplate: React.FC<Props> = ({
  marketLog,
  yesterdayMarketLog,
  tomorrowMarketLog,

  whiteCarrierIds,
  whiteCarrierNames,
  whiteCarrierMarketLogs,

  tabikoboAviations,

  serviceId,
  available
}) => (
  <>
    <Info>
      <Title>基本情報</Title>
      <InfoContent>
        <InfoItemWrap>
          <InfoItem>
            <InfoItemLabel>予約者</InfoItemLabel>
            <InfoItemText>{marketLog.userName}</InfoItemText>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>承認者</InfoItemLabel>
            <InfoItemText>{marketLog.approverNames}</InfoItemText>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>出張者</InfoItemLabel>
            <InfoItemText>{marketLog.travelerNames}</InfoItemText>
          </InfoItem>
        </InfoItemWrap>
        <InfoItemWrap>
          {marketLog.transportDescriptions.map((d, i) => (
            <InfoItem key={i}>
              <InfoItemLabel>{`経路${i + 1}`}</InfoItemLabel>
              <InfoItemText>
                {d.split('\n').map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </InfoItemText>
            </InfoItem>
          ))}
          <InfoItem>
            <InfoItemLabel>料金</InfoItemLabel>
            <InfoItemText>{utils.formatPrice(marketLog.price)}</InfoItemText>
          </InfoItem>
        </InfoItemWrap>
      </InfoContent>
    </Info>
    {available ? (
      <>
        <Summary>
          <Title>前後日の同便の金額指標データ</Title>
          <SummaryContent>
            <SummaryItem>
              <SummaryLabel>前日</SummaryLabel>
              {yesterdayMarketLog.candidates.length > 0 && yesterdayMarketLog.marketPrice ? (
                <SummaryText>{utils.formatPrice(yesterdayMarketLog.marketPrice)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>選択日</SummaryLabel>
              {/*
                marketLog.marketPriceを利用すべきと考えられるが、検索時と注文時の金額は変わりうる。
                不要な顧客の混乱を避けるため、当日の金額だけは基本情報の金額を指標値として表示する。
              */}
              {marketLog.candidates.length > 0 ? (
                <SummaryText>{utils.formatPrice(marketLog.price)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>翌日</SummaryLabel>
              {tomorrowMarketLog.candidates.length > 0 && tomorrowMarketLog.marketPrice ? (
                <SummaryText>{utils.formatPrice(tomorrowMarketLog.marketPrice)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
          </SummaryContent>
        </Summary>

        <Summary>
          <Title>全体の金額指標データ</Title>
          <SummaryContent>
            <SummaryItem>
              <SummaryLabel>最安値</SummaryLabel>
              {marketLog.candidates.length > 0 ? (
                <SummaryText>{utils.formatPrice(marketLog.minPrice)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>平均値</SummaryLabel>
              {marketLog.candidates.length > 0 ? (
                <SummaryText>{utils.formatPrice(marketLog.averagePrice)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>最高値</SummaryLabel>
              {marketLog.candidates.length > 0 ? (
                <SummaryText>{utils.formatPrice(marketLog.maxPrice)}</SummaryText>
              ) : (
                <SummaryText>-</SummaryText>
              )}
            </SummaryItem>
          </SummaryContent>
        </Summary>
        <SummaryExplanation>※マーケットデータをもとにした値を表示しています。</SummaryExplanation>

        <Summary>
          <Title>金額分布</Title>
          <PriceDistributionChart price={marketLog.price} candidates={marketLog.candidates} />

          <GraphSummaryExplanation>※赤色の部分が今回予約した航空券が含まれる分布です。</GraphSummaryExplanation>

          <Title>ランキング</Title>
          <RankingArea>
            {marketLog.priceRanking && <p>価格順：{marketLog.priceRanking}位</p>}
            {!inCandidateList(marketLog, marketLog.candidates.slice(0, 10)) && <p>おすすめ対象外</p>}
            {inCandidateList(marketLog, marketLog.candidates.slice(0, 10)) && marketLog.recommendRanking && (
              <p>おすすめ順：{marketLog.recommendRanking}位</p>
            )}
          </RankingArea>
        </Summary>

        {/* SMK社がトライアル利用した結果、判断するに足り得ない情報であるため、非表示にしてほしいという依頼があったため、一旦非表示にする */}
        {/* marketLog.deviationValue && marketLog.cheapLinePrice && marketLog.expensiveLinePrice && (
          <CompareFlightArea>
            <div>
              <Title>
                {marketLog.priceCheck() === 'cheap' && <p>😀選択された海外航空券の価格は安価です。</p>}
                {marketLog.priceCheck() === 'standard' && <p>😀選択された海外航空券の価格は標準的です。</p>}
                {marketLog.priceCheck() === 'expensive' && <p>🤔選択された海外航空券の価格は高価です。</p>}
              </Title>
              <BarGraphArea>
                <GraphItemPrice percent={marketLog.deviationValue}>
                  {utils.formatPrice(marketLog.price)}
                </GraphItemPrice>
                <Circle percent={marketLog.deviationValue} />
                <BarGraphGreen />
                <BarGraphYellow />
                <BarGraphRed />
              </BarGraphArea>
            </div>
            <RankingArea>
              {marketLog.priceRanking && <p>価格順：{marketLog.priceRanking}位</p>}
              {marketLog.recommendRanking && <p>おすすめ順：{marketLog.recommendRanking}位</p>}
            </RankingArea>
          </CompareFlightArea>
        ) */}

        <CandidatesTitle>在庫管理システムのおすすめプラン</CandidatesTitle>
        <Candidates>
          {marketLog.candidates.length > 0 ? (
            <Table>
              <tbody>
                <tr>
                  <Th className="route">経路</Th>
                  <Th>料金</Th>
                  <Th>差額</Th>
                </tr>
                {marketLog.candidates.slice(0, 10).map((c, i) => (
                  <tr key={i}>
                    <td>
                      {c.transportDescriptions.map((d, i) => (
                        <CandidateRoute key={i}>
                          {d.split('\n').map((line, j) => {
                            return j === 0 ? (
                              <span key={j}>{line}</span>
                            ) : (
                              <span key={j}>&nbsp;({line.replace('便名：', '')})</span>
                            );
                          })}
                        </CandidateRoute>
                      ))}
                    </td>
                    <PriceTd>{utils.formatPrice(c.price)}</PriceTd>
                    <PriceTd>
                      <DiffPrice diff={c.price - marketLog.price} />
                    </PriceTd>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>候補が見つかりませんでした。</p>
          )}
        </Candidates>
        {utils.isTabikobo(serviceId) && (
          <>
            <CandidatesTitle>旅行代理店過去予約データ</CandidatesTitle>
            <Candidates>
              {tabikoboAviations.length > 0 ? (
                <Table>
                  <tbody>
                    <tr>
                      <Th>便名</Th>
                      <Th>シートクラス</Th>
                      <Th>搭乗日</Th>
                      <Th>受付日</Th>
                      <Th>1 人あたりのチケット代金</Th>
                    </tr>
                    {tabikoboAviations.map((t, i) => (
                      <tr key={i}>
                        <td>{t.flightNumber || '-'}</td>
                        <td>{t.className || '-'}</td>
                        <td>{t.boardingDate || '-'}</td>
                        <td>{t?.tabikoboReservation?.receptionDate || '-'}</td>
                        <td>{utils.formatPrice(t?.tabikoboReservation?.paymentPerPerson()) || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>候補が見つかりませんでした。</p>
              )}
            </Candidates>
          </>
        )}
      </>
    ) : (
      <MarketLogSample />
    )}
  </>
);

const DiffPrice: React.FC<{ diff: number }> = ({ diff }) => {
  return (
    <>
      {diff <= 0 ? (
        <LowPrice>{utils.formatPrice(diff)}</LowPrice>
      ) : (
        <HighPrice>{utils.formatPrice(diff)}</HighPrice>
      )}
    </>
  );
};

const fontSizeLarge = '1.25em';
const fontSizeSmall = '0.75em';
const backgroundColor = '#f7f7f7';

const Title = styled.h2`
  width: 100%;
  margin-top: 1em;
`;

const Info = styled.div`
  margin-bottom: 0.75em;
`;

const InfoContent = styled.div`
  display: flex;
`;

const InfoItemWrap = styled.div`
  flex-grow: 1;
  margin-right: 50px;
`;

const InfoItem = styled.div`
  display: flex;
  font-size: ${fontSizeSmall};
`;

const InfoItemLabel = styled.p`
  flex-basis: 100px;
  background-color: ${backgroundColor};
  text-align: center;
  padding: 5px 20px;
`;

const InfoItemText = styled.div`
  padding: 5px;
`;

const Summary = styled.div`
  margin-bottom: 2em;
`;

const SummaryContent = styled.div`
  display: flex;
`;

const SummaryItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  text-align: center;
  background-color: ${backgroundColor};
  padding: 10px 30px;
  margin: 0 2px;
`;

const SummaryLabel = styled.p`
  font-size: ${fontSizeSmall};
`;

const SummaryText = styled.p`
  font-weight: bold;
  font-size: 1.3em;
`;

const SummaryExplanation = styled.p`
  font-size: ${fontSizeSmall};
  text-align: right;
`;

const GraphSummaryExplanation = styled.p`
  margin-top: 10px;
  font-size: ${fontSizeSmall};
`;

const CandidatesTitle = styled.div`
  font-size: ${fontSizeLarge};
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 0.75em;
`;

const Candidates = styled.div`
  margin-bottom: 0.75em;
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 4px 0;
  table-layout: fixed;
`;

const Th = styled.th`
  text-align: center;
  font-size: ${fontSizeSmall};
  background-color: ${backgroundColor};

  &.route {
    width: 75%;
  }
`;

const PriceTd = styled.td`
  font-weight: bold;
  font-size: 1.1em;
  text-align: right;
`;

const LowPrice = styled.span`
  color: rgb(0, 128, 0);
`;

const HighPrice = styled.span`
  color: rgb(128, 0, 0);
`;

const CandidateRoute = styled.div`
  margin-bottom: 5px;
`;

/* コメントアウトしたコストインジケータ用のスタイル
const BarGraphArea = styled.div`
  margin-top: 50px;
  display: flex;
  position: relative;
  width: 400px;
`;

const BarGraphGreen = styled.div`
  height: 5px;
  width: 25%;
  background-color: #4caf50;
`;

const BarGraphYellow = styled.div`
  height: 5px;
  width: 50%;
  background-color: #ffeb3b;
`;

const BarGraphRed = styled.div`
  height: 5px;
  width: 25%;
  background-color: #f44336;
`;

const Circle = styled.div<{ percent: number }>`
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px #999 solid;
  position: absolute;
  left: ${props => props.percent}%;
  top: -5px;
`;

const GraphItemPrice = styled.div<{ percent: number }>`
  position: absolute;
  left: ${props => props.percent}%;
  top: -50px;
  padding: 5px;
  background-color: #f7f7f7;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: 5px;
    bottom: -10px;
    border-top: 10px solid #f7f7f7;
    border-right: 10px solid transparent;
    border-left: 5px solid transparent;
  }
`;

const CompareFlightArea = styled.div`
  display: flex;
  align-items: center;
`;
*/

const RankingArea = styled.div`
  margin-top: 10px;
`;

export const Image = styled.img`
  height: 20px;
  margin-right: 5px;
`;

export default FlightMarketLogTemplate;
