import { Link as baseLink } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { ABase } from '@this/components/shared/atoms/a';

/**
 * @deprecated
 * should use js/src/components/shared/ui/navigations/link
 */
const Link = styled(baseLink)`
  ${ABase}
`;

export default Link;
