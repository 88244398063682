import React, { useCallback, useEffect, useRef, useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

import { styled } from '@this/constants/themes';
import { ThumbsUpFillIcon } from '@this/src/components/shared/icons/thumbs_up_fill_icon';
import { ThumbsUpOutlineIcon } from '@this/src/components/shared/icons/thumbs_up_outline_icon';
import { FeedbackModal } from '@this/src/components/shared/ui/feedbacks/modal/feedback_modal/feedback_modal';
import type SearchQueryItem from '@this/src/domain/search_query_item';

interface Props {
  searchQueryItem: SearchQueryItem;
}

const DURATIONS = {
  closeRecommended: 1000,
  closeDisplayanimate: 300,
  closeSnackbar: 3000
};

const FEEDBACK_OPTIONS = [
  { value: 'price', label: '価格が期待と異なる' },
  { value: 'location', label: '立地条件が合わない' },
  { value: 'facility', label: '施設・設備が不十分' },
  { value: 'review', label: 'レビュー・評価への不安' },
  { value: 'ai', label: 'おすすめ理由が不適切' },
  { value: 'other', label: 'その他' }
];

export const HotelSelectedReasonFeedbackDurations = DURATIONS;
export const HotelSelectedReasonFeedbackOptions = FEEDBACK_OPTIONS;

export const HotelSelectedReasonFeedback: React.FC<Props> = ({ searchQueryItem }) => {
  const [recommended, setRecommended] = useState(false);
  const [display, setDisplay] = useState(true);
  const [snackbar, setSnackbar] = useState<string | null>(null);
  const [dialog, setDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const feedbackMessage = useRef<HTMLTextAreaElement>(null);
  const rating = searchQueryItem.aiHotelRecommendationQuality;

  const handleThumbsUp = useCallback(() => {
    searchQueryItem.setAiHotelRecommendation(rating === 'good' ? 'not_rated' : 'good');
    setSnackbar('フィードバックをお寄せいただきありがとうございます！');
  }, [searchQueryItem, rating]);

  const handleThumbsDown = useCallback(() => {
    searchQueryItem.setAiHotelRecommendation(rating === 'bad' ? 'not_rated' : 'bad');
    setDialog(true);
  }, [searchQueryItem, rating]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbar(null);
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialog(false);
  }, []);

  const handleDialogSubmit = useCallback(() => {
    const feedback =
      selectedOption === 'other'
        ? feedbackMessage.current?.value
        : FEEDBACK_OPTIONS.find(option => option.value === selectedOption)?.label;

    if (feedback) {
      searchQueryItem.setAiHotelRecommendationFeedback(feedback);
      searchQueryItem.commitAiHotelRecommendationFeedback();
      setSnackbar('フィードバックをお寄せいただきありがとうございます！');
    }

    setDialog(false);
  }, [searchQueryItem, selectedOption]);

  useEffect(() => {
    if (rating === 'not_rated' || !display) {
      return () => {};
    }

    const timeouts = [
      setTimeout(() => setRecommended(true), DURATIONS.closeRecommended),
      setTimeout(() => setDisplay(false), DURATIONS.closeRecommended + DURATIONS.closeDisplayanimate)
    ];

    return () => timeouts.forEach(timeout => clearTimeout(timeout));
  }, [rating, display]);

  return (
    <>
      <Actions recommended={recommended} display={display}>
        <IconButton size="small" onClick={handleThumbsUp}>
          {rating === 'good' ? <ThumbsUpFillIcon /> : <ThumbsUpOutlineIcon />}
        </IconButton>
        <RotateIconButton size="small" onClick={handleThumbsDown}>
          {rating === 'bad' ? <ThumbsUpFillIcon /> : <ThumbsUpOutlineIcon />}
        </RotateIconButton>
      </Actions>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(snackbar)}
        onClose={handleSnackbarClose}
        message={snackbar}
        autoHideDuration={DURATIONS.closeSnackbar}
      />
      <FeedbackModal open={dialog} onClose={handleDialogClose} onSubmit={handleDialogSubmit}>
        <RadioGroup>
          {FEEDBACK_OPTIONS.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio color="primary" />}
              label={option.label}
              onChange={() => setSelectedOption(option.value)}
            />
          ))}
        </RadioGroup>
        {selectedOption === 'other' && (
          <TextField variant="outlined" multiline fullWidth rows={3} inputRef={feedbackMessage} />
        )}
      </FeedbackModal>
    </>
  );
};

const Actions = styled.div<{ display: boolean; recommended: boolean }>`
  display: ${props => (props.display ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  transition: ${DURATIONS.closeDisplayanimate}ms;

  ${props =>
    props.recommended
      ? `
    opacity: 0;
    visibility: hidden;
  `
      : `
    opacity: 1;
    visibility: visible;
  `}
`;

const RotateIconButton = styled(IconButton)`
  transform: rotate(180deg);
`;
