import React from 'react';
import {
  OrganizationHeader,
  OrganizationTitle,
  OrganizationBody,
  OrganizationButtonTh,
  OrganizationTdButton,
  OrganizationTable,
  OrganizationTh,
  OrganizationTd
} from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import Button from '@this/shared/atoms/button';
import Context from '@this/components/core_app/core_app_context';
import { observer } from 'mobx-react';
import { PaymentEditForm } from '@this/components/organization/payment/payment_edit_form';
import type { PaymentGatewayUser } from '@this/domain/organization/payment_gateway_user';
import { PaymentSetting } from '@this/domain/organization/payment_setting';
import { DeleteMasterButton } from '@this/components/organization/delete-master-button';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError, trackEvent } from '@this/src/util';

interface Props {}

interface State {
  loading: boolean;
  showModal: boolean;
  setting: PaymentSetting | null;
  targetPaymentGatewayUser: PaymentGatewayUser | null;
  saving: boolean;
}

interface PaymentSettingResponse {
  payment_setting: any;
}

@observer
class Payment extends React.Component<Props, State> {
  static contextType = Context;

  context!: React.ContextType<typeof Context>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      targetPaymentGatewayUser: null,
      setting: null,
      saving: false
    };
  }

  setSonyPaymentToken = (token: string, maskedCreditCard: string) => {
    const params = { token, maskedCreditCard };
    Fetcher.post<Response>('/organization/payment/add_credit_card.json', params)
      .then(() => {
        trackEvent('Payment succeed');
        location.reload();
      })
      .catch(e => {
        if (e instanceof HTTPError && e.response?.status === 400) {
          Notification.error(e.response.data.errors);
        }
        window.setTimeout(() => {
          location.reload();
        }, 5000);
      });
  };

  handleAddCreditCard() {
    SpsvApi.spsvCardInfoScreen();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.fetchPaymentSetting();

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.state?.setting?.tokenGenerateScriptUrl || '';
    script.setAttribute('callBackFunc', 'setSonyPaymentToken');
    script.className = 'spsvToken';
    document.head.appendChild(script);

    window.setSonyPaymentToken = this.setSonyPaymentToken;

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.setSonyPaymentToken = null;
  }

  async fetchPaymentSetting() {
    await Fetcher.get<PaymentSettingResponse>('/organization/payment.json').then(result => {
      const paymentSetting = new PaymentSetting(result.payment_setting);
      this.setState({ setting: paymentSetting });
    });
  }

  async updatePaymentGatewayUser(paymentGatewayUser: PaymentGatewayUser) {
    this.setState({ saving: true });
    try {
      await Fetcher.put(`/organization/payment`, paymentGatewayUser.submitParams());
      await this.fetchPaymentSetting();
    } finally {
      this.setState({ saving: false, showModal: false });
    }
  }

  handleEditClick(paymentGatewayUser: PaymentGatewayUser) {
    this.setState({ showModal: true, targetPaymentGatewayUser: paymentGatewayUser });
  }

  handleAbortPaymentEdit() {
    this.setState({ showModal: false });
  }

  async handleUseSystemFeeButtonClick(paymentGatewayUser: PaymentGatewayUser) {
    this.setState({ loading: true });
    try {
      await Fetcher.put(`/organization/payment/use_system_fee`, { id: paymentGatewayUser.id });
      await this.fetchPaymentSetting();
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleDeleteGatewayUserButtonClick(paymentGatewayUser: PaymentGatewayUser) {
    this.setState({ saving: true });
    try {
      await Fetcher.delete(`/organization/payment`, { id: paymentGatewayUser.id });

      location.reload();
    } finally {
      this.setState({ saving: false });
    }
  }

  render() {
    const { loading, showModal, setting, targetPaymentGatewayUser, saving } = this.state;
    // const params = useParams();
    return (
      <>
        <OrganizationTitle>支払い設定</OrganizationTitle>
        <OrganizationHeader>
          <TheButton onClick={() => this.handleAddCreditCard()}>クレジットカード追加</TheButton>
        </OrganizationHeader>
        <OrganizationBody>
          {loading ? (
            <SimpleLoading />
          ) : (
            <OrganizationTable>
              <thead>
                <tr>
                  <OrganizationTh>名前</OrganizationTh>
                  <OrganizationTh>カード番号</OrganizationTh>
                  {setting?.systemFee.paymentMethodType === 'credit_card' && (
                    <OrganizationTh>システム利用料の支払い</OrganizationTh>
                  )}
                  <OrganizationButtonTh>編集</OrganizationButtonTh>
                  <OrganizationButtonTh>削除</OrganizationButtonTh>
                </tr>
              </thead>
              <tbody>
                {setting?.gatewayMembers.map((member, i) => (
                  <tr key={i}>
                    <OrganizationTd>{member.name}</OrganizationTd>
                    <OrganizationTd>{member.maskedCreditCardNumber}</OrganizationTd>
                    {setting?.systemFee.paymentMethodType === 'credit_card' && (
                      <OrganizationTd>
                        <input
                          id="use-system-fee-{member.id}"
                          type="checkbox"
                          checked={member.useSystemFee}
                          onChange={e => this.handleUseSystemFeeButtonClick(member)}
                        />
                        <span>利用する</span>
                      </OrganizationTd>
                    )}
                    <OrganizationTd>
                      <OrganizationTdButton onClick={e => this.handleEditClick(member)}>編集</OrganizationTdButton>
                    </OrganizationTd>
                    <OrganizationTd>
                      <DeleteMasterButton
                        targetLabel={member.name}
                        onConfirm={() => {
                          this.handleDeleteGatewayUserButtonClick(member);
                        }}
                      />
                    </OrganizationTd>
                  </tr>
                ))}
              </tbody>
            </OrganizationTable>
          )}
        </OrganizationBody>

        {targetPaymentGatewayUser && (
          <PaymentEditForm
            open={showModal}
            paymentGatewayUser={targetPaymentGatewayUser}
            submitting={saving}
            onAbort={() => this.handleAbortPaymentEdit()}
            onSubmit={targetPaymentGatewayUser => this.updatePaymentGatewayUser(targetPaymentGatewayUser)}
          />
        )}
      </>
    );
  }
}

const TheButton = styled(Button)`
  margin-right: auto;
  padding: 3px 20px;
`;

export default Payment;
