const TripStatus: { [key: number]: string } = {
  0: '旅程リクエスト中',
  1: '要空室確認',
  2: '決済待ち',
  3: '要予約',
  4: '予約確定',
  5: 'キャンセル',
  6: 'キャンセル依頼中',
  7: '完了'
};

export default TripStatus;

export type TripStatusKey = keyof typeof TripStatus;

export const TripStatusColor: { [key: number]: string } = {
  0: 'Red',
  1: 'Yellow',
  2: 'Orange',
  3: 'Green',
  4: 'Blue',
  5: 'Black',
  6: 'Gray',
  7: 'Purple'
};

export const TripStatusAvailable: readonly TripStatusKey[] = [0, 3, 4, 5, 6, 7];

export const TripStatusOngoing: readonly TripStatusKey[] = [0, 3, 4, 6];
