import type { PaymentGatewayMember } from '@this/domain/reserve_info';
import { observable } from 'mobx';

export type OrganizationSystemFeeSettingPaymentMethodType = 'invoice' | 'credit_card';
export type OrganizationSystemFeeSettingPaymentGatewayType = 'gmo' | 'sony';
export type OrganizationSystemFeeSettingBillingCycle = 'monthly' | 'yearly';

export type OrganizationSystemFeeSettingArgs = {
  id: number;
  organization_id: number;
  payment_gateway_token_generate_script_url: string;
  payment_method_type: OrganizationSystemFeeSettingPaymentMethodType;
  payment_gateway_type: OrganizationSystemFeeSettingPaymentGatewayType;
  billing_cycle: OrganizationSystemFeeSettingBillingCycle;
};

export interface OrganizationSystemFeeSettingJson {
  id: number;
  organization_id: number;
  payment_method_type: OrganizationSystemFeeSettingPaymentMethodType;
  payment_gateway_type: OrganizationSystemFeeSettingPaymentGatewayType;
  billing_cycle: OrganizationSystemFeeSettingBillingCycle;
  payment_gateway_token_generate_script_url: string;
  payment_gateway_member: PaymentGatewayMember;
}

export class OrganizationSystemFeeSetting {
  readonly id: number;

  readonly organizationId: number;

  readonly paymentGatewayTokenGenerateScriptUrl: string;

  @observable
  paymentMethodType: OrganizationSystemFeeSettingPaymentMethodType;

  @observable
  paymentGatewayType: OrganizationSystemFeeSettingPaymentGatewayType;

  billingCycle: OrganizationSystemFeeSettingBillingCycle;

  constructor(args: OrganizationSystemFeeSettingArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.paymentGatewayTokenGenerateScriptUrl = args.payment_gateway_token_generate_script_url;
    this.paymentGatewayType = args.payment_gateway_type;
    this.paymentMethodType = args.payment_method_type;
    this.billingCycle = 'monthly'; // 支払いサイクルはいったん月で固定
  }

  submitParams() {
    return {
      id: this.id,
      organization_id: this.organizationId,
      payment_method_type: this.paymentMethodType,
      payment_gateway_type: this.paymentGatewayType,
      billing_cycle: this.billingCycle
    };
  }
}
