import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';

import Calendar from 'rc-calendar';
import type { Moment } from 'moment';
import moment from 'moment';
import 'moment/locale/ja';
import TimePicker from 'rc-time-picker';

import { TextField } from '../search/search';

const DatePicker = require('rc-calendar/lib/Picker');
const jaJP = require('rc-calendar/lib/locale/ja_JP');

interface Props {
  value?: Moment;
  onChange: (value: Moment) => void;
  onClear?: () => void;
  showTime?: boolean;
  showPast?: boolean;
  showToday?: boolean;
  dateFormat?: string;
  width?: string;
  border?: boolean;
  theme: { themeClass: string };
  disabledDays?: number;
  className?: string;
  calenderClass?: string;
  deletable?: boolean;
  placeholder?: string;
  dateFieldId?: string;
  disabledDate?: (current: Moment | undefined) => boolean;
}

interface State {
  showDateInput: boolean;
  disabled: boolean;
}

type DatePickerChildFunc = (props: { value: Moment }) => void;

/**
 * @deprecated
 * 依存しているrc-calendarがメンテナンスされてないため非推奨
 * must use js/src/components/shared/ui/inputs/datepicker
 */
class DatetimePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDateInput: true,
      disabled: false
    };
  }

  getFormat = (): string => (this.props.dateFormat ? this.props.dateFormat : 'YYYY年MM月DD日');

  disabledDate = (current: Moment | undefined) => {
    if (this.props.showPast || !current) {
      // allow empty select
      return false;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    if (this.props.showToday) {
      date.add(-1, 'day');
    }

    if (this.props.disabledDays) {
      date.add(this.props.disabledDays.valueOf(), 'day');
    }

    return current.valueOf() < date.valueOf();
  };

  onChange = (value: Moment | null) => {
    if (!this.props.deletable && !value) {
      if (this.props.onClear) {
        this.props.onClear();
      }
      return;
    }
    if (value) {
      this.props.onChange(value);
    }
  };

  onTimeClose = () => {};

  renderTimePicker = () => {
    if (this.props.showTime) {
      return (
        <TimePicker
          defaultValue={this.defaultValue}
          value={this.props.value}
          showSecond={false}
          onChange={this.onChange}
          onClose={this.onTimeClose}
        />
      );
    }
    return null;
  };

  get defaultValue(): Moment {
    return this.props.value ?? moment();
  }

  render() {
    const state = this.state;
    const props = this.props;

    const calendar = (
      <Calendar
        className={`${props.theme.themeClass} ${props.calenderClass ? props.calenderClass : ''}`}
        locale={jaJP}
        dateInputPlaceholder={this.getFormat()}
        format={this.getFormat()}
        defaultValue={this.defaultValue}
        showDateInput={state.showDateInput}
        showToday={!!this.props.showPast || !!this.props.showToday}
        disabledDate={this.props.disabledDate || this.disabledDate}
        onClear={() => props.onClear && props.onClear()}
      />
    );
    const input: DatePickerChildFunc = ({ value }) => (
      <span tabIndex={0}>
        <DateField
          id={this.props.dateFieldId}
          placeholder={this.props.placeholder ?? 'please select'}
          style={{ width: this.props.width ? this.props.width : 120 }}
          disabled={state.disabled}
          readOnly
          tabIndex={-1}
          className="ant-calendar-picker-input ant-input"
          value={(value && value.format(this.getFormat())) || ''}
          border={props.border}
        />
      </span>
    );
    return (
      <div className={props.className}>
        <div
          className="datepicker"
          style={{
            boxSizing: 'border-box',
            display: 'block',
            lineHeight: 1.5
          }}
        >
          <DatePicker
            id={this.props.dateFieldId}
            animation="slide-up"
            calendar={calendar}
            value={props.value}
            style={{ zIndex: 5000 }}
            onChange={this.onChange}
            onSelect={this.onChange}
          >
            {input}
          </DatePicker>
        </div>
        {this.renderTimePicker()}
      </div>
    );
  }
}

const DateField = styled(props => <TextField {...props} />)<{ border?: boolean }>`
  &&&& {
    height: 24px;
    padding: ${props => (props.border ? '0 4px' : 0)};
    background-color: white;
    border: ${props => (props.border ? '1px solid #ccc' : 'inherit')};
  }
`;

export default withTheme(DatetimePicker);
