import type { FormEvent } from 'react';
import React, { useCallback, useState } from 'react';
import axios from 'axios';

import { styled } from '@this/constants/themes';
import { FormControl, InputLabel } from '@this/src/components/shared/ui/inputs/form_control';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { getColor, getSpacing } from '@this/src/components/shared/ui/theme';
import type Trip from '@this/src/domain/trip/trip';
import Notification from '../../../../notification';

type Props = {
  className?: string;
  trip: Trip;
};

export const MemoFields = ({ className, trip }: Props) => {
  const handleUserMemoSubmit = useCallback(
    async (memo: string) => {
      await axios.post('/user_memos/update_or_create.json', { memo, user_id: trip.user.id });
    },
    [trip.user.id]
  );

  const handleArrangerMemoSubmit = useCallback(
    async (memo: string) => {
      try {
        await axios.post('/arranger_memos/update_or_create.json', { memo, trip_id: trip.id });
      } catch (e) {
        utils.sendErrorObject(e);
        throw e;
      }
    },
    [trip.id]
  );

  return (
    <Root className={className}>
      <MemoForm
        inputName="user_memo"
        label="メモ"
        initialValue={utils.dig(trip, 'user', 'user_memos', 0, 'memo') ?? ''}
        placeholder="例）禁煙席・朝食付き"
        onSubmit={handleUserMemoSubmit}
      />

      <MemoForm
        inputName="arranger_memo"
        label="対応者メモ"
        initialValue={utils.dig(trip, 'arranger_memo', 'memo') ?? ''}
        onSubmit={handleArrangerMemoSubmit}
      />
    </Root>
  );
};

const MemoForm = ({
  inputName,
  label,
  initialValue,
  placeholder,
  onSubmit
}: {
  inputName: string;
  label: string;
  initialValue: string;
  placeholder?: string;
  onSubmit: (memo: string) => Promise<void>;
}) => {
  const [memo, setMemo] = useState<string>(initialValue);
  const [backupMemo, setBackupMemo] = useState<string>(initialValue);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setSubmitting(true);
      try {
        await onSubmit(memo);
        setBackupMemo(memo);
        Notification.success(`${label}を保存しました。`);
      } catch {
        Notification.error('通信環境が不安定です。画面をリロードしてからもう一度お試しください。');
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, memo, label]
  );

  const handleMemoChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setMemo(value);
  }, []);

  const handleUndoClick = useCallback(() => {
    setMemo(backupMemo);
  }, [backupMemo]);

  return (
    <form onSubmit={handleSubmit} data-test={`virtual-counter-memo-${inputName}`}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Textarea
          name={inputName}
          value={memo}
          placeholder={placeholder}
          onChange={handleMemoChange}
          data-test="virtual-counter-memo-textaea"
        />
      </FormControl>

      <ButtonGroup>
        <Button
          size="small"
          color="sub"
          loading={submitting}
          onClick={handleUndoClick}
          data-test="virtual-counter-memo-undo-button"
        >
          編集前に戻す
        </Button>
        <Button type="submit" size="small" loading={submitting} data-test="virtual-counter-memo-submit-button">
          保存
        </Button>
      </ButtonGroup>
    </form>
  );
};

const Root = styled.div``;

const Textarea = styled.textarea`
  // textareaにかかったcssを上書きしたいので、&&で無理やり詳細度を上げている
  && {
    border: 2px solid ${getColor('brand', 'primary')};
    margin: 0;
    min-height: 80px;

    &:hover {
      border: 2px solid ${getColor('brand', 'primary')};
    }
  }
`;

const ButtonGroup = styled.div`
  margin-top: ${getSpacing(2)}px;
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;
