/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import Text from '@this/components/shared/text/text';
import TranslateIgnoreText from '@this/components/shared/text/translate_ignore_text';
import { media } from '@this/components/shared/atoms/media';
import InAdvanceApprovalStatus from '@this/components/shared/in_advance_approval_status/in_advance_approval_status';
import HotelElement from '@this/domain/hotel_element';
import type Trip from '@this/domain/trip/trip';
import type NonOrderItem from '@this/domain/non_order_item';
import MarketLogHelper from '@this/domain/market_log/market_log_helper';
import type Department from '@this/domain/department/department';
import type Project from '@this/domain/project/project';
import { Fetcher, toDomesticTel } from '@this/src/util';
import Notification from '../../../notification';
import TripCardHead from './trip_card_head';
import TripCardRightTemplate from './trip_card_right.template';
import TicketingTripModal from './ticketing_trip_modal';
import CancelTripModal from './trips_chat/cancel_trip_modal';
import RequestApprovalModal from './request_approval_modal/request_approval_modal';
import FlightSeatMapModal from './flight_seat_map_modal';
import OrderItemStatusLabelTemplate from './order_item_status_label.template';

import moment from '../../../lib/moment';

interface Props {
  trip: any;
  user: any;
  service: { name: string; tel: string };
  departments: Department[];
  projects: Project[];
  tripReportAvailable: boolean;
  openDraftSearch: (trip: any) => void;
  handleDownloadClick: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  handleCancelClick: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  handleExicClick: (id: number, exicUri: string, e: React.MouseEvent<HTMLElement>) => void;
  // handleHotelCancelClick: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  showConfirmationModal?: (trip: any) => void;
  serviceId: number;
  fetchTrips: () => void;
  theme: {
    themeClass: string;
    serviceName: string;
  };
}

interface State {
  showTicketingConfirm: boolean;
  ticketingRequesting: boolean;
  showCancelConfirm: boolean;
  showRequestApproval: boolean;
  showFlightSeatMapModal: boolean;
  canceling: boolean;
}

interface Translatedline {
  label: string;
  value: string;
  translate: boolean;
}

class TripCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showTicketingConfirm: false,
      ticketingRequesting: false,
      showCancelConfirm: false,
      showRequestApproval: false,
      showFlightSeatMapModal: false,
      canceling: false
    };
  }

  showTicketingModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    return this.setState({ showTicketingConfirm: true });
  };

  showDisableNotification = () => {
    Notification.error('この海外航空券は発券期限が過ぎています。\n手配オペレーターまでチャットでご連絡ください。');
  };

  hideTicketingModal = () => {
    return this.setState({ showTicketingConfirm: false });
  };

  sendTicketingRequest = async (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      ticketingRequesting: true
    });

    try {
      await Fetcher.post(`/trips/${this.props.trip.id}/ticketing_requests.json`, {});
      this.setState({
        ticketingRequesting: false,
        showTicketingConfirm: false
      });
      this.props.fetchTrips();
    } catch {
      Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    }
  };

  showCancelModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    return this.setState({ showCancelConfirm: true });
  };

  showRequestApprovalModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    return this.setState({ showRequestApproval: true });
  };

  hideCancelModal = () => {
    return this.setState({ showCancelConfirm: false });
  };

  hideRequestApprovalModal = () => {
    return this.setState({ showRequestApproval: false });
  };

  submitRequestApproval = async (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (e) {
      e.stopPropagation();
    }
    try {
      this.setState({
        showRequestApproval: false
      });
      this.props.fetchTrips();
    } catch (e) {
      Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    }
  };

  handleCancelReservation = async (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      canceling: true
    });

    const params = {
      trip_id: this.props.trip.id,
      type: 'user'
    };

    try {
      await Fetcher.delete('/trips/cancel.json', params);
      this.setState({
        canceling: false,
        showCancelConfirm: false
      });
      this.props.fetchTrips();
    } catch {
      Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    }
  };

  endTime(): Date | null {
    const { trip } = this.props;
    const endTime = utils.dig(trip, 'endTime') || trip.order.endDate() || trip.return_time;
    return endTime;
  }

  parseApproveItemJson = (item: string) => {
    return JSON.parse(item);
  };

  content(simpleRequestType: boolean) {
    const { trip, theme /* handleHotelCancelClick */ } = this.props;
    const travelerNames: string[] = trip.travelerNames();

    return trip.draft ? (
      <BlockContent>
        <MainTable>
          <tbody>
            {trip.title ? (
              <tr>
                <MainTdData>旅程名</MainTdData>
                <MainTd>
                  <TranslateIgnoreText text={trip.title} />
                </MainTd>
              </tr>
            ) : (
              <tr />
            )}
            {trip.in_advance_approval ? (
              <tr>
                <MainTdData>申請者</MainTdData>
                <MainTd>
                  <TranslateIgnoreText text={trip.user.name} />
                </MainTd>
              </tr>
            ) : (
              <tr />
            )}
            {travelerNames &&
              travelerNames.map((tn, i) => (
                <tr key={i}>
                  {travelerNames.length > 1 ? (
                    <MainTdData>{`出張者${i + 1}`}</MainTdData>
                  ) : (
                    <MainTdData>出張者</MainTdData>
                  )}
                  <MainTd>
                    <TranslateIgnoreText text={tn} />
                  </MainTd>
                </tr>
              ))}
            <tr>
              <MainTdData>期間</MainTdData>
              <MainTd>
                <TranslateIgnoreText text={trip.dateRange()} />
              </MainTd>
            </tr>
            {trip.final_destination ? (
              <tr>
                <MainTdData>訪問先</MainTdData>
                <MainTd>
                  <TranslateIgnoreText text={trip.final_destination} />
                </MainTd>
              </tr>
            ) : (
              <tr />
            )}
            {trip.purpose ? (
              <tr>
                <MainTdData>目的</MainTdData>
                <MainTd>
                  <TranslateIgnoreText text={trip.purpose} />
                </MainTd>
              </tr>
            ) : (
              <tr />
            )}
          </tbody>
        </MainTable>
      </BlockContent>
    ) : (
      <>
        {trip.order.orderItems.map((orderItem: any, i: number) => (
          <MainContent key={`${i}`}>
            {!trip.draft && trip.currentApprovalStatus() !== 0 && ![0, 8, 9, 10, 11, 12].includes(trip.status) && (
              <HeadStatus>
                <OrderItemStatusLabelTemplate orderItem={orderItem} />
              </HeadStatus>
            )}
            {orderItem.orderItemCategory === 'arrangement_request' ? (
              <MainBlock>
                <BlockTitle>リクエストフォーム利用料</BlockTitle>
                <BlockContent>
                  <span data-wovn-ignore>{utils.digits(orderItem.totalPriceWithAll())}</span>
                  <span>円</span>
                </BlockContent>
              </MainBlock>
            ) : (
              <>
                {orderItem.elements.map((element: any, j: number) => (
                  <MainBlock key={`${i}_${j}`}>
                    {!orderItem.isFollowingElement(element) && <BlockTitle>{element.title()}</BlockTitle>}
                    <BlockContent>
                      {element.type === 'hotel' &&
                        (element.isCanceled(orderItem.orderItemCategory)
                          ? element.name && (
                              <>
                                <CanceledBlockTextL data-wovn-ignore>{element.name}</CanceledBlockTextL>
                                <CancelMessage>(キャンセル済み)</CancelMessage>
                              </>
                            )
                          : element.name && <BlockTextL data-wovn-ignore>{element.name}</BlockTextL>)}
                      {element.type === 'transport' && (element.name || '')}

                      <BlockTextM>
                        <TranslateIgnoreText text={element.summary()} />
                      </BlockTextM>
                      <BlockTextM>
                        {element.type === 'hotel' ? (
                          <>
                            <p>
                              返金：
                              {element.refundable === 'true' ? ( // 文字列で入ってくる・・・
                                <span>
                                  可
                                  {/*
                                      {trip.isOngoing &&
                                        trip.currentApprovalStatus() !== 0 &&
                                        element.isCancelable(orderItem.orderItemCategory) && (
                                          <A onClick={e => handleHotelCancelClick(element.id, e)}>
                                            （キャンセルする） → マイナビの都合で一旦ペンディング。仕様策定後再度対応する
                                            </A>
                                        )}
                                      */}
                                </span>
                              ) : element.refundable === 'false' ? (
                                <span>不可</span>
                              ) : (
                                <span>お問い合わせください</span>
                              )}
                            </p>
                            {element.structuredHotelTypeDetail().map((l: Translatedline, k: number) => {
                              return l.translate ? (
                                <BlockTextM key={`t_${k}`}>
                                  <span key={`hotel_label_t_${k}`}>{l.label}</span>
                                  <span key={`hotel_value_t_${k}`}>{l.value}</span>
                                </BlockTextM>
                              ) : (
                                <BlockTextM key={`nt_${k}`}>
                                  <span key={`hotel_label_nt_${k}`}>{l.label}</span>
                                  <span data-wovn-ignore key={`hotel_value_nt_${k}`}>
                                    {l.value}
                                  </span>
                                </BlockTextM>
                              );
                            })}
                            {element.detailPath && (
                              <BlockTextM>
                                <span>宿泊詳細情報：</span>
                                <span>
                                  <a href={element.detailPath} target="_blank" rel="noopener noreferrer">
                                    詳細はコチラ
                                  </a>
                                </span>
                              </BlockTextM>
                            )}
                            <p>
                              住所:{' '}
                              <a
                                data-wovn-ignore
                                href={element.hotelMapAddressLink()}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {element.address ? element.address : '所在地を確認'}
                              </a>
                            </p>
                            <p>
                              {element.tel && (
                                <>
                                  <span>電話番号：</span>
                                  <span data-wovn-ignore>{toDomesticTel(element.tel)}</span>
                                </>
                              )}
                            </p>
                            <Room>
                              {element.structuredRoomTypeDetail().map((l: Translatedline, k: number) => {
                                return l.translate ? (
                                  <BlockTextM key={`t_${k}`}>
                                    <span>{l.label}</span>
                                    <span>{l.value}</span>
                                  </BlockTextM>
                                ) : (
                                  <BlockTextM key={`nt_${k}`}>
                                    <span>{l.label}</span>
                                    <span data-wovn-ignore>{l.value}</span>
                                  </BlockTextM>
                                );
                              })}
                            </Room>
                            {element.hotelReservationNumber && orderItem.orderItemCategory === 'rakuten_package' && (
                              <>
                                <span>ホテル予約番号：</span>
                                <span data-wovn-ignore>{element.hotelReservationNumber}</span>
                              </>
                            )}
                          </>
                        ) : (
                          !orderItem.isPrecedingElement(element) && (
                            <>
                              {element.structuredDetail(orderItem).map((l: Translatedline, k: number) => {
                                return l.translate ? (
                                  <BlockTextM key={`t_${k}`}>
                                    <span>{l.label}</span>
                                    <span>{l.value}</span>
                                  </BlockTextM>
                                ) : (
                                  <BlockTextM key={`nt_${k}`}>
                                    <span>{l.label}</span>
                                    <span data-wovn-ignore>{l.value}</span>
                                  </BlockTextM>
                                );
                              })}
                              {orderItem.travelerShinkansenTypes() &&
                                orderItem.travelerShinkansenTypes().map((tst: string, i: number) => (
                                  <tr key={i}>
                                    {orderItem.travelerShinkansenTypes().length > 1 ? (
                                      <MainTdData>{`${i + 1}人目`}</MainTdData>
                                    ) : (
                                      <MainTdData>出張者</MainTdData>
                                    )}
                                    <MainTd>
                                      <TranslateIgnoreText text={tst} />
                                    </MainTd>
                                  </tr>
                                ))}
                              {orderItem.travelerAirlineTypes() &&
                                orderItem.travelerAirlineTypes().map((tat: string, i: number) => (
                                  <tr key={i}>
                                    {orderItem.travelerAirlineTypes().length > 1 ? (
                                      <MainTdData>{`${i + 1}人目`}</MainTdData>
                                    ) : (
                                      <MainTdData>出張者</MainTdData>
                                    )}
                                    <MainTd>
                                      <TranslateIgnoreText text={tat} />
                                    </MainTd>
                                  </tr>
                                ))}
                              {element.detailPath && (
                                <BlockTextM>
                                  <span>宿泊詳細情報：</span>
                                  <span>
                                    <a href={element.detailPath} target="_blank" rel="noreferrer noopener">
                                      詳細はコチラ
                                    </a>
                                  </span>
                                </BlockTextM>
                              )}
                            </>
                          )
                        )}
                        {!orderItem.isPrecedingElement(element) && (
                          <>
                            <Space />
                            {element.type === 'transport' && (
                              <>
                                {theme.themeClass === 'tabikobo' && orderItem.price.flightPriceChangeDetail && (
                                  <p>{orderItem.price.flightPriceChangeDetail}</p>
                                )}
                                {element.ticketingExpiredAt && (
                                  <p>
                                    発券期限：
                                    <Deadline>{element.ticketingExpiredAtStr()}</Deadline>
                                  </p>
                                )}
                              </>
                            )}
                            {MarketLogHelper.isElementMarketLogLinkAvailable(
                              element,
                              theme.serviceName,
                              trip as Trip
                            ) && (
                              <A
                                href={MarketLogHelper.elementMarketLogPath(element, trip.id)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                マーケットログを確認
                                <img
                                  className="organization-trips-detail__detail-panel__item-block__market-log-link-confirm"
                                  src="/images/external_link_icon.png"
                                  alt="link"
                                />
                              </A>
                            )}
                          </>
                        )}
                      </BlockTextM>
                      {element.transportElementAttachFile && (
                        <div style={{ marginBottom: '10px' }}>
                          <BlockTextM style={{ display: 'flex' }}>
                            <PdfIcon style={{ color: '#a72c09', marginRight: '2px' }} />
                            <A
                              href={`/trips/${element.transportElementAttachFile.tripId}/transport_element_attach_files/${element.transportElementAttachFile.id}.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {element.transportElementAttachFile.uploadFileName}
                            </A>
                          </BlockTextM>
                          {(orderItem.ticketingMethod === 'qr_east' ||
                            orderItem.ticketingMethod === 'qr_west') && (
                            <BlockTextM>
                              {orderItem.ticketingMethod === 'qr_east' ? (
                                <A
                                  href="https://aitravel.my.salesforce.com/sfc/p/#7F000002CrgE/a/TL000001LMUo/pwjAtO_q8Pn6wBGvZenzhYrsVGKLyVqbayim29VPb_Q"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  ※えきねっと発券マニュアル
                                </A>
                              ) : (
                                <A
                                  href="https://aitravel.my.salesforce.com/sfc/p/#7F000002CrgE/a/TL000001LNIn/LHHz9yTUs8Oa9bz5k7ywE7EiAhoBqjuNDUJitIYaq80"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  ※えきねっと発券マニュアル
                                </A>
                              )}
                            </BlockTextM>
                          )}
                        </div>
                      )}
                    </BlockContent>
                  </MainBlock>
                ))}
                {orderItem.eTickets.length > 0 &&
                  orderItem.eTickets.map((ticket: any, j: number) => (
                    <p key={j}>
                      <a href={ticket.link} target="_blank" rel="noopener noreferrer">
                        e-ticketを表示
                        <img
                          className="organization-trips-detail__detail-panel__item-block__market-log-link-confirm"
                          src="/images/external_link_icon.png"
                          alt="link"
                        />
                      </a>
                    </p>
                  ))}
                {orderItem.price && (
                  <MainBlock>
                    <span data-wovn-ignore>{utils.digits(orderItem.price.totalPrice())}</span>
                    <span>円</span>
                  </MainBlock>
                )}
              </>
            )}
          </MainContent>
        ))}
        {!_.isEmpty(utils.dig(trip, 'nonOrderItems')) && (
          <MainContent>
            <BlockTextL>経費予定分</BlockTextL>
            {trip.nonOrderItems.map((item: NonOrderItem, i: number) => (
              <MainBlock key={i}>
                <BlockTitle>経路{i + 1}</BlockTitle>
                {item.elements.map((element: any, j: number) => (
                  <BlockFlex key={j}>
                    <BlockFlexLabel>
                      【{element.transportType !== 'other' ? element.title() : '徒歩'}】
                    </BlockFlexLabel>
                    <BlockContent>
                      <BlockTextM>
                        <Text text={element.summary()} />
                      </BlockTextM>
                      {typeof element.detail === 'function' && (
                        <BlockTextM>
                          {element instanceof HotelElement ? (
                            <>
                              <Text data-wovn-ignore text={element.hotelTypeDetail()} />
                              {element.detailPath && (
                                <div data-wovn-ignore>
                                  <span>宿泊詳細情報：</span>
                                  <span>
                                    <a href={element.detailPath} target="_blank" rel="noreferrer noopener">
                                      詳細はコチラ
                                    </a>
                                  </span>
                                </div>
                              )}
                              <p data-wovn-ignore>
                                住所:{' '}
                                <a href={element.hotelMapAddressLink()} target="_blank" rel="noopener noreferrer">
                                  {element.address ? element.address : '所在地を確認'}
                                </a>
                              </p>
                              <p data-wovn-ignore>{element.telDescription()}</p>
                              <Text data-wovn-ignore text={element.roomTypeDetail()} />
                            </>
                          ) : (
                            <Text data-wovn-ignore text={element.detail()} />
                          )}
                          {simpleRequestType ? (
                            <Text
                              data-wovn-ignore
                              text={`【${item.tripTypeText}】${utils.digits(item.price.totalPrice())}円`}
                            />
                          ) : (
                            <Text data-wovn-ignore text={`${utils.digits(item.price.totalPrice())}円`} />
                          )}
                        </BlockTextM>
                      )}
                    </BlockContent>
                  </BlockFlex>
                ))}
                {!simpleRequestType && (
                  <>
                    <BlockTextM>
                      <Text text="※表記は現地時刻です" />
                    </BlockTextM>
                    <BlockTextM>
                      <Text data-wovn-ignore text={`${utils.digits(item.price.totalPrice())}円`} />
                    </BlockTextM>
                  </>
                )}
              </MainBlock>
            ))}
          </MainContent>
        )}
      </>
    );
  }

  getChargingDepartmentNames = () => {
    const { trip } = this.props;

    if (trip.charging_departments && trip.charging_departments.length > 0) {
      return trip.charging_departments.map((data: any) => (
        <div key={data.id} data-wovn-ignore>
          {data.code}:{data.name}
        </div>
      ));
    }

    if (trip?.order?.order_items && trip.order.order_items.length > 0) {
      return (
        trip.order.order_items &&
        trip.order.order_items.map((orderItem: any, i: number) => {
          if (orderItem.order_item_mappings.some((mapping: any) => mapping.charging_department_id == null)) {
            return <div />;
          }

          return (
            <div key={`order-item-${orderItem.id}`}>
              <div data-wovn-ignore>
                {i + 1}. {orderItem?.order_item_category_text}
              </div>
              <div style={{ marginLeft: '10px' }}>
                {orderItem.order_item_mappings.map((mapping: any) => (
                  <div key={`mapping-${mapping.charging_department_id}`} data-wovn-ignore>
                    {mapping.display_charging_department}
                  </div>
                ))}
              </div>
            </div>
          );
        })
      );
    }

    // trip.non_order_itemsが存在する場合
    if (trip?.non_order_items && trip.non_order_items.length > 0) {
      return trip.non_order_items.map((nonOrderItem: any, i: number) => {
        if (nonOrderItem.non_order_item_mappings.every((mapping: any) => mapping.charging_department_id == null)) {
          return <div />;
        }

        const mappingsToShow = trip.show_department_detail
          ? nonOrderItem.non_order_item_mappings
          : [nonOrderItem.non_order_item_mappings[0]];
        return (
          <div key={`order-item-${nonOrderItem.id}`}>
            <div data-wovn-ignore>
              {i + 1}. {nonOrderItem?.element_type === 'hotel' ? 'ホテル' : '経路'}
            </div>
            <div style={{ marginLeft: '10px' }}>
              {mappingsToShow.map((mapping: any) => (
                <div key={`mapping-${mapping.charging_department_id}`} data-wovn-ignore>
                  {mapping.display_charging_department}
                </div>
              ))}
            </div>
          </div>
        );
      });
    }
    return null;
  };

  getProjectNames = () => {
    const { trip } = this.props;

    if (trip.projects && trip.projects.list.length > 0) {
      return trip.projects.list.map((data: any) => (
        <div key={data.id} data-wovn-ignore>
          {data.code}:{data.name}
        </div>
      ));
    }

    if (trip.order && trip.order.order_items && trip.order.order_items.length > 0) {
      return (
        trip.order.order_items &&
        trip.order.order_items.map((orderItem: any, i: number) => {
          if (orderItem.order_item_mappings.some((mapping: any) => mapping.project_id == null)) {
            return <div />;
          }

          return (
            <div key={`order-item-${orderItem.id}`}>
              <div data-wovn-ignore>
                {i + 1}. {orderItem?.order_item_category_text}
              </div>
              <div style={{ marginLeft: '10px' }}>
                {orderItem.order_item_mappings.map((mapping: any) => (
                  <div key={`mapping-${mapping.project_id}`} data-wovn-ignore>
                    {mapping.display_project}
                  </div>
                ))}
              </div>
            </div>
          );
        })
      );
    }

    // trip.non_order_itemsが存在する場合
    if (trip.non_order_items && trip.non_order_items.length > 0) {
      return trip.non_order_items.map((nonOrderItem: any, i: number) => {
        if (nonOrderItem.non_order_item_mappings.every((mapping: any) => mapping.project_id == null)) {
          return <div />;
        }

        const mappingsToShow = trip.show_project_detail
          ? nonOrderItem.non_order_item_mappings
          : [nonOrderItem.non_order_item_mappings[0]];
        return (
          <div key={`order-item-${nonOrderItem.id}`}>
            <div data-wovn-ignore>
              {i + 1}. {nonOrderItem?.element_type === 'hotel' ? 'ホテル' : '経路'}
            </div>
            <div style={{ marginLeft: '10px' }}>
              {mappingsToShow.map((mapping: any) => (
                <div key={`mapping-${mapping.project_id}`} data-wovn-ignore>
                  {mapping.display_project}
                </div>
              ))}
            </div>
          </div>
        );
      });
    }
    return null;
  };

  render() {
    const {
      trip,
      user,
      service,
      departments,
      projects,
      tripReportAvailable,
      openDraftSearch,
      handleDownloadClick,
      handleCancelClick,
      handleExicClick,
      showConfirmationModal,
      serviceId
    } = this.props;

    const {
      showTicketingConfirm,
      ticketingRequesting,
      showCancelConfirm,
      showRequestApproval,
      canceling,
      showFlightSeatMapModal
    } = this.state;
    const travelerNames: string[] = trip.travelerNames();
    const simpleRequestType = trip.nonOrderItems.some(
      (item: NonOrderItem) => item.requestType === 'simple_request'
    );

    return (
      <SimpleCardWrap>
        <SimpleCard key={trip.id}>
          <CardHead>
            <TripCardHead trip={trip} endTime={this.endTime()} serviceId={serviceId} />
          </CardHead>
          <SimpleCardBody>
            <CardMain>
              <CardContent>
                {this.content(simpleRequestType)}
                {trip.in_advance_approval && (
                  <>
                    {trip.approvalStatus(trip.in_advance_approval.length - 1) === 2 && (
                      <MainInfo>
                        <Bold>■却下理由</Bold>
                        <TranslateIgnoreText text={trip.rejectReason(trip.in_advance_approval.length - 1)} />
                      </MainInfo>
                    )}
                    {trip.shinkansen_deadline && trip.approvalStatus(trip.in_advance_approval.length - 1) === 0 && (
                      <MainInfo>
                        <Red>
                          <span>新幹線・特急チケットは、</span>
                          <span data-wovn-ignore>{trip.shinkansen_deadline}</span>
                          <span>が承認期限です。</span>
                        </Red>
                      </MainInfo>
                    )}
                  </>
                )}
              </CardContent>
            </CardMain>
            <CardSub>
              <TripCardRightTemplate
                trip={trip}
                user={user}
                tripReportAvailable={tripReportAvailable}
                openDraftSearch={openDraftSearch}
                handleDownloadClick={handleDownloadClick}
                handleCancelClick={handleCancelClick}
                handleExicClick={handleExicClick}
                showConfirmationModal={showConfirmationModal}
                handleTicketingClick={this.showTicketingModal}
                handleTripCancelClick={this.showCancelModal}
                handleTripRequestApprovalClick={this.showRequestApprovalModal}
                handleTicketingDisableClick={this.showDisableNotification}
                handleShowFlightSeatMapModal={() => this.setState({ showFlightSeatMapModal: true })}
                simpleRequestType={simpleRequestType}
              />
              <SubBlock>
                <SubTitle>申請情報</SubTitle>
                <SubTable>
                  <tbody>
                    <tr>
                      <SubTdData>出張期間</SubTdData>
                      <SubTdColon>：</SubTdColon>
                      <SubTd>
                        <TranslateIgnoreText text={trip.dateRange()} />
                      </SubTd>
                    </tr>
                    {trip.in_advance_approval ? (
                      <tr>
                        <SubTdData>申請者</SubTdData>
                        <SubTdColon>：</SubTdColon>
                        <SubTd>
                          <TranslateIgnoreText text={trip.user.name} />
                        </SubTd>
                      </tr>
                    ) : (
                      <tr />
                    )}
                    {travelerNames &&
                      travelerNames.map((tn, i) => (
                        <tr key={i}>
                          {travelerNames.length > 1 ? (
                            <SubTdData>{`出張者${i + 1}`}</SubTdData>
                          ) : (
                            <SubTdData>出張者</SubTdData>
                          )}
                          <SubTdColon>：</SubTdColon>
                          <SubTd>
                            <TranslateIgnoreText text={tn} />
                          </SubTd>
                        </tr>
                      ))}
                    <tr>
                      <SubTdData>訪問先</SubTdData>
                      <SubTdColon>：</SubTdColon>
                      <SubTd>
                        <TranslateIgnoreText text={trip?.final_destination || ''} />
                      </SubTd>
                    </tr>
                    <tr>
                      <SubTdData>目的</SubTdData>
                      <SubTdColon>：</SubTdColon>
                      <SubTd>
                        <TranslateIgnoreText text={trip?.purpose || ''} />
                      </SubTd>
                    </tr>
                    <tr>
                      <SubTdData>社内管理番号</SubTdData>
                      <SubTdColon>：</SubTdColon>
                      <SubTd>
                        <TranslateIgnoreText text={trip.internal_number || ''} />
                      </SubTd>
                    </tr>
                    {trip.trip_approve_item &&
                      this.parseApproveItemJson(trip.trip_approve_item.json).map((item: any) => {
                        if (item.dataType === 'label') {
                          return <></>;
                        }
                        return (
                          <tr key={item.id}>
                            <SubTdData>
                              <span data-wovn-ignore>{item.userDisplayName}</span>
                            </SubTdData>
                            <SubTdColon>：</SubTdColon>
                            <SubTd>
                              {item.dataType !== 'calendar' && (
                                <>
                                  {item.valueCode ? (
                                    <span data-wovn-ignore>
                                      {item.valueCode}：{item.value}
                                    </span>
                                  ) : (
                                    <span data-wovn-ignore>{item.value}</span>
                                  )}
                                </>
                              )}
                              {item.dataType === 'calendar' && (
                                <span data-wovn-ignore>
                                  {item.value ? moment(item.value).format('MM月DD日') : ''}
                                </span>
                              )}
                            </SubTd>
                          </tr>
                        );
                      })}
                    {departments && departments.length > 0 ? (
                      <tr>
                        <SubTdData>費用負担部署</SubTdData>
                        <SubTdColon>：</SubTdColon>
                        <SubTd data-wovn-ignore>{this.getChargingDepartmentNames()}</SubTd>
                      </tr>
                    ) : (
                      <tr />
                    )}
                    {projects && projects.length > 0 ? (
                      <tr>
                        <SubTdData>プロジェクトコード</SubTdData>
                        <SubTdColon>：</SubTdColon>
                        <SubTd data-wovn-ignore>{this.getProjectNames()}</SubTd>
                      </tr>
                    ) : (
                      <tr />
                    )}
                  </tbody>
                </SubTable>
              </SubBlock>
              {trip.in_advance_approval && trip.in_advance_approval.length > 0 ? (
                <SubBlock>
                  <SubTitle>承認ステータス</SubTitle>
                  <InAdvanceApprovalStatus
                    trip={trip}
                    applicant={trip.applicant}
                    approvers={utils.dig(user, 'department', 'approvers') || []}
                    currentStatus={trip.currentApprovalStatus()}
                  />
                </SubBlock>
              ) : (
                ''
              )}
            </CardSub>
          </SimpleCardBody>
        </SimpleCard>
        <TicketingTripModal
          show={showTicketingConfirm}
          submitting={ticketingRequesting}
          handleHide={this.hideTicketingModal}
          handleSubmit={this.sendTicketingRequest}
        >
          {this.content(simpleRequestType)}
        </TicketingTripModal>
        <CancelTripModal
          show={showCancelConfirm}
          submitting={canceling}
          trip={trip}
          service={service}
          handleHide={this.hideCancelModal}
          handleSubmit={this.handleCancelReservation}
        >
          {this.content(simpleRequestType)}
        </CancelTripModal>
        {trip.status === 0 && showRequestApproval && (
          <RequestApprovalModal
            show={showRequestApproval}
            handleHide={this.hideRequestApprovalModal}
            handleSubmit={this.submitRequestApproval}
            trip={trip}
          >
            {this.content(simpleRequestType)}
          </RequestApprovalModal>
        )}
        <FlightSeatMapModal
          trip={trip}
          show={showFlightSeatMapModal}
          onHide={() => {
            this.setState({ showFlightSeatMapModal: false });
          }}
        />
      </SimpleCardWrap>
    );
  }
}

const SimpleCardWrap = styled.div`
  overflow: hidden;
  margin-bottom: 25px;
  border-radius: 5px;
`;

const SimpleCard = styled.div`
  background: #fff;
  transition: margin ease-in 0.3s;
`;

const SimpleCardBody = styled.div`
  display: flex;
  background: #fff;
  border-radius: 5px;
  font-size: 12px;

  ${media.sp`
    display: block;
  `}
`;

const CardMain = styled.div`
  flex-grow: 1;
`;

const CardHead = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  border-bottom: solid 1px ${props => props.theme.grayBorderColor};
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const CardContent = styled.div`
  padding: 15px;
  background-color: #f7f7f7;
`;

const MainTable = styled.table`
  width: auto;
  margin: 0 0 20px;
  border: none;
  vertical-align: top;
`;

const MainTd = styled.td`
  padding: 5px 0;
  border: none;
  vertical-align: top;
  color: ${props => props.theme.grayTextColor}
  font-weight: bold;

  ${media.sp`
    padding: 3px 0;
  `}
`;

const MainTdData = styled(MainTd)`
  width: 60px;
  color: ${props => props.theme.disabledColor};
`;

const MainBlock = styled.div``;

const MainContent = styled.div`
  padding: 12px;
  border: solid 1px ${props => props.theme.grayBorderColor};
  background-color: #ffffff;
  margin-bottom: 12px;
  position: relative;
`;

const BlockFlex = styled.div`
  display: flex;
  gap: 8px;
`;

const BlockFlexLabel = styled.div`
  min-width: 70px;
`;

const BlockTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 10px;

  ${media.sp`
    font-size: 12px;
  `}
`;

const BlockContent = styled.div`
  line-height: 1.8;
`;

const BlockTextL = styled.div`
  font-size: 14px;
  font-weight: bold;
  ${media.sp`
    font-size: 12px;
  `}
`;

const CanceledBlockTextL = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-decoration: line-through;
  ${media.sp`
    font-size: 12px;
  `}
`;

const CancelMessage = styled.div`
  color: red;
`;

const BlockTextM = styled.div``;

const Space = styled.div`
  margin-bottom: 10px;
`;

const Room = styled.div`
  margin-top: 20px;
`;

const MainInfo = styled.div`
  padding-bottom: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Red = styled.p`
  color: ${props => props.theme.redColor};
`;

const Deadline = styled.span`
  color: ${props => props.theme.redColor};
`;

const CardSub = styled.div`
  min-width: 300px;
  width: 300px;
  padding: 15px 20px;
  background: #ffffff;
  border-top: solid 1px ${props => props.theme.grayBorderColor};

  ${media.sp`
    min-width: 100%;
    width: 100%;
  `}
`;

const SubBlock = styled.div`
  margin-bottom: 20px;
`;

const SubTitle = styled.p`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;

  ${media.sp`
    font-size: 12px;
  `}
`;

const SubTable = styled.table`
  width: auto;
  margin: 0;
  border: none;
  font-size: 11px;
  vertical-align: top;

  ${media.sp`
    font-size: 10px;
  `}
`;

const SubTd = styled.td`
  padding: 4px 0;
  border: none;
  vertical-align: top;

  ${media.sp`
    padding: 3px 0;
  `}
`;

const SubTdData = styled(SubTd)`
  width: 68px;
`;

const SubTdColon = styled(SubTd)`
  width: 15px;
`;

const HeadStatus = styled.div`
  padding: 8px 14px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 14px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  width: 130px;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export default withTheme(TripCard);
