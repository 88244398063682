import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';

import {
  ReserveErrorAlert,
  StyledAlertTitle
} from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/input_customer_information.template';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Section } from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_error_section';
import type { TripDuplicationInterface } from '../../../reserve_confirm';

interface Props {
  tripDuplication: TripDuplicationInterface;
}

const TripDuplicationSection = observer(({ tripDuplication }: Props) => {
  return (
    <ReserveErrorAlert icon={<ErrorOutlineIcon />} variant="filled" severity="error">
      <StyledAlertTitle>
        {tripDuplication.user_name}さんの以下の旅程番号のご依頼内容と重複している可能性がございます
      </StyledAlertTitle>
      <Section>
        <p>・旅程番号：{tripDuplication.trip_id}</p>
        <p>
          ・重複可能性がある旅行商品：
          {tripDuplication.element_types
            .map(
              type =>
                ({
                  hotel: 'ホテル',
                  shinkansen: '新幹線',
                  express: '特急',
                  domestic_air: '国内航空券',
                  foreign_air: '海外航空券',
                  rental_car: 'レンタカー',
                  taxi: 'タクシー',
                  bus: 'バス',
                  railway_ticket: '在来線'
                }[type])
            )
            .join()}
        </p>
        <p>
          申請前に申請必要性の有無をご確認いただき、申請の実行または過去の重複可能のある申請のキャンセルをお願いします。当該旅程の
          <Link href={`/trips/${tripDuplication.trip_id}`} target="_blank" rel="noopener noreferrer">
            詳細はこちら
          </Link>
        </p>
      </Section>
    </ReserveErrorAlert>
  );
});

const Link = styled.a`
  color: #0645ad;
`;

export default TripDuplicationSection;
