import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import type OrderItem from '@this/domain/order_item';
import { Fetcher } from '@this/src/util';

interface Props {
  orderItem: OrderItem;
  showLabel: boolean;
  style?: React.CSSProperties;
  classNameForModifiedField?: (...path: (string | number)[]) => string;
}

const OrderItemWaitingForm = observer(({ orderItem, showLabel, style, classNameForModifiedField }: Props) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleChange = (value: boolean) => {
    setSaving(true);

    Fetcher.put(`/arrangement/order_items/${orderItem.id}/update_waiting`, { waiting: value })
      .then(() => orderItem.handleWaitingChange(value))
      .finally(() => {
        setSaving(false);
        setEditing(false);
      });
  };

  return (
    <Box display="flex" alignItems="center" style={style}>
      {showLabel && (
        <span
          style={{ marginRight: '5px' }}
          className={classNameForModifiedField ? classNameForModifiedField('waiting') : ''}
        >
          問い合わせ対応:
        </span>
      )}
      {saving ? (
        <>保存中...</>
      ) : editing ? (
        <Box>
          <select
            onChange={e => handleChange(e.target.value === 'true')}
            value={orderItem.waiting.toString()}
            style={{ marginBottom: '0px' }}
          >
            <option value="true">◯</option>
            <option value="false">×</option>
          </select>
        </Box>
      ) : (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!orderItem.jobType) {
              orderItem.handleJobTypeChange(0);
            }
            setEditing(true);
          }}
        >
          {orderItem.waiting ? '◯' : '×'}
        </Box>
      )}
    </Box>
  );
});

export default OrderItemWaitingForm;
