import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { SearchArea, SearchBlock, SearchLabel } from '../../search_area';
import DateRangePicker from '../../date_range_picker';

interface Props {
  startDateFrom?: Moment;
  startDateTo?: Moment;
  suppliedItemName?: string;
  setStartDateFrom: (startDateFrom?: Moment) => void;
  setStartDateTo: (startDateTo?: Moment) => void;
}

const HotelOrderItemTodoRakutenTotalSearchArea: React.FC<Props> = ({
  startDateFrom,
  startDateTo,
  suppliedItemName,
  setStartDateFrom,
  setStartDateTo
}) => {
  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>予約日</SearchLabel>
        <DateRangePicker
          from={startDateFrom}
          to={startDateTo}
          onFromChange={d => setStartDateFrom(d)}
          onToChange={d => setStartDateTo(d)}
        />
      </SearchBlock>
      {suppliedItemName && (
        <SearchBlock>
          <SearchLabel>仕入商品</SearchLabel>
          <p>{suppliedItemName}</p>
        </SearchBlock>
      )}
    </SearchArea>
  );
};

export default HotelOrderItemTodoRakutenTotalSearchArea;
