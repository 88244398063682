import { observable } from 'mobx';

const KeyText: { [key: string]: string } = {
  price_diff_score: '値段',
  distance: '目的地までの距離',
  station_distance: '最寄り駅までの距離'
};

export interface HotelWeightArgs {
  id: number;
  key: string;
  weight: number;
}

export default class HotelWeight {
  readonly id: number;

  readonly key: string;

  @observable
  weight: number;

  constructor(args: HotelWeightArgs) {
    this.id = args.id;
    this.key = args.key;
    this.weight = args.weight;
  }

  keyText() {
    return KeyText[this.key] || this.key;
  }

  submitParams() {
    return { id: this.id, weight: this.weight };
  }
}
