import React, { useCallback } from 'react';

import { styled } from '@this/constants/themes';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';
import type { ChatbotMessageFormStatus } from '@this/src/domain/chatbot/chatbot_message';

import { ChatMessageFormOrigin } from './form/ChatMessageFormOrigin';
import type { OriginFieldOptions } from './form/ChatMessageFormOrigin';
import { ChatMessageFormDate } from './form/ChatMessageFormDate';

interface Props extends ChatMessageFormOptions {
  message: ChatbotMessage;
}

export interface ChatMessageFormOptions extends OriginFieldOptions {
  onSubmit: (message: ChatbotMessage, status: ChatbotMessageFormStatus) => void;
}

export const ChatMessageForm: React.FC<Props> = ({ message, onSubmit, ...options }) => {
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit(message, 'completed');
    },
    [onSubmit, message]
  );

  const handleCancel = useCallback(() => {
    onSubmit(message, 'canceled');
  }, [onSubmit, message]);

  return (
    <Form onSubmit={handleSubmit}>
      {message.requireds?.includes('origin') && <ChatMessageFormOrigin message={message} {...options} />}
      {message.requireds?.includes('date') && (
        <>
          <ChatMessageFormDate dateType="outward_date" message={message} />
          <ChatMessageFormDate dateType="homeward_date" message={message} />
        </>
      )}
      <Actions>
        <Button type="submit" size="small" color="primary">
          続行する
        </Button>
        <Button type="button" size="small" color="sub" onClick={handleCancel}>
          中止する
        </Button>
      </Actions>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;
