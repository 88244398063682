import React from 'react';

import { styled } from '@this/constants/themes';
import { Text } from '@this/shared/ui/data_displays/typography';
import { getColor, getSpacing } from '@this/shared/ui/theme';
import { OrderItemJobType } from '@this/domain/order_item/order_item_job_type';
import { OrderItemStatus } from '@this/domain/order_item/order_item_status';
import moment from 'moment/moment';
import type { OrderItemStatusLogInfo } from '@this/components/arrangement/virtual_counter/types';

type Props = {
  className?: string;
  orderItemStatusLogInfo: OrderItemStatusLogInfo;
};

export const StatusChangeLog = ({ className, orderItemStatusLogInfo }: Props) => {
  return (
    <Root className={className}>
      <h3>ステータス変更ログ</h3>
      {Object.keys(orderItemStatusLogInfo)
        .filter(k => Number.isInteger(parseInt(k, 10)))
        .map(k => {
          const logList = orderItemStatusLogInfo[k as unknown as number];

          return (
            <details key={k}>
              <Summary>trace_id: {k} のステータス変更履歴</Summary>
              <TextBlock>
                {logList.map(log => (
                  <div key={log.id}>
                    ・{log.arranger_name || 'システム'}がtrace_id={log.trace_id}の商品ステータスを「
                    {OrderItemJobType[log.before_job_type]}-{OrderItemStatus[log.before_status]}」から「
                    {OrderItemJobType[log.after_job_type]}-{OrderItemStatus[log.after_status]}」に変更しました。(
                    {moment(log.created_at).format('MM/DD HH:mm')})
                  </div>
                ))}
              </TextBlock>
            </details>
          );
        })}
    </Root>
  );
};

const Root = styled.div``;

const Summary = styled.summary`
  cursor: pointer;
  font-weight: bold;
  margin-top: ${getSpacing(1)}px;
  margin-bottom: ${getSpacing(1)}px;
`;

const TextBlock = styled(Text).attrs({ level: 'caption' })`
  padding: ${getSpacing(1)}px;
  border: 1px solid ${getColor('border', 'divider')};
  background-color: ${getColor('background', 'primary')};
  white-space: pre-wrap;
`;
