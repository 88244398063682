export interface OrganizationAnnouncementArg {
  id: number;
  title1: string;
  title2: string;
  url: string;
}

export default class OrganizationAnnouncement {
  id: number;

  title1: string;

  title2: string;

  url: string;

  constructor(args: OrganizationAnnouncementArg) {
    this.id = args.id;
    this.title1 = args.title1;
    this.title2 = args.title2;
    this.url = args.url;
  }
}
