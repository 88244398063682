import _ from 'lodash';

import type TripApproveItemJson from './trip_approve_item_json';

class TripApproveItem {
  id: number;

  userDisplayName: string;

  dataType: string;

  display: string;

  requiredType: string;

  placeholder: string;

  businessTripType: string;

  value: string;

  valueCode: string;

  file: string;

  fileType: string;

  filePath: string;

  constructor(args: TripApproveItemJson) {
    this.id = args.id;
    this.userDisplayName = args.userDisplayName;
    this.dataType = args.dataType;
    this.display = args.display;
    this.requiredType = args.requiredType;
    this.placeholder = args.placeholder;
    this.businessTripType = args.businessTripType;
    this.value = args.value;
    this.valueCode = args.valueCode;
    this.file = args.file;
    this.fileType = args.fileType;
    this.filePath = args.filePath;
  }

  setUserDisplayName = (value: string) => {
    this.userDisplayName = value;
    app.render();
  };

  setDataType = (value: string) => {
    this.dataType = value;
    app.render();
  };

  setDisplay = (value: string) => {
    this.display = value;
    app.render();
  };

  setRequiredType = (value: string) => {
    this.requiredType = value;
    app.render();
  };

  setPlaceholder = (value: string) => {
    this.placeholder = value;
    app.render();
  };

  setBusinessTripType = (value: string) => {
    this.businessTripType = value;
    app.render();
  };

  getReservePlaceholder = (isRequiredWorkflowFlag: boolean) => {
    let requiredLavel = '';
    switch (this.requiredType) {
      case 'required':
        requiredLavel = '(必須)';
        break;
      case 'optional':
        requiredLavel = '(任意)';
        break;
      case 'required_with_workflow':
        if (isRequiredWorkflowFlag) {
          requiredLavel = '(必須)';
        } else {
          requiredLavel = '(任意)';
        }
        break;
      default:
        requiredLavel = '';
        break;
    }
    const placeholder = this.placeholder !== null ? this.placeholder : '';
    return requiredLavel + placeholder;
  };
}

export default TripApproveItem;
