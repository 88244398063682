import { observable } from 'mobx';

type TicketingMethod = 'ekinet' | 'biz_ekinet' | 'qr_east' | 'qr_west';

export type IndividualRailwaySuppliedItemArgs = {
  id: number;
  service_id: number;
  ticketing_method: TicketingMethod;
  ticketing_method_text: string;
  supplied_item_id?: number;
};

export default class IndividualRailwaySuppliedItem {
  @observable
  id: number;

  @observable
  serviceId: number;

  @observable
  ticketingMethod: TicketingMethod;

  @observable
  ticketingMethodText: string;

  @observable
  suppliedItemId: number | null;

  constructor(args: IndividualRailwaySuppliedItemArgs) {
    this.id = args.id;
    this.serviceId = args.service_id;
    this.ticketingMethod = args.ticketing_method;
    this.ticketingMethodText = args.ticketing_method_text;
    this.suppliedItemId = args.supplied_item_id || null;
  }

  submitParams() {
    return {
      supplied_item_id: this.suppliedItemId
    };
  }
}
