import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  rowNumber?: number;
  isWindowExists?: boolean;
  wing?: 'start' | 'middle' | 'end';
  side: 'right' | 'left';
}

const FlightSeatMapRowSide: React.FC<Props> = ({ rowNumber, isWindowExists, wing, side }) => {
  const contents = (): JSX.Element[] => {
    const arr = [<Wall key="wall" />];
    if (rowNumber) {
      if (!isWindowExists) arr.push(<NoWindow key="window" />);
      arr.push(<RowNumber key="number">{rowNumber}</RowNumber>);
    }
    return arr;
  };

  return (
    <Wrapper>
      {wing && <Wing side={side} />}
      <Container side={side}>{contents()}</Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div<{ side: 'right' | 'left' }>`
  aspect-ratio: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.side === 'left' ? 'row-reverse' : 'row')};
  align-items: center;
`;

const RowNumber = styled.span`
  color: #999;
  font-weight: bold;
  margin: 0 20%;
  z-index: 1;
`;

const Wall = styled.div`
  border-left: thick solid #ddd;
  height: 100%;
  z-index: 1;
`;

export const NoWindow = styled.div`
  position: relative;
  width: 1em;
  aspect-ratio: 1/3;
  border: 0.2em solid #999;
  border-radius: 20%;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.2em;
    height: 3em;
    background: #999;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(25deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-25deg);
  }
`;

const WingBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 1;
  width: 100%;
  content: '';
`;

const Wing = styled(WingBase as any)<{ side: 'right' | 'left' }>`
  background-color: #bbb;
`;

/*
const WingStart = styled(WingBase as any)<{ side: 'right' | 'left' }>`
  background: linear-gradient(
    ${props => (props.side === 'left' ? '-45deg' : '45deg')},
    #bbb 50%,
    transparent 50%
  );
`;

const WingEnd = styled(WingBase as any)<{ side: 'right' | 'left' }>`
  background: linear-gradient(
    ${props => (props.side === 'left' ? '-45deg' : '45deg')},
    transparent 50%,
    #bbb 50%
  );
`;
*/

export default FlightSeatMapRowSide;
