import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@this/shared/ui/inputs/checkbox';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@this/shared/ui/data_displays/table';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { styled } from '@this/constants/themes';
import type { AutoReservationSettingArgs } from '@this/src/domain/auto_reservation_setting';
import { AutoReservationSetting } from '@this/src/domain/auto_reservation_setting';
import { Fetcher } from '@this/src/util';
import { Title, Content } from '../god';

interface AutoReservationSettingResponse {
  auto_reservation_settings: AutoReservationSettingArgs[];
}

interface UpdateResponse {
  message: any;
}

const AutoReservation = observer(() => {
  const [AutoReservationSettings, setAutoReservationSettings] = useState<AutoReservationSetting[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchService = async () => {
    setIsLoading(true);
    await Fetcher.get<AutoReservationSettingResponse>('/god/auto_reservation_settings.json').then(response => {
      setAutoReservationSettings(response.auto_reservation_settings.map(args => new AutoReservationSetting(args)));
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchService();
  }, []);

  const updateStatus = async (id: number, active: boolean) => {
    setIsLoading(true);
    const params = { active };
    await Fetcher.put<UpdateResponse>(`/god/auto_reservation_settings/${id}`, params);
    setIsLoading(false);
  };

  const setStatusChange = (id: number, active: boolean) => {
    setAutoReservationSettings(
      AutoReservationSettings.map(setting => {
        if (setting.id === id) {
          setting.active = active;
        }
        return setting;
      })
    );
    updateStatus(id, active);
  };

  return (
    <div>
      <Title>自動予約設定(変更禁止)</Title>
      <Content>
        <Desc>
          自動予約に機能利用に関するフラグを管理します。この画面の設定がオフである限りは、いかなる自動予約設定も無効化します。
        </Desc>
        {isLoading ? (
          <Loading />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>サービス名</TableCell>
                  <TableCell>有効</TableCell>
                  {/* <TableCell>API種別</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {AutoReservationSettings.map((setting, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Label>{setting.serviceName}</Label>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={setting.active}
                        onChange={e => setStatusChange(setting.id, e.target.checked)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Content>
    </div>
  );
});

const Label = styled.label`
  font-weight: bold;
`;

const Desc = styled.p`
  margin-bottom: 20px;
`;

export default AutoReservation;
