import React from 'react';
import _ from 'lodash';

import type SearchQuery from '../../../../domain/search_query';
import type SearchQueryItem from '../../../../domain/search_query_item';
import type SearchResultItem from '../../../../domain/search_result_item';
import type SelectRepository from '../../../../domain/select_repository';
import type SelectStore from '../../../../domain/select_store';
import type HotelList from '../../../../domain/hotel/hotel_list';
import type FlightList from '../../../../domain/flight/flight_list';
import TransitList from '../../../../domain/transit/transit_list';

import SelectListAreaShortdistanceTemplate from './list_area_shortdistance.template';

export interface SelectListAreaShortdistanceProps {
  // Select.props
  query: SearchQuery;
  store: SelectStore;
  repository: SelectRepository;
  serviceId: number;

  // SelectListAreaShortdistance.props
  icon: string;
  listWrapper: TransitList | HotelList | FlightList | null;
  type: string | undefined;
  subtype: number | undefined;
  show: boolean;
  hotelPriceLimit: number | null;
  queryItem?: SearchQueryItem;
  resultItem?: SearchResultItem;
  direction?: string;
  showSearchBox: boolean;
  toggleShowSearchBox: () => void;
}

export interface SelectListAreaShortdistanceState {
  transportSegment: number | undefined;
  isOpenFlightCondition: boolean;
  errors: [];
}

class SelectListAreaShortdistance extends React.Component<
  SelectListAreaShortdistanceProps,
  SelectListAreaShortdistanceState
> {
  constructor(props: SelectListAreaShortdistanceProps) {
    super(props);
    this.state = {
      transportSegment: props.listWrapper?.segment,
      isOpenFlightCondition: false,
      errors: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: SelectListAreaShortdistanceProps) {
    if (this.props.listWrapper !== nextProps.listWrapper)
      this.setState({ transportSegment: nextProps.listWrapper?.segment });
  }

  handleTransportSegmentClick = (i: number) => () => {
    if (this.props.listWrapper) {
      this.props.listWrapper.segment = i;
      this.setState({ transportSegment: i });
    }
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!this.props.query) return;

    try {
      this.props.query.validationPromise();
      if (!this.props.query || !this.props.queryItem || !this.props.resultItem) return;

      window.history.replaceState(null, '', `${location.pathname}${this.props.query.getQueryString()}`);
      this.props.resultItem.setLoading(true);
      this.props.repository.startPartialSearch(this.props.queryItem);
    } catch (errors) {
      this.setState({ errors });
    }
  };

  setItemLoading = (loading: boolean) => {
    if (this.props.resultItem) {
      this.props.resultItem.setLoadingWithSelectedLoading(loading);
    }
  };

  handleFlightConditionSubmit = (
    filteredUpgradeSeat: boolean,
    carrierIds: string[],
    changeablePrices: string[],
    showConnectingAir: boolean
  ) => {
    if (this.props.queryItem) {
      this.props.queryItem.setFilteredUpgradeSeat(filteredUpgradeSeat);
      this.props.queryItem.setCarrierIds(carrierIds);
      this.props.queryItem.setChangeablePrices(changeablePrices);
      this.props.queryItem.setShowConnectingAir(showConnectingAir);
    }
    if (this.props.listWrapper && this.props.listWrapper instanceof TransitList) {
      this.props.listWrapper.handleFilteredUpgradeSeat(filteredUpgradeSeat);
      this.props.listWrapper.handleAirlinesChange(carrierIds);
      this.props.listWrapper.handleChangeablePriceChange(changeablePrices);
      this.props.listWrapper.handleShowConnectingAir(showConnectingAir);
    }
    this.closeFlightCondition();
    app.render();
  };

  openFlightCondition = () => this.setState({ isOpenFlightCondition: true });

  closeFlightCondition = () => this.setState({ isOpenFlightCondition: false });

  render() {
    try {
      return (
        <SelectListAreaShortdistanceTemplate
          {...this.props}
          {...this.state}
          handleTransportSegmentClick={this.handleTransportSegmentClick}
          toggleShowSearchBox={this.props.toggleShowSearchBox}
          handleSubmit={this.handleSubmit}
          setItemLoading={this.setItemLoading}
          handleFlightConditionSubmit={this.handleFlightConditionSubmit}
          openFlightCondition={this.openFlightCondition}
          closeFlightCondition={this.closeFlightCondition}
          isOpenFlightCondition={this.state.isOpenFlightCondition}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectListAreaShortdistance;
