import React from 'react';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import type { ImageFile } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import StatusLabel from './status_label';
import type { Item } from '../../../domain/expenses/item';
import ReceiptDropzone from './receipt_dropzone';

interface Props {
  item: Item;
  receiptAvailable: boolean;
  isAdmin: boolean;
}

type CalcPriceOrder = 'price' | 'time' | 'transfer';

interface State {
  calcPriceOrder: CalcPriceOrder;
}

@observer
class Detail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      calcPriceOrder: 'price'
    };
  }

  private handleFileDrop(file?: ImageFile) {
    this.props.item.receiptFile = file;
  }

  private renderReceiptDropzone() {
    const item = this.props.item;
    return (
      <>
        <Label>領収書アップロード</Label>
        {this.props.receiptAvailable ? (
          <ReceiptDropzone
            selectedPath={
              item.receiptFile
                ? item.receiptFile.preview
                : item.receiptFilePath
                ? `/biztra/images/${item.receiptFilePath}`
                : undefined
            }
            onSelect={f => this.handleFileDrop(f)}
            onRemove={() => {
              item.receiptFile = undefined;
              item.receiptFilePath = undefined;
            }}
          />
        ) : (
          <ProDescription>
            {this.props.isAdmin ? (
              <>
                ※<TheLink to="/biztra/organization/plans">ビズトラProにアップグレード</TheLink>
                すると、スマホ等で撮影した領収書やPDFファイルを添付することができます
              </>
            ) : (
              <>
                ※ビズトラProにアップグレードすると、スマホ等で撮影した領収書やPDFファイルを添付することができます
              </>
            )}
          </ProDescription>
        )}
      </>
    );
  }

  private generalExpensesInputs() {
    const { item } = this.props;
    const ownCompany = item.ownCompany();
    return (
      <>
        {item.needParticipantCompanies() && (
          <>
            <FlexItem>
              <Label>参加企業</Label>
            </FlexItem>
            <FlexItemIndent>
              <FlexItem>
                <Label>合計人数</Label>
                <Text>
                  {item.participantCompanies
                    .filter(c => c.destroy !== '1')
                    .map(c => c.peopleNum)
                    .reduce((a, b) => a + b, 0) || 0}{' '}
                  人
                </Text>
              </FlexItem>
              <FlexItem>
                <Label>平均単価</Label>
                <Text>{item.priceAverageDisplay}</Text>
              </FlexItem>
              <FlexItem>
                <Label>自社の参加人数</Label>
                <Text>{ownCompany.peopleNum} 人</Text>
              </FlexItem>
              {item.otherCompaniesToDisplay().map((c, i) => (
                <div key={i}>
                  <FlexItem>
                    <Label>会社名</Label>
                    <Text>{c.name}</Text>
                  </FlexItem>
                  <FlexItem>
                    <Label>参加人数</Label>
                    <Text>{c.peopleNum} 人</Text>
                  </FlexItem>
                </div>
              ))}
            </FlexItemIndent>
          </>
        )}
        <FlexItem>
          <Label>金額</Label>
          <Text>{item.priceDisplay} 円</Text>
        </FlexItem>
        <FlexItem>
          <Label>税区分</Label>
          <Text>{item.taxType?.name || item.expensesType.taxType?.name}</Text>
        </FlexItem>
        <FlexItem>
          <Label>支払先</Label>
          <Text>{item.payee}</Text>
        </FlexItem>
        {item.project && (
          <FlexItem>
            <Label>プロジェクト</Label>
            <Text>{item.project.codeAndName()}</Text>
          </FlexItem>
        )}
        {item.tripReport && (
          <FlexItem>
            <Label>出張報告</Label>
            <Text>
              <NavLink href={`/trip_report/${item.tripReport.id}`} isExternal>
                {item.tripReport.name}
              </NavLink>
            </Text>
          </FlexItem>
        )}
        <FlexItem>
          <Label>備考</Label>
          <Text>{item.memo}</Text>
        </FlexItem>
      </>
    );
  }

  private travelExpensesInputs() {
    const { item } = this.props;
    const { calcPriceOrder } = this.state;
    const orderOptions: { [key: string]: string } = {
      price: '価格優先',
      time: '速さ優先',
      transfer: '乗換回数優先'
    };
    const tripTypeOptionNames: { [key: string]: string } = {
      one_way: '片道',
      round_trip: '往復'
    };
    const tripOptions = [
      item.expensesTypeIsTrain() && item.useIc ? 'ICカード優先' : null,
      tripTypeOptionNames[item.tripType],
      item.expensesTypeIsTrain() ? orderOptions[calcPriceOrder] : null
    ];
    return (
      <>
        <FlexItem>
          <Label>出発と到着</Label>
          <Text>
            {item.from} から {item.to}
          </Text>
          {item.expensesTypeIsTrain() && (
            <>
              <Text>{tripOptions.filter(v => v).join(', ')}</Text>
              <Label>利用鉄道名</Label>
              <Text>{item.railwayName}</Text>
            </>
          )}
          {item.expensesTypeIsTaxi() && <Text>{tripOptions.filter(v => v).join(', ')}</Text>}
        </FlexItem>
        <FlexItem>
          <Label>金額</Label>
          <Text>{item.priceDisplay} 円</Text>
        </FlexItem>
        <FlexItem>
          <Label>税区分</Label>
          <Text>{item.taxType?.name || item.expensesType.taxType?.name}</Text>
        </FlexItem>
        <FlexItem>
          <Label>訪問先</Label>
          <Text>{item.payee}</Text>
        </FlexItem>
        {item.project && (
          <FlexItem>
            <Label>プロジェクト</Label>
            <Text>{item.project.codeAndName()}</Text>
          </FlexItem>
        )}
        {item.tripReport && (
          <FlexItem>
            <Label>出張報告</Label>
            <Text>
              <NavLink href={`/trip_report/${item.tripReport.id}`} isExternal>
                {item.tripReport.name}
              </NavLink>
            </Text>
          </FlexItem>
        )}
        <FlexItem>
          <Label>備考</Label>
          <Text>{item.memo}</Text>
        </FlexItem>
      </>
    );
  }

  render() {
    const { item } = this.props;
    return (
      <TheForm className="expenses-report-item-form">
        <FlexWrapper>
          <FlexItem>
            <StatusLabel report={item.report} />
          </FlexItem>
          <FlexItem>
            <Label>日付</Label>
            <Text>{item.paidAt.format('YYYY年M月D日')}</Text>
          </FlexItem>
          <FlexItem>
            <Label>経費科目</Label>
            <Text>{item.expensesType.nameText()}</Text>
          </FlexItem>
          {item.category === 'general_expenses' ? this.generalExpensesInputs() : this.travelExpensesInputs()}
          <div>{this.renderReceiptDropzone()}</div>
        </FlexWrapper>
      </TheForm>
    );
  }
}

const blackTxtColor = '#323232';

const baseColor = '#927230';

const TheForm = styled.form`
  width: 100%;
`;

const ProDescription = styled.div`
  font-size: 12px;
  @media screen and (max-width: 767px) {
    font-size: 0.875rem;
    margin-top: 4px;
  }
`;

const TheLink = styled(Link)`
  color: ${baseColor};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 767px) {
    gap: 24px;
  }
`;

const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const FlexItemIndent = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.label`
  color: ${blackTxtColor};
  font-size: 16px;
  font-weight: bold;
`;

const Text = styled.div`
  color: black;
  font-size: 16px;
  margin-left: 12px;
  word-wrap: break-word;
  @media screen and (max-width: 767px) {
    margin-left: unset;
  }
`;

export default Detail;
