import moment from '../lib/moment';
import type DashboardInformationInterface from './dashboard_information_interface';

export type Kind = 'other' | 'notification' | 'update_function' | 'reservation_note';
export interface Raw {
  id: number;
  title: string;
  kind: Kind;
  url: string;
  updated_at: string;
  created_at: string;
}

class Information implements DashboardInformationInterface {
  public readonly id: number;

  public readonly title: string;

  public readonly kind: Kind;

  public readonly url: string;

  public readonly updatedAt: string;

  public readonly createdAt: string;

  readonly description: string;

  readonly type: 'admin';

  constructor(raw: Raw) {
    this.id = raw.id;
    this.title = raw.title;
    this.kind = raw.kind;
    this.url = raw.url;
    const description = raw.url ? `${raw.title}\n${raw.url}` : raw.title;
    this.description = description;
    this.type = 'admin';
    this.updatedAt = moment(raw.updated_at).format('YYYY-MM-DD HH:mm:ss');
    this.createdAt = moment(raw.created_at).format('YYYY-MM-DD HH:mm:ss');
  }
}

export default Information;
