// ts化前の暫定的パーツのため、呼び出し元のts化の際に/shares/atoms/buttonに切り替える
import React from 'react';
import { createTheme, styled } from '@this/constants/themes';
import {
  ButtonBase,
  BorderButtonBase,
  ButtonBase02,
  BorderButtonBase02,
  BorderButtonBase03
} from '@this/components/shared/atoms/button';
import { AITThemeProvider } from '@this/shared/ui/theme';

interface Props {
  serviceId: number;
  buttonstyle: 'normal' | 'border';
  tag: string;
  type: 'button' | 'submit' | 'reset';
  value: string;
  width: string;
  margin: string;
  padding: string;
  to: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled: boolean;
  className: string;
}

interface State {}

class SCButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public static defaultProps = {
    buttonstyle: 'normal',
    type: '',
    width: 'auto',
    margin: '',
    padding: '5px 10px',
    to: '#',
    onClick: () => false,
    disabled: false,
    className: ''
  };

  render() {
    const theme = createTheme(this.props.serviceId);

    try {
      return (
        <AITThemeProvider theme={theme}>
          {this.props.tag === 'input' ? (
            <InputButton
              type={this.props.type}
              value={this.props.value}
              width={this.props.width}
              margin={this.props.margin}
              padding={this.props.padding}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
              className={this.props.className}
            />
          ) : this.props.tag === 'input__link_color' ? (
            <InputLinkColorButton
              type={this.props.type}
              value={this.props.value}
              width={this.props.width}
              margin={this.props.margin}
              padding={this.props.padding}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
              className={this.props.className}
            />
          ) : this.props.tag === 'input__border_color' ? (
            <InputBorderColorButton
              type={this.props.type}
              value={this.props.value}
              width={this.props.width}
              margin={this.props.margin}
              padding={this.props.padding}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
              className={this.props.className}
            />
          ) : this.props.tag === 'button' ? (
            <ButtonButton
              type={this.props.type}
              width={this.props.width}
              margin={this.props.margin}
              padding={this.props.padding}
              onClick={this.props.onClick}
              disabled={this.props.disabled}
              className={this.props.className}
            >
              {this.props.value}
            </ButtonButton>
          ) : this.props.tag === 'a__link_border_color' ? (
            <ALinkBorderColorButton
              href={this.props.to}
              width={this.props.width}
              margin={this.props.margin}
              padding={this.props.padding}
              onClick={this.props.onClick}
              className={this.props.className}
            >
              {this.props.value}
            </ALinkBorderColorButton>
          ) : (
            <>
              {this.props.buttonstyle === 'border' ? (
                <ABorderButton
                  href={this.props.to}
                  width={this.props.width}
                  margin={this.props.margin}
                  padding={this.props.padding}
                  onClick={this.props.onClick}
                  className={this.props.className}
                >
                  {this.props.value}
                </ABorderButton>
              ) : (
                <AButton
                  href={this.props.to}
                  width={this.props.width}
                  margin={this.props.margin}
                  padding={this.props.padding}
                  onClick={this.props.onClick}
                  className={this.props.className}
                >
                  {this.props.value}
                </AButton>
              )}
            </>
          )}
        </AITThemeProvider>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const InputButton = styled.input<{
  width: string;
  margin: string;
  padding: string;
}>`
  &&& {
    ${ButtonBase};
    width: ${props => props.width};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`;

const InputLinkColorButton = styled.input<{
  width: string;
  margin: string;
  padding: string;
}>`
  &&& {
    ${ButtonBase02};
    width: ${props => props.width};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`;

const InputBorderColorButton = styled.input<{
  width: string;
  margin: string;
  padding: string;
}>`
  &&& {
    ${BorderButtonBase03};
    width: ${props => props.width};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`;

const ButtonButton = styled.button<{
  width: string;
  margin: string;
  padding: string;
}>`
  ${ButtonBase};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

const AButton = styled.a<{
  width: string;
  margin: string;
  padding: string;
}>`
  ${ButtonBase};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

const ABorderButton = styled.a<{
  width: string;
  margin: string;
  padding: string;
}>`
  ${BorderButtonBase};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

const ALinkBorderColorButton = styled.a<{
  width: string;
  margin: string;
  padding: string;
}>`
  ${BorderButtonBase02};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
`;

export default SCButton;
