import React from 'react';

import MuiButton from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';

import { styled } from '@this/constants/themes';

interface Props {
  onClick: () => void;
}

export const ChatbotHistoryFooter: React.FC<Props> = ({ onClick }) => {
  return (
    <ExpandedFooter>
      <Button onClick={onClick}>新しいチャットを開始する</Button>
    </ExpandedFooter>
  );
};

const ExpandedFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 100%;
  min-height: 57px;
  border-top: 1px solid #ececec;
  position: relative;
`;

const buttonColor = '#E6DFCD';

const Button = styled(MuiButton)`
  color: #3a3a3a;
  background: ${buttonColor};
  padding: 8px 16px;

  &:hover,
  &:focus {
    background: ${darken(buttonColor, 0.1)};
  }

  & .MuiButton-label {
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
    margin-top: 1px;
    margin-bottom: -1px;
  }
`;
