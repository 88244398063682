import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@this/constants/themes';
import type { KnowledgeCategoryResponseArgs } from '@this/src/domain/knowledge_category';
import HelpBlock from './help_block.template';
import type { Manual } from '../dashboard';

interface Props {
  user: any;
  manuals: Manual[];
  serviceId: number;
}

interface Item {
  href: string;
  text: string;
}

interface Banner {
  href: string;
  src: string;
}

interface KnowledgeCategoriesResponse {
  knowledge_categories: KnowledgeCategoryResponseArgs[];
}

const getDefaultItems = (serviceId: number): Item[] => {
  switch (serviceId) {
    case 2:
      return [
        {
          href: 'https://faq.tabikobo.com/category/show/40?site_domain=houjin',
          text: 'よくある質問へのリンク'
        }
      ];
    default:
      return [];
  }
};

const getDefaultBanners = (serviceId: number): Banner[] => {
  switch (serviceId) {
    case 3:
      return [
        {
          href: 'https://biz.mynavi.jp/?utm_source=btm&utm_medium=referral&utm_campaign=202012_banner',
          src: '/images/biz_lp/mynavi/banner1.jpg'
        },
        {
          href: 'https://biz.mynavi.jp/lp/stayhomeinjapan/?utm_source=btm&utm_medium=referral&utm_campaign=202012_banner',
          src: '/images/biz_lp/mynavi/banner2.jpg'
        }
      ];
    default:
      return [];
  }
};

const HelpArea = ({ user, manuals, serviceId }: Props) => {
  const [items, setItems] = useState<Item[]>(getDefaultItems(serviceId));
  const [banners] = useState<Banner[]>(getDefaultBanners(serviceId));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchKnowledgeCategories = useCallback(() => {
    setIsLoading(true);
    utils
      .jsonPromise<KnowledgeCategoriesResponse>('/knowledge_categories')
      .then(res => {
        setItems(res.knowledge_categories.map(c => ({ href: `/knowledge_categories/${c.id}`, text: c.sf_name })));
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (serviceId !== 1) {
      return;
    }
    fetchKnowledgeCategories();
  }, [serviceId, fetchKnowledgeCategories]);

  return (
    <Wrapper>
      {user && user.organization_id && (
        <HelpBlock
          mainTitle="Guide"
          subTitle="ご利用ガイド"
          items={manuals.map((m: any, i) => ({ href: m.file_url, text: m.name }))}
        />
      )}
      <HelpBlock mainTitle="FAQ" subTitle="よくある質問" items={items} banners={banners} isLoading={isLoading} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
`;

export default HelpArea;
