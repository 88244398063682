import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';

interface Props {
  date: Moment;
  futureYear?: number;
  onChange: (date: Moment) => void;
}

interface State {
  yearList: number[];
  monthList: number[];
}

class YearMonthSelector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const yearList: number[] = [];
    for (let i = 2017; i <= new Date().getFullYear() + (props.futureYear ?? 2); i += 1) {
      yearList.push(i);
    }
    const monthList: number[] = [];
    for (let i = 1; i <= 12; i += 1) {
      monthList.push(i);
    }
    this.state = {
      yearList,
      monthList
    };
  }

  handleYearChange(value: string) {
    const date = _.clone(this.props.date);
    const year = parseInt(value, 10);
    date.set('year', year);
    this.props.onChange(date);
  }

  handleMonthChange(value: string) {
    const date = _.clone(this.props.date);
    const month = parseInt(value, 10);
    date.set('month', month - 1);
    this.props.onChange(date);
  }

  render() {
    try {
      const { date } = this.props;
      const { yearList, monthList } = this.state;
      const year = date.year();
      const month = date.month() + 1;
      return (
        <div className="flex">
          <select onChange={e => this.handleYearChange(e.target.value)} value={year}>
            {yearList.map(y => (
              <option value={y} key={y}>
                {y}年
              </option>
            ))}
          </select>
          <select onChange={e => this.handleMonthChange(e.target.value)} value={month}>
            {monthList.map(m => (
              <option value={m} key={m}>
                {m}月
              </option>
            ))}
          </select>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default YearMonthSelector;
