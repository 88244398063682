import type { Moment } from 'moment';
import moment from 'moment';
import { observable } from 'mobx';
import type { OrderItemJobTypeKey } from '../order_item/order_item_job_type';
import type { OrderItemStatusKey } from '../order_item/order_item_status';

export type JalBookingInventoryItemResponseArgs = {
  ai_operator_reservation_id: number | null;
  ai_operator_reservation_created_at: string | null;
  ai_operator_reservation_status: string | null;
  jal_reservation_id: number | null;
  jal_reservation_created_at: string | null;
  reservation_number: string | null;
  target_date: string | null;
  flight_name: string | null;
  price: number | null;
  reservation_date: string | null;
  ai_operator_job_type: OrderItemJobTypeKey | null;
  ai_operator_status: OrderItemStatusKey | null;
  ai_operator_is_inventoried: string | null;
  ai_operator_inventoried_by: string | null;
  ai_operator_inventoried_at: string | null;
  ai_operator_trip_id: number | null;
  ai_operator_trace_id: number | null;
  ai_operator_order_item_id: number | null;
  jal_job_type: OrderItemJobTypeKey | null;
  jal_status: OrderItemStatusKey | null;
  jal_is_inventoried: string | null;
  jal_inventoried_by: string | null;
  jal_inventoried_at: string | null;
  jal_trip_id: number | null;
  jal_trace_id: number | null;
  jal_order_item_id: number | null;
  last_log_message: string | null;
  last_log_message_arranger: string | null;
};

export type JalBookingInventoryItemArgs = {
  aiOperatorReservationId: number | null;
  aiOperatorReservationCreatedAt: Moment | null;
  aiOperatorReservationStatus: string | null;
  jalReservationId: number | null;
  jalReservationCreatedAt: Moment | null;
  reservationNumber: string | null;
  targetDate: Moment | null;
  flightName: string | null;
  price: number | null;
  reservationDate: Moment | null;
  aiOperatorJobType: OrderItemJobTypeKey | null;
  aiOperatorStatus: OrderItemStatusKey | null;
  aiOperatorIsInventoried: string | null;
  aiOperatorInventoriedBy: string | null;
  aiOperatorInventoriedAt: Moment | null;
  aiOperatorTripId: number | null;
  aiOperatorTraceId: number | null;
  aiOperatorOrderItemId: number | null;
  jalJobType: OrderItemJobTypeKey | null;
  jalStatus: OrderItemStatusKey | null;
  jalIsInventoried: string | null;
  jalInventoriedBy: string | null;
  jalInventoriedAt: Moment | null;
  jalTripId: number | null;
  jalTraceId: number | null;
  jalOrderItemId: number | null;
  lastLogMessage: string | null;
  lastLogMessageArranger: string | null;
};

export const convertJalBookingInventoryItemResponseToArgs = (
  response: JalBookingInventoryItemResponseArgs
): JalBookingInventoryItemArgs => {
  const args: JalBookingInventoryItemArgs = {
    aiOperatorReservationId: response.ai_operator_reservation_id,
    aiOperatorReservationCreatedAt: response.ai_operator_reservation_created_at
      ? moment(response.ai_operator_reservation_created_at)
      : null,
    aiOperatorReservationStatus: response.ai_operator_reservation_status,
    jalReservationId: response.jal_reservation_id,
    jalReservationCreatedAt: response.jal_reservation_created_at
      ? moment(response.jal_reservation_created_at)
      : null,
    reservationNumber: response.reservation_number,
    targetDate: response.target_date ? moment(response.target_date) : null,
    flightName: response.flight_name,
    price: response.price,
    reservationDate: response.reservation_date ? moment(response.reservation_date) : null,
    aiOperatorJobType: response.ai_operator_job_type,
    aiOperatorStatus: response.ai_operator_status,
    aiOperatorIsInventoried: response.ai_operator_is_inventoried,
    aiOperatorInventoriedBy: response.ai_operator_inventoried_by,
    aiOperatorInventoriedAt: response.ai_operator_inventoried_at
      ? moment(response.ai_operator_inventoried_at)
      : null,
    aiOperatorTripId: response.ai_operator_trip_id,
    aiOperatorTraceId: response.ai_operator_trace_id,
    aiOperatorOrderItemId: response.ai_operator_order_item_id,
    jalJobType: response.jal_job_type,
    jalStatus: response.jal_status,
    jalIsInventoried: response.jal_is_inventoried,
    jalInventoriedBy: response.jal_inventoried_by,
    jalInventoriedAt: response.jal_inventoried_at ? moment(response.jal_inventoried_at) : null,
    jalTripId: response.jal_trip_id,
    jalTraceId: response.jal_trace_id,
    jalOrderItemId: response.jal_order_item_id,
    lastLogMessage: response.last_log_message,
    lastLogMessageArranger: response.last_log_message_arranger
  };
  return args;
};

export default class JalBookingInventoryItem {
  @observable
  aiOperatorReservationId: number | null;

  @observable
  aiOperatorReservationCreatedAt: Moment | null;

  @observable
  aiOperatorReservationStatus: string | null;

  @observable
  jalReservationId: number | null;

  @observable
  jalReservationCreatedAt: Moment | null;

  @observable
  reservationNumber: string | null;

  @observable
  targetDate: Moment | null;

  @observable
  flightName: string | null;

  @observable
  price: number | null;

  @observable
  reservationDate: Moment | null;

  @observable
  aiOperatorJobType: OrderItemJobTypeKey | null;

  @observable
  aiOperatorStatus: OrderItemStatusKey | null;

  @observable
  aiOperatorIsInventoried: string | null;

  @observable
  aiOperatorInventoriedBy: string | null;

  @observable
  aiOperatorInventoriedAt: Moment | null;

  @observable
  aiOperatorTripId: number | null;

  @observable
  aiOperatorTraceId: number | null;

  @observable
  aiOperatorOrderItemId: number | null;

  @observable
  jalJobType: OrderItemJobTypeKey | null;

  @observable
  jalStatus: OrderItemStatusKey | null;

  @observable
  jalIsInventoried: string | null;

  @observable
  jalInventoriedBy: string | null;

  @observable
  jalInventoriedAt: Moment | null;

  @observable
  jalTripId: number | null;

  @observable
  jalTraceId: number | null;

  @observable
  jalOrderItemId: number | null;

  @observable
  lastLogMessage: string | null;

  @observable
  lastLogMessageArranger: string | null;

  constructor(args: JalBookingInventoryItemArgs) {
    this.aiOperatorReservationId = args.aiOperatorReservationId;
    this.aiOperatorReservationCreatedAt = moment(args.aiOperatorReservationCreatedAt);
    this.aiOperatorReservationStatus = args.aiOperatorReservationStatus;
    this.jalReservationId = args.jalReservationId;
    this.jalReservationCreatedAt = moment(args.jalReservationCreatedAt);
    this.reservationNumber = args.reservationNumber;
    this.targetDate = moment(args.targetDate);
    this.flightName = args.flightName;
    this.price = args.price;
    this.reservationDate = moment(args.reservationDate);
    this.aiOperatorJobType = args.aiOperatorJobType;
    this.aiOperatorStatus = args.aiOperatorStatus;
    this.aiOperatorIsInventoried = args.aiOperatorIsInventoried;
    this.aiOperatorInventoriedBy = args.aiOperatorInventoriedBy;
    this.aiOperatorInventoriedAt = args.aiOperatorInventoriedAt ? moment(args.aiOperatorInventoriedAt) : null;
    this.aiOperatorTripId = args.aiOperatorTripId;
    this.aiOperatorTraceId = args.aiOperatorTraceId;
    this.aiOperatorOrderItemId = args.aiOperatorOrderItemId;
    this.jalJobType = args.jalJobType;
    this.jalStatus = args.jalStatus;
    this.jalIsInventoried = args.jalIsInventoried;
    this.jalInventoriedBy = args.jalInventoriedBy;
    this.jalInventoriedAt = args.jalInventoriedAt ? moment(args.jalInventoriedAt) : null;
    this.jalTripId = args.jalTripId;
    this.jalTraceId = args.jalTraceId;
    this.jalOrderItemId = args.jalOrderItemId;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
  }
}
