import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';
import _ from 'lodash';
import type { IObservableArray } from 'mobx';
import { observable, action, computed } from 'mobx';

import type { InvoiceItemArgs } from './invoice_item2';
import InvoiceItem from './invoice_item2';
import type { InvoiceItemTaxArgs } from './invoice_item_tax';
import InvoiceItemTax from './invoice_item_tax';
import type { InvoiceAdjustmentArgs } from './invoice_adjustment';
import InvoiceAdjustment from './invoice_adjustment';
import type { OrganizationArgs } from '../organization/organization2';
import Organization from '../organization/organization2';
import type { InvoiceActivityArgs, InvoiceActivityStatus } from './inovice_activity';
import InvoiceActivity from './inovice_activity';
import type { InvoiceCustomCsvArgs } from '../invoice_custom_csv/invoice_custom_csv';
import InvoiceCustomCsv from '../invoice_custom_csv/invoice_custom_csv';
import InfomartPostInvoiceLog from './infomart_post_invoice_log';
import type { InfomartPostInvoiceLogArgs } from './infomart_post_invoice_log';

export type InvoiceAction = InvoiceActivityStatus;

export const INVOICE_ACTIONS: InvoiceAction[] = [
  'draft',
  'applied',
  'cancel',
  'send_back',
  'approved',
  'publish',
  'discard'
];

export interface InvoiceArgs {
  id: number;
  organization_id: number;
  invoice_number?: string;
  from?: string;
  to?: string;
  target_year: number;
  target_month: number;
  charged_at: string;
  pay_at: string;
  invoice_items?: InvoiceItemArgs[];
  invoice_item_taxes?: InvoiceItemTaxArgs[];
  invoice_adjustments?: InvoiceAdjustmentArgs[];
  published?: boolean;
  disabled?: boolean;
  organization?: OrganizationArgs;
  pdf_url?: string;
  freee_receipt_id?: number;
  created_at: string;
  updated_at: string;
  has_diff?: boolean;
  closing_day?: number;
  memo?: string;
  remark: string;
  invoice_registration_number: string;
  has_draft: boolean;
  latest_activity?: InvoiceActivityArgs;
  invoice_activities?: InvoiceActivityArgs[];
  prev_activities?: {
    prev: InvoiceActivityArgs;
    applied_admin: number | null;
    approved_admins: number[];
  };
  total_steps?: number;
  approveds?: InvoiceActivityArgs[];
  invoice_splitteds?: InvoiceSplitItem[];
  organization_name: string;
  invoice_custom_csvs?: InvoiceCustomCsvArgs[];
  infomart_post_invoice_logs?: InfomartPostInvoiceLogArgs[];
}

export interface InvoiceSplitItem {
  id: number;
  code: string;
  name: string;
  price_with_tax: number;
  invoice_number: string;
}

class Invoice {
  @observable
  id: number;

  @observable
  organizationId: number;

  @observable
  invoiceNumber: string;

  @observable
  from: Moment | null;

  @observable
  to: Moment | null;

  @observable
  targetYear: number;

  @observable
  targetMonth: number;

  @observable
  chargedAt: Moment;

  @observable
  payAt: Moment;

  @observable
  invoiceItems: IObservableArray<InvoiceItem>;

  @observable
  invoiceItemTaxes: InvoiceItemTax[];

  @observable
  invoiceAdjustments: InvoiceAdjustment[];

  @observable
  published: boolean;

  @observable
  disabled: boolean;

  @observable
  organization: Organization | null;

  @observable
  pdfUrl: string;

  @observable
  freeeReceiptId: number | null;

  @observable
  createdAt: Moment;

  @observable
  updatedAt: Moment;

  @observable
  hasDiff: boolean;

  @observable
  closingDay?: number;

  @observable
  memo?: string;

  @observable
  remark: string;

  @observable
  invoiceRegistrationNumber: string;

  @observable
  hasDraft: boolean;

  @observable
  actionMemo: string;

  @observable
  latestActivity: InvoiceActivity | null = null;

  @observable
  activities: InvoiceActivity[] = [];

  @observable
  prevActivity: InvoiceActivity | null = null;

  @observable
  appliedAdmin: number | null = null;

  @observable
  approvedAdmins: number[] = [];

  @observable
  totalSteps: number;

  @observable
  approveds: InvoiceActivity[] = [];

  @observable
  invoice_splitteds: InvoiceSplitItem[] = [];

  @observable
  organizationName: string;

  @observable
  invoiceCustomCsvs: InvoiceCustomCsv[] = [];

  @observable
  infomartPostInvoiceLogs: InfomartPostInvoiceLog[] = [];

  constructor(args: InvoiceArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.invoiceNumber = args.invoice_number || '';
    this.from = args.from ? moment(args.from) : null;
    this.to = args.to ? moment(args.to) : null;
    this.targetYear = args.target_year;
    this.targetMonth = args.target_month;
    this.chargedAt = moment(args.charged_at);
    this.payAt = moment(args.pay_at);
    this.published = args.published === true;
    this.disabled = args.disabled === true;
    this.pdfUrl = args.pdf_url || '';
    if (args.invoice_items) {
      this.invoiceItems = observable(args.invoice_items.map(raw => new InvoiceItem(raw)));
    } else {
      this.invoiceItems = observable([]);
    }
    if (args.invoice_item_taxes) {
      this.invoiceItemTaxes = args.invoice_item_taxes.map(raw => new InvoiceItemTax(raw));
    } else {
      this.invoiceItemTaxes = [];
    }
    if (args.invoice_adjustments) {
      this.invoiceAdjustments = args.invoice_adjustments.map(raw => new InvoiceAdjustment(raw));
    } else {
      this.invoiceAdjustments = [];
    }
    if (args.organization) {
      this.organization = new Organization(args.organization);
    } else {
      this.organization = null;
    }
    this.freeeReceiptId = args.freee_receipt_id || null;
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);
    this.hasDiff = args.has_diff || false;
    this.closingDay = args.closing_day;
    this.memo = args.memo;
    this.remark = args.remark;
    this.invoiceRegistrationNumber = args.invoice_registration_number;
    this.hasDraft = args.has_draft;
    this.actionMemo = '';
    if (args.latest_activity) {
      this.latestActivity = new InvoiceActivity(args.latest_activity);
    }
    if (args.invoice_activities) {
      this.activities = args.invoice_activities.map(raw => new InvoiceActivity(raw));

      if (!args.latest_activity) {
        this.latestActivity = this.activities[this.activities.length - 1];
      }
    }
    this.prevActivity = args.prev_activities?.prev ? new InvoiceActivity(args.prev_activities.prev) : null;
    this.appliedAdmin = args.prev_activities?.applied_admin || null;
    this.approvedAdmins = args.prev_activities?.approved_admins || [];
    this.totalSteps = args.total_steps || 1;
    if (args.approveds) {
      this.approveds = args.approveds.map(raw => new InvoiceActivity(raw));
    }
    this.invoice_splitteds = args.invoice_splitteds || [];
    this.organizationName = args.organization_name || '';
    if (args.invoice_custom_csvs) {
      this.invoiceCustomCsvs = args.invoice_custom_csvs.map(raw => new InvoiceCustomCsv(raw));
    }
    this.infomartPostInvoiceLogs =
      args.infomart_post_invoice_logs?.map(raw => new InfomartPostInvoiceLog(raw)) || [];
  }

  @computed
  get totalPriceWithoutTax(): number {
    return _.sum(this.invoiceItems.map(item => item.priceWithoutTax));
  }

  @computed
  get totalTax(): number {
    return _.sum(this.invoiceItems.map(item => item.tax));
  }

  @computed
  get totalPriceWithTax(): number {
    return _.sum(this.invoiceItems.map(item => item.priceWithTax));
  }

  @computed
  get totalPriceWithoutTaxFromTaxes(): number {
    return (
      _.sum(this.invoiceItemTaxes.map(item => item.priceWithoutTax)) +
      _.sum(this.invoiceAdjustments.map(item => item.priceWithoutTax))
    );
  }

  @computed
  get totalTaxFromTaxes(): number {
    return (
      _.sum(this.invoiceItemTaxes && this.invoiceItemTaxes.map(item => item.tax)) +
      _.sum(this.invoiceAdjustments.map(item => item.tax))
    );
  }

  @computed
  get totalPriceWithTaxFromTaxes(): number {
    return (
      _.sum(this.invoiceItemTaxes && this.invoiceItemTaxes.map(item => item.priceWithTax)) +
      _.sum(this.invoiceAdjustments.map(item => item.priceWithTax))
    );
  }

  @computed
  get currentStep(): number | 'last' {
    const step = this.latestActivity?.step || 1;

    // ステップが1つの場合は常に'last'
    if (this.totalSteps <= 1) {
      return 'last';
    }

    // 最後のアクションが承認以外の場合は1
    if (this.latestActivity?.status !== 'approved') {
      return 1;
    }

    // 最後のアクションが承認でかつ最後のステップの場合は'last'
    if (step >= this.totalSteps - 1) {
      return 'last';
    }

    // 最後のアクションが承認の場合は前回のステップ+1
    return step + 1;
  }

  @computed
  get showQualifiedInvoice(): boolean {
    if (this.targetYear > 2023 || (this.targetYear === 2023 && this.targetMonth >= 10)) {
      return true;
    }
    return false;
  }

  // TODO: リリース日によって変更する必要がある
  // AITRAVEL-5307リリース前の年月日の場合、「振替データcsv」は存在しないためボタン非表示
  @computed
  get showTransferJournalCsv(): boolean {
    if (this.targetYear > 2024 || (this.targetYear === 2024 && this.targetMonth >= 10)) {
      return true;
    }
    return false;
  }

  @computed
  get status(): string {
    if (this.disabled) {
      return '取下げ済';
    }
    if (this.published) {
      return '発行済み';
    }
    if (this.latestActivity?.status === 'applied') {
      return '申請中';
    }
    if (this.latestActivity?.status === 'approved') {
      if (this.totalSteps === 1) {
        return '承認済み';
      }
      if (this.latestActivity.step === this.totalSteps) {
        return '最終承認済み';
      }

      return `${this.latestActivity.step}次承認済み`;
    }
    return '下書き';
  }

  actionLabel(action: InvoiceAction): string {
    switch (action) {
      case 'draft':
        return '下書き保存';
      case 'applied':
        return '発行申請';
      case 'cancel':
        return '発行申請を取り下げ';
      case 'send_back':
        return '発行申請を差し戻し';
      case 'approved':
        if (this.totalSteps === 1) {
          return '発行申請を承認';
        }
        if (this.currentStep === 'last') {
          return '発行申請を最終承認';
        }
        return `発行申請を${this.currentStep}次承認`;

      case 'publish':
        return '請求書を発行する';
      case 'discard':
        return '発行を取り下げ';
      default:
        return '';
    }
  }

  dialogMessage(action: InvoiceAction): string {
    switch (action) {
      case 'applied':
        return '請求書の発行申請をします。よろしいですか？';
      case 'cancel':
        return '請求書の発行申請を取り下げます。よろしいですか？';
      case 'send_back':
        return '請求書の発行申請を差し戻します。よろしいですか？';
      case 'approved':
        if (this.totalSteps === 1) {
          return '請求書の発行申請を承認します。よろしいですか？';
        }
        if (this.currentStep === 'last') {
          return '請求書の発行申請を最終承認します。よろしいですか？';
        }
        return `請求書の発行申請を${this.currentStep}次承認します。よろしいですか？`;

      case 'publish':
        return '請求書を発行します。請求書を発行すると、登録された連絡先にメールが送信されます。よろしいですか？';
      case 'discard':
        return '請求書の発行を取り下げます。よろしいですか？';
      default:
        return '';
    }
  }

  submitParams(action: InvoiceAction): object {
    return {
      status: action,
      organization_id: this.organizationId,
      invoice_number: this.invoiceNumber,
      charged_at: this.chargedAt.format('YYYY-MM-DD'),
      pay_at: this.payAt.format('YYYY-MM-DD'),
      invoice_items: this.invoiceItems.map(item => item.submitParams()),
      invoice_item_taxes: this.invoiceItemTaxes.map(item => item.submitParams()),
      remark: this.remark,
      invoice_registration_number: this.invoiceRegistrationNumber,
      memo: this.actionMemo
    };
  }

  @action
  addInvoiceItem(args: InvoiceItemArgs) {
    this.invoiceItems.push(new InvoiceItem(args));
  }

  @action
  removeInvoiceItem(index: number) {
    this.invoiceItems.splice(index, 1);
  }

  @action
  addInvoiceItemTax(args: InvoiceItemTaxArgs) {
    this.invoiceItemTaxes.push(new InvoiceItemTax(args));
  }

  @action
  publish() {
    this.published = true;
  }
}

export default Invoice;
