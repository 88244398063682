import React, { useContext } from 'react';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import Button from '@this/components/shared/atoms/button';
import { getColor } from '@this/shared/ui/theme';
import GodContext from '@this/components/god/god_context';

const GodHeader: React.FC = () => {
  const godContext = useContext(GodContext);

  return (
    <TheHeader>
      {godContext.currentGod?.permission === 'administrator' ? (
        <>
          <Logo to="/god">GOD</Logo>
          <TheLink to="/god">ダッシュボード</TheLink>
          <TheLink to="/god/auto_reservations">自動予約設定</TheLink>
          <TheLink to="/god/chatbot">AIコンシェルジュ設定</TheLink>
          <TheLink to="/god/hotel_master">ホテルマスタ</TheLink>
          <TheLink to="/god/hotel_master_manage">ホテルマスタ管理</TheLink>
          <TheLink to="/god/search_query_items_manage">レベニューシェア管理</TheLink>
          <TheLink to="/god/domestic_air">国内航空券データ取得</TheLink>
          <TheLink to="/god/gods">アカウント</TheLink>
          <TheLink to="/god/simulation">あとづけマーケットログ</TheLink>
          <TheLink to="/god/system">システム情報</TheLink>
        </>
      ) : (
        <>
          <Logo to="/god/simulation">GOD</Logo>
          <TheLink to="/god/simulation">あとづけマーケットログ</TheLink>
        </>
      )}
      <LogoutButton href="/god/sign_out">ログアウト</LogoutButton>
    </TheHeader>
  );
};

const TheHeader = styled.header`
  width: 100%;
  height: auto;
  padding: 10px;
  background: ${getColor('background', 'tertiary')};
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  display: flex;
`;

const Logo = styled(Link)`
  display: block;
  padding-left: 105px;
  height: 21px;
  background-image: url('${props => props.theme.logoPath}');
  background-repeat: no-repeat;
  background-size: contain;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;

  &,
  &:hover,
  &:focus {
    color: white;
  }
`;

const TheLink = styled(Link)`
  margin-left: 20px;
  font-weight: bold;

  &,
  &:hover,
  &:focus {
    color: white;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const LogoutButton = styled(Button)`
  margin-left: auto;
  font-size: 12px;
  padding: 1px 6px;
`;

export default GodHeader;
