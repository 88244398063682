import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { darken } from 'polished';
import { media } from '@this/components/shared/atoms/media';
import { MidButton } from '@this/components/shared/atoms/button';
import Tooltip from '@this/shared/tooltip/tooltip';
import { Header, Icon, Date, Body } from '../../outline_item/outline_item.template';

import type SearchQuery from '../../../../../domain/search_query';
import type SearchQueryItem from '../../../../../domain/search_query_item';
import type SelectStore from '../../../../../domain/select_store';
import SearchBlock from '../../../../shared/search/search_block/search_block';
import SelectOutlineItem from '../../outline_item/outline_item';

interface Props {
  // Select.props
  query: SearchQuery;
  store: SelectStore;
  selectErrors: string[];
  submitting: boolean;

  handleSelectItem: () => void;

  handleAddItem?: (item_type: 'transport' | 'hotel', e: React.MouseEvent<HTMLElement>) => void;
  handleAdditionalSearch: (queryItem: SearchQueryItem, e: React.FormEvent<HTMLFormElement>) => void;
  additionalSearchErrors?: { [k in string]: string };
  handleRemoveItem?: () => void;

  handleNextButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
  handleOpenShowSearchBox: () => void;

  // ArrangementRequestForm
  isRequestForm: boolean;
}

const SelectOutlineTemplate: React.SFC<Props> = ({
  query,
  store,
  selectErrors,

  handleSelectItem,

  handleAddItem,
  handleRemoveItem,
  handleAdditionalSearch,
  additionalSearchErrors,

  handleNextButtonClick,
  submitting,

  isRequestForm,
  handleOpenShowSearchBox
}) => (
  <Outline isRequestForm={isRequestForm}>
    {(store.showPackage && store.result.type === 'airPackage'
      ? store.result.packageItems[store.result.airline]
      : store.showPackage && store.result.type === 'jrPackage'
      ? store.result.jrPackageItems
      : store.result.items
    ).map((item, i) => (
      <SelectOutlineItem
        key={item.index}
        icon={i + 1}
        item={item}
        handleClickTab={store.handleClickTab}
        handleSelectItem={handleSelectItem}
        selected={store.currentTab === item.index}
        hotelPriceLimit={item.hotelPriceLimit}
        store={store}
        direction={store.result.type !== 'airPackage' ? '' : i === 0 ? 'out' : i === 2 ? 'home' : ''}
        isRequestForm={isRequestForm}
        handleOpenShowSearchBox={handleOpenShowSearchBox}
      />
    ))}

    {store.result.type === 'separate' &&
      handleAddItem &&
      store.result.isMultipleLocationsSearchResult() &&
      (store.result.items.length === store.query.items.length ? (
        <div className="select-outline__item-block">
          <Body>
            {store.result.transportAvailable && (
              <AddButton
                type="submit"
                className="search__add-block"
                disabled={query.isItemLimit()}
                onClick={e => handleAddItem('transport', e)}
              >
                +交通機関を追加
              </AddButton>
            )}
            {store.result.hotelAvailable && (
              <AddButton
                type="submit"
                className="search__add-block"
                disabled={query.isItemLimit()}
                onClick={e => handleAddItem('hotel', e)}
              >
                +ホテルを追加
              </AddButton>
            )}
          </Body>
        </div>
      ) : store.result.items.length < store.query.items.length ? (
        <div className="select-outline__item-block">
          <Body>
            <form
              className="search"
              onSubmit={e => handleAdditionalSearch(store.query.items[store.query.items.length - 1], e)}
            >
              <SearchBlock item={store.query.items[store.query.items.length - 1]} />
              <div className="search__button-wrapper">
                {additionalSearchErrors && (
                  <div className="search__error">
                    {Object.keys(additionalSearchErrors).map((key, i) => (
                      <div className="error" key={i}>
                        {additionalSearchErrors[key]}
                      </div>
                    ))}
                  </div>
                )}
                <ButtonGroup>
                  <button type="submit" className="select-list-area__search-button" disabled={submitting}>
                    検索
                  </button>
                  <LinkButton onClick={handleRemoveItem}>キャンセル</LinkButton>
                </ButtonGroup>
              </div>
            </form>
          </Body>
        </div>
      ) : null)}

    <div className="select-outline__item-block">
      <Header>
        <Icon>↑</Icon>
        {store.result.type === 'separate' ? (
          <Date>
            合計金額：
            <Price>
              <span data-wovn-ignore>{utils.digits(store.result.getReservingTrip().totalPrice())}</span>
              <Yen>円</Yen>
            </Price>
            <Tooltip type="info">
              <div className="select__tooltip-format">
                {store.result
                  .getReservingTrip()
                  .tooltipText()
                  .split('\n')
                  .map((l: string, i: number) => (
                    <p key={i}>{l}</p>
                  ))}
              </div>
            </Tooltip>
          </Date>
        ) : (
          <PackagePriceArea>
            <Wrap>
              <Content>
                <PackageTitle>
                  パッケージプラン
                  <Tooltip type="info">
                    <div className="select__tooltip-format">
                      {store.result
                        .getPackageTrip()
                        .tooltipText()
                        .split('\n')
                        .map((l: string, i: number) => (
                          <p key={i}>{l}</p>
                        ))}
                    </div>
                  </Tooltip>
                </PackageTitle>
                <Price>
                  合計：
                  <PriceMain data-wovn-ignore>
                    {utils.digits(store.result.getPackageTrip().totalPrice())}
                  </PriceMain>{' '}
                  円 (税込)
                </Price>
              </Content>
            </Wrap>
          </PackagePriceArea>
        )}
      </Header>
    </div>

    {!isRequestForm && (
      <>
        <ReserveButton
          className={`select-pc__reserve-button ${submitting ? 'loading' : ''}`}
          onClick={handleNextButtonClick}
        >
          予約確認画面へ
        </ReserveButton>
        {selectErrors.length > 0 && (
          <div className="select__errors-bottom">
            {selectErrors.map((error, i) => (
              <div className="error" key={i}>
                {error}
              </div>
            ))}
          </div>
        )}
      </>
    )}
  </Outline>
);

const Outline = styled.div<{ isRequestForm: boolean }>`
  padding: ${props => (props.isRequestForm ? '15px 10px' : '10px 10px 60px')};
`;

export const AddButton = styled.button`
  margin-right: 10px;
  background: none;
  padding: 0;
  color: ${props => props.theme.linkColor};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${props => darken(0.1, props.theme.linkColor)};
    background: none;
  }
`;

const Price = styled.span`
  color: ${props => props.theme.redColor};
  vertical-align: text-bottom;
  display: inline;
`;

const Yen = styled.span`
  font-size: 9px;
  margin-left: 3px;
`;

const PriceMain = styled.span`
  font-size: 18px;
`;

const ReserveButton = styled.div`
  ${MidButton}
  display: none;
  position: relative;
  width: 214px;
  margin: 40px auto;
  ${media.sp`
    display: block;
  `}
`;

const PackagePriceArea = styled.div`
  display: flex;
  width: 100%;
  height: 77px;
  background: #f6f4ee;
`;

const PackageTitle = styled.div`
  font-size: 11px;
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  color: #222222;
`;

const Content = styled.div`
  margin: auto;
`;

const Wrap = styled.div`
  display: flex;
  font-weight: bold;
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  *:not(:first-child) {
    margin-left: 10px;
  }
`;

const LinkButton = styled.a``;

export default SelectOutlineTemplate;
