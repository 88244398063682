import React from 'react';
import Admin from '@this/components/admin/admin';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createTheme } from '@this/constants/themes';
import ErrorBoundary from '@this/shared/error_boundary/error_boundary';
import UserSignIn from '@this/components/user_signin';
import useApi from '@this/shared/hooks/use_api';
import { ConfirmModalContainer } from '@this/shared/ui/feedbacks/modal/confirm_modal';
import { AITThemeProvider } from '@this/shared/ui/theme';
import AdminContext from './context';

export const AdminRoot = () => {
  const { data, isLoading } = useApi<{ service_id: number; me: any }>('/admin/me');
  const serviceId = data?.service_id ?? 1;
  const theme = createTheme(serviceId);

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <AITThemeProvider theme={theme}>
        <ConfirmModalContainer>
          <AdminContext.Provider value={serviceId}>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path="/admins/sign_in"
                  render={props => <UserSignIn serviceId={serviceId} {...props} />}
                />
                <Route path="/admin" component={Admin} />
              </Switch>
            </BrowserRouter>
          </AdminContext.Provider>
        </ConfirmModalContainer>
      </AITThemeProvider>
    </ErrorBoundary>
  );
};
