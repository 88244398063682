import { observable } from 'mobx';

export type ColumnType = 'standard' | 'fixed' | 'formula';

export interface InvoiceCustomFormatFieldArgs {
  id?: number;
  name?: string;
  column_type: ColumnType;
  invoice_standard_field_id?: number;
  fixed_value?: string;
  formula?: string;
  invoice_standard_field_department_target?: 'user_department' | 'charging_department';
}

class InvoiceCustomFormatField {
  @observable
  id?: number;

  @observable
  name: string;

  @observable
  columnType: ColumnType;

  @observable
  invoiceStandardFieldId?: number;

  @observable
  fixedValue: string;

  @observable
  formula: string;

  @observable
  invoiceStandardFieldDepartmentTarget?: 'user_department' | 'charging_department';

  @observable
  preview: string;

  @observable
  removed = false;

  @observable
  previewFailed = false;

  constructor(args: InvoiceCustomFormatFieldArgs) {
    this.id = args.id;
    this.name = args.name || '';
    this.columnType = args.column_type;
    this.invoiceStandardFieldId = args.invoice_standard_field_id;
    this.fixedValue = args.fixed_value || '';
    this.formula = args.formula || '';
    this.preview = '';
    this.invoiceStandardFieldDepartmentTarget = args.invoice_standard_field_department_target;
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      column_type: this.columnType,
      invoice_standard_field_id: this.invoiceStandardFieldId,
      fixed_value: this.fixedValue,
      formula: this.formula,
      invoice_standard_field_department_target: this.invoiceStandardFieldDepartmentTarget
    };
  }

  validate(isDepartmentTableSelected: boolean): string[] {
    const errors: string[] = [];
    if (this.name === '') {
      errors.push('カラム名を入力してください');
    }
    if (this.columnType === 'standard') {
      if (this.invoiceStandardFieldId === undefined) errors.push('テーブルの項目を選択してください');
      if (isDepartmentTableSelected && !this.invoiceStandardFieldDepartmentTarget) {
        errors.push('部署種別を選択してください');
      }
    } else if (this.columnType === 'formula') {
      if (this.formula === '') errors.push('数式を入力してください');
      if (this.previewFailed) errors.push('数式の構文エラーがあります');
    }
    return errors;
  }
}

export default InvoiceCustomFormatField;
