import type { CSSProperties } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';

import CsvDropzone from '@this/components/expenses/organization/csv_bulk_action/csv_dropzone';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';

interface Props {
  loading: boolean;
  historyPath: string;
  validateResult?: FileValidatorResult;
  uploadResult?: any;
  dropZoneStyle?: CSSProperties;
  select: (file?: File) => void;
  upload: (file: File) => void;
}
export default class CsvUploadForm extends React.Component<Props> {
  handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!this.canSubmit) {
      return;
    }

    this.props.upload(this.props.validateResult!.file!);
  };

  get canSubmit(): boolean {
    return !!(this.props.validateResult && this.props.validateResult.file && !this.props.uploadResult);
  }

  render() {
    const { loading, validateResult, uploadResult, historyPath, dropZoneStyle, select } = this.props;

    return (
      <form>
        <CsvDropzone
          loading={loading}
          historyPath={historyPath}
          validateResult={validateResult}
          uploadResult={uploadResult}
          dropZoneStyle={dropZoneStyle}
          select={select}
        />
        <div className="organization__csv-actions">
          <Button onClick={this.handleSubmit} disabled={!this.canSubmit}>
            アップロード
          </Button>
        </div>
      </form>
    );
  }
}

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase}
  display: inline-block;
  margin: 0 10px;
  padding: 0.75em 1em;
`;
