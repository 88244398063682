import React from 'react';
import { Link } from '@this/shared/ui/navigations/link';
import { styled } from '@this/constants/themes';

type Props = {
  href: string;
  text: string;
};

const FaqItemForAIT: React.FC<Props> = ({ href, text }) => (
  <List>
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <ChevronIcon />
      {text}
    </Link>
  </List>
);

export const ChevronIcon: React.FC = () => (
  <img
    src="/images/help/chevron_right_outlined_icon.svg"
    alt="chevron right outlined"
    style={{ marginRight: '12px', padding: '2px 0' }}
  />
);

const List = styled.li`
  max-width: 490px;
  width: 50%;
  margin-bottom: 12px;
  &:nth-child(odd) {
    margin-right: 42px; /* 奇数番目のli要素に右側の余白を追加 */
  }

  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;

export default FaqItemForAIT;
