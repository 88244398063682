export interface GradeArgs {
  id?: number;
  name: string;
  hotel_price_limit: number;
  foreign_hotel_price_limit: number;
  weight: number;
  default_seat_classes: string[];
  shinkansen_seat_types: shinkansenSeatType[];
  shinkansen_seat_type?: shinkansenSeatType;
  default_ticket_type: TicketType;
  domestic_daily_allowance_with_stay: number;
  domestic_daily_allowance_without_stay: number;
  foreign_daily_allowance_with_stay: number;
  lodging_check: boolean;
  domestic_air_seat_upgrade: boolean;
  allow_breakfast_domestic_hotel: boolean;
  allow_breakfast_foreign_hotel: boolean;
}

export type shinkansenSeatType = 'free' | 'reserve' | 'green';

export type TicketType = 'changeable' | 'unchangeable';

class Grade {
  public id: number | null;

  public name: string;

  public hotelPriceLimit: number;

  public foreignHotelPriceLimit: number;

  public weight: number;

  public defaultSeatClasses: string[];

  public shinkansenSeatTypes: shinkansenSeatType[];

  public defaultTicketType: TicketType;

  public domesticDailyAllowanceWithStay: number;

  public domesticDailyAllowanceWithoutStay: number;

  public foreignDailyAllowanceWithStay: number;

  public lodgingCheck: boolean;

  public domesticAirSeatUpgrade: boolean;

  public allowBreakfastDomesticHotel: boolean;

  public allowBreakfastForeignHotel: boolean;

  constructor(args: GradeArgs) {
    this.id = args.id || null;
    this.name = args.name;
    this.hotelPriceLimit = args.hotel_price_limit;
    this.foreignHotelPriceLimit = args.foreign_hotel_price_limit;
    this.weight = args.weight;
    this.defaultSeatClasses = args.default_seat_classes;
    this.shinkansenSeatTypes = args.shinkansen_seat_types;
    this.defaultTicketType = args.default_ticket_type;
    this.domesticDailyAllowanceWithStay = args.domestic_daily_allowance_with_stay;
    this.domesticDailyAllowanceWithoutStay = args.domestic_daily_allowance_without_stay;
    this.foreignDailyAllowanceWithStay = args.foreign_daily_allowance_with_stay;
    this.lodgingCheck = args.lodging_check;
    this.domesticAirSeatUpgrade = args.domestic_air_seat_upgrade;
    this.allowBreakfastDomesticHotel = args.allow_breakfast_domestic_hotel;
    this.allowBreakfastForeignHotel = args.allow_breakfast_foreign_hotel;
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      hotel_price_limit: this.hotelPriceLimit,
      foreign_hotel_price_limit: this.foreignHotelPriceLimit,
      weight: this.weight,
      default_seat_classes: this.defaultSeatClasses,
      shinkansen_seat_types: this.shinkansenSeatTypes,
      default_ticket_type: this.defaultTicketType,
      domestic_daily_allowance_with_stay: this.domesticDailyAllowanceWithStay,
      domestic_daily_allowance_without_stay: this.domesticDailyAllowanceWithoutStay,
      foreign_daily_allowance_with_stay: this.foreignDailyAllowanceWithStay,
      lodging_check: this.lodgingCheck,
      domestic_air_seat_upgrade: this.domesticAirSeatUpgrade,
      allow_breakfast_domestic_hotel: this.allowBreakfastDomesticHotel,
      allow_breakfast_foreign_hotel: this.allowBreakfastForeignHotel
    };
  }

  setName(value: string) {
    this.name = value;
    app.render();
  }

  setHotelPriceLimit(value: number) {
    this.hotelPriceLimit = value;
    app.render();
  }

  setForeignHotelPriceLimit(value: number) {
    this.foreignHotelPriceLimit = value;
    app.render();
  }

  setDomesticDailyAllowanceWithStay(value: number) {
    this.domesticDailyAllowanceWithStay = value;
    app.render();
  }

  setDomesticDailyAllowanceWithoutStay(value: number) {
    this.domesticDailyAllowanceWithoutStay = value;
    app.render();
  }

  setForeignDailyAllowanceWithStay(value: number) {
    this.foreignDailyAllowanceWithStay = value;
    app.render();
  }

  setLodgingCheck(value: boolean) {
    this.lodgingCheck = value;
    app.render();
  }

  setDomesticAirSeatUpgradeCheck(value: boolean) {
    this.domesticAirSeatUpgrade = value;
    app.render();
  }

  setAllowBreakfastDomesticHotel(value: boolean) {
    this.allowBreakfastDomesticHotel = value;
    app.render();
  }

  setAllowBreakfastForeignHotel(value: boolean) {
    this.allowBreakfastForeignHotel = value;
    app.render();
  }
}

export default Grade;
