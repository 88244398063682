import moment from 'moment';
import type { Moment } from '../lib/moment';

export interface MailSendLogArgs {
  id: number;
  organization_id: number;
  trip_id: number;
  mail_to: string;
  cc: string;
  subject: string;
  body: string;
  created_at: string;
  updated_at?: string;
  organization_name?: string;
}

class MailSendLog {
  id: number;

  organizationId: number | null;

  organizationName: string | null;

  tripId: number | null;

  mailTo: string | null;

  cc: string | null;

  subject: string | null;

  body: string | null;

  createdAt: Moment;

  updatedAt: Moment | null;

  constructor(args: MailSendLogArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.organizationName = args.organization_name || null;
    this.tripId = args.trip_id;
    this.mailTo = args.mail_to;
    this.cc = args.cc;
    this.subject = args.subject;
    this.body = args.body;
    this.createdAt = moment(args.created_at);
    this.updatedAt = args.updated_at ? moment(args.updated_at) : null;
  }
}

export default MailSendLog;
