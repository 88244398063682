import React from 'react';
import _ from 'lodash';

import type Transit from '@this/domain/transit/transit';
import type TransitList from '@this/domain/transit/transit_list';
import type SelectRepository from '@this/domain/select_repository';
import type SelectStore from '@this/domain/select_store';
import type Hotel from '@this/domain/hotel/hotel';
import type SearchResult from '@this/domain/search_result';
import type SearchResultItem from '@this/src/domain/search_result_item';

import type { SelectTransportBoxState } from '../../transport_box/transport_box';
import SelectTransportBox from '../../transport_box/transport_box';

interface SelectTransportListProps {
  onChange: () => void;
  transits: TransitList;
  resultItem?: SearchResultItem;
  repository: SelectRepository;
  store: SelectStore;
  direction?: string;
}

type Props = SelectTransportListProps & SelectTransportBoxState;

class SelectTransportList extends React.Component<Props, { loading: boolean }> {
  constructor(props: Props) {
    super(props);

    this.state = { loading: false };
  }

  // @param {boolean} changeable TODO
  handleSelect = async (
    t: Transit,
    opts: {
      changeable?: boolean;
      domesticAirPriceIndex?: number;
    } = {}
  ) => {
    const searchResult: SearchResult = this.props.store.result;
    this.setState({ loading: true });
    this.props.resultItem?.setNeed(true);

    try {
      if (searchResult.type === 'airPackage') {
        const segment = t.segments[0]; // FIXME: index を直に指定していて壊れやすい。

        switch (searchResult.packageProvider) {
          case 'rakuten': {
            const hotel: Hotel | null = searchResult.getRakutenPackageCurrentHotel();
            const outFlightName =
              this.props.direction === 'out'
                ? segment.legs.map(l => l.name).join(',')
                : this.fetchSelectedFlightNames(0);
            const homeFlightName =
              this.props.direction === 'home'
                ? segment.legs.map(l => l.name).join(',')
                : this.fetchSelectedFlightNames(2);

            await this.props.repository.fetchRakutenPackages({
              outFlightName,
              homeFlightName,
              airlines: [searchResult.airline],
              hotelName: hotel ? hotel.name : null,
              airCode: searchResult.getRakutenPackageAirCode()
            });
            break;
          }
          default:
            break;
        }
      }
      this.props.onChange();
      this.props.transits.selectWithAnimation(t.id, opts.changeable, opts.domesticAirPriceIndex);
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchSelectedFlightNames(num: number) {
    const item = this.props.store.result.packageItems[this.props.store.result.airline][num];
    const current = item.currentElement();

    if (current) {
      return (current as Transit).segments[0].legs.map(l => l.name).join(',') || undefined;
    }
    return undefined;
  }

  render() {
    try {
      return (
        <div className="select-transport-list">
          {this.props.transits.listBySegment().map(t => (
            <SelectTransportBox
              {...this.props}
              {...this.state}
              key={t.id}
              transport={t}
              inList
              selected={this.props.transits.currentId === t.id}
              domesticAirPriceIndex={this.props.transits.domesticAirPriceIndex || 0}
              handleSelect={this.handleSelect}
              store={this.props.store}
              direction={this.props.direction}
              disabled={this.state.loading} // 選択中、他の選択肢を選択できないようにするため
            />
          ))}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectTransportList;
