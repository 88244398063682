import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';

interface Props {
  color?: string | null;
}

const SearchIconTemplate: React.SFC<Props> = ({ color }) => (
  <svg
    viewBox="0 0 26 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Circle color={color || ''} strokeWidth="5" cx="10.5" cy="10.5" r="8" />
      <Rect
        color={color || ''}
        transform="translate(20.534114, 20.002542) rotate(46.000000) translate(-20.534114, -20.002542) "
        x="15.9703158"
        y="17.5025415"
        width="9.12759606"
        height="5"
      />
    </g>
  </svg>
);

const Circle = styled.circle<{ color: string }>`
  stroke: ${props => (props.color === '' ? props.theme.linkColor : props.color)};
`;

const Rect = styled.rect<{ color: string }>`
  fill: ${props => (props.color === '' ? props.theme.linkColor : props.color)};
`;

export default SearchIconTemplate;
