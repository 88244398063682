import React from 'react';
import _ from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';

import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Button from '@this/shared/atoms/button';
import Dropzone from 'react-dropzone';
import Modal from '@this/shared/modal/modal';
import { Fetcher } from '@this/src/util';
import { Title } from '../god';

const StickyTable = require('react-sticky-table').StickyTable;
const Row = require('react-sticky-table').Row;
const Cell = require('react-sticky-table').Cell;

export interface GodHotelMaster {
  id: number;
  expediaHotelId: number;
  expediaHotelName: string;
  expediaHotelLatitude: number;
  expediaHotelLongitude: number;
  expediaHotelUrl: string;
  rakutenHotelId: number;
  rakutenHotelName: string;
  rakutenHotelLatitude: number;
  rakutenHotelLongitude: number;
  rakutenHotelUrl: string;
  grade: number;
  new_id: number;
  candidate: Similarity[];
}

interface Similarity {
  id: number;
  rakutenHotelId: number;
  rakutenHotelName: string;
  rakutenHotelLatitude: number;
  rakutenHotelLongitude: number;
  rakutenHotelUrl: string;
  grade: number;
}

interface State {
  loading: boolean;
  merged: boolean;
  hotelMasters: GodHotelMaster[];
  filterMaster: GodHotelMaster[];
  fromId: number;
  toId: number;
  file: File | null;
  error: string | null;
  encoding: string;
  editingHotel: any;
}

interface HotelMasterResponse {
  hotels: GodHotelMaster[];
}

interface HotelMasterPutResponse {
  hotel: GodHotelMaster;
}

class HotelMaster extends React.Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      merged: false,
      hotelMasters: [],
      filterMaster: [],
      fromId: 0,
      toId: 100,
      file: null,
      error: null,
      encoding: 'utf8',
      editingHotel: null
    };
  }

  componentDidMount() {
    this.fetchMaster();
  }

  async fetchMaster() {
    this.setState({ loading: true });
    const { fromId, toId } = this.state;
    return Fetcher.get<HotelMasterResponse>('/god/hotel_master', { fromId, toId }).then(
      response => {
        const hotels = response.hotels;
        this.setState({
          loading: false,
          hotelMasters: hotels,
          error: null
        });
        this.filterMaster(this.state.merged);
      },
      () => {
        this.setState({
          loading: false,
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  changeOption = () => {
    this.filterMaster(!this.state.merged);
    this.setState({
      merged: !this.state.merged
    });
  };

  changeEncoding = () => {
    const encoding = this.state.encoding === 'sjis' ? 'utf8' : 'sjis';
    this.setState({
      encoding
    });
  };

  filterMaster = (merged: boolean) => {
    const masters = merged
      ? this.state.hotelMasters.filter(m => m.expediaHotelId && m.rakutenHotelId)
      : this.state.hotelMasters.filter(m => !(m.expediaHotelId && m.rakutenHotelId));
    this.setState({ filterMaster: masters });
  };

  changeID = (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const hotel_master_copy = this.state.filterMaster.slice();
    const new_id = parseInt(e.target.value, 10);
    if (e.target.value === '') {
      hotel_master_copy[id].new_id = 0;
    } else {
      if (isNaN(new_id)) return;
      hotel_master_copy[id].new_id = new_id;
    }
    this.setState({ filterMaster: hotel_master_copy });
  };

  submitUpdate(id: number, type: string) {
    const newMaster = this.state.filterMaster.filter(m => m.id === id)[0];
    if (type === 'cancel' || newMaster.new_id) {
      return Fetcher.put<HotelMasterPutResponse>(`/god/hotel_master/${id}/${type}`, newMaster)
        .then(response => {
          const hotel_master = this.state.hotelMasters.slice();
          const updatedHotelIndex = hotel_master.findIndex(h => h.id === id);
          if (updatedHotelIndex !== -1) {
            _.assign(hotel_master[updatedHotelIndex], response.hotel);
          }
          this.setState({
            hotelMasters: hotel_master
          });
          this.filterMaster(this.state.merged);
        })
        .catch(e => {
          this.setState({ error: '保存に失敗しました' });
        });
    }
    return false;
  }

  handleDrop = (files: File[]) => {
    this.setState({
      file: files[0]
    });
  };

  handleDownload = (encoding: string) => {
    location.href = `/god/hotel_master/csv_download?encoding=${encoding}`;
  };

  openModal(hotel: any) {
    this.setState({
      editingHotel: _.cloneDeep(hotel)
    });
  }

  async handleUpload(file: File | null) {
    this.setState({ loading: true, error: '' });
    if (file == null) {
      this.setState({ loading: false });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload('/god/hotel_master/csv_upload', formData, { method: 'PUT' });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。'
      });
    } finally {
      this.setState({
        file: null,
        loading: false
      });
    }
  }

  render() {
    const { loading, merged, filterMaster, fromId, toId, error, file, encoding, editingHotel } = this.state;
    return (
      <div>
        <Title>ホテルマスタ</Title>
        <Content>
          {loading ? (
            <SimpleLoading />
          ) : (
            <>
              <InputAreaRight>
                <RadioLabel>
                  <input type="radio" value="マージ済み" checked={merged === true} onChange={this.changeOption} />
                  <span>マージ済み</span>
                </RadioLabel>
                <RadioLabel>
                  <input type="radio" value="マージ前" checked={merged === false} onChange={this.changeOption} />
                  <span>マージ前</span>
                </RadioLabel>
                <span> 　　ID範囲指定 : 　</span>
                <HotelMasterInput
                  type="text"
                  value={fromId}
                  onChange={e => this.setState({ fromId: parseInt(e.target.value, 10) || 0 })}
                />
                <span>〜</span>
                <HotelMasterInput
                  type="text"
                  value={toId}
                  onChange={e => this.setState({ toId: parseInt(e.target.value, 10) || 0 })}
                />
                <TheButton onClick={() => this.fetchMaster()}>検索</TheButton>
                <UploadForm onDrop={this.handleDrop} multiple={false} disabled={loading}>
                  {file ? file.name : <p> クリックしてファイルをアップロード </p>}
                </UploadForm>
                <FileButton onClick={() => this.handleUpload(file)}>アップロード</FileButton>
                <FileButton onClick={() => this.handleDownload(encoding)}>ダウンロード</FileButton>
                <RadioLabel>
                  <input type="radio" value="UTF-8" checked={encoding === 'utf8'} onChange={this.changeEncoding} />
                  <span>UTF-8</span>
                </RadioLabel>
                <RadioLabel>
                  <input type="radio" value="SJIS" checked={encoding === 'sjis'} onChange={this.changeEncoding} />
                  <span>SJIS</span>
                </RadioLabel>
              </InputAreaRight>
              <div>
                <Error>{error}</Error>
                <HotelTable>
                  <Row>
                    <HotelTh style={{ minWidth: '80px' }}>AItravelID</HotelTh>
                    <HotelThRight style={{ minWidth: '80px' }}>ExpediaID</HotelThRight>
                    <HotelThRight style={{ minWidth: '100px' }}>name</HotelThRight>
                    <HotelThRight style={{ minWidth: '90px' }}>longitude</HotelThRight>
                    <HotelThRight style={{ minWidth: '90px' }}>latitude</HotelThRight>
                    <HotelThRight style={{ minWidth: '90px' }}>URL</HotelThRight>
                    <HotelThRight style={{ minWidth: '80px' }}>RakutenID</HotelThRight>
                    <HotelThRight style={{ minWidth: '100px' }}>name</HotelThRight>
                    <HotelThRight>longitude</HotelThRight>
                    <HotelThRight>latitude</HotelThRight>
                    <HotelThRight style={{ minWidth: '80px' }}>URL</HotelThRight>
                    <HotelThRight>grade</HotelThRight>
                    <HotelThRight style={{ width: '80px' }}>保存</HotelThRight>
                  </Row>
                  {filterMaster.map((hotel, i) => (
                    <Row key={i}>
                      <HotelTd>{hotel.id}</HotelTd>
                      <HotelTdRight>
                        {hotel.expediaHotelId || (
                          <HotelMasterInput
                            type="text"
                            value={hotel.new_id || 0}
                            onChange={e => this.changeID(i, e)}
                          />
                        )}
                      </HotelTdRight>
                      <HotelTdRight>{hotel.expediaHotelName || ''}</HotelTdRight>
                      <HotelTdRight>{hotel.expediaHotelLongitude || ''}</HotelTdRight>
                      <HotelTdRight>{hotel.expediaHotelLatitude || ''}</HotelTdRight>
                      <HotelTdRight>
                        {hotel.expediaHotelUrl ? <a href={hotel.expediaHotelUrl}>URL</a> : ''}
                      </HotelTdRight>
                      <HotelTdRight>
                        {hotel.rakutenHotelId || (
                          <HotelMasterInput
                            type="text"
                            value={hotel.new_id || 0}
                            onChange={e => this.changeID(i, e)}
                          />
                        )}
                      </HotelTdRight>
                      <HotelTdRight>
                        {hotel.rakutenHotelName ||
                          (hotel.candidate.length > 0 ? (
                            <Button onClick={() => this.openModal(hotel)}>候補あり</Button>
                          ) : (
                            ''
                          ))}
                      </HotelTdRight>
                      <HotelTdRight>{hotel.rakutenHotelLongitude || ''}</HotelTdRight>
                      <HotelTdRight>{hotel.rakutenHotelLatitude || ''}</HotelTdRight>
                      <HotelTdRight>
                        {hotel.rakutenHotelUrl ? <a href={hotel.rakutenHotelUrl}>URL</a> : ''}
                      </HotelTdRight>
                      <HotelTdRight>{hotel.grade || ''}</HotelTdRight>
                      <HotelTdRight>
                        {merged ? (
                          <SubmitButton
                            onClick={() => {
                              this.submitUpdate(hotel.id, 'cancel');
                            }}
                          >
                            解除
                          </SubmitButton>
                        ) : (
                          <SubmitButton
                            onClick={() => {
                              this.submitUpdate(hotel.id, 'update');
                            }}
                          >
                            保存
                          </SubmitButton>
                        )}
                      </HotelTdRight>
                    </Row>
                  ))}
                </HotelTable>
                {editingHotel && (
                  <Modal hideModal={() => this.setState({ editingHotel: null })} show title="名寄せ候補">
                    <HotelTable>
                      <Row>
                        <HotelTh style={{ minWidth: '80px' }}>AItravelID</HotelTh>
                        <HotelThRight style={{ minWidth: '100px' }}>RakutenID</HotelThRight>
                        <HotelThRight style={{ minWidth: '100px' }}>name</HotelThRight>
                        <HotelThRight style={{ minWidth: '90px' }}>longitude</HotelThRight>
                        <HotelThRight style={{ minWidth: '90px' }}>latitude</HotelThRight>
                        <HotelThRight style={{ minWidth: '90px' }}>URL</HotelThRight>
                      </Row>
                      <Row>
                        <HotelTd>{editingHotel.id}</HotelTd>
                        <HotelTdRight />
                        <HotelTdRight>{editingHotel.expediaHotelName || ''}</HotelTdRight>
                        <HotelTdRight>{editingHotel.expediaHotelLongitude || ''}</HotelTdRight>
                        <HotelTdRight>{editingHotel.expediaHotelLatitude || ''}</HotelTdRight>
                        <HotelTdRight>
                          {editingHotel.expediaHotelUrl ? <a href={editingHotel.expediaHotelUrl}>URL</a> : ''}
                        </HotelTdRight>
                      </Row>
                      <Row>
                        <HotelTd>以下、候補</HotelTd>
                        <HotelTdRight />
                        <HotelTdRight />
                        <HotelTdRight />
                        <HotelTdRight />
                        <HotelTdRight />
                      </Row>
                      {editingHotel.candidate.map((c: Similarity, i: number) => (
                        <Row key={i}>
                          <HotelTd>{c.id}</HotelTd>
                          <HotelTdRight>{c.rakutenHotelId || ''}</HotelTdRight>
                          <HotelTdRight>{c.rakutenHotelName || ''}</HotelTdRight>
                          <HotelTdRight>{c.rakutenHotelLongitude || ''}</HotelTdRight>
                          <HotelTdRight>{c.rakutenHotelLatitude || ''}</HotelTdRight>
                          <HotelTdRight>
                            {c.rakutenHotelUrl ? <a href={c.rakutenHotelUrl}>URL</a> : ''}
                          </HotelTdRight>
                        </Row>
                      ))}
                    </HotelTable>
                  </Modal>
                )}
              </div>
            </>
          )}
        </Content>
      </div>
    );
  }
}

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const RadioLabel = styled.label`
  display: inline-block;
  margin-left: 5px;
`;

const HotelMasterInput = styled.input`
  &&& {
    float: right;
    margin-right: 10px;
    margin-left: 6px;
    font-size: 13px;
    width: 130px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const InputAreaRight = styled.div`
  display: flex;
  margin-bottom: 20px;
  min-width: 550px;
`;

const HotelTable = styled(StickyTable)`
  margin: 0;
  table-layout: fixed;
  height: '80vh';
`;

const HotelTh = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
`;

const HotelThRight = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
  text-align: right;
`;

const HotelTd = styled(Cell)`
  padding: 4px 6px;
  min-width: 70px;
`;

const HotelTdRight = styled(Cell)`
  padding: 4px 6px;
  background: white;
  min-width: 70px;
  text-align: right;
`;

const TheButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    display: block;
    width: 60px;
    margin: 0 15px;
    padding: 5px 0;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const SubmitButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    display: block;
    width: 90px;
    margin: 5px 2px;
    padding: 5px 0;
  }
`;

const FileButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    display: block;
    width: 90px;
    margin: 0 15px;
    padding: 5px 0;
  }
`;

const UploadForm = styled(Dropzone)`
  width: 150px;
  background-color: #eee;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export default HotelMaster;
