import _ from 'lodash';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import type ExpensesType from '../expenses/expenses_type';
import type { TripType } from '../expenses/item';
import type { ExpenseTool } from '../setting';
import type { AllowanceItemForReportArgs } from './trip_args';

interface OptionalProps {
  defaultTime?: Moment | null;
  adding?: boolean;
}

export type CalcPriceOrder = 'price' | 'time' | 'transfer';

export type TripTypeOptions = [string, TripType][];

export type CalcPriceOrderOptions = [string, CalcPriceOrder][];

interface EditingRequest {
  cancel?: boolean;
  expenseTool?: ExpenseTool;
}

type EditableFields = 'time' | 'price' | 'tripType' | 'allowanceType' | 'allowanceTypeName';

type ValidationFields = EditableFields | 'projectId' | 'expensesTypeId';

export const AllowanceItemSort = (a: AllowanceItemForReport, b: AllowanceItemForReport) => {
  const aTime = a.getSortingTime();
  const bTime = b.getSortingTime();

  if (aTime === bTime) {
    return (a.id || 0) < (b.id || 0) ? -1 : 1;
  }

  return aTime.isBefore(bTime) ? -1 : 1;
};

export class AllowanceItemForReport {
  serial: string;

  id?: number;

  tripId?: number;

  tripReportId?: number;

  projectId?: number;

  expensesTypeId?: number;

  tripType: TripType;

  time: Moment | null = null;

  price: number | null;

  allowanceType?: string;

  allowanceTypeName?: string | null;

  expensesType?: ExpensesType | null;

  startDate: Moment | null = null;

  endDate: Moment | null = null;

  adding = false;

  editing = false;

  beforeEdit: Pick<AllowanceItemForReport, EditableFields | 'expensesTypeId' | 'expensesType'> | null = null;

  validationErrors: Partial<Record<ValidationFields, string>> = {};

  constructor(args: Partial<AllowanceItemForReportArgs>, { defaultTime, adding }: OptionalProps = {}) {
    this.serial = Math.random().toString(32).substring(2); // IDが未生成でも使える一意のキーを作成
    this.id = args.id;
    this.tripId = args.trip_id;
    this.tripReportId = args.trip_report_id;
    this.expensesTypeId = 1;
    this.tripType = args.trip_type || 'one_way';
    this.time = args.date ? moment(args.date).tz('Asia/Tokyo') : null;
    this.allowanceType = args.allowance_type || '';
    this.setAllowanceTypeName(this.allowanceType);
    this.price = args.price || null;
    this.editing = adding || false;
    this.adding = adding || false;

    if (!this.time && !this.expensesTypeId) {
      this.time = this.startDate || this.endDate;
    }

    if (!this.time && !this.startDate && !this.endDate) {
      this.time = defaultTime || null;
    }
  }

  setEditing(editing: boolean, { cancel, expenseTool }: EditingRequest = {}) {
    if (!editing && !cancel) {
      const errors = this.validation({ expenseTool });

      if (errors.length > 0) {
        app.render();
        return;
      }
    }

    this.editing = editing;
    this.adding = false;

    // 編集時に`beforeEdit`に変更前のデータを格納し、キャンセル時にデータを戻すことでバリデーションされている状態を保つ
    if (editing) {
      this.beforeEdit = {
        time: this.time,
        price: this.price,
        allowanceType: this.allowanceType,
        allowanceTypeName: this.allowanceTypeName,
        expensesType: this.expensesType,
        expensesTypeId: this.expensesTypeId,
        tripType: this.tripType
      };
      this.validationErrors = {};
    } else {
      if (cancel && this.beforeEdit) {
        this.time = this.beforeEdit.time;
        this.price = this.beforeEdit.price;
        this.allowanceType = this.beforeEdit.allowanceType;
        this.allowanceTypeName = this.beforeEdit.allowanceTypeName;
        this.expensesType = this.beforeEdit.expensesType;
        this.expensesTypeId = this.beforeEdit.expensesTypeId;
        this.tripType = this.beforeEdit.tripType;
      }
      this.beforeEdit = null;
    }
    app.render();
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  setAllowanceType(type: string | '') {
    this.setAllowanceTypeName(type);
    app.render();
  }

  setAllowanceTypeName(type: string | '') {
    this.allowanceType = type;
    if (this.allowanceType === '') {
      this.allowanceTypeName = '';
      return;
    }
    this.allowanceTypeName = type === 'one_day' ? '1日' : '半日';
  }

  getPeriod() {
    if (this.time) {
      return this.time.format('YYYY/MM/DD');
    }

    if (this.startDate && this.endDate) {
      return this.startDate.month === this.endDate.month
        ? `${this.startDate.format('M月D日')}-${this.endDate.format('D日')}`
        : `${this.startDate.format('M月D日')}-${this.endDate.format('M月D日')}`;
    }

    return `${(this.startDate || this.endDate)?.format('M月D日') || '─'}`;
  }

  getPrice() {
    return this.price ? `${this.price.toLocaleString()}円` : '─';
  }

  getAllowanceTypeName() {
    return this.allowanceTypeName || '─';
  }

  getSortingTime() {
    return this.time || this.startDate || moment().startOf('day');
  }

  getTime() {
    return this.time ? this.time.format('YYYY/MM/DD') : '─';
  }

  availableTime() {
    return Boolean(this.expensesTypeId);
  }

  invalid(field: ValidationFields) {
    return _.isEmpty(this.validationErrors[field]);
  }

  validation({ expenseTool }: Pick<EditingRequest, 'expenseTool'>) {
    const errors = [];

    if (this.time === null) {
      this.validationErrors.time = '日付を設定してください';
      errors.push(this.validationErrors.time);
    }

    if (this.price === null) {
      this.validationErrors.price = '金額を入力してください';
      errors.push(this.validationErrors.price);
    }

    if (this.allowanceType === '') {
      this.validationErrors.allowanceType = '種別を入力してください';
      errors.push(this.validationErrors.allowanceType);
    }

    return errors;
  }

  submitParams() {
    return {
      id: this.id,
      trip_id: this.tripId,
      trip_report_id: this.tripReportId,
      allowance_type: this.allowanceType,
      price: this.price || 0,
      date: this.time ? moment(this.time).format('YYYY-MM-DD') : null
    };
  }
}

export const convertAllowanceItemProps = (
  props: Partial<AllowanceItemForReport>
): Partial<AllowanceItemForReportArgs> => ({
  id: props.id,
  trip_id: props.tripId,
  trip_report_id: props.tripReportId,
  trip_type: props.tripType,
  price: props.price,
  allowance_type: props.allowanceType,
  allowance_type_name: props.allowanceTypeName || undefined,
  expenses_type: props.expensesType || undefined,
  expenses_type_id: props.expensesType?.id || props.expensesTypeId
});
