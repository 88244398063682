/* eslint-disable max-lines */
import React from 'react';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button } from '@this/components/shared/ui/inputs/button';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import FlashMessage from '@this/shared/flash_message/flash_message';
import {
  OrganizationBody,
  OrganizationTable,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTd,
  OrganizationTruncateTd,
  OrganizationTitle,
  OrganizationSubTitle
} from '@this/components/organization/organization.style';
import { Error, Tr } from '@this/components/organization/approve_items/approve_items.style';
import type { ApproveItemArgs } from '@this/domain/approve_item/approve_item';
import type ApproveItem from '@this/domain/approve_item/approve_item';
import ApproveItemList from '@this/domain/approve_item/approve_item_list';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Fetcher, HTTPError } from '@this/src/util';

const fetchConnectionErrorMessage = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
const approveItemsPath = '/organization/approve_items';
const displayOrderPath = '/organization/approve_items/display_order';

interface Props {}

interface State {
  approveItems: any;
  editingApproveItem: any;
  loading: boolean;
  error: string | null;
  saveError: string | null;
  totalPage: number;
  currentPage: number;
  selectedRowIds: string[];
}

interface ApproveItemsResponse {
  approve_items: ApproveItemArgs[];
  total_page: number;
}

class ApproveItems extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      approveItems: new ApproveItemList([]),
      editingApproveItem: null,
      loading: false,
      error: null,
      saveError: null,
      totalPage: 1,
      currentPage: 1,
      selectedRowIds: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchApproveItems();
  }

  fetchResultError() {
    this.setState({ error: fetchConnectionErrorMessage, loading: false });
  }

  fetchResultSaveError(error: unknown) {
    const saveError =
      error instanceof HTTPError && error.response?.status === 400
        ? error.response.data.errors
        : fetchConnectionErrorMessage;
    this.setState({ loading: false, saveError });
  }

  fetchApproveItems(page = 1, text = '', disabled = false) {
    Fetcher.get<ApproveItemsResponse>(`${approveItemsPath}.json`, { page, text, disabled }).then(
      result => {
        const approveItems = new ApproveItemList(result.approve_items);
        this.setState({ approveItems, loading: false, totalPage: result.total_page, currentPage: page });
      },
      () => {
        this.fetchResultError();
      }
    );
  }

  handleSaveDisplayOrderClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ saveError: null, loading: true });
    return Fetcher.put(displayOrderPath, this.displayOrderSubmitParams() ?? {}).then(
      () => {
        this.setState({ loading: false });
        this.reloadApproveItems();
      },
      error => {
        this.fetchResultSaveError(error);
      }
    );
  }

  displayOrderSubmitParams() {
    if (this.state.approveItems.list) {
      const approveItemsListParams = this.state.approveItems.list.map((ai: ApproveItem, index: number) => {
        return { id: ai.id, display_order: index + 1 };
      });
      return { approve_items: approveItemsListParams };
    }
    return null;
  }

  async reloadApproveItems() {
    await this.fetchApproveItems(this.state.currentPage);
  }

  onDragStart = (start: any) => {
    const id = start.draggableId;
    const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);
    if (!selected) {
      this.unselectAll();
    }
  };

  onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (this.state.approveItems) {
      const approveItems = this.state.approveItems;
      const approveItemsList = Array.from(approveItems.list);
      const quote = approveItemsList[source.index];
      approveItemsList.splice(source.index, 1);
      approveItemsList.splice(destination.index, 0, quote);
      approveItems.replaceList(approveItemsList);
      this.setState({ approveItems });
    }
  };

  unselectAll = () => {
    this.setState({ selectedRowIds: [] });
  };

  private approveItemCards(approveItems: any, editingApproveItem: any, loading: boolean, error: string | null) {
    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <OrganizationTable>
          <thead>
            <tr>
              <OrganizationTh>設定表示名</OrganizationTh>
              <OrganizationTh>入力データのタイプ</OrganizationTh>
              <OrganizationTh>申請対象</OrganizationTh>
              <OrganizationTh>申請画面での表示有無</OrganizationTh>
              <OrganizationTh>入力必須有無</OrganizationTh>
              <OrganizationTh>適用する出張の種類</OrganizationTh>
              <OrganizationTh>プレースホルダー／初期値</OrganizationTh>
              <OrganizationButtonTh>編集</OrganizationButtonTh>
            </tr>
          </thead>
          {loading ? (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <SimpleLoading />
                </td>
              </tr>
            </tbody>
          ) : error ? (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Error>{error}</Error>
                </td>
              </tr>
            </tbody>
          ) : (
            <Droppable droppableId="tableBody">
              {(provided: any, snapshot: any) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {approveItems &&
                    approveItems.list.map((d: ApproveItem, i: number) =>
                      this.approveItemCard(d, i, editingApproveItem)
                    )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          )}
        </OrganizationTable>
      </DragDropContext>
    );
  }

  private approveItemCard(approveItem: ApproveItem, index: number, editingApproveItem: any) {
    return (
      <Draggable draggableId={String(approveItem?.id)} index={index} key={approveItem?.id}>
        {(provided: any, snapshot: any) => (
          <Tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={approveItem.id}
            ref={provided.innerRef}
            style={this.getTrStyle(snapshot.isDragging, provided.draggableProps.style)}
            data-e2e-id={approveItem.id}
          >
            {this.approveItemCardLine(approveItem.userDisplayName, snapshot, 'e2e-user-display-name')}
            {this.approveItemCardLine(approveItem.dataTypeText, snapshot, 'e2e-data-type-text')}
            {this.approveItemCardLine(approveItem.targetText, snapshot, 'e2e-target-text')}
            {this.approveItemCardLine(approveItem.displayText, snapshot, 'e2e-display-text')}
            {this.approveItemCardLine(approveItem.requiedTypeText, snapshot, 'e2e-required-type-text')}
            {this.approveItemCardLine(approveItem.businessTripTypeText, snapshot, 'e2e-trip-type-text')}
            {(approveItem.dataType === 'freetext' || approveItem.dataType === 'multilinefreetext') &&
              this.approveItemCardLine(approveItem.placeholder, snapshot, 'e2e-placeholder')}
            {approveItem.dataType === 'list' &&
              this.approveDefaultItemCardLine(approveItem.defaultItemCode, approveItem.defaultItemName, snapshot)}
            {approveItem.dataType !== 'freetext' &&
              approveItem.dataType !== 'multilinefreetext' &&
              approveItem.dataType !== 'list' &&
              this.approveItemCardLine('', snapshot, '')}
            <OrganizationTd style={this.getTdStyle(snapshot.isDragging)}>
              {!editingApproveItem && (
                <Button href={`/organization/approve_items/${approveItem.id}/edit`}>編集</Button>
              )}
            </OrganizationTd>
          </Tr>
        )}
      </Draggable>
    );
  }

  private approveItemCardLine(value: any, snapshot: any, className: string) {
    return (
      <OrganizationTd style={this.getTdStyle(snapshot.isDragging)} className={className}>
        {value}
      </OrganizationTd>
    );
  }

  private approveDefaultItemCardLine(code: string | null, name: string | null, snapshot: any) {
    const text = (code != null ? code : '') + (name != null ? name : '');
    return (
      <OrganizationTruncateTd style={this.getTdStyle(snapshot.isDragging)} title={text}>
        {text}
      </OrganizationTruncateTd>
    );
  }

  getTrStyle = (isDragging: any, draggableStyle: any) => ({
    background: isDragging && '#fff9ed',
    ...draggableStyle
  });

  getTdStyle = (isDragging: any) => ({
    width: isDragging && '15%'
  });

  render() {
    try {
      const { approveItems, editingApproveItem, loading, error, saveError, totalPage } = this.state;
      return (
        <>
          <FlashMessage message={saveError} type="error" />
          <OrganizationTitle style={{ paddingBottom: '10px' }}>申請項目マスタ</OrganizationTitle>
          <OrganizationSubTitle>独自の申請項目を設定できます。</OrganizationSubTitle>
          <OrganizationBody>
            <MasterListContainer
              totalPage={totalPage}
              onPaginate={page => this.fetchApproveItems(page)}
              createButton={
                <ButtonContents>
                  <Button color="sub" href="/organization/approve_items/new" disabled={!!editingApproveItem}>
                    <span style={{ marginRight: '5px' }}>申請項目を追加</span>
                    <AddCircleOutlineIcon />
                  </Button>
                  <Button color="sub" onClick={e => this.handleSaveDisplayOrderClick(e)}>
                    順番を保存
                  </Button>
                </ButtonContents>
              }
              renderContent={() => this.approveItemCards(approveItems, editingApproveItem, loading, error)}
            />
          </OrganizationBody>
        </>
      );
    } catch (e) {
      return utils.sendErrorObject(e);
    }
  }
}

const ButtonContents = styled.div`
  display: flex;
  column-gap: 10px;
`;

export default ApproveItems;
