import { styled } from '@this/constants/themes';

/**
 * @deprecated
 * should use js/src/components/shared/ui/inputs/input
 */
const Input = styled.input`
  &&& {
    padding: 4px 16px 4px 2px;
    margin: 0;
    width: 100%;
    outline: none;
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export default Input;
