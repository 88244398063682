import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import ContentBody from '@this/components/shared/atoms/content_body';
import { Divider } from '@material-ui/core';

export const Wrap = styled.div`
  min-height: 100vh;
`;

export const HeaderWrap = styled.div`
  font-size: 14px;
`;

export const HeaderBody = styled(ContentBody as any)`
  max-width: 1150px;
  background-color: transparent;
  border-bottom: solid 3px ${props => props.theme.accentBorderColor};
`;

export const HeaderDivider = styled(Divider)`
  height: 3px;
  background-color: ${props => props.theme.accentBorderColor};
`;

export const PageTitle = styled.h1`
  font-size: 22px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 18px;
`;

export const BodyWrap = styled.div`
  min-height: 100vh;
  display: block;
  position: relative;
`;

export const Body = styled(ContentBody as any)`
  max-width: 1150px;
  padding: 20px;
  background: #fff;
`;

export const BodyTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const BodyDescription = styled.p`
  font-size: 12px;
  margin-top: -12px;
`;

export const Border = styled(Divider)`
  margin: 20px 0;
`;

export const LoadingWrap = styled.div`
  padding-top: 20px;
`;

export const FooterWrap = styled.div`
  margin-top: 50px;
  background: #fff;

  position: sticky;
  bottom: 0;

  &.sticky {
    box-shadow: 0px -1px 1px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%),
      0px 0px 3px 0px rgb(0 0 0 / 12%);
  }
`;

export const FooterBody = styled(ContentBody as any)`
  max-width: 1150px;
  padding: 20px;
  background: #fff;
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  ${media.sp`
    flex-flow: column;
    gap: 8px;
  `}
`;

export const FooterActionGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
