import React from 'react';
import { styled } from '@this/constants/themes';
import { useInAdvanceApplicationStore } from '@this/src/domain/in_advance/in_advance_application_store';

const ResultSummary: React.FC = () => {
  const store = useInAdvanceApplicationStore();
  const { batch } = store;

  if (batch.batch !== 'complete') return <></>;

  const action = batch.action === 'accept' ? '承認' : '却下';
  const failures = batch.batchItems.flatMap(i => (i.isFailure() ? i : []));

  return (
    <Wrap>
      {batch.successTrips.length > 0 && (
        <Text>
          {batch.successTrips.length}件の{action}が完了しました。
        </Text>
      )}
      {failures.length > 0 && (
        <Errors>
          {failures.map(failure => (
            <Error key={failure.id}>
              旅程番号#{failure.tripId}の申請の{action}に失敗しました。
              <br />
              失敗理由：{failure.errorMessage}
            </Error>
          ))}
        </Errors>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border: solid 3px ${props => props.theme.grayBorderColor};
  background-color: ${props => props.theme.grayBgColorLight};
  margin-bottom: 20px;
`;

const Text = styled.div``;

const Errors = styled.div``;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

export default ResultSummary;
