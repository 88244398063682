export interface AnnouncementJson {
  announcement: string | null;
}

export class Announcement {
  body: string;

  constructor(args: AnnouncementJson) {
    this.body = args.announcement || '';
  }
}
