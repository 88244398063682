import React from 'react';

import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import { Flex } from '@this/shared/ui/layout/flex';
import Notification from '@this/src/notification';

type Props = {
  className?: string;
  onChange: (files: File[]) => void;
};

type Attachment = {
  id: string;
  file: File | null;
};

// 4桁のランダムなIDを生成。
const createId = () => {
  const max = 9999;
  const min = 1000;
  return `temp-${Math.floor(Math.random() * (max + 1 - min)) + min}`;
};

export const AttachmentsField = ({ className, onChange }: Props) => {
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);

  const changeAttachments = React.useCallback(
    (newAttachments: Attachment[]) => {
      setAttachments([...newAttachments]);
      onChange(newAttachments.filter(a => !!a.file).map(a => a.file) as File[]);
    },
    [setAttachments, onChange]
  );

  const handleClickAddAttachment = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setAttachments([...attachments, { id: createId(), file: null }]);
    },
    [setAttachments, attachments]
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: Attachment['id']) => {
      const newAttachments = [...attachments];
      const target = newAttachments.find(a => a.id === id);
      if (target) {
        target.file = (e.target?.files ?? [])[0];
        if (target.file.size > 10 * 1024 * 1024) {
          const errorMessage = 'ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。';
          target.file = null;
          e.target.value = '';
          Notification.error(errorMessage);
          return;
        }
      }
      changeAttachments(newAttachments);
    },
    [attachments, changeAttachments]
  );

  const handleClickRemoveAttachment = React.useCallback(
    (id: Attachment['id']) => {
      const newAttachments = [...attachments];
      const index = newAttachments.findIndex(a => a.id === id);
      if (index !== -1) {
        newAttachments.splice(index, 1);
      }
      changeAttachments(newAttachments);
    },
    [attachments, changeAttachments]
  );

  return (
    <Root className={className}>
      <AddAttachmentButton size="small" onClick={handleClickAddAttachment} data-testid="attachment-field-add">
        添付ファイルを追加
      </AddAttachmentButton>
      <ul>
        {attachments.map(attachment => (
          <ListItem key={attachment.id}>
            <Flex justifyContent="space-between" alignItems="center">
              <input
                type="file"
                name="files[]"
                onChange={e => handleInputChange(e, attachment.id)}
                accept="image/jpeg, image/gif, image/png, application/pdf"
                data-testid="attachment-field-input"
              />
              <Link size="small" onClick={() => handleClickRemoveAttachment(attachment.id)}>
                削除
              </Link>
            </Flex>
          </ListItem>
        ))}
      </ul>
    </Root>
  );
};

const Root = styled.div``;

const AddAttachmentButton = styled(Link)`
  margin-bottom: 8px;
`;

const ListItem = styled.li`
  margin-left: 8px;
`;
