import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { ButtonBase, ButtonType } from '@this/components/shared/atoms/button';
import Text from '@this/components/shared/text/text';

import ContentHeader from '@this/components/shared/content_header/content_header';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import { Datepicker } from '@this/components/shared/ui/inputs/datepicker';

import { Wrap, BodyWrap, Body, LoadingWrap } from '../trips/trips';
import type { DraftTripsState } from './draft_trips_new/draft_trips_new';
import SelectTravelers from '../../reserve_trip/reserve_confirm/select_travelers/select_travelers';

type Props = DraftTripsState & {
  handleRemoveTraveler: (i: number, e: React.MouseEvent<HTMLElement>) => void;
  handleTravelerTypeChange: (i: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTravelerSelect: (i: number, traveler: any) => void;
  handleTravelerInfoChange: (i: number, method: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddTraveler: (e: React.MouseEvent<HTMLElement>) => void;
  handleChangeDate: (kind: 'outdate' | 'homedate', date: Moment) => void;
  handleChangeFinalDestination: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePurpose: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.MouseEvent<HTMLElement>) => void;
};

const DraftTripsFormTemplate: React.SFC<Props> = ({
  user,
  members,
  travelers,
  finalDestination,
  purpose,
  loading,
  validationErrors,
  outdate,
  homedate,
  error,
  handleRemoveTraveler,
  handleTravelerTypeChange,
  handleTravelerSelect,
  handleTravelerInfoChange,
  handleAddTraveler,
  handleChangeDate,
  handleChangeFinalDestination,
  handleChangePurpose,
  handleSubmit
}) => (
  <Wrap>
    <BodyWrap>
      <div>
        <DraftTripBody>
          <ContentHeader
            title={`事前旅程${location.pathname === '/draft_trips/new' ? '登録' : '編集'}`}
            backButton
            backLink="/trips"
          />
          <Content>
            {loading ? (
              <LoadingWrap>
                <SimpleLoading />
              </LoadingWrap>
            ) : error ? (
              <LoadingWrap>
                <Error>{error}</Error>
              </LoadingWrap>
            ) : (
              <>
                <Section>
                  <SectionIn>
                    <InputArea>
                      <Label>出張者</Label>
                      <List>
                        {travelers.list.map((traveler: any, i: number) => (
                          <div key={i}>
                            <ListHeader>
                              <ListNumber>{`${i + 1}人目`}</ListNumber>
                              {i > 0 && <A onClick={e => handleRemoveTraveler(i, e)}>削除</A>}
                            </ListHeader>
                            <ListContent>
                              <TypeLabel>
                                <input
                                  type="radio"
                                  value="self"
                                  onChange={e => handleTravelerTypeChange(i, e)}
                                  checked={traveler.type === 'self'}
                                />
                                <span>自分の手配をする</span>
                              </TypeLabel>
                              {user && traveler.type === 'self' && (
                                <ListInfoWrap>
                                  <ListInfo>
                                    <ListInfoIn>
                                      {user.departmentName() && <ListText>{user.departmentName()}</ListText>}
                                      <ListText>{user.name}</ListText>
                                      <ListText>{`(${user.email})`}</ListText>
                                    </ListInfoIn>
                                  </ListInfo>
                                </ListInfoWrap>
                              )}
                              {user && user.show_other_members && members.length > 0 && (
                                <>
                                  <TypeLabel>
                                    <input
                                      type="radio"
                                      value="member"
                                      onChange={e => handleTravelerTypeChange(i, e)}
                                      checked={traveler.type === 'member'}
                                    />
                                    <span>他の人の手配をする</span>
                                  </TypeLabel>
                                  {traveler.type === 'member' && (
                                    <ListInfoWrap>
                                      <SelectTravelers
                                        travelers={travelers.list}
                                        currentIndex={i}
                                        onSelect={handleTravelerSelect}
                                      />
                                      {!_.isEmpty(utils.dig(validationErrors, 'travelers', i, 'memberSelect')) && (
                                        <Errors>
                                          <Error>
                                            <Text text={validationErrors.travelers[i].memberSelect} />
                                          </Error>
                                        </Errors>
                                      )}
                                    </ListInfoWrap>
                                  )}
                                </>
                              )}
                              <TypeLabel>
                                <input
                                  type="radio"
                                  value="companion"
                                  onChange={e => handleTravelerTypeChange(i, e)}
                                  checked={traveler.type === 'companion'}
                                />
                                <span>手動で入力</span>
                              </TypeLabel>
                              {traveler.type === 'companion' && (
                                <ListInfoWrap>
                                  <InputArea>
                                    <Label>氏名（ローマ字）</Label>
                                    <InputAreaRight>
                                      <NameInput
                                        type="text"
                                        placeholder="例) Yamada"
                                        value={traveler.lastNameRoman}
                                        onChange={e => handleTravelerInfoChange(i, 'setLastNameRoman', e)}
                                      />
                                      <NameInput
                                        type="text"
                                        placeholder="例) Ichiro"
                                        value={traveler.firstNameRoman}
                                        onChange={e => handleTravelerInfoChange(i, 'setFirstNameRoman', e)}
                                      />
                                    </InputAreaRight>
                                    <Errors>
                                      <Error>
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerLastNameRomen')
                                        ) && <Text text={validationErrors.travelers[i].travelerLastNameRomen} />}
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerFirstNameRomen')
                                        ) && <Text text={validationErrors.travelers[i].travelerFirstNameRomen} />}
                                      </Error>
                                    </Errors>
                                  </InputArea>
                                  <InputArea>
                                    <Label>氏名（カナ）</Label>
                                    <InputAreaRight>
                                      <NameInput
                                        type="text"
                                        placeholder="例) ヤマダ"
                                        value={traveler.lastNameKana}
                                        onChange={e => handleTravelerInfoChange(i, 'setLastNameKana', e)}
                                      />
                                      <NameInput
                                        type="text"
                                        placeholder="例) イチロウ"
                                        value={traveler.firstNameKana}
                                        onChange={e => handleTravelerInfoChange(i, 'setFirstNameKana', e)}
                                      />
                                    </InputAreaRight>
                                    <Errors>
                                      <Error>
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerLastNameKana')
                                        ) && <Text text={validationErrors.travelers[i].travelerLastNameKana} />}
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerFirstNameKana')
                                        ) && <Text text={validationErrors.travelers[i].travelerFirstNameKana} />}
                                      </Error>
                                    </Errors>
                                  </InputArea>
                                  <InputArea>
                                    <Label>誕生日</Label>
                                    <InputAreaRight>
                                      <NameInput
                                        type="text"
                                        placeholder="例) 1986-01-01"
                                        value={traveler.birthday}
                                        onChange={e => handleTravelerInfoChange(i, 'setBirthday', e)}
                                      />
                                    </InputAreaRight>
                                    <Errors>
                                      <Error>
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerBirthday')
                                        ) && <Text text={validationErrors.travelers[i].travelerBirthday} />}
                                      </Error>
                                    </Errors>
                                  </InputArea>
                                  <InputArea>
                                    <Label>性別</Label>
                                    <InputAreaRight>
                                      <RadioLabel>
                                        <RadioInput
                                          type="radio"
                                          value="male"
                                          checked={traveler.flightGender === 'male'}
                                          onChange={e => handleTravelerInfoChange(i, 'setFlightGender', e)}
                                        />
                                        <span>男性</span>
                                      </RadioLabel>
                                      <RadioLabel>
                                        <RadioInput
                                          type="radio"
                                          value="female"
                                          checked={traveler.flightGender === 'female'}
                                          onChange={e => handleTravelerInfoChange(i, 'setFlightGender', e)}
                                        />
                                        <span>女性</span>
                                      </RadioLabel>
                                    </InputAreaRight>
                                    <Errors>
                                      <Error>
                                        {!_.isEmpty(
                                          utils.dig(validationErrors, 'travelers', i, 'travelerGender')
                                        ) && <Text text={validationErrors.travelers[i].travelerGender} />}
                                      </Error>
                                    </Errors>
                                  </InputArea>
                                </ListInfoWrap>
                              )}
                            </ListContent>
                          </div>
                        ))}
                        <Button buttonType={ButtonType.SUB} onClick={e => handleAddTraveler(e)}>
                          +出張者を追加
                        </Button>
                      </List>
                    </InputArea>
                  </SectionIn>
                </Section>
                <Section>
                  <SectionIn>
                    <InputArea>
                      <Label>出張期間</Label>
                      <Term>
                        <OutDateWrap>
                          <DateTextBox id="outdateTextbox">
                            <DateLabel>出発</DateLabel>
                            <DateInputWrap>
                              <Datepicker
                                value={outdate.toDate()}
                                dateFormat="MM/DD (ddd)"
                                fullWidth
                                disabledClear
                                variant="ghost"
                                onChange={(date: Date | null) => handleChangeDate('outdate', moment(date))}
                              />
                            </DateInputWrap>
                          </DateTextBox>
                        </OutDateWrap>
                        <HomeDateWrap>
                          <DateTextBox id="homedateTextbox">
                            <DateLabel>到着</DateLabel>
                            <DateInputWrap>
                              <Datepicker
                                value={homedate.toDate()}
                                dateFormat="MM/DD (ddd)"
                                fullWidth
                                disabledClear
                                variant="ghost"
                                onChange={(date: Date | null) => handleChangeDate('homedate', moment(date))}
                              />
                            </DateInputWrap>
                          </DateTextBox>
                        </HomeDateWrap>
                      </Term>
                    </InputArea>
                  </SectionIn>
                </Section>
                <Section>
                  <SectionIn>
                    <InputArea>
                      <Label>出張先</Label>
                      <InputAreaRight>
                        <Input
                          type="text"
                          value={finalDestination}
                          onChange={e => handleChangeFinalDestination(e)}
                        />
                      </InputAreaRight>
                      {!_.isEmpty(validationErrors.finalDestination) && (
                        <Errors>
                          <Error>
                            <Text text={validationErrors.finalDestination} />
                          </Error>
                        </Errors>
                      )}
                    </InputArea>
                  </SectionIn>
                </Section>
                <Section>
                  <SectionIn>
                    <InputArea>
                      <Label>出張の目的</Label>
                      <InputAreaRight>
                        <Textarea
                          value={purpose}
                          onChange={e => handleChangePurpose(e)}
                          placeholder="出張の目的や承認者への備考などを入力してください"
                          rows={3}
                        />
                      </InputAreaRight>
                      {!_.isEmpty(validationErrors.purpose) && (
                        <Errors>
                          <Error>
                            <Text text={validationErrors.purpose} />
                          </Error>
                        </Errors>
                      )}
                    </InputArea>
                  </SectionIn>
                </Section>
                <Section>
                  <SectionIn>
                    <Button onClick={e => handleSubmit(e)}>保存</Button>
                  </SectionIn>
                </Section>
              </>
            )}
          </Content>
        </DraftTripBody>
      </div>
    </BodyWrap>
  </Wrap>
);

const DraftTripBody = styled(Body)`
  background: ${props => props.theme.contentBgColor};
`;

const Content = styled.div``;

const Section = styled.div`
  padding-bottom: 20px;
`;

const SectionIn = styled.div`
  margin-left: 10px;
  font-size: 13px;
`;

const InputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Label = styled.label`
  width: 150px;
  line-height: 34px;
`;

const List = styled.div`
  flex-grow: 1;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ListNumber = styled.p``;

const ListContent = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
`;

const TypeLabel = styled.label`
  margin: 10px 0 5px;
`;

const ListInfoWrap = styled.div`
  justify-content: space-between;
  margin-left: 10px;
`;

const ListInfo = styled.div`
  display: flex;
  padding: 5px 20px;
  border: 2px solid ${props => props.theme.grayBorderColor};
  cursor: pointer;
`;

const ListInfoIn = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ListText = styled.p`
  line-height: 26px;
  margin-right: 10px;
`;

const Errors = styled.div`
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 300px;
`;

const NameInput = styled.input`
  &&& {
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const RadioLabel = styled.label`
  margin: 10px 10px 5px 0;
  font-size: 13px;
`;

const RadioInput = styled.input``;

const Button = styled.button`
  ${ButtonBase}
  font-size: 13px;
`;

const Term = styled.div`
  display: flex;
  flex-grow: 1;
`;

const DateWrap = styled.div`
  flex-grow: 1;
  background-color: white;
`;

const OutDateWrap = styled(DateWrap)`
  margin: 0;
`;

const HomeDateWrap = styled(DateWrap)`
  margin: 0 0 0 10px;
`;

const DateTextBox = styled.div`
  padding: 3px 5px;
  border: solid 2px #f7f7f7;
`;

const DateLabel = styled.label`
  color: ${props => props.theme.linkColor};
  font-size: 12px;
  font-weight: 700;
`;

const DateInputWrap = styled.div`
  width: 100%;
`;

const Input = styled.input`
  &&& {
    max-width: 300px;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

const Textarea = styled.textarea`
  &&& {
    max-width: 300px;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${props => props.theme.linkColor};
    }
  }
`;

export default DraftTripsFormTemplate;
