import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';

export const Note = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const Error = styled.div`
  color: ${(props: any) => props.theme.redColor};
`;

export const EditWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditSection = styled.div`
  width: 100%;
  padding: 5px;
`;

export const InputArea = styled.div`
  display: flex;
  margin-bottom: 10px;
  text-align: left;
`;

export const Label = styled.label`
  width: 20%;
  font-size: 13px;
  font-weight: normal;
`;

export const Input = styled.input`
  width: 40% !important;
  margin-bottom: 10px;
  margin-right: 10px;
  &&&:focus {
    box-shadow: none;
    border-color: ${(props: any) => props.theme.linkColor};
  }
`;

export const Textarea = styled.textarea`
  &&& {
    width: 40% !important;
    margin-right: 10px;
    font-size: 13px;

    &:focus {
      box-shadow: none;
      border-color: ${(props: any) => props.theme.linkColor};
    }
  }
`;

export const Select = styled.select`
  width: 40% !important;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0.5em;
`;

export const Saving = styled.div``;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalButton = styled.input`
  &&& {
    ${ButtonBase};
    width: 100px;
    margin: 0 0 0 20px;
    padding: 0.75em 1em;
  }
`;

export const InputNote = styled.div`
  width: 40%;
  color: ${(props: any) => props.theme.linkColor};
  font-size: 12px;
`;

export const Tr = styled.tr`
  &:hover {
    background: #fff9ed;
  }
`;

export const ButtonContents = styled.div`
  display: flex;
  padding: 20px;
  gap: 6px 15px;
`;
