import React from 'react';

interface Props {
  encoding: string;
  onChange: (value: string) => void;
}

interface State {}

class EncodingSelector extends React.Component<Props, State> {
  componentDidMount() {
    this.props.onChange(navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8');
  }

  render() {
    try {
      const { encoding, onChange } = this.props;
      return (
        <select value={encoding} onChange={e => onChange(e.target.value)} style={{ margin: 0 }}>
          <option value="utf8">Macなど（文字コード：UTF8）</option>
          <option value="sjis">Windows（文字コード：SJIS）</option>
        </select>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default EncodingSelector;
