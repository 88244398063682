import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import type { RuleCabinet } from '@this/domain/expenses/rule_cabinet';
import { TextField } from '@this/shared/form_elements/form_elements';
import {
  OrganizationBiztraEditButton,
  OrganizationFormSection,
  OrganizationLabel,
  OrganizationButtonSection
} from '@this/components/expenses/organization/organization.style';
import { styled } from '@this/constants/themes';

interface Props {
  ruleCabinet: RuleCabinet;
  onSubmit: () => void;
  submitting: boolean;
  errors?: string | string[];
  edit?: boolean;
  action: 'new' | 'edit';
  backPath: string;
}

interface State {
  ruleCabinet?: RuleCabinet;
}

const baseClass = 'expenses-report-item-form';

@observer
class Form extends React.Component<Props, State> {
  private handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.onSubmit();
  }

  private errors() {
    const errors = this.props.errors;
    if (!errors) {
      return null;
    }
    const toElm = (line: string) => {
      const error = line;
      return <p key={error}>{error}</p>;
    };
    const elm = typeof errors === 'string' ? errors.split('\n').map(toElm) : errors.map(toElm);
    return <div className="expenses-report-item-form__errors">{elm}</div>;
  }

  render() {
    const { ruleCabinet, backPath } = this.props;
    return (
      <OrganizationFormSection>
        {this.props.action === 'new' ? (
          <>
            <label>タイトル</label>
            <TextField
              className={`${baseClass}__group__full ${baseClass}__title`}
              label=""
              value={ruleCabinet?.title === null ? '' : `${ruleCabinet?.title}`}
              example="タイトルを入力してください"
              onChange={e => ruleCabinet.setTitle(e)}
              borderType
            />
          </>
        ) : (
          <OrganizationLabel>{ruleCabinet.title}</OrganizationLabel>
        )}

        <TextArea
          placeholder="※ 運用ルール記載エリア"
          value={ruleCabinet?.content === null ? '' : `${ruleCabinet?.content}`}
          minRows={20}
          className={`${baseClass}__rule-content`}
          onChange={e => ruleCabinet.setContent(e.target.value)}
        />
        {this.errors()}
        <OrganizationButtonSection>
          <Link to={backPath}>
            <OutlineButton>キャンセル</OutlineButton>
          </Link>
          <OrganizationBiztraEditButton onClick={e => this.handleSubmit(e)}>保存</OrganizationBiztraEditButton>
        </OrganizationButtonSection>
      </OrganizationFormSection>
    );
  }
}

const outlineBtnColor = '#FFFFFF';

const baseColor = '#927230';

const OutlineButton = styled(OrganizationBiztraEditButton)`
  background: ${outlineBtnColor};
  color: ${baseColor};
  border: 1px solid ${baseColor};
  &&:focus,
  &&:hover {
    background: ${outlineBtnColor};
    color: ${baseColor};
  }
`;

export default Form;
