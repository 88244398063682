/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { TextField } from '@this/shared/search/search';
import SearchableSelect from '@this/shared/searchable_select';

import type NonOrderItem from '@this/domain/non_order_item';

import EkispertInput from '@this/shared/autocompletable_input/ekispert_input';
import type { AirportType } from '@this/shared/autocompletable_input/airport_input';
import AirportInput from '@this/shared/autocompletable_input/airport_input';
import type { AirlineName } from './trips_form_block/trips_form_itemform.template';

interface Props {
  item: NonOrderItem;
  itemType: string;
  editing: boolean | null;
  domesticAirlines: AirlineName[];
  internationalAirlines: AirlineName[];
  deselectItemType: (e: React.MouseEvent<HTMLElement>) => void;
  addNonOrderItemToTrip: (e: React.FormEvent<HTMLFormElement>) => void;
  handleTimeChange: (item: NonOrderItem, type: 'from' | 'to', time: Moment) => void;
  tripItemFormTemplateErrMsg: string;
}

const TripItemFormTemplate: React.SFC<Props> = ({
  item,
  itemType,
  editing,
  domesticAirlines,
  internationalAirlines,
  deselectItemType,
  addNonOrderItemToTrip,
  handleTimeChange,
  tripItemFormTemplateErrMsg
}) => (
  <ContentWrap>
    <Content>
      <form onSubmit={e => addNonOrderItemToTrip(e)}>
        <SearchArea>
          {itemType === 'hotel' ? (
            <>
              <Section>
                <FlexBlock>
                  <SubLabel>ホテル名</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].name}
                      onChange={e => item.hotels[0].handleNameChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>所在地</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].address}
                      onChange={e => item.hotels[0].handleAddressChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>電話</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].tel}
                      onChange={e => item.hotels[0].handleTelChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>部屋タイプ</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].roomType}
                      onChange={e => item.hotels[0].handleRoomTypeChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>ベッドタイプ</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].bedType}
                      onChange={e => item.hotels[0].handleBedTypeChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>朝食</SubLabel>
                  <Select
                    value={item.hotels[0].breakfast}
                    onChange={e => item.hotels[0].handleBreakfastChange(e.target.value)}
                  >
                    <option value="true">朝食あり</option>
                    <option value="false">朝食なし</option>
                  </Select>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>返金</SubLabel>
                  <Select
                    value={item.hotels[0].refundable}
                    onChange={e => item.hotels[0].handleRefundableChange(e.target.value)}
                  >
                    <option value="true">可</option>
                    <option value="false">不可</option>
                  </Select>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>チェックイン</SubLabel>
                  <InputWrap>
                    <DatetimePicker
                      value={item.hotels[0].checkinDate}
                      onChange={time => item.hotels[0].handleCheckinDateChange(time.endOf('day'))}
                      disabledDays={0}
                      showTime={false}
                      showPast
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>チェックアウト</SubLabel>
                  <InputWrap>
                    <DatetimePicker
                      value={item.hotels[0].checkoutDate}
                      onChange={time => item.hotels[0].handleCheckoutDateChange(time)}
                      disabledDays={0}
                      showTime={false}
                      showPast
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>宿泊者(姓)</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].guestLastName || ''}
                      onChange={e => item.hotels[0].handleGuestLastNameChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>宿泊者(名)</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].guestFirstName || ''}
                      onChange={e => item.hotels[0].handleGuestFirstNameChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>宿泊者電話</SubLabel>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.hotels[0].guestTel || ''}
                      onChange={e => item.hotels[0].handleGuestTelChange(e.target.value)}
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>宿泊料金</SubLabel>
                  <InputWrap>
                    <Input
                      type="number"
                      value={item.price.price}
                      onChange={e => item.price.setPrice(parseInt(e.target.value, 10) || 0)}
                    />
                  </InputWrap>
                  <InputRightText>円</InputRightText>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>支払い</SubLabel>
                  <Select value={item.paymentType} onChange={e => item.handlePaymentTypeChange(e.target.value)}>
                    <option value="on_me">個人立替</option>
                    <option value="on_the_company">会社請求</option>
                    <option value="others">その他</option>
                  </Select>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>人数</SubLabel>
                  <InputWrap>
                    <Select
                      name="peoplenum"
                      id="search__input-peoplenum"
                      value={item.peopleNum}
                      onChange={e => item.handlePeopleNumChange(parseInt(e.target.value, 10))}
                    >
                      {_.times(8, i => (
                        <option value={i + 1} key={i}>{`${i + 1}人`}</option>
                      ))}
                    </Select>
                  </InputWrap>
                </FlexBlock>
              </Section>
              <Section>
                <FlexBlock>
                  <SubLabel>部屋数</SubLabel>
                  <InputWrap>
                    <Select
                      name="peoplenum"
                      id="search__input-roomnum"
                      value={item.hotels[0].roomNum}
                      onChange={e => item.hotels[0].handleRoomNumChange(e.target.value)}
                    >
                      {_.times(8, i => (
                        <option value={i + 1} key={i}>{`${i + 1}部屋`}</option>
                      ))}
                    </Select>
                  </InputWrap>
                </FlexBlock>
              </Section>
            </>
          ) : (
            <>
              {itemType === 'shinkansen' && (
                <Section>
                  <Label>座席</Label>
                  <Select
                    value={item.transports[0].railwaySeatType || '0'}
                    onChange={e => item.transports[0].handleRailwaySeatTypeChange(e.target.value)}
                  >
                    <option value="0">自由席</option>
                    <option value="1">指定席</option>
                    <option value="2">グリーン車</option>
                    <option value="5">グランクラス</option>
                  </Select>
                </Section>
              )}
              {itemType.indexOf('air') > -1 && (
                <>
                  <Section>
                    <Label>航空会社</Label>
                    <SearchableSelect
                      list={_.map(
                        itemType === 'domestic_air' ? domesticAirlines : internationalAirlines,
                        airline => {
                          let displayName = airline.name;
                          if (itemType !== 'domestic_air') {
                            const code =
                              airline.code === 'JL' ? 'JAL, JL' : airline.code === 'NH' ? 'ANA, NH' : airline.code;
                            displayName = `${code}: ${airline.name}`;
                          }
                          return { value: airline.code, displayName };
                        }
                      )}
                      currentValue={item.transports[0].airlineCode}
                      onChange={(v: string) => item.transports[0].handleAirlineCodeChange(v)}
                    />
                  </Section>
                  <Section>
                    <Label>便名</Label>
                    <InputWrap>
                      <Input
                        type="text"
                        value={item.transports[0].name}
                        onChange={e => item.transports[0].handleNameChange(e.target.value)}
                      />
                    </InputWrap>
                  </Section>
                  <Section>
                    <Label>シートクラス</Label>
                    <Select
                      value={item.transports[0].airClass}
                      onChange={e => item.transports[0].handleAirClassChange(e.target.value)}
                    >
                      <option value="M">エコノミー</option>
                      <option value="W">プレミアムエコノミー</option>
                      <option value="C">ビジネス</option>
                      <option value="F">ファースト</option>
                    </Select>
                  </Section>
                </>
              )}
              {itemType === 'rental_car' && (
                <Section>
                  <Label>手配会社</Label>
                  <Input
                    type="text"
                    value={item.transports[0].name || ''}
                    onChange={e => item.transports[0].handleNameChange(e.target.value)}
                  />
                </Section>
              )}
              {['railway_ticket', 'shinkansen'].indexOf(itemType) > -1 ? (
                <>
                  <Section>
                    <Label>出発駅</Label>
                    <InputWrap>
                      <EkispertInput
                        example="駅名"
                        onChange={(_, value) => item.transports[0].handleBoardingStationChange(value, true)}
                        value={item.transports[0].boardingStation}
                        id="boardingStation"
                        className=" "
                        trafficTypes={itemType === 'shinkansen' ? ['train.shinkansen'] : ['train']}
                      />
                    </InputWrap>
                  </Section>
                  <Section>
                    <Label>到着駅</Label>
                    <InputWrap>
                      <EkispertInput
                        example="駅名"
                        onChange={(_, value) => item.transports[0].handleArrivalStationChange(value, true)}
                        value={item.transports[0].arrivalStation}
                        id="arrivalStation"
                        className=" "
                        trafficTypes={itemType === 'shinkansen' ? ['train.shinkansen'] : ['train']}
                      />
                    </InputWrap>
                  </Section>
                </>
              ) : ['domestic_air', 'foreign_air'].indexOf(itemType) > -1 ? (
                <>
                  <Section>
                    <Label>出発空港</Label>
                    <InputWrap>
                      <AirportInput
                        example="空港名"
                        onChange={(_, value) => item.transports[0].handleBoardingStationChange(value, true)}
                        value={item.transports[0].boardingStation}
                        id="boardingStation"
                        airportType={itemType as AirportType}
                        className=" "
                      />
                    </InputWrap>
                  </Section>
                  <Section>
                    <Label>到着空港</Label>
                    <InputWrap>
                      <AirportInput
                        example="空港名"
                        onChange={(_, value) => item.transports[0].handleArrivalStationChange(value, true)}
                        value={item.transports[0].arrivalStation}
                        id="arrivalStation"
                        airportType={itemType as AirportType}
                        className=" "
                      />
                    </InputWrap>
                  </Section>
                </>
              ) : (
                <>
                  <Section>
                    <Label>出発地</Label>
                    <InputWrap>
                      <Input
                        type="text"
                        value={item.transports[0].boardingStation}
                        onChange={e => item.transports[0].handleBoardingStationChange(e.target.value, true)}
                      />
                    </InputWrap>
                  </Section>
                  <Section>
                    <Label>到着地</Label>
                    <InputWrap>
                      <Input
                        type="text"
                        value={item.transports[0].arrivalStation}
                        onChange={e => item.transports[0].handleArrivalStationChange(e.target.value, true)}
                      />
                    </InputWrap>
                  </Section>
                </>
              )}
              {itemType === 'foreign_air' && (
                <Section>
                  <Label>購入元</Label>
                  <Input
                    type="text"
                    value={item.supplier || ''}
                    onChange={e => item.handleSupplierChange(e.target.value)}
                  />
                </Section>
              )}
              <Section>
                <Label>日時</Label>
                <FlexBlock>
                  <SubLabel>出発</SubLabel>
                  <InputWrap>
                    <DatetimePicker
                      value={item.transports[0].from.time}
                      onChange={time => handleTimeChange(item, 'from', time)}
                      disabledDays={0}
                      showTime
                      showPast
                    />
                  </InputWrap>
                </FlexBlock>
                <FlexBlock>
                  <SubLabel>到着</SubLabel>
                  <InputWrap>
                    <DatetimePicker
                      value={item.transports[0].to.time}
                      onChange={time => handleTimeChange(item, 'to', time)}
                      disabledDays={0}
                      showTime
                      showPast
                    />
                  </InputWrap>
                </FlexBlock>
              </Section>
              {itemType === 'shinkansen' && (
                <Section>
                  <Label>便名</Label>
                  <InputWrap>
                    <Input
                      type="text"
                      value={item.transports[0].name}
                      onChange={e => item.transports[0].handleNameChange(e.target.value)}
                    />
                  </InputWrap>
                </Section>
              )}
              {itemType === 'rental_car' && (
                <Section>
                  <Label>車種クラス</Label>
                  <Select
                    value={item.transports[0].carType}
                    onChange={e => item.transports[0].handleCarTypeChange(e.target.value)}
                  >
                    <option value="compact">コンパクト</option>
                    <option value="sedan">セダン</option>
                    <option value="other">その他</option>
                  </Select>
                  <TextField
                    type="text"
                    value={item.transports[0].carTypeOther}
                    placeholder="車種等"
                    onChange={e => item.transports[0].handleCarTypeOtherChange(e.target.value)}
                    disabled={item.transports[0].carType !== 'other'}
                  />
                </Section>
              )}
              <Section>
                <Label>支払い</Label>
                <Select value={item.paymentType} onChange={e => item.handlePaymentTypeChange(e.target.value)}>
                  <option value="on_me">個人立替</option>
                  <option value="on_the_company">会社請求</option>
                  <option value="others">その他</option>
                </Select>
              </Section>
              <Section>
                <Label>{itemType.indexOf('air') > -1 ? '航空運賃' : '乗車料金'}</Label>
                <FlexBlock>
                  <InputWrap>
                    <Input
                      type="number"
                      value={item.price.price}
                      onChange={e => item.price.setPrice(parseInt(e.target.value, 10))}
                    />
                  </InputWrap>
                  <InputRightText>円</InputRightText>
                </FlexBlock>
              </Section>
              {itemType === 'rental_car' && (
                <Section>
                  <Label>給油金額(ガソリン実費費用)</Label>
                  <FlexBlock>
                    <InputWrap>
                      <Input
                        type="number"
                        value={item.price.details[0].price}
                        onChange={e => item.price.details[0].setPrice(parseInt(e.target.value, 10))}
                      />
                    </InputWrap>
                    <InputRightText>円</InputRightText>
                  </FlexBlock>
                </Section>
              )}
              <Section>
                <Label>人数</Label>
                <InputWrap>
                  <Select
                    name="peoplenum"
                    id="search__input-peoplenum"
                    value={item.peopleNum}
                    onChange={e => item.handlePeopleNumChange(parseInt(e.target.value, 10))}
                  >
                    {_.times(8, i => (
                      <option value={i + 1} key={i}>{`${i + 1}人`}</option>
                    ))}
                  </Select>
                </InputWrap>
              </Section>
            </>
          )}
          {tripItemFormTemplateErrMsg && <ErrorMessage>{tripItemFormTemplateErrMsg}</ErrorMessage>}
        </SearchArea>
        <SearchButton>
          {itemType === 'hotel' ? (
            <Total>
              <TotalNum>{item.hotels[0].roomNum}人の料金</TotalNum>
              <TotalPrice>{`合計：${utils.formatPrice(item.price.price * item.hotels[0].roomNum)}`}</TotalPrice>
            </Total>
          ) : (
            <Total>
              <TotalNum>{item.peopleNum}人の料金</TotalNum>
              <TotalPrice>{`合計：${utils.formatPrice(item.price.totalPrice() * item.peopleNum)}`}</TotalPrice>
            </Total>
          )}
          <ButtonWrap>
            <Button type="submit" value={`${editing ? '変更を保存' : '追加'}`} />
          </ButtonWrap>
        </SearchButton>
      </form>
    </Content>
  </ContentWrap>
);

const ContentWrap = styled.div``;

const Content = styled.div`
  padding-top: 20px;
`;

const Section = styled.div`
  margin-bottom: 0.75em;
`;

const Label = styled.div`
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: bold;
`;

const InputWrap = styled.div`
  font-size: 13px;
  line-height: 34px;
`;

const Input = styled.input`
  &&& {
    margin-bottom: 0;
  }
`;

const InputRightText = styled.div`
  margin-left: 8px;
  font-size: 13px;
  line-height: 30px;
`;

const Select = styled.select`
  margin-bottom: 0;
`;

const FlexBlock = styled.div`
  display: flex;
`;

const SubLabel = styled.div`
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
`;

const Total = styled.div``;

const TotalNum = styled.div`
  font-size: 12px;
`;

const TotalPrice = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const SearchArea = styled.div`
  padding: 20px;
`;

export const SearchButton = styled.div`
  background-color: #f8f6f1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  width: 200px;
`;

const Button = styled.input`
  ${ButtonBase}
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
`;
export default TripItemFormTemplate;
