import React from 'react';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import type { Moment } from 'moment';
import { Input } from '@this/shared/ui/inputs/input';
import Calendar from 'rc-calendar';

const DatePicker = require('rc-calendar/lib/Picker');
const jaJP = require('rc-calendar/lib/locale/ja_JP');

interface Props {
  value: string;
  onChange: (value: Moment) => void;
}

type DatePickerChildFunc = (props: { value: Moment }) => void;

const ModalZIndex = 1300;

class DatePickerForSearch extends React.Component<Props> {
  onChange = (value: Moment) => {
    this.props.onChange(value);
  };

  render() {
    const calendar = <Calendar locale={jaJP} />;
    const input: DatePickerChildFunc = () => (
      <TheInput
        size="small"
        value={this.props.value}
        style={{ width: '100%' }}
        readOnly
        inputProps={{
          placeholder: moment().format('YYYY/MM/DD'),
          style: { fontSize: 14 }
        }}
      />
    );
    return (
      <DatePicker
        style={{ zIndex: ModalZIndex + 1 }}
        calendar={calendar}
        onChange={this.onChange}
        onSelect={this.onChange}
      >
        {input}
      </DatePicker>
    );
  }
}

const TheInput = styled(Input)`
  padding: 10px;
  background: #faf9f8;

  .MuiOutlinedInput-notchedOutline {
    border-color: #f1ede5;
  }
`;

export default DatePickerForSearch;
