import { observable } from 'mobx';
import type Project from './project';
import type { ProjectShareMappingArgs } from './project_share_mapping';
import ProjectShareMappingList from './project_share_mapping_list';

export type ProjectShareArgs = {
  id: number;
  organization_id: number;
  code: string;
  name: string;
  disabled: boolean;
  editable: boolean;
  project_share_mappings?: ProjectShareMappingArgs[];
};

export default class ProjectShare {
  @observable
  id: number;

  @observable
  organizationId: number;

  @observable
  code: string;

  @observable
  name: string;

  @observable
  disabled: boolean;

  @observable
  editable: boolean;

  @observable
  projectShareMappings: ProjectShareMappingList;

  constructor(args: ProjectShareArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.code = args.code;
    this.name = args.name;
    this.disabled = args.disabled;
    this.editable = args.editable;
    if (args.project_share_mappings) {
      this.projectShareMappings = new ProjectShareMappingList(args.project_share_mappings);
    } else {
      this.projectShareMappings = new ProjectShareMappingList([]);
    }
  }

  clone(): ProjectShare {
    const args: ProjectShareArgs = {
      id: this.id,
      organization_id: this.organizationId,
      code: this.code,
      name: this.name,
      disabled: this.disabled,
      editable: this.editable,
      project_share_mappings: this.projectShareMappings.list.map(mapping => ({
        id: mapping.id,
        project_id: mapping.projectId,
        project: mapping.project,
        project_share_id: mapping.projectShareId,
        weight: mapping.weight
      }))
    };
    return new ProjectShare(args);
  }

  codeAndNameOneLine = (): string => `${this.code}:${this.name}`;

  codeAndNameLines = (): string[] => [`${this.code}-${this.name}`, this.createShareLabel()];

  createShareLabel(): string {
    let content = '';
    this.percentages().forEach(item => {
      const project = item.project;
      if (project) {
        if (content) {
          content += ' : ';
        }
        content += `${project.code}-${project.name}(${item.percentage}%)`;
      }
    });

    return content;
  }

  percentages(): { project: Project; percentage: number }[] {
    const shareMappings = this.projectShareMappings;
    if (shareMappings && shareMappings.list.length > 0) {
      const totalWeight = shareMappings.list.reduce((sum, shareMapping) => sum + shareMapping.weight, 0);
      const percentageList = shareMappings.list.map(shareMapping =>
        Math.floor((100 * shareMapping.weight) / totalWeight)
      );
      const totalPercentage = percentageList.reduce((sum, percentage) => sum + percentage, 0);
      percentageList[0] += 100 - totalPercentage;

      return shareMappings.list.map((shareMapping, i) => ({
        project: shareMapping.project,
        percentage: percentageList[i]
      }));
    }
    return [];
  }

  setCode = (value: string) => {
    this.code = value;
    app.render();
  };

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setDisabled = (value: boolean) => {
    this.disabled = value;
    app.render();
  };

  setProjectShareMappings = (value: ProjectShareMappingList) => {
    this.projectShareMappings = value;
    app.render();
  };

  submitParams() {
    const mappings = this.projectShareMappings.list.map(s => s.submitParams());
    return {
      code: this.code,
      name: this.name,
      disabled: this.disabled,
      project_share_mappings: mappings
    };
  }
}
