import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export interface Arranger {
  id: number;
  name: string;
}

interface Props {
  selectedArrangerIds: number[];
  onSelect: (ids: number[]) => void;
}

interface Response {
  arrangers: Arranger[];
}

const ArrangementArrangerSelector = observer(({ selectedArrangerIds, onSelect }: Props) => {
  const [arrangers, setArrangers] = useState<Arranger[]>([]);
  const [selectedArrangers, setSelectedArrangers] = useState<Arranger[]>([]);
  const [query, setQuery] = useState<string>('');

  const fetchArrangers = useCallback(() => {
    const params = {
      q: query,
      ids: selectedArrangers.map(a => a.id).join(',')
    };
    utils.jsonPromise<Response>('/arrangement/arrangers/autocomplete.json', params).then(res => {
      if (res.arrangers.map(a => a.id).join(',') !== arrangers.map(a => a.id).join(',')) {
        setArrangers(res.arrangers);
      }
    });
  }, [query, selectedArrangers]);

  useEffect(() => {
    fetchArrangers();
  }, [fetchArrangers]);

  useEffect(() => {
    setSelectedArrangers(arrangers.filter(arranger => selectedArrangerIds.includes(arranger.id)));
  }, [arrangers, selectedArrangerIds]);

  return (
    <Autocomplete
      multiple
      options={arrangers}
      getOptionLabel={a => a.name}
      filterOptions={(options, state) => {
        const q: string = state.inputValue;
        return _.filter(options, a => a.name.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
      }}
      onChange={(_, selected) => {
        onSelect(selected.map(a => a.id));
        setQuery('');
      }}
      onInputChange={(_e, value, reason) => {
        if (reason === 'input') {
          setQuery(value);
        }
      }}
      inputValue={query}
      value={selectedArrangers}
      size="small"
      renderInput={params => (
        <TextField {...params} variant="outlined" style={{}} InputLabelProps={{ shrink: true }} size="small" />
      )}
      style={{ minWidth: '240px' }}
    />
  );
});

export default ArrangementArrangerSelector;
