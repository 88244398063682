import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import type OrderItem from '@this/domain/order_item';
import type NonOrderItem from '@this/domain/non_order_item';
import TransportElement from '@this/domain/transport_element';
import HotelElement from '@this/domain/hotel_element';

interface Props {
  trip: any;
  isOrderItem: boolean;
}

const ItemArrangeIcons: React.FC<Props> = props => {
  const symbols = getSymbolsByTrip(props);

  return (
    <>
      {!props.isOrderItem && symbols.length > 0 && <SmallLabel className="text--muted">自己手配</SmallLabel>}
      {symbols.map((symbol, i) => {
        return (
          <li key={i}>
            {symbol === 'hotel' && <ArrangeIconHotel />}
            {symbol === 'air' && <ArrangeIconAir />}
            {symbol === 'rental_car' && <ArrangeIconRentalCar />}
            {symbol === 'shinkansen' && <ArrangeIconShinkansen />}
            {symbol === 'bus' && <ArrangeIconBus />}
            {symbol === 'train' && <ArrangeIconTrain />}
          </li>
        );
      })}
    </>
  );
};

const getSymbolsByTrip = ({ trip, isOrderItem }: Props) => {
  const symbols: string[] = [];

  if (isOrderItem) {
    if (trip.currentOrder) {
      trip.order.orderItems.forEach((item: OrderItem) => {
        switch (item.orderItemCategory) {
          case 'hotel':
            symbols.push('hotel');
            break;
          case 'domestic_air':
          case 'foreign_air':
            symbols.push('air');
            break;
          case 'shinkansen':
          case 'express':
            symbols.push('shinkansen');
            break;
          case 'railway_ticket':
            symbols.push('train');
            break;
          case 'rental_car':
            symbols.push('rental_car');
            break;
          case 'bus':
            symbols.push('bus');
            break;
          case 'other':
            symbols.push('option');
            break;
          case 'wbf_package':
            symbols.push('hotel');
            symbols.push('air');
            break;
          default:
            break;
        }
      });
    }
  } else if (trip.nonOrderItems.length > 0) {
    trip.nonOrderItems.forEach((non_order_item: NonOrderItem) => {
      non_order_item.elements.forEach((element: any) => {
        if (element instanceof TransportElement) {
          switch (element.transportType) {
            case 'domestic_air':
            case 'foreign_air':
              symbols.push('air');
              break;
            case 'shinkansen':
              symbols.push('shinkansen');
              break;
            case 'railway_ticket':
              symbols.push('train');
              break;
            case 'rental_car':
              symbols.push('rental_car');
              break;
            case 'bus':
              symbols.push('bus');
              break;
            default:
              break;
          }
        } else if (element instanceof HotelElement) {
          symbols.push('hotel');
        }
      });
    });
  }
  return _.uniq(symbols);
};

const SmallLabel = styled.small`
  margin-bottom: 2px;
`;

const ArrangeIcon = styled.i`
  display: block;
  width: 20px;
  height: 20px;
  margin: 0;
  background-size: 20px 20px;
`;

const ArrangeIconHotel = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/hotel.png');
`;

const ArrangeIconAir = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/air.png');
`;

const ArrangeIconShinkansen = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/shinkansen.png');
`;

const ArrangeIconRentalCar = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/rental_car.png');
`;

const ArrangeIconBus = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/bus.png');
`;

const ArrangeIconTrain = styled(ArrangeIcon)`
  background-image: url('/images/organization/trips/icons/train.png');
`;

export default ItemArrangeIcons;
