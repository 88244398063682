import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonBase, SubmitButton, BorderButtonBase } from '@this/shared/atoms/button';
import { AccountStatus } from '@this/domain/user/organization_member_json';

interface Props {
  onShowBulkInvitation: () => void;
  onShowBulkDisabling: () => void;
  onShowBulkEnabling: () => void;
  accountStatus: AccountStatus;
  currentUserIsAdmin: boolean;
  disabled: boolean;
}

const MemberBulkInvitationAndDisabling: React.FC<Props> = ({
  onShowBulkInvitation,
  onShowBulkDisabling,
  onShowBulkEnabling,
  accountStatus,
  currentUserIsAdmin,
  disabled
}) => {
  const [confirmBulkInvitation, setConfirmBulkInvitation] = useState(false);
  const [confirmBulkDisabling, setConfirmBulkDisabling] = useState(false);
  const [confirmBulkEnabling, setConfirmBulkEnabling] = useState(false);

  const handleClickBulkInvitationButton = () => {
    setConfirmBulkInvitation(true);
  };
  const handleCloseBulkInvitationButton = () => {
    setConfirmBulkInvitation(false);
  };
  const execBulkInvitation = () => {
    setConfirmBulkInvitation(false);
    onShowBulkInvitation();
  };

  const handleClickBulkDisablingButton = () => {
    setConfirmBulkDisabling(true);
  };
  const handleCloseBulkDisablingButton = () => {
    setConfirmBulkDisabling(false);
  };
  const execBulkDisabling = () => {
    setConfirmBulkDisabling(false);
    onShowBulkDisabling();
  };

  const handleClickBulkEnablingButton = () => {
    setConfirmBulkEnabling(true);
  };
  const handleCloseBulkEnablingButton = () => {
    setConfirmBulkEnabling(false);
  };
  const execBulkEnabling = () => {
    setConfirmBulkEnabling(false);
    onShowBulkEnabling();
  };
  const buttonText = () => {
    if (disabled) {
      return '処理実行中';
    }
    switch (accountStatus) {
      case AccountStatus.UNINVITE:
        return '一括招待';
      case AccountStatus.ENABLED:
        return '一括無効';
      case AccountStatus.DISABLED:
        return '一括有効';
      default:
        return '';
    }
  };

  return (
    <>
      {!currentUserIsAdmin ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {accountStatus !== AccountStatus.INVITED && (
            <OrganiztionMembersBulkInvitationAndDisablingLabel>
              ※
              フィルタリング後の対象に対して一括して処理します。また、招待は未招待ユーザーに対してのみメールが送信されます。
            </OrganiztionMembersBulkInvitationAndDisablingLabel>
          )}
          <OrganiztionMembersBulkInvitationAndDisabling>
            {accountStatus === AccountStatus.UNINVITE && (
              <BulkInvitationButton
                type="submit"
                value={buttonText()}
                onClick={() => onShowBulkInvitation()}
                disabled={disabled}
              />
            )}
            {accountStatus === AccountStatus.ENABLED && (
              <BulkDisablingButton
                type="submit"
                value={buttonText()}
                onClick={() => onShowBulkDisabling()}
                disabled={disabled}
              />
            )}
            {accountStatus === AccountStatus.DISABLED && (
              <BulkDisablingButton
                type="submit"
                value={buttonText()}
                onClick={() => onShowBulkEnabling()}
                disabled={disabled}
              />
            )}
          </OrganiztionMembersBulkInvitationAndDisabling>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <OrganiztionMembersBulkInvitationAndDisablingLabel>
            ※
            フィルタリング後の対象に対して一括して処理します。また、招待は未招待ユーザーに対してのみメールが送信されます。
          </OrganiztionMembersBulkInvitationAndDisablingLabel>
          <OrganiztionMembersBulkInvitationAndDisabling>
            {accountStatus === AccountStatus.UNINVITE && (
              <BulkInvitationButton
                type="submit"
                value={buttonText()}
                onClick={() => handleClickBulkInvitationButton()}
                disabled={disabled}
              />
            )}
            {accountStatus === AccountStatus.ENABLED && (
              <BulkDisablingButton
                type="submit"
                value={buttonText()}
                onClick={() => handleClickBulkDisablingButton()}
                disabled={disabled}
              />
            )}
            {accountStatus === AccountStatus.DISABLED && (
              <BulkDisablingButton
                type="submit"
                value={buttonText()}
                onClick={() => handleClickBulkEnablingButton()}
                disabled={disabled}
              />
            )}
          </OrganiztionMembersBulkInvitationAndDisabling>
          {confirmBulkInvitation && (
            <BulkOperationsButton className="e2e-bulk-invite">
              <div>フィルタリング後の対象に対して一括して招待メールを送信します。よろしいですか？</div>
              <BulkOperationsCancelButton onClick={() => handleCloseBulkInvitationButton()}>
                キャンセル
              </BulkOperationsCancelButton>
              <BulkOperationsConfirmedButton onClick={() => execBulkInvitation()}>
                はい
              </BulkOperationsConfirmedButton>
            </BulkOperationsButton>
          )}
          {confirmBulkDisabling && (
            <BulkOperationsButton className="e2e-bulk-disabled">
              <div>フィルタリング後の対象に対して一括して無効化します。よろしいですか？</div>
              <BulkOperationsCancelButton onClick={() => handleCloseBulkDisablingButton()}>
                キャンセル
              </BulkOperationsCancelButton>
              <BulkOperationsConfirmedButton onClick={() => execBulkDisabling()}>
                はい
              </BulkOperationsConfirmedButton>
            </BulkOperationsButton>
          )}
          {confirmBulkEnabling && (
            <BulkOperationsButton className="e2e-bulk-enabled">
              <div>フィルタリング後の対象に対して一括して有効化します。よろしいですか？</div>
              <BulkOperationsCancelButton onClick={() => handleCloseBulkEnablingButton()}>
                キャンセル
              </BulkOperationsCancelButton>
              <BulkOperationsConfirmedButton onClick={() => execBulkEnabling()}>
                はい
              </BulkOperationsConfirmedButton>
            </BulkOperationsButton>
          )}
        </div>
      )}
    </>
  );
};

const OrganiztionMembersBulkInvitationAndDisabling = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrganiztionMembersBulkInvitationAndDisablingLabel = styled.label`
  font-weight: normal;
  margin: 0 0 10px 10px;
`;

const BulkInvitationButton = styled(SubmitButton)`
  &&& {
    width: 100px;
    margin-right: 30px;
  }
`;

const BulkOperationsButton = styled.div`
  display: flex;
  > input,
  > input:hover:hover {
    width: 100px;
    margin-left: 20px;
  }
`;

const BulkOperationsCancelButtonCss = css`
  ${BorderButtonBase};
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const BulkOperationsConfirmedButtonCss = css`
  ${ButtonBase};
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const BulkOperationsCancelButton = styled.button`
  ${BulkOperationsCancelButtonCss}
`;

const BulkOperationsConfirmedButton = styled.button`
  ${BulkOperationsConfirmedButtonCss}
`;

const BulkDisablingButton = styled(SubmitButton)`
  &&& {
    width: 100px;
  }
`;

export default MemberBulkInvitationAndDisabling;
