import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/src/components/constants/themes';
import Notification from '@this/src/notification';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type JalReservation from '@this/src/domain/arrangement/jal_reservation_total';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import { Fetcher } from '@this/src/util';

interface Props {
  jalReservationTotal: JalReservation;
  onApprove: () => void;
}

interface ApproveResponse {
  jal_auto_reservation_total_approval: {
    reservation_date: string;
    approved_at: string;
    approved_by: ArrangerArgs;
  };
}

const AirAutoJalReservationTotalTableRow: React.FC<Props> = observer(({ jalReservationTotal, onApprove }) => {
  const [loading, setLoading] = useState(false);

  const handleApprove = useCallback(() => {
    setLoading(true);
    const params = {
      reservation_date: jalReservationTotal.reservationDate.format('YYYY-MM-DD')
    };
    Fetcher.post<ApproveResponse>('/arrangement/auto_reservation_inventory/jal_booking/total/confirm.json', params)
      .then(res => {
        Notification.success(`${res.jal_auto_reservation_total_approval.reservation_date}の承認が完了しました`);
      })
      .finally(() => {
        setLoading(false);
        onApprove();
      });
  }, [jalReservationTotal, setLoading]);

  return (
    <TableRow>
      <TableCell>{jalReservationTotal.reservationDate.format('YYYY-MM-DD')}</TableCell>
      <TableCell>{jalReservationTotal.aiOperatorCount?.toLocaleString() || 0}</TableCell>
      <TableCell>{jalReservationTotal.reservationCount?.toLocaleString() || 0}</TableCell>
      <TableCell>{jalReservationTotal.totalPrice?.toLocaleString() || 0}</TableCell>
      <TableCell>{jalReservationTotal.approvedAt?.format('YYYY-MM-DD') || '─'}</TableCell>
      <TableCell>{jalReservationTotal.approvedBy || '─'}</TableCell>
      <TableCell>
        {jalReservationTotal.approvedBy ? (
          <Label>確認済み</Label>
        ) : (
          <Button onClick={handleApprove} loading={loading}>
            確認
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
});

const Label = styled.div`
  padding: 8px 0;
`;

export default AirAutoJalReservationTotalTableRow;
