import React from 'react';
import _ from 'lodash';

import SelectDefaultAreaTemplate from './default_area.template';

interface ManualJson {
  name: string;
  file_url: string;
}

interface FaqJson {
  name: string;
  url: string;
}

interface OrganizationInformation {
  content: string;
  created_at: string;
  updated_at: string;
}

interface ManualResponse {
  manuals: ManualJson[];
}

export type Tab = 'guide' | 'faq' | 'information';

export interface SelectDefaultAreaProps {
  show: boolean;
  isOrganization: boolean;
  serviceId: number;
  sp: boolean;
}

export interface SelectDefaultAreaState {
  currentTab: Tab;
  informations: OrganizationInformation[];
  manuals: ManualJson[];
  faqs: FaqJson[];
}

class SelectDefaultArea extends React.Component<SelectDefaultAreaProps, SelectDefaultAreaState> {
  constructor(props: SelectDefaultAreaProps) {
    super(props);

    this.state = {
      currentTab: props.sp ? 'information' : props.isOrganization ? 'guide' : 'faq',
      informations: [],
      manuals: [],
      faqs: []
    };
  }

  async componentDidMount() {
    this.setState({
      faqs:
        this.props.serviceId !== 3
          ? [
              { url: 'https://aitravel.jp/help', name: 'サービス全般' },
              { url: 'https://aitravel.jp/knowledge_categories/1#1', name: '予約について' },
              { url: 'https://aitravel.jp/knowledge_categories/1#5', name: '新幹線・航空券手配について' },
              { url: 'https://aitravel.jp/knowledge_categories/1#4', name: '宿泊手配について' },
              {
                url: 'https://aitravel.jp/knowledge_categories/1#8',
                name: '手配の変更、キャンセルについて'
              }
            ]
          : [
              { url: 'https://btm.mynavi.jp/service/faq/#general', name: 'サービス全般について' },
              { url: 'https://btm.mynavi.jp/service/faq/#hotel', name: '国内ホテル手配について' },
              { url: 'https://btm.mynavi.jp/service/faq/#jr', name: '新幹線（JR券）手配について' },
              { url: 'https://btm.mynavi.jp/service/faq/#air', name: '飛行機手配について' },
              { url: 'https://btm.mynavi.jp/service/faq/#rentacar', name: 'レンタカー手配について' },
              { url: 'https://btm.mynavi.jp/service/faq/#cancel', name: 'キャンセル・変更について' }
            ]
    });

    try {
      const [{ manuals }, informations] = await Promise.all([
        utils.jsonPromise<ManualResponse>('/manuals.json'),
        utils.jsonPromise<OrganizationInformation[]>('/organization_informations.json')
      ]);
      this.setState({
        manuals,
        informations,
        currentTab: informations.length > 0 ? 'information' : this.state.currentTab
      });
    } catch (e) {
      utils.sendErrorObject(e);
    }
  }

  handleTabClick = (value: Tab) => () => {
    this.setState({
      currentTab: value
    });
  };

  render() {
    try {
      return <SelectDefaultAreaTemplate {...this.props} {...this.state} handleTabClick={this.handleTabClick} />;
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectDefaultArea;
