import React from 'react';
import NationalityList from './nationalities_list';
import { styled } from '../../constants/themes';

interface Props {
  selectedId: number | null;
  nationalities: { id: number; name: string }[];
  onSelect?: (id: number) => void;
}

interface State {
  showNationalityList: boolean;
  selectedNationalities: { id: number; name: string }[];
}

class SelectNationalities extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showNationalityList: false,
      selectedNationalities: []
    };

    this.toggleNationalityList = this.toggleNationalityList.bind(this);
    this.handleNationalitySelect = this.handleNationalitySelect.bind(this);
  }

  handleNationalitySelect(id: number) {
    this.setState({
      showNationalityList: false
    });
    if (this.props.onSelect) {
      this.props.onSelect(id);
    }
  }

  toggleNationalityList(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      showNationalityList: !this.state.showNationalityList
    });
  }

  render() {
    const rawNationalities: { id: number; name: string }[] = this.props.nationalities;
    const selectedNatioNality: { id: number; name: string } | undefined = rawNationalities.find(
      nationality => nationality.id === this.props.selectedId
    );
    try {
      return (
        <SelectNationalityWrapper>
          <SelectedListItem onClick={this.toggleNationalityList}>
            {selectedNatioNality ? (
              <ListItemInfo>
                <ItemPart>{selectedNatioNality.name}</ItemPart>
              </ListItemInfo>
            ) : (
              <ItemMessage>-</ItemMessage>
            )}
            <ListRight>▼</ListRight>
          </SelectedListItem>
          {this.state.showNationalityList && (
            <div>
              <NationalityList
                {...this.props}
                nationalities={this.props.nationalities}
                selectedNationalities={this.state.selectedNationalities}
                onSelect={this.handleNationalitySelect}
              />
            </div>
          )}
        </SelectNationalityWrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const SelectNationalityWrapper = styled.div`
  font-size: 13px;
  min-width: 263px;
  background-color: #ffffff;
`;

const SelectedListItem = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid #333;
`;

const ListItemInfo = styled.div`
  display: inline-block;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  flex-wrap: wrap;
  width: 250px;
`;

const ItemPart = styled.p`
  margin-right: 5px;
  display: inline-block;
`;

const ItemMessage = styled.p`
  color: #292929;
  width: 250px;
  display: inline-block;
`;

const ListRight = styled.div`
  margin: auto;
  display: inline-block;
  color: ${props => props.theme.linkColor};
`;

export default SelectNationalities;
