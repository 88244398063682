import React from 'react';
import type { AvailableRepository } from '@this/domain/available_repository';
import ContentHeader from '@this/src/components/shared/content_header/content_header';
import { reportError } from '@this/lib/bugsnag';
import SearchQuery from '../../../../domain/search_query';
import type Trip from '../../../../domain/trip/trip';

import Search from '../../../shared/search/search';

type Props = {
  trip: Trip;
  availableRepos: AvailableRepository[];
  onClose: () => void;
};

type State = {
  query: SearchQuery;
};

class DraftSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const t = this.props.trip;
    this.state = {
      query: new SearchQuery({
        peoplenum: Math.max(t.traveler_informations.length, 1),
        roomnum: Math.max(t.traveler_informations.length, 1),
        draft_id: t.id,
        availableRepos: this.props.availableRepos
      })
    };
  }

  handleSearch = (query: SearchQuery) => {
    location.href = `/select${query.getQueryString()}`;
  };

  render() {
    try {
      return (
        <>
          <ContentHeader title="旅程登録" backButton onBack={this.props.onClose} />
          <Search query={this.state.query} handleSearch={this.handleSearch} />
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

export default DraftSearch;
