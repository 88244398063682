import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/src/components/constants/themes';
import type HotelOrderItemRakutenTodo from '@this/src/domain/arrangement/hotel_order_item_rakuten_todo';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';
import HotelOrderItemRakutenTodoTableRow from './hotel_order_item_rakuten_todo_table_row';
import ServiceSupplierSettingModal from './service_supplier_setting_modal';

interface Props {
  todos: HotelOrderItemRakutenTodo[];
  totalCount: number;
  totalDiffCount: number;
  totalNotDiffCount: number;
  totalPage: number;
  page: number;
  showDescription: boolean;
  isCountLoading: boolean;
  isDiff: boolean;
  isNotDiff: boolean;
  fetchTodos: () => void;
  setPage: (page: number) => void;
}

const HotelOrderItemTodoListRakuten: React.FC<Props> = ({
  todos,
  totalCount,
  totalDiffCount,
  totalNotDiffCount,
  totalPage,
  page,
  showDescription,
  isCountLoading,
  isDiff,
  isNotDiff,
  fetchTodos,
  setPage
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedOrderItemIds, setSelectedOrderItemIds] = useState<number[]>([]);
  const [showServiceSupplierSettingModal, setShowServiceSupplierSettingModal] = useState<boolean>(false);

  const filteredTotalCount = useMemo(() => {
    if (isDiff && isNotDiff) return totalCount;
    if (isDiff) return totalDiffCount;
    if (isNotDiff) return totalNotDiffCount;
    return 0;
  }, [isDiff, isNotDiff, totalCount, totalDiffCount, totalNotDiffCount]);

  const filteredTodos = useMemo(
    () =>
      todos.filter(todo => {
        if (isDiff && todo.isDiff) return true;
        if (isNotDiff && !todo.isDiff) return true;

        return false;
      }),
    [todos, isDiff, isNotDiff]
  );

  const bulkTargetTodos = useMemo(
    () => filteredTodos.filter(todo => todo.orderItemId && todo.inventoriedBy === null),
    [filteredTodos]
  );

  const bulkInventoryDisabled = useMemo(() => isDiff || !isNotDiff, [isDiff, isNotDiff]);

  const bulkInventoryEmptyTargets = useMemo(
    () => filteredTodos.filter(todo => todo.orderItemId).length === 0,
    [filteredTodos]
  );

  const handleToggleAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedOrderItemIds(bulkTargetTodos.map(todo => todo.orderItemId));
      } else {
        setSelectedOrderItemIds([]);
      }
    },
    [bulkTargetTodos]
  );

  const handleToggle = useCallback(
    (checked: boolean, orderItemId: number) => {
      if (checked) {
        setSelectedOrderItemIds([...selectedOrderItemIds, orderItemId]);
      } else {
        setSelectedOrderItemIds(_.without(selectedOrderItemIds, orderItemId));
      }
    },
    [selectedOrderItemIds]
  );

  const handleBulkInventory = useCallback(
    async (orderItemIds: number[]) => {
      if (loading || bulkInventoryDisabled || bulkInventoryEmptyTargets) return;

      try {
        setLoading(true);
        const params = { order_item_ids: orderItemIds };
        await Fetcher.put('/arrangement/hotel_order_item_todos/bulk_inventory', params);
        fetchTodos();
        Notification.success('一括棚卸を実行しました');
      } catch {
        Notification.error('一括棚卸に失敗しました');
      } finally {
        setLoading(false);
      }
    },
    [loading, bulkInventoryDisabled, bulkInventoryEmptyTargets, fetchTodos, setLoading]
  );

  return (
    <>
      <Flex>
        <div>検索結果:{isCountLoading ? '...' : filteredTotalCount}件</div>
        <Button
          onClick={() => handleBulkInventory(selectedOrderItemIds)}
          disabled={bulkInventoryDisabled || bulkInventoryEmptyTargets}
          loading={loading}
        >
          一括棚卸
        </Button>
        {bulkInventoryDisabled ? (
          <Warn>一括棚卸は差異なしの検索時のみ利用できます</Warn>
        ) : bulkInventoryEmptyTargets ? (
          <Warn>一括棚卸の対象がありません</Warn>
        ) : null}
        <Button style={{ marginLeft: 'auto' }} onClick={() => setShowServiceSupplierSettingModal(true)}>
          ID/パスワード再設定
        </Button>
        {showServiceSupplierSettingModal && (
          <ServiceSupplierSettingModal onClose={() => setShowServiceSupplierSettingModal(false)} />
        )}
      </Flex>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={selectedOrderItemIds.length === bulkTargetTodos.length}
                disabled={bulkInventoryDisabled || bulkInventoryEmptyTargets}
                onChange={e => handleToggleAll(e.target.checked)}
              />
            </TableCell>
            <TableCell />
            <TableCell>予約番号</TableCell>
            <TableCell>宿泊人数</TableCell>
            <TableCell>施設名</TableCell>
            <TableCell>施設住所</TableCell>
            <TableCell>部屋タイプ</TableCell>
            <TableCell>プラン名</TableCell>
            <TableCell>朝食有無</TableCell>
            <TableCell>チェックイン日</TableCell>
            <TableCell>チェックアウト日</TableCell>
            <TableCell>宿泊代表者名</TableCell>
            <TableCell>料金</TableCell>
            <TableCell>卸値の仕入商品</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>棚卸ステータス</TableCell>
            <TableCell>棚卸実施者</TableCell>
            <TableCell>棚卸日時</TableCell>
            <TableCell>Trip ID</TableCell>
            <TableCell>Trace ID</TableCell>
            <TableCell>最新のログメッセージ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTodos.map((todo, i) => (
            <HotelOrderItemRakutenTodoTableRow
              key={todo.traceId || todo.fromRakutenReservation.reservationNumber || i}
              todo={todo}
              checked={selectedOrderItemIds.includes(todo.orderItemId)}
              showDescription={showDescription}
              onInventory={() => fetchTodos()}
              onToggle={(checked: boolean) => handleToggle(checked, todo.orderItemId)}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={totalPage}
        page={page}
        onChange={(_e: unknown, newPage: number) => setPage(newPage)}
      />
    </>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Warn = styled.div`
  color: gray;
`;

export default HotelOrderItemTodoListRakuten;
