import React, { useCallback, useState } from 'react';
import { styled } from '@this/constants/themes';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import type Trip from '@this/domain/trip/trip';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import { escapeHTML } from '@this/src/util';

interface Props {
  selectedTrip: Trip;
  message: Message;
  handleMessageEdit?: (messageId: number, e: React.MouseEvent<HTMLAnchorElement>) => void;
  fetchMessages: (trip: Trip) => void;
}
const Body: React.FC<Props> = ({ selectedTrip, message, fetchMessages, handleMessageEdit = () => {} }) => {
  const [removeMessageConfirmVisible, setRemoveMessageConfirmVisible] = useState(false);
  const [messageToRemove, setMessageToRemove] = useState<Message | null>(null);

  const showRemoveMessageModal = useCallback(() => {
    setRemoveMessageConfirmVisible(true);
    setMessageToRemove(message);
  }, [message]);

  const hideRemoveMessageModal = useCallback(() => {
    setRemoveMessageConfirmVisible(false);
    setMessageToRemove(null);
  }, []);

  const handleRemoveMessage = useCallback(() => {
    if (!messageToRemove) return;
    const params = { type: 'partner' };
    utils
      .jsonPromise(`/trips/${messageToRemove.trip_id}/messages/${messageToRemove.id}`, params, 'DELETE')
      .then(() => {
        fetchMessages(selectedTrip);
      })
      .catch(e => utils.sendErrorObject(e))
      .finally(() => {
        setRemoveMessageConfirmVisible(false);
        setMessageToRemove(null);
      });
  }, [fetchMessages, messageToRemove, selectedTrip]);

  const messageBody = useCallback(() => {
    const escaped = escapeHTML(message.body);
    const result = escaped.replace(
      /\[(.+)\]\((.+)\)/gm,
      '<a href="$2" class="message__yahoo-link" target="_blank">$1</a>'
    );
    return result.replace(/\*\*(.+)\*\*/, '<span class="bold">$1</span>');
  }, [message]);

  return (
    <>
      <MessageBodyEdit>
        {message.type === 'partner' && (
          <>
            <A data-testid="message-edit-link" onClick={e => handleMessageEdit(message.id, e)}>
              編集
            </A>
            <A data-testid="message-delete-link" onClick={showRemoveMessageModal}>
              削除
            </A>
          </>
        )}
        <Modal hideModal={hideRemoveMessageModal} show={removeMessageConfirmVisible} title="メッセージの削除">
          {messageToRemove && messageToRemove.body.split('\n').map((line, i) => <p key={i}>{line}</p>)}
          <Confirm
            onConfirmed={handleRemoveMessage}
            onAborted={hideRemoveMessageModal}
            message="本当に削除しますか？"
          />
        </Modal>
      </MessageBodyEdit>
      <MessageBodyText data-wovn-ignore dangerouslySetInnerHTML={{ __html: messageBody() }} />
      {selectedTrip &&
        message.attach_files &&
        message.attach_files.map((img, i) => (
          <div key={i}>
            <a
              id={`message_#{message.id}_file_img_${i}`}
              href={`/trips/${selectedTrip.id}/images/${img.file_path}?type=${message.type}`}
              target="_blank"
              rel="noreferrer"
            >
              {img.file_name}を開く
            </a>
            <br />
          </div>
        ))}
      {message.accepted && <AcceptedMessage>承認済み</AcceptedMessage>}
    </>
  );
};

const MessageBodyEdit = styled.div`
  float: right;
`;

const A = styled.a`
  padding-left: 3px;
`;
const MessageBodyText = styled.pre`
  word-break: break-all;
  white-space: pre-wrap;
  margin: 0;
  background: transparent;
  border: none;
  padding: 0;
`;

const AcceptedMessage = styled.p`
  color: green;
`;

export default Body;
