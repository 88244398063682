import ChatbotMessage, { convertChatbotMessageResponseToArgs } from '@this/src/domain/chatbot/chatbot_message';
import type {
  ChatbotMessageFormStatus,
  ChatbotMessageResponseArgs
} from '@this/src/domain/chatbot/chatbot_message';
import TextToQueryResultItem, {
  convertTextToQueryResultItemResponseToArgs
} from '@this/src/domain/chatbot/text_to_query_result_item';
import type { TextToQueryResultItemResponseArgs } from '@this/src/domain/chatbot/text_to_query_result_item';

import objectToQueryString from '../share/object_to_query_string';

interface Props {
  message: ChatbotMessage;
  status: ChatbotMessageFormStatus;
  onStarted?: () => void;
  onComplete?: (state: CompleteState) => void;
  onFinally?: () => void;
}

interface CompleteState {
  url?: string;
  status: ChatbotMessageFormStatus;
  chatbotMessages: ChatbotMessage[];
}

interface QueryCompletionResponse {
  status: ChatbotMessageFormStatus;
  chatbot_session_messages: ChatbotMessageResponseArgs[];
  search_query: TextToQueryResultItemResponseArgs[];
}

const queryCompletionApi = ({ message, status, onStarted, onComplete, onFinally }: Props) => {
  onStarted?.();
  const params = { status, ...message.updateParams() };
  utils
    .jsonPromise<QueryCompletionResponse>(`/chatbot/queries/${message.id}`, params, 'PUT')
    .then(res => {
      const chatbotMessages = res.chatbot_session_messages.map(
        raw => new ChatbotMessage(convertChatbotMessageResponseToArgs(raw))
      );

      if (res.status !== 'completed') {
        onComplete?.({ status: res.status, chatbotMessages });
        return;
      }

      // /select内で遷移した場合検索が発火しないため、rebuildを付与してSelect.tsxのcomponentDidUpdateで再検索させている
      // Select.tsx: ./js/components/reserve_trip/select/Select.tsx
      const items = res.search_query.map(
        raw => new TextToQueryResultItem(convertTextToQueryResultItemResponseToArgs(raw))
      );
      const params = {
        peoplenum: '1',
        roomnum: '1',
        search_type: 'roundTrip',
        type: 'separate',
        items: items.map((item, index) => item.toSelectQuery(index)),
        ...(location.pathname === '/select' && { rebuild: 'true' })
      };

      onComplete?.({ url: `/select?${objectToQueryString(params)}`, status: res.status, chatbotMessages });
    })
    .finally(() => {
      onFinally?.();
    });
};

export default queryCompletionApi;
