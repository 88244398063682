import _ from 'lodash';

import type TravelerList from '@this/domain/traveler/traveler_list';
import type Traveler from '@this/domain/traveler/traveler';
import type User from '@this/domain/user/user';
import type Department from '@this/domain/department/department';
import type ProjectList from '@this/domain/project/project_list';

import type { FilterableSelectorOption } from '@this/components/shared/filterable_selector/filterable_selector';

import type { ReserveRequestMailNotificationType } from '@this/domain/organization/organization2';
import ArrangementRequestFlightInfo from './arrangement_request_flight_info';
import ArrangementRequestShinkansenInfo from './arrangement_request_shinkansen_info';
import ArrangementRequestHotelInfo from './arrangement_request_hotel_info';
import ArrangementRequestRentalCarInfo from './arrangement_request_rental_car_info';
import ArrangementRequestOtherItemInfo from './arrangement_request_other_item_info';
import type { AvailableRequestForm } from '../available_request_form';

interface Args {
  hotelExist: boolean;
  shinkansenExist: boolean;
  foreignExist: boolean;
  domesticAirExist: boolean;
  useBulkTicket: boolean;
  showEx: boolean;
  travelers: TravelerList;
  user: User;
  departments: Department[];
  projects: ProjectList;
  notifiedUsers?: Traveler[];
  carType: [string, string][];
  itemType: [string, string][];
  availableRequestForms: AvailableRequestForm[];
}

class ArrangementRequestInfo {
  flightInfo: ArrangementRequestFlightInfo;

  shinkansenInfo: ArrangementRequestShinkansenInfo;

  hotelInfo: ArrangementRequestHotelInfo;

  rentalCarInfo: ArrangementRequestRentalCarInfo;

  otherItemInfo: ArrangementRequestOtherItemInfo;

  readonly hotelExist: boolean;

  readonly shinkansenExist: boolean;

  readonly foreignExist: boolean;

  readonly domesticAirExist: boolean;

  readonly useBulkTicket: boolean;

  readonly showEx: boolean;

  readonly user: User;

  readonly projects: ProjectList;

  readonly travelers: TravelerList;

  readonly availableRequestForms: AvailableRequestForm[];

  needHotel: boolean;

  needDomesticFlight: boolean;

  needForeignFlight: boolean;

  needShinkansen: boolean;

  needRentalcar: boolean;

  needOther: boolean;

  internalNumber: string;

  projectId: string;

  notifiedUsers: (Traveler | undefined)[];

  constructor(args: Args) {
    this.availableRequestForms = args.availableRequestForms;
    this.flightInfo = new ArrangementRequestFlightInfo({
      travelers: args.travelers,
      user: args.user,
      foreignExist: args.foreignExist
    });
    this.shinkansenInfo = new ArrangementRequestShinkansenInfo({
      travelers: args.travelers,
      user: args.user,
      departments: args.departments
    });
    this.hotelInfo = new ArrangementRequestHotelInfo();
    this.rentalCarInfo = new ArrangementRequestRentalCarInfo({
      carType: args.carType || [],
      rentalCarAvailable: this.isAvailable('rentalcar')
    });
    this.otherItemInfo = new ArrangementRequestOtherItemInfo({
      itemType: args.itemType || [],
      otherItemAvailable: this.isAvailable('other')
    });
    this.hotelExist = args.hotelExist;
    this.shinkansenExist = args.shinkansenExist;
    this.foreignExist = args.foreignExist;
    this.domesticAirExist = args.domesticAirExist;
    this.useBulkTicket = args.useBulkTicket;
    this.showEx = args.showEx;
    this.user = args.user;
    this.travelers = args.travelers;
    this.projects = args.projects;
    this.needHotel = false;
    this.needDomesticFlight = false;
    this.needForeignFlight = false;
    this.needShinkansen = false;
    this.needRentalcar = false;
    this.needOther = false;
    this.internalNumber = '';
    this.projectId = '';
    this.notifiedUsers = args.notifiedUsers ?? [];
  }

  toggleNeedHotel = () => {
    this.needHotel = !this.needHotel;
    if (this.needHotel) {
      const first_traveler = this.travelers.list[0];
      this.hotelInfo.hotelFirstName = first_traveler.firstNameRoman || '';
      this.hotelInfo.hotelLastName = first_traveler.lastNameRoman || '';
      this.hotelInfo.hotelFirstNameKana = first_traveler.firstNameKana || '';
      this.hotelInfo.hotelLastNameKana = first_traveler.lastNameKana || '';
      this.hotelInfo.hotelTel = first_traveler.tel || '';
    } else {
      this.hotelInfo.hotelFirstName = '';
      this.hotelInfo.hotelLastName = '';
      this.hotelInfo.hotelFirstNameKana = '';
      this.hotelInfo.hotelLastNameKana = '';
      this.hotelInfo.hotelTel = '';
    }
    app.render();
  };

  toggleNeedDomesticFlight = () => {
    this.needDomesticFlight = !this.needDomesticFlight;
    app.render();
  };

  toggleNeedForeignFlight = () => {
    this.needForeignFlight = !this.needForeignFlight;
    app.render();
  };

  toggleNeedShinkansen = () => {
    this.needShinkansen = !this.needShinkansen;
    app.render();
  };

  toggleNeedRentalcar = () => {
    this.needRentalcar = !this.needRentalcar;
    if (this.needRentalcar) {
      const first_traveler = this.travelers.list[0];
      this.rentalCarInfo.rentalCars[0].firstNameKana = first_traveler.firstNameKana || '';
      this.rentalCarInfo.rentalCars[0].lastNameKana = first_traveler.lastNameKana || '';
      this.rentalCarInfo.rentalCars[0].driverTel = first_traveler.tel || '';
    } else {
      this.rentalCarInfo.rentalCars = [];
      this.rentalCarInfo.addRentalCar();
    }
    app.render();
  };

  toggleNeedOther = () => {
    this.needOther = !this.needOther;
    if (!this.needOther) {
      this.otherItemInfo.otherItems = [];
      this.otherItemInfo.addOtherItem();
    }
    app.render();
  };

  setInternalNumber = (value: string) => {
    this.internalNumber = value;
    app.render();
  };

  setNotifiedUsers(users: (Traveler | undefined)[]) {
    this.notifiedUsers = users;
    app.render();
  }

  getProjectOption(): FilterableSelectorOption | null {
    const p = this.getProject();
    if (!p) return null;
    return { label: p.codeAndName(), value: p.id.toString(), matcher: p.codeAndName() };
  }

  isAvailable(...rfs: AvailableRequestForm[]): boolean {
    return this.availableRequestForms.find(rf => rfs.includes(rf)) !== undefined;
  }

  handleProjectSelect = (option: FilterableSelectorOption | null) => {
    this.projectId = option ? option.value : '';
    app.render();
  };

  validate(reserveRequestMailNotificationType: ReserveRequestMailNotificationType): string[] {
    const errors: string[] = [];
    if (
      !this.needHotel &&
      !this.needDomesticFlight &&
      !this.needForeignFlight &&
      !this.needShinkansen &&
      !this.needRentalcar &&
      !this.needOther &&
      reserveRequestMailNotificationType !== 'selectable_user'
    ) {
      errors.push('手配依頼のチェックボックスを1つ以上選択してください');
    }
    const setting = this.user.organization && this.user.organization.setting;
    if (setting && setting.is_internal_number_required && this.internalNumber.length === 0)
      errors.push('社内管理番号を入力してください');
    if (setting && setting.is_project_name_required && this.projectId.length === 0)
      errors.push('プロジェクト名を入力してください');
    if (this.needDomesticFlight) Array.prototype.push.apply(errors, this.flightInfo.validate(false));
    if (this.needForeignFlight) Array.prototype.push.apply(errors, this.flightInfo.validate(true));
    if (this.needHotel) Array.prototype.push.apply(errors, this.hotelInfo.validate());
    if (this.needShinkansen) Array.prototype.push.apply(errors, this.shinkansenInfo.validate());
    if (this.needRentalcar) Array.prototype.push.apply(errors, this.rentalCarInfo.validate());
    if (this.needOther) Array.prototype.push.apply(errors, this.otherItemInfo.validate());

    if (reserveRequestMailNotificationType === 'selectable_user' && _.compact(this.notifiedUsers).length === 0) {
      errors.push('出張内容を確認するユーザを1名以上設定してください。');
    }
    return errors;
  }

  submitParams() {
    const params = {
      need_hotel: this.needHotel,
      need_shinkansen: this.needShinkansen,
      need_domestic_flight: this.needDomesticFlight,
      need_foreign_flight: this.needForeignFlight,
      need_rentalcar: this.needRentalcar,
      need_other: this.needOther,
      internal_number: this.internalNumber,
      project_ids: [this.projectId],
      notified_user_ids: this.notifiedUsers.map(user => utils.dig(user, 'id'))
    };
    _.merge(params, this.shinkansenInfo.submitParams());
    _.merge(params, this.hotelInfo.submitParams());
    _.merge(params, this.rentalCarInfo.submitParams());
    _.merge(params, this.otherItemInfo.submitParams());
    return params;
  }

  private getProject() {
    return this.projects.find(this.projectId);
  }
}

export default ArrangementRequestInfo;
