import React from 'react';
import { observer } from 'mobx-react';

import type SearchTracking from '@this/src/domain/search_tracking';

import { Button } from '@this/src/components/shared/ui/inputs/button';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';

interface Props {
  searchTracking: SearchTracking;
}

const SearchTrackingsTableRow = observer(({ searchTracking }: Props) => {
  return (
    <TableRow>
      {/* トラッキングID */}
      <TableCell nowrap>{searchTracking.id}</TableCell>

      {/* ユーザー名 */}
      <TableCell>{searchTracking.userName}</TableCell>

      {/* Email */}
      <TableCell>{searchTracking.userEmail}</TableCell>

      {/* 企業名 */}
      <TableCell>{searchTracking.organizationName}</TableCell>

      {/* SearchQuery ID */}
      <TableCell nowrap>{searchTracking.searchQueryId}</TableCell>

      {/* Trip ID */}
      <TableCell nowrap>{searchTracking.tripId}</TableCell>

      {/* 追跡開始時刻 */}
      <TableCell>{searchTracking.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>

      {/* 追跡終了時刻 */}
      <TableCell>{searchTracking.lastLogCreatedAt?.format('YYYY-MM-DD HH:mm')}</TableCell>

      {/* 詳細 */}
      <TableCell>
        <Button size="small" href={`/arrangement/search_trackings/${searchTracking.id}`}>
          詳細
        </Button>
      </TableCell>
    </TableRow>
  );
});

export default SearchTrackingsTableRow;
