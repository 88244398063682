import React from 'react';
import _ from 'lodash';

import {
  OutLabelWrap,
  OutLabel,
  FieldsWrapper,
  ToggleField,
  InputArea,
  SelectWrap,
  Select
} from '../../search/search';
import { Block, Header, Body } from '../search_block/search_block.template';

import type SearchQuery from '../../../../domain/search_query';

type Props = {
  query: SearchQuery;
  handleChange: (name: 'peoplenum') => (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SearchPeoplenumBlockTemplate: React.SFC<Props> = ({ query, handleChange }) => (
  <>
    {!location.pathname.match(/trips\/\d+\/edit/) && (
      <Block className="search__block">
        <Header>
          <OutLabelWrap>
            <OutLabel>出張人数</OutLabel>
          </OutLabelWrap>
        </Header>
        <Body>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InputArea wrapped>
                <SelectWrap>
                  <Select
                    name="peoplenum"
                    id="search__input-peoplenum"
                    value={query.peoplenum}
                    onChange={handleChange('peoplenum')}
                  >
                    {_.times(8, i => (
                      <option value={i + 1} key={i}>{`${i + 1}人`}</option>
                    ))}
                  </Select>
                </SelectWrap>
              </InputArea>
            </ToggleField>
          </FieldsWrapper>
        </Body>
      </Block>
    )}
  </>
);

export default SearchPeoplenumBlockTemplate;
