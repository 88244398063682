import React, { useMemo } from 'react';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import type { AITTheme } from '../theme';
import { createAITTheme } from '../theme';
import { AITThemeContext } from './theme-context';
import { createMuiTheme } from './mui-theme.util';

type Props = {
  theme?: AITTheme;
  children: React.ReactNode;
};
export const AITThemeProvider = ({ theme: rawTheme, children }: Props) => {
  const theme = rawTheme ?? createAITTheme();
  const contextValue = useMemo(() => ({ theme }), [theme]);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);

  return (
    <AITThemeContext.Provider value={contextValue}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </AITThemeContext.Provider>
  );
};
