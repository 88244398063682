import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import Trip from '@this/domain/trip/trip';

import Notification from '@this/src/notification';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { ReceiptInfo, FlightInfo, FlightCustomer } from './type';
import { ReceiptCreateForm } from './receipt_create_form';
import { Receipt } from './receipt';

type Props = RouteComponentProps;

type ReceiptResponse = {
  name: string;
  trip: any;
  receipt: ReceiptInfo;
  outword_name: string;
  homeword_name: string;
  outword_changeable: boolean;
  homeword_changeable: boolean;
  flightCustomers: FlightCustomer[];
};

export const ReceiptPage = (props: Props) => {
  const [name, setName] = useState<string>('');
  const [trip, setTrip] = useState<Trip | null>(null);
  const [receipt, setReceipt] = useState<ReceiptInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [flightInfo, setFlightInfo] = useState<FlightInfo>({
    outwordName: '',
    homewordName: '',
    outwordChangeable: false,
    homewordChangeable: false,
    flightCustomers: []
  });

  const handleSubmit = useCallback(async (params: { name: string }) => {
    try {
      setLoading(true);
      const result = await Fetcher.post<{ receipt: ReceiptInfo }>(`${window.location.href}.json`, {
        name: params.name
      });
      setReceipt(result.receipt);
    } catch (e) {
      const errors =
        e.response.data && e.response.data.errors
          ? e.response.data.errors
          : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      Notification.error(errors);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await Fetcher.get<ReceiptResponse>(`${window.location.href}.json`);
        setTrip(new Trip({ ...result.trip, showFee: true }));
        setName(result.name);
        setReceipt(result.receipt);
        setFlightInfo({
          outwordName: result.outword_name,
          homewordName: result.homeword_name,
          outwordChangeable: result.outword_changeable,
          homewordChangeable: result.homeword_changeable,
          flightCustomers: result.flightCustomers
        });
      } catch (e) {
        Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Root className="content-body">
      {loading ? (
        <Loading loading centerd />
      ) : receipt && trip ? (
        <Receipt receipt={receipt} trip={trip} flightInfo={flightInfo} />
      ) : (
        <ReceiptCreateForm name={name} onSubmit={handleSubmit} />
      )}
    </Root>
  );
};

const Root = styled.div``;
