import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

interface Props<T extends string> {
  tabs: Record<T, string>;
  currentTab: T;
  onChange: (tab: T) => void;
}

const SelectTab = <T extends string>({ tabs, currentTab, onChange }: Props<T>) => {
  return (
    <div>
      <SelectTabWrap>
        {_.map(tabs, (label, tab) => (
          <Label key={tab}>
            <input type="radio" checked={currentTab === tab} onChange={() => onChange(tab as T)} />
            {label}
          </Label>
        ))}
      </SelectTabWrap>
    </div>
  );
};

const SelectTabWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 20px;
`;

export default SelectTab;
