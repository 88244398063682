import { observable } from 'mobx';
import type { OrderItemStepStatusEnum } from './order_item_step_status';
import type { OrderItemStepFieldArgs } from '../arrangement/order_item_step_todo_fields';
import type { MasterOrderItemActionArgs } from '../master_step/master_order_item_action';

export interface OrderItemStepResponseArgs {
  id?: number;
  master_step_id: number;
  status: OrderItemStepStatusEnum;
  step_num: number;
  step_name?: string;
  step_code?: string;
  step_description?: string;
  step_document_url?: string;
  order_item_step_fields?: OrderItemStepFieldArgs[];
  master_order_item_actions?: MasterOrderItemActionArgs[];
}

export interface OrderItemStepArgs {
  id?: number;
  masterStepId: number;
  status: OrderItemStepStatusEnum;
  stepNum: number;
  stepName?: string;
  stepCode?: string;
  stepDescription?: string;
  stepDocumentUrl?: string;
}

export const convertOrderItemStepResponseToOrderItemStep = (response: OrderItemStepResponseArgs) => {
  const args: OrderItemStepArgs = {
    id: response.id,
    masterStepId: response.master_step_id,
    status: response.status,
    stepNum: response.step_num,
    stepName: response.step_name,
    stepCode: response.step_code,
    stepDescription: response.step_description,
    stepDocumentUrl: response.step_document_url
  };
  return args;
};

export default class OrderItemStep {
  // ステップID
  @observable readonly id?: number;

  // マスターステップID
  @observable readonly masterStepId: number;

  // ステップステータス
  @observable readonly status: OrderItemStepStatusEnum;

  // ステップ
  @observable readonly stepNum: number;

  // ステップ名
  @observable readonly stepName?: string;

  // ステップコード
  @observable readonly stepCode?: string;

  // ステップ説明
  @observable readonly stepDescription?: string;

  // ステップのドキュメントURL
  @observable readonly stepDocumentUrl?: string;

  constructor(args: OrderItemStepArgs) {
    this.id = args.id;
    this.masterStepId = args.masterStepId;
    this.status = args.status;
    this.stepNum = args.stepNum;
    this.stepName = args.stepName;
    this.stepCode = args.stepCode;
    this.stepDescription = args.stepDescription;
    this.stepDocumentUrl = args.stepDocumentUrl;
  }
}
