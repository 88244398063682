import { observable } from 'mobx';

export type DomesticAirLimitTypeJson = {
  id?: number;
  business_days?: number;
  hour?: number;
};

class DomesticAirLimitType {
  @observable
  readonly id?: number | null;

  @observable
  businessDays?: number;

  @observable
  hour?: number;

  constructor(args: DomesticAirLimitTypeJson) {
    this.id = args.id;
    this.hour = typeof args.hour === 'number' ? args.hour : 15;
    this.businessDays = typeof args.business_days === 'number' ? args.business_days : 1;
  }

  description() {
    return `${this.businessDays}営${this.hour}時`;
  }

  submitParams() {
    return {
      id: this.id,
      business_days: this.businessDays,
      hour: this.hour
    };
  }
}

export default DomesticAirLimitType;
