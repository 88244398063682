import type React from 'react';
import { createContext, createRef, useContext } from 'react';
import Notification from '@this/src/notification';
import type InAdvanceApplicationFilter from '@this/src/components/in_advance_applications/in_advance_application_filter/in_advance_application_filter';
import moment from 'moment';
import type { Moment } from 'moment';
import { Fetcher, HTTPError } from '@this/src/util';
import Trip from '../trip/trip';
import HotelElement from '../hotel_element';
import TransportElement from '../transport_element';
import orderItemCategoryStrMap from '../order_item/order_item_category_str_map';
import { InAdvanceApprovalBatch } from './in_advance_approval_batch';
import type { ActionType, InAdvanceApprovalBatchArgs } from './in_advance_approval_batch';
import { InAdvanceMessageTemplate } from './in_advance_message_template';
import type { InAdvanceMessageTemplateArgs } from './in_advance_message_template';

export type SearchStatus = 'applied' | 'approved' | 'rejected';

interface SearchQuery {
  from_date?: string;
  to_date?: string;
  traveler_name: string;
  show_applied: boolean;
  show_approved: boolean;
  show_rejected: boolean;
  order_by: string;
}

interface SearchParams {
  fromDate: string;
  toDate: string;
  travelerName: string;
  showApplied: boolean;
  showApproved: boolean;
  showRejected: boolean;
  orderBy: string;
}

type LoadingFields = 'tripsLoading' | 'batchLoading' | 'batchActionLoading' | 'messageTemplatesLoading';

type ErrorsFields = 'tripsErrors' | 'batchErrors' | 'messageTemplatesErrors';

const loadingErrors: Record<LoadingFields, ErrorsFields> = {
  tripsLoading: 'tripsErrors',
  batchLoading: 'batchErrors',
  batchActionLoading: 'batchErrors',
  messageTemplatesLoading: 'messageTemplatesErrors'
};

interface FetchTripsResponse {
  user: any;
  trips: any[];
  show_fee: boolean;
  auto_stock_check_executed_at: string;
}

interface FetchBatchRequestCallbacks {
  onSuccess?: (status: ActionType) => void;
  onError?: (errors: string[]) => void;
}

interface FetchBatchStatusRequest extends FetchBatchRequestCallbacks {
  enableInterval?: boolean;
  result?: boolean;
  loading?: boolean;
}

type FetchBatchStatusResponse = InAdvanceApprovalBatchArgs;

interface FetchBatchRejectRequest extends FetchBatchRequestCallbacks {
  reject_message: string;
}

interface FetchBatchAcceptRequest extends FetchBatchRequestCallbacks {
  accept_message: string;
}

interface FetchMessageTemplatesResponse {
  message_templates: InAdvanceMessageTemplateArgs[];
}

export class InAdvanceApplicationStore {
  trips: Trip[] = [];

  tripsLoading = false;

  tripsErrors: string[] = [];

  searchFilter: React.MutableRefObject<InAdvanceApplicationFilter | null>;

  searchParams: Partial<SearchParams> = {};

  batch: InAdvanceApprovalBatch;

  batchLoading = false;

  batchActionLoading = false;

  batchErrors: string[] = [];

  allSelected = false;

  intervalId: any = null;

  messageTemplates: InAdvanceMessageTemplate[] | null = null;

  messageTemplatesLoading = false;

  messageTemplatesErrors: string[] = [];

  autoStockCheckExecutedAt: Moment | null = null;

  constructor() {
    this.searchFilter = createRef();
    this.batch = new InAdvanceApprovalBatch();
  }

  searchStatus(): SearchStatus {
    return this.searchParams.showRejected ? 'rejected' : this.searchParams.showApproved ? 'approved' : 'applied';
  }

  setLoading(name: LoadingFields, loading: boolean) {
    this[name] = loading;
    if (loading) this[loadingErrors[name]] = [];
    app.render();
  }

  setBatchSelectedAll() {
    this.allSelected = !this.allSelected;
    this.batch.setSelectedTrips(this.allSelected ? this.trips.map(t => t.id) : []);
    app.render();
  }

  convertSearchParams(query: Partial<SearchQuery>) {
    this.searchParams = {
      fromDate: query.from_date,
      toDate: query.to_date,
      travelerName: query.traveler_name,
      showApplied: query.show_applied,
      showApproved: query.show_approved,
      showRejected: query.show_rejected,
      orderBy: query.order_by
    };
  }

  orderItemCategories(trip: Trip) {
    const categories: string[] = [];

    if (trip.currentOrder) {
      trip.order.orderItems.forEach(item => {
        const label = orderItemCategoryStrMap[item.orderItemCategory];
        if (label && !categories.includes(label)) categories.push(label);
      });
    }

    if (trip.nonOrderItems.length > 0) {
      trip.nonOrderItems.forEach(item => {
        item.elements.forEach(element => {
          if (element instanceof TransportElement) {
            const label = orderItemCategoryStrMap[element.transportType];
            if (label && !categories.includes(label)) categories.push(label);
          } else if (element instanceof HotelElement) {
            const label = orderItemCategoryStrMap.hotel;
            if (label && !categories.includes(label)) categories.push(label);
          }
        });
      });
    }

    return categories;
  }

  // 旅程を検索・取得
  async fetchTrips(props: Pick<FetchBatchStatusRequest, 'enableInterval' | 'loading'> = {}) {
    this.setLoading('tripsLoading', true);
    const query = this.searchFilter.current!.searchQuery() || {};

    await Fetcher.get<FetchTripsResponse>('/application/in_advance.json', query)
      .then(res => {
        this.allSelected = false;
        this.trips = res.trips.map(raw => new Trip({ ...raw, showFee: res.show_fee, currentUser: res.user }));
        this.autoStockCheckExecutedAt = res.auto_stock_check_executed_at
          ? moment(res.auto_stock_check_executed_at)
          : null;
        this.convertSearchParams(query);
        this.fetchBatchStatus({ result: this.searchStatus() !== 'applied', ...props });
      })
      .catch(() => {
        this.tripsErrors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.tripsLoading = false;
        app.render();
      });
  }

  // 一括処理の進行状況を取得
  async fetchBatchStatus({
    enableInterval = false,
    result = false,
    loading = true,
    onSuccess,
    onError
  }: FetchBatchStatusRequest = {}) {
    if (loading) this.setLoading('batchLoading', true);

    await Fetcher.get<FetchBatchStatusResponse>(
      `/application/in_advance_batch_items${result ? '?result=true' : ''}`
    )
      .then(res => {
        this.batch = new InAdvanceApprovalBatch(res);
        if (onSuccess) onSuccess(this.batch.action);

        if (!enableInterval && !result && this.batch.batch === 'processing') {
          this.fetchBatchInterval();
        } else if (!result && this.batch.batch === 'complete') {
          this.searchFilter.current?.handleStatusChange(this.batch.action === 'accept' ? 'approved' : 'rejected');
          this.fetchTrips({ enableInterval: true });
          Notification.success(`${this.batch.action === 'accept' ? '一括承認' : '一括却下'}が完了しました`);
        }
      })
      .catch(error => {
        this.batchErrors =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.batchErrors);
      })
      .finally(() => {
        this.batchLoading = false;
        app.render();
      });
  }

  // 一括承認
  async fetchBatchAccept({ accept_message, onSuccess, onError }: FetchBatchAcceptRequest) {
    this.setLoading('batchActionLoading', true);
    const trips = this.batch.selectedTrips;

    await Fetcher.post<{ success: boolean }>('/application/in_advance_batch_items/accept', {
      trips,
      accept_message
    })
      .then(() => {
        this.fetchBatchStatus({ loading: false });
        if (onSuccess) onSuccess('accept');
      })
      .catch(error => {
        this.batchErrors =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.batchErrors);
      })
      .finally(() => {
        this.batchActionLoading = false;
        app.render();
      });
  }

  // 一括却下
  async fetchBatchReject({ reject_message, onSuccess, onError }: FetchBatchRejectRequest) {
    this.setLoading('batchActionLoading', true);
    const trips = this.batch.selectedTrips;

    await Fetcher.post<{ success: boolean }>('/application/in_advance_batch_items/reject', {
      trips,
      reject_message
    })
      .then(() => {
        this.fetchBatchStatus({ loading: false });
        if (onSuccess) onSuccess('reject');
      })
      .catch(error => {
        this.batchErrors =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.batchErrors);
      })
      .finally(() => {
        this.batchActionLoading = false;
        app.render();
      });
  }

  // 一括処理の中断
  async fetchBatchSuspension({ onSuccess, onError }: FetchBatchRequestCallbacks) {
    this.setLoading('batchActionLoading', true);

    await Fetcher.post<{ success: boolean }>('/application/in_advance_batch_items/suspension', {})
      .then(() => {
        this.fetchBatchStatus({ enableInterval: true, loading: false });
        if (onSuccess) onSuccess('unexecuted');
      })
      .catch(error => {
        this.batchErrors =
          error instanceof HTTPError && error.response?.data?.errors
            ? error.response.data.errors
            : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
        if (onError) onError(this.batchErrors);
      })
      .finally(() => {
        this.batchActionLoading = false;
        app.render();
      });
  }

  // 一括処理実行中の定期処理
  fetchBatchInterval() {
    if (this.intervalId) return;

    this.intervalId = setInterval(() => {
      this.fetchBatchStatus({
        enableInterval: true,
        loading: false,
        onSuccess: () => {
          if (this.batch.batch === 'unexecuted' || this.batch.batch === 'complete') {
            clearInterval(this.intervalId);
            this.intervalId = null;
          }
        }
      });
    }, 3000);
  }

  // 却下理由のテンプレートを取得
  async fetchMessageTemplates() {
    if (this.messageTemplates !== null) return;

    this.setLoading('messageTemplatesLoading', true);

    await Fetcher.get<FetchMessageTemplatesResponse>('/application/in_advance_message_templates')
      .then(res => {
        this.messageTemplates = res.message_templates.map(raw => new InAdvanceMessageTemplate(raw));
      })
      .catch(() => {
        this.messageTemplatesErrors = ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
      })
      .finally(() => {
        this.messageTemplatesLoading = false;
        app.render();
      });
  }
}

export const InAdvanceApplicationContext = createContext<InAdvanceApplicationStore>(
  null as unknown as InAdvanceApplicationStore
);

export const useInAdvanceApplicationStore = () => useContext(InAdvanceApplicationContext);
