import { styled } from '@this/constants/themes';

export const AdminContentIn = styled.div({
  width: '100%',
  padding: 20
});

export const Table = styled.table({
  width: 'auto',
  margin: 0,
  tableLayout: 'auto'
});

export const DashboardTableTh = styled.th({
  padding: '4px 6px',
  minWidth: 70
});

export const DashboardTableTd = styled.td({
  padding: '4px 6px',
  minWidth: 70
});
export const Flex = styled.div({
  display: 'flex'
});
export const Red = styled.div(props => ({
  color: props.theme.redColor
}));
