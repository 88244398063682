import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { ApprovalStatus } from '@this/src/domain/trip_report/approval';
import type { TripReportStatus } from '@this/src/domain/trip_report/trip_report';
import ContentBody, { ContentBodyIn } from '@this/components/shared/atoms/content_body';
import InAdvanceHeader from '../../in_advance_applications/in_advance_header';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import TripReportRow from './trip_report_row';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

const TripReportApprovals: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const location = useLocation<{ tripReport?: { id: number; message: string } }>();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const store = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);

  const fetchTripReports = useCallback(() => {
    store.setStatusEq(searchParams.get('status') || '', () => store.getIndexForApproval());
  }, []);

  const fetchChangeStatus = useCallback(
    (status: TripReportStatus | ApprovalStatus | '') => {
      store.setStatusEq(status, () => {
        store.getIndexForApproval();
        searchParams.set('status', status);
        history.replace({ search: searchParams.toString() });
      });
    },
    [searchParams]
  );

  useEffect(() => {
    fetchTripReports();

    if (location.state?.tripReport) {
      const state = location.state.tripReport;
      store.setMessage(state.id, state.message);
    }
  }, []);

  return (
    <TripReportContext.Provider value={store}>
      <Wrap>
        <BodyWrap>
          <BodyWrapIn>
            <InAdvanceHeader selected="tripReport" tripReportAvailable />
            <Body>
              <ContentBodyIn>
                <PageTitle>出張後の報告一覧</PageTitle>
                <Navigations>
                  <SelectButtons>
                    {store.selectApprovalStatusMenu().map(({ status, label }) => (
                      <SelectButton
                        key={status}
                        className={store.statusEq === status ? 'selected' : ''}
                        onClick={() => fetchChangeStatus(status)}
                      >
                        {label}
                      </SelectButton>
                    ))}
                  </SelectButtons>
                </Navigations>
                {store.loading ? (
                  <LoadingWrap>
                    <SimpleLoading />
                  </LoadingWrap>
                ) : store.errors.length > 0 ? (
                  <Error>{store.errors.join('\n')}</Error>
                ) : (
                  <TableContainer component={Paper}>
                    <StyledTable>
                      <TableHead>
                        <TableRow>
                          <TableCell>ステータス</TableCell>
                          <TableCell>旅程</TableCell>
                          <TableCell>
                            <span>出張者</span>
                            <span>(代表者)</span>
                          </TableCell>
                          <TableCell>出張日程</TableCell>
                          <TableCell>
                            <span>システム</span>
                            <span>手配金額</span>
                          </TableCell>
                          <TableCell>{store.serviceId !== 2 ? '社員立替金額' : '自己手配金額'}</TableCell>
                          {allowanceAvailable && <TableCell>日当</TableCell>}
                          <TableCell>合計金額</TableCell>
                          <TableCell>支払予定日</TableCell>
                          <TableCell>対応</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {store.tripReports.map(tripReport => (
                          <TripReportRow
                            key={tripReport.id}
                            tripReport={tripReport}
                            message={store.messages[tripReport.id]}
                            allowanceAvailable={allowanceAvailable}
                          />
                        ))}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                )}
              </ContentBodyIn>
            </Body>
          </BodyWrapIn>
        </BodyWrap>
      </Wrap>
    </TripReportContext.Provider>
  );
};

const Wrap = styled.div`
  min-height: 100vh;
`;

const BodyWrap = styled.div`
  padding: 20px 0;
  padding-top: 0;
  display: block;
`;

const BodyWrapIn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
`;

const PageTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const Navigations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  ${media.sp`
    flex-flow: column-reverse;
    gap: 24px;
  `}
`;

const SelectButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectButton = styled.div`
  padding: 5px 10px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &.selected {
    color: #ffffff;
    background-color: #374151;
  }
`;

const StyledTable = styled(Table)`
  .MuiTableCell-head span {
    display: inline-block;
  }
`;

export default TripReportApprovals;
