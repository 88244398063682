
moment = require('moment-timezone')
Terminal = require('./transport_element_terminal')
Aviation = require('./aviation').default
TimeZoneLib = require('../../../lib/time_zone.json')
toDomesticTel = require('../util/string').toDomesticTel
TransportElementAttachFile = require('./transport_element_attach_file').default

class TransportElement
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    _.assign(@, args)
    @type = 'transport'
    @from = new Terminal(args.from)
    @to = new Terminal(args.to)
    @peopleNum = args.peopleNum
    @transportType ||= 'domestic_air'
    @airlineCode ||= ''
    @airlineName ||= ''
    @airTicketType ||= 0
    @cabin ||= ''
    @ticketingType ||= 0
    @boardingStation ||= ''
    @arrivalStation ||= ''
    @postingNumber ||= ''
    @exicReceiptNumber ||= ''
    @aviations = _.map args.aviations, (a) -> new Aviation(a)
    @selectTimeZone = TimeZoneLib
    @showFromTimeZoneList = false
    @showToTimeZoneList = false
    @filteredFromTimeZone = []
    @filteredToTimeZone = []
    @airReservationNumber = args.airReservationNumber || ''
    @airConfirmationNumber = args.airConfirmationNumber || ''
    @airClass = args.airClass || 'M'
    @evoTicketType = args.evoTicketType
    @evoSeatType = args.evoSeatType
    @sakuraTicketType = args.sakuraTicketType
    @sakuraSeatType = args.sakuraSeatType
    @ticketingExpiredAt = args.ticketingExpiredAt || ''
    @ticketingAt = if args.ticketingAt then moment(args.ticketingAt) else undefined
    @alliesApplicationNumber = args.alliesApplicationNumber
    @frontierApplicationNumber = args.frontierApplicationNumber
    @skytoursApplicationNumber = args.skytoursApplicationNumber
    @name = args.name || ''


    # convert the reservation into array from object
    if args.transportElementReservations
      keys = Object.keys(args.transportElementReservations)
      transportElementReservations0 = []
      transportElementReservations0.push(args.transportElementReservations[r]) for r in keys
      @transportElementReservations = transportElementReservations0
    else
      @transportElementReservations = []

    # convert the reservation into array from object
    if args.ticketOprReservations
      keys = Object.keys(args.ticketOprReservations)
      ticketOprReservations0 = []
      ticketOprReservations0.push(args.ticketOprReservations[r]) for r in keys
      @ticketOprReservations = ticketOprReservations0
    else
      @ticketOprReservations = []

    @reservationNumber = args.reservationNumber || ''
    @ticketNumber = args.ticketNumber || ''
    # [string, string][]の形で渡してもObjectに変換されてしまうため
    if @carProviderOptions
      carProviderArray = []
      for i, option of @carProviderOptions
        carProviderArray.push [option[0], option[1]]
      @carProviderOptions = carProviderArray
    if @carTypeOptions
      carTypeArray = []
      for i, option of @carTypeOptions
        carTypeArray.push [option[0], option[1]]
      @carTypeOptions = carTypeArray
    if args.transportElementAttachFile
      @transportElementAttachFile = new TransportElementAttachFile(args.transportElementAttachFile)
    @uploadFileData = null

  handleTransportTypeChange: (transportType) ->
    @transportType = transportType
    app.render()

  handleAirTicketTypeChange: (value) ->
    @airTicketType = parseInt(value)
    app.render()

  handleCabinChange: (value) ->
    @cabin = value
    app.render()

  handleTicketingTypeChange: (value) ->
    @ticketingType = parseInt(value)
    app.render()

  handleTicketingTypeChangeWithSuppliedId: (value, orderItem, suppliedItems, individualDomesticAirSuppliedItems) ->
    @ticketingType = parseInt(value)
    name = @name
    ticketingType = @ticketingType
    orderItem.price.originalPrices = _.map orderItem.price.originalPrices, (originalPrice) ->
      originalPrice.individualSuppliedItemId(name, ticketingType, suppliedItems, individualDomesticAirSuppliedItems)
      return originalPrice
    app.render()

  handleRailwaySeatTypeChange: (value) ->
    @railwaySeatType = parseInt(value)
    app.render()

  handleRailwayTicketTypeChange: (value) ->
    @railwayTicketType = parseInt(value)
    app.render()

  handleCarProviderChange: (value) ->
    @carProvider = value
    app.render()

  handleTaxiServiceChange: (value) ->
    @taxiService = value
    app.render()

  handleNameChange: (value) ->
    @name = value
    app.render()

  handleBoardingStationChange: (value, changeFromName = false) ->
    @boardingStation = value
    if changeFromName
      @from.name = value
    app.render()

  handleArrivalStationChange: (value, changeToName = false) ->
    @arrivalStation = value
    if changeToName
      @to.name = value
    app.render()

  handlePeopleNumChange: (e) ->
    e.preventDefault()
    @peopleNum = e.target.value
    app.render()

  handlePostingNumberChange: (value) ->
    @postingNumber = value
    app.render()

  handleExicReceiptNumberChange: (value) ->
    @exicReceiptNumber = value
    app.render()

  toggleFromTimeZoneList: ->
    @showFromTimeZoneList = !@showFromTimeZoneList
    if @showFromTimeZoneList
      @initialFromTimeZoneList()
    else
      @filteredFromTimeZone = []
    app.render()

  toggleToTimeZoneList: ->
    @showToTimeZoneList = !@showToTimeZoneList
    if @showToTimeZoneList
      @initialToTimeZoneList()
    else
      @filteredToTimeZone = []
    app.render()

  handleFromTimeZoneChange: (timezone) ->
    @from.changeTimeZone(timezone)
    @toggleFromTimeZoneList()
    app.render()

  handleToTimeZoneChange: (timezone, toggleTimeZoneList, e) ->
    @to.changeTimeZone(timezone)
    @toggleToTimeZoneList()
    app.render()

  handleAirlineCodeChange: (value) ->
    @airlineCode = value
    app.render()

  handleAirReservationNumberChange: (value) ->
    @airReservationNumber = value
    app.render()

  handleAirConfirmationNumberChange: (value) ->
    @airConfirmationNumber = value
    app.render()

  handleAirClassChange: (value) ->
    @airClass = value
    @app.render()

  handleCarTypeChange: (value) ->
    @carType = value
    app.render()

  handleCarTypeOtherChange: (value) ->
    @carTypeOther = value
    app.render()

  handleLastNameKanaChange: (value) ->
    @lastNameKana = value
    app.render()

  handleFirstNameKanaChange: (value) ->
    @firstNameKana = value
    app.render()

  handleDriverTelChange: (value) ->
    @driverTel = value
    app.render()

  handleRemarksChange: (value) ->
    @remarks = value
    app.render()

  handleAlliesApplicationNumber: (value) ->
    @alliesApplicationNumber = value
    app.render()

  handleFrontierApplicationNumber: (value) ->
    @frontierApplicationNumber = value
    app.render()

  handleSkytoursApplicationNumber: (value) ->
    @skytoursApplicationNumber = value
    app.render()

  handleReservationNumberChange: (value) ->
    @reservationNumber = value
    app.render()

  handleTicketNumberChange: (value) ->
    @ticketNumber = value
    app.render()

  initialFromTimeZoneList: ->
    fromTimezone = @from.timeZone.split('/')[0]
    filtered = TimeZoneLib.filter (t) ->
      t.value.indexOf(fromTimezone) != -1 ||
        t.value.toLowerCase().indexOf(fromTimezone) != -1
    @filteredFromTimeZone = filtered

  initialToTimeZoneList: ->
    toTimezone = @to.timeZone.split('/')[0]
    filtered = TimeZoneLib.filter (t) ->
      t.value.indexOf(toTimezone) != -1 ||
        t.value.toLowerCase().indexOf(toTimezone) != -1
    @filteredToTimeZone = filtered
    app.render()

  handleSearchTimeZone: (e) ->
    keyword = e.target.value
    if _.isEmpty keyword
      if e.target.name == 'fromTimeZone'
        fromTimezone = @from.timeZone.split('/')[0]
        filtered = TimeZoneLib.filter (t) ->
          t.value.indexOf(fromTimezone) != -1 ||
            t.value.toLowerCase().indexOf(fromTimezone) != -1
      else if e.target.name == 'toTimeZone'
        toTimezone = @to.timeZone.split('/')[0]
        filtered = TimeZoneLib.filter (t) ->
          t.value.indexOf(toTimezone) != -1 ||
            t.value.toLowerCase().indexOf(toTimezone) != -1
    else
      filtered = TimeZoneLib.filter (t) ->
        t.value.indexOf(keyword) != -1 ||
          t.value.toLowerCase().indexOf(keyword) != -1

    if e.target.name == 'fromTimeZone'
      @filteredFromTimeZone = filtered
    else
      @filteredToTimeZone = filtered
    app.render()

  handleTicketTypeChange: (value) ->
    if @isEvoNumbers()
      @evoTicketType = value
    else
      @sakuraTicketType = value
    app.render()

  handleSeatTypeChange: (value) ->
    if @isEvoNumbers()
      @evoSeatType = value
    else
      @sakuraSeatType = value

    app.render()

  handleTicketingAtChange: (date) ->
    @ticketingAt = date
    app.render()

  handleUploadFileData: (uploadFileData) ->
    @uploadFileData = uploadFileData
    app.render()

  calcFlightTime: ->
    fromTime = @from.time.clone()
    toTime = @to.time.clone()
    if parseInt(fromTime.format('ZZ')) > parseInt(toTime.format('ZZ'))
      h = String(Math.floor(toTime.diff(fromTime) / 3600000) + 100).substring(1)
      m = String(Math.floor((toTime.diff(fromTime) - h * 3600000)/60000)+ 100).substring(1)
      return "搭乗時間 : #{h}時間#{m}分"
    else if parseInt(fromTime.format('ZZ')) < parseInt(toTime.format('ZZ'))
      h = String(Math.floor(toTime.diff(fromTime) / 3600000) + 100).substring(1)
      m = String(Math.floor((toTime.diff(fromTime) - h * 3600000)/60000)+ 100).substring(1)
      return "搭乗時間 : #{h}時間#{m}分"
    else
      h = String(Math.floor(toTime.diff(fromTime) / 3600000) + 100).substring(1)
      m = String(Math.floor((toTime.diff(fromTime) - h * 3600000)/60000)+ 100).substring(1)
      return "搭乗時間 : #{h}時間#{m}分"

  startDate: ->
    @from.time

  endDate: ->
    @to.time

  # FIXME: typo
  ticketingExpiredAtStr: ->
    moment(@ticketingExpiredAt).format('YYYY月MM月DD日HH時mm分')

  isTicketingExpired: ->
    moment(@ticketingExpiredAt) < moment()

  title: ->
    switch @transportType
      when 'domestic_air' then '国内航空券'
      when 'foreign_air' then '海外航空券'
      when 'shinkansen' then '新幹線'
      when 'express' then '特急'
      when 'railway_ticket' then '在来線'
      when 'foreign_railway' then '海外鉄道'
      when 'rental_car' then 'レンタカー'
      when 'bus' then 'バス'
      when 'ana_tabisaku' then 'ANA旅作'
      when 'taxi' then 'タクシー'
      when 'other' then 'その他'

  railwaySeatTypeStr: ->
    if @isRailway()
      switch @railwaySeatType
        when 0 then '自由席'
        when 1 then '指定席'
        when 2 then 'グリーン車'
        when 3 then '回数券'
        when 4 then 'EX-IC'
        when 5 then 'グランクラス'
        else ''
    else
      ''

  travelerSeatTypeStr: (orderItem) ->
    if !@isRailway()
      return ''

    seatType = @railwaySeatTypeStr()
    if !_.isEmpty(seatType)
      return seatType

    if !orderItem
      return ''

    seatTypes = Array.from(new Set(orderItem.travelerShinkansenSeatTypes()))
    return seatTypes.join('、')

  railwayTicketTypeStr: ->
    if @isRailway()
      switch @railwayTicketType
        when 0 then '通常きっぷ'
        when 1 then 'JR九州きっぷ'
        when 2 then '回数券'
        when 3 then 'EX-IC'
        else ''
    else
      ''

  travelerTicketTypeStr: (orderItem) ->
    if !@isRailway()
      return ''

    ticketType = @railwayTicketTypeStr()
    if !_.isEmpty(ticketType)
      return ticketType

    if !orderItem
      return ''

    ticketTypes = Array.from(new Set(orderItem.travelerShinkansenTicketTypes()))
    return ticketTypes.join('、')

  airTicketTypeStr: ->
    if @isDomesticAir()
      switch @airTicketType
        when 0 then '変更可'
        when 1 then '変更不可/特割'
        else ''
    else
      ''

  airSeatTypeStr: ->
    if @isDomesticAir()
      @airSeatType
    else
      ''

  ticketingTypeStr: ->
    if @isDomesticAir()
      switch @ticketingType
        when 0 then 'AIトラベル'
        when 1 then 'アリーズ'
        when 2 then 'エボラブルアジア'
        when 5 then 'フロンティア'
        when 6 then 'スカイツアーズ'
        else ''
    else
      ''

  carTypeStr: ->
    if @isRentalCar()
      switch @carType
        when 'compact' then 'コンパクト'
        when 'sedan' then 'セダン'
        else @carTypeOther
    else
      ''

  mappingDescription: ->
    name = @carTypeStr() || @name
    "#{@title()}：#{name}　#{@from.timeStr()}発"

  description: ->
    """
    <#{@title()}>
    ■ご利用：#{@peopleNum}名
    ■内容
    #{@summary()}
    """

  summary: ->
    if @transportType == 'foreign_air'
      s = "#{@from.foreignAirDescription()}発 → #{@to.foreignAirDescription()}着 #{@name}"
    else
      s = "#{@from.description()}発 → #{@to.description()}着"
      s += " #{@name.replace(/\n/g, ' ')}" if @name
    if isAiTravel(@serviceId) && !@isEvoNumbers() && @airReservationNumber && @airConfirmationNumber
      s += "\n予約番号:#{@airReservationNumber}"
      s += "\n確認番号:#{@airConfirmationNumber}"
    s += "\n予約番号:#{@evoReservationNumber}" if @evoReservationNumber
    s += "\n確認番号:#{@evoConfirmationNumber}" if @evoConfirmationNumber
    s += "\n問い合わせ番号:#{@evoApplicationNumber}" if @evoApplicationNumber
    s += "\nSKYPDF:#{@evoSkyPdf}" if @evoSkyPdf
    if @carProviderOptions && @carProvider && @isRentalCar()
      for option in @carProviderOptions
        s += "\n提供元:#{option[0]}" if option[1] == @carProvider
    if @carTypeOptions && @carType
      for option in @carTypeOptions
        s += "\n車種・クラス:#{option[0]}" if option[1] == @carType
    s += ":#{@carTypeOther}" if @carTypeOptions && @carType == 'other' && @carTypeOther
    s += "\n運転者氏名（カナ）:#{@lastNameKana} #{@firstNameKana}" if @lastNameKana && @firstNameKana
    s += "\n運転者電話番号:#{toDomesticTel(@driverTel)}" if @driverTel
    s += "\n備考:#{@remarks}" if @remarks
    s

  detail: ->
    lines = this.structuredDetail()
    s = ''
    for line in lines
      s += line.label if line.label
      s += line.value
      s += '\n'
    s

  structuredDetail: (orderItem = null) ->
    lines = []

    unless @isRailway()
      lines.push(
        {
          label: '',
          value: '※表記は現地時刻です。',
          translate: true
        },
      )

    seatType = @travelerSeatTypeStr(orderItem)
    unless _.isEmpty(seatType)
      lines.push(
        {
          label: '',
          value: seatType,
          translate: true
        }
      )

    ticketType = @travelerTicketTypeStr(orderItem)
    unless _.isEmpty(ticketType)
      lines.push(
        {
          label: '',
          value: ticketType,
          translate: true
        }
      )

    airTicketType = @airTicketTypeStr()
    unless _.isEmpty(airTicketType)
      lines.push(
        {
          label: '',
          value: airTicketType,
          translate: true
        }
      )

    airSeatType = @airSeatTypeStr()
    unless _.isEmpty(airSeatType)
      lines.push(
        {
          label: '',
          value: airSeatType,
          translate: true
        }
      )

    if @isRailway()
      lines.push(
        {
          label: '乗車駅:',
          value: @boardingStation,
          translate: false
        }
      )
      lines.push(
        {
          label: '降車駅:',
          value: @arrivalStation,
          translate: false
        }
      )

    lines

  # tabikobo(smk様向け実験用処理)
  tabikoboAviations: ->
    _.sortBy @aviations, (item) -> parseInt(item.salesTotal) / item.numberOfParticipants

  isDomesticAir: ->
    @transportType == 'domestic_air' || @transportType == 'ana_tabisaku'

  isForeignAir: ->
    @transportType == 'foreign_air'

  isShinkansen: ->
    @transportType == 'shinkansen'

  isExpress: ->
    @transportType == 'express'

  isSelectBulkTicket: ->
    @railwayTicketType != 2

  isRailwayTicket: ->
    @transportType == 'railway_ticket'

  isRentalCar: ->
    @transportType == 'rental_car'

  isBus: ->
    @transportType == 'bus'

  isTaxi: ->
    @transportType == 'taxi'

  isForeignRailway: ->
    @transportType == 'foreign_railway'

  isAir: ->
    @isDomesticAir() || @isForeignAir()

  isRailway: ->
    @isShinkansen() || @isExpress() || @isRailwayTicket() || @isForeignRailway()

  isHotel: ->
    false

  isChangeable: ->
    @airTicketType == 0

  isAirNumbersMissing: ->
    _.isEmpty(@airReservationNumber) || _.isEmpty(@airConfirmationNumber)

  # TODO: 実態はstringが返却されるので、booleanを返却するように修正する
  isEvoNumbers: ->
    @evoReservationNumber || @evoConfirmationNumber || @evoApplicationNumber

  isPackage: ->
    _.includes([2, 3], @airTicketType)

  ticketType: ->
    if @isEvoNumbers()
      @evoTicketType
    else
      @sakuraTicketType

  seatType: ->
    if @isEvoNumbers()
      @evoSeatType
    else
      @sakuraSeatType

  marketLogType: ->
    if @transportType == 'foreign_air' then @type else @transportType

  validationErrors: (orderItemStatus) ->
    errors = []
    needStatusGuard = orderItemStatus == 7 || orderItemStatus == 13

    switch @transportType
      when 'domestic_air'
        if @name == '' || @name == undefined then errors.push(@airValidationError)
        if @airReservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
        if @airConfirmationNumber == '' && needStatusGuard then errors.push(@confirmationNumberValidationError)
      when 'foreign_air'
        if @name == '' || @name == undefined then errors.push(@airValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'ana_tabisaku'
        if @name == '' || @name == undefined then errors.push(@airValidationError)
      when 'shinkansen'
        if @boardingStation == '' || @arrivalStation == '' then errors.push(@shinkansenValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'express'
        if @boardingStation == '' || @arrivalStation == '' then errors.push(@expressValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'railway_ticket'
        if @boardingStation == '' || @arrivalStation == '' then errors.push(@railwayticketValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'foreign_railway'
        if @boardingStation == '' || @arrivalStation == '' then errors.push(@foreignRailwayValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'rental_car'
        if @name == '' || @name == undefined then errors.push(@rentalcarValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'taxi'
        if @name == '' || @name == undefined then errors.push(@taxiValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
      when 'bus'
        if @name == '' || @name == undefined then errors.push(@busValidationError)
        if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
    if @to.time < @from.time then errors.push(@timeValidationError)
    errors

  airValidationError:
    '便名を入力してください。\n予約が不要な場合、\n航空券の内容を削除してください。'

  reservationNumberValidationError:
    '予約番号を入力してください。'

  confirmationNumberValidationError:
    '確認番号を入力してください。'

  shinkansenValidationError:
    '出発・到着の場所を入力してください。\n予約が不要な場合、\n新幹線の内容を削除してください。'

  expressValidationError:
    '出発・到着の場所を入力してください。\n予約が不要な場合、\n特急の内容を削除してください。'

  railwayticketValidationError:
    '出発・到着の場所を入力してください。\n予約が不要な場合、\n在来線の内容を削除してください。'

  foreignRailwayValidationError:
    '出発・到着の場所を入力してください。\n予約が不要な場合、\n海外鉄道の内容を削除してください。'

  rentalcarValidationError:
    '便名を入力してください。\n予約が不要な場合、\nレンタカーの内容を削除してください。'

  taxiValidationError:
    '便名を入力してください。\n予約が不要な場合、\nタクシーの内容を削除してください。'

  busValidationError:
    '便名を入力してください。\n予約が不要な場合、\nバスの内容を削除してください。'

  timeValidationError:
    '出発以降を指定してください。'

  updateParams: ->
    id: @id
    type: @type
    transport_type: @transportType
    air_ticket_type: @airTicketType
    ticketing_type: @ticketingType
    name: @name
    people_num: @peopleNum
    railway_seat_type: if @railwayTicketType != 2 then @railwaySeatType else null
    railway_ticket_type: @railwayTicketType
    from: @from.updateParams()
    to: @to.updateParams()
    boarding_station: @boardingStation
    arrival_station: @arrivalStation
    posting_number: @postingNumber
    exic_receipt_number: @exicReceiptNumber
    auto_reservation_status: @autoReservationStatus
    sold_out: @soldOut
    airline_code: @airlineCode
    air_reservation_number: @airReservationNumber
    air_confirmation_number: @airConfirmationNumber
    evo_ticket_type: @evoTicketType
    evo_seat_type: @evoSeatType
    evo_application_number: @evoApplicationNumber
    evo_reservation_number: @evoReservationNumber
    evo_confirmation_number: @evoConfirmationNumber
    evo_sky_pdf: @evoSkyPdf
    sakura_ticket_type: @sakuraTicketType
    sakura_seat_type: @sakuraSeatType
    last_name_kana: @lastNameKana
    first_name_kana: @firstNameKana
    driver_tel: @driverTel
    car_type: @carType
    car_type_other: @carTypeOther
    remarks: @remarks
    car_number: @carNumber
    car_provider: @carProvider
    taxi_service: @taxiService
    ticketing_expired_at: @ticketingExpiredAt
    allies_application_number: @alliesApplicationNumber
    frontier_application_number: @frontierApplicationNumber
    skytours_application_number: @skytoursApplicationNumber
    ticketing_at: if @ticketingAt then @ticketingAt.tz('Asia/Tokyo').format('YYYY-MM-DD') else undefined
    reservation_number: @reservationNumber
    ticket_number: @ticketNumber
    cabin: @cabin
    transport_element_attach_file: if @transportElementAttachFile then @transportElementAttachFile.updateParams() else undefined
    upload_file_data: @uploadFileData

module.exports = TransportElement
