class KitElement
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    _.assign(@, args)
    @type = 'kit'
    @kind ||= 'PCR検査キット'
    @name ||= '新型コロナ抗原検査キット'
    @startedAt = moment(args.startedAt)
    @endedAt = if args.endedAt then moment(args.endedAt) else moment(@startedAt).add(1, 'hour')
    @kitSize ||= 0
    @stockNumber ||= ''
    @lotNumber ||= ''
    @remark ||= ''

  setName: (value) ->
    @name = value
    app.render()

  setKind: (value) ->
    @kind = value
    app.render()

  setKitSize: (value) ->
    @kitSize = value
    app.render()

  setLotNumber: (value) ->
    @lotNumber = value
    app.render()

  setStockNumber: (value) ->
    @stockNumber = value
    app.render()

  setRemark: (value) ->
    @remark = value
    app.render()

  updateParams: ->
    type: @type
    name: @name
    kind: @kind
    started_at: @startedAt.format('YYYY-MM-DD HH:mm:ss')
    ended_at: @endedAt.format('YYYY-MM-DD HH:mm:ss')
    kit_size: @kitSize
    stock_number: @stockNumber
    lot_number: @lotNumber
    remark: @remark

  startDate: ->
    @startedAt

  endDate: ->
    @endedAt

  description: ->
    """
    <新型コロナ検査キット>
    ■数量：　#{@kitSize} 個
    ■備考
    #{@remark}
    """

  title: ->
    '新型コロナ検査キット'

  summary: ->
    '[新型コロナ検査キット]'

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  validationErrors: (_) ->
    errors = []
    if @stockNumber == '' then errors.push(@stockNumberValidationError)
    errors

  stockNumberValidationError:
    '在庫管理番号を入力してください。'

module.exports = KitElement
