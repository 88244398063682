import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { OrganizationTitle } from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import type { ExpensesTypeJson } from '@this/src/domain/expenses/expenses_type';
import ExpensesType from '@this/src/domain/expenses/expenses_type';
import type { ExpensesAccountTypeJson } from '@this/src/domain/expenses/expenses_account_type';
import ExpensesAccountType from '@this/src/domain/expenses/expenses_account_type';
import Cards from './cards';
import EditFormModal from './edit_form_modal';

type Props = {
  theme: { themeClass: string };
};

type IndexResponse = {
  expenses_types: ExpensesTypeJson[];
  expenses_account_types: ExpensesAccountTypeJson[];
  category_options: [string, string][];
};

interface ExpensesTypeResponse {
  expenses_type: ExpensesTypeJson;
  expenses_account_type: ExpensesAccountTypeJson;
}

const ExpensesTypes: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [types, setTypes] = useState<ExpensesType[]>([]);
  const [accountTypes, setAccountTypes] = useState<ExpensesAccountType[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<[string, string][]>([]);
  const [editingType, setEditingType] = useState<ExpensesType | null>(null);
  const [deletingType, setDeletingType] = useState<ExpensesType | null>(null);

  const fetchTypes = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await utils.jsonPromise<IndexResponse>('/organization/expenses_types.json');
      const expensesTypes = response.expenses_types.map(type => new ExpensesType(type));
      const expensesAccountTypes = response.expenses_account_types.map(
        account => new ExpensesAccountType(account)
      );
      setTypes(expensesTypes);
      setAccountTypes(expensesAccountTypes);
      setCategoryOptions(response.category_options);
    } catch (e) {
      if (e.status === 400) {
        setError(e.responseJSON.error);
      } else {
        setError('通信エラーが発生しました。時間をおいて再度お試しください。');
        utils.sendErrorObject(e);
      }
    }
    setLoading(false);
  };

  const deleteType = async () => {
    try {
      await utils.jsonPromise(`/organization/expenses_types/${deletingType!.id}`, {}, 'DELETE');
      setDeletingType(null);
      fetchTypes();
    } catch (e) {
      setError('通信エラーが発生しました。時間をおいて再度お試しください。');
      utils.sendErrorObject(e);
    }
  };

  const clickEdit = async (t: ExpensesType | null) => {
    if (t !== null) {
      const editT = _.cloneDeep(t);
      setEditingType(editT);
    }
  };

  const clickAdd = async () => {
    try {
      const response = await utils.jsonPromise<ExpensesTypeResponse>(`/organization/expenses_types/new`);
      const new_type = new ExpensesType(response.expenses_type);
      setTypes([...types, new_type]);
      setEditingType(new_type);
    } catch (e) {
      if (e.status === 400) {
        setError(e.responseJSON.error);
      } else {
        setError('通信エラーが発生しました。時間をおいて再度お試しください。');
        utils.sendErrorObject(e);
      }
    }
  };

  const clickCancel = () => {
    if (editingType && !editingType.id && types) {
      setTypes(types.filter(t => t.id));
    }
    setEditingType(null);
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const modal = () => (
    <>
      {editingType && (
        <EditFormModal
          type={editingType}
          accountTypes={accountTypes}
          categoryOptions={categoryOptions}
          onSubmit={() => {
            setEditingType(null);
            fetchTypes();
          }}
          hideModal={clickCancel}
        />
      )}
      {deletingType && (
        <Modal show hideModal={() => setDeletingType(null)} className="e2e-confirm-delete">
          <Confirm
            onConfirmed={() => deleteType()}
            onAborted={() => setDeletingType(null)}
            message={`${
              deletingType.name + deletingType.code
            }を削除してよろしいですか？\n※この操作は取り消せません`}
          />
        </Modal>
      )}
    </>
  );

  return (
    <>
      <OrganizationTitle>経費科目マスタ</OrganizationTitle>
      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Wrap>
          <Cards
            types={types}
            accountTypes={accountTypes}
            onClickEdit={t => clickEdit(t)}
            onClickDelete={t => {
              setDeletingType(t);
            }}
          />
        </Wrap>
      )}
      {!editingType && (
        <Wrap>
          <TheInput className="organization__add-button" type="submit" value="経費科目を追加" onClick={clickAdd} />
        </Wrap>
      )}
      {modal()}
    </>
  );
};

const Wrap = styled.div`
  padding: 10px;
`;

const TheInput = styled.input`
  margin-right: 10px;
`;

export default ExpensesTypes;
