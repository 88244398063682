import React from 'react';
import _ from 'lodash';
import type Hotel from '@this/domain/hotel/hotel';

import HotelDetail, {
  HotelBox,
  HotelImageArea,
  HotelContentArea,
  Left,
  HotelName,
  HotelBody
} from './hotel_box_parts';

type Props = {
  hotel: Hotel;
};

const ElementHotelBoxTemplate: React.SFC<Props> = ({ hotel }) => {
  return (
    <HotelBox className="select-hotel-box">
      <HotelImageArea hotel={hotel} />
      <HotelContentArea>
        <Left>
          <HotelName hotel={hotel} />
          <HotelBody>
            <HotelDetail hotel={hotel} />
          </HotelBody>
        </Left>
      </HotelContentArea>
    </HotelBox>
  );
};

export default ElementHotelBoxTemplate;
