import { observable } from 'mobx';
import type ElementBaseInterface from './element_base_interface';

const insuranceProducts = {
  overseas_travel_accident: '海外旅行傷害保険'
};

export type InsuranceProductType = keyof typeof insuranceProducts;

export interface TravelAuthorizationElementArgs {
  id?: number;
  order_item_id?: number;
  product?: InsuranceProductType;
  content?: string;
}

export default class InsuranceElement implements ElementBaseInterface {
  readonly id?: number;

  readonly type: string;

  orderItemId?: number;

  @observable
  product?: InsuranceProductType;

  @observable
  content: string;

  constructor(args: TravelAuthorizationElementArgs) {
    this.id = args.id;
    this.type = 'insurance';
    this.orderItemId = args.order_item_id;
    this.product = args.product;
    this.content = args.content || '';
  }

  title() {
    return '保険';
  }

  startDate() {
    return null;
  }

  endDate() {
    return null;
  }

  description() {
    return `<${this.title()}>
    ■商品：${this.product && insuranceProducts[this.product]}
    ■内容
    ${this.content}
    `;
  }

  summary() {
    return `[保険] ${this.product && insuranceProducts[this.product]}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  validationErrors(): string[] {
    const errors = [];
    if (!this.product) errors.push(`${this.title()}の商品を入力してください`);
    if (!this.content) errors.push(`${this.title()}の内容を入力してください`);
    return errors;
  }

  updateParams() {
    return {
      type: this.type,
      order_item_id: this.orderItemId,
      product: this.product,
      content: this.content
    };
  }
}
