import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Modal } from '@this/shared/ui/feedbacks/modal';
import styled from 'styled-components';
import type { ProjectArgs } from '@this/src/domain/project/project';
import Project from '@this/src/domain/project/project';
import TaxType from '@this/src/domain/tax_type';
import type { TaxTypeArgs } from '@this/src/domain/tax_type';
import { Fetcher, HTTPError } from '@this/src/util';
import type { ItemJson } from '../../../domain/expenses/item';
import { Item } from '../../../domain/expenses/item';
import type { ExpensesTypeJson } from '../../../domain/expenses/expenses_type';
import { ExpensesType } from '../../../domain/expenses/expenses_type';
import ExpensesMain from '../main/main';
import Form from './form';

interface ItemResponse {
  item: ItemJson;
  expenses_types: ExpensesTypeJson[];
  projects: ProjectArgs[];
  tax_types: TaxTypeArgs[];
  category_options: { [key: string]: string };
  general_expense_type_options: { [key: string]: string };
  travel_expense_type_options: { [key: string]: string };
  trip_type_options: { [key: string]: string };
  receipt_available: boolean;
  is_admin: boolean;
  use_project: boolean;
}

type Props = RouteComponentProps;

interface State {
  item?: Item;
  expensesTypes?: ExpensesType[];
  projects?: Project[];
  taxTypes?: TaxType[];
  categoryOptions?: { [key: string]: string };
  tripTypeOptions?: { [key: string]: string };
  error?: string;
  postErrors?: string | string[];
  loading: boolean;
  submitting: boolean;
  receiptAvailable: boolean;
  isAdmin: boolean;
  sourceItemIdForCopy: number | null;
  showCompleteModal: boolean;
  useProject: boolean;
}

class New extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      receiptAvailable: false,
      isAdmin: false,
      sourceItemIdForCopy: null,
      showCompleteModal: false,
      useProject: false
    };
  }

  async componentDidMount() {
    const itemId = new URLSearchParams(this.props.location.search).get('id');
    this.setState({ sourceItemIdForCopy: itemId ? Number(itemId) : null });

    await this.fetchNewItem();
    await this.fetchItem();
    mixpanel.track('[Biztra][view] /report_items/new');
  }

  handleSubmit() {
    this.submitItem();
  }

  private async fetchNewItem() {
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ItemResponse>(`/biztra/report_items/new`);
      const types = response.expenses_types.map(type => {
        return new ExpensesType(type);
      });
      this.setState({
        item: new Item(response.item),
        expensesTypes: types,
        projects: response.projects.map(raw => new Project(raw)),
        taxTypes: response.tax_types.map(raw => new TaxType(raw)),
        categoryOptions: response.category_options,
        tripTypeOptions: response.trip_type_options,
        receiptAvailable: response.receipt_available,
        isAdmin: response.is_admin,
        useProject: response.use_project,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async fetchItem() {
    if (!this.state.sourceItemIdForCopy) {
      return;
    }
    try {
      this.setState({ loading: true });
      const response = await Fetcher.get<ItemResponse>(
        `/biztra/report_items/${this.state.sourceItemIdForCopy}/edit`
      );
      const types = response.expenses_types.map(type => {
        return new ExpensesType(type);
      });
      const participantCompaniesWithoutId = response.item.participant_companies.map(p => ({
        name: p.name,
        people_num: p.people_num,
        own: p.own
      }));
      this.setState({
        item: new Item({ ...response.item, paid_at: '', participant_companies: participantCompaniesWithoutId }),
        expensesTypes: types,
        categoryOptions: response.category_options,
        tripTypeOptions: response.trip_type_options,
        receiptAvailable: response.receipt_available,
        isAdmin: response.is_admin,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async submitItem() {
    const item = this.state.item;
    if (!item) {
      return;
    }
    this.setState({ submitting: true });
    try {
      await Fetcher.upload('/biztra/report_items', item.formData);
      mixpanel.track('[Biztra] report_item submitted');
      this.setState({ submitting: false, showCompleteModal: true });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.error) {
        this.setState({
          postErrors: e.response.data.error,
          submitting: false
        });
      } else {
        this.setState({
          postErrors: '通信エラーが発生しました。時間をおいて再度お試しください。',
          submitting: false
        });
      }
    }
  }

  private itemFrom() {
    const {
      item,
      expensesTypes,
      projects,
      taxTypes,
      categoryOptions,
      tripTypeOptions,
      submitting,
      postErrors,
      receiptAvailable,
      isAdmin,
      useProject
    } = this.state;
    return (
      <Form
        item={item!}
        expensesTypes={expensesTypes!}
        projects={projects!}
        taxTypes={taxTypes!}
        categoryOptions={categoryOptions!}
        tripTypeOptions={tripTypeOptions!}
        onSubmit={() => this.handleSubmit()}
        submitting={submitting}
        errors={postErrors}
        backPath="/biztra/report_items"
        receiptAvailable={receiptAvailable}
        hideStatus
        isAdmin={isAdmin}
        useProject={useProject}
      />
    );
  }

  handleAddItem = async () => {
    await this.fetchNewItem();
    this.setState({ showCompleteModal: false });
  };

  handleApplyItem = () => {
    this.props.history.push('/biztra/reports/new');
  };

  handleBackToTop = () => {
    this.props.history.push('/biztra/report_items');
  };

  private completeModal() {
    const { showCompleteModal } = this.state;
    return (
      <Modal
        size="medium"
        onClose={() => {
          this.setState({ showCompleteModal: false });
        }}
        open={showCompleteModal}
      >
        <ModalContentWrapper>
          <ModalTitle>経費を登録しました</ModalTitle>
          <ModalActionWrapper>
            <PrimarySmallButton onClick={this.handleAddItem}>続けて経費を登録</PrimarySmallButton>
            <SecondarySmallButton onClick={this.handleApplyItem}>経費を申請する</SecondarySmallButton>
          </ModalActionWrapper>
          <ModalBackToTop onClick={this.handleBackToTop}>トップへ</ModalBackToTop>
        </ModalContentWrapper>
      </Modal>
    );
  }

  render() {
    const { loading, error } = this.state;

    return (
      <>
        <ExpensesMain
          title={this.state.sourceItemIdForCopy ? '履歴から登録' : '新規登録'}
          backLinkPath="/biztra/report_items"
        >
          {loading ? <SimpleLoading /> : error ? <p>{error}</p> : this.itemFrom()}
        </ExpensesMain>
        {this.completeModal()}
      </>
    );
  }
}

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const baseColor = '#927230';

const PrimaryButton = styled.button.attrs({ type: 'button' })`
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const PrimarySmallButton = styled(PrimaryButton)`
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
`;

const SecondaryBtnColor = '#927230';

const SecondaryTxtColor = '#ffffff';

const SecondaryButton = styled.button.attrs({ type: 'button' })`
  background: ${SecondaryBtnColor};
  color: ${SecondaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${SecondaryBtnColor};
  }
`;

const SecondarySmallButton = styled(SecondaryButton)`
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
`;

const ModalContentWrapper = styled.div`
  padding: 36px 72px;
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
`;

const ModalTitle = styled.div`
  color: black;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  justify-content: center;
  margin-top: 32px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;

const ModalActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: calc(10 / 456 * 100%);
  margin-top: 44px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;

const ModalBackToTop = styled.a`
  color: ${baseColor};
  display: flex;
  font-size: 18px;
  justify-content: center;
  margin-top: 32px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
`;

export default New;
