import { observable } from 'mobx';

export interface OrganizationBillReceiverArgs {
  id?: number;
  name?: string;
  email?: string;
  organization_id: number;
}

class OrganizationBillReceiver {
  @observable
  public id: number | null;

  @observable
  public name: string;

  @observable
  public email: string;

  @observable
  public organizationId: number;

  constructor(args: OrganizationBillReceiverArgs) {
    this.id = args.id || null;
    this.name = args.name || '';
    this.email = args.email || '';
    this.organizationId = args.organization_id;
  }

  submitParams() {
    return {
      id: this.id,
      name: this.name,
      email: this.email
    };
  }
}

export default OrganizationBillReceiver;
