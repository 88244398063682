import type { SupplierArgs } from '@this/domain/supplier/supplier';
import Supplier from '@this/domain/supplier/supplier';
import BaseAPI from '../base_api';

interface SuppliersResponse {
  suppliers: SupplierArgs[];
}

export default class SupplierAPI extends BaseAPI {
  public list(): Promise<Supplier[]> {
    return this.get<SuppliersResponse>(`/suppliers.json`).then(res =>
      res.data.suppliers.map((raw: SupplierArgs) => new Supplier(raw))
    );
  }
}
