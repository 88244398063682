import React from 'react';
import type { OutlinedInputProps as MuiInputProps } from '@material-ui/core';
import { OutlinedInput as MuiInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';

export const INPUT_SIZE = {
  small: 'small',
  medium: 'medium'
} as const;
export type InputSize = typeof INPUT_SIZE[keyof typeof INPUT_SIZE];

export type InputProps = { size?: InputSize } & Omit<
  MuiInputProps,
  'color' | 'rowsMin' | 'label' | 'labelWidth' | 'multiline'
>;

const useStyles = makeStyles<AITTheme>(({ tokens: { colors, typographies }, utils }) => ({
  root: {
    backgroundColor: colors.common.white,
    '& .MuiOutlinedInput-input': {
      padding: utils.getSpacing(2),
      '&.MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: 0
      },
      '&.MuiOutlinedInput-inputAdornedEnd': {
        paddingRight: 0
      }
    },
    '&.MuiOutlinedInput-adornedStart': {
      paddingLeft: utils.getSpacing(2)
    },
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: utils.getSpacing(2)
    },
    '& .MuiOutlinedInput-notchedOutline': {
      transition: 'border-color 0.2s'
    },
    '&.MuiOutlinedInput-multiline': {
      padding: utils.getSpacing(1),
      paddingLeft: utils.getSpacing(2),
      paddingRight: utils.getSpacing(2)
    },
    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.divider
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1
    }
  },
  sizeSmall: {
    '& .MuiOutlinedInput-input': {
      padding: utils.getSpacing(1),
      fontSize: typographies.fontSizes.caption
    },
    '&.MuiOutlinedInput-adornedStart': {
      paddingLeft: utils.getSpacing(1),
      '& .MuiInputAdornment-positionStart': {
        marginRight: utils.getSpacing(1)
      }
    },
    '&.MuiOutlinedInput-adornedEnd': {
      paddingRight: utils.getSpacing(1),
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: utils.getSpacing(1)
      }
    }
  }
}));

export const Input = React.forwardRef(({ className, size = 'medium', ...props }: InputProps, ref) => {
  const styles = useStyles();
  const classNames = [className, styles.root];
  if (size === 'small') {
    classNames.push(styles.sizeSmall);
  }

  return <MuiInput className={classNames.join(' ')} ref={ref} {...props} />;
});
