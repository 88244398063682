import React from 'react';
import { styled } from '@this/constants/themes';
import { Wrapper, Header } from '../report_items/search_form';
import { ActionWrapper, BaseButtonCss, CancelButtonCss } from './reject';

interface Props {
  onClose: () => void;
  isAdmin: boolean;
}

export default class ZenginDownload extends React.Component<Props> {
  render() {
    return (
      <Wrapper>
        <Header>振込用データのダウンロード</Header>
        <ConfirmBody>
          <ConfirmTxt>
            ビズトラProにアップグレードすると、振込用データ（全銀フォーマット）をダウンロードすることができます。
          </ConfirmTxt>
        </ConfirmBody>
        <ActionWrapper>
          <ZenginCancelButton onClick={this.props.onClose} isAdmin={this.props.isAdmin}>
            キャンセル
          </ZenginCancelButton>
          {this.props.isAdmin && (
            <ZenginBaseButton onClick={() => window.open('/biztra/organization/plans', '_blank')}>
              アップグレードする
            </ZenginBaseButton>
          )}
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const pinkColor = '#C72F62';

export const ConfirmTxt = styled.div`
  color: ${pinkColor};
  font-size: 16px;
  font-weight: bold;
  padding: 12px 60px;
  @media screen and (max-width: 767px) {
    padding: 12px 0px;
  }
`;

export const ConfirmBody = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const ZenginCancelButton = styled.button.attrs({ type: 'button' })<{ isAdmin?: boolean }>`
  ${CancelButtonCss}
  @media screen and (max-width: 767px) {
    padding: ${props => props.isAdmin && '0.815rem 1rem'};
  }
`;

const ZenginBaseButton = styled.button.attrs({ type: 'button' })`
  ${BaseButtonCss}
  @media screen and (max-width: 767px) {
    padding: 0.815rem 1rem;
  }
`;
