import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { media } from '@this/components/shared/atoms/media';

import InAdvanceApprovalStatus from '@this/components/shared/in_advance_approval_status/in_advance_approval_status';

interface Props {
  trip: any;
  approver: any;
  currentStatus: number;
  endTime: Date;
}

const InAdvanceApplicationCardTemplate: React.SFC<Props> = ({ trip, approver, currentStatus, endTime }) => (
  <Card to={`/application/in_advance/${trip.id}`}>
    <Main>
      <Title>
        旅程番号：<span data-wovn-ignore>{trip.id}</span>
      </Title>
      <Content>
        <ContentIn>
          <Table>
            <tbody>
              {trip.in_advance_approval ? (
                <tr>
                  <TdData>申請者</TdData>
                  <TdColon>：</TdColon>
                  <Td>
                    <span data-wovn-ignore>{trip.user.name}</span>
                  </Td>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <TdData>出張者</TdData>
                <TdColon>：</TdColon>
                <Td>
                  <span data-wovn-ignore>
                    {trip.travelerInformations.list
                      .map((i: any) => `${i.last_name_roman} ${i.first_name_roman}`)
                      .join(', ')}
                  </span>
                </Td>
              </tr>
              {trip.isApproved(trip.currentApprover(trip.currentUser.id)) ||
              trip.isRejected(trip.currentApprover(trip.currentUser.id)) ? (
                <>
                  {approver ? (
                    <tr>
                      <TdData>承認者</TdData>
                      <TdColon>：</TdColon>
                      <Td>
                        <span data-wovn-ignore>{approver.name}</span>
                      </Td>
                    </tr>
                  ) : trip.nextStageApprovers(
                      trip.in_advance_approval[trip.in_advance_approval.length - 1].approve_stage
                    ) && !trip.isRejected(trip.isApprovalCanceled(trip.currentUser.id)) ? (
                    <tr>
                      <TdData>次の承認担当者</TdData>
                      <TdColon>：</TdColon>
                      <Td>
                        <span data-wovn-ignore>
                          {trip
                            .nextStageApprovers(
                              trip.in_advance_approval[trip.in_advance_approval.length - 1].approve_stage
                            )
                            .join(', ')}
                        </span>
                      </Td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <tr>
                <TdData>出張期間</TdData>
                <TdColon>：</TdColon>
                <Td>
                  <span data-wovn-ignore>{trip.dateRange()}</span>
                </Td>
              </tr>
              <tr>
                <TdData>人数</TdData>
                <TdColon>：</TdColon>
                <Td>
                  <span data-wovn-ignore>{`${trip.travelerInformations.list.length}名`}</span>
                </Td>
              </tr>
              <tr>
                <TdData>申請日</TdData>
                <TdColon>：</TdColon>
                <Td>
                  <span data-wovn-ignore>{trip.createdAt()}</span>
                </Td>
              </tr>
            </tbody>
          </Table>
          <Price>
            <span data-wovn-ignore>{utils.digits(trip.totalPrice())}</span>
            <span>円</span>
          </Price>
        </ContentIn>
        <Arrow>&gt;</Arrow>
      </Content>
    </Main>
    <Sub>
      <Status>
        <StatusTitle>手配ステータス</StatusTitle>
        <InAdvanceApprovalStatus
          trip={trip}
          applicant={trip.applicant}
          approvers={trip.approvers || []}
          currentStatus={currentStatus}
        />
      </Status>
    </Sub>
  </Card>
);

const Card = styled(Link)`
  display: flex;
  overflow: hidden;
  margin-bottom: 10px;
  background: #fff;
  border: solid 1px ${props => props.theme.grayBorderColor};
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.textColor};
  }

  ${media.spSmall`
    display: block;
  `}
`;

const Main = styled.div``;

const Title = styled.div`
  padding: 20px;
  border-bottom: solid 1px ${props => props.theme.grayBorderColor};
  line-height: 1;
`;

const Content = styled.div`
  display: flex;
  padding: 20px;

  ${media.spSmall`
    padding-right: 10px;
  `}
`;

const ContentIn = styled.div``;

const Table = styled.table`
  margin: 0;
  border: none;
  vertical-align: top;
`;

const Td = styled.td`
  padding: 0 0 5px;
  border: none;
  vertical-align: top;

  ${media.sp`
    padding: 3px 0;
  `}
`;

const TdData = styled(Td)`
  width: 100px;
`;

const TdColon = styled(Td)`
  width: 15px;
`;

const Price = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: right;

  ${media.spSmall`
    font-size: 16px;
  `}
`;

const Arrow = styled.div`
  margin: auto 0 auto 25px;
  color: ${props => props.theme.accentColor};
`;

const Sub = styled.div`
  margin-left: auto;
  padding: 18px 20px 22px;
  background-color: ${props => props.theme.grayColorLight};
`;

const Status = styled.div`
  min-width: 200px;
  margin-left: auto;

  ${media.spSmall`
    margin: 0 auto 0 0;
  `}
`;

const StatusTitle = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;

  ${media.spSmall`
    font-size: 12px;
  `}
`;

export default InAdvanceApplicationCardTemplate;
