import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

type Props = RouteComponentProps;

const Tab = {
  RESERVATION_LIST: '予約機能検索',
  BOOKING_ERROR_LOG: '自動ブッキングエラーログ'
} as const;
export type TabType = keyof typeof Tab;
const AllTab: TabType[] = Object.keys(Tab) as TabType[];

interface State {
  currentModel: TabType;
}

const MODEL_MAP = {
  RESERVATION_LIST: React.lazy(() =>
    import('./hotel_reservation_list/hotel_reservation_list').then(module => ({
      default: module.HotelReservationList
    }))
  ),
  BOOKING_ERROR_LOG: React.lazy(() => import('./booking_error_logs/booking_error_logs'))
};

const HotelReservations: React.FC<Props> = props => {
  const search = useMemo(() => new URLSearchParams(props.location.search), [props.location.search]);
  const [state, setState] = useState<State>({
    currentModel: (search.get('tab') as TabType) ?? 'RESERVATION_LIST'
  });
  const { currentModel } = state;
  const CurrentModelComponent = MODEL_MAP[currentModel];

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setState(state => ({ ...state, currentModel: event.target.value as TabType }));
  }, []);

  return (
    <Wrapper>
      <SelectModel>
        {AllTab.map(tab => (
          <Label key={tab}>
            <input type="radio" checked={currentModel === tab} value={tab} onChange={handleChange} />
            {Tab[tab]}
          </Label>
        ))}
      </SelectModel>
      <Suspense fallback={<SimpleLoading />}>
        {CurrentModelComponent && <CurrentModelComponent {...props} />}
      </Suspense>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const SelectModel = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 20px;
`;

export default observer(HotelReservations);
