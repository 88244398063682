import type ElementBase from '@this/domain/element_base';
import type TransportElement from '@this/domain/transport_element';
import type HotelElement from '@this/domain/hotel_element';
import type Trip from '@this/domain/trip/trip';
import Hotel from '@this/domain/hotel/hotel';
import Transit from '@this/domain/transit/transit';
import Flight from '@this/domain/flight/flight';

class MarketLogHelper {
  static isElementMarketLogLinkAvailable(element: ElementBase, themeServiceName: string, trip?: Trip) {
    if (trip && trip.order?.orderItems.some(orderItem => orderItem.orderItemCategory === 'arrangement_request')) {
      return false;
    }

    switch (element.type) {
      case 'hotel': {
        const elm = element as HotelElement;
        return !elm.isPackage();
      }
      case 'transport': {
        const elm = element as TransportElement;
        if (elm.isDomesticAir()) return !elm.isPackage();
        if (elm.isForeignAir() && themeServiceName !== 'マイナビBTM') return true;
        break;
      }
      default:
        break;
    }
    return false;
  }

  static elementMarketLogPath(element: ElementBase, tripId: number) {
    return `/trips/${tripId}/market_log?element_id=${element.id}&element_type=${element.marketLogType()}`;
  }

  static isObjectMarketLogLinkAvailable(object: Hotel | Transit | Flight, themeServiceName: string) {
    if (object instanceof Hotel) return !object.isPackage();
    if (object instanceof Flight && themeServiceName !== 'マイナビBTM') return true;
    if (object instanceof Transit) return object.air && !object.isRakutenPackage;
    return false;
  }

  static objectMarketLogPath(object: Hotel | Transit | Flight) {
    let type = '';
    if (object instanceof Hotel) {
      type = 'hotel';
    }
    if (object instanceof Flight) {
      type = 'transport';
    }
    if (object instanceof Transit) {
      type = 'domestic_air';
    }
    return `/market_logs/${object.id}?element_type=${type}`;
  }
}

export default MarketLogHelper;
