import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { observer } from 'mobx-react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { styled } from '@this/constants/themes';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { ChatbotSetting } from '@this/src/domain/chatbot_setting';
import type { ChatbotSettingArgs } from '@this/src/domain/chatbot_setting';

import { Textarea } from '@this/shared/ui/inputs/textarea';
import { Fetcher } from '@this/src/util';
import { Input } from '../../shared/ui/inputs/input';
import { Select } from '../../shared/ui/inputs/select';

interface State {
  loading: boolean;
  updating: boolean;
  error: string | null;
  chatbotSetting: ChatbotSetting | null;
  modelOptions: { name: string; label: string }[];
  knowledgeThreshold: string;
  knowledgeUnknownMessage: string;
}

interface Response {
  chatbot_setting: ChatbotSettingArgs;
  model_options: { name: string; label: string }[];
}

type Action =
  | { type: 'LOADING' }
  | { type: 'UPDATING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_RESPONSE'; payload: Response }
  | { type: 'SET_KNOWLEDGE_THRESHOLD'; payload: string }
  | { type: 'RESET_KNOWLEDGE_THRESHOLD' }
  | { type: 'SET_KNOWLEDGE_UNKNOWN_MESSAGE'; payload: string }
  | { type: 'RESET_KNOWLEDGE_UNKNOWN_MESSAGE' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true, error: null };
    case 'UPDATING':
      return { ...state, updating: true, error: null };
    case 'SET_ERROR':
      return { ...state, loading: false, updating: false, error: action.payload };
    case 'SET_RESPONSE': {
      const setting = new ChatbotSetting(action.payload.chatbot_setting);
      return {
        ...state,
        loading: false,
        updating: false,
        chatbotSetting: setting,
        modelOptions: action.payload.model_options,
        knowledgeThreshold: setting.knowledgeThreshold.toString(),
        knowledgeUnknownMessage: setting.knowledgeUnknownMessage
      };
    }
    case 'SET_KNOWLEDGE_THRESHOLD':
      return { ...state, knowledgeThreshold: action.payload };
    case 'RESET_KNOWLEDGE_THRESHOLD':
      return { ...state, knowledgeThreshold: state.chatbotSetting?.knowledgeThreshold.toString() ?? '' };
    case 'SET_KNOWLEDGE_UNKNOWN_MESSAGE':
      return { ...state, knowledgeUnknownMessage: action.payload };
    case 'RESET_KNOWLEDGE_UNKNOWN_MESSAGE':
      return { ...state, knowledgeUnknownMessage: state.chatbotSetting?.knowledgeUnknownMessage ?? '' };
    default:
      return state;
  }
};

const initialState: State = {
  loading: false,
  updating: false,
  error: null,
  chatbotSetting: null,
  modelOptions: [],
  knowledgeThreshold: '',
  knowledgeUnknownMessage: ''
};

const ChatbotSettingForm = observer(() => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, updating, error, chatbotSetting, modelOptions, knowledgeThreshold, knowledgeUnknownMessage } =
    state;
  const isChangeKnowledgeThreshold = useMemo(
    () => knowledgeThreshold !== (chatbotSetting?.knowledgeThreshold?.toString() ?? ''),
    [knowledgeThreshold, chatbotSetting]
  );
  const isChangeKnowledgeUnknownMessage = useMemo(
    () => knowledgeUnknownMessage !== (chatbotSetting?.knowledgeUnknownMessage ?? ''),
    [knowledgeUnknownMessage, chatbotSetting]
  );

  const fetchSetting = useCallback(() => {
    dispatch({ type: 'LOADING' });
    Fetcher.get<Response>('/god/chatbot_settings.json')
      .then(response => dispatch({ type: 'SET_RESPONSE', payload: response }))
      .catch(() => dispatch({ type: 'SET_ERROR', payload: '設定が見つかりませんでした' }));
  }, [dispatch]);

  const updateSetting = useCallback(
    (chatbotSetting: ChatbotSetting) => {
      dispatch({ type: 'UPDATING' });
      const endpoint = `/god/chatbot_settings/${chatbotSetting.id}`;
      Fetcher.put(endpoint, chatbotSetting.submitParams()).finally(() => fetchSetting());
    },
    [dispatch, fetchSetting]
  );

  const handleChangeModel = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!chatbotSetting) return;

      chatbotSetting.model = e.target.value;
      updateSetting(chatbotSetting);
    },
    [chatbotSetting, updateSetting]
  );

  const handleChangeThreshold = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_KNOWLEDGE_THRESHOLD', payload: e.target.value });
    },
    [dispatch]
  );

  const handleClickThresholdReset = useCallback(() => {
    dispatch({ type: 'RESET_KNOWLEDGE_THRESHOLD' });
  }, [dispatch]);

  const handleChangeUnknownMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch({ type: 'SET_KNOWLEDGE_UNKNOWN_MESSAGE', payload: e.target.value });
    },
    [dispatch]
  );

  const handleClickUnknownMessageReset = useCallback(() => {
    dispatch({ type: 'RESET_KNOWLEDGE_UNKNOWN_MESSAGE' });
  }, [dispatch]);

  const handleClickUpdateSettings = useCallback(() => {
    if (!chatbotSetting) return;

    chatbotSetting.knowledgeThreshold = Number(knowledgeThreshold);
    chatbotSetting.knowledgeUnknownMessage = knowledgeUnknownMessage;
    updateSetting(chatbotSetting);
  }, [chatbotSetting, knowledgeThreshold, knowledgeUnknownMessage, updateSetting]);

  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  if (loading) return <Loading />;
  if (!chatbotSetting) return <Error>{error}</Error>;

  return (
    <>
      <Line>
        <MultilineLabel>モデル</MultilineLabel>
        <Rows>
          <Select
            value={chatbotSetting.model}
            onChange={handleChangeModel}
            disabled={updating}
            style={{ width: '400px' }}
          >
            {modelOptions.map(option => (
              <option key={option.name} value={option.name}>
                {option.label}
              </option>
            ))}
          </Select>
          <Text>
            モデルの詳細はこちらを参照してください。
            <br />
            <a href="https://openai.com/api/pricing/" target="_blank" rel="noreferrer">
              https://openai.com/api/pricing/
            </a>
          </Text>
        </Rows>
      </Line>
      <Line>
        <Label>ナレッジ検索の類似度閾値</Label>
        <Input
          type="number"
          value={knowledgeThreshold}
          onChange={handleChangeThreshold}
          disabled={updating}
          style={{ width: '400px' }}
        />
        {isChangeKnowledgeThreshold && (
          <Actions>
            <IconButton size="small" onClick={handleClickThresholdReset}>
              <CloseIcon />
            </IconButton>
            <IconButton size="small" onClick={handleClickUpdateSettings}>
              <CheckIcon />
            </IconButton>
          </Actions>
        )}
      </Line>
      <Line>
        <MultilineLabel>ナレッジ検索の失敗メッセージ</MultilineLabel>
        <Textarea
          value={knowledgeUnknownMessage}
          onChange={handleChangeUnknownMessage}
          disabled={updating}
          style={{ width: '400px' }}
          rowsMin="6"
        />
        {isChangeKnowledgeUnknownMessage && (
          <Actions>
            <IconButton size="small" onClick={handleClickUnknownMessageReset}>
              <CloseIcon />
            </IconButton>
            <IconButton size="small" onClick={handleClickUpdateSettings}>
              <CheckIcon />
            </IconButton>
          </Actions>
        )}
      </Line>
    </>
  );
});

const Error = styled.div`
  color: red;
`;

const Label = styled.label`
  font-weight: bold;
  width: 240px;
`;

const MultilineLabel = styled(Label)`
  align-self: flex-start;
  margin-top: 4px;
`;

const Line = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Rows = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 8px;
`;

const Actions = styled.div`
  display: flex;
  margin-left: 4px;
  gap: 8px;
  align-self: flex-end;
  margin-bottom: 2px;
`;

export default ChatbotSettingForm;
