import React from 'react';
import _ from 'lodash';

import type ArrangementRequestShinkansenInfo from '@this/domain/arrangement_request_info/arrangement_request_shinkansen_info';
import Tooltip from '@this/shared/tooltip/tooltip';
import SelectDepartments from '../../../reserve_confirm/routings/input_customer_information/select_departments/select_departments';

export type Props = {
  shinkansenInfo: ArrangementRequestShinkansenInfo;
  useBulkTicket: boolean | undefined;
  showEx: boolean | undefined;
};

const ArrangementRequestShinkansenInfoForm: React.SFC<Props> = ({ shinkansenInfo, useBulkTicket, showEx }) => (
  <div className="reserve-confirm__section">
    <div className="reserve-confirm__subtitle">新幹線・特急</div>
    <div className="reserve-confirm__child-content">
      {shinkansenInfo.seat !== 'ex' && (
        <>
          <p className="reserve-confirm__traveler__peoplenum">切符送り先住所</p>
          <div className="reserve-confirm__traveler">
            <label className="reserve-confirm__traveler__type">
              <input
                id="shinkansen_text"
                className="companion-label"
                type="radio"
                value="text"
                onChange={shinkansenInfo.setShinkansenAddressType}
                checked={shinkansenInfo.shinkansenAddressType === 'text'}
              />
              <span>手動入力</span>
            </label>
            {shinkansenInfo.shinkansenAddressType === 'text' && (
              <div className="reserve-confirm__traveler__from-member">
                <span>郵便番号</span>
                <input
                  id="postcode"
                  className="reserve-confirm__input"
                  type="text"
                  name="postcode"
                  value={shinkansenInfo.postcode}
                  onChange={shinkansenInfo.setPostcode}
                />
                <span>住所</span>
                <input
                  id="shinkansen_address"
                  type="text"
                  placeholder="チケットを送付するのに必要な情報まで記入ください（ビル名、部屋番号など）"
                  value={shinkansenInfo.shinkansenAddress}
                  onChange={shinkansenInfo.setShinkansenAddress}
                />
                <span>宛名</span>
                <input
                  id="shinkansen_addressee"
                  type="text"
                  placeholder="個人宅への送付の場合は、送付先住所に加え、お受け取りの方のお名前をフルネームで記載をお願いします"
                  value={shinkansenInfo.shinkansenAddressee}
                  onChange={shinkansenInfo.setShinkansenAddressee}
                />
              </div>
            )}
            {shinkansenInfo.departments && shinkansenInfo.departments.length > 0 && (
              <>
                <label className="reserve-confirm__traveler__type">
                  <input
                    type="radio"
                    id="shinkansen_select"
                    value="select"
                    onChange={shinkansenInfo.setShinkansenAddressType}
                    checked={shinkansenInfo.shinkansenAddressType === 'select'}
                  />
                  <span>部署から選択</span>
                </label>
                {shinkansenInfo.shinkansenAddressType === 'select' && (
                  <div className="reserve-confirm__traveler__from-member">
                    <SelectDepartments
                      departments={shinkansenInfo.departments}
                      onSelect={shinkansenInfo.setOrganizationAddress}
                      disabledAddress
                      user={shinkansenInfo.user}
                      isRequired
                      workflowFormWidth="100%"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <div className="reserve-confirm__traveler__input-area">
        <label className="reserve-confirm__radio-label">
          <input
            className="reserve-confirm__radio-input"
            type="radio"
            value="free"
            checked={shinkansenInfo.seat === 'free'}
            onChange={shinkansenInfo.setSeat}
          />
          <span>自由席</span>
        </label>
        <label className="reserve-confirm__radio-label">
          <input
            className="reserve-confirm__radio-input"
            type="radio"
            value="reserve"
            checked={shinkansenInfo.seat === 'reserve'}
            onChange={shinkansenInfo.setSeat}
          />
          <span>指定席</span>
        </label>
        <label className="reserve-confirm__radio-label">
          <input
            className="reserve-confirm__radio-input"
            type="radio"
            value="green"
            checked={shinkansenInfo.seat === 'green'}
            onChange={shinkansenInfo.setSeat}
          />
          <span>グリーン席</span>
        </label>
        {useBulkTicket && (
          <label className="reserve-confirm__radio-label">
            <input
              className="reserve-confirm__radio-input"
              type="radio"
              value="bulk_ticket"
              checked={shinkansenInfo.seat === 'bulk_ticket'}
              onChange={shinkansenInfo.setSeat}
            />
            <span>回数券</span>
            <Tooltip>
              「回数券」を選択した場合、日時・座席指定をしていない対象区間の特急・乗車券を送付します。
            </Tooltip>
          </label>
        )}
        {showEx && (
          <label className="reserve-confirm__radio-label">
            <input
              className="reserve-confirm__radio-input"
              type="radio"
              value="ex"
              checked={shinkansenInfo.seat === 'ex'}
              onChange={shinkansenInfo.setSeat}
            />
            <span>EX-IC</span>
          </label>
        )}
      </div>
      <div className="reserve-confirm__small">
        ※指定席以外を選択された場合、変更後の料金を改めてご連絡いたします。
      </div>
      {(shinkansenInfo.seat === 'reserve' || shinkansenInfo.seat === 'green' || shinkansenInfo.seat === 'ex') &&
        shinkansenInfo.travelers.list.map((traveler, i) => (
          <div key={i}>
            <p className="reserve-confirm__peoplenum">
              {i + 1}人目：{traveler.lastNameRoman} {traveler.firstNameRoman}
            </p>
            <div className="reserve-confirm__traveler">
              <div className="reserve-confirm__traveler__input-area">
                <div className="reserve-confirm__label">座席の希望</div>
                <div className="reserve-confirm__traveler__input-area-right" />
                <div className="reserve-confirm__traveler__input-area">
                  <label className="reserve-confirm__radio-label">
                    <input
                      className="reserve-confirm__radio-input"
                      type="radio"
                      value="指定なし"
                      checked={traveler.shinkansenSeat === '指定なし' || traveler.shinkansenSeat === ''}
                      onChange={shinkansenInfo.travelers.handleTravelerInfoChange(i, 'setShinkansenSeat')}
                    />
                    <span>指定なし</span>
                  </label>
                  <label className="reserve-confirm__radio-label">
                    <input
                      className="reserve-confirm__radio-input"
                      type="radio"
                      value="窓側"
                      checked={traveler.shinkansenSeat === '窓側'}
                      onChange={shinkansenInfo.travelers.handleTravelerInfoChange(i, 'setShinkansenSeat')}
                    />
                    <span>窓側</span>
                  </label>
                  <label className="reserve-confirm__radio-label">
                    <input
                      className="reserve-confirm__radio-input"
                      type="radio"
                      value="通路側"
                      checked={traveler.shinkansenSeat === '通路側'}
                      onChange={shinkansenInfo.travelers.handleTravelerInfoChange(i, 'setShinkansenSeat')}
                    />
                    <span>通路側</span>
                  </label>
                  <label className="reserve-confirm__radio-label">
                    <input
                      className="reserve-confirm__radio-input"
                      type="radio"
                      value="その他"
                      checked={traveler.shinkansenSeat === 'その他'}
                      onChange={shinkansenInfo.travelers.handleTravelerInfoChange(i, 'setShinkansenSeat')}
                    />
                    <span>その他</span>
                  </label>
                  {traveler.shinkansenSeat === 'その他' && (
                    <input
                      className="reserve-confirm__input shinkansen-seat"
                      type="text"
                      placeholder="(任意)"
                      value={traveler.requestShinkansenSeat}
                      onChange={shinkansenInfo.travelers.handleTravelerInfoChange(i, 'setShinkansenSeatText')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default ArrangementRequestShinkansenInfoForm;
