import { Fetcher, objectToFormData } from '@this/src/util';
/* eslint-disable max-lines */
import type { MouseEvent } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import _ from 'lodash';

import Text from '@this/shared/text/text';
import ExpensesAccountTypeMappingForm from '@this/src/components/trips_management/trips/request_approval_modal/expenses_account_type_mapping_form';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import SelectApproveItemList from '@this/components/shared/select_approveitemlist/select_approveitemlist';
import ApproveRoute from '@this/shared/approve_route/approve_route_input';
import Markdown from '@this/shared/markdown/markdown';
import ArrangementRequestWfInfo from '@this/domain/arrangement_request_info/arrangement_request_wf_info';
import ProjectList from '@this/domain/project/project_list';
import type Project from '@this/domain/project/project';
import TravelerList from '@this/domain/traveler/traveler_list';
import type Approver from '@this/domain/approver/approver';
import type { SettingArgs } from '@this/domain/setting';
import DepartmentList from '@this/src/domain/department/department_list';
import type Department from '@this/domain/department/department';
import type ItemList from '@this/domain/approve_item/item_list';
import moment from '@this/lib/moment';
import type User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';
import type { WorkflowStyle } from '@this/domain/workflow_style';
import type { ChargingDepartmentShareArgs } from '@this/src/domain/department/charging_department_share';
import ChargingDepartmentShareList from '@this/src/domain/department/charging_department_share_list';
import type { ProjectShareArgs } from '@this/src/domain/project/project_share';
import ProjectShareList from '@this/src/domain/project/project_share_list';
import ExpensesAccountType from '@this/src/domain/expenses/expenses_account_type';
import type { ExpensesAccountTypeJson } from '@this/src/domain/expenses/expenses_account_type';
import ApproveItemDropzone from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/approve_items/approve_item_dropzone';
import type { ImageFile } from 'react-dropzone';
import ChargingDepartmentMappingForm from './charging_department_mapping_form';
import ProjectMappingForm from './project_mapping_form';
import Notification from '../../../../notification';

interface Stage {
  stage: number;
  approvers: (User | null)[];
}

interface Props {
  show: boolean;
  handleHide: () => void;
  handleSubmit: (e?: MouseEvent<HTMLAnchorElement>) => void;
  trip: any;
  children: any;
}

interface State {
  arrangementRequestWfInfo: ArrangementRequestWfInfo | undefined;
  loading: boolean;
  validationErrors: { [key: string]: string | undefined } | null | undefined;
  workflowStyle: WorkflowStyle;
  saving: boolean;
}

interface ForArrangementRequestInfoResponse {
  departments: Department[];
  department_shares: ChargingDepartmentShareArgs[];
  projects: Project[];
  project_shares: ProjectShareArgs[];
  approvers: Approver[];
  expenses_account_types: ExpensesAccountTypeJson[];
  expenses_account_type_available: boolean;
  setting: SettingArgs;
  traveler_informations_user: User[];
  project_share_availability: boolean;
  user: { workflow_style: WorkflowStyle };
}

class RequestApprovalModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      arrangementRequestWfInfo: undefined,
      loading: true,
      validationErrors: {},
      workflowStyle: 'department',
      saving: false
    };
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  fetchOrganizations() {
    this.setState({ loading: true });
    Fetcher.get<ForArrangementRequestInfoResponse>(`/arrangement_requests/for_request_info.json?${Date.now()}`, {
      trip_user_id: this.props.trip.user.id,
      trip_id: this.props.trip.id
    })
      .then(result => {
        this.setState({
          workflowStyle: result.user.workflow_style
        });
        this.setArrangementRequestWfInfo(result);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  async setArrangementRequestWfInfo(result: ForArrangementRequestInfoResponse) {
    const trip = this.props.trip;
    const arrangementRequestWfInfo = new ArrangementRequestWfInfo({
      trip,
      travelers: RequestApprovalModal.getInitialTravelers(trip.peoplenum, result.traveler_informations_user),
      departments: new DepartmentList(result.departments),
      departmentShares: new ChargingDepartmentShareList(result.department_shares),
      approvers: result.approvers,
      setting: result.setting,
      projectShareAvailability: result.project_share_availability,
      orderItemMappingArgsList: [],
      expensesAccountTypes: result.expenses_account_types.map(account => new ExpensesAccountType(account)),
      expensesAccountTypeAvailable: result.expenses_account_type_available,
      projects: new ProjectList(result.projects),
      projectShares: new ProjectShareList(result.project_shares),
      workflowStyle: result.user.workflow_style
    });

    await arrangementRequestWfInfo.fetchApproveItems();

    this.setState({
      arrangementRequestWfInfo,
      loading: false
    });
  }

  handleApproveTextChange =
    (id: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      this.state.arrangementRequestWfInfo?.setApproveItemValue(id, e.target.value);
    };

  handleApproveCalendarSelect = (id: number) => (date: moment.Moment) => {
    if (date !== null) {
      this.state.arrangementRequestWfInfo?.setApproveItemValue(id, date.format('YYYY-MM-DD'));
    } else {
      this.state.arrangementRequestWfInfo?.setApproveItemValue(id, '');
    }
  };

  handleApproveListChange = (id: number) => (itemList: ItemList | null) => {
    this.state.arrangementRequestWfInfo?.setApproveItemValueCode(id, itemList?.code || '');
    this.state.arrangementRequestWfInfo?.setApproveItemValue(id, itemList?.name || '');
  };

  handleApproveFileChange = (id: number, file?: ImageFile) => {
    if (file) {
      this.state.arrangementRequestWfInfo?.setApproveItemFile(id, file);
    }
  };

  handleApproveFileRemove = (id: number) => {
    this.state.arrangementRequestWfInfo?.removeApproveItemFile(id);
  };

  validateInput = (e: React.MouseEvent) => {
    this.setState({ loading: true, saving: true });
    const validationErrors =
      this.state.arrangementRequestWfInfo && this.state.arrangementRequestWfInfo.validationErrors();
    this.setState({ validationErrors });
    if (_.isEmpty(validationErrors)) {
      this.submitRequestApproval();
    } else {
      this.setState({ loading: false, saving: false });
      e.preventDefault();
    }
  };

  submitRequestApproval = async () => {
    try {
      await Fetcher.put(
        `/arrangement_requests/${this.props.trip.id}.json`,
        objectToFormData(this.state.arrangementRequestWfInfo?.submitParams() ?? {})
      );
      this.props.handleSubmit();
    } catch (e) {
      Notification.error('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    } finally {
      this.setState({ loading: false, saving: false });
    }
  };

  private static getInitialTravelers(peoplenum: number, members: User[]) {
    const travelers = TravelerList.fromCount(peoplenum);
    members.map((u: User, i: number) => travelers.setTravelerAtIndex(i, new Traveler(u)));
    return travelers;
  }

  handleApproveStagesChange = (stages: Stage[]) => {
    this.state.arrangementRequestWfInfo?.setApproveStages(stages);
  };

  private arrangementRequestWf() {
    const { arrangementRequestWfInfo, workflowStyle } = this.state;
    const WORKFLOW_FORM_WIDTH = '420px';
    return (
      <>
        <Section>
          <Subtitle>ワークフロー</Subtitle>
          <ChildContent>
            {arrangementRequestWfInfo &&
              arrangementRequestWfInfo.isApprovalRequired() &&
              !_.isUndefined(arrangementRequestWfInfo.approvers) &&
              !_.isNull(arrangementRequestWfInfo.approvers) &&
              workflowStyle === 'department' && (
                <>
                  {arrangementRequestWfInfo.approvers.map(
                    (a, i) =>
                      (i === 0 || a.approve_stage > arrangementRequestWfInfo.approvers[i - 1].approve_stage) && (
                        <TravelerInputArea key={`input_approver_${i}`}>
                          <Label>{a.approve_stage}次承認者</Label>
                          <WorkflowInputArea workflowFormWidth={WORKFLOW_FORM_WIDTH}>{a.name}</WorkflowInputArea>
                        </TravelerInputArea>
                      )
                  )}
                </>
              )}

            {arrangementRequestWfInfo?.isApprovalRequired() ||
            arrangementRequestWfInfo?.isDestinationBusinessTripRequired() ? (
              <>
                <TravelerInputArea>
                  <Label>
                    出張先<Red>*</Red>
                  </Label>
                  <InputAreaRight>
                    <InputTextArea
                      id="finalDestination"
                      value={arrangementRequestWfInfo && arrangementRequestWfInfo.finalDestination}
                      onChange={e => {
                        arrangementRequestWfInfo.finalDestination = e.target.value;
                      }}
                      placeholder="(必須)出張先"
                      rows={2}
                    />
                  </InputAreaRight>
                </TravelerInputArea>
                {!_.isEmpty(this.state.validationErrors && this.state.validationErrors.area) && (
                  <Errors>
                    <div className="error">
                      <Text text={this.state.validationErrors && this.state.validationErrors.area} />
                    </div>
                  </Errors>
                )}
              </>
            ) : (
              <TravelerInputArea>
                <Label>出張先</Label>
                <InputAreaRight>
                  <InputTextArea
                    id="finalDestination"
                    value={arrangementRequestWfInfo && arrangementRequestWfInfo.finalDestination}
                    onChange={e => {
                      if (arrangementRequestWfInfo) arrangementRequestWfInfo.finalDestination = e.target.value;
                    }}
                    placeholder="(任意)出張先"
                    rows={2}
                  />
                </InputAreaRight>
              </TravelerInputArea>
            )}

            {arrangementRequestWfInfo?.isApprovalRequired() ||
            arrangementRequestWfInfo?.isPurposeBusinessTripRequired() ? (
              <>
                <TravelerInputArea>
                  <Label>
                    出張の目的<Red>*</Red>
                  </Label>
                  <InputAreaRight>
                    <InputTextArea
                      id="purpose"
                      value={arrangementRequestWfInfo.purpose}
                      onChange={e => {
                        arrangementRequestWfInfo.purpose = e.target.value;
                      }}
                      placeholder="(必須)出張の目的や承認者への備考などを入力してください"
                      rows={3}
                    />
                  </InputAreaRight>
                </TravelerInputArea>
                {!_.isEmpty(this.state.validationErrors && this.state.validationErrors.purpose) && (
                  <Errors>
                    <div className="error">
                      <Text text={this.state.validationErrors && this.state.validationErrors.purpose} />
                    </div>
                  </Errors>
                )}
              </>
            ) : (
              <TravelerInputArea>
                <Label>出張の目的</Label>
                <InputAreaRight>
                  <InputTextArea
                    id="purpose"
                    value={arrangementRequestWfInfo && arrangementRequestWfInfo.purpose}
                    onChange={e => {
                      if (arrangementRequestWfInfo) arrangementRequestWfInfo.purpose = e.target.value;
                    }}
                    placeholder="(任意)出張の目的や承認者への備考などを入力してください"
                    rows={3}
                  />
                </InputAreaRight>
              </TravelerInputArea>
            )}

            {arrangementRequestWfInfo?.isInternalNumberShown() && (
              <TravelerInputArea>
                <Label>社内管理番号</Label>
                <InputAreaRight>
                  <Input
                    type="text"
                    placeholder={`${
                      arrangementRequestWfInfo.isInternalNumberRequired() ? '(必須)' : '(任意)'
                    }社内稟議番号など`}
                    value={arrangementRequestWfInfo.internalNumber}
                    onChange={e => {
                      arrangementRequestWfInfo.internalNumber = e.target.value;
                    }}
                  />
                </InputAreaRight>
              </TravelerInputArea>
            )}

            {/* ここから費用負担部署 */}
            {arrangementRequestWfInfo && arrangementRequestWfInfo.isChargingDepartmentShown() && (
              <TravelerInputArea>
                <Label>費用負担部署</Label>
                <ChargingDepartmentMappingForm wf={arrangementRequestWfInfo} />
              </TravelerInputArea>
            )}
            {/* ここまで費用負担部署 */}

            {/* ここからプロジェクト（案件）  */}
            {arrangementRequestWfInfo && arrangementRequestWfInfo.isProjectNameShown() && (
              <TravelerInputArea>
                <Label>プロジェクト(案件)</Label>
                <ProjectMappingForm wf={arrangementRequestWfInfo} />
              </TravelerInputArea>
            )}
            {/* ここまでプロジェクト（案件）  */}

            {/* 勘定科目 */}
            {arrangementRequestWfInfo &&
              arrangementRequestWfInfo.expensesAccountTypeAvailable &&
              arrangementRequestWfInfo.isExpensesAccountTypeShown() && (
                <TravelerInputArea>
                  <Label>勘定科目</Label>
                  <ExpensesAccountTypeMappingForm wf={arrangementRequestWfInfo} />
                  {!_.isEmpty(this.state.validationErrors && this.state.validationErrors.expensesAccountType) && (
                    <Errors>
                      <div className="error">
                        <Text
                          text={this.state.validationErrors && this.state.validationErrors.expensesAccountType}
                        />
                      </div>
                    </Errors>
                  )}
                </TravelerInputArea>
              )}

            {arrangementRequestWfInfo &&
              arrangementRequestWfInfo.approveItems.list.map(d => (
                <React.Fragment key={d.id}>
                  <TravelerInputArea>
                    {d.dataType === 'label' ? (
                      <ApproveItemLabelOnly>
                        <Markdown markdownText={d.userDisplayName} />
                      </ApproveItemLabelOnly>
                    ) : (
                      <>
                        <Label>
                          {d.userDisplayName}
                          {(d.requiredType === 'required' ||
                            arrangementRequestWfInfo.isApproveItemRequiredWithWorkflow(d.requiredType)) && (
                            <Red>*</Red>
                          )}
                        </Label>
                        {d.dataType === 'freetext' && (
                          <InputAreaRight>
                            <InputTextArea
                              id={d.id.toString()}
                              value={
                                arrangementRequestWfInfo && arrangementRequestWfInfo.getApproveItemValue(d.id)
                              }
                              onChange={this.handleApproveTextChange(d.id)}
                              placeholder={d.getReservePlaceholder(
                                arrangementRequestWfInfo.isApproveItemRequiredWithWorkflow(d.requiredType)
                              )}
                              rows={1}
                            />
                          </InputAreaRight>
                        )}
                        {d.dataType === 'multilinefreetext' && (
                          <InputAreaRight>
                            <InputTextArea
                              id={d.id.toString()}
                              value={
                                arrangementRequestWfInfo && arrangementRequestWfInfo.getApproveItemValue(d.id)
                              }
                              onChange={this.handleApproveTextChange(d.id)}
                              placeholder={d.getReservePlaceholder(
                                arrangementRequestWfInfo.isApproveItemRequiredWithWorkflow(d.requiredType)
                              )}
                              rows={5}
                            />
                          </InputAreaRight>
                        )}
                        {d.dataType === 'calendar' && (
                          <ApproveItemDatePicker>
                            <DatetimePicker
                              onChange={this.handleApproveCalendarSelect(d.id)}
                              value={
                                arrangementRequestWfInfo &&
                                arrangementRequestWfInfo.getApproveItemValue(d.id) !== null &&
                                arrangementRequestWfInfo &&
                                arrangementRequestWfInfo.getApproveItemValue(d.id) !== undefined
                                  ? moment(
                                      arrangementRequestWfInfo &&
                                        arrangementRequestWfInfo.getApproveItemValue(d.id)
                                    )
                                  : undefined
                              }
                              disabledDays={0}
                              showTime={false}
                              showPast
                              deletable
                              border
                            />
                          </ApproveItemDatePicker>
                        )}

                        {d.dataType === 'list' && (
                          <SelectApproveItemList
                            onSelect={this.handleApproveListChange(d.id)}
                            approveItemId={d.id}
                            chargingDepartmentIds={
                              arrangementRequestWfInfo && arrangementRequestWfInfo.chargingDepartmentIDs
                            }
                            defaultItemListName="選択してください"
                            workflowFormWidth={WORKFLOW_FORM_WIDTH}
                          />
                        )}
                        {d.dataType === 'file' && (
                          <ApproveItemDropzone
                            preview={
                              arrangementRequestWfInfo.getApproveItemFile(d.id)
                                ? arrangementRequestWfInfo.getApproveItemFile(d.id)?.name
                                : undefined
                            }
                            onSelect={f => this.handleApproveFileChange(d.id, f)}
                            onRemove={() => this.handleApproveFileRemove(d.id)}
                          />
                        )}
                      </>
                    )}
                  </TravelerInputArea>
                  {!_.isEmpty(
                    this.state.validationErrors && this.state.validationErrors[`appleveItem_${d.id}`]
                  ) && (
                    <Errors key={d.id}>
                      <div className="error">
                        <Text
                          text={this.state.validationErrors && this.state.validationErrors[`appleveItem_${d.id}`]}
                        />
                      </div>
                    </Errors>
                  )}
                </React.Fragment>
              ))}
          </ChildContent>
        </Section>
        {arrangementRequestWfInfo && arrangementRequestWfInfo.isApprovalRequired() && workflowStyle === 'trip' && (
          <Section>
            <Subtitle>ワークフロー 承認ルート登録</Subtitle>
            <ChildContent>
              <ApproveRoute handleApproveStagesChange={this.handleApproveStagesChange} />
            </ChildContent>
          </Section>
        )}
        <Errors>
          {this.state.validationErrors &&
            _.entries(this.state.validationErrors).map(([k, v]) => (
              <div className="error" key={k}>
                <Text text={v} />
              </div>
            ))}
        </Errors>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <SimpleLoading />
        ) : (
          <Modal open={this.props.show} size="large" onClose={() => this.props.handleHide()}>
            <ModalHeader>下記の旅程を申請する</ModalHeader>
            <ModalBody>
              <ModalBodyInner>
                {this.props.children}
                {this.arrangementRequestWf()}
              </ModalBodyInner>
            </ModalBody>
            <ModalFooter>
              <Button color="sub" onClick={() => this.props.handleHide()}>
                キャンセル
              </Button>
              <Button onClick={this.validateInput} disabled={this.state.saving}>
                申請
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

const ModalBodyInner = styled.div`
  margin-top: 20px;
`;

const ChildContent = styled.div`
  font-size: 13px;
  margin-left: 10px;
`;

const Section = styled.div`
  padding-bottom: 20px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  margin: 20px 0 10px;
  font-weight: bold;
`;

const Label = styled.div`
  font-size: 13px;
  width: 150px;
  line-height: 34px;
`;

const Input = styled.input`
  max-width: 300px;
  margin-right: 10px;
`;

const InputTextArea = styled.textarea`
  max-width: 300px;
  margin-right: 10px;
`;

const InputAreaRight = styled.div`
  line-height: 34px;
  display: flex;
  width: 420px;
`;

const TravelerInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const ApproveItemDatePicker = styled.div`
  margin-left: 0;
  display: block;
`;

const Errors = styled.div`
  margin-bottom: $base-margin;
`;

const Red = styled.span`
  color: ${props => props.theme.redColor};
`;

const WorkflowInputArea = styled.div<{ workflowFormWidth?: string }>`
  line-height: 34px;
  display: flex;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const ApproveItemLabelOnly = styled.div`
  margin-bottom: 5px;
`;

export default observer(RequestApprovalModal);
