import React from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/src/domain/trip/trip';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { Button } from '@this/shared/ui/inputs/button';
import { Flex } from '@this/shared/ui/layout/flex';
import PartnerErrorMessage from '../../partner_error_message';
import VirtualCounterPartnerArchiveTrip from './archive_trip';

type Props = {
  className?: string;
  initialTripId: string | null;
  trip: Trip | null;
  errorMessage?: string;
  onSubmitSearch: (tripId: string) => Promise<void>;
};

export const VirtualCounterHeader = ({ className, initialTripId, trip, errorMessage, onSubmitSearch }: Props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [tripId, setTripId] = React.useState(initialTripId ?? '');

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (submitting) return;

      setSubmitting(true);
      try {
        await onSubmitSearch(tripId);
      } finally {
        setSubmitting(false);
      }
    },
    [submitting, setSubmitting, onSubmitSearch, tripId]
  );

  return (
    <Root className={className}>
      <div>
        {errorMessage && <PartnerErrorMessage message={errorMessage} />}
        {trip && <VirtualCounterPartnerArchiveTrip trip={trip} />}
      </div>

      <form onSubmit={handleSubmit}>
        <Flex alignItems="flex-end" gap="8px">
          <FormControl>
            <InputLabel>旅券番号検索</InputLabel>
            <Input value={tripId} data-testid="trip-id-input" onChange={e => setTripId(e.target.value)} />
          </FormControl>
          <Button type="submit" loading={submitting}>
            検索
          </Button>
        </Flex>
      </form>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.wrapperBgColor};
  padding: 16px;
`;
