moment = require('moment-timezone')
toDomesticTel = require('../../util/string').toDomesticTel

class RentalCarList
  constructor: (list, types) ->
    @list = list
    @typeOptions = types

  detail: ->
    d = ''
    for r, i in @list
      d += "\n\n" if i > 0
      d += "<#{r.departure_place} #{@dateText(r.departure_date)}出発 〜 "
      d += "#{r.return_place} #{@dateText(r.return_date)}返却>"
      d += "\n 車種：#{@typeText(r.car_type)}"
      d += "\n #{r.car_type_other}" if r.car_type == 'other'
      d += "\n 台数：#{r.car_number}台"
      d += "\n 運転者氏名：#{r.last_name_kana} #{r.first_name_kana}"
      d += "\n 運転者電話番号：#{toDomesticTel(r.driver_tel)}"
      d += "\n 備考：#{r.remarks}"
    d

  dateText: (date) ->
    moment(date).format('M月D日HH時mm分')

  typeText: (type) ->
    return 'その他' if type == 'other'
    dig(_.find(@typeOptions, (option) -> type == option[1]), 0)

module.exports = RentalCarList
