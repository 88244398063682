import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import type { InputSize } from '@this/shared/ui/inputs/input';
import { Input, INPUT_SIZE } from '@this/shared/ui/inputs/input';
import { Text } from '@this/shared/ui/data_displays/typography';
import { useFormControl, makeStyles } from '@material-ui/core';
import type {
  FilterOptionsState,
  AutocompleteInputChangeReason,
  AutocompleteCloseReason
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import useDebounce from '@this/lib/hooks/useDebounce';
import type { AITTheme } from '@this/shared/ui/theme';

export const AUTOCOMPLETE_SIZE = INPUT_SIZE;
export type AutoCompleteSize = InputSize;

export const AUTOCOMPLETE_VARIANT = {
  outlined: 'outlined',
  ghost: 'ghost'
} as const;
export type AutoCompleteVariant = keyof typeof AUTOCOMPLETE_VARIANT;

type AutocompleteInitOnlyAsyncProps<O> = {
  async: 'INIT_ONLY';
  getOptions: () => Promise<O[]>;
  filterOptions?: (options: O[], state: FilterOptionsState<O>) => O[];
};
type AutocompleteFilteringAsyncProps<O> = {
  async: 'FILTERING';
  getOptions: (inputValue?: string) => Promise<O[]>;
  inputChangeDelay?: number; // millisec
};

type AutocompleteAsyncProps<O> =
  | {
      async?: 'NONE';
      options: O[];
      filterOptions?: (options: O[], state: FilterOptionsState<O>) => O[];
    }
  | AutocompleteInitOnlyAsyncProps<O>
  | AutocompleteFilteringAsyncProps<O>;

type AutocompleteBaseProps<
  O,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> =
  | {
      className?: string;
      size?: AutoCompleteSize;
      variant?: AutoCompleteVariant;
      error?: boolean;
      getOptionCaption?: (option: O) => string;
    } & Omit<
      MuiAutocompleteProps<O, false, DisableClearable, FreeSolo>,
      | 'size'
      | 'autoHighlight'
      | 'autoSelect'
      | 'blurOnSelect'
      | 'ChipProps'
      | 'clearOnBlur'
      | 'clearOnEscape'
      | 'clearText'
      | 'closeIcon'
      | 'closeText'
      | 'disablePortal'
      | 'forcePopupIcon'
      | 'getLimitTagsText'
      | 'limitTags'
      | 'loadingText'
      | 'multiple'
      | 'onHighlightChange'
      | 'openOnFocus'
      | 'openText'
      | 'PaperComponent'
      | 'PopperComponent'
      | 'popupIcon'
      | 'renderInput'
      | 'renderTags'
      | 'renderGroup'
      | 'selectOnFocus'
      | 'options'
      | 'filterOptions'
    >;

type AutocompleteProps<
  O,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = AutocompleteBaseProps<O, DisableClearable, FreeSolo> & AutocompleteAsyncProps<O>;

const useAutocompleteStyles = makeStyles<AITTheme, { variant: AutoCompleteVariant }>(({ tokens: { colors } }) => ({
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: colors.background.selected
    },
    '&:active': {
      backgroundColor: colors.background.selected
    }
  },
  input: ({ variant }) => {
    if (variant === 'outlined') {
      return {};
    }

    return {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    };
  }
}));

const Autocomplete = <
  O,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  className,
  placeholder,
  error,
  renderOption,
  variant = 'outlined',
  disabled: disabledProps,
  getOptionLabel,
  getOptionCaption,
  ...baseProps
}: AutocompleteProps<O, DisableClearable, FreeSolo>) => {
  const styles = useAutocompleteStyles({ variant });
  const muiFormControl = useFormControl();

  const props = useMemo<
    Pick<
      MuiAutocompleteProps<O, false, DisableClearable, FreeSolo>,
      'className' | 'disabled' | 'classes' | 'renderInput' | 'renderOption' | 'getOptionLabel'
    >
  >(
    () => ({
      className,
      // FormControlで囲ったときにdisabledしても操作できてしまうため
      disabled: muiFormControl ? muiFormControl.disabled : disabledProps,
      classes: styles,
      getOptionLabel,
      renderInput: ({ InputProps: { ref, startAdornment, endAdornment }, ...params }) => (
        <Input
          className={styles.input}
          placeholder={placeholder}
          ref={ref}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          error={error}
          {...params}
        />
      ),
      renderOption:
        renderOption ||
        (option => (
          <AutocompleteOptionContent
            label={getOptionLabel ? getOptionLabel(option) : ''}
            caption={getOptionCaption ? getOptionCaption(option) : ''}
          />
        ))
    }),
    [
      className,
      muiFormControl,
      disabledProps,
      styles,
      renderOption,
      error,
      placeholder,
      getOptionLabel,
      getOptionCaption
    ]
  );

  return 'getOptions' in baseProps ? (
    <AutocompleteAsyncOption<O, DisableClearable, FreeSolo> {...props} {...baseProps} />
  ) : (
    <MuiAutocomplete<O, false, DisableClearable, FreeSolo> {...props} {...baseProps} />
  );
};

type AutocompleteAsyncOptionProps<
  O,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Omit<MuiAutocompleteProps<O, false, DisableClearable, FreeSolo>, 'options'> &
  (AutocompleteInitOnlyAsyncProps<O> | AutocompleteFilteringAsyncProps<O>);
const AutocompleteAsyncOption = <
  O,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  getOptions,
  onOpen,
  onClose,
  loading: rawLoading,
  open: rawOpen,
  filterOptions: rawFilterOptions,
  onInputChange: rawOnInputChange,
  ...baseProps
}: AutocompleteAsyncOptionProps<O, DisableClearable, FreeSolo>) => {
  const { async } = baseProps;
  const [loading, setLoading] = useState<boolean>(rawLoading ?? false);
  const [options, setOptions] = useState<O[]>([]);
  const [open, setOpen] = useState<boolean>(rawOpen ?? false);
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedInputValue = useDebounce(
    inputValue,
    baseProps.async === 'FILTERING' ? baseProps.inputChangeDelay ?? 300 : 0
  );

  const loadOptions = useCallback(
    async (keyword?: string) => {
      try {
        setOptions([]);
        setLoading(true);
        setOptions(await getOptions(keyword));
      } finally {
        setLoading(false);
      }
    },
    [getOptions]
  );

  const handleOnOpen = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: React.ChangeEvent<{}>) => {
      setOpen(true);
      loadOptions();
      if (onOpen) {
        onOpen(event);
      }
    },
    [onOpen, loadOptions]
  );

  const handleOnClose = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
      setOpen(false);
      if (onClose) {
        onClose(event, reason);
      }
    },
    [onClose]
  );

  const asyncFilterOptions = useCallback((opts: O[]) => opts, []);
  const filterOptions = useMemo(
    () => (async === 'FILTERING' ? asyncFilterOptions : rawFilterOptions),
    [async, rawFilterOptions, asyncFilterOptions]
  );
  const handleInputChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      if (rawOnInputChange) {
        rawOnInputChange(event, value, reason);
      }
      if (async === 'FILTERING' && reason === 'input') {
        setInputValue(value);
      }
    },
    [rawOnInputChange, setInputValue, async]
  );

  useEffect(() => {
    if (async === 'FILTERING' && open) {
      loadOptions(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  return (
    <MuiAutocomplete<O, false, DisableClearable, FreeSolo>
      {...baseProps}
      options={options}
      loading={loading}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      filterOptions={filterOptions}
      onInputChange={handleInputChange}
    />
  );
};

const useAutocompleteOptionContentStyles = makeStyles(() => ({
  container: { width: '100%', display: 'flex', flexDirection: 'column' },
  text: { textOverflow: 'ellipsis', width: '100%', overflow: 'hidden' }
}));

const AutocompleteOptionContentText = React.memo<React.ComponentProps<typeof Text>>(props => {
  const styles = useAutocompleteOptionContentStyles();
  return <Text className={styles.text} {...props} />;
});
const AutocompleteOptionContent = React.memo(({ label, caption }: { label: string; caption?: string }) => {
  const styles = useAutocompleteOptionContentStyles();
  return (
    <div className={styles.container}>
      <AutocompleteOptionContentText>{label}</AutocompleteOptionContentText>
      {caption && (
        <AutocompleteOptionContentText color="description" level="caption">
          {caption}
        </AutocompleteOptionContentText>
      )}
    </div>
  );
});

export type AutocompleteSelectProps<O, DisableClearable extends boolean | undefined = undefined> = Omit<
  AutocompleteBaseProps<O, DisableClearable>,
  'autoComplete' | 'freeSolo' | 'noOptionsText'
> &
  AutocompleteAsyncProps<O>;
export const AutocompleteSelect = <O, DisableClearable extends boolean | undefined = undefined>(
  props: AutocompleteSelectProps<O, DisableClearable>
) => {
  return <Autocomplete<O, DisableClearable> autoComplete={false} noOptionsText="候補はありません。" {...props} />;
};

export type AutocompleteInputProps<O> = Omit<
  AutocompleteProps<O, true, true>,
  'autoComplete' | 'disableClearable' | 'freeSolo' | 'noOptionsText'
> &
  AutocompleteAsyncProps<O>;
export const AutocompleteInput = <O,>(props: AutocompleteInputProps<O>) => (
  <Autocomplete<O, true, true> autoComplete disableClearable freeSolo noOptionsText={null} {...props} />
);
