import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  items: Item[];
  selectedValues: string[];
  selectableAll: boolean;
  selectableLabel?: string;
  onChange: (v: string[]) => void;
}

interface State {
  selectedValues: string[];
}

interface Item {
  label: string;
  value: string;
}
export default class CheckboxMultiSelector extends React.Component<Props, State> {
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = { selectedValues: this.props.selectedValues };
    this.ref = React.createRef();
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const selectedValues = this.state.selectedValues;
    const index = this.state.selectedValues.findIndex(v => v === value);
    if (index >= 0) {
      selectedValues.splice(index, 1);
    } else {
      selectedValues.push(value);
    }

    this.setState({ selectedValues });
    this.props.onChange(selectedValues);
  }

  handleCheckAll() {
    const selectedValues: string[] = [];
    this.props.items.forEach(item => {
      selectedValues.push(item.value);
    });
    this.props.onChange(selectedValues);
    this.setState({ selectedValues });
  }

  handleClearAll() {
    const selectedValues: string[] = [];
    this.props.onChange(selectedValues);
    this.setState({ selectedValues });
  }

  render() {
    const { selectedValues } = this.state;
    return (
      <div>
        {this.props.selectableAll && (
          <div>
            <SelectAll onClick={() => this.handleCheckAll()}>
              {this.props.selectableLabel ? `全ての${this.props.selectableLabel}` : '全てを選択'}
            </SelectAll>{' '}
            | <SelectAll onClick={() => this.handleClearAll()}>全てクリア</SelectAll>
          </div>
        )}
        <ul>
          {this.props.items.map(item => (
            <li key={item.value}>
              <label>
                <input
                  type="checkbox"
                  value={item.value}
                  onChange={e => this.handleChange(e)}
                  checked={selectedValues.some(v => v === item.value)}
                />
                {item.label}
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const SelectAll = styled.a`
  font-size: 12px;
  color: #a7a7a7;
`;
