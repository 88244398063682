import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';
import { observable } from 'mobx';
import type { SendListItemArgs } from './send_list/send_list_item';
import { SendListItem } from './send_list/send_list_item';

export interface ShippingCostArgs {
  id: number;
  address: string;
  addressee: string;
  price?: number;
  billing_target?: string;
  shipped_at?: string;
  expected_arrived_at?: string;
  arrived_at?: string;
  original_price: number;
  shipping_type: number;
  tax_type_id: number;
  send_list_id: number;
  send_list_items: SendListItemArgs[];
  tracking_number: string;
  supplied_item_id?: number;
  payment_method_id?: number;
  memo?: string;
  start_time?: string;
}

export default class ShippingCost {
  readonly id: number;

  readonly address: string;

  readonly addressee: string;

  @observable
  billingTarget?: Moment;

  @observable
  shippedAt?: Moment;

  @observable
  arrivedAt?: Moment;

  @observable
  expectedArrivedAt?: Moment;

  @observable
  originalPrice: number;

  @observable
  shippingType: number;

  @observable
  price?: number;

  @observable
  taxTypeId?: number;

  @observable
  sendListId: number;

  @observable
  sendListItems: SendListItem[];

  @observable
  trackingNumber?: string;

  @observable
  suppliedItemId: number | null;

  @observable
  paymentMethodId: number | null;

  @observable
  memo?: string;

  readonly startTime?: Moment;

  constructor(args: ShippingCostArgs) {
    this.id = args.id;
    this.address = args.address;
    this.addressee = args.addressee;
    this.price = args.price;
    this.billingTarget = args.billing_target ? moment(args.billing_target) : undefined;
    this.shippedAt = args.shipped_at ? moment(args.shipped_at) : undefined;
    this.arrivedAt = args.arrived_at ? moment(args.arrived_at) : undefined;
    this.expectedArrivedAt = args.expected_arrived_at ? moment(args.expected_arrived_at) : undefined;
    this.originalPrice = args.original_price;
    this.shippingType = args.shipping_type ? args.shipping_type : 1;
    this.taxTypeId = 2; // 10%
    this.sendListId = args.send_list_id;
    this.sendListItems = args.send_list_items.map(item => new SendListItem(item));
    this.trackingNumber = args.tracking_number;
    this.suppliedItemId = args.supplied_item_id || null;
    this.paymentMethodId = args.payment_method_id || null;
    this.memo = args.memo;
    this.startTime = args.start_time ? moment(args.start_time) : undefined;
  }

  submitParams() {
    return {
      id: this.id,
      price: this.price,
      billing_target: this.billingTarget,
      shipped_at: this.shippedAt || moment(),
      arrived_at: this.arrivedAt,
      expected_arrived_at: this.expectedArrivedAt || moment().add(1, 'days'),
      original_price: this.originalPrice,
      shipping_type: this.shippingType,
      tax_type_id: this.taxTypeId,
      tracking_number: this.trackingNumber,
      supplied_item_id: this.suppliedItemId,
      payment_method_id: this.paymentMethodId,
      memo: this.memo
    };
  }

  get isDeletable(): boolean {
    const noAddressee = !this.addressee || this.addressee === '　御中';
    return !this.address && noAddressee && !this.trackingNumber && !this.price && !this.originalPrice;
  }
}
