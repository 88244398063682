import React, { useState } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import type HotelWeight from '@this/domain/hotel_weight';
import { Fetcher, HTTPError } from '@this/src/util';
import Notification from '../../../notification';

type Props = {
  weights: HotelWeight[];
};

const HotelWeightsForm: React.FC<Props> = ({ weights }) => {
  const [errors, setErrors] = useState<string[]>([]);

  const updateHotelWeight = async (w: HotelWeight) => {
    setErrors([]);
    try {
      await Fetcher.put(`/admin/repository_display_adjustment/hotel_weights/${w.id}.json`, w.submitParams());
      Notification.success('ホテル表示重み付けを更新しました');
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400) {
        const permissionMessage = e.response.data.message;
        const validationErrors = e.response.data.errors;
        setErrors(validationErrors || [permissionMessage]);
      } else {
        setErrors(['通信エラーが発生しました。時間をおいて再度お試しください。']);
      }
    }
  };

  return (
    <>
      {weights.map((w, i) => (
        <Form
          key={i}
          onSubmit={e => {
            e.preventDefault();
            updateHotelWeight(w);
          }}
        >
          <Label htmlFor={w.key}>{w.keyText()}</Label>
          <Input
            id={w.key}
            type="number"
            step="0.1"
            value={w.weight}
            onChange={e => {
              w.weight = Number(e.target.value);
            }}
          />
          <button type="submit">更新</button>
        </Form>
      ))}
      {!_.isEmpty(errors) &&
        errors.map((e, i) => (
          <p key={i} className="error">
            {e}
          </p>
        ))}
    </>
  );
};

const Form = styled.form`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const Label = styled.label`
  width: 200px;
`;

const Input = styled.input`
  width: 100px !important;
  margin: 0 10px !important;
`;

export default observer(HotelWeightsForm);
