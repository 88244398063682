import React from 'react';

import ErrorBoundary from '@this/shared/error_boundary/error_boundary';
import { Loading } from '@this/components/shared/ui/feedbacks/loading';

import TravelerCost from '../../../../domain/traveler_cost';
import type { ApproveItemsResponse } from '../../../../domain/reserve_info';
import type { SearchParams } from './cost_analysis_search_area';
import CostAnalysisSearchArea from './cost_analysis_search_area';

const { StickyTable, Row, Cell } = require('react-sticky-table');

interface Props {}

interface State {
  costs: TravelerCost[];
  approveListHeader: string[];
  approveListValue: { [key: number]: { [key: string]: string } };
  loading: boolean;
}

interface CostAnalysisArgs {
  address: string;
  bill_amount: number;
  canceled_at: string;
  charging_department_code: number;
  charging_department_name: number;
  charging_department_shareName: number;
  department_code: string;
  department_name: string;
  employee_number: string;
  end_at: string;
  fee: number;
  hotel_price_limit_diff: number;
  internal_number: string;
  minus_amount: number;
  ordered_at: string;
  payment_type: string;
  private_use_desc: string;
  project_code: string;
  project_name: string;
  project_share_name: string;
  start_at: string;
  stay_days: number;
  summary: string;
  ticket_destination: string;
  travel_price: number;
  traveler: string;
  trip_id: number;
  trip_place: string;
  trip_purpose: string;
  trip_user: string;
  type: string;
  reserver_employee_number: number;
  hotel_type: string;
  used_at: string;
  approve_items: { [key: string]: string };
  grade_name: string;
}

interface CostAnalysisResponse {
  costs: CostAnalysisArgs[];
}

class CostAnalysis extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      costs: [],
      loading: false,
      approveListHeader: [],
      approveListValue: {}
    };
  }

  async fetchCostAnalysis(params: SearchParams): Promise<void> {
    this.setState({ loading: true });
    await this.fetchApproveItems();
    return utils
      .jsonPromise<CostAnalysisResponse>('/organization/cost_analysis.json', params)
      .then(result => {
        this.setState({
          loading: false,
          costs: result.costs.map(raw => new TravelerCost(raw))
        });
        this.analizeApploveItems();
      })
      .catch(e => {
        this.setState({ loading: false });
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  static downloadCsv(params: SearchParams) {
    location.href = `/organization/cost_analysis.csv?${utils.toParams(params)}`;
  }

  async fetchApproveItems() {
    await utils
      .jsonPromise<ApproveItemsResponse>(`/organization/approve_items/display.json`, {
        all_exist: true
      })
      .then(
        result => {
          const approveListHeader: string[] = [];
          result.approve_items.forEach(approveItem => {
            const keyname = approveItem.user_display_name;
            const cameledKeyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);
            if (approveItem.data_type === 'list') {
              approveListHeader.push(`${approveItem.id}${cameledKeyname}:名称`);
              approveListHeader.push(`${approveItem.id}${cameledKeyname}:コード`);
            } else {
              approveListHeader.push(approveItem.id + cameledKeyname);
            }
          });
          this.setState({ approveListHeader });
        },
        _error => {
          throw _error;
        }
      )
      .catch(e => {
        throw e;
      });
  }

  analizeApploveItems() {
    if (this.state.costs && this.state.costs.length > 0 && this.state.costs[0].approveItems) {
      const approveListValue = this.state.approveListValue;
      this.state.costs.forEach(cost => {
        if (cost.approveItems) {
          const value: { [key: string]: string } = {};
          Object.keys(cost.approveItems).forEach(k => {
            const key = k
              .replace(/^approveItem/, '')
              .replace(/Name$/, ':名称')
              .replace(/Code$/, ':コード');
            value[key] = cost.approveItems[k];
          });
          approveListValue[cost.tripId] = value;
        }
      });
      this.setState({ approveListValue });
    }
  }

  convertHeaderDisplay(header: string) {
    return header.replace(/^[0-9]{1,10}/, '');
  }

  render() {
    const { costs, loading } = this.state;

    return (
      <ErrorBoundary>
        <div>
          <div className="organization-analysis__wrap">
            <div className="organization-analysis__title">出張者コスト分析</div>
            <CostAnalysisSearchArea
              onSearch={params => this.fetchCostAnalysis(params)}
              onCsvDownload={params => CostAnalysis.downloadCsv(params)}
              loading={loading}
            />
            <div className="cost-analysis__list">
              {loading ? (
                <Loading />
              ) : (
                <div className="sticky__table__div">
                  <StickyTable className="cost-analysis__list__table">
                    <Row>
                      <Cell className="cost-analysis__list__th">手配番号</Cell>
                      <Cell className="cost-analysis__list__th">プロジェクトコード</Cell>
                      <Cell className="cost-analysis__list__th">プロジェクト名</Cell>
                      <Cell className="cost-analysis__list__th">プロジェクト按分名</Cell>
                      <Cell className="cost-analysis__list__th">社内管理番号</Cell>
                      <Cell className="cost-analysis__list__th">予約日</Cell>
                      <Cell className="cost-analysis__list__th">利用開始日</Cell>
                      <Cell className="cost-analysis__list__th">利用終了日</Cell>
                      <Cell className="cost-analysis__list__th">キャンセル処理日</Cell>
                      <Cell className="cost-analysis__list__th">予約者</Cell>
                      <Cell className="cost-analysis__list__th">予約者社員番号</Cell>
                      <Cell className="cost-analysis__list__th">出張者</Cell>
                      <Cell className="cost-analysis__list__th">社員番号</Cell>
                      <Cell className="cost-analysis__list__th">部署コード</Cell>
                      <Cell className="cost-analysis__list__th">部署名</Cell>
                      <Cell className="cost-analysis__list__th">費用負担先部署コード</Cell>
                      <Cell className="cost-analysis__list__th">費用負担先部署名</Cell>
                      <Cell className="cost-analysis__list__th">費用負担先部署按分名</Cell>
                      {this.state.approveListHeader.map(header => (
                        <Cell className="cost-analysis__list__th" key={header}>
                          {this.convertHeaderDisplay(header)}
                        </Cell>
                      ))}
                      <Cell className="cost-analysis__list__th">出張先</Cell>
                      <Cell className="cost-analysis__list__th">目的</Cell>
                      <Cell className="cost-analysis__list__th">種別</Cell>
                      <Cell className="cost-analysis__list__th">ホテル区分</Cell>
                      <Cell className="cost-analysis__list__th">内容</Cell>
                      <Cell className="cost-analysis__list__th">支払い</Cell>
                      <Cell className="cost-analysis__list__th">出張代金</Cell>
                      <Cell className="cost-analysis__list__th">手配手数料</Cell>
                      <Cell className="cost-analysis__list__th">合計金額</Cell>
                      <Cell className="cost-analysis__list__th">返金</Cell>
                      <Cell className="cost-analysis__list__th">ホテル住所</Cell>
                      <Cell className="cost-analysis__list__th">送付先</Cell>
                      <Cell className="cost-analysis__list__th">プライベート利用</Cell>
                      <Cell className="cost-analysis__list__th">旅費規程エリア:国</Cell>
                      <Cell className="cost-analysis__list__th">旅費規程エリア:地方</Cell>
                      <Cell className="cost-analysis__list__th">旅費規程エリア:県</Cell>
                      <Cell className="cost-analysis__list__th">規程金額(1泊1室あたり)</Cell>
                      <Cell className="cost-analysis__list__th">宿泊日数</Cell>
                      <Cell className="cost-analysis__list__th">規程金額と実績との差額（実績 ー 規程金額）</Cell>
                      <Cell className="cost-analysis__list__th">役職</Cell>
                    </Row>
                    {costs.map((cost, index) => (
                      <Row key={index}>
                        <Cell className="cost-analysis__list__td">{cost.tripId}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.projectCode}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.projectName}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.projectShareName}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.internalNumber}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.orderedAt}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.startAt}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.endAt}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.canceledAt}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.tripUser}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.reserverEmployeeNumber}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.traveler}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.employeeNumber}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.departmentCode}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.departmentName}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.chargingDepartmentCode}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.chargingDepartmentName}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.chargingDepartmentShareName}</Cell>
                        {this.state.approveListHeader.map(header => (
                          <>
                            {this.state.approveListValue[cost.tripId] &&
                            this.state.approveListValue[cost.tripId][header] ? (
                              <Cell className="cost-analysis__list__td">
                                {this.state.approveListValue[cost.tripId][header]}
                              </Cell>
                            ) : (
                              <Cell className="cost-analysis__list__td" />
                            )}
                          </>
                        ))}
                        <Cell className="cost-analysis__list__td">{cost.tripPlace}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.tripPurpose}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.type}</Cell>
                        <Cell className="cost-analysis__list__td">
                          {cost.hotelType ? (cost.hotelType === 'domestic' ? '国内ホテル' : '海外ホテル') : null}
                        </Cell>
                        <Cell className="cost-analysis__list__td">{cost.summary}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.paymentType}</Cell>
                        <Cell className="cost-analysis__list__td">{utils.formatPrice(cost.travelPrice)}</Cell>
                        <Cell className="cost-analysis__list__td">{utils.formatPrice(cost.fee)}</Cell>
                        <Cell className="cost-analysis__list__td">{utils.formatPrice(cost.billAmount)}</Cell>
                        <Cell className="cost-analysis__list__td">{utils.formatPrice(cost.minusAmount)}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.address}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.ticketDestination}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.privateUseDesc}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.country}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.region}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.prefecture}</Cell>
                        <Cell className="cost-analysis__list__td">{utils.formatPrice(cost.hotelPriceLimit)}</Cell>
                        <Cell className="cost-analysis__list__td">{cost.stayDays}</Cell>
                        <Cell className="cost-analysis__list__td">
                          {utils.formatPrice(cost.hotelPriceLimitDiff)}
                        </Cell>
                        <Cell className="cost-analysis__list__td">{cost.gradeName}</Cell>
                      </Row>
                    ))}
                  </StickyTable>
                </div>
              )}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default CostAnalysis;
