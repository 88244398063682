class HotelIconMarker extends google.maps.OverlayView {
  location: google.maps.LatLng;

  content: HTMLDivElement | null;

  constructor(location: { lat: number; lng: number }) {
    super();
    this.location = new google.maps.LatLng(location.lat, location.lng);
    this.content = null;
  }

  draw() {
    if (!this.content) {
      const content = document.createElement('div');
      const image = document.createElement('img');
      image.style.objectFit = 'contain';
      image.style.width = '100%';
      image.style.height = '100%';
      image.src = '/images/hotel_icon_marker.png';
      content.appendChild(image);
      content.style.position = 'absolute';
      content.style.width = '33px';
      content.style.height = '44px';
      content.style.zIndex = '1';

      const panes = this.getPanes();
      if (!panes) {
        return;
      }

      panes.overlayLayer.appendChild(content);
      panes.overlayMouseTarget.appendChild(content);
      this.content = content;
    }
    const projection = this.getProjection();
    if (projection) {
      const point = projection.fromLatLngToDivPixel(this.location);
      if (point) {
        this.content.style.left = `${point.x - this.content.offsetWidth / 2}px`;
        this.content.style.top = `${point.y - this.content.offsetHeight}px`;
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onRemove() {}
}
export default HotelIconMarker;
