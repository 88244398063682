import styled, { withTheme } from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from '@this/shared/markdown/markdown';
import type { Kind } from '@this/domain/information';
import type DashbordInformationInterface from '@this/domain/dashboard_information_interface';

type Props = {
  info: DashbordInformationInterface;
  theme: { serviceName: string };
};

const kindText = (kind: Kind) => {
  const text = {
    other: 'その他',
    notification: 'お知らせ',
    update_function: '機能アップデート',
    reservation_note: '予約時の注意'
  };
  return text[kind];
};

const detailUrl = (info: DashbordInformationInterface) => {
  return info.type === 'organization' ? `/organization_informations/${info.id}` : `/informations/${info.id}`;
};

const description = (info: DashbordInformationInterface) => {
  const limit = 50;
  let desc = info.description.slice(0, limit);
  if (info.description.length > limit) desc = `${desc} ...`;
  return desc;
};

const InformationAreaItem: React.FC<Props> = ({ info, theme }) => {
  return (
    <Line>
      <WrapLink to={detailUrl(info)}>
        <Head>
          {info.type === 'organization' ? (
            <OrganizationLabel>社内</OrganizationLabel>
          ) : (
            <AdminLabel>{theme.serviceName === 'AI Travel' ? 'AI Travel' : 'システム'}</AdminLabel>
          )}
          {info.updatedAt.replace(/-/g, '/').replace(/(.*) (.*)/, '$1')} {kindText(info.kind)}
        </Head>
        <Body>
          <Markdown markdownText={description(info)} />
        </Body>
      </WrapLink>
    </Line>
  );
};

const Line = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
`;

const Head = styled.div`
  display: flex;
  font-weight: normal;
`;

const WrapLink = styled(Link)`
  padding: 0.5em 0;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  &:link,
  &:visited,
  &a:active {
    color: inherit;
  }
`;

const Body = styled.div`
  width: 100%;
  word-wrap: break-word;
`;

const OrganizationLabel = styled.p`
  background-color: #ede4cf;
  color: #77663d;
  font-size: 10px;
  text-align: center;
  margin: 0 5px 0 0;
  padding: 2px 4px;
  width: 60px;
`;

const AdminLabel = styled.p`
  background-color: #eee;
  color: #374151;
  font-size: 10px;
  text-align: center;
  margin: 0 5px 0 0;
  padding: 2px 4px;
  width: 60px;
`;

export default withTheme(InformationAreaItem);
