import React, { useEffect, useState } from 'react';
import { styled } from '@this/constants/themes';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { Grid } from '@material-ui/core';
import { Button } from '@this/components/shared/ui/inputs/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import FlightSeatMap from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map';
import FlightSeatMapLegend from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map_legend';
import FlightSegment from '@this/domain/flight/flight_segment';
import type { FlightSegmentJson } from '@this/domain/select_repository';
import type SeatMapFacility from '@this/domain/flight/seat_map_facility';
import { Fetcher } from '@this/src/util';

interface Props {
  show: boolean;
  trip: any;
  onHide: () => void;
}

type Response = {
  flights: FlightSegmentJson[];
};

const FlightSeatMapModal: React.FC<Props> = ({ show, trip, onHide }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [flightSegments, setFlightSegments] = useState<FlightSegment[]>([]);
  const [selectedFlightSegment, setSelectedFlightSegment] = useState<FlightSegment | null>(null);
  const [selectedTravelerKey, setSelectedTravelerKey] = useState<string | null>(null);
  const [selectedSeatMapFacility, setSelectedSeatMapFacility] = useState<SeatMapFacility | null>(null);
  const [isReserving, setIsReserving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleShowFlightSeatMap = (fs: FlightSegment, travelerKey: string) => {
    setSelectedFlightSegment(fs);
    setSelectedTravelerKey(travelerKey);
  };

  const handleHideFlightSeatMap = () => {
    setSelectedFlightSegment(null);
    setSelectedTravelerKey(null);
    setSelectedSeatMapFacility(null);
  };

  const handleCloseModal = () => {
    handleHideFlightSeatMap();
    onHide();
  };

  const handleClickSeatMapFacility = (f: SeatMapFacility) => {
    if (f.selected) {
      // 現在選ばれている席がクリックされた場合
      f.selected = false;
      setSelectedSeatMapFacility(null);
    } else {
      // 現在選ばれていない席がクリックされた場合
      if (selectedSeatMapFacility) selectedSeatMapFacility.selected = false;
      f.selected = true;
      setSelectedSeatMapFacility(f);
    }
  };

  const message = (): string => {
    if (selectedSeatMapFacility) {
      return `選択した座席：${selectedSeatMapFacility.seatCode}`;
    }
    return '座席を選択してください';
  };

  const fetchFlightSegments = async () => {
    setIsLoading(true);
    const res = await Fetcher.get<Response>(`/trips/${trip.id}/flight_seat_maps.json`);
    const flightSegments = res.flights.map(f => new FlightSegment(f));
    setFlightSegments(flightSegments);
    setIsLoading(false);
  };

  const reserveSeat = async () => {
    if (selectedFlightSegment && selectedTravelerKey && selectedSeatMapFacility) {
      try {
        setIsReserving(true);
        setErrorMessage(null);
        const id = encodeURIComponent(selectedFlightSegment.api_related_data.key);
        const params = {
          traveler_key: selectedTravelerKey,
          seat_code: selectedSeatMapFacility.seatCode
        };
        await Fetcher.put(`/trips/${trip.id}/flight_seat_maps/${id}.json`, params);
        handleCloseModal();
      } catch {
        setErrorMessage('通信エラーが発生しました。時間をおいて再度お試しください。');
      } finally {
        setIsReserving(false);
      }
    }
  };

  useEffect(() => {
    if (show) {
      fetchFlightSegments();
    }
  }, [show]);

  return (
    <Modal open={show} onClose={handleCloseModal} size="large">
      {selectedFlightSegment && selectedTravelerKey ? (
        <>
          <ModalHeader>
            <AirlineImg src={utils.flightIconPath(selectedFlightSegment.name)} />
            <AirlineName>{`${selectedFlightSegment.carrier_name} ${selectedFlightSegment.name}`}</AirlineName>
            <AirlineName>{`${selectedFlightSegment.from.name} → ${selectedFlightSegment.to.name}`}</AirlineName>
          </ModalHeader>
          <ModalBody>
            <FlightSeatMap
              flightSegment={selectedFlightSegment}
              travelerKey={selectedTravelerKey}
              trip_id={trip.id}
              onClickFacility={handleClickSeatMapFacility}
            />
          </ModalBody>
          <ModalFooter>
            <FooterContent>
              <LegendWrapper>
                <FlightSeatMapLegend />
              </LegendWrapper>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs>
                  <Message>{message()}</Message>
                  {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </Grid>
                <Grid item xs container spacing={2} justify="flex-end">
                  <Grid item>
                    <Button disabled={isReserving || !selectedSeatMapFacility} onClick={() => reserveSeat()}>
                      席を指定する
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={handleHideFlightSeatMap}>キャンセル</Button>
                  </Grid>
                </Grid>
              </Grid>
            </FooterContent>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader>座席指定する航空便を選択してください</ModalHeader>
          <ModalBody>
            {isLoading ? (
              <SimpleLoading />
            ) : flightSegments.length > 0 ? (
              <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
                {flightSegments.map(fs => (
                  <Grid
                    item
                    xs={12}
                    key={fs.flight_number}
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <AirlineImg src={utils.flightIconPath(fs.name)} />
                      <AirlineName>{`${fs.carrier_name} ${fs.name}`}</AirlineName>
                      <AirlineName>{`${fs.from.name} → ${fs.to.name}`}</AirlineName>
                    </Grid>
                    <Grid item xs={4} container direction="column" spacing={2}>
                      {fs.travelportTravelers.map(t => (
                        <Grid
                          item
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          spacing={2}
                          key={t.key}
                        >
                          <Grid item>
                            <p>{t.name}</p>
                          </Grid>
                          <Grid item>
                            {t.seatCode ? (
                              <p>{t.seatCode}</p>
                            ) : (
                              <Button onClick={() => handleShowFlightSeatMap(fs, t.key)}>座席指定</Button>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <p>座席指定可能な航空便がありません</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onHide}>キャンセル</Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

const Message = styled.p`
  font-weight: bold;
`;

const ErrorMessage = styled(Message as any)`
  color: red;
`;

const AirlineImg = styled.img`
  height: 1em;
  margin-right: 1em;
`;

const AirlineName = styled.span`
  margin-right: 1em;
`;

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const LegendWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

export default FlightSeatMapModal;
