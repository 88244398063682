import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import {
  Body,
  BodyDescription,
  BodyTitle,
  Border,
  HeaderBody,
  HeaderDivider,
  HeaderWrap,
  PageTitle
} from './shared/wrappers';
import TripReportReport from './trip_report_report';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

export const TripReportShow: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const { id } = useParams<{ id: string }>();
  const tripReports = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);
  const tripReport = tripReports.currentTripReport;

  const fetchTripReports = useCallback(() => {
    tripReports.getShow(id);
    tripReports.getTrips(id);
  }, [id]);

  useEffect(() => {
    fetchTripReports();
  }, [fetchTripReports]);

  return (
    <TripReportContext.Provider value={tripReports}>
      <HeaderWrap>
        <HeaderBody>
          <PageTitle>申請内容を確認</PageTitle>
          <BackLink color="primary" to="/trip_report">
            {'<前の画面に戻る'}
          </BackLink>
          <HeaderDivider />
        </HeaderBody>
      </HeaderWrap>
      <Body>
        <BodyTitle>申請タイトル：{tripReport.name}</BodyTitle>
        <BodyDescription>下記、各プロジェクトの承認者へ出張報告をします</BodyDescription>
        <Border />
        <TripReportReport />
      </Body>
    </TripReportContext.Provider>
  );
};

const BackLink = styled(Link)`
  margin-bottom: 4px;
`;
