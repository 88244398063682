import React, { useState } from 'react';
import { observer } from 'mobx-react';
import A from '@this/components/shared/atoms/a';
import {
  MappingFormWrapper,
  MappingFormLabel,
  MappingFormDetailArea,
  MappingFormDetailIndentedArea,
  MappingFormDetailItemArea,
  MappingFormDetailSelectArea,
  MappingFormDetailLinkArea
} from '@this/components/trips_management/trips/request_approval_modal/mapping_form.style';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import type { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import type ArrangementRequestWfInfo from '@this/src/domain/arrangement_request_info/arrangement_request_wf_info';

interface Props {
  wf: ArrangementRequestWfInfo;
}

const ExpensesAccountTypeMappingForm: React.FC<Props> = ({ wf }) => {
  const [showDetail, setShowDetail] = useState(wf.isForExpensesAccountTypeDetail());

  const handleSelectExpenseDetail = (
    changingMapping: OrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    changingMapping.setExpensesAccountTypeId(Number(option?.value));
  };

  const handleSelect = (selected: FilterableSelectorOption | null): void => {
    wf.orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => handleSelectExpenseDetail(mapping, selected));
    });
  };

  const createOption = (expense: ExpensesAccountType) => ({
    label: expense.codeAndNameOneLine(),
    value: expense.getIdStr(),
    matcher: expense.codeAndNameOneLine()
  });

  const handleShowExpenseDetailClick = (value: boolean) => {
    if (!value) wf.resetMappingsExpensesAccountTypeId();
    setShowDetail(value);
    wf.showExpensesAccountTypeDetail = value;
  };

  const expenseDetailOption = (): FilterableSelectorOption[] => {
    const expensesOption: FilterableSelectorOption[] = wf.expensesAccountTypes.map(d => {
      return {
        label: d.codeAndNameOneLine(),
        value: d.getIdStr(),
        matcher: d.codeAndNameOneLine()
      };
    });
    return expensesOption;
  };

  return (
    <MappingFormWrapper>
      {showDetail ? (
        <MappingFormDetailArea>
          {wf.orderItems.map((item, item_index) => (
            <div key={`item${item.id}`}>
              <MappingFormLabel>
                {item_index + 1}. {item?.categoryStr()}
              </MappingFormLabel>
              <MappingFormDetailIndentedArea>
                {item.travelerInformations.map((traveler: any) => (
                  <MappingFormDetailItemArea key={traveler.id}>
                    <MappingFormLabel>{traveler.name}</MappingFormLabel>
                    <MappingFormDetailSelectArea>
                      <FilterableSelector
                        placeholder="勘定科目を検索"
                        options={expenseDetailOption()}
                        selected={{
                          label:
                            item.orderItemMappings
                              .findByTravelerInformationAndOrderItem(traveler.id, item.id)
                              ?.expensesAccountTypeDescription(wf.expensesAccountTypes) || '',
                          value: '',
                          matcher: ''
                        }}
                        onSelect={option => {
                          const mapping = item.orderItemMappings.findByTravelerInformationAndOrderItem(
                            traveler.id,
                            item.id
                          );
                          if (mapping) {
                            handleSelectExpenseDetail(mapping, option);
                          }
                        }}
                      />
                    </MappingFormDetailSelectArea>
                  </MappingFormDetailItemArea>
                ))}
              </MappingFormDetailIndentedArea>
            </div>
          ))}
        </MappingFormDetailArea>
      ) : (
        <div>
          <FilterableSelector
            placeholder="勘定科目を検索"
            options={expenseDetailOption()}
            selected={wf.expensesAccountType ? createOption(wf.expensesAccountType) : null}
            onSelect={handleSelect}
          />
        </div>
      )}
      {showDetail ? (
        <MappingFormDetailLinkArea>
          <A onClick={() => handleShowExpenseDetailClick(false)}>通常設定へ</A>
        </MappingFormDetailLinkArea>
      ) : (
        <MappingFormDetailLinkArea>
          <A onClick={() => handleShowExpenseDetailClick(true)}>詳細設定へ</A>
        </MappingFormDetailLinkArea>
      )}
    </MappingFormWrapper>
  );
};

export default observer(ExpensesAccountTypeMappingForm);
