import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Fetcher } from '@this/src/util';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { Link } from '@this/components/shared/ui/navigations/link';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@this/shared/ui/data_displays/table';
import { Button } from '@this/shared/ui/inputs/button';
import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
import {
  InvoiceCustomFormatTitle,
  InvoiceCustomFormatDescription
} from '@this/components/organization/invoice_custom_formats/invoice_custom_formats.style';
import InvoiceCustomFormat from '@this/domain/invoice_custom_format/invoice_custom_format';
import type { InvoiceCustomFormatArgs } from '@this/domain/invoice_custom_format/invoice_custom_format';

interface Response {
  invoice_custom_formats: InvoiceCustomFormatArgs[];
}

const InvoiceCustomFormats = observer(() => {
  const [loading, setLoading] = useState(false);
  const [invoiceCustomFormats, setInvoiceCustomFormats] = useState<InvoiceCustomFormat[]>([]);

  const fetchInvoiceCustomFormats = async () => {
    setLoading(true);
    const response = await Fetcher.get<Response>('/organization/invoice_custom_formats.json');
    setInvoiceCustomFormats(response.invoice_custom_formats.map(raw => new InvoiceCustomFormat(raw)));
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoiceCustomFormats();
  }, []);

  const handleRemoveFormat = async (format: InvoiceCustomFormat) => {
    if (confirm('本当に削除しますか？')) {
      await Fetcher.delete(`/organization/invoice_custom_formats/${format.id}.json`);
      fetchInvoiceCustomFormats();
    }
  };

  const handleToggleEnabled = async (format: InvoiceCustomFormat) => {
    await Fetcher.put(`/organization/invoice_custom_formats/${format.id}.json`, { disabled: !format.disabled });
    fetchInvoiceCustomFormats();
  };

  return (
    <>
      <OrganizationTitle>請求書・明細</OrganizationTitle>
      <OrganizationBody>
        <Link href="/organization/invoices">&lt; 戻る</Link>
        <InvoiceCustomFormatTitle>カスタムCSV一覧</InvoiceCustomFormatTitle>
        <InvoiceCustomFormatDescription>新規でカスタムCSVを作成します。</InvoiceCustomFormatDescription>
        <Button href="/organization/invoice_custom_formats/new">新規作成</Button>
        {loading ? (
          <Loading />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>最終更新日時</TableCell>
                <TableCell>ファイル名</TableCell>
                <TableCell>有効</TableCell>
                <TableCell>編集</TableCell>
                <TableCell>削除</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceCustomFormats.map(invoiceCustomFormat => (
                <TableRow key={invoiceCustomFormat.id}>
                  <TableCell>{invoiceCustomFormat.updatedAt?.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  <TableCell>{invoiceCustomFormat.name}</TableCell>
                  <TableCell>
                    <Link
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        handleToggleEnabled(invoiceCustomFormat);
                      }}
                    >
                      {invoiceCustomFormat.disabled ? '無効' : '有効'}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`/organization/invoice_custom_formats/${invoiceCustomFormat.id}/edit`}>編集</Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="danger"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        handleRemoveFormat(invoiceCustomFormat);
                      }}
                    >
                      削除
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </OrganizationBody>
    </>
  );
});

export default InvoiceCustomFormats;
