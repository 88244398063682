import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type AccountTypeMapping from '@this/domain/account_type_mapping';
import type ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import type Department from '@this/domain/department/department';
import Select from '@this/shared/atoms/select';
import { Checkbox } from '@this/shared/ui/inputs/checkbox';

type Props = {
  mappings: AccountTypeMapping[];
  accountTypes: ExpensesAccountType[];
  departments: Department[];
};

const AccountTypeMappingSection: React.FC<Props> = ({ mappings, accountTypes, departments }) => {
  const groupedMappings = _.groupBy(mappings, m => m.costTypeCategory);
  const domesticMappings = groupedMappings.domestic || [];
  const foreignMappings = groupedMappings.foreign || [];
  const commonMappings = groupedMappings.common || [];

  const table = (mappings: AccountTypeMapping[]) => (
    <Table>
      <thead>
        <tr>
          <th />
          <th>借方</th>
          <th>貸方</th>
          <th>部門</th>
        </tr>
      </thead>
      <tbody>
        {mappings.map((m, i) => (
          <tr key={i}>
            <td>{m.costTypeName}</td>
            <td>
              <Select
                value={m.debtAccountTypeId}
                onChange={e => {
                  m.debtAccountTypeId = Number(e.target.value);
                }}
              >
                <option value="">勘定科目を設定する</option>
                {accountTypes.map((t, i) => (
                  <option key={i} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </td>
            <td>
              <Select
                value={m.creditAccountTypeId}
                onChange={e => {
                  m.creditAccountTypeId = Number(e.target.value);
                }}
              >
                <option value="">勘定科目を設定する</option>
                {accountTypes.map((t, i) => (
                  <option key={i} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </td>
            <td>
              {['システム利用料', '割引き'].indexOf(m.costTypeName) >= 0 && (
                <Select
                  value={m.departmentId || ''}
                  onChange={e => {
                    if (e.target.value === '') {
                      m.departmentId = undefined;
                    } else {
                      m.departmentId = Number(e.target.value);
                    }
                  }}
                >
                  <option value="">-</option>
                  {departments.map((d, i) => (
                    <option key={i} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Select>
              )}
              {m.costTypeName === '郵送費' && (
                <>
                  <Checkbox
                    checked={m.shareWithTravelers}
                    onChange={e => {
                      m.shareWithTravelers = e.target.checked;
                    }}
                  >
                    出張者に配賦
                  </Checkbox>
                  {!m.shareWithTravelers && (
                    <Select
                      value={m.departmentId || ''}
                      onChange={e => {
                        if (e.target.value === '') {
                          m.departmentId = undefined;
                        } else {
                          m.departmentId = Number(e.target.value);
                        }
                      }}
                    >
                      <option value="">-</option>
                      {departments.map((d, i) => (
                        <option key={i} value={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <>
      <SubSection>
        <SubSectionTitle>
          <SubSectionTitleCircle />
          国内
        </SubSectionTitle>
        <Wrap>{table(domesticMappings)}</Wrap>
      </SubSection>
      <SubSection>
        <SubSectionTitle>
          <SubSectionTitleCircle />
          海外
        </SubSectionTitle>
        <Wrap>{table(foreignMappings)}</Wrap>
      </SubSection>
      <SubSection>
        <SubSectionTitle>
          <SubSectionTitleCircle />
          共通
        </SubSectionTitle>
        <Wrap>{table(commonMappings)}</Wrap>
      </SubSection>
    </>
  );
};

const SubSection = styled.section`
  position: relative;
  margin-top: 16px;
`;

const SubSectionTitle = styled.h4`
  position: relative;
  width: 174px;
  margin-left: 16px;
  margin-bottom: 0;
  height: 20px;
  padding-top: 3px;
  padding-left: 28px;
  display: flex; /* tooltip用 */

  ::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 9px;
    z-index: 1;
    width: 1px;
    height: 11px;
    border: solid 1px transparent;
    border-left-color: #fff;
  }
  ::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    z-index: 1;
    width: 6px;
    height: 4px;
    border: solid 4px transparent;
    border-left: solid 6px #fff;
  }
`;

const SubSectionTitleCircle = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 20px;
  width: 20px;
  background: ${props => props.theme.selectArrowColor};
  border-radius: 50%;
`;

const Wrap = styled.div`
  padding: 0 40px;
`;

const Table = styled.table`
  th,
  td {
    padding: 10px;
  }
`;

export default observer(AccountTypeMappingSection);
