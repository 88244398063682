import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import type { Moment } from 'moment';
import { Fetcher } from '@this/src/util';
import Notification from '@this/src/notification';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { JalReservationTotalArgs } from '@this/src/domain/arrangement/jal_reservation_total';
import JalReservationTotal, {
  convertJalReservationTotal
} from '@this/src/domain/arrangement/jal_reservation_total';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import TotalSearchArea from './total_search_area';
import AirAutoJalReservationTotal from './total_result_table';

interface TotalResponse {
  jal_reservations: JalReservationTotalArgs[];
  arrangers: ArrangerArgs[];
  current_arranger: ArrangerArgs;
  total_count: number;
  total_page: number;
}

const Total = observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [jalReservationTotals, setJalReservationTotals] = useState<JalReservationTotal[]>([]);
  const [reservationDateFrom, setReservationDateFrom] = useState<Moment | undefined>(
    utils.getParamMoment('reservation_date_from') ?? moment().subtract(1, 'day')
  );
  const [reservationDateTo, setReservationDateTo] = useState<Moment | undefined>(
    utils.getParamMoment('reservation_date_to') ?? moment().subtract(1, 'day')
  );

  const fetchTodos = useCallback(() => {
    setIsLoading(true);
    setJalReservationTotals([]);

    const params = {
      reservation_date_from: reservationDateFrom?.format('YYYY-MM-DD'),
      reservation_date_to: reservationDateTo?.format('YYYY-MM-DD')
    };

    Fetcher.get<TotalResponse>('/arrangement/auto_reservation_inventory/jal_booking/total.json', params)
      .then(res => {
        setJalReservationTotals(
          res.jal_reservations.map(args => new JalReservationTotal(convertJalReservationTotal(args)))
        );
      })
      .catch(e => {
        Notification.error('データの取得に失敗しました');
        utils.sendErrorObject(e);
      })
      .finally(() => setIsLoading(false));
  }, [reservationDateFrom, reservationDateTo]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('tab', 'AUTO_RESERVATION_INVENTORY');
    searchParams.set('jal_booking_tab', 'TOTAL');
    if (reservationDateFrom) {
      searchParams.set('reservation_date_from', reservationDateFrom?.format('YYYY-MM-DD') ?? '');
    }
    if (reservationDateTo) {
      searchParams.set('reservation_date_to', reservationDateTo?.format('YYYY-MM-DD') ?? '');
    }
    history.replace({ search: searchParams.toString() });
  }, [history, reservationDateFrom, reservationDateTo]);

  return (
    <>
      <TotalSearchArea
        reservationDateFrom={reservationDateFrom}
        reservationDateTo={reservationDateTo}
        setReservationDateFrom={setReservationDateFrom}
        setReservationDateTo={setReservationDateTo}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <AirAutoJalReservationTotal jalReservationTotals={jalReservationTotals} fetchTodos={fetchTodos} />
      )}
    </>
  );
});

export default Total;
