import React from 'react';
import { TextField } from '../../shared/form_elements/form_elements';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

interface ResetPasswordResponse {
  redirect: string;
}

export interface ResetPasswordProps {
  token: string;
}

interface State {
  newPassword: string;
  newPasswordConfirmation: string;
  resetSuccess?: string;
  resetErrors: string[];
  resetting: boolean;
  resetPasswordToken: string;
}

export default class ResetPassword extends React.Component<ResetPasswordProps, State> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      resetErrors: [],
      resetting: false,
      resetPasswordToken: props.token
    };
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ resetting: true, resetErrors: [] });
    try {
      const params = this.params();
      const result = await utils.jsonPromise<ResetPasswordResponse>('/biztra/users/password', params, 'PUT');
      this.setState(
        {
          resetting: false,
          resetErrors: [],
          resetSuccess: 'リセットに成功しました。ログインページにジャンプします...'
        },
        () => {
          setTimeout(() => {
            location.href = result.redirect;
          }, 1000);
        }
      );
    } catch (e) {
      if (e.responseJSON && e.responseJSON.errors) {
        this.setState({
          resetting: false,
          resetErrors: e.responseJSON.errors
        });
      } else {
        this.setState({
          resetting: false,
          resetErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。']
        });
        utils.sendErrorObject(e);
      }
    }
  };

  params() {
    return {
      'user[new_password]': this.state.newPassword,
      'user[new_password_confirmation]': this.state.newPasswordConfirmation,
      'user[reset_password_token]': this.state.resetPasswordToken
    };
  }

  handleNewPasswordChange(value: string) {
    this.setState({ newPassword: value });
  }

  handleNewPasswordConfirmationChange(value: string) {
    this.setState({ newPasswordConfirmation: value });
  }

  render() {
    const { newPassword, newPasswordConfirmation, resetting, resetErrors, resetSuccess } = this.state;
    const classBase = 'expenses-users-signup';
    return (
      <div className={classBase}>
        <div className={`${classBase}__content`}>
          <form onSubmit={this.handleSubmit}>
            <TextField
              label="新しいパスワード（必須）"
              value={newPassword}
              example=""
              type="password"
              onChange={value => this.handleNewPasswordChange(value)}
            />
            <TextField
              label="確認（必須）"
              value={newPasswordConfirmation}
              example=""
              type="password"
              onChange={value => this.handleNewPasswordConfirmationChange(value)}
            />
            {resetErrors.length > 0 && (
              <div className="expenses-users-signup__message">
                {resetErrors.map((e, i) => (
                  <p key={i} className="error">
                    {e}
                  </p>
                ))}
              </div>
            )}
            {resetting ? (
              <SimpleLoading />
            ) : resetSuccess ? (
              <p>{resetSuccess}</p>
            ) : (
              <input type="submit" value="リセット" disabled={resetting} />
            )}
          </form>
        </div>
      </div>
    );
  }
}
