import React, { useCallback } from 'react';
import SettingTable from './setting_table';
import ResultTable from './result_table';
import TaskTable from './task_table';

interface NewVersionTableProps {
  mode: string;
  onModeChange: (mode: string) => void;
  statusFilter: string;
  onStatusFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sourceFilter: string;
  onSourceFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  fromAirportFilter: string;
  onFromAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  toAirportFilter: string;
  onToAirportChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  targetDateFilter: string;
  onTargetDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NewVersionTable: React.FC<NewVersionTableProps> = ({
  mode,
  onModeChange,
  statusFilter,
  onStatusFilterChange,
  sourceFilter,
  onSourceFilterChange,
  fromAirportFilter,
  onFromAirportChange,
  toAirportFilter,
  onToAirportChange,
  targetDateFilter,
  onTargetDateChange
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onModeChange(e.target.value);
    },
    [onModeChange]
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        <label>
          <input type="radio" name="mode" value="setting" checked={mode === 'setting'} onChange={handleChange} />
          クロール設定
        </label>
        <label>
          <input type="radio" name="mode" value="task" checked={mode === 'task'} onChange={handleChange} />
          タスク管理
        </label>
        <label>
          <input type="radio" name="mode" value="result" checked={mode === 'result'} onChange={handleChange} />
          データ管理
        </label>
      </div>
      {mode === 'setting' && <SettingTable />}
      {mode === 'task' && (
        <TaskTable
          statusFilter={statusFilter}
          onStatusFilterChange={onStatusFilterChange}
          sourceFilter={sourceFilter}
          onSourceFilterChange={onSourceFilterChange}
          fromAirportFilter={fromAirportFilter}
          onFromAirportChange={onFromAirportChange}
          toAirportFilter={toAirportFilter}
          onToAirportChange={onToAirportChange}
        />
      )}
      {mode === 'result' && (
        <ResultTable
          sourceFilter={sourceFilter}
          onSourceFilterChange={onSourceFilterChange}
          fromAirportFilter={fromAirportFilter}
          onFromAirportChange={onFromAirportChange}
          toAirportFilter={toAirportFilter}
          onToAirportChange={onToAirportChange}
          targetDateFilter={targetDateFilter}
          onTargetDateChange={onTargetDateChange}
        />
      )}
    </>
  );
};

export default NewVersionTable;
