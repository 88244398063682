import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { Approver } from './approver';
import type { ApproverCandidateArgs } from './approver';
import type { TripReportWorkflowStyle } from '../organization/organization2';
import User from '../user/user';
import type UserJson from '../user/user_json';

export interface Stage {
  stage: number;
  approvers: (User | null)[];
}

export type ApprovalStatus = 'applied' | 'approved' | 'rejected' | 'canceled' | 'queued' | 'stoped';

export const approvalStatuses: ApprovalStatus[] = ['applied', 'approved', 'rejected', 'canceled', 'queued'];

export type WorkflowStyle = TripReportWorkflowStyle;

export const STATUS_NAMES: Record<ApprovalStatus, string> = {
  applied: '承認待ち',
  approved: '承認済み',
  rejected: '差し戻し',
  canceled: 'キャンセル',
  queued: '申請中',
  stoped: '差し戻し'
};

export const WORKFLOW_STYLE_NAMES: Record<WorkflowStyle, string> = {
  trip_report: '出張報告',
  department: '部署',
  project: 'プロジェクト'
};

export const STATUS_PRIORITY: Record<ApprovalStatus, number> = {
  approved: 1,
  rejected: 2,
  stoped: 2,
  applied: 3,
  queued: 4,
  canceled: 5
};

export class Approval {
  id: number;

  workflowStyle: WorkflowStyle;

  status: ApprovalStatus;

  lastStatus: ApprovalStatus;

  approveStage: number;

  message: string | null;

  approverName: string | null;

  approvedAt: Moment | null;

  createdAt: Moment;

  approvers: Approver[] = [];

  groupId: number | null = null;

  groupCode: string | null = null;

  groupName: string | null = null;

  inGroupApprovals: Approval[] | null = null;

  constructor(args: ApprovalArgs) {
    this.id = args.id;
    this.workflowStyle = args.workflow_style;
    this.status = args.status;
    this.lastStatus = args.last_status;
    this.approveStage = args.approve_stage;
    this.approvedAt = args.approved_at ? moment(args.approved_at) : null;
    this.message = args.message || null;
    this.approverName = args.approver?.name || null;
    this.createdAt = moment(args.created_at);

    if (args.approver_candidates) {
      this.approvers = args.approver_candidates.map(approver => new Approver(approver));
    }

    if (this.workflowStyle === 'department' && args.department) {
      this.groupId = args.department.id;
      this.groupCode = args.department.code;
      this.groupName = args.department.name;
    } else if (this.workflowStyle === 'project' && args.project) {
      this.groupId = args.project.id;
      this.groupCode = args.project.code;
      this.groupName = args.project.name;
    }
  }

  includeApprover(currentUserId: number | undefined) {
    return this.approvers.some(approver => approver.approverId === currentUserId);
  }

  setMessage(message: string) {
    this.message = message;
    app.render();
  }

  setInGroups(args: ApprovalArgs[]) {
    this.inGroupApprovals = args.map(raw => new Approval(raw));
    app.render();
  }

  currentStatus() {
    return this.status !== 'stoped' ? this.status : this.lastStatus;
  }

  static stages(approvals: Approval[]): Stage[] {
    const stages = approvals.map(approval => ({
      stage: approval.approveStage,
      approvers: approval.approvers.map(
        approver =>
          new User({
            id: approver.approverId,
            name: approver.name,
            email: approver.email
          } as unknown as UserJson)
      )
    }));

    return stages.length > 0 ? stages : [{ stage: 1, approvers: [null] }];
  }

  static approvalGroups(approvals: Approval[]) {
    return Object.values(
      approvals.reduce((r, approval) => {
        if (approval.groupId) {
          if (!r[approval.groupId]) r[approval.groupId] = [];
          r[approval.groupId].push(approval);
        }

        return r;
      }, {} as Record<number, Approval[]>)
    );
  }
}

export interface ApprovalArgs {
  id: number;

  workflow_style: WorkflowStyle;

  status: ApprovalStatus;

  last_status: ApprovalStatus;

  approve_stage: number;

  message?: string;

  approved_at?: string;

  created_at: string;

  department?: {
    id: number;

    code: string;

    name: string;
  };

  project?: {
    id: number;

    code: string;

    name: string;
  };

  approver?: {
    name: string;
  };

  approver_candidates?: ApproverCandidateArgs[];
}
