import React from 'react';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import PartnerLog from './partner_log';
import StatusLog from './status_log';
import OrderItemBilledAtLog from './order_item_billed_at_log';

interface Props {
  message: Message;
  selectedTrip: Trip;
  showMessage: boolean;
  toggleShowMessage: () => void;
  fetchMessages: (trip: Trip) => void;
}
const Logs: React.FC<Props> = ({ message, showMessage, selectedTrip, toggleShowMessage, fetchMessages }) => {
  return (
    <MessageLogWrap>
      <MessageLeft />
      <MessageCenter>
        {message.arranger_name &&
          (message.type === 'status_log' ? (
            <StatusLog message={message} />
          ) : message.type === 'partner_log' ? (
            <PartnerLog
              message={message}
              selectedTrip={selectedTrip}
              showMessage={showMessage}
              toggleShowMessage={toggleShowMessage}
              fetchMessages={fetchMessages}
            />
          ) : message.type === 'order_item_billed_at_log' ? (
            <OrderItemBilledAtLog message={message} />
          ) : null)}
      </MessageCenter>
      <MessageRight />
    </MessageLogWrap>
  );
};

const MessageLogWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-right: auto;
`;

const MessageLeft = styled.div`
  flex-shrink: 0;
  width: 60px;
  position: relative;
  margin-right: 10px;
  text-align: center;
`;

const MessageCenter = styled.div`
  flex-grow: 9999;
`;

const MessageRight = styled.div`
  flex-shrink: 0;
  width: 60px;
  text-align: center;
  margin-left: 10px;
`;

export default Logs;
