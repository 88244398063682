import { observable } from 'mobx';
import type { ChargingDepartmentShareMappingArgs } from './charging_department_share_mapping';
import type Department from './department';
import ChargingDepartmentShareMappingList from './charging_department_share_mapping_list';

export type ChargingDepartmentShareArgs = {
  id: number;
  organization_id: number;
  code: string;
  name: string;
  disabled: boolean;
  editable: boolean;
  charging_department_share_mappings: ChargingDepartmentShareMappingArgs[];
};

export default class ChargingDepartmentShare {
  @observable
  id: number;

  @observable
  organizationId: number;

  @observable
  code: string;

  @observable
  name: string;

  @observable
  disabled: boolean;

  @observable
  editable: boolean;

  @observable
  chargingDepartmentShareMappings: ChargingDepartmentShareMappingList;

  constructor(args: ChargingDepartmentShareArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.code = args.code;
    this.name = args.name;
    this.disabled = args.disabled;
    this.editable = args.editable;
    if (args.charging_department_share_mappings) {
      this.chargingDepartmentShareMappings = new ChargingDepartmentShareMappingList(
        args.charging_department_share_mappings
      );
    } else {
      this.chargingDepartmentShareMappings = new ChargingDepartmentShareMappingList([]);
    }
  }

  clone(): ChargingDepartmentShare {
    const args: ChargingDepartmentShareArgs = {
      id: this.id,
      organization_id: this.organizationId,
      code: this.code,
      name: this.name,
      disabled: this.disabled,
      editable: this.editable,
      charging_department_share_mappings: this.chargingDepartmentShareMappings.list.map(mapping => {
        return {
          id: mapping.id,
          department_id: mapping.departmentId,
          charging_department_share_id: mapping.chargingDepartmentShareId,
          weight: mapping.weight
        };
      })
    };
    return new ChargingDepartmentShare(args);
  }

  codeAndNameOneLine = (): string => {
    return `${this.code}:${this.name}`;
  };

  codeAndNameLines = (): string[] => {
    return [`${this.code}-${this.name}`, this.createShareLabel()];
  };

  createShareLabel(): string {
    let content = '';
    this.percentages().forEach(item => {
      const department = item.department;
      if (department) {
        if (content) {
          content += ' : ';
        }
        content += `${department.code}-${department.name}(${item.percentage}%)`;
      }
    });

    return content;
  }

  percentages(): { department: Department; percentage: number }[] {
    const shareMappings = this.chargingDepartmentShareMappings;
    if (shareMappings && shareMappings.list.length > 0) {
      const totalWeight = shareMappings.list.reduce((sum, shareMapping) => sum + shareMapping.weight, 0);
      const percentageList = shareMappings.list.map(shareMapping =>
        Math.floor((100 * shareMapping.weight) / totalWeight)
      );
      const totalPercentage = percentageList.reduce((sum, percentage) => sum + percentage, 0);
      percentageList[0] += 100 - totalPercentage;

      return shareMappings.list.map((shareMapping, i) => ({
        department: shareMapping.department,
        percentage: percentageList[i]
      }));
    }
    return [];
  }

  setCode = (value: string) => {
    this.code = value;
    app.render();
  };

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setDisabled = (value: boolean) => {
    this.disabled = value;
    app.render();
  };

  setChargingDepartmentShareMappings = (value: ChargingDepartmentShareMappingList) => {
    this.chargingDepartmentShareMappings = value;
    app.render();
  };

  submitParams() {
    const mappings = this.chargingDepartmentShareMappings.list.map(s => s.submitParams());
    return {
      code: this.code,
      name: this.name,
      disabled: this.disabled,
      charging_department_share_mappings: mappings
    };
  }
}
