import React from 'react';
import styled, { css } from 'styled-components';
import { getColor, getTypogragy } from '@this/shared/ui/theme';

export const TEXT_COLOR = {
  PRIMARY: 'primary',
  BRAND: 'brand',
  DANGER: 'danger',
  SUCCESS: 'success',
  INFO: 'info',
  DESCRIPTION: 'description',
  DISABLED: 'disabled'
} as const;
export type TextColor = typeof TEXT_COLOR[keyof typeof TEXT_COLOR];

export const TEXT_LEVEL = {
  BODY: 'body',
  CAPTION: 'caption'
} as const;
export type TextLevel = typeof TEXT_LEVEL[keyof typeof TEXT_LEVEL];

export type TextProps = {
  className?: string;
  children: React.ReactNode;
  component?: 'p' | 'span';
  level?: TextLevel;
  color?: TextColor;
  bold?: boolean;
} & React.ComponentPropsWithoutRef<'p' | 'span'>;

export const Text = React.forwardRef<any, TextProps>(
  ({ className, children, component = 'p', level = 'body', color = 'primary', bold }, ref) => {
    return (
      <Root className={className} ref={ref} $level={level} $color={color} $bold={bold} as={component}>
        {children}
      </Root>
    );
  }
);

const Root = styled.div<{ $color?: TextColor; $level: TextLevel; $bold?: boolean }>`
  color: ${({ $color }) => {
    if (!$color) {
      return 'inherit';
    }
    switch ($color) {
      case 'primary':
      case 'disabled':
      case 'description':
        return getColor('text', $color);
      default:
        return getColor($color, 'primary');
    }
  }};
  font-size: ${props => getTypogragy('fontSizes', props.$level)(props)}px;
  margin-top: 0;
  margin-bottom: 0;
  ${({ $bold }) =>
    $bold
      ? css`
          font-weight: bold;
        `
      : null}};
`;
