import type { ChatbotActionResponseArgs } from '@this/src/domain/chatbot/chatbot_action';
import ChatbotAction, { convertChatbotActionResponseToArgs } from '@this/src/domain/chatbot/chatbot_action';

interface Props {
  sessionId?: number;
  message: string;
  isRestart?: boolean;
  onStarted?: () => void;
  onAction?: (action: ChatbotAction['action']) => void;
  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;
  onFinally?: () => void;
}

const handleSubmitMessageApi = ({
  sessionId,
  message,
  isRestart,
  onStarted,
  onAction,
  onSuccess,
  onError,
  onFinally
}: Props) => {
  if (message.length > 0) {
    onStarted?.();
    utils
      .jsonPromise<ChatbotActionResponseArgs>(
        '/chat_user_messages',
        { session_id: sessionId, text: message, restart: isRestart },
        'POST'
      )
      .then(res => {
        const action = new ChatbotAction(convertChatbotActionResponseToArgs(res));
        if (action.action === 'error') {
          onError?.(action.message || 'エラーが発生しました。');
        } else if (action.message) {
          onSuccess?.(action.message);
        }
        onAction?.(action.action);
      })
      .finally(() => {
        onFinally?.();
      });
  }
};

export default handleSubmitMessageApi;
