import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import _ from 'lodash';
import Link from '@this/components/shared/atoms/link';

import { OrganizationBody, OrganizationTitle } from '@this/components/organization/organization.style';
import {
  BulkActionSection,
  BulkActionSectionContent,
  BulkActionSectionHeader,
  BulkActionSections
} from '@this/components/organization/csv_bulk_action/csv_bulk_action.style';
import type { BulkActionAttr } from '../types';
import type { DownloadType, FileValidatorResult } from './types';
import DownloadFormat from './download_format';
import CsvUploadForm from './csv_upload_form';
import FileFormat from '../file_format';
import FileValidator from './file_validator';
import { Title } from '../../admin/admin';

type Props = RouteComponentProps<{ id?: string }>;
interface State {
  loading: boolean;
  title: string;
  description: string;
  formats: BulkActionAttr[];
  validateResult?: FileValidatorResult;
  uploadResult?: { uploaded: boolean; errors?: string[] };
  admin?: boolean;
  organizationName?: string;
}

export default abstract class CsvBulkAction extends React.Component<Props, State> {
  dataEnabled = false;

  abstract historyPath: string;

  abstract backPath: string;

  abstract downloadFormat: (encoding: DownloadType) => void;

  abstract upload: (file: File) => void;

  checkFile = async (file?: File) => {
    this.resetState();

    this.setState({ loading: true });
    const result = await FileValidator.validate(file);
    this.setState({ loading: false, validateResult: result });
  };

  render() {
    const { loading, admin, title, description, formats, validateResult, uploadResult, organizationName } =
      this.state;
    return (
      <>
        {admin ? (
          <Title>
            <Link to="/admin/organizations">顧客一覧</Link>
            <span> &gt; {organizationName}</span>
            <span> &gt; </span>
            <Link to={`/admin/organizations/${this.props.match.params.id}/departments`}>部署マスタ</Link>
            <span> &gt; {title}</span>
          </Title>
        ) : (
          <OrganizationTitle>{title}</OrganizationTitle>
        )}
        <OrganizationBody>
          <p>
            {description.split('\n').map((text, i) => (
              <>
                {i !== 0 && <br />}
                {text}
              </>
            ))}
          </p>
          <DownloadFormat downloadFormat={this.downloadFormat} dataEnabled={this.dataEnabled} />
          <BulkActionSections>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>アップロード</h3>
                <Link to={this.historyPath}>アップロード履歴</Link>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <CsvUploadForm
                  loading={loading}
                  historyPath={this.historyPath}
                  backPath={this.backPath}
                  validateResult={validateResult}
                  uploadResult={uploadResult}
                  select={this.checkFile}
                  upload={this.upload}
                />
              </BulkActionSectionContent>
            </BulkActionSection>
            <BulkActionSection>
              <BulkActionSectionHeader>
                <h3>フォーマット</h3>
              </BulkActionSectionHeader>
              <BulkActionSectionContent>
                <FileFormat formats={formats} />
              </BulkActionSectionContent>
            </BulkActionSection>
          </BulkActionSections>
        </OrganizationBody>
      </>
    );
  }

  private resetState() {
    this.setState({ loading: false, uploadResult: undefined, validateResult: undefined });
  }
}
