import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import type RemittanceSourceAccount from '@this/domain/expenses/remittance_source_account';

type Props = {
  account: RemittanceSourceAccount;
};

const RemittanceSourceAccountForm: React.FC<Props> = ({ account }) => {
  return (
    <dl>
      <dt>
        <label htmlFor="assignor-code">委託者コード</label>
      </dt>
      <dd>
        <input
          id="assignor-code"
          type="text"
          placeholder="半角数字"
          value={account.assignorCode}
          onChange={e => {
            account.assignorCode = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="assignor-name">委託者名</label>
      </dt>
      <dd>
        <input
          id="assignor-name"
          type="text"
          placeholder="半角カナ英数字"
          value={account.assignorName}
          onChange={e => {
            account.assignorName = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="bank-code">仕向金融機関コード</label>
      </dt>
      <dd>
        <input
          id="bank-code"
          type="text"
          placeholder="半角数字"
          value={account.bankCode}
          onChange={e => {
            account.bankCode = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="bank-name">仕向金融機関名</label>
      </dt>
      <dd>
        <input
          id="bank-name"
          type="text"
          placeholder="半角カナ英数字"
          value={account.bankName}
          onChange={e => {
            account.bankName = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="branch-code">仕向支店コード</label>
      </dt>
      <dd>
        <input
          id="branch-code"
          type="text"
          placeholder="半角数字"
          value={account.branchCode}
          onChange={e => {
            account.branchCode = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="branch-name">仕向支店名</label>
      </dt>
      <dd>
        <input
          id="branch-name"
          type="text"
          placeholder="半角カナ英数字"
          value={account.branchName}
          onChange={e => {
            account.branchName = e.target.value;
          }}
        />
      </dd>
      <dt>
        <label htmlFor="account-type">預金種目</label>
      </dt>
      <dd>
        <select
          id="account-type"
          value={account.accountType}
          onChange={e => {
            account.accountType = e.target.value as 'savings' | 'checking';
          }}
        >
          <option value="savings">普通</option>
          <option value="checking">当座</option>
        </select>
      </dd>
      <dt>
        <label htmlFor="account-number">口座番号</label>
      </dt>
      <dd>
        <input
          id="account-number"
          type="text"
          placeholder="半角数字"
          value={account.accountNumber}
          onChange={e => {
            account.accountNumber = e.target.value;
          }}
        />
      </dd>
    </dl>
  );
};

export default observer(RemittanceSourceAccountForm);
