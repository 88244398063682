import React from 'react';

const ChatIcon: React.FC = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.5H17C18.1046 5.5 19 6.39543 19 7.5V13.5C19 14.6046 18.1046 15.5 17 15.5H15V19.5L11 15.5H7C6.44772 15.5 5.94772 15.2761 5.58579 14.9142M5.58579 14.9142L9 11.5H13C14.1046 11.5 15 10.6046 15 9.5V3.5C15 2.39543 14.1046 1.5 13 1.5H3C1.89543 1.5 1 2.39543 1 3.5V9.5C1 10.6046 1.89543 11.5 3 11.5H5V15.5L5.58579 14.9142Z"
        stroke="#666666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
