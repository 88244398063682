import React, { useCallback, useMemo } from 'react';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { styled } from '@this/constants/themes';
import type HotelList from '@this/src/domain/hotel/hotel_list';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Link } from '@this/src/components/shared/ui/navigations/link';

const PRICE_STEP = 1000;
const DISTANCE_STEP = 100;

interface Props {
  hotels: HotelList;
  hotelPriceLimit: number | null;
  isMobile: boolean;
}

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

interface StyleProps {
  isMobile: boolean;
}

const ListAreaHotelFilter: React.FC<Props> = ({ hotels, hotelPriceLimit, isMobile }) => {
  const filter = hotels.filter;
  const [pMarks, dMarks] = useMemo(
    () => [hotels.priceSliderMarks(PRICE_STEP), hotels.distanceSliderMarks(DISTANCE_STEP)],
    [hotels]
  );
  const minPrice = useMemo(() => hotels.minPriceValue(filter.minPrice, pMarks), [filter.minPrice, pMarks]);
  const maxPrice = useMemo(() => hotels.maxPriceValue(filter.maxPrice, pMarks), [filter.maxPrice, pMarks]);
  const minDistance = useMemo(
    () => hotels.minDistanceValue(filter.minDistance, dMarks),
    [filter.minDistance, dMarks]
  );
  const maxDistance = useMemo(
    () => hotels.maxDistanceValue(filter.maxDistance, dMarks),
    [filter.maxDistance, dMarks]
  );

  const handlePriceChange = useCallback(
    (_: unknown, newValue: number | number[]) => hotels.handlePriceChange(newValue, pMarks),
    [hotels, pMarks]
  );

  const handleDistanceChange = useCallback(
    (_: unknown, newValue: number | number[]) => hotels.handleDistanceChange(newValue, dMarks),
    [hotels, dMarks]
  );

  const priceValueLabelFormat = useCallback(
    (value: number) => hotels.priceValueLabelFormat(value, pMarks),
    [hotels, pMarks]
  );

  const distanceValueLabelFormat = useCallback(
    (value: number) => hotels.distanceValueLabelFormat(value, dMarks),
    [hotels, dMarks]
  );

  return (
    <Wrapper isMobile={isMobile}>
      <Title>ホテルの絞り込み</Title>
      <Section>
        <SectionTitle>価格帯（１泊あたり）</SectionTitle>
        <SliderSection>
          {isMobile ? (
            <CustomSliderSp
              value={[minPrice, maxPrice]}
              marks={pMarks}
              min={pMarks[0].value}
              max={pMarks[1].value}
              step={PRICE_STEP}
              ValueLabelComponent={ValueLabelComponent}
              valueLabelFormat={priceValueLabelFormat}
              onChange={handlePriceChange}
            />
          ) : (
            <CustomSlider
              value={[minPrice, maxPrice]}
              marks={pMarks}
              min={pMarks[0].value}
              max={pMarks[1].value}
              step={PRICE_STEP}
              ValueLabelComponent={ValueLabelComponent}
              valueLabelFormat={priceValueLabelFormat}
              onChange={handlePriceChange}
            />
          )}
        </SliderSection>
        {hotelPriceLimit && <SectionText>※規程金額：{utils.formatPrice(hotelPriceLimit)}</SectionText>}
      </Section>
      <Divider />
      <Section>
        <SectionTitle>目的地までの距離</SectionTitle>
        <SliderSection>
          {isMobile ? (
            <CustomSliderSp
              value={[minDistance, maxDistance]}
              marks={dMarks}
              min={dMarks[0].value}
              max={dMarks[1].value}
              step={DISTANCE_STEP}
              ValueLabelComponent={ValueLabelComponent}
              valueLabelFormat={distanceValueLabelFormat}
              onChange={handleDistanceChange}
            />
          ) : (
            <CustomSlider
              value={[minDistance, maxDistance]}
              marks={dMarks}
              min={dMarks[0].value}
              max={dMarks[1].value}
              step={DISTANCE_STEP}
              ValueLabelComponent={ValueLabelComponent}
              valueLabelFormat={distanceValueLabelFormat}
              onChange={handleDistanceChange}
            />
          )}
        </SliderSection>
      </Section>
      <Actions isMobile={isMobile}>
        <Link color="primary" onClick={() => hotels.clearFilter()}>
          リセット
        </Link>
        {isMobile ? (
          <Button color="primary" onClick={() => hotels.toggleShowFilter()}>
            絞り込む
          </Button>
        ) : (
          <Button color="sub" endIcon={<ChevronRightIcon />} onClick={() => hotels.toggleShowFilter()}>
            閉じる
          </Button>
        )}
      </Actions>
    </Wrapper>
  );
};

const ValueLabelComponent = (props: ValueLabelComponentProps) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const Wrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: ${props => (props.isMobile ? '10px' : '0')};
`;

const Title = styled.h2`
  font-size: 13px;
  font-weight: bold;
  margin: 1em 0 0.75em;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0;
`;

const SectionText = styled.p`
  font-size: 11px;
  margin: 0;
  margin-bottom: 10px;
`;

const SliderSection = styled.div`
  font-size: 11px;
  padding: 0 20px;
`;

const CustomSlider = styled(Slider)`
  .MuiSlider-markLabel {
    font-size: 11px;
  }
`;

const CustomSliderSp = styled(CustomSlider)`
  .MuiSlider-markLabel {
    font-size: 11px;
  }

  color: #c4c4c4;

  .MuiSlider-thumb {
    background-color: ${props => props.theme.fieldBgColor};
    border: 2px solid ${props => props.theme.accentColor};
    width: 25px;
    height: 25px;
    margin-top: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .MuiSlider-rail {
    height: 4px;
  }

  .MuiSlider-track {
    height: 4px;
    background-color: ${props => props.theme.accentColor};
  }
`;

const Actions = styled.div<StyleProps>`
  display: flex;
  justify-content: space-between;
  margin: ${props => (props.isMobile ? '20px 10px' : '0')};
`;

export default ListAreaHotelFilter;
