import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import Dropzone from 'react-dropzone';

import type { ImportItemArgs } from '@this/domain/import_item/import_item';
import { ImportItem } from '@this/domain/import_item/import_item';
import type { ImportItemFileArgs } from '@this/domain/import_item_file/import_item_file';
import { ImportItemFile } from '@this/domain/import_item_file/import_item_file';

import Modal from '@this/shared/modal/modal';
import { ButtonBase } from '@this/shared/atoms/button';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import useApi from '@this/components/shared/hooks/use_api';

import { DownloadType, Encoding } from '@this/components/organization/csv_bulk_action/types';
import FileFormat from '@this/components/organization/file_format';
import DownloadFormat from '@this/components/organization/csv_bulk_action/download_format';

import { Fetcher } from '@this/src/util';
import { OrganizationBody, OrganizationTitle } from '../organization.style';
import { CREATE_FORMATS } from './file_formats';

interface ImportItemResponse {
  import_item: ImportItemArgs[];
}

interface ImportItemFileResponse {
  import_item_file: ImportItemFileArgs[];
}

const ImportItems = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [canImport, setCanImport] = useState(false);

  const importItemResponse = useApi<ImportItemResponse>('/organization/import_items.json');
  const itemRawData = importItemResponse.data;
  const isItemLoading = importItemResponse.isLoading;
  const importItemsData = itemRawData
    ? itemRawData.import_item.map((args: ImportItemArgs) => new ImportItem(args))
    : [];

  const fileResponse = useApi<ImportItemFileResponse>('/organization/import_item_files.json');
  const fileRawData = fileResponse.data;
  const isFileLoading = fileResponse.isLoading;
  const importFilesData = fileRawData
    ? fileRawData.import_item_file.map((args: ImportItemFileArgs) => new ImportItemFile(args))
    : [];

  const onDrop = (file: File) => {
    setFile(file);
    setCanImport(true);
  };

  const handleImport = useCallback(async () => {
    if (!file) {
      return;
    }
    setCanImport(false);

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload('/organization/import_items.json', formData);
    } catch (e) {
      // バリデーションエラーが発生しようがしなかろうが、リダイレクトさせる。
      // エラーハンドリング周りは画面設計も絡むので修正は後の対応としたい。
    }
    location.reload();
  }, [file]);

  const handleDeleteData = async (id: number) => {
    await Fetcher.delete(`/organization/import_item_files/${id}.json`);
    location.reload();
  };

  const downloadFormat = (type: DownloadType) => {
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `/organization/import_items/templates/index?encoding=${encoding}`;
    } else {
      location.href = `/organization/import_items/templates/index?encoding=${type}`;
    }
  };

  return (
    <div>
      <OrganizationTitle>旅費データインポート</OrganizationTitle>
      <OrganizationBody>
        <p>CSVファイルをアップロードすることで、旅費データを取り込み、ダッシュボードで確認することができます。</p>
        <ImportButton onClick={() => setShowModal(true)}>インポート</ImportButton>
        {isItemLoading && isFileLoading ? (
          <SimpleLoading />
        ) : (
          <>
            <H2>アップロードファイル履歴</H2>
            <Table>
              <thead>
                <tr>
                  <Th>アップロード日時</Th>
                  <Th>ファイル名</Th>
                  <Th>データ数</Th>
                  <Th>削除</Th>
                </tr>
              </thead>
              <tbody>
                {importFilesData.map((file, i) => (
                  <tr key={i}>
                    <Td>{moment(file.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Td>
                    <Td>{file.fileName}</Td>
                    <Td>{file.count}</Td>
                    <Td>
                      <a onClick={() => handleDeleteData(file.id)}>削除</a>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <H2>インポートデータ(最新500件のデータを表示します)</H2>
            <Info>
              赤色の文字はシステムのマスタに登録されていないデータです。ダッシュボードにデータを適切に反映するため、マスタ情報登録をおすすめします。
            </Info>
            <Table>
              <thead>
                <tr>
                  <Th>利用日</Th>
                  <Th>出発日</Th>
                  <Th>帰着日</Th>
                  <Th>社員番号</Th>
                  <Th>社員名</Th>
                  <Th>金額</Th>
                  <Th>プロジェクトコード</Th>
                  <Th>プロジェクト名</Th>
                  <Th>部署コード</Th>
                  <Th>部署名</Th>
                  <Th>品目</Th>
                </tr>
              </thead>
              <tbody>
                {importItemsData.map((item, i) => (
                  <tr key={i}>
                    <Td>{item.date}</Td>
                    <Td>{item.startDate}</Td>
                    <Td>{item.endDate}</Td>
                    {item.employeeId ? (
                      <>
                        <Td>{item.employeeCode}</Td>
                        <Td>{item.employeeName}</Td>
                      </>
                    ) : (
                      <>
                        <UnmatchTd>{item.employeeCode}</UnmatchTd>
                        <UnmatchTd>{item.employeeName}</UnmatchTd>
                      </>
                    )}
                    <Td>{item.price}</Td>
                    {item.projectId ? (
                      <>
                        <Td>{item.projectCode}</Td>
                        <Td>{item.projectName}</Td>
                      </>
                    ) : (
                      <>
                        <UnmatchTd>{item.projectCode}</UnmatchTd>
                        <UnmatchTd>{item.projectName}</UnmatchTd>
                      </>
                    )}
                    {item.departmentId ? (
                      <>
                        <Td>{item.departmentCode}</Td>
                        <Td>{item.departmentName}</Td>
                      </>
                    ) : (
                      <>
                        <UnmatchTd>{item.departmentCode}</UnmatchTd>
                        <UnmatchTd>{item.departmentName}</UnmatchTd>
                      </>
                    )}
                    {!item.isOther() ? (
                      <Td>{item.importItemCategoryName}</Td>
                    ) : (
                      <UnmatchTd>{item.importItemCategoryName}</UnmatchTd>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </OrganizationBody>
      <Modal show={showModal} hideModal={() => setShowModal(false)} title="旅費データインポート">
        <DownloadFormat downloadFormat={downloadFormat} dataEnabled={false} />
        <UploadWrapper>
          <Area>
            <H2>アップロード</H2>
            <UploadForm onDrop={files => onDrop(files[0])} multiple={false}>
              {file ? (
                file.name
              ) : (
                <p>
                  ここへファイルをドラッグ&amp;ドロップ
                  <br />
                  もしくは <br />
                  クリックしてファイルを指定
                </p>
              )}
            </UploadForm>
            <ImportButton onClick={() => handleImport()} disabled={!canImport}>
              インポート
            </ImportButton>
          </Area>
          <Area>
            <H2>フォーマット</H2>
            <FileFormat formats={CREATE_FORMATS} />
          </Area>
        </UploadWrapper>
      </Modal>
    </div>
  );
};

const H2 = styled.h2`
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const Info = styled.p`
  margin-bottom: 10px;
`;

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const UnmatchTd = styled.td`
  padding: 8px;
  color: red;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Area = styled.div`
  flex-basis: 49%;
  display: flex;
  flex-direction: column;
`;

const ImportButton = styled.button`
  ${ButtonBase};
  width: 100px;
  margin-top: 10px;
`;

const UploadForm = styled(Dropzone)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 439px);
  margin-bottom: 20px;
  justify-content: center;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px dashed #ccc;
  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export default ImportItems;
