import React from 'react';
import { observer } from 'mobx-react';

import MuiPaper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import type SearchTrackingLog from '@this/src/domain/search_tracking_log';
import {
  SEARCH_TRACKING_LOG_STATUS_LABELS,
  SEARCH_TRACKING_PATH_LABELS
} from '@this/src/domain/search_tracking_log';
import type { SearchType } from '@this/src/domain/search_query';
import { SEARCH_TYPE_LABELS } from '@this/src/domain/search_query';

interface Props {
  searchTrackingLog: SearchTrackingLog;
  onClick: () => void;
}

const SearchTrackingDetailLog: React.FC<Props> = observer(({ searchTrackingLog: log, onClick }) => {
  return (
    <Paper>
      <TitleFlex>
        <Link onClick={onClick}>
          <PaperTitle>
            {SEARCH_TRACKING_PATH_LABELS[log.path]}({log.action})
          </PaperTitle>
        </Link>
        <DateP>{log.createdAt.format('YYYY/MM/DD HH:mm')}</DateP>
      </TitleFlex>
      {log.repository && (
        <Flex>
          <FlexItem>
            <FlexRow>
              <FlexRowLabel>レポジトリ</FlexRowLabel>
              <FlexRowItem>{log.repository}</FlexRowItem>
            </FlexRow>
          </FlexItem>
        </Flex>
      )}
      <Flex>
        <FlexItemSmall>
          <FlexRow>
            <FlexRowLabel>ステータス</FlexRowLabel>
            <FlexRowItem>{SEARCH_TRACKING_LOG_STATUS_LABELS[log.status]}</FlexRowItem>
          </FlexRow>
          {log.searchType && (
            <FlexRow>
              <FlexRowLabel>検索種別</FlexRowLabel>
              <FlexRowItem>{SEARCH_TYPE_LABELS[log.searchType as SearchType]}</FlexRowItem>
            </FlexRow>
          )}
        </FlexItemSmall>
        <FlexItem>
          {log.origin && (
            <FlexRow>
              <FlexRowLabel>出発・目的地</FlexRowLabel>
              <FlexRowItem>
                {log.origin}
                {log.destination && ' → '}
                {log.destination}
              </FlexRowItem>
            </FlexRow>
          )}
          {log.originLatitude && (
            <FlexRow>
              <FlexRowLabel>出発地LatLng</FlexRowLabel>
              <FlexRowItem>
                {log.originLatitude}
                {log.originLongitude && ', '}
                {log.originLongitude}
              </FlexRowItem>
            </FlexRow>
          )}
          {log.destinationLatitude && (
            <FlexRow>
              <FlexRowLabel>目的地LatLng</FlexRowLabel>
              <FlexRowItem>
                {log.destinationLatitude}
                {log.destinationLongitude && ', '}
                {log.destinationLongitude}
              </FlexRowItem>
            </FlexRow>
          )}
        </FlexItem>
        <FlexItem>
          {log.tripId && (
            <FlexRow>
              <FlexRowLabelWithNum>Trip ID</FlexRowLabelWithNum>
              <FlexRowItem>{log.tripId}</FlexRowItem>
            </FlexRow>
          )}
          {log.searchQueryId && (
            <FlexRow>
              <FlexRowLabelWithNum>Search Query ID</FlexRowLabelWithNum>
              <FlexRowItem>{log.searchQueryId}</FlexRowItem>
            </FlexRow>
          )}
          {log.searchQueryItemId && (
            <FlexRow>
              <FlexRowLabelWithNum>Search Query Item ID</FlexRowLabelWithNum>
              <FlexRowItem>{log.searchQueryItemId}</FlexRowItem>
            </FlexRow>
          )}
        </FlexItem>
        <Actions>
          <Link href={log.bugsnagLink || '#'} isExternal target="_blank">
            Bugsnag
          </Link>
        </Actions>
      </Flex>
    </Paper>
  );
});

const Paper = styled(MuiPaper)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
`;

const TitleFlex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const PaperTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
`;

const DateP = styled(Typography)`
  font-size: 0.8rem;
  color: ${props => props.theme.grayTextColor};
`;

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const FlexItemSmall = styled.div`
  flex: 0.7;
`;

const FlexItem = styled.div`
  flex: 1;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FlexRowLabel = styled(Typography)`
  width: 100px;
`;

const FlexRowLabelWithNum = styled(Typography)`
  width: 150px;
`;

const FlexRowItem = styled(Typography)`
  flex: 1;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100px;
  height: 100%;
`;

export default SearchTrackingDetailLog;
