import React from 'react';
import { Redirect } from 'react-router-dom';
import type { Moment } from 'moment';
import A from '@this/shared/atoms/a';
import { styled } from '@this/constants/themes';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import type UserJson from '@this/domain/user/user_json';
import { isIncludeHyphen } from '@this/components/shared/checker/hyphen_checker';
import SelectNationalities from '@this/shared/nationality/select_nationalities';
import _ from 'lodash';
import { Button } from '@this/shared/ui/inputs/button';
import { Box } from '@material-ui/core';
import DateSelector from '@this/shared/date_selector/date_selector';
import { Section, LeftLabel, SubLabel, Input, Field } from './user_edit/user_edit';
import User from '../domain/user/user';

interface Props {}

interface State {
  user: User | null;
  submitting: boolean;
  editErrors: string[];
  success: boolean;
}

export default class OrganizationMemberInsufficientEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      submitting: false,
      editErrors: [],
      success: false
    };
    this.handleNationalityIdChange = this.handleNationalityIdChange.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    utils.jsonPromise<UserJson>('/users/insufficient.json').then(res => {
      const user = new User(res);
      this.setState({ user });
    });
  }

  /* formの内容が正しければtrueを返す */
  isValidFormContent() {
    const invalidErrors = [];
    if (this.state.user && isIncludeHyphen(this.state.user.tel)) {
      invalidErrors.push('電話番号はハイフンを含めず入力してください');
    }
    if (invalidErrors.length > 0) {
      this.setState({
        editErrors: invalidErrors
      });
      return false;
    }
    return true;
  }

  handleNationalityIdChange(id: number) {
    this.state.user!.setNationalityId(id);
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = this.state.user;
    if (!user || !this.isValidFormContent()) {
      return;
    }
    this.setState({
      submitting: true,
      editErrors: []
    });
    const params: any = { user: user.insufficientAcceptParams() };

    try {
      await utils.jsonPromise<any>('/users/insufficient_update', params, 'PUT');
      this.setState({
        editErrors: [],
        success: true,
        submitting: false
      });
      location.href = '/dashboard';
    } catch (error) {
      if (error.status === 400) {
        this.setState({
          editErrors: error.responseJSON.errors,
          submitting: false
        });
      } else {
        this.setState({
          editErrors: ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'],
          submitting: false
        });
        utils.sendErrorObject(error);
      }
    }
  };

  render() {
    try {
      const { user, success } = this.state;
      const afterSuccessPath = '/dashboard';
      return (
        <div className="content-body">
          {success ? (
            <div>
              <Redirect to={afterSuccessPath} />
            </div>
          ) : user ? (
            <form onSubmit={this.handleSubmit}>
              <Section>
                <LeftLabel>メール</LeftLabel>
                <Mail>{user.email}</Mail>
              </Section>
              <Section>
                <LeftLabel>氏名</LeftLabel>
                <SubLabel htmlFor="lastName">氏</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="lastName"
                  placeholder="例） 山田"
                  value={user.lastName || ''}
                  onChange={e => user.setLastName(e.target.value)}
                />
                <SubLabel htmlFor="firstName">名</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="firstName"
                  placeholder="例） 一郎"
                  value={user.firstName || ''}
                  onChange={e => user.setFirstName(e.target.value)}
                />
              </Section>
              <Section>
                <LeftLabel>氏名ローマ字</LeftLabel>
                <SubLabel htmlFor="lastNameRoman">氏</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="lastNameRoman"
                  placeholder="例） Yamada"
                  value={user.lastNameRoman}
                  onChange={e => user.setLastNameRoman(e.target.value)}
                />
                <SubLabel htmlFor="firstNameRoman">名</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="firstNameRoman"
                  placeholder="例） Ichiro"
                  value={user.firstNameRoman || ''}
                  onChange={e => user.setFirstNameRoman(e.target.value)}
                />
              </Section>
              <Section>
                <LeftLabel>氏名カナ</LeftLabel>
                <SubLabel htmlFor="lastNameKana">氏</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="lastNameKana"
                  placeholder="例） ヤマダ"
                  value={user.lastNameKana || ''}
                  onChange={e => user.setLastNameKana(e.target.value)}
                />
                <SubLabel htmlFor="firstNameKana">名</SubLabel>
                <Input
                  className="user-edit__input-split"
                  type="text"
                  id="firstNameKana"
                  placeholder="例） イチロウ"
                  value={user.firstNameKana || ''}
                  onChange={e => user.setFirstNameKana(e.target.value)}
                />
              </Section>
              <Section>
                <LeftLabel htmlFor="birthdayYear">生年月日</LeftLabel>
                <DateSelector onDateChange={(m: Moment) => user.setBirthdayMoment(m)} date={user.birthdayMoment} />
              </Section>
              <Section>
                <LeftLabel>国籍</LeftLabel>
                <SelectNationalities
                  nationalities={user.nationalities}
                  selectedId={user.nationalityId}
                  onSelect={this.handleNationalityIdChange}
                />
              </Section>

              <Section>
                <LeftLabel htmlFor="gender">性別</LeftLabel>
                <label>
                  <input
                    type="radio"
                    value="male"
                    checked={user.gender === 'male'}
                    onChange={() => user.setGender('male')}
                  />
                  <span>男</span>
                </label>
                <label>
                  <input
                    type="radio"
                    value="female"
                    checked={user.gender === 'female'}
                    onChange={() => user.setGender('female')}
                  />
                  <span>女</span>
                </label>
              </Section>
              <Field>
                <LeftLabel htmlFor="tel">電話番号</LeftLabel>
                <Input
                  className="user-edit__input"
                  type="text"
                  id="tel"
                  placeholder="例） 09011112222"
                  value={user.tel || ''}
                  onChange={e => user.setTel(e.target.value)}
                />
                {user.tel && isIncludeHyphen(user.tel) && (
                  <div className="error">ハイフンを含めず入力してください</div>
                )}
              </Field>
              <Section>
                <LeftLabel>マイレージ</LeftLabel>
                <div className="user-edit__section-mileage__items">
                  {user.mileageNumbers &&
                    user.mileageNumbers.map((mileageNumber, index) => (
                      <div key={index} className="user-edit__section__mileage-items__item">
                        <select
                          value={mileageNumber.carrier}
                          onChange={e => user.setMilageCarrier(index, e.target.value)}
                        >
                          {['ANA', 'JAL', 'SFJ', 'ADO', 'SNA', 'AXM', 'その他'].map(carrier => (
                            <option key={carrier}>{carrier}</option>
                          ))}
                        </select>
                        <input
                          type="text"
                          className="user-signin__input"
                          value={mileageNumber.number}
                          onChange={e => user.setMilageNumber(index, e.target.value)}
                        />
                        <a
                          className="user-edit__section__mileage-items__remove"
                          onClick={() => user.removeMileageNumber(index)}
                        >
                          <img src="/images/close_search.png" />
                        </a>
                      </div>
                    ))}
                  <div className="user-edit__section__mileage-items__add">
                    <A onClick={() => user.addMileageNumber()}>+マイレージを追加</A>
                  </div>
                </div>
              </Section>
              {user.organization?.setting?.send_user_forwarded_email && (
                <Field>
                  <LeftLabel htmlFor="user_forwarded_email">転送アドレス</LeftLabel>
                  {_.map(user.userForwardedEmails, (e, i) => (
                    <Input
                      key={`user_forwarded_email_${i}`}
                      type="text"
                      id="user_forwarded_email"
                      value={e.email}
                      placeholder="user@example.jp"
                      onChange={e => user.setUserForwardedEmails(e.target.value, i)}
                    />
                  ))}
                </Field>
              )}
              <div className="user-edit__errors">
                {this.state.editErrors.map((error: string, i: number) => {
                  if (typeof error === 'string') {
                    return (
                      <div className="error" key={i}>
                        {error.split('\n').map((line: string, j: number) => (
                          <p key={j}>{line}</p>
                        ))}
                      </div>
                    );
                  }
                  return (
                    <div className="error" key={i}>
                      {error[0]}
                    </div>
                  );
                })}
              </div>
              {this.state.submitting ? (
                <SimpleLoading />
              ) : (
                <Box ml={2} mr={2}>
                  <Button type="submit" size="large" fullWidth>
                    保存
                  </Button>
                </Box>
              )}
            </form>
          ) : (
            <SimpleLoading />
          )}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Mail = styled.p`
  overflow: auto;
`;
