import _ from 'lodash';
import type { Args } from './organization_base';
import OrganizationBase from './organization_base';

class OrganizationBaseList {
  public list: OrganizationBase[];

  constructor(raw: Args[]) {
    this.list = raw.map(organizationBase => new OrganizationBase(organizationBase));
  }

  find(id?: number) {
    if (!id) return undefined;
    return _.find(this.list, organizationBase => organizationBase.id === id);
  }

  push(organizationBase: OrganizationBase) {
    this.list.push(organizationBase);
  }

  remove(organizationBase: OrganizationBase) {
    const i = this.list.indexOf(organizationBase);
    if (i > -1) this.list.splice(i, 1);
  }

  update(newOrganizationBase: OrganizationBase) {
    const organizationBase = this.find(newOrganizationBase.id);
    if (organizationBase) _.assign(organizationBase, newOrganizationBase);
  }
}

export default OrganizationBaseList;
