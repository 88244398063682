export interface InvoiceAdjustmentArgs {
  id?: number;
  invoice_id?: number;
  price_without_tax?: number;
  tax?: number;
  price_with_tax?: number;
}

class InvoiceAdjustment {
  id: number | null;

  invoiceId: number | null;

  priceWithoutTax: number;

  tax: number;

  priceWithTax: number;

  constructor(args: InvoiceAdjustmentArgs) {
    this.id = args.id || null;
    this.invoiceId = args.invoice_id || null;
    this.priceWithTax = args.price_with_tax || 0;
    this.tax = args.tax || 0;
    this.priceWithoutTax = args.price_without_tax || 0;
  }
}
export default InvoiceAdjustment;
