global._ = require('lodash');
const utils = require('./utils');

global.utils = utils;
_.assign(global, utils);
_.assign(global, {
  Confirm: require('./components/shared/confirm/confirm').default,
  Header: require('./components/shared/header/header').default,
  Footer: require('./components/shared/footer/footer').default,
  SimpleLoading: require('./components/shared/simple_loading/simple_loading').default,
  Modal: require('./components/shared/modal/modal').default,
  Pagination: require('./components/shared/pagination/pagination').default,
  Search: require('./components/shared/search/search').default,
  MembersSearchFilter: require('./components/organization/members/members_search_filter').default,
  MemberBulkInvitationAndDisabling:
    require('./components/organization/members/members_bulk_invitation_and_disabling').default,
  SegmentedControl: require('./components/shared/segmented_control/segmented_control').default,
  Tooltip: require('./components/shared/tooltip/tooltip').default,
  FlashMessage: require('./components/shared/flash_message/flash_message').default,
  Text: require('./components/shared/text/text').default,
  CSSTransition: require('react-transition-group/CSSTransition'),
  Dropzone: require('react-dropzone'),
  TextArea: require('react-textarea-autosize'),
  toast: require('react-toastify').toast,
  ToastContainer: require('react-toastify').ToastContainer,
  StepBar: require('./components/step_bar').default,
  JSONTree: require('react-json-inspector'),
  PriceField: require('./components/shared/price_field/price_field').default
});
