import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { TextField } from '../search/search';

export interface Props<T extends string> {
  id: T;
  value?: string;
  placeholder?: string;
  onChange: (funcName: T, value: string, address: string) => void;
  disabled?: boolean;
  className?: string;
}

interface AutoCompletableInputSuggest {
  id: number;
  mainText: string;
  subText: string;
  img: string;
  kind: string;
}

interface SuggestsResponse {
  suggests: SuggestsResponseItem[];
}

interface SuggestsResponseItem {
  id: number;
  main_text: string;
  secondary_text: string;
  type: 'history' | 'place' | 'city' | 'airport' | 'station' | 'base';
}

interface State {
  suggests: AutoCompletableInputSuggest[];
  showSuggest: boolean;
  activeIndex: number | null;
}

export default class AutoCompletableInput<T extends string> extends React.Component<Props<T>, State> {
  inputValue = '';

  state: State = {
    suggests: [],
    showSuggest: false,
    activeIndex: null
  };

  private updateSuggestSubject = new Subject<string>();

  private updateSuggestSubscription = this.updateSuggestSubject.pipe(debounceTime(300)).subscribe(async value => {
    this.inputValue = value;
    const response = await utils.jsonPromise<SuggestsResponse>('/suggests.json', {
      query: value,
      kind: this.props.id
    });

    const orderObj = { base: 1, history: 2, city: 3, airport: 4, station: 5, place: 6 };
    const suggests = response.suggests
      .sort((s1, s2) => orderObj[s1.type] - orderObj[s2.type])
      .map(s => ({
        id: s.id,
        mainText: s.main_text,
        subText: s.secondary_text,
        img: `${s.type}.png`,
        kind: s.type
      }));
    this.setState({
      suggests,
      activeIndex: null
    });
  });

  componentDidMount() {
    this.updateSuggests(this.props.value || '');
  }

  componentDidUpdate(prevProps: Props<T>, _prevState: State) {
    // 値の更新があった
    if (prevProps.value !== this.props.value) {
      this.updateSuggests(this.props.value || '');
    }
  }

  componentWillUnmount(): void {
    if (this.updateSuggestSubscription) {
      this.updateSuggestSubscription.unsubscribe();
    }
  }

  updateSuggests = (value: string) => this.updateSuggestSubject.next(value);

  handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    this.handleChange(evt.target.value);
    this.setState({ showSuggest: true, activeIndex: null });
  };

  showSuggestContainer = (
    evt: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    evt.preventDefault();
    this.setState({ showSuggest: true });
  };

  clearSuggest = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const activeItem = this.getActiveItem();
    if (activeItem) {
      this.handleChange(activeItem.mainText, activeItem.subText);
    }
    this.setState({ showSuggest: false, activeIndex: null });
  };

  setActiveItemAtIndex(index: number) {
    if (this.state.suggests.length > 0) {
      this.setState({
        activeIndex: index,
        showSuggest: true
      });
    }
  }

  getActiveItem = () => !_.isNull(this.state.activeIndex) && this.state.suggests[this.state.activeIndex];

  handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const ARROW_UP = 38;
    const ARROW_DOWN = 40;
    const ENTER_KEY = 13;
    const ESC_KEY = 27;

    switch (evt.keyCode) {
      case ENTER_KEY: {
        if (this.state.showSuggest) {
          evt.preventDefault();
          const activeItem = this.getActiveItem();
          if (activeItem) {
            this.handleChange(activeItem.mainText, activeItem.subText);
            this.updateSuggests(activeItem.mainText);
          }
          this.setState({ showSuggest: false });
        }
        break;
      }
      case ARROW_DOWN: {
        evt.preventDefault();
        const activeItem = this.getActiveItem();
        if (!activeItem) {
          this.setActiveItemAtIndex(0);
        } else {
          const nextIndex = (this.state.activeIndex! + 1) % this.state.suggests.length;
          this.setActiveItemAtIndex(nextIndex);
        }
        break;
      }
      case ARROW_UP: {
        evt.preventDefault();
        const i = this.state.activeIndex;
        const prevIndex = i ? i - 1 : this.state.suggests.length - 1;
        this.setActiveItemAtIndex(prevIndex);
        break;
      }
      case ESC_KEY: {
        this.setState({
          showSuggest: false,
          activeIndex: null
        });
        break;
      }
      default:
        break;
    }
  };

  isHighlighted = (idx: number) => this.state.activeIndex === idx;

  selectSuggest = (s: AutoCompletableInputSuggest) => {
    this.handleChange(s.mainText, s.subText);
    this.updateSuggests(s.mainText);
    this.setState({ showSuggest: false });
  };

  deleteSuggest = (s: AutoCompletableInputSuggest) => {
    utils.jsonPromise(`/search_histories/${s.id}`, {}, 'DELETE').then(() => {
      const suggests = this.state.suggests.filter(suggest => suggest.id !== s.id);
      this.setState({ suggests });
    });
  };

  private handleChange = (value: string, address = '') => {
    this.props.onChange(this.props.id, value, address);
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <Wrapper className={props.className}>
        <TextField
          type="text"
          id={props.id}
          name={props.id}
          placeholder={props.placeholder ?? '会社名 / ビル名 / 駅名など'}
          onChange={this.handleInput}
          onFocus={this.showSuggestContainer}
          onClick={this.showSuggestContainer}
          value={props.value}
          onBlur={this.clearSuggest}
          onKeyDown={this.handleInputKeyDown}
          autoComplete="off"
          disabled={props.disabled || false}
        />
        {state.showSuggest && (
          <div data-wovn-ignore className="search__suggest-container">
            {state.suggests.map((suggest, i) => (
              <SuggestWrapper key={i} highlight={this.isHighlighted(i)}>
                <Suggest
                  className="search__suggest-content"
                  onMouseDown={() => this.selectSuggest(suggest)}
                  onTouchStart={() => this.selectSuggest(suggest)}
                  title={`${suggest.mainText} ${suggest.subText}`}
                >
                  <span>
                    <img
                      src={`/images/${suggest.img}`}
                      width="15"
                      height="18"
                      alt="place"
                      className="search__suggest-icon"
                    />
                  </span>
                  <span className="search__suggest-text">{suggest.mainText}</span>
                  <span className="search__suggest-subtext">{suggest.subText}</span>
                </Suggest>
                {suggest.kind === 'history' && !this.props.value && (
                  <DeleteHistory
                    className="history"
                    onMouseDown={() => this.deleteSuggest(suggest)}
                    onTouchStart={() => this.deleteSuggest(suggest)}
                  >
                    ×
                  </DeleteHistory>
                )}
              </SuggestWrapper>
            ))}
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  flex-grow: 99999;
`;

const SuggestWrapper = styled.div<{ highlight: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${props => (props.highlight ? '#e7f0f1' : 'white')};

  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }
`;

const DeleteHistory = styled.span`
  font-weight: bold;
  color: white;
  width: 20px;
  display: block;
  &:hover {
    color: #000;
  }
`;

const Suggest = styled.span`
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  width: 100%;
`;
