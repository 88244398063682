import type { BulkActionAttr } from '@this/components/organization/types';
import { BulkActionAttrServiceType } from '@this/components/organization/types';

const UPSERT_MEMBER_FORMATS: BulkActionAttr[] = [
  {
    name: 'email',
    summary: 'メールアドレス',
    example: 'tanaka.taro@example.com',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'birthday',
    summary: '誕生日',
    example: '例) 2018/04/01',
    required: false,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'gender',
    summary: '性別',
    example: '例) 男性',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'last_name_kana',
    summary: 'カナ姓',
    example: '例) タナカ',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'first_name_kana',
    summary: 'カナ名',
    example: '例) タロウ',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'last_name',
    summary: '姓',
    example: '例) 田中',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'first_name',
    summary: '名',
    example: '例) 太郎',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'last_name_roman',
    summary: 'ローマ字姓',
    example: '例) tanaka',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'first_name_roman',
    summary: 'ローマ字名',
    example: '例) taro',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'tel',
    summary: '電話番号',
    example: '例) 09012345678',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'sign_in_method',
    summary: 'サインイン方法',
    example: 'SSO: 1, パスワード: 0',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'employee_number',
    summary: '社員番号',
    example: '例) 1',
    required: false,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'department_code',
    summary: '部署コード',
    example: '例) 1',
    required: false,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'organization_base_name',
    summary: '拠点名',
    example: '',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'domestic_approval_required',
    summary: '国内承認',
    example: '必要: 1, 不要: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'foreign_approval_required',
    summary: '海外承認',
    example: '必要: 1, 不要: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'role',
    summary: '役割',
    example: '',
    required: false,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'show_other_members',
    summary: '他ユーザー表示',
    example: '表示する: 1, 表示しない: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'grade',
    summary: '役職等級',
    example: '',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'ignore_ip_address_restriction',
    summary: 'IPアドレス制限対象から除外',
    example: '除外する: 1, 除外しない: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'browsing_scope',
    summary: 'データ閲覧可能範囲',
    example: 'すべて: all, 自部署のみ: department',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'show_hotel_of_over_limit',
    summary: '国内規程額超過ホテル表示権限',
    example: 'あり: 1, なし: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'show_foreign_hotel_of_over_limit',
    summary: '海外規程額超過ホテル表示権限',
    example: 'あり: 1, なし: 0',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'exic_id',
    summary: 'EXIC会員ID',
    example: '例) 0123456789',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'exic_password',
    summary: 'EXIC会員パスワード',
    example: '例) password',
    required: false,
    serviceType: BulkActionAttrServiceType.AI_TRAVEL
  },
  {
    name: 'disabled',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true,
    serviceType: BulkActionAttrServiceType.ALL
  },
  {
    name: 'exic_reference_number',
    summary: 'EXIC整理番号',
    example: '例) 0123456789',
    required: false,
    serviceType: BulkActionAttrServiceType.ADMIN
  }
];

export default UPSERT_MEMBER_FORMATS;
