import React from 'react';
import { css } from 'styled-components';

import { styled } from '@this/constants/themes';
import { Flex } from '@this/shared/ui/layout/flex';
import { getColor, getZindex } from '@this/shared/ui/theme';

export const LOADING_SIZE = {
  small: 'small',
  medium: 'medium'
} as const;
export type LoadingSize = typeof LOADING_SIZE[keyof typeof LOADING_SIZE];

const sizeMap: Record<LoadingSize, { height: number; width: number }> = {
  small: { height: 16, width: 16 },
  medium: { height: 48, width: 48 }
};

export type LoadingProps = {
  className?: string;
  size?: LoadingSize;
  loading?: boolean;
  /**
   * 縦横中央にするかどうか。
   * childrenが存在しない場合のみ有効。
   */
  centerd?: boolean;
  children?: React.ReactNode;
};

export const Loading = ({
  className,
  children,
  size = 'medium',
  loading = true,
  centerd = true
}: LoadingProps) => {
  if (!children) {
    const loadingImage = <LoadingImage className={className} size={size} loading={loading} />;

    return centerd ? <Center>{loadingImage}</Center> : loadingImage;
  }

  return (
    <OverlayRoot className={className} loading={loading}>
      <OverlayLoadingImage size={size} loading={loading} />
      {children}
    </OverlayRoot>
  );
};

const LoadingImage = ({ className, size = 'medium', loading = true }: Omit<LoadingProps, 'children'>) =>
  loading ? <Img className={className} src="/images/loading.gif" alt="loading" size={size} /> : null;

const OverlayRoot = styled(({ loading, ...props }: { loading: boolean } & JSX.IntrinsicElements['div']) => (
  <div {...props} />
))`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    transition: background-color 0.3s;
  }

  ${({ loading }) =>
    loading
      ? css`
          position: relative;
          &::before {
            background-color: ${getColor('background', 'overlayLight')};
            z-index: ${getZindex('overlay')};
          }
        `
      : null}
`;

const OverlayLoadingImage = styled(LoadingImage)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${getZindex('overlay', 1)};
`;

const Img = styled.img<{ size: LoadingSize }>`
  ${({ size }) => css`
    height: ${sizeMap[size].height}px;
    width: ${sizeMap[size].width}px;
  `}
`;

const Center = styled(Flex).attrs({ alignItems: 'center', justifyContent: 'center' })`
  height: 100%;
  flex-grow: 1;

  &:empty {
    display: none;
  }
`;
