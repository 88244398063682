import type TripApproveItemJson from '../trip_approve_item/trip_approve_item_json';
import type { DataType, Display, RequiredType, Target } from './approve_item';
import ApproveItem from './approve_item';

/**
 * TripReportApproveItem.json と ApproveItem は一部カラムの情報欠落があるため、完全な変換はできない
 * @param tripApproveItemJson
 * @param target
 * @returns ApproveItem
 */
export const convertTripApproveItemToApproveItem = (tripApproveItemJson: TripApproveItemJson, target: Target) =>
  new ApproveItem({
    id: tripApproveItemJson.id,
    user_display_name: tripApproveItemJson.userDisplayName,
    data_type: tripApproveItemJson.dataType as DataType,
    target, // TODO: target 情報が TripApproveItemJson に含まれていないため呼び出し側に決定してもらう
    display: tripApproveItemJson.display as Display,
    required_type: tripApproveItemJson.requiredType as RequiredType,
    placeholder: tripApproveItemJson.placeholder,
    business_trip_type: null,
    itemList: '', // TODO: TripApproveItemJson に含まれないため空文字固定
    default_item: null,
    default_item_code: null,
    default_item_name: null
  });
