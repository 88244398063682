import { Fetcher } from '@this/src/util';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import SuppliedItemMappingTabs from '@this/components/admin/supplied_item_mappings/share/supplied_item_mapping_tabs';
import type { SuppliedItemArgs } from '@this/domain/supplied_item/supplied_item';
import SuppliedItem from '@this/domain/supplied_item/supplied_item';
import type { IndividualRentalcarSuppliedItemArgs } from '@this/domain/individual_rentalcar_supplied_item/individual_rentalcar_supplied_item';
import IndividualRentalcarSuppliedItem from '@this/domain/individual_rentalcar_supplied_item/individual_rentalcar_supplied_item';
import { Title } from '../admin';
import { AdminContentIn } from '../shared/atom';
import { Button } from '../../shared/ui/inputs/button';

interface Response {
  item_mappings: IndividualRentalcarSuppliedItemArgs[];
  supplied_items: SuppliedItemArgs[];
}

const IndividualRentalcarSuppliedItems = () => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemMappings, setItemMappings] = useState<IndividualRentalcarSuppliedItem[]>([]);
  const [suppliedItems, setSuppliedItems] = useState<SuppliedItem[]>([]);

  const fetchItemMappings = () => {
    setLoading(true);
    Fetcher.get<Response>('/admin/individual_rentalcar_supplied_items.json')
      .then(res => {
        setItemMappings(res.item_mappings.map(mapping => new IndividualRentalcarSuppliedItem(mapping)));
        setSuppliedItems(res.supplied_items.map(item => new SuppliedItem(item)));
      })
      .catch(e => {
        alert(
          utils.dig(e, 'responseJSON', 'message') || '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveMapping = (mapping: IndividualRentalcarSuppliedItem) => {
    setSaving(true);
    Fetcher.put(`/admin/individual_rentalcar_supplied_items/${mapping.id}.json`, mapping.submitParams())
      .then(() => {
        alert('保存しました。');
      })
      .catch(e => {
        alert(
          utils.dig(e, 'responseJSON', 'message') || '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    fetchItemMappings();
  }, []);

  return (
    <div>
      <Title>仕入商品マッピング</Title>
      <AdminContentIn>
        <Description>個別にデフォルトの仕入商品を設定できます。</Description>
        <SuppliedItemMappingTabs />
        {loading ? (
          <Loading />
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>レンタカー利用契約形態</Th>
                <Th>仕入商品</Th>
                <Th>操作</Th>
              </tr>
            </thead>
            <tbody>
              {itemMappings.map(mapping => (
                <tr key={mapping.id}>
                  <Td>{mapping.contractualAgreementTypeLabel}</Td>
                  <Td>
                    <Select
                      value={mapping.suppliedItemId?.toString() || ''}
                      onChange={e => {
                        mapping.suppliedItemId = parseInt(e.target.value, 10) || null;
                      }}
                    >
                      <option value="">選択してください</option>
                      {suppliedItems.map(item => (
                        <option key={item.id} value={item.id.toString()}>
                          {item.supplierName}：{item.name}
                        </option>
                      ))}
                    </Select>
                  </Td>
                  <Td>
                    <Button onClick={() => saveMapping(mapping)} disabled={saving}>
                      保存
                    </Button>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </AdminContentIn>
    </div>
  );
};

const Description = styled.div`
  margin-bottom: 40px;
`;

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const Select = styled.select`
  margin: 0;
`;

export default observer(IndividualRentalcarSuppliedItems);
