Margin = require('./margin').default
MarginType = require('./organization/margin_type')
ShareholderTicket = require('./shareholder_ticket')
OrderItemOriginalPrice = require('./order_item_original_price').default
OrderItemPriceDetail = require('./order_item_price_detail').default

class OrderItemPrice
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    _.assign(@, args)
    @price ||= 0
    @originalPrice ||= 0
    @refund ||= 0
    @margin = new Margin(args.margin)
    @marginType = if args.marginType then new MarginType(args.marginType)
    @sectionNum = args.sectionNum || 1
    @shareholderTicketIds = _.toArray(args.shareholderTicketIds) || []
    if @shareholderTicketIds.length < 1
      @shareholderTicketIds.push('')
    @bulkTicketIds = _.toArray(args.bulkTicketIds)
    @bulkTicketFragmentIds = _.toArray(args.bulkTicketFragmentIds)
    if args.shareholderTickets
      @shareholderTickets = _.map args.shareholderTickets, (t) -> new ShareholderTicket(t)
    @originalPrices =
      if args.orderItemOriginalPrices
        _.map args.orderItemOriginalPrices, (raw) -> new OrderItemOriginalPrice(raw)
      else
        [new OrderItemOriginalPrice({})]
    @taxTypeId = args.taxTypeId
    @priceDetails = []
    if args.orderItemPriceDetails
      @priceDetails = _.map args.orderItemPriceDetails, (raw) -> new OrderItemPriceDetail(raw)

  setPrice: (value) ->
    @price = value
    app.render()

  setOriginalPrice: (value) ->
    @originalPrice = value
    app.render()

  setRefund: (value) ->
    @refund = value
    app.render()

  setPeopleNum: (value) ->
    @peopleNum = value
    @setBulkTicketLength(@peopleNum)
    @setBulkTicketFragmentLength(@peopleNum)
    app.render()

  setBulkTicketLength: (count) ->
    @bulkTicketIds = _.times count, (i) =>
      @bulkTicketIds[i] || ''

  setBulkTicketFragmentLength: (count) ->
    @bulkTicketFragmentIds = _.times count, (i) =>
      @bulkTicketFragmentIds[i] || ''

  setBulkTicket: (index, value) =>
    @bulkTicketIds[index] = value
    app.render()

  setBulkTicketFragment: (index, value) =>
    @bulkTicketFragmentIds[index] = value
    app.render()

  setTaxTypeId: (value) =>
    @taxTypeId = value
    app.render()

  setSelfLoss: (value) ->
    @selfLoss = value
    app.render()

  setDebtWaiver: (value) ->
    @debtWaiver = value
    app.render()

  setLossWorkflowUrl: (value) ->
    @lossWorkflowUrl = value
    app.render()

  setPaymentMethod: (value) ->
    @originalPrices.forEach((e) -> e.setPaymentMethod(value))
    app.render()

  handleShareholderTicketAdd: () ->
    @shareholderTicketIds.push('')
    app.render()

  handleShareholderTicketChange: (index, value) ->
    @shareholderTicketIds[index] = value
    app.render()

  handleShareholderTicketRemove: (index) ->
    @shareholderTicketIds.splice(index, 1)
    app.render()

  totalPrice: ->
    p = @price
    p += @margin.amount if @showFee
    p

  totalPriceWithAll: ->
    @price + @margin.amount

  setOriginalTotalPrice: ->
    newprice = 0
    @originalPrices.forEach((e) -> newprice += e.price)
    @originalPrice = newprice

  calcMarginAmount: (e) ->
    e.preventDefault()
    marginAmount = @computeMarginAmount()
    @margin.setAmount(marginAmount)
    app.render()

  computeMarginAmount: ->
    if @marginType && @marginType.constructor.name == 'MarginType'
      @marginType.calcMarginAmount(@price, @peopleNum, @sectionNum)
    # 本来はOrderItemのcurrentMarginTypeでnewすべきだが、
    # is not constructorのエラーが出てセットできないため、ここでセットし直している。
    # 参考:https://github.com/BEST10developers/travel.ai/pull/5026
    else if @marginType
      @marginType = new MarginType(@marginType)
      @marginType.calcMarginAmount(@price, @peopleNum, @sectionNum)
    else
      MarginType.calcDefaultMarginAmount(@peopleNum, @sectionNum)

  isNeedReCalcMarginAmount: ->
    currentMarginAmount = @computeMarginAmount()
    @margin.amount != currentMarginAmount

  description: ->
    d = """
    <料金>
    #{formatPrice(@totalPrice())}
    """
    if @priceDetails.length > 1
      d += _.map(@priceDetails, (detail) -> "\n└ #{detail.name}：#{formatPrice(detail.price)}").join('')
    else
      d += "\n└ 商品代金：#{formatPrice(@price)}"
    d += "\n└ 手数料：#{formatPrice(@margin.amount)}" if @showFee && @margin.amount > 0
    d

  updateParams: ->
    price: @price
    original_price: @originalPrice
    original_prices: _.map(@originalPrices, (p) -> p.updateParams())
    price_details: _.map(@priceDetails, (detail) -> detail.updateParams())
    refund: @refund
    margin: @margin.updateParams()
    shareholder_ticket_ids: @shareholderTicketIds
    bulk_ticket_ids: @bulkTicketIds
    bulk_ticket_fragment_ids: @bulkTicketFragmentIds
    prev_price_id: @id
    tax_type_id: @taxTypeId
    self_loss: @selfLoss
    debt_waiver: @debtWaiver
    loss_workflow_url: @lossWorkflowUrl

  validationErrors: ->
    _.flatMap(@originalPrices, (p) -> p.validationErrors())

module.exports = OrderItemPrice
