import type { ChangeEvent } from 'react';
import React, { useState } from 'react';

import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle
} from '@this/components/organization/trips/detail/trip_detail.style';

import TripApproveItemList from '@this/domain/trip_approve_item/trip_approve_item_list';
import type TripApproveItem from '@this/domain/trip_approve_item/trip_approve_item';
import type ItemList from '@this/domain/approve_item/item_list';
import DatetimePicker from '@this/components/shared/datetime_picker/datetime_picker';
import type Trip from '@this/domain/trip/trip';

import moment from '@this/lib/moment';
import SelectApproveItemList from '@this/components/shared/select_approveitemlist/select_approveitemlist';

interface Props {
  approveItemList: TripApproveItemList;
  trip: Trip;
  onSave: (tripApproveItemList: TripApproveItemList) => void;
}

const TripDetailApproveItemForm: React.FC<Props> = ({ approveItemList, trip, onSave }) => {
  const defaultApproveItemList = new TripApproveItemList(JSON.parse(JSON.stringify(approveItemList.list)));
  const [inputValues, setInputValues] = useState(approveItemList.convertObjects());
  const [editedApproveItemList, setEditedApproveItemList] = useState(defaultApproveItemList);
  const [approveItemListChanged, setApproveItemListChanged] = useState(false);

  const changeApproveTextarea = (id: number) => (e: ChangeEvent<HTMLTextAreaElement>) => {
    const approveItem = editedApproveItemList.find(id);
    if (approveItem !== undefined) {
      approveItem.value = e.target.value;
      setInputValues(editedApproveItemList.convertObjects());
      setApproveItemListChanged(true);
    }
  };

  const changeApproveCalendarSelect = (id: number) => (date: moment.Moment) => {
    const approveItem = editedApproveItemList.find(id);
    if (approveItem !== undefined) {
      if (date !== null) {
        approveItem.value = date.format('YYYY-MM-DD');
      } else {
        approveItem.value = '';
      }
      setInputValues(editedApproveItemList.convertObjects());
      setApproveItemListChanged(true);
    }
  };

  const changeApproveList = (id: number) => (itemList: ItemList | null) => {
    const approveItem = editedApproveItemList.find(id);
    if (approveItem !== undefined) {
      approveItem.value = itemList !== null ? itemList.name : '';
      approveItem.valueCode = itemList !== null ? itemList.code : '';
      setInputValues(editedApproveItemList.convertObjects());
      setApproveItemListChanged(true);
    }
  };

  const save = () => {
    onSave(editedApproveItemList || '');
    setApproveItemListChanged(false);
  };

  const reset = () => {
    setInputValues(defaultApproveItemList.convertObjects());
    setEditedApproveItemList(defaultApproveItemList);
    setApproveItemListChanged(false);
  };

  return (
    <MetaSection>
      {editedApproveItemList &&
        editedApproveItemList.list.map((item: TripApproveItem, i: number) => (
          <div key={i} style={{ marginBottom: '10px' }}>
            <MetaSectionTitle style={{ maxWidth: '300px', wordWrap: 'break-word' }}>
              {item.userDisplayName}
            </MetaSectionTitle>
            {item.dataType === 'freetext' && (
              <textarea placeholder={item.getReservePlaceholder(true)} onChange={changeApproveTextarea(item.id)}>
                {inputValues.get(item.id) || ''}
              </textarea>
            )}
            {item.dataType === 'multilinefreetext' && (
              <textarea placeholder={item.getReservePlaceholder(true)} onChange={changeApproveTextarea(item.id)}>
                {inputValues.get(item.id) || ''}
              </textarea>
            )}
            {item.dataType === 'calendar' && (
              <DatetimePicker
                onChange={changeApproveCalendarSelect(item.id)}
                value={
                  inputValues.get(item.id) !== '' && inputValues.get(item.id) !== undefined
                    ? moment(inputValues.get(item.id))
                    : undefined
                }
                disabledDays={0}
                showTime={false}
                showPast
                deletable
                border
              />
            )}
            {item.dataType === 'list' && (
              <SelectApproveItemList
                onSelect={changeApproveList(item.id)}
                approveItemId={item.id}
                defaultItemListName={inputValues.get(item.id) || ''}
                chargingDepartmentIds={trip.getChargingDepartmentIDs()}
                workflowFormWidth=""
                tripedit
              />
            )}
          </div>
        ))}
      {approveItemListChanged && (
        <MetaSectionFormActions>
          <MetaSectionFormButton onClick={() => save()}>保存</MetaSectionFormButton>
          <MetaSectionFormButton onClick={() => reset()}>キャンセル</MetaSectionFormButton>
        </MetaSectionFormActions>
      )}
    </MetaSection>
  );
};

export default TripDetailApproveItemForm;
