import React, { useState } from 'react';
import { observer } from 'mobx-react';

import type MailSendLog from '@this/src/domain/mail_send_log';

import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import { Button } from '../../shared/ui/inputs/button';

interface Props {
  mailSendLog: MailSendLog;
}

const MailSendLogsTableRow = observer(({ mailSendLog }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const maxPreviewLines = 5;
  const previewMailBody = mailSendLog.body?.split('\n').slice(0, maxPreviewLines).join('\n');

  const formattedCc = mailSendLog.cc ? mailSendLog.cc.split(',').join('\n') : '';

  const handleToggle = () => {
    setExpanded(prev => !prev);
  };

  return (
    <TableRow>
      {/* ID */}
      <TableCell nowrap>{mailSendLog.id}</TableCell>

      {/* 旅程番号ID */}
      <TableCell nowrap>{mailSendLog.tripId}</TableCell>

      {/* 組織 */}
      <TableCell>{mailSendLog.organizationName}</TableCell>

      {/* メール件名 */}
      <TableCell width="300px">{mailSendLog.subject}</TableCell>

      {/* メール本文 */}
      <TableCell width="500px" style={{ whiteSpace: 'pre-wrap' }}>
        {expanded ? mailSendLog.body : previewMailBody}
        {mailSendLog.body && mailSendLog.body.split('\n').length > maxPreviewLines && (
          <>
            <br />
            <Button type="button" size="small" onClick={handleToggle}>
              {expanded ? '少なく表示' : '全文表示'}
            </Button>
          </>
        )}
      </TableCell>

      {/* 宛先To */}
      <TableCell width="200px">{mailSendLog.mailTo}</TableCell>

      {/* 宛先CC */}
      <TableCell width="200px" style={{ whiteSpace: 'pre-wrap' }}>
        {formattedCc}
      </TableCell>

      {/* 送信日時 */}
      <TableCell>{mailSendLog.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>
    </TableRow>
  );
});

export default MailSendLogsTableRow;
