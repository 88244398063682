import { observable } from 'mobx';

export type OrderItemStatusLogResponseArgs = {
  arranger_name: string;
};

export type OrderItemStatusLogArgs = {
  arrangerName: string;
};

export const convertOrderItemStatusLogResponseToArgs = (
  response: OrderItemStatusLogResponseArgs
): OrderItemStatusLogArgs => {
  const args: OrderItemStatusLogArgs = {
    arrangerName: response.arranger_name
  };
  return args;
};

export default class OrderItemStatusLog {
  @observable arrangerName: string;

  constructor(args: OrderItemStatusLogArgs) {
    this.arrangerName = args.arrangerName;
  }
}
