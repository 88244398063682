import { observable } from 'mobx';

export type RemittanceSourceAccountJson = {
  id?: number;
  assignor_code: string;
  assignor_name: string;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  account_type: 'savings' | 'checking';
  account_number: string;
};

class RemittanceSourceAccount {
  private id?: number;

  @observable
  assignorCode: string;

  @observable
  assignorName: string;

  @observable
  bankCode: string;

  @observable
  bankName: string;

  @observable
  branchCode: string;

  @observable
  branchName: string;

  @observable
  accountType: 'savings' | 'checking';

  @observable
  accountNumber: string;

  constructor(args: RemittanceSourceAccountJson) {
    this.id = args.id;
    this.assignorCode = args.assignor_code;
    this.assignorName = args.assignor_name;
    this.bankCode = args.bank_code;
    this.bankName = args.bank_name;
    this.branchCode = args.branch_code;
    this.branchName = args.branch_name;
    this.accountType = args.account_type;
    this.accountNumber = args.account_number;
  }

  submitParams() {
    return {
      id: this.id,
      assignor_code: this.assignorCode,
      assignor_name: this.assignorName,
      bank_code: this.bankCode,
      bank_name: this.bankName,
      branch_code: this.branchCode,
      branch_name: this.branchName,
      account_type: this.accountType,
      account_number: this.accountNumber
    };
  }

  static build(): RemittanceSourceAccount {
    return new RemittanceSourceAccount({
      assignor_code: '',
      assignor_name: '',
      bank_code: '',
      bank_name: '',
      branch_code: '',
      branch_name: '',
      account_type: 'savings',
      account_number: ''
    });
  }
}

export default RemittanceSourceAccount;
