import { observable } from 'mobx';
import type { KnowledgeCategoryArgs, KnowledgeCategoryResponseArgs } from './knowledge_category';
import KnowledgeCategory, { convertKnowledgeCategoryResponseToArgs } from './knowledge_category';
import type { KnowledgeSubCategoryArgs, KnowledgeSubCategoryResponseArgs } from './knowledge_sub_category';
import KnowledgeSubCategory, { convertKnowledgeSubCategoryResponseToArgs } from './knowledge_sub_category';

export type KnowledgeResponseArgs = {
  salesforce_id: string;
  title: string;
  question: string;
  answer: string;
  category?: KnowledgeCategoryResponseArgs;
  sub_category?: KnowledgeSubCategoryResponseArgs;
};

export type KnowledgeArgs = {
  salesforceId: string;
  title: string;
  question: string;
  answer: string;
  category?: KnowledgeCategoryArgs;
  subCategory?: KnowledgeSubCategoryArgs;
};

export const convertKnowledgeResponseToArgs = (response: KnowledgeResponseArgs) => {
  const args: KnowledgeArgs = {
    salesforceId: response.salesforce_id,
    title: response.title,
    question: response.question,
    answer: response.answer,
    category: response.category ? convertKnowledgeCategoryResponseToArgs(response.category) : undefined,
    subCategory: response.sub_category
      ? convertKnowledgeSubCategoryResponseToArgs(response.sub_category)
      : undefined
  };
  return args;
};

export default class Knowledge {
  @observable salesforceId: string;

  @observable title: string;

  @observable question: string;

  @observable answer: string;

  @observable category?: KnowledgeCategory;

  @observable subCategory?: KnowledgeSubCategory;

  constructor(args: KnowledgeArgs) {
    this.salesforceId = args.salesforceId;
    this.title = args.title;
    this.question = args.question;
    this.answer = args.answer;
    this.category = args.category ? new KnowledgeCategory(args.category) : undefined;
    this.subCategory = args.subCategory ? new KnowledgeSubCategory(args.subCategory) : undefined;
  }
}
