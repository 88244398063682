export interface SmartHrAssociateColumnArgs {
  id?: number;
  target_column: string;
  target: number;
  target_text: string;
  associate_column: string;
  custom_name: string;
}

export default class SmartHrAssociateColumn {
  public id: number | null;

  public targetColumn: string;

  public target: number;

  public targetText: string;

  public associateColumn: string;

  public customName: string;

  constructor(args: SmartHrAssociateColumnArgs) {
    this.id = args.id || null;
    this.targetColumn = args.target_column;
    this.target = args.target;
    this.targetText = args.target_text;
    this.associateColumn = args.associate_column;
    this.customName = args.custom_name;
  }

  setAssociateColumn(value: string) {
    this.associateColumn = value;
    app.render();
  }

  setCustomName(value: string) {
    this.customName = value;
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      target_column: this.targetColumn,
      target: this.target,
      target_text: this.targetText,
      associate_column: this.associateColumn,
      custom_name: this.customName
    };
  }

  static initialize() {
    return new SmartHrAssociateColumn({
      target_column: 'name',
      target: 2,
      target_text: '',
      associate_column: '',
      custom_name: ''
    });
  }
}
