import React from 'react';
import { styled } from '@this/constants/themes';
import { css } from 'styled-components';

export type FlexProps = {
  children: React.ReactNode;
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'strech' | 'baseline';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'strech' | 'space-between' | 'space-around';
  gap?: string;
};
export const Flex = ({ children, ...res }: FlexProps) => <StyledFlexLayout {...res}>{children}</StyledFlexLayout>;
const StyledFlexLayout = styled.div<Omit<FlexProps, 'children'>>`
  display: flex;
  ${props => css`
    flex-direction: ${props.flexDirection};
    flex-wrap: ${props.flexDirection};
    justify-content: ${props.justifyContent};
    align-items: ${props.alignItems ?? 'flex-start'};
    align-content: ${props.alignContent};
    gap: ${props.gap};
  `}
`;
