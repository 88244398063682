import React from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@this/constants/themes';
import { Input } from '@this/shared/ui/inputs/input';
import { Button } from '@this/shared/ui/inputs/button';

export interface UrlQuery {
  trip_id: number | undefined;
  subject: string;
  mail_to: string;
  cc: string;
}

export type UrlQueryAction =
  | { type: 'SET_TRIP_ID'; payload: string }
  | { type: 'SET_SUBJECT'; payload: string }
  | { type: 'SET_MAIL_TO'; payload: string }
  | { type: 'SET_CC'; payload: string };

interface Props {
  urlQuery: UrlQuery;
  dispatch: Dispatch<ReducerAction<Reducer<{ urlQuery: UrlQuery }, UrlQueryAction>>>;
  renderOrganizationSelector: () => JSX.Element;
  fetchMailSendLogs: () => void;
}

export const urlQueryReducer = (state: UrlQuery, action: UrlQueryAction): UrlQuery => {
  switch (action.type) {
    case 'SET_TRIP_ID': {
      const trip_id = action.payload ? parseInt(action.payload, 10) : undefined;
      return { ...state, trip_id };
    }
    case 'SET_SUBJECT':
      return { ...state, subject: action.payload };
    case 'SET_MAIL_TO':
      return { ...state, mail_to: action.payload };
    case 'SET_CC':
      return { ...state, cc: action.payload };
    default:
      return state;
  }
};

const getStringParam = (key: string): string => {
  return utils.getParam(key) || '';
};

const getNumberParam = (key: string): number | undefined => {
  const str = utils.getParam(key);
  return str ? parseInt(str, 10) : undefined;
};

export const initialUrlQuery: UrlQuery = {
  trip_id: getNumberParam('trip_id'),
  subject: getStringParam('subject'),
  mail_to: getStringParam('mail_to'),
  cc: getStringParam('cc')
};

const MailSendLogsSearchArea: React.FC<Props> = ({
  urlQuery,
  dispatch,
  renderOrganizationSelector,
  fetchMailSendLogs
}) => {
  const { trip_id, subject, mail_to, cc } = urlQuery;

  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>旅程番号ID</SearchLabel>
        <Input
          value={trip_id || ''}
          onChange={e => dispatch({ type: 'SET_TRIP_ID', payload: e.target.value })}
          style={{ width: '120px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>メール件名</SearchLabel>
        <Input
          value={subject || ''}
          onChange={e => dispatch({ type: 'SET_SUBJECT', payload: e.target.value })}
          style={{ width: '220px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>宛先To</SearchLabel>
        <Input
          value={mail_to || ''}
          onChange={e => dispatch({ type: 'SET_MAIL_TO', payload: e.target.value })}
          style={{ width: '200px' }}
        />
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>宛先CC</SearchLabel>
        <Input
          value={cc || ''}
          onChange={e => dispatch({ type: 'SET_CC', payload: e.target.value })}
          style={{ width: '200px' }}
        />
      </SearchBlock>
      <Box>
        <Button type="submit" onClick={fetchMailSendLogs}>
          検索
        </Button>
      </Box>
    </SearchArea>
  );
};

const SearchArea = styled(Box)`
  display: flex;
  background-color: #f7f7f7;
  padding: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default MailSendLogsSearchArea;
