import type { SearchType } from './search_query';
import { SEARCH_TYPE_LABELS } from './search_query';
import type { SearchTrackingLogStatus } from './search_tracking_log';
import { SEARCH_TRACKING_LOG_STATUS_LABELS, SEARCH_TRACKING_PATH_LABELS } from './search_tracking_log';

export interface SearchTrackingOptionsArgs {
  status: SearchTrackingLogStatus[];
  path: string[];
  search_type: SearchType[];
}

interface Option {
  value: string;
  label: string;
}

class SearchTrackingOptions {
  status: Option[];

  path: Option[];

  searchType: Option[];

  constructor(args: SearchTrackingOptionsArgs) {
    this.status = args.status.map(status => ({
      value: status,
      label: SEARCH_TRACKING_LOG_STATUS_LABELS[status]
    }));
    this.path = args.path.map(path => ({
      value: path,
      label: SEARCH_TRACKING_PATH_LABELS[path]
    }));
    this.searchType = args.search_type.map(searchType => ({
      value: searchType,
      label: SEARCH_TYPE_LABELS[searchType]
    }));
  }

  static default(): SearchTrackingOptions {
    return new SearchTrackingOptions({
      status: Object.keys(SEARCH_TRACKING_LOG_STATUS_LABELS) as SearchTrackingLogStatus[],
      path: Object.keys(SEARCH_TRACKING_PATH_LABELS),
      search_type: Object.keys(SEARCH_TYPE_LABELS) as SearchType[]
    });
  }
}

export default SearchTrackingOptions;
