import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import Transit from '@this/domain/transit/transit';
import type Flight from '@this/domain/flight/flight';
import type Hotel from '@this/domain/hotel/hotel';

type Props = {
  elementType: 'transport' | 'hotel' | 'flight' | undefined;
  element: Transit | Hotel | Flight;
};

const ReserveConfirmOutlineItemShortdistanceTemplate: React.SFC<Props> = ({ elementType, element }) => {
  return (
    <ReserveConfirmRightSection>
      {elementType === 'transport' && element instanceof Transit && (
        <>
          {element.legsummaries_fromto_before && (
            <>
              <ReserveConfirmRightTitle>
                <p>{element.reserveConfirmElementLabelByType(element.legsummaries_typeprice_before.type)}</p>
                <p>
                  <span data-wovn-ignore>{utils.digits(element.legsummaries_typeprice_before.price)}</span>円
                </p>
              </ReserveConfirmRightTitle>
              <ReserveConfirmRightTitle>{element.legsummaries_fromto_before}</ReserveConfirmRightTitle>
            </>
          )}
          {element.legsummaries_fromto_after && (
            <>
              <ReserveConfirmRightTitle>
                <p>{element.reserveConfirmElementLabelByType(element.legsummaries_typeprice_after.type)}</p>
                <p>
                  <span data-wovn-ignore>{utils.digits(element.legsummaries_typeprice_after.price)}</span>円
                </p>
              </ReserveConfirmRightTitle>
              <ReserveConfirmRightTitle>{element.legsummaries_fromto_after}</ReserveConfirmRightTitle>
            </>
          )}
        </>
      )}
    </ReserveConfirmRightSection>
  );
};

const ReserveConfirmRightSection = styled.div`
  margin-bottom: 15px;
`;

const ReserveConfirmRightTitle = styled.div`
  font-weight: bold;
  color: #959595;
  padding: 10px 10px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ReserveConfirmOutlineItemShortdistanceTemplate;
