import React from 'react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { Link } from '@this/shared/ui/navigations/link';
import { Input } from '@this/shared/ui/inputs/input';
import { Box } from '@material-ui/core';
import { getColor, getSpacing } from '@this/shared/ui/theme';
import { Text } from '@this/shared/ui/data_displays/typography';

interface UserPasswordResetFormProps {
  isPasswordExpired: boolean;
  navigateToBack: () => void;
  resetPassword: (email: string) => Promise<boolean>;
}
interface UserPasswordResetFormState {
  form: { email: string };
  result?: { type: UserPasswordResetFormResultType; message: string };
}
enum UserPasswordResetFormResultType {
  SUCCESS = 'success',
  FAIL = 'fail'
}
class UserPasswordResetForm extends React.Component<UserPasswordResetFormProps, UserPasswordResetFormState> {
  constructor(props: UserPasswordResetFormProps) {
    super(props);
    this.state = { form: { email: '' } };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target as HTMLInputElement;
    this.setState({ form: { email: value } });
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const result = await this.props.resetPassword(this.state.form.email);
    if (result) {
      this.setState({
        result: {
          type: UserPasswordResetFormResultType.SUCCESS,
          message: 'リセット用のリンクをメールで送信しました'
        },
        form: { email: '' }
      });
      return;
    }
    this.setState({
      result: {
        type: UserPasswordResetFormResultType.FAIL,
        message: '通信環境が不安定です。時間をおいてもう一度お試しください。'
      }
    });
  }

  get messageClassName(): string {
    if (!this.state.result) {
      return '';
    }

    return this.state.result.type === UserPasswordResetFormResultType.SUCCESS ? 'success' : 'error';
  }

  render() {
    const { isPasswordExpired } = this.props;

    return (
      <FormContainer>
        <Form onSubmit={this.handleSubmit}>
          {!this.state.result || (
            <ResultMsg className={this.messageClassName}>{this.state.result.message}</ResultMsg>
          )}
          {isPasswordExpired && (
            <PasswordExpiredMsg>
              パスワードの有効期限が過ぎました。
              <br />
              メールアドレスを入力してパスワードを再設定してください。
            </PasswordExpiredMsg>
          )}

          <Box mb={1}>
            <Input
              type="email"
              name="email"
              placeholder="メールアドレス"
              value={this.state.form.email}
              onChange={this.handleChange}
              fullWidth
            />
          </Box>

          <Button className="pw-reset-button" type="submit" disabled={!this.state.form.email} fullWidth>
            パスワード再設定
          </Button>
        </Form>
        {!isPasswordExpired && (
          <Link className="signin-form-link" onClick={this.props.navigateToBack}>
            戻る
          </Link>
        )}
      </FormContainer>
    );
  }
}

const FormContainer = styled.div`
  width: 100%;
  background-color: ${getColor('background', 'primary')};
  padding: ${getSpacing(8)}px;
  text-align: right;
`;

const Form = styled.form`
  margin-bottom: ${getSpacing(2)}px;
`;

const ResultMsg = styled.p`
  text-align: left;
`;

const PasswordExpiredMsg = styled(Text)`
  margin-bottom: ${getSpacing(2)}px;
  text-align: left;
`;

export default UserPasswordResetForm;
