import { observable } from 'mobx';

export interface InfomartApiTokenArgs {
  id: number;
  organization_id: number;
  company_name_org: string;
  customer_mng_num: string;
}

export default class InfomartApiToken {
  id: number;

  organizationId: number;

  @observable
  companyNameOrg: string;

  @observable
  customerMngNum: string;

  constructor(arg: InfomartApiTokenArgs) {
    this.id = arg.id;
    this.organizationId = arg.organization_id;
    this.companyNameOrg = arg.company_name_org || '';
    this.customerMngNum = arg.customer_mng_num || '';
  }
}
