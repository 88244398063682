import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, styled } from '@this/constants/themes';
import { AITThemeProvider } from '@this/shared/ui/theme';

interface DestMarkerProps {
  serviceId: number;
}

const DestMarkerDiv = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 3px 5px;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: ${props => props.theme.redColor};
  color: white;
  position: relative;
  ::after {
    bottom: 0;
    left: 50%;
    border: solid transparent;
    content: '';
    position: absolute;
    pointer-events: none;
    border-color: ${props => props.theme.redColor} transparent transparent;
    border-width: 7px 5px 0 5px;
    translate: -50% 100%;
  }
`;

const DestMarkerComponent = ({ serviceId }: DestMarkerProps) => {
  const theme = createTheme(serviceId);
  return (
    <AITThemeProvider theme={theme}>
      <DestMarkerDiv>入力した目的地</DestMarkerDiv>
    </AITThemeProvider>
  );
};

class DestMarker extends google.maps.OverlayView {
  location: google.maps.LatLng;

  content: HTMLDivElement | null;

  serviceId: number;

  constructor(location: { lat: number; lng: number }, serviceId: number) {
    super();
    this.location = new google.maps.LatLng(location.lat, location.lng);
    this.content = null;
    this.serviceId = serviceId;
  }

  draw() {
    if (!this.content) {
      const content = document.createElement('div');
      content.style.position = 'absolute';
      content.style.zIndex = '1';

      const panes = this.getPanes();
      panes.overlayLayer.appendChild(content);
      panes.overlayMouseTarget.appendChild(content);

      this.content = content;

      ReactDOM.render(React.createElement(DestMarkerComponent, { serviceId: this.serviceId }), this.content);
    }
    const projection = this.getProjection();
    if (!this.location) {
      return;
    }
    const point = projection.fromLatLngToDivPixel(this.location);
    this.content.style.left = `${point.x - this.content.offsetWidth / 2}px`;
    this.content.style.top = `${point.y - this.content.offsetHeight - 6.6}px`;
  }

  // eslint-disable-next-line class-methods-use-this
  onRemove() {}
}
export default DestMarker;
