import React from 'react';
import { Button as MuiButton, makeStyles } from '@material-ui/core';
import type { SizingProps } from '@material-ui/system';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import type { AITTheme } from '@this/shared/ui/theme';
import { getSpacing } from '@this/shared/ui/theme';

type BaseButtonProps = React.ComponentProps<typeof MuiButton>;

export const BUTTON_COLOR = {
  PRIMARY: 'primary',
  SUB: 'sub',
  DANGER: 'danger'
} as const;
export type ButtonColor = typeof BUTTON_COLOR[keyof typeof BUTTON_COLOR];

export const BUTTON_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large'
} as const;
export type ButtonSize = typeof BUTTON_SIZE[keyof typeof BUTTON_SIZE];
export type ButtonProps = {
  color?: ButtonColor;
  size?: ButtonSize;
  isExternal?: boolean; // react-routerでの遷移をさせたくないときに使用
  loading?: boolean;
  target?: string;
} & Omit<BaseButtonProps, 'color' | 'size' | 'variant'> &
  SizingProps;

const useStyles = makeStyles<AITTheme>(theme => {
  return {
    root: {
      ...(theme.overrides?.Button?.root ?? {})
    }
  };
});

const buttonTypes: Record<ButtonColor, { color: BaseButtonProps['color']; variant: BaseButtonProps['variant'] }> =
  {
    primary: {
      color: 'primary',
      variant: 'contained'
    },
    sub: { color: 'primary', variant: 'outlined' },
    danger: { color: 'secondary', variant: 'contained' }
  };

/**
 * AI Travelで使用できるButtonコンポーネント
 * Material UIのボタンを拡張しています。colorとvariant以外のpropsは下記を参照してください。
 * もしくは、同階層内にあるStoryを確認してください。
 *
 * {@link https://v4.mui.com/components/buttons/}
 */
export const Button = ({
  className,
  color: baseColor = 'primary',
  href,
  disabled,
  loading,
  isExternal,
  children,
  ...props
}: ButtonProps) => {
  const { color, variant } = buttonTypes[baseColor];
  const hrefProps: { component?: typeof Link; href?: string; to?: string } = {};
  if (href) {
    if (isExternal) {
      hrefProps.href = href;
    } else {
      hrefProps.component = Link;
      hrefProps.to = href;
    }
  }
  const styles = useStyles();

  return (
    <MuiButton
      className={`${styles.root} ${className}`}
      color={color}
      variant={variant}
      disabled={loading || disabled}
      {...hrefProps}
      {...props}
    >
      {children}
      <ButtonWithLoading loading={!!loading} />
    </MuiButton>
  );
};

const ButtonWithLoading = styled(Loading).attrs({ size: 'small', centered: false })`
  margin-left: ${getSpacing(2)}px;
`;
