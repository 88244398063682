import {
  getBreakpointsFromToken,
  getColorFromToken,
  getRadiusFromToken,
  getSpacingFromToken,
  getTypogragyFromToken,
  getZindexFromToken
} from '../tokens';
import type { AITTheme } from '../theme';

type Token = AITTheme['tokens'];

/**
 * StyledComponent内で簡単にデザイントークンと取得するためのユーティリティ。
 *
 * TODO: 本当は `token('colors.brand.primary')` というような形で取得できる関数を定義したいが、
 * TS4.1のtemplate literal typesがないと型安全にできないのでtokenの種別ごとに関数を用意する
 */

type ScProps = { theme: AITTheme };

/**
 * StyledComponentのpropsを受け取って、デザイントークンで定義されている指定の色を取得します。
 * @example
 * ```
 * const Text = styled.div`
 *   color: ${getColor('brand', 'primary')}
 * `
 * ```
 */
export const getColor =
  <T extends keyof Token['colors']>(name: T, level?: keyof Token['colors'][T]) =>
  ({ theme }: ScProps) => {
    return getColorFromToken(theme.tokens, name, level);
  };

/**
 * StyledComponentのpropsを受け取って、デザイントークンで定義されているfontを取得します。
 * @example
 * ```
 * const Text = styled.div`
 *   font-size: ${ge tTypogragy('fontSizes', 'body')}
 * `
 * ```
 */
export const getTypogragy =
  <T extends keyof Token['typographies']>(name: T, level?: keyof Token['typographies'][T]) =>
  ({ theme }: ScProps) => {
    return getTypogragyFromToken(theme.tokens, name, level);
  };

/**
 * StyledComponentのpropsを受け取って、余白を返却します。
 *
 * @example
 * ```
 * const Text = styled.div`
 *   margin-left: ${getSpacing(2)}
 * `
 * ```
 */
export const getSpacing =
  (level: number) =>
  ({ theme }: ScProps) => {
    return getSpacingFromToken(theme.tokens, level);
  };

/**
 * StyledComponentのpropsを受け取って、角丸を取得する関数を取得します。
 * 角丸サイズはboxの短辺に応じて取得します。
 *
 * @example
 * ```
 * const Text = styled.div`
 *   border-raidus: ${getRadius(100)}
 * `
 * ```
 */
export const getRadius =
  (boxSize: number) =>
  ({ theme }: ScProps) => {
    return getRadiusFromToken(theme.tokens, boxSize);
  };

/**
 * StyledComponentのpropsを受け取って、z-indexを返却します。
 *
 * @example
 * ```
 * const Text = styled.div`
 *   z-index: ${getZindex('tooltip')}
 * `
 * ```
 */
export const getZindex =
  (level: keyof Token['zIndices'], adjust?: number) =>
  ({ theme }: ScProps) => {
    return getZindexFromToken(theme.tokens, level);
  };

/**
 * StyledComponentのpropsを受け取って、ブレークポイントを返却します。
 */
export const getBreakpoints =
  (level: keyof Token['breakpoints']) =>
  ({ theme }: ScProps) => {
    return getBreakpointsFromToken(theme.tokens, level);
  };
