import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';
import Modal from '@this/components/shared/modal/modal';

interface Props {
  showExicModal: boolean;
  tripId: number | null;
  handleSubmitExic: (id: number | null, e: React.MouseEvent<HTMLElement>) => void;
  handleCancelExic: () => void;
  exicError: string | null;
}

const ExicModal: React.SFC<Props> = ({ showExicModal, tripId, handleSubmitExic, handleCancelExic, exicError }) => {
  return (
    <Modal hideModal={handleCancelExic} show={showExicModal} title="JR東海EX予約">
      <Message>ここから先はAIトラベルのWebサイトではありません</Message>
      <Hint>JR東海のWebサイトに移動します</Hint>
      {exicError && <Error>{exicError}</Error>}

      <Buttons>
        <Button disabled={!!exicError} onClick={e => handleSubmitExic(tripId, e)}>
          続ける
        </Button>
        <BackButton onClick={handleCancelExic}>キャンセル</BackButton>
      </Buttons>
    </Modal>
  );
};

const Message = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Hint = styled.p`
  text-align: center;
  margin-bottom: 10px;
`;

const Error = styled.p`
  color: ${props => props.theme.redColor};
  margin: 20px;
  text-align: center;
`;

const Buttons = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Button = styled.button`
  ${ButtonBase}
  display: inline-block;
`;

const BackButton = styled(Button)`
  margin-left: 20px;
`;

export default ExicModal;
