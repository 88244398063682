import type { BulkActionAttr } from './types';

export const UPDATE_FORMAT: BulkActionAttr[] = [
  {
    name: 'project_code',
    summary: 'プロジェクトコード',
    example: '',
    required: true
  },
  {
    name: 'approver',
    summary: '承認者',
    example: `承認者のメールアドレスを指定します。
例) sato@example.com
`,
    required: true
  }
];
