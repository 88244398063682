export interface SmartHrAssociateColumnTypeArgs {
  id: number;
  target: number;
  select_value: string;
  display_name: string;
}

export default class SmartHrAssociateColumnType {
  public id: number;

  public target: number;

  public selectValue: string;

  public displayName: string;

  constructor(args: SmartHrAssociateColumnTypeArgs) {
    this.id = args.id;
    this.target = args.target;
    this.selectValue = args.select_value;
    this.displayName = args.display_name;
  }
}
