import React from 'react';
import type { InAdvanceMessageTemplate } from '@this/src/domain/in_advance/in_advance_message_template';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';

type Props = {
  messageTemplate: InAdvanceMessageTemplate;
  onSubmit: () => void;
  hideModal: () => void;
};

const DeleteFormModal: React.FC<Props> = ({ messageTemplate, onSubmit, hideModal }) => {
  return (
    <Modal open={Boolean(messageTemplate)} onClose={hideModal} className="e2e-confirm-delete">
      <ModalHeader>メッセージテンプレートの削除</ModalHeader>
      <ModalBody>
        メッセージテンプレートを削除してよろしいですか？
        <br />
        ※この操作は取り消せません
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="sub" onClick={hideModal}>
          いいえ
        </Button>
        <Button type="button" onClick={onSubmit}>
          はい
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteFormModal;
