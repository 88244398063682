/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';

import TripDetailDepartmentForm from '@this/components/organization/trips/detail/trip_detail_department_form';
import type Department from '@this/domain/department/department';
import type { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type { UpdationTripParams, UpdationTripApproveItemParams } from '@this/components/organization/trips/types';
import TripDetailProjectForm from '@this/components/organization/trips/detail/trip_detail_project_form';
import TripDetailExpensesAccountTypeForm from '@this/components/organization/trips/detail/trip_detail_expenses_account_type_form';
import FinalDestinationForm from '@this/components/organization/trips/detail/final_destination_form';
import TripDetailInternalNumberForm from '@this/components/organization/trips/detail/trip_detail_internal_number_form';
import TripDetailApproveItemForm from '@this/components/organization/trips/detail/trip_detail_approve_item_form';
import PurposeForm from '@this/components/organization/trips/detail/purpose_form';
import type ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import type OrderItemMappingList from '@this/domain/order_item_mapping_list';
import type TripApproveItemList from '@this/domain/trip_approve_item/trip_approve_item_list';
import {
  MetaSection,
  MetaSectionPanel,
  MetaSectionTitle
} from '@this/components/organization/trips/detail/trip_detail.style';
import type TransportElement from '@this/domain/transport_element';
import type HotelElement from '@this/domain/hotel_element';
import type { Moment } from 'moment';
import type ElementBase from '@this/domain/element_base';
import type NonOrderItemMappingList from '@this/domain/non_order_item_mapping_list';
import Element from './element/element';
import type { KeyValue } from '../../../shared/formatted_key_value/formatted_key_value';
import FormattedKeyValue from '../../../shared/formatted_key_value/formatted_key_value';

import type Trip from '../../../../domain/trip/trip';
import type Project from '../../../../domain/project/project';
import type Traveler from '../../../../domain/traveler/traveler';
import OrderItem from '../../../../domain/order_item';
import NonOrderItem from '../../../../domain/non_order_item';
import type User from '../../../../domain/user/user';
import Notification from '../../../../notification';
import type ProjectShareList from '../../../../domain/project/project_share_list';

interface Props {
  trip: any;
  projects: Project[];
  projectShares: ProjectShareList;
  projectShareAvailability: boolean;
  departments: Department[];
  expensesAccountTypes: ExpensesAccountType[];
  expensesAccountTypeAvailable: boolean;
  chargingDepartmentShares: ChargingDepartmentShareList;
  onUpdateTrip: (params: UpdationTripParams) => Promise<void>;
  onUpdateTripApproveItems: (params: UpdationTripApproveItemParams) => Promise<void>;
  serviceId: number;
  tripReportAvailable: boolean;
}

class Detail extends React.Component<Props> {
  saveInternalNumber = async (internalNumber: Trip['internalNumber']) => {
    await this.props.onUpdateTrip({
      project_id: this.props.trip.projectId(),
      internal_number: internalNumber,
      final_destination: this.props.trip.final_destination,
      purpose: this.props.trip.purpose,
      department_id: this.props.trip.departmentId()
    });

    Notification.success('社内管理番号を更新しました。');
  };

  saveFinalDestination = async (finalDestination: Trip['final_destination']) => {
    await this.props.onUpdateTrip({
      project_id: this.props.trip.projectId(),
      final_destination: finalDestination,
      purpose: this.props.trip.purpose,
      internal_number: this.props.trip.internalNumber,
      department_id: this.props.trip.departmentId()
    });

    Notification.success('出張先を更新しました。');
  };

  savePurpose = async (purpose: Trip['purpose']) => {
    await this.props.onUpdateTrip({
      project_id: this.props.trip.projectId(),
      final_destination: this.props.trip.final_destination,
      purpose,
      internal_number: this.props.trip.internalNumber,
      department_id: this.props.trip.departmentId()
    });

    Notification.success('出張の目的を更新しました。');
  };

  updateProjectMappings = (orderItemMappings: OrderItemMappingList) => {
    const promises: Promise<unknown>[] = [];
    orderItemMappings.list.forEach(mapping => {
      const url = `/organization/trips/order_item_mappings/${mapping.id}`;
      promises.push(utils.jsonPromise(url, mapping.submitParamsWithoutDepartment(), 'PUT'));
    });
    return promises;
  };

  saveProject = async (
    projectId: ReturnType<Trip['projectId']>,
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    await this.props.onUpdateTrip({
      project_id: projectId,
      internal_number: this.props.trip.internalNumber,
      department_id: this.props.trip.departmentId(),
      show_project_detail: false
    });

    let promises: Promise<unknown>[] = [];
    if (this.props.trip.currentOrder) {
      promises = this.updateProjectMappings(orderItemMappings);
    } else if (this.props.trip.nonOrderItems) {
      promises = this.updateNonOrderItemMappings(nonOrderItemMappings, [
        'charging_department_id',
        'expenses_account_type_id'
      ]);
    }
    Promise.all(promises).then(
      () => {
        Notification.success('プロジェクトを更新しました。');
      },
      result => {
        Notification.error(result.responseJSON.errors);
      }
    );
  };

  saveProjectDetail = async (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    await this.props.onUpdateTrip({
      show_project_detail: true
    });

    let promises: Promise<unknown>[] = [];
    if (this.props.trip.currentOrder) {
      promises = this.updateProjectMappings(orderItemMappings);
    } else if (this.props.trip.nonOrderItems) {
      promises = this.updateNonOrderItemMappings(nonOrderItemMappings, [
        'charging_department_id',
        'expenses_account_type_id'
      ]);
    }
    Promise.all(promises).then(
      () => {
        Notification.success('プロジェクトを更新しました。');
      },
      result => {
        Notification.error(result.responseJSON.errors);
      }
    );
  };

  updateDepartmentMappings = (orderItemMappings: OrderItemMappingList) => {
    const promises: Promise<unknown>[] = [];
    orderItemMappings.list.forEach(mapping => {
      const url = `/organization/trips/order_item_mappings/${mapping.id}`;
      promises.push(utils.jsonPromise(url, mapping.submitParamsWithoutProject(), 'PUT'));
    });
    return promises;
  };

  updateNonOrderItemMappings = (nonOrderItemMappings: NonOrderItemMappingList, excludeField: string[]) => {
    const promises: Promise<unknown>[] = [];
    nonOrderItemMappings.list.forEach(mapping => {
      promises.push(
        utils.jsonPromise(
          `/organization/trips/non_order_item_mappings/${mapping.id}`,
          mapping.createSubmitParams(excludeField),
          'PUT'
        )
      );
    });
    return promises;
  };

  saveDepartment = async (
    departmentId: ReturnType<Trip['departmentId']>,
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    await this.props.onUpdateTrip({
      project_id: this.props.trip.projectId(),
      department_id: departmentId,
      internal_number: this.props.trip.internalNumber,
      show_department_detail: false
    });
    let promises: Promise<unknown>[] = [];
    if (this.props.trip.currentOrder) {
      promises = this.updateDepartmentMappings(orderItemMappings);
    } else if (this.props.trip.nonOrderItems) {
      promises = this.updateNonOrderItemMappings(nonOrderItemMappings, ['project_id', 'expenses_account_type_id']);
    }
    Promise.all(promises).then(
      () => {
        Notification.success('費用負担部署を更新しました。');
      },
      result => {
        Notification.error(result.responseJSON.errors);
      }
    );
  };

  saveDepartmentDetail = async (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    await this.props.onUpdateTrip({
      show_department_detail: true
    });

    let promises: Promise<unknown>[] = [];
    if (this.props.trip.currentOrder) {
      promises = this.updateDepartmentMappings(orderItemMappings);
    } else if (this.props.trip.nonOrderItems) {
      promises = this.updateNonOrderItemMappings(nonOrderItemMappings, ['project_id', 'expenses_account_type_id']);
    }

    Promise.all(promises).then(
      () => {
        Notification.success('費用負担部署を更新しました。');
      },
      result => {
        Notification.error(result.responseJSON.errors);
      }
    );
  };

  updateExpenseMappings = (orderItemMappings: OrderItemMappingList) => {
    const promises: Promise<unknown>[] = [];
    orderItemMappings.list.forEach(mapping => {
      const url = `/organization/trips/order_item_mappings/${mapping.id}`;
      promises.push(utils.jsonPromise(url, mapping.submitParamsWithoutDepartment(), 'PUT'));
    });
    return promises;
  };

  saveExpenseTypeCommon = async (
    showDetail: boolean,
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    await this.props.onUpdateTrip({
      show_expenses_account_type_detail: showDetail
    });

    let promises: Promise<unknown>[] = [];
    if (this.props.trip.currentOrder) {
      promises = this.updateExpenseMappings(orderItemMappings);
    } else if (this.props.trip.nonOrderItems) {
      promises = this.updateNonOrderItemMappings(nonOrderItemMappings, ['charging_department_id', 'project_id']);
    }

    return Promise.all(promises);
  };

  saveExpenseType = async (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    this.saveExpenseTypeCommon(false, orderItemMappings, nonOrderItemMappings).then(
      () => Notification.success('勘定科目を更新しました。'),
      result => Notification.error(result.responseJSON.errors)
    );
  };

  saveExpenseTypeDetail = async (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    this.saveExpenseTypeCommon(true, orderItemMappings, nonOrderItemMappings).then(
      () => Notification.success('b勘定科目を更新しました。'),
      result => Notification.error(result.responseJSON.errors)
    );
  };

  saveApproveItems = async (tripApproveItemList: TripApproveItemList) => {
    await this.props.onUpdateTripApproveItems({
      json: JSON.stringify(tripApproveItemList.list)
    });
    Notification.success('申請項目を更新しました。');
  };

  static isOrderItem(item: any): boolean {
    return item instanceof OrderItem;
  }

  static isFollowingItem(e: any): boolean {
    if (Detail.isOrderItem(e.item)) {
      return e.item.isFollowingElement(e.element as TransportElement);
    }
    return false;
  }

  tripElements() {
    const elms = _.map(this.props.trip.order.orderItems.concat(this.props.trip.nonOrderItems), item => {
      return _.map(item.elements, (element: ElementBase, i: number) => {
        let price;
        if (item instanceof OrderItem) {
          price = i === 0 ? (item as OrderItem).price.totalPrice() : 0;
        } else if (item instanceof NonOrderItem) {
          price = i === 0 ? (item as NonOrderItem).price.totalPrice() : 0;
        }
        return {
          element,
          price,
          item
        };
      });
    });
    const elements: any[] = _.flatten(elms);
    return _.sortBy(elements, (e): Moment => {
      return e.element.type === 'hotel'
        ? (e.element as HotelElement).checkoutDate
        : (e.element as TransportElement).startDate();
    });
  }

  priceInfo(): KeyValue[] {
    const order = this.props.trip.order;
    const totalPrice = order.totalPrice();
    const marginAmount = order.totalMarginAmount();
    const nonOrderTotalPrice = this.props.trip.nonOrderItemsTotalPrice();
    return [
      { key: '実費', value: utils.formatPrice(totalPrice - marginAmount) },
      { key: '手数料', value: utils.formatPrice(marginAmount) },
      { key: '個人手配', value: utils.formatPrice(nonOrderTotalPrice) }
    ];
  }

  static internalNumberError(errors: string[]) {
    return errors.find(e => e === '社内管理番号を入力してください');
  }

  static projectError(errors: string[]) {
    return errors.find(e => e === 'プロジェクトを入力してください');
  }

  static hotelPriceLimit(orderItem: OrderItem, user: User) {
    const userPriceLimit = orderItem.foreign ? user.foreign_hotel_price_limit : user.hotel_price_limit;
    return orderItem.hotelPriceLimit || userPriceLimit || 0;
  }

  render() {
    try {
      const classBase = 'organization-trips-detail';
      const {
        trip,
        projects,
        projectShares,
        projectShareAvailability,
        departments,
        chargingDepartmentShares,
        serviceId,
        expensesAccountTypes,
        expensesAccountTypeAvailable,
        tripReportAvailable
      } = this.props;

      const travelerInfo = (traveler: Traveler): KeyValue[] => {
        return [
          {
            key: '部署',
            value: traveler.departmentName() || ''
          },
          {
            key: '電話',
            value: traveler.nationalizedTel
          },
          {
            key: 'メール',
            value: traveler.email
          }
        ];
      };

      const unApproveApprovers = (count: number, start: number) => {
        const arr = [];
        for (let i = 1; i <= count - start; i += 1) {
          arr.push(
            <p className={`${classBase}__meta-panel__name`} key={i}>
              {i + start}次承認待ち
            </p>
          );
        }
        return arr;
      };

      return (
        <div className={classBase}>
          <MetaSectionPanel>
            <MetaSection>
              <MetaSectionTitle>旅程番号</MetaSectionTitle>
              <p className={`${classBase}__meta-panel__trip-id text--primary`}>
                <A href={`/trips/${trip.id}`}>{trip.id}</A>
              </p>
            </MetaSection>

            <MetaSection>
              <MetaSectionTitle>予約者</MetaSectionTitle>
              <p className={`${classBase}__meta-panel__name`}>{trip.userName()}</p>
            </MetaSection>

            <MetaSection>
              <MetaSectionTitle>承認者</MetaSectionTitle>
              {trip.in_advance_approval?.map((approval: any, i: number) => (
                <>
                  {approval.approver && (
                    <p className={`${classBase}__meta-panel__name`} key={i}>
                      {approval.approver?.name} [{trip.statusAtApproval(i)}] {trip.approvedAt(i)}
                      {trip.rejectedAt(i)}
                    </p>
                  )}
                </>
              ))}
              {trip.approveStageCount === 0 && <p>承認設定なし</p>}
              {trip.approveStageCount > 0 &&
                trip.hasRejected() === false &&
                unApproveApprovers(trip.approveStageCount, trip.submittedApproverLength())}
            </MetaSection>
            {trip.in_advance_approval?.map((approval: any, i: number) => (
              <>
                {approval.approver && (
                  <MetaSection>
                    <MetaSectionTitle>
                      {approval.status === 1 ? '承認' : approval.status === 2 ? '却下' : ''}理由
                    </MetaSectionTitle>
                    <p className={`${classBase}__meta-panel__name`} key={i}>
                      {approval.status === 1 ? (
                        <>
                          {approval.approve_reason || '記入なし'}
                          <MetaSectionTitle>最終承認日時: {trip.approvedAt(i)}</MetaSectionTitle>
                        </>
                      ) : approval.status === 2 ? (
                        approval.reject_reason
                      ) : null}
                    </p>
                  </MetaSection>
                )}
              </>
            ))}
            <MetaSection>
              <MetaSectionTitle>出張者</MetaSectionTitle>
              {trip.travelers.list.map((traveler: any, i: number) => (
                <div className={`${classBase}__meta-panel__traveler`} key={i}>
                  <p className={`${classBase}__meta-panel__name`}>{traveler.displayName}</p>
                  {!trip.privateUse && (
                    <div className={`${classBase}__meta-panel__traveler-info`}>
                      <FormattedKeyValue data={travelerInfo(traveler)} />
                    </div>
                  )}
                </div>
              ))}
            </MetaSection>

            <FinalDestinationForm finalDestination={trip.final_destination} onSave={this.saveFinalDestination} />

            <PurposeForm purpose={trip.purpose} onSave={this.savePurpose} />

            <TripDetailInternalNumberForm internalNumber={trip.internalNumber} onSave={this.saveInternalNumber} />

            {!trip.privateUse && (
              <TripDetailDepartmentForm
                departments={departments}
                chargingDepartmentShares={chargingDepartmentShares}
                projectShareAvailability={projectShareAvailability}
                orderItems={this.props.trip.order.orderItems}
                nonOrderItems={this.props.trip.nonOrderItems}
                selectedDepartmentId={
                  this.props.trip.order.orderItems.length > 0
                    ? trip.departmentId()
                    : trip.nonOrderItems[0]?.nonOrderItemMappings[0]?.chargingDepartmentId || null
                }
                showDepartmentDetail={trip.showDepartmentDetail}
                setShowDepartmentDetail={status => trip.setShowDepartmentDetail(status)}
                onSave={this.saveDepartment}
                onSaveDetail={this.saveDepartmentDetail}
              />
            )}
            {!trip.privateUse && (
              <TripDetailProjectForm
                projects={projects}
                projectShares={projectShares}
                projectShareAvailability={projectShareAvailability}
                orderItems={this.props.trip.order.orderItems}
                nonOrderItems={trip.nonOrderItems}
                selectedProjectId={
                  this.props.trip.order.orderItems.length > 0
                    ? trip.projectId()
                    : trip.nonOrderItems[0]?.nonOrderItemMappings[0]?.projectId || null
                }
                showProjectDetail={trip.showProjectDetail}
                setShowProjectDetail={status => trip.setShowProjectDetail(status)}
                onSave={this.saveProject}
                onSaveDetail={this.saveProjectDetail}
              />
            )}
            {expensesAccountTypeAvailable && !trip.privateUse && (
              <TripDetailExpensesAccountTypeForm
                orderItems={this.props.trip.order.orderItems}
                nonOrderItems={trip.nonOrderItems}
                expensesAccountTypes={expensesAccountTypes}
                selectedExpenseId={
                  this.props.trip.order.orderItems.length > 0
                    ? trip.expensesAccountTypeId()
                    : trip.nonOrderItems[0]?.nonOrderItemMappings[0]?.expensesAccountTypeId || null
                }
                showExpenseDetail={trip.showExpenseDetail}
                setShowExpenseDetail={status => trip.setShowExpenseDetail(status)}
                onSave={this.saveExpenseType}
                onSaveDetail={this.saveExpenseTypeDetail}
              />
            )}
            {trip.tripApproveItem && (
              <TripDetailApproveItemForm
                approveItemList={trip.tripApproveItem}
                trip={trip}
                onSave={this.saveApproveItems}
              />
            )}
            {tripReportAvailable && (
              <MetaSection>
                <MetaSectionTitle>出張報告</MetaSectionTitle>
                <p className={`${classBase}__meta-panel__name`}>
                  {trip.tripReportId() ? (
                    <A href={`/trip_report/${trip.tripReportId()}`}>出張報告ページへ</A>
                  ) : (
                    '出張報告なし'
                  )}
                </p>
              </MetaSection>
            )}
          </MetaSectionPanel>
          <div className={`${classBase}__detail-panel`}>
            <p className={`${classBase}__detail-panel__label`}>旅程</p>
            {this.tripElements().map((e, i) => (
              <Element
                key={i}
                isFollowingElement={Detail.isFollowingItem(e)}
                isOrderItem={Detail.isOrderItem(e.item)}
                element={e.element}
                price={e.price}
                tripId={trip.id}
                hotelPriceLimit={Detail.hotelPriceLimit(e.item, trip.user)}
                serviceId={serviceId}
              />
            ))}
            <div className={`${classBase}__detail-panel__item-block`}>
              <div className={`${classBase}__detail-panel__item-block__header`}>
                <EndIconWrap>
                  <EndIcon />
                </EndIconWrap>
                <div className={`${classBase}__detail-panel__item-block__header__date`}>
                  {`合計：${trip.totalPrice().toLocaleString()}円`}
                </div>
              </div>
              <div className={`${classBase}__detail-panel__item-block__body no-border`}>
                <FormattedKeyValue data={this.priceInfo()} />
              </div>
            </div>
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const EndIconWrap = styled.div`
  &:before {
    content: '';
    display: block;
    height: 5px;
    margin-left: 8px;
    border-left: solid 4px ${props => props.theme.linkColor};
  }
`;

const EndIcon = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 15px 0 5px;
  background: ${props => props.theme.linkColor};
  border-radius: 5px;
`;

export default Detail;
