import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { Box, Divider } from '@material-ui/core';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import type { CsvImportActionArgs } from '@this/src/domain/csv_import_action/csv_import_action';
import CsvImportAction from '@this/src/domain/csv_import_action/csv_import_action';
import type CsvImportActionItem from '@this/src/domain/csv_import_action/csv_import_action_item';

interface Response {
  action: CsvImportActionArgs;
}

type Props = RouteComponentProps<{ id?: string }>;

const ApproveItemListCsvImportActionDetail = observer((props: Props) => {
  const { approveItemId } = useParams<{ approveItemId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [action, setAction] = useState<CsvImportAction | null>(null);

  const path = `/organization/approve_items/${approveItemId}/approve_item_lists/approve_item_list_csv_import_actions`;

  const fetchAction = async () => {
    setLoading(true);
    setLoadingError(null);
    try {
      const res = await utils.jsonPromise<Response>(`${path}/${props.match.params.id}.json`);
      setAction(new CsvImportAction(res.action));
    } catch {
      setLoadingError('エラーが発生しました。');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAction();
  }, []);

  const row = (item: CsvImportActionItem) => {
    const data = item.uploadedDataHash;
    return (
      <TableRow hover>
        <TableCell>{data.code}</TableCell>
        <TableCell>{data.name}</TableCell>
        <TableCell>{data.dep_codes}</TableCell>
        <TableCell>{item.statusMessage}</TableCell>
      </TableRow>
    );
  };

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box>
            <Link to={path}>一覧へ戻る</Link>
          </Box>
          <TitleWrapper>
            <Box>
              <Title>{action?.filename}</Title>
              <p style={{ color: '#aaa' }}>{action?.createdAt.format('YYYY-MM-DD HH:mm')}</p>
            </Box>
            <p style={{ color: '#aaa' }}>合計：{action?.csvImportActionItems?.length}件</p>
          </TitleWrapper>
          <Divider />
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>項目コード</TableCell>
                <TableCell>項目名</TableCell>
                <TableCell>利用可能部署コード</TableCell>
                <TableCell>ステータス</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{action?.csvImportActionItems?.map(item => row(item))}</TableBody>
          </Table>
        </>
      )}
      {loadingError && <Box color="red">{loadingError}</Box>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;
`;

const Title = styled.p`
  font-size: large;
  font-weight: bold;
`;

export default ApproveItemListCsvImportActionDetail;
