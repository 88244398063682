import React, { useMemo } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { JsonViewer } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/json_viewer';
import type HotelElement from '@this/domain/hotel_element';
import { getColor } from '@this/shared/ui/theme';

type Props = {
  className?: string;
  trip: Trip;
};

const getHotelElementList = (trip: Trip, provider: 'mynavi' | 'agoda') =>
  trip.order.orderItems.reduce((acc, item) => {
    item.elements
      .filter(e => e.type === 'hotel' && (e as HotelElement).provider === provider)
      .forEach(e => acc.push(e as HotelElement));
    return acc;
  }, [] as HotelElement[]);

export const HotelInfo = ({ className, trip }: Props) => {
  const agodaHotels = useMemo(() => getHotelElementList(trip, 'agoda'), [trip]);
  const mynaviHotels = useMemo(() => getHotelElementList(trip, 'mynavi'), [trip]);

  return (
    <Root className={className}>
      <Section>
        <h3>ホテル情報</h3>
        <p>Expedia連絡先: 03-6743-8536</p>
        <p>Expedia旅程番号: {trip.itinerary_id ?? '-'}</p>
        {trip.order.orderItems &&
          trip.order.orderItems.map(item =>
            item.elements
              .filter(e => e.type === 'hotel' && !!(e as HotelElement).rawJson)
              .map(e => (
                <div key={e.id}>
                  <JsonViewer json={(e as HotelElement).rawJson} label="ホテル詳細" />
                </div>
              ))
          )}
        {trip.hotel_json && <JsonViewer json={trip.hotel_json} label="ホテル詳細(旧)" />}
      </Section>

      {agodaHotels.length > 0 && (
        <Section>
          <h3>agodaホテル</h3>
          {agodaHotels.map(h => (
            <div key={h.id}>
              <p>▼{h.name}</p>
              <p>予約番号: {h.hotelReservationNumber}</p>
            </div>
          ))}
        </Section>
      )}

      {mynaviHotels.length > 0 && (
        <Section>
          <h3>マイナビホテル</h3>
          {mynaviHotels.map(h => (
            <div key={h.id}>
              <p>▼{h.name}</p>
              <p>予約番号: {h.hotelReservationNumber}</p>
            </div>
          ))}
        </Section>
      )}
    </Root>
  );
};

const Root = styled.div``;

const Section = styled.section`
  & + & {
    border: 1px solid ${getColor('border', 'divider')};
  }
`;
