import React from 'react';
import _ from 'lodash';
import type HotelOrderItemTodo from '@this/src/domain/arrangement/hotel_order_item_todo';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import HotelOrderItemTodoTableRow from './hotel_order_item_todo_table_row';

interface Props {
  todos: HotelOrderItemTodo[];
  totalCount: number;
  totalPage: number;
  page: number;
  showDescription: boolean;
  fetchTodos: () => void;
  setPage: (page: number) => void;
}

const HotelOrderItemTodoListAll: React.FC<Props> = ({
  todos,
  totalCount,
  totalPage,
  page,
  showDescription,
  fetchTodos,
  setPage
}) => {
  return (
    <>
      <div>検索結果: {totalCount}件</div>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Tripステータス</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>自動予約フラグ</TableCell>
            {showDescription && (
              <>
                <TableCell>依頼時の内容</TableCell>
                <TableCell>右ナビ承認時の内容</TableCell>
                <TableCell>依頼時との差分</TableCell>
              </>
            )}
            <TableCell>自動承認ステータス</TableCell>
            <TableCell>自動承認理由</TableCell>
            <TableCell>棚卸ステータス</TableCell>
            <TableCell>棚卸実施者</TableCell>
            <TableCell>棚卸日時</TableCell>
            <TableCell>法人名</TableCell>
            <TableCell nowrap={!showDescription}>出発日</TableCell>
            <TableCell nowrap={!showDescription}>依頼日</TableCell>
            <TableCell>チャットメッセージ</TableCell>
            <TableCell nowrap={!showDescription}>Trip ID</TableCell>
            <TableCell nowrap={!showDescription}>Trace ID</TableCell>
            <TableCell nowrap={!showDescription}>プロバイダ</TableCell>
            <TableCell>最新のログメッセージ</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {todos.map((todo, i) => (
            <HotelOrderItemTodoTableRow
              key={i}
              todo={todo}
              showDescription={showDescription}
              onInventory={() => fetchTodos()}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        count={totalPage}
        page={page}
        onChange={(_e: unknown, newPage: number) => setPage(newPage)}
      />
    </>
  );
};

export default HotelOrderItemTodoListAll;
