import type { SeatMapRowJson } from '@this/domain/flight/seat_map_row';
import SeatMapRow from '@this/domain/flight/seat_map_row';

export interface SeatMapJson {
  rows: SeatMapRowJson[];
}

class SeatMap {
  readonly rows: SeatMapRow[];

  constructor(args: SeatMapJson) {
    this.rows = args.rows.map(r => new SeatMapRow(r));
  }

  columnCount() {
    const counts = this.rows.map(r => r.columnCount());
    return Math.max(...counts);
  }
}

export default SeatMap;
