export interface OemServiceArgs {
  id?: number;
}

export class OemService {
  public displayData: {
    id: number;
    name: string;
    email: string;
  };

  constructor(args: OemServiceArgs) {
    this.displayData = OemService.setDisplayData(args.id || 1);
  }

  private static setDisplayData(id: number) {
    // OEM_TODO: メールアドレス要確認
    switch (id) {
      case 2:
        return { id, name: 'Tabikobo', email: 'tabikobo@example.com' };
      case 3:
        return { id, name: 'Mynavi', email: 'mynavi@example.com' };
      default:
        return { id, name: 'AI Travel', email: 'contact@aitravel.jp' };
    }
  }

  submitParams() {
    return {
      displayData: this.displayData
    };
  }

  naviItems() {
    if (this.isMynavi()) {
      return [
        {
          link: 'https://www.mynavi.jp/',
          title: '会社概要',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://btm.mynavi.jp/terms/pdf/hojinkiyaku.pdf',
          title: '利用規約',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://btm.mynavi.jp/terms/index.html',
          title: '標識・約款・旅行条件書',
          newTab: true,
          onlySignedIn: true
        },
        {
          link: 'https://btm.mynavi.jp/terms/pdf/privacypolicy.pdf',
          title: '個人情報の取り扱いについて',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: '/inquiries/new',
          title: 'お問い合わせ',
          newTab: false,
          onlySignedIn: true
        },
        {
          link: 'https://btm.mynavi.jp/service/faq/',
          title: 'よくある質問',
          newTab: true,
          onlySignedIn: true
        },
        {
          link: 'https://travel.mynavi.jp/desk/',
          title: '団体手配相談',
          newTab: true,
          onlySignedIn: true
        }
      ];
    }
    if (this.isTabikobo()) {
      return [
        {
          link: 'https://about.tabikobo.com/about/overview/',
          title: '会社概要',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://www.tabikobo.com/reservation/rule4.html',
          title: '標識・約款',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://www.tabikobo.com/policy/',
          title: '個人情報保護方針',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://faq.tabikobo.com/category/show/40?site_domain=houjin',
          title: 'よくある質問',
          newTab: true,
          onlySignedIn: true
        },
        {
          link: 'https://developer.expediapartnersolutions.com/terms/ja/?_fsi=jT8gE2yz',
          title: 'パートナーサービス利用規約',
          newTab: true,
          onlySignedIn: false
        }
      ];
    }
    if (this.isAiTravel()) {
      return [
        {
          link: 'https://transferdata.co.jp/about',
          title: '会社概要',
          newTab: true,
          onlySignedIn: false
        },
        {
          link: 'https://aitravel.cloud/tos/',
          title: '利用約款',
          newTab: false,
          onlySignedIn: false
        },
        {
          link: '/travel_terms',
          title: '標識・約款',
          newTab: false,
          onlySignedIn: true
        },
        {
          link: 'https://transferdata.co.jp/privacy-policy#a',
          title: '個人情報保護方針',
          newTab: false,
          onlySignedIn: false
        },
        {
          link: 'https://transferdata.co.jp/privacy-policy#b',
          title: '個人情報の取り扱いについて',
          newTab: false,
          onlySignedIn: false
        },
        {
          link: 'https://transferdata.co.jp/privacy-policy#c',
          title: '匿名加工情報',
          newTab: false,
          onlySignedIn: true
        },
        {
          link: '/help',
          title: 'よくある質問',
          newTab: false,
          onlySignedIn: true
        },
        {
          link: '/inquiries/new',
          title: 'お問い合わせ',
          newTab: false,
          onlySignedIn: true
        }
      ];
    }
    return [];
  }

  isMynavi(): boolean {
    return this.displayData.id === 3;
  }

  isTabikobo(): boolean {
    return this.displayData.id === 2;
  }

  isAiTravel(): boolean {
    return this.displayData.id === 1;
  }
}
