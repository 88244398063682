import _ from 'lodash';
import type Order from './order';
import type Journal from './journal';

class JournalOrganizer {
  private order: Order;

  constructor(order: Order) {
    this.order = order;
  }

  public getImportJournals(): Journal[] {
    let journals: Journal[] = [];
    journals = journals.concat(_.flatten(this.order.orderItems.map(item => item.journals)));
    journals = journals.concat(_.flatten(this.order.orderItems.map(item => item.tmpGmoSalesJournals)));
    journals = journals.concat(_.flatten(this.order.orderItems.map(item => item.tmpGmoCancelJournals)));
    return _.compact(journals);
  }
}

export default JournalOrganizer;
