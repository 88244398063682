import React from 'react';
import _ from 'lodash';
import { createTheme } from '@this/constants/themes';

import Hotel from '@this/domain/hotel/hotel';
import MarginType from '@this/domain/organization/margin_type2';
import type { HotelJson } from '@this/domain/select_repository';
import PropTypes from 'prop-types';
import { AITThemeProvider } from '@this/shared/ui/theme';
import type HotelStayPlan from '@this/domain/hotel/hotel_stay_plan';
import SelectHotelBoxTemplate from './hotel_box.template';
import type { SelectHotelBoxProps, SelectHotelBoxState } from './types';

interface HotelResponse {
  hotel: HotelJson;
  show_fee: boolean;
  margin_type: MarginType;
  is_domestic: boolean;
  user: {
    show_foreign_hotel_of_over_limit: boolean;
    show_hotel_of_over_limit: boolean;
  };
}

type State = SelectHotelBoxState & {
  user?: any;
  isDomestic?: boolean;
};

class SelectHotelBox extends React.Component<SelectHotelBoxProps, State> {
  constructor(props: SelectHotelBoxProps) {
    super(props);
    this.state = {
      user: this.props.user,
      isDomestic: this.props.isDomestic,
      loading: false, // 未使用
      message: '' // 未使用
    };
  }

  componentDidMount() {
    // 楽天staticファイルから取得されたホテルの場合にのみfetch処理を発火
    if (this.props.hotel.operator_class_name === 'RakutenStaticfileOperator') {
      this.handleFetchHotel();
    }
  }

  handleChange = () => {
    if (this.props.handleClickTab && this.props.tab !== undefined) {
      this.props.handleClickTab(this.props.selected ? 'none' : this.props.tab);
    }
  };

  handleChangeHotelStayPlan = (
    hotel: HotelJson,
    selectedPlan: HotelStayPlan,
    showFee: boolean,
    marginType: MarginType | undefined
  ) => {
    const includeTax = this.props.hotels.includeTax;
    const updatedHotel = new Hotel(
      _.merge(hotel, {
        plan_name: selectedPlan.name,
        selected_stay_plan_id: selectedPlan.id,
        room_type: selectedPlan.room_type ? selectedPlan.room_type : selectedPlan.name,
        price: selectedPlan.total_fee,
        bed_types: selectedPlan.bed_types,
        average_price: selectedPlan.average_price,
        average_price_with_tax: selectedPlan.average_price_with_tax,
        includeTax,
        showFee,
        marginType,
        walkminute: this.props.hotel.walkminute,
        loading: false
      })
    );

    this.props.hotels.updateHotelList(updatedHotel);
    app.render();
  };

  // TODO: R-Connect対応
  // 楽天staticファイル検索を利用時に初期選択されたホテルの最新情報を取得するためのイベントハンドラ
  handleFetchHotel = () => {
    this.props.hotel.setHotelLoading(true);
    utils
      .jsonPromise<HotelResponse>(`/hotels/${this.props.hotel.id}?search_query_id=${this.props.searchQueryId}`)
      .then(
        result => {
          if (result.hotel.sold_out) {
            this.props.hotel.setSoldOut(true);
          } else if (result.hotel.too_late) {
            this.props.hotel.setTooLate(true);
          } else {
            // 利用可能なホテルの情報が取得できた場合。
            let marginType: MarginType | undefined;
            if (result.margin_type) {
              marginType = new MarginType(result.margin_type);
            }
            const includeTax = this.props.hotels.includeTax;
            const updatedHotel = new Hotel(
              _.merge(result.hotel, {
                includeTax,
                showFee: result.show_fee,
                marginType,
                loading: false
              })
            );

            this.setState({
              user: result.user,
              isDomestic: result.is_domestic
            });

            this.props.hotels.updateHotelList(updatedHotel);
          }
          this.props.hotel.setHotelLoading(false);
        },
        e => {
          this.props.hotel.setHotelLoading(false);
        }
      );
  };

  render() {
    const theme = createTheme(this.props.serviceId);
    try {
      // map.coffeeのpopupとして呼ばれるときにthemeの情報がリセットされる問題への対応
      // ts化が済んだら不要
      return this.props.serviceId ? (
        <AITThemeProvider theme={theme}>
          <SelectHotelBoxTemplate
            me={this.props.me}
            destLocation={this.props.destLocation}
            key={this.props.key}
            selected={this.props.selected}
            inList={this.props.inList}
            hotel={this.props.hotel}
            hotels={this.props.hotels}
            hotelPriceLimit={this.props.hotelPriceLimit}
            isRequestForm={this.props.isRequestForm}
            disabled={this.props.disabled}
            query={this.props.query}
            searchQueryId={this.props.searchQueryId}
            handleSelect={this.props.handleSelect}
            handleClickTab={this.props.handleClickTab}
            tab={this.props.tab}
            serviceId={this.props.serviceId}
            user={this.state.user}
            isDomestic={this.state.isDomestic}
            loading={this.state.loading}
            message={this.state.message}
            handleChange={this.handleChange}
            handleChangeHotelStayPlan={this.handleChangeHotelStayPlan}
          />
        </AITThemeProvider>
      ) : (
        <SelectHotelBoxTemplate
          me={this.props.me}
          destLocation={this.props.destLocation}
          key={this.props.key}
          selected={this.props.selected}
          inList={this.props.inList}
          hotel={this.props.hotel}
          hotels={this.props.hotels}
          hotelPriceLimit={this.props.hotelPriceLimit}
          isRequestForm={this.props.isRequestForm}
          disabled={this.props.disabled}
          query={this.props.query}
          searchQueryId={this.props.searchQueryId}
          handleSelect={this.props.handleSelect}
          handleClickTab={this.props.handleClickTab}
          tab={this.props.tab}
          serviceId={this.props.serviceId}
          user={this.state.user}
          isDomestic={this.state.isDomestic}
          loading={this.state.loading}
          message={this.state.message}
          handleChange={this.handleChange}
          handleChangeHotelStayPlan={this.handleChangeHotelStayPlan}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }

  static contextTypes = {
    query: PropTypes.object // eslint-disable-line react/forbid-prop-types
  };
}

export default SelectHotelBox;
