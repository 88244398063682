import React from 'react';
import { TableContainer } from '@this/shared/ui/data_displays/table';
import { styled } from '@this/constants/themes';

type Props = { title: string; renderHeaderAction?: React.ReactNode; renderTable: React.ReactNode };

export const BulkTicketsSection = ({ title, renderHeaderAction, renderTable }: Props) => (
  <section>
    <Header>
      <Title>{title}</Title>
      {renderHeaderAction && <div>{renderHeaderAction}</div>}
    </Header>

    <TableContainer height="calc(100vh - 350px)">{renderTable}</TableContainer>
  </section>
);

const Header = styled.header`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;

const Title = styled.h2`
  margin: 0;
`;
