import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import type { ApproveItemArgs } from '@this/domain/approve_item/approve_item';
import ApproveItem from '@this/domain/approve_item/approve_item';
import {
  OrganizationBody,
  OrganizationTitle,
  OrganizationNavBar
} from '@this/components/organization/organization.style';
import ApproveItemForm from './approve_item_form';
import { Loading } from '../../shared/ui/feedbacks/loading';
import { Button } from '../../shared/ui/inputs/button';
import { Link } from '../../shared/ui/navigations/link';

type EditResponse = {
  approve_item: ApproveItemArgs;
};

type Props = {
  showExicSeisanTrips: boolean;
};

const ApproveItemEdit = observer(({ showExicSeisanTrips }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [approveItem, setApproveItem] = useState<ApproveItem | null>(null);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await utils.jsonPromise<EditResponse>(`/organization/approve_items/${id}/edit.json`);
      const item = new ApproveItem(response.approve_item);
      setApproveItem(item);
    } catch (e) {
      utils.sendErrorObject(e);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  const handleSubmit = async (fe: React.FormEvent) => {
    fe.preventDefault();
    if (!approveItem) return;

    setSaving(true);
    setErrors([]);
    let message = '';
    try {
      const params = approveItem.submitParams();
      await utils.jsonPromise(`/organization/approve_items/${approveItem.id}`, params, 'PUT');
      message = '保存しました';
      location.href = '/organization/approve_items';
    } catch (e) {
      if (e.status === 400) {
        const submitErrors = utils.dig(e, 'responseJSON', 'errors');
        message = submitErrors.join(`\n`);
        setErrors(submitErrors);
      } else {
        message = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        setErrors([message]);
        utils.sendErrorObject(e);
      }
    } finally {
      setSubmitMessage(message);
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitMessage(null);
  };

  return (
    <>
      <Snackbar
        open={!!submitMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={errors.length ? 'error' : 'success'}>
          {submitMessage && submitMessage.split('\n').map((line, i) => <div key={i}>{line}</div>)}
        </MuiAlert>
      </Snackbar>
      <OrganizationNavBar>
        <Link href="/organization/approve_items" style={{ display: 'flex', alignItems: 'center' }}>
          <ChevronLeft />
          申請項目に戻る
        </Link>
      </OrganizationNavBar>
      <OrganizationTitle>申請項目情報を変更する</OrganizationTitle>
      <OrganizationBody>
        <Wrap>
          {approveItem ? (
            <form onSubmit={handleSubmit}>
              <ApproveItemForm approveItem={approveItem} showExicSeisanTrips={showExicSeisanTrips} />
              <div className="flex">
                <Button
                  type="reset"
                  color="sub"
                  href="/organization/approve_items"
                  disabled={saving}
                  style={{ marginRight: '10px' }}
                >
                  キャンセル
                </Button>
                <Button type="submit" color="primary" disabled={saving}>
                  保存
                </Button>
              </div>
              {saving && <Loading />}
              {!!errors.length && (
                <div className="error" style={{ marginTop: '20px' }}>
                  {errors.map(e => (
                    <p key={e}>{e}</p>
                  ))}
                </div>
              )}
            </form>
          ) : (
            <Loading />
          )}
        </Wrap>
      </OrganizationBody>
    </>
  );
});

const Wrap = styled.div`
  padding: 10px;
`;

export default ApproveItemEdit;
