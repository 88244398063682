import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { ByMedia } from '@this/shared/ui/other/by_media';
import HeaderContentSm from './header_content_sm';
import type User from '../../../domain/user/user';

interface Props {
  user: User | null | undefined;
}

const ExpensesHeader: React.FC<Props> = ({ user }) => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const history = useHistory();

  useEffect(() => {
    return history.listen(_location => {
      setPath(_location.pathname);
    });
  }, [history]);

  const icon = (_path: string, _icon: string, alt: string) => {
    const src = path.startsWith(_path) ? `${_icon}_active.png` : `${_icon}.png`;
    return <img src={`/images/expenses/${src}`} alt={`${alt}アイコン`} />;
  };
  const title = (_path: string, label: string) => {
    const className = path.startsWith(_path) ? 'active' : '';
    return <span className={className}>{label}</span>;
  };

  return (
    <Header>
      <HeaderWrapper>
        <HeaderContent>
          <HeaderLogoWrapper>
            <HeaderLogo href="/biztra/report_items">
              <img src="/images/expenses/logo_194x48.png" alt="ビズトラ" />
            </HeaderLogo>
          </HeaderLogoWrapper>
          <HeaderLinkArea>
            {user && (
              <ByMedia>
                {matches =>
                  matches.sm ? (
                    <HeaderContentSm user={user} />
                  ) : (
                    <>
                      <HeaderLink>
                        <Link to="/biztra/report_items">
                          {icon('/biztra/report_items', 'icon_item', '登録')}
                          {title('/biztra/report_items', '登録')}
                        </Link>
                      </HeaderLink>
                      <HeaderLink>
                        <Link to="/biztra/reports">
                          {icon('/biztra/reports', 'icon_report', '申請')}
                          {title('/biztra/reports', '申請')}
                        </Link>
                      </HeaderLink>
                      {(user?.isOrganizationAdmin || user?.is_expenses_approver) && (
                        <HeaderLink>
                          <Link to="/biztra/approvals">
                            {icon('/biztra/approvals', 'icon_approval', '承認')}
                            {title('/biztra/approvals', '承認')}
                          </Link>
                        </HeaderLink>
                      )}
                      {user?.isOrganizationAdmin && (
                        <HeaderLink>
                          <Link to="/biztra/organization">
                            {icon('/biztra/organization', 'icon_setting', '管理')}
                            {title('/biztra/organization', '管理')}
                          </Link>
                        </HeaderLink>
                      )}
                    </>
                  )
                }
              </ByMedia>
            )}
          </HeaderLinkArea>
        </HeaderContent>
      </HeaderWrapper>
    </Header>
  );
};

const headerBgColor = '#393e46';

export const headerTxtColor = '#efedea';

export const headerHeight = '56px';

const contentPadding = '16px';

const Header = styled.header`
  height: ${headerHeight};
`;

const HeaderWrapper = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: ${headerBgColor};
  height: ${headerHeight};
  padding: ${contentPadding} 24px;
  @media screen and (max-width: 767px) {
    padding: unset;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
`;

const HeaderLogoWrapper = styled.h1`
  @media screen and (max-width: 767px) {
    padding: 16px 24px;
  }
`;

const HeaderLogo = styled.a`
  display: flex;
  z-index: 9999;
  img {
    height: calc(${headerHeight} - (${contentPadding} * 2));
  }
`;

const HeaderLinkArea = styled.ul`
  display: flex;
  height: 100%;
`;

export const HeaderLink = styled.li`
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }

  a {
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 100%;
    color: ${headerTxtColor};
    font-weight: bold;
  }

  img {
    display: inline-block;
    height: 15px;
    margin-right: 6px;
  }

  span {
    display: inline-block;

    &.active {
      color: #ba8e31;
    }
  }

  @media screen and (max-width: 767px) {
    margin-right: 0;

    a {
      display: block;
      padding: 20px 35vw;
    }
  }
`;

export default ExpensesHeader;
