import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import AutoCompletableInput from '@this/shared/autocompletable_input/autocompletable_input';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import type ArrangementRequestRentalCarInfo from '@this/domain/arrangement_request_info/arrangement_request_rental_car_info';
import moment from '../../../../../lib/moment';

export type Props = {
  rentalCarInfo: ArrangementRequestRentalCarInfo;
};

const ArrangementRequestRentalCarInfoForm: React.SFC<Props> = ({ rentalCarInfo }) => (
  <div className="reserve-confirm__section">
    <div className="reserve-confirm__subtitle">レンタカー</div>
    <div className="reserve-confirm__child-content">
      {rentalCarInfo.rentalCars.map((rentalCar, i) => (
        <div key={i}>
          {i !== 0 && <RentalCarRemove onClick={() => rentalCarInfo.removeRentalCar(i)}>×</RentalCarRemove>}
          <p className="reserve-confirm__peoplenum">ご依頼内容{i !== 0 && i + 1}</p>
          <div className="reserve-confirm__traveler">
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">出発日時</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <DatetimePicker
                  value={moment(rentalCar.departureDate)}
                  onChange={value => rentalCarInfo.setDepartureDate(value as never, i)}
                  showTime
                  showPast={false}
                  disabledDays={1}
                />
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">返却日時</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <DatetimePicker
                  value={moment(rentalCar.returnDate)}
                  onChange={value => rentalCarInfo.setReturnDate(value as never, i)}
                  showTime
                  showPast={false}
                  disabledDays={2}
                />
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">出発場所</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <StyledAutoCompletableInput
                  id={`rental-car-departure-place_${i}`}
                  value={rentalCar.departurePlace}
                  onChange={(_, value, address) =>
                    rentalCarInfo.setDeparturePlace((address ? `${value}(${address})` : value) as never, i)
                  }
                />
                {rentalCarInfo.errorMessage('departurePlace', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('departurePlace', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">返却場所</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <label className="reserve-confirm__radio-label">
                  <input
                    className="reserve-confirm__radio-input"
                    type="radio"
                    value="current"
                    checked={rentalCar.returnPlace === 'current'}
                    onChange={e => rentalCarInfo.setReturnPlace(e.target.value as never, i)}
                  />
                  <span>出発場所へ戻す</span>
                </label>
                <label className="reserve-confirm__radio-label">
                  <input
                    className="reserve-confirm__radio-input"
                    type="radio"
                    value="other"
                    checked={rentalCar.returnPlace === 'other'}
                    onChange={e => rentalCarInfo.setReturnPlace(e.target.value as never, i)}
                  />
                  <span>別の場所へ乗り捨てる(※乗り捨てを希望する場所を記載して下さい)</span>
                </label>
                <label className="reserve-confirm__radio-label">
                  <StyledAutoCompletableInput
                    id={`rental-car-return-place_${i}`}
                    value={rentalCar.returnPlaceName}
                    onChange={(_, value, address) =>
                      rentalCarInfo.setReturnPlaceName((address ? `${value}(${address})` : value) as never, i)
                    }
                    disabled={rentalCar.returnPlace !== 'other'}
                  />
                </label>
                {rentalCarInfo.errorMessage('returnPlace', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('returnPlace', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">車種・クラス</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <Annotation>※選択いただいたタイプから適切な車両をお選びします。</Annotation>
                {rentalCar.carTypeOptions.map((c, ci) => (
                  <label key={`car_type_${ci}`} className="reserve-confirm__radio-label">
                    <input
                      className="reserve-confirm__radio-input"
                      type="radio"
                      value={c[1]}
                      checked={rentalCar.carType === c[1]}
                      onChange={e => rentalCarInfo.setCarType(e.target.value as never, i)}
                    />
                    <span>{c[0]}</span>
                  </label>
                ))}
                {rentalCar.carType === 'other' && (
                  <input
                    className="reserve-confirm__input"
                    type="text"
                    value={rentalCar.carTypeOther}
                    onChange={e => rentalCarInfo.setCarTypeOther(e.target.value as never, i)}
                  />
                )}
                {rentalCarInfo.errorMessage('carType', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('carType', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">台数</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <select
                  value={rentalCar.carNumber}
                  onChange={e => rentalCarInfo.setCarNumber(e.target.value as never, i)}
                >
                  {_.times(8, i => (
                    <option value={i + 1} key={i}>{`${i + 1}台`}</option>
                  ))}
                </select>
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">運転者氏名（カナ）</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <FlexBox>
                  <input
                    className="reserve-confirm__input-name"
                    type="text"
                    placeholder="例) ヤマダ"
                    value={rentalCar.lastNameKana}
                    onChange={e => rentalCarInfo.setLastNameKana(e.target.value as never, i)}
                  />
                  <input
                    className="reserve-confirm__input-name"
                    type="text"
                    placeholder="例) イチロウ"
                    value={rentalCar.firstNameKana}
                    onChange={e => rentalCarInfo.setFirstNameKana(e.target.value as never, i)}
                  />
                </FlexBox>
                {rentalCarInfo.errorMessage('lastNameKana', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('lastNameKana', i)}</Annotation>
                )}
                {rentalCarInfo.errorMessage('firstNameKana', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('firstNameKana', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">運転者電話番号</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <input
                  className="reserve-confirm__input"
                  type="text"
                  placeholder="例) 090-1111-2222"
                  value={rentalCar.driverTel}
                  onChange={e => rentalCarInfo.setDriverTel(e.target.value as never, i)}
                />
                {rentalCarInfo.errorMessage('driverTel', i) && (
                  <Annotation className="red">{rentalCarInfo.errorMessage('driverTel', i)}</Annotation>
                )}
              </ColumnDiv>
            </div>
            <div className="reserve-confirm__traveler__input-area">
              <div className="reserve-confirm__label">備考</div>
              <ColumnDiv className="reserve-confirm__traveler__input-area-right">
                <textarea
                  className="reserve-confirm__input"
                  value={rentalCar.remarks}
                  onChange={e => rentalCarInfo.setRemarks(e.target.value as never, i)}
                />
              </ColumnDiv>
            </div>
          </div>
        </div>
      ))}
      <AddRentalCarToggle onClick={() => rentalCarInfo.addRentalCar()}>+ レンタカーを追加する</AddRentalCarToggle>
    </div>
  </div>
);

const ColumnDiv = styled.div`
  flex-flow: column;
`;

const FlexBox = styled.div`
  display: flex;
`;

const Annotation = styled.div`
  font-size: 0.85em;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const RentalCarRemove = styled.div`
  float: right;
  cursor: pointer;
  margin-right: 60px;
`;

const AddRentalCarToggle = styled.div`
  color: #af985e;
  text-decoration: none;
  transition: color 0.1s linear;
  cursor: pointer;
`;

const StyledAutoCompletableInput = styled(AutoCompletableInput)`
  // TextFieldの詳細度に負けてしまうため "&&&" で重ねがけ
  &&& {
    position: relative;
    input {
      border: 1px solid #eee;
      padding: 0.5em;
      height: auto;
      width: 300px;
    }
  }
`;

export default ArrangementRequestRentalCarInfoForm;
