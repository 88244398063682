CancelChargeOriginalMapping = require('./cancel_charge_original_mapping').default

class CancelChargeElement
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @type = 'cancel'
    @content ||= ''
    @canceledAt = moment(args.canceledAt)
    @startAt = moment(args.startAt).startOf('day')
    @endAt = moment(args.endAt).endOf('day')
    @mappings = _.map (args.cancelChargeOriginalMappings || args.cancel_charge_original_mappings || []),
                  (raw) -> new CancelChargeOriginalMapping(raw)

  handleChangeContent: (value) ->
    @content = value
    app.render()

  handleCanceledAtChange: (moment) ->
    @canceledAt = moment
    app.render()

  handleStartAtChange: (moment) ->
    @startAt = moment
    app.render()

  handleEndAtChange: (moment) ->
    @endAt = moment
    app.render()

  addMapping: ->
    @mappings.push(new CancelChargeOriginalMapping({}))
    app.render()

  removeMapping: (index) ->
    @mappings.splice(index, 1)
    app.render()

  startDate: ->
    @startAt

  endDate: ->
    @endAt

  description: ->
    """
    <キャンセル>
    ■内容
    #{@content}
    ■キャンセル日
    #{@canceledAt.format('MM/DD')}
    """

  title: ->
    'キャンセル料'

  summary: ->
    @content

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  mappingsWithSettings: ->
    _.filter(@mappings, (m) -> m.originalTraceId)

  validationErrors: (_) ->
    errors = []
    if @content == '' then errors.push(@cancelValidationError)
    if @endAt < @startAt then errors.push(@timeValidationError)
    if @mappingsWithSettings().length < 1 then errors.push(@mappingsError)
    errors

  cancelValidationError:
    'キャンセル料の内容を入力してください。'

  timeValidationError:
    '利用開始以降を指定してください。'

  mappingsError:
    'キャンセル対象を選択してください。'

  updateParams: ->
    type: @type
    content: @content
    canceled_at: @canceledAt.format('YYYY-MM-DD')
    start_at: @startAt.format('YYYY-MM-DD hh:mm')
    end_at: @endAt.format('YYYY-MM-DD hh:mm')
    cancel_charge_original_mappings_attributes: _.map(@mappingsWithSettings(), (m) -> m.updateParams())

module.exports = CancelChargeElement
