/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import type { Moment } from 'moment';
import type { OrderItemTodoResponseArgs } from '@this/src/domain/arrangement/order_item_todo';
import OrderItemTodo, { convertOrderItemTodoResponseToArgs } from '@this/src/domain/arrangement/order_item_todo';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobTypeNumKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/src/domain/order_item/order_item_status';
import { OrderItemStatusOngoing } from '@this/src/domain/order_item/order_item_status';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import { TripStatusOngoing } from '@this/src/domain/trip/trip_status';
import type { OrderItemCategoryKey } from '@this/src/domain/order_item/order_item_category';
import { OrderItemCategoryKeys } from '@this/src/domain/order_item/order_item_category';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import type { ElementQueryKey } from '@this/src/domain/order_item/element_query';
import { ElementQuery, ElementQueryKeys } from '@this/src/domain/order_item/element_query';
import type { SnoozeStatusKey } from '@this/src/domain/order_item/snooze_status';
import { SnoozeStatus, SnoozeStatusKeys } from '@this/src/domain/order_item/snooze_status';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import type { WaitingStatusKey } from '@this/src/domain/order_item/waiting_status';
import { WaitingStatus, WaitingStatusKeys } from '@this/src/domain/order_item/waiting_status';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import moment from 'moment';
import type { SpQueryKey } from '@this/src/domain/order_item/sp_query';
import { SpQuery, SpQueryKeys } from '@this/src/domain/order_item/sp_query';
import type { UseQrQueryKey } from '@this/src/domain/organization/use_qr_query';
import { UseQrQuery, UseQrQueryKeys } from '@this/src/domain/organization/use_qr_query';
import type { TicketingTypeQueryKey } from '@this/domain/arrangement/ticketing_type_query';
import { TicketingTypeQuery } from '@this/domain/arrangement/ticketing_type_query';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import type MasterStepSequence from '@this/src/domain/master_step/master_step_sequence';
import type MasterStep from '@this/src/domain/master_step/master_step';
import Notification from '@this/src/notification';
import { Fetcher, HTTPError } from '@this/src/util';
import OrderItemStatusSelector from '../order_item_status_selector';
import TripStatusSelector from '../trip_status_selector';
import OrderItemCategorySelector from './order_item_category_selector';
import OrderItemTodoTableRow from './order_item_todo_table_row';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import { TicketCountArea, TicketCountTitle, TicketCountLabel } from '../ticket_count_area';
import useOrganizationSelector from '../use_organization_selector';
import DateRangePicker from '../date_range_picker';
import OrderItemSequenceUpdateSelector from './order_item_sequence_update_selector';
import OrderItemSequenceAbortConfirm from './order_item_sequence_abort_confirm';

type SortKey = 'received_at' | 'started_at';
type SortDirection = 'asc' | 'desc';

interface PopoverProps {
  todo: OrderItemTodo | null;
  anchorEl: Element | null;
}

interface Props {
  serviceId: number;
  ticketCount: { [carrier: string]: number };
}

interface Response {
  order_item_todos: OrderItemTodoResponseArgs[];
  total_count: number;
  total_page: number;
}

const getDefaultPage = (): number => {
  const str = utils.getParam('page');
  return str ? parseInt(str, 10) : 1;
};

const getDefaultJobTypes = (): OrderItemJobTypeKey[] => {
  return (utils
    .getParam('job_types')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || OrderItemJobTypeNumKeys) as OrderItemJobTypeKey[];
};

const getDefaultStatuses = (): OrderItemStatusKey[] => {
  return (utils
    .getParam('statuses')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || OrderItemStatusOngoing) as OrderItemStatusKey[];
};

const getDefaultTripStatuses = (): TripStatusKey[] => {
  return (utils
    .getParam('trip_statuses')
    ?.split(',')
    .map((str: string) => parseInt(str, 10)) || TripStatusOngoing) as TripStatusKey[];
};

const getDefaultCategories = (): OrderItemCategoryKey[] => {
  return (utils
    .getParam('categories')
    ?.split(',')
    .map((str: string) => (str === 'message' ? str : parseInt(str, 10))) ||
    OrderItemCategoryKeys) as OrderItemCategoryKey[];
};

const getDefaultElementQuery = (): ElementQueryKey => {
  return (utils.getParam('element_query') || 'all') as ElementQueryKey;
};

const getDefaultOnlyOthers = (): boolean => {
  return utils.getParam('only_others') === 'true';
};

const getDefaultOnlyManual = (): boolean => {
  return utils.getParam('only_manual') === 'true';
};

const getDefaultOnlyPremiumSupport = (): boolean => {
  return utils.getParam('only_premium_support') === 'true';
};

const getDefaultWaiting = (): WaitingStatusKey => {
  return (utils.getParam('waiting') || 'without') as WaitingStatusKey;
};

const getDefaultSp = (): WaitingStatusKey => {
  return (utils.getParam('sp') || 'all') as SpQueryKey;
};

const getDefaultUseQr = (): UseQrQueryKey => {
  return (utils.getParam('use_qr') || 'all') as UseQrQueryKey;
};

const getDefaultTicketingType = (): TicketingTypeQueryKey => {
  return (utils.getParam('ticketing_type') || 9) as TicketingTypeQueryKey;
};

const getDefaultTripId = (): number | undefined => {
  const str = utils.getParam('trip_id');
  if (!str) {
    return undefined;
  }
  return parseInt(str, 10);
};

const getDefaultStartDateFrom = (): Moment | undefined => {
  const str = utils.getParam('start_date_from');
  return str ? moment(str) : undefined;
};

const getDefaultStartDateTo = (): Moment | undefined => {
  const str = utils.getParam('start_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateFrom = (): Moment | undefined => {
  const str = utils.getParam('receive_date_from');
  return str ? moment(str) : undefined;
};

const getDefaultReceiveDateTo = (): Moment | undefined => {
  const str = utils.getParam('receive_date_to');
  return str ? moment(str) : undefined;
};

const getDefaultOnlyUnchanged = (): boolean => {
  return utils.getParam('only_unchanged') === 'true';
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'started_at') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const getDefaultSnoozeStatus = (): SnoozeStatusKey => {
  return (utils.getParam('snooze_status') || 'todo') as SnoozeStatusKey;
};

interface ReplaceRequest {
  todo: OrderItemTodo;
  masterStepSequence: MasterStepSequence;
  masterStep: MasterStep;
  fetch: () => void;
}

const fetchReplaceMasterStepSequence = async ({ todo, masterStepSequence, masterStep, fetch }: ReplaceRequest) => {
  Fetcher.post('/arrangement/order_item_todos/replace_master_sequence.json', {
    order_item_id: todo.orderItemId,
    master_step_sequence_id: masterStepSequence.id,
    master_step_id: masterStep.id
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '更新に失敗しました';
      Notification.error(message);
    });
};

interface AbortRequest {
  todo: OrderItemTodo;
  fetch: () => void;
}

const fetchAbortMasterStepSequence = async ({ todo, fetch }: AbortRequest) => {
  Fetcher.post('/arrangement/order_item_todos/abort_master_sequence.json', {
    order_item_id: todo.orderItemId
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '更新に失敗しました';
      Notification.error(message);
    });
};

const OrderItemTodoList = observer(({ serviceId, ticketCount }: Props) => {
  const { current: history } = useRef(useHistory());
  const [isLoading, setIsLoading] = useState(false);
  const [todos, setTodos] = useState<OrderItemTodo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(getDefaultPage());

  const [jobTypes, setJobTypes] = useState<OrderItemJobTypeKey[]>(getDefaultJobTypes());
  const [statuses, setStatuses] = useState<OrderItemStatusKey[]>(getDefaultStatuses());
  const [tripStatuses, setTripStatuses] = useState<TripStatusKey[]>(getDefaultTripStatuses());
  const [categories, setCategories] = useState<OrderItemCategoryKey[]>(getDefaultCategories());
  const [elementQuery, setElementQuery] = useState<ElementQueryKey>(getDefaultElementQuery());
  const [snoozeStatus, setSnoozeStatus] = useState<SnoozeStatusKey>(getDefaultSnoozeStatus());
  const [onlyOthers, setOnlyOthers] = useState<boolean>(getDefaultOnlyOthers());
  const [onlyManual, setOnlyManual] = useState<boolean>(getDefaultOnlyManual());
  const [onlyPremiumSupport, setOnlyPremiumSupport] = useState<boolean>(getDefaultOnlyPremiumSupport());
  const [waiting, setWaiting] = useState<WaitingStatusKey>(getDefaultWaiting());
  const [sp, setSp] = useState<SpQueryKey>(getDefaultSp());
  const [useQr, setUseQr] = useState<UseQrQueryKey>(getDefaultUseQr());
  const [ticketingType, setTicketingType] = useState<TicketingTypeQueryKey>(getDefaultTicketingType());
  const [tripId, setTripId] = useState<number | undefined>(getDefaultTripId());
  const [startDateFrom, setStartDateFrom] = useState<Moment | undefined>(getDefaultStartDateFrom());
  const [startDateTo, setStartDateTo] = useState<Moment | undefined>(getDefaultStartDateTo());
  const [receiveDateFrom, setReceiveDateFrom] = useState<Moment | undefined>(getDefaultReceiveDateFrom());
  const [receiveDateTo, setReceiveDateTo] = useState<Moment | undefined>(getDefaultReceiveDateTo());
  const [onlyUnchanged, setOnlyUnchanged] = useState<boolean>(getDefaultOnlyUnchanged());
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());
  const [sequenceUpdatePopover, setSequenceUpdatePopover] = useState<PopoverProps>({ todo: null, anchorEl: null });
  const [sequenceAbortConfirm, setSequenceAbortConfirm] = useState<PopoverProps>({ todo: null, anchorEl: null });

  const fetchTodos = useCallback(() => {
    setIsLoading(true);

    const params = {
      page,
      job_types: jobTypes,
      statuses,
      trip_statuses: tripStatuses,
      categories,
      element_query: elementQuery,
      snooze_status: snoozeStatus,
      only_others: onlyOthers,
      only_manual: onlyManual,
      only_premium_support: onlyPremiumSupport,
      waiting,
      sp,
      use_qr: useQr,
      ticketing_type: ticketingType,
      trip_id: tripId,
      start_date_from: startDateFrom?.format('YYYY-MM-DD'),
      start_date_to: startDateTo?.format('YYYY-MM-DD'),
      receive_date_from: receiveDateFrom?.format('YYYY-MM-DD'),
      receive_date_to: receiveDateTo?.format('YYYY-MM-DD'),
      only_unchanged: onlyUnchanged,
      organization_ids: organizationIds.join(','),
      sort_key: sortKey,
      sort_direction: sortDirection
    };
    _.each(params, (value, key) => {
      if (value === undefined) {
        delete params[key as keyof typeof params];
      }
    });
    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=ORDER_ITEM&${query}`);

    Fetcher.get<Response>('/arrangement/order_item_todos.json', params)
      .then(res => {
        setTodos(res.order_item_todos.map(args => new OrderItemTodo(convertOrderItemTodoResponseToArgs(args))));
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .finally(() => setIsLoading(false));
  }, [
    page,
    jobTypes,
    statuses,
    tripStatuses,
    categories,
    elementQuery,
    snoozeStatus,
    onlyOthers,
    onlyManual,
    onlyPremiumSupport,
    waiting,
    sp,
    useQr,
    ticketingType,
    tripId,
    startDateFrom,
    startDateTo,
    receiveDateFrom,
    receiveDateTo,
    onlyUnchanged,
    organizationIds,
    sortKey,
    sortDirection,
    history
  ]);

  useEffect(() => {
    fetchTodos();
  }, [fetchTodos]);

  const markMessageFinish = (messageId: number) => {
    setIsLoading(true);
    Fetcher.put(`/arrangement/messages/${messageId}/finish`, {}).then(() => fetchTodos());
  };

  const saveSnooze = useCallback(
    (todo: OrderItemTodo) => {
      setIsLoading(true);
      const params = {
        order_item_id: todo.orderItemId,
        snooze_to: todo.snoozeTo?.format('YYYY-MM-DD hh:mm'),
        memo: todo.snoozeMessage
      };
      Fetcher.post('/arrangement/order_item_snoozed_todos', params).then(() => fetchTodos());
    },
    [fetchTodos]
  );

  const cancelSnooze = useCallback(
    (todo: OrderItemTodo) => {
      setIsLoading(true);
      const params = {
        order_item_id: todo.orderItemId
      };
      Fetcher.put('/arrangement/order_item_snoozed_todos/cancel', params).then(() => fetchTodos());
    },
    [fetchTodos]
  );

  const handleSequenceUpdatePopoverOpen = useCallback(
    (todo: OrderItemTodo) => (e: React.MouseEvent) => {
      setSequenceUpdatePopover({ todo, anchorEl: e.currentTarget });
    },
    []
  );

  const handleSequenceUpdatePopoverClose = useCallback(
    () => setSequenceUpdatePopover({ todo: null, anchorEl: null }),
    []
  );

  const handleSequenceUpdatePopoverChange = useCallback(
    (todo: OrderItemTodo, masterStepSequence: MasterStepSequence, masterStep: MasterStep) => {
      fetchReplaceMasterStepSequence({ todo, masterStepSequence, masterStep, fetch: fetchTodos });
    },
    [fetchTodos]
  );

  const handleSequenceAbortConfirmOpen = useCallback(
    (todo: OrderItemTodo) => () => {
      setSequenceAbortConfirm({ todo, anchorEl: null });
    },
    []
  );

  const handleSequenceAbortConfirmClose = useCallback(
    () => setSequenceAbortConfirm({ todo: null, anchorEl: null }),
    []
  );

  const handleSequenceAbortConfirmAbort = useCallback(
    (todo: OrderItemTodo) => {
      fetchAbortMasterStepSequence({ todo, fetch: fetchTodos });
    },
    [fetchTodos]
  );

  return (
    <>
      {(ticketCount.ANA > 0 || ticketCount.JAL > 0 || ticketCount.SFJ > 0) && (
        <TicketCountArea>
          <TicketCountTitle>未使用株優枚数</TicketCountTitle>
          {Object.keys(ticketCount).map(t => (
            <TicketCountLabel key={t}>
              {t}: {ticketCount[t]}
            </TicketCountLabel>
          ))}
        </TicketCountArea>
      )}
      <SearchArea>
        <SearchBlock>
          <SearchLabel>商品ステータス</SearchLabel>
          <OrderItemStatusSelector
            selectedOrderItemJobTypes={jobTypes}
            onChangeSelectedOrderItemJobTypes={setJobTypes}
            selectedOrderItemStatuses={statuses}
            onChangeSelectedOrderItemStatuses={setStatuses}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>旅程ステータス</SearchLabel>
          <TripStatusSelector selectedTripStatuses={tripStatuses} onChangeSelectedTripStatuses={setTripStatuses} />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>カテゴリ</SearchLabel>
          <OrderItemCategorySelector selectedOrderItemCategories={categories} onChange={setCategories} />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>エレメント</SearchLabel>
          <Select
            value={elementQuery}
            onChange={e => setElementQuery(e.target.value as ElementQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {ElementQueryKeys.map(key => (
              <option key={key} value={key}>
                {ElementQuery[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>スヌーズ</SearchLabel>
          <Select
            value={snoozeStatus}
            onChange={e => setSnoozeStatus(e.target.value as SnoozeStatusKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {SnoozeStatusKeys.map(key => (
              <option key={key} value={key}>
                {SnoozeStatus[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>問い合わせ対応</SearchLabel>
          <Select
            value={waiting}
            onChange={e => setWaiting(e.target.value as WaitingStatusKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {WaitingStatusKeys.map(key => (
              <option key={key} value={key}>
                {WaitingStatus[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>SP</SearchLabel>
          <Select
            value={sp}
            onChange={e => setSp(e.target.value as SpQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {SpQueryKeys.map(key => (
              <option key={key} value={key}>
                {SpQuery[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>QR利用企業</SearchLabel>
          <Select
            value={useQr}
            onChange={e => setUseQr(e.target.value as UseQrQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {UseQrQueryKeys.map(key => (
              <option key={key} value={key}>
                {UseQrQuery[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>Trip ID</SearchLabel>
          <Input
            value={tripId || ''}
            onChange={e => {
              if (_.isEmpty(e.target.value)) {
                setTripId(undefined);
              } else {
                setTripId(parseInt(e.target.value, 10));
              }
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>出発日</SearchLabel>
          <DateRangePicker
            from={startDateFrom}
            to={startDateTo}
            onFromChange={d => setStartDateFrom(d)}
            onToChange={d => setStartDateTo(d)}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>依頼日</SearchLabel>
          <DateRangePicker
            from={receiveDateFrom}
            to={receiveDateTo}
            onFromChange={d => setReceiveDateFrom(d)}
            onToChange={d => setReceiveDateTo(d)}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>発券方法</SearchLabel>
          <Select
            value={ticketingType}
            onChange={e => setTicketingType(e.target.value as TicketingTypeQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {TicketingTypeQuery(serviceId).map(d => (
              <option key={d.value} value={d.value}>
                {d.label}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>法人</SearchLabel>
          {renderOrganizationSelector()}
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyOthers} onChange={e => setOnlyOthers(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>ステータス最終更新者が自分以外</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyUnchanged} onChange={e => setOnlyUnchanged(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>依頼時からの内容変更がない商品のみ</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyManual} onChange={e => setOnlyManual(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>手動作成のみ</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyPremiumSupport} onChange={e => setOnlyPremiumSupport(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>土日祝オプション企業のみ</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>並び順</SearchLabel>
          <Select
            value={sortKey}
            onChange={e => setSortKey(e.target.value as SortKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            <option value="received_at">依頼日</option>
            <option value="started_at">出発日</option>
          </Select>
          <Radio
            checked={sortDirection === 'asc'}
            onChange={() => setSortDirection('asc')}
            style={{ marginRight: '5px' }}
          >
            昇順▲
          </Radio>
          <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
            降順▼
          </Radio>
        </SearchBlock>
      </SearchArea>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>検索結果: {totalCount}件</div>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Tripステータス</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>ステータス最終更新者</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>シーケンス</TableCell>
                <TableCell>変更</TableCell>
                <TableCell>法人名</TableCell>
                <TableCell>SP</TableCell>
                <TableCell>QR</TableCell>
                <TableCell>土日祝対応</TableCell>
                <TableCell>問い合わせ対応</TableCell>
                <TableCell>発券方法</TableCell>
                <TableCell nowrap>出発日</TableCell>
                <TableCell nowrap>依頼日</TableCell>
                <TableCell>チャットメッセージ</TableCell>
                <TableCell nowrap>Trip ID</TableCell>
                <TableCell nowrap>Trace ID</TableCell>
                <TableCell>最新のログメッセージ</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todos.map((todo, i) => (
                <OrderItemTodoTableRow
                  key={i}
                  todo={todo}
                  onMessageFinish={markMessageFinish}
                  onSaveSnooze={saveSnooze}
                  onCancelSnooze={cancelSnooze}
                  onSequenceOpen={handleSequenceUpdatePopoverOpen}
                  onSequenceAbortOpen={handleSequenceAbortConfirmOpen}
                  fetchOrderItemTodos={fetchTodos}
                  serviceId={serviceId}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_e: unknown, newPage: number) => setPage(newPage)}
          />
        </>
      )}
      <OrderItemSequenceUpdateSelector
        todo={sequenceUpdatePopover.todo}
        anchorEl={sequenceUpdatePopover.anchorEl}
        onChange={handleSequenceUpdatePopoverChange}
        onClose={handleSequenceUpdatePopoverClose}
      />
      <OrderItemSequenceAbortConfirm
        todo={sequenceAbortConfirm.todo}
        onAbort={handleSequenceAbortConfirmAbort}
        onClose={handleSequenceAbortConfirmClose}
      />
    </>
  );
});

export default OrderItemTodoList;
