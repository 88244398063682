import { observable } from 'mobx';

export interface OptionPlanArgs {
  id: number;
  name: string;
}

export type OptionPlanKey =
  | 'market_log'
  | 'project_share'
  | 'daily_allowance'
  | 'restriction_distance_and_time'
  | 'payment_change_option'
  | 'expenses_account_type'
  | 'express_outsourcing'
  | 'ip_address_fix_or_restriction'
  | 'billing_separation'
  | 'i18n'
  | 'expense_by_municipality'
  | 'approve_item'
  | 'billing_at_second_business_day'
  | 'billing_at_third_business_day'
  | 'billing_at_4th_business_day'
  | 'premium_support'
  | 'trip_report'
  | 'custom_workflow';

class OptionPlan {
  @observable
  id: number;

  @observable
  name: string;

  constructor(args: OptionPlanArgs) {
    this.id = args.id;
    this.name = args.name;
  }
}

export default OptionPlan;
