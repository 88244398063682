import React from 'react';
import { observer } from 'mobx-react';

import { Link } from '@this/shared/ui/navigations/link';
import type SearchTracking from '@this/src/domain/search_tracking';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';

import { SearchTrackingDetailSection } from './share/SearchTrackingDetailSection';

interface Props {
  searchTracking: SearchTracking;
}

const SearchTrackingDetailInformation: React.FC<Props> = observer(({ searchTracking }) => {
  return (
    <SearchTrackingDetailSection title="ログの概要">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>項目</TableCell>
            <TableCell>内容</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>トラッキングID</TableCell>
            <TableCell>{searchTracking.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ユーザー名</TableCell>
            <TableCell>{searchTracking.userName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{searchTracking.userEmail}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>企業名</TableCell>
            <TableCell>{searchTracking.organizationName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SearchQuery ID</TableCell>
            <TableCell>{searchTracking.searchQueryId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trip ID</TableCell>
            <TableCell>{searchTracking.tripId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>追跡開始時刻</TableCell>
            <TableCell>{searchTracking.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>追跡終了時刻</TableCell>
            <TableCell>{searchTracking.lastLogCreatedAt?.format('YYYY-MM-DD HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Bugsnagリンク</TableCell>
            <TableCell>
              <Link href={searchTracking.bugsnagLink || '#'} isExternal target="_blank">
                Bugsnagを見る
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SearchTrackingDetailSection>
  );
});

export default SearchTrackingDetailInformation;
