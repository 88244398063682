import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import type FlightMarketLogCandidate from '@this/domain/market_log/flight_market_log_candidate';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

type Props = {
  candidates: FlightMarketLogCandidate[];
  price: number;
};

type RangeCount = {
  [key: number]: number;
};

const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    yAxes: {
      ticks: {
        // 小数点の目盛は表示しないようにする
        callback: label => {
          const numLabel = Number(label);
          if (Math.floor(numLabel) === numLabel) {
            return label;
          }
          return null;
        }
      }
    }
  },
  maintainAspectRatio: false,
  responsive: false
};

const ranges = [10000, 30000, 50000, 100000];

const chartData = (
  targetPrice: number,
  candidates: FlightMarketLogCandidate[],
  span = 50000
): React.ComponentProps<typeof Bar>['data'] => {
  const maxPriceCandidate = _.maxBy(candidates, c => c.price);
  const max_price = maxPriceCandidate ? maxPriceCandidate.price : 0;
  const priceRanges: number[] = [];
  const backgroundColors: string[] = [];
  let range = 0;
  while (true) {
    range += span;
    priceRanges.push(range);

    // 予約した券の金額が含まれているバーのみ色を変える
    if (range - span < targetPrice && targetPrice <= range) {
      backgroundColors.push('rgba(255, 64, 64, 0.1)');
    } else {
      backgroundColors.push('rgba(0, 0, 0, 0.1)');
    }

    if (range > max_price) {
      break;
    }
  }

  // 金額帯に含まれる航空券の数をカウントする。
  const rangeCount: RangeCount = {};
  priceRanges.forEach(p => {
    rangeCount[p] = 0;
  });
  candidates.forEach(c => {
    priceRanges.forEach(p => {
      if (c.price >= p - span && p > c.price) {
        rangeCount[p] += 1;
      }
    });
  });

  return {
    labels: priceRanges.map(p => `~${utils.formatPrice(p)}`),
    datasets: [
      {
        label: '件数',
        data: Object.values(rangeCount),
        backgroundColor: backgroundColors
      }
    ]
  };
};

const PriceDistributionChart = ({ candidates, price }: Props) => {
  const [range, setRange] = useState(10000);

  return (
    <div>
      <Field>
        <span>金額表示レンジ:</span>
        <RangeSelect value={range} onChange={e => setRange(parseInt(e.target.value, 10))}>
          {ranges.map(item => (
            <option key={item} value={item}>
              {item.toLocaleString()}円
            </option>
          ))}
        </RangeSelect>
      </Field>
      <Bar data={chartData(price, candidates, range)} width={800} height={350} options={chartOptions} />
    </div>
  );
};

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const RangeSelect = styled.select`
  margin-bottom: 0;
  margin-left: 5px;
`;

export default PriceDistributionChart;
