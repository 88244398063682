import type Organization from '@this/domain/organization/organization2';
import type { TransitSearchApi } from '@this/domain/organization/organization2';
import type { AvailableRepository } from '@this/domain/available_repository';
import type SearchQuery from './search_query';
import SearchResult from './search_result';

export type SelectType = 'separate' | 'airPackage' | 'jrPackage';

export type PackageProvider = 'wbf' | 'rakuten';

export type Airline = 'ANA' | 'JAL';

export type Tab =
  | 'none' // 何も選択しない。
  | 'outword'
  | 'homeword'
  | 'hotel'
  | 'packageHotel'
  | 'packageOutword'
  | 'packageHomeword'
  | number;

class SelectStore {
  public result: SearchResult;

  public query: SearchQuery;

  public currentTab: Tab;

  public packageLoading: { ANA: boolean; JAL: boolean };

  public jrPackageLoading: boolean;

  public packageErrors: { ANA: string[] | null | undefined; JAL: string[] | null | undefined };

  public jrPackageErrors: string[] | null | undefined;

  public showPackage: boolean;

  public signedIn: boolean;

  public flashMsgEnd: boolean;

  public needReplaceSegments: boolean;

  public availableRepos: AvailableRepository[];

  // TODO: UserJson定義
  public user: {
    email: string;
    organization?: {
      arrangement_request_setting: {
        enable: boolean;
        fee?: number | null;
      };
      reserve_request_mail_notification_type: Organization['reserveRequestMailNotificationType'];
      transit_search_api: TransitSearchApi;
    };
    domestic_air_seat_upgrade: boolean;
  } | null;

  public appliedFlightQuerySetting: { carrier: boolean; flight_type: boolean } = {
    carrier: false,
    flight_type: false
  };

  constructor(args: { query: SearchQuery }, user: any, availableRepos: AvailableRepository[]) {
    this.result = new SearchResult(args);
    this.query = args.query;
    this.currentTab = 'none';
    this.packageErrors = { ANA: null, JAL: null };
    this.signedIn = false;
    this.user = user;
    this.showPackage = !this.query.disabledPackageSearch;
    this.packageLoading = { ANA: this.showPackage, JAL: this.showPackage };
    this.jrPackageLoading = this.showPackage;
    this.flashMsgEnd = false;
    this.needReplaceSegments = false;
    this.availableRepos = availableRepos ?? [];
  }

  handleClickTab = (tab: Tab) => {
    this.currentTab = tab;
    app.render();
  };

  setTab = (tab: Tab) => () => {
    this.currentTab = tab;
  };

  pushHistory = () => {
    window.history.replaceState(null, '', `/select${this.query.getQueryString()}`);
  };

  setBasicError = () => {
    app.render();
  };

  searchProgress = (serviceId: number) => {
    let Percentage = 0;
    if (utils.isMynavi(serviceId)) {
      Percentage += this.packageLoading.ANA ? 0 : 50;
      Percentage += this.packageLoading.JAL ? 0 : 50;
    } else {
      Percentage += this.packageLoading.ANA ? 0 : 33.3;
      Percentage += this.packageLoading.JAL ? 0 : 33.3;
      Percentage += this.jrPackageLoading ? 0 : 33.3;
    }
    return Math.round(Percentage);
  };

  searchProgressAna() {
    return this.packageLoading.ANA;
  }

  searchProgressJal() {
    return this.packageLoading.JAL;
  }

  searchProgressJr() {
    return this.jrPackageLoading;
  }

  searchProgressAll(serviceId: number) {
    let flg = true;
    if (utils.isMynavi(serviceId)) {
      flg = this.packageLoading.ANA || this.jrPackageLoading;
    } else {
      flg = this.packageLoading.ANA || this.jrPackageLoading || this.jrPackageLoading;
    }
    if (!flg) {
      setTimeout(() => {
        this.flashMsgEnd = true;
      }, 3000);
    }
    return flg;
  }

  searchPackageResultText(serviceId: number) {
    let findFlg = false;
    if (this.result.getAirPackageTrip('ANA').packageFinalPrice() !== 0) {
      findFlg = true;
    }
    if (this.result.getAirPackageTrip('JAL').packageFinalPrice() !== 0) {
      findFlg = true;
    }
    if (!utils.isMynavi(serviceId) && this.result.getJrPackageTrip().packageFinalPrice() !== 0) {
      findFlg = true;
    }

    if (findFlg) {
      return 'パッケージが見つかりました';
    }
    return 'パッケージが見つかりませんでした';
  }

  isEndFlashMsg() {
    return this.flashMsgEnd;
  }

  cheapestPricePackage(serviceId: number) {
    const priceMap = [{ key: 'INDI', price: this.result.getReservingTrip().separateFinalPrice() }];
    if (this.result.getAirPackageTrip('ANA').packageFinalPrice() !== 0) {
      priceMap.push({ key: 'ANA', price: this.result.getAirPackageTripCheapest('ANA').packageFinalPrice() });
    }
    if (this.result.getAirPackageTrip('JAL').packageFinalPrice() !== 0) {
      priceMap.push({ key: 'JAL', price: this.result.getAirPackageTripCheapest('JAL').packageFinalPrice() });
    }
    if (!utils.isMynavi(serviceId) && this.result.getJrPackageTrip().packageFinalPrice() !== 0) {
      priceMap.push({ key: 'JR', price: this.result.getJrPackageTripCheapest().packageFinalPrice() });
    }
    priceMap.sort((a, b) => {
      if (a.price < b.price) return -1;
      if (a.price > b.price) return 1;
      return 0;
    });
    return priceMap[0].key;
  }

  isTravelport() {
    return this.availableRepos.some(
      repo => repo.category === 'foreign_flight' && repo.repository === 'travelport'
    );
  }

  // TravelportもAmadeusも6区間毎に検索する
  maxSegments() {
    return 6;
  }

  get isCurrentAirlinePackageLoading() {
    return this.packageLoading[this.result.airline];
  }
}

export default SelectStore;
