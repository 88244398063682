import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type { SigninFormData } from './types';
import SigninForm from './user_signin_form';
import type { signInResponse } from './user_signin';
import PasswordResetForm from './user_password_reset_form';

interface UserSigninBodyProps {
  signin: ({ email, password }: SigninFormData) => Promise<signInResponse>;
  resetPassword: (email: string) => Promise<boolean>;
  serviceId: number;
  isPasswordExpired: boolean;
}

class UserSigninBody extends React.Component<UserSigninBodyProps, { isShowSigninForm: boolean }> {
  constructor(props: UserSigninBodyProps) {
    super(props);
    this.state = { isShowSigninForm: !props.isPasswordExpired };
  }

  navigateToPasswordReset = () => {
    this.setState({ isShowSigninForm: false });
  };

  navigateToSignin = () => {
    this.setState({ isShowSigninForm: true });
  };

  render() {
    const { serviceId, isPasswordExpired } = this.props;
    return (
      <UserSignInBody>
        <div>
          <LogoContainer>
            <BodyLogo />
          </LogoContainer>
          {this.state.isShowSigninForm ? (
            <SigninForm
              navigateToPasswordReset={this.navigateToPasswordReset}
              signin={this.props.signin}
              serviceId={serviceId}
            />
          ) : (
            <PasswordResetForm
              isPasswordExpired={isPasswordExpired}
              navigateToBack={this.navigateToSignin}
              resetPassword={this.props.resetPassword}
            />
          )}
        </div>
      </UserSignInBody>
    );
  }
}

export const UserSignInBody = styled.div`
  display: flex;
  align-items: center;
  max-width: 350px;
  width: 100%;
  height: calc(100vh - 165px);
  margin: 0 auto;

  > * {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.signInBodyBgColor};
  height: 140px;
  width: 100%;
`;

export const BodyLogo = styled.span`
  background-image: url('${props => props.theme.signInBodyLogoPath}');
  background-color: ${props => props.theme.signInBodyBgColor};
  background-repeat: no-repeat;
  background-size: contain;
  height: 69px;
  max-width: 350px;
  width: 100%;
  background-position: center center;
`;

export default UserSigninBody;
