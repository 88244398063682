import ChatbotMessage, { convertChatbotMessageResponseToArgs } from '@this/src/domain/chatbot/chatbot_message';
import type { TextToQueryFormAttributesResponseArgs } from '@this/src/domain/chatbot/text_to_query_result_item';
import moment from '@this/src/lib/moment';

import ChatbotUserSetting, {
  convertChatbotUserSettingResponseToArgs,
  emptyChatbotUserSetting
} from '@this/src/domain/chatbot/chatbot_user_setting';
import type { ChatbotDisplayMode } from './mode';
import type { SessionMessagesResponse } from './api/fetch_session_messages';
import type { InitiateSessionResponse } from './api/initiate_session_api';

export interface ChatbotState {
  isOpen: boolean;
  loading: boolean;
  mode: ChatbotDisplayMode;
  message: string;
  isSubmitting: boolean;
  isMakingQuery: boolean;
  isKnowledgeSearching: boolean;
  isAutoFocus: boolean;
  isLatest: boolean;
  isRestart: boolean;
  messages: ChatbotMessage[];
  userSetting: ChatbotUserSetting;
  sessionId: number | undefined;
  loadedSessionId: number | undefined;
  latestSessionId: number | undefined;
}

export type ChatbotAction =
  | { type: 'TOGGLE_OPEN' }
  | { type: 'LOADING' }
  | { type: 'CHANGE_MODE'; payload: ChatbotDisplayMode }
  | { type: 'PUSH_MESSAGES'; messages: ChatbotMessage[] }
  | { type: 'PUSH_BOT_MESSAGE'; text: string; formAttributes?: TextToQueryFormAttributesResponseArgs }
  | { type: 'PUSH_BOT_ERROR_MESSAGE'; text: string }
  | { type: 'PUSH_USER_MESSAGE'; text: string; restart?: boolean }
  | { type: 'NEW_SESSION'; payload: InitiateSessionResponse }
  | { type: 'RELOAD_MESSAGES'; payload: SessionMessagesResponse }
  | { type: 'SET_MESSAGE'; message: string }
  | { type: 'RESET_MESSAGE' }
  | { type: 'SET_IS_SUBMITTING'; isSubmitting: boolean }
  | { type: 'SET_IS_MAKING_QUERY'; isMakingQuery: boolean }
  | { type: 'SET_IS_KNOWLEDGE_SEARCHING'; isKnowledgeSearching: boolean }
  | { type: 'SET_SESSION_ID'; sessionId: number | undefined }
  | { type: 'CHANGE_SESSION'; sessionId: number }
  | { type: 'CHANGE_NEW_SESSION' }
  | { type: 'CHANGE_LATEST_SESSION' };

export const chatbotReducer = (state: ChatbotState, action: ChatbotAction): ChatbotState => {
  switch (action.type) {
    case 'TOGGLE_OPEN':
      return { ...state, isOpen: !state.isOpen, isAutoFocus: true };
    case 'LOADING':
      return { ...state, loading: true };
    case 'CHANGE_MODE':
      return { ...state, mode: action.payload };
    case 'PUSH_MESSAGES':
      return {
        ...state,
        messages: [...state.messages, ...action.messages]
      };
    case 'PUSH_BOT_MESSAGE':
    case 'PUSH_BOT_ERROR_MESSAGE':
    case 'PUSH_USER_MESSAGE':
      return {
        ...state,
        isLatest: state.sessionId === state.latestSessionId ? false : state.isLatest,
        isRestart: false,
        messages: [
          ...state.messages,
          new ChatbotMessage(
            convertChatbotMessageResponseToArgs({
              author: action.type === 'PUSH_USER_MESSAGE' ? 'user' : 'bot',
              text: action.text,
              error: action.type === 'PUSH_BOT_ERROR_MESSAGE',
              restart: action.type === 'PUSH_USER_MESSAGE' ? action.restart || false : false,
              created_at: moment(),
              ...(action.type === 'PUSH_BOT_MESSAGE' ? action.formAttributes : undefined)
            })
          )
        ]
      };
    case 'NEW_SESSION':
      return {
        ...state,
        loading: false,
        isLatest: true,
        messages: [
          new ChatbotMessage({
            author: 'bot',
            text: action.payload.message,
            error: false,
            restart: false,
            createdAt: moment()
          })
        ],
        userSetting: new ChatbotUserSetting(
          convertChatbotUserSettingResponseToArgs(action.payload.chatbot_user_setting)
        ),
        sessionId: action.payload.session_id,
        loadedSessionId: action.payload.session_id,
        latestSessionId: action.payload.session_id
      };
    case 'RELOAD_MESSAGES':
      return {
        ...state,
        loading: false,
        loadedSessionId: action.payload.session_id || undefined,
        messages: action.payload.chatbot_session_messages.map(
          message => new ChatbotMessage(convertChatbotMessageResponseToArgs(message))
        )
      };
    case 'SET_MESSAGE':
      return { ...state, message: action.message };
    case 'RESET_MESSAGE':
      return { ...state, message: '' };
    case 'SET_IS_SUBMITTING':
      return { ...state, isSubmitting: action.isSubmitting };
    case 'SET_IS_MAKING_QUERY':
      return { ...state, isMakingQuery: action.isMakingQuery };
    case 'SET_IS_KNOWLEDGE_SEARCHING':
      return { ...state, isKnowledgeSearching: action.isKnowledgeSearching };
    case 'SET_SESSION_ID':
      return {
        ...state,
        sessionId: action.sessionId,
        loadedSessionId: action.sessionId,
        latestSessionId: state.latestSessionId || action.sessionId
      };
    case 'CHANGE_SESSION':
      return { ...state, mode: 'chat', isRestart: true, sessionId: action.sessionId };
    case 'CHANGE_NEW_SESSION':
      return {
        ...state,
        mode: 'chat',
        isRestart: false,
        sessionId: undefined,
        loadedSessionId: undefined,
        latestSessionId: undefined
      };
    case 'CHANGE_LATEST_SESSION':
      return {
        ...state,
        mode: 'chat',
        isRestart: false,
        messages: [
          new ChatbotMessage({
            author: 'bot',
            text: state.messages[0]?.text || '',
            error: false,
            restart: false,
            createdAt: moment()
          })
        ],
        sessionId: state.latestSessionId,
        loadedSessionId: state.latestSessionId
      };
    default:
      return state;
  }
};

export const initialChatbotState: ChatbotState = {
  isOpen: false,
  loading: false,
  mode: 'chat',
  message: '',
  isSubmitting: false,
  isMakingQuery: false,
  isKnowledgeSearching: false,
  isAutoFocus: false,
  isLatest: false,
  isRestart: false,
  messages: [],
  userSetting: emptyChatbotUserSetting(),
  sessionId: undefined,
  loadedSessionId: undefined,
  latestSessionId: undefined
};
