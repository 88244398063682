import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import type { DomesticAirMarketLogArgs } from '../market_log/domestic_air_market_log';
import DomesticAirMarketLog from '../market_log/domestic_air_market_log';

export interface MarketLogSummaryArgs {
  trip_id: number;
  market_log: DomesticAirMarketLogArgs;
  received_at?: string;
  start_time?: string;
  lead_time: number;
  air_changeable: boolean;
}

class MarketLogSummary {
  public tripId: number;

  public marketLog: DomesticAirMarketLog;

  public receivedAt: Moment | null;

  public startTime: Moment | null;

  public leadTime: number;

  public airChangeable: boolean;

  constructor(args: MarketLogSummaryArgs) {
    this.tripId = args.trip_id;
    this.marketLog = new DomesticAirMarketLog(args.market_log);
    this.receivedAt = args.received_at ? moment(args.received_at) : null;
    this.startTime = args.start_time ? moment(args.start_time) : null;
    this.leadTime = args.lead_time;
    this.airChangeable = args.air_changeable;
  }
}

export default MarketLogSummary;
