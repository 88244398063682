import React from 'react';
import { styled, css } from '@this/constants/themes';
import {
  AvailableSeat,
  OccupiedSeat
} from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map_facility';
import { NoWindow } from './flight_seat_map_row_side';

const FlightSeatMapLegend: React.FC = () => {
  return (
    <Container>
      <Title>記号</Title>
      <Legends>
        <Legend>
          <AvailableSeatMark style={{ width: '3em' }} />
          <Description>予約可能な座席</Description>
        </Legend>
        <Legend>
          <AvailableSeatMark selected style={{ width: '3em' }} />
          <Description>選択中の座席</Description>
        </Legend>
        <Legend>
          <OccupiedSeatMark style={{ width: '3em' }} />
          <Description>ご利用不可</Description>
        </Legend>
        <Legend>
          <NoWindowMark />
          <Description>窓がない座席</Description>
        </Legend>
      </Legends>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 1em 0;
  font-size: 0.8em;
`;

const Title = styled.p`
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const Legends = styled.div`
  display: flex;
  align-items: center;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1em;
`;

const Description = styled.p`
  white-space: nowrap;
`;

const LegendMark = css`
  width: 2em;
  margin-right: 0.5em;

  &:hover {
    opacity: 1;
    cursor: auto;
  }
`;

const AvailableSeatMark = styled(AvailableSeat as any)`
  ${LegendMark}
`;

const OccupiedSeatMark = styled(OccupiedSeat as any)`
  ${LegendMark}
`;

const NoWindowMark = styled(NoWindow as any)`
  height: 2.5em;
  margin-right: 0.5em;

  &::before,
  &::after {
    height: 2.5em;
  }
`;

export default FlightSeatMapLegend;
