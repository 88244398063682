import React, { useState } from 'react';
import ArrangementOrganizationSelector from '../shared/arrangement_organization_selector';

const getDefaultOrganizationIds = (): number[] => {
  const organizationIds = utils.getParam('organization_ids');
  if (!organizationIds) {
    return [];
  }
  return organizationIds.split(',').map((id: string) => parseInt(id, 10));
};

const useOrganizationSelector = (): [number[], () => JSX.Element] => {
  const [organizationIds, setOrganizationIds] = useState<number[]>(getDefaultOrganizationIds());

  const renderOrganizationSelector = () => (
    <ArrangementOrganizationSelector
      selectedOrganizationIds={organizationIds}
      onSelect={ids => setOrganizationIds(ids)}
    />
  );

  return [organizationIds, renderOrganizationSelector];
};

export default useOrganizationSelector;
