import React from 'react';
import type { RouteComponentProps } from 'react-router';
import useApi from '@this/shared/hooks/use_api';
import { styled } from '@this/constants/themes';
import type { KnowledgeResponseArgs } from '@this/src/domain/knowledge';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import { ContentWrapper, Title } from '../knowledge';

type Props = RouteComponentProps<{ id?: string }>;
interface KnowledgeResponse {
  knowledge: KnowledgeResponseArgs;
}

const KnowledgeDetail: React.FC<Props> = (props: Props) => {
  const id = props.match.params.id;
  const { data, isLoading } = useApi<KnowledgeResponse>(`/knowledges/${id}.json`);
  if (!id) {
    return <div>お探しの記事はありません</div>;
  }
  if (!data) {
    return null;
  }
  const knowledge = data.knowledge;
  return (
    <ContentWrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Title>{knowledge.title}</Title>
          <Description dangerouslySetInnerHTML={{ __html: knowledge.answer }} />
        </>
      )}
    </ContentWrapper>
  );
};

const Description = styled.div`
  color: #3a3a3a;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  word-wrap: break-word;
`;

export default KnowledgeDetail;
