import React from 'react';

import type { AdminBulkTicktesResponse } from '@this/components/admin/bulk_tickets/types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import { BulkTicketsSection } from '@this/components/admin/bulk_tickets/bulk_tickets_section';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { useBoolean } from '@this/shared/hooks/use_boolean';

type Route = AdminBulkTicktesResponse['routes'][number];
export type RouteSeed = Pick<Route, 'from' | 'to'>;

type Props = {
  routes: AdminBulkTicktesResponse['routes'];
  onCreate: (seed: RouteSeed) => Promise<void>;
  onUpdate: (id: number, seed: RouteSeed) => Promise<void>;
};

export const BulkTicketRoutes = ({ routes, onCreate, onUpdate }: Props) => {
  const [value, { on, off }] = useBoolean();
  const [editting, setEditting] = React.useState<Route | undefined>(undefined);

  const close = React.useCallback(() => {
    setEditting(undefined);
    off();
  }, [off, setEditting]);

  const handleSubmit = React.useCallback(
    async (route: RouteSeed) => {
      if (editting) {
        await onUpdate(editting.id, route);
      } else {
        await onCreate(route);
      }

      close();
    },
    [editting, onCreate, onUpdate]
  );

  return (
    <>
      <BulkTicketsSection
        title="区間"
        renderHeaderAction={
          <Button size="small" onClick={on}>
            作成
          </Button>
        }
        renderTable={
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell width="24px">{/** 編集ボタン用 */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routes.map(route => (
                <TableRow key={route.id}>
                  <TableCell>{route.from}</TableCell>
                  <TableCell>{route.to}</TableCell>
                  <TableCell>
                    <Link
                      onClick={() => {
                        setEditting(route);
                        on();
                      }}
                    >
                      編集
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      />

      <BulkTicketRouteForm open={value} route={editting} onSubmit={handleSubmit} onCancel={close} />
    </>
  );
};

type FormProps = {
  open: boolean;
  route?: Route;
  onSubmit: (route: RouteSeed) => Promise<void>;
  onCancel: () => void;
};
const BulkTicketRouteForm = ({ open, route, onSubmit, onCancel }: FormProps) => {
  const [from, setFrom] = React.useState(route?.from ?? '');
  const [to, setTo] = React.useState(route?.to ?? '');
  const [submitting, setSubmitting] = React.useState(false);
  const formid = 'BulkTicketRouteForm';

  React.useEffect(() => {
    setFrom(route?.from ?? '');
    setTo(route?.to ?? '');
  }, [route]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      await onSubmit({ from, to });
      setFrom('');
      setTo('');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} size="small" onClose={onCancel}>
      <ModalHeader>区間の{route ? '編集' : '作成'}</ModalHeader>
      <ModalBody>
        <form id={formid} onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <InputLabel>From</InputLabel>
            <Input type="text" value={from} onChange={e => setFrom(e.target.value)} />
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel>To</InputLabel>
            <Input type="text" value={to} onChange={e => setTo(e.target.value)} />
          </FormControl>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onCancel}>
          キャンセル
        </Button>
        <Button type="submit" form={formid} disabled={submitting}>
          保存
        </Button>
      </ModalFooter>
    </Modal>
  );
};
