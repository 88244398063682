import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { observable } from 'mobx';

export interface JalReservationTotalArgs {
  reservation_date: string;
  reservation_count: number;
  ai_operator_count: number;
  total_price: number;
  approved_at: string;
  approved_by: number;
}

export interface JalReservationTotalProps {
  reservationDate: Moment;
  reservationCount: number;
  aiOperatorCount: number;
  totalPrice: number;
  approvedAt: Moment | null;
  approvedBy: number | null;
}

export const convertJalReservationTotal = (args: JalReservationTotalArgs): JalReservationTotalProps => ({
  reservationDate: moment(args.reservation_date),
  reservationCount: args.reservation_count,
  aiOperatorCount: args.ai_operator_count,
  totalPrice: args.total_price,
  approvedAt: args.approved_at ? moment(args.approved_at) : null,
  approvedBy: args.approved_by || null
});

export default class JalReservationTotal {
  @observable readonly reservationDate: Moment;

  @observable readonly reservationCount: number;

  @observable readonly aiOperatorCount: number;

  @observable readonly totalPrice: number;

  @observable approvedAt: Moment | null;

  @observable approvedBy: number | null;

  constructor(args: JalReservationTotalProps) {
    this.reservationDate = args.reservationDate;
    this.reservationCount = args.reservationCount;
    this.aiOperatorCount = args.aiOperatorCount;
    this.totalPrice = args.totalPrice;
    this.approvedAt = args.approvedAt;
    this.approvedBy = args.approvedBy;
  }

  setApprovedAt(approvedAt?: string) {
    this.approvedAt = approvedAt ? moment(approvedAt) : null;
  }

  setApprovedBy(approvedBy?: number) {
    this.approvedBy = approvedBy || null;
  }
}
