import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/components/shared/atoms/button';

import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/components/shared/modal/modal';

interface Props {
  downloading: boolean;
  showDownloadModal: boolean;
  useNonOrderItem: boolean;
  excludeOrder: boolean;
  handleSubmitDownload: (e: React.MouseEvent<HTMLElement>) => void;
  handleCancelDownload: () => void;
  handleToggleOnlyNonOrderItem: () => void;
  downloadError: string | null;
  downloadTripId: number | null;
}

const TripDownloadModalTemplate: React.SFC<Props> = ({
  downloading,
  showDownloadModal,
  useNonOrderItem,
  excludeOrder,
  handleSubmitDownload,
  handleCancelDownload,
  handleToggleOnlyNonOrderItem,
  downloadError,
  downloadTripId
}) => (
  <TripModal hideModal={handleCancelDownload} show={showDownloadModal} title="旅程CSVダウンロード">
    <Hint>外部システム連携用CSVをダウンロードします。</Hint>
    {downloading ? (
      <SimpleLoading />
    ) : (
      <>
        {downloadError && <Error>{downloadError}</Error>}
        <FormatDefinitionWraper>
          <InputWrap>
            <FormatLabel>フォーマット:</FormatLabel>
            <select>
              <option>楽楽精算</option>
            </select>
          </InputWrap>
          {useNonOrderItem && (
            <InputWrap>
              <label>
                <input type="checkbox" checked={excludeOrder} onChange={handleToggleOnlyNonOrderItem} />
                <span>従業員立替のみ</span>
              </label>
            </InputWrap>
          )}
        </FormatDefinitionWraper>
        <Buttons>
          <AButton
            href={`/trips/${downloadTripId}.csv/?exclude_order=${excludeOrder}`}
            onClick={handleSubmitDownload}
          >
            ダウンロード
          </AButton>
          <BackButton onClick={handleCancelDownload}>戻る</BackButton>
        </Buttons>
      </>
    )}
  </TripModal>
);

const TripModal = styled(Modal)`
  position: fixed;
  width: auto;
`;

const Hint = styled.p`
  margin: 0 10px 10px 10px;
`;

const FormatDefinitionWraper = styled.div`
  margin: 0 10px;
  display: flex;
`;

const FormatLabel = styled.p`
  margin-right: 10px;
`;

const InputWrap = styled.div`
  display: flex;
  margin-right: 20px;
`;

const Error = styled.p`
  color: ${props => props.theme.redColor};
  margin: 20px;
`;

const Buttons = styled.div`
  text-align: center;
`;

const AButton = styled.a`
  &&& {
    ${ButtonBase}
    display: inline-block;
    padding: 7px 10px;
  }
`;

const Button = styled.button`
  ${ButtonBase}
  display: inline-block;
`;

const BackButton = styled(Button)`
  margin-left: 20px;
`;

export default TripDownloadModalTemplate;
