import React, { useCallback } from 'react';

import _ from 'lodash';
import moment from 'moment';

import DatetimePicker from '@this/src/components/shared/datetime_picker/datetime_picker';
import { Field, FieldsWrapper, InLabel, InputArea, Select } from '@this/src/components/shared/search/search';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';
import type { HomewardDateField, OutwardDateField } from '@this/src/domain/chatbot/chatbot_message';
import type { Moment } from '@this/src/lib/moment';
import { doubleDigits } from '@this/src/util';

interface Props {
  dateType: 'outward_date' | 'homeward_date';
  message: ChatbotMessage;
}

export const ChatMessageFormDate: React.FC<Props> = ({ dateType, message }) => {
  const formField = message.formFields.find(field => field.type === dateType) as
    | OutwardDateField
    | HomewardDateField;
  const date = formField?.value as Moment;
  const outhour = formField?.outhour as number;
  const outmin = formField?.outmin as number;
  const outtype = formField?.outtype as 'departure' | 'arrival';

  const handleChangeDate = useCallback(
    (date: Moment) => {
      if (dateType === 'outward_date') {
        message.setFormFieldOutwardDate(date);
      } else {
        message.setFormFieldHomewardDate(date);
      }
    },
    [dateType, message]
  );

  const handleChangeHour = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (dateType === 'outward_date') {
        message.setFormFieldOutwardTime('outhour', parseInt(evt.target.value, 10));
      } else {
        message.setFormFieldHomewardTime('outhour', parseInt(evt.target.value, 10));
      }
    },
    [dateType, message]
  );

  const handleChangeMin = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (dateType === 'outward_date') {
        message.setFormFieldOutwardTime('outmin', parseInt(evt.target.value, 10));
      } else {
        message.setFormFieldHomewardTime('outmin', parseInt(evt.target.value, 10));
      }
    },
    [dateType, message]
  );

  const handleChangeType = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      if (dateType === 'outward_date') {
        message.setFormFieldOutwardTime('outtype', evt.target.value as 'departure' | 'arrival');
      } else {
        message.setFormFieldHomewardTime('outtype', evt.target.value as 'departure' | 'arrival');
      }
    },
    [dateType, message]
  );

  if (!formField) return null;

  return (
    <FieldsWrapper className="search__block__body__fields-wrapper">
      <Field>
        <InLabel>往路</InLabel>
        <InputArea
          style={{ alignItems: 'center' }}
          wrapped
          id="simple_outdate_input"
          className="calendar-input-div"
        >
          <DatetimePicker
            value={moment(date)}
            dateFormat="YYYY/MM/DD (ddd)"
            showToday
            onChange={handleChangeDate}
            width="115px"
            disabledDays={0}
            calenderClass="rc-calendar-trip"
          />
          <div style={{ display: 'flex' }} className="search__select-wrapper">
            <Select name="outhour" value={outhour || ''} onChange={handleChangeHour}>
              <option value="">-</option>
              {_.times(24, i => (
                <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                  {i}
                </option>
              ))}
            </Select>
            <span style={{ marginRight: '15px' }}>時</span>
          </div>
          <div style={{ display: 'flex' }} className="search__select-wrapper">
            <Select name="outmin" value={outmin} onChange={handleChangeMin}>
              <option value="">-</option>
              {_.times(6, i => (
                <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                  {i * 10}
                </option>
              ))}
            </Select>
            <span style={{ marginRight: '15px' }}>分</span>
          </div>
          <div className="search__select-wrapper">
            <Select name="outtype" value={outtype} onChange={handleChangeType}>
              <option value="departure">出発</option>
              <option value="arrival">到着</option>
            </Select>
          </div>
        </InputArea>
      </Field>
    </FieldsWrapper>
  );
};
