import React from 'react';

const HeaderSp = () => {
  return (
    <header className="flex">
      <div className="logos left">
        <a href="/">
          <div className="logo-img2" />
        </a>
      </div>
      <span className="tel right flex">
        <div className="tel_number">
          <a href="tel:03-6843-3297">03-6843-3297</a>
        </div>
        <div className="note">10:00 ~ 18:00（土日祝日除く）</div>
      </span>
      <div className="inquiries flex">
        <a className="button inquiry flex" href="https://aitravel.cloud/download/">
          資料請求
        </a>
      </div>
    </header>
  );
};

export default HeaderSp;
