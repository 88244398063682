import _ from 'lodash';
import { observable, action } from 'mobx';
import type { NonOrderItemMappingArgs, NonOrderItemMappingMode } from './non_order_item_mapping';
import NonOrderItemMapping, { NON_ORDER_ITEM_MAPPING_DEFAULT_ARGS } from './non_order_item_mapping';
import type ReservingTrip from './trip/reserving_trip';

class NonOrderItemMappingList {
  @observable
  public list: NonOrderItemMapping[];

  constructor(raw: NonOrderItemMappingArgs[]) {
    this.list = raw.map(o => new NonOrderItemMapping(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  findDefaultMapping() {
    const args = NON_ORDER_ITEM_MAPPING_DEFAULT_ARGS;
    return this.findByTravelerAndItem(args.travelerId!, args.nonOrderItemType!, args.nonOrderItemIndex!);
  }

  findByTravelerAndItem(traveler_id: number, non_order_item_type: string, index: number) {
    return _.find(
      this.list,
      o =>
        o.travelerId === traveler_id && o.nonOrderItemType === non_order_item_type && o.nonOrderItemIndex === index
    );
  }

  findByTravelerInformationAndOrderItem(traveler_information_id: number, id: number | undefined) {
    return _.find(this.list, o => o.travelerInformationId === traveler_information_id && o.nonOrderItemId === id);
  }

  first() {
    return _.first(this.list);
  }

  last() {
    return _.last(this.list);
  }

  @action
  push(o: NonOrderItemMapping) {
    this.list.push(o);
  }

  @action
  remove(o: NonOrderItemMapping) {
    const i = this.list.indexOf(o);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  clone(): NonOrderItemMappingList {
    const cloned = new NonOrderItemMappingList([]);
    this.list.forEach(mapping => {
      cloned.push(mapping.clone());
    });
    return cloned;
  }

  mappingMode(): NonOrderItemMappingMode {
    return this.list[0]?.nonOrderItemMappingType() as NonOrderItemMappingMode;
  }

  isForDetail(): boolean {
    const mode = this.mappingMode();
    if (mode) {
      return !['', 'default'].includes(mode);
    }
    return false;
  }

  isProjectExist() {
    return this.list.every(mapping => mapping.isProjectExist());
  }

  isChargingDepartmentExist() {
    return this.list.every(mapping => mapping.isChargingDepartmentExist());
  }

  isExpensesAccountTypeExist() {
    return this.list.every(mapping => mapping.isExpensesAccountTypeExist());
  }

  submitParams() {
    return this.list.map(mapping => mapping.submitParams());
  }

  static defaultList(): NonOrderItemMappingList {
    const mappingList = new NonOrderItemMappingList([]);
    mappingList.list = [NonOrderItemMapping.defaultMapping()];
    return mappingList;
  }

  static initialListForItem(reservingTrip: ReservingTrip, rentalCarsCount: number): NonOrderItemMappingList {
    const nonOrderItemMappingArgsList: NonOrderItemMappingArgs[] = [];
    if (reservingTrip.isPackage()) {
      const args: NonOrderItemMappingArgs = {
        travelerId: -1,
        nonOrderItemType: 'package',
        nonOrderItemIndex: 1
      };
      nonOrderItemMappingArgsList.push(args);
    } else {
      const list =
        reservingTrip.items && reservingTrip.items.length > 0 ? reservingTrip.items : reservingTrip.nonOrderItems;
      list.forEach((item: any) => {
        const args: NonOrderItemMappingArgs = {
          travelerId: -1,
          nonOrderItemType: 'item',
          nonOrderItemIndex: Number(item?.index)
        };
        nonOrderItemMappingArgsList.push(args);
      });
    }

    [...Array(rentalCarsCount)].forEach((_, i) => {
      const args: NonOrderItemMappingArgs = {
        travelerId: -1,
        nonOrderItemType: 'rental_car',
        nonOrderItemIndex: i
      };
      nonOrderItemMappingArgsList.push(args);
    });

    return new NonOrderItemMappingList(nonOrderItemMappingArgsList);
  }

  static initialListForTraveler(travelersCount: number): NonOrderItemMappingList {
    const nonOrderItemMappingArgsList: NonOrderItemMappingArgs[] = [];
    [...Array(travelersCount)].forEach((_, index) => {
      const args: NonOrderItemMappingArgs = {
        travelerId: index,
        nonOrderItemType: 'all',
        nonOrderItemIndex: -1
      };
      nonOrderItemMappingArgsList.push(args);
    });

    return new NonOrderItemMappingList(nonOrderItemMappingArgsList);
  }

  static initialListForBoth(
    reservingTrip: ReservingTrip,
    travelersCount: number,
    rentalCarsCount: number
  ): NonOrderItemMappingList {
    const nonOrderItemMappingArgsList: NonOrderItemMappingArgs[] = [];
    if (reservingTrip.isPackage()) {
      [...Array(travelersCount)].forEach((_, index) => {
        const args: NonOrderItemMappingArgs = {
          travelerId: index,
          nonOrderItemType: 'package',
          nonOrderItemIndex: 1
        };
        nonOrderItemMappingArgsList.push(args);
      });
    } else {
      const list =
        reservingTrip.items && reservingTrip.items.length > 0 ? reservingTrip.items : reservingTrip.nonOrderItems;
      list.forEach((item: any) => {
        [...Array(travelersCount)].forEach((_, index) => {
          const args: NonOrderItemMappingArgs = {
            travelerId: index,
            nonOrderItemType: 'item',
            nonOrderItemIndex: Number(item?.index)
          };
          nonOrderItemMappingArgsList.push(args);
        });
      });
    }

    [...Array(rentalCarsCount)].forEach((_, i) => {
      [...Array(travelersCount)].forEach((_, index) => {
        const args: NonOrderItemMappingArgs = {
          travelerId: index,
          nonOrderItemType: 'rental_car',
          nonOrderItemIndex: i
        };
        nonOrderItemMappingArgsList.push(args);
      });
    });

    return new NonOrderItemMappingList(nonOrderItemMappingArgsList);
  }
}

export default NonOrderItemMappingList;
