import React from 'react';
import { Link } from '@material-ui/core';
import type { LinkProps } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { HeaderBody, HeaderDivider, HeaderWrap, PageTitle } from '../shared/wrappers';

export const TripReportFormHeader: React.FC = () => {
  const store = useTripReportStore();
  const { step, selected } = store;

  return (
    <HeaderWrap>
      <HeaderBody>
        {step === 'tripReport' ? (
          <PageTitle>出張の報告申請をする</PageTitle>
        ) : step === 'tripSelect' ? (
          <>
            <PageTitle>報告する出張を選択</PageTitle>
            <BackLink color="primary" onClick={() => store.setStep('tripReport')}>
              {'<前の画面に戻る'}
            </BackLink>
          </>
        ) : step === 'tripSelected' && selected ? (
          <>
            <PageTitle>出張の詳細を登録</PageTitle>
            <BackLink color="primary" onClick={() => store.setStep('tripSelect')}>
              {'<旅程選択の一覧に戻る'}
            </BackLink>
          </>
        ) : step === 'tripSelected' ? (
          <>
            <PageTitle>出張の詳細を更新</PageTitle>
            <SelectedPageBackLink color="primary">{'<前の画面に戻る'}</SelectedPageBackLink>
          </>
        ) : step === 'confirm' ? (
          <>
            <PageTitle>報告する内容を確認</PageTitle>
            <BackLink color="primary" onClick={() => store.setStep('tripReport')}>
              {'<前の画面に戻る'}
            </BackLink>
          </>
        ) : (
          <></>
        )}
        <HeaderDivider />
      </HeaderBody>
    </HeaderWrap>
  );
};

const SelectedPageBackLink: React.FC<Omit<LinkProps, 'onClick'>> = props => {
  const store = useTripReportStore();

  return (
    <BackLink
      {...props}
      onClick={() => {
        store.nonOrderItems
          .filter(item => item.adding || item.editing)
          .forEach(item =>
            item.adding ? store.removeNonOrderItem(item) : item.setEditing(false, { cancel: true })
          );
        store.setStep('tripReport');
      }}
    />
  );
};

const BackLink = styled(Link)`
  margin-bottom: 4px;
`;
