import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { css } from 'styled-components';

export type FilterableSelectorOption = {
  label?: string;
  labelLines?: string[];
  value: string;
  matcher: string;
};

interface Props {
  placeholder: string;
  options: FilterableSelectorOption[];
  selected: FilterableSelectorOption | null;
  onSelect: (option: FilterableSelectorOption | null) => void;
  onChange?: (query: string) => void;
  isRequired?: boolean;
  workflowFormWidth: string;
}

interface State {
  isOpen: boolean;
  query: string;
}

interface HTMLElementEvent<T extends EventTarget> extends Event {
  target: T;
}

class FilterableSelector extends React.Component<Props, State> {
  private selectAreaRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      query: ''
    };
  }

  componentDidMount() {
    document.addEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  handleClickEvent(e: HTMLElementEvent<HTMLElement>) {
    // ref内にクリックされたeventのDOMが含まれているかを確認する
    if (this.selectAreaRef && this.selectAreaRef.current && !this.selectAreaRef.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  getFilteredOptions() {
    return this.props.options.filter(o => {
      const matcher = o.matcher.toUpperCase();
      const query = this.state.query.toUpperCase();
      return matcher.indexOf(query) > -1;
    });
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.isOpen) {
        const el = document.getElementById('filterable-selector-input');
        if (el) {
          el.focus();
        }
      }
    });
  }

  handleQueryChange(query: string) {
    this.setState({ query });
    if (this.props.onChange) {
      this.props.onChange(query);
    }
  }

  handleSelect(o: FilterableSelectorOption | null) {
    this.setState({ isOpen: false });
    this.props.onSelect(o);
  }

  render() {
    const { placeholder, selected, isRequired, workflowFormWidth } = this.props;
    const { isOpen, query } = this.state;
    const options = this.getFilteredOptions();
    const unselectedLabel = _.isNil(isRequired)
      ? '選択してください'
      : isRequired
      ? '(必須)選択してください'
      : '(任意)選択してください';
    return (
      <Wrapper ref={this.selectAreaRef}>
        <OptionBoxDiv data-testid="fs_open_box" onClick={() => this.toggleOpen()}>
          <div>{selected && selected.label !== '' ? selected.label : unselectedLabel}</div>
          <Triangle>{isOpen ? '▲' : '▼'}</Triangle>
        </OptionBoxDiv>
        {isOpen && (
          <FilterableSelectorItemList>
            <InputArea>
              <Input
                type="text"
                id="filterable-selector-input"
                placeholder={placeholder}
                value={query}
                onChange={e => this.handleQueryChange(e.target.value)}
              />
            </InputArea>
            <FilterableSelectorUl workflowFormWidth={workflowFormWidth}>
              <OptionBoxLi onClick={() => this.handleSelect(null)}>選択を外す</OptionBoxLi>
              {options.map(o => (
                <OptionBoxLi data-wovn-ignore key={o.value} onClick={() => this.handleSelect(o)}>
                  {o.label}
                  {o.labelLines?.map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </OptionBoxLi>
              ))}
            </FilterableSelectorUl>
          </FilterableSelectorItemList>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  font-size: 13px;
  margin-bottom: 5px;
  line-height: normal;
`;

const OptionBoxStyle = css`
  display: flex;
  border: 2px solid ${props => props.theme.grayBorderColor};
  padding: 2px 10px;
  cursor: pointer;
  line-height: normal;
`;

const OptionBoxDiv = styled.div`
  ${OptionBoxStyle};
`;

const OptionBoxLi = styled.li`
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  width: 100%;
  line-height: 30px;
  padding:  0 10px
  cursor: pointer;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 2px solid ${props => props.theme.grayBorderColor};
  border-top: none;
  z-index: 100;
`;

const Triangle = styled.div`
  margin-left: auto;
`;

const FilterableSelectorItemList = styled.div`
  margin: 0;
  background: ${props => props.theme.fieldBgColor};
`;

const InputArea = styled.div`
  margin-top: 5px;
`;

const Input = styled.input`
  margin-bottom: 0;
`;

const FilterableSelectorUl = styled.ul<{ workflowFormWidth?: string }>`
  overflow-y: scroll;
  max-height: 150px;
  width: ${props => props.workflowFormWidth || '420px'};
  border-bottom: 2px solid ${props => props.theme.grayBorderColor};
`;

export default FilterableSelector;
