import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import jsonPromiseContext from '@this/src/components/constants/json_promise_context';
import { styled } from '@this/constants/themes';
import PurchaseLeadtimeTooltip from '@this/components/organization/dashboard/sections/purchase_leadtime_tooltip/purchase_leadtime_tooltip';
import type { DashboardProps } from '../../share';
import type { BarLeadtime } from '../../share/purchase_leadtime_graph';
import { chartOptions, getBarDataLeadtimes } from '../../share/purchase_leadtime_graph';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import type { DepartmentSelectProps } from '../../share/department_select/department_select';
import DepartmentSelect from '../../share/department_select/department_select';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// 購買リードタイムセクションV2：リードタイムの集計軸毎の旅程の比率を比較
// Usecase::Organization::DashboardTrips::PurchaseLeadtimePatterns::CachePatternUsecase を使用
const USECASE = { section: 'purchase_lead_time', pattern: 'cache' };

type Props = DashboardProps & DepartmentSelectProps;

interface State {
  loading: boolean;
  leadtimes: BarLeadtime[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  leadtimes: State['leadtimes'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    leadtimes: result.leadtimes,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardPurchaseLeadtimeSection: React.FC<Props> = ({ departmentId, onSelect, ...props }) => {
  const jsonPromise = useContext(jsonPromiseContext);

  const [state, setState] = useState<State>({
    loading: true,
    leadtimes: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, leadtimes, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to, purchaseDepartmentId: did } = urlQuery;

  const { data, options } = useMemo(() => getBarDataLeadtimes(leadtimes), [leadtimes]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    if (props.wait) return;

    fetchSummary({ setState, jsonPromise, ...props });
  }, [type, from, to, did, refreshAt, props.refreshAt, props.wait]);

  return (
    <>
      <Wrap className="graph_title">
        <span>購買リードタイム</span>
        <PurchaseLeadtimeTooltip />
      </Wrap>
      <DepartmentSelect departmentId={departmentId} onSelect={onSelect} />
      {loading ? (
        <SimpleLoading />
      ) : (
        <Bar data={data} width={550} height={180} options={{ ...chartOptions, ...options }} />
      )}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

const Wrap = styled.h3`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export default DashboardPurchaseLeadtimeSection;
