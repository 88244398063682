import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import { Box, Divider } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/components/shared/ui/data_displays/table';
import type { CsvImportActionArgs } from '@this/domain/csv_import_action/csv_import_action';
import CsvImportAction from '@this/domain/csv_import_action/csv_import_action';
import { Fetcher } from '@this/src/util';

interface IndexResponse {
  actions: CsvImportActionArgs[];
}

const YamatoCsvImportActions = observer(() => {
  const [csv, setCsv] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [actions, setActions] = useState<CsvImportAction[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);

  const fetchActions = async () => {
    setFetching(true);
    setFetchError(null);
    try {
      const res = await Fetcher.get<IndexResponse>('/arrangement/yamato_csv_import_actions.json');
      const as = res.actions.map(a => new CsvImportAction(a));
      setActions(as);
    } catch {
      setFetchError('エラーが発生しました');
    } finally {
      setFetching(false);
    }
  };

  const handleUpload = async () => {
    if (!csv) {
      return;
    }
    const data = new FormData();
    data.append('file', csv);
    setUploading(true);
    setUploadError(null);
    try {
      await Fetcher.upload('/arrangement/yamato_csv_import_actions', data);
      setCsv(null);
      fetchActions();
    } catch (e) {
      setUploadError('エラーが発生しました。');
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    fetchActions();
  }, []);

  return (
    <Wrapper>
      <Box marginBottom="20px">
        <h3>ヤマト配送料金CSVファイルアップロード</h3>
        <Dropzone
          onDrop={(files: File[]) => setCsv(files[0])}
          accept=".csv"
          style={{
            width: '400px',
            height: '100px',
            background: '#eee',
            border: '2px dotted #999',
            textAlign: 'center',
            padding: '20px'
          }}
        >
          <p>
            ここへヤマト配送料金CSVファイルをドラッグ&amp;ドロップ
            <br />
            もしくは
            <br />
            クリックしてCSVファイルを指定
          </p>
        </Dropzone>
        {csv && (
          <>
            <Box>{csv?.name}</Box>
            {uploading ? (
              <Box width="150px">
                <Loading />
              </Box>
            ) : (
              <Button onClick={handleUpload} style={{ marginTop: '10px', width: '150px' }}>
                アップロード
              </Button>
            )}
          </>
        )}
        {uploadError && <Box color="red">{uploadError}</Box>}
      </Box>
      <HistoryTitleWrapper>
        <h3>アップロード履歴</h3>
        <p style={{ color: '#aaa' }}>合計：{actions.length}件</p>
      </HistoryTitleWrapper>
      <Divider />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ファイル名</TableCell>
            <TableCell>アップロード日時</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>詳細</TableCell>
          </TableRow>
        </TableHead>
        {fetching ? (
          <Loading />
        ) : (
          <TableBody>
            {actions.map(a => (
              <TableRow key={a.id} hover>
                <TableCell>{a.filename}</TableCell>
                <TableCell>{a.createdAt.format('YYYY-MM-DD HH:mm')}</TableCell>
                <TableCell>{a.statusMessage}</TableCell>
                <TableCell>
                  {a.status === 'success' && (
                    <Link to={`/arrangement/yamato_csv_import_actions/${a.id}`}>詳細</Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {fetchError && <Box color="red">{fetchError}</Box>}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
  font-size: 12px;
`;

const HistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default YamatoCsvImportActions;
