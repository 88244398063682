import React from 'react';
import _ from 'lodash';
import moment from '@this/lib/moment';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';

import Markdown from '@this/shared/markdown/markdown';
import type { SelectDefaultAreaProps, SelectDefaultAreaState, Tab } from './default_area';

interface Props {
  handleTabClick: (value: Tab) => () => void;
}

const SelectDefaultAreaTemplate: React.SFC<SelectDefaultAreaProps & SelectDefaultAreaState & Props> = ({
  // DefaultAreaProps
  show,
  currentTab,
  // DefaultAreaState
  informations,
  manuals,
  faqs,
  serviceId,
  isOrganization,
  sp,
  // Props
  handleTabClick
}) => {
  const mynavi_movie_height = !sp && serviceId === 3 ? ((window.innerWidth - 20) / 32) * 9 : 0;
  const information_height = window.innerHeight - 335 - mynavi_movie_height;
  const is_allow_scroll = !sp && serviceId !== 3;

  return (
    <DefaultArea show={show && (!sp || informations.length > 0)} sp={sp}>
      <Wrap>
        {!sp && serviceId === 3 && (
          <>
            <Title>検索結果画面の使い方</Title>
            <Video src="https://d14vxaqxdw6ji3.cloudfront.net/MynaviMovieManual.mp4" controls />
          </>
        )}
        <Content>
          <GuideTabs>
            {informations.length > 0 && (
              <GuideTab selected={currentTab === 'information'} onClick={handleTabClick('information')}>
                お知らせ
              </GuideTab>
            )}
            {!sp && isOrganization && (
              <GuideTab selected={currentTab === 'guide'} onClick={handleTabClick('guide')}>
                Guide
              </GuideTab>
            )}
            {!sp && serviceId === 1 && (
              <GuideTab selected={currentTab === 'faq'} onClick={handleTabClick('faq')}>
                FAQ
              </GuideTab>
            )}
            <GuideTabSpacer selected={false}>{'　'}</GuideTabSpacer>
          </GuideTabs>
          <InformationsTableWrapper allow_scroll={is_allow_scroll} height={information_height}>
            {currentTab === 'information' ? (
              <InformationsTable>
                <tbody data-wovn-ignore>
                  {informations
                    .sort(
                      (info1, info2) => new Date(info2.updated_at).getTime() - new Date(info1.updated_at).getTime()
                    )
                    .map((info, i) => (
                      <tr key={i}>
                        <InformationCreatedAtTd>
                          {moment(info.updated_at).format('YYYY-MM-DD')}
                        </InformationCreatedAtTd>
                        <InformationContentTd data-wovn-ignore>
                          <Markdown markdownText={info.content} />
                        </InformationContentTd>
                      </tr>
                    ))}
                </tbody>
              </InformationsTable>
            ) : currentTab === 'guide' ? (
              isOrganization && (
                <ul>
                  {manuals.map((m, i) => (
                    <li key={i}>
                      <A href={m.file_url} target="_blank" rel="noreferrer noopener">
                        {m.name}
                      </A>
                    </li>
                  ))}
                </ul>
              )
            ) : currentTab === 'faq' ? (
              <ul>
                {faqs.map((m, i) => (
                  <li key={i}>
                    <A href={m.url} target="_blank">
                      {m.name}
                    </A>
                  </li>
                ))}
              </ul>
            ) : null}
          </InformationsTableWrapper>
        </Content>
      </Wrap>
    </DefaultArea>
  );
};

const DefaultArea = styled.div<{ show: boolean; sp: boolean }>`
  flex-grow: 99999;
  display: flex;
  flex-direction: column;
  min-height: 0%;
  background: ${props => props.theme.accentColorLight};
  padding: ${props => (props.sp ? '10px 0px' : '40px')};

  ${props =>
    !props.show &&
    `
      display: none;
  `};
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 9999;
  background: white;
  min-height: 0%;
  padding-top: 15px;
`;

const Title = styled.h2`
  width: 100%;
  margin: 0 auto 10px;
  font-size: 15px;
  text-align: center;
`;

const Video = styled.video`
  min-height: 0%;
  width: 100%;
`;

const Content = styled.div`
  padding: 10px;
`;

const GuideTabs = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const GuideTab = styled.div<{ selected: boolean }>`
  border-bottom: 3px solid ${props => props.theme.grayBorderColor};
  text-align: center;
  font-size: 11px;
  line-height: 11px;
  font-weight: bold;
  padding-bottom: 5px;
  cursor: pointer;
  color: ${props => props.theme.linkColor};
  flex-basis: 60px;

  ${props =>
    props.selected &&
    `
    border-color: ${props.theme.linkColor};
    cursor: default;
    color: ${props.theme.textColor};
    font-size: 13px;
    line-height: 13px;
  `};
`;

const GuideTabSpacer = styled(GuideTab)`
  flex-grow: 99999;
  cursor: default;
`;

const InformationCreatedAtTd = styled.td`
  width: 95px;
  vertical-align: top;
  padding: 8px;
  font-weight: bold;
`;

const InformationContentTd = styled.td`
  padding: 8px;
`;

const InformationsTableWrapper = styled.div<{ allow_scroll: boolean; height: number }>`
  ${props =>
    props.allow_scroll &&
    `
    height: ${props.height}px;
    overflow-y: scroll;
  `};
`;

const InformationsTable = styled.table``;

export default SelectDefaultAreaTemplate;
