import type { ChangeEvent, MouseEvent } from 'react';
import React from 'react';
import type { SimulationData } from '@this/components/lp/simulation/simulation';

interface Props {
  simulationData: SimulationData;
  showResult: boolean;
  howList: string[];
  adjustList: string[];
  handleChange: (key: keyof SimulationData, e: ChangeEvent<HTMLSelectElement>) => void;
  handleTermChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSimulateButtonClick: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const SimulationSp: React.FC<Props> = ({
  simulationData,
  showResult,
  howList,
  adjustList,
  handleChange,
  handleTermChange,
  handleSimulateButtonClick
}) => {
  return (
    <>
      <section className="key_visual">
        <div className="cell">
          <div className="key_catch">
            出張手配の
            <span className="company_color">手間</span>と<span className="company_color">コスト</span>
            <br />
            AI Travel が削減します
          </div>
        </div>
        <div className="functions flex">
          <div className="function flex">
            <div className="content">
              <div className="title">検索 &amp; 予約</div>
              <div className="strong">最短5分</div>
            </div>
          </div>
          <img src="/images/biz_lp/triangle.png" alt="triangle" width="16" height="16" />
          <div className="function flex">
            <div className="content">
              <div className="title">一括精算で</div>
              <div className="strong">立替解消</div>
            </div>
          </div>
          <img src="/images/biz_lp/triangle.png" alt="triangle" width="16" height="16" />
          <div className="function flex">
            <div className="content">
              <div className="title">ロスを防ぐ</div>
              <div className="strong">申請機能</div>
            </div>
          </div>
          <img src="/images/biz_lp/triangle.png" alt="triangle" width="16" height="16" />
          <div className="function flex">
            <div className="content">
              <div className="title">分析機能で</div>
              <div className="strong">見える化</div>
            </div>
          </div>
          <img src="/images/biz_lp/triangle.png" alt="triangle" width="16" height="16" />
          <div className="function flex">
            <div className="content">
              <div className="title">コストを</div>
              <div className="strong">最適化</div>
            </div>
          </div>
        </div>
        <a href="https://aitravel.cloud/download/" className="button demo flex">
          資料請求はこちら
        </a>
        <div className="img flex">
          <img src="/images/biz_lp/service01.png" alt="" width="580" />
        </div>
      </section>
      <section className="simulation">
        <div className="container">
          <div className="simulator">
            <div className="head">
              コスト分析シミュレーター
              <span className="ver">βver.</span>
              <div className="text">
                現在の出張情報から、
                <span>AI Travel</span>
                活用によりコストと手間の削減が導けます。
              </div>
            </div>
            <div className="content">
              <div className="inputs flex">
                <div className="input flex">
                  <div className="title">
                    出張経費
                    <br />
                    <span className="small">(1ヶ月あたり)</span>
                  </div>
                  <div className="content flex">
                    <p>
                      <div className="num">{simulationData.cost}</div>
                      <span className="unit">万円</span>
                    </p>
                    <div className="bg" />
                    <select name="cost" value={simulationData.cost} onChange={e => handleChange('cost', e)}>
                      {Array(10)
                        .fill(10)
                        .map((ele, i) => ele * (i + 1))
                        .map(cost => (
                          <option value={cost} key={cost}>
                            {cost}万円
                          </option>
                        ))}
                      {Array(18)
                        .fill(100)
                        .map((ele, i) => ele + (i + 1) * 50)
                        .map(cost => (
                          <option value={cost} key={cost}>
                            {cost}万円
                          </option>
                        ))}
                      <option value={1000}>1000万円以上</option>
                    </select>
                    <img src="/images/biz_lp/triangle.png" alt="" width="16" height="16" />
                  </div>
                </div>
                <div className="input flex">
                  <div className="title">出張回数</div>
                  <div className="content flex">
                    <p>
                      <div className="num">{simulationData.count}</div>
                      <span className="unit">回</span>
                    </p>
                    <div className="bg" />
                    <select name="count" value={simulationData.count} onChange={e => handleChange('count', e)}>
                      {Array(10)
                        .fill(10)
                        .map((ele, i) => ele * (i + 1))
                        .map(count => (
                          <option value={count} key={count}>
                            {count}回
                          </option>
                        ))}
                      {Array(18)
                        .fill(100)
                        .map((ele, i) => ele + (i + 1) * 50)
                        .map(count => (
                          <option value={count} key={count}>
                            {count}回
                          </option>
                        ))}
                      <option value={1000}>1000回以上</option>
                    </select>
                    <img src="/images/biz_lp/triangle.png" alt="" width="16" height="16" />
                  </div>
                </div>

                <div className="input flex">
                  <div className="title">出張の予約方法</div>
                  <div className="content flex">
                    <p>
                      <div className="text">{howList[simulationData.how]}</div>
                    </p>
                    <div className="bg" />
                    <select name="how" value={simulationData.how} onChange={e => handleChange('how', e)}>
                      <option value={0}>従業員が自分で予約</option>
                      <option value={1}>アシスタントが手配</option>
                      <option value={2}>旅行代理店を利用</option>
                      <option value={3}>出張専用のサービスを利用</option>
                    </select>
                    <img src="/images/biz_lp/triangle.png" alt="" width="16" height="16" />
                  </div>
                </div>

                <div className="input flex">
                  <div className="title">経費精算の方法</div>
                  <div className="content flex">
                    <p>
                      <div className="text">{adjustList[simulationData.adjust]}</div>
                    </p>
                    <div className="bg" />
                    <select name="adjust" value={simulationData.adjust} onChange={e => handleChange('adjust', e)}>
                      <option value={0}>紙で経費精算&amp;申請</option>
                      <option value={1}>経費精算ソフトを利用</option>
                      <option value={2}>出張専用のサービスを利用</option>
                    </select>
                    <img src="/images/biz_lp/triangle.png" alt="" width="16" height="16" />
                  </div>
                </div>
                <a id="calc" className="button calc" href="#" onClick={handleSimulateButtonClick}>
                  結果を確認
                </a>
              </div>
            </div>
          </div>

          {/* シミュレーション結果 */}
          <div className="result_container" style={showResult ? { display: 'block' } : { display: 'none' }}>
            <div className="row flex">
              <div className="title">
                <div className="main">シミュレーション結果</div>
                <div className="sub">Cost Simulation Report</div>
              </div>
              <div className="toggle right">
                <input
                  type="radio"
                  name="termSp"
                  id="yearSp"
                  value={12}
                  checked={simulationData.term === '12'}
                  onChange={handleTermChange}
                />
                <label htmlFor="yearSp">年</label>
                <input
                  type="radio"
                  name="termSp"
                  id="monthSp"
                  value={1}
                  checked={simulationData.term === '1'}
                  onChange={handleTermChange}
                />
                <label htmlFor="monthSp">月</label>
              </div>
            </div>
            <div className="row">
              <div className="result flex">
                <div className="head">
                  Total
                  <span className="caution">(目安)</span>
                </div>
                <div className="container flex">
                  <div className="amount">{simulationData.amount}</div>
                  <span className="unit">万円</span>
                </div>
                <div className="text">削減できます</div>
              </div>
              <div className="detail flex">
                <div className="content">
                  <div className="title">
                    内訳
                    <span className="caution">(年間・目安)</span>
                  </div>
                  <div className="items flex">
                    <div className="direct">
                      <span className="name">直接コスト</span>：
                      <span className="number">{simulationData.directCost}</span>
                      <span className="unit">万円</span>
                      の削減!
                    </div>
                    <div className="indirect">
                      <span className="name">間接コスト</span>：
                      <span className="number">{simulationData.indirectCost}</span>
                      <span className="unit">万円</span>
                      の削減!
                    </div>
                    <div className="time">
                      <span className="name">削減時間</span>：<span className="number">{simulationData.time}</span>
                      <span className="unit">時間</span>
                      の削減！
                    </div>
                    <div className="efficiency">
                      <span className="name">業務効率</span>：
                      <span className="number">{simulationData.efficiency}</span>
                      <span className="unit">%</span>
                      の削減！
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <a href="https://aitravel.cloud/download/" className="inquiry flex">
                <img src="/images/biz_lp/mail_white.png" alt="" className="icon" />
                <div className="text">詳しくは資料請求</div>
              </a>
            </div>
            <div className="row">
              <div className="explanation">
                ※ 直接コストの削減効果は、一般的な予約サービス利用時とAI
                Travel独自の仕入れ価格との比較により計算しています。
              </div>
              <div className="explanation">
                ※ 業務効率の改善効果は、一般的な予約サービス利用時とAI Travel利用時との比較により試算しています。
              </div>
              <div className="explanation">
                ※
                間接コストの削減効果は、オペレーションコスト（人件費およびオフィス賃料や管理費など）を1,500円/時で試算しています。
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="spec">
        <h2>AI Travelの特徴</h2>
        <div className="features">
          <div className="feature">
            <div className="text">
              <h2>驚くほどスムーズな検索機能</h2>
              <span>
                AI
                Travelなら、従来のように複数のサービスで調べて比較したり、同じような情報を何度も入力する必要がありません。出張の旅程を入力するだけで、出張に適したホテル・飛行機・新幹線が表示され、まとめて予約することができます。また、従業員の立替負担も解消し、ワークフロー機能も手間なくスムーズにご利用いただけます。
              </span>
            </div>
            <img src="/images/biz_lp/feature01.png" alt="feature01" />
          </div>
          <div className="feature">
            <div className="text">
              <h2>簡単なのに高性能な管理画面</h2>
              <span>
                AI
                Travelなら、従来の方法と比べて圧倒的な低コストで出張情報を一元管理することができます。さらに、これまで難しかったコスト高騰要因の分析も可能です。専門のコンサルタントがデータを活用した改善策の立案や、出張コストの適正化までをサポートし、利益体質を強化する攻め攻めの経理を実現します。
              </span>
            </div>
            <img src="/images/biz_lp/feature02.png" alt="feature02" />
          </div>
        </div>
      </section>
      <section className="user_voice">
        <h2>お客様の声</h2>
        <div className="container">
          <div className="voice">
            <div className="title">経営者の方</div>
            <div className="content">
              <ul>
                <li>最適化により出張コストを削減できた</li>
                <li>可視化で営業活動の適正化ができた</li>
                <li>出張の承認作業が楽になった</li>
              </ul>
            </div>
          </div>
          <div className="voice">
            <div className="title">総務・経理の方</div>
            <div className="content">
              <ul>
                <li>出張手配の依頼を受けやすくなった</li>
                <li>一括精算で経理処理がシンプルになった</li>
                <li>出張関連業務の手間を大幅に削減できた</li>
              </ul>
            </div>
          </div>
          <div className="voice">
            <div className="title">出張者の方</div>
            <div className="content">
              <ul>
                <li>短時間で予約できるようになった</li>
                <li>面倒な出張申請がスムーズになった</li>
                <li>立替の負担が減ったしマイルも貯まる</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="inquiry">
        <h2>AI Travelで出張の予約・管理をシンプルに</h2>
        <h3>サービスの詳細は気軽にお問い合わせください</h3>
        <div className="container">
          <div className="tel">
            <div className="tel_number">
              <a href="tel:0368433297">03-6843-3297</a>
            </div>
            <div className="note">10:00 ~ 18:00（土日祝日除く）</div>
          </div>
          <div className="inquiries">
            <a href="https://aitravel.cloud/download/" className="button inquiry">
              資料請求
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default SimulationSp;
