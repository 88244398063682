import { observable, computed } from 'mobx';

export type BulkTicketRouteArgs = {
  id: number;
  from: string;
  to: string;
};

export default class BulkTicketRoute {
  @observable
  id: number;

  @observable
  from: string;

  @observable
  to: string;

  constructor(args: BulkTicketRouteArgs) {
    this.id = args.id;
    this.from = args.from;
    this.to = args.to;
  }

  @computed
  get description() {
    return `${this.from} → ${this.to}`;
  }

  submitParams() {
    return {
      from: this.from,
      to: this.to
    };
  }
}
