import type User from '@this/src/domain/user/user';
import React from 'react';

interface Context {
  serviceId: number;
  env: string;
  user: User | null;
}

const CoreAppContext = React.createContext<Context>({ serviceId: 1, env: '', user: null });
export default CoreAppContext;
