import { observable, computed } from 'mobx';
import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';
import type { OptionPlanArgs } from './option_plan';
import OptionPlan from './option_plan';

export interface OptionPlanPriceArgs {
  id?: number;
  organization_id?: number;
  price?: number;
  tax_type_id?: number;
  from_time?: string;
  to_time?: string;
  billing_target?: string;
  option_plan: OptionPlanArgs;
  every_year: boolean;
}

class OptionPlanPrice {
  @observable
  id: number | undefined | null;

  @observable
  organizationId: number | undefined;

  @observable
  price: number | undefined;

  @observable
  taxTypeId: number | undefined;

  @observable
  fromTime: Moment;

  @observable
  toTime: Moment;

  @observable
  billingTarget: Moment;

  @observable
  everyYear: boolean;

  @observable
  optionPlan: OptionPlan;

  constructor(args: OptionPlanPriceArgs) {
    this.id = args.id;
    this.organizationId = args.organization_id;
    this.price = args.price;
    this.taxTypeId = args.tax_type_id;
    this.fromTime = moment(args.from_time);
    this.toTime = moment(args.to_time);
    this.billingTarget = moment(args.billing_target);
    this.everyYear = args.every_year;
    this.optionPlan = new OptionPlan(args.option_plan);
  }

  @computed
  get monthCount(): number {
    return this.toTime.diff(this.fromTime, 'months') + 1;
  }

  @computed
  get pricePerMonth(): number {
    return Math.floor((this.price || 0) / this.monthCount);
  }

  @computed
  get billingMonth(): number {
    const nextMonth = this.billingTarget.clone();
    return nextMonth.add(1, 'month').month() + 1;
  }

  @computed
  get issueTarget(): Moment {
    return this.billingTarget.clone().add(1, 'month');
  }

  @computed
  get termDesc(): string {
    return `${this.fromTime.format('YYYYMM')} 〜 ${this.toTime.format('YYYYMM')}`;
  }

  @computed
  get billingMonthDesc(): string {
    return `${this.billingMonth}月請求`;
  }

  @computed
  get issueTargetDesc(): string {
    return this.everyYear ? `毎年${this.issueTarget.month() + 1}月請求` : this.issueTarget.format('YYYY年MM月');
  }

  @computed
  get summary() {
    return `対象月: ${this.termDesc} オプション: ${this.optionPlan.name} 料金(税抜き): ${this.price} 請求対象: ${this.billingMonthDesc}`;
  }

  submitParams() {
    return {
      price: this.price,
      tax_type_id: this.taxTypeId,
      from_time: this.fromTime.format('YYYY-MM-DD'),
      to_time: this.toTime.format('YYYY-MM-DD'),
      billing_target: this.billingTarget.format('YYYY-MM-DD'),
      every_year: this.everyYear,
      option_plan_id: this.optionPlan.id
    };
  }
}
export default OptionPlanPrice;
