export const Status = {
  login_only: 'ログイン時のみ',
  public: '公開'
} as const;

export type StatusKey = keyof typeof Status;
export const StatusKeys: StatusKey[] = ['login_only', 'public'];

export interface KnowledgeImageArg {
  id: number;

  name: string;
  status: StatusKey;
  share_url: string; // 共有用url
  file_url: string; // s3のurl
}

export default class KnowledgeImage {
  id: number;

  name: string;

  status: StatusKey;

  shareUrl: string;

  s3Url: string;

  constructor(args: KnowledgeImageArg) {
    this.id = args.id;
    this.name = args.name;
    this.status = args.status;
    this.shareUrl = args.share_url;
    this.s3Url = args.file_url;
  }
}
