import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  data: any;
  customWidth?: string;
  note?: string | null | undefined;
  customFontSize?: string;
  isBold?: boolean;
}

const SubInfo: React.FC<Props> = ({ data, customWidth, note, customFontSize, isBold }) => {
  return (
    <Section>
      {(Object.keys(data) as any[]).flatMap(key => (
        <SectionRow key={key} customFontSize={customFontSize} isBold={isBold}>
          <Label customWidth={customWidth}>{key}</Label>
          <Value>：{data[key]}</Value>
        </SectionRow>
      ))}
      {note && <div>{note}</div>}
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionRow = styled.div<{ customFontSize: string | undefined; isBold: boolean | undefined }>`
  display: flex;
  width: 100%;
  font-size: ${props => (props.customFontSize ? props.customFontSize : `14px`)};
  font-weight: ${props => (props.isBold ? `bold` : `normal`)}; ;
`;

const Label = styled.div<{ customWidth: string | undefined }>`
  width: ${props => (props.customWidth ? props.customWidth : `100px`)};
`;

const Value = styled.div`
  flex: 1;
  padding-right: 2px;
`;

export default SubInfo;
