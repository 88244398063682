/* eslint-disable max-lines */
// 航空券・ホテルそれぞれ個別の検索クエリー。
// プロパティーは航空券・ホテルが混在している。
// 個別・パッケージ用のパラメーターオブジェクトを取得できる。
import _ from 'lodash';
import type { Moment } from 'moment';

import type { PlaceItemType, PlaceOptions } from '@this/domain/place/place.repository';
import PlaceRepository from '@this/domain/place/place.repository';
import type Place from '@this/domain/place/place';
import type { TransitSearchApi } from '@this/domain/organization/organization2';
import { Fetcher, trackEvent } from '@this/src/util';
import moment from '../lib/moment';

import type SearchResultItem from './search_result_item';
import type { TransitResponse, HotelsResponse } from './select_repository';
import HotelList from './hotel/hotel_list';
import type { AirportSet } from './flight/flight_list';
import type TextToQueryResultItem from './chatbot/text_to_query_result_item';

type AiHotelQuality = 'good' | 'bad' | 'not_rated';

// getParams()からはすべてstringで渡される
interface Args {
  id?: number;
  searchQueryId?: number;
  item_type: 'transport' | 'hotel' | 'rentalCar';
  pair?: 'outward' | 'homeward' | undefined;

  // 出発地
  origin?: string;
  origin_address?: string;
  // 目的地 or 宿泊地
  destination?: string;
  destination_address?: string;
  // 第二目的地
  secondary?: string;
  secondary_address?: string;
  // 都道府県
  prefecture_id?: number | null;
  municipality_code?: string | null;

  // 交通機関日時 or チェックイン
  outdate?: string;
  outhour?: string;
  outmin?: string;
  outtype?: 'arrival';
  // チェックアウト
  homedate?: string;
  homehour?: string;
  homemin?: string;
  hometype?: 'arrival';

  smoke?: string;
  breakfast?: string;
  roomnum?: string | number;

  peoplenum: string | number;

  index: string | number;
  defaultIndex?: number;

  type?: 'separate' | 'airPackage' | undefined;
  selected?: string;
  changeable_air?: 'true' | 'false';
  carrier_id?: string;

  carType?: [string, string][] | null;
  carNumber?: number;

  // 航空便の番号。飛行機パッケージの楽天パッケージの時だけ使用。
  // [経由便対応] 便名が複数ある場合は全行程の便名をカンマ区切りでつなぐ
  // @example "JAL147,JTA012"
  flightNum?: string | undefined;

  hotelName?: string;

  input?: boolean;

  ai_hotel_recommendation?: string;
  ai_hotel_recommendation_quality?: AiHotelQuality;
  ai_hotel_recommendation_reason?: string;
  ai_hotel_ranking?: string;
  ai_hotel_ranking_quality?: AiHotelQuality;
  ai_hotel_ranking_reason?: string;
}

interface Errors {
  origin?: string;
  destination?: string;
  stay?: string;
}

interface ScoreResponse {
  selected_reason: string;
  score_reason: string;
}

class SearchQueryItem {
  public searchQueryId: number | undefined;

  // TODO: type定義
  public itemType: 'transport' | 'hotel' | 'rentalCar' | undefined;

  public pair: 'outward' | 'homeward' | undefined;

  // 出発地
  public origin: string;

  public originAddress: string;

  // 目的地 or 宿泊地
  public destination: string;

  public destinationAddress: string;

  // 第二目的地
  public secondary: string | null;

  public secondaryAddress: string | null;

  // 都道府県（HotelsSearchで追加される）
  public prefectureId: number | null = null;

  public municipalityCode: string | null = null;

  // 交通機関日時 or チェックイン
  public outdate: string;

  public outhour: string;

  public outmin: string;

  public outtype: 'arrival' | 'departure';

  // チェックアウト
  public homedate: string;

  public homehour: string;

  public homemin: string;

  private hometype: 'arrival';

  private smoke: string;

  private breakfast: string;

  private roomnum: number;

  public peoplenum: number;

  public id: number | undefined;

  public orgGeocode: Place | null;

  public destGeocode: Place | null;

  public secGeocode: Place | null;

  public domestic: boolean;

  private index: number;

  public type: string | undefined;

  public selectedElement: string | undefined;

  public resultItem!: SearchResultItem;

  public carrierIds: string[];

  public airports: AirportSet | undefined;

  public originCode: string | undefined;

  public destCode: string | undefined;

  public returnPlace: 'current' | 'other';

  public carTypeOptions: [string, string][];

  public carType: string;

  public carTypeOther: string;

  public carNumber: number;

  public flightNum: string | undefined;

  public hotelName?: string;

  public input?: boolean;

  public changeablePrices: string[];

  public filteredUpgradeSeat: boolean;

  public showConnectingAir: boolean;

  public aiHotelRecommendation?: string;

  public aiHotelRecommendationQuality: AiHotelQuality = 'not_rated';

  public aiHotelRecommendationReason?: string;

  public aiHotelRanking?: string;

  public aiHotelRankingQuality: AiHotelQuality = 'not_rated';

  public aiHotelRankingReason?: string;

  constructor(args: Args) {
    this.id = args.id || undefined;
    this.searchQueryId = args.searchQueryId;
    this.itemType = args.item_type; // transport or hotel
    this.pair = args.pair;

    // 出発地
    this.origin = args.origin || '';
    this.originAddress = args.origin_address || '';
    // 目的地 or 宿泊地
    this.destination = args.destination || '';
    this.destinationAddress = args.destination_address || '';
    // 第二目的地
    this.secondary = args.secondary || null;
    this.secondaryAddress = args.secondary_address || null;
    // 都道府県
    this.prefectureId = args.prefecture_id ?? null;
    this.municipalityCode = args.municipality_code ?? null;

    // 交通機関日時 or チェックイン
    this.outdate = args.outdate || moment().add(1, 'days').format('YYYY-MM-DD');
    this.outhour = args.outhour || (args.item_type === 'transport' ? '12' : '');
    this.outmin = args.outmin || (args.item_type === 'transport' ? '00' : '');
    this.outtype = args.outtype || 'arrival';
    // チェックアウト
    this.homedate = args.homedate || moment().add(2, 'days').format('YYYY-MM-DD');
    this.homehour = args.homehour || '';
    this.homemin = args.homemin || '';
    this.hometype = args.hometype || 'arrival';
    this.smoke = args.smoke || localStorage.getItem('smoke') || '';
    this.breakfast = args.breakfast || localStorage.getItem('breakfast') || '';
    this.roomnum = Number(args.roomnum) || 1;

    this.peoplenum = Number(args.peoplenum) || 1;

    this.carrierIds = [];
    this.orgGeocode = null;
    this.destGeocode = null;
    this.secGeocode = null;

    this.domestic = true;

    this.index = Number(typeof args.index !== 'undefined' ? args.index : args.defaultIndex);

    this.type = args.type;
    this.selectedElement = args.selected;

    this.returnPlace = 'current';

    this.carTypeOptions = args.carType || [['', '']];

    this.carType = this.carTypeOptions[0][1];

    this.carTypeOther = '';

    this.carNumber = args.carNumber || 1;

    this.flightNum = args.flightNum;

    this.hotelName = args.hotelName;

    this.input = args.input ?? true;

    this.changeablePrices = [];

    this.filteredUpgradeSeat = true;

    this.showConnectingAir = true;

    this.aiHotelRecommendation = args.ai_hotel_recommendation;
    this.aiHotelRecommendationQuality = args.ai_hotel_recommendation_quality || 'not_rated';
    this.aiHotelRecommendationReason = args.ai_hotel_recommendation_reason;
    this.aiHotelRanking = args.ai_hotel_ranking;
    this.aiHotelRankingQuality = args.ai_hotel_ranking_quality || 'not_rated';
    this.aiHotelRankingReason = args.ai_hotel_ranking_reason;
  }

  async transportGeocodesPromise(): Promise<this> {
    const destAddress = this.destinationAddress;
    const destPlace = this.destination;
    const orgAddress = this.originAddress;
    const orgPlace = this.origin;
    const secAddress = this.secondaryAddress;
    const secPlace = this.secondary;
    const promises = [
      this.placePromise(orgAddress, orgPlace, 'transport', 'origin'),
      this.placePromise(destAddress, destPlace, 'transport', 'destination')
    ];
    if (secAddress || secPlace) {
      promises.push(
        this.placePromise(
          secAddress || '',
          secPlace || '',
          'transport',
          this.pair === 'outward' ? 'origin' : 'destination'
        )
      );
    }
    const [orgGeocode, destGeocode, secGeocode] = await Promise.all(promises);
    this.orgGeocode = orgGeocode;
    this.destGeocode = destGeocode;
    this.secGeocode = secGeocode || null;

    if (orgGeocode && destGeocode) {
      this.domestic = orgGeocode.isDomestic && destGeocode.isDomestic;
      this.resultItem.setIsDomestic(this.domestic);
    }

    return this;
  }

  async fetchDomesticTransits(transitSearchApi: TransitSearchApi) {
    try {
      // urlにselect.jsonに対するurlにdomestic_flight_repositoryパラメタが付与されていたら
      // v1.json or v2.jsonに引き継ぐ

      // リポジトリを直接指定できるようにする。
      const repo = utils.getParam('domestic_flight_repository');

      // 経路検索 レガシー版(v1)・新版(v2)でエンドポイントを分割したいという要求があったので、
      // 組織のtransitSearchApiをみてどちらのエンドポイントを使うかを判断する
      const query = repo ? `domestic_flight_repository=${repo}&${Date.now()}` : Date.now();
      const url = transitSearchApi === 'legacy' ? `/v1.json?${query}` : `/v2.json?${query}`;

      const promises = [utils.jsonPromise<TransitResponse>(url, this.transitParams())];
      const transitSecondaryParams = this.transitSecondaryParams();
      if (transitSecondaryParams) {
        promises.push(utils.jsonPromise<TransitResponse>(url, transitSecondaryParams));
      }
      const results = await Promise.all(promises);
      const result = this.combinedTransitResponse(results[0], results[1]);

      trackEvent('Domestic transit completed');
      if (result.sameStation) {
        this.resultItem.setLoading(false);
      } else if (this.resultItem) {
        this.resultItem.setError(null);
        this.resultItem.setElementList(result.outword, this.selectedElement, result.changeable_air);
        this.resultItem.setLoading(false);
      }
    } catch (e) {
      let error = utils.dig(e, 'responseJSON', 'errors');
      if (error && typeof error === 'object' && error.length > 0) error = error[0];
      error = utils.dig(e, 'outError') || error;
      trackEvent('Domestic transit failed');
      this.resultItem.setError(error || '経路を取得できませんでした。\n別の条件で再度お試しください。');
      this.resultItem.setLoading(false);
    }
  }

  combinedTransitResponse(destination: TransitResponse, secondary?: TransitResponse) {
    if (!this.pair) return destination;

    destination.outword.forEach(value => {
      value.airport = this.destination;
    });
    if (secondary && this.secondary) {
      secondary.outword.forEach(value => {
        value.airport = this.secondary;
      });
    }

    if (!secondary) return destination;

    return {
      sameStation: secondary.sameStation || destination.sameStation,
      changeable_air: secondary.changeable_air || destination.changeable_air,
      outword: this.combinedArray(destination.outword, secondary.outword)
    };
  }

  async hotelGeocodesPromise(): Promise<this> {
    const destAddress = this.destinationAddress;
    const destPlace = this.destination;
    const result = await this.placePromise(destAddress, destPlace, 'hotel', 'stay').catch((e: Errors) => {
      if (e.stay) {
        const err = { hotelError: e.stay };
        throw err;
      }
      throw e;
    });
    this.destGeocode = result;
    if (result) {
      this.domestic = result.isDomestic;
      this.resultItem.setIsDomestic(this.domestic);
    }

    return this;
  }

  async fetchHotels() {
    if (this.outdate === this.homedate) {
      this.resultItem.setNeed(false);
      this.resultItem.setLoading(false);
    } else {
      const destAddress = this.destinationAddress;
      const destPlace = this.destination;
      try {
        const stayGeocode = await this.placePromise(destAddress, destPlace, 'hotel', 'stay').catch((e: Errors) => {
          if (e.stay) {
            const err = { hotelError: e.stay };
            throw err;
          }
          throw e;
        });
        if (!stayGeocode) {
          return;
        }

        const { location } = stayGeocode;
        this.resultItem.setDestLocation(location);
        this.resultItem.setIsDomestic(stayGeocode.isDomestic);
        const hotelParams = this.hotelParams(stayGeocode, stayGeocode.isDomestic);
        const hotelsResponse = await utils
          .jsonPromise<HotelsResponse>(`/hotels.json?${Date.now()}`, hotelParams)
          .catch(e => {
            const err = {
              hotelError: utils.dig(e, 'hotelError') || utils.dig(e, 'responseJSON', 'errors')
            };
            throw err;
          });
        trackEvent('Hotel completed');
        const { hotels, prefecture_id, municipality_code, scores, score_reason_enabled } =
          hotelsResponse.search_result;
        this.prefectureId = prefecture_id ?? null;
        this.municipalityCode = municipality_code ?? null;
        this.resultItem.hotelPriceLimit = hotelsResponse.price;
        this.resultItem.hotelPriceLimitOverCount = hotelsResponse.price_limit_over_count;
        this.resultItem.filteredByOrganizationBreakfastFlag =
          hotelsResponse.filtered_by_organization_breakfast_flag;
        if (hotelsResponse.rakuten_static_file_updated_at) {
          this.resultItem.rakutenStaticFileUpdatedAt = moment(hotelsResponse.rakuten_static_file_updated_at);
        }
        this.resultItem.setElementList(hotels, this.selectedElement);
        if (this.resultItem.elementList instanceof HotelList) {
          this.resultItem.elementList.fetchDistances({ lat: hotelParams.latitude, lng: hotelParams.longitude });
        }
        this.resultItem.setHotelScores(scores);
        this.resultItem.setError(null);
        this.resultItem.setLoading(false);
        if (score_reason_enabled) {
          this.fetchReason();
        }
      } catch (e) {
        const error = utils.dig(e, 'hotelError') || 'ホテルの検索に失敗しました。時間を置いて再度お試しください。';
        this.resultItem.setError(error);
        this.resultItem.setLoading(false);
      }
    }
  }

  async reFetchHotels(lat: number, lng: number) {
    const destAddress = this.destinationAddress;
    const destPlace = this.destination;
    this.resultItem.setLoadingWithSelectedLoading(true);
    try {
      const stayGeocode = await this.placePromise(destAddress, destPlace, 'hotel', 'stay').catch((e: Errors) => {
        if (e.stay) {
          const err = { hotelError: e.stay };
          throw err;
        }
        throw e;
      });
      if (!stayGeocode) {
        return;
      }
      stayGeocode.location.lat = lat;
      stayGeocode.location.lng = lng;
      const { location } = stayGeocode;
      this.resultItem.setDestLocation(location);
      this.resultItem.setIsDomestic(stayGeocode.isDomestic);
      const hotelParams = this.hotelParams(stayGeocode, stayGeocode.isDomestic);
      const hotelsResponse = await utils
        .jsonPromise<HotelsResponse>(`/hotels.json?${Date.now()}`, hotelParams)
        .catch(e => {
          const err = {
            hotelError: utils.dig(e, 'hotelError') || utils.dig(e, 'responseJSON', 'errors')
          };
          throw err;
        });
      trackEvent('Hotel completed');
      const { hotels, prefecture_id, municipality_code, scores, score_reason_enabled } =
        hotelsResponse.search_result;
      this.prefectureId = prefecture_id ?? null;
      this.municipalityCode = municipality_code ?? null;
      this.resultItem.hotelPriceLimit = hotelsResponse.price;
      if (hotelsResponse.rakuten_static_file_updated_at) {
        this.resultItem.rakutenStaticFileUpdatedAt = moment(hotelsResponse.rakuten_static_file_updated_at);
      }
      this.resultItem.setElementList(hotels, this.selectedElement, false, true);
      if (this.resultItem.elementList instanceof HotelList) {
        this.resultItem.elementList.fetchDistances({ lat: hotelParams.latitude, lng: hotelParams.longitude });
      }
      this.resultItem.setHotelScores(scores);
      this.resultItem.setError(null);
      this.resultItem.setLoadingWithSelectedLoading(false);
      if (score_reason_enabled) {
        this.fetchReason();
      }
    } catch (e) {
      const error = utils.dig(e, 'hotelError') || 'ホテルの検索に失敗しました。時間を置いて再度お試しください。';
      this.resultItem.setError(error);
      this.resultItem.setLoadingWithSelectedLoading(false);
    }
  }

  async fetchReason() {
    if (!this.resultItem.scores) {
      return;
    }

    this.resultItem.setReasonStatus('loading');
    app.render();

    try {
      const params = {
        search_query_item_id: this.id,
        scores: this.resultItem.scores,
        destination: this.destination
      };
      const response = await Fetcher.post<ScoreResponse>(`/hotels/reason.json`, params);

      this.resultItem.setReason(response.selected_reason, response.score_reason);
      this.aiHotelRecommendation = response.selected_reason;
      this.aiHotelRecommendationQuality = 'not_rated';
      this.aiHotelRecommendationReason = '';
      this.aiHotelRanking = response.score_reason;
      this.aiHotelRankingQuality = 'not_rated';
      this.aiHotelRankingReason = '';
      app.render();
    } catch (e) {
      this.resultItem.setReasonStatus('none');
      app.render();
    }
  }

  async setAiHotelRecommendation(value: AiHotelQuality) {
    const response = await Fetcher.post<{ status: string }>(`/hotels/recommendation_quality.json`, {
      search_query_item_id: this.id,
      quality: value
    });

    if (response.status === 'ok') {
      this.aiHotelRecommendationQuality = value;
      app.render();
    }
  }

  setAiHotelRecommendationFeedback(value: string) {
    this.aiHotelRecommendationReason = value;
    app.render();
  }

  async commitAiHotelRecommendationFeedback() {
    if (!this.aiHotelRecommendationReason) return;

    await Fetcher.post<{ status: string }>(`/hotels/recommendation_feedback.json`, {
      search_query_item_id: this.id,
      reason: this.aiHotelRecommendationReason
    });
  }

  async setAiHotelRanking(value: AiHotelQuality) {
    const response = await Fetcher.post<{ status: string }>(`/hotels/ranking_quality.json`, {
      search_query_item_id: this.id,
      quality: value
    });

    if (response.status === 'ok') {
      this.aiHotelRankingQuality = value;
      app.render();
    }
  }

  setAiHotelRankingFeedback(value: string) {
    this.aiHotelRankingReason = value;
    app.render();
  }

  async commitAiHotelRankingFeedback() {
    if (!this.aiHotelRankingReason) return;

    await Fetcher.post<{ status: string }>(`/hotels/ranking_feedback.json`, {
      search_query_item_id: this.id,
      reason: this.aiHotelRankingReason
    });
  }

  /* eslint-disable class-methods-use-this */
  placePromise(
    address: string,
    place: string,
    itemType: PlaceItemType,
    type: 'origin' | 'destination' | 'stay' | 'start' | 'return'
  ) {
    return SearchQueryItem.fetchGeocodeDetail(address, place, itemType, type, this.placeOptions());
  }
  /* eslint-enable class-methods-use-this */

  placeOptions(): PlaceOptions {
    return {
      search_query_id: String(this.searchQueryId),
      search_query_item_id: String(this.id)
    };
  }

  private static fetchGeocodeDetail(
    address: string,
    place: string,
    itemType: PlaceItemType,
    type: 'origin' | 'destination' | 'stay' | 'start' | 'return',
    options?: PlaceOptions
  ): Promise<Place | null> {
    try {
      return PlaceRepository.fetch(address, place, itemType, options);
    } catch (e) {
      const desc =
        type === 'origin'
          ? '出発地'
          : type === 'destination'
          ? '目的地'
          : type === 'stay'
          ? '宿泊地'
          : type === 'start'
          ? '出発場所'
          : type === 'return'
          ? '返却場所'
          : '';
      if (e.status) {
        // fetchGeocodeDetail場所チェックでエラーが発生した場合
        if (e[type]) {
          if (e.status === 'UNKNOWN_ERROR') {
            if (typeof bugsnagClient !== 'undefined') {
              bugsnagClient.notify(new Error('UNKNOWN_ERROR: Google Geocoder API failed'));
            }
            e[type] = '外部サービスに不明なエラーが発生しました。時間をおいて再度お試しください。';
          } else {
            e[type] = `${desc}が見つかりませんでした。違う表記をお試しください。`;
          }
        }
      }
      throw e;
    }
  }

  transitParams() {
    const org = this.orgGeocode && this.orgGeocode.location;
    const dest = this.destGeocode && this.destGeocode.location;
    return {
      org_lat: org && org.lat,
      org_lng: org && org.lng,
      dest_lat: dest && dest.lat,
      dest_lng: dest && dest.lng,
      outdate: this.outdate,
      outhour: this.outhour,
      outmin: this.outmin,
      outtype: this.outtype,
      homedate: this.homedate,
      homehour: this.homehour,
      homemin: this.homemin,
      hometype: this.hometype,
      peoplenum: this.peoplenum,
      search_query_id: this.searchQueryId,
      search_query_item_id: this.id
    };
  }

  transitSecondaryParams() {
    if (!this.pair || !this.secGeocode) return null;

    const sec = this.secGeocode.location;
    const org = this.pair === 'homeward' ? sec : this.orgGeocode && this.orgGeocode.location;
    const dest = this.pair === 'outward' ? sec : this.destGeocode && this.destGeocode.location;
    return {
      org_lat: org && org.lat,
      org_lng: org && org.lng,
      dest_lat: dest && dest.lat,
      dest_lng: dest && dest.lng,
      outdate: this.outdate,
      outhour: this.outhour,
      outmin: this.outmin,
      outtype: this.outtype,
      homedate: this.homedate,
      homehour: this.homehour,
      homemin: this.homemin,
      hometype: this.hometype,
      peoplenum: this.peoplenum,
      search_query_id: this.searchQueryId,
      search_query_item_id: this.id
    };
  }

  private hotelParams(place: Place, isDomestic: boolean) {
    const { location } = place;
    return {
      latitude: location.lat,
      longitude: location.lng,
      prefecture: place.prefectureName || '',
      municipality: place.municipalityName || '',
      checkin: this.outdate,
      checkout: this.homedate,
      smoke: this.smoke,
      breakfast: this.breakfast,
      roomnum: this.roomnum,
      peoplenum: this.peoplenum,
      search_query_id: this.searchQueryId,
      search_query_item_id: this.id,
      domestic: isDomestic
    };
  }

  flightParams() {
    const org = this.orgGeocode && this.orgGeocode.location;
    const dest = this.destGeocode && this.destGeocode.location;
    return {
      org_lat: org && org.lat,
      org_lng: org && org.lng,
      dest_lat: dest && dest.lat,
      dest_lng: dest && dest.lng,
      date: this.outdate,
      hour: this.outhour,
      min: this.outmin,
      type: this.outtype,
      search_query_id: this.searchQueryId,
      search_query_item_id: this.id,
      carrier_id: this.carrierIds.join(','),
      origin_code: this.originCode ?? this.orgGeocode?.info?.iata_code,
      dest_code: this.destCode ?? this.destGeocode?.info?.iata_code
    };
  }

  packageParams() {
    const org = this.orgGeocode && this.orgGeocode.location;
    const dest = this.destGeocode && this.destGeocode.location;

    switch (this.itemType) {
      case 'transport':
        return {
          item_type: 'transport',
          org_lat: org && org.lat,
          org_lng: org && org.lng,
          dest_lat: dest && dest.lat,
          dest_lng: dest && dest.lng,
          outdate: this.outdate,
          outhour: this.outhour,
          outmin: this.outmin,
          outtype: this.outtype,
          peoplenum: this.peoplenum
        };
      case 'hotel':
        return {
          item_type: 'hotel',
          latitude: dest && dest.lat,
          longitude: dest && dest.lng,
          checkin: this.outdate,
          checkout: this.homedate,
          smoke: this.smoke,
          breakfast: this.breakfast,
          roomnum: this.roomnum,
          peoplenum: this.peoplenum
        };
      default:
        return undefined;
    }
  }

  jrPackageParams() {
    const org = this.orgGeocode && this.orgGeocode.location;
    const dest = this.destGeocode && this.destGeocode.location;

    switch (this.itemType) {
      case 'transport':
        return {
          item_type: 'transport',
          origin: this.origin,
          destination: this.destination,
          destination_prefecture: (this.destGeocode && this.destGeocode.prefectureName) || '',
          org_lat: org && org.lat,
          org_lng: org && org.lng,
          dest_lat: dest && dest.lat,
          dest_lng: dest && dest.lng,
          outdate: this.outdate,
          outhour: this.outhour,
          outmin: this.outmin,
          outtype: this.outtype,
          peoplenum: this.peoplenum
        };
      case 'hotel':
        return {
          item_type: 'hotel',
          origin: this.origin,
          destination: this.destination,
          latitude: dest && dest.lat,
          longitude: dest && dest.lng,
          checkin: this.outdate,
          checkout: this.homedate,
          smoke: this.smoke,
          breakfast: this.breakfast,
          roomnum: this.roomnum,
          peoplenum: this.peoplenum
        };
      default:
        return undefined;
    }
  }

  // 楽天パッケージ用のパラメーター。
  rakutenPackageParams() {
    const org = this.orgGeocode && this.orgGeocode.location;
    const dest = this.destGeocode && this.destGeocode.location;

    switch (this.itemType) {
      case 'transport':
        return {
          item_type: 'transport',
          origin: this.origin,
          destination: this.destination,
          org_lat: org && org.lat,
          org_lng: org && org.lng,
          dest_lat: dest && dest.lat,
          dest_lng: dest && dest.lng,
          outdate: this.outdate,
          outhour: this.outhour,
          outmin: this.outmin,
          outtype: this.outtype,
          peoplenum: this.peoplenum,
          flight_num: this.flightNum // TODO: 行き・帰りで切り替える。
        };
      case 'hotel':
        return {
          item_type: 'hotel',
          origin: this.origin,
          destination: this.destination,
          latitude: dest && dest.lat,
          longitude: dest && dest.lng,
          checkin: this.outdate,
          checkout: this.homedate,
          smoke: this.smoke,
          breakfast: this.breakfast,
          roomnum: this.roomnum,
          peoplenum: this.peoplenum,
          hotel_name: this.hotelName
        };
      default:
        return undefined;
    }
  }

  searchHistoryParams() {
    const params = [];
    if (this.origin.length > 0)
      params.push({
        main_text: this.origin,
        secondary_text: this.originAddress,
        kind: 'origin'
      });
    if (this.destination.length > 0)
      params.push({
        main_text: this.destination,
        secondary_text: this.destinationAddress,
        kind: 'destination'
      });
    return params;
  }

  setResultItem(item: SearchResultItem) {
    this.resultItem = item;
  }

  setOrigin(value: string, address = '') {
    this.origin = value;
    this.originAddress = address;
    app.render();
  }

  setPair(value: 'outward' | 'homeward' | undefined) {
    this.pair = value;
    app.render();
  }

  setInput(value: boolean) {
    this.input = value;
    app.render();
  }

  setDestination(value: string, address = '') {
    this.destination = value;
    this.destinationAddress = address;
    app.render();
  }

  setOutdate(date: Moment) {
    const outMoment = moment(date);
    let homeMoment = moment(this.homedate);

    if (outMoment >= homeMoment) homeMoment = moment(outMoment).add(this.itemType === 'rentalCar' ? 0 : 1, 'day');

    this.outdate = date.format('YYYY-MM-DD');
    this.setHomedate(homeMoment);
  }

  setOuthour(value: string) {
    this.outhour = value;
    app.render();
  }

  setOutmin(value: string) {
    this.outmin = value;
    app.render();
  }

  setOuttype(value: 'arrival' | 'departure') {
    this.outtype = value;
    app.render();
  }

  setHomedate(date: Moment) {
    this.homedate = date.format('YYYY-MM-DD');
    app.render();
  }

  setHomehour(value: string) {
    this.homehour = value;
    app.render();
  }

  setHomemin(value: string) {
    this.homemin = value;
    app.render();
  }

  setHometype(value: 'arrival') {
    this.hometype = value;
    app.render();
  }

  setPeoplenum(value: number) {
    this.peoplenum = value;
    app.render();
  }

  setFlightNum(value: string) {
    this.flightNum = value;
    app.render();
  }

  setRoomnum(value: number) {
    this.roomnum = value;
    app.render();
  }

  setSmoke(value: string) {
    this.smoke = value;
    app.render();
  }

  setBreakfast(value: string) {
    this.breakfast = value;
    app.render();
  }

  setIndex(index: number) {
    this.index = index;
    app.render();
  }

  setCarrierIds(carrierIds: string[]) {
    this.carrierIds = carrierIds;
    app.render();
  }

  setOriginCode(value: string) {
    this.originCode = value;
    app.render();
  }

  setDestCode(value: string) {
    this.destCode = value;
    app.render();
  }

  clearCarrierIds() {
    this.carrierIds = [];
  }

  getStayDaysText() {
    const days = moment(this.homedate).diff(this.outdate, 'days');
    return `${days}泊${days + 1}日`;
  }

  getSummaryData(index?: number): { [key: string]: string } {
    const i = (index ?? this.index) + 1;
    if (this.isTransport()) {
      const summary: { [key: string]: string } = {};
      summary[`${i} 経路`] = `${
        // 複雑な文字列結合を行っており、template literalに書き直すのが怖い
        // eslint-disable-next-line no-useless-concat
        `${this.origin} → ${this.destination}` + ' '
      }${SearchQueryItem.dateString(this.outdate, this.outhour, this.outmin, this.outtype)}`;
      return summary;
    }
    const summary: { [key: string]: string } = {};
    summary[`${i} ホテル`] = this.destination;
    return summary;
  }

  setReturnPlace(value: never) {
    this.returnPlace = value;
    app.render();
  }

  setCarType(value: never) {
    this.carType = value;
    app.render();
  }

  setCarTypeOther(value: never) {
    this.carTypeOther = value;
    app.render();
  }

  setCarNumber(value: never) {
    this.carNumber = value;
    app.render();
  }

  setChangeablePrices(changeablePrices: string[]) {
    this.changeablePrices = changeablePrices;
    app.render();
  }

  setShowConnectingAir(val: boolean) {
    this.showConnectingAir = val;
    app.render();
  }

  setFilteredUpgradeSeat(val: boolean) {
    this.filteredUpgradeSeat = val;
    app.render();
  }

  validationPromise() {
    // just validate here
    const errors: { [key: string]: string } = {};

    switch (this.itemType) {
      case 'transport':
        // 出発地が入力されていること
        if (_.isEmpty(this.origin)) errors.origin = '出発地を入力してください';
        // 目的地が入力されていること
        if (_.isEmpty(this.destination)) {
          errors.destination = '目的地を入力してください';
          // 出発地と目的地の異なる場所が入っていること
        } else if (this.origin === this.destination) {
          errors.destination = '目的地には出発地と異なる場所を入力してください';
        }
        break;

      case 'hotel': {
        // 宿泊地が入力されていること
        if (_.isEmpty(this.destination)) errors.stay = '宿泊地を入力してください';
        // 部屋数 <= 人数であること
        if (this.roomnum > this.peoplenum) errors.peoplenum = '人数には部屋数以上を入力してください';
        // チェックアウト > チェックイン
        const outdate_str = `${this.outdate} ${this.outhour || '0'}:${this.outmin || '00'}`;
        const homedate_str = `${this.homedate} ${this.homehour || '0'}:${this.homemin || '00'}`;
        if (moment(this.outdate) > moment(this.homedate))
          errors.homedate = 'チェックアウトの日付をチェックインの日付以降に設定してください';
        if (this.outdate === this.homedate && moment(outdate_str) > moment(homedate_str))
          errors.homemin = 'チェックアウトの時刻をチェックインの時刻以降に設定してください';
        break;
      }

      case 'rentalCar': {
        // 出発場所が入力されていること
        if (_.isEmpty(this.origin)) errors.origin = '出発場所を入力してください';
        // 返却場所が入力されていること
        if (_.isEmpty(this.destination)) {
          errors.destination = '返却場所を入力してください';
        }
        // 返却日時 > 出発日時
        const outdate_str = `${this.outdate} ${this.outhour || '0'}:${this.outmin || '00'}`;
        const homedate_str = `${this.homedate} ${this.homehour || '0'}:${this.homemin || '00'}`;
        if (
          moment(this.outdate) > moment(this.homedate) ||
          (this.outdate === this.homedate && moment(outdate_str) > moment(homedate_str))
        )
          errors.homedate = '返却日時を出発日時以降に設定してください';
        break;
      }
      default:
    }
    if (_.keys(errors).length > 0) {
      return errors;
    }
    return null;
  }

  getSubmitParams() {
    return {
      item_type: this.itemType,
      pair: this.pair,
      origin: this.origin,
      origin_address: this.originAddress,
      destination: this.destination,
      destination_address: this.destinationAddress,
      secondary: this.secondary,
      secondary_address: this.secondaryAddress,
      prefecture_id: this.prefectureId,
      municipality_code: this.municipalityCode,
      outdate: this.outdate,
      outhour: this.outhour,
      outmin: this.outmin,
      outtype: this.outtype,
      homedate: this.homedate,
      homehour: this.homehour,
      homemin: this.homemin,
      hometype: this.hometype,
      peoplenum: this.peoplenum,
      roomnum: this.roomnum,
      smoke: this.smoke,
      breakfast: this.breakfast,
      index: this.index,
      car_type: this.carType,
      car_type_other: this.carTypeOther,
      car_number: this.carNumber
    };
  }

  getQueryString(key: string): string {
    let res = '';
    _.entries(this.getSubmitParams()).forEach(([k, v]) => {
      if (v == null || v === '') return;
      res += `${key}[${k}]=${v}&`;
    });
    return res;
  }

  private isTransport(): boolean {
    return this.itemType === 'transport';
  }

  private static dateString(date: string, hour: string, minute: string, timetype: 'departure' | 'arrival') {
    if (date) {
      let str = moment(date).format('MM月DD日(ddd)');
      if (!_.isEmpty(hour)) {
        str += ` ${hour}:${!_.isEmpty(minute) ? minute : '00'}`;
      }

      switch (timetype) {
        case 'departure':
          str += '出発';
          break;
        case 'arrival':
          str += '到着';
          break;
        default:
      }

      return str;
    }
    return '';
  }

  startTime() {
    return this.outTime();
  }

  endTime() {
    return this.itemType === 'hotel' ? this.homeTime() : this.outTime();
  }

  homedateWithLimitTime(hour: number | null) {
    if (hour) {
      return `${this.homedate} ${hour}:00:00`;
    }
    return this.homedate;
  }

  outdateWithLimitTime(hour: number | null) {
    if (hour) {
      return `${this.outdate} ${hour}:00:00`;
    }
    return this.outdate;
  }

  private outTime() {
    return {
      date: this.outdate,
      hour: this.outhour,
      min: this.outmin
    };
  }

  private homeTime() {
    return {
      date: this.homedate,
      hour: this.homehour,
      min: this.homemin
    };
  }

  private combinedArray(array1: any[], array2?: any[]) {
    if (!array2) return array1;

    const combinedArray = [];
    const length = Math.max(array1.length, array2.length);

    for (let i = 0; i < length; i += 1) {
      if (array1[i]) combinedArray.push(array1[i]);
      if (array2[i]) combinedArray.push(array2[i]);
    }

    return combinedArray;
  }

  applyTextToQueryResultItem(item: TextToQueryResultItem) {
    if (item.origin) {
      this.origin = item.origin;
    }
    if (item.destination) {
      this.destination = item.destination;
    }
    if (item.date) {
      this.outdate = item.date.format('YYYY-MM-DD');
    }
    if (item.hour) {
      this.outhour = item.hour.toString();
    }
    if (item.minute) {
      this.outmin = item.minute.toString();
    }
    if (item.timetype) {
      this.outtype = item.timetype;
    }
    if (item.peoplenum) {
      this.peoplenum = item.peoplenum;
    }
    if (item.checkoutDate) {
      this.homedate = item.checkoutDate.format('YYYY-MM-DD');
    }
    if (item.peoplenum) {
      this.roomnum = item.peoplenum;
    }
    app.render();
  }
}

export default SearchQueryItem;
