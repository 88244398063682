import type { ThemedCssFunction } from 'styled-components';
import { css } from 'styled-components';
import type { Themes } from '@this/constants/themes';

export const sizes = {
  sp: 900,
  spSmall: 768
};

export const media = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;

  return acc;
}, {} as { [key in keyof typeof sizes]: ThemedCssFunction<Themes> });
