import React, { useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type SegmentClass from '../../../../domain/transit/segment';
import type { LegSummaryJson } from '../../../../domain/select_repository';

type Props = {
  segment: SegmentClass;
  legsummary: LegSummaryJson;
};

const ElementTransportBoxShortdistanceModalWindowTemplate: React.FC<Props> = ({ segment, legsummary }) => {
  const [show, setShow] = useState(false);
  return (
    <StyledModalWindow>
      <Button onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        詳細
      </Button>
      {show && (
        <ContentWrapper>
          {segment.legs.map((le, q) => (
            <>
              {legsummary.indexes.includes(String(q + 1)) && (
                <Summary>
                  <ArrowBox>
                    {(le.type === 'train' || le.type === 'express' || le.type === 'shin') && (
                      <Image src="/images/train.png" />
                    )}
                    {le.airline_name !== null && <Image src="/images/plane.png" />}
                    {le.type === 'walk' && <Image src="/images/walk.png" />}
                    {le.type === 'bus' && <Image src="/images/bus.png" />}
                  </ArrowBox>
                  <SummaryDetail>
                    <BoxContent>
                      <LegTime>{le.from.time}</LegTime>
                      <SpaceStation>{le.from.name}</SpaceStation>
                    </BoxContent>
                    <BoxContent>
                      <LegTime />
                      <Space>{le.name}</Space>
                    </BoxContent>
                    <BoxContent>
                      <LegTime>{le.to.time}</LegTime>
                      <SpaceStation>{le.to.name}</SpaceStation>
                    </BoxContent>
                  </SummaryDetail>
                </Summary>
              )}
            </>
          ))}
        </ContentWrapper>
      )}
    </StyledModalWindow>
  );
};

const StyledModalWindow = styled.div`
  position: relative;
  @media screen and (max-width: 767px) {
    position: static;
  }
`;

const Button = styled.div`
  color: #999999;
  background: white;
  border: 1px solid #999999;
  border-radius: 3px;
  font-weight: bold;
  line-height: 15px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  padding: 0 5px;
  white-space: nowrap;
`;

const ContentWrapper = styled.div`
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #999999;
  color: #333;
  align-items: center;
  padding: 6px;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 0%;
  min-width: 300px;
  z-index: 1000;
  @media screen and (max-width: 767px) {
    right: 0;
    max-width: 90%;
    margin: 0 auto;
  }
`;

export const Summary = styled.div`
  padding: 5px;
  display: block;
  margin-bottom: 5px;
  clear: both;
`;

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const ArrowBox = styled.div`
  background-image: url('/images/train-route-arrow.png');
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
  width: 23px;
  height: 90px;
  text-align: center;
  vertical-align: middle;
  float: left;
  display: flex;
`;

export const Image = styled.img`
  height: 15px;
  margin: auto;
  background-color: #ffffff;
`;

export const SummaryDetail = styled.div`
  width: 250px;
  float: left;
`;

export const BoxTitle = styled.div`
  background: #aaa;
  color: white;
  width: 25px;
  margin-right: 10px;
  height: 18px;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
`;

export const BoxSpace = styled.div`
  width: 25px;
  margin-right: 4px;
`;

export const LegTime = styled.div`
  width: 40px;
  color: #1e90ff;
  font-size: 13px;
  font-weight: bold;
  height: 16px;
  vertical-align: middle;
  margin-right: 20px;
`;

export const Space = styled.div`
  margin-right: 10px;
`;

export const SpaceStation = styled.div`
  margin-right: 10px;
  font-weight: bold;
`;

export const TitleSpace = styled.div`
  margin-left: 35px;
  margin-right: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  padding: 5px;
`;

export default ElementTransportBoxShortdistanceModalWindowTemplate;
