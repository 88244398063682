import { Fetcher } from '@this/src/util';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import useApi from '@this/shared/hooks/use_api';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import SuppliedItemMappingTabs from '@this/components/admin/supplied_item_mappings/share/supplied_item_mapping_tabs';
import { Title } from '../admin';
import { AdminContentIn } from '../shared/atom';

interface Response {
  categories: {
    id: number;
    target_type: string;
    airline_company_type: string;
    display_condition_supplied_item_id: number;
    supplied_item_id: number;
    target_type_value: number;
    airline_company_type_value: number;
  }[];
  supplied_items: {
    id: number;
    supplier_name: string;
    name: string;
  }[];
}

interface SuppliedItem {
  id: number;
  supplierName: string;
  name: string;
}

const IndividualTargetSuppliedItems = () => {
  const [savingTargetType, setSavingTargetType] = useState(0);
  const [savingAirlineCompanyType, setSavingAirlineCompanyType] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(0);
  const params = { reloadFlag };
  const { data, isLoading } = useApi<Response>('/admin/individual_target_supplied_items.json', params);
  const categories = data ? data.categories : [];
  const suppliedItems: SuppliedItem[] = data
    ? data.supplied_items.map(item => ({ id: item.id, supplierName: item.supplier_name, name: item.name }))
    : [];
  const saveMapping = useCallback(
    (
      id: number,
      targetType: number,
      airLineCompanyType: number,
      suppliedItemId: number,
      displayConditionSuppliedItemId: number
    ) => {
      setSavingTargetType(targetType);
      setSavingAirlineCompanyType(airLineCompanyType);
      const saveParams = {
        target_type: targetType,
        airline_company_type: airLineCompanyType,
        supplied_item_id: suppliedItemId,
        display_condition_supplied_item_id: displayConditionSuppliedItemId
      };
      Fetcher.put(`/admin/individual_target_supplied_items/${id}.json`, saveParams)
        .catch(e => {
          alert(
            utils.dig(e, 'responseJSON', 'message') ||
              '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          );
        })
        .finally(() => {
          setSavingTargetType(0);
          setSavingAirlineCompanyType(0);
          setReloadFlag(reloadFlag + 1);
        });
    },
    [reloadFlag]
  );
  return (
    <div>
      <Title>仕入商品マッピング</Title>
      <AdminContentIn>
        <Description>個別にデフォルトの仕入商品を設定できます。</Description>
        <SuppliedItemMappingTabs />
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>対象</Th>
                <Th>航空会社</Th>
                <Th>表示条件</Th>
                <Th>仕入商品</Th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, i) => (
                <tr key={i}>
                  <Td>{category.target_type}</Td>
                  <Td>{category.airline_company_type}</Td>
                  <Td>
                    {savingTargetType === category.target_type_value &&
                    savingAirlineCompanyType === category.airline_company_type_value ? (
                      <SimpleLoading size={18} />
                    ) : (
                      <Select
                        value={category.display_condition_supplied_item_id || ''}
                        onChange={e =>
                          saveMapping(
                            category.id,
                            category.target_type_value,
                            category.airline_company_type_value,
                            category.supplied_item_id,
                            parseInt(e.target.value, 10)
                          )
                        }
                      >
                        {suppliedItems.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.supplierName}：{item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Td>
                  <Td>
                    {savingTargetType === category.target_type_value &&
                    savingAirlineCompanyType === category.airline_company_type_value ? (
                      <SimpleLoading size={18} />
                    ) : (
                      <Select
                        value={category.supplied_item_id || ''}
                        onChange={e =>
                          saveMapping(
                            category.id,
                            category.target_type_value,
                            category.airline_company_type_value,
                            parseInt(e.target.value, 10),
                            category.display_condition_supplied_item_id
                          )
                        }
                      >
                        {suppliedItems.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.supplierName}：{item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}{' '}
      </AdminContentIn>
    </div>
  );
};

const Description = styled.div`
  margin-bottom: 40px;
`;

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const Select = styled.select`
  margin: 0;
`;

export default IndividualTargetSuppliedItems;
