import React from 'react';
import { useStyles } from './datepicker_base.styles';

type DatepickerPanelWrapperProps = {
  className?: string;
  panel: React.ReactNode;
};
export const DatepickerPanelWrapper = ({ className, panel }: DatepickerPanelWrapperProps) => {
  const styles = useStyles();
  return <div className={`${styles.root} ${className}`}>{panel}</div>;
};
