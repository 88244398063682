import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle
} from '@this/components/organization/trips/detail/trip_detail.style';

interface Props {
  finalDestination?: string;
  onSave: (finalDestination: string) => void;
}

const FinalDestinationForm: React.FC<Props> = ({ finalDestination, onSave }) => {
  const [editFinalDestination, setEditFinalDestination] = useState(finalDestination);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditFinalDestination(e.target.value || '');
  };

  return (
    <MetaSection>
      <MetaSectionTitle>出張先</MetaSectionTitle>

      <input type="text" placeholder="(任意)出張先" value={editFinalDestination} onChange={onChange} />

      {finalDestination !== editFinalDestination && (
        <MetaSectionFormActions>
          <MetaSectionFormButton onClick={() => onSave(editFinalDestination || '')}>保存</MetaSectionFormButton>
          <MetaSectionFormButton onClick={() => setEditFinalDestination(finalDestination)}>
            キャンセル
          </MetaSectionFormButton>
        </MetaSectionFormActions>
      )}
    </MetaSection>
  );
};

export default FinalDestinationForm;
