interface Args {
  user_name: string;
  trip_id: number;
  seq_number: string;
  boarding_date: string;
  ticket_type: string;
  departure_station: string;
  arrival_station: string;
  purchase_date: string;
  amount: string;
}

class ExicReport {
  public readonly userName: string;

  public readonly tripId: number;

  public readonly seqNumber: string;

  public readonly boardingDate: string;

  public readonly ticketType: string;

  public readonly departureStation: string;

  public readonly arrivalStation: string;

  public readonly purchaseDate: string;

  public readonly amount: string;

  constructor(args: Args) {
    this.userName = args.user_name;
    this.tripId = args.trip_id;
    this.seqNumber = args.seq_number;
    this.boardingDate = args.boarding_date;
    this.ticketType = args.ticket_type;
    this.departureStation = args.departure_station;
    this.arrivalStation = args.arrival_station;
    this.purchaseDate = args.purchase_date;
    this.amount = args.amount;
  }
}

export default ExicReport;
