import React from 'react';

import type {
  FormControlProps as MuiFormControlProps,
  InputLabelProps as MuiInputLabelProps,
  FormHelperTextProps as MuiFormHelperTextProps
} from '@material-ui/core';
import {
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  FormHelperText as MuiFormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export type FormControlProps = Omit<MuiFormControlProps, 'color' | 'hiddenLabel' | 'variant' | 'size'>;
export const FormControl = (props: FormControlProps) => {
  return <MuiFormControl variant="outlined" {...props} />;
};

const useInputLabelStyle = makeStyles(theme => ({
  root: {
    position: 'static',
    transform: 'none',
    pointerEvents: 'auto',
    marginBottom: theme.spacing(0.5),
    '&.MuiInputLabel-shrink': { transform: 'none' }
  }
}));
export type InputLabelType = Omit<MuiInputLabelProps, 'shrink'>;
export const InputLabel = ({ className, ...props }: InputLabelType) => {
  const styles = useInputLabelStyle();
  return <MuiInputLabel className={`${className} ${styles.root}`} shrink {...props} />;
};

const useFormHelperText = makeStyles(theme => ({
  root: { marginLeft: theme.spacing(0), marginRight: theme.spacing(0) }
}));
export type FormHelperTextProps = Omit<MuiFormHelperTextProps, 'variant'>;
export const FormHelperText = ({ className, ...props }: FormHelperTextProps) => {
  const styles = useFormHelperText();
  return <MuiFormHelperText className={`${className} ${styles.root}`} {...props} />;
};
