import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { ApprovalStageJson } from '@this/domain/expenses/approval_stage';
import { ApprovalStage } from '@this/domain/expenses/approval_stage';
import type { ItemJson } from './item';
import { Item } from './item';
import User from '../user/user';
import type UserJson from '../user/user_json';

export interface ReportJson {
  id?: number;
  status: 'draft' | 'applied_for_approver' | 'applied_for_accountant' | 'approved' | 'rejected' | 'completed';
  items?: ItemJson[];
  user?: UserJson;
  from?: string;
  to?: string;
  created_at: string;
  updated_at: string;
  applied_at?: string;
  reject_reason?: string;
  title: string;
  approval_stages: ApprovalStageJson[];
  can_approve: boolean;
  can_complete: boolean;
}

type StatusType =
  | 'draft'
  | 'applied'
  | 'appliedForApprover'
  | 'appliedForAccountant'
  | 'approved'
  | 'rejected'
  | 'completed';

function statusTypeFromJson(args: ReportJson): StatusType {
  const status = args.status;
  if (status === 'applied_for_approver') return 'appliedForApprover';
  if (status === 'applied_for_accountant') return 'appliedForAccountant';
  return status;
}

export class Report {
  id?: number;

  status: StatusType;

  items: Item[];

  user?: User;

  createdAt: Moment;

  updatedAt: Moment;

  appliedAt?: Moment;

  from?: Moment;

  to?: Moment;

  rejectReason?: string;

  showDetail: boolean;

  title: string;

  selected: boolean;

  approvalStages: ApprovalStage[];

  canApprove: boolean;

  canComplete: boolean;

  constructor(args: ReportJson) {
    this.id = args.id;
    this.status = statusTypeFromJson(args);
    this.items = args.items ? args.items.map(item => new Item(item)) : [];
    this.user = args.user ? new User(args.user) : undefined;
    this.from = args.from ? moment(args.from) : undefined;
    this.to = args.to ? moment(args.to) : undefined;
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);
    if (args.applied_at) this.appliedAt = moment(args.applied_at);
    this.rejectReason = args.reject_reason;
    this.showDetail = false;
    this.selected = false;
    this.title = args.title ? args.title : '';
    this.approvalStages = args.approval_stages ? args.approval_stages.map(stage => new ApprovalStage(stage)) : [];
    this.canApprove = args.can_approve;
    this.canComplete = args.can_complete;
  }

  statusStr(): string {
    let str = '';
    switch (this.status) {
      case 'draft':
        str = '下書き';
        break;
      case 'appliedForApprover':
        str = '担当者承認待ち';
        break;
      case 'appliedForAccountant':
        str = '経理承認待ち';
        break;
      case 'approved':
        str = '承認済み';
        break;
      case 'rejected':
        str = '差し戻し';
        break;
      case 'completed':
        str = '完了';
        break;
      default:
    }
    return str;
  }

  filteringStatusFor(user: User) {
    if (user.isOrganizationAdmin) {
      if (this.isApplied()) return 'applied';
      return this.status;
    }
    if (this.status === 'appliedForApprover') return 'applied';
    if (this.status === 'appliedForAccountant') return 'approved';
    return this.status;
  }

  totalPrice(): number {
    return this.items.reduce((sum: number, item: Item) => sum + item.price, 0);
  }

  num = (): string => (this.id ? `No. ${this.id}` : '');

  subTitle(): string {
    if (!this.user) return '';
    const st = this.user.name;
    return this.user.departmentName() ? `${st} - ${this.user.departmentName()}` : st;
  }

  setTitle(value: string) {
    this.title = value;
    app.render();
  }

  appliedAtDesc(): string {
    return this.appliedAt ? this.appliedAt.format('YYYY/MM/DD') : '未申請';
  }

  isRejected(): boolean {
    return this.status === 'rejected';
  }

  isApplied(): boolean {
    return (
      this.status === 'applied' || this.status === 'appliedForApprover' || this.status === 'appliedForAccountant'
    );
  }

  toggleSelected() {
    this.selected = !this.selected;
    app.render();
  }

  isApprovableFor(user: User): boolean {
    if (user.organization.expenses_multistage_approval) return this.isApplied();
    if (user.isOrganizationAdmin) return this.isApplied();
    if (user.is_expenses_approver) {
      if (this.status === 'appliedForApprover') return true;
      return false;
    }
    return false;
  }

  isApproved(): boolean {
    return this.status === 'approved';
  }

  isCompleted(): boolean {
    return this.status === 'completed';
  }

  isRejectableFor(user: User): boolean {
    return this.isApprovableFor(user);
  }

  hasNextStepAction(): boolean {
    return this.canApprove || this.canComplete || this.status === 'completed'; // completed をみているのは完了後に全銀、CSV データを出力するため
  }

  approvalStatus() {
    switch (this.status) {
      case 'applied':
      case 'rejected':
      case 'approved':
      case 'completed':
        return this.status;
      case 'appliedForApprover':
      case 'appliedForAccountant':
        return 'applied';
      default:
        return 'applied';
    }
  }

  validationErrors() {
    const errors: { [key: string]: string | undefined } = {};
    if (_.isEmpty(this.title)) {
      errors.title = '申請タイトルを入力して下さい';
    }
    return errors;
  }

  toggleShowDetail() {
    this.showDetail = !this.showDetail;
    app.render();
  }
}
