import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { useInAdvanceApplicationStore } from '@this/src/domain/in_advance/in_advance_application_store';
import { Button } from '@this/shared/ui/inputs/button';
import { ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { List as MuiList, ListItem, ListItemText as MuiListItemText } from '@material-ui/core';
import { Accordion } from '@this/shared/ui/data_displays/accordion';

interface Props {
  onClose: () => void;
}

const BulkActionsAccept: React.FC<Props> = ({ onClose }) => {
  const store = useInAdvanceApplicationStore();
  const { batchErrors } = store;
  const [expanded, setExpanded] = useState(false);
  const [template, setTemplate] = useState<number | 'input' | null>(null);
  const [message, setMessage] = useState('');
  const handleClick = () => {
    store.fetchBatchAccept({
      onSuccess: () => onClose(),
      accept_message: template === 'input' ? message : ''
    });
  };

  return (
    <>
      <ModalHeader>申請の一括承認をしますか</ModalHeader>
      <ModalBody>
        <ModalText>
          選択した申請を一括で承認します。
          <br />
          よろしければ<strong>「承認する」</strong>をクリックしてください。
        </ModalText>
        <ModalInnerBody>
          <Select
            expanded={expanded}
            onChange={bool => setExpanded(bool)}
            summary={
              <SelectSummary className={expanded || template === null ? 'selected' : undefined}>
                {expanded || template === null
                  ? '承認事由を選択する'
                  : template === 'input'
                  ? '自由入力（任意）'
                  : ''}
              </SelectSummary>
            }
          >
            <List>
              <ListItem button onClick={() => setTemplate('input')}>
                <ListItemText
                  primary={
                    template === 'input' ? (
                      <>
                        <TextareaLabel>自由入力（任意）</TextareaLabel>
                        <Textarea rows={3} value={message} onChange={e => setMessage(e.target.value)} />
                      </>
                    ) : (
                      '自由入力（任意）'
                    )
                  }
                />
              </ListItem>
            </List>
          </Select>
        </ModalInnerBody>
        {batchErrors.length > 0 && (
          <Errors>
            {batchErrors.map((error, i) => (
              <Error key={`error-${i}`}>{error}</Error>
            ))}
          </Errors>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onClose}>
          戻る
        </Button>
        <Button onClick={handleClick}>承認する</Button>
      </ModalFooter>
    </>
  );
};

const ModalText = styled.div``;

const Errors = styled.div`
  margin-top: 16px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ModalInnerBody = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-top: 16px;
`;

const Select = styled(Accordion)`
  .MuiCollapse-wrapperInner {
    padding: 0;
  }
`;

const SelectSummary = styled.div`
  white-space: pre-wrap;

  &.selected {
    font-weight: bold;
  }
`;

const List = styled(MuiList)`
  padding: 0;

  .MuiListItem-root.Mui-focusVisible {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const ListItemText = styled(MuiListItemText)`
  white-space: pre-wrap;
`;

const Textarea = styled.textarea`
  height: auto;
`;

const TextareaLabel = styled.div`
  margin-bottom: 4px;
`;

export default BulkActionsAccept;
