import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import type {
  TicketingInstructionResponseArgs,
  TicketingMethodKey2
} from '@this/src/domain/arrangement/ticketing_instruction';
import TicketingInstruction, {
  convertTicketingInstructionResponseToArgs,
  TicketingMethod,
  TicketingMethod2,
  TicketingMethodKeys2
} from '@this/src/domain/arrangement/ticketing_instruction';
import type { UseQrQueryKey } from '@this/src/domain/organization/use_qr_query';
import { UseQrQuery, UseQrQueryKeys } from '@this/src/domain/organization/use_qr_query';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/components/shared/ui/data_displays/table';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Fetcher, HTTPError } from '@this/src/util';
import type { TabType } from '../todo_list';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import useOrganizationSelector from '../use_organization_selector';

type SortKey = 'organization' | 'received_at' | 'started_at';
type SortDirection = 'asc' | 'desc';

interface Response {
  ticketing_instructions: TicketingInstructionResponseArgs[];
  total_count: number;
  total_page: number;
}

const tab: TabType = 'TICKETING';

const getDefaultUseQr = (): UseQrQueryKey => {
  return (utils.getParam('use_qr') || 'all') as UseQrQueryKey;
};

const getDefaultOnlyPremiumSupport = (): boolean => {
  return utils.getParam('only_premium_support') === 'true';
};

const getDefaultTicketingMethod = (): TicketingMethodKey2 => {
  return (utils.getParam('ticketing_method') || 'all') as TicketingMethodKey2;
};

const getDefaultSortKey = (): SortKey => {
  return (utils.getParam('sort_key') || 'organization') as SortKey;
};

const getDefaultSortDirection = (): SortDirection => {
  return (utils.getParam('sort_direction') || 'asc') as SortDirection;
};

const Ticketing = observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<{ traceId: number; message: string } | null>(null);
  const [ticketingInstcutions, setTicketingInstcutions] = useState<TicketingInstruction[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [useQr, setUseQr] = useState<UseQrQueryKey>(getDefaultUseQr());
  const [onlyPremiumSupport, setOnlyPremiumSupport] = useState<boolean>(getDefaultOnlyPremiumSupport());
  const [ticketingMethod, setTicketingMethod] = useState<TicketingMethodKey2>(getDefaultTicketingMethod());
  const [sortKey, setSortKey] = useState<SortKey>(getDefaultSortKey());
  const [sortDirection, setSortDirection] = useState<SortDirection>(getDefaultSortDirection());

  const fetchItems = useCallback(() => {
    setIsLoading(true);

    const params = {
      page,
      organization_ids: organizationIds.join(','),
      use_qr: useQr,
      only_premium_support: onlyPremiumSupport,
      ticketing_method: ticketingMethod,
      sort_key: sortKey,
      sort_direction: sortDirection
    };

    const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
    history.push(`/arrangement/todo_list?tab=${tab}&${query}`);

    Fetcher.get<Response>('/arrangement/ticketings.json', params)
      .then(res => {
        setTicketingInstcutions(
          res.ticketing_instructions.map(
            args => new TicketingInstruction(convertTicketingInstructionResponseToArgs(args))
          )
        );
        setTotalCount(res.total_count);
        setTotalPage(res.total_page);
        if (res.total_page < page) {
          setPage(1);
        }
      })
      .finally(() => setIsLoading(false));
  }, [page, organizationIds, useQr, onlyPremiumSupport, ticketingMethod, sortKey, sortDirection]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const sendTicketing = useCallback(
    (ti: TicketingInstruction) => {
      setIsUpdating(true);
      setError(null);
      const params = {
        trace_id: ti.traceId,
        reservation_numbers: ti.reservationNumbers,
        purchase_dates: ti.purchaseDates.map(d => d?.format('YYYY-MM-DD'))
      };
      Fetcher.put<Response>('/arrangement/ticketings/proceed.json', params)
        .then(() => fetchItems())
        .catch(e => {
          setError({
            traceId: ti.traceId,
            message:
              e instanceof HTTPError && e.response?.data.errors
                ? e.response.data.errors.join('\n')
                : 'エラーが発生しました'
          });
        })
        .finally(() => setIsUpdating(false));
    },
    [fetchItems]
  );

  const revertTicketingInstruction = useCallback(
    (ti: TicketingInstruction) => {
      setIsUpdating(true);
      setError(null);
      const params = {
        order_item_id: ti.orderItemId
      };
      Fetcher.put<Response>('/arrangement/ticketing_instructions/revert.json', params)
        .then(() => fetchItems())
        .catch(e => {
          setError({
            traceId: ti.traceId,
            message:
              e instanceof HTTPError && e.response?.data.errors
                ? e.response.data.errors.join('\n')
                : 'エラーが発生しました'
          });
        })
        .finally(() => setIsUpdating(false));
    },
    [fetchItems]
  );

  const isConvertWordToBold = (message: string): boolean => {
    return message.startsWith('座席：') && message.startsWith('座席：指定席') === false;
  };

  return (
    <>
      <SearchArea>
        <SearchBlock>
          <SearchLabel>法人</SearchLabel>
          {renderOrganizationSelector()}
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>QR利用企業</SearchLabel>
          <Select
            value={useQr}
            onChange={e => setUseQr(e.target.value as UseQrQueryKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {UseQrQueryKeys.map(key => (
              <option key={key} value={key}>
                {UseQrQuery[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>発券方法</SearchLabel>
          <Select
            value={ticketingMethod}
            onChange={e => setTicketingMethod(e.target.value as TicketingMethodKey2)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            {TicketingMethodKeys2.map(key => (
              <option key={key} value={key}>
                {TicketingMethod2[key]}
              </option>
            ))}
          </Select>
        </SearchBlock>
        <SearchBlock>
          <Checkbox checked={onlyPremiumSupport} onChange={e => setOnlyPremiumSupport(e.target.checked)}>
            <b style={{ fontSize: '12px' }}>土日祝オプション企業のみ</b>
          </Checkbox>
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>並び順</SearchLabel>
          <select
            value={sortKey}
            onChange={e => setSortKey(e.target.value as SortKey)}
            style={{ marginBottom: 0, marginRight: '5px' }}
          >
            <option value="organization">法人</option>
            <option value="received_at">依頼日</option>
            <option value="started_at">出発日</option>
          </select>
          <Radio
            checked={sortDirection === 'asc'}
            onChange={() => setSortDirection('asc')}
            style={{ marginRight: '5px' }}
          >
            昇順▲
          </Radio>
          <Radio checked={sortDirection === 'desc'} onChange={() => setSortDirection('desc')}>
            降順▼
          </Radio>
        </SearchBlock>
      </SearchArea>
      <Box>未処理件数: {totalCount}件</Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>出発日</TableCell>
                <TableCell>依頼日</TableCell>
                <TableCell>企業名</TableCell>
                <TableCell>QR利用企業</TableCell>
                <TableCell>土日祝対応</TableCell>
                <TableCell>発券方法</TableCell>
                <TableCell>備考</TableCell>
                <TableCell>チャットメッセージ</TableCell>
                <TableCell>TripID</TableCell>
                <TableCell>TraceID</TableCell>
                <TableCell>最新のログメッセージ</TableCell>
                <TableCell>内容</TableCell>
                <TableCell>予約番号</TableCell>
                <TableCell>仕入日</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketingInstcutions.map(ti => (
                <TableRow key={ti.orderItemId}>
                  <TableCell nowrap>{ti.startDate?.format('YYYY-MM-DD') || ''}</TableCell>
                  <TableCell nowrap>{ti.receivedAt.format('YYYY-MM-DD HH:mm')}</TableCell>
                  <TableCell>{ti.organizationName}</TableCell>
                  <TableCell>{ti.useQr ? '◯' : ''}</TableCell>
                  <TableCell>{ti.premiumSupport ? '◯' : ''}</TableCell>
                  <TableCell>{TicketingMethod[ti.ticketingMethod]}</TableCell>
                  <TableCell>{ti.ticketingNote}</TableCell>
                  <TableCell>
                    {ti.latestMessage?.split('\n').map((line, index) => (
                      <Box key={index}>{line}</Box>
                    ))}
                  </TableCell>
                  <TableCell nowrap>
                    <Link
                      href={`/arrangement/virtual_counter?trip_id=${ti.tripId}`}
                      target="_blank"
                      rel="noopener noreffer"
                    >
                      {ti.tripId}
                    </Link>
                  </TableCell>
                  <TableCell nowrap>{ti.traceId}</TableCell>
                  <TableCell>{ti.latestLog}</TableCell>
                  <TableCell>
                    {ti.content?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {isConvertWordToBold(line) ? <strong>{line}</strong> : <>{line}</>}
                        {index !== (ti.content?.split('\n').length || 0) - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                  <TableCell>
                    {ti.reservationNumbers.map((reservationNumber, i) => (
                      <Box key={i}>
                        <Input
                          value={reservationNumber || ''}
                          onChange={e => {
                            ti.reservationNumbers[i] = e.target.value;
                          }}
                        />
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell>
                    {ti.purchaseDates.map((date, i) => (
                      <DatetimePicker
                        key={i}
                        value={date || undefined}
                        onChange={d => {
                          ti.purchaseDates[i] = d;
                        }}
                        showPast
                        border
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Button loading={isUpdating} onClick={() => sendTicketing(ti)}>
                        発券完了
                      </Button>
                    </Box>
                    <Box marginTop="10px">
                      <Button loading={isUpdating} color="sub" onClick={() => revertTicketingInstruction(ti)}>
                        発券指示に戻す
                      </Button>
                    </Box>
                    {error?.traceId === ti.traceId && (
                      <Box marginTop="10px" color="red">
                        {error.message}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_: unknown, newPage: number) => setPage(newPage)}
          />
        </>
      )}
    </>
  );
});

export default Ticketing;
