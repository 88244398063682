import React, { useState, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import { Loading } from '@this/shared/ui/feedbacks/loading/loading';
import type UserJson from '@this/domain/user/user_json';
import User from '@this/domain/user/user';
import useDebounce from '@this/lib/hooks/useDebounce';
import { Fetcher } from '@this/src/util/fetcher';

interface Props {
  stage: number;
  index: number;
  defaultMember?: User | null;
  onSelect: (stage: number, index: number, member: User) => void;
}

interface MembersListResponse {
  members: UserJson[];
}

const SelectApprovers: React.FC<Props> = ({ stage, index, defaultMember, onSelect }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<User[]>([]);
  const [selectedMember, setSelectedMember] = useState<User | null>(defaultMember ?? null);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [showCandidate, setShowCandidate] = useState<boolean>(false);
  const debouncedSelectedValue = useDebounce(selectedValue, 300);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleShowCandidate = useCallback(() => {
    setShowCandidate(prev => !prev);
  }, []);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const params = { keyword: debouncedSelectedValue, approver_required: true };
    const data = await Fetcher.get<MembersListResponse>('/members.json', params);
    const fetchedMembers: User[] = data
      ? data.members.map(raw => {
          return new User(raw);
        })
      : [];
    setMembers(fetchedMembers);
    setLoading(false);
  }, [debouncedSelectedValue]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (showCandidate && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCandidate]);

  const selectList = (member: User) => {
    onSelect(stage, index, member);
    setSelectedMember(member);
    setShowCandidate(false);
  };

  return (
    <>
      <div className="select-approvers__selected-list__item" onClick={toggleShowCandidate}>
        {selectedMember ? (
          <div className="approver-members-list__item__info">
            <p className="approver-members-list__item-part">{selectedMember.name}</p>
            <p className="approver-members-list__item-part">{`(${selectedMember.email})`}</p>
          </div>
        ) : (
          <p className="select-approvers__selected-list__message">選択してください</p>
        )}
        <div className="select-approvers__selected-list__right">{showCandidate ? '▲' : '▼'}</div>
      </div>
      {showCandidate && (
        <div className="members-list">
          <input
            type="search"
            ref={inputRef}
            value={selectedValue}
            placeholder="社員名またはemailで検索"
            onChange={e => {
              setSelectedValue(e.target.value);
            }}
          />
          <ul>
            {loading ? (
              <li className="members-list__item">
                <Loading />
              </li>
            ) : (
              <>
                {members.map((member, i) => (
                  <li key={i} className="members-list__item" onClick={() => selectList(member)}>
                    <div className="members-list__item__info">
                      {member.departmentName() && (
                        <p className="members-list__item__name">{member.departmentName()}</p>
                      )}
                      <p className="members-list__item__name">{member.name}</p>
                      <p className="members-list__item__email">({member.email})</p>
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default SelectApprovers;
