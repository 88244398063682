import React, { useReducer } from 'react';
import { styled } from '@this/constants/themes';
import type Invoice from '@this/src/domain/invoice/invoice2';
import Button from '@this/components/shared/atoms/button';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import { Fetcher, HTTPError } from '@this/src/util';

interface Props {
  infomartConnected: boolean;
  invoice: Invoice;
  onRefreshInvoices?: () => void;
}

interface State {
  infomartSubmitting: boolean;
  infomartMessage: string[];
}

interface InfomartResponse {
  data?: {
    result: string;
    error_list?: InfomartErrorResponse[];
    invoice_mng_num?: string;
  };
}

interface InfomartErrorResponse {
  error_code?: string;
  error_detail?: string;
  error_item?: string;
}

export type InfomartExternalProps = Props;

type Action =
  | { type: 'INFOMART_SUBMITTING_START' }
  | { type: 'INFOMART_SUBMITTING_SUCCESS'; payload: string[] }
  | { type: 'INFOMART_SUBMITTING_ERROR'; payload: string[] };

const initialState: State = {
  infomartSubmitting: false,
  infomartMessage: []
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INFOMART_SUBMITTING_START':
      return { ...state, infomartSubmitting: true, infomartMessage: [] };
    case 'INFOMART_SUBMITTING_SUCCESS':
      return { ...state, infomartSubmitting: false, infomartMessage: action.payload };
    case 'INFOMART_SUBMITTING_ERROR':
      return { ...state, infomartSubmitting: false, infomartMessage: action.payload };
    default:
      return state;
  }
};

const InfomartExternal: React.FC<Props> = ({ invoice, onRefreshInvoices }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { infomartSubmitting, infomartMessage } = state;

  const postInfomartInvoices = async () => {
    dispatch({ type: 'INFOMART_SUBMITTING_START' });
    const params = {
      invoice_id: invoice.id
    };

    Fetcher.post<InfomartResponse>('/organization/infomart/invoices', params)
      .then(response => {
        if (response.data && response.data.error_list) {
          const errorMessages = response.data.error_list.map((error: any) => {
            let message = error.error_detail ? error.error_detail : 'エラーが発生しました';
            if (error.error_code) {
              message += ` コード: ${error.error_code}`;
            }
            if (error.error_item) {
              message += ` 項目: ${error.error_item}`;
            }
            return message;
          });
          dispatch({ type: 'INFOMART_SUBMITTING_ERROR', payload: errorMessages });
        } else {
          dispatch({
            type: 'INFOMART_SUBMITTING_SUCCESS',
            payload: [`請求書登録が完了しました。請求書管理番号: ${response.data?.invoice_mng_num}`]
          });
        }
        onRefreshInvoices?.();
      })
      .catch(e => {
        dispatch({
          type: 'INFOMART_SUBMITTING_ERROR',
          payload: [
            e instanceof HTTPError && e.response?.data.error
              ? e.response?.data.error
              : '通信エラーが発生しました。時間をおいて再度お試しください。'
          ]
        });
      });
  };

  return (
    <Ul>
      <LowerLi>
        <h3>請求書登録</h3>
        <p>請求内容をBtoBプラットフォームへ登録できます。</p>
        <ButtonArea>
          {infomartSubmitting ? (
            <SimpleLoading />
          ) : (
            <TheButton onClick={() => postInfomartInvoices()}>請求内容をBtoBプラットフォームへ登録</TheButton>
          )}
        </ButtonArea>
        {infomartMessage?.map((message, index) => (
          <LabelErrorDescription key={index}>{message}</LabelErrorDescription>
        ))}
        {invoice.infomartPostInvoiceLogs && invoice.infomartPostInvoiceLogs.length > 0 && (
          <LogTable>
            <caption>■ 実行履歴（最新の5件を表示）</caption>
            <thead>
              <LogRow>
                <LogHeader>登録日時</LogHeader>
                <LogHeader>ステータス</LogHeader>
                <LogHeader>管理番号</LogHeader>
                <LogHeaderWide>エラーメッセージ</LogHeaderWide>
              </LogRow>
            </thead>
            <tbody>
              {invoice.infomartPostInvoiceLogs
                ?.slice()
                .reverse()
                .slice(0, 5)
                .map((log, index) => (
                  <LogRow key={index}>
                    <LogColumn>{log.createdAt}</LogColumn>
                    <LogColumn>{log.statusLabel}</LogColumn>
                    <LogColumn>{log.invoiceMngNum}</LogColumn>
                    <ErrorMessageColumn>
                      <ErrorMessageFormat errorMessage={log.formattedErrorMessage} />
                    </ErrorMessageColumn>
                  </LogRow>
                ))}
            </tbody>
          </LogTable>
        )}
      </LowerLi>
    </Ul>
  );
};

const ErrorMessageFormat: React.FC<{ errorMessage: string }> = ({ errorMessage }) => {
  const formattedMessages = errorMessage.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return <>{formattedMessages}</>;
};

const Ul = styled.ul`
  padding-left: 10px;
  list-style-type: disc;
  padding-inline-start: 30px;
`;

const LowerLi = styled.li`
  margin-top: 15px;
`;

const TheButton = styled(Button)`
  width: fit-content;
  margin-right: 10px;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const LabelErrorDescription = styled.div`
  font-weight: bold;
  padding: 4px;
  color: ${props => props.theme.redColor};
`;

const LogTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  caption {
    text-align: left;
    padding: 4px;
    font-weight: bold;
  }
`;

const LogHeader = styled.th`
  background-color: #ddd;
  padding: 5px;
  text-align: left;
`;

const LogHeaderWide = styled(LogHeader)`
  width: 40%;
`;

const LogData = styled.td`
  padding: 5px;
`;

const LogRow = styled.tr`
  &:nth-child(odd) {
    background-color: #f2f2f2;
  }
`;

const LogColumn = styled(LogData)`
  width: 50px;
`;

const ErrorMessageColumn = styled(LogData)`
  width: 300px;
  white-space: normal;
  word-wrap: break-word;
`;

export default InfomartExternal;
