import { observable } from 'mobx';

export type ProjectShareMappingArgs = {
  id: number;
  project_id: number;
  project?: any;
  project_share_id: number;
  weight: number;
};

export default class ProjectShareMapping {
  @observable
  id: number;

  @observable
  projectId: number;

  @observable
  projectShareId: number;

  @observable
  weight: number;

  @observable
  project: any;

  constructor(args: ProjectShareMappingArgs) {
    this.id = args.id;
    this.projectId = args.project_id;
    this.project = args.project;
    this.projectShareId = args.project_share_id;
    this.weight = args.weight;
  }

  setProjectId = (value: number) => {
    this.projectId = value;
    app.render();
  };

  setWeight = (value: number) => {
    this.weight = value;
    app.render();
  };

  submitParams() {
    return {
      id: this.id,
      project_id: this.projectId,
      project_share_id: this.projectShareId,
      weight: this.weight
    };
  }
}
