import type { Moment as _Moment } from 'moment';
import _moment from 'moment';

export type Moment = _Moment;

_moment.locale('ja', {
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
});

export default _moment;
