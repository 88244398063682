import React from 'react';
import _ from 'lodash';

import { styled } from '@this/constants/themes';

import type SearchQuery from '../../../../domain/search_query';

import { InLabel, FieldsWrapper, Field, InputArea, SelectWrap, Select } from '../../search/search';
import { Block, Body } from '../search_block/search_block.template';

type Props = {
  query: SearchQuery;
  handleChange: (name: 'roomnum' | 'smoke' | 'breakfast') => (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SearchHotelOptionBlockTemplate: React.SFC<Props> = ({ query, handleChange }) => (
  <Block className="search__block">
    <Body>
      <FieldsWrapper className="search__block__body__fields-wrapper">
        <Field>
          <InLabel htmlFor="hotel_option">ホテル詳細</InLabel>
          <InputArea wrapped>
            <SelectRoomWrap>
              <Select name="roomnum" value={query.roomnum} onChange={handleChange('roomnum')}>
                {_.times(8, i => (
                  <option value={i + 1} key={i}>{`${i + 1}部屋`}</option>
                ))}
              </Select>
            </SelectRoomWrap>
            <SelectWrap>
              <Select name="smoke" value={query.smoke} onChange={handleChange('smoke')}>
                <option value="">煙草指定なし</option>
                <option value="no">禁煙室</option>
                <option value="yes">喫煙室</option>
              </Select>
            </SelectWrap>
            <SelectWrap>
              <Select name="breakfast" value={query.breakfast} onChange={handleChange('breakfast')}>
                <option value="">朝食指定なし</option>
                <option value="no">朝食なし</option>
                <option value="yes">朝食あり</option>
              </Select>
            </SelectWrap>
          </InputArea>
        </Field>
      </FieldsWrapper>
    </Body>
  </Block>
);

const SelectRoomWrap = styled.div`
  flex-basis: 44px;
`;

export default SearchHotelOptionBlockTemplate;
