import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

import Trip from '@this/domain/trip/trip';
import User from '@this/src/domain/user/user';
import TravelerList from '@this/src/domain/traveler/traveler_list';
import Traveler from '@this/domain/traveler/traveler';
import { Fetcher } from '@this/src/util';
import DraftTripsFormTemplate from '../draft_trips_form.template';

interface Props {}

export interface DraftTripsState {
  trip: any;
  user: any;
  members: any;
  travelers: any;
  finalDestination: string;
  purpose: string;
  signedIn: boolean;
  loading: boolean;
  validationErrors: any;
  outdate: Moment;
  homedate: Moment;
  error: string | null;
}

interface DraftTripsEditResponse {
  trip: any;
  user: any;
  members: any[];
}

interface TravelerErrors {
  memberSelect?: string | null;
  travelerFirstNameRomen?: string | null;
  travelerLastNameRomen?: string | null;
  travelerFirstNameKana?: string | null;
  travelerLastNameKana?: string | null;
  travelerBirthday?: string | null;
  travelerGender?: string | null;
}

interface Errors {
  finalDestination: string | null;
  purpose: string | null;
  travelers: TravelerErrors[];
}

class DraftTripsEdit extends React.Component<Props, DraftTripsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      trip: null,
      user: null,
      members: [],
      travelers: [],
      finalDestination: '',
      purpose: '',
      signedIn: false,
      loading: true,
      validationErrors: {},
      outdate: moment().add(1, 'days'),
      homedate: moment().add(2, 'days'),
      error: null
    };

    this.handleRemoveTraveler = this.handleRemoveTraveler.bind(this);
    this.handleTravelerTypeChange = this.handleTravelerTypeChange.bind(this);
    this.handleTravelerSelect = this.handleTravelerSelect.bind(this);
    this.handleTravelerInfoChange = this.handleTravelerInfoChange.bind(this);
    this.handleAddTraveler = this.handleAddTraveler.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeFinalDestination = this.handleChangeFinalDestination.bind(this);
    this.handleChangePurpose = this.handleChangePurpose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    Fetcher.get<DraftTripsEditResponse>(`${location.href}.json`).then(
      result => {
        const trip = new Trip(result.trip);
        const user = new User(result.user);
        const members = _.map(result.members, m => new User(m));
        const travelers = TravelerList.fromTravelerInformations({
          informations: trip.travelerInformations,
          user,
          members,
          peoplenum: trip.travelerInformations.list.length
        });
        this.setState({
          trip,
          user,
          members,
          travelers,
          outdate: moment(trip.departure_time),
          homedate: moment(trip.return_time),
          finalDestination: trip.final_destination,
          purpose: trip.purpose,
          signedIn: true,
          loading: false
        });
      },
      () => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  handleTravelerTypeChange(i: number, e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'self') {
      this.state.travelers.setTravelerAtIndex(i, new Traveler(this.state.user));
    } else {
      this.state.travelers.setTravelerTypeAtIndex(i, e.target.value);
    }
  }

  handleTravelerSelect(i: number, traveler: any) {
    this.state.travelers.setTravelerAtIndex(i, traveler);
  }

  handleTravelerInfoChange(i: number, method: string, e: React.ChangeEvent<HTMLInputElement>) {
    this.state.travelers.list[i][method](e.target.value);
  }

  handleAddTraveler(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.state.travelers.addTraveler(this.state.user);
  }

  handleRemoveTraveler(i: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.state.travelers.removeTravelerAtIndex(i);
  }

  handleChangeFinalDestination(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ finalDestination: e.target.value });
  }

  handleChangePurpose(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({ purpose: e.target.value });
  }

  handleChangeDate(kind: 'outdate' | 'homedate', date: Moment) {
    let outMoment = this.state.outdate;
    let homeMoment = this.state.homedate;

    switch (kind) {
      case 'outdate': {
        outMoment = date;
        if (date >= homeMoment) {
          homeMoment = moment(outMoment).add(1, 'day');
        }
        break;
      }
      case 'homedate': {
        homeMoment = date;
        if (date <= outMoment) {
          outMoment = moment(homeMoment).subtract(1, 'day');
        }
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      outdate: outMoment,
      homedate: homeMoment
    });
  }

  handleSubmit(e: React.MouseEvent<HTMLElement>) {
    const validationErrors = this.validationErrors();
    if (_.isEmpty(validationErrors)) {
      this.submit();
    } else {
      e.preventDefault();
      this.setState({ validationErrors });
    }
  }

  submit() {
    Fetcher.put(`/draft_trips/${this.state.trip.id}`, this.submitParams()).then(
      () => {
        location.href = '/trips';
      },
      () => {
        this.setState({
          error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        });
      }
    );
  }

  submitParams() {
    return {
      travelers: this.state.travelers.getTravelersParam(),
      departure_time: this.state.outdate,
      return_time: this.state.homedate,
      purpose: this.state.purpose,
      final_destination: this.state.finalDestination
    };
  }

  validationErrors() {
    const errors: Errors = {
      finalDestination: DraftTripsEdit.requiredError('出張先', this.state.finalDestination),
      purpose: DraftTripsEdit.requiredError('出張の目的', this.state.purpose),
      travelers: this.state.travelers.list.map((t: any) => {
        const tError: TravelerErrors = {};
        if (utils.dig(this.state.user, 'organization')) {
          switch (t.type) {
            case 'self':
            case 'member': {
              if (!t.id) {
                tError.memberSelect = '出張者を選択してください';
              }
              break;
            }
            case 'companion': {
              tError.travelerFirstNameRomen = DraftTripsEdit.alphabetError(
                '出張者の名前(ローマ字)',
                t.firstNameRoman
              );
              tError.travelerLastNameRomen = DraftTripsEdit.alphabetError(
                '出張者の名字(ローマ字)',
                t.lastNameRoman
              );
              tError.travelerFirstNameKana = DraftTripsEdit.katakanaError('出張者の名前(カナ)', t.firstNameKana);
              tError.travelerLastNameKana = DraftTripsEdit.katakanaError('出張者の名字(カナ)', t.lastNameKana);
              tError.travelerBirthday = DraftTripsEdit.dateError('出張者の誕生日', t.flightBirthday);
              tError.travelerGender = DraftTripsEdit.requiredError('出張者の性別', t.flightGender);
              break;
            }
            default: {
              break;
            }
          }
        }
        return tError;
      })
    };

    errors.travelers = _.map(errors.travelers, te => utils.compactObject(te));
    errors.travelers = utils.compactObject(errors.travelers);
    return utils.compactObject(errors);
  }

  static requiredError(name: string, value: string, message = 'を入力してください') {
    if (!value || value.length === 0) {
      return name + message;
    }
    return null;
  }

  static katakanaError(name: string, value: string) {
    const error = DraftTripsEdit.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[ァ-ヶー　]*$/)) {
      return `${name}はカタカナで入力してください`;
    }
    return null;
  }

  static alphabetError(name: string, value: string) {
    const error = DraftTripsEdit.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!value.match(/^[a-zA-Z]+$/)) {
      return `${name}はアルファベッドで入力してください`;
    }
    return null;
  }

  static dateError(name: string, value: string) {
    const error = DraftTripsEdit.requiredError(name, value);
    if (error) {
      return error;
    }
    if (!moment(value).isValid()) {
      return `${name}を正しく入力してください（例：1986-01-01）`;
    }
    return null;
  }

  render() {
    try {
      return (
        <DraftTripsFormTemplate
          {...this.state}
          handleRemoveTraveler={this.handleRemoveTraveler}
          handleTravelerTypeChange={this.handleTravelerTypeChange}
          handleTravelerSelect={this.handleTravelerSelect}
          handleTravelerInfoChange={this.handleTravelerInfoChange}
          handleAddTraveler={this.handleAddTraveler}
          handleChangeDate={this.handleChangeDate}
          handleChangeFinalDestination={this.handleChangeFinalDestination}
          handleChangePurpose={this.handleChangePurpose}
          handleSubmit={this.handleSubmit}
        />
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default DraftTripsEdit;
