import React, { useMemo } from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import {
  Button,
  IconButton,
  Paper,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@material-ui/core';
import type Project from '@this/src/domain/project/project';
import type { OrderItemCategoryOptions } from '@this/src/domain/order_item';
import type { OrderItemPriceDetailForReport } from '@this/src/domain/trip_report/order_item_price_detail';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { BodyTitle } from '../../../shared/wrappers';

type Props = {
  categoryOptions: OrderItemCategoryOptions;
  projectOptions: Project[];
  priceDetails: OrderItemPriceDetailForReport[];
};

export const TripReportTripSelectedOrderItems: React.FC<Props> = ({
  categoryOptions,
  projectOptions,
  priceDetails
}) => {
  const projectable = useMemo(() => projectOptions.length > 0, [projectOptions]);

  return (
    <OrderItemSection>
      <BodyTitle>システムで予約した商品（{priceDetails.length}件）</BodyTitle>
      <TableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell>ジャンル</TableCell>
              <TableCell>日程</TableCell>
              <TableCell>詳細</TableCell>
              <TableCell>合計金額</TableCell>
              {projectable && <TableCell>プロジェクト</TableCell>}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {priceDetails.map(priceDetail => (
              <TableRow key={priceDetail.id}>
                <TableCell className="spWrap">
                  <StyledChip
                    label={
                      categoryOptions.find(([_, value]) => value === priceDetail.orderItemCategory)?.[0] ||
                      '未定義'
                    }
                  />
                </TableCell>
                <TableCell className="spWrap">{priceDetail.getPeriod()}</TableCell>
                <TableCell>{priceDetail.name}</TableCell>
                <TableCell>{priceDetail.price.toLocaleString()}円</TableCell>
                {projectable && (
                  <ProjectCell>
                    <ProjectRows>
                      {priceDetail.projects.map((project, i) => (
                        <ProjectRow key={i}>
                          <ProjectSelect
                            value={project.projectId?.toString() || ''}
                            onChange={e =>
                              project.setProject(
                                projectOptions.find(option => option.id.toString() === e.target.value)
                              )
                            }
                          >
                            <option value="">プロジェクトを選択</option>
                            {projectOptions.map(project => (
                              <option key={project.id} value={project.id.toString()}>
                                {project.code} - {project.name}
                              </option>
                            ))}
                          </ProjectSelect>
                          <ProjectRemoveButton color="primary" onClick={() => priceDetail.removeProject(i)}>
                            <RemoveIcon />
                          </ProjectRemoveButton>
                        </ProjectRow>
                      ))}
                      {priceDetail.projects.length < projectOptions.length && (
                        <Button
                          color="primary"
                          size="small"
                          startIcon={<AddCircleIcon />}
                          onClick={() => priceDetail.addProject()}
                        >
                          プロジェクトを追加する
                        </Button>
                      )}
                    </ProjectRows>
                  </ProjectCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </OrderItemSection>
  );
};

const OrderItemSection = styled.div``;

const StyledTable = styled(Table)`
  ${media.sp`
    display: block;

    .spDisable {
      display: none;
    }

    .MuiTableHead-root {
      display: none;
    }

    .MuiTableBody-root {
      display: flex;
      flex-flow: column;
    }

    .MuiTableRow-root {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      padding: 16px;
      gap: 8px 16px;
      border-bottom: solid 1px #eee;
    }

    .MuiTableCell-root {
      border: 0;
      padding: 0;
      width: 100%;

      &.spWrap {
        width: auto;
      }

      &:last-child {
        padding-top: 8px;
        border-top: solid 1px #eee;
      }

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }
    }
  `}
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.grayBgColorLight};

  .MuiTableCell {
    &-head {
      padding: 8px 16px;
      min-width: 120px;
    }
  }

  ${media.sp`
    display: none;
  `}
`;

const StyledChip = styled(Chip)`
  height: auto;
  padding: 4px 0;
`;

const ProjectCell = styled(TableCell)`
  padding: 8px 16px;
`;

const ProjectRows = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 8px;
`;

const ProjectRow = styled.div`
  display: flex;
`;

const ProjectSelect = styled.select`
  padding: 4px;
  border-color: #ccc;
  border-radius: 4px;
  margin-bottom: 0;
`;

const ProjectRemoveButton = styled(IconButton)`
  padding: 8px;
`;
