export interface MessageLogArgs {
  arranger_name: string;
  body: string;
  created_at: string;
}

export class MessageLog {
  arrangerName: string;

  body: string;

  createdAt: string;

  constructor(args: MessageLogArgs) {
    this.arrangerName = args.arranger_name;
    this.body = args.body;
    this.createdAt = args.created_at;
  }
}
