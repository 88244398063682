import Project from '../project/project';
import type { OrderItemPriceDetailProjectArgs } from './trip_args';

export class OrderItemPriceDetailProject {
  id?: number;

  projectId?: number;

  proportion?: number;

  project: Project | null;

  constructor(args: Partial<OrderItemPriceDetailProjectArgs>) {
    this.id = args.id;
    this.projectId = args.project_id ?? args.project?.id;
    this.proportion = args.proportion;
    this.project = args.project ? new Project(args.project) : null;
  }

  setProject(project?: Project) {
    if (project) {
      this.projectId = project.id;
      this.project = project;
    } else {
      this.projectId = undefined;
      this.project = null;
    }
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      project_id: this.projectId,
      proportion: this.proportion
    };
  }
}
