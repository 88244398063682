import React from 'react';
import {
  LineSection,
  Section
} from '@this/components/reserve_trip/reserve_confirm/routings/input_customer_information/trip_rule_section/trip_rule_error_section';
import type TripRuleDistanceTimeInfo from '@this/domain/trip/trip_rule_distance_time_info';

interface Props {
  tripRuleDistanceTimeInfo: TripRuleDistanceTimeInfo;
  showAlert: boolean;
}

const TripRuleSuccessSection: React.FC<Props> = ({ tripRuleDistanceTimeInfo, showAlert }) => {
  return (
    <Section>
      <p>■{tripRuleDistanceTimeInfo.ruleDistanceTimeInfoText()}</p>
      {showAlert && tripRuleDistanceTimeInfo.detail.lines?.length && (
        <LineSection>
          {tripRuleDistanceTimeInfo.detail.lines.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </LineSection>
      )}
    </Section>
  );
};

export default TripRuleSuccessSection;
