import React, { useMemo } from 'react';
import { Divider, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { styled } from '@this/constants/themes';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { Approval } from '@this/src/domain/trip_report/approval';
import { media } from '../../../shared/atoms/media';

type Props = {
  approvalGroups: Approval[][];
  totalAmount: number;
};

const ProjectInformations: React.FC<Props> = ({ approvalGroups, totalAmount }) => {
  const store = useTripReportStore();
  const budgets = useMemo(() => store.approvalGroupBudgets(approvalGroups), [approvalGroups]);

  return (
    <>
      {approvalGroups.map((approvals, i) => {
        const { total, budget, remainingBudget, percent, isOver } = budgets[i];

        return (
          <React.Fragment key={`approvals-${i}`}>
            <SectionRow>
              <SectionRowLeft>
                <Typography>{approvals[0].groupName}</Typography>
                <Typography variant="body2">
                  {approvals
                    .map(approval => approval.approvers.map(approver => approver.name).join('、'))
                    .join('、')}
                </Typography>
              </SectionRowLeft>
              <SectionRowRight>
                {budget && budget.totalBudget ? (
                  <>
                    {isOver && (
                      <PriceWarn>
                        <WarningIcon />
                        このプロジェクトは予算をオーバーしています
                      </PriceWarn>
                    )}
                    <PriceRow>
                      <PriceLabel>消化予算：</PriceLabel>
                      <PriceTotal red={isOver}>
                        ¥ {total.toLocaleString()}
                        <PricePercent>({percent}%使用)</PricePercent>
                      </PriceTotal>
                    </PriceRow>
                    <PriceRow>
                      <PriceLabel>残り予算：</PriceLabel>
                      <PriceLabel>¥ {remainingBudget?.toLocaleString()}</PriceLabel>
                    </PriceRow>
                    <PriceRow>
                      <PriceLabel>全体予算：</PriceLabel>
                      <PriceLabel>¥ {budget.totalBudget.toLocaleString()}</PriceLabel>
                    </PriceRow>
                  </>
                ) : (
                  <>
                    <PriceWarn>
                      <WarningIcon />
                      このプロジェクトは予算が設定されていません
                    </PriceWarn>
                    <PriceRow>
                      <PriceLabel>小計：</PriceLabel>
                      <PriceTotal>¥ {total.toLocaleString()}</PriceTotal>
                    </PriceRow>
                  </>
                )}
              </SectionRowRight>
            </SectionRow>
            <SectionDivider />
          </React.Fragment>
        );
      })}
      <SectionRow>
        <SectionRowLeft>
          <Typography style={{ fontWeight: 'bold' }}>全ての出張の合計金額</Typography>
        </SectionRowLeft>
        <SectionRowRight>
          <Total>¥ {totalAmount.toLocaleString()}</Total>
        </SectionRowRight>
      </SectionRow>
    </>
  );
};

const SectionRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${media.sp`
    flex-flow: column;
    gap: 16px;
  `};
`;

const SectionDivider = styled(Divider)`
  &:last-child {
    display: none;
  }
`;

const SectionRowLeft = styled.div``;

const SectionRowRight = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 4px;

  ${media.sp`
    align-self: flex-start;
  `};
`;

const PriceWarn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px;
  color: ${props => props.theme.redColor};
`;

const PriceRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  gap: 4px;
`;

const PriceLabel = styled.label`
  font-size: 0.8em;
  font-weight: normal;
  color: ${props => props.theme.grayTextColor};
`;

const PriceTotal = styled.div<{ red?: boolean }>`
  font-size: 1.1em;
  font-weight: bold;
  color: ${props => props.theme.grayTextColor};

  ${props => (props.red ? `color: ${props.theme.redColor};` : '')}
`;

const PricePercent = styled.span`
  font-size: 0.75em;
  font-weight: normal;
  margin-left: 8px;
`;

const Total = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;

export default ProjectInformations;
