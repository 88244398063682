import React from 'react';
import _ from 'lodash';
import { css } from 'styled-components';
import { styled } from '@this/constants/themes';
import { ButtonBase, ButtonType } from '@this/shared/atoms/button';

import SearchableSelect from '@this/shared/searchable_select';
import { doubleDigits } from '@this/src/util';
import type { FlightQuerySetting, FlightType, InternationalAirline, InternationalAirport } from './types';

interface Props {
  flightQuerySettings: FlightQuerySetting[];
  internationalAirports: InternationalAirport[];
  internationalAirlines: InternationalAirline[];
  onChange: (values: FlightQuerySetting[]) => void;
}

interface State {
  flightQuerySettings: FlightQuerySetting[];
}

export default class FlightQuerySettingArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { flightQuerySettings: this.props.flightQuerySettings };
  }

  addQuerySetting = () => {
    const { flightQuerySettings } = this.state;
    flightQuerySettings.push({
      origin: '',
      destination: '',
      base_hour: '',
      base_min: '',
      base_spent_min: 0,
      carriers: [''],
      carrier_weights: [0],
      flight_type: ''
    });
    this.setState({ flightQuerySettings });
  };

  removeQuerySetting = (index: number) => {
    const { flightQuerySettings } = this.state;
    flightQuerySettings.splice(index, 1);
    this.setState({ flightQuerySettings });
  };

  addCarrier = (i: number) => {
    const { flightQuerySettings } = this.state;
    flightQuerySettings[i].carriers.push('');
    flightQuerySettings[i].carrier_weights.push(0);
    this.setState({ flightQuerySettings });
  };

  get internationalAirportList() {
    const airports = this.props.internationalAirports.map(airport => ({
      value: airport.iata,
      displayName: airport.name_ja
    }));
    const cities = this.props.internationalAirports
      .filter(airport => airport.city_code)
      .map(airport => ({
        value: `${airport.city_code}(citycode)`,
        displayName: `${airport.city_code}(citycode)`
      }))
      .filter((airport, index, self) => self.findIndex(a => a.value === airport.value) === index);
    return airports.concat(cities);
  }

  get internationalAirlineList() {
    return this.props.internationalAirlines.map(airport => ({ value: airport.code, displayName: airport.name }));
  }

  handleChangeOrigin = (index: number, value: string) => {
    this.handleChange({ index, value, attrName: 'origin' });
  };

  handleChangeDestination = (index: number, value: string) => {
    this.handleChange({ index, value, attrName: 'destination' });
  };

  handleChangeHour = (index: number, value: string) => {
    this.handleChange({ index, value, attrName: 'base_hour' });
  };

  handleChangeMin = (index: number, value: string) => {
    this.handleChange({ index, value, attrName: 'base_min' });
  };

  handleChangeBaseSpentMin = (index: number, value: number) => {
    this.handleChange({ index, value, attrName: 'base_spent_min' });
  };

  handleChangeCarriers = (index: number, index2: number, value: string) => {
    const carriers = this.state.flightQuerySettings[index].carriers;
    carriers[index2] = value;
    this.handleChange({ index, value: carriers, attrName: 'carriers' });
  };

  handleChangeCarrierWeights = (index: number, index2: number, value: number) => {
    const carrier_weights = this.state.flightQuerySettings[index].carrier_weights;
    carrier_weights[index2] = value;
    this.handleChange({ index, value: carrier_weights, attrName: 'carrier_weights' });
  };

  removeCarrier = (index: number, index2: number) => {
    const carriers = this.state.flightQuerySettings[index].carriers;
    const carrier_weights = this.state.flightQuerySettings[index].carrier_weights;
    carriers.splice(index2, 1);
    carrier_weights.splice(index2, 1);
    this.handleChange({ index, value: carriers, attrName: 'carriers' });
    this.handleChange({ index, value: carrier_weights, attrName: 'carrier_weights' });
  };

  handleChangeFlightType = (index: number, flightType: FlightType) => {
    this.handleChange({ index, value: flightType, attrName: 'flight_type' });
  };

  handleChange({
    index,
    value,
    attrName
  }:
    | {
        index: number;
        value: string;
        attrName: 'origin' | 'destination';
      }
    | {
        index: number;
        value: string;
        attrName: 'base_hour';
      }
    | {
        index: number;
        value: string;
        attrName: 'base_min';
      }
    | {
        index: number;
        value: number;
        attrName: 'base_spent_min';
      }
    | {
        index: number;
        value: string[];
        attrName: 'carriers';
      }
    | {
        index: number;
        value: number[];
        attrName: 'carrier_weights';
      }
    | {
        index: number;
        value: FlightType;
        attrName: 'flight_type';
      }) {
    const settings = this.state.flightQuerySettings;
    const setting = settings[index];

    (setting[attrName] as string | number | string[] | number[]) = value;
    this.setState({ flightQuerySettings: settings });
    this.props.onChange(settings);
  }

  render() {
    const { flightQuerySettings } = this.state;

    return (
      <Container>
        {flightQuerySettings.length > 0 && (
          <Table>
            <thead>
              <tr>
                <Th>出発地</Th>
                <Th>目的地</Th>
                <Th width="130px">航路</Th>
                <Th width="130px">基準到着時刻</Th>
                <Th width="120px">基準所要時間</Th>
                <Th>航空会社 / 検索重みづけ</Th>
                <Th width="72px" />
              </tr>
            </thead>
            <tbody>
              {this.state.flightQuerySettings.map((s, i) => (
                <tr key={i}>
                  <Td>
                    <SearchableSelect
                      list={this.internationalAirportList}
                      currentValue={s.origin}
                      onChange={(v: string) => this.handleChangeOrigin(i, v)}
                    />
                  </Td>
                  <Td>
                    <SearchableSelect
                      list={this.internationalAirportList}
                      currentValue={s.destination}
                      onChange={(v: string) => this.handleChangeDestination(i, v)}
                    />
                  </Td>
                  <Td>
                    <CheckboxLabel>
                      <select
                        value={s.flight_type || ''}
                        onChange={e => this.handleChangeFlightType(i, e.target.value as FlightType)}
                      >
                        <option value="">すべて</option>
                        <option value="C">乗り継ぎのみ</option>
                        <option value="N">直行便のみ</option>
                      </select>
                    </CheckboxLabel>
                  </Td>
                  <Td>
                    <BaseTimeWrapper>
                      <select value={s.base_hour} onChange={e => this.handleChangeHour(i, e.target.value)}>
                        <option value="">-時</option>
                        {_.times(24, i => (
                          <option value={doubleDigits(i)} key={i}>{`${i}時`}</option>
                        ))}
                      </select>
                      <select value={s.base_min} onChange={e => this.handleChangeMin(i, e.target.value)}>
                        <option value="">-分</option>
                        {_.times(6, i => (
                          <option value={doubleDigits(i * 10)} key={i}>{`${i * 10}分`}</option>
                        ))}
                      </select>
                    </BaseTimeWrapper>
                  </Td>
                  <Td>
                    <BaseTimeWrapper>
                      <BaseSpentMinInput
                        type="text"
                        value={s.base_spent_min}
                        onChange={v => this.handleChangeBaseSpentMin(i, parseInt(v.target.value, 10) || 0)}
                      />
                      分
                    </BaseTimeWrapper>
                  </Td>
                  <Td>
                    {s.carriers.map((c: string, j: number) => (
                      <CarrierSelectArea key={j}>
                        <StyledSearchableSelect
                          list={this.internationalAirlineList}
                          currentValue={c}
                          onChange={v => this.handleChangeCarriers(i, j, v)}
                        />
                        <WeightInput
                          type="text"
                          value={s.carrier_weights[j]}
                          onChange={v => this.handleChangeCarrierWeights(i, j, parseInt(v.target.value, 10) || 0)}
                        />
                        <RemoveCarrierButton onClick={() => this.removeCarrier(i, j)}>+</RemoveCarrierButton>
                      </CarrierSelectArea>
                    ))}
                    <LinkButton onClick={() => this.addCarrier(i)}>+ 航空会社を追加</LinkButton>
                  </Td>
                  <Td>
                    <Button buttonType={ButtonType.DANGER} onClick={() => this.removeQuerySetting(i)}>
                      削除
                    </Button>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Button onClick={this.addQuerySetting}>航路を追加</Button>
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 10px 0 10px 30px;
`;

const Table = styled.table`
  margin-top: 0;
  margin-bottom: 10px;
`;

const ColumnBase = css`
  vertical-align: top;
  padding: 10px;
`;

const Th = styled.th<{ width?: string }>`
  ${ColumnBase};

  ${({ width }) => width && `width: ${width}`}
`;

const Td = styled.td`
  ${ColumnBase};
`;

const BaseTimeWrapper = styled.div`
  display: flex;
  padding-top: 6px;
`;

const LinkButton = styled.a`
  display: block;
  margin-top: 5px;
  cursor: pointer;
`;

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase};
  padding: 0.75em 1em;
`;

const StyledSearchableSelect = styled(SearchableSelect)``;
const CarrierSelectArea = styled.div`
  position: relative;
  align-items: center;
  margin-bottom: 8px;
  display: flex;

  ${StyledSearchableSelect} {
    width: 80%;
  }
`;

const BaseSpentMinInput = styled.input`
  &&& {
    width: 50px;
    margin-right: 5px;
  }
`;

const WeightInput = styled.input`
  &&& {
    margin-left: 10px;
    width: 10%;
  }
`;

const RemoveCarrierButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 6px;
  right: 0;
  color: #7d7d7d;
  border-radius: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  transform: rotate(45deg);
  transition: opacity 0.3s;
  font-size: 16px;
  outline: none;

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
    color: #7d7d7d;
  }
`;

const CheckboxLabel = styled.label`
  padding-top: 6px;
`;
