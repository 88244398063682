import { observable } from 'mobx';
import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';
import type ElementBaseInterface from './element_base_interface';

const travelAuthorizationServices = {
  visa: 'ビザ',
  esta: 'ESTA'
};

export type TravelAuthorizationServiceType = keyof typeof travelAuthorizationServices;

export interface TravelAuthorizationElementArgs {
  id?: number;
  order_item_id?: number;
  service?: TravelAuthorizationServiceType;
  content?: string;
  startedAt?: Moment;
  endedAt?: Moment;
}

export default class TravelAuthorizationElement implements ElementBaseInterface {
  readonly id?: number;

  readonly type: string;

  orderItemId?: number;

  @observable
  service?: TravelAuthorizationServiceType;

  @observable
  content: string;

  @observable
  startedAt: Moment;

  @observable
  endedAt: Moment;

  constructor(args: TravelAuthorizationElementArgs) {
    this.id = args.id;
    this.type = 'travel_authorization';
    this.orderItemId = args.order_item_id;
    this.service = args.service;
    this.content = args.content || '';
    this.startedAt = args.startedAt ? moment(args.startedAt) : moment();
    this.endedAt = args.endedAt ? moment(args.endedAt) : moment();
  }

  title() {
    return '査証・渡航認証関連';
  }

  startDate() {
    return null;
    // return moment();
  }

  endDate() {
    return null;
  }

  description() {
    return `<${this.title()}>
    ■サービス：${this.service && travelAuthorizationServices[this.service]}
    ■内容
    ${this.content}
    ■開始日時：${this.startedAt.format('YYYY/MM/DD HH:mm:ss')}
    ■終了日時：${this.endedAt.format('YYYY/MM/DD HH:mm:ss')}
    `;
  }

  summary() {
    return `[査証] ${this.service && travelAuthorizationServices[this.service]}`;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  setField<T extends keyof this>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  validationErrors(): string[] {
    const errors = [];
    if (!this.service) errors.push('査証・渡航認証関連のサービスを入力してください');
    if (!this.content) errors.push('査証・渡航認証関連の内容を入力してください');
    if (this.endedAt < this.startedAt) errors.push('終了日は開始日以降を指定してください');
    return errors;
  }

  updateParams() {
    return {
      type: this.type,
      order_item_id: this.orderItemId,
      service: this.service,
      content: this.content,
      started_at: this.startedAt ? moment(this.startedAt).format('YYYY-MM-DD HH:mm:ss') : null,
      ended_at: this.endedAt ? moment(this.endedAt).format('YYYY-MM-DD HH:mm:ss') : null
    };
  }
}
