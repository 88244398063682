import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { calculateAge } from '@this/domain/age_util';
import type TransportElement from '@this/domain/transport_element';
import { FormField, InputGroup, TextInput } from './order_item_traveler.style';
import type { Traveler } from './type';

export type TravelerInputsFormData = {
  firstNameKana: Traveler['firstNameKana'];
  lastNameKana: Traveler['lastNameKana'];
  firstNameRoman: Traveler['firstNameRoman'];
  lastNameRoman: Traveler['lastNameRoman'];
  middleNameRoman: Traveler['middleNameRoman'];
  gender: Traveler['flightGender'];
  birthday: Traveler['birthday'];
};

type Props = {
  formData: TravelerInputsFormData | null;
  transportDate: string | null;
  onChange: (formData: TravelerInputsFormData) => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
  transportType: TransportElement['transportType'] | null;
};
type State = TravelerInputsFormData;

const OrderItemTravelerInputsForm: React.FC<Props> = ({
  formData,
  transportDate,
  onChange,
  classNameForModifiedField,
  transportType
}) => {
  const [state, setState] = useState<State>(
    formData
      ? { ...formData }
      : {
          firstNameKana: '',
          lastNameKana: '',
          firstNameRoman: '',
          lastNameRoman: '',
          middleNameRoman: '',
          gender: 'male',
          birthday: ''
        }
  );
  const onChangeInput = (value: any, key: keyof State) => {
    const newState = { ...state };
    newState[key] = value;
    setState(newState);

    onChange(newState);
  };

  return (
    <div>
      <FormField>
        <label
          className={`
            ${classNameForModifiedField('lastNameRoman')}
            ${classNameForModifiedField('firstNameRoman')}
          `}
        >
          氏名(ローマ字)
        </label>
        <InputGroup>
          {transportType === 'foreign_air' ? (
            <>
              <NameInputShort
                type="text"
                placeholder="姓(ローマ字)"
                value={state.lastNameRoman}
                onChange={e => onChangeInput(e.target.value, 'lastNameRoman')}
              />
              <NameInputShort
                type="text"
                placeholder="ミドルネーム(ローマ字)"
                value={state.middleNameRoman}
                onChange={e => onChangeInput(e.target.value, 'middleNameRoman')}
              />
              <NameInputShort
                type="text"
                placeholder="名(ローマ字)"
                value={state.firstNameRoman}
                onChange={e => onChangeInput(e.target.value, 'firstNameRoman')}
              />
            </>
          ) : (
            <>
              <NameInput
                type="text"
                placeholder="姓(ローマ字)"
                value={state.lastNameRoman}
                onChange={e => onChangeInput(e.target.value, 'lastNameRoman')}
              />

              <NameInput
                type="text"
                placeholder="名(ローマ字)"
                value={state.firstNameRoman}
                onChange={e => onChangeInput(e.target.value, 'firstNameRoman')}
              />
            </>
          )}
        </InputGroup>
      </FormField>
      <FormField>
        <label
          className={`
            ${classNameForModifiedField('lastNameKana')}
            ${classNameForModifiedField('firstNameKana')}
          `}
        >
          氏名(カナ)
        </label>
        <InputGroup>
          <NameInput
            type="text"
            placeholder="姓(カナ)"
            value={state.lastNameKana}
            onChange={e => onChangeInput(e.target.value, 'lastNameKana')}
          />
          <NameInput
            type="text"
            placeholder="名(カナ)"
            value={state.firstNameKana}
            onChange={e => onChangeInput(e.target.value, 'firstNameKana')}
          />
        </InputGroup>
      </FormField>
      <FormField>
        <label className={classNameForModifiedField('birthday')}>誕生日</label>
        <TextInput
          type="text"
          placeholder="例) 2020-04-01"
          value={state.birthday}
          onChange={e => onChangeInput(e.target.value, 'birthday')}
        />
      </FormField>
      <FlightAge>
        <label>搭乗時年齢</label>
        {state.birthday && transportDate && (
          <span>{`${calculateAge(state.birthday, transportDate).toString()}歳`}</span>
        )}
      </FlightAge>
      <FormField>
        <label className={classNameForModifiedField('gender')}>性別</label>
        <InputGroup>
          <label>
            <input
              type="radio"
              value="male"
              name="gender"
              checked={state.gender === 'male'}
              onChange={e => onChangeInput(e.target.value, 'gender')}
            />
            男性
          </label>
          <label>
            <input
              type="radio"
              value="female"
              name="gender"
              checked={state.gender === 'female'}
              onChange={e => onChangeInput(e.target.value, 'gender')}
            />
            女性
          </label>
        </InputGroup>
      </FormField>
    </div>
  );
};

export const NameInput = styled(TextInput)`
  width: 110px !important;
`;

export const NameInputShort = styled(TextInput)`
  width: 70px !important;
`;

const FlightAge = styled.div`
  margin-bottom: 0.75em;
`;

export default OrderItemTravelerInputsForm;
