class MarginType
  @defaultBaseAmount = 1100

  @baseTypes =
    'fixed': '固定'
    'percentage': '歩合'

  @calcDefaultMarginAmount: (peopleNum, sectionNum = 1) ->
    MarginType.defaultBaseAmount * peopleNum * sectionNum

  constructor: (args) ->
    args = snakeToCamelObject(args)
    _.assign @, args

    @baseType ||= 'fixed'
    @baseAmount = MarginType.defaultBaseAmount unless typeof args.baseAmount == 'number'
    @category ||= 'domestic'

  setBaseType: (value) ->
    @baseType = value
    app.render()

  setBaseAmount: (value) ->
    @baseAmount = value
    app.render()

  baseTypeText: ->
    MarginType.baseTypes[@baseType]

  baseAmountSuffix: ->
    switch @baseType
      when 'fixed'
        '円'
      when 'percentage'
        '％'

  baseAmountText: ->
    @baseAmount + @baseAmountSuffix()

  submitParams: ->
    id: @id
    base_type: @baseType
    base_amount: @baseAmount
    category: @category

  calcMarginAmount: (price, peopleNum, sectionNum = 1) ->
    target = if @baseType == 'fixed' then peopleNum else price
    base = if @baseType == 'fixed' then @baseAmount * sectionNum else @baseAmount / 100.0
    _.ceil target * base

  calcBulkTicketFee: (ticket) ->
    if @baseType == 'fixed'
      @baseAmount
    else
      ticket.price * @baseAmount / 100

  describe: (price, peopleNum, sectionNum = 1) ->
    switch @baseType
      when 'fixed'
        formatPrice(@baseAmount * sectionNum) + ' × ' + peopleNum + '名'
      when 'percentage'
        formatPrice(price) + ' × ' + @baseAmount + '％'

  amount: (price, sectionNum = 1) ->
    switch @baseType
      when 'fixed'
        @baseAmount * sectionNum
      when 'percentage'
        price

  isDomestic: ->
    @category == 'domestic'

  isForeign: ->
    @category == 'foreign'

module.exports = MarginType
