import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type Hotel from '@this/domain/hotel/hotel';
import type HotelList from '@this/domain/hotel/hotel_list';
import type SelectRepository from '@this/domain/select_repository';
import DestMarker from './dest_marker';
import HotelMarker from './hotel_marker';
import HotelIconMarker from './hotel_icon_marker';
import HotelDetailOnMap from './hotel_detail_on_map';

interface Props {
  height?: string;
  destLocation?: { lat: number; lng: number } | null;
  hotel?: Hotel | null;
  hotels?: HotelList;
  serviceId?: number;
  hotelPriceLimit?: number | null;
  searchQueryId: number | null;
  onHotelSelect?: (i: number) => void;
  onChange?: () => void;
  repository?: SelectRepository;
}

interface State {
  visibleDetail: boolean;
  detailTargetHotel: Hotel | null;
}

class HotelMap extends React.Component<Props, State> {
  map: google.maps.Map | null;

  ref: React.RefObject<HTMLDivElement>;

  destMarker: DestMarker | null;

  hotelIconMarker: HotelIconMarker | null;

  boundsAdjusted: boolean;

  markers: { [key: string]: HotelMarker };

  constructor(props: Props) {
    super(props);
    this.map = null;
    this.ref = React.createRef<HTMLDivElement>();
    this.destMarker = null;
    this.hotelIconMarker = null;
    this.boundsAdjusted = false;
    this.markers = {};
    this.state = {
      visibleDetail: false,
      detailTargetHotel: null
    };
  }

  componentDidMount() {
    this.map = this.createMap();
    this.updateMap();
    if (this.props.repository) {
      this.addCustomBotton();
    }
  }

  componentDidUpdate() {
    this.updateMap();
  }

  createMap() {
    if (google && this.ref.current) {
      return new google.maps.Map(this.ref.current, {
        zoom: 14,
        center: new google.maps.LatLng(35.681247, 139.766709),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        streetViewControl: false
      });
    }
    return null;
  }

  updateMap() {
    this.reloadMarkers();
    if (this.hotelIconMarker && !this.boundsAdjusted && this.map) {
      this.map.setCenter(this.hotelIconMarker.location);
    } else if (this.props.destLocation && !this.boundsAdjusted && this.map) {
      this.map.setCenter(this.props.destLocation);
    }
    this.adjustBounds();
  }

  addCustomBotton() {
    const controlDiv = document.createElement('div');
    const controlButton = document.createElement('button');

    // Set CSS for the control.
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = '2px solid #fff';
    controlButton.style.borderRadius = '3px';
    controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    controlButton.style.color = 'rgb(25,25,25)';
    controlButton.style.cursor = 'pointer';
    controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
    controlButton.style.fontSize = '14px';
    controlButton.style.lineHeight = '38px';
    controlButton.style.margin = '8px 0 22px';
    controlButton.style.padding = '0 5px';
    controlButton.style.textAlign = 'center';

    controlButton.textContent = 'このエリアを検索';
    controlButton.title = 'このエリアを検索';
    controlButton.type = 'button';

    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener('click', () => {
      if (this.map != null) {
        const latlng = this.map.getCenter();
        this.props.repository?.reSearchHotel(latlng.lat(), latlng.lng());
        this.map.setCenter(latlng);
      }
    });

    controlDiv.appendChild(controlButton);
    this.map?.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }

  reloadMarkers() {
    const { destLocation, hotels, serviceId } = this.props;
    if (!google) {
      return;
    }
    if (!this.destMarker && destLocation) {
      this.destMarker = new DestMarker(destLocation, serviceId || 1);
    }
    if (this.destMarker) {
      this.destMarker.setMap(this.map);
    }
    if (!this.hotelIconMarker && this.props.hotel) {
      if (this.props.hotel.latitude && this.props.hotel.longitude) {
        this.hotelIconMarker = new HotelIconMarker({
          lat: this.props.hotel.latitude,
          lng: this.props.hotel.longitude
        });
      }
    }
    if (this.hotelIconMarker) {
      this.hotelIconMarker.setMap(this.map);
    }
    if (typeof hotels === 'undefined') {
      return;
    }

    const hotelList = hotels.filteredList;
    const inStockHotels = hotelList.filter(h => !h.sold_out).filter(h => h.latitude && h.longitude);
    const newMarkers: { [key: string]: HotelMarker } = {};
    _.each(inStockHotels, h => {
      if (!h.id) {
        return;
      }
      const m =
        this.markers[h.id] ||
        new HotelMarker(h, hotels, serviceId || 1, hotel => this.handleClickHotelMarker(hotel));
      m.selected = hotels.currentId === h.id;
      m.showDetail = this.state.detailTargetHotel?.id === h.id;
      m.setMap(this.map);
      newMarkers[h.id] = m;
      this.markers[h.id] = m;
    });

    _.each(this.markers, (marker, hotelId) => {
      if (!newMarkers[hotelId]) {
        marker.setMap(null);
      }
    });

    this.markers = newMarkers;
  }

  adjustBounds() {
    if (this.boundsAdjusted || !this.map || this.hotelIconMarker) {
      return;
    }
    this.boundsAdjusted = true;
    let centerLat = 0;
    let centerLng = 0;
    let count = 0;

    // Focus on top rated hotels
    const topMarkers = _.values(this.markers).slice(0, 5);
    _.each(topMarkers, m => {
      if (m.location) {
        centerLat += m.location.lat();
        centerLng += m.location.lng();
        count += 1;
      }
    });

    if (this.props.destLocation) {
      centerLat += this.props.destLocation.lat;
      centerLng += this.props.destLocation.lng;
      count += 1;
    }
    const centerLatLng = new google.maps.LatLng(centerLat / count, centerLng / count);
    this.map.setCenter(centerLatLng);

    let i = 0;
    const mapEventListener = google.maps.event.addListener(this.map, 'bounds_changed', () => {
      if (this.map) {
        this.boundsAdjusted = true;
        const bounds = this.map.getBounds();
        if (!bounds) {
          return;
        }
        const m = topMarkers[i];
        if (!m) {
          return;
        }
        const location = m.location;
        if (!location) {
          return;
        }
        if (this.destMarker && this.destMarker.location) {
          if (!bounds.contains(this.destMarker.location)) {
            this.map.setZoom(this.map.getZoom() - 1);
            return;
          }
        }
        if (bounds.contains(location)) {
          i += 1;
          if (i >= topMarkers.length) {
            google.maps.event.removeListener(mapEventListener);
          } else {
            google.maps.event.trigger(this.map, 'bounds_changed');
          }
        } else {
          this.map.setZoom(this.map.getZoom() - 1);
        }
      }
    });
  }

  handleClickHotelMarker(hotel: Hotel) {
    if (hotel) {
      this.setState({ visibleDetail: true });
      if (this.state.detailTargetHotel !== hotel) {
        this.setState({ detailTargetHotel: hotel });
      }
    }
  }

  handleCloseHotelDetail() {
    this.setState({ visibleDetail: false });
    this.setState({ detailTargetHotel: null });
    this.reloadMarkers();
  }

  handleHotelSelect(hotel: Hotel) {
    if (this.props.onChange) {
      this.props.onChange();
    }
    if (!_.isNil(hotel.orderedIndex) && this.props.onHotelSelect) {
      this.props.onHotelSelect(hotel.orderedIndex);
    }
    this.setState({ visibleDetail: false });
    this.setState({ detailTargetHotel: null });
    this.reloadMarkers();
  }

  render() {
    const { height } = this.props;
    return (
      <Wrapper>
        <Map height={height} ref={this.ref} />
        {this.state.detailTargetHotel && this.props.hotels && (
          <Overlay>
            <HotelDetailOnMap
              hotel={this.state.detailTargetHotel}
              hotels={this.props.hotels}
              hotelPriceLimit={this.props.hotelPriceLimit || null}
              searchQueryId={this.props.searchQueryId}
              selected={this.props.hotels.currentId === this.state.detailTargetHotel.id}
              visible={this.state.visibleDetail}
              onClose={() => this.handleCloseHotelDetail()}
              handleHotelSelect={hotel => this.handleHotelSelect(hotel)}
            />
          </Overlay>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 58px);
  height: fit-content;
  margin: 4px;
  z-index: 2;
`;

const Map = styled.div<{ height?: string }>`
  flex-grow: 99999;
  flex-basis: 300px;
  height: ${props => props.height || '300px'};
`;

export default HotelMap;
