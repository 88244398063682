import React from 'react';
import _ from 'lodash';

import SearchBlock from './search_block/search_block';
import SearchTravelersBlock from './search_travelers_block/search_travelers_block';
import SearchPeoplenumBlock from './search_peoplenum_block/search_peoplenum_block.template';

import type { SearchTemplateProps } from './types';

const SearchOneWayTemplate: React.SFC<SearchTemplateProps> = ({ query, user, availableOptions, handleChange }) => (
  <div>
    {query.items.map((item, i) => (
      <SearchBlock key={i} index={i} item={item} user={user} availableOptions={availableOptions} />
    ))}
    {user ? (
      <SearchTravelersBlock query={query} user={user} handleChange={handleChange} />
    ) : (
      <SearchPeoplenumBlock query={query} handleChange={handleChange} />
    )}
  </div>
);

export default SearchOneWayTemplate;
