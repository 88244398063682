import React from 'react';

import InputBase from '@material-ui/core/InputBase';
import MuiIconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';

import { styled } from '@this/constants/themes';

interface Props {
  message: string;
  isAutoFocus: boolean;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const ChatbotFooter: React.FC<Props> = ({
  message,
  isAutoFocus,
  disabled,
  onChange,
  onSubmit,
  onKeyDown
}) => {
  return (
    <ExpandedFooter id="chat-form" onSubmit={onSubmit}>
      <InputBase
        fullWidth
        multiline
        autoFocus={isAutoFocus}
        rowsMax={8}
        disabled={disabled}
        placeholder="ご要望をご入力ください"
        value={message}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <IconButton type="submit" disabled={disabled}>
        <SendIcon />
      </IconButton>
    </ExpandedFooter>
  );
};

const ExpandedFooter = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 4px 4px 16px;
  width: 100%;
  min-height: 70px;
  border-top: 1px solid #ececec;

  & > *:first-child {
    // チャットを開く際にPlaceholderが改行されてしまうのを防ぐ
    min-width: 240px;
  }

  & textarea {
    background-color: ${props => props.theme.grayBgColorLight};
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 11.5px;
    padding-bottom: 8.5px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

const IconButton = styled(MuiIconButton)``;
