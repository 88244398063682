import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { rgba } from 'polished';
import type Arranger from '@this/src/domain/arranger/arranger';
import type OrderItemStepTodo from '@this/src/domain/arrangement/order_item_step_todo';
import type { OrderItemStepAction } from '@this/src/domain/arrangement/order_item_step_todo';
import { ORDER_ITEM_STEP_ACTIONS } from '@this/src/domain/arrangement/order_item_step_todo';
import { OrderItemStepStatusEnums } from '@this/src/domain/order_item/order_item_step_status';
import { OrderItemCategory } from '@this/src/domain/order_item/order_item_category';
import { styled } from '@this/src/components/constants/themes';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import TripStatusIndicator from '../../shared/trip_status_indicator';
import LogModal from '../log_modal';

interface Props {
  arranger: Arranger;
  todo: OrderItemStepTodo;
  loading: boolean;
  orderItemVisible: boolean;
  onTodoAction: (todo: OrderItemStepTodo, action: OrderItemStepAction) => void;
  onMessageFinish: (messageId: number) => void;
  onWorkDialogOpen: (todo: OrderItemStepTodo) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSequenceOpen: (todo: OrderItemStepTodo) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSequenceAbortOpen: (
    todo: OrderItemStepTodo
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onArrangerOpen: (todo: OrderItemStepTodo) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  assignType: string;
}

interface State {
  showLogModal: boolean;
}

const OrderItemStepTodoTableRow = observer(
  ({
    arranger,
    todo,
    loading,
    orderItemVisible,
    onTodoAction,
    onMessageFinish,
    onWorkDialogOpen,
    onSequenceOpen,
    onSequenceAbortOpen,
    onArrangerOpen,
    assignType
  }: Props) => {
    const [state, setState] = useState<State>({ showLogModal: false });
    const { showLogModal } = state;
    const allowChangeArranger = useMemo(
      () => ['queue', 'todo', 'started', 'suspended'].includes(todo.stepStatus),
      [todo.stepStatus]
    );

    return (
      <TableRow style={{ background: todo.isUrgent ? rgba('red', 0.3) : 'auto' }}>
        <TableCell nowrap>
          <TripStatusIndicator status={todo.tripStatus} />
        </TableCell>
        <TableCell>
          {todo.orderItemId && (
            <OrderItemStatusForm
              orderItemId={todo.orderItemId}
              jobType={todo.jobType}
              status={todo.status}
              onJobTypeChange={value => {
                todo.jobType = value;
              }}
              onStatusChange={value => {
                todo.status = value;
              }}
              showLabel={false}
            />
          )}
        </TableCell>
        <TableCell nowrap>{todo.category !== undefined && OrderItemCategory[todo.category]}</TableCell>
        <TableCell>
          {todo.todoType === 'order_item_step' && (
            <TextWithChanged>
              {todo.sequenceName}
              <Button size="small" color="sub" loading={loading} onClick={onSequenceOpen(todo)}>
                変更
              </Button>
              <Button size="small" color="danger" loading={loading} onClick={onSequenceAbortOpen(todo)}>
                破棄
              </Button>
            </TextWithChanged>
          )}
        </TableCell>
        <TableCell>{todo.stepNum}</TableCell>
        <TableCell>{OrderItemStepStatusEnums[todo.stepStatus]}</TableCell>
        <TableCell>
          {todo.todoType === 'order_item_step' && (
            <TextWithChanged>
              {todo.assignedToName ?? '未アサイン'}
              <Button
                size="small"
                color="sub"
                loading={loading}
                disabled={!allowChangeArranger}
                onClick={onArrangerOpen(todo)}
              >
                変更
              </Button>
            </TextWithChanged>
          )}
        </TableCell>
        <TableCell>{todo.organizationName}</TableCell>
        <TableCell nowrap>
          <Link
            href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`}
            target="_blank"
            rel="noopener noreffer"
          >
            {todo.tripId}
          </Link>
        </TableCell>
        <TableCell nowrap>{todo.traceId}</TableCell>
        {orderItemVisible && (
          <>
            <TableCell>{todo.sp ? '◯' : ''}</TableCell>
            <TableCell>{todo.useQr ? '◯' : ''}</TableCell>
            <TableCell>{todo.premiumSupport ? '◯' : ''}</TableCell>
            <TableCell>{todo.waiting ? '◯' : ''}</TableCell>
            <TableCell>
              {todo.lastUserMessage?.split('\n').map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </TableCell>
            <TableCell>
              {todo.lastLogMessage && <span>{todo.lastLogMessage}</span>}
              {todo.lastLogMessageArranger && <span>({todo.lastLogMessageArranger})</span>}
              <p>
                (<Link onClick={() => setState(state => ({ ...state, showLogModal: true }))}>ログ</Link>)
              </p>
            </TableCell>
          </>
        )}
        <TableCell>{todo.durationString()}</TableCell>
        {assignType !== 'unassigned' && <TableCell>{todo?.assignedDuration}</TableCell>}
        <TableCell>
          <Actions>
            {todo.todoType === 'message' && todo.lastUserMessageId && (
              <Button
                size="small"
                loading={loading}
                onClick={() => todo.lastUserMessageId && onMessageFinish(todo.lastUserMessageId)}
              >
                対応不要
              </Button>
            )}
            {todo.actions(arranger).map(
              action =>
                action !== 'abort' && (
                  <Button key={action} size="small" loading={loading} onClick={() => onTodoAction(todo, action)}>
                    {ORDER_ITEM_STEP_ACTIONS[action]}
                  </Button>
                )
            )}
            {todo.stepStatus === 'started' && (
              <Button size="small" loading={loading} onClick={onWorkDialogOpen(todo)}>
                作業
              </Button>
            )}
          </Actions>
        </TableCell>
        {showLogModal && todo.traceId && (
          <LogModal
            traceId={todo.traceId}
            onClose={() => setState(state => ({ ...state, showLogModal: false }))}
          />
        )}
      </TableRow>
    );
  }
);

const TextWithChanged = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export default OrderItemStepTodoTableRow;
