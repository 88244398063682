import type { BulkActionAttr } from '@this/components/organization/types';

const CREATE_FORMATS: BulkActionAttr[] = [
  {
    name: '利用日',
    summary: '利用日',
    example: '例)2020-01-01',
    required: true
  },
  {
    name: '出発日',
    summary: '出発日',
    example: '例)2020-01-01',
    required: false
  },
  {
    name: '帰着日',
    summary: '帰着日',
    example: '例)2020-01-01',
    required: false
  },
  {
    name: '社員番号',
    summary: '社員マスタと関連付ける場合は登録が必要です',
    example: '1234',
    required: false
  },
  {
    name: '社員名',
    summary: 'dashboardに表示する社員名',
    example: '例) 田中太郎',
    required: true
  },
  {
    name: '金額',
    summary: '商材の価格情報',
    example: '10000',
    required: true
  },
  {
    name: 'プロジェクトコード',
    summary: 'プロジェクトマスタと関連付ける場合は登録が必要です',
    example: 'project111',
    required: false
  },
  {
    name: 'プロジェクト名',
    summary: 'dashboardに表示するプロジェクト名',
    example: 'テストプロジェクト',
    required: true
  },
  {
    name: '部署コード',
    summary: '部署マスタと関連付ける場合は登録が必要です',
    example: 'department111',
    required: false
  },
  {
    name: '部署名',
    summary: 'dashboardに表示する部署名',
    example: 'テスト部署',
    required: true
  },
  {
    name: '品目',
    summary:
      '「ホテル, 国内ホテル, 海外ホテル, 国内航空券, 海外航空券, 新幹線・特急, レンタカー, 航空券パッケージ, 配送料, WiFi, 会議室, バス, キャンセル」のいずれかの値を入力ください。それ以外の値は「その他」で集計します',
    example: '国内航空券',
    required: true
  }
];

export { CREATE_FORMATS };
