import React from 'react';

import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import { getColor } from '@this/shared/ui/theme';

const headerHeight = '50px';

interface Props {}

interface State {}

class AdminHeader extends React.Component<Props, State> {
  render() {
    return (
      <TheHeader className="noprint">
        <HeaderIn>
          <h1>
            <Logo to="/admin" />
          </h1>
          <LinkArea>
            <Button id="password" href="/admin/edit">
              パスワードの変更
            </Button>
            <Button id="logout" href="/admins/sign_out">
              ログアウト
            </Button>
          </LinkArea>
        </HeaderIn>
      </TheHeader>
    );
  }
}

const TheHeader = styled.header`
  width: 100%;
  height: ${headerHeight};
`;

const HeaderIn = styled.div`
  display: flex;
  align-items: center;
  background: ${getColor('background', 'tertiary')};
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  height: ${headerHeight};
`;

const Logo = styled(Link)`
  display: block;
  width: 120px;
  height: 21px;
  margin-left: 10px;
  background-image: url('${props => props.theme.logoPath}');
  background-repeat: no-repeat;
  background-size: contain;
`;

const LinkArea = styled.div`
  display: flex;
  margin-left: auto;
`;

const Button = styled.a`
  display: block;
  margin-right: 15px;
  color: ${getColor('brand', 'primary')};
  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.linkFocusColorLight};
  }
`;

export default AdminHeader;
