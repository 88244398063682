import React from 'react';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import TripStatus from '@this/domain/trip/trip_status';
import moment from 'moment/moment';
import { styled } from '@this/constants/themes';

interface Props {
  message: Message;
}
const StatusLog: React.FC<Props> = ({ message }) => {
  return (
    <ArrangerName>
      {message.current &&
        message.previous &&
        `・${message.arranger_name}が旅程のステータスを「${TripStatus[message.previous]}」から「${
          TripStatus[message.current]
        }」に変更しました。 (${moment(message.created_at).format('MM/DD HH:mm')})`}
    </ArrangerName>
  );
};

const ArrangerName = styled.div`
  margin-bottom: 5px;
`;
export default StatusLog;
