import _ from 'lodash';

interface Args {
  name: string;
  price: number;
  distance: number;
}

class MarketLogCandidate {
  public name: string;

  public price: number;

  public distance: number;

  constructor(args?: Args) {
    this.name = (args && args.name) || '';
    this.price = (args && args.price) || 0;
    this.distance = (args && args.distance) || 0;
  }

  distanceDesc() {
    const d = Math.ceil(this.distance);
    return `${d}m`;
  }
}

export default MarketLogCandidate;
