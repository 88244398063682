import type { OrganizationResourceArgs } from './organization_resource';
import { OrganizationResource } from './organization_resource';

export interface OrganizationRoleArgs {
  id: number;
  name: string;
  organization_role_resources: OrganizationResourceArgs[];
}

export default class OrganizationRole {
  id: number;

  name: string;

  resources: OrganizationResource[];

  constructor(args: OrganizationRoleArgs) {
    this.id = args.id;
    this.name = args.name;
    this.resources = args.organization_role_resources.map(resource => {
      return new OrganizationResource(resource);
    });
  }
}

export interface OrganizationRoleResponse {
  roles: OrganizationRoleArgs[];
}
