template = require('./arrangement_reports.template').ArrangementReportsTemplate
qs = require('qs')
Fetcher = require('../../../util/fetcher').Fetcher

dateFormat = 'YYYY-MM-DD'

module.exports = createReactClass
  getInitialState: ->
    {
      initialFilterData: null
      loading: false
      items: []
      times: []
    }

  componentDidMount: ->
    @fetchItems()

  componentWillReceiveProps: (nextProps) ->
    # クエリパラメータが変更されたら再検索する
    return if nextProps.location.search == @props.location.search

    @fetchItems()

  fetchItems: ->
    params = @searchParams()
    fromDate = params.fromDate
    toDate = params.toDate
    mode = params.mode

    @setState(
      loading: true
      initialFilterData: {
        fromDate: fromDate,
        toDate: toDate,
        groupBy: mode
      }
    )

    Fetcher.get("/admin/arrangement_reports", {
      from: fromDate.format(dateFormat)
      to: toDate.format(dateFormat)
      mode: mode
    }).then (result) =>
      @setState
        items: result.items
        loading: false
        times: _.map(result.times, (t) -> { value: moment(t.value), str: t.str } )

  searchParams: (searchMode) ->
    params = getParams()
    fromStr = dig(params, 'from')
    toStr = dig(params, 'to')
    mode = searchMode || dig(params, 'mode') || 'day'
    from = null
    to = null

    switch mode
      when 'week'
        if fromStr
          from = @validateWeekDay(moment(fromStr), true)
        else
          from = moment().startOf('month')

        if toStr
          to = @validateWeekDay(moment(toStr), false)
        else
          to = from.clone().add(6, 'd')

      when 'month'
        if fromStr
          from = moment(fromStr).startOf('month')
        else
          from = moment().subtract(1, 'month').startOf('month')

        if toStr
          to = moment(toStr).endOf('month')
        else
          to = from.clone().endOf('month')

      else
        from = if fromStr then moment(fromStr) else moment().startOf('month')
        to = if toStr then moment(toStr) else moment().endOf('month')
    {
      mode
      fromDate: from
      toDate: to
    }

  validateWeekDay: (date, start) ->
    if start
      if date.isSame(date.clone().startOf('month'), 'day')
        date
      else
        d = date.clone().startOf('isoWeek')
        if d.month() < date.month() then date.startOf('month') else d
    else
      if date.isSame(date.clone().endOf('month'), 'day')
        date
      else
        d = date.clone().endOf('isoWeek')
        if date.month() < d.month() then date.endOf('month') else d

  handleFilterSubmit: (data) ->
    from = moment(data.fromDate)
    to = moment(data.toDate)

    @props.history.push({
      search: qs.stringify({
        from: from.format(dateFormat),
        to: to.format(dateFormat),
        mode: data.groupBy
      })
    })

  render: ->
    try
      template({
        loading: @state.loading
        initialFilterData: @state.initialFilterData
        reportItems: @state.items
        reportTimes: @state.times
        onFilterSubmit: @handleFilterSubmit
      })
    catch e
      sendErrorObject e
