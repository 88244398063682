import React, { useMemo, useState } from 'react';
import { Button as MuiButton, Collapse, TableCell, TableRow } from '@material-ui/core';
import { Button } from '@this/shared/ui/inputs/button';
import { styled } from '@this/constants/themes';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as NavLink } from '@this/shared/ui/navigations/link';
import type { TripReport } from '@this/src/domain/trip_report/trip_report';
import { STATUS_NAMES as APPROVAL_STATUS } from '@this/src/domain/trip_report/approval';
import { useTripReportStore } from '@this/domain/trip_report/trip_report_store';
import StatusWorkflow from './approvals/status_workflow';

type Props = {
  tripReport: TripReport;
  message?: string;
  allowanceAvailable: boolean;
};

const TripReportRow: React.FC<Props> = ({ tripReport, message }) => {
  const [open, setOpen] = useState(false);
  const approval = tripReport.currentApproval;
  const trip = tripReport.tripSummary();
  const store = useTripReportStore();
  const allowanceAvailable = store.allowanceAvailable;
  const statusName = useMemo(
    () =>
      tripReport.status === 'approved'
        ? 'approved'
        : approval?.status === 'approved'
        ? 'nextStage'
        : approval?.status || 'queued',
    [tripReport.status, approval?.status]
  );

  return (
    <>
      {message && (
        <StyledRow className="active">
          <TableCell colSpan={allowanceAvailable ? 10 : 9}>{message}</TableCell>
        </StyledRow>
      )}
      <StyledRow className={message ? 'active' : ''}>
        <TableCell>
          <MuiButton endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={() => setOpen(!open)}>
            <StatusColor className={approval?.status}>
              {statusName === 'nextStage' ? '確認中' : APPROVAL_STATUS[approval?.status || 'queued']}
            </StatusColor>
          </MuiButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {approval && <StatusWorkflow tripReport={tripReport} approval={approval} />}
          </Collapse>
        </TableCell>
        <TableCell>
          <Title href={`/trip_report/approvals/${tripReport.id}`}>{tripReport.name}</Title>
          {trip?.tripId && <Description>旅程番号：{trip.tripId}</Description>}
          <Description>出張報告番号：{tripReport.id}</Description>
          {trip?.to && <Description>出張先：{trip.to}</Description>}
        </TableCell>
        <TableCell>{tripReport.userName}</TableCell>
        <TableCell>
          {trip?.startDate && trip.startDate.format('YYYY/MM/DD')}
          {trip?.startDate?.format('YYYY/MM/DD') !== trip?.endDate?.format('YYYY/MM/DD') &&
            trip?.endDate &&
            ` ~ ${trip.endDate.format('YYYY/MM/DD')}`}
        </TableCell>
        <TableCell>{tripReport.totalPrice().toLocaleString()}円</TableCell>
        <TableCell>{tripReport.totalAdvance().toLocaleString()}円</TableCell>
        {allowanceAvailable && <TableCell>{tripReport.totalAllowance().toLocaleString()}円</TableCell>}
        <TableCell>
          <strong>{tripReport.totalAmount()}</strong>
        </TableCell>
        <TableCell>─</TableCell>
        <TableCell>
          {approval?.status === 'approved' ? (
            `${approval.approverName}が承認済み`
          ) : (
            <Button size="small" href={`/trip_report/approvals/${tripReport.id}`}>
              確認する
            </Button>
          )}
        </TableCell>
      </StyledRow>
    </>
  );
};

const COLORS = {
  gray: '#6B7280',
  orange: '#FB923C',
  green: '#10B981'
};

const StatusColor = styled.div`
  color: ${COLORS.gray};
  &.applied {
    color: ${COLORS.orange};
  }
  &.approved {
    color: ${COLORS.green};
  }
`;

const Title = styled(NavLink)`
  text-decoration: underline;
`;

const Description = styled.div`
  font-size: 10px;
  color: ${COLORS.gray};
`;

const StyledRow = styled(TableRow)`
  &.active {
    background-color: ${props => props.theme.accentColorLight};
  }
`;

export default TripReportRow;
