import type User from '@this/domain/user/user';

export const setupBugsnag = (user: User) => {
  if (typeof bugsnagClient === 'undefined') {
    return;
  }

  bugsnagClient.user = {
    id: user.id,
    name: user.name,
    email: user.email
  };
};

export const reportError = (e: Error) => {
  if (typeof bugsnagClient === 'undefined') {
    return;
  }

  bugsnagClient.notify(e);
};
