import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';

interface Props {
  message?: string;
  messages?: string[];
  confirm?: string;
  abort?: string;
  onConfirmed?: () => void;
  onAborted?: () => void;
}

/**
 * @deprecated
 * shold use js/src/components/shared/ui/feedbacks/modal/confirm_modal
 */
const Confirm = ({ message, messages, confirm, abort, onConfirmed, onAborted }: Props) => {
  try {
    const lines = messages ?? (message ? message.split('\n') : ['よろしいですか？']);
    const component = (
      <MessageArea>
        <div className="confirm__message">
          {lines.map((line, i) => (
            <p key={i}>{line}</p>
          ))}
        </div>
        <Buttons>
          <Button type="button" onClick={() => onConfirmed && onConfirmed()}>
            {confirm || 'はい'}
          </Button>
          <Button type="button" onClick={() => onAborted && onAborted()}>
            {abort || 'いいえ'}
          </Button>
        </Buttons>
      </MessageArea>
    );
    return component;
  } catch (e) {
    utils.sendErrorObject(e);
    return null;
  }
};

const MessageArea = styled.div`
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  text-align: right;
`;

const Button = styled.button`
  &&& {
    ${ButtonBase};
    display: inline-block;
    margin-left: 10px;
  }
`;

export default Confirm;
