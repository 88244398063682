import type User from '../domain/user/user';

export const setupMixpanel = (user: User | null) => {
  if (typeof mixpanel === 'undefined') {
    // wait for mixpanel loading
    setTimeout(() => {
      setupMixpanel(user);
    }, 100);
  } else if (user) {
    if (mixpanel.get_distinct_id) {
      mixpanel.alias(user.id.toString(), mixpanel.get_distinct_id());
    }
    mixpanel.identify(user.id.toString());
    mixpanel.people.set({
      name: user.name,
      $first_name: user.firstName,
      $last_name: user.lastName,
      first_name_roman: user.firstNameRoman,
      last_name_roman: user.lastNameRoman,
      $email: user.email,
      tel: user.tel,
      gender: user.gender,
      gmoId: user.gmoId,
      birthday: user.birthday,
      organization_id: user.organization && user.organization.id,
      organization_name: user.organization && user.organization.name
    });
  } else if (mixpanel) {
    mixpanel.identify(mixpanel.get_distinct_id());
    mixpanel.people.set_once({ name: 'anonymous' });
  }
};
