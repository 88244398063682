import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox } from '@this/shared/ui/inputs/checkbox';
import { Fetcher } from '@this/src/util';

interface Props {
  onAcceptTerms: () => void;
}

const ChatbotTerms = observer(({ onAcceptTerms }: Props) => {
  const [checked, setChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    Fetcher.put('/users/accept_chatbot_terms.json', {})
      .then(() => {
        setSubmitting(false);
        onAcceptTerms();
      })
      .catch(() => {
        setSubmitting(false);
        setError('エラーが発生しました');
      });
  };

  return (
    <>
      <h2 style={{ textAlign: 'center', margin: '0.75em 0' }}>AIコンシェルジュご利用上の注意</h2>
      <ul style={{ listStyleType: 'disc', paddingInlineStart: '20px', fontSize: '0.9em' }}>
        <li>
          AIコンシェルジュは、検索や相談事項等について、不適切な表現をする場合や、正しい情報を表示しない場合がございますが、
          その掲載内容の正確性、完全性、信頼性、最新性を保証するものではございません。
        </li>
        <li>
          AIコンシェルジュから紹介された内容を予約する際に限らず、旅程の手配時は、 必ずAI
          Travel上に表示される予約内容をご確認ください。
        </li>
        <li>
          AIコンシェルジュの表示した相談事項等について、確実な情報を必要とする場合には、当社で掲示するご利用ガイド・当社窓口をご活用ください。
        </li>
      </ul>
      <form onSubmit={handleSubmit} style={{ marginTop: '0.75em' }}>
        <label style={{ fontSize: '0.8em' }}>
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
          ご利用上の注意と
          <a href="https://transferdata.co.jp/privacy-policy" target="_blank" rel="noreferrer">
            プライバシーポリシー
          </a>
          を確認のうえ、AIコンシェルジュサービスの利用に同意する。
        </label>
        <div style={{ marginTop: '30px', width: '100%' }}>
          <button type="submit" disabled={!checked || submitting} style={{ width: '100%' }}>
            同意する
          </button>
          {error && (
            <div className="error" style={{ marginTop: '10px', fontSize: '0.8em' }}>
              {error}
            </div>
          )}
        </div>
      </form>
    </>
  );
});

export default ChatbotTerms;
