import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import useApi from '@this/shared/hooks/use_api';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import SuppliedItemMappingTabs from '@this/components/admin/supplied_item_mappings/share/supplied_item_mapping_tabs';
import { Fetcher } from '@this/src/util';
import { Title } from '../admin';
import { AdminContentIn } from '../shared/atom';

interface Response {
  categories: {
    id: number;
    ticketing_type: number;
    ticketing_type_text: string;
    status: number;
    status_text: string;
    supplied_item_id: number;
  }[];
  supplied_items: {
    id: number;
    supplier_name: string;
    name: string;
  }[];
}

interface SuppliedItem {
  id: number;
  supplierName: string;
  name: string;
}

const IndividualDomesticAirSuppliedItems = () => {
  const [savingTicketingType, setSavingTicketingType] = useState(9);
  const [savingStatus, setSavingStatus] = useState(9);
  const [reloadFlag, setReloadFlag] = useState(0);
  const params = { reloadFlag };
  const { data, isLoading } = useApi<Response>('/admin/individual_domestic_air_supplied_items.json', params);

  const categories = data ? data.categories : [];
  const suppliedItems: SuppliedItem[] = data
    ? data.supplied_items.map(item => ({ id: item.id, supplierName: item.supplier_name, name: item.name }))
    : [];

  const saveMapping = useCallback(
    (id: number, ticketingType: number, status: number, suppliedItemId: number | null) => {
      setSavingTicketingType(ticketingType);
      setSavingStatus(status);
      const saveParams = {
        supplied_item_id: suppliedItemId,
        ticketing_type: ticketingType,
        status
      };
      Fetcher.put(`/admin/individual_domestic_air_supplied_items/${id}.json`, saveParams)
        .catch(e => {
          alert(
            utils.dig(e, 'responseJSON', 'message') ||
              '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          );
        })
        .finally(() => {
          setSavingTicketingType(9);
          setSavingStatus(9);
          setReloadFlag(reloadFlag + 1);
        });
    },
    [reloadFlag]
  );
  return (
    <div>
      <Title>仕入商品マッピング</Title>
      <AdminContentIn>
        <Description>発券方法に対するデフォルトの仕入商品を設定できます。</Description>
        <SuppliedItemMappingTabs />
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Table>
            <thead>
              <tr>
                <Th>発券方法</Th>
                <Th>成功/失敗</Th>
                <Th>仕入商品</Th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, i) => (
                <tr key={i}>
                  <Td>{category.ticketing_type_text}</Td>
                  <Td>{category.status_text}</Td>
                  <Td>
                    {savingTicketingType === category.ticketing_type && savingStatus === category.status ? (
                      <SimpleLoading size={18} />
                    ) : (
                      <Select
                        value={category.supplied_item_id || ''}
                        onChange={e =>
                          saveMapping(
                            category.id,
                            category.ticketing_type,
                            category.status,
                            e.target.value === '' ? null : parseInt(e.target.value, 10)
                          )
                        }
                      >
                        <option value="">選択を外す</option>
                        {suppliedItems.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.supplierName}：{item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}{' '}
      </AdminContentIn>
    </div>
  );
};

const Description = styled.div`
  margin-bottom: 40px;
`;

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const Select = styled.select`
  margin: 0;
`;

export default IndividualDomesticAirSuppliedItems;
