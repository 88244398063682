class Project
  constructor: (args = {}, members) ->
    _.assign @, args
    @approveStages =
      @approveStages ||
      (@approval_authorities || []).reduce (approveStages, authority) ->
        workflowType = authority.workflow_type
        stage = authority.approve_stage
        id = authority.approver_id

        if approveStages[workflowType][stage]? && approveStages[workflowType][stage][0] != ''
          approveStages[workflowType][stage].push id
        else
          approveStages[workflowType][stage] = [id]
        approveStages
      ,{ default: { 1: [''] } }
    @members = members

  setCode: (value) ->
    @code = value
    app.render()

  setName: (value) ->
    @name = value
    app.render()

  setDisabled: (value) ->
    @disabled = value
    app.render()

  setApproveStageParam: (multipleApproveStages) ->
    _.mapValues multipleApproveStages, (approveStages) ->
      _.mapValues approveStages, (as) ->
        _.filter as, (a) ->
          a != ""

  updateParams: ->
    code: @code
    name: @name
    approve_stages: @setApproveStageParam @approveStages
    budgets_attributes: @budgets
    disabled: @disabled

  codeAndName: ->
    @code + ':' + @name

  membersNotApprover: (workflowType, stage) ->
    _.filter @members, (m) =>
      !_.includes @approveStages[workflowType][stage], m.id

  setApproverIds: (ids, s, workflowType) ->
    @approveStages[workflowType][s] = if ids then ids else []
    app.render()

module.exports = Project
