import type { AxiosInstance } from 'axios';
import axios from 'axios';

const getCsrfToken = (): string => {
  const metaElem = document.querySelector("meta[name='csrf-token']");
  if (!metaElem) {
    return '';
  }
  const csrfToken = metaElem.getAttribute('content');
  if (!csrfToken) {
    return '';
  }
  return csrfToken;
};

/* eslint-disable no-plusplus */
window.pendingRequestCount = 0;
export const createHttpClient = (): AxiosInstance => {
  const client = axios.create({
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRF-TOKEN': getCsrfToken() }
  });

  client.interceptors.request.use(config => {
    window.pendingRequestCount++;
    return config;
  }, Promise.reject);

  client.interceptors.response.use(
    response => {
      window.pendingRequestCount--;
      return response;
    },
    error => {
      window.pendingRequestCount--;
      return Promise.reject(error);
    }
  );

  return client;
};
/* eslint-enable no-plusplus */
