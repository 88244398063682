import type { AxiosResponse } from 'axios';
import axios from 'axios';

export default class BaseAPI {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected get<T>(path: string, params: { [key: string]: any } = {}): Promise<AxiosResponse<T>> {
    return axios.get<T>(`${this.baseUrl}${path}`, params);
  }

  protected post<T>(path: string, params: { [key: string]: any }): Promise<AxiosResponse<T>> {
    return axios.post<T>(`${this.baseUrl}${path}`, params);
  }

  protected put<T>(path: string, params: { [key: string]: any }): Promise<AxiosResponse<T>> {
    return axios.put<T>(`${this.baseUrl}${path}`, params);
  }

  protected delete<T>(path: string): Promise<AxiosResponse<T>> {
    return axios.delete<T>(`${this.baseUrl}${path}`);
  }
}
