import { observable } from 'mobx';

export interface MasterOrderItemActionArgs {
  id?: number;
  trigger_type: string;
  name: string;
}

class MasterOrderItemAction {
  @observable id?: number;

  @observable triggerType: string;

  @observable name: string;

  constructor(args: MasterOrderItemActionArgs) {
    this.id = args.id;
    this.triggerType = args.trigger_type;
    this.name = args.name;
  }
}

export default MasterOrderItemAction;
