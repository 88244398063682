import _ from 'lodash';
import { observable, action } from 'mobx';
import type { ProjectShareMappingArgs } from './project_share_mapping';
import ProjectShareMapping from './project_share_mapping';

class ProjectShareMappingList {
  @observable
  public list: ProjectShareMapping[];

  constructor(raw: ProjectShareMappingArgs[]) {
    this.list = raw.map(o => new ProjectShareMapping(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  last() {
    return _.last(this.list);
  }

  @action
  push(o: ProjectShareMapping) {
    this.list.push(o);
  }

  @action
  remove(o: ProjectShareMapping) {
    const i = this.list.indexOf(o);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  @action
  update(newOrg: ProjectShareMapping) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }
}

export default ProjectShareMappingList;
