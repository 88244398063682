import type { BulkActionAttr } from './types';

export const UPDATE_FORMAT: BulkActionAttr[] = [
  {
    name: 'department_code',
    summary: '部署コード',
    example: '',
    required: true
  },
  {
    name: 'workflow_type',
    summary: 'ワークフロータイプ',
    example: '',
    required: false
  },
  {
    name: 'approver_1_1',
    summary: '1次承認1人目',
    example: `承認者のメールアドレスを指定します。
例) sato@example.com

「approver_x_y」の形式で列を追加することで、複数の承認者を設定することができます。

例)
1次承認2人目:
  approver_1_2
2次承認1人目:
  approver_2_1
`,
    required: true
  }
];
