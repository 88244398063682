/* eslint-disable max-lines */
import React, { useState } from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Text as DangerText, Text } from '@this/shared/ui/data_displays/typography';
import { getSmartTicketInfo } from '@this/components/arrangement/virtual_counter/smart_ticket_info';
import { getSpacing } from '@this/shared/ui/theme';
import { Flex } from '@this/shared/ui/layout/flex';
import { JsonViewer } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/json_viewer';
import { Button } from '@this/shared/ui/inputs/button';
import type TicketOprReservation from '@this/domain/ticket_opr_reservation';
import { Fetcher, HTTPError } from '@this/src/util';
import type { TicketOprResponseArgs } from '@this/domain/ticket_opr_response';

type Props = {
  className?: string;
  trip: Trip;
  showTicketOprTicketing: boolean;
  ticketOprResponse: TicketOprResponseArgs[];
  onReloadTrip: () => void;
};

interface ReservationState {
  inquiry: {
    loading: boolean;
    time?: string;
    data?: any;
    message?: string;
    reservation?: any;
    status?: 'success' | 'error';
  };
  ticketing: {
    loading: boolean;
    time?: string;
    data?: any;
    message?: string;
    status?: 'success' | 'error';
  };
  downloadTicket: {
    loading: boolean;
    time?: string;
    data?: any;
    message?: string;
    status?: 'success' | 'error';
  };
}

interface Reservations {
  [key: number]: ReservationState;
}

export const TransportInfo = ({
  className,
  trip,
  showTicketOprTicketing,
  onReloadTrip,
  ticketOprResponse
}: Props) => {
  const ticketInfo = React.useMemo(() => getSmartTicketInfo(trip, ticketOprResponse), [trip]);
  const initialReservations: Reservations = {};
  const [results, setResults] = useState<Reservations>(initialReservations);

  const handleTicketOprTicketing = (ticketOprReservation: TicketOprReservation) => {
    const params = {
      ticket_opr_reservation: ticketOprReservation,
      ticket_opr_rsv_id: ticketOprReservation?.ticketOprRsvId
    };

    setResults(prev => {
      const currentReservation = prev[ticketOprReservation.id] || {};
      const currentInquiry = currentReservation.inquiry || {};
      const currentTicketing = currentReservation.ticketing || {};
      const currentDownloadTicket = currentReservation.downloadTicket || {};

      return {
        ...prev,
        [ticketOprReservation.id]: {
          ...currentReservation,
          inquiry: {
            ...currentInquiry
          },
          ticketing: {
            ...currentTicketing,
            loading: true
          },
          downloadTicket: {
            ...currentDownloadTicket
          }
        }
      };
    });

    Fetcher.post('/arrangement/ticket_opr_ticketings/ticketing', params)
      .then((res: any) => {
        if (res.data.code === '200') {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentInquiry = currentReservation.inquiry || {};
            const currentDownloadTicket = currentReservation.downloadTicket || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                inquiry: {
                  ...currentInquiry
                },
                downloadTicket: {
                  ...currentDownloadTicket
                },
                ticketing: {
                  time: res.time,
                  data: res.data,
                  message: `${res.time} 発券依頼が成功しました。結果をお待ちください。`,
                  status: 'success',
                  loading: false
                }
              }
            };
          });
          onReloadTrip();
        } else {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentInquiry = currentReservation.inquiry || {};
            const currentDownloadTicket = currentReservation.downloadTicket || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                inquiry: {
                  ...currentInquiry
                },
                downloadTicket: {
                  ...currentDownloadTicket
                },
                ticketing: {
                  time: res.time,
                  message: `${res.time} 発券依頼が失敗しました。ticketoprを確認ください。`,
                  data: { code: res.data.code, error: res.data.body.error },
                  status: 'error',
                  loading: false
                }
              }
            };
          });
        }
      })
      .catch(e => {
        const errorMessage =
          e instanceof HTTPError && e.response?.data.error.message
            ? e.response.data.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        const errorTime = e instanceof HTTPError && e.response?.data.error.time ? e.response.data.message : '';

        setResults(prev => {
          const currentReservation = prev[ticketOprReservation.id] || {};
          const currentInquiry = currentReservation.inquiry || {};
          const currentDownloadTicket = currentReservation.downloadTicket || {};

          return {
            ...prev,
            [ticketOprReservation.id]: {
              ...currentReservation,
              inquiry: {
                ...currentInquiry
              },
              downloadTicket: {
                ...currentDownloadTicket
              },
              ticketing: {
                time: errorTime,
                message: `${errorTime} 発券依頼が失敗しました。ticketoprを確認ください。`,
                data: {
                  code: e instanceof HTTPError && e.response?.status ? e.response.status : '',
                  error: errorMessage
                },
                status: 'error',
                loading: false
              }
            }
          };
        });
      })
      .finally(() => {});
  };

  const handleTicketOprInquiry = (ticketOprReservation: TicketOprReservation) => {
    const params = {
      ticket_opr_reservation: ticketOprReservation,
      ticket_opr_rsv_id: ticketOprReservation?.ticketOprRsvId
    };
    setResults(prev => {
      const currentReservation = prev[ticketOprReservation.id] || {};
      const currentInquiry = currentReservation.inquiry || {};
      const currentTicketing = currentReservation.ticketing || {};
      const currentDownloadTicket = currentReservation.downloadTicket || {};

      return {
        ...prev,
        [ticketOprReservation.id]: {
          ...currentReservation,
          inquiry: {
            ...currentInquiry,
            loading: true
          },
          ticketing: {
            ...currentTicketing
          },
          downloadTicket: {
            ...currentDownloadTicket
          }
        }
      };
    });

    Fetcher.post('/arrangement/ticket_opr_ticketings/inquiry', params)
      .then((res: any) => {
        if (res.data.code === '200') {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentTicketing = currentReservation.ticketing || {};
            const currentDownloadTicket = currentReservation.downloadTicket || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                ticketing: {
                  ...currentTicketing
                },
                downloadTicket: {
                  ...currentDownloadTicket
                },
                inquiry: {
                  time: res.time,
                  data: res.data,
                  message: `${res.time} ID照会が成功しました。`,
                  reservation: res.data.body?.reservation,
                  status: 'success',
                  loading: false
                }
              }
            };
          });
        } else {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentTicketing = currentReservation.ticketing || {};
            const currentDownloadTicket = currentReservation.downloadTicket || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                ticketing: {
                  ...currentTicketing
                },
                downloadTicket: {
                  ...currentDownloadTicket
                },
                inquiry: {
                  time: res.time,
                  message: `${res.time} ID照会が失敗しました。ticketoprを確認ください。`,
                  data: { code: res.data.code, error: res.data.body.error },
                  status: 'error',
                  loading: false
                }
              }
            };
          });
        }
      })
      .catch(e => {
        const errorMessage =
          e instanceof HTTPError && e.response?.data.error.message
            ? e.response.data.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        const errorTime = e instanceof HTTPError && e.response?.data.error.time ? e.response.data.message : '';

        setResults(prev => {
          const currentReservation = prev[ticketOprReservation.id] || {};
          const currentTicketing = currentReservation.ticketing || {};
          const currentDownloadTicket = currentReservation.downloadTicket || {};

          return {
            ...prev,
            [ticketOprReservation.id]: {
              ...currentReservation,
              ticketing: {
                ...currentTicketing
              },
              downloadTicket: {
                ...currentDownloadTicket
              },
              inquiry: {
                time: errorTime,
                message: `${errorTime} ID照会が失敗しました。ticketoprを確認ください。`,
                data: {
                  code: e instanceof HTTPError && e.response?.status ? e.response.status : '',
                  error: errorMessage
                },
                status: 'error',
                loading: false
              }
            }
          };
        });
      })
      .finally(() => {});
  };

  const handleTicketOprDownloadTicket = (ticketOprReservation: TicketOprReservation) => {
    const params = {
      ticket_opr_reservation: ticketOprReservation,
      ticket_opr_rsv_id: ticketOprReservation?.ticketOprRsvId
    };
    setResults(prev => {
      const currentReservation = prev[ticketOprReservation.id] || {};
      const currentInquiry = currentReservation.inquiry || {};
      const currentTicketing = currentReservation.ticketing || {};
      const currentDownloadTicket = currentReservation.downloadTicket || {};

      return {
        ...prev,
        [ticketOprReservation.id]: {
          ...currentReservation,
          inquiry: {
            ...currentInquiry
          },
          ticketing: {
            ...currentTicketing
          },
          downloadTicket: {
            ...currentDownloadTicket,
            loading: true
          }
        }
      };
    });

    Fetcher.post('/arrangement/ticket_opr_ticketings/download_ticket', params)
      .then((res: any) => {
        if (res.data.code === '200') {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentTicketing = currentReservation.ticketing || {};
            const currentInquiry = currentReservation.inquiry || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                ticketing: {
                  ...currentTicketing
                },
                inquiry: {
                  ...currentInquiry
                },
                downloadTicket: {
                  time: res.time,
                  data: res.data,
                  message: `${res.time} e-ticketの取得が成功しました。`,
                  reservation: res.data?.body?.reservation,
                  status: 'success',
                  loading: false
                }
              }
            };
          });
        } else {
          setResults(prev => {
            const currentReservation = prev[ticketOprReservation.id] || {};
            const currentTicketing = currentReservation.ticketing || {};
            const currentInquiry = currentReservation.inquiry || {};

            return {
              ...prev,
              [ticketOprReservation.id]: {
                ...currentReservation,
                ticketing: {
                  ...currentTicketing
                },
                inquiry: {
                  ...currentInquiry
                },
                downloadTicket: {
                  time: res.time,
                  message: `${res.time} e-ticketの取得が失敗しました。ticketoprを確認ください。`,
                  data: { code: res.data.code, error: res.data.body.error },
                  status: 'error',
                  loading: false
                }
              }
            };
          });
        }
      })
      .catch(e => {
        const errorMessage =
          e instanceof HTTPError && e.response?.data.error.message
            ? e.response.data.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        const errorTime = e instanceof HTTPError && e.response?.data.error.time ? e.response.data.message : '';

        setResults(prev => {
          const currentReservation = prev[ticketOprReservation.id] || {};
          const currentTicketing = currentReservation.ticketing || {};
          const currentInquiry = currentReservation.inquiry || {};

          return {
            ...prev,
            [ticketOprReservation.id]: {
              ...currentReservation,
              ticketing: {
                ...currentTicketing
              },
              inquiry: {
                ...currentInquiry
              },
              downloadTicket: {
                time: errorTime,
                message: `${errorTime} e-ticketの取得が失敗しました。ticketoprを確認ください。`,
                data: {
                  code: e instanceof HTTPError && e.response?.status ? e.response.status : '',
                  error: errorMessage
                },
                status: 'error',
                loading: false
              }
            }
          };
        });
      })
      .finally(() => {});
  };

  return (
    <Root className={className}>
      <Section>
        <Flex flexDirection="column" gap="4px">
          {ticketInfo.travelers.map(t => (
            <div key={t.name}>
              <p>出張者: {t.name}</p>
              <TransportList>
                {t.transports.map(transport => (
                  <>
                    <TransportListItem key={transport.flight}>
                      <Flex gap="4px">
                        <p>予約便: {transport.flight}</p>
                        <p>{transport.status === '予約済み' ? '成功しました' : transport.status}</p>
                      </Flex>
                      {transport.info && (
                        <TransportMessage>
                          {transport.info.split('<br>').map(line => (
                            <p key={line}>{line}</p>
                          ))}
                        </TransportMessage>
                      )}
                      {transport.retryMessage && (
                        <TransportMessage color={transport.status === '予約済み' ? 'description' : 'primary'}>
                          {transport.retryMessage}
                          {transport.status === '予約済み' && <p>リトライに成功しました。</p>}
                        </TransportMessage>
                      )}
                      {transport.errorMessage && (
                        <TransportMessage color="danger">
                          エラーメッセージ: {transport.errorMessage}
                        </TransportMessage>
                      )}
                    </TransportListItem>

                    {showTicketOprTicketing && transport.ticketOprReservation && (
                      <>
                        <>
                          <ActionButton
                            loading={results[transport.ticketOprReservation.id]?.inquiry.loading}
                            onClick={() =>
                              transport.ticketOprReservation &&
                              handleTicketOprInquiry(transport.ticketOprReservation)
                            }
                          >
                            照会する
                          </ActionButton>
                          {results[transport.ticketOprReservation.id]?.inquiry && (
                            <>
                              {results[transport.ticketOprReservation.id].inquiry.status === 'success' && (
                                <>
                                  <SuccessTextDiv>
                                    {results[transport.ticketOprReservation.id].inquiry.message}
                                  </SuccessTextDiv>
                                  <JsonViewer
                                    json={results[transport.ticketOprReservation.id].inquiry.reservation}
                                    label="ID照会結果"
                                  />
                                </>
                              )}
                              {results[transport.ticketOprReservation.id].inquiry.status === 'error' && (
                                <>
                                  <DangerText color="danger">
                                    {results[transport.ticketOprReservation.id].inquiry.message}
                                  </DangerText>
                                  <JsonViewer
                                    json={results[transport.ticketOprReservation.id].inquiry.data}
                                    label="ID照会結果"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                        <Separator />
                        <>
                          <ActionButton
                            loading={results[transport.ticketOprReservation.id]?.ticketing.loading}
                            onClick={() =>
                              transport.ticketOprReservation &&
                              handleTicketOprTicketing(transport.ticketOprReservation)
                            }
                            disabled={transport.ticketOprReservation.status !== 'reservation_info_gotten'}
                          >
                            発券する
                          </ActionButton>
                          {results[transport.ticketOprReservation.id]?.ticketing && (
                            <>
                              {results[transport.ticketOprReservation.id].ticketing.status === 'success' && (
                                <SuccessTextDiv>
                                  {results[transport.ticketOprReservation.id].ticketing.message}
                                </SuccessTextDiv>
                              )}
                              {results[transport.ticketOprReservation.id].ticketing.status === 'error' && (
                                <>
                                  <DangerText color="danger">
                                    {results[transport.ticketOprReservation.id].ticketing.message}
                                  </DangerText>
                                  <JsonViewer
                                    json={results[transport.ticketOprReservation.id].ticketing.data}
                                    label="発券指示結果"
                                  />
                                </>
                              )}
                            </>
                          )}
                        </>
                        <Separator />
                        {transport.ticketOprReservation.status === 'ticket_issued' && (
                          <>
                            <ActionButton
                              loading={results[transport.ticketOprReservation.id]?.downloadTicket.loading}
                              onClick={() =>
                                transport.ticketOprReservation &&
                                handleTicketOprDownloadTicket(transport.ticketOprReservation)
                              }
                              disabled={transport.ticketOprReservation.status !== 'ticket_issued'}
                            >
                              e-ticket控えを取得する
                            </ActionButton>
                            {!results[transport.ticketOprReservation.id]?.downloadTicket?.loading &&
                              (results[transport.ticketOprReservation.id]?.downloadTicket?.data?.body?.s3_key ||
                                transport.ticketOprReservation.s3FileKey) && (
                                <DownLoadSection>
                                  <a
                                    href={`/arrangement/ticket_opr_ticketings/${transport.ticketOprReservation.id}/show_ticket_pdf`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    PDFを表示する
                                  </a>
                                </DownLoadSection>
                              )}
                            {results[transport.ticketOprReservation.id]?.downloadTicket && (
                              <>
                                {results[transport.ticketOprReservation.id].downloadTicket.status ===
                                  'success' && (
                                  <SuccessTextDiv>
                                    {results[transport.ticketOprReservation.id].downloadTicket.message}
                                  </SuccessTextDiv>
                                )}
                                {results[transport.ticketOprReservation.id].downloadTicket.status === 'error' && (
                                  <>
                                    <DangerText color="danger">
                                      {results[transport.ticketOprReservation.id].downloadTicket.message}
                                    </DangerText>
                                    <JsonViewer
                                      json={results[transport.ticketOprReservation.id].downloadTicket.data}
                                      label="e-ticketダウンロード結果"
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}
              </TransportList>
            </div>
          ))}
        </Flex>
      </Section>
    </Root>
  );
};

const Root = styled.div``;

const Section = styled.section``;

const TransportList = styled.ul`
  margin-left: ${getSpacing(2)}px;
`;

const TransportListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const TransportMessage = styled(Text)`
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  padding: 5px;
  margin-left: 5px;
  min-width: 60px;
`;

const SuccessTextDiv = styled.div`
  color: ${props => props.theme.successColor};
`;

const Separator = styled.div`
  padding: 4px;
`;

const DownLoadSection = styled.span`
  padding: 10px;
  font-size: 14px;
`;
