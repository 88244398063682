import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import type { Traveler } from './type';

export type TravelerRailwayFormData = {
  shinkansenSeat: Traveler['shinkansenSeat'] | null;
};

type Props = {
  formData: TravelerRailwayFormData;
  onChange: (formData: TravelerRailwayFormData) => void;
  classNameForModifiedField: (...path: (string | number)[]) => string;
};

type State = {
  seatType: Traveler['shinkansenSeat'] | 'その他';
  seatInfo: string | null;
};

const OrderItemTravelerRailwayForm: React.FC<Props> = ({ formData, onChange, classNameForModifiedField }) => {
  const [initialSeatType, initialSeatInfo] = formData.shinkansenSeat
    ? formData.shinkansenSeat.split('\n')
    : ['指定なし', null];
  const [state, setState] = useState<State>({ seatType: initialSeatType, seatInfo: initialSeatInfo });
  const emitOnChange = (seatType: State['seatType'], seatInfo: State['seatInfo']) => {
    const shinkansenSeat = seatType === 'その他' ? [seatType, seatInfo].join('\n') : seatType;
    onChange({ shinkansenSeat });
  };
  const changeSeatType = (seatType: State['seatType']) => {
    setState({ ...state, seatType });
    emitOnChange(seatType, state.seatInfo);
  };
  const changeSeatInfo = (seatInfo: State['seatInfo']) => {
    setState({ ...state, seatInfo });
    emitOnChange(state.seatType, seatInfo);
  };

  return (
    <Container>
      <Label className={classNameForModifiedField('shinkansenSeat')}>座席の希望</Label>
      <FormContainer>
        <Select value={state.seatType} onChange={e => changeSeatType(e.target.value)}>
          <option value="指定なし">指定なし</option>
          <option value="窓側">窓側</option>
          <option value="通路側">通路側</option>
          <option value="その他">その他</option>
        </Select>
        {state.seatType === 'その他' && (
          <Input value={state.seatInfo ?? ''} onChange={e => changeSeatInfo(e.target.value)} />
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.div``;

const Label = styled.label``;

const Select = styled.select`
  height: 24px;
`;

const Input = styled.input.attrs({ type: 'text' })``;

const FormContainer = styled.div`
  display: flex;
  align-items: center;

  ${Select} + ${Input} {
    margin-left: 5px;
  }
`;

export default OrderItemTravelerRailwayForm;
