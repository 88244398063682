import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  contactEmail?: string;
}

const InquiryThanks = ({ contactEmail }: Props) => {
  const email = contactEmail || 'contact@aitravel.jp';
  return (
    <div>
      <FinishLabel>送信が完了しました</FinishLabel>
      <p>
        お問い合わせいただきありがとうございます。
        <br />
        お問い合わせ内容を確認させていただき、後ほど担当者よりご連絡をさせていただきます。
        <br />
        ※3営業日経っても担当者より連絡がない場合、大変お手数ですが {email} にご連絡頂けますと幸いです。
      </p>
    </div>
  );
};

const FinishLabel = styled.div`
  margin-bottom: 20px;
`;

export default InquiryThanks;
