import moment from 'moment/moment';
import type { SmartHrImportDataStatusResponseArgs } from '@this/domain/smart_hr_import_data_status';
import SmartHrImportDataStatus, {
  convertSmartHrImportDataStatusResponseToArgs
} from '@this/domain/smart_hr_import_data_status';

type Status = 'unstarted' | 'started' | 'succeeded' | 'finished' | 'failed';

const StatusMessage: { [key in Status]: string } = {
  unstarted: '読み込み中',
  started: '実行中',
  succeeded: '成功',
  failed: '失敗',
  finished: '完了'
};

export type SmartHrImportActionResponseArgs = {
  id: number;
  status: Status;
  user_id: number;
  user_name: string;
  organization_id: number;
  finished_at: string;
  error_message: any;
  count_by_status: SmartHrImportDataStatusResponseArgs;
};

export type SmartHrImportActionArgs = {
  id: number;
  status: Status;
  userId: number;
  userName: string;
  organizationId: number;
  finishedAt: string | null;
  errorMessage: any;
  countByStatus: SmartHrImportDataStatus | null;
};

export const convertSmartHrImportActionResponseToArgs = (response: SmartHrImportActionResponseArgs) => {
  const args: SmartHrImportActionArgs = {
    id: response.id,
    status: response.status,
    userId: response.user_id,
    userName: response.user_name,
    finishedAt: response.finished_at ? moment(response.finished_at).format('YYYY/MM/DD HH:mm:ss') : null,
    errorMessage: response.error_message,
    countByStatus: response.count_by_status
      ? new SmartHrImportDataStatus(convertSmartHrImportDataStatusResponseToArgs(response.count_by_status))
      : null,
    organizationId: response.organization_id
  };
  return args;
};

export default class SmartHrImportAction {
  public readonly id: number;

  public readonly status: Status;

  public readonly userId: number;

  public readonly userName: string;

  public readonly organizationId: number;

  public readonly finishedAt: string | null;

  public readonly errorMessage: any;

  public readonly countByStatus: SmartHrImportDataStatus | null;

  constructor(args: SmartHrImportActionArgs) {
    this.id = args.id;
    this.status = args.status;
    this.userId = args.userId;
    this.userName = args.userName;
    this.organizationId = args.organizationId;
    this.finishedAt = args.finishedAt;
    this.errorMessage = args.errorMessage;
    this.countByStatus = args.countByStatus;
  }

  isSuccess() {
    return this.status === 'succeeded';
  }

  isFail() {
    return this.status === 'failed';
  }

  isFinish() {
    return this.status === 'finished';
  }

  statusMessage() {
    if (this.status === 'unstarted' || this.status === 'started') {
      return `${StatusMessage[this.status]} (${
        Number(this.countByStatus?.total) - Number(this.countByStatus?.unstarted)
      } / ${this.countByStatus?.total})`;
    }
    return `${StatusMessage[this.status]} (成功：${this.countByStatus?.succeeded}件、失敗：${
      this.countByStatus?.failed
    }件、スキップ：${this.countByStatus?.skipped}件)`;
  }
}
