import { Fetcher } from '@this/src/util';
import React, { useCallback } from 'react';

import User from '@this/domain/user/user';
import Traveler from '@this/domain/traveler/traveler';

import type UserJson from '@this/domain/user/user_json';
import _ from 'lodash';
import { AutocompleteSelect } from '@this/shared/ui/inputs/autocomplete';

type Props = {
  user: User;
  traveler: Traveler | undefined;
  index: number;
  onSelect: (i: number, traveler: Traveler, type: string) => void;
  excludeUserIds?: number[];
};

type MembersListResponse = {
  members: UserJson[];
};

const SelectTravelersBlock = ({ user, traveler, index, onSelect, excludeUserIds = [] }: Props) => {
  const getOptions = useCallback(
    async (keyword?: string) => {
      try {
        const res = await Fetcher.get<MembersListResponse>('/members.json', { keyword });
        const allUsers = [user, ...res.members.map(m => new User(m))];
        return _.unionBy(allUsers, u => u.id).filter(u => !excludeUserIds.find(id => id === u.id));
      } catch (e) {
        return [];
      }
    },
    [user, excludeUserIds]
  );

  const handleOnChange = useCallback(
    (selected: User | null) => {
      if (!selected) {
        return;
      }

      const newTraveler = new Traveler(selected);
      const newType = selected.id === user.id ? 'self' : 'member';
      onSelect(index, newTraveler, newType);
    },
    [user, onSelect, index]
  );

  return (
    <AutocompleteSelect
      async="FILTERING"
      placeholder="-- クリックしてメール通知者を選択ください --"
      getOptions={getOptions}
      value={traveler}
      getOptionLabel={u => u.emailAndName()}
      onChange={(_, selected) => handleOnChange(selected)}
      disableClearable
    />
  );
};

export default SelectTravelersBlock;
