import { DownloadType, Encoding } from '@this/components/organization/csv_bulk_action/types';
import { UPDATE_FORMAT } from '@this/components/expenses/organization/approver_file_formats';
import CsvBulkAction from '@this/components/expenses/organization/csv_bulk_action/csv_bulk_action';
import { Fetcher, HTTPError } from '@this/src/util';

class CsvBulkUpdateApprovers extends CsvBulkAction {
  dataEnabled = true;

  historyPath = '/biztra/organization/bulk_action_statuses?actionType=update_approvers';

  backPath = '/biztra/organization/departments';

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      formats: UPDATE_FORMAT,
      title: '承認者一括更新',
      description: [
        'CSVファイルをアップロードすることで、承認者を一括登録・更新することができます。',
        '既存の情報を編集したい場合はプルダウンメニューより「登録データ」を選択の上、ダウンロードして編集してください。',
        '※存在している部署コードがCSVに含まれている場合、承認者は上書きされます。'
      ].join('\n')
    };
  }

  downloadFormat = (type: DownloadType) => {
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `/biztra/organization/departments/csv/bulk_update_approvers/template_with_approvers?encoding=${encoding}`;
    } else {
      location.href = `/biztra/organization/departments/csv/bulk_update_approvers/template?encoding=${type}`;
    }
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload('/biztra/organization/departments/csv/bulk_update_approvers', formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };
}

export default CsvBulkUpdateApprovers;
