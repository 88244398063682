import type { ApprovalJson } from '@this/domain/expenses/approval';
import { Approval } from '@this/domain/expenses/approval';

export interface ApprovalStageJson {
  approve_stage: number;
  id: number;
  approval: ApprovalJson | null;
  user_id: number;
  user_name: string;
}

export class ApprovalStage {
  approveStage: number;

  id: number;

  approval: Approval | null;

  userId: number;

  userName: string;

  constructor(args: ApprovalStageJson) {
    this.approveStage = args.approve_stage;
    this.id = args.id;
    this.approval = args.approval ? new Approval(args.approval) : null;
    this.userId = args.user_id;
    this.userName = args.user_name;
  }
}
