import _ from 'lodash';
import moment from '@this/lib/moment';
import type { ShippingCostArgs } from '@this/domain/shipping_cost';
import ShippingCost from '@this/domain/shipping_cost';
import type { SendListItemArgs } from './send_list_item';
import { SendListItem } from './send_list_item';

export interface SendListArgs {
  id?: number;
  send_list_items?: SendListItemArgs[];
  available_shipping_costs?: ShippingCostArgs[];
  created_at?: string;
  updated_at?: string;
}

export class SendList {
  id?: number;

  items: SendListItem[];

  shippingCosts: ShippingCost[];

  createdAt?: string;

  updatedAt?: string;

  constructor(args: SendListArgs) {
    this.id = args.id;
    this.items = _.map(args.send_list_items, li => new SendListItem(li));
    this.shippingCosts = _.map(args.available_shipping_costs, args => new ShippingCost(args));
    this.createdAt = args.created_at;
    this.updatedAt = args.updated_at;
  }

  submitParams() {
    return { send_list_items_attributes: _.map(this.checkedItems(), item => item.submitParams()) };
  }

  shippingCostsSubmitParams() {
    return { shipping_costs_attributes: _.map(this.shippingCosts, cost => cost.submitParams()) };
  }

  summary(): string {
    return moment(this.updatedAt).format('YYYY-MM-DD HH:mm');
  }

  allCheck() {
    this.items.forEach(item => {
      item.checked = true;
    });
  }

  allUncheck() {
    this.items.forEach(item => {
      item.checked = false;
    });
  }

  private checkedItems(): SendListItem[] {
    return _.filter(this.items, item => item.checked);
  }
}
