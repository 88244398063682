interface MessageTemplateArgs {
  id?: number | null;
  title?: string | undefined;
  content?: string | undefined;
  isDeleted?: number | null;
}

class MessageTemplate {
  public id: number | null | undefined;

  public title: string | undefined;

  public content: string | undefined;

  public isDeleted: number | null | undefined;

  constructor(args?: MessageTemplateArgs) {
    if (args) {
      this.id = args.id;
      this.title = args.title;
      this.content = args.content;
      this.isDeleted = args.isDeleted;
    } else {
      this.id = null;
      this.title = '';
      this.content = '';
      this.isDeleted = 0;
    }
  }

  setTitle(value: string) {
    this.title = value;
    app.render();
  }

  setContent(value: string) {
    this.content = value;
    app.render();
  }

  setIsDelete(value: number) {
    this.isDeleted = value;
    app.render();
  }

  updateParams() {
    return {
      title: this.title,
      content: this.content,
      is_deleted: this.isDeleted
    };
  }
}

export default MessageTemplate;
