import React from 'react';
import styled from 'styled-components';
import { AccountStatus } from '@this/domain/user/organization_member_json';
import { getColor } from '@this/shared/ui/theme';

interface Props {
  currentStatus: AccountStatus | null;
  onChange: (newStatus: AccountStatus | null) => void;
}

const DownloadAccountStatusFilter: React.FC<Props> = ({ currentStatus, onChange }) => {
  const handleChangeStatus = (status: AccountStatus | null) => {
    onChange(status);
  };

  return (
    <OrganizationMembersMemberFilter>
      <OrganizationMembersSearchConditionsRow>
        <p>ダウンロード対象のユーザーステータス</p>
      </OrganizationMembersSearchConditionsRow>
      <OrganizationMembersSearchConditionsRow>
        <OrganizationMembersMemberFilterRadioDiv>
          <OrganizationMembersMemberFilterRadioInput
            id="radio-all"
            type="radio"
            name="accountStatusAll"
            checked={currentStatus === null}
            onChange={() => handleChangeStatus(null)}
          />
          <label htmlFor="radio-all">すべて</label>
          <OrganizationMembersMemberFilterRadioInput
            id={`radio-${AccountStatus.ENABLED}`}
            type="radio"
            name="accountStatusEnabled"
            value={AccountStatus.ENABLED}
            checked={currentStatus === AccountStatus.ENABLED}
            onChange={e => handleChangeStatus(e.target.value as AccountStatus)}
          />
          <label htmlFor={`radio-${AccountStatus.ENABLED}`}>有効</label>
          <OrganizationMembersMemberFilterRadioInput
            id={`radio-${AccountStatus.DISABLED}`}
            type="radio"
            name="accountStatusDisabled"
            value={AccountStatus.DISABLED}
            checked={currentStatus === AccountStatus.DISABLED}
            onChange={e => handleChangeStatus(e.target.value as AccountStatus)}
          />
          <label htmlFor={`radio-${AccountStatus.DISABLED}`}>無効</label>
        </OrganizationMembersMemberFilterRadioDiv>
      </OrganizationMembersSearchConditionsRow>
    </OrganizationMembersMemberFilter>
  );
};

const OrganizationMembersMemberFilter = styled.div`
  margin-bottom: 30px;
`;

const OrganizationMembersSearchConditionsRow = styled.div`
  margin-bottom: 5px;
`;

const OrganizationMembersMemberFilterRadioDiv = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationMembersMemberFilterRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

export default DownloadAccountStatusFilter;
