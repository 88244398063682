import { useState, useEffect, useCallback } from 'react';
import type { AxiosResponse as Response } from 'axios';
import axios from 'axios';
import useObjectMemo from '@this/shared/hooks/use_object_memo';

const { CancelToken } = axios;
const source = CancelToken.source();

function useApi<T>(url: string, params: object = {}) {
  const [response, setResponse] = useState<Response<T> | null>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const paramsMemo = useObjectMemo<object>(params);

  const fetch = useCallback(() => {
    setIsLoading(true);
    axios
      .request<T>({ params: paramsMemo, url, cancelToken: source.token })
      .then(response => {
        setResponse(response);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
        utils.sendErrorObject(error);
      });
  }, [paramsMemo, url]);

  useEffect(() => {
    fetch();
  }, [url, paramsMemo, fetch]);

  const data: T | null = response ? response.data : null;
  return { data, error, isLoading };
}

export default useApi;
