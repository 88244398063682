import type { ThemedStyledInterface, ThemedCssFunction } from 'styled-components';
import baseStyled, { css as baseCss } from 'styled-components';
import type { AITTheme } from '@this/shared/ui/theme';
import { createAITTheme } from '@this/shared/ui/theme';

/**
 * @deprecated
 * js/src/components/shared/ui/theme の値を使用すること
 */
export interface Themes {
  serviceName: string;
  accentColor: string;
  accentColorHover: string;
  accentColorLight: string;
  redColor: string;
  textColor: string;
  grayTextColor: string;
  grayColorLight: string;
  grayColorLight2: string;
  successColor: string;
  disabledColor: string;
  linkColor: string;
  linkColorHover: string;
  linkOpacityHover: string;
  linkFocusColorLight: string;
  moneyColor: string;
  iconColor: string;
  grayBorderColor: string;
  accentBorderColor: string;
  fieldBorderColor: string;
  wrapperBgColor: string;
  grayBgColor: string;
  grayBgColorLight: string;
  contentBgColor: string;
  backHeaderBgColor: string;
  fieldBgColor: string;
  selectArrowColor: string;
  buttonRadius: string;
  logoPath: string;
  closeIconPath: string;
  signInHeaderLogoPath: string;
  signInBodyLogoPath: string;
  signInBodyBgColor: string;
  marketLogLogoPath: string;
  footerLogoPath: string;
  footerBgPath: string;
  copyright: string;
  themeClass: string;
}

// serviceId:
//   1: AITravel
//   2: Tabikobo
const themes: { [n: number]: Themes } = {
  1: {
    serviceName: 'AI Travel',
    accentColor: '#af985e',
    accentColorHover: '#8d7b50',
    accentColorLight: '#f9f7f3',
    redColor: '#a72c09',
    textColor: '#404040',
    grayTextColor: '#7d7d7d',
    grayColorLight: '#f7f7f7',
    grayColorLight2: '#F0EFEF',
    successColor: 'green',
    disabledColor: '#aaa',
    linkColor: '#af985e',
    linkColorHover: '#8d7b50',
    linkOpacityHover: '1',
    linkFocusColorLight: '#8d7b50',
    moneyColor: '#af985e',
    iconColor: '#af985e',
    grayBorderColor: '#eee',
    accentBorderColor: '#af985e',
    fieldBorderColor: '#eee',
    wrapperBgColor: '#eee',
    grayBgColor: '#f7f7f7',
    grayBgColorLight: '#f7f7f7',
    contentBgColor: '#f7f7f7',
    backHeaderBgColor: '#292929',
    fieldBgColor: '#fff',
    buttonRadius: '3px',
    selectArrowColor: '#af985e',
    logoPath: '/images/logo/aitravel/logo.png',
    closeIconPath: '/images/icon/aitravel/close.png',
    signInHeaderLogoPath: '/images/logo/aitravel/logo2.png',
    signInBodyLogoPath: '/images/logo/aitravel/logo2.png',
    signInBodyBgColor: '#000',
    marketLogLogoPath: '/images/logo/aitravel/logo_corp_black.png',
    footerLogoPath: '/images/logo/aitravel/logo2.png',
    footerBgPath: '/images/footer_sp_bg.png',
    copyright: '© TRANSFER DATA Inc.',
    themeClass: 'aitravel'
  },
  2: {
    serviceName: 'AI Travel for 旅工房',
    accentColor: '#af985e',
    accentColorHover: '#8d7b50',
    accentColorLight: '#f9f7f3',
    redColor: '#a72c09',
    textColor: '#404040',
    grayTextColor: '#7d7d7d',
    grayColorLight: '#f7f7f7',
    grayColorLight2: '#F0EFEF',
    successColor: 'green',
    disabledColor: '#aaa',
    linkColor: '#af985e',
    linkColorHover: '#8d7b50',
    linkOpacityHover: '1',
    linkFocusColorLight: '#8d7b50',
    moneyColor: '#af985e',
    iconColor: '#af985e',
    grayBorderColor: '#eee',
    accentBorderColor: '#af985e',
    fieldBorderColor: '#eee',
    wrapperBgColor: '#eee',
    grayBgColor: '#f7f7f7',
    grayBgColorLight: '#f7f7f7',
    contentBgColor: '#f7f7f7',
    backHeaderBgColor: '#292929',
    fieldBgColor: '#fff',
    buttonRadius: '3px',
    selectArrowColor: '#af985e',
    logoPath: '/images/logo/tabikobo/logo2.png',
    closeIconPath: '/images/icon/tabikobo/close.png',
    signInHeaderLogoPath: '/images/logo/tabikobo/logo2.png',
    signInBodyLogoPath: '/images/logo/tabikobo/logo2.png',
    signInBodyBgColor: '#f9423a',
    marketLogLogoPath: '/images/logo/tabikobo/logo.png',
    footerLogoPath: '/images/logo/tabikobo/logo2.png',
    footerBgPath: '/images/footer_sp_bg.png',
    copyright: '© TABIKOBO Co. Ltd.',
    themeClass: 'tabikobo'
  }
};

export const createTheme = (serviceId?: number) => {
  // tabikoboの場合
  if (serviceId === 2) {
    return {
      ...themes[2],
      ...createAITTheme({
        overrides: {
          Button: {
            root: {
              /**
               * 旅工房はお客様の意向でサインイン前だけボタンを赤くする
               * https://aitravel.slack.com/archives/CETM6MMGX/p1636150432012400?thread_ts=1635917524.002700&cid=CETM6MMGX
               */
              '&.signin-button,&.pw-reset-button': {
                backgroundColor: '#f83933',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: '#f83933'
                },
                '&.Mui-disabled': {
                  color: '#fff'
                }
              }
            }
          },
          Link: {
            root: {
              // 旅工房はお客様の意向でサインイン前だけ赤くする
              '&.reset-password-link,&.signin-form-link': {
                color: '#f83933',
                '&:hover': {
                  color: '#f83933'
                }
              }
            }
          }
        }
      })
    };
  }

  return { ...themes[1], ...createAITTheme() };
};

export const styled = baseStyled as ThemedStyledInterface<Themes & AITTheme>;
export const css = baseCss as ThemedCssFunction<Themes & AITTheme>;
