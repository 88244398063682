import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import type { InAdvanceMessageTemplate } from '@this/src/domain/in_advance/in_advance_message_template';
import { Button } from '@this/shared/ui/inputs/button';
import { InputLabel } from '@this/shared/ui/inputs/form_control';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@this/shared/ui/feedbacks/modal';
import { Fetcher, HTTPError } from '@this/src/util';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

type Props = {
  messageTemplate: InAdvanceMessageTemplate;
  onSubmit: () => void;
  hideModal: () => void;
};

const EditFormModal: React.FC<Props> = ({ messageTemplate, onSubmit, hideModal }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);

  const updateType = async () => {
    setSubmitting(true);
    setSubmitErrors([]);
    try {
      if (messageTemplate.id !== 0) {
        await Fetcher.put(
          `/organization/in_advance_message_templates/${messageTemplate.id}`,
          messageTemplate.submitParams()
        );
      } else {
        await Fetcher.post('/organization/in_advance_message_templates', messageTemplate.submitParams());
      }
      setSubmitting(false);
      onSubmit();
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400) {
        const error = e.response.data.error;
        setSubmitErrors(typeof error === 'string' ? [error] : error);
        setSubmitting(false);
      } else {
        setSubmitErrors(['通信エラーが発生しました。時間をおいて再度お試しください。']);
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal open={Boolean(messageTemplate)} onClose={hideModal} className="e2e-modal-edit">
      <ModalHeader>メッセージテンプレートを{messageTemplate.id === 0 ? '追加' : '編集'}</ModalHeader>
      <ModalBody>
        <form>
          <div className="type-edit">
            <Section className="type-edit__section">
              <InputLabel htmlFor="message" className="type-edit__label">
                メッセージ
              </InputLabel>
              <Textarea
                id="message"
                rows={3}
                value={messageTemplate.message || ''}
                onChange={e => messageTemplate.setMessage(e.target.value)}
              />
            </Section>
          </div>
          {submitErrors && submitErrors.map((e, i) => <p key={i}>{e}</p>)}
        </form>
        {submitting && <SimpleLoading />}
      </ModalBody>
      <ModalFooter>
        <Button color="sub" type="button" disabled={submitting} onClick={hideModal}>
          キャンセル
        </Button>
        <Button type="submit" disabled={submitting} onClick={updateType}>
          保存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Section = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
`;

const Textarea = styled.textarea`
  height: auto;
`;

export default EditFormModal;
