import React from 'react';
import { styled } from '@this/constants/themes';

import type DomesticAirMarketLog from '@this/domain/market_log/domestic_air_market_log';
import MarketLogSample from './market_log_sample';

type Props = {
  marketLog: DomesticAirMarketLog;
  available: boolean;
};

const DomesticAirMarketLogTemplate: React.FC<Props> = ({ marketLog, available }) => (
  <div className="merket-log__body">
    <BasicInfo>
      <Title>基本情報</Title>
      <BasicInfoContents>
        <BasicInfoItemWrapper>
          <BasicInfoItem>
            <BasicInfoLabel>予約者</BasicInfoLabel>
            <BasicInfoText>{marketLog.userName}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>承認者</BasicInfoLabel>
            <BasicInfoText>{marketLog.approverNames}</BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>出張者</BasicInfoLabel>
            <BasicInfoText>{marketLog.travelerNames}</BasicInfoText>
          </BasicInfoItem>
        </BasicInfoItemWrapper>
        <BasicInfoItemWrapper>
          <BasicInfoItem>
            <BasicInfoLabel>便名</BasicInfoLabel>
            <BasicInfoText>
              {marketLog.info.map((air, i) => (
                <div key={i}>
                  <p>
                    <span>{air.name}</span>
                    <Image src={utils.transitIconPath(air.name)} />
                  </p>
                  <p>発：{air.from}</p>
                  <p>着：{air.to}</p>
                  <p>{air.changeable}</p>
                </div>
              ))}
            </BasicInfoText>
          </BasicInfoItem>
          <BasicInfoItem>
            <BasicInfoLabel>料金</BasicInfoLabel>
            <BasicInfoText>{marketLog.priceDesc}</BasicInfoText>
          </BasicInfoItem>
        </BasicInfoItemWrapper>
      </BasicInfoContents>
    </BasicInfo>
    {available ? (
      <>
        <Summary>
          <Title>サマリー（変更可）</Title>
          <SummaryContents>
            <SummaryItem>
              <SummaryLabel>最高値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.changeable.max_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>最安値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.changeable.min_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>中央値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.changeable.median_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>平均値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.changeable.average_price)}</SummaryText>
            </SummaryItem>
          </SummaryContents>
        </Summary>
        <Summary>
          <Title>サマリー（変更不可）</Title>
          <SummaryContents>
            <SummaryItem>
              <SummaryLabel>最高値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.unchangeable.max_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>最安値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.unchangeable.min_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>中央値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.unchangeable.median_price)}</SummaryText>
            </SummaryItem>
            <SummaryItem>
              <SummaryLabel>平均値</SummaryLabel>
              <SummaryText>{utils.formatPrice(marketLog.summary.unchangeable.average_price)}</SummaryText>
            </SummaryItem>
          </SummaryContents>
        </Summary>
        <Candidates>
          <Title>その他の候補</Title>
          {marketLog.candidates.length > 0 ? (
            <CandidatesTable>
              <tbody>
                <tr>
                  <CandidatesNameTh>便名</CandidatesNameTh>
                  <CandidatesTh>価格</CandidatesTh>
                </tr>
                {marketLog.candidates.map((c, i) => (
                  <tr key={String(i)}>
                    <td>
                      {c.legs.map((leg, index) => (
                        <div key={index}>
                          <p>
                            {leg.name}
                            <Image src={utils.transitIconPath(leg.name)} />
                          </p>
                          <p>発：{leg.from}</p>
                          <p>着：{leg.to}</p>
                        </div>
                      ))}
                    </td>
                    <td>
                      <p>変更可：{c.changeablePrice ? utils.formatPrice(c.changeablePrice) : '空席なし'}</p>
                      <p>変更不可：{c.unchangeablePrice ? utils.formatPrice(c.unchangeablePrice) : '空席なし'}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </CandidatesTable>
          ) : (
            <p>その他の候補が見つかりませんでした</p>
          )}
        </Candidates>
      </>
    ) : (
      <MarketLogSample />
    )}
  </div>
);

const BasicInfo = styled.div`
  margin-top: 10px;
  font-weight: bold;
`;

const Title = styled.h2`
  font-weight: bold;
`;

const BasicInfoContents = styled.div`
  display: flex;
`;

const BasicInfoItemWrapper = styled.div`
  flex-grow: 1;
`;

const BasicInfoItem = styled.div`
  display: flex;
  font-size: 0.75em;
`;

const BasicInfoLabel = styled.p`
  flex-basis: 100px;
  background-color: #f7f7f7;
  text-align: center;
  padding: 5px 20px;
`;

const BasicInfoText = styled.div`
  padding: 5px;
`;

const Summary = styled.div`
  margin-top: 10px;
`;

const SummaryContents = styled.div`
  display: flex;
  justify-content: space-around;
`;

const SummaryItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  text-align: center;
  background-color: #f7f7f7;
  padding: 10px 30px;
  margin: 0 2px;
`;

const SummaryLabel = styled.p`
  font-size: 0.75em;
`;

const SummaryText = styled.p`
  font-weight: bold;
  font-size: 1.3em;
`;

const Candidates = styled.div`
  margin-top: 10px;
`;

const CandidatesTable = styled.table`
  border-collapse: separate;
  border-spacing: 4px 0;
  table-layout: fixed;
`;

const CandidatesTh = styled.th`
  text-align: center;
  font-size: 0.75em;
  background-color: #f7f7f7;
`;

const CandidatesNameTh = styled.th`
  text-align: center;
  font-size: 0.75em;
  background-color: #f7f7f7;
  width: 60%;
`;

export const Image = styled.img`
  height: 1em;
  margin-left: 5px;
`;

export default DomesticAirMarketLogTemplate;
