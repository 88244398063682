import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from '@this/shared/ui/navigations/link';
import Header from '@this/shared/header/header';
import Footer from '@this/shared/footer/footer';
import { createTheme, styled } from '@this/constants/themes';
import { AITThemeProvider } from '@this/shared/ui/theme';

export const UserConfirm = () => {
  const theme = createTheme();

  return (
    <AITThemeProvider theme={theme}>
      <Router>
        <div>
          <Header />
          <div className="content-body">
            <Description>
              このリンクは無効です。
              <br />
              お手数をおかけいたしますが、ログインをお試しいただくか、再度新規登録をお願い致します。
              <br />
              <br />
              <Link href="/users/sign_in" isExternal>
                ログイン・新規登録はこちら
              </Link>
            </Description>
          </div>
          <Footer signedIn={false} />
        </div>
      </Router>
    </AITThemeProvider>
  );
};

const Description = styled.p`
  padding: 20px;
`;
