import React from 'react';

interface Props {
  initialValue?: number;
  className?: string;
  onChange: (value: number) => void;
}
interface State {
  value: string;
  viewValue: string;
}

class PriceField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initValue = (props.initialValue || 0).toString();
    this.state = {
      value: initValue,
      viewValue: initValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const initValue = (nextProps.initialValue || 0).toString();
    this.setState({
      value: initValue,
      viewValue: initValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    });
  }

  handleChange(value: string) {
    const changeValue = (parseInt(value.replace(/,/g, ''), 10) || 0).toString();
    this.setState({
      value: changeValue,
      viewValue: changeValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    });
  }

  handleBlur() {
    const newPrice = utils.sanitizePrice(this.state.value) || '0';
    this.setState({ value: newPrice }, () => this.props.onChange(parseInt(this.state.value, 10)));
  }

  render() {
    return (
      <input
        type="text"
        className={this.props.className}
        value={this.state.viewValue}
        onChange={e => this.handleChange(e.target.value)}
        onBlur={() => this.handleBlur()}
      />
    );
  }
}

export default PriceField;
