import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import {
  OrganizationHeader,
  OrganizationHeaderTitle,
  OrganizationTable,
  OrganizationTh,
  OrganizationTd,
  OrganizationBody,
  OrganizationTitle
} from '@this/components/organization/organization.style';

import type { RouteComponentProps } from 'react-router-dom';
import type { BulkActionStatus as BulkActionStatusType, Organization } from '../types';
import SegmentedControl from '../../shared/segmented_control/segmented_control';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

const BulkActionStatus = require('../../../domain/bulk_action/bulk_action_status');

// TODO BulkActionStatusのts化が完了したらBulkActionStatusに置く
enum BulkActionType {
  CREATE_PROJECTS = 'create_projects',
  UPSERT_USERS = 'upsert_users',

  DELETE_USERS = 'delete_users',
  UPSERT_DEPARTMENTS = 'upsert_departments',
  UPDATE_APPROVERS = 'update_approvers',
  UPDATE_PROJECT_APPROVERS = 'update_project_approvers',
  UPSERT_ACCOUNT_TYPES = 'upsert_account_types',
  UPSERT_EXPENSES_TYPES = 'upsert_expenses_types'
}

interface BulkActionTypeSegment {
  actionType: BulkActionType;
  label: string;
  segmentNumber: number;
  showTitle?: string;
}

interface Props extends RouteComponentProps {
  expenses?: boolean;
}
interface State {
  selectedSegment: number;
  statuses: any[];
  loading: boolean;
  error: string | null;
}

export const ShowTitleOnly = {
  expenses: 'expenses',
  aitravel: 'aitravel'
};

const BASE_BULK_ACTION_TYPE_SEGMENTS: Pick<BulkActionTypeSegment, 'actionType' | 'label' | 'showTitle'>[] = [
  { actionType: BulkActionType.UPSERT_USERS, label: '社員一括追加・更新' },
  { actionType: BulkActionType.DELETE_USERS, label: '社員一括削除' },
  { actionType: BulkActionType.CREATE_PROJECTS, label: 'プロジェクト一括追加', showTitle: ShowTitleOnly.aitravel },
  { actionType: BulkActionType.UPSERT_DEPARTMENTS, label: '部署一括追加・更新' },
  { actionType: BulkActionType.UPDATE_APPROVERS, label: '部署承認者一括追加・更新' },
  {
    actionType: BulkActionType.UPDATE_PROJECT_APPROVERS,
    label: 'プロジェクト承認者一括追加・更新',
    showTitle: ShowTitleOnly.aitravel
  },
  {
    actionType: BulkActionType.UPSERT_ACCOUNT_TYPES,
    label: '勘定科目一括追加・更新',
    showTitle: ShowTitleOnly.expenses
  },
  {
    actionType: BulkActionType.UPSERT_EXPENSES_TYPES,
    label: '経費科目一括追加・更新',
    showTitle: ShowTitleOnly.expenses
  }
];

class BulkActionStatuses extends React.Component<Props, State> {
  bulkActionTypeSegments: BulkActionTypeSegment[];

  unListenRouting?: () => void;

  constructor(props: Props) {
    super(props);

    this.bulkActionTypeSegments = BASE_BULK_ACTION_TYPE_SEGMENTS.reduce(
      (acc: BulkActionTypeSegment[], segment) => {
        if (this.props.expenses && segment.showTitle !== ShowTitleOnly.aitravel) {
          acc.push({ ...segment, segmentNumber: acc.length });
        } else if (!this.props.expenses && segment.showTitle !== ShowTitleOnly.expenses) {
          acc.push({ ...segment, segmentNumber: acc.length });
        }

        return acc;
      },
      []
    );

    this.state = {
      selectedSegment: this.getSegmentNumberBylocation(),
      statuses: [],
      loading: true,
      error: null
    };

    this.unListenRouting = this.props.history.listen(() => {
      this.setState({ selectedSegment: this.getSegmentNumberBylocation() });
    });
  }

  componentDidMount() {
    utils
      .jsonPromise<{
        bulk_action_statuses: BulkActionStatusType[];
        orgnization: Organization;
      }>('./bulk_action_statuses.json')
      .then(
        result => {
          this.setState({
            statuses: result.bulk_action_statuses.map(status => new BulkActionStatus(status)),
            loading: false
          });
        },
        error => {
          let errorText: string | null;
          if (error.status === 400) {
            errorText = utils.dig(error, 'responseJSON', 'errors');
          } else {
            errorText = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState({
            loading: false,
            error: errorText
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  componentWillUnmount(): void {
    if (this.unListenRouting) {
      this.unListenRouting();
    }
  }

  handleChangeSegment = (i: number) => {
    const segment = this.bulkActionTypeSegments.find(s => s.segmentNumber === i);

    if (segment) {
      this.props.history.push({ search: `?actionType=${segment.actionType}` });
    }
  };

  private getSegmentNumberBylocation() {
    const parsedQueryParams = utils.getParams();
    const actionType =
      parsedQueryParams && parsedQueryParams.actionType
        ? Array.isArray(parsedQueryParams.actionType)
          ? parsedQueryParams.actionType[0]
          : parsedQueryParams.actionType
        : null;

    const segment = this.bulkActionTypeSegments.find(s => s.actionType === actionType);
    return segment ? segment.segmentNumber : 0;
  }

  render() {
    const { selectedSegment, loading, error, statuses } = this.state;
    const { expenses } = this.props;
    const segment = this.bulkActionTypeSegments.find(s => s.segmentNumber === selectedSegment);
    if (!segment) {
      return null;
    }
    const filteredStatuses = statuses.filter(s => s.actionType === segment.actionType);

    return (
      <div className="organization-members">
        {expenses ? (
          <Header>
            <HeaderTitle>アップロード履歴</HeaderTitle>
          </Header>
        ) : (
          <OrganizationTitle>アップロード履歴</OrganizationTitle>
        )}
        <OrganizationBody>
          <SegmentedControl
            name="type"
            labels={this.bulkActionTypeSegments.map(({ label }) => label)}
            value={selectedSegment}
            onChange={this.handleChangeSegment}
          />
          {loading ? (
            <SimpleLoading />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <OrganizationTable>
              <tbody>
                <tr>
                  <OrganizationTh>ファイル名</OrganizationTh>
                  <OrganizationTh>アップロードユーザー</OrganizationTh>
                  <OrganizationTh>送信日時</OrganizationTh>
                  <OrganizationTh>ステータス</OrganizationTh>
                  <OrganizationTh>詳細</OrganizationTh>
                </tr>
                {filteredStatuses.map((status, i) => (
                  <tr key={i}>
                    <OrganizationTd className="e2e-filename">{status.filename}</OrganizationTd>
                    <OrganizationTd className="e2e-user-name">{status.kickedByUser.name}</OrganizationTd>
                    <OrganizationTd className="e2e-created-at">{status.createdAt}</OrganizationTd>
                    <OrganizationTd className="e2e-status-message">
                      <TdIn color={status.isSuccess() ? 'green' : status.isFail() ? 'red' : 'gray'}>
                        {status.statusMessage()}
                      </TdIn>
                    </OrganizationTd>
                    <OrganizationTd>
                      <Link to={`${expenses ? '/biztra' : ''}/organization/bulk_action_statuses/${status.id}`}>
                        詳細
                      </Link>
                    </OrganizationTd>
                  </tr>
                ))}
              </tbody>
            </OrganizationTable>
          )}
        </OrganizationBody>
      </div>
    );
  }
}

const Header = styled(OrganizationHeader)`
  padding: 0;
`;

const HeaderTitle = styled(OrganizationHeaderTitle)`
  color: #927230;
  font-size: 24px;
  margin: 0 0 12px 0;
`;

const TdIn = styled.p<{ color: string }>`
  ${props =>
    props.color === 'green'
      ? `
      color: ${props.theme.successColor};
    `
      : props.color === 'red'
      ? `
      color: ${props.theme.redColor};
    `
      : `
      color: ${props.theme.grayTextColor};
    `}
`;

export default BulkActionStatuses;
