export enum Encoding {
  UTF8 = 'utf8',
  SJIS = 'sjis'
}

export enum DownloadType {
  UTF8 = 'utf8',
  SJIS = 'sjis',
  DATA_UTF8 = 'data_utf8',
  DATA_SJIS = 'data_sjis'
}

export interface FileValidatorResult {
  file?: File;
  rowCount: number;
  errors?: string[];
}
