import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import TranslateIgnoreText from '@this/components/shared/text/translate_ignore_text';

interface Props {
  expense: any;
  i: number;
  project?: any | null;
}

const ExpenseConfirmTemplate: React.SFC<Props> = ({ expense, i, project }) => (
  <Form>
    <FormNumber>{`${i + 1}.`}</FormNumber>
    <div>
      日付：<span data-wovn-ignore>{expense.date.format('YYYY-MM-DD')}</span>
      <br />
      種別：<span data-wovn-ignore>{expense.type}</span>
      <br />
      支払い先・内容：
      <Abstract>
        <TranslateIgnoreText text={expense.abstract} />
      </Abstract>
      金額：<span data-wovn-ignore>{utils.formatPrice(expense.price)}</span>
      <br />
      費用負担先：<span data-wovn-ignore>{utils.dig(project, 'name')}</span>
      <br />
      社内管理番号：<span data-wovn-ignore>{expense.internalNumber}</span>
      <br />
      メモ：
      <Abstract>
        <TranslateIgnoreText data-wovn-ignore text={expense.memo} />
      </Abstract>
    </div>
  </Form>
);

const Form = styled.div``;

const FormNumber = styled.div``;

const Abstract = styled.div`
  margin-left: 20px;
`;

export default ExpenseConfirmTemplate;
