import _ from 'lodash';
import { observable, action } from 'mobx';
import type OrderItem from '@this/domain/order_item';
import type { OrderItemMappingArgs, OrderItemMappingMode } from './order_item_mapping';
import OrderItemMapping, { ORDER_ITEM_MAPPING_DEFAULT_ARGS } from './order_item_mapping';
import type ReservingTrip from './trip/reserving_trip';

class OrderItemMappingList {
  @observable
  public list: OrderItemMapping[];

  constructor(raw: OrderItemMappingArgs[]) {
    this.list = raw.map(o => new OrderItemMapping(o));
  }

  find(id: number) {
    return _.find(this.list, o => o.id === id);
  }

  findDefaultMapping() {
    const args = ORDER_ITEM_MAPPING_DEFAULT_ARGS;
    return this.findByTravelerAndItem(args.traveler_id!, args.order_item_type!, args.order_item_index!);
  }

  findByTravelerAndItem(traveler_id: number, order_item_type: string, order_item_index: number) {
    return _.find(
      this.list,
      o =>
        o.travelerId === traveler_id &&
        o.orderItemType === order_item_type &&
        o.orderItemIndex === order_item_index
    );
  }

  findByTravelerInformationAndOrderItem(traveler_information_id: number, order_item_id: number) {
    return _.find(
      this.list,
      o => o.travelerInformationId === traveler_information_id && o.orderItemId === order_item_id
    );
  }

  first() {
    return _.first(this.list);
  }

  last() {
    return _.last(this.list);
  }

  @action
  push(o: OrderItemMapping) {
    this.list.push(o);
  }

  @action
  remove(o: OrderItemMapping) {
    const i = this.list.indexOf(o);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  clone(): OrderItemMappingList {
    const cloned = new OrderItemMappingList([]);
    this.list.forEach(mapping => {
      cloned.push(mapping.clone());
    });
    return cloned;
  }

  mappingMode(): OrderItemMappingMode {
    return this.list[0]?.orderItemMappingType() as OrderItemMappingMode;
  }

  isForDetail(): boolean {
    const mode = this.mappingMode();
    if (mode) {
      return !['', 'default'].includes(mode);
    }
    return false;
  }

  isForDepartmentDetail() {
    const depIds = this.list.map(mapping => mapping.chargingDepartmentId);
    const depShareIds = this.list.map(mapping => mapping.chargingDepartmentShareId);
    if (depIds.some(id => !!id)) return !depIds.every(id => id === depIds[0]);
    if (depShareIds.some(id => !!id)) return !depShareIds.every(id => id === depShareIds[0]);
    return false;
  }

  isForProjectDetail() {
    const proIds = this.list.map(mapping => mapping.projectId);
    const proShareIds = this.list.map(mapping => mapping.projectShareId);
    if (proIds.some(id => !!id)) return !proIds.every(id => id === proIds[0]);
    if (proShareIds.some(id => !!id)) return !proShareIds.every(id => id === proShareIds[0]);
    return false;
  }

  isForExpensesAccountTypeDetail() {
    const accountTypeIds = this.list.map(mapping => mapping.expensesAccountTypeId);
    if (accountTypeIds.some(id => !!id)) return !accountTypeIds.every(id => id === accountTypeIds[0]);
    return false;
  }

  isProjectExist() {
    return this.list.every(mapping => mapping.isProjectExist());
  }

  isChargingDepartmentExist() {
    return this.list.every(mapping => mapping.isChargingDepartmentExist());
  }

  isExpensesAccountTypeExist() {
    return this.list.every(mapping => mapping.isExpensesAccountTypeExist());
  }

  submitParams() {
    return this.list.map(mapping => mapping.submitParams());
  }

  /*
  @action
  update(newOrg: OrderItemMapping) {
    const o = this.find(newOrg.id);
    if (o) {
      _.assign(o, newOrg);
    }
  }
  */

  static defaultList(): OrderItemMappingList {
    const mappingList = new OrderItemMappingList([]);
    mappingList.list = [OrderItemMapping.defaultMapping()];
    return mappingList;
  }

  static initialListForItem(reservingTrip: ReservingTrip, rentalCarsCount: number): OrderItemMappingList {
    const orderItemMappingArgsList: OrderItemMappingArgs[] = [];
    if (reservingTrip.isPackage()) {
      const args: OrderItemMappingArgs = {
        traveler_id: -1,
        order_item_type: 'package',
        order_item_index: 1
      };
      orderItemMappingArgsList.push(args);
    } else {
      const list =
        reservingTrip.items && reservingTrip.items.length > 0 ? reservingTrip.items : reservingTrip.nonOrderItems;
      list.forEach((item: any) => {
        const args: OrderItemMappingArgs = {
          traveler_id: -1,
          order_item_type: 'item',
          order_item_index: Number(item?.index)
        };
        orderItemMappingArgsList.push(args);
      });
    }

    [...Array(rentalCarsCount)].forEach((_, i) => {
      const args: OrderItemMappingArgs = {
        traveler_id: -1,
        order_item_type: 'rental_car',
        order_item_index: i
      };
      orderItemMappingArgsList.push(args);
    });

    return new OrderItemMappingList(orderItemMappingArgsList);
  }

  static initialListForTraveler(travelersCount: number): OrderItemMappingList {
    const orderItemMappingArgsList: OrderItemMappingArgs[] = [];
    [...Array(travelersCount)].forEach((_, index) => {
      const args: OrderItemMappingArgs = {
        traveler_id: index,
        order_item_type: 'all',
        order_item_index: -1
      };
      orderItemMappingArgsList.push(args);
    });

    return new OrderItemMappingList(orderItemMappingArgsList);
  }

  static initialListForBoth(
    reservingTrip: ReservingTrip,
    travelersCount: number,
    rentalCarsCount: number
  ): OrderItemMappingList {
    const orderItemMappingArgsList: OrderItemMappingArgs[] = [];
    if (reservingTrip.isPackage()) {
      [...Array(travelersCount)].forEach((_, index) => {
        const args: OrderItemMappingArgs = {
          traveler_id: index,
          order_item_type: 'package',
          order_item_index: 1
        };
        orderItemMappingArgsList.push(args);
      });
    } else {
      const list =
        reservingTrip.items && reservingTrip.items.length > 0 ? reservingTrip.items : reservingTrip.nonOrderItems;
      list.forEach((item: any) => {
        [...Array(travelersCount)].forEach((_, index) => {
          const args: OrderItemMappingArgs = {
            traveler_id: index,
            order_item_type: 'item',
            order_item_index: Number(item?.index)
          };
          orderItemMappingArgsList.push(args);
        });
      });
    }

    [...Array(rentalCarsCount)].forEach((_, i) => {
      [...Array(travelersCount)].forEach((_, index) => {
        const args: OrderItemMappingArgs = {
          traveler_id: index,
          order_item_type: 'rental_car',
          order_item_index: i
        };
        orderItemMappingArgsList.push(args);
      });
    });

    return new OrderItemMappingList(orderItemMappingArgsList);
  }

  // initialListForItem と同じ用途だが、ArrangementRequestWfInfo用にコピーして作成
  // [変更点] ReservingTrip → OrderItemを使用する、Package、RentalCar、のチェックはしない
  static initialListForOrderItem(orderItem: OrderItem[]): OrderItemMappingList {
    const orderItemMappingArgsList: OrderItemMappingArgs[] = [];
    orderItem.forEach((item: OrderItem | undefined, i: number) => {
      const args: OrderItemMappingArgs = {
        traveler_id: -1,
        order_item_type: 'item',
        order_item_index: i
      };
      orderItemMappingArgsList.push(args);
    });
    return new OrderItemMappingList(orderItemMappingArgsList);
  }

  // initialListForBoth と同じ用途だが、ArrangementRequestWfInfo用にコピーして作成
  // [変更点] ReservingTrip → OrderItemを使用する、Package、RentalCar、のチェックはしない
  static initialListForBothOrderItem(orderItem: OrderItem[], travelersCount: number): OrderItemMappingList {
    const orderItemMappingArgsList: OrderItemMappingArgs[] = [];
    orderItem.forEach((item: OrderItem | undefined, i: number) => {
      [...Array(travelersCount)].forEach((_, index) => {
        const args: OrderItemMappingArgs = {
          traveler_id: index,
          order_item_type: 'item',
          order_item_index: i
        };
        orderItemMappingArgsList.push(args);
      });
    });
    return new OrderItemMappingList(orderItemMappingArgsList);
  }
}

export default OrderItemMappingList;
