import React, { useState } from 'react';

import type { InternationalAirline } from '@this/components/admin/repository_display_adjustment/types';
import SearchableSelect from '@this/shared/searchable_select';
import { styled } from '@this/constants/themes';
import { ButtonBase02 } from '@this/shared/atoms/button';

interface Props {
  airlines: InternationalAirline[];
  exclusionAirlineCodes: string[];
  onUpdate: (exclusionAirlineCodes: string[]) => Promise<void>;
}

const ExclusionAirlinesForm: React.FC<Props> = ({ airlines, exclusionAirlineCodes, onUpdate }) => {
  const [editingCodes, setEditingCodes] = useState([...exclusionAirlineCodes]);
  const selectableAirlines = airlines.map(airline => ({
    value: airline.code,
    displayName: airline.name
  }));

  const onChange = (i: number, code: string) => {
    editingCodes.splice(i, 1, code);
    setEditingCodes([...editingCodes]);
  };

  const onClickRemove = (i: number) => {
    editingCodes.splice(i, 1);
    setEditingCodes([...editingCodes]);
  };

  const onClickAdd = () => {
    editingCodes.push('');
    setEditingCodes([...editingCodes]);
  };

  const isChanged = () => editingCodes.join('') !== exclusionAirlineCodes.join('');

  return (
    <>
      <AirlineSelectAreaGroup className="e2e-airline-list">
        {editingCodes.map((code, i) => (
          <AirlineSelectArea key={i}>
            <SearchableSelect
              list={selectableAirlines}
              currentValue={code}
              onChange={(v: string) => onChange(i, v)}
            />
            <RemoveButton onClick={() => onClickRemove(i)}>+</RemoveButton>
          </AirlineSelectArea>
        ))}
        <LinkButton onClick={onClickAdd}>+ 航空会社を追加</LinkButton>
      </AirlineSelectAreaGroup>

      <Button onClick={() => onUpdate(editingCodes)} disabled={!isChanged()}>
        更新
      </Button>
    </>
  );
};

const Button = styled.button.attrs({
  type: 'button'
})`
  ${ButtonBase02};
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  color: #7d7d7d;
  border-radius: 50%;
  border: 0;
  padding: 0;
  background-color: transparent;
  transform: rotate(45deg);
  transition: opacity 0.3s;
  font-size: 16px;
  outline: none;

  &:hover,
  &:focus {
    opacity: 0.8;
    background-color: transparent;
    color: #7d7d7d;
  }
`;

const AirlineSelectArea = styled.div`
  display: flex;
  align-items: center;

  .searchable-select {
    width: 150px;
  }

  ${RemoveButton} {
    margin-left: 10px;
  }
`;

const AirlineSelectAreaGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  ${AirlineSelectArea} + ${AirlineSelectArea} {
    margin-top: 5px;
  }
`;

const LinkButton = styled.a`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
`;

export default ExclusionAirlinesForm;
