import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import SearchQueryItem from './search_query_item';
import type { Airline } from './select_store';
import type { FlightType, SearchType } from './search_query';
import TravelerList from './traveler/traveler_list';
import type Traveler from './traveler/traveler';
import type User from './user/user';

export interface SearchQueryHistoriesArgs {
  id: number;
  smoke?: string;
  breakfast?: string;
  roomnum?: string | number;
  peoplenum: string | number;
  search_type: SearchType;
  cabin?: string;
  carrier_list?: string;
  flight_type?: FlightType;
  type?: 'separate' | 'airPackage';
  airline?: Airline;
  draft_id?: number;
  items: any[];
  traveler_informations: Traveler[] | null;
  user: User;
  created_at: string;
}

class SearchQueryHistories {
  public id: number;

  public smoke: string;

  public breakfast: string;

  public roomnum: number;

  public peoplenum: number;

  public items: SearchQueryItem[];

  public type: 'separate' | 'airPackage' | undefined;

  public searchType: SearchType;

  public flightType: FlightType;

  public showDetail: boolean;

  public travelers?: TravelerList;

  public defaultTime: { startTime: Moment; endTime: Moment };

  public createdAt: Moment;

  constructor(args: SearchQueryHistoriesArgs, user: User) {
    this.id = args.id;
    this.smoke = args.smoke || localStorage.getItem('smoke') || '';
    this.breakfast = args.breakfast || localStorage.getItem('breakfast') || '';
    this.roomnum = Number(args.roomnum) || 1;
    this.peoplenum = Number(args.peoplenum) || 1;
    this.type = args.type;
    this.searchType = args.search_type;
    this.flightType = args.flight_type || '';
    this.items = args.items.map((iem: any) => new SearchQueryItem(iem));
    this.travelers = TravelerList.fromTravelers({
      travelers: args.traveler_informations,
      peoplenum: this.peoplenum,
      user
    });
    if (this.items && this.items.length > 0) {
      this.defaultTime = {
        startTime: this.startEndTime(this.items[0]),
        endTime: this.startEndTime(this.items[this.items.length - 1])
      };
    } else {
      this.defaultTime = { startTime: moment(), endTime: moment() };
    }
    this.createdAt = moment(args.created_at);
    this.showDetail = false;
  }

  showOrHideDetail() {
    this.showDetail = !this.showDetail;
    app.render();
  }

  startEndTime(item: SearchQueryItem) {
    if (item != null) {
      return moment(`${item.outdate} ${item.outhour}:${item.outmin}`);
    }
    return moment();
  }

  breakfastStr() {
    switch (this.breakfast) {
      case 'yes':
        return '有';
      case 'no':
        return '無';
      default:
        return '指定なし';
    }
  }

  smokeStr() {
    switch (this.smoke) {
      case 'yes':
        return '喫煙室';
      case 'no':
        return '禁煙室';
      default:
        return '指定なし';
    }
  }

  calcPastDate() {
    const startDatetime = moment(`${this.items[0].outdate} 00:00:00`);
    const now = moment().hour(0).minutes(0).second(0).millisecond(0);
    const diffDays = now.diff(startDatetime, 'days');
    if (diffDays > 0) {
      this.defaultTime.startTime = this.defaultTime.startTime.add(diffDays, 'd');
      this.defaultTime.endTime = this.defaultTime.startTime.add(diffDays, 'd');
      this.items = this.items.map(it => {
        it.homedate = moment(`${it.homedate} 00:00:00`).add(diffDays, 'd').format('YYYY-MM-DD');
        it.outdate = moment(`${it.outdate} 00:00:00`).add(diffDays, 'd').format('YYYY-MM-DD');
        return it;
      });
    }
  }
}

export default SearchQueryHistories;
