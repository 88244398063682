class HotelElementGuest
  constructor: (args = {}) ->
    args = snakeToCamelObject(args)
    _.assign(@, args)

  bedTypeDescriptionOverview: ->
    if _.isEmpty(@bedTypeDescription)
      'ベッドタイプ：-'
    else
      "ベッドタイプ：#{@bedTypeDescription}"

module.exports = HotelElementGuest
