import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import Button from '@this/shared/atoms/button';
import moment from 'moment';
import useApi from '@this/components/shared/hooks/use_api';

import type { AllowanceArgs } from '@this/domain/allowance/allowance';
import { Allowance } from '@this/domain/allowance/allowance';

import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

import { OrganizationTitle, OrganizationBody, DownloadButton } from '../organization.style';

interface AllowanceResponse {
  allowances: AllowanceArgs[];
}

const Allowances = () => {
  const [from, setFrom] = useState(
    utils.getParam('from') ? moment(utils.getParam('from')) : moment().startOf('month')
  );
  const [to, setTo] = useState(utils.getParam('to') ? moment(utils.getParam('to')) : moment().endOf('month'));
  const [aggregateTarget, setAggregateTarget] = useState(
    utils.getParam('aggregateTarget') ? utils.getParam('aggregateTarget') : 'contain_start_or_end'
  );
  const [downloading, setDownloading] = useState(false);
  const [csvEncoding, setCsvEncoding] = useState('utf8');
  const [csvTarget, setCsvTarget] = useState('person');
  const params = { from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), aggregateTarget };
  const allowanceResponse = useApi<AllowanceResponse>('/organization/allowances.json', params);
  const allowanceRawData = allowanceResponse.data;
  const isAllowanceLoading = allowanceResponse.isLoading;
  const allowanceData = allowanceRawData
    ? allowanceRawData.allowances.map((args: AllowanceArgs) => new Allowance(args))
    : [];

  const handleCSVDownload = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDownloading(true);
    new Promise(resolve => {
      location.href = `/organization/allowances.csv?${utils.toParams(
        _.merge(params, { encoding: csvEncoding, csvTarget, aggregateTarget })
      )}`;
      resolve();
    }).then(() => {
      setTimeout(() => {
        setDownloading(false);
      }, 5000);
    });
  };

  const changeDate = useCallback(() => {
    setFrom(from);
    setTo(to);
    setAggregateTarget(aggregateTarget);
  }, [from, to, aggregateTarget]);

  return (
    <div>
      <OrganizationTitle>日当一覧</OrganizationTitle>
      <OrganizationBody>
        {isAllowanceLoading ? (
          <SimpleLoading />
        ) : (
          <>
            <div className="organization-trips__date-area">
              <p>期間：</p>
              <DatetimePicker
                value={from}
                onChange={d => setFrom(d)}
                showTime={false}
                disabledDays={0}
                showPast
                border
              />
              <span>〜</span>
              <DatetimePicker
                value={to}
                onChange={d => setTo(d)}
                showTime={false}
                disabledDays={0}
                showPast
                border
              />
              <Label>絞り込み：</Label>
              <TheSelect value={aggregateTarget} onChange={e => setAggregateTarget(e.target.value)}>
                <option value="contain_start_or_end">出張期間が含まれている旅程</option>
                <option value="contain_start_date">出発日が含まれている旅程</option>
                <option value="contain_end_date">帰着日が含まれている旅程</option>
              </TheSelect>
              <TheButton onClick={() => changeDate()}>更新</TheButton>
              <TheSelect value={csvTarget} onChange={e => setCsvTarget(e.target.value)}>
                <option value="person">人別集計データ</option>
                <option value="daily">日別集計データ</option>
              </TheSelect>
              <TheSelect value={csvEncoding} onChange={e => setCsvEncoding(e.target.value)}>
                <option value="utf8">Macなど（文字コード：UTF8）</option>
                <option value="sjis">Windows（文字コード：SJIS）</option>
              </TheSelect>
              <DownloadButton type="button" disabled={downloading} onClick={handleCSVDownload}>
                CSVをダウンロード
              </DownloadButton>
            </div>
            <Table>
              <thead>
                <tr>
                  <Th>出張者</Th>
                  <Th>社員番号</Th>
                  <Th>国内日帰り日当数</Th>
                  <Th>国内宿泊日当数</Th>
                  <Th>海外宿泊日当数</Th>
                  <Th>国内日帰り日当単価</Th>
                  <Th>国内宿泊日当単価</Th>
                  <Th>海外宿泊日当単価</Th>
                  <Th>国内日帰り日当合計</Th>
                  <Th>国内宿泊日当合計</Th>
                  <Th>海外宿泊日当合計</Th>
                  <Th>日当合計</Th>
                </tr>
              </thead>
              <tbody>
                {allowanceData.map((item, i) => (
                  <tr key={i}>
                    <Td>{item.name}</Td>
                    <Td>{item.employeeNumber}</Td>
                    <Td>{item.domesticWithoutStay}</Td>
                    <Td>{item.domesticWithStay}</Td>
                    <Td>{item.foreignWithStay}</Td>
                    <Td>{item.domesticDailyAllowanceWithoutStay}</Td>
                    <Td>{item.domesticDailyAllowanceWithStay}</Td>
                    <Td>{item.foreignDailyAllowanceWithStay}</Td>
                    <Td>{item.domesticWithoutStay * item.domesticDailyAllowanceWithoutStay}</Td>
                    <Td>{item.domesticWithStay * item.domesticDailyAllowanceWithStay}</Td>
                    <Td>{item.foreignWithStay * item.foreignDailyAllowanceWithStay}</Td>
                    <Td>
                      {item.domesticWithoutStay * item.domesticDailyAllowanceWithoutStay +
                        item.domesticWithStay * item.domesticDailyAllowanceWithStay +
                        item.foreignWithStay * item.foreignDailyAllowanceWithStay}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </OrganizationBody>
    </div>
  );
};

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;
`;

const Th = styled.th`
  padding: 8px;
`;

const Td = styled.td`
  padding: 8px;
`;

const TheButton = styled(Button)`
  margin-left: 5px;
  padding: 3px;
`;

const TheSelect = styled.select`
  margin-left: 10px;
  margin-right: 5px;
`;

const Label = styled.p`
  padding-left: 16px;
`;

export default Allowances;
