import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import type { ReportJson } from '../../../domain/expenses/report';
import { Report } from '../../../domain/expenses/report';
import type { Item } from '../../../domain/expenses/item';
import ExpensesMain from '../main/main';
import ReportCard from './report_card';
import ReportItemCards from '../report_items/report_item_cards';

interface ReportResponse {
  report: ReportJson;
  trip_report_available: boolean;
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  report?: Report;
  tripReportAvailable: boolean;
  error?: string;
  loading: boolean;
}

class Detail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      tripReportAvailable: false
    };
  }

  componentDidMount() {
    this.fetchReport();
  }

  handlePrintReport = () => {
    open(`/biztra/reports/${this.state.report!.id}/print`, '_blank');
  };

  handleDestroyReport = () => {
    const ret = confirm('本当に削除しますか？');
    if (ret) this.destroyReport();
  };

  handleReapplyReport = () => {
    const ret = confirm('再申請しますか？');
    if (ret) this.reapplyReport();
  };

  handleClickItem = (item: Item) => {
    this.props.history.push(`/biztra/report_items/${item.id}/edit`);
  };

  private async fetchReport() {
    try {
      this.setState({ loading: true });
      const response = await utils.jsonPromise<ReportResponse>(`/biztra/reports/${this.props.match.params.id}`);
      this.setState({
        report: new Report(response.report),
        tripReportAvailable: response.trip_report_available,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async destroyReport() {
    this.setState({ loading: true });
    try {
      await utils.jsonPromise(`/biztra/reports/${this.props.match.params.id}`, {}, 'DELETE');
      this.props.history.push('/biztra/reports');
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  private async reapplyReport() {
    this.setState({ loading: true });
    try {
      await utils.jsonPromise(`/biztra/reports/${this.props.match.params.id}/reapply`, {}, 'PUT');
      this.props.history.push('/biztra/reports');
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  buttonArea() {
    const { report } = this.state;
    const buttonArea = report ? (
      report.isRejected() ? (
        <ButtonArea>
          <DeleteButton onClick={this.handleDestroyReport}>削除</DeleteButton>
          <BaseButton onClick={this.handleReapplyReport}>再申請</BaseButton>
        </ButtonArea>
      ) : (
        <ButtonArea>
          <DeleteButton onClick={this.handleDestroyReport}>削除</DeleteButton>
          <BaseButton onClick={this.handlePrintReport}>印刷</BaseButton>
        </ButtonArea>
      )
    ) : null;
    return buttonArea;
  }

  render() {
    const { tripReportAvailable, loading, error } = this.state;

    return (
      <ExpensesMain title="申請詳細" backLinkPath="/biztra/reports">
        {loading ? (
          <SimpleLoading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TheWrapper>
            <ReportCard report={this.state.report} detail />
            <ReportItemCardsWrapper>
              <ReportItemCards
                items={this.state.report!.items}
                handleAddItem={() => {}}
                onClickItem={this.handleClickItem}
                tripReportAvailable={tripReportAvailable}
                inDetail
              />
            </ReportItemCardsWrapper>
            {this.buttonArea()}
          </TheWrapper>
        )}
      </ExpensesMain>
    );
  }
}

export default Detail;

const primaryBtnColor = '#1d7c2d';

const primaryTxtColor = '#FFFFFF';

const deleteBtnColor = '#faf9f8';

const deleteTxtColor = '#c72f62';

const TheWrapper = styled.div`
  width: 100%;
`;

const ReportItemCardsWrapper = styled.div``;

export const ButtonArea = styled.div`
  margin: 0 calc(-32 / 1440 * 100vw) calc(-32 / 1440 * 100vw);
  padding: 24px;
  background-color: #f1ede5;
  @media screen and (max-width: 767px) {
    margin: 0 -20px -20px;
  }
`;

export const BaseButton = styled.button.attrs({ type: 'button' })`
  width: unset !important;
  background: ${primaryBtnColor};
  color: ${primaryTxtColor};
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.875rem 2.25rem;
  &&:focus,
  &&:hover {
    background: ${primaryBtnColor};
  }
`;

const DeleteButton = styled(BaseButton)`
  background: ${deleteBtnColor};
  color: ${deleteTxtColor};
  border: 1px solid ${deleteTxtColor};
  padding: 0.815rem 2.25rem;
  margin-right: 24px;
  &&:focus,
  &&:hover {
    background: ${deleteBtnColor};
    color: ${deleteTxtColor};
  }
`;
