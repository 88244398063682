import React from 'react';
import _ from 'lodash';

import type Order from '../../../../../domain/order';
import JournalOrganizer from '../../../../../domain/journal_organizer';

interface Props {
  order: Order;
}

interface State {
  copied: boolean;
}

class JournalTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  handleCopy() {
    utils.copyElementToClipBoard(this.refs.table, false);
    this.setState({ copied: true });
  }

  render() {
    const classBase = 'journal-table';
    const jo = new JournalOrganizer(this.props.order);
    const journals = jo.getImportJournals();
    return (
      <>
        <input type="submit" value="仕訳データをコピー" onClick={() => this.handleCopy()} />
        <table className={classBase} ref="table">
          <tbody>
            {journals.map((j, index) => (
              <tr key={index}>
                <td className={`${classBase}__td`}>{j.date}</td>
                <td className={`${classBase}__td`}>{j.debtor}</td>
                <td className={`${classBase}__td`}>{j.debtorSub}</td>
                <td className={`${classBase}__td`}>{j.debtorAmount}</td>
                <td className={`${classBase}__td`}>{j.creditor}</td>
                <td className={`${classBase}__td`}>{j.creditorSub}</td>
                <td className={`${classBase}__td`}>{j.creditorAmount}</td>
                <td className={`${classBase}__td`}>{j.summary}</td>
                <td className={`${classBase}__td`}>{_.join(j.tags, '|')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.copied ? <div className="green">コピーしました</div> : null}
      </>
    );
  }
}

export default JournalTable;
