import type Trip from '@this/src/domain/trip/trip';
import axios, { AxiosError } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@this/components/shared/ui/inputs/button';
import Notification from '@this/src/notification';

type Props = {
  trip: Trip;
  onReloadTrip: () => void;
};

export const ConfirmRequestButton = ({ trip, onReloadTrip }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const disabled = useMemo(() => trip.status === 5, [trip]);

  const handleClick = useCallback(async () => {
    if (disabled || submitting) {
      return;
    }
    const res = window.confirm('見積もり承認依頼をお客様に依頼しますか？');
    if (!res) {
      return;
    }

    setSubmitting(true);
    try {
      await axios.post(`/orders/${trip.currentOrder.id}/confirmations.json`, {});
      onReloadTrip();
    } catch (e) {
      const msg =
        e instanceof AxiosError && e.response?.data?.errors
          ? e.response?.data?.errors
          : '通信環境が不安定です。画面をリロードしてからもう一度お試しください。';

      Notification.error(msg);
      utils.sendErrorObject(e);
    } finally {
      setSubmitting(false);
    }
  }, [disabled, submitting, trip.currentOrder.id, onReloadTrip]);

  return (
    <Button disabled={disabled} loading={submitting} onClick={handleClick}>
      見積もり承認依頼
    </Button>
  );
};
