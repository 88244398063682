import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { rgba } from 'polished';
import { styled } from '@this/src/components/constants/themes';
import { TableCell, TableRow } from '@this/src/components/shared/ui/data_displays/table';
import type HotelOrderItemTodo from '@this/src/domain/arrangement/hotel_order_item_todo';
import { HotelElementProvider } from '@this/src/domain/hotel_element_provider';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Button } from '@this/components/shared/ui/inputs/button';
import _ from 'lodash';
import { OrderItemStatusForm } from '@this/components/arrangement/shared/price_change_form';
import { Fetcher } from '@this/src/util';
import TripStatusIndicator from '../../shared/trip_status_indicator';
import LogModal from '../log_modal';

interface Props {
  todo: HotelOrderItemTodo;
  showDescription: boolean;
  onInventory: () => void;
}

const HotelOrderItemTodoTableRow = observer(({ todo, showDescription, onInventory }: Props) => {
  const [showLogModal, setShowLogModal] = useState<boolean>(false);

  const handleInventory = async () => {
    await Fetcher.put(`/arrangement/hotel_order_item_todos/${todo.orderItemId}/inventory.json`, {});
    onInventory();
  };

  const handleCancelInventory = async () => {
    await Fetcher.put(`/arrangement/hotel_order_item_todos/${todo.orderItemId}/cancel_inventory.json`, {});
    onInventory();
  };

  return (
    <TableRow style={{ background: todo.isUrgent ? rgba('red', 0.3) : 'auto' }}>
      {/* Tripステータス */}
      <TableCell nowrap>
        <TripStatusIndicator status={todo.tripStatus} />
      </TableCell>
      {/* ステータス */}
      <TableCell>
        {todo.orderItemId && (
          <OrderItemStatusForm
            orderItemId={todo.orderItemId}
            jobType={todo.jobType}
            status={todo.status}
            onJobTypeChange={value => {
              todo.jobType = value;
            }}
            onStatusChange={value => {
              todo.status = value;
            }}
            showLabel={false}
            fetchData={handleInventory}
          />
        )}
      </TableCell>
      {/* 自動予約フラグ */}
      <TableCell>{todo.autoBooked ? '◯' : ''}</TableCell>
      {showDescription && (
        <>
          {/* 依頼時の内容 */}
          <TableCell>
            <Text>{todo.traceDescription}</Text>
          </TableCell>
          {/* 右ナビ承認時の内容 */}
          <TableCell>
            <Text>{todo.description}</Text>
          </TableCell>
          {/* 依頼時との差分 */}
          <TableCell>
            {todo.diffDescription &&
              todo.diffDescription.map((section, i) => (
                <DiffSection key={i}>
                  {section.map((line, j) =>
                    line.action === '+' ? (
                      <DiffAddLine key={`${i}-${j}`}>+ {line.element}</DiffAddLine>
                    ) : (
                      <DiffRemoveLine key={`${i}-${j}`}>- {line.element}</DiffRemoveLine>
                    )
                  )}
                </DiffSection>
              ))}
          </TableCell>
        </>
      )}
      {/* 自動承認ステータス */}
      <TableCell>{todo.autoApproved ? '自動' : '手動'}</TableCell>
      {/* 自動承認理由 */}
      <TableCell>{todo.autoApprovedMessage}</TableCell>
      {/* 棚卸ステータス */}
      <TableCell>{todo.inventoriedAt ? '済' : '未'}</TableCell>
      {/* 棚卸実施者 */}
      <TableCell>{todo.inventoriedBy}</TableCell>
      {/* 棚卸日時 */}
      <TableCell>{todo.inventoriedAt?.format('YYYY-MM-DD HH:mm')}</TableCell>
      {/* 法人名 */}
      <TableCell>{todo.organizationName}</TableCell>
      {/* 出発日 */}
      <TableCell nowrap={!showDescription}>{todo.startDate?.format('YYYY-MM-DD')}</TableCell>
      {/* 依頼日 */}
      <TableCell nowrap={!showDescription}>{todo.receivedAt?.format('YYYY-MM-DD HH:mm')}</TableCell>
      {/* チャットメッセージ */}
      <TableCell>
        {todo.lastUserMessage?.split('\n').map((line, i) => (
          <Box key={i}>{line}</Box>
        ))}
      </TableCell>
      {/* Trip ID */}
      <TableCell nowrap={!showDescription}>
        <Link href={`/arrangement/virtual_counter?trip_id=${todo.tripId}`} target="_blank" rel="noopener noreffer">
          {todo.tripId}
        </Link>
      </TableCell>
      {/* Trace ID */}
      <TableCell nowrap={!showDescription}>{todo.traceId}</TableCell>
      {/* プロバイダ */}
      <TableCell nowrap={!showDescription}>{todo.provider && HotelElementProvider[todo.provider]}</TableCell>
      {/* 最新のログメッセージ */}
      <TableCell>
        {todo.lastLogMessage && <span>{todo.lastLogMessage}</span>}
        {todo.lastLogMessageArranger && <span>({todo.lastLogMessageArranger})</span>}
        <p>
          (<Link onClick={() => setShowLogModal(true)}>ログ</Link>)
        </p>
      </TableCell>
      {/* アクション */}
      <TableCell>
        {todo.inventoriedAt ? (
          <Button size="small" onClick={() => handleCancelInventory()}>
            取消
          </Button>
        ) : (
          <Button size="small" onClick={() => handleInventory()}>
            棚卸完了
          </Button>
        )}
      </TableCell>
      {showLogModal && todo.traceId && <LogModal traceId={todo.traceId} onClose={() => setShowLogModal(false)} />}
    </TableRow>
  );
});

const Text = styled.span`
  white-space: pre-wrap;
`;

const DiffSection = styled.div`
  margin-bottom: 0.5rem;
`;

const DiffRemoveLine = styled.div`
  color: ${props => props.theme.redColor};
`;

const DiffAddLine = styled.div`
  color: ${props => props.theme.successColor};
`;

export default HotelOrderItemTodoTableRow;
