import _ from 'lodash';
import MessageTemplate from './message_template';

interface MessageTemplateListArgs {
  message_templates: MessageTemplate[];
}

class MessageTemplateList {
  public list: MessageTemplate[];

  constructor(args: MessageTemplateListArgs) {
    this.list =
      args.message_templates.map(t => {
        return new MessageTemplate(t);
      }) || [];
  }

  find(id: number | null | void) {
    this.list.find((m: MessageTemplate) => {
      return m.id === id;
    });
  }

  last() {
    _.last(this.list);
  }

  push(m: MessageTemplate) {
    this.list.push(m);
  }

  unshift(m: MessageTemplate) {
    this.list.unshift(m);
  }

  remove(m: MessageTemplate) {
    const i = this.list.indexOf(m);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  update(newMessageTemplate: MessageTemplate) {
    const m: MessageTemplate | void = this.find(newMessageTemplate.id);
    if (m !== undefined) {
      _.assign(m, newMessageTemplate);
    }
  }
}

export default MessageTemplateList;
