import React from 'react';
import { styled } from '@this/constants/themes';
import { Datepicker } from '@this/shared/ui/inputs/datepicker';
import { ModalFooter, ModalHeader, ModalBody, Modal } from '@this/shared/ui/feedbacks/modal';
import { Flex } from '@this/shared/ui/layout/flex';
import { Button } from '@this/shared/ui/inputs/button';
import InfoTemplate from './info/info.template';

interface Props {
  submitting: boolean;
  report: any | null;
  onSubmit: (payDate: Date) => void;
  onCancel: () => void;
}

export const ApproveModalTemplate = ({ report, ...props }: Props) => (
  <Modal size="small" open={!!report} onClose={props.onCancel}>
    {report && <ModalContent report={report} {...props} />}
  </Modal>
);

const formId = 'tripReportApprove';
const ModalContent = ({
  report,
  submitting,
  onSubmit,
  onCancel
}: { report: NonNullable<Props['report']> } & Omit<Props, 'report'>) => {
  const [payDate, setPayDate] = React.useState<Date | null>(report.payDate?.toDate() ?? null);

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!payDate) {
        return;
      }

      onSubmit(payDate);
    },
    [payDate, onSubmit]
  );

  return (
    <>
      <ModalHeader>承認</ModalHeader>
      <ModalBody>
        <form id={formId} onSubmit={handleSubmit}>
          <p>以下の出張報告を承認します。よろしいですか？</p>
          <Flex>
            <InfoTemplate selectedReport={report} />
          </Flex>
          <Flex>
            <Label>支払日</Label>
            <Datepicker value={payDate ?? undefined} onChange={setPayDate} disabledClear />
          </Flex>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onCancel}>
          キャンセル
        </Button>
        <Button form={formId} type="submit" loading={submitting} disabled={!payDate}>
          承認
        </Button>
      </ModalFooter>
    </>
  );
};

const Label = styled.div`
  width: 120px;
  font-weight: bold;
`;

export default ApproveModalTemplate;
