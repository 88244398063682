import React, { useMemo } from 'react';
import type TransportElement from '@this/src/domain/transport_element';
import type { OrderItemStepTodoFieldStore } from '@this/src/domain/arrangement/order_item_step_todo_field_store';
import { styled } from '@this/src/components/constants/themes';

interface Props {
  orderItemStepTodoFieldStore: OrderItemStepTodoFieldStore;
}

const OrderItemStepWorkDialogFields: React.FC<Props> = ({ orderItemStepTodoFieldStore }) => {
  const fields = useMemo(() => orderItemStepTodoFieldStore.viewFields(), [orderItemStepTodoFieldStore]);
  const transportElement = orderItemStepTodoFieldStore.orderItem.elements[0] as TransportElement | undefined;
  const reservation = transportElement?.transportElementReservations?.[0];
  const ticketOprReservation = transportElement?.ticketOprReservations?.[0];

  return (
    <>
      {fields.map(field => (
        <Li key={field.label}>
          <label>{field.label}</label>
          {field.type === 'transportText' ? (
            transportElement && <Text>{field.value(transportElement)}</Text>
          ) : field.type === 'transportSelect' ? (
            transportElement && (
              <Text>
                {orderItemStepTodoFieldStore.todo.orderItemStepTodoInputFields.enumLabel(field, transportElement)}
              </Text>
            )
          ) : field.type === 'transportReservationView' ? (
            reservation && <Text>{field.value(reservation)}</Text>
          ) : field.type === 'ticketOprReservationView' ? (
            ticketOprReservation && <Text>{field.value(ticketOprReservation)}</Text>
          ) : (
            <></>
          )}
        </Li>
      ))}
    </>
  );
};

const Li = styled.li`
  margin-bottom: 16px;
`;

const Text = styled.p`
  white-space: pre-wrap;
  padding-left: 16px;
  word-break: break-all;
`;

export default OrderItemStepWorkDialogFields;
