import React from 'react';
import _ from 'lodash';
import { withTheme } from 'styled-components';
import {
  Header,
  Icon,
  Date,
  Remove,
  Body
} from '@this/components/reserve_trip/select/outline_item/outline_item.template';
import { Name } from '@this/src/components/reserve_trip/select/transport_box/transport_box_foreign.template';
import { BoxButton } from '@this/components/reserve_trip/select/transport_box/right/right_in_outline.template';
import { BoxButtonText } from '@this/components/reserve_trip/select/shared/box_parts';
import {
  Block,
  CheckLabel,
  Card,
  CardLeft,
  CardRight,
  CardPrice,
  Text,
  BlockButtonArea
} from '@this/components/trips_management/trips/transport_block/transport_block.template';

import type NonOrderItem from '@this/domain/non_order_item';
import type HotelElement from '@this/domain/hotel_element';

interface Props {
  icon: number;
  item: NonOrderItem;
  element: HotelElement;
  editing: boolean;
  editable: boolean;
  deselectItemType?: (e: React.MouseEvent<HTMLElement>) => void;
  startEditingNonOrderItem?: (item: NonOrderItem) => void;
  toggleNonOrderItemNeed?: (item: NonOrderItem) => void;
  theme: { serviceName: string };
}

const HotelBlockTemplate: React.SFC<Props> = ({
  icon,
  item,
  element,
  editing,
  editable,
  deselectItemType,
  startEditingNonOrderItem,
  toggleNonOrderItemNeed,
  theme
}) => (
  <Block className="select-outline__item-block">
    <Header>
      <Icon>{icon}</Icon>
      <Date>{`宿泊施設：${element.dateStr()}`}</Date>
      {editable && item.isNeed && (
        <CheckLabel>
          <input type="checkbox" checked={false} disabled />
          システムで手配
        </CheckLabel>
      )}
      {editable && toggleNonOrderItemNeed && (
        <Remove onClick={() => toggleNonOrderItemNeed(item)}>{item.isNeed ? '削除する' : '元に戻す'}</Remove>
      )}
    </Header>
    <Body>
      {item.isNeed && (
        <Card>
          <CardLeft>
            <Name>{element.name}</Name>
            <Text>{element.address.length !== 0 && <p>{element.address}</p>}</Text>
          </CardLeft>
          <CardRight>
            <>
              <CardPrice>{utils.formatPriceWithMark(item.totalPrice())}</CardPrice>
              {editable && startEditingNonOrderItem && deselectItemType && (
                <BlockButtonArea>
                  <BoxButton
                    selected={editing}
                    onClick={e => (editing ? deselectItemType(e) : startEditingNonOrderItem(item))}
                  >
                    <BoxButtonText className="select-transport-box__right-gray__button-text">
                      {editing ? (
                        <span>
                          編集を
                          <br />
                          キャンセル
                        </span>
                      ) : (
                        '編集 >'
                      )}
                    </BoxButtonText>
                  </BoxButton>
                </BlockButtonArea>
              )}
            </>
          </CardRight>
        </Card>
      )}
    </Body>
  </Block>
);

export default withTheme(HotelBlockTemplate);
