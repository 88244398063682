import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Popover, FormControlLabel, Checkbox, FormGroup, Box, FormLabel } from '@material-ui/core';
import type { OrderItemCategoryKey } from '@this/src/domain/order_item/order_item_category';
import { OrderItemCategory, OrderItemCategoryKeys } from '@this/src/domain/order_item/order_item_category';

interface Props {
  selectedOrderItemCategories: OrderItemCategoryKey[];
  onChange: (categories: OrderItemCategoryKey[]) => void;
}

const OrderItemStepCategorySelector = observer(({ selectedOrderItemCategories, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const categoryKeys = useMemo(() => OrderItemCategoryKeys.filter(k => k !== 'message'), []);

  const handleClickOrderItemFilterButton = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseOrderItemFilter = () => {
    setAnchorEl(null);
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const categories = (e.target.checked ? categoryKeys : []) as OrderItemCategoryKey[];
    onChange(categories);
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>, category: OrderItemCategoryKey) => {
    const categories = e.target.checked
      ? selectedOrderItemCategories.concat([category])
      : _.without(selectedOrderItemCategories, category);
    onChange(categories);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClickOrderItemFilterButton}
        style={{ backgroundColor: 'white' }}
      >
        絞り込み
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseOrderItemFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box display="flex">
          <FormGroup style={{ padding: '5px' }}>
            <FormLabel>カテゴリ</FormLabel>
            <FormControlLabel
              label="すべて選択"
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOrderItemCategories.length === categoryKeys.length}
                  onChange={handleSelectAll}
                />
              }
            />
            {categoryKeys.map(key => (
              <FormControlLabel
                key={key}
                label={OrderItemCategory[key]}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedOrderItemCategories.includes(key)}
                    onChange={e => {
                      handleSelect(e, key);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
});

export default OrderItemStepCategorySelector;
