import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import jsonPromiseContext from '@this/src/components/constants/json_promise_context';
import { sortBarApprovalsLeadtime } from '@this/components/organization/dashboard/share/graph';
import type { DashboardProps } from '../../share';
import type { DashboardRequest } from '../../share/fetch';
import type { BarApprovalsLeadtime } from '../../share/approvals_leadtime_pile_up_graph';
import { chartOptions, getBarData, getBarHeight } from '../../share/approvals_leadtime_pile_up_graph';
import { fetchDashboard } from '../../share/fetch';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

const USECASE = { section: 'approval_lead_time_by_project', pattern: 'cache' };

type Props = DashboardProps;

interface State {
  loading: boolean;
  leadtimes: BarApprovalsLeadtime[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  leadtimes: State['leadtimes'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    leadtimes: result.leadtimes,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardApprovalsLeadtimeSection: React.FC<Props> = props => {
  const jsonPromise = useContext(jsonPromiseContext);

  const [state, setState] = useState<State>({
    loading: true,
    leadtimes: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, leadtimes, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  const data = useMemo(() => getBarData(sortBarApprovalsLeadtime(leadtimes, 'desc'), 'default'), [leadtimes]);
  const height = useMemo(() => getBarHeight(leadtimes), [leadtimes]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    fetchSummary({ setState, jsonPromise, ...props });
  }, [type, from, to, refreshAt, props.refreshAt]);

  return (
    <>
      <h3 className="graph_title">承認リードタイム（プロジェクト別）</h3>
      {loading ? (
        <SimpleLoading />
      ) : (
        <Bar data={data} width={550} height={height} options={{ indexAxis: 'y', ...chartOptions }} />
      )}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

export default DashboardApprovalsLeadtimeSection;
