import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { Title } from '../god';

type SystemInfo = {
  du: string[];
};

const System = observer(() => {
  const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);

  useEffect(() => {
    const fetchSystemInfo = async () => {
      try {
        const response = await fetch('/god/system.json');
        const data: SystemInfo = await response.json();
        setSystemInfo(data);
      } catch (error) {
        console.error('Failed to fetch system info:', error);
      }
    };

    fetchSystemInfo();
  }, []);

  return (
    <>
      <Title>システム情報</Title>
      <Box padding="20px">
        {systemInfo?.du.map(du => (
          <div key={du}>{du}</div>
        ))}
      </Box>
    </>
  );
});

export default System;
