import type { Moment } from 'moment';
import moment from 'moment';
import { computed, observable } from 'mobx';

export type BulkTicketUnavailableTermArgs = {
  id: number;

  from: string;

  to: string;
};

export default class BulkTicketUnavailableTerm {
  @observable
  id: number;

  @observable
  from: Moment;

  @observable
  to: Moment;

  constructor(args: BulkTicketUnavailableTermArgs) {
    this.id = args.id;
    this.from = moment(args.from);
    this.to = moment(args.to);
  }

  @computed
  get description() {
    return `${this.from.format('YYYY/MM/DD')} 〜 ${this.to.format('YYYY/MM/DD')}`;
  }

  submitParams() {
    return {
      from: this.from.toString(),
      to: this.to.toString()
    };
  }
}
