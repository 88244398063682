import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';

import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { doubleDigits } from '@this/src/util';
import { InLabel, FieldsWrapper, Field, ToggleField, InputArea, AddressArea, Select } from '../search/search';

import moment from '../../../lib/moment';
import SearchTravelersBlock from './search_travelers_block/search_travelers_block';
import SearchPeoplenumBlock from './search_peoplenum_block/search_peoplenum_block.template';
import SearchHotelOptionBlock from './search_hotel_option_block/search_hotel_option_block.template';
import type { SearchTemplateProps } from './types';
import AutoCompletableInput from '../autocompletable_input/autocompletable_input';
import DatetimePicker from '../datetime_picker/datetime_picker';

type Props = {
  getCurrentPlace: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  isLoadingCurrentPlace: boolean;
} & SearchTemplateProps;

class SearchRoundTrip extends React.Component<Props> {
  handlePlaceChange = (name: 'origin' | 'destination', value: string, address: string) => {
    const hqi = this.hotelQueryItem();
    switch (name) {
      case 'origin':
        // 往路の出発地 = 復路の目的地
        this.outwardQueryItem().setOrigin(value, address);
        this.homewardQueryItem().setDestination(value, address);
        if (hqi) hqi.setOrigin(value, address);
        break;
      case 'destination': {
        // 往路の目的地 = ホテルの宿泊地 = 復路の出発地
        this.outwardQueryItem().setDestination(value, address);
        if (hqi) hqi.setDestination(value, address);
        this.homewardQueryItem().setOrigin(value, address);
        break;
      }
      default:
        throw new Error(`never: ${name}`);
    }
  };

  handleChangeDate = (name: 'outdate' | 'homedate', date: Moment) => {
    switch (name) {
      case 'outdate': {
        // 往路の出発日 = ホテルのチェックイン日
        this.outwardQueryItem().setOutdate(date);
        const hqi = this.hotelQueryItem();
        if (hqi) hqi.setOutdate(date);

        // 往路の出発日が復路の出発日よりも未来だったら強制的に往路出発日+1にする
        const outdateMoment = moment(date);
        const homedateMoment = moment(this.homewardQueryItem().outdate);
        if (outdateMoment >= homedateMoment) {
          this.homewardQueryItem().setOutdate(moment(outdateMoment).add(1, 'day'));
        }
        break;
      }
      case 'homedate': {
        // 復路のの出発日 = ホテルのチェックアウト日
        const hqi = this.hotelQueryItem();
        if (hqi) hqi.setHomedate(date);
        this.homewardQueryItem().setOutdate(date);
        break;
      }
      default:
        throw new Error(`never: ${name}`);
    }
  };

  handleChange =
    (name: 'outhour' | 'outmin' | 'outtype' | 'homehour' | 'homemin' | 'hometype') =>
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      switch (name) {
        case 'outhour':
          this.outwardQueryItem().setOuthour(value);
          break;
        case 'outmin':
          this.outwardQueryItem().setOutmin(value);
          break;
        case 'outtype':
          if (value !== 'arrival' && value !== 'departure') {
            throw new Error(`unexpected outtype: ${value}`);
          }
          this.outwardQueryItem().setOuttype(value);
          break;
        case 'homehour':
          this.homewardQueryItem().setOuthour(value);
          break;
        case 'homemin':
          this.homewardQueryItem().setOutmin(value);
          break;
        case 'hometype':
          if (value !== 'arrival' && value !== 'departure') {
            throw new Error(`unexpected outtype: ${value}`);
          }
          this.homewardQueryItem().setOuttype(value);
          break;
        default:
          throw new Error(`never: ${name}`);
      }
    };

  startDateCalendarInput = (kind: 'outdate' | 'homedate', ref_name: string, calendar_textbox_id: string) => () => {
    const transportDatePicker: any = this.refs[ref_name];
    switch (kind) {
      case 'outdate':
        transportDatePicker.initializeDate(this.outwardQueryItem().outdate);
        break;
      case 'homedate':
        transportDatePicker.initializeDate(this.homewardQueryItem().outdate);
        break;
      default:
        throw new Error(`never: ${name}`);
    }
    const element = document.getElementById(calendar_textbox_id);
    if (element) element.classList.add('selected-date-textbox');
  };

  private outwardQueryItem() {
    const items = this.props.query.getInputItems();
    return items[0];
  }

  private hotelQueryItem() {
    // 旅工房はホテルが無い
    const items = this.props.query.items;
    return items.find(i => i.itemType === 'hotel');
  }

  private homewardQueryItem() {
    const items = this.props.query.getInputItems();
    return items[items.length - 1];
  }

  render() {
    const { query, user, handleChange, getCurrentPlace, isLoadingCurrentPlace, availableOptions } = this.props;
    const restrictionDistanceAndTimeAvailability =
      availableOptions?.includes('restriction_distance_and_time') ?? false;
    return (
      <div>
        <FieldsWrapper className="search__block__body__fields-wrapper">
          <Field>
            <InLabel htmlFor="origin">出発地</InLabel>
            <InputArea wrapped>
              <AutoCompletableInput
                onChange={this.handlePlaceChange}
                value={this.outwardQueryItem().origin}
                id="origin"
              />
              <div className="search__input-current-place">
                {isLoadingCurrentPlace ? (
                  <div className="search-loader-line-mask">
                    <SearchLoaderLine />
                  </div>
                ) : (
                  <CurrentPlace title="現在地を取得" onClick={getCurrentPlace}>
                    現在地
                  </CurrentPlace>
                )}
              </div>
            </InputArea>
            <AddressArea>
              <p>{this.outwardQueryItem().originAddress}</p>
              {restrictionDistanceAndTimeAvailability && user?.hasOrganizationBase() && (
                <p>{`拠点情報: ${user?.organizationBase.address}`}</p>
              )}
            </AddressArea>
          </Field>
        </FieldsWrapper>
        <FieldsWrapper className="search__block__body__fields-wrapper">
          <Field>
            <InLabel htmlFor="destination">目的地</InLabel>
            <InputArea wrapped>
              <AutoCompletableInput
                onChange={this.handlePlaceChange}
                value={this.outwardQueryItem().destination}
                id="destination"
              />
            </InputArea>
            <AddressArea>
              <p>{this.outwardQueryItem().destinationAddress}</p>
            </AddressArea>
          </Field>
        </FieldsWrapper>
        <FieldsWrapper className="search__block__body__fields-wrapper">
          <Field>
            <InLabel>往路</InLabel>
            <InputArea
              style={{ alignItems: 'center' }}
              wrapped
              id="simple_outdate_input"
              className="calendar-input-div"
            >
              <DatetimePicker
                value={moment(this.outwardQueryItem().outdate)}
                dateFormat="YYYY/MM/DD (ddd)"
                showToday
                onChange={date => this.handleChangeDate('outdate', date)}
                width="115px"
                disabledDays={0}
                calenderClass="rc-calendar-trip"
              />
              <div style={{ display: 'flex' }} className="search__select-wrapper">
                <Select
                  name="outhour"
                  value={this.outwardQueryItem().outhour}
                  onChange={this.handleChange('outhour')}
                >
                  <option value="">-</option>
                  {_.times(24, i => (
                    <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                      {i}
                    </option>
                  ))}
                </Select>
                <span style={{ marginRight: '15px' }}>時</span>
              </div>
              <div style={{ display: 'flex' }} className="search__select-wrapper">
                <Select
                  name="outmin"
                  value={this.outwardQueryItem().outmin}
                  onChange={this.handleChange('outmin')}
                >
                  <option value="">-</option>
                  {_.times(6, i => (
                    <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                      {i * 10}
                    </option>
                  ))}
                </Select>
                <span style={{ marginRight: '15px' }}>分</span>
              </div>
              <div className="search__select-wrapper">
                <Select
                  name="outtype"
                  value={this.outwardQueryItem().outtype}
                  onChange={this.handleChange('outtype')}
                >
                  <option value="departure">出発</option>
                  <option value="arrival">到着</option>
                </Select>
              </div>
            </InputArea>
          </Field>
        </FieldsWrapper>
        <FieldsWrapper className="search__block__body__fields-wrapper">
          <ToggleField disabled={false}>
            <InLabel>復路</InLabel>
            <InputArea
              style={{ alignItems: 'center' }}
              wrapped
              id="simple_outdate_input"
              className="calendar-input-div"
            >
              <DatetimePicker
                value={moment(this.homewardQueryItem().outdate)}
                dateFormat="YYYY/MM/DD (ddd)"
                onChange={date => this.handleChangeDate('homedate', date)}
                width="115px"
                disabledDays={0}
                calenderClass="rc-calendar-trip"
              />
              <div style={{ display: 'flex' }} className="search__select-wrapper">
                <Select
                  name="homehour"
                  value={this.homewardQueryItem().outhour}
                  onChange={this.handleChange('homehour')}
                >
                  <option value="">-</option>
                  {_.times(24, i => (
                    <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                      {i}
                    </option>
                  ))}
                </Select>
                <span style={{ marginRight: '15px' }}>時</span>
              </div>
              <div style={{ display: 'flex' }} className="search__select-wrapper">
                <Select
                  name="homemin"
                  value={this.homewardQueryItem().outmin}
                  onChange={this.handleChange('homemin')}
                >
                  <option value="">-</option>
                  {_.times(6, i => (
                    <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                      {i * 10}
                    </option>
                  ))}
                </Select>
                <span style={{ marginRight: '15px' }}>分</span>
              </div>
              <div className="search__select-wrapper">
                <Select
                  name="hometype"
                  value={this.homewardQueryItem().outtype}
                  onChange={this.handleChange('hometype')}
                >
                  <option value="departure">出発</option>
                  <option value="arrival">到着</option>
                </Select>
              </div>
            </InputArea>
          </ToggleField>
        </FieldsWrapper>
        {user ? (
          <SearchTravelersBlock query={query} user={user} handleChange={handleChange} />
        ) : (
          <SearchPeoplenumBlock query={query} handleChange={handleChange} />
        )}
        <SearchHotelOptionBlock query={query} handleChange={handleChange} />
      </div>
    );
  }
}

const SearchLoaderLine = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: inset 0 1px 1px 3px ${props => props.theme.linkColor};
`;

const CurrentPlace = styled(A)`
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
`;

export default SearchRoundTrip;
