import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import jsonPromiseContext from '@this/src/components/constants/json_promise_context';
import { styled } from '@this/constants/themes';
import { sortBarApprovalStatusCounts } from '@this/components/organization/dashboard/share/graph';
import type { DashboardProps, ApprovalStatus } from '../../share';
import type { DashboardRequest } from '../../share/fetch';
import { fetchDashboard } from '../../share/fetch';
import {
  chartOptions,
  getBarDataApprovalStatusCounts,
  getBarHeight
} from '../../share/approval_rejection_count_graph';
import { RefreshCacheButton } from '../../share/refresh_cache_button';

// Usecase::Organization::DashboardTrips::ApprovalStatusCountByTravelerDepartmentPatterns::CachePatternUsecaseを使用
const USECASE = { section: 'approval_status_count_by_traveler_department', pattern: 'cache' };

interface BarApprovalStatusCounts {
  label: string;
  approvalStatusCounts: Record<ApprovalStatus, number>;
}
interface State {
  loading: boolean;
  approvalStatusCounts: BarApprovalStatusCounts[];
  lastUpdatedAt: Moment | null;
  refreshAt: Moment | null;
}

interface Response {
  approvalStatusCounts: State['approvalStatusCounts'];
  last_updated_at: string;
}

const fetchSummary = async ({ setState, ...props }: DashboardRequest<State>) => {
  setState(state => ({ ...state, loading: true }));
  const result = await fetchDashboard<Response>({ ...USECASE, ...props });
  setState(state => ({
    ...state,
    loading: false,
    approvalStatusCounts: result.approvalStatusCounts,
    lastUpdatedAt: result.last_updated_at ? moment(result.last_updated_at) : null
  }));
};

const DashboardApprovalRejectionCountByTravelerDepartmentSection: React.FC<DashboardProps> = props => {
  const jsonPromise = useContext(jsonPromiseContext);

  const [state, setState] = useState<State>({
    loading: true,
    approvalStatusCounts: [],
    lastUpdatedAt: null,
    refreshAt: null
  });
  const { loading, approvalStatusCounts, lastUpdatedAt, refreshAt } = state;
  const { urlQuery, organizationId } = props;
  const { type, from, to } = urlQuery;

  const data = useMemo(
    () => getBarDataApprovalStatusCounts(sortBarApprovalStatusCounts(approvalStatusCounts, 'desc'), 'default'),
    [approvalStatusCounts]
  );
  const height = useMemo(() => getBarHeight(approvalStatusCounts), [approvalStatusCounts]);

  const handleRefreshCache = useCallback(() => {
    setState(state => ({ ...state, refreshAt: moment() }));
  }, [setState]);

  useEffect(() => {
    fetchSummary({ setState, jsonPromise, ...props });
  }, [type, from, to, refreshAt, props.refreshAt]);

  return (
    <>
      <Wrap className="graph_title">
        <span>承認・却下件数（所属部署別）</span>
      </Wrap>
      {loading ? (
        <SimpleLoading />
      ) : (
        <Bar data={data} width={550} height={height} options={{ indexAxis: 'y', ...chartOptions }} />
      )}
      <RefreshCacheButton
        cacheType={USECASE.section}
        dateType={type}
        from={from}
        to={to}
        organizationId={organizationId}
        lastUpdatedAt={lastUpdatedAt}
        onRefreshCache={handleRefreshCache}
      />
    </>
  );
};

const Wrap = styled.h3`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

export default DashboardApprovalRejectionCountByTravelerDepartmentSection;
