export type SmartHrImportDataStatusResponseArgs = {
  total: string;
  unstarted: string;
  skipped: string;
  succeeded: string;
  failed: string;
};

export type SmartHrImportDataStatusArgs = {
  total: string;
  unstarted: string;
  skipped: string;
  succeeded: string;
  failed: string;
};

export const convertSmartHrImportDataStatusResponseToArgs = (response: SmartHrImportDataStatusResponseArgs) => {
  const args: SmartHrImportDataStatusArgs = {
    total: response.total,
    unstarted: response.unstarted,
    skipped: response.skipped,
    succeeded: response.succeeded,
    failed: response.failed
  };
  return args;
};

export default class SmartHrImportDataStatus {
  public readonly total: string;

  public readonly unstarted: string;

  public readonly skipped: string;

  public readonly succeeded: string;

  public readonly failed: string;

  constructor(args: SmartHrImportDataStatusArgs) {
    this.total = args.total;
    this.unstarted = args.unstarted;
    this.skipped = args.skipped;
    this.succeeded = args.succeeded;
    this.failed = args.failed;
  }
}
