import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { FormControl } from '@this/shared/ui/inputs/form_control';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/components/shared/ui/feedbacks/modal';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Input } from '@this/components/shared/ui/inputs/input';
import { Radio, RadioGroup } from '@this/shared/ui/inputs/radio';
import type { StatusKey } from '@this/domain/knowledge_image/knowledge_image';
import { StatusKeys, Status } from '@this/domain/knowledge_image/knowledge_image';
import { Fetcher, HTTPError } from '@this/src/util';

interface Props {
  open: boolean;
  onClose: () => void;
  afterSubmit: () => void;
  id?: number | null;
  fileName?: string | null;
  fileStatus?: StatusKey;
  modalType: string;
}

const KnowledgeImageModal = observer(
  ({ open, onClose, afterSubmit, id, fileName, fileStatus, modalType }: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState<string>(fileName || '');
    const [status, setStatus] = useState<StatusKey>(fileStatus ?? StatusKeys[0]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const type = modalType === 'add' ? '登録' : modalType === 'mod' ? '更新' : '削除';

    const handleSubmit = useCallback(async () => {
      const uri = id ? `/admin/knowledge_images/${id}` : '/admin/knowledge_images';
      const method = id ? 'PUT' : 'POST';
      const formData = new FormData();
      formData.append('file', file || '');
      formData.append('name', name);
      formData.append('status', status);
      setIsLoading(true);
      try {
        await Fetcher.upload(uri, formData, { method });
        onClose();
        afterSubmit();
      } catch (e) {
        if (e instanceof HTTPError && e.response?.data?.errors) {
          setErrors(e.response?.data?.errors);
        }
      } finally {
        setIsLoading(false);
      }
    }, [id, file, name, status, onClose, afterSubmit]);

    const handleDelete = useCallback(async () => {
      setErrors([]);
      if (id == null) {
        return;
      }
      setIsLoading(true);
      try {
        await Fetcher.delete(`/admin/knowledge_images/${id}`);
        onClose();
        afterSubmit();
      } catch (e) {
        if (e instanceof HTTPError && e.response?.data?.errors) {
          setErrors(e.response?.data?.errors);
        }
      } finally {
        setIsLoading(false);
      }
    }, [afterSubmit, id, onClose]);

    const onFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      setErrors([]);
      const file = e.target.files ? e.target.files[0] : null;
      if (!file) return;
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      const acceptExtension = ['pdf', 'png', 'jpg', 'jpeg'];
      if (fileExtension && !acceptExtension.includes(fileExtension)) {
        setErrors(['ファイルはpdf/png/jpg/jpegで登録してください。']);
        e.target.value = '';
        return;
      }
      setFile(file);
    }, []);

    return (
      <Modal
        open={open}
        onClose={onClose}
        size="medium"
        className={modalType === 'delete' ? 'e2e-confirm-delete' : 'e2e-modal-edit'}
      >
        <ModalHeader>ファイルを{type}する</ModalHeader>
        <ModalBody style={{ margin: '10px' }}>
          {modalType === 'delete' ? (
            <Text>
              {name}を削除してよろしいですか？
              <br />
              ※この操作は取り消せません
            </Text>
          ) : (
            <>
              <Box margin="10px 0" className="e2e-status">
                <Label>公開ステータス</Label>
                <RadioGroup
                  layout="horizontal"
                  value={status}
                  onChange={(_, value) => setStatus(value as StatusKey)}
                  style={{ marginTop: '10px' }}
                >
                  {StatusKeys.map((statusKey, i) => (
                    <Radio key={statusKey} name={statusKey} value={statusKey}>
                      {Status[statusKey]}
                    </Radio>
                  ))}
                </RadioGroup>
              </Box>
              <Box margin="20px 0 10px 0" className="e2e-name">
                <Input
                  type="text"
                  placeholder="ファイル名"
                  fullWidth
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Box>
              <Box className="e2e-file">
                <input
                  type="file"
                  accept="image/jpg,image/jpeg,image/png,application/pdf"
                  onChange={e => onFileInputChange(e)}
                />
              </Box>
            </>
          )}

          {errors.length > 0 && (
            <FormControl fullWidth margin="dense">
              {errors.map((error, i) => (
                <Box key={i} color="red">
                  {error}
                </Box>
              ))}
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          {isLoading && <SimpleLoading />}
          {modalType === 'delete' ? (
            <Button onClick={handleDelete} disabled={isLoading}>
              はい
            </Button>
          ) : (
            <Button onClick={handleSubmit} disabled={isLoading}>
              {type}
            </Button>
          )}
          <Button onClick={onClose} disabled={isLoading}>
            {modalType === 'delete' ? 'いいえ' : '取り消し'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

const Text = styled.span`
  font-size: 14px;
  color: ${props => props.theme.textColor};
`;

const Label = styled.label`
  font-weight: normal;
`;

export default KnowledgeImageModal;
