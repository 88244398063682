import React from 'react';

import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import moment from '@this/lib/moment';
import { Flex } from '@this/shared/ui/layout/flex';
import type Trip from '@this/domain/trip/trip';
import { ReceiptDetail } from './receipt_detail';
import type { ReceiptInfo, FlightInfo } from './type';

type Props = {
  className?: string;
  receipt: ReceiptInfo;
  trip: Trip;
  flightInfo: FlightInfo;
};

type KVContent = { label: string; value: React.ReactNode };

export const Receipt = ({ className, receipt, trip, flightInfo }: Props) => {
  const order = trip.order;
  const isCanceled = React.useMemo<boolean>(() => trip.isTripCanceled(), [trip]);
  const totalPrice = React.useMemo<number>(
    () => (isCanceled ? trip.cancelFee() : trip.totalPrice()),
    [isCanceled, trip]
  );
  const receiptNo = React.useMemo<string>(() => {
    const str = receipt.id.toString().padStart(8, '0');
    return `${str.slice(0, 4)}-${str.slice(4)}`;
  }, [receipt.id]);

  const mainContents = React.useMemo<KVContent[]>(
    () => [
      { label: '宛名', value: `${receipt.name} 様` },
      {
        label: '金額',
        value: (
          <>
            ￥{utils.digits(totalPrice)} <small>（税込み）</small>
          </>
        )
      }
    ],
    [receipt.name, totalPrice]
  );

  const secondaryContents = React.useMemo<KVContent[]>(
    () => [
      { label: 'ただし書き', value: isCanceled ? 'キャンセル代として' : '旅費交通費として' },
      {
        label: 'お支払い',
        value: 'クレジットカード利用'
      },
      { label: '決済日', value: moment(order.paid_at).format('YYYY年MM月DD日') }
    ],
    [isCanceled, order.paid_at]
  );

  return (
    <Root className={className}>
      <Button size="small" color="sub" onClick={() => window.print()}>
        領収書を印刷する
      </Button>

      <Content>
        <ContentHeader>
          <ContentTitle>領収書</ContentTitle>
          <ContentHeaderCaption>
            発行日: {moment(receipt.created_at).format('YYYY年MM月DD日')}
          </ContentHeaderCaption>
        </ContentHeader>
        <ContentBody>
          <ContentMain>
            {mainContents.map(kv => (
              <Flex key={kv.label} alignItems="center">
                <ContentLabel>{kv.label}</ContentLabel>
                <ContentValue>{kv.value}</ContentValue>
              </Flex>
            ))}
          </ContentMain>

          <ContentSecondary>
            {secondaryContents.map(kv => (
              <Flex key={kv.label} alignItems="center">
                <ContentLabel>{kv.label}</ContentLabel>
                <ContentValue>{kv.value}</ContentValue>
              </Flex>
            ))}
            <ContentSecondaryBottomText>上記正に領収いたしました。</ContentSecondaryBottomText>
          </ContentSecondary>

          <ReceiptNumber>
            [電子領収書につき印紙不要]
            <br />
            No. {receiptNo}
          </ReceiptNumber>

          <Signature>
            株式会社トランスファーデータ
            <br />
            〒105-0014
            <br />
            東京都港区芝3丁目5−7
            <br />
            TEL:03-6843-4294
            <br />
            東京都知事登録旅行業第3-7247号
          </Signature>

          {!isCanceled && (
            <ReceiptDetailArea>
              <ReceiptDetail trip={trip} flightInfo={flightInfo} totalPrice={totalPrice} />
            </ReceiptDetailArea>
          )}
        </ContentBody>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  padding: 8px;
  font-size: 11px;
`;

const Content = styled.div`
  border: 1px solid ${props => props.theme.grayBorderColor};
  background-color: ${props => props.theme.grayBgColor};
  margin-top: 8px;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 8px;
  background-color: ${props => props.theme.backHeaderBgColor};
`;

const ContentTitle = styled.h1`
  font-size: 15px;
`;

const ContentHeaderCaption = styled.div``;

const ContentBody = styled.div`
  padding: 8px;
`;

const ContentMain = styled.div`
  font-size: 15px;
  font-weight: bold;

  & > * {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${props => props.theme.accentBorderColor};
  }
`;
const ContentSecondary = styled.div`
  font-weight: bold;
  margin-top: 16px;
`;

const ContentSecondaryBottomText = styled.p`
  margin-top: 16px;
`;

const ContentLabel = styled.div`
  width: 70px;
`;
const ContentValue = styled.div``;

const ReceiptNumber = styled.p`
  margin-top: 16px;
`;

const Signature = styled.p`
  margin-top: 16px;
`;

const ReceiptDetailArea = styled.section`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px dashed ${props => props.theme.grayTextColor};
`;
