import React from 'react';
import ApproversMemberList from './approver_members_list/approver_members_list';
import type User from '../../../../domain/user/user';
import type { WorkflowTypeKeys } from '../departments';

interface Props<T extends WorkflowTypeKeys> {
  i: number;
  s: number;
  workflowType?: T;
  selectedId: number;
  rawMembers: User[];
  members: User[];
  auth?: {
    id?: number;
    department_id?: number;
    expenses_approver_id?: number;
    created_at?: string;
    updated_at?: string;
  };
  onSelect?: (i: number, s: number, member: User, workflowType?: T) => void;
  onReset?: (i: number, s: number, workflowType?: T) => void;
  onSelectExpenses?: (
    auth: {
      id?: number;
      department_id?: number;
      expenses_approver_id?: number;
      created_at?: string;
      updated_at?: string;
    },
    member: User
  ) => void;
  onSelectExpensesForMultistage?: (stageIndex: number, approverIndex: number, userId: number) => void;
  isDeletableApprover?: (i: number, s: number, workflowType?: T) => boolean;
}

interface State {
  showMemberList: boolean;
  showResetButton: boolean;
  selectedMembers: User[];
}

interface HTMLElementEvent<T extends EventTarget> extends Event {
  target: T;
}

class SelectApprovers<T extends WorkflowTypeKeys> extends React.Component<Props<T>, State> {
  private selectAreaRef = React.createRef<HTMLInputElement>();

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      showMemberList: false,
      showResetButton: !!props.onReset,
      selectedMembers: []
    };

    this.toggleMemberList = this.toggleMemberList.bind(this);
    this.handleMemberSelect = this.handleMemberSelect.bind(this);
    this.handleMemberReset = this.handleMemberReset.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', {
      handleEvent: (e: HTMLElementEvent<HTMLElement>) => {
        this.handleClickEvent(e);
      }
    });
  }

  handleClickEvent(e: HTMLElementEvent<HTMLElement>) {
    if (this.selectAreaRef && this.selectAreaRef.current && !this.selectAreaRef.current.contains(e.target)) {
      this.setState({ showMemberList: false });
    }
  }

  handleMemberSelect(i: number, s: number, member: User, workflowType?: T) {
    this.setState({
      showMemberList: false
    });
    if (this.props.onSelectExpensesForMultistage) {
      this.props.onSelectExpensesForMultistage(s, i, member.id);
    } else if (this.props.auth && this.props.onSelectExpenses) {
      this.props.onSelectExpenses(this.props.auth, member);
    } else if (this.props.onSelect) {
      this.props.onSelect(i, s, member, workflowType);
    }
  }

  handleMemberReset() {
    const { s, i, workflowType } = this.props;
    this.setState({
      showMemberList: false
    });
    if (this.props.onReset) {
      this.props.onReset(i, s, workflowType);
    }
  }

  toggleMemberList(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      showMemberList: !this.state.showMemberList
    });
  }

  render() {
    const rawMembers: User[] = this.props.rawMembers;
    const selectedMember: User | undefined = rawMembers.find(member => member.id === this.props.selectedId);
    try {
      return (
        <div className="select-approvers">
          <div className="select-approvers__wrapper" ref={this.selectAreaRef}>
            <div className="select-approvers__selected-list__item" onClick={this.toggleMemberList}>
              {selectedMember ? (
                <div className="approver-members-list__item__info">
                  <p className="approver-members-list__item-part">{selectedMember.name}</p>
                  <p className="approver-members-list__item-part">{`(${selectedMember.email})`}</p>
                </div>
              ) : (
                <p className="select-approvers__selected-list__message">選択してください</p>
              )}
              <div className="select-approvers__selected-list__right">
                {this.state.showResetButton && selectedMember && (
                  <a className="select-approvers__selected-list__reset" onClick={() => this.handleMemberReset()}>
                    <img src="/images/close_search.png" />
                  </a>
                )}
                {this.state.showMemberList ? '▲' : '▼'}
              </div>
            </div>
            {this.state.showMemberList && (
              <div className="select-approvers__travelers-list">
                <ApproversMemberList<T>
                  {...this.props}
                  members={this.props.members}
                  selectedMembers={this.state.selectedMembers}
                  onSelect={this.handleMemberSelect}
                  isDeletableApprover={this.props.isDeletableApprover}
                />
              </div>
            )}
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default SelectApprovers;
