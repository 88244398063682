import _ from 'lodash';
import { observable } from 'mobx';
import moment from 'moment';

export interface RuleCabinetJson {
  id?: number;
  title: string;
  content: string;
  is_deleted: boolean;
  updated_at: string;
}

export class RuleCabinet {
  id?: number;

  @observable
  title: string;

  @observable
  content: string;

  updatedAt: string;

  isDeleted?: boolean;

  constructor(args: RuleCabinetJson) {
    this.id = args.id;
    this.title = args.title;
    this.content = args.content;
    this.isDeleted = args.is_deleted;
    this.updatedAt = moment(args.updated_at).format('YYYY-MM-DD');
  }

  setTitle = (value: string) => {
    this.title = value;
    app.render();
  };

  setContent = (value: string) => {
    this.content = value;
    app.render();
  };

  submitParams() {
    return {
      id: this.id,
      title: this.title,
      content: this.content
    };
  }
}

export default RuleCabinet;
