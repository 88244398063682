import { observable } from 'mobx';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import type Arranger from '../arranger/arranger';
import type { OrderItemJobTypeKey } from '../order_item/order_item_job_type';
import type { OrderItemStatusKey } from '../order_item/order_item_status';
import type { TripStatusKey } from '../trip/trip_status';
import type { OrderItemStepStatusEnum } from '../order_item/order_item_step_status';
import type { OrderItemCategoryKey } from '../order_item/order_item_category';
import type { OrderItemStepFieldArgs } from './order_item_step_todo_fields';
import OrderItemStepTodoFields from './order_item_step_todo_fields';
import type { OrderItemStepArgs, OrderItemStepResponseArgs } from '../order_item/order_item_step';
import OrderItemStep, {
  convertOrderItemStepResponseToOrderItemStep as convertOrderItem
} from '../order_item/order_item_step';
import MasterOrderItemAction from '../master_step/master_order_item_action';
import type { MasterOrderItemActionArgs } from '../master_step/master_order_item_action';

export type OrderItemStepTodoType = 'message' | 'order_item_step';

export type OrderItemStepAssignType = 'assigned_to_me' | 'unassigned' | 'all_tasks';

export type OrderItemStepAction = 'assign' | 'start' | 'release' | 'pause' | 'complete' | 'cancel' | 'abort';

export const ORDER_ITEM_STEP_ASSIGNS: Record<OrderItemStepAssignType, string> = {
  assigned_to_me: '自分に割り当てられたタスク',
  unassigned: '未アサイン',
  all_tasks: 'すべて'
};

export const ORDER_ITEM_STEP_ACTIONS: Record<Exclude<OrderItemStepAction, 'abort'>, string> = {
  assign: '自分にアサイン',
  start: 'スタート',
  release: 'リリース',
  pause: '一時停止',
  complete: '完了',
  cancel: 'キャンセル'
};

export type OrderItemStepTodoResponseArgs = {
  id: number;
  todo_type: string;
  trip_id: number;
  order_item_id?: number;
  trace_id?: number;
  trip_status: number;
  job_type: number;
  status: number;
  step_status: string;
  arranger_name?: string;
  category?: OrderItemCategoryKey;
  sequence_name: string;
  sequence_id: number;
  step_num: number;
  master_step_id: number;
  standard_duration_minutes?: number;
  started_at?: string;
  assigned_to_name?: string;
  assigned_to_id?: number;
  organization_name?: string;
  waiting?: boolean;
  start_date?: string;
  end_date?: string;
  received_at?: string;
  duration?: number;
  assigned_duration?: string;
  last_user_message?: string;
  last_user_message_id?: number;
  last_log_message?: string;
  last_log_message_arranger?: string;
  snooze_to?: string;
  snooze_message?: string;
  is_urgent: boolean;
  sp: boolean;
  use_qr?: boolean;
  premium_support?: boolean;
  changed_status?: boolean;
  order_item_step_sequence?: {
    id: number;
    trace_id: number;
    current_step_id?: number;
    master_step_sequence_id: number;
    master_step_name: string;
    master_step_code: string;
    current_step?: OrderItemStepResponseArgs;
  };
};

export type OrderItemStepTodoArgs = {
  id: number;
  todoType: OrderItemStepTodoType;
  tripId: number;
  orderItemId?: number;
  traceId?: number;
  tripStatus: TripStatusKey;
  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  stepStatus: OrderItemStepStatusEnum;
  arrangerName?: string;
  category?: OrderItemCategoryKey;
  sequenceName: string;
  sequenceId: number;
  stepNum: number;
  masterStepId: number;
  standardDurationMinutes?: number;
  startedAt?: Moment;
  step?: OrderItemStepArgs;
  assignedToName?: string;
  assignedToId?: number;
  organizationName?: string;
  waiting?: boolean;
  startDate?: Moment;
  endDate?: Moment;
  receivedAt?: Moment;
  duration?: number;
  assignedDuration?: string;
  lastUserMessage?: string;
  lastUserMessageId?: number;
  lastLogMessage?: string;
  lastLogMessageArranger?: string;
  snoozeTo?: Moment;
  snoozeMessage?: string;
  isUrgent: boolean;
  sp: boolean;
  useQr: boolean;
  premiumSupport: boolean;
  changedStatus?: boolean;
  orderItemStepFields: OrderItemStepFieldArgs[];
  masterOrderItemActions: MasterOrderItemActionArgs[];
};

export const convertOrderItemStepTodoResponseToArgs = (response: OrderItemStepTodoResponseArgs) => {
  const args: OrderItemStepTodoArgs = {
    id: response.id,
    todoType: response.todo_type as OrderItemStepTodoType,
    tripId: response.trip_id,
    orderItemId: response.order_item_id,
    traceId: response.trace_id,
    tripStatus: response.trip_status as TripStatusKey,
    jobType: response.job_type as OrderItemJobTypeKey,
    status: response.status as OrderItemStatusKey,
    stepStatus: response.step_status as OrderItemStepStatusEnum,
    arrangerName: response.arranger_name,
    category: response.category,
    sequenceName: response.sequence_name ?? response.order_item_step_sequence?.master_step_name,
    sequenceId: response.sequence_id ?? response.order_item_step_sequence?.master_step_sequence_id,
    stepNum: response.step_num,
    masterStepId: response.master_step_id,
    standardDurationMinutes: response.standard_duration_minutes,
    startedAt: response.started_at ? moment(response.started_at) : undefined,
    step: response.order_item_step_sequence?.current_step
      ? convertOrderItem(response.order_item_step_sequence.current_step)
      : undefined,
    assignedToName: response.assigned_to_name,
    assignedToId: response.assigned_to_id,
    organizationName: response.organization_name,
    waiting: response.waiting,
    startDate: response.start_date ? moment(response.start_date) : undefined,
    endDate: response.end_date ? moment(response.end_date) : undefined,
    receivedAt: response.received_at ? moment(response.received_at) : undefined,
    duration: response.duration,
    assignedDuration: response.assigned_duration,
    lastUserMessage: response.last_user_message,
    lastUserMessageId: response.last_user_message_id,
    lastLogMessage: response.last_log_message,
    lastLogMessageArranger: response.last_log_message_arranger,
    snoozeTo: response.snooze_to ? moment(response.snooze_to) : undefined,
    snoozeMessage: response.snooze_message,
    isUrgent: response.is_urgent,
    sp: response.sp,
    useQr: response.use_qr || false,
    premiumSupport: response.premium_support || false,
    changedStatus: response.changed_status,
    orderItemStepFields: response.order_item_step_sequence?.current_step?.order_item_step_fields || [],
    masterOrderItemActions: response.order_item_step_sequence?.current_step?.master_order_item_actions || []
  };
  return args;
};

export default class OrderItemStepTodo {
  // OrderItemStep ID
  @observable readonly id: number;

  // TODOの種類 (message or order_item_step)
  @observable readonly todoType: OrderItemStepTodoType;

  // Trip ID
  @observable readonly tripId: number;

  // OrderItem ID
  @observable readonly orderItemId?: number;

  // Trace ID
  @observable readonly traceId?: number;

  // 旅程ステータス
  @observable readonly tripStatus: TripStatusKey;

  // 商品ステータス１
  @observable jobType: OrderItemJobTypeKey;

  // 商品ステータス２
  @observable status: OrderItemStatusKey;

  // ステップステータス
  @observable stepStatus: OrderItemStepStatusEnum;

  // 商品ステータス最終更新者
  @observable arrangerName?: string;

  // 商品カテゴリ
  @observable category?: OrderItemCategoryKey;

  // シーケンス名
  @observable sequenceName: string;

  // シーケンスID
  @observable sequenceId: number;

  // ステップ
  @observable stepNum: number;

  // マスターステップID
  @observable masterStepId: number;

  // 標準時間
  @observable standardDurationMinutes?: number;

  // 開始日時（経過時間）
  @observable startedAt?: Moment;

  // ステップ（Object）
  @observable step: OrderItemStep | null;

  // 担当者
  @observable assignedToName?: string;

  // 担当者ID
  @observable assignedToId?: number;

  // 法人名
  @observable organizationName?: string;

  // 問い合わせ対応
  @observable waiting?: boolean;

  // 出発日
  @observable startDate?: Moment;

  // 到着日
  @observable endDate?: Moment;

  // 依頼日
  @observable receivedAt?: Moment;

  // 所要時間
  @observable duration?: number;

  // アサイン日時からの経過時間
  @observable assignedDuration?: string;

  // チャットメッセージ
  @observable lastUserMessage?: string;

  // チャットメッセージID
  @observable lastUserMessageId?: number;

  // ログメッセージ
  @observable lastLogMessage?: string;

  // ログメッセージ最終更新者
  @observable lastLogMessageArranger?: string;

  // スヌーズ
  @observable snoozeTo?: Moment;

  // スヌーズメッセージ
  @observable snoozeMessage?: string;

  // 緊急
  @observable isUrgent: boolean;

  // SP
  @observable sp: boolean;

  // QR
  @observable useQr: boolean;

  // 土日祝サポート
  @observable premiumSupport: boolean;

  // 商品ステータス変更
  @observable changedStatus?: boolean;

  // 入力フィールド
  @observable orderItemStepTodoInputFields: OrderItemStepTodoFields;

  // 表示フィールド
  @observable orderItemStepTodoViewFields: OrderItemStepTodoFields;

  // 完了後アクション
  @observable masterOrderItemActions: MasterOrderItemAction[];

  constructor(args: OrderItemStepTodoArgs, serviceId?: number) {
    this.id = args.id;
    this.todoType = args.todoType;
    this.tripId = args.tripId;
    this.orderItemId = args.orderItemId;
    this.traceId = args.traceId;
    this.tripStatus = args.tripStatus;
    this.jobType = args.jobType;
    this.status = args.status;
    this.stepStatus = args.stepStatus;
    this.arrangerName = args.arrangerName;
    this.category = args.category;
    this.sequenceName = args.sequenceName;
    this.sequenceId = args.sequenceId;
    this.stepNum = args.stepNum;
    this.masterStepId = args.masterStepId;
    this.standardDurationMinutes = args.standardDurationMinutes;
    this.startedAt = args.startedAt;
    this.step = args.step ? new OrderItemStep(args.step) : null;
    this.assignedToName = args.assignedToName;
    this.assignedToId = args.assignedToId;
    this.organizationName = args.organizationName;
    this.waiting = args.waiting;
    this.startDate = args.startDate;
    this.endDate = args.endDate;
    this.receivedAt = args.receivedAt;
    this.assignedDuration = args.assignedDuration;
    this.duration = args.duration;
    this.lastUserMessage = args.lastUserMessage;
    this.lastUserMessageId = args.lastUserMessageId;
    this.lastLogMessage = args.lastLogMessage;
    this.lastLogMessageArranger = args.lastLogMessageArranger;
    this.snoozeTo = args.snoozeTo;
    this.snoozeMessage = args.snoozeMessage;
    this.isUrgent = args.isUrgent;
    this.sp = args.sp;
    this.useQr = args.useQr;
    this.premiumSupport = args.premiumSupport;
    this.changedStatus = args.changedStatus;
    this.orderItemStepTodoInputFields = new OrderItemStepTodoFields(
      args.orderItemStepFields.filter(f => f.field_type === 'input'),
      serviceId
    );
    this.orderItemStepTodoViewFields = new OrderItemStepTodoFields(
      args.orderItemStepFields.filter(f => f.field_type === 'view'),
      serviceId
    );
    this.masterOrderItemActions = args.masterOrderItemActions.map(raw => new MasterOrderItemAction(raw));
  }

  actions(arranger: Arranger): OrderItemStepAction[] {
    if (this.assignedToId && this.assignedToId === arranger.id) {
      if (['todo', 'suspended'].includes(this.stepStatus)) return ['start', 'release'];
      if (this.stepStatus === 'started') return ['cancel', 'pause'];
    }

    if (!this.assignedToId) {
      if (['todo', 'suspended'].includes(this.stepStatus)) return ['assign'];
    }

    return ['abort'];
  }

  durationString(): string {
    if (!this.duration) return '';

    if (this.duration >= 3600) {
      const hour = Math.floor(this.duration / 3600);
      const minute = Math.floor((this.duration % 3600) / 60);
      return `${hour}時間${minute}分`;
    }

    if (this.duration >= 60) {
      const minute = Math.floor(this.duration / 60);
      const second = this.duration % 60;
      return `${minute}分${second}秒`;
    }

    return `${this.duration}秒`;
  }

  getActionNames(): string | null {
    const names = this.masterOrderItemActions.map(action => action.name);
    return names.length > 0 ? names.join('、') : null;
  }
}
