import { Fetcher } from '@this/src/util';
import React, { useCallback, useReducer, useRef } from 'react';
import type { Dispatch, Reducer, ReducerAction } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import SearchPnrFlight from '@this/src/domain/search_pnr_flight';
import type { SearchPnrFlightArgs } from '@this/src/domain/search_pnr_flight';

import { Loading } from '@this/shared/ui/feedbacks/loading';
import { styled } from '@this/constants/themes';

import { Link } from '@this/shared/ui/navigations/link';
import { Text } from '@this/shared/ui/data_displays/typography';
import SearchPnrFlightsSearchArea, { initialUrlQuery, urlQueryReducer } from './search_pnr_flights_search_area';
import type { UrlQuery, UrlQueryAction } from './search_pnr_flights_search_area';

interface State {
  searchPnrFlights: SearchPnrFlight[];
  urlQuery: UrlQuery;
  loading: boolean;
  errorMessage: string;
  totalCount: number;
}

interface Response {
  search_pnr_flights: SearchPnrFlightArgs[];
  total_count: number;
}

type Action =
  | UrlQueryAction
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_RESPONSE'; payload: { response: Response } };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_FLIGHT_TYPE':
    case 'SET_PNR_ID':
      return { ...state, urlQuery: urlQueryReducer(state.urlQuery, action) };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, errorMessage: action.payload };
    case 'SET_RESPONSE':
      return {
        ...state,
        searchPnrFlights: action.payload.response.search_pnr_flights.map(raw => new SearchPnrFlight(raw)),
        loading: false,
        totalCount: action.payload.response.total_count
      };
    default:
      return state;
  }
};

interface Request {
  urlQuery: UrlQuery;
  history: RouteComponentProps['history'];
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}

const fetchSearchPnrFlights = ({ urlQuery, history, dispatch }: Request) => {
  dispatch({ type: 'SET_LOADING', payload: true });
  dispatch({ type: 'SET_ERROR', payload: '' });

  const { ...others } = urlQuery;
  const params = {
    ...others
  };
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) delete params[key as keyof typeof params];
  });
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  history.push(`/arrangement/search_pnr_flights?${query}`);

  Fetcher.get<Response>('/arrangement/search_pnr_flights.json', params)
    .then(response => {
      dispatch({ type: 'SET_RESPONSE', payload: { response } });
    })
    .catch(e => {
      if (e.response.data && e.response.data.message) {
        dispatch({ type: 'SET_ERROR', payload: e.response.data.message });
      } else {
        dispatch({ type: 'SET_ERROR', payload: 'エラーが発生しました。' });
      }
      dispatch({ type: 'SET_LOADING', payload: false });
    });
};

const initialState: State = {
  searchPnrFlights: [],
  urlQuery: initialUrlQuery,
  loading: false,
  errorMessage: '',
  totalCount: 0
};

const SearchPnrFlights: React.FC = observer(() => {
  const { current: history } = useRef(useHistory());
  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchPnrFlights, urlQuery, loading, totalCount, errorMessage } = state;
  const handleSearch = useCallback(() => {
    fetchSearchPnrFlights({
      urlQuery,
      history,
      dispatch
    });
  }, [urlQuery, history, dispatch]);

  return (
    <Wrapper>
      <SearchPnrFlightsSearchArea urlQuery={state.urlQuery} dispatch={dispatch} handleSearch={handleSearch} />
      {loading ? (
        <Loading />
      ) : (
        <ResultArea>
          <div>旅程数: {totalCount}件</div>
          {errorMessage && <Text color="danger">{errorMessage}</Text>}
          {searchPnrFlights.map(searchPnrFlight => (
            <div key={searchPnrFlight.id}>
              旅程番号:　
              <Link
                href={`/arrangement/virtual_counter?trip_id=${searchPnrFlight.tripId}`}
                target="_blank"
                rel="noopener noreffer"
              >
                {searchPnrFlight.tripId}
              </Link>
            </div>
          ))}
        </ResultArea>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 20px;
`;

const ResultArea = styled.div`
  padding: 20px 0 5px 20px;
`;

export default SearchPnrFlights;
