import type { CSSProperties } from 'react';
import React from 'react';
import CsvDropzone from '@this/components/organization/csv_bulk_action/csv_dropzone';
import type { FileValidatorResult } from '@this/components/organization/csv_bulk_action/types';
import { styled } from '@this/constants/themes';
import { Button } from '@this/src/components/shared/ui/inputs/button';

interface Props {
  loading: boolean;
  backPath: string;
  historyPath: string;
  validateResult?: FileValidatorResult;
  uploadResult?: any;
  dropZoneStyle?: CSSProperties;
  select: (file?: File) => void;
  upload: (file: File) => void;
}
const CsvBulkUpsertForm: React.FC<Props> = ({
  loading,
  backPath,
  historyPath,
  validateResult,
  uploadResult,
  dropZoneStyle,
  select,
  upload
}) => {
  const canSubmit = validateResult && validateResult.file && !uploadResult && !loading;
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!canSubmit) {
      return;
    }

    upload(validateResult!.file!);
  };

  return (
    <form>
      <CsvDropzone
        loading={loading}
        validateResult={validateResult}
        uploadResult={uploadResult}
        dropZoneStyle={dropZoneStyle}
        select={select}
        historyPath={historyPath}
      />
      <Actions>
        <Button color="sub" href={backPath}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleSubmit} disabled={!canSubmit}>
          アップロード
        </Button>
      </Actions>
    </form>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  *:not(:first-child) {
    margin-left: 10px;
  }
`;

export default CsvBulkUpsertForm;
