import React, { useCallback } from 'react';
import { css } from 'styled-components';

import { styled } from '@this/constants/themes';
import { Flex } from '@this/shared/ui/layout/flex';
import { Link } from '@this/shared/ui/navigations/link';
import type { Message as MessageType } from '@this/components/trips_management/trips/trips_chat/types';

type Props = {
  className?: string;
  tripId: number;
  attachments: EdittingAttachment[];
  onClickToggleDelete: (attachment: EdittingAttachment) => void;
};

export type EdittingAttachment = NonNullable<MessageType['attach_files']>[number] & { isDelete?: boolean };

export const EdittingAttachmentsField = ({ className, tripId, attachments, onClickToggleDelete }: Props) => {
  const handleClickToggleDelete = useCallback(
    (e: React.MouseEvent, attachment) => {
      e.preventDefault();
      onClickToggleDelete(attachment);
    },
    [onClickToggleDelete]
  );

  return (
    <Root className={className}>
      <p>添付済ファイル</p>
      <ul>
        {attachments.map(f => (
          <ListItem key={f.file_path}>
            <Flex justifyContent="space-between" alignItems="center" gap="4px">
              <FileLink href={`/trips/${tripId}/images/${f.file_path}`} target="_blank" isDelete={!!f.isDelete}>
                {f.file_name}
              </FileLink>
              <Link
                onClick={(e: React.MouseEvent) => handleClickToggleDelete(e, f)}
                data-testid="editting-attachment-field-delete"
              >
                {f.isDelete ? '削除をキャンセル' : '削除'}
              </Link>
            </Flex>
          </ListItem>
        ))}
      </ul>
    </Root>
  );
};

const Root = styled.div``;

const ListItem = styled.li`
  margin-left: 8px;
`;

const FileLink = styled.a<{ isDelete: boolean }>`
  ${props =>
    props.isDelete
      ? css`
          text-decoration: line-through;
        `
      : null}
`;
