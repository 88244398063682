import React from 'react';
import _ from 'lodash';

import type ArrangementRequestHotelInfo from '@this/domain/arrangement_request_info/arrangement_request_hotel_info';
import { toDomesticTel } from '@this/src/util';

export type Props = {
  hotelInfo: ArrangementRequestHotelInfo;
};

const ArrangementRequestHotelInfoForm: React.SFC<Props> = ({ hotelInfo }) => (
  <div className="reserve-confirm__section">
    <div className="reserve-confirm__subtitle">宿泊</div>
    <div className="reserve-confirm__child-content">
      <div className="reserve-confirm__traveler__input-area">
        <div className="reserve-confirm__label">代表者氏名（ローマ字）</div>
        <div className="reserve-confirm__traveler__input-area-right">
          <input
            className="reserve-confirm__input-name"
            type="text"
            placeholder="例) Yamada"
            value={hotelInfo.hotelLastName}
            onChange={hotelInfo.setHotelLastName}
          />
          <input
            className="reserve-confirm__input-name"
            type="text"
            placeholder="例) Ichiro"
            value={hotelInfo.hotelFirstName}
            onChange={hotelInfo.setHotelFirstName}
          />
        </div>
      </div>
      <div className="reserve-confirm__traveler__input-area">
        <div className="reserve-confirm__label">代表者氏名（カナ）</div>
        <div className="reserve-confirm__traveler__input-area-right">
          <input
            className="reserve-confirm__input-name-kana"
            type="text"
            placeholder="例) ヤマダ"
            value={hotelInfo.hotelLastNameKana}
            onChange={hotelInfo.setHotelLastNameKana}
          />
          <input
            className="reserve-confirm__input-name-kana"
            type="text"
            placeholder="例) イチロウ"
            value={hotelInfo.hotelFirstNameKana}
            onChange={hotelInfo.setHotelFirstNameKana}
          />
        </div>
      </div>
      <div className="reserve-confirm__traveler__input-area">
        <div className="reserve-confirm__label">代表者電話番号</div>
        <div className="reserve-confirm__traveler__input-area-right">
          <input
            className="reserve-confirm__input"
            type="text"
            placeholder="例) 090-1111-2222"
            value={toDomesticTel(hotelInfo.hotelTel)}
            onChange={hotelInfo.setHotelTel}
          />
        </div>
      </div>
      {hotelInfo.bedTypes && (
        <div className="reserve-confirm__traveler__input-area">
          <div className="reserve-confirm__label">ベッドの種類</div>
          <div className="reserve-confirm__traveler__input-area-right">
            {hotelInfo.bedTypes.length > 1 ? (
              <select name="bedTypes" value={hotelInfo.bedTypeId} onChange={hotelInfo.setBedType}>
                {hotelInfo.bedTypes.map(b => (
                  <option value={b.id} key={b.id}>
                    {b.description}
                  </option>
                ))}
              </select>
            ) : (
              <p>{hotelInfo.bedTypes[0] && hotelInfo.bedTypes[0].description}</p>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default ArrangementRequestHotelInfoForm;
