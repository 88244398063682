import React from 'react';
import Alert from '@material-ui/lab/Alert';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import type SearchResult from '@this/src/domain/search_result';
import type SearchResultItem from '@this/domain/search_result_item';
import Hotel from '@this/domain/hotel/hotel';
import { styled } from '@this/constants/themes';

interface Props {
  result: SearchResult;
}

const AlertArea: React.FC<Props> = ({ result }) => {
  const items = result.currentItems;
  const user = result.travelerRepresentative;

  // 現状ホテルのloadingだけ検知
  const refetchHotelLoading = items.some(item => {
    const currentElement = item.elementList && item.elementList.current();
    return currentElement instanceof Hotel && currentElement.loading;
  });

  const isOverHotelPriceLimit = (hotelItems: SearchResultItem[]): boolean => {
    return hotelItems.some(item => {
      const currentElement = item.elementList && item.elementList.current();
      if (currentElement instanceof Hotel) {
        if (item.isDomestic && !user?.show_hotel_of_over_limit) return false;
        if (!item.isDomestic && !user?.show_foreign_hotel_of_over_limit) return false;
        if (!item.isNeed) return false;

        const limit = item.hotelPriceLimit || 0;
        const isOverLimit = limit > 0 && currentElement.getAveragePriceWithTax()! > limit;
        return isOverLimit && !currentElement.sold_out;
      }
      return false;
    });
  };

  const alertMessages: string[] = [];
  const hotelItems = items.filter(item => item.elementType() === 'hotel');
  if (isOverHotelPriceLimit(hotelItems)) {
    alertMessages.push('宿泊施設が規程値をオーバーしています。');
  }

  return (
    <>
      {!refetchHotelLoading && alertMessages.length > 0 && (
        <StyledAlert icon={<StyledAlertIcon />} variant="outlined" severity="error">
          {alertMessages.join('\n')}
        </StyledAlert>
      )}
    </>
  );
};

const StyledAlert = styled(Alert)`
  color: #a72c09;
  border: 1px solid #a72c09;
`;

const StyledAlertIcon = styled(AnnouncementIcon)`
  color: #a72c09;
`;

export default AlertArea;
