import { observable } from 'mobx';
import type { SuppliedItemArgs } from '../supplied_item/supplied_item';
import SuppliedItem from '../supplied_item/supplied_item';

export type SupplierArgs = {
  id: number;
  service_id: number;
  name: string;
  code: string;
  disabled: boolean;
  supplied_items?: SuppliedItemArgs[];
};

export default class Supplier {
  @observable
  id: number;

  @observable
  serviceId: number;

  @observable
  name: string;

  @observable
  code: string;

  @observable
  disabled: boolean;

  @observable
  suppliedItems: SuppliedItem[] = [];

  constructor(args: SupplierArgs) {
    this.id = args.id;
    this.serviceId = args.service_id;
    this.name = args.name;
    this.code = args.code;
    this.disabled = args.disabled;

    if (args.supplied_items) {
      this.suppliedItems = args.supplied_items.map(
        suppliedItem =>
          new SuppliedItem({
            ...suppliedItem
          })
      );
    }
  }

  clone(): Supplier {
    const args: SupplierArgs = {
      id: this.id,
      service_id: this.serviceId,
      name: this.name,
      code: this.code,
      disabled: this.disabled
    };
    return new Supplier(args);
  }

  setName = (value: string) => {
    this.name = value;
    app.render();
  };

  setCode = (value: string) => {
    this.code = value;
    app.render();
  };

  setDisabled = (value: boolean) => {
    this.disabled = value;
    app.render();
  };

  submitParams() {
    return {
      service_id: this.serviceId,
      name: this.name,
      code: this.code,
      disabled: this.disabled
    };
  }
}
