export interface RegionArgs {
  id: number;
  name: string;
  grades: Array<{ id?: number; hotel_price_limit: number | '' }>;
  prefectures: Array<{
    id: number;
    name: string;
    grades: Array<{ id?: number; hotel_price_limit: number | '' }>;
    hidden: boolean;
  }>;

  hidden: boolean;
}
export interface ResionsMunicipalitiesArgs {
  id: number;
  name: string;
  prefectures: Array<{
    id: number;
    name: string;
    municipalities: Array<{
      prefecture_id: number;
      code: string;
      name: string;
      grades: { id: number | null; hotel_price_limit: number | '' }[];
      hidden: boolean;
    }>;
  }>;
}
class Region {
  public id: number;

  public name: string;

  public grades: { id: number | null; hotelPriceLimit: number | '' }[];

  public prefectures: {
    id: number;
    name: string;
    grades: { id: number | null; hotelPriceLimit: number | '' }[];
    hidden: boolean;
  }[];

  public municipalities: Map<
    number,
    Array<{
      prefectureId: number;
      municipalityCode: string;
      name: string;
      grades: { id: number | null; hotelPriceLimit: number | '' }[];
      hidden: boolean;
    }>
  >;

  public hidden: boolean;

  constructor(args: RegionArgs) {
    this.id = args.id;
    this.name = args.name;
    this.grades = args.grades.map(g => ({
      id: g.id || null,
      hotelPriceLimit: g.hotel_price_limit
    }));
    this.prefectures = args.prefectures.map(p => ({
      id: p.id,
      name: p.name,
      grades: p.grades.map(g => ({
        id: g.id || null,
        hotelPriceLimit: g.hotel_price_limit
      })),
      hidden: true
    }));
    this.municipalities = new Map<
      number,
      Array<{
        prefectureId: number;
        municipalityCode: string;
        name: string;
        grades: { id: number | null; hotelPriceLimit: number | '' }[];
        hidden: boolean;
      }>
    >();
    this.hidden = true;
  }

  setMunicipalities(args: ResionsMunicipalitiesArgs) {
    args.prefectures.forEach(p => {
      if (this.municipalities.get(p.id) === undefined) {
        this.municipalities.set(
          p.id,
          p.municipalities.map(m => ({
            prefectureId: m.prefecture_id,
            municipalityCode: m.code,
            name: m.name,
            grades: m.grades.map(g => ({
              id: g.id || null,
              hotelPriceLimit: g.hotel_price_limit
            })),
            hidden: true
          }))
        );
      }
    });
  }

  getMunicipalities(prefectureId: number) {
    const municipalities = this.municipalities.get(prefectureId);
    if (municipalities === undefined || municipalities === null) {
      return [];
    }
    return municipalities;
  }

  submitParams() {
    const municipalities: any[] = [];
    this.municipalities.forEach((munis, _) => {
      munis.forEach(muni => {
        municipalities.push({
          prefecture_id: muni.prefectureId,
          municipality_code: muni.municipalityCode,
          grades: muni.grades.map(g => ({
            id: g.id,
            price: g.hotelPriceLimit
          }))
        });
      });
    });
    return {
      id: this.id,
      grades: this.grades.map(g => ({
        id: g.id,
        price: g.hotelPriceLimit
      })),
      prefectures: this.prefectures.map(p => ({
        id: p.id,
        grades: p.grades.map(g => ({
          id: g.id,
          price: g.hotelPriceLimit
        }))
      })),
      municipalities
    };
  }

  setHotelPriceLimit(value: number, g_id: number | null, p_id: number) {
    if (p_id === 0) {
      this.grades.filter(g => g.id === g_id)[0].hotelPriceLimit = value;
    } else {
      this.prefectures.filter(p => p.id === p_id)[0].grades.filter(g => g.id === g_id)[0].hotelPriceLimit = value;
    }
    app.render();
  }

  setHotelPriceLimitMunicipality(
    value: number,
    g_id: number | null,
    prefectureId: number,
    municipalityCode: string
  ) {
    const municipalities = this.getMunicipalities(prefectureId);
    if (municipalities !== undefined) {
      municipalities.forEach(m => {
        if (m.municipalityCode === municipalityCode) {
          m.grades.filter(g => g.id === g_id)[0].hotelPriceLimit = value;
        }
      });
      this.municipalities.set(prefectureId, municipalities);
    }
    app.render();
  }

  toggleRegionDisabled() {
    this.hidden = !this.hidden;
    app.render();
  }

  togglePrefectureDisabled() {
    this.prefectures.forEach(p => {
      p.hidden = !p.hidden;
    });
    app.render();
  }

  toggleMunicipalitiesDisabled(prefectureId: number) {
    const municipalities = this.getMunicipalities(prefectureId);
    if (municipalities !== undefined) {
      municipalities.forEach(m => {
        m.hidden = !m.hidden;
      });
      this.municipalities.set(prefectureId, municipalities);
    }
    app.render();
  }

  isMunicipalitiesHidden(prefectureId: number) {
    const municipalities = this.getMunicipalities(prefectureId);
    let flg = true;
    if (municipalities !== undefined) {
      municipalities.forEach(m => {
        if (m.hidden === false) {
          flg = false;
        }
      });
    }
    return flg;
  }
}

export default Region;
