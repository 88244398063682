import { observable } from 'mobx';

export type ArrangementRequestLimitTypeJson = {
  id?: number;
  business_days: number;
  hour: number;
};

class ArrangementRequestLimitType {
  readonly id?: number;

  @observable
  businessDays: number;

  @observable
  hour: number;

  constructor(args: ArrangementRequestLimitTypeJson) {
    this.id = args.id;
    this.businessDays = args.business_days || 1;
    this.hour = args.hour || 15;
  }

  description() {
    return `${this.businessDays}営${this.hour}時`;
  }

  submitParams() {
    return {
      id: this.id,
      business_days: this.businessDays,
      hour: this.hour
    };
  }
}

export default ArrangementRequestLimitType;
