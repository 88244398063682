export interface OrganizationResourceArgs {
  id: number;
  resource_id: number;
  resource_name: string;
  resource_path: string;
  read_permission: boolean;
  write_permission: boolean;
}

export class OrganizationResource {
  id: number;

  resourceId: number;

  resourceName: string;

  resourcePath: string;

  readPermission: boolean;

  writePermission: boolean;

  constructor(args: OrganizationResourceArgs) {
    this.id = args.id;
    this.resourceId = args.resource_id;
    this.resourceName = args.resource_name;
    this.resourcePath = args.resource_path;
    this.readPermission = Boolean(args.read_permission);
    this.writePermission = Boolean(args.write_permission);
  }
}

export const ReadPermissionType = 'read';
export const WritePermissionType = 'write';
