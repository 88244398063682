class Margin {
  amount = 0;

  constructor(args: { amount?: number }) {
    Object.assign(this, utils.snakeToCamelObject(args));
  }

  setAmount(value: number) {
    this.amount = value;
    app.render();
  }

  updateParams() {
    return { amount: this.amount };
  }
}

export default Margin;
