import React from 'react';
import type { ModalProps } from '@this/shared/ui/feedbacks/modal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { FormControl, FormHelperText, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { ReadPermissionType } from '@this/domain/organization_role/organization_resource';
import { Checkbox, CheckboxGroup } from '@this/shared/ui/inputs/checkbox';
import { Box, Grid } from '@material-ui/core';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import { cloneDeep } from 'lodash';

type Props = {
  role: OrganizationRole;
  submitting?: boolean;
  onSubmit: (role: OrganizationRole) => void;
  onAbort: () => void;
} & Pick<ModalProps, 'open'>;
export const OrganizationRoleForm = ({ role, submitting, open, onAbort, onSubmit }: Props) => (
  <Modal open={open} onClose={onAbort} className="e2e-modal-edit">
    <ModalHeader>役割の{role.id === 0 ? '作成' : '編集'}</ModalHeader>
    <OrganizationRoleFormContent role={role} submitting={submitting} onSubmit={onSubmit} onAbort={onAbort} />
  </Modal>
);

const userResourceId = 5;
const departmentResourceId = 6;
const projectResourceId = 7;

type PermissionType = 'read' | 'write';

const changeUserResourcePermission = (
  resources: OrganizationRole['resources'],
  type: PermissionType
): OrganizationRole['resources'] => {
  const userResource = resources.find(r => r.resourceId === userResourceId);
  const departmentResource = resources.find(r => r.resourceId === departmentResourceId);
  const projectResource = resources.find(r => r.resourceId === projectResourceId);

  if (userResource && departmentResource && projectResource) {
    const relatedResources = [departmentResource, projectResource];
    // eslint-disable-next-line default-case
    switch (type) {
      case 'read':
        userResource.readPermission = !userResource.readPermission;
        relatedResources.forEach(r => {
          r.readPermission = userResource.readPermission;
        });
        break;
      case 'write':
        userResource.writePermission = !userResource.writePermission;
        relatedResources.forEach(r => {
          r.writePermission = userResource.writePermission;
        });
        break;
    }
  }

  return resources;
};

const OrganizationRoleFormContent = ({ role, submitting, onSubmit, onAbort }: Omit<Props, 'open'>) => {
  const [roleName, setRoleName] = React.useState(role.name);
  const [roleNameError, setRoleNameError] = React.useState<string | null>(null);
  const [roleResources, setRoleResources] = React.useState(role.resources);

  const handleSubmit = () => {
    setRoleNameError(null);

    if (!roleName) {
      setRoleNameError('役割名を入力してください');
      return;
    }
    if (['一般ユーザー', '管理者'].includes(roleName)) {
      setRoleNameError(`役割名に${roleName}は登録できません`);
      return;
    }

    const newRole = new OrganizationRole({ id: role?.id ?? 0, name: roleName, organization_role_resources: [] });
    newRole.resources = roleResources;
    onSubmit(newRole);
  };

  const handleChangePermission = (resourceId: number, type: PermissionType) => {
    const newResources = cloneDeep(roleResources);

    const target = newResources.find(r => r.resourceId === resourceId);

    if (target) {
      if (target.resourceId === userResourceId) {
        changeUserResourcePermission(newResources, type);
      } else {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'read':
            target.readPermission = !target.readPermission;
            break;
          case 'write':
            target.writePermission = !target.writePermission;
            break;
        }
      }
    }

    setRoleResources(newResources);
  };

  // ユーザマスタの権限が有効になってる場合、関連のリソースを変更不可にする
  const isDisabledUserRelated = (resourceId: number) => {
    const userPermitted = roleResources.find(r => r.resourceId === userResourceId)?.readPermission ?? false;
    return userPermitted ? [departmentResourceId, projectResourceId].includes(resourceId) : false;
  };

  return (
    <>
      <ModalBody>
        <FormControl fullWidth required error={!!roleNameError}>
          <InputLabel htmlFor="name">役割名</InputLabel>
          <Input id="name" value={roleName} onChange={e => setRoleName(e.target.value)} />
          <FormHelperText>{roleNameError}</FormHelperText>
        </FormControl>

        <Grid container justify="space-between" spacing={1}>
          <Grid item xs={6}>
            {roleResources.slice(0, roleResources.length / 2).map(r => (
              <PermissionFormControl
                key={r.resourceId}
                resource={r}
                disabled={isDisabledUserRelated(r.resourceId)}
                onChange={handleChangePermission}
              />
            ))}
          </Grid>
          <Grid item xs={6}>
            {roleResources.slice(roleResources.length / 2).map(r => (
              <PermissionFormControl
                key={r.resourceId}
                resource={r}
                disabled={isDisabledUserRelated(r.resourceId)}
                onChange={handleChangePermission}
              />
            ))}
          </Grid>
        </Grid>

        <Box mt={1}>
          <FormHelperText>
            ※ 社員マスタの権限を有効にした場合、部署マスタとプロジェクトマスタの権限も有効になります。
          </FormHelperText>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onAbort}>
          キャンセル
        </Button>
        <Button onClick={handleSubmit} disabled={submitting}>
          保存
        </Button>
      </ModalFooter>
    </>
  );
};

type PermissionFormControlProps = {
  resource: OrganizationRole['resources'][number];
  disabled: boolean;
  onChange: (resourceId: number, type: PermissionType) => void;
};

const PermissionFormControl = ({ resource, disabled, onChange }: PermissionFormControlProps) => {
  return (
    <FormControl fullWidth disabled={disabled} margin="dense" data-e2e-resource-name={resource.resourceName}>
      <InputLabel>{resource.resourceName}</InputLabel>
      <CheckboxGroup layout="horizontal">
        <Checkbox
          checked={resource.readPermission}
          onChange={() => onChange(resource.resourceId, ReadPermissionType)}
        >
          利用
        </Checkbox>
        {/**
          <Checkbox
            checked={resource.writePermission}
            onChange={() => onChange(resource.resourceId, WritePermissionType)}
          >
            編集
          </Checkbox> */}
      </CheckboxGroup>
    </FormControl>
  );
};
