import React from 'react';
import _ from 'lodash';

import type { RouteComponentProps } from 'react-router-dom';
import TextArea from 'react-textarea-autosize';
import { Fetcher, HTTPError } from '@this/src/util';
import SimpleLoading from '../../../shared/simple_loading/simple_loading';
import type { SendListArgs } from '../../../../domain/send_list/send_list';
import { SendList } from '../../../../domain/send_list/send_list';
import type { ShinkansenSeatArgs } from '../../../../domain/send_list/shinkansen_seat';
import { ShinkansenSeat } from '../../../../domain/send_list/shinkansen_seat';
import type { SendListItem } from '../../../../domain/send_list/send_list_item';

interface MessageContent {
  body: string;
  type: string;
}

interface Message {
  trip_id: number;
  messages: MessageContent[];
}

interface EditResponse {
  list: SendListArgs;
  messages: Message[];
  honorific_options: { [key: string]: string };
  shinkansen_seats: ShinkansenSeatArgs[];
}

type Props = RouteComponentProps<{ id?: string }>;

interface State {
  loading: boolean;
  submitting: boolean;
  sendList?: SendList;
  messages?: Message[];
  honorificOptions?: { [key: string]: string };
  shinkansenSeats: ShinkansenSeat[];
}

class Edit extends React.Component<Props, State> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      shinkansenSeats: []
    };
  }

  componentDidMount() {
    this.fetchSendList();
  }

  async fetchSendList() {
    this.setState({ loading: true });
    const response = await Fetcher.get<EditResponse>(this.url());
    this.setState({
      loading: false,
      sendList: new SendList(response.list),
      messages: response.messages,
      honorificOptions: response.honorific_options,
      shinkansenSeats: response.shinkansen_seats.map(s => new ShinkansenSeat(s))
    });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.updateSendList();
  }

  async updateSendList() {
    this.setState({ submitting: true });
    try {
      await Fetcher.put(this.updateUrl(), this.params());
      this.setState({ submitting: false });
      location.href = '/arrangement/send_list2';
    } catch (error) {
      this.setState({ submitting: false });
      if (error instanceof HTTPError && error.response?.status === 400) {
        alert(error.response.data.message);
      } else {
        alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      }
    }
  }

  static handleChangeName(item: any, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.name = e.target.value;
    app.render();
  }

  static handleChangeHonorific(item: SendListItem, e: React.ChangeEvent<HTMLSelectElement>) {
    item.honorific = e.target.value;
    app.render();
  }

  static handleChangePic(item: SendListItem, e: React.ChangeEvent<HTMLInputElement>) {
    item.pic = e.target.value;
    app.render();
  }

  static handleChangePostcode(item: SendListItem, e: React.ChangeEvent<HTMLInputElement>) {
    item.postcode = e.target.value;
    app.render();
  }

  static handleChangeAddress(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.address = e.target.value;
    app.render();
  }

  static handleChangeSubAddress(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.subAddress = e.target.value;
    app.render();
  }

  static handleChangeAltShinkansenSeat(item: SendListItem, e: React.ChangeEvent<HTMLTextAreaElement>) {
    item.altShinkansenSeat = e.target.value;
    app.render();
  }

  private url(): string {
    return `/arrangement/send_list2/${this.props.match.params.id}/edit`;
  }

  private updateUrl(): string {
    return `/arrangement/send_list2/${this.props.match.params.id}`;
  }

  private params() {
    return this.state.sendList!.submitParams();
  }

  private messageForItem(item: SendListItem): MessageContent {
    const message = this.state.messages!.find(m => m.trip_id === item.tripId);
    return utils.dig(message, 'messages', 0);
  }

  private getshinkansenSeats(item: SendListItem): string {
    const shinkansen = this.state.shinkansenSeats!.find(m => m.tripId === item.tripId);
    if (shinkansen !== undefined) {
      return shinkansen.shinkansenSeat;
    }
    return '';
  }

  render() {
    const { loading, submitting, sendList, honorificOptions } = this.state;

    return (
      <div className="send-list-new">
        {loading ? (
          <div>
            <SimpleLoading />
          </div>
        ) : (
          <div>
            <h2>送付リスト編集</h2>
            <form onSubmit={e => this.handleSubmit(e)}>
              <table className="send-list-new__table">
                <tbody>
                  <tr>
                    <th className="send-list-new-id">旅程番号</th>
                    <th className="send-list-new-name">クライアント名</th>
                    <th className="send-list-new-honorific">ラベル</th>
                    <th className="send-list-new-pic">担当者名</th>
                    <th className="send-list-new-postcode">郵便番号</th>
                    <th className="send-list-new-address">住所</th>
                    <th className="send-list-new-sub-address">住所(フロア名)</th>
                    <th className="send-list-new-shinkansen-seat">座席希望</th>
                    <th className="send-list-message">メッセージ</th>
                  </tr>
                  {sendList &&
                    sendList!.items.map((item: SendListItem, i: number) => (
                      <tr key={i}>
                        <td className="send-list-new-id">
                          <p>{item.tripId}</p>
                        </td>
                        <td className="send-list-new-name">
                          <TextArea
                            value={item.name}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                              Edit.handleChangeName(item, e)
                            }
                            cols={40}
                          />
                        </td>
                        <td className="send-list-new-honorific">
                          <select value={item.honorific} onChange={e => Edit.handleChangeHonorific(item, e)}>
                            {honorificOptions &&
                              Object.keys(honorificOptions!).map((v: string) => (
                                <option key={v} value={v}>
                                  {honorificOptions![v]}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td className="send-list-new-pic">
                          <div className="flex">
                            <input type="text" value={item.pic} onChange={e => Edit.handleChangePic(item, e)} />
                            <span>様</span>
                          </div>
                        </td>
                        <td className="send-list-new-postcode">
                          <input
                            type="text"
                            value={item.postcode}
                            onChange={e => Edit.handleChangePostcode(item, e)}
                          />
                        </td>
                        <td className="send-list-new-address">
                          <TextArea
                            value={item.address}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                              Edit.handleChangeAddress(item, e)
                            }
                            cols={40}
                          />
                        </td>
                        <td className="send-list-new-sub-address">
                          <TextArea
                            value={item.subAddress}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                              Edit.handleChangeSubAddress(item, e)
                            }
                            cols={40}
                          />
                        </td>
                        <td className="send-list-new-alt_shinkansen_seat">
                          <TextArea
                            value={item.altShinkansenSeat}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                              Edit.handleChangeAltShinkansenSeat(item, e)
                            }
                            cols={40}
                          />
                          <div className="send-list-new__shinkansen-seat">{this.getshinkansenSeats(item)}</div>
                        </td>{' '}
                        <td className="send-list-message">
                          {this.messageForItem(item) && (
                            <div className="send-list-new__message">
                              <div className="send-list-new__message-container">
                                {this.messageForItem(item)!
                                  .body.split('\n')
                                  .map((line: string, i: number) => (
                                    <p key={i}>{line}</p>
                                  ))}
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <input className="send-list-new__submit" type="submit" value="変更" disabled={submitting} />
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default Edit;
