import type User from '@this/domain/user/user';
import React, { useEffect, useState } from 'react';
import HamburgerButton from '@this/components/expenses/header/hamburger_button';
import { styled } from '@this/constants/themes';
import { useLocation } from 'react-router';
import { headerHeight } from '@this/components/expenses/header/header';
import NavContentSm from './nav_content_sm';

interface Props {
  user: User | null | undefined;
}

const HeaderContentSm: React.FC<Props> = ({ user }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();

  const close = () => {
    document.body.removeAttribute('style');
    setActive(false);
  };

  const open = () => {
    document.body.style.overflow = 'hidden';
    setActive(true);
  };

  useEffect(close, [location]);

  return (
    <>
      <Container>
        <HamburgerButton active={active} onClick={() => (active ? close() : open())} />
      </Container>
      {user && (
        <>
          <Overlay active={active} onClick={() => close()} />
          <StyledNav user={user} active={active} />
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 0;
  height: ${headerHeight};
  z-index: 9999;
`;

const Overlay = styled.div<{ active: boolean }>`
  top: ${({ active }) => (active ? headerHeight : '-100%')};
  opacity: ${({ active }) => (active ? 0.3 : 0)};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: #5c636e;
  z-index: 999;
`;

const StyledNav = styled(NavContentSm)<{ active: boolean }>`
  top: ${({ active }) => (active ? headerHeight : '-100%')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s, top 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

export default HeaderContentSm;
