import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { BudgetItem } from './budget_item';
import type { BudgetItemArgs } from './budget_item';

export type EditableFields = 'toTime' | 'totalBudget' | 'editing' | 'removed';

export class Budget {
  id: number | null;

  fromTime: Moment;

  toTime: Moment;

  totalBudget: number;

  budgetItems: BudgetItem[] = [];

  removeBudgetItems: BudgetItem[] = [];

  editing = false;

  removed = false;

  constructor(args: BudgetArgs) {
    this.id = args.id || null;
    this.fromTime = args.from_time ? moment(args.from_time) : moment();
    this.toTime = args.to_time ? moment(args.to_time) : moment();
    this.totalBudget = args.total_budget;
    this.editing = args.editing ?? false;

    if (args.budget_items) {
      this.budgetItems = args.budget_items.map(raw => new BudgetItem(raw));
    }
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  setFromTime(fromTime: Moment) {
    this.fromTime = fromTime;
    if (this.fromTime > this.toTime) {
      this.toTime = this.fromTime.clone();
    }
    app.render();
  }

  getPeriod() {
    return `${this.fromTime?.format('Y年M月')}～${this.toTime?.format('Y年M月')}`;
  }

  getTotalBudget() {
    return `${this.totalBudget.toLocaleString()} 円`;
  }

  getUsedBudgets() {
    return `${this.usedBudgets().toLocaleString()} 円`;
  }

  usedBudgets() {
    return this.budgetItems.reduce((prev, current) => prev + current.price, 0);
  }

  validTotalBudget() {
    return this.totalBudget < 0 ? '全体予算は正の値を設定してください' : null;
  }

  addBudgetItem() {
    const budgetItem = new BudgetItem({
      name: '',
      price: 0,
      created_at: moment().toString(),
      editing: true
    });

    this.budgetItems.splice(0, 0, budgetItem);
    app.render();
  }

  removeBudgetItem(index: number) {
    const budgetItem = this.budgetItems[index];
    if (!budgetItem) return;

    if (budgetItem.id) {
      budgetItem.setField('removed', true);
      this.removeBudgetItems.push(budgetItem);
    }

    this.budgetItems.splice(index, 1);
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      from_time: this.fromTime.format('YYYY-MM-DD'),
      to_time: this.toTime.format('YYYY-MM-DD'),
      total_budget: this.totalBudget,
      _destroy: this.removed,
      budget_items_attributes: [...this.budgetItems, ...this.removeBudgetItems].map(budgetItem =>
        budgetItem.submitParams()
      )
    };
  }
}

export interface BudgetArgs {
  id?: number;

  from_time: string;

  to_time: string;

  total_budget: number;

  budget_items?: BudgetItemArgs[];

  editing?: boolean;
}
