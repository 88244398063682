/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import AddIcon from '@material-ui/icons/Add';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import FlashMessage from '@this/shared/flash_message/flash_message';
import {
  OrganizationBiztraTh,
  OrganizationBiztraTdEditButton,
  OrganizationBiztraTdDeleteButton,
  OrganizationBiztraAddButton,
  OrganizationBiztraTable,
  OrganizationBiztraTd
} from '@this/components/expenses/organization/organization.style';

import Project from '@this/src/domain/project/project';
import ProjectList from '@this/src/domain/project/project_list';
import User from '@this/domain/user/user';
import MainTitle from '../../shared/organization/main_title/main_title';
import { ModalInputArea, ModalInput, ModalInputLabel } from './members';
import { ActionWrapper, BaseButtonCss, CancelButtonCss } from '../approvals/reject';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '../ui/feedbacks/modal';

interface Props {}

interface State {
  projects: any;
  members: any[];
  useProjects: Record<number, boolean>;
  editingProject: any;
  deletingProject: any;
  title: string;
  loading: boolean;
  saving: boolean;
  error: string | null;
  saveError: string | null;
  showEditModal: boolean;
}

interface ProjectsResponse {
  projects: any[];
  members: any[];
  use_projects: Record<number, boolean>;
}

interface CreateResponse {
  id: number;
}

class Projects extends React.Component<Props, State> {
  private projectsPath: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      projects: new ProjectList([], []),
      members: [],
      useProjects: {},
      editingProject: null,
      deletingProject: null,
      title: '',
      loading: false,
      saving: false,
      error: null,
      saveError: null,
      showEditModal: false
    };

    this.projectsPath = '/biztra/organization/projects';
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchProjects();
  }

  fetchProjects() {
    utils
      .jsonPromise<ProjectsResponse>(`${this.projectsPath}.json`)
      .then(
        result => {
          const members = _.map(result.members, u => new User(u));
          this.setState({
            projects: new ProjectList(result.projects, members),
            members,
            useProjects: result.use_projects,
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  handleAddProjectClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const project = new Project({ id: 0, name: '', code: '', disabled: false }, this.state.members);
    this.state.projects.push(project);
    this.setEditingProject(project, '追加');
  }

  handleEditClick(project: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setEditingProject(_.cloneDeep(project), '編集');
  }

  handleDeleteClick(project: any, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setDeletingProject(_.cloneDeep(project));
  }

  setEditingProject(project: any, title: string) {
    this.setState({
      editingProject: project,
      title,
      showEditModal: true
    });
  }

  setDeletingProject(project: any) {
    this.setState({
      deletingProject: project
    });
  }

  handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveError: null
    });

    if (this.state.editingProject.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  }

  submitCreate() {
    utils
      .jsonPromise<CreateResponse>(this.projectsPath, this.state.editingProject.updateParams(), 'POST')
      .then(
        result => {
          _.assign(this.state.editingProject, { id: result.id });
          this.setState({
            editingProject: null,
            saving: false,
            showEditModal: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: true });
        this.fetchProjects();
      });
  }

  submitUpdate() {
    utils
      .jsonPromise(
        `${this.projectsPath}/${this.state.editingProject.id}`,
        this.state.editingProject.updateParams(),
        'PUT'
      )
      .then(
        _result => {
          this.state.projects.update(this.state.editingProject);
          this.setState({
            editingProject: null,
            saving: false,
            showEditModal: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: true });
        this.fetchProjects();
      });
  }

  handleCancelClick() {
    // remove only new project
    if (!this.state.editingProject.id) {
      this.state.projects.remove(this.state.editingProject);
    }
    this.setState({
      editingProject: null
    });
  }

  handleFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingProject[name](e.target.value);
  }

  handleDeleteAlertClose() {
    this.setState({ deletingProject: null });
  }

  handleChangeProjectDisabledRadio(e: React.ChangeEvent<HTMLInputElement>) {
    this.state.editingProject.setDisabled(e.target.value === 'disabled');
  }

  handleDeleteConfirm() {
    utils
      .jsonPromise(`/organization/projects/${this.state.deletingProject.id}`, {}, 'DELETE')
      .then(
        _result => {
          this.state.projects.remove(this.state.deletingProject);
          this.setState({
            deletingProject: null,
            saving: false
          });
        },
        error => {
          this.setState({
            saving: false,
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      })
      .finally(() => {
        this.setState({ loading: true });
        this.fetchProjects();
      });
  }

  grayIfNeeded = (disabled: boolean) => {
    return {
      background: disabled ? '#f2f2f3' : '#ffffff'
    };
  };

  render() {
    try {
      const {
        projects,
        useProjects,
        editingProject,
        deletingProject,
        title,
        loading,
        saving,
        error,
        saveError,
        showEditModal
      } = this.state;

      return (
        <>
          <FlashMessage message={saveError} type="error" />
          <MainTitle
            value="プロジェクトマスタ"
            buttons={
              <TheAddButton onClick={e => this.handleAddProjectClick(e)}>
                <AddIcon fontSize="small" style={{ marginRight: 8, verticalAlign: 'text-top' }} />
                プロジェクトを追加
              </TheAddButton>
            }
          />
          <ContentBody>
            {/* TODO: ビズトラ画面対応 */}
            {/* <BulkActionArea>
                <BulkActionLink to="/organization/projects/csv/bulk_upsert">
                  プロジェクト一括追加・更新
                </BulkActionLink>
                <BulkActionLink to="/organization/projects/csv/bulk_update_approvers">
                  承認者一括追加・更新
                </BulkActionLink>
              </BulkActionArea> */}
            <TheTable>
              <tbody>
                <tr>
                  <OrganizationBiztraTh>プロジェクトコード</OrganizationBiztraTh>
                  <OrganizationBiztraTh>プロジェクト名</OrganizationBiztraTh>
                  <OrganizationBiztraTh width="90px">編集</OrganizationBiztraTh>
                  <OrganizationBiztraTh width="90px">削除</OrganizationBiztraTh>
                </tr>
                {loading ? (
                  <tr>
                    <td colSpan={11}>
                      <SimpleLoading />
                    </td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan={11}>
                      <Error>{error}</Error>
                    </td>
                  </tr>
                ) : (
                  <>
                    {utils.dig(projects, 'list').map((d: any, i: number) => (
                      <tr style={this.grayIfNeeded(d.disabled)} key={i}>
                        <OrganizationBiztraTd>{d.code}</OrganizationBiztraTd>
                        <OrganizationBiztraTd>{d.name}</OrganizationBiztraTd>
                        <OrganizationBiztraTd>
                          {!editingProject && (
                            <OrganizationBiztraTdEditButton onClick={e => this.handleEditClick(d, e)}>
                              編集
                            </OrganizationBiztraTdEditButton>
                          )}
                        </OrganizationBiztraTd>
                        <OrganizationBiztraTd>
                          {!deletingProject && (
                            <OrganizationBiztraTdDeleteButton
                              disabled={useProjects[d.id] || false}
                              onClick={e => this.handleDeleteClick(d, e)}
                            >
                              削除
                            </OrganizationBiztraTdDeleteButton>
                          )}
                        </OrganizationBiztraTd>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </TheTable>
            {!editingProject ? null : (
              <BiztraModal size="medium" onClose={() => this.handleCancelClick()} open={showEditModal}>
                <BiztraModalHeader>プロジェクト情報を{title}する</BiztraModalHeader>
                <BiztraModalBody>
                  <form onSubmit={e => this.handleFormSubmit(e)}>
                    <EditWrap>
                      <EditSection>
                        <ModalInputArea>
                          <ProjectsModalInputLabel htmlFor="code">プロジェクトコード</ProjectsModalInputLabel>
                          <ModalInput
                            id="code"
                            type="text"
                            value={editingProject.code}
                            onChange={e => this.handleFieldChange('setCode', e)}
                            autoFocus={showEditModal}
                            disabled={useProjects[editingProject.id] || false}
                          />
                        </ModalInputArea>
                        <ModalInputArea>
                          <ProjectsModalInputLabel htmlFor="name">プロジェクト名</ProjectsModalInputLabel>
                          <ModalInput
                            id="name"
                            type="text"
                            value={editingProject.name}
                            onChange={e => this.handleFieldChange('setName', e)}
                            disabled={useProjects[editingProject.id] || false}
                          />
                        </ModalInputArea>
                        <ModalInputArea>
                          <ProjectsModalInputLabel>状態</ProjectsModalInputLabel>
                          <Flex>
                            <ProjectEditRadioValue>
                              <input
                                type="radio"
                                value="enabled"
                                onChange={e => this.handleChangeProjectDisabledRadio(e)}
                                checked={!editingProject.disabled}
                              />
                              <span>有効</span>
                            </ProjectEditRadioValue>
                            <ProjectEditRadioValue>
                              <input
                                type="radio"
                                value="disabled"
                                onChange={e => this.handleChangeProjectDisabledRadio(e)}
                                checked={editingProject.disabled}
                              />
                              <span>無効</span>
                            </ProjectEditRadioValue>
                          </Flex>
                        </ModalInputArea>
                      </EditSection>
                    </EditWrap>
                    {saving && (
                      <Saving>
                        <SimpleLoading />
                      </Saving>
                    )}
                    <ActionWrapper>
                      <CancelButton type="reset" value="キャンセル" onClick={() => this.handleCancelClick()} />
                      <SubmitButton type="submit" value="保存" />
                    </ActionWrapper>
                  </form>
                </BiztraModalBody>
              </BiztraModal>
            )}
          </ContentBody>
          {deletingProject && (
            <Modal show maxWidth={600} hideModal={() => this.handleDeleteAlertClose()}>
              <Confirm
                onConfirmed={() => this.handleDeleteConfirm()}
                onAborted={() => this.handleDeleteAlertClose()}
                message={`${deletingProject.name}(${deletingProject.code})を削除してよろしいですか？\n※この操作は取り消せません`}
              />
            </Modal>
          )}
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const EditWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px calc(20 / 1248 * 100%);
`;

const EditSection = styled.div`
  padding: 5px;
`;

const ProjectsModalInputLabel = styled(ModalInputLabel)`
  min-width: 75px;
`;

const Saving = styled.div``;

export const TheAddButton = styled(OrganizationBiztraAddButton)`
  width: auto;
  margin: 0;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 1.5;
`;

const ContentBody = styled.div`
  flex-grow: 9999;
`;

const ProjectEditRadioValue = styled.label`
  width: 80px;
  > span {
    padding-left: 4px;
  }
`;

const TheTable = styled(OrganizationBiztraTable)`
  margin: 0;
`;

const Flex = styled.div`
  display: flex;
`;

const CancelButton = styled.input`
  &&& {
    ${CancelButtonCss}
  }
`;

const SubmitButton = styled.input`
  &&& {
    ${BaseButtonCss}
  }
`;

export default Projects;
