import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { AllowanceItemForReport, AllowanceItemSort } from '@this/domain/trip_report/allowance_item';
import type { SettingArgs } from '../setting';
import { NonOrderItemForReport, NonOrderItemSort } from './non_order_item';
import { OrderItemPriceDetailForReport } from './order_item_price_detail';
import { Approval, STATUS_NAMES as APPROVAL_STATUS, STATUS_PRIORITY as APPROVAL_PRIORITY } from './approval';
import type { ApprovalArgs } from './approval';
import type {
  NonOrderItemForReportArgs,
  OrderItemPriceDetailForReportArgs,
  AllowanceItemForReportArgs
} from './trip_args';
import type { ApproveItemArgs } from '../approve_item/approve_item';
import type { TripReportApproveItem } from './trip_report_approval_item';

export type TripReportStatus =
  | 'initial'
  | 'draft'
  | 'applying'
  | 'approved'
  | 'send_back'
  | 'self_approved'
  | 'reported'
  | 'cancel';

export const tripReportStatuses: TripReportStatus[] = [
  'initial',
  'draft',
  'applying',
  'approved',
  'send_back',
  'self_approved',
  'reported',
  'cancel'
];

export const STATUS_NAMES: Record<TripReportStatus, string> = {
  initial: '申請前',
  draft: '下書き',
  applying: '申請中',
  approved: '承認済み',
  send_back: '差し戻し',
  self_approved: 'ワークフローなし',
  reported: '報告済み',
  cancel: 'キャンセル'
};

export const STATUS_COLORS: Record<TripReportStatus, { textColor: string; backgroundColor: string }> = {
  initial: { textColor: '#292929', backgroundColor: '#F3F4F6' },
  draft: { textColor: '#292929', backgroundColor: '#F3F4F6' },
  applying: { textColor: '#92400E', backgroundColor: '#FEF3C7' },
  approved: { textColor: '#065F46', backgroundColor: '#D1FAE5' },
  send_back: { textColor: '#991B1B', backgroundColor: '#FEE2E2' },
  self_approved: { textColor: '#065F46', backgroundColor: '#D1FAE5' },
  reported: { textColor: '#065F46', backgroundColor: '#D1FAE5' },
  cancel: { textColor: '#292929', backgroundColor: '#F3F4F6' }
};

export type EditableFields =
  | 'name'
  | 'userName'
  | 'activityContent'
  | 'resultsContent'
  | 'otherText'
  | 'expensesConverted';

export class TripReport {
  id: number;

  name: string;

  userId?: number;

  userName?: string;

  organizationName?: string;

  expensesConverted = false;

  status: TripReportStatus;

  activityContent: string;

  resultsContent: string;

  otherText: string;

  priceDetails: OrderItemPriceDetailForReport[] = [];

  nonOrderItems: NonOrderItemForReport[] = [];

  allowanceItems: AllowanceItemForReport[] = [];

  approvals: Approval[] = [];

  currentApproval: Approval | null = null;

  approveItem?: TripReportApproveItem;

  createdAt: Moment;

  constructor(args: TripReportArgs) {
    this.id = args.id;
    this.name = args.name;
    this.userId = args.user_id;
    this.userName = args.user?.name;
    this.organizationName = args.user?.organization.name;
    this.expensesConverted = args.expenses_converted || false;
    this.status = args.status;
    this.activityContent = args.activity_content || '';
    this.resultsContent = args.results_content || '';
    this.otherText = args.other_text || '';
    this.createdAt = moment(args.created_at);

    if (args.order_item_price_details) {
      this.priceDetails = args.order_item_price_details.map(
        priceDetail => new OrderItemPriceDetailForReport(priceDetail)
      );
    }

    if (args.non_order_items) {
      this.nonOrderItems = args.non_order_items
        .map(item => new NonOrderItemForReport(item))
        .sort(NonOrderItemSort);
    }

    if (args.allowance_items) {
      this.allowanceItems = args.allowance_items
        .map(item => new AllowanceItemForReport(item))
        .sort(AllowanceItemSort);
    }

    if (args.approvals) {
      this.approvals = args.approvals
        .map(approval => new Approval(approval))
        .sort((a, b) => b.createdAt.diff(a.createdAt));
    } else if (args.active_approvals) {
      this.approvals = args.active_approvals.map(approval => new Approval(approval));
    }

    if (args.current_user && this.approvals.length > 0) {
      this.currentApproval = this.findCurrentApproval(args.current_user.id);
    }

    if (args.approve_item) this.approveItem = args.approve_item;
  }

  // 自身の承認ステージを取得する
  // 未承認の承認ステージが残っている場合は、その承認ステージを優先する（複数ステージで承認が必要なユーザーへの対策）
  findCurrentApproval(userId: number) {
    const approvals = this.approvals.filter(approval => approval.includeApprover(userId));
    const sortedApprovals = approvals
      .sort((prev, current) => (prev.approveStage < current.approveStage ? -1 : 1))
      .sort((prev, current) => (APPROVAL_PRIORITY[prev.status] < APPROVAL_PRIORITY[current.status] ? -1 : 1));
    const unapprovedApprovals = sortedApprovals.filter(approval => approval.status !== 'approved');

    if (unapprovedApprovals.length > 0) {
      return unapprovedApprovals[0];
    }

    return sortedApprovals[0] || null;
  }

  convertNewArgs(args: TripReportNewArgs) {
    this.userId = args.user.id;
    this.userName = args.user.name;
    this.organizationName = args.user.organization.name;
  }

  setField<T extends EditableFields>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  statusName() {
    return STATUS_NAMES[this.status];
  }

  statusColor() {
    return STATUS_COLORS[this.status];
  }

  totalPrice() {
    return this.priceDetails.reduce((prevValue, currentValue) => prevValue + currentValue.price, 0);
  }

  totalAdvance() {
    return this.nonOrderItems.reduce((prevValue, currentValue) => prevValue + (currentValue.price || 0), 0);
  }

  totalAllowance() {
    return this.allowanceItems.reduce((prevValue, currentValue) => prevValue + (currentValue.price || 0), 0);
  }

  totalAmount() {
    const amount = this.totalPrice() + this.totalAdvance() + this.totalAllowance();
    return `${amount.toLocaleString()}円`;
  }

  appliedAt() {
    const approvalCreatedAt = this.approvals.map(approval => approval.createdAt).sort((a, b) => a.diff(b))?.[0];
    return approvalCreatedAt || this.createdAt;
  }

  approvedAt() {
    if (this.status !== 'approved') return null;

    return this.approvals
      .map(approval => approval.approvedAt)
      .filter((approvedAt): approvedAt is Moment => !!approvedAt)[0];
  }

  // TODO: 出張タイトルを設定した際に差し替え
  tripTitle() {
    return '〇〇出張／〇〇出張';
  }

  tripSummary() {
    if (this.priceDetails.length < 1 && this.nonOrderItems.length < 1 && this.allowanceItems.length < 1)
      return null;

    const main = this.priceDetails.reduce(
      (prev, current) => (prev.id < current.id ? prev : current),
      this.priceDetails[0]
    );

    // 出張日程：旅程の日付が見つかれば、その範囲を参照。見つからなければ、立替経費の日付を参照。
    const startDate = this.priceDetails.flatMap(d => d.startDate || []).sort((a, b) => a.diff(b))[0];
    const endDate = this.priceDetails.flatMap(d => d.endDate || []).sort((a, b) => b.diff(a))[0];
    const nonOrderItemTimes = this.nonOrderItems.flatMap(d => d.time || []);
    // const allowanceItemTimes = this.allowanceItems.flatMap(d => d.time || []);

    return {
      tripId: main?.tripId,
      from: main?.fromName,
      to: main?.toName,
      startDate: startDate || endDate ? startDate : nonOrderItemTimes.sort((a, b) => a.diff(b))[0],
      endDate: startDate || endDate ? endDate : nonOrderItemTimes.sort((a, b) => b.diff(a))[0]
    };
  }

  // 予算期間の基準日：出張報告内で一番最後の日付を使用する
  budgetBaseDate() {
    return this.priceDetails
      .flatMap(d => d.endDate || [])
      .concat(this.nonOrderItems.flatMap(d => d.time || []))
      .sort((a, b) => b.diff(a))[0];
  }

  expensesConvertible() {
    return !this.expensesConverted && (this.nonOrderItems.length > 0 || this.allowanceItems.length > 0);
  }

  newComment() {
    return this.approvals
      .filter(approval => approval.message)
      .map(approval => `${APPROVAL_STATUS[approval.status]}：${approval.message}`);
  }

  isComment() {
    return this.approvals.some(approval => approval.message);
  }

  approvalGroups() {
    return Approval.approvalGroups(this.approvals);
  }

  submitParams(action: 'draft' | 'apply') {
    let params = {
      name: this.name,
      activity_content: this.activityContent,
      results_content: this.resultsContent,
      other_text: this.otherText
    };
    if (['initial', 'draft', 'send_back'].includes(this.status)) {
      params = { ...params, [action]: true };
    }
    return params;
  }

  static initialize() {
    return new this({
      id: 0,
      name: '',
      user_id: 0,
      status: 'initial',
      approve_item: {
        id: NaN,
        trip_report_id: NaN,
        json: '',
        created_at: '',
        updated_at: ''
      }
    });
  }
}

interface TripReportUser {
  name: string;

  organization: {
    name: string;

    setting?: SettingArgs;
  };
}

interface TripReportCurrentUser {
  id: number;

  organization_id: number;

  name: string;
}

interface TripReportIndexSerializer {
  id: number;

  name: string;

  user_id?: number;

  expenses_converted?: boolean;

  status: TripReportStatus;

  order_item_price_details?: OrderItemPriceDetailForReportArgs[];

  non_order_items?: NonOrderItemForReportArgs[];

  allowance_items?: AllowanceItemForReportArgs[];

  active_approvals?: ApprovalArgs[];

  approve_item?: TripReportApproveItem;

  user?: TripReportUser;

  created_at?: string;
}

interface TripReportShowSerializer {
  id: number;

  name: string;

  status: TripReportStatus;

  activity_content?: string;

  results_content?: string;

  other_text?: string;

  non_order_items?: NonOrderItemForReportArgs[];

  allowance_items?: AllowanceItemForReportArgs[];

  approvals?: ApprovalArgs[];

  user?: TripReportUser;
}

interface TripReportForApprovalIndexSerializer {
  id: number;

  name: string;

  user_id?: number;

  status: TripReportStatus;

  order_item_price_details?: OrderItemPriceDetailForReportArgs[];

  non_order_items?: NonOrderItemForReportArgs[];

  allowance_items?: AllowanceItemForReportArgs[];

  approvals?: ApprovalArgs[];

  user?: TripReportUser;

  current_user?: TripReportCurrentUser;
}

interface TripReportForApprovalShowSerializer extends TripReportShowSerializer {
  current_user?: TripReportCurrentUser;

  approve_item: TripReportApproveItem;
}

export type TripReportArgs = TripReportIndexSerializer &
  TripReportForApprovalIndexSerializer &
  TripReportForApprovalShowSerializer;

export interface TripReportNewArgs {
  user: {
    id: number;

    name: string;

    organization: {
      name: string;

      setting: SettingArgs;
    };
  };
  approve_items: ApproveItemArgs[];
}
