import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';
import type { AdminArgs } from '../admin/admin';
import Admin from '../admin/admin';

export type InvoiceActivityStatus =
  | 'draft'
  | 'applied'
  | 'cancel'
  | 'send_back'
  | 'approved'
  | 'discard'
  | 'publish';

export interface InvoiceActivityArgs {
  id: number;
  invoice_id: number;
  admin_id: number;
  admin?: AdminArgs;
  status: InvoiceActivityStatus;
  step: number;
  memo?: string;
  created_at: string;
  updated_at: string;
  total_steps?: number;
}

class InvoiceActivity {
  id: number;

  invoiceId: number;

  adminId: number;

  status: InvoiceActivityStatus;

  step: number;

  memo: string;

  createdAt: Moment;

  updatedAt: Moment;

  admin: Admin | null = null;

  totalSteps: number;

  constructor(args: InvoiceActivityArgs) {
    this.id = args.id;
    this.invoiceId = args.invoice_id;
    this.adminId = args.admin_id;
    this.status = args.status;
    this.step = args.step;
    this.memo = args.memo ?? '';
    this.createdAt = moment(args.created_at);
    this.updatedAt = moment(args.updated_at);
    this.totalSteps = args.total_steps ?? 1;

    if (args.admin) {
      this.admin = new Admin(args.admin);
    }
  }

  get statusLabel(): string {
    switch (this.status) {
      case 'draft':
        return '保存';
      case 'applied':
        return '申請';
      case 'cancel':
        return '取り下げ';
      case 'send_back':
        return '差し戻し';
      case 'approved':
        if (this.totalSteps <= 1) {
          return '承認';
        }
        if (this.step >= this.totalSteps) {
          return '最終承認';
        }
        return `${this.step}次承認`;

      case 'publish':
        return '発行';
      case 'discard':
        return '発行後取り下げ';
      default:
        return '';
    }
  }
}

export default InvoiceActivity;
