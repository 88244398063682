import type { AdminResourceArgs } from './admin_resource';
import { AdminResource } from './admin_resource';

export interface AdminRoleArgs {
  id: number;
  name: string;
  admin_role_resources: AdminResourceArgs[];
}

export default class AdminRole {
  id: number;

  name: string;

  resources: AdminResource[];

  constructor(args: AdminRoleArgs) {
    this.id = args.id;
    this.name = args.name;
    this.resources = args.admin_role_resources.map(resource => {
      return new AdminResource(resource);
    });
  }
}

export interface AdminRoleResponse {
  role: AdminRoleArgs;
}
