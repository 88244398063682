import _ from 'lodash';
import type Flight from './flight';

class FlightSliceCandidate {
  key: string;

  list: Flight[];

  basePrice: number;

  hovered: boolean;

  isSP: boolean;

  constructor(key: string, flights: Flight[] = [], basePrice: number) {
    this.key = key;
    this.list = flights;
    this.basePrice = basePrice;
    this.hovered = false;
    this.isSP = false;
  }

  setHovered = (value: boolean) => () => {
    if (!this.isSP) {
      this.hovered = value;
      app.render();
    }
  };

  handleTouched = () => {
    this.isSP = true;
  };

  pricePerPerson() {
    return this.cheapestFlight().price / this.list[0].peoplenum;
  }

  cheapestFlight() {
    return _.sortBy(this.list, ['price', 'orderedIndex'])[0];
  }

  priceDiff() {
    const price = this.cheapestFlight().price;
    return price - this.basePrice;
  }

  priceDiffPerPerson() {
    return this.priceDiff() / this.list[0].peoplenum;
  }

  cheapestFlightMinirulePerPerson() {
    const minirule = this.cheapestFlight().minirule;
    const peoplenum = this.cheapestFlight().peoplenum;
    if (minirule && minirule.split(/：|円|　/)[3] !== '不可') {
      const cancelPrice = utils.formatPriceText(minirule.split(/：|円|　/)[3]);
      const cancelPricePerPerson = cancelPrice / peoplenum;
      return minirule.replace(
        `${minirule.split(/：|円|　/)[3]}円`,
        `${utils.formatPrice(cancelPricePerPerson)} * ${peoplenum}人`
      );
    }
    return minirule;
  }
}
export default FlightSliceCandidate;
