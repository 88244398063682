import moment from 'moment';
import type { Moment } from 'moment';
import type { InvoiceCustomCsvBuildActionArgs } from './invoice_custom_csv_build_action';
import InvoiceCustomCsvBuildAction from './invoice_custom_csv_build_action';

export interface InvoiceCustomCsvArgs {
  id: number;
  invoice_id: number;
  invoice_custom_format_id: number;
  invoice_custom_csv_build_action: InvoiceCustomCsvBuildActionArgs;
  updated_at?: string;
}

class InvoiceCustomCsv {
  id: number;

  invoiceId: number;

  invoiceCustomFormatId: number;

  invoiceCustomCsvBuildAction: InvoiceCustomCsvBuildAction;

  updatedAt?: Moment;

  constructor(args: InvoiceCustomCsvArgs) {
    this.id = args.id;
    this.invoiceId = args.invoice_id;
    this.invoiceCustomFormatId = args.invoice_custom_format_id;
    this.invoiceCustomCsvBuildAction = new InvoiceCustomCsvBuildAction(args.invoice_custom_csv_build_action);
    this.updatedAt = args.updated_at ? moment(args.updated_at) : undefined;
  }

  get status() {
    return this.invoiceCustomCsvBuildAction.status;
  }

  get onGoing() {
    return this.invoiceCustomCsvBuildAction.onGoing;
  }
}

export default InvoiceCustomCsv;
