import React, { useEffect, useState } from 'react';

import { styled } from '@this/constants/themes';
import Link from '@this/components/shared/atoms/link';
import type User from '@this/domain/user/user';
import { ByMedia } from '@this/shared/ui/other/by_media';
import HeaderContent from '@this/shared/header/header_content';
import { headerHeight } from '@this/shared/header/header.style';
import HeaderContentSm from '@this/shared/header/header_content_sm';
import { getColor } from '@this/shared/ui/theme';

interface Props {
  user?: User | null;
  ignoreMenu?: boolean;
}

const Header = ({ user, ignoreMenu }: Props) => {
  const [appliedCount, setAppliedCount] = useState<number>(0);

  useEffect(() => {
    if (user === null) return;
    const getAppliedCount = async () => {
      try {
        const res = await utils.jsonPromise<{ applied_count: number }>('/application/in_advance/applied_count');
        setAppliedCount(res.applied_count);
      } catch (e) {
        utils.sendErrorObject(e);
      }
    };
    getAppliedCount();
  }, []);

  const dashbordLocation = () => {
    if (ignoreMenu !== undefined && ignoreMenu === true) {
      return '#';
    }
    if (user && user.id) {
      return '/dashboard';
    }
    return '/';
  };

  return (
    <TheHeader className="noprint">
      <HeaderIn>
        <h1>
          <Logo to={dashbordLocation} />
        </h1>
        {location.href.indexOf('stg.tabikobo') !== -1 && <Environment>(テスト環境)</Environment>}
        {(ignoreMenu === undefined || ignoreMenu === false) && (
          <ByMedia>
            {matches =>
              matches.sm ? (
                <HeaderContentSm user={user} appliedCount={appliedCount} />
              ) : (
                <HeaderContent user={user} appliedCount={appliedCount} />
              )
            }
          </ByMedia>
        )}
      </HeaderIn>
    </TheHeader>
  );
};

const TheHeader = styled.header`
  width: 100%;
  height: ${headerHeight};
`;

const HeaderIn = styled.div`
  display: flex;
  align-items: center;
  background: ${getColor('background', 'tertiary')};
  border-bottom: 1px solid ${getColor('brand', 'primary')};
  height: ${headerHeight};
`;

const Logo = styled(Link)`
  display: block;
  width: 120px;
  height: 28px;
  margin-left: 10px;
  background-image: url('${props => props.theme.logoPath}');
  background-repeat: no-repeat;
  background-size: contain;
`;

const Environment = styled.div`
  color: #fff;
`;
export default Header;
