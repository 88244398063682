import _ from 'lodash';

import { Fetcher, HTTPError } from '@this/src/util';
import { DownloadType, Encoding } from './types';
import CsvBulkAction from './csv_bulk_action';

import { CREATE_FORMATS } from '../project_file_formats';

export default class CsvBulkCreateProjects extends CsvBulkAction {
  dataEnabled = true;

  historyPath = '/organization/bulk_action_statuses?actionType=create';

  backPath = '/organization/projects';

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      formats: CREATE_FORMATS,
      title: 'プロジェクト一括登録',
      description: 'CSVファイルをアップロードすることで、プロジェクトを一括登録することができます。'
    };
  }

  downloadFormat = (type: DownloadType) => {
    if (type === DownloadType.DATA_UTF8 || type === DownloadType.DATA_SJIS) {
      const encoding = type === DownloadType.DATA_UTF8 ? Encoding.UTF8 : Encoding.SJIS;
      location.href = `/organization/projects/csv/bulk_update/template?encoding=${encoding}`;
    } else {
      location.href = `/organization/projects/csv/bulk_create/template?encoding=${type}`;
    }
  };

  upload = async (file: File) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', file);
    try {
      await Fetcher.upload('/organization/projects/csv/bulk_create', formData);
      this.setState({ uploadResult: { uploaded: true } });
    } catch (e) {
      if (e instanceof HTTPError && e.response?.data?.error) {
        this.setState({ uploadResult: { uploaded: false, errors: [e.response.data.error] } });
      } else {
        this.setState({
          uploadResult: { uploaded: false, errors: ['通信エラーが発生しました。時間をおいて再度お試しください。'] }
        });
      }
    }
    this.setState({ loading: false });
  };
}
