import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import type FlightSlice from '@this/domain/flight/flight_slice';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import {
  BoxContent,
  Left,
  Segment,
  Name,
  Image,
  BoxFooter
} from '../../select/transport_box/transport_box_foreign.template';

type FlightBoxTemplateProps = {
  flightSlice: FlightSlice | null;
};

const ElementFlightBoxTemplate: React.SFC<FlightBoxTemplateProps> = ({ flightSlice }) => (
  <FlightBox>
    {flightSlice && (
      <>
        <BoxContent>
          <Left>
            {flightSlice.segments.map((s, i) => (
              <Segment key={i}>
                <div className="flex">
                  <Name data-wovn-ignore>
                    {s.carrier_name && `${s.carrier_name} `}
                    {s.name}
                  </Name>
                  <Image src={utils.flightIconPath(s.name)} />
                </div>
                <div className="select-transport-box__left__icon-body">
                  <img src="/images/departure.png" width="28" height="17" />
                  <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                    {`${s.from.date} ${s.from.time} ${s.from.name}`}
                  </div>
                </div>
                <div className="select-transport-box__left__icon-body">
                  <img src="/images/arrival.png" width="28" height="17" />
                  <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                    {`${s.to.date} ${s.to.time} ${s.to.name}`}
                  </div>
                </div>
                <div className="select-transport-box__left__icon-body">
                  <img
                    src={utils.flightClassIconPath(s.cabin)}
                    title={utils.flightClassName(s.cabin)}
                    width="28"
                    height="17"
                  />
                  <div data-wovn-ignore className="select-transport-box__left__icon-body__text">
                    {utils.flightClassName(s.cabin)}
                  </div>
                </div>
              </Segment>
            ))}
          </Left>
          <FlightBoxWrap>
            <div className="select-transport-box__right-deadline">
              {flightSlice.deadline && (
                <DeadlineWrap title="発券期限は航空会社により予告なく変更される場合があります">
                  発券期限：
                  <Text color="danger" level="caption" bold>
                    <span>ご依頼から</span>
                    <span data-wovn-ignore>{flightSlice.timeToLive}</span>
                    <span>時間</span>
                  </Text>
                </DeadlineWrap>
              )}
            </div>
          </FlightBoxWrap>
        </BoxContent>
        <BoxFooter selected={false}>
          <div>
            <span>所要時間：</span>
            <span data-wovn-ignore>{flightSlice.duration}</span>
          </div>
          <span>表記は現地時刻です。</span>
        </BoxFooter>
      </>
    )}
  </FlightBox>
);

const FlightBox = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.grayBorderColor};
  background: white;
  font-size: 10px;
  flex-direction: column;
`;

const FlightBoxWrap = styled.div`
  display: flex;
  flex-grow: 0;
  margin-left: auto;
  min-width: 170px;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
`;

const DeadlineWrap = styled(Text).attrs({ level: 'caption' })`
  display: flex;
  align-items: center;
  color: inherit;
  margin-top: 8px;
`;

export default ElementFlightBoxTemplate;
