import React, { useState } from 'react';
import A from '@this/components/shared/atoms/a';

import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle,
  Flex,
  ShareDetailLinkArea,
  ShareDetailArea,
  ShareDetailFlexArea,
  ShareDetailIndentedArea,
  ShareDetailLabel,
  ShareDetailSelectArea
} from '@this/components/organization/trips/detail/trip_detail.style';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import type { ExpensesAccountType } from '@this/domain/expenses/expenses_account_type';
import type Trip from '@this/domain/trip/trip';
import type OrderItem from '@this/domain/order_item';
import OrderItemMappingList from '@this/domain/order_item_mapping_list';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import type NonOrderItem from '@this/domain/non_order_item';
import NonOrderItemMappingList from '@this/domain/non_order_item_mapping_list';
import type NonOrderItemMapping from '@this/domain/non_order_item_mapping';

interface Props {
  expensesAccountTypes: ExpensesAccountType[];
  orderItems: OrderItem[];
  nonOrderItems?: NonOrderItem[];
  selectedExpenseId: ReturnType<Trip['expensesAccountTypeId']>;
  showExpenseDetail: boolean;
  setShowExpenseDetail: (status: boolean) => void;
  onSave: (orderItemMapping: OrderItemMappingList, nonOrderItemMappings: NonOrderItemMappingList) => void;
  onSaveDetail: (orderItemMapping: OrderItemMappingList, nonOrderItemMappings: NonOrderItemMappingList) => void;
}

const TripDetailExpensesAccountTypeForm: React.FC<Props> = ({
  expensesAccountTypes,
  orderItems,
  nonOrderItems,
  selectedExpenseId,
  showExpenseDetail,
  setShowExpenseDetail,
  onSave,
  onSaveDetail
}) => {
  const [selectedId, setSelectedId] = useState(selectedExpenseId);
  const [expenseDetailChanged, setExpenseDetailChanged] = useState(false);

  const prepareOrderItemMappingFromTrip = () => {
    const mappings: OrderItemMappingList = new OrderItemMappingList([]);
    orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        const newMapping = mapping.clone();

        const expenses = expensesAccountTypes.find(type => type.id === newMapping.expensesAccountTypeId);
        newMapping.setSelectedOptionLabel(expenses?.codeAndNameOneLine() || '');
        mappings.push(newMapping);
      });
    });
    return mappings;
  };

  const prepareNonOrderItemMappingFromTrip = () => {
    const mappings: NonOrderItemMappingList = new NonOrderItemMappingList([]);
    nonOrderItems?.forEach(item => {
      item.nonOrderItemMappings.forEach(mapping => {
        const newMapping = mapping.clone();

        const expenses = expensesAccountTypes.find(type => type.id === newMapping.expensesAccountTypeId);
        newMapping.setSelectedOptionLabel(expenses?.codeAndNameOneLine() || '');
        mappings.push(newMapping);
      });
    });
    return mappings;
  };

  const [orderItemMappings, setOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [editingOrderItemMappings, setEditingOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [nonOrderItemMappings, setNonOrderItemMappings] = useState(prepareNonOrderItemMappingFromTrip());
  const [editingNonOrderItemMappings, setEditingNonOrderItemMappings] = useState(
    prepareNonOrderItemMappingFromTrip()
  );

  const onSelectExpenseDetail = (
    changingMapping: OrderItemMapping | NonOrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    changingMapping.setExpensesAccountTypeId(Number(option?.value));
    changingMapping.setSelectedOptionLabel(option?.label || '');
    setExpenseDetailChanged(true);
  };

  const createOption = (expense: ExpensesAccountType) => ({
    label: expense.codeAndNameOneLine(),
    value: expense.getIdStr(),
    matcher: expense.codeAndNameOneLine()
  });
  const onSelect = (selected: FilterableSelectorOption | null): void => {
    setSelectedId(selected ? parseInt(selected.value, 10) : null);
    editingOrderItemMappings.list.forEach(mapping => {
      onSelectExpenseDetail(mapping, selected);
    });
    editingNonOrderItemMappings.list.forEach(mapping => {
      onSelectExpenseDetail(mapping, selected);
    });
  };

  const selectedExpense = expensesAccountTypes.find(d => d.id === selectedId);
  const selectOption = selectedExpense ? createOption(selectedExpense) : null;

  const onShowExpenseDetailClick = (value: boolean) => {
    setShowExpenseDetail(value);
  };

  const expenseDetailOption = (): FilterableSelectorOption[] => {
    const expensesOption: FilterableSelectorOption[] = expensesAccountTypes.map(d => {
      return {
        label: d.codeAndNameOneLine(),
        value: d.getIdStr(),
        matcher: d.codeAndNameOneLine()
      };
    });
    return expensesOption;
  };

  const onClickSaveDetail = (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    onSaveDetail(orderItemMappings, nonOrderItemMappings);
    setOrderItemMappings(editingOrderItemMappings.clone());
    setNonOrderItemMappings(editingNonOrderItemMappings.clone());
    setExpenseDetailChanged(false);
  };

  const onCancelDetail = () => {
    setExpenseDetailChanged(false);
    setEditingOrderItemMappings(orderItemMappings.clone());
    setEditingNonOrderItemMappings(nonOrderItemMappings.clone());
  };

  return (
    <MetaSection>
      <Flex>
        <MetaSectionTitle>勘定科目</MetaSectionTitle>
        {showExpenseDetail ? (
          <ShareDetailLinkArea>
            <A onClick={() => onShowExpenseDetailClick(false)}>通常設定へ</A>
          </ShareDetailLinkArea>
        ) : (
          <ShareDetailLinkArea>
            <A onClick={() => onShowExpenseDetailClick(true)}>詳細設定へ</A>
          </ShareDetailLinkArea>
        )}
      </Flex>
      {showExpenseDetail ? (
        <>
          <ShareDetailArea>
            {orderItems.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.categoryStr()}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="勘定科目を検索"
                          options={expenseDetailOption()}
                          selected={{
                            label:
                              editingOrderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                ?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectExpenseDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
            {nonOrderItems?.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.elementTypeForOrganizationTrip === 'hotel' ? 'ホテル' : '経路'}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="勘定科目を検索"
                          options={expenseDetailOption()}
                          selected={{
                            label:
                              editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                                traveler.id,
                                item.id
                              )?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectExpenseDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
          </ShareDetailArea>
          {expenseDetailChanged && (
            <MetaSectionFormActions>
              <MetaSectionFormButton
                onClick={() => onClickSaveDetail(editingOrderItemMappings, editingNonOrderItemMappings)}
              >
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => onCancelDetail()}>キャンセル</MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      ) : (
        <>
          <FilterableSelector
            placeholder="勘定科目を検索"
            options={expenseDetailOption()}
            selected={selectOption}
            onSelect={onSelect}
          />
          {selectedExpenseId !== selectedId && (
            <MetaSectionFormActions>
              <MetaSectionFormButton onClick={() => onSave(editingOrderItemMappings, editingNonOrderItemMappings)}>
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => setSelectedId(selectedExpenseId)}>
                キャンセル
              </MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      )}
    </MetaSection>
  );
};

export default TripDetailExpensesAccountTypeForm;
