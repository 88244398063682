import type { ChangeEvent } from 'react';
import React from 'react';
import type { Moment } from 'moment';
import DatePickerForSearch from '../search_form/date_picker_for_search';
import {
  Wrapper,
  Header,
  SearchFormSection,
  Row1Column,
  Row3Column,
  Title,
  DatePickerForSearchWrapper,
  SearchFormInput,
  FromTo,
  SelectWrapper,
  Select,
  ActionWrapper,
  OutlineSmallButton,
  SecondarySmallButton
} from '../report_items/search_form';

interface Props {
  onSetSearchParams: (
    appliedAtFrom: string,
    appliedAtTo: string,
    selectedPayee: string,
    priceFrom: string,
    priceTo: string,
    tripReportId: string
  ) => void;
  onCancel: () => void;
  payees: string[];
  tripReports?: [number, string][];
  periodFrom: string;
  periodTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  tripReportId?: string;
  tripReportAvailable?: boolean;
}

interface State {
  periodFrom: string;
  periodTo: string;
  selectedPayee: string;
  priceFrom: string;
  priceTo: string;
  tripReportId: string;
}

export default class SearchForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      periodFrom: props.periodFrom,
      periodTo: props.periodTo,
      selectedPayee: props.selectedPayee,
      priceFrom: props.priceFrom,
      priceTo: props.priceTo,
      tripReportId: props.tripReportId || ''
    };
  }

  getFormat = (): string => 'YYYY-MM-DD';

  handleChangePeriodFrom = (value: Moment) => {
    this.setState({ periodFrom: (value && value.format(this.getFormat())) || '' });
  };

  handleChangePeriodTo = (value: Moment) => {
    this.setState({ periodTo: (value && value.format(this.getFormat())) || '' });
  };

  handleChangeSelectedPayee = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedPayee: e.target.value });
  };

  handleChangePriceFrom = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceFrom: e.target.value });
  };

  handleChangePriceTo = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceTo: e.target.value });
  };

  handleChangeTripReportId = (e: ChangeEvent<HTMLSelectElement>) => {
    this.setState({ tripReportId: e.target.value });
  };

  setSearchParams = () => {
    this.props.onSetSearchParams(
      this.state.periodFrom,
      this.state.periodTo,
      this.state.selectedPayee,
      this.state.priceFrom,
      this.state.priceTo,
      this.state.tripReportId
    );
  };

  render() {
    return (
      <Wrapper>
        <Header>検索条件を指定</Header>
        <SearchFormSection>
          <Title>期間</Title>
          <Row3Column>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.periodFrom} onChange={this.handleChangePeriodFrom} />
            </DatePickerForSearchWrapper>
            <FromTo>～</FromTo>
            <DatePickerForSearchWrapper>
              <DatePickerForSearch value={this.state.periodTo} onChange={this.handleChangePeriodTo} />
            </DatePickerForSearchWrapper>
          </Row3Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>取引先</Title>
          <Row1Column>
            <Select value={this.state.selectedPayee} onChange={this.handleChangeSelectedPayee}>
              <option value="" />
              {this.props.payees.map(payee => (
                <option value={payee} key={payee}>
                  {payee}
                </option>
              ))}
            </Select>
          </Row1Column>
        </SearchFormSection>
        <SearchFormSection>
          <Title>金額</Title>
          <Row3Column>
            <SearchFormInput
              value={this.state.priceFrom}
              onChange={this.handleChangePriceFrom}
              size="small"
              type="number"
              inputProps={{
                placeholder: '100000',
                style: { fontSize: 14 }
              }}
            />
            <FromTo>～</FromTo>
            <SearchFormInput
              value={this.state.priceTo}
              onChange={this.handleChangePriceTo}
              size="small"
              type="number"
              inputProps={{
                placeholder: '999999999',
                style: { fontSize: 14 }
              }}
            />
          </Row3Column>
        </SearchFormSection>
        {this.props.tripReportAvailable && this.props.tripReports && this.props.tripReports.length > 0 && (
          <SearchFormSection>
            <Title>出張報告</Title>
            <Row1Column>
              <SelectWrapper>
                <Select value={this.state.tripReportId} onChange={this.handleChangeTripReportId}>
                  <option value="">選択してください</option>
                  {this.props.tripReports.map(([id, name]) => (
                    <option value={id.toString()} key={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            </Row1Column>
          </SearchFormSection>
        )}
        <ActionWrapper>
          <OutlineSmallButton onClick={this.props.onCancel}>キャンセル</OutlineSmallButton>
          <SecondarySmallButton onClick={this.setSearchParams}>検索</SecondarySmallButton>
        </ActionWrapper>
      </Wrapper>
    );
  }
}
