import _ from 'lodash';

interface Args {
  price?: number;
  purpose: string;
}

class NonOrderItemPriceDetail {
  price: number;

  purpose: string;

  constructor(args: Args) {
    this.price = args.price || 0;
    this.purpose = args.purpose;
  }

  setPrice(value: number) {
    this.price = value;
    app.render();
  }

  updateParams() {
    return {
      price: this.price,
      purpose: this.purpose
    };
  }
}

export default NonOrderItemPriceDetail;
