import moment from 'moment';

class BulkActionAdminUser {
  email: string;

  status: string;

  changes: Record<string, { before: any; after: any }>;

  errorMessage: string;

  createdAt: string;

  updatedAt: string;

  constructor(raw: any) {
    this.email = raw.email;
    this.status = raw.status;
    this.changes = raw.changes || {};
    this.errorMessage = raw.error_message;
    this.createdAt = moment(raw.created_at).format('YYYY-MM-DD HH:mm:ss');
    this.updatedAt = moment(raw.updated_at).format('YYYY-MM-DD HH:mm:ss');
  }

  statusMessage() {
    switch (this.status) {
      case 'enqueued':
        return '未着手';
      case 'running':
        return '着手中';
      case 'success':
        return '成功';
      case 'skip':
        return `スキップ: ${this.errorMessage}`;
      case 'fail':
        return `失敗: ${this.errorMessage}`;
      default:
        return '';
    }
  }

  isSuccess() {
    return this.status === 'success';
  }

  isFail() {
    return this.status === 'fail';
  }

  hasChange(name: string): { before: any; after: any } | null {
    if (!this.changes) return null;

    const change = this.changes[name];
    if (change && change.before !== change.after) {
      return change;
    }
    return null;
  }
}

export default BulkActionAdminUser;
