import React from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import { media } from '@this/components/shared/atoms/media';
import type Hotel from '@this/domain/hotel/hotel';
import A from '@this/components/shared/atoms/a';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CachedIcon from '@material-ui/icons/Cached';

export const HotelBox = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.grayBorderColor};
  background: white;
  font-size: 10px;

  flex-wrap: wrap;

  width: 100%;
  background: white;
  position: relative;
`;

const imageWidth = '100px';

const HotelImageAreaDiv = styled.div`
  min-width: ${imageWidth};
  min-height: 110px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${media.sp`
    width: 100%;
    height: 150px;
  `}
`;

export const HotelImageArea = ({ hotel }: { hotel: Hotel }) => (
  <HotelImageAreaDiv style={{ backgroundImage: `url(${hotel.image})` }} />
);

export const HotelContentArea = styled.div`
  flex-grow: 9999;
  display: flex;
  max-width: calc(100% - ${imageWidth}); /* 画像幅を引く */
  ${media.sp`
    max-width: 100%;
  `}
`;

export const Left = styled.div`
  padding: 5px 5px 5px 10px;
`;

export const HotelNameArea = styled.div`
  display: flex;
`;

export const HotelNameDiv = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  width: 160px;
  ${media.sp`
    width: 80px;
`}
`;

export const HotelName = ({ hotel }: { hotel: Hotel }) => (
  <HotelNameArea>
    <HotelNameDiv data-wovn-ignore>{hotel.name}</HotelNameDiv>
  </HotelNameArea>
);

export const HotelBody = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Row = styled.div`
  display: flex;
`;

const Cell = styled.div`
  float: left;
`;

const HotelDetail = ({ hotel, theme }: { hotel: Hotel; theme: { themeClass: string } }) => (
  <div>
    <Row>
      <Cell className="select-hotel-box__detail-label">目的地まで</Cell>
      <Cell className="select-hotel-box__detail-separator">：</Cell>
      <Cell data-wovn-ignore className="select-hotel-box__detail-value">
        {hotel.walkminute ? `徒歩${hotel.walkminute}` : hotel.latitude && hotel.longitude ? '(計算中...)' : '-'}
      </Cell>
    </Row>
    <Row>
      <Cell className="select-hotel-box__detail-label">最寄駅まで</Cell>
      <Cell className="select-hotel-box__detail-separator">：</Cell>
      <Cell data-wovn-ignore className="select-hotel-box__detail-value">
        {hotel.station_distance ? `徒歩${Math.floor(hotel.station_distance / 80)}分` : '-'}
      </Cell>
    </Row>
    <Row>
      <Cell className="select-hotel-box__detail-label">キャンセル</Cell>
      <Cell className="select-hotel-box__detail-separator">：</Cell>
      <Cell className="select-hotel-box__detail-value">
        {hotel.package_type && theme.themeClass === 'mynavi' ? (
          <A
            href="https://btm.mynavi.jp/terms/pdf/cancellation_policy_rakutendp.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            詳細を確認
          </A>
        ) : hotel.package_type ? (
          `出発の21日前まで無料`
        ) : ['r', 'new_rakuten', 'agoda'].includes(hotel.type || '') && !hotel.sold_out ? (
          <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
            詳細を確認
          </A>
        ) : theme.themeClass === 'tabikobo' && hotel.refund === '不可' ? (
          <WarningText>{hotel.refund}</WarningText>
        ) : (
          hotel.refund
        )}
      </Cell>
    </Row>
    {/*
      本当は新版APIのJRPackegeでもホテルの詳細リンクを表示したいが、表示手段が現状不明なためコメントアウトする。
      {(!(hotel.package_type || hotel.sold_out) || hotel.package_type === 'RP' || hotel.package_type === 'JR') && ( <Row>
          <Cell>
            <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
              詳細を見る
              {hotel.package_type === 'RP' || hotel.package_type === 'JR' ? '(外部サイトに移動します)' : null}
            </A>
          </Cell>
        </Row>
      )}
    */}
    {(!(hotel.package_type || hotel.sold_out) || hotel.package_type === 'RP') && (
      <Row>
        <Cell>
          <A href={hotel.detail_path || ''} target="_blank" rel="noreferrer noopener">
            詳細情報（プラン名・設備等）
            {hotel.package_type === 'RP' ? '(外部サイトに移動します)' : null}
          </A>
        </Cell>
      </Row>
    )}
  </div>
);

export const Right = styled.div`
  flex-shrink: 0;
  margin: auto 10px auto auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BreakfastDiv = styled.div`
  margin: 4px 0 5px 0;
  height: 14px;
  width: 45px;
  background-color: #bbb;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: white;
    font-size: 9px;
    letter-spacing: 0;
  }
`;

export const Breakfast = () => (
  <BreakfastDiv>
    <span>朝食付き</span>
  </BreakfastDiv>
);

const NoteDiv = styled.div`
  margin-bottom: 5px;
  color: ${props => props.theme.redColor};
`;

export const Note = ({ hotel, hotelPriceLimit }: { hotel: Hotel; hotelPriceLimit: number | null }) => {
  const limit = hotelPriceLimit || 0;
  const isOverLimit = () => {
    return limit > 0 && hotel.getAveragePrice()! > limit;
  };
  return (
    <>
      {isOverLimit() && (
        <NoteDiv>
          <span>規程金額</span>
          <span data-wovn-ignore>{` +${utils.formatPrice(hotel.getAveragePrice()! - limit)}`}</span>
        </NoteDiv>
      )}
    </>
  );
};

export const Rating = ({ rating }: { rating: number }) => <RatingDiv rating={rating} />;

const RatingDiv = styled.div<{ rating: number | undefined }>`
  position: relative;
  display: inline;
  letter-spacing: 0;
  &::before,
  &::after {
    content: '★★★★★';
  }
  &::before {
    color: #ccc;
    width: 5em;
  }
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    color: #ffa500;
    overflow: hidden;
    width: ${props => props.rating}em;
  }
`;

export const AnnounceOverLimit = ({
  hotel,
  hotelPriceLimit,
  loading
}: {
  hotel: Hotel;
  hotelPriceLimit: number | null;
  loading: boolean;
}) => {
  const limit = hotelPriceLimit || 0;
  const isOverLimit = limit > 0 && hotel.getAveragePriceWithTax()! > limit;
  if (!isOverLimit) return null;
  if (loading) return null;
  return (
    <AnnounceOverLimitDiv>
      <StyledAnnouncementIcon />
      <span>規程値オーバー</span>
    </AnnounceOverLimitDiv>
  );
};

const AnnounceOverLimitDiv = styled.div`
  color: ${props => props.theme.redColor};
`;

const StyledAnnouncementIcon = styled(AnnouncementIcon)`
  font-size: 13px;
  vertical-align: bottom;
`;

export const FetchNewPriceText = ({
  hotel,
  hotelPriceLimit,
  loading
}: {
  hotel: Hotel;
  hotelPriceLimit: number | null;
  loading: boolean;
}) => {
  const limit = hotelPriceLimit || 0;
  const isOverLimit = limit > 0 && hotel.getAveragePriceWithTax()! > limit;
  if (isOverLimit) return null;
  if (loading) return null;
  return (
    <FetchNewPriceTextDiv>
      <StyledCachedIcon />
      <span>最新の料金を取得しました</span>
    </FetchNewPriceTextDiv>
  );
};

const FetchNewPriceTextDiv = styled.div`
  color: ${props => props.theme.successColor};
`;

const StyledCachedIcon = styled(CachedIcon)`
  font-size: 13px;
  vertical-align: bottom;
`;

const WarningText = styled.span`
  font-weight: bold;
  color: ${props => props.theme.redColor};
`;

export default withTheme(HotelDetail);
