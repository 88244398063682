import React from 'react';
import { styled } from '@this/constants/themes';
import type SeatMapFacility from '@this/domain/flight/seat_map_facility';

interface Props {
  seatMapFacility: SeatMapFacility;
  onClick: (f: SeatMapFacility) => void;
}

const FlightSeatMapFacility: React.FC<Props> = ({ seatMapFacility, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(seatMapFacility);
  };

  return (
    <Container>
      {seatMapFacility.type === 'seat' ? (
        <>
          {seatMapFacility.availability === 'available' ? (
            <AvailableSeat selected={seatMapFacility.selected} onClick={handleClick}>
              {seatMapFacility.seatCode}
            </AvailableSeat>
          ) : seatMapFacility.availability === 'no_seat' ? (
            <NoSeat />
          ) : (
            <OccupiedSeat>{seatMapFacility.seatCode}</OccupiedSeat>
          )}
        </>
      ) : (
        <Aisle />
      )}
    </Container>
  );
};

const Container = styled.div`
  aspect-ratio: 1;
  width: 100%;
  padding: 5%;
`;

const Seat = styled.div`
  aspect-ratio: 1;
  width: 100%;
  border-radius: 10%;
  display: grid;
  place-items: center;
`;

export const AvailableSeat = styled(Seat as any)<{ selected: boolean }>`
  background-color: ${props => (props.selected ? '#102663' : '#d4e4d9')};
  ${props =>
    props.selected &&
    `
    color: white;
    `}

  &:hover {
    opacity: 0.33;
    cursor: pointer;
  }
`;

export const OccupiedSeat = styled(Seat as any)`
  background-color: #ddd;
`;

const NoSeat = styled(Seat as any)`
  visibility: hidden;
`;

const Aisle = styled.div`
  aspect-ratio: 1;
  width: 100%;
  visibility: hidden;
`;

export default FlightSeatMapFacility;
