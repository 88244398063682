import { observable } from 'mobx';

export type AccountTypeMappingJson = {
  id: number;
  debt_account_type_id: number;
  credit_account_type_id: number;
  department_id?: number;
  cost_type_name: string;
  cost_type_category: string;
  share_with_travelers: boolean; // 郵送費用
};

class AccountTypeMapping {
  readonly id: number;

  @observable
  debtAccountTypeId: number;

  @observable
  creditAccountTypeId: number;

  @observable
  departmentId?: number;

  @observable
  shareWithTravelers: boolean;

  readonly costTypeName: string;

  readonly costTypeCategory: string;

  constructor(args: AccountTypeMappingJson) {
    this.id = args.id;
    this.debtAccountTypeId = args.debt_account_type_id;
    this.creditAccountTypeId = args.credit_account_type_id;
    this.departmentId = args.department_id;
    this.costTypeName = args.cost_type_name;
    this.costTypeCategory = args.cost_type_category;
    this.shareWithTravelers = args.share_with_travelers;
  }

  submitParams() {
    return {
      id: this.id,
      debt_account_type_id: this.debtAccountTypeId,
      credit_account_type_id: this.creditAccountTypeId,
      department_id: this.departmentId,
      share_with_travelers: this.shareWithTravelers
    };
  }
}

export default AccountTypeMapping;
