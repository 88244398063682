import React, { useCallback } from 'react';

import { styled } from '@this/constants/themes';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';
import type ChatbotUserSetting from '@this/src/domain/chatbot/chatbot_user_setting';

interface Props extends ChatMessageDefaultOriginToggleOptions {
  message: ChatbotMessage;
}

export interface ChatMessageDefaultOriginToggleOptions {
  userSetting: ChatbotUserSetting;
  updateDefaultOrigin: (origin?: string) => void;
}

export const ChatMessageDefaultOriginToggle: React.FC<Props> = ({ message, userSetting, updateDefaultOrigin }) => {
  const origin = message.getOrigin();

  const handleToggle = useCallback(() => {
    if (origin === userSetting.defaultOrigin) {
      updateDefaultOrigin();
    } else {
      updateDefaultOrigin(origin);
    }
  }, [origin, userSetting, updateDefaultOrigin]);

  return (
    <WhiteCheckbox color="secondary" checked={origin === userSetting.defaultOrigin} onChange={handleToggle}>
      この出発地をデフォルトにする
    </WhiteCheckbox>
  );
};

const WhiteCheckbox = styled(Checkbox)`
  margin-top: 4px;
  & .MuiCheckbox-root,
  & .MuiCheckbox-colorPrimary.Mui-checked {
    color: #fff;
  }
  & .MuiFormControlLabel-label {
    margin-top: 2px;
  }
`;
