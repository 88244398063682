import TextToQueryResultItem, {
  convertTextToQueryResultItemResponseToArgs
} from '@this/src/domain/chatbot/text_to_query_result_item';
import type {
  TextToQueryFormAttributesResponseArgs,
  TextToQueryResultItemResponseArgs
} from '@this/src/domain/chatbot/text_to_query_result_item';

import objectToQueryString from '../share/object_to_query_string';

interface Props {
  sessionId?: number;
  message: string;
  onStarted?: () => void;
  onComplete?: (state: CompleteState) => void;
  onIncomplete?: (state: IncompleteState) => void;
  onError?: (message: string) => void;
  onFinally?: () => void;
}

interface CompleteState {
  url: string;
  message: string;
}

interface IncompleteState {
  message: string;
  formAttributes: TextToQueryFormAttributesResponseArgs;
}

interface TextToQueryResponse {
  items: TextToQueryResultItemResponseArgs[];
  message: string | null;
  error_message: string | null;
  form_attributes: TextToQueryFormAttributesResponseArgs | null;
}

const textToQueryApi = ({
  sessionId,
  message,
  onStarted,
  onComplete,
  onIncomplete,
  onError,
  onFinally
}: Props) => {
  if (message.length > 0) {
    onStarted?.();
    utils
      .jsonPromise<TextToQueryResponse>('/text_to_query', { session_id: sessionId, text: message })
      .then(res => {
        if (res.error_message) {
          onError?.(res.error_message);
          return;
        }

        // /select内で遷移した場合検索が発火しないため、rebuildを付与してSelect.tsxのcomponentDidUpdateで再検索させている
        // Select.tsx: ./js/components/reserve_trip/select/Select.tsx
        const items = res.items.map(
          raw => new TextToQueryResultItem(convertTextToQueryResultItemResponseToArgs(raw))
        );
        const params = {
          peoplenum: '1',
          roomnum: '1',
          search_type: 'roundTrip',
          type: 'separate',
          items: items.map((item, index) => item.toSelectQuery(index)),
          ...(location.pathname === '/select' && { rebuild: 'true' })
        };

        if (res.form_attributes?.form_status === 'active' && res.form_attributes) {
          onIncomplete?.({ message: res.message || '', formAttributes: res.form_attributes });
          return;
        }

        onComplete?.({ url: `/select?${objectToQueryString(params)}`, message: res.message || '' });
      })
      .finally(() => {
        onFinally?.();
      });
  }
};

export default textToQueryApi;
