import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import A from '@this/shared/atoms/a';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';

import type ReserveInfo from '@this/domain/reserve_info';
import type { OrderItemMappingMode } from '@this/domain/order_item_mapping';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import Department from '@this/domain/department/department';
import InputCustomerInformationFilterableSelector from '../input_customer_information_filterable_selector/input_customer_information_filterable_selector';
import SelectDepartments from '../select_departments/select_departments';

type Props = {
  showDetail: boolean;
  onClickShowDetail: () => void;
  onClickHideDetail: () => void;
  detailMode: OrderItemMappingMode;
  onClickChangeDetailMode: (mode: OrderItemMappingMode) => void;
  onSelectDetail: (mapping: OrderItemMapping, option: FilterableSelectorOption | null) => void;
  onSelect: (department: Department) => void;
  reserveInfo: ReserveInfo;
  itemList: any;
  workflowFormWidth: string;
  projectShareAvailability: boolean;
};

const DETAIL_FORM_WIDTH = '240px';
const FORM_WIDTH = '100%';

const ChargingDepartmentSection: React.FC<Props> = ({
  showDetail,
  onClickShowDetail,
  onClickHideDetail,
  detailMode,
  onClickChangeDetailMode,
  onSelectDetail,
  onSelect,
  reserveInfo,
  itemList,
  workflowFormWidth,
  projectShareAvailability
}) => {
  const defaultDepartment = (id: number, item_type: string, index: number): string => {
    const department = new Department(reserveInfo.getFirstExistAccountTraveler().department);
    const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(id, item_type, index);

    const label = department.descriptionWithCode();
    if (department && mapping) {
      mapping.chargingDepartmentId = department.id;
      mapping.chargingDepartmentShareId = null;
      mapping.setSelectedOptionLabel(label);
    }

    return label;
  };

  return (
    <>
      <Label>
        費用負担部署
        {reserveInfo.expensesAccountTypeAvailable && reserveInfo.isChargingDepartmentRequired() && <Red>*</Red>}
      </Label>
      {showDetail ? (
        <ShareDetailArea workflowFormWidth={workflowFormWidth}>
          {detailMode === 'item' ? (
            <>
              <ShareDetailFlexArea>
                <ShareDetailModeSelected>商品ごと</ShareDetailModeSelected>
                <ShareDetailModeLinkArea>
                  <A onClick={() => onClickChangeDetailMode('traveler')}>出張者ごと</A>
                </ShareDetailModeLinkArea>
                <ShareDetailModeLinkArea>
                  <A onClick={() => onClickChangeDetailMode('both')}>商品×出張者ごと</A>
                </ShareDetailModeLinkArea>
              </ShareDetailFlexArea>
              {reserveInfo.reservingTrip.isPackage() ? (
                <ShareDetailFlexArea>
                  <MappingDetailLabel>パッケージ</MappingDetailLabel>
                  <ShareDetailSelectArea>
                    <InputCustomerInformationFilterableSelector
                      placeholder="部署を検索"
                      options={reserveInfo.prepareDepartmentDetailOption()}
                      selected={{
                        label:
                          reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(-1, 'package', 1)
                            ?.selectedOptionLabel || defaultDepartment(-1, 'package', 1),
                        value: '',
                        matcher: ''
                      }}
                      onSelect={option => {
                        const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                          -1,
                          'package',
                          1
                        );
                        if (mapping) {
                          onSelectDetail(mapping, option);
                        }
                      }}
                      isRequired={reserveInfo.isChargingDepartmentRequired()}
                      workflowFormWidth={DETAIL_FORM_WIDTH}
                    />
                  </ShareDetailSelectArea>
                </ShareDetailFlexArea>
              ) : (
                <>
                  {itemList.map((item: any, i: number) => (
                    <div key={`item${i}`}>
                      <ShareDetailFlexArea>
                        {item?.element_type === 'hotel' || item?.elementType === 'hotel' ? (
                          <ShareDetailLabel>{i + 1}. ホテル</ShareDetailLabel>
                        ) : (
                          <ShareDetailLabel>{i + 1}. 経路</ShareDetailLabel>
                        )}
                        <ShareDetailSelectArea>
                          <InputCustomerInformationFilterableSelector
                            placeholder="部署を検索"
                            options={reserveInfo.prepareDepartmentDetailOption()}
                            selected={{
                              label:
                                reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                  -1,
                                  'item',
                                  Number(item?.index)
                                )?.selectedOptionLabel || defaultDepartment(-1, 'item', Number(item?.index)),
                              value: '',
                              matcher: ''
                            }}
                            onSelect={option => {
                              const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                -1,
                                'item',
                                Number(item?.index)
                              );
                              if (mapping) {
                                onSelectDetail(mapping, option);
                              }
                            }}
                            isRequired={reserveInfo.isChargingDepartmentRequired()}
                            workflowFormWidth={DETAIL_FORM_WIDTH}
                          />
                        </ShareDetailSelectArea>
                      </ShareDetailFlexArea>
                    </div>
                  ))}
                </>
              )}
              {reserveInfo.rentalCarRequired === 'true' && (
                <>
                  {reserveInfo.rentalCars.map((_rentalCar, car_index) => (
                    <ShareDetailFlexArea key={`car${car_index}`}>
                      <ShareDetailLabel>レンタカー {car_index + 1}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <InputCustomerInformationFilterableSelector
                          placeholder="部署を検索"
                          options={reserveInfo.prepareDepartmentDetailOption()}
                          selected={{
                            label:
                              reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                -1,
                                'rental_car',
                                car_index
                              )?.selectedOptionLabel || defaultDepartment(-1, 'rental_car', car_index),
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                              -1,
                              'rental_car',
                              car_index
                            );
                            if (mapping) {
                              onSelectDetail(mapping, option);
                            }
                          }}
                          isRequired={reserveInfo.isChargingDepartmentRequired()}
                          workflowFormWidth={DETAIL_FORM_WIDTH}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </>
              )}
            </>
          ) : detailMode === 'traveler' ? (
            <>
              <ShareDetailFlexArea>
                <>
                  <ShareDetailModeLinkArea>
                    <A onClick={() => onClickChangeDetailMode('item')}>商品ごと</A>
                  </ShareDetailModeLinkArea>
                  <ShareDetailModeSelected>出張者ごと</ShareDetailModeSelected>
                  <ShareDetailModeLinkArea>
                    <A onClick={() => onClickChangeDetailMode('both')}>商品×出張者ごと</A>
                  </ShareDetailModeLinkArea>
                </>
              </ShareDetailFlexArea>
              {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                <ShareDetailFlexArea key={traveler_index}>
                  <ShareDetailLabel>{traveler.getTravelerName()}</ShareDetailLabel>
                  <ShareDetailSelectArea>
                    <InputCustomerInformationFilterableSelector
                      placeholder="部署を検索"
                      options={reserveInfo.prepareDepartmentDetailOption()}
                      selected={{
                        label:
                          reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                            traveler_index,
                            'all',
                            -1
                          )?.selectedOptionLabel || defaultDepartment(traveler_index, 'all', -1),
                        value: '',
                        matcher: ''
                      }}
                      onSelect={option => {
                        const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                          traveler_index,
                          'all',
                          -1
                        );
                        if (mapping) {
                          onSelectDetail(mapping, option);
                        }
                      }}
                      isRequired={reserveInfo.isChargingDepartmentRequired()}
                      workflowFormWidth={DETAIL_FORM_WIDTH}
                    />
                  </ShareDetailSelectArea>
                </ShareDetailFlexArea>
              ))}
            </>
          ) : detailMode === 'both' ? (
            <>
              <ShareDetailFlexArea>
                <ShareDetailModeLinkArea>
                  <A onClick={() => onClickChangeDetailMode('item')}>商品ごと</A>
                </ShareDetailModeLinkArea>
                <ShareDetailModeLinkArea>
                  <A onClick={() => onClickChangeDetailMode('traveler')}>出張者ごと</A>
                </ShareDetailModeLinkArea>
                <ShareDetailModeSelected>商品×出張者ごと</ShareDetailModeSelected>
              </ShareDetailFlexArea>
              {reserveInfo.reservingTrip.isPackage() ? (
                <>
                  <MappingDetailLabel>パッケージ</MappingDetailLabel>
                  <ShareDetailIndentedArea>
                    {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                      <ShareDetailFlexArea key={traveler_index}>
                        <MappingDetailLabel>{traveler.getTravelerName()}</MappingDetailLabel>
                        <ShareDetailSelectArea>
                          <InputCustomerInformationFilterableSelector
                            placeholder="部署を検索"
                            options={reserveInfo.prepareDepartmentDetailOption()}
                            selected={{
                              label:
                                reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                  traveler_index,
                                  'package',
                                  1
                                )?.selectedOptionLabel || defaultDepartment(traveler_index, 'package', 1),
                              value: '',
                              matcher: ''
                            }}
                            onSelect={option => {
                              const mapping = reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                traveler_index,
                                'package',
                                1
                              );
                              if (mapping) {
                                onSelectDetail(mapping, option);
                              }
                            }}
                            isRequired={reserveInfo.isChargingDepartmentRequired()}
                            workflowFormWidth={DETAIL_FORM_WIDTH}
                          />
                        </ShareDetailSelectArea>
                      </ShareDetailFlexArea>
                    ))}
                  </ShareDetailIndentedArea>
                </>
              ) : (
                <>
                  {itemList.map((item: any, i: number) => (
                    <div key={`item${i}`}>
                      {item?.element_type === 'hotel' || item?.elementType === 'hotel' ? (
                        <MappingDetailLabel>{i + 1}. ホテル</MappingDetailLabel>
                      ) : (
                        <MappingDetailLabel>{i + 1}. 経路</MappingDetailLabel>
                      )}
                      <ShareDetailIndentedArea>
                        {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                          <ShareDetailFlexArea key={traveler_index}>
                            <MappingDetailLabel>{traveler.getTravelerName()}</MappingDetailLabel>
                            <ShareDetailSelectArea>
                              <InputCustomerInformationFilterableSelector
                                placeholder="部署を検索"
                                options={reserveInfo.prepareDepartmentDetailOption()}
                                selected={{
                                  label:
                                    reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                      traveler_index,
                                      'item',
                                      Number(item?.index)
                                    )?.selectedOptionLabel ||
                                    defaultDepartment(traveler_index, 'item', Number(item?.index)),
                                  value: '',
                                  matcher: ''
                                }}
                                onSelect={option => {
                                  const mapping =
                                    reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                      traveler_index,
                                      'item',
                                      Number(item?.index)
                                    );
                                  if (mapping) {
                                    onSelectDetail(mapping, option);
                                  }
                                }}
                                isRequired={reserveInfo.isChargingDepartmentRequired()}
                                workflowFormWidth={DETAIL_FORM_WIDTH}
                              />
                            </ShareDetailSelectArea>
                          </ShareDetailFlexArea>
                        ))}
                      </ShareDetailIndentedArea>
                    </div>
                  ))}
                </>
              )}
              {reserveInfo.rentalCarRequired === 'true' && (
                <>
                  {reserveInfo.rentalCars.map((_rentalCar, car_index) => (
                    <div key={`car${car_index}`}>
                      <MappingDetailLabel>レンタカー {car_index + 1}</MappingDetailLabel>
                      <ShareDetailIndentedArea>
                        {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                          <ShareDetailFlexArea key={traveler_index}>
                            <MappingDetailLabel>{traveler.getTravelerName()}</MappingDetailLabel>
                            <ShareDetailSelectArea>
                              <InputCustomerInformationFilterableSelector
                                placeholder="部署を検索"
                                options={reserveInfo.prepareDepartmentDetailOption()}
                                selected={{
                                  label:
                                    reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                      traveler_index,
                                      'rental_car',
                                      car_index
                                    )?.selectedOptionLabel ||
                                    defaultDepartment(traveler_index, 'rental_car', car_index),
                                  value: '',
                                  matcher: ''
                                }}
                                onSelect={option => {
                                  const mapping =
                                    reserveInfo.orderItemMappingsDepartmentPart.findByTravelerAndItem(
                                      traveler_index,
                                      'rental_car',
                                      car_index
                                    );
                                  if (mapping) {
                                    onSelectDetail(mapping, option);
                                  }
                                }}
                                isRequired={reserveInfo.isChargingDepartmentRequired()}
                                workflowFormWidth={DETAIL_FORM_WIDTH}
                              />
                            </ShareDetailSelectArea>
                          </ShareDetailFlexArea>
                        ))}
                      </ShareDetailIndentedArea>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <DetailLinkArea>
            <A onClick={onClickHideDetail}>キャンセル</A>
          </DetailLinkArea>
        </ShareDetailArea>
      ) : (
        <>
          <InputAreaRight data-testid="charging_department_select">
            <SelectDepartments
              departments={reserveInfo.departments}
              onSelect={onSelect}
              disabledAddress={false}
              user={reserveInfo.getFirstExistAccountTraveler()}
              allowBlank
              isRequired={reserveInfo.isChargingDepartmentRequired()}
              workflowFormWidth={FORM_WIDTH}
              defaultDepartment={reserveInfo.chargingDepartment}
            />
          </InputAreaRight>
          {projectShareAvailability && (
            <DetailLinkArea>
              <A onClick={onClickShowDetail}>詳細設定</A>
            </DetailLinkArea>
          )}
        </>
      )}
    </>
  );
};

const Label = styled.div`
  font-size: 13px;
  width: 200px;
  line-height: 34px;
`;

const InputAreaRight = styled.div<{ workflowFormWidth?: string }>`
  line-height: 34px;
  display: flex;
  width: ${props => props.workflowFormWidth || '420px'};
`;
const DetailLinkArea = styled.div`
  font-weight: bold;
  margin-top: 5px;
  margin-left: 8px;
`;

const ShareDetailModeLinkArea = styled.div`
  font-weight: bold;
  margin-top: 8px;
  margin-right: 16px;
`;

const ShareDetailModeSelected = styled.p`
  font-weight: bold;
  margin-top: 8px;
  margin-right: 16px;
`;

const ShareDetailArea = styled.div<{ workflowFormWidth?: string }>`
  display: block;
  margin-bottom: 24px;
  width: ${props => props.workflowFormWidth || '420px'};
`;

const ShareDetailFlexArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const MappingDetailLabel = styled.p`
  font-weight: bold;
  margin-top: 4px;
  margin-left: 8px;
`;

const ShareDetailLabel = styled.p`
  font-weight: bold;
  margin-top: 4px;
  margin-left: 8px;
  width: 60px;
`;

const ShareDetailIndentedArea = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  width: ${DETAIL_FORM_WIDTH};
`;

const ShareDetailSelectArea = styled.div`
  width: ${DETAIL_FORM_WIDTH};
`;

const Red = styled.span`
  color: ${props => props.theme.redColor};
`;

export default ChargingDepartmentSection;
