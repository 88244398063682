import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import TextArea from 'react-textarea-autosize';
import { styled } from '@this/constants/themes';
import { Box } from '@material-ui/core';
import { Button } from '@this/components/shared/ui/inputs/button';
import type { ShippingCostArgs } from '@this/domain/shipping_cost';
import ShippingCost from '@this/domain/shipping_cost';
import type SuppliedItem from '@this/domain/supplied_item/supplied_item';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';
import { Link } from '@this/shared/ui/navigations/link';
import Tooltip from '@this/shared/tooltip/tooltip';
import { Fetcher } from '@this/src/util';

interface Response {
  costs: ShippingCostArgs[];
}

type Props = {
  suppliedItems: SuppliedItem[];
};

type SearchCategory = 'default' | 'term';

enum SortOrder {
  ASCENDING,
  DESCENDING
}

const ShippingCostTable = observer(({ suppliedItems }: Props) => {
  const history = useHistory();
  const shippingTypeList = ['通常', 'バイク便'];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchCategory, setSearchCategory] = useState<SearchCategory>('default');
  const [searchType, setSearchType] = useState<string>(utils.getParam('searchType') || 'shippedAt');
  const [searchSuppliedItemId, setSearchSuppliedItemId] = useState<number | 'all'>('all');
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [shippingCost, setShippingCost] = useState<ShippingCost[]>([]);
  const [editingShippingCost, setEditingShippingCost] = useState<ShippingCost | null>(null);
  const [updating, setUpdating] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [from, setFrom] = useState(moment(utils.getParam('from')));
  const [to, setTo] = useState(moment(utils.getParam('to')));

  const [startTimeSortOrder, setStartTimeSortOrder] = useState<SortOrder>(SortOrder.ASCENDING);

  const sortByStartTime = (a: ShippingCost, b: ShippingCost) => {
    if (!a.startTime || !b.startTime) {
      return 1;
    }

    if (startTimeSortOrder === SortOrder.DESCENDING) {
      return b.startTime.unix() - a.startTime.unix();
    }

    return a.startTime.unix() - b.startTime.unix();
  };

  const fetch = async () => {
    setIsLoading(true);
    setFetchError(null);

    try {
      const result = await Fetcher.get<Response>('/arrangement/shipping_costs.json', {
        search_category: searchCategory,
        search_type: searchType,
        search_supplied_item_id: searchSuppliedItemId,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
      });

      const costs = result.costs.map(raw => new ShippingCost(raw)).sort(sortByStartTime);
      setShippingCost(costs);
    } catch {
      setFetchError('エラーが発生しました');
    }

    setIsLoading(false);
  };

  const update = async (cost: ShippingCost) => {
    setUpdating(true);
    setUpdateError(null);

    try {
      await Fetcher.put(`/arrangement/shipping_costs/${cost.id}.json`, cost.submitParams());
      setEditingShippingCost(null);
    } catch {
      setUpdateError('エラーが発生しました');
    }

    setUpdating(false);
  };

  const suppliedItemDescription = (suppliedItemId: number | null) => {
    const item = _.find(suppliedItems, s => s.id === suppliedItemId);
    if (!item) {
      return '';
    }
    return `(${item.supplierCode})${item.supplierName} (${item.code})${item.name}`;
  };

  useEffect(() => {
    history.push(
      `/arrangement/send_list2?search_category=${searchCategory}&search_type=${searchType}&search_supplied_item_id=${searchSuppliedItemId}&from=${from.format(
        'YYYY-MM-DD'
      )}&to=${to.format('YYYY-MM-DD')}&tabType=search`
    );
    fetch();
  }, []);

  useEffect(() => {
    const costs = [...shippingCost].sort(sortByStartTime);
    setShippingCost(costs);
  }, [startTimeSortOrder]);

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          fetch();
        }}
        style={{ display: 'flex', marginBottom: '20px' }}
      >
        <Box>
          <SearchBox>
            <Label>検索方法：</Label>
            <select value={searchCategory} onChange={e => setSearchCategory(e.target.value as SearchCategory)}>
              <option value="default">デフォルト</option>
              <option value="term">期間</option>
            </select>
            <Tooltip place="right" width={460}>
              <p>デフォルト：「到着日が空のもの」＋「到着予定日が画面を開いた日以前のもの」</p>
            </Tooltip>
          </SearchBox>
          {searchCategory === 'term' && (
            <>
              <SearchType>
                <Label>
                  <input
                    type="radio"
                    checked={searchType === 'shippedAt'}
                    onChange={() => setSearchType('shippedAt')}
                  />
                  発送日
                </Label>
                <Label>
                  <input
                    type="radio"
                    checked={searchType === 'expectedArrivedAt'}
                    onChange={() => setSearchType('expectedArrivedAt')}
                  />
                  到着予定日
                </Label>
                <Label>
                  <input
                    type="radio"
                    checked={searchType === 'arrivedAt'}
                    onChange={() => setSearchType('arrivedAt')}
                  />
                  到着日
                </Label>
                <Label>
                  <input
                    type="radio"
                    checked={searchType === 'billedAt'}
                    onChange={() => setSearchType('billedAt')}
                  />
                  請求対象日
                </Label>
              </SearchType>
              <InputArea>
                <DateArea>
                  <DatetimePicker
                    value={from}
                    onChange={value => setFrom(value)}
                    showPast
                    disabledDays={0}
                    border
                  />
                  〜
                  <DatetimePicker value={to} onChange={value => setTo(value)} showPast disabledDays={0} border />
                </DateArea>
              </InputArea>
            </>
          )}
          <SearchBox>
            <Label>仕入商品：</Label>
            <select
              value={searchSuppliedItemId}
              onChange={e => {
                if (e.target.value === 'all') {
                  setSearchSuppliedItemId('all');
                } else {
                  setSearchSuppliedItemId(parseInt(e.target.value, 10));
                }
              }}
            >
              <option value="all">すべて</option>
              {suppliedItems.map(item => (
                <option key={item.id} value={item.id}>
                  {suppliedItemDescription(item.id)}
                </option>
              ))}
            </select>
          </SearchBox>
        </Box>
        <Box>
          <Button type="submit" style={{ marginLeft: '10px' }}>
            検索
          </Button>
        </Box>
      </form>
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <table style={{ fontSize: '12px', margin: 0, tableLayout: 'auto' }}>
          <thead>
            <tr>
              <Th>送付状ID</Th>
              <Th>送付先住所 宛名</Th>
              <Th>送料(税込)</Th>
              <Th>発送日</Th>
              <Th>到着予定日</Th>
              <Th>到着日</Th>
              <Th>請求対象日</Th>
              <Th>追跡番号</Th>
              <Th style={{ width: '60px' }}>TripID</Th>
              <Th>
                旅程出発日
                <a onClick={() => setStartTimeSortOrder(SortOrder.ASCENDING)}>▲</a>
                <a onClick={() => setStartTimeSortOrder(SortOrder.DESCENDING)}>▼</a>
              </Th>
              <Th>卸値</Th>
              <Th>種別</Th>
              <Th>仕入商品</Th>
              <Th>メモ</Th>
              <Th>編集</Th>
            </tr>
          </thead>
          <tbody>
            {shippingCost.map(cost => (
              <tr key={cost.id}>
                <Td>{cost.sendListId}</Td>
                <Td>
                  {cost.address} {cost.addressee}
                </Td>
                <Td>{cost.price}</Td>
                <Td>{cost.shippedAt && cost.shippedAt.format('YYYY-MM-DD')}</Td>
                <Td>{cost.expectedArrivedAt && cost.expectedArrivedAt.format('YYYY-MM-DD')}</Td>
                <Td>{cost.arrivedAt && cost.arrivedAt.format('YYYY-MM-DD')}</Td>
                <Td>{cost.billingTarget && cost.billingTarget.format('YYYY-MM-DD')}</Td>
                <Td>{cost.trackingNumber}</Td>
                <Td>
                  {cost.sendListItems.map(item => (
                    <p key={`p_${item.id}`}>
                      <Link key={item.id} isExternal href={`/arrangement/virtual_counter?trip_id=${item.tripId}`}>
                        {item.tripId}
                      </Link>
                    </p>
                  ))}
                </Td>
                <Td>{cost.startTime && cost.startTime.format('YYYY-MM-DD')}</Td>
                <Td>{cost.originalPrice}</Td>
                <Td>{shippingTypeList[cost.shippingType - 1]}</Td>
                <Td>{suppliedItemDescription(cost.suppliedItemId)}</Td>
                {/*
                <Td>{cost.suppliedItemId}</Td>

                */}
                <Td>
                  {editingShippingCost && editingShippingCost.id === cost.id ? (
                    <TextArea
                      placeholder="メモ"
                      value={cost.memo}
                      minRows={2}
                      onChange={e => {
                        cost.memo = e.target.value;
                      }}
                    />
                  ) : (
                    <p>{cost.memo}</p>
                  )}
                </Td>
                <Td>
                  {editingShippingCost && editingShippingCost.id === cost.id ? (
                    <>
                      <Box style={{ display: 'flex' }}>
                        <Button onClick={() => update(cost)} disabled={updating} style={{ marginRight: '5px' }}>
                          送信
                        </Button>
                        <Button onClick={() => setEditingShippingCost(null)} disabled={updating}>
                          キャンセル
                        </Button>
                      </Box>
                      {updateError && <p className="error">{updateError}</p>}
                    </>
                  ) : (
                    <Button onClick={() => setEditingShippingCost(cost)}>編集</Button>
                  )}
                </Td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {fetchError && <Box color="red">{fetchError}</Box>}
    </>
  );
});

const Th = styled.th<{ minWidth?: string }>`
  padding: 4px;
  min-width: ${props => props.minWidth || '50px'};
  font-weight: normal;
  background: #ccc;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Td = styled.td`
  padding: 4px;
`;

const SearchBox = styled.div`
  display: flex;
  margin-bottom: 5px;

  & > select {
    margin-bottom: 0;
  }
`;

const SearchType = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const Label = styled.label`
  margin-right: 10px;
`;

const InputArea = styled.div`
  margin-bottom: 5px;
`;

const DateArea = styled.div`
  display: flex;
`;

export default ShippingCostTable;
