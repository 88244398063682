import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@this/constants/themes';
import God from '@this/domain/god/god';
import type { GodArgs } from '@this/domain/god/god';
import { Input } from '@this/shared/ui/inputs/input';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Button } from '@this/components/shared/ui/inputs/button';
import { Loading } from '@this/shared/ui/feedbacks/loading/loading';
import { Fetcher, HTTPError } from '@this/src/util';
import { Title } from '../god';

interface GodEditResponse {
  god: GodArgs;
}

const Edit: React.FC = () => {
  const [god, setGod] = useState<God | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const fetchGod = useCallback(async () => {
    setLoading(true);
    await Fetcher.get<GodEditResponse>('/god/edit.json')
      .then(res => {
        setGod(new God(res.god));
      })
      .catch(() => {
        setLoadError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      })
      .finally(() => setLoading(false));
  }, []);

  const submitParams = useCallback(() => {
    return {
      god: {
        god_id: god?.id,
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation
      }
    };
  }, [currentPassword, god, password, passwordConfirmation]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (god === null) return;
      setSubmitting(true);
      setSubmitError(null);
      setSuccess(null);

      await Fetcher.put('/god', submitParams())
        .then(() => {
          setSuccess('パスワードを変更しました');
        })
        .catch(error => {
          if (error instanceof HTTPError && error.response?.status === 400 && error.response.data.errors) {
            setSubmitError(error.response.data.errors.join('\n'));
          } else {
            setSubmitError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [god, submitParams]
  );

  useEffect(() => {
    fetchGod();
  }, [fetchGod]);

  if (loading) return <Loading />;
  if (loadError) return <Error>{loadError}</Error>;

  const placeHolder = '英(大小)数記号を含む8文字以上';
  return (
    <div>
      <Title>パスワードの変更</Title>
      <Content>
        <form id="resetPasswordForm" onSubmit={handleSubmit} autoComplete="on" noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="currentPassword">現在のパスワード</InputLabel>
            <Input
              id="currentPassword"
              autoComplete="on"
              type="password"
              name="currentPassword"
              placeholder={placeHolder}
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="password">新しいパスワード</InputLabel>
            <Input
              id="password"
              autoComplete="on"
              type="password"
              name="password"
              placeholder={placeHolder}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel htmlFor="passwordConfirmation">新しいパスワード　（確認）</InputLabel>
            <Input
              id="passwordConfirmation"
              autoComplete="on"
              type="password"
              name="passwordConfirmation"
              placeholder={placeHolder}
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
          </FormControl>
          {submitError && <Error>{submitError}</Error>}
          {success && <Success>{success}</Success>}
          <SubmitButton type="submit" loading={submitting}>
            保存
          </SubmitButton>
        </form>
      </Content>
    </div>
  );
};

const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Success = styled.div`
  color: ${props => props.theme.successColor};
`;

export default Edit;
