import React, { useState, useEffect } from 'react';
import HeaderPc from '@this/components/lp/simulation/header_pc';
import HeaderSp from '@this/components/lp/simulation/header_sp';
import FooterPc from '@this/components/lp/simulation/footer_pc';
import FooterSp from '@this/components/lp/simulation/footer_sp';
import { OemService } from '@this/domain/oem_service';

const Thanks = () => {
  const [service, setService] = useState<OemService | null>(null);

  useEffect(() => {
    let serviceId = 1;
    // クエリパラメータの値からserviceをstateに設定
    if (window.location.search.indexOf('?') > -1) {
      const param = window.location.search.substring(1).split('&')[0].split('=');
      if (param[0] === 'id') {
        serviceId = parseInt(param[1], 10);
      }
      setService(new OemService({ id: serviceId }));
    }
  }, []);

  return (
    <div className="lp">
      <div className="simulation_pc">
        <HeaderPc />
        <div className="thanks">
          <h2>資料請求が完了しました</h2>
          <div className="message">
            {service?.displayData.name}に資料請求いただきありがとうございます。
            <br />
            内容を確認の上、担当者よりご連絡いたします。
            <br />
            ※3営業日経っても担当者の連絡がない場合、大変お手数ですが {service?.displayData.email}{' '}
            までご連絡くださいますようお願いいたします。
          </div>
        </div>
        <FooterPc />
      </div>
      <div className="simulation_sp">
        <HeaderSp />
        <div className="thanks">
          <h2>資料請求が完了しました</h2>
          <div className="message">{service?.displayData.name}に資料請求いただきありがとうございます。</div>
          <br />
          内容を確認の上、担当者よりご連絡いたします。
          <br />
          ※3営業日経っても担当者の連絡がない場合、大変お手数ですが {service?.displayData.email}{' '}
          までご連絡くださいますようお願いいたします。
        </div>
        <FooterSp />
      </div>
    </div>
  );
};

export default Thanks;
