import React from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';

type Props = {
  className?: string;
  href: string;
  text: string;
  number: number;
};

const HelpItem: React.FC<Props> = ({ className, href, text, number }) => (
  <li className={className}>
    <Number>{`0${number}`.slice(-2)}</Number>
    <A href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </A>
  </li>
);

const Number = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export default HelpItem;
