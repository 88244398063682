import _ from 'lodash';
import type { NonOrderItemMappingArgs } from '@this/domain/non_order_item_mapping';
import NonOrderItemMapping from '@this/domain/non_order_item_mapping';
import Traveler from '@this/domain/traveler/traveler';
import NonOrderItemPrice from './non_order_item_price';
import TransportElement from './transport_element';
import HotelElement from './hotel_element';

interface Args {
  id?: number;
  price?: any;
  paymentType?: string;
  requestType?: string;
  transports?: any;
  hotels?: any;
  elementType: string;
  peopleNum: number;
  supplier: string;
  nonOrderItemMappings?: NonOrderItemMappingArgs[];
  travelerInformations?: any;
  elementTypeForOrganizationTrip?: string;
  tripType?: string;
  tripTypeText?: string;
}

class NonOrderItem {
  id: number | undefined;

  peopleNum: number;

  transports: TransportElement[];

  hotels: HotelElement[];

  elements: (TransportElement | HotelElement)[];

  price: NonOrderItemPrice;

  additionalType: string;

  paymentType: string;

  requestType: string;

  isNeed: boolean;

  supplier: string;

  nonOrderItemMappings: NonOrderItemMapping[] = [];

  travelerInformations?: any;

  elementTypeForOrganizationTrip?: string;

  tripType?: string;

  tripTypeText?: string;

  constructor(args: Args) {
    this.id = args.id;
    this.peopleNum = args.peopleNum;
    this.transports = args.transports
      ? _.map(args.transports, (raw: any) => {
          const peopleNum = raw.peopleNum || this.peopleNum;
          return new TransportElement(_.merge(raw, { peopleNum }));
        })
      : [];
    this.hotels = args.hotels
      ? _.map(args.hotels, (raw: any) => {
          const peopleNum = raw.peopleNum || this.peopleNum;
          return new HotelElement(_.merge(raw, { peopleNum }));
        })
      : [];
    this.elements = [this.transports, this.hotels].flat();
    const elementType = args.elementType;
    this.price = new NonOrderItemPrice(utils.dig(args, 'price') || { price: 0, elementType });
    this.additionalType = 'transport';
    this.paymentType = args.paymentType ? args.paymentType : 'cash';
    this.requestType = args.requestType ? args.requestType : 'simple_request';
    this.isNeed = utils.dig(args, 'isNeed') || true;
    this.supplier = args.supplier;
    this.elementTypeForOrganizationTrip = args.elementTypeForOrganizationTrip;
    this.nonOrderItemMappings = args.nonOrderItemMappings
      ? _.map(args.nonOrderItemMappings, (raw: any) => {
          return new NonOrderItemMapping(raw);
        })
      : [];
    this.travelerInformations = args.travelerInformations
      ? _.map(args.travelerInformations, (raw: any) => {
          return new Traveler(raw);
        })
      : [];
    this.tripType = args.tripType || 'round_trip';
    this.tripTypeText = args.tripTypeText || '';
  }

  startDate() {
    const dates = _.compact(_.map(this.elements, (element: any) => element.startDate()));
    return _.first(_.sortBy(dates, (d: any) => d.toDate()));
  }

  startDateStr() {
    const d = this.startDate();
    return d ? d.format('YYYY-MM-DD') : '';
  }

  // /trips/:id/edit での日時順並び替えのため
  startDateTime() {
    return this.startDate();
  }

  endDate() {
    const dates = _.compact(_.map(this.elements, (element: any) => element.endDate()));
    return _.last(_.sortBy(dates, (d: any) => d.toDate()));
  }

  typeStr() {
    return this.hotels.length > 0 ? 'ホテル' : '経路';
  }

  endDateStr() {
    const d = this.endDate();
    return d ? d.format('YYYY-MM-DD') : '';
  }

  totalPrice() {
    if (this.hotels.length > 0) {
      return this.price.totalPrice() * this.hotels[0].roomNum;
    }
    return this.price.totalPrice() * this.peopleNum;
  }

  paymentTypeStr() {
    switch (this.paymentType) {
      case 'cash':
        return '現金';
      case 'ic':
        return 'IC';
      case 'credit':
        return 'クレジット';
      case 'on_the_company':
        return '会社請求';
      case 'on_me':
        return '個人立替';
      default:
        return 'その他';
    }
  }

  isElementTypes(types: string[]) {
    if (types[0] === 'hotel') {
      return this.hotels.length > 0;
    }
    return this.transports.length > 0 && types.indexOf(this.transports[0].transportType) > -1;
  }

  handleSelectAdditionalType(type: string) {
    this.additionalType = type;
    app.render();
  }

  handleAddElementByType(elementType: string, startTime: string, endTime: string) {
    let newElement: TransportElement | HotelElement | null;
    if (elementType === 'hotel') {
      newElement = new HotelElement({
        peopleNum: this.peopleNum,
        checkinDate: startTime,
        checkoutDate: endTime
      });
      this.hotels.push(newElement);
    } else {
      newElement = new TransportElement({
        peopleNum: this.peopleNum,
        from: { time: startTime },
        to: { time: startTime }
      });
      this.transports.push(newElement);
    }
    this.elements.push(newElement);
    app.render();
  }

  handlePaymentTypeChange(value: string) {
    this.paymentType = value;
    app.render();
  }

  handlePeopleNumChange(value: number) {
    this.peopleNum = value;
    this.elements.forEach(el => {
      el.peopleNum = value;
    });
    app.render();
  }

  handleSupplierChange(value: string) {
    this.supplier = value;
    app.render();
  }

  toggleNeed = () => {
    this.isNeed = !this.isNeed;
    app.render();
  };

  updateParams() {
    // "削除する"が押された状態かつ未保存のものはParamsから省く
    if (_.isEmpty(this.elements) || (!this.id && !this.isNeed)) {
      return null;
    }

    return {
      id: this.id,
      price: this.price.updateParams(),
      elements: _.map(this.elements, (e: any) => e.updateParams()),
      people_num: this.peopleNum,
      payment_type: this.paymentType,
      request_type: this.requestType,
      is_need: this.isNeed,
      supplier: this.supplier,
      trip_type: this.tripType
    };
  }
}

export default NonOrderItem;
