import React from 'react';
import type { ModalProps } from '@this/shared/ui/feedbacks/modal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@this/shared/ui/feedbacks/modal';
import { Button } from '@this/shared/ui/inputs/button';
import { FormHelperText } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import { Box } from '@material-ui/core';
import type { PaymentGatewayUser } from '@this/domain/organization/payment_gateway_user';
import { styled } from '@this/constants/themes';

type Props = {
  paymentGatewayUser: PaymentGatewayUser;
  submitting?: boolean;
  onSubmit: (paymentGatewayUser: PaymentGatewayUser) => void;
  onAbort: () => void;
} & Pick<ModalProps, 'open'>;
export const PaymentEditForm = ({ paymentGatewayUser, submitting, open, onAbort, onSubmit }: Props) => (
  <Modal open={open} onClose={onAbort}>
    <ModalHeader>クレジットカードの編集</ModalHeader>
    <PaymentEditFormContent
      paymentGatewayUser={paymentGatewayUser}
      submitting={submitting}
      onSubmit={onSubmit}
      onAbort={onAbort}
    />
  </Modal>
);

const PaymentEditFormContent = ({ paymentGatewayUser, submitting, onSubmit, onAbort }: Omit<Props, 'open'>) => {
  const [name, setName] = React.useState(paymentGatewayUser.name);
  const [saveError, setSaveError] = React.useState<string | null>(null);

  const handleSubmit = async () => {
    setSaveError('');

    if (!name) {
      setSaveError('名称を入力してください');
      return;
    }

    paymentGatewayUser.name = name;
    onSubmit(paymentGatewayUser);
  };

  return (
    <>
      <ModalBody>
        <EditWrap>
          <EditSection>
            <Error>{saveError}</Error>
            <InputArea>
              <Label>名称</Label>
              <Input value={name} onChange={e => setName(e.target.value)} />
            </InputArea>

            <Box>
              <FormHelperText>※ クレジットカードの番号を変更したい場合は新規登録をしてください</FormHelperText>
            </Box>
          </EditSection>
        </EditWrap>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onAbort}>
          キャンセル
        </Button>
        <Button onClick={handleSubmit} disabled={submitting}>
          保存
        </Button>
      </ModalFooter>
    </>
  );
};

const EditWrap = styled.div`
  display: flex;
  gap: 8px;
`;

const EditSection = styled.div`
  flex: 1 1 auto;

  &:empty {
    display: none;
  }

  & + & {
    max-width: 50%;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const InputArea = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: normal;
`;
