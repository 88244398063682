import moment from 'moment';
import type { Moment } from 'moment';

export interface InvoiceCustomCsvBuildActionArgs {
  id: number;
  status: 'unstarted' | 'started' | 'succeeded' | 'failed';
  finished_at?: string;
  duration: number;
  invoice_custom_csv_id: number;
}

class InvoiceCustomCsvBuildAction {
  id: number;

  status: string;

  finishedAt?: Moment;

  duration: number;

  invoiceCustomCsvId: number;

  constructor(args: InvoiceCustomCsvBuildActionArgs) {
    this.id = args.id;
    this.status = args.status;
    this.finishedAt = args.finished_at ? moment(args.finished_at) : undefined;
    this.duration = args.duration;
    this.invoiceCustomCsvId = args.invoice_custom_csv_id;
  }

  get onGoing() {
    return this.status === 'started' || this.status === 'unstarted';
  }
}

export default InvoiceCustomCsvBuildAction;
