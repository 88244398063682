import React from 'react';

import { styled } from '@this/constants/themes';
import type Trip from '@this/domain/trip/trip';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import { getColor, getSpacing } from '@this/src/components/shared/ui/theme';
import { Collapse } from '@material-ui/core';
import { useBoolean } from '@this/src/components/shared/hooks/use_boolean';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { ApproveItemList } from '@this/components/arrangement/virtual_counter/virtual_counter_aside/approve_item_list';

type Props = {
  trip: Trip;
};

export const OrderInfo = ({ trip }: Props) => {
  const [isOpen, { toggle }] = useBoolean(false);

  return (
    <Root>
      <Trigger onClick={toggle}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        <Text bold>現時点での依頼内容</Text>
      </Trigger>
      <Collapse in={isOpen}>
        <Section>
          <h4>新データ</h4>
          <TextBlock>
            {trip.order.description()}
            {trip?.trip_approve_item?.json && (
              <SectionApproveItem>
                <ApproveItemList
                  label="<カスタム申請項目>"
                  tripId={trip.id}
                  tripApproveItems={trip.trip_approve_item.json}
                />
              </SectionApproveItem>
            )}
          </TextBlock>
        </Section>
        <Section>
          <h4>旧データ</h4>
          <TextBlock>{trip.order.oldDescription()}</TextBlock>
        </Section>
      </Collapse>
    </Root>
  );
};

const Root = styled.div``;

const Trigger = styled.div.attrs({ role: 'button' })`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const Section = styled.section`
  margin-top: ${getSpacing(2)}px;
`;

const TextBlock = styled(Text).attrs({ level: 'caption' })`
  padding: ${getSpacing(1)}px;
  border: 1px solid ${getColor('border', 'divider')};
  background-color: ${getColor('background', 'primary')};
  white-space: pre-wrap;
`;

const SectionApproveItem = styled.div`
  margin-top: 10px;
`;
