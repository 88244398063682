import _ from 'lodash';
import moment from '../../lib/moment';

import FlightSegment from './flight_segment';
import type { Carrier } from './flight_list';

import type { FlightSliceJson } from '../select_repository';

class FlightSlice {
  public readonly id: string;

  public readonly duration: string; // ex: 12時間30分

  public readonly durationRaw: number; // ex: 1230

  public readonly deadline: string | null;

  public readonly timeToLive: string | null;

  readonly ticketing_enable: boolean;

  // private readonly routes: never[];

  // private readonly description: string;

  public readonly segments: FlightSegment[];

  public readonly carriers: Carrier[];

  // _firstSegmentName: unknown;

  // _lastSegmentName: unknown;

  // _firstSegmentFromName: unknown;

  // _lastSegmentToName: unknown;

  private firstSegmentFromDateCache: string | undefined;

  private lastSegmentToDateCache: string | undefined;

  // _firstSegmentFromTime: unknown;

  // _lastSegmentToTime: unknown;

  constructor(args: FlightSliceJson) {
    this.id = args.id;
    this.durationRaw = args.duration_raw;
    this.duration = args.duration;
    this.deadline = args.deadline;
    this.timeToLive = args.time_to_live;
    this.ticketing_enable = args.ticketing_enable;
    // this.routes = args.routes;
    // this.description = args.description;
    this.segments = _.map(args.segments, s => new FlightSegment(s));
    this.carriers = this.segments.map(s => ({ id: s.marketing_carrier, name: s.carrier_name }));
  }

  // firstSegmentName() {
  //   if (!this._firstSegmentName) this._firstSegmentName = utils.dig(_.first(this.segments), 'name');
  //   return this._firstSegmentName;
  // }

  // lastSegmentName() {
  //   if (!this._lastSegmentName) this._lastSegmentName = utils.dig(_.last(this.segments), 'name');
  //   return this._lastSegmentName;
  // }

  // firstSegmentFromName() {
  //   if (!this._firstSegmentFromName)
  //     this._firstSegmentFromName = utils.dig(_.first(this.segments), 'from', 'name');
  //   return this._firstSegmentFromName;
  // }

  // lastSegmentToName() {
  //   if (!this._lastSegmentToName) this._lastSegmentToName = utils.dig(_.last(this.segments), 'to', 'name');
  //   return this._lastSegmentToName;
  // }

  firstSegmentFromDate() {
    if (!this.firstSegmentFromDateCache)
      this.firstSegmentFromDateCache = utils.dig(_.first(this.segments), 'from', 'date');
    return this.firstSegmentFromDateCache;
  }

  lastSegmentToDate() {
    if (!this.lastSegmentToDateCache) this.lastSegmentToDateCache = utils.dig(_.last(this.segments), 'to', 'date');
    return this.lastSegmentToDateCache;
  }

  // firstSegmentFromTime() {
  //   if (!this._firstSegmentFromTime)
  //     this._firstSegmentFromTime = utils.dig(_.first(this.segments), 'from', 'time');
  //   return this._firstSegmentFromTime;
  // }

  // lastSegmentToTime() {
  //   if (!this._lastSegmentToTime) this._lastSegmentToTime = utils.dig(_.last(this.segments), 'to', 'time');
  //   return this._lastSegmentToTime;
  // }

  startDate() {
    const d = this.firstSegmentFromDate();
    if (d) {
      return `${moment().format('YYYY')}/${d}`;
    }
    return null;
  }

  endDate() {
    const d = this.lastSegmentToDate();
    if (d) {
      return `${moment().format('YYYY')}/${d}`;
    }
    return null;
  }

  startDateTime() {
    return moment(utils.dig(_.first(this.segments), 'from', 'datetime'));
  }

  uniqString() {
    return this.segments.map(s => s.name).join(',');
  }
}

export default FlightSlice;
