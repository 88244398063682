import React from 'react';

import TextArea from 'react-textarea-autosize';
import type WifiElement from '../../../../../../domain/wifi_element';
import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: WifiElement;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const WifiElementForm = ({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('provider')}`}>
        プロバイダ
      </label>
      <select value={element.provider} onChange={e => element.handleChangeProvider(e.target.value)}>
        {Object.keys(element.wifiProviderOptions).map(v => (
          <option key={v} value={v}>
            {element.wifiProviderOptions[v]}
          </option>
        ))}
      </select>
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('startedAt')}`}>
        利用開始日時
      </label>
      <DatetimePicker
        value={element.startedAt}
        onChange={time => element.handleStartedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper-item">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('endedAt')}`}>
        利用終了日時
      </label>
      <DatetimePicker
        value={element.endedAt}
        onChange={time => element.handleEndedAtChange(time)}
        disabledDays={0}
        showTime
        showPast
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('area')}`}>
        エリア
        <span className="red">（必須）</span>
      </label>
      <input type="text" value={element.area} onChange={e => element.handleChangeArea(e.target.value)} />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('remark')}`}>
        備考
      </label>
      <TextArea
        type="text"
        value={element.remark}
        onChange={e => element.handleChangeRemark(e.target.value)}
        minRows={2}
      />
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`virtual-counte-price-change-form__label ${classNameForModifiedField('reservationNumber')}`}
      >
        注文番号
      </label>
      <input
        type="text"
        value={element.reservationNumber}
        onChange={e => element.handleReservationNumberChange(e.target.value)}
      />
    </div>
  </>
);
export default WifiElementForm;
