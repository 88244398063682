import React, { useCallback } from 'react';

import MuiButton from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';

import { styled } from '@this/constants/themes';

import type { ChatbotDisplayMode } from '../mode';

interface Props {
  onChange: (mode: ChatbotDisplayMode) => void;
}

export const ChatbotSettingFooter: React.FC<Props> = ({ onChange }) => {
  const handleClick = useCallback(() => {
    onChange('chat');
  }, [onChange]);

  return (
    <ExpandedFooter>
      <Button onClick={handleClick}>チャットに戻る</Button>
    </ExpandedFooter>
  );
};

const ExpandedFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 100%;
  min-height: 57px;
  border-top: 1px solid #ececec;
  position: relative;
`;

const buttonColor = '#E6DFCD';

const Button = styled(MuiButton)`
  color: #3a3a3a;
  background: ${buttonColor};
  padding: 8px 16px;

  &:hover,
  &:focus {
    background: ${darken(buttonColor, 0.1)};
  }

  & .MuiButton-label {
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
    margin-top: 1px;
    margin-bottom: -1px;
  }
`;
