import { Fetcher } from '@this/src/util';
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import ShareholderTicketHistory from '@this/domain/shareholder_ticket_history';
import type { ShareholderTicketHistoryArgs } from '@this/domain/shareholder_ticket_history';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { styled } from '@this/constants/themes';
import { Input } from '@this/shared/ui/inputs/input';
import type { DateRangePickerValue } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import moment from 'moment';
import _ from 'lodash';
import { Box } from '@material-ui/core';

const ENDPOINT = '/arrangement/shareholder_ticket_histories' as const;

interface Response {
  shareholder_ticket_histories: ShareholderTicketHistoryArgs[];
}

const ShareholderTicketHistories = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [shareholderTicketHistories, setShareholderTicketHistories] = useState<ShareholderTicketHistory[]>([]);
  const [createdAtFrom, setCreatedAtFromDate] = React.useState<Date | null>(null);
  const [createdAtTo, setCreatedAtToDate] = React.useState<Date | null>(null);
  const [tripId, setTripId] = useState<number | null>(null);
  const [orderItemId, setOrderItemId] = useState<number | null>(null);
  const [traceId, setTraceId] = useState<number | null>(null);
  const [shareholderTicketId, setShareholderTicketId] = useState<number | null>(null);
  const [shareholderTicketNumber, setShareholderTicketNumber] = useState<string>('');
  const [usedAtFrom, setUsedAtFromDate] = React.useState<Date | null>(null);
  const [usedAtTo, setUsedAtToDate] = React.useState<Date | null>(null);

  const fetchShareholderTicketHistories = useCallback(() => {
    setIsLoading(true);

    const params = {
      created_at_from: createdAtFrom,
      created_at_to: createdAtTo,
      trip_id: tripId,
      order_item_id: orderItemId,
      trace_id: traceId,
      shareholder_ticket_id: shareholderTicketId,
      shareholder_ticket_number: shareholderTicketNumber,
      used_at_from: usedAtFrom,
      used_at_to: usedAtTo
    };

    Fetcher.get<Response>(`${ENDPOINT}.json`, params)
      .then(res => {
        setShareholderTicketHistories(
          res.shareholder_ticket_histories.map(args => new ShareholderTicketHistory(args))
        );
      })
      .catch(error => {
        setErrors(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    createdAtFrom,
    createdAtTo,
    tripId,
    orderItemId,
    traceId,
    shareholderTicketId,
    shareholderTicketNumber,
    usedAtFrom,
    usedAtTo
  ]);

  const setUrlQueryParams = useCallback((queryParams: { name: string; value: string }[]) => {
    const searchParams = new URLSearchParams(window.location.search);
    queryParams.forEach(q => searchParams.set(q.name, q.value));
    window.history.pushState({}, '', `/arrangement/shareholder_tickets?${searchParams.toString()}`);
  }, []);

  const handleRangeChange = React.useCallback((value: DateRangePickerValue | null) => {
    const [newFrom, newTo] = value ?? [];
    setCreatedAtFromDate(newFrom || null);
    setCreatedAtToDate(newTo || null);
  }, []);

  const handleUsedAtRangeChange = React.useCallback((value: DateRangePickerValue | null) => {
    const [newFrom, newTo] = value ?? [];
    setUsedAtFromDate(newFrom || null);
    setUsedAtToDate(newTo || null);
  }, []);

  useEffect(() => {
    fetchShareholderTicketHistories();
  }, [fetchShareholderTicketHistories]);

  useEffect(() => {
    setUrlQueryParams([
      { name: 'tab', value: 'HISTORY' },
      { name: 'createdAtFrom', value: _.isEmpty(createdAtFrom) ? '' : moment(createdAtFrom).format('YYYY-MM-DD') },
      { name: 'createdAtTo', value: _.isEmpty(createdAtTo) ? '' : moment(createdAtTo).format('YYYY-MM-DD') },
      { name: 'trip_id', value: tripId?.toString() || '' },
      { name: 'order_item_id', value: orderItemId?.toString() || '' },
      { name: 'trace_id', value: traceId?.toString() || '' },
      { name: 'shareholder_ticket_id', value: shareholderTicketId?.toString() || '' },
      { name: 'shareholder_ticket_number', value: shareholderTicketNumber },
      { name: 'usedAtFrom', value: _.isEmpty(usedAtFrom) ? '' : moment(usedAtFrom).format('YYYY-MM-DD') },
      { name: 'usedAtTo', value: _.isEmpty(usedAtTo) ? '' : moment(usedAtTo).format('YYYY-MM-DD') }
    ]);
  }, [
    createdAtFrom,
    createdAtTo,
    tripId,
    orderItemId,
    traceId,
    shareholderTicketId,
    shareholderTicketNumber,
    usedAtFrom,
    usedAtTo
  ]);

  return (
    <div>
      <SearchArea>
        <SearchBlock>
          <SearchLabel>履歴作成日</SearchLabel>
          <DateRangePicker value={[createdAtFrom, createdAtTo]} onChange={handleRangeChange} />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>Trip ID</SearchLabel>
          <Input
            value={tripId || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setTripId(isNaN(value) ? null : value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>Oder item ID</SearchLabel>
          <Input
            value={orderItemId || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setOrderItemId(isNaN(value) ? null : value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>Trace ID</SearchLabel>
          <Input
            value={traceId || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setTraceId(isNaN(value) ? null : value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>優待券 ID</SearchLabel>
          <Input
            value={shareholderTicketId || ''}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              setShareholderTicketId(isNaN(value) ? null : value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>優待券番号</SearchLabel>
          <Input
            value={shareholderTicketNumber || ''}
            onChange={e => {
              setShareholderTicketNumber(e.target.value);
            }}
            style={{ width: '80px' }}
          />
        </SearchBlock>
        <SearchBlock>
          <SearchLabel>使用日</SearchLabel>
          <DateRangePicker value={[usedAtFrom, usedAtTo]} onChange={handleUsedAtRangeChange} />
        </SearchBlock>
      </SearchArea>
      {isLoading ? (
        <Loading />
      ) : errors.length > 0 ? (
        <Errors>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </Errors>
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>優待券 ID</TableCell>
              <TableCell nowrap>Trip ID</TableCell>
              <TableCell nowrap>Order item ID</TableCell>
              <TableCell nowrap>Trace ID</TableCell>
              <TableCell nowrap>使用日</TableCell>
              <TableCell>航空会社</TableCell>
              <TableCell>購入価格</TableCell>
              <TableCell nowrap>購入日</TableCell>
              <TableCell nowrap>利用期限</TableCell>
              <TableCell>優待券番号</TableCell>
              <TableCell>パスワード</TableCell>
              <TableCell nowrap>失効日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shareholderTicketHistories.map((history, index) => (
              <TableRow key={index}>
                <TableCell>{history.shareholderTicketId}</TableCell>
                <TableCell>{history.tripId}</TableCell>
                <TableCell>{history.orderItemId}</TableCell>
                <TableCell>{history.traceId}</TableCell>
                <TableCell>{history.usedAt?.format('YYYY-MM-DD')}</TableCell>
                <TableCell>{history.shareholderTicket.companyStr()}</TableCell>
                <TableCell>{history.shareholderTicket.price}</TableCell>
                <TableCell>{history.shareholderTicket.purchasedAt.format('YYYY-MM-DD')}</TableCell>
                <TableCell>{history.shareholderTicket.expireAt.format('YYYY-MM-DD')}</TableCell>
                <TableCell>{history.shareholderTicketNumber}</TableCell>
                <TableCell>{history.shareholderTicket.password}</TableCell>
                <TableCell>{history.shareholderTicket.inactivatedAt?.format('YYYY-MM-DD')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
});

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.redColor};
`;

const SearchArea = styled(Box)`
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  font-size: 12px;
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export default ShareholderTicketHistories;
