import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Divider } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ItemList from '@this/src/domain/approve_item/item_list';
import type { ItemListArgs } from '@this/src/domain/approve_item/item_list';
import {
  OrganizationBody,
  OrganizationTitle,
  OrganizationNavBar,
  OrganizationTable,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTd,
  OrganizationTdButton
} from '@this/components/organization/organization.style';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import { Link } from '@this/src/components/shared/ui/navigations/link';
import { Input } from '@this/src/components/shared/ui/inputs/input';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Modal, ModalHeader, ModalBody } from '@this/src/components/shared/ui/feedbacks/modal';
import { Pagination } from '@this/src/components/shared/ui/navigations/pagination';
import styled from 'styled-components';
import { ButtonType } from '@this/shared/atoms/button';
import { Fetcher, HTTPError } from '@this/src/util';
import ApproveItemListForm from './approve_item_list_form';

type IndexResponse = {
  approve_item_lists: ItemListArgs[];
  current_page: number;
  total_pages: number;
};

const ApproveItemLists = observer(() => {
  const { approveItemId } = useParams<{ approveItemId: string }>();
  const [lists, setLists] = useState<ItemList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchValidity, setSearchValidity] = useState<'valid' | 'invalid'>('valid');
  const [editingList, setEditingList] = useState<ItemList | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchData = useCallback(
    async (searchPage: number = page) => {
      setLoading(true);
      try {
        const params = {
          word: searchWord,
          validity: searchValidity,
          page: searchPage
        };
        const response = await Fetcher.get<IndexResponse>(
          `/organization/approve_items/${approveItemId}/approve_item_lists.json`,
          params
        );
        const itemLists = response.approve_item_lists.map(l => new ItemList(l));
        setLists(itemLists);
        setPage(response.current_page);
        setTotalPages(response.total_pages);
      } catch (e) {
        setError('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
      } finally {
        setLoading(false);
      }
    },
    [approveItemId, searchWord, searchValidity, page]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleClickEdit = (list: ItemList) => {
    const tmpList = _.cloneDeep(list);
    setEditingList(tmpList);
  };

  const handleClickAdd = () => {
    setEditingList(new ItemList({} as any));
  };

  const handleClickDestroy = (list: ItemList) => {
    if (confirm('無効化しますか？')) {
      Fetcher.delete(`/organization/approve_items/${approveItemId}/approve_item_lists/${list.id}`).then(() => {
        fetchData();
      });
    }
  };

  const handleClickEnable = (list: ItemList) => {
    if (confirm('有効化しますか？')) {
      Fetcher.put(`/organization/approve_items/${approveItemId}/approve_item_lists/${list.id}/enable`, {}).then(
        () => {
          fetchData();
        }
      );
    }
  };

  const handleCloseModal = () => {
    setSubmitErrors([]);
    setEditingList(null);
  };

  const handleClickSubmit = () => {
    if (!editingList) return;
    if (editingList.id) {
      updateItemList(editingList);
    } else {
      createItemList(editingList);
    }
  };

  const updateItemList = async (itemList: ItemList) => {
    setSubmitting(true);
    setSubmitErrors([]);
    try {
      await Fetcher.put(
        `/organization/approve_items/${approveItemId}/approve_item_lists/${itemList.id}`,
        itemList.submitParams
      );
      setEditingList(null);
      fetchData();
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
        const errors = e.response.data.errors;
        setSubmitErrors(errors);
      } else {
        setSubmitErrors(['通信環境が不安定です。\n時間をおいてもう一度お試しください。']);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const createItemList = async (itemList: ItemList) => {
    setSubmitting(true);
    setSubmitErrors([]);
    try {
      await Fetcher.post(`/organization/approve_items/${approveItemId}/approve_item_lists`, itemList.submitParams);
      setEditingList(null);
      fetchData();
    } catch (e) {
      if (e instanceof HTTPError && e.response?.status === 400 && e.response.data.errors) {
        const errors = e.response.data.errors;
        setSubmitErrors(errors);
      } else {
        setSubmitErrors(['通信環境が不安定です。\n時間をおいてもう一度お試しください。']);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClickSearch = () => {
    fetchData(1);
  };

  return (
    <>
      <OrganizationNavBar>
        <Link
          href={`/organization/approve_items/${approveItemId}/edit`}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <ChevronLeft />
          戻る
        </Link>
      </OrganizationNavBar>
      <OrganizationTitle>リスト申請項目</OrganizationTitle>
      <OrganizationBody>
        <Wrap>
          <div style={{ marginBottom: '20px' }}>
            <p style={{ fontSize: '1.1em', fontWeight: 'bold' }}>検索条件</p>
            <SearchInputs>
              <Input value={searchWord} onChange={e => setSearchWord(e.target.value)} placeholder="検索ワード" />
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                <Radio
                  name="searchValidity"
                  value="valid"
                  checked={searchValidity === 'valid'}
                  onChange={() => setSearchValidity('valid')}
                >
                  有効
                </Radio>
                <Radio
                  name="searchValidity"
                  value="invalid"
                  checked={searchValidity === 'invalid'}
                  onChange={() => setSearchValidity('invalid')}
                >
                  無効
                </Radio>
              </div>
              <Button onClick={handleClickSearch}>検索</Button>
            </SearchInputs>
          </div>
          <Divider />
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '20px 0' }}
          >
            <Button color="sub" onClick={handleClickAdd} disabled={!!editingList}>
              <span style={{ marginRight: '5px' }}>項目を追加</span>
              <AddCircleOutlineIcon />
            </Button>
            <Button
              color="sub"
              href={`/organization/approve_items/${approveItemId}/approve_item_lists/bulk_upsert`}
              disabled={!!editingList}
            >
              リスト項目一括追加・更新
            </Button>
          </div>
          <OrganizationTable>
            <thead>
              <tr>
                <OrganizationTh>項目コード</OrganizationTh>
                <OrganizationTh>項目名</OrganizationTh>
                <OrganizationButtonTh>編集</OrganizationButtonTh>
                <OrganizationButtonTh>無効化/有効化</OrganizationButtonTh>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3}>
                    <Loading />
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan={3}>
                    <div className="error">{error}</div>
                  </td>
                </tr>
              ) : (
                lists.map(list => (
                  <tr key={list.id}>
                    <OrganizationTd>{list.code}</OrganizationTd>
                    <OrganizationTd>{list.name}</OrganizationTd>
                    <OrganizationTd>
                      {!list.deletedAt && (
                        <OrganizationTdButton onClick={e => handleClickEdit(list)}>編集</OrganizationTdButton>
                      )}
                    </OrganizationTd>
                    <OrganizationTd>
                      {list.deletedAt ? (
                        <OrganizationTdButton onClick={() => handleClickEnable(list)}>有効化</OrganizationTdButton>
                      ) : (
                        <OrganizationTdButton
                          buttonType={ButtonType.DANGER}
                          onClick={() => {
                            handleClickDestroy(list);
                          }}
                        >
                          無効化
                        </OrganizationTdButton>
                      )}
                    </OrganizationTd>
                  </tr>
                ))
              )}
            </tbody>
          </OrganizationTable>
          <Pagination
            page={page}
            count={totalPages}
            onChange={(e, p) => {
              fetchData(p);
            }}
          />
        </Wrap>
        {editingList && (
          <Modal open={!!editingList} onClose={handleCloseModal}>
            <ModalHeader>{editingList.id ? '項目を変更する' : '項目を追加する'}</ModalHeader>
            <ModalBody>
              <ApproveItemListForm approveItemList={editingList} />
              {!!submitErrors.length && (
                <div className="error">
                  {submitErrors.map(e => (
                    <p key={e}>{e}</p>
                  ))}
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '5px' }}>
                <Button color="sub" onClick={handleCloseModal} disabled={submitting}>
                  キャンセル
                </Button>
                <Button color="primary" onClick={handleClickSubmit} disabled={submitting}>
                  保存
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </OrganizationBody>
    </>
  );
});

const Wrap = styled.div`
  padding: 10px;
`;

const SearchInputs = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
export default ApproveItemLists;
