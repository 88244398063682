import { observable } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';

export type PermissionType = 'administrator' | 'analyst';

export interface GodArgs {
  id?: number;
  email?: string;
  last_name?: string;
  first_name?: string;
  permission: PermissionType;
  deleted_at?: string | null;
}

export const initializeGod = {
  id: undefined,
  email: '',
  last_name: '',
  first_name: '',
  permission: 'analyst' as PermissionType,
  deleted_at: null
};

class God {
  @observable
  id?: number;

  @observable
  email: string;

  @observable
  lastName: string;

  @observable
  firstName: string;

  @observable
  permission: PermissionType;

  @observable
  deletedAt: Moment | null;

  constructor(args: GodArgs) {
    this.id = args.id;
    this.email = args.email || '';
    this.lastName = args.last_name || '';
    this.firstName = args.first_name || '';
    this.permission = args.permission;
    this.deletedAt = args.deleted_at ? moment(args.deleted_at) : null;
  }

  submitParams() {
    return {
      email: this.email,
      last_name: this.lastName,
      first_name: this.firstName,
      permission: this.permission
    };
  }
}

export default God;
