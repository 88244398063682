/* eslint-disable max-lines */
import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import A from '@this/shared/atoms/a';
import Link from '@this/shared/atoms/link';
import { ButtonBase, BorderButtonBase } from '@this/shared/atoms/button';
import type { OrganizationRoleArgs } from '@this/domain/organization_role/organization_role';
import OrganizationRole from '@this/domain/organization_role/organization_role';
import type { Moment } from 'moment';
import type { OrganizationArgs } from '@this/domain/organization/organization2';
import {
  OrganizationTable,
  OrganizationBiztraTr,
  OrganizationBiztraTd,
  OrganizationBiztraTh,
  OrganizationBiztraButtonTh
} from '@this/components/expenses/organization/organization.style';
import type { MembersFilterFormData } from '@this/components/organization/members/types';
import type UserJson from '@this/domain/user/user_json';
import MembersFilter from '@this/components/expenses/organization/members_filter';
import Tooltip from '@this/shared/tooltip/tooltip';
import type OrganizationMemberJson from '@this/domain/user/organization_member_json';
import { AccountStatus } from '@this/domain/user/organization_member_json';
import User from '@this/domain/user/user';
import RemittanceDestinationAccountForm from '@this/components/expenses/organization/remittance_destination_account_form';
import moment from 'moment-timezone';
import DepartmentList from '@this/domain/department/department_list';
import EkispertInput from '@this/shared/autocompletable_input/ekispert_input';
import FlashMessage from '@this/shared/flash_message/flash_message';
import MainTitle from '@this/shared/organization/main_title/main_title';
import { RadioField } from '@this/shared/form_elements/form_elements';
import type { EkispertSort } from '@this/domain/teiki';
import { BiztraModal, BiztraModalHeader, BiztraModalBody } from '@this/components/expenses/ui/feedbacks/modal';
import DateSelector from '@this/shared/date_selector/date_selector';
import Modal from '../../shared/modal/modal';
import { ContentBody } from './organization';
import { ActionWrapper, BaseButtonCss, CancelButtonCss } from '../approvals/reject';

interface Props {}

interface State {
  members: User[];
  memberIds: { withApprover: number[]; withoutApprover: number[]; withoutDepartment: number[] };
  departments: any;
  saving: boolean;
  organization: any;
  currentUser: any | null;
  route: string;
  inviteEmail: string;
  filterFormData: MembersFilterFormData;
  inviteOrganizationRole: number;
  organizationRoles: OrganizationRole[];
  inviteErrors: string[] | null;
  inviteSuccessMessage: string | null;
  loading: boolean;
  editingUser: any;
  editingAllApproval: { type: 'domestic' | 'foreign' | null; val: boolean | null };
  saveError: string | null;
  saveModalErrors: string[] | null;
  approvalErrors: { domestic: string | null; foreign: string | null };
  zenginAvailable: boolean;
  showEditModal: boolean;
}

interface FetchMembersResponse {
  current_user: UserJson;
  members: OrganizationMemberJson[];
  roles: OrganizationRoleArgs[];
  organization: OrganizationArgs;
  zengin_available: boolean;
}

interface EkispertCourse {
  price: number;
  route: string;
}

interface EkispertPriceResponse {
  courses: EkispertCourse[];
}

type ApprovalApiKey = 'domestic_approval_required' | 'foreign_approval_required';

class Members extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      members: [],
      memberIds: { withApprover: [], withoutApprover: [], withoutDepartment: [] },
      departments: new DepartmentList([], []),
      saving: false,
      organization: {},
      currentUser: null,
      route: '',
      inviteEmail: '',
      filterFormData: {
        text: '',
        accountStatus: AccountStatus.ENABLED,
        pendingAccountStatus: AccountStatus.ENABLED,
        departmentText: '',
        roleId: 0,
        gradeId: 0,
        sortTypeText: 'CREATED_AT_ASC'
      },
      inviteOrganizationRole: 0,
      organizationRoles: [],
      inviteErrors: null,
      inviteSuccessMessage: null,
      loading: false,
      editingUser: null,
      editingAllApproval: { type: null, val: null },
      saveError: null,
      saveModalErrors: null,
      approvalErrors: { domestic: null, foreign: null },
      zenginAvailable: false,
      showEditModal: false
    };
    this.handleFlashMessageClose = this.handleFlashMessageClose.bind(this);
    this.handleInvitationSubmit = this.handleInvitationSubmit.bind(this);
    this.handleInviteEmailChange = this.handleInviteEmailChange.bind(this);
    this.handleInviteRoleChange = this.handleInviteRoleChange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEditFormSubmit = this.handleEditFormSubmit.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleGradeIdChange = this.handleGradeIdChange.bind(this);
    this.handleAddMileageNumber = this.handleAddMileageNumber.bind(this);
    this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
    this.handleRoleIdChange = this.handleRoleIdChange.bind(this);
    this.handleTeikiSectionChange = this.handleTeikiSectionChange.bind(this);
    this.handleAddTeiki = this.handleAddTeiki.bind(this);
    this.handleRemoveTeiki = this.handleRemoveTeiki.bind(this);
  }

  componentDidMount(): void {
    this.fetchMembers();
    const path = '/biztra/organization/departments.json';
    utils.jsonPromise<any>(path).then(result => {
      this.setState({
        departments: new DepartmentList(result.departments, [])
      });
    });
  }

  fetchMembers(): void {
    this.setState({
      loading: true
    });
    const path = '/biztra/organization/members.json';

    utils.jsonPromise<FetchMembersResponse>(path).then(result => {
      let inviteEmail;
      if (this.state.inviteEmail && this.state.inviteEmail.length > 0) {
        inviteEmail = this.state.inviteEmail;
      } else if (result.organization.domain) {
        inviteEmail = `@${String(result.organization.domain)}`;
      } else {
        inviteEmail = '';
      }

      const zenginAvailable = result.zengin_available;
      const members = _.map(result.members, u => new User(_.merge(u, { needAccount: zenginAvailable })));
      const roles = result ? result.roles.map(args => new OrganizationRole(args)) : [];

      this.setState({
        members,
        organization: result.organization,
        organizationRoles: roles,
        inviteOrganizationRole: roles[0].id,
        currentUser: new User(result.current_user),
        inviteEmail,
        zenginAvailable,
        loading: false
      });
    });
  }

  handleFilterchange = (formData: MembersFilterFormData) => {
    this.setState({ filterFormData: formData });
  };

  handleEditClick(user: any) {
    this.setState(
      {
        editingUser: _.cloneDeep(user),
        saveModalErrors: null,
        showEditModal: true
      },
      () => this.handleFetchRoute()
    );
  }

  handleInviteEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({
      inviteEmail: e.currentTarget.value
    });
  }

  handleInviteRoleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    e.preventDefault();
    this.setState({
      inviteOrganizationRole: parseInt(e.currentTarget.value, 10)
    });
  }

  sendInvitation(email: string, roleId: number) {
    const params = {
      user: {
        email,
        organization_role_id: this.state.inviteOrganizationRole
      },
      orientation_expenses: true
    };

    this.setState(
      {
        inviteErrors: null,
        inviteSuccessMessage: null
      },
      () => {
        const path = '/biztra/users/invitation.json';
        utils
          .jsonPromise(path, params, 'POST')
          .then(
            () => {
              this.setState({
                inviteEmail: `@${this.state.organization.domain}`,
                inviteSuccessMessage: `${email}に招待メールを送信しました`
              });
              this.fetchMembers();
            },
            e => {
              const inviteErrors =
                e.status === 400
                  ? utils.dig(e, 'responseJSON', 'errors')
                  : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];
              this.setState({
                inviteErrors
              });
            }
          )
          .catch(e => utils.sendErrorObject(e));
      }
    );
  }

  handleInvitationSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.sendInvitation(this.state.inviteEmail, this.state.inviteOrganizationRole);
  }

  handleResendInvitationClick(member: any, e: React.MouseEvent) {
    e.preventDefault();
    this.sendInvitation(member.email, member.role_id);
  }

  async handleEditFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveModalErrors: null
    });

    const path = `/biztra/organization/members/${this.state.editingUser.id}`;
    return utils
      .jsonPromise(path, this.state.editingUser.updateParams(), 'PUT')
      .then(
        () => {
          this.setState({
            editingUser: null,
            saving: false
          });
          this.fetchMembers();
        },
        error => {
          const state = { saving: false, saveModalErrors: null };

          state.saveModalErrors =
            error.status === 400
              ? utils.dig(error, 'responseJSON', 'errors')
              : ['通信環境が不安定です。\n時間をおいてもう一度お試しください。'];

          return this.setState(state);
        }
      )
      .catch(e => utils.sendErrorObject(e));
  }

  // 使われていない?
  handleAllApprovalClick(type: 'domestic' | 'foreign', val: boolean, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const withApprover: number[] = [];
    const withoutApprover: number[] = [];
    const withoutDepartment: number[] = [];
    if (type !== null && val === true) {
      this.getFilteredMember().forEach(m => {
        if (!m.department) {
          withoutDepartment.push(m.id);
        } else if (m.department.approvers.length === 0) {
          withoutApprover.push(m.id);
        } else {
          withApprover.push(m.id);
        }
      });
    }
    this.setState({
      memberIds: { withApprover, withoutApprover, withoutDepartment },
      editingAllApproval: { type, val }
    });
  }

  updateAllApprovalConfirmText() {
    const approval = this.state.editingAllApproval;
    if (approval.type === null || approval.val === null) {
      return '';
    }
    const typeTxt = approval.type === 'domestic' ? '国内' : '海外';
    const valTxt = approval.val ? '必要' : '不要';
    return `本当に${typeTxt}承認${valTxt}に一括変更してもよろしいですか？`;
  }

  executeUpdateAllApproval(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({
      saving: true
    });
    const approval = this.state.editingAllApproval;
    const filteredMembers = this.getFilteredMember();
    if (approval.type !== null && approval.val !== null) {
      const userIds: number[] = approval.val
        ? this.state.memberIds.withApprover
        : filteredMembers.map(m => {
            return m.id;
          });
      let params: { [key in ApprovalApiKey]?: boolean };
      let apiParams: { [key in ApprovalApiKey]?: 1 | 0 };
      if (approval.type === 'domestic') {
        params = { domestic_approval_required: approval.val };
        apiParams = { domestic_approval_required: approval.val ? 1 : 0 };
      } else {
        params = { foreign_approval_required: approval.val };
        apiParams = { foreign_approval_required: approval.val ? 1 : 0 };
      }
      _.extend(
        apiParams,
        _.transform(userIds, (r: any, v: number, k: number) => {
          r[`user_id[${k}]`] = v;
        })
      );
      utils
        .jsonPromise(`/organization/member/approval/${this.state.organization.id}`, apiParams, 'POST')
        .then(
          () => {
            filteredMembers.forEach(m => {
              if (userIds.indexOf(m.id) !== -1) {
                _.assign(m, params);
              }
            });

            this.setState({
              saving: false,
              editingAllApproval: { type: null, val: null }
            });
            return this.fetchMembers();
          },
          error => {
            const state = {
              saving: false,
              saveError: '',
              editingAllApproval: { type: null, val: null }
            };
            state.saveError =
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
            return this.setState(state);
          }
        )
        .catch(e => utils.sendErrorObject(e));
    }
  }

  handleTeikiSectionChange(name: string, e: React.ChangeEvent<HTMLInputElement>) {
    if (name === 'from') {
      this.state.editingUser.teiki.setFrom(e.target.value);
    } else {
      this.state.editingUser.teiki.setTo(e.target.value);
    }
  }

  private handleChangeRoute = (name: string, value: string, _address: string) => {
    if (name === 'origin') {
      this.state.editingUser.teiki.setFrom(value);
    } else {
      this.state.editingUser.teiki.setTo(value);
    }
  };

  private handleChangeEkispertSort = (value: EkispertSort) => {
    this.state.editingUser.teiki.setEkispertSort(value);
    this.handleFetchRoute();
  };

  handleAddTeiki() {
    this.state.editingUser.addTeiki();
  }

  handleRemoveTeiki() {
    this.state.editingUser.removeTeiki();
    this.setState({ route: '' });
  }

  async updateAllApproval(approvalApiParam: { [k in ApprovalApiKey]?: number }) {
    return utils
      .jsonPromise(`/organization/member/approval/${this.state.organization.id}`, approvalApiParam, 'POST')
      .then(
        () => {
          this.setState({
            saving: false
          });
          return this.fetchMembers();
        },
        error => {
          const state = { saving: false, saveError: '' };
          state.saveError =
            error.status === 400
              ? utils.dig(error, 'responseJSON', 'errors')
              : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          return this.setState(state);
        }
      )
      .catch(e => utils.sendErrorObject(e));
  }

  handleAllApprovalsCancelClick = () => {
    return this.setState({ editingAllApproval: { type: null, val: null } });
  };

  handleCancelClick() {
    return this.setState({ editingUser: null, route: '', showEditModal: false });
  }

  handleFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    return this.state.editingUser[name](e.currentTarget.value);
  }

  handleApprovalChange(type: 'domestic' | 'foreign', e: React.ChangeEvent<HTMLInputElement>) {
    const department = this.state.editingUser.department;
    const approvers = utils.dig(department, 'approvers') || [];
    const organization = this.state.organization;
    const errors = this.state.approvalErrors;
    if (
      e.currentTarget.value === 'false' ||
      (department && approvers.length > 0) ||
      organization?.freee_workflow
    ) {
      const action = type === 'domestic' ? 'setDomesticApprovalRequired' : 'setForeignApprovalRequired';
      errors[type] = null;
      this.state.editingUser[action](e.currentTarget.value);
    } else if (!department) {
      errors[type] = '部署を設定してください。';
    } else if (organization && !organization.freee_workflow)
      errors[type] = `${department.name}に承認者を設定してください`;
    this.setState({ approvalErrors: errors });
  }

  handleBirthdayChange(birthdayMoment: Moment) {
    return this.state.editingUser.setBirthday(birthdayMoment.format('YYYY-MM-DD'));
  }

  handleRoleIdChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    return this.state.editingUser.setOrganizationRoleId(parseInt(e.currentTarget.value, 10));
  }

  handleDepartmentChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const d = this.state.departments.find(e.currentTarget.value);
    return this.state.editingUser.setDepartment(d);
  }

  handleGradeIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    return this.state.editingUser.setGradeId(parseInt(e.currentTarget.value, 10));
  }

  handleFlashMessageClose() {
    return this.setState({ saveError: null });
  }

  handleMileageCarrierChange(index: number, e: React.ChangeEvent<HTMLSelectElement>) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers[index].carrier = e.currentTarget.value;
    this.setState({ editingUser });
  }

  handleMileageNumberChange(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers[index].number = e.currentTarget.value;
    this.setState({ editingUser });
  }

  handleRemoveMileageNumber(index: number) {
    const editingUser = this.state.editingUser;
    editingUser.mileageNumbers = this.state.editingUser.mileageNumbers.filter((_m: any, i: number) => i !== index);
    this.setState({ editingUser });
  }

  handleAddMileageNumber() {
    this.state.editingUser.mileageNumbers.push({ carrier: 'ANA', number: '' });
    return app.render();
  }

  private getFilteredMember(): User[] {
    const { text, accountStatus } = this.state.filterFormData;
    return this.state.members.reduce((acc: User[], u: User) => {
      if (u.account_status === accountStatus && Members.isMatchMemberByText(u, text)) {
        acc.push(u);
      }
      return acc;
    }, []);
  }

  private static isMatchMemberByText(u: User, text?: string): boolean {
    if (!text) {
      return true;
    }

    const values: string[] = [
      u.name,
      u.nameRoman(),
      u.nameKana(),
      u.email,
      (u.employee_number == null ? '' : u.employee_number).toString(),
      u.departmentName() || ''
    ];
    return values.some(v => v.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0);
  }

  private handleFetchRoute = async () => {
    const editingUser = this.state.editingUser;
    if (this.state.editingUser.teiki) {
      const sort = this.state.editingUser.teiki.ekispertSort;
      if (editingUser.teiki.from && editingUser.teiki.to) {
        const params = {
          trip_type: 'one_way',
          sort,
          from: editingUser.teiki.from,
          to: editingUser.teiki.to
        };
        const q = utils.toParams(params);
        const res = await utils.jsonPromise<EkispertPriceResponse>(`/biztra/ekispert/route?${q}`);
        const course = res.courses[0];
        if (course) {
          this.setState({ route: course.route });
        }
      }
    }
  };

  getOrganizationRoleName(organization_role_id: number) {
    const result = this.state.organizationRoles.find(item => {
      return item.id === organization_role_id;
    });
    return result ? result.name : '';
  }

  render() {
    const orderOptions: { [key: string]: string } = {
      price: '価格優先',
      time: '速さ優先',
      transfer: '乗換回数優先'
    };

    const {
      organization,
      memberIds,
      inviteEmail,
      filterFormData,
      editingUser,
      currentUser,
      editingAllApproval,
      organizationRoles,
      showEditModal
    } = this.state;
    const filteredMembers = this.getFilteredMember();
    return (
      <>
        <FlashMessage
          message={this.state.saveError}
          type="error"
          handleFlashMessageClose={this.handleFlashMessageClose}
        />
        <MainTitle value="社員マスタ" />
        <ContentBody>
          <OrganizationMembersInviteArea>
            <OrganizationMembersInviteForm onSubmit={this.handleInvitationSubmit}>
              <OrganizationMembersInviteLabel>招待するメールアドレス</OrganizationMembersInviteLabel>
              <OrganizationMembersInviteField
                type="text"
                name="inviteEmail"
                value={inviteEmail}
                placeholder="user@example.jp"
                onChange={this.handleInviteEmailChange}
              />
              <OrganizationMembersInviteSelect onChange={this.handleInviteRoleChange}>
                {organizationRoles.map(role => {
                  return (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  );
                })}
              </OrganizationMembersInviteSelect>
              <OrganizationMembersInviteButtonWrap>
                <OrganizationMembersInviteButton type="submit" value="招待" />
              </OrganizationMembersInviteButtonWrap>
            </OrganizationMembersInviteForm>
            <OrganizationMembersCsvLinkArea>
              <span>または</span>
              <Link to="/biztra/organization/members/csv/bulk_upsert">一括登録・更新</Link>
            </OrganizationMembersCsvLinkArea>
          </OrganizationMembersInviteArea>
          {this.state.inviteSuccessMessage && (
            <InviteSuccessMessage>{this.state.inviteSuccessMessage}</InviteSuccessMessage>
          )}
          <OrganizationMembersInviteErrors>
            {this.state.inviteErrors &&
              this.state.inviteErrors.map((e, i) => (
                <div className="red" key={i}>
                  {e}
                </div>
              ))}
          </OrganizationMembersInviteErrors>

          <MembersFilter formData={filterFormData} onChange={this.handleFilterchange} />

          <OrganizationTable>
            <tbody>
              <tr>
                <OrganizationBiztraTh>社員番号</OrganizationBiztraTh>
                <OrganizationBiztraTh>氏名</OrganizationBiztraTh>
                <OrganizationBiztraTh>カナ</OrganizationBiztraTh>
                <OrganizationBiztraTh>ローマ字</OrganizationBiztraTh>
                <OrganizationBiztraTh>性別</OrganizationBiztraTh>
                <OrganizationBiztraTh>誕生日</OrganizationBiztraTh>
                <OrganizationBiztraTh>メール</OrganizationBiztraTh>
                <OrganizationBiztraTh>電話</OrganizationBiztraTh>
                <OrganizationBiztraTh>部署</OrganizationBiztraTh>
                <OrganizationBiztraTh>雇用形態</OrganizationBiztraTh>
                <OrganizationBiztraTh>役割</OrganizationBiztraTh>
                {this.state.organization && this.state.organization.sso_enabled && (
                  <OrganizationBiztraTh>ログイン方法</OrganizationBiztraTh>
                )}
                <OrganizationBiztraTh>招待</OrganizationBiztraTh>
                <OrganizationBiztraButtonTh>編集</OrganizationBiztraButtonTh>
              </tr>
              {this.state.loading ? (
                <tr>
                  <td colSpan={8}>
                    <img className="select__loading" src="/images/loading.gif" width={50} height={50} />
                  </td>
                </tr>
              ) : (
                <>
                  {filteredMembers.map((m, i) => {
                    const organizationTdClass = m.disabled ? 'organization__td-disabled' : 'organization__td';
                    let gender = '';
                    if (m.gender === 'male') {
                      gender = '男性';
                    } else if (m.gender === 'female') {
                      gender = '女性';
                    }
                    return (
                      <OrganizationBiztraTr key={i} className={i % 2 === 1 ? 'even' : 'odd'}>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.employee_number}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.name}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.nameKana()}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.nameRoman()}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{gender}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.birthday}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.email}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>{m.tel}</OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.departmentName()}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.employment_status}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {this.getOrganizationRoleName(m.organizationRoleId)}
                        </OrganizationMemberTd>
                        {this.state.organization && this.state.organization.sso_enabled && (
                          <OrganizationMemberTd className={organizationTdClass}>
                            {m.signInMethod === 'sso' ? 'SSO' : 'パスワード'}
                          </OrganizationMemberTd>
                        )}
                        <OrganizationMemberTd className={organizationTdClass}>
                          {m.account_status === AccountStatus.ENABLED ||
                          m.account_status === AccountStatus.DISABLED ? (
                            <span>登録済み</span>
                          ) : m.account_status === AccountStatus.INVITED ? (
                            <span>
                              招待中(
                              <A onClick={e => this.handleResendInvitationClick(m, e)}>再送</A>)
                              <InvitedAtTooltipWrapper>
                                <Tooltip>
                                  前回招待日時: {moment(m.invitation_sent_at).format('YYYY/MM/DD HH:mm')}
                                </Tooltip>
                              </InvitedAtTooltipWrapper>
                            </span>
                          ) : m.account_status === AccountStatus.UNINVITE ? (
                            <span>
                              招待前(
                              <a href="#" onClick={e => this.handleResendInvitationClick(m, e)}>
                                招待
                              </a>
                              )
                            </span>
                          ) : (
                            ''
                          )}
                        </OrganizationMemberTd>
                        <OrganizationMemberTd className={organizationTdClass}>
                          {!editingUser && (
                            <OrganizationEditButton
                              type="submit"
                              value="編集"
                              onClick={() => this.handleEditClick(m)}
                            />
                          )}
                        </OrganizationMemberTd>
                      </OrganizationBiztraTr>
                    );
                  })}
                </>
              )}
            </tbody>
          </OrganizationTable>
          {(editingAllApproval.type !== null || editingAllApproval.val !== null) && (
            <Modal hideModal={this.handleAllApprovalsCancelClick} show title="承認設定の一括編集">
              <AllApprovalUpdate>
                {editingAllApproval.val && memberIds.withApprover.length === 0 ? (
                  <AllApprovalUpdateConfirm>
                    変更が適用される社員がいないため承認設定の一括編集は実行できません。
                  </AllApprovalUpdateConfirm>
                ) : (
                  <AllApprovalUpdateConfirm>{this.updateAllApprovalConfirmText()}</AllApprovalUpdateConfirm>
                )}
                {editingAllApproval.val && (
                  <>
                    <AllApprovalUpdateConfirm>変更範囲は以下となります。</AllApprovalUpdateConfirm>
                    <AllApprovalUpdateConfirm>
                      <p>
                        {`
                          ・承認必要に変更可（すでに必要と設定済の社員含む）：
                          ${memberIds.withApprover.length}人
                        `}
                      </p>
                      <p>
                        {`
                          ・承認設定変更不可（部署が未設定）：
                          ${memberIds.withoutDepartment.length}人
                        `}
                      </p>
                      <p>
                        {`
                          ・承認設定変更不可（部署の承認者が未設定）：
                          ${memberIds.withoutApprover.length}人
                        `}
                      </p>
                    </AllApprovalUpdateConfirm>
                  </>
                )}
              </AllApprovalUpdate>
              <MemberEditModalButton>
                <EditModalCancelButtonButton
                  disabled={this.state.saving}
                  onClick={() => this.handleAllApprovalsCancelClick()}
                >
                  キャンセル
                </EditModalCancelButtonButton>
                {(!editingAllApproval.val || memberIds.withApprover.length > 0) && (
                  <EditModalSubmitButtonButton
                    disabled={this.state.saving}
                    onClick={e => this.executeUpdateAllApproval(e)}
                  >
                    はい
                  </EditModalSubmitButtonButton>
                )}
              </MemberEditModalButton>
            </Modal>
          )}
          {editingUser && (
            <BiztraModal size="large" onClose={() => this.handleCancelClick()} open={showEditModal}>
              <BiztraModalHeader>社員情報を変更する</BiztraModalHeader>
              <BiztraModalBody>
                <form onSubmit={this.handleEditFormSubmit}>
                  <MemberEdit>
                    <MemberEditSection>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="employeeNumber">社員番号</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="employeeNumber"
                          type="text"
                          value={_.isEmpty(editingUser.employee_number) ? '' : editingUser.employee_number}
                          onChange={e => this.handleFieldChange('setEmployeeNumber', e)}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="lastName">氏名(姓 名)</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="lastName"
                          type="text"
                          placeholder="氏"
                          value={_.isEmpty(editingUser.last_name) ? '' : editingUser.last_name}
                          onChange={e => this.handleFieldChange('setLastName', e)}
                        />
                        <ModalInput
                          className="member-edit__input"
                          id="firstName"
                          type="text"
                          placeholder="名"
                          value={_.isEmpty(editingUser.first_name) ? '' : editingUser.first_name}
                          onChange={e => this.handleFieldChange('setFirstName', e)}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="firstNameKana">氏名(カナ)</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="lastNameKana"
                          type="text"
                          placeholder="氏 カナ"
                          value={_.isEmpty(editingUser.last_name_kana) ? '' : editingUser.last_name_kana}
                          onChange={e => this.handleFieldChange('setLastNameKana', e)}
                        />
                        <ModalInput
                          className="member-edit__input"
                          id="firstNameKana"
                          type="text"
                          placeholder="名 カナ"
                          value={_.isEmpty(editingUser.first_name_kana) ? '' : editingUser.first_name_kana}
                          onChange={e => this.handleFieldChange('setFirstNameKana', e)}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="firstNameRoman">氏名(ローマ字)</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="lastNameRoman"
                          type="text"
                          placeholder="Last Name"
                          value={_.isEmpty(editingUser.last_name_roman) ? '' : editingUser.last_name_roman}
                          onChange={e => this.handleFieldChange('setLastNameRoman', e)}
                        />
                        <ModalInput
                          className="member-edit__input"
                          id="firstNameRoman"
                          type="text"
                          placeholder="First Name"
                          value={_.isEmpty(editingUser.first_name_roman) ? '' : editingUser.first_name_roman}
                          onChange={e => this.handleFieldChange('setFirstNameRoman', e)}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel>性別</ModalInputLabel>
                        <Flex>
                          <MemberEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="male"
                              checked={editingUser.gender === 'male'}
                              onChange={e => this.handleFieldChange('setGender', e)}
                            />
                            <span>男</span>
                          </MemberEditRadioValue>
                          <MemberEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="female"
                              checked={editingUser.gender === 'female'}
                              onChange={e => this.handleFieldChange('setGender', e)}
                            />
                            <span>女</span>
                          </MemberEditRadioValue>
                        </Flex>
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="birthday">誕生日</ModalInputLabel>
                        <DateSelector
                          onDateChange={this.handleBirthdayChange}
                          date={editingUser.birthday ? moment(editingUser.birthday) : moment()}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel>ログイン方法</ModalInputLabel>
                        <Flex>
                          <MemberEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="sso"
                              checked={editingUser.signInMethod === 'sso'}
                              onChange={e => this.handleFieldChange('setSignInMethod', e)}
                            />
                            <span>SSO</span>
                          </MemberEditRadioValue>
                          <MemberEditRadioValue>
                            <ModalInput
                              type="radio"
                              value="password"
                              checked={editingUser.signInMethod === 'password'}
                              onChange={e => this.handleFieldChange('setSignInMethod', e)}
                            />
                            <span>パスワード</span>
                          </MemberEditRadioValue>
                        </Flex>
                      </ModalInputArea>
                      {this.state.zenginAvailable && (
                        <ModalInputArea>
                          <ModalInputLabel>振込先口座</ModalInputLabel>
                          <RemittanceDestinationAccountForm account={editingUser.remittanceDestinationAccount} />
                        </ModalInputArea>
                      )}
                    </MemberEditSection>
                    <MemberEditSection>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="email">メール</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="email"
                          type="email"
                          value={_.isEmpty(editingUser.email) ? '' : editingUser.email}
                          disabled
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="tel">電話番号</ModalInputLabel>
                        <ModalInput
                          className="member-edit__input"
                          id="tel"
                          type="tel"
                          value={_.isEmpty(editingUser.tel) ? '' : editingUser.tel}
                          onChange={e => this.handleFieldChange('setTel', e)}
                        />
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="departmentId">部署</ModalInputLabel>
                        <ModalSelect
                          className="member-edit__select"
                          id="departmentId"
                          value={editingUser.departmentId() || ''}
                          onChange={this.handleDepartmentChange}
                        >
                          <option value="">-</option>
                          {this.state.departments.list.map((d: any) => (
                            <option
                              value={d.id}
                              key={d.id}
                              disabled={this.state.editingUser.approvalRequired() && d.approvers.length === 0}
                            >
                              {`${d.name}(${d.code})`}
                            </option>
                          ))}
                        </ModalSelect>
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="departmentId">定期区間</ModalInputLabel>
                        {editingUser.teiki && !editingUser.teiki.isRemoved() ? (
                          <TeikiInformationWrapper>
                            <TeikiInformation>※駅を検索して選択してください</TeikiInformation>
                            <TeikiInformation> 駅すぱあとから取得した情報を利用しています</TeikiInformation>
                            <RadioFieldWrapper>
                              {Object.keys(orderOptions).map(v => (
                                <RadioField
                                  key={`order-${v}`}
                                  label={orderOptions[v]}
                                  value={v}
                                  checked={this.state.editingUser.teiki.ekispertSort === v}
                                  onChange={value => this.handleChangeEkispertSort(value as EkispertSort)}
                                  id={`order-${v}`}
                                />
                              ))}
                            </RadioFieldWrapper>
                            <TeikiInputLabel>出発</TeikiInputLabel>
                            <EkispertInput
                              example="駅名・ビル名など"
                              onChange={this.handleChangeRoute}
                              value={editingUser.teiki.from}
                              onDisappearSuggest={this.handleFetchRoute}
                              freeInput={false}
                              id="origin"
                              className="members"
                              serviceType="biztra"
                            />
                            <TeikiInputLabel>到着</TeikiInputLabel>
                            <EkispertInput
                              example="駅名・ビル名など"
                              onChange={this.handleChangeRoute}
                              value={editingUser.teiki.to}
                              onDisappearSuggest={this.handleFetchRoute}
                              freeInput={false}
                              id="destination"
                              className="members"
                              serviceType="biztra"
                            />
                            {this.state.route && (
                              <>
                                <ModalInputLabel htmlFor="departmentId">経路</ModalInputLabel>
                                <TeikiRoute>{this.state.route}</TeikiRoute>
                              </>
                            )}
                            <button type="button" onClick={() => this.handleRemoveTeiki()}>
                              削除
                            </button>
                          </TeikiInformationWrapper>
                        ) : (
                          <button type="button" onClick={() => this.handleAddTeiki()}>
                            追加
                          </button>
                        )}
                      </ModalInputArea>
                      {organization.use_exic_expence && (
                        <ModalInputArea>
                          <ModalInputLabel>エクスプレス・カード(ID PASS)</ModalInputLabel>
                          <ModalInput
                            className="member-edit__input"
                            id="exicId"
                            type="text"
                            value={_.isEmpty(editingUser.exicId) ? '' : editingUser.exicId}
                            onChange={e => this.handleFieldChange('setExicId', e)}
                          />
                          <ModalInput
                            className="member-edit__input"
                            id="exicPassword"
                            type="password"
                            value={_.isEmpty(editingUser.exicPassword) ? '' : editingUser.exicPassword}
                            onChange={e => this.handleFieldChange('setExicPassword', e)}
                          />
                        </ModalInputArea>
                      )}
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="employmentStatus">雇用形態</ModalInputLabel>
                        <ModalSelect
                          className="member-edit__select"
                          id="employmentStatus"
                          value={editingUser.employment_status || ''}
                          onChange={e => this.handleFieldChange('setEmploymentStatus', e)}
                        >
                          <option value="">-</option>
                          <option value="役員">役員</option>
                          <option value="正社員">正社員</option>
                          <option value="契約社員">契約社員</option>
                          <option value="パート">パート</option>
                          <option value="アルバイト">アルバイト</option>
                          <option value="派遣社員">派遣社員</option>
                          <option value="その他">その他</option>
                        </ModalSelect>
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel htmlFor="roleId">役割</ModalInputLabel>
                        <ModalSelect
                          className="member-edit__select"
                          id="organizationRoleId"
                          value={editingUser.organizationRoleId}
                          onChange={e => this.handleFieldChange('setOrganizationRoleId', e)}
                        >
                          {organizationRoles.map(role => {
                            return (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            );
                          })}
                        </ModalSelect>
                      </ModalInputArea>
                      <ModalInputArea>
                        <ModalInputLabel>ユーザー状態</ModalInputLabel>
                        <Flex>
                          {currentUser.id === editingUser.id ? (
                            <>
                              <MemberEditRadioValue>
                                <input
                                  id="userDisabled"
                                  type="radio"
                                  value="false"
                                  checked={!editingUser.disabled}
                                  onChange={e => this.handleFieldChange('setDisabled', e)}
                                  disabled
                                />
                                <span>有効</span>
                              </MemberEditRadioValue>
                              <MemberEditRadioValue>
                                <input
                                  id="userDisabled"
                                  type="radio"
                                  value="true"
                                  checked={editingUser.disabled}
                                  onChange={e => this.handleFieldChange('setDisabled', e)}
                                  disabled
                                />
                                <span>無効</span>
                              </MemberEditRadioValue>
                            </>
                          ) : (
                            <>
                              <MemberEditRadioValue>
                                <input
                                  id="userDisabled"
                                  type="radio"
                                  value="false"
                                  checked={!editingUser.disabled}
                                  onChange={e => this.handleFieldChange('setDisabled', e)}
                                />
                                <span>有効</span>
                              </MemberEditRadioValue>
                              <MemberEditRadioValue>
                                <input
                                  id="userDisabled"
                                  type="radio"
                                  value="true"
                                  checked={editingUser.disabled}
                                  onChange={e => this.handleFieldChange('setDisabled', e)}
                                />
                                <span>無効</span>
                              </MemberEditRadioValue>
                            </>
                          )}
                        </Flex>
                      </ModalInputArea>
                    </MemberEditSection>
                  </MemberEdit>
                  {this.state.saveModalErrors && (
                    <MemberEditModalErrors>
                      {this.state.saveModalErrors.map((error, i) =>
                        typeof error === 'string' ? (
                          <div className="error" key={i}>
                            {error.split('\n').map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                          </div>
                        ) : (
                          <div className="error" key={i}>
                            {error[0]}
                          </div>
                        )
                      )}
                    </MemberEditModalErrors>
                  )}
                  {this.state.saving && (
                    <OrganizationSaving>
                      <img src="/images/loading.gif" width={20} height={20} />
                    </OrganizationSaving>
                  )}
                  <ActionWrapper>
                    <CancelButton type="reset" value="キャンセル" onClick={() => this.handleCancelClick()} />
                    <EditModalSubmitInputButton type="submit" value="保存" disabled={this.state.saving} />
                  </ActionWrapper>
                </form>
              </BiztraModalBody>
            </BiztraModal>
          )}
        </ContentBody>
      </>
    );
  }
}

const OrganizationMembersInviteArea = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationMembersInviteForm = styled.form`
  display: flex;
  align-items: center;
`;

const OrganizationMembersInviteLabel = styled.label`
  font-weight: 400;
`;

const OrganizationMembersInviteField = styled.input`
  && {
    width: 300px;
    margin-left: 10px;
    margin-bottom: 0;
  }
`;

const OrganizationMembersInviteSelect = styled.select`
  margin-left: 10px;
  margin-bottom: 0;
`;

const OrganizationMembersInviteButtonWrap = styled.div`
  margin-left: 10px;
`;

const OrganizationMembersInviteButton = styled.input`
  &&& {
    ${ButtonBase}
    width: 100px;
    padding: 8px 0;
  }
`;

const OrganizationMembersCsvLinkArea = styled.div`
  margin-left: 20px;

  a {
    font-weight: bold;
  }
`;

const InviteSuccessMessage = styled.div`
  color: ${props => props.theme.successColor};
`;

const OrganizationMembersInviteErrors = styled.div`
  margin-bottom: 20px;
`;

const OrganizationEditButton = styled.input`
  &&& {
    ${ButtonBase}
    width: 80px;
    margin: 2px auto;
    padding: 5px 0;
  }
`;

const OrganizationMemberTd = styled(OrganizationBiztraTd)`
  ${({ className }) =>
    className === 'organization__td-disabled' &&
    `
    background-color: gray;
  `}
`;

const AllApprovalUpdate = styled.div``;

const AllApprovalUpdateConfirm = styled.div`
  margin-bottom: 10px;
`;

const InvitedAtTooltipWrapper = styled.div``;

const MemberEdit = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MemberEditSection = styled.div`
  width: 48%;
  padding: 5px;
`;

export const ModalInputArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalInputLabel = styled.label`
  min-width: 144px;
  font-size: 13px;
  font-weight: normal;
`;

export const ModalInput = styled.input`
  &&& {
    margin: 0;
  }
  &&&:focus {
    box-shadow: none;
    border-color: ${props => props.theme.linkColor};
  }
`;

export const ModalSelect = styled.select`
  &&& {
    margin: 0;
    padding: 0.25em;
  }
`;

export const MemberEditRadioValue = styled.label`
  width: auto;
  font-weight: normal;

  > span {
    padding-left: 4px;
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const MemberEditModalErrors = styled.div`
  margin: 20px;
`;

const MemberEditModalButton = styled.div`
  display: flex;
  justify-content: flex-end;

  > input,
  > input:hover:hover {
    width: 100px;
    margin-left: 20px;
  }
`;

const EditModalCancelButtonCss = css`
  ${BorderButtonBase}
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const EditModalCancelButtonButton = styled.button`
  ${EditModalCancelButtonCss}
`;

const EditModalSubmitButtonCss = css`
  ${ButtonBase}
  width: 100px;
  margin: 0 0 0 20px;
  padding: 0.75em 1em;
`;

const EditModalSubmitButtonButton = styled.button`
  ${EditModalSubmitButtonCss}
`;

const CancelButton = styled.input`
  &&& {
    ${CancelButtonCss}
  }
`;

const EditModalSubmitInputButton = styled.input`
  &&& {
    ${BaseButtonCss}
  }
`;

const OrganizationSaving = styled.div`
  width: 110px;
  margin: 0 auto;
`;

const TeikiInputLabel = styled.div`
  font-size: 6px;
`;

const TeikiInformationWrapper = styled.div`
  display: box;
`;

const TeikiInformation = styled.div`
  font-size: 5px;
`;

const RadioFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin: calc(10 / 351 * 100%) 0;
  }
`;

const TeikiRoute = styled.div`
  padding-bottom: 5px;
`;

export default Members;
