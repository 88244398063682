/* eslint-disable max-lines */
import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import type { ArrangerArgs } from '@this/src/domain/arranger/arranger';
import Arranger from '@this/src/domain/arranger/arranger';
import type MasterStepSequence from '@this/src/domain/master_step/master_step_sequence';
import type {
  OrderItemStepAction,
  OrderItemStepAssignType,
  OrderItemStepTodoResponseArgs
} from '@this/src/domain/arrangement/order_item_step_todo';
import OrderItemStepTodo, {
  convertOrderItemStepTodoResponseToArgs,
  ORDER_ITEM_STEP_ASSIGNS
} from '@this/src/domain/arrangement/order_item_step_todo';
import Notification from '@this/src/notification';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import type MasterStep from '@this/domain/master_step/master_step';
import { Fetcher, HTTPError } from '@this/src/util';
import OrderItemStepTodoTableRow from './order_item_step_todo_table_row';
import useOrganizationSelector from '../use_organization_selector';
import useArrangerSelector from '../use_arranger_selector';
import useMasterStepSequenceSelector from '../use_master_step_sequence_selector';
import type { UrlQuery } from './order_item_step_todo_search_area';
import { initialUrlQuery, OrderItemStepTodoSearchArea } from './order_item_step_todo_search_area';
import OrderItemStepSequenceUpdateSelector from './order_item_step_sequence_update_selector';
import OrderItemStepArrangerUpdateSelector from './order_item_step_arranger_update_selector';
import OrderItemStepSequenceAbortConfirm from './order_item_step_sequence_abort_confirm';
import OrderItemStepWorkDialog from './order_item_step_work_dialog';

type AssignedCounts = Record<OrderItemStepAssignType, number>;

interface Props {
  serviceId: number;
}

export interface State {
  isLoading: boolean;
  isUpdating: boolean;
  arranger: Arranger;
  todos: OrderItemStepTodo[];
  totalCount: number;
  totalPage: number;
  page: number;
  assignedCounts: AssignedCounts;
  urlQuery: UrlQuery;
  workDialog: {
    todo: OrderItemStepTodo | null;
  };
  sequenceUpdatePopover: {
    todo: OrderItemStepTodo | null;
    anchorEl: Element | null;
  };
  sequenceAbortConfirm: {
    todo: OrderItemStepTodo | null;
  };
  arrangerUpdatePopover: {
    todo: OrderItemStepTodo | null;
    anchorEl: Element | null;
  };
}

interface Request {
  page: number;
  organizationIds: number[];
  arrangerIds: number[];
  masterStepSequenceIds: number[];
  urlQuery: UrlQuery;
  history: RouteComponentProps['history'];
  setState: Dispatch<SetStateAction<State>>;
}

interface Response {
  order_item_todos: OrderItemStepTodoResponseArgs[];
  total_count: number;
  total_page: number;
  arranger: ArrangerArgs;
}

interface TodoActionRequest {
  todo: OrderItemStepTodo;
  actionType: OrderItemStepAction;
  setState: Dispatch<SetStateAction<State>>;
  fetch: () => void;
}

interface ReplaceRequest {
  todo: OrderItemStepTodo;
  masterStepSequence: MasterStepSequence;
  masterStep: MasterStep;
  setState: Dispatch<SetStateAction<State>>;
  fetch: () => void;
}

interface AbortRequest {
  todo: OrderItemStepTodo;
  setState: Dispatch<SetStateAction<State>>;
  fetch: () => void;
}

interface AssignRequest {
  todo: OrderItemStepTodo;
  arranger: Arranger | null;
  setState: Dispatch<SetStateAction<State>>;
  fetch: () => void;
}

const getNumberParam = (key: string): number | undefined => {
  const str = utils.getParam(key);
  return str ? parseInt(str, 10) : undefined;
};

const fetchTodos = ({
  page,
  organizationIds,
  arrangerIds,
  masterStepSequenceIds,
  urlQuery,
  history,
  setState
}: Request) => {
  setState(state => ({ ...state, isLoading: true }));

  const { start_date_from, start_date_to, receive_date_from, receive_date_to, ...others } = urlQuery;
  const params = {
    page,
    ...others,
    start_date_from: start_date_from?.format('YYYY-MM-DD'),
    start_date_to: start_date_to?.format('YYYY-MM-DD'),
    receive_date_from: receive_date_from?.format('YYYY-MM-DD'),
    receive_date_to: receive_date_to?.format('YYYY-MM-DD'),
    organization_ids: organizationIds.join(','),
    arranger_ids: arrangerIds.join(','),
    master_step_sequence_ids: masterStepSequenceIds.join(',')
  };
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) delete params[key as keyof typeof params];
  });
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  history.push(`/arrangement/todo_list?tab=STEP&${query}`);

  Fetcher.get<Response>('/arrangement/order_item_step_todos.json', params)
    .then(res => {
      setState(state => ({
        ...state,
        arranger: new Arranger(res.arranger),
        todos: res.order_item_todos.map(
          args => new OrderItemStepTodo(convertOrderItemStepTodoResponseToArgs(args))
        ),
        totalCount: res.total_count,
        totalPage: res.total_page,
        page: res.total_page < page ? 1 : state.page
      }));
    })
    .finally(() => setState(state => ({ ...state, isLoading: false })));
};

const fetchTodosCount = ({ setState }: { setState: Dispatch<SetStateAction<State>> }) => {
  Fetcher.get<AssignedCounts>('/arrangement/order_item_step_todos/count.json').then(res => {
    setState(state => ({ ...state, assignedCounts: res }));
  });
};

const fetchTodoAction = ({ todo, actionType, setState, fetch }: TodoActionRequest) => {
  setState(state => ({ ...state, isUpdating: true }));
  Fetcher.post('/arrangement/order_item_step_todos/todo_action.json', {
    order_item_step_id: todo.id,
    action_type: actionType
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '更新に失敗しました';
      Notification.error(message);
    })
    .finally(() => {
      setState(state => ({ ...state, isUpdating: false }));
    });
};

const fetchReplaceMasterStepSequence = ({
  todo,
  masterStepSequence,
  masterStep,
  setState,
  fetch
}: ReplaceRequest) => {
  setState(state => ({ ...state, isUpdating: true }));
  Fetcher.post('/arrangement/order_item_step_todos/replace_master_sequence.json', {
    order_item_step_id: todo.id,
    master_step_sequence_id: masterStepSequence.id,
    master_step_id: masterStep.id
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '更新に失敗しました';
      Notification.error(message);
    })
    .finally(() => {
      setState(state => ({ ...state, isUpdating: false }));
    });
};

const fetchAbortMasterStepSequence = ({ todo, setState, fetch }: AbortRequest) => {
  setState(state => ({ ...state, isUpdating: true }));
  Fetcher.post('/arrangement/order_item_step_todos/abort_master_sequence.json', {
    order_item_step_id: todo.id
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '破棄に失敗しました';
      Notification.error(message);
    })
    .finally(() => {
      setState(state => ({ ...state, isUpdating: false }));
    });
};

const fetchAssign = ({ todo, arranger, setState, fetch }: AssignRequest) => {
  setState(state => ({ ...state, isUpdating: true }));
  Fetcher.post('/arrangement/order_item_step_todos/assign.json', {
    order_item_step_id: todo.id,
    assigned_to_id: arranger?.id
  })
    .then(() => {
      fetch();
    })
    .catch(error => {
      const message =
        error instanceof HTTPError && error.response?.data.message
          ? error.response.data.message
          : '更新に失敗しました';
      Notification.error(message);
    })
    .finally(() => {
      setState(state => ({ ...state, isUpdating: false }));
    });
};

const OrderItemStepTodoList = observer(({ serviceId }: Props) => {
  const { current: history } = useRef(useHistory());
  const [state, setState] = useState<State>({
    isLoading: false,
    isUpdating: false,
    arranger: Arranger.empty(),
    todos: [],
    totalCount: 0,
    totalPage: 1,
    page: getNumberParam('page') || 1,
    assignedCounts: {
      assigned_to_me: 0,
      unassigned: 0,
      all_tasks: 0
    },
    urlQuery: initialUrlQuery(),
    workDialog: {
      todo: null
    },
    sequenceUpdatePopover: {
      todo: null,
      anchorEl: null
    },
    sequenceAbortConfirm: {
      todo: null
    },
    arrangerUpdatePopover: {
      todo: null,
      anchorEl: null
    }
  });
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [arrangerIds, renderArrangerSelector] = useArrangerSelector();
  const [masterStepSequenceIds, renderMasterStepSequenceSelector] = useMasterStepSequenceSelector();

  const {
    isLoading,
    isUpdating,
    arranger,
    todos,
    totalCount,
    totalPage,
    page,
    assignedCounts,
    urlQuery,
    workDialog,
    sequenceUpdatePopover,
    sequenceAbortConfirm,
    arrangerUpdatePopover
  } = state;
  const { assign_type: assignType } = urlQuery;

  const setUrlQuery = useCallback(<T extends State['urlQuery']>(key: keyof T, value: T[keyof T]) => {
    setState(state => ({ ...state, urlQuery: { ...state.urlQuery, [key]: value } }));
  }, []);

  const fetch = useCallback(() => {
    fetchTodos({ page, urlQuery, organizationIds, arrangerIds, masterStepSequenceIds, history, setState });
  }, [page, urlQuery, organizationIds, arrangerIds, masterStepSequenceIds, history]);

  const fetchCount = useCallback(() => {
    fetchTodosCount({ setState });
  }, []);

  const fetchAction = useCallback(() => {
    fetch();
    fetchCount();
  }, [fetch, fetchCount]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    fetchCount();
  }, []);

  const handleWorkDialogOpen = useCallback(
    (todo: OrderItemStepTodo) => () => {
      setState(state => ({ ...state, workDialog: { todo } }));
    },
    []
  );

  const handleTodoAction = useCallback(
    (todo: OrderItemStepTodo, action: OrderItemStepAction) => {
      fetchTodoAction({
        todo,
        actionType: action,
        setState,
        fetch: () => {
          fetchAction();
          if (action === 'start') handleWorkDialogOpen(todo)();
        }
      });
    },
    [fetchAction, handleWorkDialogOpen]
  );

  const handleMessageFinish = useCallback(
    (messageId: number) => {
      Fetcher.put(`/arrangement/messages/${messageId}/finish`, {}).then(() => fetch());
    },
    [fetch]
  );

  const handleWorkDialogClose = useCallback(
    (isFetch = false) => {
      setState(state => ({ ...state, workDialog: { todo: null } }));
      if (isFetch) fetchAction();
    },
    [fetchAction]
  );

  const handleSequenceUpdatePopoverOpen = useCallback(
    (todo: OrderItemStepTodo) => (e: React.MouseEvent) => {
      setState(state => ({ ...state, sequenceUpdatePopover: { todo, anchorEl: e.currentTarget } }));
    },
    []
  );

  const handleSequenceUpdatePopoverClose = useCallback(
    () => setState(state => ({ ...state, sequenceUpdatePopover: { todo: null, anchorEl: null } })),
    []
  );

  const handleSequenceUpdatePopoverChange = useCallback(
    (todo: OrderItemStepTodo, masterStepSequence: MasterStepSequence, masterStep: MasterStep) => {
      fetchReplaceMasterStepSequence({ todo, masterStepSequence, masterStep, setState, fetch: fetchAction });
    },
    [fetchAction]
  );

  const handleSequenceAbortConfirmOpen = useCallback(
    (todo: OrderItemStepTodo) => () => {
      setState(state => ({ ...state, sequenceAbortConfirm: { todo } }));
    },
    []
  );

  const handleSequenceAbortConfirmClose = useCallback(
    () => setState(state => ({ ...state, sequenceAbortConfirm: { todo: null } })),
    []
  );

  const handleSequenceAbortConfirmAbort = useCallback(
    (todo: OrderItemStepTodo) => {
      fetchAbortMasterStepSequence({ todo, setState, fetch: fetchAction });
    },
    [fetchAction]
  );

  const handleArrangerUpdatePopoverOpen = useCallback(
    (todo: OrderItemStepTodo) => (e: React.MouseEvent) => {
      setState(state => ({ ...state, arrangerUpdatePopover: { todo, anchorEl: e.currentTarget } }));
    },
    []
  );

  const handleArrangerUpdatePopoverClose = useCallback(
    () => setState(state => ({ ...state, arrangerUpdatePopover: { todo: null, anchorEl: null } })),
    []
  );

  const handleArrangerUpdatePopoverChange = useCallback(
    (todo: OrderItemStepTodo, arranger: Arranger | null) => {
      fetchAssign({ todo, arranger, setState, fetch: fetchAction });
    },
    [fetchAction]
  );

  return (
    <>
      <SelectModel>
        {Object.entries(ORDER_ITEM_STEP_ASSIGNS).map(([key, value]) => (
          <Label key={key}>
            <input
              type="radio"
              checked={assignType === key}
              onChange={() => setUrlQuery('assign_type', key as OrderItemStepAssignType)}
            />
            {value}
            {assignedCounts[key as OrderItemStepAssignType] > 0 &&
              `(${assignedCounts[key as OrderItemStepAssignType]})`}
          </Label>
        ))}
      </SelectModel>
      <OrderItemStepTodoSearchArea
        urlQuery={urlQuery}
        renderOrganizationSelector={renderOrganizationSelector}
        renderArrangerSelector={renderArrangerSelector}
        renderMasterStepSequenceSelector={renderMasterStepSequenceSelector}
        setState={setState}
        assignType={assignType}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>検索結果: {totalCount}件</div>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Tripステータス</TableCell>
                <TableCell>商品ステータス</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>シーケンス</TableCell>
                <TableCell>ステップ</TableCell>
                <TableCell>ステップステータス</TableCell>
                <TableCell>担当者</TableCell>
                <TableCell>法人名</TableCell>
                <TableCell nowrap>Trip ID</TableCell>
                <TableCell nowrap>Trace ID</TableCell>
                {urlQuery.order_item_visible && (
                  <>
                    <TableCell>SP</TableCell>
                    <TableCell>QR</TableCell>
                    <TableCell>土日祝対応</TableCell>
                    <TableCell>問い合わせ対応</TableCell>
                    <TableCell>チャットメッセージ</TableCell>
                    <TableCell>最新のログメッセージ</TableCell>
                  </>
                )}
                <TableCell>所要時間</TableCell>
                {assignType !== 'unassigned' && <TableCell>アサインからの経過時間</TableCell>}
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todos.map((todo, i) => (
                <OrderItemStepTodoTableRow
                  key={i}
                  arranger={arranger}
                  todo={todo}
                  loading={isUpdating}
                  orderItemVisible={urlQuery.order_item_visible}
                  onTodoAction={handleTodoAction}
                  onMessageFinish={handleMessageFinish}
                  onWorkDialogOpen={handleWorkDialogOpen}
                  onSequenceOpen={handleSequenceUpdatePopoverOpen}
                  onSequenceAbortOpen={handleSequenceAbortConfirmOpen}
                  onArrangerOpen={handleArrangerUpdatePopoverOpen}
                  assignType={assignType}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            count={totalPage}
            page={page}
            onChange={(_e: unknown, newPage: number) => setState(state => ({ ...state, page: newPage }))}
          />
        </>
      )}
      <OrderItemStepWorkDialog serviceId={serviceId} todo={workDialog.todo} onClose={handleWorkDialogClose} />
      <OrderItemStepSequenceUpdateSelector
        todo={sequenceUpdatePopover.todo}
        anchorEl={sequenceUpdatePopover.anchorEl}
        onChange={handleSequenceUpdatePopoverChange}
        onClose={handleSequenceUpdatePopoverClose}
      />
      <OrderItemStepSequenceAbortConfirm
        todo={sequenceAbortConfirm.todo}
        onAbort={handleSequenceAbortConfirmAbort}
        onClose={handleSequenceAbortConfirmClose}
      />
      <OrderItemStepArrangerUpdateSelector
        todo={arrangerUpdatePopover.todo}
        anchorEl={arrangerUpdatePopover.anchorEl}
        onChange={handleArrangerUpdatePopoverChange}
        onClose={handleArrangerUpdatePopoverClose}
      />
    </>
  );
});

const SelectModel = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Label = styled.label`
  margin-right: 20px;
`;

export default OrderItemStepTodoList;
