import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Title } from '@this/components/admin/admin';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { Box, Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import OrganizationAnnouncementModal from '@this/components/admin/organization_announcements/organization_announcement_modal';
import { styled } from '@this/constants/themes';
import type { OrganizationAnnouncementArg } from '@this/domain/organization_announcement/organization_announcement';
import OrganizationAnnouncement from '@this/domain/organization_announcement/organization_announcement';
import { Button } from '@this/shared/ui/inputs/button';

interface Props {}

interface Response {
  organization_announcements: OrganizationAnnouncementArg[];
}
const OrganizationAnnouncements: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [organizationAnnouncements, setOrganizationAnnouncements] = useState<OrganizationAnnouncement[]>([]);
  const [organizationAnnouncement, setOrganizationAnnouncement] = useState<OrganizationAnnouncement | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');

  const fetchOrganizationAnnouncements = useCallback(async () => {
    setIsLoading(true);
    axios
      .get<Response>('/admin/organization_announcements.json', {})
      .then(response => {
        setIsLoading(false);
        setOrganizationAnnouncements(
          response.data.organization_announcements.map(raw => new OrganizationAnnouncement(raw))
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    fetchOrganizationAnnouncements();
  }, []);

  return (
    <>
      <Title>管理者向けのお知らせ</Title>
      <Content>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>1行目</TableCell>
                  <TableCell>2行目</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {organizationAnnouncements.map(data => (
                  <TableRow hover key={data.id} data-e2e-id={data.id}>
                    <TableCell className="e2e-title1">{data.title1}</TableCell>
                    <TableCell className="e2e-title2">{data.title2}</TableCell>
                    <TableCell className="e2e-url">{data.url}</TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setOrganizationAnnouncement(data);
                          setShowModal(true);
                          setModalType('mod');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>編集</LinkText>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        size="small"
                        onClick={() => {
                          setOrganizationAnnouncement(data);
                          setShowModal(true);
                          setModalType('delete');
                        }}
                        disabled={isLoading}
                      >
                        <LinkText>削除</LinkText>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box marginTop="10px">
              <Button
                size="small"
                onClick={() => {
                  setOrganizationAnnouncement(null);
                  setShowModal(true);
                  setModalType('add');
                }}
                disabled={isLoading || organizationAnnouncements.length >= 3}
              >
                追加
              </Button>
            </Box>

            {showModal && (
              <OrganizationAnnouncementModal
                open
                onClose={() => setShowModal(false)}
                afterSubmit={fetchOrganizationAnnouncements}
                id={organizationAnnouncement?.id}
                organizationAnnouncement={organizationAnnouncement}
                modalType={modalType}
              />
            )}
          </Grid>
        )}
      </Content>
    </>
  );
};

const LinkText = styled.span`
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.linkColor};
`;

const Content = styled.div`
  padding: 10px 20px;
`;

export default OrganizationAnnouncements;
