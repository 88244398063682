import React, { useCallback, useState } from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase, Collapse, IconButton, Paper } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getSpacing } from '@this/shared/ui/theme';

// TODO: storybookがない

interface Props {
  className?: string;
  expanded?: boolean;
  defaultExpanded?: boolean;
  summary: React.ReactNode;
  onChange?: (isExpanded: boolean) => void;
}

export const Accordion: React.FC<Props> = ({
  className,
  expanded,
  defaultExpanded,
  summary,
  children,
  onChange
}) => {
  const [expand, setExpand] = useState(defaultExpanded ?? false);
  const isExpanded = expanded ?? expand;

  const handleClick = useCallback(() => {
    if (onChange) {
      onChange(!isExpanded);
    } else {
      setExpand(!isExpanded);
    }
  }, [isExpanded, setExpand, onChange]);

  return (
    <AccordionBase className={`ui-accordion ${className || ''}`}>
      <AccordionSummary className="ui-accordion__summary" onClick={handleClick} {...{ component: 'div' }}>
        <AccordionSummaryContent className="ui-accordion__summary-content">{summary}</AccordionSummaryContent>
        <AccordionSummaryExpand className={`ui-accordion__summary-expand ${isExpanded ? 'expanded' : ''}`}>
          <ExpandMore />
        </AccordionSummaryExpand>
      </AccordionSummary>
      <AccordionDetail className="ui-accodion__detail" in={isExpanded} unmountOnExit>
        {children}
      </AccordionDetail>
    </AccordionBase>
  );
};

const AccordionBase = styled(Paper)``;

const AccordionSummary = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${getSpacing(4)}px;
  text-align: left;
  white-space: initial;
`;

const AccordionSummaryContent = styled.div`
  padding: ${getSpacing(2)}px 0;
`;

const AccordionSummaryExpand = styled(IconButton)`
  margin-right: ${getSpacing(-4)}px;

  &.expanded {
    transform: rotate(180deg);
  }
`;

const AccordionDetail = styled(Collapse)`
  .MuiCollapse-wrapperInner {
    padding: ${getSpacing(2)}px ${getSpacing(4)}px ${getSpacing(4)}px;
  }
`;
