interface JournalArgs {
  date: string;
  debtor: string;
  debtorSub?: string;
  debtorAmount: number;
  creditor: string;
  creditorSub?: string;
  creditorAmount: number;
  summary?: string;
  tags?: string[];
  shouldImport: boolean;
}

class Journal {
  public date: string;

  public debtor: string;

  public debtorSub: string;

  public debtorAmount: number;

  public creditor: string;

  public creditorSub: string;

  public creditorAmount: number;

  public summary: string;

  public tags: string[];

  public shouldImport: boolean;

  constructor(args: JournalArgs) {
    this.date = args.date;
    if (args.debtorAmount >= 0) {
      this.debtor = args.debtor;
      this.debtorSub = args.debtorSub || '';
      this.debtorAmount = args.debtorAmount;
      this.creditor = args.creditor;
      this.creditorSub = args.creditorSub || '';
      this.creditorAmount = args.creditorAmount;
    } else {
      this.debtor = args.creditor;
      this.debtorSub = args.creditorSub || '';
      this.debtorAmount = -args.creditorAmount;
      this.creditor = args.debtor;
      this.creditorSub = args.debtorSub || '';
      this.creditorAmount = -args.debtorAmount;
    }
    this.summary = args.summary || '';
    this.tags = args.tags || [];
    this.shouldImport = args.shouldImport;
  }
}

export default Journal;
