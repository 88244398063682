import moment from 'moment';

class BulkActionProject {
  code: string;

  status: string;

  changes: unknown;

  errorMessage: string;

  createdAt: string;

  updatedAt: string;

  constructor(raw: any) {
    this.code = raw.code;
    this.status = raw.status;
    this.changes = raw.changes;
    this.errorMessage = raw.error_message;
    this.createdAt = moment(raw.created_at).format('YYYY-MM-DD HH:mm:ss');
    this.updatedAt = moment(raw.updated_at).format('YYYY-MM-DD HH:mm:ss');
  }

  statusMessage() {
    switch (this.status) {
      case 'enqueued':
        return '未着手';
      case 'running':
        return '着手中';
      case 'success':
        return '成功';
      case 'skip':
        return `スキップ: ${this.errorMessage}`;
      case 'fail':
        return `失敗: ${this.errorMessage}`;
      default:
        return '';
    }
  }

  isSuccess() {
    return this.status === 'success';
  }

  isFail() {
    return this.status === 'fail';
  }
}

export default BulkActionProject;
