class OrderItemPayment
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @paymentType = 1 if typeof @paymentType == 'undefined'

  handlePaymentTypeChange: (value) ->
    @paymentType = parseInt(value)
    app.render()

  paymentTypeString: () ->
    switch @paymentType
      when 0 then 'クレジットカード'
      when 1 then '請求書'
      when 2 then '現地払い'
      when 3 then '回数券'

  isBulkTicket: ->
    parseInt(@paymentType) == 3

  updateParams: ->
    payment_type: @paymentType

module.exports = OrderItemPayment
