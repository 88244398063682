import React from 'react';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import { styled } from '@this/constants/themes';
import UpdateConfirmedAtForm from '@this/components/arrangement/virtual_counter/update_confirmed_at_form/update_confirmed_at_form';
import type Trip from '@this/domain/trip/trip';
import moment from 'moment';
import VirtualCounterMessageBody from './body';

interface Props {
  message: Message;
  selectedTrip: Trip;
  showMessage: boolean;
  toggleShowMessage: () => void;
  fetchMessages: (trip: Trip) => void;
}
const PartnerLog: React.FC<Props> = ({ message, showMessage, selectedTrip, toggleShowMessage, fetchMessages }) => {
  return (
    <>
      <TripLogTitle onClick={toggleShowMessage}>
        {showMessage ? '▼' : '▶'}
        {message.arranger_name}が注文内容を変更しました。 ({moment(message.created_at).format('MM/DD HH:mm')})
        {message.confirmed_at && ` [請求確定日:${moment(message.confirmed_at).format('YYYY/MM/DD')}]`}
      </TripLogTitle>
      {showMessage && (
        <>
          {message.confirmed_at && (
            <UpdateConfirmedAtForm orderId={Number(message.order_id)} initialConfirmedAt={message.confirmed_at} />
          )}
          <MessageLogBox>
            <VirtualCounterMessageBody
              message={message}
              selectedTrip={selectedTrip}
              fetchMessages={fetchMessages}
            />
          </MessageLogBox>
        </>
      )}
    </>
  );
};

const TripLogTitle = styled.div`
  margin-bottom: 5px;
  cursor: pointer;
`;

const MessageLogBox = styled.div`
  border: 1px solid ${props => props.theme.grayBorderColor};
  border-radius: 5px;
  padding: 10px;
  background: #bde8fd;
`;

export default PartnerLog;
