import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@this/constants/themes';
import { Route, Switch } from 'react-router-dom';

import type { GodArgs } from '@this/domain/god/god';
import GodModel from '@this/domain/god/god';
import GodContext from '@this/components/god/god_context';
import Gods from '@this/components/god/gods/gods';
import Edit from '@this/components/god/edit/edit';
import { Fetcher } from '@this/src/util';
import GodHeader from './god_header';
import GodDashboard from './god_dashboard/god_dashboard';
import AutoReservation from './auto_reservation/auto_reservation';
import Chatbot from './chatbot/chatbot';
import HotelMaster from './hotel_master/hotel_master';
import HotelMasterManage from './hotel_master_manage/hotel_master_manage';
import SearchQueryItems from './search_query_items/search_query_items';
import SearchQueryItem from './search_query_items/search_query_item_detail';
import DomesticAir from './domestic_air/domestic_air';
import Simulation from './simulation/simulation';
import SimulationRecords from './simulation/simulation_records';
import System from './system/system';

interface CurrentResponse {
  god: GodArgs;
}

const God: React.FC = () => {
  const [currentGod, setCurrentGod] = useState<GodModel | null>(null);

  useEffect(() => {
    Fetcher.get<CurrentResponse>('/god/gods/current.json').then(response => {
      const god = new GodModel(response.god);
      setCurrentGod(god);
    });
  }, []);

  const contextValue = useMemo(() => ({ currentGod }), [currentGod]);

  return (
    <GodContext.Provider value={contextValue}>
      <TheGod>
        <GodHeader />
        <Wrapper>
          <Switch>
            <Route exact path="/god" component={GodDashboard} />
            <Route exact path="/god/edit" component={Edit} />
            <Route exact path="/god/auto_reservations" component={AutoReservation} />
            <Route exact path="/god/chatbot" component={Chatbot} />
            <Route exact path="/god/hotel_master" component={HotelMaster} />
            <Route exact path="/god/hotel_master_manage" component={HotelMasterManage} />
            <Route exact path="/god/search_query_items_manage" component={SearchQueryItems} />
            <Route
              exact
              path="/god/search_query_items_manage/:id"
              render={props => <SearchQueryItem {...props} />}
            />
            <Route exact path="/god/domestic_air" component={DomesticAir} />
            <Route exact path="/god/gods" component={Gods} />
            <Route exact path="/god/simulation" component={Simulation} />
            <Route exact path="/god/simulation/:id" render={props => <SimulationRecords {...props} />} />
            <Route exact path="/god/system" component={System} />
          </Switch>
        </Wrapper>
      </TheGod>
    </GodContext.Provider>
  );
};

const TheGod = styled.div`
  flex-grow: 9999;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  flex-grow: 9999;
  background: white;
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
`;

export default God;
