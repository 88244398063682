import React from 'react';
import _ from 'lodash';

import {
  CsvDownloadContainer,
  OrganizationBody,
  DownloadButton,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import ExicReport from '../../../domain/exic_report';
import ExicReportList from './table/exic_report_list';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

interface Props {}

interface State {
  loading: boolean;
  csvEncoding: string;
  reports: ExicReport[];
}

class ExicReports extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      csvEncoding: navigator.platform.indexOf('Win') !== -1 ? 'sjis' : 'utf8',
      reports: []
    };
  }

  componentDidMount() {
    this.fetchReports();
  }

  async fetchReports() {
    this.setState({ loading: true });
    utils.jsonPromise<any>('/organization/exic_reports.json').then(result => {
      this.setState({
        reports: _.map(result.reports, r => new ExicReport(r)),
        loading: false
      });
    });
  }

  handleCSVEncodingChange(value: string) {
    if (value === 'sjis' || value === 'utf8') {
      this.setState({ csvEncoding: value });
    }
  }

  getParams() {
    return { encoding: this.state.csvEncoding };
  }

  handleCsvDownload(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    location.href = `/organization/exic_reports.csv?${utils.toParams(this.getParams())}`;
  }

  render() {
    const { loading, csvEncoding } = this.state;
    try {
      return (
        <>
          <OrganizationTitle>EXIC利用一覧</OrganizationTitle>
          <OrganizationBody>
            <CsvDownloadContainer>
              <select value={csvEncoding} onChange={e => this.handleCSVEncodingChange(e.target.value)}>
                <option value="utf8">Macなど（文字コード：UTF8）</option>
                <option value="sjis">Windows（文字コード：SJIS）</option>
              </select>
              <DownloadButton type="button" onClick={e => this.handleCsvDownload(e)}>
                CSVをダウンロード
              </DownloadButton>
            </CsvDownloadContainer>
            {loading ? <SimpleLoading /> : <ExicReportList {...this.props} reports={this.state.reports} />}
          </OrganizationBody>
        </>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default ExicReports;
