import React from 'react';
import {
  OrganizationHeader,
  OrganizationTitle,
  OrganizationBody,
  OrganizationTable,
  OrganizationTh,
  OrganizationTd
} from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Context from '@this/components/core_app/core_app_context';
import { observer } from 'mobx-react';
import type { CreditCardPaymentHistoryCsvReportArgs } from '@this/domain/organization/credit_card_payment_history_csv_report';
import { CreditCardPaymentHistoryCsvReport } from '@this/domain/organization/credit_card_payment_history_csv_report';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';
import EncodingSelector from '@this/shared/encoding_selector/encoding_selector';

interface Props {}

interface State {
  loading: boolean;
  encoding: string;
  reports: CreditCardPaymentHistoryCsvReport[];
}

interface CreditCardPaymentHistoryCsvReportResponse {
  reports: CreditCardPaymentHistoryCsvReportArgs[];
}

@observer
class CreditCard extends React.Component<Props, State> {
  static contextType = Context;

  context!: React.ContextType<typeof Context>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      encoding: 'UTF-8',
      reports: []
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.fetchReports();
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.setSonyPaymentToken = null;
  }

  async fetchReports() {
    await utils
      .jsonPromise<CreditCardPaymentHistoryCsvReportResponse>(
        '/organization/credit_card_payment_history_csv_reports.json'
      )
      .then(result => {
        const reports = result.reports.map(raw => new CreditCardPaymentHistoryCsvReport(raw));
        this.setState({ reports });
      });
  }

  selectedEncodeFileDownloadPath(report: CreditCardPaymentHistoryCsvReport) {
    const file = report.files.find(f => f.encoding === this.state.encoding);
    return file?.fileDownloadPath;
  }

  render() {
    const { loading, encoding, reports } = this.state;
    // const params = useParams();
    return (
      <>
        <OrganizationTitle>クレジットカード明細</OrganizationTitle>
        <OrganizationHeader />
        <OrganizationBody>
          {loading ? (
            <SimpleLoading />
          ) : (
            <>
              <EncodingSelector encoding={encoding} onChange={e => this.setState({ encoding: e })} />

              <OrganizationTable>
                <thead>
                  <tr>
                    <OrganizationTh>対象期間</OrganizationTh>
                    <OrganizationTh>最終更新日</OrganizationTh>
                    <OrganizationTh>CSV</OrganizationTh>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((report: CreditCardPaymentHistoryCsvReport, i: number) => (
                    <tr key={i}>
                      <OrganizationTd>
                        {report.targetFrom.format('YYYY/MM/DD HH:mm:ss')} 〜{' '}
                        {report.targetTo.format('YYYY/MM/DD HH:mm:ss')}
                      </OrganizationTd>
                      <OrganizationTd>{report.lastFileUpdatedAt.format('YYYY/MM/DD HH:mm')}</OrganizationTd>
                      <Td style={{ display: 'flex' }}>
                        <Button href={this.selectedEncodeFileDownloadPath(report)}>支払い明細</Button>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              </OrganizationTable>
            </>
          )}
        </OrganizationBody>
      </>
    );
  }
}

export default CreditCard;

const Td = styled.td`
  padding: 4px;
`;

const Button = styled.a`
  &&& {
    ${ButtonBase}
    padding: 5px 10px;
  }
`;
