class BulkTicketRoute
  constructor: (args = {}) ->
    _.assign @, snakeToCamelObject(args)

  description: ->
    "#{@from} → #{@to}"

  setFrom: (from) ->
    @from = from
    app.render()

  setTo: (to) ->
    @to = to
    app.render()

  submitParams: ->
    from: @from
    to: @to

module.exports = BulkTicketRoute
