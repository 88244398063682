import { toast } from 'react-toastify';

export default class Notification {
  static success(msg: string) {
    toast.success(msg, { position: toast.POSITION.TOP_CENTER });
  }

  static error(msg: string | string[]) {
    let showMsg = msg;
    if (msg instanceof Array) {
      showMsg = msg.join('\n');
    }

    toast.error(showMsg, { position: toast.POSITION.TOP_CENTER });
  }
}
