import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';

export interface UserCost {
  name: string;
  department?: string;
  cost: number;
}

interface Props {
  userCosts: UserCost[];
}

const CostSavingList: React.FC<Props> = ({ userCosts }) => (
  <TableContainer height="auto">
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>社員名</TableCell>
          <TableCell>部署名</TableCell>
          <TableCell align="right">節約金額</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userCosts.map((cost, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{cost.name}</TableCell>
              <TableCell>{cost.department}</TableCell>
              <TableCell align="right">{cost.cost.toLocaleString()}円</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CostSavingList;
