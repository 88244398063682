import React from 'react';

import { styled } from '@this/constants/themes';
import { Select } from '@this/shared/ui/inputs/select';
import { Button } from '@this/shared/ui/inputs/button';
import type { DateRangePickerValue } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';

type Props = {
  className?: string;
  initialFilterData: ArrangementReportsFilterData;
  onSubmit: (data: ArrangementReportsFilterData) => void;
};

type ArrangementReportsFilterGroupBy = 'day' | 'week' | 'month';

type ArrangementReportsFilterData = {
  groupBy: ArrangementReportsFilterGroupBy;
  fromDate: Date;
  toDate: Date;
};

export const ArrangementReportsFilter = ({ className, initialFilterData, onSubmit }: Props) => {
  const [groupBy, setGroupBy] = React.useState<ArrangementReportsFilterGroupBy>(initialFilterData.groupBy);
  const [fromDate, setFromDate] = React.useState<Date>(initialFilterData.fromDate);
  const [toDate, setToDate] = React.useState<Date>(initialFilterData.toDate);

  const handleRangeChange = React.useCallback((value: DateRangePickerValue | null) => {
    const [from, to] = value || [];
    if (!from || !to) {
      throw new Error('');
    }
    setFromDate(from);
    setToDate(to);
  }, []);

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit({ groupBy, fromDate, toDate });
    },
    [onSubmit, groupBy, fromDate, toDate]
  );
  return (
    <Root className={className} onSubmit={handleSubmit}>
      <FormField>
        <label>検索間隔</label>
        <Select value={groupBy} onChange={e => setGroupBy(e.target.value as ArrangementReportsFilterGroupBy)}>
          <option value="day">日ごと</option>
          <option value="week">週ごと（月曜始まり）</option>
          <option value="month">月ごと</option>
        </Select>
      </FormField>

      <FormField>
        <label>手配依頼日</label>
        <DateRangePicker value={[fromDate, toDate]} onChange={handleRangeChange} disabledClear />
      </FormField>

      <Button type="submit">検索</Button>
    </Root>
  );
};

const Root = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FormField = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
