import React from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { doubleDigits } from '@this/src/util';
import {
  OutLabel,
  InLabel,
  FieldsWrapper,
  Field,
  TextField,
  ToggleField,
  InputArea,
  AddressArea,
  SelectWrap,
  Select
} from '../../search/search';

import moment from '../../../../lib/moment';

import AutoCompletableInput from '../../autocompletable_input/autocompletable_input';
import type { SearchBlockProps } from './search_block';

import DatetimePicker from '../../datetime_picker/datetime_picker';

type Props = SearchBlockProps & {
  handlePlaceChange: (name: 'origin' | 'destination', value: string, address: string) => void;
  handleChange: (
    name: 'outhour' | 'outmin' | 'outtype' | 'homehour' | 'homemin'
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeDate: (name: 'outdate' | 'homedate', date: Moment) => void;
  handleRemoveItem: () => void;
  canRemoveItem: () => boolean;
  handleRentalCarInfoChange: (
    method: string
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  rentalCarLimit: { business_days: number; actual_days: number; hour: number | null };
};

// Stateless function components cannot have refs.
// SFCだとrefが使えないので通常Component
class SearchBlockTemplate extends React.Component<Props> {
  render() {
    const {
      item,
      icon,
      canRemoveItem,
      handleRemoveItem,
      handlePlaceChange,
      handleChange,
      handleChangeDate,
      handleRentalCarInfoChange,
      rentalCarLimit,
      availableOptions,
      user
    } = this.props;
    const restrictionDistanceAndTimeAvailability =
      availableOptions?.includes('restriction_distance_and_time') ?? false;

    return item.itemType === 'transport' ? (
      <Block className="search__block">
        <Header>
          {icon && (
            <div className="search__block__header__left">
              <Icon>{icon}</Icon>
            </div>
          )}
          <HeaderCenter>
            <OutLabel>交通機関</OutLabel>
          </HeaderCenter>
          {canRemoveItem() && (
            <div className="search__block__header__right">
              <A onClick={handleRemoveItem}>削除</A>
            </div>
          )}
        </Header>
        <Body>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InLabel htmlFor="origin">出発地</InLabel>
              <InputArea wrapped>
                <AutoCompletableInput onChange={handlePlaceChange} value={item.origin} id="origin" />
              </InputArea>
              <AddressArea>
                <p>{item.originAddress}</p>
                {restrictionDistanceAndTimeAvailability && user?.hasOrganizationBase() && (
                  <p>{`拠点情報: ${user?.organizationBase.address}`}</p>
                )}
              </AddressArea>
            </ToggleField>
            <ToggleField disabled={false}>
              <InLabel htmlFor="destination">目的地</InLabel>
              <InputArea wrapped>
                <AutoCompletableInput onChange={handlePlaceChange} value={item.destination} id="destination" />
              </InputArea>
              <AddressArea>
                <p>{item.destinationAddress}</p>
              </AddressArea>
            </ToggleField>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <Field>
              <InLabel>日時</InLabel>
              <InputArea
                style={{ alignItems: 'center' }}
                wrapped
                id={`simple_outdate_input_${icon}`}
                className="calendar-input-div"
              >
                <DatetimePicker
                  value={moment(item.outdate)}
                  dateFormat="YYYY/MM/DD (ddd)"
                  onChange={date => handleChangeDate('outdate', date)}
                  width="115px"
                  disabledDays={0}
                  calenderClass="rc-calendar-trip"
                />
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="outhour" value={item.outhour} onChange={handleChange('outhour')}>
                    <option value="">-</option>
                    {_.times(24, i => (
                      <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                        {i}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>時</span>
                </div>
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="outmin" value={item.outmin} onChange={handleChange('outmin')}>
                    <option value="">-</option>
                    {_.times(6, i => (
                      <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                        {i * 10}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>分</span>
                </div>
                <div className="search__select-wrapper">
                  <Select name="outtype" value={item.outtype} onChange={handleChange('outtype')}>
                    <option value="departure">出発</option>
                    <option value="arrival">到着</option>
                  </Select>
                </div>
              </InputArea>
            </Field>
          </FieldsWrapper>
        </Body>
      </Block>
    ) : item.itemType === 'hotel' ? (
      <Block className="search__block">
        <Header>
          {icon && (
            <div className="search__block__header__left">
              <Icon>{icon}</Icon>
            </div>
          )}
          <HeaderCenter>
            <OutLabel>宿泊</OutLabel>
          </HeaderCenter>
          {canRemoveItem() && (
            <div className="search__block__header__right">
              <A onClick={handleRemoveItem}>削除</A>
            </div>
          )}
        </Header>
        <Body>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InLabel htmlFor="destination">宿泊地</InLabel>
              <InputArea wrapped>
                <AutoCompletableInput onChange={handlePlaceChange} value={item.destination} id="destination" />
              </InputArea>
              <AddressArea>
                <p>{item.destinationAddress}</p>
                {restrictionDistanceAndTimeAvailability && user?.hasOrganizationBase() && (
                  <p>{`拠点情報: ${user?.organizationBase.address}`}</p>
                )}
              </AddressArea>
            </ToggleField>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false} className="calendar-input-div">
              <div id={`checkin_date_input_${icon}`} className="inner-calendar-input-textbox">
                <InLabel>チェックイン</InLabel>
                <DatetimePicker
                  value={moment(item.outdate)}
                  dateFormat="YYYY/MM/DD (ddd)"
                  showToday
                  onChange={date => handleChangeDate('outdate', date)}
                  disabledDays={0}
                  calenderClass="rc-calendar-hotel"
                />
              </div>
            </ToggleField>
            <ToggleField disabled={false} className="calendar-input-div">
              <div id={`checkout_date_input_${icon}`} className="inner-calendar-input-textbox">
                <InLabel> チェックアウト</InLabel>
                <Homedate>
                  <DatetimePicker
                    value={moment(item.homedate)}
                    dateFormat="YYYY/MM/DD (ddd)"
                    onChange={date => handleChangeDate('homedate', date)}
                    disabledDays={0}
                    calenderClass="rc-calendar-hotel"
                  />
                  <span>{item.getStayDaysText()}</span>
                </Homedate>
              </div>
            </ToggleField>
          </FieldsWrapper>
        </Body>
      </Block>
    ) : item.itemType === 'rentalCar' && icon ? (
      <Block className="search__block">
        <Header>
          <div className="search__block__header__left">
            <Icon>{icon}</Icon>
          </div>
          <HeaderCenter>
            <OutLabel>レンタカー</OutLabel>
          </HeaderCenter>
          {canRemoveItem() && icon > 1 && (
            <div className="search__block__header__right">
              <A onClick={handleRemoveItem}>削除</A>
            </div>
          )}
        </Header>
        <Body>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InLabel htmlFor="origin">出発場所</InLabel>
              <InputArea wrapped>
                <AutoCompletableInput onChange={handlePlaceChange} value={item.origin} id="origin" />
              </InputArea>
              <AddressArea>
                <p>{item.originAddress}</p>
              </AddressArea>
            </ToggleField>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InLabel htmlFor="destination">返却場所</InLabel>
              <RadioInputArea>
                <RentalCarRadio>
                  <input
                    className="companion-label"
                    type="radio"
                    value="current"
                    onChange={handleRentalCarInfoChange('setReturnPlace')}
                    checked={item.returnPlace === 'current'}
                  />
                  <RentalCarRadioP>出発場所へ戻す</RentalCarRadioP>
                </RentalCarRadio>
                <RentalCarRadio>
                  <input
                    type="radio"
                    value="other"
                    onChange={handleRentalCarInfoChange('setReturnPlace')}
                    checked={item.returnPlace === 'other'}
                  />
                  <RentalCarRadioP>別の場所へ乗り捨てる(※乗り捨てを希望する場所を記載して下さい)</RentalCarRadioP>
                </RentalCarRadio>
                <AutoCompletableInput
                  onChange={handlePlaceChange}
                  value={item.destination}
                  id="destination"
                  disabled={item.returnPlace === 'current'}
                />
              </RadioInputArea>
              <AddressArea>
                <RentalCarDestinationAddress display={item.returnPlace === 'current' ? 'none' : 'block'}>
                  {item.destinationAddress}
                </RentalCarDestinationAddress>
              </AddressArea>
            </ToggleField>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <Field>
              <InLabel>出発日時</InLabel>
              <InputArea
                style={{ alignItems: 'center' }}
                wrapped
                id={`simple_outdate_input_${icon}`}
                className="calendar-input-div"
              >
                <DatetimePicker
                  value={moment(item.outdateWithLimitTime(rentalCarLimit.hour))}
                  dateFormat="YYYY/MM/DD (ddd)"
                  onChange={date => handleChangeDate('outdate', date)}
                  width="115px"
                  disabledDays={rentalCarLimit.actual_days}
                  calenderClass="rc-calendar-rentalcar"
                />
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="outhour" value={item.outhour} onChange={handleChange('outhour')}>
                    <option value="">-</option>
                    {_.times(24, i => (
                      <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                        {i}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>時</span>
                </div>
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="outmin" value={item.outmin} onChange={handleChange('outmin')}>
                    <option value="">-</option>
                    {_.times(6, i => (
                      <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                        {i * 10}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>分</span>
                </div>
                {rentalCarLimit.hour && (
                  <RentalCarP>
                    {`レンタカーは出発日の${rentalCarLimit.business_days}営業日前の${rentalCarLimit.hour}時までにご依頼ください。`}
                  </RentalCarP>
                )}
              </InputArea>
            </Field>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <Field>
              <InLabel>返却日時</InLabel>
              <InputArea
                style={{ alignItems: 'center' }}
                wrapped
                id={`simple_outdate_input_${icon}`}
                className="calendar-input-div"
              >
                <DatetimePicker
                  value={moment(item.homedateWithLimitTime(rentalCarLimit.hour))}
                  dateFormat="YYYY/MM/DD (ddd)"
                  onChange={date => handleChangeDate('homedate', date)}
                  width="115px"
                  disabledDays={rentalCarLimit.actual_days}
                  calenderClass="rc-calendar-rentalcar"
                />
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="homehour" value={item.homehour} onChange={handleChange('homehour')}>
                    <option value="">-</option>
                    {_.times(24, i => (
                      <option data-wovn-ignore value={doubleDigits(i)} key={i}>
                        {i}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>時</span>
                </div>
                <div style={{ display: 'flex' }} className="search__select-wrapper">
                  <Select name="homemin" value={item.homemin} onChange={handleChange('homemin')}>
                    <option value="">-</option>
                    {_.times(6, i => (
                      <option data-wovn-ignore value={doubleDigits(i * 10)} key={i}>
                        {i * 10}
                      </option>
                    ))}
                  </Select>
                  <span style={{ marginRight: '15px' }}>分</span>
                </div>
              </InputArea>
            </Field>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <Field>
              <InLabel>車種・クラス ※選択いただいたタイプから適切な車両をお選びします。</InLabel>
              <RadioInputArea>
                {item.carTypeOptions.map((c, ci) => (
                  <RentalCarRadio key={`car_type_${ci}`}>
                    <input
                      className="companion-label"
                      type="radio"
                      value={c[1]}
                      onChange={handleRentalCarInfoChange('setCarType')}
                      checked={item.carType === c[1]}
                    />
                    <RentalCarRadioP>{c[0]}</RentalCarRadioP>
                  </RentalCarRadio>
                ))}
                <TextField
                  type="text"
                  value={item.carTypeOther}
                  placeholder="車種等"
                  onChange={handleRentalCarInfoChange('setCarTypeOther')}
                  disabled={item.carType !== 'other'}
                />
              </RadioInputArea>
            </Field>
          </FieldsWrapper>
          <FieldsWrapper className="search__block__body__fields-wrapper">
            <ToggleField disabled={false}>
              <InLabel>台数</InLabel>
              <InputArea wrapped>
                <SelectWrap>
                  <Select value={item.carNumber} onChange={handleRentalCarInfoChange('setCarNumber')}>
                    {_.times(8, i => (
                      <option value={i + 1} key={i}>{`${i + 1}台`}</option>
                    ))}
                  </Select>
                </SelectWrap>
              </InputArea>
            </ToggleField>
          </FieldsWrapper>
        </Body>
      </Block>
    ) : null;
  }
}

export const Block = styled.div`
  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
`;

const Icon = styled.div`
  border-radius: 9px;
  background: ${props => props.theme.iconColor};
  color: white;
  width: 18px;
  text-align: center;
  font-size: 12px;
`;

const HeaderCenter = styled.div`
  margin: 0 auto 0 7px;
`;

export const Body = styled.div`
  margin-top: -10px;
`;

const Homedate = styled.div`
  display: flex;
`;

const RadioInputArea = styled.div``;

export const RentalCarRadio = styled.label`
  display: flex;
  font-size: 12px;
  font-weight: normal;

  &:last-child {
    margin-bottom: 5px;
  }
`;

export const RentalCarRadioP = styled.p`
  display: inline-block;

  @media (max-width: 430px) {
    margin-top: -7px;
    display: block;
    float: left;
    width: calc(100% - 24px);
  }
`;

export const RentalCarP = styled.p`
  font-size: 12px;
`;

const RentalCarDestinationAddress = styled.p<{ display: string }>`
  display: ${props => props.display};
`;

export default SearchBlockTemplate;
