import type { ColorTheme } from '@this/components/organization/dashboard/share/graph';
import { BORDER_STYLES, COLOR_THEMES } from '@this/components/organization/dashboard/share/graph';
import type { Bar } from 'react-chartjs-2';
import type { ApprovalsLeadtimeType } from '@this/components/organization/dashboard/share/index';

export interface BarApprovalsLeadtime {
  label: string;
  leadtimes: Record<Exclude<ApprovalsLeadtimeType, 'all'> | 'total', number>;
}

export const getBarHeight = (leadtimes: BarApprovalsLeadtime[]) => {
  return 120 + 25 * leadtimes.length;
};

export const getBarData = (leadtimes: BarApprovalsLeadtime[], theme: ColorTheme) => {
  const labels = leadtimes.map(({ label }) => label);
  const values = leadtimes.map(({ leadtimes }) => leadtimes);
  const colorTheme = COLOR_THEMES[theme];
  const borderStyle = BORDER_STYLES[theme];

  const datasets = [
    {
      label: '1日以内',
      data: values.map(value => value.short),
      ...colorTheme.blue,
      ...borderStyle
    },
    {
      label: '1日〜3日',
      data: values.map(value => value.normal),
      ...colorTheme.green,
      ...borderStyle
    },
    {
      label: '3日〜2週間',
      data: values.map(value => value.long),
      ...colorTheme.yellow,
      ...borderStyle
    },
    {
      label: '2週間より長い',
      data: values.map(value => value.too_long),
      ...colorTheme.red,
      ...borderStyle
    }
  ];

  return { labels, datasets };
};

export const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    xAxes: {
      stacked: true,
      ticks: {
        precision: 0
      }
    },
    yAxes: {
      grid: { display: false },
      stacked: true
    }
  },
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: tooltipItem => {
          const value = tooltipItem.raw as number;
          return `${tooltipItem.dataset.label}:${value.toLocaleString()}件`;
        }
      }
    }
  }
};
