import React, { createRef } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import type { SendItemArgs } from '@this/domain/send_list/send_item';
import { SendItem } from '@this/domain/send_list/send_item';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';

type Props = RouteComponentProps;

interface SendItemResponse {
  items: SendItemArgs[];
}

interface State {
  sendItems: SendItem[];
  loading: boolean;
}

class SendList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sendItems: [],
      loading: false
    };
  }

  componentDidMount() {
    this.fetchSendItem();
  }

  async fetchSendItem(): Promise<void> {
    this.setState({ loading: true });

    return utils.jsonPromise<SendItemResponse>('/arrangement/send_list.json').then(result =>
      this.setState({
        sendItems: result.items.map(raw => new SendItem(raw)),
        loading: false
      })
    );
  }

  private readonly sendList = createRef<HTMLTableElement>();

  handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    utils.copyElementToClipBoard(this.sendList.current, true);
  };

  isShinkansenUrgent(item: SendItem) {
    return item.shinkansenUrgent;
  }

  render() {
    try {
      const { sendItems, loading } = this.state;
      return (
        <Wrapper>
          {loading ? (
            <SimpleLoading />
          ) : (
            <div>
              <h2>送付リスト</h2>
              <button type="button" onClick={e => this.handleCopy(e)}>
                クリップボードに全てコピー
              </button>
              <SendListTable ref={this.sendList}>
                <tbody>
                  {sendItems.map((item: SendItem, index) => (
                    <SendListTr key={index} immediate={this.isShinkansenUrgent(item)}>
                      <td>{item.company}</td>
                      <td>{item.address}</td>
                      <td>{item.content}</td>
                      <td>{item.trip_id}</td>
                      <td>{item.traveler}</td>
                      <td>{item.from}</td>
                      <td>{item.to}</td>
                      <td>{item.name}</td>
                      <td>{item.boardingArrival}</td>
                      <td>{item.type}</td>
                      <td>{item.seat}</td>
                      <td>{item.preference}</td>
                      <td>{item.applicator}</td>
                    </SendListTr>
                  ))}
                </tbody>
              </SendListTable>
            </div>
          )}
        </Wrapper>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const ImmediateTxtColor = '#ff839c';

const Wrapper = styled.div`
  padding: 20px;
`;

const SendListTable = styled.table`
  font-size: 10px;
`;

const SendListTr = styled.tr<{ immediate: boolean }>`
  td {
    &:nth-of-type(6) {
      color: ${props => props.immediate && ImmediateTxtColor};
    }

    &:nth-of-type(7) {
      color: ${props => props.immediate && ImmediateTxtColor};
    }
  }
`;

export default SendList;
