import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';

import SelectTravelersBlock from '@this/shared/search/search_notify_members_block/select_travelers_block/select_travelers_block';
import type User from '@this/domain/user/user';
import type Traveler from '@this/domain/traveler/traveler';

interface Props {
  applicant: User;
  users: (Traveler | undefined)[];
  onAdd: (e: React.MouseEvent<HTMLElement>) => void;
  onSelect: (i: number, traveler: Traveler, type: string) => void;
  onRemove: (e: React.MouseEvent<HTMLElement>, i: number) => void;
}

const NotifiedUserSelectorTemplate: React.SFC<Props> = ({ applicant, users, onAdd, onSelect, onRemove }) => (
  <>
    {users.map((user: Traveler | undefined, i: number) => (
      <NotifiedUserBox key={i}>
        <TravelerFromMember>
          <SelectTravelersBlock
            user={applicant}
            traveler={user}
            index={i}
            onSelect={onSelect}
            excludeUserIds={[applicant.id, ...(users.filter(u => !!u) as Traveler[]).map(u => u.id)]}
          />
        </TravelerFromMember>
        <NotifiedUserRemove onClick={e => onRemove(e, i)}>x</NotifiedUserRemove>
      </NotifiedUserBox>
    ))}
    <A onClick={e => onAdd(e)}>出張内容を確認するユーザを追加する</A>
  </>
);

const NotifiedUserBox = styled.div`
  display: flex;
  margin: 0 0 10px 0px;
`;

const NotifiedUserRemove = styled(A)`
  margin: auto 10px;
`;

const TravelerFromMember = styled.div`
  justify-content: space-between;
  flex-grow: 9999;
  min-width: 0;

  .reserve-confirm__traveler__label-name,
  .reserve-confirm__traveler__label-birthday {
    line-height: 26px;
  }
`;

export default NotifiedUserSelectorTemplate;
