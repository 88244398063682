import React from 'react';

import { styled } from '@this/constants/themes';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import A from '@this/shared/atoms/a';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import { escapeHTML, Fetcher } from '@this/src/util';

interface Props {
  message: Message;
  onSubmitConfirmation: () => void;
}

const parse = (text: string) => {
  const safeText = escapeHTML(text);

  let result = safeText.replace(/\[(.+)\]\((.+)\)/gm, '<a href="$2" target="_blank">$1</a>');
  result = result.replace(/\*\*(.+)\*\*/, '<span class="bold">$1</span>');
  return result;
};

const TripChatMessageBody: React.FC<Props> = ({ message, onSubmitConfirmation }) => {
  const submitConfirmation = async () => {
    try {
      await Fetcher.put(`/orders/${message.order_id}/confirmations.json`, { user_ids: [] });
      alert('見積もり承諾しました。');
      onSubmitConfirmation();
    } catch (e) {
      alert('通信環境が不安定です。\n時間をおいてもう一度お試しください。');
    }
  };

  return (
    <MessageBody>
      <pre data-wovn-ignore dangerouslySetInnerHTML={{ __html: parse(message.body) }} />
      {message.attach_files &&
        message.attach_files.map((img, i) => (
          <React.Fragment key={i}>
            <AttachedFileLink
              id={`file_img_id_${i}`}
              href={`/trips/${message.trip_id}/images/${img.file_path}?type=${message.type}`}
              target="_blank"
            >
              {img.file_name}を開く
            </AttachedFileLink>
            <br />
          </React.Fragment>
        ))}
      {message.show_accept_button && !message.accepted && (
        <>
          <p style={{ marginBottom: '10px' }}>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</p>
          <p>上記の内容で問題なければ、下記の「見積もりを承諾する」ボタンをクリックしてください。</p>
          <p>
            ※現時点でまだ予約手配は完了していません。在庫・価格の変動が発生する場合がございますのでお早めにご確認ください。
          </p>
          <Button onClick={submitConfirmation} style={{ margin: '10px 0' }}>
            見積もりを承諾する
          </Button>
          <p>
            見積もり承諾完了後、事前申請機能をご利用の場合は旅程一覧画面から「承認申請」ボタンをクリックし、出張申請を実施してください。
          </p>
        </>
      )}
    </MessageBody>
  );
};

const MessageBody = styled.div`
  background-color: #eee;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  min-height: 90px;

  pre {
    margin: 0;
    padding: 0;
    background-color: transparent;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
`;

const AttachedFileLink = styled(A)`
  & + & {
    margin-top: 10px;
  }
`;

export default TripChatMessageBody;
