import React from 'react';
import { Route, Switch } from 'react-router-dom';

import type User from '@this/src/domain/user/user';
import InAdvanceApplications from '../in_advance_applications/in_advance_applications';
import InAdvanceApplicationDetail from '../in_advance_applications/in_advance_application_detail/in_advance_application_detail';
import TabledInAdvanceApplications from '../in_advance_applications/tabled_in_advance_applications';

export const ApplicationsPaths = [
  '/application/in_advance',
  '/application/in_advance/:id',
  '/application/approved/:id'
];

interface Props {
  user: User | null;
  availableOptions: string[];
}

interface State {}

class Applications extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    try {
      return (
        <Switch>
          <Route path="/application/in_advance/:id" component={InAdvanceApplicationDetail} />
          <Route
            path="/application/approved/:id"
            render={props => <InAdvanceApplicationDetail {...props} {...this.props} approved />}
          />
          {this.props.user?.organization?.enable_batch_approval ? (
            <Route
              exact
              path="/application/in_advance"
              render={props => <TabledInAdvanceApplications {...props} {...this.props} />}
            />
          ) : (
            <Route
              exact
              path="/application/in_advance"
              render={props => <InAdvanceApplications {...props} {...this.props} />}
            />
          )}
        </Switch>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default Applications;
