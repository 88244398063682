import React from 'react';

import A from '@this/components/shared/atoms/a';

interface Props {
  index: number;
  items: string[];
  onChange: (i: number) => void;
}

export default class SimpleSelectorCustom extends React.Component<Props> {
  handleItemClick(i: number, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.onChange(i);
  }

  getItemClass(i: number) {
    let c: string;
    c = 'simple-selector-custom__link';
    if (this.props.index === i) {
      c += ' selected';
    }
    return c;
  }

  render() {
    try {
      const { items } = this.props;
      return (
        <div className="simple-selector-custom">
          {items.map((item, i) => (
            <React.Fragment key={i}>
              {item.length > 0 && (
                <A className={this.getItemClass(i)} key={i} onClick={e => this.handleItemClick(i, e)}>
                  {item}
                </A>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}
