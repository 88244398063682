import React, { useCallback } from 'react';
import { styled } from '@this/constants/themes';
import type { TripSearchType } from '@this/src/domain/trip_report/trip_report_store';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { media } from '@this/components/shared/atoms/media';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import { Divider, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import type { TripForReport } from '@this/src/domain/trip_report/trip';
import Pagination from '@this/src/components/shared/pagination/pagination';
import LineClampBox from '@this/src/components/shared/text/line_clamp_box';
import { BodyTitle, LoadingWrap } from '../../shared/wrappers';

export const TripReportTripSelect: React.FC = () => {
  const store = useTripReportStore();
  const { serviceId, loading, selected, itemEditing, tripOptions, page, totalPages, tripSearchType } = store;

  const handleChange = useCallback(
    (_, value: string) => {
      if (value === 'true') {
        store.setItemEditing(true);
      } else {
        store.setSelected(value ? parseInt(value, 10) : null);
      }
    },
    [store.setSelected, store.setItemEditing]
  );

  const handleClick = useCallback(
    (searchType: TripSearchType) => {
      store.getSelect(1, searchType);
    },
    [store.getSelect]
  );

  const handleSearch = useCallback(
    (page: number) => {
      store.getSelect(page);
    },
    [store.getSelect]
  );

  return (
    <>
      <BodyTitle>報告する旅程を選択して経費の詳細を登録してください</BodyTitle>
      <Navigations>
        <SelectButtons>
          <SelectButton
            className={tripSearchType === 'history' ? 'selected' : ''}
            onClick={() => handleClick('history')}
          >
            終了した旅程
          </SelectButton>
          <SelectButton
            className={tripSearchType === 'ongoing' ? 'selected' : ''}
            onClick={() => handleClick('ongoing')}
          >
            手配中・進行中の旅程
          </SelectButton>
        </SelectButtons>
      </Navigations>
      <TripSelectWrap>
        <StyledRadioGroup value={selected || itemEditing.toString()} onChange={handleChange}>
          <StyledFormControlLabel
            value="true"
            disabled={loading}
            control={<Radio color="primary" />}
            label="手動で旅程を追加する"
          />
          {!loading && tripOptions.length > 0 && (
            <>
              {tripOptions.map(trip => (
                <StyledFormControlLabel
                  key={trip.id}
                  value={trip.id}
                  control={<Radio color="primary" disabled={trip.alreadyReported()} />}
                  label={<TripOption trip={trip} serviceId={serviceId} />}
                />
              ))}
            </>
          )}
        </StyledRadioGroup>
        {loading ? (
          <LoadingWrap>
            <SimpleLoading />
          </LoadingWrap>
        ) : (
          <>
            {tripOptions.length < 1 && (
              <LoadingWrap>
                <Error>報告できる旅程がみつかりませんでした</Error>
              </LoadingWrap>
            )}
            {tripOptions.length > 1 && (
              <Pagination currentPage={page} totalPage={totalPages} handleSearch={handleSearch} />
            )}
          </>
        )}
      </TripSelectWrap>
    </>
  );
};

const TripOption: React.FC<{ trip: TripForReport; serviceId: number }> = React.memo(
  ({ trip, serviceId }) => {
    const totalPrice = trip.totalPrice();
    const totalAdvance = trip.totalAdvance();
    const totalAmount = trip.totalAmount();

    return (
      <Grid container alignItems="flex-end">
        <Grid item xs={12} sm={8}>
          {trip.alreadyReported() && (
            <Row>
              <Red>報告済み</Red>
            </Row>
          )}
          <Row>
            {trip.startTime?.format('YYYY年M月D日')}∼{trip.endTime?.format('YYYY年M月D日')}
          </Row>
          <Row>
            {trip.from && <div>出発地：{trip.from}</div>}
            {trip.to && <div>目的地：{trip.to}</div>}
            {trip.finalDestination && <div>出張先：{trip.finalDestination}</div>}
          </Row>
          {trip.purpose && (
            <Row>
              <Flex>
                出張の目的：
                <LineClampBox row={1}>{trip.purpose}</LineClampBox>
              </Flex>
            </Row>
          )}
          <Row>
            <Gray>旅程番号：{trip.id}</Gray>
          </Row>
        </Grid>
        <StyledDivider />
        <Grid item xs={12} sm={4}>
          <PriceRow>
            <PriceLabel>システム手配金額</PriceLabel>
            <PriceValue>{totalPrice > 0 ? `${totalPrice.toLocaleString()}円` : <Gray>登録なし</Gray>}</PriceValue>
          </PriceRow>
          <PriceRow>
            <PriceLabel>{serviceId !== 2 ? '立替経費' : '自己手配経費'}</PriceLabel>
            <PriceValue>
              {totalAdvance > 0 ? `${totalAdvance.toLocaleString()}円` : <Gray>登録なし</Gray>}
            </PriceValue>
          </PriceRow>
          <PriceRow>
            <PriceLabel>合計金額</PriceLabel>
            <PriceValue>
              <Bold>{totalAmount.toLocaleString()}円</Bold>
            </PriceValue>
          </PriceRow>
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => prevProps.trip.id === nextProps.trip.id
);

const TripSelectWrap = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;

const Navigations = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  ${media.sp`
    flex-flow: column-reverse;
    gap: 24px;
  `}
`;

const SelectButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectButton = styled.div`
  padding: 5px 10px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &.selected {
    color: #ffffff;
    background-color: #374151;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

// flex-directionを有効にしているとバグるためflex-flowに差し替え
const StyledRadioGroup = styled(RadioGroup)`
  gap: 12px;
  flex-direction: inherit;
  flex-flow: column;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  border: solid 1px ${props => props.theme.grayBorderColor};
  border-radius: 4px;
  margin: 0;

  & > .MuiFormControlLabel-label {
    flex: 1;
    padding: 8px 0;
    padding-right: 16px;
  }
`;

const StyledDivider = styled(Divider)`
  display: none;
  width: 100%;
  margin: 8px 0;

  ${media.sp`
    display: block;
  `}
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  margin: 4px 0;

  ${media.sp`
    flex-flow: column;
    gap: 4px;
  `}
`;

const Flex = styled.div`
  display: flex;
`;

const Gray = styled.span`
  color: ${props => props.theme.grayTextColor};
`;

const Red = styled.span`
  color: ${props => props.theme.redColor};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin: 4px 0;

  ${media.sp`
    justify-content: space-between;
  `}
`;

const PriceLabel = styled.label`
  font-size: 0.9em;
  color: ${props => props.theme.grayTextColor};
`;

const PriceValue = styled.span`
  width: 100px;
`;
