import React from 'react';

import type OrganizationBase from '../../../../../../../domain/organization_base/organization_base';

type Props = {
  organizationBases: OrganizationBase[];
  onSelect: (organizationBase: OrganizationBase) => void;
};

type State = {
  filteredOrganizationBases: OrganizationBase[];
};

class OrganizationBasesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filteredOrganizationBases: this.props.organizationBases
    };
  }

  handleSelect = (department: OrganizationBase) => () => {
    this.props.onSelect(department);
  };

  handleSearchOrganizationBases = (e: any) => {
    const keyword = e.target.value;
    const filtered = this.props.organizationBases.filter(
      organizationBase => organizationBase.name.indexOf(keyword) >= 0
    );
    return this.setState({ filteredOrganizationBases: filtered });
  };

  render() {
    try {
      return (
        <div className="members-list">
          <input type="search" defaultValue="" placeholder="検索" onKeyUp={this.handleSearchOrganizationBases} />
          <ul>
            {this.state.filteredOrganizationBases.map((organizationBase, i) => (
              <li key={i} className="members-list__item" onClick={this.handleSelect(organizationBase)}>
                <div className="members-list__item__info">
                  <p>{organizationBase.description()}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default OrganizationBasesList;
