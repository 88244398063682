import React from 'react';
import { styled } from '@this/constants/themes';
import Tooltip from '@this/shared/tooltip/tooltip';
import type { PasswordPolicy } from './types';

type Props = {
  passwordPolicy?: PasswordPolicy;
  onChange: (passwordPolicy: PasswordPolicy) => void;
};

const PasswordPolicySettingArea: React.FC<Props> = ({ passwordPolicy, onChange }) => {
  return (
    <Container>
      <Control>
        <Label>
          <span>パスワード有効期限(日)</span>
          <Tooltip place="right">
            <p>
              前回のパスワード変更から指定の日数が経過した場合、パスワードの再設定を強制します。再設定を行わないとサービスを利用できません。
              値を設定しない場合、有効期限が無期限になります。
              <br />
              (例) 90日: パスワード変更から90日後にパスワード再設定を求める。
            </p>
          </Tooltip>
        </Label>
        <NumberText
          type="number"
          placeholder="例) 365"
          value={passwordPolicy?.expire_date ?? undefined}
          onChange={e => {
            const value = parseInt(e.target.value, 10);
            onChange({
              expire_date: isNaN(value) ? null : value,
              disable_generation_count: passwordPolicy?.disable_generation_count
            });
          }}
        />
      </Control>
      <Control>
        <Label>
          <span> パスワード無効世代</span>
          <Tooltip place="right">
            <p>
              指定の世代分、過去設定したパスワードを設定できないようにします。
              値を設定しない場合世代によるパスワードの制限を行いません。
              <br />
              (例) 3世代: 過去3回分のパスワードを再利用できない
            </p>
          </Tooltip>
        </Label>
        <NumberText
          type="number"
          placeholder="例) 3"
          value={passwordPolicy?.disable_generation_count ?? undefined}
          onChange={e => {
            const value = parseInt(e.target.value, 10);

            onChange({
              expire_date: passwordPolicy?.expire_date,
              disable_generation_count: isNaN(value) ? null : value
            });
          }}
        />
      </Control>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Control = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 70px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 200px;
  font-weight: bold;
  font-size: 11px;
  padding-top: 0;
`;

const NumberText = styled.input.attrs({ type: 'number' })`
  width: 40px !important;
  margin-bottom: 0 !important;
`;

export default PasswordPolicySettingArea;
