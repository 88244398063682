import { observable } from 'mobx';
import type { KnowledgeArgs, KnowledgeResponseArgs } from '@this/domain/knowledge';
import Knowledge, { convertKnowledgeResponseToArgs } from '@this/domain/knowledge';

export type HowToCategoryResponseArgs = {
  target: string;
  target_text: string;
  name: string;
  knowledges?: KnowledgeResponseArgs[];
};

export type HowToCategoryArgs = {
  target: string;
  targetText: string;
  name: string;
  knowledges?: KnowledgeArgs[];
};

export const convertHowToCategoryResponseToArgs = (response: HowToCategoryResponseArgs): HowToCategoryArgs => {
  return {
    target: response.target,
    targetText: response.target_text,
    name: response.name,
    knowledges: response.knowledges
      ? response.knowledges.map(raw => new Knowledge(convertKnowledgeResponseToArgs(raw)))
      : []
  };
};

export default class HowToCategory {
  @observable target: string;

  @observable targetText: string;

  @observable name: string;

  @observable knowledges: KnowledgeArgs[];

  constructor(args: HowToCategoryArgs) {
    const { target, targetText, name, knowledges = [] } = args;

    this.target = target;
    this.targetText = targetText;
    this.name = name;
    this.knowledges = knowledges;
  }
}
