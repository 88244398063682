import React from 'react';
import styled from 'styled-components';
import SCText from '@this/components/organization/text';
import type { ExicSeisanTripInterface } from './exic_seisan_table';

interface Props {
  serviceId: number;
  trip: ExicSeisanTripInterface;
}

const Item: React.FC<Props> = ({ serviceId, trip }) => (
  <Tr>
    <TrContent>
      <Td>
        <div className="text--primary">
          <SCText serviceId={serviceId} value={String(trip.id)} />
        </div>
      </Td>
      <Td>{utils.isTabikobo(serviceId) && <span>{trip.trip_category}</span>}</Td>
      <Td className="travelers">
        <span>{trip.traveler.name}</span>
      </Td>
      <Td>
        {trip.trip_route.map((route, routeIndex) => (
          <div key={routeIndex}>{route}</div>
        ))}
      </Td>

      <Td style={{ whiteSpace: 'nowrap' }}>{trip.total_price.toLocaleString()}円</Td>
      <Td>
        {trip.check_trip_rule.check ? (
          <>
            <TdCostCheckMarkSuccess>
              <img src="/images/organization/trips/check.png" alt="ok" />
            </TdCostCheckMarkSuccess>
            <TdCostCheckTextSuccess>問題なし</TdCostCheckTextSuccess>
          </>
        ) : (
          <RuleErrorTdContent>
            <TdCostCheckMarkDanger>
              <img src="/images/organization/trips/question.png" alt="question" />
            </TdCostCheckMarkDanger>
            <TdCostCheckTextDanger>
              <span>{trip.check_trip_rule.error}</span>
            </TdCostCheckTextDanger>
          </RuleErrorTdContent>
        )}
      </Td>
    </TrContent>
  </Tr>
);

const Tr = styled.div`
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
`;

const TrContent = styled.div`
  display: flex;
  align-items: center;
`;

const Td = styled.div<{ tripReport?: boolean }>`
  padding: 14px 0;
  font-weight: bold;

  small {
    display: block;
    font-weight: normal;
    font-size: 10px;
    margin-top: 4px;
  }

  &.travelers span + span {
    margin-left: 4px;
  }

  &:nth-child(1) {
    width: 8%;
  }
  &:nth-child(2) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '8%' : '0%')};
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: ${props => (props.theme.themeClass === 'tabikobo' ? '29%' : '37%')};
    padding-right: 10px;
  }
  &:nth-child(5) {
    width: 15%;
    text-align: right;
    padding-right: 20px;
  }
  &:nth-child(6) {
    width: 25%;
  }
`;

const TdCostCheckMark = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 6px;
  margin-right: 10px;
  vertical-align: middle;

  img {
    width: 15px;
    height: 15px;
  }
`;

const TdCostCheckMarkSuccess = styled(TdCostCheckMark)`
  background-color: #28a745;
`;

const TdCostCheckMarkDanger = styled(TdCostCheckMark)`
  background-color: ${props => props.theme.redColor};
`;

const TdCostCheckTextSuccess = styled.span`
  color: #28a745;
`;

const RuleErrorTdContent = styled.div`
  display: flex;
  align-items: center;
`;

const TdCostCheckTextDanger = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.redColor};

  .tooltip__content {
    width: 250px;
    top: auto;
    bottom: 110%;
  }
`;

export default Item;
