import { observable } from 'mobx';
import _ from 'lodash';
import type { ShareholderInfo } from '@this/domain/other_service_shareholder_info';
import type { IndividualTargetSuppliedItem } from '@this/domain/individual_target_supplied_item';
import OrderItem from '../order_item';
import Trip from '../trip/trip';
import BulkTicket from '../bulk_ticket2';
import type { BulkTicketArgs } from '../bulk_ticket2';
import ShareholderTicketList from '../shareholder_ticket_list';
import type { ShareholderTicketArgs } from '../shareholder_ticket';
import ShareholderTicket from '../shareholder_ticket';
import type { TaxTypeArgs } from '../tax_type';
import TaxType from '../tax_type';
import type { SuppliedItemArgs } from '../supplied_item/supplied_item';
import SuppliedItem from '../supplied_item/supplied_item';
import PaymentMethodList from '../payment_method/payment_method_list';
import type { PaymentMethodArgs } from '../payment_method/payment_method';
import type { OrderItemStepTodoArgs, OrderItemStepTodoResponseArgs } from './order_item_step_todo';
import OrderItemStepTodo, { convertOrderItemStepTodoResponseToArgs } from './order_item_step_todo';

export interface OrderItemStepTodoFieldStoreResponseArgs {
  service_id: number;
  todo: OrderItemStepTodoResponseArgs;
  order_item: {
    order: {
      trip: any;
    };
  };
  initial_order_item: {
    order: {
      trip: any;
    };
  };
  bulk_tickets: BulkTicketArgs[];
  shareholder_tickets: ShareholderTicketArgs[];
  tax_types: TaxTypeArgs[];
  supplied_items: SuppliedItemArgs[];
  payment_methods: PaymentMethodArgs[];
  shareholder_infos: ShareholderInfo[];
  individual_target_supplied_items: IndividualTargetSuppliedItem[];
}

export interface OrderItemStepTodoFieldStoreArgs {
  serviceId: number;
  todo: OrderItemStepTodoArgs;
  trip: any;
  orderItem: any;
  initialOrderItem: any;
  bulkTickets: BulkTicketArgs[];
  shareholderTickets: ShareholderTicketArgs[];
  taxTypes: TaxTypeArgs[];
  suppliedItems: SuppliedItemArgs[];
  paymentMethods: PaymentMethodArgs[];
  shareholderInfos: ShareholderInfo[];
  individualTargetSuppliedItems: IndividualTargetSuppliedItem[];
}

export const convertOrderItemStepTodoFieldStoreResponseArgs = (
  response: OrderItemStepTodoFieldStoreResponseArgs
) => {
  const args: OrderItemStepTodoFieldStoreArgs = {
    serviceId: response.service_id,
    todo: convertOrderItemStepTodoResponseToArgs(response.todo),
    trip: response.order_item.order.trip,
    orderItem: response.order_item,
    initialOrderItem: response.initial_order_item,
    bulkTickets: response.bulk_tickets,
    shareholderTickets: response.shareholder_tickets,
    taxTypes: response.tax_types,
    suppliedItems: response.supplied_items,
    paymentMethods: response.payment_methods,
    shareholderInfos: response.shareholder_infos,
    individualTargetSuppliedItems: response.individual_target_supplied_items
  };
  return args;
};

export class OrderItemStepTodoFieldStore {
  @observable serviceId: number;

  @observable todo: OrderItemStepTodo;

  @observable selectedTrip: Trip;

  @observable initialOrderItem: OrderItem;

  @observable orderItem: OrderItem;

  @observable orderItemDiff: any | null;

  @observable bulkTickets: BulkTicket[];

  @observable shareholderTickets: ShareholderTicketList;

  @observable taxTypes: TaxType[];

  @observable suppliedItems: SuppliedItem[];

  @observable paymentMethods: PaymentMethodList;

  @observable shareholderInfos: any;

  @observable individualTargetSuppliedItems: any;

  constructor(args: OrderItemStepTodoFieldStoreArgs) {
    this.serviceId = args.serviceId;
    this.todo = new OrderItemStepTodo(args.todo, this.serviceId);
    this.selectedTrip = new Trip(args.trip);
    this.initialOrderItem = new OrderItem(args.initialOrderItem || args.orderItem);
    this.orderItem = new OrderItem(args.orderItem);
    this.orderItemDiff = this.orderItem.diffWith(this.initialOrderItem);
    this.bulkTickets = args.bulkTickets.map(bulkTicketArgs => new BulkTicket(bulkTicketArgs));
    this.shareholderTickets = new ShareholderTicketList(
      args.shareholderTickets.map(shareholderTicketArgs => new ShareholderTicket(shareholderTicketArgs))
    );
    this.taxTypes = args.taxTypes.map(taxTypeArgs => new TaxType(taxTypeArgs));
    this.suppliedItems = args.suppliedItems.map(suppliedItemArgs => new SuppliedItem(suppliedItemArgs));
    this.paymentMethods = new PaymentMethodList(args.paymentMethods);
    this.shareholderInfos = args.shareholderInfos;
    this.individualTargetSuppliedItems = args.individualTargetSuppliedItems;

    this.classNameForModifiedField = this.classNameForModifiedField.bind(this);
    this.changed = this.changed.bind(this);
  }

  changed() {
    this.orderItemDiff = this.orderItem.diffWith(this.initialOrderItem);
  }

  inputFields() {
    return this.todo.orderItemStepTodoInputFields.fields();
  }

  isPriceForm() {
    return this.todo.orderItemStepTodoInputFields.isOrderItemPriceForm;
  }

  viewFields() {
    return this.todo.orderItemStepTodoViewFields.fields();
  }

  getProps() {
    return {
      index: 0,
      selectedTrip: this.selectedTrip,
      orderItem: this.orderItem,
      bulkTickets: this.bulkTickets,
      shareholderTickets: this.shareholderTickets,
      taxTypes: this.taxTypes,
      suppliedItems: this.suppliedItems,
      paymentMethods: this.paymentMethods,
      shareholderInfos: this.shareholderInfos,
      individualTargetSuppliedItems: this.individualTargetSuppliedItems
    };
  }

  classNameForModifiedField = (...path: (string | number)[]): string => {
    // 右ナビでorder_itemを追加した場合はinitialOrderItemが存在せず、orderItemDiffもない
    // したがってその場合は全項目変更されたものとする
    const modified = this.orderItemDiff ? _.has(this.orderItemDiff, path) : true;
    return modified ? 'virtual-counte-price-change-form__modified_field' : '';
  };

  updateParams() {
    // バリデーションで使用するためinputFieldsを入れているが、viewFieldsは必要ない
    return {
      order_item_step_id: this.todo.id,
      action_type: 'complete',
      trip_id: this.selectedTrip.id,
      deleted_trace_ids: [],
      ...this.todo.orderItemStepTodoInputFields.updateParams(this.orderItem)
    };
  }
}
