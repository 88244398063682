import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  isActive: boolean;
  isBallon: boolean;
}

const DashboardButton: React.SFC<Props> = ({ isActive, isBallon, children }) => (
  <Balloon isActive={isActive} isBallon={isBallon}>
    {children}
  </Balloon>
);

const Balloon = styled.div<{ isActive: boolean; isBallon: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 249px;
  height: 64px;
  background-color: ${props => (props.isActive ? '#af985e' : '#f7f7f7')};
  color: ${props => (props.isActive ? '#fff' : '#af985e')};
  ${props =>
    props.isBallon &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -15px;
      border: 15px solid transparent;
      border-top: 15px solid ${props.isActive ? '#af985e' : '#f7f7f7'};
    }
  `}
`;

export default DashboardButton;
