/* eslint-disable max-lines */
import React from 'react';
import { styled } from '@this/constants/themes';
import { ButtonBase } from '@this/shared/atoms/button';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Tooltip from '@this/shared/tooltip/tooltip';

import ChargingDepartmentShare from '@this/domain/department/charging_department_share';
import ChargingDepartmentShareMapping from '@this/domain/department/charging_department_share_mapping';
import ChargingDepartmentShareList from '@this/domain/department/charging_department_share_list';
import {
  OrganizationTable,
  OrganizationTh,
  OrganizationButtonTh,
  OrganizationTd,
  OrganizationTdButton,
  OrganizationDisableTd,
  DisableTdButton,
  AddButton
} from '@this/components/organization/organization.style';
import { DeleteMasterButton } from '@this/components/organization/delete-master-button';
import { MasterListContainer } from '@this/components/organization/master_list_container';
import { DepartmentSharesFilter } from '@this/components/organization/departments/department_shares_filter';
import Notification from '../../../notification';

interface Props {}

interface State {
  shares: ChargingDepartmentShareList;
  departments: SharableDepartment[];
  editingShare: ChargingDepartmentShare | null;
  loading: boolean;
  error: string | null;
  saveError: string | null;
  filterFormText: string;
  filterDisabled: boolean;
  totalPage: number;
}

interface SharesResponse {
  chargingDepartmentShares: any;
  total_page: number;
}

interface CreateResponse {
  id: number;
}

interface UpdateResponse {}

interface SharableDepartment {}

class ChargingDepartmentSharesBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shares: new ChargingDepartmentShareList([]),
      departments: [],
      editingShare: null,
      loading: false,
      error: null,
      saveError: null,
      filterFormText: '',
      filterDisabled: false,
      totalPage: 1
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchDepartmentShares();
    this.fetchDepartment();
  }

  fetchDepartmentShares(page = 1, text = '', disabled = false) {
    utils
      .jsonPromise<SharesResponse>('/organization/departments/shares', { page, text, disabled })
      .then(
        result => {
          this.setState({
            shares: new ChargingDepartmentShareList(result.chargingDepartmentShares),
            loading: false,
            totalPage: result.total_page,
            filterFormText: text,
            filterDisabled: disabled
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  fetchDepartment = async () => {
    try {
      const { departments } = await utils.jsonPromise<{ departments: SharableDepartment[] }>(
        '/organization/departments/shares/sharable_departments'
      );
      this.setState({ departments });
    } catch (e) {
      this.setState({
        error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
        loading: false
      });
      utils.sendErrorObject(e);
    }
  };

  handleAddShareClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const share = new ChargingDepartmentShare({} as any);
    this.state.shares.push(share);
    this.setState({
      editingShare: share
    });
  }

  handleShareNameInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.editingShare) {
      this.state.editingShare.setName(e.target.value);
    }
  }

  handleShareCodeInput(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.editingShare) {
      this.state.editingShare.setCode(e.target.value);
    }
  }

  changeShareDisabledRadio(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.state.editingShare) {
      this.state.editingShare.setDisabled(e.target.value === 'disabled');
    }
  }

  handleAddChargingDepartmentShareMappingClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.state.editingShare) {
      const departmentShareMapping = new ChargingDepartmentShareMapping({} as any);
      this.state.editingShare.chargingDepartmentShareMappings.push(departmentShareMapping);
      app.render();
    }
  }

  handleChargingDepartmentShareMappingDepartmentChange(index: number, value: number) {
    if (this.state.editingShare) {
      this.state.editingShare.chargingDepartmentShareMappings.list[index].setDepartmentId(value);
    }
  }

  handleChargingDepartmentShareMappingWeightChange(index: number, value: number) {
    if (this.state.editingShare) {
      this.state.editingShare.chargingDepartmentShareMappings.list[index].setWeight(value);
    }
  }

  handleEditShareClick(share: ChargingDepartmentShare, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({
      editingShare: share.clone()
    });
  }

  handleCancelEditShareClick() {
    // remove only new pro
    if (this.state.editingShare && !this.state.editingShare.id) {
      this.state.shares.remove(this.state.editingShare);
    }
    this.setState({
      editingShare: null
    });
  }

  handleEditShareSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    this.setState({
      saveError: null
    });

    let errorStr: string | null = null;
    if (this.state.editingShare) {
      if (!this.state.editingShare.name) {
        errorStr = '按分名が設定されていません。';
      } else if (!this.state.editingShare.code) {
        errorStr = '按分コードが設定されていません。';
      } else if (this.state.editingShare.chargingDepartmentShareMappings.list.length === 0) {
        errorStr = '部署が設定されていません。';
      } else {
        this.state.editingShare.chargingDepartmentShareMappings.list.forEach(
          (mapping: ChargingDepartmentShareMapping) => {
            if (!mapping.departmentId) {
              errorStr = '部署コードが設定されていません。';
            } else if (!mapping.weight) {
              errorStr = '重みが設定されていません。';
            } else if (mapping.weight < 1 || mapping.weight > 10) {
              errorStr = '重みは1〜10に設定してください。';
            }
          }
        );
      }
      if (errorStr !== null) {
        this.setState({
          saveError: errorStr
        });
        return;
      }

      if (this.state.editingShare.id) {
        this.submitShareUpdate();
      } else {
        this.submitShareCreate();
      }
    }
  };

  submitShareCreate() {
    utils
      .jsonPromise<CreateResponse>(
        '/organization/departments/shares',
        this.state.editingShare?.submitParams(),
        'POST'
      )
      .then(
        () => {
          this.setState({
            editingShare: null
          });
          this.fetchDepartmentShares(1, '', this.state.filterDisabled);
        },
        error => {
          this.setState({
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  submitShareUpdate() {
    utils
      .jsonPromise<UpdateResponse>(
        `/organization/departments/shares/${this.state.editingShare?.id}`,
        this.state.editingShare?.submitParams(),
        'PUT'
      )
      .then(
        _result => {
          this.setState({
            editingShare: null
          });
          this.fetchDepartmentShares(1, '', this.state.filterDisabled);
        },
        error => {
          this.setState({
            saveError:
              error.status === 400
                ? utils.dig(error, 'responseJSON', 'errors')
                : error.status === 403
                ? 'この按分は設定済みのため編集できません'
                : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  grayIfNeeded = (disabled: boolean) => {
    return {
      background: disabled ? '#f2f2f3' : '#ffffff'
    };
  };

  deleteDepartmentShare = (share: ChargingDepartmentShare) => {
    utils
      .jsonPromise(`/organization/departments/shares/${share.id}`, {}, 'DELETE')
      .then(() => {
        this.fetchDepartmentShares(1, '', this.state.filterDisabled);
      })
      .catch(e => {
        Notification.error(
          e.status === 400
            ? utils.dig(e, 'responseJSON', 'errors')
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。'
        );
        utils.sendErrorObject(e);
      });
  };

  render() {
    const { shares, filterFormText, filterDisabled, totalPage } = this.state;

    return (
      <>
        <DepartmentSharesFilter onSearch={(text, disabled) => this.fetchDepartmentShares(1, text, disabled)} />
        <MasterListContainer
          totalPage={totalPage}
          onPaginate={page => this.fetchDepartmentShares(page, filterFormText, filterDisabled)}
          createButton={<AddButton onClick={e => this.handleAddShareClick(e)}>按分を追加</AddButton>}
          renderContent={() => (
            <OrganizationTable>
              <tbody>
                <tr>
                  <OrganizationTh>按分コード</OrganizationTh>
                  <OrganizationTh>按分名</OrganizationTh>
                  <OrganizationTh>按分内容</OrganizationTh>
                  <OrganizationButtonTh>編集</OrganizationButtonTh>
                  <OrganizationButtonTh>削除</OrganizationButtonTh>
                </tr>

                {this.state.loading ? (
                  <tr>
                    <td colSpan={11}>
                      <SimpleLoading />
                    </td>
                  </tr>
                ) : this.state.error ? (
                  <tr>
                    <td colSpan={11}>
                      <Error>{this.state.error}</Error>
                    </td>
                  </tr>
                ) : (
                  <>
                    {shares.list.map((s: ChargingDepartmentShare, i: number) => (
                      <tr style={this.grayIfNeeded(s.disabled)} key={i} data-e2e-id={s.id}>
                        <OrganizationTd className="e2e-code">{s.code}</OrganizationTd>
                        <OrganizationTd className="e2e-name">{s.name}</OrganizationTd>
                        <OrganizationTd className="e2e-share-label">{s.createShareLabel()}</OrganizationTd>
                        {s.editable ? (
                          <OrganizationTd>
                            <OrganizationTdButton onClick={e => this.handleEditShareClick(s, e)}>
                              編集
                            </OrganizationTdButton>
                          </OrganizationTd>
                        ) : (
                          <OrganizationDisableTd>
                            <DisableTdButton disabled>編集</DisableTdButton>
                            <Tooltip type="info" place="left">
                              <p>請求内容に影響を与えるため、旅程と紐づいた按分内容は編集できません。</p>
                            </Tooltip>
                          </OrganizationDisableTd>
                        )}
                        <OrganizationTd>
                          <DeleteMasterButton
                            className="e2e-confirm-delete"
                            targetLabel={s.name}
                            onConfirm={() => {
                              this.deleteDepartmentShare(s);
                            }}
                          />
                        </OrganizationTd>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </OrganizationTable>
          )}
        />

        {this.state.editingShare && (
          <Modal
            show
            className="e2e-modal-edit"
            hideModal={() => this.handleCancelEditShareClick()}
            title="部署按分を追加する"
          >
            <form onSubmit={this.handleEditShareSubmit}>
              <ShareEdit>
                <ShareEditSection>
                  <ShareEditInputArea>
                    <ShareEditInputLabel htmlFor="shareName">按分名</ShareEditInputLabel>
                    <input
                      id="shareName"
                      type="text"
                      onChange={e => this.handleShareNameInput(e)}
                      defaultValue={this.state.editingShare.name}
                    />
                  </ShareEditInputArea>
                  <ShareEditInputArea>
                    <ShareEditInputLabel htmlFor="shareCode">按分コード</ShareEditInputLabel>
                    <input
                      id="shareCode"
                      type="text"
                      onChange={e => this.handleShareCodeInput(e)}
                      defaultValue={this.state.editingShare.code}
                    />
                  </ShareEditInputArea>
                  <ShareEditInputArea>
                    <ShareEditInputLabel>状態</ShareEditInputLabel>
                    <Flex>
                      <ShareEditRadioValue>
                        <input
                          type="radio"
                          value="enabled"
                          onChange={e => this.changeShareDisabledRadio(e)}
                          checked={!this.state.editingShare.disabled}
                        />
                        <span>有効</span>
                      </ShareEditRadioValue>
                      <ShareEditRadioValue>
                        <input
                          type="radio"
                          value="disabled"
                          onChange={e => this.changeShareDisabledRadio(e)}
                          checked={this.state.editingShare.disabled}
                        />
                        <span>無効</span>
                      </ShareEditRadioValue>
                    </Flex>
                  </ShareEditInputArea>
                </ShareEditSection>
                <ShareEditSection>
                  <ShareEditInputArea>
                    <ShareEditInputLabel>部署</ShareEditInputLabel>
                    {this.state.editingShare.chargingDepartmentShareMappings.list.map(
                      (departmentShareMapping: ChargingDepartmentShareMapping, index: number) => (
                        <ChargingDepartmentShareMappingsEdit key={index} className="e2e-share-mapping">
                          <select
                            id="departmentId"
                            className="e2e-department-id"
                            onChange={e =>
                              this.handleChargingDepartmentShareMappingDepartmentChange(
                                index,
                                Number(e.target.value)
                              )
                            }
                            defaultValue={departmentShareMapping.departmentId}
                          >
                            <option>設定してください</option>
                            {this.state.departments.map((p: any, i: number) => (
                              <option key={i} value={p.id}>
                                {p.code}
                              </option>
                            ))}
                          </select>
                          <input
                            id="weight"
                            className="e2e-weight"
                            type="text"
                            placeholder="按分の重み"
                            onChange={e =>
                              this.handleChargingDepartmentShareMappingWeightChange(
                                index,
                                parseInt(e.target.value, 10)
                              )
                            }
                            defaultValue={departmentShareMapping.weight}
                          />
                        </ChargingDepartmentShareMappingsEdit>
                      )
                    )}
                    <ChargingDepartmentShareMappingsAdd>
                      <a onClick={e => this.handleAddChargingDepartmentShareMappingClick(e)}>+部署を追加</a>
                    </ChargingDepartmentShareMappingsAdd>
                  </ShareEditInputArea>
                </ShareEditSection>
              </ShareEdit>
              <ModalButtons>
                <Error>{this.state.saveError}</Error>
                <ModalButton type="submit" value="保存" />
                <ModalButton type="reset" value="キャンセル" onClick={e => this.handleCancelEditShareClick()} />
              </ModalButtons>
            </form>
          </Modal>
        )}
      </>
    );
  }
}

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ShareEdit = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShareEditSection = styled.div`
  width: 48%;
  padding: 5px;
`;

const ShareEditInputArea = styled.div`
  margin-bottom: 10px;
`;

const ShareEditInputLabel = styled.label`
  font-size: 13px;
  font-weight: normal;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ModalButton = styled.input`
  &&& {
    ${ButtonBase};
    width: 100px;
    margin: 0 0 0 20px;
    padding: 0.75em 1em;
  }
`;

const ShareEditRadioValue = styled.label`
  width: 80px;
  > span {
    padding-left: 4px;
  }
`;

const ChargingDepartmentShareMappingsEdit = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  select {
    margin-bottom: 0.75em;
  }
`;

const ChargingDepartmentShareMappingsAdd = styled.div`
  text-align: right;
  a {
    color: ${props => props.theme.linkColor};
  }
`;

const Flex = styled.div`
  display: flex;
`;

export default ChargingDepartmentSharesBlock;
/* eslint-enable max-lines */
