import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';

import type { SmartHrImportActionResponseArgs } from '@this/domain/smart_hr_import_action';
import SmartHrImportAction, {
  convertSmartHrImportActionResponseToArgs
} from '@this/domain/smart_hr_import_action';

import Notification from '@this/src/notification';
import {
  OrganizationTable,
  OrganizationTh,
  OrganizationTd,
  OrganizationTitle
} from '@this/components/organization/organization.style';
import { TableBody, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type Props = any;

type Response = {
  smart_hr_import_actions: SmartHrImportActionResponseArgs[];
};

const SmartHrImportActions: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [smartHrImportActions, setSmartHrImportActions] = useState<SmartHrImportAction[]>([]);

  const fetchSmartHrImportAction = async () => {
    setLoading(true);
    setError('');
    const params = {};
    await utils
      .jsonPromise<Response>('/organization/smart_hr/import_actions.json', params)
      .then(result => {
        setSmartHrImportActions(
          result.smart_hr_import_actions.map(
            r => new SmartHrImportAction(convertSmartHrImportActionResponseToArgs(r))
          )
        );
      })
      .catch(e => {
        const errorMessage =
          e.status === 400 && e.responseJSON?.errors?.message
            ? e.responseJSON.errors.message
            : '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        Notification.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSmartHrImportAction();
  }, []);

  const getStatusClass = (data: any): string =>
    data.isSuccess() || data.isFinish() ? 'green' : data.isFail() ? 'red' : 'gray';

  return (
    <>
      <OrganizationTitle>SmartHR連携 実行履歴</OrganizationTitle>
      <BackLinkSection>
        <Link href="/organization/members">
          <ArrowBackIcon />
          社員マスタへ戻る
        </Link>
      </BackLinkSection>
      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Wrap>
          <OrganizationTable>
            <TableHead>
              <TableRow>
                <OrganizationTh>連携実行ユーザー</OrganizationTh>
                <OrganizationTh>連携日時</OrganizationTh>
                <OrganizationTh>ステータス</OrganizationTh>
                <OrganizationTh>エラー</OrganizationTh>
                <OrganizationTh>詳細</OrganizationTh>
              </TableRow>
            </TableHead>
            <TableBody>
              {smartHrImportActions.map(data => (
                <TableRow key={data.id}>
                  <OrganizationTd>{data.userName}</OrganizationTd>
                  <OrganizationTd>{data.finishedAt}</OrganizationTd>
                  <OrganizationTd className={`organization__td ${getStatusClass(data)}`}>
                    {data.statusMessage()}
                  </OrganizationTd>
                  <OrganizationTd>{data?.errorMessage?.message}</OrganizationTd>
                  <OrganizationTd>
                    <Link
                      style={{ fontWeight: 'bold' }}
                      href={`/organization/member/smart_hr/import_actions/${data.id}`}
                    >
                      詳細
                    </Link>
                  </OrganizationTd>
                </TableRow>
              ))}
            </TableBody>
          </OrganizationTable>
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div`
  padding: 10px;
`;

const BackLinkSection = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export default SmartHrImportActions;
