import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  steps: string[];
  index: number;
  type?: 'thin';
  onChange?: (index: number) => void;
}

class StepBar extends React.Component<Props> {
  handleChange = (index: number) => {
    if (this.props.onChange) {
      this.props.onChange(index);
    }
  };

  getClassForIndex = (i: number) => {
    let c: string;
    c = 'step-bar__step';
    if (this.props.index === i) {
      c += ' current';
    }
    if (this.props.type === 'thin') {
      c += ' thin';
    }
    return c;
  };

  getInnerClassForIndex = (i: number) => {
    let c: string;
    c = 'step-bar__step-in';
    if (this.props.index === i) {
      c += ' current';
    }
    if (this.props.type === 'thin') {
      c += ' thin';
    }
    return c;
  };

  getClassName = (i: number) => {
    if (this.props.index === i) {
      return 'current';
    }
    return 'other';
  };

  getInnerId = (i: number) => {
    if (this.props.steps.length === i + 1) {
      return 'inner-last';
    }
    return `inner-${i}`;
  };

  render() {
    try {
      const { steps } = this.props;
      return (
        <StepBarDiv>
          {steps.map((s, i) => (
            <StepBarItem
              key={i}
              type={this.props.type}
              onClick={() => this.handleChange(i)}
              className={this.getClassName(i)}
            >
              <>
                {this.props.type === 'thin' ? (
                  <ThinStepBarInner className={this.getClassName(i)} id={this.getInnerId(i)}>
                    {s}
                  </ThinStepBarInner>
                ) : (
                  <NormalStepBarInner className={this.getClassName(i)} id={this.getInnerId(i)}>
                    {s}
                  </NormalStepBarInner>
                )}
              </>
            </StepBarItem>
          ))}
        </StepBarDiv>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const stepBarSpacing = '6px';

const StepBarDiv = styled.div`
  display: flex;
  background: #eee;
`;

const StepBarItem = styled.div<{ type: 'thin' | undefined }>`
  flex: 1 1 100%;
  font-size: ${props => (props.type === 'thin' ? '13px' : '18px')};
  position: relative;
  font-weight: bold;
  margin-right: ${stepBarSpacing};
  background: ${props => {
    return props.className === 'current' ? props.theme.tokens.colors.brand.primary : 'white';
  }};
  color: ${props => (props.className === 'current' ? 'white' : '#404040')};
  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    width: 0;
    height: 0;
    border-color: transparent transparent transparent #eee;
    z-index: 1;
    border-style: solid;
    border-width: ${props => (props.type === 'thin' ? '15px 0 15px 10px;' : '24px 0 24px 20px;')};
    transform: translateX(${stepBarSpacing});
  }
  &:last-child {
    margin-right: 0;
    &:after {
      border: 0;
    }
  }
`;

const ThinStepBarInner = styled.div`
  padding: 5px;
  border-color: ${props => (props.className === 'current' ? props.theme.tokens.colors.brand.primary : 'white')};
  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    width: 0;
    height: 0;
    border-color: transparent;
    z-index: 2;
    border-style: solid;
    border-width: 15px 0 15px 10px;
    border-left-color: ${props =>
      props.className === 'current' ? props.theme.tokens.colors.brand.primary : 'white'};
    border: ${props => props.id === 'inner-last' && '0'};
  }
`;

const NormalStepBarInner = styled.div`
  position: relative;
  padding: 10px;
  border-color: ${props => (props.className === 'current' ? props.theme.tokens.colors.brand.primary : 'white')};
  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    content: '';
    width: 0;
    height: 0;
    border-color: transparent;
    z-index: 2;
    border-style: solid;
    border-width: 24px 0 24px 20px;
    border-left-color: ${props =>
      props.className === 'current' ? props.theme.tokens.colors.brand.primary : 'white'};
    border: ${props => props.id === 'inner-last' && '0'};
  }
`;

export default StepBar;
