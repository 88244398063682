import React from 'react';
import { Popover, useMediaQuery } from '@material-ui/core';
import type TripRuleError from '@this/domain/trip/trip_rule_error';
import { styled } from '@this/constants/themes';
import { media, sizes } from '@this/components/shared/atoms/media';

interface Props {
  ruleErrors: TripRuleError[];
}

const RuleErrors: React.FC<Props> = ({ ruleErrors, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const sp = useMediaQuery(`(max-width: ${sizes.sp}px)`);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!sp) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (!sp) setAnchorEl(null);
  };

  return (
    <>
      <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {children}
      </div>
      <SpBox>
        <ul>
          {ruleErrors.map((e, i: number) => (
            <li key={i.toString()}>{e.ruleErrorText()}</li>
          ))}
        </ul>
      </SpBox>
      <Popover
        style={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          variant: 'outlined',
          style: { width: 300, fontSize: 12, padding: 8, whiteSpace: 'pre-wrap', backgroundColor: '#eee' }
        }}
      >
        <ul>
          {ruleErrors.map((e, i: number) => (
            <li key={i.toString()}>{e.ruleErrorText()}</li>
          ))}
        </ul>
      </Popover>
    </>
  );
};

const SpBox = styled.div`
  display: none;
  font-size: 0.9em;
  padding-left: 18px;

  ${media.sp`
    display: block;
  `}
`;

export default RuleErrors;
