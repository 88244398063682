import React from 'react';

import {
  OrganizationTable,
  OrganizationTd,
  OrganizationTh
} from '@this/components/organization/organization.style';
import type ExicReport from '../../../../domain/exic_report';

interface State {}
interface Props {
  reports: ExicReport[];
}

class ExicReportList extends React.Component<Props, State> {
  render() {
    const { reports } = this.props;
    try {
      return (
        <OrganizationTable>
          <thead>
            <tr>
              <OrganizationTh>旅程番号</OrganizationTh>
              <OrganizationTh>お預かり番号</OrganizationTh>
              <OrganizationTh>EXIC登録ユーザ</OrganizationTh>
              <OrganizationTh>乗車日</OrganizationTh>
              <OrganizationTh>券種</OrganizationTh>
              <OrganizationTh>利用区間</OrganizationTh>
              <OrganizationTh>購入日</OrganizationTh>
              <OrganizationTh>金額</OrganizationTh>
            </tr>
          </thead>
          <tbody>
            {reports.map(report => {
              return (
                <tr key={report.seqNumber.toString()}>
                  <OrganizationTd>{report.tripId}</OrganizationTd>
                  <OrganizationTd>{report.seqNumber}</OrganizationTd>
                  <OrganizationTd>{report.userName}</OrganizationTd>
                  <OrganizationTd>{report.boardingDate}</OrganizationTd>
                  <OrganizationTd>{report.ticketType}</OrganizationTd>
                  <OrganizationTd>
                    {report.departureStation} ~ {report.arrivalStation}
                  </OrganizationTd>
                  <OrganizationTd>{report.purchaseDate}</OrganizationTd>
                  <OrganizationTd>{report.amount}</OrganizationTd>
                </tr>
              );
            })}
          </tbody>
        </OrganizationTable>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default ExicReportList;
