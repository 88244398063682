import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import A from '@this/components/shared/atoms/a';

import CSSTransition from 'react-transition-group/CSSTransition';
import { Text } from '@this/components/shared/ui/data_displays/typography';
import type User from '@this/domain/user/user';
import TransitList from '../../../../domain/transit/transit_list';
import FlightList from '../../../../domain/flight/flight_list';

import SelectTransportBox from '../transport_box/transport_box';
import SelectFlightBox from '../flight_box/flight_box';
import SelectHotelBox from '../hotel_box/hotel_box';
import { BoxButtonArea, BoxButton } from '../transport_box/right/right_in_outline.template';
import { BoxButtonText } from '../shared/box_parts';

import type { SelectOutlineItemProps } from './outline_item';
import HotelList from '../../../../domain/hotel/hotel_list';
import type { Tab } from '../../../../domain/select_store';
import type SelectStore from '../../../../domain/select_store';
import HotelSelectedReason from './hotel_selected_reason';

type Props = SelectOutlineItemProps & {
  // SelectOutlineItem.methods
  handleCssTransitionExited: () => void;
  store: SelectStore;
  direction?: string; // 航空券の時のみ入る。行き: 'out' か帰り: 'home'
  isNeedTexts: string[];
  theme: { serviceName: string };
};

const handleSearchAgain = (
  handleClickTab: (tab: Tab) => void,
  handleOpenShowSearchBox: () => void,
  selected: boolean,
  tab: Tab
) => {
  handleClickTab(selected ? 'none' : tab);
  handleOpenShowSearchBox();
};

const SelectOutlineItemTemplate: React.SFC<Props> = ({
  icon,
  item,
  handleClickTab,
  selected,
  hotelPriceLimit,
  handleCssTransitionExited,
  store,
  direction,
  isNeedTexts,
  isRequestForm,
  handleOpenShowSearchBox,
  isTripsForm,
  theme
}) => (
  <div className="select-outline__item-block">
    {item.elementType() === 'transport' && (!item.elementList || item.elementList instanceof TransitList)
      ? [
          <Header key={0}>
            <Icon>{icon}</Icon>
            <Date>
              <span>交通機関：</span>
              <span data-wovn-ignore>{item.queryItem.outdate}</span>
            </Date>
            {isTripsForm && item.isNeed && (
              <CheckLabel htmlFor={`transport_${item.index}`}>
                <input
                  type="checkbox"
                  id={`transport_${item.index}`}
                  checked={!item.isNonOrderItem}
                  onChange={() => item.toggleNonOrderItem()}
                />
                システムで手配
              </CheckLabel>
            )}
            {item.type !== 'airPackage' && !isRequestForm && (
              <Remove onClick={item.toggleNeed}>{item.isNeed ? isNeedTexts[0] : isNeedTexts[1]}</Remove>
            )}
          </Header>,
          <Body key={1} className="select-outline__item-block__body">
            {item.isNeed &&
              (item.loadingWithPair() ? (
                [
                  <img key={0} className="select__loading" src="/images/loading.gif" width={50} height={50} />,
                  <div key={1} className="select__loading-text">
                    経路を検索中です
                  </div>
                ]
              ) : item.error ? (
                <Result>
                  <Message>
                    {item.error.split('\n').map((l, i) => (
                      <Text color="danger" key={i}>
                        {l}
                      </Text>
                    ))}
                  </Message>
                  {!isRequestForm && (
                    <BoxButtonArea>
                      <BoxButton
                        selected={selected}
                        onClick={() =>
                          handleSearchAgain(handleClickTab, handleOpenShowSearchBox, selected, item.index)
                        }
                      >
                        <BoxButtonText>
                          {selected ? ['再検索を', <br key={0} />, 'キャンセル'] : '再検索 >'}
                        </BoxButtonText>
                      </BoxButton>
                    </BoxButtonArea>
                  )}
                </Result>
              ) : !item.elementList || item.elementList.list.length === 0 ? (
                <Result>
                  <Message>
                    <p>予約可能な交通機関はありません</p>
                  </Message>
                  {!isRequestForm && (
                    <BoxButtonArea>
                      <BoxButton
                        selected={selected}
                        onClick={() =>
                          handleSearchAgain(handleClickTab, handleOpenShowSearchBox, selected, item.index)
                        }
                      >
                        <BoxButtonText>
                          {selected ? ['再検索を', <br key={0} />, 'キャンセル'] : '再検索 >'}
                        </BoxButtonText>
                      </BoxButton>
                    </BoxButtonArea>
                  )}
                </Result>
              ) : !item.elementList.current() ? (
                <div className="select-pc__no-results">
                  <p>空席がありません</p>
                </div>
              ) : (
                <CSSTransition
                  in={item.elementList.showBox}
                  classNames="select-animation-box"
                  timeout={500}
                  onExited={handleCssTransitionExited}
                >
                  <SelectTransportBox
                    transport={item.elementList.current()!}
                    inList={false}
                    handleClickTab={handleClickTab}
                    tab={item.index}
                    selected={selected}
                    domesticAirPriceIndex={item.elementList.domesticAirPriceIndex || 0}
                    store={store}
                    direction={direction}
                    isRequestForm={isRequestForm}
                  />
                </CSSTransition>
              ))}
          </Body>
        ]
      : item.elementType() === 'flight' && (!item.elementList || item.elementList instanceof FlightList)
      ? [
          <Header key={0}>
            <Icon>{icon}</Icon>
            <Date>
              <span>航空券：</span>
              <span data-wovn-ignore>{item.queryItem.outdate}</span>
            </Date>
            {isTripsForm && item.isNeed && (
              <CheckLabel htmlFor={`flight_${item.index}`}>
                <input
                  type="checkbox"
                  id={`transport_${item.index}`}
                  checked={!item.isNonOrderItem}
                  onChange={() => item.toggleNonOrderItem()}
                />
                システムで手配
              </CheckLabel>
            )}
            {!isRequestForm && (
              <Remove onClick={item.toggleNeed}>{item.isNeed ? isNeedTexts[0] : isNeedTexts[1]}</Remove>
            )}
          </Header>,
          <Body key={1}>
            {item.isNeed &&
              (item.loading ? (
                [
                  <img key={0} className="select__loading" src="/images/loading.gif" width={50} height={50} />,
                  <div key={1} className="select__loading-text">
                    航空券を検索中です
                  </div>
                ]
              ) : item.error ? (
                item.error.split('\n').map((l, i) => (
                  <p className="error" key={i}>
                    {l}
                  </p>
                ))
              ) : !item.elementList || item.elementList.list.length === 0 || item.flightIndex == null ? (
                <div className="select-pc__no-results">予約可能な交通機関はありません</div>
              ) : (
                <CSSTransition
                  in={item.elementList.showBox}
                  classNames="select-animation-box"
                  timeout={500}
                  onExited={handleCssTransitionExited}
                >
                  <SelectFlightBox
                    flight={item.elementList.currentFlightCandidate(item.flightIndex)}
                    inList={false}
                    handleClickTab={handleClickTab}
                    tab={item.index}
                    flightIndex={item.flightIndex}
                    selected={selected}
                    isRequestForm={isRequestForm}
                    isDomestic={store.result.isDomesticAirResult()}
                  />
                </CSSTransition>
              ))}
          </Body>
        ]
      : item.elementType() === 'hotel' && (!item.elementList || item.elementList instanceof HotelList)
      ? [
          <Header key={0}>
            <Icon>{icon}</Icon>
            <Date>
              <span>宿泊：</span>
              <span data-wovn-ignore>
                {item.queryItem.outdate}〜{item.queryItem.homedate}
              </span>
            </Date>
            {isTripsForm && item.isNeed && (
              <CheckLabel htmlFor={`hotel_${item.index}`}>
                <input
                  type="checkbox"
                  id={`transport_${item.index}`}
                  checked={!item.isNonOrderItem}
                  onChange={() => item.toggleNonOrderItem()}
                />
                システムで手配
              </CheckLabel>
            )}
            {item.type !== 'airPackage' && !isRequestForm && (
              <Remove onClick={item.toggleNeed}>{item.isNeed ? isNeedTexts[0] : isNeedTexts[1]}</Remove>
            )}
          </Header>,
          <Body key={1}>
            {item.isNeed && [
              <div key={0} className="select-pc__card-title">
                <div className="bold" />
              </div>,
              item.loading ? (
                [
                  <img key={1} className="select__loading" src="/images/loading.gif" width={50} height={50} />,
                  <div key={2} className="select__loading-text">
                    ホテルを検索中です
                  </div>
                ]
              ) : item.error ? (
                <Result>
                  <Text color="danger">{item.error}</Text>
                  {!isRequestForm && (
                    <BoxButtonArea>
                      <BoxButton
                        selected={selected}
                        onClick={() =>
                          handleSearchAgain(handleClickTab, handleOpenShowSearchBox, selected, item.index)
                        }
                      >
                        <BoxButtonText>
                          {selected ? ['再検索を', <br key={0} />, 'キャンセル'] : '再検索 >'}
                        </BoxButtonText>
                      </BoxButton>
                    </BoxButtonArea>
                  )}
                </Result>
              ) : !item.elementList ||
                item.elementList.list.length === 0 ||
                item.elementList.list.every(h => !!h.sold_out) ? (
                <Result>
                  {item.filteredByOrganizationBreakfastFlag ? (
                    <div key={1} className="select-pc__no-results">
                      設定されている規程に基づき 朝食が許可されていないため該当のホテルを表示できません。
                      <br />
                      許可された条件で再度検索してください。
                    </div>
                  ) : (
                    <div key={1} className="select-pc__no-results">
                      ホテルが見つかりませんでした
                      <br />
                      検索条件を変更して再度検索してください。
                    </div>
                  )}
                  {!isRequestForm && (
                    <BoxButtonArea>
                      <BoxButton
                        selected={selected}
                        onClick={() =>
                          handleSearchAgain(handleClickTab, handleOpenShowSearchBox, selected, item.index)
                        }
                      >
                        <BoxButtonText>
                          {selected ? ['再検索を', <br key={0} />, 'キャンセル'] : '再検索 >'}
                        </BoxButtonText>
                      </BoxButton>
                    </BoxButtonArea>
                  )}
                </Result>
              ) : (
                item.elementList.current() && (
                  <div>
                    <CSSTransition
                      key={1}
                      in={item.elementList.showBox}
                      classNames="select-animation-box"
                      timeout={500}
                      onExited={handleCssTransitionExited}
                    >
                      <SelectHotelBox
                        me={store.user as unknown as User}
                        destLocation={item.destLocation}
                        hotel={item.elementList.current()}
                        hotels={item.elementList}
                        inList={false}
                        isDomestic={item.isDomestic}
                        handleClickTab={handleClickTab}
                        hotelPriceLimit={hotelPriceLimit}
                        user={store.user}
                        tab={item.index}
                        selected={selected}
                        searchQueryId={store.result.searchQueryId}
                        isRequestForm={isRequestForm}
                        // パッケージで交通機関を再選択中は他の操作をできないように
                        disabled={item.type === 'airPackage' && store.isCurrentAirlinePackageLoading}
                      />
                    </CSSTransition>
                    <HotelSelectedReason
                      searchQueryItem={item.queryItem}
                      reasonStatus={item.reasonStatus}
                      selectedReason={item.selectedReason}
                      isFirst={item.elementList.isFirst()}
                      selected={selected}
                    />
                  </div>
                )
              )
            ]}
          </Body>
        ]
      : null}
  </div>
);

export const Header = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  text-align: center;
  width: 20px;
  height: 20px;
  background: ${props => props.theme.iconColor};
  border-radius: 10px;
  color: white;
  font-weight: bold;
  margin-right: 10px;
`;

export const Date = styled.div`
  display: flex;
  font-weight: bold;
  margin-right: auto;
`;

const CheckLabel = styled.label`
  color: ${props => props.theme.linkColor};
  font-weight: inherit;
  cursor: pointer;
`;

export const Remove = styled(A)`
  margin-left: 20px;
`;

export const Body = styled.div`
  margin-left: 8px;
  border-left: solid 4px ${props => props.theme.iconColor};
  padding: 10px 0 10px 10px;
`;

const Result = styled.div`
  color: ${props => props.theme.iconColor};
  margin: 20px 0px 20px 10px;
  display: flex;
`;

const Message = styled.div``;

export default withTheme(SelectOutlineItemTemplate);
