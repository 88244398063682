import type { Moment } from '@this/lib/moment';
import moment from '@this/lib/moment';

import type { CreditCardPaymentHistoryCsvReportFileArgs } from '@this/domain/organization/credit_card_payment_history_csv_report_file';
import { CreditCardPaymentHistoryCsvReportFile } from '@this/domain/organization/credit_card_payment_history_csv_report_file';

export type CreditCardPaymentHistoryCsvReportArgs = {
  target_from: string;
  target_to: string;
  last_file_updated_at: string;

  files: CreditCardPaymentHistoryCsvReportFileArgs[];
};

export class CreditCardPaymentHistoryCsvReport {
  targetFrom: Moment;

  targetTo: Moment;

  lastFileUpdatedAt: Moment;

  files: CreditCardPaymentHistoryCsvReportFile[];

  constructor(args: CreditCardPaymentHistoryCsvReportArgs) {
    this.targetFrom = moment(args.target_from);
    this.targetTo = moment(args.target_to);
    this.lastFileUpdatedAt = moment(args.last_file_updated_at);
    this.files = args.files.map(file => new CreditCardPaymentHistoryCsvReportFile(file)) || [];
  }
}
