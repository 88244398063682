interface OtherServiceShareholderInfoArgs {
  id?: number | null | undefined;
  from?: string | undefined;
  to?: string | undefined;
  purchase_price_ana: number | null | undefined;
  purchase_price_jal: number | null | undefined;
  purchase_price_sfj: number | null | undefined;
}

export interface ShareholderInfo {
  type: string;
  from: string;
  id: number;
  purchase_price_ana: number;
  purchase_price_jal: number;
  purchase_price_sfj: number;
  supplier_type: 'allies' | 'frontier';
  to: string | null;
  [key: string]: any;
}

export default class OtherServiceShareholderInfo {
  public id: number | null | undefined;

  public from: string | undefined;

  public to: string | undefined;

  public purchase_price_ana: number | null | undefined;

  public purchase_price_jal: number | null | undefined;

  public purchase_price_sfj: number | null | undefined;

  constructor(args?: OtherServiceShareholderInfoArgs) {
    if (args) {
      this.id = args.id;
      this.from = args.from;
      this.to = args.to;
      this.purchase_price_ana = args.purchase_price_ana;
      this.purchase_price_jal = args.purchase_price_jal;
      this.purchase_price_sfj = args.purchase_price_sfj;
    } else {
      this.id = null;
      this.from = '';
      this.to = '';
      this.purchase_price_ana = 0;
      this.purchase_price_jal = 0;
      this.purchase_price_sfj = 0;
    }
  }

  setFrom(value: string) {
    this.from = value;
    app.render();
  }

  setTo(value: string) {
    this.to = value;
    app.render();
  }

  setPurchasePrice(value: string, target: string) {
    const numericValue = parseFloat(value);
    let purchasePrice;

    if (value === '') {
      purchasePrice = null;
    } else if (!isNaN(numericValue)) {
      purchasePrice = parseInt(value, 10);
    }

    if (target === 'ana') {
      this.purchase_price_ana = purchasePrice;
    } else if (target === 'jal') {
      this.purchase_price_jal = purchasePrice;
    } else if (target === 'sfj') {
      this.purchase_price_sfj = purchasePrice;
    }

    app.render();
  }

  setPurchasePriceAna(value: string) {
    this.setPurchasePrice(value, 'ana');
  }

  setPurchasePriceJal(value: string) {
    this.setPurchasePrice(value, 'jal');
  }

  setPurchasePriceSfj(value: string) {
    this.setPurchasePrice(value, 'sfj');
  }

  updateParams(supplierType: number) {
    return {
      from: this.from,
      to: this.to,
      purchase_price_ana: this.purchase_price_ana,
      purchase_price_jal: this.purchase_price_jal,
      purchase_price_sfj: this.purchase_price_sfj,
      supplier_type: supplierType
    };
  }
}
