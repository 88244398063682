import React from 'react';
import type User from '../../../../../domain/user/user';
import type { WorkflowTypeKeys } from '../../departments';
// import Department from '../../../../../domain/department/department';

interface Props<T extends WorkflowTypeKeys> {
  members: User[];
  onSelect: (i: number, s: number, member: any, workflowType?: T) => void;
  i: number;
  s: number;
  workflowType?: T;
  // department: Department;
  selectedMembers: User[];
  isDeletableApprover?: (i: number, s: number, workflowType?: T) => boolean;
}

interface State {
  filteredMembers: User[];
}

class ApproversMemberList<T extends WorkflowTypeKeys> extends React.Component<Props<T>, State> {
  private searchTextInput: React.RefObject<HTMLInputElement>;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      filteredMembers: this.props.members
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearchMembers = this.handleSearchMembers.bind(this);
    this.searchTextInput = React.createRef();
  }

  componentDidMount() {
    if (this.searchTextInput.current) {
      this.searchTextInput.current.focus();
    }
  }

  handleSelect(member: User | null) {
    this.props.onSelect(this.props.i, this.props.s, member, this.props.workflowType);
  }

  handleSearchMembers(e: React.ChangeEvent<HTMLInputElement>) {
    const keyword = e.target.value;
    const filtered = this.props.members.filter(
      member =>
        member.name.toLowerCase().indexOf(keyword) >= 0 ||
        member.nameRoman().toLowerCase().indexOf(keyword) >= 0 ||
        member.email.indexOf(keyword) >= 0
    );
    this.setState({ filteredMembers: filtered });
  }

  isDeletable = (): boolean => {
    if (this.props.isDeletableApprover !== undefined) {
      return this.props.isDeletableApprover(this.props.i, this.props.s, this.props.workflowType);
    }
    return false;
  };

  render() {
    try {
      return (
        <div className="select-approvers">
          <div className="approver-members-list" style={{ zIndex: 10 }}>
            <input
              type="search"
              defaultValue=""
              placeholder="社員名またはemailで検索"
              onChange={this.handleSearchMembers}
              ref={this.searchTextInput}
            />
            <ul>
              {this.isDeletable() && (
                <li className="approver-members-list__message" onClick={() => this.handleSelect(null)}>
                  <div className="approver-members-list__message__info">
                    <p className="approver-members-list__message__name">選択してください</p>
                  </div>
                </li>
              )}
              {this.state.filteredMembers.map((member, i) => (
                <li
                  key={`approver_${i}_${this.props.i}_${this.props.s}`}
                  className={
                    this.props.selectedMembers.indexOf(member) >= 0
                      ? 'approver-members-list__item selected'
                      : 'approver-members-list__item'
                  }
                  onClick={() => this.handleSelect(member)}
                >
                  <div className="approver-members-list__item__info">
                    {member.departmentName() && (
                      <p className="approver-members-list__item__name">{member.departmentName()}</p>
                    )}
                    <p className="approver-members-list__item__name">{member.name}</p>
                    <p className="approver-members-list__item__name">({member.email})</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

export default ApproversMemberList;
