import _ from 'lodash';
import MarketLogCandidate from './market_log_candidate';

export interface MarketLogArgs {
  candidates?: MarketLogCandidate[];
  user_name: string;
  approver_names: string;
  traveler_names: string;
  term: string;
  name: string;
  price_desc: string;
  hotel_price_limit: number;
  average_price: number;
  price_ordered: number;
  price_increases: number;
  price_limit_diff: number;
}

class MarketLog {
  public candidates: MarketLogCandidate[];

  public userName: string;

  public approverNames: string;

  public travelerNames: string;

  public term: string;

  public name: string;

  public priceDesc: string;

  public hotelPriceLimit: number;

  public averagePrice: number;

  public priceOrdered: number;

  public priceIncreases: number;

  public priceLimitDiff: number;

  constructor(args: MarketLogArgs) {
    this.candidates = args.candidates ? args.candidates.map(c => new MarketLogCandidate(c)) : [];
    this.userName = args.user_name;
    this.approverNames = args.approver_names;
    this.travelerNames = args.traveler_names;
    this.term = args.term;
    this.name = args.name;
    this.priceDesc = args.price_desc || '';
    this.hotelPriceLimit = args.hotel_price_limit;
    this.averagePrice = args.average_price;
    this.priceOrdered = args.price_ordered;
    this.priceIncreases = args.price_increases;
    this.priceLimitDiff = args.price_limit_diff;
  }

  hotelPriceLimitDesc() {
    return utils.formatPrice(this.hotelPriceLimit) || '-';
  }

  priceLimitDiffDesc() {
    let sign = '';
    if (this.isPriceLimitDiffPlus()) {
      sign = '+';
    }
    return sign + String(utils.formatPrice(this.priceLimitDiff));
  }

  isPriceLimitDiffPlus() {
    return Math.sign(this.priceLimitDiff) === 1;
  }

  priceIncreasesDesc() {
    let sign = '';
    if (this.isPriceIncreasesPlus()) {
      sign = '+';
    }
    return sign + String(utils.formatPrice(this.priceIncreases));
  }

  isPriceIncreasesPlus() {
    return Math.sign(this.priceIncreases) === 1;
  }
}

export default MarketLog;
