import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { OrganizationTitle } from '@this/components/organization/organization.style';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import type { ExpensesAccountTypeJson } from '@this/domain/expenses/expenses_account_type';
import ExpensesAccountType from '@this/domain/expenses/expenses_account_type';
import type { FreeeAccountItemResponseArgs } from '@this/domain/organization/freee_account_item';
import FreeeAccountItem, {
  convertFreeeAccountItemRespnseToArgs
} from '@this/domain/organization/freee_account_item';
import Cards from './cards';
import NewFormModal from './new_form_modal';
import EditFormModal from './edit_form_modal';

type Props = {
  theme: { themeClass: string };
};

type IndexResponse = {
  expenses_account_types: ExpensesAccountTypeJson[];
  freee_account_items: FreeeAccountItemResponseArgs[];
  is_freee_connected: boolean;
};

const ExpensesAccountTypes: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [types, setTypes] = useState<ExpensesAccountType[]>([]);
  const [freeeAccountItems, setFreeeAccountItems] = useState<FreeeAccountItem[]>([]);
  const [isFreeeConnected, setIsFreeeConnected] = useState<boolean>(false);
  const [creatingType, setCreatingType] = useState<ExpensesAccountType | null>(null);
  const [editingType, setEditingType] = useState<ExpensesAccountType | null>(null);
  const [deletingType, setDeletingType] = useState<ExpensesAccountType | null>(null);

  const fetchTypes = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await utils.jsonPromise<IndexResponse>('/organization/expenses_account_types.json');
      const types = response.expenses_account_types.map(r => new ExpensesAccountType(r));
      setFreeeAccountItems(
        response.freee_account_items.map(r => new FreeeAccountItem(convertFreeeAccountItemRespnseToArgs(r)))
      );
      setIsFreeeConnected(response.is_freee_connected);
      setTypes(types);
    } catch (e) {
      if (e.status === 400) {
        setError(e.responseJSON.error);
      } else {
        setError('通信エラーが発生しました。時間をおいて再度お試しください。');
        utils.sendErrorObject(e);
      }
    }
    setLoading(false);
  };

  const deleteType = async () => {
    try {
      await utils.jsonPromise(`/organization/expenses_account_types/${deletingType!.id}`, {}, 'DELETE');
      setDeletingType(null);
      fetchTypes();
    } catch (e) {
      setError('通信エラーが発生しました。時間をおいて再度お試しください。');
      utils.sendErrorObject(e);
    }
  };

  const clieckEdit = async (t: ExpensesAccountType | null) => {
    if (t !== null) {
      const editT = _.cloneDeep(t);
      await editT.fetchDepartments();
      setEditingType(editT);
    }
  };

  const connectFreeeAccountItem = async () => {
    try {
      await utils.jsonPromise('/organization/expenses_account_type/freee_account_item.json', {}, 'PUT');
      fetchTypes();
    } catch (e) {
      setError('通信エラーが発生しました。時間をおいて再度お試しください。');
      utils.sendErrorObject(e);
    }
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const modal = () => (
    <>
      {creatingType && (
        <NewFormModal
          type={creatingType}
          isFreeeConnected={isFreeeConnected}
          freeeAccountItems={freeeAccountItems}
          onSubmit={() => {
            setCreatingType(null);
            fetchTypes();
          }}
          hideModal={() => setCreatingType(null)}
        />
      )}
      {editingType && (
        <EditFormModal
          type={editingType}
          isFreeeConnected={isFreeeConnected}
          freeeAccountItems={freeeAccountItems}
          onSubmit={() => {
            setEditingType(null);
            fetchTypes();
          }}
          hideModal={() => setEditingType(null)}
        />
      )}
      {deletingType && (
        <Modal show hideModal={() => setDeletingType(null)} className="e2e-confirm-delete">
          <Confirm
            onConfirmed={() => deleteType()}
            onAborted={() => setDeletingType(null)}
            message={`${
              deletingType.name + deletingType.codeText
            }を削除してよろしいですか？\n※この操作は取り消せません`}
          />
        </Modal>
      )}
    </>
  );

  return (
    <>
      <OrganizationTitle>勘定科目マスタ</OrganizationTitle>
      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Wrap>
          <Cards
            types={types}
            isFreeeConnected={isFreeeConnected}
            freeeAccountItems={freeeAccountItems}
            onClickEdit={t => clieckEdit(t)}
            onClickDelete={t => {
              setDeletingType(t);
            }}
          />
        </Wrap>
      )}
      {!editingType && (
        <Wrap>
          <TheInput
            className="organization__add-button"
            type="submit"
            value="勘定科目を追加"
            onClick={() => {
              const newType = ExpensesAccountType.new();
              setCreatingType(newType);
            }}
          />
          {isFreeeConnected && (
            <TheInput
              className="organization__add-button"
              type="submit"
              value="freee勘定科目マスタと紐付け"
              onClick={() => {
                connectFreeeAccountItem();
              }}
            />
          )}
        </Wrap>
      )}
      {modal()}
    </>
  );
};

const Wrap = styled.div`
  padding: 10px;
`;

const TheInput = styled.input`
  margin-right: 10px;
`;

export default ExpensesAccountTypes;
