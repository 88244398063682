import React from 'react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import DatetimePicker from '@this/src/components/shared/datetime_picker/datetime_picker';
import type { Budget } from '@this/src/domain/organization/budget';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import { Link } from '../../shared/ui/navigations/link';

type Props = {
  budget: Budget;
  index: number;
};

export const BudgetItemsForm: React.FC<Props> = ({ budget, index }) => {
  const { budgetItems } = budget;

  return (
    <>
      <TitleRow>
        <h4>{budget.getPeriod()} 消化予算概要</h4>
        <Button size="small" onClick={() => budget.addBudgetItem()}>
          消化予算追加
        </Button>
      </TitleRow>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>項目名</TableCell>
              <TableCell>追加場所</TableCell>
              <TableCell>金額</TableCell>
              <TableCell>登録日</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetItems.length < 1 && (
              <TableRow>
                <TableCell colSpan={5}>消化予算は登録されていません</TableCell>
              </TableRow>
            )}
            {budgetItems.map((budgetItem, i) => (
              <TableRow key={`budgetItem-${index}-${i}`}>
                {budgetItem.editing ? (
                  <>
                    <TableCell>
                      <input
                        type="text"
                        defaultValue={budgetItem.name}
                        onChange={e => budgetItem.setField('name', e.target.value as string)}
                      />
                      {budgetItem.validName() && <Error>{budgetItem.validName()}</Error>}
                    </TableCell>
                    <TableCell>
                      {budgetItem.getTarget() ? <Link {...budgetItem.getTarget()} target="_blank" /> : '─'}
                    </TableCell>
                    <TableCell>
                      <input
                        type="number"
                        defaultValue={budgetItem.price}
                        onChange={e => budgetItem.setField('price', parseInt(e.target.value, 10) || 0)}
                      />
                      （{budgetItem.getPrice()}）
                    </TableCell>
                    <TableCell>
                      <DatetimePicker
                        value={budgetItem.createdAt}
                        onChange={value => budgetItem.setField('createdAt', value)}
                        showPast
                        border
                        placeholder="日付を選択"
                      />
                    </TableCell>
                    <TableCell>
                      <Actions>
                        <Button size="small" onClick={() => budgetItem.setField('editing', false)}>
                          登録
                        </Button>
                      </Actions>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>
                      {budgetItem.name}
                      {budgetItem.validName() && <Error>{budgetItem.validName()}</Error>}
                    </TableCell>
                    <TableCell>
                      {budgetItem.getTarget() ? <Link {...budgetItem.getTarget()} target="_blank" /> : '─'}
                    </TableCell>
                    <TableCell>{budgetItem.getPrice()}</TableCell>
                    <TableCell>{budgetItem.getCreatedAt()}</TableCell>
                    <TableCell>
                      <Actions>
                        <Button size="small" onClick={() => budgetItem.setField('editing', true)}>
                          編集
                        </Button>
                        <IconButton color="primary" size="small" onClick={() => budget.removeBudgetItem(i)}>
                          <CancelOutlined />
                        </IconButton>
                      </Actions>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  & > h4 {
    margin: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
  font-size: 0.9em;
`;
