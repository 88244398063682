import { Fetcher } from '@this/src/util';
import React, { useEffect, useState } from 'react';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import FlightSeatMapRow from '@this/components/trips_management/trips/flight_seat_map/flight_seat_map_row';
import type FlightSegment from '@this/domain/flight/flight_segment';
import type { SeatMapJson } from '@this/domain/flight/seat_map';
import SeatMap from '@this/domain/flight/seat_map';
import type SeatMapFacility from '@this/domain/flight/seat_map_facility';

interface Props {
  trip_id: number;
  flightSegment: FlightSegment;
  travelerKey: string;
  onClickFacility: (f: SeatMapFacility) => void;
}

type Response = {
  seat_map: SeatMapJson;
};

const FlightSeatMap: React.FC<Props> = ({ trip_id, flightSegment, travelerKey, onClickFacility }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [seatMap, setSeatMap] = useState<SeatMap>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchSeatMap = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      const id = encodeURIComponent(flightSegment.api_related_data.key);
      const params = {
        traveler_key: travelerKey
      };
      const res = await Fetcher.get<Response>(`/trips/${trip_id}/flight_seat_maps/${id}`, params);
      const seatMap = new SeatMap(res.seat_map);
      setSeatMap(seatMap);
    } catch (e) {
      setErrorMessage('通信エラーが発生しました。時間をおいて再度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSeatMap();
  }, []);

  const flightSeatMapRows = (): JSX.Element[] => {
    const rows: JSX.Element[] = [];

    if (seatMap) {
      const seatMapRows = seatMap.rows;
      const maxColumnCount = seatMap.columnCount();

      seatMapRows.forEach((r, i) => {
        // 行番号が連番でない場合、前には席が無くスペースがあると判断する。空行を追加する。
        const noSeatsFrontOfRow = !(i === 0) && r.rowNumber !== seatMapRows[i - 1].rowNumber + 1;
        if (noSeatsFrontOfRow) {
          rows.push(
            <FlightSeatMapRow
              key={`${r.rowNumber}empty`}
              columnCount={maxColumnCount}
              seatMapRow={null}
              onClickFacility={onClickFacility}
            />
          );
        }
        rows.push(
          <FlightSeatMapRow
            key={r.rowNumber}
            columnCount={maxColumnCount}
            seatMapRow={r}
            onClickFacility={onClickFacility}
          />
        );
      });
    }

    return rows;
  };

  return (
    <>
      {isLoading ? (
        <LoadingContainer>
          <SimpleLoading />
        </LoadingContainer>
      ) : errorMessage ? (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      ) : (
        <Container>{flightSeatMapRows()}</Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 0 5%;
`;

const LoadingContainer = styled.div`
  padding: 1em 0;
`;

const ErrorMessage = styled.p`
  padding: 1em 0;
  font-weight: bold;
  color: red;
`;

export default FlightSeatMap;
