import React, { useState } from 'react';
import A from '@this/components/shared/atoms/a';

import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle,
  Flex,
  ShareDetailLinkArea,
  ShareDetailArea,
  ShareDetailFlexArea,
  ShareDetailIndentedArea,
  ShareDetailLabel,
  ShareDetailSelectArea
} from '@this/components/organization/trips/detail/trip_detail.style';
import type { FilterableSelectorOption } from '@this/shared/filterable_selector/filterable_selector';
import FilterableSelector from '@this/shared/filterable_selector/filterable_selector';
import type Project from '@this/domain/project/project';
import type Trip from '@this/domain/trip/trip';
import type ProjectShareList from '@this/domain/project/project_share_list';
import type OrderItem from '@this/domain/order_item';
import OrderItemMappingList from '@this/domain/order_item_mapping_list';
import type OrderItemMapping from '@this/domain/order_item_mapping';
import type NonOrderItem from '@this/domain/non_order_item';
import NonOrderItemMappingList from '@this/domain/non_order_item_mapping_list';
import type NonOrderItemMapping from '@this/domain/non_order_item_mapping';

interface Props {
  projects: Project[];
  projectShares: ProjectShareList;
  projectShareAvailability: boolean;
  orderItems: OrderItem[];
  nonOrderItems?: NonOrderItem[];
  selectedProjectId: ReturnType<Trip['projectId']>;
  showProjectDetail: boolean;
  setShowProjectDetail: (status: boolean) => void;
  onSave: (
    projectId: ReturnType<Trip['projectId']>,
    orderItemMapping: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => void;
  onSaveDetail: (orderItemMapping: OrderItemMappingList, nonOrderItemMappings: NonOrderItemMappingList) => void;
}

const TripDetailProjectForm: React.FC<Props> = ({
  projects,
  projectShares,
  projectShareAvailability,
  orderItems,
  nonOrderItems,
  selectedProjectId,
  showProjectDetail,
  setShowProjectDetail,
  onSave,
  onSaveDetail
}) => {
  const [selectedId, setSelectedId] = useState(selectedProjectId);
  const [projectDetailChanged, setProjectDetailChanged] = useState(false);

  const prepareOrderItemMappingFromTrip = () => {
    const mappings: OrderItemMappingList = new OrderItemMappingList([]);
    orderItems.forEach(item => {
      item.orderItemMappings.list.forEach(mapping => {
        const newMapping = mapping.clone();
        if (newMapping.projectId) {
          const project = projects.find(project => project.id === newMapping.projectId);
          newMapping.setSelectedOptionLabel(project?.codeAndName() || '');
        } else if (newMapping.projectShareId) {
          const projectShare = projectShares.find(newMapping.projectShareId);
          newMapping.setSelectedOptionLabel(projectShare?.codeAndNameOneLine() || '');
        }
        mappings.push(newMapping);
      });
    });

    return mappings;
  };

  const prepareNonOrderItemMappingFromTrip = () => {
    const mappings: NonOrderItemMappingList = new NonOrderItemMappingList([]);
    nonOrderItems?.forEach(item => {
      item.nonOrderItemMappings.forEach(mapping => {
        const newMapping = mapping.clone();
        if (newMapping.projectId) {
          const project = projects.find(project => project.id === newMapping.projectId);
          newMapping.setSelectedOptionLabel(project?.codeAndName() || '');
        } else if (newMapping.projectShareId) {
          const projectShare = projectShares.find(newMapping.projectShareId);
          newMapping.setSelectedOptionLabel(projectShare?.codeAndNameOneLine() || '');
        }
        mappings.push(newMapping);
      });
    });

    return mappings;
  };

  const [orderItemMappings, setOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [editingOrderItemMappings, setEditingOrderItemMappings] = useState(prepareOrderItemMappingFromTrip());
  const [nonOrderItemMappings, setNonOrderItemMappings] = useState(prepareNonOrderItemMappingFromTrip());
  const [editingNonOrderItemMappings, setEditingNonOrderItemMappings] = useState(
    prepareNonOrderItemMappingFromTrip()
  );

  const onSelectProjectDetail = (
    changingMapping: OrderItemMapping | NonOrderItemMapping,
    option: FilterableSelectorOption | null
  ) => {
    const values = option?.value.split('-'); // e.g. project-1
    if (values && values?.length === 2) {
      if (values[0] === 'project') {
        changingMapping.setProjectId(Number(values[1]));
        changingMapping.setSelectedOptionLabel(option?.label || '');
      } else if (values[0] === 'share') {
        changingMapping.setProjectShareId(Number(values[1]));
        if (option?.labelLines) {
          changingMapping.setSelectedOptionLabel(option.labelLines[0] || '');
        }
      }
    } else {
      changingMapping.setProjectId(null);
      changingMapping.setProjectShareId(null);
      changingMapping.setSelectedOptionLabel('');
    }
    setProjectDetailChanged(true);
  };

  const createOption = (project: Project) => ({
    label: `${project.code}: ${project.name}`,
    value: project.id.toString(),
    matcher: `${project.code}${project.name}`
  });
  const onSelect = (selected: FilterableSelectorOption | null): void => {
    const values = selected?.value.split('-'); // e.g. project-1
    if (values && values?.length === 2 && values[0] === 'project') {
      setSelectedId(parseInt(values[1], 10));
    } else {
      setSelectedId(null);
    }
    editingOrderItemMappings.list.forEach(mapping => {
      onSelectProjectDetail(mapping, selected);
    });
    editingNonOrderItemMappings.list.forEach(mapping => {
      onSelectProjectDetail(mapping, selected);
    });
  };

  const selectedDepartment = projects.find(d => d.id === selectedId);
  const selectOption = selectedDepartment ? createOption(selectedDepartment) : null;

  const onShowProjectDetailClick = (value: boolean) => {
    setShowProjectDetail(value);
  };

  const projectDetailOption = (): any[] => {
    const projectsOption: any[] = projects.map(p => {
      return {
        label: p.codeAndName(),
        value: `project-${p.id.toString()}`,
        matcher: p.codeAndName()
      };
    });
    const sharesOption: any[] = projectShares.list.map(s => {
      return {
        labelLines: s.codeAndNameLines(),
        value: `share-${s.id.toString()}`,
        matcher: s.codeAndNameOneLine()
      };
    });
    return projectsOption.concat(sharesOption);
  };

  const onClickSaveDetail = (
    orderItemMappings: OrderItemMappingList,
    nonOrderItemMappings: NonOrderItemMappingList
  ) => {
    onSaveDetail(orderItemMappings, nonOrderItemMappings);
    setOrderItemMappings(editingOrderItemMappings.clone());
    setNonOrderItemMappings(editingNonOrderItemMappings.clone());
    setProjectDetailChanged(false);
  };

  const onCancelDetail = () => {
    setProjectDetailChanged(false);
    setEditingOrderItemMappings(orderItemMappings.clone());
    setEditingNonOrderItemMappings(nonOrderItemMappings.clone());
  };

  return (
    <MetaSection>
      <Flex>
        <MetaSectionTitle>プロジェクト</MetaSectionTitle>
        {projectShareAvailability &&
          (showProjectDetail ? (
            <ShareDetailLinkArea>
              <A onClick={() => onShowProjectDetailClick(false)}>通常設定へ</A>
            </ShareDetailLinkArea>
          ) : (
            <ShareDetailLinkArea>
              <A onClick={() => onShowProjectDetailClick(true)}>詳細設定へ</A>
            </ShareDetailLinkArea>
          ))}
      </Flex>
      {showProjectDetail ? (
        <>
          <ShareDetailArea>
            {orderItems.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.categoryStr()}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="プロジェクトを検索"
                          options={projectDetailOption()}
                          selected={{
                            label:
                              editingOrderItemMappings.findByTravelerInformationAndOrderItem(traveler.id, item.id)
                                ?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectProjectDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
            {nonOrderItems?.map((item, item_index) => (
              <div key={`item${item_index}`}>
                <ShareDetailLabel>
                  {item_index + 1}. {item?.elementTypeForOrganizationTrip === 'hotel' ? 'ホテル' : '経路'}
                </ShareDetailLabel>
                <ShareDetailIndentedArea>
                  {item.travelerInformations.map((traveler: any, index: number) => (
                    <ShareDetailFlexArea key={traveler.id}>
                      <ShareDetailLabel>{traveler.name}</ShareDetailLabel>
                      <ShareDetailSelectArea>
                        <FilterableSelector
                          placeholder="プロジェクトを検索"
                          options={projectDetailOption()}
                          selected={{
                            label:
                              editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                                traveler.id,
                                item.id
                              )?.selectedOptionLabel || '',
                            value: '',
                            matcher: ''
                          }}
                          onSelect={option => {
                            const mapping = editingNonOrderItemMappings.findByTravelerInformationAndOrderItem(
                              traveler.id,
                              item.id
                            );
                            if (mapping) {
                              onSelectProjectDetail(mapping, option);
                            }
                          }}
                        />
                      </ShareDetailSelectArea>
                    </ShareDetailFlexArea>
                  ))}
                </ShareDetailIndentedArea>
              </div>
            ))}
          </ShareDetailArea>
          {projectDetailChanged && (
            <MetaSectionFormActions>
              <MetaSectionFormButton
                onClick={() => onClickSaveDetail(editingOrderItemMappings, editingNonOrderItemMappings)}
              >
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => onCancelDetail()}>キャンセル</MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      ) : (
        <>
          <FilterableSelector
            placeholder="プロジェクトを検索"
            options={projectDetailOption()}
            selected={selectOption}
            onSelect={onSelect}
          />
          {selectedProjectId !== selectedId && (
            <MetaSectionFormActions>
              <MetaSectionFormButton
                onClick={() => onSave(selectedId, editingOrderItemMappings, editingNonOrderItemMappings)}
              >
                保存
              </MetaSectionFormButton>
              <MetaSectionFormButton onClick={() => setSelectedId(selectedProjectId)}>
                キャンセル
              </MetaSectionFormButton>
            </MetaSectionFormActions>
          )}
        </>
      )}
    </MetaSection>
  );
};

export default TripDetailProjectForm;
