import React from 'react';

import { styled } from '@this/constants/themes';
import type { AirRouteDetail } from '@this/domain/select_repository';

export const AirRoutePolicies: React.FC<{ airRoutePolicies: AirRouteDetail }> = ({ airRoutePolicies }) => {
  if (!airRoutePolicies.change_reservation_policies && !airRoutePolicies.cancel_policies) {
    return null;
  }
  return (
    <Section>
      <Title>航空券の変更・取消条件について</Title>
      {airRoutePolicies.change_reservation_policies && (
        <>
          <SubTitle>予約の変更</SubTitle>
          <Content>
            {airRoutePolicies.change_reservation_policies.map((policy, i) => (
              <p key={i.toString()}>{policy}</p>
            ))}
          </Content>
        </>
      )}

      {airRoutePolicies.cancel_policies && (
        <>
          <SubTitle>取消・払戻し</SubTitle>
          <Content>
            {airRoutePolicies.cancel_policies.map((policy, i) => (
              <p key={i.toString()}>{policy}</p>
            ))}
          </Content>
        </>
      )}
      <Provided>株式会社オーエフシー(c)</Provided>
    </Section>
  );
};

const Section = styled.section`
  & + & {
    margin-top: 20px;
  }
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SubTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Content = styled.div`
  margin-left: 10px;
  margin-bottom: 15px;
`;

const Provided = styled.div`
  text-align: right;
  font-size: 11px;
`;
