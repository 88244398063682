import type { MutableRefObject } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@this/src/components/shared/ui/inputs/checkbox';
import { Radio } from '@this/src/components/shared/ui/inputs/radio';
import { Select } from '@this/src/components/shared/ui/inputs/select';
import type { UseQrQueryKey } from '@this/src/domain/organization/use_qr_query';
import { UseQrQuery, UseQrQueryKeys } from '@this/src/domain/organization/use_qr_query';
import type { TicketingMethodKey2 } from '@this/src/domain/arrangement/ticketing_instruction';
import { TicketingMethod2, TicketingMethodKeys2 } from '@this/src/domain/arrangement/ticketing_instruction';
import { SearchArea, SearchBlock, SearchLabel } from '../search_area';
import useOrganizationSelector from '../use_organization_selector';

interface Props {
  queryRef: MutableRefObject<URLSearchParams | null>;
  page: number;
  onFetch: () => void;
}

type SortKey = 'organization' | 'received_at' | 'started_at';
type SortDirection = 'asc' | 'desc';

interface State {
  useQr: UseQrQueryKey;
  ticketingMethod: TicketingMethodKey2;
  onlyPremiumSupport: boolean;
  sortKey: SortKey;
  sortDirection: SortDirection;
}

const initialState: () => State = () => ({
  useQr: (utils.getParam('use_qr') as UseQrQueryKey) || 'all',
  ticketingMethod: (utils.getParam('ticketing_method') as TicketingMethodKey2) || 'all',
  onlyPremiumSupport: utils.getParam('only_premium_support') === 'true',
  sortKey: (utils.getParam('sort_key') as SortKey) || 'organization',
  sortDirection: (utils.getParam('sort_direction') as SortDirection) || 'asc'
});

const TicketingInstructionSearchArea: React.FC<Props> = ({ queryRef, page, onFetch }) => {
  const [organizationIds, renderOrganizationSelector] = useOrganizationSelector();
  const [state, setState] = useState<State>(initialState());
  const { useQr, ticketingMethod, onlyPremiumSupport, sortKey, sortDirection } = state;

  useEffect(() => {
    if (queryRef.current === null) {
      queryRef.current = new URLSearchParams();
    }
    queryRef.current.set('organization_ids', organizationIds.join(','));
    queryRef.current.set('use_qr', state.useQr);
    queryRef.current.set('ticketing_method', state.ticketingMethod);
    queryRef.current.set('only_premium_support', state.onlyPremiumSupport.toString());
    queryRef.current.set('sort_key', state.sortKey);
    queryRef.current.set('sort_direction', state.sortDirection);
    queryRef.current.set('page', page.toString());
    onFetch();
  }, [page, organizationIds, state, onFetch]);

  const handleUseQrChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(state => ({ ...state, useQr: e.target.value as UseQrQueryKey }));
    e.persist();
  }, []);

  const handleTicketingMethodChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(state => ({ ...state, ticketingMethod: e.target.value as TicketingMethodKey2 }));
    e.persist();
  }, []);

  const handleOnlyPremiumSupportChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setState(state => ({ ...state, onlyPremiumSupport: e.target.checked }));
    e.persist();
  }, []);

  const handleSortKeyChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setState(state => ({ ...state, sortKey: e.target.value as SortKey }));
    e.persist();
  }, []);

  const handleSortAscChange = useCallback((e: React.ChangeEvent<Record<string, unknown>>) => {
    setState(state => ({ ...state, sortDirection: 'asc' }));
    e.persist();
  }, []);

  const handleSortDescChange = useCallback((e: React.ChangeEvent<Record<string, unknown>>) => {
    setState(state => ({ ...state, sortDirection: 'desc' }));
    e.persist();
  }, []);

  return (
    <SearchArea>
      <SearchBlock>
        <SearchLabel>法人</SearchLabel>
        {renderOrganizationSelector()}
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>QR利用企業</SearchLabel>
        <Select value={useQr} onChange={handleUseQrChange} style={{ marginBottom: 0, marginRight: '5px' }}>
          {UseQrQueryKeys.map(key => (
            <option key={key} value={key}>
              {UseQrQuery[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>発券方法</SearchLabel>
        <Select
          value={ticketingMethod}
          onChange={handleTicketingMethodChange}
          style={{ marginBottom: 0, marginRight: '5px' }}
        >
          {TicketingMethodKeys2.map(key => (
            <option key={key} value={key}>
              {TicketingMethod2[key]}
            </option>
          ))}
        </Select>
      </SearchBlock>
      <SearchBlock>
        <Checkbox checked={onlyPremiumSupport} onChange={handleOnlyPremiumSupportChange}>
          <b style={{ fontSize: '12px' }}>土日祝オプション企業のみ</b>
        </Checkbox>
      </SearchBlock>
      <SearchBlock>
        <SearchLabel>並び順</SearchLabel>
        <select value={sortKey} onChange={handleSortKeyChange} style={{ marginBottom: 0, marginRight: '5px' }}>
          <option value="received_at">依頼日</option>
          <option value="started_at">出発日</option>
        </select>
        <Radio checked={sortDirection === 'asc'} onChange={handleSortAscChange} style={{ marginRight: '5px' }}>
          昇順▲
        </Radio>
        <Radio checked={sortDirection === 'desc'} onChange={handleSortDescChange}>
          降順▼
        </Radio>
      </SearchBlock>
    </SearchArea>
  );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.page === nextProps.page;
};

export default React.memo(TicketingInstructionSearchArea, propsAreEqual);
