import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';

import type { RouteComponentProps } from 'react-router-dom';
import FlashMessage from '@this/shared/flash_message/flash_message';
import Modal from '../../shared/modal/modal';
import Confirm from '../../shared/confirm/confirm';
import SimpleLoading from '../../shared/simple_loading/simple_loading';
import MessageTemplateList from '../../../domain/message_template_list';
import MessageTemplate from '../../../domain/message_template';
import MessageTemplateBlock from './message_template_block/message_template_block';

interface MessageTemplateGetResponse {
  message_templates: MessageTemplate[];
}

interface MessageTemplatePostResponse {
  id: number;
}

interface MessageTemplatePutesponse {
  id: number;
}
interface State {
  messageTemplates: MessageTemplateList;
  loading: boolean;
  editingMessageTemplate: MessageTemplate;
  saving: boolean;
  saveError: string | null;
  deletingMessageTemplate: MessageTemplate;
}

interface MessageTemplateObj {
  setTitle: MessageTemplate;
  setContent: MessageTemplate;
}

class MessageTemplates extends React.Component<RouteComponentProps, State> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      messageTemplates: new MessageTemplateList({
        message_templates: []
      }),
      editingMessageTemplate: new MessageTemplate(),
      saving: false,
      saveError: null,
      deletingMessageTemplate: new MessageTemplate()
    };
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeleteAlertClose = this.handleDeleteAlertClose.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    MessageTemplates.contentFormat = MessageTemplates.contentFormat.bind(this);
  }

  componentDidMount() {
    this.fetchMessageTemplates();
  }

  async fetchMessageTemplates(): Promise<void> {
    this.setState({ loading: true });
    return Fetcher.get<MessageTemplateGetResponse>('/arrangement/message_templates.json').then(response => {
      this.setState({
        messageTemplates: new MessageTemplateList(response),
        loading: false
      });
    });
  }

  handleAddClick(e: React.MouseEvent<HTMLInputElement>) {
    e.preventDefault();
    const m = new MessageTemplate();
    this.state.messageTemplates.unshift(m);
    this.setState({
      editingMessageTemplate: m
    });
  }

  handleFieldChange(
    name: keyof MessageTemplateObj,
    e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>
  ) {
    e.preventDefault();
    if (this.state.editingMessageTemplate) {
      this.state.editingMessageTemplate[name](e.target.value);
    }
  }

  handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({
      saving: true,
      saveError: null
    });
    if (this.state.editingMessageTemplate && this.state.editingMessageTemplate.id) {
      this.submitUpdate();
    } else {
      this.submitCreate();
    }
  }

  submitCreate() {
    if (this.state.editingMessageTemplate) {
      return Fetcher.post<MessageTemplatePostResponse>(
        '/arrangement/message_templates',
        this.state.editingMessageTemplate.updateParams()
      ).then(
        res => {
          _.assign(this.state.editingMessageTemplate, { id: res.id });
          if (this.state.editingMessageTemplate) {
            this.fetchMessageTemplates();
            this.setState({
              editingMessageTemplate: new MessageTemplate(),
              saving: false
            });
          }
        },
        error => {
          const state: {
            saving: boolean;
            saveError: string | null;
          } = {
            saving: false,
            saveError: null
          };
          if (error.response.data.status === 400) {
            state.saveError = error.response.data.errors;
          } else {
            state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState(state);
        }
      );
    }
    return false;
  }

  submitUpdate() {
    if (this.state.editingMessageTemplate) {
      return Fetcher.put<MessageTemplatePutesponse>(
        `/arrangement/message_templates/${this.state.editingMessageTemplate.id}`,
        this.state.editingMessageTemplate.updateParams()
      ).then(
        () => {
          if (this.state.editingMessageTemplate) {
            this.state.messageTemplates.update(this.state.editingMessageTemplate);
            this.fetchMessageTemplates();
            this.setState({
              editingMessageTemplate: new MessageTemplate(),
              saving: false
            });
          }
        },
        error => {
          const state = {
            saving: false,
            saveError: ''
          };
          if (error.response.data.status === 400) {
            state.saveError = error.response.data.errors;
          } else {
            state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
          }
          this.setState(state);
        }
      );
    }
    return false;
  }

  handleFlashMessageClose() {
    this.setState({ saveError: null });
  }

  handleCancelClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.state.editingMessageTemplate && !this.state.editingMessageTemplate.id) {
      this.state.messageTemplates.remove(this.state.editingMessageTemplate);
    }
    this.setState({ editingMessageTemplate: new MessageTemplate() });
  }

  handleEditClick(m: MessageTemplate, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ editingMessageTemplate: _.cloneDeep(m) });
  }

  handleDeleteClick(m: MessageTemplate, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.setState({ deletingMessageTemplate: m });
  }

  handleDeleteAlertClose() {
    this.setState({ deletingMessageTemplate: new MessageTemplate() });
  }

  handleDeleteConfirm() {
    this.state.deletingMessageTemplate.setIsDelete(1);
    return Fetcher.put(
      `/arrangement/message_templates/${this.state.deletingMessageTemplate.id}`,
      this.state.deletingMessageTemplate.updateParams()
    ).then(
      () => {
        this.state.messageTemplates.remove(this.state.deletingMessageTemplate);
        this.setState({
          deletingMessageTemplate: new MessageTemplate(),
          saving: false
        });
        this.fetchMessageTemplates();
      },
      error => {
        const state = {
          saving: false,
          saveError: ''
        };
        if (error.response.data.status === 400) {
          state.saveError = error.response.data.errors;
        } else {
          state.saveError = '通信環境が不安定です。\n時間をおいてもう一度お試しください。';
        }
        this.setState(state);
      }
    );
  }

  static contentFormat(content: string | undefined) {
    if (content) {
      const regex = /(<br>|\n)/g;
      return content.split(regex).map((line, i) => {
        if (line !== '<br>') {
          return <div key={i}>{line}</div>;
        }
        return '';
      });
    }
    return '';
  }

  render() {
    const state = this.state;
    const classBase = 'message-templates';
    return (
      <div className={classBase}>
        <FlashMessage
          message={this.state.saveError}
          type="error"
          handleFlashMessageClose={this.handleFlashMessageClose}
        />
        <h2>メッセージテンプレート</h2>
        {state.editingMessageTemplate ? (
          <input
            className={`${classBase}__add-button`}
            type="submit"
            value="追加"
            onClick={e => this.handleAddClick(e)}
          />
        ) : null}
        <form onSubmit={e => this.handleFormSubmit(e)}>
          {state.loading || state.saving ? (
            <div className="message-templates__loadling">
              <SimpleLoading />
            </div>
          ) : (
            <table className="message-templates__table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>タイトル</th>
                  <th>内容</th>
                  <th />
                  <th />
                </tr>
              </thead>
              {this.state.messageTemplates.list.map((messageTemplate, i) => (
                <MessageTemplateBlock
                  key={i}
                  messageTemplates={state.messageTemplates}
                  messageTemplate={messageTemplate}
                  editingMessageTemplate={state.editingMessageTemplate}
                  handleFieldChange={this.handleFieldChange}
                  handleEditClick={this.handleEditClick}
                  handleDeleteClick={this.handleDeleteClick}
                  handleCancelClick={this.handleCancelClick}
                  contentFormat={MessageTemplates.contentFormat}
                />
              ))}
            </table>
          )}
        </form>
        {this.state.deletingMessageTemplate.id
          ? [
              <Modal key={1} show hideModal={this.handleDeleteAlertClose}>
                <Confirm
                  onConfirmed={this.handleDeleteConfirm}
                  onAborted={this.handleDeleteAlertClose}
                  message={`${this.state.deletingMessageTemplate.title} を削除してよろしいですか？\n※この操作は取り消せません`}
                />
              </Modal>
            ]
          : null}
      </div>
    );
  }
}

export default MessageTemplates;
