import type { ChangeEvent } from 'react';
import React, { useState } from 'react';

import {
  MetaSection,
  MetaSectionFormActions,
  MetaSectionFormButton,
  MetaSectionTitle
} from '@this/components/organization/trips/detail/trip_detail.style';

interface Props {
  internalNumber?: string;
  onSave: (internalNumber: string) => void;
}

const TripDetailInternalNumberForm: React.FC<Props> = ({ internalNumber, onSave }) => {
  const [edittingInternalNumber, setEditingInternalNumber] = useState(internalNumber);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEditingInternalNumber(e.target.value || '');
  };

  return (
    <MetaSection>
      <MetaSectionTitle>社内管理番号</MetaSectionTitle>

      <input type="text" placeholder="(任意)社内稟議番号など" value={edittingInternalNumber} onChange={onChange} />

      {internalNumber !== edittingInternalNumber && (
        <MetaSectionFormActions>
          <MetaSectionFormButton onClick={() => onSave(edittingInternalNumber || '')}>保存</MetaSectionFormButton>
          <MetaSectionFormButton onClick={() => setEditingInternalNumber(internalNumber)}>
            キャンセル
          </MetaSectionFormButton>
        </MetaSectionFormActions>
      )}
    </MetaSection>
  );
};

export default TripDetailInternalNumberForm;
