Project = require('./project')

class ProjectList
  constructor: (rawDeps, members) ->
    @list = _.map rawDeps, (d) -> new Project(d, members)
    @list ||= []
    @members = members || []

  find: (id) ->
    _.find @list, (d) -> d.id == parseInt(id)

  push: (d) ->
    @list.push(d)

  remove: (d) ->
    i = @list.indexOf(d)
    if i >  -1
      @list.splice(i, 1)

  update: (newDep) ->
    d = @find(newDep.id)
    if d
      _.assign d, newDep

module.exports = ProjectList
