import React from 'react';
import { observer } from 'mobx-react';

import { styled } from '@this/constants/themes';

import { Content, Title } from '../god';

import ChatbotSettingForm from './chatbot_setting_form';
import ChatbotTryOuts from './chatbot_try_outs';

const Chatbot = observer(() => {
  return (
    <div>
      <Title>AIコンシェルジュ設定</Title>
      <Content>
        <Desc>
          AIコンシェルジュに関する設定を管理します。モデルの設定やトークン数の計測方法などを設定できます。
        </Desc>
        <ChatbotSettingForm />
        <ChatbotTryOuts />
      </Content>
    </div>
  );
});

const Desc = styled.p`
  margin-bottom: 20px;
`;

export default Chatbot;
