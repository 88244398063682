import _ from 'lodash';

export interface ETicketArgs {
  id: number;
  link: string;
}

class ETicket {
  id: number;

  link: string;

  constructor(args: ETicketArgs) {
    this.id = args.id;
    this.link = args.link;
  }
}

export default ETicket;
