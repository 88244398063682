import React from 'react';
import { styled } from '@this/constants/themes';
import Modal from '@this/shared/modal/modal';
import { Body, Info, Buttons, Button } from './style';

import TripReportForm from '../../../../trips_management/trip_reports/trip_reports_new/trip_report_form/trip_report_form';
import TripReportBodyTemplate from '../../../../trips_management/trip_reports/trip_report_body.template';

interface Props {
  projects: any[];
  expenseTypes: any;
  selectedReport: any;
  trip: any;
  confirming: boolean;
  requesting: boolean;
  handleEdit: () => void;
  handleConfirm: () => void;
  handleFormSubmit: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  handleCancelConfirm: () => void;
  handleCancelClick: () => void;
  editSubmitRef: any;
}

const EditModalTemplate: React.SFC<Props> = ({
  projects,
  expenseTypes,
  selectedReport,
  trip,
  confirming,
  requesting,
  handleEdit,
  handleConfirm,
  handleFormSubmit,
  handleCancelConfirm,
  handleCancelClick,
  editSubmitRef
}) => (
  <Modal hideModal={() => handleCancelClick()} show title="編集">
    <Body>
      <Info>
        <FormWrap>
          {confirming ? (
            <>
              <Title>以下の内容で編集します。よろしいですか？</Title>
              <TripReportBodyTemplate user={trip.user} report={selectedReport} projects={projects} />
            </>
          ) : (
            <TripReportForm
              user={trip.user}
              trip={trip}
              report={selectedReport}
              expenseTypes={expenseTypes}
              projects={projects}
              onConfirm={handleConfirm}
              withSubmitButton={false}
              ref={editSubmitRef}
            />
          )}
        </FormWrap>
      </Info>
      <Buttons>
        {confirming ? (
          <>
            <Button type="button" value="戻る" disabled={requesting} onClick={() => handleCancelConfirm()} />
            <Button type="button" value="確定" disabled={requesting} onClick={() => handleEdit()} />
          </>
        ) : (
          <>
            <Button type="button" value="戻る" disabled={requesting} onClick={() => handleCancelClick()} />
            <Button type="button" value="確認" disabled={requesting} onClick={e => handleFormSubmit(e)} />
          </>
        )}
      </Buttons>
    </Body>
  </Modal>
);

const FormWrap = styled.div`
  width: 70%;
`;

const Title = styled.p`
  margin-bottom: 30px;
`;

export default EditModalTemplate;
