import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Button, Popover, FormControlLabel, Checkbox, FormGroup, Box, FormLabel } from '@material-ui/core';
import type { TripStatusKey } from '@this/src/domain/trip/trip_status';
import TripStatus, { TripStatusAvailable } from '@this/domain/trip/trip_status';

interface Props {
  selectedTripStatuses: TripStatusKey[];
  onChangeSelectedTripStatuses: (statuses: TripStatusKey[]) => void;
}

const TripStatusSelector = observer(({ selectedTripStatuses, onChangeSelectedTripStatuses }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClickTripFilterButton = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTripFilter = () => {
    setAnchorEl(null);
  };

  const handleSelectAllTripStatuses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const statuses = e.target.checked ? TripStatusAvailable : [];
    onChangeSelectedTripStatuses(statuses as TripStatusKey[]);
  };

  const handleSelectTripStatus = (e: React.ChangeEvent<HTMLInputElement>, status: number) => {
    const statuses = e.target.checked
      ? selectedTripStatuses.concat([status as TripStatusKey])
      : _.without(selectedTripStatuses, status);
    onChangeSelectedTripStatuses(statuses as TripStatusKey[]);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClickTripFilterButton}
        style={{ backgroundColor: 'white' }}
      >
        絞り込み
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseTripFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box display="flex">
          <FormGroup style={{ padding: '5px' }}>
            <FormLabel>ステータス</FormLabel>
            <FormControlLabel
              label="すべて選択"
              control={
                <Checkbox
                  color="primary"
                  checked={selectedTripStatuses.length === TripStatusAvailable.length}
                  indeterminate={
                    selectedTripStatuses.length !== 0 && selectedTripStatuses.length !== TripStatusAvailable.length
                  }
                  onChange={handleSelectAllTripStatuses}
                />
              }
            />
            {TripStatusAvailable.map(key => (
              <FormControlLabel
                key={key}
                label={TripStatus[key]}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedTripStatuses.includes(key)}
                    onChange={e => {
                      handleSelectTripStatus(e, key);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
});

export default TripStatusSelector;
