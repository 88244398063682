import React from 'react';

export const ThumbsUpOutlineIcon: React.FC = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4998 5.14006H9.29317L9.9265 2.09339L9.9465 1.88006C9.9465 1.60672 9.83317 1.35339 9.65317 1.17339L8.9465 0.473389L4.55984 4.86672C4.31317 5.10672 4.1665 5.44006 4.1665 5.80672V12.4734C4.1665 13.2067 4.7665 13.8067 5.49984 13.8067H11.4998C12.0532 13.8067 12.5265 13.4734 12.7265 12.9934L14.7398 8.29339C14.7998 8.14006 14.8332 7.98006 14.8332 7.80672V6.47339C14.8332 5.74006 14.2332 5.14006 13.4998 5.14006ZM13.4998 7.80672L11.4998 12.4734H5.49984V5.80672L8.39317 2.91339L7.65317 6.47339H13.4998V7.80672ZM0.166504 5.80672H2.83317V13.8067H0.166504V5.80672Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
