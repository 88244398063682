import _ from 'lodash';
import type MarginType from '@this/domain/organization/margin_type2';
import type ListWrapperInterface from '../list_wrapper_interface';
import type { TransitJson } from '../select_repository';
import Transit from './transit';

interface TransitListArgs {
  showFee?: boolean;
  package?: boolean;
  // packageType?: 'IIT' | 'DP';
  marginType?: MarginType;
  current?: string;
  changeableAir?: boolean;
  handleChange?: (prevId: string | undefined) => void;
}
class TransitList implements ListWrapperInterface {
  private showFee: boolean;

  public package: boolean | undefined;

  // private packageType: 'IIT' | 'DP' | undefined;

  private marginType: MarginType | undefined;

  public list: Transit[];

  public currentId: string | undefined;

  public segment: number;

  public showBox: boolean;

  private newId: string | null;

  private newChangeable: boolean | null | undefined;

  public isAirChangeable: boolean | undefined;

  public domesticAirPriceIndex: number | undefined;

  private handleChange: ((prevId: string | undefined) => void) | null = null;

  private filteredAirlines: string[];

  private filteredChangeablePrice: string[];

  private filteredUpgradeSeat: boolean;

  private showConnectingAir: boolean;

  constructor(rawTransits?: TransitJson[], args: TransitListArgs = {}) {
    this.showFee = typeof args.showFee === 'undefined' ? true : args.showFee;
    this.package = args.package;
    // this.packageType = args.packageType;
    this.marginType = args.marginType;
    this.list = _.map(
      rawTransits,
      t =>
        new Transit(
          _.merge(t, {
            showFee: this.showFee,
            package: this.package,
            // packageType: this.packageType,
            marginType: this.marginType
          })
        )
    );
    if (!this.package) this.list = _.uniqBy(this.list, t => t.text) || [];

    let t;
    if (args.current) t = this.list.find(t => t.text.replace(/\n/g, '') + t.price === args.current);
    if (!t) t = this.list.find(t => !!t.optimal);
    if (!t && this.list.length > 0) t = args.package ? _.sortBy(this.list, t => t.price)[0] : this.list[0];

    this.currentId = t && t.id;
    this.segment = t && t.airExists() ? 0 : 1;

    // デフォルトの券種、席種を持つpriceの順番をセット
    if (t?.prices) {
      const priceIndex = t?.prices.findIndex(p => {
        const changeable = p.ticket_category === 'changeable';
        return args.changeableAir === changeable;
      });
      this.domesticAirPriceIndex = priceIndex >= 0 ? priceIndex : 0;
    } else {
      this.domesticAirPriceIndex = 0;
    }

    // for CSSTransition
    this.showBox = true;
    this.newId = null;
    this.newChangeable = null;

    if (args.changeableAir !== undefined) {
      this.updateAirChangeable(args.changeableAir);
    } else {
      this.updateAirChangeable(true);
    }

    if (args.handleChange) {
      this.handleChange = (prevId: string | undefined) => {
        if (args.handleChange) args.handleChange(prevId);
      };
    }

    this.filteredAirlines = [];
    this.filteredChangeablePrice = [];
    this.filteredUpgradeSeat = true;
    if (this.segment === 0 && this.list.filter(t => t.airExists() && !t.connectingAirExists()).length > 0) {
      this.showConnectingAir = true;
    } else {
      this.showConnectingAir = false;
    }
  }

  firstHandleChange() {
    if (this.handleChange) this.handleChange(undefined);
  }

  find(id: string): Transit | undefined {
    if (id) return this.list.find(t => t.id === id);
    return undefined;
  }

  current(): Transit | undefined {
    return this.currentId !== undefined ? this.find(this.currentId) : undefined;
  }

  currentChangeablePrice() {
    const current = this.current();
    return current && current.changeable_price;
  }

  currentUnchangeablePrice() {
    const current = this.current();
    return current && current.unchangeable_price;
  }

  currentIndex() {
    const current = this.current();
    if (!current) return undefined;

    const i = this.list.indexOf(current);
    if (i === -1) {
      return 0;
    }
    return i;
  }

  selectedPriceOriginalIndex(id: string, index: number | undefined) {
    const transit = this.find(id);
    // transitのpricesやpricesOriginalはnullがあり得る。（新幹線やパッケージの場合）
    if (transit?.prices == null || transit?.pricesOriginal == null) {
      return undefined;
    }
    const selectedPrice = transit?.prices[index || 0];
    if (selectedPrice) {
      return transit?.pricesOriginal.indexOf(selectedPrice);
    }
    return undefined;
  }

  select(id: string, changeable?: boolean) {
    this.list.forEach(t => {
      t.hovered = false;
    });

    const t = this.find(id);
    if (!t) return;

    if (t.airExists())
      if (
        !(
          (changeable && t.changeable_price) ||
          (!changeable && t.unchangeable_price) ||
          (t.package && t.additionalPrice !== null) ||
          (t.prices && t.prices.length > 0)
        )
      )
        return;

    const prevId = this.currentId;
    this.currentId = id;
    if (this.handleChange) this.handleChange(prevId);
    if (typeof changeable === 'undefined') {
      app.render();
    } else {
      this.setAirChangeable(changeable);
    }
  }

  selectWithAnimation(
    id: string,
    changeable: boolean | undefined,
    selectedDomesticAirPriceIndex: number | undefined
  ) {
    this.newId = id;
    this.newChangeable = changeable;
    this.domesticAirPriceIndex = this.selectedPriceOriginalIndex(id, selectedDomesticAirPriceIndex) || 0;
    app.render(); // スマホのために一度renderしてoutlineを表示しておく

    setTimeout(() => {
      this.showBox = false;
      app.render();
    }, 100); // スマホでCSSTransitionのonExitを発火させるため
  }

  handleTransitionExited() {
    this.showBox = true;
    if (this.newId && this.newChangeable !== null) this.select(this.newId, this.newChangeable);
    this.newId = null;
    this.newChangeable = null;
  }

  hasAirConnection() {
    this.list.some(t => t.segments.filter(s => s.isAir()).length >= 2);
  }

  updateAirChangeable(c: boolean) {
    if (c) {
      const t = this.current();
      if (t && t.changeable_price) this.isAirChangeable = true;
    } else {
      const t = this.current();
      if (t && t.unchangeable_price) this.isAirChangeable = false;
    }
    this.list.forEach(t => {
      t.isAirChangeable = this.isAirChangeable;
    });
  }

  setAirChangeable(c: boolean) {
    this.updateAirChangeable(c);
    app.render();
  }

  isCurrentShinkansenTooLate(): boolean {
    const current = this.current();
    return !!current && current.shinkansenTooLate();
  }

  currentShinkansenDeadline() {
    const current = this.current();
    return current && current.shinkansenDeadline();
  }

  isCurrentAirTooLate(): boolean {
    const current = this.current();
    return !!current && current.airTooLate();
  }

  includeAir() {
    return this.list.some(t => t.airExists());
  }

  includeShinkansen() {
    return this.list.some(t => t.shinkansenExists());
  }

  listBySegment(): Transit[] {
    if (this.package) return this.list;

    switch (this.segment) {
      case 0:
        return this.airList();
      case 1:
        return this.trainList();
      default:
        return this.list;
    }
  }

  airList() {
    let list = this.list.filter(t => t.airExists());
    list = list
      .map(t => {
        t.resetPricesOriginal();
        return t;
      })
      .filter(t => t.prices && t.prices.length > 0);
    if (this.showConnectingAir === true) list = list.filter(t => !t.connectingAirExists());
    if (this.filteredAirlines.length > 0) list = list.filter(t => t.isIncludeAirline(this.filteredAirlines));
    if (this.filteredChangeablePrice.length > 0) {
      const filterdList = list.map(t => {
        t.includeChangeablePrice(this.filteredChangeablePrice);
        return t;
      });
      list = filterdList.filter(t => t.prices && t.prices.length > 0);
    }
    if (this.filteredUpgradeSeat !== true) {
      const filterdList = list.map(t => {
        t.notIncludePremiumSeat();
        return t;
      });
      list = filterdList.filter(t => t.prices && t.prices.length > 0);
    }
    return list;
  }

  trainList() {
    return this.list.filter(t => !t.airExists());
  }

  length(): number {
    return this.listBySegment().length;
  }

  get isAirList(): boolean {
    return this.segment === 0;
  }

  get hasNoDirectAir(): boolean {
    return this.isAirList && this.length() === 0;
  }

  get allAirLineName(): string[] {
    return Array.from(new Set(this.list.flatMap(l => l.getAirLineName())));
  }

  isShowConnectingAir(): boolean {
    return this.showConnectingAir;
  }

  handleAirlinesChange(airlines: string[]) {
    this.filteredAirlines = airlines;
    app.render();
  }

  handleChangeablePriceChange(changeable: string[]) {
    this.filteredChangeablePrice = changeable;
    app.render();
  }

  handleFilteredUpgradeSeat(val: boolean) {
    this.filteredUpgradeSeat = val;
    app.render();
  }

  handleShowConnectingAir(val: boolean) {
    this.showConnectingAir = val;
    app.render();
  }
}

export default TransitList;
