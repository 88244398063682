import type { Dispatch, FormEvent, SetStateAction } from 'react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { styled } from '@this/constants/themes';
import { Button } from '@this/shared/ui/inputs/button';
import { FormControl, InputLabel } from '@this/shared/ui/inputs/form_control';
import { Input } from '@this/shared/ui/inputs/input';
import type { TextFieldProps } from '@material-ui/core';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../shared/ui/feedbacks/modal';

type Props = {
  tripReportId: number | null;
  errors: string[];
  setErrors: Dispatch<SetStateAction<string[]>>;
  onClose: () => void;
  onSubmit: (id: number, message: string) => void;
};

const TripReportCancel: React.FC<Props> = ({ tripReportId, onClose, ...props }) => (
  <Modal open={!!tripReportId} onClose={onClose}>
    <TripReportCancelForm tripReportId={tripReportId} onClose={onClose} {...props} />
  </Modal>
);

const TripReportCancelForm: React.FC<Props> = ({ tripReportId, errors, setErrors, onClose, onSubmit }) => {
  const textRef = useRef<TextFieldProps>({ value: '' });
  const formId = useMemo(() => `TripReportCancel--${tripReportId}`, [tripReportId]);

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (tripReportId) onSubmit(tripReportId, textRef.current.value as string);
    },
    [tripReportId]
  );

  useEffect(() => {
    setErrors([]);
  }, [tripReportId]);

  return (
    <>
      <ModalHeader>申請を取り消す</ModalHeader>
      <ModalBody>
        <form id={formId} onSubmit={handleSubmit}>
          <FormControl required fullWidth>
            <InputLabel>取り消し理由</InputLabel>
            <Input inputRef={textRef} name="code" defaultValue="" error={!!errors.length} />
            {errors.length > 0 && <Error>{errors.join('\n')}</Error>}
          </FormControl>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="sub" onClick={onClose}>
          キャンセル
        </Button>
        <Button type="submit" form={formId}>
          申請を取り消す
        </Button>
      </ModalFooter>
    </>
  );
};

const Error = styled.div`
  font-size: 12px;
  color: ${props => props.theme.redColor};
  padding: 0 8px;
`;

export default TripReportCancel;
