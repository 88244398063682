import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import A from '@this/components/shared/atoms/a';
import { MidButton } from '@this/components/shared/atoms/button';

import type SearchQuery from '../../../../../domain/search_query';
import type SearchQueryItem from '../../../../../domain/search_query_item';
import SearchIconTemplate from '../../search_icon/search_icon.template';
import SearchBlock from '../../../../shared/search/search_block/search_block';

interface SelectListAreaSearchProps {
  submitting?: boolean; // FIXME: 渡されないprops

  // Select.props
  query: SearchQuery;

  // SelectListArea.props
  queryItem: SearchQueryItem;

  // SelectListArea.state
  errors: string[];
  showSearchBox: boolean;

  // SelectListArea.methods
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  toggleShowSearchBox: () => void;
}
const SelectListAreaSearchTemplate: React.SFC<SelectListAreaSearchProps> = ({
  submitting,

  query,

  queryItem,

  errors,
  showSearchBox,

  handleSubmit,
  toggleShowSearchBox
}) => (
  <Box>
    <Link onClick={toggleShowSearchBox}>
      <IconWrap>
        <SearchIconTemplate />
      </IconWrap>
      <span>検索条件を変更する</span>
    </Link>
    {showSearchBox && (
      <form className="search" onSubmit={handleSubmit}>
        <SearchBlock item={queryItem} />
        <div className="search__button-wrapper">
          <div className="search__error">
            {_.map(errors, (error, i) => (
              <div className="error" key={i}>
                {error}
              </div>
            ))}
          </div>
          <Button type="submit" className={submitting ? 'loading' : ''}>
            検索
          </Button>
        </div>
      </form>
    )}
    <hr />
  </Box>
);

const Box = styled.div`
  margin-bottom: 10px;
`;

const Link = styled(A)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  height: fit-content;
  margin-bottom: 10px;
`;

const IconWrap = styled.div`
  width: 12px;
  height: 12px;
`;

const Button = styled.button`
  ${MidButton}
  width: 214px;
  margin: 0 auto;
`;

export default SelectListAreaSearchTemplate;
