import React from 'react';

import Typography from '@material-ui/core/Typography';

import { styled } from '@this/constants/themes';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';

import buildHTML from '../share/build_html';

import { ChatMessageForm } from './ChatMessageForm';
import type { ChatMessageFormOptions } from './ChatMessageForm';
import { ChatMessageDefaultOriginToggle } from './ChatMessageDefaultOriginToggle';
import type { ChatMessageDefaultOriginToggleOptions } from './ChatMessageDefaultOriginToggle';

interface Props extends ChatMessagesOptions {
  messages: ChatbotMessage[];
  isRestart: boolean;
  isSubmitting: boolean;
  isKnowledgeSearching: boolean;
  isMakingQuery: boolean;
}

export type ChatMessagesOptions = ChatMessageFormOptions & ChatMessageDefaultOriginToggleOptions;

const ChatMessages: React.FC<Props> = ({
  messages,
  isRestart,
  isSubmitting,
  isKnowledgeSearching,
  isMakingQuery,
  ...options
}) => {
  const length = messages.length;

  return (
    <>
      {messages[0] && <TimestampMessage restart={isRestart} createdAt={messages[0].createdAt} />}
      {messages.map((ms, i) => (
        <React.Fragment key={ms.createdAt.unix()}>
          {ms.restart && <TimestampMessage restart createdAt={ms.createdAt} />}
          {ms.author === 'bot' ? (
            <BotMessage error={ms.error}>
              <p dangerouslySetInnerHTML={{ __html: buildHTML(ms.text) }} />
              {ms.formStatus === 'active' && i === length - 1 && <ChatMessageForm message={ms} {...options} />}
            </BotMessage>
          ) : (
            <UserMessage>
              <p>{ms.text}</p>
              {ms.requireds?.includes('origin') && <ChatMessageDefaultOriginToggle message={ms} {...options} />}
            </UserMessage>
          )}
        </React.Fragment>
      ))}
      {(isSubmitting || isKnowledgeSearching || isMakingQuery) && (
        <TypingIndicatorWrapper>
          {Array.from({ length: 5 }).map((_, index) => (
            <TypingIndicator key={index} />
          ))}
        </TypingIndicatorWrapper>
      )}
    </>
  );
};

interface TimestampProps {
  restart?: boolean;
  createdAt: moment.Moment;
}

const TimestampMessage: React.FC<TimestampProps> = ({ restart, createdAt }) => {
  return (
    <Timestamp color="textSecondary">
      {createdAt.locale('en').format(restart ? 'YYYY/MM/DD hh:mm A' : 'hh:mm A')}
      <br />
      チャットが開始されました。
    </Timestamp>
  );
};

const Timestamp = styled(Typography)`
  font-size: 12px;
  margin-bottom: -12px;
  padding-left: 2px;
`;

const BotMessage = styled.div<{ error?: boolean }>`
  background: #f7f5ef;
  padding: 12px;
  border-radius: 0 16px 16px 16px;
  width: 100%;
  word-break: break-word;
  ${props => props.error && `color: ${props.theme.redColor};`}
`;

const UserMessage = styled.div`
  background: ${props => props.theme.accentColor};
  padding: 12px;
  border-radius: 16px 0 16px 16px;
  width: 100%;
  color: white;
  word-break: break-word;
`;

const TypingIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f7f5ef;
  padding: 16px 12px;
  border-radius: 0 16px 16px 16px;
  gap: 4px;
  width: fit-content;
`;

const TypingIndicator = styled.div`
  padding: 4px;
  background-color: rgba(58, 58, 58, 0.2);
  border-radius: 50%;
  animation: typing 1.5s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
  &:nth-child(4) {
    animation-delay: 0.9s;
  }
  &:nth-child(5) {
    animation-delay: 1.2s;
  }

  @keyframes typing {
    0% {
      background-color: rgba(58, 58, 58, 0.2);
    }
    50% {
      background-color: rgba(58, 58, 58, 0.4);
    }
    100% {
      background-color: rgba(58, 58, 58, 0.2);
    }
  }
`;

export default ChatMessages;
