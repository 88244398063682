import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { ButtonBase } from '@this/shared/atoms/button';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

type Props = {
  filterRef: React.MutableRefObject<any>;
  loading?: boolean;
  onSearch: (query: any) => void;
};

type StatusTarget = 'applied' | 'approved' | 'rejected';

const TripAdvanceApplicationFilter: React.FC<Props> = ({ filterRef, loading, onSearch }) => {
  const [filterParams, setFilterParamsState] = useState(getQueries());
  const paramsRef = useRef(filterParams);

  const setFilterParams = useCallback(filterParams => {
    setFilterParamsState(filterParams);
    paramsRef.current = filterParams;
  }, []);

  const handleTravelerNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterParams({ ...filterParams, travelerName: e.target.value });
    },
    [filterParams]
  );

  const handleOrderByChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setFilterParams({ ...filterParams, orderBy: e.target.value });
    },
    [filterParams]
  );

  const handleDateChange = useCallback(
    (field: 'fromDate' | 'toDate') => (date: Moment | null) => {
      setFilterParams({ ...filterParams, [field]: date ?? undefined });
    },
    [filterParams]
  );

  const handleStatusChange = useCallback(
    (target: StatusTarget) => {
      setFilterParams({
        ...filterParams,
        showApplied: target === 'applied',
        showApproved: target === 'approved',
        showRejected: target === 'rejected'
      });
    },
    [filterParams]
  );

  const searchQuery = useCallback(() => {
    const { fromDate, toDate, travelerName, showApplied, showApproved, showRejected, orderBy } = paramsRef.current;
    return {
      from_date: fromDate?.format('YYYY-MM-DD'),
      to_date: toDate?.format('YYYY-MM-DD'),
      traveler_name: travelerName,
      show_applied: showApplied,
      show_approved: showApproved,
      show_rejected: showRejected,
      order_by: orderBy
    };
  }, []);

  filterRef.current = { handleStatusChange, searchQuery };

  const handleSearch = useCallback(() => {
    const query = searchQuery();

    const q = _.join(
      _.map(query, (v, k) => `${k}=${v}`),
      '&'
    );
    const url = `${location.pathname}?${q}`;
    window.history.pushState(null, '', url);

    onSearch(query);
  }, [searchQuery]);

  const handleClickStatus = useCallback(
    (target: StatusTarget) => () => {
      handleStatusChange(target);
      handleSearch();
    },
    [handleStatusChange, handleSearch]
  );

  return (
    <Search>
      <StatusField className="statusField">
        <StatusButton
          className={`${filterParams.showApplied ? 'selected' : ''} ${loading ? 'disabled' : ''}`}
          onClick={handleClickStatus('applied')}
        >
          未承認
        </StatusButton>
        <StatusButton
          className={`${filterParams.showApproved ? 'selected' : ''} ${loading ? 'disabled' : ''}`}
          onClick={handleClickStatus('approved')}
        >
          承認済み
        </StatusButton>
        <StatusButton
          className={`${filterParams.showRejected ? 'selected' : ''} ${loading ? 'disabled' : ''}`}
          onClick={handleClickStatus('rejected')}
        >
          却下済み
        </StatusButton>
      </StatusField>
      <SearchFields className="searchFields">
        <SearchField>
          <SearchTitle>出張者</SearchTitle>
          <SearchInput>
            <input
              type="text"
              placeholder="出張者"
              value={filterParams.travelerName}
              onChange={handleTravelerNameChange}
            />
          </SearchInput>
        </SearchField>
        <SearchField>
          <SearchTitle>出張日</SearchTitle>
          <SearchInput>
            <DatetimePicker
              dateFieldId="filterParams-fromDate"
              value={filterParams.fromDate}
              onChange={handleDateChange('fromDate')}
              placeholder="開始日"
              border
              showToday
              showPast
              width="140px"
              deletable
            />
            <span>〜</span>
            <DatetimePicker
              dateFieldId="filterParams-toDate"
              value={filterParams.toDate}
              onChange={handleDateChange('toDate')}
              placeholder="終了日"
              border
              showToday
              showPast
              width="140px"
              deletable
            />
          </SearchInput>
        </SearchField>
        <SearchField>
          <SearchTitle>並び順</SearchTitle>
          <SearchInput>
            <select value={filterParams.orderBy} onChange={handleOrderByChange}>
              <option key="start" value="start">
                出発日順
              </option>
              <option key="create" value="create">
                依頼日順
              </option>
            </select>
          </SearchInput>
        </SearchField>
        <SearchField>
          <SearchButton className={loading ? 'disabled' : undefined} onClick={handleSearch}>
            検索
          </SearchButton>
        </SearchField>
      </SearchFields>
    </Search>
  );
};

const getQueries = () => {
  const params = utils.getParams();
  /**
   * from_dateとend_dateが指定されてない場合はデフォルト値として、開始日に今日の日付をいれる。
   * 明示的にundefinedが指定されてる場合、開始日終了日を明示的に未指定で検索したとして、undefinedをセットする
   */
  const from = utils.dig(params, 'from_date');
  const fromDate = from ? (from === 'undefined' ? undefined : moment(from)) : moment();
  const to = utils.dig(params, 'to_date');
  const toDate = to && to !== 'undefined' ? moment(to) : undefined;
  const showApplied = _.isNil(utils.dig(params, 'show_applied'))
    ? true
    : utils.dig(params, 'show_applied') === 'true';
  const showApproved = utils.dig(params, 'show_approved') === 'true';
  const showRejected = utils.dig(params, 'show_rejected') === 'true';

  return {
    fromDate,
    toDate,
    travelerName: utils.dig(params, 'traveler_name') || '',
    showApplied,
    showApproved,
    showRejected,
    orderBy: utils.dig(params, 'order_by') || 'start'
  };
};

const Search = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 8px;
  margin-bottom: 20px;
`;

const StatusField = styled.div`
  display: flex;
  column-gap: 8px;
`;

const StatusButton = styled.div`
  padding: 5px 10px;
  background: ${props => props.theme.grayBgColor};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &.disabled {
    background: ${props => props.theme.grayBgColor};
    pointer-events: none;
    opacity: 0.5;
  }

  &.selected {
    color: #ffffff;
    background-color: #374151;
    opacity: 1;
  }
`;

const SearchFields = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 8px;
  align-items: flex-end;

  ${media.sp`
    flex-wrap: wrap;
  `}
`;

const SearchField = styled.div`
  display: flex;
  flex-flow: column;
`;

const SearchTitle = styled.label`
  font-size: 12px;
  color: ${props => props.theme.grayTextColor};
`;

const SearchInput = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  column-gap: 4px;

  input[type='text'] {
    margin-bottom: 0;
  }

  label {
    font-weight: normal;
    cursor: pointer;
    margin-right: 10px;
  }

  input[type='checkbox'] {
    cursor: pointer;
  }

  & .single-calendar-input {
    width: 120px !important;
  }

  select,
  #filterParams-fromDate,
  #filterParams-toDate {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 6%);
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
    font-size: 1em;
    padding: 0.5em;
    margin-bottom: 0;
    transition: border-color;
    width: 100%;
    height: auto;
  }

  select {
    font-size: 14px;
  }
`;

const SearchButton = styled.a`
  ${ButtonBase};
  padding: 5px 10px;
  margin-left: auto;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default TripAdvanceApplicationFilter;
