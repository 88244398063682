import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import ContentBody from '@this/components/shared/atoms/content_body';

import ContentHeader from '@this/components/shared/content_header/content_header';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import TripReportBodyTemplate from '../trip_report_body.template';

const TripReport = require('../../../../domain/trip_report');

type Props = RouteComponentProps<{ id: string }>;

interface State {
  user: any;
  report: any;
  loading: boolean;
  error: string | null;
}

interface TripReportResult {
  user: any;
  trip_report: any;
  show_fee: boolean;
}

class TripReportDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: null,
      report: null,
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    utils
      .jsonPromise<TripReportResult>(`/trip_reports/${this.props.match.params.id}`)
      .then(
        result => {
          this.setState({
            user: result.user,
            report: new TripReport(result.trip_report),
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  render() {
    try {
      const { user, report, loading, error } = this.state;
      return (
        <Wrap>
          <Body>
            <ContentHeader title="出張報告" backButton />
            <BodyIn>
              {loading ? (
                <SimpleLoading />
              ) : error ? (
                <Error>{error}</Error>
              ) : user && report ? (
                <>
                  <Status className={report.statusClass()}>{report.statusDescription()}</Status>
                  <TripReportBodyTemplate user={user} report={report} />
                </>
              ) : (
                <></>
              )}
            </BodyIn>
          </Body>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  min-height: 100vh;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
  min-height: 100vh;
  padding: 20px;
  background: ${props => props.theme.contentBgColor};

  ${media.sp`
    padding: 0;
    background: #fff;
  `};
`;

const BodyIn = styled.div`
  padding: 20px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Status = styled.div`
  text-align: right;

  .gray {
    color: ${props => props.theme.grayTextColor};
  }
  .yellow {
    color: #e5aa00;
  }
  .green {
    color: ${props => props.theme.successColor};
  }
`;

export default TripReportDetail;
