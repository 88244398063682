import React from 'react';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import type Trip from '@this/domain/trip/trip';
import type { Message } from '@this/components/trips_management/trips/trips_chat/types';
import VirtualCounterMessageBody from './body';

interface Props {
  message: Message;
  selectedTrip: Trip;
  fetchMessages: (trip: Trip) => void;
  handleMessageEdit: (messageId: number, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const PartnerMessage: React.FC<Props> = ({ message, selectedTrip, fetchMessages, handleMessageEdit }) => {
  return (
    <PartnerMessageWrap>
      <MessageLeft>
        <MessageReadStatus>{message.user_read ? '[ 既読 ]' : '[ 未読 ]'}</MessageReadStatus>
      </MessageLeft>
      <MessageCenter>
        <ArrangerName>{message.arranger_name || 'コンシェルジュデスク'}</ArrangerName>
        <MessageBox>
          <VirtualCounterMessageBody
            message={message}
            selectedTrip={selectedTrip}
            fetchMessages={fetchMessages}
            handleMessageEdit={handleMessageEdit}
          />
        </MessageBox>
      </MessageCenter>
      <MessageRight>
        <PartnerIcon data-testid="partnerMessageIcon" />
        <MessageTime>
          {moment(message.created_at).format('MM/DD')}
          <br />
          {moment(message.created_at).format('HH:mm')}
        </MessageTime>
      </MessageRight>
    </PartnerMessageWrap>
  );
};

const PartnerMessageWrap = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  margin-left: auto;
`;

export const MessageBox = styled.div`
  border: 1px solid ${props => props.theme.grayBorderColor};
  border-radius: 5px;
  padding: 10px;
`;

const MessageReadStatus = styled.span`
  position: absolute;
  bottom: 10px;
  right: 0;
  font-size: 10px;
`;

const ArrangerName = styled.div`
  text-align: right;
  margin-bottom: 5px;
`;

const PartnerIcon = styled.div`
  margin-bottom: 7px;
  padding: 30%;
  background: url('/images/partner_icon.png') center center / 55px auto no-repeat;
  width: 60px;
  height: 55px;
`;

const MessageTime = styled.div`
  line-height: 1.1;
`;

export const MessageLeft = styled.div`
  flex-shrink: 0;
  width: 60px;
  position: relative;
  margin-right: 10px;
  text-align: center;
`;

export const MessageCenter = styled.div`
  flex-grow: 9999;
`;

const MessageRight = styled.div`
  flex-shrink: 0;
  width: 60px;
  text-align: center;
  margin-left: 10px;
`;

export default PartnerMessage;
