import React from 'react';
import Media from 'react-media';
import type { QueryResults } from 'react-media';
import { useTheme } from '@material-ui/core';

type Query = {
  sm: { maxWidth: number };
  md: { minWidth: number; maxWidth: number };
  lg: { minWidth: number };
};

type Props = { children: (matches: QueryResults<Query>) => React.ReactNode };

export const ByMedia: React.FC<Props> = ({ children }) => {
  const {
    breakpoints: {
      values: { sm, md }
    }
  } = useTheme();
  const queries = React.useMemo<Query>(
    () => ({
      sm: { maxWidth: sm - 1 },
      md: { minWidth: sm, maxWidth: md - 1 },
      lg: { minWidth: md }
    }),
    [sm, md]
  );

  return <Media<Query> queries={queries}>{matches => children(matches)}</Media>;
};
