import React from 'react';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { styled } from '@this/constants/themes';
import type { AnnouncementJson } from '../../../domain/expenses/announcement';
import { Announcement } from '../../../domain/expenses/announcement';

interface State {
  announcement?: Announcement;
  loading: boolean;
  error?: string;
}

type Props = any;

export default class AnnouncementMessage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    await this.fetchAnnouncement();
  }

  private async fetchAnnouncement() {
    try {
      this.setState({ loading: true });
      const response = await utils.jsonPromise<AnnouncementJson>('/biztra/announcements.json');
      const announcement = new Announcement(response);
      this.setState({
        announcement,
        loading: false
      });
    } catch (e) {
      this.setState({
        error: '通信エラーが発生しました。時間をおいて再度お試しください。',
        loading: false
      });
    }
  }

  render() {
    const { announcement, loading, error } = this.state;
    return (
      <AnnouncementWrapper>
        {loading ? (
          <div className="expenses-announcement">
            <SimpleLoading />
          </div>
        ) : error ? (
          <div className="expenses-announcement">
            <p>{error}</p>
          </div>
        ) : announcement !== undefined && announcement.body !== undefined && announcement.body.length > 0 ? (
          <div className="expenses-announcement">
            <pre className="expenses-announcement__body">{announcement.body}</pre>
          </div>
        ) : (
          <></>
        )}
      </AnnouncementWrapper>
    );
  }
}

const AnnouncementWrapper = styled.div`
  .expenses-announcement {
    background: white;
    width: 95%;
    max-width: 950px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

    &__body {
      background: white;
      border: none;
      margin: 0;
      padding: 0;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`;
