import React from 'react';
import { styled } from '@this/constants/themes';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type FreeePostDealHistoryList from '@this/src/domain/freee/freee_post_deal_history_list';
import { Button } from '@this/src/components/shared/ui/inputs/button';

interface Props {
  freeePostDealHistoryList: FreeePostDealHistoryList;
  loading: boolean;
  loadingError: string | null;
  submitting: boolean;
  actionDisabled?: boolean;
  postFreeeDeals: (method?: string) => void;
  resetFreeeDeals: (historyId: number) => void;
}

const FreeeHistories: React.FC<Props> = ({
  freeePostDealHistoryList,
  loading,
  loadingError
  // actionDisabled,
  // postFreeeDeals,
  // resetFreeeDeals,
  // ...props
}) => {
  if (loading) return <SimpleLoading />;
  if (loadingError) return <Errors>{loadingError}</Errors>;
  if (freeePostDealHistoryList.list.length === 0) return null;

  // const submitting = props.submitting || freeePostDealHistoryList.submitting;

  return (
    <Table>
      <tbody>
        <tr>
          <Th>登録番号</Th>
          <Th>実行種別</Th>
          <Th>登録方法</Th>
          <Th>ステータス</Th>
          <Th>開始時刻</Th>
          <Th>終了時刻</Th>
          <Th>件数</Th>
          <Th>請求書</Th>
          {/* 取引の削除機能は機能提供範囲外とする（操作対象のアクションが無いため非表示にする） */}
          {/* https://aitravel.atlassian.net/browse/AITRAVEL-4889?focusedCommentId=32866 */}
          {/* <Th>操作</Th> */}
        </tr>
        {freeePostDealHistoryList.list.map(history => (
          <tr key={`${history.historyId}-${history.actionType}`}>
            <Td>{history.index}</Td>
            <Td>{history.actionTypeText}</Td>
            <Td>{history.methodText}</Td>
            <Td>{history.statusText}</Td>
            <Td>{history.startedAtText}</Td>
            <Td>{history.finishedAtText}</Td>
            <Td>{history.countText}</Td>
            <Td>
              <Actions>
                <Button
                  size="small"
                  href={`/organization/invoices/${history.invoiceId}/invoice_pdf_qualified.pdf`}
                >
                  適格請求書
                </Button>
              </Actions>
            </Td>
            {/* <Td>
              <Actions>
                {history.actions.map(
                  action =>
                    action === 'remove' && (
                      <Button
                        key={action}
                        size="small"
                        disabled={submitting || actionDisabled}
                        onClick={() => resetFreeeDeals(history.historyId)}
                      >
                        削除連携
                      </Button>
                    )
                )}
              </Actions>
            </Td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  table-layout: auto !important;
`;

const Th = styled.th`
  padding: 4px;
`;

const Td = styled.td`
  padding: 4px;
`;

const Actions = styled.div`
  display: flex;
  gap: 4px;
`;

const Errors = styled.div`
  color: ${props => props.theme.redColor};
`;

export default FreeeHistories;
