import { action, computed, observable } from 'mobx';

export interface InvoiceItemTaxArgs {
  id?: number;
  invoice_id?: number;
  item?: string;
  amount?: number;
  include_tax?: boolean;
  price_without_tax?: number;
  tax?: number;
  price_with_tax?: number;
  tax_type_id?: number;
  create_manually?: boolean;
}

class InvoiceItemTax {
  @observable
  id: number | null;

  @observable
  invoiceId: number | null;

  @observable
  item: string;

  @observable
  amount: number;

  @observable
  includeTax: boolean;

  @observable
  priceWithoutTax: number;

  @observable
  tax: number;

  @observable
  priceWithTax: number;

  @observable
  taxTypeId: number | null;

  @observable
  createManually: boolean;

  @observable
  consumingTaxRatio = 0.1;

  constructor(args: InvoiceItemTaxArgs) {
    this.id = args.id || null;
    this.invoiceId = args.invoice_id || null;
    this.item = args.item || '';
    this.amount = args.amount || 1;
    this.includeTax = args.include_tax || false;
    this.priceWithTax = args.price_with_tax || 0;
    this.tax = args.tax || 0;
    this.priceWithoutTax = args.price_without_tax || 0;
    this.taxTypeId = args.tax_type_id || null;
    this.createManually = args.create_manually || false;
  }

  @computed
  get calcTax(): number {
    if (this.includeTax) {
      return 0;
    }
    // 一旦既存と合わせたまま
    // 本来は画面上で税率を設定できるようにすべきとの記載が、invoice_itemにあった
    return Math.floor(this.priceWithTax * this.amount * this.consumingTaxRatio);
  }

  @action
  setUnitPrice(unitPrice: number) {
    if (this.includeTax) {
      this.priceWithTax = unitPrice;
    } else {
      this.priceWithoutTax = unitPrice;
    }
    this.tax = this.calcTax;
  }

  @action
  setAmount(amount: number) {
    this.amount = amount;
    this.tax = this.calcTax;
  }

  @action
  setIncludeTax(includeTax: boolean) {
    this.includeTax = includeTax;
    this.tax = this.calcTax;
  }

  // @computed
  unitPrice() {
    return this.includeTax ? this.priceWithTax : this.priceWithoutTax;
  }

  @computed
  get calcPrice(): number {
    return this.unitPrice() * this.amount;
  }

  submitParams(): object {
    return {
      id: this.id,
      invoice_id: this.invoiceId,
      item: this.item,
      amount: this.amount,
      include_tax: this.includeTax,
      price_with_tax: this.priceWithTax,
      tax: this.tax,
      price_without_tax: this.priceWithoutTax,
      tax_type_id: this.taxTypeId,
      create_manually: this.createManually
    };
  }
}

export default InvoiceItemTax;
