import _ from 'lodash';
import type { TransportType } from '../transport_element';
import type { ExpensesAccountTypeJson } from './expenses_account_type';
import ExpensesAccountType from './expenses_account_type';
import type { Item } from './item';
import type { TaxTypeArgs } from '../tax_type';
import TaxType, { TAX_TYPE_ID } from '../tax_type';

export interface ExpensesTypeJson {
  id: number;
  name: string;
  category: string;
  category_options: { [key: string]: string };
  expenses_account_type: ExpensesAccountTypeJson;
  code: string;
  is_deleted: boolean;
  display_order: number;
  tax_type_id?: number;
  tax_type?: TaxTypeArgs;
}

export class ExpensesType {
  id: number;

  name: string;

  category: string;

  categoryOptions: { [key: string]: string };

  expensesAccountType?: ExpensesAccountType;

  code?: string;

  isDeleted?: boolean;

  displayOrder: number | null;

  taxTypeId: number;

  taxType?: TaxType;

  constructor(args: ExpensesTypeJson) {
    this.id = args.id;
    this.name = args.name;
    this.category = args.category;
    this.categoryOptions = args.category_options;
    this.expensesAccountType = args.expenses_account_type && new ExpensesAccountType(args.expenses_account_type);
    this.code = args.code;
    this.isDeleted = args.is_deleted;
    this.displayOrder = args.display_order;
    this.taxTypeId = args.tax_type_id || TAX_TYPE_ID.TAXABLE_10_ID;
    this.taxType = args.tax_type && new TaxType(args.tax_type);
  }

  setName(value: string) {
    this.name = value;
    app.render();
  }

  setExpensesAccountType(value: ExpensesAccountType) {
    this.expensesAccountType = value;
    app.render();
  }

  setCategry(value: string) {
    this.category = value;
    app.render();
  }

  setCode(value: string) {
    this.code = value;
    app.render();
  }

  setTaxTypeId(value: number) {
    this.taxTypeId = value;
    app.render();
  }

  isTrain(): boolean {
    return this.name === '電車代';
  }

  isBus(): boolean {
    return this.name === 'バス代';
  }

  isTaxi(): boolean {
    return this.name === 'タクシー代';
  }

  isReception(): boolean {
    return this.category === 'reception_expenses';
  }

  isDailyAllowance(): boolean {
    return this.name === '出張日当';
  }

  isRequiredUpdateMemo(): boolean {
    const targetNames = ['出張日当', '電車代', 'バス代', 'タクシー代', '飛行機代', '高速代', '旅費交通費'];
    const targetCategories = ['travel_expenses', 'travel_expenses_daily_allowance'];
    return targetNames.indexOf(this.name) !== -1 || targetCategories.indexOf(this.category) !== -1;
  }

  isNeedParticipants(): boolean {
    const targetTypes = ['会議費', '接待飲食費', '接待交際費'];
    const targetCategories = ['reception_expenses'];
    return targetTypes.indexOf(this.name) !== -1 || targetCategories.indexOf(this.category) !== -1;
  }

  mapTransportType(): TransportType {
    const transportItems: Record<string, TransportType> = {
      電車代: 'railway_ticket',
      バス代: 'bus',
      タクシー代: 'taxi',
      飛行機代: 'domestic_air'
    };

    return transportItems[this.name] || 'other';
  }

  submitParams() {
    if (!this.expensesAccountType) return null;
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      expenses_account_type_id: this.expensesAccountType.id,
      code: this.code,
      display_order: this.displayOrder,
      tax_type_id: this.taxTypeId
    };
  }

  categoryName() {
    return _.find(this.categoryOptions, (_, key: string) => key === this.category);
  }

  nameText() {
    let name = '';
    if (this.name && this.expensesAccountType) {
      name = `${this.expensesAccountType.isDeletedName}:${this.isDeletedName()}`;
    } else if (this.expensesAccountType) {
      name = `${this.expensesAccountType.isDeletedName}:`;
    } else if (this.isDeletedName()) {
      name = `${this.isDeletedName()}:`;
    }
    return name;
  }

  isDeletedName() {
    if (this.isDeleted) {
      return `${this.name}(削除済)`;
    }
    return this.name;
  }

  filterDeletedpulldownList(item: Item) {
    return this.isDeletedExpensesOrAcountType() || this.isSelectedDeleteExpenses(item);
  }

  isDeletedExpensesOrAcountType() {
    return (
      this.isDeleted === false &&
      !_.isEmpty(this.expensesAccountType) &&
      this.expensesAccountType!.isDeleted === false
    );
  }

  isSelectedDeleteExpenses(item: Item) {
    return (
      !_.isEmpty(this.expensesAccountType) &&
      (this.isDeleted === true || this.expensesAccountType!.isDeleted === true) &&
      item.expensesType.id === this.id
    );
  }
}

export default ExpensesType;
