import React, { useCallback, useEffect, useMemo } from 'react';
import { TripReportContext, TripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { TripReportForm } from './trip_report_form/trip_report_form';

interface Props {
  serviceId: number;
  allowanceAvailable: boolean;
}

export const TripReportNew: React.FC<Props> = ({ serviceId, allowanceAvailable }) => {
  const tripReports = useMemo(() => new TripReportStore({ serviceId, loading: true, allowanceAvailable }), []);

  const fetchCurrentUser = useCallback(() => {
    tripReports.getNew();
  }, []);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  return (
    <TripReportContext.Provider value={tripReports}>
      <TripReportForm />
    </TripReportContext.Provider>
  );
};
