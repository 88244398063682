import React, { useMemo } from 'react';
import type TripApproveItemJson from '@this/domain/trip_approve_item/trip_approve_item_json';
import { styled } from '@this/constants/themes';
import TranslateIgnoreText from '@this/shared/text/translate_ignore_text';
import moment from '@this/lib/moment';

type Props = {
  tripId: number;
  tripApproveItems: string | TripApproveItemJson[] | null;
  label?: string;
};

export const ApproveItemList = ({ tripId, tripApproveItems, label }: Props) => {
  const parseApproveItems = (items: string | TripApproveItemJson[] | null): TripApproveItemJson[] => {
    if (!items) return [];

    try {
      return typeof items === 'string' ? JSON.parse(items) : items;
    } catch (error) {
      return [];
    }
  };

  const renderItemContent = (item: TripApproveItemJson) => {
    if (item.dataType === 'file') {
      const href = item.filePath
        ? `/trips/${tripId}/approve_item_files/${item.id}`
        : `/trips/${tripId}/approve_item_files_base64/${item.id}`;
      return (
        <ApplicationInfoText>
          <a href={href} target="_blank" rel="noreferrer">
            <FileLinkIcon src="/images/clip_highlighted.png" />
          </a>
        </ApplicationInfoText>
      );
    }

    return (
      <ApplicationInfoText>
        <TranslateIgnoreText text={item.valueCode ? `${item.valueCode}：${item.value}` : item.value} />
      </ApplicationInfoText>
    );
  };

  const approveItems = useMemo(() => {
    return parseApproveItems(tripApproveItems).filter(item => item.dataType !== 'label');
  }, [tripApproveItems]);

  if (!approveItems?.length) return null;

  return (
    <>
      {label && <p>{label}</p>}
      {approveItems.map((item: TripApproveItemJson) => {
        return (
          <InfoSection key={item.id}>
            <ApplicationInfoLabel>{item.userDisplayName}</ApplicationInfoLabel>
            {item.dataType !== 'calendar' && renderItemContent(item)}
            {item.dataType === 'calendar' && (
              <ApplicationInfoText>
                <TranslateIgnoreText text={item.value ? moment(item.value).format('MM月DD日') : ''} />
              </ApplicationInfoText>
            )}
          </InfoSection>
        );
      })}
    </>
  );
};

const InfoSection = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const ApplicationInfoLabel = styled.div`
  padding: 2px 10px 2px 8px;
  flex: 0 0 160px;
  word-break: break-all;
`;

const ApplicationInfoText = styled.span`
  padding: 2px 0;
  word-break: break-all;
  max-width: 60%;
  flex: 1 1 auto;
  max-width: calc(100% - 160px);
  display: block;
`;

const FileLinkIcon = styled.img`
  width: 14px;
`;
