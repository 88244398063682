import React from 'react';
import type { TripReport } from '@this/src/domain/trip_report/trip_report';
import type { NonOrderItemForReport } from '@this/src/domain/trip_report/non_order_item';
import type Project from '@this/src/domain/project/project';
import { Divider } from '@material-ui/core';
import type { AllowanceItemForReport } from '@this/domain/trip_report/allowance_item';
import { useTripReportStore } from '@this/domain/trip_report/trip_report_store';
import { Heading } from '@this/shared/ui/data_displays/typography';
import {
  BetweenRow,
  Body,
  GrayLabel,
  LeftWrap,
  PriceLabel,
  PriceRow,
  PriceSection,
  RightWrap,
  Text,
  TextSpan,
  TotalSection,
  WrapLabel,
  Wrapper,
  WrapRow
} from './trip_card';

type Props = {
  serviceId: number;
  tripReport: TripReport;
  nonOrderItems: NonOrderItemForReport[];
  allowanceItems: AllowanceItemForReport[];
  projects: Project[];
  totalAdvance: number;
  totalAmount: number;
  totalAllowance: number;
};

const NonOrderItemsCard: React.FC<Props> = ({
  serviceId,
  tripReport,
  nonOrderItems,
  allowanceItems,
  projects,
  totalAdvance,
  totalAmount,
  totalAllowance
}) => {
  const store = useTripReportStore();
  const allowanceAvailable = store.allowanceAvailable;

  if (nonOrderItems.length < 1 && allowanceItems.length < 1) return <></>;
  return (
    <Wrapper>
      <Heading level="3">{serviceId !== 2 ? 'その他の立替経費' : 'その他の自己手配経費'}</Heading>
      <Body>
        <LeftWrap>
          <WrapRow>
            <WrapLabel>申請者</WrapLabel>
            <Text>{tripReport.userName}</Text>
          </WrapRow>
          {projects.length > 0 && <Divider />}
          {projects.map(project => (
            <BetweenRow key={`project-${project.id}`}>
              <GrayLabel>プロジェクトコード</GrayLabel>
              <Text>{project.code || project.name}</Text>
            </BetweenRow>
          ))}
        </LeftWrap>
        <RightWrap>
          <TotalSection>
            <label>合計金額</label>
            <Text bold>{totalAmount > 0 ? `¥${totalAmount.toLocaleString()}` : '─'}</Text>
          </TotalSection>
          <label>【申請内容】</label>
          <PriceSection>
            <PriceLabel>{serviceId !== 2 ? '社員立替金額' : '自己手配経費'}</PriceLabel>
            {nonOrderItems.map((item, i) => (
              <PriceRow key={`nonOrderItem-${i}`}>
                <Text>{item.expensesType?.name || item.payee}</Text>
                <Text>{item.getPrice()}</Text>
              </PriceRow>
            ))}
            <Divider />
            <BetweenRow>
              <label>合計</label>
              <Text bold>{totalAdvance.toLocaleString()}円</Text>
            </BetweenRow>
          </PriceSection>
          {allowanceAvailable && allowanceItems.length > 0 && (
            <PriceSection>
              <PriceLabel>日当</PriceLabel>
              {allowanceItems.map((item, i) => (
                <PriceRow key={`nonOrderItem-${i}`}>
                  <Text>
                    {item.getTime()}
                    <TextSpan>{item.getAllowanceTypeName()}</TextSpan>
                  </Text>
                  <Text>{item.getPrice()}</Text>
                </PriceRow>
              ))}
              <Divider />
              <BetweenRow>
                <label>合計</label>
                <Text bold>{totalAllowance.toLocaleString()}円</Text>
              </BetweenRow>
            </PriceSection>
          )}
        </RightWrap>
      </Body>
    </Wrapper>
  );
};

export default NonOrderItemsCard;
