import type { MouseEvent } from 'react';
import React from 'react';
import { styled } from '@this/constants/themes';
import { withTheme } from 'styled-components';
import Modal from '@this/shared/modal/modal';
import Confirm from '@this/shared/confirm/confirm';
import { Link } from '@this/shared/ui/navigations/link';

interface Props {
  show: boolean;
  submitting: boolean;
  trip: any;
  service: { name: string; tel: string };
  handleHide: () => void;
  handleSubmit: (e?: MouseEvent<HTMLAnchorElement>) => void;
  theme: { themeClass: string };
}

const CancelTripModal: React.FC<Props> = ({
  show,
  submitting,
  trip,
  service,
  handleHide,
  handleSubmit,
  children,
  theme
}) => {
  return (
    <Modal show={show} hideModal={handleHide} maxWidth={1200} title="下記の旅程をキャンセル依頼する">
      {trip.status === 5 ? (
        <p>この旅程はキャンセル済みです</p>
      ) : trip.status === 6 ? (
        <p>
          この旅程はキャンセル対応中です。
          <br />
          対応が完了するまで少々 お待ち下さい。
        </p>
      ) : submitting ? (
        <p>処理中...</p>
      ) : (
        children && (
          <ModalContentWrapper>
            <ModalContent>
              {children}
              {theme.themeClass === 'tabikobo' && trip.foreign_air && (
                <CancelFee>
                  {`キャンセル手数料：${trip.cancelFeeText()}`}
                  <br />
                  航空券発券後は、キャンセル手数料の他、キャンセル規程に従いキャンセル料が発生します。
                </CancelFee>
              )}
            </ModalContent>
            <CancelConfirmPolicy>
              <>
                <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>[ご利用当日のキャンセル依頼］</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
                  <li>
                    当日のキャンセルは、ご自身でご対応をお願いいたします。
                    {theme.themeClass !== 'tabikobo' && (
                      <>
                        （キャンセル方法は
                        <Link isExternal href="https://aitravel.jp/knowledges/000001075">
                          こちら
                        </Link>
                        をご確認ください）
                      </>
                    )}
                  </li>
                  <li>
                    ご自身でキャンセルした場合は、弊社にてご請求データを更新する必要がございます。キャンセルがお済みになりましたら、その旨チャットよりお知らせください。
                  </li>
                </ul>

                <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>[キャンセル料金について]</p>
                <ul style={{ listStyle: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
                  <li>キャンセルした場合、キャンセル規程に則りキャンセル料金が発生する場合がございます。</li>
                  <li>各提携先の規程により、返金できない場合がございます。</li>
                  <li>返金不可プラン等、キャンセル料金が100%発生している場合、返金はございません。</li>
                  <li>キャンセルご依頼後、キャンセルの取り消しはできかねます。</li>
                  <li>弊社事務手数料は、返金対象外でございます。</li>
                  <li>旅程内の一部商品のみをキャンセルしたい場合、その旨をチャットにてご連絡ください。</li>
                </ul>

                <p style={{ marginBottom: '20px' }}>質問、不明点がある場合は下記までお問い合わせください。</p>
                <table>
                  <tbody>
                    <tr>
                      <th>ツール</th>
                      <th>詳細</th>
                    </tr>
                    <tr>
                      <td>チャット</td>
                      <td>
                        <Link href={`/trips/${trip.id}`}>チャットへのリンク</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>電話番号</td>
                      <td>{service.tel}</td>
                    </tr>
                    <tr>
                      <td>問い合わせフォーム</td>
                      <td>
                        <Link href="/inquiries/new">お問い合わせフォームへのリンク</Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: '40px' }}>
                  ※平日18時以降、土日、祝日にご依頼いただいたキャンセル対応は翌営業日の対応になります。予めご了承くださいますようお願い申し上げます。
                </p>
                <Confirm
                  message="この予約を本当にキャンセル依頼しますか？"
                  onConfirmed={handleSubmit}
                  onAborted={handleHide}
                />
              </>
            </CancelConfirmPolicy>
          </ModalContentWrapper>
        )
      )}
    </Modal>
  );
};

const CancelFee = styled.p`
  margin-bottom: 10px;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ModalContent = styled.div`
  display: block;
  max-width: 50%;
`;

const CancelConfirmPolicy = styled.div`
  padding: 20px;
  max-width: 500px;
  margin-left: auto;
  background: #eee;
`;

export default withTheme(CancelTripModal);
