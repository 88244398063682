BulkTicketRoute = require('./bulk_ticket_route')
BulkTicketUnavailableTerm = require('./bulk_ticket_unavailable_term')

class BulkTicket
  constructor: (args = {}) ->
    _.assign @, snakeToCamelObject(args)
    @route = new BulkTicketRoute(args.route) if args.route
    @term = new BulkTicketUnavailableTerm(args.term) if args.term

  # @unusedはサーバーには送らない、クライアント側で回数券の残数を"予測"するための変数
  # (利用のコンフリクトなどが起きうるため)
  # 利用の紐づけ・解除が手動なので、あくまでも目安
  use: () ->
    @unused -= 1
    app.render()

  cancelUse: () ->
    @unused += 1
    app.render()

  pricePerTicket: ->
    return 0 if @amount == 0
    @price / @amount

  routeDescription: (routes) ->
    if routes
      route = routes.find (route) => route.id == @routeId
      route.description() if route
    else
      dig(@route, 'description')

  termDescription: (terms) ->
    if terms
      term = terms.find (term) => term.id == @unavailableTermId
      term.description() if term
    else
      dig(@term, 'description')

  seatTypeDescription: ->
    seatTypes = { reserved: '指定席', unreserved: '自由席', green: 'グリーン車' }
    seatTypes[@seatType]

  ticketTypeDescription: ->
    ticketTypes = { both: '乗車券＋特急券', basic: '乗車券', express: '特急券' }
    ticketTypes[@ticketType]

  summary: ->
    (@bulkTicketNumber || '') + ':' + @routeDescription() + ' ' + ' 残数:' + @unused + ' 購入日:' + @purchasedAt

  setId: (id) ->
    @id = parseInt id
    app.render()

  setRouteId: (id) ->
    @routeId = parseInt id
    app.render()

  setUnavailableTermId: (id) ->
    @unavailableTermId = parseInt id
    app.render()

  setSeatType: (type) ->
    @seatType = type
    app.render()

  setTicketType: (type) ->
    @ticketType = type
    app.render()

  setAmount: (amount) ->
    @amount = amount
    app.render()

  setPrice: (price) ->
    @price = price
    app.render()

  setPurchasedAt: (date) ->
    @purchasedAt = date
    app.render()

  setAvailableFrom: (date) ->
    @availableFrom = date
    app.render()

  setExpireAt: (date) ->
    @expireAt = date
    app.render()

  setFee: (value) ->
    @fee = value
    app.render()

  setBulkTicketNumber: (value) ->
    @bulkTicketNumber = value
    app.render()

  submitParams: ->
    bulk_ticket_number: @bulkTicketNumber
    route_id: @routeId
    seat_type: @seatType
    ticket_type: @ticketType
    amount: @amount
    price: @price
    fee: @fee
    purchased_at: @purchasedAt
    available_from: @availableFrom
    expire_at: @expireAt
    unavailable_term_id: @unavailableTermId

module.exports = BulkTicket
