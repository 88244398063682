import _ from 'lodash';
import moment from '@this/lib/moment';
import type { RentalCar } from '../rental_car_info';
import RentalCarInfo from '../rental_car_info';

interface RentalCarOverrideMaps {
  carNumber: number;

  remarks: string;

  firstNameKana: string;

  lastNameKana: string;

  driverTel: string;
}

type ErrorMap = { [key in keyof Partial<RentalCar & RentalCarOverrideMaps>]: string };

class ArrangementRequestRentalCarInfo extends RentalCarInfo<RentalCarOverrideMaps> {
  errors: ErrorMap[] = [];

  /* eslint class-methods-use-this: 0 */
  setDefaultRentalCar(): RentalCarOverrideMaps & Partial<RentalCar> {
    return {
      departureDate: moment()
        .tz('Asia/Tokyo')
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
        .add(1, 'days')
        .format(),
      returnDate: moment()
        .tz('Asia/Tokyo')
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
        .add(2, 'days')
        .format(),
      carNumber: 1,
      remarks: '',
      firstNameKana: '',
      lastNameKana: '',
      driverTel: ''
    };
  }

  setCarNumber(value: never, i: number) {
    this.rentalCars[i].carNumber = value;
    app.render();
  }

  setRemarks(value: never, i: number) {
    this.rentalCars[i].remarks = value;
    app.render();
  }

  setFirstNameKana(value: never, i: number) {
    this.rentalCars[i].firstNameKana = value;
    app.render();
  }

  setLastNameKana(value: never, i: number) {
    this.rentalCars[i].lastNameKana = value;
    app.render();
  }

  setDriverTel(value: never, i: number) {
    this.rentalCars[i].driverTel = value;
    app.render();
  }

  errorMessage(key: keyof (RentalCar & RentalCarOverrideMaps), i: number) {
    return this.errors[i]?.[key];
  }

  requiredError = (name: string, value: string | undefined, message = 'を入力してください') => {
    return !value || value.length === 0 ? `${name}${message}` : undefined;
  };

  validate() {
    this.errors = this.rentalCars.map(rentalCar => {
      const errors: ErrorMap = {};

      if (_.isEmpty(rentalCar.departurePlace)) {
        errors.departurePlace = this.requiredError('出発場所', rentalCar.departurePlace);
      }
      if (rentalCar.returnPlace !== 'current' && _.isEmpty(rentalCar.returnPlaceName)) {
        errors.returnPlace = this.requiredError('返却場所', rentalCar.returnPlaceName);
      }
      if (rentalCar.carType === 'other' && _.isEmpty(rentalCar.carTypeOther)) {
        errors.carType = this.requiredError('車種・クラス', rentalCar.carTypeOther);
      }
      if (_.isEmpty(rentalCar.lastNameKana)) {
        errors.lastNameKana = this.requiredError('運転者氏名（姓）', rentalCar.lastNameKana);
      }
      if (_.isEmpty(rentalCar.firstNameKana)) {
        errors.firstNameKana = this.requiredError('運転者氏名（名）', rentalCar.firstNameKana);
      }
      if (_.isEmpty(rentalCar.driverTel)) {
        errors.driverTel = this.requiredError('運転者電話番号', rentalCar.driverTel);
      }

      return errors;
    });

    const errors: string[] = [];
    this.errors.forEach((errs, i) => {
      const prefix = `レンタカー${i > 0 ? i + 1 : ''}：`;
      Object.values(errs).forEach(e => errors.push(`${prefix}${e}`));
    });

    app.render();
    return errors;
  }

  submitParams() {
    return {
      rentalcar_requests: this.rentalCars.map(rentalCar => ({
        departure_place: rentalCar.departurePlace,
        departure_date: rentalCar.departureDate,
        return_place: rentalCar.returnPlace === 'current' ? rentalCar.departurePlace : rentalCar.returnPlaceName,
        return_date: rentalCar.returnDate,
        car_type: rentalCar.carType,
        car_type_other: rentalCar.carTypeOther,
        remarks: rentalCar.remarks,
        first_name_kana: rentalCar.firstNameKana,
        last_name_kana: rentalCar.lastNameKana,
        car_number: rentalCar.carNumber,
        driver_tel: rentalCar.driverTel
      }))
    };
  }
}

export default ArrangementRequestRentalCarInfo;
