import React from 'react';
import { styled } from '@this/constants/themes';
import type { FreeePostDealHistoryErrorInfo } from '@this/domain/freee/freee_post_deal_history';

interface Props {
  freeeErrorInfo: FreeePostDealHistoryErrorInfo;
}

const FreeeErrorTable: React.FC<Props> = ({ freeeErrorInfo }) => {
  const { lastRequestPath, lastRequestArgs } = freeeErrorInfo;

  return (
    <table style={{ tableLayout: 'auto', width: 'auto', fontSize: '11px' }}>
      <tr>
        <Th>対象のリクエストパス</Th>
        <Td>{lastRequestPath}</Td>
      </tr>
      <tr>
        <Th>対象のリクエストパラメータ</Th>
        <Td>
          <pre style={{ margin: 0, padding: 0, border: 'none' }}>
            <code>{lastRequestArgs}</code>
          </pre>
        </Td>
      </tr>
    </table>
  );
};

const Th = styled.th`
  vertical-align: top;
  color: #999;
  padding-right: 10px;
  border-bottom: 1px solid #bbb;
  font-weight: normal;
`;

const Td = styled.td`
  color: #333;
  border-bottom: 1px solid #bbb;
`;

export default FreeeErrorTable;
