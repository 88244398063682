import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import ContentHeader from '@this/components/shared/content_header/content_header';
import ContentBody from '@this/components/shared/atoms/content_body';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import TripReportCardTemplate from './trip_report_card/trip_report_card.template';

const TripReport = require('../../../domain/trip_report');

interface Props {}

interface State {
  reports: any[];
  loading: boolean;
  error: string | null;
}

interface TripReportsResult {
  trip_reports: any[];
}

class TripReports extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reports: [],
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    utils
      .jsonPromise<TripReportsResult>('/trip_reports')
      .then(
        result => {
          this.setState({
            reports: _.map(result.trip_reports, raw => new TripReport(utils.snakeToCamelObject(raw))),
            loading: false
          });
        },
        _error => {
          this.setState({
            error: '通信環境が不安定です。\n時間をおいてもう一度お試しください。',
            loading: false
          });
        }
      )
      .catch(e => {
        utils.sendErrorObject(e);
      });
  }

  render() {
    const { reports, loading, error } = this.state;
    try {
      return (
        <Wrap>
          <div>
            <Body>
              <Header>
                <ContentHeader title="出張報告一覧(β)" backButton />
              </Header>
              {loading ? (
                <SimpleLoading />
              ) : error ? (
                <Error>{error}</Error>
              ) : (
                <ListWrap>
                  {!reports || reports.length === 0 ? (
                    '報告済みの出張はありません'
                  ) : (
                    <>
                      {reports.map(r => (
                        <div key={r.id}>
                          <TripReportCardTemplate report={r} />
                        </div>
                      ))}
                    </>
                  )}
                </ListWrap>
              )}
            </Body>
          </div>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  min-height: 100vh;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
  min-height: 100vh;
  padding: 20px;
  background: ${props => props.theme.contentBgColor};

  ${media.sp`
    padding: 0;
  `};
`;

const Header = styled.div`
  margin-bottom: 25px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const ListWrap = styled.div`
  ${media.sp`
    padding: 20px;
  `};
`;

export default TripReports;
