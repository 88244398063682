import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';

export class ExpensesMonthlyPriceOrder {
  id: number;

  from: Moment;

  to: Moment;

  billingTarget: Moment;

  everyYear: boolean;

  title: string;

  userCount: number | null;

  priceUnit: number;

  priceWithoutTax: number;

  priceWithTax: number;

  constructor(args: ExpensesMonthlyPriceOrderArgs) {
    this.id = args.id;
    this.from = moment(args.from);
    this.to = moment(args.to);
    this.billingTarget = moment(args.billing_target);
    this.everyYear = args.every_year;
    this.title = args.term_and_count_str;
    this.userCount = args.user_count || null;
    this.priceUnit = args.plan_price.saas_price;
    this.priceWithoutTax = args.price_without_tax;
    this.priceWithTax = args.price_with_tax;
  }

  formattedBillingTarget() {
    return this.billingTarget.clone().add(1, 'M').format('YYYY年M月');
  }

  formattedUserCount() {
    return this.userCount ? `${this.userCount.toLocaleString()}名` : '未定';
  }

  formattedPriceUnit() {
    return `${this.priceUnit.toLocaleString()}円`;
  }

  formattedPriceWithoutTax() {
    return this.priceWithoutTax > 0 ? `¥${this.priceWithoutTax.toLocaleString()}` : '─';
  }

  formattedPriceWithTax() {
    return this.priceWithTax > 0 ? `¥${this.priceWithTax.toLocaleString()}` : '─';
  }
}

export type ExpensesMonthlyPriceOrderArgs = {
  id: number;

  from: string;

  to: string;

  billing_target: string;

  every_year: boolean;

  term_and_count_str: string;

  user_count: number | null;

  price_without_tax: number;

  price_with_tax: number;

  plan_price: {
    id: number;

    saas_price: number;

    tax_type: {
      id: number;

      name: string;

      tax_ratio: number;
    };
  };
};
