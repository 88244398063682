import React from 'react';
import { observer } from 'mobx-react';
import type { Moment } from 'moment';
import { Box } from '@material-ui/core';
import DatetimePicker from '@this/shared/datetime_picker/datetime_picker';

interface Props {
  from: Moment | undefined;
  to: Moment | undefined;
  onFromChange: (from: Moment | undefined) => void;
  onToChange: (to: Moment | undefined) => void;
}

const DateRangePicker = observer(({ from, to, onFromChange, onToChange }: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <DatetimePicker
          value={from}
          onChange={d => onFromChange(d)}
          onClear={() => onFromChange(undefined)}
          showTime={false}
          disabledDays={0}
          showPast
          border
          placeholder=""
        />
      </Box>
      <Box>〜</Box>
      <Box>
        <DatetimePicker
          value={to}
          onChange={d => onToChange(d)}
          onClear={() => onToChange(undefined)}
          showTime={false}
          disabledDays={0}
          showPast
          border
          placeholder=""
        />
      </Box>
    </Box>
  );
});

export default DateRangePicker;
