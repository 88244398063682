import React, { useState } from 'react';
import { styled } from '@this/constants/themes';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import type { OrderItemJobTypeKey } from '@this/src/domain/order_item/order_item_job_type';
import { OrderItemJobType, OrderItemJobTypeKeys } from '@this/src/domain/order_item/order_item_job_type';
import type { OrderItemStatusKey } from '@this/domain/order_item/order_item_status';
import { OrderItemStatus, OrderItemStatusAvailable } from '@this/domain/order_item/order_item_status';
import { Button } from '@this/src/components/shared/ui/inputs/button';
import type { UpdateOrderItemStatusParams } from '@this/components/arrangement/todo_list/types';
import { Fetcher, HTTPError } from '@this/src/util';

interface PutResponse {
  order_item: {
    id: number;
    job_type: OrderItemJobTypeKey;
    status: OrderItemStatusKey;
    arrangerName: string;
  };
  changed_trip_status: boolean;
}

interface Props {
  orderItemId: number;
  jobType: OrderItemJobTypeKey;
  status: OrderItemStatusKey;
  onJobTypeChange: (value: OrderItemJobTypeKey) => void;
  onStatusChange: (value: OrderItemStatusKey) => void;
  showLabel: boolean;
  style?: React.CSSProperties;
  classNameForModifiedField?: (...path: (string | number)[]) => string;
  fetchData?: () => void; // statusが完了の場合に該当データを再取得するための関数
}

const OrderItemStatusForm = observer(
  ({
    orderItemId,
    jobType,
    status,
    onJobTypeChange,
    onStatusChange,
    showLabel,
    style,
    classNameForModifiedField,
    fetchData
  }: Props) => {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [alert, setAlert] = useState('');
    const [initialJobType, setInitialJobType] = useState(jobType);
    const [initialStatus, setInitialStatus] = useState(status);

    const handleSave = () => {
      setSaving(true);

      const params: UpdateOrderItemStatusParams = {
        job_type: jobType,
        status
      };
      Fetcher.put<PutResponse>(`/arrangement/order_items/${orderItemId}/update_status`, params)
        .then(res => {
          // 保存に成功した場合、initialJobType/initialStatusを変更しておく
          setInitialJobType(jobType);
          setInitialStatus(status);
          setSaving(false);
          setEditing(false);
          if (status === 7 && res.changed_trip_status) {
            // OrderItemのstatusが完了かつtripのstatusに変更があった場合に該当データを再取得
            if (fetchData) fetchData();
          }
        })
        .catch(e => {
          if (e instanceof HTTPError && e.response?.data.errors) {
            setAlert(e.response.data.errors);
          }
          setSaving(false);
        });
    };

    const handleCancelClick = () => {
      onJobTypeChange(initialJobType);
      onStatusChange(initialStatus);
      setEditing(false);
    };

    return (
      <Box display="flex" alignItems="center" style={style}>
        {showLabel && (
          <span
            style={{ marginRight: '5px' }}
            className={
              classNameForModifiedField
                ? `${classNameForModifiedField('status')} ${classNameForModifiedField('jobType')}`
                : ''
            }
          >
            ステータス:
          </span>
        )}
        {saving ? (
          <>保存中...</>
        ) : editing ? (
          <Box>
            <select
              onChange={e => onJobTypeChange(e.target.value as OrderItemJobTypeKey)}
              value={jobType}
              style={{ marginBottom: '5px' }}
            >
              {OrderItemJobTypeKeys.map(key => (
                <option key={key} value={key}>
                  {OrderItemJobType[key]}
                </option>
              ))}
            </select>
            <select
              onChange={e => onStatusChange(parseInt(e.target.value, 10) as OrderItemStatusKey)}
              value={status}
              style={{ marginBottom: '5px' }}
            >
              {OrderItemStatusAvailable.map(key => (
                <option key={key} value={key}>
                  {OrderItemStatus[key]}
                </option>
              ))}
            </select>
            <Box display="flex">
              <Button size="small" onClick={() => handleSave()}>
                保存
              </Button>
              <Button size="small" color="sub" onClick={() => handleCancelClick()} style={{ marginLeft: '5px' }}>
                キャンセル
              </Button>
            </Box>
            {alert && <AlertMessage>{alert}</AlertMessage>}
          </Box>
        ) : (
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!jobType) {
                onJobTypeChange(0);
              }
              setEditing(true);
            }}
          >
            {OrderItemJobType[jobType]}-{OrderItemStatus[status]}
          </Box>
        )}
      </Box>
    );
  }
);

const AlertMessage = styled.div`
  color: red;
`;

export default OrderItemStatusForm;
