import { observable } from 'mobx';
import type { ClosingDateArgs } from '@this/domain/payment_method/payment_method_closing_date';
import ClosingDate from '@this/domain/payment_method/payment_method_closing_date';
import type { PaymentMethodTypeArgs } from '@this/domain/payment_method/payment_method_type';
import PaymentMethodType from '@this/domain/payment_method/payment_method_type';

export type PaymentMethodArgs = {
  id?: number;
  payment_method_type_id: number | null;
  code?: string;
  name?: string;
  payment_method_closing_dates?: ClosingDateArgs[];
  disabled: boolean;
  payment_method_type?: PaymentMethodTypeArgs;
};

export const initializePaymentMethod: PaymentMethodArgs = {
  id: undefined,
  payment_method_type_id: null,
  code: '',
  name: '',
  payment_method_closing_dates: [],
  disabled: false
};

type EditableFields = 'id' | 'paymentMethodTypeId' | 'code' | 'name';

class PaymentMethod {
  @observable
  id?: number;

  @observable
  paymentMethodTypeId: number | null;

  @observable
  code: string;

  @observable
  name: string;

  @observable
  closingDates: ClosingDate[];

  @observable
  paymentMethodType: PaymentMethodType | undefined;

  @observable
  disabled: boolean;

  constructor(args: PaymentMethodArgs) {
    this.id = args.id;
    this.paymentMethodTypeId = args.payment_method_type_id || null;
    this.code = args.code || '';
    this.name = args.name || '';
    if (args.payment_method_closing_dates && args.payment_method_closing_dates.length > 0) {
      this.closingDates = args.payment_method_closing_dates.map(args => new ClosingDate(args));
    } else {
      this.closingDates = [];
    }
    if (args.payment_method_type) {
      this.paymentMethodType = new PaymentMethodType(args.payment_method_type);
    }
    this.disabled = args.disabled || false;
  }

  codeWithName() {
    return `${this.name}${this.code && ` - ${this.code}`}`;
  }

  setField<T extends EditableFields>(field: T, value: this[T]) {
    this[field] = value;
    app.render();
  }

  activeClosingDates() {
    return this.closingDates.filter(cd => !cd.destroy);
  }

  findClosingDate(i: number) {
    return this.activeClosingDates()[i];
  }

  addClosingDate() {
    this.closingDates.push(ClosingDate.initialize());
    app.render();
  }

  removeClosingDate(i: number) {
    this.findClosingDate(i)?.setDestroy();
    app.render();
  }

  submitParams() {
    return {
      id: this.id,
      payment_method_type_id: this.paymentMethodTypeId,
      code: this.code,
      name: this.name,
      payment_method_closing_dates_attributes: this.closingDates.map(cd => cd.submitParams())
    };
  }

  submitParamsForCreate() {
    return {
      id: this.id,
      payment_method_type_id: this.paymentMethodTypeId,
      code: this.code,
      name: this.name,
      payment_method_closing_dates_attributes: this.closingDates
        .filter(cd => !cd.destroy)
        .map(cd => cd.submitParams())
    };
  }
}

export default PaymentMethod;
