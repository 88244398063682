import React, { useState } from 'react';
import styled from 'styled-components';
import { AccountStatus } from '@this/domain/user/organization_member_json';
import type { MembersFilterFormData } from '@this/components/organization/members/types';
import { getColor } from '@this/shared/ui/theme';

interface Props {
  formData: MembersFilterFormData;
  onChange: (formData: MembersFilterFormData) => void;
}

const MembersFilter: React.FC<Props> = ({ formData, onChange }) => {
  const [form, setForm] = useState(formData);
  const onChangeForm = (value: string, formAttr: keyof MembersFilterFormData) => {
    const newFormData = { ...form, [formAttr]: value };
    setForm(newFormData);
    onChange(newFormData);
  };

  return (
    <OrganizationMembersMemberFilter>
      <OrganizationMembersMemberFilterLabel>社員検索</OrganizationMembersMemberFilterLabel>
      <OrganizationMembersMemberFilterField
        value={form.text}
        placeholder="検索"
        onChange={e => onChangeForm(e.target.value, 'text')}
      />
      <OrganizationMembersMemberFilterRadioDiv>
        <OrganizationMembersMemberFilterRadioInput
          id={`radio-${AccountStatus.ENABLED}`}
          type="radio"
          name="accountStatus"
          value={AccountStatus.ENABLED}
          checked={form.accountStatus === AccountStatus.ENABLED}
          onChange={e => onChangeForm(e.target.value, 'accountStatus')}
        />
        <OrganizationMembersMemberFilterInputLabel htmlFor={`radio-${AccountStatus.ENABLED}`}>
          有効
        </OrganizationMembersMemberFilterInputLabel>
        <OrganizationMembersMemberFilterRadioInput
          id={`radio-${AccountStatus.DISABLED}`}
          type="radio"
          name="accountStatus"
          value={AccountStatus.DISABLED}
          checked={form.accountStatus === AccountStatus.DISABLED}
          onChange={e => onChangeForm(e.target.value, 'accountStatus')}
        />
        <OrganizationMembersMemberFilterInputLabel htmlFor={`radio-${AccountStatus.DISABLED}`}>
          無効
        </OrganizationMembersMemberFilterInputLabel>
        <OrganizationMembersMemberFilterRadioInput
          id={`radio-${AccountStatus.INVITED}`}
          type="radio"
          name="accountStatus"
          value={AccountStatus.INVITED}
          checked={form.accountStatus === AccountStatus.INVITED}
          onChange={e => onChangeForm(e.target.value, 'accountStatus')}
        />
        <OrganizationMembersMemberFilterInputLabel htmlFor={`radio-${AccountStatus.INVITED}`}>
          招待済み
        </OrganizationMembersMemberFilterInputLabel>
        <OrganizationMembersMemberFilterRadioInput
          id={`radio-${AccountStatus.UNINVITE}`}
          type="radio"
          name="accountStatus"
          value={AccountStatus.UNINVITE}
          checked={form.accountStatus === AccountStatus.UNINVITE}
          onChange={e => onChangeForm(e.target.value, 'accountStatus')}
        />
        <OrganizationMembersMemberFilterInputLabel htmlFor={`radio-${AccountStatus.UNINVITE}`}>
          招待前
        </OrganizationMembersMemberFilterInputLabel>
      </OrganizationMembersMemberFilterRadioDiv>
    </OrganizationMembersMemberFilter>
  );
};

const OrganizationMembersMemberFilter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OrganizationMembersMemberFilterLabel = styled.label`
  font-weight: normal;
  margin-right: 10px;
`;

const OrganizationMembersMemberFilterRadioDiv = styled.div`
  flex-basis: 30%;
`;

const OrganizationMembersMemberFilterField = styled.input.attrs({ type: 'text' })`
  flex-grow: 1;
  width: auto !important;
  margin-right: 20px;
  margin-bottom: 0 !important;
`;

const OrganizationMembersMemberFilterRadioInput = styled.input`
  && {
    display: none;
    + label {
      padding-left: 20px;
      position: relative;
      float: left;
      margin-right: 10px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        border-radius: 50%;
      }
    }
    &:checked {
      + label {
        color: ${getColor('brand', 'primary')};
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 2px;
          width: 11px;
          height: 11px;
          background: ${getColor('brand', 'primary')};
          border-radius: 50%;
        }
      }
    }
  }
`;

const OrganizationMembersMemberFilterInputLabel = styled.label`
  font-weight: normal;
`;

export default MembersFilter;
