class BulkInvitationEmail
  constructor: (raw, action_type) ->
    @email = dig(raw, 'email')
    @status = dig(raw, 'status')
    @changes = raw.changes
    @actionType = action_type
    @errorMessage = raw.error_message
    @createdAt = moment(dig(raw, 'created_at')).format('YYYY-MM-DD HH:mm:ss')
    @updatedAt = moment(dig(raw, 'updated_at')).format('YYYY-MM-DD HH:mm:ss')

  statusMessage: ->
    switch @status
      when 'enqueued'
        '未着手'
      when 'running'
        '着手中'
      when 'success'
        return '削除成功' if @actionType == 'delete_users'
        "#{if @changes then '更新' else '作成'}成功"
      when 'skip'
        "スキップ: #{@errorMessage}"
      when 'fail'
        "失敗: #{@errorMessage}"

  isSuccess: ->
    @status == 'success'

  isFail: ->
    @status == 'fail'

  # TODO: 関数名から想起できるようにbool値を返すようにする
  hasChange: (name) ->
    return null unless @changes

    change = @changes[name]
    if change.before != change.after then change else null

module.exports = BulkInvitationEmail
