import React from 'react';
import { styled } from '@this/constants/themes';
import Link from '@this/shared/atoms/link';

const MarketLogSample: React.FC = () => (
  <Sample>
    <SampleMessage>
      マーケットログはオプション機能です。
      <br />
      ご利用の際は
      <InquiryLink to="/inquiries/new">こちらからお問い合わせ</InquiryLink>
      ください。
    </SampleMessage>
  </Sample>
);

const Sample = styled.div`
  margin-top: 20px;
  background: url('/images/market_log_sample.png') no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: darken;
  width: 1087px;
  height: 600px;
`;

const SampleMessage = styled.div`
  padding: 200px 0;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
`;

const InquiryLink = styled(Link)`
  & {
    text-decoration: underline;
    color: white;
  }
  &:focus,
  &:hover {
    color: #ccc;
  }
`;

export default MarketLogSample;
