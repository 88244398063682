interface Props {
  sessionId?: number;
  message: string;
  onStarted?: () => void;
  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;
  onFinally?: () => void;
}

interface SearchKnowledgeResponse {
  message: string;
  error: boolean;
}

const searchKnowledgeApi = ({ sessionId, message, onStarted, onSuccess, onError, onFinally }: Props) => {
  if (message.length > 0) {
    onStarted?.();
    utils
      .jsonPromise<SearchKnowledgeResponse>('/llm/knowledges', { session_id: sessionId, text: message })
      .then(res => {
        if (res.error) {
          onError?.(res.message);
          return;
        }

        onSuccess?.(res.message);
      })
      .finally(() => {
        onFinally?.();
      });
  }
};

export default searchKnowledgeApi;
