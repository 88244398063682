import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';

export const useStyles = makeStyles<AITTheme>(
  ({ shadows, tokens: { colors, radiuses, typographies }, utils }) => ({
    root: () => ({
      backgroundColor: colors.common.white,
      border: `1px solid ${colors.border.divider}`,
      boxShadow: shadows[1],
      borderRadius: radiuses.r12,
      fontSize: typographies.fontSizes.body,
      '.rc-picker-dropdown-hidden &': {
        display: 'none'
      },
      '& .rc-picker-panel-layout': {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch'
      },
      '& .rc-picker-datetime-panel': {
        display: 'flex'
      },
      '& .rc-picker-header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.border.divider}`,
        height: 34,
        paddingLeft: utils.getSpacing(2),
        paddingRight: utils.getSpacing(2),
        '& button': {
          border: 'none',
          backgroundColor: 'transparent',
          color: colors.text.description,
          cursor: 'pointer',
          transition: `color 0.3s`,

          /** AI TravelのグローバルCSSの上書き。本来必要ない指定 */
          padding: 0,
          fontFamily: typographies.fontFamily,
          /***/

          '&:hover': {
            color: colors.brand.primary,

            /** AI TravelのグローバルCSSの上書き。本来必要ない指定 */
            backgroundColor: 'transparent'
            /***/
          }
        },
        '& .rc-picker-year-btn, & .rc-picker-month-btn': {
          color: colors.text.primary,
          fontWeight: 'bold'
        }
      },
      '& .rc-picker-footer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `1px solid ${colors.border.divider}`,
        height: 34,
        paddingLeft: utils.getSpacing(2),
        paddingRight: utils.getSpacing(2),
        '& .rc-picker-ranges': {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0
        }
      },
      '& .rc-picker-today-btn, & .rc-picker-now-btn': {
        color: colors.brand.primary,
        fontSize: typographies.fontSizes.body,
        cursor: 'pointer',
        transition: 'opacity 0.3s',
        '&:hover': {
          opacity: 0.8
        }
      },
      '& .rc-picker-ok button': {
        padding: '4px 10px',
        color: colors.common.white,
        backgroundColor: colors.brand.primary,
        fontWeight: 'bold',
        transition: 'background-color 0.3s',
        borderRadius: radiuses.r4,
        lineHeight: typographies.lineHeight.body,
        fontSize: typographies.fontSizes.body,
        boxShadow: 'none',
        cursor: 'pointer',
        outline: 'none',
        appearance: 'none',
        border: 'none',
        '&:hover': {
          backgroundColor: colors.brand.hover
        }
      },
      '& .rc-picker-year-panel, & .rc-picker-month-panel, & .rc-picker-decade-panel': {
        '& table.rc-picker-content': {
          '& th, & td': {
            width: 60
          }
        }
      },
      '& table.rc-picker-content': {
        width: '100%',
        paddingLeft: utils.getSpacing(2),
        paddingRight: utils.getSpacing(2),
        fontSize: typographies.fontSizes.body,
        /** AI TravelのグローバルCSSの上書き。本来必要ない指定 */
        borderCollapse: 'initial',
        margin: 0,
        tableLayout: 'auto',
        /***/

        '& tr': {
          display: 'flex',
          alignItems: 'center'
        },
        '& th, & td': {
          width: 26,
          /** AI TravelのグローバルCSSの上書き。本来必要ない指定 */
          padding: 0,
          borderBottom: 'none'
          /***/
        },
        '& th': {
          /** AI TravelのグローバルCSSの上書き。本来必要ない指定 */
          textAlign: 'center',
          /***/
          '&:nth-last-child(7)': {
            color: 'red'
          },
          '&:nth-child(7)': {
            color: 'blue'
          }
        },
        '& td': {
          color: colors.text.description,
          paddingTop: utils.getSpacing(1),
          paddingBottom: utils.getSpacing(1),
          cursor: 'pointer',
          '&.rc-picker-cell-disabled': {
            color: colors.text.disabled,
            cursor: 'normal'
          },
          '&.rc-picker-cell-in-view:not(.rc-picker-cell-disabled)': {
            color: colors.text.primary,
            transition: 'background-color 0.3s',
            '&:nth-last-child(7)': {
              color: 'red'
            },
            '&:nth-child(7)': {
              color: 'blue'
            },
            '&:hover': {
              backgroundColor: colors.background.hover
            },
            '&.rc-picker-cell-selected': {
              backgroundColor: colors.brand.primary,
              color: colors.common.white,
              borderRadius: radiuses.r4
            },
            '&.rc-picker-cell-today': {
              border: `1px solid ${colors.brand.primary}`,
              borderRadius: radiuses.r4
            }
          }
        },
        '& .rc-picker-cell-inner': {
          textAlign: 'center'
        }
      },
      '& .rc-picker-time-panel': {
        borderLeft: `1px solid ${colors.border.divider}`,
        '& .rc-picker-header': {
          justifyContent: 'center'
        },
        '& .rc-picker-content': {
          display: 'flex',
          maxHeight: 200,
          gap: 4,
          paddingLeft: 4,
          paddingRight: 4
        }
      },
      '& .rc-picker-time-panel-column': {
        listStyle: 'none',
        margin: 0,
        padding: '0 0 90px 0',
        width: 46,
        textAlign: 'left',
        overflowY: 'auto',
        '& > li': {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: colors.background.hover
          },
          '&.rc-picker-time-panel-cell-selected': {
            backgroundColor: colors.brand.primary,
            color: colors.common.white,
            borderRadius: radiuses.r4
          },
          '& .rc-picker-time-panel-cell-inner': {
            paddingLeft: utils.getSpacing(3)
          }
        }
      }
    })
  })
);
