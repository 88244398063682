import React from 'react';
import { styled } from '@this/constants/themes';
import type Organization from '@this/domain/organization/organization2';

interface Props {
  organization: Organization | null;
}

interface State {}

class SsoEnable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { organization } = this.props;
    return (
      <FlexLabel>
        {organization && (
          <>
            <input
              type="checkbox"
              checked={organization.ssoEnabled}
              onChange={() => organization.toggleSsoEnabled()}
            />
            <div>シングルサインオンを有効化する</div>
          </>
        )}
      </FlexLabel>
    );
  }
}

const FlexLabel = styled.label`
  display: flex;
`;

export default SsoEnable;
