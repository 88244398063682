/* eslint-disable max-lines */
import _ from 'lodash';
import Hotel from '@this/domain/hotel/hotel';
import Transit from '@this/domain/transit/transit';
import type User from '@this/domain/user/user';
import type { SearchType } from './search_query';
import type SearchQuery from './search_query';
import type SearchQueryItem from './search_query_item';
import SearchResultItem from './search_result_item';
import MarginTypeList from './organization/margin_type_list';
import ReservingTrip from './trip/reserving_trip';
import type { MarginTypeJson, TransitJson, HotelJson, RakutenPackageAirCode } from './select_repository';
import TransitList from './transit/transit_list';
import HotelList from './hotel/hotel_list';
import FlightList from './flight/flight_list';
import type { SelectType, PackageProvider, Airline } from './select_store';

/* eslint-disable max-lines */
class SearchResult {
  public type: SelectType;

  public showFee: boolean;

  public marginTypes: MarginTypeList;

  private includeTax: boolean;

  public query: SearchQuery;

  private searchType: SearchType;

  public items: SearchResultItem[];

  public packageItems: { ANA: SearchResultItem[]; JAL: SearchResultItem[] };

  public jrPackageItems: SearchResultItem[];

  public packageProvider: PackageProvider | null;

  public airline: Airline;

  public searchQueryId: number | null;

  public transportAvailable: boolean;

  public hotelAvailable: boolean;

  public travelerRepresentative: User | null;

  public handleChange: (index: number, prevId: string | undefined) => void;

  constructor(args: {
    query: SearchQuery;
    showFee?: string;
    marginTypes?: MarginTypeJson[];
    includeTax?: boolean;
    transportAvailable?: boolean;
    hotelAvailable?: boolean;
  }) {
    this.type = args.query.type || 'separate';
    this.showFee = typeof args.showFee === 'undefined' ? true : !!args.showFee;
    this.marginTypes = new MarginTypeList(args.marginTypes);
    this.includeTax = args.includeTax || false;
    this.transportAvailable = args.transportAvailable || false;
    this.hotelAvailable = args.hotelAvailable || false;
    this.query = args.query;
    this.searchType = this.query.searchType();
    this.packageItems = { ANA: [], JAL: [] };
    this.jrPackageItems = [];
    this.packageProvider = null;
    this.airline = args.query.airline || 'ANA';
    this.searchQueryId = null;
    this.travelerRepresentative = null;

    this.handleChange = (index: number, prevId: string | undefined) => {
      this.changeElementItem(index, prevId);
    };

    // SearchResultItemにhandleChangeを渡しているため、handleChangeの設定より後で生成する
    this.items = this.query.items.map(
      (queryItem, i) =>
        new SearchResultItem({
          searchResult: this,
          queryItem,
          index: i,
          type: 'separate',
          showFee: this.showFee,
          marginTypes: this.marginTypes.rawMargins,
          includeTax: this.includeTax
        })
    );
  }

  get currentItems(): SearchResultItem[] {
    if (this.type === 'jrPackage') {
      return this.jrPackageItems;
    }
    if (this.type === 'airPackage') {
      return this.packageItems[this.airline];
    }
    return this.items;
  }

  setSearchQueryId(id: number) {
    this.searchQueryId = id;
  }

  setShowFee(value: boolean) {
    this.showFee = value;
    _.each(this.items, item => item.setShowFee(value));
  }

  setMarginTypes(value: MarginTypeJson[]) {
    this.marginTypes = new MarginTypeList(value);
    _.each(this.items, item => item.setMarginTypes(value));
  }

  setIncludeTax(value: boolean) {
    this.includeTax = value;
    _.each(this.items, item => item.setIncludeTax(value));
  }

  toggleAllFlight() {
    this.items
      .filter(item => item.elementType() === 'flight')
      .forEach(flight => {
        flight.isNeed = !flight.isNeed;
      });
    app.render();
  }

  setType(type: SelectType) {
    this.type = type;
    app.render();
  }

  setAirline(airline: Airline) {
    this.airline = airline;
    app.render();
  }

  setPackageResult(results: (TransitJson[] | HotelJson[])[], packageProvider: PackageProvider, airline: Airline) {
    const packageQueryItems = this.getPackageQueryItems();

    this.packageProvider = packageProvider;
    this.packageItems[airline] = results
      .map((result, i) => {
        let current: string | undefined;
        // クエリで選択便が指定されていたらcurrentにセット
        if (
          packageQueryItems[i] &&
          packageQueryItems[i].type === 'airPackage' &&
          results[0].length &&
          results[2].length
        ) {
          current = packageQueryItems[i].selectedElement;
        }
        return {
          result,
          current
        };
      })
      .map(({ result, current }, i) => {
        if (result.length === 0) {
          return this.packageItems[airline][i];
        }
        let list: TransitList | HotelList;
        if (SearchResult.isTransportResults(result)) {
          list = new TransitList(result, { package: true, current });
        } else {
          list = new HotelList(result, { package: true, includeTax: true, current });
        }
        return new SearchResultItem({
          searchResult: this,
          type: 'airPackage',
          index: i,
          queryItem: packageQueryItems[i],
          showFee: this.showFee,
          marginTypes: this.marginTypes.rawMargins,
          includeTax: this.includeTax,
          elementList: list
        });
      });
  }

  setJrPackageResult(results: (TransitJson[] | HotelJson[])[]) {
    const packageQueryItems = this.getPackageQueryItems();
    this.jrPackageItems = results
      .map((result, i) => ({
        result,
        current:
          packageQueryItems[i] && packageQueryItems[i].type === 'jrPackage'
            ? packageQueryItems[i].selectedElement
            : undefined
      }))
      .map(({ result, current }, i) =>
        SearchResult.isTransportResults(result)
          ? new TransitList(result, { package: true, current })
          : new HotelList(result, { package: true, current })
      )
      .map(
        (elementList, i) =>
          new SearchResultItem({
            searchResult: this,
            type: 'airPackage',
            index: i,
            queryItem: packageQueryItems[i],
            showFee: this.showFee,
            marginTypes: this.marginTypes.rawMargins,
            includeTax: this.includeTax,
            elementList
          })
      );
  }

  setTransportAvailable(bool: boolean) {
    this.transportAvailable = bool;
  }

  setHotelAvailable(bool: boolean) {
    this.hotelAvailable = bool;
  }

  setTravelerRepresentative(user: User) {
    this.travelerRepresentative = user;
  }

  private static isTransportResults(results: TransitJson[] | HotelJson[]): results is TransitJson[] {
    return results.length === 0 || (results[0] as TransitJson).additional_price !== undefined;
  }

  // TODO: interface定義
  getQueryParams(): { [key: string]: string | boolean } {
    const params: { [key: string]: string | boolean } = {
      type: this.type,
      airline: this.airline
    };
    return params;
  }

  getQueryString() {
    let res = '';
    _.entries(this.getQueryParams()).forEach(([k, v]) => {
      res += `${k}=${v}&`;
    });
    const items =
      this.type === 'airPackage'
        ? this.packageItems[this.airline]
        : this.type === 'jrPackage'
        ? this.jrPackageItems
        : this.items.filter(i => i.queryItem.input);
    items.forEach((item, i) => {
      res += item.getQueryString(`items[${i}]`);
    });
    return res;
  }

  getPackageQueryItems() {
    const packages = this.query.identifyPackageQueryItems();
    if (packages.length < 1) return [];
    const { origin, hotel, destination } = packages[0];
    return [origin, hotel, destination];
  }

  // TODO: interface定義
  confirmParams(): { [key: string]: string | number | boolean | { [key: string]: any } | null | undefined } {
    const items = this.requiredItems();

    const settingCurrentIdItems = items.filter(item => {
      if (!item.elementList) {
        return false;
      }
      if (_.isNil(item.elementList.currentId)) return false;
      return true;
    });
    return {
      search_query_id: this.searchQueryId,
      search_type: this.query.searchType(),
      type: this.type,
      package_provider: this.packageProvider,
      airline: this.airline,
      items: _.map(SearchResult.uniqueItems(settingCurrentIdItems), (item, i) => item.confirmParams(i))
    };
  }

  updateTransitSegmentsParams() {
    const items = this.requiredItems();
    const settingCurrentIdItems = items.filter(item => {
      if (!item.elementList) {
        return false;
      }
      return typeof item.elementList.currentId !== 'undefined';
    });
    return {
      items: _.map(SearchResult.uniqueItems(settingCurrentIdItems), item => item.updateTransitSegmentsParams())
    };
  }

  getReservingTrip() {
    return new ReservingTrip({
      items: this.getReservingTripItems(),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  getPackageTrip() {
    return new ReservingTrip({
      items: this.getReservingTripPackageItems(),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  /* 選択しているパッケージが存在しない時は別のタイプ（ANA or JAL or JR）の存在しているパッケージを返す。 */
  getExistPackageTrip() {
    let trip: ReservingTrip = new ReservingTrip({
      items: this.getReservingTripPackageItems(),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
    if (this.type === 'jrPackage') {
      if (trip.packageBasePrice() === 0) {
        trip = new ReservingTrip({
          items: this.getReservingTripPackageItems('airPackage'),
          packageType: this.type,
          peopleNum: this.query.peoplenum,
          showFee: this.showFee,
          marginTypes: this.marginTypes
        });
        if (trip.packageBasePrice() === 0) {
          trip = new ReservingTrip({
            items: this.getReservingTripPackageItems('airPackage', this.anotherAirline()),
            packageType: this.type,
            peopleNum: this.query.peoplenum,
            showFee: this.showFee,
            marginTypes: this.marginTypes
          });
        }
      }
    } else {
      if (trip.packageBasePrice() === 0) {
        trip = new ReservingTrip({
          items: this.getReservingTripPackageItems('airPackage', this.anotherAirline()),
          packageType: this.type,
          peopleNum: this.query.peoplenum,
          showFee: this.showFee,
          marginTypes: this.marginTypes
        });
      }
      if (trip.packageBasePrice() === 0) {
        trip = new ReservingTrip({
          items: this.getReservingTripPackageItems('jrPackage'),
          packageType: this.type,
          peopleNum: this.query.peoplenum,
          showFee: this.showFee,
          marginTypes: this.marginTypes
        });
      }
    }
    return trip;
  }

  getAirPackageTrip(airline: Airline) {
    return new ReservingTrip({
      items: this.getReservingTripPackageItems('airPackage', airline),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  getAirPackageTripCheapest(airline: Airline) {
    return new ReservingTrip({
      items: this.getReservingTripPackageItemsCheapest('airPackage', airline),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  getJrPackageTrip() {
    return new ReservingTrip({
      items: this.getReservingTripPackageItems('jrPackage'),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  getJrPackageTripCheapest() {
    return new ReservingTrip({
      items: this.getReservingTripPackageItemsCheapest('jrPackage'),
      packageType: this.type,
      peopleNum: this.query.peoplenum,
      showFee: this.showFee,
      marginTypes: this.marginTypes
    });
  }

  // 現在のホテルを取得する。
  // 楽天パッケージのみで使う。
  getRakutenPackageCurrentHotel(): Hotel | null {
    const resultItems = this.getPackageSearchResultItems();
    if (!resultItems) {
      return null;
    }
    const hotelResultItem: SearchResultItem | undefined = (() => {
      return resultItems.find(i => i.elementType() === 'hotel');
    })();
    if (!hotelResultItem || !hotelResultItem.elementList) {
      return null;
    }
    const currentElement = hotelResultItem.elementList.current();
    return currentElement instanceof Hotel ? currentElement : null;
  }

  // 楽天パッケージにおける、行き・帰りの出発・到着のコードを返す。
  // 複数拠点は考慮していない。
  getRakutenPackageAirCode(): RakutenPackageAirCode | null {
    const resultItems = this.getPackageSearchResultItems();
    if (!resultItems) {
      return null;
    }
    const flightItems = _.filter(resultItems, item => item.elementType() === 'transport');
    const outwardTransitItem = flightItems[0];
    const homewardTransitItem = flightItems[1];
    if (
      !outwardTransitItem ||
      !outwardTransitItem.elementList ||
      !homewardTransitItem ||
      !homewardTransitItem.elementList
    ) {
      return null;
    }
    const outwardTransit = outwardTransitItem.elementList.list[0];
    const homewardTransit = homewardTransitItem.elementList.list[0];
    if (!(outwardTransit instanceof Transit) || !(homewardTransit instanceof Transit)) {
      return null;
    }
    const outwardLeg = outwardTransit.segments[0].legs[0];
    const homewardLeg = homewardTransit.segments[0].legs[0];
    if (!outwardLeg.from.code || !outwardLeg.to.code || !homewardLeg.from.code || !homewardLeg.to.code) {
      return null;
    }
    return {
      outwardDeparture: outwardLeg.from.code,
      outwardArrival: outwardLeg.to.code,
      homewardDeparture: homewardLeg.from.code,
      homewardArrival: homewardLeg.to.code
    };
  }

  private getPackageSearchResultItems(): SearchResultItem[] | null {
    const resultItems: SearchResultItem[] | null = (() => {
      switch (this.airline) {
        case 'ANA':
          return this.packageItems.ANA;
        case 'JAL':
          return this.packageItems.JAL;
        default:
          return null;
      }
    })();
    return resultItems;
  }

  private getReservingTripItems() {
    const requiredItems: SearchResultItem[] = _.filter(this.items, item => item.isNeed && !item.loadingWithPair());
    const flight_items = _.filter(requiredItems, item => item.elementType() === 'flight');
    const other_items = _.remove(requiredItems, item => item.elementType() !== 'flight');
    const items = flight_items ? _.concat(other_items, flight_items) : other_items;
    const unique_items = SearchResult.uniqueItems(items);
    return _.map(unique_items, item => item.getReservingTripItem());
  }

  private getReservingTripPackageItems(type: SelectType = this.type, airline: Airline = this.airline) {
    let packageItems: SearchResultItem[] = [];
    if (type === 'jrPackage') {
      packageItems = _.clone(this.jrPackageItems);
    } else {
      packageItems = _.clone(this.packageItems[airline]);
    }
    const flight_items = _.filter(packageItems, item => item.elementType() === 'flight');
    const other_items = _.remove(packageItems, item => item.elementType() !== 'flight');
    const items = flight_items ? _.concat(other_items, flight_items) : other_items;
    const unique_items = SearchResult.uniqueItems(items);
    return _.map(unique_items, item => item.getReservingTripItem());
  }

  private getReservingTripPackageItemsCheapest(type: SelectType = this.type, airline: Airline = this.airline) {
    let packageItems: SearchResultItem[] = [];
    if (type === 'jrPackage') {
      packageItems = _.clone(this.jrPackageItems);
    } else {
      packageItems = _.clone(this.packageItems[airline]);
    }
    const flight_items = _.filter(packageItems, item => item.elementType() === 'flight');
    const other_items = _.remove(packageItems, item => item.elementType() !== 'flight');
    const items = flight_items ? _.concat(other_items, flight_items) : other_items;
    const unique_items = SearchResult.uniqueItems(items);
    return _.map(unique_items, item => item.getReservingTripItemCheapest());
  }

  private anotherAirline(): Airline {
    if (this.airline === 'ANA') {
      return 'JAL';
    }
    return 'ANA';
  }

  forignFlights() {
    const flight_items = _.filter(this.requiredItems(), item => item.elementType() === 'flight');
    return SearchResult.uniqueItems(flight_items);
  }

  static uniqueItems(items: SearchResultItem[]) {
    return items.filter((item, i, self) => {
      if (item.elementList) {
        return (
          self.findIndex(conpareItem => {
            return conpareItem.elementList && item.elementList
              ? item.elementList.currentId === conpareItem.elementList.currentId
              : false;
          }) === i
        );
      }
      return null;
    });
  }

  isShinkansenTooLate() {
    return this.requiredItems().some(item => item.isShinkansenTooLate());
  }

  isAirTooLate() {
    return this.requiredItems().some(item => item.isAirTooLate());
  }

  isHotelTooLate() {
    return this.requiredItems().some(item => item.isHotelTooLate()) && !this.everyCanReserveJustBefore();
  }

  everyCanReserveJustBefore() {
    return this.requiredItems().every(item => item.canReserveJustBefore());
  }

  shinkansenDeadline() {
    const tooLateItem = this.requiredItems().find(item => item.isShinkansenTooLate());
    if (tooLateItem) return tooLateItem.shinkansenDeadline();

    return undefined;
  }

  isRoundTripSearchResult(): boolean {
    return this.searchType === 'roundTrip';
  }

  isOneWaySearchResult() {
    return this.searchType === 'oneWay';
  }

  isMultipleLocationsSearchResult(): boolean {
    return this.searchType === 'multipleLocations';
  }

  isHotelSearchResult() {
    return this.searchType === 'hotel';
  }

  isPackageSupportSearchType() {
    return (this.isRoundTripSearchResult() || this.isMultipleLocationsSearchResult()) && !this.isFlightResult();
  }

  addItem(queryItem: SearchQueryItem) {
    const newItem = new SearchResultItem({
      searchResult: this,
      queryItem,
      index: this.items.length,
      type: 'separate',
      showFee: this.showFee,
      marginTypes: this.marginTypes.rawMargins,
      includeTax: this.includeTax
    });
    this.items.push(newItem);
    return newItem;
  }

  replaceItems(queryItems: SearchQueryItem[]) {
    this.items = queryItems.map(
      (queryItem, i) =>
        new SearchResultItem({
          searchResult: this,
          queryItem,
          index: i,
          type: 'separate',
          showFee: this.showFee,
          marginTypes: this.marginTypes.rawMargins,
          includeTax: this.includeTax
        })
    );
    app.render();
  }

  changeElementItem(index: number, prevId: string | undefined) {
    const item = this.items.find(i => i.index === index);
    if (!item || !item.queryItem.pair) return;

    const elementList = item.elementList;
    if (elementList instanceof TransitList) {
      const flight = this.items.find(i => !i.isDomestic && i.queryItem.pair === item.queryItem.pair);
      if (!flight || !flight.elementList) return;

      if (prevId) {
        // Transportの空港を変えた場合は、Flightを再設定する (帰りは対応しない)
        if (item.queryItem.pair === 'homeward') return;

        this.flightAtTransitChange(flight, item.elementList as TransitList, prevId);
      } else if (item.queryItem.pair === 'outward') {
        // 初回でFlightがあればTransportを再設定する
        this.outwardAtFlightChange(item, flight.elementList as FlightList);
      } else {
        // 初回でFlightがあればTransportを再設定する
        this.homewardAtFlightChange(item, flight.elementList as FlightList);
      }
    } else if (elementList instanceof FlightList) {
      // Flightの空港を変えた場合は、Transportを再設定する
      const outward = this.items.find(i => i.isDomestic && i.queryItem.pair === 'outward');
      const homeward = this.items.find(i => i.isDomestic && i.queryItem.pair === 'homeward');

      if (outward && outward.elementList) {
        this.outwardAtFlightChange(outward, elementList);
      }

      if (homeward && homeward.elementList) {
        this.homewardAtFlightChange(homeward, elementList);
      }
    }
  }

  flightAtTransitChange(item: SearchResultItem, transitList: TransitList, prevId: string | undefined) {
    const flightList = item.elementList as FlightList;
    const current = transitList.current();
    const airport = current?.airport;
    if (!airport) return;

    const prevAirport = transitList.find(prevId || '')?.airport;
    if (prevAirport === airport) return;

    const arrivalTime = this.timeToNumber(current?.endTime(), '10:00') + 200;
    const flight = flightList.list.filter(
      t =>
        t.outword.segments[0].name === airport &&
        this.timeToNumber(t.outword.segments[0].from.time, '12:00') >= arrivalTime
    )[0];

    if (flight) {
      flightList.selectWithAnimation(flight.id);
      item.setNeed(true);
    } else {
      item.setNeed(false);
    }
  }

  outwardAtFlightChange(item: SearchResultItem, flightList: FlightList) {
    const transitList = item.elementList as TransitList;
    const current = flightList.current();
    const airport = current?.outword.segments[0].from.name;
    if (!airport) return;

    // 海外航空券の出発時刻の2時間前に到着予定の交通機関を自動設定する
    // https://www.google.com/search?q=%E5%9B%BD%E9%9A%9B%E7%B7%9A+%E4%BD%95%E6%99%82%E9%96%93%E5%89%8D&oq=%E5%9B%BD%E9%9A%9B%E7%B7%9A+&aqs=chrome.3.69i57j69i59l3j0i131i433j0i512l2j69i61.7549j0j7&sourceid=chrome&ie=UTF-8
    const flightTime = this.timeToNumber(current?.outword.segments[0].from.time, '12:00') - 200;
    const transit = transitList.list
      .filter(t => t.airport === airport && this.timeToNumber(t.endTime(), '14:00') <= flightTime)
      .sort((a, b) => this.timeToNumber(b.endTime(), '14:00') - this.timeToNumber(a.endTime(), '14:00'))[0];

    if (transit) {
      transitList.selectWithAnimation(transit.id, true, transitList.domesticAirPriceIndex);
      item.setNeed(true);
    } else {
      item.setNeed(false);
    }
  }

  homewardAtFlightChange(item: SearchResultItem, flightList: FlightList) {
    const transitList = item.elementList as TransitList;
    const current = flightList.current();
    const segments = current?.homeword?.segments ?? current?.outword.segments;
    const airport = segments?.slice(-1)[0].to.name;
    if (!airport) return;

    // 海外航空券の到着時刻の2時間後以降に出発予定の交通機関を自動設定する
    const flightTime = this.timeToNumber(segments?.slice(-1)[0].to.time, '15:00') + 200;
    const transit = transitList.list
      .filter(t => t.airport === airport && this.timeToNumber(t.startTime(), '17:00') >= flightTime)
      .sort((a, b) => this.timeToNumber(a.startTime(), '17:00') - this.timeToNumber(b.startTime(), '17:00'))[0];

    if (transit) {
      transitList.selectWithAnimation(transit.id, true, transitList.domesticAirPriceIndex);
      item.setNeed(true);
    } else {
      item.setNeed(false);
    }
  }

  get flightResult(): SearchResultItem | undefined {
    return this.items.find(i => i.elementList instanceof FlightList);
  }

  get flightList(): FlightList | undefined {
    const item = this.flightResult;
    if (!item) {
      return undefined;
    }

    return item.elementList as FlightList;
  }

  isFlightResult(): boolean {
    return this.items.find(i => i.elementType() === 'flight') !== undefined;
  }

  isHotelResult(): boolean {
    return this.items.find(i => i.elementType() === 'hotel') !== undefined;
  }

  isDomesticAirResult(): boolean {
    return this.items.some(i => i.isDomesticAir());
  }

  isShinkansenResult(): boolean {
    return this.items.some(i => i.isShinkansen());
  }

  private timeToNumber(time: string | undefined, defaultTime: string) {
    return parseInt((time ?? defaultTime).replace(':', ''), 10);
  }

  private requiredItems() {
    if (this.type === 'airPackage') {
      return _.clone(this.packageItems[this.airline]);
    }

    if (this.type === 'jrPackage') {
      return _.clone(this.jrPackageItems);
    }
    return _.filter(this.items, item => item.isNeed);
  }

  errors(queries: SearchQuery): string[] | undefined {
    // if (this.isDomesticEmpty()) {
    //   return ['往路、復路、ホテルのいずれかを選択してください'];
    // }
    // if (this.isForeignEmpty()) {
    //   return ['航空券、ホテルのどちらかを選択してください'];
    // }
    // if (this.isUnableTripTime(queries)) {
    //   return ['復路の出発時間が、往路の到着時間よりも早い旅程は手配できません'];
    // }
    if (this.isShinkansenTooLate()) {
      return [`新幹線・特急チケットは、${this.shinkansenDeadline()}が依頼期限です`];
    }
    // if (this.type === 'airPackage' && this.packageHotel.current().sold_out) {
    //   return ['選択中のホテルの空室がありません'];
    // }
    if (this.isAirTooLate()) {
      return ['国内航空券の手配は1営業日前の15時が依頼期限です'];
    }
    // if (this.isHotelTooLate()) {
    //   return ['選択中のホテルの手配は1営業日前の15時が依頼期限です'];
    // }
    //
    return undefined;
  }
}

export default SearchResult;
/*  eslint-enable max-lines */
