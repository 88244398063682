import React, { useEffect, useMemo } from 'react';
import { styled } from '@this/constants/themes';
import { media } from '@this/components/shared/atoms/media';
import { lighten } from '@material-ui/core';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type { TripReport } from '@this/src/domain/trip_report/trip_report';
import type { Approval } from '@this/src/domain/trip_report/approval';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';

type Props = {
  tripReport: TripReport;
  approval: Approval;
};

const StatusWorkflow: React.FC<Props> = ({ tripReport, approval }) => {
  const store = useTripReportStore();
  const approvals = approval.inGroupApprovals;

  const statuses = useMemo(() => {
    const statuses = [{ name: '出張報告申請', current: false, approved: true, prev: true }];

    approvals?.forEach(a => {
      statuses.push({
        name: `${a.approveStage}次承認`,
        current: a.approveStage === approval.approveStage,
        approved: a.status === 'approved',
        prev: a.approveStage < approval.approveStage
      });
    });

    statuses.push({
      name: '出張報告完了',
      current: false,
      approved: tripReport.status === 'approved',
      prev: false
    });

    return statuses;
  }, [approvals]);

  useEffect(() => {
    if (!approvals || approvals.length < 1) {
      store.getApprovalInGroups(tripReport, approval);
    }
  }, [approval.id]);

  return approvals === null ? (
    <LoadingWrap>
      <SimpleLoading />
    </LoadingWrap>
  ) : approvals.length < 1 ? (
    <Error>通信に失敗しました</Error>
  ) : (
    <Wrap>
      <StatusItems>
        {statuses.map((status, i) => (
          <StatusItem
            key={i}
            first={i === 0}
            approved={status.approved}
            current={status.current}
            prev={status.prev}
          >
            <StatusIcon>
              <svg
                viewBox="0 0 22 22"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <Circle
                    approved={status.approved}
                    current={status.current}
                    prev={status.prev}
                    strokeWidth="6"
                    cx="11"
                    cy="11"
                    r="6"
                  />
                </g>
              </svg>
            </StatusIcon>
            <StatusText approved={status.approved} current={status.current} prev={status.prev}>
              <span>{status.name}</span>
            </StatusText>
          </StatusItem>
        ))}
      </StatusItems>
    </Wrap>
  );
};

const LoadingWrap = styled.div`
  padding-top: 20px;
`;

const Error = styled.div`
  padding: 20px;
  color: ${props => props.theme.redColor};
`;

const Wrap = styled.div`
  font-size: 11px;

  ${media.sp`
    font-size: 10px;
  `}
`;

const StatusItems = styled.div`
  margin-top: -12px;
  padding-left: 3px;
`;

const StatusItem = styled.div<{ first: boolean; approved: boolean; current: boolean; prev: boolean }>`
  position: relative;
  z-index: 0;
  height: 20px;
  margin-bottom: 6px;
  border-left: solid 3px ${props => props.theme.grayTextColor};

  ${props =>
    (props.approved || props.current) &&
    `
    border-color: ${props.theme.linkColor};
  `}
  ${props =>
    (props.prev || props.current) &&
    `
    border-left: solid 3px ${lighten(props.theme.linkColor, 0.6)};
  `}
  ${props =>
    props.first &&
    `
    border-color: transparent;
  `}
  ${media.sp`
    margin-bottom: 6px;
  `}
`;

const StatusIcon = styled.div`
  position: absolute;
  bottom: -6px;
  left: -7px;
  z-index: 10;
  width: 11px;
  height: 11px;
`;

const Circle = styled.circle<{ approved: boolean; current: boolean; prev: boolean }>`
  stroke: ${props => props.theme.grayTextColor};

  ${props =>
    (props.approved || props.current) &&
    `
    stroke: ${props.theme.linkColor};
  `}
  ${props =>
    props.prev &&
    `
    stroke: ${lighten(props.theme.linkColor, 0.6)};
  `}
`;

const StatusText = styled.div<{ approved: boolean; current: boolean; prev: boolean }>`
  position: absolute;
  bottom: -10px;
  left: 10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  ${props =>
    (props.approved || props.current) &&
    `
    color: ${props.theme.linkColor};
  `}

  ${props =>
    props.prev &&
    `
    color: ${lighten(props.theme.linkColor, 0.6)};
  `}

  ${media.sp`
    bottom: -8px;
  `}
`;

export default StatusWorkflow;
