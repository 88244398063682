import _ from 'lodash';
import type MarginType from '@this/domain/organization/margin_type2';
import type { FlightType } from '@this/domain/search_query';
import type ListWrapperInterface from '../list_wrapper_interface';
import Flight from './flight';
import type { FlightJson } from '../select_repository';
import FlightSliceCandidate from './flight_slice_candidate';

interface Args {
  showFee?: boolean;
  marginType?: MarginType;
  current?: string;
  cabins?: string[];
  handleChange?: (prevId: string | undefined) => void;
}

export interface Carrier {
  id: string;
  name: string;
}

export interface Airport {
  label: string;
  value: string;
}

export interface AirportSet {
  origin: Airport[];
  destination: Airport[];
}

export const FlightListSortKeys = ['orderedIndex', 'departure', 'price', 'time'] as const;
export type FlightListSortKeyType = typeof FlightListSortKeys[number];

const StoreKeyName = 'flightSortKey';

class FlightList implements ListWrapperInterface {
  showFee: boolean;

  marginType: MarginType | undefined;

  list: Flight[];

  carrierList: Carrier[];

  currentId: number;

  sortKey: string;

  cabins: string[];

  flightType: { [key: number]: FlightType };

  originCode: { [key: number]: string };

  destCode: { [key: number]: string };

  minirule: string;

  airlines: { [key: number]: string[] };

  showBox: boolean;

  newId: number | null;

  segment: undefined; // only for typing

  handleChange: ((prevId: string | undefined) => void) | null = null;

  constructor(rawFlights?: FlightJson[], args: Args = {}) {
    this.showFee = typeof args.showFee === 'undefined' ? true : args.showFee;
    this.marginType = args.marginType;
    this.list =
      _.map(
        rawFlights,
        (f, i) => new Flight(_.merge(f, { showFee: this.showFee, marginType: this.marginType }), i)
      ) || [];
    this.carrierList = this.createCarrierList();

    const c = this.list.find(f => f.uniqString() === args.current);
    if (c) {
      this.currentId = c.id;
    } else {
      this.currentId = utils.dig(_.first(this.list), 'id');
    }

    this.sortKey = localStorage.getItem(StoreKeyName) || 'orderedIndex';

    // for filter
    this.cabins = args.cabins && args.cabins.length > 0 ? args.cabins : [];
    this.flightType = {};
    this.originCode = {};
    this.destCode = {};
    this.minirule = 'all';
    this.airlines = {};

    // for CSSTransition
    this.showBox = true;
    this.newId = null;

    if (args.handleChange) {
      this.handleChange = (prevId: string | undefined) => {
        if (args.handleChange) args.handleChange(prevId);
      };
    }
  }

  firstHandleChange() {
    if (this.handleChange) this.handleChange(undefined);
  }

  current() {
    if (this.currentId) return _.find(this.list, f => f.id === this.currentId);
    return undefined;
  }

  currentSliceId(subtype: number): string | undefined {
    const c = this.current();
    if (c) {
      const slice = c.itineraries[subtype];
      return slice.id;
    }
    return undefined;
  }

  currentFlightCandidate(subtype: number) {
    const candidates = this.flightCandidates(subtype, false);
    const c = this.current();
    if (c) {
      return _.find(candidates, can => c.itineraries[subtype].id === can.key);
    }
    return undefined;
  }

  // STEP1: 指定した航路に対して、全Flightのうち表示対象のFlightだけに絞り込む
  filteredList(subtype: number, forList: boolean): Flight[] {
    const c = this.current();
    return _.filter(this.list, f => {
      // a: 手前の航路で選択しているものに合致しなければ除外
      if (!c) {
        return true;
      }
      for (let i = 0; i < subtype; i += 1) {
        const currentSlice = c.itineraries[i];
        const targetSlice = f.itineraries[i];
        if (currentSlice.uniqString() !== targetSlice.uniqString()) {
          return false;
        }
      }

      // 現在選択中のFlightが含まれない絞り込み条件が選ばれた時に、
      // 左カラムに何も表示できなくなるのを防ぐ
      if (forList) {
        const segments = f.itineraries[subtype].segments;
        for (let i = 0; i < segments.length; i += 1) {
          const s = segments[i];
          // シートクラス
          if (this.cabins.indexOf(s.cabin) < 0) {
            return false;
          }

          // 航空会社
          if (this.airlines[subtype]) {
            if (this.airlines[subtype].indexOf(s.marketing_carrier) < 0) {
              return false;
            }
          }
        }

        // 変更・キャンセル条件
        switch (this.minirule) {
          case 'changeable':
            if (!f.checkChangeable()) {
              return false;
            }
            break;
          case 'cancelable':
            if (!f.checkCancelable()) {
              return false;
            }
            break;
          case 'both':
            if (!f.checkChangeable() || !f.checkCancelable()) {
              return false;
            }
            break;
          default:
        }

        // 乗り継ぎのみ/直行便のみ
        const flightType = this.flightType[subtype];
        if (flightType) {
          const segments = f.itineraries[subtype].segments;
          if ((flightType === 'N' && segments.length > 1) || (flightType === 'C' && segments.length === 1)) {
            return false;
          }
        }

        // 出発空港
        if (this.originCode[subtype]) {
          if (f.itineraries[subtype].segments[0].from.code !== this.originCode[subtype]) {
            return false;
          }
        }

        // 到着空港
        if (this.destCode[subtype]) {
          const segment = _.last(f.itineraries[subtype].segments);
          if (segment && segment.to.code !== this.destCode[subtype]) {
            return false;
          }
        }
      }
      return true;
    });
  }

  // STEP2: 絞り込んだFlight[]を並べ替える
  sortedList(subtype: number, forList: boolean): Flight[] {
    const flights = _.sortBy(this.filteredList(subtype, forList), f => {
      switch (this.sortKey) {
        case 'departure':
          return f.itineraries[subtype].segments[0].from.datetime;
        case 'price':
          return f.price;
        case 'time':
          return f.itineraries[subtype].durationRaw;
        default:
          return f.orderedIndex;
      }
    });
    const available = _.filter(flights, f => !!f.ticketing_enable);
    const unavailable = _.filter(flights, f => !f.ticketing_enable);
    return _.concat(available, unavailable);
  }

  // STEP3: 並べ替えたFlight[]を、指定した航路のFlightSlice.idでグルーピング（重複を出さない）
  groupedList(subtype: number, forList: boolean) {
    return _.groupBy(this.sortedList(subtype, forList), flight => flight.itineraries[subtype].id);
  }

  // STEP4: グループ化したFlight[]から、FlightSliceCandidateを作成
  flightCandidates(subtype: number, forList: boolean) {
    let basePrice = 0;
    if (subtype > 0) {
      const c = this.current();
      if (c) {
        const prevCandidate = this.currentFlightCandidate(subtype - 1);
        if (prevCandidate) {
          basePrice = prevCandidate.cheapestFlight().price;
        }
      }
    }
    return _.map(
      this.groupedList(subtype, forList),
      (list, key) => new FlightSliceCandidate(key, list, basePrice)
    );
  }

  find(id: number) {
    return _.find(this.list, f => f.id === id);
  }

  select(id: number) {
    this.list.forEach(f => {
      f.hovered = false;
    });

    const flight = this.find(id);
    if (!flight) return;

    const prevId = this.currentId;
    this.currentId = id;
    if (this.handleChange) this.handleChange(prevId.toString());
    app.render();
  }

  length(opts: any) {
    const subtype = opts.subtype;
    return this.flightCandidates(subtype, false).length;
  }

  get hasNoDirectAir() {
    return false;
  }

  selectWithAnimation(id: number) {
    this.newId = id;
    app.render(); // スマホのために一度renderしてoutlineを表示しておく

    setTimeout(() => {
      this.showBox = false;
      app.render();
    }, 100); // スマホでCSSTransitionのonExitを発火させるため
  }

  handleTransitionExited() {
    this.showBox = true;
    if (this.newId) this.select(this.newId);
    this.newId = null;
  }

  handleSortKeyChange = (key: FlightListSortKeyType) => () => {
    this.sortKey = key;
    localStorage.setItem('flightSortKey', key);
    app.render();
  };

  handleCabinsChange(cabins: string[]) {
    this.cabins = cabins;
    app.render();
  }

  handleAirlinesChange(subtype: number, airlines: string[]) {
    this.airlines[subtype] = airlines;
    app.render();
  }

  resetAirlines(subtype: number) {
    delete this.airlines[subtype];
    app.render();
  }

  handleFlightType(subtype: number, flightType: FlightType) {
    this.flightType[subtype] = flightType;
    app.render();
  }

  handleOriginCodeChange(subtype: number, code: string) {
    this.originCode[subtype] = code;
    app.render();
  }

  resetOriginCode(subtype: number) {
    delete this.originCode[subtype];
    app.render();
  }

  handleDestCodeChange(subtype: number, code: string) {
    this.destCode[subtype] = code;
    app.render();
  }

  resetDestCode(subtype: number) {
    delete this.destCode[subtype];
    app.render();
  }

  handleMiniruleChange(minirule: string) {
    this.minirule = minirule;
    app.render();
  }

  private createCarrierList(): Carrier[] {
    return this.list.reduce((array: Carrier[], flight) => {
      flight.getCarriers().forEach(carrier => {
        if (!array.find(c => c.id === carrier.id)) {
          array.push(carrier);
        }
      });
      return array;
    }, []);
  }
}

export default FlightList;
