import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@this/constants/themes';
import moment from 'moment';
import Organization from '@this/src/domain/organization/organization2';
import TripStatusIndicator from '../../shared/trip_status_indicator';

interface Props {
  trip: any;
}

const ArchiveTrip: React.FC<Props> = ({ trip }) => {
  const [sinceLastUpdate, setSinceLastUpdate] = useState<string>('');
  const [isLongTimeNoUpdate, setIsLongTimeNoUpdate] = useState<boolean>(false);
  const revenueReferenceDateType = trip?.user?.organization?.revenue_reference_date_type;

  const pastTimeInfo = useCallback(() => {
    if (trip.last_action_at == null && trip.updated_at == null) {
      return;
    }
    const targetDate = trip.last_action_at || trip.updated_at;
    const millisec = moment().diff(moment(targetDate));

    setIsLongTimeNoUpdate(false);
    let lastUpdate = '';
    if (millisec < 1000) {
      lastUpdate = 'たった今';
    } else if (millisec / 1000 < 60) {
      lastUpdate = `${Math.floor(millisec / 1000)}秒前`;
    } else if (millisec / 1000 / 60 < 60) {
      lastUpdate = `${Math.floor(millisec / 1000 / 60)}分前`;
    } else if (millisec / 1000 / 60 / 60 < 24) {
      lastUpdate = `${Math.floor(millisec / 1000 / 60 / 60)}時間前`;
    } else {
      lastUpdate = `${Math.floor(millisec / 1000 / 60 / 60 / 24)}日前`;
      setIsLongTimeNoUpdate(true);
    }
    setSinceLastUpdate(lastUpdate);
  }, [trip]);

  useEffect(() => {
    pastTimeInfo();
  }, [trip]);

  return (
    <Wrapper>
      <Item>
        {trip.exists_unopend_user_message && (
          <div>
            <New id={`newmark-trip-${trip.id}`}>新着メッセージがあります</New>
          </div>
        )}
        <TripStatusIndicator status={trip.status} />
        <span>{trip.billing ? '<請求書払い>' : '<カード払い>'}</span>
        <div>企業名：{trip?.user?.organization?.name}</div>
        <div>売上基準日：{Organization.revenueReferenceDateTypes[revenueReferenceDateType]}</div>
        <div>
          {trip.user && trip.user.name}
          {` [${trip.id}]`}
        </div>
      </Item>
      <Item>
        <div>{`${trip.startDate()} → ${trip.endDate()}`}</div>
        <div>手配依頼日：{trip.received_at && moment(trip.received_at).format('YYYY-MM-DD')}</div>
        <div>
          最終更新：
          <LastUpdateSection isLongTimeNoUpdate={isLongTimeNoUpdate}>{sinceLastUpdate}</LastUpdateSection>
        </div>
      </Item>
      <Item>合計: {utils.formatPrice(trip.totalPrice() + trip.nonOrderItemsTotalPrice())}</Item>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Item = styled.div`
  flex-basis: 30%;
  max-width: 250px;
  min-width: 200px;
`;

const LastUpdateSection = styled.span<{ isLongTimeNoUpdate: boolean | undefined }>`
  color: ${props => (props.isLongTimeNoUpdate ? props.theme.redColor : props.theme.textColor)};
`;

const New = styled.span`
  color: red;
  font-size: 50%;
  padding: 2px 5px;
  border: solid 1px #ff0000;
`;

export default ArchiveTrip;
