import { observable } from 'mobx';

import moment from '@this/src/lib/moment';
import type { Moment } from '@this/src/lib/moment';

export interface ChatbotHistoryResponseArgs {
  id: number;
  user_id: number;
  first_message_id: number;
  created_at: string;
  updated_at: string;
  user_message: string;
}

export interface ChatbotHistoryArgs {
  id: number;
  userId: number;
  firstMessageId: number;
  userMessage: string;
  createdAt: Moment;
  updatedAt: Moment;
}

export const convertChatbotHistoryArgs = (args: ChatbotHistoryResponseArgs) => {
  const convertedArgs: ChatbotHistoryArgs = {
    id: args.id,
    userId: args.user_id,
    firstMessageId: args.first_message_id,
    userMessage: args.user_message,
    createdAt: moment(args.created_at),
    updatedAt: moment(args.updated_at)
  };
  return convertedArgs;
};

export class ChatbotHistory {
  @observable id: number;

  @observable userId: number;

  @observable firstMessageId: number;

  @observable userMessage: string;

  @observable createdAt: Moment;

  @observable updatedAt: Moment;

  constructor(args: ChatbotHistoryArgs) {
    this.id = args.id;
    this.userId = args.userId;
    this.firstMessageId = args.firstMessageId;
    this.userMessage = args.userMessage;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
  }

  createdAtString = () => {
    return this.createdAt.locale('en').format('yyyy/MM/DD hh:mm A');
  };

  updatedAtString = () => {
    return this.updatedAt.locale('en').format('yyyy/MM/DD hh:mm A');
  };
}

export default ChatbotHistory;
