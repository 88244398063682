export const OrderItemStepStatus = {
  1: '先行ステップ待ち',
  2: 'TODO',
  3: '着手中',
  4: '一時停止中',
  5: '完了',
  6: '破棄'
} as const;

export const OrderItemStepEnums = {
  1: 'queue',
  2: 'todo',
  3: 'started',
  4: 'suspended',
  5: 'finished',
  6: 'aborted'
} as const;

// 1, 2, ... という数値の配列
export type OrderItemStepStatusKey = keyof typeof OrderItemStepStatus;

// '先行ステップ待ち', 'TODO', ... という文字列の配列
export type OrderItemStepStatusName = typeof OrderItemStepStatus[keyof typeof OrderItemStepStatus];

// 'queue', 'todo', ... という文字列の配列
export type OrderItemStepStatusEnum = typeof OrderItemStepEnums[keyof typeof OrderItemStepEnums];

// queue: '先行ステップ待ち', ... というオブジェクト
export const OrderItemStepStatusEnums = Object.keys(OrderItemStepStatus).reduce((acc, key) => {
  const castKey = key as unknown as OrderItemStepStatusKey;
  acc[OrderItemStepEnums[castKey]] = OrderItemStepStatus[castKey];
  return acc;
}, {} as Record<OrderItemStepStatusEnum, OrderItemStepStatusName>);

export const OrderItemStepStatusAvailable: readonly OrderItemStepStatusKey[] = [1, 2, 3, 4, 5];

export const OrderItemStepStatusOngoing: readonly OrderItemStepStatusKey[] = [2, 3, 4];
