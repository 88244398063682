import React from 'react';

import type { AdminBulkTicktesResponse } from '@this/components/admin/bulk_tickets/types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@this/shared/ui/data_displays/table';
import { Link } from '@this/shared/ui/navigations/link';
import { BulkTicketsSection } from '@this/components/admin/bulk_tickets/bulk_tickets_section';

type Props = {
  organizations: AdminBulkTicktesResponse['organizations'];
};

export const BulkTicketOrganizations = ({ organizations }: Props) => (
  <BulkTicketsSection
    title="顧客別回数券サマリ"
    renderTable={
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>顧客名</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell align="right" width="50px">
              冊数
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations.map(org => (
            <TableRow key={org.id}>
              <TableCell>
                <Link href={`/admin/organizations/${org.id}/bulk_tickets`}>{org.name}</Link>
              </TableCell>
              <TableCell>{org.domain}</TableCell>
              <TableCell align="right">{org.bulk_tickets.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    }
  />
);
