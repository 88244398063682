import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { CheckOutlinedIcon } from '@this/components/expenses/icons';

interface Props {
  onChange: (index: number) => void;
  value: number;
  labels: string[];
  name: string;
}
interface SegmentProps {
  label: string;
  name: string;
  position: 'left' | 'middle' | 'right';
  checked: boolean;
  onChange: () => void;
  number: number;
}
const defaultProps: Props = {
  name: 'segmented_control',
  labels: [],
  value: 0,
  onChange: _.noop
};

const Segment = ({ position, label, name, onChange, checked, number }: SegmentProps) => (
  <SegmentedLabel>
    <SegmentedInput type="radio" name={name} onChange={onChange} checked={checked} />
    <SegmentedLabelIn
      position={position}
      checked={checked}
      className={
        position === 'left'
          ? 'first'
          : position === 'right'
          ? number + 1 === 3
            ? 'label_3 last'
            : 'last'
          : `label_${number + 1}`
      }
    >
      <CheckOutlinedIcon color={checked ? '#fff' : grayTextColor} />
      {label}
    </SegmentedLabelIn>
  </SegmentedLabel>
);
const SegmentedControl = ({ labels, name, value, onChange }: Props = defaultProps) => {
  const handleChange = (index: number) => () => onChange(index);
  return (
    <Container name={name}>
      {labels.map((label, i) => (
        <Segment
          key={label}
          onChange={handleChange(i)}
          checked={value === i}
          label={label}
          name={name}
          position={i === 0 ? 'left' : i === labels.length - 1 ? 'right' : 'middle'}
          number={i}
        />
      ))}
    </Container>
  );
};

const baseColor = '#927230';

const grayTextColor = `#767676`;

const grayBackgroundColor = `#efedea`;

const Container = styled.div<{ name: string }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-right: ${props =>
    props.name === 'type' ? 'unset' : 'calc((((100vw - (100vw * 0.02) * 2) - 733.7433333px) / 2) * 0.057)'};
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    row-gap: 1px;
  }
`;

const SegmentedLabel = styled.label`
  cursor: pointer;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
`;

const SegmentedInput = styled.input`
  &&& {
    display: none;
  }
`;

const SegmentedLabelIn = styled.div<{ position: string; checked: boolean }>`
  font-size: 14px;
  font-weight: bold;
  line-height: revert;
  padding: 6px 20px 6px 10px;
  border-right: 1px solid #fff;
  color: ${baseColor};
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  ${props =>
    props.checked
      ? `
      background-color: ${baseColor};
      color: #fff;
    `
      : `
      background-color: ${grayBackgroundColor};
      color: ${grayTextColor};
      `}
  &.first {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &.last {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-right: none;
  }
  @media screen and (max-width: 767px) {
    &.first {
      border-bottom-left-radius: unset;
    }
    &.label_2 {
      border-top-right-radius: 6px;
      border-right: none;
    }
    &.label_3 {
      border-bottom-left-radius: 6px;
    }
    &.last {
      border-top-right-radius: unset;
    }
  }
`;

export default SegmentedControl;
