ShareholderTicket = require('./shareholder_ticket')

class ShareholderTicketList
  constructor: (rawTickets = []) ->
    @allList = _.map(rawTickets, (t) -> new ShareholderTicket(t)) || []
    @list = @allList.reverse()
    @unavailableTicketsList = _.filter(@allList, (t) -> t.isDeleted || t.isExpired)

  find: (id) ->
    _.find @list, (t) -> t.id == parseInt(id)

  last: ->
    _.last(@list)

  push: (t) ->
    @list.push(t)

  unshift: (t) ->
    @list.unshift(t)

  remove: (t) ->
    i = @list.indexOf(t)
    if i >  -1
      @list.splice(i, 1)
    if typeof t.id != 'undefined'
      @unavailableTicketsList.unshift(t)

  update: (newTicket) ->
    t = @find(newTicket.id)
    if t
      _.assign t, newTicket

  removeExpired: (t) ->
    i = @list.indexOf(t)
    if i >  -1
      @list.splice(i, 1)
    @unavailableTicketsList.unshift(t)

  totalByCompany: (companyType) ->
    tickets = _.filter(@list, (st) -> st.companyStr() == companyType)
    _.sumBy(tickets, (st) -> st.price)

  countByCompany: (companyType) ->
    tickets = _.filter(@list, (st) -> st.companyStr() == companyType)
    tickets.length

module.exports = ShareholderTicketList
