import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';
import type { DatepickerProps } from './datepicker';

type Props = Pick<DatepickerProps, 'size' | 'fullWidth' | 'variant'>;

export const useStyles = makeStyles<AITTheme, Props>(({ tokens: { colors } }) => ({
  root: ({ size, fullWidth, variant }) => ({
    display: 'inline-flex',
    width: fullWidth ? '100%' : undefined,
    '&.rc-picker-focused': {
      border: 'none'
    },
    '& .rc-picker-input': {
      position: 'relative',
      width: fullWidth ? '100%' : undefined
    },
    '& .rc-picker-input-placeholder input': {
      color: colors.text.disabled
    },
    '& .rc-picker-clear': {
      position: 'absolute',
      cursor: 'pointer',
      top: size === 'small' ? -3 : 2,
      right: size === 'small' ? 2 : 4
    },
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        cursor: variant === 'ghost' ? 'pointer' : undefined
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: variant === 'ghost' ? 0 : undefined
      }
    }
  })
}));
