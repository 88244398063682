import React from 'react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type ReserveInfo from '@this/domain/reserve_info';
import type OrderItemMappingList from '@this/domain/order_item_mapping_list';

type Props = {
  reserveInfo: ReserveInfo;
  mappingList: OrderItemMappingList;
};

const OrderItemMappingListSection: React.FC<Props> = ({ reserveInfo, mappingList }) => {
  const mode = mappingList.mappingMode();
  const getItemList = (): any[] => {
    const { items, nonOrderItems } = reserveInfo.reservingTrip;
    return items && items.length > 0 ? items : nonOrderItems;
  };

  return (
    <ChildContent>
      {mode === 'item' ? (
        <TravelerBox>
          <MappingTitle>商品ごと</MappingTitle>
          {reserveInfo.reservingTrip.isPackage() ? (
            <MappingBox>
              <MappingLabel>パッケージ</MappingLabel>
              <MappingText>{mappingList.findByTravelerAndItem(-1, 'package', 1)?.selectedOptionLabel}</MappingText>
            </MappingBox>
          ) : (
            <>
              {getItemList().map((item: any, i: number) => (
                <MappingBox key={i}>
                  {item?.element_type === 'hotel' || item?.elementType === 'hotel' ? (
                    <MappingLabel>{i + 1}. ホテル</MappingLabel>
                  ) : (
                    <MappingLabel>{i + 1}. 経路</MappingLabel>
                  )}
                  <MappingText>
                    {mappingList.findByTravelerAndItem(-1, 'item', Number(i))?.selectedOptionLabel}
                  </MappingText>
                </MappingBox>
              ))}
            </>
          )}
          {reserveInfo.rentalCarRequired === 'true' && (
            <>
              {reserveInfo.rentalCars.map((_rentalCar, car_index) => (
                <MappingBox key={car_index}>
                  <MappingLabel>{`レンタカー${car_index + 1}`}</MappingLabel>
                  <MappingText>
                    {mappingList.findByTravelerAndItem(-1, 'rental_car', Number(car_index))?.selectedOptionLabel}
                  </MappingText>
                </MappingBox>
              ))}
            </>
          )}
        </TravelerBox>
      ) : mode === 'traveler' ? (
        <TravelerBox>
          <MappingTitle>出張者ごと</MappingTitle>
          {reserveInfo.travelers.list.map((traveler, traveler_index) => (
            <MappingBox key={traveler_index}>
              <MappingLabel>{traveler.getTravelerName()}</MappingLabel>
              <MappingText>
                {mappingList.findByTravelerAndItem(traveler_index, 'all', -1)?.selectedOptionLabel}
              </MappingText>
            </MappingBox>
          ))}
        </TravelerBox>
      ) : mode === 'both' ? (
        <TravelerBox>
          <MappingTitle>商品×出張者ごと</MappingTitle>
          {reserveInfo.reservingTrip.isPackage() ? (
            <MappingBox>
              <MappingLabel>パッケージ</MappingLabel>
              {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                <React.Fragment key={traveler_index}>
                  <MappingLabel>{traveler.getTravelerName()}</MappingLabel>
                  <MappingText>
                    {mappingList.findByTravelerAndItem(traveler_index, 'package', 1)?.selectedOptionLabel}
                  </MappingText>
                </React.Fragment>
              ))}
            </MappingBox>
          ) : (
            <>
              {getItemList().map((item: any, i: number) => (
                <MappingBox key={i}>
                  {item?.element_type === 'hotel' || item?.elementType === 'hotel' ? (
                    <MappingLabel>{i + 1}. ホテル</MappingLabel>
                  ) : (
                    <MappingLabel>{i + 1}. 経路</MappingLabel>
                  )}
                  {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                    <React.Fragment key={traveler_index}>
                      <MappingLabel>{traveler.getTravelerName()}</MappingLabel>
                      <MappingText>
                        {mappingList.findByTravelerAndItem(traveler_index, 'item', Number(i))?.selectedOptionLabel}
                      </MappingText>
                    </React.Fragment>
                  ))}
                </MappingBox>
              ))}
            </>
          )}
          {reserveInfo.rentalCarRequired === 'true' && (
            <>
              {reserveInfo.rentalCars.map((_rentalCar, car_index) => (
                <MappingBox key={car_index}>
                  <MappingLabel>{`レンタカー${car_index + 1}`}</MappingLabel>
                  {reserveInfo.travelers.list.map((traveler, traveler_index) => (
                    <React.Fragment key={traveler_index}>
                      <MappingLabel>{traveler.getTravelerName()}</MappingLabel>
                      <MappingText>
                        {
                          mappingList.findByTravelerAndItem(traveler_index, 'rental_car', Number(car_index))
                            ?.selectedOptionLabel
                        }
                      </MappingText>
                    </React.Fragment>
                  ))}
                </MappingBox>
              ))}
            </>
          )}
        </TravelerBox>
      ) : null}
    </ChildContent>
  );
};

const ChildContent = styled.div`
  font-size: 13px;
  margin-left: 10px;
`;

const MappingBox = styled.div`
  width: 100%;
  display: flex;
`;

const MappingTitle = styled.p`
  margin: 10px 5px 5px;
`;

const MappingLabel = styled.div`
  line-height: 39px;
  padding-right: 5px;
`;

const MappingText = styled.div`
  line-height: 39px;
`;

const TravelerBox = styled.div`
  margin-bottom: 15px;
  margin-left: 10px;
`;

export default OrderItemMappingListSection;
