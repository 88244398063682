import { computed, observable } from 'mobx';

type ContractualAgreementType = 'smas' | 'no_smas';

export type IndividualRentalcarSuppliedItemArgs = {
  id: number;
  contractual_agreement_type: ContractualAgreementType;
  supplied_item_id?: number;
};

export default class IndividualRentalcarSuppliedItem {
  @observable
  id: number;

  @observable
  contractualAgreementType: ContractualAgreementType;

  @observable
  suppliedItemId: number | null;

  constructor(args: IndividualRentalcarSuppliedItemArgs) {
    this.id = args.id;
    this.contractualAgreementType = args.contractual_agreement_type;
    this.suppliedItemId = args.supplied_item_id || null;
  }

  @computed
  get contractualAgreementTypeLabel() {
    return this.contractualAgreementType === 'smas' ? 'SMAS利用企業' : 'SMAS利用企業以外';
  }

  submitParams() {
    return {
      supplied_item_id: this.suppliedItemId
    };
  }
}
