import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import { observable } from 'mobx';

export interface HotelOrderItemRakutenTotalArgs {
  reservation_date: string;
  hotel_element_total: number;
  hotel_element_price_total: number;
  order_item_original_price_total: number;
  order_item_original_price_price_total: number;
  order_item_price_details_price_total: number;
  rakuten_reservation_total: number;
  rakuten_reservation_price_total: number;
  exact_match: boolean;
  approved_at: string;
  approved_by: number;
  supplied_item_id: number;
}

export interface HotelOrderItemRakutenTotalProps {
  reservationDate: Moment;
  hotelElementTotal: number;
  hotelElementPriceTotal: number;
  orderItemOriginalPriceTotal: number;
  orderItemOriginalPricePriceTotal: number;
  orderItemPriceDetailsPriceTotal: number;
  rakutenReservationTotal: number;
  rakutenReservationPriceTotal: number;
  exactMatch: boolean;
  approvedAt: Moment | null;
  approvedBy: number | null;
  suppliedItemId: number | null;
}

export const convertHotelOrderItemRakutenTotal = (
  args: HotelOrderItemRakutenTotalArgs
): HotelOrderItemRakutenTotalProps => ({
  reservationDate: moment(args.reservation_date),
  hotelElementTotal: args.hotel_element_total,
  hotelElementPriceTotal: args.hotel_element_price_total,
  orderItemOriginalPriceTotal: args.order_item_original_price_total,
  orderItemOriginalPricePriceTotal: args.order_item_original_price_price_total,
  orderItemPriceDetailsPriceTotal: args.order_item_price_details_price_total,
  rakutenReservationTotal: args.rakuten_reservation_total,
  rakutenReservationPriceTotal: args.rakuten_reservation_price_total,
  exactMatch: args.exact_match,
  approvedAt: args.approved_at ? moment(args.approved_at) : null,
  approvedBy: args.approved_by || null,
  suppliedItemId: args.supplied_item_id || null
});

export default class HotelOrderItemRakutenTotal {
  @observable readonly reservationDate: Moment;

  @observable readonly hotelElementTotal: number;

  @observable readonly hotelElementPriceTotal: number;

  @observable readonly orderItemOriginalPriceTotal: number;

  @observable readonly orderItemOriginalPricePriceTotal: number;

  @observable readonly orderItemPriceDetailsPriceTotal: number;

  @observable readonly rakutenReservationTotal: number;

  @observable readonly rakutenReservationPriceTotal: number;

  @observable readonly exactMatch: boolean;

  @observable approvedAt: Moment | null;

  @observable approvedBy: number | null;

  @observable suppliedItemId: number | null;

  constructor(args: HotelOrderItemRakutenTotalProps) {
    this.reservationDate = args.reservationDate;
    this.hotelElementTotal = args.hotelElementTotal;
    this.hotelElementPriceTotal = args.hotelElementPriceTotal;
    this.orderItemOriginalPriceTotal = args.orderItemOriginalPriceTotal;
    this.orderItemOriginalPricePriceTotal = args.orderItemOriginalPricePriceTotal;
    this.orderItemPriceDetailsPriceTotal = args.orderItemPriceDetailsPriceTotal;
    this.rakutenReservationTotal = args.rakutenReservationTotal;
    this.rakutenReservationPriceTotal = args.rakutenReservationPriceTotal;
    this.exactMatch = args.exactMatch;
    this.approvedAt = args.approvedAt;
    this.approvedBy = args.approvedBy;
    this.suppliedItemId = args.suppliedItemId;
  }

  setApprovedAt(approvedAt?: string) {
    this.approvedAt = approvedAt ? moment(approvedAt) : null;
  }

  setApprovedBy(approvedBy?: number) {
    this.approvedBy = approvedBy || null;
  }

  setSuppliedItemId(suppliedItemId?: number) {
    this.suppliedItemId = suppliedItemId || null;
  }
}
