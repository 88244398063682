import React, { useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import type { ActivitySelected } from '@this/src/domain/trip/trip_activity_store';
import { TripActivityContext, TripActivityStore } from '@this/src/domain/trip/trip_activity_store';
import { lighten, Paper, Badge } from '@material-ui/core';
import { HeaderWrap, HeaderBody, PageTitle } from '../trips_management/trip_report/shared/wrappers';

type Props = {
  selected: ActivitySelected;
  tripReportAvailable: boolean;
};

const InAdvanceHeader: React.FC<Props> = ({ selected, tripReportAvailable }) => {
  const location = useLocation();
  const store = useMemo(() => new TripActivityStore(location.search), []);
  const activity = store.activity(selected);
  const tripUnread = store.unreadCount('trip');
  const tripReportUnread = store.unreadCount('tripReport');

  const fetchActivity = useCallback(() => {
    store.getApprovalActivity();
  }, []);

  useEffect(() => {
    fetchActivity();
  }, []);

  return (
    <TripActivityContext.Provider value={store}>
      <HeaderWrap>
        <HeaderBody>
          <PageTitle>承認</PageTitle>
          {activity.length > 0 && (
            <Activity>
              {activity.map((row, i) => (
                <ActivityRow key={i}>
                  {row.unread && <ActivityChip>NEW</ActivityChip>}
                  <p>{row.message}</p>
                </ActivityRow>
              ))}
            </Activity>
          )}
          <Navigations>
            <Badge badgeContent={tripUnread} color="error" invisible={tripUnread < 1}>
              <Tab className={selected === 'trip' ? 'selected' : ''} to="/application/in_advance">
                出張前の申請
                {store.tripCount > 0 && `（${store.tripCount}）`}
              </Tab>
            </Badge>
            {tripReportAvailable && (
              <Badge badgeContent={tripReportUnread} color="error" invisible={tripReportUnread < 1}>
                <Tab className={selected === 'tripReport' ? 'selected' : ''} to="/trip_report/approvals">
                  出張後の報告
                  {store.tripReportCount > 0 && `（${store.tripReportCount}）`}
                </Tab>
              </Badge>
            )}
          </Navigations>
        </HeaderBody>
      </HeaderWrap>
    </TripActivityContext.Provider>
  );
};

const Activity = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin-top: 12px;
`;

const ActivityRow = styled(Paper as any)`
  display: flex;
  gap: 8px;
  padding: 16px;
`;

const ActivityChip = styled.div`
  color: ${props => props.theme.redColor};
`;

const Navigations = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 30px;
  border-bottom: solid 3px ${props => props.theme.accentBorderColor};
`;

const Tab = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  max-width: calc(50vw - 2px);
  color: ${props => props.theme.textColor};
  background-color: ${props => lighten(props.theme.grayBgColorLight, 0.5)};
  cursor: pointer;

  &.selected {
    color: #ffffff;
    background-color: ${props => props.theme.accentBorderColor};
    pointer-events: none;
  }
`;

export default InAdvanceHeader;
