import type { SettingArgs } from '../setting';
import Setting from '../setting';
import type { CutoffDateJson } from './cutoff_date';
import { CutoffDate } from './cutoff_date';
import type { RemittanceSourceAccountJson } from './remittance_source_account';
import RemittanceSourceAccount from './remittance_source_account';

export interface SettingJson {
  cutoff_dates: CutoffDateJson[];
  account?: RemittanceSourceAccountJson;
  zengin_available: boolean;
  announcement: string | null;
  multistage_approval: boolean;
  setting: SettingArgs;
}

export const CutoffDateType = 'cutoff';
export const ApplicationDateType = 'application';
export const PaymentDateType = 'payment';
export const AllDateType = 'all';

export class OrganizationSetting {
  private cutoffDates: CutoffDate[];

  account?: RemittanceSourceAccount;

  zenginAvailable: boolean;

  announcement: string;

  multistageApproval: boolean;

  setting: Setting;

  showApprovalSetting: boolean;

  constructor(args: SettingJson) {
    this.cutoffDates = args.cutoff_dates.map(d => new CutoffDate(d)).filter(d => d.dateType);
    if (args.account) {
      this.account = new RemittanceSourceAccount(args.account);
    } else if (args.zengin_available) {
      this.account = RemittanceSourceAccount.build();
    }
    this.zenginAvailable = args.zengin_available;
    this.announcement = args.announcement || '';
    this.multistageApproval = args.multistage_approval;
    this.setting = new Setting(args.setting);
    this.showApprovalSetting = !args.multistage_approval;
  }

  cutoffDatesToShow(dateType: string): CutoffDate[] {
    switch (dateType) {
      case CutoffDateType:
        return this.cutoffDates.filter(date => !date.destroy && date.dateType === CutoffDateType);
      case ApplicationDateType:
        return this.cutoffDates.filter(date => !date.destroy && date.dateType === ApplicationDateType);
      case PaymentDateType:
        return this.cutoffDates.filter(date => !date.destroy && date.dateType === PaymentDateType);
      case AllDateType:
        return this.cutoffDates.filter(date => !date.destroy);
      default:
        return this.cutoffDates.filter(date => !date.destroy);
    }
  }

  addNewCutoffDate = (dateType: string, index: number) => {
    this.cutoffDates.splice(index, 0, new CutoffDate({ date: '1', end_of_month: false, date_type: dateType }));
    app.render();
  };

  removeCutoffDate = (index: number) => {
    const dates = this.cutoffDatesToShow(AllDateType);
    dates[index].setDestroy();
    app.render();
  };

  setCutoffDate = (index: number, date: string, date_type: string) => {
    const dates = this.cutoffDatesToShow(AllDateType);
    dates[index].setDate(date, date_type);
    app.render();
  };

  updateAnnouncement = (value: string) => {
    this.announcement = value;
    app.render();
  };

  updateMultistageApproval = () => {
    this.multistageApproval = !this.multistageApproval;
    app.render();
  };

  submitParams() {
    const params: { [key: string]: any } = {
      cutoff_dates_attributes: this.cutoffDates.map(date => date.submitParams())
    };
    if (this.account) params.remittance_source_account_attributes = this.account.submitParams();
    params.setting_attributes = { id: this.setting.id, announcement: this.announcement };
    params.expenses_multistage_approval = this.multistageApproval;
    return params;
  }
}
