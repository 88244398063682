import React from 'react';
import { styled } from '@this/constants/themes';

interface Props {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * @deprecated
 * should use js/src/components/shared/ui/inputs/select
 */
const Select: React.FC<Props> = ({ value, onChange, children }) => (
  <Wrapper>
    <TheSelect value={value} onChange={e => onChange(e)}>
      {children}
    </TheSelect>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;

  ::after {
    content: '▼';
    position: absolute;
    top: 6px;
    right: 0;
    color: ${props => props.theme.selectArrowColor};
    font-size: 10px;
  }
`;

const TheSelect = styled.select`
  padding: 4px 16px 4px 2px;
  margin: 0;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #333;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export default Select;
