import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import type HotelOrderItemRakutenTotal from '@this/src/domain/arrangement/hotel_order_item_rakuten_total';
import type Arranger from '@this/src/domain/arranger/arranger';
import type { HotelElementProviderKey } from '@this/src/domain/hotel_element_provider';
import HotelOrderItemRakutenTotalTableRow from './hotel_order_item_rakuten_total_table_row';

interface Props {
  hotelOrderItemRakutenTotals: HotelOrderItemRakutenTotal[];
  arrangers: Arranger[];
  currentArranger: Arranger | null;
  suppliedItemId: number | null;
  providers: HotelElementProviderKey[];
}

const HotelOrderItemTodoListRakutenTotal: React.FC<Props> = ({
  hotelOrderItemRakutenTotals,
  arrangers,
  currentArranger,
  suppliedItemId,
  providers
}) => {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>予約日</TableCell>
          <TableCell align="right">HotelElement件数</TableCell>
          <TableCell align="right">卸値の件数</TableCell>
          <TableCell align="right">楽天CSVの件数</TableCell>
          <TableCell align="right">代金詳細の合計金額</TableCell>
          <TableCell align="right">卸値の合計金額</TableCell>
          <TableCell align="right">楽天CSVの合計金額</TableCell>
          <TableCell>完全一致</TableCell>
          <TableCell />
          <TableCell />
          <TableCell>総数承認日</TableCell>
          <TableCell>総数承認者</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {hotelOrderItemRakutenTotals.map(hotelOrderItemRakutenTotal => (
          <HotelOrderItemRakutenTotalTableRow
            key={hotelOrderItemRakutenTotal.reservationDate.format('YYYY-MM-DD')}
            hotelOrderItemRakutenTotal={hotelOrderItemRakutenTotal}
            arrangers={arrangers}
            currentArranger={currentArranger}
            suppliedItemId={suppliedItemId}
            providers={providers}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default HotelOrderItemTodoListRakutenTotal;
