class WifiElement
  constructor: (args = {}) ->
    _.assign(@, snakeToCamelObject(args))
    @type = 'wifi'
    @wifiProviderOptions = camelToSnakeObject(@wifiProviderOptions)
    @startedAt = moment(args.startedAt)
    @endedAt = if args.endedAt then moment(args.endedAt) else moment(@startedAt).add(1, 'hour')
    @provider ||= _.keys(@wifiProviderOptions)[0]
    @area ||= ''
    @remark ||= ''
    @reservationNumber = args.reservationNumber || ''

  handleStartedAtChange: (moment) ->
    @startedAt = moment
    app.render()

  handleEndedAtChange: (moment) ->
    @endedAt = moment
    app.render()

  handleChangeProvider: (value) ->
    @provider = value
    app.render()

  handleChangeArea: (value) ->
    @area = value
    app.render()

  handleChangeRemark: (value) ->
    @remark = value
    app.render()

  startDate: ->
    @startedAt

  endDate: ->
    @endedAt

  description: ->
    """
    <WiFi>
    ■プロバイダ：#{@providerName()}
    ■ご利用日時：#{@startedAt.format('MM/DD HH:mm')}〜#{@endedAt.format('MM/DD HH:mm')}
    ■ご利用エリア：#{@area}
    ■備考
    #{@remark}
    """

  title: ->
    'WiFi'

  summary: ->
    '[WiFi]' + @providerName()

  detail: ->
    ''

  structuredDetail: ->
    []

  mappingDescription: ->
    @title()

  providerName: ->
    @wifiProviderOptions[@provider] || ''

  validationErrors: (orderItemStatus) ->
    errors = []
    needStatusGuard = orderItemStatus == 7 || orderItemStatus == 13
    if @area == '' then errors.push(@wifiValidationError)
    if @endedAt < @startedAt then errors.push(@timeValidationError)
    if @reservationNumber == '' && needStatusGuard then errors.push(@reservationNumberValidationError)
    errors

  handleReservationNumberChange: (value) ->
    @reservationNumber = value
    app.render()

  wifiValidationError:
    'wifiのエリアを入力してください。'

  timeValidationError:
    '利用開始以降を指定してください。'

  reservationNumberValidationError:
    '注文番号を入力してください。'

  updateParams: ->
    type: @type
    provider: @provider
    started_at: @startedAt.format('YYYY-MM-DD HH:mm:ss')
    ended_at: @endedAt.format('YYYY-MM-DD HH:mm:ss')
    area: @area
    remark: @remark
    reservation_number: @reservationNumber

module.exports = WifiElement
