import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import ExpenseConfirmTemplate from './expense_confirm/expense_confirm.template';

interface Props {
  user: any;
  report: any;
  projects?: any[] | null;
}

const TripReportBodyTemplate: React.SFC<Props> = ({ user, report, projects }) => (
  <>
    <Section>
      <Title>旅程情報</Title>
      <p>
        部署：<span data-wovn-ignore>{user.department.name}</span>
        <br />
        氏名：<span data-wovn-ignore>{user.name}</span>
        <br />
        場所：<span data-wovn-ignore>{`${report.origin} <=> ${report.destination}`}</span>
        <br />
        期間：
        <span data-wovn-ignore>{`${report.departureDate.format('YYYY-MM-DD')} 〜 ${report.returnDate.format(
          'YYYY-MM-DD'
        )}`}</span>
      </p>
    </Section>
    <Section>
      <Title>経費一覧</Title>
      {report.expenseList.items.map((expense: any, i: number) => (
        <div key={i}>
          <ExpenseConfirmTemplate
            expense={expense}
            i={i}
            project={_.find(projects, p => p.id === expense.projectId)}
          />
        </div>
      ))}
    </Section>
    <Section>
      <Title>経費集計</Title>
      <table className="key-value-table">
        <tbody>
          {Object.entries(report.typeSummary()).map((v, k) => (
            <tr key={k}>
              <td className="key-value-table__label">{v[0]}</td>
              <td className="key-value-table__separator">：</td>
              {typeof v[1] === 'string' ? (
                <td data-wovn-ignore className="key-value-table__value">
                  {v[1]}
                </td>
              ) : (
                <td />
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Section>
    <Section>
      <Title>出張先</Title>
      <p data-wovn-ignore>{report.finalDestination}</p>
    </Section>
    <Section>
      <Title>出張の目的</Title>
      <p data-wovn-ignore>{report.purpose}</p>
    </Section>
    <Section>
      <Title>成果報告・備考</Title>
      <Remark data-wovn-ignore>{report.result}</Remark>
    </Section>
  </>
);

const Section = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h3`
  margin-bottom: 20px;
`;

const Remark = styled.p`
  word-break: break-all;
`;

export default TripReportBodyTemplate;
