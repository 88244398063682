import { useRef } from 'react';
import _ from 'lodash';

function useObjectMemo<T>(object: T) {
  const ref = useRef<T>();
  if (!_.isEqual(object, ref.current)) {
    ref.current = object;
  }
  return ref.current;
}

export default useObjectMemo;
