import type { ChangeEvent, MouseEvent } from 'react';
import React, { useState, useCallback } from 'react';
import HeaderPc from '@this/components/lp/simulation/header_pc';
import HeaderSp from '@this/components/lp/simulation/header_sp';
import SimulationPc from '@this/components/lp/simulation/simulation_pc';
import SimulationSp from '@this/components/lp/simulation/simulation_sp';
import FooterPc from '@this/components/lp/simulation/footer_pc';
import FooterSp from '@this/components/lp/simulation/footer_sp';

const howList = ['社員が自分で予約', 'アシスタントが手配', '旅行代理店を利用', '出張専用のサービスを利用'];
const adjustList = ['紙で経費精算&申請', '経費精算ソフトを利用', '出張専用のサービスを利用'];

export type SimulationData = {
  cost: number;
  count: number;
  how: number;
  adjust: number;
  term: string;
  amount: number;
  directCost: number;
  indirectCost: number;
  time: number;
  efficiency: number;
};

const Simulation = () => {
  const [simulationData, setSimulationData] = useState<SimulationData>({
    cost: 100,
    count: 30,
    how: 0,
    adjust: 0,
    term: '12',
    amount: 0,
    directCost: 0,
    indirectCost: 0,
    time: 0,
    efficiency: 0
  });
  const [showResult, setShowResult] = useState(false);

  const handleChange = useCallback((key: keyof SimulationData, e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSimulationData(prev => ({ ...prev, [key]: value }));
  }, []);

  const makeSimulation = useCallback(
    (term: string) => {
      const HOW_IMPROVEMENT_RATIOS = [0.8, 0.6, 0.4, 0.2];
      const ADJUST_IMPROVEMENT_RATIOS = [0.8, 0.5, 0.2];
      const HUMAN_COST = 3000;
      const DIRECT_COST_IMPROVEMENT_RATIO = 0.12;
      const RESERVATION_TIME_IMPROVEMENT_RATIO = 0.82;
      const APPLY_TIME_IMPROVEMENT_RATIO = 0.09;
      const ADJUST_TIME_IMPROVEMENT_RATIO = 0.09;

      const cost = (simulationData.cost || 100) * 10000;
      const count = simulationData.count || 30;
      const avgCost = cost / count;
      const people = cost / avgCost;
      const howImprovement = HOW_IMPROVEMENT_RATIOS[simulationData.how];
      const adjustImprovement = ADJUST_IMPROVEMENT_RATIOS[simulationData.adjust];
      const improvementRatio =
        howImprovement * RESERVATION_TIME_IMPROVEMENT_RATIO +
        howImprovement * APPLY_TIME_IMPROVEMENT_RATIO +
        adjustImprovement * ADJUST_TIME_IMPROVEMENT_RATIO;
      const indirectConstImprovement =
        (count * HUMAN_COST * howImprovement * 50) / 60 + (people * HUMAN_COST * adjustImprovement * 5) / 60;
      const directCostImprovement = cost * DIRECT_COST_IMPROVEMENT_RATIO;
      const timeImprovement =
        (count * 45 * howImprovement) / 60 +
        (count * 5 * howImprovement) / 60 +
        (count * 5 * adjustImprovement) / 60;
      // 有効小数点第一位
      // 金額系は1万円単位に桁削る
      const directCost = Math.round(((directCostImprovement * Number(term)) / 10000) * 10) / 10;
      const indirectCost = Math.round(((indirectConstImprovement * Number(term)) / 10000) * 10) / 10;
      // 合計金額は小数点以下四捨五入
      const amount = Math.round(directCost + indirectCost);
      const time = Math.round(timeImprovement * Number(term) * 10) / 10;
      // 少数を%に変換
      const efficiency = Math.round(improvementRatio * 100 * 10) / 10;

      setSimulationData(prev => ({ ...prev, directCost, indirectCost, amount, time, efficiency }));
    },
    [simulationData]
  );

  const handleTermChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setSimulationData(prev => ({ ...prev, term: value }));
      makeSimulation(e.target.value);
    },
    [makeSimulation]
  );

  const handleSimulateButtonClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      makeSimulation(simulationData.term);
      setShowResult(true);
    },
    [makeSimulation, simulationData]
  );

  return (
    <div className="lp">
      <div className="simulation_pc">
        <HeaderPc />
        <SimulationPc
          simulationData={simulationData}
          adjustList={adjustList}
          howList={howList}
          showResult={showResult}
          handleChange={handleChange}
          handleTermChange={handleTermChange}
          handleSimulateButtonClick={handleSimulateButtonClick}
        />
        <FooterPc />
      </div>
      <div className="simulation_sp">
        <HeaderSp />
        <SimulationSp
          simulationData={simulationData}
          adjustList={adjustList}
          howList={howList}
          showResult={showResult}
          handleChange={handleChange}
          handleTermChange={handleTermChange}
          handleSimulateButtonClick={handleSimulateButtonClick}
        />
        <FooterSp />
      </div>
    </div>
  );
};

export default Simulation;
