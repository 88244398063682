import React from 'react';
import { styled } from '@this/constants/themes';
import A from '@this/shared/atoms/a';
import Text from '@this/shared/text/text';
import DateSelector from '@this/shared/date_selector/date_selector';
import moment from 'moment-timezone';
import type { Moment } from 'moment';
import { Input, TextArea } from '../trip_report_form/trip_report_form';

interface Props {
  index: number;
  report: any;
  expense: any;
  types: [];
  projects: [];
}

interface State {}

class ExpenseForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  handleChange(name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    e.preventDefault();
    this.props.expense[name](e.target.value);
  }

  handleRemoveButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.report.removeExpense(this.props.expense);
  }

  render() {
    try {
      const { index, expense, types, projects } = this.props;
      return (
        <Wrap>
          <Number>{index + 1}</Number>
          <div>
            {expense.paid ? (
              <>
                <Paid>
                  日付：<span data-wovn-ignore>{expense.date.format('YYYY-MM-DD')}</span>
                </Paid>
                <Paid>
                  種別：<span data-wovn-ignore>{expense.type}</span>
                </Paid>
                <Paid>
                  支払い先・内容：
                  <Text data-wovn-ignore text={expense.abstract} />
                </Paid>
                <Paid>
                  金額：<span data-wovn-ignore>{utils.formatPrice(expense.price)}</span>
                </Paid>
              </>
            ) : (
              <>
                <Label>日付</Label>
                <DateSelector
                  onDateChange={(m: Moment) => expense.setDate(m)}
                  date={expense.date ? moment(expense.date) : moment()}
                />
                <Label>種別</Label>
                <select value={expense.type} onChange={e => this.handleChange('setType', e)}>
                  <option value="" />
                  {types.map((t: string, i: number) => (
                    <option key={i} value={t}>
                      {t}
                    </option>
                  ))}
                </select>
                <Label>支払い先・内容</Label>
                <TextArea value={expense.abstract} onChange={e => this.handleChange('setAbstract', e)} cols={40} />
                <Label>金額</Label>
                <Input type="number" value={expense.price} onChange={e => this.handleChange('setPrice', e)} />
                <Label>費用負担先</Label>
                <select value={expense.projectId} onChange={e => this.handleChange('setProjectId', e)}>
                  <option value="" />
                  {projects.map((p: any, i: number) => (
                    <option key={i} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </select>
                <Label>社内管理番号</Label>
                <Input
                  type="text"
                  value={expense.internalNumber}
                  onChange={e => this.handleChange('setInternalNumber', e)}
                />
                <Label>メモ</Label>
                <TextArea value={expense.memo} onChange={e => this.handleChange('setMemo', e)} cols={40} />
              </>
            )}
          </div>
          <div>{!expense.paid && <Remove onClick={e => this.handleRemoveButtonClick(e)}>×</Remove>}</div>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Number = styled.div`
  text-align: right;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid ${props => props.theme.grayBorderColor};
`;

const Paid = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-weight: normal;
  margin-bottom: 5px;
`;

const Remove = styled(A)`
  display: block;
  font-size: 20px;
  margin-left: auto;
`;

export default ExpenseForm;
