import type { Moment } from 'moment';
import moment from 'moment';

export interface SearchTrackingExternalLogArgs {
  id: number;
  search_tracking_id: number;
  table_name?: string;
  table_id?: number;
  operation?: string;
  created_at: string;
}

class SearchTrackingExternalLog {
  id: number;

  searchTrackingId: number;

  tableName: string | null;

  tableId: number | null;

  operation: string | null;

  createdAt: Moment;

  constructor(args: SearchTrackingExternalLogArgs) {
    this.id = args.id;
    this.searchTrackingId = args.search_tracking_id;
    this.tableName = args.table_name || null;
    this.tableId = args.table_id || null;
    this.operation = args.operation || null;
    this.createdAt = moment(args.created_at);
  }
}

export default SearchTrackingExternalLog;
