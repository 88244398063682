import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import _ from 'lodash';

import type { AvailableRepository } from '@this/domain/available_repository';
import Dashboard from './dashboard/dashboard';
import Select from './select/select';
import Hotel from '../hotel/hotel';
import HotelOrderItem from '../hotel/order_item/hotel_order_item';
import ReserveConfirm from './reserve_confirm/reserve_confirm';
import InformationDetail from './dashboard/information_detail/information_detail';
import OrganizationInformationDetail from './dashboard/organization_information_detail/organization_information_detail';
import SearchQuery from '../../domain/search_query';
import User from '../../domain/user/user';
import type UserJson from '../../domain/user/user_json';

interface TravelerCandidatesResponse {
  members: UserJson[];
}

interface Props {
  user: User | null;
  serviceId: number;
  availableRepos: AvailableRepository[];
  availableOptions: string[];
}

interface State {
  query: SearchQuery;
}

export const ReserveTripPaths = [
  '/dashboard',
  '/select',
  '/hotels',
  '/reserve_confirm',
  '/informations/:id',
  '/organization_informations/:id'
];

class ReserveTrip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const user = this.props.user;
    const defaultSeatClasses = user ? _.cloneDeep(user.defaultSeatClasses) : undefined;
    const params = utils.getParams();
    const query = new SearchQuery(
      Object.keys(params).length === 0
        ? {
            defaultSeatClasses,
            serviceId: props.serviceId,
            availableRepos: props.availableRepos
          }
        : Object.assign(params, {
            defaultSeatClasses,
            serviceId: props.serviceId,
            availableRepos: props.availableRepos
          })
    );
    this.state = { query };
  }

  async componentDidUpdate() {
    // userが入ってきたタイミングでqueryにtravelersを設定する
    const { user } = this.props;
    const params = utils.getParams();
    const travelerQueries = params.travelers;
    if (travelerQueries && user && !this.state.query.travelers) {
      const ids = travelerQueries.map((t: any) => t.user_id).filter((t: any) => !_.isNil(t));
      utils
        .jsonPromise<TravelerCandidatesResponse>('/members/traveler_candidates', { ids })
        .then(res => {
          const members = res.members.map(m => new User(m));
          this.state.query.setTravelersFromQuery(travelerQueries, user, members);
        })
        .catch(e => {
          utils.sendErrorObject(e);
        });
    }
  }

  render() {
    const { user, serviceId, availableRepos, availableOptions } = this.props;
    const { query } = this.state;

    return (
      <Switch>
        <Route
          exact
          path="/dashboard"
          render={props => <Dashboard {...{ user, serviceId, query, availableOptions }} {...props} />}
        />
        <Route
          path="/select"
          render={props => (
            <>
              {user ? (
                <Select {...{ user, serviceId, query, availableRepos, availableOptions }} {...props} />
              ) : (
                <Spacer />
              )}
            </>
          )}
        />
        <Route
          path="/hotels/order_items"
          render={props => (
            <>{user ? <HotelOrderItem {...{ user, serviceId, query }} {...props} /> : <Spacer />}</>
          )}
        />
        <Route
          path="/hotels"
          render={props => <>{user ? <Hotel {...{ user, serviceId, query }} {...props} /> : <Spacer />}</>}
        />
        <Route
          path="/reserve_confirm"
          render={props => (
            <>{user ? <ReserveConfirm {...{ user, serviceId, query }} {...props} /> : <Spacer />}</>
          )}
        />
        <Route path="/informations/:id" component={InformationDetail} />
        <Route path="/organization_informations/:id" component={OrganizationInformationDetail} />
      </Switch>
    );
  }
}

const Spacer = styled.div`
  flex-grow: 9999;
  background: white;
`;

export default ReserveTrip;
