import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { Fetcher } from '@this/src/util';
import { Button } from '@this/shared/ui/inputs/button';
import { Modal, ModalBody } from '@this/shared/ui/feedbacks/modal';
import { Loading } from '@this/shared/ui/feedbacks/loading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@this/src/components/shared/ui/data_displays/table';
import { Link } from '@this/components/shared/ui/navigations/link';
import type InvoiceCustomFormat from '@this/domain/invoice_custom_format/invoice_custom_format';
import type InvoiceStandardTable from '@this/src/domain/invoice_custom_format/invoice_standard_table';
import InvoiceCustomFormatField from '@this/src/domain/invoice_custom_format/invoice_custom_format_field';
import InvoiceCustomFormatFieldForm from './invoice_custom_format_field_form';

interface Props {
  invoiceCustomFormat: InvoiceCustomFormat;
  invoiceStandardTables: InvoiceStandardTable[];
  onSubmit: () => void;
}

const InvoiceCustomFormatForm = observer(({ invoiceCustomFormat, invoiceStandardTables, onSubmit }: Props) => {
  const [editingField, setEditingField] = useState<InvoiceCustomFormatField | null>(null);
  const [editTargetField, setEditTargetField] = useState<InvoiceCustomFormatField | null>(null);
  const [erros, setErrors] = useState<string[]>([]);
  const [fetchingPreviews, setFetchingPreviews] = useState(false);

  const fetchFieldPreviews = async () => {
    const params = invoiceCustomFormat.invoiceCustomFormatFields.map(field => {
      return field.submitParams();
    });
    if (params.length === 0) return;
    setFetchingPreviews(true);
    const response = await Fetcher.get<{ results: { [key: string]: string } }>(
      `/organization/invoice_custom_formats/field_previews.json`,
      { field_params: params }
    );
    const results = response.results;
    Object.keys(results).forEach(key => {
      const field = invoiceCustomFormat.invoiceCustomFormatFields.find(f => f.name === key);
      if (field === undefined) return;
      field.preview = results[key];
    });
    setFetchingPreviews(false);
  };

  useEffect(() => {
    fetchFieldPreviews();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors([]);
    const errs = invoiceCustomFormat.validate();
    setErrors(errs);
    if (errs.length > 0) return;
    onSubmit();
  };

  const handleAddField = () => {
    const field = new InvoiceCustomFormatField({
      column_type: 'standard'
    });
    setEditingField(field);
    setEditTargetField(null);
  };

  const handleEditField = (field: InvoiceCustomFormatField) => {
    const fieldCopy = _.cloneDeep(field);
    setEditingField(fieldCopy);
    setEditTargetField(field);
  };

  const handleConfirmField = () => {
    if (editingField === null) return;
    if (editTargetField === null) {
      invoiceCustomFormat.invoiceCustomFormatFields.push(editingField);
    } else {
      const index = invoiceCustomFormat.invoiceCustomFormatFields.findIndex(f => f === editTargetField);
      if (index === -1) return;
      invoiceCustomFormat.invoiceCustomFormatFields[index] = editingField;
    }
    setEditingField(null);
    setEditTargetField(null);
    fetchFieldPreviews();
  };

  const handleCancelField = () => {
    setEditingField(null);
    setEditTargetField(null);
  };

  const handleRemoveField = async (field: InvoiceCustomFormatField) => {
    if (confirm('本当に削除しますか？')) {
      field.removed = true;
      if (!_.isNil(invoiceCustomFormat.id) && !_.isNil(field.id)) {
        await Fetcher.delete(
          `/organization/invoice_custom_formats/${invoiceCustomFormat.id}/invoice_custom_format_fields/${field.id}.json`
        );
      }
      invoiceCustomFormat.invoiceCustomFormatFields = invoiceCustomFormat.invoiceCustomFormatFields.filter(
        f => !f.removed
      );
      fetchFieldPreviews();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: '20px 0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <label htmlFor="name" style={{ width: '50px' }}>
                名称
              </label>
              <input
                id="name"
                type="text"
                style={{ margin: 0 }}
                value={invoiceCustomFormat.name}
                onChange={e => {
                  invoiceCustomFormat.name = e.target.value;
                }}
              />
            </div>
            <div>
              <Button type="submit">保存</Button>
            </div>
          </div>
          <div style={{ marginTop: '10px', textAlign: 'right' }}>
            {erros.map((error, i) => (
              <p key={i} className="error">
                {error}
              </p>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div>
            <Button size="small" onClick={handleAddField} style={{ marginBottom: '10px', width: '100%' }}>
              新しいカラムを追加
            </Button>
            {invoiceCustomFormat.invoiceCustomFormatFields.map(field => (
              <div
                key={field.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px'
                }}
              >
                <p style={{ marginRight: '10px' }}>{field.name}</p>
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Link
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      handleEditField(field);
                    }}
                  >
                    編集
                  </Link>
                  <Link
                    color="danger"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      handleRemoveField(field);
                    }}
                  >
                    削除
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div>
            {fetchingPreviews ? (
              <Loading size="small" />
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {invoiceCustomFormat.invoiceCustomFormatFields.map((field, i) => (
                      <TableCell key={i}>{field.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {invoiceCustomFormat.invoiceCustomFormatFields.map((field, i) => (
                      <TableCell key={i}>{field.preview}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </form>
      {editingField !== null && (
        <Modal size="large" open={editingField !== null} onClose={handleCancelField}>
          <ModalBody>
            <InvoiceCustomFormatFieldForm
              invoiceCustomFormatField={editingField}
              invoiceStandardTables={invoiceStandardTables}
              onCancel={handleCancelField}
              onConfirm={handleConfirmField}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
});

export default InvoiceCustomFormatForm;
