import React from 'react';

export const AiHotelIcon: React.FC = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5714 3.27206C5.97007 2.10539 7.58207 2.07006 8.05474 3.16606L8.09474 3.27272L8.63274 4.84606C8.75603 5.20688 8.95527 5.53706 9.21702 5.81434C9.47877 6.09161 9.79694 6.30953 10.1501 6.45339L10.2947 6.50739L11.8681 7.04472C13.0347 7.44339 13.0701 9.05539 11.9747 9.52806L11.8681 9.56806L10.2947 10.1061C9.93379 10.2293 9.60348 10.4285 9.32608 10.6902C9.04869 10.952 8.83067 11.2702 8.68674 11.6234L8.63274 11.7674L8.0954 13.3414C7.69674 14.5081 6.08474 14.5434 5.61274 13.4481L5.5714 13.3414L5.03407 11.7681C4.91086 11.4071 4.71165 11.0768 4.4499 10.7994C4.18814 10.522 3.86993 10.304 3.51674 10.1601L3.37274 10.1061L1.7994 9.56872C0.632069 9.17006 0.596736 7.55806 1.69274 7.08606L1.7994 7.04472L3.37274 6.50739C3.73356 6.38409 4.06374 6.18485 4.34102 5.9231C4.61829 5.66135 4.83621 5.34318 4.98007 4.99006L5.03407 4.84606L5.5714 3.27206ZM12.1667 0.973389C12.2915 0.973389 12.4137 1.00837 12.5195 1.07437C12.6253 1.14037 12.7105 1.23472 12.7654 1.34672L12.7974 1.42472L13.0307 2.10872L13.7154 2.34206C13.8404 2.38452 13.95 2.46313 14.0302 2.56793C14.1105 2.67273 14.1578 2.799 14.1663 2.93074C14.1747 3.06247 14.1438 3.19374 14.0775 3.30791C14.0113 3.42208 13.9126 3.51402 13.7941 3.57206L13.7154 3.60406L13.0314 3.83739L12.7981 4.52206C12.7555 4.64701 12.6769 4.75652 12.572 4.83672C12.4672 4.91691 12.3409 4.96418 12.2092 4.97254C12.0774 4.98089 11.9462 4.94995 11.8321 4.88364C11.7179 4.81733 11.6261 4.71863 11.5681 4.60006L11.5361 4.52206L11.3027 3.83806L10.6181 3.60472C10.4931 3.56226 10.3835 3.48365 10.3033 3.37885C10.223 3.27405 10.1756 3.14778 10.1672 3.01604C10.1588 2.88431 10.1897 2.75304 10.2559 2.63886C10.3222 2.52469 10.4208 2.43276 10.5394 2.37472L10.6181 2.34272L11.3021 2.10939L11.5354 1.42472C11.5804 1.29301 11.6654 1.17866 11.7786 1.09772C11.8918 1.01678 12.0276 0.973305 12.1667 0.973389Z"
        fill="url(#paint0_linear_5850_527)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5850_527"
          x1="0.0142994"
          y1="-3.41635"
          x2="13.6255"
          y2="-2.87893"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#47B5FF" />
          <stop offset="1" stopColor="#B482F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
