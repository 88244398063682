import React from 'react';
import { observer } from 'mobx-react';
import moment from '@this/src/lib/moment';

interface Props {
  message: any;
}

const OrderItemBilledAtLog = observer(({ message }: Props) => {
  return (
    <>
      ・{message.arranger_name}がtrace_id={message.trace_id}の請求対象日を「
      {moment(message.after_billed_at).format('YYYY年MM月DD日')}」に変更しました。(
      {moment(message.created_at).format('MM/DD HH:mm')})
    </>
  );
});

export default OrderItemBilledAtLog;
