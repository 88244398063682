import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

interface Props {
  onChange: (index: number) => void;
  value: number;
  labels: string[];
  name: string;
}
interface SegmentProps {
  label: string;
  name: string;
  position: 'left' | 'middle' | 'right';
  checked: boolean;
  onChange: () => void;
}
const defaultProps: Props = {
  name: 'segmented_control',
  labels: [],
  value: 0,
  onChange: _.noop
};

const Segment = ({ position, label, name, onChange, checked }: SegmentProps) => (
  <SegmentedLabel>
    <SegmentedInput type="radio" name={name} onChange={onChange} checked={checked} />
    <SegmentedLabelIn position={position} checked={checked}>
      {label}
    </SegmentedLabelIn>
  </SegmentedLabel>
);
const SegmentedControl = ({ labels, name, value, onChange }: Props = defaultProps) => {
  const handleChange = (index: number) => () => onChange(index);
  return (
    <Container>
      {labels.map((label, i) => (
        <Segment
          key={label}
          onChange={handleChange(i)}
          checked={value === i}
          label={label}
          name={name}
          position={i === 0 ? 'left' : i === labels.length - 1 ? 'right' : 'middle'}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
`;

const SegmentedLabel = styled.label`
  flex-grow: 2;
  cursor: pointer;
  text-align: center;
`;

const SegmentedInput = styled.input`
  &&& {
    display: none;
  }
`;

const SegmentedLabelIn = styled.div<{ position: string; checked: boolean }>`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  min-width: 120px;
  height: 40px;
  padding-top: 8px;
  border: 1px solid ${props => props.theme.linkColor};
  color: ${props => props.theme.linkColor};

  ${props =>
    props.checked &&
    `
      background-color: ${props.theme.linkColor};
      color: #fff;
    `}
`;

export default SegmentedControl;
