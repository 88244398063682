import ChangeFeeOriginalMapping from '@this/domain/change_fee_original_mapping';
import type { ChangeFeeOriginalMappingArgs } from '@this/domain/change_fee_original_mapping';
import type { Moment } from '@this/src/lib/moment';
import moment from '@this/src/lib/moment';
import type ElementBaseInterface from './element_base_interface';

// order_item.coffeeで、snakeToCamelObject(args)した値が渡される為、CamelCaseにしている
export interface ChangeFeeElementArgs {
  id?: number;

  orderItemId?: number;

  content?: string;

  canceledAt?: Moment;

  startAt?: Moment;

  endAt?: Moment;

  changeFeeOriginalMappings: ChangeFeeOriginalMappingArgs;
}

export default class ChangeFeeElement implements ElementBaseInterface {
  readonly id?: number;

  readonly type: string;

  orderItemId?: number;

  content: string;

  canceledAt: Moment;

  startAt: Moment;

  endAt: Moment;

  mappings: ChangeFeeOriginalMapping[] = [];

  constructor(args: ChangeFeeElementArgs) {
    this.id = args.id;
    this.type = 'change_fee';
    this.orderItemId = args.orderItemId;
    this.content = args.content || '';
    this.canceledAt = args.canceledAt ? moment(args.canceledAt) : moment();
    this.startAt = args.startAt ? moment(args.startAt).endOf('day') : moment();
    this.endAt = args.endAt ? moment(args.endAt).endOf('day') : moment();
    const arr = args.changeFeeOriginalMappings ? Object.values(args.changeFeeOriginalMappings) : [];
    this.mappings = arr.map(raw => new ChangeFeeOriginalMapping(raw));
  }

  title() {
    return '変更手数料';
  }

  startDate() {
    return moment();
  }

  endDate() {
    return null;
  }

  description() {
    return `<${this.title()}>
    ■内容
    ${this.content}
    ■変更日
    ${this.canceledAt?.format('YYYY/MM/DD')}
    `;
  }

  summary() {
    return this.content;
  }

  detail() {
    return '';
  }

  structuredDetail() {
    return [];
  }

  mappingDescription() {
    return this.title();
  }

  setField<T extends keyof this>(name: T, value: this[T]) {
    this[name] = value;
    app.render();
  }

  addMapping() {
    // eslint-disable-next-line
    // @ts-ignore
    this.mappings.push(new ChangeFeeOriginalMapping({}));
    app.render();
  }

  removeMapping(index: number) {
    if (index !== -1) this.mappings.splice(index, 1);
    app.render();
  }

  optionalAllowanceItems() {
    return this.mappings.filter(mapping => mapping.originalTraceId);
  }

  validationErrors(): string[] {
    const errors = [];
    if (!this.content) errors.push(`${this.title()}の内容を入力してください`);
    if (this.optionalAllowanceItems().length < 1) errors.push(`変更手数料対象を選択してください`);

    return errors;
  }

  updateParams() {
    return {
      type: this.type,
      order_item_id: this.orderItemId,
      content: this.content,
      start_at: this.startAt ? moment(this.startAt).format('YYYY-MM-DD') : null,
      end_at: this.endAt ? moment(this.endAt).format('YYYY-MM-DD') : null,
      canceled_at: this.canceledAt ? moment(this.canceledAt).format('YYYY-MM-DD') : null,
      change_fee_original_mappings_attributes: this.mappings.map(mapping => mapping.updateParams())
    };
  }
}
