import type { BulkActionAttr } from '@this/components/organization/types';

export const UPSERT_FORMAT: BulkActionAttr[] = [
  {
    name: 'code',
    summary: '経費科目コード',
    example: '',
    required: false
  },
  {
    name: 'name',
    summary: '経費科目名',
    example: '',
    required: true
  },
  {
    name: 'expenses_account_type_code',
    summary: '対応させる勘定科目',
    example: '勘定科目マスタで設定した勘定科目を入力してください',
    required: true
  },
  {
    name: 'category',
    summary: '入力フォーマット',
    example:
      '汎用: general_expenses, 旅費交通費: travel_expenses, 旅費交通費(日当): travel_expenses_daily_allowance, 接待交際費: reception_expenses',
    required: true
  },
  {
    name: 'is_deleted',
    summary: '状態',
    example: '無効にする: 1, 無効にしない: 0',
    required: true
  }
];
