import React from 'react';

import { styled } from '@this/constants/themes';
import { AiHotelIcon } from '@this/src/components/shared/icons/ai_hotel_icon';
import SimpleLoading from '@this/src/components/shared/simple_loading/simple_loading';
import type SearchQueryItem from '@this/src/domain/search_query_item';
import { HotelSelectedReasonFeedback } from './hotel_selected_reason_feedback';

interface Props {
  searchQueryItem: SearchQueryItem;
  reasonStatus: 'none' | 'loading' | 'completed';
  selectedReason: string | null;
  isFirst: boolean;
  selected: boolean;
}

const HotelSelectedReason: React.FC<Props> = ({
  searchQueryItem,
  reasonStatus,
  selectedReason,
  isFirst,
  selected
}) => {
  if (reasonStatus === 'none' || !isFirst) {
    return null;
  }

  if (reasonStatus === 'completed' && !selectedReason) {
    return null;
  }

  return (
    <Reason className="HotelSelectedReason" selected={selected}>
      <Header>
        <AiHotelIcon />
        <Label>AIによるおすすめ理由</Label>
      </Header>
      {reasonStatus === 'loading' ? (
        <SimpleLoading />
      ) : (
        <>
          <Text>{selectedReason}</Text>
          <HotelSelectedReasonFeedback searchQueryItem={searchQueryItem} />
        </>
      )}
    </Reason>
  );
};

const Reason = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 2px;
  gap: 4px;
  border: 1px solid;
  border-image-source: linear-gradient(92.26deg, #47b5ff -6.66%, #b482f5 91.17%);
  border-image-slice: 1;

  ${props =>
    props.selected &&
    `
    border-color: ${props.theme.accentBorderColor};
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: normal;
  gap: 4px;
`;

const Label = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${props => props.theme.blackTextColor};
`;

const Text = styled.div`
  font-size: 12px;
  color: ${props => props.theme.blackTextColor};
`;

export default HotelSelectedReason;
