import type React from 'react';
import type { Bar } from 'react-chartjs-2';

import type { ApprovalStatus } from '@this/components/organization/dashboard/share/index';
import type { ColorTheme } from './graph';
import { BORDER_STYLES, COLOR_THEMES } from './graph';

export interface BarApprovalStatusCounts {
  label: string;
  approvalStatusCounts: Record<ApprovalStatus, number>;
}

export const chartOptions: React.ComponentProps<typeof Bar>['options'] = {
  scales: {
    xAxes: {
      stacked: true
    },
    yAxes: {
      grid: { display: false },
      stacked: true
    }
  },
  maintainAspectRatio: false,
  responsive: false,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: any) => {
          return `${tooltipItem.dataset.label}: ${tooltipItem.raw}件`;
        }
      }
    }
  }
};

export const getBarHeight = (array: any[]) => {
  return 120 + 25 * array.length;
};

export const getBarDataApprovalStatusCounts = (
  approvalStatusCounts: BarApprovalStatusCounts[],
  theme: ColorTheme = 'default'
) => {
  const labels = approvalStatusCounts.map(({ label }) => label);
  const values = approvalStatusCounts.map(({ approvalStatusCounts }) => approvalStatusCounts);
  const colorTheme = COLOR_THEMES[theme];
  const borderStyle = BORDER_STYLES[theme];

  const datasets = [
    {
      label: '承認済み',
      data: values.map(value => value.approved),
      ...colorTheme.yellow,
      ...borderStyle
    },
    {
      label: '却下済み',
      data: values.map(value => value.rejected),
      ...colorTheme.blue,
      ...borderStyle
    },
    {
      label: '未承認',
      data: values.map(value => value.unapproved),
      ...colorTheme.red,
      ...borderStyle
    },
    {
      label: 'キャンセル済み',
      data: values.map(value => value.canceled),
      ...colorTheme.green,
      ...borderStyle
    }
  ];

  return { labels, datasets };
};
