import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import type Information from '../../../../domain/information';

interface Props {
  criticalInfos: Information[];
}

const CriticalInformationsTemplate: React.SFC<Props> = ({ criticalInfos }) => (
  <Wrapper>
    <Title>重要：</Title>
    <ul>
      {criticalInfos.map(information => (
        <li key={information.id}>{information.title}</li>
      ))}
    </ul>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  font-weight: bold;
`;

const Title = styled.p`
  white-space: nowrap;
`;

export default CriticalInformationsTemplate;
