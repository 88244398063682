import React from 'react';
import _ from 'lodash';

interface Item {
  label: string;
  value: string;
}

interface Props {
  items: Item[];
  headerLabel: string;
  name: string;
  currentValue: string;
  onChange: (key: string) => void;
}

interface State {
  show: boolean;
}

class PopupListSelector extends React.Component<Props, State> {
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false
    };
    this.ref = React.createRef();
  }

  getCurrentLabel(): string {
    const item = _.find(this.props.items, i => i.value === this.props.currentValue);
    if (item) {
      return item.label;
    }
    return '';
  }

  toggleShowSelector() {
    this.setState({ show: !this.state.show }, () =>
      this.state.show ? this.addListener() : this.removeListener()
    );
  }

  closeShowSelector() {
    this.setState({ show: false });
    this.removeListener();
  }

  handleChange(e: React.FormEvent<HTMLInputElement>) {
    this.props.onChange(e.currentTarget.value);
    this.closeShowSelector();
  }

  onClick = (e: MouseEvent) => {
    const currentNode = this.ref.current;

    if (currentNode && currentNode.contains(e.target as Node)) {
      return;
    }

    this.closeShowSelector();
  };

  isFirstRadioButtonSelected() {
    return this.props.items[0].value === this.props.currentValue;
  }

  private removeListener() {
    document.removeEventListener('click', this.onClick);
  }

  private addListener() {
    document.addEventListener('click', this.onClick);
  }

  render() {
    const classBase = 'popup-list-selector';
    const state = this.state;
    const buttonHiddenclassBase = `popup-list-selector${this.isFirstRadioButtonSelected() ? '' : '__select'}`;
    return (
      <div className={classBase} ref={this.ref}>
        {state.show ? (
          <>
            <div className={`${classBase}__button-show`} onClick={() => this.toggleShowSelector()}>
              {this.getCurrentLabel()}
            </div>
            <div className={`${classBase}__popup`}>
              <div className={`${classBase}__popup__header`}>
                {this.props.headerLabel}
                <div
                  className={`${classBase}__popup__header__close-button`}
                  onClick={() => this.closeShowSelector()}
                >
                  ☓
                </div>
              </div>
              <div className={`${classBase}__popup__body`}>
                <ul>
                  {this.props.items.map(item => (
                    <li key={item.value}>
                      <label className={`${classBase}__popup__body__label`}>
                        <input
                          type="radio"
                          name={this.props.name}
                          value={item.value}
                          onChange={e => this.handleChange(e)}
                          checked={this.props.currentValue === item.value}
                        />
                        {item.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className={`${buttonHiddenclassBase}__button-hidden`} onClick={() => this.toggleShowSelector()}>
            {this.getCurrentLabel()}
          </div>
        )}
      </div>
    );
  }
}

export default PopupListSelector;
