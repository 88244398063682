import React from 'react';
import { styled } from '@this/constants/themes';
import { Link } from '@this/shared/ui/navigations/link';
import { media } from '@this/shared/atoms/media';

interface Props {
  title?: string;
  backButton?: boolean;
  backLink?: string | null;
  onBack?: () => void;
  closeButton?: boolean;
  onClose?: () => void;
  history?: any;
  customTitlePosition?: boolean;
  customWrapPadding?: string;
  customFlexBasis?: string;
}

class ContentHeader extends React.Component<Props> {
  handleBackButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (this.props.onBack) {
      this.props.onBack();
    } else if (this.props.backLink) {
      if (this.props.history) {
        this.props.history.push(this.props.backLink);
      } else {
        location.href = this.props.backLink;
      }
    } else {
      history.back();
    }
  }

  handleCloseButtonClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (!this.props.onClose) {
      return;
    }

    this.props.onClose();
  }

  render() {
    try {
      const { title, backButton, backLink, closeButton, customTitlePosition, customWrapPadding, customFlexBasis } =
        this.props;

      return (
        <Wrap customWrapPadding={customWrapPadding}>
          <Left customFlexBasis={customFlexBasis}>
            {customTitlePosition && <TitleCustom>{title}</TitleCustom>}
            {backButton && (
              <Link
                size="small"
                href={backLink || '#'}
                onClick={(e: React.MouseEvent<HTMLElement>) => this.handleBackButtonClick(e)}
              >
                &lt;戻る
              </Link>
            )}
          </Left>

          {!customTitlePosition && <Title>{title}</Title>}

          <Right>
            {closeButton && (
              <Link size="small" onClick={(e: React.MouseEvent<HTMLElement>) => this.handleCloseButtonClick(e)}>
                ×
              </Link>
            )}
          </Right>
        </Wrap>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const Wrap = styled.div<{ customWrapPadding: string | undefined }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${props => (props.customWrapPadding ? props.customWrapPadding : `20px`)};
  border-bottom: 1px solid ${props => props.theme.grayBorderColor};
  height: 40px;
`;

const Left = styled.div<{ customFlexBasis: string | undefined }>`
  flex-basis: ${props => (props.customFlexBasis ? props.customFlexBasis : `50px`)};
  font-size: 12px;
`;

const Right = styled.div`
  flex-basis: 50px;
  font-size: 22px;
  margin-top: -3px;
  text-align: right;
`;

const Title = styled.h2`
  margin: 0;
`;

const TitleCustom = styled.h2`
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: 700;

  ${media.sp`
    margin: 0 6px 4px;
    font-size: 18px;
  `}
`;

export default ContentHeader;
