import React, { useEffect, useMemo } from 'react';
import { styled } from '@this/src/components/constants/themes';
import Button from '@this/shared/atoms/button';
import TextArea from 'react-textarea-autosize';
import type CancelChargeElement from '../../../../../../domain/cancel_charge_element';
import DatetimePicker from '../../../../../shared/datetime_picker/datetime_picker';

interface Props {
  element: CancelChargeElement;
  traceId: number | null;
  traceItems: Record<number, string>;
  classNameForModifiedField: (...path: (string | number)[]) => string;
}

const CancelElementForm = ({ element, traceId, traceItems, classNameForModifiedField }: Props) => {
  const mappingOptions = useMemo(
    () =>
      Object.entries(traceItems)
        .filter(([id]) => id !== traceId?.toString())
        .map(([id, label]) => ({ traceId: parseInt(id, 10), label })),
    [traceItems]
  );

  useEffect(() => {
    if (element.mappings.length < 1) element.addMapping();
  }, [element]);

  return (
    <>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('content')}`}>
          内容
          <span className="red">（必須）</span>
        </label>
        <TextArea
          type="text"
          value={element.content}
          onChange={e => element.handleChangeContent(e.target.value)}
          minRows={1}
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('canceledAt')}`}>
          キャンセル日
        </label>
        <DatetimePicker
          value={element.canceledAt}
          onChange={time => element.handleCanceledAtChange(time)}
          showTime={false}
          disabledDays={0}
          showPast
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('startAt')}`}>
          元の商品の利用開始日
        </label>
        <DatetimePicker
          value={element.startAt}
          onChange={time => element.handleStartAtChange(time)}
          showTime={false}
          disabledDays={0}
          showPast
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper-item">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('endAt')}`}>
          元の商品の利用終了日
        </label>
        <DatetimePicker
          value={element.endAt}
          onChange={time => element.handleEndAtChange(time)}
          showTime={false}
          disabledDays={0}
          showPast
        />
      </div>
      <div className="virtual-counte-price-change-form__input-wrapper">
        <label className={`virtual-counte-price-change-form__label ${classNameForModifiedField('mappings')}`}>
          キャンセル商品
        </label>
      </div>
      {element.mappings.map((mapping, i) => (
        <div key={`mapping-${i}`} className="virtual-counte-price-change-form__input-wrapper-item">
          <Select
            value={mapping.originalTraceId?.toString() || ''}
            onChange={e => mapping.setOriginalTraceId(parseInt(e.target.value, 10) || null)}
          >
            <option key="">選択してください</option>
            {mappingOptions.map(({ traceId, label }) => (
              <option key={traceId} value={traceId.toString()}>
                trace_id-{traceId}: {label}
              </option>
            ))}
          </Select>
          {i > 0 && <DeleteButton onClick={() => element.removeMapping(i)}>×</DeleteButton>}
        </div>
      ))}
      {element.mappings.length < mappingOptions.length && (
        <div className="virtual-counte-price-change-form__input-wrapper-item">
          <button type="button" onClick={() => element.addMapping()}>
            キャンセル商品を追加する
          </button>
        </div>
      )}
    </>
  );
};

const Select = styled.select`
  width: calc(100% - 40px);
`;

const DeleteButton = styled(Button)`
  padding: 0px;
  margin-left: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
`;

export default CancelElementForm;
