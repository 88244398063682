import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import Pagination from '@this/src/components/shared/pagination/pagination';
import { Loading } from '@this/components/shared/ui/feedbacks/loading/loading';
import { Fetcher } from '@this/src/util';

const Wrapper = styled.div`
  padding: 0 120px;
`;

const PaginationWrapper = styled.div`
  padding: 40px;
`;

const Table = styled.table`
  &&& {
    margin-bottom: 40px;
    border-collapse: collapse;
  }
`;

const TableRow = styled.tr<{ type: string }>`
  background-color: ${props => (props.type === 'master' ? '#e6f7ff' : 'white')};
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  text-align: center;
  width: auto;
  &&& {
    padding: 0;
  }
`;
const FixedWidthCell = styled(TableCell)<{ width: number }>`
  width: ${props => props.width}px;
`;

const StyledImage = styled.img`
  &&& {
    height: 100px;
    width: 160px;
    object-fit: cover;
  }
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap;
  background-color: #f0f0f0;
  text-align: center;
`;

const FixedWidthHeader = styled(TableHeader)<{ width: number }>`
  width: ${props => props.width}px;
`;

type Hotel = {
  id: number;
  name: string;
  hotel_image_url: string;
  latitude: string;
  longitude: string;
};

type HotelGroup = {
  master_hotel: Hotel;
  merged_hotels: Hotel[];
};
const useHotelOperations = (currentPage: number, setTotalPage: any, setHotels: any, setLoading: any) => {
  const fetchHotels = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Fetcher.get<{ hotels: HotelGroup[]; page: number; total_pages: number }>(
        '/god/hotel_master_manage/merged_hotel_masters.json',
        { page: currentPage }
      );
      setHotels(response.hotels);
      setTotalPage(response.total_pages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  }, [currentPage, setLoading, setHotels, setTotalPage]);

  const unmergeHotel = async (hotelId: number) => {
    const confirmResult = window.confirm(`hotel_master_id=${hotelId}をマージ解除しますか？`);
    if (!confirmResult) {
      return;
    }
    try {
      await Fetcher.post(`/god/hotel_master_manage/unmerge/${hotelId}`, {});
      fetchHotels();
    } catch (error) {
      console.error('Error unmerging hotel: ', error);
    }
  };

  return { fetchHotels, unmergeHotel };
};

const HotelMaserManage = () => {
  const [hotels, setHotels] = useState<HotelGroup[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1); // 総ページ数
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchHotels, unmergeHotel } = useHotelOperations(currentPage, setTotalPage, setHotels, setLoading);

  useEffect(() => {
    fetchHotels();
  }, [currentPage, fetchHotels]);

  function getProvider(mergedHotel: any) {
    if (mergedHotel.rakuten_id) {
      return '楽天';
    }
    if (mergedHotel.expedia_id) {
      return 'Expedia';
    }
    if (mergedHotel.agoda_id) {
      return 'Agoda';
    }
    return null;
  }

  function providerNames(mergedHotel: any) {
    const providers = [];

    if (mergedHotel.rakuten_id) {
      providers.push('楽天');
    }
    if (mergedHotel.expedia_id) {
      providers.push('Expedia');
    }
    if (mergedHotel.agoda_id) {
      providers.push('Agoda');
    }

    return providers.join(', ');
  }

  function getFirstImageUrl(hotel: any) {
    if (hotel.hotel_image_url) {
      const imageUrls = hotel.hotel_image_url.split('\t');
      return imageUrls[0];
    }
    return hotel.hotel_image_url;
  }

  return (
    <div>
      <PaginationWrapper>
        <Pagination currentPage={currentPage} totalPage={totalPage} handleSearch={page => setCurrentPage(page)} />
      </PaginationWrapper>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          {hotels.map((hotelGroup, index) => (
            <Table key={index}>
              <thead>
                <tr>
                  <FixedWidthHeader width={80}>id</FixedWidthHeader>
                  <FixedWidthHeader width={80}>種別</FixedWidthHeader>
                  <FixedWidthHeader width={160}>プロバイダ</FixedWidthHeader>
                  <TableHeader>ホテル名</TableHeader>
                  <FixedWidthHeader width={160}>ホテル画像</FixedWidthHeader>
                  <FixedWidthHeader width={180}>緯度経度</FixedWidthHeader>
                  <FixedWidthHeader width={120} />
                </tr>
              </thead>
              <tbody>
                <TableRow type="master">
                  <FixedWidthCell width={80}>{hotelGroup.master_hotel.id}</FixedWidthCell>
                  <FixedWidthCell width={80}>master</FixedWidthCell>
                  <FixedWidthCell width={160}>{providerNames(hotelGroup.master_hotel)}</FixedWidthCell>
                  <TableCell>{hotelGroup.master_hotel.name}</TableCell>
                  <FixedWidthCell width={160}>
                    <StyledImage
                      src={hotelGroup.master_hotel.hotel_image_url}
                      alt={hotelGroup.master_hotel.name}
                    />
                  </FixedWidthCell>
                  <FixedWidthCell width={180}>
                    {hotelGroup.master_hotel.latitude},{hotelGroup.master_hotel.longitude}
                  </FixedWidthCell>
                  <FixedWidthCell width={120}>-</FixedWidthCell>
                </TableRow>
                {hotelGroup.merged_hotels.map((mergedHotel, index) => (
                  <TableRow key={index} type="merged">
                    <FixedWidthCell width={80}>{mergedHotel.id}</FixedWidthCell>
                    <FixedWidthCell width={80}>merged</FixedWidthCell>
                    <FixedWidthCell width={160}>{getProvider(mergedHotel)}</FixedWidthCell>
                    <TableCell>{mergedHotel.name}</TableCell>
                    <FixedWidthCell width={160}>
                      <StyledImage src={getFirstImageUrl(mergedHotel)} alt={mergedHotel.name} />
                    </FixedWidthCell>
                    <FixedWidthCell width={180}>
                      {mergedHotel.latitude},{mergedHotel.longitude}
                    </FixedWidthCell>
                    <FixedWidthCell width={120}>
                      <button type="button" onClick={() => unmergeHotel(mergedHotel.id)}>
                        マージ解除
                      </button>
                    </FixedWidthCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ))}
        </Wrapper>
      )}
      <PaginationWrapper>
        <Pagination currentPage={currentPage} totalPage={totalPage} handleSearch={page => setCurrentPage(page)} />
      </PaginationWrapper>
    </div>
  );
};

export default HotelMaserManage;
