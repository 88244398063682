import React from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';

import type { LegJson } from '../../../../domain/select_repository';
import SegmentClass from '../../../../domain/transit/segment';

type Props = {
  segment: SegmentClass;
  leg: LegJson;
  i: number;
  j: number;
};

const ElementTransportBox: React.SFC<Props> = ({ segment, leg, i, j }) => (
  <Segment key={`${i}_${j}`} className="select-transport-box__button_exist_leg_shortdistance">
    <Summary>
      {segment.shouldShowBoardingStation() && j === 0 && (
        <BoxContentThin>
          <LegTime />
          <div>乗車駅: {segment.boardingName()}</div>
        </BoxContentThin>
      )}
      <BoxContent>
        <LegTime>{leg.from.time}</LegTime>
        <BoxTitle>出発</BoxTitle>
        <Space className="select-transport-box__button_exist_leg_station">{leg.from.name}</Space>
      </BoxContent>
      <BoxContent>
        <LegTime />
        <ImageBox>
          {(leg.type === 'shinkansen' || leg.type === 'express') && <Image src="/images/train.png" />}
        </ImageBox>
        <ImageBox>
          <Image src={SegmentClass.imagePathByName(leg.name)} />
        </ImageBox>
        <SpaceName>
          {leg.airline_name && `${leg.airline_name} `}
          {leg.name}
        </SpaceName>
      </BoxContent>
      <BoxContent>
        <LegTime>{leg.to.time}</LegTime>
        <BoxTitle>到着</BoxTitle>
        <Space className="select-transport-box__button_exist_leg_station">{leg.to.name}</Space>
      </BoxContent>
      {segment.shouldShowArrivalStation() && j === segment.legs.length - 1 && (
        <BoxContentThin>
          <LegTime />
          <div>降車駅: {segment.arrivalName()}</div>
        </BoxContentThin>
      )}
    </Summary>
  </Segment>
);

export const BoxContent = styled.div`
  display: flex;
  padding: 5px;
  color: #999999;
`;

export const BoxContentThin = styled.div`
  display: flex;
  padding: 2px;
  color: #999999;
  font-size: 0.7em;
`;

export const Segment = styled.div`
  padding: 5px 0;
  margin: 0;
  border: 1px solid #eeeeee;
  display: flex;
`;

export const Summary = styled.div`
  padding: 0;
  margin: 0;
  display: block;
`;

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const ImageBox = styled.div`
  display: flex;
  min-width: 20px;
  margin-right: 5px;
`;

export const Image = styled.img`
  height: 15px;
  margin-left: 5px;
`;

export const BoxTitle = styled.div`
  background: #aaa;
  color: white;
  width: 25px;
  margin-right: 10px;
  height: 18px;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  vertical-align: middle;
`;

export const BoxTitleBlank = styled.div`
  width: 25px;
  margin-right: 10px;
  height: 18px;
`;

export const BoxSpace = styled.div`
  width: 25px;
  margin-right: 10px;
`;

export const LegTime = styled.div`
  width: 50px;
  color: #1e90ff;
  font-size: 13px;
  font-weight: bold;
  height: 16px;
  vertical-align: middle;
`;

export const Space = styled.div`
  margin-right: 10px;
  font-size: 0.9em;
`;

export const SpaceName = styled.div`
  margin-right: 10px;
  font-size: 0.8em;
`;

export default ElementTransportBox;
