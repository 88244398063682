import React from 'react';
import { observer } from 'mobx-react';
import type ReserveInfo from '@this/domain/reserve_info';

type Props = {
  reserveInfo: ReserveInfo;
};

const WelfareSection: React.FC<Props> = ({ reserveInfo }) => (
  <label>
    <input
      type="checkbox"
      checked={reserveInfo.privateUse}
      onChange={() => {
        reserveInfo.privateUse = !reserveInfo.privateUse;
      }}
    />
    プライベート利用
  </label>
);

export default observer(WelfareSection);
