export interface TripRuleErrorArgs {
  code: Code;
  detail: Detail;
}

export type Code = 'HOTEL_PRICE_EXCEEDED' | 'DISTANCE_TOO_SHORT' | 'TIME_TOO_SHORT';

export type Detail = {
  actual: number;
  limit: number;
  lines?: string[];
};

class TripRuleError {
  code: Code;

  detail: Detail;

  constructor(args: TripRuleErrorArgs) {
    this.code = args.code || '';
    this.detail = args.detail || { actual: 0, limit: 0 };
  }

  ruleErrorText = () => {
    const round = (num: number) => Math.round(num * 10) / 10;

    switch (this.code) {
      case 'TIME_TOO_SHORT':
        return `宿泊不可の移動時間(${round(this.detail.actual / 60)}時間, 下限:${round(
          this.detail.limit / 60
        )}時間)`;
      case 'DISTANCE_TOO_SHORT':
        return `宿泊不可の移動距離(${round(this.detail.actual / 1000)}km, 下限:${round(
          this.detail.limit / 1000
        )}km)`;
      case 'HOTEL_PRICE_EXCEEDED':
        return `宿泊料金超過(${this.detail.actual.toLocaleString()}円, 上限:${this.detail.limit.toLocaleString()}円)`;
      default:
        return '';
    }
  };
}

export default TripRuleError;
