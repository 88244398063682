import React from 'react';
import styled from 'styled-components';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import type ProjectList from '@this/domain/project/project_list';
import type DepartmentList from '@this/domain/department/department_list';
import type Organization from '@this/domain/organization/organization2';
import type { DateRangePickerValue } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { DateRangePicker } from '@this/shared/ui/inputs/datepicker/date_range_picker';
import { Flex } from '@this/shared/ui/layout/flex';
import SCButton from '../../button';

export type SearchType = 'status' | 'id' | 'exic_seisan';

export interface FilterParams {
  fromDate: Moment;
  toDate: Moment;
  projectId: string;
  userName: string;
  travelerName: string;
  departmentId: string;
  dateType: string;
  inAdvanced: boolean;
  arranged: boolean;
  fixed: boolean;
  canceled: boolean;
  foreign: string;
  tripReport: string;
  orderType: string;
  searchType: SearchType;
  tripId: string;
}

interface Props {
  departments: DepartmentList;
  projects: ProjectList;
  filterParams: FilterParams;
  search: (filterParams: FilterParams) => void;
  serviceId: number;
  organization?: Organization;
  tripReportAvailable: boolean;
  onChangeSearchType: (type: SearchType) => void;
}

interface State {
  fromDate: Moment;
  toDate: Moment;
  projectId: string;
  userName: string;
  travelerName: string;
  departmentId: string;
  dateType: string;
  inAdvanced: boolean;
  arranged: boolean;
  fixed: boolean;
  canceled: boolean;
  foreign: string;
  tripReport: string;
  orderType: string;
  searchType: SearchType;
  tripId: string;
}

class Filter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      fromDate,
      toDate,
      projectId,
      userName,
      travelerName,
      departmentId,
      dateType,
      inAdvanced,
      arranged,
      fixed,
      canceled,
      foreign,
      tripReport,
      orderType,
      searchType,
      tripId
    } = this.props.filterParams;
    this.state = {
      fromDate,
      toDate,
      projectId,
      userName,
      travelerName,
      departmentId,
      dateType,
      inAdvanced,
      arranged,
      fixed,
      canceled,
      foreign,
      tripReport,
      orderType,
      searchType,
      tripId
    };

    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleTravelerNameChange = this.handleTravelerNameChange.bind(this);
    this.handleDepartmentIdChange = this.handleDepartmentIdChange.bind(this);
    this.handleProjectIdChange = this.handleProjectIdChange.bind(this);
    this.handleDateTypeChange = this.handleDateTypeChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleInAdvancedChange = this.handleInAdvancedChange.bind(this);
    this.handleArrangedChange = this.handleArrangedChange.bind(this);
    this.handleFixedChange = this.handleFixedChange.bind(this);
    this.handleCanceledChange = this.handleCanceledChange.bind(this);
    this.handleForeignChange = this.handleForeignChange.bind(this);
    this.handleTripReportChange = this.handleTripReportChange.bind(this);
    this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
    this.handleSearchTypeChange = this.handleSearchTypeChange.bind(this);
    this.handleTripIdChange = this.handleTripIdChange.bind(this);
  }

  handleUserNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      userName: e.currentTarget.value
    });
  }

  handleTravelerNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      travelerName: e.currentTarget.value
    });
  }

  handleDepartmentIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      departmentId: e.currentTarget.value
    });
  }

  handleProjectIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      projectId: e.currentTarget.value
    });
  }

  handleDateTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      dateType: e.currentTarget.value
    });
  }

  handleDateRangeChange = (value: DateRangePickerValue | null) => {
    if (!value) {
      return;
    }
    this.setState({
      fromDate: moment(value[0]),
      toDate: moment(value[1])
    });
  };

  handleInAdvancedChange(checked: boolean) {
    this.setState({
      inAdvanced: checked
    });
  }

  handleArrangedChange(checked: boolean) {
    this.setState({
      arranged: checked
    });
  }

  handleFixedChange(checked: boolean) {
    this.setState({
      fixed: checked
    });
  }

  handleCanceledChange(checked: boolean) {
    this.setState({
      canceled: checked
    });
  }

  handleForeignChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      foreign: e.currentTarget.value
    });
  }

  handleTripReportChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      tripReport: e.currentTarget.value
    });
  }

  handleOrderTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      orderType: e.currentTarget.value
    });
  }

  handleSearchTypeChange(type: SearchType) {
    this.setState(
      {
        searchType: type
      },
      () => {
        this.props.onChangeSearchType(type);
      }
    );
  }

  handleTripIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      tripId: e.currentTarget.value
    });
  }

  handleSearchClick() {
    this.props.search(this.state);
  }

  render() {
    try {
      const { serviceId, organization, projects, departments, tripReportAvailable } = this.props;
      const {
        fromDate,
        toDate,
        projectId,
        userName,
        travelerName,
        departmentId,
        dateType,
        inAdvanced,
        arranged,
        fixed,
        canceled,
        foreign,
        tripReport,
        orderType,
        searchType,
        tripId
      } = this.state;
      return (
        <SearchForm>
          <Flex>
            <label>
              <input
                type="radio"
                value="status"
                checked={searchType === 'status'}
                onChange={() => this.handleSearchTypeChange('status')}
              />
              <span>条件検索</span>
            </label>
            <label>
              <input
                type="radio"
                value="id"
                checked={searchType === 'id'}
                onChange={() => this.handleSearchTypeChange('id')}
              />
              <span>旅程番号検索</span>
            </label>
            {organization && organization.showExicSeisanTrips && (
              <label>
                <input
                  type="radio"
                  value="exic_seisan"
                  checked={searchType === 'exic_seisan'}
                  onChange={() => this.handleSearchTypeChange('exic_seisan')}
                />
                <span>EXIC利用時の簡易申請旅程</span>
              </label>
            )}
          </Flex>
          {searchType === 'status' ? (
            <>
              <Flex>
                <SearchItem>
                  <Label>手配者</Label>
                  <Input type="text" placeholder="手配者" value={userName} onChange={this.handleUserNameChange} />
                </SearchItem>
                <SearchItem>
                  <Label>出張者</Label>
                  <Input
                    type="text"
                    placeholder="出張者"
                    value={travelerName}
                    onChange={this.handleTravelerNameChange}
                  />
                </SearchItem>
                <SearchItem>
                  <Label>部署</Label>
                  <Select value={departmentId} onChange={this.handleDepartmentIdChange}>
                    <option key="" value="">
                      全て
                    </option>
                    {departments.list.map(d => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </Select>
                </SearchItem>
                <SearchItem>
                  <Label>プロジェクト</Label>
                  <Select value={projectId} onChange={this.handleProjectIdChange}>
                    <option key="" value="">
                      全て
                    </option>
                    {projects.list.map(
                      p =>
                        p.code &&
                        p.name && (
                          <option key={p.id} value={p.id}>
                            {`${p.code}-${p.name}`}
                          </option>
                        )
                    )}
                  </Select>
                </SearchItem>
              </Flex>
              <Flex alignItems="center">
                <DateArea>
                  <Select value={dateType} onChange={this.handleDateTypeChange}>
                    <option key="travel" value="travel">
                      出張日
                    </option>
                    <option key="start" value="start">
                      出発日
                    </option>
                    <option key="end" value="end">
                      帰着日
                    </option>
                    <option key="trip" value="trip">
                      手配依頼日
                    </option>
                  </Select>
                  <DateRangePicker
                    value={[fromDate.toDate(), toDate.toDate()]}
                    onChange={this.handleDateRangeChange}
                    disabledClear
                  />
                  {dateType === 'trip' && (
                    <Comment>※弊社のシステムに依頼いただいた日付を基準としています。</Comment>
                  )}
                </DateArea>
                <SearchItem>
                  <Label>
                    <input
                      type="checkbox"
                      checked={inAdvanced}
                      onChange={() => this.handleInAdvancedChange(!inAdvanced)}
                    />
                    承認待ち
                  </Label>
                  <Label>
                    <input
                      type="checkbox"
                      checked={arranged}
                      onChange={() => this.handleArrangedChange(!arranged)}
                    />
                    手配中
                  </Label>
                  <Label>
                    <input type="checkbox" checked={fixed} onChange={() => this.handleFixedChange(!fixed)} />
                    手配済み
                  </Label>
                  <Label>
                    <input
                      type="checkbox"
                      checked={canceled}
                      onChange={() => this.handleCanceledChange(!canceled)}
                    />
                    キャンセル
                  </Label>
                </SearchItem>
              </Flex>
              <Flex>
                <SearchItem>
                  <Label>出張種別</Label>
                  <Select value={foreign} onChange={this.handleForeignChange}>
                    <option key="" value="">
                      全て
                    </option>
                    <option key="false" value="false">
                      国内のみ
                    </option>
                    <option key="true" value="true">
                      海外のみ
                    </option>
                  </Select>
                </SearchItem>
                {tripReportAvailable && (
                  <SearchItem>
                    <Label>出張報告</Label>
                    <Select value={tripReport} onChange={this.handleTripReportChange}>
                      <option key="" value="">
                        全て
                      </option>
                      <option key="false" value="false">
                        出張報告前
                      </option>
                      <option key="true" value="true">
                        出張報告済み
                      </option>
                    </Select>
                  </SearchItem>
                )}
                <SearchItem>
                  <Label>手配種別</Label>
                  <Select value={orderType} onChange={this.handleOrderTypeChange}>
                    <option key="" value="">
                      全て
                    </option>
                    <option key="true" value="true">
                      システム手配
                    </option>
                    <option key="false" value="false">
                      自己手配
                    </option>
                  </Select>
                </SearchItem>
                <ReloadButtonArea>
                  <SCButton
                    serviceId={serviceId}
                    tag="input"
                    type="submit"
                    value="検索"
                    width="80px"
                    onClick={this.handleSearchClick}
                  />
                </ReloadButtonArea>
              </Flex>
            </>
          ) : searchType === 'id' ? (
            <SearchIdBox>
              <Input type="text" placeholder="旅程番号" value={tripId} onChange={this.handleTripIdChange} />
              <SCButton
                serviceId={serviceId}
                tag="input"
                type="submit"
                value="検索"
                width="80px"
                onClick={this.handleSearchClick}
              />
            </SearchIdBox>
          ) : null}
        </SearchForm>
      );
    } catch (e) {
      utils.sendErrorObject(e);
      return null;
    }
  }
}

const SearchForm = styled.div`
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: #f7f7f7;
  padding: 10px;
  font-weight: bold;
`;

const SearchItem = styled.div`
  display: flex;
`;

const SearchIdBox = styled.div`
  display: flex;
  width: 220px;
`;

const Label = styled.p`
  line-height: 25px;
  margin-right: 5px;
  white-space: nowrap;
`;

const Input = styled.input`
  height: 25px;
  margin-right: 15px;
`;

const Select = styled.select`
  height: 25px;
  margin-right: 15px;
  margin-bottom: 0.75em;
`;

const Comment = styled.p`
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  font-weight: normal;
  margin-left: 15px;
`;

const DateArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  select {
    margin: 0;
  }
`;

const ReloadButtonArea = styled.div`
  margin-left: auto;
`;

export default Filter;
