import type User from '@this/domain/user/user';
import Department from '@this/domain/department/department';

type DepartmentData = any;

export default class DepartmentList {
  list: Department[];

  members?: User[];

  constructor(rawDeps: DepartmentData[], members?: User[]) {
    this.list = rawDeps.map(d => new Department(d, members));
    if (!this.list) {
      this.list = [];
    }
    this.members = members;
  }

  find(id: number | string | null) {
    return this.list.find(d => d.id === parseInt((id ?? '').toString(), 10));
  }

  last() {
    return this.list[this.list.length - 1];
  }

  push(d: Department) {
    return this.list.push(d);
  }

  remove(d: Department) {
    const i = this.list.indexOf(d);
    if (i > -1) {
      this.list.splice(i, 1);
    }
  }

  update(newDep: DepartmentData) {
    const d = this.find(newDep.id);
    if (d) {
      Object.assign(d, newDep);
    }
  }
}
