import React, { useCallback, useMemo, useState } from 'react';

import { styled } from '@this/constants/themes';
import A from '@this/src/components/shared/atoms/a';
import AutoCompletableInput from '@this/src/components/shared/autocompletable_input/autocompletable_input';
import { AddressArea, Field, FieldsWrapper, InLabel, InputArea } from '@this/src/components/shared/search/search';
import type ChatbotMessage from '@this/src/domain/chatbot/chatbot_message';
import type { OriginField } from '@this/src/domain/chatbot/chatbot_message';
import type User from '@this/src/domain/user/user';

interface Props extends OriginFieldOptions {
  message: ChatbotMessage;
}

export interface OriginFieldOptions {
  availableOptions?: string[];
  user?: User | null;
}

interface State {
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  loading: false,
  error: null
};

export const ChatMessageFormOrigin: React.FC<Props> = ({ message, availableOptions, user }) => {
  const [state, setState] = useState(initialState);
  const formField = message.formFields.find(field => field.type === 'origin') as OriginField;
  const origin = formField?.value as string;
  const originAddress = formField?.address as string;
  const restrictionDistanceAndTimeAvailability = useMemo(
    () => availableOptions?.includes('restriction_distance_and_time') ?? false,
    [availableOptions]
  );

  const handlePlaceChange = useCallback(
    (_name: unknown, value: string, address: string) => {
      message.setFormFieldOrigin(value, address);
    },
    [message]
  );

  const getCurrentPlace = useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      evt.preventDefault();
      if (navigator.geolocation) {
        setState(state => ({ ...state, loading: true }));
        navigator.geolocation.getCurrentPosition(
          position => {
            utils
              .fetchCityName(position.coords.latitude, position.coords.longitude)
              .then(
                result => {
                  if (result.status === 'OK') {
                    message.setFormFieldOrigin(
                      result.results[0].formatted_address!.replace(/^日本、〒\d{3}-\d{4} /g, '')
                    );
                    return setState(state => ({ ...state, loading: false }));
                  }
                  return setState({ loading: false, error: '位置情報の取得に失敗しました。' });
                },
                _err => setState({ loading: false, error: '位置情報の取得に失敗しました。' })
              )
              .catch(err => utils.sendErrorObject(err));
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setState({ loading: false, error: '位置情報の取得が許可されていません。' });
                break;
              case error.POSITION_UNAVAILABLE:
                setState({ loading: false, error: '位置情報が利用できません。' });
                break;
              case error.TIMEOUT:
                setState({ loading: false, error: '位置情報の取得にタイムアウトしました。' });
                break;
              default:
                setState({ loading: false, error: '位置情報の取得に失敗しました。' });
            }
            return utils.sendErrorObject(error);
          },
          {
            enableHighAccuracy: true,
            timeout: 60000,
            maximumAge: 3000
          }
        );
      } else {
        setState(state => ({ ...state, error: '位置情報が利用できません。' }));
      }
    },
    [setState, message]
  );

  return (
    <FieldsWrapper className="search__block__body__fields-wrapper">
      <Field>
        <InLabel htmlFor="origin">出発地</InLabel>
        <FixedInputArea wrapped>
          <AutoCompletableInput onChange={handlePlaceChange} value={origin || ''} id="origin" />
          <div className="search__input-current-place">
            {state.loading ? (
              <div className="search-loader-line-mask">
                <SearchLoaderLine />
              </div>
            ) : (
              <CurrentPlace title="現在地を取得" onClick={getCurrentPlace}>
                現在地
              </CurrentPlace>
            )}
          </div>
        </FixedInputArea>
        <AddressArea>
          <p>{originAddress}</p>
          {restrictionDistanceAndTimeAvailability && user?.hasOrganizationBase() && (
            <p>{`拠点情報: ${user?.organizationBase.address}`}</p>
          )}
        </AddressArea>
      </Field>
    </FieldsWrapper>
  );
};

const SearchLoaderLine = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: inset 0 1px 1px 3px ${props => props.theme.linkColor};
`;

const CurrentPlace = styled(A)`
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
`;

const FixedInputArea = styled(InputArea)`
  position: relative;
  & .search__suggest-container {
    left: -16px;
    max-width: 278px;
  }
`;
