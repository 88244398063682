import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@this/constants/themes';
import type { SimulateBaseFile } from '@this/src/domain/simulate_base_file';
import { SimulateBaseSummary } from '@this/src/domain/simulate_base_summary';
import type { SimulateBaseSummaryArgs, Summary } from '@this/src/domain/simulate_base_summary';
import Link from '@this/components/shared/atoms/link';
import { Fetcher } from '@this/src/util';
import SimpleLoading from '../../shared/simple_loading/simple_loading';

interface Props {
  simulateBaseFile: SimulateBaseFile | null;
  searchParams: URLSearchParams;
}

interface SimulateBaseSummaryResponse {
  simulate_base_summary: SimulateBaseSummaryArgs;
}

const SimulationSummary: React.FC<Props> = ({ simulateBaseFile, searchParams }) => {
  const { id, status } = useMemo(
    () => ({
      id: simulateBaseFile?.id,
      status: simulateBaseFile?.status
    }),
    [simulateBaseFile]
  );

  const { search, isSame, isPastSame } = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString());
    if (params.get('page')) params.delete('page');

    return {
      search: params.toString(),
      isSame: searchParams.get('same_item') === 'true',
      isPastSame: searchParams.get('past_same_item') === 'true'
    };
  }, [searchParams]);

  const [simulateBaseSummary, setSimulateBaseSummary] = useState<SimulateBaseSummary | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSimulateBaseSummary = useCallback(
    async (search: string) => {
      setLoading(true);
      setError(null);

      await Fetcher.get<SimulateBaseSummaryResponse>(`/god/simulate_base_files/${id}/summary.json?${search}`)
        .then(({ simulate_base_summary }) => {
          setSimulateBaseSummary(new SimulateBaseSummary(simulate_base_summary));
        })
        .catch(() =>
          setError('サマリーの取得に失敗しました。\n通信環境が不安定です。\n時間をおいてもう一度お試しください。')
        )
        .finally(() => setLoading(false));
    },
    [id, setSimulateBaseSummary, setLoading, setError]
  );

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      await fetchSimulateBaseSummary(search);
    },
    [search, fetchSimulateBaseSummary]
  );

  useEffect(() => {
    if (id) fetchSimulateBaseSummary(search);
  }, [search, id]);

  return (
    <div>
      {loading ? (
        <SimpleLoading />
      ) : error ? (
        <Wrap>
          <Error>{error}</Error>
        </Wrap>
      ) : (
        simulateBaseSummary && (
          <Wrap>
            <Table>
              <thead>
                <GroupTr>
                  <Th />
                  <GroupTh colSpan={isSame ? 6 : 5}>現在</GroupTh>
                  <GroupTh colSpan={isPastSame ? 6 : 5}>予約時</GroupTh>
                </GroupTr>
                <tr>
                  <Th>
                    サマリー
                    {status !== 'completed' && (
                      <Link to="" onClick={handleClick}>
                        更新
                      </Link>
                    )}
                  </Th>
                  <RightTh width={80}>単位</RightTh>
                  <RightTh>元の料金(円)</RightTh>
                  {isSame && <RightTh>同名(円)</RightTh>}
                  <RightTh>最安値(円)</RightTh>
                  <RightTh>おすすめ(円)</RightTh>
                  <RightTh>平均値(円)</RightTh>
                  <RightTh width={80}>単位</RightTh>
                  <RightTh>元の料金(円)</RightTh>
                  {isPastSame && <RightTh>同名(円)</RightTh>}
                  <RightTh>最安値(円)</RightTh>
                  <RightTh>おすすめ(円)</RightTh>
                  <RightTh>平均値(円)</RightTh>
                </tr>
              </thead>
              <tbody>
                <SummaryRow
                  summary={simulateBaseSummary.total}
                  label="合計"
                  isSame={isSame}
                  isPastSame={isPastSame}
                />
                <SummaryRow
                  summary={simulateBaseSummary.perCount}
                  label="１件当たり"
                  isSame={isSame}
                  isPastSame={isPastSame}
                />
                <SummaryRow
                  summary={simulateBaseSummary.perPeople}
                  label="１人当たり"
                  isSame={isSame}
                  isPastSame={isPastSame}
                />
                <SummaryRow
                  summary={simulateBaseSummary.perStay}
                  label="１泊当たり"
                  isSame={isSame}
                  isPastSame={isPastSame}
                />
              </tbody>
            </Table>
          </Wrap>
        )
      )}
    </div>
  );
};

interface SummaryProps {
  summary: Summary;
  label: string;
  isSame: boolean;
  isPastSame: boolean;
}

const SummaryRow: React.FC<SummaryProps> = ({ summary, label, isSame, isPastSame }) => {
  const {
    priceWithTax,
    perUnit,
    aitSameItemPrice,
    aitSameItemDiff,
    aitCheapestItemPrice,
    aitCheapestItemDiff,
    aitRecommendedItemPrice,
    aitRecommendedItemDiff,
    aitAveragePrice,
    aitAverageDiff,
    pastPriceWithTax,
    pastPerUnit,
    aitPastSameItemPrice,
    aitPastSameItemDiff,
    aitPastCheapestItemPrice,
    aitPastCheapestItemDiff,
    aitPastRecommendedItemPrice,
    aitPastRecommendedItemDiff,
    aitPastAveragePrice,
    aitPastAverageDiff
  } = summary;

  return (
    <tr>
      <Td>{label}</Td>
      <TdRight>{perUnit}</TdRight>
      <TdRight>{priceWithTax.toLocaleString()}</TdRight>
      {isSame && <PriceWithDiff price={aitSameItemPrice} diff={aitSameItemDiff} />}
      <PriceWithDiff price={aitCheapestItemPrice} diff={aitCheapestItemDiff} />
      <PriceWithDiff price={aitRecommendedItemPrice} diff={aitRecommendedItemDiff} />
      <PriceWithDiff price={aitAveragePrice} diff={aitAverageDiff} />
      <TdRight>{pastPerUnit}</TdRight>
      <TdRight>{pastPriceWithTax.toLocaleString()}</TdRight>
      {isPastSame && <PriceWithDiff price={aitPastSameItemPrice} diff={aitPastSameItemDiff} />}
      <PriceWithDiff price={aitPastCheapestItemPrice} diff={aitPastCheapestItemDiff} />
      <PriceWithDiff price={aitPastRecommendedItemPrice} diff={aitPastRecommendedItemDiff} />
      <PriceWithDiff price={aitPastAveragePrice} diff={aitPastAverageDiff} />
    </tr>
  );
};

interface PriceProps {
  price: number;
  diff: number;
}

const PriceWithDiff: React.FC<PriceProps> = ({ price, diff }) => {
  const posi = diff >= 0;

  return (
    <TdRight>
      <span>{price.toLocaleString()}</span>(
      <span className={posi ? 'plus' : 'minus'}>
        {posi && '+'}
        {diff.toLocaleString()}
      </span>
      )
    </TdRight>
  );
};

const Wrap = styled.div`
  margin-bottom: 20px;
  max-width: 1400px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Table = styled.table`
  margin: 0;
  font-size: 12px;
  width: auto;

  &&& {
    table-layout: auto;
  }
`;

const GroupTr = styled.tr`
  th {
    border-bottom: 0;
  }
`;

const GroupTh = styled.th.attrs<{ colSpan: number }>(props => ({
  colSpan: props.colSpan
}))`
  padding: 4px;
  text-align: center;
  border-left: solid 3px white;
  background-color: ${props => props.theme.grayBgColorLight};
`;

const Th = styled.th`
  padding: 4px;

  & > a {
    margin-left: 8px;
  }
`;

const RightTh = styled.th<{ width?: number }>`
  padding: 4px;
  text-align: right;

  ${props => props.width && `width: ${props.width}px;`}
`;

const Td = styled.td`
  padding: 4px;
`;

const TdRight = styled.td`
  padding: 4px;
  text-align: right;

  span:first-child {
    margin-right: 4px;
  }

  .plus {
    color: ${props => props.theme.redColor};
  }
  .minus {
    color: ${props => props.theme.successColor};
  }
`;

export default SimulationSummary;
