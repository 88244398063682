export type Usage = {
  hit_count: number;
  unhit_count: number;
};

export type DataProviderDomesticAirUsageResponseArgs = {
  usage: Usage;
};

export type DataProviderDomesticAirUsageArgs = {
  hitCount: number;
  unhitCount: number;
};

export const convertDataproviderDomesticAirUsageRespnseToArgs = (
  response: DataProviderDomesticAirUsageResponseArgs
) => {
  const args: DataProviderDomesticAirUsageArgs = {
    hitCount: response.usage.hit_count,
    unhitCount: response.usage.unhit_count
  };
  return args;
};

export default class DataProviderDomesticAirUsage {
  hitCount: number;

  unhitCount: number;

  constructor(args: DataProviderDomesticAirUsageArgs) {
    this.hitCount = args.hitCount;
    this.unhitCount = args.unhitCount;
  }
}
