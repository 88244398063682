import _ from 'lodash';
import MarginType from './margin_type2';
import type { MarginTypeJson } from '../select_repository';

class MarginTypeList {
  rawMargins: MarginTypeJson[];

  marginTypes: MarginType[];

  constructor(margins: MarginTypeJson[] = []) {
    this.rawMargins = margins;
    this.marginTypes = _.map(margins, m => new MarginType(m));
  }

  domesticMarginType() {
    return _.find(this.marginTypes, m => m.isDomestic);
  }

  foreignMarginType() {
    return _.find(this.marginTypes, m => m.isForeign);
  }

  domesticFlightMarginType() {
    return _.find(this.marginTypes, m => m.isDomesticFlight);
  }

  domesticShinkansenMarginType() {
    return _.find(this.marginTypes, m => m.isDomesticShinkansen);
  }

  domesticHotelMarginType() {
    return _.find(this.marginTypes, m => m.isDomesticHotel);
  }

  foreignHotelMarginType() {
    return _.find(this.marginTypes, m => m.isForeignHotel);
  }
}

export default MarginTypeList;
