import React, { useState } from 'react';
import ArrangementMasterStepSequenceSelector from '../shared/arrangement_master_step_sequence_selector';

const getDefaultMasterStepSequenceIds = (): number[] => {
  const masterStepSequenceIds = utils.getParam('master_step_sequence_ids');
  if (!masterStepSequenceIds) {
    return [];
  }
  return masterStepSequenceIds.split(',').map((id: string) => parseInt(id, 10));
};

const useMasterStepSequenceSelector = (): [number[], () => JSX.Element] => {
  const [masterStepSequenceIds, setMasterStepSequenceIds] = useState<number[]>(getDefaultMasterStepSequenceIds());

  const renderMasterStepSequenceSelector = () => (
    <ArrangementMasterStepSequenceSelector
      selectedMasterStepSequenceIds={masterStepSequenceIds}
      onSelect={ids => setMasterStepSequenceIds(ids)}
    />
  );

  return [masterStepSequenceIds, renderMasterStepSequenceSelector];
};

export default useMasterStepSequenceSelector;
