import type { AITTokens as Tokens } from './index';

export const getColorFromToken = <T extends keyof Tokens['colors']>(
  tokens: Tokens,
  name: T,
  level?: keyof Tokens['colors'][T]
): string => {
  const color = tokens.colors[name];
  return typeof color === 'string' ? color : (color as any)[level];
};

export const getTypogragyFromToken = <T extends keyof Tokens['typographies']>(
  tokens: Tokens,
  name: T,
  level?: keyof Tokens['typographies'][T]
): string => {
  const typography = tokens.typographies[name];
  return typeof typography === 'string' ? typography : (typography as any)[level];
};

export const getSpacingFromToken = (tokens: Tokens, level: number) => {
  return level * tokens.spacing.base;
};

/**
 * 対象のboxの短辺(px)から角丸サイズを取得します。
 */
export const getRadiusFromToken = (tokens: Tokens, boxSize: number) => {
  if (boxSize <= 40) {
    return tokens.radiuses.r4;
  }

  if (boxSize >= 120) {
    return tokens.radiuses.r12;
  }

  return tokens.radiuses.r8;
};

export const getZindexFromToken = (tokens: Tokens, level: keyof Tokens['zIndices']) => {
  return tokens.zIndices[level];
};

export const getBreakpointsFromToken = (tokens: Tokens, level: keyof Tokens['breakpoints']) => {
  return tokens.breakpoints[level];
};
