import React from 'react';
import type { NativeSelectProps as MuiSelectProps } from '@material-ui/core/NativeSelect';
import MuiNativeSelect from '@material-ui/core/NativeSelect';
import { Input } from '@this/shared/ui/inputs/input';
import { makeStyles } from '@material-ui/core/styles';
import type { AITTheme } from '@this/shared/ui/theme';

export const SELECT_SIZE = {
  small: 'small',
  medium: 'medium'
} as const;
export type SelectSize = typeof SELECT_SIZE[keyof typeof SELECT_SIZE];

export type SelectProps = {
  size?: SelectSize;
} & Omit<MuiSelectProps, 'native' | 'variant' | 'label' | 'labelWidth'>;

const useStyles = makeStyles<AITTheme>(({ utils }) => ({
  root: {
    '& .MuiNativeSelect-select': {
      paddingRight: utils.getSpacing(6),
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  }
}));

export const Select = React.forwardRef<HTMLDivElement, SelectProps>(
  ({ size = 'medium', className, children, ...props }, ref) => {
    const styles = useStyles();
    const classNames = [className, styles.root];

    return (
      <MuiNativeSelect className={classNames.join(' ')} input={<Input size={size} />} ref={ref} {...props}>
        {children}
      </MuiNativeSelect>
    );
  }
);
