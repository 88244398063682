import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { styled } from '@this/constants/themes';
import { Switch, Route, NavLink, useLocation, useHistory } from 'react-router-dom';
import CsvBulkUpsertMember from '@this/components/expenses/organization/csv_bulk_action/csv_bulk_upsert_member';
import CsvBulkUpsertExpensesAccountTypes from '@this/components/expenses/organization/csv_bulk_action/csv_bulk_upsert_expenses_account_types';
import CsvBulkUpsertExpensesTypes from '@this/components/expenses/organization/csv_bulk_action/csv_bulk_upsert_expenses_types';
import BulkActionStatuses from '@this/components/expenses/organization/bulk_action_statuses/bulk_action_statuses';
import BulkActionStatus from '@this/components/expenses/organization/bulk_action_status/bulk_action_status';
import { LockClosedOutlinedIcon, MagicWandOutlinedIcon } from '@this/components/expenses/icons';
import CsvBulkUpsertDepartments from './csv_bulk_action/csv_bulk_upsert_departments';
import CsvBulkUpdateApprovers from './csv_bulk_action/csv_bulk_update_approvers';
import Members from './members';
import Departments from './departments';
import Projects from './projects';
import Setting from './setting';
import ExpensesTypes from './expenses_types';
import ExpensesAccountTypes from './expenses_account_types';
import RuleCabinets from './rule_cabinets/rule_cabinets';
import RuleCabinetDetail from './rule_cabinets/detail';
import RuleCabinetEdit from './rule_cabinets/edit';
import Plans from './plans';

interface Props {
  env: string;
}

interface State {
  useTripReport: boolean;
}

interface OrganizationResponse {
  use_trip_report: boolean;
}

const Organization = (props: Props): JSX.Element => {
  const location = useLocation();
  const initialPath =
    location.pathname === '/biztra/organization' ? '/biztra/organization/members' : location.pathname;
  const [path, setPath] = useState(initialPath);
  const history = useHistory();

  const [state, setState] = useState<State>({ useTripReport: false });
  const { useTripReport } = state;

  useEffect(() => {
    utils.jsonPromise<OrganizationResponse>('/biztra/organization/organization.json').then(result => {
      setState({ ...state, useTripReport: result.use_trip_report });
    });
  }, [setState]);

  useEffect(() => {
    return history.listen(_location => {
      setPath(_location.pathname);
    });
  }, [history]);

  const isActive = (_path: string): boolean => {
    return _path === path;
  };

  const navLink = (_path: string, title: string) => {
    return (
      <BiztraNavLink to={_path} className={isActive(_path) ? 'active' : ''}>
        {title}
      </BiztraNavLink>
    );
  };

  const navLinkWithIcon = (_path: string, title: string, icon: JSX.Element) => {
    return (
      <BiztraNavLink to={_path} className={isActive(_path) ? 'active' : ''}>
        <BiztraNavLinkIcon>{icon}</BiztraNavLinkIcon>
        {title}
      </BiztraNavLink>
    );
  };

  return (
    <Wrapper>
      <Nav>
        <ul>
          <Li>{navLink('/biztra/organization/members', '社員マスタ')}</Li>
          <Li>{navLink('/biztra/organization/departments', '部署マスタ')}</Li>
          {useTripReport && <Li>{navLink('/biztra/organization/projects', 'プロジェクトマスタ')}</Li>}
          <Li>{navLink('/biztra/organization/expenses_account_types', '勘定科目マスタ')}</Li>
          <Li>{navLink('/biztra/organization/expenses_types', '経費科目マスタ')}</Li>
          <Border />
          <Li>
            {navLinkWithIcon(
              '/biztra/organization/setting',
              '詳細設定',
              <LockClosedOutlinedIcon color={isActive('/biztra/organization/setting') ? '#ba8e31' : 'white'} />
            )}
          </Li>
          <Li>
            {navLinkWithIcon(
              '/biztra/organization/plans',
              '利用プラン',
              <MagicWandOutlinedIcon color={isActive('/biztra/organization/plans') ? '#ba8e31' : 'white'} />
            )}
          </Li>
          <Li>
            {navLinkWithIcon(
              '/biztra/organization/rule_cabinets',
              'ルール保管庫',
              <LockClosedOutlinedIcon
                color={isActive('/biztra/organization/rule_cabinets') ? '#ba8e31' : 'white'}
              />
            )}
          </Li>
        </ul>
      </Nav>
      <Content>
        <Switch>
          <Route
            exact
            path="/biztra/organization"
            render={routeProps => <Members {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/members"
            render={routeProps => <Members {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/departments"
            render={routeProps => <Departments {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/projects"
            render={routeProps => <Projects {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/expenses_account_types"
            render={routeProps => <ExpensesAccountTypes {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/rule_cabinets"
            render={routeProps => <RuleCabinets {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/rule_cabinets/:id"
            render={routeProps => <RuleCabinetDetail {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/rule_cabinets/:id/edit"
            render={routeProps => <RuleCabinetEdit {..._.merge(routeProps, props, { action: 'edit' } as const)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/rule_cabinets/new"
            render={routeProps => <RuleCabinetEdit {..._.merge(routeProps, props, { action: 'new' } as const)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/expenses_types"
            render={routeProps => <ExpensesTypes {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/members/csv/bulk_upsert"
            render={routeProps => <CsvBulkUpsertMember {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/expenses_account_types/csv/bulk_upsert"
            render={routeProps => (
              <CsvBulkUpsertExpensesAccountTypes {..._.merge(routeProps, props, { expenses: true })} />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/expenses_types/csv/bulk_upsert"
            render={routeProps => (
              <CsvBulkUpsertExpensesTypes {..._.merge(routeProps, props, { expenses: true })} />
            )}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/bulk_action_statuses"
            render={routeProps => <BulkActionStatuses {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/bulk_action_statuses/:id"
            render={routeProps => <BulkActionStatus {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/departments/csv/bulk_upsert"
            render={routeProps => <CsvBulkUpsertDepartments {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/departments/csv/bulk_update_approvers"
            render={routeProps => <CsvBulkUpdateApprovers {..._.merge(routeProps, props, { expenses: true })} />}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path="/biztra/organization/setting"
            render={routeProps => <Setting {..._.merge(routeProps, props)} />}
          />
        </Switch>
        <Switch>
          <Route exact path="/biztra/organization/plans" render={routeProps => <Plans />} />
        </Switch>
      </Content>
    </Wrapper>
  );
};

const NavWidth = '170px';

const BorderMargin = '8px';

const Wrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.grayBgColorLight};
  flex-grow: 9999;
`;

const Nav = styled.nav`
  background-color: #4e545e;
  padding: 0;
  width: ${NavWidth};
  border-right: 1px solid ${props => props.theme.grayBorderColor};
`;

const Li = styled.li`
  width: ${NavWidth};
`;

const BiztraNavLink = styled(NavLink)`
  color: white;
  display: block;
  padding: 14px 20px;
  &:active,
  &:hover,
  &:focus {
    color: white;
  }
  &.active {
    border-left: 6px solid #927230;
    padding-left: 14px;
    color: #ba8e31;
  }
`;

const Border = styled.div`
  width: calc(${NavWidth} - ${BorderMargin}*2);
  margin-left: ${BorderMargin};
  border-bottom: 1px solid rgba(118, 118, 118, 0.2);
`;

const BiztraNavLinkIcon = styled.span`
  margin-right: 8px;
  vertical-align: text-top;
  line-height: 1.8;
`;

const Content = styled.div`
  flex-grow: 2;
  margin: calc(30 / 1440 * 100%) calc(32 / 1440 * 100%) 0 calc(24 / 1440 * 100%);
  display: flex;
  flex-direction: column;
  width: calc((100vw - 170px) / 1440);
`;

export const ContentBody = styled.div`
  flex-grow: 9999;
  background: white;
  padding: calc(20 / 1214 * 100%);
`;

export const ContentBodyDetail = styled(ContentBody)`
  flex-grow: 9999;
  background: white;
  padding: 20px 50px;
`;

export default Organization;
