export interface AllowanceArgs {
  name: string;
  employee_number: string;
  domestic_with_stay: number;
  domestic_without_stay: number;
  foreign_with_stay: number;
  domestic_daily_allowance_with_stay: number;
  domestic_daily_allowance_without_stay: number;
  foreign_daily_allowance_with_stay: number;
}

export class Allowance {
  name: string;

  employeeNumber: string;

  domesticWithStay: number;

  domesticWithoutStay: number;

  foreignWithStay: number;

  domesticDailyAllowanceWithStay: number;

  domesticDailyAllowanceWithoutStay: number;

  foreignDailyAllowanceWithStay: number;

  constructor(args: AllowanceArgs) {
    this.name = args.name;
    this.employeeNumber = args.employee_number;
    this.domesticWithStay = args.domestic_with_stay;
    this.domesticWithoutStay = args.domestic_without_stay;
    this.foreignWithStay = args.foreign_with_stay;
    this.domesticDailyAllowanceWithStay = args.domestic_daily_allowance_with_stay;
    this.domesticDailyAllowanceWithoutStay = args.domestic_daily_allowance_without_stay;
    this.foreignDailyAllowanceWithStay = args.foreign_daily_allowance_with_stay;
  }
}
