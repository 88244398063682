import { observable } from 'mobx';

import moment from '@this/src/lib/moment';
import type { Moment } from '@this/src/lib/moment';

import TextToQueryResultItem, { convertTextToQueryResultItemResponseToArgs } from './text_to_query_result_item';
import type { TextToQueryResultItemResponseArgs } from './text_to_query_result_item';

export type AuthorType = 'user' | 'bot';

export type ChatbotMessageFormStatus = 'not_form' | 'active' | 'canceled' | 'completed';

export type ChatbotMessageRequired = 'origin' | 'date';

export type ChatbotMessageFormFieldType = 'origin' | 'outward_date' | 'homeward_date';

export type OriginField = { type: 'origin'; value: string; address: string };

type DateField = { value: Moment; outhour: number; outmin: number; outtype: 'departure' | 'arrival' };

export type OutwardDateField = { type: 'outward_date' } & DateField;

export type HomewardDateField = { type: 'homeward_date' } & DateField;

export type ChatbotMessageFormField = OriginField | OutwardDateField | HomewardDateField;

export type ChatbotMessageResponseArgs = {
  id?: number;
  author: AuthorType;
  text: string;
  error?: boolean;
  restart?: boolean;
  form_status?: ChatbotMessageFormStatus;
  requireds?: ChatbotMessageRequired[];
  search_query?: TextToQueryResultItemResponseArgs[];
  created_at: string | Moment;
};

export type ChatbotMessageArgs = {
  id?: number;
  author: AuthorType;
  text: string;
  error: boolean;
  restart: boolean;
  formStatus?: ChatbotMessageFormStatus;
  requireds?: ChatbotMessageRequired[];
  searchQuery?: TextToQueryResultItem[];
  createdAt: Moment;
};

export const convertChatbotMessageResponseToArgs = (response: ChatbotMessageResponseArgs) => {
  const args: ChatbotMessageArgs = {
    id: response.id,
    author: response.author,
    text: response.text,
    error: response.error || false,
    restart: response.restart || false,
    formStatus: response.form_status,
    requireds: response.requireds,
    searchQuery: response.search_query?.map(
      raw => new TextToQueryResultItem(convertTextToQueryResultItemResponseToArgs(raw))
    ),
    createdAt: moment(response.created_at)
  };
  return args;
};

export default class ChatbotMessage {
  @observable id: number | null;

  @observable author: AuthorType;

  @observable text: string;

  @observable error: boolean;

  @observable restart: boolean;

  @observable formStatus: ChatbotMessageFormStatus;

  @observable requireds: ChatbotMessageRequired[];

  @observable searchQuery: TextToQueryResultItem[];

  @observable createdAt: Moment;

  @observable formFields: ChatbotMessageFormField[];

  constructor(args: ChatbotMessageArgs) {
    this.id = args.id || null;
    this.author = args.author;
    this.text = args.text;
    this.error = args.error;
    this.restart = args.restart;
    this.formStatus = args.formStatus || 'not_form';
    this.requireds = args.requireds || [];
    this.searchQuery = args.searchQuery || [];
    this.createdAt = args.createdAt;
    this.formFields = this.initializeFormFields();
  }

  initializeFormFields(): ChatbotMessageFormField[] {
    return this.requireds.flatMap(required => {
      switch (required) {
        case 'origin': {
          return { type: 'origin', value: '', address: '' };
        }
        case 'date': {
          if (this.searchQuery.length > 1) {
            return [
              { type: 'outward_date', value: moment(), outhour: 12, outmin: 0, outtype: 'departure' },
              {
                type: 'homeward_date',
                value: moment().add(1, 'day'),
                outhour: 12,
                outmin: 0,
                outtype: 'departure'
              }
            ] as ChatbotMessageFormField[];
          }
          return { type: 'outward_date', value: moment(), outhour: 12, outmin: 0, outtype: 'departure' };
        }
        default:
          return [];
      }
    });
  }

  setStatus(status: ChatbotMessageFormStatus) {
    this.formStatus = status;
    app.render();
  }

  getOrigin() {
    if (this.requireds.length === 0) return '';
    if (this.searchQuery.length === 0) return '';

    const origin = this.searchQuery[0].origin;
    return origin || '';
  }

  getCompletedText() {
    if (this.requireds.length === 0) return null;
    if (this.searchQuery.length === 0) return null;

    const requireds = this.requireds.map(required => {
      switch (required) {
        case 'origin': {
          if (!this.searchQuery[0].origin) return null;
          return `出発地：${this.searchQuery[0].origin}`;
        }
        case 'date': {
          const dates = Array.from(
            new Set(this.searchQuery.flatMap(query => (query.date ? [query.date.format('YYYY/MM/DD')] : [])))
          );
          if (dates.length === 0) return null;
          if (dates.length === 1) return `日付：${dates[0]}`;
          return `日付：${dates[0]}〜${dates[dates.length - 1]}`;
        }
        default:
          return null;
      }
    });

    return requireds.filter(required => required !== null).join('、');
  }

  setFormFieldOrigin(value: string, address = '') {
    const target = this.formFields.find(field => field.type === 'origin') as OriginField;
    if (!target) return;
    target.value = value;
    target.address = address;
    app.render();
  }

  setFormFieldOutwardDate(value: Moment) {
    const target = this.formFields.find(field => field.type === 'outward_date') as OutwardDateField;
    if (!target) return;
    target.value = value;

    const homewardDate = this.formFields.find(field => field.type === 'homeward_date') as HomewardDateField;
    if (homewardDate && homewardDate.value.isBefore(value)) {
      homewardDate.value = value.clone().add(1, 'day');
    }

    app.render();
  }

  setFormFieldHomewardDate(value: Moment) {
    const target = this.formFields.find(field => field.type === 'homeward_date') as HomewardDateField;
    if (!target) return;
    target.value = value;
    app.render();
  }

  setFormFieldOutwardTime<T extends 'outhour' | 'outmin' | 'outtype'>(name: T, value: OutwardDateField[T]) {
    const target = this.formFields.find(field => field.type === 'outward_date') as OutwardDateField;
    if (!target) return;
    target[name] = value;
    app.render();
  }

  setFormFieldHomewardTime<T extends 'outhour' | 'outmin' | 'outtype'>(name: T, value: HomewardDateField[T]) {
    const target = this.formFields.find(field => field.type === 'homeward_date') as HomewardDateField;
    if (!target) return;
    target[name] = value;
    app.render();
  }

  updateParams() {
    return {
      origin: this.formFields.find(field => field.type === 'origin'),
      outward_date: this.updateDateParams(
        this.formFields.find(field => field.type === 'outward_date') as OutwardDateField
      ),
      homeward_date: this.updateDateParams(
        this.formFields.find(field => field.type === 'homeward_date') as HomewardDateField
      )
    };
  }

  updateDateParams(date?: OutwardDateField | HomewardDateField) {
    if (!date) return null;

    return {
      value: date.value.format('YYYY-MM-DD'),
      outhour: date.outhour,
      outmin: date.outmin,
      outtype: date.outtype
    };
  }
}
