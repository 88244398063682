import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import { TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core';
import { Button } from '@this/shared/ui/inputs/button';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import type { ApproveAction } from '@this/src/domain/trip_report/trip_report_store';

const TripReportApprove: React.FC = () => {
  const store = useTripReportStore();
  const history = useHistory();
  const textRef = useRef<TextFieldProps>({ value: '' });
  const { currentTripReport, validationMessages, loading, submitting } = store;
  const currentApproval = currentTripReport?.currentApproval;
  const { status, message } = currentApproval || { status: null, message: null };

  const handleClick = useCallback(
    (actionType: ApproveAction, message: string) => () => {
      store.approve({
        actionType,
        message: textRef.current.value as string,
        onSuccess: id => {
          history.push('/trip_report/approvals', { tripReport: { id, message } });
          window.scroll({ top: 0 });
        }
      });
    },
    [history]
  );

  if (loading || !currentApproval) return <></>;

  return (
    <Section>
      <SectionBody>
        {status === 'applied' ? (
          <>
            <div>
              <TextField
                variant="outlined"
                inputRef={textRef}
                defaultValue={message || ''}
                placeholder="コメントを記載する"
                rows={3}
                multiline
                fullWidth
              />
            </div>
            <div>この内容を承認しますか</div>
            {validationMessages.length > 0 && (
              <div>
                <Error>{validationMessages.join('\n')}</Error>
              </div>
            )}
            <Actions>
              <Button disabled={submitting} color="sub" onClick={handleClick('comment', '申請にコメントしました')}>
                コメントのみをする
              </Button>
              <Button disabled={submitting} color="sub" onClick={handleClick('reject', '申請を差し戻しました')}>
                差し戻し
              </Button>
              <Button disabled={submitting} color="primary" onClick={handleClick('accept', '申請を承認しました')}>
                承認
              </Button>
            </Actions>
          </>
        ) : (
          <>
            {message && (
              <div>
                <label>コメント</label>
                <div>{message}</div>
              </div>
            )}
            <div>
              {status === 'approved'
                ? '※ この申請は承認済みです'
                : status === 'rejected'
                ? '※ この申請は差し戻しされました'
                : status === 'canceled'
                ? '※ この申請は取り消しされました'
                : status === 'queued' && '※ この申請は他の申請者が確認中です'}
            </div>
          </>
        )}
      </SectionBody>
    </Section>
  );
};

const Section = styled.section`
  padding: 0 16px;
`;

const SectionBody = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  max-width: 600px;
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export default TripReportApprove;
