import React from 'react';
import { useTripReportStore } from '@this/src/domain/trip_report/trip_report_store';
import { BodyTitle, BodyDescription, Border } from '../../shared/wrappers';
import TripReportReport from '../../trip_report_report';

export const TripReportConfirm: React.FC = () => {
  const store = useTripReportStore();
  const tripReport = store.currentTripReport;

  return (
    <>
      <BodyTitle>申請タイトル：{tripReport.name}</BodyTitle>
      <BodyDescription>下記、各プロジェクトの承認者へ出張報告をします</BodyDescription>
      <Border />
      <TripReportReport editable />
    </>
  );
};
