import { Fetcher } from '@this/src/util';
import React from 'react';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { styled } from '@this/constants/themes';

import User from '@this/domain/user/user';
import type UserJson from '@this/domain/user/user_json';
import SimpleLoading from '@this/shared/simple_loading/simple_loading';
import { reportError } from '@this/lib/bugsnag';

interface MembersListResponse {
  members: UserJson[];
}

interface Props {
  self: User;
  onSelect: (user: User) => void;
  excludeSelf?: boolean;
  excludeUserIds?: number[];
}

interface State {
  filteredUsers: User[];
  isLoading: boolean;
}

class SelectTravelersBlockList extends React.Component<Props, State> {
  input: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      filteredUsers: props.excludeSelf ? [] : [props.self],
      isLoading: false
    };
  }

  componentDidMount() {
    this.fetchMembers('');
  }

  componentWillUnmount() {
    if (this.fetchMembersSubscription) {
      this.fetchMembersSubscription.unsubscribe();
    }
  }

  handleSearchUsers = (_e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyword = this.input.current!.value;
    this.fetchMembersSubject.next(keyword);
  };

  fetchMembers = async (keyword: string) => {
    const { self, excludeUserIds = [] } = this.props;
    this.setState({ isLoading: true });
    try {
      const res = await Fetcher.get<MembersListResponse>('/members.json', { keyword, travelable: true });
      const members = res.members.map(m => new User(m));
      const allUsers = [self, ...members];
      const filteredUsers = _.unionBy(allUsers, u => u.id).filter(u => !excludeUserIds.find(id => id === u.id));
      this.setState({
        isLoading: false,
        filteredUsers
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  };

  private fetchMembersSubject = new Subject<string>();

  private fetchMembersSubscription = this.fetchMembersSubject.pipe(debounceTime(300)).subscribe(this.fetchMembers);

  handleSelect(user: User) {
    this.props.onSelect(user);
  }

  render() {
    try {
      const { filteredUsers, isLoading } = this.state;
      return (
        <>
          <Input type="search" ref={this.input} placeholder="検索" onKeyUp={this.handleSearchUsers} />
          <ul>
            {isLoading ? (
              <li>
                <SimpleLoading />
              </li>
            ) : (
              <>
                {filteredUsers.map((u, i) => (
                  <ListItem key={i} onClick={() => this.handleSelect(u)}>
                    <ListItemName>{u.name}</ListItemName>
                    <ListItemEmail>{u.email}</ListItemEmail>
                  </ListItem>
                ))}
              </>
            )}
          </ul>
        </>
      );
    } catch (e) {
      reportError(e);
      return null;
    }
  }
}

const Input = styled.input`
  &&& {
    margin-bottom: 0;
  }
`;

const ListItem = styled.div`
  flex-wrap: nowrap;
  display: flex;
  background: ${props => props.theme.fieldBgColor};
  min-height: 30px;
  max-height: 60px;
  max-width: 350px;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListItemName = styled.div`
  min-width: 0;
  position: relative;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
`;

const ListItemEmail = styled.div`
  min-width: 0;
  overflow: hidden !important;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
  margin-left: 7px;
  vertical-align: 2px;
  font-size: 12px;
  color: #aaa;
  flex-shrink: 99;
`;

export default SelectTravelersBlockList;
