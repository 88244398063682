import type { Dispatch, SetStateAction } from 'react';
import type { ServiceType, UrlQuery } from '.';

const ENDPOINTS: Record<ServiceType, string> = {
  admin: '/admin/organization_dashboards/:id/:section',
  organization: '/organization/dashboard_trips/:section.json'
};

interface Request {
  section: string;
  pattern: string;
  serviceType: ServiceType;
  urlQuery: UrlQuery;
  organizationId?: number;
  jsonPromise: typeof utils.jsonPromise;
}

export interface DashboardRequest<T> extends Omit<Request, 'section' | 'pattern'> {
  setState: Dispatch<SetStateAction<T>>;
}

export const createEndpoint = (section: string, organizationId?: number) => {
  if (organizationId) {
    return ENDPOINTS.admin.replace(':id', organizationId.toString()).replace(':section', section);
  }

  return ENDPOINTS.organization.replace(':section', section);
};

export const fetchDashboard = async <T>({
  section,
  pattern,
  serviceType,
  urlQuery,
  organizationId,
  jsonPromise
}: Request) => {
  let endpoint = ENDPOINTS[serviceType];
  endpoint = endpoint.replace(':id', organizationId?.toString() ?? '').replace(':section', section);
  const query = {
    pattern,
    type: urlQuery.type,
    from: urlQuery.from.format('YYYY-MM-DD'),
    to: urlQuery.to.format('YYYY-MM-DD'),
    purchase_department_id: urlQuery.purchaseDepartmentId,
    approvals_department_id: urlQuery.approvalsDepartmentId
  };
  return jsonPromise<T>(endpoint, query);
};
