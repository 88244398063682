export interface MessageJson {
  message: string;
}

export class Message {
  message: string;

  constructor(args: MessageJson) {
    this.message = args.message;
  }
}
