import React from 'react';
import { observer } from 'mobx-react';

import TextArea from 'react-textarea-autosize';
import type InsuranceElement from '@this/domain/insurance_element';
import type { InsuranceProductType } from '@this/domain/insurance_element';

interface Props {
  element: InsuranceElement;
  classNameForModifiedField?: (...path: (string | number)[]) => string;
}

const InsuranceElementForm = observer(({ element, classNameForModifiedField }: Props) => (
  <>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`
          virtual-counte-price-change-form__label ${
            classNameForModifiedField ? classNameForModifiedField('product') : ''
          }
        `}
      >
        商品
      </label>
      <select
        value={element.product}
        onChange={e => {
          element.product = e.target.value as InsuranceProductType;
        }}
      >
        <option value="">選択なし</option>
        <option value="overseas_travel_accident">海外旅行傷害保険</option>
      </select>
    </div>
    <div className="virtual-counte-price-change-form__input-wrapper">
      <label
        className={`
          virtual-counte-price-change-form__label ${
            classNameForModifiedField ? classNameForModifiedField('content') : ''
          }
        `}
      >
        内容
        <span style={{ color: 'red' }}>（必須）</span>
      </label>
      <TextArea
        type="text"
        value={element.content}
        onChange={e => {
          element.content = e.target.value;
        }}
        minRows={2}
      />
    </div>
  </>
));

export default InsuranceElementForm;
