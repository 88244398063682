import React, { useCallback, useEffect, useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { styled } from '@this/constants/themes';
import ContentBody, { ContentBodyIn } from '@this/components/shared/atoms/content_body';
import SimpleLoading from '@this/components/shared/simple_loading/simple_loading';
import {
  InAdvanceApplicationContext,
  InAdvanceApplicationStore
} from '@this/src/domain/in_advance/in_advance_application_store';
import InAdvanceHeader from './in_advance_header';
import InAdvanceApplicationTable from './in_advance_application_table/in_advance_application_table';
import TripAdvanceApplicationFilter from './in_advance_application_filter/trip_advance_application_filter';
import InAdvanceApplicationSummary from './in_advance_application_table/in_advance_application_summary';

interface Props extends RouteComponentProps {
  availableOptions: string[];
}

const TabledInAdvanceApplications: React.FC<Props> = ({ availableOptions }) => {
  const store = useMemo(() => new InAdvanceApplicationStore(), []);
  const tripReportAvailable = useMemo(() => availableOptions.includes('trip_report'), [availableOptions]);
  const { tripsLoading, tripsErrors, searchFilter } = store;

  const handleSearch = useCallback((_query: any) => {
    store.fetchTrips();
  }, []);

  useEffect(() => {
    store.fetchTrips();
  }, []);

  return (
    <InAdvanceApplicationContext.Provider value={store}>
      <ApplicationsWrap>
        <Applications tripReportAvailable={tripReportAvailable}>
          <ApplicationsIn>
            {tripReportAvailable && <InAdvanceHeader selected="trip" tripReportAvailable />}
            <Body>
              <ContentBodyIn>
                <PageTitle>出張前の申請</PageTitle>
                <Filter>
                  <TripAdvanceApplicationFilter
                    onSearch={handleSearch}
                    filterRef={searchFilter}
                    loading={tripsLoading}
                  />
                </Filter>
                {tripsLoading ? (
                  <SimpleLoading />
                ) : tripsErrors.length > 0 ? (
                  <Error>{tripsErrors.join('\n')}</Error>
                ) : (
                  <>
                    <InAdvanceApplicationSummary />
                    <InAdvanceApplicationTable />
                  </>
                )}
              </ContentBodyIn>
            </Body>
          </ApplicationsIn>
        </Applications>
      </ApplicationsWrap>
    </InAdvanceApplicationContext.Provider>
  );
};

const ApplicationsWrap = styled.div`
  min-height: 100vh;
`;

const Applications = styled.div<{ tripReportAvailable: boolean }>`
  padding: 20px 0;
  display: block;

  ${props =>
    props.tripReportAvailable &&
    `
    padding-top: 0;
  `}
`;

const ApplicationsIn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
`;

const Body = styled(ContentBody)`
  max-width: 1150px;
`;

const PageTitle = styled.h2`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Filter = styled.div`
  .searchFields {
    padding: 16px;
    background-color: ${props => props.theme.accentColorLight};
  }
`;

const Error = styled.div`
  color: ${props => props.theme.redColor};
  white-spacing: pre-wrap;
`;

export default TabledInAdvanceApplications;
